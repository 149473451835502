import { useEffect } from "react";
import MDDialog from "components/MDDialog";
import { Grid } from "@mui/material";
import { useAppSelector, useAppDispatch } from "store/store";
import { getRMSMSRequestDetails } from "store/thunk/repairMaintenance";
import MDLabelValue from "components/MDLabelValue";
import { getLocalDateTime } from "helper/services";

function SmsRequestViewPopup(props: any): JSX.Element {
  const { onClose, detailData } = props;
  const dispatch = useAppDispatch();
  const { smsRequestDetailData } = useAppSelector((state: any) => state.repairMaintenanceSlice);

  useEffect(() => {
    if (detailData && detailData?.id && detailData?.location_id) {
      const req = {
        id: detailData?.id,
        location_id: detailData?.location_id,
        errorMsg: true,
      };
      dispatch(getRMSMSRequestDetails(req));
    }
  }, []);

  const viewDialogProps = {
    open: detailData ? detailData : false,
    onClose,
    title: `View`,
    size: "md",
    saveBtn: false,
    closeBtnText: "Close",
  };

  return (
    <MDDialog {...viewDialogProps}>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12} md={6} lg={6}>
          <MDLabelValue label="Location ID" value={smsRequestDetailData?.location_id || "-"} />
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6}>
          <MDLabelValue
            label="Location Name"
            value={smsRequestDetailData?.location_data?.name || "-"}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6}>
          <MDLabelValue
            label="Twillio Phone Number"
            value={smsRequestDetailData?.assigned_number_data?.twillio_phone_number || "-"}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6}>
          <MDLabelValue label="Request Status" value={smsRequestDetailData?.status || "-"} />
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6}>
          <MDLabelValue
            label="Requested User Name"
            value={
              smsRequestDetailData?.requested_user_data
                ? `${smsRequestDetailData?.requested_user_data?.first_name} ${smsRequestDetailData?.requested_user_data?.last_name}`
                : "-"
            }
          />
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6}>
          <MDLabelValue
            label="Approved User Name"
            value={
              smsRequestDetailData?.approved_user_data
                ? `${smsRequestDetailData?.approved_user_data?.first_name} ${smsRequestDetailData?.approved_user_data?.last_name}`
                : "-"
            }
          />
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6}>
          <MDLabelValue
            label="Created At"
            value={
              smsRequestDetailData?.created_at
                ? getLocalDateTime(smsRequestDetailData?.created_at)
                : "-"
            }
          />
        </Grid>
      </Grid>
    </MDDialog>
  );
}

export default SmsRequestViewPopup;
