import MDDialog from "components/MDDialog";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
// import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Accordion, AccordionDetails, AccordionSummary, Grid } from "@mui/material";
import { getLocalDateTime } from "helper/services";

function ViewDetails(props: any): JSX.Element {
  const { open, onClose, updateData } = props;

  const dialogProps = {
    open,
    onClose,
    title: "View State Region",
    size: "sm",
    saveBtn: false,
  };

  return (
    <MDDialog {...dialogProps}>
      <MDBox pb={2}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12} md={12}>
            <Accordion expanded>
              <AccordionSummary
                className="location-accordion"
                // expandIcon={<ExpandMoreIcon />}
                aria-controls="publishbh-content"
              >
                <MDTypography variant="h6">Basic info</MDTypography>
              </AccordionSummary>
              <AccordionDetails>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6} md={6}>
                    <MDTypography fontWeight="bold" variant="button">
                      Sola Class Region
                    </MDTypography>
                    <MDBox>
                      <MDTypography variant="button">
                        {updateData.sola_class_region_name || "-"}
                      </MDTypography>
                    </MDBox>
                  </Grid>
                  <Grid item xs={12} sm={6} md={6}>
                    <MDTypography fontWeight="bold" variant="button">
                      State
                    </MDTypography>
                    <MDBox>
                      <MDTypography variant="button">{updateData.state || "-"}</MDTypography>
                    </MDBox>
                  </Grid>
                  <Grid item xs={12} sm={6} md={6}>
                    <MDTypography fontWeight="bold" variant="button">
                      Created At
                    </MDTypography>
                    <MDBox>
                      <MDTypography variant="button">
                        {getLocalDateTime(updateData.created_at)}
                      </MDTypography>
                    </MDBox>
                  </Grid>
                  <Grid item xs={12} sm={6} md={6}>
                    <MDTypography fontWeight="bold" variant="button">
                      Updated At
                    </MDTypography>
                    <MDBox>
                      <MDTypography variant="button">
                        {getLocalDateTime(updateData.updated_at)}
                      </MDTypography>
                    </MDBox>
                  </Grid>
                </Grid>
              </AccordionDetails>
            </Accordion>
          </Grid>
        </Grid>
      </MDBox>
    </MDDialog>
    // <MDDialog {...dialogProps}>
    //   <MDBox mt={2}>
    //     <Grid container>
    //       {updateData?.sola_class_region_name && (
    //         <Grid item xs={12} sm={6} md={6}>
    //           <MDTypography fontWeight="bold" variant="button">
    //             Sola Class Region
    //           </MDTypography>
    //           <MDBox>
    //             <MDTypography variant="button">{updateData?.sola_class_region_name}</MDTypography>
    //           </MDBox>
    //         </Grid>
    //       )}
    //     </Grid>
    //   </MDBox>
    //   <MDBox mt={2}>
    //     <Grid container>
    //       <Grid item xs={12} sm={6} md={6}>
    //         <MDTypography fontWeight="bold" variant="button">
    //           State
    //         </MDTypography>
    //         <MDBox>
    //           <MDTypography variant="button">{updateData?.state || "-"}</MDTypography>
    //         </MDBox>
    //       </Grid>
    //     </Grid>
    //   </MDBox>
    // </MDDialog>
  );
}

export default ViewDetails;
