import { useState, useCallback, useRef, useEffect, useLayoutEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Card, Icon, Checkbox } from "@mui/material";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import DataTable from "examples/Tables/DataTable";
import AddUpdate from "layouts/pages/locations/location-wizard/addUpdate";
import { useAppDispatch, useAppSelector } from "store/store";
import { getLocations, deleteLocationAllData } from "store/thunk/locationThunk";
import MDIconButton from "components/MDIconButton";
import exportData from "helper/exportTableConst";
import {
  getLocationAllTabAccessPermission,
  insertinArray,
  removeExportFields,
  setDefaultSelectedColumns,
  adminFranchisie,
  userRoles,
  isFranchisie,
  getUrlName,
  adminSuperAdmin,
  getAssociationLocations,
} from "helper/services";
import Messages from "helper/messages";
import MDDialog from "components/MDDialog";
import { getDefaultCoulmns, saveSelectedAllData } from "store/thunk/connectUserThunk";
import { setDefaultsColumns } from "store/slices/connectUsersSlice";
import ViewDetails from "./viewDetails";

function Index(): JSX.Element {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { locations } = useAppSelector((state) => state.locationSlice);
  const { defaultSelectedColumns } = useAppSelector((state) => state.connectUsersReducer);
  const { userData } = useAppSelector((state) => state.authReducer);
  const [locationData, setLocationData] = useState([]);
  const [addUpdatePopup, setAddUpdatePopup] = useState(false);
  const [deleteLocationId, setDeleteLocationId] = useState<any>(null);
  const [selectedItems, setSelectedItems] = useState([]);
  const [location, setLocation] = useState<any>(null);
  const [pageCount, setPageCount] = useState(0);
  const [pageNo, setPageNo] = useState(0);
  const [perPage, setPageSize] = useState(10);
  const [search, setSearch] = useState<any>(null);
  const [sortBy, setSortBy] = useState<any>([]);
  const [isCheckAll, setIsCheckAll] = useState(false);
  const [totalCount, setTotalCount] = useState(0);
  const [detailsPopup, setDetailsPopup] = useState<any>(false);
  const [updateData, setUpdateData] = useState<any>("");
  const fetchIdRef = useRef(0);
  const [displayColumns, setDisplayColumns] = useState<any>([]);
  const [defaultExport, setDefaultExport] = useState<any>([]);
  // SD-3199
  const [callFetch, setCallFetch] = useState<any>(false);
  const [defaultSorting, setDefaultSorting] = useState<any>([]);
  const [defaultFilteing, setDefaultFilteing] = useState<any>([]);

  const getColumns = () => {
    let columns: any = [
      {
        Header: "",
        disableSortBy: true,
        width: "75px",
        accessor: "checkbox",
        disableCheck: false,
        checked: true,
      },
      {
        Header: "Name",
        accessor: "name",
        width: "200px",
        export: true,
        disableCheck: true,
        checked: true,
      },
      {
        Header: "Sola ID",
        accessor: "store_id",
        width: "85px",
        export: true,
        disableCheck: false,
        checked: true,
      },
      {
        Header: "Address",
        accessor: "address_1",
        width: "200px",
        export: true,
        disableCheck: false,
        checked: true,
      },
      { Header: "City", accessor: "city", export: true, disableCheck: false, checked: true },
      { Header: "State", accessor: "state", export: true, disableCheck: false, checked: false },
      {
        Header: "MSA",
        accessor: "msas",
        export: true,
        disableCheck: false,
        checked: false,
      },
      {
        Header: "Franchisee",
        accessor: "franchisee",
        export: true,
        disableCheck: false,
        associative_colums: "admins.email",
        checked: false,
      },
      {
        Header: "Phone",
        accessor: "phone_number",
        export: true,
        disableCheck: false,
        checked: false,
        // associative_colums: "locations.phone_number",
      },
      {
        Header: "URL Name",
        accessor: "url_name",
        export: true,
        disableCheck: false,
        checked: false,
      },
    ];
    if (adminFranchisie()) {
      columns = insertinArray(columns, 1, {
        Header: "ID",
        accessor: "id",
        export: true,
        disableCheck: true,
        checked: true,
      });
    }
    columns.push({
      Header: "Action",
      accessor: "action",
      width: "200px",
      export: false,
      disableCheck: false,
      disableSortBy: true,
      checked: true,
    });
    return columns;
  };
  const [checkedColumns, setCheckedColumns] = useState<any>(getColumns());
  const latestRefValue = useRef(checkedColumns);

  useEffect(() => {
    latestRefValue.current = checkedColumns;
  }, [checkedColumns]);

  const fetchData = useCallback(({ pageSize, pageIndex, search, sortBy, filterData, isManual }) => {
    const fetchId = ++fetchIdRef.current;
    const sort: any = sortBy || [];
    // We'll even set a delay to simulate a server here
    setTimeout(() => {
      if (fetchId === fetchIdRef.current) {
        const sentReq = {
          sendData: {
            user_id: userData?.user_id,
            table_name: exportData.locations.table_name,
            table_columns: latestRefValue.current ? latestRefValue.current : checkedColumns, // Changes in checkedColumns
            table_sort: sortBy && sortBy?.length ? sortBy : [],
            table_filter: filterData && filterData?.length ? filterData : [],
            default_export_data: defaultExport,
          },
        };
        if (isManual) {
          dispatch(saveSelectedAllData(sentReq));
        }
        setPageNo(pageIndex);
        setSearch(search);
        setSortBy(sort);
        setPageSize(pageSize);
        // setSelectedItems([]);
        setIsCheckAll(false);
        dispatch(getLocations({ pageSize, pageIndex, search, sort, userData, filterData }));
      }
    }, 1000);
  }, []);

  // useLayoutEffect for get lateset Updated record
  useLayoutEffect(() => {
    dispatch(setDefaultsColumns([]));
    // Set data in variable and Call defaultColums Thunk
    const sentReq = {
      user_id: userData?.user_id,
      table_name: exportData.locations.table_name,
    };
    dispatch(getDefaultCoulmns(sentReq)).then((resp: any) => {
      // SD-3199
      if (resp?.payload) {
        const respFilter = resp?.payload?.table_filter || [];
        const respSorting = resp?.payload?.table_sort || [];
        const defaultDBexportData = resp?.payload?.default_export_data || [];
        const defaultDBColumn = resp?.payload?.table_columns || [];
        const tempdata = setDefaultSelectedColumns(defaultDBColumn, getColumns());
        setCheckedColumns(tempdata);
        setDefaultExport(defaultDBexportData);
        setDefaultSorting(respSorting);
        setSortBy(respSorting);
        setDefaultFilteing(respFilter);
        setCallFetch(true);
        fetchData({
          perPage,
          pageNo,
          search,
          sortBy: respSorting && respSorting?.length ? respSorting : [],
          additionalField: null,
          filterData: respFilter && respFilter?.length ? respFilter : [],
        });
      }
    });
  }, []);

  useEffect(() => {
    if (locations && locations?.data) {
      setLocationData(locations?.data);
      setPageCount(locations?.meta?.total_pages);
      setTotalCount(locations?.meta?.total_count);
    }
  }, [locations]);

  useEffect(() => {
    if (defaultSelectedColumns && defaultSelectedColumns?.length) {
      const newColumns = setDefaultSelectedColumns(defaultSelectedColumns, getColumns());
      setCheckedColumns(newColumns);
    } else {
      setCheckedColumns(getColumns());
    }
  }, [defaultSelectedColumns]);

  useEffect(() => {
    if (checkedColumns?.length) {
      setDisplayColumns(checkedColumns.filter((col: any) => col.checked));
    }
  }, [checkedColumns]);

  const goToLocationDetails = (obj: any) => {
    if (
      getLocationAllTabAccessPermission(
        obj?.location?.id || "",
        "locations",
        "locations_location",
        "",
        "edit_access"
      ) ||
      obj.is_owner ||
      isFranchisie()
    ) {
      navigate(`/locations/${getUrlName(obj?.location?.name)}`, {
        state: { prevPath: "/locations", defaultTab: "0", id: obj?.location?.id },
      });
    }
  };

  // Start Remove selcted items
  const removeSelectedItem = (deletedId: number) => {
    setSelectedItems(selectedItems.filter((item: any) => Number(item) !== deletedId));
  };
  // End Remove selcted items

  const onDelete = () => {
    if (deleteLocationId) {
      dispatch(
        deleteLocationAllData({
          id: deleteLocationId,
          callback: setDeleteLocationId,
          pageNo,
          perPage,
          search,
          sortBy,
          removeSelectedItem,
        })
      );
    }
  };

  const openDetailPopup = (data: any) => {
    setUpdateData(data);
    setDetailsPopup(true);
  };

  const closeDetailPopup = () => {
    setDetailsPopup(false);
  };

  const actionButtons = (obj: any) => (
    <>
      {(getLocationAllTabAccessPermission(
        obj?.location?.id || "",
        "locations",
        "locations_location",
        "",
        "view_access"
      ) ||
        obj.is_owner) && (
        <MDIconButton
          tooltipName="View"
          aria-label="Open"
          color="info"
          onClick={() => openDetailPopup(obj)}
        >
          <Icon fontSize="small">visibility</Icon>
        </MDIconButton>
      )}
      {(getLocationAllTabAccessPermission(
        obj?.location?.id || "",
        "locations",
        "locations_location",
        "",
        "edit_access"
      ) ||
        obj.is_owner ||
        isFranchisie()) && (
        <MDIconButton
          tooltipName="Location Details"
          aria-label="Open"
          color="info"
          onClick={() => goToLocationDetails(obj)}
        >
          <Icon fontSize="small">edit</Icon>
        </MDIconButton>
      )}
      {(userData?.role === userRoles.SYSTEM_ADMIN || userData?.role === userRoles.ADMIN) && (
        <MDIconButton
          tooltipName="Delete"
          aria-label="Delete"
          color="error"
          onClick={() => setDeleteLocationId(obj?.cms_location_id)}
        >
          <Icon fontSize="small">delete</Icon>
        </MDIconButton>
      )}
    </>
  );

  const onChangeCheckBox = (e: any) => {
    let items = JSON.parse(JSON.stringify(selectedItems));
    if (e.target.checked && !items.includes(e.target.value)) {
      items.push(e.target.value);
      if (items.length === locationData.length) {
        setIsCheckAll(true);
      }
    } else {
      items = items.filter((id: any) => id !== e.target.value);
      setIsCheckAll(false);
    }
    setSelectedItems(items);
  };

  const checkUncheckAll = (e: any) => {
    let allIds: any = [];
    allIds = locationData.map((val: any) => String(val?.location?.id));
    if (e.target.checked) {
      const selectedIds = [...new Set([...selectedItems, ...allIds])];
      setSelectedItems(selectedIds);
      setIsCheckAll(true);
    } else {
      allIds = locationData.map((val: any) => String(val?.location?.id));
      allIds = selectedItems.filter((el: any) => !allIds.includes(el));
      setSelectedItems([...allIds]);
      setIsCheckAll(false);
    }
  };

  const getMsasName = (msas?: any[]) => msas?.map((msa: any) => msa?.name)?.toString();

  const getRows = () =>
    locationData &&
    locationData.map((obj: any) => ({
      checkbox: (
        <Checkbox
          checked={
            selectedItems.includes(obj.id) || selectedItems.includes(String(obj?.location?.id))
          }
          onChange={(e: any) => onChangeCheckBox(e)}
          value={obj?.location?.id}
          className="custom-checkbox"
        />
      ),
      id: obj?.location?.id,
      store_id: obj?.location?.store_id,
      name: (
        <MDTypography
          sx={{ fontSize: 14, cursor: "pointer", fontWeight: "bold" }}
          onClick={() => goToLocationDetails(obj)}
          variant="subtitle2"
        >
          {obj?.location?.name}
        </MDTypography>
      ),
      address_1: obj?.location?.address_1 || "-",
      city: obj?.location?.city || "-",
      state: obj?.location?.state || "-",
      msas: getMsasName(obj?.location?.msas) || "-",
      franchisee: obj?.location?.admin?.email || "-",
      phone_number: obj?.location?.phone_number || "-",
      url_name: obj?.location?.url_name || "-",
      action: actionButtons(obj),
    }));

  const tableData = {
    columns: getColumns(),
    rows: getRows(),
  };

  const closePopup = () => {
    setAddUpdatePopup(false);
    setLocation(null);
  };

  const deleteDialogProps = {
    open: deleteLocationId ? true : false,
    onClose: () => setDeleteLocationId(false),
    onSave: () => onDelete(),
    title: "Delete Location",
    size: "sm",
    saveBtn: false,
    deleteBtn: true,
    saveTbtText: "Yes",
    closeBtnText: "No",
  };

  // Remove uncheck boxes
  const clearCheckItem = () => {
    setSelectedItems([]);
    // setSelectedObj([]);
  };

  return (
    <MDBox>
      <Card>
        <MDBox
          bgColor="dark"
          color="white"
          coloredShadow="dark"
          borderRadius="xl"
          alignItems="center"
          justifyContent="space-between"
          pt={1}
          pb={1}
          pl={2}
          pr={2}
          ml={2}
          mr={2}
          display="flex"
          mt={-3}
          className="page-header"
        >
          <p className="page-header-label">Locations</p>
          {(userData?.role === userRoles.SYSTEM_ADMIN || userData?.role === userRoles.ADMIN) && (
            <MDButton
              variant="gradient"
              color="light"
              size="small"
              onClick={() => setAddUpdatePopup(true)}
              className="xs-small"
            >
              <Icon sx={{ fontWeight: "bold" }}>add</Icon> Add Location
            </MDButton>
          )}
        </MDBox>
        {(defaultSorting || defaultSorting?.length) &&
          callFetch && ( // SD-3199
            <DataTable
              defaultSortingColumns={defaultSorting} // SD-3199
              defaultFilteringColums={defaultFilteing} // SD-3199
              defaultDisplayColumns={displayColumns}
              setCheckedColumns={setCheckedColumns}
              table={tableData}
              fetchData={fetchData}
              canSearch
              searchText="Search Locations..."
              selectedItems={selectedItems}
              pageNo={pageNo}
              setPageNo={setPageNo}
              pageCount={pageCount}
              totalCount={totalCount}
              showExportSelectedBtn
              showExportAllBtn
              exportFields={removeExportFields(exportData.locations.export_fields)}
              exportTable={exportData.locations.table_name}
              exportType={exportData.locations.export_type}
              joinTables={exportData.locations.join_tables}
              excludeColumns={exportData.locations.exclude_columns}
              manualSearch
              manualSort
              isDisplayCheckAllCheckbox
              onChangeCheckAllCheckbox={checkUncheckAll}
              isCheckAll={isCheckAll}
              checkedColumns={checkedColumns}
              clearCheckItem={clearCheckItem}
              advanceFilter
              locationsIds={!adminSuperAdmin() && getAssociationLocations()}
            />
          )}
        {addUpdatePopup && (
          <AddUpdate
            open={addUpdatePopup}
            pageNo={pageNo}
            perPage={perPage}
            search={search}
            sortBy={sortBy}
            onClose={closePopup}
            location={location}
          />
        )}
        {detailsPopup && (
          <ViewDetails
            open={detailsPopup}
            updateData={updateData}
            onClose={() => {
              closeDetailPopup();
            }}
          />
        )}
        {deleteLocationId && (
          <MDDialog {...deleteDialogProps}>
            <MDTypography variant="h6" fontWeight="medium">
              {Messages.GENERAL.SURE_TO_DELETE}
            </MDTypography>
          </MDDialog>
        )}
      </Card>
    </MDBox>
  );
}

export default Index;
