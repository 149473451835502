import { useEffect, useState } from "react";
import MDDialog from "components/MDDialog";
import MDInput from "components/MDInput";
import MDBox from "components/MDBox";
import { Autocomplete, TextField } from "@mui/material";
import Validations from "helper/validations";
import { useAppDispatch, useAppSelector } from "store/store";
import { saveReports } from "store/thunk/systemAdmin";

function AddUpdate(props: any): JSX.Element {
  const dispatch = useAppDispatch();
  const { reportsTypesList } = useAppSelector((state) => state.systemAdminSlice);

  const { open, onClose, updateData, pageNo, perPage, search, sortBy } = props;
  const [isUpdate, setIsUpdate] = useState<any>(false);
  const [reportData, setReportData] = useState<any>({});
  const [errors, setErrors] = useState<any>({});

  const onSave = () => {
    const error = Validations.validateReportsForm(reportData);
    setErrors(error);
    if (!Object.keys(error).length) {
      const req = {
        reportData,
        onClose,
        isUpdate,
        pageNo,
        perPage,
        search,
        sortBy,
      };
      dispatch(saveReports(req));
    }
  };
  useEffect(() => {
    if (updateData) {
      const data = { ...updateData };
      setIsUpdate(true);
      data.report_type = reportsTypesList.find((v: any) => v.value === data.report_type);
      setReportData(data);
    }
  }, [updateData]);

  const onChangeField = (e: any, field: string) => {
    setReportData({ ...reportData, [field]: e.target.value });
  };

  const dialogProps = {
    open,
    onSave,
    onClose,
    title: isUpdate ? "Update Report" : "Add Report",
    size: "sm",
    saveTbtText: isUpdate ? "Update" : "Save",
  };

  return (
    <MDDialog {...dialogProps}>
      <MDBox mt={2}>
        <Autocomplete
          id="report_type"
          options={reportsTypesList}
          getOptionLabel={(option) => option.name}
          value={reportData.report_type || null}
          onChange={(event: any, newValue: any) => {
            setReportData({ ...reportData, report_type: newValue });
          }}
          renderOption={(props: any, option: any) => <li {...props}>{option.name}</li>}
          renderInput={(params: any) => (
            <TextField
              {...params}
              variant="standard"
              label="Report Type"
              placeholder="Report Type"
              required
              error={errors && errors.report_type ? true : false}
              helperText={errors && errors.report_type}
            />
          )}
        />
      </MDBox>
      <MDBox mt={2}>
        <MDInput
          label="Email Address"
          variant="standard"
          fullWidth
          required
          name="email_address"
          placeholder=""
          onChange={(event: any) => onChangeField(event, "email_address")}
          value={reportData?.email_address ? reportData?.email_address : ""}
          error={errors && errors.email_address ? true : false}
          helperText={errors && errors.email_address}
        />
      </MDBox>
      <MDBox mt={2}>
        <MDInput
          label="Parameters"
          variant="standard"
          fullWidth
          name="parameters"
          placeholder=""
          onChange={(event: any) => onChangeField(event, "parameters")}
          value={reportData?.parameters ? reportData?.parameters : ""}
          error={errors && errors.parameters ? true : false}
          helperText={errors && errors.parameters}
        />
      </MDBox>
    </MDDialog>
  );
}

export default AddUpdate;
