import { Card, CardActions, CardContent, Icon, Tooltip } from "@mui/material";
import MDTypography from "components/MDTypography";
import moment from "moment";
import { getMessageCountTexts, getEnrollNextTime } from "helper/services";
import { useState } from "react";
import MDIconButton from "components/MDIconButton";
import ViewNotesDetails from "./viewNotesDetails";

function NewViewLead(props: any): JSX.Element {
  const {
    leads,
    getLeadChatLocationDetails,
    cardClickDetails,
    statusCard,
    viewType,
    statusDisp,
    leadData,
    getLeadChatStatusDetails,
    applySort,
  } = props;
  const cards: any = [];
  let dispType = "";
  const [detailsPopup, setDetailsPopup] = useState<any>(false);
  const [elementNote, setElementNote] = useState<any>([]);
  const [elementId, setElementId] = useState<any>(null);

  const displayDate = (date: any) => {
    if (moment().isSame(date, "day")) {
      dispType = moment(date).format("h:mm a");
    } else if (moment().diff(date, "days") <= 6) {
      dispType = moment(date).fromNow();
    } else if (moment().diff(date, "days") <= 13) {
      dispType = "1 week ago";
    } else if (moment().diff(date, "days") >= 14 && moment().diff(date, "days") < 50) {
      dispType = "6 week ago";
    } else {
      dispType = moment(date).fromNow();
    }
    return dispType;
  };

  const getMessagePreview = (element: any) => {
    const smsRequestStatus = element?.location?.connect_twillio_sms_request?.status || "";
    const conversionCount = Number(element?.conversation_count || 0);
    let previewHtml = (
      <>
        <MDTypography variant="body2" fontSize={14} className="multiline-ellipsis">
          {element?.last_conversation || "No Message Preview"}
        </MDTypography>
        <MDTypography
          component="span"
          variant="subtitle1"
          color="text.secondary"
          fontSize={12}
          display="block"
        >
          {getMessageCountTexts(element)}
        </MDTypography>
        {element?.enroll_lead && (
          <MDTypography
            component="span"
            variant="subtitle1"
            color="text.secondary"
            fontSize={12}
            display="block"
          >
            {getEnrollNextTime(element)}
          </MDTypography>
        )}
      </>
    );
    if (!smsRequestStatus || smsRequestStatus === "requested") {
      previewHtml = (
        <MDTypography variant="body2" fontSize={14} className="multiline-ellipsis">
          SMS Not Enabled
        </MDTypography>
      );
    } else if (smsRequestStatus && smsRequestStatus !== "requested" && !conversionCount) {
      previewHtml = (
        <>
          <MDTypography
            component="span"
            variant="subtitle1"
            color="text.secondary"
            fontSize={12}
            display="block"
          >
            {getMessageCountTexts(element)}
          </MDTypography>
          {element?.enroll_lead && (
            <MDTypography
              component="span"
              variant="subtitle1"
              color="text.secondary"
              fontSize={12}
              display="block"
            >
              {getEnrollNextTime(element)}
            </MDTypography>
          )}
        </>
      );
    }
    return previewHtml;
  };

  const openNotePopup = (e: any, note: any) => {
    e.stopPropagation();
    setElementId(note?.id);
    setElementNote(note?.connect_deal_notes);
    setDetailsPopup(true);
  };

  const onCloseNotePopup = () => {
    setElementNote([]);
    setElementId(null);
    setDetailsPopup(false);
  };

  const cardItem = (element: any) => (
    <Card
      style={{ cursor: "pointer" }}
      className={`lead-card ${element.id === leadData?.id && "active-card"}`}
      key={element?.id}
      onClick={() => cardClickDetails(element)}
    >
      <CardContent sx={{ padding: "15px 15px 10px 15px" }}>
        <MDTypography variant="h5" component="div" className="deal-name">
          {element?.deal_name}
        </MDTypography>
        <Tooltip title={element.message_status === "done" ? "Mark Open" : "Mark Done"} arrow>
          <Icon
            className={element.message_status === "done" ? "deal-done" : "deal-open"}
            onClick={(e: any) => statusCard(e, element)}
          >
            check_circle_outline_icon
          </Icon>
        </Tooltip>

        {getMessagePreview(element)}
        {element?.last_conversation_created_at && (
          <MDTypography
            variant="caption"
            fontWeight="regular"
            style={{ display: "block", fontSize: "11px", color: "#aaa" }}
          >
            {displayDate(element.last_conversation_created_at)}
          </MDTypography>
        )}
        {element?.connect_deal_notes.length > 0 && (
          <MDTypography variant="subtitle2" fontWeight="medium">
            Resubmitted Contact Inquiries:
            <MDIconButton
              tooltipName="View"
              aria-label="Open"
              color="info"
              onClick={(e: any) => openNotePopup(e, element)}
            >
              <Icon fontSize="small">visibility </Icon>
            </MDIconButton>
          </MDTypography>
        )}
      </CardContent>
      <CardActions sx={{ paddingLeft: "16px", paddingRight: "24px", justifyContent: "flex-start" }}>
        {element?.location?.name && (
          <Tooltip title={element?.location?.name} arrow>
            <MDTypography
              variant="body2"
              className="lead-tags-2"
              onClick={() => getLeadChatLocationDetails(element)}
              style={{
                whiteSpace: "nowrap",
                display: "inline-block",
                overflow: "hidden",
                textOverflow: "ellipsis",
              }}
            >
              {element?.location?.name}
            </MDTypography>
          </Tooltip>
        )}
        {applySort && (
          <Tooltip title={element?.status} arrow>
            <MDTypography
              variant="body2"
              className="lead-tags-2"
              style={{
                whiteSpace: "nowrap",
                display: "inline-block",
                overflow: "hidden",
                textOverflow: "ellipsis",
              }}
              onClick={() => getLeadChatStatusDetails && getLeadChatStatusDetails(element)}
            >
              {element?.status}
            </MDTypography>
          </Tooltip>
        )}
      </CardActions>
      {detailsPopup && elementId === element?.id && (
        <ViewNotesDetails
          detailsPopup={detailsPopup}
          onClose={() => onCloseNotePopup()}
          notesData={elementNote}
        />
      )}
    </Card>
  );

  if (viewType === "sort") {
    if (leads && leads.length > 0) {
      cards.push(
        <MDTypography variant="h5" className="deal-status-name">
          {statusDisp}
        </MDTypography>
      );
      leads.forEach((element: any) => {
        cards.push(cardItem(element));
      });
    }
  } else {
    return leads && leads?.length > 0 && leads?.map((element: any) => cardItem(element));
  }
  return cards;
}

export default NewViewLead;
