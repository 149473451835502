import { Grid, TextareaAutosize } from "@mui/material";
import MDTypography from "components/MDTypography";

function EmailSms(): JSX.Element {
  return (
    <>
      <Grid item xs={12} sm={12} md={9} lg={8}>
        <MDTypography variant="h6">Email</MDTypography>
        <TextareaAutosize
          aria-label="Email"
          minRows={5}
          placeholder="Email..."
          style={{ width: "100%", borderRadius: "5px", padding: "5px" }}
        />
      </Grid>
      <Grid item xs={12} sm={12} md={9} lg={8}>
        <MDTypography variant="h6">SMS</MDTypography>
        <TextareaAutosize
          aria-label="SMS"
          minRows={5}
          placeholder="SMS..."
          style={{ width: "100%", borderRadius: "5px", padding: "5px" }}
        />
      </Grid>
    </>
  );
}

export default EmailSms;
