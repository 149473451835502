import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import { Checkbox, FormControl, FormControlLabel, FormGroup, Switch } from "@mui/material";
import CustomErrorMessage from "components/CustomErrorMessage";

function Settings(props: any): JSX.Element {
  const { webPageDetail, onCheckboxChange } = props;

  return (
    <Card id="settings">
      <MDBox p={3}>
        <MDTypography variant="h5">Settings</MDTypography>
      </MDBox>
      <MDBox pb={3} px={3}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={12}>
            <FormControlLabel
              control={
                <Switch
                  checked={webPageDetail?.reserved ? true : false}
                  onChange={onCheckboxChange}
                  name="reserved"
                  inputProps={{ "aria-label": "controlled" }}
                />
              }
              label="Visible On Site"
              labelPlacement="end"
            />
            <CustomErrorMessage
              errorColor="inherit"
              message="If enabled, the user will have a Sola webpage, appear in the directory, and in searches."
            />
          </Grid>
          <Grid item xs={12} sm={6} md={6} lg={6}>
            <FormControl component="fieldset">
              <FormGroup aria-label="position">
                <FormControlLabel
                  value="end"
                  control={
                    <Checkbox
                      checked={webPageDetail?.accepting_new_clients || false}
                      name="accepting_new_clients"
                      onChange={onCheckboxChange}
                      inputProps={{ "aria-label": "controlled" }}
                    />
                  }
                  label="Accepting New Clients"
                  labelPlacement="end"
                />
              </FormGroup>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6} md={6} lg={6}>
            <FormControl component="fieldset">
              <FormGroup aria-label="position">
                <FormControlLabel
                  value="end"
                  control={
                    <Checkbox
                      checked={webPageDetail?.walkins || false}
                      name="walkins"
                      onChange={onCheckboxChange}
                      inputProps={{ "aria-label": "controlled" }}
                    />
                  }
                  label="Walkins"
                  labelPlacement="end"
                />
              </FormGroup>
            </FormControl>
          </Grid>
        </Grid>
      </MDBox>
    </Card>
  );
}

export default Settings;
