import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

// @mui material components
import { Grid, Card, Stepper, Step, StepLabel } from "@mui/material";

// Material Dashboard 2 PRO React TS components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";

// Wizard page components
import PersonalContact from "layouts/pages/lease/components/personal-contact";
import Mailing from "layouts/pages/lease/components/mailing";
import LeaseSummary from "layouts/pages/lease/components/lease-summary";
import LeaseAgreement from "layouts/pages/lease/components/lease-agreement";
import BillingInformation from "layouts/pages/lease/components/billing-information";
import BillingAddress from "layouts/pages/lease/components/billing-address";
import Review from "layouts/pages/lease/components/review";
import Header from "layouts/pages/lease/components/header";
import Validations from "helper/validations";
import PageLayout from "examples/LayoutContainers/PageLayout";
import { useAppDispatch, useAppSelector } from "store/store";
import { getLease, updateLease } from "store/thunk/leaseThunk";
import { updatePreferences } from "store/thunk/authThunk";
import { userRoles, getPreferenceValue } from "helper/services";

const steps = [
  "Personal Contact",
  "Mailing",
  "Lease summary",
  "Lease Agreement",
  "Billing Information",
  "Billing Address",
  "Review",
];

function Index(): JSX.Element {
  const appDispatch = useAppDispatch();
  const navigate = useNavigate();
  const { leaseData } = useAppSelector((state) => state.leaseSlice);
  const { userData } = useAppSelector((state) => state.authReducer);
  const [activeStep, setActiveStep] = useState<number>(0);
  const [leaseDetails, setLeaseDetails] = useState<any>({});
  const [errors, setErrors] = useState<any>({});
  const [filedChanged, setFiledChanged] = useState<boolean>(false);
  const [updateLead, setUpdateLead] = useState<boolean>(false);

  const isLastStep: boolean = activeStep === steps.length - 1;

  useEffect(() => {
    if (userData && userData.user_id) {
      if (userData.role === userRoles.SOLA_PRO_USER) {
        appDispatch(getLease({ user_id: userData?.user_id }));
        const leaseStatus = getPreferenceValue(userData, "LEASE_WIZARD");
        if (leaseStatus) {
          setUpdateLead(true);
        }
      } else {
        navigate("/home");
      }
    } else {
      navigate("/sign-in");
    }
  }, [userData]);

  useEffect(() => {
    if (leaseData) {
      setLeaseDetails(leaseData);
    }
  }, [leaseData]);

  const changeActiveStep = () => {
    setActiveStep(activeStep + 1);
    setFiledChanged(false);
  };

  const handleNext = () => {
    const error = Validations.validateLease({ ...leaseDetails, step: activeStep });
    setErrors(error);
    if (!Object.keys(error).length) {
      if (filedChanged) {
        const request = {
          callback: !isLastStep ? changeActiveStep : false,
          lease: { user_id: userData?.user_id, leaseData: leaseDetails },
        };
        appDispatch(updateLease(request));
      } else {
        changeActiveStep();
      }
    }
  };
  const handleBack = () => setActiveStep(activeStep - 1);

  const onChangeField = (event: any) => {
    setLeaseDetails({ ...leaseDetails, [event.target.name]: event.target.value });
    setFiledChanged(true);
  };

  const onChangeSelect = (name: string, value: any) => {
    setLeaseDetails({ ...leaseDetails, [name]: value });
    setFiledChanged(true);
  };

  const onChangeRadio = (e: any) => {
    setLeaseDetails({ ...leaseDetails, [e.target.name]: e.target.checked });
    setFiledChanged(true);
  };

  const onChangeDate = (fieldName: string, value: any) => {
    setLeaseDetails({ ...leaseDetails, [fieldName]: value });
    setFiledChanged(true);
  };

  const updateLeasePreference = () => {
    appDispatch(
      updatePreferences({
        user_id: userData?.user_id,
        preferences: [{ preference_type: "LEASE_WIZARD", preference_value: "pending" }],
        lease_wizard: navigate,
      })
    );
  };

  function getStepContent(stepIndex: number): JSX.Element {
    const attrs = {
      leaseDetails,
      onChangeField,
      onChangeSelect,
      onChangeRadio,
      onChangeDate,
      errors,
    };
    switch (stepIndex) {
      case 0:
        return <PersonalContact {...attrs} />;
      case 1:
        return <Mailing {...attrs} />;
      case 2:
        return <LeaseSummary {...attrs} />;
      case 3:
        return <LeaseAgreement {...attrs} />;
      case 4:
        return <BillingInformation {...attrs} />;
      case 5:
        return <BillingAddress {...attrs} />;
      case 6:
        return <Review {...attrs} />;
      default:
        return null;
    }
  }

  return (
    <PageLayout width="100%">
      <Header showHomeBtn={updateLead} />
      <MDBox pb={8} mt={2}>
        <Grid container justifyContent="center" sx={{ my: 4 }}>
          <Grid item xs={12} lg={8}>
            <MDBox mt={8} mb={6} textAlign="center">
              <MDBox mb={1}>
                <MDTypography variant="h4" fontWeight="bold">
                  Lease Application
                </MDTypography>
              </MDBox>
              <MDTypography variant="h6" fontWeight="regular" color="secondary">
                This information will let us know more about you.
              </MDTypography>
            </MDBox>
            <Card>
              <MDBox mt={-3} mx={2}>
                <Stepper activeStep={activeStep} alternativeLabel className="lease-stepper">
                  {steps.map((label) => (
                    <Step key={label}>
                      <StepLabel>{label}</StepLabel>
                    </Step>
                  ))}
                </Stepper>
              </MDBox>
              <MDBox p={2}>
                <MDBox>
                  {getStepContent(activeStep)}
                  <MDBox mt={3} width="100%" display="flex" justifyContent="space-between">
                    {activeStep === 0 ? (
                      <MDBox />
                    ) : (
                      <MDButton variant="outlined" color="dark" onClick={handleBack}>
                        back
                      </MDButton>
                    )}
                    <MDButton
                      variant="gradient"
                      color="dark"
                      onClick={!isLastStep ? handleNext : updateLeasePreference}
                      disabled={(isLastStep && !leaseDetails.agreement) || false}
                    >
                      {isLastStep ? "Save" : "next"}
                    </MDButton>
                  </MDBox>
                </MDBox>
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
    </PageLayout>
  );
}

export default Index;
