import { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Grid from "@mui/material/Grid";
import moment from "moment";
import MDButton from "components/MDButton";
import MDBox from "components/MDBox";
import Validation from "helper/validations";
import General from "layouts/pages/locations/cms-locations/general";
import Contact from "layouts/pages/locations/cms-locations/contact";
import Address from "layouts/pages/locations/cms-locations/address";
import Promotions from "layouts/pages/locations/cms-locations/promotions";
import Social from "layouts/pages/locations/cms-locations/social";
import TrackingCode from "layouts/pages/locations/cms-locations/tracking-code";
import Images from "layouts/pages/locations/cms-locations/images";
import Rockbot from "layouts/pages/locations/cms-locations/rockbot";
import RentManager from "layouts/pages/locations/cms-locations/rent-manager";
import Tours360 from "layouts/pages/locations/cms-locations/tours360";
import TextEmailUntegration from "layouts/pages/locations/cms-locations/text-email-untegration";
import { createCmsLocation, getCmsLocation } from "store/thunk/locationThunk";
import { setCmsLocation } from "store/slices/locationSlice";
import { useAppDispatch, useAppSelector } from "store/store";
import { scrollToErrorByClass, systemAdmin } from "helper/services";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Sidenav from "layouts/pages/locations/cms-locations/sidenav";
import CustomBackButton from "components/CustomBackButton";

function AddUpdate(props: any): JSX.Element {
  const { from } = props;
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const locationState: any = useLocation();
  const { cmsLocation } = useAppSelector((state) => state.locationSlice);
  const [errors, setErrors] = useState<any>({});
  const [locationDetail, setLocationDetail] = useState<any>({});
  const [imagePreview, setImagePreview] = useState<any>([]);
  const [floorPlanImage, setFloorPlanImage] = useState<any>(null);

  useEffect(() => {
    if (locationState?.state?.id) {
      dispatch(getCmsLocation({ id: locationState?.state?.id }));
    } else {
      setLocationDetail(null);
      setFloorPlanImage(null);
      setImagePreview([]);
      dispatch(setCmsLocation({}));
    }
  }, [locationState]);

  useEffect(() => {
    if (cmsLocation?.location && Object.keys(cmsLocation?.location).length > 0) {
      const tempDetails: any = JSON.parse(JSON.stringify(cmsLocation.location));
      if (cmsLocation.location?.msa_id) {
        tempDetails.msa = {
          id: cmsLocation.location?.msa_id,
          name: cmsLocation.location?.msa_name,
        };
      }
      if (cmsLocation.location?.admin_id) {
        tempDetails.franchisee = {
          id: cmsLocation.location?.admin_id,
          email: cmsLocation.location?.franchisee,
        };
      }
      if (cmsLocation.location?.walkins_end_of_day) {
        tempDetails.walkins_end_of_day = moment(cmsLocation.location?.walkins_end_of_day).utcOffset(
          0
        );
      }
      if (cmsLocation.location?.country_id) {
        tempDetails.countries = {
          id: cmsLocation.location?.country_id,
          name: cmsLocation.location?.country_name ? cmsLocation.location?.country_name : "",
        };
      }
      setFloorPlanImage(tempDetails?.floorplan_image_url || "");
      const images: any = [];
      for (let i = 1; i <= 20; i++) {
        images[i] = tempDetails?.[`image_${i}_url`] || "";
      }
      setImagePreview(images);
      setLocationDetail(tempDetails);
    } else {
      setLocationDetail(null);
      setFloorPlanImage(null);
      setImagePreview([]);
    }
  }, [cmsLocation]);

  const onCallBack = () => {
    if (!from) {
      dispatch(setCmsLocation(null));
      navigate(`/sola-cms/cms-locations`);
    }
  };

  const onSave = () => {
    const location = {
      ...locationDetail,
      msa_id: locationDetail?.msa?.id || "",
      admin_id: locationDetail?.franchisee?.id || "",
      country_id: locationDetail?.countries?.id || "",
      walkins_end_of_day: locationDetail?.walkins_end_of_day
        ? moment(locationDetail?.walkins_end_of_day)?.format("HH:mm")
        : "",
    };
    const errors: any = Validation.validateCmsLocationWizard(locationDetail);
    setErrors(errors);
    if (!Object.keys(errors).length) {
      dispatch(createCmsLocation({ location, callback: onCallBack }));
    } else {
      scrollToErrorByClass(errors);
    }
  };

  const onChangeAutoComplete = (name: string, value: any) => {
    setLocationDetail({ ...locationDetail, [name]: value });
  };

  const onChangeField = (event: any) => {
    setLocationDetail({ ...locationDetail, [event.target.name]: event.target.value });
  };

  const onChangeTimeField = (value: any) => {
    setLocationDetail({
      ...locationDetail,
      walkins_end_of_day: value ? moment(value) : "",
    });
  };

  const onChangeImage = async (files: any, name: any, key: any) => {
    if (files[0]) {
      const file = files[0];
      const reader = new FileReader();
      reader.onload = function (e) {
        if (key) {
          const tempImgPreview = JSON.parse(JSON.stringify(imagePreview));
          tempImgPreview[key] = e.target.result;
          setImagePreview(tempImgPreview);
        } else {
          setFloorPlanImage(e.target.result);
        }
      };
      setLocationDetail({ ...locationDetail, [name]: file, [`delete_${name}`]: 0 });
      reader.readAsDataURL(file);
    }
  };

  const onImageRemove = async (e: any, name: string, key: string) => {
    e.stopPropagation();
    if (key) {
      const tempImgPreview = JSON.parse(JSON.stringify(imagePreview));
      tempImgPreview[key] = null;
      setImagePreview(tempImgPreview);
    } else {
      setFloorPlanImage("");
    }
    setLocationDetail({ ...locationDetail, [name]: null, [`delete_${name}`]: 1 });
  };

  const onChangeDescription = (val: any, delta: any, source: any) => {
    if (source === "user") {
      setLocationDetail({ ...locationDetail, description: val });
    }
  };

  const attr = {
    locationDetail,
    onChangeDescription,
    errors,
    imagePreview,
    floorPlanImage,
    onChangeField,
    onChangeTimeField,
    onChangeAutoComplete,
    onChangeImage,
    onImageRemove,
    from,
    setLocationDetail,
  };

  const getConntent = () => (
    <>
      {!from && (
        <>
          <DashboardNavbar />
          <MDBox mx={2} onClick={onCallBack}>
            <CustomBackButton
              label="Back To Locations"
              historyState={{
                prevPath: "/sola-cms/cms-locations",
              }}
            />
          </MDBox>
        </>
      )}
      <Grid container spacing={3} mt={1}>
        <Grid item xs={12} lg={3}>
          <Sidenav />
        </Grid>
        <Grid item xs={12} lg={9}>
          <MDBox mb={3}>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <General {...attr} />
              </Grid>
              <Grid item xs={12}>
                <Contact {...attr} />
              </Grid>
              <Grid item xs={12}>
                <Address {...attr} />
              </Grid>
              <Grid item xs={12}>
                <Promotions {...attr} />
              </Grid>
              <Grid item xs={12}>
                <Social {...attr} />
              </Grid>
              <Grid item xs={12}>
                <TrackingCode {...attr} />
              </Grid>
              <Grid item xs={12}>
                <Images {...attr} />
              </Grid>
              <Grid item xs={12}>
                <Rockbot {...attr} />
              </Grid>
              {systemAdmin() && (
                <Grid item xs={12}>
                  <RentManager {...attr} />
                </Grid>
              )}
              <Grid item xs={12}>
                <Tours360 {...attr} />
              </Grid>
              <Grid item xs={12}>
                <TextEmailUntegration {...attr} />
              </Grid>
              <Grid item xs={12} mt={1} textAlign="right">
                <MDButton
                  variant="gradient"
                  color="info"
                  size="small"
                  onClick={onSave}
                  className="fixed-button"
                >
                  {locationDetail?.id ? "Update" : "Save"}
                </MDButton>
              </Grid>
            </Grid>
          </MDBox>
        </Grid>
      </Grid>
    </>
  );
  return (
    <>
      {from && getConntent()}
      {!from && <DashboardLayout>{getConntent()}</DashboardLayout>}
    </>
  );
}

export default AddUpdate;
