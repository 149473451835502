import { useState } from "react";

import MDBox from "components/MDBox";
import MDDialog from "components/MDDialog";
import MDTypography from "components/MDTypography";
import AccountSetup from "layouts/pages/sola-pros/account-setup-wizard";

function Index(): JSX.Element {
  const [open, setOpen] = useState(false);

  const dialogProps = {
    open,
    onClose: () => setOpen(false),
    title: "Setup",
    size: "xl",
    saveBtn: false,
  };

  return (
    <MDBox ml={2}>
      <MDTypography
        variant="a"
        color="info"
        onClick={() => setOpen(true)}
        fontSize="14px"
        sx={{ cursor: "pointer" }}
      >
        Account Setup
      </MDTypography>
      {open && (
        <MDDialog {...dialogProps}>
          <AccountSetup />
        </MDDialog>
      )}
    </MDBox>
  );
}

export default Index;
