import { useEffect, useState } from "react";
import MDDialog from "components/MDDialog";
import { Grid } from "@mui/material";
import MDTypography from "components/MDTypography";
import { getLocalDateTime, phoneNumberformat } from "helper/services";
import MDBox from "components/MDBox";

function ViewDetails(props: any): JSX.Element {
  const { open, onClose, viewInquiry } = props;
  const [loading] = useState<boolean>(false);
  const [partnerInquiriesData, setPartnerInquiriesData] = useState<any>({});

  useEffect(() => {
    if (viewInquiry) {
      setPartnerInquiriesData((prev: any) => ({
        ...prev,
        subject: viewInquiry.subject,
        name: viewInquiry.name,
        company_name: viewInquiry.company_name,
        email: viewInquiry.email,
        phone: viewInquiry.phone,
        message: viewInquiry.message,
        request_url: viewInquiry.request_url,
        created_at: viewInquiry.created_at,
        updated_at: viewInquiry.updated_at,
      }));
    }
  }, []);

  const dialogProps = {
    open,
    onClose,
    saveBtn: false,
    title: "Partner Inquiry Info",
    size: "md",
    loading,
  };

  return (
    <MDDialog {...dialogProps}>
      <Grid container p="0 10px">
        <Grid container>
          <Grid item xs={12} sm={6} md={6}>
            <MDTypography fontWeight="bold" variant="button">
              Subject
            </MDTypography>
            <MDBox>
              <MDTypography variant="button">{partnerInquiriesData?.subject || "-"}</MDTypography>
            </MDBox>
          </Grid>
          <Grid item xs={12} sm={6} md={6}>
            <MDTypography fontWeight="bold" variant="button">
              Name
            </MDTypography>
            <MDBox>
              <MDTypography variant="button">{partnerInquiriesData?.name || "-"}</MDTypography>
            </MDBox>
          </Grid>
        </Grid>
        <Grid container>
          <Grid item xs={12} sm={6} md={6}>
            <MDTypography fontWeight="bold" variant="button">
              Company name
            </MDTypography>
            <MDBox>
              <MDTypography variant="button">
                {partnerInquiriesData?.company_name || "-"}
              </MDTypography>
            </MDBox>
          </Grid>

          <Grid item xs={12} sm={6} md={6}>
            <MDTypography fontWeight="bold" variant="button">
              Email
            </MDTypography>
            <MDBox>
              <MDTypography variant="button">{partnerInquiriesData?.email || "-"}</MDTypography>
            </MDBox>
          </Grid>
        </Grid>
        <Grid container>
          <Grid item xs={12} sm={6} md={6}>
            <MDTypography fontWeight="bold" variant="button">
              Phone
            </MDTypography>
            <MDBox>
              <MDTypography variant="button">
                {phoneNumberformat(partnerInquiriesData.phone)}
              </MDTypography>
            </MDBox>
          </Grid>

          <Grid item xs={12} sm={6} md={6}>
            <MDTypography fontWeight="bold" variant="button">
              Message
            </MDTypography>
            <MDBox>
              <MDTypography variant="button">{partnerInquiriesData?.message || "-"}</MDTypography>
            </MDBox>
          </Grid>

          <Grid item xs={12} sm={6} md={6}>
            <MDTypography fontWeight="bold" variant="button">
              Request URL
            </MDTypography>
            <MDBox>
              <MDTypography variant="button">
                {partnerInquiriesData?.request_url || "-"}
              </MDTypography>
            </MDBox>
          </Grid>

          <Grid item xs={12} sm={6} md={6}>
            <MDTypography fontWeight="bold" variant="button">
              Created At
            </MDTypography>
            <MDBox>
              <MDTypography variant="button">
                {getLocalDateTime(partnerInquiriesData?.created_at)}
              </MDTypography>
            </MDBox>
          </Grid>

          <Grid item xs={12} sm={6} md={6}>
            <MDTypography fontWeight="bold" variant="button">
              Updated At
            </MDTypography>
            <MDBox>
              <MDTypography variant="button">
                {getLocalDateTime(partnerInquiriesData?.updated_at)}
              </MDTypography>
            </MDBox>
          </Grid>
        </Grid>
      </Grid>
    </MDDialog>
  );
}

export default ViewDetails;
