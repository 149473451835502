import {
  FormControl,
  Card,
  Autocomplete,
  TextField,
  Grid,
  Typography,
  Tooltip,
  IconButton,
} from "@mui/material";
import InfoIcon from "@mui/icons-material/Info";
import MDBox from "components/MDBox";
import {
  performanceKeys,
  revenueKeys,
  salonInformation,
  SalonInformationProps,
  revenueKeysValues,
  revenueKeysQuartile,
  revenueKeysRank,
  performanceKeysQuartile,
  performanceKeysRank,
  performanceKeysValues,
} from "helper/reports";
import { AvailableLocation, SelectedDate, SelectedDateRange } from "..";
import ScorecardTitle from "./scorecardTitle";
import ScorecardSalonInfo from "./scorecardSalonInfo";
import RenderDetails from "./renderDetails";
import ScorecardDateInput from "../components/scorecardDateInput";

export interface ScorecardDate {
  value: string;
}

interface ScorecardProps {
  dateArr: ScorecardDate[];
  availableLocation: AvailableLocation[];
  disableFields: boolean;
  // eslint-disable-next-line no-unused-vars
  handleOnLocationChange: (e: any) => void;
  selectedLocation: AvailableLocation;
  selectedScorecard: { [key: string]: number | string };
  // eslint-disable-next-line no-unused-vars
  onChangeDateField: (event: any) => void;
  selectedDate: SelectedDate;
  selectedDateRange: SelectedDateRange;
  // eslint-disable-next-line no-unused-vars
  setSelectedDateRange: (selectedDateRange: SelectedDateRange) => void;
  // eslint-disable-next-line no-unused-vars
  setSelectedDate: (selectedDate: SelectedDate) => void;
  currentDateCheckBox: boolean;
  // eslint-disable-next-line no-unused-vars
  setCurrentDateCheckBox: (currentDateCheckBox: boolean) => void;
}

function Scorecard({
  dateArr,
  availableLocation,
  disableFields,
  handleOnLocationChange,
  selectedLocation,
  selectedScorecard,
  onChangeDateField,
  selectedDate,
  selectedDateRange,
  setSelectedDateRange,
  setSelectedDate,
  currentDateCheckBox,
  setCurrentDateCheckBox,
}: ScorecardProps): JSX.Element {
  return (
    <MDBox py={3} sx={{ marginTop: { md: 1 } }}>
      <Card sx={{ padding: "20px" }}>
        <MDBox sx={{ display: "flex", alignItems: "end", marginBottom: { xs: 2, md: 3 } }}>
          <ScorecardDateInput
            selectedDate={selectedDate}
            onChangeDateField={onChangeDateField}
            dateArr={dateArr}
            selectedDateRange={selectedDateRange}
            setSelectedDateRange={setSelectedDateRange}
            setSelectedDate={setSelectedDate}
            currentDateCheckBox={currentDateCheckBox}
            setCurrentDateCheckBox={setCurrentDateCheckBox}
          />
          <MDBox sx={{ marginBottom: "9px" }}>
            <FormControl
              sx={{
                backgroundColor: "#fff",
                borderRadius: "10px",
                width: { xs: "100%", md: "150px" },
              }}
              disabled={disableFields}
            >
              <Autocomplete
                freeSolo
                disablePortal
                id="combo-box-demo"
                options={availableLocation}
                onSelect={handleOnLocationChange}
                sx={{ width: 300 }}
                value={selectedLocation ? selectedLocation.location : ""}
                renderInput={(params) => (
                  <TextField
                    size="small"
                    label="Salon name"
                    onChange={handleOnLocationChange}
                    {...params}
                  />
                )}
              />
            </FormControl>
          </MDBox>
        </MDBox>
        <ScorecardTitle title="Salon Information" />
        <MDBox sx={{ padding: "5px 0px 0px 20px" }}>
          {selectedScorecard &&
            salonInformation.map((info: SalonInformationProps) => (
              <ScorecardSalonInfo
                title={info.title}
                value={selectedScorecard[info.key].toString()}
              />
            ))}
          <MDBox sx={{ display: "flex", flexDirection: "row", marginBottom: { md: 1 } }}>
            <Grid item xs={4}>
              <Typography variant="body2">
                <MDBox fontWeight="fontWeightMedium" display="inline">
                  SSS Eligible
                  <Tooltip title="This location is older than 18 months" arrow={false}>
                    <IconButton>
                      <InfoIcon />
                    </IconButton>
                  </Tooltip>
                </MDBox>
              </Typography>
            </Grid>
            <Grid item xs={8}>
              <Typography variant="body2">{selectedScorecard.eligible}</Typography>
            </Grid>
          </MDBox>
        </MDBox>
        <ScorecardTitle title="Revenue & Volume Metrics" />
        <MDBox mt={1}>
          <RenderDetails
            keys={revenueKeys}
            selectedScorecard={selectedScorecard}
            column1={revenueKeysValues}
            column2={revenueKeysRank}
            column3={revenueKeysQuartile}
          />
        </MDBox>
        <ScorecardTitle title="Key Perfomance Indicators (KPIs)" />
        <MDBox mt={1}>
          <RenderDetails
            keys={performanceKeys}
            selectedScorecard={selectedScorecard}
            column1={performanceKeysValues}
            column2={performanceKeysRank}
            column3={performanceKeysQuartile}
          />
        </MDBox>
      </Card>
    </MDBox>
  );
}

export default Scorecard;
