import { Grid, Typography } from "@mui/material";
import MDBox from "components/MDBox";

interface ScorecardSalonInfoProps {
  title: string;
  value: string;
}

export default function ScorecardSalonInfo({ title, value }: ScorecardSalonInfoProps) {
  return (
    <MDBox sx={{ display: "flex", flexDirection: "row", marginBottom: { md: 1 } }}>
      <Grid item xs={4}>
        <Typography variant="body2">
          <MDBox fontWeight="fontWeightMedium" display="inline">
            {title}
          </MDBox>
        </Typography>
      </Grid>
      <Grid item xs={8}>
        <Typography variant="body2">{value}</Typography>
      </Grid>
    </MDBox>
  );
}
