import { useEffect } from "react";
import { Link } from "react-router-dom";
import { Grid } from "@mui/material";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Header from "layouts/pages/profile/components/Header";
import GeneralInfo from "layouts/pages/profile/profile-overview/components/generalInfo";
import ProAccountSetup from "layouts/pages/profile/pro-account-setup";
import { useAppDispatch, useAppSelector } from "store/store";
import { getUser } from "store/thunk/authThunk";
import { getAuthUser, userRoles } from "helper/services";

function Overview(): JSX.Element {
  const dispatch = useAppDispatch();
  const { userData } = useAppSelector((state) => state.authReducer);
  const authUser = getAuthUser();

  useEffect(() => {
    if (authUser) {
      dispatch(getUser({ user_id: authUser.user_id }));
    }
  }, []);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox mb={2} />
      <Header>
        <MDBox mt={5} mb={3}>
          <Grid container spacing={1}>
            <Grid item xs={12} md={12} xl={12}>
              <GeneralInfo userData={userData} />
            </Grid>
            <Grid item xs={12} md={6} xl={6}>
              {authUser && authUser?.role === userRoles.SOLA_PRO_USER && (
                <>
                  <MDBox ml={2} mb={2}>
                    <Link to="/lease">
                      <MDTypography variant="a" color="info" fontSize="14px">
                        Update Lease
                      </MDTypography>
                    </Link>
                  </MDBox>
                  <ProAccountSetup />
                </>
              )}
            </Grid>
          </Grid>
        </MDBox>
      </Header>
    </DashboardLayout>
  );
}

export default Overview;
