import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import { Grid, IconButton, Icon, styled, Card } from "@mui/material";
import MuiAccordion, { AccordionProps } from "@mui/material/Accordion";
import MuiAccordionSummary, { AccordionSummaryProps } from "@mui/material/AccordionSummary";
import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import * as React from "react";

const Accordion = styled((props: AccordionProps) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  "&:before": {
    display: "none",
  },
}));

const AccordionSummary = styled((props: AccordionSummaryProps) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: "0.9rem" }} />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === "dark" ? "rgba(255, 255, 255, .05)" : "rgba(0, 0, 0, .03)",
  flexDirection: "row-reverse",
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(90deg)",
  },
  "& .MuiAccordionSummary-content": {
    marginLeft: theme.spacing(1),
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: "1px solid rgba(0, 0, 0, .125)",
}));

function Testimonials(props: any): JSX.Element {
  const { webPageDetail, setWebPageDetails, onChangeMultipleField, errors, expanded, setExpanded } =
    props;

  const handleChangeAccordian =
    (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : false);
    };

  const onTestimonialRemove = (event: any, key: any) => {
    const data = webPageDetail?.[key] ? JSON.parse(JSON.stringify(webPageDetail[key])) : {};
    const data1: any = { id: data.id, is_deleted: 1 };
    setWebPageDetails({ ...webPageDetail, [key]: data1 });
    setExpanded("panel1");
  };

  const displayItems = (i: any): JSX.Element => {
    const items: any = [];
    const key = `testimonial_${i}`;
    const errClassName = `${key}_text`;
    items.push(
      <Accordion
        expanded={expanded === errClassName}
        sx={{ marginTop: "20px", marginLeft: "15px", width: "100%" }}
        id={errClassName}
        className={errClassName}
        onChange={handleChangeAccordian(errClassName)}
        key={key}
      >
        <AccordionSummary aria-controls="testimonial-content" id={errClassName}>
          <MDTypography variant="h6">Testimonial {i}</MDTypography>
        </AccordionSummary>
        <AccordionDetails>
          <Grid item xs={12} sm={12} md={12} key={i}>
            <Grid container>
              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                display="flex"
                justifyContent="space-between"
                alignItems="center"
              >
                <MDTypography variant="body2" fontWeight="regular" />
                <IconButton
                  aria-label="Delete"
                  color="error"
                  className="removeBtnClass"
                  onClick={(e: any) => onTestimonialRemove(e, key)}
                >
                  <Icon fontSize="small">delete</Icon>
                </IconButton>
              </Grid>
            </Grid>
            <Grid container spacing={3}>
              <Grid item md={4} sm={12} xs={12} lg={4}>
                <MDInput
                  variant="standard"
                  label="Name"
                  fullWidth
                  name="name"
                  placeholder="Name"
                  onChange={(e: any) => onChangeMultipleField(e, key)}
                  value={webPageDetail?.[key]?.name || ""}
                  error={errors && errors[`${key}_name`] ? true : false}
                  helperText={errors && errors[`${key}_name`]}
                />
              </Grid>
              <Grid item md={4} sm={12} xs={12} lg={4}>
                <MDInput
                  variant="standard"
                  label="Region"
                  fullWidth
                  name="region"
                  placeholder="Region"
                  onChange={(e: any) => onChangeMultipleField(e, key)}
                  value={webPageDetail?.[key]?.region || ""}
                  error={errors && errors[`${key}_region`] ? true : false}
                  helperText={errors && errors[`${key}_region`]}
                />
              </Grid>
              <Grid item md={4} sm={12} xs={12} lg={4}>
                <MDInput
                  variant="standard"
                  label="Title"
                  fullWidth
                  name="title"
                  placeholder="Title"
                  onChange={(e: any) => onChangeMultipleField(e, key)}
                  value={webPageDetail?.[key]?.title || ""}
                  error={errors && errors[`${key}_title`] ? true : false}
                  helperText={errors && errors[`${key}_title`]}
                />
              </Grid>
              <Grid item md={12} sm={12} xs={12} lg={12}>
                <MDInput
                  multiline
                  rows="2"
                  variant="standard"
                  label="Text"
                  fullWidth
                  name="text"
                  placeholder="Text"
                  onChange={(e: any) => onChangeMultipleField(e, key)}
                  value={webPageDetail?.[key]?.text || ""}
                  error={errors && errors[`${key}_text`] ? true : false}
                  helperText={errors && errors[`${key}_text`]}
                />
              </Grid>
            </Grid>
          </Grid>
        </AccordionDetails>
      </Accordion>
    );
    return items;
  };

  return (
    <Card id="testimonials" sx={{ overflow: "visible" }}>
      <MDBox p={3}>
        <MDTypography variant="h5">Testimonials</MDTypography>
      </MDBox>
      <MDBox component="form" pb={3} px={3}>
        <Grid container spacing={2}>
          {displayItems(1)}
          {displayItems(2)}
          {displayItems(3)}
          {displayItems(4)}
          {displayItems(5)}
          {displayItems(6)}
          {displayItems(7)}
          {displayItems(8)}
          {displayItems(9)}
          {displayItems(10)}
        </Grid>
      </MDBox>
    </Card>
  );
}

export default Testimonials;
