import {
  Grid,
  InputLabel,
  Paper,
  Autocomplete,
  Select,
  MenuItem,
  FormControl,
} from "@mui/material";

import MDBox from "components/MDBox";
import MDInput from "components/MDInput";
import MDTypography from "components/MDTypography";
import CustomPhoneInput from "components/CustomPhoneInput";
import FormField from "layouts/pages/account/components/FormField";
import MDDatePicker from "components/MDDatePicker";
import CustomErrorMessage from "components/CustomErrorMessage";

function Index(props: any): JSX.Element {
  const { leaseDetails, onChangeField, onChangeSelect, onChangeDate, errors } = props;
  const services = ["Service 1", "Service 2", "Service 3"];
  return (
    <MDBox>
      <MDBox textAlign="center" mx="auto" my={4}>
        <MDBox mb={1}>
          <MDTypography variant="h5" fontWeight="regular">
            Personal contact
          </MDTypography>
        </MDBox>
      </MDBox>
      <MDBox mt={2}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <MDInput
              label="First Name"
              variant="standard"
              fullWidth
              name="first_name"
              placeholder="First Name"
              onChange={onChangeField}
              value={leaseDetails?.first_name || ""}
              error={errors && errors.first_name ? true : false}
              helperText={errors && errors.first_name}
              required
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <MDInput
              label="Last Name"
              variant="standard"
              fullWidth
              name="last_name"
              placeholder="Last Name"
              onChange={onChangeField}
              value={leaseDetails?.last_name || ""}
              error={errors && errors.last_name ? true : false}
              helperText={errors && errors.last_name}
              required
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <MDInput
              type="email"
              label="Email"
              variant="standard"
              fullWidth
              name="email"
              placeholder="Email"
              onChange={onChangeField}
              value={leaseDetails?.email || ""}
              error={errors && errors.email ? true : false}
              helperText={errors && errors.email}
              required
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <InputLabel>
              Phone <span className="required_star">*</span>
            </InputLabel>
            <MDInput
              variant="standard"
              fullWidth
              name="phone"
              placeholder="(000) 000-0000"
              id="phone-input"
              InputProps={{
                inputComponent: CustomPhoneInput as any,
              }}
              value={leaseDetails?.phone}
              error={errors && errors.phone ? true : false}
              helperText={errors && errors.phone}
              onChange={onChangeField}
              required
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormControl>
              <MDTypography variant="button">
                Date of Birth <span className="required_star">*</span>
              </MDTypography>
              <MDDatePicker
                options={{
                  maxDate: new Date(),
                  dateFormat: "M d, Y",
                }}
                onChange={([date]: any) => onChangeDate("dob", date)}
                name="dob"
                value={leaseDetails?.dob || null}
              />
              <CustomErrorMessage message={errors && errors.dob} />
            </FormControl>
          </Grid>
        </Grid>
        <Paper sx={{ padding: "10px", marginTop: "15px", marginBottom: "15px" }}>
          <Grid container>
            <Grid item>
              <MDTypography variant="h6" fontWeight="regular">
                Emergency Contact Information
              </MDTypography>
            </Grid>
            <Grid item container spacing={2}>
              <Grid item xs={12} sm={6}>
                <MDInput
                  label="Name"
                  variant="standard"
                  fullWidth
                  name="emergency_contact_name"
                  placeholder="Name"
                  onChange={onChangeField}
                  value={leaseDetails?.emergency_contact_name || ""}
                  error={errors && errors.emergency_contact_name ? true : false}
                  helperText={errors && errors.emergency_contact_name}
                  required
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <MDInput
                  label="Relationship"
                  variant="standard"
                  fullWidth
                  name="emergency_relationship"
                  placeholder="Relationship"
                  onChange={onChangeField}
                  value={leaseDetails?.emergency_relationship || ""}
                  error={errors && errors.emergency_relationship ? true : false}
                  helperText={errors && errors.emergency_relationship}
                  required
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <InputLabel>
                  Emergency Phone Number<span className="required_star">*</span>
                </InputLabel>
                <MDInput
                  variant="standard"
                  fullWidth
                  name="emergency_phonenumber"
                  placeholder="(000) 000-0000"
                  id="emergency_phonenumber"
                  InputProps={{
                    inputComponent: CustomPhoneInput as any,
                  }}
                  value={leaseDetails?.emergency_phonenumber}
                  error={errors && errors.emergency_phonenumber ? true : false}
                  helperText={errors && errors.emergency_phonenumber}
                  onChange={onChangeField}
                  required
                />
              </Grid>
            </Grid>
          </Grid>
        </Paper>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <MDInput
              label="Professional License Number"
              variant="standard"
              fullWidth
              name="professional_license_lumber"
              placeholder="Professional License Number"
              onChange={onChangeField}
              value={leaseDetails?.professional_license_lumber || ""}
              error={errors && errors.professional_license_lumber ? true : false}
              helperText={errors && errors.professional_license_lumber}
              required
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Autocomplete
              options={services}
              multiple
              value={leaseDetails?.services || []}
              onChange={(e: any, value: any) => onChangeSelect("services", value)}
              renderInput={(params: any) => (
                <FormField
                  {...params}
                  label="Services"
                  name="services"
                  InputLabelProps={{ shrink: true }}
                  error={errors && errors.services ? true : false}
                  helperText={errors && errors.services}
                  required
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <MDInput
              label="Referred By"
              variant="standard"
              fullWidth
              name="referred_by"
              placeholder="Referred By"
              onChange={onChangeField}
              value={leaseDetails?.referred_by || ""}
              error={errors && errors.referred_by ? true : false}
              helperText={errors && errors.referred_by}
              required
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormControl fullWidth>
              <InputLabel id="hear-about-label">
                How Did You Hear About Sola?<span className="required_star">*</span>
              </InputLabel>
              <Select
                id="hear-about-sola"
                label="How Did You Hear About Sola?*"
                value={leaseDetails?.hear_about_sola || ""}
                name="hear_about_sola"
                onChange={onChangeField}
                error={errors && errors.hear_about_sola ? true : false}
                className="height45"
              >
                <MenuItem value="Google/Search Engine">Google/Search Engine</MenuItem>
                <MenuItem value="Social Media">Social Media</MenuItem>
                <MenuItem value="Television">Television</MenuItem>
                <MenuItem value="Radio">Radio</MenuItem>
                <MenuItem value="Newspaper">Newspaper</MenuItem>
                <MenuItem
                  value="Word of mouth
"
                >
                  Word of mouth
                </MenuItem>
                <MenuItem value="Other">Other</MenuItem>
              </Select>
            </FormControl>
          </Grid>
        </Grid>
      </MDBox>
    </MDBox>
  );
}

export default Index;
