import { createAsyncThunk } from "@reduxjs/toolkit";
import { notificationSuccess, notificationFail } from "store/slices/notificationSlice";
import { setLoading } from "store/slices/authSlice";
import axios from "axios";
import config from "config/config";
import Messages from "helper/messages";
import { allInquiriesList } from "store/slices/partnerInquiriesSlice";
import {
  msaList,
  locationList,
  sola10kImagesList,
  mysolaImageList,
  countriesList,
  setReportsList,
  setContactInquiriesList,
  setStylistMessages,
  setMaintenanceContact,
  setCategoryList,
  setTagList,
  setUpdateSolaWebsitesList,
  setAllStylists,
  setStylistUnitsList,
  setSearchResultStylist,
  setLegacyAdmins,
  setRmSearchResultStylist,
  setMatchingRentManager,
  setMatchingStylist,
  setExternalList,
  setTestimonialsList,
  setCallFireLogList,
  setGloassGeniusLogList,
  setNavEventsList,
  setRmUnitListList,
  setRMEventsList,
  setContactIquiryData,
  faqsList,
  amenitiesList,
  setHelpDeskReqList,
  setStylistDataForApprovalReq,
  setTabWiseSolaProList,
  setFrenchiseeRmMatchData,
  setAnalyticsList,
} from "store/slices/systemAdminSlice";
import {
  getErrorMessage,
  getValue,
  getAssociationLocations,
  getAllAssociationLocations,
  // formatDate,
  getFilterURL,
} from "helper/services";
import { allBookingList, setBookingDetails } from "store/slices/bookNowBokingSlice";
import moment from "moment";
import apiClient from "config/apiClient";
import slsApiClient from "config/slsApiClient";
import platformApiClient from "config/platformClient";
import { getCustomerLeads, getLeads } from "./leaseThunk";

/* Get Gloass Genius Logs list */
export const getGloassGeniusLogList = createAsyncThunk(
  "getGloassGeniusLogList",
  async (_request: any, { dispatch }) => {
    const page = _request.pageIndex || 0;
    const size = _request.pageSize || 50;
    const search = _request.search || "";
    const sortBy = _request.sort || [];
    const filterBy = _request.filterData || [];
    dispatch(setLoading(true));
    try {
      let url = `gloss_genius_logs?page=${page + 1}&per_page=${size}`;
      if (search) {
        url += `&search=${search}`;
      }
      if (sortBy?.length) {
        url += `&field=${sortBy[0]?.id}&order_by=${sortBy[0]?.desc ? "desc" : "asc"}`;
      }
      if (filterBy && filterBy.length) {
        url = getFilterURL(url, filterBy);
      }
      const response = await apiClient().get(url);
      if (response) {
        dispatch(setGloassGeniusLogList(response?.data || []));
        dispatch(setLoading(false));
      }
    } catch (error: any) {
      dispatch(notificationFail(error.message || Messages.ERROR.NO_DATA_FOUND));
      dispatch(setLoading(false));
    }
  }
);

/* Get Gloass Nav Events list */
export const getNavEventsList = createAsyncThunk(
  "getNavEventsList",
  async (_request: any, { dispatch }) => {
    const page = _request.pageIndex || 0;
    const size = _request.pageSize || 50;
    const search = _request.search || "";
    const sortBy = _request.sort || [];
    const filterBy = _request.filterData || [];
    dispatch(setLoading(true));
    try {
      let url = `events?page=${page + 1}&per_page=${size}`;
      if (search) {
        url += `&search=${search}`;
      }
      if (sortBy?.length) {
        url += `&field=${sortBy[0]?.id}&order_by=${sortBy[0]?.desc ? "desc" : "asc"}`;
      }
      if (filterBy && filterBy.length) {
        url = getFilterURL(url, filterBy);
      }
      const response = await apiClient().get(url);
      if (response) {
        dispatch(setNavEventsList(response?.data || []));
        dispatch(setLoading(false));
      }
    } catch (error: any) {
      dispatch(notificationFail(error.message || Messages.ERROR.NO_DATA_FOUND));
      dispatch(setLoading(false));
    }
  }
);

/* Get rm unit list */
export const getRmUnitsList = createAsyncThunk(
  "getNavEventsList",
  async (_request: any, { dispatch }) => {
    const page = _request.pageIndex || 0;
    const size = _request.pageSize || 50;
    const search = _request.search || "";
    const sortBy = _request.sort || [];
    const filterBy = _request.filterData || [];
    dispatch(setLoading(true));
    try {
      let url = `rent_manager_units?page=${page + 1}&per_page=${size}`;
      if (search) {
        url += `&search=${search}`;
      }
      if (sortBy?.length) {
        url += `&field=${sortBy[0]?.id}&order_by=${sortBy[0]?.desc ? "desc" : "asc"}`;
      }
      if (filterBy && filterBy.length) {
        url = getFilterURL(url, filterBy);
      }
      const response = await apiClient().get(url);
      if (response) {
        dispatch(setRmUnitListList(response?.data || []));
        dispatch(setLoading(false));
      }
    } catch (error: any) {
      dispatch(notificationFail(error.message || Messages.ERROR.NO_DATA_FOUND));
      dispatch(setLoading(false));
    }
  }
);

/**
 * Get MSAs list
 */
export const getMsaList = createAsyncThunk("getMsaList", async (_request: any, { dispatch }) => {
  dispatch(setLoading(true));
  const page = _request.pageIndex || 0;
  const size = _request.pageSize || 50;
  const search = _request.search || "";
  const sortBy = _request.sort || [];
  const filterBy = _request.filterData || [];
  try {
    let url = `msas?page=${page}&per_page=${size}`;
    if (search) {
      url += `&search=${search}`;
    }
    if (sortBy?.length) {
      url += `&field=${sortBy[0]?.id}&order_by=${sortBy[0]?.desc ? "desc" : "asc"}`;
    }
    if (filterBy && filterBy.length) {
      url = getFilterURL(url, filterBy);
    }
    const response = await slsApiClient().get(`${config.API_URL}${url}`);
    if (response) {
      dispatch(
        msaList({
          msas: response?.data?.data,
          meta: response?.data?.meta,
        })
      );
      dispatch(setLoading(false));
    }
  } catch (error: any) {
    dispatch(notificationFail(error.message || Messages.ERROR.NO_DATA_FOUND));
    dispatch(setLoading(false));
  }
});

export const saveMsa = createAsyncThunk("saveMsa", async (_request: any, { dispatch }) => {
  dispatch(setLoading(true));
  const { msaData, onClose, pageNo, perPage } = _request;
  try {
    const response = await slsApiClient().post(`${config.API_URL}msas`, {
      msa: msaData,
    });
    if (response) {
      dispatch(setLoading(false));
      onClose();
      dispatch(notificationSuccess(Messages.SUCCESS.MSA_CREATE_SUCCESS));
      dispatch(getMsaList({ pageIndex: pageNo, pageSize: perPage }));
    } else {
      dispatch(notificationFail(response?.data?.message || Messages.ERROR.MSA_CREATE_ERROR));
    }
  } catch (error: any) {
    dispatch(setLoading(false));
    if (error?.response?.data?.error) {
      const message = getErrorMessage(error?.response?.data?.error);
      dispatch(notificationFail(message));
    } else {
      dispatch(notificationFail(error?.response?.data?.message || Messages.ERROR.MSA_CREATE_ERROR));
    }
  }
});

export const updateMsa = createAsyncThunk("updateMsa", async (_request: any, { dispatch }) => {
  dispatch(setLoading(true));
  const { msaData, onClose, pageNo, perPage, search, sortBy } = _request;
  try {
    const response = await slsApiClient().put(`${config.API_URL}msas`, {
      msa: msaData,
    });
    if (response) {
      dispatch(setLoading(false));
      dispatch(notificationSuccess(Messages.SUCCESS.MSA_UPDATE_SUCCESS));
      dispatch(getMsaList({ pageIndex: pageNo, pageSize: perPage, search, sort: sortBy }));
      onClose();
    } else {
      dispatch(notificationFail(response?.data?.message || Messages.ERROR.MSA_UPDATE_ERROR));
    }
  } catch (error: any) {
    dispatch(setLoading(false));
    if (error?.response?.data?.error) {
      const message = getErrorMessage(error?.response?.data?.error);
      dispatch(notificationFail(message));
    } else {
      dispatch(notificationFail(error?.response?.data?.message || Messages.ERROR.MSA_UPDATE_ERROR));
    }
  }
});

export const deleteMsaRecord = createAsyncThunk(
  "deleteMsa",
  async (_request: any, { dispatch }) => {
    dispatch(setLoading(true));
    const {
      msaData,
      onCloseDeleteModal,
      pageNo,
      perPage,
      search,
      sortBy,
      removeSelectedItem = null,
    } = _request;
    try {
      const response = await slsApiClient().delete(`${config.API_URL}msas/${msaData.id}`);
      dispatch(setLoading(false));
      if (response) {
        onCloseDeleteModal();
        if (removeSelectedItem) {
          removeSelectedItem(msaData.id);
        }
        dispatch(notificationSuccess(Messages.SUCCESS.MSA_DELETE_SUCCESS));
        dispatch(getMsaList({ pageIndex: pageNo, pageSize: perPage, search, sort: sortBy }));
      } else {
        dispatch(notificationFail(response?.data?.message || Messages.ERROR.MSA_DELETE_ERROR));
      }
    } catch (error: any) {
      if (error?.response?.data?.error) {
        const message = getErrorMessage(error?.response?.data?.error);
        dispatch(notificationFail(message));
      } else {
        dispatch(
          notificationFail(error?.response?.data?.message || Messages.ERROR.MSA_DELETE_ERROR)
        );
      }
    }
  }
);

/**
 * Get all records of partner inquiries
 */
export const getAllPartnerInquiries = createAsyncThunk(
  "getAllPartnerInquiries",
  async (_request: any, { dispatch }) => {
    dispatch(setLoading(true));
    const page = _request.pageIndex || 0;
    const size = _request.pageSize || 50;
    const search = _request.search || "";
    const sortBy = _request.sort || [];
    const filterBy = _request.filterData || [];
    try {
      let url = `partner_inquiries?page=${page + 1}&per_page=${size}`;
      if (search) {
        url += `&search=${search}`;
      }
      if (sortBy?.length) {
        url += `&field=${sortBy[0]?.id}&order_by=${sortBy[0]?.desc ? "desc" : "asc"}`;
      }
      if (filterBy && filterBy.length) {
        url = getFilterURL(url, filterBy);
      }
      const response = await apiClient().get(url);
      if (response) {
        dispatch(setLoading(false));
        dispatch(allInquiriesList(response?.data || []));
      }
    } catch (error: any) {
      dispatch(allInquiriesList(null));
      dispatch(setLoading(false));
      dispatch(notificationFail(error.message || Messages.ERROR.GET_INQUIRIES_ERROR));
    }
  }
);

/**
 * Create Partner Inquiries
 */
export const createPartnerInquiries = createAsyncThunk(
  "createPartnerInquiries",
  (_request: any, { dispatch }) => {
    dispatch(setLoading(true));
    const { partnerInquiriesData, onClose, pageNo, perPage } = _request;
    axios
      .post(`${config.BACKEND_API_URL}partner_inquiries`, partnerInquiriesData, {
        headers: {
          "Access-Control-Allow-Origin": "*",
          "api-key": config.BACKEND_API_KEY,
        },
      })
      .then((response) => {
        dispatch(setLoading(false));
        if (response?.status === 200) {
          onClose();
          dispatch(notificationSuccess(Messages.SUCCESS.INQUIRY_CREATE_SUCCESS));
          dispatch(getAllPartnerInquiries({ pageIndex: pageNo, pageSize: perPage }));
        } else {
          dispatch(
            notificationFail(response?.data?.message || Messages.ERROR.INQUIRY_CREATE_ERROR)
          );
        }
      })
      .catch((error) => {
        dispatch(setLoading(false));
        if (error?.response?.status === 400) {
          const message = getErrorMessage(error?.message);
          dispatch(notificationFail(message));
        } else {
          dispatch(notificationFail(error?.message || Messages.ERROR.INQUIRY_CREATE_ERROR));
        }
      });
  }
);

/**
 * Update Partner Inquiries
 */
export const updatePartnerInquiries = createAsyncThunk(
  "updatePartnerInquiries",
  (_request: any, { dispatch }) => {
    dispatch(setLoading(true));
    const { id, partnerInquiriesData, onClose, pageNo, perPage, search, sortBy } = _request;
    axios
      .put(`${config.BACKEND_API_URL}partner_inquiries/${id}`, partnerInquiriesData, {
        headers: {
          "Access-Control-Allow-Origin": "*",
          "api-key": config.BACKEND_API_KEY,
        },
      })
      .then((response) => {
        dispatch(setLoading(false));
        if (response?.status === 200) {
          onClose();
          dispatch(notificationSuccess(Messages.SUCCESS.INQUIRY_UPDATE_SUCCESS));
          dispatch(
            getAllPartnerInquiries({ pageIndex: pageNo, pageSize: perPage, search, sort: sortBy })
          );
        } else {
          dispatch(
            notificationFail(response?.data?.message || Messages.ERROR.INQUIRY_UPDATE_ERROR)
          );
        }
      })
      .catch((error) => {
        dispatch(setLoading(false));
        if (error?.response?.status === 400) {
          const message = getErrorMessage(error?.message);
          dispatch(notificationFail(message));
        } else {
          dispatch(notificationFail(error?.message || Messages.ERROR.INQUIRY_UPDATE_ERROR));
        }
      });
  }
);

/**
 * Delete Partner Inquiry
 */
export const deleteInquiry = createAsyncThunk("deleteInquiry", (_request: any, { dispatch }) => {
  const { pageNo, perPage, search, sortBy, removeSelectedItem = null } = _request;
  dispatch(setLoading(true));
  axios
    .delete(`${config.BACKEND_API_URL}partner_inquiries/${_request.id}`, {
      headers: {
        "Access-Control-Allow-Origin": "*",
        "api-key": config.BACKEND_API_KEY,
      },
    })
    .then((response) => {
      dispatch(setLoading(false));
      if (removeSelectedItem) {
        removeSelectedItem(_request.id);
      }
      if (response?.data?.message) {
        _request.setDeleteInquiryId(false);
        dispatch(notificationSuccess(response?.data?.message));
        dispatch(
          getAllPartnerInquiries({ pageIndex: pageNo, pageSize: perPage, search, sort: sortBy })
        );
      } else {
        dispatch(notificationFail(response?.data?.message || Messages.ERROR.INQUIRY_DELETE_ERROR));
      }
    })
    .catch((error) => {
      dispatch(setLoading(false));
      dispatch(notificationFail(error?.message || Messages.ERROR.INQUIRY_DELETE_ERROR));
    });
});

/**
 * Get all Book now Booking records
 */
export const getAllBooking = createAsyncThunk(
  "getAllBooking",
  async (_request: any, { dispatch }) => {
    dispatch(setLoading(true));
    const page = _request.pageIndex || 0;
    const size = _request.pageSize || 50;
    const search = _request.search || "";
    const sortBy = _request.sort || [];
    const filterBy = _request.filterData || [];
    try {
      let url = `book_now_bookings?page=${page + 1}&per_page=${size}`;
      if (search) {
        url += `&search=${search}`;
      }
      if (sortBy?.length) {
        url += `&field=${sortBy[0]?.id}&order_by=${sortBy[0]?.desc ? "desc" : "asc"}`;
      }
      if (filterBy && filterBy.length) {
        url = getFilterURL(url, filterBy);
      }
      const response = await apiClient().get(url);
      if (response) {
        dispatch(setLoading(false));
        dispatch(allBookingList(response?.data || []));
      }
    } catch (error: any) {
      dispatch(allBookingList(null));
      dispatch(setLoading(false));
      dispatch(notificationFail(error?.message || Messages.ERROR.SOMETHING_WENT_WRONG));
    }
  }
);

/**
 * Get details of Book Now Booking for particular id
 */
export const getBookingDetails = createAsyncThunk(
  "getBookingDetails",
  (_request: any, { dispatch }) => {
    dispatch(setLoading(true));
    axios
      .get(`${config.BACKEND_API_URL}book_now_bookings/${_request.id}`, {
        headers: {
          "Access-Control-Allow-Origin": "*",
          "api-key": config.BACKEND_API_KEY,
        },
      })
      .then((response) => {
        dispatch(setLoading(false));
        if (response?.status === 200) {
          dispatch(setBookingDetails(response?.data));
        } else {
          dispatch(notificationFail(response?.data?.message || Messages.ERROR.GET_INQUIRIES_ERROR));
        }
      })
      .catch((error) => {
        dispatch(setLoading(false));
        dispatch(notificationFail(error?.message || Messages.ERROR.NO_DATA_FOUND));
      });
  }
);

/**
 * Create Book now Booking
 */
export const createBookNowBooking = createAsyncThunk(
  "createBookNowBooking",
  (_request: any, { dispatch }) => {
    dispatch(setLoading(false));
    const { bookNowBookingData, onClose } = _request;
    bookNowBookingData.time_range = `${moment(bookNowBookingData.from).format(
      "hh:mm A"
    )} - ${moment(bookNowBookingData.to).format("hh:mm A")}`;
    const regExp = /\{|\}/g;
    const hasBrackets = regExp.test(bookNowBookingData.services);
    if (bookNowBookingData.services && hasBrackets) {
      bookNowBookingData.services = JSON.parse(bookNowBookingData.services);
    }

    const reqData = {
      booking: {
        time_range: bookNowBookingData.time_range,
        location_id: bookNowBookingData.location_id.id,
        query: bookNowBookingData.query,
        services: bookNowBookingData.services,
        stylist_id: bookNowBookingData.stylist_id.id,
        booking_user_name: bookNowBookingData.booking_user_name,
        booking_user_email: bookNowBookingData.booking_user_email,
        booking_user_phone: bookNowBookingData.booking_user_phone,
        referring_url: bookNowBookingData.referring_url,
        total: bookNowBookingData.total,
      },
    };
    axios
      .post(`${config.BACKEND_API_URL}book_now_bookings`, reqData, {
        headers: {
          "Access-Control-Allow-Origin": "*",
          "api-key": config.BACKEND_API_KEY,
        },
      })
      .then((response) => {
        dispatch(setLoading(false));
        if (response?.status === 200) {
          onClose();
          dispatch(notificationSuccess(Messages.SUCCESS.BOOKING_CREATE_SUCCESS));
          dispatch(getAllBooking({}));
        } else {
          dispatch(
            notificationFail(response?.data?.message || Messages.ERROR.BOOKING_CREATE_ERROR)
          );
        }
      })
      .catch((error) => {
        dispatch(setLoading(false));
        if (error?.response?.status === 400) {
          const message = getErrorMessage(error?.message);
          dispatch(notificationFail(message));
        } else {
          dispatch(notificationFail(error?.message || Messages.ERROR.BOOKING_CREATE_ERROR));
        }
      });
  }
);

/**
 * Update Book Now Booking
 */
export const updateBookNowBooking = createAsyncThunk(
  "updateBookNowBooking",
  async (_request: any, { dispatch }) => {
    dispatch(setLoading(false));
    const { bookNowBookingData, onClose, pageNo, perPage, search, sortBy } = _request;
    bookNowBookingData.time_range = `${moment(bookNowBookingData.from).format(
      "hh:mm A"
    )} - ${moment(bookNowBookingData.to).format("hh:mm A")}`;
    const regExp = /\{|\}/g;
    const hasBrackets = regExp.test(bookNowBookingData.services);
    if (bookNowBookingData.services && hasBrackets) {
      bookNowBookingData.services = JSON.parse(bookNowBookingData.services);
    }

    const reqData = {
      booking: {
        time_range: bookNowBookingData.time_range,
        location_id: bookNowBookingData.location_id.id,
        query: bookNowBookingData.query,
        services: bookNowBookingData.services,
        stylist_id: bookNowBookingData.stylist_id.id,
        booking_user_name: bookNowBookingData.booking_user_name,
        booking_user_email: bookNowBookingData.booking_user_email,
        booking_user_phone: bookNowBookingData.booking_user_phone,
        referring_url: bookNowBookingData.referring_url,
        total: bookNowBookingData.total,
      },
    };
    try {
      const response = await apiClient().put(`book_now_bookings/${bookNowBookingData.id}`, reqData);
      if (response) {
        onClose();
        dispatch(setLoading(false));
        dispatch(notificationSuccess(Messages.SUCCESS.BOOKING_UPDATE_SUCCESS));
        dispatch(getAllBooking({ pageIndex: pageNo, pageSize: perPage, search, sort: sortBy }));
      } else {
        dispatch(notificationFail(response?.data?.message || Messages.ERROR.BOOKING_UPDATE_ERROR));
      }
    } catch (error: any) {
      dispatch(setLoading(false));
      if (error?.response?.data?.error) {
        const message = getErrorMessage(error?.response?.data?.error);
        dispatch(notificationFail(message));
      } else {
        dispatch(
          notificationFail(error?.response?.data?.message || Messages.ERROR.BOOKING_UPDATE_ERROR)
        );
      }
    }
  }
);

export const deleteBooking = createAsyncThunk("deleteBooking", (_request: any, { dispatch }) => {
  const { pageNo, perPage, search, sortBy, removeSelectedItem = null } = _request;
  dispatch(setLoading(true));
  axios
    .delete(`${config.BACKEND_API_URL}book_now_bookings/${_request.id}`, {
      headers: {
        "Access-Control-Allow-Origin": "*",
        "api-key": config.BACKEND_API_KEY,
      },
    })
    .then((response: any) => {
      dispatch(setLoading(false));
      if (response?.status === 200) {
        _request.setDeleteBookingId(false);
        if (removeSelectedItem) {
          removeSelectedItem(_request.id);
        }
        dispatch(notificationSuccess(response?.message));
        dispatch(getAllBooking({ pageIndex: pageNo, pageSize: perPage, search, sort: sortBy }));
      } else {
        dispatch(notificationFail(response?.message || Messages.ERROR.BOOKING_DELETE_ERROR));
      }
    })
    .catch((error) => {
      dispatch(setLoading(false));
      dispatch(notificationFail(error?.message || Messages.ERROR.BOOKING_DELETE_ERROR));
    });
});

export const getLocationList = createAsyncThunk(
  "getLocationList",
  async (_request: any, { dispatch }) => {
    const { all } = _request;

    dispatch(setLoading(true));
    try {
      let response = null;

      if (all) response = await apiClient().get(`locations?all=${all}`);
      else response = await apiClient().get(`locations`);

      if (response) {
        dispatch(locationList(response?.data?.locations || []));
        dispatch(setLoading(false));
      }
    } catch (error: any) {
      dispatch(notificationFail(error.message || Messages.ERROR.NO_DATA_FOUND));
      dispatch(setLoading(false));
    }
  }
);

// get sola 10k images list
export const getSola10kImagesList = createAsyncThunk(
  "getSola10kImagesList",
  async (_request: any, { dispatch }) => {
    const page = _request.pageIndex || 0;
    const size = _request.pageSize || 50;
    const search = _request.search || "";
    const sortBy = _request.sort || [];
    const filterBy = _request.filterData || [];
    dispatch(setLoading(true));
    try {
      let url = `sola10k_images?page=${page + 1}&per_page=${size}`;
      if (search) {
        url += `&search=${search}`;
      }
      if (sortBy?.length) {
        url += `&field=${sortBy[0]?.id}&order_by=${sortBy[0]?.desc ? "desc" : "asc"}`;
      }
      if (filterBy && filterBy.length) {
        url = getFilterURL(url, filterBy);
      }
      const response = await apiClient().get(url);
      if (response) {
        dispatch(setLoading(false));
        dispatch(sola10kImagesList(response?.data || []));
      }
    } catch (error: any) {
      dispatch(setLoading(false));
      dispatch(notificationFail(error.message || Messages.ERROR.NO_DATA_FOUND));
    }
  }
);

export const saveSola10kImage = createAsyncThunk(
  "saveSola10kImage",
  async (_request: any, { dispatch }) => {
    dispatch(setLoading(true));
    const { solaImageData, onClose, isUpdate, pageNo, perPage, search, sortBy } = _request;
    const sola10kDataRequest = new FormData();

    if (isUpdate && solaImageData.id) {
      sola10kDataRequest.append("sola10k_image[id]", getValue(solaImageData?.id));
    }
    sola10kDataRequest.append("sola10k_image[name]", getValue(solaImageData?.name));
    sola10kDataRequest.append(
      "sola10k_image[instagram_handle]",
      getValue(solaImageData?.instagram_handle)
    );

    sola10kDataRequest.append("sola10k_image[approved]", getValue(solaImageData?.approved));
    sola10kDataRequest.append("sola10k_image[statement]", getValue(solaImageData?.statement));
    sola10kDataRequest.append("sola10k_image[image]", getValue(solaImageData?.original_image_url));

    sola10kDataRequest.append(
      "sola10k_image[generated_image]",
      getValue(solaImageData.generated_image_url)
    );

    try {
      const response = isUpdate
        ? await apiClient(true).put(`sola10k_images/${solaImageData.id}`, sola10kDataRequest)
        : await apiClient(true).post("sola10k_images", sola10kDataRequest);
      dispatch(setLoading(false));
      if (response) {
        onClose();
        if (isUpdate) {
          dispatch(
            notificationSuccess(response?.data?.message || Messages.SUCCESS.SOLA_10K_UPDATE_SUCCESS)
          );
          dispatch(
            getSola10kImagesList({ pageIndex: pageNo, pageSize: perPage, search, sort: sortBy })
          );
        } else {
          dispatch(
            notificationSuccess(response?.data?.message || Messages.SUCCESS.SOLA_10K_CREATE_SUCCESS)
          );
          dispatch(getSola10kImagesList({ pageIndex: pageNo, pageSize: perPage }));
        }
      } else {
        dispatch(notificationFail(response?.data?.message || Messages.ERROR.SOLA_10K_SAVE_ERROR));
      }
    } catch (error: any) {
      dispatch(setLoading(false));
      if (error?.response?.data?.error) {
        const message = getErrorMessage(error?.response?.data?.error);
        dispatch(notificationFail(message));
      } else {
        dispatch(
          notificationFail(error?.response?.data?.message || Messages.ERROR.SOLA_10K_SAVE_ERROR)
        );
      }
    }
  }
);

export const deleteSola10kRecord = createAsyncThunk(
  "deleteMsa",
  async (_request: any, { dispatch }) => {
    dispatch(setLoading(true));
    const {
      sola10kData,
      onCloseDeleteModal,
      pageNo,
      perPage,
      search,
      sortBy,
      removeSelectedItem = null,
    } = _request;
    try {
      const response = await apiClient(true).delete(`sola10k_images/${sola10kData.id}`);
      dispatch(setLoading(false));
      if (response) {
        onCloseDeleteModal();
        if (removeSelectedItem) {
          removeSelectedItem(sola10kData.id);
        }
        dispatch(notificationSuccess(Messages.SUCCESS.SOLA_10K_DELETE_SUCCESS));
        dispatch(
          getSola10kImagesList({ pageIndex: pageNo, pageSize: perPage, search, sort: sortBy })
        );
      } else {
        dispatch(notificationFail(response?.data?.message || Messages.ERROR.SOLA_10K_DELETE_ERROR));
      }
    } catch (error: any) {
      if (error?.response?.data?.error) {
        const message = getErrorMessage(error?.response?.data?.error);
        dispatch(notificationFail(message));
      } else {
        dispatch(
          notificationFail(error?.response?.data?.message || Messages.ERROR.SOLA_10K_DELETE_ERROR)
        );
      }
    }
  }
);

// get all my sola image list
export const getMysolaImageList = createAsyncThunk(
  "getMysolaImageList",
  async (_request: any, { dispatch }) => {
    dispatch(setLoading(true));
    const page = _request.pageIndex || 0;
    const size = _request.pageSize || 50;
    const search = _request.search || "";
    const sortBy = _request.sort || [];
    const filterBy = _request.filterData || [];
    try {
      let url = `my_sola_images?page=${page + 1}&per_page=${size}`;
      if (search) {
        url += `&search=${search}`;
      }
      if (sortBy?.length) {
        url += `&field=${sortBy[0]?.id}&order_by=${sortBy[0]?.desc ? "desc" : "asc"}`;
      }
      if (filterBy && filterBy.length) {
        url = getFilterURL(url, filterBy);
      }
      const response = await apiClient().get(url);
      dispatch(setLoading(false));
      if (response) {
        dispatch(mysolaImageList(response?.data || []));
      }
    } catch (error: any) {
      dispatch(notificationFail(error.message || Messages.ERROR.NO_DATA_FOUND));
      dispatch(setLoading(false));
    }
  }
);

export const saveMySolaImage = createAsyncThunk(
  "saveMySolaImage",
  async (_request: any, { dispatch }) => {
    try {
      const { mySolaImage, onClose, isUpdate, pageNo, perPage, search, sortBy } = _request;
      const requestData = new FormData();

      if (isUpdate && mySolaImage.id) {
        requestData.append("my_sola_image[id]", getValue(mySolaImage?.id));
      }
      requestData.append("my_sola_image[name]", getValue(mySolaImage?.name));
      requestData.append(
        "my_sola_image[instagram_handle]",
        getValue(mySolaImage?.instagram_handle)
      );

      requestData.append("my_sola_image[approved]", getValue(mySolaImage?.approved));
      requestData.append("my_sola_image[statement]", getValue(mySolaImage?.statement));
      requestData.append(
        "my_sola_image[statement_variant]",
        getValue(mySolaImage?.statement_variant)
      );
      requestData.append("my_sola_image[image]", getValue(mySolaImage?.original_image_url));
      requestData.append(
        "my_sola_image[generated_image]",
        getValue(mySolaImage?.generated_image_url)
      );

      if (mySolaImage?.delete_image && mySolaImage?.delete_image === 1) {
        requestData.append("my_sola_image[delete_image]", getValue(mySolaImage?.delete_image));
      } else {
        requestData.append("my_sola_image[delete_image]", "");
      }

      if (mySolaImage?.delete_carousel_image && mySolaImage?.delete_carousel_image === 1) {
        requestData.append(
          "my_sola_image[delete_carousel_image]",
          getValue(mySolaImage?.delete_carousel_image)
        );
      } else {
        requestData.append("my_sola_image[delete_carousel_image]", "");
      }

      dispatch(setLoading(true));
      const response = isUpdate
        ? await apiClient(true).put(`my_sola_images/${mySolaImage.id}`, requestData)
        : await apiClient(true).post("my_sola_images", requestData);
      dispatch(setLoading(false));
      if (response) {
        onClose();
        if (isUpdate) {
          dispatch(
            notificationSuccess(
              response?.data?.message || Messages.SUCCESS.SOLA_MY_IMAGE_UPDATE_SUCCESS
            )
          );
          dispatch(
            getMysolaImageList({ pageIndex: pageNo, pageSize: perPage, search, sort: sortBy })
          );
        } else {
          dispatch(
            notificationSuccess(
              response?.data?.message || Messages.SUCCESS.SOLA_MY_IMAGE_CREATE_SUCCESS
            )
          );
          dispatch(getMysolaImageList({ pageIndex: pageNo, pageSize: perPage }));
        }
      } else {
        dispatch(
          notificationFail(response?.data?.message || Messages.ERROR.SOLA_MY_IMAGE_SAVE_ERROR)
        );
      }
    } catch (error: any) {
      dispatch(setLoading(false));
      if (error?.response?.data?.error) {
        const message = getErrorMessage(error?.response?.data?.error);
        dispatch(notificationFail(message));
      } else {
        dispatch(
          notificationFail(
            error?.response?.data?.message || Messages.ERROR.SOLA_MY_IMAGE_SAVE_ERROR
          )
        );
      }
    }
  }
);

export const deleteMySolaImage = createAsyncThunk(
  "deleteMySolaImage",
  async (_request: any, { dispatch }) => {
    dispatch(setLoading(true));
    const {
      mySolaImageData,
      onCloseDeleteModal,
      pageNo,
      perPage,
      search,
      sortBy,
      removeSelectedItem = null,
    } = _request;
    try {
      const response = await apiClient().delete(`my_sola_images/${mySolaImageData.id}`);
      dispatch(setLoading(false));
      if (response) {
        onCloseDeleteModal();
        if (removeSelectedItem) {
          removeSelectedItem(mySolaImageData.id);
        }
        dispatch(notificationSuccess(Messages.SUCCESS.SOLA_MY_IMAGE_DELETE_SUCCESS));
        dispatch(
          getMysolaImageList({ pageIndex: pageNo, pageSize: perPage, search, sort: sortBy })
        );
      } else {
        dispatch(
          notificationFail(response?.data?.message || Messages.ERROR.SOLA_MY_IMAGE_DELETE_ERROR)
        );
      }
    } catch (error: any) {
      if (error?.response?.data?.error) {
        const message = getErrorMessage(error?.response?.data?.error);
        dispatch(notificationFail(message));
      } else {
        dispatch(setLoading(false));
        dispatch(
          notificationFail(
            error?.response?.data?.message || Messages.ERROR.SOLA_MY_IMAGE_DELETE_ERROR
          )
        );
      }
    }
  }
);
export const getCountriesList = createAsyncThunk(
  "getCountriesList",
  async (_request: any, { dispatch }) => {
    const { all } = _request;
    dispatch(setLoading(true));
    try {
      let response = null;

      if (all) response = await apiClient().get(`countries?all=${all}`);
      else response = await apiClient().get(`countries`);

      if (response) {
        dispatch(countriesList(response?.data || []));
        dispatch(setLoading(false));
      }
    } catch (error: any) {
      dispatch(notificationFail(error.message || Messages.ERROR.NO_DATA_FOUND));
      dispatch(setLoading(false));
    }
  }
);

/**
 * Get Report list
 */
export const getReportList = createAsyncThunk(
  "getReportList",
  async (_request: any, { dispatch }) => {
    const page = _request.pageIndex || 0;
    const size = _request.pageSize || 50;
    const search = _request.search || "";
    const sortBy = _request.sort || [];
    const filterBy = _request.filterData || [];
    dispatch(setLoading(true));
    try {
      let url = `reports?page=${page + 1}&per_page=${size}`;
      if (search) {
        url += `&search=${search}`;
      }
      if (sortBy?.length) {
        url += `&field=${sortBy[0]?.id}&order_by=${sortBy[0]?.desc ? "desc" : "asc"}`;
      }
      if (filterBy && filterBy.length) {
        url = getFilterURL(url, filterBy);
      }
      const response = await apiClient().get(url);
      if (response) {
        dispatch(setLoading(false));
        dispatch(setReportsList(response?.data || []));
      }
    } catch (error: any) {
      dispatch(setLoading(false));
      dispatch(notificationFail(error.message || Messages.ERROR.NO_DATA_FOUND));
    }
  }
);

/**
 * Create / Update report
 */
export const saveReports = createAsyncThunk("saveReports", async (_request: any, { dispatch }) => {
  dispatch(setLoading(true));
  const { reportData, onClose, isUpdate, pageNo, perPage, search, sortBy } = _request;
  const reqData = {
    report: {
      report_type: reportData.report_type.value,
      email_address: reportData.email_address,
      parameters: reportData.parameters,
    },
  };
  try {
    const response = isUpdate
      ? await apiClient().put(`reports/${reportData.id}`, reqData)
      : await apiClient().post("reports", reqData);
    if (response) {
      dispatch(setLoading(false));
      onClose();
      if (isUpdate) {
        dispatch(
          notificationSuccess(response?.data?.message || Messages.SUCCESS.REPORT_UPDATE_SUCCESS)
        );
        dispatch(getReportList({ pageIndex: pageNo, pageSize: perPage, search, sort: sortBy }));
      } else {
        dispatch(
          notificationSuccess(response?.data?.message || Messages.SUCCESS.REPORT_CREATE_SUCCESS)
        );
        dispatch(getReportList({ pageIndex: pageNo, pageSize: perPage }));
      }
    } else {
      dispatch(notificationFail(response?.data?.message || Messages.ERROR.REPORT_SAVE_ERROR));
    }
  } catch (error: any) {
    dispatch(setLoading(false));
    if (error?.response?.data?.error) {
      const message = getErrorMessage(error?.response?.data?.error);
      dispatch(notificationFail(message));
    } else {
      dispatch(
        notificationFail(error?.response?.data?.message || Messages.ERROR.REPORT_SAVE_ERROR)
      );
    }
  }
});

export const deleteReportRecord = createAsyncThunk(
  "deleteReportRecord",
  async (_request: any, { dispatch }) => {
    dispatch(setLoading(true));
    const {
      reportData,
      onCloseDeleteModal,
      pageNo,
      perPage,
      search,
      sortBy,
      removeSelectedItem = null,
    } = _request;
    try {
      const response = await apiClient().delete(`reports/${reportData.id}`);
      dispatch(setLoading(false));
      if (response) {
        onCloseDeleteModal();
        if (removeSelectedItem) {
          removeSelectedItem(reportData.id);
        }
        dispatch(notificationSuccess(Messages.SUCCESS.REPORT_DELETE_SUCCESS));
        dispatch(getReportList({ pageIndex: pageNo, pageSize: perPage, search, sort: sortBy }));
      } else {
        dispatch(notificationFail(response?.data?.message || Messages.ERROR.REPORT_DELETE_ERROR));
      }
    } catch (error: any) {
      if (error?.response?.data?.error) {
        const message = getErrorMessage(error?.response?.data?.error);
        dispatch(notificationFail(message));
      } else {
        dispatch(
          notificationFail(error?.response?.data?.message || Messages.ERROR.REPORT_DELETE_ERROR)
        );
      }
    }
  }
);

// get contact Inquiries list
export const getContactInquiriesList = createAsyncThunk(
  "getContactInquiriesList",
  async (_request: any, { dispatch }) => {
    dispatch(setLoading(true));
    const page = _request.pageIndex || 0;
    const size = _request.pageSize || 50;
    const search = _request.search || "";
    const sortBy = _request.sort || [];
    const filterBy = _request?.filterData || [];

    try {
      let url = `contact-inquiry-by-location?page=${page}&per_page=${size}`;
      if (search) {
        url += `&search=${search}`;
      }
      if (sortBy?.length) {
        url += `&field=${sortBy[0]?.id}&order_by=${sortBy[0]?.desc ? "desc" : "asc"}`;
      }
      if (filterBy && filterBy.length) {
        url = getFilterURL(url, filterBy);
      }
      const response = await slsApiClient().get(url, {
        params: getAssociationLocations(),
      });
      if (response) {
        dispatch(setContactInquiriesList(response?.data || []));
        dispatch(setLoading(false));
      } else {
        dispatch(
          notificationFail(response?.data?.message || Messages.ERROR.GET_CONTACT_INQUIRIES_ERROR)
        );
      }
    } catch (error: any) {
      dispatch(notificationFail(error.message || Messages.ERROR.NO_DATA_FOUND));
      dispatch(setLoading(false));
    }
  }
);

export const saveContactIquiries = createAsyncThunk(
  "saveContactIquiries",
  async (_request: any, { dispatch }) => {
    dispatch(setLoading(true));
    const {
      contactInquiriesData,
      onClose,
      callback,
      isUpdate,
      pageNo,
      perPage,
      search,
      sortBy,
      isLead = false,
      type = "",
    } = _request;
    try {
      const response = isUpdate
        ? await apiClient().put(`request_tour_inquiries/${contactInquiriesData.id}`, {
            request_tour_inquiry: contactInquiriesData,
          })
        : await apiClient().post("request_tour_inquiries", {
            request_tour_inquiry: contactInquiriesData,
          });
      dispatch(setLoading(false));
      if (response) {
        if (callback) {
          callback();
        }
        if (onClose) {
          onClose();
        }
        if (isUpdate) {
          dispatch(notificationSuccess(Messages.SUCCESS.CONTACT_INQUIRIES_UPDATE_SUCCESS));
          if (pageNo) {
            dispatch(
              getContactInquiriesList({
                pageIndex: pageNo,
                pageSize: perPage,
                search,
                sort: sortBy,
              })
            );
          }
        } else {
          dispatch(notificationSuccess(Messages.SUCCESS.CONTACT_INQUIRIES_CREATE_SUCCESS));
          if (pageNo) {
            dispatch(
              getContactInquiriesList({
                pageIndex: pageNo,
                pageSize: perPage,
                search,
                sort: sortBy,
              })
            );
          }
        }
        if (isLead) {
          dispatch(
            getCustomerLeads({
              locations: getAllAssociationLocations(),
              pageSize: perPage,
              pageIndex: pageNo,
              search,
              sort: sortBy,
              type,
            })
          );
        }
      } else {
        dispatch(
          notificationFail(response?.data?.message || Messages.ERROR.CONTACT_INQUIRIES_SAVE_ERROR)
        );
      }
    } catch (error: any) {
      dispatch(setLoading(false));
      if (error?.response?.data?.error) {
        const message = getErrorMessage(error?.response?.data?.error);
        dispatch(notificationFail(message));
      } else {
        dispatch(
          notificationFail(
            error?.response?.data?.message || Messages.ERROR.CONTACT_INQUIRIES_SAVE_ERROR
          )
        );
      }
    }
  }
);

export const deleteContactInquiries = createAsyncThunk(
  "deleteMsa",
  async (_request: any, { dispatch }) => {
    dispatch(setLoading(true));
    const {
      contactInquiriesData,
      onCloseDeleteModal,
      pageNo,
      perPage,
      search,
      sortBy,
      isLead = false,
      type = "",
      removeSelectedItem = null,
    } = _request;
    try {
      const response = await apiClient(true).delete(
        `request_tour_inquiries/${contactInquiriesData.id}`
      );
      dispatch(setLoading(false));
      if (response) {
        onCloseDeleteModal();
        if (removeSelectedItem) {
          removeSelectedItem(Number(contactInquiriesData?.id));
        }
        dispatch(notificationSuccess(Messages.SUCCESS.CONTACT_INQUIRIES_DELETE_SUCCESS));
        if (isLead) {
          dispatch(
            getLeads({
              locations: getAllAssociationLocations(),
              pageSize: perPage,
              pageIndex: pageNo,
              search,
              sort: sortBy,
              type,
            })
          );
        } else {
          dispatch(
            getContactInquiriesList({ pageIndex: pageNo, pageSize: perPage, search, sort: sortBy })
          );
        }
      } else {
        dispatch(
          notificationFail(response?.data?.message || Messages.ERROR.CONTACT_INQUIRIES_DELETE_ERROR)
        );
      }
    } catch (error: any) {
      if (error?.response?.data?.error) {
        const message = getErrorMessage(error?.response?.data?.error);
        dispatch(notificationFail(message));
      } else {
        dispatch(
          notificationFail(
            error?.response?.data?.message || Messages.ERROR.CONTACT_INQUIRIES_DELETE_ERROR
          )
        );
      }
    }
  }
);

/**
 * Get all stylist messages list
 */
export const getStylistMessages = createAsyncThunk(
  "getStylistMessages",
  async (_request: any, { dispatch }) => {
    dispatch(setLoading(true));
    const page = _request.pageIndex || 0;
    const size = _request.pageSize || 50;
    const search = _request.search || "";
    const sortBy = _request.sort || [];
    const filterBy = _request.filterData || [];
    try {
      let url = `stylist_messages?page=${page + 1}&per_page=${size}`;
      if (search) {
        url += `&search=${search}`;
      }
      if (sortBy?.length) {
        url += `&field=${sortBy[0]?.id}&order_by=${sortBy[0]?.desc ? "desc" : "asc"}`;
      }
      if (filterBy && filterBy.length) {
        url = getFilterURL(url, filterBy);
      }
      const response = await apiClient().get(url);
      if (response) {
        dispatch(setLoading(false));
        dispatch(setStylistMessages(response?.data || []));
      }
    } catch (error: any) {
      dispatch(setStylistMessages(null));
      dispatch(setLoading(false));
      dispatch(notificationFail(error.message || Messages.ERROR.GET_INQUIRIES_ERROR));
    }
  }
);

/**
 * Delete Stylist messages
 */
export const deleteStylistMessage = createAsyncThunk(
  "deleteStylistMessage",
  async (_request: any, { dispatch }) => {
    const { pageNo, perPage, search, sortBy, removeSelectedItem = null } = _request;
    dispatch(setLoading(true));
    try {
      const response = await apiClient().delete(`stylist_messages/${_request.id}`);
      dispatch(setLoading(false));
      if (response) {
        _request.setStylistMessageId(false);
        if (removeSelectedItem) {
          removeSelectedItem(_request.id);
        }
        dispatch(notificationSuccess(Messages.SUCCESS.STYLIST_MESSAGE_DELETE_SUCCESS));
        dispatch(
          getStylistMessages({ pageIndex: pageNo, pageSize: perPage, search, sort: sortBy })
        );
      } else {
        dispatch(
          notificationFail(response?.data?.message || Messages.ERROR.STYLIST_MESSAGE_DELETE_ERROR)
        );
      }
    } catch (error: any) {
      if (error?.response?.data?.error) {
        const message = getErrorMessage(error?.response?.data?.error);
        dispatch(notificationFail(message));
      } else {
        dispatch(
          notificationFail(
            error?.response?.data?.message || Messages.ERROR.STYLIST_MESSAGE_DELETE_ERROR
          )
        );
      }
    }
  }
);

/**
 * Get all Maintenance Contacts records
 */
export const getMaintenanceContactsList = createAsyncThunk(
  "getMaintenanceContactsList",
  async (_request: any, { dispatch }) => {
    dispatch(setLoading(true));
    const page = _request.pageIndex || 0;
    const size = _request.pageSize || 50;
    const search = _request.search || "";
    const sortBy = _request.sort || [];
    const filterBy = _request.filterData || [];
    try {
      let url = `maintenance_contacts?page=${page + 1}&per_page=${size}`;
      if (search) {
        url += `&search=${search}`;
      }
      if (sortBy?.length) {
        let sortField = sortBy[0]?.id;
        if (sortField === "location") {
          sortField = "locations.name";
        }
        url += `&field=${sortField}&order_by=${sortBy[0]?.desc ? "desc" : "asc"}`;
      }
      if (filterBy && filterBy.length) {
        url = getFilterURL(url, filterBy);
      }
      const response = await apiClient().get(url);
      if (response) {
        dispatch(setLoading(false));
        dispatch(setMaintenanceContact(response?.data || []));
      }
    } catch (error: any) {
      dispatch(setMaintenanceContact(null));
      dispatch(setLoading(false));
      dispatch(notificationFail(error?.message || Messages.ERROR.SOMETHING_WENT_WRONG));
    }
  }
);

/**
 * Save Maintenance Contacts
 */
export const saveMaintenanceContacts = createAsyncThunk(
  "saveMaintenanceContacts",
  async (_request: any, { dispatch }) => {
    dispatch(setLoading(true));
    const { maintenanceData, onClose, isUpdate, pageNo, perPage, search, sortBy } = _request;

    try {
      const response = isUpdate
        ? await apiClient().put(`maintenance_contacts/${maintenanceData.id}`, {
            connect_maintenance_contact: maintenanceData,
          })
        : await apiClient().post("maintenance_contacts", {
            connect_maintenance_contact: maintenanceData,
          });
      dispatch(setLoading(false));
      if (response) {
        onClose();
        if (isUpdate) {
          dispatch(notificationSuccess(Messages.SUCCESS.MAINTENANCE_CONTACT_UPDATE_SUCCESS));
          dispatch(
            getMaintenanceContactsList({
              pageIndex: pageNo,
              pageSize: perPage,
              search,
              sort: sortBy,
            })
          );
        } else {
          dispatch(notificationSuccess(Messages.SUCCESS.MAINTENANCE_CONTACT_CREATE_SUCCESS));
          dispatch(getMaintenanceContactsList({ pageIndex: pageNo, pageSize: perPage }));
        }
      } else {
        dispatch(
          notificationFail(response?.data?.message || Messages.ERROR.MAINTENANCE_CONTACT_SAVE_ERROR)
        );
      }
    } catch (error: any) {
      dispatch(setLoading(false));
      if (error?.response?.data?.error) {
        const message = getErrorMessage(error?.response?.data?.error);
        dispatch(notificationFail(message));
      } else {
        dispatch(
          notificationFail(
            error?.response?.data?.message || Messages.ERROR.MAINTENANCE_CONTACT_SAVE_ERROR
          )
        );
      }
    }
  }
);

/**
 * Delete Maintenance Contact record
 */
export const deleteMaintenanceContact = createAsyncThunk(
  "deleteMaintenanceContact",
  async (_request: any, { dispatch }) => {
    dispatch(setLoading(true));
    const {
      contactData,
      onCloseDeleteModal,
      pageNo,
      perPage,
      search,
      sortBy,
      removeSelectedItem = null,
    } = _request;
    try {
      const response = await apiClient().delete(`maintenance_contacts/${contactData.id}`);
      dispatch(setLoading(false));
      if (response) {
        onCloseDeleteModal();
        if (removeSelectedItem) {
          removeSelectedItem(contactData.id);
        }
        dispatch(notificationSuccess(Messages.SUCCESS.MAINTENANCE_CONTACT_DELETE_SUCCESS));
        dispatch(
          getMaintenanceContactsList({ pageIndex: pageNo, pageSize: perPage, search, sort: sortBy })
        );
      } else {
        dispatch(
          notificationFail(
            response?.data?.message || Messages.ERROR.MAINTENANCE_CONTACT_DELETE_ERROR
          )
        );
      }
    } catch (error: any) {
      if (error?.response?.data?.error) {
        const message = getErrorMessage(error?.response?.data?.error);
        dispatch(notificationFail(message));
      } else {
        dispatch(
          notificationFail(
            error?.response?.data?.message || Messages.ERROR.MAINTENANCE_CONTACT_DELETE_ERROR
          )
        );
      }
    }
  }
);

// get all categories list
export const getCategoryList = createAsyncThunk(
  "getCategoryList",
  async (_request: any, { dispatch }) => {
    const { all } = _request;

    dispatch(setLoading(true));
    try {
      let response = null;

      if (all) response = await apiClient().get(`categories?all=${all}`);
      else response = await apiClient().get(`categories`);

      if (response) {
        dispatch(setCategoryList(response?.data || []));
        dispatch(setLoading(false));
      }
    } catch (error: any) {
      dispatch(notificationFail(error.message || Messages.ERROR.NO_DATA_FOUND));
      dispatch(setLoading(false));
    }
  }
);

// get all tags list
export const getTagList = createAsyncThunk("getTagList", async (_request: any, { dispatch }) => {
  const { all } = _request;

  dispatch(setLoading(true));
  try {
    let response = null;

    if (all) response = await apiClient().get(`tags?all=${all}`);
    else response = await apiClient().get(`tags`);

    if (response) {
      dispatch(setTagList(response?.data || []));
      dispatch(setLoading(false));
    }
  } catch (error: any) {
    dispatch(notificationFail(error.message || Messages.ERROR.NO_DATA_FOUND));
    dispatch(setLoading(false));
  }
});

/**
 * Get all stylists
 */
export const getAllStylist = createAsyncThunk(
  "getAllStylist",
  async (_request: any, { dispatch }) => {
    const { page, pageSize, search } = _request;
    dispatch(setLoading(true));
    try {
      let url = `stylists?fields[]=id&fields[]=name&page=${page || 1}&per_page=${
        pageSize || 50
      }&status=active`;
      if (search) {
        url += `&search=${search}`;
      }
      const response = await apiClient().get(url);
      if (response) {
        dispatch(setAllStylists(response?.data || []));
      }
      dispatch(setLoading(false));
    } catch (error: any) {
      dispatch(setLoading(false));
      dispatch(notificationFail(error.message || Messages.ERROR.NO_DATA_FOUND));
    }
  }
);

/**
 * Save / update stylist messages
 */
export const saveStylistMessages = createAsyncThunk(
  "saveStylistMessages",
  async (_request: any, { dispatch }) => {
    dispatch(setLoading(true));
    const { stylistMessagesData, onClose, pageNo, perPage, search, sortBy, id } = _request;
    const reqData = {
      name: stylistMessagesData?.name || "",
      email: stylistMessagesData?.email || "",
      phone: stylistMessagesData?.phone || "",
      message: stylistMessagesData?.message || "",
      stylist_id: stylistMessagesData?.stylist?.id || "",
    };
    try {
      const response = id
        ? await apiClient().put(`stylist_messages/${id}`, reqData)
        : await apiClient().post("stylist_messages", reqData);
      if (response) {
        dispatch(setLoading(false));
        onClose();
        if (id) {
          dispatch(notificationSuccess(Messages.SUCCESS.STYLIST_MESSAGE_UPDATE_SUCCESS));
          dispatch(
            getStylistMessages({ pageIndex: pageNo, pageSize: perPage, search, sort: sortBy })
          );
        } else {
          dispatch(notificationSuccess(Messages.SUCCESS.STYLIST_MESSAGE_CREATE_SUCCESS));
          dispatch(getStylistMessages({ pageIndex: pageNo, pageSize: perPage }));
        }
      } else {
        dispatch(
          notificationFail(response?.data?.message || Messages.ERROR.STYLIST_MESSAGE_SAVE_ERROR)
        );
      }
    } catch (error: any) {
      dispatch(setLoading(false));
      if (error?.response?.data?.error) {
        const message = getErrorMessage(error?.response?.data?.error);
        dispatch(notificationFail(message));
      } else {
        dispatch(
          notificationFail(
            error?.response?.data?.message || Messages.ERROR.STYLIST_MESSAGE_SAVE_ERROR
          )
        );
      }
    }
  }
);

/**
 * Get List of Update my website
 */
export const getUpdateMyWebsiteList = createAsyncThunk(
  "getUpdateMyWebsiteList",
  async (_request: any, { dispatch }) => {
    const { status } = _request;
    const page = _request.pageIndex || 0;
    const size = _request.pageSize || 50;
    const search = _request.search || "";
    const sortBy = _request.sort || [];
    const filterBy = _request.filterData || [];

    dispatch(setLoading(true));
    try {
      let url = `update_my_sola_websites?status=${status}&page=${page + 1}&per_page=${size}`;
      if (search) {
        url += `&search=${search}`;
      }
      if (sortBy?.length) {
        url += `&field=${sortBy[0]?.id}&order_by=${sortBy[0]?.desc ? "desc" : "asc"}`;
      }
      if (filterBy && filterBy.length) {
        url = getFilterURL(url, filterBy);
      }
      const response = await apiClient().get(url, {
        params: getAssociationLocations(),
      });

      if (response) {
        dispatch(setUpdateSolaWebsitesList(response?.data || []));
        dispatch(setLoading(false));
      }
    } catch (error: any) {
      dispatch(notificationFail(error.message || Messages.ERROR.NO_DATA_FOUND));
      dispatch(setLoading(false));
    }
  }
);

/**
 * Update Website
 */
export const updateWebsites = createAsyncThunk(
  "updateWebsites",
  async (_request: any, { dispatch }) => {
    dispatch(setLoading(true));
    const { websiteData, onClose, pageNo, perPage, search, sortBy, status } = _request;
    console.log("websiteData:", websiteData);

    const websiteFormData = new FormData();
    // websiteFormData.append("name", getValue(websiteData?.name));
    websiteFormData.append("f_name", getValue(websiteData?.f_name));
    websiteFormData.append("m_name", getValue(websiteData?.m_name));
    websiteFormData.append("l_name", getValue(websiteData?.l_name));
    websiteFormData.append("biography", getValue(websiteData?.biography));
    websiteFormData.append("phone_number", getValue(websiteData?.phone_number));
    websiteFormData.append("business_name", getValue(websiteData?.business_name));
    websiteFormData.append("work_hours", getValue(websiteData?.work_hours));
    websiteFormData.append("hair", getValue(websiteData?.hair));
    websiteFormData.append("skin", getValue(websiteData?.skin));
    websiteFormData.append("nails", getValue(websiteData?.nails));
    websiteFormData.append("massage", getValue(websiteData?.massage));
    websiteFormData.append("teeth_whitening", getValue(websiteData?.teeth_whitening));
    websiteFormData.append("eyelash_extensions", getValue(websiteData?.eyelash_extensions));
    websiteFormData.append("makeup", getValue(websiteData?.makeup));
    websiteFormData.append("tanning", getValue(websiteData?.tanning));
    websiteFormData.append("waxing", getValue(websiteData?.waxing));
    websiteFormData.append("brows", getValue(websiteData?.brows));
    websiteFormData.append("website_url", getValue(websiteData?.website_url));
    websiteFormData.append("booking_url", getValue(websiteData?.booking_url));
    websiteFormData.append("pinterest_url", getValue(websiteData?.pinterest_url));
    websiteFormData.append("facebook_url", getValue(websiteData?.facebook_url));
    websiteFormData.append("twitter_url", getValue(websiteData?.twitter_url));
    websiteFormData.append("yelp_url", getValue(websiteData?.yelp_url));
    websiteFormData.append("laser_hair_removal", getValue(websiteData?.laser_hair_removal));
    websiteFormData.append("threading", getValue(websiteData?.threading));
    websiteFormData.append("permanent_makeup", getValue(websiteData?.permanent_makeup));
    websiteFormData.append("other_service", getValue(websiteData?.other_service));
    websiteFormData.append("google_plus_url", getValue(websiteData?.google_plus_url));
    websiteFormData.append("linkedin_url", getValue(websiteData?.linkedin_url));
    websiteFormData.append("hair_extensions", getValue(websiteData?.hair_extensions));
    websiteFormData.append("email_address", getValue(websiteData?.email_address));
    websiteFormData.append("approved", "true");
    websiteFormData.append("microblading", getValue(websiteData?.microblading));
    websiteFormData.append("reserved", getValue(websiteData?.reserved));
    websiteFormData.append("botox", getValue(websiteData?.botox));
    websiteFormData.append("tik_tok_url", getValue(websiteData?.tik_tok_url));
    websiteFormData.append("barber", getValue(websiteData?.barber || false));
    websiteFormData.append("stylist_id", getValue(websiteData?.stylist_id));

    if (getValue(websiteData?.testimonials)) {
      Object.keys(websiteData.testimonials).forEach((val: any) => {
        if (websiteData.testimonials[val]) {
          const nameKey = `stylist[${val}_attributes[name]]`;
          const regionKey = `stylist[${val}_attributes[region]]`;
          const textKey = `stylist[${val}_attributes[text]]`;
          websiteFormData.append(nameKey, websiteData.testimonials[val].name);
          websiteFormData.append(regionKey, websiteData.testimonials[val].region);
          websiteFormData.append(textKey, websiteData.testimonials[val].text);
        }
      });
    }

    [...Array(10)].map((_, i) => {
      const flagName: any = `delete_image_${i + 1}`;
      if (getValue(websiteData[flagName]) === 1) {
        return websiteFormData.append(flagName, getValue(websiteData[flagName]));
      }
      return websiteFormData.append(flagName, "");
    });

    websiteFormData.append("image_1", getValue(websiteData?.update_my_sola__image_1_url));
    websiteFormData.append("image_2", getValue(websiteData?.update_my_sola__image_2_url));
    websiteFormData.append("image_3", getValue(websiteData?.update_my_sola__image_3_url));
    websiteFormData.append("image_4", getValue(websiteData?.update_my_sola__image_4_url));
    websiteFormData.append("image_5", getValue(websiteData?.update_my_sola__image_5_url));
    websiteFormData.append("image_6", getValue(websiteData?.update_my_sola__image_6_url));
    websiteFormData.append("image_7", getValue(websiteData?.update_my_sola__image_7_url));
    websiteFormData.append("image_8", getValue(websiteData?.update_my_sola__image_8_url));
    websiteFormData.append("image_9", getValue(websiteData?.update_my_sola__image_9_url));
    websiteFormData.append("image_10", getValue(websiteData?.update_my_sola__image_10_url));

    try {
      const response = await apiClient(true).put(
        `update_my_sola_websites/${websiteData.id}`,
        websiteFormData
      );
      if (response) {
        dispatch(setLoading(false));
        if (response?.status === 200) {
          onClose();
          dispatch(
            notificationSuccess(response?.data?.message || Messages.SUCCESS.WEBSITE_UPDATE_SUCCESS)
          );
          dispatch(
            getUpdateMyWebsiteList({
              pageIndex: pageNo,
              pageSize: perPage,
              status,
              search,
              sort: sortBy,
            })
          );
        } else {
          dispatch(notificationFail(response?.data?.message || Messages.ERROR.WEBSITE_SAVE_ERROR));
        }
      } else {
        dispatch(notificationFail(response?.data?.message || Messages.ERROR.WEBSITE_SAVE_ERROR));
      }
    } catch (error: any) {
      dispatch(setLoading(false));
      if (error?.response?.data?.error) {
        const message = getErrorMessage(error?.response?.data?.error);
        dispatch(notificationFail(message));
      } else {
        dispatch(
          notificationFail(error?.response?.data?.message || Messages.ERROR.WEBSITE_SAVE_ERROR)
        );
      }
    }
  }
);

/**
 * Delete Update My Sola Website
 */
export const deleteWebsite = createAsyncThunk(
  "deleteWebsite",
  async (_request: any, { dispatch }) => {
    dispatch(setLoading(true));
    const { status, pageNo, perPage, search, sortBy, reject, removeSelectedItem = null } = _request;
    try {
      const response = await apiClient().delete(`update_my_sola_websites/${_request.id}`);
      dispatch(setLoading(false));
      if (response) {
        _request.setWebsiteId(false);
        if (removeSelectedItem) {
          removeSelectedItem(_request.id);
        }
        dispatch(
          notificationSuccess(
            reject
              ? Messages.SUCCESS.WEBSITE_REJECT_SUCCESS
              : Messages.SUCCESS.WEBSITE_DELETE_SUCCESS
          )
        );
        dispatch(
          getUpdateMyWebsiteList({
            pageIndex: pageNo,
            pageSize: perPage,
            status,
            search,
            sort: sortBy,
          })
        );
      } else {
        _request.setWebsiteId(false);
        dispatch(notificationFail(response?.data?.message || Messages.ERROR.WEBSITE_DELETE_ERROR));
      }
    } catch (error: any) {
      if (error?.response?.data?.error) {
        const message = getErrorMessage(error?.response?.data?.error);
        dispatch(notificationFail(message));
      } else {
        dispatch(
          notificationFail(error?.response?.data?.message || Messages.ERROR.WEBSITE_DELETE_ERROR)
        );
      }
    }
  }
);

/**
 * Get all stylist Units
 */
export const getStylistUnits = createAsyncThunk(
  "getStylistUnits",
  async (_request: any, { dispatch }) => {
    dispatch(setLoading(true));
    const page = _request.pageIndex || 0;
    const size = _request.pageSize || 50;
    const search = _request.search || "";
    const sortBy = _request.sort || [];
    const filterBy = _request.filterData || [];
    try {
      let url = `stylist_units?page=${page + 1}&per_page=${size}`;
      if (search) {
        url += `&search=${search}`;
      }
      if (sortBy?.length) {
        url += `&field=${sortBy[0]?.id}&order_by=${sortBy[0]?.desc ? "desc" : "asc"}`;
      }
      if (filterBy && filterBy.length) {
        url = getFilterURL(url, filterBy);
      }
      const response = await apiClient().get(url);
      if (response) {
        dispatch(setLoading(false));
        dispatch(setStylistUnitsList(response?.data || []));
      }
    } catch (error: any) {
      dispatch(setStylistUnitsList(null));
      dispatch(setLoading(false));
      dispatch(notificationFail(error.message || Messages.ERROR.GET_INQUIRIES_ERROR));
    }
  }
);

/**
 * Save Stylist Unit
 */
export const saveStylistUnits = createAsyncThunk(
  "saveStylistUnits",
  async (_request: any, { dispatch }) => {
    dispatch(setLoading(true));
    const { stylistUnitsData, onClose, pageNo, perPage, search, sortBy, id } = _request;
    const reqData = {
      rent_manager_stylist_unit: {
        stylist_id: stylistUnitsData?.stylist?.id || "",
        rent_manager_unit_id: stylistUnitsData?.rent_manager_unit?.id || "",
        rm_lease_id: stylistUnitsData?.rm_lease || "",
        move_in_at: stylistUnitsData?.move_in_at || "",
        move_out_at: stylistUnitsData?.move_out_at || "",
      },
    };
    try {
      const response = id
        ? await apiClient().put(`stylist_units/${id}`, reqData)
        : await apiClient().post("stylist_units", reqData);
      if (response) {
        dispatch(setLoading(false));
        onClose();
        if (id) {
          dispatch(
            notificationSuccess(
              response?.data?.message || Messages.SUCCESS.STYLIST_UNIT_UPDATE_SUCCESS
            )
          );
          dispatch(getStylistUnits({ pageIndex: pageNo, pageSize: perPage, search, sort: sortBy }));
        } else {
          dispatch(
            notificationSuccess(
              response?.data?.message || Messages.SUCCESS.STYLIST_UNIT_CREATE_SUCCESS
            )
          );
          dispatch(getStylistUnits({ pageIndex: pageNo, pageSize: perPage }));
        }
      } else {
        dispatch(
          notificationFail(response?.data?.message || Messages.ERROR.STYLIST_UNIT_SAVE_ERROR)
        );
      }
    } catch (error: any) {
      dispatch(setLoading(false));
      if (error?.response?.data?.error) {
        const message = getErrorMessage(error?.response?.data?.error);
        dispatch(notificationFail(message));
      } else {
        dispatch(
          notificationFail(error?.response?.data?.message || Messages.ERROR.STYLIST_UNIT_SAVE_ERROR)
        );
      }
    }
  }
);

/**
 * Delete stylist Units
 */
export const deleteStylistUnit = createAsyncThunk(
  "deleteStylistUnit",
  async (_request: any, { dispatch }) => {
    const { pageNo, perPage, search, sortBy, removeSelectedItem = null } = _request;
    dispatch(setLoading(true));
    try {
      const response = await apiClient().delete(`stylist_units/${_request.id}`);
      dispatch(setLoading(false));
      if (response) {
        _request.setStylistUnitId(false);
        if (removeSelectedItem) {
          removeSelectedItem(_request.id);
        }
        dispatch(notificationSuccess(Messages.SUCCESS.STYLIST_UNIT_DELETE_SUCCESS));
        dispatch(getStylistUnits({ pageIndex: pageNo, pageSize: perPage, search, sort: sortBy }));
      } else {
        dispatch(
          notificationFail(response?.data?.message || Messages.ERROR.STYLIST_UNIT_DELETE_ERROR)
        );
      }
    } catch (error: any) {
      if (error?.response?.data?.error) {
        const message = getErrorMessage(error?.response?.data?.error);
        dispatch(notificationFail(message));
      } else {
        dispatch(
          notificationFail(
            error?.response?.data?.message || Messages.ERROR.STYLIST_UNIT_DELETE_ERROR
          )
        );
      }
    }
  }
);

/**
 * Get all getAllStylistApi for search sola cms rent manager
 */
export const getAllStylistApi = createAsyncThunk(
  "getAllStylistApi",
  async (_request: any, { dispatch }) => {
    const { search, fields } = _request;
    try {
      dispatch(setLoading(true));
      let url = `stylists`;
      let fieldsQueryString = "";
      if (fields && fields.length > 0) {
        fields.forEach((val: string) => {
          fieldsQueryString += `fields[]=${val}&`;
        });
        fieldsQueryString = fieldsQueryString.slice(0, -1);
      }
      if (search) {
        url += `?search=${search}`;
      }
      if (fieldsQueryString) {
        url += `&${fieldsQueryString}&status=active`;
      }

      const headers = {
        "Access-Control-Allow-Origin": "*",
        "api-key": "f2f6ef9d211a7e653e7930b60518f61f",
      };

      axios
        .get(`https://www.solasalonstudios.com/api/${url}`, {
          headers,
        })
        .then((response) => {
          dispatch(setLoading(false));
          dispatch(setSearchResultStylist(response?.data.stylists || []));
        })
        .catch((error) => {
          dispatch(setLoading(false));
          dispatch(notificationFail(error.message || Messages.ERROR.NO_DATA_FOUND));
        });
    } catch (error: any) {
      dispatch(setLoading(false));
      dispatch(notificationFail(error.message || Messages.ERROR.NO_DATA_FOUND));
    }
  }
);

/**
 * Get legacy admins list
 */
export const getLegacyAdminListApi = createAsyncThunk(
  "getLegacyAdminListApi",
  async (_request: any, { dispatch }) => {
    dispatch(setLoading(true));
    const page = _request.pageIndex || 0;
    const size = _request.pageSize || 50;
    const search = _request.search || "";
    const sortBy = _request.sort || [];
    const filterBy = _request.filterData || [];
    try {
      let url = `admins?page=${page + 1}&per_page=${size}`;
      if (search) {
        url += `&search=${search}`;
      }
      if (sortBy?.length) {
        url += `&field=${sortBy[0]?.id}&order_by=${sortBy[0]?.desc ? "desc" : "asc"}`;
      }
      if (filterBy && filterBy.length) {
        url = getFilterURL(url, filterBy);
      }
      const response = await apiClient().get(url, {
        params: getAssociationLocations(),
      });
      if (response) {
        dispatch(setLegacyAdmins(response?.data || []));
        dispatch(setLoading(false));
      }
    } catch (error: any) {
      dispatch(notificationFail(error.message || Messages.ERROR.NO_DATA_FOUND));
      dispatch(setLoading(false));
    }
  }
);

/* SEARCH rent manager from rent manager tenants API
 */
export const searchRentManagerTenants = createAsyncThunk(
  "searchRentManager",
  async (_request: any, { dispatch }) => {
    dispatch(setLoading(true));
    try {
      const getLocationIds = getAssociationLocations();
      let cmsLocationIds: any = [];
      if (
        getLocationIds &&
        getLocationIds?.get_location_by_ids &&
        getLocationIds?.get_location_by_ids.length > 0
      ) {
        if (
          getLocationIds?.get_location_by_ids.length === 1 &&
          getLocationIds?.get_location_by_ids[0] === 0
        ) {
          cmsLocationIds = [];
        } else {
          cmsLocationIds = getLocationIds?.get_location_by_ids;
        }
      }
      Object.assign(_request, { location_ids: cmsLocationIds });

      console.log("_request:", _request);
      const response = await slsApiClient().post(`search-rent-manager`, _request);
      if (response?.status) {
        dispatch(setLoading(false));
        dispatch(setRmSearchResultStylist(response?.data?.data));
        if (response?.data?.data.length === 0) {
          dispatch(notificationFail(response?.data?.message || Messages.ERROR.NO_DATA_FOUND));
        }
      } else {
        dispatch(setLoading(false));
        dispatch(notificationFail(response?.data?.message || Messages.ERROR.SOMETHING_WENT_WRONG));
      }
    } catch (error: any) {
      dispatch(notificationFail(error.message || Messages.ERROR.SOMETHING_WENT_WRONG));
      dispatch(setLoading(false));
    }
  }
);

export const addOrUpdateLegacyAdmin = createAsyncThunk(
  "addLegacyAdmin",
  async (_request: any, { dispatch }) => {
    dispatch(setLoading(true));
    const { adminData, onClose, pageNo, perPage, oldEmail, search } = _request;
    const reqData = {
      admin: {
        email: adminData?.email ? adminData?.email : "",
        email_address: adminData?.email_address ? adminData?.email_address : "",
        password: adminData?.password ? adminData?.password : "",
        password_confirmation: adminData?.password_confirmation
          ? adminData?.password_confirmation
          : "",
        franchisee: adminData?.franchisee ? adminData?.franchisee : "",
        mailchimp_api_key: adminData?.mailchimp_api_key ? adminData?.mailchimp_api_key : "",
        callfire_app_login: adminData?.callfire_app_login ? adminData?.callfire_app_login : "",
        callfire_app_password: adminData?.callfire_app_password
          ? adminData?.callfire_app_password
          : "",
        sola_pro_country_admin: adminData?.sola_pro_country_admin
          ? adminData?.sola_pro_country_admin
          : "",
      },
    };

    if (adminData?.id && oldEmail === adminData?.email) {
      delete reqData.admin.email;
    }
    try {
      const response = adminData?.id
        ? await apiClient().put(`admins/${adminData.id}`, reqData)
        : await apiClient().post(`admins`, reqData);
      if (response) {
        onClose();
        if (adminData?.id) {
          dispatch(
            notificationSuccess(
              response?.data?.message || Messages.SUCCESS.LEGACY_ADMIN_UPDATE_SUCCESS
            )
          );
        } else {
          dispatch(
            notificationSuccess(
              response?.data?.message || Messages.SUCCESS.LEGACY_ADMIN_CREATE_SUCCESS
            )
          );
        }
      }
      dispatch(getLegacyAdminListApi({ pageIndex: pageNo, pageSize: perPage, search }));
    } catch (error: any) {
      dispatch(notificationFail(error.message || Messages.ERROR.NO_DATA_FOUND));
      dispatch(setLoading(false));
    }
  }
);

export const deleteLegacyAdminRecord = createAsyncThunk(
  "deleteLegacyAdminRecord",
  async (_request: any, { dispatch }) => {
    const { removeSelectedItem = null } = _request;
    dispatch(setLoading(true));
    try {
      const response = await apiClient().delete(`admins/${_request.adminData?.id}`);
      dispatch(setLoading(false));
      if (response) {
        // _request.setStylistUnitId(false);
        _request.onCloseDeleteModal(false);
        if (removeSelectedItem) {
          removeSelectedItem(_request.adminData?.id);
        }
        dispatch(notificationSuccess(Messages.SUCCESS.LEGACY_ADMIN_DELETE_SUCCESS));
        dispatch(getLegacyAdminListApi({}));
      } else {
        dispatch(
          notificationFail(response?.data?.message || Messages.ERROR.LEGACY_ADMIN_DELETE_ERROR)
        );
      }
    } catch (error: any) {
      if (error?.response?.data?.error) {
        const message = getErrorMessage(error?.response?.data?.error);
        dispatch(notificationFail(message));
      } else {
        dispatch(
          notificationFail(
            error?.response?.data?.message || Messages.ERROR.LEGACY_ADMIN_DELETE_ERROR
          )
        );
      }
    }
  }
);

/* SEARCH matching rent manager for stylist
 */
export const searchMatchingRM = createAsyncThunk(
  "searchMatchingRM",
  async (_request: any, { dispatch }) => {
    dispatch(setLoading(true));
    try {
      const response = await slsApiClient().post(`search-matching-rm-for-stylist`, _request);
      if (response?.status) {
        dispatch(setLoading(false));
        dispatch(setMatchingRentManager(response?.data?.data));
        if (response?.data?.data.length === 0) {
          dispatch(notificationFail(response?.data?.message || Messages.ERROR.NO_DATA_FOUND));
        }
      } else {
        dispatch(setLoading(false));
        dispatch(notificationFail(response?.data?.message || Messages.ERROR.SOMETHING_WENT_WRONG));
      }
    } catch (error: any) {
      if (error?.response?.data?.message) {
        dispatch(notificationFail(error?.response?.data?.message));
      } else {
        dispatch(notificationFail(error.message || Messages.ERROR.SOMETHING_WENT_WRONG));
      }
      dispatch(setLoading(false));
    }
  }
);

/* SEARCH matching stylist for rent manager
 */
export const searchMatchingStylist = createAsyncThunk(
  "searchMatchingRM",
  async (_request: any, { dispatch }) => {
    dispatch(setLoading(true));
    try {
      const response = await slsApiClient().post(`search-matching-stylist-for-rm-tenant`, _request);
      if (response?.status) {
        dispatch(setLoading(false));
        dispatch(setMatchingStylist(response?.data?.data));
        if (response?.data?.data.length === 0) {
          dispatch(notificationFail(response?.data?.message || Messages.ERROR.NO_DATA_FOUND));
        }
      } else {
        dispatch(setLoading(false));
        dispatch(notificationFail(response?.data?.message || Messages.ERROR.SOMETHING_WENT_WRONG));
      }
    } catch (error: any) {
      if (error?.response?.data?.message) {
        dispatch(notificationFail(error?.response?.data?.message));
      } else {
        dispatch(notificationFail(error.message || Messages.ERROR.SOMETHING_WENT_WRONG));
      }
      dispatch(setLoading(false));
    }
  }
);

/**
 * Get externals list
 */
export const getExternalsList = createAsyncThunk(
  "getExternalsList",
  async (_request: any, { dispatch }) => {
    dispatch(setLoading(true));
    const page = _request.pageIndex || 0;
    const size = _request.pageSize || 50;
    const search = _request.search || "";
    const sortBy = _request.sort || [];
    const filterBy = _request.filterData || [];
    try {
      let url = `externals?page=${page + 1}&per_page=${size}`;
      if (search) {
        url += `&search=${search}`;
      }
      if (sortBy?.length) {
        url += `&field=${sortBy[0]?.id}&order_by=${sortBy[0]?.desc ? "desc" : "asc"}`;
      }
      if (filterBy && filterBy.length) {
        url = getFilterURL(url, filterBy);
      }
      const response = await apiClient().get(url);
      if (response) {
        dispatch(setExternalList(response?.data || []));
        dispatch(setLoading(false));
      }
    } catch (error: any) {
      dispatch(notificationFail(error.message || Messages.ERROR.NO_DATA_FOUND));
      dispatch(setLoading(false));
    }
  }
);

/* Delete externals record */
export const deleteExternalRecord = createAsyncThunk(
  "deleteExternalRecord",
  async (_request: any, { dispatch }) => {
    dispatch(setLoading(true));
    const { externalData, onCloseDeleteModal, pageNo, perPage } = _request;
    try {
      const response = await apiClient(true).delete(`externals/${externalData.id}`);
      if (response) {
        onCloseDeleteModal();
        dispatch(notificationSuccess(Messages.SUCCESS.EXTERNAL_DELETE_SUCCESS));
        dispatch(getExternalsList({ pageIndex: pageNo, pageSize: perPage }));
      } else {
        dispatch(setLoading(false));
        dispatch(notificationFail(response?.data?.message || Messages.ERROR.EXTERNAL_DELETE_ERROR));
      }
    } catch (error: any) {
      dispatch(setLoading(false));
      if (error?.response?.data?.error) {
        const message = getErrorMessage(error?.response?.data?.error);
        dispatch(notificationFail(message));
      } else {
        dispatch(
          notificationFail(error?.response?.data?.message || Messages.ERROR.EXTERNAL_DELETE_ERROR)
        );
      }
    }
  }
);

/* Edit or add external */
export const addOrUpdateExternal = createAsyncThunk(
  "addOrUpdateExternal",
  async (_request: any, { dispatch }) => {
    dispatch(setLoading(true));
    const { externalData, onClose, pageNo, perPage } = _request;
    if (externalData?.location_objectable_id) {
      delete externalData.location_objectable_id;
    }
    const reqData = {
      external_id: {
        ...externalData,
        objectable_id: externalData.objectable_id.id,
      },
    };
    try {
      const response = externalData?.id
        ? await apiClient().put(`externals/${externalData.id}`, reqData)
        : await apiClient().post(`externals`, reqData);
      if (response) {
        onClose();
        if (externalData?.id) {
          dispatch(
            notificationSuccess(response?.data?.message || Messages.SUCCESS.EXTERNAL_UPDATE_SUCCESS)
          );
        } else {
          dispatch(
            notificationSuccess(response?.data?.message || Messages.SUCCESS.EXTERNAL_CREATE_SUCCESS)
          );
        }
      }
      dispatch(getExternalsList({ pageIndex: pageNo, pageSize: perPage }));
    } catch (error: any) {
      if (error?.response?.data?.error) {
        error.message = error?.response?.data?.error;
      }
      dispatch(notificationFail(error.message || Messages.ERROR.NO_DATA_FOUND));
      dispatch(setLoading(false));
    }
  }
);

/* Get testimonials list */
export const getTestimonialsList = createAsyncThunk(
  "getTestimonialsList",
  async (_request: any, { dispatch }) => {
    const page = _request.pageIndex || 0;
    const size = _request.pageSize || 50;
    const search = _request.search || "";
    const sortBy = _request.sort || [];
    const filterBy = _request.filterData || [];
    dispatch(setLoading(true));
    try {
      let url = `get-connect-testimonials?page=${page}&per_page=${size}`;
      if (search) {
        url += `&search=${search}`;
      }
      if (sortBy?.length) {
        url += `&field=${sortBy[0]?.id}&order_by=${sortBy[0]?.desc ? "desc" : "asc"}`;
      }
      if (filterBy && filterBy.length) {
        url = getFilterURL(url, filterBy);
      }
      const response = await platformApiClient().get(url);
      if (response) {
        dispatch(setTestimonialsList(response?.data || []));
        dispatch(setLoading(false));
      }
    } catch (error: any) {
      dispatch(notificationFail(error.message || Messages.ERROR.NO_DATA_FOUND));
      dispatch(setLoading(false));
    }
  }
);
/* Get CallFire Logs list */
export const getCallFireLogList = createAsyncThunk(
  "getCallFireLogList",
  async (_request: any, { dispatch }) => {
    const page = _request.pageIndex || 0;
    const size = _request.pageSize || 50;
    const search = _request.search || "";
    const sortBy = _request.sort || [];
    dispatch(setLoading(true));
    try {
      let url = `callfire_logs?page=${page + 1}&per_page=${size}`;
      if (search) {
        url += `&search=${search}`;
      }
      if (sortBy?.length) {
        url += `&field=${sortBy[0]?.id}&order_by=${sortBy[0]?.desc ? "desc" : "asc"}`;
      }
      const response = await apiClient().get(url);
      if (response) {
        dispatch(setCallFireLogList(response?.data || []));
        dispatch(setLoading(false));
      }
    } catch (error: any) {
      dispatch(notificationFail(error.message || Messages.ERROR.NO_DATA_FOUND));
      dispatch(setLoading(false));
    }
  }
);

/* Get RM Events */
export const getRMEventList = createAsyncThunk(
  "getRMEventList",
  async (_request: any, { dispatch }) => {
    const page = _request.pageIndex || 0;
    const size = _request.pageSize || 50;
    const search = _request.search || "";
    const sortBy = _request.sort || [];
    const filterBy = _request.filterData || [];
    dispatch(setLoading(true));
    try {
      let url = `rent_manager_events?page=${page + 1}&per_page=${size}`;
      if (search) {
        url += `&search=${search}`;
      }
      if (sortBy?.length) {
        url += `&field=${sortBy[0]?.id}&order_by=${sortBy[0]?.desc ? "desc" : "asc"}`;
      }
      if (filterBy && filterBy.length) {
        url = getFilterURL(url, filterBy);
      }
      const response = await apiClient().get(url);
      if (response) {
        dispatch(setRMEventsList(response?.data || []));
        dispatch(setLoading(false));
      }
    } catch (error: any) {
      dispatch(notificationFail(error.message || Messages.ERROR.NO_DATA_FOUND));
      dispatch(setLoading(false));
    }
  }
);

/**
 * Get contact inquiry by ID
 */
export const getContactIquiryById = createAsyncThunk(
  "getContactIquiryById",
  async (_request: any, { dispatch }) => {
    const { id } = _request;
    dispatch(setLoading(true));
    try {
      const response = await apiClient().get(`request_tour_inquiries/${id}`);
      if (response) {
        dispatch(setContactIquiryData(response?.data || null));
      }
      dispatch(setLoading(false));
    } catch (error: any) {
      dispatch(setLoading(false));
      dispatch(notificationFail(error.message || Messages.ERROR.NO_DATA_FOUND));
    }
  }
);

/**
 * Get List of Rejected Update my website
 */
export const getUpdateMyWebsiteRejectedList = createAsyncThunk(
  "getUpdateMyWebsiteRejectedList",
  async (_request: any, { dispatch }) => {
    // const { status } = _request;
    const page = _request.pageIndex || 0;
    const size = _request.pageSize || 50;
    const search = _request.search || "";
    const sortBy = _request.sort || [];
    const filterBy = _request.filterData || [];

    dispatch(setLoading(true));
    try {
      let url = `update_my_sola_website_deleted_records?page=${page + 1}&per_page=${size}`;
      if (search) {
        url += `&search=${search}`;
      }
      if (sortBy?.length) {
        url += `&field=${sortBy[0]?.id}&order_by=${sortBy[0]?.desc ? "desc" : "asc"}`;
      }
      if (filterBy && filterBy.length) {
        url = getFilterURL(url, filterBy);
      }
      const response = await apiClient().get(url, {
        params: getAssociationLocations(),
      });

      if (response) {
        dispatch(setUpdateSolaWebsitesList(response?.data || []));
        dispatch(setLoading(false));
      }
    } catch (error: any) {
      dispatch(notificationFail(error.message || Messages.ERROR.NO_DATA_FOUND));
      dispatch(setLoading(false));
    }
  }
);

// Fqs calls
export const getFaqs = createAsyncThunk("getFaqs", async (_request: any, { dispatch }) => {
  dispatch(setLoading(true));
  const page = _request.pageIndex || 0;
  const size = _request.pageSize || 50;
  const search = _request.search || "";
  const sortBy = _request.sort || [];
  try {
    let url = `get-faq?page=${page}&per_page=${size}`;
    if (search) {
      url += `&search=${search}`;
    }
    if (sortBy?.length) {
      url += `&field=${sortBy[0]?.id}&order_by=${sortBy[0]?.desc ? "desc" : "asc"}`;
    }
    const response = await slsApiClient().get(url);
    if (response?.data?.status) {
      dispatch(faqsList(response?.data || []));
      dispatch(setLoading(false));
    } else {
      dispatch(setLoading(false));
      dispatch(notificationFail(response?.data?.message || Messages.ERROR.NO_DATA_FOUND));
    }
  } catch (error: any) {
    dispatch(notificationFail(error.message || Messages.ERROR.NO_DATA_FOUND));
    dispatch(setLoading(false));
  }
});

// Faq create function
export const createFaq = createAsyncThunk("createFaq", async (_request: any, { dispatch }) => {
  dispatch(setLoading(true));
  const { isUpdate, sendData, onClose, pageNo, perPage, search, sortType } = _request;
  try {
    const response = isUpdate
      ? await slsApiClient().post(`edit-faq`, sendData)
      : await slsApiClient().post(`add-faq`, sendData);
    if (response?.data?.status) {
      dispatch(setLoading(false));
      onClose();
      if (isUpdate) {
        dispatch(notificationSuccess(Messages.SUCCESS.FAQ_UPDATE_SUCCESS));
        dispatch(getFaqs({ pageIndex: pageNo, pageSize: perPage, search, sort: sortType }));
      } else {
        dispatch(notificationSuccess(Messages.SUCCESS.FAQ_ADD_SUCCESS));
        dispatch(getFaqs({ pageIndex: pageNo, pageSize: perPage }));
      }
    } else {
      dispatch(setLoading(false));
      dispatch(notificationFail(response?.data?.message || Messages.ERROR.FAQ_EDIT_ERROR));
    }
  } catch (error: any) {
    dispatch(notificationFail(error.message || Messages.ERROR.SOMETHING_WENT_WRONG));
    dispatch(setLoading(false));
  }
});

// Faq Delete function
export const deleteFaqs = createAsyncThunk("deleteFaqs", async (_request: any, { dispatch }) => {
  dispatch(setLoading(true));
  const { faqId, onClose, pageNo, perPage, search, sortType, removeSelectedItem = null } = _request;
  try {
    const response = await slsApiClient().delete(`delete-faq/${faqId}`);
    if (response?.data?.status) {
      onClose();
      if (removeSelectedItem) {
        removeSelectedItem(faqId);
      }
      dispatch(setLoading(false));
      dispatch(notificationSuccess(response?.data?.message || Messages.SUCCESS.FAQ_ADD_SUCCESS));
      dispatch(getFaqs({ pageIndex: pageNo, pageSize: perPage, search, sort: sortType }));
    } else {
      dispatch(notificationFail(response?.data?.message || Messages.ERROR.FAQ_ADD_ERROR));
    }
  } catch (error: any) {
    dispatch(setLoading(false));
    if (error?.response?.data?.error) {
      const message = getErrorMessage(error?.response?.data?.error);
      dispatch(notificationFail(message));
    } else {
      dispatch(notificationFail(Messages.ERROR.FAQ_ADD_ERROR));
    }
  }
});

// Amenities get function
export const getAmenities = createAsyncThunk(
  "getAmenities",
  async (_request: any, { dispatch }) => {
    dispatch(setLoading(true));
    const page = _request.pageIndex || 0;
    const size = _request.pageSize || 50;
    const search = _request.search || "";
    const sortBy = _request.sort || [];
    try {
      let url = `get-amenities-list?page=${page}&per_page=${size}`;
      if (search) {
        url += `&search=${search}`;
      }
      if (sortBy?.length) {
        url += `&field=${sortBy[0]?.id}&order_by=${sortBy[0]?.desc ? "desc" : "asc"}`;
      }
      const response = await slsApiClient().get(url);
      if (response?.data?.status) {
        dispatch(amenitiesList(response?.data || []));
        dispatch(setLoading(false));
      } else {
        dispatch(setLoading(false));
        dispatch(notificationFail(response?.data?.message || Messages.ERROR.NO_DATA_FOUND));
      }
    } catch (error: any) {
      dispatch(notificationFail(error.message || Messages.ERROR.NO_DATA_FOUND));
      dispatch(setLoading(false));
    }
  }
);

// Amenities create function
export const createAmenities = createAsyncThunk(
  "createAmenities",
  async (_request: any, { dispatch }) => {
    dispatch(setLoading(true));
    const { isUpdate, sendData, onClose, pageNo, perPage, search, sortType } = _request;
    try {
      const request = {
        headline: sendData.headline || "",
        description: sendData.description || "",
        icon: sendData?.icon?.value || "",
        amenity_type: sendData?.amenity_type || false,
      };
      if (sendData.id) {
        Object.assign(request, { id: sendData.id });
      }
      const response = isUpdate
        ? await slsApiClient().post(`edit-amenities`, request)
        : await slsApiClient().post(`create-amenities`, request);
      if (response?.data?.status) {
        dispatch(setLoading(false));
        onClose();
        if (isUpdate) {
          dispatch(notificationSuccess(Messages.SUCCESS.AMENITIES_UPDATE_SUCCESS));
          dispatch(getAmenities({ pageIndex: pageNo, pageSize: perPage, search, sort: sortType }));
        } else {
          dispatch(notificationSuccess(Messages.SUCCESS.AMENITIES_ADD_SUCCESS));
          dispatch(getAmenities({ pageIndex: pageNo, pageSize: perPage }));
        }
      } else {
        dispatch(setLoading(false));
        dispatch(notificationFail(response?.data?.message || Messages.ERROR.AMENITIES_EDIT_ERROR));
      }
    } catch (error: any) {
      dispatch(notificationFail(error.message || Messages.ERROR.SOMETHING_WENT_WRONG));
      dispatch(setLoading(false));
    }
  }
);

// Amenities Delete function
export const deleteAmenities = createAsyncThunk(
  "deleteAmenities",
  async (_request: any, { dispatch }) => {
    dispatch(setLoading(true));
    const { id, onClose, pageNo, perPage, search, sortType } = _request;
    try {
      const response = await slsApiClient().delete(`delete-amenities/${id}`);
      if (response?.data?.status) {
        onClose();
        dispatch(setLoading(false));
        dispatch(
          notificationSuccess(response?.data?.message || Messages.SUCCESS.AMENITIES_ADD_SUCCESS)
        );
        dispatch(getAmenities({ pageIndex: pageNo, pageSize: perPage, search, sort: sortType }));
      } else {
        dispatch(notificationFail(response?.data?.message || Messages.ERROR.AMENITIES_ADD_ERROR));
      }
    } catch (error: any) {
      dispatch(setLoading(false));
      if (error?.response?.data?.error) {
        const message = getErrorMessage(error?.response?.data?.error);
        dispatch(notificationFail(message));
      } else {
        dispatch(notificationFail(Messages.ERROR.AMENITIES_ADD_ERROR));
      }
    }
  }
);

// get list of all sola pro support request list
export const getHelpDeskRequestList = createAsyncThunk(
  "getHelpDeskRequestList",
  async (_request: any, { dispatch }) => {
    dispatch(setLoading(true));
    const page = _request.pageIndex || 0;
    const size = _request.pageSize || 50;
    const search = _request.search || "";
    const sortBy = _request.sort || [];
    try {
      let url = `get-solapro-support-request-list?page=${page}&per_page=${size}`;
      if (search) {
        url += `&search=${search}`;
      }
      if (sortBy?.length) {
        url += `&field=${sortBy[0]?.id}&order_by=${sortBy[0]?.desc ? "desc" : "asc"}`;
      }
      const response = await slsApiClient().get(url);
      if (response?.data?.status) {
        dispatch(setHelpDeskReqList(response?.data || []));
        dispatch(setLoading(false));
      } else {
        dispatch(setLoading(false));
        dispatch(notificationFail(response?.data?.message || Messages.ERROR.NO_DATA_FOUND));
      }
    } catch (error: any) {
      dispatch(notificationFail(error.message || Messages.ERROR.NO_DATA_FOUND));
      dispatch(setLoading(false));
    }
  }
);
// get stylist detail for approval process
export const getStylistDataForApprovalReq = createAsyncThunk(
  "getStylistDataForApprovalReq",
  async (_request: any, { dispatch }) => {
    dispatch(setLoading(true));
    try {
      const url = `get-stylist-data-for-approval-req?stylist_id=${_request?.stylist_id}`;
      const response = await slsApiClient().get(url);
      if (response?.data?.status) {
        dispatch(setStylistDataForApprovalReq(response?.data?.data || null));
        dispatch(setLoading(false));
      } else {
        dispatch(setLoading(false));
        dispatch(notificationFail(response?.data?.message || Messages.ERROR.NO_DATA_FOUND));
      }
    } catch (error: any) {
      dispatch(notificationFail(error.message || Messages.ERROR.NO_DATA_FOUND));
      dispatch(setLoading(false));
    }
  }
);
// approve sola pro support request for matching RM
export const onRejectSolaPrpMatchARequest = createAsyncThunk(
  "onRejectSolaPrpMatchARequest",
  async (_request: any, { dispatch }) => {
    const { onClose, pageNo, perPage, search, sortType, onCloseRejectPopup } = _request;
    const rejectObj = {
      request_id: _request?.request_id,
      reject_reason: _request?.reject_reason,
      rejected_by: _request?.approved_by,
    };
    dispatch(setLoading(true));
    try {
      const response = await slsApiClient().post(`reject-solapro-match-request`, rejectObj);
      if (response?.data?.status) {
        dispatch(
          getHelpDeskRequestList({ pageIndex: pageNo, pageSize: perPage, search, sort: sortType })
        );
        dispatch(setLoading(false));
        if (onClose) {
          onClose();
        }
        if (onCloseRejectPopup) {
          onCloseRejectPopup();
        }
        dispatch(notificationSuccess(response?.data?.message));
      } else {
        dispatch(setLoading(false));
        dispatch(notificationFail(response?.data?.message || Messages.ERROR.NO_DATA_FOUND));
      }
    } catch (error: any) {
      dispatch(notificationFail(error.message || Messages.ERROR.NO_DATA_FOUND));
      dispatch(setLoading(false));
    }
  }
);
// approve sola pro support request for matching RM
export const onApproveSolaProSupportRequest = createAsyncThunk(
  "onApproveSolaProSupportRequest",
  async (_request: any, { dispatch }) => {
    console.log("_request:", _request);
    const { onClose, pageNo, perPage, search, sortType } = _request;
    const approveObj = {
      stylist_id: _request?.stylist_id,
      rm_location_id: _request?.rm_location_id,
      tenant_id: _request?.tenant_id,
      approved_by: _request?.approved_by,
      request_id: _request?.request_id,
      match_done_by_id: _request?.match_done_by_id,
      match_done_by_type: _request?.match_done_by_type,
      cms_location_id: _request?.cms_location_id,
      rm_property_id: _request?.rm_property_id,
    };
    console.log("approveObj:", approveObj);
    dispatch(setLoading(true));
    try {
      const response = await slsApiClient().post(`confirm-rm-match`, approveObj);
      if (response?.data?.status) {
        dispatch(
          getHelpDeskRequestList({ pageIndex: pageNo, pageSize: perPage, search, sort: sortType })
        );
        dispatch(setLoading(false));
        if (onClose) {
          onClose();
        }
        dispatch(notificationSuccess(response?.data?.message));
      } else {
        dispatch(setLoading(false));
        dispatch(notificationFail(response?.data?.message || Messages.ERROR.NO_DATA_FOUND));
      }
    } catch (error: any) {
      dispatch(
        notificationFail(
          error?.response.data?.message || error.message || Messages.ERROR.NO_DATA_FOUND
        )
      );
      dispatch(setLoading(false));
    }
  }
);

/* getting list of sola pro for frenchisee with rm match/unmatch/multiple match and confirmed */
export const getTabWiseSolaProList = createAsyncThunk(
  "getTabWiseSolaProList",
  async (_request: any, { dispatch }) => {
    dispatch(setLoading(true));
    try {
      const getLocationIds = getAssociationLocations();
      let cmsLocationIds: any = [];
      if (
        getLocationIds &&
        getLocationIds?.get_location_by_ids &&
        getLocationIds?.get_location_by_ids.length > 0
      ) {
        cmsLocationIds = getLocationIds?.get_location_by_ids;
      }

      const response = await slsApiClient().get(
        `get-solapro-list-tab-wise?tab_name=${
          _request?.tab_name
        }&cms_location_ids=${cmsLocationIds.join()}`
      );
      dispatch(setLoading(false));
      if (response?.data?.status) {
        dispatch(setTabWiseSolaProList(response?.data?.stylist));
      }
    } catch (error: any) {
      dispatch(setLoading(false));
      dispatch(
        notificationFail(error?.response?.data?.message || Messages.ERROR.SOMETHING_WENT_WRONG)
      );
    }
  }
);
export const getRmMatchingData = createAsyncThunk(
  "getRmMatchingData",
  async (_request: any, { dispatch }) => {
    dispatch(setLoading(true));
    try {
      const response = await slsApiClient().post(`get-matching-rent-manager-data`, _request);
      dispatch(setLoading(false));
      if (response?.data?.status) {
        dispatch(setFrenchiseeRmMatchData(response?.data?.data));
      }
    } catch (error: any) {
      dispatch(setLoading(false));
      dispatch(
        notificationFail(error?.response?.data?.message || Messages.ERROR.SOMETHING_WENT_WRONG)
      );
    }
  }
);
/* get list of match confirmed sola pro from new table */
export const getmatchConfirmSolaProList = createAsyncThunk(
  "getTabWiseSolaProList",
  async (_: any, { dispatch }) => {
    dispatch(setLoading(true));
    try {
      const getLocationIds = getAssociationLocations();
      let cmsLocationIds: any = [];
      if (
        getLocationIds &&
        getLocationIds?.get_location_by_ids &&
        getLocationIds?.get_location_by_ids.length > 0
      ) {
        cmsLocationIds = getLocationIds?.get_location_by_ids;
      }

      const response = await slsApiClient(true).post("get-list-confirmed-rm-solapro", {
        location_ids: cmsLocationIds,
      });
      dispatch(setLoading(false));
      if (response?.data?.status) {
        dispatch(setTabWiseSolaProList(response?.data?.data));
      }
    } catch (error: any) {
      dispatch(setLoading(false));
      dispatch(
        notificationFail(error?.response?.data?.message || Messages.ERROR.SOMETHING_WENT_WRONG)
      );
    }
  }
);
/* remove sola pro which are confirmed */
export const removeConfirmedSolaPro = createAsyncThunk(
  "getTabWiseSolaProList",
  async (_request: any, { dispatch }) => {
    const { id, closeConifrm } = _request;
    dispatch(setLoading(true));
    try {
      const response = await slsApiClient(true).post("remove-from-confirmed-solapro", {
        id,
      });
      dispatch(setLoading(false));
      if (response?.data?.status) {
        dispatch(getmatchConfirmSolaProList({}));
        dispatch(notificationSuccess(response?.data?.message));
        if (closeConifrm) {
          closeConifrm();
        }
      }
    } catch (error: any) {
      dispatch(setLoading(false));
      dispatch(
        notificationFail(error?.response?.data?.message || Messages.ERROR.SOMETHING_WENT_WRONG)
      );
    }
  }
);

/**
 * Get lead tracking list
 */
export const getLeadTrackingReport = createAsyncThunk(
  "getLeadTrackingReport",
  async (_request: any, { dispatch }) => {
    const page = _request.pageIndex || 0;
    const size = _request.pageSize || 50;
    const search = _request.search || "";
    const sortBy = _request.sort || [];
    const filterBy = _request.filterData || [];
    dispatch(setLoading(true));
    try {
      let url = `lead-analysis-tracking?page=${page}&per_page=${size}`;
      if (search) {
        url += `&search=${search}`;
      }
      if (sortBy?.length) {
        url += `&field=${sortBy[0]?.id}&order_by=${sortBy[0]?.desc ? "desc" : "asc"}`;
      }
      if (filterBy && filterBy.length) {
        url = getFilterURL(url, filterBy);
      }
      const response = await slsApiClient().get(url, {
        params: getAssociationLocations(),
      });
      if (response) {
        dispatch(setLoading(false));
        dispatch(setAnalyticsList(response?.data || []));
      }
    } catch (error: any) {
      dispatch(setLoading(false));
      dispatch(notificationFail(error.message || Messages.ERROR.NO_DATA_FOUND));
    }
  }
);
