import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";

function BasicInfo(props: any): JSX.Element {
  const { accountDetail, onChangeField, errors } = props;
  return (
    <Paper>
      <MDBox p={2} mt={2}>
        <MDBox mb={1}>
          <MDTypography variant="h5" fontWeight="regular">
            Basic Info
          </MDTypography>
        </MDBox>
        <MDBox>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6} md={6}>
              <MDInput
                label="Display name/Business name"
                variant="standard"
                fullWidth
                name="business_name"
                placeholder="Display name/Business name"
                onChange={onChangeField}
                value={accountDetail?.business_name || ""}
                error={errors && errors.business_name ? true : false}
                helperText={errors && errors.business_name}
                required
              />
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
              <MDInput
                label="URL"
                variant="standard"
                fullWidth
                name="business_url"
                placeholder="URL"
                onChange={onChangeField}
                value={accountDetail?.business_url || ""}
                error={errors && errors.business_url ? true : false}
                helperText={errors && errors.business_url}
                required
              />
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
              <MDInput
                label="Description"
                variant="standard"
                fullWidth
                name="business_description"
                placeholder="Description"
                onChange={onChangeField}
                value={accountDetail?.business_description || ""}
                error={errors && errors.business_description ? true : false}
                helperText={errors && errors.business_description}
                required
              />
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
              <MDInput
                label="Work Hours"
                variant="standard"
                type="number"
                fullWidth
                name="business_work_hours"
                placeholder="Work Hours"
                onChange={onChangeField}
                value={accountDetail?.business_work_hours || ""}
                error={errors && errors.business_work_hours ? true : false}
                helperText={errors && errors.business_work_hours}
                required
              />
            </Grid>
          </Grid>
        </MDBox>
      </MDBox>
    </Paper>
  );
}

export default BasicInfo;
