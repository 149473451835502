import MDBox from "components/MDBox";

function Index(): JSX.Element {
  return (
    <MDBox ml={2} py={3}>
      Marketing Admin Dashboard
    </MDBox>
  );
}

export default Index;
