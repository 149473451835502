import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MESSAGES from "helper/messages";

function TextEmailIntegration(props: any): JSX.Element {
  const { locationDetail, onChangeField } = props;
  return (
    <Card id="text-email-integration">
      <MDBox p={3}>
        <MDTypography variant="h5">Text and Email Integration</MDTypography>
      </MDBox>
      <MDBox pb={3} px={3}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6} md={6} lg={6}>
            <MDInput
              multiline
              rows="3"
              variant="outlined"
              label="Mailchimp List Ids"
              fullWidth
              name="mailchimp_list_ids"
              placeholder="Mailchimp List Ids"
              onChange={onChangeField}
              value={locationDetail?.mailchimp_list_ids || ""}
              infoText={MESSAGES.INFO.MAIL_CHIP_IDS}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={6} lg={6}>
            <MDInput
              multiline
              rows="3"
              variant="outlined"
              label="Callfire List Ids"
              fullWidth
              name="callfire_list_ids"
              placeholder="Callfire List Ids"
              onChange={onChangeField}
              value={locationDetail?.callfire_list_ids || ""}
              infoText={MESSAGES.INFO.CALL_FIRE_IDS}
            />
          </Grid>
        </Grid>
      </MDBox>
    </Card>
  );
}

export default TextEmailIntegration;
