import { useEffect, useState } from "react";
import { useAppDispatch } from "store/store";
import MDDialog from "components/MDDialog";
import MDInput from "components/MDInput";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import CustomDropZone from "components/CustomDropZone";
import { Grid, Icon } from "@mui/material";
import Validations from "helper/validations";
import { saveProductInformation } from "store/thunk/mobileAppThunk";
import fileimg from "assets/images/file-image.svg";
import ImagePreview from "layouts/pages/ImagePreview";
import CustomErrorMessage from "components/CustomErrorMessage";
import CustomAutoSearch from "components/CustomAutoSearch";
import MDIconButton from "components/MDIconButton";
import { getPermission } from "helper/services";

function ViewDetails(props: any): JSX.Element {
  const dispatch = useAppDispatch();

  const { open, onClose, updateData, pageNo, perPage, search, sortBy } = props;
  const [isUpdate, setIsUpdate] = useState<any>(false);
  const [productInformation, setProductInformation] = useState<any>({});
  const [imagePreview, setImagePreview] = useState<any>("");
  const [errors, setErrors] = useState<any>({});

  const [isPreview, setImagePreviewPopup] = useState(false);
  const [imageToPreview, setImageToPreview] = useState("");

  const previewImage = (image: any) => {
    setImagePreviewPopup(true);
    setImageToPreview(image);
  };

  const closeImagePreview = () => {
    setImagePreviewPopup(false);
    setImageToPreview("");
  };

  const onSave = () => {
    const error = Validations.validateProductInfoForm(productInformation);
    setErrors(error);
    if (!Object.keys(error).length) {
      const req = {
        productInfo: productInformation,
        onClose,
        isUpdate,
        pageNo,
        perPage,
        search,
        sortBy,
      };
      dispatch(saveProductInformation(req));
    }
  };
  useEffect(() => {
    if (updateData) {
      setIsUpdate(true);
      setProductInformation({
        ...updateData,
        brand: updateData?.brand_id
          ? { id: updateData?.brand_id, name: updateData?.brand_name }
          : null,
      });
      setImagePreview(updateData.image_url);
    }
  }, [updateData]);

  const onChangeImage = async (files: any, type: any) => {
    if (files[0]) {
      const file = files[0];
      const reader = new FileReader();
      reader.onload = function (e) {
        if (type === "image_url") {
          setProductInformation({ ...productInformation, [type]: files[0], delete_image: 0 });
          setImagePreview(e.target.result);
        } else {
          setProductInformation({ ...productInformation, [type]: files[0], delete_file: 0 });
        }
      };
      reader.readAsDataURL(file);
    }
  };
  const onImageRemove = async (e: any, type: any) => {
    e.stopPropagation();
    if (type === "image_url") {
      setProductInformation({ ...productInformation, [type]: null, delete_image: 1 });
      setImagePreview("");
    } else {
      setProductInformation({ ...productInformation, [type]: null, delete_file: 1 });
    }
  };

  const onChangeField = (e: any, field: string) => {
    setProductInformation({ ...productInformation, [field]: e.target.value });
  };

  const onChangeAutoComplete = (name: string, value: any) => {
    setProductInformation({ ...productInformation, [name]: value });
  };

  const dialogProps = {
    open,
    onSave,
    onClose,
    title: isUpdate ? "Update Product Information" : "Add Product Information",
    size: "md",
    saveTbtText: isUpdate ? "Update" : "Save",
  };
  return (
    <MDDialog {...dialogProps}>
      <Grid container spacing={2} display="flex" alignItems="flex-end">
        <Grid item xs={12} sm={12} md={6} lg={6}>
          <MDInput
            label="Title"
            variant="standard"
            required
            fullWidth
            name="title"
            placeholder="title"
            value={productInformation?.title || ""}
            onChange={(event: any) => onChangeField(event, "title")}
            error={errors && errors.title ? true : false}
            helperText={errors && errors.title}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6}>
          <MDInput
            label="Description"
            fullWidth
            multiline
            variant="standard"
            rows={2}
            name="description"
            onChange={(e: any) => onChangeField(e, "description")}
            value={productInformation?.description ? productInformation.description : ""}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6}>
          <MDInput
            label="Link Url"
            variant="standard"
            fullWidth
            name="link_url"
            placeholder="link_url"
            value={productInformation?.link_url || ""}
            onChange={(event: any) => onChangeField(event, "link_url")}
            error={errors && errors.link_url ? true : false}
            helperText={errors && errors.link_url}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6}>
          <CustomAutoSearch
            idKey="id"
            nameKey="name"
            fieldLabel="Brand"
            apiName="brands"
            responseKey="brands"
            name="brand"
            value={productInformation?.brand || null}
            onChange={onChangeAutoComplete}
            createNewLink={
              getPermission("mobile_app", "mobile_app_brands") ? "/mobile-app/brands" : ""
            }
          />
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <MDBox>
            <MDTypography variant="button">Image</MDTypography>
            <Grid container>
              <Grid item xs={12} md={8}>
                <CustomDropZone
                  multipleUpload={false}
                  acceptedFiles={{
                    "image/*": [".gif", ".png", ".jpg", ".jpeg"],
                  }}
                  onDrop={(e: any) => onChangeImage(e, "image_url")}
                >
                  Drop your image here, or Browse
                </CustomDropZone>
              </Grid>
              <Grid item xs={12} md={4} alignItems="center" display="flex">
                {imagePreview && (
                  <Grid
                    container
                    onClick={() => previewImage(imagePreview)}
                    justifyContent="center"
                    alignItems="center"
                    textAlign="center"
                  >
                    <Grid item className="imageWithDeleteIcon">
                      <img
                        width="90%"
                        style={{ maxHeight: "100px" }}
                        src={imagePreview}
                        alt="original"
                      />
                      <MDIconButton
                        tooltipName="Delete"
                        aria-label="Delete"
                        className="removeBtnClass"
                        onClick={(e: any) => onImageRemove(e, "image_url")}
                      >
                        <Icon fontSize="small">close</Icon>
                      </MDIconButton>
                    </Grid>
                  </Grid>
                )}
              </Grid>
            </Grid>
            {errors && errors.image && <CustomErrorMessage message={errors.image} />}
          </MDBox>
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <MDBox mt={2}>
            <MDTypography variant="button">File</MDTypography>
            <Grid container>
              <Grid item xs={12} md={8}>
                <CustomDropZone
                  multipleUpload={false}
                  acceptedFiles={{
                    "image/*": [".pdf", ".doc", ".xls"],
                  }}
                  onDrop={(e: any) => onChangeImage(e, "file_url")}
                >
                  Drop your File here, or Browse
                </CustomDropZone>
              </Grid>
              <Grid item xs={12} md={4} alignItems="center" display="flex">
                {productInformation?.file_url && (
                  <Grid container justifyContent="center" alignItems="center" textAlign="center">
                    <Grid item className="imageWithDeleteIcon">
                      <img src={fileimg} height="70" alt="file" />
                      <MDIconButton
                        tooltipName="Delete"
                        aria-label="Delete"
                        className="removeBtnClass"
                        onClick={(e: any) => onImageRemove(e, "file_url")}
                      >
                        <Icon fontSize="small">close</Icon>
                      </MDIconButton>
                    </Grid>
                  </Grid>
                )}
              </Grid>
            </Grid>
            {errors && errors.image && <CustomErrorMessage message={errors.image} />}
          </MDBox>
        </Grid>
      </Grid>
      <ImagePreview
        imageData={imageToPreview}
        open={isPreview}
        onCloseImage={() => {
          closeImagePreview();
        }}
      />
    </MDDialog>
  );
}

export default ViewDetails;
