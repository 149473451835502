import axios from "axios";
import MDBox from "components/MDBox";
import { useCallback, useEffect, useState } from "react";
import config from "config/config";
import DataTable from "examples/Tables/DataTable";
import { Card, CircularProgress, Icon, Typography } from "@mui/material";
import MDIconButton from "components/MDIconButton";
import MDButton from "components/MDButton";
import { notificationFail } from "store/slices/notificationSlice";
import Messages from "helper/messages";
import { SortProps } from "../../scorecard-dashboard/scorecard-table";
import DeleteDialog from "./deleteDialog";
import AddEditDialog from "./addEditDialog";

function formatDate(dateString: string) {
  const parts = dateString.split("-");
  const year = parts[0];
  const month = parts[1];
  const day = parts[2]?.split("T")[0];
  const formattedDate = `${month}/${day}/${year}`;
  return dateString === "-" || dateString === "" ? dateString : formattedDate;
}

function formatMonthDate(dateString: string) {
  const parts = dateString.split("-");
  const month = parts[0];
  const year = parts[1];
  const formattedDate = `${year}/${month}`;
  return dateString === "-" || dateString === "" ? dateString : formattedDate;
}

const getOrDefault = (value: any, defaultValue: any = "-") =>
  value && value !== "undefined" ? value : defaultValue;

function MasterData() {
  const [tableData, setTableData] = useState([]);
  const [search, setSearch] = useState("");
  const [sort, setSort] = useState([]);
  const [fetchApi, setFetchApi] = useState(false);
  const [selectedItem, setSelectedItem] = useState<{
    [key: string]: number | string;
  }>();
  const [selectedDialog, setSelectedDialog] = useState("");
  const [isLoading, setIsLoading] = useState(true);

  const fetchData = useCallback(() => {
    const processData = [...tableData];
    let result: {
      [key: string]: number | string;
    }[] = [];
    if (sort && sort[0]?.desc !== undefined) {
      result = processData.sort((a, b) => {
        const valueA = a[sort[0]?.id];
        const valueB = b[sort[0]?.id];

        if (valueA > valueB) {
          return sort[0]?.desc ? -1 : 1;
        }
        if (valueA < valueB) {
          return sort[0]?.desc ? 1 : -1;
        }
        return 0;
      });
    }

    if (search !== undefined) {
      const toSearchFrom = result.length ? result : processData;
      result = toSearchFrom.filter((data) => {
        // eslint-disable-next-line no-restricted-syntax
        for (const key in data) {
          if (data[key]?.toString().toLowerCase().includes(search.toLowerCase())) {
            return true;
          }
        }
        return false;
      });
    }
    const searchedData = result.length ? result : processData;

    return searchedData;
  }, [search, sort, tableData]);

  const openAddEditDialog = () => {
    setSelectedItem({});
    setSelectedDialog("create");
  };

  const dialogBox = (dialogProp: string) => {
    switch (dialogProp) {
      case "create":
        return (
          <AddEditDialog
            selectedItem={selectedItem}
            title="Add"
            selectedDialog={selectedDialog}
            onClose={setSelectedDialog}
            setSelectedItem={setSelectedItem}
            setFetchApi={setFetchApi}
            fetchApi={fetchApi}
          />
        );
      case "edit":
        return (
          <AddEditDialog
            selectedItem={selectedItem}
            title="Edit"
            selectedDialog={selectedDialog}
            onClose={setSelectedDialog}
            setSelectedItem={setSelectedItem}
            setFetchApi={setFetchApi}
            fetchApi={fetchApi}
          />
        );
      case "delete":
        return (
          <DeleteDialog
            onClose={setSelectedDialog}
            selectedItem={selectedItem}
            setSelectedItem={setSelectedItem}
            setFetchApi={setFetchApi}
            fetchApi={fetchApi}
          />
        );
      default:
        return null;
    }
  };

  const actionButtons = (object: any) => (
    <>
      <MDIconButton
        tooltipName="Edit"
        aria-label="Edit"
        color="info"
        onClick={() => {
          setSelectedItem(object);
          setSelectedDialog("edit");
        }}
      >
        <Icon fontSize="small">edit</Icon>
      </MDIconButton>
      <MDIconButton
        tooltipName="Delete"
        aria-label="Delete"
        color="error"
        onClick={() => {
          setSelectedItem(object);
          setSelectedDialog("delete");
        }}
      >
        <Icon fontSize="small">delete</Icon>
      </MDIconButton>
    </>
  );

  useEffect(() => {
    setIsLoading(true);
    axios
      .get(`${config.REPORT_URL}/report/getMasterData`, {
        headers: {
          "x-api-key": config.REPORT_KEY,
        },
      })
      .then((response) => {
        const result = response.data.data.map((obj: any) => ({
          action: actionButtons(obj),
          sola_id: getOrDefault(obj.sola_id),
          rm_location_id: getOrDefault(obj.rm_location_id),
          rm_property_id: getOrDefault(obj.rm_property_id),
          rm_location_name: getOrDefault(obj.rm_location_name),
          nps_score: getOrDefault(obj.nps_score),
          nps_of_participants: getOrDefault(obj.nps_of_participants),
          open_date: getOrDefault(obj.open_date),
          royalty_rate: getOrDefault(obj.royalty_rate),
          marketing_rate: getOrDefault(obj.marketing_rate),
          site_name: getOrDefault(obj.site_name),
          city: getOrDefault(obj.city),
          state: getOrDefault(obj.state),
          address: getOrDefault(obj.address),
          contact_name: getOrDefault(obj.contact_name),
          entity: getOrDefault(obj.entity),
          msa: getOrDefault(obj.msa),
          country: getOrDefault(obj.country),
          region: getOrDefault(obj.region),
          obsolete: getOrDefault(obj.obsolete, 0),
          closed_date: getOrDefault(obj.closed_date),
          month_year: getOrDefault(obj.month_year),
        }));
        setTableData(result);
      })
      .catch((error) => {
        notificationFail(error?.response?.data?.message || Messages.ERROR.SOMETHING_WENT_WRONG);
      })
      .finally(() => {
        fetchData();
        setIsLoading(false);
      });
  }, [fetchApi]);

  const tableColumns = [
    {
      Header: "",
      disableSortBy: true,
      accessor: "checkbox",
      export: false,
      width: "80px",
      checked: true,
    },
    {
      Header: "Action",
      accessor: "action",
      width: "200px",
      export: false,
      disableSortBy: true,
      checked: true,
    },
    {
      Header: "Sola ID",
      accessor: "sola_id",
      width: "200px",
      export: true,
      disableCheck: true,
      checked: true,
    },
    {
      Header: "RM Location ID",
      accessor: "rm_location_id",
      width: "200px",
      export: true,
      disableCheck: true,
      checked: true,
    },
    {
      Header: "RM Property ID",
      accessor: "rm_property_id",
      width: "200px",
      export: true,
      disableCheck: true,
      checked: true,
    },
    {
      Header: "RM Location Name",
      accessor: "rm_location_name",
      width: "200px",
      export: true,
      disableCheck: true,
      checked: true,
    },
    {
      Header: "NPS Score",
      accessor: "nps_score",
      width: "200px",
      export: true,
      disableCheck: true,
      checked: true,
    },
    {
      Header: "NPS of Participants",
      accessor: "nps_of_participants",
      width: "200px",
      export: true,
      disableCheck: true,
      checked: true,
    },
    {
      Header: "Open Date",
      accessor: (d: any) => formatDate(d.open_date),
      width: "200px",
      export: true,
      disableCheck: true,
      checked: true,
    },
    {
      Header: "Royalty Rate",
      accessor: "royalty_rate",
      width: "200px",
      export: true,
      disableCheck: true,
      checked: true,
    },
    {
      Header: "Marketing Rate",
      accessor: "marketing_rate",
      width: "200px",
      export: true,
      disableCheck: true,
      checked: true,
    },
    {
      Header: "Site Name",
      accessor: "site_name",
      width: "200px",
      export: true,
      disableCheck: true,
      checked: true,
    },
    {
      Header: "City",
      accessor: "city",
      width: "200px",
      export: true,
      disableCheck: true,
      checked: true,
    },
    {
      Header: "State",
      accessor: "state",
      width: "200px",
      export: true,
      disableCheck: true,
      checked: true,
    },
    {
      Header: "Address",
      accessor: "address",
      width: "200px",
      export: true,
      disableCheck: true,
      checked: true,
    },
    {
      Header: "Contact Name",
      accessor: "contact_name",
      width: "200px",
      export: true,
      disableCheck: true,
      checked: true,
    },
    {
      Header: "Entity",
      accessor: "entity",
      width: "200px",
      export: true,
      disableCheck: true,
      checked: true,
    },
    {
      Header: "MSA",
      accessor: "msa",
      width: "200px",
      export: true,
      disableCheck: true,
      checked: true,
    },
    {
      Header: "Country",
      accessor: "country",
      width: "200px",
      export: true,
      disableCheck: true,
      checked: true,
    },
    {
      Header: "Region",
      accessor: "region",
      width: "200px",
      export: true,
      disableCheck: true,
      checked: true,
    },
    {
      Header: "Obsolete",
      accessor: "obsolete",
      width: "200px",
      export: true,
      disableCheck: true,
      checked: true,
    },
    {
      Header: "Closed Date",
      accessor: (d: any) => formatDate(d.closed_date),
      width: "200px",
      export: true,
      disableCheck: true,
      checked: true,
    },
    {
      Header: "Month-Year",
      accessor: (d: any) => formatMonthDate(d.month_year),
      width: "200px",
      export: true,
      disableCheck: true,
      checked: true,
    },
  ];

  return (
    <MDBox mt={6}>
      {!isLoading ? (
        fetchData().length ? (
          <Card>
            <MDBox
              bgColor="dark"
              color="white"
              coloredShadow="dark"
              borderRadius="xl"
              alignItems="center"
              justifyContent="space-between"
              pt={1}
              pb={1}
              pl={2}
              pr={2}
              ml={2}
              mr={2}
              display="flex"
              mt={-3}
              className="page-header"
            >
              <p className="page-header-label">Master Data</p>
              <MDButton
                variant="gradient"
                color="light"
                size="small"
                className="xs-small"
                onClick={openAddEditDialog}
              >
                <Icon sx={{ fontWeight: "bold" }}>add</Icon> Add New
              </MDButton>
            </MDBox>
            <DataTable
              table={{
                columns: tableColumns,
                rows: fetchData(),
              }}
              canSearch
              fetchData={({
                sortBy,
                search,
              }: {
                sortBy: Array<SortProps>;
                search: string;
                filterData: any;
              }) => {
                setSearch(search);
                setSort(sortBy);
              }}
            />
            {dialogBox(selectedDialog)}
          </Card>
        ) : (
          <MDBox sx={{ minHeight: "200" }}>
            <Card
              variant="outlined"
              sx={{
                height: "200px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Typography variant="h5">No record found for these values</Typography>
            </Card>
          </MDBox>
        )
      ) : (
        <MDBox display="flex" justifyContent="center" alignItems="center" mt={3}>
          <CircularProgress style={{ color: "#1A73E8" }} />
        </MDBox>
      )}
    </MDBox>
  );
}

export default MasterData;
