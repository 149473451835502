import { Icon } from "@mui/material";
import MDButton from "components/MDButton";
import { useNavigate } from "react-router-dom";

function Index(props: any): JSX.Element | null {
  const { label, historyState, path, historyData } = props;
  const navigate = useNavigate();

  const onBackArrowClick = () => {
    if (path || (historyState && Object.keys(historyState).length)) {
      navigate(path || historyState?.prevPath, {
        state: { defaultTab: historyState?.defaultTab || false, data: historyData || null },
        replace: true,
      });
    } else navigate(-1);
  };

  return (
    <MDButton
      variant="text"
      color="dark"
      size="small"
      startIcon={
        <Icon fontSize="small" sx={{ mb: "3px" }}>
          arrow_back
        </Icon>
      }
      sx={{ padding: 0, fontSize: "14px", color: "#227bea", textTransform: "capitalize" }}
      onClick={onBackArrowClick}
    >
      {label}
    </MDButton>
  );
}
export default Index;
