import { createSlice, Draft, PayloadAction } from "@reduxjs/toolkit";

interface InitialState {
  importDealList: any;
  importDealDataList: any;
}

const initialState: InitialState = {
  importDealList: [],
  importDealDataList: [],
};

const DealImportSlice = createSlice({
  name: "solaWebSite",
  initialState,
  reducers: {
    setImportDealList: (state: Draft<InitialState>, action: PayloadAction<any>) => ({
      ...state,
      importDealList: action.payload,
    }),
    setImportDealDataList: (state: Draft<InitialState>, action: PayloadAction<any>) => ({
      ...state,
      importDealDataList: action.payload,
    }),
  },
});

export const { setImportDealList, setImportDealDataList } = DealImportSlice.actions;

export default DealImportSlice.reducer;
