import { createSlice, Draft, PayloadAction } from "@reduxjs/toolkit";

interface InitialState {
  msaList: any;
  sola10kImagesList: any;
  locationList: any;
  reportsList: any;
  reportsTypesList: any;
  mysolaImageList: any;
  countriesList: any;
  maintenanceContactList: any;
  updateSolaWebsitesList: any;
  contactInquiriesList: any;
  stylistMessagesList: any;
  categoryList: any;
  tagsList: any;
  allStylists: any;
  stylistUnitsList: any;
  stylistSearchResult: any;
  legacyAdmins: any;
  rmSearchResult: any;
  matchingRentManagers: any;
  matchingStylist: any;
  externalList: any;
  testimonialsList: any;
  callFireLogList: any;
  gloassGeniusLogList: any;
  navEventsList: any;
  rmUnitList: any;
  rmEventsList: any;
  contactIquiryData: any;
  faqsList: any;
  amenitiesList: any;
  helpDeskReqList: any;
  stylistDataForApprovalReq: any;
  tabWiseSolaProList: any;
  frenchiseeRmMatchData: any;
  analyticsList: any;
}

const initialState: InitialState = {
  msaList: [],
  sola10kImagesList: [],
  locationList: [],
  categoryList: [],
  tagsList: [],
  reportsList: [],
  contactInquiriesList: [],
  reportsTypesList: [
    { value: "all_locations", name: "All Locations" },
    { value: "all_contact_form_submission", name: "All Contact Form Submission" },
    { value: "request_tour_inquiries", name: "Request Tour Inquiries" },
    { value: "all_stylists", name: "All Stylists" },
    { value: "all_terminated_stylists_report", name: "All Terminated Stylists Report" },
    { value: "solapro_solagenius_penetration", name: "Sola Pro / SolaGenius Penetration" },
  ],
  mysolaImageList: [],
  countriesList: [],
  maintenanceContactList: [],
  updateSolaWebsitesList: [],
  stylistMessagesList: [],
  allStylists: [],
  stylistUnitsList: [],
  stylistSearchResult: [],
  legacyAdmins: [],
  rmSearchResult: [],
  matchingRentManagers: [],
  matchingStylist: [],
  externalList: [],
  testimonialsList: [],
  callFireLogList: [],
  gloassGeniusLogList: [],
  navEventsList: [],
  rmUnitList: [],
  rmEventsList: [],
  contactIquiryData: null,
  faqsList: [],
  amenitiesList: [],
  helpDeskReqList: [],
  stylistDataForApprovalReq: null,
  tabWiseSolaProList: [],
  frenchiseeRmMatchData: [],
  analyticsList: null,
};

const systemAdminSlice = createSlice({
  name: "solaWebSite",
  initialState,
  reducers: {
    msaList: (state: Draft<InitialState>, action: PayloadAction<any>) => ({
      ...state,
      msaList: action.payload,
    }),
    sola10kImagesList: (state: Draft<InitialState>, action: PayloadAction<any>) => ({
      ...state,
      sola10kImagesList: action.payload,
    }),
    locationList: (state: Draft<InitialState>, action: PayloadAction<any>) => ({
      ...state,
      locationList: action.payload,
    }),
    mysolaImageList: (state: Draft<InitialState>, action: PayloadAction<any>) => ({
      ...state,
      mysolaImageList: action.payload,
    }),
    countriesList: (state: Draft<InitialState>, action: PayloadAction<any>) => ({
      ...state,
      countriesList: action.payload,
    }),
    setReportsList: (state: Draft<InitialState>, action: PayloadAction<any>) => ({
      ...state,
      reportsList: action.payload,
    }),
    setContactInquiriesList: (state: Draft<InitialState>, action: PayloadAction<any>) => ({
      ...state,
      contactInquiriesList: action.payload,
    }),
    setStylistMessages: (state: Draft<InitialState>, action: PayloadAction<any>) => ({
      ...state,
      stylistMessagesList: action.payload,
    }),
    setMaintenanceContact: (state: Draft<InitialState>, action: PayloadAction<any>) => ({
      ...state,
      maintenanceContactList: action.payload,
    }),
    setCategoryList: (state: Draft<InitialState>, action: PayloadAction<any>) => ({
      ...state,
      categoryList: action.payload,
    }),
    setTagList: (state: Draft<InitialState>, action: PayloadAction<any>) => ({
      ...state,
      tagsList: action.payload,
    }),
    setUpdateSolaWebsitesList: (state: Draft<InitialState>, action: PayloadAction<any>) => ({
      ...state,
      updateSolaWebsitesList: action.payload,
    }),
    setAllStylists: (state: Draft<InitialState>, action: PayloadAction<any>) => ({
      ...state,
      allStylists: action.payload,
    }),
    setStylistUnitsList: (state: Draft<InitialState>, action: PayloadAction<any>) => ({
      ...state,
      stylistUnitsList: action.payload,
    }),
    setSearchResultStylist: (state: Draft<InitialState>, action: PayloadAction<any>) => ({
      ...state,
      stylistSearchResult: action.payload,
    }),
    setLegacyAdmins: (state: Draft<InitialState>, action: PayloadAction<any>) => ({
      ...state,
      legacyAdmins: action.payload,
    }),
    setRmSearchResultStylist: (state: Draft<InitialState>, action: PayloadAction<any>) => ({
      ...state,
      rmSearchResult: action.payload,
    }),
    setMatchingRentManager: (state: Draft<InitialState>, action: PayloadAction<any>) => ({
      ...state,
      matchingRentManagers: action.payload,
    }),
    setMatchingStylist: (state: Draft<InitialState>, action: PayloadAction<any>) => ({
      ...state,
      matchingStylist: action.payload,
    }),
    setExternalList: (state: Draft<InitialState>, action: PayloadAction<any>) => ({
      ...state,
      externalList: action.payload,
    }),
    setTestimonialsList: (state: Draft<InitialState>, action: PayloadAction<any>) => ({
      ...state,
      testimonialsList: action.payload,
    }),
    setCallFireLogList: (state: Draft<InitialState>, action: PayloadAction<any>) => ({
      ...state,
      callFireLogList: action.payload,
    }),
    setGloassGeniusLogList: (state: Draft<InitialState>, action: PayloadAction<any>) => ({
      ...state,
      gloassGeniusLogList: action.payload,
    }),
    setNavEventsList: (state: Draft<InitialState>, action: PayloadAction<any>) => ({
      ...state,
      navEventsList: action.payload,
    }),
    setRmUnitListList: (state: Draft<InitialState>, action: PayloadAction<any>) => ({
      ...state,
      rmUnitList: action.payload,
    }),
    setRMEventsList: (state: Draft<InitialState>, action: PayloadAction<any>) => ({
      ...state,
      rmEventsList: action.payload,
    }),
    setContactIquiryData: (state: Draft<InitialState>, action: PayloadAction<any>) => ({
      ...state,
      contactIquiryData: action.payload,
    }),
    // Faqs call
    faqsList: (state: Draft<InitialState>, action: PayloadAction<any>) => ({
      ...state,
      faqsList: action.payload,
    }),
    amenitiesList: (state: Draft<InitialState>, action: PayloadAction<any>) => ({
      ...state,
      amenitiesList: action.payload,
    }),
    setHelpDeskReqList: (state: Draft<InitialState>, action: PayloadAction<any>) => ({
      ...state,
      helpDeskReqList: action.payload,
    }),
    setStylistDataForApprovalReq: (state: Draft<InitialState>, action: PayloadAction<any>) => ({
      ...state,
      stylistDataForApprovalReq: action.payload,
    }),
    setTabWiseSolaProList: (state: Draft<InitialState>, action: PayloadAction<any>) => ({
      ...state,
      tabWiseSolaProList: action.payload,
    }),
    setFrenchiseeRmMatchData: (state: Draft<InitialState>, action: PayloadAction<any>) => ({
      ...state,
      frenchiseeRmMatchData: action.payload,
    }),
    setAnalyticsList: (state: Draft<InitialState>, action: PayloadAction<any>) => ({
      ...state,
      analyticsList: action.payload,
    }),
  },
});

export const {
  msaList,
  sola10kImagesList,
  locationList,
  countriesList,
  mysolaImageList,
  setReportsList,
  setContactInquiriesList,
  setStylistMessages,
  setMaintenanceContact,
  setCategoryList,
  setTagList,
  setUpdateSolaWebsitesList,
  setAllStylists,
  setStylistUnitsList,
  setSearchResultStylist,
  setLegacyAdmins,
  setRmSearchResultStylist,
  setMatchingRentManager,
  setMatchingStylist,
  setExternalList,
  setTestimonialsList,
  setCallFireLogList,
  setGloassGeniusLogList,
  setNavEventsList,
  setRmUnitListList,
  setRMEventsList,
  setContactIquiryData,
  faqsList,
  amenitiesList,
  setHelpDeskReqList,
  setStylistDataForApprovalReq,
  setTabWiseSolaProList,
  setFrenchiseeRmMatchData,
  setAnalyticsList,
} = systemAdminSlice.actions;

export default systemAdminSlice.reducer;
