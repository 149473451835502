import { useState, useEffect } from "react";
import { Grid, Icon } from "@mui/material";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDDialog from "components/MDDialog";
import MDIconButton from "components/MDIconButton";
import MDTypography from "components/MDTypography";
import CustomDropZone from "components/CustomDropZone";
import { useAppDispatch, useAppSelector } from "store/store";
import constants from "helper/constants";
import messages from "helper/messages";
import {
  getPreRecordedVoice,
  savePreRecordedVoice,
  deletePreRecordedVoice,
} from "store/thunk/locationThunk";
import { notificationFail } from "store/slices/notificationSlice";

function preRecordedVoice(): JSX.Element {
  const dispatch = useAppDispatch();
  const { preRecordedVoices } = useAppSelector((state) => state.locationSlice);
  const [recordingFile, setRecordingFile] = useState(null);
  const [deletePopup, setDeletePopup] = useState(false);

  const getPreRecordedData = () => {
    dispatch(getPreRecordedVoice({}));
  };

  useEffect(() => {
    getPreRecordedData();
  }, []);

  const onDropFile = (e: any) => {
    const filenameArray = e?.[0]?.name?.split(".");
    if (e?.[0]) {
      if (!constants?.callTreeAudioExts?.includes(filenameArray[filenameArray.length - 1])) {
        dispatch(
          notificationFail(
            `Unsupported file type, it should be ${constants?.callTreeAudioExts?.join(", ")}.`
          )
        );
        setRecordingFile(null);
      } else {
        setRecordingFile(e?.[0]);
      }
    } else {
      setRecordingFile(null);
    }
  };

  const updateRecordedVoice = () => {
    dispatch(savePreRecordedVoice({ digit: 1, recordingFile, setRecordingFile }));
  };

  const deleteRecordedVoice = () => {
    dispatch(deletePreRecordedVoice({ id: preRecordedVoices?.[0]?.id, setDeletePopup }));
  };

  const dialogProps: any = {
    open: deletePopup,
    onClose: () => setDeletePopup(false),
    title: "Delete pre-recorded voice",
    saveTbtText: "Yes",
    closeBtnText: "No",
    deleteBtn: true,
    saveBtn: false,
    onSave: () => deleteRecordedVoice(),
    size: "sm",
  };

  return (
    <MDBox pb={2}>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          {preRecordedVoices?.[0]?.voice_url && (
            <MDBox display="flex" alignItems="center">
              <MDTypography variant="h6" pr={1}>
                1 - Salon Customers
              </MDTypography>
              <audio controls>
                <source src={preRecordedVoices?.[0]?.voice_url} />
                <track
                  src={preRecordedVoices?.[0]?.voice_url}
                  kind="captions"
                  label="english_captions"
                />
                Your browser does not support the audio element.
              </audio>
              <MDIconButton
                tooltipName="Delete"
                aria-label="Delete"
                onClick={() => setDeletePopup(true)}
              >
                <Icon fontSize="small" color="error">
                  delete
                </Icon>
              </MDIconButton>
            </MDBox>
          )}
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <MDBox>
            <CustomDropZone
              minHeight="20px"
              multipleUpload={false}
              style={{ display: "none" }}
              onDrop={onDropFile}
            >
              Add Audio
              <MDTypography variant="body2" fontSize="12px">
                ({constants?.callTreeAudioExts?.join(", ")})
              </MDTypography>
            </CustomDropZone>
            {recordingFile && (
              <MDTypography variant="subtitle2">
                {recordingFile?.name}
                <MDIconButton
                  tooltipName="Delete"
                  aria-label="Delete"
                  onClick={() => setRecordingFile(null)}
                >
                  <Icon fontSize="small" color="error">
                    delete
                  </Icon>
                </MDIconButton>
              </MDTypography>
            )}
          </MDBox>
        </Grid>
        {recordingFile && (
          <Grid item xs={12} sm={12} md={12} lg={12} display="flex" justifyContent="end">
            <MDButton variant="gradient" color="info" size="small" onClick={updateRecordedVoice}>
              {preRecordedVoices?.[0]?.voice_url ? "Update" : "Save"}
            </MDButton>
          </Grid>
        )}
        {deletePopup && (
          <MDDialog {...dialogProps}>
            <MDTypography>{messages.GENERAL.SURE_TO_DELETE}</MDTypography>
          </MDDialog>
        )}
      </Grid>
    </MDBox>
  );
}

export default preRecordedVoice;
