import { useState, useEffect } from "react";
import { Grid } from "@mui/material";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import CustomBackButton from "components/CustomBackButton";
import NumberStatus from "layouts/pages/locations/phone-numbers/numberStatus";
import SmsStatus from "layouts/pages/locations/phone-numbers/smsStatus";
import Locations from "layouts/pages/locations/phone-numbers/locations";
import CallTreeStatus from "layouts/pages/locations/phone-numbers/callTreeStatus";
import CallTreeSetting from "layouts/pages/locations/phone-numbers/callTreeSetting";
import SmsForwardingNumber from "layouts/pages/locations/phone-numbers/smsForwardingNumber";
import DasicInfo from "layouts/pages/locations/phone-numbers/basicInfo";
import Sidenav from "layouts/pages/locations/phone-numbers/sidenav";
import { useAppSelector, useAppDispatch } from "store/store";
import {
  getPhoneDetailById,
  updateTwillioPhoneSetting,
  getPreRecordedVoice,
} from "store/thunk/locationThunk";
import { setPhoneNumberDetails } from "store/slices/locationSlice";
import { useLocation, useNavigate } from "react-router-dom";
import Validation from "helper/validations";
import { scrollToErrorByClass } from "helper/services";

const sideMenu = [
  { icon: "description", label: "Basic Info", href: "basic-info" },
  { icon: "phone", label: "Number Status", href: "number-status" },
  { icon: "sms", label: "SMS Status", href: "sms-status" },
  { icon: "receipt_long", label: "Locations", href: "locations" },
  { icon: "ring_volume", label: "Call Tree Status", href: "call-tree-status" },
  { icon: "phone_forwarded_icon", label: "SMS Forwarding Number", href: "sms-forward-number" },
];

function phoneNumberDetail(): JSX.Element {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const phoneState: any = useLocation();
  const [phoneDetail, setPhoneDetail] = useState<any>({});
  const { preRecordedVoices } = useAppSelector((state) => state.locationSlice);
  const { phoneNumberDetails, phoneTreeSettingDetails } = useAppSelector(
    (state) => state.locationSlice
  );
  const [sidenavItems, setSidenavItems] = useState<any>(sideMenu);
  const [errors, setErrors] = useState<any>({});
  const onChangeField = (event: any) => {
    if (event.target.name === "call_tree_status") {
      if (!event.target.checked) {
        setSidenavItems(sideMenu);
      } else {
        let mainSlides: any = [...sideMenu];
        mainSlides.push({
          icon: "call_split",
          label: "Call Tree Setting",
          href: "call-tree-setting",
        });
        const startElements = mainSlides?.slice(0, 5);
        const last2Elements = mainSlides?.slice(Math.max(mainSlides.length - 2, 0)).reverse();
        mainSlides = [];
        mainSlides.push(...startElements, ...last2Elements);
        setSidenavItems(mainSlides);
      }
    }
    setPhoneDetail({ ...phoneDetail, [event.target.name]: event.target.checked });
  };

  const onCheckField = (event: any) => {
    setPhoneDetail({ ...phoneDetail, [event.target.name]: event.target.checked });
  };

  const onChangeTextField = (event: any) => {
    setPhoneDetail({ ...phoneDetail, [event.target.name]: event.target.value });
  };

  useEffect(() => {
    dispatch(getPreRecordedVoice({}));
    const clearPhoneDetails = () => {
      dispatch(setPhoneNumberDetails(null));
    };

    return () => clearPhoneDetails();
  }, []);

  useEffect(() => {
    if (phoneDetail?.call_tree_status) {
      setPhoneDetail({
        ...phoneDetail,
        use_pre_recorded_instructions: phoneDetail?.use_pre_recorded_instructions || true,
      });
    }

    if (phoneTreeSettingDetails) {
      setPhoneDetail({
        ...phoneDetail,
        ...phoneTreeSettingDetails,
        id: phoneDetail.id,
        tree_setting_id: phoneTreeSettingDetails.id,
      });
    }
  }, [phoneTreeSettingDetails]);

  useEffect(() => {
    if (phoneState?.state?.phoneId) {
      dispatch(getPhoneDetailById({ id: phoneState?.state?.phoneId }));
    } else {
      navigate(`/locations/phone-numbers`);
    }
  }, [phoneState]);

  useEffect(() => {
    if (phoneNumberDetails && Object.keys(phoneNumberDetails)?.length) {
      setPhoneDetail(phoneNumberDetails);
    }
    if (phoneNumberDetails?.call_tree_status) {
      let mainSlides: any = [...sideMenu];
      mainSlides.push({
        icon: "call_split",
        label: "Call Tree Setting",
        href: "call-tree-setting",
      });
      const startElements = mainSlides?.slice(0, 5);
      const last2Elements = mainSlides?.slice(Math.max(mainSlides.length - 2, 0)).reverse();
      mainSlides = [];
      mainSlides.push(...startElements, ...last2Elements);
      setSidenavItems(mainSlides);
    }
  }, [phoneNumberDetails]);

  const updateSettings = () => {
    const error = Validation.validateUpdatePhoneNumber({
      ...phoneDetail,
      preRecordedVoice1: preRecordedVoices?.[0]?.voice_url,
    });
    setErrors(error);
    if (!Object.keys(error).length) {
      let status = phoneDetail?.status;
      if (status === true || status === false) {
        if (status) {
          status = "enabled";
        } else {
          status = "disabled";
        }
      }
      const req = {
        id: phoneDetail?.id,
        sms_status: phoneDetail?.sms_status,
        call_tree_status: phoneDetail?.call_tree_status,
        number_name: phoneDetail?.number_name,
        number_description: phoneDetail?.number_description,
        sms_forwarding_number: phoneDetail?.sms_forwarding_number,
        status,
      };
      if (phoneDetail?.call_tree_status) {
        Object.assign(req, {
          call_tree_settings: {
            pre_recorded_instruction: phoneDetail?.pre_recorded_instruction,
            use_pre_recorded_instructions: phoneDetail?.use_pre_recorded_instructions || false,
            pre_recorded_instruction_2: phoneDetail?.pre_recorded_instruction_2,
            use_pre_recorded_instructions_2: phoneDetail?.use_pre_recorded_instructions_2 || false,
            pre_recorded_instruction_3: phoneDetail?.pre_recorded_instruction_3,
            use_pre_recorded_instructions_3: phoneDetail?.use_pre_recorded_instructions_3 || false,
            pre_recorded_instruction_4: phoneDetail?.pre_recorded_instruction_4,
            use_pre_recorded_instructions_4: phoneDetail?.use_pre_recorded_instructions_4 || false,
            connect_phone_number_id: phoneDetail?.id,
            forwarding_no_1: phoneDetail?.forwarding_no_1 || "",
            forwarding_no_2: phoneDetail?.forwarding_no_2 || "",
            forwarding_no_3: phoneDetail?.forwarding_no_3 || "",
            forwarding_no_4: phoneDetail?.forwarding_no_4 || "",
            forwarding_notes_1: phoneDetail?.forwarding_notes_1 || "",
            forwarding_notes_2: phoneDetail?.forwarding_notes_2 || "",
            forwarding_notes_3: phoneDetail?.forwarding_notes_3 || "",
            forwarding_notes_4: phoneDetail?.forwarding_notes_4 || "",
            id: phoneDetail?.tree_setting_id,
            forwarding_number: "",
          },
        });
      } else {
        Object.assign(req, {
          call_tree_settings: {
            forwarding_number:
              phoneDetail?.forwarding_number && phoneDetail?.forwarding_number !== "+1 ("
                ? phoneDetail?.forwarding_number
                : "",
            pre_recorded_instruction: "",
            use_pre_recorded_instructions: false,
            pre_recorded_instruction_2: "",
            use_pre_recorded_instructions_2: false,
            pre_recorded_instruction_3: "",
            use_pre_recorded_instructions_3: false,
            pre_recorded_instruction_4: "",
            use_pre_recorded_instructions_4: false,
            connect_phone_number_id: phoneDetail?.id,
            forwarding_no_1: "",
            forwarding_no_2: "",
            forwarding_no_3: "",
            forwarding_no_4: "",
            forwarding_notes_1: "",
            forwarding_notes_2: "",
            forwarding_notes_3: "",
            forwarding_notes_4: "",
            id: phoneDetail?.tree_setting_id,
          },
        });
      }
      dispatch(updateTwillioPhoneSetting(req));
    } else {
      scrollToErrorByClass(error);
    }
  };

  const attr = {
    phoneDetail,
    onChangeField,
    onChangeTextField,
    setPhoneDetail,
    onCheckField,
    sidenavItems,
    errors,
    preRecordedVoice1: preRecordedVoices?.[0]?.voice_url,
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox mx={2}>
        <Grid container spacing={3} sx={{ display: "flex", justifyContent: "space-between" }}>
          <Grid item>
            <CustomBackButton label="Back To Phone Numbers" />
          </Grid>
        </Grid>
      </MDBox>
      <MDBox pb={2}>
        <Grid container spacing={3} mt={1}>
          <Grid item xs={12} lg={3}>
            <Sidenav {...attr} />
          </Grid>
          <Grid item xs={12} lg={9}>
            <MDBox mb={3}>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <DasicInfo {...attr} />
                </Grid>
                <Grid item xs={12}>
                  <NumberStatus {...attr} />
                </Grid>
                <Grid item xs={12}>
                  <SmsStatus {...attr} />
                </Grid>
                <Grid item xs={12}>
                  <Locations {...attr} />
                </Grid>
                <Grid item xs={12}>
                  <CallTreeStatus {...attr} />
                </Grid>
                {phoneDetail?.call_tree_status && (
                  <Grid item xs={12}>
                    <CallTreeSetting {...attr} />
                  </Grid>
                )}
                <Grid item xs={12}>
                  <SmsForwardingNumber {...attr} />
                </Grid>
                <Grid item xs={12} sm={12} display="flex" justifyContent="end">
                  <MDButton
                    variant="gradient"
                    color="info"
                    size="small"
                    onClick={updateSettings}
                    className="fixed-button"
                  >
                    Update Settings
                  </MDButton>
                </Grid>
              </Grid>
            </MDBox>
          </Grid>
        </Grid>
      </MDBox>
    </DashboardLayout>
  );
}

export default phoneNumberDetail;
