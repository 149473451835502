import axios from "axios";
import MDBox from "components/MDBox";
import { useCallback, useEffect, useState } from "react";
import config from "config/config";
import DataTable from "examples/Tables/DataTable";
import { Card, CircularProgress, Icon } from "@mui/material";
import MDIconButton from "components/MDIconButton";
import MDButton from "components/MDButton";
import { notificationFail } from "store/slices/notificationSlice";
import Messages from "helper/messages";
import { SortProps } from "../../scorecard-dashboard/scorecard-table";
import DeleteDialog from "./deleteRoyaltyDialog";
import AddRoyaltyDialog from "./addRoyaltyDialog";

function formatDate(dateString: string) {
  const parts = dateString?.split("-");
  const year = parts[0];
  const month = parts[1];
  const formattedDate = `${month}/${year}`;
  return formattedDate;
}

function MasterData() {
  const [tableData, setTableData] = useState([]);
  const [search, setSearch] = useState("");
  const [sort, setSort] = useState([]);
  const [fetchApi, setFetchApi] = useState(false);
  const [selectedItem, setSelectedItem] = useState<{
    [key: string]: number | string;
  }>();
  const [selectedDialog, setSelectedDialog] = useState("");
  const [isLoading, setIsLoading] = useState(true);

  const fetchData = useCallback(() => {
    const processData = [...tableData];
    let result: {
      [key: string]: number | string;
    }[] = [];
    if (sort && sort[0]?.desc !== undefined) {
      result = processData.sort((a, b) => {
        const valueA = a[sort[0]?.id];
        const valueB = b[sort[0]?.id];

        if (valueA > valueB) {
          return sort[0]?.desc ? -1 : 1;
        }
        if (valueA < valueB) {
          return sort[0]?.desc ? 1 : -1;
        }
        return 0;
      });
    }

    if (search !== undefined) {
      const toSearchFrom = result.length ? result : processData;
      result = toSearchFrom.filter((data) => {
        // eslint-disable-next-line no-restricted-syntax
        for (const key in data) {
          if (data[key]?.toString().toLowerCase().includes(search.toLowerCase())) {
            return true;
          }
        }
        return false;
      });
    }
    const searchedData = result.length ? result : processData;

    return searchedData;
  }, [search, sort, tableData]);

  const openAddEditDialog = () => {
    setSelectedItem({});
    setSelectedDialog("create");
  };

  const dialogBox = (dialogProp: string) => {
    switch (dialogProp) {
      case "create":
        return (
          <AddRoyaltyDialog
            selectedItem={selectedItem}
            title="Add"
            selectedDialog={selectedDialog}
            onClose={setSelectedDialog}
            setSelectedItem={setSelectedItem}
            setFetchApi={setFetchApi}
            fetchApi={fetchApi}
          />
        );
      case "edit":
        return (
          <AddRoyaltyDialog
            selectedItem={selectedItem}
            title="Edit"
            selectedDialog={selectedDialog}
            onClose={setSelectedDialog}
            setSelectedItem={setSelectedItem}
            setFetchApi={setFetchApi}
            fetchApi={fetchApi}
          />
        );
      case "delete":
        return (
          <DeleteDialog
            onClose={setSelectedDialog}
            selectedItem={selectedItem}
            setSelectedItem={setSelectedItem}
            setFetchApi={setFetchApi}
            fetchApi={fetchApi}
          />
        );
      default:
        return null;
    }
  };

  const actionButtons = (object: any) => (
    <>
      <MDIconButton
        tooltipName="Edit"
        aria-label="Edit"
        color="info"
        onClick={() => {
          setSelectedItem(object);
          setSelectedDialog("edit");
        }}
      >
        <Icon fontSize="small">edit</Icon>
      </MDIconButton>
      <MDIconButton
        tooltipName="Delete"
        aria-label="Delete"
        color="error"
        onClick={() => {
          setSelectedItem(object);
          setSelectedDialog("delete");
        }}
      >
        <Icon fontSize="small">delete</Icon>
      </MDIconButton>
    </>
  );

  useEffect(() => {
    setIsLoading(true);
    axios
      .get(`${config.REPORT_URL}/report/getRoyaltyData`, {
        headers: {
          "x-api-key": config.REPORT_KEY,
        },
      })
      .then((response) => {
        const result = response.data.data.map((obj: any) => ({
          action: actionButtons(obj),
          sola_id: obj.sola_id ? obj.sola_id : "-",
          income: obj.income ? obj.income : "-",
          month_year: obj.month_year ? obj.month_year : "-",
        }));
        setTableData(result);
      })
      .catch((error) => {
        notificationFail(error?.response?.data?.message || Messages.ERROR.SOMETHING_WENT_WRONG);
      })
      .finally(() => {
        fetchData();
        setIsLoading(false);
      });
  }, [fetchApi]);

  const tableColumns = [
    {
      Header: "Action",
      accessor: "action",
      width: "200px",
      export: false,
      disableSortBy: true,
      checked: true,
    },
    {
      Header: "Sola ID",
      accessor: "sola_id",
      width: "200px",
      export: true,
      disableCheck: true,
      checked: true,
    },
    {
      Header: "Month-Year",
      accessor: (d: any) => formatDate(d.month_year),
      width: "200px",
      export: true,
      disableCheck: true,
      checked: true,
    },
    {
      Header: "Income",
      accessor: "income",
      width: "200px",
      export: true,
      disableCheck: true,
      checked: true,
    },
  ];

  return (
    <MDBox mt={6}>
      {!isLoading ? (
        <Card>
          <MDBox
            bgColor="dark"
            color="white"
            coloredShadow="dark"
            borderRadius="xl"
            alignItems="center"
            justifyContent="space-between"
            pt={1}
            pb={1}
            pl={2}
            pr={2}
            ml={2}
            mr={2}
            display="flex"
            mt={-3}
            className="page-header"
          >
            <p className="page-header-label">Master Data</p>
            <MDButton
              variant="gradient"
              color="light"
              size="small"
              className="xs-small"
              onClick={openAddEditDialog}
            >
              <Icon sx={{ fontWeight: "bold" }}>add</Icon> Add New
            </MDButton>
          </MDBox>
          <DataTable
            table={{
              columns: tableColumns,
              rows: fetchData(),
            }}
            canSearch
            fetchData={({
              sortBy,
              search,
            }: {
              sortBy: Array<SortProps>;
              search: string;
              filterData: any;
            }) => {
              setSearch(search);
              setSort(sortBy);
            }}
          />
          {dialogBox(selectedDialog)}
        </Card>
      ) : (
        <MDBox display="flex" justifyContent="center" alignItems="center" mt={3}>
          <CircularProgress style={{ color: "#1A73E8" }} />
        </MDBox>
      )}
    </MDBox>
  );
}

export default MasterData;
