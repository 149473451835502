import { useEffect, useState } from "react";
import MDDialog from "components/MDDialog";
import { FormControlLabel, Grid, Icon, Switch } from "@mui/material";
import Validations from "helper/validations";
import { useAppDispatch } from "store/store";
import { createAmenities } from "store/thunk/systemAdmin";
import CustomSelect from "components/CustomSelect";
import MDInput from "components/MDInput";
import iconsList from "helper/iconsList";

function AddUpdate(props: any): JSX.Element {
  const { open, onClose, updateData, pageNo, perPage, search, sortType } = props;
  const dispatch = useAppDispatch();
  const [amenityFormData, setAmenityFormData] = useState<any>({});
  const [errors, setErrors] = useState<any>({});
  const [loading] = useState<boolean>(false);
  const [isUpdate, setIsUpdate] = useState<any>(false);

  useEffect(() => {
    if (!updateData) {
      setAmenityFormData({});
    }
  }, []);

  useEffect(() => {
    if (updateData) {
      setIsUpdate(true);
      setAmenityFormData((prev: any) => ({
        ...prev,
        id: updateData.id,
        headline: updateData.headline,
        description: updateData.description,
        icon: { label: <Icon>{updateData.icon}</Icon>, value: updateData.icon },
        amenity_type: updateData.amenity_type || false,
      }));
    }
  }, [updateData]);

  const onChangeField = (e: any, field: string) => {
    setAmenityFormData((prev: any) => ({
      ...prev,
      [field]: e.target.value,
    }));
  };

  const onSave = () => {
    const error = Validations.validateAmenitiesForm(amenityFormData);
    setErrors(error);
    const sendData = {
      id: amenityFormData?.id,
      headline: amenityFormData?.headline,
      description: amenityFormData?.description,
      icon: amenityFormData?.icon,
      amenity_type: amenityFormData?.amenity_type,
    };
    if (!Object.keys(error).length) {
      const req = {
        sendData,
        onClose,
        isUpdate,
        pageNo,
        perPage,
        search,
        sortType,
      };
      dispatch(createAmenities(req));
    }
  };

  const onChangeAutoComplete = (name: string, value: any) => {
    setAmenityFormData({ ...amenityFormData, [name]: value });
  };

  const dialogProps = {
    open,
    onClose,
    onSave,
    saveTbtText: updateData ? "Update" : "Save",
    title: updateData ? "Edit Amenities" : "Add Amenities",
    size: "lg",
    loading,
    overflowVisible: "overflowVisible",
  };

  const onCheckField = (event: any) => {
    setAmenityFormData({ ...amenityFormData, [event.target.name]: event.target.checked });
  };

  return (
    <MDDialog {...dialogProps}>
      <Grid container spacing={2} display="flex" alignItems="flex-end">
        <Grid item xs={12} sm={12} md={6} lg={6}>
          <MDInput
            label="Headline"
            fullWidth
            required
            variant="standard"
            placeholder="Headline"
            name="headline"
            onChange={(e: any) => onChangeField(e, "headline")}
            value={amenityFormData?.headline ? amenityFormData.headline : ""}
            error={errors && errors.headline ? true : false}
            helperText={errors && errors.headline}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6}>
          <MDInput
            label="Description"
            fullWidth
            multiline
            rows="2"
            required
            variant="standard"
            name="description"
            placeholder="Description"
            onChange={(e: any) => onChangeField(e, "description")}
            value={amenityFormData?.description || ""}
            error={errors && errors.description ? true : false}
            helperText={errors && errors.description}
          />
        </Grid>{" "}
        <Grid item xs={12} sm={12} md={6} lg={6}>
          <CustomSelect
            name="icon"
            id="icon"
            fieldLabel="Icon"
            placeholder="Icon"
            options={
              iconsList?.length
                ? iconsList.map((obj: any) => ({
                    label: <Icon fontSize="medium">{obj}</Icon>,
                    value: obj,
                  }))
                : []
            }
            value={amenityFormData.icon || null}
            onChange={(value: any) => onChangeAutoComplete("icon", value)}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6}>
          <FormControlLabel
            control={
              <Switch
                checked={amenityFormData?.amenity_type || false}
                name="amenity_type"
                onChange={onCheckField}
                inputProps={{ "aria-label": "controlled" }}
              />
            }
            label="Fixed Type"
          />
        </Grid>
      </Grid>
    </MDDialog>
  );
}

export default AddUpdate;
