import { Grid, Card, FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDEditor from "components/MDEditor";
import CustomErrorMessage from "components/CustomErrorMessage";
import { getMUISelectValue } from "helper/services";
import { useEffect, useState } from "react";
import MESSAGES from "helper/messages";
import InputAdornment from "@mui/material/InputAdornment";
import InfoIcon from "@mui/icons-material/Info";
import Tooltip from "@mui/material/Tooltip";

const modules = {
  toolbar: [
    [{ header: [1, 2, false] }],
    ["bold", "italic", "underline", "strike", "blockquote"],
    [{ list: "ordered" }, { list: "bullet" }, { indent: "-1" }, { indent: "+1" }],
    ["link", "image"],
    ["clean"],
  ],
};

const formats = [
  "header",
  "bold",
  "italic",
  "underline",
  "strike",
  "blockquote",
  "list",
  "bullet",
  "indent",
  "link",
  "image",
];
const inActiveReasonList = ["left", "accidental", "incorrect"];

function General(props: any): JSX.Element {
  const { solaProDetail, onChangeField, onChangeBiography, errors } = props;
  const [inActiveReason, setInActiveReason] = useState<any>("");

  useEffect(() => {
    if (solaProDetail?.inactive_reason) {
      if (inActiveReasonList.includes(solaProDetail?.inactive_reason)) {
        setInActiveReason(inActiveReasonList.indexOf(solaProDetail?.inactive_reason));
      } else {
        setInActiveReason(solaProDetail?.inactive_reason || "");
      }
    }
  }, [solaProDetail?.inactive_reason]);

  return (
    <Card id="general">
      <MDBox p={3}>
        <MDTypography variant="h5">General</MDTypography>
      </MDBox>
      <MDBox pb={3} px={3}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6} md={6} lg={6}>
            <MDInput
              label="First Name"
              variant="standard"
              fullWidth
              name="f_name"
              placeholder="First name"
              onChange={onChangeField}
              value={solaProDetail?.f_name || ""}
              error={errors && errors.f_name ? true : false}
              helperText={errors && errors.f_name}
              required
              infoText={MESSAGES.INFO.FIRSTNAME_OR_DISPLAYNAME}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={6} lg={6}>
            <MDInput
              label="Middle name"
              variant="standard"
              fullWidth
              name="m_name"
              placeholder="Middle name"
              onChange={onChangeField}
              value={solaProDetail?.m_name || ""}
              error={errors && errors.m_name ? true : false}
              helperText={errors && errors.m_name}
              infoText={MESSAGES.INFO.OPTIONAL}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={6} lg={6}>
            <MDInput
              label="Last name"
              variant="standard"
              fullWidth
              name="l_name"
              placeholder="Last name"
              onChange={onChangeField}
              value={solaProDetail?.l_name || ""}
              error={errors && errors.l_name ? true : false}
              helperText={errors && errors.l_name}
              infoText={MESSAGES.INFO.OPTIONAL}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={6} lg={6}>
            <MDInput
              label="URL Name"
              variant="standard"
              fullWidth
              required
              name="url_name"
              placeholder="URL Name"
              onChange={onChangeField}
              value={solaProDetail?.url_name || ""}
              error={errors && errors.url_name ? true : false}
              helperText={errors && errors.url_name}
              infoText={MESSAGES.INFO.URL_NAME}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <MDTypography variant="button">Biography</MDTypography>
            <MDEditor
              value={solaProDetail?.biography ? solaProDetail.biography : ""}
              onChange={(val: any, delta: any, source: any) =>
                onChangeBiography(val, delta, source)
              }
              modules={modules}
              formats={formats}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={6} lg={6}>
            <FormControl fullWidth>
              <InputLabel id="reserved-label">Reserved</InputLabel>
              <Select
                fullWidth
                className="height45"
                labelId="reserved-label"
                id="reserved"
                value={getMUISelectValue(solaProDetail?.reserved)}
                name="reserved"
                label="reserved"
                onChange={onChangeField}
                endAdornment={
                  <InputAdornment position="end">
                    <Tooltip title={MESSAGES.INFO.RESERVED} arrow>
                      <InfoIcon
                        style={{
                          width: 20,
                          height: 20,
                          cursor: "pointer",
                          color: "rgba(48, 57, 65, 0.3)",
                          marginRight: "5px",
                        }}
                      />
                    </Tooltip>
                  </InputAdornment>
                }
              >
                <MenuItem value="true">Yes</MenuItem>
                <MenuItem value="false">No</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6} md={6} lg={6}>
            <FormControl fullWidth>
              <InputLabel>
                Status<span className="required_star">*</span>
              </InputLabel>
              <Select
                className="height45"
                label="Status"
                value={solaProDetail?.status || ""}
                name="status"
                onChange={onChangeField}
                error={errors && errors?.status ? true : false}
                endAdornment={
                  <InputAdornment position="end">
                    <Tooltip title={MESSAGES.INFO.REQUIRED} arrow>
                      <InfoIcon
                        style={{
                          width: 20,
                          height: 20,
                          cursor: "pointer",
                          color: "rgba(48, 57, 65, 0.3)",
                          marginRight: "5px",
                        }}
                      />
                    </Tooltip>
                  </InputAdornment>
                }
              >
                <MenuItem value="closed">Inactive</MenuItem>
                <MenuItem value="open">Active</MenuItem>
              </Select>
              {errors && errors.status && <CustomErrorMessage message={errors.status} />}
            </FormControl>
          </Grid>
          {solaProDetail?.status === "closed" && (
            <Grid item xs={12} sm={6} md={6} lg={6}>
              <FormControl fullWidth>
                <InputLabel>Inactive Reason</InputLabel>
                <Select
                  className="height45"
                  label="Inactive Reason"
                  value={inActiveReason}
                  name="inactive_reason"
                  onChange={onChangeField}
                >
                  <MenuItem value="0">Sola Pro has left their salon</MenuItem>
                  <MenuItem value="1">Accidental account creation</MenuItem>
                  <MenuItem value="2">Incorrect / duplicate information</MenuItem>
                </Select>
              </FormControl>
            </Grid>
          )}
        </Grid>
      </MDBox>
    </Card>
  );
}

export default General;
