import React, { useEffect, useState } from "react";
import MDDialog from "components/MDDialog";
import MDInput from "components/MDInput";
import CustomAutoSearch from "components/CustomAutoSearch";
import { Grid } from "@mui/material";
import Validations from "helper/validations";
import { addUpdateTag } from "store/thunk/solaWebsiteThunk";
import { useAppDispatch, useAppSelector } from "store/store";
import { getPermission } from "helper/services";

function AddUpdate(props: any): JSX.Element {
  const { open, onClose, pageNo, perPage, search, sortBy } = props;
  const dispatch = useAppDispatch();
  const { tag } = useAppSelector((state) => state.solaWebSiteSlice);
  const [tagData, setTagData] = useState<any>({});
  const [errors, setErrors] = useState<any>({});
  const [loading] = useState<boolean>(false);

  useEffect(() => {
    if (tag && Object.keys(tag).length > 0) {
      setTagData(tag);
    }
  }, [tag]);

  const onChangeField = (event: any) => {
    setTagData((prev: any) => ({
      ...prev,
      [event.target.name]: event.target.value,
    }));
  };

  const onSave = () => {
    const error = Validations.validateTagsForm(tagData);
    setErrors(error);
    if (!Object.keys(error).length) {
      const tag = {
        id: tagData?.id,
        name: tagData?.name,
        category_ids: tagData?.category?.map((obj: any) => obj.id),
        stylist_ids: tagData?.stylist?.map((obj: any) => obj.id),
        video_ids: tagData?.video?.map((obj: any) => obj.id),
      };
      const request = {
        tag,
        onClose,
        pageNo,
        perPage,
        search,
        sortBy,
      };
      dispatch(addUpdateTag(request));
    }
  };

  const dialogProps = {
    open,
    onClose,
    onSave,
    saveTbtText: tag?.id ? "Update" : "Save",
    title: tag?.id ? "Edit Tag" : "Add Tag",
    size: "sm",
    loading,
  };

  const onChangeAutoComplete = (name: string, value: any) => {
    setTagData({ ...tagData, [name]: value });
  };

  return (
    <MDDialog {...dialogProps}>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <MDInput
            label="Name"
            variant="standard"
            fullWidth
            required
            name="name"
            placeholder="Name"
            onChange={onChangeField}
            value={tagData?.name || ""}
            error={errors && errors?.name ? true : false}
            helperText={errors && errors?.name}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12} mt={1}>
          <CustomAutoSearch
            idKey="id"
            nameKey="name"
            fieldLabel="Categories"
            apiName="categories"
            responseKey="categories"
            name="category"
            value={tagData?.category || null}
            onChange={onChangeAutoComplete}
            multiple
            createNewLink={
              getPermission("sola_website", "sola_website_categories")
                ? "/sola-website/categories"
                : ""
            }
          />
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <CustomAutoSearch
            idKey="id"
            nameKey="name"
            fieldLabel="Stylists"
            apiName="stylists"
            responseKey="stylists"
            name="stylist"
            value={tagData?.stylist || null}
            onChange={onChangeAutoComplete}
            multiple
            createNewLink={getPermission("sola_pros", "sola_pros_sola_pros") ? "/sola-pros" : ""}
            additionaFields={{ status: "active" }}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12} mt={1}>
          <CustomAutoSearch
            idKey="id"
            nameKey="title"
            fieldLabel="Videos"
            apiName="videos"
            responseKey="videos"
            name="video"
            value={tagData?.video || null}
            onChange={onChangeAutoComplete}
            multiple
            createNewLink={
              getPermission("mobile_app", "mobile_app_videos") ? "/mobile-app/videos" : ""
            }
          />
        </Grid>
      </Grid>
    </MDDialog>
  );
}

export default AddUpdate;
