import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "store/store";
import MDDialog from "components/MDDialog";
import MDInput from "components/MDInput";
import CustomPhoneInput from "components/CustomPhoneInput";
import { Autocomplete, Checkbox, Grid, TextField } from "@mui/material";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import Validations from "helper/validations";
import {
  getCRMCreateAccess,
  scrollToError,
  getPermission,
  adminSuperAdmin,
  getAllAssociationLocations,
} from "helper/services";
import CustomAutoSearch from "components/CustomAutoSearch";
import CustomSelect from "components/CustomSelect";
import { createContactIquiries } from "store/thunk/leaseThunk";
import { getLocation } from "store/thunk/locationThunk";
import Messages from "helper/messages";
import FormControlLabel from "@mui/material/FormControlLabel";
import constants from "helper/constants";
import { useNavigate } from "react-router-dom";

const services = [
  { id: "hair", name: "Hair" },
  { id: "skincare", name: "Skincare" },
  { id: "makeup", name: "Makeup" },
  { id: "massage", name: "Massage" },
  { id: "nails", name: "Nails" },
  { id: "lashes", name: "Lashes" },
  { id: "waxing", name: "Waxing" },
  { id: "permanent_makeup", name: "Permanent Makeup" },
  { id: "other", name: "Other" },
];

function ViewDetails(props: any): JSX.Element {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { statusArray } = useAppSelector((state) => state.dealSlice);
  const { connectLocation } = useAppSelector((state) => state.locationSlice);
  const {
    open,
    onClose,
    updateData,
    pageNo,
    perPage,
    search,
    sortBy,
    crmLeads,
    setBoardData,
    statusWise,
  } = props;
  const [isUpdate, setIsUpdate] = useState<any>(false);
  const [contactInquiries, setContactInquiries] = useState<any>({
    deal_status: { label: "New", value: "New" },
  });
  const [errors, setErrors] = useState<any>({});
  const [selectedServices, setSelectedServices] = useState<any>([]);

  useEffect(() => {
    if (crmLeads.cms_location_id) {
      setContactInquiries({
        ...contactInquiries,
        location: { cms_location_id: crmLeads.cms_location_id, name: crmLeads.name },
      });
    }
  }, []);

  const onCallback = (dealId: any) => {
    navigate(`/crm/sola-pro-leads`, {
      state: { id: dealId },
      replace: true,
    });
  };

  const onSave = () => {
    const error = Validations.validateSolaProLeads(contactInquiries);
    setErrors(error);
    let leadObj = { ...contactInquiries };
    if (selectedServices.length) {
      const allServices = selectedServices.map((val: any) => val.name).join(",");
      leadObj = { ...leadObj, services: allServices };
    } else {
      setErrors({ ...error, services: Messages.ERROR.FIELD_REQUIRED });
    }
    if (!Object.keys(error).length) {
      const req = {
        contactInquiriesData: leadObj,
        onClose,
        isUpdate,
        pageNo,
        perPage,
        search,
        sortBy,
        setBoardData,
        statusWise,
        locationId: getAllAssociationLocations().get_location_by_ids || null,
        callback: onCallback,
      };
      dispatch(createContactIquiries(req));
    } else {
      scrollToError(error);
    }
  };
  useEffect(() => {
    if (updateData) {
      setIsUpdate(true);
      setContactInquiries(updateData);
    }
  }, [updateData]);

  const onChangeField = (e: any, field: string) => {
    setContactInquiries({ ...contactInquiries, [field]: e.target.value });
  };

  // const onChangeDate = (value: any) => {
  //   setContactInquiries({ ...contactInquiries, created_at: new Date(value).toISOString() });
  // };

  const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
  const checkedIcon = <CheckBoxIcon fontSize="small" />;

  const onChangeAutoComplete = (name: string, value: any) => {
    setContactInquiries({ ...contactInquiries, [name]: value });
    if (name === "location" && value?.cms_location_id) {
      dispatch(getLocation({ id: value?.cms_location_id, cmsLocation: false }));
    }
  };

  const dialogProps = {
    open,
    onSave,
    onClose,
    title: isUpdate ? "Update Leads" : "Add Leads",
    size: "lg",
    saveTbtText: isUpdate ? "Update" : "Save",
    overflowVisible: "overflowVisible",
  };
  return (
    <MDDialog {...dialogProps}>
      <Grid container spacing={2}>
        {/* <Grid item xs={12} sm={12} md={6} lg={6}>
          <MDInput
            label="Name"
            variant="standard"
            fullWidth
            required
            name="name"
            onChange={(event: any) => onChangeField(event, "name")}
            placeholder="name"
            value={contactInquiries?.name}
            error={errors && errors.name ? true : false}
            helperText={errors && errors.name}
          />
        </Grid> */}
        <Grid item xs={12} sm={12} md={6} lg={6}>
          <MDInput
            label="First Name"
            variant="standard"
            fullWidth
            required
            name="firstname"
            onChange={(event: any) => onChangeField(event, "firstname")}
            placeholder="Firstname"
            value={contactInquiries?.firstname}
            error={errors && errors.firstname ? true : false}
            helperText={errors && errors.firstname}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6}>
          <MDInput
            label="Last Name"
            variant="standard"
            fullWidth
            required
            name="lastname"
            onChange={(event: any) => onChangeField(event, "lastname")}
            placeholder="Lastname"
            value={contactInquiries?.lastname}
            error={errors && errors.lastname ? true : false}
            helperText={errors && errors.lastname}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6}>
          <MDInput
            label="Email"
            variant="standard"
            fullWidth
            required
            name="email"
            onChange={(event: any) => onChangeField(event, "email")}
            placeholder="email"
            value={contactInquiries?.email}
            error={errors && errors.email ? true : false}
            helperText={errors && errors.email}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6}>
          <MDInput
            variant="standard"
            fullWidth
            label="Phone"
            name="phone"
            placeholder="(000) 000-0000"
            id="phone-input"
            onChange={(event: any) => onChangeField(event, "phone")}
            InputProps={{
              inputComponent: CustomPhoneInput as any,
            }}
            value={contactInquiries?.phone}
            error={errors && errors.phone ? true : false}
            helperText={errors && errors.phone}
            required
          />
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6}>
          <CustomAutoSearch
            slsAPI
            idKey="cms_location_id"
            nameKey="name"
            fieldLabel="Locations"
            apiName="get-locations"
            parentKey="location"
            responseKey="data"
            name="location"
            required
            usersData={
              !adminSuperAdmin() ? getCRMCreateAccess("add_access", "crm", "crm_deals") : null
            }
            value={contactInquiries?.location || null}
            onChange={onChangeAutoComplete}
            error={errors && errors.location}
            createNewLink={
              adminSuperAdmin() && getPermission("locations", "locations_location")
                ? "/locations"
                : ""
            }
          />
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6}>
          <Autocomplete
            options={services}
            disableCloseOnSelect
            getOptionLabel={(option: any) => option?.name || option}
            id="services"
            multiple
            value={selectedServices}
            onChange={(e: any, newValue: any) => {
              setSelectedServices(newValue);
            }}
            renderOption={(props: any, option: any, { selected }: any) => (
              <li {...props} key={option.id}>
                <Checkbox
                  icon={icon}
                  checkedIcon={checkedIcon}
                  style={{ marginRight: 8 }}
                  checked={selected}
                />
                {option.name}
              </li>
            )}
            renderInput={(params: any) => (
              <TextField
                {...params}
                required
                variant="standard"
                label="Services"
                placeholder=""
                error={errors && errors.services ? true : false}
                helperText={errors && errors.services}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6}>
          <MDInput
            multiline
            rows={3}
            label="Message"
            variant="outlined"
            fullWidth
            name="message"
            onChange={(e: any) => onChangeField(e, "message")}
            placeholder="Message"
            value={contactInquiries?.message ? contactInquiries.message : ""}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6}>
          <CustomSelect
            name="deal_status"
            id="deal_status"
            fieldLabel="Deal Status"
            placeholder="Deal Status"
            options={
              statusArray?.length
                ? statusArray.map((obj: any) => ({ label: obj.status, value: obj.status }))
                : []
            }
            value={contactInquiries?.deal_status || null}
            onChange={(value: any) => onChangeAutoComplete("deal_status", value)}
          />
          {contactInquiries?.location &&
            connectLocation?.drip_campaign &&
            connectLocation?.connect_twillio_phone_number?.sms_status &&
            (contactInquiries?.deal_status?.value === "New" ||
              contactInquiries?.deal_status?.value === "Attempting to Connect") && (
              <FormControlLabel
                value="enroll_lead"
                control={
                  <Checkbox
                    name="enroll_lead"
                    inputProps={{ "aria-label": "controlled" }}
                    checked={contactInquiries?.enroll_lead ? true : false}
                    onChange={(e: any) => onChangeAutoComplete("enroll_lead", e.target.checked)}
                  />
                }
                label="Enroll this lead in the automated prospecting sequence?"
                labelPlacement="end"
              />
            )}
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6}>
          <CustomSelect
            name="original_source_type"
            id="original_source_type"
            fieldLabel="Original Source Type"
            placeholder="Original Source Type"
            options={constants.originalSourceType}
            value={contactInquiries?.original_source_type || null}
            onChange={(value: any) => onChangeAutoComplete("original_source_type", value)}
          />
        </Grid>
      </Grid>
    </MDDialog>
  );
}

export default ViewDetails;
