import MDDialog from "components/MDDialog";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
// import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Grid, Accordion, AccordionDetails, AccordionSummary } from "@mui/material";
import { useState } from "react";
import ImagePreview from "layouts/pages/ImagePreview";
import CustomChipList from "components/CustomChipList";
import { getLocalDateTime } from "helper/services";

function ViewDetails(props: any): JSX.Element {
  const { open, onClose, viewData } = props;
  const [isPreview, setImagePreviewPopup] = useState<boolean>(false);
  const [imageToPreview, setImageToPreview] = useState("");
  const dialogProps = {
    open,
    onClose,
    title: "View Franchisee Press & Blog",
    size: "md",
    saveBtn: false,
  };

  const previewImage = (image: any) => {
    setImagePreviewPopup(true);
    setImageToPreview(image);
  };

  const closeImagePreview = () => {
    setImagePreviewPopup(false);
    setImageToPreview("");
  };

  const getImageElement = (name: any, imageData: any) => {
    if (imageData) {
      return (
        <Grid item xs={12} sm={6} md={6}>
          <MDTypography fontWeight="bold" variant="button">
            {name}
          </MDTypography>
          <Grid container mt={2}>
            <Grid item xs={12} sm={6} md={6}>
              {imageData && (
                <Grid
                  container
                  onClick={() => previewImage(imageData)}
                  justifyContent="left"
                  alignItems="center"
                  display="flex"
                >
                  <Grid item className="thumbnail-img">
                    <img height={100} width={100} src={imageData} alt="location" />
                  </Grid>
                </Grid>
              )}
            </Grid>
          </Grid>
        </Grid>
      );
    }
    return null;
  };

  const getDetailElementWithDangersoulyHTML = (name: any, value: any) => (
    <Grid item xs={12} sm={12} md={12} lg={6}>
      <MDTypography fontWeight="bold" variant="button">
        {name}
      </MDTypography>
      <MDBox
        dangerouslySetInnerHTML={{
          __html: value ? value : "-",
        }}
      />
    </Grid>
  );

  const getTypograpghy = (label: any, value: any) => (
    <Grid item xs={12} sm={12} md={12} lg={6}>
      <MDTypography fontWeight="bold" variant="button">
        {label}
      </MDTypography>
      <MDBox>
        <MDTypography variant="button">{value || "-"}</MDTypography>
      </MDBox>
    </Grid>
  );

  const getAccordianSummary = (label: any) => (
    <AccordionSummary
      className="location-accordion"
      // expandIcon={<ExpandMoreIcon />}
      aria-controls="publishbh-content"
    >
      <MDTypography variant="h6">{label}</MDTypography>
    </AccordionSummary>
  );

  const getChipList = (label: any, key: any) => (
    <Accordion expanded>
      {getAccordianSummary(label)}
      <AccordionDetails>
        <CustomChipList
          listData={viewData?.[key]
            ?.map((obj: any) => ({
              ...obj,
              title: obj?.name || null,
            }))
            .filter((f: any) => f?.title !== null)}
        />
      </AccordionDetails>
    </Accordion>
  );

  return (
    <MDDialog {...dialogProps}>
      <MDBox mt={2}>
        <Grid container>
          <Grid item xs={12} sm={12} md={12}>
            <Accordion expanded>
              {getAccordianSummary("Basic Info")}
              <AccordionDetails>
                <Grid container spacing={2}>
                  {getTypograpghy("Type", viewData?.kind || "-")}
                  {getTypograpghy("Country", viewData?.country_name || "-")}
                </Grid>
                <Grid container spacing={2}>
                  {getTypograpghy("Slug", viewData?.slug || "-")}
                  {getTypograpghy("Title", viewData?.title || "-")}
                </Grid>
                <Grid container spacing={2}>
                  {getTypograpghy("Url", viewData?.url || "-")}
                  {getTypograpghy("Summary", viewData?.summary || "-")}
                </Grid>
                <Grid container spacing={2}>
                  {getTypograpghy("Auther", viewData?.author || "-")}
                  {getTypograpghy("Created At", getLocalDateTime(viewData?.created_at) || "-")}
                </Grid>
                <Grid container spacing={2}>
                  {viewData?.thumbnail_url && getImageElement("Thumbnail", viewData?.thumbnail_url)}
                  {viewData?.image_url && getImageElement("Image", viewData?.image_url)}
                </Grid>
                <Grid container spacing={2} mt={1}>
                  {getDetailElementWithDangersoulyHTML("Body", viewData?.body || "-")}
                </Grid>
              </AccordionDetails>
            </Accordion>
            {getChipList("Categories", "category")}
            {getChipList("Tags", "tag")}
          </Grid>
        </Grid>
      </MDBox>
      {isPreview && (
        <ImagePreview
          imageData={imageToPreview}
          open={isPreview}
          onCloseImage={() => {
            closeImagePreview();
          }}
        />
      )}
    </MDDialog>
  );
}

export default ViewDetails;
