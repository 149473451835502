import { useState } from "react";
import MDDialog from "components/MDDialog";
import { Grid } from "@mui/material";
import CustomAutoSearch from "components/CustomAutoSearch";
import Messages from "helper/messages";
import { scrollToErrorByClass } from "helper/services";
import { useAppSelector, useAppDispatch } from "store/store";
import { assignTwillioNumber } from "store/thunk/locationThunk";

function AssignPopup(props: any): JSX.Element {
  const { onClose, smsRequestData, listingRequestData } = props;
  const dispatch = useAppDispatch();
  const [selectedNumber, setSelectedNumber] = useState<any>(null);
  const [errors, setErrors] = useState<any>({});
  const { userData } = useAppSelector((state: any) => state.authReducer);

  const onAssignNumber = () => {
    if (smsRequestData) {
      const errors: any = {};
      if (!selectedNumber) {
        errors.phone = Messages.ERROR.FIELD_REQUIRED;
      }
      setErrors(errors);
      if (!Object.keys(errors).length) {
        const req = {
          assignReq: {
            sms_request_id: smsRequestData?.id,
            location_id: Number(smsRequestData?.location_id),
            approved_user_id: userData?.user_id,
            phone_number_id: selectedNumber?.id,
          },
          listingRequestData,
          onClose,
        };
        dispatch(assignTwillioNumber(req));
      } else {
        scrollToErrorByClass(errors);
      }
    }
  };

  const onChangeAutoComplete = (name: string, value: any) => {
    setSelectedNumber(value);
  };

  const assignDialogProps = {
    open: smsRequestData ? true : false,
    onClose,
    onSave: () => onAssignNumber(),
    title: `Assign Twillio Number To ${smsRequestData?.location?.name}`,
    size: "sm",
    saveBtn: true,
    saveTbtText: "Assign Number",
    closeBtnText: "Close",
    overflowVisible: "overflowVisible",
  };

  return (
    <MDDialog {...assignDialogProps}>
      <Grid container spacing={2} display="flex" alignItems="flex-end">
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <CustomAutoSearch
              idKey="id"
              nameKey="twillio_phone_number"
              fieldLabel="Twillio Numbers"
              apiName="get-all-twillio-numbers-list"
              responseKey="connect_twillio_phone_numbers"
              name="twillio_phone_number"
              required
              slsAPI
              value={selectedNumber || null}
              onChange={onChangeAutoComplete}
              error={errors && errors.phone}
            />
          </Grid>
        </Grid>
      </Grid>
    </MDDialog>
  );
}

export default AssignPopup;
