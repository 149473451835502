import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MESSAGES from "helper/messages";

function Tours360(props: any): JSX.Element {
  const { locationDetail, onChangeField } = props;
  return (
    <Card id="tours360">
      <MDBox p={3}>
        <MDTypography variant="h5">Tours360</MDTypography>
      </MDBox>
      <MDBox pb={3} px={3}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6} md={6} lg={6}>
            <MDInput
              multiline
              rows="3"
              variant="outlined"
              label="Tour #1"
              fullWidth
              name="tour_iframe_1"
              placeholder="Tour #1"
              onChange={onChangeField}
              value={locationDetail?.tour_iframe_1 || ""}
              infoText={MESSAGES.INFO.TOURS}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={6} lg={6}>
            <MDInput
              multiline
              rows="3"
              variant="outlined"
              label="Tour #2"
              fullWidth
              name="tour_iframe_2"
              placeholder="Tour #2"
              onChange={onChangeField}
              value={locationDetail?.tour_iframe_2 || ""}
              infoText={MESSAGES.INFO.TOURS}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={6} lg={6}>
            <MDInput
              multiline
              rows="3"
              variant="outlined"
              label="Tour #3"
              fullWidth
              name="tour_iframe_3"
              placeholder="Tour #3"
              onChange={onChangeField}
              value={locationDetail?.tour_iframe_3 || ""}
              infoText={MESSAGES.INFO.TOURS}
            />
          </Grid>
        </Grid>
      </MDBox>
    </Card>
  );
}

export default Tours360;
