import React, { useEffect, useState } from "react";
import MDDialog from "components/MDDialog";
import { Grid, FormControl } from "@mui/material";
import MDInput from "components/MDInput";
import TimePicker from "rc-time-picker";
import "rc-time-picker/assets/index.css";
import Validations from "helper/validations";
import CustomErrorMessage from "components/CustomErrorMessage";
import CustomRequiredLabel from "components/CustomRequiredLabel";
import { useAppDispatch } from "store/store";
import { createBookNowBooking, updateBookNowBooking } from "store/thunk/systemAdmin";
import moment from "moment";
import CustomAutoSearch from "components/CustomAutoSearch";
import { getPermission, getAllAssociationLocations, adminSuperAdmin } from "helper/services";

function AddUpdate(props: any): JSX.Element {
  const dispatch = useAppDispatch();
  const { open, onClose, editBookingId, pageNo, perPage, search, sortBy } = props;
  const [btnloading, setLoading] = useState<boolean>(false);
  const [bookNowBookingData, setBookNowBookingData] = useState<any>({});
  const [errors, setErrors] = useState<any>({});

  useEffect(() => {
    if (editBookingId && Object.keys(editBookingId).length) {
      let spitedTime = [];
      if (editBookingId.time_range) {
        spitedTime = editBookingId.time_range.split("-");
      }
      const data = {
        ...bookNowBookingData,
        id: editBookingId && editBookingId.id,
        services: JSON.stringify(editBookingId.services),
        from: spitedTime.length && moment(spitedTime[0].trim(), "hh:mm A"),
        to: spitedTime.length && moment(spitedTime[1].trim(), "hh:mm A"),
        location_id: { id: editBookingId.location_id, name: editBookingId.location_name },
        query: editBookingId.query,
        stylist_id: { id: editBookingId.stylist_id, name: editBookingId.stylist_name },
        booking_user_name: editBookingId.booking_user_name,
        booking_user_phone: editBookingId.booking_user_phone,
        booking_user_email: editBookingId.booking_user_email,
        referring_url: editBookingId.referring_url,
        total:
          editBookingId.total && editBookingId.total.startsWith("$")
            ? editBookingId.total.replace("$", "")
            : editBookingId.total,
      };
      setBookNowBookingData(data);
    }
  }, [editBookingId]);

  const onSave = () => {
    const error = Validations.validateBookingForm(bookNowBookingData);
    setErrors(error);
    if (!Object.keys(error).length) {
      setLoading(true);
      const req = {
        bookNowBookingData,
        onClose,
        pageNo,
        perPage,
        search,
        sortBy,
      };
      if (editBookingId) {
        dispatch(updateBookNowBooking(req));
      } else {
        dispatch(createBookNowBooking(req));
      }
    }
  };

  const onChangeField = (e: any, field: string) => {
    setBookNowBookingData((prev: any) => ({
      ...prev,
      [field]: e.target.value,
    }));
  };

  const onChangeAutoComplete = (name: string, value: any) => {
    setBookNowBookingData({ ...bookNowBookingData, [name]: value });
  };

  const dialogProps = {
    open,
    onClose,
    onSave,
    saveTbtText: editBookingId ? "Update" : "Save",
    title: editBookingId ? "Update Book Now Booking" : "Add Book Now Booking",
    size: "md",
    loading: btnloading ? true : false,
  };

  return (
    <MDDialog {...dialogProps}>
      <Grid container spacing={2} display="flex" alignItems="flex-end">
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <Grid container>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <CustomRequiredLabel label="Time Range" />
            </Grid>
            <Grid container item xs={12} sm={12} md={12} lg={12}>
              <Grid item xs={12} sm={6} md={6} lg={6} className="timPickerClass">
                <FormControl fullWidth>
                  <TimePicker
                    className="time-picker time-picker-child"
                    showSecond={false}
                    format="h:mm A"
                    use12Hours
                    value={bookNowBookingData.from}
                    minuteStep={15}
                    inputReadOnly
                    onChange={(e: any) => setBookNowBookingData({ ...bookNowBookingData, from: e })}
                  />
                  {errors && errors.from && <CustomErrorMessage message={errors.from} />}
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6} md={6} lg={6} className="timPickerClass">
                <FormControl fullWidth>
                  <TimePicker
                    className="time-picker"
                    showSecond={false}
                    format="h:mm A"
                    use12Hours
                    value={bookNowBookingData.to}
                    minuteStep={15}
                    inputReadOnly
                    onChange={(e: any) => setBookNowBookingData({ ...bookNowBookingData, to: e })}
                  />
                  {errors && errors.to && <CustomErrorMessage message={errors.to} />}
                </FormControl>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6}>
          <CustomAutoSearch
            idKey="id"
            nameKey="name"
            fieldLabel="Location"
            apiName="locations"
            responseKey="locations"
            name="location_id"
            required
            error={errors && errors.location_id ? true : false}
            value={bookNowBookingData?.location_id || null}
            onChange={onChangeAutoComplete}
            createNewLink={
              adminSuperAdmin() && getPermission("locations", "locations_location")
                ? "/locations"
                : ""
            }
            usersData={getAllAssociationLocations()}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6}>
          <MDInput
            label="Query"
            variant="standard"
            fullWidth
            name="query"
            placeholder=""
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => onChangeField(e, "query")}
            value={bookNowBookingData?.query || ""}
            error={errors && errors.query ? true : false}
            helperText={errors && errors.query}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6}>
          <MDInput
            label="Services"
            fullWidth
            multiline
            variant="standard"
            rows={2}
            name="services"
            onChange={(e: any) => onChangeField(e, "services")}
            value={bookNowBookingData?.services ? bookNowBookingData.services : ""}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6}>
          <CustomAutoSearch
            idKey="id"
            nameKey="name"
            fieldLabel="Stylists"
            apiName="stylists"
            responseKey="stylists"
            name="stylist_id"
            required
            value={bookNowBookingData?.stylist_id || null}
            error={errors && errors.stylist_id ? true : false}
            onChange={onChangeAutoComplete}
            createNewLink={getPermission("sola_pros", "sola_pros_sola_pros") ? "/sola-pros" : ""}
            additionaFields={{ status: "active" }}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6}>
          <MDInput
            label="Booking User Name"
            variant="standard"
            fullWidth
            required
            name="booking_user_name"
            placeholder=""
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              onChangeField(e, "booking_user_name")
            }
            value={bookNowBookingData?.booking_user_name || ""}
            error={errors && errors.booking_user_name ? true : false}
            helperText={errors && errors.booking_user_name}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6}>
          <MDInput
            label="Booking User Phone"
            variant="standard"
            fullWidth
            name="booking_user_phone"
            placeholder=""
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              onChangeField(e, "booking_user_phone")
            }
            value={bookNowBookingData?.booking_user_phone || ""}
            error={errors && errors.booking_user_phone ? true : false}
            helperText={errors && errors.booking_user_phone}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6}>
          <MDInput
            label="Booking User Email"
            variant="standard"
            fullWidth
            required
            name="booking_user_email"
            placeholder=""
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              onChangeField(e, "booking_user_email")
            }
            value={bookNowBookingData?.booking_user_email || ""}
            error={errors && errors.booking_user_email ? true : false}
            helperText={errors && errors.booking_user_email}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6}>
          <MDInput
            label="Referring Url"
            variant="standard"
            fullWidth
            name="referring_url"
            placeholder=""
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => onChangeField(e, "referring_url")}
            value={bookNowBookingData?.referring_url || ""}
            error={errors && errors.referring_url ? true : false}
            helperText={errors && errors.referring_url}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6}>
          <MDInput
            label="Total"
            variant="standard"
            fullWidth
            sx={{ margin: "5px 0px" }}
            name="total"
            placeholder=""
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => onChangeField(e, "total")}
            value={bookNowBookingData?.total || ""}
          />
        </Grid>
      </Grid>
    </MDDialog>
  );
}

export default AddUpdate;
