import { createSlice, Draft, PayloadAction } from "@reduxjs/toolkit";

interface InitialState {
  smsRequestList: any;
  phoneNumbersList: any;
  locationUserDetails: any;
  availableNumbersList: any;
  smsRequestDetailData: any;
  phoneNumberDetails: any;
  smsTemplateList: any;
  phoneTreeSettingsList: any;
  phoneTreeSettingDetails: any;
  locationHistoryDetails: any;
  rmContactList: any;
  rmContactDetail: any;
}

const initialState: InitialState = {
  smsRequestList: [],
  phoneNumbersList: [],
  locationUserDetails: [],
  availableNumbersList: [],
  smsRequestDetailData: null,
  phoneNumberDetails: null,
  smsTemplateList: null,
  phoneTreeSettingsList: [],
  phoneTreeSettingDetails: {},
  locationHistoryDetails: [],
  rmContactList: [],
  rmContactDetail: null,
};

const repairMaintenanceSlice = createSlice({
  name: "location",
  initialState,
  reducers: {
    setSmsRequestList: (state: Draft<InitialState>, action: PayloadAction<any>) => ({
      ...state,
      smsRequestList: action.payload,
    }),
    setPhoneNumbersList: (state: Draft<InitialState>, action: PayloadAction<any>) => ({
      ...state,
      phoneNumbersList: action.payload,
    }),
    setAvailableNumnersList: (state: Draft<InitialState>, action: PayloadAction<any>) => ({
      ...state,
      availableNumbersList: action.payload,
    }),
    setSMSRequestDetailData: (state: Draft<InitialState>, action: PayloadAction<any>) => ({
      ...state,
      smsRequestDetailData: action.payload,
    }),
    setPhoneNumberDetails: (state: Draft<InitialState>, action: PayloadAction<any>) => ({
      ...state,
      phoneNumberDetails: action.payload,
    }),
    setSMSTemplateList: (state: Draft<InitialState>, action: PayloadAction<any>) => ({
      ...state,
      smsTemplateList: action.payload,
    }),
    setPhoneTreeSettingsList: (state: Draft<InitialState>, action: PayloadAction<any>) => ({
      ...state,
      phoneTreeSettingsList: action.payload,
    }),
    setPhoneTreeSettingDetails: (state: Draft<InitialState>, action: PayloadAction<any>) => ({
      ...state,
      phoneTreeSettingDetails: action.payload,
    }),
    setLocationHistoryDetails: (state: Draft<InitialState>, action: PayloadAction<any>) => ({
      ...state,
      locationHistoryDetails: action.payload,
    }),
    setRMContactList: (state: Draft<InitialState>, action: PayloadAction<any>) => ({
      ...state,
      rmContactList: action.payload,
    }),
    setRMContactDetail: (state: Draft<InitialState>, action: PayloadAction<any>) => ({
      ...state,
      rmContactDetail: action.payload,
    }),
  },
});

export const {
  setSmsRequestList,
  setPhoneNumbersList,
  setAvailableNumnersList,
  setSMSRequestDetailData,
  setPhoneNumberDetails,
  setSMSTemplateList,
  setPhoneTreeSettingsList,
  setPhoneTreeSettingDetails,
  setLocationHistoryDetails,
  setRMContactList,
  setRMContactDetail,
} = repairMaintenanceSlice.actions;

export default repairMaintenanceSlice.reducer;
