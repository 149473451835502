import { Card, Tab, Tabs } from "@mui/material";
import MDBox from "components/MDBox";
import MDTabPanel from "components/MDTabPanel";
import SolaProsActive from "layouts/pages/sola-pros/sola-pros-list";
import SolaProsInActive from "layouts/pages/sola-pros/sola-pros-inactive";
// import SolaPros from "layouts/pages/sola-pros/sola-pros-list";
import { useState } from "react";

function Index(props?: any): JSX.Element {
  const { locationId, getAccessTypePermission } = props;
  const [tabValue, setTabValue] = useState<number>(0);
  const changeTab = (event: any, newValue: any) => {
    setTabValue(newValue);
  };

  return (
    <MDBox mt={3}>
      <Card>
        <MDBox minWidth={{ xs: "100%", md: "25rem" }} mx="auto" mt={1} mb={-3}>
          <Tabs className="location-tabs" value={tabValue} onChange={changeTab}>
            <Tab
              id="Active"
              label={
                <MDBox py={0.5} px={2} color="inherit">
                  Active
                </MDBox>
              }
            />
            <Tab
              id="Inactive"
              label={
                <MDBox py={0.5} px={2} color="inherit">
                  Inactive
                </MDBox>
              }
            />
          </Tabs>
        </MDBox>
        <MDTabPanel value={tabValue} index={0}>
          <SolaProsActive
            locationId={locationId}
            accessDisabled={!getAccessTypePermission("location_solapros", "edit_access")}
          />
        </MDTabPanel>
        <MDTabPanel value={tabValue} index={1}>
          <SolaProsInActive
            locationId={locationId}
            accessDisabled={!getAccessTypePermission("location_solapros", "edit_access")}
          />
        </MDTabPanel>
      </Card>
      {/* <SolaPros
        locationId={locationId}
        accessDisabled={!getAccessTypePermission("location_solapros", "edit_access")}
      /> */}
    </MDBox>
  );
}

export default Index;
