export default {
  ENV: `${process.env.REACT_APP_ENV}`,
  AWS: {
    USER_POOL: `${process.env.REACT_APP_USER_POOL}`,
    CLIENT_ID: `${process.env.REACT_APP_CLIENT_ID}`,
    ACCESS_KEY: `${process.env.REACT_APP_ACCESS_KEY}`,
    SECRET_KEY: `${process.env.REACT_APP_SECRET_KEY}`,
    REGION: `${process.env.REACT_APP_REGION}`,
    IDENTITY_POOL_ID: `${process.env.REACT_APP_IDENTITY_POOL_ID}`,
  },
  DOMAIN: `${process.env.REACT_APP_AWS_DOMAIN}`,
  REDIRECT_SIGNIN: `${process.env.REACT_APP_REDIRECT_SIGNIN}`,
  REDIRECT_SIGNOUT: `${process.env.REACT_APP_REDIRECT_SIGNOUT}`,
  RESPONSE_TYPE: `${process.env.REACT_APP_RESPONSE_TYPE}`,
  DEFAULT_PASSWORD: `${process.env.REACT_APP_DEFAULT_PASSWORD}`,
  API_URL: `${process.env.REACT_APP_API_URL}`,
  BACKEND_API_URL: `${process.env.REACT_APP_BACKEND_API_URL}`,
  BACKEND_API_KEY: `${process.env.REACT_APP_BACKEND_API_KEY}`,
  HELLOSIGN_CLIENT_ID: `${process.env.REACT_APP_HELLOSIGN_CLIENT_ID}`,
  BRANCHIO_KEY: `${process.env.REACT_APP_BRANCHIO_KEY}`,
  BRANCHIO_URL: `${process.env.REACT_APP_BRANCHIO_URL}`,
  HELLOSIGN_URL: `${process.env.REACT_APP_HELLOSIGN_URL}`,
  BACKEND_API_SOLA_WEB: `${process.env.REACT_APP_BACKEND_API_WEB_PAGES}`,
  SLS_X_API_KEY: `${process.env.REACT_APP_SLS_X_API_KEY}`,
  REPORT_URL: `${process.env.REACT_APP_REPORT_URL}`,
  REPORT_KEY: `${process.env.REACT_APP_REPORT_API_KEY}`,
  FRONT_END_URL: `${process.env.REACT_APP_FRONT_END_URL}`,
  WEBSOCKET_URL: `${process.env.REACT_APP_WEBSOCKET_URL}`,
  PLATFORM_API_KEY: `${process.env.REACT_APP_PLATFORM_KEY}`,
  SLS_X_API_KEY_LOCAL: `${process.env.REACT_APP_SLS_X_API_KEY_LOCAL}`,
};
