import { useState } from "react";
import { Card, Tabs, Tab } from "@mui/material";

import MDBox from "components/MDBox";
import MDTabPanel from "components/MDTabPanel";

import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import PendingList from "./pending";
import ApprovedList from "./approved";
import RejectedList from "./rejected";

function Index(): JSX.Element {
  const [tabValue, setTabValue] = useState<number>(0);
  const changeTab = (event: any, newValue: any) => {
    setTabValue(newValue);
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pb={3}>
        <Card>
          <MDBox minWidth={{ xs: "100%", md: "25rem" }} mx="auto" mt={2} mb={2}>
            <Tabs className="location-tabs" value={tabValue} onChange={changeTab}>
              <Tab
                id="pending"
                label={
                  <MDBox py={0.5} px={2} color="inherit">
                    Pending
                  </MDBox>
                }
              />
              <Tab
                id="approved"
                label={
                  <MDBox py={0.5} px={2} color="inherit">
                    Approved
                  </MDBox>
                }
              />
              <Tab
                id="rejected"
                label={
                  <MDBox py={0.5} px={2} color="inherit">
                    Rejected
                  </MDBox>
                }
              />
            </Tabs>
          </MDBox>
          <MDTabPanel value={tabValue} index={0}>
            <PendingList />
          </MDTabPanel>
          <MDTabPanel value={tabValue} index={1}>
            <ApprovedList />
          </MDTabPanel>
          <MDTabPanel value={tabValue} index={2}>
            <RejectedList />
          </MDTabPanel>
        </Card>
      </MDBox>
    </DashboardLayout>
  );
}

export default Index;
