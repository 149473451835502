import { useState } from "react";
import { Grid, FormControl, Autocomplete, Icon } from "@mui/material";
import MDInput from "components/MDInput";
// import DripEditor from "components/Drip-editor";
import constants from "helper/constants";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDIconButton from "components/MDIconButton";
// import MDButton from "components/MDButton";

function EmailSms(props: any): JSX.Element {
  const {
    token,
    itemKey,
    addToken,
    crmTabSettings,
    // onChangeFollowUpToggle,
    onChangeFollowUpField,
    // onChangeEmailField,
    accessDisabled,
    // getAccessTypePermission,
  } = props;
  // const editorRef = useRef();
  const followUpItem = crmTabSettings?.followups?.[itemKey];
  const [tokenOpen, setTokenOpen] = useState(false);

  const handleAddToken = (event: any, value: any) => {
    addToken(value, itemKey, "sms_content");
    setTokenOpen(false);
  };

  return (
    <>
      <Grid item xs={12} sm={12} md={12} lg={12}>
        <MDBox display="flex" justifyContent="space-between">
          {/* <FormControlLabel
            // control={
            //   <Switch
            //     checked={followUpItem?.send_sms || false}
            //     onChange={(e: any) => onChangeFollowUpToggle(e, "send_sms", itemKey)}
            //     inputProps={{ "aria-label": "controlled" }}
            //     name="send_sms"
            //     disabled={getAccessTypePermission("location_crm", "edit_access")}
            //   />
            // }
            label="Send SMS"
            labelPlacement="end"
          /> */}
          <div /> {/* For toggle dispaly */}
          <MDBox>
            {tokenOpen && (
              <MDBox className="placeholder-location-sms-dorpdown-wrapper">
                <FormControl fullWidth className="placeholder-location-sms-dorpdown">
                  <Autocomplete
                    onChange={handleAddToken}
                    value={token || null}
                    options={constants.smsTokens}
                    groupBy={(option) => option.table_name}
                    getOptionLabel={(option: any) => option.label}
                    renderGroup={(params: any) => (
                      <li {...params}>
                        <MDTypography
                          sx={{ pl: 1 }}
                          variant="subtitle2"
                          className="font-bold text-capitalize"
                        >
                          {params.group}
                        </MDTypography>
                        <MDTypography>{params.children}</MDTypography>
                      </li>
                    )}
                    renderOption={(props, data) => (
                      <MDTypography key={data.value} {...props} variant="subtitle2">
                        {data.label}
                      </MDTypography>
                    )}
                    renderInput={(params) => <MDInput {...params} label="Select Token" />}
                  />
                </FormControl>
              </MDBox>
            )}
            <MDIconButton
              tooltipName="Add Token"
              type="button"
              aria-label="Add Token"
              sx={{ color: "#0b8cfb" }}
              disabled={followUpItem?.send_sms ? false : true}
              onClick={() => setTokenOpen(!tokenOpen)}
            >
              <Icon fontSize="small">add</Icon>
            </MDIconButton>
          </MDBox>
        </MDBox>
        <MDInput
          multiline
          rows={3}
          variant="outlined"
          fullWidth
          name="sms_content"
          placeholder="Send SMS"
          onChange={(e: any) => onChangeFollowUpField(e, "sms_content", itemKey)}
          disabled={followUpItem?.send_sms && !accessDisabled ? false : true}
          value={followUpItem?.sms_content || ""}
          id={`sms_content_${itemKey}`}
          key={`sms_content_${itemKey}`}
        />
      </Grid>
      <Grid item xs={12} sm={12} md={12} lg={12}>
        {/* <FormControlLabel
          control={
            <Switch
              checked={followUpItem?.send_email || false}
              onChange={(e: any) => onChangeFollowUpToggle(e, "send_email", itemKey)}
              inputProps={{ "aria-label": "controlled" }}
              name="send_email"
              disabled={getAccessTypePermission("location_crm", "edit_access")}
            />
          }
          label="Send Email"
          labelPlacement="end"
        /> */}

        {/* <DripEditor
          value={followUpItem?.email_content || ""}
          onChange={(val: any) => onChangeEmailField(val, "email_content", itemKey)}
          modules={constants.editorModules}
          formats={constants.editorFormats}
          readOnly={followUpItem?.send_email && !accessDisabled ? false : true}
          id={`email_content_${itemKey}`}
          key={`email_content_${itemKey}`}
        /> */}
      </Grid>
    </>
  );
}

export default EmailSms;
