import { useEffect, useState } from "react";
import MDDialog from "components/MDDialog";
import MDTypography from "components/MDTypography";
import CustomChipList from "components/CustomChipList";
import MDBox from "components/MDBox";
import { Grid } from "@mui/material";
import { useAppSelector } from "store/store";
import { getLocalDateTime } from "helper/services";

function ViewDetails(props: any): JSX.Element {
  const { open, onClose } = props;
  const [categoryData, setCategoryData] = useState<any>({});
  const { category } = useAppSelector((state) => state.solaWebSiteSlice);

  useEffect(() => {
    if (category && Object.keys(category).length > 0) {
      setCategoryData(category);
    }
  }, [category]);

  const dialogProps = {
    open,
    onClose,
    saveBtn: false,
    title: "View Category",
    size: "md",
  };

  return (
    <MDDialog {...dialogProps}>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6} md={6}>
          <MDTypography fontWeight="bold" variant="button">
            Name
          </MDTypography>
          <MDBox>
            <MDTypography variant="button">
              {categoryData?.name ? categoryData.name : "-"}
            </MDTypography>
          </MDBox>
        </Grid>
        <Grid item xs={12} sm={6} md={6}>
          <MDTypography fontWeight="bold" variant="button">
            Slug
          </MDTypography>
          <MDBox>
            <MDTypography variant="button">
              {categoryData?.slug ? categoryData.slug : "-"}
            </MDTypography>
          </MDBox>
        </Grid>
      </Grid>
      <CustomChipList name="Blogs" listData={categoryData?.blog} />
      <CustomChipList name="Deals" listData={categoryData?.deal} />
      <CustomChipList name="Tools" listData={categoryData?.tool} />
      <CustomChipList name="Videos" listData={categoryData?.video} />
      <CustomChipList name="Tags" listData={categoryData?.tag} />
      <CustomChipList name="Franchise articles" listData={categoryData?.franchise_article} />
      <Grid container spacing={2} mt={1}>
        <Grid item xs={12} sm={6} md={6}>
          <MDTypography fontWeight="bold" variant="button">
            Created At
          </MDTypography>
          <MDBox>
            <MDTypography variant="button">
              {categoryData?.created_at ? getLocalDateTime(categoryData?.created_at) : "-"}
            </MDTypography>
          </MDBox>
        </Grid>
        <Grid item xs={12} sm={6} md={6}>
          <MDTypography fontWeight="bold" variant="button">
            Updated At
          </MDTypography>
          <MDBox>
            <MDTypography variant="button">
              {categoryData?.updated_at ? getLocalDateTime(categoryData?.updated_at) : "-"}
            </MDTypography>
          </MDBox>
        </Grid>
      </Grid>
    </MDDialog>
  );
}

export default ViewDetails;
