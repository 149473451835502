import React, {
  useState,
  useEffect,
  JSXElementConstructor,
  Key,
  ReactElement,
  useRef,
  useCallback,
} from "react";
import { Routes, Route, Navigate, useLocation } from "react-router-dom";
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import { Icon } from "@mui/material";
import Sidenav from "examples/Sidenav";
import Configurator from "examples/Configurator";
import theme from "assets/theme";
import "assets/styles/common.scss";
import themeDark from "assets/theme-dark";
import routes from "routes";
import { useMaterialUIController, setMiniSidenav } from "context";
import MDSnackbar from "components/MDSnackbar";
import MDBox from "components/MDBox";
import brandWhite from "assets/images/white-logo.png";
import brandDark from "assets/images/dark-logo.png";
import brandMiniWhite from "assets/images/white-mini-logo.png";
import brandMiniDark from "assets/images/dark-mini-logo.png";
import { useAppDispatch, useAppSelector } from "store/store";
import { notificationClear } from "store/slices/notificationSlice";
import SignInCover from "layouts/authentication/sign-in/cover";
import SignUpCover from "layouts/authentication/sign-up/cover";
import ResetPassword from "layouts/authentication/reset-password/cover";
import CreatePassword from "layouts/authentication/reset-password/create-password";
import Rockbot from "layouts/pages/rockbot";
import EleaseAgreement from "layouts/pages/elease-agreement";
import ScheduleEvent from "layouts/pages/schedule";
import SamplePage from "layouts/pages/sample";
import Lease from "layouts/pages/lease";
import LocationFranchiseView from "layouts/pages/LocationFranchiseView/location-wizard";
import NoAccess from "layouts/pages/no-access";
import * as Amplify from "aws-amplify";
import config from "config/config";
import { getAuthUser } from "helper/services";
import CustomLoader from "components/CustomLoader";
import { getUser, getAllNotifications } from "store/thunk/authThunk";

Amplify.Amplify.configure({
  Auth: {
    identityPoolId: config.AWS.IDENTITY_POOL_ID,
    region: config.AWS.REGION,
    userPoolId: config.AWS.USER_POOL,
    userPoolWebClientId: config.AWS.CLIENT_ID,
    oauth: {
      domain: config.DOMAIN,
      redirectSignIn: config.REDIRECT_SIGNIN,
      redirectSignOut: config.REDIRECT_SIGNOUT,
      responseType: config.RESPONSE_TYPE,
    },
  },
});

export default function App() {
  const socket = useRef<WebSocket | null>(null);
  const appDispatch = useAppDispatch();
  const authState = useAppSelector((state) => state.authReducer);
  const notifications = useAppSelector((state) => state.authReducer);
  const notificationInfo = useAppSelector((state) => state.notificationReducer);
  const [controller, dispatch] = useMaterialUIController();
  const {
    miniSidenav,
    direction,
    layout,
    sidenavColor,
    transparentSidenav,
    whiteSidenav,
    darkMode,
  } = controller;
  const [onMouseEnter, setOnMouseEnter] = useState(false);
  const { pathname, search } = useLocation();
  const [menu, setMenu] = useState([]);
  const [loader, setLoader] = useState(false);
  const authUser = getAuthUser();

  const onSocketOpen = () => {
    socket.current?.send(JSON.stringify({ action: "setName", name: authUser?.user_id.toString() }));
  };

  // const onSocketClose = () => {
  //   console.log("disconnect");
  // };

  const onSocketMessage = useCallback((dataStr) => {
    const data = JSON.parse(dataStr);
    if (data.members) {
      // console.log("members ", data.members);
      // setMembers(data.members);
    } else if (data.publicMessage) {
      // console.log("data.publicMessage ", data.publicMessage);
      // setChatRows(oldArray => [...oldArray, <span><b>{data.publicMessage}</b></span>]);
    } else if (data.privateMessage) {
      // console.log("privateMessage ", data.privateMessage);
    } else if (data.notificationMessage) {
      const originalMessage = JSON.parse(data.notificationMessage);
      if (
        Object.keys(originalMessage).find((key) => key.toString() === authUser.user_id.toString())
      ) {
        appDispatch(
          getAllNotifications({
            userId: authUser.user_id,
            page: 0,
            size: 20,
            notifications,
          })
        );
      }
    } else if (data.systemMessage) {
      // console.log("data.systemMessage ", data.systemMessage);
      // setChatRows(oldArray => [...oldArray, <span><i>{data.systemMessage}</i></span>]);
    }
  }, []);

  const openConnection = () => {
    try {
      if (socket.current?.readyState !== WebSocket.OPEN) {
        socket.current = new WebSocket(config.WEBSOCKET_URL);
        socket.current.addEventListener("open", onSocketOpen);
        socket.current.addEventListener("close", () => {
          openConnection();
        });
        socket.current.addEventListener("message", (event: any) => {
          onSocketMessage(event.data);
        });
      }
    } catch (socketError) {
      console.log("socketError ", socketError);
    }
  };

  useEffect(() => {
    if (authUser && authUser.user_id) {
      appDispatch(getUser({ user_id: authUser?.user_id, fetch_all_data: true }));
      openConnection();
      // if (socket.current?.readyState !== WebSocket.OPEN) {
      //   socket.current = new WebSocket(URL);
      //   socket.current.addEventListener("open", onSocketOpen);
      //   socket.current.addEventListener("close", onSocketClose);
      //   socket.current.addEventListener("message", (event: any) => {
      //     onSocketMessage(event.data);
      //   });
      // }
    }
  }, []);

  // Open sidenav when mouse enter on mini sidenav
  const handleOnMouseEnter = () => {
    if (miniSidenav && !onMouseEnter) {
      setMiniSidenav(dispatch, false);
      setOnMouseEnter(true);
    }
  };

  // Close sidenav when mouse leave mini sidenav
  const handleOnMouseLeave = () => {
    if (onMouseEnter) {
      setMiniSidenav(dispatch, true);
      setOnMouseEnter(false);
    }
  };

  // Setting the dir attribute for the body element
  useEffect(() => {
    document.body.setAttribute("dir", direction);
  }, [direction]);

  useEffect(() => {
    if (notificationInfo.message) {
      setTimeout(() => {
        appDispatch(notificationClear());
      }, 1000 * 10);
    }
  }, [notificationInfo]);

  // Setting page scroll to 0 when changing the route
  useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
  }, [pathname]);

  useEffect(() => {
    setLoader(authState.loading && true);
  }, [authState.loading]);

  useEffect(() => {
    if (routes && routes.length) {
      const components: any = [];
      const userData = getAuthUser();
      if (userData && userData?.access) {
        userData?.access.map((obj: any) => {
          const collapse: any = [];
          if (obj?.child_menu && obj?.child_menu.length) {
            obj?.child_menu.map((child: any) => {
              const getItem: any = routes.find((c) => c.alias === child.alias);
              if (getItem) {
                getItem.name = child.display_menu;
                collapse.push(getItem);
              } else {
                collapse.push({
                  key: child.alias,
                  alias: child.alias,
                  name: child.alias,
                  route: `/${child.alias}`,
                  component: <SamplePage />,
                  icon: <Icon fontSize="medium">menu</Icon>,
                  type: "collapse",
                  noCollapse: true,
                });
              }
              return true;
            });
          }
          const getItem: any = routes.find((c) => c.alias === obj.alias);
          if (getItem) {
            getItem.name = getItem.route === "/home" ? "Home" : obj.display_menu;
            if (collapse.length) {
              getItem.collapse = collapse;
              getItem.noCollapse = false;
            }
            components.push(getItem);
          } else {
            const itemObject: any = {
              key: obj.alias,
              alias: obj.alias,
              name: obj.alias,
              route: `/${obj.alias}`,
              component: <SamplePage />,
              icon: <Icon fontSize="medium">menu</Icon>,
              type: "collapse",
              noCollapse: true,
            };
            if (collapse.length) {
              itemObject.collapse = collapse;
              itemObject.noCollapse = false;
            }
            components.push(itemObject);
          }
          return true;
        });
        setMenu(components);
      }
    }
  }, [routes]);

  const openNotification = () => {
    if (notificationInfo.message) {
      return true;
    }
    return false;
  };

  const clearNotification = () => {
    appDispatch(notificationClear());
  };

  const getRoutes = (allRoutes: any[]): any =>
    allRoutes.map(
      (route: {
        collapse: any;
        route: string;
        component: ReactElement<any, string | JSXElementConstructor<any>>;
        key: Key;
        alias: string;
      }) => {
        if (route.collapse && route.collapse.length) {
          return getRoutes(route.collapse);
        }
        const userData = getAuthUser();

        if (!userData) {
          return <Route path="*" key={route.key} element={<Navigate to="/sign-in" />} />;
        }
        // if (
        //   authState?.userData &&
        //   authState?.userData?.role === userRoles.SOLA_PRO_USER &&
        //   !getPreferenceValue(authState?.userData, "LEASE_WIZARD")
        // ) {
        //   return <Route path="*" key={route.key} element={<Navigate to="/lease" />} />;
        // }

        let { component } = route;
        if (
          route.route === "/home" &&
          (window.location.pathname === "home" ||
            window.location.pathname === "/home" ||
            window.location.pathname === "/home/")
        ) {
          if (userData && userData.access && userData.access.length) {
            let getHomePage = userData.access.find((obj: any) => obj.home_page);
            if (!getHomePage) {
              userData.access.map((obj: any) => {
                if (obj.child_menu && obj.child_menu.length) {
                  obj.child_menu.map((child: any) => {
                    if (child.home_page) {
                      getHomePage = child;
                    }
                    return true;
                  });
                }
                return true;
              });
            }
            if (getHomePage) {
              const getRoute = allRoutes.find((obj: any) => getHomePage.alias === obj.alias);
              if (getRoute) {
                component = getRoute.component;
              }
            }
          }
        } else {
          let pageAccess = false;
          if (userData && userData.access && userData.access.length) {
            userData.access.map((element: any) => {
              if (
                route.alias === element.alias ||
                (element.child_menu &&
                  element.child_menu.length &&
                  element.child_menu.find((c: any) => c.alias === route.alias))
              ) {
                pageAccess = true;
              }
              return true;
            });
          }
          if (!pageAccess) {
            component = <NoAccess />;
          }
        }

        if (route.route) {
          return <Route path={route.route} element={component} key={route.key} />;
        }

        return null;
      }
    );

  window.addEventListener("storage", (e) => {
    if (e.key === "user_data" && e.oldValue && !e.newValue) {
      window.location.reload();
    }
  });

  return (
    <ThemeProvider theme={darkMode ? themeDark : theme}>
      {loader && (
        <MDBox
          style={{
            background: "rgba(0,0,0,0.3)",
            zIndex: 99999,
            display: "flex",
            position: "fixed",
            alignItems: "center",
            justifyContent: "center",
            width: "100%",
            height: "100vh",
          }}
        >
          <CustomLoader />
        </MDBox>
      )}
      {notificationInfo?.message && (
        <MDSnackbar
          color={notificationInfo.status ? "success" : "error"}
          icon={notificationInfo.status ? "check" : "warning"}
          title={notificationInfo.status ? "Success" : "Error"}
          content={notificationInfo.message}
          dateTime=""
          open={openNotification()}
          onClose={clearNotification}
          close={clearNotification}
          bgWhite
        />
      )}
      <CssBaseline />
      {layout === "dashboard" && getAuthUser() && (
        <>
          <Sidenav
            color={sidenavColor}
            brand={(transparentSidenav && !darkMode) || whiteSidenav ? brandDark : brandWhite}
            brandmini={
              (transparentSidenav && !darkMode) || whiteSidenav ? brandMiniDark : brandMiniWhite
            }
            brandName="Sola Connect"
            routes={menu}
            onMouseEnter={handleOnMouseEnter}
            onMouseLeave={handleOnMouseLeave}
          />
          <Configurator />
        </>
      )}
      {layout === "vr" && <Configurator />}
      <Routes>
        {getRoutes(routes)}
        <Route path="/sign-in" element={<SignInCover />} />
        <Route path="/sign-up" element={<SignUpCover />} />
        <Route path="/forgot-password" element={<ResetPassword />} />
        <Route path="/create-password" element={<CreatePassword />} />
        <Route path="/lease" element={<Lease />} />
        <Route path="/new-location-form" element={<LocationFranchiseView />} />
        <Route path="/rockbot" element={<Rockbot />} />
        <Route path="/elease-agreement" element={<EleaseAgreement />} />
        <Route path="/schedule" element={<ScheduleEvent />} />
        <Route
          path="/web-pages/update-web-page-requests"
          element={<Navigate to={`/sola-pros/update-web-page-requests${search}`} />}
        />
        <Route path="*" element={<Navigate to="/home" />} />
      </Routes>
    </ThemeProvider>
  );
}
