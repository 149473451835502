import { Card, Grid } from "@mui/material";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDEditor from "components/MDEditor";
import constants from "helper/constants";
import CustomErrorMessage from "components/CustomErrorMessage";
import CustomRequiredLabel from "components/CustomRequiredLabel";
import { systemAdmin, isFranchisie } from "helper/services";

function BasicInfo(props: any): JSX.Element {
  const { errors, editPermission, onChangeDescription, onChangeField, locationDetail } = props;
  return (
    <Card id="basic-info" sx={{ overflow: "visible" }}>
      <MDBox p={3}>
        <MDTypography variant="h5">Basic Info</MDTypography>
      </MDBox>
      <MDBox component="form" pb={3} px={3}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            <MDInput
              label="URL"
              placeholder="URL"
              fullWidth
              variant="standard"
              name="url_name"
              disabled={!systemAdmin()}
              value={locationDetail?.url_name || ""}
              onChange={onChangeField}
              required
            />
          </Grid>
          <Grid item xs={12} sm={12} className="editorHeight">
            <CustomRequiredLabel label="Description" />
            <MDEditor
              readOnly={!editPermission && !isFranchisie()}
              value={locationDetail?.description || ""}
              onChange={(val: any) => onChangeDescription(val)}
              modules={constants.editorModules}
              formats={constants.editorFormats}
            />
            {errors && errors?.description && <CustomErrorMessage message={errors?.description} />}
          </Grid>
        </Grid>
      </MDBox>
    </Card>
  );
}

export default BasicInfo;
