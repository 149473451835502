import { Box, Icon, Paper, Typography } from "@mui/material";
import MDButton from "components/MDButton";
import { leaseApplicantValue } from "helper/services";
import { setleaseApplicationSteps } from "store/slices/leaseSlice";
import { useAppDispatch, useAppSelector } from "store/store";

function NotFoundRecord(props: any): JSX.Element {
  const { type, setShowNotFound, setShowDeals } = props;
  const { leaseApplicationSteps } = useAppSelector((state) => state.leaseSlice);
  const dispatch = useAppDispatch();

  const addNew = () => {
    setShowNotFound(false);
    setShowDeals(false);
    dispatch(
      setleaseApplicationSteps({
        ...leaseApplicationSteps,
        mainType: leaseApplicantValue.ADDNEW,
        selectedType: leaseApplicantValue.ADDFROMDEAL,
        deal: "",
        professional: "",
        locations: "",
      })
    );
  };
  const findNewUser = () => {
    setShowNotFound(false);
    dispatch(
      setleaseApplicationSteps({
        ...leaseApplicationSteps,
        deal: "",
        professional: "",
      })
    );
  };

  const fromDeal = () => {
    setShowNotFound(false);
    dispatch(
      setleaseApplicationSteps({
        ...leaseApplicationSteps,
        selectedType: leaseApplicantValue.ADDFROMDEAL,
        professional: "",
      })
    );
  };

  return (
    <Paper sx={{ padding: "20px", margin: "20px 0px", position: "relative" }}>
      <Typography sx={{ textAlign: "center" }}>
        <Icon fontSize="large">sentiment_dissatisfied</Icon>
      </Typography>
      <Typography
        sx={{ fontSize: "18px", fontWeight: "bold", textAlign: "center", marginBottom: "15px" }}
      >
        Oops! {type} not found from your input. Would you like to create new {type} or find form
        Existing
        {type} ?
      </Typography>
      <Box sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
        <MDButton
          variant="gradient"
          color="info"
          size="small"
          className="xs-small"
          onClick={leaseApplicationSteps?.professional ? findNewUser : addNew}
          sx={{ m: "0 5px" }}
        >
          {leaseApplicationSteps?.professional ? "Find New User" : "Create New Deal"}
        </MDButton>
        <MDButton
          variant="gradient"
          color="info"
          size="small"
          className="xs-small"
          onClick={fromDeal}
          sx={{ m: "0 5px" }}
        >
          {leaseApplicationSteps?.professional ? "Find From Deal" : "From Deal"}
        </MDButton>
      </Box>
    </Paper>
  );
}

export default NotFoundRecord;
