import { useState, useEffect, ReactNode } from "react";
import { Card, Grid } from "@mui/material";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import CustomDropZone from "components/CustomDropZone";
import MDAvatar from "components/MDAvatar";
import MDDialog from "components/MDDialog";
import userDefaultImg from "assets/images/user_default_image.png";
import backgroundImage from "assets/images/bg-profile.jpeg";
import { setLoading } from "store/slices/authSlice";
import { updateUserProfile } from "store/thunk/authThunk";
import { useAppDispatch, useAppSelector } from "store/store";
import { toBase64 } from "helper/services";

function Header({ children }: { children?: ReactNode }): JSX.Element {
  const dispatch = useAppDispatch();
  const notificationInfo = useAppSelector((state) => state.notificationReducer);
  const { userData } = useAppSelector((state) => state.authReducer);
  const [open, setOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState("");
  const [profilePic, setProfilePic] = useState<string>(userDefaultImg);

  useEffect(() => {
    if (userData && userData.profile_picture) {
      setProfilePic(userData.profile_picture);
    }
  }, [userData]);

  const onProfileChange = async (files: any) => {
    if (files[0]) {
      const base64String: any = await toBase64(files[0]);
      if (base64String) {
        setSelectedImage(base64String);
      }
    }
  };

  const toggleDialog = () => {
    if (open) {
      setSelectedImage("");
    }
    setOpen(!open);
  };

  const onSave = () => {
    if (selectedImage) {
      dispatch(setLoading(true));
      const req = {
        user_id: userData.user_id,
        profile_picture: selectedImage,
      };
      dispatch(updateUserProfile(req));
    }
  };

  useEffect(() => {
    if (notificationInfo.status && open) {
      toggleDialog();
    }
  }, [notificationInfo]);

  return (
    <MDBox position="relative" mb={5}>
      <MDDialog
        open={open}
        onClose={toggleDialog}
        onSave={onSave}
        title="Choose profile picture"
        size="sm"
      >
        <CustomDropZone
          multipleUpload={false}
          acceptedFiles={{
            "image/*": [".gif", ".png", ".jpg", ".jpeg"],
          }}
          onDrop={onProfileChange}
        >
          Drop your image here, or Browse
        </CustomDropZone>
        {selectedImage && (
          <Grid container alignItems="center" textAlign="center" mt={2}>
            <MDAvatar size="xl" shadow="sm" src={selectedImage} sx={{ margin: "0 auto" }} />
          </Grid>
        )}
      </MDDialog>
      <MDBox
        display="flex"
        alignItems="center"
        position="relative"
        minHeight="18.75rem"
        borderRadius="xl"
        sx={{
          backgroundImage: ({ functions: { rgba, linearGradient }, palette: { gradients } }) =>
            `${linearGradient(
              rgba(gradients.info.main, 0.6),
              rgba(gradients.info.state, 0.6)
            )}, url(${backgroundImage})`,
          backgroundSize: "cover",
          backgroundPosition: "50%",
          overflow: "hidden",
        }}
      />
      <Card
        sx={{
          position: "relative",
          mt: -8,
          mx: 3,
          py: 2,
          px: 2,
        }}
      >
        <Grid container spacing={3} alignItems="center">
          <Grid item>
            <MDAvatar
              onClick={toggleDialog}
              src={profilePic}
              alt="profile-image"
              size="xl"
              shadow="sm"
              sx={{
                cursor: "pointer",
              }}
            />
          </Grid>
          <Grid item>
            <MDBox height="100%" mt={0.5} lineHeight={1}>
              <MDTypography variant="h5" fontWeight="medium">
                {userData && userData.first_name}
                {userData && !userData.first_name && !userData.last_name
                  ? userData.login_email
                  : ""}
              </MDTypography>
              <MDTypography variant="button" color="text" fontWeight="regular">
                {userData && userData.last_name}
              </MDTypography>
            </MDBox>
          </Grid>
        </Grid>
        {children}
      </Card>
    </MDBox>
  );
}

// Declaring default props for Header
Header.defaultProps = {
  children: "",
};

export default Header;
