import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Card,
  Grid,
  Icon,
  Typography,
} from "@mui/material";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";
import DataTable from "examples/Tables/DataTable";
import { getAllStylistApi, searchMatchingRM } from "store/thunk/systemAdmin";
import { setSearchResultStylist, setMatchingRentManager } from "store/slices/systemAdminSlice";
import { useAppDispatch, useAppSelector } from "store/store";
import { useState } from "react";
import { getLocalDateTime, phoneNumberformat } from "helper/services";
import MDLabelValue from "components/MDLabelValue";
import MDIconButton from "components/MDIconButton";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";

function SolaCmsSearch(): JSX.Element {
  const dispatch = useAppDispatch();
  const { stylistSearchResult, matchingRentManagers } = useAppSelector(
    (state) => state.systemAdminSlice
  );
  const { loading } = useAppSelector((state) => state.authReducer);
  const [error, setError] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [detailViewData, setDetailViewData] = useState<any>(false);
  const [isShowMatchingRm, setIsShowMatchingRm] = useState(false);
  const actionButtons = (object: any) => (
    <MDIconButton
      tooltipName="View"
      aria-label="Info"
      color="info"
      onClick={() => setDetailViewData(object)}
    >
      <Icon fontSize="small">visibility</Icon>
    </MDIconButton>
  );

  const getRows = () => {
    const data: any = [];
    if (stylistSearchResult.length > 0) {
      stylistSearchResult.forEach((obj: any) => {
        const objNew = {
          name: obj.name || "-",
          phone_number: obj.phone_number ? phoneNumberformat(obj.phone_number) : "-",
          email_address: obj.email_address || "-",
          business_name: obj.business_name || "-",
          status: obj.status || "-",
          action: actionButtons(obj),
        };
        data.push(objNew);
      });
    }
    return data;
  };
  const tableData = {
    columns: [
      { Header: "Name", accessor: "name", export: true },
      { Header: "Email address", accessor: "email_address", export: true },
      { Header: "Phone Number", accessor: "phone_number", export: true },
      { Header: "Business Name", accessor: "business_name", export: true },
      { Header: "Status", accessor: "status", export: true },
      { Header: "Action", accessor: "action", width: "50px", export: false, disableSortBy: true },
    ],
    rows: getRows(),
  };
  const searchData = (e: any) => {
    e.preventDefault();
    if (!searchValue) {
      setError(true);
    } else {
      const request = {
        search: searchValue,
        fields: [
          "id",
          "email_address",
          "name",
          "phone_number",
          "business_name",
          "url_name",
          "website_url",
          "status",
          "booking_url",
          "location_name",
          "billing_first_name",
          "billing_last_name",
          "billing_phone",
          "billing_email",
          "rent_manager_id",
          "rm_status",
        ],
      };
      dispatch(getAllStylistApi(request));
    }
  };
  const onChangeField = (e: any) => {
    setSearchValue(e.target.value);
    setError(false);
  };
  const resetSearch = () => {
    setSearchValue("");
    dispatch(setSearchResultStylist([]));
  };

  const getRowsRentManagerList = () => {
    const data: any = [];
    if (matchingRentManagers.length > 0) {
      matchingRentManagers.forEach((obj: any) => {
        const objNew = {
          id: obj.id || "-",
          name: obj.name || "-",
          first_name: obj.first_name || "-",
          last_name: obj.last_name || "-",
          email: obj.email || "-",
          phone: obj.phone ? phoneNumberformat(obj.phone) : "-",
          tenant_id: obj.tenant_id || "-",
          location_id: obj.location_id || "-",
          property_id: obj.property_id || "-",
          status: obj.status || "-",
          active_start_date: obj.active_start_date ? getLocalDateTime(obj.active_start_date) : "-",
          active_end_date: obj.active_end_date ? getLocalDateTime(obj.active_end_date) : "-",
          last_payment_date: obj.last_payment_date ? getLocalDateTime(obj.last_payment_date) : "-",
          last_transaction_date: obj.last_transaction_date
            ? getLocalDateTime(obj.last_transaction_date)
            : "-",
          updated_at: obj.updated_at ? getLocalDateTime(obj.updated_at) : "-",
          created_at: obj.created_at ? getLocalDateTime(obj.created_at) : "-",
        };
        data.push(objNew);
      });
    }
    return data;
  };
  const matchingRmData = {
    columns: [
      { Header: "ID", accessor: "id" },
      { Header: "Name", accessor: "name" },
      { Header: "First Name", accessor: "first_name" },
      { Header: "Last Name", accessor: "last_name" },
      { Header: "Email address", accessor: "email" },
      { Header: "Phone Number", accessor: "phone" },
      { Header: "Tenant Id", accessor: "tenant_id" },
      { Header: "Location Id", accessor: "location_id" },
      { Header: "Property Id", accessor: "property_id" },
      { Header: "Status", accessor: "status" },
      { Header: "Active Start Date", accessor: "active_start_date" },
      { Header: "Active End Date", accessor: "active_end_date" },
      { Header: "Last Payment Date", accessor: "last_payment_date" },
      { Header: "Last Transaction Date", accessor: "last_transaction_date" },
      { Header: "Updated At", accessor: "updated_at" },
      { Header: "Created At", accessor: "created_at" },
    ],
    rows: getRowsRentManagerList(),
  };
  const onSearchMatchingRm = () => {
    if (detailViewData?.id) {
      setIsShowMatchingRm(true);
      dispatch(searchMatchingRM({ stylist_id: parseInt(detailViewData.id, 10) }));
    }
  };

  const onBack = () => {
    setDetailViewData(false);
    setIsShowMatchingRm(false);
    dispatch(setMatchingRentManager([]));
  };

  return (
    <Card>
      {detailViewData && (
        <MDBox>
          <Grid container spacing={2}>
            <Grid item md={12} sx={{ padding: 0 }}>
              <MDButton onClick={() => onBack()} startIcon={<ArrowBackIosIcon />}>
                Back
              </MDButton>
            </Grid>
          </Grid>
        </MDBox>
      )}
      {!detailViewData && (
        <>
          <MDBox
            component="form"
            role="form"
            onSubmit={(e) => searchData(e)}
            mt={3}
            mb={1}
            padding={5}
          >
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12} md={12}>
                <Typography>Sola CMS</Typography>
              </Grid>
              <Grid item xs={12} sm={6} md={6}>
                <MDInput
                  label="Search"
                  variant="standard"
                  required
                  fullWidth
                  onChange={onChangeField}
                  name="title"
                  placeholder="Enter search text"
                  InputLabelProps={{ shrink: true }}
                  value={searchValue}
                  error={error}
                  helperText={error ? "This is field is required" : ""}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={6}>
                <MDButton
                  variant="gradient"
                  color="info"
                  size="small"
                  sx={{ marginTop: "5px" }}
                  type="submit"
                  disabled={loading}
                >
                  Search{" "}
                  <Icon sx={{ fontWeight: "bold", marginLeft: "4px", fontSize: "40px" }}>
                    search
                  </Icon>
                </MDButton>
                {"     "}
                <MDButton
                  variant="gradient"
                  color="dark"
                  size="small"
                  sx={{ marginTop: "5px" }}
                  onClick={() => resetSearch()}
                >
                  Reset
                </MDButton>
              </Grid>
            </Grid>
          </MDBox>
          <MDBox
            bgColor="dark"
            color="white"
            coloredShadow="dark"
            borderRadius="xl"
            alignItems="center"
            justifyContent="space-between"
            pt={1}
            pb={1}
            pl={2}
            pr={2}
            ml={2}
            mr={2}
            display="flex"
            mt={4}
            className="page-header"
          >
            <p className="page-header-label">Search Result</p>
          </MDBox>
          <DataTable
            showExportSelectedBtn={false}
            showExportAllBtn={false}
            table={tableData}
            canSearch
            searchText="Search..."
          />
        </>
      )}
      {detailViewData && (
        <>
          <MDBox mt={3} padding={5}>
            <Grid container spacing={2}>
              <Grid item md={6}>
                <MDLabelValue label="ID" value={detailViewData?.id || "-"} />
              </Grid>
              <Grid item md={6}>
                <MDLabelValue label="Name" value={detailViewData?.name || "-"} />
              </Grid>
              <Grid item md={6}>
                <MDLabelValue label="Email Address" value={detailViewData?.email_address || "-"} />
              </Grid>
              <Grid item md={6}>
                <MDLabelValue
                  label="Phone Number"
                  value={phoneNumberformat(detailViewData?.phone_number) || "-"}
                />
              </Grid>
              <Grid item md={6}>
                <MDLabelValue label="Business Name" value={detailViewData?.business_name || "-"} />
              </Grid>
              <Grid item md={6}>
                <MDLabelValue label="Status" value={detailViewData?.status || "-"} />
              </Grid>
              <Grid item md={6}>
                <MDLabelValue label="Url Name" value={detailViewData?.url_name || "-"} />
              </Grid>
              <Grid item md={6}>
                <MDLabelValue label="Website Url" value={detailViewData?.website_url || "-"} />
              </Grid>
              <Grid item md={6}>
                <MDLabelValue label="Booking Url" value={detailViewData?.booking_url || "-"} />
              </Grid>
              <Grid item md={6}>
                <MDLabelValue label="Location Name" value={detailViewData?.location_name || "-"} />
              </Grid>
            </Grid>
            <Accordion expanded>
              <AccordionSummary style={{ background: "#dbdbdb", height: "50px" }}>
                <Typography>Rent Manager</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Grid container spacing={2}>
                  <Grid item md={6}>
                    <MDLabelValue
                      label="Rent Manager Id"
                      value={detailViewData?.rent_manager_id || "-"}
                    />
                  </Grid>
                  <Grid item md={6}>
                    <MDLabelValue
                      label="Rent Manager Status"
                      value={detailViewData?.rm_status || "-"}
                    />
                  </Grid>
                </Grid>
              </AccordionDetails>
            </Accordion>
            <Accordion expanded>
              <AccordionSummary style={{ background: "#dbdbdb", height: "50px" }}>
                <Typography>Billing Detail</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Grid container spacing={2}>
                  <Grid item md={6}>
                    <MDLabelValue
                      label="Billing First Name"
                      value={detailViewData?.billing_first_name || "-"}
                    />
                  </Grid>
                  <Grid item md={6}>
                    <MDLabelValue
                      label="Billing Last Name"
                      value={detailViewData?.billing_last_name || "-"}
                    />
                  </Grid>
                  <Grid item md={6}>
                    <MDLabelValue
                      label="Billing Phone"
                      value={detailViewData?.billing_phone || "-"}
                    />
                  </Grid>
                  <Grid item md={6}>
                    <MDLabelValue
                      label="Billing Email"
                      value={detailViewData?.billing_email || "-"}
                    />
                  </Grid>
                </Grid>
              </AccordionDetails>
            </Accordion>
          </MDBox>
          <MDBox padding={5} textAlign="left">
            <Grid item xs={12} sm={12} md={12}>
              <MDButton
                variant="gradient"
                color="info"
                size="small"
                sx={{ marginTop: "5px" }}
                onClick={() => onSearchMatchingRm()}
                disabled={loading}
              >
                Find Matching Rent Manager{" "}
                <Icon sx={{ fontWeight: "bold", marginLeft: "4px", fontSize: "40px" }}>search</Icon>
              </MDButton>
            </Grid>
          </MDBox>
          {isShowMatchingRm && (
            <DataTable
              showExportSelectedBtn={false}
              showExportAllBtn={false}
              table={matchingRmData}
              canSearch
              searchText="Search..."
            />
          )}
        </>
      )}
    </Card>
  );
}

export default SolaCmsSearch;
