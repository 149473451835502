import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";

export default function SimpleLoader(props: any) {
  const { size } = props;
  return (
    <Box sx={{ display: "flex" }}>
      <CircularProgress size={size || 20} style={{ color: "#fff" }} />
    </Box>
  );
}
