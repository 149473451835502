import { Card, Tab, Tabs } from "@mui/material";
import MDBox from "components/MDBox";
import MDTabPanel from "components/MDTabPanel";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import { useEffect, useState } from "react";
import ApprovedList from "layouts/pages/sola-web/update-web-page-requests/approved";
import PendingList from "layouts/pages/sola-web/update-web-page-requests/pending";
import RejectedList from "layouts/pages/sola-web/update-web-page-requests/rejected";
import { useAppDispatch } from "store/store";
import { checkWebpageRequestIsApproved } from "store/thunk/solaProsThunk";

function Index(): JSX.Element {
  const dispatch = useAppDispatch();
  const [idState, setIdState] = useState<any>(null);
  const [tabValue, setTabValue] = useState<number>(0);
  const changeTab = (event: any, newValue: any) => {
    setTabValue(newValue);
  };
  const queryParameter: URLSearchParams = new URLSearchParams(window.location.search);

  useEffect(() => {
    if (queryParameter) {
      const webpageRequestId: any = queryParameter.get("webpage_request_id");
      if (webpageRequestId) {
        queryParameter.delete("webpage_request_id");
        dispatch(checkWebpageRequestIsApproved({ webpage_request_id: webpageRequestId }))
          .unwrap()
          .then((result: any) => {
            if (result?.approved_status) {
              setIdState("request-already-approved");
            } else if (result && Object.keys(result)?.length) {
              setIdState(webpageRequestId);
            }
          });
      }
    }
  }, []);

  const props: any = {
    idState,
    setIdState,
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={4} pb={3}>
        <Card>
          <MDBox minWidth={{ xs: "100%", md: "25rem" }} mx="auto" mt={2} mb={2}>
            <Tabs className="location-tabs" value={tabValue} onChange={changeTab}>
              <Tab
                id="pending"
                label={
                  <MDBox py={0.5} px={2} color="inherit">
                    Pending
                  </MDBox>
                }
              />
              <Tab
                id="approved"
                label={
                  <MDBox py={0.5} px={2} color="inherit">
                    Approved
                  </MDBox>
                }
              />
              <Tab
                id="rejected"
                label={
                  <MDBox py={0.5} px={2} color="inherit">
                    Rejected
                  </MDBox>
                }
              />
            </Tabs>
          </MDBox>
          <MDTabPanel value={tabValue} index={0}>
            <PendingList {...props} />
          </MDTabPanel>
          <MDTabPanel value={tabValue} index={1}>
            <ApprovedList />
          </MDTabPanel>
          <MDTabPanel value={tabValue} index={2}>
            <RejectedList />
          </MDTabPanel>
        </Card>
      </MDBox>
    </DashboardLayout>
  );
}

export default Index;
