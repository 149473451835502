import { useEffect, useState } from "react"; // SD-3402
import MDDialog from "components/MDDialog";
import {
  Grid,
  Tabs,
  Tab,
  AccordionDetails,
  AccordionSummary,
  Accordion,
  Icon,
  Card,
  FormControl,
  FormControlLabel,
  FormGroup,
  Checkbox,
  Switch,
} from "@mui/material";
import MDTypography from "components/MDTypography";
import MDBox from "components/MDBox";
import moment from "moment";
import { adminSuperAdmin, getLocationTabs, systemAdmin } from "helper/services";
import { useAppDispatch, useAppSelector } from "store/store";
import { getLocation, getLocationHistoryList } from "store/thunk/locationThunk";
import CustomChipList from "components/CustomChipList";
import * as React from "react";
import { getSolaProsList } from "store/thunk/solaProsThunk";
import ImagePreview from "layouts/pages/ImagePreview";
import DataTable from "examples/Tables/DataTable";
import userDefaultImg from "assets/images/user_default_image.png";
import { RRule } from "rrule";
import SmsRequest from "./smsRequest";

function ViewDetails(props: any): JSX.Element {
  const dispatch = useAppDispatch();
  const { open, updateData, onClose } = props;
  const { connectLocation, locationHistoryDetails } = useAppSelector(
    (state) => state.locationSlice
  );
  const { amenitiesList } = useAppSelector((state) => state.systemAdminSlice);
  const { solaProsList } = useAppSelector((state) => state.solaProSlice);
  const [locationData, setLocationData] = useState<any>({});
  const [isPreview, setImagePreviewPopup] = useState(false);
  const [imageToPreview, setImageToPreview] = useState("");
  const [solaList, setSolaList] = useState<any>({});
  const [pageAccess, setPageAccess] = useState<any>([]);
  const [users, setUsersData] = useState([]);
  const [unitsData, setUnitsData] = useState([]);
  const [aliasData, setAliasData] = useState([]);
  const [tabValue, setTabValue] = useState<any>();
  const [locationHistoryData, setLocationHistoryData] = useState([]);
  const [dealOwnerId, setDealOwnerId] = useState<any>(null);
  const [dripCampaign, setDripCampaign] = useState<any>(false);
  const [crmTabSettings, setCrmTabSettings] = useState<any>({});
  const [weekendTilldate, setWeekendTilldate] = useState<any>(null);

  useEffect(() => {
    if (updateData && updateData?.cms_location_id) {
      dispatch(getLocation({ id: updateData?.cms_location_id, cmsLocation: true }));
    }
  }, []);

  useEffect(() => {
    if (connectLocation) {
      console.log("connectLocation ", connectLocation);
      setLocationData(connectLocation);
      const owners: any = connectLocation?.owners?.map((obj: any) => obj.connect_user);
      const tabAccess = getLocationTabs(connectLocation?.cms_location_id, owners);
      setAliasData(tabAccess?.map((obj: any) => obj.alias)); // SD-3402
      if (tabAccess?.length > 0) {
        setPageAccess(tabAccess);
      }
      if (connectLocation?.location_users && Array.isArray(connectLocation?.location_users)) {
        setUsersData(connectLocation?.location_users);
      }
      if (connectLocation?.units && Array.isArray(connectLocation?.units)) {
        setUnitsData(connectLocation?.units);
      }
      if (connectLocation?.recurring_rule) {
        const rule = RRule.fromString(connectLocation?.recurring_rule);
        setWeekendTilldate(moment(rule.options.until).format());
      }
    }
    if (solaProsList) {
      setSolaList(solaProsList);
    }
    if (locationHistoryDetails && Array.isArray(locationHistoryDetails)) {
      setLocationHistoryData(locationHistoryDetails);
    }

    if (connectLocation?.deal_default_owner_id) {
      setDealOwnerId({
        value: {
          user_id: connectLocation?.deal_default_owner_id,
          first_name: connectLocation?.connect_user?.first_name || "",
          last_name: connectLocation?.connect_user?.last_name || "",
          profile_picture: connectLocation?.connect_user?.profile_picture || "",
        },
        label: `${connectLocation?.connect_user?.first_name || ""} ${
          connectLocation?.connect_user?.last_name || ""
        } `,
      });
      if (connectLocation?.drip_campaign) {
        setDripCampaign(connectLocation?.drip_campaign);
      }
    } else {
      setDealOwnerId(null);
    }
    const tempCrmData = connectLocation?.crm_data;
    const updateFolloups = tempCrmData?.followups?.map((obj: any) => {
      const tempObj = { ...obj };
      if (tempObj.send_sms === false) {
        tempObj.send_sms = true;
      }
      if (tempObj.send_email === true) {
        tempObj.send_email = false;
      }
      return tempObj;
    });
    setCrmTabSettings({
      ...tempCrmData,
      followups: updateFolloups,
    });
  }, [connectLocation, solaProsList, locationHistoryDetails]);

  // Fro set tabValue Default
  useEffect(() => {
    if (aliasData[0] !== tabValue) {
      setTabValue(aliasData[0]);
    }
  }, [aliasData[0]]);

  const dialogProps = {
    open,
    onClose,
    saveBtn: false,
    title: "View Location",
    size: "lg",
  };

  const previewImage = (image: any) => {
    setImagePreviewPopup(true);
    setImageToPreview(image);
  };

  const closeImagePreview = () => {
    setImagePreviewPopup(false);
    setImageToPreview("");
  };

  const getIcon = (value: any) => {
    if (value) {
      return (
        <Icon fontSize="medium" color="success">
          check_circle_rounded
        </Icon>
      );
    }
    return (
      <Icon fontSize="medium" color="error">
        cancel
      </Icon>
    );
  };

  const getIconElement = (name: any, iconValue: any) => (
    <Grid item xs={12} sm={6} md={6}>
      <MDTypography fontWeight="bold" variant="button">
        {name}
      </MDTypography>
      <MDBox>{getIcon(iconValue)}</MDBox>
    </Grid>
  );

  const getImageElement = (name: any, imageData: any) => {
    if (imageData) {
      return (
        <Grid item xs={12} sm={6} md={6}>
          <MDTypography fontWeight="bold" variant="button">
            {name}
          </MDTypography>
          <Grid container mt={2}>
            <Grid item xs={12} sm={6} md={6}>
              {imageData && (
                <Grid
                  container
                  onClick={() => previewImage(imageData)}
                  // justifyContent="left"
                  // alignItems="center"
                  // display="flex"
                >
                  <Grid item className="thumbnail-img">
                    <img height={100} width={125} src={imageData} alt="location" />
                  </Grid>
                </Grid>
              )}
            </Grid>
          </Grid>
        </Grid>
      );
    }
    return null;
  };

  const getDetailElementWithDangersoulyHTML = (name: any, value: any) => {
    if (value) {
      return (
        <Grid item xs={12} sm={6} md={6}>
          <MDTypography fontWeight="bold" variant="button">
            {name}
          </MDTypography>
          <MDBox
            dangerouslySetInnerHTML={{
              __html: value ? value : "",
            }}
          />
        </Grid>
      );
    }
    return null;
  };

  const getFromToDateData = (name: any, crmData: any) => {
    const fromDateString = crmData?.send_initial_contact_from
      ? moment(crmData?.send_initial_contact_from)
          .tz(crmData?.timezone)
          .format("YYYY-MM-DD HH:mm:ss")
      : "";

    const toDateString = crmData?.send_initial_contact_to
      ? moment(crmData?.send_initial_contact_to).tz(crmData?.timezone).format("YYYY-MM-DD HH:mm:ss")
      : "";

    return (
      <Grid item xs={12} sm={6} md={6}>
        <MDTypography fontWeight="bold" variant="button">
          {name}
        </MDTypography>

        <MDBox sx={{ wordBreak: "break-all" }}>
          {fromDateString && toDateString ? `From ${fromDateString} To ${toDateString}` : "-"}
        </MDBox>
      </Grid>
    );
  };

  const getOwnerData = (name: any, ownerData: any) => (
    <Grid item xs={12} sm={6} md={6}>
      <img
        style={{ height: "25px", width: "25px", borderRadius: "50%" }}
        src={ownerData?.value?.profile_picture ? ownerData?.value?.profile_picture : userDefaultImg}
        alt={ownerData?.value?.profile_picture}
      />
      <span
        style={{ position: "absolute", marginTop: "-1px", textAlign: "center", padding: "5px" }}
      >
        {ownerData?.label ? ownerData?.label : "No Owner "}
      </span>
    </Grid>
  );

  const getDetailElement = (name: any, value: any) => (
    <Grid item xs={12} sm={6} md={6}>
      <MDTypography fontWeight="bold" variant="button">
        {name}
      </MDTypography>
      <MDBox sx={{ wordBreak: "break-all" }}>{value ? value : "-"}</MDBox>
    </Grid>
  );

  const getAccordianSummary = (name: any) => (
    <AccordionSummary className="location-accordion" style={{ cursor: "auto" }}>
      <MDTypography>{name}</MDTypography>
    </AccordionSummary>
  );

  const loadFollowUps = (): JSX.Element => {
    const items: any = [];
    for (let i = 1; i <= 10; i++) {
      if (locationData?.crm_data) {
        items.push(
          <Accordion expanded>
            {getAccordianSummary(`Follow-Up ${i}`)}
            <AccordionDetails>
              <Grid container spacing={2}>
                {getIconElement("Enabled", locationData?.crm_data?.[`follow_up_${i}`])}
                {getIconElement("Send SMS", locationData?.crm_data?.[`follow_up_${i}_sms`])}
                {getIconElement("Send Email", locationData?.crm_data?.[`follow_up_${i}_email`])}
                {getDetailElement(
                  "Time",
                  locationData?.crm_data?.[`send_follow_up_${i}`]
                    ? `${
                        locationData?.crm_data?.[`send_follow_up_${i}`]
                      } day(s) after initial contact`
                    : "-"
                )}
              </Grid>
            </AccordionDetails>
          </Accordion>
        );
      }
    }
    return items;
  };

  // // const loadImages = (): JSX.Element => {
  // //   const items: any = [];
  // //   for (let i = 1; i <= 20; i++) {
  // //     items.push(
  // //       locationData?.[`image_${i}_url`] &&
  // //         getImageElement(`Image ${i}`, locationData?.[`image_${i}_url`])
  // //     );
  // //   }
  // //   return items;
  // // };

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setTabValue(newValue);
    if (newValue === "location_solapros") {
      dispatch(getSolaProsList({ locationId: locationData?.cms_location_id }));
    }
    if (newValue === "location_history") {
      dispatch(getLocationHistoryList({ locationId: locationData?.cms_location_id }));
    }
  };

  const getRows = () => {
    const data: any = users?.map((obj: any) => ({
      id: obj?.user_id,
      first_name: obj?.first_name || "-",
      last_name: obj?.last_name || "-",
      email: obj?.login_email || "-",
    }));
    return data;
  };

  const tableData = {
    columns: [
      { Header: "Id", accessor: "id", export: true },
      { Header: "First name", accessor: "first_name", export: true },
      { Header: "Last name", accessor: "last_name", export: true },
      { Header: "Email", accessor: "email", export: true },
    ],
    rows: getRows(),
  };

  const getUnitRows = () => {
    const data: any = unitsData?.map((obj: any) => ({
      type: obj?.type || "-",
      units: obj?.unit || "-",
      // window: obj?.window || false,
    }));
    return data;
  };

  const tableDataUnits = {
    columns: [
      { Header: "Type", accessor: "type", export: true },
      { Header: "Units", accessor: "units", export: true },
      // { Header: "Window", accessor: "window", export: true },
    ],
    rows: getUnitRows(),
  };

  const getLocationHistory = () => {
    const data: any = locationHistoryData?.map((obj: any) => ({
      data: obj?.changed_data || "-",
    }));
    return data;
  };

  const tableLocationHistory = {
    columns: [{ Header: "Data", accessor: "changed_data", export: true }],
    rows: getLocationHistory(),
  };

  // // SD-3402
  // const getMDLabel = (label: any, value: any) => (
  //   <>
  //     <Grid item xs={12} sm={2}>
  //       <MDTypography variant="button" fontWeight="bold" textTransform="capitalize">
  //         {label}
  //       </MDTypography>
  //     </Grid>
  //     <Grid item xs={12} sm={10}>
  //       <MDTypography variant="button" fontWeight="regular" color="text">
  //         {value}
  //       </MDTypography>
  //     </Grid>
  //   </>
  // );

  let amenitiesIds: any = [];
  const locationAmenities = locationData?.location?.connect_location_amenities || [];
  if (locationAmenities?.length) {
    amenitiesIds = locationAmenities?.map((obj: any) => Number(obj?.amenity_id));
  }

  const getDetailElementDelay = (name: any, followData: any) => (
    <Grid item xs={12} sm={12} md={12}>
      <MDTypography fontWeight="bold" variant="button">
        {name}
      </MDTypography>

      <table className="custom-table-delay">
        <tbody>
          {followData && followData.length > 0
            ? followData.map((obj: any, index: any) => (
                <tr>
                  <td>
                    {getDetailElement(
                      "",
                      `Send follow-up ${
                        obj?.send_follow_up_days
                      } day(s) after initial contact at ${moment(
                        followData?.[index]?.send_follow_up_time
                      ).format("hh:mm a")}`
                    )}
                  </td>
                </tr>
              ))
            : null}
        </tbody>
      </table>
    </Grid>
  );

  const getMsasName = (msas?: any[]) => msas?.map((msa: any) => msa?.name)?.toString();

  return (
    <MDDialog {...dialogProps}>
      <MDBox borderRadius="xl" alignItems="center">
        {pageAccess && pageAccess?.length > 0 && (
          <Tabs
            className="location-tabs"
            indicatorColor="secondary"
            classes={{ indicator: "info" }}
            value={tabValue}
            onChange={handleChange}
          >
            {pageAccess.map((obj: any) => (
              <Tab
                label={
                  <MDBox py={0.5} px={2} color="inherit">
                    {obj.display_menu}
                  </MDBox>
                }
                value={obj?.alias}
              />
            ))}
          </Tabs>
        )}
      </MDBox>
      <Grid container spacing={2}>
        <Grid item xs={12} md={12}>
          {aliasData.includes("location_general") && tabValue === "location_general" && (
            <>
              <MDBox mt={1}>
                <MDBox>
                  {(locationData?.cms_location_id ||
                    locationData?.name ||
                    locationData?.location?.msas?.length ||
                    locationData?.store_id) && (
                    <Accordion expanded>
                      {getAccordianSummary("Basic Info")}
                      <AccordionDetails>
                        <Grid container spacing={2}>
                          {getDetailElement("Location Id", locationData?.cms_location_id)}
                          {getDetailElement("Location Name", locationData?.name)}
                          <Grid item xs={12} sm={6} md={6}>
                            <MDTypography fontWeight="bold" variant="button">
                              Association
                            </MDTypography>
                            <MDBox>
                              {locationData?.association &&
                              locationData?.association?.length > 0 ? (
                                <CustomChipList
                                  listData={locationData?.association.map((obj: any) => ({
                                    ...obj,
                                    title: obj?.association_name,
                                  }))}
                                />
                              ) : (
                                <MDTypography variant="button">-</MDTypography>
                              )}
                            </MDBox>
                          </Grid>
                          {getDetailElement("MSA", getMsasName(locationData?.location?.msas))}
                          {getDetailElement("Sola ID", locationData?.store_id)}
                          {getDetailElement("Status", locationData?.status)}
                          {/* Add two new fileds */}
                          {adminSuperAdmin() && (
                            <>
                              {getDetailElement("FBC", locationData?.fbc)}{" "}
                              {getDetailElement("Region", locationData?.region)}
                            </>
                          )}
                          <Grid item xs={12} sm={6} md={6}>
                            <MDTypography fontWeight="bold" variant="button">
                              Blogs
                            </MDTypography>
                            {/* SD-3424 */}
                            <MDBox>
                              {locationData?.location_blogs &&
                              locationData?.location_blogs?.length > 0 ? (
                                <CustomChipList
                                  listData={locationData?.location_blogs.map((obj: any) => ({
                                    ...obj,
                                    title: obj?.title,
                                  }))}
                                />
                              ) : (
                                <MDTypography variant="button">-</MDTypography>
                              )}
                            </MDBox>
                          </Grid>
                        </Grid>
                      </AccordionDetails>
                    </Accordion>
                  )}
                </MDBox>
              </MDBox>
              <MDBox mt={1}>
                {(locationData?.general_contact_first_name ||
                  locationData?.general_contact_last_name ||
                  locationData?.general_contact_email ||
                  locationData?.email_address_for_inquiries ||
                  locationData?.email_address_for_reports ||
                  locationData?.email_address_for_hubspot ||
                  locationData?.emails_for_stylist_website_approvals ||
                  locationData?.phone_number) && (
                  <Accordion expanded>
                    {getAccordianSummary("Contact Info")}
                    <AccordionDetails>
                      <Grid container spacing={2}>
                        {getDetailElement("First Name", locationData?.general_contact_first_name)}
                        {getDetailElement("Last Name", locationData?.general_contact_last_name)}
                        {getDetailElement("Contact Email", locationData?.general_contact_email)}
                        {getDetailElement("Contact Phone", locationData?.phone_number)}
                        {getDetailElement(
                          "Email For Website Inquiries",
                          locationData?.email_address_for_inquiries
                        )}
                        {getDetailElement(
                          "Email For Reports",
                          locationData?.email_address_for_reports
                        )}
                        {getDetailElement(
                          "Email For Hubspot",
                          locationData?.email_address_for_hubspot
                        )}
                        {getDetailElement(
                          "Email For Sola Professional Website Approval",
                          locationData?.emails_for_stylist_website_approvals
                        )}
                      </Grid>
                    </AccordionDetails>
                  </Accordion>
                )}
              </MDBox>

              <MDBox mt={1}>
                {(locationData?.address_1 ||
                  locationData?.address_2 ||
                  locationData?.city ||
                  locationData?.state ||
                  locationData?.postal_code ||
                  locationData?.country ||
                  locationData?.latitude ||
                  locationData?.longitude ||
                  locationData?.custom_maps_url) && (
                  <Accordion expanded>
                    {getAccordianSummary("Address Info")}
                    <AccordionDetails>
                      <Grid container spacing={2}>
                        {getDetailElement("Address 1", locationData?.address_1)}
                        {getDetailElement("Address 2", locationData?.address_2)}
                        {getDetailElement("City", locationData?.city)}
                        {getDetailElement("State(Province in CA)", locationData?.state)}
                        {getDetailElement("Postal Code", locationData?.postal_code)}
                        {getDetailElement("Country", locationData?.country)}
                        {getDetailElement("Latitude", locationData?.latitude)}
                        {getDetailElement("Longitude", locationData?.longitude)}
                        {getDetailElement("Custom Maps URL", locationData?.custom_maps_url)}
                        {getDetailElement("Map / GMB URL", locationData?.gmb_map_url)}
                      </Grid>
                    </AccordionDetails>
                  </Accordion>
                )}
              </MDBox>

              <MDBox mt={1}>
                {(locationData?.max_walkins_time ||
                  locationData?.walkins_enabled ||
                  locationData?.walkins_end_of_day ||
                  locationData?.walkins_timezone ||
                  locationData?.floorplan_image_url) && (
                  <Accordion expanded>
                    {getAccordianSummary("Walk-Ins")}
                    <AccordionDetails>
                      <Grid container spacing={2}>
                        {getIconElement("Walk-Ins Enabled", locationData?.walkins_enabled)}
                        {getDetailElement("Max Walk-Ins Time", locationData?.max_walkins_time)}
                        {getDetailElement(
                          "Walk-Ins End Of Day",
                          locationData?.walkins_end_of_day
                            ? moment(locationData?.walkins_end_of_day).utcOffset(0)?.format("HH:mm")
                            : ""
                        )}
                        {getDetailElement("Walk-Ins Time Zone", locationData?.walkins_timezone)}
                        {locationData?.floorplan_image_url &&
                          getImageElement("Floorplan Image", locationData?.floorplan_image_url)}
                        {getDetailElement(
                          "Rockbot Manager Email",
                          locationData?.rockbot_manager_email
                        )}
                      </Grid>
                    </AccordionDetails>
                  </Accordion>
                )}
              </MDBox>

              <MDBox mt={1}>
                {(locationData?.mailchimp_list_ids || locationData?.callfire_list_ids) && (
                  <Accordion expanded>
                    {getAccordianSummary("Integration")}
                    <AccordionDetails>
                      <Grid container spacing={2}>
                        {getDetailElement("Mailchimp List Ids", locationData?.mailchimp_list_ids)}
                        {getDetailElement("Callfire List Ids", locationData?.callfire_list_ids)}
                      </Grid>
                    </AccordionDetails>
                  </Accordion>
                )}
              </MDBox>
              <MDBox mt={1}>
                {locationData?.owners?.length ? (
                  <MDBox mt={1}>
                    {" "}
                    {locationData?.owners && (
                      <Accordion expanded>
                        {getAccordianSummary("Owners")}
                        <AccordionDetails>
                          <Grid container spacing={2}>
                            <Grid item xs={12} sm={12} md={12}>
                              {locationData?.owners && (
                                <CustomChipList
                                  listData={locationData?.owners
                                    .map((obj: any) => ({
                                      ...obj?.connect_user,
                                      title: obj?.connect_user?.first_name || null,
                                    }))
                                    .filter((f: any) => f?.title !== null)}
                                />
                              )}
                            </Grid>
                          </Grid>
                        </AccordionDetails>
                      </Accordion>
                    )}
                  </MDBox>
                ) : (
                  ""
                )}
              </MDBox>
            </>
          )}

          {aliasData?.includes("location_cms") && tabValue === "location_cms" && (
            <>
              <MDBox mt={1}>
                <MDBox>
                  <Accordion expanded>
                    {getAccordianSummary("General")}
                    <AccordionDetails>
                      <Grid container spacing={2}>
                        {getDetailElement("Location Name", locationData?.name)}
                        {getDetailElement("URL Name", locationData?.url_name)}
                        {getDetailElementWithDangersoulyHTML(
                          "Description",
                          locationData?.description
                        )}
                        {adminSuperAdmin() && (
                          <>
                            {getDetailElement("MSA", getMsasName(locationData?.location?.msas))}
                            {getDetailElement("Franchisee", locationData?.franchisee)}
                          </>
                        )}
                        {getDetailElement("Sola ID", locationData?.store_id)}
                        {getDetailElement("Status", locationData?.status)}
                        {/* Add two new fileds */}
                        {adminSuperAdmin() && (
                          <>
                            {getDetailElement("FBC", locationData?.fbc)}{" "}
                            {getDetailElement("Region", locationData?.region)}
                          </>
                        )}
                      </Grid>
                    </AccordionDetails>
                  </Accordion>
                </MDBox>
              </MDBox>
              <MDBox mt={1}>
                <Accordion expanded>
                  {getAccordianSummary("Contact")}
                  <AccordionDetails>
                    <Grid container spacing={2}>
                      {getDetailElement("General Contact Name", locationData?.general_contact_name)}
                      {getDetailElement(
                        "Email Address For Inquiries",
                        locationData?.email_address_for_inquiries
                      )}
                      {getDetailElement(
                        "Email Address For Reports",
                        locationData?.email_address_for_reports
                      )}
                      {getDetailElement(
                        "Email Address For Hubspot",
                        locationData?.email_address_for_hubspot
                      )}
                      {getDetailElement(
                        "Email Addresses For Stylist Website Approvals",
                        locationData?.emails_for_stylist_website_approvals
                      )}
                      {getDetailElement("Phone Number", locationData?.phone_number)}
                    </Grid>
                  </AccordionDetails>
                </Accordion>
              </MDBox>
              <MDBox mt={1}>
                <Accordion expanded>
                  {getAccordianSummary("Address")}
                  <AccordionDetails>
                    <Grid container spacing={2}>
                      {getDetailElement("Address 1", locationData?.address_1)}
                      {getDetailElement("Address 2", locationData?.address_2)}
                      {getDetailElement("City", locationData?.city)}
                      {getDetailElement("State/Province", locationData?.state)}
                      {getDetailElement("Postal Code", locationData?.postal_code)}
                      {getDetailElement("Country", locationData?.country)}
                      {getDetailElement("Latitude", locationData?.latitude)}
                      {getDetailElement("Longitude", locationData?.longitude)}
                      {getDetailElement("Custom Maps URL", locationData?.custom_maps_url)}
                    </Grid>
                  </AccordionDetails>
                </Accordion>
              </MDBox>
              <MDBox mt={1}>
                <Accordion expanded>
                  {getAccordianSummary("Promotions")}
                  <AccordionDetails>
                    <Grid container spacing={2}>
                      {getDetailElementWithDangersoulyHTML(
                        "Move In Special",
                        locationData?.move_in_special
                      )}
                      {getDetailElement("Special Callout", locationData?.open_house)}
                    </Grid>
                  </AccordionDetails>
                </Accordion>
              </MDBox>
              <MDBox mt={1}>
                <Accordion expanded>
                  {getAccordianSummary("Social")}
                  <AccordionDetails>
                    <Grid container spacing={2}>
                      {getDetailElement("Facebook Url", locationData?.facebook_url)}
                      {getDetailElement("Instagram Url", locationData?.instagram_url)}
                      {/* {getDetailElement("Twitter Url", locationData?.twitter_url)} */}
                    </Grid>
                  </AccordionDetails>
                </Accordion>
              </MDBox>
              <MDBox mt={1}>
                <Accordion expanded>
                  {getAccordianSummary("Tracking Code")}
                  <AccordionDetails>
                    <Grid container spacing={2}>
                      {/* {getDetailElementWithDangersoulyHTML("Chat Code", locationData?.chat_code)} */}
                      {getDetailElementWithDangersoulyHTML(
                        "Tracking Code",
                        locationData?.tracking_code
                      )}
                    </Grid>
                  </AccordionDetails>
                </Accordion>
              </MDBox>
              <MDBox mt={1}>
                <Accordion expanded>
                  {getAccordianSummary("Images")}
                  <AccordionDetails>
                    <Grid container spacing={2}>
                      {locationData?.image_1_url &&
                        getImageElement("Image 1", locationData?.image_1_url)}
                      {getDetailElement("Image 1 Alt Text", locationData?.image_1_alt_text)}
                      {locationData?.image_2_url &&
                        getImageElement("Image 2", locationData?.image_2_url)}
                      {getDetailElement("Image 2 Alt Text", locationData?.image_2_alt_text)}
                      {locationData?.image_3_url &&
                        getImageElement("Image 3", locationData?.image_3_url)}
                      {getDetailElement("Image 3 Alt Text", locationData?.image_3_alt_text)}
                      {locationData?.image_4_url &&
                        getImageElement("Image 4", locationData?.image_4_url)}
                      {getDetailElement("Image 4 Alt Text", locationData?.image_4_alt_text)}
                      {locationData?.image_5_url &&
                        getImageElement("Image 5", locationData?.image_5_url)}
                      {getDetailElement("Image 5 Alt Text", locationData?.image_5_alt_text)}
                      {locationData?.image_6_url &&
                        getImageElement("Image 6", locationData?.image_6_url)}
                      {getDetailElement("Image 6 Alt Text", locationData?.image_6_alt_text)}
                      {locationData?.image_7_url &&
                        getImageElement("Image 7", locationData?.image_7_url)}
                      {getDetailElement("Image 7 Alt Text", locationData?.image_7_alt_text)}
                      {locationData?.image_8_url &&
                        getImageElement("Image 8", locationData?.image_8_url)}
                      {getDetailElement("Image 8 Alt Text", locationData?.image_8_alt_text)}
                      {locationData?.image_9_url &&
                        getImageElement("Image 9", locationData?.image_9_url)}
                      {getDetailElement("Image 9 Alt Text", locationData?.image_9_alt_text)}
                      {locationData?.image_10_url &&
                        getImageElement("Image 10", locationData?.image_10_url)}
                      {getDetailElement("Image 10 Alt Text", locationData?.image_10_alt_text)}
                      {locationData?.image_11_url &&
                        getImageElement("Image 11", locationData?.image_11_url)}
                      {getDetailElement("Image 11 Alt Text", locationData?.image_11_alt_text)}
                      {locationData?.image_12_url &&
                        getImageElement("Image 12", locationData?.image_12_url)}
                      {getDetailElement("Image 12 Alt Text", locationData?.image_12_alt_text)}
                      {locationData?.image_13_url &&
                        getImageElement("Image 13", locationData?.image_13_url)}
                      {getDetailElement("Image 13 Alt Text", locationData?.image_13_alt_text)}
                      {locationData?.image_14_url &&
                        getImageElement("Image 14", locationData?.image_14_url)}
                      {getDetailElement("Image 14 Alt Text", locationData?.image_14_alt_text)}
                      {locationData?.image_15_url &&
                        getImageElement("Image 15", locationData?.image_15_url)}
                      {getDetailElement("Image 15 Alt Text", locationData?.image_15_alt_text)}
                      {locationData?.image_16_url &&
                        getImageElement("Image 16", locationData?.image_16_url)}
                      {getDetailElement("Image 16 Alt Text", locationData?.image_16_alt_text)}
                      {locationData?.image_17_url &&
                        getImageElement("Image 17", locationData?.image_17_url)}
                      {getDetailElement("Image 17 Alt Text", locationData?.image_17_alt_text)}
                      {locationData?.image_18_url &&
                        getImageElement("Image 18", locationData?.image_18_url)}
                      {getDetailElement("Image 18 Alt Text", locationData?.image_18_alt_text)}
                      {locationData?.image_19_url &&
                        getImageElement("Image 19", locationData?.image_19_url)}
                      {getDetailElement("Image 19 Alt Text", locationData?.image_19_alt_text)}
                      {locationData?.image_20_url &&
                        getImageElement("Image 20", locationData?.image_20_url)}
                      {getDetailElement("Image 20 Alt Text", locationData?.image_20_alt_text)}
                    </Grid>
                  </AccordionDetails>
                </Accordion>
              </MDBox>
              <MDBox mt={1}>
                <Accordion expanded>
                  {getAccordianSummary("Rockbot")}
                  <AccordionDetails>
                    <Grid container spacing={2}>
                      {getDetailElement(
                        "Walk-Ins Enabled",
                        locationData?.walkins_enabled ? "Yes" : "No"
                      )}
                      {getDetailElement("Max Walk-Ins Time", locationData?.max_walkins_time)}
                      {getDetailElement(
                        "Walk-Ins End Of Day",
                        moment(locationData?.walkins_end_of_day).utcOffset(0)?.format("HH:mm")
                      )}
                      {getDetailElement("Walk-Ins Time Zone", locationData?.walkins_timezone)}
                      {locationData?.floorplan_image_url &&
                        getImageElement("Floorplan Image", locationData?.floorplan_image_url)}
                      {getDetailElement(
                        "Rockbot Manager Email",
                        locationData?.rockbot_manager_email
                      )}
                    </Grid>
                  </AccordionDetails>
                </Accordion>
              </MDBox>
              <MDBox mt={1}>
                <Accordion expanded>
                  {getAccordianSummary("Rent Manager")}
                  <AccordionDetails>
                    <Grid container spacing={2}>
                      {getDetailElement(
                        "Rent Manager Property ID",
                        locationData?.rent_manager_property_id
                      )}
                      {getDetailElement(
                        "Rent Manager Location ID",
                        locationData?.rent_manager_location_id
                      )}
                      {getDetailElement(
                        "Service Request Enabled",
                        locationData?.service_request_enabled ? "Yes" : "No"
                      )}
                    </Grid>
                  </AccordionDetails>
                </Accordion>
              </MDBox>
              <MDBox mt={1}>
                <Accordion expanded>
                  {getAccordianSummary("Tours360")}
                  <AccordionDetails>
                    <Grid container spacing={2}>
                      {getDetailElementWithDangersoulyHTML("Tour #1", locationData?.tour_iframe_1)}
                      {getDetailElementWithDangersoulyHTML("Tour #2", locationData?.tour_iframe_2)}
                      {getDetailElementWithDangersoulyHTML("Tour #3", locationData?.tour_iframe_3)}
                    </Grid>
                  </AccordionDetails>
                </Accordion>
              </MDBox>
              <MDBox mt={1}>
                <Accordion expanded>
                  {getAccordianSummary("Text and Email Integration")}
                  <AccordionDetails>
                    <Grid container spacing={2}>
                      {getDetailElement("Mailchimp List Ids", locationData?.mailchimp_list_ids)}
                      {getDetailElement("Callfire List Ids", locationData?.callfire_list_ids)}
                    </Grid>
                  </AccordionDetails>
                </Accordion>
              </MDBox>
            </>
          )}

          {aliasData?.includes("location_website") && tabValue === "location_website" && (
            <>
              <MDBox mt={1}>
                <MDBox>
                  <Accordion expanded>
                    {getAccordianSummary("Basic Info")}
                    <AccordionDetails>
                      <Grid container spacing={2}>
                        {getDetailElement("URL", locationData?.url_name)}
                        {getDetailElementWithDangersoulyHTML(
                          "Description",
                          locationData?.description
                        )}
                      </Grid>
                    </AccordionDetails>
                  </Accordion>
                </MDBox>
              </MDBox>
              <MDBox mt={1}>
                <MDBox>
                  <Accordion expanded>
                    {getAccordianSummary("Contact Info")}
                    <AccordionDetails>
                      <Grid container spacing={2}>
                        {getIconElement(
                          "Display General Contact Info?",
                          locationData?.display_general_contact_info
                        )}
                        {locationData?.display_general_contact_info ? (
                          <>
                            {getDetailElement(
                              "First Name :",
                              locationData?.general_contact_first_name || "-"
                            )}
                            {getDetailElement(
                              "Last Name :",
                              locationData?.general_contact_last_name || "-"
                            )}
                            {getDetailElement("Phone :", locationData?.phone_number || "-")}
                            {getDetailElement(
                              "Email :",
                              locationData?.general_contact_email || "-"
                            )}
                          </>
                        ) : (
                          <>
                            {getDetailElement(
                              "Website Contact Name",
                              locationData?.public_contact_name
                            )}
                            {getDetailElement("Website PhoneNumber", locationData?.public_phone)}
                            {getDetailElement("Website Email", locationData?.public_email)}
                          </>
                        )}
                      </Grid>
                    </AccordionDetails>
                  </Accordion>
                </MDBox>
              </MDBox>
              <MDBox mt={1}>
                <MDBox>
                  <Accordion expanded>
                    {getAccordianSummary("Images")}
                    <AccordionDetails>
                      <Grid container spacing={2}>
                        {locationData?.image_1_url &&
                          getImageElement("Image 1", locationData?.image_1_url)}
                        {getDetailElement("Image 1 Alt Text", locationData?.image_1_alt_text)}
                        {locationData?.image_2_url &&
                          getImageElement("Image 2", locationData?.image_2_url)}
                        {getDetailElement("Image 2 Alt Text", locationData?.image_2_alt_text)}
                        {locationData?.image_3_url &&
                          getImageElement("Image 3", locationData?.image_3_url)}
                        {getDetailElement("Image 3 Alt Text", locationData?.image_3_alt_text)}
                        {locationData?.image_4_url &&
                          getImageElement("Image 4", locationData?.image_4_url)}
                        {getDetailElement("Image 4 Alt Text", locationData?.image_4_alt_text)}
                        {locationData?.image_5_url &&
                          getImageElement("Image 5", locationData?.image_5_url)}
                        {getDetailElement("Image 5 Alt Text", locationData?.image_5_alt_text)}
                        {locationData?.image_6_url &&
                          getImageElement("Image 6", locationData?.image_6_url)}
                        {getDetailElement("Image 6 Alt Text", locationData?.image_6_alt_text)}
                        {locationData?.image_7_url &&
                          getImageElement("Image 7", locationData?.image_7_url)}
                        {getDetailElement("Image 7 Alt Text", locationData?.image_7_alt_text)}
                        {locationData?.image_8_url &&
                          getImageElement("Image 8", locationData?.image_8_url)}
                        {getDetailElement("Image 8 Alt Text", locationData?.image_8_alt_text)}
                        {locationData?.image_9_url &&
                          getImageElement("Image 9", locationData?.image_9_url)}
                        {getDetailElement("Image 9 Alt Text", locationData?.image_9_alt_text)}
                        {locationData?.image_10_url &&
                          getImageElement("Image 10", locationData?.image_10_url)}
                        {getDetailElement("Image 10 Alt Text", locationData?.image_10_alt_text)}
                        {locationData?.image_11_url &&
                          getImageElement("Image 11", locationData?.image_11_url)}
                        {getDetailElement("Image 11 Alt Text", locationData?.image_11_alt_text)}
                        {locationData?.image_12_url &&
                          getImageElement("Image 12", locationData?.image_12_url)}
                        {getDetailElement("Image 12 Alt Text", locationData?.image_12_alt_text)}
                        {locationData?.image_13_url &&
                          getImageElement("Image 13", locationData?.image_13_url)}
                        {getDetailElement("Image 13 Alt Text", locationData?.image_13_alt_text)}
                        {locationData?.image_14_url &&
                          getImageElement("Image 14", locationData?.image_14_url)}
                        {getDetailElement("Image 14 Alt Text", locationData?.image_14_alt_text)}
                        {locationData?.image_15_url &&
                          getImageElement("Image 15", locationData?.image_15_url)}
                        {getDetailElement("Image 15 Alt Text", locationData?.image_15_alt_text)}
                        {locationData?.image_16_url &&
                          getImageElement("Image 16", locationData?.image_16_url)}
                        {getDetailElement("Image 16 Alt Text", locationData?.image_16_alt_text)}
                        {locationData?.image_17_url &&
                          getImageElement("Image 17", locationData?.image_17_url)}
                        {getDetailElement("Image 17 Alt Text", locationData?.image_17_alt_text)}
                        {locationData?.image_18_url &&
                          getImageElement("Image 18", locationData?.image_18_url)}
                        {getDetailElement("Image 18 Alt Text", locationData?.image_18_alt_text)}
                        {locationData?.image_19_url &&
                          getImageElement("Image 19", locationData?.image_19_url)}
                        {getDetailElement("Image 19 Alt Text", locationData?.image_19_alt_text)}
                        {locationData?.image_20_url &&
                          getImageElement("Image 20", locationData?.image_20_url)}
                        {getDetailElement("Image 20 Alt Text", locationData?.image_20_alt_text)}
                      </Grid>
                    </AccordionDetails>
                  </Accordion>
                </MDBox>
              </MDBox>
              <MDBox mt={1}>
                <MDBox>
                  <Accordion expanded>
                    {getAccordianSummary("Social Links")}
                    <AccordionDetails>
                      <Grid container spacing={2}>
                        {getDetailElement("Facebook URL", locationData?.facebook_url)}
                        {getDetailElement("Instagram URL", locationData?.instagram_url)}
                        {getDetailElement("Twitter URL", locationData?.twitter_url)}
                      </Grid>
                    </AccordionDetails>
                  </Accordion>
                </MDBox>
              </MDBox>
              <MDBox mt={1}>
                <MDBox>
                  <Accordion expanded>
                    {getAccordianSummary("Integration")}
                    <AccordionDetails>
                      <Grid container spacing={2}>
                        {systemAdmin() && (
                          <>
                            {getDetailElement("Tour #1", locationData?.tour_iframe_1)}
                            {getDetailElement("Tour #2", locationData?.tour_iframe_2)}
                            {getDetailElement("Tour #2", locationData?.tour_iframe_3)}
                          </>
                        )}
                      </Grid>
                    </AccordionDetails>
                  </Accordion>
                </MDBox>
              </MDBox>
              <MDBox mt={1}>
                <MDBox>
                  <Accordion expanded>
                    {getAccordianSummary("Settings")}
                    <AccordionDetails>
                      <Grid container spacing={2}>
                        {getDetailElementWithDangersoulyHTML(
                          "Move-In Special",
                          locationData?.move_in_special
                        )}
                        {getDetailElement("Special Callout", locationData?.open_house)}
                        {getDetailElement(
                          "Display contact form on website",
                          <Switch
                            checked={locationData?.display_contact_form_on_website}
                            disabled
                          />
                        )}
                      </Grid>
                    </AccordionDetails>
                  </Accordion>
                </MDBox>
              </MDBox>
              <MDBox mt={1}>
                <MDBox>
                  <Accordion expanded>
                    {getAccordianSummary("Amenities")}
                    <AccordionDetails>
                      <Grid container spacing={2}>
                        {getDetailElement(
                          "",
                          amenitiesList?.amenities?.map((amenitiesObj: any) => (
                            <FormControl component="fieldset">
                              <FormGroup aria-label="position">
                                <FormControlLabel
                                  value={Number(amenitiesObj?.id)}
                                  name="amenities"
                                  control={
                                    <Checkbox
                                      checked={
                                        amenitiesIds?.includes(Number(amenitiesObj?.id))
                                          ? true
                                          : false
                                      }
                                      disabled
                                      inputProps={{ "aria-label": "controlled" }}
                                    />
                                  }
                                  label={amenitiesObj?.headline}
                                  labelPlacement="end"
                                />
                              </FormGroup>
                            </FormControl>
                          ))
                        )}
                      </Grid>
                    </AccordionDetails>
                  </Accordion>
                </MDBox>
              </MDBox>
            </>
          )}

          {aliasData?.includes("location_solapros") &&
          tabValue === "location_solapros" &&
          solaList?.stylists &&
          solaList?.stylists?.length > 0 ? (
            <MDBox mt={1}>
              <Accordion expanded>
                {getAccordianSummary("Sola Professionals")}
                <AccordionDetails>
                  <CustomChipList
                    listData={solaList?.stylists
                      ?.map((obj: any) => ({
                        ...obj,
                        title: obj?.name || null,
                      }))
                      .filter((f: any) => f?.title !== null)}
                  />
                </AccordionDetails>
              </Accordion>
            </MDBox>
          ) : (
            tabValue === "location_solapros" && (
              <MDTypography style={{ textAlign: "center", fontWeight: "bold", fontSize: "medium" }}>
                No data found
              </MDTypography>
            )
          )}
          {aliasData?.includes("location_crm") && tabValue === "location_crm" && (
            <MDBox mt={1}>
              {locationData?.connect_twillio_sms_request ||
              (locationData?.connect_twillio_phone_number?.status === "enabled" &&
                locationData?.crm_data) ? (
                <>
                  <Accordion expanded>
                    {getAccordianSummary("Owner Detail")}
                    <AccordionDetails>
                      <Grid container spacing={2}>
                        {getOwnerData("Deal owner", dealOwnerId)}
                      </Grid>
                    </AccordionDetails>
                  </Accordion>
                  <Accordion expanded>
                    {getAccordianSummary("Weekend / Out of Office")}
                    <AccordionDetails>
                      <Grid container spacing={2}>
                        {getIconElement("Weekend", locationData.weekend)}
                        {getIconElement("Out of Office", locationData.out_of_office)}
                        {getDetailElement("Timezone", locationData.weekend_timezone)}
                        {getDetailElement(
                          "Enddate of Weekend",
                          moment(weekendTilldate).format("YYYY-MM-DD")
                        )}
                        {getDetailElement(
                          "Startdate of Out of Office",
                          moment(locationData.out_office_start_date)
                            .utc()
                            .format("YYYY-MM-DD hh:mm a")
                        )}
                        {getDetailElement(
                          "Enddate of Out of Office",
                          moment(locationData.out_office_end_date)
                            .utc()
                            .format("YYYY-MM-DD hh:mm a")
                        )}
                        {getDetailElement(
                          "Out of Office Lead Owner",
                          locationData?.secondary_user
                            ? locationData?.secondary_user.first_name
                            : "No Owner"
                        )}
                        {getDetailElement("Weekend Message", locationData.weekend_message)}
                      </Grid>
                    </AccordionDetails>
                  </Accordion>
                  <Accordion expanded>
                    {getAccordianSummary("Automatic Enrollment")}
                    <AccordionDetails>
                      <Grid container spacing={2}>
                        {getIconElement("Automatically Enroll All New Deals", dripCampaign)}
                      </Grid>
                    </AccordionDetails>
                  </Accordion>
                  <Accordion expanded>
                    {getAccordianSummary("Communication Delay")}
                    <AccordionDetails>
                      <Grid container spacing={2}>
                        {getDetailElement("Timezone", crmTabSettings?.timezone)}
                        {getDetailElement(
                          "Send initial contact",
                          locationData?.crm_data?.send_initial_contact
                            ? `${locationData?.crm_data?.send_initial_contact} minutes after form submission`
                            : "-"
                        )}
                        {getFromToDateData("Only send this first message between", crmTabSettings)}
                        {getDetailElement(
                          "Set lead as closed",
                          crmTabSettings?.set_lead_as_closed
                            ? `${crmTabSettings?.set_lead_as_closed} day(s) after last follow-up is sent`
                            : "-"
                        )}
                        {getDetailElementDelay(
                          "If lead hasn't replied...",
                          crmTabSettings?.followups
                        )}
                      </Grid>
                    </AccordionDetails>
                  </Accordion>

                  <Accordion expanded>
                    {getAccordianSummary("Initial Response")}
                    <AccordionDetails>
                      <Grid container spacing={2}>
                        {getIconElement("Enabled", locationData?.crm_data?.initial_response)}
                        {getIconElement("Send SMS", locationData?.crm_data?.initial_response_sms)}
                        {getIconElement(
                          "Send Email",
                          locationData?.crm_data?.initial_response_email
                        )}
                      </Grid>
                    </AccordionDetails>
                  </Accordion>
                  <MDBox>{loadFollowUps()}</MDBox>
                </>
              ) : (
                <SmsRequest locationData={locationData} section="crm" />
              )}
            </MDBox>
          )}

          {aliasData?.includes("location_rentmanager") && tabValue === "location_rentmanager" && (
            <>
              <MDBox mt={1}>
                {(locationData?.from_email_address || locationData?.to_email_address) && (
                  <Accordion expanded>
                    {getAccordianSummary("Correspondence")}
                    <AccordionDetails>
                      <Grid container spacing={2}>
                        {getDetailElement(
                          "SEND FROM Email Address",
                          locationData?.from_email_address
                        )}
                        {getDetailElement("REPLY TO Email Address", locationData?.to_email_address)}
                      </Grid>
                    </AccordionDetails>
                  </Accordion>
                )}
              </MDBox>
              <MDBox mt={1}>
                {(locationData?.store_id ||
                  locationData?.name ||
                  locationData?.notice_address ||
                  locationData?.master_landlord ||
                  locationData?.manager?.login_email ||
                  locationData?.business_name ||
                  locationData?.incorporation_state ||
                  locationData?.notice_address) && (
                  <Accordion expanded>
                    {getAccordianSummary("Business Info")}
                    <AccordionDetails>
                      <Grid container spacing={2}>
                        {getDetailElement("Sola ID", locationData?.store_id)}
                        {getDetailElement(
                          "Full Name",
                          `${locationData?.store_id} - ${locationData?.name}`
                        )}
                        {getDetailElement("Short Name", locationData?.short_name)}
                        {getDetailElement("Address", locationData?.notice_address)}
                        {getDetailElement("Master Landlord", locationData?.master_landlord)}
                        {getDetailElement("Lease Manager", locationData?.manager)}
                        {getDetailElement("Business Name", locationData?.business_name)}
                        {getDetailElement(
                          "State Of Incorporation",
                          locationData?.incorporation_state
                        )}
                        {getDetailElement("Notice Address", locationData?.notice_address)}
                      </Grid>
                    </AccordionDetails>
                  </Accordion>
                )}
              </MDBox>
              <MDBox mt={1}>
                {(locationData?.default_bank || locationData?.security_deposit_payment_method) && (
                  <Accordion expanded>
                    {getAccordianSummary("Banking Information")}
                    <AccordionDetails>
                      <Grid container spacing={2}>
                        {getDetailElement("Bank Account", locationData?.default_bank)}
                        {getDetailElement(
                          "Security Deposit Payment Method",
                          locationData?.security_deposit_payment_method
                        )}
                      </Grid>
                    </AccordionDetails>
                  </Accordion>
                )}
              </MDBox>
              <MDBox mt={1}>
                {(locationData?.rent_period ||
                  locationData?.rent_due_date ||
                  locationData?.lease_term ||
                  locationData?.security_deposit_amount ||
                  locationData?.insurance_term ||
                  locationData?.insurance_fee ||
                  locationData?.insurance_charge_frequency ||
                  locationData?.annual_price_increase ||
                  locationData?.weeks_free_rent ||
                  locationData?.months_free_insurence) && (
                  <Accordion expanded>
                    {getAccordianSummary("Default Dates & Fees")}
                    <AccordionDetails>
                      <Grid container spacing={2}>
                        {getDetailElement("Rent Period", locationData?.rent_period)}
                        {getDetailElement("Rent Due Date", locationData?.rent_due_date)}
                        {getDetailElement("Lease Term", locationData?.lease_term)}
                        {getDetailElement(
                          "Security Deposit Amount",
                          locationData?.security_deposit_amount
                        )}
                        {getDetailElement("Insurance Term", locationData?.insurance_term)}
                        {getDetailElement("Insurance Fee", locationData?.insurance_fee)}
                        {getDetailElement("Charge Fee", locationData?.insurance_charge_frequency)}
                        {getDetailElement(
                          "Annual Price Increase",
                          locationData?.annual_price_increase
                        )}
                        {getDetailElement("Weeks Free Rent", locationData?.weeks_free_rent)}
                        {getDetailElement(
                          "Months Free Insurance",
                          locationData?.months_free_insurence
                        )}
                        {getIconElement("Free Paint", locationData?.free_paint)}
                      </Grid>
                    </AccordionDetails>
                  </Accordion>
                )}
              </MDBox>
            </>
          )}

          {aliasData?.includes("location_studios") && tabValue === "location_studios" && (
            <MDBox mt={7}>
              <Card>
                <MDBox
                  bgColor="dark"
                  color="white"
                  coloredShadow="dark"
                  borderRadius="xl"
                  alignItems="center"
                  justifyContent="space-between"
                  pt={1}
                  pb={1}
                  pl={2}
                  pr={2}
                  ml={2}
                  mr={2}
                  display="flex"
                  mt={-3}
                  className="page-header"
                >
                  <p className="page-header-label">Studios</p>
                </MDBox>
                <DataTable table={tableDataUnits} canSearch searchText="Search..." />
              </Card>
            </MDBox>
          )}

          {aliasData?.includes("location_users") && tabValue === "location_users" && (
            <MDBox mt={7}>
              <Card>
                <MDBox
                  bgColor="dark"
                  color="white"
                  coloredShadow="dark"
                  borderRadius="xl"
                  alignItems="center"
                  justifyContent="space-between"
                  pt={1}
                  pb={1}
                  pl={2}
                  pr={2}
                  ml={2}
                  mr={2}
                  display="flex"
                  mt={-3}
                  className="page-header"
                >
                  <p className="page-header-label">Users</p>
                </MDBox>
                <DataTable table={tableData} canSearch searchText="Search..." />
              </Card>
            </MDBox>
          )}

          {aliasData?.includes("location_history") &&
          tabValue === "location_history" &&
          locationHistoryData &&
          locationHistoryData?.length > 0 ? (
            <MDBox mt={7}>
              <Card>
                <MDBox
                  bgColor="dark"
                  color="white"
                  coloredShadow="dark"
                  borderRadius="xl"
                  alignItems="center"
                  justifyContent="space-between"
                  pt={1}
                  pb={1}
                  pl={2}
                  pr={2}
                  ml={2}
                  mr={2}
                  display="flex"
                  mt={-3}
                  className="page-header"
                >
                  <p className="page-header-label">History</p>
                </MDBox>
                <DataTable table={tableLocationHistory} canSearch searchText="Search..." />
              </Card>
            </MDBox>
          ) : (
            tabValue === "location_history" && (
              <MDTypography style={{ textAlign: "center", fontWeight: "bold", fontSize: "medium" }}>
                No data found
              </MDTypography>
            )
          )}
        </Grid>
      </Grid>
      {isPreview && (
        <ImagePreview
          imageData={imageToPreview}
          open={isPreview}
          onCloseImage={() => {
            closeImagePreview();
          }}
        />
      )}
    </MDDialog>
  );
}

export default ViewDetails;
