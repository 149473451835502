import { AppBar, Card, Grid, Icon, IconButton, Tab, Tabs, Typography } from "@mui/material";
import axios from "axios";
import MDBox from "components/MDBox";
import MDTabPanel from "components/MDTabPanel";
import constants from "helper/constants";
import { getAuthUser } from "helper/services";
import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "store/store";
import { getCmsLocations } from "store/thunk/locationThunk";
import config from "config/config";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import {
  getDateRange,
  rankKeys,
  formatScorecard,
  scoercardDateRange,
  getFirstDateOfCurrentMonth,
} from "helper/reports";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import { notificationFail, notificationSuccess } from "store/slices/notificationSlice";
import Messages from "helper/messages";
import CustomLoader from "components/CustomLoader";
import Scorecard from "./scorecard";
import ScorecardTable from "./scorecard-table";
import MaturedLocation from "./maturedLocation";

export interface SelectedDate {
  value: string;
}

export interface AvailableLocation {
  id: string;
  location: string;
  label: string;
}

export interface SelectedDateRange {
  startMonth: string;
  endMonth: string;
}

function convertToFirstDayOfMonth(dateString: string) {
  const dateParts = dateString.split("-");
  const year = dateParts[0];
  const month = dateParts[1];
  const firstDayOfMonth = `${year}-${month}-01`;
  return firstDayOfMonth;
}

function fetchCurrentDate() {
  const date = new Date().toJSON();
  return date.slice(0, 10);
}

export const convertToLastDayOfMonth = (dateString: string) => {
  const [year, month] = dateString.split("-");
  const adjustedMonth = month === "00" ? "01" : month;
  const adjustedYear = month === "00" ? String(Number(year)) : year;
  const firstDayOfMonth = new Date(`${adjustedYear}-${adjustedMonth}-01`);
  const lastDayOfMonth = new Date(firstDayOfMonth);
  lastDayOfMonth.setMonth(lastDayOfMonth.getMonth() + 1);
  lastDayOfMonth.setDate(lastDayOfMonth.getDate() - 1);
  const formattedDate = lastDayOfMonth.toISOString().substring(0, 10);
  return formattedDate;
};
function ScorecardDashboard() {
  const [tabValue, setTabValue] = useState<number>(0);
  const { cmsLocations } = useAppSelector((state) => state.locationSlice);
  const userDetails = getAuthUser();
  const [selectedDate, setSelectedDate] = useState<SelectedDate>();
  const [maturedLocationsData, getMaturedLocationsData] = useState<
    {
      [key: string]: number | string;
    }[]
  >(undefined);
  const [scorecardData, setScorecardData] = useState<
    {
      [key: string]: number | string;
    }[]
  >(undefined);
  const [availableLocation, setAvailableLocation] = useState<AvailableLocation[]>([]);
  const [selectedScorecard, setSelectedScorecard] = useState<{
    [key: string]: number | string;
  }>();
  const [count, setCount] = useState<number>();
  const [countMatured, setCountMatured] = useState<number>();
  const [selectedLocation, setSelectedLocation] = useState<AvailableLocation>();
  const [disableFields, setDisableFields] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [showFilter, setShowFilter] = useState<boolean>(false);
  const [selectedDateRange, setSelectedDateRange] = useState<SelectedDateRange>({
    startMonth: "",
    endMonth: "",
  });
  const dispatch = useAppDispatch();
  const [currentDateCheckBox, setCurrentDateCheckBox] = useState<boolean>(false);
  const [initialLoader, setinitialLoader] = useState<boolean>(false);
  // const buttonRef = useRef(null);
  const [rawScorecardURL, setRawScorecardURL] = useState<string>("");

  const fetchRawScorecard = () => {
    try {
      if (selectedDateRange.startMonth > selectedDateRange.endMonth) {
        throw new Error("Start month should be lower than end month");
      } else {
        setDisableFields(true);
        setIsLoading(true);
        const acceptedRoals = ["Admin", "System Admin"];
        let url = "";
        if (
          selectedDate &&
          (acceptedRoals.includes(userDetails.role) || !acceptedRoals.includes(userDetails.role))
        ) {
          const startDate = currentDateCheckBox
            ? getFirstDateOfCurrentMonth()
            : convertToFirstDayOfMonth(selectedDateRange.startMonth);
          const endDate = currentDateCheckBox
            ? fetchCurrentDate()
            : convertToLastDayOfMonth(selectedDateRange.endMonth);
          url = `${config.REPORT_URL}/report/scorecardRawData?startDate=${startDate}&endDate=${endDate}`;
          axios
            .get(url, {
              responseType: "json",
            })
            .then((res: any) => {
              setRawScorecardURL(res.data.result);
              dispatch(
                notificationSuccess(res.data.message || Messages.SUCCESS.MASTER_DATA_CREATED)
              );
            })
            .catch((err) => {
              dispatch(
                notificationFail(
                  err?.response?.data?.message || Messages.ERROR.SOMETHING_WENT_WRONG
                )
              );
            })
            .finally(() => {
              setDisableFields(false);
              setIsLoading(false);
              setinitialLoader(true);
            });
        }
      }
    } catch (e) {
      dispatch(notificationFail(e.message || Messages.ERROR.SOMETHING_WENT_WRONG));
    }
  };

  const fetchScorecard = (locationsData: AvailableLocation[]) => {
    try {
      if (selectedDateRange.startMonth > selectedDateRange.endMonth) {
        throw new Error("Start month should be lower than end month");
      } else {
        setDisableFields(true);
        setIsLoading(true);
        const ids = locationsData?.map((location) => location.id);
        const acceptedRoals = ["Admin", "System Admin"];
        let url = "";
        if (
          selectedDate &&
          (acceptedRoals.includes(userDetails.role) ||
            (!acceptedRoals.includes(userDetails.role) && locationsData))
        ) {
          const startDate = currentDateCheckBox
            ? getFirstDateOfCurrentMonth()
            : convertToFirstDayOfMonth(selectedDateRange.startMonth);
          const endDate = currentDateCheckBox
            ? fetchCurrentDate()
            : convertToLastDayOfMonth(selectedDateRange.endMonth);
          url = `${
            config.REPORT_URL
          }/report/getScoreCard?startDate=${startDate}&endDate=${endDate}&locations=${
            userDetails.role === "Admin" || userDetails.role === "System Admin"
              ? "All"
              : `'${ids?.join(", ")}'`
          }`;
          axios
            .get(url, {
              responseType: "json",
            })
            .then((res: any) => {
              setScorecardData(res.data.result);
              setCount(res.data.count);
              let locations: AvailableLocation[] = [];
              if (userDetails.role === "System Admin" || userDetails.role === "Admin") {
                locations = res.data.result.map((score: any) => ({
                  id: score.sola_location_id?.toString(),
                  location: score.property?.toString(),
                  label: score.property?.toString(),
                }));
              } else {
                locations = locationsData;
              }
              setAvailableLocation(locations);
              if (!selectedLocation) {
                setSelectedLocation(locations[0]);
              }
              dispatch(
                notificationSuccess(res.data.message || Messages.SUCCESS.MASTER_DATA_CREATED)
              );
            })
            .catch((err) => {
              dispatch(
                notificationFail(
                  err?.response?.data?.message || Messages.ERROR.SOMETHING_WENT_WRONG
                )
              );
            })
            .finally(() => {
              setDisableFields(false);
              setIsLoading(false);
              setinitialLoader(true);
            });
        }
      }
    } catch (e) {
      dispatch(notificationFail(e.message || Messages.ERROR.SOMETHING_WENT_WRONG));
    }
  };

  const fetchMaturedLocation = (locationsData: AvailableLocation[]) => {
    try {
      if (selectedDateRange.startMonth > selectedDateRange.endMonth) {
        throw new Error("Start month should be lower than end month");
      } else {
        setDisableFields(true);
        setIsLoading(true);
        const ids = locationsData?.map((location) => location.id);
        const acceptedRoals = ["Admin", "System Admin"];
        let url = "";
        if (
          selectedDate &&
          (acceptedRoals.includes(userDetails.role) ||
            (!acceptedRoals.includes(userDetails.role) && locationsData))
        ) {
          const startDate = currentDateCheckBox
            ? getFirstDateOfCurrentMonth()
            : convertToFirstDayOfMonth(selectedDateRange.startMonth);
          const endDate = currentDateCheckBox
            ? fetchCurrentDate()
            : convertToLastDayOfMonth(selectedDateRange.endMonth);
          url = `${
            config.REPORT_URL
          }/report/getMaturedLocations?startDate=${startDate}&endDate=${endDate}&locations=${
            userDetails.role === "Admin" || userDetails.role === "System Admin"
              ? "All"
              : `'${ids?.join(", ")}'`
          }`;
          axios
            .get(url, {
              responseType: "json",
            })
            .then((res: any) => {
              getMaturedLocationsData(res.data.result);
              setCountMatured(res.data.count);
              let locations: AvailableLocation[] = [];
              if (userDetails.role === "System Admin" || userDetails.role === "Admin") {
                locations = res.data.result.map((score: any) => ({
                  id: score.sola_location_id?.toString(),
                  location: score.property?.toString(),
                  label: score.property?.toString(),
                }));
              } else {
                locations = locationsData;
              }
              setAvailableLocation(locations);
              if (!selectedLocation) {
                setSelectedLocation(locations[0]);
              }
              dispatch(
                notificationSuccess(res.data.message || Messages.SUCCESS.MASTER_DATA_CREATED)
              );
            })
            .catch((err) => {
              dispatch(
                notificationFail(
                  err?.response?.data?.message || Messages.ERROR.SOMETHING_WENT_WRONG
                )
              );
            })
            .finally(() => {
              setDisableFields(false);
              setIsLoading(false);
              setinitialLoader(true);
            });
        }
      }
    } catch (e) {
      dispatch(notificationFail(e.message || Messages.ERROR.SOMETHING_WENT_WRONG));
    }
  };

  useEffect(() => {
    const pageSize = 50;
    const pageIndex = 0;
    const search: any = undefined;
    const sort: any[] = [];
    const filterData: any[] = [];
    dispatch(getCmsLocations({ pageSize, pageIndex, search, sort, filterData }));
    setSelectedDate(scoercardDateRange[0]);
    const [startDate, endDate] = getDateRange(scoercardDateRange[0].value);
    setSelectedDateRange({
      startMonth: startDate,
      endMonth: endDate,
    });
  }, []);

  useEffect(() => {
    const locations = cmsLocations.locations?.map((item: any) => ({
      id: item.id.toString(),
      location: item.name,
    }));
    fetchScorecard(locations);
    fetchRawScorecard();
    fetchMaturedLocation(locations);
  }, [cmsLocations, selectedDateRange, currentDateCheckBox]);

  const changeTab = (e: any, value: number) => {
    setTabValue(value);
  };

  const onChangeDateField = (event: any) => {
    const date = scoercardDateRange.find((date) => date.value === event.target.value);
    const [startDate, endDate] = getDateRange(date.value);
    setSelectedDate(date);
    setSelectedDateRange({
      startMonth: startDate,
      endMonth: endDate,
    });
  };

  const handleOnLocationChange = (e: any) => {
    const selectedLocation = availableLocation.filter(
      (location) => e.target.value === location.location
    )[0];
    setSelectedLocation(selectedLocation);
    const selectedCard = scorecardData?.filter(
      (data) => data.sola_location_id === selectedLocation?.id
    );
    if (selectedCard?.length && count) {
      setSelectedScorecard(formatScorecard(selectedCard[0], rankKeys, count));
    }
  };

  useEffect(() => {
    const selectedCard = scorecardData?.filter(
      (data) => data.sola_location_id === selectedLocation?.id
    );
    if (selectedCard?.length) {
      setSelectedScorecard(formatScorecard(selectedCard[0], rankKeys, count));
    }
  }, [scorecardData, selectedLocation]);

  const downloadRawData = async () => {
    const link = document.createElement("a");
    link.href = rawScorecardURL;
    link.setAttribute(
      "download",
      `Raw-scorecard-${selectedDateRange.startMonth}-${selectedDateRange.endMonth}.csv`
    );

    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const getComponent = (alias: any) => {
    switch (alias) {
      case "scorecard":
        return initialLoader ? (
          scorecardData && selectedScorecard ? (
            <Scorecard
              dateArr={scoercardDateRange}
              disableFields={disableFields}
              availableLocation={availableLocation}
              handleOnLocationChange={handleOnLocationChange}
              selectedLocation={selectedLocation}
              selectedScorecard={selectedScorecard}
              onChangeDateField={onChangeDateField}
              selectedDate={selectedDate}
              selectedDateRange={selectedDateRange}
              setSelectedDateRange={setSelectedDateRange}
              setSelectedDate={setSelectedDate}
              currentDateCheckBox={currentDateCheckBox}
              setCurrentDateCheckBox={setCurrentDateCheckBox}
            />
          ) : (
            <MDBox mt={3} sx={{ minHeight: "200" }}>
              <Card
                variant="outlined"
                sx={{
                  height: "200px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Typography variant="h5">No Data Found</Typography>
              </Card>
            </MDBox>
          )
        ) : null;
      case "scorecard_data":
        return scorecardData ? (
          <ScorecardTable
            scorecardData={scorecardData}
            showFilter={showFilter}
            setShowFilter={setShowFilter}
            count={count}
            dateArr={scoercardDateRange}
            onChangeDateField={onChangeDateField}
            selectedDate={selectedDate}
            selectedDateRange={selectedDateRange}
            setSelectedDateRange={setSelectedDateRange}
            setSelectedDate={setSelectedDate}
            currentDateCheckBox={currentDateCheckBox}
            setCurrentDateCheckBox={setCurrentDateCheckBox}
          />
        ) : (
          <MDBox mt={3} sx={{ minHeight: "200" }}>
            <Card
              variant="outlined"
              sx={{
                height: "200px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Typography variant="h5">No Data Found</Typography>
            </Card>
          </MDBox>
        );
      case "matured_location":
        return maturedLocationsData ? (
          <MaturedLocation
            scorecardData={maturedLocationsData.filter((item) => item.eligible === "True")}
            showFilter={showFilter}
            setShowFilter={setShowFilter}
            count={countMatured}
            dateArr={scoercardDateRange}
            onChangeDateField={onChangeDateField}
            selectedDate={selectedDate}
            selectedDateRange={selectedDateRange}
            setSelectedDateRange={setSelectedDateRange}
            setSelectedDate={setSelectedDate}
            currentDateCheckBox={currentDateCheckBox}
            setCurrentDateCheckBox={setCurrentDateCheckBox}
          />
        ) : (
          <MDBox mt={3} sx={{ minHeight: "200" }}>
            <Card
              variant="outlined"
              sx={{
                height: "200px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Typography variant="h5">No Data Found</Typography>
            </Card>
          </MDBox>
        );
      default:
        return null;
    }
  };

  const downloadCSV = () => {
    try {
      const csvString = [
        [
          "Sola ID",
          "Property",
          "MSA",
          "Contact Name",
          "State",
          "Region",
          "Opening Date",
          "FBC",
          "Income",
          "Revenue Occupied Chair",
          "Revenue Total Chair",
          "Lease Expense % By Revenue",
          "Total Chair",
          "Occupied Chair",
          "Occupancy Percentage",
          "Nps Score",
          "Nps Of Participants",
          "Move Ins",
          "Move Outs",
          "Property Name",
          "Leads",
          "SSS eligible",
          "Deal Location Id",
          "Income Rank",
          "Occupied Chair Rank",
          "Revenue Occupied Chair Rank",
          "Revenue Total Chair Rank",
          "Lease Expense % By Revenue Rank",
          "Total Chair Rank",
          "Occupancy Percentage Rank",
          "Move Ins Rank",
          "Move Outs Rank",
          "Nps Score Rank",
          "Nps Of Participants Rank",
          "Leads",
        ],
        ...scorecardData.map((item) => [
          item.sola_id,
          item.property,
          item.msa,
          item.contact_name,
          item.state,
          item.region,
          item.opening_date,
          item.fbc,
          item.total_gross,
          item.revenue_occupied_chair,
          item.revenue_total_chair,
          item.lease_expense_percentage,
          item.total_chair,
          item.occupied_chair,
          item.occupancy_percentage,
          item.nps_score,
          item.nps_of_participants,
          item.move_ins,
          item.move_outs,
          item.propertyname,
          item.leads,
          item.eligible,
          item.deal_location_id,
          item.income_rank,
          item.occupied_chair_rank,
          item.revenue_occupied_chair_rank,
          item.revenue_total_chair_rank,
          item.lease_expense_percentage_rank,
          item.total_chair_rank,
          item.occupancy_percentage_rank,
          item.move_ins_rank,
          item.move_outs_rank,
          item.nps_score_rank,
          item.nps_participants_rank,
          item.leads_rank,
        ]),
      ]
        .map((row) => row.map((value) => `"${value}"`).join(","))
        .join("\n");

      const blob = new Blob([csvString], { type: "text/csv;charset=utf-8;" });
      const url = URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute(
        "download",
        `Scorecard-${selectedDateRange.startMonth}-${selectedDateRange.endMonth}.csv`
      );

      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      dispatch(notificationSuccess(Messages.SUCCESS.SCORECARD_EXPORT_CSV_SUCCESS));
    } catch (e) {
      dispatch(notificationFail(Messages.ERROR.SCORECARD_EXPORT_CSV_ERROR));
    }
  };

  const downloadMaturedLocationCSV = () => {
    const data = scorecardData.filter((item) => item.eligible === "True");
    try {
      const csvString = [
        [
          "Sola ID",
          "Property",
          "MSA",
          "Contact Name",
          "State",
          "Region",
          "Opening Date",
          "FBC",
          "Income",
          "Revenue Occupied Chair",
          "Revenue Total Chair",
          "Lease Expense % By Revenue",
          "Total Chair",
          "Occupied Chair",
          "Occupancy Percentage",
          "Nps Score",
          "Nps Of Participants",
          "Move Ins",
          "Move Outs",
          "Property Name",
          "Leads",
          "SSS eligible",
          "Deal Location Id",
          "Income Rank",
          "Occupied Chair Rank",
          "Revenue Occupied Chair Rank",
          "Revenue Total Chair Rank",
          "Lease Expense % By Revenue Rank",
          "Total Chair Rank",
          "Occupancy Percentage Rank",
          "Move Ins Rank",
          "Move Outs Rank",
          "Nps Score Rank",
          "Nps Of Participants Rank",
          "Leads",
        ],
        ...data.map((item) => [
          item.sola_id,
          item.property,
          item.msa,
          item.contact_name,
          item.state,
          item.region,
          item.opening_date,
          item.fbc,
          item.total_gross,
          item.revenue_occupied_chair,
          item.revenue_total_chair,
          item.lease_expense_percentage,
          item.total_chair,
          item.occupied_chair,
          item.occupancy_percentage,
          item.nps_score,
          item.nps_of_participants,
          item.move_ins,
          item.move_outs,
          item.propertyname,
          item.leads,
          item.eligible,
          item.deal_location_id,
          item.income_rank,
          item.occupied_chair_rank,
          item.revenue_occupied_chair_rank,
          item.revenue_total_chair_rank,
          item.lease_expense_percentage_rank,
          item.total_chair_rank,
          item.occupancy_percentage_rank,
          item.move_ins_rank,
          item.move_outs_rank,
          item.nps_score_rank,
          item.nps_participants_rank,
          item.leads_rank,
        ]),
      ]
        .map((row) => row.map((value) => `"${value}"`).join(","))
        .join("\n");

      const blob = new Blob([csvString], { type: "text/csv;charset=utf-8;" });
      const url = URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute(
        "download",
        `Matured-location-${selectedDateRange.startMonth}-${selectedDateRange.endMonth}.csv`
      );

      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      dispatch(notificationSuccess(Messages.SUCCESS.SCORECARD_EXPORT_CSV_SUCCESS));
    } catch (e) {
      dispatch(notificationFail(Messages.ERROR.SCORECARD_EXPORT_CSV_ERROR));
    }
  };

  return (
    <>
      {isLoading && (
        <MDBox
          style={{
            background: "rgba(0,0,0,0.3)",
            zIndex: 99999,
            display: "flex",
            position: "fixed",
            alignItems: "center",
            justifyContent: "center",
            width: "100%",
            height: "100vh",
          }}
        >
          <CustomLoader />
        </MDBox>
      )}
      <DashboardLayout>
        <DashboardNavbar />
        <MDBox mt={1}>
          <Grid container sx={{ display: "flex", justifyContent: "space-between" }}>
            <Grid item xs={12} sm={8} lg={6}>
              <AppBar position="static">
                <Tabs className="scorecard-dasboard-tabs" value={tabValue} onChange={changeTab}>
                  {constants?.scorecardTabs?.map((obj: any) => (
                    <Tab label={obj.display_name} key={obj.alias} />
                  ))}
                </Tabs>
              </AppBar>
            </Grid>
            <Grid>
              <MDBox sx={{ display: "flex", justifyContent: "flex-end" }}>
                <MDBox>
                  {tabValue !== 0 && (
                    <MDBox display="flex" gap={1}>
                      <MDButton variant="gradient" color="info">
                        <MDTypography variant="subtitle2" color="white" onClick={downloadRawData}>
                          Export Raw Data
                        </MDTypography>
                      </MDButton>
                      <MDButton variant="gradient" color="info">
                        <MDTypography
                          variant="subtitle2"
                          color="white"
                          onClick={tabValue === 2 ? downloadMaturedLocationCSV : downloadCSV}
                        >
                          Export
                        </MDTypography>
                      </MDButton>
                      <IconButton
                        onClick={() => {
                          setShowFilter(!showFilter);
                        }}
                      >
                        <Icon>filter_list</Icon>
                      </IconButton>
                    </MDBox>
                  )}
                </MDBox>
              </MDBox>
            </Grid>
          </Grid>
          <MDBox>
            {constants?.scorecardTabs?.map((obj: any, key: number) => (
              <MDTabPanel index={key} padding={0} value={tabValue} key={obj.alias}>
                {getComponent(obj.alias)}
              </MDTabPanel>
            ))}
          </MDBox>
        </MDBox>
      </DashboardLayout>
    </>
  );
}

export default ScorecardDashboard;
