import React, { useEffect, useState } from "react";
import MDDialog from "components/MDDialog";
import MDInput from "components/MDInput";
import { Grid } from "@mui/material";
import Validations from "helper/validations";
import { useAppDispatch } from "store/store";
import { getAllAssociationLocations, scrollToErrorByClass } from "helper/services";
import CustomAutoSearch from "components/CustomAutoSearch";
import { createFaq } from "store/thunk/systemAdmin";

function AddUpdate(props: any): JSX.Element {
  const { open, onClose, updateData, pageNo, perPage, search, sortType } = props;
  const dispatch = useAppDispatch();
  const [faqFormData, setFaqFormData] = useState<any>({});
  const [errors, setErrors] = useState<any>({});
  const [loading] = useState<boolean>(false);
  const [isUpdate, setIsUpdate] = useState<any>(false);

  useEffect(() => {
    if (!updateData) {
      setFaqFormData({});
    }
  }, []);

  useEffect(() => {
    if (updateData) {
      setIsUpdate(true);
      setFaqFormData((prev: any) => ({
        ...prev,
        faq_id: updateData.faq_id,
        question: updateData.question,
        answer: updateData.answer,
        location: updateData?.location?.id
          ? { id: updateData?.location?.id, name: updateData?.location?.name }
          : null,
      }));
    }
  }, [updateData]);

  const onChangeField = (e: any, field: string) => {
    setFaqFormData((prev: any) => ({
      ...prev,
      [field]: e.target.value,
    }));
  };

  const onSave = () => {
    const error = Validations.validateFaqsForm(faqFormData);
    setErrors(error);
    const sendData = {
      faq_id: faqFormData?.faq_id ? faqFormData?.faq_id : "",
      question: faqFormData?.question,
      answer: faqFormData?.answer,
      location_id: faqFormData?.location?.id,
    };
    if (!Object.keys(error).length) {
      const req = {
        sendData,
        onClose,
        isUpdate,
        pageNo,
        perPage,
        search,
        sortType,
      };
      dispatch(createFaq(req));
    } else {
      scrollToErrorByClass(error);
    }
  };

  const onChangeAutoComplete = (name: string, value: any) => {
    setFaqFormData({ ...faqFormData, [name]: value });
  };

  const dialogProps = {
    open,
    onClose,
    onSave,
    saveTbtText: updateData ? "Update" : "Save",
    title: updateData ? "Edit Faq" : "Add Faq",
    size: "lg",
    loading,
  };

  return (
    <MDDialog {...dialogProps}>
      <Grid container spacing={2} display="flex" alignItems="flex-end">
        <Grid item xs={12} sm={12} md={6} lg={6}>
          <MDInput
            label="Question"
            variant="standard"
            fullWidth
            required
            name="question"
            placeholder="Question"
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => onChangeField(e, "question")}
            value={faqFormData.question || ""}
            error={errors && errors.question ? true : false}
            helperText={errors && errors.question}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6}>
          <MDInput
            label="Answer"
            fullWidth
            multiline
            required
            variant="standard"
            placeholder="Answer"
            rows={2}
            name="answer"
            onChange={(e: any) => onChangeField(e, "answer")}
            value={faqFormData?.answer ? faqFormData.answer : ""}
            error={errors && errors.answer ? true : false}
            helperText={errors && errors.answer}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={6}>
          <CustomAutoSearch
            idKey="id"
            nameKey="name"
            fieldLabel="Location"
            apiName="locations"
            responseKey="locations"
            name="location"
            required
            value={faqFormData.location || null}
            onChange={onChangeAutoComplete}
            error={errors && errors.location}
            usersData={getAllAssociationLocations()}
          />
        </Grid>
      </Grid>
    </MDDialog>
  );
}

export default AddUpdate;
