import { useCallback, useEffect, useRef, useState } from "react";
import { Card, Icon, Checkbox } from "@mui/material";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import DataTable from "examples/Tables/DataTable";
import { useAppDispatch, useAppSelector } from "store/store";
import { adminSuperAdmin, getAssociationLocations } from "helper/services";
import Messages from "helper/messages";
import MDDialog from "components/MDDialog";
import MDTypography from "components/MDTypography";
import MDIconButton from "components/MDIconButton";
import exportData from "helper/exportTableConst";
import { deleteFaqs, getFaqs } from "store/thunk/systemAdmin";
import AddUpdate from "./addUpdate";

function Faqs(): JSX.Element {
  const dispatch = useAppDispatch();
  const { faqsList } = useAppSelector((state) => state.systemAdminSlice);
  const [selectedItems, setSelectedItems] = useState([]);
  const [faqList, setFaqList] = useState([]);
  const [addUpdatePopup, setAddUpdatePopup] = useState(false);
  const [deleteFaqId, setDeleteFaq] = useState<any>(false);
  const [pageCount, setPageCount] = useState(0);
  const [pageNo, setPageNo] = useState(0);
  const [perPage, setPageSize] = useState(50);
  const [totalCount, setTotalCount] = useState(0);
  const [search, setSearch] = useState<any>(null);
  const [sortBy, setSortBy] = useState<any>([]);
  const [isCheckAll, setIsCheckAll] = useState(false);
  const [updateData, setUpdateData] = useState<any>(null);
  const fetchIdRef = useRef(0);

  useEffect(() => {
    if (faqsList && faqsList?.faqs) {
      setFaqList(faqsList?.faqs);
      setPageCount(faqsList?.meta?.total_pages);
      setTotalCount(faqsList?.meta?.total_count);
    }
  }, [faqsList]);

  const fetchData = useCallback(({ pageSize, pageIndex, search, sortBy }) => {
    const fetchId = ++fetchIdRef.current;
    const sort: any = sortBy || [];
    // We'll even set a delay to simulate a server here
    setTimeout(() => {
      if (fetchId === fetchIdRef.current) {
        setPageNo(pageIndex);
        setSearch(search);
        setSortBy(sort);
        setPageSize(pageSize);
        // setSelectedItems([]);
        setIsCheckAll(false);
        dispatch(getFaqs({ pageSize, pageIndex, search, sort }));
      }
    }, 1000);
  }, []);

  const onEditFaq = (object: any) => {
    setUpdateData(object);
    setAddUpdatePopup(true);
  };

  const actionButtons = (object: any) => (
    <>
      <MDIconButton
        tooltipName="Edit"
        aria-label="Edit"
        color="info"
        onClick={() => onEditFaq(object)}
      >
        <Icon fontSize="small">edit</Icon>
      </MDIconButton>
      <MDIconButton
        tooltipName="Delete"
        aria-label="Delete"
        color="error"
        onClick={() => setDeleteFaq(object.faq_id)}
      >
        <Icon fontSize="small">delete</Icon>
      </MDIconButton>
    </>
  );

  const onChangeCheckBox = (e: any) => {
    let items = [...selectedItems];
    if (e.target.checked && !items.includes(e.target.value)) {
      items.push(e.target.value);
      if (items.length === faqList.length) {
        setIsCheckAll(true);
      }
    } else {
      items = items.filter((id: any) => id !== e.target.value);
      setIsCheckAll(false);
    }
    setSelectedItems(items);
  };

  const checkUncheckAll = (e: any) => {
    let allIds: any = [];
    allIds = faqList.map((val: any) => String(val.faq_id));
    if (e.target.checked) {
      const selectedIds = [...new Set([...selectedItems, ...allIds])];
      setSelectedItems(selectedIds);
      setIsCheckAll(true);
    } else {
      allIds = faqList.map((val: any) => String(val.faq_id));
      allIds = selectedItems.filter((el: any) => !allIds.includes(el));
      setSelectedItems([...allIds]);
      setIsCheckAll(false);
    }
  };

  const getRows = () => {
    const data: any = faqList.map((obj: any) => ({
      checkbox: (
        <Checkbox
          checked={
            selectedItems.includes(obj.faq_id) || selectedItems.includes(obj.faq_id.toString())
          }
          onChange={(e: any) => onChangeCheckBox(e)}
          value={obj.faq_id}
        />
      ),
      faq_id: obj?.faq_id,
      question: obj?.question ? obj?.question : "-",
      answer: obj?.answer ? obj?.answer : "-",
      location: obj?.location ? obj?.location?.name : "-",
      action: actionButtons(obj),
    }));
    return data;
  };

  const tableData = {
    columns: [
      {
        Header: "",
        disableSortBy: true,
        accessor: "checkbox",
        width: "80px",
      },
      { Header: "Id", accessor: "faq_id", export: true },
      { Header: "Question", accessor: "question", export: true },
      { Header: "Answer", accessor: "answer", export: true },
      {
        Header: "Location",
        accessor: "location",
        export: true,
        associative_colums: "locations.name",
      },
      { Header: "Action", accessor: "action", export: false, disableSortBy: true, width: "200px" },
    ],
    rows: getRows(),
  };

  // Start Remove selcted items
  const removeSelectedItem = (deletedId: number) => {
    setSelectedItems(selectedItems.filter((item: any) => Number(item) !== deletedId));
  };
  // End Remove selcted items

  const onDelete = () => {
    if (deleteFaqId) {
      const delReq = {
        faqId: deleteFaqId,
        onClose: () => setDeleteFaq(false),
        setDeleteFaq,
        pageNo,
        perPage,
        search,
        sortBy,
        removeSelectedItem,
      };
      dispatch(deleteFaqs(delReq));
    }
  };

  const deleteDialogProps = {
    open: deleteFaqs,
    onClose: () => setDeleteFaq(false),
    onSave: () => onDelete(),
    title: "Delete Faq",
    size: "sm",
    saveBtn: false,
    deleteBtn: true,
    saveTbtText: "Yes",
    closeBtnText: "No",
  };

  const closeModal = () => {
    setUpdateData(null);
    setAddUpdatePopup(false);
  };

  // Remove uncheck boxes
  const clearCheckItem = () => {
    setSelectedItems([]);
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={4} pb={3}>
        <Card>
          <MDBox
            bgColor="dark"
            color="white"
            coloredShadow="dark"
            borderRadius="xl"
            alignItems="center"
            justifyContent="space-between"
            pt={1}
            pb={1}
            pl={2}
            pr={2}
            ml={2}
            mr={2}
            display="flex"
            mt={-3}
            className="page-header"
          >
            <p className="page-header-label">FAQs</p>
            <MDButton
              variant="gradient"
              color="light"
              size="small"
              className="xs-small"
              onClick={() => {
                setAddUpdatePopup(true);
              }}
            >
              <Icon sx={{ fontWeight: "bold" }}>add</Icon> Add New
            </MDButton>
          </MDBox>
          <DataTable
            table={tableData}
            canSearch
            searchText="Search Faq..."
            selectedItems={selectedItems}
            showExportSelectedBtn
            showExportAllBtn
            pageCount={pageCount}
            pageNo={pageNo}
            setPageNo={setPageNo}
            totalCount={totalCount}
            fetchData={fetchData}
            exportFields={exportData.faq.export_fields}
            exportTable={exportData.faq.table_name}
            exportType={exportData.faq.export_type}
            manualSearch
            locationsIds={!adminSuperAdmin() && getAssociationLocations()}
            manualSort
            isDisplayCheckAllCheckbox
            onChangeCheckAllCheckbox={checkUncheckAll}
            isCheckAll={isCheckAll}
            clearCheckItem={clearCheckItem}
          />
          {addUpdatePopup && (
            <AddUpdate
              open={addUpdatePopup}
              onClose={() => closeModal()}
              pageNo={pageNo}
              perPage={perPage}
              search={search}
              sortType={sortBy}
              updateData={updateData}
            />
          )}
          {deleteFaqId && (
            <MDDialog {...deleteDialogProps}>
              <MDTypography variant="h6" fontWeight="medium">
                {Messages.GENERAL.SURE_TO_DELETE}
              </MDTypography>
            </MDDialog>
          )}
        </Card>
      </MDBox>
    </DashboardLayout>
  );
}

export default Faqs;
