import { useEffect, useState } from "react";
import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";
import { Theme } from "@mui/material/styles";

import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import { useAppSelector } from "store/store";

import { ScrollToSection } from "helper/services";

import { useMaterialUIController } from "context";

function Sidenav(): JSX.Element {
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;
  const { solaProDetails } = useAppSelector((state: any) => state.solaProSlice);
  const [sidenavItems, setSidenavItems] = useState([]);

  useEffect(() => {
    const items = [
      { icon: "language", label: "website", href: "website" },
      { icon: "people", label: "Sola Pros", href: "users" },
      { icon: "badge", label: "integrations", href: "integrations" },
      { icon: "settings", label: "Settings", href: "settings" },
      // { icon: "add_location", label: "Locations", href: "locations" },
      { icon: "event_available", label: "work hours", href: "work-hours" },
      { icon: "connect_without_contact", label: "Social", href: "social" },
      { icon: "image", label: "Banner Image", href: "banner" },
      { icon: "image", label: "Images", href: "images" },
      { icon: "forum", label: "Testimonials", href: "testimonials" },
    ];
    setSidenavItems(items);
  }, [solaProDetails]);

  const renderSidenavItems = sidenavItems.map(({ icon, label, href }, key) => {
    const itemKey = `item-${key}`;

    return (
      <MDBox key={itemKey} component="li" pt={key === 0 ? 0 : 1}>
        <MDTypography
          component="a"
          onClick={() => ScrollToSection(href)}
          variant="button"
          fontWeight="regular"
          textTransform="capitalize"
          sx={({
            borders: { borderRadius },
            functions: { pxToRem },
            palette: { light },
            transitions,
          }: Theme) => ({
            display: "flex",
            alignItems: "center",
            cursor: "pointer",
            borderRadius: borderRadius.md,
            padding: `${pxToRem(10)} ${pxToRem(16)}`,
            transition: transitions.create("background-color", {
              easing: transitions.easing.easeInOut,
              duration: transitions.duration.shorter,
            }),

            "&:hover": {
              backgroundColor: light.main,
            },
          })}
        >
          <MDBox mr={1.5} lineHeight={1} color={darkMode ? "white" : "dark"}>
            <Icon fontSize="small">{icon}</Icon>
          </MDBox>
          {label}
        </MDTypography>
      </MDBox>
    );
  });

  return (
    <Card
      sx={{
        borderRadius: ({ borders: { borderRadius } }) => borderRadius.lg,
        position: "sticky",
        top: "100px",
      }}
    >
      <MDBox
        component="ul"
        display="flex"
        flexDirection="column"
        p={2}
        m={0}
        sx={{ listStyle: "none" }}
      >
        {renderSidenavItems}
      </MDBox>
    </Card>
  );
}

export default Sidenav;
