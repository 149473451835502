import { useState, useEffect } from "react";
import { FormControl, InputLabel, Icon, Grid } from "@mui/material";
import Select from "react-select";
import CustomErrorMessage from "components/CustomErrorMessage";
import MDIconButton from "components/MDIconButton";
import { useNavigate } from "react-router-dom";
import NoBlogImage from "assets/images/no-blog-image.png";
import apiClient from "config/apiClient";
import moment from "moment";

function Blogsdata(props: any): JSX.Element | null {
  const {
    name,
    value,
    onChange,
    multiple,
    fieldLabel,
    apiName,
    responseKey,
    required,
    error,
    slsAPI,
    usersData,
    disabled,
    defaultOptions,
    createNewLink,
    placeholder,
    additionaFields,
    responseCallback,
    roleName,
    platformAPI,
  } = props;
  const [searchText, setSearchText] = useState<any>("");
  const [page, setPage] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const [listData, setListData] = useState<any>([]);
  const navigate = useNavigate();

  useEffect(() => {
    if (value) {
      const tempList = listData && listData.length > 0 ? JSON.parse(JSON.stringify(listData)) : [];
      if (multiple) {
        for (let v = 0; v < value.length; v++) {
          const existing = tempList.find((obj: any) => obj.value.id === value[v].value.id);
          if (existing) {
            tempList.push(existing);
          } else {
            tempList.push({
              value: {
                id: value[v].value.id,
                title: value[v].value.title || "",
                image_url: value[v].value.image_url || "",
                created_at: value[v].value.created_at || "",
              },
              label: `${value[v].value.title || ""}`,
            });
          }
        }
      } else {
        const checkExists = tempList.filter((v: any) => v.value.id === value.value.id);
        if (!checkExists.length) {
          tempList.push({
            value: {
              id: value.value.id,
              title: value.value.title || "",
              image_url: value.value.image_url || "",
              created_at: value.value.created_at || "",
            },
            label: `${value.value.title || ""}`,
          });
        }
      }
      setListData(tempList);
    }
  }, [value]);

  const getSelectedItems = (value: any) => {
    if (listData && value && (value.length || Object.keys(value).length)) {
      if (multiple) {
        const values: any = [];
        for (let v = 0; v < value.length; v++) {
          const element = listData.find((obj: any) => obj.value.id === value[v].id);
          if (element) {
            values.push(element);
          } else {
            values.push({
              value: {
                id: value[v].id,
                title: value[v].title || "",
                image_url: value[v].image_url || "",
                created_at: value[v].value.created_at || "",
              },
              label: `${value[v].title || ""}`,
            });
          }
        }
        return values;
      }
      const element = listData.find((obj: any) => obj.value.id === value.value.id);

      return element;
    }
    return null;
  };

  const getData = async (page?: any, searchText?: any) => {
    setPage(page);
    setIsLoading(true);
    let url = `${apiName}?page=${page || (slsAPI || platformAPI ? 0 : 1)}&per_page=20`;
    if (searchText) {
      url += `&search=${searchText}`;
    }
    // If any additional Fields come then it'll execute
    if (additionaFields && Object.entries(additionaFields)?.length) {
      Object.entries(additionaFields).map((field: any) => {
        if (field?.[0] && field?.[1]) {
          url += `&${field?.[0]}=${field?.[1]}`;
        }
        return true;
      });
    }
    if (apiName === "connect_users") {
      // const user = getAuthUser();
      if (roleName) {
        url += `&role_name=${roleName}`;
      } else {
        url += `&role_name=Admin`;
      }
    }

    const response = await apiClient().get(url, {
      params: usersData ? usersData : null,
    });
    if (response) {
      const respList = response.data[responseKey].map((val: any) => ({
        value: {
          id: val.id,
          title: val.title || "",
          image_url: val.image_url || "",
          created_at: val.created_at || "",
        },
        label: `${val.title || ""}`,
      }));
      // For API response give blank array
      if (responseCallback) {
        responseCallback(respList);
      }
      let data: any = [];
      const defaultOpts = defaultOptions || [];
      data = [...defaultOpts, ...listData, ...respList];
      if (searchText) {
        data = [...respList];
      }
      const arrayUniqueByKey = [
        ...new Map(
          data.map((item: any) => [item.value, { value: item.value, label: item.label }])
        ).values(),
      ];
      setIsLoading(false);
      setListData(arrayUniqueByKey);
    }
  };

  const handleInputChange = (search: string) => {
    if (!search) {
      setListData([]);
    }
    if (search !== searchText) {
      setSearchText(search);
      getData(slsAPI || platformAPI ? 0 : 1, search);
    }
  };

  const customFilter = () => true;

  const handleReactSelectScroll = () => {
    getData(page + 1, searchText || "");
  };

  const style = {
    control: (base: any) => ({
      ...base,
      height: "auto",
      border: "none",
      boxShadow: "0 !important",
      borderBottom: error ? "1px solid red" : "1px solid #d2d6da",
      width: "100%",
      borderRadius: "0px !important",
      textAlign: "left",
      fontSize: 14,
    }),
    menu: (provided: any) => ({
      ...provided,
      zIndex: 99,
      fontSize: 14,
    }),
    valueContainer: (valueContainer: any) => ({
      ...valueContainer,
      padding: "0 0 0 8px",
    }),
  };

  const redirectToCreateNew = () => {
    navigate(`${createNewLink}`);
  };

  const getFormatedLabel = (val: any) => (
    <div>
      <img
        style={{ height: "25px", width: "25px", borderRadius: "100%" }}
        src={val?.value?.image_url ? val?.value?.image_url : NoBlogImage}
        alt={val?.label}
      />
      <span
        style={{ position: "absolute", marginTop: "-1px", textAlign: "center", padding: "5px" }}
      >
        {`${val?.label} - ${moment(val?.value?.created_at).format("YYYY-MM-DD")}`}
      </span>
    </div>
  );

  return (
    <Grid container>
      <Grid item style={{ width: createNewLink ? "calc(100% - 36px)" : "100%" }}>
        <FormControl required={required} fullWidth sx={{ marginTop: "7px" }}>
          <InputLabel htmlFor="name" shrink sx={{ marginLeft: "-12px", marginTop: "-10px" }}>
            {fieldLabel || ""}
          </InputLabel>
          <Select
            formatOptionLabel={(val: any) => getFormatedLabel(val)}
            menuPlacement="auto"
            placeholder={placeholder || "Select..."}
            isLoading={isLoading}
            isClearable={!multiple}
            filterOption={customFilter}
            onMenuOpen={() => getData(slsAPI || platformAPI ? 0 : 1, "")}
            onInputChange={handleInputChange}
            options={listData.filter((val: any) => val.label)}
            onMenuScrollToBottom={handleReactSelectScroll}
            isMulti={multiple || false}
            isDisabled={disabled || false}
            onChange={(value: any) => {
              onChange(name, value);
            }}
            value={
              value && (value.length || Object.keys(value).length) ? getSelectedItems(value) : []
            }
            styles={style}
            blurInputOnSelect={false}
          />
          {error && <CustomErrorMessage message={error} />}
        </FormControl>
      </Grid>
      {createNewLink && (
        <Grid item>
          <MDIconButton
            tooltipName="Add new"
            aria-label="Add new"
            onClick={redirectToCreateNew}
            marginTop="9px"
            className="add-new-btn"
          >
            <Icon fontSize="small" color="info">
              add
            </Icon>
          </MDIconButton>
        </Grid>
      )}
    </Grid>
  );
}
export default Blogsdata;
