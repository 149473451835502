// import ImageList from "@mui/material/ImageList";
import { SortableContainer } from "react-sortable-hoc";
import ImageListItem from "@mui/material/ImageListItem";
import { v4 as uuidv4 } from "uuid";
import CustomDropZone from "components/CustomDropZone";
import SortableItem from "./SortableItem";

function SortableList(props: any) {
  const {
    items,
    previewImage,
    showAddMoreButton,
    onChangeImage,
    onImageRemove,
    onOpenEditModal,
    showEditBtn,
    disabled,
    showDeleteBtn,
    disableDrag,
  } = props;

  return (
    <div className="image-grid">
      {items.map((value: any, index: any) => (
        <SortableItem
          previewImage={previewImage}
          onImageRemove={onImageRemove}
          onOpenEditModal={onOpenEditModal}
          showEditBtn={showEditBtn}
          showDeleteBtn={showDeleteBtn}
          key={value.id}
          index1={value.index}
          index={index}
          value={value}
          disabled={value?.url.startsWith("data:image/") || disableDrag}
        />
      ))}
      <ImageListItem key={uuidv4()} className="image-item custom-file-size">
        {showAddMoreButton && showAddMoreButton() ? (
          <CustomDropZone
            minWidth="170px"
            minHeight="150px"
            multipleUpload={false}
            style={{ display: "none" }}
            maxSize={1048576 * 2}
            acceptedFiles={{
              "image/*": [".gif", ".png", ".jpg", ".jpeg"],
            }}
            onDrop={(e: any) => onChangeImage(e, showAddMoreButton())}
            disabled={disabled}
          >
            {!disabled ? "+Add Image" : "No Access"}
            <div style={{ marginTop: "12px", fontSize: "13px", lineHeight: "normal" }}>
              Maximum File size : 2 MB
            </div>
          </CustomDropZone>
        ) : (
          ""
        )}
      </ImageListItem>
    </div>
  );
}

export default SortableContainer(SortableList);
