import MDBox from "components/MDBox";
import { Grid } from "@mui/material";
import MDButton from "components/MDButton";
import Sidenav from "./sidenav";
import LeaseOverview from "./leaseOverview";
import NextPayment from "./nextPayment";
import PastCharges from "./pastCharges";
import BillingContact from "./billingContact";
import BillingAddress from "./billingAddress";
import LeaseAgreements from "./leaseAgreements";

function Index(props: any): JSX.Element {
  const { onUpdateBillingTab } = props;
  return (
    <Grid container spacing={3} mt={1}>
      <Grid item xs={12} lg={3}>
        <Sidenav />
      </Grid>
      <Grid item xs={12} lg={9}>
        <MDBox mb={3}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <NextPayment />
            </Grid>
            <Grid item xs={12}>
              <LeaseOverview {...props} />
            </Grid>
            <Grid item xs={12}>
              <PastCharges />
            </Grid>
            <Grid item xs={12}>
              <BillingContact {...props} />
            </Grid>
            <Grid item xs={12}>
              <BillingAddress {...props} />
            </Grid>
            <Grid item xs={12}>
              <LeaseAgreements {...props} />
            </Grid>
            <Grid item xs={12} display="flex" justifyContent="right" alignItems="flex-start">
              <MDButton
                onClick={onUpdateBillingTab}
                variant="gradient"
                color="info"
                size="small"
                className="fixed-button"
              >
                Update
              </MDButton>
            </Grid>
          </Grid>
        </MDBox>
      </Grid>
    </Grid>
  );
}

export default Index;
