import MDBox from "components/MDBox";
import BasicInfo from "layouts/pages/sola-pros/account-setup-wizard/steps/general/basicInfo";
import ContacInfo from "layouts/pages/sola-pros/account-setup-wizard/steps/general/contacInfo";
import Services from "layouts/pages/sola-pros/account-setup-wizard/steps/general/services";

function Index(props: any): JSX.Element {
  return (
    <MDBox mt={2}>
      <BasicInfo {...props} />
      <ContacInfo {...props} />
      <Services {...props} />
    </MDBox>
  );
}

export default Index;
