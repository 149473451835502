import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import CustomAutoSearch from "components/CustomAutoSearch";
import { adminSuperAdmin } from "helper/services";
import MESSAGES from "helper/messages";

function Address(props: any): JSX.Element {
  const { locationDetail, onChangeField, onChangeAutoComplete, errors } = props;
  return (
    <Card id="address">
      <MDBox p={3}>
        <MDTypography variant="h5">Address</MDTypography>
      </MDBox>
      <MDBox pb={3} px={3}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6} md={6} lg={6}>
            <MDInput
              label="Address 1"
              variant="standard"
              fullWidth
              name="address_1"
              placeholder="Address 1"
              onChange={onChangeField}
              value={locationDetail?.address_1 || ""}
              error={errors && errors.address_1 ? true : false}
              helperText={errors && errors.address_1}
              disabled={!adminSuperAdmin()}
              infoText={MESSAGES.INFO.OPTIONAL_LENGTH_255}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={6} lg={6}>
            <MDInput
              label="Address 2"
              variant="standard"
              fullWidth
              name="address_2"
              placeholder="Address 2"
              onChange={onChangeField}
              value={locationDetail?.address_2 || ""}
              error={errors && errors.address_2 ? true : false}
              helperText={errors && errors.address_2}
              disabled={!adminSuperAdmin()}
              infoText={MESSAGES.INFO.OPTIONAL_LENGTH_255}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={6} lg={6}>
            <MDInput
              label="City"
              variant="standard"
              fullWidth
              name="city"
              placeholder="City"
              onChange={onChangeField}
              value={locationDetail?.city || ""}
              error={errors && errors.city ? true : false}
              helperText={errors && errors.city}
              disabled={!adminSuperAdmin()}
              infoText={MESSAGES.INFO.OPTIONAL_LENGTH_255}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={6} lg={6}>
            <MDInput
              label="State/Province"
              variant="standard"
              fullWidth
              name="state"
              placeholder="State/Province"
              onChange={onChangeField}
              value={locationDetail?.state || ""}
              error={errors && errors.state ? true : false}
              helperText={errors && errors.state}
              disabled={!adminSuperAdmin()}
              infoText={MESSAGES.INFO.OPTIONAL_LENGTH_255}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={6} lg={6}>
            <MDInput
              label="Postal Code"
              variant="standard"
              fullWidth
              name="postal_code"
              placeholder="Postal Code"
              onChange={onChangeField}
              value={locationDetail?.postal_code || ""}
              error={errors && errors.postal_code ? true : false}
              helperText={errors && errors.postal_code}
              disabled={!adminSuperAdmin()}
              infoText={MESSAGES.INFO.OPTIONAL_LENGTH_255}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={6} lg={6} className="countries">
            <CustomAutoSearch
              idKey="id"
              nameKey="name"
              fieldLabel="Country"
              apiName="countries"
              responseKey="countries"
              name="countries"
              value={locationDetail?.countries || null}
              onChange={onChangeAutoComplete}
              error={errors && errors.countries}
              // required
              disabled={!adminSuperAdmin()}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={6} lg={6}>
            <MDInput
              label="Latitude"
              variant="standard"
              fullWidth
              name="latitude"
              placeholder="Latitude"
              onChange={onChangeField}
              value={locationDetail?.latitude || ""}
              disabled={!adminSuperAdmin()}
              infoText={MESSAGES.INFO.LATITUDE}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={6} lg={6}>
            <MDInput
              label="Longitude"
              variant="standard"
              fullWidth
              name="longitude"
              placeholder="Longitude"
              onChange={onChangeField}
              value={locationDetail?.longitude || ""}
              disabled={!adminSuperAdmin()}
              infoText={MESSAGES.INFO.LONGITUDE}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={6} lg={6}>
            <MDInput
              label="Custom Maps URL"
              variant="standard"
              fullWidth
              name="custom_maps_url"
              placeholder="Custom Maps URL"
              onChange={onChangeField}
              value={locationDetail?.custom_maps_url || ""}
              disabled={!adminSuperAdmin()}
              infoText={MESSAGES.INFO.CUSTOM_MAP_URL}
            />
          </Grid>
        </Grid>
      </MDBox>
    </Card>
  );
}

export default Address;
