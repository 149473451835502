import { useEffect, useRef, useState, useCallback } from "react";
import { Card, Icon } from "@mui/material";
import MDBox from "components/MDBox";
import DataTable from "examples/Tables/DataTable";
import MDIconButton from "components/MDIconButton";
import { useNavigate } from "react-router-dom";
import { useAppSelector, useAppDispatch } from "store/store";
import { getRMPhoneNumberList, deleteRMPhoneNumber } from "store/thunk/repairMaintenance";
import {
  getLocalDateTime,
  userRoles,
  formatPhoneNumber,
  capitalizeFirstLetter,
} from "helper/services";
import MDDialog from "components/MDDialog";
import MDTypography from "components/MDTypography";

function Index(): JSX.Element {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { phoneNumbersList } = useAppSelector((state) => state.repairMaintenanceSlice);
  const { userData } = useAppSelector((state) => state.authReducer);
  const [numbersData, setNumbersList] = useState([]);
  const [deleteData, setDeleteData] = useState<any>(false);
  const [pageCount, setPageCount] = useState(0);
  const [pageNo, setPageNo] = useState(0);
  const [totalCount, setTotalCount] = useState(0);
  const fetchIdRef = useRef(0);
  const [phoneListingRequestData, setPhoneRequestListingData] = useState(null);

  useEffect(() => {
    if (phoneNumbersList) {
      setPageCount(phoneNumbersList?.meta?.total_pages);
      setTotalCount(phoneNumbersList?.meta?.total_count);
      setNumbersList(phoneNumbersList?.connect_rm_twillio_phone_numbers?.map((obj: any) => obj));
    }
  }, [phoneNumbersList]);

  const navigateToEdit = (data: any) => {
    navigate(`/r&m/phone-numbers/${data?.twillio_phone_number}`, {
      state: { phoneId: data?.id },
    });
  };

  const actionButtons = (phoneData: any) => (
    <>
      <MDIconButton
        tooltipName="Edit Phone Numner"
        aria-label="Open"
        color="info"
        onClick={() => navigateToEdit(phoneData)}
      >
        <Icon fontSize="small">edit</Icon>
      </MDIconButton>
      <MDIconButton
        tooltipName="Delete Phone Numnber"
        aria-label="Open"
        color="error"
        onClick={() => setDeleteData(phoneData)}
      >
        <Icon fontSize="small">delete</Icon>
      </MDIconButton>
    </>
  );

  const getRows = () => {
    if (numbersData) {
      const data: any = numbersData?.map((obj: any) => ({
        id: obj?.id || "-",
        twillio_phone_number: formatPhoneNumber(obj?.twillio_phone_number) || "-",
        number_name: obj?.number_name || "-",
        number_description: obj?.number_description || "-",
        status: obj?.status ? capitalizeFirstLetter(obj?.status) : "-",
        created_at: obj.created_at ? getLocalDateTime(obj.created_at) : "-",
        action: actionButtons(obj),
      }));
      return data;
    }
    return [];
  };

  const fetchData = useCallback(({ pageSize, pageIndex, search, sortBy }) => {
    const fetchId = ++fetchIdRef.current;
    const sort: any = sortBy || [];
    // We'll even set a delay to simulate a server here
    setTimeout(() => {
      if (fetchId === fetchIdRef.current) {
        setPageNo(pageIndex);
        const obj = {
          pageSize,
          pageIndex,
          search,
          sort,
        };
        setPhoneRequestListingData(obj);
        dispatch(getRMPhoneNumberList({ pageSize, pageIndex, search, sort }));
      }
    }, 1000);
  }, []);

  const getColumns = () => {
    const columns: any = [
      { Header: "ID", accessor: "id" },
      { Header: "Phone Numner", accessor: "twillio_phone_number" },
      { Header: "Name", accessor: "number_name" },
      { Header: "Description", accessor: "number_description" },
      { Header: "Status", accessor: "status" },
      { Header: "Created At", accessor: "created_at" },
    ];
    if (userData?.role === userRoles.SYSTEM_ADMIN) {
      columns.push({ Header: "Action", accessor: "action" });
    }
    return columns;
  };

  const reviewTableData = {
    columns: getColumns(),
    rows: getRows(),
  };

  const onDeletePhoneNumber = () => {
    if (deleteData) {
      const req = {
        phoneListingRequestData,
        setDeleteData,
        deleteReq: {
          id: deleteData?.id,
          delete_by_user_id: userData?.user_id,
        },
      };
      console.log("req", req);
      dispatch(deleteRMPhoneNumber(req));
    }
  };

  const deleteDialogProps = {
    open: deleteData ? true : false,
    onClose: () => setDeleteData(false),
    onSave: () => onDeletePhoneNumber(),
    title: "Delete Twillio Phone Number",
    size: "sm",
    saveBtn: false,
    deleteBtn: true,
    saveTbtText: "Yes",
    closeBtnText: "No",
  };

  return (
    <MDBox>
      <Card>
        <DataTable
          table={reviewTableData}
          pageCount={pageCount}
          pageNo={pageNo}
          totalCount={totalCount}
          fetchData={fetchData}
          canSearch
          manualSearch
          searchText="Search...."
        />
      </Card>
      {deleteData && (
        <MDDialog {...deleteDialogProps}>
          <MDTypography fontSize={16}>
            Are you sure you want to delete this number ? This will completely remove this number.
          </MDTypography>
          <MDTypography fontSize={16}>
            Removing this number will disable all SMS features for all connected Locations,
            including any automated communications.
          </MDTypography>
        </MDDialog>
      )}
    </MDBox>
  );
}

export default Index;
