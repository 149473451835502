import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import CustomPhoneInput from "components/CustomPhoneInput";
import MESSAGES from "helper/messages";

function RentManager(props: any): JSX.Element {
  const { solaProDetail, onChangeField, errors } = props;
  return (
    <Card id="rent-manager">
      <MDBox p={3}>
        <MDTypography variant="h5">Rent Manager</MDTypography>
      </MDBox>
      <MDBox pb={3} px={3}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6} md={6} lg={6}>
            <MDInput
              label="Billing First name"
              variant="standard"
              fullWidth
              name="billing_first_name"
              placeholder="Billing First name"
              onChange={onChangeField}
              value={solaProDetail?.billing_first_name || ""}
              error={errors && errors.billing_first_name ? true : false}
              helperText={errors && errors.billing_first_name}
              infoText={MESSAGES.INFO.OPTIONAL}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={6} lg={6}>
            <MDInput
              label="Billing last name"
              variant="standard"
              fullWidth
              name="billing_last_name"
              placeholder="Billing last name"
              onChange={onChangeField}
              value={solaProDetail?.billing_last_name || ""}
              error={errors && errors.billing_last_name ? true : false}
              helperText={errors && errors.billing_last_name}
              infoText={MESSAGES.INFO.OPTIONAL}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={6} lg={6}>
            <MDInput
              label="Billing Email"
              variant="standard"
              fullWidth
              name="billing_email"
              placeholder="Billing Email"
              onChange={onChangeField}
              value={solaProDetail?.billing_email || ""}
              error={errors && errors.billing_email ? true : false}
              helperText={errors && errors.billing_email}
              infoText={MESSAGES.INFO.OPTIONAL}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={6} lg={6}>
            <MDInput
              label="Billing Phone"
              variant="standard"
              fullWidth
              name="billing_phone"
              placeholder="(000) 000-0000"
              InputProps={{
                inputComponent: CustomPhoneInput as any,
              }}
              onChange={onChangeField}
              value={solaProDetail?.billing_phone || ""}
              error={errors && errors.billing_phone ? true : false}
              helperText={errors && errors.billing_phone}
              infoText={MESSAGES.INFO.OPTIONAL}
            />
          </Grid>
        </Grid>
      </MDBox>
    </Card>
  );
}

export default RentManager;
