import MDDialog from "components/MDDialog";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
// import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Grid, AccordionSummary, Accordion, AccordionDetails, Icon } from "@mui/material";
import { phoneNumberformat, getLocalDateTime } from "helper/services";

function ViewDetails(props: any): JSX.Element {
  const { open, onClose, viewData } = props;
  const dialogProps = {
    open,
    onClose,
    title: "View Franchisee Inquiries",
    size: "md",
    saveBtn: false,
  };

  const getIcon = (value: any) => {
    if (value) {
      return (
        <Icon fontSize="medium" color="success">
          check_circle_rounded
        </Icon>
      );
    }
    return (
      <Icon fontSize="medium" color="error">
        cancel
      </Icon>
    );
  };

  const getIconElement = (name: any, iconValue: any) => {
    const value: any = iconValue;
    return (
      <Grid item xs={12} sm={6} md={6} mt={1}>
        <MDTypography fontWeight="bold" variant="button">
          {name}
        </MDTypography>
        <MDBox>{getIcon(value)}</MDBox>
      </Grid>
    );
  };

  const getTypograpghy = (label: any, value: any) => (
    <Grid item xs={12} sm={12} md={12} lg={6} mt={1}>
      <MDTypography fontWeight="bold" variant="button">
        {label}
      </MDTypography>
      <MDBox>
        <MDTypography variant="button">{value || "-"}</MDTypography>
      </MDBox>
    </Grid>
  );

  const getAccordianSummary = (label: any) => (
    <AccordionSummary
      className="location-accordion"
      // expandIcon={<ExpandMoreIcon />}
      aria-controls="publishbh-content"
    >
      <MDTypography variant="h6">{label}</MDTypography>
    </AccordionSummary>
  );

  return (
    <MDDialog {...dialogProps}>
      <MDBox mt={2}>
        <Grid container>
          <Grid item xs={12} sm={12} md={12}>
            <Accordion expanded>
              {getAccordianSummary("Basic Info")}
              <AccordionDetails>
                <Grid container spacing={2}>
                  {getTypograpghy("First Name", viewData?.first_name || "-")}
                  {getTypograpghy("Last Name", viewData?.last_name || "-")}
                </Grid>
                <Grid container spacing={2}>
                  {getTypograpghy("Email Address", viewData?.email_address || "-")}
                  {getTypograpghy(
                    "Phone Number",
                    viewData?.phone_number ? phoneNumberformat(viewData?.phone_number) : "-"
                  )}
                </Grid>
                <Grid container spacing={2}>
                  {getIconElement("Multi Unit Operator", viewData?.multi_unit_operator || "-")}
                  {getTypograpghy("Liquid Capital", viewData?.liquid_capital || "-")}
                </Grid>
                <Grid container spacing={2}>
                  {getTypograpghy("City", viewData?.city || "-")}
                  {getTypograpghy("State", viewData?.state || "-")}
                </Grid>
                <Grid container spacing={2}>
                  {getIconElement(
                    "Agree To Receive Email",
                    viewData?.agree_to_receive_email || "-"
                  )}
                  {getTypograpghy("Country", viewData?.country || "-")}
                </Grid>
                <Grid container spacing={2}>
                  {getTypograpghy("UTM Source", viewData?.utm_source || "-")}
                  {getTypograpghy("UTM Campaign", viewData?.utm_campaign || "-")}
                </Grid>
                <Grid container spacing={2}>
                  {getTypograpghy("UTM Medium", viewData?.utm_medium || "-")}
                  {getTypograpghy("UTM Content", viewData?.utm_content || "-")}
                </Grid>
                <Grid container spacing={2}>
                  {getTypograpghy("UTM Term", viewData?.utm_term || "-")}
                  {getTypograpghy("Created At", getLocalDateTime(viewData?.created_at) || "-")}
                </Grid>
                <Grid container spacing={2}>
                  {getTypograpghy("Updated At", getLocalDateTime(viewData?.updated_at) || "-")}
                </Grid>
              </AccordionDetails>
            </Accordion>
          </Grid>
        </Grid>
      </MDBox>
    </MDDialog>
  );
}

export default ViewDetails;
