import axios from "axios";
import Config from "config/config";

const baseURL = Config.BACKEND_API_SOLA_WEB;

const platformApiClient = (options?: any) => {
  const headers = {};
  if (options && options?.formData) {
    Object.assign(headers, { "content-type": "multipart/form-data" });
  }
  return axios.create({
    baseURL,
    withCredentials: false,
    headers,
  });
};

export default platformApiClient;
