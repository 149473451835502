import { createAsyncThunk } from "@reduxjs/toolkit";
import { notificationSuccess, notificationFail } from "store/slices/notificationSlice";
import { setLoading } from "store/slices/authSlice";
import {
  articlesList,
  webBlogsList,
  categoriesList,
  category,
  tagList,
  setTag,
  getFeaturedBlogsList,
} from "store/slices/solaWebSiteSlice";
import axios from "axios";
import config from "config/config";
import Messages from "helper/messages";
import { getErrorMessage, getValue, getAssociationLocations, getFilterURL } from "helper/services";
import apiClient from "config/apiClient";
import slsApiClient from "config/slsApiClient";

/**
 * Get list of all websites Blogs
 */
export const getWebSiteBlogs = createAsyncThunk(
  "getWebSiteBlogs",
  async (_request: any, { dispatch }) => {
    dispatch(setLoading(true));
    const page = _request.pageIndex || 0;
    const size = _request.pageSize || 50;
    const search = _request.search || "";
    const sortBy = _request.sort || [];
    const filterBy = _request?.filterData || [];
    try {
      let url = `blogs?page=${page + 1}&per_page=${size}`;
      if (search) {
        url += `&search=${search}`;
      }
      if (sortBy?.length) {
        url += `&field=${sortBy[0]?.id}&order_by=${sortBy[0]?.desc ? "desc" : "asc"}`;
      }
      // Apply advance filter
      if (filterBy && filterBy.length) {
        url = getFilterURL(url, filterBy);
      }
      const response = await apiClient().get(url);
      if (response) {
        dispatch(setLoading(false));
        dispatch(webBlogsList(response?.data || []));
      }
    } catch (error: any) {
      dispatch(webBlogsList([]));
      dispatch(setLoading(false));
      dispatch(notificationFail(error.message || Messages.ERROR.SOMETHING_WENT_WRONG));
    }
  }
);

export const getFeaturedBlogs = createAsyncThunk(
  "getFeaturedBlogs",
  async (_request: any, { dispatch }) => {
    dispatch(setLoading(true));
    const page = _request.pageIndex || 0;
    const size = _request.pageSize || 50;
    const search = _request.search || "";
    const sortBy = _request.sort || [];
    try {
      let url = `get-featured-blogs?page=${page + 1}&per_page=${size}`;
      if (search) {
        url += `&search=${search}`;
      }
      if (sortBy?.length) {
        url += `&field=${sortBy[0]?.id}&order_by=${sortBy[0]?.desc ? "desc" : "asc"}`;
      }
      const response = await slsApiClient().get(url);
      if (response) {
        dispatch(setLoading(false));
        dispatch(getFeaturedBlogsList(response?.data || []));
      }
    } catch (error: any) {
      dispatch(webBlogsList([]));
      dispatch(setLoading(false));
      dispatch(notificationFail(error.message || Messages.ERROR.SOMETHING_WENT_WRONG));
    }
  }
);

// update location user
export const saveFeaturedBlogs = createAsyncThunk(
  "saveFeaturedBlogs",
  async (_request: any, { dispatch }) => {
    dispatch(setLoading(true));
    try {
      const response = await slsApiClient().post("save-featured-blogs", {
        featured_blog_ids: _request?.blogData ? _request?.blogData : [],
      });
      if (response?.data) {
        _request?.onClose();
        dispatch(getFeaturedBlogs({ pageIndex: 0, pageSize: 50 }));
        dispatch(
          notificationSuccess(response?.data?.message || Messages.SUCCESS.USER_UPDATE_SUCCESS)
        );
      } else {
        _request?.onClose();
        dispatch(notificationFail(Messages.ERROR.SOMETHING_WENT_WRONG));
      }
    } catch (error: any) {
      dispatch(setLoading(false));
      dispatch(
        notificationFail(error?.response?.data?.message || Messages.ERROR.SOMETHING_WENT_WRONG)
      );
    }
  }
);

export const createBlog = createAsyncThunk("createBlog", async (_request: any, { dispatch }) => {
  dispatch(setLoading(true));
  const { blogData, isUpdate, onClose, pageNo, perPage, search, sortBy } = _request;
  const formData = new FormData();

  if (isUpdate && blogData.id) {
    formData.append("blog[id]", blogData.id);
  }
  formData.append("blog[title]", getValue(blogData?.title));
  formData.append("blog[url_name]", getValue(blogData?.url_name));
  formData.append("blog[canonical_url]", getValue(blogData?.canonical_url));
  if (blogData?.delete_image && blogData?.delete_image === 1) {
    formData.append("blog[delete_image]", getValue(blogData?.delete_image));
  } else {
    formData.append("blog[delete_image]", "");
  }
  if (blogData?.delete_carousel_image && blogData?.delete_carousel_image === 1) {
    formData.append("blog[delete_carousel_image]", getValue(blogData?.delete_carousel_image));
  } else {
    formData.append("blog[delete_carousel_image]", "");
  }
  formData.append("blog[meta_description]", getValue(blogData?.meta_description));
  formData.append("blog[summary]", getValue(blogData?.summary));
  formData.append("blog[body]", getValue(blogData?.body));
  formData.append("blog[author]", getValue(blogData?.author));
  if (blogData?.contact_form_visible) {
    if (blogData.contact_form_visible === "yes") {
      formData.append("blog[contact_form_visible]", "true");
    }
    if (blogData.contact_form_visible === "no") {
      formData.append("blog[contact_form_visible]", "false");
    }
  } else {
    formData.append("blog[contact_form_visible]", "false");
  }
  if (blogData?.categories?.length) {
    blogData.categories.forEach((val: any) => {
      formData.append("blog[category_ids][]", val.id);
    });
  } else {
    formData.append("blog[category_ids][]", "");
  }
  // SD-3424
  if (blogData?.location_ids?.length) {
    blogData.location_ids.forEach((val: any) => {
      formData.append("blog[location_ids][]", val.id);
    });
  } else {
    formData.append("blog[location_ids][]", "");
  }
  if (blogData?.tags?.length) {
    blogData.tags.forEach((val: any) => {
      formData.append("blog[tag_ids][]", val.id);
    });
  } else {
    formData.append("blog[tag_ids][]", "");
  }
  formData.append("blog[status]", getValue(blogData?.status));
  formData.append("blog[publish_date]", getValue(blogData?.publish_date));
  formData.append("blog[carousel_text]", getValue(blogData?.carousel_text));
  formData.append("blog[fb_conversion_pixel]", getValue(blogData?.fb_conversion_pixel));
  if (blogData?.countries?.length) {
    blogData.countries.forEach((val: any) => {
      formData.append("blog[country_ids][]", val.id);
    });
  } else {
    formData.append("blog[country_ids][]", "");
  }
  formData.append("blog[image]", blogData?.image_url || "");
  formData.append("blog[carousel_image]", blogData?.carousel_image_url || "");
  try {
    const response = isUpdate
      ? await apiClient(true).put(`blogs/${blogData.id}`, formData)
      : await apiClient(true).post("blogs", formData);

    if (response) {
      onClose();
      if (isUpdate) {
        dispatch(notificationSuccess(Messages.SUCCESS.BLOG_UPDATE_SUCCESS));
        dispatch(getWebSiteBlogs({ pageIndex: pageNo, pageSize: perPage, search, sort: sortBy }));
      } else {
        dispatch(notificationSuccess(Messages.SUCCESS.BLOG_CREATE_SUCCESS));
        dispatch(getWebSiteBlogs({ pageIndex: pageNo, pageSize: perPage }));
      }
    }
  } catch (error: any) {
    dispatch(setLoading(false));
    if (error?.response?.data?.error) {
      const message = getErrorMessage(error?.response?.data?.error);
      dispatch(notificationFail(message));
    } else {
      dispatch(
        notificationFail(error?.response?.data?.message || Messages.ERROR.BLOG_CREATE_ERROR)
      );
    }
  }
});

// export const updateBlog = createAsyncThunk("updateBlog", (_request: any, { dispatch }) => {
//   dispatch(setLoading(true));
//   const req = {
//     blog: _request,
//   };
//   axios
//     .put(`${config.BACKEND_API_URL}blogs/${_request.id}`, req, {
//       headers: {
//         "Access-Control-Allow-Origin": "*",
//         "api-key": config.BACKEND_API_KEY,
//       },
//     })
//     .then((response) => {
//       dispatch(setLoading(false));
//       if (response?.data?.status) {
//         dispatch(notificationSuccess(Messages.SUCCESS.BLOG_UPDATE_SUCCESS));
//         dispatch(getWebSiteBlogs({}));
//       } else {
//         dispatch(notificationFail(response?.data?.message || Messages.ERROR.BLOG_UPDATE_ERROR));
//       }
//     })
//     .catch((error) => {
//       dispatch(setLoading(false));
//       dispatch(
//         notificationFail(error?.response?.data?.message || Messages.ERROR.BLOG_UPDATE_ERROR)
//       );
//     });
// });

export const deleteBlog = createAsyncThunk("deleteBlog", (_request: any, { dispatch }) => {
  dispatch(setLoading(true));
  const { id, pageNo, perPage, search, sortBy, removeSelectedItem = null } = _request;
  axios
    .delete(`${config.BACKEND_API_URL}blogs/${id}`, {
      headers: {
        "Access-Control-Allow-Origin": "*",
        "api-key": config.BACKEND_API_KEY,
      },
    })
    .then((response) => {
      if (response?.data?.message) {
        _request.setDeleteBlog(false);
        if (removeSelectedItem) {
          removeSelectedItem(id);
        }
        dispatch(notificationSuccess(response?.data?.message));
        dispatch(getWebSiteBlogs({ pageIndex: pageNo, pageSize: perPage, search, sort: sortBy }));
      } else {
        dispatch(setLoading(false));
        dispatch(notificationFail(response?.data?.message || Messages.ERROR.BLOG_DELETE_ERROR));
      }
    })
    .catch((error) => {
      dispatch(setLoading(false));
      dispatch(
        notificationFail(error?.response?.data?.message || Messages.ERROR.BLOG_DELETE_ERROR)
      );
    });
});

export const getArticles = createAsyncThunk("getArticles", async (_request: any, { dispatch }) => {
  dispatch(setLoading(true));
  const page = _request.pageIndex || 0;
  const size = _request.pageSize || 50;
  const search = _request.search || "";
  const sortBy = _request.sort || [];
  const filterBy = _request?.filterData || [];
  try {
    let url = `articles?page=${page + 1}&per_page=${size}`;
    if (search) {
      url += `&search=${search}`;
    }
    if (sortBy?.length) {
      url += `&field=${sortBy[0]?.id}&order_by=${sortBy[0]?.desc ? "desc" : "asc"}`;
    }
    // Apply advance filter
    if (filterBy && filterBy.length) {
      url = getFilterURL(url, filterBy);
    }
    const response = await apiClient().get(url, {
      params: getAssociationLocations(),
    });
    if (response) {
      dispatch(setLoading(false));
      dispatch(articlesList(response?.data || []));
    }
  } catch (error: any) {
    dispatch(articlesList([]));
    dispatch(setLoading(false));
    dispatch(notificationFail(error.message || Messages.ERROR.SOMETHING_WENT_WRONG));
  }
});

export const createArticle = createAsyncThunk("createArticle", (_request: any, { dispatch }) => {
  dispatch(setLoading(true));
  const { articalFormData, onClose, pageNo, perPage } = _request;
  const formData = new FormData();
  formData.append("article[title]", getValue(articalFormData?.title));
  formData.append("article[article_url]", getValue(articalFormData?.article_url));
  formData.append("article[image]", articalFormData?.image || "");
  formData.append("article[summary]", getValue(articalFormData?.summary));
  formData.append("article[location_id]", getValue(articalFormData?.location?.id));
  formData.append("article[display_setting]", getValue(articalFormData?.display_setting));
  formData.append("article[created_at]", getValue(articalFormData?.created_at));
  axios
    .post(`${config.BACKEND_API_URL}articles`, formData, {
      headers: {
        "Access-Control-Allow-Origin": "*",
        "api-key": config.BACKEND_API_KEY,
        "content-type": "multipart/form-data",
      },
    })
    .then((response) => {
      dispatch(setLoading(false));
      if (response?.status === 200) {
        onClose();
        dispatch(notificationSuccess(Messages.SUCCESS.ARTICLE_CREATE_SUCCESS));
        dispatch(getArticles({ pageIndex: pageNo, pageSize: perPage }));
      } else {
        dispatch(notificationFail(response?.data?.message || Messages.ERROR.ARTICLE_CREATE_ERROR));
      }
    })
    .catch((error) => {
      dispatch(setLoading(false));
      if (error?.response?.status === 400) {
        const message = getErrorMessage(error?.message);
        dispatch(notificationFail(message));
      } else {
        dispatch(notificationFail(error?.message || Messages.ERROR.ARTICLE_CREATE_ERROR));
      }
    });
});

export const updateArticle = createAsyncThunk("updateArticle", (_request: any, { dispatch }) => {
  dispatch(setLoading(true));
  const { articalFormData, onClose, pageNo, perPage, search, sortBy } = _request;
  const formData = new FormData();
  formData.append("article[title]", getValue(articalFormData?.title));
  formData.append("article[article_url]", getValue(articalFormData?.article_url));
  if (articalFormData?.delete_image && articalFormData?.delete_image === 1) {
    formData.append("article[delete_image]", getValue(articalFormData?.delete_image));
  } else {
    formData.append("article[delete_image]", "");
  }
  formData.append("article[image]", articalFormData?.image || "");
  formData.append("article[summary]", getValue(articalFormData?.summary));
  formData.append("article[location_id]", getValue(articalFormData?.location?.id));
  formData.append("article[display_setting]", getValue(articalFormData?.display_setting));
  formData.append("article[created_at]", getValue(articalFormData?.created_at));
  axios
    .put(`${config.BACKEND_API_URL}articles/${articalFormData.id}`, formData, {
      headers: {
        "Access-Control-Allow-Origin": "*",
        "api-key": config.BACKEND_API_KEY,
        "content-type": "multipart/form-data",
      },
    })
    .then((response) => {
      dispatch(setLoading(false));
      if (response?.status === 200) {
        onClose();
        dispatch(notificationSuccess(Messages.SUCCESS.ARTICLE_UPDATE_SUCCESS));
        dispatch(getArticles({ pageIndex: pageNo, pageSize: perPage, search, sort: sortBy }));
      } else {
        dispatch(notificationFail(response?.data?.message || Messages.ERROR.ARTICLE_UPDATE_ERROR));
      }
    })
    .catch((error) => {
      dispatch(setLoading(false));
      if (error?.response?.status === 400) {
        const message = getErrorMessage(error?.message);
        dispatch(notificationFail(message));
      } else {
        dispatch(notificationFail(error?.message || Messages.ERROR.ARTICLE_UPDATE_ERROR));
      }
    });
});

export const getArticle = createAsyncThunk("getArticle", (_request: any, { dispatch }) => {
  dispatch(setLoading(true));
  axios
    .get(`${config.BACKEND_API_URL}articles/${_request.id}`, {
      headers: {
        "Access-Control-Allow-Origin": "*",
        "api-key": config.BACKEND_API_KEY,
      },
    })
    .then((response) => {
      dispatch(setLoading(false));
      if (response?.status === 200) {
        _request.setArticleDetails(response.data);
      } else {
        dispatch(notificationFail(response?.data?.message || Messages.ERROR.ARTICLE_GET_ERROR));
      }
    })
    .catch((error) => {
      dispatch(setLoading(false));
      dispatch(notificationFail(error?.message || Messages.ERROR.ARTICLE_GET_ERROR));
    });
});

export const deleteArticle = createAsyncThunk("deleteArticle", (_request: any, { dispatch }) => {
  dispatch(setLoading(true));
  const { id, pageNo, perPage, search, sortBy, removeSelectedItem = null } = _request;
  axios
    .delete(`${config.BACKEND_API_URL}articles/${id}`, {
      headers: {
        "Access-Control-Allow-Origin": "*",
        "api-key": config.BACKEND_API_KEY,
      },
    })
    .then((response) => {
      dispatch(setLoading(false));
      if (removeSelectedItem) {
        removeSelectedItem(id);
      }
      if (response?.data?.message) {
        _request.setDeleteArticle(false);
        dispatch(notificationSuccess(response?.data?.message));
        dispatch(getArticles({ pageIndex: pageNo, pageSize: perPage, search, sort: sortBy }));
      } else {
        dispatch(notificationFail(response?.data?.message || Messages.ERROR.ARTICLE_DELETE_ERROR));
      }
    })
    .catch((error) => {
      dispatch(setLoading(false));
      dispatch(notificationFail(error?.message || Messages.ERROR.ARTICLE_DELETE_ERROR));
    });
});

// get all categories list
export const getCategoriesList = createAsyncThunk(
  "getCategoriesList",
  async (_request: any, { dispatch }) => {
    const page = _request.pageIndex || 0;
    const size = _request.pageSize || 50;
    const search = _request.search || "";
    const sortBy = _request.sort || [];
    const filterBy = _request?.filterData || [];
    dispatch(setLoading(true));
    try {
      let url = `categories?page=${page + 1}&per_page=${size}`;
      if (search) {
        url += `&search=${search}`;
      }
      if (_request.all) {
        url += `all=true`;
      }
      if (sortBy?.length) {
        url += `&field=${sortBy[0]?.id}&order_by=${sortBy[0]?.desc ? "desc" : "asc"}`;
      }
      // Apply advance filter
      if (filterBy && filterBy.length) {
        url = getFilterURL(url, filterBy);
      }
      const response = await apiClient().get(url);
      if (response) {
        dispatch(categoriesList(response?.data || []));
        dispatch(setLoading(false));
      }
    } catch (error: any) {
      dispatch(notificationFail(error.message || Messages.ERROR.NO_DATA_FOUND));
      dispatch(setLoading(false));
    }
  }
);

// get single category
export const getCategory = createAsyncThunk("getCategory", async (_request: any, { dispatch }) => {
  dispatch(setLoading(true));
  try {
    const response = await apiClient().get(`categories/${_request.id}`);
    dispatch(setLoading(false));
    if (response) {
      dispatch(category(response.data));
    } else {
      dispatch(category(null));
      dispatch(notificationFail(Messages.ERROR.NO_DATA_FOUND));
    }
  } catch (error: any) {
    dispatch(notificationFail(error.message || Messages.ERROR.NO_DATA_FOUND));
    dispatch(setLoading(false));
  }
});

// add update catrgory
export const addUpdateCatrgory = createAsyncThunk(
  "addUpdateCatrgory",
  async (_request: any, { dispatch }) => {
    dispatch(setLoading(true));
    const { categoryData, onClose, pageNo, perPage, search, sortBy } = _request;
    const formData = new FormData();
    formData.append("category[name]", getValue(categoryData.name));
    formData.append("category[slug]", getValue(categoryData.slug));
    if (categoryData.blog && categoryData.blog.length) {
      categoryData.blog.forEach((val: any) => {
        formData.append("category[blog_ids][]", val.id);
      });
    } else {
      formData.append("category[blog_ids][]", "");
    }
    if (categoryData.tool && categoryData.tool.length) {
      categoryData.tool.forEach((val: any) => {
        formData.append("category[tool_ids][]", val.id);
      });
    } else {
      formData.append("category[tool_ids][]", "");
    }
    if (categoryData.deal && categoryData.deal.length) {
      categoryData.deal.forEach((val: any) => {
        formData.append("category[deal_ids][]", val.id);
      });
    } else {
      formData.append("category[deal_ids][]", "");
    }
    if (categoryData.tag && categoryData.tag.length) {
      categoryData.tag.forEach((val: any) => {
        formData.append("category[tag_ids][]", val.id);
      });
    } else {
      formData.append("category[tag_ids][]", "");
    }
    if (categoryData.video && categoryData.video.length) {
      categoryData.video.forEach((val: any) => {
        formData.append("category[video_ids][]", val.id);
      });
    } else {
      formData.append("category[video_ids][]", "");
    }
    if (categoryData.franchise_article && categoryData.franchise_article.length) {
      categoryData.franchise_article.forEach((val: any) => {
        formData.append("category[franchise_article_ids][]", val.id);
      });
    } else {
      formData.append("category[franchise_article_ids][]", "");
    }

    try {
      const response = categoryData.id
        ? await apiClient(true).put(`categories/${categoryData.id}`, formData)
        : await apiClient(true).post("categories", formData);

      if (response) {
        onClose();
        dispatch(
          notificationSuccess(
            categoryData.id
              ? Messages.SUCCESS.CATEGORY_UPDATE_SUCCESS
              : Messages.SUCCESS.CATEGORY_CREATE_SUCCESS
          )
        );
        if (categoryData?.id) {
          dispatch(
            getCategoriesList({ pageIndex: pageNo, pageSize: perPage, search, sort: sortBy })
          );
        } else {
          dispatch(getCategoriesList({ pageIndex: pageNo, pageSize: perPage }));
        }
      }
    } catch (error: any) {
      dispatch(setLoading(false));
      if (error?.response?.data?.error) {
        const message = getErrorMessage(error?.response?.data?.error);
        dispatch(notificationFail(message));
      } else {
        dispatch(
          notificationFail(error?.response?.data?.message || Messages.ERROR.SOMETHING_WENT_WRONG)
        );
      }
    }
  }
);

// delete category
export const deleteCategory = createAsyncThunk(
  "deleteCategory",
  async (_request: any, { dispatch }) => {
    const { pageNo, perPage, search, sortBy, removeSelectedItem = null } = _request;
    dispatch(setLoading(true));
    try {
      const response = await apiClient().delete(`categories/${_request.id}`);
      if (response) {
        if (removeSelectedItem) {
          removeSelectedItem(_request.id);
        }
        dispatch(notificationSuccess(Messages.SUCCESS.CATEGORY_DELETE_SUCCESS));
        if (_request.callback) {
          _request.callback();
        }
        dispatch(getCategoriesList({ pageIndex: pageNo, pageSize: perPage, search, sort: sortBy }));
      } else {
        dispatch(setLoading(false));
        dispatch(notificationFail(Messages.ERROR.SOMETHING_WENT_WRONG));
      }
    } catch (error: any) {
      dispatch(setLoading(false));
      dispatch(notificationFail(error.message || Messages.ERROR.SOMETHING_WENT_WRONG));
    }
  }
);

/**
 * get tags list
 */
export const getTags = createAsyncThunk("getTags", async (_request: any, { dispatch }) => {
  const page = _request.pageIndex || 0;
  const size = _request.pageSize || 50;
  const search = _request.search || "";
  const sortBy = _request.sort || [];
  const filterBy = _request.filterData || [];
  dispatch(setLoading(true));
  try {
    let url = `tags?page=${page + 1}&per_page=${size}`;
    if (search) {
      url += `&search=${search}`;
    }
    if (_request.all) {
      url += `all=true`;
    }
    if (sortBy?.length) {
      url += `&field=${sortBy[0]?.id}&order_by=${sortBy[0]?.desc ? "desc" : "asc"}`;
    }
    // Apply advance filter
    if (filterBy && filterBy.length) {
      url = getFilterURL(url, filterBy);
    }
    const response = await apiClient().get(url);
    dispatch(setLoading(false));
    if (response) {
      dispatch(tagList(response?.data || []));
    }
  } catch (error: any) {
    dispatch(notificationFail(error.message || Messages.ERROR.NO_DATA_FOUND));
    dispatch(setLoading(false));
  }
});

/**
 * add/update tag
 */
export const addUpdateTag = createAsyncThunk(
  "addUpdateTag",
  async (_request: any, { dispatch }) => {
    dispatch(setLoading(true));
    const { tag, onClose, pageNo, perPage, search, sortBy } = _request;
    try {
      const response = tag.id
        ? await apiClient().put(`tags/${tag.id}`, { tag })
        : await apiClient().post(`tags`, { tag });
      if (response) {
        onClose();
        dispatch(
          notificationSuccess(
            tag.id ? Messages.SUCCESS.RECORD_UPDATE_SUCCESS : Messages.SUCCESS.RECORD_CREATE_SUCCESS
          )
        );
        if (tag?.id) {
          dispatch(getTags({ pageIndex: pageNo, pageSize: perPage, search, sort: sortBy }));
        } else {
          dispatch(getTags({ pageIndex: pageNo, pageSize: perPage }));
        }
      } else {
        dispatch(notificationFail(response?.data?.message || Messages.ERROR.SOMETHING_WENT_WRONG));
      }
    } catch (error: any) {
      dispatch(setLoading(false));
      if (error?.response?.data?.error) {
        const message = getErrorMessage(error?.response?.data?.error);
        dispatch(notificationFail(message));
      } else {
        dispatch(
          notificationFail(error?.response?.data?.message || Messages.ERROR.SOMETHING_WENT_WRONG)
        );
      }
    }
  }
);

/**
 * get single tag
 */
export const getTag = createAsyncThunk("getTag", async (_request: any, { dispatch }) => {
  dispatch(setLoading(true));
  try {
    const response = await apiClient().get(`tags/${_request.id}`);
    dispatch(setLoading(false));
    if (response) {
      dispatch(setTag(response.data));
    } else {
      dispatch(setTag(null));
      dispatch(notificationFail(Messages.ERROR.NO_DATA_FOUND));
    }
  } catch (error: any) {
    dispatch(setLoading(false));
    dispatch(notificationFail(error.message || Messages.ERROR.NO_DATA_FOUND));
  }
});

/**
 * delete tag
 */
export const deleteTag = createAsyncThunk("deleteTag", async (_request: any, { dispatch }) => {
  dispatch(setLoading(true));
  try {
    const { pageNo, perPage, search, sortBy, removeSelectedItem = null } = _request;
    const response = await apiClient().delete(`tags/${_request.id}`);
    if (response) {
      dispatch(notificationSuccess(Messages.SUCCESS.RECORD_DELETE_SUCCESS));
      if (_request.callback) {
        _request.callback();
      }
      if (removeSelectedItem) {
        removeSelectedItem(_request.id);
      }
      dispatch(getTags({ pageIndex: pageNo, pageSize: perPage, search, sort: sortBy }));
    } else {
      dispatch(setLoading(false));
      dispatch(notificationFail(Messages.ERROR.SOMETHING_WENT_WRONG));
    }
  } catch (error: any) {
    dispatch(setLoading(false));
    dispatch(notificationFail(error.message || Messages.ERROR.SOMETHING_WENT_WRONG));
  }
});
