import React, { useEffect, useState } from "react";
import MDDialog from "components/MDDialog";
import { Grid } from "@mui/material";
import MDTypography from "components/MDTypography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import MDBox from "components/MDBox";
import { displayMultipleValue, getLocalDateTime } from "helper/services";
import ImagePreview from "layouts/pages/ImagePreview";
import CustomChipList from "components/CustomChipList";

function AddUpdate(props: any): JSX.Element {
  const { open, onClose, updateData } = props;
  const [blogData, setblogData] = useState<any>({});
  const [imagePreview, setImagePreview] = useState<any>("");
  const [imagePreviewCarousel, setImagePreviewCarousel] = useState<any>("");
  const [expanded, setExpanded] = React.useState<string | false>(false);
  const [isPreview, setImagePreviewPopup] = useState(false);
  const [imageToPreview, setImageToPreview] = useState("");

  useEffect(() => {
    if (updateData) {
      setblogData(updateData);
      if (updateData?.image_url) setImagePreview(updateData.image_url);
      if (updateData?.carousel_image_url) setImagePreviewCarousel(updateData.carousel_image_url);
    }
  }, [updateData]);

  const handleChangeAccordian =
    (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : false);
    };

  const dialogProps = {
    open,
    onClose,
    saveBtn: false,
    title: "View Blog",
    size: "lg",
  };

  const previewImage = (image: any) => {
    setImagePreviewPopup(true);
    setImageToPreview(image);
  };

  const closeImagePreview = () => {
    setImagePreviewPopup(false);
    setImageToPreview("");
  };

  return (
    <MDDialog {...dialogProps}>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6} md={6}>
          <MDTypography fontWeight="bold" variant="button">
            Title
          </MDTypography>
          <MDBox>
            <MDTypography variant="button">{blogData?.title ? blogData.title : "-"}</MDTypography>
          </MDBox>
        </Grid>
        <Grid item xs={12} sm={6} md={6}>
          <MDTypography fontWeight="bold" variant="button">
            URL Name
          </MDTypography>
          <MDBox>
            <MDTypography variant="button">
              {blogData?.url_name ? blogData.url_name : "-"}
            </MDTypography>
          </MDBox>
        </Grid>
        <Grid item xs={12} sm={6} md={6}>
          <MDTypography fontWeight="bold" variant="button">
            Canonical URL Name
          </MDTypography>
          <MDBox>
            <MDTypography variant="button">
              {blogData?.canonical_url ? blogData.canonical_url : "-"}
            </MDTypography>
          </MDBox>
        </Grid>
        <Grid item xs={12} sm={6} md={6}>
          <MDTypography fontWeight="bold" variant="button">
            Author
          </MDTypography>
          <MDBox>
            <MDTypography variant="button">{blogData?.author ? blogData.author : "-"}</MDTypography>
          </MDBox>
        </Grid>
        <Grid item xs={12} sm={6} md={6}>
          <MDTypography fontWeight="bold" variant="button">
            Image
          </MDTypography>
          <Grid container mt={2}>
            <Grid item xs={12} sm={6} md={6}>
              {imagePreview && (
                <Grid
                  container
                  onClick={() => previewImage(imagePreview)}
                  justifyContent="center"
                  alignItems="center"
                  display="flex"
                  className="thumbnail-img"
                >
                  <Grid item>
                    <img height={100} width={100} src={imagePreview} alt="blog" />
                  </Grid>
                </Grid>
              )}
              {!imagePreview && "-"}
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} sm={6} md={6}>
          <MDTypography fontWeight="bold" variant="button">
            Meta Description
          </MDTypography>
          <MDBox p={2} sx={{ border: "1px solid #eee", overflow: "auto" }}>
            <MDTypography variant="button">
              {blogData?.meta_description ? blogData.meta_description : "-"}
            </MDTypography>
          </MDBox>
        </Grid>
        <Grid item xs={12} sm={12} md={12}>
          <MDTypography fontWeight="bold" variant="button">
            Summary
          </MDTypography>
          <MDBox
            sx={{ border: "1px solid #eee", overflow: "auto" }}
            p={2}
            dangerouslySetInnerHTML={{ __html: blogData?.summary ? blogData.summary : "-" }}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={12}>
          <MDTypography fontWeight="bold" variant="button">
            Body
          </MDTypography>
          <MDBox
            sx={{ border: "1px solid #eee", overflow: "auto" }}
            p={2}
            dangerouslySetInnerHTML={{ __html: blogData?.body ? blogData.body : "-" }}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={6}>
          <MDTypography fontWeight="bold" variant="button">
            Tags
          </MDTypography>
          <MDBox>
            <MDTypography variant="button">{displayMultipleValue(updateData.tags)}</MDTypography>
          </MDBox>
        </Grid>
        <Grid item xs={12} sm={6} md={6}>
          <MDTypography fontWeight="bold" variant="button">
            Contact Form Visible
          </MDTypography>
          <MDBox>{blogData?.contact_form_visible ? "Yes" : "No"}</MDBox>
        </Grid>
        <Grid item xs={12} sm={6} md={6}>
          <CustomChipList name="Categories" listData={blogData?.categories} />
        </Grid>
        <Grid item xs={12} sm={6} md={6}>
          <MDTypography fontWeight="bold" variant="button">
            Created At
          </MDTypography>
          <MDBox>{blogData?.created_at ? getLocalDateTime(blogData?.created_at) : "-"}</MDBox>
        </Grid>
        <Grid item xs={12} sm={6} md={6}>
          <MDTypography fontWeight="bold" variant="button">
            Updated At
          </MDTypography>
          <MDBox>{blogData?.updated_at ? getLocalDateTime(blogData?.updated_at) : "-"}</MDBox>
        </Grid>
        <Grid item xs={12} sm={6} md={6}>
          <CustomChipList
            name="Locations"
            listData={updateData?.locations ? updateData?.locations : []}
          />
        </Grid>
        <Grid item xs={12} md={12}>
          <Accordion
            expanded={expanded === "publish"}
            sx={{ marginTop: "20px" }}
            onChange={handleChangeAccordian("publish")}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="publishbh-content"
              id="publishbh-header"
              className="location-accordion"
            >
              <MDTypography variant="h6">Publish</MDTypography>
            </AccordionSummary>
            <AccordionDetails>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6} md={6}>
                  <MDTypography fontWeight="bold" variant="button">
                    Status
                  </MDTypography>
                  <MDBox>{blogData?.status ? blogData?.status : "-"}</MDBox>
                </Grid>
                <Grid item xs={12} sm={6} md={6}>
                  <MDTypography fontWeight="bold" variant="button">
                    Publish date
                  </MDTypography>
                  <MDBox>
                    {blogData?.publish_date ? getLocalDateTime(blogData?.publish_date) : "-"}
                  </MDBox>
                </Grid>
              </Grid>
            </AccordionDetails>
          </Accordion>
          <Accordion
            expanded={expanded === "carousel"}
            sx={{ marginTop: "20px" }}
            onChange={handleChangeAccordian("carousel")}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="carouselbh-content"
              id="carouselbh-header"
              className="location-accordion"
            >
              <MDTypography variant="h6">Carousel</MDTypography>
            </AccordionSummary>
            <AccordionDetails>
              <Grid item xs={12} sm={12} md={12} mb={2}>
                <Grid container mt={2}>
                  <Grid item xs={12} sm={6} md={6}>
                    <MDTypography fontWeight="bold" variant="button">
                      Carousel Image
                    </MDTypography>
                    {imagePreviewCarousel && (
                      <Grid
                        container
                        onClick={() => previewImage(imagePreviewCarousel)}
                        justifyContent="left"
                        alignItems="center"
                        textAlign="center"
                        className="thumbnail-img"
                      >
                        <Grid item>
                          <img height={100} width={100} src={imagePreviewCarousel} alt="blog" />
                        </Grid>
                      </Grid>
                    )}
                    <MDBox>{!imagePreviewCarousel && "-"}</MDBox>
                  </Grid>
                  <Grid item xs={12} sm={6} md={6}>
                    <MDTypography fontWeight="bold" variant="button">
                      Carousel Text
                    </MDTypography>
                    <MDBox>
                      <MDTypography variant="button">
                        {blogData?.carousel_text ? blogData.carousel_text : "-"}
                      </MDTypography>
                    </MDBox>
                  </Grid>
                </Grid>
              </Grid>
            </AccordionDetails>
          </Accordion>
          <Accordion
            expanded={expanded === "fb_conversion_pixel"}
            sx={{ marginTop: "20px" }}
            onChange={handleChangeAccordian("fb_conversion_pixel")}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="fb_conversion_pixelbh-content"
              id="fb_conversion_pixelbh-header"
              className="location-accordion"
            >
              <MDTypography variant="h6">Tracking</MDTypography>
            </AccordionSummary>
            <AccordionDetails>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={12} md={12}>
                  <MDTypography fontWeight="bold" variant="button">
                    FB Conversion Pixel
                  </MDTypography>
                  <MDBox sx={{ border: "1px solid #eee", overflow: "auto" }} p={2}>
                    {blogData?.fb_conversion_pixel || "-"}
                  </MDBox>
                </Grid>
              </Grid>
            </AccordionDetails>
          </Accordion>
          <Accordion
            expanded={expanded === "country_ids"}
            sx={{ marginTop: "20px" }}
            onChange={handleChangeAccordian("country_ids")}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="country_idsbh-content"
              id="country_idsbh-header"
              className="location-accordion"
            >
              <MDTypography variant="h6">Countries</MDTypography>
            </AccordionSummary>
            <AccordionDetails>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6} md={6}>
                  <MDTypography fontWeight="bold" variant="button">
                    Country
                  </MDTypography>
                  <MDBox>
                    <MDTypography variant="button">
                      {displayMultipleValue(updateData.countries)}
                    </MDTypography>
                  </MDBox>
                </Grid>
              </Grid>
            </AccordionDetails>
          </Accordion>
        </Grid>
      </Grid>
      <ImagePreview
        imageData={imageToPreview}
        open={isPreview}
        onCloseImage={() => {
          closeImagePreview();
        }}
      />
    </MDDialog>
  );
}

export default AddUpdate;
