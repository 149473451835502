import { useState, useEffect, useRef, useCallback } from "react";
import {
  Card,
  Checkbox,
  Icon,
  FormControlLabel,
  Grid,
  FormControl,
  Autocomplete,
  Chip,
} from "@mui/material";
import moment from "moment";
import Select from "react-select";
import MDButton from "components/MDButton";
import MDBox from "components/MDBox";
import DataTable from "examples/Tables/DataTable";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import { getAuthUser, getLocalDateTime, downloadCenterFields, getFilterURL } from "helper/services";
import { useAppDispatch, useAppSelector } from "store/store";
import {
  getDownloadCenter,
  downloadFile,
  deleteDownloadCenter,
  updateExportData,
  saveScheduleDownload,
} from "store/thunk/downloadCenter";
import { setDownloadFileUrl } from "store/slices/downloadCenter";
import Messages from "helper/messages";
import MDIconButton from "components/MDIconButton";
import MDDialog from "components/MDDialog";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDDatePicker from "components/MDDatePicker";
import exportData from "helper/exportTableConst";
import { RRule } from "rrule";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";

const weekDays = ["M", "T", "W", "T", "F", "S", "S"];
const weekDaysIndex = [0, 1, 2, 3, 4, 5, 6];
const repeatOptions = [
  { label: "Weekly", value: "week" },
  { label: "Monthly", value: "month" },
];
const dateRangeOptions = [
  { label: "Last Week", value: "last_week" },
  { label: "Last Month", value: "last_month" },
  { label: "Last 3 months", value: "last_3_months" },
  { label: "Last 6 months", value: "last_6_months" },
  { label: "Last 12 months", value: "last_12_months" },
  { label: "All Time", value: "all_time" },
];

const getDates = () => {
  const date = new Date();
  const month = date.getMonth();
  date.setDate(1);
  const allDays = [];
  while (date.getMonth() === month) {
    const dt = date.getDate().toString().padStart(2, "0");
    let lastDay: any = new Date(date.getFullYear(), date.getMonth() + 1, 0);
    lastDay = lastDay.getDate().toString().padStart(2, "0");
    const d = {
      label: dt === lastDay ? "Last" : dt,
      value: dt,
    };
    allDays.push(d);
    date.setDate(date.getDate() + 1);
  }
  return allDays;
};

function DownloadCenter(): JSX.Element {
  const dispatch = useAppDispatch();
  const { downloadCenter, downloadUrl } = useAppSelector((state) => state.downloadCenterSlice);
  const [inquiriesList, setInquiriesList] = useState([]);
  const [pageCount, setPageCount] = useState(0);
  const [pageNo, setPageNo] = useState(0);
  const [totalCount, setTotalCount] = useState(0);
  const [deleteDownloadCenterId, setDeleteDownloadCenterId] = useState<any>(null);
  const authUser = getAuthUser();
  const fetchIdRef = useRef(0);
  const [perPage, setPageSize] = useState(10);
  const [search, setSearch] = useState<any>(null);
  const [sortBy, setSortBy] = useState<any>([]);
  const [editRecord, setEditRecord] = useState<any>(null);
  const [selectedFields, setSelectedFields] = useState<any>([]);
  const [exportFields, setExportFields] = useState<any>([]);
  const [exportFromDate, setExportFromDate] = useState<any>(null);
  const [exportToDate, setExportToDate] = useState<any>(null);
  const [schedule, setSchedule] = useState<any>(null);
  const [emailValue, setEmailValue] = useState<any>([authUser.login_email]);
  const [selectedWeekDays, setSelectedWeekDays] = useState<any>([new Date().getDay() - 1]);
  const [repeatOption, setRepeatOption] = useState<any>(repeatOptions[0]);
  const [selectedDate, setSelectedDate] = useState<any>(null);
  const [repeatNumber, setRepeatNumber] = useState<any>(1);
  const [allDays] = useState<any>(getDates());
  const [exportDateRange, setExportDateRange] = useState<any>(dateRangeOptions[0]);

  useEffect(() => {
    if (downloadUrl && downloadUrl?.data) {
      const link = document.createElement("a");
      link.href = downloadUrl?.data;
      document.body.appendChild(link);
      link.click();
      dispatch(setDownloadFileUrl(""));
    }
  }, [downloadUrl]);

  useEffect(() => {
    if (schedule) {
      if (schedule?.connect_schedule_download_center?.emails) {
        setEmailValue(schedule?.connect_schedule_download_center?.emails);
      }
      const spitObj = schedule?.connect_schedule_download_center?.recurring_rule.split("\n");
      if (spitObj) {
        const rule = RRule.fromString(spitObj[1]);
        if (rule.options.bymonthday && rule.options.bymonthday.length) {
          const monthDate = allDays.find((val: any) =>
            rule.options.bymonthday.filter((v: any) => val.value === v.toString().padStart(2, "0"))
          );
          setSelectedDate(monthDate);
        }
        setRepeatNumber(rule.options.interval);
        setRepeatOption(
          repeatOptions.find((v: any) =>
            rule.options.freq === 2 ? v.value === "week" : v.value === "month"
          )
        );
        setSelectedWeekDays(rule.options.byweekday);
      }
    } else {
      setSelectedDate(null);
      setRepeatNumber(1);
      setRepeatOption(repeatOptions[0]);
      setSelectedWeekDays([new Date().getDay() - 1]);
    }
  }, [schedule]);

  useEffect(() => {
    if (downloadCenter && downloadCenter?.download_center) {
      setInquiriesList(downloadCenter?.download_center);
      setPageCount(downloadCenter?.meta?.total_pages);
      setTotalCount(downloadCenter?.meta?.total_count);
    }
  }, [downloadCenter]);

  const fetchData = useCallback(({ pageSize, pageIndex, search, sortBy, filterData }) => {
    const fetchId = ++fetchIdRef.current;
    const sort: any = sortBy || [];
    // We'll even set a delay to simulate a server here
    setTimeout(() => {
      if (fetchId === fetchIdRef.current) {
        let advanceSearch: any = [];
        if (filterData?.length) {
          const getUrl = getFilterURL("", filterData);
          const urlParams = new URLSearchParams(getUrl);
          advanceSearch = JSON.parse(urlParams.get("advance_search")) || [];
        }
        setPageNo(pageIndex);
        setSearch(search);
        setSortBy(sort);
        setPageSize(pageSize);
        dispatch(
          getDownloadCenter({
            size: pageSize,
            page: pageIndex,
            search_text: search,
            user_id: authUser.user_id,
            sort_field: sort.length ? sort[0].id : "id",
            sort_type: sort.length ? (sort[0].desc ? "desc" : "asc") : "desc",
            advance_search: advanceSearch,
          })
        );
      }
    }, 1000);
  }, []);

  const downloadServerFile = (obj: any) => {
    dispatch(downloadFile({ user_id: authUser.user_id, id: obj.id }));
  };

  const onEdit = (dataTemp: any) => {
    const data = JSON.parse(JSON.stringify(dataTemp));
    const tempExportData: any = exportData;
    if (
      data?.join_tables &&
      data?.join_tables?.length &&
      data?.table_name === "request_tour_inquiries"
    ) {
      data?.join_tables.map((obj: any) => {
        if (obj?.table === "locations" && obj?.fields.includes("name")) {
          if (data?.fields_to_export) {
            data.fields_to_export += ",location_name";
          } else {
            data.fields_to_export += "location_name";
          }
        }
        return true;
      });
    }

    let tableName: any = data?.table_name;
    if (data?.table_name === "connect_locations") {
      tableName = "locations";
      if (data?.join_tables && data?.join_tables?.length) {
        data?.join_tables.map((obj: any) => {
          data.fields_to_export += (data.fields_to_export ? "," : "") + obj.fields;
          const { include } = obj;
          if (include && include?.length) {
            include?.map((childObj: any) => {
              if (childObj?.table === "msas" && childObj?.fields?.includes("name")) {
                data.fields_to_export += data?.fields_to_export ? ",msa_name" : "msa_name";
              }
              if (childObj?.table === "admins" && childObj?.fields?.includes("email")) {
                data.fields_to_export += data?.fields_to_export ? ",franchisee" : "franchisee";
              }
              return true;
            });
          }
          return true;
        });
      }
    }
    const tempSelected = [...new Set(data?.fields_to_export?.split(",") || [])];
    setSelectedFields(tempSelected);
    setExportFields(tempExportData?.[tableName]?.export_fields || []);
    setExportFromDate(data?.export_from_date || null);
    setExportToDate(data?.export_to_date || null);
    setEditRecord(data);
  };
  const actionButtons = (obj: any) => (
    <>
      <MDIconButton
        aria-label="Open"
        tooltipName="Download"
        color="info"
        disabled={obj.status === "ready" ? false : true}
        onClick={() => {
          downloadServerFile(obj);
        }}
      >
        <Icon fontSize="small">download</Icon>
      </MDIconButton>
      <MDIconButton aria-label="Edit" tooltipName="Edit" color="info" onClick={() => onEdit(obj)}>
        <Icon fontSize="small">edit</Icon>
      </MDIconButton>
      <MDIconButton
        tooltipName="Delete"
        aria-label="Delete"
        color="error"
        onClick={() => setDeleteDownloadCenterId(obj.id)}
      >
        <Icon fontSize="small">delete</Icon>
      </MDIconButton>
      <MDIconButton
        tooltipName="Schedule"
        aria-label="Schedule"
        color="info"
        disabled={obj.status === "ready" ? false : true}
        onClick={() => setSchedule(obj)}
      >
        <Icon fontSize="small">access_time_icon</Icon>
      </MDIconButton>
    </>
  );

  const getRows = () => {
    const data: any = inquiriesList.map((obj: any) => ({
      id: obj.id,
      subject: obj.subject || "-",
      export_type: obj.export_type || "-",
      fields_to_export: downloadCenterFields(obj?.fields_to_export, obj?.join_tables) || "-",
      created_at: obj.created_at ? getLocalDateTime(obj.updated_at) : "-",
      updated_at: obj.updated_at ? getLocalDateTime(obj.updated_at) : "-",
      action: actionButtons(obj),
    }));
    return data;
  };

  const tableData = {
    columns: [
      { Header: "Id", accessor: "id", export: true },
      { Header: "Name", accessor: "export_type", export: true, width: "300px" },
      { Header: "Fields", accessor: "fields_to_export", export: true, width: "300px" },
      { Header: "Created At", accessor: "created_at", export: true, is_date: true },
      { Header: "Updated At", accessor: "updated_at", export: true, is_date: true },
      { Header: "Action", accessor: "action", export: false, width: "200px", disableSortBy: true },
    ],
    rows: getRows(),
  };

  const onDelete = () => {
    if (deleteDownloadCenterId) {
      const delReq = {
        id: deleteDownloadCenterId,
        setDeleteDownloadCenterId,
        size: perPage,
        page: pageNo,
        searchText: search,
        userId: authUser?.user_id,
        sortField: sortBy?.length ? sortBy[0]?.id : "",
        sortType: sortBy?.length ? (sortBy[0]?.desc ? "desc" : "asc") : "desc",
      };
      dispatch(deleteDownloadCenter(delReq));
    }
  };

  const resetData = () => {
    dispatch(
      getDownloadCenter({ pageSize: 10, pageIndex: 0, search_text: "", user_id: authUser.user_id })
    );
  };

  const deleteDialogProps = {
    open: deleteDownloadCenterId,
    onClose: () => setDeleteDownloadCenterId(false),
    onSave: () => onDelete(),
    title: "Delete Download Center",
    size: "sm",
    saveBtn: false,
    deleteBtn: true,
    saveTbtText: "Yes",
    closeBtnText: "No",
  };

  const callback = () => {
    setEditRecord(null);
    setSelectedFields([]);
    setExportFields([]);
    setExportFromDate(null);
    setExportToDate(null);
  };

  const onUpdate = () => {
    let tempField = JSON.parse(JSON.stringify(selectedFields));
    if (
      editRecord?.table_name === "request_tour_inquiries" &&
      selectedFields.includes("location_name")
    ) {
      if (tempField.indexOf("location_name") !== -1) {
        tempField.splice(tempField.indexOf("location_name"), 1);
      }
    }
    if (editRecord?.table_name === "connect_locations") {
      editRecord.join_tables = editRecord?.join_tables?.map((item: any) => {
        const mainFields = [...new Set([...item.fields, ...tempField])];
        const onlyMatchedRecords = mainFields.filter(
          (fieldName: any) => tempField.indexOf(fieldName) !== -1
        );
        const filteredFields = onlyMatchedRecords.filter(
          (x: any) => x !== "msa_name" && x !== "franchisee"
        );
        return {
          ...item,
          fields: filteredFields,
          include: item?.include?.map((xChild: any) => {
            if (xChild?.table === "msas") {
              return {
                ...xChild,
                fields: tempField?.includes("msa_name") ? ["name"] : [],
              };
            }
            if (xChild?.table === "admins") {
              return {
                ...xChild,
                fields: tempField?.includes("franchisee") ? ["email"] : [],
              };
            }
            return xChild;
          }),
        };
      });
      tempField = [];
    }

    dispatch(
      updateExportData({
        id: editRecord?.id,
        exportType: editRecord?.export_type,
        fieldsToExport: tempField.join(),
        size: perPage,
        page: pageNo,
        searchText: search,
        userId: authUser?.user_id,
        sortField: sortBy?.length ? sortBy[0]?.id : "id",
        sortType: sortBy?.length ? (sortBy[0]?.desc ? "desc" : "asc") : "desc",
        exportFromDate: exportFromDate ? moment(exportFromDate).format("YYYY-MM-DD") : null,
        exportToDate: exportToDate ? moment(exportToDate).format("YYYY-MM-DD") : null,
        ...(editRecord?.table_name === "connect_locations" && {
          joinTables: editRecord.join_tables,
        }),
        callback,
      })
    );
  };

  const onChangeField = (event: any) => {
    if (event.target.checked) {
      const tempSelected = JSON.parse(JSON.stringify(selectedFields));
      tempSelected.push(event.target.value);
      setSelectedFields(tempSelected);
    } else {
      setSelectedFields(selectedFields.filter((val: any) => val !== event.target.value));
    }
  };

  const onChangeSelectAll = (event: any) => {
    let checkedFields: any = [];
    const commonlyUsed = event.target.value === "commonly_used" ? true : false;
    if (selectedFields?.length) {
      if (event.target.checked) {
        checkedFields = JSON.parse(JSON.stringify(selectedFields));
      } else {
        selectedFields.map((field: any) => {
          console.log("field ", field);
          const tempField = exportFields.find((obj: any) => obj.key === field);
          console.log("tempField ", tempField);
          if (tempField) {
            checkedFields.push(field);
          }
          return true;
        });
      }
    }
    if (exportFields && exportFields.length > 0) {
      exportFields
        .filter((field: any) => field.common === commonlyUsed)
        .map((obj: any) => {
          if (event.target.checked) {
            if (!checkedFields.includes(obj.key)) {
              checkedFields.push(obj.key);
            }
          } else {
            checkedFields = checkedFields.filter((field: string) => field !== obj.key);
          }
          return true;
        });
    }

    setSelectedFields(checkedFields);
  };

  const editDialogProps = {
    open: editRecord,
    saveBtn: false,
    disabledBtn: true,
    isDisabled:
      !editRecord?.export_type ||
      !selectedFields?.length ||
      (!exportFromDate && exportToDate) ||
      (exportFromDate && !exportToDate),
    onClose: () => callback(),
    onSave: () => onUpdate(),
    title: "Update Export",
    size: "xl",
  };

  const onCloseSchedule = () => {
    setSchedule(null);
    setEmailValue([authUser.login_email]);
    // setSelectedWeekDays([]);
    setRepeatOption(repeatOptions[0]);
    setSelectedDate(null);
    setRepeatNumber(1);
    setExportDateRange(dateRangeOptions[0]);
  };

  const onSchedule = () => {
    const dtStart = new Date(
      Date.UTC(
        new Date().getUTCFullYear(),
        new Date().getUTCMonth(),
        new Date().getUTCDate(),
        new Date().getUTCHours(),
        new Date().getUTCMinutes(),
        0
      )
    );
    const RRWeekDay = [RRule.MO, RRule.TU, RRule.WE, RRule.TH, RRule.FR, RRule.SA, RRule.SU];
    const recurringObj = {
      freq: RRule.WEEKLY,
      interval: Number(repeatNumber),
      count: 3,
      dtstart: dtStart,
    };
    if (repeatOption.value === "week") {
      if (selectedWeekDays.length > 0) {
        Object.assign(recurringObj, {
          byweekday: [...selectedWeekDays.map((val: any) => RRWeekDay[val])],
        });
      }
    }
    if (repeatOption.value === "month") {
      if (selectedDate) {
        Object.assign(recurringObj, {
          freq: RRule.MONTHLY,
          wkst: RRule.MO,
          bymonthday: [Number(selectedDate.value)],
        });
      }
    }
    if (repeatOption.value === "year") {
      Object.assign(recurringObj, {
        freq: RRule.YEARLY,
      });
    }
    const rule = new RRule(recurringObj);
    const triggerDate = rule.after(new Date(moment.utc().toISOString()), false);
    dispatch(
      saveScheduleDownload({
        downloadId: schedule.id,
        emails: emailValue,
        recurringRule: rule.toString(),
        triggerDate,
        exportDateRange: exportDateRange.value,
        onCloseSchedule,
        size: perPage,
        page: pageNo,
        searchText: search,
        userId: authUser?.user_id,
        sortField: sortBy?.length ? sortBy[0]?.id : "id",
        sortType: sortBy?.length ? (sortBy[0]?.desc ? "desc" : "asc") : "desc",
      })
    );
    dispatch(
      getDownloadCenter({
        size: perPage,
        page: pageNo,
        search_text: search,
        user_id: authUser.user_id,
        sort_field: sortBy.length ? sortBy[0].id : "id",
        sort_type: sortBy.length ? (sortBy[0].desc ? "desc" : "asc") : "desc",
      })
    );
  };

  const scheduleDialogProps = {
    open: schedule,
    saveBtn: false,
    disabledBtn: true,
    onClose: () => onCloseSchedule(),
    onSave: () => onSchedule(),
    title: "Schedule",
    size: "sm",
    overflowVisible: "overflowVisible",
  };

  const onSelectWeekDays = (val: string) => {
    const days = [...selectedWeekDays];
    const index = days.indexOf(val);
    if (index === -1) {
      days.push(val);
    } else {
      days.splice(index, 1);
    }
    setSelectedWeekDays(days);
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={4} pb={3}>
        <Card>
          <MDBox
            bgColor="dark"
            color="white"
            coloredShadow="dark"
            borderRadius="xl"
            alignItems="center"
            justifyContent="space-between"
            pt={1}
            pb={1}
            pl={2}
            pr={2}
            ml={2}
            mr={2}
            display="flex"
            mt={-3}
            className="page-header"
          >
            <p className="page-header-label">Download Center</p>
            <MDButton
              variant="gradient"
              color="light"
              size="small"
              className="xs-small"
              onClick={() => resetData()}
            >
              <Icon sx={{ fontWeight: "bold" }}>refresh</Icon> Refresh Data
            </MDButton>
          </MDBox>
          <DataTable
            table={tableData}
            canSearch
            manualSort
            showExportAllBtn={false}
            showExportSelectedBtn={false}
            pageCount={pageCount}
            pageNo={pageNo}
            totalCount={totalCount}
            fetchData={fetchData}
            manualSearch
            exportFields={exportData.download_center.export_fields}
            exportTable={exportData.download_center.table_name}
            exportType={exportData.download_center.export_type}
            advanceFilter
          />
          {deleteDownloadCenterId && (
            <MDDialog {...deleteDialogProps}>
              <MDTypography variant="h6" fontWeight="medium">
                {Messages.GENERAL.SURE_TO_DELETE}
              </MDTypography>
            </MDDialog>
          )}
          {editRecord && (
            <MDDialog {...editDialogProps}>
              <Grid container spacing={1} mb={1}>
                <Grid item xs={12} sm={6} md={3} lg={3}>
                  <FormControl>
                    <MDTypography variant="button">Export Name:</MDTypography>
                    <MDInput
                      name="export_type"
                      value={editRecord?.export_type || ""}
                      fullWidth
                      onChange={(event: any) => {
                        setEditRecord({ ...editRecord, export_type: event?.target?.value });
                      }}
                      required
                      mb={2}
                    />
                  </FormControl>
                </Grid>
              </Grid>
              <Grid container spacing={1} mb={1}>
                {exportFields &&
                  exportFields.length > 0 &&
                  exportFields.filter((obj: any) => obj.common).length > 0 && (
                    <Grid item xs={12} sm={12} md={12} lg={12}>
                      <MDBox>
                        <Accordion expanded>
                          <AccordionSummary className="export-accordion">
                            <MDTypography variant="h6">Commonly Used</MDTypography>
                            <FormControlLabel
                              value="commonly_used"
                              control={
                                <Checkbox
                                  name="select_all"
                                  inputProps={{ "aria-label": "controlled" }}
                                  checked={
                                    exportFields
                                      ?.filter((obj: any) => obj.common)
                                      ?.every((element: any) =>
                                        selectedFields.includes(element.key)
                                      )
                                      ? true
                                      : false
                                  }
                                  onChange={onChangeSelectAll}
                                />
                              }
                              label="Select All"
                              labelPlacement="end"
                            />
                          </AccordionSummary>
                          <AccordionDetails>
                            <Grid container spacing={1}>
                              {exportFields &&
                                exportFields.length > 0 &&
                                exportFields
                                  .filter((obj: any) => obj.common)
                                  .sort((a: any, b: any) => a.label.localeCompare(b.label))
                                  .map((obj: any) => (
                                    <Grid item xs={12} sm={6} md={3} lg={3}>
                                      <FormControlLabel
                                        value={obj.key}
                                        control={
                                          <Checkbox
                                            inputProps={{ "aria-label": "controlled" }}
                                            checked={
                                              selectedFields && selectedFields.includes(obj.key)
                                                ? true
                                                : false
                                            }
                                            onChange={onChangeField}
                                          />
                                        }
                                        label={obj.label}
                                        labelPlacement="end"
                                      />
                                    </Grid>
                                  ))}
                            </Grid>
                          </AccordionDetails>
                        </Accordion>
                      </MDBox>
                    </Grid>
                  )}
                {exportFields &&
                  exportFields.length > 0 &&
                  exportFields.filter((obj: any) => !obj.common).length > 0 && (
                    <Grid item xs={12} sm={12} md={12} lg={12}>
                      <MDBox>
                        <Accordion expanded>
                          <AccordionSummary className="export-accordion">
                            <MDTypography variant="h6">All Fields</MDTypography>
                            <FormControlLabel
                              value="select_all"
                              control={
                                <Checkbox
                                  name="select_all"
                                  inputProps={{ "aria-label": "controlled" }}
                                  checked={
                                    exportFields
                                      ?.filter((obj: any) => !obj.common)
                                      ?.every((element: any) =>
                                        selectedFields.includes(element.key)
                                      )
                                      ? true
                                      : false
                                  }
                                  onChange={onChangeSelectAll}
                                />
                              }
                              label="Select All"
                              labelPlacement="end"
                            />
                          </AccordionSummary>
                          <AccordionDetails>
                            <Grid container spacing={1}>
                              {exportFields &&
                                exportFields.length > 0 &&
                                exportFields
                                  .filter((obj: any) => !obj.common)
                                  .sort((a: any, b: any) => a.label.localeCompare(b.label))
                                  .map((obj: any) => (
                                    <Grid item xs={12} sm={6} md={3} lg={3}>
                                      <FormControlLabel
                                        value={obj.key}
                                        control={
                                          <Checkbox
                                            inputProps={{ "aria-label": "controlled" }}
                                            checked={
                                              selectedFields && selectedFields.includes(obj.key)
                                                ? true
                                                : false
                                            }
                                            onChange={onChangeField}
                                          />
                                        }
                                        label={obj.label}
                                        labelPlacement="end"
                                      />
                                    </Grid>
                                  ))}
                            </Grid>
                          </AccordionDetails>
                        </Accordion>
                      </MDBox>
                    </Grid>
                  )}
              </Grid>
              <Grid container spacing={1} mb={1}>
                <Grid item xs={12} sm={6} md={3} lg={3}>
                  <FormControl>
                    <MDTypography variant="button">From Date:</MDTypography>
                    <MDDatePicker
                      options={{
                        dateFormat: "Y-m-d",
                        maxDate: new Date(),
                      }}
                      onChange={([date]: any) => setExportFromDate(date)}
                      value={exportFromDate || null}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={6} md={3} lg={3}>
                  <FormControl>
                    <MDTypography variant="button">To Date:</MDTypography>
                    <MDDatePicker
                      options={{
                        dateFormat: "Y-m-d",
                        maxDate: new Date(),
                      }}
                      onChange={([date]: any) => setExportToDate(date)}
                      value={exportToDate || null}
                    />
                  </FormControl>
                </Grid>
              </Grid>
            </MDDialog>
          )}
          {schedule && (
            <MDDialog {...scheduleDialogProps}>
              <Grid container>
                <Grid item md={12}>
                  <Autocomplete
                    multiple
                    id="tags-filled"
                    options={[]}
                    freeSolo
                    value={emailValue}
                    onChange={(e, newval, reason) => {
                      if (reason === "removeOption") {
                        setEmailValue(newval);
                      }
                    }}
                    renderTags={(value: string[], getTagProps) =>
                      value.map((option: string, index: number) => (
                        <Chip variant="outlined" label={option} {...getTagProps({ index })} />
                      ))
                    }
                    renderInput={(params) => (
                      <MDInput
                        label="Send To"
                        {...params}
                        fullWidth
                        variant="standard"
                        placeholder="Emails"
                        onKeyDown={(e: any) => {
                          if (e.keyCode === 13 && e.target.value) {
                            const emailRegex =
                              /^(([^<>()\]\\.,;:\s@"]+(\.[^<>()\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
                            if (emailRegex.test(e.target.value)) {
                              setEmailValue(emailValue.concat(e.target.value));
                            }
                          }
                        }}
                      />
                    )}
                  />
                </Grid>
                <Grid item md={12} sx={{ marginTop: "10px" }}>
                  <MDTypography variant="body2">Export Date Range</MDTypography>
                  <Select
                    menuPlacement="auto"
                    isClearable={false}
                    name="exportDateRange"
                    defaultValue={dateRangeOptions[0]}
                    value={
                      dateRangeOptions.find(
                        (v: any) =>
                          v.value === schedule?.connect_schedule_download_center?.date_range
                      ) || exportDateRange
                    }
                    onChange={(value: any) => {
                      setExportDateRange(value);
                    }}
                    options={dateRangeOptions}
                    blurInputOnSelect={false}
                    placeholder="Select..."
                  />
                </Grid>
                <Grid item md={12} sx={{ display: "flex", marginTop: "10px" }}>
                  <MDInput
                    label="Repeat every"
                    type="number"
                    InputProps={{ inputProps: { min: 1 } }}
                    defaultValue={1}
                    value={repeatNumber}
                    onChange={(e: any) => setRepeatNumber(e.target.value)}
                    variant="standard"
                  />
                </Grid>
                <Grid
                  item
                  md={12}
                  sx={{ display: "flex", marginTop: "10px", flexDirection: "column" }}
                >
                  <MDTypography variant="body2" sx={{ fontSize: "0.8rem" }}>
                    Send Frequency
                  </MDTypography>
                  <Select
                    menuPlacement="auto"
                    className="frequency"
                    isClearable={false}
                    name="repeatOption"
                    defaultValue={repeatOptions[0]}
                    value={repeatOption}
                    onChange={(value: any) => {
                      if (value.value === "month") {
                        // setSelectedWeekDays([]);
                      } else if (value.value === "week") {
                        setSelectedDate(null);
                      }
                      setRepeatOption(value);
                    }}
                    options={repeatOptions}
                    blurInputOnSelect={false}
                    placeholder="Select..."
                  />
                </Grid>
                {repeatOption.value !== "year" && (
                  <Grid item md={12} sx={{ marginTop: "10px" }}>
                    <MDTypography variant="body2">Repeat on</MDTypography>
                    {repeatOption.value === "week" && (
                      <Grid container className="repeat-days">
                        {weekDaysIndex.map((val: any) => (
                          <Grid
                            md={1}
                            className={
                              selectedWeekDays.indexOf(val) !== -1 ? "selected days" : "days"
                            }
                            onClick={() => onSelectWeekDays(val)}
                          >
                            {weekDays[val]}
                          </Grid>
                        ))}
                      </Grid>
                    )}
                    {repeatOption.value === "month" && (
                      <Grid container className="repeat-days">
                        <Select
                          menuPlacement="auto"
                          isClearable={false}
                          name="monthDates"
                          value={selectedDate ? selectedDate : allDays[allDays.length - 1]}
                          onChange={(value: any) => {
                            setSelectedDate(value);
                          }}
                          options={allDays}
                          blurInputOnSelect={false}
                          placeholder="Select..."
                        />
                      </Grid>
                    )}
                  </Grid>
                )}
              </Grid>
            </MDDialog>
          )}
        </Card>
      </MDBox>
    </DashboardLayout>
  );
}

export default DownloadCenter;
