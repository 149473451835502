import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDLabelValue from "components/MDLabelValue";
import Grid from "@mui/material/Grid";
import Chip from "@mui/material/Chip";
import Switch from "@mui/material/Switch";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormGroup from "@mui/material/FormGroup";
import Checkbox from "@mui/material/Checkbox";
import Paper from "@mui/material/Paper";
import { v4 as uuidv4 } from "uuid";

function Review(props: any): JSX.Element {
  const { accountDetail, serviceList } = props;

  const getServices = (): JSX.Element => {
    const servicesJsx: any = [];
    if (accountDetail.services && accountDetail.services.length) {
      accountDetail.services.map((serviceId: any) => {
        servicesJsx.push(
          <Chip
            key={uuidv4()}
            label={serviceList.find((s: any) => s.id.toString() === serviceId).name}
            sx={{ marginRight: "2px" }}
          />
        );
        return true;
      });
    }
    return servicesJsx;
  };

  return (
    <MDBox>
      <MDBox textAlign="center" m={2}>
        <MDTypography variant="h5" fontWeight="regular">
          Review
        </MDTypography>
      </MDBox>
      <Grid container spacing={2}>
        <Grid item lg={12} md={12} sm={12} xs={12} xl={12}>
          <MDBox>
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="general"
              >
                <MDTypography>General</MDTypography>
              </AccordionSummary>
              <AccordionDetails>
                <Paper elevation={3} sx={{ padding: "10px" }}>
                  <MDTypography component="h6" fontSize="16px" fontWeight="bold">
                    Billing Information
                  </MDTypography>
                  <MDLabelValue label="Sola Pro ID" value={accountDetail?.sola_pro_id || ""} />
                  <MDLabelValue label="First name" value={accountDetail?.first_name || ""} />
                  <MDLabelValue label="Last name" value={accountDetail?.last_name || ""} />
                </Paper>
                <Paper elevation={3} sx={{ padding: "10px", marginTop: "5px" }}>
                  <MDTypography component="h6" fontSize="16px" fontWeight="bold">
                    Contact Info
                  </MDTypography>
                  <MDLabelValue
                    label="Personal Email"
                    value={accountDetail?.personal_email || ""}
                  />
                  <MDLabelValue
                    label="Personal Phone"
                    value={accountDetail?.personal_phone || ""}
                  />
                  <MDLabelValue label="Billing Email" value={accountDetail?.billing_email || ""} />
                  <MDLabelValue label="Billing Phone" value={accountDetail?.billing_phone || ""} />
                </Paper>
                <Paper elevation={3} sx={{ padding: "10px", marginTop: "5px" }}>
                  <MDTypography component="h6" fontSize="16px" fontWeight="bold">
                    Services
                  </MDTypography>
                  {getServices()}
                </Paper>
              </AccordionDetails>
            </Accordion>
          </MDBox>
        </Grid>
        <Grid item lg={12} md={12} sm={12} xs={12} xl={12}>
          <MDBox>
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="website"
              >
                <MDTypography>Website</MDTypography>
              </AccordionSummary>
              <AccordionDetails>
                <Paper elevation={3} sx={{ padding: "10px" }}>
                  <MDTypography component="h6" fontSize="16px" fontWeight="bold">
                    Basic Info
                  </MDTypography>
                  <MDLabelValue label="Business name" value={accountDetail?.business_name || ""} />
                  <MDLabelValue label="URL" value={accountDetail?.business_url || ""} />
                  <MDLabelValue
                    label="Description"
                    value={accountDetail?.business_description || ""}
                  />
                  <MDLabelValue
                    label="Work Hours"
                    value={accountDetail?.business_work_hours || ""}
                  />
                </Paper>

                <Paper elevation={3} sx={{ padding: "10px", marginTop: "5px" }}>
                  <MDTypography component="h6" fontSize="16px" fontWeight="bold">
                    Contact Info
                  </MDTypography>
                  <MDLabelValue
                    label="Same As Personal?"
                    value={accountDetail?.contact_info_same_as_personal ? "Yes" : "No"}
                  />

                  {!accountDetail?.contact_info_same_as_personal && (
                    <Grid container spacing={3}>
                      <Grid item xs={12} sm={12}>
                        <MDTypography variant="h6">Website Contact</MDTypography>
                      </Grid>
                      <Grid item xs={12} sm={12}>
                        <MDTypography variant="h6">Public Contact Name</MDTypography>
                        <FormControlLabel
                          control={
                            <Switch
                              checked={accountDetail?.not_display_name || false}
                              inputProps={{ "aria-label": "controlled" }}
                              name="not_display_name"
                              disabled
                            />
                          }
                          label="Do not display a name on the site"
                          labelPlacement="end"
                        />
                      </Grid>
                      <Grid item xs={12} sm={12}>
                        <MDTypography variant="h6">Public Phone</MDTypography>
                        <FormControlLabel
                          control={
                            <Switch
                              checked={accountDetail?.not_deisplay_phone || false}
                              inputProps={{ "aria-label": "controlled" }}
                              name="not_deisplay_phone"
                              disabled
                            />
                          }
                          label="Do not display a phone number on the site"
                          labelPlacement="end"
                        />
                      </Grid>
                      <Grid item xs={12} sm={12}>
                        <MDTypography variant="h6">Public Email</MDTypography>
                        <FormControlLabel
                          control={
                            <Switch
                              checked={accountDetail?.not_display_email || false}
                              inputProps={{ "aria-label": "controlled" }}
                              name="not_display_email"
                              disabled
                            />
                          }
                          label="Do not display a email on the site"
                          labelPlacement="end"
                        />
                      </Grid>
                    </Grid>
                  )}
                </Paper>
                <Paper elevation={3} sx={{ padding: "10px", marginTop: "5px" }}>
                  <MDTypography component="h6" fontSize="16px" fontWeight="bold">
                    Images
                  </MDTypography>
                  <Grid container justifyContent="center" spacing={1}>
                    {accountDetail?.images &&
                      accountDetail?.images.length > 0 &&
                      accountDetail?.images.map((imgSrc: string) => (
                        <Grid item key={uuidv4()}>
                          <img
                            key={imgSrc}
                            width="100"
                            height="100"
                            src={imgSrc}
                            alt="account images"
                          />
                        </Grid>
                      ))}
                  </Grid>
                </Paper>
                <Paper elevation={3} sx={{ padding: "10px", marginTop: "5px" }}>
                  <MDTypography component="h6" fontSize="16px" fontWeight="bold">
                    Testimonials
                  </MDTypography>
                  {accountDetail?.testimonials &&
                    accountDetail?.testimonials.length > 0 &&
                    accountDetail?.testimonials.map((obj: any) => (
                      <Grid item key={uuidv4()} container mt={1}>
                        <Paper elevation={3} sx={{ width: "100%", padding: "5px 10px" }}>
                          <Grid item md={6} sm={6} xs={12}>
                            <MDLabelValue label="Name" value={obj?.name || ""} />
                          </Grid>
                          <Grid item md={6} sm={6} xs={12}>
                            <MDLabelValue label="Region" value={obj?.region || ""} />
                          </Grid>
                          <Grid item lg={12} md={12} sm={12} xs={12} xl={12}>
                            <MDLabelValue label="Text" value={obj?.text || ""} />
                          </Grid>
                        </Paper>
                      </Grid>
                    ))}
                </Paper>
                <Paper elevation={3} sx={{ padding: "10px", marginTop: "5px" }}>
                  <MDTypography component="h6" fontSize="16px" fontWeight="bold">
                    Social Links
                  </MDTypography>
                  <MDLabelValue label="Facebook URL" value={accountDetail?.facebook_url || ""} />
                  <MDLabelValue label="Instagram URL" value={accountDetail?.instagram_url || ""} />
                  <MDLabelValue label="Twitter URL" value={accountDetail?.twitter_url || ""} />
                </Paper>
                <Paper elevation={3} sx={{ padding: "10px", marginTop: "5px" }}>
                  <MDTypography component="h6" fontSize="16px" fontWeight="bold">
                    Personal Website
                  </MDTypography>
                  <MDLabelValue label="Website URL" value={accountDetail?.website_url || ""} />
                  <MDLabelValue label="Booking URL" value={accountDetail?.booking_url || ""} />
                </Paper>
              </AccordionDetails>
            </Accordion>
          </MDBox>
        </Grid>
        <Grid item lg={12} md={12} sm={12} xs={12} xl={12}>
          <MDBox>
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="scheduling"
              >
                <MDTypography>Scheduling</MDTypography>
              </AccordionSummary>
              <AccordionDetails>-</AccordionDetails>
            </Accordion>
          </MDBox>
        </Grid>
        <Grid item lg={12} md={12} sm={12} xs={12} xl={12}>
          <MDBox>
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="beauty_hive"
              >
                <MDTypography>Beauty Hive</MDTypography>
              </AccordionSummary>
              <AccordionDetails>-</AccordionDetails>
            </Accordion>
          </MDBox>
        </Grid>
        <Grid item lg={12} md={12} sm={12} xs={12} xl={12}>
          <MDBox>
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="preferences"
              >
                <MDTypography>Preferences</MDTypography>
              </AccordionSummary>
              <AccordionDetails>
                <Grid container spacing={3}>
                  <Grid item>
                    <FormControl component="fieldset">
                      <FormGroup aria-label="position" row>
                        <FormControlLabel
                          value="end"
                          control={
                            <Checkbox
                              checked={accountDetail?.accepting_new_client || false}
                              name="accepting_new_client"
                              disabled
                              inputProps={{ "aria-label": "controlled" }}
                            />
                          }
                          label="Accepting new client"
                          labelPlacement="end"
                        />
                      </FormGroup>
                    </FormControl>
                  </Grid>
                  <Grid item>
                    <FormControl component="fieldset">
                      <FormGroup aria-label="position" row>
                        <FormControlLabel
                          value="end"
                          control={
                            <Checkbox
                              checked={accountDetail?.accepting_walkins || false}
                              name="accepting_walkins"
                              disabled
                              inputProps={{ "aria-label": "controlled" }}
                            />
                          }
                          label="Accepting Wals-Ins"
                          labelPlacement="end"
                        />
                      </FormGroup>
                    </FormControl>
                  </Grid>
                  <Grid item>
                    <FormControl component="fieldset">
                      <FormGroup aria-label="position" row>
                        <FormControlLabel
                          value="end"
                          control={
                            <Checkbox
                              checked={accountDetail?.visible_on_website || false}
                              name="visible_on_website"
                              disabled
                              inputProps={{ "aria-label": "controlled" }}
                            />
                          }
                          label="Wisible on Website"
                          labelPlacement="end"
                        />
                      </FormGroup>
                    </FormControl>
                  </Grid>
                </Grid>
              </AccordionDetails>
            </Accordion>
          </MDBox>
        </Grid>
      </Grid>
    </MDBox>
  );
}

export default Review;
