import { Grid, Card, FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import CustomPhoneInput from "components/CustomPhoneInput";
import { getMUISelectValue } from "helper/services";
import MESSAGES from "helper/messages";
import InputAdornment from "@mui/material/InputAdornment";
import InfoIcon from "@mui/icons-material/Info";
import Tooltip from "@mui/material/Tooltip";

function Contact(props: any): JSX.Element {
  const { solaProDetail, onChangeField, errors } = props;
  return (
    <Card id="contact">
      <MDBox p={3}>
        <MDTypography variant="h5">Contact</MDTypography>
      </MDBox>
      <MDBox pb={3} px={3}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6} md={6} lg={6}>
            <FormControl fullWidth>
              <MDInput
                variant="standard"
                fullWidth
                label="Phone Number"
                name="phone_number"
                placeholder="(000) 000-0000"
                id="phone-input"
                InputProps={{
                  inputComponent: CustomPhoneInput as any,
                }}
                value={solaProDetail?.phone_number || ""}
                onChange={onChangeField}
                error={errors && errors.phone_number ? true : false}
                helperText={errors && errors.phone_number}
                infoText={MESSAGES.INFO.OPTIONAL_LENGTH_255}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6} md={6} lg={6}>
            <FormControl fullWidth>
              <InputLabel id="phone-number-display-label">Phone Number Display</InputLabel>
              <Select
                fullWidth
                labelId="phone-number-display-label"
                className="height45"
                id="phone_number_display"
                value={solaProDetail?.phone_number_display ? 1 : 0}
                label="Phone Number Display"
                name="phone_number_display"
                onChange={onChangeField}
                endAdornment={
                  <InputAdornment position="end">
                    <Tooltip title={MESSAGES.INFO.PHONE_NUMBER_DISPLAY} arrow>
                      <InfoIcon
                        style={{
                          width: 20,
                          height: 20,
                          cursor: "pointer",
                          color: "rgba(48, 57, 65, 0.3)",
                          marginRight: "5px",
                        }}
                      />
                    </Tooltip>
                  </InputAdornment>
                }
              >
                <MenuItem value={1}>Visible</MenuItem>
                <MenuItem value={0}>Hidden</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6} md={6} lg={6}>
            <MDInput
              label="Email Address"
              variant="standard"
              fullWidth
              required
              name="email_address"
              placeholder="Email Address"
              value={solaProDetail?.email_address || ""}
              onChange={onChangeField}
              error={errors && errors.email_address ? true : false}
              helperText={errors && errors.email_address}
              infoText={MESSAGES.INFO.REQUIRED}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={6} lg={6}>
            <FormControl fullWidth>
              <InputLabel id="send-a-message-button">Send A Message Button</InputLabel>
              <Select
                fullWidth
                labelId="send-a-message-button"
                className="height45"
                id="send_a_message_button"
                name="send_a_message_button"
                value={getMUISelectValue(solaProDetail?.send_a_message_button)}
                label="Send A Message Button"
                onChange={onChangeField}
                endAdornment={
                  <InputAdornment position="end">
                    <Tooltip title={MESSAGES.INFO.SEND_MESSAGE_BUTTON} arrow>
                      <InfoIcon
                        style={{
                          width: 20,
                          height: 20,
                          cursor: "pointer",
                          color: "rgba(48, 57, 65, 0.3)",
                          marginRight: "5px",
                        }}
                      />
                    </Tooltip>
                  </InputAdornment>
                }
              >
                <MenuItem value="true">Visible</MenuItem>
                <MenuItem value="false">Hidden</MenuItem>
              </Select>
            </FormControl>
          </Grid>
        </Grid>
      </MDBox>
    </Card>
  );
}

export default Contact;
