import { useEffect, useState } from "react";
import { notificationFail, notificationSuccess } from "store/slices/notificationSlice";
import { useAppDispatch } from "store/store";
import config from "config/config";
import CustomLoader from "components/CustomLoader";
import axios from "axios";
import Messages from "helper/messages";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import MDBox from "components/MDBox";
import { Grid, AppBar, Tabs, Tab } from "@mui/material";
import constants from "helper/constants";
import MDButton from "components/MDButton";
import MDTabPanel from "components/MDTabPanel";
import MDTypography from "components/MDTypography";
import OpenLocationsMatured from "./maturedOpenLocations";
import OpenLocationsUnmatured from "./unmaturedOpenLocations";

export interface OpenLocations {
  id: string;
  open_date: string;
  store_id: string;
  name: string;
  status: string;
  city: string;
  state: string;
  contact_name: string;
  msa: string;
  entity: string;
  country: string;
}

function filterDuplicates(data: OpenLocations[]) {
  const seenStoreIds = new Set();
  const result = [];

  // eslint-disable-next-line no-restricted-syntax
  for (const entry of data) {
    const storeId = entry.store_id;
    if (!seenStoreIds.has(storeId)) {
      seenStoreIds.add(storeId);
      result.push(entry);
    }
  }

  return result;
}

function formatDate(date: Date): string {
  const year: number = date.getFullYear();
  const month: number = date.getMonth() + 1;
  const day: number = date.getDate();

  const formattedMonth: string = month < 10 ? `0${month}` : `${month}`;
  const formattedDay: string = day < 10 ? `0${day}` : `${day}`;

  const formattedDate: string = `${year}-${formattedMonth}-${formattedDay}`;

  return formattedDate;
}

function OpenLocationDashboard() {
  const [openMaturedLocation, setOpenMaturedLocation] = useState<OpenLocations[]>();
  const [openUnmaturedLocation, setOpenUnmaturedLocation] = useState<OpenLocations[]>();
  const dispatch = useAppDispatch();
  const [tabValue, setTabValue] = useState<number>(0);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [initialLoader, setinitialLoader] = useState<boolean>(false);

  const fetchOpenLocation = () => {
    try {
      setIsLoading(true);
      axios
        .get(`${config.REPORT_URL}/report/fetchOpenLocations`, {
          responseType: "json",
        })
        .then((res: any) => {
          const monthCheck: Date = new Date();
          monthCheck.setMonth(monthCheck.getMonth() - 18);
          monthCheck.setMonth(monthCheck.getMonth(), 0);
          const maturedOpenData: OpenLocations[] = res.data.result.filter(
            (item: any) =>
              item.open_date !== null &&
              item.open_date.length &&
              item.open_date <= formatDate(monthCheck)
          );
          setOpenMaturedLocation(filterDuplicates(maturedOpenData));
          const unmaturedOpenData: OpenLocations[] = res.data.result.filter(
            (item: any) =>
              item.open_date === null ||
              item.open_date === "" ||
              item.open_date > formatDate(monthCheck)
          );
          setOpenUnmaturedLocation(filterDuplicates(unmaturedOpenData));
          dispatch(notificationSuccess(res.data.message || Messages.SUCCESS.MASTER_DATA_CREATED));
        })
        .catch((err) => {
          dispatch(
            notificationFail(err?.response?.data?.message || Messages.ERROR.SOMETHING_WENT_WRONG)
          );
        })
        .finally(() => {
          setIsLoading(false);
          setinitialLoader(true);
        });
    } catch (e) {
      dispatch(notificationFail(e.message || Messages.ERROR.SOMETHING_WENT_WRONG));
    }
  };

  useEffect(() => {
    fetchOpenLocation();
  }, []);

  const changeTab = (e: any, value: number) => {
    setTabValue(value);
  };

  const getComponent = (alias: any) => {
    switch (alias) {
      case "matured_locations":
        return initialLoader ? (
          <OpenLocationsMatured openMaturedLocation={openMaturedLocation} />
        ) : null;
      case "unmatured_locations":
        return initialLoader ? (
          <OpenLocationsUnmatured openUnmaturedLocation={openUnmaturedLocation} />
        ) : null;
      default:
        return null;
    }
  };

  const maturedOpenLocationCSV = () => {
    const csvString = [
      [
        "ID",
        "open_date",
        "Sola ID",
        "Name",
        "Status",
        "City",
        "State",
        "Contact Name",
        "MSA",
        "Entity",
        "Country",
      ],
      ...openMaturedLocation.map((item) => [
        item.id,
        item.open_date,
        item.store_id,
        item.name,
        item.status,
        item.city,
        item.state,
        item.contact_name,
        item.msa,
        item.entity,
        item.country,
      ]),
    ]
      .map((row) => row.map((value) => `"${value}"`).join(","))
      .join("\n");

    const blob = new Blob([csvString], { type: "text/csv;charset=utf-8;" });
    const url = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", `Open Locations Matured.csv`);

    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const unmaturedOpenLocationCSV = () => {
    const csvString = [
      [
        "ID",
        "open_date",
        "Sola ID",
        "Name",
        "Status",
        "City",
        "State",
        "Contact Name",
        "MSA",
        "Entity",
        "Country",
      ],
      ...openUnmaturedLocation.map((item) => [
        item.id,
        item.open_date,
        item.store_id,
        item.name,
        item.status,
        item.city,
        item.state,
        item.contact_name,
        item.msa,
        item.entity,
        item.country,
      ]),
    ]
      .map((row) => row.map((value) => `"${value}"`).join(","))
      .join("\n");

    const blob = new Blob([csvString], { type: "text/csv;charset=utf-8;" });
    const url = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", `Open Locations Unmatured.csv`);

    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <>
      {isLoading && (
        <MDBox
          style={{
            background: "rgba(0,0,0,0.3)",
            zIndex: 99999,
            display: "flex",
            position: "fixed",
            alignItems: "center",
            justifyContent: "center",
            width: "100%",
            height: "100vh",
          }}
        >
          <CustomLoader />
        </MDBox>
      )}
      <DashboardLayout>
        <DashboardNavbar />
        <MDBox mt={1}>
          <Grid container sx={{ display: "flex", justifyContent: "space-between" }}>
            <Grid item xs={12} sm={8} lg={6}>
              <AppBar position="static">
                <Tabs className="scorecard-dasboard-tabs" value={tabValue} onChange={changeTab}>
                  {constants?.openLocationTabs?.map((obj: any) => (
                    <Tab label={obj.display_name} key={obj.alias} />
                  ))}
                </Tabs>
              </AppBar>
            </Grid>
            <Grid>
              <MDBox sx={{ display: "flex", justifyContent: "flex-end" }}>
                <MDBox>
                  <MDBox display="flex">
                    <MDButton variant="gradient" color="info">
                      <MDTypography
                        variant="subtitle2"
                        color="white"
                        onClick={tabValue === 1 ? unmaturedOpenLocationCSV : maturedOpenLocationCSV}
                      >
                        Export
                      </MDTypography>
                    </MDButton>
                  </MDBox>
                </MDBox>
              </MDBox>
            </Grid>
          </Grid>
          <MDBox>
            {constants?.openLocationTabs?.map((obj: any, key: number) => (
              <MDTabPanel index={key} padding={0} value={tabValue} key={obj.alias}>
                {getComponent(obj.alias)}
              </MDTabPanel>
            ))}
          </MDBox>
        </MDBox>
      </DashboardLayout>
    </>
  );
}

export default OpenLocationDashboard;
