import { useNavigate } from "react-router-dom";
import { useEffect, useRef, useState, useCallback } from "react";
import { Card, Icon } from "@mui/material";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import MDBox from "components/MDBox";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import DataTable from "examples/Tables/DataTable";
import MDTypography from "components/MDTypography";
import MDIconButton from "components/MDIconButton";
import * as React from "react";
import { useAppSelector, useAppDispatch } from "store/store";
import { getAllLocationApprovals } from "store/thunk/locationThunk";
import { userRoles, capitalizeFirstLetter, getUrlName } from "helper/services";

function Index(): JSX.Element {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [tabValue, setTabValue] = useState(0);
  const { approvals } = useAppSelector((state) => state.locationSlice);
  const { userData } = useAppSelector((state) => state.authReducer);
  const [applicationsList, setApplicationsList] = useState([]);
  const [activeStatus, setActiveStatus] = useState("new");
  const [pageCount, setPageCount] = useState(0);
  const [pageNo, setPageNo] = useState(0);
  const [totalCount, setTotalCount] = useState(0);
  const fetchIdRef = useRef(0);

  const tabStatus = [
    { name: "new", count: 0 },
    { name: "approved", count: 0 },
  ];

  useEffect(() => {
    if (approvals) {
      setPageCount(approvals?.meta?.total_pages);
      setTotalCount(approvals?.meta?.total_count);
      setApplicationsList(approvals?.connect_location_approvels?.map((obj: any) => obj));
      tabStatus?.map((status: any, key: number) => {
        const count = approvals?.connect_location_approvels?.filter(
          (obj: any) => obj?.request_status === status.name
        ).length;
        tabStatus[key].count = count;
        return tabStatus;
      });
    }
  }, [approvals]);

  const actionButtons = (locationName: string, id: any) => (
    <MDIconButton
      tooltipName="View approval details"
      aria-label="Open"
      color="info"
      onClick={() =>
        navigate(`/locations/approval/${getUrlName(locationName)}`, {
          state: {
            prevPath: "/locations/approval",
            listData: applicationsList.find((obj) => obj.approval_id === id),
          },
        })
      }
    >
      <Icon fontSize="small">arrow_forward_icon</Icon>
    </MDIconButton>
  );

  const getRows = () => {
    if (applicationsList) {
      const data: any = applicationsList?.map((obj: any) => ({
        name: obj?.approval_data?.old_value?.name || "-",
        request_status: obj?.request_status ? capitalizeFirstLetter(obj?.request_status) : "-",
        owned_by: obj?.approval_data?.new_value?.who_changed?.first_name
          ? obj?.approval_data?.new_value?.who_changed?.first_name
          : "-",
        phone: obj?.approval_data?.old_value?.phone_number || "-",
        email:
          obj?.approval_data?.old_value?.general_contact_email ||
          obj?.approval_data?.old_value?.email_address_for_inquiries ||
          "-",
        action: actionButtons(obj?.approval_data?.old_value?.name, obj.approval_id),
      }));
      return data;
    }
    return [];
  };

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setActiveStatus(tabStatus[newValue]?.name);
    setTabValue(newValue);
    dispatch(getAllLocationApprovals({ activeStatus: tabStatus[newValue]?.name }));
  };

  const fetchData = useCallback(({ pageSize, pageIndex, search, sortBy }) => {
    const fetchId = ++fetchIdRef.current;
    const sort: any = sortBy || [];
    // We'll even set a delay to simulate a server here
    setTimeout(() => {
      if (fetchId === fetchIdRef.current) {
        setPageNo(pageIndex);
        dispatch(getAllLocationApprovals({ pageSize, pageIndex, search, sort, activeStatus }));
      }
    }, 1000);
  }, []);

  const getColumns = () => {
    const columns: any = [
      { Header: "Location name", accessor: "name" },
      { Header: "Request Status", accessor: "request_status" },
      { Header: "Owned By", accessor: "owned_by" },
      { Header: "Phone", accessor: "phone" },
      { Header: "Email", accessor: "email" },
    ];
    if (userData?.role === userRoles.SYSTEM_ADMIN || userData?.role === userRoles.ADMIN) {
      columns.push({ Header: "Action", accessor: "action" });
    }
    return columns;
  };

  const reviewTableData = {
    columns: getColumns(),
    rows: getRows(),
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDTypography variant="subtitle2" style={{ margin: "15px" }}>
        Used for the upkeep of Google My Business & Yext
      </MDTypography>
      <MDBox pt={4} pb={3}>
        <Card>
          <MDBox
            bgColor="dark"
            color="white"
            coloredShadow="dark"
            borderRadius="xl"
            alignItems="center"
            justifyContent="space-between"
            pt={1}
            pb={1}
            pl={2}
            pr={2}
            ml={2}
            mr={2}
            display="flex"
            mt={-3}
          >
            Needs Review
          </MDBox>
          <MDBox
            borderRadius="xl"
            alignItems="center"
            pt={1}
            pb={1}
            pl={2}
            pr={2}
            ml={2}
            mr={2}
            mt={2}
          >
            <Tabs
              className="location-tabs"
              indicatorColor="secondary"
              classes={{ indicator: "info" }}
              value={tabValue}
              onChange={handleChange}
            >
              <Tab
                label={
                  <MDBox py={0.5} px={2} color="inherit">
                    New
                  </MDBox>
                }
              />
              <Tab
                label={
                  <MDBox py={0.5} px={2} color="inherit">
                    Approved
                  </MDBox>
                }
              />
              <Tab
                label={
                  <MDBox py={0.5} px={2} color="inherit">
                    View All
                  </MDBox>
                }
              />
            </Tabs>
          </MDBox>
          <DataTable
            table={reviewTableData}
            pageCount={pageCount}
            pageNo={pageNo}
            totalCount={totalCount}
            fetchData={fetchData}
            canSearch
            manualSearch
            searchText="Search...."
          />
        </Card>
      </MDBox>
    </DashboardLayout>
  );
}

export default Index;
