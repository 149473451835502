import MDDialog from "components/MDDialog";
import MDTypography from "components/MDTypography";
import MDBox from "components/MDBox";
import CustomChipList from "components/CustomChipList";
// import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Grid, AccordionSummary, Accordion, AccordionDetails, Icon } from "@mui/material";
import { useAppSelector } from "store/store";

function ViewDetails(props: any): JSX.Element {
  const { open, onClose } = props;
  const { video } = useAppSelector((state) => state.mobileAppSlice);

  const dialogProps = {
    open,
    onClose,
    saveBtn: false,
    title: "View Video",
    size: "md",
  };

  const getIcon = (value: any) => {
    if (value) {
      return (
        <Icon fontSize="medium" color="success">
          check_circle_rounded
        </Icon>
      );
    }
    return (
      <Icon fontSize="medium" color="error">
        cancel
      </Icon>
    );
  };

  const getIconElement = (name: any, iconValue: any) => {
    const value: any = iconValue;
    return (
      <Grid item xs={12} sm={6} md={6}>
        <MDTypography fontWeight="bold" variant="button">
          {name}
        </MDTypography>
        <MDBox>{getIcon(value)}</MDBox>
      </Grid>
    );
  };

  const getTypograpghy = (label: any, value: any) => (
    <Grid item xs={12} sm={12} md={12} lg={6}>
      <MDTypography fontWeight="bold" variant="button">
        {label}
      </MDTypography>
      <MDBox>
        <MDTypography variant="button">{value || "-"}</MDTypography>
      </MDBox>
    </Grid>
  );

  const getAccordianSummary = (label: any) => (
    <AccordionSummary
      className="location-accordion"
      // expandIcon={<ExpandMoreIcon />}
      aria-controls="publishbh-content"
    >
      <MDTypography variant="h6">{label}</MDTypography>
    </AccordionSummary>
  );

  const getChipList = (label: any, key: any) =>
    video?.[key]?.length > 0 && (
      <Accordion expanded>
        {getAccordianSummary(label)}
        <AccordionDetails>
          <CustomChipList
            listData={video?.[key]
              ?.map((obj: any) => ({
                ...obj,
                title: obj?.name || null,
              }))
              .filter((f: any) => f?.title !== null)}
          />
        </AccordionDetails>
      </Accordion>
    );

  return (
    <MDDialog {...dialogProps}>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12} md={12}>
          <Accordion expanded>
            {getAccordianSummary("Basic Info")}
            <AccordionDetails>
              <Grid container spacing={2}>
                {getTypograpghy("Title", video?.title || "-")}
                {getIconElement("Webinar", video?.webinar || false)}
              </Grid>
              <Grid container spacing={2}>
                {getTypograpghy("Youtube Url", video?.youtube_url || "-")}
                {getTypograpghy("Duration", video?.duration || "-")}
              </Grid>
              <Grid container spacing={2}>
                {getTypograpghy("Related Tool Or Resource", video?.tool_title || "-")}
                {getTypograpghy("Brand", video?.brand_name || "-")}
              </Grid>
              <Grid container spacing={2}>
                {getTypograpghy("Is Featured", video?.is_featured ? "Yes" : "No")}
                {getTypograpghy("Is Introduction", video?.is_introduction ? "Yes" : "No")}
              </Grid>
            </AccordionDetails>
          </Accordion>
          {getChipList("Categories", "categories")}
          {getChipList("Tags", "tags")}
          {getChipList("Countries", "countries")}
        </Grid>
      </Grid>
    </MDDialog>
  );
}

export default ViewDetails;
