import React, { useEffect, useState } from "react";
import MDDialog from "components/MDDialog";
import { Autocomplete, Checkbox, Grid, Icon, TextField } from "@mui/material";
import MDInput from "components/MDInput";
import MDTypography from "components/MDTypography";
import CustomDropZone from "components/CustomDropZone";
import MDIconButton from "components/MDIconButton";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import { useAppDispatch, useAppSelector } from "store/store";
import { createBrand, getBrand, updateBrand } from "store/thunk/mobileAppThunk";
import ImagePreview from "layouts/pages/ImagePreview";
import Validations from "helper/validations";
import CustomErrorMessage from "components/CustomErrorMessage";

const linksData = [
  { name: "link1", id: 1 },
  { name: "link2", id: 2 },
];

function AddUpdate(props: any): JSX.Element {
  const dispatch = useAppDispatch();
  const { countriesList } = useAppSelector((state) => state.systemAdminSlice);
  const { brandDetails } = useAppSelector((state: any) => state.mobileAppSlice);
  const { open, onClose, editBrandId, pageNo, perPage, search, sortBy } = props;
  const [loading] = useState<boolean>(false);
  const [brandsData, setBrandsData] = useState<any>({});
  const [imagePreview, setImagePreview] = useState<any>("");
  const [expanded, setExpanded] = React.useState<string | false>(false);
  const [countriesListData, setCountriesListData] = useState<any>([]);
  const [selectedCountries, setSelectedCountries] = useState<any>([]);
  const [errors, setErrors] = useState<any>({});

  const [isPreview, setImagePreviewPop] = useState(false);
  const [imageToPreview, setImageToPreview] = useState("");

  useEffect(() => {
    if (editBrandId) {
      dispatch(getBrand({ id: editBrandId }));
    }
  }, []);

  useEffect(() => {
    if (countriesList?.countries) {
      setCountriesListData(countriesList.countries);
    }
  }, [countriesList]);

  useEffect(() => {
    if (editBrandId && Object.keys(brandDetails).length) {
      setBrandsData({
        id: editBrandId && brandDetails.id,
        name: brandDetails.name,
        website_url: brandDetails.website_url,
        links: brandDetails.links,
        introduction_video_heading_title: brandDetails.introduction_video_heading_title,
        events_and_classes_heading_title: brandDetails.events_and_classes_heading_title,
        countries:
          brandDetails.countries_name && brandDetails.countries_name.map((val: any) => val.id),
      });
      const tempArray: any = [];
      const countryArray = brandDetails.countries_name.filter(
        (v: any, i: any, a: any) => a.findIndex((v2: any) => v2.id === v.id) === i
      );
      countryArray.forEach((val: any) => {
        const getFilter = countriesList.countries.find((v: any) => v.id === val.id);
        if (getFilter) {
          tempArray.push(getFilter);
        }
      });
      setSelectedCountries(tempArray);
      setImagePreview(brandDetails.image_url);
    }
  }, [brandDetails]);

  const openAccordian = (accordianElementId: any, accordianExpandName: any) => {
    const element = document.getElementById(accordianElementId);
    element.addEventListener("change", () => {
      setExpanded(accordianExpandName);
    });
    const event = new Event("change", {
      bubbles: true,
      cancelable: true,
      composed: false,
    });
    element.dispatchEvent(event);
  };

  const onSave = () => {
    const error = Validations.validateBrandsForm(brandsData);
    if (error?.introduction_video_heading_title || error?.events_and_classes_heading_title) {
      openAccordian("settings-accordian", "settings");
    }
    setErrors(error);
    if (!Object.keys(error).length) {
      const req = {
        brandsData,
        onClose,
        pageNo,
        perPage,
        search,
        sortBy,
      };
      if (editBrandId) {
        dispatch(updateBrand(req));
      } else {
        dispatch(createBrand(req));
      }
    }
  };

  const onChangeField = (e: any, field: string) => {
    setBrandsData((prev: any) => ({
      ...prev,
      [field]: e.target.value,
    }));
  };

  const onChangeImage = async (files: any, type: any) => {
    if (files[0]) {
      setBrandsData({ ...brandsData, [type]: files[0], delete_image: 0 });
      const file = files[0];
      const reader = new FileReader();
      reader.onload = function (e) {
        if (type === "image") {
          setImagePreview(e.target.result);
        }
      };
      reader.readAsDataURL(file);
    }
  };

  const onImageRemove = async (e: any, type: any) => {
    e.stopPropagation();
    if (type === "image") {
      setBrandsData({ ...brandsData, [type]: null, delete_image: 1 });
      setImagePreview("");
    }
  };

  const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
  const checkedIcon = <CheckBoxIcon fontSize="small" />;

  const handleChangeAccordian =
    (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : false);
    };

  const previewImage = (image: any) => {
    setImagePreviewPop(true);
    setImageToPreview(image);
  };

  const closeImagePreview = () => {
    setImagePreviewPop(false);
    setImageToPreview("");
  };

  const dialogProps = {
    open,
    onClose,
    onSave,
    saveTbtText: editBrandId ? "Update" : "Save",
    title: editBrandId ? "Update Brands" : "Add Brands",
    size: "lg",
    loading,
  };

  return (
    <MDDialog {...dialogProps}>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12} md={6} lg={6}>
          <MDInput
            label="Name"
            variant="standard"
            fullWidth
            required
            sx={{ margin: "5px 0px" }}
            name="name"
            placeholder=""
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => onChangeField(e, "name")}
            value={brandsData?.name || ""}
            error={errors && errors.name ? true : false}
            helperText={errors && errors.name}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6}>
          <MDInput
            label="Website Url"
            variant="standard"
            fullWidth
            sx={{ margin: "5px 0px" }}
            name="website_url"
            placeholder=""
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => onChangeField(e, "website_url")}
            value={brandsData?.website_url || ""}
            error={errors && errors.website_url ? true : false}
            helperText={errors && errors.website_url}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <MDTypography component="label" variant="button" fontWeight="regular" color="text">
            Color Image
          </MDTypography>
          <Grid container>
            <Grid item xs={12} sm={8} md={6} lg={6}>
              <CustomDropZone
                multipleUpload={false}
                acceptedFiles={{
                  "image/*": [".gif", ".png", ".jpg", ".jpeg"],
                }}
                onDrop={(e: any) => onChangeImage(e, "image")}
              >
                Drop your image here, or Browse
              </CustomDropZone>
            </Grid>
            <Grid item xs={12} sm={4} md={6} lg={6}>
              {imagePreview && (
                <Grid
                  onClick={() => previewImage(imagePreview)}
                  container
                  justifyContent="center"
                  alignItems="center"
                  display="flex"
                >
                  <Grid item className="imageWithDeleteIcon">
                    <img height={100} width={100} src={imagePreview} alt="article" />
                    <MDIconButton
                      tooltipName="Delete"
                      aria-label="Delete"
                      className="removeBtnClass"
                      onClick={(e: any) => onImageRemove(e, "image")}
                    >
                      <Icon fontSize="small">close</Icon>
                    </MDIconButton>
                  </Grid>
                </Grid>
              )}
            </Grid>
            {errors && errors.image && <CustomErrorMessage message={errors.image} />}
          </Grid>
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6}>
          <Autocomplete
            sx={{ margin: "7px 0px" }}
            id="links"
            options={linksData}
            getOptionLabel={(option) => option.name}
            onChange={(event: any, newValue: any) => {
              setBrandsData({ ...brandsData, links: newValue });
            }}
            renderOption={(props: any, option: any) => (
              <li {...props}>
                {/* <Checkbox
                  icon={icon}
                  checkedIcon={checkedIcon}
                  style={{ marginRight: 8 }}
                  checked={selected}
                /> */}
                {option.name}
              </li>
            )}
            renderInput={(params: any) => (
              <TextField {...params} variant="standard" label="Links" placeholder="" />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6}>
          <Autocomplete
            multiple
            sx={{ margin: "7px 0px" }}
            id="countries"
            options={countriesListData}
            disableCloseOnSelect
            getOptionLabel={(option) => option?.name || option}
            value={selectedCountries}
            onChange={(event: any, newValue: any) => {
              const isselected = newValue.map((val: any) => val.id);
              setBrandsData({ ...brandsData, countries: isselected });
              setSelectedCountries(newValue);
            }}
            renderOption={(props: any, option: any, { selected }: any) => (
              <li {...props}>
                <Checkbox
                  icon={icon}
                  checkedIcon={checkedIcon}
                  style={{ marginRight: 8 }}
                  checked={selected}
                />
                {option.name}
              </li>
            )}
            renderInput={(params: any) => (
              <TextField
                {...params}
                variant="standard"
                label="Countries"
                placeholder=""
                error={errors && errors.countries ? true : false}
                helperText={errors && errors.countries}
                required
              />
            )}
          />
        </Grid>
        {/* <Grid container>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            
          </Grid>
        </Grid> */}
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <Accordion
            expanded={expanded === "settings"}
            sx={{ marginTop: "20px" }}
            id="settings-accordian"
            onChange={handleChangeAccordian("settings")}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="settings-content"
              id="settings-header"
              className="location-accordion"
            >
              <MDTypography variant="h6">Settings</MDTypography>
            </AccordionSummary>
            <AccordionDetails>
              <Grid container>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <MDInput
                    label="Introduction Video Heading Title"
                    variant="standard"
                    fullWidth
                    sx={{ margin: "5px 0px" }}
                    name="introduction_video_heading_title"
                    placeholder=""
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                      onChangeField(e, "introduction_video_heading_title")
                    }
                    value={brandsData?.introduction_video_heading_title || ""}
                    error={errors && errors.introduction_video_heading_title ? true : false}
                    helperText={errors && errors.introduction_video_heading_title}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <MDInput
                    label="Events And Classes Heading Title"
                    variant="standard"
                    fullWidth
                    sx={{ margin: "5px 0px" }}
                    name="events_and_classes_heading_title"
                    placeholder=""
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                      onChangeField(e, "events_and_classes_heading_title")
                    }
                    value={brandsData?.events_and_classes_heading_title || ""}
                    error={errors && errors.events_and_classes_heading_title ? true : false}
                    helperText={errors && errors.events_and_classes_heading_title}
                  />
                </Grid>
              </Grid>
            </AccordionDetails>
          </Accordion>
        </Grid>
      </Grid>
      <ImagePreview
        imageData={imageToPreview}
        open={isPreview}
        onCloseImage={() => {
          closeImagePreview();
        }}
      />
    </MDDialog>
  );
}

export default AddUpdate;
