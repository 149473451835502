import { useEffect, useState } from "react";

// @mui material components
import { Card } from "@mui/material";

import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import TimezoneSelect from "react-timezone-select";
import { updatePreferences } from "store/thunk/authThunk";
import { useAppDispatch } from "store/store";
import { getUserTimeZone } from "helper/services";

function Index({ userData }: any): JSX.Element {
  const dispatch = useAppDispatch();
  const [timeZone, setTimeZone] = useState("");

  useEffect(() => {
    if (userData) {
      setTimeZone(getUserTimeZone(userData));
    }
  }, [userData]);

  const changeTimeZone = (value: any) => {
    dispatch(
      updatePreferences({
        user_id: userData.user_id,
        preferences: [{ preference_type: "TIMEZONE", preference_value: value?.value || "" }],
      })
    );
  };

  return (
    <Card sx={{ boxShadow: "none" }}>
      <MDBox pt={1} pb={2} lineHeight={1.25}>
        <MDTypography variant="caption" fontWeight="regular" color="text">
          Time zone
        </MDTypography>
        <MDBox mt={1} fontSize={14}>
          <TimezoneSelect value={timeZone} onChange={(value: any) => changeTimeZone(value)} />
        </MDBox>
      </MDBox>
    </Card>
  );
}

export default Index;
