import { useEffect, useRef, useState, useCallback } from "react";
import { Card, Icon } from "@mui/material";
import MDBox from "components/MDBox";
import DataTable from "examples/Tables/DataTable";
import MDIconButton from "components/MDIconButton";
import { useAppSelector, useAppDispatch } from "store/store";
import { getRMNewSmsRequestList } from "store/thunk/repairMaintenance";
import { setSMSRequestDetailData } from "store/slices/repairMaintenanceSlice";
import { capitalizeFirstLetter, getLocalDateTime, userRoles } from "helper/services";
import AssignPopup from "./assignPopup";
import ViewPopup from "./smsRequestViewPopup";

function Index(): JSX.Element {
  const dispatch = useAppDispatch();
  const { smsRequestList } = useAppSelector((state) => state.repairMaintenanceSlice);
  const { userData } = useAppSelector((state) => state.authReducer);
  const [smsRequestListData, setSmsRequestList] = useState([]);
  const [pageCount, setPageCount] = useState(0);
  const [pageNo, setPageNo] = useState(0);
  const [totalCount, setTotalCount] = useState(0);
  const [smsRequestData, setSmsRequestData] = useState(null);
  const [listingRequestData, setRequestListingData] = useState(null);
  const [viewPopup, setViewPopup] = useState(null);
  const fetchIdRef = useRef(0);

  useEffect(() => {
    if (smsRequestList) {
      setPageCount(smsRequestList?.meta?.total_pages);
      setTotalCount(smsRequestList?.meta?.total_count);
      setSmsRequestList(smsRequestList?.connect_rm_twillio_sms_request?.map((obj: any) => obj));
    }
  }, [smsRequestList]);
  const openAssignPopup = (data: any) => {
    setSmsRequestData(data);
  };

  const actionButtons = (obj: any) => (
    <>
      <MDIconButton
        onClick={() => openAssignPopup(obj)}
        tooltipName={
          obj?.status === "active"
            ? "The phone number is already assigned to this location you can remove/disabled it from twillio number tab."
            : "Assign New Phone Number To Location"
        }
        aria-label="Open"
        disabled={obj?.status === "active" ? true : false}
        color="info"
      >
        <Icon fontSize="small">app_settings_alt</Icon>
      </MDIconButton>
      <MDIconButton
        onClick={() => setViewPopup(obj)}
        tooltipName="View"
        aria-label="View"
        color="info"
      >
        <Icon fontSize="small">visibility</Icon>
      </MDIconButton>
    </>
  );

  const closeViewPopup = () => {
    setViewPopup(null);
    dispatch(setSMSRequestDetailData(null));
  };

  const getRows = () => {
    if (smsRequestListData) {
      const data: any = smsRequestListData?.map((obj: any) => ({
        id: obj?.id || "-",
        location_id: obj?.location_id || "-",
        location_name: obj?.location?.name || "-",
        status: obj?.status ? capitalizeFirstLetter(obj?.status) : "-",
        created_at: obj.created_at ? getLocalDateTime(obj.created_at) : "-",
        action: actionButtons(obj),
      }));
      return data;
    }
    return [];
  };

  const fetchData = useCallback(({ pageSize, pageIndex, search, sortBy }) => {
    const fetchId = ++fetchIdRef.current;
    const sort: any = sortBy || [];
    // We'll even set a delay to simulate a server here
    setTimeout(() => {
      if (fetchId === fetchIdRef.current) {
        setPageNo(pageIndex);
        const obj = {
          pageSize,
          pageIndex,
          search,
          sort,
        };
        setRequestListingData(obj);
        dispatch(getRMNewSmsRequestList({ pageSize, pageIndex, search, sort }));
      }
    }, 1000);
  }, []);

  const getColumns = () => {
    const columns: any = [
      { Header: "ID", accessor: "id" },
      { Header: "Location Id", accessor: "location_id" },
      { Header: "Location Name", accessor: "location_name" },
      { Header: "Status", accessor: "status" },
      { Header: "Created At", accessor: "created_at" },
    ];
    if (userData?.role === userRoles.SYSTEM_ADMIN) {
      columns.push({ Header: "Action", accessor: "action" });
    }
    return columns;
  };

  const reviewTableData = {
    columns: getColumns(),
    rows: getRows(),
  };

  return (
    <MDBox>
      <Card>
        <DataTable
          table={reviewTableData}
          pageCount={pageCount}
          pageNo={pageNo}
          totalCount={totalCount}
          fetchData={fetchData}
          canSearch
          manualSearch
          searchText="Search...."
        />
        {smsRequestData && (
          <AssignPopup
            open={smsRequestData}
            listingRequestData={listingRequestData}
            onClose={() => setSmsRequestData(null)}
            smsRequestData={smsRequestData}
          />
        )}
        {viewPopup && <ViewPopup onClose={closeViewPopup} detailData={viewPopup} />}
      </Card>
    </MDBox>
  );
}

export default Index;
