import Grid from "@mui/material/Grid";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDLabelValue from "components/MDLabelValue";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Checkbox from "@mui/material/Checkbox";
import moment from "moment";

function Index(props: any): JSX.Element {
  const { leaseDetails, onChangeRadio } = props;
  return (
    <MDBox>
      <MDBox textAlign="center" mx="auto" my={4}>
        <MDBox mb={1}>
          <MDTypography variant="h5" fontWeight="regular">
            Review
          </MDTypography>
        </MDBox>
      </MDBox>
      <MDBox>
        <Grid container spacing={2}>
          <Grid item lg={12} md={12} sm={12} xs={12} xl={12}>
            <MDBox>
              <Accordion>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="personal-contact"
                >
                  <MDTypography>Personal Contact</MDTypography>
                </AccordionSummary>
                <AccordionDetails>
                  <MDLabelValue label="First name" value={leaseDetails?.first_name || ""} />
                  <MDLabelValue label="Last name" value={leaseDetails?.last_name || ""} />
                  <MDLabelValue label="Email" value={leaseDetails?.email || ""} />
                  <MDLabelValue label="Phone" value={leaseDetails?.phone || ""} />
                  <MDLabelValue
                    label="Date of Birth"
                    value={
                      (leaseDetails?.dob && moment(leaseDetails.dob).format("MMM DD, YYYY")) || ""
                    }
                  />
                  <MDLabelValue
                    label="Emergency Contact Name"
                    value={leaseDetails?.emergency_contact_name || ""}
                  />
                  <MDLabelValue
                    label="Emergency Relationship"
                    value={leaseDetails?.emergency_relationship || ""}
                  />
                  <MDLabelValue
                    label="Emergency Phone Number"
                    value={leaseDetails?.emergency_phonenumber || ""}
                  />
                  <MDLabelValue
                    label="Professional License Number"
                    value={leaseDetails?.professional_license_lumber || ""}
                  />
                  <MDLabelValue label="Services" value={leaseDetails?.services?.join() || ""} />
                  <MDLabelValue label="Referred By" value={leaseDetails?.referred_by || ""} />
                  <MDLabelValue
                    label="How Did You Hear About Sola?"
                    value={leaseDetails?.hear_about_sola || ""}
                  />
                </AccordionDetails>
              </Accordion>
            </MDBox>
          </Grid>
          <Grid item lg={12} md={12} sm={12} xs={12} xl={12}>
            <MDBox>
              <Accordion>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="mailing"
                >
                  <MDTypography>Mailing</MDTypography>
                </AccordionSummary>
                <AccordionDetails>
                  <MDLabelValue label="Address 1" value={leaseDetails?.mailing_address_1 || ""} />
                  <MDLabelValue label="Address 2" value={leaseDetails?.mailing_address_2 || ""} />
                  <MDLabelValue label="City" value={leaseDetails?.mailing_city || ""} />
                  <MDLabelValue label="State" value={leaseDetails?.mailing_state || ""} />
                  <MDLabelValue label="ZIP" value={leaseDetails?.mailing_zip || ""} />
                </AccordionDetails>
              </Accordion>
            </MDBox>
          </Grid>
          <Grid item lg={12} md={12} sm={12} xs={12} xl={12}>
            <MDBox>
              <Accordion>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="billing-information"
                >
                  <MDTypography>Billing Information</MDTypography>
                </AccordionSummary>
                <AccordionDetails>
                  <MDLabelValue label="Billing Type" value={leaseDetails?.billing_type || ""} />
                  {leaseDetails?.billing_type === "card" && (
                    <>
                      <MDLabelValue label="Card name" value={leaseDetails?.card_name || ""} />
                      <MDLabelValue
                        label="Card number"
                        value={leaseDetails?.billing_card_number || ""}
                      />
                      <MDLabelValue label="MM/YY" value={leaseDetails?.card_exp_mm_yy || ""} />
                      <MDLabelValue label="CVC" value={leaseDetails?.cvc || ""} />
                    </>
                  )}
                  {leaseDetails?.billing_type === "bank_transfer" && (
                    <>
                      <MDLabelValue label="Bank name" value={leaseDetails?.bank_name || ""} />
                      <MDLabelValue
                        label="Account number"
                        value={leaseDetails?.bank_account || ""}
                      />
                      <MDLabelValue label="Routing" value={leaseDetails?.bank_routing || ""} />
                    </>
                  )}
                </AccordionDetails>
              </Accordion>
            </MDBox>
          </Grid>
          <Grid item lg={12} md={12} sm={12} xs={12} xl={12}>
            <MDBox>
              <Accordion>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="billing-address"
                >
                  <MDTypography>Billing Address</MDTypography>
                </AccordionSummary>
                <AccordionDetails>
                  <MDLabelValue label="Address 1" value={leaseDetails?.billing_address_1 || ""} />
                  <MDLabelValue label="Address 2" value={leaseDetails?.billing_address_2 || ""} />
                  <MDLabelValue label="City" value={leaseDetails?.billing_city || ""} />
                  <MDLabelValue label="State" value={leaseDetails?.billing_state || ""} />
                  <MDLabelValue label="ZIP" value={leaseDetails?.billing_zip || ""} />
                </AccordionDetails>
              </Accordion>
            </MDBox>
          </Grid>
          <Grid item lg={12} md={12} sm={12} xs={12} xl={12}>
            <MDBox>
              <Accordion>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="lease-summary"
                >
                  <MDTypography>Lease Summary</MDTypography>
                </AccordionSummary>
                <AccordionDetails>
                  <MDLabelValue label="Location" value="Location 1" />
                  <MDLabelValue label="Location Address" value="Address" />
                  <MDLabelValue label="Unit Number" value="Unit Number" />
                  <MDLabelValue label="Payment Type" value="Payment Type" />
                  <MDLabelValue label="First Payment Due" value="-" />
                  <MDLabelValue label="Payment Amount:" value="$xxx" />
                </AccordionDetails>
              </Accordion>
            </MDBox>
          </Grid>
          <Grid item lg={12} md={12} sm={12} xs={12} xl={12}>
            <MDBox>
              <Accordion>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="lease-agreement"
                >
                  <MDTypography>Lease Agreement</MDTypography>
                </AccordionSummary>
                <AccordionDetails>
                  <MDLabelValue
                    label="Digital Signature"
                    value={leaseDetails?.digital_signature || ""}
                  />
                </AccordionDetails>
              </Accordion>
            </MDBox>
          </Grid>
          <Grid item lg={12} md={12} sm={12} xs={12} xl={12}>
            <MDTypography variant="h6" fontWeight="regular" fontSize="14px">
              <Checkbox
                onChange={onChangeRadio}
                name="agreement"
                sx={{ padding: 0, paddingRight: 1 }}
              />
              By checking this box applicant(s) hereby declares that all information provided on
              this Application is complete, true, and correct to the best of his/her/their
              knowledge. Applicant(s) hereby authorizes the owner, manager, or his/her/their agent
              (hereinafter &quot;Landlord&quot;) to verify any information at any time contained in
              this application. This application does not obligate Landlord to execute a rental
              agreement or deliver possession of the premises. Applicant(s) further acknowledges
              that any false or fraudulent information contained herein will void this application
              and terminate any rental agreement.
            </MDTypography>
          </Grid>
        </Grid>
      </MDBox>
    </MDBox>
  );
}

export default Index;
