import { useEffect, useState } from "react";
import Icon from "@mui/material/Icon";
import IconButton from "@mui/material/IconButton";
import Grid from "@mui/material/Grid";
import ButtonGroup from "@mui/material/ButtonGroup";
import Container from "@mui/material/Container";
import TwitterIcon from "@mui/icons-material/Twitter";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import FacebookIcon from "@mui/icons-material/Facebook";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDProgress from "components/MDProgress";
import MDTypography from "components/MDTypography";
import PageLayout from "examples/LayoutContainers/PageLayout";
import Validations from "helper/validations";
import Step1 from "layouts/pages/rockbot/steps/step1";
import Step2 from "layouts/pages/rockbot/steps/step2";
import Step3 from "layouts/pages/rockbot/steps/step3";
import { useAppDispatch, useAppSelector } from "store/store";
import { getRockbot, addRockbot } from "store/thunk/rockbotThunk";
import { publicIpv4 } from "public-ip";

function Index(): JSX.Element {
  const dispatch = useAppDispatch();
  const { answerData } = useAppSelector((state) => state.rockbotSlice);
  const [activeStep, setActiveStep] = useState(0);
  const [answers, setAnserws] = useState<any>({});
  const [thanks, setThanks] = useState<boolean>(false);
  const [errors, setErrors] = useState<any>({});
  const [ipAddress, setIpAddress] = useState<string>("");
  const [start, setStart] = useState<boolean>(false);
  const isLastStep: boolean = activeStep === 2;

  useEffect(() => {
    try {
      publicIpv4().then((ip) => setIpAddress(ip));
    } catch (e) {
      console.log(e);
    }
  }, []);

  useEffect(() => {
    if (ipAddress) {
      dispatch(getRockbot({ ip_address: ipAddress }));
    }
  }, [ipAddress]);

  useEffect(() => {
    if (answerData) {
      setAnserws(answerData);
    }
  }, [answerData]);

  const handleNext = () => {
    const error = Validations.validateRockbotForm({ ...answers, step: activeStep });
    setErrors(error);
    if (!Object.keys(error).length && !isLastStep) {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }
    if (isLastStep) {
      if (ipAddress) {
        const objData = {
          data: { answers, ip_address: ipAddress },
          callback: () => {
            setThanks(true);
            setStart(false);
          },
        };
        dispatch(addRockbot(objData));
      }
    }
  };

  const tryAgain = () => {
    setThanks(false);
    setStart(true);
    setActiveStep(0);
  };
  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const onChangeField = (event: any) => {
    setAnserws({ ...answers, [event.target.name]: event.target.value });
  };

  const onEnter = (e: any) => {
    e.preventDefault();
    setStart(true);
  };

  const onChangeCheckbox = (event: any) => {
    const tempAnswers = JSON.parse(JSON.stringify(answers));
    let tempValue = tempAnswers?.[event.target.name] || [];
    if (event.target.checked) {
      if (!tempValue.includes(event.target.value)) {
        tempValue.push(event.target.value);
      } else {
        tempValue = [event.target.value];
      }
    } else {
      tempValue = tempValue.filter((val: any) => val !== event.target.value);
    }
    setAnserws({ ...tempAnswers, [event.target.name]: tempValue });
  };

  const onChangeFieldValue = (field: string, value: any) => {
    setAnserws({ ...answers, [field]: value });
  };

  function getStepContent(stepIndex: number): JSX.Element {
    const attrs = {
      onChangeField,
      onChangeCheckbox,
      onChangeFieldValue,
      handleNext,
      answers,
      errors,
    };
    switch (stepIndex) {
      case 0:
        return <Step1 {...attrs} />;
      case 1:
        return <Step2 {...attrs} />;
      case 2:
        return <Step3 {...attrs} />;
      default:
        return null;
    }
  }

  return (
    <PageLayout width="100%">
      <MDBox mx="auto" bgColor="info" height="100vh" variant="gradient" overflow="auto">
        {!start ? (
          <MDBox>
            <Grid container spacing={1} justifyContent="center">
              <Grid item xs={11} sm={9} md={6} lg={6} xl={6}>
                <MDBox component="form" role="form" onSubmit={(e) => onEnter(e)}>
                  <MDBox mt={8} textAlign="center">
                    <MDBox
                      component="img"
                      src="https://dobyu981wavlb.cloudfront.net/assets/Sola_Logo_New@2x-7821741c6fad6a04a1d7830a328ecb4d.png"
                      height="120px"
                      width="180px"
                    />
                    {thanks ? (
                      <MDBox mt={5}>
                        <MDTypography
                          variant="h4"
                          component="span"
                          color="white"
                          mt={1}
                          style={{ fontWeight: "normal" }}
                        >
                          Thank you for filling this out, we&apos;ll reach out to everyone in 2-3
                          business days with your order form for completion.
                        </MDTypography>
                        <MDBox>
                          <IconButton
                            aria-label="facebook"
                            size="large"
                            href="https://www.facebook.com/sharer/sharer.php?u=https%3A%2F%2Frockbot1.typeform.com%2Fto%2FFBmXrv"
                            target="_blank"
                          >
                            <FacebookIcon color="inherit" />
                          </IconButton>
                          <IconButton
                            aria-label="twitter"
                            size="large"
                            href="https://twitter.com/intent/tweet?url=https%3A%2F%2Frockbot1.typeform.com%2Fto%2FFBmXrv"
                            target="_blank"
                          >
                            <TwitterIcon color="inherit" />
                          </IconButton>
                          <IconButton
                            aria-label="linked in"
                            size="large"
                            href="https://www.linkedin.com/shareArticle?url=https%3A%2F%2Frockbot1.typeform.com%2Fto%2FFBmXrv"
                            target="_blank"
                          >
                            <LinkedInIcon color="inherit" />
                          </IconButton>
                        </MDBox>
                        <MDBox mt={2}>
                          <MDButton
                            type="button"
                            variant="gradient"
                            color="light"
                            onClick={tryAgain}
                          >
                            Again
                          </MDButton>
                        </MDBox>
                      </MDBox>
                    ) : (
                      <MDBox mt={5}>
                        <MDTypography
                          variant="h4"
                          component="span"
                          color="white"
                          mt={1}
                          style={{ fontWeight: "normal" }}
                        >
                          Please fill out the information below to help us process your order,
                        </MDTypography>
                        <MDTypography
                          variant="h4"
                          color="white"
                          component="span"
                          fontWeight="normal"
                          my={1}
                        >
                          ensuring to complete 1 order for each location.
                        </MDTypography>
                        <MDTypography
                          variant="h5"
                          color="white"
                          my={1}
                          mt={3}
                          style={{ fontWeight: "normal", color: "rgba(255, 255, 255, 0.7)" }}
                        >
                          Note: Current lead time for new orders is 6-8 weeks to get hardware, and
                          these are subject to change.
                        </MDTypography>
                        <MDBox mt={2}>
                          <MDButton type="submit">Start</MDButton>
                          {/* <MDTypography
                            variant="subtitle2"
                            color="white"
                            component="span"
                            style={{ fontWeight: "normal" }}
                            ml={1}
                          >
                            Press Enter
                            <Icon color="inherit">subdirectory_arrow_left</Icon>
                          </MDTypography> */}
                        </MDBox>
                      </MDBox>
                    )}
                  </MDBox>
                </MDBox>
              </Grid>
            </Grid>
          </MDBox>
        ) : (
          <>
            <MDProgress value={activeStep * 33.33} variant="contained" color="dark" />
            <Container maxWidth="md">
              {getStepContent(activeStep)}
              <MDBox mt={2}>
                <MDButton variant="gradient" color="light" onClick={handleNext} size="small">
                  {isLastStep ? "Submit" : "Ok"} <Icon fontSize="large">done</Icon>
                </MDButton>
              </MDBox>
            </Container>
            <MDBox mt={3} display="flex" position="fixed" bottom={10} right={20}>
              <ButtonGroup variant="contained" color="info" sx={{ boxShadow: "none" }}>
                <MDButton
                  variant="gradient"
                  color="light"
                  onClick={handleBack}
                  disabled={!activeStep}
                  size="small"
                >
                  <Icon fontSize="large">keyboard_arrow_up</Icon>
                </MDButton>
                <MDButton
                  variant="gradient"
                  color="light"
                  onClick={handleNext}
                  disabled={isLastStep}
                  size="small"
                >
                  <Icon fontSize="large">keyboard_arrow_down</Icon>
                </MDButton>
              </ButtonGroup>
            </MDBox>
          </>
        )}
      </MDBox>
    </PageLayout>
  );
}

export default Index;
