import { Card } from "@mui/material";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
// import MDBox from "components/MDBox";
// import MDTypography from "components/MDTypography";
import PageLayout from "examples/LayoutContainers/PageLayout";
import { useEffect } from "react";

import { useAppDispatch, useAppSelector } from "store/store";
import { getReceipentView, updateSignStatus } from "store/thunk/docusignThunk";

function Index(): JSX.Element {
  const dispatch = useAppDispatch();
  const { signStatusData } = useAppSelector((state) => state.docusignSlice);
  const search = new URLSearchParams(window.location.search);
  const name = search.get("name") || "";
  const email = search.get("email") || "";
  const event = search.get("event") || "";
  const envelopId = search.get("envelopId") || "";

  const originalData: any = {
    name,
    email,
    envelopId,
  };

  useEffect(() => {
    if (signStatusData && signStatusData?.data) {
      window.open(signStatusData?.data, "_self");
    }
  }, [signStatusData]);

  useEffect(() => {
    if (originalData && originalData.email && !event) {
      dispatch(
        getReceipentView({
          email: originalData?.email,
          envelopId: originalData?.envelopId,
        })
      );
    } else if (originalData && originalData.email && event) {
      dispatch(
        updateSignStatus({
          email: originalData?.email,
          name: originalData?.name,
          envelopId: originalData?.envelopId,
        })
      );
    }
  }, []);

  return (
    <PageLayout width="100%">
      <Card sx={{ m: 3, height: "100%" }}>
        <MDBox padding="1rem">
          <MDTypography variant="h5">{signStatusData?.message}</MDTypography>
        </MDBox>
      </Card>
    </PageLayout>
  );
}

export default Index;
