import { useEffect, useState } from "react";
import MDDialog from "components/MDDialog";
import MDTypography from "components/MDTypography";
import { Grid } from "@mui/material";
import MDBox from "components/MDBox";
import ImagePreview from "layouts/pages/ImagePreview";
import { getLocalDateTime } from "helper/services";

function ViewDetails(props: any): JSX.Element {
  const { open, onClose, articleDetails } = props;
  const [articalFormData, setArticalFormData] = useState<any>({});
  const [imagePreview, setImagePreview] = useState<any>("");
  const [loading] = useState<boolean>(false);
  const [isPreview, setImagePreviewPopup] = useState(false);
  const [imageToPreview, setImageToPreview] = useState("");

  const displaySetting = [
    { value: "sola_website", name: "Sola Website" },
    { value: "franchising_website", name: "Franchising Website" },
    { value: "both", name: "Both" },
  ];

  const previewImage = (image: any) => {
    setImagePreviewPopup(true);
    setImageToPreview(image);
  };

  const closeImagePreview = () => {
    setImagePreviewPopup(false);
    setImageToPreview("");
  };

  useEffect(() => {
    if (Object.keys(articleDetails).length > 0) {
      setArticalFormData((prev: any) => ({
        ...prev,
        title: articleDetails.title,
        article_url: articleDetails.article_url,
        summary: articleDetails.summary,
        location: articleDetails.location_id,
        location_name: articleDetails.location_name,
        display_setting: displaySetting.find((val) => articleDetails.display_setting === val.value),
        created_at: articleDetails.created_at,
        updated_at: articleDetails.updated_at,
      }));
      if (articleDetails.id.toString().length === 1) {
        articleDetails.id = `00${articleDetails.id}`;
      } else if (articleDetails.id.toString().length === 2) {
        articleDetails.id = `0${articleDetails.id}`;
      }
      setImagePreview(articleDetails.image_url);
    }
  }, [articleDetails]);

  const dialogProps = {
    open,
    onClose,
    saveBtn: false,
    title: "View News",
    size: "md",
    loading,
  };

  return (
    <MDDialog {...dialogProps}>
      <Grid container>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <Grid container>
            <Grid item xs={12} sm={6} md={6}>
              <MDTypography fontWeight="bold" variant="button">
                Title
              </MDTypography>
              <MDBox>
                <MDTypography variant="button">{articalFormData?.title || "-"}</MDTypography>
              </MDBox>
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
              <MDTypography fontWeight="bold" variant="button">
                Article Url
              </MDTypography>
              <MDBox sx={{ overflow: "hidden", wordBreak: "break-word" }}>
                <MDTypography variant="button">{articalFormData?.article_url || "-"}</MDTypography>
              </MDBox>
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
              <MDTypography fontWeight="bold" variant="button">
                Display Setting
              </MDTypography>
              <MDBox>
                <MDTypography variant="button">
                  {articalFormData?.display_setting?.name || "-"}
                </MDTypography>
              </MDBox>
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
              <MDTypography fontWeight="bold" variant="button">
                Location
              </MDTypography>
              <MDBox>
                <MDTypography variant="button">
                  {articalFormData?.location_name || "-"}
                </MDTypography>
              </MDBox>
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
              <MDTypography fontWeight="bold" variant="button">
                Summary
              </MDTypography>
              <MDBox>
                <MDBox
                  dangerouslySetInnerHTML={{
                    __html: articalFormData?.summary ? articalFormData?.summary : "-",
                  }}
                />
              </MDBox>
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
              <MDTypography fontWeight="bold" variant="button">
                Created At
              </MDTypography>
              <MDBox>
                <MDTypography variant="button">
                  {articalFormData?.created_at
                    ? getLocalDateTime(articalFormData?.created_at)
                    : "-"}
                </MDTypography>
              </MDBox>
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
              <MDTypography fontWeight="bold" variant="button">
                Updated At
              </MDTypography>
              <MDBox>
                <MDTypography variant="button">
                  {articalFormData?.updated_at
                    ? getLocalDateTime(articalFormData?.updated_at)
                    : "-"}
                </MDTypography>
              </MDBox>
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={12} sm={12} md={12}>
              <MDTypography fontWeight="bold" variant="button">
                Image
              </MDTypography>
              {imagePreview && (
                <Grid
                  container
                  onClick={() => previewImage(imagePreview)}
                  mt={2}
                  alignItems="center"
                  className="thumbnail-img"
                >
                  <img width="150px" src={imagePreview} alt="original" />
                </Grid>
              )}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <ImagePreview
        imageData={imageToPreview}
        open={isPreview}
        onCloseImage={() => {
          closeImagePreview();
        }}
      />
    </MDDialog>
  );
}

export default ViewDetails;
