import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "store/store";
import TableContainer from "@mui/material/TableContainer";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import Table from "@mui/material/Table";
import MDBox from "components/MDBox";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import {
  ButtonGroup,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  Input,
  InputAdornment,
  MenuItem,
  OutlinedInput,
  Radio,
  RadioGroup,
  Select,
  TableHead,
  TextField,
} from "@mui/material";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import Validations from "helper/validations";
import { amountCalculation, percentCalculation } from "helper/services";
import EleaseButtons from "components/EleaseButtons";
import { getLocation } from "store/thunk/locationThunk";
import { setleaseApplicationSteps } from "store/slices/leaseSlice";

function SecurityDeposit(props: any): JSX.Element {
  const {
    saveDraftData,
    setSaveDraftData,
    setChildMembers,
    childMembers,
    rmChangeDetials,
    setRmChangeDetials,
    setSubletApplicantError,
    isUpdateDeposite,
  } = props;
  const { leaseApplicationSteps, locationStudios } = useAppSelector((state) => state.leaseSlice);
  const { connectLocation } = useAppSelector((state) => state.locationSlice);
  const [errors, setErrors] = useState<any>({});
  const [isNotConfirmedCount, setIsNotConfirmedCount] = useState<any>(0);
  const [rmDetails, setRmDetails] = useState<any>({});
  const [showDepositAmount, setShowDepositAmount] = useState();
  const [securityAmount, setSecurityAmount] = useState<any>(0);
  const [value, setValue] = useState<any>({});
  const dispatch = useAppDispatch();
  const leaseAmount = leaseApplicationSteps?.lease_fees;
  // const leaseAmount = 250;
  const [studios, setStudios] = useState<any>([]);
  const filterData = leaseApplicationSteps?.applicants?.filter((item: any) => !item.isSublet);

  // Location DetailsAPI call
  useEffect(() => {
    const sendData = {
      id: leaseApplicationSteps?.locations?.cms_location_id,
      isAminities: true,
    };
    dispatch(getLocation(sendData));
    if (locationStudios) {
      setStudios(locationStudios?.data?.rows?.filter((data: any) => data?.is_vacant));
    }
  }, []);

  useEffect(() => {
    if (connectLocation) {
      setSubletApplicantError(false);
      setShowDepositAmount(
        leaseApplicationSteps?.security_deposit_amount_type
          ? leaseApplicationSteps?.security_deposit_amount_type
          : connectLocation?.security_deposit_amount_type
      );
      setRmDetails({
        rent_period: connectLocation?.rent_period,
        security_deposit_amount: leaseApplicationSteps?.security_deposit_amount
          ? leaseApplicationSteps?.security_deposit_amount
          : connectLocation?.security_deposit_amount,
        security_deposit_amount_type: leaseApplicationSteps?.security_deposit_amount_type
          ? leaseApplicationSteps?.security_deposit_amount_type
          : connectLocation?.security_deposit_amount_type,
      });
      setRmChangeDetials({
        ...rmChangeDetials,
        rent_period: connectLocation?.rent_period,
        security_deposit_amount: connectLocation?.security_deposit_amount,
        security_deposit_amount_type: connectLocation?.security_deposit_amount_type,
      });
    }
  }, [connectLocation]);

  // Change on Radio selection PaidinFull
  const handleRadioChange = (event: any) => {
    const updatedMembers = childMembers.map((member: any) => ({
      ...member,
      deposit_status: event.target.value,
    }));
    setChildMembers(updatedMembers);
    setValue(event.target.value);
    dispatch(
      setleaseApplicationSteps({ ...leaseApplicationSteps, paymentStatus: event.target.value })
    );
  };

  const allCaculation = (members: any, tempSecurityAmount?: number) => {
    let updatedMembers = members;
    if (showDepositAmount === "flat") {
      setSecurityAmount(tempSecurityAmount);
      const totalAmount = tempSecurityAmount / members.length;
      const totalPercent = Number((totalAmount * 100) / tempSecurityAmount);

      updatedMembers = updatedMembers.map((member: any) => ({
        ...member,
        amount: totalAmount ? Number(totalAmount?.toFixed(2)) : 0,
        percent: totalPercent ? Number(totalPercent?.toFixed(2)) : 0,
        isConfirm: false,
        // deposit_status: "Unpaid",
        isPayed: true,
        lease_fee: Number(connectLocation?.security_deposit_amount),
        lease_fee_frequency: Number(connectLocation?.lease_term),
      }));
      setChildMembers(updatedMembers);
      setSaveDraftData({ ...saveDraftData, applicants: updatedMembers });
    } else if (showDepositAmount === "rent_retio") {
      setSecurityAmount(tempSecurityAmount);
      const totalAmount = tempSecurityAmount / members.length;
      const totalPercent = (totalAmount * 100) / tempSecurityAmount;

      updatedMembers = updatedMembers.map((member: any) => ({
        ...member,
        amount: totalAmount ? Number(totalAmount?.toFixed(2)) : 0,
        percent: totalPercent ? Number(totalPercent?.toFixed(2)) : 0,
        isConfirm: false,
        // deposit_status: "Unpaid",
        isPayed: true,
        lease_fee: leaseAmount,
        lease_fee_frequency: Number(connectLocation?.lease_term),
      }));
      setChildMembers(updatedMembers);
      setSaveDraftData({ ...saveDraftData, applicants: updatedMembers });
    }
    setIsNotConfirmedCount(
      leaseApplicationSteps?.applicants?.filter((item: any) => !item.isConfirm)?.length
    );
  };

  // Calculation useEffect
  useEffect(() => {
    // const exactWeek = isUpdateDeposite
    //   ? parseFloat(leaseApplicationSteps?.security_deposit_amount?.split()?.[0])
    //   : parseFloat(connectLocation?.security_deposit_amount?.split()?.[0]);
    const exactWeek = leaseApplicationSteps?.security_deposit_amount
      ? parseFloat(leaseApplicationSteps?.security_deposit_amount?.split()?.[0])
      : parseFloat(connectLocation?.security_deposit_amount?.split()?.[0]);
    if (showDepositAmount) {
      if (
        isUpdateDeposite &&
        rmDetails?.security_deposit_amount_type !==
          leaseApplicationSteps?.security_deposit_amount_type
      ) {
        allCaculation(filterData, showDepositAmount === "flat" ? 0 : 0);
      } else {
        allCaculation(
          filterData,
          showDepositAmount === "flat"
            ? Number(
                leaseApplicationSteps?.security_deposit_amount
                  ? leaseApplicationSteps?.security_deposit_amount
                  : connectLocation?.security_deposit_amount
              )
            : Number(leaseApplicationSteps.lease_fees * exactWeek)
        );
      }
    }

    const foundApplicant = leaseApplicationSteps?.applicants.find(
      (applicant: any) => applicant?.deposit_status
    );

    if (foundApplicant?.deposit_status === "Paid") {
      setValue("Paid");
    }
  }, [showDepositAmount]);

  const handleBtnGroupClick = (event: any) => {
    setShowDepositAmount(event.target.name);
    const tempRM = JSON.parse(JSON.stringify(rmDetails));
    if (
      isUpdateDeposite &&
      event.target.name !== leaseApplicationSteps?.security_deposit_amount_type
    ) {
      tempRM.security_deposit_amount = "";
    } else {
      tempRM.security_deposit_amount = leaseApplicationSteps?.security_deposit_amount
        ? leaseApplicationSteps?.security_deposit_amount
        : connectLocation?.security_deposit_amount;
    }
    tempRM.security_deposit_amount_type = event.target.name;
    setRmDetails(tempRM);
    setRmChangeDetials(tempRM);
    allCaculation(filterData, Number(rmDetails?.security_deposit_amount));
  };

  const onChangeField = (event: any) => {
    setRmChangeDetials({
      ...rmChangeDetials,
      security_deposit_amount: event.target.value,
    });
    setRmDetails({ ...rmDetails, security_deposit_amount: event.target.value });
    const changeWeek = parseFloat(event.target.value.split()[0]);
    const changeInValue = leaseAmount * changeWeek;
    allCaculation(
      filterData,
      rmDetails?.security_deposit_amount_type === "flat"
        ? Number(event.target.value)
        : Number(changeInValue)
    );
  };

  // Amount calculation function
  const amountChange = (event: any, index: any) => {
    const confirmedItems = childMembers.filter((item: any) => item.isConfirm);
    const totalConfirmedAmount = confirmedItems.reduce(
      (acc: any, item: any) => acc + item.amount,
      0
    );
    const changeValue = Number(event.target.value);
    // Call amountCalculation for amount calculation
    const amountsDetails = amountCalculation(
      totalConfirmedAmount,
      securityAmount,
      Number(event.target.value),
      confirmedItems,
      childMembers
    );
    let updatedMembersAmount = [...childMembers];
    updatedMembersAmount = updatedMembersAmount.map((member: any, i: any) => {
      if (i !== index) {
        return {
          ...member,
          amount: !member?.isConfirm
            ? amountsDetails?.amountChange < 0
              ? 0
              : amountsDetails?.amountChange
            : +Number(member?.amount).toFixed(2),
          percent: !member?.isConfirm
            ? amountsDetails?.percentChange < 0
              ? 0
              : amountsDetails?.percentChange
            : +Number(member?.percent).toFixed(2),
          isPayed: amountsDetails?.amountChange === 0 ? false : true,
        };
      }

      return {
        ...member,
        amount: changeValue < 0 ? 0 : changeValue,
        percent: amountsDetails?.percentageCurrent,
        isPayed: true,
      };
    });

    setChildMembers(updatedMembersAmount);
    const confirmedAmountTotal = updatedMembersAmount
      .filter((item: any) => !item.isConfirm)
      .reduce((acc: any, item: any) => acc + Number(item.amount), 0);
    const unConfirmedAmountTotal = updatedMembersAmount
      .filter((item: any) => item.isConfirm)
      .reduce((acc: any, item: any) => acc + Number(item.amount), 0);
    const errors = Validations.securityData({
      confirmedAmountTotal,
      unConfirmedAmountTotal,
      existingMembers: updatedMembersAmount,
      securityAmount,
      changeValue: Number(event.target.value),
    });
    setErrors(errors);
  };

  // Percentage calculation function
  const percentChange = (event: any, index: any) => {
    const confirmedItems = childMembers.filter((item: any) => item.isConfirm);
    const totalConfirmedPercent = confirmedItems.reduce(
      (acc: any, item: any) => acc + item.percent,
      0
    );
    const totalConfirmedAmount = confirmedItems.reduce(
      (acc: any, item: any) => acc + item.amount,
      0
    );
    // Call amountCalculation for amount calculation
    const percentDetails = percentCalculation(
      totalConfirmedPercent,
      securityAmount,
      Number(event.target.value),
      childMembers,
      totalConfirmedAmount
    );
    let updatedMembersPercent = [...childMembers];
    updatedMembersPercent = updatedMembersPercent.map((member: any, i: any) => {
      if (i !== index) {
        return {
          ...member,
          amount: !member?.isConfirm
            ? percentDetails?.otherApplicantAmount < 0
              ? 0
              : percentDetails?.otherApplicantAmount.toFixed(2)
            : +Number(member?.amount).toFixed(2),
          percent: !member?.isConfirm
            ? percentDetails?.otherApplicantPercent < 0
              ? 0
              : percentDetails?.otherApplicantPercent.toFixed(2)
            : +Number(member?.percent).toFixed(2),
          isPayed: percentDetails?.otherApplicantPercent === 0 ? false : true,
        };
      }

      return {
        ...member,
        amount: percentDetails?.amountCurrent,
        percent: Number(event.target.value),
        isPayed: true,
      };
    });
    setChildMembers(updatedMembersPercent);
    const confirmedTotalPercent = updatedMembersPercent
      .filter((item: any) => !item.isConfirm)
      .reduce((accumlator: any, item: any) => accumlator + Number(item.percent), 0);

    const unConfirmedTotalPercent = updatedMembersPercent
      .filter((item: any) => item.isConfirm)
      .reduce((accumlator: any, item: any) => accumlator + Number(item.percent), 0);
    const errors = Validations.securityData({
      confirmedTotalPercent,
      unConfirmedTotalPercent,
      existingMembers: updatedMembersPercent,
      securityAmount,
      changeValue: Number(event.target.value),
    });
    setErrors(errors);
  };

  const handleChange = (event: any, index: any) => {
    const { name, checked } = event.target;
    const checkCheckboxForFixed: any = childMembers ? [...childMembers] : [];
    checkCheckboxForFixed[index] = {
      ...checkCheckboxForFixed[index],
      [name]: checked,
    };
    setChildMembers(checkCheckboxForFixed);
    setIsNotConfirmedCount(
      event.target.checked ? isNotConfirmedCount - 1 : isNotConfirmedCount + 1
    );
  };

  const displayStudioName = (studio: any) => {
    const mainName = studios.filter(
      (obj: any) => obj.group_id === studio?.id && obj?.id !== studio?.id
    )?.length ? (
      <>
        {studios
          .filter((obj: any) => obj.group_id === studio?.id && obj?.id !== studio?.id)
          ?.map((obj: any, index: any) => (
            <>
              <span>{obj?.name}</span>
              {index < studios.length - 1 && <span>,</span>}
            </>
          ))}
        <span>{studio?.name}</span>
      </>
    ) : (
      <span>{studio?.name}</span>
    );
    return mainName;
  };
  return (
    <MDBox sx={{ maxWidth: "auto" }}>
      <Grid
        container
        sx={{ flexWrap: { lg: "nowrap", md: "unset" }, display: { lg: "flex", md: "block" } }}
      >
        <Grid item lg={3} md={12} xs={12}>
          <MDTypography variant="h6">Security Deposit Amount :</MDTypography>
          <Grid>
            <ButtonGroup
              variant="contained"
              color="info"
              sx={{ boxShadow: "none", display: "block", mt: 2, maxwidth: " 300px" }}
            >
              <MDButton
                variant="gradient"
                name="flat"
                color={showDepositAmount === "flat" ? "dark" : "light"}
                onClick={handleBtnGroupClick}
                size="small"
                sx={{ width: "50%", p: 0 }}
                disabled={value === "Paid"}
              >
                Flat Fee
              </MDButton>
              <MDButton
                variant="gradient"
                color={showDepositAmount === "rent_retio" ? "dark" : "light"}
                name="rent_retio"
                sx={{ width: "50%", p: 0 }}
                onClick={handleBtnGroupClick}
                size="small"
                disabled={value === "Paid"}
              >
                Rent Ratio
              </MDButton>
            </ButtonGroup>
            <MDBox mt={2}>
              {showDepositAmount === "rent_retio" && (
                <>
                  <MDTypography variant="h6">Set security deposit to</MDTypography>
                  <FormControl sx={{ width: 224 }}>
                    <FormControl sx={{ width: 224 }}>
                      <Select
                        className="height45"
                        value={rmDetails?.security_deposit_amount || ""}
                        name="security_deposit_amount"
                        onChange={onChangeField}
                        disabled={value === "Paid"}
                      >
                        {rmDetails?.rent_period === "Weekly" ? (
                          <MenuItem value="2 Weekly Rent">2 Weekly Rent</MenuItem>
                        ) : (
                          <MenuItem value="2 Monthly Rent">2 Monthly Rent</MenuItem>
                        )}
                        {rmDetails?.rent_period === "Weekly" ? (
                          <MenuItem value="2.5 Weekly Rent">2.5 Weekly Rent</MenuItem>
                        ) : (
                          <MenuItem value="2.5 Monthly Rent">2.5 Monthly Rent</MenuItem>
                        )}
                        {rmDetails?.rent_period === "Weekly" ? (
                          <MenuItem value="3 Weekly Rent">3 Weekly Rent</MenuItem>
                        ) : (
                          <MenuItem value="3 Monthly Rent">3 Monthly Rent</MenuItem>
                        )}
                      </Select>
                    </FormControl>
                  </FormControl>
                </>
              )}
              {showDepositAmount === "flat" && (
                <OutlinedInput
                  type="number"
                  value={
                    rmDetails?.security_deposit_amount
                      ? rmDetails?.security_deposit_amount
                      : leaseApplicationSteps?.security_deposit_amount || ""
                  }
                  name="security_deposit_amount"
                  startAdornment="$"
                  onChange={onChangeField}
                />
              )}
              <MDTypography variant="h6">
                Studio: {displayStudioName(leaseApplicationSteps?.studio[0])}
                {/* {leaseApplicationSteps?.studio[0]?.name} */}
              </MDTypography>
              {rmDetails?.security_deposit_amount_type === "rent_retio" && (
                <MDTypography variant="h6">
                  Lease Fee: $ {leaseApplicationSteps?.lease_fees}
                </MDTypography>
              )}
            </MDBox>
            <RadioGroup
              row
              aria-labelledby="demo-row-radio-buttons-group-label"
              name="row-radio-buttons-group"
              value={value}
              onChange={handleRadioChange}
            >
              <FormControlLabel value="Paid" control={<Radio />} label="Paid in full" />
            </RadioGroup>
          </Grid>
        </Grid>
        <Grid item lg={9} md={12} xs={12} pt={1}>
          <MDTypography variant="h6" sx={{ maxWidth: "700px", margin: "0 auto 20px" }}>
            Sublets are not listed
          </MDTypography>
          <TableContainer
            component={Paper}
            sx={{
              maxWidth: "700px",
              margin: "0 auto 30px",
              border: " 1px solid #ddd",
              maxHeight: "unset",
              minHeight: "unset",
              borderRadius: "0px !important",
            }}
          >
            <Table aria-label="simple table" className="sublets-table">
              <TableHead sx={{ display: "table-header-group !important" }}>
                <TableRow className="sublets-table-row">
                  <TableCell>Applicant</TableCell>
                  <TableCell>Amount</TableCell>
                  <TableCell>Percent</TableCell>
                  {childMembers?.length !== 1 && <TableCell>Lock</TableCell>}
                </TableRow>
              </TableHead>
              {childMembers &&
                childMembers?.length > 0 &&
                childMembers?.map((items: any, index: any) => (
                  <TableBody>
                    <TableRow sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                      <TableCell component="td" scope="row">
                        <TextField
                          value={`${items?.first_name} ${items?.last_name}` || ""}
                          id="standard-basic"
                          variant="standard"
                          disabled
                        />
                      </TableCell>
                      <TableCell component="td" scope="row">
                        {childMembers?.length === 1 ? (
                          <Input
                            id="standard-adornment-amount"
                            value={items?.amount}
                            startAdornment={<InputAdornment position="start">$</InputAdornment>}
                            disabled
                          />
                        ) : (
                          <FormControl>
                            <Input
                              id="standard-adornment-amount"
                              name="amount"
                              type="number"
                              value={items?.amount}
                              startAdornment={<InputAdornment position="start">$</InputAdornment>}
                              onChange={(e: any) => amountChange(e, index)}
                              error={errors && errors[`applicant_${index}_amount`] ? true : false}
                              disabled={
                                isNotConfirmedCount === 1 || items?.isConfirm || value === "Paid"
                              }
                            />
                            <FormHelperText id="standard-weight-helper-text">
                              {errors && errors[`applicant_${index}_amount`]}
                            </FormHelperText>
                          </FormControl>
                        )}
                      </TableCell>
                      <TableCell component="td" scope="row">
                        {childMembers?.length === 1 ? (
                          <Input
                            value={items?.percent}
                            id="standard-adornment-amount"
                            endAdornment={<InputAdornment position="end">%</InputAdornment>}
                            disabled
                          />
                        ) : (
                          <FormControl fullWidth sx={{ m: 1 }} variant="standard">
                            <Input
                              id="standard-adornment-amount"
                              name="percent"
                              type="number"
                              value={items?.percent}
                              endAdornment={<InputAdornment position="end">%</InputAdornment>}
                              onChange={(e: any) => percentChange(e, index)}
                              error={errors && errors[`applicant_${index}_percent`] ? true : false}
                              disabled={
                                isNotConfirmedCount === 1 || items?.isConfirm || value === "Paid"
                              }
                            />
                            <FormHelperText id="standard-weight-helper-text">
                              {errors && errors[`applicant_${index}_percent`]}
                            </FormHelperText>
                          </FormControl>
                        )}
                      </TableCell>
                      {childMembers?.length > 1 && (
                        <TableCell>
                          <Checkbox
                            checked={isNotConfirmedCount === 1 || items?.isConfirm || false}
                            name="isConfirm"
                            value={items?.[index]?.isConfirm || false}
                            onChange={(e: any) => handleChange(e, index)}
                            inputProps={{ "aria-label": "controlled" }}
                            disabled={value === "Paid"}
                          />
                        </TableCell>
                      )}
                    </TableRow>
                  </TableBody>
                ))}
            </Table>
          </TableContainer>
        </Grid>
      </Grid>
      <EleaseButtons
        {...props}
        data={{
          applicants: childMembers,
          rent_period: rmDetails?.rent_period,
          security_deposit_amount: rmDetails?.security_deposit_amount,
          security_deposit_amount_type: rmDetails?.security_deposit_amount_type,
          rent_due_date: connectLocation?.rent_due_date,
          lease_term: connectLocation?.lease_term,
          insurance_charge_frequency: connectLocation?.insurance_charge_frequency,
          insurance_fees: connectLocation?.insurance_fee,
          weeks_free_rent: connectLocation?.weeks_free_rent,
          months_free_insurance: connectLocation?.months_free_insurence,
          free_paints: connectLocation?.free_paint,
          security_deposite: securityAmount,
        }}
      />
    </MDBox>
  );
}

export default SecurityDeposit;
