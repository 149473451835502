import React, { useEffect, useState } from "react";
import MDDialog from "components/MDDialog";
import MDInput from "components/MDInput";
import MDBox from "components/MDBox";
import { Checkbox, FormControlLabel, FormGroup, InputLabel, Paper } from "@mui/material";
import TreeView from "@mui/lab/TreeView";
import TreeItem from "@mui/lab/TreeItem";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { getMenusList, saveRole } from "store/thunk/rolesAndMenusThunk";
import { useAppDispatch, useAppSelector } from "store/store";
import Validations from "helper/validations";
import CustomErrorMessage from "components/CustomErrorMessage";
import MDTypography from "components/MDTypography";

function AddUpdate(props: any): JSX.Element {
  const dispatch = useAppDispatch();
  const { open, onClose, editRole, viewRole } = props;
  const [name, setName] = useState("");
  const [oldName, setOldName] = useState("");
  const [description, setDescription] = useState("");
  const [allAlias, setAllAlias] = useState<any>([]);
  const [allMenuIds, setAllMenuIds] = useState<any>([]);
  const [errors, setErrors] = useState<any>({});
  const rolesMenusInfo = useAppSelector((state) => state.rolesAndMenusReducer);

  const onSave = () => {
    const request = {
      name,
      oldName,
      description,
      menuIds: allMenuIds,
      editRole: editRole?.name?.length > 0,
    };

    const error = Validations.validateRoleForm(request);
    setErrors(error);
    if (!Object.keys(error).length) {
      dispatch(saveRole(request));
      onClose();
    }
  };

  const getTitleOfPage = () => {
    if (viewRole) return "View Role";
    if (editRole) return "Edit Role";
    return "Add Role";
  };

  useEffect(() => {
    dispatch(getMenusList());
  }, []);

  useEffect(() => {
    if (editRole) {
      setName(editRole.name);
      setOldName(editRole.name);
      setDescription(editRole.description);
    }
  }, [editRole]);

  useEffect(() => {
    if (editRole || viewRole) {
      const allMenuIdsArr: any = [];
      const allAliasArr: any = [];
      if (rolesMenusInfo && rolesMenusInfo.roleDetails && rolesMenusInfo.roleDetails.length) {
        rolesMenusInfo.roleDetails.map((role: any) => {
          allAliasArr.push(role.alias);
          allMenuIdsArr.push(role.menu_id);
          if (role.children && role.children.length) {
            role.children.map((child: any) => {
              allAliasArr.push(child.alias);
              allMenuIdsArr.push(child.menu_id);
              if (child.page_sections && child.page_sections.length) {
                child.page_sections.map((section: any) => {
                  allAliasArr.push(section.alias);
                  allMenuIdsArr.push(section.menu_id);
                  return true;
                });
              }
              return true;
            });
          }
          if (role.page_sections && role.page_sections.length) {
            role.page_sections.map((section: any) => {
              allAliasArr.push(section.alias);
              allMenuIdsArr.push(section.menu_id);
              return true;
            });
          }
          return true;
        });
      }
      setAllAlias(allAliasArr);
      setAllMenuIds(allMenuIdsArr);
    }
  }, [rolesMenusInfo]);

  const dialogProps = {
    open,
    onClose,
    onSave,
    title: getTitleOfPage(),
    saveTbtText: editRole && "Update",
    size: "sm",
    saveBtn: !viewRole,
  };

  const onSelectMenu = (
    event: any,
    menuId: number,
    alias: string,
    childMenu: any = [],
    pageSections: any = []
  ) => {
    const allAliasArr: any = JSON.parse(JSON.stringify(allAlias));
    const allMenuIdsArr: any = allMenuIds;
    if (childMenu?.length) {
      if (allAliasArr.indexOf(alias) === -1) {
        childMenu.map((child: any) => {
          if (allAliasArr.indexOf(child.alias) === -1) {
            allAliasArr.push(child.alias);
            allMenuIdsArr.push(child.menu_id);
          }

          if (child?.page_sections?.length) {
            child.page_sections.map((section: any) => {
              if (allAliasArr.indexOf(section.alias) === -1) {
                allAliasArr.push(section.alias);
                allMenuIdsArr.push(section.menu_id);
              }
              return true;
            });
          }

          return true;
        });
      } else {
        childMenu.map((child: any) => {
          if (allAliasArr.indexOf(child.alias) !== -1) {
            allAliasArr.splice(allAliasArr.indexOf(child.alias), 1);
            allMenuIdsArr.splice(allMenuIdsArr.indexOf(child.menu_id), 1);
          }

          if (child?.page_sections?.length) {
            child.page_sections.map((section: any) => {
              if (allAliasArr.indexOf(section.alias) !== -1) {
                allAliasArr.splice(allAliasArr.indexOf(section.alias), 1);
                allMenuIdsArr.splice(allMenuIdsArr.indexOf(section.menu_id), 1);
              }
              return true;
            });
          }

          return true;
        });
      }
    }

    if (pageSections?.length) {
      if (allAliasArr.indexOf(alias) === -1) {
        pageSections.map((section: any) => {
          if (allAliasArr.indexOf(section.alias) === -1) {
            allAliasArr.push(section.alias);
            allMenuIdsArr.push(section.menu_id);
          }

          return true;
        });
      } else {
        pageSections.map((section: any) => {
          if (allAliasArr.indexOf(section.alias) !== -1) {
            allAliasArr.splice(allAliasArr.indexOf(section.alias), 1);
            allMenuIdsArr.splice(allMenuIdsArr.indexOf(section.menu_id), 1);
          }

          return true;
        });
      }
    }

    if (allAliasArr.indexOf(alias) === -1) {
      allAliasArr.push(alias);
      allMenuIdsArr.push(menuId);
    } else {
      allAliasArr.splice(allAliasArr.indexOf(alias), 1);
      allMenuIdsArr.splice(allMenuIdsArr.indexOf(menuId), 1);
    }

    setAllAlias(allAliasArr);
    setAllMenuIds(allMenuIdsArr);
  };

  const isExpand = (item: any) =>
    item?.child_menu?.length || item?.page_sections?.length ? true : false;

  const getSubmenuList = (menu: any) =>
    menu.map((item: any) => (
      <TreeItem
        key={item.menu_id}
        nodeId={item.menu_id}
        expandIcon={isExpand(item) ? <ChevronRightIcon /> : <> </>}
        label={
          <FormControlLabel
            label={item?.display_menu}
            control={
              <Checkbox
                disabled={viewRole}
                checked={allAlias.includes(item.alias)}
                onChange={(event: any) =>
                  onSelectMenu(
                    event,
                    item.menu_id,
                    item.alias,
                    item?.child_menu || [],
                    item?.page_sections || []
                  )
                }
              />
            }
            key={item.menu_id}
          />
        }
      >
        {((item.child_menu && item.child_menu.length > 0) ||
          (item.page_sections && item.page_sections.length > 0)) && (
          <MDBox mt={1}>
            <Paper variant="outlined">
              <MDBox p={1}>
                {item.child_menu && item.child_menu.length > 0 && (
                  <>
                    <MDTypography
                      component="label"
                      variant="button"
                      fontWeight="regular"
                      color="text"
                      sx={{ my: 2, mx: 4, color: "rgba(108,108,132,1)" }}
                    >
                      Submenu
                    </MDTypography>
                    {getSubmenuList(item.child_menu)}
                  </>
                )}
                {item.page_sections && item.page_sections.length > 0 && (
                  <MDBox>
                    <MDBox>
                      <MDTypography
                        component="label"
                        variant="button"
                        fontWeight="regular"
                        color="text"
                      >
                        Page sections
                      </MDTypography>
                    </MDBox>
                    <MDBox>
                      {item.page_sections.map((obj: any) => (
                        <FormControlLabel
                          label={obj?.display_menu}
                          sx={{ mx: 2 }}
                          control={
                            <Checkbox
                              disabled={viewRole}
                              checked={allAlias.includes(obj.alias)}
                              onChange={(event: any) =>
                                onSelectMenu(
                                  event,
                                  obj.menu_id,
                                  obj.alias,
                                  obj?.child_menu || [],
                                  obj?.page_sections || []
                                )
                              }
                            />
                          }
                          key={obj.menu_id}
                        />
                      ))}
                    </MDBox>
                  </MDBox>
                )}
              </MDBox>
            </Paper>
          </MDBox>
        )}
      </TreeItem>
    ));

  return (
    <MDDialog {...dialogProps}>
      <FormGroup>
        <MDInput
          label="Name"
          variant="standard"
          fullWidth
          required
          name="name"
          placeholder="Role name"
          disabled={viewRole}
          error={(errors && errors.name) || false}
          helperText={errors && errors.name}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => setName(e.target.value)}
          value={name}
        />
      </FormGroup>
      <FormGroup>
        <MDInput
          label="Description"
          variant="standard"
          fullWidth
          name="description"
          placeholder="Description"
          disabled={viewRole}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => setDescription(e.target.value)}
          value={description}
          sx={{ mt: 2, mb: 2 }}
        />
      </FormGroup>
      <FormGroup>
        <InputLabel sx={{ mb: 2 }}>Menus</InputLabel>
        <CustomErrorMessage message={errors && errors.menuIds} />
        {/* {errors && errors.menuIds && <Alert severity="error">{errors.menuIds}</Alert>} */}
        <TreeView
          aria-label="rich object"
          defaultCollapseIcon={<ExpandMoreIcon />}
          defaultExpanded={["root"]}
          disableSelection={viewRole}
          defaultExpandIcon={<ChevronRightIcon />}
          sx={{ minHeight: 200, overflowY: "auto" }}
          multiSelect
        >
          {rolesMenusInfo?.menus.length > 0 && getSubmenuList(rolesMenusInfo?.menus || [])}
          {/* {rolesMenusInfo?.menus.map((menu: any) =>
            menu?.child_menu?.length ? (
              <TreeItem
                key={menu?.menu_id}
                nodeId={menu?.menu_id}
                expandIcon={menu?.child_menu?.length ? <ChevronRightIcon /> : <> </>}
                label={
                  <FormControlLabel
                    label={menu?.display_menu}
                    control={
                      <Checkbox
                        disabled={viewRole}
                        checked={allAlias.includes(menu.alias)}
                        onChange={(event: any) =>
                          onSelectMenu(event, menu?.menu_id, menu?.alias, menu?.child_menu)
                        }
                      />
                    }
                  />
                }
              >
                {menu?.child_menu?.map((childmenu: any) => (
                  <TreeItem
                    key={childmenu?.menu_id}
                    nodeId={childmenu?.menu_id}
                    label={
                      <FormControlLabel
                        label={childmenu?.display_menu}
                        control={
                          <Checkbox
                            disabled={viewRole}
                            checked={allAlias.includes(childmenu.alias)}
                            onChange={(event: any) =>
                              onSelectMenu(event, childmenu.menu_id, childmenu.alias)
                            }
                          />
                        }
                      />
                    }
                  />
                ))}
              </TreeItem>
            ) : (
              <TreeItem
                key={menu?.menu_id}
                nodeId={menu?.menu_id}
                expandIcon={menu?.child_menu.length ? <ChevronRightIcon /> : <> </>}
                label={
                  <FormControlLabel
                    label={menu?.display_menu}
                    control={
                      <Checkbox
                        disabled={viewRole}
                        checked={allAlias.includes(menu.alias)}
                        onChange={(event: any) => onSelectMenu(event, menu.menu_id, menu.alias)}
                      />
                    }
                  />
                }
              />
            )
          )} */}
        </TreeView>
      </FormGroup>
    </MDDialog>
  );
}

export default AddUpdate;
