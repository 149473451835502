/* eslint-disable no-unused-vars */
import {
  LeadsAttributionData,
  LeadsDataProps,
  LeadsDensityData,
  LeadsIntentDataProps,
  LeadsVolumeDataProps,
  MaturedLocationResult,
  SelectedDateRange,
  SourceDataProps,
  SourceTypeProps,
} from "layouts/pages/leadsDashboard";

// eslint-disable-next-line import/prefer-default-export
export function getLastThreeMonths() {
  const months = [];
  const currentDate = new Date();
  let currentMonth = currentDate.getMonth();
  for (let i = 2; i >= 0; i--) {
    currentMonth--;
    if (currentMonth < 0) {
      currentMonth = 11;
      currentDate.setFullYear(currentDate.getFullYear() - 1);
    }
    const targetDate = new Date(currentDate.getFullYear(), currentMonth, 1);
    const monthName = targetDate.toLocaleString("default", { month: "long" });
    const year = targetDate.getFullYear();
    const formattedMonth = `${monthName} ${year}`;
    const month = new Date(`${monthName} 1, ${year}`).getMonth() + 1;
    const dateKey = month < 10 ? `${year}-0${month}` : `${year}-${month}`;
    const dateObj = {
      value: dateKey,
      label: formattedMonth,
    };
    months.push(dateObj);
  }
  return months;
}

export function getLastMonthYear() {
  const currentDate = new Date();
  const lastMonth = new Date(currentDate.getFullYear(), currentDate.getMonth() - 1);

  const year = lastMonth.getFullYear();
  const month = lastMonth.toLocaleString("default", { month: "long" });

  const formattedDate = {
    value: `${year}-${(lastMonth.getMonth() + 1).toString().padStart(2, "0")}`,
    label: `${month} ${year}`,
  };

  return formattedDate;
}

export const keys: string[] = [
  "Total Gross Revenue",
  "Revenue Occupied Chair",
  "Revenue Total Chair",
  "Lease Expense Revenue",
  "Total Chairs",
  "Occupied Chairs",
  "Occupancy Percentage",
  "Nps Score",
  "Nps Participants",
  "Leads",
  "Move Ins",
  "Move Outs",
];

export const rankKeys: string[] = [
  "income_rank",
  "revenue_occupied_chair_rank",
  "revenue_total_chair_rank",
  "occupied_chair_rank",
  "total_chair_rank",
  "occupancy_percentage_rank",
  "lease_expense_percentage_rank",
  "move_ins_rank",
  "move_outs_rank",
  "nps_score_rank",
  "nps_participants_rank",
  "leads_rank",
];

interface FormattedData {
  [key: string]: number | string;
}

export function formatScorecard(
  value: {
    [key: string]: number | string;
  },
  rankKeys: string[],
  count: number
) {
  const formattedData: FormattedData = { ...value };
  if (count) {
    rankKeys.map((key) => {
      const quadeValue = parseInt(formattedData[key] as string, 10);
      const calcQuartile = (quadeValue / count) * 100;
      formattedData[key] = `${formattedData[key]} out of ${count}`;
      const quartileKey = `${key}_quartile`;
      if (calcQuartile >= 0 && calcQuartile < 25) {
        formattedData[quartileKey] = "1st Quartile";
      } else if (calcQuartile >= 25 && calcQuartile <= 50) {
        formattedData[quartileKey] = "2nd Quartile";
      } else if (calcQuartile >= 50 && calcQuartile <= 75) {
        formattedData[quartileKey] = "3rd Quartile";
      } else {
        formattedData[quartileKey] = "4th Quartile";
      }

      return 0;
    });
  }
  return formattedData;
}

export interface SalonInformationProps {
  key: string;
  title: string;
}

export const revenueKeys: string[] = [
  "Total Gross Revenue",
  "Revenue Occupied Chair",
  "Revenue Total Chair",
  "Occupied Chair",
  "Total Chairs",
  "Occupancy Percentage",
  "Lease Expense % by Revenue",
];

export const performanceKeys: string[] = [
  "NPS Score",
  "NPS Participants",
  "Leads",
  "Move Ins",
  "Move Outs",
];

export const salonInformation: SalonInformationProps[] = [
  {
    key: "sola_id",
    title: "Sola ID",
  },
  {
    key: "opening_date",
    title: "Open Date",
  },
  {
    key: "contact_name",
    title: "Contact Name",
  },
  {
    key: "msa",
    title: "MSA",
  },
  {
    key: "region",
    title: "Region",
  },
  {
    key: "fbc",
    title: "Franchise Business Consultant",
  },
];

export const revenueKeysValues = [
  "total_gross",
  "revenue_occupied_chair",
  "revenue_total_chair",
  "occupied_chair",
  "total_chair",
  "occupancy_percentage",
  "lease_expense_percentage",
];

export const performanceKeysValues = [
  "nps_score",
  "nps_of_participants",
  "leads",
  "move_ins",
  "move_outs",
];

export const revenueKeysRank = [
  "income_rank",
  "revenue_occupied_chair_rank",
  "revenue_total_chair_rank",
  "occupied_chair_rank",
  "total_chair_rank",
  "occupancy_percentage_rank",
  "lease_expense_percentage_rank",
];

export const performanceKeysRank = [
  "nps_score_rank",
  "nps_participants_rank",
  "leads_rank",
  "move_ins_rank",
  "move_outs_rank",
];

export const revenueKeysQuartile = [
  "income_rank_quartile",
  "revenue_occupied_chair_rank_quartile",
  "revenue_total_chair_rank_quartile",
  "occupied_chair_rank_quartile",
  "total_chair_rank_quartile",
  "occupancy_percentage_rank_quartile",
  "lease_expense_percentage_rank_quartile",
];

export const performanceKeysQuartile = [
  "nps_score_rank_quartile",
  "nps_participants_rank_quartile",
  "leads_rank_quartile",
  "move_ins_rank_quartile",
  "move_outs_rank_quartile",
];

export const filterMetaData = {
  occupied_chair: ["More than 10", "More than 20", "More than 30", "More than 40", "More than 50"],
  total_chair: ["More than 10", "More than 20", "More than 30", "More than 40", "More than 50"],
  total_gross: [
    "More than 10000",
    "More than 20000",
    "More than 30000",
    "More than 40000",
    "More than 50000",
  ],
  royalty: ["More than 5000", "More than 10000", "More than 15000", "More than 20000"],
  marketing: ["More than 1000", "More than 2000", "More than 3000", "More than 4000"],
  revenue_occupied_chair: [
    "More than 1000",
    "More than 2000",
    "More than 3000",
    "More than 4000",
    "More than 5000",
  ],
  revenue_total_chair: [
    "More than 1000",
    "More than 2000",
    "More than 3000",
    "More than 4000",
    "More than 5000",
  ],
  open_chairs: ["More than 5", "More than 10", "More than 15", "More than 20"],
  nps_of_participants: ["More than 2", "More than 4", "More than 6", "More than 8", "More than 10"],
  occupancy_percentage: ["More than 25", "More than 50", "More than 75"],
  lease_expense_percentage: ["More than 25", "More than 50", "More than 75"],
  nps_score: ["More than 10", "More than 20", "More than 30", "More than 40", "More than 50"],
  move_ins: ["More than 0", "More than 2", "More than 4", "More than 6", "More than 8"],
  move_outs: ["Equal to 0", "More than -2", "More than -4", "More than -6", "More than -8"],
  leads: ["More than 5", "More than 10", "More than 15", "More than 20", "More than 25"],
  eligible: ["True", "False"],
};

const filterNonUpdateKeys = [
  "occupied_chair",
  "open_chairs",
  "royalty",
  "marketing",
  "leads",
  "move_ins",
  "move_outs",
  "lease_expense_percentage",
  "occupancy_percentage",
  "nps_score",
  "nps_of_participants",
  "revenue_total_chair",
  "revenue_occupied_chair",
  "total_gross",
  "total_chair",
  "occupied_chair",
];

export function getUniqueValues(scorecardData: { [key: string]: number | string }[]) {
  const uniqueValues: { [key: string]: string[] } = { ...filterMetaData };
  const data = [...scorecardData];
  data.forEach((item: { [key: string]: string }) => {
    Object.keys(item).forEach((key) => {
      if (!key.includes("rank") && !filterNonUpdateKeys.includes(key)) {
        if (!uniqueValues[key]) {
          uniqueValues[key] = [];
        }

        if (!uniqueValues[key].includes(item[key])) {
          uniqueValues[key].push(item[key]);
        }
      }
    });
  });
  return uniqueValues;
}

export function convertOpenDate(date: string) {
  const [year, month, day] = date.split("-");
  return `${day}/${month}/${year}`;
}

export function formatMonthYear(date: string) {
  const [year, month] = date.split("-");
  return `${year}-${month}`;
}

export function getRankValues(scorecardData: { [key: string]: number | string }[]) {
  const ranks: Record<string, number> = {
    income_rank: -Infinity,
    occupied_chair_rank: -Infinity,
    revenue_occupied_chair_rank: -Infinity,
    revenue_total_chair_rank: -Infinity,
    total_chair_rank: -Infinity,
    occupancy_percentage_rank: -Infinity,
    move_ins_rank: -Infinity,
    move_outs_rank: -Infinity,
    nps_score_rank: -Infinity,
    nps_participants_rank: -Infinity,
    leads_rank: -Infinity,
  };
  scorecardData.forEach((item) => {
    // eslint-disable-next-line no-restricted-syntax
    for (const rankKey in ranks) {
      if (rankKey in item) {
        const rankValue = parseInt(String(item[rankKey]), 10);
        if (rankValue > ranks[rankKey]) {
          ranks[rankKey] = rankValue;
        }
      }
    }
  });
  return ranks;
}

export function getDateRange(selectedOption: string) {
  const today = new Date();
  const startDate = new Date();

  switch (selectedOption) {
    case "Last month":
      startDate.setMonth(today.getMonth() - 1);
      break;
    case "Last 2 months":
      startDate.setMonth(today.getMonth() - 2);
      break;
    case "Last 3 months":
      startDate.setMonth(today.getMonth() - 3);
      break;
    case "Last 6 months":
      startDate.setMonth(today.getMonth() - 6);
      break;
    case "Last year":
      startDate.setFullYear(today.getFullYear() - 1);
      break;
    default:
      break;
  }
  const startYear = startDate.getFullYear();
  const startMonth = (startDate.getMonth() + 1).toString().padStart(2, "0");
  const startDateString = `${startYear}-${startMonth}`;

  today.setMonth(today.getMonth() - 1);
  const endYear = today.getFullYear();
  const endMonth = (today.getMonth() + 1).toString().padStart(2, "0");
  const endDateString = `${endYear}-${endMonth}`;

  return [startDateString, endDateString];
}

export const scoercardDateRange = [
  { value: "Last month" },
  { value: "Last 2 months" },
  { value: "Last 3 months" },
  { value: "Last 6 months" },
  { value: "Last year" },
];

export interface LeadsChartProps {
  labels: string[];
  datasets: {
    label: string;
    data: number[];
  };
  isCurrency?: boolean;
}
export const generateChartLabelsAndData = (
  selectedLead: LeadsDensityData,
  slicefrom: number,
  sliceTo: number,
  selectedYear: "current_year" | "last_year"
) => {
  const top5Data = Array.isArray(selectedLead)
    ? selectedLead.slice(slicefrom, sliceTo)
    : [selectedLead];
  const top5leads: number[] = top5Data.flatMap((item) =>
    item?.[selectedYear]
      ?.slice(slicefrom, sliceTo)
      .map((currentYearItem: LeadsDataProps) => Number(currentYearItem.leads))
  );
  const top5name: string[] = top5Data.flatMap((item) =>
    item?.[selectedYear]
      ?.slice(slicefrom, sliceTo)
      .map((currentYearItem: LeadsDataProps) => String(currentYearItem.name))
  );
  const chartData: LeadsChartProps = {
    labels: top5name,
    datasets: {
      label: "",
      data: top5leads,
    },
  };

  return chartData;
};

export const convertToFirstDayOfMonth = (dateString: string) => {
  const dateParts = dateString.split("-");
  const year = dateParts[0];
  const month = dateParts[1];
  const firstDayOfMonth = `${year}-${month}-01`;
  return firstDayOfMonth;
};

export const fetchCurrentDate = () => {
  const date = new Date().toJSON();
  return date.slice(0, 10);
};

export const convertToLastDayOfMonth = (dateString: string) => {
  const [year, month] = dateString.split("-");
  const adjustedMonth = month === "00" ? "01" : month;
  const adjustedYear = month === "00" ? String(Number(year)) : year;
  const firstDayOfMonth = new Date(`${adjustedYear}-${adjustedMonth}-01`);
  const lastDayOfMonth = new Date(firstDayOfMonth);
  lastDayOfMonth.setMonth(lastDayOfMonth.getMonth() + 1);
  lastDayOfMonth.setDate(lastDayOfMonth.getDate() - 1);
  const formattedDate = lastDayOfMonth.toISOString().substring(0, 10);
  return formattedDate;
};

export function getFirstDateOfCurrentMonth() {
  const currentDate = new Date();
  currentDate.setDate(1);

  const year = currentDate.getFullYear();
  const month = String(currentDate.getMonth() + 1).padStart(2, "0");
  const day = String(currentDate.getDate()).padStart(2, "0");

  return `${year}-${month}-${day}`;
}

interface ScorecardData {
  [key: string]: string | number;
}

interface SortingValueProps {
  [key: string]: string;
}

interface SortParams {
  id: string;
  desc: boolean;
}

export const sortingValues: SortingValueProps = {
  sola_id: "sola_id",
  property: "property",
  msa: "msa",
  contact_name: "contact_name",
  state: "state",
  region: "region",
  "Opening Date": "opening_date",
  fbc: "fbc",
  Income: "total_gross",
  "Revenue / Occupied Chair": "revenue_occupied_chair",
  "Lease Expense % by Revenue": "lease_expense_percentage",
  "Revenue / Total Chair": "revenue_total_chair",
  "Total Occupancy": "total_chair",
  Occupied: "occupied_chair",
  "Occupancy Percentage": "occupancy_percentage",
  "NPS Score": "nps_score",
  "NPS Of Participants": "nps_of_participants",
  "Move Ins": "move_ins",
  "Move Outs": "move_outs",
  "Property Name": "propertyname",
  Leads: "leads",
  "SSS Eligible": "eligible",
};

export const columnsToCast: string[] = [
  "total_gross",
  "revenue_occupied_chair",
  "revenue_total_chair",
  "total_chair",
  "occupied_chair",
  "occupancy_percentage",
  "lease_expense_percentage",
  "nps_score",
  "nps_of_participants",
  "move_ins",
  "move_outs",
  "leads",
  "deal_location_id",
  "sola_location_id",
];
export interface SourceTypeChart {
  labels: string[];
  datasets: {
    label: string;
    backgroundColors: string[];
    data: number[];
  };
}
export const SourceType = (selectedSourceType: SourceDataProps[]) => {
  const originalSourceType: string[] = selectedSourceType?.map((item) =>
    String(item.original_source_type)
  );
  const countSourceType: number[] = selectedSourceType?.map((item) => Number(item.count));
  const SourceTypeChartData: SourceTypeChart = {
    labels: originalSourceType,
    datasets: {
      label: "Source Type",
      backgroundColors: [
        "primary",
        "secondary",
        "info",
        "success",
        "warning",
        "error",
        "light",
        "dark",
      ],
      data: countSourceType,
    },
  };
  return SourceTypeChartData;
};

export const columnsToCastOccupancy: string[] = [
  "total_chairs",
  "occupied_chairs",
  "occupancy_percentage",
  "move_ins",
  "move_outs",
  "open_chairs",
];

export const columnsToCastRoyalty: string[] = [
  "total_gross",
  "royalty",
  "marketing",
  "royalty_rate",
  "marketing_rate",
];

export const sortingValuesOccupancy: SortingValueProps = {
  sola_id: "sola_id",
  location: "location",
  property: "property",
  total_chairs: "total_chairs",
  occupied_chairs: "occupied_chairs",
  occupancy_percentage: "occupancy_percentage",
  move_ins: "move_ins",
  move_outs: "move_outs",
  open_chairs: "open_chairs",
};

export const sortingValuesRoyalty: SortingValueProps = {
  sola_id: "sola_id",
  location: "location",
  property: "property",
  Income: "total_gross",
  locationid: "locationid",
  Royalty: "royalty",
  Marketing: "marketing",
  "Royalty Rate": "royalty_rate",
  "Marketing Rate": "marketing_rate",
};

export function getFBC(scorecardData: ScorecardData[]) {
  const distinctFbcValues = [...new Set(scorecardData.map((obj: any) => obj.fbc))];

  const filteredAndFormattedArray = scorecardData
    .filter((data) => data.fbc !== null)
    .reduce((accumulator, data) => {
      if (!accumulator.find((item) => item.label === data.fbc)) {
        accumulator.push({ label: data.fbc, value: data.fbc });
      }
      return accumulator;
    }, []);

  return filteredAndFormattedArray;
}

export function getUniqueValuesOccupancy(occupancyData: { [key: string]: number | string }[]) {
  const uniqueValues: { [key: string]: string[] } = { ...filterMetaData };
  const data = [...occupancyData];
  data.forEach((item: { [key: string]: string }) => {
    Object.keys(item).forEach((key) => {
      if (!key.includes("rank") && !filterNonUpdateKeys.includes(key)) {
        if (!uniqueValues[key]) {
          uniqueValues[key] = [];
        }

        if (!uniqueValues[key].includes(item[key])) {
          uniqueValues[key].push(item[key]);
        }
      }
    });
  });
  return uniqueValues;
}

export function getUniqueValuesRoyalty(royaltyData: { [key: string]: number | string }[]) {
  const uniqueValues: { [key: string]: string[] } = { ...filterMetaData };
  const data = [...royaltyData];
  data.forEach((item: { [key: string]: string }) => {
    Object.keys(item).forEach((key) => {
      if (!key.includes("rank") && !filterNonUpdateKeys.includes(key)) {
        if (!uniqueValues[key]) {
          uniqueValues[key] = [];
        }

        if (!uniqueValues[key].includes(item[key]) && item[key] !== null) {
          uniqueValues[key].push(item[key]);
        }
      }
    });
  });
  return uniqueValues;
}

export function getYears(selectedDateRange: SelectedDateRange, currentDateCheckBox: boolean) {
  const endMonth = Number(selectedDateRange.endMonth.split("-")[0]);
  const currentYear = currentDateCheckBox ? new Date().getFullYear() : endMonth;

  const previousYear = currentYear - 1;
  const yearArray = [currentYear.toString(), previousYear.toString()];

  return yearArray;
}

export function calculateMaturedAndUnmaturedLocations(data: MaturedLocationResult) {
  const currentDate = new Date();

  function isLocationMatured(openDate: string) {
    const locationDate = new Date(openDate);
    locationDate.setMonth(locationDate.getMonth() + 18);
    return locationDate <= currentDate;
  }

  const currentYearLocations = data.current_year;
  const maturedCurrentYear = currentYearLocations.filter((location) =>
    isLocationMatured(location.open_date)
  ).length;
  const unmaturedCurrentYear = currentYearLocations.length - maturedCurrentYear;

  const lastYearLocations = data.last_year;
  const maturedLastYear = lastYearLocations.filter((location) =>
    isLocationMatured(location.open_date)
  ).length;
  const unmaturedLastYear = lastYearLocations.length - maturedLastYear;

  const maturedLocations = [maturedCurrentYear, maturedLastYear];
  const unmaturedLocations = [unmaturedCurrentYear, unmaturedLastYear];

  return { maturedLocations, unmaturedLocations };
}

export const leadsAttribution = [
  "source_gmb",
  "source_national",
  "source_search",
  "source_email",
  "source_paid_other",
  "source_3rdparty",
  "source_other",
  "source_unknown",
];

export function createAttributionObject(data: LeadsAttributionData) {
  const resultObject: { [key: string]: [number, number] } = {};

  data.current_year.forEach((item) => {
    const key = item.source.toLowerCase();
    if (resultObject[key]) {
      resultObject[key][0] += parseInt(item.count, 10);
    } else {
      resultObject[key] = [parseInt(item.count, 10), 0];
    }
  });

  data.last_year.forEach((item) => {
    const key = item.source.toLowerCase();
    if (resultObject[key]) {
      resultObject[key][1] += parseInt(item.count, 10);
    } else {
      resultObject[key] = [0, parseInt(item.count, 10)];
    }
  });

  return resultObject;
}

export interface LeadsAttributionTable {
  currentYearData: {
    source: string;
    value: number;
  }[];
  lastYearData: {
    source: string;
    value: number;
  }[];
}

export function convertAttributionData(data: LeadsAttributionData) {
  const currentData: { [key: string]: number } = {};
  const lastData: { [key: string]: number } = {};
  leadsAttribution.forEach((key) => {
    currentData[key] = 0;
    lastData[key] = 0;
  });

  data.current_year.forEach((source) => {
    const key = source.source.toLowerCase();
    const value = parseInt(source.count, 10) || 0;
    currentData[key] = value;
  });

  data.last_year.forEach((source) => {
    const key = source.source.toLowerCase();
    const value = parseInt(source.count, 10) || 0;
    lastData[key] = value;
  });

  const currentYearData = leadsAttribution.map((key) => ({
    source: key,
    value: currentData[key],
  }));

  const lastYearData = leadsAttribution.map((key) => ({
    source: key,
    value: lastData[key],
  }));
  return {
    currentYearData,
    lastYearData,
  };
}

export const leadsB2B = [
  "Request leasing information",
  "Request Leasing Information",
  "Slick lead",
  "Request leasing Info",
  "Request leasking info",
  "Request Leasking info",
  "leasing info",
  "Lead reached out via phone call",
  "tour",
  "Request a tour",
];
export function createB2BObject(data: LeadsAttributionData) {
  const resultObject: { [key: string]: [number, number] } = {};

  function getKey(item: string) {
    return item.split(" ").join("");
  }
  leadsB2B.forEach((item) => {
    resultObject[getKey(item)] = [0, 0];
  });
  data.current_year.forEach((item) => {
    const key = getKey(item.how_can_we_help_you);
    if (resultObject[key]) {
      resultObject[key][0] += parseInt(item.occurrence_count, 10);
    } else {
      resultObject[key] = [parseInt(item.occurrence_count, 10), 0];
    }
  });

  data.last_year.forEach((item) => {
    const key = getKey(item.how_can_we_help_you);
    if (resultObject[key]) {
      resultObject[key][1] += parseInt(item.occurrence_count, 10);
    } else {
      resultObject[key] = [0, parseInt(item.occurrence_count, 10)];
    }
  });
  return resultObject;
}

export interface LeadsB2BTable {
  currentYearData: {
    how_can_we_help_you: string;
    occurrence_count: number;
  }[];
  lastYearData: {
    how_can_we_help_you: string;
    occurrence_count: number;
  }[];
}

export function convertB2BData(data: LeadsAttributionData) {
  const currentData: { [key: string]: number } = {};
  const lastData: { [key: string]: number } = {};
  leadsB2B.forEach((key) => {
    currentData[key] = 0;
    lastData[key] = 0;
  });

  data.current_year.forEach((item) => {
    const key = item.how_can_we_help_you;
    const value = parseInt(item.occurrence_count, 10) || 0;
    currentData[key] = value;
  });

  data.last_year.forEach((item) => {
    const key = item.how_can_we_help_you;
    const value = parseInt(item.occurrence_count, 10) || 0;
    lastData[key] = value;
  });

  const currentYearData = leadsB2B.map((key) => ({
    how_can_we_help_you: key,
    occurrence_count: currentData[key],
  }));

  const lastYearData = leadsB2B.map((key) => ({
    how_can_we_help_you: key,
    occurrence_count: lastData[key],
  }));
  return {
    currentYearData,
    lastYearData,
  };
}
export const leadsB2C = ["Book an appointment with a salon professional", "Others"];
export interface LeadsB2CTable {
  currentYearData: {
    help_category: string;
    occurrence_count: number;
  }[];
  lastYearData: {
    help_category: string;
    occurrence_count: number;
  }[];
}

export function createB2CObject(data: LeadsAttributionData) {
  const resultObject: { [key: string]: [number, number] } = {};

  function getKey(item: string) {
    return item.split(" ").join("");
  }
  leadsB2C.forEach((item) => {
    resultObject[getKey(item)] = [0, 0];
  });
  data.current_year.forEach((item) => {
    const key = getKey(item.help_category);
    if (resultObject[key]) {
      resultObject[key][0] += parseInt(item.occurrence_count, 10);
    } else {
      resultObject[key] = [parseInt(item.occurrence_count, 10), 0];
    }
  });

  data.last_year.forEach((item) => {
    const key = getKey(item.help_category);
    if (resultObject[key]) {
      resultObject[key][1] += parseInt(item.occurrence_count, 10);
    } else {
      resultObject[key] = [0, parseInt(item.occurrence_count, 10)];
    }
  });
  return resultObject;
}

export function convertB2CData(data: LeadsAttributionData) {
  const currentData: { [key: string]: number } = {};
  const lastData: { [key: string]: number } = {};
  leadsB2C.forEach((key) => {
    currentData[key] = 0;
    lastData[key] = 0;
  });

  data.current_year.forEach((item) => {
    const key = item.help_category;
    const value = parseInt(item.occurrence_count, 10) || 0;
    currentData[key] = value;
  });

  data.last_year.forEach((item) => {
    const key = item.help_category;
    const value = parseInt(item.occurrence_count, 10) || 0;
    lastData[key] = value;
  });

  const currentYearData = leadsB2C.map((key) => ({
    help_category: key,
    occurrence_count: currentData[key],
  }));

  const lastYearData = leadsB2C.map((key) => ({
    help_category: key,
    occurrence_count: lastData[key],
  }));
  return {
    currentYearData,
    lastYearData,
  };
}
export const leadsDensity = [
  "Less Than 1 Lead",
  "Leads Equals To 1",
  "Leads 2 to 5",
  "Leads 5 to 10",
  "Leads More Than 10",
];
export function countLeadsByRange(data: LeadsDensityData) {
  const resultObject: { [key: string]: [number, number] } = {
    LessThan1Lead: [0, 0],
    LeadsEqualsTo1: [0, 0],
    Leads2to5: [0, 0],
    Leads5to10: [0, 0],
    LeadsMoreThan10: [0, 0],
  };
  function updateCounts(lead: number, isCurrentYear: boolean) {
    if (lead === 0) {
      resultObject.LessThan1Lead[isCurrentYear ? 0 : 1] += 1;
    } else if (lead === 1) {
      resultObject.LeadsEqualsTo1[isCurrentYear ? 0 : 1] += 1;
    } else if (lead >= 2 && lead <= 5) {
      resultObject.Leads2to5[isCurrentYear ? 0 : 1] += 1;
    } else if (lead >= 5 && lead <= 10) {
      resultObject.Leads5to10[isCurrentYear ? 0 : 1] += 1;
    } else {
      resultObject.LeadsMoreThan10[isCurrentYear ? 0 : 1] += 1;
    }
  }

  if (Array.isArray(data.current_year)) {
    data.current_year.forEach((item) => {
      const lead = parseInt(item.leads, 10);
      updateCounts(lead, true);
    });
  }

  if (Array.isArray(data.last_year)) {
    data.last_year.forEach((item) => {
      const lead = parseInt(item.leads, 10);
      updateCounts(lead, false);
    });
  }
  const result: { [key: string]: [number, number] } = {
    LessThan1Lead: resultObject.LessThan1Lead,
    LeadsEqualsTo1: resultObject.LeadsEqualsTo1,
    Leads2to5: resultObject.Leads2to5,
    Leads5to10: resultObject.Leads5to10,
    LeadsMoreThan10: resultObject.LeadsMoreThan10,
  };
  return result;
}
export interface LeadsCountByRangeTable {
  currentYearData: {
    Leads: string;
    count: number;
  }[];
  lastYearData: {
    Leads: string;
    count: number;
  }[];
}
export function convertcountLeadsByRange(data: LeadsDensityData): LeadsCountByRangeTable {
  const currentData: { [key: string]: number } = {};
  const lastData: { [key: string]: number } = {};

  leadsDensity.forEach((key) => {
    currentData[key] = 0;
    lastData[key] = 0;
  });

  data.current_year.forEach((item) => {
    const key = parseInt(item.leads, 10);
    const value = key;
    if (value === 0) {
      currentData["Less than 1 lead"] += 1;
    } else if (value === 1) {
      currentData["Leads Equals To 1"] += 1;
    } else if (value >= 2 && value <= 5) {
      currentData["Leads 2 to 5"] += 1;
    } else if (value > 5 && value <= 10) {
      currentData["Leads 5 to 10"] += 1;
    } else {
      currentData["Leads More Than 10"] += 1;
    }
  });
  data.last_year.forEach((item) => {
    const key = parseInt(item.leads, 10);
    const value = key;
    if (value === 0) {
      currentData["Less than 1 lead"] += 1;
    } else if (value === 1) {
      lastData["Leads Equals To 1"] += 1;
    } else if (value >= 2 && value <= 5) {
      lastData["Leads 2 to 5"] += 1;
    } else if (value > 5 && value <= 10) {
      lastData["Leads 5 to 10"] += 1;
    } else {
      lastData["Leads More Than 10"] += 1;
    }
  });

  const currentYearData = leadsDensity.map((key) => ({
    Leads: key,
    count: currentData[key],
  }));

  const lastYearData = leadsDensity.map((key) => ({
    Leads: key,
    count: lastData[key],
  }));
  return {
    currentYearData,
    lastYearData,
  };
}

export const sourceType = [
  "DIRECT_TRAFFIC",
  "EMAIL_MARKETING",
  "OFFLINE",
  "ORGANIC_SEARCH",
  "OTHER_CAMPAIGNS",
  "PAID_SEARCH",
  "PAID_SOCIAL",
  "REFERRALS",
  "SOCIAL_MEDIA",
];

export function createSourceTypeObject(data: SourceTypeProps) {
  const resultObject: { [key: string]: [number, number] } = {};
  sourceType.forEach((item) => {
    resultObject[item] = [0, 0];
  });
  data.current_year.forEach((item) => {
    const key = item.original_source_type;
    if (resultObject[key]) {
      resultObject[key][0] += parseInt(item.count, 10);
    } else {
      resultObject[key] = [parseInt(item.count, 10), 0];
    }
  });

  data.last_year.forEach((item) => {
    const key = item.original_source_type;
    if (resultObject[key]) {
      resultObject[key][1] += parseInt(item.count, 10);
    } else {
      resultObject[key] = [0, parseInt(item.count, 10)];
    }
  });
  return resultObject;
}
export interface SourceTypeTable {
  currentYearData: {
    original_source_type: string;
    count: string;
  }[];
  lastYearData: {
    original_source_type: string;
    count: string;
  }[];
}

export function convertSourceTypeData(data: SourceTypeProps) {
  const currentData: { [key: string]: number } = {};
  const lastData: { [key: string]: number } = {};
  sourceType.forEach((key) => {
    currentData[key] = 0;
    lastData[key] = 0;
  });

  data.current_year.forEach((source) => {
    const key = source.original_source_type;
    const value = parseInt(source.count, 10) || 0;
    currentData[key] = value;
  });

  data.last_year.forEach((source) => {
    const key = source.original_source_type;
    const value = parseInt(source.count, 10) || 0;
    lastData[key] = value;
  });
  const currentYearData = sourceType.map((key) => ({
    original_source_type: key,
    count: String(currentData[key]),
  }));

  const lastYearData = sourceType.map((key) => ({
    original_source_type: key,
    count: String(lastData[key]),
  }));
  return {
    currentYearData,
    lastYearData,
  };
}
export const leadsVolume = ["Contact Us", "Locations", "Going Independent", "Why Sola", "Other"];
export function createLeadsVolumeObject(data: LeadsVolumeDataProps) {
  const resultObject: { [key: string]: [number, number] } = {};

  function getKey(item: string) {
    return item.split(" ").join("");
  }
  leadsVolume.forEach((item) => {
    resultObject[getKey(item)] = [0, 0];
  });
  data.current_year.forEach((item) => {
    const key = getKey(item.form_type);
    if (resultObject[key]) {
      resultObject[key][0] += parseInt(item.count, 10);
    } else {
      resultObject[key] = [parseInt(item.count, 10), 0];
    }
  });
  data.last_year.forEach((item) => {
    const key = getKey(item.form_type);
    if (resultObject[key]) {
      resultObject[key][1] += parseInt(item.count, 10);
    } else {
      resultObject[key] = [0, parseInt(item.count, 10)];
    }
  });
  return resultObject;
}
export interface LeadsVolumeTable {
  currentYearData: {
    form_type: string;
    count: string;
  }[];
  lastYearData: {
    form_type: string;
    count: string;
  }[];
}

export function convertLeadsVolumeTableObject(data: LeadsVolumeDataProps) {
  const currentData: { [key: string]: number } = {};
  const lastData: { [key: string]: number } = {};
  leadsVolume.forEach((key) => {
    currentData[key] = 0;
    lastData[key] = 0;
  });

  data.current_year.forEach((item) => {
    const key = item.form_type;
    const value = parseInt(item.count, 10) || 0;
    currentData[key] = value;
  });

  data.last_year.forEach((item) => {
    const key = item.form_type;
    const value = parseInt(item.count, 10) || 0;
    lastData[key] = value;
  });

  const currentYearData = leadsVolume.map((key) => ({
    form_type: key,
    count: String(currentData[key]),
  }));

  const lastYearData = leadsVolume.map((key) => ({
    form_type: key,
    count: String(lastData[key]),
  }));
  return {
    currentYearData,
    lastYearData,
  };
}

export const leadsIntentData = ["intent_b2b", "intent_b2c", "intent_other"];
export function createLeadsIntentObject(data: LeadsIntentDataProps) {
  const resultObject: { [key: string]: [number, number] } = {};
  leadsIntentData.forEach((item) => {
    resultObject[item] = [0, 0];
  });
  data.current_year.forEach((item) => {
    const key = item.intent_category;
    if (resultObject[key]) {
      resultObject[key][0] += parseInt(item.count, 10);
    } else {
      resultObject[key] = [parseInt(item.count, 10), 0];
    }
  });

  data.last_year.forEach((item) => {
    const key = item.intent_category;
    if (resultObject[key]) {
      resultObject[key][1] += parseInt(item.count, 10);
    } else {
      resultObject[key] = [0, parseInt(item.count, 10)];
    }
  });
  return resultObject;
}

export interface LeadsIntentTable {
  currentYearData: {
    intent_category: string;
    count: string;
  }[];
  lastYearData: {
    intent_category: string;
    count: string;
  }[];
}
export function convertLeadsIntentTableObject(data: LeadsIntentDataProps) {
  const currentData: { [key: string]: number } = {};
  const lastData: { [key: string]: number } = {};
  leadsIntentData.forEach((key) => {
    currentData[key] = 0;
    lastData[key] = 0;
  });
  function formatFormType(formType: string): string {
    return formType.replace(/_/g, " ").replace(/\b\w/g, (char) => char.toUpperCase());
  }

  data.current_year.forEach((item) => {
    const key = item.intent_category;
    const value = parseInt(item.count, 10) || 0;
    currentData[key] = value;
  });

  data.last_year.forEach((item) => {
    const key = item.intent_category;
    const value = parseInt(item.count, 10) || 0;
    lastData[key] = value;
  });

  const currentYearData = leadsIntentData.map((key) => ({
    intent_category: formatFormType(key),
    count: String(currentData[key]),
  }));

  const lastYearData = leadsIntentData.map((key) => ({
    intent_category: formatFormType(key),
    count: String(lastData[key]),
  }));
  return {
    currentYearData,
    lastYearData,
  };
}

export const leadsAttributionCSV = {
  Source_GMB: "GMB / Organic ",
  Source_national: "National Ad Words",
  Source_Paid_Other: "Facebook / Social",
  Source_3rdParty: "3rd Party",
  Source_Search: "Bing",
  Source_Email: "email",
  Source_Other: "craigslist",
  Source_Unknown: "Unknown",
};

export function generateLocationData(selectedLead: any): any {
  const currentDate = new Date();

  const matureStoresCountCurrentYear = selectedLead.current_year.filter((location: any) => {
    const openDate = new Date(location.open_date);
    const monthsDiff =
      (currentDate.getFullYear() - openDate.getFullYear()) * 12 +
      currentDate.getMonth() -
      openDate.getMonth();
    return monthsDiff >= 18;
  }).length;

  const unmatureStoresCountCurrentYear =
    selectedLead.current_year.length - matureStoresCountCurrentYear;

  const matureStoresCountLastYear = selectedLead.last_year.filter((location: any) => {
    const openDate = new Date(location.open_date);
    const monthsDiff =
      (currentDate.getFullYear() - openDate.getFullYear()) * 12 +
      currentDate.getMonth() -
      openDate.getMonth();
    return monthsDiff >= 18;
  }).length;

  const unmatureStoresCountLastYear = selectedLead.last_year.length - matureStoresCountLastYear;

  const totalOpenStoresCountCurrentYear = selectedLead.current_year.length;
  const totalOpenStoresCountLastYear = selectedLead.last_year.length;

  const locationData = {
    matureStoresCountCurrentYear,
    unmatureStoresCountCurrentYear,
    matureStoresCountLastYear,
    unmatureStoresCountLastYear,
    totalOpenStoresCountCurrentYear,
    totalOpenStoresCountLastYear,
  };

  return locationData;
}

export function generateLeadDensityData(locations: any): any {
  let leads0 = 0;
  let leads1 = 0;
  let leads2to5 = 0;
  let leads5to10 = 0;
  let leads10plus = 0;
  let totalStoresWithLeads = 0;
  let leads0LastYear = 0;
  let leads1LastYear = 0;
  let leads2to5LastYear = 0;
  let leads5to10LastYear = 0;
  let leads10plusLastYear = 0;
  let totalStoresWithLeadsLastYear = 0;

  locations.current_year.forEach((location: any) => {
    const leadsCount = parseInt(location.leads, 10);

    if (leadsCount === 0) {
      leads0++;
    } else if (leadsCount === 1) {
      leads1++;
    } else if (leadsCount >= 2 && leadsCount <= 5) {
      leads2to5++;
    } else if (leadsCount > 5 && leadsCount <= 10) {
      leads5to10++;
    } else if (leadsCount > 10) {
      leads10plus++;
    }

    // Check if the location has at least 1 lead
    if (leadsCount > 0) {
      totalStoresWithLeads++;
    }
  });

  locations.last_year.forEach((location: any) => {
    const leadsCount = parseInt(location.leads, 10);

    if (leadsCount === 0) {
      leads0LastYear++;
    } else if (leadsCount === 1) {
      leads1LastYear++;
    } else if (leadsCount >= 2 && leadsCount <= 5) {
      leads2to5LastYear++;
    } else if (leadsCount > 5 && leadsCount <= 10) {
      leads5to10LastYear++;
    } else if (leadsCount > 10) {
      leads10plusLastYear++;
    }

    if (leadsCount > 0) {
      totalStoresWithLeadsLastYear++;
    }
  });

  const leadDensityData = {
    "0_lead": { current_year: leads0, last_year: leads0LastYear },
    "1_lead": { current_year: leads1, last_year: leads1LastYear },
    "2_5_leads": { current_year: leads2to5, last_year: leads2to5LastYear },
    "5_10_leads": { current_year: leads5to10, last_year: leads5to10LastYear },
    "10_plus_leads": { current_year: leads10plus, last_year: leads10plusLastYear },
    total_stores_with_leads: {
      current_year: totalStoresWithLeads,
      last_year: totalStoresWithLeadsLastYear,
    },
  };

  return leadDensityData;
}

export const leadsVolumeCSV = {
  "Contact Us": "Lead Capture - Contact Us",
  "Why Sola": "Lead Capture - Why Sola",
  Locations: "Lead Capture - Location Detail",
  "Going Independent": "Lead Capture - Going Independent",
  Other: "Lead Capture - Other / UNK",
};
export const leadsIntentCSV = {
  intent_b2b: "Leads Captured B2B w/ B2B Intent",
  intent_b2c: "Leads Captured B2B w/ B2C Intent",
  intent_other: "Leads Lost / Not Captured Properly",
};
export function leadsFormatted(leadsVolume: any): any {
  let leadsVolumeCurrentYearTotal = 0;
  let leadsVolumeLastYearTotal = 0;
  leadsVolume.current_year.forEach((item: any) => {
    leadsVolumeCurrentYearTotal += parseInt(item.count, 10) || 0;
  });
  leadsVolume.last_year.forEach((item: any) => {
    leadsVolumeLastYearTotal += parseInt(item.count, 10) || 0;
  });
  const percentageDenominator = leadsVolumeLastYearTotal;
  const percentage =
    percentageDenominator !== 0
      ? ((leadsVolumeCurrentYearTotal - leadsVolumeLastYearTotal) / percentageDenominator) * 100
      : "N/A";
  const FormattedleadsVolume = {
    current_year: leadsVolumeCurrentYearTotal,
    last_year: leadsVolumeLastYearTotal,
    percentage: percentage !== "N/A" ? `${(percentage as number).toFixed(2)}%` : "N/A",
  };
  return FormattedleadsVolume;
}

export function getleadsIntent(data: any, value: string) {
  const intentCurrentYear: { intent_category: string; count: number } = data.current_year.find(
    (entry: any) => entry.intent_category === value
  ) || {
    count: 0,
  };
  const intentLastYear: { intent_category: string; count: number } = data.last_year.find(
    (entry: any) => entry.intent_category === value
  ) || {
    count: 0,
  };
  const currentYearCount = intentCurrentYear.count;
  const lastYearCount = intentLastYear.count;
  return {
    currentYear: currentYearCount,
    lastYear: lastYearCount,
  };
}
