import { Card } from "@mui/material";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import DataTable from "examples/Tables/DataTable";
import { getLocalDateTime } from "helper/services";
import { useEffect, useState } from "react";
import { useAppSelector } from "store/store";

function PastCharges(): JSX.Element {
  const { billingTabDetail } = useAppSelector((state) => state.solaProSlice);
  const [pastChargesList, setPastChargesList] = useState<any>([]);

  useEffect(() => {
    if (billingTabDetail && billingTabDetail?.pastChargesList) {
      setPastChargesList(billingTabDetail?.pastChargesList);
    }
  }, [billingTabDetail]);

  const getRemainingAmount = (amount: any, paidAmount: any) => amount - paidAmount;

  const getRows = () => {
    const rows: any = pastChargesList.map((obj: any) => ({
      date: obj?.TransactionDate ? getLocalDateTime(obj?.TransactionDate) : "-",
      type: obj?.TransactionType ? obj?.TransactionType : "-",
      amount: obj?.Amount ? obj?.Amount : "-",
      paid: obj?.AmountAllocated ? obj?.AmountAllocated : "-",
      remaining:
        obj?.AmountAllocated && obj?.Amount
          ? getRemainingAmount(obj?.Amount, obj?.AmountAllocated)
          : 0,
    }));
    return rows;
  };

  const tableData = {
    columns: [
      { Header: "Date", accessor: "date", width: "25%" },
      { Header: "Type", accessor: "type", width: "20%" },
      { Header: "Amount", accessor: "amount", width: "20%" },
      { Header: "Paid", accessor: "paid", width: "15%" },
      { Header: "Remaining", accessor: "remaining", width: "15%" },
    ],
    rows: getRows(),
  };

  return (
    <Card id="past-charges" sx={{ overflow: "visible" }}>
      <MDBox p={3}>
        <MDTypography variant="h5">Past Charges</MDTypography>
      </MDBox>
      <DataTable table={tableData} canSearch searchText="Search..." />
    </Card>
  );
}

export default PastCharges;
