import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import { Grid } from "@mui/material";

function Index(): JSX.Element {
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox textAlign="center">
        <Grid
          container
          spacing={0}
          direction="column"
          alignItems="center"
          justifyContent="center"
          style={{ minHeight: "70vh" }}
        >
          <MDTypography fontWeight="bold" color="dark">
            Oops!
          </MDTypography>
          <MDTypography fontWeight="bold" color="dark">
            No Access!!
          </MDTypography>
        </Grid>
      </MDBox>
    </DashboardLayout>
  );
}

export default Index;
