import { Checkbox, FormControl, FormControlLabel, FormGroup } from "@mui/material";
import Grid from "@mui/material/Grid";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

function Preferences(props: any): JSX.Element {
  const { accountDetail, handleSingleCheckbox } = props;
  return (
    <MDBox>
      <MDBox textAlign="center" mx="auto" my={4}>
        <MDBox mb={1}>
          <MDTypography variant="h5" fontWeight="regular">
            Preferences
          </MDTypography>
        </MDBox>
      </MDBox>
      <MDBox mt={2}>
        <Grid container spacing={3}>
          <Grid item>
            <FormControl component="fieldset">
              <FormGroup aria-label="position" row>
                <FormControlLabel
                  value="end"
                  control={
                    <Checkbox
                      checked={accountDetail?.accepting_new_client || false}
                      name="accepting_new_client"
                      onChange={handleSingleCheckbox}
                      inputProps={{ "aria-label": "controlled" }}
                    />
                  }
                  label="Accepting new client"
                  labelPlacement="end"
                />
              </FormGroup>
            </FormControl>
          </Grid>
          <Grid item>
            <FormControl component="fieldset">
              <FormGroup aria-label="position" row>
                <FormControlLabel
                  value="end"
                  control={
                    <Checkbox
                      checked={accountDetail?.accepting_walkins || false}
                      name="accepting_walkins"
                      onChange={handleSingleCheckbox}
                      inputProps={{ "aria-label": "controlled" }}
                    />
                  }
                  label="Accepting Wals-Ins"
                  labelPlacement="end"
                />
              </FormGroup>
            </FormControl>
          </Grid>
          <Grid item>
            <FormControl component="fieldset">
              <FormGroup aria-label="position" row>
                <FormControlLabel
                  value="end"
                  control={
                    <Checkbox
                      checked={accountDetail?.visible_on_website || false}
                      name="visible_on_website"
                      onChange={handleSingleCheckbox}
                      inputProps={{ "aria-label": "controlled" }}
                    />
                  }
                  label="Wisible on Website"
                  labelPlacement="end"
                />
              </FormGroup>
            </FormControl>
          </Grid>
        </Grid>
      </MDBox>
    </MDBox>
  );
}

export default Preferences;
