import { createSlice, Draft, PayloadAction } from "@reduxjs/toolkit";

interface InitialState {
  connectUsers: any;
  userDetails: Array<[]>;
  defaultSelectedColumns: any;
  defaultExportData: any;
}

const initialState: InitialState = {
  connectUsers: [],
  userDetails: [],
  defaultSelectedColumns: [],
  defaultExportData: null,
};

const connectUsersSlice = createSlice({
  name: "connectUsers",
  initialState,
  reducers: {
    connectUsers: (state: Draft<InitialState>, action: PayloadAction<any>) => ({
      ...state,
      connectUsers: action.payload,
    }),
    // Set default colums data
    setDefaultsColumns: (state: Draft<InitialState>, action: PayloadAction<any>) => ({
      ...state,
      defaultSelectedColumns: action?.payload?.table_columns || [],
      defaultExportData: action?.payload?.default_export_data || null,
    }),
  },
});

export const { connectUsers, setDefaultsColumns } = connectUsersSlice.actions;

export default connectUsersSlice.reducer;
