import { useAppSelector } from "store/store";
import { useEffect, useState } from "react";
import MDDialog from "components/MDDialog";
import { Grid, Icon } from "@mui/material";
import MDTypography from "components/MDTypography";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import MDBox from "components/MDBox";
import ImagePreview from "layouts/pages/ImagePreview";
import { capitalizeFirstLetter, getLocalDateTime } from "helper/services";

function ViewDetails(props: any): JSX.Element {
  const { open, onClose } = props;
  const { solaProDetails } = useAppSelector((state: any) => state.solaProSlice);
  const [solaProsData, setSolaProsData] = useState<any>({});
  const [isPreview, setImagePreviewPopup] = useState(false);
  const [imageToPreview, setImageToPreview] = useState("");

  useEffect(() => {
    if (solaProDetails) {
      setSolaProsData(solaProDetails);
    }
  }, [solaProDetails]);

  const dialogProps = {
    open,
    onClose,
    saveBtn: false,
    title: "View Sola Pros",
    size: "lg",
  };

  const previewImage = (image: any) => {
    setImagePreviewPopup(true);
    setImageToPreview(image);
  };

  const closeImagePreview = () => {
    setImagePreviewPopup(false);
    setImageToPreview("");
  };

  const getIcon = (value: any) => {
    if (value) {
      return (
        <Icon fontSize="medium" color="success">
          check_circle_rounded
        </Icon>
      );
    }
    return (
      <Icon fontSize="medium" color="error">
        cancel
      </Icon>
    );
  };

  const getImageElement = (name: any, imageData: any) => {
    if (imageData) {
      return (
        <Grid item xs={12} sm={6} md={6}>
          <MDTypography fontWeight="bold" variant="button">
            {name}
          </MDTypography>
          <Grid container mt={2}>
            <Grid item xs={12} sm={6} md={6}>
              {imageData && (
                <Grid
                  container
                  onClick={() => previewImage(imageData)}
                  justifyContent="center"
                  alignItems="center"
                  display="flex"
                  className="thumbnail-img"
                >
                  <Grid item>
                    <img height={100} width={100} src={imageData} alt="blog" />
                  </Grid>
                </Grid>
              )}
            </Grid>
          </Grid>
        </Grid>
      );
    }
    return null;
  };

  const getDetailElement = (name: any, value: any) => (
    <Grid item xs={12} sm={6} md={6}>
      <MDTypography fontWeight="bold" variant="button">
        {name}
      </MDTypography>
      <MDBox sx={{ wordBreak: "break-all" }}>{value ? value : "-"}</MDBox>
    </Grid>
  );

  const getDetailElementWithDangersoulyHTML = (name: any, value: any) => (
    <Grid item xs={12} sm={12} md={12}>
      <MDTypography fontWeight="bold" variant="button">
        {name}
      </MDTypography>
      <MDBox
        sx={{ border: "1px solid #eee", overflow: "auto" }}
        p={2}
        dangerouslySetInnerHTML={{
          __html: value ? value : "-",
        }}
      />
    </Grid>
  );

  const getIconElement = (name: any, iconValue: any) => {
    const value: any = iconValue;
    return (
      <Grid item xs={12} sm={6} md={6}>
        <MDTypography fontWeight="bold" variant="button">
          {name}
        </MDTypography>
        <MDBox>{getIcon(value)}</MDBox>
      </Grid>
    );
  };

  const getAccordianSummary = (name: any) => {
    const labelName = name;
    return (
      <AccordionSummary className="location-accordion">
        <MDTypography>{labelName}</MDTypography>
      </AccordionSummary>
    );
  };

  return (
    <MDDialog {...dialogProps}>
      <Grid container spacing={2}>
        <Grid item xs={12} md={12}>
          <MDBox mt={1}>
            <MDBox>
              <Accordion expanded>
                {getAccordianSummary("General")}
                <AccordionDetails>
                  <Grid container spacing={2}>
                    {getDetailElement("Sola Pro ID", solaProsData?.id)}
                    {getDetailElement("Name", solaProsData?.name)}
                    {getDetailElement("URL Name", solaProsData?.url_name)}
                    {getDetailElementWithDangersoulyHTML("Biography", solaProsData?.biography)}
                    {getDetailElement("Reserved", solaProsData?.reserved ? "Yes" : "No")}
                    {getDetailElement("Status", solaProsData?.status)}
                    {getDetailElement("Created At", getLocalDateTime(solaProsData?.created_at))}
                    {getDetailElement("Updated At", getLocalDateTime(solaProsData?.updated_at))}
                  </Grid>
                </AccordionDetails>
              </Accordion>
            </MDBox>
          </MDBox>
          <MDBox mt={1}>
            <MDBox>
              <Accordion expanded>
                {getAccordianSummary("Contact")}
                <AccordionDetails>
                  <Grid container spacing={2}>
                    {getDetailElement("Phone Number", solaProsData?.phone_number)}
                    {getDetailElement(
                      "Phone Number Display",
                      solaProsData?.phone_number_display ? "Visible" : "No Visible"
                    )}
                    {getDetailElement("Email Address", solaProsData?.email_address)}
                    {getDetailElement(
                      "Send A Message Button",
                      solaProsData?.send_a_message_button ? "Visible" : "No Visible"
                    )}
                  </Grid>
                </AccordionDetails>
              </Accordion>
            </MDBox>
          </MDBox>
          <MDBox mt={1}>
            <MDBox>
              <Accordion expanded>
                {getAccordianSummary("Business")}
                <AccordionDetails>
                  <Grid container spacing={2}>
                    {getDetailElement("Locations", solaProsData?.location_name)}
                    {getDetailElement("Salon Name", solaProsData?.business_name)}
                    {getDetailElement("Studio Number", solaProsData?.studio_number)}
                    {getDetailElement("Work Hours", solaProsData?.work_hours)}
                    {getIconElement("Accepting New Clients", solaProsData?.accepting_new_clients)}
                    {getIconElement("Walkins", solaProsData?.walkins)}
                  </Grid>
                </AccordionDetails>
              </Accordion>
            </MDBox>
          </MDBox>
          <MDBox mt={1}>
            <MDBox>
              <Accordion expanded>
                {getAccordianSummary("Website")}
                <AccordionDetails>
                  <Grid container spacing={2}>
                    {getDetailElement("Website Url", solaProsData?.website_url)}
                    {getDetailElement("Booking Url", solaProsData?.booking_url)}
                  </Grid>
                </AccordionDetails>
              </Accordion>
            </MDBox>
          </MDBox>
          <MDBox mt={1}>
            <MDBox>
              <Accordion expanded>
                {getAccordianSummary("Social")}
                <AccordionDetails>
                  <Grid container spacing={2}>
                    {getDetailElement("Facebook Url", solaProsData?.facebook_url)}
                    {getDetailElement("Google Plus Url", solaProsData?.google_plus_url)}
                    {getDetailElement("Instagram Url", solaProsData?.instagram_url)}
                    {getDetailElement("Linkedin Url", solaProsData?.linkedin_url)}
                    {getDetailElement("Pinterest Url", solaProsData?.pinterest_url)}
                    {getDetailElement("Twitter Url", solaProsData?.twitter_url)}
                    {getDetailElement("Yelp Url", solaProsData?.yelp_url)}
                    {getDetailElement("Tik Tok Url", solaProsData?.tik_tok_url)}
                  </Grid>
                </AccordionDetails>
              </Accordion>
            </MDBox>
          </MDBox>
          <MDBox mt={1}>
            <MDBox>
              <Accordion expanded>
                {getAccordianSummary("Services")}
                <AccordionDetails>
                  <Grid container spacing={2}>
                    {getIconElement("Barber", solaProsData?.barber)}
                    {getIconElement("Botox/Fillers", solaProsData?.botox)}
                    {getIconElement("Brows", solaProsData?.brows)}
                    {getIconElement("Hair", solaProsData?.hair)}
                    {getIconElement("Hair Extensions", solaProsData?.hair_extensions)}
                    {getIconElement("Laser Hair Removal", solaProsData?.laser_hair_removal)}
                    {getIconElement("Lashes", solaProsData?.eyelash_extensions)}
                    {getIconElement("Makeup", solaProsData?.makeup)}
                    {getIconElement("Massage", solaProsData?.massage)}
                    {getIconElement("Microblading", solaProsData?.microblading)}
                    {getIconElement("Nails", solaProsData?.nails)}
                    {getIconElement("Permanent Makeup", solaProsData?.permanent_makeup)}
                    {getIconElement("Skincare", solaProsData?.skin)}
                    {getIconElement("Tanning", solaProsData?.tanning)}
                    {getIconElement("Teeth Whitening", solaProsData?.teeth_whitening)}
                    {getIconElement("Threading", solaProsData?.threading)}
                    {getIconElement("Waxing", solaProsData?.waxing)}
                    {getDetailElement("Other", solaProsData?.other_service)}
                  </Grid>
                </AccordionDetails>
              </Accordion>
            </MDBox>
          </MDBox>
          <MDBox mt={1}>
            <MDBox>
              <Accordion expanded>
                {getAccordianSummary("Images")}
                <AccordionDetails>
                  <Grid container spacing={2}>
                    {solaProsData?.image_1_url &&
                      getImageElement("Image 1", solaProsData?.image_1_url)}
                    {getDetailElement("Image 1 Alt Text", solaProsData?.image_1_alt_text)}
                    {solaProsData?.image_2_url &&
                      getImageElement("Image 2", solaProsData?.image_2_url)}
                    {getDetailElement("Image 2 Alt Text", solaProsData?.image_2_alt_text)}
                    {solaProsData?.image_3_url &&
                      getImageElement("Image 3", solaProsData?.image_3_url)}
                    {getDetailElement("Image 3 Alt Text", solaProsData?.image_3_alt_text)}
                    {solaProsData?.image_4_url &&
                      getImageElement("Image 4", solaProsData?.image_4_url)}
                    {getDetailElement("Image 4 Alt Text", solaProsData?.image_4_alt_text)}
                    {solaProsData?.image_5_url &&
                      getImageElement("Image 5", solaProsData?.image_5_url)}
                    {getDetailElement("Image 5 Alt Text", solaProsData?.image_5_alt_text)}
                    {solaProsData?.image_6_url &&
                      getImageElement("Image 6", solaProsData?.image_6_url)}
                    {getDetailElement("Image 6 Alt Text", solaProsData?.image_6_alt_text)}
                    {solaProsData?.image_7_url &&
                      getImageElement("Image 7", solaProsData?.image_7_url)}
                    {getDetailElement("Image 7 Alt Text", solaProsData?.image_7_alt_text)}
                    {solaProsData?.image_8_url &&
                      getImageElement("Image 8", solaProsData?.image_8_url)}
                    {getDetailElement("Image 8 Alt Text", solaProsData?.image_8_alt_text)}
                    {solaProsData?.image_9_url &&
                      getImageElement("Image 9", solaProsData?.image_9_url)}
                    {getDetailElement("Image 9 Alt Text", solaProsData?.image_9_alt_text)}
                    {solaProsData?.image_10_url &&
                      getImageElement("Image 10", solaProsData?.image_10_url)}
                    {getDetailElement("Image 10 Alt Text", solaProsData?.image_10_alt_text)}
                  </Grid>
                </AccordionDetails>
              </Accordion>
            </MDBox>
          </MDBox>
          <MDBox mt={1}>
            <MDBox>
              <Accordion expanded>
                {getAccordianSummary("Testimonials")}
                <AccordionDetails>
                  <Grid container spacing={2}>
                    {getDetailElement("Testimonial 1", solaProsData?.testimonial_1?.name)}
                    {getDetailElement("Testimonial 2", solaProsData?.testimonial_2?.name)}
                    {getDetailElement("Testimonial 3", solaProsData?.testimonial_3?.name)}
                    {getDetailElement("Testimonial 4", solaProsData?.testimonial_4?.name)}
                    {getDetailElement("Testimonial 5", solaProsData?.testimonial_5?.name)}
                    {getDetailElement("Testimonial 6", solaProsData?.testimonial_6?.name)}
                    {getDetailElement("Testimonial 7", solaProsData?.testimonial_7?.name)}
                    {getDetailElement("Testimonial 8", solaProsData?.testimonial_8?.name)}
                    {getDetailElement("Testimonial 9", solaProsData?.testimonial_9?.name)}
                    {getDetailElement("Testimonial 10", solaProsData?.testimonial_10?.name)}
                  </Grid>
                </AccordionDetails>
              </Accordion>
            </MDBox>
          </MDBox>
          <MDBox mt={1}>
            <MDBox>
              <Accordion expanded>
                {getAccordianSummary("Rent manager")}
                <AccordionDetails>
                  <Grid container spacing={2}>
                    {getDetailElement("Billing First Name", solaProsData?.billing_first_name)}
                    {getDetailElement("Billing Last Name", solaProsData?.billing_last_name)}
                    {getDetailElement("Billing Email", solaProsData?.billing_email)}
                    {getDetailElement("Billing Phone", solaProsData?.billing_phone)}
                  </Grid>
                </AccordionDetails>
              </Accordion>
            </MDBox>
          </MDBox>
          <MDBox mt={1}>
            <MDBox>
              <Accordion expanded>
                {getAccordianSummary("Linked Accounts")}
                <AccordionDetails>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={12} md={12}>
                      <MDTypography fontSize="14px">
                        The fields below indicate if this beauty professional has a linked account
                        for Sola Pro App and SolaGenius.
                      </MDTypography>
                    </Grid>
                    <Grid item xs={12} sm={6} md={6}>
                      <MDTypography fontWeight="bold" variant="button">
                        Sola Pro App Account:{" "}
                      </MDTypography>
                      {(solaProsData?.has_sola_pro_login === true ||
                        solaProsData?.has_sola_pro_login === false) &&
                        capitalizeFirstLetter(solaProsData?.has_sola_pro_login?.toString())}
                    </Grid>
                    <Grid item xs={12} sm={6} md={6}>
                      <MDTypography fontWeight="bold" variant="button">
                        SolaGenius Account:{" "}
                      </MDTypography>
                      {(solaProsData?.has_sola_genius_account === true ||
                        solaProsData?.has_sola_genius_account === false) &&
                        capitalizeFirstLetter(solaProsData?.has_sola_genius_account?.toString())}
                    </Grid>
                  </Grid>
                </AccordionDetails>
              </Accordion>
            </MDBox>
          </MDBox>
        </Grid>
      </Grid>
      <ImagePreview
        imageData={imageToPreview}
        open={isPreview}
        onCloseImage={() => {
          closeImagePreview();
        }}
      />
    </MDDialog>
  );
}

export default ViewDetails;
