import { useEffect, useState } from "react";
import MDDialog from "components/MDDialog";
import MDBox from "components/MDBox";
import { Grid, AccordionSummary, Accordion, AccordionDetails } from "@mui/material";
// import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { getLocalDateTime } from "helper/services";
import MDTypography from "components/MDTypography";

function ViewDetails(props: any): JSX.Element {
  const { open, onClose, updateData } = props;
  const [external, setExternal] = useState<any>({});

  useEffect(() => {
    if (updateData?.id) {
      setExternal(updateData);
    }
  }, [updateData]);

  const dialogProps = {
    open,
    onClose,
    title: "View External",
    size: "md",
    saveBtn: false,
  };

  const getTypograpghy = (label: any, value: any) => (
    <Grid item xs={12} sm={12} md={12} lg={6}>
      <MDTypography fontWeight="bold" variant="button">
        {label}
      </MDTypography>
      <MDBox>
        <MDTypography variant="button">{value || "-"}</MDTypography>
      </MDBox>
    </Grid>
  );

  const getAccordianSummary = (label: any) => (
    <AccordionSummary
      className="location-accordion"
      // expandIcon={<ExpandMoreIcon />}
      aria-controls="publishbh-content"
    >
      <MDTypography variant="h6">{label}</MDTypography>
    </AccordionSummary>
  );

  return (
    <MDDialog {...dialogProps}>
      <MDBox mt={1}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12} md={12}>
            <Accordion expanded>
              {getAccordianSummary("Basic Info")}
              <AccordionDetails>
                <Grid container spacing={2}>
                  {getTypograpghy("Id", external?.id || "-")}
                  {getTypograpghy("Objectable", external?.objectable_name || "-")}
                </Grid>
                <Grid container spacing={2}>
                  {getTypograpghy("Objectable Type", external?.objectable_type || "-")}
                  {getTypograpghy("Kind", external?.kind || "-")}
                </Grid>
                <Grid container spacing={2}>
                  {getTypograpghy("Name", external?.name || "-")}
                  {getTypograpghy("Value", external?.value || "-")}
                </Grid>
                <Grid container spacing={2}>
                  {getTypograpghy("Rm Location", external?.rm_location_id || "-")}
                  {getTypograpghy(
                    "Created At",
                    external?.created_at ? getLocalDateTime(external?.created_at) : "-"
                  )}
                </Grid>
                <Grid container spacing={2}>
                  {getTypograpghy(
                    "Updated At",
                    external?.updated_at ? getLocalDateTime(external?.updated_at) : "-"
                  )}
                </Grid>
              </AccordionDetails>
            </Accordion>
          </Grid>
        </Grid>
      </MDBox>
    </MDDialog>
  );
}

export default ViewDetails;
