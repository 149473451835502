import { Card, Grid } from "@mui/material";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import EmailSms from "../EmailSms";

function FollowUp2(): JSX.Element {
  return (
    <Card id="follow-up-2" sx={{ overflow: "visible" }}>
      <MDBox p={3}>
        <MDTypography variant="h5">Follow-Up #2</MDTypography>
      </MDBox>
      <MDBox component="form" pb={3} px={3}>
        <Grid container spacing={3}>
          <EmailSms />
        </Grid>
      </MDBox>
    </Card>
  );
}

export default FollowUp2;
