import {
  Grid,
  InputLabel,
  FormGroup,
  Checkbox,
  FormControlLabel,
  Accordion,
  AccordionDetails,
  AccordionSummary,
} from "@mui/material";
import MDBox from "components/MDBox";
import MDDialog from "components/MDDialog";
import MDTypography from "components/MDTypography";
import CustomAutoSearch from "components/CustomAutoSearch";
import { scrollToErrorByClass } from "helper/services";
import Validations from "helper/validations";
import CustomErrorMessage from "components/CustomErrorMessage";
import { useAppSelector, useAppDispatch } from "store/store";
import { useEffect, useState } from "react";
import { updateLocationUser } from "store/thunk/locationThunk";
import { v4 as uuidv4 } from "uuid";

function EditUser(props: any): JSX.Element {
  const dispatch = useAppDispatch();
  const {
    userDialog,
    locationId,
    viewStatus,
    errros,
    setErrors,
    selectedUsers,
    crmAccessDetails,
    locationAccessDetails,
  } = props;
  const [userDetails, setUserDetails] = useState<any>([]);
  const { locationUserDetails } = useAppSelector((state: any) => state.locationSlice);

  useEffect(() => {
    if (locationUserDetails && locationUserDetails?.length) {
      setUserDetails(
        locationUserDetails?.map((obj: any) => {
          if (!obj?.add_access && !obj?.view_access && !obj?.edit_access) {
            return {
              ...obj,
              none_access: true,
            };
          }
          return obj;
        })
      );
    }
  }, [locationUserDetails]);

  const handleChange = (event: any, menuId: any) => {
    const isExists: any = userDetails?.find((obj: any) => obj.menu_id === Number(menuId));
    if (isExists) {
      const newUserDetails: any = userDetails?.filter((obj: any) => obj.menu_id !== Number(menuId));
      const changedData: any = {
        ...isExists,
        menu_id: Number(menuId),
        [event.target.name]: event.target.checked,
        none_access: false,
      };
      if (event.target.checked) {
        if (event.target.name === "edit_access") {
          changedData.view_access = true;
        } else if (event.target.name === "add_access") {
          changedData.view_access = true;
          changedData.edit_access = true;
        }
      }
      newUserDetails.push(changedData);
      setUserDetails(JSON.parse(JSON.stringify(newUserDetails)));
    } else {
      const changedData: any = {
        menu_id: Number(menuId),
        [event.target.name]: event.target.checked,
        none_access: false,
      };
      if (event.target.checked) {
        if (event.target.name === "edit_access") {
          changedData.view_access = true;
        } else if (event.target.name === "add_access") {
          changedData.view_access = true;
          changedData.edit_access = true;
        }
      }
      const userDetailWithNewOne: any = JSON.parse(JSON.stringify(userDetails));
      userDetailWithNewOne.push(changedData);
      setUserDetails(userDetailWithNewOne);
    }
  };

  const getDefaultCheckedAccess = ({ menuId, accessName }: any) => {
    const isExists: any = userDetails?.find((obj: any) => obj.menu_id === Number(menuId));
    if (isExists) {
      return isExists?.[accessName] || false;
    }
    return false;
  };

  const onEditUser = () => {
    const requestObj: any = {
      user_access: userDetails,
      onClose: userDialog?.onClose,
      user_id: selectedUsers?.user_id,
      location_id: locationId,
    };
    const error = Validations.validateLocationUserUpdateForm(requestObj);
    setErrors(error);
    if (!Object.keys(error).length) {
      dispatch(updateLocationUser(requestObj));
    } else {
      scrollToErrorByClass(error);
    }
  };

  const handleChangeOnNone = (event: any, menuId: any) => {
    const isExists: any = userDetails?.find((obj: any) => obj.menu_id === Number(menuId));
    if (isExists) {
      const newUserDetails: any = userDetails?.filter((obj: any) => obj.menu_id !== Number(menuId));
      if (event.target.checked) {
        const changedData = {
          view_access: false,
          edit_access: false,
          add_access: false,
          menu_id: Number(menuId),
          [event.target.name]: event.target.checked,
          none_access: true,
        };
        newUserDetails.push(changedData);
        setUserDetails(JSON.parse(JSON.stringify(newUserDetails)));
      }
    } else {
      const changedData = {
        view_access: false,
        edit_access: false,
        add_access: false,
        menu_id: Number(menuId),
        [event.target.name]: event.target.checked,
        none_access: true,
      };
      const userDetailWithNewOne: any = JSON.parse(JSON.stringify(userDetails));
      userDetailWithNewOne.push(changedData);
      setUserDetails(userDetailWithNewOne);
    }
  };

  const getCheckboxList = ({ crm }: any) => {
    const html: any = [];
    const selectedDetails: any[] = crm ? crmAccessDetails : locationAccessDetails;
    selectedDetails?.map((obj: any) => {
      html.push(
        <Grid item xs={12} sm={12} md={12} lg={12} key={uuidv4()}>
          <Grid container>
            <Grid item md={12}>
              <InputLabel sx={{ mb: 1, mt: 1, fontWeight: "bold" }}>{obj?.display_menu}</InputLabel>
            </Grid>
            <Grid item md={12}>
              <FormGroup row>
                <MDBox sx={{ display: "flex" }}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={
                          getDefaultCheckedAccess({
                            menuId: obj?.menu_id,
                            accessName: "none_access",
                          }) || false
                        }
                        id={obj?.alias}
                        name="none_access"
                        disabled={viewStatus}
                        onChange={(e: any) => handleChangeOnNone(e, obj?.menu_id)}
                      />
                    }
                    label="None"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={
                          getDefaultCheckedAccess({
                            menuId: obj?.menu_id,
                            accessName: "view_access",
                          }) || false
                        }
                        id={obj?.alias}
                        name="view_access"
                        disabled={
                          viewStatus ||
                          getDefaultCheckedAccess({
                            menuId: obj?.menu_id,
                            accessName: "view_access",
                          })
                        }
                        onChange={(e: any) => handleChange(e, obj?.menu_id)}
                        sx={{
                          opacity:
                            viewStatus ||
                            getDefaultCheckedAccess({
                              menuId: obj?.menu_id,
                              accessName: "view_access",
                            })
                              ? "0.5"
                              : "",
                        }}
                      />
                    }
                    label="View"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={
                          getDefaultCheckedAccess({
                            menuId: obj?.menu_id,
                            accessName: "edit_access",
                          }) || false
                        }
                        id={obj?.alias}
                        name="edit_access"
                        disabled={
                          viewStatus ||
                          getDefaultCheckedAccess({
                            menuId: obj?.menu_id,
                            accessName: "add_access",
                          })
                        }
                        onChange={(e: any) => handleChange(e, obj?.menu_id)}
                        sx={{
                          opacity:
                            viewStatus ||
                            getDefaultCheckedAccess({
                              menuId: obj?.menu_id,
                              accessName: "add_access",
                            })
                              ? "0.5"
                              : "",
                        }}
                      />
                    }
                    label="Edit"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={
                          getDefaultCheckedAccess({
                            menuId: obj?.menu_id,
                            accessName: "add_access",
                          }) || false
                        }
                        id={obj?.alias}
                        name="add_access"
                        disabled={viewStatus}
                        onChange={(e: any) => handleChange(e, obj?.menu_id)}
                      />
                    }
                    label="Add"
                  />
                </MDBox>
              </FormGroup>
            </Grid>
          </Grid>
        </Grid>
      );
      return true;
    });
    return html;
  };

  return (
    <MDDialog {...userDialog} onSave={onEditUser}>
      <Grid container spacing={2} mt={1}>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <CustomAutoSearch
            idKey="user_id"
            nameKey="login_email"
            fieldLabel="User"
            apiName="connect_users"
            responseKey="connect_users"
            name="users"
            slsAPI
            required
            value={selectedUsers || null}
            disabled
          />
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12} className="checkbox-list-accordian">
          <MDBox className="mui-checkbox-group">
            <Accordion expanded>
              <AccordionSummary className="location-accordion" aria-controls="publishbh-content">
                <MDTypography variant="h6">Location</MDTypography>
              </AccordionSummary>
              <AccordionDetails>
                <Grid container>{getCheckboxList({ crm: false })}</Grid>
              </AccordionDetails>
            </Accordion>
            <Accordion expanded>
              <AccordionSummary className="location-accordion" aria-controls="publishbh-content">
                <MDTypography variant="h6">CRM</MDTypography>
              </AccordionSummary>
              <AccordionDetails>
                <Grid container>{getCheckboxList({ crm: true })}</Grid>
              </AccordionDetails>
            </Accordion>
          </MDBox>
        </Grid>
        <MDBox className="user_access" pl={2} pt={1}>
          {errros && errros?.user_access && <CustomErrorMessage message={errros?.user_access} />}
        </MDBox>
      </Grid>
    </MDDialog>
  );
}

export default EditUser;
