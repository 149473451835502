import { useEffect, useState } from "react";
import MDDialog from "components/MDDialog";
import MDTypography from "components/MDTypography";
import CustomChipList from "components/CustomChipList";
import MDBox from "components/MDBox";
import { Grid } from "@mui/material";
import { useAppSelector } from "store/store";
import { getLocalDateTime } from "helper/services";

function ViewDetails(props: any): JSX.Element {
  const { open, onClose } = props;
  const [tagData, setTagData] = useState<any>({});
  const { tag } = useAppSelector((state) => state.solaWebSiteSlice);

  useEffect(() => {
    if (tag && Object.keys(tag).length > 0) {
      setTagData(tag);
    }
  }, [tag]);

  const dialogProps = {
    open,
    onClose,
    saveBtn: false,
    title: "View Tag",
    size: "sm",
  };

  return (
    <MDDialog {...dialogProps}>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6} md={6}>
          <MDTypography fontWeight="bold" variant="button">
            Name
          </MDTypography>
          <MDBox>
            <MDTypography variant="button">{tagData?.name ? tagData.name : "-"}</MDTypography>
          </MDBox>
        </Grid>
      </Grid>
      <CustomChipList name="Categories" listData={tagData?.category} />
      <CustomChipList name="Stylists" listData={tagData?.stylist} />
      <CustomChipList name="Videos" listData={tagData?.video} />
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6} md={6}>
          <MDTypography fontWeight="bold" variant="button">
            Created At
          </MDTypography>
          <MDBox>
            <MDTypography variant="button">{getLocalDateTime(tagData?.created_at)}</MDTypography>
          </MDBox>
        </Grid>
        <Grid item xs={12} sm={6} md={6}>
          <MDTypography fontWeight="bold" variant="button">
            Updated At
          </MDTypography>
          <MDBox>
            <MDTypography variant="button">{getLocalDateTime(tagData?.updated_at)}</MDTypography>
          </MDBox>
        </Grid>
      </Grid>
    </MDDialog>
  );
}

export default ViewDetails;
