import React, { useEffect, useState } from "react";
import { v4 as uuidv4 } from "uuid";
import { Grid, AppBar, Tabs, Tab, Icon } from "@mui/material";
import constants from "helper/constants";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import { useNavigate, useLocation } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "store/store";
import {
  getBilligTabDetails,
  saveSolaProfessional,
  updateSolaProsBillingDetail,
  updateStylistImageOrder,
  getNewSolaProsDetail,
  updateSolaProInCognito,
  // getSolaProsDetail,
} from "store/thunk/solaProsThunk";
import { notificationFail } from "store/slices/notificationSlice";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import MDBox from "components/MDBox";
import MDTabPanel from "components/MDTabPanel";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import { getSolaProTabs, scrollToErrorByClass } from "helper/services";
import Validations from "helper/validations";
import { arrayMoveImmutable } from "array-move";
import { setLocationWebPagesList, setWebPageDetail } from "store/slices/solaProSlice";
import General from "./general";
import Website from "./website";
import Scheduling from "./scheduling";
import Billing from "./billing";
import Support from "./support";
import Leasing from "./leasing";

function SolaProsDetails(): JSX.Element {
  const location: any = useLocation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const [tabValue, setTabValue] = useState<number>(0);
  const [pageAccess, setPageAccess] = useState<any>([]);
  const { newSolaProDetails } = useAppSelector((state) => state.solaProSlice);
  const [solaProsData, setSolaProsData] = useState<any>({});
  console.log("solaProsData ", solaProsData);
  const [generalTabData, setGeneralTabData] = useState<any>({});
  const [websiteTabData, setWebsiteTabData] = useState<any>({});
  const [billingTabData, setBillingTabData] = useState<any>({});
  const [expanded, setExpanded] = useState<string | false>("panel1");
  const [errors, setErrors] = useState<any>({});
  const [imagesArray, setImagesArray] = useState([]);
  const [updateImageOrder, setUpdateImageOrder] = useState(false);
  const [newStateSolaProDetails, setNewStateSolaProDetails] = useState<any>({});
  const [locationId, setLocationId] = useState<any>(null);
  const [stylistId, setStylistId] = useState<any>(null);

  const openAccordian = (accordianElementId: any, accordianExpandName: any) => {
    const element = document.getElementById(accordianElementId);
    element.addEventListener("change", () => {
      setExpanded(accordianExpandName);
    });
    const event = new Event("change", {
      bubbles: true,
      cancelable: true,
      composed: false,
    });
    element.dispatchEvent(event);
  };

  const onSave = () => {
    setUpdateImageOrder(false);
    const error = Validations.validateSolaProWebsiteForm(websiteTabData);

    if (websiteTabData?.status?.value) {
      websiteTabData.status = websiteTabData.status.value;
    }

    for (let i = 1; i <= 10; i++) {
      if (error?.[`testimonial_${i}_text`]) {
        openAccordian(`testimonial_${i}_text`, `testimonial_${i}_text`);
        break;
      }
    }

    setErrors(error);
    if (!Object.keys(error).length) {
      dispatch(
        saveSolaProfessional({
          solaProDetail: websiteTabData,
          isUpdate: true,
          isWebsiteUpdate: true,
        })
      );
    } else {
      scrollToErrorByClass(error);
    }
  };

  const onUpdateBillingTab = () => {
    const submitData = JSON.parse(JSON.stringify(billingTabData));
    const error = Validations.validateSolaProfessionalsBillingForm(submitData);
    setErrors(error);
    if (!Object.keys(error).length) {
      dispatch(updateSolaProsBillingDetail(submitData));
    } else {
      scrollToErrorByClass(error);
    }
  };
  const onUpdateImageOrder = () => {
    let allowUpdateOrder = true;
    if (allowUpdateOrder) {
      for (let i = 0; i < 10; i++) {
        if (newSolaProDetails?.[`delete_image_${i + 1}`]) {
          allowUpdateOrder = false;
        }
      }
    }
    if (!allowUpdateOrder) {
      dispatch(notificationFail("Please click update button first as you delete the image"));
      return;
    }
    if (updateImageOrder && imagesArray.length) {
      const orderUpdateReq = {
        imagesArray,
        id: newSolaProDetails?.id,
        setUpdateImageOrder,
      };
      dispatch(updateStylistImageOrder(orderUpdateReq));
    }
  };

  const onUpdateGeneralTab = () => {
    const submitData = JSON.parse(JSON.stringify(generalTabData));
    const error = Validations.validateSolaProfessionalsGeneralForm(submitData);

    if (generalTabData?.status?.value) {
      submitData.status = submitData.status.value;
    }
    if (!submitData.inactive_reason) {
      submitData.inactive_reason = "0";
    }
    setErrors(error);
    if (!Object.keys(error).length) {
      dispatch(
        saveSolaProfessional({
          solaProDetail: submitData,
          isUpdate: true,
        })
      );
    } else {
      scrollToErrorByClass(error);
    }
  };

  const onChangeField = (tab: string) => (event: any) => {
    if (tab === "GENERAL") {
      setGeneralTabData({ ...generalTabData, [event.target.name]: event.target.value });
    } else if (tab === "WEBSITE") {
      setWebsiteTabData({ ...websiteTabData, [event.target.name]: event.target.value });
    } else if (tab === "BILLING") {
      setBillingTabData({ ...billingTabData, [event.target.name]: event.target.value });
    }
  };

  const onChangeRadio = (tab: string) => (event: any) => {
    if (tab === "GENERAL") {
      setGeneralTabData({ ...generalTabData, [event.target.name]: event.target.checked });
    } else if (tab === "WEBSITE") {
      setWebsiteTabData({ ...websiteTabData, [event.target.name]: event.target.checked });
    } else if (tab === "BILLING") {
      setBillingTabData({ ...billingTabData, [event.target.name]: event.target.checked });
    }
  };

  const onChangeBiography = (value: any, tab: string) => {
    if (tab === "GENERAL") {
      setGeneralTabData({ ...generalTabData, biography: value });
    } else if (tab === "WEBSITE") {
      setWebsiteTabData({ ...websiteTabData, biography: value });
    } else if (tab === "BILLING") {
      setBillingTabData({ ...billingTabData, biography: value });
    }
  };

  // Bydefault call detail api and data set to state
  useEffect(() => {
    if (location?.state?.solaProId) {
      // dispatch(getSolaProsDetail({ id: location?.state?.solaProId, pastCharges: true })).then(
      // () => {
      dispatch(getNewSolaProsDetail({ id: location?.state?.solaProId, pastCharges: true }));
      // }
      // );
    } else {
      navigate(`/sola-pros`);
    }
    dispatch(setLocationWebPagesList([]));
    dispatch(setWebPageDetail({}));
  }, []);

  useEffect(() => {
    if (newSolaProDetails) {
      let updatedData = JSON.parse(JSON.stringify(newSolaProDetails));
      const cvalue = constants.solaProStatusOptions.find(
        (val) => val.value === updatedData?.status
      );
      let inactiveReason = newSolaProDetails?.inactive_reason;
      if (cvalue?.value === "closed") {
        inactiveReason = constants.inactiveReason[newSolaProDetails?.inactive_reason] || 0;
      }
      updatedData = {
        ...newSolaProDetails,
        location: { id: newSolaProDetails?.location_id, name: newSolaProDetails?.location_name },
        f_name: newSolaProDetails?.connect_user?.first_name,
        m_name: newSolaProDetails?.connect_user?.middle_name,
        l_name: newSolaProDetails?.connect_user?.last_name,
        home_address_1: newSolaProDetails?.connect_user?.home_street_address,
        home_address_2: newSolaProDetails?.connect_user?.other_street_address,
        country: newSolaProDetails?.connect_user?.home_country,
        webpage: newSolaProDetails?.connect_user?.connect_webpages || [],
        services: newSolaProDetails?.connect_user?.connect_services || [],
        ...newSolaProDetails.connect_user,
        status: cvalue?.value || "",
        inactive_reason: inactiveReason,
        other_service: newSolaProDetails?.other_service,
      };

      const services = newSolaProDetails?.connect_user?.connect_services.reduce(
        (result: any, service: any) => ({
          ...result,
          [service.label]: service.isActive,
        }),
        {}
      );

      updatedData = {
        ...updatedData,
        ...services,
      };

      setNewStateSolaProDetails(updatedData);
      setLocationId(newSolaProDetails?.location_id);
      setStylistId(newSolaProDetails?.id);
      setSolaProsData(updatedData);
      setGeneralTabData(updatedData);
      setBillingTabData(updatedData);
    }
  }, [newSolaProDetails]);

  useEffect(() => {
    const tabAccess = getSolaProTabs();
    if (tabAccess?.length > 0) {
      setPageAccess(tabAccess);
    }
  }, []);

  useEffect(() => {
    if (location && location?.state) {
      setTabValue(Number(location?.state?.defaultTab) || 0);
    }
  }, [location]);

  const changeTab = (e: any, value: any) => {
    if (value === 2) {
      dispatch(getBilligTabDetails({ stylistId: newSolaProDetails?.id }));
    }
    setTabValue(value);
  };

  const onChangeAutoComplete = (tab: string) => (name: string, value: any) => {
    if (tab === "GENERAL") {
      setGeneralTabData({ ...generalTabData, [name]: value });
    } else if (tab === "WEBSITE") {
      setWebsiteTabData({ ...websiteTabData, [name]: value });
    } else if (tab === "BILLING") {
      setBillingTabData({ ...billingTabData, [name]: value });
    }
  };

  const onCheckboxChange = (tab: string) => (event: any) => {
    if (tab === "GENERAL") {
      const checked =
        event.target.name === "reserved" ? !event.target.checked : event.target.checked;
      setGeneralTabData({ ...generalTabData, [event.target.name]: checked });
    } else if (tab === "WEBSITE") {
      setWebsiteTabData({ ...websiteTabData, [event.target.name]: event.target.checked });
    } else if (tab === "BILLING") {
      if (event.target.name === "billing_contact_info") {
        const oldValueArr = {
          billing_email: newSolaProDetails.billing_email,
          billing_first_name: newSolaProDetails.billing_first_name,
          billing_last_name: newSolaProDetails.billing_last_name,
          billing_middle_name: newSolaProDetails.billing_middle_name,
          billing_phone: newSolaProDetails.billing_phone,
        };
        setBillingTabData({
          ...newSolaProDetails,
          ...oldValueArr,
          [event.target.name]: event.target.checked,
        });
      } else if (event.target.name === "billing_address_info") {
        const oldValueArr = {
          billing_address_1: newSolaProDetails.billing_address_1,
          billing_address_2: newSolaProDetails.billing_address_2,
          billing_city: newSolaProDetails.billing_city,
          billing_state: newSolaProDetails.billing_state,
          billing_postal_code: newSolaProDetails.billing_postal_code,
          billing_country: newSolaProDetails.billing_country,
        };
        setBillingTabData({
          ...billingTabData,
          ...oldValueArr,
          [event.target.name]: event.target.checked,
        });
      } else {
        setBillingTabData({ ...billingTabData, [event.target.name]: event.target.checked });
      }
    }
  };

  const onChangeSelect = (tab: string) => (value: any, name: any) => {
    if (tab === "GENERAL") {
      setGeneralTabData({ ...generalTabData, [name]: value });
    } else if (tab === "WEBSITE") {
      setWebsiteTabData({ ...websiteTabData, [name]: value });
    } else if (tab === "BILLING") {
      setBillingTabData({ ...billingTabData, [name]: value });
    }
  };

  const onSortEnd = ({ oldIndex, newIndex }: any) => {
    setImagesArray((prevItem: any) => arrayMoveImmutable(prevItem, oldIndex, newIndex));
    setUpdateImageOrder(true);
  };

  const onChangeFieldForNew = (event: any) => {
    setNewStateSolaProDetails({
      ...newStateSolaProDetails,
      [event.target.name]: event.target.value,
    });
  };

  const onChangeAutoCompleteForNew = (name: string, value: any) => {
    setNewStateSolaProDetails({ ...newStateSolaProDetails, [name]: value });
  };

  const onCheckboxChangeForNew = (event: any) => {
    const detailObject = { ...newStateSolaProDetails };
    const newMappedServices = detailObject?.services?.map((item: any) => {
      if (item?.label === event.target.name) {
        return {
          ...item,
          isActive: event.target.checked,
        };
      }
      return item;
    });
    setNewStateSolaProDetails({
      ...newStateSolaProDetails,
      services: newMappedServices,
      [event.target.name]: event.target.checked,
    });
  };

  const onUpdateGeneralTabForNew = () => {
    const submitData = JSON.parse(JSON.stringify(newStateSolaProDetails));
    const error = Validations.validateNewSolaProForm(submitData);
    setErrors(error);

    // Set Satus conditios
    // if (submitData?.status === "closed") {
    //   if (!submitData.inactive_reason) {
    //     submitData.inactive_reason = "left";
    //   }
    // } else {
    //   submitData.inactive_reason = "left";
    // }

    if (!Object.keys(error).length) {
      dispatch(
        updateSolaProInCognito({
          solaProDetail: submitData,
          isUpdate: true,
        })
      );
    } else {
      scrollToErrorByClass(error);
    }
  };
  const attr = {
    generalTabData,
    websiteTabData,
    billingTabData,
    onChangeField,
    errors,
    setErrors,
    onChangeRadio,
    onChangeBiography,
    setSolaProsData: setNewStateSolaProDetails,
    setWebsiteTabData,
    onSave,
    onChangeAutoComplete,
    onCheckboxChange,
    onUpdateGeneralTab,
    onChangeSelect,
    expanded,
    setExpanded,
    onUpdateBillingTab,
    onSortEnd,
    imagesArray,
    setImagesArray,
    updateImageOrder,
    onUpdateImageOrder,
    locationId,
    stylistId,
  };

  const newAttr = {
    onChangeField: onChangeFieldForNew,
    onChangeAutoComplete: onChangeAutoCompleteForNew,
    onCheckboxChange: onCheckboxChangeForNew,
    solaProDetail: newStateSolaProDetails,
    onUpdateGeneralTabForNew,
    errors,
    setErrors,
    setNewStateSolaProDetails,
  };

  const getComponent = (alias: string) => {
    switch (alias) {
      case "sola_pros_general":
        return <General {...newAttr} />;
      case "sola_pros_website":
        return <Website {...attr} />;
      case "sola_pros_scheduling":
        return <Scheduling />;
      case "sola_pros_billing":
        return <Billing {...attr} />;
      case "sola_pros_maintenance":
        return <Scheduling />;
      case "sola_pros_support":
        return <Support />;
      case "sola_pros_leasing":
        return <Leasing />;
      default:
        return null;
    }
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox mx={2}>
        <MDButton
          variant="text"
          color="dark"
          size="small"
          startIcon={
            <Icon fontSize="small" sx={{ mb: "3px" }}>
              arrow_back
            </Icon>
          }
          sx={{ padding: 0, fontSize: "14px", color: "#227bea", textTransform: "capitalize" }}
          onClick={() => window.history?.back()}
        >
          Back To Sola Pros
        </MDButton>
      </MDBox>
      <MDBox pt={2} pb={2}>
        {pageAccess && pageAccess?.length > 0 ? (
          <>
            <MDBox>
              <Grid container>
                <Grid item xs={12} sm={8} md={8}>
                  <AppBar position="static">
                    <Tabs className="location-tabs" value={tabValue} onChange={changeTab}>
                      {pageAccess.map((obj: any) => (
                        <Tab label={obj.display_menu} key={uuidv4()} />
                      ))}
                    </Tabs>
                  </AppBar>
                </Grid>
              </Grid>
            </MDBox>
            <MDBox>
              {pageAccess.map((obj: any, key: number) => (
                <MDTabPanel value={tabValue} index={key} padding={0} key={obj.alias}>
                  {getComponent(obj.alias)}
                </MDTabPanel>
              ))}
            </MDBox>
          </>
        ) : (
          <MDTypography ml={2}>No Access</MDTypography>
        )}
      </MDBox>
    </DashboardLayout>
  );
}

export default SolaProsDetails;
