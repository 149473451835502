import { useEffect, useState } from "react";
import { Autocomplete, Button, Checkbox, Grid, Paper, TextField, Typography } from "@mui/material";
import MDInput from "components/MDInput";
import CustomPhoneInput from "components/CustomPhoneInput";
import CustomSelect from "components/CustomSelect";
import constants from "helper/constants";
import { useAppDispatch, useAppSelector } from "store/store";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import CustomAutoSearch from "components/CustomAutoSearch";
import { adminSuperAdmin, getCRMCreateAccess, getPermission } from "helper/services";
import Validations from "helper/validations";
import { getAllDelasByParams } from "store/thunk/leaseThunk";
import MDBox from "components/MDBox";
import { experimentalStyled as styled } from "@mui/material/styles";
import { setleaseApplicationSteps } from "store/slices/leaseSlice";
import HandleButton from "./handleButton";

function AddAndCheckDeal(props: any): JSX.Element {
  const { contactInquiries, setFinalStep, finalStep, showDeals, setShowDeals } = props;
  const { services, existingDeal, leaseApplicationSteps } = useAppSelector(
    (state) => state.leaseSlice
  );
  const { statusArray } = useAppSelector((state) => state.dealSlice);
  const [selectedServices, setSelectedServices] = useState<any>([]);
  const [selectedItemId, setSelectedItemId] = useState<string | null>(null);
  const [foundNotFound, setFoundNotFound] = useState<boolean>(false);
  const [errors, setErrors] = useState<any>({});
  const [childContactInquiries, setContactInquiriesChild] = useState<any>({
    deal_status: { label: "Closed Leased", value: "Closed Leased" },
  });
  const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
  const checkedIcon = <CheckBoxIcon fontSize="small" />;
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (leaseApplicationSteps?.deal?.id) {
      setSelectedItemId(leaseApplicationSteps?.deal?.id);
    }
    if (showDeals) {
      setFoundNotFound(true);
    }
  }, []);

  useEffect(() => {
    if (contactInquiries) {
      const data = { ...contactInquiries };
      if (data?.services?.length) {
        const newservices: any = [];
        data?.services.split(",").map((service: any) => {
          newservices.push(services.find((obj: any) => obj.label === service));

          return true;
        });
        setSelectedServices(newservices);
      }
      setContactInquiriesChild(data);
    }
  }, []);

  // OnchangeFiled for childContactInquiries
  const onChangeField = (event: any) => {
    setContactInquiriesChild({ ...childContactInquiries, [event.target.name]: event.target.value });
  };

  // onChangeAutoCompleteContact for childContactInquiries Dropdown
  const onChangeAutoCompleteContact = (name: string, value: any) => {
    setContactInquiriesChild({ ...childContactInquiries, [name]: value });
  };

  // onChangeServices for Services
  const onChangeServices = (e: any, value: any) => {
    setSelectedServices(value);
    let savePreviousData = { ...childContactInquiries };
    const allServices = value.map((val: any) => val.label).join(",");
    savePreviousData = {
      ...savePreviousData,
      services: allServices,
    };
    setContactInquiriesChild(savePreviousData);
  };

  const handleOnBack = () => {
    setSelectedItemId("");
    setShowDeals(false);
    setFoundNotFound(false);
    setFinalStep(true);
    if (!existingDeal?.length) {
      setFinalStep(true);
    }
  };

  const handleOnSave = () => {
    const errors = Validations.newDataCheckDeals(childContactInquiries);
    setErrors(errors);
    if (!Object.keys(errors).length) {
      dispatch(
        getAllDelasByParams({
          childContactInquiries,
          setShowDeals,
          setFinalStep,
          leaseApplicationSteps,
        })
      );
      setFoundNotFound(true);
    }
  };
  const selectedDeal = (selctedDeal: any, setSelectedItemId: Function, dealName: string) => {
    setSelectedItemId(selctedDeal);
    setFinalStep(false);
    dispatch(
      setleaseApplicationSteps({
        ...leaseApplicationSteps,
        deal: { id: selctedDeal, deal_name: dealName },
      })
    );
  };

  const Item = styled(Paper)(({ theme }) => ({
    ...theme.typography.body2,
    padding: "20px",
    margin: "10px",
    textAlign: "center",
  }));

  const existingItem = (items: any) => (
    <Grid item xs={12} sm={12} md={4}>
      {items?.connect_deal !== null && (
        <Item
          sx={{
            position: "relative",
            backgroundColor: selectedItemId === items?.connect_deal?.id ? "#1A73E8" : "white",
            cursor: "pointer",
          }}
          onClick={() =>
            selectedDeal(items?.connect_deal?.id, setSelectedItemId, items?.connect_deal?.deal_name)
          }
        >
          <Typography
            sx={{ fontSize: "13px" }}
            color={selectedItemId === items?.connect_deal?.id ? "#fff" : "#000"}
            variant="h3"
          >
            {items?.connect_deal?.deal_name && items?.connect_deal?.deal_name}
          </Typography>
          <Typography
            sx={{ fontSize: "13px" }}
            color={selectedItemId === items?.connect_deal?.id ? "#fff" : "#000"}
            variant="h3"
          >
            {items?.email}
          </Typography>
          <Typography
            sx={{ fontSize: "13px" }}
            color={selectedItemId === items?.connect_deal?.id ? "#fff" : "#000"}
            variant="h3"
          >
            {items?.phone}
          </Typography>
        </Item>
      )}
    </Grid>
  );

  return (
    <>
      {showDeals ? (
        <MDBox sx={{ height: "79px", overflow: "auto" }}>
          <Typography>This Deals are found from your filled data.</Typography>
          <Grid container>
            {existingDeal?.length > 0 && existingDeal?.map((item: any) => existingItem(item))}
          </Grid>
        </MDBox>
      ) : (
        finalStep && (
          <Grid container>
            <Grid item xs={12} sm={12} md={6} lg={6} sx={{ p: 2 }}>
              <MDInput
                label="First Name"
                variant="standard"
                fullWidth
                required
                name="firstname"
                onChange={onChangeField}
                placeholder="Firstname"
                value={childContactInquiries?.firstname || ""}
                error={errors && errors.firstname ? true : false}
                helperText={errors && errors.firstname}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6} sx={{ p: 2 }}>
              <MDInput
                label="Last Name"
                variant="standard"
                fullWidth
                required
                name="lastname"
                onChange={onChangeField}
                placeholder="Lastname"
                value={childContactInquiries?.lastname || ""}
                error={errors && errors.lastname ? true : false}
                helperText={errors && errors.lastname}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6} sx={{ p: 2 }}>
              <MDInput
                label="Email"
                variant="standard"
                fullWidth
                required
                name="email"
                onChange={onChangeField}
                placeholder="email"
                value={childContactInquiries?.email || ""}
                error={errors && errors.email ? true : false}
                helperText={errors && errors.email}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6} sx={{ p: 2 }}>
              <MDInput
                variant="standard"
                fullWidth
                label="Phone"
                name="phone"
                placeholder="(000) 000-0000"
                id="phone-input"
                onChange={onChangeField}
                InputProps={{
                  inputComponent: CustomPhoneInput as any,
                }}
                value={childContactInquiries?.phone || ""}
                error={errors && errors.phone ? true : false}
                helperText={errors && errors.phone}
                required
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6} sx={{ p: 2 }}>
              <CustomAutoSearch
                slsAPI
                idKey="cms_location_id"
                nameKey="name"
                fieldLabel="Locations"
                apiName="get-locations"
                parentKey="location"
                responseKey="data"
                name="location"
                required
                usersData={
                  !adminSuperAdmin() ? getCRMCreateAccess("add_access", "crm", "crm_deals") : null
                }
                value={childContactInquiries?.location || null}
                onChange={onChangeAutoCompleteContact}
                error={errors && errors.location}
                createNewLink={
                  adminSuperAdmin() && getPermission("locations", "locations_location")
                    ? "/locations"
                    : ""
                }
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6} sx={{ p: 2 }}>
              <Autocomplete
                options={services}
                disableCloseOnSelect
                getOptionLabel={(option: any) => option?.label || option}
                id="services"
                multiple
                value={selectedServices ? selectedServices : []}
                onChange={(e: any, newValue: any) => {
                  onChangeServices(e, newValue);
                  // setSelectedServices(newValue);
                }}
                renderOption={(props: any, option: any, { selected }: any) => (
                  <li {...props} key={option.id}>
                    <Checkbox
                      icon={icon}
                      checkedIcon={checkedIcon}
                      style={{ marginRight: 8 }}
                      checked={selected}
                    />
                    {option.label}
                  </li>
                )}
                renderInput={(params: any) => (
                  <TextField
                    {...params}
                    required
                    variant="standard"
                    label="Services"
                    placeholder=""
                    error={errors && errors.services ? true : false}
                    helperText={errors && errors.services}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6} sx={{ p: 2 }}>
              <MDInput
                multiline
                rows={3}
                label="Message"
                variant="outlined"
                fullWidth
                name="message"
                onChange={onChangeField}
                placeholder="Message"
                value={childContactInquiries?.message ? childContactInquiries.message : ""}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6} sx={{ p: 2 }}>
              <CustomSelect
                name="deal_status"
                id="deal_status"
                fieldLabel="Deal Status"
                placeholder="Deal Status"
                options={
                  statusArray?.length
                    ? statusArray.map((obj: any) => ({ label: obj.status, value: obj.status }))
                    : []
                }
                value={childContactInquiries?.deal_status || null}
                onChange={(value: any) => onChangeAutoCompleteContact("deal_status", value)}
                disabled
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6} sx={{ p: 2 }}>
              <CustomSelect
                name="original_source_type"
                id="original_source_type"
                fieldLabel="Original Source Type"
                placeholder="Original Source Type"
                options={constants.originalSourceType}
                value={childContactInquiries?.original_source_type || null}
                onChange={(value: any) =>
                  onChangeAutoCompleteContact("original_source_type", value)
                }
              />
            </Grid>
          </Grid>
        )
      )}

      {finalStep && (
        <>
          <Button
            variant="outlined"
            onClick={handleOnSave}
            sx={{
              mt: 1,
              mr: 1,
              color: "#1a73e8",
              "&:hover": {
                borderColor: "#1a73e8",
              },
            }}
            disabled={foundNotFound}
          >
            Save
          </Button>
          <Button
            variant="outlined"
            onClick={handleOnBack}
            sx={{
              mt: 1,
              mr: 1,
              color: "#1a73e8",
              "&:hover": {
                borderColor: "#1a73e8",
              },
            }}
            disabled={!foundNotFound}
          >
            Back
          </Button>
        </>
      )}

      <HandleButton {...props} contactInquiries={childContactInquiries} finalStep={finalStep} />
    </>
  );
}

export default AddAndCheckDeal;
