import { Card, Grid } from "@mui/material";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MESSAGES from "helper/messages";

function Integrations(props: any): JSX.Element {
  const { webPageDetail, onChangeField, errors } = props;

  const getInputElement = (label: any, name: any, helperText?: any) => (
    <Grid item xs={12} sm={12}>
      <MDInput
        label={label}
        variant="standard"
        fullWidth
        name={name}
        value={webPageDetail?.[name] || ""}
        onChange={onChangeField}
        error={errors && errors[name] ? true : false}
        helperText={errors && errors[name]}
        infoText={MESSAGES.INFO.WEBSITE_BOOKING_URL}
      />
      {helperText && (
        <MDTypography variant="caption" mt={1}>
          {helperText}
        </MDTypography>
      )}
    </Grid>
  );

  return (
    <Card id="integrations" sx={{ overflow: "visible" }}>
      <MDBox p={3}>
        <MDTypography variant="h5">Integrations</MDTypography>
      </MDBox>
      <MDBox component="form" pb={3} px={3}>
        <Grid container spacing={3}>
          {getInputElement(
            "Website URL",
            "website_url",
            `It is critical that you include the "https://" portion of the URL. If you do not have your own website, leave this blank`
          )}
          {getInputElement(
            "Booking URL",
            "booking_url",
            `It is critical that you include the "https://" portion of the URL. If you do not have online booking, leave this blank`
          )}
        </Grid>
      </MDBox>
    </Card>
  );
}

export default Integrations;
