import { useState, useEffect } from "react";
import { useAppDispatch } from "store/store";
import { Accordion, AccordionDetails, AccordionSummary, Grid } from "@mui/material";
import MDBox from "components/MDBox";
import MDDialog from "components/MDDialog";
import MDInput from "components/MDInput";
import CustomAutoSearch from "components/CustomAutoSearch";
import Validations from "helper/validations";
import { createAssociation } from "store/thunk/associationThunk";
import MDTypography from "components/MDTypography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  scrollToErrorByClass,
  getPermission,
  getLocationCrmAccess,
  adminSuperAdmin,
} from "helper/services";
import ManageAccess from "layouts/pages/associations/user-access/manage-access";
import LocationWiseUsers from "layouts/pages/associations/user-access/locationWiseUsers";

function AddUpdate(props: any): JSX.Element {
  const { open, onClose, pageNo, perPage } = props;
  const dispatch = useAppDispatch();
  const [editPermission, setEditPermission] = useState<any>(false);
  const [association, setAssociation] = useState<any>({});
  const [errors, setErrors] = useState<any>({});
  const [defaultAccess, setDefaultAccess] = useState<any>([]);
  const [locationAccess, setLocationAccess] = useState<any>([]);
  const [crmAccess, setCrmAccess] = useState<any>([]);

  useEffect(() => {
    const getAccess = getLocationCrmAccess();
    setLocationAccess(getAccess?.locationAccess || []);
    setCrmAccess(getAccess?.crmAccess || []);
    setDefaultAccess(getAccess?.defaultAccess || []);
  }, []);

  const onChangeField = (event: any) => {
    setAssociation({ ...association, [event.target.name]: event.target.value });
  };

  const onChangeAutoComplete = (name: string, value: any) => {
    let userAccess: any = [];
    if (
      (name === "locations" || name === "users") &&
      (association?.locations?.length || association?.user?.length) &&
      value?.length
    ) {
      association?.[name === "locations" ? "users" : "locations"]?.map((obj: any) => {
        value.map((v: any) => {
          const tempUserId = Number(obj?.user_id || v?.user_id);
          const templocationId = Number(obj?.cms_location_id || v?.cms_location_id);
          const existing = userAccess.find(
            (u: any) =>
              Number(u?.location_id) === templocationId && Number(u?.user_id) === tempUserId
          );
          if (!existing) {
            const alreadyAdded = association?.userAccess?.find(
              (u: any) =>
                Number(u?.location_id) === templocationId && Number(u?.user_id) === tempUserId
            );
            if (alreadyAdded) {
              userAccess.push(alreadyAdded);
            } else {
              userAccess.push({
                location_id: templocationId,
                user_id: tempUserId,
                access: defaultAccess,
              });
            }
          }
          return true;
        });
        return true;
      });
    } else {
      userAccess = association.userAccess || [];
    }
    setAssociation({ ...association, [name]: value, userAccess });
  };

  const onSave = () => {
    const error: any = Validations.validateAssociationWizard(association);
    setErrors(error);
    if (!Object.keys(error).length) {
      dispatch(createAssociation({ association, onClose, pageNo, perPage }));
    } else {
      scrollToErrorByClass(error);
    }
  };

  const dialogProps = {
    open,
    onClose,
    onSave,
    title: `Add Association`,
    size: "xl",
    saveTbtText: "Save",
  };

  const onSaveAccess = () => {
    const tempAssociation = JSON.parse(JSON.stringify(association));
    const getIndex = tempAssociation.userAccess.findIndex(
      (obj: any) =>
        Number(obj.location_id) === Number(editPermission?.location?.cms_location_id) &&
        Number(obj.user_id) === Number(editPermission?.user?.user_id)
    );
    const tempUserAccess = tempAssociation.userAccess;
    tempUserAccess[getIndex].access = editPermission.accessData;
    setAssociation(tempAssociation);
    setEditPermission(false);
  };

  const editLocationAccess = (location: any, user: any) => {
    const tempAccess: any =
      location &&
      user &&
      association?.userAccess?.find(
        (obj: any) =>
          Number(obj?.location_id) === Number(location?.cms_location_id) &&
          Number(obj?.user_id) === Number(user?.user_id)
      );
    setEditPermission({
      location,
      user,
      accessData: tempAccess ? tempAccess?.access : [],
    });
  };

  const onCloseAccess = () => {
    setEditPermission(false);
  };

  const onChangeAccess = (data: any) => {
    setEditPermission({ ...editPermission, accessData: data });
  };

  const editPermissionAttr = {
    onSave: onSaveAccess,
    onClose: onCloseAccess,
    locationAccess,
    crmAccess,
    accessData: editPermission?.accessData || [],
    location: editPermission?.location || null,
    user: editPermission?.user || null,
    setAccessData: onChangeAccess,
  };

  return (
    <MDDialog {...dialogProps}>
      <MDBox pb={2}>
        <Grid container spacing={3} mt={1}>
          <Grid item xs={12} sm={12} md={12}>
            <Accordion expanded>
              <AccordionSummary
                className="location-accordion"
                expandIcon={<ExpandMoreIcon />}
                aria-controls="publishbh-content"
              >
                <MDTypography variant="h6">Basic Info</MDTypography>
              </AccordionSummary>
              <AccordionDetails>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={12} md={12} lg={6}>
                    <MDInput
                      label="Association Name"
                      variant="standard"
                      fullWidth
                      name="association_name"
                      placeholder="Association Name"
                      onChange={onChangeField}
                      value={association?.association_name || ""}
                      error={errors && errors.association_name ? true : false}
                      helperText={errors && errors.association_name}
                      required
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={12} lg={6}>
                    <MDInput
                      label="Association Description"
                      variant="standard"
                      fullWidth
                      name="association_description"
                      placeholder="Association Description"
                      onChange={onChangeField}
                      value={association?.association_description || ""}
                    />
                  </Grid>
                </Grid>
              </AccordionDetails>
            </Accordion>
          </Grid>
          <Grid item xs={12} sm={12} md={12}>
            <Accordion expanded>
              <AccordionSummary
                className="location-accordion"
                expandIcon={<ExpandMoreIcon />}
                aria-controls="publishbh-content"
              >
                <MDTypography variant="h6">Business Info</MDTypography>
              </AccordionSummary>
              <AccordionDetails>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={12} md={12} lg={6}>
                    <MDInput
                      label="Business Name"
                      variant="standard"
                      fullWidth
                      name="bussiness_name"
                      placeholder="Business Name"
                      onChange={onChangeField}
                      value={association?.bussiness_name || ""}
                      error={errors && errors.bussiness_name ? true : false}
                      helperText={errors && errors.bussiness_name}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={12} lg={6}>
                    <MDInput
                      label="State of Incorporation"
                      variant="standard"
                      fullWidth
                      name="incorporation_state"
                      placeholder="State of Incorporation"
                      onChange={onChangeField}
                      value={association?.incorporation_state || ""}
                      error={errors && errors.incorporation_state ? true : false}
                      helperText={errors && errors.incorporation_state}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={12} lg={6}>
                    <MDInput
                      label="Notice Address 1"
                      variant="standard"
                      fullWidth
                      name="notice_address_1"
                      placeholder="Notice Address 1"
                      onChange={onChangeField}
                      value={association?.notice_address_1 || ""}
                      error={errors && errors.notice_address_1 ? true : false}
                      helperText={errors && errors.notice_address_1}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={12} lg={6}>
                    <MDInput
                      label="Notice Address 2"
                      variant="standard"
                      fullWidth
                      name="notice_address_2"
                      placeholder="Notice Address 2"
                      onChange={onChangeField}
                      value={association?.notice_address_2 || ""}
                      error={errors && errors.notice_address_2 ? true : false}
                      helperText={errors && errors.notice_address_2}
                    />
                  </Grid>

                  <Grid item xs={12} sm={12} md={12} lg={6}>
                    <MDInput
                      label="Notice City "
                      variant="standard"
                      fullWidth
                      name="notice_city"
                      placeholder="Notice City "
                      onChange={onChangeField}
                      value={association?.notice_city || ""}
                      error={errors && errors.notice_city ? true : false}
                      helperText={errors && errors.notice_city}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={12} lg={6}>
                    <MDInput
                      label="Notice State / Province"
                      variant="standard"
                      fullWidth
                      name="notice_state"
                      placeholder="Notice State / Province"
                      onChange={onChangeField}
                      value={association?.notice_state || ""}
                      error={errors && errors.notice_state ? true : false}
                      helperText={errors && errors.notice_state}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={12} lg={6}>
                    <MDInput
                      label="Notice Postal Code"
                      variant="standard"
                      fullWidth
                      name="notice_postal_code"
                      placeholder="Notice Postal Code"
                      onChange={onChangeField}
                      value={association?.notice_postal_code || ""}
                      error={errors && errors.notice_postal_code ? true : false}
                      helperText={errors && errors.notice_postal_code}
                    />
                  </Grid>
                </Grid>
              </AccordionDetails>
            </Accordion>
          </Grid>
          <Grid item xs={12} sm={12} md={12}>
            <Accordion expanded>
              <AccordionSummary
                className="location-accordion"
                expandIcon={<ExpandMoreIcon />}
                aria-controls="publishbh-content"
              >
                <MDTypography variant="h6">Other</MDTypography>
              </AccordionSummary>
              <AccordionDetails>
                <Grid container spacing={2} mt={1}>
                  <Grid item xs={12} sm={12} md={12} lg={6} className="locations">
                    <CustomAutoSearch
                      required
                      slsAPI
                      multiple
                      idKey="cms_location_id"
                      nameKey="name"
                      fieldLabel="Locations"
                      parentKey="location"
                      apiName="get-locations"
                      responseKey="data"
                      name="locations"
                      value={association?.locations || []}
                      error={errors && errors.locations}
                      onChange={onChangeAutoComplete}
                      createNewLink={
                        adminSuperAdmin() && getPermission("locations", "locations_location")
                          ? "/locations"
                          : ""
                      }
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={12} lg={6} className="owners">
                    <CustomAutoSearch
                      idKey="user_id"
                      nameKey="login_email"
                      fieldLabel="Owner"
                      apiName="connect_users"
                      responseKey="connect_users"
                      name="owners"
                      slsAPI
                      required
                      value={association?.owners || []}
                      onChange={onChangeAutoComplete}
                      error={errors && errors.owners}
                      multiple
                      createNewLink={
                        getPermission("users", "users_manage") ? "/users-&-roles/all-users" : ""
                      }
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={12} lg={6}>
                    <CustomAutoSearch
                      idKey="user_id"
                      nameKey="login_email"
                      fieldLabel="Users"
                      apiName="connect_users"
                      responseKey="connect_users"
                      name="users"
                      slsAPI
                      value={association?.users || []}
                      onChange={onChangeAutoComplete}
                      error={errors && errors.users}
                      multiple
                      createNewLink={
                        getPermission("users", "users_manage") ? "/users-&-roles/all-users" : ""
                      }
                    />
                  </Grid>

                  <Grid item xs={12} sm={12} md={12} lg={6}>
                    <CustomAutoSearch
                      idKey="id"
                      nameKey="name"
                      fieldLabel="Country"
                      apiName="countries"
                      responseKey="countries"
                      name="notice_country"
                      value={association?.notice_country || null}
                      onChange={onChangeAutoComplete}
                      error={errors && errors.notice_country}
                    />
                  </Grid>
                </Grid>
                <LocationWiseUsers
                  association={association}
                  editLocationAccess={editLocationAccess}
                />
                {editPermission && <ManageAccess open {...editPermissionAttr} />}
              </AccordionDetails>
            </Accordion>
          </Grid>
        </Grid>
      </MDBox>
    </MDDialog>
  );
}

export default AddUpdate;
