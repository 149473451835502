import { IconButton, Tooltip } from "@mui/material";
import { FC, forwardRef } from "react";

const MDIconButton: FC<any> = forwardRef(({ tooltipName, children, ...rest }, ref) => (
  <Tooltip title={tooltipName ? tooltipName : ""} placement="top">
    <span>
      <IconButton {...rest} ref={ref}>
        {children}
      </IconButton>
    </span>
  </Tooltip>
));

export default MDIconButton;
