import { Card, Grid } from "@mui/material";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import CustomPhoneInput from "components/CustomPhoneInput";
import CustomErrorMessage from "components/CustomErrorMessage";

function CallTreeStatus(props: any): JSX.Element {
  const { onChangeTextField, phoneDetail, errors } = props;

  return (
    <Card id="call-tree-status">
      <MDBox p={3}>
        <MDTypography variant="h5">Call Forwarding</MDTypography>
      </MDBox>
      <MDBox component="form" pb={3} px={3}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={12}>
            <MDInput
              variant="standard"
              fullWidth
              label="Forwarding Number"
              name="forwarding_phone_number"
              placeholder="(000) 000-0000"
              id="phone-input"
              InputProps={{
                inputComponent: CustomPhoneInput as any,
              }}
              value={phoneDetail?.forwarding_phone_number || ""}
              onChange={onChangeTextField}
              error={errors && errors.forwarding_phone_number ? true : false}
              helperText={errors && errors.forwarding_phone_number}
            />
            <CustomErrorMessage
              message="All call made to this number will be passed directly to this phone number."
              errorColor="inherit"
            />
          </Grid>
        </Grid>
      </MDBox>
    </Card>
  );
}

export default CallTreeStatus;
