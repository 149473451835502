import { createAsyncThunk } from "@reduxjs/toolkit";
import { setLoading, setTableLoader } from "store/slices/authSlice";
import { notificationSuccess, notificationFail } from "store/slices/notificationSlice";
import { connectUsers, setDefaultsColumns } from "store/slices/connectUsersSlice";
import { getRoleDetails } from "store/thunk/rolesAndMenusThunk";
import { ConnectAccessRequest, RoleDetailsRequest } from "types/custom-types";
import Messages from "helper/messages";
import slsApiClient from "config/slsApiClient";
import { getAssociationOwnersAndUsers, getAuthUser } from "helper/services";

export const getConnectUsers = createAsyncThunk(
  "getConnectUsers",
  async (_request: any, { dispatch }) => {
    const page = _request?.pageIndex || 0;
    const size = _request?.pageSize || 50;
    const search = _request?.search || "";
    const sortBy = _request?.sort || [];
    dispatch(setLoading(true));
    try {
      const userData: any = getAuthUser();
      let url = `connect_users?page=${page}&per_page=${size}`;
      if (search) {
        url += `&search=${search}`;
      }
      if (sortBy?.length) {
        url += `&field=${sortBy[0]?.id}&order_by=${sortBy[0]?.desc ? "desc" : "asc"}`;
      }
      url += `&role_name=${userData.role}`;
      const response = await slsApiClient().get(url, {
        params: getAssociationOwnersAndUsers(userData?.roleDetails?.franchisee_owner),
      });
      if (response?.data?.status) {
        dispatch(connectUsers(response?.data));
        if (_request && _request.name) {
          const request: RoleDetailsRequest = {
            name: _request.name,
          };
          dispatch(getRoleDetails(request));
        } else {
          dispatch(setLoading(false));
        }
      } else {
        dispatch(setLoading(false));
        dispatch(notificationFail(response?.data?.message || Messages.ERROR.NO_DATA_FOUND));
      }
    } catch (error: any) {
      dispatch(setLoading(false));
      dispatch(
        notificationFail(error?.response?.data?.message || Messages.ERROR.SOMETHING_WENT_WRONG)
      );
    }
  }
);

export const saveConnectuser = createAsyncThunk(
  "saveConnectuser",
  async (_request: ConnectAccessRequest, { dispatch }) => {
    const { roleId, userId, menuIds } = _request;
    dispatch(setLoading(true));
    try {
      const response = await slsApiClient().post(`users/update-user-access`, {
        role_id: roleId,
        user_id: userId,
        menu_ids: menuIds,
      });
      dispatch(setLoading(false));
      if (response?.data?.status) {
        dispatch(getConnectUsers(null));
        dispatch(notificationSuccess(response?.data?.message || Messages.SUCCESS.USER_ACCESS));
      } else {
        dispatch(notificationFail(response?.data?.message || Messages.ERROR.USER_ACCESS));
      }
    } catch (error: any) {
      dispatch(setLoading(false));
      dispatch(
        notificationFail(error?.response?.data?.message || Messages.ERROR.SOMETHING_WENT_WRONG)
      );
    }
  }
);

// save selected checkbox datas
export const saveSelectedCoulms = createAsyncThunk(
  "saveSelectedCoulms",
  async (_request: any, { dispatch }) => {
    dispatch(setLoading(true));
    try {
      const response = await slsApiClient().post(`create-user-table-settings`, _request);
      dispatch(setLoading(false));
      if (response?.data?.status) {
        dispatch(notificationSuccess(response?.data?.message));
        const tempData = _request;
        if (_request?.setSaveAsDefaultBtnDisabled) {
          _request?.setSaveAsDefaultBtnDisabled(true);
          delete tempData?.setSaveAsDefaultBtnDisabled;
        }
        dispatch(setDefaultsColumns(tempData));
      } else {
        dispatch(notificationFail(Messages.ERROR.SOMETHING_WENT_WRONG));
      }
    } catch (error: any) {
      dispatch(setLoading(false));
      dispatch(
        notificationFail(error?.response?.data?.message || Messages.ERROR.SOMETHING_WENT_WRONG)
      );
    }
  }
);

// get default columns display
export const getDefaultCoulmns = createAsyncThunk(
  "getDefaultCoulmns",
  async (_request: any, { dispatch }) => {
    dispatch(setLoading(true));
    dispatch(setTableLoader(true));
    let response: any = {};
    try {
      response = await slsApiClient().get(`get-users-table-settings`, {
        params: {
          user_id: _request?.user_id,
          table_name: _request.table_name,
        },
      });
      dispatch(setLoading(false));
      setTimeout(() => {
        dispatch(setTableLoader(false));
      }, 1000);
      if (response?.data?.status) {
        dispatch(setDefaultsColumns(response?.data?.data));
      } else {
        dispatch(setDefaultsColumns([]));
        dispatch(notificationFail(response?.data?.message || Messages.ERROR.SOMETHING_WENT_WRONG));
      }
    } catch (error: any) {
      setTimeout(() => {
        dispatch(setTableLoader(false));
      }, 1000);
      dispatch(setDefaultsColumns([]));
      dispatch(notificationFail(error.message || Messages.ERROR.SOMETHING_WENT_WRONG));
    }
    return response?.data?.data || {};
  }
);

// SD-3199
export const saveSelectedAllData = createAsyncThunk(
  "saveSelectedAllData",
  async (_request: any, { dispatch }) => {
    let response: any = {};

    try {
      response = await slsApiClient().post(`create-user-table-settings`, _request?.sendData);
      if (!response?.data?.status) {
        dispatch(notificationFail(Messages.ERROR.SOMETHING_WENT_WRONG));
      }
    } catch (error: any) {
      dispatch(
        notificationFail(error?.response?.data?.message || Messages.ERROR.SOMETHING_WENT_WRONG)
      );
    }
    return response?.data?.data || {};
  }
);
