import { useEffect, useState } from "react";
import MDDialog from "components/MDDialog";
import MDBox from "components/MDBox";
import Grid from "@mui/material/Grid";
import { getLocalDateTime, displayMultipleValue } from "helper/services";
import MDTypography from "components/MDTypography";

function ViewDetails(props: any): JSX.Element {
  const { open, onClose, updateData } = props;
  const [msa, setMsa] = useState<any>({});

  useEffect(() => {
    if (updateData?.id) {
      setMsa(updateData);
    }
  }, [updateData]);

  const dialogProps = {
    open,
    onClose,
    title: "View MSA",
    size: "md",
    saveBtn: false,
  };

  return (
    <MDDialog {...dialogProps}>
      <MDBox mt={1}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6} md={6}>
            <MDTypography fontWeight="bold" variant="button">
              Name
            </MDTypography>
            <MDBox>
              <MDTypography variant="button">{msa?.name || "-"}</MDTypography>
            </MDBox>
          </Grid>
          <Grid item xs={12} sm={6} md={6}>
            <MDTypography fontWeight="bold" variant="button">
              Url name
            </MDTypography>
            <MDBox>
              <MDTypography variant="button">{msa?.url_name || "-"}</MDTypography>
            </MDBox>
          </Grid>
          <Grid item xs={12} sm={6} md={6}>
            <MDTypography fontWeight="bold" variant="button">
              Description
            </MDTypography>
            <MDBox>
              <MDTypography variant="button">{msa?.description || "-"}</MDTypography>
            </MDBox>
          </Grid>
          <Grid item xs={12} sm={6} md={6}>
            <MDTypography fontWeight="bold" variant="button">
              Legacy
            </MDTypography>
            <MDBox>
              <MDTypography variant="button">{msa?.legacy_id || "-"}</MDTypography>
            </MDBox>
          </Grid>
          <Grid item xs={12} sm={6} md={6}>
            <MDTypography fontWeight="bold" variant="button">
              Tracking Code
            </MDTypography>
            <MDBox>
              <MDTypography variant="button">{msa?.tracking_code || "-"}</MDTypography>
            </MDBox>
          </Grid>
          <Grid item xs={12} sm={6} md={6}>
            <MDTypography fontWeight="bold" variant="button">
              Locations
            </MDTypography>
            <MDBox>
              <MDTypography variant="button">
                {msa?.locations ? displayMultipleValue(msa?.locations) : "-"}
              </MDTypography>
            </MDBox>
          </Grid>
          <Grid item xs={12} sm={6} md={6}>
            <MDTypography fontWeight="bold" variant="button">
              Created At
            </MDTypography>
            <MDBox>
              <MDTypography variant="button">
                {msa?.created_at ? getLocalDateTime(msa?.created_at) : "-"}
              </MDTypography>
            </MDBox>
          </Grid>
          <Grid item xs={12} sm={6} md={6}>
            <MDTypography fontWeight="bold" variant="button">
              Updated At
            </MDTypography>
            <MDBox>
              <MDTypography variant="button">
                {msa?.updated_at ? getLocalDateTime(msa?.updated_at) : "-"}
              </MDTypography>
            </MDBox>
          </Grid>
        </Grid>
      </MDBox>
    </MDDialog>
  );
}

export default ViewDetails;
