import React, { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "store/store";
import MDDialog from "components/MDDialog";
import MDInput from "components/MDInput";
import MDTypography from "components/MDTypography";
import { Autocomplete, Grid, Icon, TextField } from "@mui/material";
import Validations from "helper/validations";
import { saveDeals } from "store/thunk/mobileAppThunk";
import CustomDropZone from "components/CustomDropZone";
import MDIconButton from "components/MDIconButton";
import ImagePreview from "layouts/pages/ImagePreview";
import fileimg from "assets/images/file-image.svg";
import { scrollToError, getPermission } from "helper/services";
import CustomAutoSearch from "components/CustomAutoSearch";

const isFeaturedOption = [
  { value: "true", name: "Yes" },
  { value: "false", name: "No" },
];

function addUpdate(props: any): JSX.Element {
  const dispatch = useAppDispatch();

  const { brands } = useAppSelector((state) => state.mobileAppSlice);
  const { open, onClose, updateData, pageNo, perPage, search, sortBy } = props;
  const [isUpdate, setIsUpdate] = useState<any>(false);
  const [dealsData, setDealsData] = useState<any>({});
  const [errors, setErrors] = useState<any>({});
  const [imagePreview, setImagePreview] = useState<any>("");
  const [filePreview, setFilePreview] = useState<any>("");

  const [isPreview, setImagePreviewPop] = useState(false);
  const [imageToPreview, setImageToPreview] = useState("");

  const onSave = () => {
    const error = Validations.validateDealsForm(dealsData);
    setErrors(error);
    if (!Object.keys(error).length) {
      const req = {
        dealsData,
        onClose,
        isUpdate,
        pageNo,
        perPage,
        search,
        sortBy,
      };
      dispatch(saveDeals(req));
    } else {
      scrollToError(error);
    }
  };
  useEffect(() => {
    if (updateData) {
      setIsUpdate(true);
      setImagePreview(updateData.image_url);
      setFilePreview(updateData.file_url);
      const data = {
        ...updateData,
        categories: updateData?.categories_name.length ? updateData?.categories_name : [],
        countries: updateData?.countries_name.length ? updateData?.countries_name : [],
        brand: updateData?.brand_id
          ? { id: updateData?.brand_id, name: updateData?.brand_name }
          : null,
        tags: updateData?.tags_name.length ? updateData?.tags_name : [],
        is_featured: isFeaturedOption.find(
          (val: any) => val.value === updateData?.is_featured?.toString()
        ),
        // countries,
      };
      setDealsData(data);
    }
  }, [updateData, brands]);

  const onChangeField = (e: any, field: string) => {
    setDealsData({ ...dealsData, [field]: e.target.value });
  };

  const onChangeImage = async (files: any, type: any) => {
    if (files[0]) {
      const file = files[0];
      const reader = new FileReader();
      reader.onload = function (e) {
        if (type === "image") {
          setDealsData({ ...dealsData, [type]: files[0], delete_image: 0 });
          setImagePreview(e.target.result);
        }
        if (type === "file") {
          setDealsData({ ...dealsData, [type]: files[0], delete_file: 0 });
          setFilePreview(e.target.result);
        }
      };
      reader.readAsDataURL(file);
    }
  };

  const onImageRemove = async (e: any, type: any) => {
    e.stopPropagation();
    if (type === "image") {
      setDealsData({ ...dealsData, [type]: null, delete_image: 1 });
      setImagePreview("");
    }
    if (type === "file") {
      setDealsData({ ...dealsData, [type]: null, delete_file: 1 });
      setFilePreview("");
    }
  };

  const previewImage = (image: any) => {
    setImagePreviewPop(true);
    setImageToPreview(image);
  };

  const closeImagePreview = () => {
    setImagePreviewPop(false);
    setImageToPreview("");
  };

  const onChangeAutoComplete = (name: string, value: any) => {
    setDealsData({ ...dealsData, [name]: value });
  };

  const dialogProps = {
    open,
    onSave,
    onClose,
    title: isUpdate ? "Update Deals" : "Add Deals",
    size: "lg",
    saveTbtText: isUpdate ? "Update" : "Save",
  };
  return (
    <MDDialog {...dialogProps}>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <MDInput
            label="Title"
            variant="standard"
            fullWidth
            name="title"
            required
            placeholder="title"
            value={dealsData?.title || ""}
            onChange={(event: any) => onChangeField(event, "title")}
            error={errors && errors.title ? true : false}
            helperText={errors && errors.title}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <MDInput
            label="Description"
            fullWidth
            multiline
            required
            variant="standard"
            rows={2}
            name="description"
            onChange={(e: any) => onChangeField(e, "description")}
            value={dealsData?.description ? dealsData.description : ""}
            error={errors && errors.description ? true : false}
            helperText={errors && errors.description}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6}>
          <CustomAutoSearch
            idKey="id"
            nameKey="name"
            fieldLabel="Brand"
            apiName="brands"
            responseKey="brands"
            name="brand"
            value={dealsData?.brand || null}
            onChange={onChangeAutoComplete}
            createNewLink={
              getPermission("mobile_app", "mobile_app_brands") ? "/mobile-app/brands" : ""
            }
          />
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6}>
          <CustomAutoSearch
            idKey="id"
            nameKey="name"
            fieldLabel="Categories"
            apiName="categories"
            responseKey="categories"
            name="categories"
            value={dealsData?.categories || []}
            onChange={onChangeAutoComplete}
            multiple
            createNewLink={
              getPermission("sola_website", "sola_website_categories")
                ? "/sola-website/categories"
                : ""
            }
          />
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6}>
          <CustomAutoSearch
            idKey="id"
            nameKey="name"
            fieldLabel="Tags"
            apiName="tags"
            responseKey="tags"
            name="tags"
            value={dealsData?.tags || []}
            onChange={onChangeAutoComplete}
            multiple
            createNewLink={
              getPermission("sola_website", "sola_website_tags") ? "/sola-website/tags" : ""
            }
          />
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <MDTypography component="label" variant="button" fontWeight="regular" color="text">
            Image
          </MDTypography>
          <Grid container>
            <Grid item xs={12} sm={8} md={8} lg={8}>
              <CustomDropZone
                multipleUpload={false}
                acceptedFiles={{
                  "image/*": [".gif", ".png", ".jpg", ".jpeg"],
                }}
                onDrop={(e: any) => onChangeImage(e, "image")}
              >
                Drop your image here, or Browse
              </CustomDropZone>
            </Grid>
            <Grid item xs={12} sm={4} md={4} lg={4}>
              {imagePreview && (
                <Grid
                  container
                  onClick={() => previewImage(imagePreview)}
                  justifyContent="center"
                  alignItems="center"
                  display="flex"
                >
                  <Grid item className="imageWithDeleteIcon">
                    <img height={100} width={100} src={imagePreview} alt="article" />
                    <MDIconButton
                      tooltipName="Delete"
                      aria-label="Delete"
                      className="removeBtnClass"
                      onClick={(e: any) => onImageRemove(e, "image")}
                    >
                      <Icon fontSize="small">close</Icon>
                    </MDIconButton>
                  </Grid>
                </Grid>
              )}
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <MDTypography component="label" variant="button" fontWeight="regular" color="text">
            File
          </MDTypography>
          <Grid container>
            <Grid item xs={12} sm={8} md={8} lg={8}>
              <CustomDropZone
                multipleUpload={false}
                acceptedFiles={{
                  "image/*": [".gif", ".png", ".jpg", ".jpeg"],
                }}
                onDrop={(e: any) => onChangeImage(e, "file")}
              >
                Drop your image here, or Browse
              </CustomDropZone>
            </Grid>
            <Grid item xs={12} sm={4} md={4} lg={4}>
              {filePreview && (
                <Grid container justifyContent="center" alignItems="center" display="flex">
                  <Grid item className="imageWithDeleteIcon">
                    <img height={100} width={100} src={fileimg} alt="article" />
                    <MDIconButton
                      tooltipName="Delete"
                      aria-label="Delete"
                      className="removeBtnClass"
                      onClick={(e: any) => onImageRemove(e, "file")}
                    >
                      <Icon fontSize="small">close</Icon>
                    </MDIconButton>
                  </Grid>
                </Grid>
              )}
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6}>
          <MDInput
            label="More Info Url"
            variant="standard"
            fullWidth
            name="more_info_url"
            placeholder="More Info Url"
            value={dealsData?.more_info_url || ""}
            onChange={(event: any) => onChangeField(event, "more_info_url")}
            error={errors && errors.more_info_url ? true : false}
            helperText={errors && errors.more_info_url}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6}>
          <Autocomplete
            id="is_featured"
            options={isFeaturedOption}
            value={dealsData.is_featured || null}
            getOptionLabel={(option: any) => option?.name}
            onChange={(event: any, newValue: any) => {
              setDealsData({ ...dealsData, is_featured: newValue });
            }}
            renderOption={(props: any, option: any) => (
              <li {...props} key={option.value}>
                {option.name}
              </li>
            )}
            renderInput={(params: any) => (
              <TextField
                {...params}
                variant="standard"
                label="Is Featured"
                placeholder="Is Featured"
              />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6}>
          <CustomAutoSearch
            idKey="id"
            nameKey="name"
            fieldLabel="Countries"
            apiName="countries"
            responseKey="countries"
            name="countries"
            value={dealsData?.countries || []}
            onChange={onChangeAutoComplete}
            error={(errors && errors.countries) || ""}
            required
            multiple
          />
        </Grid>
      </Grid>
      <ImagePreview
        imageData={imageToPreview}
        open={isPreview}
        onCloseImage={() => {
          closeImagePreview();
        }}
      />
    </MDDialog>
  );
}

export default addUpdate;
