import { Card, Grid } from "@mui/material";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import CustomAutoSearch from "components/CustomAutoSearch";

function BusinessInfo(props: any): JSX.Element {
  const { onChangeField, onChangeAutoComplete, association, errors } = props;

  return (
    <Card id="business-info">
      <MDBox p={3}>
        <MDTypography variant="h5">Business Info</MDTypography>
      </MDBox>
      <MDBox component="form" pb={3} px={3}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={12}>
            <MDInput
              label="Business Name"
              variant="standard"
              fullWidth
              name="bussiness_name"
              placeholder="Business Name"
              onChange={onChangeField}
              value={association?.bussiness_name || ""}
              error={errors && errors.bussiness_name ? true : false}
              helperText={errors && errors.bussiness_name}
            />
          </Grid>
          <Grid item xs={12} sm={12}>
            <MDInput
              label="State of Incorporation"
              variant="standard"
              fullWidth
              name="incorporation_state"
              placeholder="State of Incorporation"
              onChange={onChangeField}
              value={association?.incorporation_state || ""}
              error={errors && errors.incorporation_state ? true : false}
              helperText={errors && errors.incorporation_state}
            />
          </Grid>
          <Grid item xs={12} sm={12}>
            <MDInput
              label="Notice Address 1"
              variant="standard"
              fullWidth
              name="notice_address_1"
              placeholder="Notice Address 1"
              onChange={onChangeField}
              value={association?.notice_address_1 || ""}
              error={errors && errors.notice_address_1 ? true : false}
              helperText={errors && errors.notice_address_1}
            />
          </Grid>
          <Grid item xs={12} sm={12}>
            <MDInput
              label="Notice Address 2"
              variant="standard"
              fullWidth
              name="notice_address_2"
              placeholder="Notice Address 2"
              onChange={onChangeField}
              value={association?.notice_address_2 || ""}
              error={errors && errors.notice_address_2 ? true : false}
              helperText={errors && errors.notice_address_2}
            />
          </Grid>
          <Grid item xs={12} sm={12}>
            <MDInput
              label="Notice City "
              variant="standard"
              fullWidth
              name="notice_city"
              placeholder="Notice City "
              onChange={onChangeField}
              value={association?.notice_city || ""}
              error={errors && errors.notice_city ? true : false}
              helperText={errors && errors.notice_city}
            />
          </Grid>
          <Grid item xs={12} sm={12}>
            <MDInput
              label="Notice State / Province"
              variant="standard"
              fullWidth
              name="notice_state"
              placeholder="Notice State / Province"
              onChange={onChangeField}
              value={association?.notice_state || ""}
              error={errors && errors.notice_state ? true : false}
              helperText={errors && errors.notice_state}
            />
          </Grid>
          <Grid item xs={12} sm={12}>
            <CustomAutoSearch
              idKey="id"
              nameKey="name"
              fieldLabel="Country"
              apiName="countries"
              responseKey="countries"
              name="notice_country"
              value={association?.notice_country || null}
              onChange={onChangeAutoComplete}
              error={errors && errors.notice_country}
            />
          </Grid>
          <Grid item xs={12} sm={12}>
            <MDInput
              label="Notice Postal Code"
              variant="standard"
              fullWidth
              name="notice_postal_code"
              placeholder="Notice Postal Code"
              onChange={onChangeField}
              value={association?.notice_postal_code || ""}
              error={errors && errors.notice_postal_code ? true : false}
              helperText={errors && errors.notice_postal_code}
            />
          </Grid>
        </Grid>
      </MDBox>
    </Card>
  );
}

export default BusinessInfo;
