import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";

function BasicInfo(props: any): JSX.Element {
  const { accountDetail, onChangeField, errors } = props;
  return (
    <Paper>
      <MDBox p={2} mt={2}>
        <MDBox mb={1}>
          <MDTypography variant="h5" fontWeight="regular">
            Basic Info
          </MDTypography>
        </MDBox>
        <MDBox>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6} md={6}>
              <MDInput
                label="Sola Pro ID"
                variant="standard"
                fullWidth
                name="sola_pro_id"
                placeholder="Sola Pro ID"
                onChange={onChangeField}
                value={accountDetail?.sola_pro_id || ""}
                error={errors && errors.sola_pro_id ? true : false}
                helperText={errors && errors.sola_pro_id}
                required
              />
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
              <MDInput
                label="First name"
                variant="standard"
                fullWidth
                name="first_name"
                placeholder="First name"
                onChange={onChangeField}
                value={accountDetail?.first_name || ""}
                error={errors && errors.first_name ? true : false}
                helperText={errors && errors.first_name}
                required
              />
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
              <MDInput
                label="Last name"
                variant="standard"
                fullWidth
                name="last_name"
                placeholder="Last name"
                onChange={onChangeField}
                value={accountDetail?.last_name || ""}
                error={errors && errors.last_name ? true : false}
                helperText={errors && errors.last_name}
                required
              />
            </Grid>
          </Grid>
        </MDBox>
      </MDBox>
    </Paper>
  );
}

export default BasicInfo;
