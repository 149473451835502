import { createAsyncThunk } from "@reduxjs/toolkit";
import { notificationFail, notificationSuccess } from "store/slices/notificationSlice";
import { setLoading } from "store/slices/authSlice";
import {
  setLeaseData,
  setAccountSetUpData,
  setLeaseApplications,
  setLeads,
  setLeadDetails,
  setLeaseDetails,
  setLeaseConversation,
  setLeaseConversationNotes,
  setAllConversation,
  setDealActivityDetails,
  setAllServices,
  getAllexistingDealData,
  setApplicantDetails,
  setStudioData,
  setleaseApplicationSteps,
} from "store/slices/leaseSlice";
import Messages from "helper/messages";
import slsApiClient from "config/slsApiClient";
import {
  getErrorMessage,
  getAssociationLocations,
  removeSpecialCharactersFromPhone,
  getFilterURL,
  getAuthUser,
  getAccessLocationPermission,
} from "helper/services";
import boards from "layouts/pages/crm/deals/data";
// import { setBoardData } from "store/slices/dealSlice";
import constants from "helper/constants";
import Cookies from "js-cookie";

const hubspotutk = Cookies.get("hubspotutk");

const getLeaseData = (response: any) => {
  const lease = response?.data?.data;
  if (lease) {
    delete lease.lease_id;
    delete lease.user_id;
    delete lease.created_at;
    delete lease.updated_at;
  }
  return lease;
};

const getAccountSetUpData = (response: any) => {
  const setUp = response?.data?.data;
  if (setUp) {
    delete setUp.set_up_id;
    delete setUp.user_id;
    delete setUp.created_at;
    delete setUp.updated_at;
  }
  return setUp;
};

export const getLease = createAsyncThunk("getLease", async (_request: any, { dispatch }) => {
  dispatch(setLoading(true));
  try {
    const response = await slsApiClient().get(`get-lease?user_id=${_request.user_id}`);
    dispatch(setLoading(false));
    if (response && response?.data?.status) {
      dispatch(setLeaseData(getLeaseData(response)));
    } else {
      dispatch(notificationFail(response?.data?.message || Messages.ERROR.NO_DATA_FOUND));
    }
  } catch (error: any) {
    dispatch(setLoading(false));
    dispatch(notificationFail(error?.response?.data?.message || Messages.ERROR.NO_DATA_FOUND));
  }
});

export const updateLease = createAsyncThunk("updateLease", async (_request: any, { dispatch }) => {
  dispatch(setLoading(true));
  try {
    const response = await slsApiClient().post(`update-lease`, _request.lease);
    if (response?.data?.status) {
      dispatch(setLeaseData(getLeaseData(response)));
      if (_request.callback) {
        _request.callback();
        dispatch(setLoading(false));
      }
    } else {
      dispatch(setLoading(false));
      dispatch(notificationFail(response?.data?.message || Messages.ERROR.SOMETHING_WENT_WRONG));
    }
  } catch (error: any) {
    dispatch(notificationFail(error.message || Messages.ERROR.SOMETHING_WENT_WRONG));
    dispatch(setLoading(false));
  }
});

export const getAccountSetUp = createAsyncThunk(
  "getAccountSetUp",
  async (_request: any, { dispatch }) => {
    dispatch(setLoading(true));
    try {
      const response = await slsApiClient().get(`get-account-setup?user_id=${_request.user_id}`);
      dispatch(setLoading(false));
      if (response && response?.data?.status) {
        dispatch(setAccountSetUpData(getAccountSetUpData(response)));
      } else {
        dispatch(notificationFail(response?.data?.message || Messages.ERROR.NO_DATA_FOUND));
      }
    } catch (error: any) {
      dispatch(setLoading(false));
      dispatch(notificationFail(error?.response?.data?.message || Messages.ERROR.NO_DATA_FOUND));
    }
  }
);

export const updateAccountSetUp = createAsyncThunk(
  "updateAccountSetUp",
  async (_request: any, { dispatch }) => {
    dispatch(setLoading(true));
    try {
      const response = await slsApiClient().post(`update-account-setup`, _request.setUpData);
      if (response?.data?.status) {
        dispatch(setAccountSetUpData(getAccountSetUpData(response)));
        if (_request.callback) {
          _request.callback();
          dispatch(setLoading(false));
        }
      } else {
        dispatch(setLoading(false));
        dispatch(notificationFail(response?.data?.message || Messages.ERROR.SOMETHING_WENT_WRONG));
      }
    } catch (error: any) {
      dispatch(notificationFail(error.message || Messages.ERROR.SOMETHING_WENT_WRONG));
      dispatch(setLoading(false));
    }
  }
);

// lease applications
export const getAllLeaseApplications = createAsyncThunk(
  "getAllLeaseApplications",
  async (_request: any, { dispatch }) => {
    dispatch(setLoading(true));
    const page = _request?.pageIndex || 0;
    const size = _request?.pageSize || 50;
    const search = _request?.search || "";
    const status = _request?.activeStatus || "";
    const sortBy = _request?.sort || [];

    const request: any = {
      page,
      size,
      status,
      search_text: search,
    };
    if (sortBy?.length) {
      request.sort_field = sortBy[0]?.id;
      request.sort_type = sortBy[0]?.desc ? "desc" : "asc";
    }
    const getLocationIds = getAssociationLocations();
    if (getLocationIds && getLocationIds?.get_location_by_ids) {
      request.get_location_by_ids = getLocationIds?.get_location_by_ids;
    }

    try {
      const response = await slsApiClient().post("get-all-lease-applications-list", request);
      dispatch(setLoading(false));
      if (response?.data) {
        dispatch(setLeaseApplications(response?.data));
      } else {
        dispatch(notificationFail(Messages.ERROR.NO_DATA_FOUND));
      }
    } catch (error: any) {
      dispatch(setLoading(false));
      dispatch(notificationFail(error?.response?.data?.message || Messages.ERROR.NO_DATA_FOUND));
    }
  }
);

export const getLeaseApplicationDetail = createAsyncThunk(
  "getLeaseApplicationDetail",
  async (_request: any, { dispatch }) => {
    dispatch(setLoading(true));
    try {
      const response = await slsApiClient().get(
        `get-lease-application-by-id?id=${_request?.leaseId}`
      );
      dispatch(setLoading(false));
      if (response && response?.data?.status) {
        dispatch(setLeaseDetails(response?.data?.data));
      } else {
        dispatch(notificationFail(response?.data?.message || Messages.ERROR.NO_DATA_FOUND));
      }
    } catch (error: any) {
      dispatch(setLoading(false));
      dispatch(notificationFail(error?.response?.data?.message || Messages.ERROR.NO_DATA_FOUND));
    }
  }
);

// leads
export const getLeads = createAsyncThunk("getLeads", async (_request: any, { dispatch }) => {
  dispatch(setLoading(true));
  const page = _request?.pageIndex || 0;
  const size = _request?.pageSize || 100;
  const search = _request?.search || "";
  const type = _request?.type || "";
  const locationId = _request?.locationId || "";
  const locations = _request?.locations || [];
  const status = _request?.status || [];
  const services = _request?.services || [];
  const studioTypes = _request?.studioTypes || [];
  const studioPreferance = _request?.studioPreferance || [];
  const leadComingFrom = _request?.leadComingFrom || [];
  const sharingStudio = _request?.sharingStudio || null;
  const reasonCloseLead = _request?.reasonCloseLead || null;
  const competitorName = _request?.competitorName || null;
  const sortBy = _request?.sort || [
    {
      id: "id",
      desc: true,
    },
  ];
  const filterBy = _request?.filterData || [];
  const leads = _request?.leads || null;
  const messageStatus = _request?.messageStatus?.value || null;
  const statusWise = _request?.statusWise || false;
  const dateCreated = _request?.dateCreated || null;
  const dateClosed = _request?.dateClosed || null;
  // const viewMyDeals = _request?.viewMyDeals || false;
  const dealOwnerId = _request?.dealOwnerId || false;
  const optOut = _request?.optOut;
  const dealContactNumber = _request?.dealContactNumber;
  const isDealResubmitted = _request?.isDealResubmitted;

  try {
    let url = `get-contact-inquiry?type=${type}&page=${page}&per_page=${size}`;
    if (search) {
      url += `&search=${search}`;
    }
    // if (locationId) {
    //   url += `&location_id=${locationId}`;
    // }
    if (sortBy?.length) {
      url += `&field=${sortBy[0]?.id}&order_by=${sortBy[0]?.desc ? "desc" : "asc"}`;
    }
    const params: any = {};
    if (locations) {
      Object.assign(params, { locations });
    }
    if (status) {
      Object.assign(params, { status });
    }
    if (messageStatus) {
      Object.assign(params, { messageStatus });
    }
    if (services) {
      Object.assign(params, { services: services.map((v: any) => v.name) });
    }
    if (studioTypes) {
      Object.assign(params, { studioTypes: studioTypes.map((v: any) => v.value) });
    }
    if (studioPreferance) {
      Object.assign(params, { studioPreferance: studioPreferance.map((v: any) => v.value) });
    }
    if (leadComingFrom) {
      Object.assign(params, { leadComingFrom: leadComingFrom.map((v: any) => v.value) });
    }
    if (sharingStudio) {
      Object.assign(params, {
        sharingStudio:
          sharingStudio.value === "yes" ? true : sharingStudio.value === "no" ? false : null,
      });
    }
    if (reasonCloseLead) {
      Object.assign(params, {
        reasonCloseLead: reasonCloseLead.label,
      });
    }
    if (competitorName) {
      Object.assign(params, {
        competitorName,
      });
    }
    if (locationId && locationId.length) {
      Object.assign(params, { locations: locationId });
    }
    if (optOut) {
      Object.assign(params, { opt_in_out: optOut?.value === "yes" ? true : false });
    }
    if (dealContactNumber) {
      Object.assign(params, { deal_contact_number: dealContactNumber });
    }

    // const tempLocations: any = getAssociationLocations()?.get_location_by_ids;
    const tempLocations: any = getAccessLocationPermission(
      "crm",
      "crm_deals",
      "",
      "view_access"
    )?.get_location_by_ids;
    if (!params?.locations?.length && tempLocations?.length) {
      Object.assign(params, { locations: tempLocations });
    }
    if (statusWise) {
      Object.assign(params, { status_wise: statusWise });
    }
    if (dateCreated) {
      Object.assign(params, { date_created: dateCreated });
    }
    if (dateClosed) {
      Object.assign(params, { date_closed: dateClosed });
    }
    if (dealOwnerId) {
      Object.assign(params, { deal_owner_id: dealOwnerId });
    }
    if (isDealResubmitted) {
      Object.assign(params, { is_deal_resubmitted: isDealResubmitted });
    }
    // Apply advance filter
    if (filterBy && filterBy.length) {
      url = getFilterURL(url, filterBy);
    }

    const response = await slsApiClient().get(url, {
      params: params || null,
    });
    dispatch(setLoading(false));
    if (response?.data) {
      const result = response?.data;
      let allDeals = response?.data?.leads || [];
      if (leads && leads?.leads && leads?.leads?.length) {
        allDeals = [...leads.leads, ...allDeals];
      }

      allDeals = [...new Map(allDeals.map((obj: any) => [obj.id, obj])).values()];
      Object.assign(result, { leads: allDeals });
      dispatch(setLeads(result));
    } else {
      dispatch(notificationFail(Messages.ERROR.NO_DATA_FOUND));
    }
  } catch (error: any) {
    dispatch(setLoading(false));
    dispatch(notificationFail(error?.response?.data?.message || Messages.ERROR.NO_DATA_FOUND));
  }
});

// get customer leads
export const getCustomerLeads = createAsyncThunk(
  "getCustomerLeads",
  async (_request: any, { dispatch }) => {
    dispatch(setLoading(true));
    const page = _request?.pageIndex || 0;
    const size = _request?.pageSize || 100;
    const search = _request?.search || "";
    const type = _request?.type || "";
    const locationId = _request?.locationId || "";
    const locations = _request?.locations || [];
    const status = _request?.status || [];
    const services = _request?.services || [];
    const sortBy = _request?.sort || [];
    const filterBy = _request?.filterData || [];

    try {
      let url = `get-salon-lead?type=${type}&page=${page}&per_page=${size}`;
      if (search) {
        url += `&search=${search}`;
      }
      // if (locationId) {
      //   url += `&location_id=${locationId}`;
      // }
      if (sortBy?.length) {
        url += `&field=${sortBy[0]?.id}&order_by=${sortBy[0]?.desc ? "desc" : "asc"}`;
      }
      const params = {};
      if (locations) {
        Object.assign(params, { locations });
      }
      if (status) {
        Object.assign(params, { status });
      }
      if (services) {
        Object.assign(params, { services: services.map((v: any) => v.name) });
      }
      if (locationId && locationId.length) {
        Object.assign(params, { locations: locationId });
      }
      // Apply advance filter
      if (filterBy && filterBy.length) {
        url = getFilterURL(url, filterBy);
      }
      const response = await slsApiClient().get(url, {
        params: params || null,
      });
      dispatch(setLoading(false));
      if (response?.data) {
        dispatch(setLeads(response?.data));
      } else {
        dispatch(notificationFail(Messages.ERROR.NO_DATA_FOUND));
      }
    } catch (error: any) {
      dispatch(setLoading(false));
      dispatch(notificationFail(error?.response?.data?.message || Messages.ERROR.NO_DATA_FOUND));
    }
  }
);

export const getLeadDetails = createAsyncThunk(
  "getLeadDetails",
  async (_request: any, { dispatch }) => {
    dispatch(setLoading(true));
    const leadId = _request?.leadId || "";

    try {
      const url = `get-contact-inquiry/${leadId}`;
      const response = await slsApiClient().get(url);
      dispatch(setLoading(false));
      if (response?.data) {
        dispatch(
          setLeadDetails({ ...response?.data, goToMessages: _request?.goToMessages || false })
        );
      } else {
        dispatch(setLeadDetails(null));
        dispatch(notificationFail(Messages.ERROR.NO_DATA_FOUND));
      }
      return response?.data?.data;
    } catch (error: any) {
      dispatch(setLeadDetails(null));
      dispatch(setLoading(false));
      dispatch(notificationFail(error?.response?.data?.message || Messages.ERROR.NO_DATA_FOUND));
    }
    return "";
  }
);

export const checkDealsInLease = createAsyncThunk(
  "checkDealsInLease",
  async (_request: any, { dispatch }) => {
    dispatch(setLoading(true));
    const dealId = _request?.dealId || "";

    try {
      const url = `check_deals_from_lease/?deal_id=${dealId}`;
      const response = await slsApiClient().get(url);
      dispatch(setLoading(false));
      if (response?.data?.status) {
        dispatch(setApplicantDetails({ ...response?.data }));
      } else {
        dispatch(notificationFail(Messages.ERROR.NO_DATA_FOUND));
      }
    } catch (error: any) {
      dispatch(setLeadDetails(null));
      dispatch(setLoading(false));
      dispatch(notificationFail(error?.response?.data?.message || Messages.ERROR.NO_DATA_FOUND));
    }
    return "";
  }
);

export const getLeadConversation = createAsyncThunk(
  "getLeadConversation",
  async (_request: any, { dispatch }) => {
    let loader = true;
    if (!!_request.loading && _request.loading === "not_visible") {
      loader = false;
    }
    dispatch(setLoading(loader));
    const leadId = _request?.leadId || "";
    const page = _request?.page || 0;
    const size = _request?.size || 100;
    const append = _request?.append || false;
    const oldConversation = _request?.leaseConversation || [];
    const contactId = _request?.contact_id || null;
    const conversationsType = _request?.conversations_type || null;
    const locationIds = _request?.location_ids || [];

    try {
      const url = `get-conversation-by-lead-id`;
      const response = await slsApiClient().post(url, {
        lead_id: leadId,
        contact_id: contactId,
        conversations_type: conversationsType,
        location_ids: locationIds,
        page,
        size,
      });
      dispatch(setLoading(false));
      if (response?.data) {
        let conversation =
          response?.data?.connect_conversations && response?.data?.connect_conversations.length > 0
            ? response?.data?.connect_conversations
            : [];
        if (append && oldConversation.connect_conversations) {
          conversation = [...conversation, ...oldConversation.connect_conversations];
        }
        if (conversationsType === "notes") {
          dispatch(
            setLeaseConversationNotes({
              connect_conversations:
                conversation.length > 0 ? conversation.sort((a: any, b: any) => a.id - b.id) : [],
              meta: response?.data?.meta,
              deal_details: response?.data?.deal_details,
            })
          );
        } else {
          dispatch(
            setLeaseConversation({
              connect_conversations:
                conversation.length > 0 ? conversation.sort((a: any, b: any) => a.id - b.id) : [],
              meta: response?.data?.meta,
              deal_details: response?.data?.deal_details,
            })
          );
        }
      } else {
        dispatch(notificationFail(Messages.ERROR.NO_DATA_FOUND));
      }
    } catch (error: any) {
      dispatch(setLoading(false));
      dispatch(notificationFail(error?.response?.data?.message || Messages.ERROR.NO_DATA_FOUND));
    }
  }
);

export const getAllConversation = createAsyncThunk(
  "getAllConversation",
  async (_request: any, { dispatch }) => {
    // dispatch(setLoading(true));
    const page = _request?.page || 0;
    const size = _request?.size || 50;

    try {
      const url = `get-conversation-by-lead-id`;
      const response = await slsApiClient().post(url, {
        page,
        size,
      });
      dispatch(setLoading(false));
      if (response?.data) {
        const conversation =
          response?.data?.connect_conversations && response?.data?.connect_conversations.length > 0
            ? response?.data?.connect_conversations
            : [];
        dispatch(
          setAllConversation({
            connect_conversations:
              conversation.length > 0 ? conversation.sort((a: any, b: any) => b.id - a.id) : [],
            meta: response?.data?.meta,
          })
        );
      } else {
        dispatch(notificationFail(Messages.ERROR.NO_DATA_FOUND));
      }
    } catch (error: any) {
      dispatch(setLoading(false));
      dispatch(notificationFail(error?.response?.data?.message || Messages.ERROR.NO_DATA_FOUND));
    }
  }
);

export const sendSMS = createAsyncThunk("sendSMS", async (_request: any, { dispatch }) => {
  const leadId = _request?.leadId || "";
  const content = _request?.content || "";
  const locationId = _request?.location_id || "";
  const contactId = _request?.contact_id || null;
  const locationIds = _request?.location_ids || "";
  const fromNo = removeSpecialCharactersFromPhone(_request?.from_no) || "";
  const toNo = removeSpecialCharactersFromPhone(_request?.to_no) || "";
  const type = _request?.type || "";
  const senderUserId = _request?.sender_user_id || "";
  const attachments = _request?.attachments || [];
  const unenrollLead = _request?.unenroll_lead || false;
  const setSendSMSLoader = _request?.setSendSMSLoader || false;
  if (unenrollLead) {
    dispatch(setLoading(true));
  }
  try {
    const url = `send-sms-to-lead`;
    const response = await slsApiClient().post(url, {
      lead_id: leadId,
      content,
      location_id: locationId,
      contact_id: contactId,
      from_no: fromNo,
      to_no: toNo,
      conversations_type: type,
      sender_user_id: senderUserId,
      attachments,
      unenroll_lead: unenrollLead,
    });
    if (unenrollLead) {
      dispatch(setLoading(false));
    }
    if (response?.status) {
      if (_request?.callback) {
        _request?.callback();
      }
      dispatch(
        getLeadConversation({
          leadId,
          contact_id: contactId,
          location_ids: locationIds,
          conversations_type: type,
          loading: "not_visible",
        })
      );
    } else {
      dispatch(notificationFail(Messages.ERROR.NO_DATA_FOUND));
    }
  } catch (error: any) {
    if (unenrollLead) {
      dispatch(setLoading(false));
    }
    if (setSendSMSLoader) {
      setSendSMSLoader(false);
    }
    dispatch(notificationFail(error?.response?.data?.message || Messages.ERROR.NO_DATA_FOUND));
  }
});

export const reSendSMS = createAsyncThunk("reSendSMS", async (_request: any, { dispatch }) => {
  const { twilioSmsId, reSendMessageCallBack } = _request;
  try {
    const url = `resend-sms-to-lead`;
    const response = await slsApiClient().post(url, {
      twilio_sms_id: twilioSmsId,
    });
    if (response?.status) {
      dispatch(notificationSuccess(Messages.SUCCESS.MESSAGE_RESEND));
    } else {
      dispatch(notificationFail(response?.data?.message || Messages.ERROR.FAIL_RESEND_MESSAGE));
    }
    if (reSendMessageCallBack) {
      reSendMessageCallBack({
        status: response?.status,
        newTwilioSmsId: response?.data?.data?.twilio_sms_id,
        twilioSmsId,
      });
    }
  } catch (error: any) {
    if (reSendMessageCallBack) {
      reSendMessageCallBack({ status: false });
    }
    dispatch(
      notificationFail(error?.response?.data?.message || Messages.ERROR.FAIL_RESEND_MESSAGE)
    );
  }
});

export const leadStatusUpdate = createAsyncThunk(
  "leadStatusUpdate",
  async (_request: any, { dispatch }) => {
    dispatch(setLoading(true));
    const { leadStatus, leadId, userId } = _request;
    const startLease = _request.start_lease || null;
    try {
      const request = {
        lead_status: leadStatus,
        lead_id: leadId,
        user_id: userId,
      };
      const response = await slsApiClient().put(`update-lead-status`, request);
      if (response.data.status || startLease) {
        dispatch(setLoading(false));
        // const temp = { ...boards };
        // dispatch(setBoardData(temp));
        // const request = {
        //   type: "Request leasing information",
        //   locations: getAssociationLocations().get_location_by_ids,
        //   sort: [
        //     {
        //       id: "id",
        //       desc: true,
        //     },
        //   ],
        // };
        // dispatch(getLeads(request));
        dispatch(
          getLeadDetails({
            leadId,
          })
        );
      }
    } catch (error: any) {
      dispatch(setLoading(false));
      if (error?.response?.data?.error) {
        const message = getErrorMessage(error?.response?.data?.error);
        dispatch(notificationFail(message));
      } else {
        dispatch(
          notificationFail(
            error?.response?.data?.message || Messages.ERROR.CONTACT_INQUIRIES_SAVE_ERROR
          )
        );
      }
    }
  }
);

export const createContactIquiries = createAsyncThunk(
  "createContactIquiries",
  async (_request: any, { dispatch }) => {
    dispatch(setLoading(true));
    const {
      contactInquiriesData,
      onClose,
      isUpdate,
      pageNo,
      perPage,
      setBoardData,
      newLead,
      statusWise,
      callback,
    } = _request;
    try {
      const request = {
        // name: contactInquiriesData.name,
        firstname: contactInquiriesData.firstname,
        lastname: contactInquiriesData.lastname,
        email: contactInquiriesData.email,
        phone: contactInquiriesData.phone,
        location_id: contactInquiriesData.location.cms_location_id,
        services: contactInquiriesData.services || "",
        message: contactInquiriesData.message || "",
        how_can_we_help_you: "request_leasing_info",
        original_source_type: contactInquiriesData?.original_source_type?.value || "",
        enroll_lead: contactInquiriesData.enroll_lead || false,
        deal_status: contactInquiriesData?.deal_status?.value || "",
        hutk: hubspotutk || "",
        request_url: document.location.href || "",
      };
      const response = isUpdate
        ? await slsApiClient().put(`create-contact-inquiry/${contactInquiriesData.id}`, {
            request_tour_inquiry: contactInquiriesData,
          })
        : await slsApiClient().post("create-contact-inquiry", request);
      dispatch(setLoading(false));
      if (response) {
        const dealId = response?.data?.data?.deal_id;
        const resubmitted = response?.data?.data?.resubmitted;
        // Extra perameter for handle new lead from Lease applicant ot not
        if (newLead) {
          const setData = {
            id: response?.data?.data?.id,
            deal_name: response?.data?.data?.deal_name,
          };
          const existingDeal = {
            email: contactInquiriesData.email,
            phone: contactInquiriesData.phone,
            connect_deal: {
              id: response?.data?.data?.id,
              deal_name: response?.data?.data?.deal_name,
            },
          };
          dispatch(getAllexistingDealData([existingDeal || []]));
          dispatch(
            setleaseApplicationSteps({
              ..._request?.leaseApplicationSteps,
              deal: setData,
            })
          );
          dispatch(notificationSuccess(Messages.SUCCESS.CONTACT_INQUIRIES_CREATE_SUCCESS_CONTINUE));
        } else {
          onClose();
          if (isUpdate) {
            dispatch(notificationSuccess(Messages.SUCCESS.CONTACT_INQUIRIES_UPDATE_SUCCESS));
            // dispatch(
            //   getContactInquiriesList({ pageIndex: pageNo, pageSize: perPage, search, sort: sortBy })
            // );
          } else {
            if (callback) {
              callback(dealId);
            }
            if (resubmitted) {
              dispatch(notificationSuccess(Messages.SUCCESS.CONTACT_INQUIRIES_UPDATE_INFO));
            } else {
              dispatch(notificationSuccess(Messages.SUCCESS.CONTACT_INQUIRIES_CREATE_SUCCESS));
            }
            const temp = { ...boards };
            dispatch(setBoardData(temp));
            const request = {
              type: "Request leasing information",
              locationId: _request?.locationId || "",
              pageIndex: pageNo,
              pageSize: perPage,
              sort: [
                {
                  id: "id",
                  desc: true,
                },
              ],
              statusWise,
            };
            dispatch(getLeads(request));
          }
        }
      } else {
        dispatch(
          notificationFail(response?.data?.message || Messages.ERROR.CONTACT_INQUIRIES_SAVE_ERROR)
        );
      }
      return response?.data?.data;
    } catch (error: any) {
      dispatch(setLoading(false));
      if (error?.response?.data?.error) {
        const message = getErrorMessage(error?.response?.data?.error);
        dispatch(notificationFail(message));
      } else {
        dispatch(
          notificationFail(
            error?.response?.data?.message || Messages.ERROR.CONTACT_INQUIRIES_SAVE_ERROR
          )
        );
      }
    }
    return "";
  }
);

export const editContactIquiries = createAsyncThunk(
  "editContactIquiries",
  async (_request: any, { dispatch }) => {
    dispatch(setLoading(true));
    const {
      leadInfo,
      studioType,
      studioPreference,
      waitlistDate,
      leadComingFrom,
      reasonClosingLead,
      interestedSharing,
      competitorName,
      setMoveToWaitlist,
      setMarkClosedPopUp,
      selectedServices,
      dealOwnerId,
    } = _request;
    try {
      const authUser = getAuthUser();
      const request = {
        lead_id: leadInfo.id,
        hs_contact_id: leadInfo?.connect_contact?.hs_contact_id,
        deal_name: leadInfo.deal_name,
        firstname: leadInfo.first_name,
        lastname: leadInfo.last_name,
        email: leadInfo.email,
        phone: leadInfo.phone,
        studio_type: studioType,
        studio_preference: studioPreference,
        waitlist_date: waitlistDate,
        lead_coming_from: leadComingFrom,
        reason_closing_lead: reasonClosingLead ? reasonClosingLead.label : null,
        sharing_studio: interestedSharing ? interestedSharing.value === "yes" : false,
        lead_lost_competitor_name: competitorName,
        userId: authUser.user_id,
        services: selectedServices ? selectedServices.map((v: any) => v.name) : null,
        deal_owner_id: dealOwnerId,
      };
      const response = await slsApiClient().post("edit-contact-inquiry", request);
      dispatch(setLoading(false));
      if (response) {
        dispatch(getLeadDetails({ leadId: leadInfo.id }));
        dispatch(notificationSuccess(Messages.SUCCESS.LEAD_UPDATE_SUCCESS));
        if (setMoveToWaitlist) {
          setMoveToWaitlist(false);
        }
        if (setMarkClosedPopUp) {
          setMarkClosedPopUp(false);
        }
      } else {
        dispatch(notificationFail(response?.data?.message || Messages.ERROR.LEAD_UPDATE_ERROR));
      }
    } catch (error: any) {
      dispatch(setLoading(false));
      if (error?.response?.data?.error) {
        const message = getErrorMessage(error?.response?.data?.error);
        dispatch(notificationFail(message));
      } else {
        dispatch(
          notificationFail(error?.response?.data?.message || Messages.ERROR.LEAD_UPDATE_ERROR)
        );
      }
    }
  }
);

export const editMessageStatus = createAsyncThunk(
  "editMessageStatus",
  async (_request: any, { dispatch }) => {
    dispatch(setLoading(true));
    const { leadId, messageStatus, checkDealInfo, getAllLeads, refreshPage } = _request;
    try {
      const request = {
        lead_id: leadId,
        messageStatus,
      };
      const response = await slsApiClient().post("edit-message-status", request);
      dispatch(setLoading(false));
      if (response) {
        if (refreshPage) {
          refreshPage();
        }
        if (getAllLeads) {
          getAllLeads({});
        } else {
          const request = {
            type: "Request leasing information",
            locations: getAssociationLocations().get_location_by_ids,
            sort: [
              {
                id: "id",
                desc: true,
              },
            ],
          };
          dispatch(getLeads(request));
        }
        dispatch(notificationSuccess(Messages.SUCCESS.MESSAGE_STATUS_UPDATE_SUCCESS));
        if (checkDealInfo) {
          checkDealInfo(leadId);
        }
      } else {
        dispatch(
          notificationFail(response?.data?.message || Messages.ERROR.MESSAGE_STATUS_UPDATE_ERROR)
        );
      }
    } catch (error: any) {
      dispatch(setLoading(false));
      if (error?.response?.data?.error) {
        const message = getErrorMessage(error?.response?.data?.error);
        dispatch(notificationFail(message));
      } else {
        dispatch(
          notificationFail(
            error?.response?.data?.message || Messages.ERROR.MESSAGE_STATUS_UPDATE_ERROR
          )
        );
      }
    }
  }
);

export const deleteLeads = createAsyncThunk("deleteLeads", async (_request: any, { dispatch }) => {
  dispatch(setLoading(true));
  const { leadId, getAllLeads } = _request;
  try {
    const response = await slsApiClient().delete(`delete-lead/${leadId}`);
    if (response) {
      if (getAllLeads) {
        getAllLeads({});
      } else {
        const requestBody = {
          type: "Request leasing information",
          locations: getAssociationLocations().get_location_by_ids,
          sort: [
            {
              id: "id",
              desc: true,
            },
          ],
        };
        dispatch(getLeads(requestBody));
      }
      dispatch(setLeadDetails(null));
      dispatch(notificationSuccess(Messages.SUCCESS.LEAD_DELETE_SUCCESS));
    } else {
      dispatch(notificationFail(response?.data?.message || Messages.ERROR.LEAD_DELETE_ERROR));
    }
  } catch (error: any) {
    dispatch(setLoading(false));
    if (error?.response?.data?.error) {
      const message = getErrorMessage(error?.response?.data?.error);
      dispatch(notificationFail(message));
    } else {
      dispatch(
        notificationFail(error?.response?.data?.message || Messages.ERROR.LEAD_DELETE_ERROR)
      );
    }
  }
});

export const SendLeaseApplication = createAsyncThunk(
  "SendLeaseApplication",
  async (_request: any, { dispatch }) => {
    dispatch(setLoading(true));
    const { leaseData, onClose, leadId, leadStatus, isNotSend, activeStatus, setDraggedCard } =
      _request;
    try {
      const request: any = {
        lead_id: leadId,
        first_name: leaseData?.first_name || leaseData?.title || "",
        last_name: leaseData?.last_name || "-",
        email: leaseData?.email || "",
        phone: leaseData?.phone || "",
        location_name: leaseData?.name || leaseData?.location?.name || "",
        location_id: leaseData?.locationId || Number(leaseData?.location?.cms_location_id) || "",
        move_in_date: leaseData?.move_in_date || "",
        address:
          `${leaseData?.address_1 ? leaseData?.address_1 : ""} ${
            leaseData?.address_2 ? leaseData?.address_2 : ""
          }` || "",
        city: leaseData?.city || "",
        state: leaseData?.state || "",
        zip: leaseData?.postal_code || "",
        rent_period: leaseData?.rent_period || "",
        lease_units: leaseData?.unit || leaseData?.lease_units || {},
        rent_due_date: leaseData?.rent_due_date || "",
        lease_term: leaseData?.lease_term || "",
        license_fee_year1: leaseData?.licence_fee_1 || leaseData?.license_fee_year1 || "",
        license_fee_year2: leaseData?.licence_fee_2 || leaseData?.license_fee_year2 || "",
        security_deposit_amount: leaseData?.security_deposit_amount || "",
        charge_insurance: leaseData?.charge_insurance || false,
        insurance_change_frequency:
          leaseData?.insurance_charge_frequency || leaseData?.insurance_change_frequency || "",
        insurance_fee: leaseData?.insurance_fee || "",
        weeks_free_rent: leaseData?.weeks_free_rent || "",
        months_free_insurance: leaseData?.months_free_insurance || "",
        free_paint: leaseData?.free_paint || "",
        lease_sign: leaseData?.lease_sign || "",
        lease_start: leaseData?.lease_start || "",
        lease_end: leaseData?.lease_end || "",
        first_payment_date: leaseData?.first_payment_date || "",
        insurance_start: leaseData?.insurance_start || "",
        insurance_anniversary: leaseData?.insurance_anniversary || "",
        note: leaseData?.note || "",
        lease_application_status: isNotSend
          ? constants?.leaseApplicationStatus?.NOT_SENT
          : constants?.leaseApplicationStatus?.APPLICATION_SENT,
      };

      if (leaseData?.id) request.id = leaseData?.id;

      const response = await slsApiClient().post("add-new-lease-application", request);
      dispatch(setLoading(false));
      if (response) {
        if (setDraggedCard) {
          setDraggedCard(null);
        }
        if (onClose) onClose();
        dispatch(
          notificationSuccess(
            isNotSend
              ? Messages.SUCCESS.NOT_SENT_LEASE_SUCCESS
              : Messages.SUCCESS.SEND_LEASE_SUCCESS
          )
        );
        if (leadId) {
          dispatch(leadStatusUpdate({ leadId, leadStatus, start_lease: true }));
        } else {
          dispatch(getAllLeaseApplications({ activeStatus }));
        }
      } else {
        dispatch(notificationFail(response?.data?.message || Messages.ERROR.SEND_LEASE_ERROR));
      }
    } catch (error: any) {
      dispatch(setLoading(false));
      if (error?.response?.data?.error) {
        const message = getErrorMessage(error?.response?.data?.error);
        dispatch(notificationFail(message));
      } else {
        dispatch(
          notificationFail(error?.response?.data?.message || Messages.ERROR.SEND_LEASE_ERROR)
        );
      }
    }
  }
);

export const updateLeaseStatus = createAsyncThunk(
  "updateLeaseStatus",
  async (_request: any, { dispatch }) => {
    try {
      dispatch(setLoading(true));
      const leaseId = _request.lease_id;
      const leaseApplicationStatus = _request.lease_application_status;
      const setAddOpenDepositPopup = _request?.setAddOpenDepositPopup;
      const setRedirectUrl = _request?.setRedirectUrl;
      const activeStatus = _request?.activeStatus;
      const response = await slsApiClient().put(`lease/update-lease-status`, {
        lease_id: leaseId,
        lease_application_status: leaseApplicationStatus,
      });
      dispatch(setLoading(false));
      if (response?.data?.status) {
        dispatch(
          notificationSuccess(response?.data?.message || Messages.SUCCESS.RECORD_UPDATE_SUCCESS)
        );
        if (setAddOpenDepositPopup) {
          setAddOpenDepositPopup();
        }
        if (activeStatus) {
          dispatch(getAllLeaseApplications({ activeStatus }));
        }
        if (setRedirectUrl) {
          setRedirectUrl(response.data.deeplinkUrl);
        }
      } else {
        dispatch(notificationFail(response?.data?.message || Messages.ERROR.SOMETHING_WENT_WRONG));
      }
    } catch (error: any) {
      dispatch(setLoading(false));
      dispatch(notificationFail(error.message || Messages.ERROR.SOMETHING_WENT_WRONG));
    }
  }
);

export const deleteLeaseApplication = createAsyncThunk(
  "deleteLeaseApplication",
  async (_request: any, { dispatch }) => {
    try {
      dispatch(setLoading(true));
      const { leaseId, activeStatus, setDeleteLeaseId } = _request;
      let activeStatusTemp = activeStatus;
      if (!activeStatusTemp) {
        activeStatusTemp = "";
      }
      const response = await slsApiClient().post(`delete-lease-application`, {
        lease_id: leaseId,
      });
      dispatch(setLoading(false));
      if (response?.data?.status) {
        dispatch(
          notificationSuccess(response?.data?.message || Messages.SUCCESS.RECORD_DELETE_SUCCESS)
        );
        if (setDeleteLeaseId) {
          setDeleteLeaseId(false);
        }
        dispatch(getAllLeaseApplications({ activeStatus: activeStatusTemp }));
      } else {
        dispatch(notificationFail(response?.data?.message || Messages.ERROR.SOMETHING_WENT_WRONG));
      }
    } catch (error: any) {
      dispatch(setLoading(false));
      dispatch(notificationFail(error.message || Messages.ERROR.SOMETHING_WENT_WRONG));
    }
  }
);

export const getDealActivity = createAsyncThunk(
  "getDealActivity",
  async (_request: any, { dispatch }) => {
    dispatch(setLoading(true));
    const leadId = _request?.leadId || "";
    const page = _request?.page || 0;
    const pageSize = _request?.pageSize || 20;

    try {
      const url = `get-deal-activity/${leadId}?page=${page}&page_size=${pageSize}`;
      const response = await slsApiClient().get(url);
      dispatch(setLoading(false));
      if (response?.data && response?.data?.status) {
        dispatch(setDealActivityDetails(response?.data));
      } else {
        dispatch(notificationFail(Messages.ERROR.NO_DATA_FOUND));
      }
    } catch (error: any) {
      dispatch(setLoading(false));
      dispatch(notificationFail(error?.response?.data?.message || Messages.ERROR.NO_DATA_FOUND));
    }
  }
);

export const updateLeadTour = createAsyncThunk(
  "updateLeadTour",
  async (_request: any, { dispatch }) => {
    dispatch(setLoading(true));
    const leadId = _request?.leadId || "";
    const leadTour = _request?.leadTour || false;

    try {
      const url = `update-lead-tour`;
      const response = await slsApiClient().put(url, { leadId, leadTour });
      dispatch(setLoading(false));
      if (response?.data && response?.data?.status) {
        // dispatch(setDealActivityDetails(response?.data));
      } else {
        dispatch(notificationFail(Messages.ERROR.NO_DATA_FOUND));
      }
    } catch (error: any) {
      dispatch(setLoading(false));
      dispatch(notificationFail(error?.response?.data?.message || Messages.ERROR.NO_DATA_FOUND));
    }
  }
);

export const enrollDeal = createAsyncThunk("enrollDeal", async (_request: any, { dispatch }) => {
  dispatch(setLoading(true));
  try {
    const response = await slsApiClient().post(`enroll-deal`, _request);
    dispatch(setLoading(false));
    if (response?.data?.status) {
      dispatch(
        getLeadDetails({
          leadId: _request?.leadId,
        })
      );
      dispatch(
        notificationSuccess(
          _request?.enrollType
            ? Messages.SUCCESS.DEAL_ENROLLED_SUCCESS
            : Messages.SUCCESS.DEAL_UNENROLLED_SUCCESS
        )
      );
    } else {
      dispatch(notificationFail(response?.data?.message || Messages.ERROR.SOMETHING_WENT_WRONG));
    }
  } catch (error: any) {
    dispatch(setLoading(false));
    dispatch(
      notificationFail(error?.response?.data?.message || Messages.ERROR.SOMETHING_WENT_WRONG)
    );
  }
});

export const assignContactToDeal = createAsyncThunk(
  "assignContactToDeal",
  async (_request: any, { dispatch }) => {
    dispatch(setLoading(true));
    try {
      const response = await slsApiClient().post("assign-contacts-to-deal", _request);
      if (response) {
        dispatch(
          notificationSuccess(response?.data?.message || Messages.SUCCESS.CONTACT_ASSIGN_SUCCESS)
        );
        dispatch(getLeadDetails({ leadId: _request.leadId }));
      } else {
        dispatch(notificationFail(response?.data?.message || Messages.ERROR.ASSIGN_CONTACT_ERROR));
        dispatch(setLoading(false));
      }
    } catch (error: any) {
      dispatch(setLoading(false));
      if (error?.response?.data?.error) {
        const message = getErrorMessage(error?.response?.data?.error);
        dispatch(notificationFail(message));
      } else {
        dispatch(
          notificationFail(error?.response?.data?.message || Messages.ERROR.ASSIGN_CONTACT_ERROR)
        );
      }
    }
  }
);

export const removeContactFromDeal = createAsyncThunk(
  "removeContactFromDeal",
  async (_request: any, { dispatch }) => {
    dispatch(setLoading(true));
    const { setContactDeletePopup } = _request;
    try {
      const response = await slsApiClient().post("remove-contacts-from-deal", _request);
      if (response) {
        dispatch(
          notificationSuccess(response?.data?.message || Messages.SUCCESS.CONTACT_REMOVE_SUCCESS)
        );
        dispatch(getLeadDetails({ leadId: _request.leadId }));
        setContactDeletePopup(false);
      } else {
        dispatch(notificationFail(response?.data?.message || Messages.ERROR.REMOVE_CONTACT_ERROR));
        dispatch(setLoading(false));
      }
    } catch (error: any) {
      dispatch(setLoading(false));
      if (error?.response?.data?.error) {
        const message = getErrorMessage(error?.response?.data?.error);
        dispatch(notificationFail(message));
      } else {
        dispatch(
          notificationFail(error?.response?.data?.message || Messages.ERROR.REMOVE_CONTACT_ERROR)
        );
      }
    }
  }
);

// Get All services
export const getAllServices = createAsyncThunk(
  "getAllServices",
  async (_request: any, { dispatch }) => {
    dispatch(setLoading(true));
    try {
      const response = await slsApiClient().get("get-services", _request);
      if (response?.data?.status) {
        dispatch(setAllServices(response?.data?.data || []));
        dispatch(setLoading(false));
      } else {
        dispatch(setLoading(false));
        dispatch(notificationFail(response?.data?.message || Messages.ERROR.NO_DATA_FOUND));
      }
    } catch (error: any) {
      dispatch(setLoading(false));
      if (error?.response?.data?.error) {
        const message = getErrorMessage(error?.response?.data?.error);
        dispatch(notificationFail(message));
      } else {
        dispatch(
          notificationFail(error?.response?.data?.message || Messages.ERROR.REMOVE_CONTACT_ERROR)
        );
      }
    }
  }
);

// Get All Deals by Params
export const getAllDelasByParams = createAsyncThunk(
  "getAllDelasByParams",
  async (_request: any, { dispatch }) => {
    dispatch(setLoading(true));
    try {
      const { setShowDeals, setFinalStep } = _request;
      const email = _request?.childContactInquiries?.email || "";
      const firstname = _request?.childContactInquiries?.firstname || "";
      const lastname = _request?.childContactInquiries?.lastname || "";
      const phone = _request?.childContactInquiries?.phone || "";

      const response = await slsApiClient().get(
        `get-all-deals-by-params?email=${email}&firstname=${firstname}&lastname=${lastname}&phone=${phone}`
      );
      if (response?.data?.status) {
        if (response?.data?.leads?.length > 0) {
          dispatch(getAllexistingDealData(response?.data?.leads || []));
          if (setShowDeals) {
            setShowDeals(true);
          }
          dispatch(setLoading(false));
        } else {
          setFinalStep(false);
          const newLead = true;
          const sendData = {
            contactInquiriesData: _request?.childContactInquiries,
            newLead,
            leaseApplicationSteps: _request?.leaseApplicationSteps,
          };

          dispatch(createContactIquiries(sendData));
          dispatch(setLoading(true));
        }
      } else {
        dispatch(setLoading(false));
        dispatch(getAllexistingDealData(response?.data));
        dispatch(notificationFail(response?.data?.message || Messages.ERROR.NO_DATA_FOUND));
      }
      return response?.data?.leads;
    } catch (error: any) {
      dispatch(setLoading(false));
      if (error?.response?.data?.error) {
        const message = getErrorMessage(error?.response?.data?.error);
        dispatch(notificationFail(message));
      } else {
        dispatch(
          notificationFail(error?.response?.data?.message || Messages.ERROR.REMOVE_CONTACT_ERROR)
        );
      }
      return [];
    }
  }
);

// Get All deals by professional
export const getLeadByProfessionalDetails = createAsyncThunk(
  "getLeadByProfessionalDetails",
  async (_request: any, { dispatch }) => {
    dispatch(setLoading(true));
    try {
      const url = `check_deals_from_sola_pro?user_id=${_request?.user_id}`;
      const response = await slsApiClient().get(url);
      dispatch(setLoading(false));
      if (response?.status) {
        dispatch(getAllexistingDealData(response?.data?.data || []));
      }
      // For update state value false to true
      if (_request?.setShowDeals) {
        _request?.setShowDeals(true);
      }
      return response?.data?.data;
    } catch (error: any) {
      dispatch(setLoading(false));
      dispatch(notificationFail(error?.response?.data?.message || Messages.ERROR.NO_DATA_FOUND));
    }
    return [];
  }
);

// Studios by Locations
export const getStudiosByLocation = createAsyncThunk(
  "getStudiosByLocation",
  async (_request: any, { dispatch }) => {
    dispatch(setLoading(true));
    try {
      const url = `get_studio_from_location?location_id=${_request}`;
      const response = await slsApiClient().get(url);
      dispatch(setLoading(false));
      if (response?.status) {
        dispatch(setStudioData(response?.data || []));
      }
    } catch (error: any) {
      dispatch(setLoading(false));
      dispatch(notificationFail(error?.response?.data?.message || Messages.ERROR.NO_DATA_FOUND));
    }
  }
);

export const saveAsDraftApplication = createAsyncThunk(
  "saveAsDraftApplication",
  async (_request: any, { dispatch }) => {
    dispatch(setLoading(true));
    const removeData = JSON.stringify(_request?.removeDocs);
    // console.log("_request?.leaseApplicationSteps", _request?.leaseApplicationSteps?.applicants);
    const updatedObject = {
      ..._request?.leaseApplicationSteps,
      lease_application_status: "draft",
      leaseData: _request?.childLeaseData
        ? _request?.childLeaseData
        : _request?.leaseApplicationSteps?.leaseData,
      applicants: _request?.childMembers?.length
        ? _request?.childMembers
        : _request?.leaseApplicationSteps?.applicants,
      studio: _request?.leaseApplicationSteps?.studio,
      rm_details: _request?.rmChangeDetials,
      security_deposit_amount_type: _request?.rmChangeDetials?.security_deposit_amount_type,
      security_deposit_amount: _request?.rmChangeDetials?.security_deposit_amount,
      rent_period: _request?.rmChangeDetials?.rent_period,
      applicant_documents: _request?.leaseApplicationSteps?.applicant_documents?.length ? [] : [],
    };
    // console.log("updatedObject ", updatedObject);
    // console.log("_request?.saveDraftData ", _request?.saveDraftData?.updatedApplicants);
    // const applicantDocs = _request?.saveDraftData?.updatedApplicant
    //   ? _request?.saveDraftData?.updatedApplicant
    //   : [];
    const applicantDocs = _request?.saveDraftData?.updatedApplicant;
    const mainData: any = updatedObject;
    const formData = new FormData();
    const leaseId = _request?.leaseAppplicationId;
    if (_request?.isUpdate) {
      formData.append("lease_application_id", leaseId);
    }
    if (applicantDocs) {
      updatedObject?.applicants?.map((appItem: any, appIndex: number) => {
        applicantDocs?.[`applicant_${appIndex}`]?.map((obj: any, docIndex: any) => {
          const fiedlName = `applicants_${appIndex}_document_${docIndex}`;
          const docData = obj;
          formData.append(fiedlName, docData);
          return true;
        });
        return true;
      });
    }
    // Remove Doc array
    formData.append("remove_doc", removeData);
    // Set approval data Object
    formData.append("approval_data", JSON.stringify(mainData));
    try {
      const response = await slsApiClient().post(`save-as-draft`, formData);
      dispatch(setLoading(false));
      if (response?.status) {
        if (_request?.setLeasePopup) {
          _request?.setLeasePopup(false);
        }
        dispatch(getAllLeaseApplications({ activeStatus: _request?.activeStatus }));
        dispatch(notificationSuccess(response?.data?.message));
        dispatch(setleaseApplicationSteps({ page: 1 }));
        dispatch(setApplicantDetails({}));
      }
    } catch (error: any) {
      dispatch(setLoading(false));
      dispatch(notificationFail(error?.response?.data?.message || Messages.ERROR.NO_DATA_FOUND));
    }
  }
);

// Get Lease Data By lease id
export const getLeaseDataById = createAsyncThunk(
  "getLeaseDataById",
  async (_request: any, { dispatch }) => {
    dispatch(setLoading(true));
    try {
      const url = `get-save-draft?lease_application_id=${_request?.id}`;
      const response = await slsApiClient().get(url);
      dispatch(setLoading(false));
      if (response?.status) {
        dispatch(setleaseApplicationSteps(response?.data?.data?.temp_data || {}));
      }
    } catch (error: any) {
      dispatch(setLoading(false));
      dispatch(notificationFail(error?.response?.data?.message || Messages.ERROR.NO_DATA_FOUND));
    }
  }
);

// Send final Lease application
export const saveFinalApplication = createAsyncThunk(
  "saveFinalApplication",
  async (_request: any, { dispatch }) => {
    dispatch(setLoading(true));
    const removeData = JSON.stringify(_request?.removeDocs);
    const updatedObject = {
      ..._request?.leaseApplicationSteps,
      leaseData: _request?.childLeaseData
        ? _request?.childLeaseData
        : _request?.leaseApplicationSteps?.leaseData,
      applicants: _request?.leaseApplicationSteps?.applicants,
      studio: _request?.leaseApplicationSteps?.studio,
      rm_details: _request?.rmChangeDetials,
      security_deposit_amount_type: _request?.rmChangeDetials?.security_deposit_amount_type,
      security_deposit_amount: _request?.rmChangeDetials?.security_deposit_amount,
      rent_period: _request?.rmChangeDetials?.rent_period,
      applicant_documents: _request?.leaseApplicationSteps?.applicant_documents?.length ? [] : [],
    };
    const applicantDocs = _request?.saveDraftData?.updatedApplicant;
    const mainData: any = updatedObject;
    const formData = new FormData();
    const leaseId = _request?.leaseAppplicationId;
    if (_request?.isUpdate) {
      formData.append("lease_application_id", leaseId);
    }
    if (applicantDocs) {
      updatedObject?.applicants?.map((appItem: any, appIndex: number) => {
        applicantDocs?.[`applicant_${appIndex}`]?.map((obj: any, docIndex: any) => {
          const fiedlName = `applicants_${appIndex}_document_${docIndex}`;
          const docData = obj;
          formData.append(fiedlName, docData);
          return true;
        });
        return true;
      });
    }

    // Remove Doc array
    formData.append("remove_doc", removeData);
    // Set approval data Object
    formData.append("approval_data", JSON.stringify(mainData));
    try {
      const response = await slsApiClient().post(`create-lease-application`, formData);
      dispatch(setLoading(false));
      if (response?.status) {
        if (_request?.setLeasePopup) {
          _request?.setLeasePopup(false);
        }
        dispatch(getAllLeaseApplications({ activeStatus: _request?.activeStatus }));
        dispatch(notificationSuccess(response?.data?.message));
        dispatch(setleaseApplicationSteps({ page: 1 }));
        dispatch(setApplicantDetails({}));
      }
    } catch (error: any) {
      dispatch(setLoading(false));
      dispatch(notificationFail(error?.response?.data?.message || Messages.ERROR.NO_DATA_FOUND));
    }
  }
);

// Update deal owner from message
export const leadOwnerUpdate = createAsyncThunk(
  "leadOwnerUpdate",
  async (_request: any, { dispatch }) => {
    dispatch(setLoading(true));
    const { leadId, leadOwner, userId } = _request;
    try {
      const request = {
        lead_id: leadId,
        deal_owner_id: leadOwner,
        user_id: userId,
      };
      const response = await slsApiClient().put(`update-lead-owner`, request);
      if (response.data.status) {
        dispatch(setLoading(false));
        dispatch(
          getLeadDetails({
            leadId,
          })
        );
      }
    } catch (error: any) {
      dispatch(setLoading(false));
      if (error?.response?.data?.error) {
        const message = getErrorMessage(error?.response?.data?.error);
        dispatch(notificationFail(message));
      } else {
        dispatch(notificationFail(error?.response?.data?.message || Messages.ERROR.NO_DATA_FOUND));
      }
    }
  }
);
