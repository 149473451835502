import MDDialog from "components/MDDialog";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import { Grid } from "@mui/material";
import { getLocalDateTime, phoneNumberformat } from "helper/services";

function ViewDetails(props: any): JSX.Element {
  const { open, onClose, updateData } = props;

  const dialogProps = {
    open,
    onClose,
    title: "View Maintenance Contact",
    size: "md",
    saveBtn: false,
  };

  return (
    <MDDialog {...dialogProps}>
      <MDBox mt={2}>
        <Grid container>
          <Grid item xs={12} sm={6} md={6}>
            <MDTypography fontWeight="bold" variant="button">
              Location
            </MDTypography>
            <MDBox>
              <MDTypography variant="button">
                {updateData?.location?.name ? updateData?.location?.name : "-"}
              </MDTypography>
            </MDBox>
          </Grid>
          <Grid item xs={12} sm={6} md={6}>
            <MDTypography fontWeight="bold" variant="button">
              Contact Type
            </MDTypography>
            <MDBox>
              <MDTypography variant="button">
                {updateData?.contact_type ? updateData?.contact_type : "-"}
              </MDTypography>
            </MDBox>
          </Grid>
        </Grid>
      </MDBox>
      <MDBox mt={2}>
        <Grid container>
          <Grid item xs={12} sm={6} md={6}>
            <MDTypography fontWeight="bold" variant="button">
              Contact Order
            </MDTypography>
            <MDBox>
              <MDTypography variant="button">
                {updateData?.contact_order ? updateData?.contact_order : "-"}
              </MDTypography>
            </MDBox>
          </Grid>
          <Grid item xs={12} sm={6} md={6}>
            <MDTypography fontWeight="bold" variant="button">
              Contact First Name
            </MDTypography>
            <MDBox>
              <MDTypography variant="button">
                {updateData?.contact_first_name ? updateData?.contact_first_name : "-"}
              </MDTypography>
            </MDBox>
          </Grid>
        </Grid>
      </MDBox>
      <MDBox mt={2}>
        <Grid container>
          <Grid item xs={12} sm={6} md={6}>
            <MDTypography fontWeight="bold" variant="button">
              Contact Last Name
            </MDTypography>
            <MDBox>
              <MDTypography variant="button">
                {updateData?.contact_last_name ? updateData?.contact_last_name : "-"}
              </MDTypography>
            </MDBox>
          </Grid>
          <Grid item xs={12} sm={6} md={6}>
            <MDTypography fontWeight="bold" variant="button">
              Contact Phone number
            </MDTypography>
            <MDBox>
              <MDTypography variant="button">
                {phoneNumberformat(updateData?.contact_phone_number) || "-"}
              </MDTypography>
            </MDBox>
          </Grid>
        </Grid>
      </MDBox>
      <MDBox mt={2}>
        <Grid container>
          <Grid item xs={12} sm={6} md={6}>
            <MDTypography fontWeight="bold" variant="button">
              Contact Email
            </MDTypography>
            <MDBox>
              <MDTypography variant="button">{updateData?.contact_email || "-"}</MDTypography>
            </MDBox>
          </Grid>
          <Grid item xs={12} sm={6} md={6}>
            <MDTypography fontWeight="bold" variant="button">
              Contact Admin
            </MDTypography>
            <MDBox>
              <MDTypography variant="button">{updateData?.contact_admin || "-"}</MDTypography>
            </MDBox>
          </Grid>
        </Grid>
      </MDBox>
      <MDBox mt={2}>
        <Grid container>
          <Grid item xs={12} sm={6} md={6}>
            <MDTypography fontWeight="bold" variant="button">
              Contact Preference
            </MDTypography>
            <MDBox>
              <MDTypography variant="button">{updateData?.contact_preference || "-"}</MDTypography>
            </MDBox>
          </Grid>
          <Grid item xs={12} sm={6} md={6}>
            <MDTypography fontWeight="bold" variant="button">
              Request Routing Url
            </MDTypography>
            <MDBox>
              <MDTypography variant="button">{updateData?.request_routing_url || "-"}</MDTypography>
            </MDBox>
          </Grid>
        </Grid>
      </MDBox>
      <MDBox mt={2}>
        <Grid container>
          <Grid item xs={12} sm={6} md={6}>
            <MDTypography fontWeight="bold" variant="button">
              Created At
            </MDTypography>
            <MDBox>
              <MDTypography variant="button">
                {getLocalDateTime(updateData?.created_at)}
              </MDTypography>
            </MDBox>
          </Grid>
          <Grid item xs={12} sm={6} md={6}>
            <MDTypography fontWeight="bold" variant="button">
              Updated At
            </MDTypography>
            <MDBox>
              <MDTypography variant="button">
                {getLocalDateTime(updateData?.updated_at)}
              </MDTypography>
            </MDBox>
          </Grid>
        </Grid>
      </MDBox>
    </MDDialog>
  );
}

export default ViewDetails;
