import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";
import { Theme } from "@mui/material/styles";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import { ScrollToSection } from "helper/services";
import { useMaterialUIController } from "context";

function Sidenav(props: any): JSX.Element {
  const { sidenavItems } = props;
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;

  // const sidenavItems = [
  //   { icon: "phone", label: "Number Status", href: "number-status" },
  //   { icon: "sms", label: "SMS Status", href: "sms-status" },
  //   { icon: "receipt_long", label: "Locations", href: "locations" },
  //   { icon: "ring_volume", label: "Call Tree Status", href: "call-tree-status" },
  //   { icon: "call_split", label: "Call Tree Setting", href: "call-tree-setting" },
  // ];

  const renderSidenavItems = sidenavItems.map(({ icon, label, href }: any, key: any) => {
    const itemKey = `item-${key}`;

    return (
      <MDBox key={itemKey} component="li" pt={key === 0 ? 0 : 1}>
        <MDTypography
          component="a"
          onClick={() => ScrollToSection(href)}
          variant="button"
          fontWeight="regular"
          textTransform="capitalize"
          sx={({
            borders: { borderRadius },
            functions: { pxToRem },
            palette: { light },
            transitions,
          }: Theme) => ({
            display: "flex",
            alignItems: "center",
            cursor: "pointer",
            borderRadius: borderRadius.md,
            padding: `${pxToRem(10)} ${pxToRem(16)}`,
            transition: transitions.create("background-color", {
              easing: transitions.easing.easeInOut,
              duration: transitions.duration.shorter,
            }),

            "&:hover": {
              backgroundColor: light.main,
            },
          })}
        >
          <MDBox mr={1.5} lineHeight={1} color={darkMode ? "white" : "dark"}>
            <Icon fontSize="small">{icon}</Icon>
          </MDBox>
          {label}
        </MDTypography>
      </MDBox>
    );
  });

  return (
    <Card
      sx={{
        borderRadius: ({ borders: { borderRadius } }) => borderRadius.lg,
        position: "sticky",
        top: "100px",
      }}
    >
      <MDBox
        component="ul"
        display="flex"
        flexDirection="column"
        p={2}
        m={0}
        sx={{ listStyle: "none" }}
      >
        {renderSidenavItems}
      </MDBox>
    </Card>
  );
}

export default Sidenav;
