import { Card, FormControlLabel, Grid, Switch } from "@mui/material";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

function SmsStatus(props: any): JSX.Element {
  const { onChangeField, phoneDetail } = props;

  return (
    <Card id="sms-status">
      <MDBox p={3}>
        <MDTypography variant="h5">SMS Status</MDTypography>
      </MDBox>
      <MDBox component="form" pb={3} px={3}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={12}>
            <FormControlLabel
              control={
                <Switch
                  checked={phoneDetail?.sms_status}
                  name="sms_status"
                  onChange={onChangeField}
                  inputProps={{ "aria-label": "controlled" }}
                />
              }
              label={phoneDetail?.sms_status ? "Enabled" : "Disabled"}
            />
          </Grid>
        </Grid>
      </MDBox>
    </Card>
  );
}

export default SmsStatus;
