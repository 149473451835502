import { useState, useEffect } from "react";
import MDDialog from "components/MDDialog";
import { Checkbox, FormControl, FormControlLabel, FormGroup, Grid } from "@mui/material";
import { useAppSelector, useAppDispatch } from "store/store";
import { buyRMNewNumber, getRMAvailableNumbersList } from "store/thunk/repairMaintenance";
import DataTable from "examples/Tables/DataTable";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";

function BuyNumberPopup(props: any): JSX.Element {
  const { onClose, open } = props;
  const dispatch = useAppDispatch();
  const [dataList, setDataList] = useState<any>([]);
  const [confirmPopupData, setConfirmData] = useState<any>(false);
  const { userData } = useAppSelector((state: any) => state.authReducer);
  const { availableNumbersList } = useAppSelector((state: any) => state.repairMaintenanceSlice);
  const [search, setSearch] = useState<any>("");

  useEffect(() => {
    dispatch(getRMAvailableNumbersList({}));
  }, []);

  useEffect(() => {
    if (availableNumbersList) {
      setDataList(availableNumbersList);
    }
  }, [availableNumbersList]);

  const onBuyNow = () => {
    if (confirmPopupData) {
      const req = {
        buyReq: {
          user_id: userData?.user_id,
          phone_number: confirmPopupData?.phoneNumber,
        },
        onClose,
        setConfirmData,
      };
      dispatch(buyRMNewNumber(req));
    }
  };

  const actionButtons = (obj: any) => (
    <MDButton
      color="info"
      variant="gradient"
      size="small"
      className="xs-small"
      onClick={() => setConfirmData(obj)}
    >
      Buy
    </MDButton>
  );
  const capabilities = (obj: any) => (
    <FormControl sx={{ m: 3 }} component="fieldset" variant="standard">
      <FormGroup row>
        <FormControlLabel control={<Checkbox checked={obj?.capabilities?.MMS} />} label="SMS" />
        <FormControlLabel control={<Checkbox checked={obj?.capabilities?.SMS} />} label="MMS" />
        <FormControlLabel control={<Checkbox checked={obj?.capabilities?.voice} />} label="Voice" />
      </FormGroup>
    </FormControl>
  );

  const assignDialogProps = {
    open,
    onClose,
    title: `Buy New Twillio Number`,
    size: "lg",
    saveBtn: false,
    saveTbtText: "Buy Now",
    closeBtnText: "Close",
  };

  const getRows = () => {
    if (dataList.length > 0) {
      const data: any = dataList?.map((obj: any) => ({
        friendlyName: obj?.friendlyName || "-",
        isoCountry: obj?.isoCountry || "-",
        postalCode: obj?.postalCode || "-",
        capabilities: capabilities(obj),
        action: actionButtons(obj),
      }));
      return data;
    }
    return [];
  };

  const getColumns = () => {
    const columns: any = [
      { Header: "Friendly Name", accessor: "friendlyName", width: "100px" },
      { Header: "Iso Country", accessor: "isoCountry", width: "25px" },
      { Header: "Postal Code", accessor: "postalCode", width: "100px" },
      { Header: "Capabilities", accessor: "capabilities", width: "250px" },
      { Header: "action", accessor: "action" },
    ];
    return columns;
  };

  const buyTableData = {
    columns: getColumns(),
    rows: getRows(),
  };

  const closeConifrm = () => {
    setConfirmData(false);
  };

  const confirmDialogProps = {
    open: confirmPopupData ? confirmPopupData : false,
    onClose: () => closeConifrm(),
    onSave: () => onBuyNow(),
    title: `Are you sure you want to buy ${confirmPopupData?.friendlyName} ?`,
    size: "sm",
    saveBtn: true,
    saveTbtText: "Buy Now",
    closeBtnText: "Close",
  };
  const onSearchChange = () => {
    const request: any = {
      area_code: search,
    };
    dispatch(getRMAvailableNumbersList(request));
  };

  return (
    <MDDialog {...assignDialogProps}>
      <Grid container spacing={{ xs: 2, md: 3 }}>
        <Grid item xs={6} sm={6} md={6} lg={6} />
        <Grid item xs={6} sm={6} md={6} lg={6} style={{ textAlign: "right" }}>
          <MDInput
            placeholder="Filter By Area Code"
            value={search}
            className="search-xs-small"
            size="small"
            onChange={(event: any) => setSearch(event.target.value)}
          />
          <MDButton
            color="info"
            variant="gradient"
            size="small"
            className="xs-small"
            onClick={onSearchChange}
          >
            {" "}
            Search{" "}
          </MDButton>
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <DataTable table={buyTableData} canSearch manualSort={false} searchText="Search...." />
        </Grid>
      </Grid>
      {confirmPopupData && (
        <MDDialog {...confirmDialogProps}>
          <MDTypography fontSize={16}>
            If you buy this phone number, This will charge the twillio account.
          </MDTypography>
        </MDDialog>
      )}
    </MDDialog>
  );
}

export default BuyNumberPopup;
