import { useEffect, useState } from "react";
import { useAppDispatch } from "store/store";
import MDDialog from "components/MDDialog";
import MDInput from "components/MDInput";
import MDBox from "components/MDBox";
import CustomDropZone from "components/CustomDropZone";
import { InputLabel, MenuItem, Select, Grid, Icon } from "@mui/material";
import Validations from "helper/validations";
import { saveSola10kImage } from "store/thunk/systemAdmin";
import ImagePreview from "layouts/pages/ImagePreview";
import CustomErrorMessage from "components/CustomErrorMessage";
import CustomRequiredLabel from "components/CustomRequiredLabel";
import MDIconButton from "components/MDIconButton";
import { scrollToErrorByClass } from "helper/services";

function ViewDetails(props: any): JSX.Element {
  const dispatch = useAppDispatch();

  const { open, onClose, updateData, pageNo, perPage, search, sortBy } = props;
  const [isUpdate, setIsUpdate] = useState<any>(false);
  const [solaImageData, setSolaImageData] = useState<any>({});
  const [imagePreview, setImagePreview] = useState<any>("");
  const [errors, setErrors] = useState<any>({});
  const [generatedImagePreview, setGeneratedImagePreview] = useState<any>("");

  const [isPreview, setImagePreviewPopup] = useState(false);
  const [imageToPreview, setImageToPreview] = useState("");

  const onSave = () => {
    const error = Validations.validateSola10kForm(solaImageData);
    setErrors(error);
    if (!Object.keys(error).length) {
      const req = {
        solaImageData,
        onClose,
        isUpdate,
        pageNo,
        perPage,
        search,
        sortBy,
      };
      dispatch(saveSola10kImage(req));
    } else {
      scrollToErrorByClass(error);
    }
  };

  const previewImage = (image: any) => {
    setImagePreviewPopup(true);
    setImageToPreview(image);
  };

  const closeImagePreview = () => {
    setImagePreviewPopup(false);
    setImageToPreview("");
  };

  useEffect(() => {
    if (updateData) {
      setIsUpdate(true);
      setSolaImageData(updateData);
      setImagePreview(updateData.original_image_url);
      setGeneratedImagePreview(updateData.generated_image_url);
    }
  }, [updateData]);

  const onChangeImage = async (files: any, type: any) => {
    if (files[0]) {
      setSolaImageData({ ...solaImageData, [type]: files[0] });
      const file = files[0];
      const reader = new FileReader();
      reader.onload = function (e) {
        if (type === "original_image_url") {
          setImagePreview(e.target.result);
        } else {
          setGeneratedImagePreview(e.target.result);
        }
      };
      reader.readAsDataURL(file);
    }
  };

  const onChangeField = (e: any, field: string) => {
    setSolaImageData({ ...solaImageData, [field]: e.target.value });
  };

  const onImageRemove = async (e: any, type: any) => {
    e.stopPropagation();
    setSolaImageData({ ...solaImageData, [type]: null });
    if (type === "original_image_url") {
      setImagePreview(null);
    } else {
      setGeneratedImagePreview(null);
    }
  };

  const dialogProps = {
    open,
    onSave,
    onClose,
    title: isUpdate ? "Update Sola10k Image" : "Add Sola10k Image",
    size: "sm",
    saveTbtText: isUpdate ? "Update" : "Save",
  };

  return (
    <MDDialog {...dialogProps}>
      <MDBox mt={2} className="select-container">
        <InputLabel id="approved-label">Approved</InputLabel>
        <Select
          fullWidth
          labelId="approved-label"
          variant="standard"
          id="approved"
          value={solaImageData?.approved ? 1 : 0}
          label="approved"
          sx={{ padding: 0 }}
          onChange={(event: any) => onChangeField(event, "approved")}
          error={errors && errors.approved ? true : false}
        >
          <MenuItem value={1}>Yes</MenuItem>
          <MenuItem value={0}>No</MenuItem>
        </Select>
        {errors && errors.approved && <CustomErrorMessage message={errors.approved} />}
      </MDBox>
      <MDBox mt={2}>
        <MDInput
          label="Name"
          variant="standard"
          fullWidth
          required
          name="name"
          placeholder="name"
          onChange={(event: any) => onChangeField(event, "name")}
          value={solaImageData?.name ? solaImageData?.name : ""}
          error={errors && errors.name ? true : false}
          helperText={errors && errors.name}
        />
      </MDBox>
      <MDBox mt={2}>
        <MDInput
          label="Instagram Handle"
          variant="standard"
          fullWidth
          name="instagram_handle"
          placeholder="Instagram Handle"
          onChange={(event: any) => onChangeField(event, "instagram_handle")}
          value={solaImageData?.instagram_handle ? solaImageData?.instagram_handle : ""}
          error={errors && errors.instagram_handle ? true : false}
          helperText={errors && errors.instagram_handle}
        />
      </MDBox>
      <MDBox mt={2}>
        <MDInput
          label="Statement"
          variant="standard"
          fullWidth
          name="statement"
          placeholder="Statement"
          onChange={(event: any) => onChangeField(event, "statement")}
          value={solaImageData?.statement ? solaImageData?.statement : ""}
        />
      </MDBox>
      <MDBox mt={2}>
        <CustomRequiredLabel label="Image" />
        <Grid container spacing={2}>
          <Grid item xs={12} md={8}>
            <CustomDropZone
              multipleUpload={false}
              acceptedFiles={{
                "image/*": [".gif", ".png", ".jpg", ".jpeg"],
              }}
              onDrop={(e: any) => onChangeImage(e, "original_image_url")}
            >
              Drop your image here, or Browse
            </CustomDropZone>
          </Grid>
          <Grid item xs={12} md={4}>
            {imagePreview && (
              <Grid
                container
                onClick={() => previewImage(imagePreview)}
                justifyContent="center"
                alignItems="center"
                textAlign="center"
              >
                <Grid item className="imageWithDeleteIcon">
                  <img
                    style={{ maxHeight: "100px" }}
                    width={100}
                    src={imagePreview}
                    alt="original"
                    className="thumbnail-img"
                  />
                  <MDIconButton
                    tooltipName="Delete"
                    aria-label="Delete"
                    className="removeBtnClass"
                    onClick={(e: any) => onImageRemove(e, "original_image_url")}
                  >
                    <Icon fontSize="small">close</Icon>
                  </MDIconButton>
                </Grid>
              </Grid>
            )}
          </Grid>
        </Grid>
        <div className="image">
          {errors && errors.image && <CustomErrorMessage message={errors.image} />}
        </div>
      </MDBox>
      <MDBox mt={2}>
        <CustomRequiredLabel label="Generated Image" />
        <Grid container spacing={2}>
          <Grid item xs={12} md={8}>
            <CustomDropZone
              multipleUpload={false}
              acceptedFiles={{
                "image/*": [".gif", ".png", ".jpg", ".jpeg"],
              }}
              onDrop={(e: any) => onChangeImage(e, "generated_image_url")}
            >
              Drop your image here, or Browse
            </CustomDropZone>
          </Grid>
          <Grid item xs={12} md={4}>
            {generatedImagePreview && (
              <Grid
                container
                onClick={() => previewImage(generatedImagePreview)}
                justifyContent="center"
                alignItems="center"
                textAlign="center"
              >
                <Grid item className="imageWithDeleteIcon">
                  <img
                    style={{ maxHeight: "100px" }}
                    width={100}
                    src={generatedImagePreview}
                    alt="generated"
                    className="thumbnail-img"
                  />
                  <MDIconButton
                    tooltipName="Delete"
                    aria-label="Delete"
                    className="removeBtnClass"
                    onClick={(e: any) => onImageRemove(e, "generated_image_url")}
                  >
                    <Icon fontSize="small">close</Icon>
                  </MDIconButton>
                </Grid>
              </Grid>
            )}
          </Grid>
        </Grid>
        <div className="generated_image">
          {errors && errors.generated_image && (
            <CustomErrorMessage message={errors.generated_image} />
          )}
        </div>
      </MDBox>
      <ImagePreview
        imageData={imageToPreview}
        open={isPreview}
        onCloseImage={() => {
          closeImagePreview();
        }}
      />
    </MDDialog>
  );
}

export default ViewDetails;
