import { useEffect, useState } from "react";
import { useAppDispatch } from "store/store";
import MDDialog from "components/MDDialog";
import MDInput from "components/MDInput";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import CustomDropZone from "components/CustomDropZone";
import MDIconButton from "components/MDIconButton";
import { Grid, Icon } from "@mui/material";
import { saveClassImage } from "store/thunk/mobileAppThunk";
import Validations from "helper/validations";
import ImagePreview from "layouts/pages/ImagePreview";
import CustomErrorMessage from "components/CustomErrorMessage";

function AddUpdate(props: any): JSX.Element {
  const dispatch = useAppDispatch();

  const { open, onClose, updateData, pageNo, perPage, search, sortBy } = props;
  const [isUpdate, setIsUpdate] = useState<any>(false);
  const [classImagesData, setClassImagesData] = useState<any>({});
  const [imagePreview, setImagePreview] = useState<any>("");
  const [errors, setErrors] = useState<any>({});
  const [thumbnailImagePreview, setThumbnailImagePreview] = useState<any>("");

  const [isPreview, setImagePreviewPopup] = useState(false);
  const [imageToPreview, setImageToPreview] = useState("");

  const onSave = () => {
    const error = Validations.validateClassImagesForm(classImagesData);
    setErrors(error);
    if (!Object.keys(error).length) {
      const req = {
        classImagesData,
        onClose,
        isUpdate,
        pageNo,
        perPage,
        search,
        sortBy,
      };
      dispatch(saveClassImage(req));
    }
  };
  useEffect(() => {
    if (updateData) {
      setIsUpdate(true);
      setClassImagesData(updateData);
      setImagePreview(updateData.image_large_url);
      setThumbnailImagePreview(updateData.thumbnail_large_url);
    }
  }, [updateData]);

  const onChangeImage = async (files: any, type: any) => {
    if (files[0]) {
      const file = files[0];
      const reader = new FileReader();
      reader.onload = function (e) {
        if (type === "image") {
          setClassImagesData({ ...classImagesData, [type]: files[0], delete_image: 0 });
          setImagePreview(e.target.result);
        } else {
          setClassImagesData({ ...classImagesData, [type]: files[0], delete_thumbnail: 0 });
          setThumbnailImagePreview(e.target.result);
        }
      };
      reader.readAsDataURL(file);
    }
  };

  const onChangeField = (e: any, field: string) => {
    setClassImagesData({ ...classImagesData, [field]: e.target.value });
  };

  const onImageRemove = async (e: any, type: string) => {
    e.stopPropagation();
    if (type === "image") {
      setClassImagesData({ ...classImagesData, [type]: null, delete_image: 1 });
      setImagePreview("");
    } else {
      setClassImagesData({ ...classImagesData, [type]: null, delete_thumbnail: 1 });
      setThumbnailImagePreview("");
    }
  };

  const dialogProps = {
    open,
    onSave,
    onClose,
    title: isUpdate ? "Update Class Images" : "Add Class Images",
    size: "sm",
    saveTbtText: isUpdate ? "Update" : "Save",
  };

  const previewImage = (image: any) => {
    setImagePreviewPopup(true);
    setImageToPreview(image);
  };

  const closeImagePreview = () => {
    setImagePreviewPopup(false);
    setImageToPreview("");
  };

  return (
    <MDDialog {...dialogProps}>
      <MDBox mt={2}>
        <MDInput
          label="Name"
          variant="standard"
          fullWidth
          name="name"
          placeholder="name"
          required
          value={classImagesData.name || ""}
          onChange={(event: any) => onChangeField(event, "name")}
          error={errors && errors.name ? true : false}
          helperText={errors && errors.name}
        />
      </MDBox>
      <MDBox mt={2}>
        <MDTypography variant="button">Image</MDTypography>
        <Grid container>
          <Grid item xs={12} md={8}>
            <CustomDropZone
              multipleUpload={false}
              acceptedFiles={{
                "image/*": [".gif", ".png", ".jpg", ".jpeg"],
              }}
              onDrop={(e: any) => onChangeImage(e, "image")}
            >
              Drop your image here, or Browse
            </CustomDropZone>
          </Grid>
          <Grid item xs={12} md={4} alignItems="center" display="flex">
            {imagePreview && (
              <Grid
                container
                onClick={() => previewImage(imagePreview)}
                justifyContent="center"
                alignItems="center"
                textAlign="center"
              >
                <Grid item className="imageWithDeleteIcon">
                  <img
                    width="90%"
                    style={{ maxHeight: "100px" }}
                    src={imagePreview}
                    alt="original"
                  />
                  <MDIconButton
                    tooltipName="Delete"
                    aria-label="Delete"
                    className="removeBtnClass"
                    onClick={(e: any) => onImageRemove(e, "image")}
                  >
                    <Icon fontSize="small">close</Icon>
                  </MDIconButton>
                </Grid>
              </Grid>
            )}
          </Grid>
        </Grid>
        {errors && errors.countries && <CustomErrorMessage message={errors.countries} />}
      </MDBox>
      <MDBox mt={2}>
        <MDTypography variant="button">Thumbnail</MDTypography>
        <Grid container>
          <Grid item xs={12} md={8}>
            <CustomDropZone
              multipleUpload={false}
              acceptedFiles={{
                "image/*": [".gif", ".png", ".jpg", ".jpeg"],
              }}
              onDrop={(e: any) => onChangeImage(e, "thumbnail")}
            >
              Drop your image here, or Browse
            </CustomDropZone>
          </Grid>
          <Grid item xs={12} md={4} alignItems="center" display="flex">
            {thumbnailImagePreview && (
              <Grid
                container
                onClick={() => previewImage(thumbnailImagePreview)}
                justifyContent="center"
                alignItems="center"
                textAlign="center"
              >
                <Grid item className="imageWithDeleteIcon">
                  <img
                    width="90%"
                    style={{ maxHeight: "100px" }}
                    src={thumbnailImagePreview}
                    alt="original"
                  />
                  <MDIconButton
                    tooltipName="Delete"
                    aria-label="Delete"
                    className="removeBtnClass"
                    onClick={(e: any) => onImageRemove(e, "thumbnail")}
                  >
                    <Icon fontSize="small">close</Icon>
                  </MDIconButton>
                </Grid>
              </Grid>
            )}
          </Grid>
        </Grid>
        {errors && errors.countries && <CustomErrorMessage message={errors.countries} />}
        <ImagePreview
          imageData={imageToPreview}
          open={isPreview}
          onCloseImage={() => {
            closeImagePreview();
          }}
        />
      </MDBox>
    </MDDialog>
  );
}

export default AddUpdate;
