import React, { useState, useEffect } from "react";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import General from "layouts/pages/sola-pros/account-setup-wizard/steps/general";
import Website from "layouts/pages/sola-pros/account-setup-wizard/steps/website";
import BeautyHive from "layouts/pages/sola-pros/account-setup-wizard/steps/beautyHive";
import Preferences from "layouts/pages/sola-pros/account-setup-wizard/steps/preferences";
import Review from "layouts/pages/sola-pros/account-setup-wizard/steps/review";
import Scheduling from "layouts/pages/sola-pros/account-setup-wizard/steps/scheduling";
import Validation from "helper/validations";
import { toBase64 } from "helper/services";
import { useAppDispatch, useAppSelector } from "store/store";
import { getAccountSetUp, updateAccountSetUp } from "store/thunk/leaseThunk";
import { updatePreferences } from "store/thunk/authThunk";
import { useNavigate } from "react-router-dom";

const steps = ["General", "Website", "Scheduling", "Beauty Hive", "Preferences", "Review"];

function AccountSetupWizard(): JSX.Element {
  const appDispatch = useAppDispatch();
  const navigate = useNavigate();
  const { accountSetUpData } = useAppSelector((state) => state.leaseSlice);
  const { userData } = useAppSelector((state) => state.authReducer);
  const [filedChanged, setFiledChanged] = useState<boolean>(false);
  const [accountDetail, setAccountDetail] = useState<any>({
    same_as_personal: true,
  });
  const [errors, setErrors] = useState<any>({});
  const [activeStep, setActiveStep] = useState<number>(0);
  const isLastStep: boolean = activeStep === steps.length - 1;
  const serviceList = [
    { id: 1, name: "Service-1" },
    { id: 2, name: "Service-2" },
    { id: 3, name: "Service-3" },
    { id: 4, name: "Service-4" },
    { id: 5, name: "Service-5" },
  ];

  useEffect(() => {
    if (accountSetUpData) {
      setAccountDetail(accountSetUpData);
    }
  }, [accountSetUpData]);

  useEffect(() => {
    if (userData && userData?.user_id) {
      appDispatch(getAccountSetUp({ user_id: userData?.user_id }));
    }
  }, [userData]);

  const onChangeField = (event: any) => {
    setAccountDetail({ ...accountDetail, [event.target.name]: event.target.value });
    setFiledChanged(true);
  };

  const onChangeRadio = (event: any) => {
    setAccountDetail({ ...accountDetail, [event.target.name]: event.target.checked });
    setFiledChanged(true);
  };

  const onChangeCheckBoxes = (event: any) => {
    const tempDetails = JSON.parse(JSON.stringify(accountDetail));
    const services = tempDetails?.services || [];
    if (event.target.checked) {
      if (!services.includes(event.target.id)) {
        services.push(event.target.id);
      }
    } else {
      const index = services.indexOf(event.target.id);
      if (index > -1) {
        services.splice(index, 1);
      }
    }
    Object.assign(tempDetails, services);
    setAccountDetail(tempDetails);
    setFiledChanged(true);
  };

  const handleChangeNext = () => {
    setActiveStep(activeStep + 1);
    setFiledChanged(false);
  };

  const handleNext = () => {
    const errors = Validation.validateAccountWizard({ ...accountDetail, step: activeStep });
    setErrors(errors);
    if (!Object.keys(errors).length) {
      if (filedChanged) {
        const request = {
          callback: !isLastStep ? handleChangeNext : false,
          setUpData: { user_id: userData?.user_id, setUpData: accountDetail },
        };
        appDispatch(updateAccountSetUp(request));
      } else {
        handleChangeNext();
      }
    }
  };
  const handleBack = () => setActiveStep(activeStep - 1);

  const handleSingleCheckbox = (event: React.ChangeEvent<HTMLInputElement>) => {
    setAccountDetail({ ...accountDetail, [event.target.name]: event.target.checked });
    setFiledChanged(true);
  };

  const onImagesChange = async (files: any) => {
    if (files.length) {
      let imagesArray: any = [];
      if (accountDetail?.images && accountDetail?.images.length > 0) {
        imagesArray = JSON.parse(JSON.stringify(accountDetail?.images));
      }

      await Promise.all(
        files.map(async (file: any) => {
          const base64String: any = await toBase64(file);
          if (base64String) {
            imagesArray.push(base64String);
          }
        })
      );
      setAccountDetail({ ...accountDetail, images: imagesArray });
      setFiledChanged(true);
    }
  };

  const onImageRemove = async (e: any, index: number) => {
    e.stopPropagation();
    let imagesArray: any = [];
    if (accountDetail?.images && accountDetail?.images.length > 0) {
      imagesArray = JSON.parse(JSON.stringify(accountDetail?.images));
    }
    if (imagesArray.length > 0) {
      imagesArray.splice(index, 1);
      setAccountDetail({ ...accountDetail, images: imagesArray });
    }
  };

  const addNewElement = async (type: string) => {
    if (type === "testimonials") {
      let testimonials: any = [];
      if (accountDetail?.testimonials && accountDetail?.testimonials.length > 0) {
        testimonials = JSON.parse(JSON.stringify(accountDetail?.testimonials));
      }
      testimonials.push({
        name: "",
        region: "",
        text: "",
      });
      setAccountDetail({ ...accountDetail, testimonials });
      setFiledChanged(true);
    }
  };

  const onChangeMultipleField = (event: any, index: any, type: any) => {
    if (type === "testimonials") {
      const data = accountDetail?.testimonials;
      data[index][event.target.name] = event.target.value;
      setAccountDetail({ ...accountDetail, testimonials: data });
      setFiledChanged(true);
    }
  };

  const removeElement = (type: any, index: any) => {
    if (type === "testimonials") {
      const testimonials = accountDetail?.testimonials.filter(
        (_element: any, i: any) => i !== index
      );
      setAccountDetail({ ...accountDetail, testimonials });
      setFiledChanged(true);
    }
  };

  const updateLeasePreference = () => {
    appDispatch(
      updatePreferences({
        user_id: userData?.user_id,
        preferences: [{ preference_type: "ACCOUNT_SETUP_WIZARD", preference_value: "completed" }],
        lease_wizard: navigate,
      })
    );
  };

  function getStepContent(stepIndex: number): JSX.Element {
    const attr = {
      accountDetail,
      onChangeField,
      onChangeRadio,
      onChangeCheckBoxes,
      handleSingleCheckbox,
      onImagesChange,
      addNewElement,
      onChangeMultipleField,
      removeElement,
      errors,
      serviceList,
      onImageRemove,
    };
    switch (stepIndex) {
      case 0:
        return <General {...attr} />;
      case 1:
        return <Website {...attr} />;
      case 2:
        return <Scheduling />;
      case 3:
        return <BeautyHive />;
      case 4:
        return <Preferences {...attr} />;
      case 5:
        return <Review {...attr} />;
      default:
        return null;
    }
  }
  return (
    <MDBox pt={2}>
      <Grid sx={{ my: 4 }}>
        <Grid item xs={12} lg={12}>
          <Card>
            <MDBox mt={-3} mx={2}>
              <Stepper activeStep={activeStep} alternativeLabel className="acount-setup-stepper">
                {steps.map((label) => (
                  <Step key={label}>
                    <StepLabel style={{ fontSize: "10px" }}>{label}</StepLabel>
                  </Step>
                ))}
              </Stepper>
            </MDBox>
            <MDBox p={2}>
              <MDBox>
                {getStepContent(activeStep)}
                <MDBox mt={3} width="100%" display="flex" justifyContent="space-between">
                  {activeStep === 0 ? (
                    <MDBox />
                  ) : (
                    <MDButton variant="outlined" color="dark" onClick={handleBack}>
                      back
                    </MDButton>
                  )}
                  <MDButton
                    variant="gradient"
                    color="dark"
                    onClick={!isLastStep ? handleNext : updateLeasePreference}
                  >
                    {isLastStep ? "send" : "next"}
                  </MDButton>
                </MDBox>
              </MDBox>
            </MDBox>
          </Card>
        </Grid>
      </Grid>
    </MDBox>
  );
}

export default AccountSetupWizard;
