import { useEffect, useState } from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Grid,
  Icon,
  Typography,
} from "@mui/material";
import MDBox from "components/MDBox";
import DataTable from "examples/Tables/DataTable";
import { searchRentManagerTenants, onApproveSolaProSupportRequest } from "store/thunk/systemAdmin";
import { setRmSearchResultStylist } from "store/slices/systemAdminSlice";
import { useAppDispatch, useAppSelector } from "store/store";
import MDLabelValue from "components/MDLabelValue";
import { getLocalDateTime, phoneNumberformat } from "helper/services";
import MDDialog from "components/MDDialog";
import MDIconButton from "components/MDIconButton";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";

function SearchUnMatched(props: any): JSX.Element {
  const { detailViewData, onClose } = props;
  const dispatch = useAppDispatch();
  const { rmSearchResult } = useAppSelector((state) => state.systemAdminSlice);
  const { loading } = useAppSelector((state) => state.authReducer);
  const [searchValue, setSearchValue] = useState("");
  const { userData } = useAppSelector((state: any) => state.authReducer);
  const [listingData, setListingData] = useState([]);
  const [showConfirmPopup, setShowConfirmPopup] = useState(false);
  const [confirmData, setConfirmData] = useState({});
  const [error, setError] = useState(false);

  const onCloseConfirmPopup = () => {
    setShowConfirmPopup(false);
    setConfirmData({});
  };

  const confirmMatch = () => {
    dispatch(onApproveSolaProSupportRequest(confirmData));
    onCloseConfirmPopup();
  };

  const deleteDialogProps = {
    open: showConfirmPopup,
    onClose: () => onCloseConfirmPopup(),
    onSave: () => confirmMatch(),
    title: "Are you sure you want to confirm RM match ?",
    size: "sm",
    saveBtn: true,
    saveTbtText: "Yes",
    closeBtnText: "No",
  };
  const sureToconfirm = (object: any) => {
    setShowConfirmPopup(true);
    const req = {
      stylist_id: detailViewData?.id,
      rm_location_id: object?.location_id,
      tenant_id: object?.tenant_id,
      match_done_by_id: userData?.user_id,
      match_done_by_type: userData?.role,
      cms_location_id: detailViewData?.location_id,
      rm_property_id: object?.property_id,
      approved_by: userData?.user_id,
    };
    setConfirmData(req);
  };

  const actionButtons = (object: any) => (
    <MDIconButton
      tooltipName="Confirm Rm Match"
      aria-label="Info"
      color="info"
      onClick={() => sureToconfirm(object)}
    >
      <Icon fontSize="small">launch</Icon>
    </MDIconButton>
  );

  useEffect(() => {
    if (rmSearchResult && rmSearchResult?.length > 0) {
      const allList: any = [];
      rmSearchResult.map((obj: any) => {
        allList.push({
          id: obj.id || "-",
          name: obj.name || "-",
          first_name: obj.first_name || "-",
          last_name: obj.last_name || "-",
          email: obj.email || "-",
          phone: obj.phone ? phoneNumberformat(obj.phone) : "-",
          tenant_id: obj.tenant_id || "-",
          location_id: obj.location_id || "-",
          property_id: obj.property_id || "-",
          status: obj.status || "-",
          active_start_date: obj.active_start_date ? getLocalDateTime(obj.active_start_date) : "-",
          active_end_date: obj.active_end_date ? getLocalDateTime(obj.active_end_date) : "-",
          last_payment_date: obj.last_payment_date ? getLocalDateTime(obj.last_payment_date) : "-",
          last_transaction_date: obj.last_transaction_date
            ? getLocalDateTime(obj.last_transaction_date)
            : "-",
          updated_at: obj.updated_at ? getLocalDateTime(obj.updated_at) : "-",
          created_at: obj.created_at ? getLocalDateTime(obj.created_at) : "-",
          action: actionButtons(obj),
        });
        return true;
      });
      setListingData(allList);
    }
  }, [rmSearchResult]);

  const tableData = {
    columns: [
      { Header: "ID", accessor: "id" },
      { Header: "Name", accessor: "name" },
      { Header: "First Name", accessor: "first_name" },
      { Header: "Last Name", accessor: "last_name" },
      { Header: "Email address", accessor: "email" },
      { Header: "Phone Number", accessor: "phone" },
      { Header: "Tenant Id", accessor: "tenant_id" },
      { Header: "Location Id", accessor: "location_id" },
      { Header: "Property Id", accessor: "property_id" },
      { Header: "Status", accessor: "status" },
      { Header: "Active Start Date", accessor: "active_start_date" },
      { Header: "Active End Date", accessor: "active_end_date" },
      { Header: "Last Payment Date", accessor: "last_payment_date" },
      { Header: "Last Transaction Date", accessor: "last_transaction_date" },
      { Header: "Updated At", accessor: "updated_at" },
      { Header: "Created At", accessor: "created_at" },
      { Header: "Action", accessor: "action", width: "50px", export: false, disableSortBy: true },
    ],
    rows: listingData,
  };
  const onChangeField = (e: any) => {
    setSearchValue(e.target.value);
    setError(false);
  };
  const resetSearch = () => {
    setSearchValue("");
    dispatch(setRmSearchResultStylist([]));
  };
  const searchData = (e: any) => {
    e.preventDefault();
    if (!searchValue) {
      setError(true);
    } else {
      const request = {
        search_text: searchValue,
      };
      dispatch(searchRentManagerTenants(request));
    }
  };
  const dialogProps = {
    open: detailViewData ? true : false,
    onClose,
    saveBtn: false,
    title: "Search matching rent manager for stylist",
    size: "xl",
  };
  return (
    <MDDialog {...dialogProps}>
      <MDBox mt={3} padding={5}>
        <Grid container spacing={2}>
          <Grid item md={6}>
            <MDLabelValue label="ID" value={detailViewData?.id || "-"} />
          </Grid>
          <Grid item md={6}>
            <MDLabelValue label="Name" value={detailViewData?.name || "-"} />
          </Grid>
          <Grid item md={6}>
            <MDLabelValue label="Email Address" value={detailViewData?.email_address || "-"} />
          </Grid>
          <Grid item md={6}>
            <MDLabelValue
              label="Phone Number"
              value={phoneNumberformat(detailViewData?.phone_number) || "-"}
            />
          </Grid>
          <Grid item md={6}>
            <MDLabelValue label="Business Name" value={detailViewData?.business_name || "-"} />
          </Grid>
          <Grid item md={6}>
            <MDLabelValue label="Status" value={detailViewData?.status || "-"} />
          </Grid>
          <Grid item md={6}>
            <MDLabelValue label="Url Name" value={detailViewData?.url_name || "-"} />
          </Grid>
          <Grid item md={6}>
            <MDLabelValue label="Website Url" value={detailViewData?.website_url || "-"} />
          </Grid>
          <Grid item md={6}>
            <MDLabelValue label="Booking Url" value={detailViewData?.booking_url || "-"} />
          </Grid>
          <Grid item md={6}>
            <MDLabelValue label="Location Name" value={detailViewData?.location_name || "-"} />
          </Grid>
        </Grid>
        <Accordion expanded>
          <AccordionSummary style={{ background: "#dbdbdb", height: "50px" }}>
            <Typography>Rent Manager</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Grid container spacing={2}>
              <Grid item md={6}>
                <MDLabelValue
                  label="Rent Manager Id"
                  value={detailViewData?.rent_manager_id || "-"}
                />
              </Grid>
              <Grid item md={6}>
                <MDLabelValue
                  label="Rent Manager Status"
                  value={detailViewData?.rm_status || "-"}
                />
              </Grid>
            </Grid>
          </AccordionDetails>
        </Accordion>
        <Accordion expanded>
          <AccordionSummary style={{ background: "#dbdbdb", height: "50px" }}>
            <Typography>Billing Detail</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Grid container spacing={2}>
              <Grid item md={6}>
                <MDLabelValue
                  label="Billing First Name"
                  value={detailViewData?.billing_first_name || "-"}
                />
              </Grid>
              <Grid item md={6}>
                <MDLabelValue
                  label="Billing Last Name"
                  value={detailViewData?.billing_last_name || "-"}
                />
              </Grid>
              <Grid item md={6}>
                <MDLabelValue label="Billing Phone" value={detailViewData?.billing_phone || "-"} />
              </Grid>
              <Grid item md={6}>
                <MDLabelValue label="Billing Email" value={detailViewData?.billing_email || "-"} />
              </Grid>
            </Grid>
          </AccordionDetails>
        </Accordion>
      </MDBox>
      <MDBox component="form" role="form" onSubmit={(e) => searchData(e)} mt={3} mb={1} padding={5}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12} md={12}>
            <Typography>Rent Manager</Typography>
          </Grid>
          <Grid item xs={12} sm={6} md={6}>
            <MDInput
              label="Search"
              variant="standard"
              required
              fullWidth
              onChange={onChangeField}
              name="title"
              placeholder="Enter search text"
              InputLabelProps={{ shrink: true }}
              value={searchValue}
              error={error}
              helperText={error ? "This is field is required" : ""}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={6}>
            <MDButton
              variant="gradient"
              color="info"
              size="small"
              sx={{ marginTop: "5px" }}
              type="submit"
              disabled={loading}
            >
              Search{" "}
              <Icon sx={{ fontWeight: "bold", marginLeft: "4px", fontSize: "40px" }}>search</Icon>
            </MDButton>
            {"     "}
            <MDButton
              variant="gradient"
              color="dark"
              size="small"
              sx={{ marginTop: "5px" }}
              onClick={() => resetSearch()}
            >
              Reset
            </MDButton>
          </Grid>
        </Grid>
      </MDBox>
      <DataTable
        showExportSelectedBtn={false}
        showExportAllBtn={false}
        table={tableData}
        canSearch
        searchText="Search..."
      />
      {showConfirmPopup && (
        <MDDialog {...deleteDialogProps}>
          <MDTypography variant="h6" fontWeight="medium">
            Once you confirm the stylist with matching rent manager you will not be able to revert
            this.
          </MDTypography>
        </MDDialog>
      )}
    </MDDialog>
  );
}

export default SearchUnMatched;
