/* eslint-disable no-unused-vars */
import { Dialog, DialogTitle, Grid, Icon, Typography } from "@mui/material";
import config from "config/config";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import Messages from "helper/messages";
import MDIconButton from "components/MDIconButton";
import axios from "axios";
import { notificationFail, notificationSuccess } from "store/slices/notificationSlice";
import { useAppDispatch } from "store/store";

export interface SimpleDialogProps {
  selectedItem: {
    [key: string]: string | number;
  };
  setSelectedItem: (value: { [key: string]: string | number }) => void;
  onClose: (value: string) => void;
  fetchApi: boolean;
  setFetchApi: (value: boolean) => void;
}

function DeleteDialog(props: SimpleDialogProps) {
  const { onClose, selectedItem, setSelectedItem, fetchApi, setFetchApi } = props;
  const dispatch = useAppDispatch();

  const handleClose = () => {
    onClose("");
  };

  const confirmDelete = () => {
    axios
      .delete(`${config.REPORT_URL}/report/deleteMasterData/${selectedItem.id}`, {
        headers: {
          "x-api-key": config.REPORT_KEY,
        },
      })
      .then((response) => {
        dispatch(
          notificationSuccess(response.data.message || Messages.SUCCESS.MASTER_DATA_DELETED)
        );
        setFetchApi(!fetchApi);
      })
      .catch((err) => {
        dispatch(
          notificationFail(err?.response?.data?.message || Messages.ERROR.SOMETHING_WENT_WRONG)
        );
      });
    setSelectedItem({});
    onClose("");
  };

  return (
    <Dialog onClose={handleClose} open>
      <Grid container>
        <Grid item md={12} display="flex" justifyContent="space-between" alignItems="center">
          <DialogTitle>Confirm Delete</DialogTitle>
          <MDBox mr={2}>
            <MDIconButton
              tooltipName="Close"
              aria-label="Close"
              color="error"
              onClick={handleClose}
            >
              <Icon fontSize="small">close</Icon>
            </MDIconButton>
          </MDBox>
        </Grid>
        <Grid item md={12} sx={{ paddingLeft: "30px" }}>
          <Typography variant="subtitle2">Are you sure you want to delete this record?</Typography>
        </Grid>
        <Grid item md={12} display="flex" justifyContent="end" sx={{ padding: "10px" }}>
          <MDButton
            variant="contained"
            color="primary"
            sx={{ marginRight: "10px" }}
            onClick={handleClose}
          >
            Cancel
          </MDButton>
          <MDButton variant="contained" color="success" onClick={confirmDelete}>
            Confirm
          </MDButton>
        </Grid>
      </Grid>
    </Dialog>
  );
}

export default DeleteDialog;
