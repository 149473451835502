import MDDialog from "components/MDDialog";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
// import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Accordion, AccordionDetails, AccordionSummary, Grid } from "@mui/material";
import CustomChipList from "components/CustomChipList";
import { displayMultipleValue, getLocalDateTime } from "helper/services";

function ViewDetails(props: any): JSX.Element {
  const { open, onClose, updateData } = props;

  const dialogProps = {
    open,
    onClose,
    title: "View Events And Classess",
    size: "md",
    saveBtn: false,
  };

  return (
    <MDDialog {...dialogProps}>
      <MDBox pb={2}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12} md={12}>
            <Accordion expanded>
              <AccordionSummary
                className="location-accordion"
                // expandIcon={<ExpandMoreIcon />}
                aria-controls="publishbh-content"
              >
                <MDTypography variant="h6">Basic info</MDTypography>
              </AccordionSummary>
              <AccordionDetails>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6} md={6}>
                    <MDTypography fontWeight="bold" variant="button">
                      Title
                    </MDTypography>
                    <MDBox>
                      <MDTypography variant="button">{updateData.title || "-"}</MDTypography>
                    </MDBox>
                  </Grid>
                  <Grid item xs={12} sm={6} md={6}>
                    <MDTypography fontWeight="bold" variant="button">
                      Description
                    </MDTypography>
                    <MDBox>
                      <MDTypography variant="button">{updateData.description || "-"}</MDTypography>
                    </MDBox>
                  </Grid>
                  <Grid item xs={12} sm={6} md={6}>
                    <MDTypography fontWeight="bold" variant="button">
                      Class Image
                    </MDTypography>
                    <MDBox>
                      <MDTypography variant="button">
                        {updateData.class_image_name || "-"}
                      </MDTypography>
                    </MDBox>
                  </Grid>
                  <Grid item xs={12} sm={6} md={6}>
                    <MDTypography fontWeight="bold" variant="button">
                      Category
                    </MDTypography>
                    <MDBox>
                      <MDTypography variant="button">
                        {updateData?.category?.name || "-"}
                      </MDTypography>
                    </MDBox>
                  </Grid>
                  <Grid item xs={12} sm={6} md={6}>
                    <MDBox>
                      <MDTypography variant="button">
                        <CustomChipList name="Tags" listData={updateData?.tag} />
                      </MDTypography>
                    </MDBox>
                  </Grid>
                  <Grid item xs={12} sm={6} md={6}>
                    <MDTypography fontWeight="bold" variant="button">
                      Cost
                    </MDTypography>
                    <MDBox>
                      <MDTypography variant="button">{updateData?.cost || "-"}</MDTypography>
                    </MDBox>
                  </Grid>
                  <Grid item xs={12} sm={6} md={6}>
                    <MDTypography fontWeight="bold" variant="button">
                      Link Text
                    </MDTypography>
                    <MDBox>
                      <MDTypography variant="button">{updateData?.link_text || "-"}</MDTypography>
                    </MDBox>
                  </Grid>
                  <Grid item xs={12} sm={6} md={6}>
                    <MDTypography fontWeight="bold" variant="button">
                      Link Url
                    </MDTypography>
                    <MDBox>
                      <MDTypography variant="button">{updateData?.link_url || "-"}</MDTypography>
                    </MDBox>
                  </Grid>
                  <Grid item xs={12} sm={6} md={6}>
                    <MDTypography fontWeight="bold" variant="button">
                      File Text
                    </MDTypography>
                    <MDBox>
                      <MDTypography variant="button">{updateData?.file_text || "-"}</MDTypography>
                    </MDBox>
                  </Grid>
                  <Grid item xs={12} sm={6} md={6}>
                    <MDTypography fontWeight="bold" variant="button">
                      Video
                    </MDTypography>
                    <MDBox>
                      <MDTypography variant="button">
                        {updateData?.video?.title || "-"}
                      </MDTypography>
                    </MDBox>
                  </Grid>
                  <Grid item xs={12} sm={6} md={6}>
                    <MDTypography fontWeight="bold" variant="button">
                      Created At
                    </MDTypography>
                    <MDBox>
                      <MDTypography variant="button">
                        {getLocalDateTime(updateData.created_at)}
                      </MDTypography>
                    </MDBox>
                  </Grid>
                  <Grid item xs={12} sm={6} md={6}>
                    <MDTypography fontWeight="bold" variant="button">
                      Updated At
                    </MDTypography>
                    <MDBox>
                      <MDTypography variant="button">
                        {getLocalDateTime(updateData.updated_at)}
                      </MDTypography>
                    </MDBox>
                  </Grid>
                </Grid>
              </AccordionDetails>
            </Accordion>
          </Grid>
          <Grid item xs={12} sm={12} md={12}>
            <Accordion expanded>
              <AccordionSummary
                className="location-accordion"
                // expandIcon={<ExpandMoreIcon />}
                aria-controls="publishbh-content"
              >
                <MDTypography variant="h6">When</MDTypography>
              </AccordionSummary>
              <AccordionDetails>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6} md={6}>
                    <MDTypography fontWeight="bold" variant="button">
                      Start Date
                    </MDTypography>
                    <MDBox>
                      <MDTypography variant="button">
                        {updateData?.start_date
                          ? getLocalDateTime(updateData?.start_date, "MMMM D, YYYY")
                          : "-"}
                      </MDTypography>
                    </MDBox>
                  </Grid>
                  <Grid item xs={12} sm={6} md={6}>
                    <MDTypography fontWeight="bold" variant="button">
                      End Date
                    </MDTypography>
                    <MDBox>
                      <MDTypography variant="button">
                        {updateData?.end_date
                          ? getLocalDateTime(updateData?.end_date, "MMMM D, YYYY")
                          : "-"}
                      </MDTypography>
                    </MDBox>
                  </Grid>
                </Grid>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6} md={6}>
                    <MDTypography fontWeight="bold" variant="button">
                      Start Time
                    </MDTypography>
                    <MDBox>
                      <MDTypography variant="button">
                        {updateData?.start_time ? updateData?.start_time : "-"}
                      </MDTypography>
                    </MDBox>
                  </Grid>
                  <Grid item xs={12} sm={6} md={6}>
                    <MDTypography fontWeight="bold" variant="button">
                      End Time
                    </MDTypography>
                    <MDBox>
                      <MDTypography variant="button">
                        {updateData?.end_time ? updateData?.end_time : "-"}
                      </MDTypography>
                    </MDBox>
                  </Grid>
                </Grid>
              </AccordionDetails>
            </Accordion>
          </Grid>
          <Grid item xs={12} sm={12} md={12}>
            <Accordion expanded>
              <AccordionSummary
                className="location-accordion"
                // expandIcon={<ExpandMoreIcon />}
                aria-controls="publishbh-content"
              >
                <MDTypography variant="h6">Where</MDTypography>
              </AccordionSummary>
              <AccordionDetails>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6} md={6}>
                    <MDTypography fontWeight="bold" variant="button">
                      Region
                    </MDTypography>
                    <MDBox>
                      <MDTypography variant="button">
                        {updateData?.region?.name || "-"}
                      </MDTypography>
                    </MDBox>
                  </Grid>
                  <Grid item xs={12} sm={6} md={6}>
                    <MDTypography fontWeight="bold" variant="button">
                      Location
                    </MDTypography>
                    <MDBox>
                      <MDTypography variant="button">{updateData.location || "-"}</MDTypography>
                    </MDBox>
                  </Grid>
                  <Grid item xs={12} sm={6} md={6}>
                    <MDTypography fontWeight="bold" variant="button">
                      Address
                    </MDTypography>
                    <MDBox>
                      <MDTypography variant="button">{updateData.address || "-"}</MDTypography>
                    </MDBox>
                  </Grid>
                  <Grid item xs={12} sm={6} md={6}>
                    <MDTypography fontWeight="bold" variant="button">
                      City
                    </MDTypography>
                    <MDBox>
                      <MDTypography variant="button">{updateData.city || "-"}</MDTypography>
                    </MDBox>
                  </Grid>
                  <Grid item xs={12} sm={6} md={6}>
                    <MDTypography fontWeight="bold" variant="button">
                      State/Province
                    </MDTypography>
                    <MDBox>
                      <MDTypography variant="button">{updateData.state || "-"}</MDTypography>
                    </MDBox>
                  </Grid>
                </Grid>
              </AccordionDetails>
            </Accordion>
          </Grid>
          <Grid item xs={12} sm={12} md={12}>
            <Accordion expanded>
              <AccordionSummary
                className="location-accordion"
                // expandIcon={<ExpandMoreIcon />}
                aria-controls="publishbh-content"
              >
                <MDTypography variant="h6">Who</MDTypography>
              </AccordionSummary>
              <AccordionDetails>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6} md={6}>
                    <MDTypography fontWeight="bold" variant="button">
                      Brand
                    </MDTypography>
                    <MDBox>
                      <MDTypography variant="button">
                        {displayMultipleValue(updateData?.brands)}
                      </MDTypography>
                    </MDBox>
                  </Grid>
                </Grid>
              </AccordionDetails>
            </Accordion>
          </Grid>
          <Grid item xs={12} sm={12} md={12}>
            <Accordion expanded>
              <AccordionSummary
                className="location-accordion"
                // expandIcon={<ExpandMoreIcon />}
                aria-controls="publishbh-content"
              >
                <MDTypography variant="h6">Rsvp</MDTypography>
              </AccordionSummary>
              <AccordionDetails>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6} md={6}>
                    <MDTypography fontWeight="bold" variant="button">
                      Email Address
                    </MDTypography>
                    <MDBox>
                      <MDTypography variant="button">
                        {updateData?.rsvp_email_address || "-"}
                      </MDTypography>
                    </MDBox>
                  </Grid>
                  <Grid item xs={12} sm={6} md={6}>
                    <MDTypography fontWeight="bold" variant="button">
                      Phone number
                    </MDTypography>
                    <MDBox>
                      <MDTypography variant="button">
                        {updateData?.rsvp_phone_number || "-"}
                      </MDTypography>
                    </MDBox>
                  </Grid>
                </Grid>
              </AccordionDetails>
            </Accordion>
          </Grid>
        </Grid>
      </MDBox>
    </MDDialog>
  );
}

export default ViewDetails;
