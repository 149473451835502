import { useState, useEffect } from "react";
import { FormControl, InputLabel, Icon, Grid } from "@mui/material";
import apiClient from "config/apiClient";
import slsApiClient from "config/slsApiClient";
import Select from "react-select";
import CustomErrorMessage from "components/CustomErrorMessage";
// import { getAuthUser } from "helper/services";
import MDIconButton from "components/MDIconButton";
import debounce from "lodash/debounce";
import { useNavigate } from "react-router-dom";
import platformApiClient from "config/platformClient";
import { formattedPhone } from "helper/services";

function Index(props: any): JSX.Element | null {
  const {
    name,
    idKey,
    nameKey,
    value,
    onChange,
    parentKey,
    multiple,
    fieldLabel,
    apiName,
    responseKey,
    required,
    error,
    slsAPI,
    usersData,
    disabled,
    defaultOptions,
    createNewLink,
    placeholder,
    additionaFields,
    responseCallback,
    roleName,
    platformAPI,
    additionalLabel,
    additionalLabelDevider,
  } = props;
  const [searchText, setSearchText] = useState<any>("");
  const [page, setPage] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const [listData, setListData] = useState<any>([]);
  const navigate = useNavigate();

  useEffect(() => {
    if (defaultOptions) {
      const tempList = listData && listData.length > 0 ? JSON.parse(JSON.stringify(listData)) : [];
      const checkExists = tempList.filter((v: any) => v.value === value[idKey]);
      if (checkExists.length === 0) {
        tempList.push({ value: value[idKey], label: value[nameKey] });
      }
      setListData(tempList);
    }
  }, [defaultOptions]);

  useEffect(() => {
    if (value) {
      const tempList = listData && listData.length > 0 ? JSON.parse(JSON.stringify(listData)) : [];
      if (multiple) {
        for (let v = 0; v < value.length; v++) {
          const existing = tempList.find((obj: any) => obj.value === value[v][idKey]);
          if (existing) {
            tempList.push(existing);
          } else {
            tempList.push({ value: value[v][idKey], label: value[v][nameKey] });
          }
        }
      } else {
        const checkExists = tempList.filter((v: any) => v.value === value[idKey]);
        if (checkExists.length === 0) {
          tempList.push({ value: value[idKey], label: value[nameKey] });
        }
      }
      setListData(tempList);
    }
  }, [value]);

  const getSelectedItems = (value: any) => {
    if (listData && value && (value.length || Object.keys(value).length)) {
      if (multiple) {
        const values: any = [];
        for (let v = 0; v < value.length; v++) {
          const element = listData.find((obj: any) => obj.value === value[v][idKey]);
          if (element) {
            values.push(element);
          } else {
            values.push({ value: value[v][idKey], label: value[v][nameKey] });
          }
        }
        return values;
      }
      const element = listData.find((obj: any) => obj.value === value[idKey]);

      return element;
    }
    return null;
  };

  const getData = async (page?: any, searchText?: any) => {
    setPage(page);
    setIsLoading(true);
    let url = `${apiName}?page=${page || (slsAPI || platformAPI ? 0 : 1)}&per_page=20`;
    if (searchText) {
      url += `&search=${searchText}`;
    }
    // If any additional Fields come then it'll execute
    if (additionaFields && Object.entries(additionaFields)?.length) {
      Object.entries(additionaFields).map((field: any) => {
        if (field?.[0] && field?.[1]) {
          url += `&${field?.[0]}=${field?.[1]}`;
        }
        return true;
      });
    }
    if (apiName === "connect_users") {
      // const user = getAuthUser();
      if (roleName) {
        url += `&role_name=${roleName}`;
      } else {
        url += `&role_name=Admin`;
      }
    }

    const response = slsAPI
      ? await slsApiClient().get(url, {
          params: usersData ? usersData : null,
        })
      : platformAPI
      ? await platformApiClient().get(url, {
          params: usersData ? usersData : null,
        })
      : await apiClient().get(url, {
          params: usersData ? usersData : null,
        });
    if (response) {
      const respList = response.data[responseKey].map((val: any) => ({
        value: val[idKey],
        label:
          additionalLabel && val[additionalLabel]
            ? `${val[additionalLabel]} ${additionalLabelDevider || ""} ${
                nameKey === "twillio_phone_number" ? formattedPhone(val[nameKey]) : val[nameKey]
              }`
            : parentKey
            ? val[parentKey][nameKey]
            : val[nameKey],
      }));
      // For API response give blank array
      if (responseCallback) {
        responseCallback(respList);
      }
      let data: any = [];
      const defaultOpts = defaultOptions || [];
      data = [...defaultOpts, ...listData, ...respList];
      if (searchText) {
        data = [...respList];
      }
      const arrayUniqueByKey = [
        ...new Map(
          data.map((item: any) => [item.value, { value: item.value, label: item.label }])
        ).values(),
      ];
      setIsLoading(false);
      setListData(arrayUniqueByKey);
    }
  };

  const handleInputChange = debounce((search: string) => {
    if (!search) {
      setListData([]);
    }
    if (search !== searchText) {
      setSearchText(search);
      getData(slsAPI || platformAPI ? 0 : 1, search);
    }
  }, 500);

  const customFilter = () => true;

  const handleReactSelectScroll = () => {
    getData(page + 1, searchText || "");
  };

  const style = {
    control: (base: any) => ({
      ...base,
      height: "auto",
      border: "none",
      boxShadow: "0 !important",
      borderBottom: error ? "1px solid red" : "1px solid #d2d6da",
      width: "100%",
      borderRadius: "0px !important",
      textAlign: "left",
      fontSize: 14,
    }),
    menu: (provided: any) => ({
      ...provided,
      zIndex: 99,
      fontSize: 14,
    }),
    valueContainer: (valueContainer: any) => ({
      ...valueContainer,
      padding: "0 0 0 8px",
    }),
  };

  const redirectToCreateNew = () => {
    navigate(`${createNewLink}`);
  };

  return (
    <Grid container>
      <Grid item style={{ width: createNewLink ? "calc(100% - 36px)" : "100%" }}>
        <FormControl required={required} fullWidth sx={{ marginTop: "7px" }}>
          <InputLabel htmlFor="name" shrink sx={{ marginLeft: "-12px", marginTop: "-10px" }}>
            {fieldLabel || ""}
          </InputLabel>
          <Select
            menuPlacement="auto"
            placeholder={placeholder || "Select..."}
            isLoading={isLoading}
            isClearable={!multiple}
            filterOption={customFilter}
            onMenuOpen={() => getData(slsAPI || platformAPI ? 0 : 1, "")}
            onInputChange={handleInputChange}
            options={listData.filter((val: any) => val.label)}
            onMenuScrollToBottom={handleReactSelectScroll}
            isMulti={multiple || false}
            isDisabled={disabled || false}
            onChange={(value: any) => {
              let values = null;
              if (multiple) {
                values = value.map((val: any) => ({ [idKey]: val.value, [nameKey]: val.label }));
              } else {
                values =
                  value && Object.keys(value)?.length
                    ? { [idKey]: value.value, [nameKey]: value.label }
                    : null;
              }
              onChange(name, values);
            }}
            value={
              value && (value.length || Object.keys(value).length) ? getSelectedItems(value) : []
            }
            styles={style}
            blurInputOnSelect={false}
          />
          {error && <CustomErrorMessage message={error} />}
        </FormControl>
      </Grid>
      {createNewLink && (
        <Grid item>
          <MDIconButton
            tooltipName="Add new"
            aria-label="Add new"
            onClick={redirectToCreateNew}
            marginTop="9px"
            className="add-new-btn"
          >
            <Icon fontSize="small" color="info">
              add
            </Icon>
          </MDIconButton>
        </Grid>
      )}
    </Grid>
  );
}
export default Index;
