import Grid from "@mui/material/Grid";
import MDBox from "components/MDBox";
import MDInput from "components/MDInput";
import Tooltip from "@mui/material/Tooltip";
import MDButton from "components/MDButton";
import Icon from "@mui/material/Icon";
import InputAdornment from "@mui/material/InputAdornment";

function General(props: any): JSX.Element {
  const { locationFranchiseView, onChangeField, errors } = props;
  return (
    <MDBox>
      <MDBox p={2} mt={2}>
        <MDBox>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6} md={6}>
              <MDInput
                label="Franchise Owner First Name"
                variant="standard"
                fullWidth
                InputLabelProps={{ shrink: true }}
                name="first_name"
                required
                onChange={onChangeField}
                value={locationFranchiseView?.first_name || ""}
                error={errors && errors.first_name ? true : false}
                helperText={errors && errors.first_name}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
              <MDInput
                label="Franchise Owner Last Name"
                variant="standard"
                fullWidth
                InputLabelProps={{ shrink: true }}
                name="last_name"
                required
                onChange={onChangeField}
                value={locationFranchiseView?.last_name || ""}
                error={errors && errors.last_name ? true : false}
                helperText={errors && errors.last_name}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
              <MDInput
                label="What is the CMS login that this new location should be associated with?"
                variant="standard"
                fullWidth
                InputLabelProps={{ shrink: true }}
                name="cms_login"
                required
                onChange={onChangeField}
                value={locationFranchiseView?.cms_login || ""}
                error={errors && errors.cms_login ? true : false}
                helperText={errors && errors.cms_login}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <Tooltip
                        title="CMS is the backend of the Sola website where you manage your location and stylist information.
"
                        placement="bottom"
                        arrow
                      >
                        <MDButton
                          variant="outlined"
                          color="secondary"
                          size="small"
                          circular
                          iconOnly
                        >
                          <Icon>question_mark</Icon>
                        </MDButton>
                      </Tooltip>
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
              <MDInput
                label="What is your MSA?"
                variant="standard"
                fullWidth
                InputLabelProps={{ shrink: true }}
                name="location_msa"
                required
                onChange={onChangeField}
                value={locationFranchiseView?.location_msa || ""}
                error={errors && errors.location_msa ? true : false}
                helperText={errors && errors.location_msa}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <Tooltip
                        title="MSA stands for the Metropolitan Statistical Area. You can easily find this by googling your location's city. For example, Denver suburb locations like Arvada are in the Denver MSA. Usually, MSAs are the nearest, largest city.
                        "
                        placement="bottom"
                        arrow
                      >
                        <MDButton
                          variant="outlined"
                          color="secondary"
                          size="small"
                          circular
                          iconOnly
                        >
                          <Icon>question_mark</Icon>
                        </MDButton>
                      </Tooltip>
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={12}>
              <MDInput
                label="What is the name of the neighborhood or specific location that you think most people in your MSA will identify your location by? This will appear as the location name within your market page."
                variant="standard"
                fullWidth
                required
                InputLabelProps={{ shrink: true }}
                name="location_display_name"
                onChange={onChangeField}
                value={locationFranchiseView?.location_display_name || ""}
                error={errors && errors.location_display_name ? true : false}
                helperText={errors && errors.location_display_name}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={12}>
              <MDInput
                label="What is the name of the Instagram account you want connected with this location? If you don't have a Instagram account, what is the name you would like to use?"
                variant="standard"
                fullWidth
                required
                InputLabelProps={{ shrink: true }}
                name="location_instagram_name"
                onChange={onChangeField}
                value={locationFranchiseView?.location_instagram_name || ""}
                error={errors && errors.location_instagram_name ? true : false}
                helperText={errors && errors.location_instagram_name}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={12}>
              <MDInput
                label="What is the name of the Facebook account you want connected with this location? If you don't have a Facebook account, what is the name you would like to use?"
                variant="standard"
                fullWidth
                required
                InputLabelProps={{ shrink: true }}
                name="location_facebook_name"
                onChange={onChangeField}
                value={locationFranchiseView?.location_facebook_name || ""}
                error={errors && errors.location_facebook_name ? true : false}
                helperText={errors && errors.location_facebook_name}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
              <MDInput
                label="Do you have an existing HubSpot login?"
                variant="standard"
                fullWidth
                required
                InputLabelProps={{ shrink: true }}
                name="location_hubspot_login"
                onChange={onChangeField}
                value={locationFranchiseView?.location_hubspot_login || ""}
                error={errors && errors.location_hubspot_login ? true : false}
                helperText={errors && errors.location_hubspot_login}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
              <MDInput
                label="What email address do you want connected to your HubSpot sales pipeline?"
                variant="standard"
                fullWidth
                required
                InputLabelProps={{ shrink: true }}
                name="location_hubspot_sale_email"
                onChange={onChangeField}
                value={locationFranchiseView?.location_hubspot_sale_email || ""}
                error={errors && errors.location_hubspot_sale_email ? true : false}
                helperText={errors && errors.location_hubspot_sale_email}
              />
            </Grid>
          </Grid>
        </MDBox>
      </MDBox>
    </MDBox>
  );
}

export default General;
