import React, { useEffect, useState } from "react";
import MDDialog from "components/MDDialog";
import MDInput from "components/MDInput";
import { Autocomplete, InputLabel, Grid } from "@mui/material";
import FormField from "layouts/pages/account/components/FormField";
import { useAppDispatch } from "store/store";
import CustomPhoneInput from "components/CustomPhoneInput";
import { saveMaintenanceContacts } from "store/thunk/systemAdmin";
import Validations from "helper/validations";
import { getAllAssociationLocations, getPermission, adminSuperAdmin } from "helper/services";
import CustomAutoSearch from "components/CustomAutoSearch";

const contactPreferenceList = [
  { value: "sms", name: "sms" },
  { value: "phone", name: "phone" },
  { value: "email", name: "email" },
  { value: "url", name: "url" },
];

function AddUpdate(props: any): JSX.Element {
  const dispatch = useAppDispatch();
  const { open, onClose, updateData, pageNo, perPage, search, sortBy } = props;
  const [isUpdate, setIsUpdate] = useState<any>(false);
  const [maintenanceData, setMaintenanceData] = useState<any>({});
  const [errors, setErrors] = useState<any>({});

  const onSave = () => {
    const reqData = {
      ...maintenanceData,
      location_id: maintenanceData?.location?.id || "",
      contact_preference: maintenanceData?.contact_preference?.value || "",
      contact_order: maintenanceData?.contact_order || 1,
    };
    const error: any = Validations.validateMaintenanceContactForm(reqData);
    setErrors(error);
    if (!Object.keys(error).length) {
      delete reqData.location;
      const req = {
        maintenanceData: reqData,
        onClose,
        isUpdate,
        pageNo,
        perPage,
        search,
        sortBy,
      };
      dispatch(saveMaintenanceContacts(req));
    }
  };

  useEffect(() => {
    if (updateData) {
      setIsUpdate(true);
      setMaintenanceData((prev: any) => ({
        ...prev,
        id: updateData.id,
        location: updateData?.location ? updateData?.location : null,
        contact_type: updateData.contact_type,
        contact_order: updateData.contact_order,
        contact_first_name: updateData.contact_first_name,
        contact_last_name: updateData.contact_last_name,
        contact_phone_number: updateData.contact_phone_number,
        contact_email: updateData.contact_email,
        contact_admin: updateData.contact_admin,
        contact_preference: contactPreferenceList.find(
          (val: any) => updateData.contact_preference === val.value
        ),
        request_routing_url: updateData.request_routing_url,
      }));
    }
  }, [updateData]);

  const onChangeField = (e: any, field: string) => {
    setMaintenanceData({ ...maintenanceData, [field]: e.target.value });
  };

  const dialogProps = {
    open,
    onSave,
    onClose,
    title: isUpdate ? "Update Maintenance Contact" : "Add Maintenance Contact",
    size: "lg",
    saveTbtText: isUpdate ? "Update" : "Save",
  };

  const onChangeAutoComplete = (name: string, value: any) => {
    setMaintenanceData({ ...maintenanceData, [name]: value });
  };

  return (
    <MDDialog {...dialogProps}>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12} md={6} lg={6}>
          <CustomAutoSearch
            idKey="id"
            nameKey="name"
            fieldLabel="Location"
            apiName="locations"
            responseKey="locations"
            name="location"
            required
            value={maintenanceData?.location || null}
            onChange={onChangeAutoComplete}
            error={errors && errors.location}
            usersData={getAllAssociationLocations()}
            createNewLink={
              adminSuperAdmin() && getPermission("locations", "locations_location")
                ? "/locations"
                : ""
            }
          />
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6}>
          <MDInput
            label="Contact Type"
            variant="standard"
            fullWidth
            name="contact_type"
            placeholder=""
            onChange={(event: any) => onChangeField(event, "contact_type")}
            value={maintenanceData?.contact_type ? maintenanceData.contact_type : ""}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6}>
          <MDInput
            label="Contact Order"
            type="Number"
            variant="standard"
            fullWidth
            name="contact_order"
            placeholder="1"
            onChange={(event: any) => onChangeField(event, "contact_order")}
            value={maintenanceData?.contact_order ? maintenanceData.contact_order : ""}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6}>
          <MDInput
            label="Contact First Name"
            variant="standard"
            fullWidth
            name="contact_first_name"
            placeholder=""
            onChange={(event: any) => onChangeField(event, "contact_first_name")}
            value={maintenanceData?.contact_first_name ? maintenanceData.contact_first_name : ""}
            error={errors && errors.contact_first_name ? true : false}
            helperText={errors && errors.contact_first_name}
            required
          />
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6}>
          <MDInput
            label="Contact Last Name"
            variant="standard"
            fullWidth
            name="contact_last_name"
            placeholder=""
            onChange={(event: any) => onChangeField(event, "contact_last_name")}
            value={maintenanceData?.contact_last_name ? maintenanceData.contact_last_name : ""}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6}>
          <InputLabel sx={{ marginTop: "5px" }}>
            Contact Phone Number<span className="required_star">*</span>
          </InputLabel>
          <MDInput
            variant="standard"
            fullWidth
            sx={{ marginBottom: "5px" }}
            name="phone"
            placeholder="(000) 000-0000"
            id="phone-input"
            InputProps={{
              inputComponent: CustomPhoneInput as any,
            }}
            value={maintenanceData?.contact_phone_number || ""}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              onChangeField(e, "contact_phone_number")
            }
            error={(errors && errors.contact_phone_number) || false}
            helperText={errors && errors.contact_phone_number}
            required
          />
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6}>
          <MDInput
            label="Contact Email"
            variant="standard"
            fullWidth
            name="contact_email"
            placeholder=""
            onChange={(event: any) => onChangeField(event, "contact_email")}
            value={maintenanceData?.contact_email ? maintenanceData.contact_email : ""}
            error={errors && errors.contact_email ? true : false}
            helperText={errors && errors.contact_email}
            required
          />
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6}>
          <MDInput
            label="Contact Admin"
            variant="standard"
            fullWidth
            name="contact_admin"
            placeholder=""
            onChange={(event: any) => onChangeField(event, "contact_admin")}
            value={maintenanceData?.contact_admin ? maintenanceData.contact_admin : ""}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6}>
          <Autocomplete
            id="contact_preference"
            options={contactPreferenceList}
            getOptionLabel={(option) => option.name}
            value={maintenanceData.contact_preference || null}
            onChange={(event: any, newValue: any) => {
              setMaintenanceData({ ...maintenanceData, contact_preference: newValue });
            }}
            renderOption={(props: any, option: any) => <li {...props}>{option.name}</li>}
            renderInput={(params: any) => (
              <FormField
                {...params}
                label="Contact Preference"
                InputLabelProps={{ shrink: true }}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6}>
          <MDInput
            label="Request Routig Url"
            variant="standard"
            fullWidth
            name="request_routing_url"
            placeholder=""
            onChange={(event: any) => onChangeField(event, "request_routing_url")}
            value={maintenanceData?.request_routing_url ? maintenanceData.request_routing_url : ""}
          />
        </Grid>
      </Grid>
    </MDDialog>
  );
}

export default AddUpdate;
