import { forwardRef, RefCallback } from "react";
import { IMaskInput } from "react-imask";

interface CustomProps {
  onChange: any;
  name: string;
}

const Index = forwardRef<HTMLElement, CustomProps>((prop, ref) => {
  const { onChange, ...other } = prop;

  return (
    <IMaskInput
      {...other}
      mask="00/00"
      definitions={{
        "#": /[1-9]/,
      }}
      inputRef={ref as RefCallback<HTMLTextAreaElement | HTMLInputElement>}
      id="mm_yy"
      onChange={onChange}
      overwrite
    />
  );
});
export default Index;
