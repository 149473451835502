import MDDialog from "components/MDDialog";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  Icon,
} from "@mui/material";
import { useEffect, useState } from "react";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ImagePreview from "layouts/pages/ImagePreview";
import MDInput from "components/MDInput";
import MDEditor from "components/MDEditor";
import { isFranchisie, convertImageToWebp } from "helper/services";
import MDIconButton from "components/MDIconButton";
import CustomDropZone from "components/CustomDropZone";
import { useAppDispatch } from "store/store";
import { approveWebpageRequests } from "store/thunk/solaProsThunk";
import CustomErrorMessage from "components/CustomErrorMessage";
import constants from "helper/constants";

const modules = {
  toolbar: [
    [{ header: [1, 2, false] }],
    ["bold", "italic", "underline", "strike", "blockquote"],
    [{ list: "ordered" }, { list: "bullet" }, { indent: "-1" }, { indent: "+1" }],
    ["link", "image"],
    ["clean"],
  ],
};

const formats = [
  "header",
  "bold",
  "italic",
  "underline",
  "strike",
  "blockquote",
  "list",
  "bullet",
  "indent",
  "link",
  "image",
];

function AddUpdate(props: any): JSX.Element {
  const dispatch = useAppDispatch();
  const {
    open,
    onClose,
    updateData,
    setUpdateData,
    status,
    alreadyApproved,
    onReject,
    pageNo,
    perPage,
    search,
    sortBy,
  } = props;
  const [isPreview, setImagePreviewPopup] = useState(false);
  const [imageToPreview, setImageToPreview] = useState("");
  const [changedDataKeys, setChangedDataKeys] = useState([]);
  const [bannerError, setBannerError] = useState("");
  const [errors] = useState<any>({});

  useEffect(() => {
    if (
      updateData &&
      updateData?.changed_data &&
      Array.isArray(updateData?.changed_data) &&
      updateData?.changed_data?.length
    ) {
      setChangedDataKeys(updateData?.changed_data?.map((obj: any) => obj?.label));
    }
  }, [updateData]);

  const approveRequests = () => {
    dispatch(
      approveWebpageRequests({
        webpageRequest: updateData,
        pageNo,
        perPage,
        search,
        sortBy,
        callBack: onClose,
      })
    );
  };

  const dialogProps = {
    open,
    onClose,
    title: "Approve Update Webpage Request",
    size: "md",
    saveTbtText: "Approve",
    closeBtnText: "Reject",
    closeBtnColor: "error",
    closeBtn: !alreadyApproved,
    saveBtn: !alreadyApproved,
    onSave: approveRequests,
    onCloseFunc: () => onReject(updateData.id),
  };

  const previewImage = (image: any) => {
    setImagePreviewPopup(true);
    setImageToPreview(image);
  };

  const closeImagePreview = () => {
    setImagePreviewPopup(false);
    setImageToPreview("");
  };

  const onChangeField = (event: any) => {
    setUpdateData({ ...updateData, [event.target.name]: event.target.value });
  };

  const onCheckboxChange = (event: any) => {
    setUpdateData({ ...updateData, [event.target.name]: event.target.checked });
  };

  const onChangeDescription = (value: any, delta: any, source: any) => {
    if (source === "user") {
      setUpdateData({ ...updateData, description: value });
    }
  };

  const onChangeMultipleField = (event: any, key: any) => {
    const data = updateData[key] ? JSON.parse(JSON.stringify(updateData[key])) : {};
    data[event.target.name] = event.target.value;
    setUpdateData({ ...updateData, [key]: { ...data } });
  };

  const getImageElement = (name: any, imageData: any, key?: any) => {
    if (changedDataKeys.includes(key)) {
      return (
        imageData && (
          <Grid item xs={12} sm={6} md={6}>
            <MDTypography fontWeight="bold" variant="button">
              {name}
            </MDTypography>
            <Grid container mt={2}>
              <Grid item xs={12} sm={6} md={6}>
                {imageData && (
                  <Grid
                    container
                    onClick={() => previewImage(imageData)}
                    // justifyContent="center"
                    // alignItems="center"
                    // display="flex"
                    className="thumbnail-img"
                  >
                    <Grid item>
                      <img height={100} width={100} src={imageData} alt="blog" />
                    </Grid>
                  </Grid>
                )}
              </Grid>
            </Grid>
          </Grid>
        )
      );
    }
    return null;
  };

  const getDetailElement = (label: any, name: any) => {
    if (changedDataKeys.includes(name)) {
      return (
        updateData?.[name] && (
          <Grid item xs={12} sm={6} md={6}>
            <MDInput
              label={label}
              variant="standard"
              fullWidth
              name={name}
              placeholder={label}
              onChange={onChangeField}
              value={updateData?.[name] || ""}
              error={errors && errors?.[name] ? true : false}
              helperText={errors && errors?.[name]}
              disabled={!isFranchisie()}
            />
          </Grid>
        )
      );
    }
    return null;
  };

  const getDetailElementWithDangersoulyHTML = (label: any, name: any) => {
    if (changedDataKeys.includes(name)) {
      return (
        updateData?.[name] && (
          <Grid item xs={12} sm={12} md={12}>
            <MDTypography variant="button">{label}</MDTypography>
            <MDEditor
              value={updateData?.[name] ? updateData?.[name] : ""}
              onChange={(val: any, delta: any, source: any) =>
                onChangeDescription(val, delta, source)
              }
              modules={modules}
              formats={formats}
              readOnly={!isFranchisie()}
            />
          </Grid>
        )
      );
    }
    return null;
  };

  const getCheckBoxElement = (label: any, name: any) => {
    if (changedDataKeys.includes(name)) {
      return (
        updateData?.[name] && (
          <Grid item xs={12} sm={6} md={6}>
            <FormControl component="fieldset">
              <FormGroup aria-label="position">
                <FormControlLabel
                  value="end"
                  control={
                    <Checkbox
                      checked={updateData?.[name] || false}
                      name="walkins"
                      onChange={onCheckboxChange}
                      inputProps={{ "aria-label": "controlled" }}
                      disabled={!isFranchisie()}
                    />
                  }
                  label={label}
                  labelPlacement="end"
                />
              </FormGroup>
            </FormControl>
          </Grid>
        )
      );
    }
    return null;
  };

  const getAccordianSummary = (name: any) => (
    <AccordionSummary className="location-accordion">
      <MDTypography>{name}</MDTypography>
    </AccordionSummary>
  );

  const getTestimonialAccordian = (index: any) => {
    const key = `testimonial_${index}`;
    if (
      changedDataKeys?.includes(`testimonial_${index}_name`) ||
      changedDataKeys?.includes(`testimonial_${index}_region`) ||
      changedDataKeys?.includes(`testimonial_${index}_title`) ||
      changedDataKeys?.includes(`testimonial_${index}_text`)
    ) {
      return (
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id={`panel1a-header-${index}`}
          >
            <MDTypography>Testimonials {index}</MDTypography>
          </AccordionSummary>
          <AccordionDetails>
            <Grid container spacing={1}>
              {changedDataKeys.includes(`testimonial_${index}_name`) && (
                <Grid item xs={12} sm={6} md={6}>
                  <MDInput
                    label="Name"
                    variant="standard"
                    fullWidth
                    name="name"
                    placeholder="Name"
                    onChange={(e: any) => onChangeMultipleField(e, key)}
                    value={updateData?.[`testimonial_${index}`]?.name || ""}
                    error={errors && errors[`${key}_name`] ? true : false}
                    helperText={errors && errors[`${key}_name`]}
                    disabled={!isFranchisie()}
                  />
                </Grid>
              )}
              {changedDataKeys.includes(`testimonial_${index}_region`) && (
                <Grid item xs={12} sm={6} md={6}>
                  <MDInput
                    label="Region"
                    variant="standard"
                    fullWidth
                    name="region"
                    placeholder="Region"
                    onChange={(e: any) => onChangeMultipleField(e, key)}
                    value={updateData?.[`testimonial_${index}`]?.region || ""}
                    error={errors && errors[`${key}_region`] ? true : false}
                    helperText={errors && errors[`${key}_region`]}
                    disabled={!isFranchisie()}
                  />
                </Grid>
              )}
              {changedDataKeys.includes(`testimonial_${index}_title`) && (
                <Grid item xs={12} sm={6} md={6}>
                  <MDInput
                    label="Title"
                    variant="standard"
                    fullWidth
                    name="title"
                    placeholder="Title"
                    onChange={(e: any) => onChangeMultipleField(e, key)}
                    value={updateData?.[`testimonial_${index}`]?.title || ""}
                    error={errors && errors[`${key}_title`] ? true : false}
                    helperText={errors && errors[`${key}_title`]}
                    disabled={!isFranchisie()}
                  />
                </Grid>
              )}
              {changedDataKeys.includes(`testimonial_${index}_text`) && (
                <Grid item xs={12} sm={6} md={6}>
                  <MDInput
                    multiline
                    rows="2"
                    variant="standard"
                    label="Text"
                    fullWidth
                    name="text"
                    placeholder="Text"
                    onChange={(e: any) => onChangeMultipleField(e, key)}
                    value={updateData?.[`testimonial_${index}`]?.text || ""}
                    error={errors && errors[`${key}_text`] ? true : false}
                    helperText={errors && errors[`${key}_text`]}
                    disabled={!isFranchisie()}
                  />
                </Grid>
              )}
            </Grid>
          </AccordionDetails>
        </Accordion>
      );
    }
    return null;
  };

  const onChangeImage = async (files: any, key: any) => {
    if (files[0]) {
      const file = await convertImageToWebp(files[0], "web_page", dispatch);
      const reader = new FileReader();
      reader.onload = function (e) {
        setUpdateData({
          ...updateData,
          [`image_${key}_url`]: e.target.result,
          [`image_${key}`]: file,
        });
      };
      reader.readAsDataURL(file);
    }
  };

  const onImageRemove = async (e: any, key: any) => {
    e.stopPropagation();
    const deleteflag = `delete_image_${key}`;
    setUpdateData({
      ...updateData,
      [`image_${key}_url`]: null,
      [`image_${key}`]: null,
      [deleteflag]: 1,
    });
  };

  const displayItems = (i: any): JSX.Element => {
    const items: any = [];
    if (updateData?.[`image_${i}_url`]) {
      items.push(
        <Grid container spacing={2} key={i} mt={1}>
          <Grid item xs={12} sm={3} md={3} lg={3}>
            <MDTypography fontSize="medium">Image {i}</MDTypography>
          </Grid>
          <Grid item xs={12} sm={5} md={5} lg={5}>
            <CustomDropZone
              multipleUpload={false}
              acceptedFiles={{
                "image/*": [".gif", ".png", ".jpg", ".jpeg"],
              }}
              onDrop={(e: any) => onChangeImage(e, i)}
            >
              Drop your image here, or Browse
            </CustomDropZone>
          </Grid>
          <Grid item xs={12} sm={4} md={4} lg={4}>
            {updateData[`image_${i}_url`] && (
              <Grid
                container
                onClick={() => previewImage(updateData[`image_${i}_url`])}
                // justifyContent="center"
                // alignItems="center"
                textAlign="center"
              >
                <Grid item className="imageWithDeleteIcon">
                  <img
                    width="90%"
                    style={{ maxHeight: "100px" }}
                    src={updateData[`image_${i}_url`]}
                    alt="original"
                  />
                  <MDIconButton
                    tooltipName="Delete"
                    aria-label="Delete"
                    className="removeBtnClass"
                    onClick={(e: any) => onImageRemove(e, i)}
                  >
                    <Icon fontSize="small">close</Icon>
                  </MDIconButton>
                </Grid>
              </Grid>
            )}
          </Grid>
        </Grid>
      );
    }
    return items;
  };
  // SD-3077 banner display image
  const onBannerImageRemove = async (e: any) => {
    e.stopPropagation();
    setUpdateData({
      ...updateData,
      banner_image_url: null,
      delete_banner_image: 1,
      banner: null,
    });
  };

  const onChangeBanner = (files: any) => {
    const selectedImage = files && files[0];
    const reader = new FileReader();
    if (selectedImage) {
      const maxSize = constants?.maxSize; // 3MB
      if (selectedImage?.size > maxSize) {
        const msg = "Upload image till 3MB";
        setBannerError(msg);
      } else {
        setBannerError("");
        reader.onload = function (e) {
          setUpdateData({
            ...updateData,
            banner_image_url: e.target.result,
            banner: selectedImage,
          });
        };
        reader.readAsDataURL(selectedImage);
      }
    }
  };

  const displayBannerItems = (bannerUrl: any): JSX.Element => {
    const bannerItems: any = [];
    if (bannerUrl) {
      bannerItems.push(
        <Grid container spacing={2} mt={1}>
          <Grid item xs={12} sm={3} md={3} lg={3}>
            <MDTypography fontSize="medium">Banner Image</MDTypography>
          </Grid>
          <Grid xs={12} sm={5} md={5} lg={5}>
            <CustomDropZone
              multipleUpload={false}
              acceptedFiles={{
                "image/*": [".png", ".jpg", ".jpeg"],
              }}
              onDrop={onChangeBanner}
            >
              <MDTypography sx={{ fontWeight: "bold" }}>
                Drop your image here, or Browse
              </MDTypography>
              <MDTypography sx={{ color: "#9fa2a6", fontSize: "0.9rem" }}>
                Maximum allowed file size is 3 MB (Allowed Type(s): .png .jpg, .jpeg)
              </MDTypography>
            </CustomDropZone>
            {bannerError && bannerError && <CustomErrorMessage message={bannerError} />}
          </Grid>
          <Grid xs={12} sm={4} md={4} lg={4}>
            <Grid
              container
              onClick={() => previewImage(bannerUrl)}
              justifyContent="center"
              alignItems="center"
              textAlign="center"
            >
              <Grid item className="imageWithDeleteIcon">
                <img width="90%" style={{ maxHeight: "100px" }} src={bannerUrl} alt="original" />
                <MDIconButton
                  tooltipName="Delete"
                  aria-label="Delete"
                  className="removeBtnClass"
                  onClick={(e: any) => onBannerImageRemove(e)}
                >
                  <Icon fontSize="small">close</Icon>
                </MDIconButton>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      );
    }
    return bannerItems;
  };

  return (
    <MDDialog {...dialogProps}>
      {!alreadyApproved ? (
        <MDBox pb={2}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={12}>
              <MDBox mt={1}>
                <MDBox>
                  <Accordion expanded>
                    {getAccordianSummary("Website")}
                    <AccordionDetails>
                      <Grid container spacing={2}>
                        {getDetailElement("Website Email Address", "website_email")}
                        {getDetailElement("Website Phone Number", "website_phone")}
                        {getDetailElement("Subtitle", "subtitle")}
                        {getDetailElementWithDangersoulyHTML("Description", "description")}
                        {getDetailElement("Url Name", "url_name")}
                      </Grid>
                    </AccordionDetails>
                  </Accordion>
                </MDBox>
              </MDBox>
              <MDBox mt={1}>
                <MDBox>
                  <Accordion expanded>
                    {getAccordianSummary("Integrations")}
                    <AccordionDetails>
                      <Grid container spacing={2}>
                        {getDetailElement("Website Url", "website_url")}
                        {getDetailElement("Booking Url", "booking_url")}
                      </Grid>
                    </AccordionDetails>
                  </Accordion>
                </MDBox>
              </MDBox>
              <MDBox mt={1}>
                <MDBox>
                  <Accordion expanded>
                    {getAccordianSummary("Business")}
                    <AccordionDetails>
                      <Grid container spacing={2}>
                        {getDetailElement("Salon Name", "business_name")}
                        {getDetailElement("Studio Number", "studio_number")}
                        {getCheckBoxElement("Visible On Site", "reserved")}
                        {getCheckBoxElement("Accepting New Clients", "accepting_new_clients")}
                        {getCheckBoxElement("Walkins", "walkins")}
                        {getCheckBoxElement("Send A Message Button", "send_a_message_button")}
                      </Grid>
                    </AccordionDetails>
                  </Accordion>
                </MDBox>
              </MDBox>
              <MDBox mt={1}>
                <MDBox>
                  <Accordion expanded>
                    {getAccordianSummary("Social")}
                    <AccordionDetails>
                      <Grid container spacing={2}>
                        {getDetailElement("Facebook Url", "facebook_url")}
                        {getDetailElement("Google Plus Url", "google_plus_url")}
                        {getDetailElement("Instagram Url", "instagram_url")}
                        {getDetailElement("Linkedin Url", "linkedin_url")}
                        {getDetailElement("Pinterest Url", "pinterest_url")}
                        {getDetailElement("Twitter Url", "twitter_url")}
                        {getDetailElement("Yelp Url", "yelp_url")}
                        {getDetailElement("Tik Tok Url", "tik_tok_url")}
                      </Grid>
                    </AccordionDetails>
                  </Accordion>
                </MDBox>
              </MDBox>
            </Grid>
            <Grid item xs={12} sm={12} md={12}>
              <Accordion expanded>
                {getAccordianSummary("Testimonials")}
                <AccordionDetails>
                  {getTestimonialAccordian(1)}
                  {getTestimonialAccordian(2)}
                  {getTestimonialAccordian(3)}
                  {getTestimonialAccordian(4)}
                  {getTestimonialAccordian(5)}
                  {getTestimonialAccordian(6)}
                  {getTestimonialAccordian(7)}
                  {getTestimonialAccordian(8)}
                  {getTestimonialAccordian(9)}
                  {getTestimonialAccordian(10)}
                </AccordionDetails>
              </Accordion>
            </Grid>
            <Grid item xs={12} sm={12} md={12}>
              <Accordion expanded>
                {getAccordianSummary("Images")}
                <AccordionDetails>
                  {!isFranchisie() ? (
                    <Grid container spacing={2}>
                      {status !== "rejected"
                        ? getImageElement("Image 1", updateData?.image_1_url, "image_1_url")
                        : getImageElement(
                            "Image 1",
                            updateData?.update_my_webpage__image_1_url,
                            "image_1_url"
                          )}
                      {status !== "rejected"
                        ? getImageElement("Image 2", updateData?.image_2_url, "image_2_url")
                        : getImageElement(
                            "Image 2",
                            updateData?.update_my_webpage__image_2_url,
                            "image_2_url"
                          )}
                      {status !== "rejected"
                        ? getImageElement("Image 3", updateData?.image_3_url, "image_3_url")
                        : getImageElement(
                            "Image 3",
                            updateData?.update_my_webpage__image_3_url,
                            "image_3_url"
                          )}
                      {status !== "rejected"
                        ? getImageElement("Image 4", updateData?.image_4_url, "image_4_url")
                        : getImageElement(
                            "Image 4",
                            updateData?.update_my_webpage__image_4_url,
                            "image_4_url"
                          )}
                      {status !== "rejected"
                        ? getImageElement("Image 5", updateData?.image_5_url, "image_5_url")
                        : getImageElement(
                            "Image 5",
                            updateData?.update_my_webpage__image_5_url,
                            "image_5_url"
                          )}
                      {status !== "rejected"
                        ? getImageElement("Image 6", updateData?.image_6_url, "image_6_url")
                        : getImageElement(
                            "Image 6",
                            updateData?.update_my_webpage__image_6_url,
                            "image_6_url"
                          )}
                      {status !== "rejected"
                        ? getImageElement("Image 7", updateData?.image_7_url, "image_7_url")
                        : getImageElement(
                            "Image 7",
                            updateData?.update_my_webpage__image_7_url,
                            "image_7_url"
                          )}
                      {status !== "rejected"
                        ? getImageElement("Image 8", updateData?.image_8_url, "image_8_url")
                        : getImageElement(
                            "Image 8",
                            updateData?.update_my_webpage__image_8_url,
                            "image_8_url"
                          )}
                      {status !== "rejected"
                        ? getImageElement("Image 9", updateData?.image_9_url, "image_9_url")
                        : getImageElement(
                            "Image 9",
                            updateData?.update_my_webpage__image_9_url,
                            "image_9_url"
                          )}
                      {status !== "rejected"
                        ? getImageElement("Image 10", updateData?.image_10_url, "image_10_url")
                        : getImageElement(
                            "Image 10",
                            updateData?.update_my_webpage__image_10_url,
                            "image_10_url"
                          )}
                    </Grid>
                  ) : (
                    <MDBox pb={3} px={3}>
                      {displayItems(1)}
                      {displayItems(2)}
                      {displayItems(3)}
                      {displayItems(4)}
                      {displayItems(5)}
                      {displayItems(6)}
                      {displayItems(7)}
                      {displayItems(8)}
                      {displayItems(9)}
                      {displayItems(10)}
                    </MDBox>
                  )}
                </AccordionDetails>
              </Accordion>
            </Grid>
            <Grid item xs={12} sm={12} md={12}>
              <Accordion expanded>
                {getAccordianSummary("Banner Image")}
                <AccordionDetails>
                  {!isFranchisie() ? (
                    status !== "rejected" ? (
                      getImageElement(
                        "Banner Image",
                        updateData?.banner_image_url,
                        "banner_image_url"
                      )
                    ) : (
                      getImageElement(
                        "Banner Image",
                        updateData?.banner_image_url,
                        "banner_image_url"
                      )
                    )
                  ) : (
                    <MDBox pb={3} px={3}>
                      {displayBannerItems(updateData?.banner_image_url)}
                    </MDBox>
                  )}
                </AccordionDetails>
              </Accordion>
            </Grid>
          </Grid>
        </MDBox>
      ) : (
        <MDBox>
          <MDTypography
            variant="h6"
            style={{
              display: "flex",
              justifyContent: "center",
              alignItem: "center",
            }}
          >
            This change has already been approved
          </MDTypography>
        </MDBox>
      )}
      {isPreview && (
        <ImagePreview
          imageData={imageToPreview}
          open={isPreview}
          onCloseImage={() => {
            closeImagePreview();
          }}
        />
      )}
    </MDDialog>
  );
}

export default AddUpdate;
