/* eslint-disable no-unused-vars */
import { useEffect } from "react";
import { SelectedDateRange } from "layouts/pages/scorecard-dashboard";
import { notificationFail } from "store/slices/notificationSlice";
import { useAppDispatch } from "store/store";
import config from "config/config";
import axios from "axios";
import {
  getFirstDateOfCurrentMonth,
  convertToFirstDayOfMonth,
  fetchCurrentDate,
  convertToLastDayOfMonth,
  getYears,
  calculateMaturedAndUnmaturedLocations,
} from "helper/reports";
import Messages from "helper/messages";
import MDTypography from "components/MDTypography";
import { Card, Divider, Grid, Icon } from "@mui/material";
import MDBox from "components/MDBox";
import { Bar } from "react-chartjs-2";
import { MaturedAndUnmaturedLocation, MaturedLocationResult } from "../..";

interface MaturedLocationLeadsProps {
  selectedDateRange: SelectedDateRange;
  currentDateCheckBox: boolean;
  setMaturedLocation: (maturedLocation: MaturedLocationResult) => void;
  setTotalMaturedAndUnmaturedLocation: (totalMaturedLocation: MaturedAndUnmaturedLocation) => void;
  totalMaturedAndUnmaturedLocation: MaturedAndUnmaturedLocation;
}
function MaturedLocationLeads({
  selectedDateRange,
  currentDateCheckBox,
  setMaturedLocation,
  setTotalMaturedAndUnmaturedLocation,
  totalMaturedAndUnmaturedLocation,
}: MaturedLocationLeadsProps) {
  const dispatch = useAppDispatch();
  const fetchLeadMaturedData = () => {
    try {
      if (selectedDateRange.startMonth > selectedDateRange.endMonth) {
        throw new Error("Start month should be lower than end month");
      } else {
        const startDate = currentDateCheckBox
          ? getFirstDateOfCurrentMonth()
          : convertToFirstDayOfMonth(selectedDateRange.startMonth);
        const endDate = currentDateCheckBox
          ? fetchCurrentDate()
          : convertToLastDayOfMonth(selectedDateRange.endMonth);
        let url = "";
        url = `${config.REPORT_URL}/report/fetchLeadMatured?startDate=${startDate
          .toString()
          .slice(0, 10)}&endDate=${endDate.toString().slice(0, 10)}`;
        axios
          .get(url, {
            responseType: "json",
          })
          .then((res: any) => {
            setMaturedLocation(res.data.result);
            const { maturedLocations, unmaturedLocations } = calculateMaturedAndUnmaturedLocations(
              res.data.result
            );
            setTotalMaturedAndUnmaturedLocation({ maturedLocations, unmaturedLocations });
          })
          .catch((err) => {
            dispatch(
              notificationFail(err?.response?.data?.message || Messages.ERROR.SOMETHING_WENT_WRONG)
            );
          });
      }
    } catch (e) {
      dispatch(notificationFail(e.message || Messages.ERROR.SOMETHING_WENT_WRONG));
    }
  };
  useEffect(() => {
    fetchLeadMaturedData();
  }, [selectedDateRange]);

  const options = {
    plugins: {
      legend: {
        display: false,
        position: "top" as const,
      },
      title: {
        display: false,
        text: "Chart.js Bar Chart",
      },
    },
  };

  const data = {
    labels: getYears(selectedDateRange, currentDateCheckBox),
    datasets: [
      {
        label: "Matured",
        data: totalMaturedAndUnmaturedLocation?.maturedLocations,
        backgroundColor: "rgba(255, 99, 132, 0.5)",
      },
      {
        label: "Unmatured",
        data: totalMaturedAndUnmaturedLocation?.unmaturedLocations,
        backgroundColor: "rgba(53, 162, 235, 0.5)",
      },
    ],
  };

  return (
    <Card sx={{ height: "100%" }}>
      <Grid container>
        <Grid item md={12} sm={12} pt={3} pb={1} px={1}>
          <Bar options={options} data={data} />
        </Grid>
        <Grid item md={12} sm={12}>
          <MDTypography variant="h6" textTransform="capitalize" padding="0px 0px 0px 15px">
            Matured/Unmatured Leads
          </MDTypography>
          <Divider />
          <MDBox display="flex" alignItems="center" padding="0px 0px 15px 15px">
            <MDTypography variant="button" color="text" lineHeight={1} sx={{ mt: 0.15, mr: 0.5 }}>
              <Icon>schedule</Icon>
            </MDTypography>
            <MDTypography variant="button" color="text" fontWeight="light">
              {!currentDateCheckBox
                ? `${selectedDateRange.startMonth} - ${selectedDateRange.endMonth}`
                : `${new Date().getFullYear()}-${(new Date().getMonth() + 1)
                    .toString()
                    .padStart(2, "0")}`}
            </MDTypography>
          </MDBox>
        </Grid>
      </Grid>
    </Card>
  );
}

export default MaturedLocationLeads;
