import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "store/store";
import MDDialog from "components/MDDialog";
import MDInput from "components/MDInput";
import MDBox from "components/MDBox";
import { Autocomplete, Checkbox, TextField } from "@mui/material";
import Validations from "helper/validations";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import { saveSideMenuItems } from "store/thunk/mobileAppThunk";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

function addUpdate(props: any): JSX.Element {
  const dispatch = useAppDispatch();

  const { countriesList } = useAppSelector((state) => state.systemAdminSlice);
  const { open, onClose, updateData, pageNo, perPage, search, sortBy } = props;
  const [isUpdate, setIsUpdate] = useState<any>(false);
  const [sideMenuData, setSideMenuData] = useState<any>({});
  const [errors, setErrors] = useState<any>({});
  const [countriesListData, setCountriesListData] = useState<any>([]);
  const [selectedCountries, setSelectedCountries] = useState<any>([]);

  useEffect(() => {
    if (countriesList && countriesList?.countries) {
      setCountriesListData(countriesList.countries);
    }
  }, [countriesList]);

  const onSave = () => {
    const error = Validations.validateSideMenuForm(sideMenuData);
    setErrors(error);
    if (!Object.keys(error).length) {
      const reqData = {
        ...sideMenuData,
        country_ids: sideMenuData.countries.map((v: any) => v.id),
      };
      const req = {
        sideMenuData: reqData,
        onClose,
        isUpdate,
        pageNo,
        perPage,
        search,
        sortBy,
      };
      dispatch(saveSideMenuItems(req));
    }
  };
  useEffect(() => {
    if (updateData) {
      setIsUpdate(true);
      setSideMenuData(updateData);
      const temp: any = [];
      updateData.countries.forEach((val: any) => {
        const getFilter = countriesList.countries.find((v: any) => v.id === val.id);
        if (getFilter) {
          temp.push(getFilter);
        }
      });
      setSelectedCountries(temp);
    }
  }, [updateData]);

  const onChangeField = (e: any, field: string) => {
    setSideMenuData({ ...sideMenuData, [field]: e.target.value });
  };

  const dialogProps = {
    open,
    onSave,
    onClose,
    title: isUpdate ? "Update Side Menu Items" : "Add Side Menu Items",
    size: "sm",
    saveTbtText: isUpdate ? "Update" : "Save",
  };
  return (
    <MDDialog {...dialogProps}>
      <MDBox mt={2}>
        <MDInput
          label="Name"
          variant="standard"
          fullWidth
          required
          name="name"
          placeholder="name"
          value={sideMenuData?.name || ""}
          onChange={(event: any) => onChangeField(event, "name")}
          error={errors && errors.name ? true : false}
          helperText={errors && errors.name}
        />
      </MDBox>
      <MDBox mt={2}>
        <MDInput
          label="Action Link"
          variant="standard"
          fullWidth
          required
          name="action_link"
          placeholder="action_link"
          value={sideMenuData?.action_link || ""}
          onChange={(event: any) => onChangeField(event, "action_link")}
          error={errors && errors.action_link ? true : false}
          helperText={errors && errors.action_link}
        />
      </MDBox>
      <MDBox mt={2}>
        <MDInput
          label="Position"
          variant="standard"
          fullWidth
          required
          type="number"
          name="position"
          placeholder="position"
          value={sideMenuData?.position || ""}
          onChange={(event: any) => onChangeField(event, "position")}
          error={errors && errors.position ? true : false}
          helperText={errors && errors.position}
        />
      </MDBox>
      <MDBox mt={2}>
        <Autocomplete
          multiple
          id="countries"
          options={countriesListData}
          disableCloseOnSelect
          value={selectedCountries}
          getOptionLabel={(option: any) => option?.name}
          onChange={(event: any, newValue: any) => {
            setSelectedCountries(newValue);
            setSideMenuData({ ...sideMenuData, countries: newValue });
          }}
          renderOption={(props: any, option: any, { selected }: any) => (
            <li {...props} key={option.id}>
              <Checkbox
                icon={icon}
                checkedIcon={checkedIcon}
                style={{ marginRight: 8 }}
                checked={selected}
              />
              {option.name}
            </li>
          )}
          renderInput={(params: any) => (
            <TextField
              {...params}
              required
              variant="standard"
              label="Countries"
              placeholder="Countries"
              error={errors && errors.countries ? true : false}
              helperText={errors && errors.countries}
            />
          )}
        />
      </MDBox>
    </MDDialog>
  );
}

export default addUpdate;
