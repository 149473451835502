import React, { useEffect, useState } from "react";
import MDDialog from "components/MDDialog";
// import Validations from "helper/validations";
import { saveFeaturedBlogs } from "store/thunk/solaWebsiteThunk";
import { useAppDispatch } from "store/store";
import { Grid } from "@mui/material";
// import { scrollToError } from "helper/services";
import CustomAutoSearch from "components/CustomAutoSearch";

function EditFeaturedBlog(props: any): JSX.Element {
  const dispatch = useAppDispatch();
  const { featuredBlogs, open, onClose, pageNo, perPage, search, sortBy } = props;
  const [blogData, setblogData] = useState<any>([]);
  const [isUpdate, setIsUpdate] = useState<any>(false);
  // const [errors, setErrors] = useState<any>({});

  const onSave = () => {
    const req = {
      isUpdate,
      blogData,
      onClose,
      pageNo,
      perPage,
      search,
      sortBy,
    };
    dispatch(saveFeaturedBlogs(req));
  };

  useEffect(() => {
    if (featuredBlogs) {
      const result = featuredBlogs.map((a: any) => a.id);
      setIsUpdate(true);
      setblogData(result);
    }
  }, [featuredBlogs]);

  const onChangeAutoComplete = (name: string, value: any) => {
    const data = [...blogData];
    if (value.id) data[parseFloat(name) - 1] = value.id;

    setblogData(data);
  };

  const dialogProps = {
    open,
    onClose,
    onSave,
    title: isUpdate ? "Update Blog" : "Add Blog",
    size: "lg",
    saveTbtText: isUpdate ? "Update" : "Save",
    overflowVisible: "overflowVisible",
  };

  return (
    <MDDialog {...dialogProps}>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12} md={6}>
          <CustomAutoSearch
            idKey="id"
            nameKey="title"
            fieldLabel="Blog"
            apiName="blogs"
            responseKey="blogs"
            name="1"
            value={featuredBlogs[0] || null}
            onChange={onChangeAutoComplete}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={6}>
          <CustomAutoSearch
            idKey="id"
            nameKey="title"
            fieldLabel="Blog"
            apiName="blogs"
            responseKey="blogs"
            name="2"
            value={featuredBlogs[1] || null}
            onChange={onChangeAutoComplete}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={6}>
          <CustomAutoSearch
            idKey="id"
            nameKey="title"
            fieldLabel="Blog"
            apiName="blogs"
            responseKey="blogs"
            name="3"
            value={featuredBlogs[2] || null}
            onChange={onChangeAutoComplete}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={6}>
          <CustomAutoSearch
            idKey="id"
            nameKey="title"
            fieldLabel="Blog"
            apiName="blogs"
            responseKey="blogs"
            name="4"
            value={featuredBlogs[3] || null}
            onChange={onChangeAutoComplete}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={6}>
          <CustomAutoSearch
            idKey="id"
            nameKey="title"
            fieldLabel="Blog"
            apiName="blogs"
            responseKey="blogs"
            name="5"
            value={featuredBlogs[4] || null}
            onChange={onChangeAutoComplete}
          />
        </Grid>
      </Grid>
    </MDDialog>
  );
}

export default EditFeaturedBlog;
