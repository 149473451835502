import MDDialog from "components/MDDialog";
// import MDInput from "components/MDInput";
import MDBox from "components/MDBox";
import { getLocalDateTime, phoneNumberformat } from "helper/services";
import { Grid } from "@mui/material";
import MDTypography from "components/MDTypography";

function ViewDetails(props: any): JSX.Element {
  const { open, onClose, updateData } = props;

  const dialogProps = {
    open,
    onClose,
    title: "View Contact Inquiries",
    size: "md",
    saveBtn: false,
  };

  return (
    <MDDialog {...dialogProps}>
      <MDBox>
        <Grid container>
          {updateData?.name && (
            <Grid item xs={12} sm={6} md={6} mt={2}>
              <MDTypography fontWeight="bold" variant="button">
                Name
              </MDTypography>
              <MDBox>
                <MDTypography variant="button">{updateData?.name || "-"}</MDTypography>
              </MDBox>
            </Grid>
          )}
          {updateData?.email && (
            <Grid item xs={12} sm={6} md={6} mt={2}>
              <MDTypography fontWeight="bold" variant="button">
                Email
              </MDTypography>
              <MDBox>
                <MDTypography variant="button">{updateData?.email || "-"}</MDTypography>
              </MDBox>
            </Grid>
          )}
        </Grid>
      </MDBox>
      <MDBox>
        <Grid container>
          {updateData?.phone && (
            <Grid item xs={12} sm={6} md={6} mt={2}>
              <MDTypography fontWeight="bold" variant="button">
                Phone
              </MDTypography>
              <MDBox>
                <MDTypography variant="button">
                  {phoneNumberformat(updateData?.phone) || "-"}
                </MDTypography>
              </MDBox>
            </Grid>
          )}
          <Grid item xs={12} sm={6} md={6} mt={2}>
            <MDTypography fontWeight="bold" variant="button">
              I Would Like To Be Contacted
            </MDTypography>
            <MDBox>
              <MDTypography variant="button">
                {updateData?.i_would_like_to_be_contacted ? "Yes" : "No"}
              </MDTypography>
            </MDBox>
          </Grid>
        </Grid>
      </MDBox>
      <MDBox>
        <Grid container>
          {updateData?.how_can_we_help_you && (
            <Grid item xs={12} sm={6} md={6} mt={2}>
              <MDTypography fontWeight="bold" variant="button">
                How Can we Help You
              </MDTypography>
              <MDBox>
                <MDTypography variant="button">
                  {updateData?.how_can_we_help_you || "-"}
                </MDTypography>
              </MDBox>
            </Grid>
          )}
          {updateData?.contact_preference && (
            <Grid item xs={12} sm={6} md={6} mt={2}>
              <MDTypography fontWeight="bold" variant="button">
                Contact Preference
              </MDTypography>
              <MDBox>
                <MDTypography variant="button">
                  {updateData?.contact_preference || "-"}
                </MDTypography>
              </MDBox>
            </Grid>
          )}
        </Grid>
      </MDBox>
      <MDBox>
        <Grid container>
          {updateData?.services && (
            <Grid item xs={12} sm={6} md={6} mt={2}>
              <MDTypography fontWeight="bold" variant="button">
                Services
              </MDTypography>
              <MDBox>
                <MDTypography variant="button">{updateData?.services || "-"}</MDTypography>
              </MDBox>
            </Grid>
          )}
          {updateData?.state && (
            <Grid item xs={12} sm={6} md={6} mt={2}>
              <MDTypography fontWeight="bold" variant="button">
                State
              </MDTypography>
              <MDBox>
                <MDTypography variant="button">{updateData?.state || "-"}</MDTypography>
              </MDBox>
            </Grid>
          )}
        </Grid>
      </MDBox>
      <MDBox>
        <Grid container>
          {updateData?.zip_code && (
            <Grid item xs={12} sm={6} md={6} mt={2}>
              <MDTypography fontWeight="bold" variant="button">
                Zip Code
              </MDTypography>
              <MDBox>
                <MDTypography variant="button">{updateData?.zip_code || "-"}</MDTypography>
              </MDBox>
            </Grid>
          )}
          {updateData?.request_url && (
            <Grid item xs={12} sm={12} md={12} mt={2}>
              <MDTypography fontWeight="bold" variant="button">
                Request URL
              </MDTypography>
              <MDBox>
                <MDTypography variant="button">{updateData?.request_url || "-"}</MDTypography>
              </MDBox>
            </Grid>
          )}
        </Grid>
      </MDBox>
      <MDBox>
        <Grid container>
          {updateData?.utm_source && (
            <Grid item xs={12} sm={6} md={6} mt={2}>
              <MDTypography fontWeight="bold" variant="button">
                UTM Source
              </MDTypography>
              <MDBox>
                <MDTypography variant="button">{updateData?.utm_source || "-"}</MDTypography>
              </MDBox>
            </Grid>
          )}
          {updateData?.utm_medium && (
            <Grid item xs={12} sm={6} md={6} mt={2}>
              <MDTypography fontWeight="bold" variant="button">
                UTM Medium
              </MDTypography>
              <MDBox>
                <MDTypography variant="button">{updateData?.utm_medium || "-"}</MDTypography>
              </MDBox>
            </Grid>
          )}
        </Grid>
      </MDBox>
      <MDBox>
        <Grid container>
          {updateData?.utm_campaign && (
            <Grid item xs={12} sm={6} md={6} mt={2}>
              <MDTypography fontWeight="bold" variant="button">
                UTM Campaign
              </MDTypography>
              <MDBox>
                <MDTypography variant="button">{updateData?.utm_campaign || "-"}</MDTypography>
              </MDBox>
            </Grid>
          )}
          {updateData?.utm_medium && (
            <Grid item xs={12} sm={6} md={6} mt={2}>
              <MDTypography fontWeight="bold" variant="button">
                Newsletter Subscription
              </MDTypography>
              <MDBox>
                <MDTypography variant="button">
                  {updateData?.newsletter ? "Yes" : "No"}
                </MDTypography>
              </MDBox>
            </Grid>
          )}
        </Grid>
      </MDBox>
      <MDBox>
        <Grid container>
          {updateData?.created_at && (
            <Grid item xs={12} sm={6} md={6} mt={2}>
              <MDTypography fontWeight="bold" variant="button">
                Created At
              </MDTypography>
              <MDBox>
                <MDTypography variant="button">
                  {updateData?.created_at
                    ? getLocalDateTime(updateData?.created_at, "MM/DD/YYYY")
                    : "-"}
                </MDTypography>
              </MDBox>
            </Grid>
          )}
        </Grid>
      </MDBox>
    </MDDialog>
  );
}

export default ViewDetails;
