import React from "react";
import MDDialog from "components/MDDialog";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
// import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Accordion, AccordionDetails, AccordionSummary, Grid } from "@mui/material";
import { displayMultipleValue, getLocalDateTime } from "helper/services";

function ViewDetails(props: any): JSX.Element {
  const { open, onClose, updateData } = props;

  const dialogProps = {
    open,
    onClose,
    title: "View Side Menu Item",
    size: "md",
    saveBtn: false,
  };

  return (
    <MDDialog {...dialogProps}>
      <MDBox pb={2}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12} md={12}>
            <Accordion expanded>
              <AccordionSummary
                className="location-accordion"
                // expandIcon={<ExpandMoreIcon />}
                aria-controls="publishbh-content"
              >
                <MDTypography variant="h6">Basic info</MDTypography>
              </AccordionSummary>
              <AccordionDetails>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6} md={6}>
                    <MDTypography fontWeight="bold" variant="button">
                      Name
                    </MDTypography>
                    <MDBox>
                      <MDTypography variant="button">{updateData.name || "-"}</MDTypography>
                    </MDBox>
                  </Grid>
                  <Grid item xs={12} sm={6} md={6}>
                    <MDTypography fontWeight="bold" variant="button">
                      Action Link
                    </MDTypography>
                    <MDBox>
                      <MDTypography variant="button">{updateData.action_link || "-"}</MDTypography>
                    </MDBox>
                  </Grid>
                  <Grid item xs={12} sm={6} md={6}>
                    <MDTypography fontWeight="bold" variant="button">
                      Position
                    </MDTypography>
                    <MDBox>
                      <MDTypography variant="button">{updateData.position || "-"}</MDTypography>
                    </MDBox>
                  </Grid>
                  <Grid item xs={12} sm={6} md={6}>
                    <MDTypography fontWeight="bold" variant="button">
                      Countries
                    </MDTypography>
                    <MDBox>
                      <MDTypography variant="button">
                        {displayMultipleValue(updateData.countries)}
                      </MDTypography>
                    </MDBox>
                  </Grid>
                  <Grid item xs={12} sm={6} md={6}>
                    <MDTypography fontWeight="bold" variant="button">
                      Created At
                    </MDTypography>
                    <MDBox>
                      <MDTypography variant="button">
                        {getLocalDateTime(updateData.created_at)}
                      </MDTypography>
                    </MDBox>
                  </Grid>
                  <Grid item xs={12} sm={6} md={6}>
                    <MDTypography fontWeight="bold" variant="button">
                      Updated At
                    </MDTypography>
                    <MDBox>
                      <MDTypography variant="button">
                        {getLocalDateTime(updateData.updated_at)}
                      </MDTypography>
                    </MDBox>
                  </Grid>
                </Grid>
              </AccordionDetails>
            </Accordion>
          </Grid>
        </Grid>
      </MDBox>
    </MDDialog>
  );
}

export default ViewDetails;
