import { useState, useEffect, useRef, useCallback, useLayoutEffect } from "react";
import { Card, Checkbox, Grid, Icon } from "@mui/material";
import MDBox from "components/MDBox";
import DataTable from "examples/Tables/DataTable";
import {
  deleteUpdateWebpageRequests,
  rejectWebPageRequest,
  updateWebPagesListing,
} from "store/thunk/solaProsThunk";
import { useAppDispatch, useAppSelector } from "store/store";
import {
  setDefaultSelectedColumns,
  removeExportFields,
  adminFranchisie,
  getAssociationLocations,
} from "helper/services";
import exportData from "helper/exportTableConst";
import ImagePreview from "layouts/pages/ImagePreview";
import { getDefaultCoulmns, saveSelectedAllData } from "store/thunk/connectUserThunk";
import { setDefaultsColumns } from "store/slices/connectUsersSlice";
import MDIconButton from "components/MDIconButton";
import ViewDetails from "layouts/pages/sola-web/update-web-page-requests/viewDetails";
import AddUpdate from "layouts/pages/sola-web/update-web-page-requests/addUpdate";
import MDDialog from "components/MDDialog";
import MDTypography from "components/MDTypography";
import Messages from "helper/messages";

function PendingList(props: any): JSX.Element {
  const { idState, setIdState }: any = props;
  const dispatch = useAppDispatch();
  const { updateWebPagesRequests } = useAppSelector((state) => state.solaProSlice);
  const { defaultSelectedColumns } = useAppSelector((state) => state.connectUsersReducer);
  const { userData } = useAppSelector((state) => state.authReducer);
  const [selectedItems, setSelectedItems] = useState([]);
  const [updateWebsites, setUpdateWebsites] = useState([]);
  const [pageCount, setPageCount] = useState(0);
  const [pageNo, setPageNo] = useState(0);
  const [perPage, setPerPage] = useState(0);
  const [totalCount, setTotalCount] = useState(0);
  const [search, setSearch] = useState<any>(null);
  const [sortBy, setSortBy] = useState<any>([]);

  const [isPreview, setImagePreview] = useState(false);
  const [imageToPreview, setImageToPreview] = useState("");
  const [isCheckAll, setIsCheckAll] = useState(false);
  const fetchIdRef = useRef(0);
  const [displayColumns, setDisplayColumns] = useState<any>([]);
  const [viewPopup, setViewPopup] = useState<boolean>(false);
  const [updateData, setUpdateData] = useState<any>({});
  const [editPopup, setEditPopup] = useState<boolean>(false);
  const [alreadyApproved, setAlreadyApproved] = useState<boolean>(false);
  const [deleteWebPageRequest, setDeleteWebPageRequest] = useState<boolean>(false);
  const [deleteWebPageRequestId, setDeleteWebPageRequestId] = useState<boolean>(null);
  const [defaultExport, setDefaultExport] = useState<any>([]);
  const [callFetch, setCallFetch] = useState<any>(false);
  const [defaultSorting, setDefaultSorting] = useState<any>([]);
  const [defaultFilteing, setDefaultFilteing] = useState<any>([]);

  const getTestimonialImages = () => {
    const arr: any = [];
    [...Array(10)].map((item: any, ix: any) => {
      arr.push({
        Header: `Testimonial #${ix + 1}`,
        accessor: `testimonial_id_${ix + 1}`,
        export: true,
        checked: false,
        associative_colums: `testimonial_${ix + 1}.name`,
        disableSortBy: true,
      });
      return true;
    });
    [...Array(10)].map((item: any, ix: any) => {
      arr.push({
        Header: `Image #${ix + 1}`,
        accessor: `image_${ix + 1}`,
        export: true,
        checked: false,
        hide_columns: true,
        disableSortBy: true,
      });
      return true;
    });
    return arr;
  };

  // Get columns
  const getColumns = () => {
    const columns: any = [
      {
        Header: "",
        disableSortBy: true,
        accessor: "checkbox",
        export: false,
        width: "100px",
        checked: true,
      },
      {
        Header: "Business Name",
        accessor: "business_name",
        export: true,
        checked: true,
        disableCheck: true,
      },
      { Header: "Subtitle", accessor: "subtitle", export: true, checked: true, disableCheck: true },
      {
        Header: "Studio Number",
        accessor: "studio_number",
        export: true,
        checked: true,
        disableCheck: true,
      },
      { Header: "Website Url", accessor: "website_url", export: true, checked: true },
      { Header: "Booking Url", accessor: "booking_url", export: true, checked: true },
      { Header: "Facebook Url", accessor: "facebook_url", export: true, checked: true },
      { Header: "Google Plus Url", accessor: "google_plus_url", export: true, checked: false },
      { Header: "Instagram Url", accessor: "instagram_url", export: true, checked: false },
      { Header: "Linkedin Url", accessor: "linkedin_url", export: true, checked: false },
      { Header: "Pinterest Url", accessor: "pinterest_url", export: true, checked: false },
      { Header: "Twitter Url", accessor: "twitter_url", export: true, checked: false },
      { Header: "Yelp Url", accessor: "yelp_url", export: true, checked: false },
      { Header: "Tik Tok Url", accessor: "tik_tok_url", export: true, checked: false },
      ...getTestimonialImages(),
      { Header: "Approved", accessor: "approved", export: true, checked: false, is_boolean: true },
      {
        Header: "Action",
        accessor: "action",
        width: "200px",
        checked: true,
        export: false,
        disableSortBy: true,
      },
    ];
    return columns;
  };

  const [checkedColumns, setCheckedColumns] = useState<any>(getColumns());
  const latestRefValue = useRef(checkedColumns);

  useEffect(() => {
    latestRefValue.current = checkedColumns;
  }, [checkedColumns]);

  useEffect(() => {
    if (checkedColumns?.length) {
      setDisplayColumns(checkedColumns.filter((col: any) => col.checked));
    }
  }, [checkedColumns]);

  const fetchData = useCallback(({ pageSize, pageIndex, search, sortBy, isManual }) => {
    const fetchId = ++fetchIdRef.current;
    const sort: any = sortBy || [];
    const filter: any = [];

    setTimeout(() => {
      if (fetchId === fetchIdRef.current) {
        const sentReq = {
          sendData: {
            user_id: userData?.user_id,
            table_name: `${exportData.update_connect_web_page_requests.table_name}_pending`,
            table_columns: latestRefValue.current ? latestRefValue.current : checkedColumns,
            table_sort: sortBy && sortBy?.length ? sortBy : [],
            table_filter: filter,
            default_export_data: defaultExport,
          },
        };
        if (isManual) {
          dispatch(saveSelectedAllData(sentReq));
        }
        setPageNo(pageIndex);
        setSearch(search);
        setSortBy(sort);
        setPerPage(pageSize);
        setIsCheckAll(false);
        dispatch(
          updateWebPagesListing({
            status: "pending",
            pageSize,
            pageIndex,
            search,
            sort,
          })
        );
      }
    }, 1000);
  }, []);

  useLayoutEffect(() => {
    dispatch(setDefaultsColumns([]));
    // Set data in variable and Call defaultColums Thunk
    const sentReq = {
      user_id: userData?.user_id,
      table_name: `${exportData.update_connect_web_page_requests.table_name}_pending`,
    };
    dispatch(getDefaultCoulmns(sentReq)).then((resp: any) => {
      // SD-3199
      if (resp?.payload) {
        const respFilter = resp?.payload?.table_filter || [];
        const respSorting = resp?.payload?.table_sort || [];
        const defaultDBexportData = resp?.payload?.default_export_data || [];
        const defaultDBColumn = resp?.payload?.table_columns || [];
        const tempdata = setDefaultSelectedColumns(defaultDBColumn, getColumns());
        setCheckedColumns(tempdata);
        setDefaultExport(defaultDBexportData);
        setDefaultSorting(respSorting);
        setSortBy(respSorting);
        setDefaultFilteing(respFilter);
        setCallFetch(true);
        fetchData({
          perPage,
          pageNo,
          search,
          sortBy: respSorting && respSorting?.length ? respSorting : [],
          additionalField: null,
          filterData: respFilter && respFilter?.length ? respFilter : [],
        });
      }
    });
  }, []);

  useEffect(() => {
    if (defaultSelectedColumns && defaultSelectedColumns?.length) {
      const newColumns = setDefaultSelectedColumns(defaultSelectedColumns, getColumns());
      setCheckedColumns(newColumns);
    } else {
      setCheckedColumns(getColumns());
    }
  }, [defaultSelectedColumns]);

  useEffect(() => {
    if (updateWebPagesRequests && updateWebPagesRequests?.data) {
      setUpdateWebsites(updateWebPagesRequests?.data);
      setPageCount(updateWebPagesRequests?.meta?.total_pages);
      setTotalCount(updateWebPagesRequests?.meta?.total_count);
      if (idState === "request-already-approved") {
        setAlreadyApproved(true);
        setEditPopup(true);
      } else if (idState) {
        setAlreadyApproved(false);
        const filteredData: any = updateWebPagesRequests?.data?.find(
          (obj: any) => Number(obj?.id) === Number(idState)
        );
        setUpdateData(filteredData);
        setEditPopup(true);
      }
    }
  }, [updateWebPagesRequests]);

  const openViewPopup = (obj: any) => {
    setIdState(null);
    setAlreadyApproved(false);
    setUpdateData(obj);
    setViewPopup(true);
  };

  const openEditPop = (obj: any) => {
    setIdState(null);
    setAlreadyApproved(false);
    setUpdateData(obj);
    setEditPopup(true);
  };

  const onCloseDeleteModal = () => {
    setDeleteWebPageRequest(false);
    setDeleteWebPageRequestId(null);
  };

  const deleteWebpage = (obj: any) => {
    setDeleteWebPageRequest(true);
    setDeleteWebPageRequestId(obj?.id);
  };

  const onDelete = () => {
    dispatch(
      deleteUpdateWebpageRequests({
        pageSize: perPage,
        pageIndex: pageNo,
        search,
        sort: sortBy,
        webpage_request_id: deleteWebPageRequestId,
        callBack: onCloseDeleteModal,
      })
    );
  };

  const actionButtons = (obj: any) => (
    <>
      <MDIconButton
        tooltipName="View"
        aria-label="View"
        color="info"
        onClick={() => openViewPopup(obj)}
      >
        <Icon fontSize="small">visibility</Icon>
      </MDIconButton>
      {adminFranchisie() && (
        <>
          <MDIconButton
            tooltipName="Approve"
            aria-label="Approve"
            color="info"
            onClick={() => openEditPop(obj)}
          >
            <Icon fontSize="small">edit</Icon>
          </MDIconButton>
          <MDIconButton
            tooltipName="Delete"
            aria-label="Delete"
            color="error"
            onClick={() => deleteWebpage(obj)}
          >
            <Icon fontSize="small">delete</Icon>
          </MDIconButton>
        </>
      )}
    </>
  );

  const onChangeCheckBox = (e: any) => {
    let items = JSON.parse(JSON.stringify(selectedItems));
    if (e.target.checked && !items.includes(e.target.value)) {
      items.push(e.target.value);
      if (items.length === updateWebsites.length) {
        setIsCheckAll(true);
      }
    } else {
      items = items.filter((id: any) => id !== e.target.value);
      setIsCheckAll(false);
    }
    setSelectedItems(items);
  };

  const checkUncheckAll = (e: any) => {
    let allIds: any = [];
    allIds = updateWebsites.map((val: any) => String(val.id));
    if (e.target.checked) {
      const selectedIds = [...new Set([...selectedItems, ...allIds])];
      setSelectedItems(selectedIds);
      setIsCheckAll(true);
    } else {
      allIds = updateWebsites.map((val: any) => String(val.id));
      allIds = selectedItems.filter((el: any) => !allIds.includes(el));
      setSelectedItems([...allIds]);
      setIsCheckAll(false);
    }
  };

  const previewImage = (image: any) => {
    setImagePreview(true);
    setImageToPreview(image);
  };

  const closeImagePreview = () => {
    setImagePreview(false);
    setImageToPreview("");
  };

  const getImage = (imageUrl: any) => (
    <Grid container>
      <Grid item>
        {imageUrl && (
          <Grid
            container
            onClick={() => previewImage(imageUrl)}
            justifyContent="center"
            alignItems="center"
            textAlign="center"
            className="thumbnail-img"
          >
            <img width="100%" height="100px" src={imageUrl} alt="original" />
          </Grid>
        )}
      </Grid>
    </Grid>
  );

  const renderCheckmark = (value: any) =>
    value ? (
      <Icon fontSize="medium" color="success">
        check_circle_rounded
      </Icon>
    ) : (
      <Icon fontSize="medium" color="error">
        cancel
      </Icon>
    );

  const getRows = () => {
    const data: any = updateWebsites.map((obj: any) => ({
      checkbox: (
        <Checkbox
          checked={selectedItems.includes(obj?.id.toString())}
          onChange={(e: any) => onChangeCheckBox(e)}
          value={obj?.id}
        />
      ),
      id: obj?.id || "-",
      business_name: obj?.business_name || "-",
      subtitle: obj?.subtitle || "-",
      studio_number: obj?.studio_number || "-",
      website_url: obj?.website_url || "-",
      booking_url: obj?.booking_url || "-",
      pinterest_url: obj?.pinterest_url || "-",
      facebook_url: obj?.facebook_url || "-",
      twitter_url: obj?.twitter_url || "-",
      instagram_url: obj?.instagram_url || "-",
      linkedin_url: obj?.linkedin_url || "-",
      yelp_url: obj?.yelp_url || "-",
      google_plus_url: obj?.google_plus_url || "-",
      tik_tok_url: obj?.tik_tok_url || "-",
      testimonial_id_1: obj?.testimonial_id_1 ? obj?.testimonial_1?.name || "-" : "-",
      testimonial_id_2: obj?.testimonial_id_2 ? obj?.testimonial_2?.name || "-" : "-",
      testimonial_id_3: obj?.testimonial_id_3 ? obj?.testimonial_3?.name || "-" : "-",
      testimonial_id_4: obj?.testimonial_id_4 ? obj?.testimonial_4?.name || "-" : "-",
      testimonial_id_5: obj?.testimonial_id_5 ? obj?.testimonial_5?.name || "-" : "-",
      testimonial_id_6: obj?.testimonial_id_6 ? obj?.testimonial_6?.name || "-" : "-",
      testimonial_id_7: obj?.testimonial_id_7 ? obj?.testimonial_7?.name || "-" : "-",
      testimonial_id_8: obj?.testimonial_id_8 ? obj?.testimonial_8?.name || "-" : "-",
      testimonial_id_9: obj?.testimonial_id_9 ? obj?.testimonial_9?.name || "-" : "-",
      testimonial_id_10: obj?.testimonial_id_10 ? obj?.testimonial_10?.name || "-" : "-",
      image_1: obj?.image_1_url ? getImage(obj?.image_1_url) : "-",
      image_2: obj?.image_2_url ? getImage(obj?.image_2_url) : "-",
      image_3: obj?.image_3_url ? getImage(obj?.image_3_url) : "-",
      image_4: obj?.image_4_url ? getImage(obj?.image_4_url) : "-",
      image_5: obj?.image_5_url ? getImage(obj?.image_5_url) : "-",
      image_6: obj?.image_6_url ? getImage(obj?.image_6_url) : "-",
      image_7: obj?.image_7_url ? getImage(obj?.image_7_url) : "-",
      image_8: obj?.image_8_url ? getImage(obj?.image_8_url) : "-",
      image_9: obj?.image_9_url ? getImage(obj?.image_9_url) : "-",
      image_10: obj?.image_10_url ? getImage(obj?.image_10_url) : "-",
      approved: renderCheckmark(obj?.approved) || "-",
      action: actionButtons(obj),
    }));
    return data;
  };

  const tableData = {
    columns: getColumns(),
    rows: getRows(),
  };

  // Remove uncheck boxes
  const clearCheckItem = () => {
    setSelectedItems([]);
  };

  const closeModal = () => {
    setViewPopup(false);
    setUpdateData({});
  };

  const closeEditModal = () => {
    setIdState(null);
    setAlreadyApproved(false);
    setEditPopup(false);
    setUpdateData({});
  };

  const rejectRequest = (props: any) => {
    dispatch(
      rejectWebPageRequest({
        webpage_request_id: props,
        pageSize: perPage,
        pageIndex: pageNo,
        search,
        sort: sortBy,
        status: "pending",
        callBack: closeEditModal,
      })
    );
  };

  const deleteDialogProps = {
    open: deleteWebPageRequest,
    onClose: () => onCloseDeleteModal(),
    onSave: () => onDelete(),
    title: "Delete Update Webpage Request",
    size: "sm",
    saveBtn: false,
    deleteBtn: true,
    saveTbtText: "Yes",
    closeBtnText: "No",
  };

  return (
    <Card>
      <MDBox
        bgColor="dark"
        color="white"
        coloredShadow="dark"
        borderRadius="xl"
        alignItems="center"
        justifyContent="space-between"
        pt={1}
        pb={1}
        pl={2}
        pr={2}
        ml={2}
        mr={2}
        display="flex"
        className="page-header"
        mt={-3}
      >
        <p className="page-header-label">Update Web Page Requests</p>
      </MDBox>
      {(defaultSorting || defaultSorting?.length) && callFetch && (
        <DataTable
          defaultSortingColumns={defaultSorting}
          defaultFilteringColums={defaultFilteing}
          defaultDisplayColumns={displayColumns}
          setCheckedColumns={setCheckedColumns}
          table={tableData}
          canSearch
          searchText="Search..."
          selectedItems={selectedItems}
          showExportSelectedBtn
          showExportAllBtn
          pageCount={pageCount}
          pageNo={pageNo}
          setPageNo={setPageNo}
          totalCount={totalCount}
          fetchData={fetchData}
          exportFields={removeExportFields(
            exportData.update_connect_web_page_requests.export_fields
          )}
          exportTable={exportData.update_connect_web_page_requests.table_name}
          exportType={exportData.update_connect_web_page_requests.export_type}
          exportSection="pending"
          status="pending"
          manualSearch
          manualSort
          isDisplayCheckAllCheckbox
          locationsIds={getAssociationLocations()}
          onChangeCheckAllCheckbox={checkUncheckAll}
          isCheckAll={isCheckAll}
          checkedColumns={checkedColumns}
          clearCheckItem={clearCheckItem}
        />
      )}
      {viewPopup && (
        <ViewDetails
          open={viewPopup}
          updateData={updateData}
          onClose={() => closeModal()}
          status="pending"
          pageNo={pageNo}
          perPage={perPage}
          search={search}
          sortBy={sortBy}
        />
      )}
      {editPopup && (
        <AddUpdate
          open={editPopup}
          alreadyApproved={alreadyApproved}
          updateData={updateData}
          onClose={() => closeEditModal()}
          status="pending"
          pageNo={pageNo}
          perPage={perPage}
          search={search}
          sortBy={sortBy}
          setUpdateData={setUpdateData}
          onReject={(props: any) => rejectRequest(props)}
        />
      )}
      {deleteWebPageRequest && (
        <MDDialog {...deleteDialogProps}>
          <MDTypography variant="h6" fontWeight="medium">
            {Messages.GENERAL.SURE_TO_DELETE}
          </MDTypography>
        </MDDialog>
      )}
      <ImagePreview
        imageData={imageToPreview}
        open={isPreview}
        onCloseImage={() => {
          closeImagePreview();
        }}
      />
    </Card>
  );
}

export default PendingList;
