import { useEffect } from "react";
import { Button, Box } from "@mui/material";

function HandleButton(props: any): JSX.Element {
  const {
    datas,
    btnTxt,
    handleNext,
    activeStep,
    handleBack,
    checkForNextButton,
    contactInquiries,
    setContactInquiries,
  } = props;

  useEffect(() => {
    if (contactInquiries) {
      setContactInquiries(contactInquiries);
    }
  }, [contactInquiries]);

  return (
    <Box
      sx={{
        mb: 2,
        display: "flex",
        alignItems: "center",
        justifyContent: { md: "center", xs: "flex-start" },
      }}
    >
      <Button
        variant="contained"
        sx={{ mt: 1, mr: 1, color: "#fff", p: "0 10px" }}
        onClick={() => handleNext(datas)}
        disabled={checkForNextButton()}
      >
        {btnTxt ? btnTxt : "Continue"}
      </Button>

      <Button
        variant="outlined"
        disabled={activeStep === 0}
        onClick={handleBack}
        sx={{
          mt: 1,
          mr: 1,
          color: "#1a73e8",
          "&:hover": {
            borderColor: "#1a73e8",
          },
        }}
      >
        Back
      </Button>
    </Box>
  );
}

export default HandleButton;
