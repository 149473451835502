import { Card, Icon, Grid } from "@mui/material";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDIconButton from "components/MDIconButton";
import MDTypography from "components/MDTypography";
import MDDialog from "components/MDDialog";
import CustomAutoSearch from "components/CustomAutoSearch";
import DataTable from "examples/Tables/DataTable";
import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "store/store";
import {
  updateRMTwilioNumberToLocation,
  removeRMLocationFromTwillioNumber,
} from "store/thunk/repairMaintenance";

function Locations(props: any): JSX.Element {
  const dispatch = useAppDispatch();
  const { phoneDetail } = props;
  const { userData } = useAppSelector((state: any) => state.authReducer);
  const [location, setLocation] = useState(null);
  const [locationListData, setLocationData] = useState([]);
  const [addLocation, setAddLocation] = useState(false);
  const [deleteLocationId, setDeleteLocationId] = useState<any>(null);

  useEffect(() => {
    if (phoneDetail?.locations && phoneDetail?.locations.length) {
      setLocationData(phoneDetail?.locations);
    }
  }, [phoneDetail]);

  const actionButtons = (obj: any) => (
    <MDIconButton
      tooltipName={locationListData.length <= 1 ? "Must Have At Least 1 Location" : "Remove"}
      aria-label="Delete"
      disabled={locationListData.length <= 1}
      color="error"
      onClick={() => setDeleteLocationId(obj?.id)}
    >
      <Icon fontSize="small">delete</Icon>
    </MDIconButton>
  );

  const getRows = () => {
    const data: any = locationListData?.map((obj: any) => ({
      id: obj?.id,
      store_id: obj?.store_id,
      location_name: obj?.name ? obj?.name : "-",
      address: obj?.address_1 ? obj?.address_1 : "-",
      city: obj?.city ? obj?.city : "-",
      state: obj?.state ? obj?.state : "-",
      action: actionButtons(obj),
    }));
    return data;
  };

  const tableData = {
    columns: [
      { Header: "ID", accessor: "id", width: "150px", export: true },
      { Header: "Sola ID", accessor: "store_id", width: "150px", export: true },
      { Header: "Location name", accessor: "location_name", export: true },
      { Header: "Address", accessor: "address", export: true },
      { Header: "City", accessor: "city", export: true },
      { Header: "State", accessor: "state", export: true },
      { Header: "Action", accessor: "action", export: false, disableSortBy: true, width: "200px" },
    ],
    rows: getRows(),
  };

  const onClose = () => {
    setLocation(null);
    setAddLocation(false);
  };

  const onAddLocation = () => {
    const req = {
      updateData: {
        id: phoneDetail?.id,
        user_id: userData?.user_id,
        location_ids: location.map((obj: any) => obj.cms_location_id),
      },
      onClose,
    };
    dispatch(updateRMTwilioNumberToLocation(req));
  };

  const onDelete = () => {
    if (deleteLocationId) {
      const req = {
        removeData: {
          id: phoneDetail?.id,
          user_id: userData?.user_id,
          location_id: deleteLocationId,
        },
        setDeleteLocationId,
      };
      dispatch(removeRMLocationFromTwillioNumber(req));
    }
  };

  const locationDialog = {
    open: addLocation,
    onClose,
    onSave: onAddLocation,
    title: "Assign New Location to Phone Number",
    size: "sm",
    saveBtn: location ? true : false,
    overflowVisible: "overflowVisible",
  };

  const deleteDialogProps = {
    open: deleteLocationId,
    onClose: () => setDeleteLocationId(false),
    onSave: () => onDelete(),
    title: "Remove Location",
    size: "sm",
    saveBtn: false,
    deleteBtn: true,
    saveTbtText: "Yes",
    closeBtnText: "No",
  };

  return (
    <Card id="locations" sx={{ overflow: "visible" }}>
      <MDBox p={3} display="flex" justifyContent="space-between">
        <MDTypography className="page-header-label" variant="h5">
          Locations
        </MDTypography>
        <MDButton
          className="xs-small"
          variant="gradient"
          color="light"
          size="small"
          onClick={() => setAddLocation(true)}
        >
          <Icon sx={{ fontWeight: "bold" }}>add</Icon> Add New
        </MDButton>
      </MDBox>
      <DataTable table={tableData} canSearch searchText="Search..." />

      {addLocation && (
        <MDDialog {...locationDialog}>
          <Grid container spacing={2} mt={1}>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <CustomAutoSearch
                idKey="cms_location_id"
                nameKey="name"
                fieldLabel="Location"
                parentKey="location"
                apiName="get-locations"
                responseKey="data"
                name="locations"
                multiple
                value={location || null}
                required
                slsAPI
                onChange={(event: any, val: any) => setLocation(val)}
              />
            </Grid>
          </Grid>
        </MDDialog>
      )}

      {deleteLocationId && (
        <MDDialog {...deleteDialogProps}>
          <MDTypography variant="h6" fontWeight="medium">
            Are you sure you want to remove this location from this Phone number? All sms/call
            functionality will be disabled.
          </MDTypography>
        </MDDialog>
      )}
    </Card>
  );
}

export default Locations;
