// @material-ui core components
import { Card, FormControl, Grid, InputLabel, MenuItem, Select } from "@mui/material";
import MDBox from "components/MDBox";
import MDInput from "components/MDInput";
import MDTypography from "components/MDTypography";
import CustomAutoSearch from "components/CustomAutoSearch";
import { useAppSelector } from "store/store";
import { adminSuperAdmin, getPermission, systemAdmin } from "helper/services";

// Settings page components
import FormField from "layouts/pages/account/components/FormField";

function BasicInfo(props: any): JSX.Element {
  const {
    editPermission,
    onChangeField,
    locationDetail,
    onChangeAutoComplete,
    errors,
    getAccessTypePermission,
  } = props;
  const { userData } = useAppSelector((state) => state.authReducer);
  return (
    <Card id="basic-info" sx={{ overflow: "visible" }}>
      <MDBox p={3}>
        <MDTypography variant="h5">Basic Info</MDTypography>
      </MDBox>
      <MDBox component="form" pb={3} px={3}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            <FormField
              label="Location ID"
              disabled
              value={locationDetail?.id || ""}
              placeholder="Location ID"
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <MDInput
              label="Location Name"
              variant="standard"
              fullWidth
              name="name"
              placeholder="Location Name"
              disabled={!editPermission}
              onChange={onChangeField}
              value={locationDetail?.name || ""}
              error={errors && errors.name ? true : false}
              helperText={errors && errors.name}
              required
            />
          </Grid>
          {systemAdmin() && (
            <Grid item xs={12} sm={6} className="association">
              <CustomAutoSearch
                multiple
                idKey="id"
                nameKey="association_name"
                fieldLabel="Association"
                apiName="get-associations-list"
                responseKey="data"
                name="association"
                onChange={onChangeAutoComplete}
                value={locationDetail?.association || []}
                usersData={{ user_id: userData?.user_id }}
                slsAPI
                createNewLink={
                  getPermission("locations", "locaions_associations")
                    ? "/locations/associations"
                    : ""
                }
                disabled={getAccessTypePermission("location_general", "edit_access")}
              />
            </Grid>
          )}
          <Grid item xs={12} sm={6} className="msa">
            <CustomAutoSearch
              idKey="id"
              multiple
              nameKey="name"
              fieldLabel="MSA"
              apiName="msas"
              slsAPI
              responseKey="data"
              name="msas"
              value={locationDetail.msas}
              onChange={onChangeAutoComplete}
              createNewLink={getPermission("locations", "locations_msa") ? "/locations/msa" : ""}
              disabled={
                getAccessTypePermission("location_general", "edit_access") || !adminSuperAdmin()
              }
              required
              error={errors && errors.msas}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormField
              label="Sola ID"
              // disabled={!editPermission}
              value={locationDetail?.store_id || ""}
              placeholder="Sola ID"
              name="store_id"
              onChange={onChangeField}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={6} lg={6}>
            <FormControl fullWidth>
              <InputLabel>Status</InputLabel>
              <Select
                className="height45"
                label="Status"
                value={locationDetail?.status || ""}
                name="status"
                onChange={onChangeField}
                disabled={!adminSuperAdmin()}
              >
                <MenuItem value="open">Open</MenuItem>
                <MenuItem value="closed">Closed</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          {adminSuperAdmin() && (
            <>
              <Grid item xs={12} sm={6} md={6} lg={6}>
                <FormControl fullWidth>
                  <InputLabel>Region</InputLabel>
                  <Select
                    className="height45"
                    label="Region"
                    value={locationDetail?.region || ""}
                    name="region"
                    onChange={onChangeField}
                  >
                    <MenuItem value="east">East</MenuItem>
                    <MenuItem value="central">Central</MenuItem>
                    <MenuItem value="west">West</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6}>
                <MDInput
                  label="FBC"
                  variant="standard"
                  fullWidth
                  name="fbc"
                  placeholder="FBC"
                  disabled={!editPermission}
                  onChange={onChangeField}
                  value={locationDetail?.fbc || ""}
                />
              </Grid>
            </>
          )}
        </Grid>
      </MDBox>
    </Card>
  );
}

export default BasicInfo;
