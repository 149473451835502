import { useState, useEffect } from "react";
import { useAppDispatch } from "store/store";
import { Card, Icon, Grid } from "@mui/material";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDIconButton from "components/MDIconButton";
import MDTypography from "components/MDTypography";
import MDDialog from "components/MDDialog";
import DataTable from "examples/Tables/DataTable";
import CustomAutoSearch from "components/CustomAutoSearch";
import Messages from "helper/messages";
import { associationAddUser, deleteAssociationUser } from "store/thunk/associationThunk";
import { getPermission, getLocationCrmAccess } from "helper/services";
import LocationWiseUsers from "layouts/pages/associations/user-access/locationWiseUsers";
import ManageAccess from "layouts/pages/associations/user-access/manage-access";

function UserAccess(props: any): JSX.Element {
  const dispatch = useAppDispatch();
  const { association, setUserData, setAssociation } = props;
  const [userAccess, setuserAccessData] = useState([]);
  const [addUserPopup, setAddUserPopup] = useState(false);
  const [selectedUsers, setSelectedUsers] = useState(null);
  const [deleteUserId, setDeleteUserId] = useState<any>(null);
  const [editPermission, setEditPermission] = useState<any>(false);
  const [defaultAccess, setDefaultAccess] = useState<any>([]);
  const [locationAccess, setLocationAccess] = useState<any>([]);
  const [crmAccess, setCrmAccess] = useState<any>([]);

  useEffect(() => {
    const getAccess = getLocationCrmAccess();
    setLocationAccess(getAccess?.locationAccess || []);
    setCrmAccess(getAccess?.crmAccess || []);
    setDefaultAccess(getAccess?.defaultAccess || []);
  }, []);

  useEffect(() => {
    if (association && Array.isArray(association?.users) && association?.users?.length) {
      setuserAccessData(association?.users?.filter((obj: any) => obj));
    }
  }, [association]);

  const onClose = () => {
    setSelectedUsers(null);
    setAddUserPopup(false);
  };

  const onAddUser = () => {
    dispatch(
      associationAddUser({
        userId: selectedUsers?.user_id,
        associationId: association?.id,
        defaultAccess,
        onClose,
      })
    );
  };

  const onDelete = () => {
    if (deleteUserId) {
      dispatch(
        deleteAssociationUser({
          userId: deleteUserId,
          associationId: association?.id,
        })
      );
      setDeleteUserId(false);
    }
  };

  const actionButtons = (obj: any) => (
    <>
      <MDIconButton
        tooltipName="View Details"
        aria-label="View Details"
        color="info"
        onClick={() => setUserData(obj)}
      >
        <Icon fontSize="small">visibility</Icon>
      </MDIconButton>
      <MDIconButton
        tooltipName="Delete"
        aria-label="Delete"
        color="error"
        onClick={() => setDeleteUserId(obj?.user_id)}
      >
        <Icon fontSize="small">delete</Icon>
      </MDIconButton>
    </>
  );

  const getRows = () => {
    const data: any = userAccess?.map((obj: any) => ({
      id: obj?.user_id,
      first_name: obj?.first_name ? obj?.first_name : "-",
      last_name: obj?.last_name ? obj?.last_name : "-",
      email: obj?.login_email ? obj?.login_email : "-",
      action: actionButtons(obj),
    }));
    return data;
  };

  const tableData = {
    columns: [
      { Header: "Id", accessor: "id", width: "50px", export: true },
      { Header: "First name", accessor: "first_name", export: true },
      { Header: "Last name", accessor: "last_name", export: true },
      { Header: "Email", accessor: "email", export: true },
      { Header: "Action", accessor: "action", export: false, disableSortBy: true, width: "200px" },
    ],
    rows: getRows(),
  };

  const onChangeAutoComplete = (name: string, value: any) => {
    const userAccess: any = [];
    if (association?.locations?.length && value?.length) {
      association?.locations?.map((obj: any) => {
        value.map((v: any) => {
          const tempUserId = Number(obj?.user_id || v?.user_id);
          const templocationId = Number(obj?.cms_location_id || v?.cms_location_id);
          const existing = userAccess.find(
            (u: any) =>
              Number(u?.location_id) === templocationId && Number(u?.user_id) === tempUserId
          );
          if (!existing) {
            const alreadyAdded = association?.userAccess?.find(
              (u: any) =>
                Number(u?.location_id) === templocationId && Number(u?.user_id) === tempUserId
            );
            if (alreadyAdded) {
              userAccess.push(alreadyAdded);
            } else {
              userAccess.push({
                location_id: templocationId,
                user_id: tempUserId,
                access: defaultAccess,
              });
            }
          }
          return true;
        });
        return true;
      });
    }
    setAssociation({ ...association, userAccess });
    setSelectedUsers(value);
  };

  const editLocationAccess = (location: any, user: any) => {
    const tempAccess: any =
      location &&
      user &&
      association?.userAccess?.find(
        (obj: any) =>
          Number(obj?.location_id || obj?.id) ===
            Number(location?.cms_location_id || location?.id) &&
          Number(obj?.user_id) === Number(user?.user_id)
      );
    setEditPermission({
      location,
      user,
      accessData: tempAccess ? tempAccess?.access : [],
    });
  };

  const onSaveAccess = () => {
    const tempAssociation = JSON.parse(JSON.stringify(association));
    const getIndex = tempAssociation.userAccess.findIndex(
      (obj: any) =>
        Number(obj?.location_id || obj?.id) ===
          Number(editPermission?.location?.cms_location_id || editPermission?.location?.id) &&
        Number(obj?.user_id) === Number(editPermission?.user?.user_id)
    );
    const tempUserAccess = tempAssociation.userAccess;
    tempUserAccess[getIndex].access = editPermission.accessData;
    setAssociation(tempAssociation);
    setEditPermission(false);
  };

  const onCloseAccess = () => {
    setEditPermission(false);
  };

  const onChangeAccess = (data: any) => {
    setEditPermission({ ...editPermission, accessData: data });
  };

  const editPermissionAttr = {
    onSave: onSaveAccess,
    onClose: onCloseAccess,
    locationAccess,
    crmAccess,
    accessData: editPermission?.accessData || [],
    location: editPermission?.location || null,
    user: editPermission?.user || null,
    setAccessData: onChangeAccess,
  };

  const userDialog = {
    open: addUserPopup,
    onClose,
    onSave: onAddUser,
    title: "Add User",
    size: "sm",
    saveBtn: selectedUsers ? true : false,
    overflowVisible: "overflowVisible",
  };

  const deleteDialogProps = {
    open: deleteUserId,
    onClose: () => setDeleteUserId(false),
    onSave: () => onDelete(),
    title: "Delete Owner",
    size: "sm",
    saveBtn: false,
    deleteBtn: true,
    saveTbtText: "Yes",
    closeBtnText: "No",
  };

  return (
    <Card id="user-access">
      <MDBox p={3} display="flex" justifyContent="space-between">
        <MDTypography variant="h5" className="page-header-label">
          Users
        </MDTypography>
        <MDButton
          variant="gradient"
          className="xs-small"
          color="light"
          size="small"
          onClick={() => setAddUserPopup(true)}
        >
          <Icon sx={{ fontWeight: "bold" }}>add</Icon> Add New
        </MDButton>
      </MDBox>
      <MDBox position="relative">
        <DataTable table={tableData} canSearch searchText="Search..." />
      </MDBox>
      <MDBox p={3} pt={0} fontSize="initial">
        <LocationWiseUsers association={association} editLocationAccess={editLocationAccess} />
      </MDBox>
      {editPermission && <ManageAccess open {...editPermissionAttr} />}
      {addUserPopup && (
        <MDDialog {...userDialog}>
          <Grid container spacing={2} mt={1}>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <CustomAutoSearch
                idKey="user_id"
                nameKey="login_email"
                fieldLabel="User"
                apiName="connect_users"
                responseKey="connect_users"
                name="users"
                slsAPI
                required
                value={selectedUsers || null}
                onChange={onChangeAutoComplete}
                createNewLink={
                  getPermission("users", "users_manage") ? "/users-&-roles/all-users" : ""
                }
              />
            </Grid>
          </Grid>
        </MDDialog>
      )}

      {deleteUserId && (
        <MDDialog {...deleteDialogProps}>
          <MDTypography variant="h6" fontWeight="medium">
            {Messages.GENERAL.SURE_TO_DELETE}
          </MDTypography>
        </MDDialog>
      )}
    </Card>
  );
}

export default UserAccess;
