import { useState, useEffect } from "react";
import { Card, Icon } from "@mui/material";

import MDBox from "components/MDBox";
import MDButton from "components/MDButton";

import DataTable from "examples/Tables/DataTable";
import { getRolesList, getRoleDetails } from "store/thunk/rolesAndMenusThunk";
import { useAppDispatch, useAppSelector } from "store/store";
import { RoleDetailsRequest } from "types/custom-types";
import MDIconButton from "components/MDIconButton";
import AddUpdate from "./addUpdate";

function RoleList(): JSX.Element {
  const dispatch = useAppDispatch();
  const rolesInfo = useAppSelector((state) => state.rolesAndMenusReducer);

  const [rolePopup, setRolePopup] = useState(false);
  const [editRole, setEditRole] = useState(null);
  const [viewRole, setViewRole] = useState(null);
  const [roleList, setRoleList] = useState([]);

  useEffect(() => {
    dispatch(getRolesList());
  }, []);

  const editRoleAction = (val: any) => {
    const request: RoleDetailsRequest = {
      name: val.name,
    };
    dispatch(getRoleDetails(request));
    setRolePopup(true);
    setEditRole(val);
    setViewRole(false);
  };

  const viewRoleAction = (val: any) => {
    const request: RoleDetailsRequest = {
      name: val.name,
    };
    dispatch(getRoleDetails(request));
    setRolePopup(true);
    setEditRole(val);
    setViewRole(true);
  };

  useEffect(() => {
    if (rolesInfo?.roles.length > 0) {
      const allRoles: any = [];
      rolesInfo.roles.map((val: any) => {
        allRoles.push({
          role_name: val.name,
          description: val.description,
          action: (
            <>
              <MDIconButton
                tooltipName="View"
                onClick={() => {
                  viewRoleAction(val);
                }}
                aria-label="View details"
                color="info"
              >
                <Icon fontSize="small">visibility_icon</Icon>
              </MDIconButton>
              <MDIconButton
                tooltipName="Edit"
                aria-label="Edit"
                color="info"
                onClick={() => editRoleAction(val)}
              >
                <Icon fontSize="small">edit</Icon>
              </MDIconButton>
            </>
          ),
        });
        return true;
      });
      setRoleList(allRoles);
    }
  }, [rolesInfo]);

  const tableData = {
    columns: [
      { Header: "role name", accessor: "role_name" },
      { Header: "description", accessor: "description" },
      { Header: "action", accessor: "action", disableSortBy: true },
    ],
    rows: roleList,
  };
  return (
    <Card>
      <MDBox
        bgColor="dark"
        color="white"
        coloredShadow="dark"
        borderRadius="xl"
        alignItems="center"
        justifyContent="space-between"
        pt={1}
        pb={1}
        pl={2}
        pr={2}
        ml={2}
        mr={2}
        display="flex"
        mt={-3}
        className="page-header"
      >
        <p className="page-header-label">Roles</p>
        <MDButton
          variant="gradient"
          color="light"
          size="small"
          className="xs-small"
          onClick={() => {
            setRolePopup(true);
            setEditRole(null);
            setViewRole(false);
          }}
        >
          <Icon sx={{ fontWeight: "bold" }}>add</Icon> Add Role
        </MDButton>
      </MDBox>
      <DataTable table={tableData} canSearch />
      {rolePopup && (
        <AddUpdate
          open={rolePopup}
          onClose={() => setRolePopup(false)}
          viewRole={viewRole}
          editRole={editRole}
        />
      )}
    </Card>
  );
}

export default RoleList;
