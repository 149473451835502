import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import { useState } from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Icon from "@mui/material/Icon";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";
import MDTypography from "components/MDTypography";
import MDEditor from "components/MDEditor";
import CustomErrorMessage from "components/CustomErrorMessage";
import constants from "helper/constants";

function Correspondence(props: any): JSX.Element {
  const { onChangeField, onChangeEditor, errors, rmDetails, getAccessTypePermission } = props;
  const [editLetter, setEditLetter] = useState("");

  const editLetterBtn = (field: string) => (
    <MDButton
      variant="gradient"
      className="xs-small text-capitalize"
      color="light"
      size="small"
      onClick={() => setEditLetter(field)}
      disabled={getAccessTypePermission("location_rentmanager", "edit_access")}
      mt={2}
    >
      Edit
      <Icon fontSize="small" sx={{ marginLeft: "2px", marginBottom: "5px" }}>
        edit
      </Icon>
    </MDButton>
  );

  const checkDisable = (field: string) => {
    let status = true;
    if (editLetter === field) {
      status = false;
    }
    return status;
  };
  return (
    <Card id="correspondence" sx={{ overflow: "visible" }}>
      <MDBox p={3}>
        <MDTypography variant="h5">Correspondence</MDTypography>
      </MDBox>
      <MDBox component="form" p={2}>
        <MDBox pl={1}>
          <MDTypography variant="h6">Email</MDTypography>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={12}>
              <MDInput
                label="SEND FROM Email Address"
                variant="standard"
                fullWidth
                name="from_email_address"
                placeholder="SEND FROM Email Address"
                onChange={onChangeField}
                value={rmDetails?.from_email_address || ""}
                error={errors && errors.from_email_address ? true : false}
                helperText={errors && errors.from_email_address}
                disabled={getAccessTypePermission("location_rentmanager", "edit_access")}
              />
            </Grid>
            <Grid item xs={12} sm={12}>
              <MDInput
                label="REPLY TO Email Address"
                variant="standard"
                fullWidth
                name="to_email_address"
                placeholder="REPLY TO Email Address"
                onChange={onChangeField}
                value={rmDetails?.to_email_address || ""}
                error={errors && errors.to_email_address ? true : false}
                helperText={errors && errors.to_email_address}
                disabled={getAccessTypePermission("location_rentmanager", "edit_access")}
              />
            </Grid>
          </Grid>
        </MDBox>
        <MDBox mt={4} pl={1}>
          <MDTypography variant="h6">Email Correspondence</MDTypography>
        </MDBox>
        <MDBox mt={2} pl={1}>
          <MDBox>
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="profetional-letter"
              >
                <MDTypography>Sola New Professional Letter</MDTypography>
              </AccordionSummary>
              <AccordionDetails>
                <MDEditor
                  value={rmDetails?.professional_letter || ""}
                  onChange={(e: any) => onChangeEditor(e, "professional_letter")}
                  modules={constants.editorModules}
                  formats={constants.editorFormats}
                  readOnly={
                    checkDisable("professional_letter") ||
                    getAccessTypePermission("location_rentmanager", "edit_access")
                  }
                />
                {editLetterBtn("professional_letter")}
                {errors && errors?.professional_letter && (
                  <CustomErrorMessage message={errors?.professional_letter} />
                )}
              </AccordionDetails>
            </Accordion>
          </MDBox>
          <MDBox mt={2}>
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="renewal-letter"
              >
                <MDTypography>Sola Renewal Letter</MDTypography>
              </AccordionSummary>
              <AccordionDetails>
                <MDEditor
                  value={rmDetails?.renewal_letter || ""}
                  onChange={(e: any) => onChangeEditor(e, "renewal_letter")}
                  modules={constants.editorModules}
                  formats={constants.editorFormats}
                  readOnly={
                    checkDisable("renewal_letter") ||
                    getAccessTypePermission("location_rentmanager", "edit_access")
                  }
                />
                {editLetterBtn("renewal_letter")}
                {errors && errors?.renewal_letter && (
                  <CustomErrorMessage message={errors?.renewal_letter} />
                )}
              </AccordionDetails>
            </Accordion>
          </MDBox>
          <MDBox mt={4}>
            <MDTypography variant="h6">Legal Correspondence</MDTypography>
          </MDBox>
          <MDBox mt={2}>
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="lease-agreement-letter"
              >
                <MDTypography>Lease Agreement</MDTypography>
              </AccordionSummary>
              <AccordionDetails>
                <MDEditor
                  value={rmDetails?.lease_agreement_letter || ""}
                  onChange={(e: any) => onChangeEditor(e, "lease_agreement_letter")}
                  modules={constants.editorModules}
                  formats={constants.editorFormats}
                  readOnly={
                    checkDisable("lease_agreement_letter") ||
                    getAccessTypePermission("location_rentmanager", "edit_access")
                  }
                />
                {editLetterBtn("lease_agreement_letter")}
                {errors && errors?.lease_agreement_letter && (
                  <CustomErrorMessage message={errors?.lease_agreement_letter} />
                )}
              </AccordionDetails>
            </Accordion>
          </MDBox>
          <MDBox mt={2}>
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="application-email-letter"
              >
                <MDTypography>Electronic Fund Transfer Agreement</MDTypography>
              </AccordionSummary>
              <AccordionDetails>
                <MDEditor
                  value={rmDetails?.eft_letter || ""}
                  onChange={(e: any) => onChangeEditor(e, "eft_letter")}
                  modules={constants.editorModules}
                  formats={constants.editorFormats}
                  readOnly={
                    checkDisable("eft_letter") ||
                    getAccessTypePermission("location_rentmanager", "edit_access")
                  }
                />
                {editLetterBtn("eft_letter")}
                {errors && errors?.eft_letter && (
                  <CustomErrorMessage message={errors?.eft_letter} />
                )}
              </AccordionDetails>
            </Accordion>
          </MDBox>
        </MDBox>
      </MDBox>
    </Card>
  );
}

export default Correspondence;
