import Grid from "@mui/material/Grid";
import MDBox from "components/MDBox";
import MDLabelValue from "components/MDLabelValue";
import MDDialog from "components/MDDialog";

function UserDetails(props: any): JSX.Element {
  const { open, onClose, userData } = props;

  const dialogProps = {
    open,
    onClose,
    saveBtn: false,
    title: userData?.headerTitle || "User Details",
    size: "sm",
  };

  return (
    <MDDialog {...dialogProps}>
      <MDBox m={2}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <MDLabelValue label="First name" value={userData?.first_name || "-"} />
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <MDLabelValue label="Last Name" value={userData?.last_name || "-"} />
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <MDLabelValue label="Email" value={userData?.login_email || "-"} />
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <MDLabelValue label="Phone" value={userData?.sms_phone || "-"} />
          </Grid>
        </Grid>
      </MDBox>
    </MDDialog>
  );
}

export default UserDetails;
