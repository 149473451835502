import { Card, Grid, Icon, Typography } from "@mui/material";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";
import DataTable from "examples/Tables/DataTable";
import { searchRentManagerTenants, searchMatchingStylist } from "store/thunk/systemAdmin";
import { setRmSearchResultStylist, setMatchingStylist } from "store/slices/systemAdminSlice";
import { useAppDispatch, useAppSelector } from "store/store";
import { useState } from "react";
import { getLocalDateTime, phoneNumberformat } from "helper/services";
import MDIconButton from "components/MDIconButton";
import MDLabelValue from "components/MDLabelValue";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ViewDetails from "./viewDetails";

function RentManagerSearch(): JSX.Element {
  const dispatch = useAppDispatch();
  const { rmSearchResult, matchingStylist } = useAppSelector((state) => state.systemAdminSlice);
  const { loading } = useAppSelector((state) => state.authReducer);
  const [error, setError] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [detailViewData, setDetailViewData] = useState<any>(false);
  const [isShowMatchingRm, setIsShowMatchingRm] = useState(false);
  const [fullDetailData, setDetailData] = useState<any>(null);
  const [detailsPopup, setDetailsPopup] = useState<any>(false);
  const actionButtons = (object: any) => (
    <MDIconButton
      tooltipName="View"
      aria-label="Info"
      color="info"
      onClick={() => setDetailViewData(object)}
    >
      <Icon fontSize="small">visibility</Icon>
    </MDIconButton>
  );

  const getRows = () => {
    const data: any = [];
    if (rmSearchResult.length > 0) {
      rmSearchResult.forEach((obj: any) => {
        const objNew = {
          name: obj.name || "-",
          phone: obj.phone ? phoneNumberformat(obj.phone) : "-",
          email: obj.email || "-",
          location_id: obj.location_id || "-",
          status: obj.status || "-",
          action: actionButtons(obj),
        };
        data.push(objNew);
      });
    }
    return data;
  };
  const tableData = {
    columns: [
      { Header: "Name", accessor: "name", export: true },
      { Header: "Email address", accessor: "email", export: true },
      { Header: "Location ID", accessor: "location_id", export: true },
      { Header: "Phone Number", accessor: "phone", export: true },
      { Header: "Status", accessor: "status", export: true },
      { Header: "Action", accessor: "action", width: "50px", export: false, disableSortBy: true },
    ],
    rows: getRows(),
  };
  const searchData = (e: any) => {
    e.preventDefault();
    if (!searchValue) {
      setError(true);
    } else {
      const request = {
        search_text: searchValue,
      };
      dispatch(searchRentManagerTenants(request));
    }
  };
  const onChangeField = (e: any) => {
    setSearchValue(e.target.value);
    setError(false);
  };
  const resetSearch = () => {
    setSearchValue("");
    dispatch(setRmSearchResultStylist([]));
  };

  const onSearchMatchingRm = () => {
    if (detailViewData?.tenant_id) {
      setIsShowMatchingRm(true);
      dispatch(
        searchMatchingStylist({
          tenant_id: parseInt(detailViewData.tenant_id, 10),
          location_id: parseInt(detailViewData.location_id, 10),
        })
      );
    }
  };

  const onBack = () => {
    setDetailViewData(false);
    setIsShowMatchingRm(false);
    dispatch(setMatchingStylist([]));
  };

  const openDetailPopup = (data: any) => {
    setDetailData(data);
    setDetailsPopup(true);
  };

  const closeDetailPopup = () => {
    setDetailData("");
    setDetailsPopup(false);
  };

  const actionButtonsMatchingData = (object: any) => (
    <MDIconButton
      tooltipName="View"
      aria-label="Info"
      color="info"
      onClick={() => openDetailPopup(object)}
    >
      <Icon fontSize="small">visibility</Icon>
    </MDIconButton>
  );

  const getRowsMatchingStylistList = () => {
    const data: any = [];
    if (matchingStylist.length > 0) {
      matchingStylist.forEach((obj: any) => {
        const objNew = {
          id: obj.id || "-",
          name: obj.name || "-",
          email_address: obj.email_address || "-",
          phone_number: obj.phone_number ? phoneNumberformat(obj.phone_number) : "-",
          business_name: obj.business_name || "-",
          url_name: obj.url_name || "-",
          website_url: obj.website_url || "-",
          status: obj.status || "-",
          booking_url: obj.booking_url || "-",
          location_id: obj.location_id || "-",
          location_name: obj.location_name || "-",
          action: actionButtonsMatchingData(obj),
        };
        data.push(objNew);
      });
    }
    return data;
  };

  const matchingStylistData = {
    columns: [
      { Header: "ID", accessor: "id" },
      { Header: "Name", accessor: "name" },
      { Header: "Email address", accessor: "email_address" },
      { Header: "Phone Number", accessor: "phone_number" },
      { Header: "Business Name", accessor: "business_name" },
      { Header: "Url Name", accessor: "url_name" },
      { Header: "Website Url", accessor: "website_url" },
      { Header: "Status", accessor: "status" },
      { Header: "Booking Url", accessor: "booking_url" },
      { Header: "Location ID", accessor: "location_id" },
      { Header: "Location Name", accessor: "location_name" },
      { Header: "Action", accessor: "action", disableSortBy: true },
    ],
    rows: getRowsMatchingStylistList(),
  };

  return (
    <Card>
      {detailViewData && (
        <MDBox>
          <Grid container spacing={2}>
            <Grid item md={12} sx={{ padding: 0 }}>
              <MDButton onClick={() => onBack()} startIcon={<ArrowBackIosIcon />}>
                Back
              </MDButton>
            </Grid>
          </Grid>
        </MDBox>
      )}
      {!detailViewData && (
        <>
          <MDBox
            component="form"
            role="form"
            onSubmit={(e) => searchData(e)}
            mt={3}
            mb={1}
            padding={5}
          >
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12} md={12}>
                <Typography>Rent Manager</Typography>
              </Grid>
              <Grid item xs={12} sm={6} md={6}>
                <MDInput
                  label="Search"
                  variant="standard"
                  required
                  fullWidth
                  onChange={onChangeField}
                  name="title"
                  placeholder="Enter search text"
                  InputLabelProps={{ shrink: true }}
                  value={searchValue}
                  error={error}
                  helperText={error ? "This is field is required" : ""}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={6}>
                <MDButton
                  variant="gradient"
                  color="info"
                  size="small"
                  sx={{ marginTop: "5px" }}
                  type="submit"
                  disabled={loading}
                >
                  Search{" "}
                  <Icon sx={{ fontWeight: "bold", marginLeft: "4px", fontSize: "40px" }}>
                    search
                  </Icon>
                </MDButton>
                {"     "}
                <MDButton
                  variant="gradient"
                  color="dark"
                  size="small"
                  sx={{ marginTop: "5px" }}
                  onClick={() => resetSearch()}
                >
                  Reset
                </MDButton>
              </Grid>
            </Grid>
          </MDBox>
          <MDBox
            bgColor="dark"
            color="white"
            coloredShadow="dark"
            borderRadius="xl"
            alignItems="center"
            justifyContent="space-between"
            pt={1}
            pb={1}
            pl={2}
            pr={2}
            ml={2}
            mr={2}
            display="flex"
            mt={4}
            className="page-header"
          >
            <p className="page-header-label">Search Result</p>
          </MDBox>
          <DataTable
            showExportSelectedBtn={false}
            showExportAllBtn={false}
            table={tableData}
            canSearch
            searchText="Search..."
          />
        </>
      )}
      {detailViewData && (
        <>
          <MDBox mt={3} mb={1} padding={5}>
            <Grid container spacing={2}>
              <Grid item md={6}>
                <MDLabelValue label="Tenant ID" value={detailViewData?.tenant_id || "-"} />
              </Grid>
              <Grid item md={6}>
                <MDLabelValue label="Name" value={detailViewData?.name || "-"} />
              </Grid>
              <Grid item md={6}>
                <MDLabelValue label="First Name" value={detailViewData?.first_name || "-"} />
              </Grid>
              <Grid item md={6}>
                <MDLabelValue label="Last Name" value={detailViewData?.last_name || "-"} />
              </Grid>
              <Grid item md={6}>
                <MDLabelValue label="Email Address" value={detailViewData?.email || "-"} />
              </Grid>
              <Grid item md={6}>
                <MDLabelValue
                  label="Phone Number"
                  value={phoneNumberformat(detailViewData?.phone) || "-"}
                />
              </Grid>
              <Grid item md={6}>
                <MDLabelValue label="Location ID" value={detailViewData?.location_id || "-"} />
              </Grid>
              <Grid item md={6}>
                <MDLabelValue label="Property ID" value={detailViewData?.property_id || "-"} />
              </Grid>
              <Grid item md={6}>
                <MDLabelValue
                  label="Active Start Date"
                  value={getLocalDateTime(detailViewData?.active_start_date, "MM/DD/YYYY") || "-"}
                />
              </Grid>
              <Grid item md={6}>
                <MDLabelValue
                  label="Active End Date"
                  value={getLocalDateTime(detailViewData?.active_end_date, "MM/DD/YYYY") || "-"}
                />
              </Grid>
              <Grid item md={6}>
                <MDLabelValue
                  label="Last Transaction Date"
                  value={
                    getLocalDateTime(detailViewData?.last_transaction_date, "MM/DD/YYYY") || "-"
                  }
                />
              </Grid>
              <Grid item md={6}>
                <MDLabelValue
                  label="Last Payment Date"
                  value={getLocalDateTime(detailViewData?.last_payment_date, "MM/DD/YYYY") || "-"}
                />
              </Grid>
              <Grid item md={6}>
                <MDLabelValue
                  label="Created At"
                  value={getLocalDateTime(detailViewData?.created_at, "MM/DD/YYYY") || "-"}
                />
              </Grid>
              <Grid item md={6}>
                <MDLabelValue
                  label="Updated At"
                  value={getLocalDateTime(detailViewData?.updated_at, "MM/DD/YYYY") || "-"}
                />
              </Grid>
              <Grid item md={6}>
                <MDLabelValue label="Status" value={detailViewData?.status || "-"} />
              </Grid>
            </Grid>
          </MDBox>
          <MDBox padding={5} textAlign="left">
            <Grid item xs={12} sm={12} md={12}>
              <MDButton
                variant="gradient"
                color="info"
                size="small"
                sx={{ marginTop: "5px" }}
                onClick={() => onSearchMatchingRm()}
                disabled={loading}
              >
                Find Matching Stylist{" "}
                <Icon sx={{ fontWeight: "bold", marginLeft: "4px", fontSize: "40px" }}>search</Icon>
              </MDButton>
            </Grid>
          </MDBox>
          {detailsPopup && (
            <ViewDetails
              open={detailsPopup}
              updateData={fullDetailData}
              onClose={() => closeDetailPopup()}
            />
          )}
          {isShowMatchingRm && (
            <DataTable
              table={matchingStylistData}
              showExportSelectedBtn={false}
              showExportAllBtn={false}
              canSearch
              searchText="Search..."
            />
          )}
        </>
      )}
    </Card>
  );
}

export default RentManagerSearch;
