import { v4 as uuidv4 } from "uuid";
import { Grid, Chip } from "@mui/material";
import MDTypography from "components/MDTypography";
import MDBox from "components/MDBox";

function Index(props: any): JSX.Element | null {
  const { listData, name } = props;

  const getChips = (dataArray: any) => {
    let chips: any = [];
    if (dataArray && dataArray.length) {
      chips = dataArray.map((obj: any) => (
        <Chip
          label={obj.title ? obj.title : obj.name ? obj.name : ""}
          key={uuidv4()}
          className="chip-list-item"
        />
      ));
    }
    return chips;
  };

  return (
    <div>
      {listData && listData?.length > 0 && (
        <Grid container mt={1}>
          <Grid item m={0}>
            <MDTypography fontWeight="bold" variant="button">
              {name}
            </MDTypography>
            <MDBox>{getChips(listData)}</MDBox>
          </Grid>
        </Grid>
      )}
    </div>
  );
}
export default Index;
