import { Icon } from "@mui/material";
import ProfileOverview from "layouts/pages/profile/profile-overview";

import SamplePage from "layouts/pages/sample";
import ConnectUsers from "layouts/pages/system-admin/connect-users";
import ConnectAccess from "layouts/pages/system-admin/connect-access";
import ContactInquiries from "layouts/pages/system-admin/contact-inquiries";
import ContactInquiriesAddUpdate from "layouts/pages/system-admin/contact-inquiries/addUpdate";
import RolesMenus from "layouts/pages/roles-menus";
import LocationReview from "layouts/pages/locations/approval/locationReview";
import PhoneNumners from "layouts/pages/locations/phone-numbers";
import BookNowBooking from "layouts/pages/system-admin/book-now-booking";
import News from "layouts/pages/sola-website/news";
import Brands from "layouts/pages/mobile-app/brands";
import ClassImages from "layouts/pages/mobile-app/class-images";
import MysolaImage from "layouts/pages/system-admin/mysola-image";
import PartnerInquiries from "layouts/pages/system-admin/partner-inquiries";
import Reports from "layouts/pages/system-admin/reports";
import MaintenanceContacts from "layouts/pages/system-admin/maintenance-contact";
import UpdateMySolaWebsites from "layouts/pages/system-admin/update-my-sola-websites";
import StylistMessages from "layouts/pages/system-admin/stylist-messages";
import Msa from "layouts/pages/system-admin/msa";
import Sola10kImage from "layouts/pages/system-admin/sola10k-image";
import Testimonials from "layouts/pages/system-admin/testimonials";
import CallfireLogs from "layouts/pages/system-admin/callfire-logs";
import GlossGeniusLogs from "layouts/pages/system-admin/gloss-genius-log";
import Events from "layouts/pages/system-admin/nav-events";
import RentManagerUnits from "layouts/pages/system-admin/rm-units";

import StateRegions from "layouts/pages/mobile-app/state-regions";
import SideMenu from "layouts/pages/mobile-app/side-menu";
import RMtroubleshooting from "layouts/pages/system-admin/rent-manager-troubleshooting";
import Deals from "layouts/pages/mobile-app/deals";
import EducationHeroImages from "layouts/pages/mobile-app/education-hero-images";
import Categories from "layouts/pages/sola-website/categories";
import StylistUnits from "layouts/pages/system-admin/stylist-units";
import LegacyAdmin from "layouts/pages/system-admin/legacy-admin";
import FranchiseeInquiries from "layouts/pages/franchisee-website/inquiries";
import Locations from "layouts/pages/locations";
import Blogs from "layouts/pages/franchisee-website/blogs";
import LocationsApproval from "layouts/pages/locations/approval";
import Associations from "layouts/pages/associations";
import AssociationsDetails from "layouts/pages/associations/associationDetails";
import LocationDetails from "layouts/pages/locations/location-details";
import SolaPros from "layouts/pages/sola-pros";
import CmsSolaProsAddUpdate from "layouts/pages/sola-pros/addUpdate";
import SolaProsDetails from "layouts/pages/sola-pros/sola-pros-details";
import FacilityRequest from "layouts/pages/sola-pros/support/facilityRequest";
import CrmDeals from "layouts/pages/crm/deals";
import LeaseApplication from "layouts/pages/crm/leaseApplication";
import SalonCustomerLeads from "layouts/pages/crm/salonCustomerLeads";
import UnknownLeads from "layouts/pages/crm/unknownLeads";
import Lead from "layouts/pages/crm/deals/lead";
import HomeButtons from "layouts/pages/mobile-app/home-buttons";
import ProductInformation from "layouts/pages/mobile-app/product-information";
import SolaWebSiteBlog from "layouts/pages/sola-website/blog";
import SolaProSatisfactionSurvey from "layouts/pages/survey/sola-pro-satisfaction";
import HomeHeroImages from "layouts/pages/mobile-app/home-hero-images";
import Videos from "layouts/pages/mobile-app/videos";
import Regions from "layouts/pages/mobile-app/regions";
import ToolAndResources from "layouts/pages/mobile-app/tool-and-resources";
import Notifications from "layouts/pages/mobile-app/notifications";
import Externals from "layouts/pages/system-admin/externals";
import Tags from "layouts/pages/sola-website/tags";
import EventsAndClasses from "layouts/pages/mobile-app/events-and-classes";
import DownloadCenter from "layouts/pages/downloadCenter";
import Notification from "layouts/pages/notifications";
import CmsLocations from "layouts/pages/locations/cms-locations";
import CmsLocationAddUpdate from "layouts/pages/locations/cms-locations/addUpdate";
import Dashboard from "layouts/dashboards";
import Franchisee from "layouts/dashboards/franchisee";
import MarketingAdmin from "layouts/dashboards/marketing-admin";
import SolaProUser from "layouts/dashboards/sola-pro-user";
import { getAuthUser, userRoleAlias } from "helper/services";
import RMEvents from "layouts/pages/system-admin/rm-events";
import Faqs from "layouts/pages/system-admin/connect-faq";
import PhoneNumberDetail from "layouts/pages/locations/phone-numbers/phoneNumberDetail";
import Amenities from "layouts/pages/system-admin/amenities";
import SelfServiceHelpdesk from "layouts/pages/system-admin/sola-pro-help-desk";
import SolaWeb from "layouts/pages/sola-web";
import UpdateWebPageRequests from "layouts/pages/sola-web/update-web-page-requests";
import FranchiseeRmSync from "layouts/pages/FranchiseeRmSync";
import OccupancyDashboard from "layouts/pages/occupancyReport";
import WebPageAddUpdate from "layouts/pages/sola-web/web-page-details";
import RMPhoneNumbers from "layouts/pages/repair-maintenance/phone-numbers";
import RMPhoneNumberDetail from "layouts/pages/repair-maintenance/phone-numbers/phoneNumberDetail";
import ConnectSolaProsAddUpdate from "layouts/pages/sola-pros/connectAddUpdate";
import ScorecardDashboard from "layouts/pages/scorecard-dashboard";
import RMContacts from "layouts/pages/repair-maintenance/contacts";
import ReportsMasterData from "layouts/pages/reportsMasterData";
import RoyaltyDashboard from "layouts/pages/royaltyReport";
import OpenLocationDashboard from "layouts/pages/openLocationsDashboard";
import LeadsDashboard from "layouts/pages/leadsDashboard";
import ImportDeals from "layouts/pages/crm/deals/import-deals";
import ImportDealsData from "layouts/pages/crm/deals/import-deals/import-deals-data";
import AnalysisTracking from "layouts/pages/reports/analysis";

let dashboardAlias = userRoleAlias.SOLA_PRO;
const authUser = getAuthUser();
if (
  authUser &&
  authUser?.access &&
  authUser?.access?.find((obj: any) => obj.alias === userRoleAlias.FRANCHISEE)
) {
  dashboardAlias = userRoleAlias.FRANCHISEE;
}
if (
  authUser &&
  authUser?.access &&
  authUser?.access.find((obj: any) => obj.alias === userRoleAlias.MARKETING)
) {
  dashboardAlias = userRoleAlias.MARKETING;
}

const routes = [
  {
    key: dashboardAlias,
    alias: dashboardAlias,
    route: "/home",
    component: <Dashboard />,
    icon: <Icon fontSize="medium">dashboard</Icon>,
    type: "collapse",
    noCollapse: true,
  },
  {
    key: "dashboard-franchisee",
    alias: "dashboard_franchisee",
    route: "/home",
    component: <Franchisee />,
    icon: <Icon fontSize="medium">dashboard</Icon>,
    type: "no-collapse",
    noCollapse: true,
  },
  {
    key: "dashboard-solapro",
    alias: "dashboard_solapro",
    route: "/home",
    component: <SolaProUser />,
    icon: <Icon fontSize="medium">dashboard</Icon>,
    type: "no-collapse",
    noCollapse: true,
  },
  {
    key: "dashboard-marketing",
    alias: "dashboard_marketing",
    route: "/home",
    component: <MarketingAdmin />,
    icon: <Icon fontSize="medium">dashboard</Icon>,
    type: "no-collapse",
    noCollapse: true,
  },

  /* profile menu routes START */
  {
    key: "profile_menu",
    alias: "profile_menu",
    route: "/profile_menu",
    component: <SamplePage />,
    icon: <Icon fontSize="medium">account_circle</Icon>,
    type: "no-collapse",
    noCollapse: true,
  },
  {
    key: "my-account",
    alias: "profile_menu_myaccount",
    route: "/my-account",
    component: <ProfileOverview />,
    icon: <Icon fontSize="medium">account_circle</Icon>,
    type: "no-collapse",
    noCollapse: true,
  },
  {
    key: "reports_download_center",
    alias: "reports_download_center",
    route: "/reports/download-center",
    component: <DownloadCenter />,
    icon: <Icon fontSize="medium">cloud_download</Icon>,
    type: "no-collapse",
    noCollapse: true,
  },
  {
    key: "notifications",
    alias: "profile_menu_notifications",
    route: "/notifications",
    component: <Notification />,
    icon: <Icon fontSize="medium">cloud_download</Icon>,
    type: "no-collapse",
    noCollapse: true,
  },
  /* profile menu routes END */

  /* CRM menu routes START */
  {
    key: "crm",
    alias: "crm",
    route: "/crm",
    component: <SamplePage />,
    icon: <Icon fontSize="medium">group_add</Icon>,
    type: "collapse",
    noCollapse: true,
  },
  {
    key: "crm-deals",
    alias: "crm_deals",
    route: "/crm/sola-pro-leads",
    component: <Lead />,
    icon: <Icon fontSize="medium">assessment</Icon>,
    type: "collapse",
    noCollapse: true,
  },
  {
    key: "deals-lead",
    alias: "crm_deals",
    route: "/crm/sola-pro-leads/kanban",
    component: <CrmDeals />,
    icon: <Icon fontSize="medium">assessment</Icon>,
    type: "collapse",
    noCollapse: true,
  },
  {
    key: "crm-import-deals",
    alias: "crm_import_deals",
    route: "/crm/import-deals",
    component: <ImportDeals />,
    icon: <Icon fontSize="medium">assessment</Icon>,
    type: "collapse",
    noCollapse: true,
  },
  {
    key: "crm-import-deals",
    alias: "crm_import_deals",
    route: "/crm/import-deals/:id",
    component: <ImportDealsData />,
    icon: <Icon fontSize="medium">assessment</Icon>,
    type: "collapse",
    noCollapse: true,
  },
  {
    key: "lease-applications",
    alias: "crm_lease_application",
    route: "/crm/lease-applications",
    component: <LeaseApplication />,
    icon: <Icon fontSize="medium">assessment</Icon>,
    type: "collapse",
    noCollapse: true,
  },
  {
    key: "salon-customer-leads",
    alias: "crm_salon_customer_leads",
    route: "/crm/salon-customer-leads",
    component: <SalonCustomerLeads />,
    icon: <Icon fontSize="medium">assessment</Icon>,
    type: "collapse",
    noCollapse: true,
  },
  {
    key: "unknown-leads",
    alias: "crm_unknown_leads",
    route: "/crm/unknown-leads",
    component: <UnknownLeads />,
    icon: <Icon fontSize="medium">assessment</Icon>,
    type: "collapse",
    noCollapse: true,
  },

  /* CRM menu routes END */

  /* associations menu routes START */
  {
    key: "locations_associations",
    alias: "locations_associations",
    route: "/locations/associations",
    component: <Associations />,
    icon: <Icon fontSize="medium">group_work</Icon>,
    type: "collapse",
    noCollapse: true,
  },
  {
    key: "associations-details",
    alias: "locations_associations",
    route: "/locations/associations/:name",
    component: <AssociationsDetails />,
    icon: <Icon fontSize="medium">group_work</Icon>,
    type: "collapse",
    noCollapse: true,
  },
  /* associations menu routes END */

  /* location menu routes START */
  {
    key: "locations",
    alias: "locations",
    route: "/locations",
    icon: <Icon fontSize="medium">location_on</Icon>,
    type: "collapse",
    noCollapse: true,
  },
  {
    key: "locations-list",
    alias: "locations_location",
    route: "/locations",
    component: <Locations />,
    icon: <Icon fontSize="medium">location_on</Icon>,
    type: "collapse",
    noCollapse: true,
  },
  {
    key: "locations-approval",
    alias: "locations_approval",
    route: "/locations/approval",
    component: <LocationsApproval />,
    icon: <Icon fontSize="medium">location_on</Icon>,
    type: "collapse",
    noCollapse: true,
  },
  {
    key: "locations-phone-number",
    alias: "locations_phone_numbers",
    route: "/locations/phone-numbers",
    component: <PhoneNumners />,
    icon: <Icon fontSize="medium">location_on</Icon>,
    type: "collapse",
    noCollapse: true,
  },
  {
    key: "phone-number-edit",
    alias: "locations_phone_numbers",
    route: "/locations/phone-numbers/:name",
    component: <PhoneNumberDetail />,
    icon: <Icon fontSize="medium">group_work</Icon>,
    type: "collapse",
    noCollapse: true,
  },
  {
    key: "locations-review",
    alias: "locations_approval",
    route: "/locations/approval/:name",
    component: <LocationReview />,
    icon: <Icon fontSize="medium">location_on</Icon>,
    type: "collapse",
    noCollapse: true,
  },
  {
    key: "location-details",
    alias: "locations",
    route: "/locations/:name",
    component: <LocationDetails />,
    icon: <Icon fontSize="medium">location_on</Icon>,
    type: "collapse",
    noCollapse: true,
  },
  /* location menu routes END */

  /* reports menu routes start */
  {
    key: "reports",
    alias: "reports",
    route: "/reports",
    icon: <Icon fontSize="medium">assessment</Icon>,
    type: "collapse",
    noCollapse: true,
  },
  {
    key: "reports_Occupancy",
    alias: "reports_occupancy",
    route: "/reports/occupancy",
    component: <OccupancyDashboard />,
    icon: <Icon fontSize="medium">assessment</Icon>,
    type: "collapse",
    noCollapse: true,
  },
  {
    key: "reports_scorecard",
    alias: "reports_scorecard",
    route: "/reports/scorecard",
    component: <ScorecardDashboard />,
    icon: <Icon fontSize="medium">assessment</Icon>,
    type: "collapse",
    noCollapse: true,
  },
  {
    key: "reports_royalty",
    alias: "reports_royalty",
    route: "/reports/royalty",
    component: <RoyaltyDashboard />,
    icon: <Icon fontSize="medium">assessment</Icon>,
    type: "collapse",
    noCollapse: true,
  },
  {
    key: "reports_master_data",
    alias: "reports_master_data",
    route: "/reports/master-data",
    component: <ReportsMasterData />,
    icon: <Icon fontSize="medium">assessment</Icon>,
    type: "collapse",
    noCollapse: true,
  },
  {
    key: "reports_leads",
    alias: "reports_leads",
    route: "/reports/leads",
    component: <LeadsDashboard />,
    icon: <Icon fontSize="medium">assessment</Icon>,
    type: "collapse",
    noCollapse: true,
  },
  {
    key: "reports_open_locations",
    alias: "reports_open_locations",
    route: "/reports/open-locations",
    component: <OpenLocationDashboard />,
    icon: <Icon fontSize="medium">assessment</Icon>,
    type: "collapse",
    noCollapse: true,
  },
  /* reports
   menu routes end */

  /* sola web new menu */
  // {
  //   key: "sola_pros_webpages",
  //   alias: "sola_pros_webpages",
  //   route: "/sola-pros/web-pages",
  //   component: <SamplePage />,
  //   icon: <Icon fontSize="medium">web</Icon>,
  //   type: "collapse",
  //   noCollapse: true,
  // },
  {
    key: "sola_pros_webpages",
    alias: "sola_pros_webpages",
    route: "/sola-pros/web-pages",
    component: <SolaWeb />,
    icon: <Icon fontSize="medium">person</Icon>,
    type: "collapse",
    noCollapse: true,
  },
  {
    key: "sola_pros_webpages_details",
    alias: "sola_pros_webpages",
    route: "/sola-pros/web-pages/:name",
    component: <WebPageAddUpdate />,
    icon: <Icon fontSize="medium">manage_accounts</Icon>,
    type: "collapse",
    noCollapse: true,
  },
  /* sola web new menu */
  {
    key: "sola_pros_webpages_request",
    alias: "sola_pros_webpages_request",
    route: "/sola-pros/update-web-page-requests",
    component: <UpdateWebPageRequests />,
    icon: <Icon fontSize="medium">person</Icon>,
    type: "collapse",
    noCollapse: true,
  },

  /* sola pro menu routes START */
  {
    key: "sola-pros",
    alias: "sola_pros",
    route: "/sola-pros",
    icon: <Icon fontSize="medium">person</Icon>,
    type: "collapse",
    noCollapse: true,
  },
  {
    key: "sola-pros",
    alias: "sola_pros_sola_pros",
    route: "/sola-pros",
    component: <SolaPros />,
    icon: <Icon fontSize="medium">person</Icon>,
    type: "collapse",
    noCollapse: true,
  },
  {
    key: "sola-pros-details",
    alias: "sola_pros",
    route: "/sola-pros/:id",
    component: <SolaProsDetails />,
    icon: <Icon fontSize="medium">person</Icon>,
    type: "collapse",
    noCollapse: true,
  },
  {
    key: "sola-pros-support-details",
    alias: "sola_pros",
    route: "/sola-pros/:id/:reqId",
    component: <FacilityRequest />,
    icon: <Icon fontSize="medium">person</Icon>,
    type: "collapse",
    noCollapse: true,
  },
  {
    key: "create-sola-pros",
    alias: "sola_pros",
    route: "/sola-pros/create-stylist",
    component: <ConnectSolaProsAddUpdate />,
    icon: <Icon fontSize="medium">person</Icon>,
    type: "collapse",
    noCollapse: true,
  },
  /* sola pro menu routes END */

  /* sola website menu routes START */
  {
    key: "sola-website",
    alias: "sola_website",
    route: "/sola-website",
    component: <SamplePage />,
    icon: <Icon fontSize="medium">web</Icon>,
    type: "collapse",
    noCollapse: true,
  },
  {
    key: "sola-website-news",
    alias: "sola_website_news",
    route: "/sola-website/news",
    component: <News />,
    icon: <Icon fontSize="medium">manage_accounts</Icon>,
    type: "collapse",
    noCollapse: true,
  },
  {
    key: "sola-website-blog",
    alias: "sola_website_blog",
    route: "/sola-website/blog",
    component: <SolaWebSiteBlog />,
    icon: <Icon fontSize="medium">manage_accounts</Icon>,
    type: "collapse",
    noCollapse: true,
  },
  {
    key: "sola-website-categories",
    alias: "sola_website_categories",
    route: "/sola-website/categories",
    component: <Categories />,
    icon: <Icon fontSize="medium">manage_accounts</Icon>,
    type: "collapse",
    noCollapse: true,
  },
  {
    key: "sola-website-tags",
    alias: "sola_website_tags",
    route: "/sola-website/tags",
    component: <Tags />,
    icon: <Icon fontSize="medium">manage_accounts</Icon>,
    type: "collapse",
    noCollapse: true,
  },
  {
    key: "crm_contact_inquiries",
    alias: "crm_contact_inquiries",
    route: "/crm/contact-inquiries",
    component: <ContactInquiries />,
    icon: <Icon fontSize="medium">manage_accounts</Icon>,
    type: "collapse",
    noCollapse: true,
  },
  {
    key: "crm_inquiries_add_update",
    alias: "crm_contact_inquiries",
    route: "/crm/contact-inquiries/:name",
    component: <ContactInquiriesAddUpdate />,
    icon: <Icon fontSize="medium">manage_accounts</Icon>,
    type: "collapse",
    noCollapse: true,
  },
  {
    key: "sola-website-booking",
    alias: "sola_website_booking",
    route: "/sola-website/bookings",
    component: <BookNowBooking />,
    icon: <Icon fontSize="medium">manage_accounts</Icon>,
    type: "collapse",
    noCollapse: true,
  },
  /* sola website menu routes END */

  /* franchisee website menu routes START */
  {
    key: "franchisee-website",
    alias: "franchising_website",
    route: "/franchisee-website",
    component: <SamplePage />,
    icon: <Icon fontSize="medium">language</Icon>,
    type: "collapse",
    noCollapse: true,
  },
  {
    key: "franchising-website-news",
    alias: "franchising_website_news",
    route: "/franchisee-website/news",
    component: <SamplePage />,
    icon: <Icon fontSize="medium">manage_accounts</Icon>,
    type: "collapse",
    noCollapse: true,
  },
  {
    key: "sola_pros_press_blogs",
    alias: "sola_pros_press_blogs",
    route: "/sola-pros/press-&-blog",
    component: <Blogs />,
    icon: <Icon fontSize="medium">manage_accounts</Icon>,
    type: "collapse",
    noCollapse: true,
  },
  {
    key: "franchising-website-categories",
    alias: "franchising_website_categories",
    route: "/franchisee-website/categories",
    component: <SamplePage />,
    icon: <Icon fontSize="medium">manage_accounts</Icon>,
    type: "collapse",
    noCollapse: true,
  },
  {
    key: "franchising-website-tags",
    alias: "franchising_website_tags",
    route: "/franchisee-website/tags",
    component: <SamplePage />,
    icon: <Icon fontSize="medium">manage_accounts</Icon>,
    type: "collapse",
    noCollapse: true,
  },
  {
    key: "crm_franchisee_inquiries",
    alias: "crm_franchisee_inquiries",
    route: "/crm/franchisee-inquiries",
    component: <FranchiseeInquiries />,
    icon: <Icon fontSize="medium">manage_accounts</Icon>,
    type: "collapse",
    noCollapse: true,
  },
  /* franchisee website menu routes END */

  /* mobile app menu routes START */
  {
    key: "mobile-app",
    alias: "mobile_app",
    route: "/mobile-app",
    component: <SamplePage />,
    icon: <Icon fontSize="medium">app_settings_alt</Icon>,
    type: "collapse",
    noCollapse: true,
  },
  {
    key: "mobile-app-brands",
    alias: "mobile_app_brands",
    route: "/mobile-app/brands",
    component: <Brands />,
    icon: <Icon fontSize="medium">manage_accounts</Icon>,
    type: "collapse",
    noCollapse: true,
  },
  {
    key: "mobile_app_class_images",
    alias: "mobile_app_class_images",
    route: "/mobile-app/class-images",
    component: <ClassImages />,
    icon: <Icon fontSize="medium">manage_accounts</Icon>,
    type: "collapse",
    noCollapse: true,
  },
  {
    key: "mobile_app_deals",
    alias: "mobile_app_deals",
    route: "/mobile-app/deals",
    component: <Deals />,
    icon: <Icon fontSize="medium">manage_accounts</Icon>,
    type: "collapse",
    noCollapse: true,
  },
  {
    key: "mobile_app_edu_hero_images",
    alias: "mobile_app_edu_hero_images",
    route: "/mobile-app/education-hero-images",
    component: <EducationHeroImages />,
    icon: <Icon fontSize="medium">manage_accounts</Icon>,
    type: "collapse",
    noCollapse: true,
  },
  {
    key: "mobile_app_events_classes",
    alias: "mobile_app_events_classes",
    route: "/mobile-app/events-and-classes",
    component: <EventsAndClasses />,
    icon: <Icon fontSize="medium">manage_accounts</Icon>,
    type: "collapse",
    noCollapse: true,
  },
  {
    key: "mobile_app_home_btn",
    alias: "mobile_app_home_btn",
    route: "/mobile-app/home-buttons",
    component: <HomeButtons />,
    icon: <Icon fontSize="medium">manage_accounts</Icon>,
    type: "collapse",
    noCollapse: true,
  },
  {
    key: "mobile_app_home_hero_imgs",
    alias: "mobile_app_home_hero_imgs",
    route: "/mobile-app/home-hero-images",
    component: <HomeHeroImages />,
    icon: <Icon fontSize="medium">manage_accounts</Icon>,
    type: "collapse",
    noCollapse: true,
  },
  {
    key: "mobile_app_notifications",
    alias: "mobile_app_notifications",
    route: "/mobile-app/notifications",
    component: <Notifications />,
    icon: <Icon fontSize="medium">manage_accounts</Icon>,
    type: "collapse",
    noCollapse: true,
  },
  {
    key: "mobile_app_prod_info",
    alias: "mobile_app_prod_info",
    route: "/mobile-app/product-information",
    component: <ProductInformation />,
    icon: <Icon fontSize="medium">manage_accounts</Icon>,
    type: "collapse",
    noCollapse: true,
  },
  {
    key: "mobile_app_regions",
    alias: "mobile_app_regions",
    route: "/mobile-app/regions",
    component: <Regions />,
    icon: <Icon fontSize="medium">manage_accounts</Icon>,
    type: "collapse",
    noCollapse: true,
  },
  {
    key: "mobile_app_side_menu",
    alias: "mobile_app_side_menu",
    route: "/mobile-app/side-menu",
    component: <SideMenu />,
    icon: <Icon fontSize="medium">manage_accounts</Icon>,
    type: "collapse",
    noCollapse: true,
  },
  {
    key: "mobile_app_state_regions",
    alias: "mobile_app_state_regions",
    route: "/mobile-app/state-regions",
    component: <StateRegions />,
    icon: <Icon fontSize="medium">manage_accounts</Icon>,
    type: "collapse",
    noCollapse: true,
  },
  {
    key: "mobile_app_tools_resources",
    alias: "mobile_app_tools_resources",
    route: "/mobile-app/tools-and-resources",
    component: <ToolAndResources />,
    icon: <Icon fontSize="medium">manage_accounts</Icon>,
    type: "collapse",
    noCollapse: true,
  },
  {
    key: "mobile_app_videos",
    alias: "mobile_app_videos",
    route: "/mobile-app/videos",
    component: <Videos />,
    icon: <Icon fontSize="medium">manage_accounts</Icon>,
    type: "collapse",
    noCollapse: true,
  },
  /* mobile app menu routes END */

  /* System admin menu routes START */
  {
    key: "system-admin",
    alias: "system_admin",
    route: "/system-admin",
    component: <SamplePage />,
    icon: <Icon fontSize="medium">manage_accounts</Icon>,
    type: "collapse",
    noCollapse: true,
  },
  {
    key: "system_admin_rm_data_sync",
    alias: "system_admin_rm_data_sync",
    route: "/system-admin/rm-data-sync",
    component: <RMtroubleshooting />,
    icon: <Icon fontSize="medium">dvr</Icon>,
    type: "collapse",
    noCollapse: true,
  },
  {
    key: "system_admin_stylist_units",
    alias: "system_admin_stylist_units",
    route: "/system-admin/stylist-units",
    component: <StylistUnits />,
    icon: <Icon fontSize="medium">manage_accounts</Icon>,
    type: "collapse",
    noCollapse: true,
  },
  {
    key: "system_admin_nav_events",
    alias: "system_admin_nav_events",
    route: "/system-admin/nav-events",
    component: <Events />,
    icon: <Icon fontSize="medium">manage_accounts</Icon>,
    type: "collapse",
    noCollapse: true,
  },
  {
    key: "system_admin_rm_events",
    alias: "system_admin_rm_events",
    route: "/system-admin/rm-events",
    component: <RMEvents />,
    icon: <Icon fontSize="medium">manage_accounts</Icon>,
    type: "collapse",
    noCollapse: true,
  },
  {
    key: "system_admin_units",
    alias: "system_admin_units",
    route: "/system-admin/units",
    component: <RentManagerUnits />,
    icon: <Icon fontSize="medium">manage_accounts</Icon>,
    type: "collapse",
    noCollapse: true,
  },
  {
    key: "system_admin_external",
    alias: "system_admin_external",
    route: "/system-admin/externals",
    component: <Externals />,
    icon: <Icon fontSize="medium">manage_accounts</Icon>,
    type: "collapse",
    noCollapse: true,
  },
  {
    key: "sola_cms_legacy_accounts",
    alias: "sola_cms_legacy_accounts",
    route: "/sola-cms/legacy-accounts",
    component: <LegacyAdmin />,
    icon: <Icon fontSize="medium">manage_accounts</Icon>,
    type: "collapse",
    noCollapse: true,
  },
  {
    key: "system_admin_callfire_logs",
    alias: "system_admin_callfire_logs",
    route: "/system-admin/callfire-logs",
    component: <CallfireLogs />,
    icon: <Icon fontSize="medium">manage_accounts</Icon>,
    type: "collapse",
    noCollapse: true,
  },
  {
    key: "system_admin_new_relic_logs",
    alias: "system_admin_new_relic_logs",
    route: "/system-admin/new-relic-logs",
    component: <SamplePage />,
    icon: <Icon fontSize="medium">manage_accounts</Icon>,
    type: "collapse",
    noCollapse: true,
  },
  {
    key: "system_admin_gloss_logs",
    alias: "system_admin_gloss_logs",
    route: "/system-admin/gloss-logs",
    component: <GlossGeniusLogs />,
    icon: <Icon fontSize="medium">manage_accounts</Icon>,
    type: "collapse",
    noCollapse: true,
  },
  /* System admin menu routes END */

  /* users menu routes START */
  {
    key: "users",
    alias: "users",
    route: "/users-&-roles",
    component: <SamplePage />,
    icon: <Icon fontSize="medium">group</Icon>,
    type: "collapse",
    noCollapse: true,
  },
  {
    key: "users_manage",
    alias: "users_manage",
    route: "/users-&-roles/all-users",
    component: <ConnectUsers />,
    icon: <Icon fontSize="medium">manage_accounts</Icon>,
    type: "collapse",
    noCollapse: true,
  },
  {
    key: "users_manage_details",
    alias: "users_manage_details",
    route: "/users-&-roles/user-permissions",
    component: <ConnectAccess />,
    icon: <Icon fontSize="medium">manage_accounts</Icon>,
    type: "collapse",
    noCollapse: true,
  },
  {
    key: "users_roles",
    alias: "users_roles",
    route: "/users-&-roles/all-roles",
    component: <RolesMenus />,
    icon: <Icon fontSize="medium">manage_accounts</Icon>,
    type: "collapse",
    noCollapse: true,
  },
  /* users menu routes END */

  /* survey menu routes START */
  {
    key: "survey",
    alias: "survey",
    route: "/survey",
    component: <SamplePage />,
    icon: <Icon fontSize="medium">poll</Icon>,
    type: "collapse",
    noCollapse: true,
  },
  {
    key: "survey_solapro",
    alias: "survey_solapro",
    route: "/survey/survey-solapro",
    component: <SolaProSatisfactionSurvey />,
    icon: <Icon fontSize="medium">manage_accounts</Icon>,
    type: "collapse",
    noCollapse: true,
  },
  {
    key: "survey_franchisee",
    alias: "survey_franchisee",
    route: "/survey/survey-franchisee",
    component: <SolaProSatisfactionSurvey />,
    icon: <Icon fontSize="medium">manage_accounts</Icon>,
    type: "collapse",
    noCollapse: true,
  },
  /* survey menu routes END */

  /* sola cms menu routes START */
  {
    key: "sola-cms",
    alias: "sola_cms",
    route: "/sola-cms",
    component: <SamplePage />,
    icon: <Icon fontSize="medium">dvr</Icon>,
    type: "collapse",
    noCollapse: true,
  },
  {
    key: "locations_msa",
    alias: "locations_msa",
    route: "/locations/msa",
    component: <Msa />,
    icon: <Icon fontSize="medium">manage_accounts</Icon>,
    type: "collapse",
    noCollapse: true,
  },
  {
    key: "sola_cms_locations",
    alias: "sola_cms_locations",
    route: "/sola-cms/cms-locations",
    component: <CmsLocations myAccount={false} />,
    icon: <Icon fontSize="medium">manage_accounts</Icon>,
    type: "collapse",
    noCollapse: true,
  },
  {
    key: "sola_cms_locations_add_update",
    alias: "sola_cms_locations",
    route: "/sola-cms/cms-locations/:name",
    component: <CmsLocationAddUpdate />,
    icon: <Icon fontSize="medium">manage_accounts</Icon>,
    type: "collapse",
    noCollapse: true,
  },
  {
    key: "sola_cms_solapro",
    alias: "sola_cms_solapro",
    route: "/sola-cms/cms-sola-pros",
    component: <SolaPros />,
    icon: <Icon fontSize="medium">manage_accounts</Icon>,
    type: "collapse",
    noCollapse: true,
  },
  {
    key: "sola_cms_solapro",
    alias: "sola_cms_solapro",
    route: "/sola-cms/cms-sola-pros/:name",
    component: <CmsSolaProsAddUpdate />,
    icon: <Icon fontSize="medium">manage_accounts</Icon>,
    type: "collapse",
    noCollapse: true,
  },
  {
    key: "sola_cms_partner_inquiries",
    alias: "sola_cms_partner_inquiries",
    route: "/sola-cms/partner-inquiries",
    component: <PartnerInquiries />,
    icon: <Icon fontSize="medium">manage_accounts</Icon>,
    type: "collapse",
    noCollapse: true,
  },
  {
    key: "reports_cms_reports",
    alias: "reports_cms_reports",
    route: "/reports/cms-reports",
    component: <Reports />,
    icon: <Icon fontSize="medium">manage_accounts</Icon>,
    type: "collapse",
    noCollapse: true,
  },
  {
    key: "system_admin_sola_10k_imgs",
    alias: "system_admin_sola_10k_imgs",
    route: "/system-admin/sola10k-images",
    component: <Sola10kImage />,
    icon: <Icon fontSize="medium">manage_accounts</Icon>,
    type: "collapse",
    noCollapse: true,
  },
  {
    key: "sola_pros_stylist_messages",
    alias: "sola_pros_stylist_messages",
    route: "/sola-pros/stylist-messages",
    component: <StylistMessages />,
    icon: <Icon fontSize="medium">manage_accounts</Icon>,
    type: "collapse",
    noCollapse: true,
  },
  {
    key: "sola_cms_update_my_sola",
    alias: "sola_cms_update_my_sola",
    route: "/sola-cms/update-my-sola",
    component: <UpdateMySolaWebsites />,
    icon: <Icon fontSize="medium">manage_accounts</Icon>,
    type: "collapse",
    noCollapse: true,
  },
  {
    key: "system_admin_sola_images",
    alias: "system_admin_sola_images",
    route: "/system-admin/my-sola-images",
    component: <MysolaImage />,
    icon: <Icon fontSize="medium">manage_accounts</Icon>,
    type: "collapse",
    noCollapse: true,
  },
  // New FAQ page
  {
    key: "system_admin_faq",
    alias: "system_admin_faq",
    route: "/system-admin/faqs",
    component: <Faqs />,
    icon: <Icon fontSize="medium">manage_accounts</Icon>,
    type: "collapse",
    noCollapse: true,
  },
  // New Amenities page
  {
    key: "system_admin_amenities",
    alias: "system_admin_amenities",
    route: "/system-admin/amenities",
    component: <Amenities />,
    icon: <Icon fontSize="medium">manage_accounts</Icon>,
    type: "collapse",
    noCollapse: true,
  },
  {
    key: "system_admin_self_service_helpdesk",
    alias: "system_admin_self_service_helpdesk",
    route: "/system-admin/self-service-helpdesk",
    component: <SelfServiceHelpdesk />,
    icon: <Icon fontSize="medium">manage_accounts</Icon>,
    type: "collapse",
    noCollapse: true,
  },
  {
    key: "sola_cms_maintenance_contacts",
    alias: "sola_cms_maintenance_contacts",
    route: "/sola-cms/maintenance-contacts",
    component: <MaintenanceContacts />,
    icon: <Icon fontSize="medium">manage_accounts</Icon>,
    type: "collapse",
    noCollapse: true,
  },
  {
    key: "sola_pros_testimonials",
    alias: "sola_pros_testimonials",
    route: "/sola-pros/testimonials",
    component: <Testimonials />,
    icon: <Icon fontSize="medium">dvr</Icon>,
    type: "collapse",
    noCollapse: true,
  },
  /* sola cms menu routes END */

  /* RM sync menu for Franchisee START */
  {
    key: "franchisee_rm_sync",
    alias: "franchisee_rm_sync",
    route: "/franchisee-rm-sync",
    component: <FranchiseeRmSync />,
    icon: <Icon fontSize="medium">autorenew</Icon>,
    type: "collapse",
    noCollapse: true,
  },
  /* RM sync menu for Franchisee END */
  {
    key: "r_m",
    alias: "r_m",
    route: "/r&m",
    component: <SamplePage />,
    icon: <Icon fontSize="medium">construction</Icon>,
    type: "collapse",
    noCollapse: true,
  },
  {
    key: "r_m_phone_numbers",
    alias: "r_m_phone_numbers",
    route: "/r&m/phone-numbers",
    component: <RMPhoneNumbers />,
    icon: <Icon fontSize="medium">construction</Icon>,
    type: "collapse",
    noCollapse: true,
  },
  {
    key: "r_m_phone_numbers",
    alias: "r_m_phone_numbers",
    route: "/r&m/phone-numbers/:number",
    component: <RMPhoneNumberDetail />,
    icon: <Icon fontSize="medium">construction</Icon>,
    type: "collapse",
    noCollapse: true,
  },
  {
    key: "r_m_contacts",
    alias: "r_m_contacts",
    route: "/r&m/contacts",
    component: <RMContacts />,
    icon: <Icon fontSize="medium">construction</Icon>,
    type: "collapse",
    noCollapse: true,
  },
  {
    key: "connect_lead_tracking",
    alias: "connect_lead_tracking",
    route: "/reports/analytics",
    component: <AnalysisTracking />,
    icon: <Icon fontSize="medium">construction</Icon>,
    type: "collapse",
    noCollapse: true,
  },
];

export default routes;
