import { Grid, TextareaAutosize, IconButton, Paper } from "@mui/material";
import MDBox from "components/MDBox";
import MDInput from "components/MDInput";
import MDTypography from "components/MDTypography";
import Add from "@mui/icons-material/Add";
import MDButton from "components/MDButton";
import CloseIcon from "@mui/icons-material/Close";
import { v4 as uuidv4 } from "uuid";

function Testimonials(props: any): JSX.Element {
  const { accountDetail, addNewElement, onChangeMultipleField, removeElement } = props;
  return (
    <Paper>
      <MDBox p={2} mt={2}>
        <MDBox mb={2}>
          <Grid container>
            <Grid item md={6}>
              <MDTypography variant="h5" fontWeight="regular">
                Testimonials
              </MDTypography>
            </Grid>
            <Grid item md={6} textAlign="right">
              <MDButton
                onClick={() => addNewElement("testimonials")}
                variant="outlined"
                color="dark"
                size="small"
                startIcon={<Add />}
              >
                Add
              </MDButton>
            </Grid>
          </Grid>
        </MDBox>
        <Grid container spacing={3} justifyContent="center">
          {accountDetail?.testimonials &&
            accountDetail?.testimonials.length > 0 &&
            accountDetail?.testimonials.map((_val: any, index: number) => (
              <Grid item container key={uuidv4()}>
                <Paper sx={{ width: "100%", padding: "5px 10px" }} elevation={3}>
                  <Grid item md={12} textAlign="right">
                    <IconButton
                      aria-label="delete"
                      onClick={() => removeElement("testimonials", index)}
                    >
                      <CloseIcon />
                    </IconButton>
                  </Grid>
                  <Grid item md={12} container spacing={3}>
                    <Grid item md={6} sm={6} xs={12}>
                      <MDInput
                        label="Name"
                        variant="standard"
                        fullWidth
                        onChange={(e: any) => onChangeMultipleField(e, index, "testimonials")}
                        name="name"
                        placeholder="name"
                        value={
                          accountDetail?.testimonials ? accountDetail?.testimonials[index].name : ""
                        }
                      />
                    </Grid>

                    <Grid item md={6} sm={6} xs={12}>
                      <MDInput
                        label="Region"
                        variant="standard"
                        fullWidth
                        name="region"
                        onChange={(e: any) => onChangeMultipleField(e, index, "testimonials")}
                        placeholder="Region"
                        value={
                          accountDetail?.testimonials
                            ? accountDetail?.testimonials[index].region
                            : ""
                        }
                      />
                    </Grid>
                    <Grid item md={12} xs={12}>
                      <TextareaAutosize
                        style={{ width: "100%", height: "100px", marginTop: "10px" }}
                        name="text"
                        maxRows={4}
                        placeholder="Text"
                        onChange={(e: any) => onChangeMultipleField(e, index, "testimonials")}
                        value={
                          accountDetail?.testimonials ? accountDetail?.testimonials[index].text : ""
                        }
                      />
                    </Grid>
                  </Grid>
                </Paper>
              </Grid>
            ))}
        </Grid>
      </MDBox>
    </Paper>
  );
}

export default Testimonials;
