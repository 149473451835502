import { Divider, List, ListItem, ListItemText, Popover, Typography } from "@mui/material";
import MDBox from "components/MDBox";
import AddIcon from "@mui/icons-material/Add";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import { getSMSTemplateList } from "store/thunk/locationThunk";
import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "store/store";
import { getAssociationLocations } from "helper/services";
import AddSMSTemplate from "layouts/pages/locations/location-details/crm/template-settings/add-sms-template";

function Index(props: any): JSX.Element | null {
  const assignedLocationsToUser = getAssociationLocations();
  const { anchorEl, handleClose, setSMStext, leadInfo } = props;
  const { smsTemplateList } = useAppSelector((state) => state.locationSlice);
  const [templateList, setTemplateList] = useState([]);
  const [isViewAll, setIsViewAll] = useState(false);
  const [templateSearchTxt, setTemplateSearchTxt] = useState("");
  const [addTemplatePopup, setAddTemplatePopup] = useState(false);
  const dispatch = useAppDispatch();
  const open = Boolean(anchorEl);
  const id = open ? "sms-template-popover" : undefined;

  useEffect(() => {
    dispatch(
      getSMSTemplateList({
        locationIds:
          assignedLocationsToUser?.get_location_by_ids &&
          assignedLocationsToUser?.get_location_by_ids?.length > 0
            ? assignedLocationsToUser?.get_location_by_ids
            : null,
      })
    );
  }, []);

  useEffect(() => {
    if (smsTemplateList && smsTemplateList?.length) {
      const filterdByLocatioId = smsTemplateList.filter(
        (val: any) => Number(leadInfo?.location_id) === Number(val.location_id)
      );
      if (isViewAll) {
        setTemplateList(smsTemplateList);
      } else {
        setTemplateList(filterdByLocatioId);
      }
    }
  }, [smsTemplateList]);

  const setTemplate = (tempalteContent: any) => {
    setSMStext(tempalteContent);
    handleClose();
  };
  const onClickView = () => {
    const tempView = isViewAll;
    setIsViewAll(!tempView);
    if (smsTemplateList && smsTemplateList?.length) {
      const filterdByLocatioId = smsTemplateList.filter(
        (val: any) => Number(leadInfo?.location_id) === Number(val.location_id)
      );
      if (!tempView) {
        setTemplateList(smsTemplateList);
      } else {
        setTemplateList(filterdByLocatioId);
      }
    }
  };
  const handleSearchTemplate = (searchString: any) => {
    const searchedTemplates: any = templateList.filter((template: any) =>
      template?.template_name?.toLowerCase().includes(searchString?.toLowerCase())
    );
    setTemplateSearchTxt(searchString);
    setTemplateList(searchedTemplates);
    if (!searchString) {
      const filterdByLocatioId = smsTemplateList.filter(
        (val: any) => Number(leadInfo?.location_id) === Number(val.location_id)
      );
      if (isViewAll) {
        setTemplateList(smsTemplateList);
      } else {
        setTemplateList(filterdByLocatioId);
      }
    }
  };

  return (
    <Popover
      id={id}
      open={open}
      anchorEl={anchorEl}
      onClose={handleClose}
      anchorOrigin={{
        vertical: "top",
        horizontal: "left",
      }}
      transformOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
      className="sms-template-popover"
    >
      <MDBox
        sx={{
          display: "flex",
          flexWrap: "wrap",
          "& > :not(style)": {
            m: 1,
            width: 128,
            height: 128,
          },
        }}
      >
        <MDBox className="sms-template-header">
          <MDTypography className="sms-header-title">Message Templates</MDTypography>
          <MDButton
            variant="contained"
            color="white"
            className="xs-small"
            size="small"
            startIcon={<AddIcon />}
            onClick={() => setAddTemplatePopup(true)}
          >
            create
          </MDButton>
        </MDBox>
        <MDBox className="sms-template-body">
          <List dense>
            {!templateList.length && (
              <Typography component="span" variant="body2" color="text.primary">
                No templates found this location
              </Typography>
            )}
            {templateList.map((template: any) => (
              <>
                <ListItem onClick={() => setTemplate(template?.template_content)}>
                  {/* <Tooltip
                    title={template?.template_content ? template?.template_content : ""}
                    placement="top"
                  > */}
                  <ListItemText
                    primary={
                      <Typography
                        sx={{ display: "inline" }}
                        component="span"
                        variant="body2"
                        color="text.primary"
                      >
                        {template?.template_name}
                      </Typography>
                    }
                    secondary={
                      <div className="sms-template-content">{template?.template_content}</div>
                    }
                  />
                  {/* </Tooltip> */}
                </ListItem>
                <Divider />
              </>
            ))}
          </List>
        </MDBox>
        <MDBox alignItems="left" textAlign="left">
          <MDButton
            variant="text"
            color="dark"
            size="small"
            sx={{ padding: 0, fontSize: "14px", color: "#227bea", textTransform: "capitalize" }}
            onClick={onClickView}
          >
            {!isViewAll ? "View all" : "View less"}
          </MDButton>
        </MDBox>
        <MDBox>
          <MDInput
            value={templateSearchTxt}
            onChange={(event: any) => {
              handleSearchTemplate(event?.target?.value);
            }}
            placeholder="Search"
            name="template_name"
          />
        </MDBox>
      </MDBox>
      {addTemplatePopup && (
        <AddSMSTemplate
          open={addTemplatePopup}
          isUpdate={false}
          onClose={() => setAddTemplatePopup(false)}
          location={{ cms_location_id: Number(leadInfo?.location_id) }}
        />
      )}
    </Popover>
  );
}
export default Index;
