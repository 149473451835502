import MDBox from "components/MDBox";
import LocationHistoryList from "layouts/pages/locations/location-details/location-history/location-history-list";

function Index(props?: any): JSX.Element {
  const { locationId, getAccessTypePermission } = props;

  return (
    <MDBox mt={3}>
      <LocationHistoryList
        locationId={locationId}
        accessDisabled={!getAccessTypePermission("location_history", "edit_access")}
      />
    </MDBox>
  );
}

export default Index;
