import { createSlice, Draft, PayloadAction } from "@reduxjs/toolkit";

interface InitialState {
  menus: Array<[]>;
  roles: Array<[]>;
  roleDetails: Array<[]>;
  roleSelected: string;
  additionalDetails: Array<[]>;
}

const initialState: InitialState = {
  menus: [],
  roles: [],
  roleDetails: [],
  roleSelected: "",
  additionalDetails: [],
};

const rolesAndMenusSlice = createSlice({
  name: "rolesAndMenus",
  initialState,
  reducers: {
    menuList: (state: Draft<InitialState>, action: PayloadAction<any>) => ({
      ...state,
      menus: action.payload,
    }),
    roleList: (state: Draft<InitialState>, action: PayloadAction<any>) => ({
      ...state,
      roles: action.payload,
    }),
    roleDetails: (state: Draft<InitialState>, action: PayloadAction<any>) => ({
      ...state,
      roleDetails: action.payload,
    }),
    roleSelected: (state: Draft<InitialState>, action: PayloadAction<any>) => ({
      ...state,
      roleSelected: action.payload,
    }),
    // Set additionl detials
    additionalDetails: (state: Draft<InitialState>, action: PayloadAction<any>) => ({
      ...state,
      additionalDetails: action.payload,
    }),
  },
});

export const { menuList, roleList, roleDetails, roleSelected, additionalDetails } =
  rolesAndMenusSlice.actions;

export default rolesAndMenusSlice.reducer;
