import { useEffect, useRef, useState, useCallback, useLayoutEffect } from "react";
import { Card, Checkbox, Icon } from "@mui/material";
import MDBox from "components/MDBox";
import Messages from "helper/messages";
import MDButton from "components/MDButton";
import exportData from "helper/exportTableConst";
import { useAppDispatch, useAppSelector } from "store/store";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import DataTable from "examples/Tables/DataTable";
import MDIconButton from "components/MDIconButton";
import AddUpdate from "layouts/pages/associations/addUpdate";
import { deleteAssociation, getAssociationList } from "store/thunk/associationThunk";
import { useNavigate } from "react-router-dom";
import {
  userRoles,
  displayMultipleValue,
  displayMultipleValueWithName,
  checkUserAssociationOwnersAndAdmin,
  getUrlName,
  isFranchisie,
  setDefaultSelectedColumns,
} from "helper/services";
import MDDialog from "components/MDDialog";
import MDTypography from "components/MDTypography";
import { setDefaultsColumns } from "store/slices/connectUsersSlice";
import { getDefaultCoulmns, saveSelectedAllData } from "store/thunk/connectUserThunk";
import ViewDetails from "./viewDetails";

function Index(): JSX.Element {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { associationList } = useAppSelector((state) => state.associationSlice);
  const { userData } = useAppSelector((state) => state.authReducer);
  const [addUpdatePopup, setaddUpdatePopup] = useState<boolean>(false);
  const [associations, setAssociations] = useState([]);
  const [pageCount, setPageCount] = useState(0);
  const [pageNo, setPageNo] = useState(0);
  const [perPage, setPageSize] = useState(10);
  const [totalCount, setTotalCount] = useState(0);
  const [search, setSearch] = useState<any>(null);
  const fetchIdRef = useRef(0);
  const [viewPopup, setViewPopup] = useState(false);
  const [viewData, setViewData] = useState({});
  const [deleteId, setDeleteId] = useState<any>(null);
  const [selectedItems, setSelectedItems] = useState([]);
  const [isCheckAll, setIsCheckAll] = useState(false);
  const [displayColumns, setDisplayColumns] = useState<any>([]);
  const [defaultExport, setDefaultExport] = useState<any>([]);
  const [callFetch, setCallFetch] = useState<any>(false);
  const [defaultSorting, setDefaultSorting] = useState<any>([]);
  const [defaultFilteing, setDefaultFilteing] = useState<any>([]);
  const [sortBy, setSortBy] = useState<any>([]);

  const getColumns = () => {
    const columns: any = [
      {
        Header: "",
        disableSortBy: true,
        accessor: "checkbox",
        export: false,
        width: "35px",
      },
      { Header: "Id", accessor: "id", width: "50px", export: true },
      { Header: "Association name", accessor: "association_name", width: "150px", export: true },
      {
        Header: "Locations",
        accessor: "locations",
        export: true,
        width: "30%",
        disableSortBy: true,
        hide_columns: true,
      },
      {
        Header: "Owners",
        accessor: "owners",
        export: true,
        disableSortBy: true,
        hide_columns: true,
      },
      { Header: "Users", accessor: "users", export: true, disableSortBy: true, hide_columns: true },
    ];
    if (
      userData?.role === userRoles.SYSTEM_ADMIN ||
      userData?.role === userRoles.ADMIN ||
      isFranchisie()
    ) {
      columns.push({
        Header: "Action",
        accessor: "action",
        width: "150px",
        export: false,
        disableSortBy: true,
      });
    }
    return columns;
  };

  const [checkedColumns, setCheckedColumns] = useState<any>(getColumns());

  const latestRefValue = useRef(checkedColumns);

  useEffect(() => {
    latestRefValue.current = checkedColumns;
  }, [checkedColumns]);

  useEffect(() => {
    if (checkedColumns?.length) {
      setDisplayColumns(checkedColumns.filter((col: any) => col.checked));
    }
  }, [checkedColumns]);

  useEffect(() => {
    if (associationList && associationList?.data) {
      setAssociations(associationList?.data);
      setPageCount(associationList?.meta?.total_pages);
      setTotalCount(associationList?.meta?.total_count);
    }
  }, [associationList]);

  const onChangeCheckBox = (e: any) => {
    let items = JSON.parse(JSON.stringify(selectedItems));
    if (e.target.checked && !items.includes(e.target.value)) {
      items.push(e.target.value);
    } else {
      items = items.filter((id: any) => id !== e.target.value);
      setIsCheckAll(false);
    }
    setSelectedItems(items);
  };

  const fetchData = useCallback(({ pageSize, pageIndex, search, sortBy, filterData, isManual }) => {
    const fetchId = ++fetchIdRef.current;
    const sort: any = sortBy || [];

    // We'll even set a delay to simulate a server here
    setTimeout(() => {
      if (fetchId === fetchIdRef.current) {
        const sentReq = {
          sendData: {
            user_id: userData?.user_id,
            table_name: exportData.connect_associations.table_name,
            table_columns: latestRefValue.current ? latestRefValue.current : checkedColumns,
            table_sort: sortBy && sortBy?.length ? sortBy : [],
            table_filter: filterData && filterData?.length ? filterData : [],
            default_export_data: defaultExport,
          },
        };
        if (isManual) {
          dispatch(saveSelectedAllData(sentReq));
        }

        setSortBy(sort);
        setPageNo(pageIndex);
        setSearch(search);
        setPageSize(pageSize);
        // setSelectedItems([]);
        setIsCheckAll(false);
        dispatch(getAssociationList({ pageSize, pageIndex, search, sort, filterData }));
      }
    }, 1000);
  }, []);

  useLayoutEffect(() => {
    dispatch(setDefaultsColumns([]));
    // Set data in variable and Call defaultColums Thunk
    const sentReq = {
      user_id: userData?.user_id,
      table_name: exportData.connect_associations.table_name,
    };
    dispatch(getDefaultCoulmns(sentReq)).then((resp: any) => {
      // SD-3199
      if (resp?.payload) {
        const respFilter = resp?.payload?.table_filter || [];
        const respSorting = resp?.payload?.table_sort || [];
        const defaultDBexportData = resp?.payload?.default_export_data || [];
        const defaultDBColumn = resp?.payload?.table_columns || [];
        const tempdata = setDefaultSelectedColumns(defaultDBColumn, getColumns());
        setCheckedColumns(tempdata);
        setDefaultExport(defaultDBexportData);
        setDefaultSorting(respSorting);
        setSortBy(respSorting);
        setDefaultFilteing(respFilter);
        setCallFetch(true);
        fetchData({
          perPage,
          pageNo,
          search,
          sortBy: respSorting && respSorting?.length ? respSorting : [],
          additionalField: null,
          filterData: respFilter && respFilter?.length ? respFilter : [],
        });
      }
    });
  }, []);

  const navigateToEdit = (locationName: any, associationId: any) => {
    navigate(`/locations/associations/${getUrlName(locationName)}`, { state: { associationId } });
  };

  const actionButtons = (locationName: string, id: any, row: any) => (
    <>
      <MDIconButton
        tooltipName="View Association"
        aria-label="Open"
        color="info"
        onClick={() => {
          setViewData(row);
          setViewPopup(true);
        }}
      >
        <Icon fontSize="small">visibility</Icon>
      </MDIconButton>
      <MDIconButton
        tooltipName="Edit Association"
        aria-label="Open"
        color="info"
        onClick={() => navigateToEdit(locationName, id)}
      >
        <Icon fontSize="small">edit</Icon>
      </MDIconButton>
      <MDIconButton
        tooltipName="Delete Association"
        aria-label="Delete"
        color="error"
        onClick={() => setDeleteId(id)}
      >
        <Icon fontSize="small">delete</Icon>
      </MDIconButton>
    </>
  );

  const getRows = () => {
    const data: any = associations.map((obj: any) => ({
      checkbox: (
        <Checkbox
          checked={selectedItems.includes(obj.id.toString())}
          onChange={(e: any) => onChangeCheckBox(e)}
          value={obj.id}
        />
      ),
      id: obj?.id,
      association_name: obj?.association_name ? (
        <MDTypography
          sx={{ fontSize: 14, cursor: "pointer", fontWeight: "bold" }}
          onClick={() => navigateToEdit(obj.association_name, obj.id)}
        >
          {obj.association_name}
        </MDTypography>
      ) : (
        "-"
      ),
      bussiness_name: obj?.bussiness_name || "-",
      locations: obj?.locations ? displayMultipleValue(obj?.locations) : "-",
      owners: obj?.owners ? displayMultipleValueWithName(obj?.owners, "first_name") : "-",
      users: obj?.user_list ? displayMultipleValueWithName(obj?.user_list, "first_name") : "-",
      action: checkUserAssociationOwnersAndAdmin(obj)
        ? actionButtons(obj?.association_name, obj.id, obj)
        : "",
    }));
    return data;
  };

  const tableData = {
    columns: getColumns(),
    rows: getRows(),
  };

  // Start Remove selcted items
  const removeSelectedItem = (deletedId: number) => {
    setSelectedItems(selectedItems.filter((item: any) => Number(item) !== deletedId));
  };
  // End Remove selcted items

  const onDelete = () => {
    if (deleteId) {
      dispatch(
        deleteAssociation({
          setDeleteId,
          deleteData: { association_id: deleteId },
          pageNo,
          perPage,
          search,
          sortBy,
          removeSelectedItem,
        })
      );
      setDeleteId(false);
    }
  };

  const deleteDialogProps = {
    open: deleteId,
    onClose: () => setDeleteId(false),
    onSave: () => onDelete(),
    title: "Delete Association",
    size: "sm",
    saveBtn: false,
    deleteBtn: true,
    saveTbtText: "Yes",
    closeBtnText: "No",
  };

  const checkUncheckAll = (e: any) => {
    let allIds: any = [];
    allIds = associations.map((val: any) => String(val.id));
    if (e.target.checked) {
      const selectedIds = [...new Set([...selectedItems, ...allIds])];
      setSelectedItems(selectedIds);
      setIsCheckAll(true);
    } else {
      allIds = associations.map((val: any) => String(val.id));
      allIds = selectedItems.filter((el: any) => !allIds.includes(el));
      setSelectedItems([...allIds]);
      setIsCheckAll(false);
    }
    // setSelectedItems(allIds);
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={4} pb={3}>
        <Card>
          <MDBox
            bgColor="dark"
            color="white"
            coloredShadow="dark"
            borderRadius="xl"
            alignItems="center"
            justifyContent="space-between"
            pt={1}
            pb={1}
            pl={2}
            pr={2}
            ml={2}
            mr={2}
            display="flex"
            className="page-header"
            mt={-3}
          >
            <p className="page-header-label">Associations</p>
            {(userData?.role === userRoles.SYSTEM_ADMIN || userData?.role === userRoles.ADMIN) && (
              <MDButton
                variant="gradient"
                color="light"
                size="small"
                className="xs-small"
                onClick={() => setaddUpdatePopup(true)}
              >
                <Icon sx={{ fontWeight: "bold" }}>add</Icon> Add New
              </MDButton>
            )}
          </MDBox>
          {(defaultSorting || defaultSorting?.length) && callFetch && (
            <DataTable
              defaultSortingColumns={defaultSorting}
              defaultFilteringColums={defaultFilteing}
              defaultDisplayColumns={displayColumns}
              setCheckedColumns={setCheckedColumns}
              table={tableData}
              canSearch
              searchText="Search..."
              pageCount={pageCount}
              pageNo={pageNo}
              totalCount={totalCount}
              fetchData={fetchData}
              selectedItems={selectedItems}
              showExportSelectedBtn
              showExportAllBtn
              exportFields={exportData.connect_associations.export_fields}
              exportTable={exportData.connect_associations.table_name}
              exportType={exportData.connect_associations.export_type}
              manualSearch
              manualSort
              isDisplayCheckAllCheckbox
              onChangeCheckAllCheckbox={checkUncheckAll}
              isCheckAll={isCheckAll}
              advanceFilter
            />
          )}
        </Card>
        {addUpdatePopup && (
          <AddUpdate
            open={addUpdatePopup ? true : false}
            onClose={() => setaddUpdatePopup(false)}
            perPage={perPage}
            pageNo={pageNo}
          />
        )}
        {viewPopup && (
          <ViewDetails
            open={viewPopup}
            associationData={viewData}
            onClose={() => {
              setViewPopup(false);
            }}
          />
        )}
        {deleteId && (
          <MDDialog {...deleteDialogProps}>
            <MDTypography variant="h6" fontWeight="medium">
              {Messages.GENERAL.SURE_TO_DELETE}
            </MDTypography>
          </MDDialog>
        )}
      </MDBox>
    </DashboardLayout>
  );
}

export default Index;
