import React, { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "store/store";

// Images
import bgImage from "assets/images/bg-reset-cover.jpeg";
import { Card, ListItem, ListItemIcon, ListItemText, InputLabel } from "@mui/material";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
// import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import CustomLoader from "components/CustomLoader";
import CloseIcon from "@mui/icons-material/Close";
import OtpInput from "react-otp-input";
import { useNavigate } from "react-router-dom";

// Authentication layout components
import Validations from "helper/validations";
import {
  verifyUser,
  updatePassword,
  updateForgotPassword,
  checkUserInDatabase,
} from "store/thunk/authThunk";
import CustomPasswordInput from "components/CustomPasswordInput";
import CoverLayout from "../components/CoverLayout";
import Messages from "../../../helper/messages";
import config from "../../../config/config";
import SimpleLoader from "../../../components/MDLoader/simpleLoader";

function CreatePassword(): JSX.Element {
  const appDispatch = useAppDispatch();
  const navigate = useNavigate();
  const notificationInfo = useAppSelector((state) => state.notificationReducer);
  const { checkUser } = useAppSelector((state) => state.authReducer);
  const [code, setCode] = useState<any>("");
  const [password, setPassword] = useState<string>("");
  const [confirmPassword, setConfirmPassword] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);
  const [passwordError, setPasswordError] = useState<any>({
    length: Messages.ERROR.PASSWORD_LENGTH,
    number: Messages.ERROR.PASSWORD_NUMBER,
    uppercase: Messages.ERROR.PASSWORD_UPPERCASE,
    lowercase: Messages.ERROR.PASSWORD_LOWERCASE,
    special: Messages.ERROR.PASSWORD_SPECIAL,
    confirm: Messages.ERROR.PASSWORD_CONFIRM,
  });
  const search = new URLSearchParams(window.location.search);
  const email = search.get("email") ? decodeURIComponent(search.get("email")) : "";
  const forgotPassword = search.get("forgot") || false;
  const verifyAccount = search.get("verify") || false;
  const [tempLoader, setTempLoader] = useState<boolean>(false);

  const onChangePassword = (password?: string) => {
    setPassword(password);
    const error = Validations.validatePassword(password, confirmPassword);
    setPasswordError(error);
  };

  const onChangeConfirmPassword = (cnfpassword: string) => {
    setConfirmPassword(cnfpassword);
    const error = Validations.validatePassword(password, cnfpassword);
    setPasswordError(error);
  };

  const checkDisabled = () => {
    let disabled = false;
    if (Object.keys(passwordError).length) {
      disabled = true;
    }
    if (forgotPassword && code.length < 6) {
      disabled = true;
    }
    if (password !== confirmPassword) {
      disabled = true;
    }
    return disabled;
  };

  const onSubmit = (e: any) => {
    e.preventDefault();
    setLoading(true);
    if (forgotPassword) {
      const request = {
        email,
        password,
        code,
        navigate,
      };
      appDispatch(updateForgotPassword(request));
    } else {
      const request = {
        email,
        oldPassword: config.DEFAULT_PASSWORD,
        newPassword: password,
      };
      appDispatch(updatePassword(request));
    }
  };

  useEffect(() => {
    if (verifyAccount) {
      setTempLoader(true);
      const verifyRequest = {
        email,
        navigate,
      };
      appDispatch(checkUserInDatabase(verifyRequest));
    }
  }, [verifyAccount]);

  useEffect(() => {
    if (checkUser) {
      setTempLoader(false);
      if (!checkUser.verified) {
        const verifyRequest = {
          email,
        };
        appDispatch(verifyUser(verifyRequest));
      }
    }
  }, [checkUser]);

  useEffect(() => {
    if (notificationInfo.message) {
      setLoading(false);
    }
  }, [notificationInfo]);

  return (
    <CoverLayout coverHeight="50vh" image={bgImage}>
      {!tempLoader ? (
        <Card>
          <MDBox>
            <MDBox
              variant="gradient"
              bgColor="info"
              borderRadius="lg"
              coloredShadow="success"
              mx={2}
              mt={-3}
              py={2}
              mb={1}
              textAlign="center"
            >
              <MDTypography variant="h3" fontWeight="medium" color="white" mt={1}>
                Create Password
              </MDTypography>
            </MDBox>
            <MDBox pt={4} pb={3} px={3}>
              <MDBox component="form" role="form" onSubmit={(e) => onSubmit(e)}>
                {forgotPassword && (
                  <div style={{ textAlign: "center" }}>
                    <div style={{ display: "inline-flex", textAlign: "center" }}>
                      <MDBox mb={4}>
                        <InputLabel>Verification code</InputLabel>
                        <OtpInput
                          value={code}
                          onChange={(value: any) => setCode(value)}
                          numInputs={6}
                          separator={<span>-</span>}
                          inputStyle={{ height: "35px", width: "35px", margin: "3px" }}
                          isInputNum
                        />
                      </MDBox>
                    </div>
                  </div>
                )}
                <MDBox>
                  <CustomPasswordInput
                    label="Password"
                    name="password"
                    value={password}
                    placeholder="************"
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                      onChangePassword(e.target.value)
                    }
                  />
                </MDBox>
                <MDBox mt={1}>
                  <CustomPasswordInput
                    label="Confirm Password"
                    name="confirm_password"
                    value={confirmPassword}
                    placeholder="************"
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                      onChangeConfirmPassword(e.target.value)
                    }
                  />
                </MDBox>
                <MDBox mt={2} mb={2}>
                  {passwordError && passwordError.length && (
                    <ListItem>
                      <ListItemIcon style={{ minWidth: "30px" }}>
                        <CloseIcon color="error" />
                      </ListItemIcon>
                      <ListItemText
                        primary={
                          <MDTypography variant="subtitle2" style={{ fontSize: "14px" }}>
                            {Messages.ERROR.PASSWORD_LENGTH}
                          </MDTypography>
                        }
                      />
                    </ListItem>
                  )}
                  {passwordError && passwordError.number && (
                    <ListItem>
                      <ListItemIcon style={{ minWidth: "30px" }}>
                        <CloseIcon color="error" />
                      </ListItemIcon>
                      <ListItemText
                        primary={
                          <MDTypography variant="subtitle2" style={{ fontSize: "14px" }}>
                            {Messages.ERROR.PASSWORD_NUMBER}
                          </MDTypography>
                        }
                      />
                    </ListItem>
                  )}
                  {passwordError && passwordError.uppercase && (
                    <ListItem>
                      <ListItemIcon style={{ minWidth: "30px" }}>
                        <CloseIcon color="error" />
                      </ListItemIcon>
                      <ListItemText
                        primary={
                          <MDTypography variant="subtitle2" style={{ fontSize: "14px" }}>
                            {Messages.ERROR.PASSWORD_UPPERCASE}
                          </MDTypography>
                        }
                      />
                    </ListItem>
                  )}
                  {passwordError && passwordError.lowercase && (
                    <ListItem>
                      <ListItemIcon style={{ minWidth: "30px" }}>
                        <CloseIcon color="error" />
                      </ListItemIcon>
                      <ListItemText
                        primary={
                          <MDTypography variant="subtitle2" style={{ fontSize: "14px" }}>
                            {Messages.ERROR.PASSWORD_LOWERCASE}
                          </MDTypography>
                        }
                      />
                    </ListItem>
                  )}
                  {passwordError && passwordError.special && (
                    <ListItem>
                      <ListItemIcon style={{ minWidth: "30px" }}>
                        <CloseIcon color="error" />
                      </ListItemIcon>
                      <ListItemText
                        primary={
                          <MDTypography variant="subtitle2" style={{ fontSize: "14px" }}>
                            {Messages.ERROR.PASSWORD_SPECIAL}
                          </MDTypography>
                        }
                      />
                    </ListItem>
                  )}
                  {confirmPassword && passwordError.confirm && (
                    <ListItem>
                      <ListItemIcon style={{ minWidth: "30px" }}>
                        <CloseIcon color="error" />
                      </ListItemIcon>
                      <ListItemText
                        primary={
                          <MDTypography variant="subtitle2" style={{ fontSize: "14px" }}>
                            {Messages.ERROR.PASSWORD_CONFIRM}
                          </MDTypography>
                        }
                      />
                    </ListItem>
                  )}
                </MDBox>
                <MDBox>
                  <MDButton
                    variant="gradient"
                    color="info"
                    fullWidth
                    type="submit"
                    disabled={checkDisabled()}
                  >
                    {loading ? <SimpleLoader size={20} /> : "Create Password"}
                  </MDButton>
                </MDBox>
              </MDBox>
            </MDBox>
          </MDBox>
        </Card>
      ) : (
        <MDBox textAlign="center">
          <CustomLoader />
        </MDBox>
      )}
    </CoverLayout>
  );
}

export default CreatePassword;
