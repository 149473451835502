import { useState, useEffect, useRef, useCallback, useLayoutEffect } from "react";
import { Card, Checkbox, Grid, Icon } from "@mui/material";

import MDBox from "components/MDBox";

import DataTable from "examples/Tables/DataTable";
import { getUpdateMyWebsiteList, deleteWebsite } from "store/thunk/systemAdmin";
import { useAppDispatch, useAppSelector } from "store/store";
import {
  adminSuperAdmin,
  getAssociationLocations,
  phoneNumberformat,
  setDefaultSelectedColumns,
} from "helper/services";
import Messages from "helper/messages";
import exportData from "helper/exportTableConst";
import MDDialog from "components/MDDialog";
import MDTypography from "components/MDTypography";
import ImagePreview from "layouts/pages/ImagePreview";
import MDIconButton from "components/MDIconButton";
import { getDefaultCoulmns, saveSelectedAllData } from "store/thunk/connectUserThunk";
import { setDefaultsColumns } from "store/slices/connectUsersSlice";
import AddUpdate from "./addUpdate";
import ViewDetails from "./viewDetails";

function ApprovedList(): JSX.Element {
  const dispatch = useAppDispatch();
  const { updateSolaWebsitesList } = useAppSelector((state) => state.systemAdminSlice);
  const { defaultSelectedColumns } = useAppSelector((state) => state.connectUsersReducer);
  const { userData } = useAppSelector((state) => state.authReducer);
  const [selectedItems, setSelectedItems] = useState([]);
  const [updateWebsites, setUpdateWebsites] = useState([]);
  const [pageCount, setPageCount] = useState(0);
  const [pageNo, setPageNo] = useState(0);
  const [perPage, setPerPage] = useState(0);
  const [totalCount, setTotalCount] = useState(0);
  const [updateData, setUpdateData] = useState<any>(null);
  const [addUpdatePopup, setAddUpdatePopup] = useState<any>(false);
  const [viewPopup, setViewPopup] = useState<any>(false);
  const [websiteId, setWebsiteId] = useState<any>(false);
  const [search, setSearch] = useState<any>(null);
  const [sortBy, setSortBy] = useState<any>([]);

  const [isPreview, setImagePreview] = useState(false);
  const [imageToPreview, setImageToPreview] = useState("");
  const [isCheckAll, setIsCheckAll] = useState(false);
  const fetchIdRef = useRef(0);
  const [displayColumns, setDisplayColumns] = useState<any>([]);
  // SD-3199
  const [defaultExport, setDefaultExport] = useState<any>([]);
  const [callFetch, setCallFetch] = useState<any>(false);
  const [defaultSorting, setDefaultSorting] = useState<any>([]);
  const [defaultFilteing, setDefaultFilteing] = useState<any>([]);

  // Get columns
  const getColumns = () => {
    const columns: any = [
      {
        Header: "",
        disableSortBy: true,
        accessor: "checkbox",
        export: false,
        width: "100px",
        checked: true,
      },
      { Header: "Name", accessor: "name", export: true, disableCheck: true, checked: true },
      { Header: "First Name", accessor: "f_name", export: true, checked: true },
      { Header: "Middle Name", accessor: "m_name", export: true, checked: true },
      { Header: "Last Name", accessor: "l_name", export: true, checked: true },
      { Header: "Biography", accessor: "biography", export: true, checked: true },
      { Header: "Phone Number", accessor: "phone_number", export: true, checked: true },
      {
        Header: "Email Address",
        accessor: "email_address",
        export: true,
        checked: false,
      },
      { Header: "Business Name", accessor: "business_name", export: true },
      { Header: "Work Hours", accessor: "work_hours", export: true },
      { Header: "Website Url", accessor: "website_url", export: true },
      { Header: "Booking Url", accessor: "booking_url", export: true },
      { Header: "Reserved", accessor: "reserved", export: true, checked: false, is_boolean: true },
      { Header: "Facebook Url", accessor: "facebook_url", export: true },
      { Header: "Google Plus Url", accessor: "google_plus_url", export: true },
      { Header: "Instagram Url", accessor: "instagram_url", export: true },
      { Header: "Linkedin Url", accessor: "linkedin_url", export: true },
      { Header: "Pinterest Url", accessor: "pinterest_url", export: true },
      { Header: "Twitter Url", accessor: "twitter_url", export: true },
      { Header: "Yelp Url", accessor: "yelp_url", export: true },
      { Header: "Tik Tok Url", accessor: "tik_tok_url", export: true },
      { Header: "Barber", accessor: "barber", export: true, checked: false, is_boolean: true },
      {
        Header: "Botox/Fillers",
        accessor: "botox",
        export: true,
        checked: false,
        is_boolean: true,
      },
      { Header: "Brows", accessor: "brows", export: true, checked: false, is_boolean: true },
      { Header: "Hair", accessor: "hair", export: true, checked: false, is_boolean: true },
      {
        Header: "Hair Extensions",
        accessor: "hair_extensions",
        export: true,
        checked: false,
        is_boolean: true,
      },
      {
        Header: "Laser Hair Removal",
        accessor: "laser_hair_removal",
        export: true,
        checked: false,
        is_boolean: true,
      },
      {
        Header: "Lashes",
        accessor: "eyelash_extensions",
        export: true,
        checked: false,
        is_boolean: true,
      },
      { Header: "Makeup", accessor: "makeup", export: true, checked: false, is_boolean: true },
      { Header: "Massage", accessor: "massage", export: true, checked: false, is_boolean: true },
      { Header: "Nails", accessor: "nails", export: true, checked: false, is_boolean: true },
      {
        Header: "Permanent Mackup",
        accessor: "permanent_makeup",
        export: true,
        checked: false,
        is_boolean: true,
      },
      { Header: "Skincare", accessor: "skin", export: true, checked: false, is_boolean: true },
      { Header: "Tanning", accessor: "tanning", export: true, checked: false, is_boolean: true },
      {
        Header: "Teeth Whitening",
        accessor: "teeth_whitening",
        export: true,
        checked: false,
        is_boolean: true,
      },
      {
        Header: "Threading",
        accessor: "threading",
        export: true,
        checked: false,
        is_boolean: true,
      },
      { Header: "Waxing", accessor: "waxing", export: true, checked: false, is_boolean: true },
      {
        Header: "Other",
        accessor: "other_service",
        export: true,
        checked: false,
        is_boolean: true,
      },
      {
        Header: "Testimonial #1",
        accessor: "testimonial_id_1",
        export: true,
        checked: false,
        associative_colums: "testimonials.name",
      },
      {
        Header: "Testimonial #2",
        accessor: "testimonial_id_2",
        export: true,
        checked: false,
        associative_colums: "testimonials.name",
      },
      {
        Header: "Testimonial #3",
        accessor: "testimonial_id_3",
        export: true,
        checked: false,
        associative_colums: "testimonials.name",
      },
      {
        Header: "Testimonial #4",
        accessor: "testimonial_id_4",
        export: true,
        checked: false,
        associative_colums: "testimonials.name",
      },
      {
        Header: "Testimonial #5",
        accessor: "testimonial_id_5",
        export: true,
        checked: false,
        associative_colums: "testimonials.name",
      },
      {
        Header: "Testimonial #6",
        accessor: "testimonial_id_6",
        export: true,
        checked: false,
        associative_colums: "testimonials.name",
      },
      {
        Header: "Testimonial #7",
        accessor: "testimonial_id_7",
        export: true,
        checked: false,
        associative_colums: "testimonials.name",
      },
      {
        Header: "Testimonial #8",
        accessor: "testimonial_id_8",
        export: true,
        checked: false,
        associative_colums: "testimonials.name",
      },
      {
        Header: "Testimonial #9",
        accessor: "testimonial_id_9",
        export: true,
        checked: false,
        associative_colums: "testimonials.name",
      },
      {
        Header: "Testimonial #10",
        accessor: "testimonial_id_10",
        export: true,
        checked: false,
        associative_colums: "testimonials.name",
      },
      { Header: "Image #1", accessor: "image_1", export: true, checked: false, hide_columns: true },
      { Header: "Image #2", accessor: "image_2", export: true, checked: false, hide_columns: true },
      { Header: "Image #3", accessor: "image_3", export: true, checked: false, hide_columns: true },
      { Header: "Image #4", accessor: "image_4", export: true, checked: false, hide_columns: true },
      { Header: "Image #5", accessor: "image_5", export: true, checked: false, hide_columns: true },
      { Header: "Image #6", accessor: "image_6", export: true, checked: false, hide_columns: true },
      { Header: "Image #7", accessor: "image_7", export: true, checked: false, hide_columns: true },
      { Header: "Image #8", accessor: "image_8", export: true, checked: false, hide_columns: true },
      { Header: "Image #9", accessor: "image_9", export: true, checked: false, hide_columns: true },
      {
        Header: "Image #10",
        accessor: "image_10",
        export: true,
        checked: false,
        hide_columns: true,
      },
      { Header: "Approved", accessor: "approved", export: true, checked: false, is_boolean: true },
      {
        Header: "Action",
        accessor: "action",
        width: "200px",
        export: false,
        checked: true,
        disableSortBy: true,
      },
    ];
    return columns;
  };

  const [checkedColumns, setCheckedColumns] = useState<any>(getColumns());
  const latestRefValue = useRef(checkedColumns);

  useEffect(() => {
    latestRefValue.current = checkedColumns;
  }, [checkedColumns]);

  useEffect(() => {
    if (checkedColumns?.length) {
      setDisplayColumns(checkedColumns.filter((col: any) => col.checked));
    }
  }, [checkedColumns]);

  const fetchData = useCallback(({ pageSize, pageIndex, search, sortBy, filterData }) => {
    const fetchId = ++fetchIdRef.current;
    const sort: any = sortBy || [];
    // We'll even set a delay to simulate a server here
    setTimeout(() => {
      if (fetchId === fetchIdRef.current) {
        // SD-3199
        if (
          (sortBy && sortBy?.length) ||
          (filterData && filterData?.length) ||
          sortBy?.length === 0
        ) {
          const sentReq = {
            sendData: {
              user_id: userData?.user_id,
              table_name: `${exportData.update_my_sola_websites.table_name}_approved`,
              table_columns: latestRefValue.current ? latestRefValue.current : checkedColumns,
              table_sort: sortBy,
              table_filter: filterData,
              default_export_data: defaultExport,
            },
          };
          dispatch(saveSelectedAllData(sentReq));
        }
        setPageNo(pageIndex);
        setSearch(search);
        setSortBy(sort);
        setPerPage(pageSize);
        // setSelectedItems([]);
        setIsCheckAll(false);
        dispatch(
          getUpdateMyWebsiteList({
            status: "pending",
            pageSize,
            pageIndex,
            search,
            sort,
            userData,
            filterData,
          })
        );
      }
    }, 1000);
  }, []);

  useLayoutEffect(() => {
    dispatch(setDefaultsColumns([]));
    // Set data in variable and Call defaultColums Thunk
    const sentReq = {
      user_id: userData?.user_id,
      table_name: `${exportData.update_my_sola_websites.table_name}_approved`,
    };
    dispatch(getDefaultCoulmns(sentReq)).then((resp: any) => {
      // SD-3199
      const respFilter = resp?.payload?.table_filter || [];
      const respSorting = resp?.payload?.table_sort || [];
      const defaultDBexportData = resp?.payload?.default_export_data || [];
      const defaultDBColumn = resp?.payload?.table_columns || [];
      const tempdata = setDefaultSelectedColumns(defaultDBColumn, getColumns());
      setCheckedColumns(tempdata);
      setDefaultExport(defaultDBexportData);
      setDefaultSorting(respSorting);
      setSortBy(respSorting);
      setDefaultFilteing(respFilter);
      if (resp?.payload) {
        setCallFetch(true);
        fetchData({
          perPage,
          pageNo,
          search,
          sortBy: respSorting && respSorting?.length ? respSorting : [],
          additionalField: null,
          filterData: respFilter && respFilter?.length ? respFilter : [],
        });
      }
    });
  }, []);

  useEffect(() => {
    if (updateSolaWebsitesList && updateSolaWebsitesList?.sola_websites) {
      setUpdateWebsites(updateSolaWebsitesList?.sola_websites);
      setPageCount(updateSolaWebsitesList?.meta?.total_pages);
      setTotalCount(updateSolaWebsitesList?.meta?.total_count);
    }
  }, [updateSolaWebsitesList]);

  useEffect(() => {
    if (defaultSelectedColumns && defaultSelectedColumns?.length) {
      const newColumns = setDefaultSelectedColumns(defaultSelectedColumns, getColumns());
      setCheckedColumns(newColumns);
    } else {
      setCheckedColumns(getColumns());
    }
  }, [defaultSelectedColumns]);

  const closeModal = () => {
    setUpdateData("");
    setViewPopup(false);
    setAddUpdatePopup(false);
  };

  const onDelete = () => {
    if (websiteId) {
      const delReq = {
        id: websiteId,
        setWebsiteId,
        status: "approved",
        pageNo,
        perPage,
        search,
        sortBy,
      };
      dispatch(deleteWebsite(delReq));
    }
  };

  const deleteDialogProps = {
    open: websiteId,
    onClose: () => setWebsiteId(false),
    onSave: () => onDelete(),
    title: "Delete Update My Sola Website",
    size: "sm",
    saveBtn: false,
    deleteBtn: true,
    saveTbtText: "Yes",
    closeBtnText: "No",
  };

  const actionButtons = (object: any) => (
    <>
      <MDIconButton
        tooltipName="View"
        aria-label="Info"
        color="info"
        onClick={() => {
          setViewPopup(true);
          setUpdateData(object);
        }}
      >
        <Icon fontSize="small">visibility</Icon>
      </MDIconButton>
      {/* <MDIconButton
        tooltipName="Edit"
        aria-label="Edit"
        color="info"
        onClick={() => openUpdateSolaWebsiteEditModal(object)}
      >
        <Icon fontSize="small">edit</Icon>
      </MDIconButton>
      <MDIconButton
        tooltipName="Delete"
        aria-label="Delete"
        color="error"
        onClick={() => setWebsiteId(object.id)}
      >
        <Icon fontSize="small">delete</Icon>
      </MDIconButton> */}
    </>
  );

  const onChangeCheckBox = (e: any) => {
    let items = JSON.parse(JSON.stringify(selectedItems));
    if (e.target.checked && !items.includes(e.target.value)) {
      items.push(e.target.value);
      if (items.length === updateWebsites.length) {
        setIsCheckAll(true);
      }
    } else {
      items = items.filter((id: any) => id !== e.target.value);
      setIsCheckAll(false);
    }
    setSelectedItems(items);
  };

  const checkUncheckAll = (e: any) => {
    let allIds: any = [];
    allIds = updateWebsites.map((val: any) => String(val.id));
    if (e.target.checked) {
      const selectedIds = [...new Set([...selectedItems, ...allIds])];
      setSelectedItems(selectedIds);
      setIsCheckAll(true);
    } else {
      allIds = updateWebsites.map((val: any) => String(val.id));
      allIds = selectedItems.filter((el: any) => !allIds.includes(el));
      setSelectedItems([...allIds]);
      setIsCheckAll(false);
      // allIds = [];
      // setIsCheckAll(false);
    }
    // setSelectedItems(allIds);
  };

  const previewImage = (image: any) => {
    setImagePreview(true);
    setImageToPreview(image);
  };

  const closeImagePreview = () => {
    setImagePreview(false);
    setImageToPreview("");
  };

  const getImage = (imageUrl: any) => (
    <Grid container>
      <Grid item>
        {imageUrl && (
          <Grid
            container
            onClick={() => previewImage(imageUrl)}
            justifyContent="center"
            alignItems="center"
            textAlign="center"
            className="thumbnail-img"
          >
            <img width="100%" height="100px" src={imageUrl} alt="original" />
          </Grid>
        )}
      </Grid>
    </Grid>
  );

  const renderCheckmark = (value: any) =>
    value ? (
      <Icon fontSize="medium" color="success">
        check_circle_rounded
      </Icon>
    ) : (
      <Icon fontSize="medium" color="error">
        cancel
      </Icon>
    );

  const getRows = () => {
    const data: any = updateWebsites.map((obj: any) => ({
      checkbox: (
        <Checkbox
          checked={selectedItems.includes(obj.id.toString())}
          onChange={(e: any) => onChangeCheckBox(e)}
          value={obj.id}
        />
      ),
      id: obj.id || "-",
      name: obj.name || "-",
      f_name: obj.f_name || "-",
      m_name: obj.m_name || "-",
      l_name: obj.l_name || "-",
      biography: (
        <MDBox className="update-my-sola-biography-text">
          {obj.biography ? obj.biography.replace(/(<([^>]+)>)/gi, "") : "-"}
        </MDBox>
      ),
      phone_number: obj.phone_number ? phoneNumberformat(obj.phone_number) : "-",
      email_address: obj.email_address || "-",
      business_name: obj.business_name || "-",
      work_hours: obj.work_hours || "-",
      website_url: obj.website_url || "-",
      booking_url: obj.booking_url || "-",
      reserved: renderCheckmark(obj.reserved) || "-",
      facebook_url: obj.facebook_url || "-",
      google_plus_url: obj.google_plus_url || "-",
      instagram_url: obj.instagram_url || "-",
      linkedin_url: obj.linkedin_url || "-",
      pinterest_url: obj.pinterest_url || "-",
      twitter_url: obj.twitter_url || "-",
      yelp_url: obj.yelp_url || "-",
      tik_tok_url: obj.tik_tok_url || "-",
      barber: renderCheckmark(obj.barber) || "-",
      botox: renderCheckmark(obj.botox) || "-",
      brows: renderCheckmark(obj.brows) || "-",
      hair: renderCheckmark(obj.hair) || "-",
      hair_extensions: renderCheckmark(obj.hair_extensions) || "-",
      laser_hair_removal: renderCheckmark(obj.laser_hair_removal) || "-",
      eyelash_extensions: renderCheckmark(obj.lashes) || "-",
      makeup: renderCheckmark(obj.makeup) || "-",
      massage: renderCheckmark(obj.massage) || "-",
      nails: renderCheckmark(obj.nails) || "-",
      permanent_makeup: renderCheckmark(obj.permanent_makeup) || "-",
      skin: renderCheckmark(obj.skin) || "-",
      tanning: renderCheckmark(obj.tanning) || "-",
      teeth_whitening: renderCheckmark(obj.teeth_whitening) || "-",
      threading: renderCheckmark(obj.threading) || "-",
      waxing: renderCheckmark(obj.waxing) || "-",
      other_service: obj.other_service || "-",
      testimonial_id_1: obj.testimonial_id_1 ? obj.testimonials?.testimonial_1?.name || "-" : "-",
      testimonial_id_2: obj.testimonial_id_2 ? obj.testimonials?.testimonial_2?.name || "-" : "-",
      testimonial_id_3: obj.testimonial_id_3 ? obj.testimonials?.testimonial_3?.name || "-" : "-",
      testimonial_id_4: obj.testimonial_id_4 ? obj.testimonials?.testimonial_4?.name || "-" : "-",
      testimonial_id_5: obj.testimonial_id_5 ? obj.testimonials?.testimonial_5?.name || "-" : "-",
      testimonial_id_6: obj.testimonial_id_6 ? obj.testimonials?.testimonial_6?.name || "-" : "-",
      testimonial_id_7: obj.testimonial_id_7 ? obj.testimonials?.testimonial_7?.name || "-" : "-",
      testimonial_id_8: obj.testimonial_id_8 ? obj.testimonials?.testimonial_8?.name || "-" : "-",
      testimonial_id_9: obj.testimonial_id_9 ? obj.testimonials?.testimonial_9?.name || "-" : "-",
      testimonial_id_10: obj.testimonial_id_10
        ? obj.testimonials?.testimonial_10?.name || "-"
        : "-",
      image_1: obj.update_my_sola__image_1_url ? getImage(obj.update_my_sola__image_1_url) : "-",
      image_2: obj.update_my_sola__image_2_url ? getImage(obj.update_my_sola__image_2_url) : "-",
      image_3: obj.update_my_sola__image_3_url ? getImage(obj.update_my_sola__image_3_url) : "-",
      image_4: obj.update_my_sola__image_4_url ? getImage(obj.update_my_sola__image_4_url) : "-",
      image_5: obj.update_my_sola__image_5_url ? getImage(obj.update_my_sola__image_5_url) : "-",
      image_6: obj.update_my_sola__image_6_url ? getImage(obj.update_my_sola__image_6_url) : "-",
      image_7: obj.update_my_sola__image_7_url ? getImage(obj.update_my_sola__image_7_url) : "-",
      image_8: obj.update_my_sola__image_8_url ? getImage(obj.update_my_sola__image_8_url) : "-",
      image_9: obj.update_my_sola__image_9_url ? getImage(obj.update_my_sola__image_9_url) : "-",
      image_10: obj.update_my_sola__image_10_url ? getImage(obj.update_my_sola__image_10_url) : "-",
      approved: renderCheckmark(obj.approved) || "-",
      action: actionButtons(obj),
    }));
    return data;
  };

  const tableData = {
    columns: getColumns(),
    rows: getRows(),
  };

  // Remove uncheck boxes
  const clearCheckItem = () => {
    setSelectedItems([]);
  };

  return (
    <Card>
      <MDBox
        bgColor="dark"
        color="white"
        coloredShadow="dark"
        borderRadius="xl"
        alignItems="center"
        justifyContent="space-between"
        pt={1}
        pb={1}
        pl={2}
        pr={2}
        ml={2}
        mr={2}
        display="flex"
        mt={-3}
        className="page-header"
      >
        <p className="page-header-label">Approved</p>
      </MDBox>
      {(defaultSorting || defaultSorting?.length) &&
        callFetch && ( // SD-3199
          <DataTable
            defaultSortingColumns={defaultSorting} // SD-3199
            defaultFilteringColums={defaultFilteing} // SD-3199
            defaultDisplayColumns={displayColumns}
            setCheckedColumns={setCheckedColumns}
            table={tableData}
            canSearch
            searchText="Search..."
            selectedItems={selectedItems}
            showExportSelectedBtn
            pageCount={pageCount}
            pageNo={pageNo}
            totalCount={totalCount}
            fetchData={fetchData}
            exportFields={exportData.update_my_sola_websites.export_fields}
            exportTable={exportData.update_my_sola_websites.table_name}
            exportType={exportData.update_my_sola_websites.export_type}
            advanceFilter
            exportSection="approved"
            status="approved"
            locationsIds={!adminSuperAdmin() && getAssociationLocations()}
            manualSearch
            manualSort
            isDisplayCheckAllCheckbox
            onChangeCheckAllCheckbox={checkUncheckAll}
            isCheckAll={isCheckAll}
            checkedColumns={checkedColumns}
            clearCheckItem={clearCheckItem}
            useRubyApiToExport
          />
        )}
      {addUpdatePopup && (
        <AddUpdate
          open={addUpdatePopup}
          updateData={updateData}
          onClose={() => closeModal()}
          status="approved"
          pageNo={pageNo}
          perPage={perPage}
          search={search}
          sortBy={sortBy}
        />
      )}
      {viewPopup && (
        <ViewDetails
          open={viewPopup}
          updateData={updateData}
          onClose={() => closeModal()}
          status="pending"
          pageNo={pageNo}
          perPage={perPage}
        />
      )}
      {websiteId && (
        <MDDialog {...deleteDialogProps}>
          <MDTypography variant="h6" fontWeight="medium">
            {Messages.GENERAL.SURE_TO_DELETE}
          </MDTypography>
        </MDDialog>
      )}
      <ImagePreview
        imageData={imageToPreview}
        open={isPreview}
        onCloseImage={() => {
          closeImagePreview();
        }}
      />
    </Card>
  );
}

export default ApprovedList;
