import { useEffect, useState } from "react";
import { useAppDispatch } from "store/store";
import MDDialog from "components/MDDialog";
import MDInput from "components/MDInput";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDIconButton from "components/MDIconButton";
import CustomDropZone from "components/CustomDropZone";
import {
  Grid,
  Icon,
  FormControl,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@mui/material";
import Validations from "helper/validations";
import { createUpdateEventAndClasses } from "store/thunk/mobileAppThunk";
import CustomAutoSearch from "components/CustomAutoSearch";
import MDDatePicker from "components/MDDatePicker";
import CustomErrorMessage from "components/CustomErrorMessage";
import CustomPhoneInput from "components/CustomPhoneInput";
import CustomRequiredLabel from "components/CustomRequiredLabel";
import { scrollToErrorByClass, adminSuperAdmin, getPermission } from "helper/services";
import moment from "moment";

function addUpdate(props: any): JSX.Element {
  const dispatch = useAppDispatch();

  const { open, onClose, updateData, pageNo, perPage, search, sortBy } = props;
  const [isUpdate, setIsUpdate] = useState<any>(false);
  const [eventsAndClass, setEventsAndClass] = useState<any>({});
  const [filePreview, setFilePreview] = useState<any>("");
  const [errors, setErrors] = useState<any>({});

  const onSave = () => {
    const error = Validations.validateEventsAndClasses(eventsAndClass);
    setErrors(error);
    if (!Object.keys(error).length) {
      const req = {
        eventsAndClass,
        onClose,
        isUpdate,
        pageNo,
        perPage,
        search,
        sortBy,
      };
      dispatch(createUpdateEventAndClasses(req));
    } else {
      scrollToErrorByClass(error);
    }
  };

  useEffect(() => {
    if (updateData) {
      setIsUpdate(true);
      const tempDetails: any = JSON.parse(JSON.stringify(updateData));
      if (tempDetails?.file_url) {
        setFilePreview(tempDetails?.file_url);
      }
      if (updateData?.video?.id) {
        tempDetails.video_id = { id: updateData?.video?.id, title: updateData?.video?.title };
      }
      if (updateData?.region?.id) {
        tempDetails.sola_class_region_id = {
          id: updateData?.region?.id,
          name: updateData?.region?.name,
        };
      }
      if (updateData?.class_image_id) {
        tempDetails.class_image_id = {
          id: updateData?.class_image_id,
          name: updateData?.class_image_name,
        };
      }
      if (updateData?.category?.id) {
        tempDetails.category_id = {
          id: updateData?.category?.id,
          name: updateData?.category?.name,
        };
      }
      if (updateData?.tag) {
        tempDetails.tag_ids = updateData?.tag;
      }
      if (updateData?.brands) {
        tempDetails.brand_ids = updateData?.brands;
      }
      if (updateData?.start_date) {
        tempDetails.start_date = moment.utc(new Date(updateData?.start_date)).format();
      }
      if (updateData?.end_date) {
        tempDetails.end_date = moment.utc(new Date(updateData?.end_date)).format();
      }
      setEventsAndClass(tempDetails);
    }
  }, [updateData]);

  const onChangeField = (e: any, field: string) => {
    setEventsAndClass({ ...eventsAndClass, [field]: e.target.value });
  };

  const onChangeAutoComplete = (name: string, value: any) => {
    setEventsAndClass({ ...eventsAndClass, [name]: value });
  };

  const onImageRemove = async (e: any, type: any) => {
    e.stopPropagation();
    if (type === "file") {
      setEventsAndClass({ ...eventsAndClass, file: null, delete_file: 1 });
      setFilePreview("");
    }
  };

  const onChangeImage = async (files: any, type: any) => {
    if (files[0]) {
      const file = files[0];
      const reader = new FileReader();
      reader.onload = function (e) {
        if (type === "file") {
          setEventsAndClass({ ...eventsAndClass, [type]: files[0], delete_file: 0 });
          setFilePreview(e.target.result);
        }
      };
      reader.readAsDataURL(file);
    }
  };

  const onChangeDate = (fieldName: string, value: any) => {
    setEventsAndClass({ ...eventsAndClass, [fieldName]: value });
  };

  const dialogProps = {
    open,
    onSave,
    onClose,
    title: isUpdate ? "Update Events And Classes" : "Add Events And Classes",
    size: "lg",
    saveTbtText: isUpdate ? "Update" : "Save",
  };
  return (
    <MDDialog {...dialogProps}>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <MDInput
            label="Title"
            variant="standard"
            fullWidth
            name="title"
            placeholder="Title"
            value={eventsAndClass?.title || ""}
            onChange={(event: any) => onChangeField(event, "title")}
            error={(errors && errors.title) || false}
            helperText={errors && errors.title}
            required
          />
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <MDInput
            label="Description"
            fullWidth
            multiline
            variant="standard"
            rows={2}
            name="description"
            onChange={(e: any) => onChangeField(e, "description")}
            value={eventsAndClass?.description ? eventsAndClass.description : ""}
            error={errors && errors.title ? true : false}
            helperText={errors && errors.title}
            required
          />
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6}>
          <CustomAutoSearch
            idKey="id"
            nameKey="name"
            fieldLabel="Category"
            apiName="categories"
            responseKey="categories"
            name="category_id"
            value={eventsAndClass?.category_id || null}
            onChange={onChangeAutoComplete}
            createNewLink={
              getPermission("sola_website", "sola_website_categories")
                ? "/sola-website/categories"
                : ""
            }
          />
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6}>
          <CustomAutoSearch
            idKey="id"
            nameKey="name"
            fieldLabel="Class Image"
            apiName="class_images"
            responseKey="class_images"
            name="class_image_id"
            value={eventsAndClass?.class_image_id || null}
            onChange={onChangeAutoComplete}
            createNewLink={
              getPermission("mobile_app", "mobile_app_class_images")
                ? "/mobile-app/class-images"
                : ""
            }
          />
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6}>
          <CustomAutoSearch
            idKey="id"
            nameKey="name"
            fieldLabel="Tags"
            apiName="tags"
            responseKey="tags"
            name="tag_ids"
            value={eventsAndClass?.tag_ids || null}
            onChange={onChangeAutoComplete}
            multiple
            createNewLink={
              getPermission("sola_website", "sola_website_tags") ? "/sola-website/tags" : ""
            }
          />
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6} mt={1}>
          <MDInput
            label="Cost"
            variant="standard"
            fullWidth
            name="cost"
            placeholder="Cost"
            value={eventsAndClass?.cost || ""}
            onChange={(event: any) => onChangeField(event, "cost")}
            error={errors && errors.cost ? true : false}
            helperText={errors && errors.cost}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6}>
          <MDInput
            label="Link Text"
            variant="standard"
            fullWidth
            name="link_text"
            placeholder="Link Text"
            value={eventsAndClass?.link_text || ""}
            onChange={(event: any) => onChangeField(event, "link_text")}
            error={errors && errors.link_text ? true : false}
            helperText={errors && errors.link_text}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6}>
          <MDInput
            label="Link Url"
            variant="standard"
            fullWidth
            name="link_url"
            placeholder="Link Url"
            value={eventsAndClass?.link_url || ""}
            onChange={(event: any) => onChangeField(event, "link_url")}
            error={errors && errors.link_url ? true : false}
            helperText={errors && errors.link_url}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <MDTypography variant="button">File</MDTypography>
          <Grid container>
            <Grid item xs={12} md={6}>
              <CustomDropZone multipleUpload={false} onDrop={(e: any) => onChangeImage(e, "file")}>
                Drop your file here, or Browse
              </CustomDropZone>
            </Grid>
            <Grid item xs={12} md={6} alignItems="center" display="flex">
              {filePreview && (
                <Grid container justifyContent="center" alignItems="center" display="flex">
                  <Grid item className="imageWithDeleteIcon">
                    <img height={100} width={100} src={filePreview} alt="article" />
                    <MDIconButton
                      tooltipName="Delete"
                      aria-label="Delete"
                      className="removeBtnClass"
                      onClick={(e: any) => onImageRemove(e, "file")}
                    >
                      <Icon fontSize="small">close</Icon>
                    </MDIconButton>
                  </Grid>
                </Grid>
              )}
            </Grid>
          </Grid>
          {errors && errors.image && (
            <MDTypography variant="caption" color="error">
              {errors.image}
            </MDTypography>
          )}
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6}>
          <MDInput
            label="File Text"
            variant="standard"
            fullWidth
            name="file_text"
            placeholder="File Text"
            value={eventsAndClass?.file_text || ""}
            onChange={(event: any) => onChangeField(event, "file_text")}
            error={errors && errors.file_text ? true : false}
            helperText={errors && errors.file_text}
          />
          <CustomErrorMessage errorColor="inherit" message="Customize the file button text" />
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6}>
          <CustomAutoSearch
            idKey="id"
            nameKey="title"
            fieldLabel="Video"
            apiName="videos"
            responseKey="videos"
            name="video_id"
            value={eventsAndClass?.video_id || null}
            onChange={onChangeAutoComplete}
            createNewLink={
              getPermission("mobile_app", "mobile_app_videos") ? "/mobile-app/videos" : ""
            }
          />
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <MDBox>
            <Accordion expanded>
              <AccordionSummary className="location-accordion">
                <MDTypography>When</MDTypography>
              </AccordionSummary>
              <AccordionDetails>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={12} md={6} lg={6} className="start_date">
                    <FormControl fullWidth>
                      <CustomRequiredLabel label="Start Date" />
                      <MDDatePicker
                        options={{
                          dateFormat: "M d, Y",
                        }}
                        onChange={([date]: any) => onChangeDate("start_date", date)}
                        name="start_date"
                        value={eventsAndClass?.start_date || null}
                      />
                      <CustomErrorMessage message={errors && errors.start_date} />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={12} md={6} lg={6}>
                    <FormControl fullWidth>
                      <MDTypography variant="button">Start Time</MDTypography>
                      <MDInput
                        variant="outlined"
                        fullWidth
                        name="start_time"
                        placeholder="Start Time"
                        value={eventsAndClass?.start_time || ""}
                        onChange={(event: any) => onChangeField(event, "start_time")}
                        error={errors && errors.start_time ? true : false}
                        helperText={errors && errors.start_time}
                      />
                      <CustomErrorMessage errorColor="inherit" message="(e.g 9:30am, 7pm, etc)" />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={12} md={6} lg={6} className="end_date">
                    <FormControl fullWidth>
                      <CustomRequiredLabel label="End Date" />
                      <MDDatePicker
                        options={{
                          dateFormat: "M d, Y",
                        }}
                        onChange={([date]: any) => onChangeDate("end_date", date)}
                        name="end_date"
                        value={eventsAndClass?.end_date || null}
                      />
                      <CustomErrorMessage message={errors && errors.end_date} />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={12} md={6} lg={6}>
                    <FormControl fullWidth>
                      <MDTypography variant="button">End Time</MDTypography>
                      <MDInput
                        variant="standard"
                        fullWidth
                        name="end_time"
                        placeholder="End Time"
                        value={eventsAndClass?.end_time || ""}
                        onChange={(event: any) => onChangeField(event, "end_time")}
                        error={errors && errors.end_time ? true : false}
                        helperText={errors && errors.end_time}
                      />
                      <CustomErrorMessage errorColor="inherit" message="(e.g 9:30am, 7pm, etc)" />
                    </FormControl>
                  </Grid>
                </Grid>
              </AccordionDetails>
            </Accordion>
          </MDBox>
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <MDBox>
            <Accordion expanded>
              <AccordionSummary className="location-accordion">
                <MDTypography>Where</MDTypography>
              </AccordionSummary>
              <AccordionDetails>
                <Grid container spacing={2} mt={1}>
                  <Grid item xs={12} sm={12} md={6} lg={6} className="sola_class_region_id">
                    <CustomAutoSearch
                      idKey="id"
                      nameKey="name"
                      fieldLabel="Region"
                      apiName="sola_class_regions"
                      responseKey="regions"
                      name="sola_class_region_id"
                      value={eventsAndClass?.sola_class_region_id || null}
                      onChange={onChangeAutoComplete}
                      error={errors && errors.sola_class_region_id}
                      required
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={6} lg={6}>
                    <MDInput
                      label="Location"
                      variant="standard"
                      fullWidth
                      name="location"
                      placeholder="Location"
                      value={eventsAndClass?.location || ""}
                      onChange={(event: any) => onChangeField(event, "location")}
                      error={errors && errors.location ? true : false}
                      helperText={errors && errors.location}
                      required
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={6} lg={6}>
                    <MDInput
                      label="Address"
                      variant="standard"
                      fullWidth
                      name="address"
                      placeholder="Address"
                      value={eventsAndClass?.address || ""}
                      onChange={(event: any) => onChangeField(event, "address")}
                      error={errors && errors.address ? true : false}
                      helperText={errors && errors.address}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={6} lg={6}>
                    <MDInput
                      label="City"
                      variant="standard"
                      fullWidth
                      name="city"
                      placeholder="City"
                      value={eventsAndClass?.city || ""}
                      onChange={(event: any) => onChangeField(event, "city")}
                      error={errors && errors.city ? true : false}
                      helperText={errors && errors.city}
                      required
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={6} lg={6}>
                    <MDInput
                      label="State/Province"
                      variant="standard"
                      fullWidth
                      name="state"
                      placeholder="State/Province"
                      value={eventsAndClass?.state || ""}
                      onChange={(event: any) => onChangeField(event, "state")}
                      error={errors && errors.state ? true : false}
                      helperText={errors && errors.state}
                      required
                    />
                  </Grid>
                </Grid>
              </AccordionDetails>
            </Accordion>
          </MDBox>
        </Grid>
        {adminSuperAdmin() && (
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <MDBox>
              <Accordion expanded>
                <AccordionSummary className="location-accordion">
                  <MDTypography>Who</MDTypography>
                </AccordionSummary>
                <AccordionDetails>
                  <Grid container spacing={2} mt={1}>
                    <Grid item xs={12} sm={12} md={12} lg={12}>
                      <CustomAutoSearch
                        idKey="id"
                        nameKey="name"
                        fieldLabel="Brand"
                        apiName="brands"
                        responseKey="brands"
                        name="brand_ids"
                        value={eventsAndClass?.brand_ids || null}
                        onChange={onChangeAutoComplete}
                        multiple
                        createNewLink={
                          getPermission("mobile_app", "mobile_app_brands")
                            ? "/mobile-app/brands"
                            : ""
                        }
                      />
                    </Grid>
                  </Grid>
                </AccordionDetails>
              </Accordion>
            </MDBox>
          </Grid>
        )}
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <MDBox>
            <Accordion expanded>
              <AccordionSummary className="location-accordion">
                <MDTypography>Rsvp</MDTypography>
              </AccordionSummary>
              <AccordionDetails>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={12} md={6} lg={6}>
                    <MDInput
                      label="Email Address"
                      variant="standard"
                      fullWidth
                      name="rsvp_email_address"
                      placeholder="Email Address"
                      value={eventsAndClass?.rsvp_email_address || ""}
                      onChange={(event: any) => onChangeField(event, "rsvp_email_address")}
                      error={errors && errors.rsvp_email_address ? true : false}
                      helperText={errors && errors.rsvp_email_address}
                    />
                  </Grid>

                  <Grid item xs={12} sm={12} md={6} lg={6}>
                    <MDInput
                      variant="standard"
                      fullWidth
                      label="Phone Number"
                      name="phone"
                      placeholder="(000) 000-0000"
                      id="phone-input"
                      InputProps={{
                        inputComponent: CustomPhoneInput as any,
                      }}
                      value={eventsAndClass?.rsvp_phone_number || ""}
                      onChange={(event: any) => onChangeField(event, "rsvp_phone_number")}
                      error={errors && errors.rsvp_phone_number ? true : false}
                      helperText={errors && errors.rsvp_phone_number}
                    />
                  </Grid>
                </Grid>
              </AccordionDetails>
            </Accordion>
          </MDBox>
        </Grid>
      </Grid>
    </MDDialog>
  );
}

export default addUpdate;
