import { createAsyncThunk } from "@reduxjs/toolkit";
import { notificationSuccess, notificationFail } from "store/slices/notificationSlice";
import { setLoading } from "store/slices/authSlice";
import {
  setLocations,
  setCmsLocations,
  setCmsLocation,
  setApprovals,
  setLocation,
  setTemplates,
  setDashboardData,
  setLocationUserDetails,
  setSmsRequestList,
  setPhoneNumbersList,
  setAvailableNumnersList,
  setSMSRequestDetailData,
  setPhoneNumberDetails,
  setSMSTemplateList,
  setPhoneTreeSettingsList,
  setPhoneTreeSettingDetails,
  setLocationHistoryDetails,
  setPreRecordedVoices,
  setRentManagerProperties,
  setESignTemplateList,
} from "store/slices/locationSlice";
import { getLeadDetails } from "store/thunk/leaseThunk";
import Messages from "helper/messages";
import slsApiClient from "config/slsApiClient";
import apiClient from "config/apiClient";
import platformApiClient from "config/platformClient";
import {
  userRoles,
  getValue,
  getErrorMessage,
  getAssociationLocations,
  getFilterURL,
  getAuthUser,
} from "helper/services";
import moment from "moment";
import Config from "config/config";

export const deleteLocationFromCache = createAsyncThunk(
  "deleteLocationFromCache",
  async (_request: any, { dispatch }) => {
    try {
      dispatch(setLoading(true));
      const response = await platformApiClient().delete(`clear-web-cache-storage`);
      if (!response) {
        dispatch(notificationFail(response?.data?.message || Messages.ERROR.SOMETHING_WENT_WRONG));
      }
    } catch (error: any) {
      dispatch(setLoading(false));
      dispatch(notificationFail(error.message || Messages.ERROR.SOMETHING_WENT_WRONG));
    }
  }
);

export const addLocationInCloudSearch = createAsyncThunk(
  "addLocationInCloudSearch",
  async (_request: any, { dispatch }) => {
    try {
      dispatch(setLoading(true));
      const response = await platformApiClient().post(`migrate-web-pages`, _request, {
        headers: {
          "x-api-key": Config?.PLATFORM_API_KEY,
        },
      });
      if (!response) {
        dispatch(notificationFail(response?.data?.message || Messages.ERROR.SOMETHING_WENT_WRONG));
      }
    } catch (error: any) {
      dispatch(setLoading(false));
      dispatch(notificationFail(error.message || Messages.ERROR.SOMETHING_WENT_WRONG));
    }
  }
);

export const getLocations = createAsyncThunk(
  "getLocations",
  async (_request: any, { dispatch }) => {
    dispatch(setLoading(true));
    try {
      const page = _request?.pageIndex || 0;
      const size = _request?.pageSize || 50;
      const sortBy = _request?.sort || [];
      const search = _request?.search || "";
      const userData = _request?.userData;
      const filterBy = _request?.filterData || [];

      let url = `get-locations?page=${page}&per_page=${size}`;
      if (search) {
        url += `&search=${search}`;
      }
      if (sortBy?.length) {
        url += `&field=${sortBy[0]?.id}&order_by=${sortBy[0]?.desc ? "desc" : "asc"}`;
      }
      if (
        userData?.role !== userRoles?.SYSTEM_ADMIN &&
        userData?.role !== userRoles?.ADMIN &&
        userData?.user_id
      ) {
        url += `&user_id=${userData?.user_id}`;
      }
      // Apply advance filter
      if (filterBy && filterBy.length) {
        url = getFilterURL(url, filterBy);
      }

      const response = await slsApiClient().get(url, {
        params: getAssociationLocations(),
      });
      dispatch(setLoading(false));
      if (response && response?.data?.status) {
        dispatch(setLocations(response?.data));
      } else {
        dispatch(notificationFail(response?.data?.message || Messages.ERROR.NO_DATA_FOUND));
      }
    } catch (error: any) {
      dispatch(setLoading(false));
      dispatch(notificationFail(error?.response?.data?.message || Messages.ERROR.NO_DATA_FOUND));
    }
  }
);

// get location history
export const getLocationHistoryList = createAsyncThunk(
  "getLocationHistory",
  async (_request: any, { dispatch }) => {
    dispatch(setLoading(true));
    try {
      const page = _request?.pageIndex || 0;
      const size = _request?.pageSize || 50;
      const sortBy = _request?.sort || [];
      const search = _request?.search || "";
      const locationId = _request.locationId || null;
      const UserId = _request.userId || null;

      let url = `get-location-history?page=${page}&per_page=${size}`;
      if (search) {
        url += `&search=${search}`;
      }
      if (sortBy?.length) {
        url += `&field=${sortBy[0]?.id}&order_by=${sortBy[0]?.desc ? "desc" : "asc"}`;
      }
      if (UserId) {
        url += `&user_id=${UserId}`;
      }
      const response = await slsApiClient().get(url, {
        params: locationId ? { cms_location_id: locationId } : null,
      });
      dispatch(setLoading(false));
      if (response && response?.data?.status) {
        dispatch(setLocationHistoryDetails(response?.data));
      } else {
        dispatch(notificationFail(response?.data?.message || Messages.ERROR.NO_DATA_FOUND));
      }
    } catch (error: any) {
      dispatch(setLoading(false));
      dispatch(notificationFail(error?.response?.data?.message || Messages.ERROR.NO_DATA_FOUND));
    }
  }
);

// get cms location
export const getLocation = createAsyncThunk("getLocation", async (_request: any, { dispatch }) => {
  dispatch(setLoading(true));
  try {
    const response = await slsApiClient().get(`get-locations-details`, {
      params: {
        cms_location_id: _request?.id,
      },
    });
    // dispatch(setLoading(false));
    if (response?.status) {
      dispatch(setLoading(false));
      // dispatch(getAllUnitTypeName({}));
      const locationData = JSON.parse(JSON.stringify(response?.data?.data));
      if (_request?.cmsLocation) {
        dispatch(setLoading(true));
        try {
          // const cmsResponse: any = await apiClient().get(`locations/${_request.id}`);
          // const rmLinked: any = await slsApiClient().get(
          //   `location_linked_rm?location_id=${_request.id}`
          // // );
          // if (cmsResponse?.status) {
          //   locationData = {
          //     ...locationData,
          //     // ...{ rmlinkedData: rmLinked?.data?.data },
          //     //  ...cmsResponse?.data?.location,
          //   };
          // }
          dispatch(setLoading(false));
        } catch (e: any) {
          dispatch(setLoading(false));
        }
        dispatch(setLocation(locationData));
      } else {
        dispatch(setLocation(locationData));
      }
    } else {
      dispatch(setLocation(null));
      dispatch(notificationFail(Messages.ERROR.NO_DATA_FOUND));
    }
    return response?.data?.data;
  } catch (error: any) {
    dispatch(notificationFail(error.message || Messages.ERROR.NO_DATA_FOUND));
    dispatch(setLoading(false));
  }
  return "";
});

export const addLocation = createAsyncThunk("addLocation", async (_request: any, { dispatch }) => {
  dispatch(setLoading(true));
  try {
    // callback, pageNo, perPage, search, sortBy
    const { location, callback, pageNo, perPage, search, sortBy } = _request;
    const formData = new FormData();
    // SD-3424
    const blogIds = location?.blogs
      ? location?.blogs?.map((location: any) => location.id).join(",")
      : "";
    formData.append("name", getValue(location?.name));
    formData.append("url_name", getValue(location?.url_name));
    formData.append("description", getValue(location?.description));
    formData.append(
      "msa_ids",
      location?.msas?.length ? location.msas.map((msa: any) => msa.id) : []
    );
    formData.append("region", getValue(location?.region?.value));
    formData.append("fbc", getValue(location?.fbc));
    formData.append(
      "association",
      location?.association?.length ? location?.association.map((obj: any) => obj.id) : []
    );
    formData.append("country_id", getValue(location?.country?.id));
    formData.append("store_id", getValue(location?.store_id));
    formData.append("gmb_map_url", location?.gmb_map_url || "");
    formData.append("general_contact_first_name", getValue(location?.general_contact_first_name));
    formData.append("general_contact_last_name", getValue(location?.general_contact_last_name));
    formData.append("general_contact_email", getValue(location?.general_contact_email));
    formData.append("general_contact_phone", getValue(location?.general_contact_phone));
    formData.append("email_address_for_inquiries", getValue(location?.email_address_for_inquiries));
    formData.append("email_address_for_reports", getValue(location?.email_address_for_reports));
    formData.append("email_address_for_hubspot", getValue(location?.email_address_for_hubspot));
    formData.append(
      "emails_for_stylist_website_approvals",
      getValue(location?.emails_for_stylist_website_approvals)
    );
    formData.append("address_1", getValue(location?.address_1));
    formData.append("address_2", getValue(location?.address_2));
    formData.append("city", getValue(location?.city));
    formData.append("state", getValue(location?.state));
    formData.append("postal_code", getValue(location?.postal_code));
    formData.append("facebook_url", getValue(location?.facebook_url));
    formData.append("twitter_url", getValue(location?.twitter_url));
    formData.append("instagram_url", getValue(location?.instagram_url));
    formData.append("signed_lease", location?.signed_lease);
    formData.append("rent_manager_location_id", location?.rent_manager_location?.LocationID || "");
    formData.append("blog_ids", blogIds || "");

    const response = await slsApiClient().post(`add-location`, formData);
    if (response?.data?.status) {
      dispatch(notificationSuccess(Messages.SUCCESS.LOCATION_CREATE_SUCCESS));
      if (callback) {
        callback();
      }
      dispatch(getLocations({ pageIndex: pageNo, pageSize: perPage, search, sort: sortBy }));
    } else {
      dispatch(setLoading(false));
      dispatch(notificationFail(response?.data?.message || Messages.ERROR.SOMETHING_WENT_WRONG));
    }
  } catch (error: any) {
    dispatch(
      notificationFail(
        error?.response?.data?.message || error.message || Messages.ERROR.SOMETHING_WENT_WRONG
      )
    );
    dispatch(setLoading(false));
  }
});

// get Rent Manager Properties
export const getRentManagerProperties = createAsyncThunk(
  "getRentManagerProperties",
  async (_request: any, { dispatch }) => {
    dispatch(setLoading(true));
    try {
      const page = _request?.pageIndex || 0;
      const size = _request?.pageSize || 50;
      const sortBy = _request?.sort || [];
      const search = _request?.search || "";

      let url = `rent_manager_properties?page=${page}&per_page=${size}`;
      if (search) {
        url += `&search=${search}`;
      }
      if (sortBy?.length) {
        url += `&field=${sortBy[0]?.id}&order_by=${sortBy[0]?.desc ? "desc" : "asc"}`;
      }

      const response = await slsApiClient().get(url);
      dispatch(setLoading(false));
      if (response && response?.data?.status) {
        dispatch(setRentManagerProperties(response?.data));
      } else {
        dispatch(notificationFail(response?.data?.message || Messages.ERROR.NO_DATA_FOUND));
      }
    } catch (error: any) {
      dispatch(setLoading(false));
      dispatch(notificationFail(error?.response?.data?.message || Messages.ERROR.NO_DATA_FOUND));
    }
  }
);

export const getCmsLocations = createAsyncThunk(
  "getCmsLocations",
  async (_request: any, { dispatch }) => {
    dispatch(setLoading(true));
    const page = _request.pageIndex || 0;
    const size = _request.pageSize || 50;
    const sortBy = _request.sort || [];
    const search = _request.search || "";
    const filterBy = _request?.filterData || [];

    try {
      let url = `locations?page=${page + 1}&per_page=${size}`;
      if (search) {
        url += `&search=${search}`;
      }
      if (sortBy?.length) {
        const sortField = sortBy[0]?.id;
        url += `&field=${sortField}&order_by=${sortBy[0]?.desc ? "desc" : "asc"}`;
      }

      // Apply advance filter
      if (filterBy && filterBy.length) {
        url = getFilterURL(url, filterBy);
      }
      const response = await apiClient().get(url, {
        params: getAssociationLocations(),
      });
      dispatch(setLoading(false));
      dispatch(setCmsLocations((response && response?.data) || []));
    } catch (error: any) {
      dispatch(setCmsLocations([]));
      dispatch(setLoading(false));
      dispatch(notificationFail(error.message || Messages.ERROR.SOMETHING_WENT_WRONG));
    }
  }
);

export const createCmsLocation = createAsyncThunk(
  "createCmsLocation",
  async (_request: any, { dispatch }) => {
    dispatch(setLoading(true));
    const { location, callback, pageNo, perPage, search, sortBy } = _request;
    const formData = new FormData();

    if (location.id) {
      formData.append("location[id]", location.id);
    }
    formData.append("location[name]", getValue(location?.name));
    formData.append("location[url_name]", getValue(location?.url_name));
    // Add two new fields
    formData.append("location[fbc]", getValue(location?.fbc));
    formData.append("location[region]", getValue(location?.region));
    formData.append("location[description]", getValue(location?.description));
    formData.append("location[msa_id]", getValue(location?.msa_id));
    formData.append("location[admin_id]", getValue(location?.admin_id));
    formData.append("location[store_id]", getValue(location?.store_id));
    formData.append("location[status]", getValue(location?.status));
    formData.append("location[general_contact_name]", getValue(location?.general_contact_name));
    formData.append(
      "location[email_address_for_inquiries]",
      getValue(location?.email_address_for_inquiries)
    );
    formData.append(
      "location[email_address_for_reports]",
      getValue(location?.email_address_for_reports)
    );
    formData.append(
      "location[email_address_for_hubspot]",
      getValue(location?.email_address_for_hubspot)
    );
    formData.append(
      "location[emails_for_stylist_website_approvals]",
      getValue(location?.emails_for_stylist_website_approvals)
    );
    formData.append("location[phone_number]", getValue(location?.phone_number));
    formData.append("location[address_1]", getValue(location?.address_1));
    formData.append("location[address_2]", getValue(location?.address_2));
    formData.append("location[city]", getValue(location?.city));
    formData.append("location[state]", getValue(location?.state));
    formData.append("location[postal_code]", getValue(location?.postal_code));
    if (location?.country_id) {
      formData.append("location[country_id]", getValue(location?.country_id));
    }
    formData.append("location[latitude]", getValue(location?.latitude));
    formData.append("location[longitude]", getValue(location?.longitude));
    formData.append("location[custom_maps_url]", getValue(location?.custom_maps_url));
    formData.append("location[move_in_special]", getValue(location?.move_in_special));
    formData.append("location[open_house]", getValue(location?.open_house));
    formData.append("location[facebook_url]", getValue(location?.facebook_url));
    formData.append("location[twitter_url]", getValue(location?.twitter_url));
    formData.append("location[instagram_url]", getValue(location?.instagram_url));
    formData.append("location[chat_code]", getValue(location?.chat_code));
    formData.append("location[tracking_code]", getValue(location?.tracking_code));
    formData.append("location[image_1_alt_text]", getValue(location?.image_1_alt_text));
    formData.append("location[image_2_alt_text]", getValue(location?.image_2_alt_text));
    formData.append("location[image_3_alt_text]", getValue(location?.image_3_alt_text));
    formData.append("location[image_4_alt_text]", getValue(location?.image_4_alt_text));
    formData.append("location[image_5_alt_text]", getValue(location?.image_5_alt_text));
    formData.append("location[image_6_alt_text]", getValue(location?.image_6_alt_text));
    formData.append("location[image_7_alt_text]", getValue(location?.image_7_alt_text));
    formData.append("location[image_8_alt_text]", getValue(location?.image_8_alt_text));
    formData.append("location[image_9_alt_text]", getValue(location?.image_9_alt_text));
    formData.append("location[image_10_alt_text]", getValue(location?.image_10_alt_text));
    formData.append("location[image_11_alt_text]", getValue(location?.image_11_alt_text));
    formData.append("location[image_12_alt_text]", getValue(location?.image_12_alt_text));
    formData.append("location[image_13_alt_text]", getValue(location?.image_13_alt_text));
    formData.append("location[image_14_alt_text]", getValue(location?.image_14_alt_text));
    formData.append("location[image_15_alt_text]", getValue(location?.image_15_alt_text));
    formData.append("location[image_16_alt_text]", getValue(location?.image_16_alt_text));
    formData.append("location[image_17_alt_text]", getValue(location?.image_17_alt_text));
    formData.append("location[image_18_alt_text]", getValue(location?.image_18_alt_text));
    formData.append("location[image_19_alt_text]", getValue(location?.image_19_alt_text));
    formData.append("location[image_20_alt_text]", getValue(location?.image_20_alt_text));
    formData.append("location[walkins_enabled]", getValue(location?.walkins_enabled));
    formData.append("location[max_walkins_time]", getValue(location?.max_walkins_time));
    formData.append("location[walkins_end_of_day]", getValue(location?.walkins_end_of_day));
    formData.append("location[walkins_timezone]", getValue(location?.walkins_timezone));
    formData.append("location[rockbot_manager_email]", getValue(location?.rockbot_manager_email));
    formData.append(
      "location[rent_manager_property_id]",
      getValue(location?.rent_manager_property_id)
    );
    formData.append(
      "location[rent_manager_location_id]",
      getValue(location?.rent_manager_location_id)
    );
    formData.append(
      "location[service_request_enabled]",
      getValue(location?.service_request_enabled)
    );
    formData.append("location[tour_iframe_1]", getValue(location?.tour_iframe_1));
    formData.append("location[tour_iframe_2]", getValue(location?.tour_iframe_2));
    formData.append("location[tour_iframe_3]", getValue(location?.tour_iframe_3));
    formData.append("location[mailchimp_list_ids]", getValue(location?.mailchimp_list_ids));
    formData.append("location[callfire_list_ids]", getValue(location?.callfire_list_ids));
    formData.append("location[franchisee]", getValue(location?.franchisee?.email));
    for (let i = 1; i <= 20; i++) {
      formData.append(`location[image_${i}]`, location?.[`image_${i}`] || "");
    }
    for (let i = 1; i <= 20; i++) {
      formData.append(`location[delete_image_${i}]`, getValue(location?.[`delete_image_${i}`]));
    }
    formData.append("location[floorplan_image]", location?.floorplan_image || "");
    formData.append("location[delete_floorplan_image]", getValue(location?.delete_floorplan_image));

    try {
      const loginUser = getAuthUser();
      const response = location.id
        ? await apiClient(true).put(
            `locations/${location.id}?current_user=${loginUser?.user_id}`,
            formData
          )
        : await apiClient(true).post("locations", formData);
      if (response) {
        if (callback) {
          callback();
        }
        if (location.id) {
          dispatch(
            addLocationInCloudSearch({
              type: "update",
              data_type: "location",
              id: location?.id,
            })
          );
          dispatch(notificationSuccess(Messages.SUCCESS.LOCATION_UPDATE_SUCCESS));
          dispatch(getCmsLocations({ pageIndex: pageNo, pageSize: perPage, search, sort: sortBy }));
        } else {
          dispatch(notificationSuccess(Messages.SUCCESS.LOCATION_CREATE_SUCCESS));
          dispatch(getCmsLocations({ pageIndex: pageNo, pageSize: perPage }));
          if (response?.data?.location?.id) {
            dispatch(
              addLocationInCloudSearch({
                type: "add",
                data_type: "location",
                id: response?.data?.location?.id,
              })
            );
            await slsApiClient().post(`location/migrate-locations`, {
              location_ids: [response?.data?.location?.id],
              page: 0,
              size: 1,
            });
          }
        }
      } else {
        dispatch(setLoading(false));
        dispatch(notificationFail(Messages.ERROR.SOMETHING_WENT_WRONG));
      }
    } catch (error: any) {
      dispatch(setLoading(false));
      if (error?.response?.data?.error) {
        if (Object.keys(error?.response?.data?.error).length) {
          dispatch(notificationFail(getErrorMessage(error?.response?.data?.error)));
        } else {
          dispatch(notificationFail(error?.response?.data?.error));
        }
      } else {
        dispatch(
          notificationFail(error?.response?.data?.message || Messages.ERROR.SOMETHING_WENT_WRONG)
        );
      }
    }
  }
);

// get cms location
export const getCmsLocation = createAsyncThunk(
  "getCmsLocation",
  async (_request: any, { dispatch }) => {
    dispatch(setLoading(true));
    try {
      const response = await apiClient().get(`locations/${_request.id}`);
      dispatch(setLoading(false));
      if (response) {
        dispatch(setCmsLocation(response.data));
      } else {
        dispatch(setCmsLocation(null));
        dispatch(notificationFail(Messages.ERROR.NO_DATA_FOUND));
      }
    } catch (error: any) {
      dispatch(notificationFail(error.message || Messages.ERROR.NO_DATA_FOUND));
      dispatch(setLoading(false));
    }
  }
);

// delete cms location
export const deleteCmsLocation = createAsyncThunk(
  "deleteCmsLocation",
  async (_request: any, { dispatch }) => {
    const { pageNo, perPage, search, sortBy, removeSelectedItem = null } = _request;
    dispatch(setLoading(true));
    try {
      const response = await apiClient().delete(`locations/${_request.id}`);
      if (response) {
        if (removeSelectedItem) {
          removeSelectedItem(Number(_request.id));
        }
        dispatch(notificationSuccess(Messages.SUCCESS.RECORD_DELETE_SUCCESS));
        if (_request.callback) {
          _request.callback();
        }
        dispatch(deleteLocationFromCache({}));
        dispatch(getCmsLocations({ pageIndex: pageNo, pageSize: perPage, search, sort: sortBy }));
      } else {
        dispatch(setLoading(false));
        dispatch(notificationFail(Messages.ERROR.SOMETHING_WENT_WRONG));
      }
    } catch (error: any) {
      dispatch(setLoading(false));
      dispatch(notificationFail(error.message || Messages.ERROR.SOMETHING_WENT_WRONG));
    }
  }
);

// location approval applications
export const getAllLocationApprovals = createAsyncThunk(
  "getAllLocationApprovals",
  async (_request: any, { dispatch }) => {
    dispatch(setLoading(true));
    const page = _request?.pageIndex || 0;
    const size = _request?.pageSize || 50;
    const search = _request?.search || "";
    const status = _request?.activeStatus || "";
    const sortBy = _request?.sort || [];

    const request: any = {
      page,
      size,
      request_status: status,
      search_text: search,
    };
    if (sortBy?.length) {
      request.sort_field = sortBy[0]?.id;
      request.sort_type = sortBy[0]?.desc ? "desc" : "asc";
    }
    try {
      const response = await slsApiClient().get("get-location-approval", {
        params: {
          page,
          size,
          request_status: status,
          search,
          field: request.sort_field,
          order_by: request.sort_type,
        },
      });
      dispatch(setLoading(false));
      if (response?.data) {
        dispatch(setApprovals(response?.data));
      } else {
        dispatch(notificationFail(Messages.ERROR.NO_DATA_FOUND));
      }
    } catch (error: any) {
      dispatch(setLoading(false));
      dispatch(notificationFail(error?.response?.data?.message || Messages.ERROR.NO_DATA_FOUND));
    }
  }
);

export const locationRequest = createAsyncThunk(
  "locationRequest",
  async (_request: any, { dispatch }) => {
    dispatch(setLoading(true));
    const { callback, requestType, requestStatus, approvalType, approvalData, signedLease } =
      _request;
    const formData = new FormData();
    formData.append("request_type", requestType);
    formData.append("request_status", requestStatus);
    formData.append("approval_type", approvalType);
    formData.append("approval_data", approvalData);
    formData.append("signed_lease", signedLease);
    try {
      const response = await slsApiClient(true).post("create-location-approval", formData);
      dispatch(setLoading(false));
      if (response) {
        if (callback) {
          callback();
        }
        if (approvalData.id) {
          dispatch(notificationSuccess(Messages.SUCCESS.CHANGE_LOCATION_REQUEST));
        } else {
          dispatch(notificationSuccess(Messages.SUCCESS.NEW_LOCATION_REQUEST));
        }
      } else {
        dispatch(notificationFail(Messages.ERROR.SOMETHING_WENT_WRONG));
      }
    } catch (error: any) {
      dispatch(setLoading(false));
      if (error?.response?.data?.error) {
        if (Object.keys(error?.response?.data?.error).length) {
          dispatch(notificationFail(getErrorMessage(error?.response?.data?.error)));
        } else {
          dispatch(notificationFail(error?.response?.data?.error));
        }
      } else {
        dispatch(
          notificationFail(error?.response?.data?.message || Messages.ERROR.SOMETHING_WENT_WRONG)
        );
      }
    }
  }
);

// update location images order
export const updateLocationImageOrder = createAsyncThunk(
  "updateLocationImageOrder",
  async (_request: any, { dispatch }) => {
    dispatch(setLoading(true));
    try {
      const { imagesArray, id, setUpdateImageOrder } = _request;

      const formData = new FormData();
      const orderImgArray = imagesArray.filter((img: any) => img.order);
      if (imagesArray.length) {
        for (let i = 0; i < orderImgArray.length; i++) {
          formData.append(`orders[][name]`, orderImgArray[i]?.name);
          formData.append(`orders[][order]`, orderImgArray[i]?.order);
        }
      }

      const response = await apiClient().post(`locations/${id}/rearrange_image_order`, formData);

      if (response?.data?.message) {
        dispatch(notificationSuccess(response?.data?.message));
        dispatch(getLocation({ id, cmsLocation: true }));
      } else {
        dispatch(setLoading(false));
        dispatch(notificationFail(response?.data?.message || Messages.ERROR.SOMETHING_WENT_WRONG));
      }
      if (setUpdateImageOrder) {
        setUpdateImageOrder(false);
      }
    } catch (error: any) {
      dispatch(notificationFail(error.message || Messages.ERROR.SOMETHING_WENT_WRONG));
      dispatch(setLoading(false));
    }
  }
);

// update Location
export const updateLocation = createAsyncThunk(
  "updateLocation",
  async (_request: any, { dispatch }) => {
    dispatch(setLoading(true));
    try {
      const {
        approveData,
        floorplanImage,
        websiteData,
        deleteFloorPlanImage,
        tabName,
        id,
        whoAddedId,
        crmData,
        dripCampaign,
        dealDefaultOwnerId,
      } = _request;

      const formData = new FormData();

      formData.append("tab_name", getValue(tabName));

      if (!crmData) {
        for (let i = 1; i <= 20; i++) {
          formData.append(`image_${i}`, websiteData?.[`image_${i}`] || "");
        }

        for (let i = 1; i <= 20; i++) {
          formData.append(`delete_image_${i}`, websiteData?.[`delete_image_${i}`] || "");
        }

        formData.append("approval_data", getValue(approveData));
        formData.append("delete_floor_plan_image", deleteFloorPlanImage || "");
        formData.append("video", getValue(websiteData?.video));
        formData.append("delete_video", websiteData?.delete_video || "");
        formData.append("floorplan_image", floorplanImage || "");
      } else {
        formData.append("crm_data", getValue(crmData));
        formData.append("location_id", getValue(id));
        formData.append("drip_campaign", dripCampaign || false);
        formData.append("deal_default_owner_id", dealDefaultOwnerId || null);
      }
      formData.append("who_added_id", whoAddedId);

      const response = await slsApiClient().post(`update-location`, formData);
      if (response?.data?.status) {
        dispatch(notificationSuccess(Messages.SUCCESS.LOCATION_UPDATE_SUCCESS));
        dispatch(addLocationInCloudSearch({ type: "update", data_type: "location", id }));
        dispatch(getLocation({ id, cmsLocation: true }));
      } else {
        dispatch(setLoading(false));
        dispatch(notificationFail(response?.data?.message || Messages.ERROR.SOMETHING_WENT_WRONG));
      }
    } catch (error: any) {
      dispatch(notificationFail(error.message || Messages.ERROR.SOMETHING_WENT_WRONG));
      dispatch(setLoading(false));
    }
  }
);

/* add Owner to assoication */
export const locationAddOwner = createAsyncThunk(
  "locationAddOwner",
  async (_request: any, { dispatch }) => {
    const { onClose, locationId, ownerId, whoAddedId } = _request;
    dispatch(setLoading(true));
    try {
      const response = await slsApiClient().post(`location/add-owner`, {
        location_id: Number(locationId),
        owner_user_id: Number(ownerId),
        who_added_id: whoAddedId,
      });
      if (response?.data?.status) {
        dispatch(getLocation({ id: Number(locationId), cmsLocation: true }));
        dispatch(
          notificationSuccess(
            response?.data?.message || Messages.SUCCESS.ASSOCIATION_OWNER_ADD_SUCCESS
          )
        );
        onClose();
      } else {
        dispatch(setLoading(false));
        dispatch(notificationFail(response?.data?.message || Messages.ERROR.NO_DATA_FOUND));
      }
    } catch (error: any) {
      dispatch(setLoading(false));
      dispatch(
        notificationFail(error?.response?.data?.message || Messages.ERROR.SOMETHING_WENT_WRONG)
      );
    }
  }
);

/* delete Owner to assoication */
export const deleteLocationOwner = createAsyncThunk(
  "deleteLocationOwner",
  async (_request: any, { dispatch }) => {
    const { locationId, ownerId } = _request;
    dispatch(setLoading(true));
    try {
      const response = await slsApiClient().post(`location/delete-owner`, {
        location_id: Number(locationId),
        owner_user_id: Number(ownerId),
      });
      if (response?.data?.status) {
        dispatch(getLocation({ id: locationId, cmsLocation: true }));
        dispatch(
          notificationSuccess(
            response?.data?.message || Messages.SUCCESS.ASSOCIATION_OWNER_DELETE_SUCCESS
          )
        );
      } else {
        dispatch(setLoading(false));
        dispatch(notificationFail(response?.data?.message || Messages.ERROR.NO_DATA_FOUND));
      }
    } catch (error: any) {
      dispatch(setLoading(false));
      dispatch(
        notificationFail(error?.response?.data?.message || Messages.ERROR.SOMETHING_WENT_WRONG)
      );
    }
  }
);

export const getTemplates = createAsyncThunk(
  "getTemplates",
  async (_request: any, { dispatch }) => {
    dispatch(setLoading(true));
    try {
      const response = await slsApiClient().get(`get-email-templates`, { params: _request });
      if (response?.data?.status) {
        dispatch(setTemplates(response?.data?.data));
      } else {
        dispatch(notificationFail(response?.data?.message || Messages.ERROR.SOMETHING_WENT_WRONG));
      }
      dispatch(setLoading(false));
    } catch (error: any) {
      dispatch(setLoading(false));
      dispatch(notificationFail(error.message || Messages.ERROR.SOMETHING_WENT_WRONG));
    }
  }
);

// approve location
export const approveLocation = createAsyncThunk(
  "approveLocation",
  async (_request: any, { dispatch }) => {
    dispatch(setLoading(true));
    try {
      const response = await slsApiClient().post("approve-location", {
        approval_id: _request?.approval_id,
      });
      dispatch(setLoading(false));
      if (response?.data) {
        if (_request.callback) {
          _request.callback();
        }
      } else {
        dispatch(notificationFail(Messages.ERROR.SOMETHING_WENT_WRONG));
      }
    } catch (error: any) {
      dispatch(setLoading(false));
      dispatch(
        notificationFail(error?.response?.data?.message || Messages.ERROR.SOMETHING_WENT_WRONG)
      );
    }
  }
);

// update rent manager tab data
export const updateLocationRmData = createAsyncThunk(
  "updateLocationRmData",
  async (_request: any, { dispatch }) => {
    dispatch(setLoading(true));
    try {
      const response = await slsApiClient().post("update_location_rm_data", _request);
      dispatch(setLoading(false));
      if (response?.data?.status) {
        if (_request.callback) {
          _request.callback();
        }
        dispatch(notificationSuccess(response?.data?.message));
        dispatch(getLocation({ id: _request?.id, cmsLocation: true }));
      } else {
        dispatch(notificationFail(Messages.ERROR.SOMETHING_WENT_WRONG));
      }
    } catch (error: any) {
      dispatch(setLoading(false));
      dispatch(
        notificationFail(error?.response?.data?.message || Messages.ERROR.SOMETHING_WENT_WRONG)
      );
    }
  }
);

// update location units
export const updateLocationUnits = createAsyncThunk(
  "updateLocationUnits",
  async (_request: any, { dispatch }) => {
    dispatch(setLoading(true));
    try {
      const response = await slsApiClient().post("update-location-units", _request);
      dispatch(setLoading(false));
      if (response?.data?.status) {
        if (_request.callback) {
          _request.callback();
        }
        dispatch(notificationSuccess(Messages.SUCCESS.RECORD_UPDATE_SUCCESS));
        dispatch(getLocation({ id: _request?.id, cmsLocation: true }));
      } else {
        dispatch(notificationFail(Messages.ERROR.SOMETHING_WENT_WRONG));
      }
    } catch (error: any) {
      dispatch(setLoading(false));
      dispatch(
        notificationFail(error?.response?.data?.message || Messages.ERROR.SOMETHING_WENT_WRONG)
      );
    }
  }
);

// delete all location data
export const deleteLocationAllData = createAsyncThunk(
  "deleteLocationAllData",
  async (_request: any, { dispatch }) => {
    const { pageNo, perPage, search, sortBy, removeSelectedItem = null } = _request;
    dispatch(setLoading(true));
    try {
      const response = await slsApiClient().post(`location/delete-location-all-data`, {
        cms_location_id: _request.id,
      });
      if (response) {
        if (removeSelectedItem) {
          removeSelectedItem(_request.id);
        }
        dispatch(notificationSuccess(Messages.SUCCESS.RECORD_DELETE_SUCCESS));
        if (_request.callback) {
          _request.callback();
        }
        dispatch(deleteLocationFromCache({}));
        dispatch(getLocations({ pageIndex: pageNo, pageSize: perPage, search, sort: sortBy }));
      } else {
        dispatch(setLoading(false));
        dispatch(notificationFail(Messages.ERROR.SOMETHING_WENT_WRONG));
      }
    } catch (error: any) {
      dispatch(setLoading(false));
      dispatch(notificationFail(error.message || Messages.ERROR.SOMETHING_WENT_WRONG));
    }
  }
);

export const getDashboardData = createAsyncThunk(
  "getDashboardData",
  async (_request: any, { dispatch }) => {
    // const {locations, month} = _request;
    try {
      dispatch(setLoading(true));
      const response = await slsApiClient().post(`get-dashboard-data-list`, _request);
      if (response?.data?.status) {
        dispatch(setDashboardData(response?.data));
        dispatch(setLoading(false));
      } else {
        dispatch(setLoading(false));
        dispatch(notificationFail(response?.data?.message || Messages.ERROR.SOMETHING_WENT_WRONG));
      }
    } catch (error: any) {
      dispatch(setLoading(false));
      dispatch(notificationFail(error.message || Messages.ERROR.SOMETHING_WENT_WRONG));
    }
  }
);

// save location user
export const saveLocationUser = createAsyncThunk(
  "saveLocationUser",
  async (_request: any, { dispatch }) => {
    dispatch(setLoading(true));
    try {
      const response = await slsApiClient().post("save-location-user", _request);
      if (response?.data) {
        _request?.onClose();
        dispatch(getLocation({ id: _request.location_id, cmsLocation: true }));
        dispatch(
          notificationSuccess(
            response?.data?.message || Messages.SUCCESS.ASSOCIATION_USER_ADD_SUCCESS
          )
        );
      } else {
        _request?.onClose();
        dispatch(notificationFail(Messages.ERROR.SOMETHING_WENT_WRONG));
      }
    } catch (error: any) {
      dispatch(setLoading(false));
      dispatch(
        notificationFail(error?.response?.data?.message || Messages.ERROR.SOMETHING_WENT_WRONG)
      );
    }
  }
);

// save Location Blog
export const saveLocationBlogs = createAsyncThunk(
  "saveLocationBlogs",
  async (_request: any, { dispatch }) => {
    dispatch(setLoading(true));
    try {
      const response = await slsApiClient().post("update-locations-blogs", _request);
      if (response?.data) {
        _request?.onClose();
        dispatch(getLocation({ id: _request.location_id, cmsLocation: true }));
        dispatch(notificationSuccess(response?.data?.message));
      } else {
        _request?.onClose();
        dispatch(notificationFail(Messages.ERROR.SOMETHING_WENT_WRONG));
      }
    } catch (error: any) {
      dispatch(setLoading(false));
      dispatch(
        notificationFail(error?.response?.data?.message || Messages.ERROR.SOMETHING_WENT_WRONG)
      );
    }
  }
);
// remove Location Blog
export const removeLocationBlogs = createAsyncThunk(
  "removeLocationBlogs",
  async (_request: any, { dispatch }) => {
    dispatch(setLoading(true));
    try {
      const response = await slsApiClient().post("remove-locations-blogs", _request);
      if (response?.data) {
        _request?.setBlogsRemovePopup(false);
        dispatch(getLocation({ id: _request.location_id, cmsLocation: true }));
        dispatch(notificationSuccess(response?.data?.message));
      } else {
        _request?.setBlogsRemovePopup(false);
        dispatch(notificationFail(Messages.ERROR.SOMETHING_WENT_WRONG));
      }
    } catch (error: any) {
      dispatch(setLoading(false));
      dispatch(
        notificationFail(error?.response?.data?.message || Messages.ERROR.SOMETHING_WENT_WRONG)
      );
    }
  }
);

// get location user
export const getLocationUserDetails = createAsyncThunk(
  "getLocationUserDetails",
  async (_request: any, { dispatch }) => {
    dispatch(setLoading(true));
    try {
      const response = await slsApiClient().post("get-location-user", {
        location_id: _request?.location_id,
        user_id: _request?.user_id,
      });
      if (response?.data) {
        dispatch(setLocationUserDetails(response?.data?.data || {}));
        dispatch(setLoading(false));
      } else {
        dispatch(setLoading(false));
        dispatch(notificationFail(Messages.ERROR.SOMETHING_WENT_WRONG));
      }
    } catch (error: any) {
      dispatch(setLoading(false));
      dispatch(
        notificationFail(error?.response?.data?.message || Messages.ERROR.SOMETHING_WENT_WRONG)
      );
    }
  }
);

// get location user
export const deleteLocationUser = createAsyncThunk(
  "deleteLocationUser",
  async (_request: any, { dispatch }) => {
    dispatch(setLoading(true));
    try {
      const response = await slsApiClient().post("delete-location-user", {
        location_id: _request?.location_id,
        user_id: _request?.user_id,
      });
      if (response?.data) {
        dispatch(getLocation({ id: _request.location_id, cmsLocation: true }));
        dispatch(
          notificationSuccess(
            response?.data?.message || Messages.SUCCESS.ASSOCIATION_USER_DELETE_SUCCESS
          )
        );
      } else {
        dispatch(setLoading(false));
        dispatch(notificationFail(Messages.ERROR.SOMETHING_WENT_WRONG));
      }
    } catch (error: any) {
      dispatch(setLoading(false));
      dispatch(
        notificationFail(error?.response?.data?.message || Messages.ERROR.SOMETHING_WENT_WRONG)
      );
    }
  }
);

// update location user
export const updateLocationUser = createAsyncThunk(
  "updateLocationUser",
  async (_request: any, { dispatch }) => {
    dispatch(setLoading(true));
    try {
      const response = await slsApiClient().post("update-location-user", _request);
      if (response?.data) {
        _request?.onClose();
        dispatch(setLoading(false));
        dispatch(
          notificationSuccess(response?.data?.message || Messages.SUCCESS.USER_UPDATE_SUCCESS)
        );
      } else {
        _request?.onClose();
        dispatch(notificationFail(Messages.ERROR.SOMETHING_WENT_WRONG));
      }
    } catch (error: any) {
      dispatch(setLoading(false));
      dispatch(
        notificationFail(error?.response?.data?.message || Messages.ERROR.SOMETHING_WENT_WRONG)
      );
    }
  }
);
// Request sms (new twillio number request)
export const requestSms = createAsyncThunk("requestSms", async (_request: any, { dispatch }) => {
  dispatch(setLoading(true));
  try {
    const response = await slsApiClient().post("request-twillio-number", _request);
    if (response?.data) {
      dispatch(setLoading(false));
      dispatch(
        notificationSuccess(response?.data?.message || Messages.SUCCESS.SMS_REQUEST_SUCCESS)
      );
      if (_request?.section === "deal") {
        dispatch(getLeadDetails({ leadId: _request?.lead_id }));
      }
      if (_request?.section === "crm") {
        dispatch(getLocation({ id: _request?.location_id, cmsLocation: true }));
      }
    } else {
      dispatch(notificationFail(Messages.ERROR.SOMETHING_WENT_WRONG));
    }
  } catch (error: any) {
    dispatch(setLoading(false));
    dispatch(
      notificationFail(error?.response?.data?.message || Messages.ERROR.SOMETHING_WENT_WRONG)
    );
  }
});

export const getNewSmsRequestList = createAsyncThunk(
  "getNewSmsRequestList",
  async (_request: any, { dispatch }) => {
    dispatch(setLoading(true));
    const page = _request?.pageIndex || 0;
    const size = _request?.pageSize || 50;
    const search = _request?.search || "";
    const sortBy = _request?.sort || [];

    const request: any = {
      page,
      size,
      search,
    };
    if (sortBy?.length) {
      request.sort_field = sortBy[0]?.id;
      request.sort_type = sortBy[0]?.desc ? "desc" : "asc";
    }
    try {
      const response = await slsApiClient().get("get-new-sms-request-list", {
        params: request,
      });
      dispatch(setLoading(false));
      if (response?.data) {
        dispatch(setSmsRequestList(response?.data));
      } else {
        dispatch(notificationFail(Messages.ERROR.NO_DATA_FOUND));
      }
    } catch (error: any) {
      dispatch(setLoading(false));
      dispatch(notificationFail(error?.response?.data?.message || Messages.ERROR.NO_DATA_FOUND));
    }
  }
);
export const getPhoneNumberList = createAsyncThunk(
  "getPhoneNumberList",
  async (_request: any, { dispatch }) => {
    dispatch(setLoading(true));
    const page = _request?.pageIndex || 0;
    const size = _request?.pageSize || 50;
    const search = _request?.search || "";
    const sortBy = _request?.sort || [];

    const request: any = {
      page,
      size,
      search,
    };
    if (sortBy?.length) {
      request.sort_field = sortBy[0]?.id;
      request.sort_type = sortBy[0]?.desc ? "desc" : "asc";
    }
    try {
      const response = await slsApiClient().get("get-all-twillio-numbers-list", {
        params: request,
      });
      dispatch(setLoading(false));
      if (response?.data) {
        dispatch(setPhoneNumbersList(response?.data));
      } else {
        dispatch(notificationFail(Messages.ERROR.NO_DATA_FOUND));
      }
    } catch (error: any) {
      dispatch(setLoading(false));
      dispatch(notificationFail(error?.response?.data?.message || Messages.ERROR.NO_DATA_FOUND));
    }
  }
);

// Assign Twillio Numner to request location
export const assignTwillioNumber = createAsyncThunk(
  "assignTwillioNumber",
  async (_request: any, { dispatch }) => {
    const { listingRequestData, assignReq, onClose } = _request;
    dispatch(setLoading(true));
    try {
      const response = await slsApiClient().post("assign-twilio-number-to-location", assignReq);
      if (response?.data) {
        dispatch(getNewSmsRequestList(listingRequestData));
        dispatch(
          notificationSuccess(
            response?.data?.message || Messages.SUCCESS.SUCCESS_PHONE_NUMBER_ASSIGNED
          )
        );
        if (onClose) {
          onClose();
        }
      } else {
        dispatch(setLoading(false));
        dispatch(notificationFail(Messages.ERROR.SOMETHING_WENT_WRONG));
      }
    } catch (error: any) {
      dispatch(setLoading(false));
      dispatch(
        notificationFail(error?.response?.data?.message || Messages.ERROR.SOMETHING_WENT_WRONG)
      );
    }
  }
);
// Assign Twillio Numner to request location
export const getAvailableNumbersList = createAsyncThunk(
  "getAvailableNumbersList",
  async (_request: any, { dispatch }) => {
    dispatch(setLoading(true));
    try {
      const response = await slsApiClient().get("get-available-phone-numbers", {
        params: { area_code: _request.area_code },
      });
      if (response?.data) {
        dispatch(setAvailableNumnersList(response?.data?.data));
      } else {
        dispatch(notificationFail(Messages.ERROR.SOMETHING_WENT_WRONG));
      }
      dispatch(setLoading(false));
    } catch (error: any) {
      dispatch(setLoading(false));
      dispatch(
        notificationFail(error?.response?.data?.message || Messages.ERROR.SOMETHING_WENT_WRONG)
      );
    }
  }
);

// buy New twillio number
export const buyNewNumber = createAsyncThunk(
  "buyNewNumber",
  async (_request: any, { dispatch }) => {
    const { buyReq, onClose, setConfirmData } = _request;
    dispatch(setLoading(true));
    try {
      const response = await slsApiClient().post("buy-new-twillio-number", buyReq);
      if (response?.data) {
        dispatch(
          notificationSuccess(response?.data?.message || Messages.SUCCESS.SUCCESS_PHONE_NUMBER_BUY)
        );
        if (onClose) {
          onClose();
        }
        if (setConfirmData) {
          setConfirmData(false);
        }
        dispatch(getPhoneNumberList({ pageIndex: 0, pageSize: 10 }));
      } else {
        dispatch(setLoading(false));
        dispatch(notificationFail(Messages.ERROR.SOMETHING_WENT_WRONG));
      }
    } catch (error: any) {
      dispatch(setLoading(false));
      dispatch(
        notificationFail(error?.response?.data?.message || Messages.ERROR.SOMETHING_WENT_WRONG)
      );
    }
  }
);
// get SMS request detail data buy id/location_id
export const getSMSRequestDetails = createAsyncThunk(
  "getSMSRequestDetails",
  async (_request: any, { dispatch }) => {
    dispatch(setLoading(true));
    try {
      const response = await slsApiClient().post("get-sms-request-detail", _request);
      if (response?.data) {
        dispatch(setSMSRequestDetailData(response?.data?.data));
      } else {
        dispatch(notificationFail(Messages.ERROR.SOMETHING_WENT_WRONG));
      }
      dispatch(setLoading(false));
    } catch (error: any) {
      dispatch(setLoading(false));
      dispatch(
        notificationFail(error?.response?.data?.message || Messages.ERROR.SOMETHING_WENT_WRONG)
      );
    }
  }
);

// Assign Twillio Numner to request location
export const deletePhoneNumber = createAsyncThunk(
  "deletePhoneNumber",
  async (_request: any, { dispatch }) => {
    const { phoneListingRequestData, deleteReq, setDeleteData } = _request;
    dispatch(setLoading(true));
    try {
      const response = await slsApiClient().post("delete-phone-number", deleteReq);
      if (response?.data) {
        dispatch(getPhoneNumberList(phoneListingRequestData));
        dispatch(
          notificationSuccess(response?.data?.message || Messages.SUCCESS.SUCCESS_PHONE_DELETE)
        );
        if (setDeleteData) {
          setDeleteData(false);
        }
      } else {
        dispatch(notificationFail(Messages.ERROR.SOMETHING_WENT_WRONG));
      }
      dispatch(setLoading(false));
    } catch (error: any) {
      dispatch(setLoading(false));
      dispatch(
        notificationFail(error?.response?.data?.message || Messages.ERROR.SOMETHING_WENT_WRONG)
      );
    }
  }
);

export const getPhoneTreeSettings = createAsyncThunk(
  "getPhoneTreeSettings",
  async (_request: any, { dispatch }) => {
    dispatch(setLoading(true));
    try {
      const response = await slsApiClient().get(`get-phone-tree-settings`);
      if (response?.data) {
        dispatch(setPhoneTreeSettingsList(response?.data?.data || []));
      } else {
        dispatch(setLoading(false));
        dispatch(notificationFail(Messages.ERROR.SOMETHING_WENT_WRONG));
      }
      dispatch(setLoading(false));
    } catch (error: any) {
      dispatch(setLoading(false));
      dispatch(
        notificationFail(error?.response?.data?.message || Messages.ERROR.SOMETHING_WENT_WRONG)
      );
    }
  }
);

// get phone tree settings by id
export const getPhoneTreeSettingById = createAsyncThunk(
  "getPhoneTreeSettingById",
  async (_request: any, { dispatch }) => {
    dispatch(setLoading(true));
    try {
      const response = await slsApiClient().get(`get-phone-tree-setting-by-id?id=${_request?.id}`);
      if (response?.data) {
        dispatch(setPhoneTreeSettingDetails(response?.data?.data));
        dispatch(setLoading(false));
      } else {
        dispatch(setLoading(false));
        dispatch(notificationFail(Messages.ERROR.SOMETHING_WENT_WRONG));
      }
    } catch (error: any) {
      dispatch(setLoading(false));
      dispatch(
        notificationFail(error?.response?.data?.message || Messages.ERROR.SOMETHING_WENT_WRONG)
      );
    }
  }
);

// get twillio numebr detail from by ID
export const getPhoneDetailById = createAsyncThunk(
  "getPhoneDetailById",
  async (_request: any, { dispatch }) => {
    dispatch(setLoading(true));
    try {
      const response = await slsApiClient().get(`get-phone-number-by-id?id=${_request?.id}`);
      if (response?.data) {
        dispatch(getPhoneTreeSettingById({ id: response?.data?.data?.id }));
        dispatch(setPhoneNumberDetails(response?.data?.data));
      } else {
        dispatch(setLoading(false));
        dispatch(notificationFail(Messages.ERROR.SOMETHING_WENT_WRONG));
      }
    } catch (error: any) {
      dispatch(setLoading(false));
      dispatch(
        notificationFail(error?.response?.data?.message || Messages.ERROR.SOMETHING_WENT_WRONG)
      );
    }
  }
);

// updateTwillioPhoneSetting
export const updateTwillioPhoneSetting = createAsyncThunk(
  "updateTwillioPhoneSetting",
  async (_request: any, { dispatch }) => {
    dispatch(setLoading(true));
    try {
      const response = await slsApiClient().put("update-phone-number-by-id", _request);
      if (response?.data) {
        dispatch(getPhoneDetailById(_request));
        dispatch(
          notificationSuccess(
            response?.data?.message || Messages.SUCCESS.SUCCESS_PHONE_SETTING_UPDATE
          )
        );
      } else {
        dispatch(notificationFail(Messages.ERROR.SOMETHING_WENT_WRONG));
      }
      dispatch(setLoading(false));
    } catch (error: any) {
      dispatch(setLoading(false));
      dispatch(
        notificationFail(error?.response?.data?.message || Messages.ERROR.SOMETHING_WENT_WRONG)
      );
    }
  }
);

// addLocations To Twillio phone number from edit page
export const updateTwilioNumberToLocation = createAsyncThunk(
  "updateTwilioNumberToLocation",
  async (_request: any, { dispatch }) => {
    const { onClose, updateData } = _request;
    dispatch(setLoading(true));
    try {
      const response = await slsApiClient().post("update-twilio-number-to-location", updateData);
      if (response?.data) {
        dispatch(getPhoneDetailById(updateData));
        dispatch(
          notificationSuccess(
            response?.data?.message || Messages.SUCCESS.SUCCESS_PHONE_SETTING_UPDATE
          )
        );
        if (onClose) {
          onClose();
        }
      } else {
        dispatch(notificationFail(Messages.ERROR.SOMETHING_WENT_WRONG));
      }
      dispatch(setLoading(false));
    } catch (error: any) {
      dispatch(setLoading(false));
      dispatch(
        notificationFail(error?.response?.data?.message || Messages.ERROR.SOMETHING_WENT_WRONG)
      );
    }
  }
);
// remove location Twillio phone number from edit page
export const removeLocationFromTwillioNumber = createAsyncThunk(
  "removeLocationFromTwillioNumber",
  async (_request: any, { dispatch }) => {
    const { setDeleteLocationId, removeData } = _request;
    dispatch(setLoading(true));
    try {
      const response = await slsApiClient().post("remove-twilio-number-to-location", removeData);
      if (response?.data) {
        dispatch(getPhoneDetailById(removeData));
        dispatch(notificationSuccess(Messages.SUCCESS.SUCCESS_PHONE_SETTING_UPDATE));
        if (setDeleteLocationId) {
          setDeleteLocationId(false);
        }
      } else {
        dispatch(notificationFail(Messages.ERROR.SOMETHING_WENT_WRONG));
      }
      dispatch(setLoading(false));
    } catch (error: any) {
      dispatch(setLoading(false));
      dispatch(
        notificationFail(error?.response?.data?.message || Messages.ERROR.SOMETHING_WENT_WRONG)
      );
    }
  }
);

export const getSMSTemplateList = createAsyncThunk(
  "getSMSTemplateList",
  async (_request: any, { dispatch }) => {
    dispatch(setLoading(true));
    try {
      const locationIds = _request?.locationIds || [];

      const url = `get-sms-template?location_ids=[${locationIds}]`;
      const response = await slsApiClient().get(url);
      dispatch(setLoading(false));
      if (response && response?.data?.status) {
        dispatch(setSMSTemplateList(response?.data?.data));
      } else {
        dispatch(notificationFail(response?.data?.message || Messages.ERROR.NO_DATA_FOUND));
      }
    } catch (error: any) {
      dispatch(setLoading(false));
      dispatch(notificationFail(error?.response?.data?.message || Messages.ERROR.NO_DATA_FOUND));
    }
  }
);

// save location user
export const createUpdateSMSTemplate = createAsyncThunk(
  "createUpdateSMSTemplate",
  async (_request: any, { dispatch }) => {
    try {
      const req: any = {
        template_name: _request?.templateName || "",
        template_content: _request?.templateContent || "",
        location_id: _request?.locationId,
        user_id: _request?.userId,
      };

      if (_request?.id) {
        req.id = _request?.id;
        req.template_alias = _request?.templateAlias;
      } else {
        req.template_alias = _request?.templateName ? _request?.templateName.replace(" ", "-") : "";
      }
      dispatch(setLoading(true));
      const response = await slsApiClient().post("create-sms-template", req);
      if (response?.data) {
        _request?.onClose();
        dispatch(getSMSTemplateList({ locationIds: [req?.location_id] }));
        dispatch(
          notificationSuccess(
            response?.data?.message || Messages.SUCCESS.ASSOCIATION_USER_ADD_SUCCESS
          )
        );
      } else {
        _request?.onClose();
        dispatch(notificationFail(Messages.ERROR.SOMETHING_WENT_WRONG));
      }
    } catch (error: any) {
      dispatch(setLoading(false));
      dispatch(
        notificationFail(error?.response?.data?.message || Messages.ERROR.SOMETHING_WENT_WRONG)
      );
    }
  }
);

// delete cms location
export const deleteSMSTemplate = createAsyncThunk(
  "deleteSMSTemplate",
  async (_request: any, { dispatch }) => {
    const { id, locationId } = _request;
    dispatch(setLoading(true));
    try {
      const response = await slsApiClient().delete(`delete-sms-template/${id}`);
      if (response) {
        dispatch(notificationSuccess(Messages.SUCCESS.RECORD_DELETE_SUCCESS));
        dispatch(getSMSTemplateList({ locationIds: [locationId] }));
      } else {
        dispatch(setLoading(false));
        dispatch(notificationFail(Messages.ERROR.SOMETHING_WENT_WRONG));
      }
    } catch (error: any) {
      dispatch(setLoading(false));
      dispatch(notificationFail(error.message || Messages.ERROR.SOMETHING_WENT_WRONG));
    }
  }
);

// send broadcast sms to multiple deals
export const sendBulkSms = createAsyncThunk("sendBulkSms", async (_request: any, { dispatch }) => {
  dispatch(setLoading(true));
  const { onClose, onCloseConfirmDialog, clearBroadcastArray, onCallback } = _request;
  try {
    const response = await slsApiClient().post("send-bulk-sms", _request?.broadCastData);
    if (response?.data) {
      if (response?.data?.messageError?.length) {
        dispatch(
          notificationFail(
            `Problem occurred while sending messages to ${response?.data?.messageError?.length} out of ${_request?.broadCastData?.deals_array?.length} deals.`
          )
        );
      } else {
        dispatch(notificationSuccess(response?.data?.message));
      }
      if (onClose) {
        onClose();
      }
      if (onCloseConfirmDialog) {
        onCloseConfirmDialog();
      }
      if (clearBroadcastArray) {
        clearBroadcastArray([]);
      }
      if (onCallback) {
        onCallback(response?.data);
      }
      dispatch(setLoading(false));
    } else {
      dispatch(notificationFail(Messages.ERROR.SOMETHING_WENT_WRONG));
    }
  } catch (error: any) {
    dispatch(setLoading(false));
    dispatch(
      notificationFail(error?.response?.data?.message || Messages.ERROR.SOMETHING_WENT_WRONG)
    );
  }
});

// create and update phone tree settings
export const createUpdatePhoneTreeSettings = createAsyncThunk(
  "createUpdatePhoneTreeSettings",
  async (_request: any, { dispatch }) => {
    const { id, updateData, onClose } = _request;
    dispatch(setLoading(true));
    try {
      const response = await slsApiClient().post("create-phone-tree-settings", updateData);
      if (response?.data) {
        dispatch(getPhoneDetailById({ id }));
        dispatch(
          notificationSuccess(
            response?.data?.message || Messages.SUCCESS.SUCCESS_PHONE_TREE_SETTING_ADDED
          )
        );
        if (onClose) {
          onClose();
        }
      } else {
        dispatch(setLoading(false));
        dispatch(notificationFail(Messages.ERROR.SOMETHING_WENT_WRONG));
      }
    } catch (error: any) {
      dispatch(setLoading(false));
      dispatch(
        notificationFail(error?.response?.data?.message || Messages.ERROR.SOMETHING_WENT_WRONG)
      );
    }
  }
);

// get pre recorded voice
export const getPreRecordedVoice = createAsyncThunk(
  "getPreRecordedVoice",
  async (_request: any, { dispatch }) => {
    dispatch(setLoading(true));
    try {
      const url = `get-pre-recorded-voice`;
      const response = await slsApiClient().get(url);
      dispatch(setLoading(false));
      if (response && response?.data?.status) {
        dispatch(setPreRecordedVoices(response?.data?.data));
      } else {
        dispatch(notificationFail(response?.data?.message || Messages.ERROR.NO_DATA_FOUND));
      }
    } catch (error: any) {
      dispatch(setLoading(false));
      dispatch(notificationFail(error?.response?.data?.message || Messages.ERROR.NO_DATA_FOUND));
    }
  }
);

// save pre recorded voice
export const savePreRecordedVoice = createAsyncThunk(
  "savePreRecordedVoice",
  async (_request: any, { dispatch }) => {
    dispatch(setLoading(true));
    try {
      const { recordingFile, digit, setRecordingFile } = _request;
      const formData = new FormData();

      formData.append("recordingFile", recordingFile);
      formData.append("digit", digit);

      const response = await slsApiClient().post("save-pre-recorded-voice", formData);
      dispatch(setLoading(false));
      if (response && response?.data?.status) {
        dispatch(getPreRecordedVoice({}));
        if (setRecordingFile) {
          setRecordingFile(null);
        }
      } else {
        dispatch(notificationFail(response?.data?.message || Messages.ERROR.SOMETHING_WENT_WRONG));
      }
    } catch (error: any) {
      dispatch(setLoading(false));
      dispatch(
        notificationFail(error?.response?.data?.message || Messages.ERROR.SOMETHING_WENT_WRONG)
      );
    }
  }
);

// deletePreRecordedVoice
export const deletePreRecordedVoice = createAsyncThunk(
  "deletePreRecordedVoice",
  async (_request: any, { dispatch }) => {
    dispatch(setLoading(true));
    try {
      const { id, setDeletePopup } = _request;
      const response = await slsApiClient().post("delete-pre-recorded-voice", { id });
      dispatch(setLoading(false));
      if (response && response?.data?.status) {
        if (setDeletePopup) {
          setDeletePopup(false);
        }
        dispatch(getPreRecordedVoice({}));
      } else {
        dispatch(notificationFail(response?.data?.message || Messages.ERROR.SOMETHING_WENT_WRONG));
      }
    } catch (error: any) {
      dispatch(setLoading(false));
      dispatch(
        notificationFail(error?.response?.data?.message || Messages.ERROR.SOMETHING_WENT_WRONG)
      );
    }
  }
);

// Rent manager exist in db
export const rmExistInDb = createAsyncThunk("rmExistInDb", async (_request: any, { dispatch }) => {
  dispatch(setLoading(true));
  try {
    const response = await slsApiClient().get(`check_rm_exist`, {
      params: {
        location_id: _request?.cms_location_id,
        id: _request?.id,
      },
    });
    if (response?.data?.status) {
      _request?.onClose();
      dispatch(notificationSuccess(response?.data?.message));
      dispatch(getLocation({ id: _request?.cms_location_id, cmsLocation: true }));
    } else {
      _request?.onClose();
      dispatch(notificationFail(response?.data?.message || Messages.ERROR.SOMETHING_WENT_WRONG));
    }
    dispatch(setLoading(false));
  } catch (error: any) {
    dispatch(setLoading(false));
    dispatch(
      notificationFail(
        error?.response?.data?.message || error.message || Messages.ERROR.NO_DATA_FOUND
      )
    );
  }
});

// Get Esign template list
export const getESignTemplateList = createAsyncThunk(
  "getESignTemplateList",
  async (_request: any, { dispatch }) => {
    dispatch(setLoading(true));
    try {
      const locationId = _request?.locationId || "";

      const url = `docusign/get-templates-list?location_id=${locationId}`;
      const response = await slsApiClient().get(url);
      dispatch(setLoading(false));
      if (response && response?.data?.status) {
        dispatch(setESignTemplateList(response?.data?.templates));
      } else {
        dispatch(notificationFail(response?.data?.message || Messages.ERROR.NO_DATA_FOUND));
      }
    } catch (error: any) {
      dispatch(setLoading(false));
      dispatch(notificationFail(error?.response?.data?.message || Messages.ERROR.NO_DATA_FOUND));
    }
  }
);

// Create ESign template
export const createESignTemplate = createAsyncThunk(
  "createESignTemplate",
  async (_request: any, { dispatch }) => {
    dispatch(setLoading(true));
    try {
      const formData = new FormData();
      if (_request.attachments) {
        _request.attachments.map((item: any) => formData.append("file[]", item));
      }
      formData.append("template_name", _request.template_name);
      formData.append("location_id", _request.location_id);
      formData.append("html_content", _request.html_content);
      formData.append("is_default", _request.is_default);
      formData.append("is_sublet", _request.is_sublet);
      // const data = {
      //   template_name: _request.template_name,
      //   location_id: _request.location_id,
      //   html_content: _request.html_content,
      //   is_default: _request.is_default,
      //   is_sublet: _request.is_sublet,
      //   attachments: _request.attachments,
      // };
      const response = await slsApiClient().post(`docusign/create-template`, formData);
      dispatch(setLoading(false));
      if (_request.onClose) {
        _request.onClose(false);
      }
      if (response && response?.data?.status) {
        dispatch(getESignTemplateList({ locationId: _request.location_id }));
      } else {
        dispatch(notificationFail(response?.data?.message || Messages.ERROR.NO_DATA_FOUND));
      }
    } catch (error: any) {
      dispatch(setLoading(false));
      dispatch(notificationFail(error?.response?.data?.message || Messages.ERROR.NO_DATA_FOUND));
    }
  }
);

// Edit ESign template
export const editESignTemplate = createAsyncThunk(
  "editESignTemplate",
  async (_request: any, { dispatch }) => {
    dispatch(setLoading(true));
    try {
      const data = {
        templateName: _request.template_name,
        location_id: _request.location_id,
        html_content: _request.html_content,
        is_default: _request.is_default,
        is_sublet: _request.is_sublet,
        templateId: _request.document_id,
        documentId: 1,
      };
      const response = await slsApiClient().put(`docusign/update-template`, data);
      dispatch(setLoading(false));
      if (_request.onClose) {
        _request.onClose(false);
      }
      if (response && response?.data?.status) {
        dispatch(getESignTemplateList({ locationId: _request.location_id }));
      } else {
        dispatch(notificationFail(response?.data?.message || Messages.ERROR.NO_DATA_FOUND));
      }
    } catch (error: any) {
      dispatch(setLoading(false));
      dispatch(notificationFail(error?.response?.data?.message || Messages.ERROR.NO_DATA_FOUND));
    }
  }
);

// delete location templates
export const deleteESignTemplate = createAsyncThunk(
  "deleteESignTemplate",
  async (_request: any, { dispatch }) => {
    dispatch(setLoading(true));
    try {
      const { templateId, locationId } = _request;
      const response = await slsApiClient().delete(`docusign/delete-template/${templateId}`);
      dispatch(setLoading(false));
      if (_request.onCloseDeleteModal) {
        _request.onCloseDeleteModal();
      }
      if (response && response?.data?.status) {
        dispatch(getESignTemplateList({ locationId }));
      } else {
        dispatch(notificationFail(response?.data?.message || Messages.ERROR.NO_DATA_FOUND));
      }
    } catch (error: any) {
      dispatch(setLoading(false));
      dispatch(notificationFail(error?.response?.data?.message || Messages.ERROR.NO_DATA_FOUND));
    }
  }
);

export const getEditUrlESignTemplate = createAsyncThunk(
  "getEditUrlESignTemplate",
  async (_request: any, { dispatch }) => {
    dispatch(setLoading(true));
    try {
      const { setTemplateEditUrl } = _request;
      const data = {
        location_id: _request.locationId,
        template_id: _request.templateId,
      };
      const response = await slsApiClient().post(`docusign/get-template-edit-url`, data);
      dispatch(setLoading(false));
      if (response && response?.data?.status) {
        if (setTemplateEditUrl) {
          setTemplateEditUrl(response?.data.data.url);
        }
      } else {
        dispatch(notificationFail(response?.data?.message || Messages.ERROR.NO_DATA_FOUND));
      }
    } catch (error: any) {
      dispatch(setLoading(false));
      dispatch(notificationFail(error?.response?.data?.message || Messages.ERROR.NO_DATA_FOUND));
    }
  }
);

// Delete Studios
export const deleteStudio = createAsyncThunk("deleteTag", async (_request: any, { dispatch }) => {
  const { id, setDeleteStudioId } = _request;
  dispatch(setLoading(true));
  try {
    const response = await slsApiClient().delete(`delete_studio?studio_id=${id}`);
    if (response?.data?.message === Messages.ERROR.STUDIO_OCCUPIED) {
      dispatch(notificationFail(response?.data?.message || Messages.ERROR.STUDIO_OCCUPIED));
      dispatch(setLoading(false));
    } else {
      dispatch(
        notificationSuccess(response?.data?.message || Messages.SUCCESS.DELETE_STUDIO_SUCCESS)
      );
      dispatch(setLoading(false));
    }
    if (setDeleteStudioId) {
      setDeleteStudioId(false);
    }
  } catch (error: any) {
    dispatch(setLoading(false));
    dispatch(notificationFail(error.message || Messages.ERROR.SOMETHING_WENT_WRONG));
  }
});

// Add/Update location units
export const addUpdateLocationUnits = createAsyncThunk(
  "addUpdateLocationUnits",
  async (_request: any, { dispatch }) => {
    dispatch(setLoading(true));
    try {
      const response = await slsApiClient().post("add-update-studio", _request?.updatedUnits);
      dispatch(setLoading(false));
      if (response?.data?.status) {
        dispatch(notificationSuccess(response?.data?.message));
        dispatch(getLocation({ id: _request?.id, cmsLocation: true }));
        if (_request?.setEditStudio) {
          _request?.setEditStudio(false);
        }
      } else {
        dispatch(notificationFail(Messages.ERROR.SOMETHING_WENT_WRONG));
      }
    } catch (error: any) {
      dispatch(setLoading(false));
      if (!error?.response?.data?.status) {
        if (_request?.setEditStudio) {
          _request?.setEditStudio(true);
        }
        dispatch(notificationFail(Messages.ERROR.NO_DUPLICATE_NAME));
      }
    }
  }
);

// Add/Update location units
export const groupUngroupStudio = createAsyncThunk(
  "groupUngroupStudio",
  async (_request: any, { dispatch }) => {
    dispatch(setLoading(true));
    try {
      const response = await slsApiClient().post("group-studio", _request?.mainData);
      dispatch(setLoading(false));
      if (response?.data?.status) {
        dispatch(notificationSuccess(response?.data?.message));
        dispatch(getLocation({ id: _request?.id, cmsLocation: true }));
      } else {
        dispatch(notificationFail(Messages.ERROR.SOMETHING_WENT_WRONG));
      }
    } catch (error: any) {
      dispatch(setLoading(false));
      dispatch(
        notificationFail(error?.response?.data?.message || Messages.ERROR.SOMETHING_WENT_WRONG)
      );
    }
  }
);

// Ungrouping Studio
export const ungroupStudios = createAsyncThunk(
  "ungroupStudios",
  async (_request: any, { dispatch }) => {
    dispatch(setLoading(true));
    try {
      const response: any = await slsApiClient().post(`studio_ungroup/${_request.unGroupStudio}`);
      dispatch(setLoading(false));
      if (response?.data?.status) {
        if (_request?.setUngroupStudio) {
          _request?.setUngroupStudio(false);
        }
        dispatch(notificationSuccess(response?.data?.message));
        dispatch(getLocation({ id: _request?.id, cmsLocation: true }));
      } else {
        dispatch(notificationFail(Messages.ERROR.SOMETHING_WENT_WRONG));
      }
    } catch (error: any) {
      dispatch(setLoading(false));
      dispatch(
        notificationFail(error?.response?.data?.message || Messages.ERROR.SOMETHING_WENT_WRONG)
      );
    }
  }
);
export const updateWeekend = createAsyncThunk(
  "updateWeekend",
  async (_request: any, { dispatch }) => {
    dispatch(setLoading(true));
    try {
      const {
        locationId,
        weekend,
        weekendTimezone,
        weekendStartdate,
        weekendEnddate,
        weekendMessage,
        recurringRule,
        outOfOffice,
        outOfficeStartdate,
        outOfficeEnddate,
        outOfficeOwnerId,
        setWeekendEditPopup,
        cmsLocationId,
      } = _request;
      const apiReq = {
        location_id: locationId,
        weekend,
        weekend_start_date: moment(weekendStartdate).utc().toISOString(),
        weekend_end_date: moment(weekendEnddate).utc().toISOString(),
        weekend_timezone: weekendTimezone,
        weekend_message: weekendMessage,
        recurring_rule: recurringRule,
        out_of_office: outOfOffice || false,
        out_office_start_date: outOfficeStartdate,
        out_office_end_date: outOfficeEnddate,
        deal_secondary_owner_id: Number(outOfficeOwnerId),
      };

      const response = await slsApiClient().post("update-location-weekend", apiReq);
      dispatch(setLoading(false));
      if (setWeekendEditPopup) {
        setWeekendEditPopup(false);
      }
      if (response && response?.data?.status) {
        dispatch(
          notificationSuccess(response?.data?.message || Messages.ERROR.SOMETHING_WENT_WRONG)
        );
        dispatch(getLocation({ id: cmsLocationId, cmsLocation: true }));
      } else {
        dispatch(notificationFail(response?.data?.message || Messages.ERROR.SOMETHING_WENT_WRONG));
      }
    } catch (error: any) {
      dispatch(setLoading(false));
      dispatch(
        notificationFail(error?.response?.data?.message || Messages.ERROR.SOMETHING_WENT_WRONG)
      );
    }
  }
);
