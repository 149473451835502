import React, { useEffect, useState } from "react";
import MDDialog from "components/MDDialog";
import MDInput from "components/MDInput";
import CustomAutoSearch from "components/CustomAutoSearch";
import { Grid } from "@mui/material";
import Validations from "helper/validations";
import { addUpdateCatrgory } from "store/thunk/solaWebsiteThunk";
import { useAppDispatch, useAppSelector } from "store/store";
import { getPermission } from "helper/services";

function AddUpdate(props: any): JSX.Element {
  const { open, onClose, pageNo, perPage, search, sortBy } = props;
  const dispatch = useAppDispatch();
  const { category } = useAppSelector((state) => state.solaWebSiteSlice);
  const [categoryData, setCategoryData] = useState<any>({});
  const [errors, setErrors] = useState<any>({});
  const [loading] = useState<boolean>(false);

  useEffect(() => {
    if (category && Object.keys(category).length > 0) {
      setCategoryData(category);
    }
  }, [category]);

  const onChangeField = (event: any) => {
    setCategoryData((prev: any) => ({
      ...prev,
      [event.target.name]: event.target.value,
    }));
  };

  const onSave = () => {
    const error = Validations.validateCategoryForm(categoryData);
    setErrors(error);
    if (!Object.keys(error).length) {
      const request = {
        categoryData,
        onClose,
        pageNo,
        perPage,
        search,
        sortBy,
      };
      dispatch(addUpdateCatrgory(request));
    }
  };

  const dialogProps = {
    open,
    onClose,
    onSave,
    saveTbtText: category?.id ? "Update" : "Save",
    title: category?.id ? "Edit Category" : "Add Category",
    size: "lg",
    loading,
  };

  const onChangeAutoComplete = (name: string, value: any) => {
    setCategoryData({ ...categoryData, [name]: value });
  };

  return (
    <MDDialog {...dialogProps}>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12} md={6} lg={6}>
          <MDInput
            label="Name"
            variant="standard"
            fullWidth
            required
            name="name"
            placeholder="Name"
            onChange={onChangeField}
            value={categoryData?.name || ""}
            error={errors && errors?.name ? true : false}
            helperText={errors && errors?.name}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6}>
          <MDInput
            label="Slug"
            variant="standard"
            fullWidth
            name="slug"
            placeholder="Slug"
            onChange={onChangeField}
            value={categoryData?.slug || ""}
            error={errors && errors?.slug ? true : false}
            helperText={errors && errors?.slug}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6}>
          <CustomAutoSearch
            idKey="id"
            nameKey="title"
            fieldLabel="Blogs"
            apiName="blogs"
            responseKey="blogs"
            name="blog"
            value={categoryData?.blog || []}
            onChange={onChangeAutoComplete}
            multiple
            createNewLink={
              getPermission("sola_website", "sola_website_blog") ? "/sola-website/blog" : ""
            }
          />
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6}>
          <CustomAutoSearch
            idKey="id"
            nameKey="title"
            fieldLabel="Deals"
            apiName="deals"
            responseKey="deals"
            name="deal"
            value={categoryData?.deal || []}
            onChange={onChangeAutoComplete}
            multiple
            createNewLink={
              getPermission("mobile_app", "mobile_app_deals") ? "/mobile-app/deals" : ""
            }
          />
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6}>
          <CustomAutoSearch
            idKey="id"
            nameKey="title"
            fieldLabel="Tools"
            apiName="tools_and_resources"
            responseKey="tools_and_resources"
            name="tool"
            value={categoryData?.tool || []}
            onChange={onChangeAutoComplete}
            multiple
            createNewLink={
              getPermission("mobile_app", "mobile_app_tools_resources")
                ? "/mobile-app/tools-and-resources"
                : ""
            }
          />
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6}>
          <CustomAutoSearch
            idKey="id"
            nameKey="title"
            fieldLabel="Videos"
            apiName="videos"
            responseKey="videos"
            name="video"
            value={categoryData?.video || []}
            onChange={onChangeAutoComplete}
            multiple
            createNewLink={
              getPermission("mobile_app", "mobile_app_videos") ? "/mobile-app/videos" : ""
            }
          />
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6}>
          <CustomAutoSearch
            idKey="id"
            nameKey="name"
            fieldLabel="Tags"
            apiName="tags"
            responseKey="tags"
            name="tag"
            value={categoryData?.tag || []}
            onChange={onChangeAutoComplete}
            multiple
            createNewLink={
              getPermission("sola_website", "sola_website_tags") ? "/sola-website/tags" : ""
            }
          />
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6}>
          <CustomAutoSearch
            idKey="id"
            nameKey="title"
            fieldLabel="Franchise Articles"
            apiName="franchise_articles"
            responseKey="franchise_articles"
            name="franchise_article"
            value={categoryData?.franchise_article || []}
            onChange={onChangeAutoComplete}
            multiple
            createNewLink={`/franchisee-website/press-${"&"}-blog`}
          />
        </Grid>
      </Grid>
    </MDDialog>
  );
}

export default AddUpdate;
