import * as React from "react";
import { useState, useEffect } from "react";
import { Card, Icon } from "@mui/material";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import MDBox from "components/MDBox";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import { setAvailableNumnersList } from "store/slices/repairMaintenanceSlice";
import { useAppDispatch } from "store/store";
import MDTabPanel from "components/MDTabPanel";
import MDButton from "components/MDButton";
import { useLocation } from "react-router-dom";
import SmsRequestList from "./smsRequestList";
import ActivePhoneNumberList from "./activePhoneNumberList";
import BuyNumberPopup from "./buyNumberPopup";

function Index(): JSX.Element {
  const dispatch = useAppDispatch();
  const locationState: any = useLocation();
  const [tabValue, setTabValue] = useState(0);
  const [isBuyPopupOpen, setIsBuyPopupOpen] = useState(false);

  useEffect(() => {
    if (locationState?.state?.defaultTab) {
      setTabValue(locationState?.state?.defaultTab);
    }
  }, [locationState]);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue);
  };

  const closeBuyNumberPopup = () => {
    setIsBuyPopupOpen(false);
    dispatch(setAvailableNumnersList([]));
  };
  const openBuyPopup = () => {
    setIsBuyPopupOpen(true);
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={4} pb={3}>
        <Card>
          <MDBox
            bgColor="dark"
            color="white"
            coloredShadow="dark"
            borderRadius="xl"
            alignItems="center"
            justifyContent="space-between"
            pt={1}
            pb={1}
            pl={2}
            pr={2}
            ml={2}
            mr={2}
            display="flex"
            mt={-3}
          >
            <p className="page-header-label">Phone Numbers</p>
            <MDButton
              variant="gradient"
              color="light"
              size="small"
              className="xs-small"
              onClick={() => openBuyPopup()}
            >
              <Icon sx={{ fontWeight: "bold" }}>add</Icon> Buy Number
            </MDButton>
          </MDBox>
          <MDBox
            borderRadius="xl"
            alignItems="center"
            pt={1}
            pb={1}
            pl={2}
            pr={2}
            ml={2}
            mr={2}
            mt={2}
          >
            <Tabs
              className="location-tabs"
              indicatorColor="secondary"
              classes={{ indicator: "info" }}
              value={tabValue}
              onChange={handleChange}
            >
              <Tab
                label={
                  <MDBox py={0.5} px={2} color="inherit">
                    SMS Request
                  </MDBox>
                }
              />
              <Tab
                label={
                  <MDBox py={0.5} px={2} color="inherit">
                    Twillio Phone Numbers
                  </MDBox>
                }
              />
            </Tabs>
          </MDBox>
          <MDTabPanel value={tabValue} index={0}>
            <SmsRequestList />
          </MDTabPanel>
          <MDTabPanel value={tabValue} index={1}>
            <MDBox py={0.5} px={2} color="inherit">
              <ActivePhoneNumberList />
            </MDBox>
          </MDTabPanel>
        </Card>
        {isBuyPopupOpen && (
          <BuyNumberPopup open={isBuyPopupOpen} onClose={() => closeBuyNumberPopup()} />
        )}
      </MDBox>
    </DashboardLayout>
  );
}

export default Index;
