import { Grid, AppBar, Tabs, Tab } from "@mui/material";
import constants from "helper/constants";
import MDBox from "components/MDBox";
import MDTabPanel from "components/MDTabPanel";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import { useState } from "react";
import AddRoyalty from "./addRoyaltyTable";
import MasterData from "./masterDataTable";

function ReportsMasterData() {
  const [tabValue, setTabValue] = useState<number>(0);
  const changeTab = (e: any, value: number) => {
    setTabValue(value);
  };

  const getComponent = (alias: any) => {
    switch (alias) {
      case "masterData":
        return <MasterData />;
      case "addroyalty":
        return <AddRoyalty />;
      default:
        return null;
    }
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox mt={1}>
        <Grid container sx={{ display: "flex", justifyContent: "space-between" }}>
          <Grid item xs={12} sm={8} lg={6}>
            <AppBar position="static">
              <Tabs className="master-data-dasboard-tabs" value={tabValue} onChange={changeTab}>
                {constants?.masterDataTabs?.map((obj: any) => (
                  <Tab label={obj.display_name} key={obj.alias} />
                ))}
              </Tabs>
            </AppBar>
          </Grid>
        </Grid>
        <MDBox>
          {constants?.masterDataTabs?.map((obj: any, key: number) => (
            <MDTabPanel index={key} padding={0} value={tabValue} key={obj.alias}>
              {getComponent(obj.alias)}
            </MDTabPanel>
          ))}
        </MDBox>
      </MDBox>
    </DashboardLayout>
  );
}

export default ReportsMasterData;
