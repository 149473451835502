import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "store/store";
import MDDialog from "components/MDDialog";
import MDInput from "components/MDInput";
import CustomPhoneInput from "components/CustomPhoneInput";
import {
  // Autocomplete,
  InputLabel,
  Select,
  MenuItem,
  Checkbox,
  Grid,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
} from "@mui/material";
import Validations from "helper/validations";
import { saveContactIquiries } from "store/thunk/systemAdmin";
// import FormField from "layouts/pages/account/components/FormField";
import MDTypography from "components/MDTypography";
import MDDatePicker from "components/MDDatePicker";
import { scrollToError, getPermission, adminSuperAdmin, getCRMCreateAccess } from "helper/services";
import CustomAutoSearch from "components/CustomAutoSearch";
import CustomErrorMessage from "components/CustomErrorMessage";

function ViewDetails(props: any): JSX.Element {
  const dispatch = useAppDispatch();

  const { locationList } = useAppSelector((state) => state.systemAdminSlice);
  const { open, onClose, updateData, pageNo, perPage, search, sortBy } = props;
  const [isUpdate, setIsUpdate] = useState<any>(false);
  const [contactInquiries, setContactInquiries] = useState<any>({
    how_can_we_help_you: "Other",
  });
  // const [imagePreview, setImagePreview] = useState<any>("");
  const [errors, setErrors] = useState<any>({});
  // const [locationListData, setLocationListData] = useState<any>([]);

  useEffect(() => {
    // setLocationListData(locationList);
  }, [locationList]);

  const onSave = () => {
    const error = Validations.validateContactInquiries(contactInquiries);
    setErrors(error);
    if (!Object.keys(error).length) {
      const tempData = JSON.parse(JSON.stringify(contactInquiries));
      tempData.services = tempData?.services?.length ? tempData?.services.join(", ") : "";
      const req = {
        contactInquiriesData: {
          ...tempData,
          location: tempData.location,
          location_id: tempData.location.id,
          sola_pro_lead_status: "New",
        },
        onClose,
        isUpdate,
        pageNo,
        perPage,
        search,
        sortBy,
        isLead: true,
        type: "Other",
      };
      dispatch(saveContactIquiries(req));
    } else {
      scrollToError(error);
    }
  };
  useEffect(() => {
    if (updateData) {
      setIsUpdate(true);
      const tempData = JSON.parse(JSON.stringify(updateData));
      tempData.services = tempData?.services?.length ? tempData?.services.split(", ") : [];
      setContactInquiries(tempData);
      setContactInquiries(updateData);
    }
  }, [updateData]);

  const onChangeField = (e: any, field: string) => {
    if (field === "how_can_we_help_you") {
      setContactInquiries({ ...contactInquiries, [field]: e.target.value, services: [] });
    } else {
      setContactInquiries({ ...contactInquiries, [field]: e.target.value });
    }
  };

  const onChangeCheckBoxField = (e: any, field: string) => {
    setContactInquiries({ ...contactInquiries, [field]: e.target.checked });
  };

  const onChangeDate = (value: any) => {
    setContactInquiries({ ...contactInquiries, created_at: new Date(value).toISOString() });
  };

  const onChangeAutoComplete = (name: string, value: any) => {
    setContactInquiries({ ...contactInquiries, [name]: value });
  };

  const onChangeService = (e: any) => {
    let tempServices = contactInquiries?.services || [];
    if (e.target.checked) {
      tempServices.push(e.target.value);
    } else {
      tempServices = tempServices.filter((s: string) => s !== e.target.value);
    }
    setContactInquiries({ ...contactInquiries, services: tempServices });
  };

  const getServices = () => {
    let services: any = [];
    if (contactInquiries?.how_can_we_help_you === "Request leasing information") {
      services = [
        "Hair",
        "Skincare",
        "Makeup",
        "Massage",
        "Nails",
        "Lashes",
        "Waxing",
        "Permanent Makeup / Tattoo",
        "Other",
      ];
    } else if (
      contactInquiries?.how_can_we_help_you === "Book an appointment with a salon professional"
    ) {
      services = [
        "Hair",
        "Highlights (Color)",
        "Nails",
        "Eyelash Extensions",
        "Pedicure",
        "Lashes",
        "Waxing",
        "Permanent Makeup / Tattoo",
        "Other",
      ];
    }
    if (services?.length) {
      return services?.map((s: any) => (
        <Grid item xs={6} sm={4} md={4} lg={4} key={s}>
          <FormControl component="fieldset">
            <FormControlLabel
              value={s}
              control={
                <Checkbox
                  checked={contactInquiries?.services?.includes(s) || false}
                  name="services"
                  onChange={onChangeService}
                  inputProps={{ "aria-label": "controlled" }}
                />
              }
              label={s}
              labelPlacement="end"
              sx={{ display: "flex" }}
            />
          </FormControl>
        </Grid>
      ));
    }
    return null;
  };

  const dialogProps = {
    open,
    onSave,
    onClose,
    title: isUpdate ? "Update Unknown Leads" : "Add Unknown Leads",
    size: "lg",
    saveTbtText: isUpdate ? "Update" : "Save",
  };
  return (
    <MDDialog {...dialogProps}>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12} md={6} lg={6}>
          <MDInput
            label="Name"
            variant="standard"
            fullWidth
            required
            name="name"
            onChange={(event: any) => onChangeField(event, "name")}
            placeholder="name"
            value={contactInquiries?.name}
            error={errors && errors?.name ? true : false}
            helperText={errors && errors?.name}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6}>
          <MDInput
            label="Email"
            variant="standard"
            fullWidth
            required
            name="email"
            onChange={(event: any) => onChangeField(event, "email")}
            placeholder="email"
            value={contactInquiries?.email}
            error={errors && errors?.email ? true : false}
            helperText={errors && errors?.email}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6}>
          <MDInput
            variant="standard"
            fullWidth
            required
            label="Phone"
            name="phone"
            placeholder="(000) 000-0000"
            id="phone-input"
            onChange={(event: any) => onChangeField(event, "phone")}
            InputProps={{
              inputComponent: CustomPhoneInput as any,
            }}
            value={contactInquiries?.phone}
            error={errors && errors.phone ? true : false}
            helperText={errors && errors.phone}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6}>
          <CustomAutoSearch
            idKey="id"
            nameKey="name"
            fieldLabel="Locations"
            apiName="locations"
            responseKey="locations"
            name="location"
            required
            value={contactInquiries?.location || null}
            onChange={onChangeAutoComplete}
            error={errors && errors?.location}
            createNewLink={
              adminSuperAdmin() && getPermission("locations", "locations_location")
                ? "/locations"
                : ""
            }
            usersData={
              !adminSuperAdmin()
                ? getCRMCreateAccess("add_access", "crm", "crm_unknown_leads")
                : null
            }
          />
          {/* <Autocomplete
            id="location"
            options={locationListData}
            getOptionLabel={(option) => option.name}
            value={
              locationListData?.find((v: any) => v.id === contactInquiries?.location_id) || null
            }
            onChange={(event: any, newValue: any) => {
              setContactInquiries({ ...contactInquiries, location_id: newValue.id });
            }}
            renderOption={(props: any, option: any) => (
              <li {...props} key={option.id}>
                {option.name}
              </li>
            )}
            renderInput={(params: any) => (
              <FormField {...params} label="Locations" InputLabelProps={{ shrink: true }} />
            )}
          /> */}
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6}>
          <FormControl>
            <MDTypography variant="button" fontWeight="regular" opacity={0.8}>
              How Can We Help You? <span className="required_star">*</span>
            </MDTypography>
            <RadioGroup
              name="how_can_we_help_you"
              value={contactInquiries?.how_can_we_help_you || ""}
              onChange={(event: any) => onChangeField(event, "how_can_we_help_you")}
            >
              <FormControlLabel
                value="Request leasing information"
                control={<Radio />}
                label="Request leasing information"
                disabled
              />
              <FormControlLabel
                value="Book an appointment with a salon professional"
                control={<Radio />}
                label="Book an appointment with a salon professional"
                disabled
              />
              <FormControlLabel value="Other" control={<Radio />} label="Other" disabled />
            </RadioGroup>
            {errors && errors?.how_can_we_help_you && (
              <CustomErrorMessage message={errors?.how_can_we_help_you} />
            )}
          </FormControl>
          {/* <MDInput
            label="How can We Help You ?"
            multiline
            rows={3}
            variant="outlined"
            fullWidth
            name="how_can_we_help_you"
            onChange={(event: any) => onChangeField(event, "how_can_we_help_you")}
            placeholder="How can We Help You ?"
            value={contactInquiries?.how_can_we_help_you || ""}
          /> */}
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6}>
          <Grid container>
            {contactInquiries?.how_can_we_help_you &&
              contactInquiries?.how_can_we_help_you !== "Other" && (
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <MDTypography variant="button" fontWeight="regular" opacity={0.8}>
                    Services <span className="required_star">*</span>
                  </MDTypography>
                </Grid>
              )}
            {getServices()}
            {errors && errors?.services && <CustomErrorMessage message={errors?.services} />}
          </Grid>
          {/* <MDInput
            multiline
            rows={3}
            label="Services"
            variant="outlined"
            fullWidth
            name="services"
            onChange={(e: any) => onChangeField(e, "services")}
            placeholder="Services"
            value={contactInquiries?.services ? contactInquiries.services : ""}
          /> */}
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <FormControl fullWidth>
            <InputLabel id="contact-preference-label">Contact Preference</InputLabel>
            <Select
              fullWidth
              labelId="contact-preference-label"
              variant="outlined"
              id="contact_preference"
              value={contactInquiries?.contact_preference && contactInquiries?.contact_preference}
              label="Contact Preference"
              sx={{ padding: 0 }}
              onChange={(event: any) => onChangeField(event, "contact_preference")}
              className="height45"
            >
              <MenuItem value="phone">Phone</MenuItem>
              <MenuItem value="email">Email</MenuItem>
              <MenuItem value="text">Text</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6}>
          <MDInput
            multiline
            rows={3}
            label="Message"
            variant="outlined"
            fullWidth
            required
            name="message"
            onChange={(e: any) => onChangeField(e, "message")}
            placeholder="Message"
            value={contactInquiries?.message ? contactInquiries.message : ""}
            error={errors && errors.message ? true : false}
            helperText={errors && errors.message}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6}>
          <MDInput
            multiline
            rows={3}
            label="Request URL"
            variant="outlined"
            fullWidth
            name="request_url"
            onChange={(e: any) => onChangeField(e, "request_url")}
            placeholder="Request URL"
            value={contactInquiries?.request_url ? contactInquiries.request_url : ""}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6}>
          <MDInput
            label="State"
            variant="standard"
            fullWidth
            name="state"
            onChange={(event: any) => onChangeField(event, "state")}
            placeholder="state"
            value={contactInquiries?.state}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6}>
          <MDInput
            label="Zip Code"
            variant="standard"
            fullWidth
            name="zip_code"
            onChange={(event: any) => onChangeField(event, "zip_code")}
            placeholder="zip_code"
            value={contactInquiries?.zip_code}
          />
        </Grid>

        <Grid item xs={12} sm={12} md={6} lg={6}>
          <MDInput
            label="Prospect Origin"
            variant="standard"
            fullWidth
            name="send_email_to_prospect"
            onChange={(event: any) => onChangeField(event, "send_email_to_prospect")}
            placeholder="Prospect Origin"
            value={contactInquiries?.send_email_to_prospect}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6}>
          <MDInput
            label="UTM Source"
            variant="standard"
            fullWidth
            name="utm_source"
            onChange={(event: any) => onChangeField(event, "utm_source")}
            placeholder="UTM Source"
            value={contactInquiries?.utm_source}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6}>
          <MDInput
            label="UTM Medium"
            variant="standard"
            fullWidth
            name="utm_medium"
            onChange={(event: any) => onChangeField(event, "utm_medium")}
            placeholder="UTM Medium"
            value={contactInquiries?.utm_medium}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6}>
          <MDInput
            label="UTM Campaign"
            variant="standard"
            fullWidth
            name="utm_campaign"
            onChange={(event: any) => onChangeField(event, "utm_campaign")}
            placeholder="UTM Campaign"
            value={contactInquiries?.utm_campaign}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6}>
          <MDInput
            label="UTM Content"
            variant="standard"
            fullWidth
            name="utm_content"
            onChange={(event: any) => onChangeField(event, "utm_content")}
            placeholder="UTM Content"
            value={contactInquiries?.utm_content}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6} className="select-container">
          <FormControl fullWidth>
            <InputLabel id="newsletter-label">Newsletter</InputLabel>
            <Select
              fullWidth
              labelId="newsletter-label"
              variant="outlined"
              id="newsletter"
              value={contactInquiries?.newsletter ? 1 : 0}
              label="Newsletter"
              sx={{ padding: 0 }}
              onChange={(event: any) => onChangeField(event, "newsletter")}
              className="height45"
            >
              <MenuItem value={1}>Yes</MenuItem>
              <MenuItem value={0}>No</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6} display="flex" alignItems="center">
          <FormControl component="fieldset">
            <FormControlLabel
              value={contactInquiries?.dont_see_your_location}
              control={
                <Checkbox
                  checked={contactInquiries?.dont_see_your_location}
                  name="dont_see_your_location"
                  onChange={(event: any) => onChangeCheckBoxField(event, "dont_see_your_location")}
                  inputProps={{ "aria-label": "controlled" }}
                />
              }
              label="Dont See Your Location"
            />
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6}>
          <FormControl fullWidth>
            <MDTypography mr={2} variant="button">
              Created At
            </MDTypography>
            <MDDatePicker
              onChange={(value: any) => onChangeDate(value)}
              options={{
                enableTime: false,
                dateFormat: "m/d/Y",
                time_24hr: true,
              }}
            />
          </FormControl>
        </Grid>
      </Grid>
    </MDDialog>
  );
}

export default ViewDetails;
