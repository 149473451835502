import { useEffect, useState } from "react";
import {
  ButtonGroup,
  Card,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  MenuItem,
  OutlinedInput,
  Select,
  Paper,
} from "@mui/material";
import CustomErrorMessage from "components/CustomErrorMessage";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import { blockInvalidChar } from "helper/services";

function DateFee(props: any): JSX.Element {
  const { onChangeField, rmDetails, errors, setRmDetails, getAccessTypePermission } = props;
  const [showDepositAmount, setShowDepositAmount] = useState(
    rmDetails?.security_deposit_amount_type
  );
  const [insurenceChargeFee, setInsurenceChargeFee] = useState(
    rmDetails?.insurance_charge_frequency
  );
  const [annualPriceIncrease, setAnnualPriceIncrease] = useState(
    rmDetails?.annual_price_increase_type
  );

  useEffect(() => {
    if (rmDetails) {
      setInsurenceChargeFee(rmDetails?.insurance_charge_frequency || "");
      setAnnualPriceIncrease(rmDetails?.annual_price_increase_type || "");
      setShowDepositAmount(rmDetails?.security_deposit_amount_type || "");
    }
  }, [rmDetails]);

  const handleBtnGroupClick = (event: any) => {
    setShowDepositAmount(event.target.name);
    const tempRM = JSON.parse(JSON.stringify(rmDetails));
    tempRM.security_deposit_amount_type = event.target.name;
    tempRM.security_deposit_amount = "";
    setRmDetails(tempRM);
  };

  const handlechangeInsurenceFee = (event: any) => {
    setInsurenceChargeFee(event.target.name);
    const obj = {
      target: {
        name: "insurance_charge_frequency",
        value: event.target.name,
      },
    };
    onChangeField(obj);
  };

  const handleAnnualPriceIncrease = (event: any) => {
    setAnnualPriceIncrease(event.target.name);
    const tempRM = JSON.parse(JSON.stringify(rmDetails));
    tempRM.annual_price_increase_type = event.target.name;
    tempRM.annual_price_increase = "";
    setRmDetails(tempRM);
  };

  const onChangeCheckbox = (event: any) => {
    const tempRM = JSON.parse(JSON.stringify(rmDetails));
    tempRM.free_paint = event.target.checked;
    setRmDetails(tempRM);
  };
  const renderPercentageOptions = () => {
    const menusItems = [];
    for (let i = 0; i <= 100; i++) {
      menusItems.push(<MenuItem value={`${i}%`}>{`${i}%`}</MenuItem>);
    }
    return menusItems;
  };

  return (
    <Card id="date_fee" sx={{ overflow: "visible" }}>
      <MDBox p={3}>
        <MDTypography variant="h5">Default Dates & Fees</MDTypography>
      </MDBox>
      <MDBox component="form">
        <MDBox px={3} pb={3}>
          <Grid container>
            {/* Lease */}
            <Grid item xs={12} sm={12} md={12} lg={12} mb={1}>
              <MDTypography variant="h6">Lease</MDTypography>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <Paper variant="outlined">
                <MDBox p={2}>
                  <Grid container spacing={3}>
                    <Grid item xs={12} sm={3} md={3} lg={3}>
                      <MDTypography variant="h6">Rent Period :</MDTypography>
                    </Grid>
                    <Grid item xs={12} sm={9} md={9} lg={9}>
                      <FormControl fullWidth>
                        <Select
                          className="height45"
                          name="rent_period"
                          value={rmDetails?.rent_period || ""}
                          onChange={onChangeField}
                          disabled={getAccessTypePermission("location_rentmanager", "edit_access")}
                        >
                          <MenuItem value="Weekly">Weekly</MenuItem>
                          <MenuItem value="Monthly">Monthly</MenuItem>
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={3} md={3} lg={3}>
                      <MDTypography variant="h6">Rent Due Date :</MDTypography>
                    </Grid>
                    <Grid item xs={12} sm={9} md={9} lg={9}>
                      <FormControl fullWidth>
                        <Select
                          className="height45"
                          name="rent_due_date"
                          value={rmDetails?.rent_due_date || ""}
                          onChange={onChangeField}
                          disabled={getAccessTypePermission("location_rentmanager", "edit_access")}
                        >
                          <MenuItem value="Monday">Monday</MenuItem>
                          <MenuItem value="Tuesday">Tuesday</MenuItem>
                          <MenuItem value="Wednesday">Wednesday</MenuItem>
                          <MenuItem value="Thursday">Thursday</MenuItem>
                          <MenuItem value="Friday">Friday</MenuItem>
                          <MenuItem value="Saturday">Saturday</MenuItem>
                          <MenuItem value="Sunday">Sunday</MenuItem>
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={3} md={3} lg={3}>
                      <MDTypography variant="h6">Lease Term :</MDTypography>
                    </Grid>
                    <Grid item xs={12} sm={9} md={9} lg={9}>
                      <FormControl fullWidth>
                        <Select
                          className="height45"
                          name="lease_term"
                          value={rmDetails?.lease_term || ""}
                          onChange={onChangeField}
                          disabled={getAccessTypePermission("location_rentmanager", "edit_access")}
                        >
                          <MenuItem value="MTM">MTM</MenuItem>
                          <MenuItem value="6">6 Month</MenuItem>
                          <MenuItem value="12">12 Month</MenuItem>
                          <MenuItem value="18">18 Month</MenuItem>
                          <MenuItem value="24">24 Month</MenuItem>
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={3} md={3} lg={3}>
                      <MDTypography variant="h6">Security Deposit Amount :</MDTypography>
                    </Grid>
                    <Grid item xs={12} sm={9} md={9} lg={9}>
                      <ButtonGroup variant="contained" color="info" sx={{ boxShadow: "none" }}>
                        <MDButton
                          variant="gradient"
                          name="flat"
                          color={showDepositAmount === "flat" ? "dark" : "light"}
                          onClick={handleBtnGroupClick}
                          size="small"
                          disabled={getAccessTypePermission("location_rentmanager", "edit_access")}
                        >
                          Flat Fee
                        </MDButton>
                        <MDButton
                          variant="gradient"
                          color={showDepositAmount === "rent_retio" ? "dark" : "light"}
                          name="rent_retio"
                          onClick={handleBtnGroupClick}
                          size="small"
                          disabled={getAccessTypePermission("location_rentmanager", "edit_access")}
                        >
                          Rent Ratio
                        </MDButton>
                      </ButtonGroup>
                      <MDBox mt={2}>
                        {showDepositAmount === "rent_retio" && (
                          <FormControl sx={{ width: 224 }}>
                            <Select
                              className="height45"
                              value={rmDetails?.security_deposit_amount || ""}
                              name="security_deposit_amount"
                              onChange={onChangeField}
                              disabled={getAccessTypePermission(
                                "location_rentmanager",
                                "edit_access"
                              )}
                            >
                              {rmDetails?.rent_period === "Weekly" ? (
                                <MenuItem value="2 Weekly Rent">2 Weekly Rent</MenuItem>
                              ) : (
                                <MenuItem value="2 Monthly Rent">2 Monthly Rent</MenuItem>
                              )}
                              {rmDetails?.rent_period === "Weekly" ? (
                                <MenuItem value="2.5 Weekly Rent">2.5 Weekly Rent</MenuItem>
                              ) : (
                                <MenuItem value="2.5 Monthly Rent">2.5 Monthly Rent</MenuItem>
                              )}
                              {rmDetails?.rent_period === "Weekly" ? (
                                <MenuItem value="3 Weekly Rent">3 Weekly Rent</MenuItem>
                              ) : (
                                <MenuItem value="3 Monthly Rent">3 Monthly Rent</MenuItem>
                              )}
                            </Select>
                          </FormControl>
                        )}
                        {showDepositAmount === "flat" && (
                          <OutlinedInput
                            type="number"
                            value={rmDetails?.security_deposit_amount || ""}
                            name="security_deposit_amount"
                            onChange={onChangeField}
                            error={errors && errors.security_deposit_amount ? true : false}
                            startAdornment="$"
                            disabled={getAccessTypePermission(
                              "location_rentmanager",
                              "edit_access"
                            )}
                          />
                        )}
                        {errors && errors.security_deposit_amount && (
                          <CustomErrorMessage message={errors.security_deposit_amount} />
                        )}
                      </MDBox>
                    </Grid>
                  </Grid>
                </MDBox>
              </Paper>
            </Grid>

            {/* Insurence */}
            <Grid item xs={12} sm={12} md={12} lg={12} mt={3} mb={1}>
              <MDTypography variant="h6">Insurance</MDTypography>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <Paper variant="outlined">
                <MDBox p={2}>
                  <Grid item xs={12} sm={3} md={3} lg={3}>
                    <MDTypography variant="h6">Insurance Term :</MDTypography>
                  </Grid>
                  <Grid item xs={12} sm={9} md={9} lg={9}>
                    <FormControl fullWidth>
                      <Select
                        className="height45"
                        value={rmDetails?.insurance_term || ""}
                        name="insurance_term"
                        onChange={onChangeField}
                        disabled={getAccessTypePermission("location_rentmanager", "edit_access")}
                      >
                        <MenuItem value="6 Month">6 Month</MenuItem>
                        <MenuItem value="12 Month">12 Month</MenuItem>
                        <MenuItem value="18 Month">18 Month</MenuItem>
                        <MenuItem value="24 Month">24 Month</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={3} md={3} lg={3}>
                    <MDTypography variant="h6">Insurance Fee :</MDTypography>
                  </Grid>
                  <Grid item xs={12} sm={9} md={9} lg={9}>
                    <OutlinedInput
                      type="number"
                      value={rmDetails?.insurance_fee || ""}
                      name="insurance_fee"
                      onChange={onChangeField}
                      error={errors && errors.insurance_fee ? true : false}
                      startAdornment="$"
                      disabled={getAccessTypePermission("location_rentmanager", "edit_access")}
                    />
                    {errors && errors.insurance_fee && (
                      <CustomErrorMessage message={errors.insurance_fee} />
                    )}
                  </Grid>

                  <Grid item xs={12} sm={3} md={3} lg={3}>
                    <MDTypography variant="h6">Charge Fee :</MDTypography>
                  </Grid>
                  <Grid item xs={12} sm={9} md={9} lg={9}>
                    <ButtonGroup variant="contained" color="info" sx={{ boxShadow: "none" }}>
                      <MDButton
                        variant="gradient"
                        name="weekly"
                        color={insurenceChargeFee === "weekly" ? "dark" : "light"}
                        onClick={handlechangeInsurenceFee}
                        size="small"
                        disabled={getAccessTypePermission("location_rentmanager", "edit_access")}
                      >
                        Weekly
                      </MDButton>
                      <MDButton
                        variant="gradient"
                        color={insurenceChargeFee === "annually" ? "dark" : "light"}
                        name="annually"
                        onClick={handlechangeInsurenceFee}
                        size="small"
                        disabled={getAccessTypePermission("location_rentmanager", "edit_access")}
                      >
                        Annually
                      </MDButton>
                    </ButtonGroup>
                  </Grid>
                </MDBox>
              </Paper>
            </Grid>

            {/* Payment Schedule */}
            <Grid item xs={12} sm={12} md={12} lg={12} mt={3} mb={1}>
              <MDTypography variant="h6">Other Fees</MDTypography>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <Paper variant="outlined">
                <MDBox p={2}>
                  <Grid item xs={12} sm={3} md={3} lg={3}>
                    <MDTypography variant="h6">Annual Price Increase :</MDTypography>
                  </Grid>
                  <Grid item xs={12} sm={9} md={9} lg={9}>
                    <ButtonGroup variant="contained" color="info" sx={{ boxShadow: "none" }}>
                      <MDButton
                        variant="gradient"
                        name="flat"
                        color={annualPriceIncrease === "flat" ? "dark" : "light"}
                        onClick={handleAnnualPriceIncrease}
                        size="small"
                        disabled={getAccessTypePermission("location_rentmanager", "edit_access")}
                      >
                        Flat Fee
                      </MDButton>
                      <MDButton
                        variant="gradient"
                        color={annualPriceIncrease === "percentage" ? "dark" : "light"}
                        name="percentage"
                        onClick={handleAnnualPriceIncrease}
                        size="small"
                        disabled={getAccessTypePermission("location_rentmanager", "edit_access")}
                      >
                        Percentage
                      </MDButton>
                    </ButtonGroup>
                    <MDBox mt={2}>
                      {annualPriceIncrease === "percentage" && (
                        <FormControl fullWidth={false} sx={{ width: "200px" }}>
                          <Select
                            className="height45"
                            value={rmDetails?.annual_price_increase || ""}
                            name="annual_price_increase"
                            onChange={onChangeField}
                            disabled={getAccessTypePermission(
                              "location_rentmanager",
                              "edit_access"
                            )}
                          >
                            {renderPercentageOptions()}
                          </Select>
                        </FormControl>
                      )}
                      {annualPriceIncrease === "flat" && (
                        <OutlinedInput
                          type="number"
                          value={rmDetails?.annual_price_increase || ""}
                          name="annual_price_increase"
                          onChange={onChangeField}
                          error={errors && errors.annual_price_increase ? true : false}
                          startAdornment="$"
                          disabled={getAccessTypePermission("location_rentmanager", "edit_access")}
                        />
                      )}
                      {errors && errors.annual_price_increase && (
                        <CustomErrorMessage message={errors.annual_price_increase} />
                      )}
                    </MDBox>
                  </Grid>
                </MDBox>
              </Paper>
            </Grid>

            {/* Default Move-In Incentives */}
            <Grid item xs={12} sm={12} md={12} lg={12} mt={3} mb={1}>
              <MDTypography variant="h6">Default Move-In Incentives</MDTypography>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <Paper variant="outlined">
                <MDBox p={2}>
                  <Grid item xs={12} sm={3} md={3} lg={3}>
                    <MDTypography variant="h6">
                      Weeks Free Rent<span className="required_star">*</span> :
                    </MDTypography>
                  </Grid>
                  <Grid item xs={12} sm={9} md={9} lg={9}>
                    <OutlinedInput
                      type="number"
                      value={rmDetails?.weeks_free_rent || ""}
                      name="weeks_free_rent"
                      onKeyDown={blockInvalidChar}
                      onChange={onChangeField}
                      error={errors && errors.weeks_free_rent ? true : false}
                      required
                      inputProps={{
                        min: 0,
                      }}
                      disabled={getAccessTypePermission("location_rentmanager", "edit_access")}
                    />
                    {errors && errors.weeks_free_rent && (
                      <CustomErrorMessage message={errors.weeks_free_rent} />
                    )}
                  </Grid>
                  <Grid item xs={12} sm={3} md={3} lg={3}>
                    <MDTypography variant="h6">
                      Months Free Insurance<span className="required_star">*</span>
                    </MDTypography>
                  </Grid>
                  <Grid item xs={12} sm={9} md={9} lg={9}>
                    <OutlinedInput
                      type="number"
                      value={rmDetails?.months_free_insurence || ""}
                      name="months_free_insurence"
                      onKeyDown={blockInvalidChar}
                      onChange={onChangeField}
                      error={errors && errors.months_free_insurence ? true : false}
                      inputProps={{
                        min: 0,
                      }}
                      disabled={getAccessTypePermission("location_rentmanager", "edit_access")}
                    />
                    {errors && errors.months_free_insurence && (
                      <CustomErrorMessage message={errors.months_free_insurence} />
                    )}
                  </Grid>
                  <Grid item xs={12} sm={3} md={3} lg={3}>
                    <FormControl component="fieldset">
                      <FormGroup aria-label="position" row>
                        <FormControlLabel
                          value="free_paint"
                          control={
                            <Checkbox
                              checked={rmDetails?.free_paint || false}
                              name="free_paint"
                              onChange={onChangeCheckbox}
                              inputProps={{ "aria-label": "controlled" }}
                              disabled={getAccessTypePermission(
                                "location_rentmanager",
                                "edit_access"
                              )}
                            />
                          }
                          label="Free Paint"
                          labelPlacement="end"
                        />
                      </FormGroup>
                    </FormControl>
                  </Grid>
                </MDBox>
              </Paper>
            </Grid>
          </Grid>
        </MDBox>
      </MDBox>
    </Card>
  );
}

export default DateFee;
