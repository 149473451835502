import { useState, useEffect } from "react";
import { useAppDispatch, useAppSelector } from "store/store";
// react-router components
import { useLocation, Link, useNavigate } from "react-router-dom";
// @material-ui core components
import { AppBar, Toolbar, IconButton, Menu, Icon, Card, CardContent, Tooltip } from "@mui/material";
import MDBox from "components/MDBox";
import MDIconButton from "components/MDIconButton";
import MDAvatar from "components/MDAvatar";
import Breadcrumbs from "examples/Breadcrumbs";
import NotificationItem from "examples/Items/NotificationItem";
// import DefaultItem from "examples/Items/DefaultItem";
// Custom styles for DashboardNavbar
import {
  navbar,
  navbarContainer,
  navbarRow,
  navbarIconButton,
  navbarDesktopMenu,
  navbarMobileMenu,
} from "examples/Navbars/DashboardNavbar/styles";

// Material Dashboard 2 PRO React context
import { useMaterialUIController, setMiniSidenav } from "context";
import { getAllNotifications, logout, markAsRead, updateDealId } from "store/thunk/authThunk";
import userDefaultImg from "assets/images/user_default_image.png";
import CustomAssociationDropdown from "components/CustomAssociationDropdown";
import { userRoles, getAuthUser, isFranchisie } from "helper/services";
import MDTypography from "components/MDTypography";
import moment from "moment";
import MDButton from "components/MDButton";
import ResetPassword from "layouts/pages/system-admin/connect-users/resetPassword";

// Declaring prop types for DashboardNavbar
interface Props {
  absolute?: boolean;
  light?: boolean;
  isMini?: boolean;
}

function DashboardNavbar({ absolute, light, isMini }: Props): JSX.Element {
  const appDispatch = useAppDispatch();
  const navigate = useNavigate();
  const { userData, notifications, notificationUnread } = useAppSelector(
    (state) => state.authReducer
  );
  const [profilePic, setProfilePic] = useState<string>(userDefaultImg);
  const [navbarType, setNavbarType] = useState<
    "fixed" | "absolute" | "relative" | "static" | "sticky"
  >();
  const [controller, dispatch] = useMaterialUIController();
  const { miniSidenav, fixedNavbar, darkMode } = controller;
  const [openMenu, setOpenMenu] = useState<any>(false);
  const [openNotifications, setOpenNotifications] = useState<any>(false);
  const [displayFilter, setDisplayFilter] = useState<any>(false);
  const [notificationLoader, setNotificationLoader] = useState<any>(false);
  const [notificationInterval, setNotificationInterval] = useState<any>(null);
  const [displayIndicator, setDisplayIndicator] = useState<any>(false);
  const [notificationPage, setNotificationPage] = useState<any>(0);
  const [notificationTotalPage, setNotificationTotalPage] = useState<any>(0);
  const [notificationSize] = useState<any>(20);
  const route = useLocation().pathname.split("/").slice(1);
  const [transparentNavbar, setTransparentNavbar] = useState<boolean>(true);
  const associations: any = getAuthUser()?.connect_associations;
  const [resetPassword, setresetPassword] = useState(null);
  const [resetPasswordPopup, setResetPasswordPopup] = useState(false);

  useEffect(() => {
    if (userData && userData.profile_picture) {
      setProfilePic(userData.profile_picture);
    }
    // if (userData && userData?.role === userRoles.SOLA_PRO_USER) {
    //   const leaseStatus = getPreferenceValue(userData, "LEASE_WIZARD");
    //   if (leaseStatus === "pending") {
    //     setUpdateLead(true);
    //   }
    // }
  }, [userData]);

  useEffect(() => {
    // Setting the navbar type
    if (fixedNavbar) {
      setNavbarType("sticky");
    } else {
      setNavbarType("static");
    }

    // A function that sets the transparent state of the navbar.
    function handleTransparentNavbar() {
      setTransparentNavbar((fixedNavbar && window.scrollY === 0) || !fixedNavbar);
    }

    /** 
     The event listener that's calling the handleTransparentNavbar function when 
     scrolling the window.
    */
    window.addEventListener("scroll", handleTransparentNavbar);

    // Call the handleTransparentNavbar function to set the state with the initial value.
    handleTransparentNavbar();

    // Remove event listener on cleanup
    return () => window.removeEventListener("scroll", handleTransparentNavbar);
  }, [dispatch, fixedNavbar]);

  const handleMiniSidenav = () => setMiniSidenav(dispatch, !miniSidenav);
  const handleOpenMenu = (event: any) => setOpenMenu(event.currentTarget);
  const handleOpenNoptifications = (event: any) => {
    setOpenNotifications(event.currentTarget);
    // get all notifications from API
    appDispatch(
      getAllNotifications({
        userId: userData.user_id,
        setNotificationLoader,
        page: 0,
        size: notificationSize,
        setNotificationPage,
        setNotificationTotalPage,
        notifications,
      })
    );
  };
  const handleCloseNoptifications = () => setOpenNotifications(false);
  const handleCloseMenu = () => setOpenMenu(false);

  const resetPasswordAction = () => {
    setresetPassword({ login_email: userData?.login_email });
    setResetPasswordPopup(true);
  };

  const onLogout = () => {
    appDispatch(logout());
  };

  // Render the notifications menu
  const renderMenu = () => (
    <Menu
      anchorEl={openMenu}
      anchorReference={null}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
      open={Boolean(openMenu)}
      onClose={handleCloseMenu}
      sx={{ mt: 2 }}
    >
      <Link to="/my-account">
        <NotificationItem icon={<Icon>person</Icon>} title="My Account" />
      </Link>
      {/* <Link to="/reports/download-center">
        <NotificationItem icon={<Icon>download</Icon>} title="Download Center" />
      </Link> */}
      <NotificationItem
        icon={<Icon>key</Icon>}
        title="Reset Password"
        onClick={resetPasswordAction}
        className="profile-menu-item"
      />
      <NotificationItem
        icon={<Icon>logout</Icon>}
        title="Logout"
        onClick={onLogout}
        className="profile-menu-item"
      />
    </Menu>
  );

  // Render the notifications menu
  /* <Menu
    anchorEl={openNotifications}
    anchorReference={null}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "left",
    }}
    open={Boolean(openNotifications)}
    onClose={handleCloseNoptifications}
    sx={{ mt: 2 }}
  >
    {notificationLoader && <>Loading...</>}
    {!notificationLoader &&
      notifications &&
      notifications.map((v: any) => (
        <DefaultItem
          key={v.id}
          icon={<Icon>person</Icon>}
          title={v.notification_type === "dealcreate" ? "Deal" : ""}
          description={v.notification_message}
        />
      ))}
  </Menu> */
  /* <DefaultItem
              key={v.id}
              icon={<Icon>person</Icon>}
              title={v.notification_type === "dealcreate" ? "Deal" : ""}
              description={v.notification_message}
            /> */
  const renderNotifications = () => (
    <MDBox display={openNotifications ? "block" : "none"}>
      <MDBox className="notification-wrapper" onClick={handleCloseNoptifications} />
      <MDBox
        className={
          notifications && notifications.length === 0
            ? "notification-container no-notification"
            : "notification-container"
        }
        id="notificationPopup"
      >
        {notifications && notifications.length > 0 && (
          <MDTypography
            onClick={() => appDispatch(markAsRead({ userId: userData.user_id }))}
            className="mark-as-read"
          >
            Mark as read
          </MDTypography>
        )}
        {notificationLoader && <>Loading...</>}
        {!notificationLoader && notifications && notifications.length === 0 && (
          <MDTypography>No notifications</MDTypography> // SD-3070 changes
        )}
        {!notificationLoader &&
          notifications &&
          notifications.map((v: any) => (
            <Card
              className="activity-card"
              key={v.id}
              onClick={() => {
                appDispatch(
                  markAsRead({ userId: userData.user_id, notificationId: v.id, notifications })
                );
                appDispatch(updateDealId({ dealId: v.deal_id }));
                handleCloseNoptifications();
                navigate(`/crm/sola-pro-leads`, {
                  state: { id: v.deal_id },
                  replace: true,
                });
              }}
            >
              <CardContent style={{ padding: 12, position: "relative" }}>
                <MDTypography variant="h6" component="div">
                  {v?.connect_deal?.deal_name || "-"}
                </MDTypography>
                <MDTypography
                  variant="body2"
                  component="p"
                  fontSize="small"
                  className="notification-card-date"
                >
                  {moment(v?.created_at).format("MMMM D, YYYY, HH:mm")}
                </MDTypography>
                {!v.mark_as_read && (
                  <Tooltip title={!v.mark_as_read && "Mark as Read"} placement="bottom">
                    {/* eslint-disable-next-line react/button-has-type, jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions */}
                    <span
                      className="read-indicator"
                      // onClick={() =>
                      //   appDispatch(markAsRead({ userId: userData.user_id, notificationId: v.id }))
                      // }
                    />
                  </Tooltip>
                )}
                <MDTypography variant="body2" fontSize={14}>
                  {v.notification_message}
                </MDTypography>
              </CardContent>
            </Card>
          ))}
        {!notificationLoader &&
          notificationTotalPage !== notificationPage &&
          notifications &&
          notifications.length > 0 && (
            <MDBox sx={{ display: "flex", justifyContent: "center" }} mt={1}>
              <MDButton
                disabled={notificationPage + 1 === notificationSize}
                onClick={() =>
                  appDispatch(
                    getAllNotifications({
                      userId: userData.user_id,
                      page: notificationPage,
                      size: notificationSize,
                      setNotificationPage,
                      setNotificationTotalPage,
                      notifications,
                    })
                  )
                }
                color="info"
                size="small"
                sx={{ padding: "5px 10px 2px", minHeight: "unset", fontSize: "10px" }}
              >
                Load More...
              </MDButton>
            </MDBox>
          )}
      </MDBox>
    </MDBox>
  );

  useEffect(() => {
    setNotificationInterval(null);
    appDispatch(
      getAllNotifications({
        userId: userData.user_id,
        page: notificationPage,
        size: notificationSize,
        setNotificationPage,
        setNotificationTotalPage,
        notifications,
      })
    );
  }, []);

  useEffect(() => {
    if (notificationUnread && notificationUnread > 0) {
      setDisplayIndicator(true);
    } else {
      setDisplayIndicator(false);
    }
  }, [notificationUnread]);

  useEffect(() => {
    if (notifications) {
      if (notificationInterval === null) {
        // const notificationInter = setInterval(() => {
        //   appDispatch(
        //     getAllNotifications({
        //       userId: userData.user_id,
        //       page: notificationPage,
        //       size: notificationSize,
        //       setNotificationPage,
        //       setNotificationTotalPage,
        //       notifications,
        //     })
        //   );
        // }, 1000 * 30);
        // setNotificationInterval(notificationInter);
      }
      renderNotifications();
    }
  }, [notifications]);

  // Styles for the navbar icons
  const iconsStyle = ({
    palette: { dark, white, text },
    functions: { rgba },
  }: {
    palette: any;
    functions: any;
  }) => ({
    color: () => {
      let colorValue = light || darkMode ? white.main : dark.main;

      if (transparentNavbar && !light) {
        colorValue = darkMode ? rgba(text.main, 0.6) : text.main;
      }

      return colorValue;
    },
  });

  const displayLocationsDropdown = () => {
    let locationDropdown = null;
    if (userData.role !== userRoles.SYSTEM_ADMIN && userData.role !== userRoles.ADMIN) {
      if (associations?.length > 1 || (associations && associations?.[0]?.locations?.length)) {
        locationDropdown = (
          <>
            <MDIconButton
              aria-label="Location Filter"
              color="inherit"
              sx={navbarIconButton}
              onClick={() => setDisplayFilter(!displayFilter)}
            >
              <Icon fontSize="small">filter_list</Icon>
            </MDIconButton>
            {displayFilter && <CustomAssociationDropdown associations={associations} />}
          </>
        );
      }
    }
    return locationDropdown;
  };
  return (
    <AppBar
      id="mainHeader"
      position={absolute ? "absolute" : navbarType}
      color="inherit"
      sx={(theme) => navbar(theme, { transparentNavbar, absolute, light, darkMode })}
    >
      <Toolbar sx={navbarContainer} className="main-header-toolbar">
        <MDBox
          color="inherit"
          mb={{ xs: 1, md: 0 }}
          sx={(theme) => navbarRow(theme, { isMini })}
          style={{ alignItems: "flex-start" }}
          className="breadcrumb-wrapper"
        >
          <IconButton sx={navbarDesktopMenu} onClick={handleMiniSidenav} size="small" disableRipple>
            <Icon fontSize="medium" sx={iconsStyle}>
              {miniSidenav ? "chevron_right" : "chevron_left"}
            </Icon>
          </IconButton>
          <Breadcrumbs icon="home" title={route[route.length - 1]} route={route} light={light} />
        </MDBox>
        {isMini ? null : (
          <MDBox sx={(theme) => navbarRow(theme, { isMini })} className="avatar-wrapper">
            <MDBox pr={1}>{/* <MDInput label="Search here" /> */}</MDBox>
            <MDBox sx={{ display: "flex" }} color={light ? "white" : "inherit"}>
              <IconButton
                size="small"
                disableRipple
                color="inherit"
                sx={navbarMobileMenu}
                onClick={handleMiniSidenav}
              >
                <Icon sx={iconsStyle} fontSize="medium">
                  {miniSidenav ? "menu_open" : "menu"}
                </Icon>
              </IconButton>
              {displayLocationsDropdown()}
              {(userData.role === userRoles.SYSTEM_ADMIN ||
                userData.role === userRoles.ADMIN ||
                isFranchisie()) && (
                <>
                  <IconButton
                    size="small"
                    color="inherit"
                    sx={navbarIconButton}
                    onClick={handleOpenNoptifications}
                  >
                    <Icon>notifications</Icon>
                    {/* <span className="notification-indicator" /> */}
                    {displayIndicator && (
                      <span className="notification-indicator-count">
                        {notificationUnread < 9 ? notificationUnread : "9+"}
                      </span>
                    )}
                  </IconButton>
                  {renderNotifications()}
                </>
              )}
              <IconButton
                size="small"
                color="inherit"
                sx={navbarIconButton}
                onClick={handleOpenMenu}
              >
                <MDAvatar size="sm" shadow="sm" src={profilePic} />
              </IconButton>
              {renderMenu()}
            </MDBox>
          </MDBox>
        )}
      </Toolbar>
      {resetPasswordPopup && (
        <ResetPassword
          open={resetPasswordPopup}
          onClose={() => {
            setResetPasswordPopup(false);
            setresetPassword(null);
          }}
          resetPassword={resetPassword}
        />
      )}
    </AppBar>
  );
}

// Declaring default props for DashboardNavbar
DashboardNavbar.defaultProps = {
  absolute: false,
  light: false,
  isMini: false,
};

export default DashboardNavbar;
