import MDDialog from "components/MDDialog";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import { Grid } from "@mui/material";
import { useAppSelector } from "store/store";
import { getLocalDateTime } from "helper/services";

function ViewDetails(props: any): JSX.Element {
  const { open, onClose, updateData } = props;
  const { reportsTypesList } = useAppSelector((state) => state.systemAdminSlice);

  const dialogProps = {
    open,
    onClose,
    title: "View Report",
    size: "md",
    saveBtn: false,
  };

  const getReportType = (obj: any) => {
    const typeData = reportsTypesList.find((v: any) => v.value === obj.report_type);
    return typeData?.name || "-";
  };

  return (
    <MDDialog {...dialogProps}>
      <MDBox mt={2}>
        <Grid container>
          <Grid item xs={12} sm={6} md={6}>
            <MDTypography fontWeight="bold" variant="button">
              Report Type
            </MDTypography>
            <MDBox>
              <MDTypography variant="button">{getReportType(updateData) || "-"}</MDTypography>
            </MDBox>
          </Grid>
          <Grid item xs={12} sm={6} md={6}>
            <MDTypography fontWeight="bold" variant="button">
              Email Address
            </MDTypography>
            <MDBox>
              <MDTypography variant="button">{updateData?.email_address || "-"}</MDTypography>
            </MDBox>
          </Grid>
        </Grid>
      </MDBox>
      <MDBox mt={2}>
        <Grid container>
          <Grid item xs={12} sm={6} md={6}>
            <MDTypography fontWeight="bold" variant="button">
              Processed At
            </MDTypography>
            <MDBox>
              <MDTypography variant="button">
                {getLocalDateTime(updateData?.processed_at)}
              </MDTypography>
            </MDBox>
          </Grid>
          <Grid item xs={12} sm={6} md={6}>
            <MDTypography fontWeight="bold" variant="button">
              Parameters
            </MDTypography>
            <MDBox>
              <MDTypography variant="button">{updateData?.parameters || "-"}</MDTypography>
            </MDBox>
          </Grid>
        </Grid>
      </MDBox>
      <MDBox mt={2}>
        <Grid container>
          <Grid item xs={12} sm={6} md={6}>
            <MDTypography fontWeight="bold" variant="button">
              Created At
            </MDTypography>
            <MDBox>
              <MDTypography variant="button">
                {getLocalDateTime(updateData?.created_at)}
              </MDTypography>
            </MDBox>
          </Grid>

          <Grid item xs={12} sm={6} md={6}>
            <MDTypography fontWeight="bold" variant="button">
              Updated At
            </MDTypography>
            <MDBox>
              <MDTypography variant="button">
                {getLocalDateTime(updateData?.updated_at)}
              </MDTypography>
            </MDBox>
          </Grid>
        </Grid>
      </MDBox>
    </MDDialog>
  );
}

export default ViewDetails;
