import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MESSAGES from "helper/messages";

function Social(props: any): JSX.Element {
  const { solaProDetail, errors, onChangeField } = props;
  return (
    <Card id="social">
      <MDBox p={3}>
        <MDTypography variant="h5">Social</MDTypography>
      </MDBox>
      <MDBox pb={3} px={3}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6} md={6} lg={6}>
            <MDInput
              variant="standard"
              label="Facebook Url"
              fullWidth
              name="facebook_url"
              placeholder="Facebook Url"
              onChange={onChangeField}
              value={solaProDetail?.facebook_url || ""}
              error={errors && errors.facebook_url ? true : false}
              helperText={errors && errors.facebook_url}
              infoText={MESSAGES.INFO.SOCIAL_URL}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={6} lg={6}>
            <MDInput
              variant="standard"
              label="Google Plus Url"
              fullWidth
              name="google_plus_url"
              placeholder="Google Plus Url"
              onChange={onChangeField}
              value={solaProDetail?.google_plus_url || ""}
              error={errors && errors.google_plus_url ? true : false}
              helperText={errors && errors.google_plus_url}
              infoText={MESSAGES.INFO.SOCIAL_URL}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={6} lg={6}>
            <MDInput
              variant="standard"
              label="Instagram Url"
              fullWidth
              name="instagram_url"
              placeholder="Instagram Url"
              onChange={onChangeField}
              value={solaProDetail?.instagram_url || ""}
              error={errors && errors.instagram_url ? true : false}
              helperText={errors && errors.instagram_url}
              infoText={MESSAGES.INFO.SOCIAL_URL}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={6} lg={6}>
            <MDInput
              variant="standard"
              label="Linkedin Url"
              fullWidth
              name="linkedin_url"
              placeholder="Linkedin Url"
              onChange={onChangeField}
              value={solaProDetail?.linkedin_url || ""}
              error={errors && errors.linkedin_url ? true : false}
              helperText={errors && errors.linkedin_url}
              infoText={MESSAGES.INFO.SOCIAL_URL}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={6} lg={6}>
            <MDInput
              variant="standard"
              label="Pinterest Url"
              fullWidth
              name="pinterest_url"
              placeholder="Pinterest Url"
              onChange={onChangeField}
              value={solaProDetail?.pinterest_url || ""}
              error={errors && errors.pinterest_url ? true : false}
              helperText={errors && errors.pinterest_url}
              infoText={MESSAGES.INFO.SOCIAL_URL}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={6} lg={6}>
            <MDInput
              variant="standard"
              label="Twitter Url"
              fullWidth
              name="twitter_url"
              placeholder="Twitter Url"
              onChange={onChangeField}
              value={solaProDetail?.twitter_url || ""}
              error={errors && errors.twitter_url ? true : false}
              helperText={errors && errors.twitter_url}
              infoText={MESSAGES.INFO.SOCIAL_URL}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={6} lg={6}>
            <MDInput
              variant="standard"
              label="Yelp Url"
              fullWidth
              name="yelp_url"
              placeholder="Yelp Url"
              onChange={onChangeField}
              value={solaProDetail?.yelp_url || ""}
              error={errors && errors.yelp_url ? true : false}
              helperText={errors && errors.yelp_url}
              infoText={MESSAGES.INFO.SOCIAL_URL}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={6} lg={6}>
            <MDInput
              variant="standard"
              label="Tik Tok Url"
              fullWidth
              name="tik_tok_url"
              placeholder="Tik Tok Url"
              onChange={onChangeField}
              value={solaProDetail?.tik_tok_url || ""}
              error={errors && errors.tik_tok_url ? true : false}
              helperText={errors && errors.tik_tok_url}
              infoText={MESSAGES.INFO.SOCIAL_URL}
            />
          </Grid>
        </Grid>
      </MDBox>
    </Card>
  );
}

export default Social;
