import { Card, Grid } from "@mui/material";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import CustomPhoneInput from "components/CustomPhoneInput";
import CustomErrorMessage from "components/CustomErrorMessage";

function SmsForwardingNumber(props: any): JSX.Element {
  const { onChangeTextField, phoneDetail, errors } = props;

  return (
    <Card id="sms-forward-number">
      <MDBox p={3}>
        <MDTypography variant="h5">SMS Forwarding Number</MDTypography>
      </MDBox>
      <MDBox component="form" pb={3} px={3}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={12}>
            <MDInput
              variant="standard"
              fullWidth
              label="Text Message"
              name="sms_forwarding_number"
              placeholder="(000) 000-0000"
              id="phone-input"
              InputProps={{
                inputComponent: CustomPhoneInput as any,
              }}
              value={phoneDetail?.sms_forwarding_number || ""}
              onChange={onChangeTextField}
              error={errors && errors.sms_forwarding_number ? true : false}
              helperText={errors && errors.sms_forwarding_number}
            />
            <CustomErrorMessage
              message="All SMS made to this number will be passed directly to this phone number."
              errorColor="inherit"
            />
          </Grid>
        </Grid>
      </MDBox>
    </Card>
  );
}

export default SmsForwardingNumber;
