import { Card, Grid } from "@mui/material";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";

function BasicInfo(props: any): JSX.Element {
  const { onChangeField, association, errors } = props;

  return (
    <Card id="basic-info" sx={{ overflow: "visible" }}>
      <MDBox p={3}>
        <MDTypography variant="h5">Basic Info</MDTypography>
      </MDBox>
      <MDBox component="form" pb={3} px={3}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={12}>
            <MDInput
              label="Association ID"
              variant="standard"
              fullWidth
              disabled
              name="association_id"
              placeholder="Association ID"
              onChange={onChangeField}
              value={association?.association_id || ""}
              error={errors && errors.association_id ? true : false}
              helperText={errors && errors.association_id}
              required
            />
          </Grid>
          <Grid item xs={12} sm={12}>
            <MDInput
              label="Association Name"
              variant="standard"
              fullWidth
              name="association_name"
              placeholder="Association Name"
              onChange={onChangeField}
              value={association?.association_name || ""}
              error={errors && errors.association_name ? true : false}
              helperText={errors && errors.association_name}
              required
            />
          </Grid>
          <Grid item xs={12} sm={12}>
            <MDInput
              label="Association Description"
              variant="standard"
              fullWidth
              name="association_description"
              placeholder="Association Description"
              onChange={onChangeField}
              value={association?.association_description || ""}
            />
          </Grid>
        </Grid>
      </MDBox>
    </Card>
  );
}

export default BasicInfo;
