import { createSlice, Draft, PayloadAction } from "@reduxjs/toolkit";

interface InitialState {
  allBooking: Array<[]>;
  bookingDetails: Object;
}

const initialState: InitialState = {
  allBooking: [],
  bookingDetails: {},
};

const bookNowBookingSlice = createSlice({
  name: "bookNowBookingSlice",
  initialState,
  reducers: {
    allBookingList: (state: Draft<InitialState>, action: PayloadAction<any>) => ({
      ...state,
      allBooking: action.payload,
    }),
    setBookingDetails: (state: Draft<InitialState>, action: PayloadAction<any>) => ({
      ...state,
      bookingDetails: action.payload,
    }),
  },
});

export const { allBookingList, setBookingDetails } = bookNowBookingSlice.actions;

export default bookNowBookingSlice.reducer;
