import { useCallback, useEffect, useLayoutEffect, useRef, useState } from "react";
import { Card, Icon, Checkbox } from "@mui/material";
import MDBox from "components/MDBox";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import DataTable from "examples/Tables/DataTable";
import { useLocation } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "store/store";
import {
  setDefaultSelectedColumns,
  getLocalDateTime,
  insertinArray,
  systemAdmin,
  formattedPhone,
} from "helper/services";
import { getImportDataListById, retryImportedDeal } from "store/thunk/deal-import-thunk";
// import exportData from "helper/exportTableConst";
import MDIconButton from "components/MDIconButton";
import { getDefaultCoulmns } from "store/thunk/connectUserThunk"; // saveSelectedAllData
import { setDefaultsColumns } from "store/slices/connectUsersSlice";
import { setImportDealDataList } from "store/slices/deal-import-slice";
import CustomBackButton from "components/CustomBackButton";
import MDDialog from "components/MDDialog";
import MDTypography from "components/MDTypography";

function Index(): JSX.Element {
  const dispatch = useAppDispatch();
  const stateData: any = useLocation();
  const { importDealDataList } = useAppSelector((state) => state.dealImportSlice);
  const { defaultSelectedColumns } = useAppSelector((state) => state.connectUsersReducer);
  const { userData } = useAppSelector((state) => state.authReducer);
  const [selectedItems, setSelectedItems] = useState([]);
  const [importedDealsList, setImportedDealsList] = useState([]);
  const [pageCount, setPageCount] = useState(0);
  const [pageNo, setPageNo] = useState(0);
  const [totalCount, setTotalCount] = useState(0);
  const [perPage, setPageSize] = useState(50);
  const [search, setSearch] = useState<any>(null);
  const [sortBy, setSortBy] = useState<any>([]);
  const [displayColumns, setDisplayColumns] = useState<any>([]);
  // const [defaultExport, setDefaultExport] = useState<any>([]);
  const [callFetch, setCallFetch] = useState<any>(false);
  const [defaultSorting, setDefaultSorting] = useState<any>([]);
  const [defaultFilteing, setDefaultFilteing] = useState<any>([]);
  const [retryPopup, setDeleteContactInquiries] = useState<any>(false);
  const [retryId, setRetryId] = useState<any>(null);
  const [tempFilterData, setTempFilterData] = useState<any>([]);

  // Get columns
  const getColumns = () => {
    let columns: any = [
      {
        Header: "",
        disableSortBy: true,
        width: "100px",
        accessor: "checkbox",
        default: true,
        checked: true,
      },
      {
        Header: "First Name",
        accessor: "first_name",
        export: true,
        disableCheck: true,
        checked: true,
      },
      {
        Header: "Last Name",
        accessor: "last_name",
        export: false,
        disableCheck: true,
        checked: true,
      },
      {
        Header: "Email",
        accessor: "email",
        export: true,
        checked: true,
      },
      {
        Header: "Phone Number",
        accessor: "phone_number",
        export: true,
        checked: true,
      },
      {
        Header: "Zipcode",
        accessor: "zipcode",
        export: true,
        checked: true,
      },
      {
        Header: "State",
        accessor: "state",
        export: true,
        checked: true,
      },
      {
        Header: "City",
        accessor: "city",
        export: true,
        checked: true,
      },
      {
        Header: "Services",
        accessor: "services",
        export: true,
        checked: true,
      },
      {
        Header: "Status",
        accessor: "status",
        export: true,
        checked: true,
      },
      {
        Header: "Deal Id",
        accessor: "deal_id",
        export: true,
        checked: true,
      },
      {
        Header: "Resubmitted",
        accessor: "resubmitted",
        export: true,
        checked: true,
      },
      {
        Header: "Error",
        accessor: "error",
        export: true,
        checked: true,
      },
      {
        Header: "Created at",
        accessor: "created_at",
        width: "200px",
        export: true,
        is_date: true,
        checked: true,
      },
      {
        Header: "Updated at",
        accessor: "updated_at",
        width: "200px",
        export: true,
        is_date: true,
        checked: false,
      },
      {
        Header: "Action",
        accessor: "action",
        width: "200px",
        export: false,
        disableSortBy: true,
        checked: true,
      },
    ];
    if (systemAdmin()) {
      columns = insertinArray(columns, 1, {
        Header: "ID",
        accessor: "id",
        export: true,
        disableCheck: true,
        checked: true,
      });
    }
    return columns;
  };

  const [checkedColumns, setCheckedColumns] = useState<any>(getColumns());
  const [isCheckAll, setIsCheckAll] = useState(false);
  const latestRefValue = useRef(checkedColumns);

  useEffect(() => {
    latestRefValue.current = checkedColumns;
  }, [checkedColumns]);
  useEffect(() => {
    dispatch(setImportDealDataList([]));
  }, []);

  const fetchIdRef = useRef(0);

  const fetchData = useCallback(({ pageSize, pageIndex, search, sortBy, filterData }) => {
    const fetchId = ++fetchIdRef.current;
    const sort: any = sortBy || [];
    // We'll even set a delay to simulate a server here
    setTimeout(() => {
      if (fetchId === fetchIdRef.current) {
        // const sentReq = {
        //   sendData: {
        //     user_id: userData?.user_id,
        //     table_name: exportData.msas.table_name,
        //     table_columns: latestRefValue.current && latestRefValue.current,
        //     table_sort: sortBy,
        //     table_filter: filterData,
        //     default_export_data: defaultExport,
        //   },
        // };
        // if (isManual) {
        //   dispatch(saveSelectedAllData(sentReq));
        // }

        setPageNo(pageIndex);
        setSearch(search);
        setSortBy(sort);
        setPageSize(pageSize);
        setSelectedItems([]);
        setIsCheckAll(false);
        setTempFilterData(filterData);
        dispatch(
          getImportDataListById({
            pageSize,
            pageIndex,
            search,
            sort,
            userData,
            filterData,
            importId: stateData?.state?.id,
          })
        );
      }
    }, 1000);
  }, []);

  useLayoutEffect(() => {
    dispatch(setDefaultsColumns([]));
    const sentReq = {
      user_id: userData?.user_id,
      table_name: "connect_imported_deal_data",
    };
    dispatch(getDefaultCoulmns(sentReq)).then((resp: any) => {
      const respFilter = resp?.payload?.table_filter || [];
      const respSorting = resp?.payload?.table_sort || [];
      // const defaultDBexportData = resp?.payload?.default_export_data || [];
      const defaultDBColumn = resp?.payload?.table_columns || [];
      const tempdata = setDefaultSelectedColumns(defaultDBColumn, getColumns());
      setCheckedColumns(tempdata);
      // setDefaultExport(defaultDBexportData);
      setDefaultSorting(respSorting);
      setSortBy(respSorting);
      setDefaultFilteing(respFilter);
      if (resp?.payload) {
        setCallFetch(true);
        fetchData({
          perPage,
          pageNo,
          search,
          sortBy: respSorting && respSorting?.length ? respSorting : [],
          additionalField: null,
          filterData: respFilter && respFilter?.length ? respFilter : [],
        });
      }
    });
  }, []);

  useEffect(() => {
    if (checkedColumns?.length) {
      setDisplayColumns(checkedColumns.filter((col: any) => col.checked));
    }
  }, [checkedColumns]);

  useEffect(() => {
    if (importDealDataList && importDealDataList?.connect_imported_deal_data) {
      setImportedDealsList(importDealDataList?.connect_imported_deal_data);
      setPageCount(importDealDataList?.meta?.total_pages);
      setTotalCount(importDealDataList?.meta?.total_count);
    }
  }, [importDealDataList]);

  useEffect(() => {
    if (defaultSelectedColumns && defaultSelectedColumns?.length) {
      const newColumns = setDefaultSelectedColumns(defaultSelectedColumns, getColumns());
      setCheckedColumns(newColumns);
    } else {
      setCheckedColumns(getColumns());
    }
  }, [defaultSelectedColumns]);

  const openRetryPopup = (data: any) => {
    setRetryId(data?.id);
    setDeleteContactInquiries(true);
  };

  const closeRetryPopup = () => {
    setDeleteContactInquiries(false);
    setRetryId("");
  };
  const onRetry = () => {
    const req = {
      id: retryId,
      onClose: closeRetryPopup,
      pageNo,
      perPage,
      search,
      sortBy,
      filterData: tempFilterData,
      importId: stateData?.state?.id,
    };
    dispatch(retryImportedDeal(req));
  };

  const actionButtons = (object: any) => (
    <>
      {object?.error && (
        <MDIconButton
          tooltipName="Retry"
          aria-label="Info"
          color="info"
          onClick={() => openRetryPopup(object)}
        >
          <Icon fontSize="small">replay</Icon>
        </MDIconButton>
      )}
      {!object?.error && "-"}
    </>
  );

  const onChangeCheckBox = (e: any) => {
    let items = JSON.parse(JSON.stringify(selectedItems));
    if (e.target.checked && !items.includes(e.target.value)) {
      items.push(e.target.value);
      if (items.length === importedDealsList?.length) {
        setIsCheckAll(true);
      }
    } else {
      items = items.filter((id: any) => id !== e.target.value);
      setIsCheckAll(false);
    }
    setSelectedItems(items);
  };

  const checkUncheckAll = (e: any) => {
    let allIds: any = [];
    allIds = importedDealsList.map((val: any) => String(val.id));
    if (e.target.checked) {
      const selectedIds = [...new Set([...selectedItems, ...allIds])];
      setSelectedItems(selectedIds);
      // allIds = msas.map((val: any) => String(val.id));
      setIsCheckAll(true);
    } else {
      allIds = importedDealsList.map((val: any) => String(val.id));
      allIds = selectedItems.filter((el: any) => !allIds.includes(el));
      setSelectedItems([...allIds]);
      setIsCheckAll(false);
      // allIds = [];
      // setIsCheckAll(false);
    }
    // setSelectedItems(allIds);
  };

  const getRows = () => {
    const data: any = importedDealsList.map((obj: any) => ({
      checkbox: (
        <Checkbox
          checked={selectedItems.includes(obj.id.toString())}
          onChange={(e: any) => onChangeCheckBox(e)}
          value={obj.id}
        />
      ),
      id: obj.id ? obj.id : "-",
      first_name: obj?.first_name ? obj?.first_name : "-",
      last_name: obj?.last_name ? obj?.last_name : "-",
      number_of_leads: obj?.no_of_leads ? obj?.no_of_leads : "0",
      email: obj?.email ? obj?.email : "-",
      phone_number: obj?.phone_number ? formattedPhone(obj?.phone_number) : "-",
      zipcode: obj?.zipcode ? obj?.zipcode : "-",
      state: obj?.state ? obj?.state : "-",
      services: obj?.services?.length ? obj?.services?.join(",") : "-",
      city: obj?.city ? obj?.city : "-",
      status: obj?.status ? obj?.status : "-",
      deal_id: obj?.deal_id ? obj?.deal_id : "-",
      resubmitted: obj?.resubmitted ? "Yes" : "No",
      error: obj?.error ? obj?.error : "-",
      created_at: obj.created_at ? getLocalDateTime(obj.created_at) : "-",
      updated_at: obj.updated_at ? getLocalDateTime(obj.updated_at) : "-",
      action: actionButtons(obj),
    }));
    return data;
  };

  const tableData = {
    columns: getColumns(),
    rows: getRows(),
  };

  // Remove uncheck boxes
  const clearCheckItem = () => {
    setSelectedItems([]);
  };

  const retryDialogProps = {
    open: retryPopup,
    onClose: () => closeRetryPopup(),
    onSave: () => onRetry(),
    title: "Retry",
    size: "sm",
    saveBtn: false,
    deleteBtn: true,
    saveTbtText: "Yes",
    closeBtnText: "No",
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <CustomBackButton label="Back" path="/crm/import-deals" />
      <MDBox pt={4} pb={3}>
        <Card>
          <MDBox
            bgColor="dark"
            color="white"
            coloredShadow="dark"
            borderRadius="xl"
            alignItems="center"
            justifyContent="space-between"
            pt={1}
            pb={1}
            pl={2}
            pr={2}
            ml={2}
            mr={2}
            display="flex"
            mt={-3}
            className="page-header"
          >
            <p className="page-header-label">Imported Deals Data</p>
          </MDBox>
          {(defaultSorting || defaultSorting?.length) &&
            callFetch && ( // SD-3199
              <DataTable
                defaultSortingColumns={defaultSorting} // SD-3199
                defaultFilteringColums={defaultFilteing} // SD-3199
                defaultDisplayColumns={displayColumns}
                setCheckedColumns={setCheckedColumns}
                table={tableData}
                canSearch
                // searchText="Search..."
                selectedItems={selectedItems}
                tableSetting="connect_imported_deal_data"
                // showExportSelectedBtn
                // showExportAllBtn
                pageCount={pageCount}
                pageNo={pageNo}
                setPageNo={setPageNo}
                totalCount={totalCount}
                fetchData={fetchData}
                // exportFields={exportData.msas.export_fields}
                // exportTable={exportData.msas.table_name}
                // exportType={exportData.msas.export_type}
                // advanceFilter
                manualSearch
                manualSort
                isDisplayCheckAllCheckbox
                onChangeCheckAllCheckbox={checkUncheckAll}
                isCheckAll={isCheckAll}
                checkedColumns={checkedColumns}
                clearCheckItem={clearCheckItem}
                // useRubyApiToExport
              />
            )}
          {retryPopup && (
            <MDDialog {...retryDialogProps}>
              <MDTypography variant="h6" fontWeight="medium">
                Are you sure you want to retry ?
              </MDTypography>
            </MDDialog>
          )}
        </Card>
      </MDBox>
    </DashboardLayout>
  );
}

export default Index;
