import { Box } from "@mui/material";
import MDTypography from "components/MDTypography";

export default function CustomLoader() {
  return (
    <Box>
      <Box className="loader-triangle-1" />
      <Box className="loader-triangle-2" />
      <MDTypography variant="h5" sx={{ margin: "30px auto", color: "#FFFFFF" }}>
        Please Wait...
      </MDTypography>
    </Box>
  );
}
