import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Checkbox,
  FormControlLabel,
  Grid,
} from "@mui/material";
import EleaseButtons from "components/EleaseButtons";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import moment from "moment";
import { useEffect } from "react";
import { setleaseApplicationSteps } from "store/slices/leaseSlice";
import { useAppDispatch, useAppSelector } from "store/store";

function ReviewAndSend(props: any): JSX.Element {
  const { leaseApplicationSteps } = useAppSelector((state) => state.leaseSlice);
  const dispatch = useAppDispatch();

  const getAccordianSummary = (name: any) => (
    <AccordionSummary className="location-accordion">
      <MDTypography>{name}</MDTypography>
    </AccordionSummary>
  );

  const getDetailElement = (name: any, value: any) => (
    <Grid item xs={12} sm={6} md={6}>
      <MDTypography fontWeight="bold" variant="button">
        {name}
      </MDTypography>
      <MDBox sx={{ wordBreak: "break-all" }}>{value ? value : "-"}</MDBox>
    </Grid>
  );

  const showApplicants = (applicants: any) => {
    if (!Array.isArray(applicants)) {
      return ""; // or any other default value you want to return
    }

    const fullNames = applicants?.map((item: any) => `${item?.first_name} ${item?.last_name}`);
    const commaSeparatedNames = fullNames?.join(", ");
    return commaSeparatedNames;
  };

  const checkDocuments = () => {
    const applicants = leaseApplicationSteps?.applicants;
    const applicantDocs = leaseApplicationSteps?.applicant_documents;
    const editedDocuments = leaseApplicationSteps?.applicants;
    let allUploadDocs = true;

    if (
      editedDocuments ||
      (applicants && applicantDocs && applicants.length === applicantDocs.length)
    ) {
      for (let appIndex = 0; appIndex < applicants.length; appIndex++) {
        const applicantDoc = applicantDocs[appIndex]?.[`applicant_${appIndex}`];

        if (
          (!applicantDoc || applicantDoc.length === 0) &&
          (!editedDocuments[appIndex]?.documents ||
            editedDocuments[appIndex]?.documents.length === 0)
        ) {
          allUploadDocs = false;
          break;
        }
      }
    } else {
      allUploadDocs = false;
    }
    return allUploadDocs;
  };

  const checkValue = checkDocuments();

  useEffect(() => {
    dispatch(setleaseApplicationSteps({ ...leaseApplicationSteps, upload_doc_status: checkValue }));
  }, [checkValue]);

  const handleSingleCheckbox = (e: any) => {
    dispatch(
      setleaseApplicationSteps({ ...leaseApplicationSteps, security_deposit: e.target.checked })
    );
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md={12}>
        <MDBox mt={1}>
          <MDBox>
            <Accordion expanded>
              {getAccordianSummary("Selection of Deal")}
              <AccordionDetails>
                <Grid container spacing={2}>
                  {getDetailElement(
                    "Selected Type",
                    leaseApplicationSteps?.selectedType?.toUpperCase().replace(/_/g, "")
                  )}
                  {getDetailElement("Selected Deal", leaseApplicationSteps?.deal?.deal_name)}
                  {getDetailElement(
                    "Applicants",
                    showApplicants(leaseApplicationSteps?.applicants)
                  )}
                  {getDetailElement("Document Status", checkValue ? "Upload" : "Not Upload")}

                  {getDetailElement(
                    "Payment Status",
                    leaseApplicationSteps?.applicants[0]?.deposit_status
                  )}
                  {getDetailElement(
                    "Collect Security Deposit",
                    <FormControlLabel
                      value="end"
                      control={
                        <Checkbox
                          // checked={leaseApplicationSteps?.security_deposit}
                          checked={leaseApplicationSteps?.security_deposit}
                          name="security_deposit"
                          onChange={handleSingleCheckbox}
                          inputProps={{ "aria-label": "controlled" }}
                        />
                      }
                      label=""
                      labelPlacement="end"
                    />
                  )}
                </Grid>
              </AccordionDetails>
            </Accordion>
            <Accordion expanded>
              {getAccordianSummary("Studio Section")}
              <AccordionDetails>
                <Grid container spacing={2}>
                  {getDetailElement("Selected Location", leaseApplicationSteps?.locations?.name)}
                  {getDetailElement("Selected Studio", leaseApplicationSteps?.studio[0]?.name)}
                </Grid>
              </AccordionDetails>
            </Accordion>
            <Accordion expanded>
              {getAccordianSummary("Security Deposit")}
              <AccordionDetails>
                <Grid container spacing={2}>
                  {getDetailElement(
                    "Security Deposit Amount Type ",
                    leaseApplicationSteps?.security_deposit_amount_type
                      ?.toUpperCase()
                      .replace(/_/g, "")
                  )}
                  {getDetailElement(
                    "Security Deposit Rent",
                    leaseApplicationSteps?.security_deposit_amount
                  )}
                  {leaseApplicationSteps?.security_deposit_amount_type === "rent_retio"
                    ? getDetailElement("Lease Fees", `$ ${leaseApplicationSteps?.lease_fees}`)
                    : getDetailElement(
                        "Security Deposit Amount",
                        `$ ${leaseApplicationSteps?.security_deposit_amount}`
                      )}
                  {getDetailElement("Toal Amount", `$ ${leaseApplicationSteps?.security_deposite}`)}
                </Grid>
              </AccordionDetails>
            </Accordion>
            <Accordion expanded>
              {getAccordianSummary("Lease Data")}
              <AccordionDetails>
                <Grid container spacing={2}>
                  {getDetailElement(
                    "Weeks Free Rent",
                    leaseApplicationSteps?.leaseData?.weeks_free_rent
                  )}
                  {getDetailElement(
                    "Months Free Insurance",
                    leaseApplicationSteps?.leaseData?.months_free_insurance
                  )}
                  {getDetailElement(
                    "License Fee Year 1",
                    leaseApplicationSteps?.leaseData?.licence_fee_1
                  )}
                  {getDetailElement(
                    "License Fee Year 2",
                    leaseApplicationSteps?.leaseData?.licence_fee_2
                  )}
                  {getDetailElement("Lease Term", leaseApplicationSteps?.leaseData?.lease_term)}
                  {getDetailElement(
                    "Move In Date",
                    moment(leaseApplicationSteps?.leaseData?.move_in_date).format("yyyy-MM-DD")
                  )}
                  {getDetailElement(
                    "Lease Sign",
                    moment(leaseApplicationSteps?.leaseData?.lease_sign).format("yyyy-MM-DD")
                  )}
                  {getDetailElement(
                    "Lease Start",
                    moment(leaseApplicationSteps?.leaseData?.lease_start).format("yyyy-MM-DD")
                  )}
                  {getDetailElement(
                    "Lease End",
                    moment(leaseApplicationSteps?.leaseData?.lease_end).format("yyyy-MM-DD")
                  )}
                  {getDetailElement(
                    "First Payment Date",
                    moment(leaseApplicationSteps?.leaseData?.first_payment_date).format(
                      "yyyy-MM-DD"
                    )
                  )}
                  {getDetailElement(
                    "Rent Due Date",
                    leaseApplicationSteps?.leaseData?.rent_due_date
                  )}
                  {getDetailElement(
                    "Insurance Charge Frequency",
                    leaseApplicationSteps?.leaseData?.insurance_charge_frequency
                  )}
                  {getDetailElement(
                    "Insurance Charge Frequency",
                    leaseApplicationSteps?.leaseData?.insurance_fee
                  )}
                  {getDetailElement("Notes", leaseApplicationSteps?.leaseData?.note)}
                </Grid>
              </AccordionDetails>
            </Accordion>
          </MDBox>
        </MDBox>
        <EleaseButtons {...props} />
      </Grid>
    </Grid>
  );
}

export default ReviewAndSend;
