import MDDialog from "components/MDDialog";
import MDBox from "components/MDBox";
import Grid from "@mui/material/Grid";
import MDInput from "components/MDInput";
import { useAppSelector } from "store/store";
import { Checkbox, FormControlLabel } from "@mui/material";
import MDTypography from "components/MDTypography";
// import MDDatePicker from "components/MDDatePicker";
import CustomChipList from "components/CustomChipList";

function SecurityDepositPopup(props: any): JSX.Element {
  const { open, onClose, leaseData, onCollectDeposit } = props;
  const { connectLocation } = useAppSelector((state) => state.locationSlice);

  const dialogProps = {
    open,
    onClose,
    saveBtn: false,
    additionalBtn: true,
    additionalBtnText: "Collect Deposit",
    title: "Charge Security Deposit",
    additionalBtnColor: "success",
    onAdditionalBtnSave: () => onCollectDeposit(),
    size: "xs",
  };

  return (
    <MDDialog {...dialogProps}>
      <MDBox pb={2}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <CustomChipList
              name="Units"
              listData={leaseData?.lease_units?.map((elem: any) => ({
                title: elem.name,
              }))}
            />
            {/* <MDTypography variant="h6">Unit</MDTypography>
            <FormControl fullWidth>
              <Select
                id="Unit"
                value={["54015", "54015"]}
                // value={leaseData?.lease_units?.unit || ""}
                className="height45"
                label=""
                multiple
              >
                {leaseData?.lease_units.map((v: any) => (
                  <MenuItem value={v.id}>{v.name}</MenuItem>
                ))}
              </Select>
            </FormControl> */}
          </Grid>

          <Grid item xs={12} sm={12} md={12} lg={12}>
            <MDTypography variant="h6">Deposit Type</MDTypography>
            <MDInput
              connectLocation
              variant="outlined"
              fullWidth
              name="deposit_type"
              value="DP - Security Deposits"
            />
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <MDTypography variant="h6">Amount</MDTypography>
            <span>$ {leaseData?.temp_data?.security_deposite}</span>
            {/* <FormControl fullWidth>
              <OutlinedInput
                type="number"
                value={leaseData?.insurance_fee || ""}
                name="security_deposit_amount"
                startAdornment="$"
              />
            </FormControl> */}
          </Grid>
          {/* <Grid item xs={12} sm={12} md={12} lg={12}>
            <FormControl fullWidth>
              <MDTypography variant="h6">Date</MDTypography>
              <MDDatePicker
                value={new Date()}
                fullWidth
                options={{
                  enableTime: true,
                  dateFormat: "M d, Y H:i",
                  time_24hr: true,
                }}
              />
            </FormControl>
          </Grid> */}
          <Grid item xs={12} sm={12} md={12} lg={12}>
            {(connectLocation?.security_deposit_payment_method === "ACH" ||
              connectLocation?.security_deposit_payment_method === "CREDITCARD") && (
              <FormControlLabel
                label="Use Account on File"
                control={
                  <Checkbox
                    checked={
                      connectLocation?.security_deposit_payment_method === "ACH" ? true : false
                    }
                    name="walkins"
                    inputProps={{ "aria-label": "controlled" }}
                  />
                }
              />
            )}
          </Grid>
        </Grid>
      </MDBox>
    </MDDialog>
  );
}

export default SecurityDepositPopup;
