import { useState } from "react";
import { Grid, Icon, FormControl, Autocomplete } from "@mui/material";
import MDInput from "components/MDInput";
// import DripEditor from "components/Drip-editor";
import constants from "helper/constants";
import MDBox from "components/MDBox";
import MDIconButton from "components/MDIconButton";
import MDTypography from "components/MDTypography";

function EmailSms(props: any): JSX.Element {
  const {
    token,
    addToken,
    fieldName,
    // addEmailToken,
    crmTabSettings,
    // onChangeToggle,
    onChangeField,
    // onChangeEditor,
    accessDisabled,
  } = props;

  const [tokenOpen, setTokenOpen] = useState(false);

  const handleAddToken = (event: any, value: any) => {
    addToken(value, `${fieldName}_sms_content`);
    setTokenOpen(false);
  };

  return (
    <>
      <Grid item xs={12} sm={12} md={12} lg={12}>
        <MDBox display="flex" justifyContent="space-between">
          {/* <FormControlLabel
            control={
              <Switch
                checked={crmTabSettings?.[`${fieldName}_sms`] || false}
                onChange={onChangeToggle}
                inputProps={{ "aria-label": "controlled" }}
                name={`${fieldName}_sms`}
                disabled={accessDisabled}
              />
            }
            label="Send SMS"
            labelPlacement="end"
          /> */}
          <div /> {/* For toggle dispaly */}
          <MDBox>
            {tokenOpen && (
              <MDBox className="placeholder-location-sms-dorpdown-wrapper">
                <FormControl fullWidth className="placeholder-location-sms-dorpdown">
                  <Autocomplete
                    onChange={handleAddToken}
                    value={token || null}
                    options={constants.smsTokens}
                    groupBy={(option) => option.table_name}
                    getOptionLabel={(option: any) => option.label}
                    renderGroup={(params: any) => (
                      <li {...params}>
                        <MDTypography
                          sx={{ pl: 1 }}
                          variant="subtitle2"
                          className="font-bold text-capitalize"
                        >
                          {params.group}
                        </MDTypography>
                        <MDTypography>{params.children}</MDTypography>
                      </li>
                    )}
                    renderOption={(props, data) => (
                      <MDTypography key={data.value} {...props} variant="subtitle2">
                        {data.label}
                      </MDTypography>
                    )}
                    renderInput={(params) => <MDInput {...params} label="Select Token" />}
                  />
                </FormControl>
              </MDBox>
            )}
            <MDIconButton
              tooltipName="Add Token"
              type="button"
              aria-label="Add Token"
              sx={{ color: "#0b8cfb" }}
              disabled={crmTabSettings?.[`${fieldName}_sms`] ? false : true}
              onClick={() => setTokenOpen(!tokenOpen)}
            >
              <Icon fontSize="small">add</Icon>
            </MDIconButton>
          </MDBox>
        </MDBox>
        <MDInput
          multiline
          rows={3}
          variant="outlined"
          fullWidth
          name={`${fieldName}_sms_content`}
          id={`${fieldName}_sms_content`}
          placeholder="Send SMS"
          onChange={onChangeField}
          disabled={crmTabSettings?.[`${fieldName}_sms`] && !accessDisabled ? false : true}
          value={crmTabSettings?.[`${fieldName}_sms_content`] || ""}
        />
      </Grid>
      {/* <Grid item xs={12} sm={12} md={12} lg={12}>
        <FormControlLabel
          control={
            <Switch
              checked={crmTabSettings?.[`${fieldName}_email`] || false}
              onChange={onChangeToggle}
              inputProps={{ "aria-label": "controlled" }}
              name={`${fieldName}_email`}
              disabled={accessDisabled}
            />
          }
          label="Send Email"
          labelPlacement="end"
        />
        <DripEditor
          // addEmailToken={addEmailToken}
          value={crmTabSettings?.[`${fieldName}_email_content`] || ""}
          onChange={(e: any) => onChangeEditor(e, `${fieldName}_email_content`)}
          modules={constants.editorModules}
          isDisabled={crmTabSettings?.[`${fieldName}_email`] ? false : true}
          formats={constants.editorFormats}
          readOnly={crmTabSettings?.[`${fieldName}_email`] && !accessDisabled ? false : true}
        />
      </Grid> */}
    </>
  );
}

export default EmailSms;
