import { Grid } from "@mui/material";
import MDBox from "components/MDBox";
import MDDialog from "components/MDDialog";
import MDTypography from "components/MDTypography";
import Messages from "helper/messages";

const updateData = {
  Status: "In progress",
  expended_resolution_date: "05/07/2022",
  open_date: "05/07/2022",
  subject: "Action required",
  location: "Location 1",
  unit: "Unit 1",
  multi_unit_operator: "true",
  description: "testing",
};

function Index(props: any): JSX.Element {
  const { open, onClose } = props;

  const onCancelRequest = () => {
    console.log("Request cancelled.");
    onClose();
  };

  const dialogProps = {
    open,
    onClose,
    onSave: () => onCancelRequest(),
    title: `${Messages.GENERAL.CANCEL_REQUEST_TITLE} #1125?`,
    size: "md",
    saveBtn: false,
    deleteBtn: true,
    saveTbtText: "Yes, Confirm Cancellation",
    closeBtnText: "No, do not cancel this request",
  };

  return (
    <MDDialog {...dialogProps}>
      <MDBox mt={2}>
        <Grid container>
          {updateData?.Status && (
            <Grid item xs={12} sm={12} md={6}>
              <MDTypography fontWeight="bold" variant="button">
                Status
              </MDTypography>
              <MDBox>
                <MDTypography variant="button">{updateData?.Status}</MDTypography>
              </MDBox>
            </Grid>
          )}
          {updateData?.expended_resolution_date && (
            <Grid item xs={12} sm={6} md={6}>
              <MDTypography fontWeight="bold" variant="button">
                Expended Resolution Date
              </MDTypography>
              <MDBox>
                <MDTypography variant="button">{updateData?.expended_resolution_date}</MDTypography>
              </MDBox>
            </Grid>
          )}
          {updateData?.open_date && (
            <Grid item xs={12} sm={12} md={6}>
              <MDTypography fontWeight="bold" variant="button">
                Open Date
              </MDTypography>
              <MDBox>
                <MDTypography variant="button">{updateData?.open_date}</MDTypography>
              </MDBox>
            </Grid>
          )}
          {updateData?.subject && (
            <Grid item xs={12} sm={6} md={6}>
              <MDTypography fontWeight="bold" variant="button">
                Subject
              </MDTypography>
              <MDBox>
                <MDTypography variant="button">{updateData?.subject}</MDTypography>
              </MDBox>
            </Grid>
          )}
          {updateData?.location && (
            <Grid item xs={12} sm={6} md={6}>
              <MDTypography fontWeight="bold" variant="button">
                Location
              </MDTypography>
              <MDBox>
                <MDTypography variant="button">{updateData?.location}</MDTypography>
              </MDBox>
            </Grid>
          )}
          {updateData?.unit && (
            <Grid item xs={12} sm={6} md={6}>
              <MDTypography fontWeight="bold" variant="button">
                Unit
              </MDTypography>
              <MDBox>
                <MDTypography variant="button">{updateData.unit}</MDTypography>
              </MDBox>
            </Grid>
          )}
          {updateData?.multi_unit_operator && (
            <Grid item xs={12} sm={6} md={6}>
              <MDTypography fontWeight="bold" variant="button">
                Is this impacting common space or multiple units?
              </MDTypography>
              <MDBox>
                <MDTypography variant="button">{updateData.multi_unit_operator}</MDTypography>
              </MDBox>
            </Grid>
          )}
          {updateData?.description && (
            <Grid item xs={12} sm={6} md={6}>
              <MDTypography fontWeight="bold" variant="button">
                Description
              </MDTypography>
              <MDBox>
                <MDTypography variant="button">{updateData.description}</MDTypography>
              </MDBox>
            </Grid>
          )}
        </Grid>
      </MDBox>
    </MDDialog>
  );
}

export default Index;
