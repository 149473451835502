import { useEffect, useState } from "react";
import { VectorMap } from "@react-jvectormap/core";
import { worldMerc } from "@react-jvectormap/world";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import SalesTable from "examples/Tables/SalesTable";
import MDButton from "components/MDButton";
import { formatNumbers } from "helper/services";

function SalesByCountry(props: any): JSX.Element {
  const { dashboardDetails, onChangePage, showLoadMore } = props;
  const [tempDashboardData, setTempDashboardData] = useState<any>([]);
  const [salesData, setSalesData] = useState<any>([]);
  const [markersData, setmarkersData] = useState<any>([]);
  const [isShowMap, setShowMap] = useState<any>(false);
  const [loadMoreStatus, setLoadMoreState] = useState<boolean>(true);

  const getSalesTableData = () => {
    const salesTableData: any = [];
    tempDashboardData?.map((dashboard: any) => {
      salesTableData.push({
        ID: dashboard?.sola_id,
        "Location Name": dashboard?.location,
        "Rev / Occupied Chair": `$${formatNumbers(dashboard?.revenue_occupied_chair)}`,
        Occupancy: `${dashboard?.occupancy}%`,
        "Occupied Chair": dashboard?.occupied_chairs || 0,
        Leads: dashboard?.leads || 0,
      });
      return salesTableData;
    });
    setSalesData(salesTableData);
  };

  const loadMoreDashboardData = () => {
    setLoadMoreState(false);
    onChangePage();
    setShowMap(false);
  };

  useEffect(() => {
    if (tempDashboardData?.length) {
      getSalesTableData();
      const tempMarkersData: any = [];
      tempDashboardData?.map((dashboard: any) => {
        tempMarkersData.push({
          name: dashboard?.location,
          latLng: [parseFloat(dashboard?.lat), parseFloat(dashboard?.long)],
        });
        return true;
      });
      setmarkersData(tempMarkersData);
      setShowMap(true);
    }
  }, [tempDashboardData]);

  useEffect(() => {
    if (tempDashboardData && dashboardDetails) {
      if (!loadMoreStatus) {
        setTempDashboardData([...tempDashboardData, ...dashboardDetails]);
        setLoadMoreState(true);
      } else {
        setTempDashboardData(dashboardDetails);
        setLoadMoreState(true);
      }
    }
  }, [dashboardDetails]);

  return (
    <Card sx={{ width: "100%" }}>
      <MDBox display="flex">
        <MDBox
          display="flex"
          justifyContent="center"
          alignItems="center"
          width="4rem"
          height="4rem"
          variant="gradient"
          bgColor="success"
          color="white"
          shadow="md"
          borderRadius="xl"
          ml={3}
          mt={-2}
        >
          <Icon fontSize="medium" color="inherit">
            language
          </Icon>
        </MDBox>
        <MDTypography variant="h6" sx={{ mt: 2, mb: 1, ml: 2 }}>
          Locations
        </MDTypography>
      </MDBox>
      <MDBox p={2}>
        <Grid container>
          <Grid item xs={12} md={7} lg={7} className="location-list-data">
            <SalesTable rows={salesData} shadow={false} />
            <Grid container>
              <Grid item md={12} textAlign="center">
                {showLoadMore && (
                  <MDButton sx={{ width: "100%", marginTop: 1.5 }} onClick={loadMoreDashboardData}>
                    {isShowMap ? "Load More..." : "Loading..."}
                  </MDButton>
                )}
              </Grid>
            </Grid>
          </Grid>
          <Grid
            item
            xs={12}
            md={5}
            lg={5}
            sx={{
              display: "flex",
              alignItems: "center",
              minHeight: { xs: "150px", sm: "250px" },
              mt: { xs: 5, lg: 0 },
            }}
          >
            {!isShowMap ? (
              <MDButton fullWidth>Loading...</MDButton>
            ) : (
              <VectorMap
                map={worldMerc}
                zoomOnScroll={false}
                zoomButtons={false}
                markersSelectable
                focusOn={{
                  lat: 35.63228901243448,
                  lng: -97.40184611353963,
                  x: 1,
                  y: 1,
                  scale: 5,
                }}
                backgroundColor="transparent"
                onRegionClick={(event: any, code: any) => {
                  console.log(event.target, code, "test");
                }}
                markers={markersData}
                regionStyle={{
                  initial: {
                    fill: "#dee2e7",
                    "fill-opacity": 1,
                    stroke: "none",
                    "stroke-width": 0,
                    "stroke-opacity": 0,
                  },
                }}
                markerStyle={{
                  initial: {
                    fill: "#0080ea",
                    stroke: "#ffffff",
                    "stroke-width": 5,
                    "stroke-opacity": 0.5,
                    r: 7,
                  },
                  hover: {
                    fill: "#000",
                    stroke: "#ffffff",
                    "stroke-width": 5,
                    "stroke-opacity": 1,
                  },
                }}
                style={{
                  marginTop: "-1.5rem",
                }}
              />
            )}
          </Grid>
        </Grid>
      </MDBox>
    </Card>
  );
}

export default SalesByCountry;
