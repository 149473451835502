import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import BlogListing from "layouts/pages/franchisee-website/blogs/listing";
import { useState } from "react";
import { Card, Tabs, Tab } from "@mui/material";

import MDBox from "components/MDBox";
import MDTabPanel from "components/MDTabPanel";

function Index(): JSX.Element {
  const [tabValue, setTabValue] = useState<number>(0);
  const changeTab = (event: any, newValue: any) => {
    setTabValue(newValue);
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pb={3}>
        <Card>
          <MDBox minWidth={{ xs: "100%", md: "25rem" }} mx="auto" mt={1} mb={-3}>
            <Tabs className="location-tabs" value={tabValue} onChange={changeTab}>
              <Tab
                id="all"
                label={
                  <MDBox py={0.5} px={2} color="inherit">
                    All
                  </MDBox>
                }
              />
              <Tab
                id="press"
                label={
                  <MDBox py={0.5} px={2} color="inherit">
                    Press
                  </MDBox>
                }
              />
              <Tab
                id="blog"
                label={
                  <MDBox py={0.5} px={2} color="inherit">
                    Blog
                  </MDBox>
                }
              />
            </Tabs>
          </MDBox>
          <MDTabPanel value={tabValue} index={0}>
            <BlogListing kind="" />
          </MDTabPanel>
          <MDTabPanel value={tabValue} index={1}>
            <BlogListing kind="1" />
          </MDTabPanel>
          <MDTabPanel value={tabValue} index={2}>
            <BlogListing kind="0" />
          </MDTabPanel>
        </Card>
      </MDBox>
    </DashboardLayout>
  );
}

export default Index;
