import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MESSAGES from "helper/messages";

function Website(props: any): JSX.Element {
  const { solaProDetail, errors, onChangeField } = props;
  return (
    <Card id="website">
      <MDBox p={3}>
        <MDTypography variant="h5">Website</MDTypography>
      </MDBox>
      <MDBox pb={3} px={3}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6} md={6} lg={6}>
            <MDInput
              label="Website Url"
              variant="standard"
              fullWidth
              name="website_url"
              placeholder=""
              onChange={onChangeField}
              value={solaProDetail?.website_url ? solaProDetail.website_url : ""}
              error={errors && errors.website_url ? true : false}
              helperText={errors && errors.website_url}
              infoText={MESSAGES.INFO.WEBSITE_BOOKING_URL}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={6} lg={6}>
            <MDInput
              label="Booking Url"
              variant="standard"
              fullWidth
              name="booking_url"
              placeholder=""
              onChange={onChangeField}
              value={solaProDetail?.booking_url ? solaProDetail.booking_url : ""}
              error={errors && errors.booking_url ? true : false}
              helperText={errors && errors.booking_url}
              infoText={MESSAGES.INFO.WEBSITE_BOOKING_URL}
            />
          </Grid>
        </Grid>
      </MDBox>
    </Card>
  );
}

export default Website;
