import { useState, useEffect } from "react";
import Grid from "@mui/material/Grid";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";

function Address(props: any): JSX.Element {
  const { locationFranchiseView, onChangeField, errors, setSelectedCountryId, countryList } = props;
  const [selectedCountry, setSelectedCountry] = useState<any>({});
  useEffect(() => {
    if (locationFranchiseView?.country_id && countryList.length > 0) {
      const getFilter = countryList.find((v: any) => v.id === locationFranchiseView?.country_id);
      if (getFilter) {
        setSelectedCountry(getFilter);
      }
    }
  }, [locationFranchiseView]);

  const getCountryError = () => {
    let returnError = false;
    if (errors && errors.country_id) {
      returnError = true;
    }
    return returnError;
  };
  return (
    <MDBox>
      <MDBox p={2} mt={2}>
        <MDBox mb={1}>
          <MDTypography variant="body" fontSize={14} fontWeight="regular">
            Full address of location
          </MDTypography>
        </MDBox>
        <MDBox>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={12} md={12}>
              <MDInput
                label="Address line 1"
                variant="standard"
                fullWidth
                InputLabelProps={{ shrink: true }}
                name="address_1"
                required
                onChange={onChangeField}
                value={locationFranchiseView?.address_1 || ""}
                error={errors && errors.address_1 ? true : false}
                helperText={errors && errors.address_1}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={12}>
              <MDInput
                label="Address line 2"
                variant="standard"
                fullWidth
                InputLabelProps={{ shrink: true }}
                name="address_2"
                required
                onChange={onChangeField}
                value={locationFranchiseView?.address_2 || ""}
                error={errors && errors.address_2 ? true : false}
                helperText={errors && errors.address_2}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
              <MDInput
                label="City"
                variant="standard"
                fullWidth
                required
                InputLabelProps={{ shrink: true }}
                name="city"
                onChange={onChangeField}
                value={locationFranchiseView?.city || ""}
                error={errors && errors.city ? true : false}
                helperText={errors && errors.city}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
              <MDInput
                label="State / Province / Region"
                variant="standard"
                fullWidth
                required
                InputLabelProps={{ shrink: true }}
                name="state"
                onChange={onChangeField}
                value={locationFranchiseView?.state || ""}
                error={errors && errors.state ? true : false}
                helperText={errors && errors.state}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
              <MDInput
                label="Postal / Zip Code"
                variant="standard"
                fullWidth
                required
                InputLabelProps={{ shrink: true }}
                name="zipcode"
                onChange={onChangeField}
                value={locationFranchiseView?.zipcode || ""}
                error={errors && errors.zipcode ? true : false}
                helperText={errors && errors.zipcode}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
              <Autocomplete
                id="country_id"
                options={countryList}
                getOptionLabel={(option: any) => option.name || ""}
                value={selectedCountry}
                onChange={(event: any, newValue: any) => {
                  setSelectedCountryId(newValue);
                  setSelectedCountry(newValue);
                }}
                renderOption={(propsData: any, option: any) => (
                  <li {...propsData} key={option.id}>
                    {option.name}
                  </li>
                )}
                renderInput={(params: any) => (
                  <TextField
                    {...params}
                    variant="standard"
                    required
                    InputLabelProps={{ shrink: true }}
                    label="Country"
                    error={getCountryError()}
                    helperText={errors && errors.country_id}
                  />
                )}
              />
            </Grid>
          </Grid>
        </MDBox>
      </MDBox>
    </MDBox>
  );
}

export default Address;
