import { createAsyncThunk } from "@reduxjs/toolkit";
import { notificationSuccess, notificationFail } from "store/slices/notificationSlice";
import { setLoading } from "store/slices/authSlice";
import Messages from "helper/messages";
import {
  solaProsList,
  webPagesList,
  updateWebPagesRequests,
  setSolaProDetails,
  setBillingTabDetail,
  setWorkHoursDetail,
  setAllConnectServices,
  setActiveServices,
  setWebPageDetail,
  setUserLocations,
  setWebpageRequestIsApproved,
  setNewSolaProDetails,
  setLocationWebPagesList,
} from "store/slices/solaProSlice";
import {
  getAuthUser,
  getErrorMessage,
  getValue,
  getAssociationLocations,
  getFilterURL,
} from "helper/services";
import apiClient from "config/apiClient";
import slsApiClient from "config/slsApiClient";
import platformApiClient from "config/platformClient";

export const getWorkHoursDetails = createAsyncThunk(
  "getWorkHoursDetails",
  async (_request: any, { dispatch }) => {
    dispatch(setLoading(true));
    try {
      const response = await slsApiClient().get(`get-work-hours`, {
        params: {
          stylist_id: _request?.id,
        },
      });
      if (response) {
        dispatch(setWorkHoursDetail(response?.data?.data || {}));
      }
      dispatch(setLoading(false));
    } catch (error: any) {
      dispatch(setLoading(false));
      dispatch(notificationFail(error.message || Messages.ERROR.NO_DATA_FOUND));
    }
  }
);

// get salon professionals detail by id
export const getSolaProsDetail = createAsyncThunk(
  "getSolaProsDetail",
  async (_request: any, { dispatch }) => {
    dispatch(setLoading(true));
    try {
      const response = await apiClient().get(`stylists/${_request?.id}`);
      if (response) {
        if (response?.data?.id) {
          dispatch(setSolaProDetails(response?.data || null));
          dispatch(getWorkHoursDetails({ id: _request?.id }));
        } else {
          dispatch(setLoading(false));
        }
      }
    } catch (error: any) {
      dispatch(setLoading(false));
      dispatch(notificationFail(error.message || Messages.ERROR.NO_DATA_FOUND));
    }
  }
);

export const saveSolaProsWorkHours = createAsyncThunk(
  "saveSolaProsWorkHours",
  async (_request: any, { dispatch }) => {
    dispatch(setLoading(true));
    try {
      const response = await slsApiClient().post(`save-work-hours`, {
        stylist_id: _request?.id,
        work_hours: _request.work_hours,
      });
      if (!response) {
        dispatch(setLoading(false));
        dispatch(notificationFail(response?.data?.message || Messages.ERROR.SOMETHING_WENT_WRONG));
      }
    } catch (error: any) {
      dispatch(setLoading(false));
      dispatch(notificationFail(error.message || Messages.ERROR.SOMETHING_WENT_WRONG));
    }
  }
);

// update salon professionals detail by id
export const updateSolaProsWebsiteDetail = createAsyncThunk(
  "updateSolaProsWebsiteDetail",
  async (_request: any, { dispatch }) => {
    dispatch(setLoading(true));
    try {
      const response = await slsApiClient().post(`update-sola-pro-website`, {
        id: _request?.id,
        display_personal_contact_info: _request?.display_personal_contact_info || false,
        email_address_display: _request?.email_address_display || false,
        business_name_display: _request?.business_name_display || false,
        website_email_address: _request?.website_email_address || "",
        website_phone_number: _request?.website_phone_number || "",
      });
      if (response?.data?.status) {
        dispatch(setLoading(false));
        if (_request?.id) {
          if (Object.keys(_request?.work_hours)?.length) {
            dispatch(saveSolaProsWorkHours(_request)).then(() => {
              dispatch(getSolaProsDetail({ id: _request?.id }));
            });
          } else {
            dispatch(getSolaProsDetail({ id: _request?.id }));
          }
        }
      } else {
        dispatch(setLoading(false));
        dispatch(notificationFail(response?.data?.message || Messages.ERROR.SOMETHING_WENT_WRONG));
      }
    } catch (error: any) {
      dispatch(setLoading(false));
      dispatch(notificationFail(error.message || Messages.ERROR.SOMETHING_WENT_WRONG));
    }
  }
);

// get salon professionals list
export const getSolaProsList = createAsyncThunk(
  "getSolaProsList",
  async (_request: any, { dispatch }) => {
    const page = _request.pageIndex || 0;
    const size = _request.pageSize || 50;
    const search = _request.search || "";
    const sortBy = _request.sort || [];
    const filterBy = _request?.filterData || [];
    const locationId = _request.locationId || null;
    const status = _request?.status || "active";
    const userId = _request?.userId || null;

    dispatch(setLoading(true));
    try {
      let url = `get-sola-pro-list?page=${page}&per_page=${size}&status=${status}`;
      // let url = `get-stylists-list?page=${page}&per_page=${size}&status=${status}`;
      if (search) {
        url += `&search=${search}`;
      }
      if (sortBy?.length) {
        url += `&field=${sortBy[0]?.id}&order_by=${sortBy[0]?.desc ? "desc" : "asc"}`;
      }

      if (userId) {
        url += `&user_id=${userId}`;
      }

      // // Apply advance filter
      if (filterBy && filterBy.length) {
        url = getFilterURL(url, filterBy);
      }

      const response = await slsApiClient().get(url, {
        // const response = await slsApiClient().get(url, {
        params: locationId ? { get_location_by_ids: [locationId] } : getAssociationLocations(),
      });
      if (response) {
        dispatch(setLoading(false));
        dispatch(solaProsList(response?.data || []));
      }
    } catch (error: any) {
      dispatch(setLoading(false));
      dispatch(notificationFail(error.message || Messages.ERROR.NO_DATA_FOUND));
    }
  }
);

// get billing tab detail by stylist id
export const getBilligTabDetails = createAsyncThunk(
  "getBilligTabDetails",
  async (_request: any, { dispatch }) => {
    dispatch(setLoading(true));
    try {
      const { stylistId }: any = _request;
      const response = await slsApiClient().get(`get-sola-pro-billing-detail`, {
        params: {
          stylist_id: stylistId,
        },
      });
      if (response) {
        dispatch(setBillingTabDetail(response?.data || {}));
        dispatch(setLoading(false));
      }
    } catch (error: any) {
      dispatch(setLoading(false));
      dispatch(notificationFail(error.message || Messages.ERROR.NO_DATA_FOUND));
    }
  }
);

export const saveSolaProfessional = createAsyncThunk(
  "saveSolaProfessional",
  async (_request: any, { dispatch }) => {
    dispatch(setLoading(true));
    const {
      solaProDetail,
      onClose,
      isUpdate,
      isWebsiteUpdate,
      pageNo,
      perPage,
      search,
      sortBy,
      locationId,
      callback,
    } = _request;
    const solaProRequest = new FormData();
    if (isUpdate && solaProDetail?.id) {
      solaProRequest.append("stylist[id]", getValue(solaProDetail?.id));
    }
    solaProRequest.append("stylist[reserved]", getValue(solaProDetail?.reserved || false));
    solaProRequest.append("stylist[f_name]", getValue(solaProDetail?.f_name));
    solaProRequest.append("stylist[m_name]", getValue(solaProDetail?.m_name));
    solaProRequest.append("stylist[l_name]", getValue(solaProDetail?.l_name));
    solaProRequest.append("stylist[url_name]", getValue(solaProDetail?.url_name));
    solaProRequest.append("stylist[biography]", getValue(solaProDetail?.biography));
    solaProRequest.append("stylist[status]", solaProDetail?.status);
    if (solaProDetail?.inactive_reason) {
      solaProRequest.append("stylist[inactive_reason]", getValue(solaProDetail?.inactive_reason));
    }
    solaProRequest.append("stylist[phone_number]", getValue(solaProDetail?.phone_number));
    solaProRequest.append(
      "stylist[phone_number_display]",
      getValue(solaProDetail?.phone_number_display)
    );
    solaProRequest.append("stylist[email_address]", getValue(solaProDetail?.email_address));
    solaProRequest.append(
      "stylist[send_a_message_button]",
      getValue(solaProDetail?.send_a_message_button)
    );
    solaProRequest.append("stylist[location_id]", getValue(solaProDetail?.location?.id));
    solaProRequest.append("stylist[business_name]", getValue(solaProDetail?.business_name));
    solaProRequest.append("stylist[work_hours]", getValue(solaProDetail?.work_hours));
    solaProRequest.append("stylist[studio_number]", getValue(solaProDetail?.studio_number));
    solaProRequest.append(
      "stylist[accepting_new_clients]",
      getValue(solaProDetail?.accepting_new_clients)
    );
    solaProRequest.append("stylist[walkins]", getValue(solaProDetail?.walkins));
    solaProRequest.append("stylist[website_url]", getValue(solaProDetail?.website_url));
    solaProRequest.append("stylist[booking_url]", getValue(solaProDetail?.booking_url));
    solaProRequest.append("stylist[facebook_url]", getValue(solaProDetail?.facebook_url));
    solaProRequest.append("stylist[google_plus_url]", getValue(solaProDetail?.google_plus_url));
    solaProRequest.append("stylist[instagram_url]", getValue(solaProDetail?.instagram_url));
    solaProRequest.append("stylist[linkedin_url]", getValue(solaProDetail?.linkedin_url));
    solaProRequest.append("stylist[pinterest_url]", getValue(solaProDetail?.pinterest_url));
    solaProRequest.append("stylist[twitter_url]", getValue(solaProDetail?.twitter_url));
    solaProRequest.append("stylist[yelp_url]", getValue(solaProDetail?.yelp_url));
    solaProRequest.append("stylist[tik_tok_url]", getValue(solaProDetail?.tik_tok_url));
    solaProRequest.append("stylist[barber]", getValue(solaProDetail?.barber));
    solaProRequest.append("stylist[botox]", getValue(solaProDetail?.botox));
    solaProRequest.append("stylist[brows]", getValue(solaProDetail?.brows));
    solaProRequest.append("stylist[hair]", getValue(solaProDetail?.hair));
    solaProRequest.append("stylist[hair_extensions]", getValue(solaProDetail?.hair_extensions));
    solaProRequest.append(
      "stylist[laser_hair_removal]",
      getValue(solaProDetail?.laser_hair_removal)
    );
    solaProRequest.append(
      "stylist[eyelash_extensions]",
      getValue(solaProDetail?.eyelash_extensions)
    );
    solaProRequest.append("stylist[makeup]", getValue(solaProDetail?.makeup));
    solaProRequest.append("stylist[massage]", getValue(solaProDetail?.massage));
    solaProRequest.append("stylist[microblading]", getValue(solaProDetail?.microblading));
    solaProRequest.append("stylist[nails]", getValue(solaProDetail?.nails));
    solaProRequest.append("stylist[permanent_makeup]", getValue(solaProDetail?.permanent_makeup));
    solaProRequest.append("stylist[skin]", getValue(solaProDetail?.skin));
    solaProRequest.append("stylist[tanning]", getValue(solaProDetail?.tanning));
    solaProRequest.append("stylist[teeth_whitening]", getValue(solaProDetail?.teeth_whitening));
    solaProRequest.append("stylist[threading]", getValue(solaProDetail?.threading));
    solaProRequest.append("stylist[waxing]", getValue(solaProDetail?.waxing));
    solaProRequest.append("stylist[other_service]", getValue(solaProDetail?.other_service));
    solaProRequest.append("stylist[home_address_1]", getValue(solaProDetail?.home_address_1));
    solaProRequest.append("stylist[home_address_2]", getValue(solaProDetail?.home_address_2));
    solaProRequest.append("stylist[home_city]", getValue(solaProDetail?.home_city));
    solaProRequest.append("stylist[home_state]", getValue(solaProDetail?.home_state));
    solaProRequest.append("stylist[home_postal_code]", getValue(solaProDetail?.home_postal_code));
    solaProRequest.append("stylist[country]", getValue(solaProDetail?.country));
    for (let i = 1; i <= 10; i++) {
      solaProRequest.append(`stylist[image_${i}]`, getValue(solaProDetail[`image_${i}`]));
      solaProRequest.append(
        `stylist[delete_image_${i}]`,
        getValue(solaProDetail[`delete_image_${i}`])
      );
      solaProRequest.append(
        `stylist[image_${i}_alt_text]`,
        getValue(solaProDetail[`image_${i}_alt_text`])
      );
      if (solaProDetail[`testimonial_${i}`]?.text) {
        solaProRequest.append(
          `stylist[testimonial_${i}_attributes[name]]`,
          getValue(solaProDetail[`testimonial_${i}`]?.name)
        );
        solaProRequest.append(
          `stylist[testimonial_${i}_attributes[region]]`,
          getValue(solaProDetail[`testimonial_${i}`]?.region)
        );
        solaProRequest.append(
          `stylist[testimonial_${i}_attributes[text]]`,
          getValue(solaProDetail[`testimonial_${i}`]?.text)
        );
        solaProRequest.append(
          `stylist[testimonial_${i}_attributes[title]]`,
          getValue(solaProDetail[`testimonial_${i}`]?.title)
        );
      }
      if (solaProDetail[`testimonial_${i}`]?.id) {
        solaProRequest.append(
          `stylist[testimonial_${i}_attributes[id]]`,
          getValue(solaProDetail[`testimonial_${i}`]?.id)
        );
      }
      if (solaProDetail[`testimonial_${i}`]?.is_deleted) {
        solaProRequest.append(
          `stylist[testimonial_${i}_attributes[_destroy]]`,
          getValue(solaProDetail[`testimonial_${i}`]?.is_deleted)
        );
      }
    }
    solaProRequest.append(
      "stylist[billing_first_name]",
      getValue(solaProDetail?.billing_first_name)
    );
    solaProRequest.append("stylist[billing_last_name]", getValue(solaProDetail?.billing_last_name));
    solaProRequest.append("stylist[billing_email]", getValue(solaProDetail?.billing_email));
    solaProRequest.append("stylist[billing_phone]", getValue(solaProDetail?.billing_phone));
    solaProRequest.append("stylist[password]", getValue(solaProDetail?.password));
    solaProRequest.append(
      "stylist[password_confirmation]",
      getValue(solaProDetail?.password_confirmation)
    );
    solaProRequest.append("stylist[is_deleted]", solaProDetail?.is_deleted);
    try {
      const loginUser = getAuthUser();
      const response = isUpdate
        ? await apiClient(true).put(
            `stylists/${solaProDetail.id}?current_user=${loginUser?.user_id}`,
            solaProRequest
          )
        : await apiClient(true).post("stylists", solaProRequest);
      dispatch(setLoading(false));
      if (response) {
        if (isWebsiteUpdate && solaProDetail?.id) {
          dispatch(
            updateSolaProsWebsiteDetail({
              id: solaProDetail?.id,
              display_personal_contact_info: solaProDetail?.display_personal_contact_info || false,
              email_address_display: solaProDetail?.email_address_display || false,
              business_name_display: solaProDetail?.business_name_display || false,
              website_email_address: solaProDetail?.website_email_address || "",
              website_phone_number: solaProDetail?.website_phone_number || "",
              work_hours: solaProDetail?.work_hours_data || {},
            })
          );
        }
        if (callback) callback();
        if (onClose) onClose();
        if (isUpdate) {
          dispatch(
            notificationSuccess(
              response?.data?.message || Messages.SUCCESS.SOLA_PROFESSIONAL_UPDATE_SUCCESS
            )
          );
          if (onClose) {
            onClose();
            dispatch(
              getSolaProsList({
                pageIndex: pageNo,
                pageSize: perPage,
                search,
                sort: sortBy,
                locationId,
              })
            );
          }
        } else {
          dispatch(
            notificationSuccess(
              response?.data?.message || Messages.SUCCESS.SOLA_PROFESSIONAL_CREATE_SUCCESS
            )
          );
          if (pageNo) {
            dispatch(getSolaProsList({ pageIndex: pageNo, pageSize: perPage }));
          }
        }
        if (!isWebsiteUpdate && solaProDetail?.id) {
          dispatch(getSolaProsDetail({ id: solaProDetail?.id, pastCharges: true }));
        }
      } else {
        dispatch(
          notificationFail(response?.data?.message || Messages.ERROR.SOLA_PROFESSIONAL_SAVE_ERROR)
        );
      }
    } catch (error: any) {
      dispatch(setLoading(false));
      if (error?.response?.data?.error) {
        const message = getErrorMessage(error?.response?.data?.error);
        dispatch(notificationFail(message));
      } else {
        dispatch(
          notificationFail(
            error?.response?.data?.message || Messages.ERROR.SOLA_PROFESSIONAL_SAVE_ERROR
          )
        );
      }
    }
  }
);

// update Stylist images order
export const updateStylistImageOrder = createAsyncThunk(
  "updateStylistImageOrder",
  async (_request: any, { dispatch }) => {
    dispatch(setLoading(true));
    try {
      const { imagesArray, id, setUpdateImageOrder } = _request;

      const formData = new FormData();

      if (imagesArray.length) {
        for (let i = 0; i < imagesArray.length; i++) {
          formData.append(`orders[][name]`, imagesArray[i]?.name);
          formData.append(`orders[][order]`, (i + 1).toString());
        }
      }

      const response = await apiClient().post(`stylists/${id}/rearrange_image_order`, formData);
      if (response?.data?.message) {
        dispatch(notificationSuccess(response?.data?.message));
        dispatch(getSolaProsDetail({ id, pastCharges: true }));
      } else {
        dispatch(setLoading(false));
        dispatch(notificationFail(response?.data?.message || Messages.ERROR.SOMETHING_WENT_WRONG));
      }
      if (setUpdateImageOrder) {
        setUpdateImageOrder(false);
      }
    } catch (error: any) {
      dispatch(notificationFail(error.message || Messages.ERROR.SOMETHING_WENT_WRONG));
      dispatch(setLoading(false));
    }
  }
);

// update salon professionals detail by id
export const updateSolaProsBillingDetail = createAsyncThunk(
  "updateSolaProsBillingDetail",
  async (_request: any, { dispatch }) => {
    dispatch(setLoading(true));
    try {
      const response = await slsApiClient().post(`update-sola-pro-billing`, {
        sola_pro_id: _request.id || null,
        ..._request,
      });
      if (response?.data?.status) {
        dispatch(notificationSuccess(Messages.SUCCESS.SOLA_PROFESSIONAL_UPDATE_SUCCESS));
        dispatch(setLoading(false));
      } else {
        dispatch(setLoading(false));
        dispatch(notificationFail(response?.data?.message || Messages.ERROR.SOMETHING_WENT_WRONG));
      }
    } catch (error: any) {
      dispatch(setLoading(false));
      dispatch(notificationFail(error.message || Messages.ERROR.SOMETHING_WENT_WRONG));
    }
  }
);

// get sola web list
export const getWebPagesList = createAsyncThunk(
  "getWebPagesList",
  async (_request: any, { dispatch }) => {
    const page = _request.pageIndex || 0;
    const size = _request.pageSize || 50;
    const search = _request.search || "";
    const sortBy = _request.sort || [];

    dispatch(setLoading(true));
    try {
      let url = `get-web-pages?page=${page}&per_page=${size}`;
      if (search) {
        url += `&search=${search}`;
      }
      if (sortBy?.length) {
        url += `&field=${sortBy[0]?.id}&order_by=${sortBy[0]?.desc ? "desc" : "asc"}`;
      }

      const response = await platformApiClient().get(url, {
        params: getAssociationLocations(),
      });
      if (response) {
        dispatch(setLoading(false));
        dispatch(webPagesList(response?.data || []));
      }
    } catch (error: any) {
      dispatch(setLoading(false));
      dispatch(notificationFail(error.message || Messages.ERROR.NO_DATA_FOUND));
    }
  }
);

// get webpage by location list
export const getWebpageByLocationId = createAsyncThunk(
  "getWebpageByLocationId",
  async (_request: any, { dispatch }) => {
    const page = _request.pageIndex || 0;
    const size = _request.pageSize || 50;
    const search = _request.search || "";
    const sortBy = _request.sort || [];
    const stylistId = _request.stylistId || null;
    const email = _request.email || null;
    const filterBy = _request?.filterData || [];

    dispatch(setLoading(true));
    try {
      let url = `get-webpage-by-location-id?page=${page}&per_page=${size}`;
      if (search) {
        url += `&search=${search}`;
      }
      if (sortBy?.length) {
        url += `&field=${sortBy[0]?.id}&order_by=${sortBy[0]?.desc ? "desc" : "asc"}`;
      }

      if (stylistId) {
        url += `&stylist_id=${stylistId}`;
      }

      if (email) {
        url += `&email=${email}`;
      }

      // Apply advance filter
      if (filterBy && filterBy.length) {
        url = getFilterURL(url, filterBy);
      }

      const response = await platformApiClient().get(url);
      if (response) {
        dispatch(setLoading(false));
        dispatch(setLocationWebPagesList(response?.data || []));
      }
    } catch (error: any) {
      dispatch(setLoading(false));
      dispatch(
        notificationFail(
          error?.response?.data?.message || error.message || Messages.ERROR.NO_DATA_FOUND
        )
      );
    }
  }
);

// get update web pages request listing
export const updateWebPagesListing = createAsyncThunk(
  "updateWebPagesListing",
  async (_request: any, { dispatch }) => {
    const page = _request.pageIndex || 0;
    const size = _request.pageSize || 50;
    const search = _request.search || "";
    const sortBy = _request.sort || [];
    const status = _request.status || "pending";

    dispatch(setLoading(true));
    try {
      let url = `get-update-web-page-requests?page=${page}&per_page=${size}&status=${status}`;
      if (search) {
        url += `&search=${search}`;
      }
      if (sortBy?.length) {
        url += `&field=${sortBy[0]?.id}&order_by=${sortBy[0]?.desc ? "desc" : "asc"}`;
      }

      const response = await platformApiClient().get(url, {
        params: getAssociationLocations(),
      });
      if (response) {
        dispatch(setLoading(false));
        dispatch(updateWebPagesRequests(response?.data || []));
      }
    } catch (error: any) {
      dispatch(setLoading(false));
      dispatch(notificationFail(error.message || Messages.ERROR.NO_DATA_FOUND));
    }
  }
);

// get rejected update web pages request listing
export const rejectedUpdateWebPagesListing = createAsyncThunk(
  "rejectedUpdateWebPagesListing",
  async (_request: any, { dispatch }) => {
    const page = _request.pageIndex || 0;
    const size = _request.pageSize || 50;
    const search = _request.search || "";
    const sortBy = _request.sort || [];

    dispatch(setLoading(true));
    try {
      let url = `get-rejected-web-page-requests?page=${page}&per_page=${size}`;
      if (search) {
        url += `&search=${search}`;
      }
      if (sortBy?.length) {
        url += `&field=${sortBy[0]?.id}&order_by=${sortBy[0]?.desc ? "desc" : "asc"}`;
      }

      const response = await platformApiClient().get(url, {
        params: getAssociationLocations(),
      });
      if (response) {
        dispatch(setLoading(false));
        dispatch(updateWebPagesRequests(response?.data || []));
      }
    } catch (error: any) {
      dispatch(setLoading(false));
      dispatch(
        notificationFail(
          error?.response?.data?.message || error.message || Messages.ERROR.SOMETHING_WENT_WRONG
        )
      );
    }
  }
);

// get all connect services list
export const getAllConnectServices = createAsyncThunk(
  "getAllConnectServices",
  async (_request: any, { dispatch }) => {
    dispatch(setLoading(true));
    try {
      const response = await platformApiClient().get("get-connect-services");
      if (response) {
        dispatch(setLoading(false));
        dispatch(setAllConnectServices(response?.data?.data || []));
      }
    } catch (error: any) {
      dispatch(setLoading(false));
      dispatch(notificationFail(error.message || Messages.ERROR.NO_DATA_FOUND));
    }
  }
);

// get all connect services list
export const getUserCombineServices = createAsyncThunk(
  "getUserCombineServices",
  async (_request: any, { dispatch }) => {
    dispatch(setLoading(true));
    try {
      const response = await platformApiClient().get("get-users-combine-services", {
        params: {
          user_ids: JSON.stringify(_request?.user_ids),
        },
      });
      if (response) {
        dispatch(setLoading(false));
        dispatch(setActiveServices(response?.data?.data || []));
      }
    } catch (error: any) {
      dispatch(setLoading(false));
      dispatch(notificationFail(error.message || Messages.ERROR.NO_DATA_FOUND));
    }
  }
);

// get web page details
export const getWebPageDetails = createAsyncThunk(
  "getWebPageDetails",
  async (_request: any, { dispatch }) => {
    dispatch(setLoading(true));
    const { id, platform } = _request;
    try {
      const response = await platformApiClient().get("get-web-page-detail", {
        params: {
          id,
          platform,
        },
      });
      if (response) {
        dispatch(setLoading(false));
        dispatch(setWebPageDetail(response?.data?.data || {}));
      }
    } catch (error: any) {
      dispatch(setLoading(false));
      dispatch(
        notificationFail(
          error?.response?.data?.message || Messages.ERROR.SOMETHING_WENT_WRONG || error.message
        )
      );
    }
  }
);

// get all connect services list
export const saveWebPages = createAsyncThunk(
  "saveWebPages",
  async (_request: any, { dispatch }) => {
    const { webPageDetail, isUpdate, pageNo, perPage, callback, method } = _request;
    const loginUser = getAuthUser();
    const webPageRequest = new FormData();
    if (isUpdate && webPageDetail?.id) {
      webPageRequest.append("connect_webpage_id", getValue(webPageDetail?.id));
      webPageRequest.append("current_user", loginUser?.user_id);
    }
    webPageRequest.append("reserved", getValue(webPageDetail?.reserved || false));
    webPageRequest.append("website_url", getValue(webPageDetail?.website_url));
    webPageRequest.append("booking_url", getValue(webPageDetail?.booking_url));
    webPageRequest.append("url", getValue(webPageDetail?.url));
    webPageRequest.append("website_phone", getValue(webPageDetail?.website_phone));
    webPageRequest.append("description", getValue(webPageDetail?.description));
    webPageRequest.append("website_email", getValue(webPageDetail?.website_email));
    webPageRequest.append("business_name", getValue(webPageDetail?.business_name));
    webPageRequest.append("url_name", getValue(webPageDetail?.url_name));
    webPageRequest.append("subtitle", getValue(webPageDetail?.subtitle));
    webPageRequest.append("studio_number", getValue(webPageDetail?.studio_number));
    webPageRequest.append("accepting_new_clients", webPageDetail?.accepting_new_clients || false);
    webPageRequest.append("is_show_work_hours", webPageDetail?.is_show_work_hours || false);
    webPageRequest.append("walkins", webPageDetail?.walkins || false);
    webPageRequest.append("is_standard_work_hours", webPageDetail?.is_standard_work_hours || false);
    webPageRequest.append("method", method);
    webPageRequest.append(
      "send_a_message_button",
      getValue(webPageDetail?.send_a_message_button || false)
    );
    webPageRequest.append(
      "connect_users",
      JSON.stringify([
        ...new Set(webPageDetail?.connect_users?.map((item: any) => Number(item?.user_id))),
      ])
    );
    if (method === "new") {
      webPageRequest.append(
        "location_ids",
        JSON.stringify([...new Set(webPageDetail?.location?.map((obj: any) => obj?.id))])
      );
    }
    webPageRequest.append(
      "location_permission_data",
      JSON.stringify(webPageDetail?.location_permission_data)
    );
    if (webPageDetail?.is_standard_work_hours) {
      webPageRequest.append(
        "work_hours",
        JSON.stringify(webPageDetail?.work_hours_data) || JSON.stringify({})
      );
    }
    webPageRequest.append(
      "norm_work_hours",
      webPageDetail?.norm_work_hours?.replace(/<p><br><\/p>/g, "") || false
    );
    webPageRequest.append("facebook_url", getValue(webPageDetail?.facebook_url));
    webPageRequest.append("google_plus_url", getValue(webPageDetail?.google_plus_url));
    webPageRequest.append("instagram_url", getValue(webPageDetail?.instagram_url));
    webPageRequest.append("linkedin_url", getValue(webPageDetail?.linkedin_url));
    webPageRequest.append("pinterest_url", getValue(webPageDetail?.pinterest_url));
    webPageRequest.append("twitter_url", getValue(webPageDetail?.twitter_url));
    webPageRequest.append("yelp_url", getValue(webPageDetail?.yelp_url));
    webPageRequest.append("tik_tok_url", getValue(webPageDetail?.tik_tok_url));
    for (let i = 1; i <= 10; i++) {
      webPageRequest.append(`image_${i}`, webPageDetail?.[`image_${i}`] || "");
      webPageRequest.append(`delete_image_${i}`, getValue(webPageDetail[`delete_image_${i}`]));
      if (webPageDetail[`testimonial_${i}`]?.text) {
        webPageRequest.append(
          `testimonial_${i}_attributes[name]`,
          getValue(webPageDetail[`testimonial_${i}`]?.name)
        );
        webPageRequest.append(
          `testimonial_${i}_attributes[region]`,
          getValue(webPageDetail[`testimonial_${i}`]?.region)
        );
        webPageRequest.append(
          `testimonial_${i}_attributes[text]`,
          getValue(webPageDetail[`testimonial_${i}`]?.text)
        );
        webPageRequest.append(
          `testimonial_${i}_attributes[title]`,
          getValue(webPageDetail[`testimonial_${i}`]?.title)
        );
      }
      if (webPageDetail[`testimonial_${i}`]?.id) {
        webPageRequest.append(
          `testimonial_${i}_attributes[id]`,
          getValue(webPageDetail[`testimonial_${i}`]?.id)
        );
      }
      if (webPageDetail[`testimonial_${i}`]?.is_deleted) {
        webPageRequest.append(
          `testimonial_${i}_attributes[_destroy]`,
          getValue(webPageDetail[`testimonial_${i}`]?.is_deleted)
        );
      }
    }
    // SD-3077 Banner changes
    webPageRequest.append("banner", webPageDetail?.banner || "");
    webPageRequest.append("delete_banner_image", getValue(webPageDetail?.delete_banner_image));

    dispatch(setLoading(true));
    try {
      const response = isUpdate
        ? await platformApiClient(true).post(`edit-web-page`, webPageRequest)
        : await platformApiClient(true).post("create-web-page", webPageRequest);
      if (response) {
        dispatch(setLoading(false));
        if (isUpdate) {
          dispatch(
            getWebPageDetails({
              id: webPageDetail?.id,
              platform: "web",
            })
          );
          dispatch(notificationSuccess(Messages.SUCCESS.WEBPAGE_UPDATE_SUCCESS));
        } else {
          if (callback) callback();
          dispatch(notificationSuccess(Messages.SUCCESS.WEBPAGE_CREATE_SUCCESS));
          if (pageNo) {
            dispatch(getWebPagesList({ pageIndex: pageNo, pageSize: perPage }));
          }
        }
      }
    } catch (error: any) {
      dispatch(setLoading(false));
      dispatch(
        notificationFail(
          error?.response?.data?.message || Messages.ERROR.SOMETHING_WENT_WRONG || error.message
        )
      );
    }
  }
);

// get user locations
export const getUserLocations = createAsyncThunk(
  "getUserLocations",
  async (_request: any, { dispatch }) => {
    dispatch(setUserLocations([]));
    dispatch(setLoading(true));
    const { connectUserId } = _request;
    let response: any = [];
    try {
      response = await platformApiClient().get("get-user-locations", {
        params: {
          connect_user_id: connectUserId,
        },
      });
      if (response) {
        dispatch(setUserLocations(response?.data?.data || []));
      }
    } catch (error: any) {
      dispatch(setLoading(false));
      dispatch(
        notificationFail(
          error?.response?.data?.message || Messages.ERROR.SOMETHING_WENT_WRONG || error.message
        )
      );
    }
    return response?.data?.data || [];
  }
);

// check webpage request is approved or not
export const checkWebpageRequestIsApproved = createAsyncThunk(
  "checkWebpageRequestIsApproved",
  async (_request: any, { dispatch }) => {
    dispatch(setLoading(true));
    let response: any = {};
    try {
      response = await platformApiClient().get("check-web-page-request-approved", {
        params: {
          id: _request?.webpage_request_id,
        },
      });
      if (response) {
        dispatch(setLoading(false));
        dispatch(setWebpageRequestIsApproved(response?.data?.data || {}));
      }
    } catch (error: any) {
      dispatch(setLoading(false));
      dispatch(
        notificationFail(
          error?.response?.data?.message || Messages.ERROR.SOMETHING_WENT_WRONG || error.message
        )
      );
    }
    return response?.data?.data || {};
  }
);

// reject webpage requests
export const rejectWebPageRequest = createAsyncThunk(
  "rejectWebPageRequest",
  async (_request: any, { dispatch }) => {
    const page = _request.pageIndex || 0;
    const size = _request.pageSize || 50;
    const search = _request.search || "";
    const sortBy = _request.sort || [];

    dispatch(setLoading(true));
    try {
      const loginUser = getAuthUser();
      const response = await platformApiClient().delete(
        `rejected-web-page-requests/${_request?.webpage_request_id}`,
        {
          params: {
            current_user: loginUser?.user_id,
          },
        }
      );
      if (response) {
        _request?.callBack();
        dispatch(notificationSuccess(Messages.SUCCESS.WEBPAGE_REQUEST_REJECT_SUCCESS));
        dispatch(
          updateWebPagesListing({
            pageIndex: page,
            pageSize: size,
            search,
            sort: sortBy,
            status: "pending",
          })
        );
      }
    } catch (error: any) {
      dispatch(setLoading(false));
      dispatch(
        notificationFail(
          error?.response?.data?.message || Messages.ERROR.SOMETHING_WENT_WRONG || error.message
        )
      );
    }
  }
);

// approve webpage requests
export const approveWebpageRequests = createAsyncThunk(
  "approveWebpageRequests",
  async (_request: any, { dispatch }) => {
    const { webpageRequest, pageNo, perPage, sortBy, search } = _request;

    const approveRequest = new FormData();
    approveRequest.append("id", getValue(webpageRequest?.id));
    approveRequest.append("reserved", getValue(webpageRequest?.reserved || false));
    approveRequest.append("website_url", getValue(webpageRequest?.website_url));
    approveRequest.append("booking_url", getValue(webpageRequest?.booking_url));
    approveRequest.append("url_name", getValue(webpageRequest?.url_name));
    approveRequest.append("website_phone", getValue(webpageRequest?.website_phone));
    approveRequest.append("description", getValue(webpageRequest?.description));
    approveRequest.append("website_email", getValue(webpageRequest?.website_email));
    approveRequest.append("business_name", getValue(webpageRequest?.business_name));
    approveRequest.append("subtitle", getValue(webpageRequest?.subtitle));
    approveRequest.append("studio_number", getValue(webpageRequest?.studio_number));
    approveRequest.append("accepting_new_clients", webpageRequest?.accepting_new_clients || false);
    approveRequest.append("walkins", webpageRequest?.walkins || false);
    approveRequest.append(
      "send_a_message_button",
      getValue(webpageRequest?.send_a_message_button || false)
    );
    approveRequest.append("facebook_url", getValue(webpageRequest?.facebook_url));
    approveRequest.append("google_plus_url", getValue(webpageRequest?.google_plus_url));
    approveRequest.append("instagram_url", getValue(webpageRequest?.instagram_url));
    approveRequest.append("linkedin_url", getValue(webpageRequest?.linkedin_url));
    approveRequest.append("pinterest_url", getValue(webpageRequest?.pinterest_url));
    approveRequest.append("twitter_url", getValue(webpageRequest?.twitter_url));
    approveRequest.append("yelp_url", getValue(webpageRequest?.yelp_url));
    approveRequest.append("tik_tok_url", getValue(webpageRequest?.tik_tok_url));
    for (let i = 1; i <= 10; i++) {
      if (webpageRequest?.[`image_${i}`] && typeof webpageRequest?.[`image_${i}`] === "object") {
        approveRequest.append(`image_${i}`, getValue(webpageRequest?.[`image_${i}`]));
      } else {
        approveRequest.append(`image_${i}`, getValue(webpageRequest?.[`image_${i}_url`]));
      }
      approveRequest.append(`delete_image_${i}`, getValue(webpageRequest[`delete_image_${i}`]));
      approveRequest.append(
        `testimonial_${i}_attributes[name]`,
        getValue(webpageRequest[`testimonial_${i}`]?.name)
      );
      approveRequest.append(
        `testimonial_${i}_attributes[region]`,
        getValue(webpageRequest[`testimonial_${i}`]?.region)
      );
      approveRequest.append(
        `testimonial_${i}_attributes[text]`,
        getValue(webpageRequest[`testimonial_${i}`]?.text)
      );
      approveRequest.append(
        `testimonial_${i}_attributes[title]`,
        getValue(webpageRequest[`testimonial_${i}`]?.title)
      );
    }
    // SD-3077 Banner changes
    if (webpageRequest?.banner_image_url) {
      approveRequest.append("banner_image_url", getValue(webpageRequest?.banner_image_url));
    } else {
      approveRequest.append("banner", getValue(webpageRequest?.banner_image_url));
      approveRequest.append("delete_banner_image", getValue(webpageRequest?.delete_banner_image));
    }

    dispatch(setLoading(true));
    try {
      const loginUser = getAuthUser();
      const response = await platformApiClient(true).post(
        `approve-web-page?current_user=${loginUser?.user_id}`,
        approveRequest
      );
      if (response) {
        _request?.callBack();
        dispatch(notificationSuccess(Messages.SUCCESS.WEBPAGE_REQUEST_APPROVE_SUCCESS));
        dispatch(
          updateWebPagesListing({
            pageIndex: pageNo,
            pageSize: perPage,
            search,
            sort: sortBy,
            status: "pending",
          })
        );
      }
    } catch (error: any) {
      dispatch(setLoading(false));
      dispatch(
        notificationFail(
          error?.response?.data?.message || Messages.ERROR.SOMETHING_WENT_WRONG || error.message
        )
      );
    }
  }
);

// delete webpage
export const deleteWebpageRecord = createAsyncThunk(
  "deleteWebpageRecord",
  async (_request: any, { dispatch }) => {
    const page = _request.pageIndex || 0;
    const size = _request.pageSize || 50;
    const search = _request.search || "";
    const sortBy = _request.sort || [];

    dispatch(setLoading(true));
    try {
      const response = await platformApiClient().delete(
        `delete-web-page/${_request?.connect_webpage_id}`
      );
      if (response) {
        _request?.callBack();
        dispatch(notificationSuccess(Messages.SUCCESS.WEBPAGE_DELETE_SUCCESS));
        dispatch(
          getWebPagesList({
            pageIndex: page,
            pageSize: size,
            search,
            sort: sortBy,
          })
        );
      }
    } catch (error: any) {
      dispatch(setLoading(false));
      dispatch(
        notificationFail(
          error?.response?.data?.message || Messages.ERROR.SOMETHING_WENT_WRONG || error.message
        )
      );
    }
  }
);

// delete update webpage requests
export const deleteUpdateWebpageRequests = createAsyncThunk(
  "deleteUpdateWebpageRequests",
  async (_request: any, { dispatch }) => {
    const page = _request.pageIndex || 0;
    const size = _request.pageSize || 50;
    const search = _request.search || "";
    const sortBy = _request.sort || [];

    dispatch(setLoading(true));
    try {
      const response = await platformApiClient().delete(
        `delete-update-web-page-request/${_request?.webpage_request_id}`
      );
      if (response) {
        _request?.callBack();
        dispatch(notificationSuccess(Messages.SUCCESS.WEBPAGE_REQUEST_DELETE_SUCCESS));
        dispatch(
          updateWebPagesListing({
            pageIndex: page,
            pageSize: size,
            search,
            sort: sortBy,
            status: "pending",
          })
        );
      }
    } catch (error: any) {
      dispatch(setLoading(false));
      dispatch(
        notificationFail(
          error?.response?.data?.message || Messages.ERROR.SOMETHING_WENT_WRONG || error.message
        )
      );
    }
  }
);

// update location images order
export const updateWebpageImageOrder = createAsyncThunk(
  "updateWebpageImageOrder",
  async (_request: any, { dispatch }) => {
    dispatch(setLoading(true));
    try {
      const { id, setUpdateImageOrder } = _request;
      if (_request?.setUpdateImageOrder) {
        // eslint-disable-next-line no-param-reassign
        delete _request.setUpdateImageOrder;
      }
      const response = await platformApiClient().post(`reshuffle-image-order`, _request);

      if (response?.data?.message) {
        dispatch(notificationSuccess(response?.data?.message));
        dispatch(getWebPageDetails({ id, platform: "web" }));
      } else {
        dispatch(setLoading(false));
        dispatch(notificationFail(response?.data?.message || Messages.ERROR.SOMETHING_WENT_WRONG));
      }
      if (setUpdateImageOrder) {
        setUpdateImageOrder(false);
      }
    } catch (error: any) {
      dispatch(notificationFail(error.message || Messages.ERROR.SOMETHING_WENT_WRONG));
      dispatch(setLoading(false));
    }
  }
);
// assign webpage to user
export const assignWebpageToUser = createAsyncThunk(
  "assignWebpageToUser",
  async (_request: any, { dispatch }) => {
    dispatch(setLoading(true));
    try {
      const response = await platformApiClient().post(`assign-webpage-to-user`, {
        connect_webpage_ids: _request.connect_webpage_ids || [],
        email: _request.email || "",
        location_id: _request.location_id || 0,
      });

      if (response) {
        dispatch(
          getWebpageByLocationId({
            locationId: _request.location_id || 0,
            email: _request.email || "",
          })
        );
        _request?.callBack();
        if (response?.data?.status) {
          dispatch(notificationSuccess(Messages.SUCCESS.ASSIGN_WEBPAGES_TO_USER));
          dispatch(setLoading(false));
        } else {
          dispatch(setLoading(false));
          dispatch(
            notificationFail(response?.data?.message || Messages.ERROR.SOMETHING_WENT_WRONG)
          );
        }
      }
    } catch (error: any) {
      dispatch(setLoading(false));
      dispatch(
        notificationFail(
          error?.response?.data?.message || error.message || Messages.ERROR.SOMETHING_WENT_WRONG
        )
      );
    }
  }
);
// get billing tab detail by stylist id
export const createSolaProInCognito = createAsyncThunk(
  "createSolaProInCognito",
  async (_request: any, { dispatch }) => {
    dispatch(setLoading(true));
    try {
      const { solaProDetail } = _request;
      const webPageIds = solaProDetail?.webpage?.map((item: any) => item.id);
      const formdata = new FormData();
      formdata.append("f_name", getValue(solaProDetail?.f_name));
      formdata.append("m_name", getValue(solaProDetail?.m_name));
      formdata.append("l_name", getValue(solaProDetail?.l_name));
      // formdata.append("url_name", getValue(solaProDetail?.url_name));
      formdata.append("status", getValue(solaProDetail?.status));
      formdata.append("inactive_reason", getValue(solaProDetail?.inactive_reason));
      formdata.append("phone_number", getValue(solaProDetail?.phone_number));
      formdata.append("email_address", getValue(solaProDetail?.email_address));
      formdata.append("location_id", getValue(solaProDetail?.location?.id));
      formdata.append("location_name", getValue(solaProDetail?.location?.name));
      formdata.append("home_address_1", getValue(solaProDetail?.home_address_1));
      formdata.append("home_address_2", getValue(solaProDetail?.home_address_2));
      formdata.append("home_city", getValue(solaProDetail?.home_city));
      formdata.append("home_state", getValue(solaProDetail?.home_state));
      formdata.append("home_postal_code", getValue(solaProDetail?.home_postal_code));
      formdata.append("country", getValue(solaProDetail?.country));
      formdata.append("billing_first_name", getValue(solaProDetail?.billing_first_name));
      formdata.append("billing_last_name", getValue(solaProDetail?.billing_last_name));
      formdata.append("billing_email", getValue(solaProDetail?.billing_email));
      formdata.append("billing_phone", getValue(solaProDetail?.billing_phone));
      formdata.append("location_id", getValue(solaProDetail?.location?.id));
      formdata.append("create_webpage", getValue(solaProDetail?.create_webpage));
      if (webPageIds && webPageIds.length > 0) {
        formdata.append("webpage_ids", JSON.stringify(webPageIds));
      }
      if (solaProDetail?.services && solaProDetail.services.length > 0) {
        formdata.append("services", solaProDetail.services.join(","));
      }
      if (solaProDetail?.other_service) {
        const replaceStr = solaProDetail?.other_service.trim().replace(/,*$/, "");
        formdata.append("other_service", getValue(replaceStr)); // SD-3065 changes
      }

      const response = await slsApiClient(true).post(`create-stylists`, formdata);
      if (response) {
        _request.callback();
        dispatch(setLoading(false));
        dispatch(
          notificationSuccess(
            response?.data?.message || Messages.SUCCESS.SOLA_PROFESSIONAL_CREATE_SUCCESS
          )
        );
      }
    } catch (error: any) {
      dispatch(setLoading(false));
      dispatch(
        notificationFail(
          error?.response?.data?.message || error.message || Messages.ERROR.NO_DATA_FOUND
        )
      );
    }
  }
);

// get new solapro detail by id
export const getNewSolaProsDetail = createAsyncThunk(
  "getNewSolaProsDetail",
  async (_request: any, { dispatch }) => {
    dispatch(setLoading(true));
    try {
      const response = await slsApiClient().get(`get-solapro-detail`, {
        params: {
          stylist_id: _request?.id,
        },
      });
      if (response) {
        if (response?.data?.data?.id) {
          dispatch(setNewSolaProDetails(response?.data?.data || null));
        } else {
          dispatch(setLoading(false));
        }
      }
      dispatch(setLoading(false));
    } catch (error: any) {
      dispatch(setLoading(false));
      dispatch(
        notificationFail(
          error?.response?.data?.message || error.message || Messages.ERROR.NO_DATA_FOUND
        )
      );
    }
  }
);

// Update sola pro deatils cognito
export const updateSolaProInCognito = createAsyncThunk(
  "updateSolaProInCognito",
  async (_request: any, { dispatch }) => {
    dispatch(setLoading(true));
    try {
      const { solaProDetail, isUpdate } = _request;
      const loginUser = getAuthUser();
      const webPageIds = solaProDetail?.webpage?.map((item: any) => item.id);
      const serviceNames = solaProDetail?.services
        ?.filter((item: any) => item.isActive)
        ?.map((item: any) => item.newLabel);
      const formdata = new FormData();
      if (isUpdate && solaProDetail?.id) {
        formdata.append("stylist_id", getValue(solaProDetail?.id));
        formdata.append("current_user", loginUser?.user_id);
      }
      formdata.append("f_name", getValue(solaProDetail?.f_name));
      formdata.append("m_name", getValue(solaProDetail?.m_name));
      formdata.append("l_name", getValue(solaProDetail?.l_name));
      // formdata.append("url_name", getValue(solaProDetail?.url_name));
      formdata.append("status", getValue(solaProDetail?.status));
      formdata.append("inactive_reason", getValue(solaProDetail?.inactive_reason));
      formdata.append("phone_number", getValue(solaProDetail?.phone_number));
      formdata.append("email_address", getValue(solaProDetail?.email_address));
      formdata.append("location_id", getValue(solaProDetail?.location?.id));
      formdata.append("location_name", getValue(solaProDetail?.location?.name));
      formdata.append("home_address_1", getValue(solaProDetail?.home_address_1));
      formdata.append("home_address_2", getValue(solaProDetail?.home_address_2));
      formdata.append("home_city", getValue(solaProDetail?.home_city));
      formdata.append("home_state", getValue(solaProDetail?.home_state));
      formdata.append("home_postal_code", getValue(solaProDetail?.home_postal_code));
      formdata.append("country", getValue(solaProDetail?.country));
      formdata.append("billing_first_name", getValue(solaProDetail?.billing_first_name));
      formdata.append("billing_last_name", getValue(solaProDetail?.billing_last_name));
      formdata.append("billing_email", getValue(solaProDetail?.billing_email));
      formdata.append("billing_phone", getValue(solaProDetail?.billing_phone));
      if (webPageIds && webPageIds.length > 0) {
        formdata.append("webpage_ids", JSON.stringify(webPageIds));
      }
      if (serviceNames && serviceNames.length > 0) {
        formdata.append("services", serviceNames.join(","));
      }
      if (solaProDetail?.other_service) {
        const replaceStr = solaProDetail?.other_service.trim().replace(/,*$/, "");
        formdata.append("other_service", getValue(replaceStr)); // SD-3065 changes
      }
      // SD-3077 Banner changes
      formdata.append("banner", getValue(solaProDetail?.banner_image_url));
      formdata.append("delete_banner_image", getValue(solaProDetail?.delete_banner_image));

      const response = await slsApiClient(true).put(`update-stylists`, formdata);
      if (response) {
        if (response?.status) {
          dispatch(getNewSolaProsDetail({ id: solaProDetail?.id }));
          // dispatch(setNewSolaProDetails(response?.data?.data || null));
          dispatch(
            notificationSuccess(
              response?.data?.message || Messages.SUCCESS.SOLA_PROFESSIONAL_UPDATE_SUCCESS
            )
          );
        } else {
          dispatch(setLoading(false));
        }
      }
      dispatch(setLoading(false));
    } catch (error: any) {
      dispatch(setLoading(false));
      dispatch(
        notificationFail(
          error?.response?.data?.message || error.message || Messages.ERROR.NO_DATA_FOUND
        )
      );
    }
  }
);
