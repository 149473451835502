import { Checkbox, FormControl, FormControlLabel, FormGroup, Paper } from "@mui/material";
import Grid from "@mui/material/Grid";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import CustomErrorMessage from "components/CustomErrorMessage";

function Services(props: any): JSX.Element {
  const { accountDetail, onChangeCheckBoxes, errors, serviceList } = props;
  return (
    <Paper>
      <MDBox p={2} mt={2}>
        <MDBox mb={1}>
          <MDTypography variant="h5" fontWeight="regular">
            Services
          </MDTypography>
        </MDBox>
        <MDBox>
          <Grid container spacing={3}>
            <Grid item xs={12} md={8}>
              {serviceList.map((item: any) => (
                <FormControl key={`${item.id}control`} component="fieldset">
                  <FormGroup key={`${item.id}group`} aria-label="position" row>
                    <FormControlLabel
                      key={`${item.id}label`}
                      value="end"
                      control={
                        <Checkbox
                          checked={
                            (accountDetail?.services &&
                              accountDetail?.services.includes(item.id.toString())) ||
                            false
                          }
                          name="services"
                          id={item.id.toString()}
                          key={item.id}
                          onChange={onChangeCheckBoxes}
                          inputProps={{ "aria-label": "controlled" }}
                        />
                      }
                      label={item.name}
                      labelPlacement="end"
                    />
                  </FormGroup>
                </FormControl>
              ))}
              {errors && errors.services && (
                <CustomErrorMessage message={errors && errors.services} />
              )}
            </Grid>
          </Grid>
        </MDBox>
      </MDBox>
    </Paper>
  );
}

export default Services;
