import MDDialog from "components/MDDialog";
import { Grid } from "@mui/material";

function viewDetails(props: any): JSX.Element {
  const { open, onClose, updateData } = props;

  const dialogProps = {
    open,
    onClose,
    saveBtn: false,
    title: "View Lease Agreement",
    size: "lg",
  };

  return (
    <MDDialog {...dialogProps}>
      <Grid container spacing={2}>
        <Grid item xs={12} md={12} pr={2} />
        <iframe
          src={updateData?.agreementURL}
          title="Lease Agreement"
          width="100%"
          height="500"
          style={{ marginLeft: "15px" }}
        />
      </Grid>
    </MDDialog>
  );
}

export default viewDetails;
