import { useState, useCallback, useRef, useEffect, useLayoutEffect } from "react";
import { Card, Checkbox } from "@mui/material";
import MDBox from "components/MDBox";
import DataTable from "examples/Tables/DataTable";
import { useAppDispatch, useAppSelector } from "store/store";
import { getLeadTrackingReport } from "store/thunk/systemAdmin";
import exportData from "helper/exportTableConst";
import {
  removeExportFields,
  setDefaultSelectedColumns,
  adminSuperAdmin,
  getAssociationLocations,
  getLocalDateTime,
} from "helper/services";
import { getDefaultCoulmns, saveSelectedAllData } from "store/thunk/connectUserThunk";
import { setDefaultsColumns } from "store/slices/connectUsersSlice";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";

function Index(): JSX.Element {
  const dispatch = useAppDispatch();
  const { defaultSelectedColumns } = useAppSelector((state) => state.connectUsersReducer);
  const { userData } = useAppSelector((state) => state.authReducer);
  const { analyticsList } = useAppSelector((state) => state.systemAdminSlice);
  const [selectedItems, setSelectedItems] = useState([]);
  const [pageCount, setPageCount] = useState(0);
  const [pageNo, setPageNo] = useState(0);
  const [perPage, setPageSize] = useState(10);
  const [search, setSearch] = useState<any>(null);
  const [isCheckAll, setIsCheckAll] = useState(false);
  const [totalCount, setTotalCount] = useState(0);
  const fetchIdRef = useRef(0);
  const [displayColumns, setDisplayColumns] = useState<any>([]);
  const [defaultExport, setDefaultExport] = useState<any>([]);
  const [callFetch, setCallFetch] = useState<any>(false);
  const [defaultSorting, setDefaultSorting] = useState<any>([]);
  const [defaultFilteing, setDefaultFilteing] = useState<any>([]);
  const [reportList, setReportList] = useState([]);

  const getColumns = () => {
    const columns: any = [
      {
        Header: "",
        disableSortBy: true,
        accessor: "checkbox",
        export: false,
        width: "50px",
        checked: true,
      },
      {
        Header: "Location",
        accessor: "location",
        width: "200px",
        export: true,
        associative_colums: "locations.name",
        checked: true,
        disableCheck: true,
      },
      {
        Header: "Services",
        accessor: "services",
        width: "200px",
        export: true,
        checked: true,
      },
      {
        Header: "UTM parameteres",
        accessor: "utm_parameters",
        width: "200px",
        export: true,
        checked: true,
      },
      {
        Header: "Created At",
        accessor: "created_at",
        width: "200px",
        export: true,
        is_date: true,
        checked: true,
      },
    ];
    return columns;
  };
  const [checkedColumns, setCheckedColumns] = useState<any>(getColumns());
  const latestRefValue = useRef(checkedColumns);

  useEffect(() => {
    latestRefValue.current = checkedColumns;
  }, [checkedColumns]);

  const fetchData = useCallback(({ pageSize, pageIndex, search, sortBy, filterData, isManual }) => {
    const fetchId = ++fetchIdRef.current;
    const sort: any = sortBy || [];
    // We'll even set a delay to simulate a server here
    setTimeout(() => {
      if (fetchId === fetchIdRef.current) {
        const sentReq = {
          sendData: {
            user_id: userData?.user_id,
            table_name: exportData.connect_lead_tracking.table_name,
            table_columns: latestRefValue.current ? latestRefValue.current : checkedColumns, // Changes in checkedColumns
            table_sort: sortBy && sortBy?.length ? sortBy : [],
            table_filter: filterData && filterData?.length ? filterData : [],
            default_export_data: defaultExport,
          },
        };
        if (isManual) {
          dispatch(saveSelectedAllData(sentReq));
        }
        setPageNo(pageIndex);
        setSearch(search);
        setPageSize(pageSize);
        setIsCheckAll(false);
        dispatch(
          getLeadTrackingReport({ pageSize, pageIndex, search, sort, userData, filterData })
        );
      }
    }, 1000);
  }, []);

  // useLayoutEffect for get lateset Updated record
  useLayoutEffect(() => {
    dispatch(setDefaultsColumns([]));
    // Set data in variable and Call defaultColums Thunk
    const sentReq = {
      user_id: userData?.user_id,
      table_name: exportData.connect_lead_tracking.table_name,
    };
    dispatch(getDefaultCoulmns(sentReq)).then((resp: any) => {
      // SD-3199
      if (resp?.payload) {
        const respFilter = resp?.payload?.table_filter || [];
        const respSorting = resp?.payload?.table_sort || [];
        const defaultDBexportData = resp?.payload?.default_export_data || [];
        const defaultDBColumn = resp?.payload?.table_columns || [];
        const tempdata = setDefaultSelectedColumns(defaultDBColumn, getColumns());
        setCheckedColumns(tempdata);
        setDefaultExport(defaultDBexportData);
        setDefaultSorting(respSorting);
        setDefaultFilteing(respFilter);
        setCallFetch(true);
        fetchData({
          perPage,
          pageNo,
          search,
          sortBy: respSorting && respSorting?.length ? respSorting : [],
          additionalField: null,
          filterData: respFilter && respFilter?.length ? respFilter : [],
        });
      }
    });
  }, []);

  useEffect(() => {
    if (analyticsList && analyticsList?.lead_tracking_report) {
      setReportList(analyticsList?.lead_tracking_report);
      setPageCount(analyticsList?.meta?.total_pages);
      setTotalCount(analyticsList?.meta?.total_count);
    }
  }, [analyticsList]);

  useEffect(() => {
    if (defaultSelectedColumns && defaultSelectedColumns?.length) {
      const newColumns = setDefaultSelectedColumns(defaultSelectedColumns, getColumns());
      setCheckedColumns(newColumns);
    } else {
      setCheckedColumns(getColumns());
    }
  }, [defaultSelectedColumns]);

  useEffect(() => {
    if (checkedColumns?.length) {
      setDisplayColumns(checkedColumns.filter((col: any) => col.checked));
    }
  }, [checkedColumns]);

  const onChangeCheckBox = (e: any) => {
    let items = JSON.parse(JSON.stringify(selectedItems));
    if (e.target.checked && !items.includes(e.target.value)) {
      items.push(e.target.value);
      if (items.length === reportList.length) {
        setIsCheckAll(true);
      }
    } else {
      items = items.filter((id: any) => id !== e.target.value);
      setIsCheckAll(false);
    }
    setSelectedItems(items);
  };

  const checkUncheckAll = (e: any) => {
    let allIds: any = [];
    allIds = reportList.map((val: any) => String(val?.id));
    if (e.target.checked) {
      const selectedIds = [...new Set([...selectedItems, ...allIds])];
      setSelectedItems(selectedIds);
      setIsCheckAll(true);
    } else {
      allIds = reportList.map((val: any) => String(val?.id));
      allIds = selectedItems.filter((el: any) => !allIds.includes(el));
      setSelectedItems([...allIds]);
      setIsCheckAll(false);
    }
  };

  const getRows = () =>
    reportList &&
    reportList.map((obj: any) => ({
      checkbox: (
        <Checkbox
          checked={selectedItems.includes(obj.id) || selectedItems.includes(obj.id.toString())}
          onChange={(e: any) => onChangeCheckBox(e)}
          value={obj.id}
        />
      ),
      id: obj?.id || "-",
      location: obj?.location?.name || "-",
      services: obj?.services || "-",
      utm_parameters: obj?.utm_parameters || "-",
      created_at: obj.created_at ? getLocalDateTime(obj.created_at) : "-",
    }));

  const tableData = {
    columns: getColumns(),
    rows: getRows(),
  };

  const clearCheckItem = () => {
    setSelectedItems([]);
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={4} pb={3}>
        <Card>
          <MDBox
            bgColor="dark"
            color="white"
            coloredShadow="dark"
            borderRadius="xl"
            alignItems="center"
            justifyContent="space-between"
            pt={1}
            pb={1}
            pl={2}
            pr={2}
            ml={2}
            mr={2}
            display="flex"
            mt={-3}
            className="page-header"
          >
            <p className="page-header-label">Analytics</p>
          </MDBox>
          {(defaultSorting || defaultSorting?.length) &&
            callFetch && ( // SD-3199
              <DataTable
                defaultSortingColumns={defaultSorting} // SD-3199
                defaultFilteringColums={defaultFilteing} // SD-3199
                defaultDisplayColumns={displayColumns}
                setCheckedColumns={setCheckedColumns}
                table={tableData}
                fetchData={fetchData}
                canSearch
                searchText="Search..."
                selectedItems={selectedItems}
                pageNo={pageNo}
                setPageNo={setPageNo}
                pageCount={pageCount}
                totalCount={totalCount}
                showExportSelectedBtn
                showExportAllBtn
                exportFields={removeExportFields(exportData.connect_lead_tracking.export_fields)}
                exportTable={exportData.connect_lead_tracking.table_name}
                exportType={exportData.connect_lead_tracking.export_type}
                joinTables={exportData.connect_lead_tracking.join_tables}
                manualSearch
                manualSort
                isDisplayCheckAllCheckbox
                onChangeCheckAllCheckbox={checkUncheckAll}
                isCheckAll={isCheckAll}
                checkedColumns={checkedColumns}
                clearCheckItem={clearCheckItem}
                advanceFilter
                locationsIds={!adminSuperAdmin() && getAssociationLocations()}
              />
            )}
        </Card>
      </MDBox>
    </DashboardLayout>
  );
}

export default Index;
