import { useEffect, useState } from "react";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import MDBox from "components/MDBox";
import { getAuthUser } from "helper/services";
import config from "config/config";
import axios from "axios";
import { getCmsLocations } from "store/thunk/locationThunk";
import ReportsLineChart from "examples/Charts/LineCharts/ReportsLineChart";
import {
  convertToFirstDayOfMonth,
  convertToLastDayOfMonth,
  fetchCurrentDate,
  generateChartLabelsAndData,
  getDateRange,
  getFirstDateOfCurrentMonth,
  getYears,
  leadsAttributionCSV,
  scoercardDateRange,
  generateLocationData,
  generateLeadDensityData,
  leadsVolumeCSV,
  leadsFormatted,
  leadsIntentCSV,
  getleadsIntent,
} from "helper/reports";
import { useAppDispatch, useAppSelector } from "store/store";
import { notificationFail, notificationSuccess } from "store/slices/notificationSlice";
import Messages from "helper/messages";
import { Card, Grid } from "@mui/material";
import CustomLoader from "components/CustomLoader";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import ScorecardDateInput from "../scorecard-dashboard/components/scorecardDateInput";
import LeadsData from "./components/leadsData";
import SourceType from "./components/sourceType/sourceType";
import SourceTypeDetailed from "./components/sourceType/sourceTypeDetailed";
import MaturedLocationLeads from "./components/maturedLeads/maturedLeads";
import MaturedLeadsDetailed from "./components/maturedLeads/maturedLeadsDetailed";
import LeadsAttribution from "./components/leadsAttribution/leadsAttribution";
import AttributionLeadsDetailed from "./components/leadsAttribution/leadsAttributionDetailed";
import LeadsB2B from "./components/leadsB2B/leadsB2B";
import LeadsB2BDetailed from "./components/leadsB2B/leadB2BDetailed";
import LeadsB2C from "./components/leadsB2C/leadsB2C";
import LeadsB2CDetailed from "./components/leadsB2C/leadsB2CDetailed";
import LeadsDensity from "./components/leadsDensity/leadsDensity";
import LeadsDensityDetailed from "./components/leadsDensity/leadsDensityDetailed";
import LeadsIntent from "./components/leadsIntent/leadsIntent";
import LeadsIntentDetailed from "./components/leadsIntent/leadsIntentDetailed";
import LeadsVolumeB2B from "./components/leadsVolume/leadsVolumeB2B";
import LeadsVolumeB2C from "./components/leadsVolume/leadsVolumeB2C";
import LeadsVolumeB2CDetailed from "./components/leadsVolume/leadsVolumeB2CDetailed";
import LeadsVolumeB2BDetailed from "./components/leadsVolume/leadsVolumeB2BDetails";

export interface SelectedDate {
  value: string;
}
export interface SelectedDateRange {
  startMonth: string;
  endMonth: string;
}

export interface LeadsDataProps {
  location_id: number;
  name: string;
  leads: string;
}
export interface LeadAvailableLocation {
  id: string;
  name: string;
}

export interface SourceDataProps {
  original_source_type: string;
  count: string;
}
export interface SourceTypeProps {
  current_year: SourceDataProps[];
  last_year: SourceDataProps[];
}
export interface MaturedLocationYearData {
  location_id: number;
  name: string;
  leads: string;
  open_date: string;
}

export interface MaturedLocationResult {
  current_year: MaturedLocationYearData[];
  last_year: MaturedLocationYearData[];
}
export interface MaturedAndUnmaturedLocation {
  maturedLocations: number[];
  unmaturedLocations: number[];
}

export interface LeadsAttributionData {
  current_year: {
    [key: string]: string;
  }[];
  last_year: {
    [key: string]: string;
  }[];
}
export interface CurrentAndLastYearRange {
  start: number;
  end: number;
}
export interface LeadsDensityData {
  current_year: LeadsDataProps[];
  last_year: LeadsDataProps[];
}
export interface FormattedType {
  [key: string]: [number, number];
}
export interface Year {
  value: string;
}
export interface LeadsVolumeProps {
  form_type: string;
  count: string;
}
export interface LeadsVolumeDataProps {
  current_year: LeadsVolumeProps[];
  last_year: LeadsVolumeProps[];
}
export interface LeadsIntentProps {
  intent_category: string;
  count: string;
}
export interface LeadsIntentDataProps {
  current_year: LeadsIntentProps[];
  last_year: LeadsIntentProps[];
}

function LeadsDashboard() {
  const userDetails = getAuthUser();
  const { cmsLocations } = useAppSelector((state) => state.locationSlice);
  const [visiblity, setVisiblity] = useState<boolean>(false);
  const [selectedDate, setSelectedDate] = useState<SelectedDate>();
  const [selectedDateRange, setSelectedDateRange] = useState<SelectedDateRange>({
    startMonth: "",
    endMonth: "",
  });
  const [isLoading, setIsLoading] = useState<Boolean>(true);
  const [loader, setLoader] = useState<boolean>(true);
  const dispatch = useAppDispatch();
  const [currentDateCheckBox, setCurrentDateCheckBox] = useState<boolean>(true);
  const [selectedlead, setselectedlead] = useState<LeadsDensityData>();
  const [leadsCurrentYearRange, setLeadsCurrentYearRange] = useState<CurrentAndLastYearRange>();
  const [leadsLastYearRange, setLeadsLastYearRange] = useState<CurrentAndLastYearRange>();
  const [title, setTitle] = useState<string>("");
  const [sourceVisiblity, setSourceVisiblity] = useState<boolean>(false);
  const [selectedSourceType, setSelectedSourceType] = useState<SourceTypeProps>();
  const [maturedLocation, setMaturedLocation] = useState<MaturedLocationResult>();
  const [totalMaturedAndUnmaturedLocation, setTotalMaturedAndUnmaturedLocation] =
    useState<MaturedAndUnmaturedLocation>();
  const [maturedUnmaturedLeadsVisibility, setMaturedUnmaturedLeadsVisibility] = useState<boolean>();
  const [attributionVisibiltiy, setAttributionVisibiltiy] = useState<boolean>(false);
  const [leadsAttributionData, setLeadsAttributionData] = useState<LeadsAttributionData>();
  const [leadsB2BData, setLeadsB2BData] = useState<LeadsAttributionData>();
  const [leadsB2CData, setLeadsB2CData] = useState<LeadsAttributionData>();
  const [leadB2BVisbility, setLeadsB2BVisibility] = useState<boolean>(false);
  const [leadB2CVisbility, setLeadsB2CVisibility] = useState<boolean>(false);
  const [leadsDensity, setLeadsDensity] = useState<LeadsDensityData>();
  const [leadDensityVisbility, setLeadsDensityVisibility] = useState<boolean>(false);
  const [leadsVolumeB2BVisbility, setLeadsVolumeB2BVisibility] = useState<boolean>(false);
  const [leadsVolumeB2CVisbility, setLeadsVolumeB2CVisibility] = useState<boolean>(false);
  const [leadsVolumeB2B, setLeadsVolumeB2B] = useState<LeadsVolumeDataProps>();
  const [leadsVolumeB2C, setLeadsVolumeB2C] = useState<LeadsVolumeDataProps>();
  const [leadsIntent, setLeadsIntent] = useState<LeadsIntentDataProps>();
  const [leadsIntentVisbility, setLeadsIntentVisibility] = useState<boolean>(false);
  const [year, setYear] = useState<string[]>(getYears(selectedDateRange, currentDateCheckBox));

  useEffect(() => {
    const yearArray = getYears(selectedDateRange, currentDateCheckBox);
    setYear(yearArray);
  }, [selectedDateRange, currentDateCheckBox]);
  const onChangeDateField = (event: any) => {
    const date = scoercardDateRange.find((date) => date.value === event.target.value);
    const [startDate, endDate] = getDateRange(date.value);
    setSelectedDate(date);
    setSelectedDateRange({
      startMonth: startDate,
      endMonth: endDate,
    });
  };
  const fetchLeads = async (locationsData: LeadAvailableLocation[]) => {
    try {
      if (selectedDateRange.startMonth > selectedDateRange.endMonth) {
        throw new Error("Start month should be lower than end month");
      } else {
        const ids = locationsData?.map((location) => location.id);
        const acceptedRoles = ["Admin", "System Admin"];
        setLoader(true);
        let url = "";
        if (
          selectedDate &&
          (acceptedRoles.includes(userDetails.role) || !acceptedRoles.includes(userDetails.role))
        ) {
          const startDate = currentDateCheckBox
            ? getFirstDateOfCurrentMonth()
            : convertToFirstDayOfMonth(selectedDateRange.startMonth);
          const endDate = currentDateCheckBox
            ? fetchCurrentDate()
            : convertToLastDayOfMonth(selectedDateRange.endMonth);
          url = `${config.REPORT_URL}/report/fetchLeadsData?startDate=${startDate
            .toString()
            .slice(0, 10)}&endDate=${endDate.toString().slice(0, 10)}&locations=${
            userDetails.role === "Admin" || userDetails.role === "System Admin"
              ? "All"
              : `'${ids?.join(", ")}'`
          }`;
          axios
            .get(url, {
              responseType: "json",
            })
            .then((res: any) => {
              setselectedlead(res.data.result);
              dispatch(
                notificationSuccess(res.data.message || Messages.SUCCESS.MASTER_DATA_CREATED)
              );
            })
            .catch((err) => {
              dispatch(
                notificationFail(
                  err?.response?.data?.message || Messages.ERROR.SOMETHING_WENT_WRONG
                )
              );
            })
            .finally(() => {
              setIsLoading(false);
              setLoader(false);
            });
        }
      }
    } catch (e) {
      dispatch(notificationFail(e.message || Messages.ERROR.SOMETHING_WENT_WRONG));
    }
  };

  useEffect(() => {
    const pageSize = 50;
    const pageIndex = 0;
    const search: any = undefined;
    const sort: any[] = [];
    const filterData: any[] = [];
    dispatch(getCmsLocations({ pageSize, pageIndex, search, sort, filterData }));
    setSelectedDate(scoercardDateRange[0]);
    const [startDate, endDate] = getDateRange(scoercardDateRange[0].value);
    setSelectedDateRange({
      startMonth: startDate,
      endMonth: endDate,
    });
  }, []);

  useEffect(() => {
    const locations = cmsLocations.locations?.map((item: any) => ({
      location_id: item.id.toString(),
      location: item.name,
    }));
    fetchLeads(locations);
  }, [cmsLocations, selectedDateRange, currentDateCheckBox]);

  const downloadMultiTableCSV = () => {
    const locationData = generateLocationData(maturedLocation);
    const denistyData = generateLeadDensityData(leadsDensity);
    const leadsVolumeB2BData = leadsFormatted(leadsVolumeB2B);
    const leadsVolumeB2CData = leadsFormatted(leadsVolumeB2C);
    const leadsAttribution = leadsFormatted(leadsAttributionData);
    const leadsIntentData = leadsFormatted(leadsIntent);
    const startDate = currentDateCheckBox
      ? getFirstDateOfCurrentMonth()
      : convertToFirstDayOfMonth(selectedDateRange.startMonth);
    const endDate = currentDateCheckBox
      ? fetchCurrentDate()
      : convertToLastDayOfMonth(selectedDateRange.endMonth);
    const leadsIntentFormatted = getleadsIntent(leadsIntent, "intent_b2b");
    const leadAttributionCurrentYear =
      (leadsAttribution.current_year / leadsVolumeB2BData.current_year) * 100;
    const leadsAttributionLastYear =
      (leadsAttribution.last_year / leadsVolumeB2BData.last_year) * 100;
    const leadsAttributionPercentage =
      ((leadAttributionCurrentYear - leadsAttributionLastYear) / leadsAttributionLastYear) * 100;
    const leadsCaptureCurrentYear =
      (leadsIntentData.current_year / leadsVolumeB2BData.current_year) * 100;
    const leadsCaptureLastYear = (leadsIntentData.last_year / leadsVolumeB2BData.last_year) * 100;
    const leadsCapturePercentage =
      ((leadsCaptureCurrentYear - leadsCaptureLastYear) / leadsCaptureLastYear) * 100;
    const leadsB2BIntentCurrentYear =
      (leadsIntentFormatted.currentYear / leadsIntentData.current_year) * 100;
    const leadsB2BIntentLastYear =
      (leadsIntentFormatted.lastYear / leadsIntentData.last_year) * 100;
    const leadsB2BIntnetPercentage =
      ((leadsB2BIntentCurrentYear - leadsB2BIntentLastYear) / leadsB2BIntentLastYear) * 100;
    const intentB2BLeadsCurrentYear =
      (leadsIntentFormatted.currentYear / leadsVolumeB2BData.current_year) * 100;
    const intentB2BLeadsLastYear =
      (leadsIntentFormatted.lastYear / leadsVolumeB2BData.last_year) * 100;
    const intentB2BLeadsPercentage =
      ((intentB2BLeadsCurrentYear - intentB2BLeadsLastYear) / intentB2BLeadsLastYear) * 100;
    const errorLeadsCapturedCurrentYear =
      ((leadsVolumeB2BData.current_year * (1 - leadsB2BIntentCurrentYear)) /
        leadsVolumeB2BData.current_year) *
      100;
    const errorLeadsCapturedLastYear =
      ((leadsVolumeB2BData.last_year * (1 - leadsB2BIntentLastYear)) /
        leadsVolumeB2BData.last_year) *
      100;
    const previousYear = Number(endDate.split("-")[0]) - 1;
    const tableData = [
      ["Year", endDate.split("-")[0], previousYear.toString()],
      [],
      ["Location Type"],
      [
        "Mature Stores",
        locationData.matureStoresCountCurrentYear,
        locationData.matureStoresCountLastYear,
      ],
      [
        "New Stores",
        locationData.unmatureStoresCountCurrentYear,
        locationData.unmatureStoresCountLastYear,
      ],
      [
        "Total Open Stores",
        locationData.totalOpenStoresCountCurrentYear,
        locationData.totalOpenStoresCountLastYear,
      ],
      [],
      ["LEAD Volume(B2B)"],
      ...Object.keys(leadsVolumeCSV).map((key) => {
        const currentYearEntry = leadsVolumeB2B.current_year.find(
          (entry: any) => entry.form_type === key
        ) || { count: 0 };
        const lastYearEntry = leadsVolumeB2B.last_year.find(
          (entry: any) => entry.form_type === key
        ) || { count: 0 };
        const percentageDenominator = parseInt(lastYearEntry.count.toString(), 10);
        const percentage =
          percentageDenominator !== 0
            ? ((parseInt(currentYearEntry.count.toString(), 10) - percentageDenominator) /
                percentageDenominator) *
              100
            : "N/A";

        return [
          (leadsVolumeCSV as any)[key],
          currentYearEntry.count,
          lastYearEntry.count,
          percentage !== "N/A" ? `${(percentage as number).toFixed(2)}%` : "N/A",
        ];
      }),
      [
        "Leads Captured (No Test Loc)",
        leadsVolumeB2BData.current_year,
        leadsVolumeB2BData.last_year,
        leadsVolumeB2BData.percentage,
      ],
      [
        "Leads per Store",
        (locationData.totalOpenStoresCountCurrentYear / leadsVolumeB2BData.current_year).toFixed(2),
        (locationData.totalOpenStoresCountLastYear / leadsVolumeB2BData.last_year).toFixed(2),
      ],
      [],
      ["LEAD Volume(B2C)"],
      ...Object.keys(leadsVolumeCSV).map((key) => {
        const currentYearEntry = leadsVolumeB2C.current_year.find(
          (entry: any) => entry.form_type === key
        ) || { count: 0 };
        const lastYearEntry = leadsVolumeB2C.last_year.find(
          (entry: any) => entry.form_type === key
        ) || { count: 0 };
        const percentageDenominator = parseInt(lastYearEntry.count.toString(), 10);
        const percentage =
          percentageDenominator !== 0
            ? ((parseInt(currentYearEntry.count.toString(), 10) - percentageDenominator) /
                percentageDenominator) *
              100
            : "N/A";
        return [
          (leadsVolumeCSV as any)[key],
          currentYearEntry.count,
          lastYearEntry.count,
          percentage !== "N/A" ? `${(percentage as number).toFixed(2)}%` : "N/A",
        ];
      }),
      [
        "Leads Captured (No Test Loc)",
        leadsVolumeB2CData.current_year,
        leadsVolumeB2CData.last_year,
        leadsVolumeB2CData.percentage,
      ],
      [
        "Leads per Store",
        (locationData.totalOpenStoresCountCurrentYear / leadsVolumeB2CData.current_year).toFixed(2),
        (locationData.totalOpenStoresCountLastYear / leadsVolumeB2CData.last_year).toFixed(2),
      ],
      [],
      ["Lead Density"],
      ["Less Than 1 Lead", denistyData["0_lead"].current_year, denistyData["0_lead"].last_year],
      ["1 Leads", denistyData["1_lead"].current_year, denistyData["1_lead"].last_year],
      ["2-5 Leads", denistyData["2_5_leads"].current_year, denistyData["2_5_leads"].last_year],
      ["5-10 Leads", denistyData["5_10_leads"].current_year, denistyData["5_10_leads"].last_year],
      [
        "10+ Leads",
        denistyData["10_plus_leads"].current_year,
        denistyData["10_plus_leads"].last_year,
      ],
      [
        "Total Stores With Leads",
        denistyData.total_stores_with_leads.current_year,
        denistyData.total_stores_with_leads.last_year,
      ],
      [
        "% Stores with Leads",
        (denistyData.total_stores_with_leads.current_year /
          locationData.totalOpenStoresCountCurrentYear) *
          100,
        (denistyData.total_stores_with_leads.last_year /
          locationData.totalOpenStoresCountLastYear) *
          100,
      ],
      [],
      ["LEAD QUALIFIED (B2B)"],
      ...Object.keys(leadsIntentCSV).map((key) => {
        const currentYearEntry = leadsIntent.current_year.find(
          (entry: any) => entry.intent_category === key
        ) || { count: 0 };
        const lastYearEntry = leadsIntent.last_year.find(
          (entry: any) => entry.intent_category === key
        ) || { count: 0 };
        const percentageDenominator = parseInt(lastYearEntry.count.toString(), 10);
        const percentage =
          percentageDenominator !== 0
            ? ((parseInt(currentYearEntry.count.toString(), 10) - percentageDenominator) /
                percentageDenominator) *
              100
            : "N/A";
        return [
          (leadsIntentCSV as any)[key],
          currentYearEntry.count,
          lastYearEntry.count,
          percentage !== "N/A" ? `${(percentage as number).toFixed(2)}%` : "N/A",
        ];
      }),
      [
        "Leads Captured w/ Intent",
        leadsIntentData.current_year,
        leadsIntentData.last_year,
        leadsIntentData.percentage,
      ],
      [
        "Intent Captured as % of Leads Captured",
        `${leadsCaptureCurrentYear.toFixed(2)}%`,
        `${leadsCaptureLastYear.toFixed(2)}%`,
        `${leadsCapturePercentage.toFixed(2)}%`,
      ],
      [
        "B2B Intnet as % of Intent Captured",
        `${leadsB2BIntentCurrentYear.toFixed(2)}%`,
        `${leadsB2BIntentLastYear.toFixed(2)}%`,
        `${leadsB2BIntnetPercentage.toFixed(2)}%`,
      ],
      [
        "B2B Intnet as % of Leads Captured",
        `${intentB2BLeadsCurrentYear.toFixed(2)}%`,
        `${intentB2BLeadsLastYear.toFixed(2)}%`,
        `${intentB2BLeadsPercentage.toFixed(2)}%`,
      ],
      [],
      ["INTENT ERROR (B2B)"],
      [
        "ESTIMATED Wrong Intent Leads",
        (leadsVolumeB2BData.current_year * (1 - leadsB2BIntentCurrentYear)).toFixed(2),
        (leadsVolumeB2BData.last_year * (1 - leadsB2BIntentLastYear)).toFixed(2),
      ],
      [
        "Error as % of Leads Captured",
        `${errorLeadsCapturedCurrentYear.toFixed(2)}%`,
        `${errorLeadsCapturedLastYear.toFixed(2)}%`,
        `${(
          ((errorLeadsCapturedCurrentYear - errorLeadsCapturedLastYear) /
            errorLeadsCapturedLastYear) *
          100
        ).toFixed(2)}%`,
      ],
      [],
      ["LEAD ATTRIBUTION (B2B)"],
      ...Object.keys(leadsAttributionCSV).map((key) => {
        const currentYearEntry = leadsAttributionData.current_year.find(
          (entry: any) => entry.source === key
        ) || { count: 0 };
        const lastYearEntry = leadsAttributionData.last_year.find(
          (entry: any) => entry.source === key
        ) || { count: 0 };
        const percentageDenominator = parseInt(lastYearEntry.count.toString(), 10);
        const percentage =
          percentageDenominator !== 0
            ? ((parseInt(currentYearEntry.count.toString(), 10) - percentageDenominator) /
                percentageDenominator) *
              100
            : "N/A";

        return [
          (leadsAttributionCSV as any)[key],
          currentYearEntry.count,
          lastYearEntry.count,
          percentage !== "N/A" ? `${(percentage as number).toFixed(2)}%` : "N/A",
        ];
      }),
      [],
      [
        "Leads Captured w/ Attribution",
        leadsAttribution.current_year,
        leadsAttribution.last_year,
        leadsAttribution.percentage,
      ],
      [
        "Attribution as % of Leads Captured",
        `${leadAttributionCurrentYear.toFixed(2)}%`,
        `${leadsAttributionLastYear.toFixed(2)}%`,
        `${leadsAttributionPercentage.toFixed(2)}%`,
      ],
      [],
    ];

    const csvString = tableData.map((row) => row.map((value) => `"${value}"`).join(",")).join("\n");

    const blob = new Blob([csvString], { type: "text/csv;charset=utf-8;" });
    const url = URL.createObjectURL(blob);
    const link = document.createElement("a");
    const dateRange = currentDateCheckBox
      ? `till_current_date`
      : `${startDate.slice(0, 7)}_${endDate.slice(0, 7)}`;
    link.href = url;
    link.setAttribute("download", `leads_analysis_report_${dateRange}.csv`);

    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <>
      {loader && (
        <MDBox
          style={{
            background: "rgba(0,0,0,0.3)",
            zIndex: 99999,
            display: "flex",
            position: "fixed",
            alignItems: "center",
            justifyContent: "center",
            width: "100%",
            height: "100vh",
          }}
        >
          <CustomLoader />
        </MDBox>
      )}
      <DashboardLayout>
        <DashboardNavbar />
        {!visiblity &&
          !sourceVisiblity &&
          !maturedUnmaturedLeadsVisibility &&
          !attributionVisibiltiy &&
          !leadB2BVisbility &&
          !leadB2CVisbility &&
          !leadDensityVisbility &&
          !leadsVolumeB2BVisbility &&
          !leadsIntentVisbility &&
          !leadsVolumeB2CVisbility &&
          !isLoading && (
            <Card>
              <MDBox sx={{ padding: "15px" }}>
                <MDBox display="flex" justifyContent="space-between" alignItems="center">
                  <ScorecardDateInput
                    dateArr={scoercardDateRange}
                    setCurrentDateCheckBox={setCurrentDateCheckBox}
                    currentDateCheckBox={currentDateCheckBox}
                    selectedDate={selectedDate}
                    onChangeDateField={onChangeDateField}
                    selectedDateRange={selectedDateRange}
                    setSelectedDateRange={setSelectedDateRange}
                    setSelectedDate={setSelectedDate}
                  />
                  <MDButton
                    variant="gradient"
                    color="info"
                    size="small"
                    onClick={downloadMultiTableCSV}
                  >
                    <MDTypography variant="subtitle2" color="white">
                      Export
                    </MDTypography>
                  </MDButton>
                </MDBox>
                <MDBox mt={6}>
                  <Grid container spacing={3}>
                    <Grid item xs={12} md={6} lg={4}>
                      <MDBox
                        mb={3}
                        onClick={() => {
                          setVisiblity(true);
                          setLeadsCurrentYearRange({ start: 0, end: 5 });
                          setLeadsLastYearRange({ start: 0, end: 5 });
                          setTitle("Leads%");
                          setTitle("Top 5 Locations");
                        }}
                      >
                        <ReportsLineChart
                          color="success"
                          title="Top 5 Locations"
                          description=""
                          date={
                            !currentDateCheckBox
                              ? `${selectedDateRange.startMonth} - ${selectedDateRange.endMonth}`
                              : `${new Date().getFullYear()}-${(new Date().getMonth() + 1)
                                  .toString()
                                  .padStart(2, "0")}`
                          }
                          chart={generateChartLabelsAndData(selectedlead, 0, 5, "current_year")}
                        />
                      </MDBox>
                    </Grid>
                    <Grid item xs={12} md={6} lg={4}>
                      <MDBox
                        mb={3}
                        onClick={() => {
                          setVisiblity(true);
                          setLeadsCurrentYearRange({ start: 0, end: 20 });
                          setLeadsLastYearRange({ start: 0, end: 20 });
                          setTitle("Top 20 Locations");
                        }}
                      >
                        <ReportsLineChart
                          color="success"
                          title="Top 20 Locations"
                          description=""
                          date={
                            !currentDateCheckBox
                              ? `${selectedDateRange.startMonth} - ${selectedDateRange.endMonth}`
                              : `${new Date().getFullYear()}-${(new Date().getMonth() + 1)
                                  .toString()
                                  .padStart(2, "0")}`
                          }
                          chart={generateChartLabelsAndData(selectedlead, 0, 20, "current_year")}
                        />
                      </MDBox>
                    </Grid>
                    <Grid item xs={12} md={6} lg={4}>
                      <MDBox
                        mb={3}
                        onClick={() => {
                          setVisiblity(true);
                          setLeadsCurrentYearRange({
                            // eslint-disable-next-line no-unsafe-optional-chaining
                            start: selectedlead?.current_year.length - 20,
                            end: selectedlead?.current_year.length,
                          });
                          setLeadsLastYearRange({
                            // eslint-disable-next-line no-unsafe-optional-chaining
                            start: selectedlead?.last_year.length - 20,
                            end: selectedlead?.last_year.length,
                          });
                          setTitle("last 20 Locations");
                        }}
                      >
                        <ReportsLineChart
                          color="success"
                          title="last 20 Locations"
                          description=""
                          date={
                            !currentDateCheckBox
                              ? `${selectedDateRange.startMonth} - ${selectedDateRange.endMonth}`
                              : `${new Date().getFullYear()}-${(new Date().getMonth() + 1)
                                  .toString()
                                  .padStart(2, "0")}`
                          }
                          chart={generateChartLabelsAndData(
                            selectedlead,
                            // eslint-disable-next-line no-unsafe-optional-chaining
                            selectedlead?.current_year.length - 20,
                            selectedlead?.current_year.length,
                            "current_year"
                          )}
                        />
                      </MDBox>
                    </Grid>
                    <Grid item xs={12} md={6} lg={4}>
                      <MDBox
                        mb={3}
                        onClick={() => {
                          setSourceVisiblity(true);
                        }}
                      >
                        {selectedDateRange.startMonth && selectedDateRange.endMonth && (
                          <SourceType
                            selectedDateRange={selectedDateRange}
                            currentDateCheckBox={currentDateCheckBox}
                            setSelectedSourceType={setSelectedSourceType}
                          />
                        )}
                      </MDBox>
                    </Grid>
                    <Grid item xs={12} md={6} lg={4}>
                      <MDBox
                        mb={3}
                        onClick={() => {
                          setMaturedUnmaturedLeadsVisibility(true);
                        }}
                      >
                        {selectedDateRange.startMonth && selectedDateRange.endMonth && (
                          <MaturedLocationLeads
                            selectedDateRange={selectedDateRange}
                            currentDateCheckBox={currentDateCheckBox}
                            setMaturedLocation={setMaturedLocation}
                            totalMaturedAndUnmaturedLocation={totalMaturedAndUnmaturedLocation}
                            setTotalMaturedAndUnmaturedLocation={
                              setTotalMaturedAndUnmaturedLocation
                            }
                          />
                        )}
                      </MDBox>
                    </Grid>
                    <Grid item xs={12} md={6} lg={4}>
                      <MDBox
                        mb={3}
                        onClick={() => {
                          setAttributionVisibiltiy(true);
                        }}
                      >
                        {selectedDateRange.startMonth && selectedDateRange.endMonth && (
                          <LeadsAttribution
                            selectedDateRange={selectedDateRange}
                            currentDateCheckBox={currentDateCheckBox}
                            setLeadsAttributionData={setLeadsAttributionData}
                          />
                        )}
                      </MDBox>
                    </Grid>
                    <Grid item xs={12} md={6} lg={4}>
                      <MDBox
                        mb={3}
                        onClick={() => {
                          setLeadsB2BVisibility(true);
                        }}
                      >
                        {selectedDateRange.startMonth && selectedDateRange.endMonth && (
                          <LeadsB2B
                            selectedDateRange={selectedDateRange}
                            currentDateCheckBox={currentDateCheckBox}
                            setLeadsB2BData={setLeadsB2BData}
                          />
                        )}
                      </MDBox>
                    </Grid>
                    <Grid item xs={12} md={6} lg={4}>
                      <MDBox
                        mb={3}
                        onClick={() => {
                          setLeadsB2CVisibility(true);
                        }}
                      >
                        {selectedDateRange.startMonth && selectedDateRange.endMonth && (
                          <LeadsB2C
                            selectedDateRange={selectedDateRange}
                            currentDateCheckBox={currentDateCheckBox}
                            setLeadsB2CData={setLeadsB2CData}
                          />
                        )}
                      </MDBox>
                    </Grid>
                    <Grid item xs={12} md={6} lg={4}>
                      <MDBox
                        mb={3}
                        onClick={() => {
                          setLeadsDensityVisibility(true);
                        }}
                      >
                        {selectedDateRange.startMonth && selectedDateRange.endMonth && (
                          <LeadsDensity
                            selectedDateRange={selectedDateRange}
                            currentDateCheckBox={currentDateCheckBox}
                            setLeadsDensity={setLeadsDensity}
                          />
                        )}
                      </MDBox>
                    </Grid>
                    <Grid item xs={12} md={6} lg={4}>
                      <MDBox
                        mb={3}
                        onClick={() => {
                          setLeadsVolumeB2BVisibility(true);
                        }}
                      >
                        {selectedDateRange.startMonth && selectedDateRange.endMonth && (
                          <LeadsVolumeB2B
                            selectedDateRange={selectedDateRange}
                            currentDateCheckBox={currentDateCheckBox}
                            setLeadsB2BVolume={setLeadsVolumeB2B}
                          />
                        )}
                      </MDBox>
                    </Grid>
                    <Grid item xs={12} md={6} lg={4}>
                      <MDBox
                        mb={3}
                        onClick={() => {
                          setLeadsVolumeB2CVisibility(true);
                        }}
                      >
                        {selectedDateRange.startMonth && selectedDateRange.endMonth && (
                          <LeadsVolumeB2C
                            selectedDateRange={selectedDateRange}
                            currentDateCheckBox={currentDateCheckBox}
                            setLeadsVolumeB2C={setLeadsVolumeB2C}
                          />
                        )}
                      </MDBox>
                    </Grid>
                    <Grid item xs={12} md={6} lg={4}>
                      <MDBox
                        mb={3}
                        onClick={() => {
                          setLeadsIntentVisibility(true);
                        }}
                      >
                        {selectedDateRange.startMonth && selectedDateRange.endMonth && (
                          <LeadsIntent
                            selectedDateRange={selectedDateRange}
                            currentDateCheckBox={currentDateCheckBox}
                            setLeadsIntent={setLeadsIntent}
                          />
                        )}
                      </MDBox>
                    </Grid>
                  </Grid>
                </MDBox>
              </MDBox>
            </Card>
          )}
        {visiblity && (
          <LeadsData
            selectedDate={selectedDate}
            onChangeDateField={onChangeDateField}
            selectedDateRange={selectedDateRange}
            setSelectedDateRange={setSelectedDateRange}
            setSelectedDate={setSelectedDate}
            currentDateCheckBox={currentDateCheckBox}
            setCurrentDateCheckBox={setCurrentDateCheckBox}
            scoercardDateRange={scoercardDateRange}
            leadsCurrentYear={leadsCurrentYearRange}
            leadsLastYear={leadsLastYearRange}
            graphDescription=""
            graphTitle={title}
            setVisiblity={setVisiblity}
            selectedlead={selectedlead}
            year={year}
          />
        )}
        {sourceVisiblity && (
          <SourceTypeDetailed
            selectedDate={selectedDate}
            onChangeDateField={onChangeDateField}
            selectedDateRange={selectedDateRange}
            setSelectedDateRange={setSelectedDateRange}
            setSelectedDate={setSelectedDate}
            scoercardDateRange={scoercardDateRange}
            setCurrentDateCheckBox={setCurrentDateCheckBox}
            currentDateCheckBox={currentDateCheckBox}
            setVisiblity={setSourceVisiblity}
            setSourceType={setSelectedSourceType}
            SourceData={selectedSourceType}
            year={year}
          />
        )}
        {maturedUnmaturedLeadsVisibility && (
          <MaturedLeadsDetailed
            selectedDate={selectedDate}
            onChangeDateField={onChangeDateField}
            selectedDateRange={selectedDateRange}
            setSelectedDateRange={setSelectedDateRange}
            setSelectedDate={setSelectedDate}
            scoercardDateRange={scoercardDateRange}
            setCurrentDateCheckBox={setCurrentDateCheckBox}
            currentDateCheckBox={currentDateCheckBox}
            setVisiblity={setMaturedUnmaturedLeadsVisibility}
            setMaturedLocation={setMaturedLocation}
            maturedLocation={maturedLocation}
            totalMaturedAndUnmaturedLocation={totalMaturedAndUnmaturedLocation}
            setTotalMaturedAndUnmaturedLocation={setTotalMaturedAndUnmaturedLocation}
            year={year}
          />
        )}
        {attributionVisibiltiy && (
          <AttributionLeadsDetailed
            selectedDate={selectedDate}
            onChangeDateField={onChangeDateField}
            selectedDateRange={selectedDateRange}
            setSelectedDateRange={setSelectedDateRange}
            setSelectedDate={setSelectedDate}
            scoercardDateRange={scoercardDateRange}
            setCurrentDateCheckBox={setCurrentDateCheckBox}
            currentDateCheckBox={currentDateCheckBox}
            setVisiblity={setAttributionVisibiltiy}
            setLeadsAttributionData={setLeadsAttributionData}
            leadsAttributionData={leadsAttributionData}
            year={year}
          />
        )}
        {leadB2BVisbility && (
          <LeadsB2BDetailed
            selectedDate={selectedDate}
            onChangeDateField={onChangeDateField}
            selectedDateRange={selectedDateRange}
            setSelectedDateRange={setSelectedDateRange}
            setSelectedDate={setSelectedDate}
            scoercardDateRange={scoercardDateRange}
            setCurrentDateCheckBox={setCurrentDateCheckBox}
            currentDateCheckBox={currentDateCheckBox}
            setVisiblity={setLeadsB2BVisibility}
            leadsB2BData={leadsB2BData}
            setLeadsB2BData={setLeadsB2BData}
            year={year}
          />
        )}
        {leadB2CVisbility && (
          <LeadsB2CDetailed
            selectedDate={selectedDate}
            onChangeDateField={onChangeDateField}
            selectedDateRange={selectedDateRange}
            setSelectedDateRange={setSelectedDateRange}
            setSelectedDate={setSelectedDate}
            scoercardDateRange={scoercardDateRange}
            setCurrentDateCheckBox={setCurrentDateCheckBox}
            currentDateCheckBox={currentDateCheckBox}
            setVisiblity={setLeadsB2CVisibility}
            leadsB2CData={leadsB2CData}
            setLeadsB2CData={setLeadsB2CData}
            year={year}
          />
        )}
        {leadDensityVisbility && (
          <LeadsDensityDetailed
            selectedDate={selectedDate}
            onChangeDateField={onChangeDateField}
            selectedDateRange={selectedDateRange}
            setSelectedDateRange={setSelectedDateRange}
            setSelectedDate={setSelectedDate}
            scoercardDateRange={scoercardDateRange}
            setCurrentDateCheckBox={setCurrentDateCheckBox}
            currentDateCheckBox={currentDateCheckBox}
            setVisiblity={setLeadsDensityVisibility}
            leadsDensityData={leadsDensity}
            setLeadsDensityData={setLeadsDensity}
            year={year}
          />
        )}
        {leadsVolumeB2BVisbility && (
          <LeadsVolumeB2BDetailed
            selectedDate={selectedDate}
            onChangeDateField={onChangeDateField}
            selectedDateRange={selectedDateRange}
            setSelectedDateRange={setSelectedDateRange}
            setSelectedDate={setSelectedDate}
            scoercardDateRange={scoercardDateRange}
            setCurrentDateCheckBox={setCurrentDateCheckBox}
            currentDateCheckBox={currentDateCheckBox}
            setVisiblity={setLeadsVolumeB2BVisibility}
            leadsVolumeB2BData={leadsVolumeB2B}
            setLeadsVolumeB2BData={setLeadsVolumeB2B}
            year={year}
          />
        )}
        {leadsVolumeB2CVisbility && (
          <LeadsVolumeB2CDetailed
            selectedDate={selectedDate}
            onChangeDateField={onChangeDateField}
            selectedDateRange={selectedDateRange}
            setSelectedDateRange={setSelectedDateRange}
            setSelectedDate={setSelectedDate}
            scoercardDateRange={scoercardDateRange}
            setCurrentDateCheckBox={setCurrentDateCheckBox}
            currentDateCheckBox={currentDateCheckBox}
            setVisiblity={setLeadsVolumeB2CVisibility}
            leadsVolumeB2CData={leadsVolumeB2C}
            setLeadsVolumeB2CData={setLeadsVolumeB2C}
            year={year}
          />
        )}
        {leadsIntentVisbility && (
          <LeadsIntentDetailed
            selectedDate={selectedDate}
            onChangeDateField={onChangeDateField}
            selectedDateRange={selectedDateRange}
            setSelectedDateRange={setSelectedDateRange}
            setSelectedDate={setSelectedDate}
            scoercardDateRange={scoercardDateRange}
            setCurrentDateCheckBox={setCurrentDateCheckBox}
            currentDateCheckBox={currentDateCheckBox}
            setVisiblity={setLeadsIntentVisibility}
            leadsIntent={leadsIntent}
            setLeadsIntent={setLeadsIntent}
            year={year}
          />
        )}
      </DashboardLayout>
    </>
  );
}
export default LeadsDashboard;
