/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import { SelectedDateRange } from "layouts/pages/scorecard-dashboard";
import { notificationFail } from "store/slices/notificationSlice";
import { useAppDispatch, useAppSelector } from "store/store";
import config from "config/config";
import axios from "axios";
import {
  getFirstDateOfCurrentMonth,
  convertToFirstDayOfMonth,
  fetchCurrentDate,
  convertToLastDayOfMonth,
  getYears,
  countLeadsByRange,
} from "helper/reports";
import Messages from "helper/messages";
import MDTypography from "components/MDTypography";
import { Card, Divider, Grid, Icon } from "@mui/material";
import MDBox from "components/MDBox";
import { Bar } from "react-chartjs-2";
import { getAuthUser } from "helper/services";
import { FormattedType, LeadAvailableLocation, LeadsDensityData } from "../..";

interface LeadsB2CLocationProps {
  selectedDateRange: SelectedDateRange;
  currentDateCheckBox: boolean;
  setLeadsDensity: (LeadsDensity: LeadsDensityData) => void;
}
function LeadsDensity({
  selectedDateRange,
  currentDateCheckBox,
  setLeadsDensity,
}: LeadsB2CLocationProps) {
  const userDetails = getAuthUser();
  const dispatch = useAppDispatch();
  const { cmsLocations } = useAppSelector((state) => state.locationSlice);
  const [formattedLeadsDensity, setFormattedLeadsDensity] = useState<FormattedType>();

  const fetchLeadB2CData = (locationsData: LeadAvailableLocation[]) => {
    try {
      if (selectedDateRange.startMonth > selectedDateRange.endMonth) {
        throw new Error("Start month should be lower than end month");
      } else {
        const ids = locationsData?.map((location) => location.id);
        const acceptedRoles = ["Admin", "System Admin"];
        let url = "";
        if (acceptedRoles.includes(userDetails.role) || !acceptedRoles.includes(userDetails.role)) {
          const startDate = currentDateCheckBox
            ? getFirstDateOfCurrentMonth()
            : convertToFirstDayOfMonth(selectedDateRange.startMonth);
          const endDate = currentDateCheckBox
            ? fetchCurrentDate()
            : convertToLastDayOfMonth(selectedDateRange.endMonth);
          url = `${config.REPORT_URL}/report/fetchLeadsDensity?startDate=${startDate
            .toString()
            .slice(0, 10)}&endDate=${endDate.toString().slice(0, 10)}&locations=${
            userDetails.role === "Admin" || userDetails.role === "System Admin"
              ? "All"
              : `'${ids?.join(", ")}'`
          }`;
          axios
            .get(url, {
              responseType: "json",
            })
            .then((res: any) => {
              setLeadsDensity(res.data.result);
              setFormattedLeadsDensity(countLeadsByRange(res.data.result));
            })
            .catch((err) => {
              dispatch(
                notificationFail(
                  err?.response?.data?.message || Messages.ERROR.SOMETHING_WENT_WRONG
                )
              );
            });
        }
      }
    } catch (e) {
      dispatch(notificationFail(e.message || Messages.ERROR.SOMETHING_WENT_WRONG));
    }
  };

  useEffect(() => {
    const locations = cmsLocations.locations?.map((item: any) => ({
      location_id: item.id.toString(),
      location: item.name,
    }));
    fetchLeadB2CData(locations);
  }, [cmsLocations, selectedDateRange]);

  const options = {
    plugins: {
      legend: {
        display: false,
      },
      title: {
        display: false,
        text: "Chart.js Bar Chart",
      },
    },
  };
  const data = {
    labels: getYears(selectedDateRange, currentDateCheckBox),
    datasets: [
      {
        label: "Less Than 1 Lead",
        data: formattedLeadsDensity?.LessThan1Lead,
        backgroundColor: "rgba(255, 99, 132, 0.5)",
      },
      {
        label: "Leads Equals To 1",
        data: formattedLeadsDensity?.LeadsEqualsTo1,
        backgroundColor: "rgba(255, 99, 132, 0.5)",
      },
      {
        label: "Leads 2 to 5",
        data: formattedLeadsDensity?.Leads2to5,
        backgroundColor: "rgba(178, 245, 39, 0.8)",
      },
      {
        label: "Leads 5 to 10",
        data: formattedLeadsDensity?.Leads5to10,
        backgroundColor: "rgba(56, 54, 39, 0.8)",
      },
      {
        label: "Leads More Than 10",
        data: formattedLeadsDensity?.LeadsMoreThan10,
        backgroundColor: "rgba(39, 245, 196, 0.8)",
      },
    ],
  };
  return (
    <Card sx={{ height: "100%" }}>
      <Grid container>
        <Grid item md={12} sm={12} pt={3} pb={1} px={1}>
          <Bar options={options} data={data} />
        </Grid>
        <Grid item md={12} sm={12}>
          <MDTypography variant="h6" textTransform="capitalize" padding="0px 0px 0px 15px">
            LeadsDensity
          </MDTypography>
          <MDTypography
            component="div"
            variant="button"
            color="text"
            fontWeight="light"
            padding="0px 0px 15px 15px"
          >
            A bar graph representing all LeadsDensity
          </MDTypography>
          <Divider />
          <MDBox display="flex" alignItems="center" padding="0px 0px 15px 15px">
            <MDTypography variant="button" color="text" lineHeight={1} sx={{ mt: 0.15, mr: 0.5 }}>
              <Icon>schedule</Icon>
            </MDTypography>
            <MDTypography variant="button" color="text" fontWeight="light">
              {!currentDateCheckBox
                ? `${selectedDateRange.startMonth} - ${selectedDateRange.endMonth}`
                : `${new Date().getFullYear()}-${(new Date().getMonth() + 1)
                    .toString()
                    .padStart(2, "0")}`}
            </MDTypography>
          </MDBox>
        </Grid>
      </Grid>
    </Card>
  );
}

export default LeadsDensity;
