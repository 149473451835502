import MDDialog from "components/MDDialog";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
// import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
} from "@mui/material";
import { useState } from "react";
import ImagePreview from "layouts/pages/ImagePreview";

function ViewDetails(props: any): JSX.Element {
  const { open, onClose, updateData, status } = props;
  const [isPreview, setImagePreviewPopup] = useState(false);
  const [imageToPreview, setImageToPreview] = useState("");

  const dialogProps = {
    open,
    onClose,
    title: "View Update My Sola Website",
    size: "md",
    saveBtn: false,
  };

  const previewImage = (image: any) => {
    setImagePreviewPopup(true);
    setImageToPreview(image);
  };

  const closeImagePreview = () => {
    setImagePreviewPopup(false);
    setImageToPreview("");
  };

  const getImageElement = (name: any, imageData: any) => {
    if (imageData) {
      return (
        <Grid item xs={12} sm={6} md={6}>
          <MDTypography fontWeight="bold" variant="button">
            {name}
          </MDTypography>
          <Grid container mt={2}>
            <Grid item xs={12} sm={6} md={6}>
              {imageData && (
                <Grid
                  container
                  onClick={() => previewImage(imageData)}
                  justifyContent="left"
                  alignItems="center"
                  display="flex"
                >
                  <Grid item className="thumbnail-img">
                    <img height={100} width={100} src={imageData} alt="location" />
                  </Grid>
                </Grid>
              )}
            </Grid>
          </Grid>
        </Grid>
      );
    }
    return null;
  };
  const getDetailElement = (name: any, value: any) => {
    if (value) {
      return (
        <Grid item xs={12} sm={6} md={6}>
          <MDTypography fontWeight="bold" variant="button">
            {name}
          </MDTypography>
          <MDBox sx={{ wordBreak: "break-all" }}>{value ? value : ""}</MDBox>
        </Grid>
      );
    }
    return null;
  };

  return (
    <MDDialog {...dialogProps}>
      <MDBox pb={2}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12} md={12}>
            <Accordion expanded>
              <AccordionSummary
                className="location-accordion"
                // expandIcon={<ExpandMoreIcon />}
                aria-controls="publishbh-content"
              >
                <MDTypography variant="h6">General</MDTypography>
              </AccordionSummary>
              <AccordionDetails>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6} md={6}>
                    <MDTypography fontWeight="bold" variant="button">
                      Name
                    </MDTypography>
                    <MDBox>
                      <MDTypography variant="button">{updateData.name || "-"}</MDTypography>
                    </MDBox>
                  </Grid>
                  <Grid item xs={12} sm={6} md={6}>
                    <MDTypography fontWeight="bold" variant="button">
                      Biography
                    </MDTypography>
                    <MDBox
                      dangerouslySetInnerHTML={{
                        __html: updateData?.biography ? updateData?.biography : "",
                      }}
                    />
                  </Grid>
                </Grid>
              </AccordionDetails>
            </Accordion>
          </Grid>
          <Grid item xs={12} sm={12} md={12}>
            <Accordion expanded>
              <AccordionSummary
                className="location-accordion"
                // expandIcon={<ExpandMoreIcon />}
                aria-controls="publishbh-content"
              >
                <MDTypography variant="h6">Contact</MDTypography>
              </AccordionSummary>
              <AccordionDetails>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6} md={6}>
                    <MDTypography fontWeight="bold" variant="button">
                      Phone Number
                    </MDTypography>
                    <MDBox>
                      <MDTypography variant="button">
                        {updateData?.phone_number || "-"}
                      </MDTypography>
                    </MDBox>
                  </Grid>
                  <Grid item xs={12} sm={6} md={6}>
                    <MDTypography fontWeight="bold" variant="button">
                      Email Address
                    </MDTypography>
                    <MDBox>
                      <MDTypography variant="button">
                        {updateData?.email_address || "-"}
                      </MDTypography>
                    </MDBox>
                  </Grid>
                </Grid>
              </AccordionDetails>
            </Accordion>
          </Grid>
          <Grid item xs={12} sm={12} md={12}>
            <Accordion expanded>
              <AccordionSummary
                className="location-accordion"
                // expandIcon={<ExpandMoreIcon />}
                aria-controls="publishbh-content"
              >
                <MDTypography variant="h6">Business</MDTypography>
              </AccordionSummary>
              <AccordionDetails>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6} md={6}>
                    <MDTypography fontWeight="bold" variant="button">
                      Business Name
                    </MDTypography>
                    <MDBox>
                      <MDTypography variant="button">
                        {updateData?.business_name || "-"}
                      </MDTypography>
                    </MDBox>
                  </Grid>
                  <Grid item xs={12} sm={6} md={6}>
                    <MDTypography fontWeight="bold" variant="button">
                      Work Hours
                    </MDTypography>
                    <MDBox>
                      <MDTypography variant="button">{updateData?.work_hours || "-"}</MDTypography>
                    </MDBox>
                  </Grid>
                </Grid>
              </AccordionDetails>
            </Accordion>
          </Grid>
          <Grid item xs={12} sm={12} md={12}>
            <Accordion expanded>
              <AccordionSummary
                className="location-accordion"
                // expandIcon={<ExpandMoreIcon />}
                aria-controls="publishbh-content"
              >
                <MDTypography variant="h6">Website</MDTypography>
              </AccordionSummary>
              <AccordionDetails>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6} md={6}>
                    <MDTypography fontWeight="bold" variant="button">
                      Website Url
                    </MDTypography>
                    <MDBox>
                      <MDTypography variant="button">{updateData?.website_url || "-"}</MDTypography>
                    </MDBox>
                  </Grid>
                  <Grid item xs={12} sm={6} md={6}>
                    <MDTypography fontWeight="bold" variant="button">
                      Booking Url
                    </MDTypography>
                    <MDBox>
                      <MDTypography variant="button">{updateData?.booking_url || "-"}</MDTypography>
                    </MDBox>
                  </Grid>
                  <Grid item xs={12} sm={6} md={6}>
                    <MDTypography fontWeight="bold" variant="button">
                      Reserved
                    </MDTypography>
                    <MDBox>
                      <FormControl component="fieldset">
                        <FormGroup aria-label="position">
                          <FormControlLabel
                            value="end"
                            control={
                              <Checkbox
                                checked={updateData?.reserved || false}
                                inputProps={{ "aria-label": "controlled" }}
                              />
                            }
                            label=""
                          />
                        </FormGroup>
                      </FormControl>
                    </MDBox>
                  </Grid>
                </Grid>
              </AccordionDetails>
            </Accordion>
          </Grid>
          <Grid item xs={12} sm={12} md={12}>
            <Accordion expanded>
              <AccordionSummary
                className="location-accordion"
                // expandIcon={<ExpandMoreIcon />}
                aria-controls="publishbh-content"
              >
                <MDTypography variant="h6">Social</MDTypography>
              </AccordionSummary>
              <AccordionDetails>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6} md={6}>
                    <MDTypography fontWeight="bold" variant="button">
                      Facebook Url
                    </MDTypography>
                    <MDBox>
                      <MDTypography variant="button">
                        {updateData?.facebook_url || "-"}
                      </MDTypography>
                    </MDBox>
                  </Grid>
                  <Grid item xs={12} sm={6} md={6}>
                    <MDTypography fontWeight="bold" variant="button">
                      Google Plus Url
                    </MDTypography>
                    <MDBox>
                      <MDTypography variant="button">
                        {updateData?.google_plus_url || "-"}
                      </MDTypography>
                    </MDBox>
                  </Grid>
                  <Grid item xs={12} sm={6} md={6}>
                    <MDTypography fontWeight="bold" variant="button">
                      Instagram Url
                    </MDTypography>
                    <MDBox>
                      <MDTypography variant="button">
                        {updateData?.instagram_url || "-"}
                      </MDTypography>
                    </MDBox>
                  </Grid>
                  <Grid item xs={12} sm={6} md={6}>
                    <MDTypography fontWeight="bold" variant="button">
                      Linkedin Url
                    </MDTypography>
                    <MDBox>
                      <MDTypography variant="button">
                        {updateData?.linkedin_url || "-"}
                      </MDTypography>
                    </MDBox>
                  </Grid>
                  <Grid item xs={12} sm={6} md={6}>
                    <MDTypography fontWeight="bold" variant="button">
                      Pinterest Url
                    </MDTypography>
                    <MDBox>
                      <MDTypography variant="button">
                        {updateData?.pinterest_url || "-"}
                      </MDTypography>
                    </MDBox>
                  </Grid>
                  <Grid item xs={12} sm={6} md={6}>
                    <MDTypography fontWeight="bold" variant="button">
                      Twitter Url
                    </MDTypography>
                    <MDBox>
                      <MDTypography variant="button">{updateData?.twitter_url || "-"}</MDTypography>
                    </MDBox>
                  </Grid>
                  <Grid item xs={12} sm={6} md={6}>
                    <MDTypography fontWeight="bold" variant="button">
                      Yelp Url
                    </MDTypography>
                    <MDBox>
                      <MDTypography variant="button">{updateData?.yelp_url || "-"}</MDTypography>
                    </MDBox>
                  </Grid>
                  <Grid item xs={12} sm={6} md={6}>
                    <MDTypography fontWeight="bold" variant="button">
                      Tik Tok Url
                    </MDTypography>
                    <MDBox>
                      <MDTypography variant="button">{updateData?.tik_tok_url || "-"}</MDTypography>
                    </MDBox>
                  </Grid>
                </Grid>
              </AccordionDetails>
            </Accordion>
          </Grid>
          <Grid item xs={12} sm={12} md={12}>
            <Accordion expanded>
              <AccordionSummary
                className="location-accordion"
                // expandIcon={<ExpandMoreIcon />}
                aria-controls="publishbh-content"
              >
                <MDTypography variant="h6">Services</MDTypography>
              </AccordionSummary>
              <AccordionDetails>
                <MDBox mt={2}>
                  <Grid container>
                    <Grid item xs={12} md={6} sm={6}>
                      <FormControl component="fieldset">
                        <FormGroup aria-label="position">
                          <FormControlLabel
                            value="end"
                            control={
                              <Checkbox
                                checked={updateData?.barber || false}
                                name="barber"
                                inputProps={{ "aria-label": "controlled" }}
                              />
                            }
                            label="Barber"
                            labelPlacement="end"
                          />
                        </FormGroup>
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} md={6} sm={6}>
                      <FormControl component="fieldset">
                        <FormGroup aria-label="position">
                          <FormControlLabel
                            value="end"
                            control={
                              <Checkbox
                                checked={updateData?.botox || false}
                                name="botox"
                                inputProps={{ "aria-label": "controlled" }}
                              />
                            }
                            label="Botox/Fillers"
                            labelPlacement="end"
                          />
                        </FormGroup>
                      </FormControl>
                    </Grid>
                  </Grid>
                  <Grid container>
                    <Grid item xs={12} md={6} sm={6}>
                      <FormControl component="fieldset">
                        <FormGroup aria-label="position">
                          <FormControlLabel
                            value="end"
                            control={
                              <Checkbox
                                checked={updateData?.brows || false}
                                name="brows"
                                inputProps={{ "aria-label": "controlled" }}
                              />
                            }
                            label="Brows"
                            labelPlacement="end"
                          />
                        </FormGroup>
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} md={6} sm={6}>
                      <FormControl component="fieldset">
                        <FormGroup aria-label="position">
                          <FormControlLabel
                            value="end"
                            control={
                              <Checkbox
                                checked={updateData?.hair || false}
                                name="hair"
                                inputProps={{ "aria-label": "controlled" }}
                              />
                            }
                            label="Hair"
                            labelPlacement="end"
                          />
                        </FormGroup>
                      </FormControl>
                    </Grid>
                  </Grid>
                  <Grid container>
                    <Grid item xs={12} md={6} sm={6}>
                      <FormControl component="fieldset">
                        <FormGroup aria-label="position">
                          <FormControlLabel
                            value="end"
                            control={
                              <Checkbox
                                checked={updateData?.hair_extensions || false}
                                name="hair_extensions"
                                inputProps={{ "aria-label": "controlled" }}
                              />
                            }
                            label="Hair Extensions"
                            labelPlacement="end"
                          />
                        </FormGroup>
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} md={6} sm={6}>
                      <FormControl component="fieldset">
                        <FormGroup aria-label="position">
                          <FormControlLabel
                            value="end"
                            control={
                              <Checkbox
                                checked={updateData?.laser_hair_removal || false}
                                name="laser_hair_removal"
                                inputProps={{ "aria-label": "controlled" }}
                              />
                            }
                            label="Laser Hair Removal"
                            labelPlacement="end"
                          />
                        </FormGroup>
                      </FormControl>
                    </Grid>
                  </Grid>
                  <Grid container>
                    <Grid item xs={12} md={6} sm={6}>
                      <FormControl component="fieldset">
                        <FormGroup aria-label="position">
                          <FormControlLabel
                            value="end"
                            control={
                              <Checkbox
                                checked={updateData?.eyelash_extensions || false}
                                name="eyelash_extensions"
                                inputProps={{ "aria-label": "controlled" }}
                              />
                            }
                            label="Lashes"
                            labelPlacement="end"
                          />
                        </FormGroup>
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} md={6} sm={6}>
                      <FormControl component="fieldset">
                        <FormGroup aria-label="position">
                          <FormControlLabel
                            value="end"
                            control={
                              <Checkbox
                                checked={updateData?.makeup || false}
                                name="makeup"
                                inputProps={{ "aria-label": "controlled" }}
                              />
                            }
                            label="Makeup"
                            labelPlacement="end"
                          />
                        </FormGroup>
                      </FormControl>
                    </Grid>
                  </Grid>
                  <Grid container>
                    <Grid item xs={12} md={6} sm={6}>
                      <FormControl component="fieldset">
                        <FormGroup aria-label="position">
                          <FormControlLabel
                            value="end"
                            control={
                              <Checkbox
                                checked={updateData?.massage || false}
                                name="massage"
                                inputProps={{ "aria-label": "controlled" }}
                              />
                            }
                            label="Massage"
                            labelPlacement="end"
                          />
                        </FormGroup>
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} md={6} sm={6}>
                      <FormControl component="fieldset">
                        <FormGroup aria-label="position">
                          <FormControlLabel
                            value="end"
                            control={
                              <Checkbox
                                checked={updateData?.nails || false}
                                name="nails"
                                inputProps={{ "aria-label": "controlled" }}
                              />
                            }
                            label="Nails"
                            labelPlacement="end"
                          />
                        </FormGroup>
                      </FormControl>
                    </Grid>
                  </Grid>
                  <Grid container>
                    <Grid item xs={12} md={6} sm={6}>
                      <FormControl component="fieldset">
                        <FormGroup aria-label="position">
                          <FormControlLabel
                            value="end"
                            control={
                              <Checkbox
                                checked={updateData?.permanent_makeup || false}
                                name="permanent_makeups"
                                inputProps={{ "aria-label": "controlled" }}
                              />
                            }
                            label="Permanent Makeup"
                            labelPlacement="end"
                          />
                        </FormGroup>
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} md={6} sm={6}>
                      <FormControl component="fieldset">
                        <FormGroup aria-label="position">
                          <FormControlLabel
                            value="end"
                            control={
                              <Checkbox
                                checked={updateData?.skin || false}
                                name="skin"
                                inputProps={{ "aria-label": "controlled" }}
                              />
                            }
                            label="Skincare"
                            labelPlacement="end"
                          />
                        </FormGroup>
                      </FormControl>
                    </Grid>
                  </Grid>
                  <Grid container>
                    <Grid item xs={12} md={6} sm={6}>
                      <FormControl component="fieldset">
                        <FormGroup aria-label="position">
                          <FormControlLabel
                            value="end"
                            control={
                              <Checkbox
                                checked={updateData?.tanning || false}
                                name="tanning"
                                inputProps={{ "aria-label": "controlled" }}
                              />
                            }
                            label="Tanning"
                            labelPlacement="end"
                          />
                        </FormGroup>
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} md={6} sm={6}>
                      <FormControl component="fieldset">
                        <FormGroup aria-label="position">
                          <FormControlLabel
                            value="end"
                            control={
                              <Checkbox
                                checked={updateData?.teeth_whitening || false}
                                name="teeth_whitening"
                                inputProps={{ "aria-label": "controlled" }}
                              />
                            }
                            label="Teeth Whitening"
                            labelPlacement="end"
                          />
                        </FormGroup>
                      </FormControl>
                    </Grid>
                  </Grid>
                  <Grid container>
                    <Grid item xs={12} md={6} sm={6}>
                      <FormControl component="fieldset">
                        <FormGroup aria-label="position">
                          <FormControlLabel
                            value="end"
                            control={
                              <Checkbox
                                checked={updateData?.threading || false}
                                name="threading"
                                inputProps={{ "aria-label": "controlled" }}
                              />
                            }
                            label="Threading"
                            labelPlacement="end"
                          />
                        </FormGroup>
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} md={6} sm={6}>
                      <FormControl component="fieldset">
                        <FormGroup aria-label="position">
                          <FormControlLabel
                            value="end"
                            control={
                              <Checkbox
                                checked={updateData?.waxing || false}
                                name="waxing"
                                inputProps={{ "aria-label": "controlled" }}
                              />
                            }
                            label="Waxing"
                            labelPlacement="end"
                          />
                        </FormGroup>
                      </FormControl>
                    </Grid>
                  </Grid>
                  <Grid container>
                    <Grid item xs={12} md={6} sm={6}>
                      <MDTypography fontWeight="bold" variant="button">
                        Other Services
                      </MDTypography>
                      <MDBox>
                        <MDTypography variant="button">
                          {updateData?.other_service || "-"}
                        </MDTypography>
                      </MDBox>
                    </Grid>
                  </Grid>
                </MDBox>
              </AccordionDetails>
            </Accordion>
          </Grid>
          <Grid item xs={12} sm={12} md={12}>
            <Accordion expanded>
              <AccordionSummary
                className="location-accordion"
                // expandIcon={<ExpandMoreIcon />}
                aria-controls="publishbh-content"
              >
                <MDTypography variant="h6">Testimonials</MDTypography>
              </AccordionSummary>
              <AccordionDetails>
                <Grid container spacing={2}>
                  {updateData?.testimonial_id_1 &&
                    getDetailElement(
                      "Testimonial #1",
                      updateData?.testimonials?.testimonial_1?.name
                    )}
                  {updateData?.testimonial_id_2 &&
                    getDetailElement(
                      "Testimonial #2",
                      updateData?.testimonials?.testimonial_2?.name
                    )}
                  {updateData?.testimonial_id_3 &&
                    getDetailElement(
                      "Testimonial #3",
                      updateData?.testimonials?.testimonial_3?.name
                    )}
                  {updateData?.testimonial_id_4 &&
                    getDetailElement(
                      "Testimonial #4",
                      updateData?.testimonials?.testimonial_4?.name
                    )}
                  {updateData?.testimonial_id_5 &&
                    getDetailElement(
                      "Testimonial #5",
                      updateData?.testimonials?.testimonial_5?.name
                    )}
                  {updateData?.testimonial_id_6 &&
                    getDetailElement(
                      "Testimonial #6",
                      updateData?.testimonials?.testimonial_6?.name
                    )}
                  {updateData?.testimonial_id_7 &&
                    getDetailElement(
                      "Testimonial #7",
                      updateData?.testimonials?.testimonial_7?.name
                    )}
                  {updateData?.testimonial_id_8 &&
                    getDetailElement(
                      "Testimonial #8",
                      updateData?.testimonials?.testimonial_8?.name
                    )}
                  {updateData?.testimonial_id_9 &&
                    getDetailElement(
                      "Testimonial #9",
                      updateData?.testimonials?.testimonial_9?.name
                    )}
                  {updateData?.testimonial_id_10 &&
                    getDetailElement(
                      "Testimonial #10",
                      updateData?.testimonials?.testimonial_10?.name
                    )}
                </Grid>
              </AccordionDetails>
            </Accordion>
          </Grid>
          <Grid item xs={12} sm={12} md={12}>
            <Accordion expanded>
              <AccordionSummary
                className="location-accordion"
                // expandIcon={<ExpandMoreIcon />}
                aria-controls="publishbh-content"
              >
                <MDTypography variant="h6">Images</MDTypography>
              </AccordionSummary>
              <AccordionDetails>
                <Grid container spacing={2}>
                  {status === "rejected"
                    ? updateData?.image_1_url && getImageElement("Image 1", updateData?.image_1_url)
                    : updateData?.update_my_sola__image_1_url &&
                      getImageElement("Image 1", updateData?.update_my_sola__image_1_url)}
                  {status === "rejected"
                    ? updateData?.image_2_url && getImageElement("Image 2", updateData?.image_2_url)
                    : updateData?.update_my_sola__image_2_url &&
                      getImageElement("Image 2", updateData?.update_my_sola__image_2_url)}
                  {status === "rejected"
                    ? updateData?.image_3_url && getImageElement("Image 3", updateData?.image_3_url)
                    : updateData?.update_my_sola__image_3_url &&
                      getImageElement("Image 3", updateData?.update_my_sola__image_3_url)}
                  {status === "rejected"
                    ? updateData?.image_4_url && getImageElement("Image 4", updateData?.image_4_url)
                    : updateData?.update_my_sola__image_4_url &&
                      getImageElement("Image 4", updateData?.update_my_sola__image_4_url)}
                  {status === "rejected"
                    ? updateData?.image_5_url && getImageElement("Image 5", updateData?.image_5_url)
                    : updateData?.update_my_sola__image_5_url &&
                      getImageElement("Image 5", updateData?.update_my_sola__image_5_url)}
                  {status === "rejected"
                    ? updateData?.image_6_url && getImageElement("Image 6", updateData?.image_6_url)
                    : updateData?.update_my_sola__image_6_url &&
                      getImageElement("Image 6", updateData?.update_my_sola__image_6_url)}
                  {status === "rejected"
                    ? updateData?.image_7_url && getImageElement("Image 7", updateData?.image_7_url)
                    : updateData?.update_my_sola__image_7_url &&
                      getImageElement("Image 7", updateData?.update_my_sola__image_7_url)}
                  {status === "rejected"
                    ? updateData?.image_8_url && getImageElement("Image 8", updateData?.image_8_url)
                    : updateData?.update_my_sola__image_8_url &&
                      getImageElement("Image 8", updateData?.update_my_sola__image_8_url)}
                  {status === "rejected"
                    ? updateData?.image_9_url && getImageElement("Image 9", updateData?.image_9_url)
                    : updateData?.update_my_sola__image_9_url &&
                      getImageElement("Image 9", updateData?.update_my_sola__image_9_url)}
                  {status === "rejected"
                    ? updateData?.image_10_url &&
                      getImageElement("Image 10", updateData?.image_10_url)
                    : updateData?.update_my_sola__image_10_url &&
                      getImageElement("Image 10", updateData?.update_my_sola__image_10_url)}
                </Grid>
              </AccordionDetails>
            </Accordion>
          </Grid>
        </Grid>
      </MDBox>
      {isPreview && (
        <ImagePreview
          imageData={imageToPreview}
          open={isPreview}
          onCloseImage={() => {
            closeImagePreview();
          }}
        />
      )}
    </MDDialog>
  );
}

export default ViewDetails;
