import { useEffect, useState } from "react";
import MDDialog from "components/MDDialog";
import { Grid } from "@mui/material";
import MDTypography from "components/MDTypography";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import MDBox from "components/MDBox";
import ImagePreview from "layouts/pages/ImagePreview";
import moment from "moment";
import { adminSuperAdmin } from "helper/services";

function ViewDetails(props: any): JSX.Element {
  const { open, updateData, onClose } = props;
  const [locationData, setLocationData] = useState<any>({});
  const [isPreview, setImagePreviewPopup] = useState(false);
  const [imageToPreview, setImageToPreview] = useState("");

  useEffect(() => {
    if (updateData) {
      setLocationData(updateData);
    }
  }, [updateData]);

  const dialogProps = {
    open,
    onClose,
    saveBtn: false,
    title: "View Location",
    size: "lg",
  };

  const previewImage = (image: any) => {
    setImagePreviewPopup(true);
    setImageToPreview(image);
  };

  const closeImagePreview = () => {
    setImagePreviewPopup(false);
    setImageToPreview("");
  };

  const getImageElement = (name: any, imageData: any) => {
    if (imageData) {
      return (
        <Grid item xs={12} sm={6} md={6}>
          <MDTypography fontWeight="bold" variant="button">
            {name}
          </MDTypography>
          <Grid container mt={2}>
            <Grid item xs={12} sm={6} md={6}>
              {imageData && (
                <Grid
                  container
                  onClick={() => previewImage(imageData)}
                  justifyContent="left"
                  alignItems="center"
                  display="flex"
                >
                  <Grid item className="thumbnail-img">
                    <img height={100} width={100} src={imageData} alt="location" />
                  </Grid>
                </Grid>
              )}
            </Grid>
          </Grid>
        </Grid>
      );
    }
    return null;
  };

  const getDetailElementWithDangersoulyHTML = (name: any, value: any) => (
    <Grid item xs={12} sm={12} md={12}>
      <MDTypography fontWeight="bold" variant="button">
        {name}
      </MDTypography>
      <MDBox
        sx={{ border: "1px solid #eee", overflow: "auto" }}
        p={2}
        dangerouslySetInnerHTML={{
          __html: value ? value : "-",
        }}
      />
    </Grid>
  );

  const getDetailElement = (name: any, value: any) => (
    <Grid item xs={12} sm={6} md={6}>
      <MDTypography fontWeight="bold" variant="button">
        {name}
      </MDTypography>
      <MDBox sx={{ wordBreak: "break-all" }}>{value ? value : "-"}</MDBox>
    </Grid>
  );

  const getAccordianSummary = (name: any) => {
    const labelName = name;
    return (
      <AccordionSummary className="location-accordion">
        <MDTypography>{labelName}</MDTypography>
      </AccordionSummary>
    );
  };

  return (
    <MDDialog {...dialogProps}>
      <Grid container spacing={2}>
        <Grid item xs={12} md={12}>
          <MDBox mt={1}>
            <MDBox>
              <Accordion expanded>
                {getAccordianSummary("General")}
                <AccordionDetails>
                  <Grid container spacing={2}>
                    {getDetailElement("Location Name", locationData?.name)}
                    {getDetailElement("URL Name", locationData?.url_name)}
                    {getDetailElementWithDangersoulyHTML("Description", locationData?.description)}
                    {adminSuperAdmin() && (
                      <>
                        {getDetailElement("MSA", locationData?.msa_name)}
                        {getDetailElement("Franchisee", locationData?.franchisee)}
                      </>
                    )}
                    {getDetailElement("Sola ID", locationData?.store_id)}
                    {getDetailElement("Status", locationData?.status)}
                    {/* Add two new fileds */}
                    {adminSuperAdmin() && (
                      <>
                        {getDetailElement("FBC", locationData?.fbc)}{" "}
                        {getDetailElement("Region", locationData?.region)}
                      </>
                    )}
                  </Grid>
                </AccordionDetails>
              </Accordion>
            </MDBox>
          </MDBox>
          <Accordion expanded>
            {getAccordianSummary("Contact")}
            <AccordionDetails>
              <Grid container spacing={2}>
                {getDetailElement("General Contact Name", locationData?.general_contact_name)}
                {getDetailElement(
                  "Email Address For Inquiries",
                  locationData?.email_address_for_inquiries
                )}
                {getDetailElement(
                  "Email Address For Reports",
                  locationData?.email_address_for_reports
                )}
                {getDetailElement(
                  "Email Address For Hubspot",
                  locationData?.email_address_for_hubspot
                )}
                {getDetailElement(
                  "Email Addresses For Stylist Website Approvals",
                  locationData?.emails_for_stylist_website_approvals
                )}
                {getDetailElement("Phone Number", locationData?.phone_number)}
              </Grid>
            </AccordionDetails>
          </Accordion>
          <Accordion expanded>
            {getAccordianSummary("Address")}
            <AccordionDetails>
              <Grid container spacing={2}>
                {getDetailElement("Address 1", locationData?.address_1)}
                {getDetailElement("Address 2", locationData?.address_2)}
                {getDetailElement("City", locationData?.city)}
                {getDetailElement("State/Province", locationData?.state)}
                {getDetailElement("Postal Code", locationData?.postal_code)}
                {getDetailElement("Country", locationData?.country)}
                {getDetailElement("Latitude", locationData?.latitude)}
                {getDetailElement("Longitude", locationData?.longitude)}
                {getDetailElement("Custom Maps URL", locationData?.custom_maps_url)}
              </Grid>
            </AccordionDetails>
          </Accordion>
          <Accordion expanded>
            {getAccordianSummary("Promotions")}
            <AccordionDetails>
              <Grid container spacing={2}>
                {getDetailElement("Move In Special", locationData?.move_in_special)}
                {getDetailElement("Special Callout", locationData?.move_in_special)}
              </Grid>
            </AccordionDetails>
          </Accordion>
          {
            <Accordion expanded>
              {getAccordianSummary("Social")}
              <AccordionDetails>
                <Grid container spacing={2}>
                  {getDetailElement("Facebook Url", locationData?.facebook_url)}
                  {getDetailElement("Instagram Url", locationData?.instagram_url)}
                  {/* {getDetailElement("Twitter Url", locationData?.twitter_url)} */}
                </Grid>
              </AccordionDetails>
            </Accordion>
          }
          <Accordion expanded>
            {getAccordianSummary("Tracking Code")}
            <AccordionDetails>
              <Grid container spacing={2}>
                {/* {getDetailElementWithDangersoulyHTML("Chat Code", locationData?.chat_code)} */}
                {getDetailElementWithDangersoulyHTML("Tracking Code", locationData?.tracking_code)}
              </Grid>
            </AccordionDetails>
          </Accordion>
          <Accordion expanded>
            {getAccordianSummary("Images")}
            <AccordionDetails>
              <Grid container spacing={2}>
                {locationData?.image_1_url && getImageElement("Image 1", locationData?.image_1_url)}
                {getDetailElement("Image 1 Alt Text", locationData?.image_1_alt_text)}
                {locationData?.image_2_url && getImageElement("Image 2", locationData?.image_2_url)}
                {getDetailElement("Image 2 Alt Text", locationData?.image_2_alt_text)}
                {locationData?.image_3_url && getImageElement("Image 3", locationData?.image_3_url)}
                {getDetailElement("Image 3 Alt Text", locationData?.image_3_alt_text)}
                {locationData?.image_4_url && getImageElement("Image 4", locationData?.image_4_url)}
                {getDetailElement("Image 4 Alt Text", locationData?.image_4_alt_text)}
                {locationData?.image_5_url && getImageElement("Image 5", locationData?.image_5_url)}
                {getDetailElement("Image 5 Alt Text", locationData?.image_5_alt_text)}
                {locationData?.image_6_url && getImageElement("Image 6", locationData?.image_6_url)}
                {getDetailElement("Image 6 Alt Text", locationData?.image_6_alt_text)}
                {locationData?.image_7_url && getImageElement("Image 7", locationData?.image_7_url)}
                {getDetailElement("Image 7 Alt Text", locationData?.image_7_alt_text)}
                {locationData?.image_8_url && getImageElement("Image 8", locationData?.image_8_url)}
                {getDetailElement("Image 8 Alt Text", locationData?.image_8_alt_text)}
                {locationData?.image_9_url && getImageElement("Image 9", locationData?.image_9_url)}
                {getDetailElement("Image 9 Alt Text", locationData?.image_9_alt_text)}
                {locationData?.image_10_url &&
                  getImageElement("Image 10", locationData?.image_10_url)}
                {getDetailElement("Image 10 Alt Text", locationData?.image_10_alt_text)}
                {locationData?.image_11_url &&
                  getImageElement("Image 11", locationData?.image_11_url)}
                {getDetailElement("Image 11 Alt Text", locationData?.image_11_alt_text)}
                {locationData?.image_12_url &&
                  getImageElement("Image 12", locationData?.image_12_url)}
                {getDetailElement("Image 12 Alt Text", locationData?.image_12_alt_text)}
                {locationData?.image_13_url &&
                  getImageElement("Image 13", locationData?.image_13_url)}
                {getDetailElement("Image 13 Alt Text", locationData?.image_13_alt_text)}
                {locationData?.image_14_url &&
                  getImageElement("Image 14", locationData?.image_14_url)}
                {getDetailElement("Image 14 Alt Text", locationData?.image_14_alt_text)}
                {locationData?.image_15_url &&
                  getImageElement("Image 15", locationData?.image_15_url)}
                {getDetailElement("Image 15 Alt Text", locationData?.image_15_alt_text)}
                {locationData?.image_16_url &&
                  getImageElement("Image 16", locationData?.image_16_url)}
                {getDetailElement("Image 16 Alt Text", locationData?.image_16_alt_text)}
                {locationData?.image_17_url &&
                  getImageElement("Image 17", locationData?.image_17_url)}
                {getDetailElement("Image 17 Alt Text", locationData?.image_17_alt_text)}
                {locationData?.image_18_url &&
                  getImageElement("Image 18", locationData?.image_18_url)}
                {getDetailElement("Image 18 Alt Text", locationData?.image_18_alt_text)}
                {locationData?.image_19_url &&
                  getImageElement("Image 19", locationData?.image_19_url)}
                {getDetailElement("Image 19 Alt Text", locationData?.image_19_alt_text)}
                {locationData?.image_20_url &&
                  getImageElement("Image 20", locationData?.image_20_url)}
                {getDetailElement("Image 20 Alt Text", locationData?.image_20_alt_text)}
              </Grid>
            </AccordionDetails>
          </Accordion>
          <Accordion expanded>
            {getAccordianSummary("Rockbot")}
            <AccordionDetails>
              <Grid container spacing={2}>
                {getDetailElement("Walk-Ins Enabled", locationData?.walkins_enabled ? "Yes" : "No")}
                {getDetailElement("Max Walk-Ins Time", locationData?.max_walkins_time)}
                {getDetailElement(
                  "Walk-Ins End Of Day",
                  moment(locationData?.walkins_end_of_day).utcOffset(0)?.format("HH:mm")
                )}
                {getDetailElement("Walk-Ins Time Zone", locationData?.walkins_timezone)}
                {locationData?.floorplan_image_url &&
                  getImageElement("Floorplan Image", locationData?.floorplan_image_url)}
                {getDetailElement("Rockbot Manager Email", locationData?.rockbot_manager_email)}
              </Grid>
            </AccordionDetails>
          </Accordion>
          <Accordion expanded>
            {getAccordianSummary("Rent Manager")}
            <AccordionDetails>
              <Grid container spacing={2}>
                {getDetailElement(
                  "Rent Manager Property ID",
                  locationData?.rent_manager_property_id
                )}
                {getDetailElement(
                  "Rent Manager Location ID",
                  locationData?.rent_manager_location_id
                )}
                {getDetailElement(
                  "Service Request Enabled",
                  locationData?.service_request_enabled ? "Yes" : "No"
                )}
              </Grid>
            </AccordionDetails>
          </Accordion>
          <Accordion expanded>
            {getAccordianSummary("Tours360")}
            <AccordionDetails>
              <Grid container spacing={2}>
                {getDetailElementWithDangersoulyHTML("Tour #1", locationData?.tour_iframe_1)}
                {getDetailElementWithDangersoulyHTML("Tour #2", locationData?.tour_iframe_2)}
                {getDetailElementWithDangersoulyHTML("Tour #3", locationData?.tour_iframe_3)}
              </Grid>
            </AccordionDetails>
          </Accordion>
          <Accordion expanded>
            {getAccordianSummary("Text and Email Integration")}
            <AccordionDetails>
              <Grid container spacing={2}>
                {getDetailElement("Mailchimp List Ids", locationData?.mailchimp_list_ids)}
                {getDetailElement("Callfire List Ids", locationData?.callfire_list_ids)}
              </Grid>
            </AccordionDetails>
          </Accordion>
        </Grid>
      </Grid>
      {isPreview && (
        <ImagePreview
          imageData={imageToPreview}
          open={isPreview}
          onCloseImage={() => {
            closeImagePreview();
          }}
        />
      )}
    </MDDialog>
  );
}

export default ViewDetails;
