import { Grid, Paper } from "@mui/material";
import MDBox from "components/MDBox";
import MDInput from "components/MDInput";
import MDTypography from "components/MDTypography";

function PersonalWebsite(props: any): JSX.Element {
  const { accountDetail, onChangeField } = props;
  return (
    <Paper>
      <MDBox p={2} mt={2}>
        <MDBox mb={1}>
          <MDTypography variant="h5" fontWeight="regular">
            Personal Website
          </MDTypography>
        </MDBox>
        <Grid item md={12} container spacing={3}>
          <Grid item md={6} sm={6} xs={12}>
            <MDInput
              label="Website URL"
              variant="standard"
              fullWidth
              name="website_url"
              onChange={onChangeField}
              placeholder="Website URL"
              value={accountDetail?.website_url || ""}
            />
          </Grid>
          <Grid item md={6} sm={6} xs={12}>
            <MDInput
              label="Booking URL"
              variant="standard"
              fullWidth
              name="booking_url"
              onChange={onChangeField}
              placeholder="Booking URL"
              value={accountDetail?.booking_url || ""}
            />
          </Grid>
        </Grid>
      </MDBox>
    </Paper>
  );
}

export default PersonalWebsite;
