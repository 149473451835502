import MDDialog from "components/MDDialog";
import MDBox from "components/MDBox";
import CustomAutoSearch from "components/CustomAutoSearch";
import { Grid } from "@mui/material";
import MDButton from "components/MDButton";
import { useAppSelector, useAppDispatch } from "store/store";
import { useEffect, useState } from "react";
import { rmExistInDb } from "store/thunk/locationThunk";

function ViewDetails(props: any): JSX.Element {
  const dispatch = useAppDispatch();
  const { open, onClose, locationId, cmsLocationId } = props;
  const { rentManagerProperties } = useAppSelector((state) => state.locationSlice);
  const [selectedNumber, setSelectedNumber] = useState<any>(null);
  const [errors] = useState<any>({});

  useEffect(() => {
    if (rentManagerProperties) {
      setSelectedNumber(rentManagerProperties);
    }
  }, []);

  const onChangeAutoComplete = (name: string, value: any) => {
    setSelectedNumber({ ...selectedNumber, [name]: value });
  };

  const onSend = () => {
    const req = {
      id: selectedNumber?.property_name?.id,
      location_id: Number(locationId),
      cms_location_id: Number(cmsLocationId),
      onClose,
    };
    // api call for link to existing
    dispatch(rmExistInDb(req));
  };

  const dialogProps = {
    open,
    onClose,
    onSave: () => onSend(),
    saveBtn: true,
    title: "Rent Manager Properties",
    size: "lg",
    overflowVisible: "overflowVisible",
    saveTbtText: "Link to Rm",
  };

  return (
    <MDDialog {...dialogProps}>
      <MDBox borderRadius="xl" alignItems="center" />
      <Grid container spacing={2} mt={1}>
        <Grid item xs={6} sm={6} md={6} lg={6}>
          <CustomAutoSearch
            required
            slsAPI
            idKey="id"
            nameKey="property_name"
            fieldLabel="Rent Manager"
            apiName="rent_manager_properties"
            responseKey="data"
            name="property_name"
            error={errors && errors.property_name}
            onChange={onChangeAutoComplete}
            value={selectedNumber?.property_name || null}
          />
        </Grid>
        <Grid item xs={6} sm={6} md={6} lg={6}>
          <MDButton
            variant="gradient"
            color="info"
            fullWidth
            type="submit"
            sx={{ textTransform: "capitalize", fontSize: "14px" }}
          >
            Search
          </MDButton>
        </Grid>
      </Grid>
    </MDDialog>
  );
}

export default ViewDetails;
