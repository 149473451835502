import { useEffect } from "react";
import MDDialog from "components/MDDialog";
import MDTypography from "components/MDTypography";
import MDBox from "components/MDBox";
import Grid from "@mui/material/Grid";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Accordion, AccordionDetails, AccordionSummary } from "@mui/material";
import CustomChipList from "components/CustomChipList";
import { useAppDispatch, useAppSelector } from "store/store";
import { getAssociationDetails } from "store/thunk/associationThunk";

function ViewDetails(props: any): JSX.Element {
  const { open, onClose, associationData } = props;
  const dispatch = useAppDispatch();
  const { associationDetails } = useAppSelector((state: any) => state.associationSlice);

  useEffect(() => {
    if (associationData && associationData?.id) {
      dispatch(getAssociationDetails({ id: associationData?.id }));
    }
  }, []);

  const dialogProps = {
    open,
    onClose,
    saveBtn: false,
    title: "View Association",
    size: "md",
  };

  return (
    <MDDialog {...dialogProps}>
      <MDBox pb={2}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12} md={12}>
            <Accordion expanded>
              <AccordionSummary
                className="location-accordion"
                expandIcon={<ExpandMoreIcon />}
                aria-controls="publishbh-content"
              >
                <MDTypography variant="h6">Basic Info</MDTypography>
              </AccordionSummary>
              <AccordionDetails>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={12} md={12}>
                    <MDTypography fontWeight="bold" variant="button">
                      Association Name
                    </MDTypography>
                    <MDBox>
                      <MDTypography variant="button">
                        {associationDetails?.association_name
                          ? associationDetails.association_name
                          : "-"}
                      </MDTypography>
                    </MDBox>
                  </Grid>
                  <Grid item xs={12} sm={12} md={12}>
                    <MDTypography fontWeight="bold" variant="button">
                      Association Description
                    </MDTypography>
                    <MDBox>
                      <MDTypography variant="button">
                        {associationDetails?.association_description
                          ? associationDetails.association_description
                          : "-"}
                      </MDTypography>
                    </MDBox>
                  </Grid>
                </Grid>
              </AccordionDetails>
            </Accordion>
          </Grid>
          <Grid item xs={12} sm={12} md={12}>
            <Accordion expanded>
              <AccordionSummary
                className="location-accordion"
                expandIcon={<ExpandMoreIcon />}
                aria-controls="publishbh-content"
              >
                <MDTypography variant="h6">Business Info</MDTypography>
              </AccordionSummary>
              <AccordionDetails>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={12} md={12}>
                    <MDTypography fontWeight="bold" variant="button">
                      Business Name
                    </MDTypography>
                    <MDBox>
                      <MDTypography variant="button">
                        {associationDetails?.bussiness_name
                          ? associationDetails.bussiness_name
                          : "-"}
                      </MDTypography>
                    </MDBox>
                  </Grid>
                  <Grid item xs={12} sm={12} md={12}>
                    <MDTypography fontWeight="bold" variant="button">
                      State of Incorporation
                    </MDTypography>
                    <MDBox>
                      <MDTypography variant="button">
                        {associationDetails?.incorporation_state
                          ? associationDetails.incorporation_state
                          : "-"}
                      </MDTypography>
                    </MDBox>
                  </Grid>
                  <Grid item xs={12} sm={12} md={12}>
                    <MDTypography fontWeight="bold" variant="button">
                      Notice Address 1
                    </MDTypography>
                    <MDBox>
                      <MDTypography variant="button">
                        {associationDetails?.notice_address_1
                          ? associationDetails.notice_address_1
                          : "-"}
                      </MDTypography>
                    </MDBox>
                  </Grid>
                  <Grid item xs={12} sm={12} md={12}>
                    <MDTypography fontWeight="bold" variant="button">
                      Notice Address 2
                    </MDTypography>
                    <MDBox>
                      <MDTypography variant="button">
                        {associationDetails?.notice_address_2
                          ? associationDetails.notice_address_2
                          : "-"}
                      </MDTypography>
                    </MDBox>
                  </Grid>
                  <Grid item xs={12} sm={12} md={12}>
                    <MDTypography fontWeight="bold" variant="button">
                      Notice City
                    </MDTypography>
                    <MDBox>
                      <MDTypography variant="button">
                        {associationDetails?.notice_city ? associationDetails.notice_city : "-"}
                      </MDTypography>
                    </MDBox>
                  </Grid>
                  <Grid item xs={12} sm={12} md={12}>
                    <MDTypography fontWeight="bold" variant="button">
                      Notice State / Province
                    </MDTypography>
                    <MDBox>
                      <MDTypography variant="button">
                        {associationDetails?.notice_state ? associationDetails.notice_state : "-"}
                      </MDTypography>
                    </MDBox>
                  </Grid>
                  <Grid item xs={12} sm={12} md={12}>
                    <MDTypography fontWeight="bold" variant="button">
                      Notice Postal Code
                    </MDTypography>
                    <MDBox>
                      <MDTypography variant="button">
                        {associationDetails?.notice_postal_code
                          ? associationDetails.notice_postal_code
                          : "-"}
                      </MDTypography>
                    </MDBox>
                  </Grid>
                </Grid>
              </AccordionDetails>
            </Accordion>
          </Grid>
          <Grid item xs={12} sm={12} md={12}>
            <Accordion expanded>
              <AccordionSummary
                className="location-accordion"
                expandIcon={<ExpandMoreIcon />}
                aria-controls="publishbh-content"
              >
                <MDTypography variant="h6">Other</MDTypography>
              </AccordionSummary>
              <AccordionDetails>
                <CustomChipList name="Locations" listData={associationDetails?.locations} />
                <CustomChipList
                  name="Owners"
                  listData={associationDetails?.owners?.map((elem: any) => ({
                    title: elem.login_email,
                  }))}
                />
                <CustomChipList
                  name="User"
                  listData={associationDetails?.users?.map((elem: any) => ({
                    title: elem.login_email,
                  }))}
                />
                <Grid item xs={12} sm={12} md={12}>
                  <MDTypography fontWeight="bold" variant="button">
                    Country
                  </MDTypography>
                  <MDBox>
                    <MDTypography variant="button">
                      {associationDetails?.country?.name ? associationDetails?.country?.name : "-"}
                    </MDTypography>
                  </MDBox>
                </Grid>
              </AccordionDetails>
            </Accordion>
          </Grid>
        </Grid>
      </MDBox>
    </MDDialog>
  );
}

export default ViewDetails;
