import React, { useState } from "react";

// react-router-dom components
import { Link } from "react-router-dom";

// @mui material components
import Card from "@mui/material/Card";

// Material Dashboard 2 PRO React TS components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";

// import { Auth, Hub } from "aws-amplify";
// import { CognitoHostedUIIdentityProvider } from "@aws-amplify/auth";

// Authentication layout components
import CoverLayout from "layouts/authentication/components/CoverLayout";

// Images
import bgImage from "assets/images/bg-sign-in-cover.jpeg";
import Validations from "helper/validations";
import CustomPasswordInput from "components/CustomPasswordInput";
import { LoginRequest } from "../../../../types/custom-types";
// import SimpleLoader from "../../../../components/MDLoader/simpleLoader";

import { useAppDispatch } from "../../../../store/store";
import { login } from "../../../../store/thunk/authThunk";

function Cover(): JSX.Element {
  const appDispatch = useAppDispatch();
  // const notificationInfo = useAppSelector((state) => state.notificationReducer);
  const [email, setEmail] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  // const [loading, setLoading] = useState<boolean>(false);
  // const [googleLoading, setGoogleLoading] = useState<boolean>(false);
  // const [rscText] = useState<string>("");
  const [errors, setErrors] = useState<any>({});
  // useEffect(() => {
  //   if (notificationInfo.message) {
  //     setLoading(false);
  //   }
  //   if (notificationInfo.status) {
  //     window.location.replace("/dashboard");
  //   }
  // }, [notificationInfo]);

  /* useEffect(() => {
    Hub.listen("auth", ({ payload: { event } }) => {
      switch (event) {
        case "signIn":
          appDispatch(SSOLogin());
          break;
        default:
          break;
      }
    });
  }, []); */

  const onLogin = (e: any) => {
    e.preventDefault();
    const requestData: LoginRequest = {
      email,
      password,
    };
    const error = Validations.validateLoginForm(requestData);
    setErrors(error);
    if (!Object.keys(error).length) {
      appDispatch(login(requestData));
    }
  };

  // login in with google
  /* const googleLogin = async () => {
    localStorage.setItem("sso_login", String(true));
    setGoogleLoading(true);
    await Auth.federatedSignIn({
      provider: CognitoHostedUIIdentityProvider.Google,
      customState: rscText,
    });
  }; */

  return (
    <CoverLayout image={bgImage} versionInfo="1.0.0">
      <Card>
        <MDBox
          variant="gradient"
          bgColor="info"
          borderRadius="lg"
          coloredShadow="success"
          mx={2}
          mt={-3}
          p={3}
          mb={1}
          textAlign="center"
        >
          <MDTypography variant="h4" fontWeight="medium" color="white" mt={1}>
            Sign In
          </MDTypography>
          <MDTypography display="block" variant="button" color="white" my={1}>
            Enter your email and password to sign in
          </MDTypography>
        </MDBox>
        <MDBox pt={4} pb={3} px={3}>
          <MDBox component="form" role="form" onSubmit={(e) => onLogin(e)}>
            <MDBox mb={2}>
              <MDInput
                type="email"
                label="Email"
                variant="standard"
                fullWidth
                placeholder="john@example.com"
                InputLabelProps={{ shrink: true }}
                name="email"
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => setEmail(e.target.value)}
                value={email}
                error={(errors && errors.email) || false}
                helperText={errors && errors.email}
                required
              />
            </MDBox>
            <MDBox mb={2}>
              <CustomPasswordInput
                label="Password"
                name="password"
                value={password}
                placeholder="************"
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => setPassword(e.target.value)}
                error={errors && errors.password ? true : false}
                helperText={errors && errors.password}
                required
              />
            </MDBox>
            <MDBox>
              <MDTypography
                component={Link}
                to="/forgot-password"
                variant="button"
                color="info"
                fontWeight="medium"
                textGradient
              >
                Forgot my password
              </MDTypography>
            </MDBox>
            <MDBox mt={4} mb={1}>
              <MDButton
                variant="gradient"
                color="info"
                fullWidth
                type="submit"
                sx={{ textTransform: "capitalize", fontSize: "14px" }}
              >
                Sign in
              </MDButton>
            </MDBox>
            {/* <MDBox mt={1} mb={1}>
              <MDButton
                variant="gradient"
                color="info"
                fullWidth
                type="button"
                disabled={googleLoading}
                onClick={() => googleLogin()}
              >
                {googleLoading ? <SimpleLoader size={20} /> : "Sign in with Google"}
              </MDButton>
            </MDBox> */}
            {/* <MDBox mt={2} mb={1} textAlign="center">
              <MDTypography variant="button" color="text">
                Don&apos;t have an account?{" "}
                <MDTypography
                  component={Link}
                  to="/sign-up"
                  variant="button"
                  color="info"
                  fontWeight="medium"
                  textGradient
                >
                  Sign up
                </MDTypography>
              </MDTypography>
            </MDBox> */}
          </MDBox>
        </MDBox>
      </Card>
    </CoverLayout>
  );
}

export default Cover;
