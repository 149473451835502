import { useState } from "react";
import { Card, FormControlLabel, Grid, Switch } from "@mui/material";
import MDBox from "components/MDBox";
import MDInput from "components/MDInput";
import MDLabelValue from "components/MDLabelValue";
import MDTypography from "components/MDTypography";
import { getShortName } from "helper/services";

function BusinessInfo(props: any): JSX.Element {
  const { onChangeField, errors, setRmDetails, rmDetails, getAccessTypePermission } = props;
  const [infoSwitch, setInfoSwitch] = useState(rmDetails?.use_association_information || false);

  const onchangeSwitch = (event: any) => {
    setInfoSwitch(event.target.checked);
    const tempRM = JSON.parse(JSON.stringify(rmDetails));
    tempRM.use_association_information = event.target.checked;
    setRmDetails(tempRM);
  };

  return (
    <Card id="business-info" sx={{ overflow: "visible" }}>
      <MDBox p={3}>
        <MDTypography variant="h5">Business Info</MDTypography>
      </MDBox>
      <MDBox component="form" px={3} pb={2}>
        <MDBox mt={2}>
          <MDTypography variant="h6">General</MDTypography>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={12}>
              <MDLabelValue label="Sola ID" value={rmDetails?.store_id} />
              <MDLabelValue
                label="Full Name"
                value={`${rmDetails?.store_id} - ${rmDetails?.name}`}
              />
              <MDLabelValue label="Short Name" value={`${getShortName(rmDetails?.name)}`} />
              <MDLabelValue
                label="Address"
                value={`${rmDetails?.address_1} ${rmDetails?.address_2}, ${rmDetails?.state}`}
              />{" "}
            </Grid>
            <Grid item xs={12} sm={6}>
              <MDTypography variant="h6">Master Landlord</MDTypography>
              <MDInput
                label="Master Landlord"
                variant="standard"
                fullWidth
                name="master_landlord"
                onChange={onChangeField}
                value={rmDetails?.master_landlord || ""}
                error={errors && errors.master_landlord ? true : false}
                helperText={errors && errors.master_landlord}
                required
                disabled={getAccessTypePermission("location_rentmanager", "edit_access")}
              />
            </Grid>
          </Grid>
        </MDBox>
        <MDBox mt={4}>
          <Grid container mt={1}>
            <Grid item xs={12} sm={12} className="manager">
              <MDInput
                label="Lease Manager"
                variant="standard"
                fullWidth
                name="manager"
                onChange={onChangeField}
                value={rmDetails?.manager || ""}
                error={errors && errors.manager ? true : false}
                helperText={errors && errors.manager}
                required
                sx={{ mb: 1 }}
                disabled={getAccessTypePermission("location_rentmanager", "edit_access")}
              />
            </Grid>
          </Grid>
        </MDBox>
        <MDBox mt={4}>
          <MDTypography variant="h6">Business information</MDTypography>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={12}>
              <FormControlLabel
                control={
                  <Switch
                    checked={infoSwitch || false}
                    onChange={onchangeSwitch}
                    inputProps={{ "aria-label": "controlled" }}
                    disabled={getAccessTypePermission("location_rentmanager", "edit_access")}
                  />
                }
                label="Use Association information"
                labelPlacement="end"
              />
            </Grid>
            {infoSwitch && (
              <Grid item xs={12} sm={12}>
                <MDLabelValue label="Business Name" value={`${rmDetails?.business_name}`} />
                <MDLabelValue
                  label="State of Incorporation"
                  value={`${rmDetails?.incorporation_state}`}
                />
                <MDLabelValue label="Notice Address" value={`${rmDetails?.notice_address}`} />
              </Grid>
            )}
            {!infoSwitch && (
              <Grid item xs={12} sm={12}>
                <MDInput
                  label="Business Name"
                  variant="standard"
                  fullWidth
                  name="business_name"
                  onChange={onChangeField}
                  value={rmDetails?.business_name || ""}
                  error={errors && errors.business_name ? true : false}
                  helperText={errors && errors.business_name}
                  required
                  sx={{ mb: 1 }}
                  disabled={getAccessTypePermission("location_rentmanager", "edit_access")}
                />
                <MDInput
                  label="State of Incorporation"
                  variant="standard"
                  fullWidth
                  name="incorporation_state"
                  onChange={onChangeField}
                  value={rmDetails?.incorporation_state || ""}
                  error={errors && errors.incorporation_state ? true : false}
                  helperText={errors && errors.incorporation_state}
                  required
                  sx={{ mb: 1 }}
                  disabled={getAccessTypePermission("location_rentmanager", "edit_access")}
                />
                <MDInput
                  label="Notice Address"
                  variant="standard"
                  fullWidth
                  name="notice_address"
                  onChange={onChangeField}
                  value={rmDetails?.notice_address || ""}
                  error={errors && errors.notice_address ? true : false}
                  helperText={errors && errors.notice_address}
                  required
                  sx={{ mb: 1 }}
                  disabled={getAccessTypePermission("location_rentmanager", "edit_access")}
                />
              </Grid>
            )}
          </Grid>
        </MDBox>
      </MDBox>
    </Card>
  );
}

export default BusinessInfo;
