import { Grid, Icon, Paper } from "@mui/material";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import CustomDropZone from "components/CustomDropZone";
import MDIconButton from "components/MDIconButton";
import { v4 as uuidv4 } from "uuid";

function Images(props: any): JSX.Element {
  const { accountDetail, onImagesChange, onImageRemove } = props;
  return (
    <Paper>
      <MDBox MDBox p={2} mt={2}>
        <MDBox>
          <MDBox mb={1}>
            <MDTypography variant="h5" fontWeight="regular">
              Images
            </MDTypography>
          </MDBox>
        </MDBox>
        <MDBox>
          <Grid container spacing={3}>
            <Grid item xs={12} md={12}>
              <CustomDropZone
                multipleUpload
                acceptedFiles={{
                  "image/*": [".gif", ".png", ".jpg", ".jpeg"],
                }}
                onDrop={onImagesChange}
              >
                Drop your image here, or Browse
              </CustomDropZone>
            </Grid>
            <Grid item xs={12} md={12}>
              <MDBox>
                <Grid sx={{ flexGrow: 1 }} container spacing={2}>
                  <Grid item xs={12}>
                    <Grid container justifyContent="center" spacing={1}>
                      {accountDetail?.images &&
                        accountDetail?.images.length > 0 &&
                        accountDetail?.images.map((imgSrc: string, index: number) => (
                          <Grid key={uuidv4()} item className="imageWithDeleteIcon">
                            <img width="100" height="100" src={imgSrc} alt="account images" />
                            <MDIconButton
                              tooltipName="Delete"
                              aria-label="Delete"
                              color="error"
                              className="removeBtnClass"
                              onClick={(e: any) => onImageRemove(e, index)}
                            >
                              <Icon fontSize="small">close</Icon>
                            </MDIconButton>
                          </Grid>
                        ))}
                    </Grid>
                  </Grid>
                </Grid>
              </MDBox>
            </Grid>
          </Grid>
        </MDBox>
      </MDBox>
    </Paper>
  );
}

export default Images;
