import Checkbox from "@mui/material/Checkbox";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormGroup from "@mui/material/FormGroup";
import MDBox from "components/MDBox";
import MDInput from "components/MDInput";
import MDTypography from "components/MDTypography";

function Step3(props: any): JSX.Element {
  const { onChangeField, answers, errors } = props;
  return (
    <>
      <MDBox mt={5}>
        <MDTypography className="helper-info-texts" variant="body" fontWeight="bold">
          11. What is the full location address where Rockbot will be installed?
        </MDTypography>
        <MDTypography className="helper-info-texts" variant="span" paragraph>
          Please include any suite or special address
        </MDTypography>
        <MDInput
          placeholder="Type your answer here..."
          variant="standard"
          className="colorWhite"
          fullWidth
          InputLabelProps={{ shrink: true }}
          name="location_installed"
          required
          onChange={onChangeField}
          value={answers?.location_installed || ""}
          error={errors && errors.location_installed ? true : false}
          helperText={errors && errors.location_installed}
        />
      </MDBox>
      <MDBox mt={2}>
        <MDTypography className="helper-info-texts" variant="body" fontWeight="bold">
          12. What is the best shipping address for your Rockbot player?
        </MDTypography>
        <MDTypography className="helper-info-texts" variant="span" paragraph>
          Write &quot;same&quot; if it is to the location address, add &quot;attention to&quot; name
          if the name is different than yours
        </MDTypography>
        <MDInput
          placeholder="Type your answer here..."
          variant="standard"
          className="colorWhite"
          fullWidth
          InputLabelProps={{ shrink: true }}
          name="shiping_address"
          required
          onChange={onChangeField}
          value={answers?.shiping_address || ""}
          error={errors && errors.shiping_address ? true : false}
          helperText={errors && errors.shiping_address}
        />
      </MDBox>
      <MDBox mt={2}>
        <MDTypography className="helper-info-texts" variant="body" fontWeight="bold">
          13. What is your preferred shipping method?
        </MDTypography>
        <MDTypography className="helper-info-texts" variant="span" paragraph>
          Note: These shipping times below are after the order processing is complete. Due to the
          global chip shortage, processing times to get hardware are running between 6-8 weeks and
          subject to change.
        </MDTypography>
        <FormControl component="fieldset">
          <FormGroup aria-label="position" row>
            <FormControlLabel
              value="5-7-business-days"
              control={
                <Checkbox
                  checked={
                    answers?.shiping_method && answers?.shiping_method.includes("5-7-business-days")
                      ? true
                      : false
                  }
                  name="shiping_method"
                  onChange={onChangeField}
                  inputProps={{ "aria-label": "controlled" }}
                />
              }
              label="$22 Ground (5-7 Business Days)"
              labelPlacement="end"
              className="checkbox-while-lable"
            />
            <FormControlLabel
              value="45-2-day-shipping"
              control={
                <Checkbox
                  checked={
                    answers?.shiping_method && answers?.shiping_method.includes("45-2-day-shipping")
                      ? true
                      : false
                  }
                  name="shiping_method"
                  onChange={onChangeField}
                  inputProps={{ "aria-label": "controlled" }}
                />
              }
              label="$45 2-Day Shipping"
              labelPlacement="end"
              className="checkbox-while-lable"
            />
            <FormControlLabel
              value="90_overnight_shipping"
              control={
                <Checkbox
                  checked={
                    answers?.shiping_method &&
                    answers?.shiping_method.includes("90_overnight_shipping")
                      ? true
                      : false
                  }
                  name="shiping_method"
                  onChange={onChangeField}
                  inputProps={{ "aria-label": "controlled" }}
                />
              }
              label="$90 Overnight Shipping"
              labelPlacement="end"
              className="checkbox-while-lable"
            />
          </FormGroup>
        </FormControl>
      </MDBox>
      <MDBox mt={2}>
        <MDTypography className="helper-info-texts" variant="body" fontWeight="bold">
          14. What is your preferred payment method?
        </MDTypography>
        <MDTypography className="helper-info-texts" variant="span" paragraph>
          Rockbot offers payment via ACH and Credit Card
        </MDTypography>
        <FormControl component="fieldset">
          <FormGroup aria-label="position" row>
            <FormControlLabel
              value="Central Billing"
              control={
                <Checkbox
                  checked={
                    answers?.payment_method && answers?.payment_method.includes("Central Billing")
                      ? true
                      : false
                  }
                  name="payment_method"
                  onChange={onChangeField}
                  inputProps={{ "aria-label": "controlled" }}
                />
              }
              label="Central Billing"
              labelPlacement="end"
              className="checkbox-while-lable"
            />
            <FormControlLabel
              value="Separate Billing For Each Salon"
              control={
                <Checkbox
                  checked={
                    answers?.payment_method &&
                    answers?.payment_method.includes("Separate Billing For Each Salon")
                      ? true
                      : false
                  }
                  name="payment_method"
                  onChange={onChangeField}
                  inputProps={{ "aria-label": "controlled" }}
                />
              }
              label="Separate Billing For Each Salon"
              labelPlacement="end"
              className="checkbox-while-lable"
            />
          </FormGroup>
        </FormControl>
      </MDBox>
    </>
  );
}

export default Step3;
