import { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "store/store";
import { useAsyncDebounce } from "react-table";

import Board from "@asseinfo/react-kanban";

import parse from "html-react-parser";
// @mui material components
import Icon from "@mui/material/Icon";
import { Theme } from "@mui/material/styles";
import InfoIcon from "@mui/icons-material/Info";
import Tooltip from "@mui/material/Tooltip";

// Material Dashboard 2 PRO React TS components
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDDialog from "components/MDDialog";

// Material Dashboard 2 PRO React TS examples components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";

// icons
import AppsIcon from "@mui/icons-material/Apps";
import ViewListIcon from "@mui/icons-material/ViewList";

// Material Dashboard 2 PRO React context
import { useMaterialUIController } from "context";
import { setBoardData } from "store/slices/dealSlice";
import {
  Tabs,
  Tab,
  InputAdornment,
  Checkbox,
  Grid,
  FormControl,
  IconButton,
  FormControlLabel,
} from "@mui/material";
import MDDatePicker from "components/MDDatePicker";
import {
  editContactIquiries,
  getLeads,
  leadStatusUpdate,
  SendLeaseApplication,
  updateLeadTour,
} from "store/thunk/leaseThunk";
import {
  getAccessPermission,
  // getAllAssociationLocations,
  // getAssociationLocations,
  adminSuperAdmin,
  getCRMCreateAccess,
  getAuthUser,
  onlyUniqueArray,
  getMessageCountTexts,
  getAccessLocationPermission,
} from "helper/services";
import { notificationFail } from "store/slices/notificationSlice";
import { setLeadDetails, setLeaseConversation, setLeads } from "store/slices/leaseSlice";
import MESSAGES from "helper/messages";
import CustomAutoSearch from "components/CustomAutoSearch";
import CustomSelect from "components/CustomSelect";
import CustomBackButton from "components/CustomBackButton";
import CustomRequiredLabel from "components/CustomRequiredLabel";
import CustomErrorMessage from "components/CustomErrorMessage";
import Validations from "helper/validations";
import constants from "helper/constants";
import moment from "moment";
import ListView from "./components/List";
import CreateBroacastPopup from "../broadcast/createBroacastPopup";

// Data
import boards from "./data";
import CardItem from "./components/Card/cardItem";
import AddUpdate from "./addUpdate";
import StartLease from "./startLease";

const authUser = getAuthUser();

const {
  servicesFilterOptions,
  studioTypeOptions,
  studioPreferenceOptions,
  interestedSharingOptions,
  leadComingFromOptions,
  reasonClosingLeadOptions,
  messageStatusOptions,
  sortByOptions,
  optOutOptions,
} = constants;

function Index(): JSX.Element {
  const navigate = useNavigate();
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;
  const { boardData, statusArray } = useAppSelector((state) => state.dealSlice);
  const { leads } = useAppSelector((state) => state.leaseSlice);
  const { loading } = useAppSelector((state) => state.authReducer);
  const dispatch = useAppDispatch();
  const { state }: any = useLocation();

  const [newCardForm, setNewCardForm] = useState<string | number | boolean>(false);
  const [addUpdatePopup, setAddUpdatePopup] = useState<boolean>(false);
  const [statusStartLease, setStatusStartLease] = useState<boolean>(false);
  const [startLeasePopUp, setStartLeasePopUp] = useState<any>(false);
  const [fullCardDatas, setFullCardDatas] = useState<any>(false);
  const [notSent, setNotSent] = useState<any>(false);
  const [formValue, setFormValue] = useState<string>("");
  const [leadSearch, setLeadSearch] = useState<string>("");
  const [serviceFilter, setServiceFilter] = useState<any>(null);
  const [studioTypeFilter, setStudioTypeFilter] = useState<any>(null);
  const [studioPreferenceFilter, setStudioPreferenceFilter] = useState<any>(null);
  const [leadComingFilter, setLeadComingFilter] = useState<any>(null);
  const [sharingStudioFilter, setSharingStudioFilter] = useState<any>(null);
  const [reasonClosingFilter, setReasonClosingFilter] = useState<any>(null);
  const [competitorNameFilter, setCompetitorNameFilter] = useState<any>(null);
  const [board, setBoard] = useState<any>(boards);
  const [draggedCard, setDraggedCard] = useState<any>(null);
  const [gridView, setGridView] = useState<number>(0);

  const [pageCount, setPageCount] = useState(0);
  const [totalCount, setTotalCount] = useState(0);
  const [pageNo, setPageNo] = useState(0);
  const [perPage] = useState(10);
  const [openBroadCastPopup, setOpenBroadCastPopup] = useState(false);
  const [crmLeads, setCrmLeads] = useState<any>([]);
  const [defaultOptions] = useState<any>([{ value: "viewall", label: "View All" }]);
  const [onChangeClick, setOnChangeClick] = useState<boolean>(false);
  const [leadTourPopUp, setLeadTourPopUp] = useState<any>(false);
  const [moveToWaitlistPopUp, setMoveToWaitlist] = useState<any>(false);
  const [markClosedPopUp, setMarkClosedPopUp] = useState<any>(false);
  const [waitlistDate, setWaitlistDate] = useState<any>(null);
  const [studioType, setStudioType] = useState<any>(null);
  const [studioPreference, setStudioPreference] = useState<any>(null);
  const [interestedSharing, setInterestedSharing] = useState<any>(null);
  const [leadComingFrom, setLeadComingFrom] = useState<any>(null);
  const [reasonClosingLead, setReasonClosingLead] = useState<any>(null);
  const [errors, setErrors] = useState<any>({});
  const [competitorName, setCompetitorName] = useState<any>("");
  const [showFilter, setShowFilter] = useState<any>(false);
  const [messageStatusFilter, setMessageStatusFilter] = useState(null);
  const [sortByFilter, setSortByFilter] = useState(null);
  const [dateCreatedFilter, setDateCreatedFilter] = useState<any>(null);
  const [dateClosedFilter, setDateClosedFilter] = useState<any>(null);
  const [myDeals, setMyDeals] = useState<boolean>(false);
  const [statusFilter, setStatusFilter] = useState<any>([]);
  const [filterChanged, setFilterChanged] = useState<any>(false);
  const [resubmittedDeals, setResubmittedDeals] = useState<boolean>(false);

  const closeNewCardForm = () => setNewCardForm(false);
  const handelSetFormValue = ({ currentTarget }: any) => setFormValue(currentTarget.value);
  const [optOutFilter, setOptOutFilter] = useState(null);
  const [dealPhoneFilter, setDealPhoneFilter] = useState<any>(null);
  useEffect(() => {
    if (!moveToWaitlistPopUp) {
      setWaitlistDate(null);
      setStudioType(null);
      setStudioPreference(null);
      setInterestedSharing(null);
      setLeadComingFrom(null);
    }
    if (!markClosedPopUp) {
      setReasonClosingLead(null);
      setCompetitorName("");
    }
  }, [moveToWaitlistPopUp, markClosedPopUp]);

  const onCardDrag = (cardData: any) => {
    setDraggedCard(cardData || null);
  };

  useEffect(() => {
    if (state && state?.data && state.data.locations) {
      const originalLeads: any[] = [
        ...new Map(
          [...crmLeads, ...state.data.locations].map((obj: any) => [obj?.cms_location_id, obj])
        ).values(),
      ];
      setCrmLeads(originalLeads);
    }
    if (state?.data?.statusData && state?.data?.statusData?.length) {
      setStatusFilter(state?.data?.statusData);
    }
    if (state && state?.data && state?.data?.search) {
      setLeadSearch(state?.data?.search);
    }
    if (state && state?.data?.serviceData) {
      setServiceFilter(state?.data?.serviceData);
    }
    if (state && state?.data?.studioTypeFilter?.length) {
      setStudioTypeFilter(state?.data?.studioTypeFilter);
    }
    if (state && state?.data?.studioPreferenceFilter?.length) {
      setStudioPreferenceFilter(state?.data?.studioPreferenceFilter);
    }
    if (state && state?.data?.leadComingFilter?.length) {
      setLeadComingFilter(state?.data?.leadComingFilter);
    }
    if (
      state?.data?.reasonClosingFilter &&
      Object.entries(state?.data?.reasonClosingFilter).length
    ) {
      setReasonClosingFilter(state?.data?.reasonClosingFilter);
    }
    if (
      state?.data?.sharingStudioFilter &&
      Object.entries(state?.data?.sharingStudioFilter).length
    ) {
      setSharingStudioFilter(state?.data?.sharingStudioFilter);
    }
    if (state && state?.data?.competitorNameFilter) {
      setCompetitorNameFilter(state?.data?.competitorNameFilter);
    }
    if (state && state?.data?.messageStatusFilter) {
      setMessageStatusFilter(state?.data?.messageStatusFilter);
    }
    if (state && state?.data?.sortByFilter) {
      setSortByFilter(state?.data?.sortByFilter);
    }
    if (state && state?.data?.dateCreatedFilter) {
      setDateCreatedFilter(state?.data?.dateCreatedFilter);
    }
    if (state && state?.data?.dateClosedFilter) {
      setDateClosedFilter(state?.data?.dateClosedFilter);
    }
    if (state && state?.data?.viewMyDeals) {
      setMyDeals(state?.data?.viewMyDeals);
    }
    if (state && state?.data && state?.data?.optOutFilter) {
      setOptOutFilter(state?.data?.optOutFilter);
    }
    if (state?.data?.dealPhoneFilter) {
      setDealPhoneFilter(state?.data?.dealPhoneFilter);
    }
    if (state && state?.data?.resubmittedDeals) {
      setResubmittedDeals(true);
    }
    window.history.replaceState(null, "");
  }, [state]);

  const handleDateChange = (date: any, flag: any) => {
    const utcDate = date && date.length ? moment(new Date(date)).format("YYYY-MM-DD") : null;
    if (flag === "date_created") {
      setDateCreatedFilter(utcDate);
    }
    if (flag === "date_closed") {
      setDateClosedFilter(utcDate);
    }
  };

  const getLeadData = (locationsIf?: any, page?: any, size?: any) => {
    let locationIds: any = [];
    if (crmLeads && crmLeads.length) {
      crmLeads.map((val: any) => {
        if (val.cms_location_id !== "viewall") {
          locationIds.push(val.cms_location_id);
        }
        return true;
      });
    }

    if (locationsIf && locationsIf?.length) {
      locationsIf.map((val: any) => {
        if (val.cms_location_id !== "viewall") {
          locationIds.push(val.cms_location_id);
        }
        return true;
      });
    }

    locationIds = [...new Set(locationIds)];
    const tempPageIndex = page !== undefined ? page : pageNo;
    const request: any = {
      type: "Request leasing information",
      pageIndex: tempPageIndex,
      pageSize: size || perPage,
      leads: tempPageIndex ? leads : null,
      sort: [
        {
          id: "id",
          desc: true,
        },
      ],
    };
    if (
      (studioTypeFilter && studioTypeFilter?.length) ||
      (state && state?.data?.studioTypeFilter?.length)
    ) {
      request.studioTypes = studioTypeFilter?.length
        ? studioTypeFilter
        : state?.data?.studioTypeFilter;
    }
    if (
      (studioPreferenceFilter && studioPreferenceFilter?.length) ||
      (state && state?.data?.studioPreferenceFilter?.length)
    ) {
      request.studioPreferance = studioPreferenceFilter?.length
        ? studioPreferenceFilter
        : state?.data?.studioPreferenceFilter;
    }
    if (
      (leadComingFilter && leadComingFilter?.length) ||
      (state && state?.data?.leadComingFilter?.length)
    ) {
      request.leadComingFrom = leadComingFilter?.length
        ? leadComingFilter
        : state?.data?.leadComingFilter;
    }
    if (sharingStudioFilter?.length || state?.data?.sharingStudioFilter?.length) {
      request.sharingStudio = sharingStudioFilter?.length
        ? sharingStudioFilter
        : state?.data?.sharingStudioFilter;
    }
    if (
      optOutFilter ||
      (state?.data?.optOutFilter && Object.keys(state?.data?.optOutFilter)?.length)
    ) {
      request.optOut = optOutFilter ? optOutFilter : state?.data?.optOutFilter;
    }
    if (reasonClosingFilter?.length || state?.data?.reasonClosingFilter?.length) {
      request.reasonCloseLead = reasonClosingFilter?.length
        ? reasonClosingFilter
        : state?.data?.reasonClosingFilter;
    }
    if (serviceFilter?.length || state?.data?.serviceData) {
      request.services = serviceFilter?.length ? serviceFilter : state?.data?.serviceData;
    }
    if (competitorNameFilter || state?.data?.competitorNameFilter) {
      request.competitorName = competitorNameFilter || state?.data?.competitorNameFilter;
    }
    if (leadSearch || state?.data?.search) {
      request.search = leadSearch || state?.data?.search;
    }
    if (messageStatusFilter || state?.data?.messageStatusFilter) {
      request.messageStatus = messageStatusFilter || state?.data?.messageStatusFilter;
    }
    if (sortByFilter?.value || state?.data?.sortByFilter?.value) {
      let spitSortValue = sortByFilter?.value || state?.data?.sortByFilter?.value;
      if (spitSortValue) {
        spitSortValue = spitSortValue.split("__");
      }
      if (spitSortValue?.[0] && spitSortValue?.[1]) {
        request.sort = [{ id: spitSortValue[0], desc: spitSortValue[1] === "desc" ? true : false }];
      }
    }
    if (statusFilter?.length || state?.data?.statusData) {
      request.status = statusFilter?.length
        ? statusFilter.map((v: any) => v.status)
        : state?.data?.statusData?.map((v: any) => v.status);
    }
    if (dateCreatedFilter || state?.data?.dateCreatedFilter) {
      request.dateCreated = dateCreatedFilter ? dateCreatedFilter : state?.data?.dateCreatedFilter;
    }
    if (dateClosedFilter || state?.data?.dateClosedFilter) {
      request.dateClosed = dateClosedFilter ? dateClosedFilter : state?.data?.dateClosedFilter;
    }
    if (locationIds.length && !state?.data?.locations) {
      request.locationId = locationIds;
    } else if (!state?.data || state?.data?.statusData) {
      request.locationId = locationIds?.length
        ? locationIds
        : getAccessLocationPermission("crm", "crm_deals", "", "view_access").get_location_by_ids; // SD-3070 change related permission
      // : getAssociationLocations().get_location_by_ids;
    } else if (onChangeClick) {
      // request.locationId = getAssociationLocations().get_location_by_ids;
      // SD-3070 change related permission
      request.locationId = getAccessLocationPermission(
        "crm",
        "crm_deals",
        "",
        "view_access"
      ).get_location_by_ids;
    }
    request.statusWise = gridView ? false : true;
    if (myDeals || state?.data?.viewMyDeals) {
      request.dealOwnerId = authUser?.user_id;
    }

    if (dealPhoneFilter || state?.data?.dealPhoneFilter) {
      request.dealContactNumber = dealPhoneFilter || state?.data?.dealPhoneFilter;
    }
    if (resubmittedDeals || state?.data?.resubmittedDeals) {
      request.isDealResubmitted = true;
    }
    dispatch(getLeads(request));
  };

  useEffect(() => {
    if (gridView === 0) {
      setPageCount(0);
      setTotalCount(0);
      setPageNo(0);
      dispatch(setLeads(null));
      dispatch(setLeadDetails(null));
      dispatch(setLeaseConversation(null));
      getLeadData(state?.data?.locations?.length ? state?.data?.locations : [], 0, 10);
    }
  }, [gridView]);

  useEffect(() => {
    if (leads) {
      setPageCount(leads?.meta?.total_pages);
      setTotalCount(leads?.meta?.total_count);
      setPageNo(leads?.meta?.current_page);
      // setPerPage(leads?.meta?.current_count);
      if (leads.leads && leads.leads.length > 0) {
        const newStatus = leads.leads.filter((value: any) => value.status === "New");
        const AttemptingToConnectStatus = leads.leads.filter(
          (value: any) => value.status === "Attempting to Connect"
        );
        const connectedStatus = leads.leads.filter((value: any) => value.status === "Connected");
        const tourScheduledStatus = leads.leads.filter(
          (value: any) => value.status === "Tour Scheduled"
        );
        const tourCompletedStatus = leads.leads.filter(
          (value: any) => value.status === "Tour Completed"
        );
        const closedLeaseStatus = leads.leads.filter(
          (value: any) => value.status === "Closed Leased"
        );
        const closedStatus = leads.leads.filter((value: any) => value.status === "Closed");
        const waitlistStatus = leads.leads.filter((value: any) => value.status === "Waitlist");
        const allCards = { ...board };
        let newBoard = [];
        if (allCards && Object.keys(allCards).length > 0) {
          newBoard = allCards.columns.map((val: any) => {
            const alltickets = { ...val };
            const objNew: any = [...alltickets.cards];
            switch (alltickets.title) {
              case "New":
                newStatus.map((v: any) => {
                  const checkExists = objNew.filter((val: any) => val.id === v.id);
                  if (checkExists.length === 0) {
                    objNew.push({
                      id: v.id,
                      template: (
                        <CardItem
                          title={v.deal_name}
                          isDealLock={v.deal_locked}
                          locationId={v.location_id}
                          locationName={v?.location?.name}
                          email={v?.connect_contact && v?.connect_contact?.email}
                          phone={v?.connect_contact && v?.connect_contact?.phone}
                          content={v.last_conversation || "No Message Preview"}
                          messageCount={getMessageCountTexts(v)}
                          messageStatus={v.message_status}
                          conversionCount={Number(v?.conversation_count || 0)}
                          smsRequestStatus={v?.location?.connect_twillio_sms_request?.status || ""}
                          connectDealNotes={v?.connect_deal_notes}
                        />
                      ),
                    });
                  }
                  return true;
                });
                alltickets.cards = objNew;
                break;

              case "Attempting To Connect":
                AttemptingToConnectStatus.map((v: any) => {
                  const checkExists = objNew.filter((val: any) => val.id === v.id);
                  if (checkExists.length === 0) {
                    objNew.push({
                      id: v.id,
                      template: (
                        <CardItem
                          title={v.deal_name}
                          isDealLock={v.deal_locked}
                          locationId={v.location_id}
                          locationName={v?.location?.name}
                          email={v?.connect_contact && v?.connect_contact?.email}
                          phone={v?.connect_contact && v?.connect_contact?.phone}
                          content={v.last_conversation || "No Message Preview"}
                          messageCount={getMessageCountTexts(v)}
                          messageStatus={v.message_status}
                          conversionCount={Number(v?.conversation_count || 0)}
                          smsRequestStatus={v?.location?.connect_twillio_sms_request?.status || ""}
                          connectDealNotes={v?.connect_deal_notes}
                        />
                      ),
                    });
                  }
                  return true;
                });
                alltickets.cards = objNew;
                break;

              case "Connected":
                connectedStatus.map((v: any) => {
                  const checkExists = objNew.filter((val: any) => val.id === v.id);
                  if (checkExists.length === 0) {
                    objNew.push({
                      id: v.id,
                      template: (
                        <CardItem
                          title={v.deal_name}
                          isDealLock={v.deal_locked}
                          locationId={v.location_id}
                          locationName={v?.location?.name}
                          email={v?.connect_contact && v?.connect_contact?.email}
                          phone={v?.connect_contact && v?.connect_contact?.phone}
                          content={v.last_conversation || "No Message Preview"}
                          messageCount={getMessageCountTexts(v)}
                          messageStatus={v.message_status}
                          conversionCount={Number(v?.conversation_count || 0)}
                          smsRequestStatus={v?.location?.connect_twillio_sms_request?.status || ""}
                          connectDealNotes={v?.connect_deal_notes}
                        />
                      ),
                    });
                  }
                  return true;
                });
                alltickets.cards = objNew;
                break;

              case "Tour Scheduled":
                tourScheduledStatus.map((v: any) => {
                  const checkExists = objNew.filter((val: any) => val.id === v.id);
                  if (checkExists.length === 0) {
                    objNew.push({
                      id: v.id,
                      template: (
                        <CardItem
                          title={v.deal_name}
                          isDealLock={v.deal_locked}
                          locationId={v.location_id}
                          locationName={v?.location?.name}
                          email={v?.connect_contact && v?.connect_contact?.email}
                          phone={v?.connect_contact && v?.connect_contact?.phone}
                          content={v.last_conversation || "No Message Preview"}
                          messageCount={getMessageCountTexts(v)}
                          messageStatus={v.message_status}
                          conversionCount={Number(v?.conversation_count || 0)}
                          smsRequestStatus={v?.location?.connect_twillio_sms_request?.status || ""}
                          connectDealNotes={v?.connect_deal_notes}
                        />
                      ),
                    });
                  }
                  return true;
                });
                alltickets.cards = objNew;
                break;

              case "Tour Completed":
                tourCompletedStatus.map((v: any) => {
                  const checkExists = objNew.filter((val: any) => val.id === v.id);
                  if (checkExists.length === 0) {
                    objNew.push({
                      id: v.id,
                      template: (
                        <CardItem
                          title={v.deal_name}
                          isDealLock={v.deal_locked}
                          locationId={v.location_id}
                          locationName={v?.location?.name}
                          email={v?.connect_contact && v?.connect_contact?.email}
                          phone={v?.connect_contact && v?.connect_contact?.phone}
                          content={v.last_conversation || "No Message Preview"}
                          messageCount={getMessageCountTexts(v)}
                          messageStatus={v.message_status}
                          conversionCount={Number(v?.conversation_count || 0)}
                          smsRequestStatus={v?.location?.connect_twillio_sms_request?.status || ""}
                          connectDealNotes={v?.connect_deal_notes}
                        />
                      ),
                    });
                  }
                  return true;
                });
                alltickets.cards = objNew;
                break;

              case "Closed Leased":
                closedLeaseStatus.map((v: any) => {
                  const checkExists = objNew.filter((val: any) => val.id === v.id);
                  if (checkExists.length === 0) {
                    objNew.push({
                      id: v.id,
                      template: (
                        <CardItem
                          title={v.deal_name}
                          isDealLock={v.deal_locked}
                          locationId={v.location_id}
                          locationName={v?.location?.name}
                          email={v?.connect_contact && v?.connect_contact?.email}
                          phone={v?.connect_contact && v?.connect_contact?.phone}
                          content={v.last_conversation || "No Message Preview"}
                          messageCount={getMessageCountTexts(v)}
                          messageStatus={v.message_status}
                          conversionCount={Number(v?.conversation_count || 0)}
                          smsRequestStatus={v?.location?.connect_twillio_sms_request?.status || ""}
                          connectDealNotes={v?.connect_deal_notes}
                        />
                      ),
                    });
                  }
                  return true;
                });
                alltickets.cards = objNew;
                break;

              case "Closed":
                closedStatus.map((v: any) => {
                  const checkExists = objNew.filter((val: any) => val.id === v.id);
                  if (checkExists.length === 0) {
                    objNew.push({
                      id: v.id,
                      template: (
                        <CardItem
                          title={v.deal_name}
                          isDealLock={v.deal_locked}
                          locationId={v.location_id}
                          locationName={v?.location?.name}
                          email={v?.connect_contact && v?.connect_contact?.email}
                          phone={v?.connect_contact && v?.connect_contact?.phone}
                          content={v.last_conversation || "No Message Preview"}
                          messageCount={getMessageCountTexts(v)}
                          messageStatus={v.message_status}
                          conversionCount={Number(v?.conversation_count || 0)}
                          smsRequestStatus={v?.location?.connect_twillio_sms_request?.status || ""}
                          connectDealNotes={v?.connect_deal_notes}
                        />
                      ),
                    });
                  }
                  return true;
                });
                alltickets.cards = objNew;
                break;

              case "Waitlist":
                waitlistStatus.map((v: any) => {
                  const checkExists = objNew.filter((val: any) => val.id === v.id);
                  if (checkExists.length === 0) {
                    objNew.push({
                      id: v.id,
                      template: (
                        <CardItem
                          title={v.deal_name}
                          isDealLock={v.deal_locked}
                          locationId={v.location_id}
                          locationName={v?.location?.name}
                          email={v?.connect_contact && v?.connect_contact?.email}
                          phone={v?.connect_contact && v?.connect_contact?.phone}
                          content={v.last_conversation || "No Message Preview"}
                          messageCount={getMessageCountTexts(v)}
                          messageStatus={v.message_status}
                          conversionCount={Number(v?.conversation_count || 0)}
                          smsRequestStatus={v?.location?.connect_twillio_sms_request?.status || ""}
                          connectDealNotes={v?.connect_deal_notes}
                        />
                      ),
                    });
                  }
                  return true;
                });
                alltickets.cards = objNew;
                break;

              default:
                return alltickets;
            }
            return alltickets;
          });
        } else {
          newBoard = [];
        }
        dispatch(setBoardData({ columns: newBoard }));
      } else if (Object.keys(boardData).length) {
        const existingData = boardData?.columns
          ? JSON.parse(JSON.stringify(boardData.columns))
          : [];
        const emptyColumn = existingData.map((val: any) => {
          Object.assign(val, { cards: [] });
          return val;
        });
        dispatch(setBoardData({ columns: emptyColumn }));
      }
    }
  }, [leads]);

  useEffect(() => {
    if (Object.keys(boardData).length) {
      setBoard(boardData);
    }
  }, [boardData]);

  useEffect(() => {
    dispatch(setBoardData({ ...boards }));
  }, []);

  const onSendLater = (value: any) => {
    const props = value?.board?.template?.props;
    const split = props.title.split(" ");
    const firstname = split.slice(0, split.length - 1).join(" ");
    const lastname = split.pop();
    const objData = {
      first_name: firstname,
      last_name: lastname,
      email: props.email,
      locationId: props.locationId,
      name: props.locationName,
      phone: props.phone,
    };
    const req = {
      leaseData: objData,
      leadId: value?.board?.id,
      leadStatus: "Closed Leased",
      isNotSend: true,
      setDraggedCard,
    };
    dispatch(SendLeaseApplication(req));
  };

  const onCardMoved = () => {
    if (draggedCard) {
      const sorceStage = statusArray.find(
        (val: any) => val.value === draggedCard.source.toColumnId
      );

      if (sorceStage.value === "closed_lease" && false) {
        // added && false for SD-2839 hide temporary until elease release
        if (notSent) {
          onSendLater(draggedCard);
          setNotSent(false);
        } else {
          setStartLeasePopUp(draggedCard?.board?.template?.props);
          setFullCardDatas(draggedCard);
        }
      } else {
        const newBoard = board?.columns.map((obj: any) => {
          const column = { ...obj };
          if (obj.id === draggedCard?.card?.fromColumnId) {
            column.cards = column.cards.filter((card: any) => card.id !== draggedCard?.board?.id);
          }
          if (obj.id === draggedCard?.source?.toColumnId) {
            const tempCards: any = [];
            if (column.cards && column.cards.length) {
              column.cards.map((card: any, index: number) => {
                if (draggedCard?.source?.toPosition === index) {
                  tempCards.push(draggedCard?.board);
                }
                tempCards.push(card);
                return true;
              });
              if (!tempCards.find((card: any) => card.id === draggedCard?.board?.id)) {
                tempCards.push(draggedCard?.board);
              }
            } else {
              tempCards.push(draggedCard?.board);
            }
            column.cards = tempCards;
          }
          return column;
        });

        dispatch(
          leadStatusUpdate({
            leadId: draggedCard.board.id,
            leadStatus: sorceStage.status,
            userId: authUser.user_id,
          })
        );
        dispatch(setBoardData({ columns: newBoard }));
      }
    }
    setDraggedCard(null);
  };

  useEffect(() => {
    if (notSent) {
      onCardMoved();
    }
  }, [notSent]);

  const checkModalOpen = () => {
    let open = false;
    if (draggedCard && !leadTourPopUp) {
      open = true;
    }
    return open;
  };

  const dialogProps = {
    open: () => checkModalOpen(),
    onSave: () => onCardMoved(),
    onClose: () => {
      setDraggedCard(null);
      setStatusStartLease(false);
    },
    title: "Status Change",
    saveTbtText: statusStartLease ? "Yes, Start Application" : "Yes",
    closeBtnText: "No",
    size: "sm",
    additionalBtn: statusStartLease ? true : false,
    additionalBtnText: "Yes, Send later",
    additionalBtnColor: "light",
    onAdditionalBtnSave: () => {
      setNotSent(true);
    },
  };

  const leadTourDialogProps = {
    open: () => leadTourPopUp,
    onSave: () => {
      dispatch(updateLeadTour({ leadId: draggedCard.board.id, leadTour: true }));
      if (draggedCard.source.toColumnId === "closed_lease") {
        // setStatusStartLease(true); // SD-2839 hide temporary until elease release
      }
      if (draggedCard.source.toColumnId === "waitlist") {
        setStatusStartLease(false);
        setMoveToWaitlist(true);
      }
      if (draggedCard.source.toColumnId === "closed") {
        setStatusStartLease(false);
        setMarkClosedPopUp(true);
      }
      setLeadTourPopUp(false);
    },
    onClose: () => {
      setDraggedCard(null);
      setStatusStartLease(false);
      setLeadTourPopUp(false);
    },
    onCloseFunc: () => {
      dispatch(updateLeadTour({ leadId: draggedCard.board.id, leadTour: false }));
      setLeadTourPopUp(false);
      if (draggedCard.source.toColumnId === "closed_lease") {
        // setStatusStartLease(true); // SD-2839 hide temporary until elease release
      }
      if (draggedCard.source.toColumnId === "waitlist") {
        setStatusStartLease(false);
        setMoveToWaitlist(true);
      }
      if (draggedCard.source.toColumnId === "closed") {
        setStatusStartLease(false);
        setMarkClosedPopUp(true);
      }
    },
    title: "Tour",
    saveTbtText: "Yes",
    closeBtnText: "No",
    size: "sm",
  };

  const onSaveWaitlist = () => {
    const error = Validations.validateWaitlistPopup({ waitlistDate });
    setErrors(error);
    if (!Object.keys(error).length) {
      dispatch(
        editContactIquiries({
          leadInfo: {
            id: draggedCard.board.id,
          },
          studioType,
          studioPreference,
          waitlistDate: waitlistDate
            ? moment(waitlistDate[0]).add(moment().utcOffset(), "minutes").toISOString()
            : null,
          leadComingFrom,
          interestedSharing,
          setMoveToWaitlist,
        })
      );
    }
  };

  const moveToWaitlistDialogProps = {
    open: () => moveToWaitlistPopUp,
    onSave: () => onSaveWaitlist(),
    onClose: () => setMoveToWaitlist(false),
    title: "Move to Waitlist",
    saveTbtText: "Save",
    closeBtnText: "Close",
    size: "sm",
    overflowVisible: "overflowVisible",
  };

  const onSaveMarkClosed = () => {
    const error = Validations.validateMarkClosedPopup({ reasonClosingLead });
    setErrors(error);
    if (!Object.keys(error).length) {
      dispatch(
        editContactIquiries({
          leadInfo: {
            id: draggedCard.board.id,
          },
          reasonClosingLead,
          competitorName,
          setMarkClosedPopUp,
        })
      );
    }
  };

  const markClosedDialogProps = {
    open: () => markClosedPopUp,
    onSave: () => {
      onSaveMarkClosed();
    },
    onClose: () => setMarkClosedPopUp(false),
    title: "Mark Closed",
    saveTbtText: "Save",
    closeBtnText: "Close",
    size: "sm",
    overflowVisible: "overflowVisible",
  };

  const handleViewChange = (event: any, value: any) => {
    setGridView(value);
  };

  const closeModal = () => {
    setAddUpdatePopup(false);
    setStartLeasePopUp(false);
  };

  const onChangeAutoComplete = (name: string, value: any) => {
    setOnChangeClick(true);
    if (value.cms_location_id === "viewall") {
      getLeadData();
    } else {
      setCrmLeads(value);
    }
    setFilterChanged(true);
  };

  const onSearchChange = useAsyncDebounce(() => {
    setFilterChanged(false);
    setShowFilter(false);
    setPageCount(0);
    setTotalCount(0);
    setPageNo(0);
    const temp = { ...boards };
    dispatch(setBoardData(temp));
    const request: any = {
      type: "Request leasing information",
      locationId: crmLeads.cms_location_id || "",
      search: leadSearch,
      sort: [
        {
          id: "id",
          desc: true,
        },
      ],
    };
    if (serviceFilter && serviceFilter?.length) {
      request.services = serviceFilter;
    }
    if (studioTypeFilter && studioTypeFilter?.length) {
      request.studioTypes = studioTypeFilter;
    }
    if (studioPreferenceFilter && studioPreferenceFilter?.length) {
      request.studioPreferance = studioPreferenceFilter;
    }
    if (leadComingFilter && leadComingFilter?.length) {
      request.leadComingFrom = leadComingFilter;
    }
    if (sharingStudioFilter) {
      request.sharingStudio = sharingStudioFilter;
    }
    if (optOutFilter) {
      request.optOut = optOutFilter;
    }
    if (reasonClosingFilter) {
      request.reasonCloseLead = reasonClosingFilter;
    }
    if (competitorNameFilter) {
      request.competitorName = competitorNameFilter;
    }
    if (crmLeads && crmLeads?.length) {
      const filteredLocations = crmLeads.filter((inx: any) => inx.cms_location_id !== "viewall");
      request.locations = filteredLocations.map((obj: any) => obj.cms_location_id);
    }
    if (dateCreatedFilter) {
      request.dateCreated = dateCreatedFilter;
    }
    if (dateClosedFilter) {
      request.dateClosed = dateClosedFilter;
    }
    if (myDeals) {
      request.dealOwnerId = authUser?.user_id;
    }
    if (messageStatusFilter) {
      request.messageStatus = messageStatusFilter;
    }
    if (sortByFilter?.value) {
      let spitSortValue = sortByFilter?.value;
      if (spitSortValue) {
        spitSortValue = spitSortValue.split("__");
      }
      if (spitSortValue?.[0] && spitSortValue?.[1]) {
        request.sort = [{ id: spitSortValue[0], desc: spitSortValue[1] === "desc" ? true : false }];
      }
    }
    if (statusFilter?.length) {
      request.status = statusFilter.map((v: any) => v.status);
    }
    if (dealPhoneFilter) {
      request.dealContactNumber = dealPhoneFilter;
    }
    if (resubmittedDeals) {
      request.isDealResubmitted = true;
    }
    request.statusWise = true;
    request.pageIndex = 0;
    request.pageSize = gridView ? 100 : 10;
    dispatch(getLeads(request));
  }, 1000);

  const getAccessValue = (locationId: any, accessType: any) =>
    getAccessPermission(locationId, "crm", "crm_deals", "", accessType);

  const getViewble = (id: any, template: any) => {
    if (getAccessValue(template.props?.locationId, "view_access")) {
      dispatch(setLeaseConversation(null));
      const stateData = {
        leads: [...crmLeads],
        status: statusFilter,
        serviceFilter,
        studioTypeFilter,
        studioPreferenceFilter,
        leadComingFilter,
        reasonClosingFilter,
        sharingStudioFilter,
        competitorNameFilter,
        leadSearch,
        messageStatusFilter,
        dateCreatedFilter,
        dateClosedFilter,
        myDeals,
        sortByFilter,
        optOutFilter,
        dealPhoneFilter,
        resubmittedDeals,
        ...template.props,
      };
      navigate(`/crm/sola-pro-leads`, {
        state: { data: stateData, id },
        replace: true,
      });
    } else {
      dispatch(notificationFail(MESSAGES.ERROR.LEAD_NOT_ABLE_TO_VIEW_ERROR));
    }
  };

  const [broadCastDealsArray, setBroadCastDealsArray] = useState([]);
  const addToBroadCast = (e: any, dealId: any) => {
    e.stopPropagation();
    if (broadCastDealsArray) {
      let tempArray = JSON.parse(JSON.stringify(broadCastDealsArray));
      if (e.target.checked) {
        tempArray.push(dealId);
      } else {
        tempArray = tempArray.filter((item: any) => item !== dealId);
      }
      setBroadCastDealsArray(tempArray);
    }
  };
  const addAllCardsToBroadCast = (e: any, cards: any) => {
    let oldBroadCastArray = JSON.parse(JSON.stringify(broadCastDealsArray));
    const cardIds = cards.map((card: any) => card.id);
    if (e.target.checked) {
      oldBroadCastArray = oldBroadCastArray.concat(cardIds).filter(onlyUniqueArray);
    } else {
      oldBroadCastArray = oldBroadCastArray.filter((el: any) => !cardIds.includes(el));
    }
    setBroadCastDealsArray(oldBroadCastArray);
  };
  const checkAllBroadCastIds = (status: any) => {
    let checked = false;
    if (leads?.leads?.length > 0) {
      const statusWiseDealsIds = leads?.leads
        .filter((deal: any) => deal?.status?.toLowerCase() === status?.toLowerCase())
        .map((card: any) => card?.id);
      if (
        statusWiseDealsIds?.length > 0 &&
        broadCastDealsArray?.length > 0 &&
        statusWiseDealsIds?.every((elem: any) => broadCastDealsArray?.includes(elem))
      ) {
        checked = true;
      }
    }
    return checked;
  };
  const onOpenBroadCastPopup = () => {
    setOpenBroadCastPopup(true);
  };

  // const checkStatusWiseSelcted = (status: any) => {
  //   console.log("status:", status);
  //   let checked = false;
  //   if (leads?.leads?.length > 0) {
  //     const statusWiseDealsIds = leads.leads
  //       .filter((deal: any) => deal.status.toLowerCase() === status.toLowerCase())
  //       .map((card: any) => card.id);
  //     if (statusWiseDealsIds.length > 0 && broadCastDealsArray.length > 0) {
  //       const found = statusWiseDealsIds.some((r: any) => broadCastDealsArray.indexOf(r) >= 0);
  //       if (found) {
  //         checked = true;
  //       }
  //     }
  //   }
  //   return checked;
  // };

  const getEditable = (locationId: any) => getAccessValue(locationId, "edit_access");

  const clearFilter = () => {
    setPageNo(0);
    setLeadSearch("");
    setShowFilter(false);
    setCrmLeads([]);
    setServiceFilter(null);
    setCompetitorNameFilter("");
    setStudioTypeFilter(null);
    setStudioPreferenceFilter(null);
    setLeadComingFilter(null);
    setSharingStudioFilter(null);
    setReasonClosingFilter(null);
    setStatusFilter([]);
    setMessageStatusFilter(null);
    setDateCreatedFilter(null);
    setDateClosedFilter(null);
    setMyDeals(false);
    setSortByFilter(null);
    setOptOutFilter(null);
    setDealPhoneFilter(null);
    setResubmittedDeals(false);
    onSearchChange();
  };

  return (
    <DashboardLayout>
      {openBroadCastPopup && (
        <CreateBroacastPopup
          open={openBroadCastPopup}
          selectedDeals={broadCastDealsArray}
          allLeads={leads}
          clearBroadcastArray={setBroadCastDealsArray}
          onClose={() => {
            setOpenBroadCastPopup(false);
          }}
        />
      )}
      <DashboardNavbar />
      <MDBox className="deals-board-change-view">
        {/* <MDBox>
          <Autocomplete
            options={createdFilterOptions}
            sx={{ width: "200px" }}
            getOptionLabel={(option: any) => option?.name || option}
            id="createdFilter"
            value={createdFilter}
            onChange={(e: any, newValue: any) => {
              setCreatedFilter(newValue);
            }}
            renderOption={(props: any, option: any) => (
              <li {...props} key={option.value}>
                {option.name}
              </li>
            )}
            renderInput={(params: any) => (
              <TextField {...params} variant="standard" label="Create Date" placeholder="" />
            )}
          />
        </MDBox> */}
        <MDBox sx={{ display: "flex", marginTop: "5px" }}>
          <MDInput
            variant="outlined"
            sx={{ marginRight: "5px" }}
            fullWidth
            className="height45"
            name="search"
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <Icon fontSize="medium">search</Icon>
                </InputAdornment>
              ),
            }}
            onChange={(e: any) => {
              if (!loading) {
                setLeadSearch(e.target.value);
                onSearchChange();
              }
            }}
            placeholder="Search Leads..."
            value={leadSearch}
          />
        </MDBox>
        <IconButton
          onClick={() => {
            setShowFilter(!showFilter);
          }}
        >
          <Icon>filter_list</Icon>
        </IconButton>
        {showFilter && (
          <>
            <MDBox
              className="crm-location-header-wrapper"
              onClick={() => {
                if (filterChanged) {
                  onSearchChange();
                }
                setShowFilter(false);
              }}
            />
            <MDBox
              className="crm-location-header"
              display={showFilter ? "inline-flex" : "none"}
              sx={{ maxWidth: "350px" }}
            >
              <MDBox sx={{ display: "inline" }}>
                <IconButton
                  className="close-filter-button"
                  onClick={() => {
                    if (filterChanged) {
                      onSearchChange();
                    }
                    setShowFilter(false);
                  }}
                >
                  <Icon>close</Icon>
                </IconButton>
              </MDBox>
              <MDBox sx={{ display: "inline", mt: 4 }}>
                <CustomAutoSearch
                  slsAPI
                  multiple
                  idKey="cms_location_id"
                  nameKey="name"
                  parentKey="location"
                  apiName="get-locations"
                  responseKey="data"
                  name="location"
                  defaultOptions={defaultOptions}
                  // usersData={getAllAssociationLocations()}
                  usersData={getAccessLocationPermission("crm", "crm_deals", "", "view_access")}
                  value={
                    crmLeads && Object.entries(crmLeads).length
                      ? crmLeads
                      : defaultOptions[0]
                      ? defaultOptions.map((v: any) => ({
                          cms_location_id: v.value,
                          name: v.label,
                        }))
                      : null
                  }
                  onChange={onChangeAutoComplete}
                />
              </MDBox>
              <MDBox sx={{ display: "inline" }}>
                <CustomSelect
                  multiple
                  onChange={(val: any) => {
                    setStatusFilter(val);
                    setFilterChanged(true);
                  }}
                  name="status"
                  placeholder="Select Status"
                  value={Object.keys(statusFilter).length > 0 ? statusFilter : null}
                  id="status"
                  options={statusArray.map((obj: any) => {
                    const tempObj = { ...obj };
                    tempObj.label = tempObj.status;
                    return tempObj;
                  })}
                  isClearable
                />
              </MDBox>
              <MDBox sx={{ display: "inline" }}>
                <CustomSelect
                  onChange={(value: any) => {
                    setServiceFilter(value);
                    setFilterChanged(true);
                  }}
                  name="services"
                  fieldLabel=""
                  placeholder="Services"
                  value={serviceFilter || []}
                  id="services"
                  options={servicesFilterOptions.map((obj: any) => {
                    const tempObj = obj;
                    tempObj.label = tempObj.name;
                    return tempObj;
                  })}
                  multiple
                  isClearable
                />
              </MDBox>
              <MDBox sx={{ display: "inline" }}>
                <CustomSelect
                  onChange={(value: any) => {
                    setStudioTypeFilter(value);
                    setFilterChanged(true);
                  }}
                  name="studio_type"
                  fieldLabel=""
                  placeholder="Studio Type"
                  value={studioTypeFilter || []}
                  id="studio_type"
                  options={studioTypeOptions}
                  multiple
                  isClearable
                />
              </MDBox>
              <MDBox sx={{ display: "inline" }}>
                <CustomSelect
                  onChange={(value: any) => {
                    setStudioPreferenceFilter(value);
                    setFilterChanged(true);
                  }}
                  name="studio_preference"
                  fieldLabel=""
                  placeholder="Studio Preference"
                  value={studioPreferenceFilter || []}
                  id="studio_preference"
                  options={studioPreferenceOptions}
                  multiple
                  isClearable
                />
              </MDBox>
              <MDBox sx={{ display: "inline" }}>
                <CustomSelect
                  onChange={(value: any) => {
                    setLeadComingFilter(value);
                    setFilterChanged(true);
                  }}
                  name="where_is_lead_coming_from"
                  fieldLabel=""
                  placeholder="Where is Lead Coming From"
                  value={leadComingFilter || []}
                  id="where_is_lead_coming_from"
                  options={leadComingFromOptions}
                  multiple
                  isClearable
                />
              </MDBox>
              <MDBox sx={{ display: "inline" }}>
                <CustomSelect
                  onChange={(value: any) => {
                    setSharingStudioFilter(value);
                    setFilterChanged(true);
                  }}
                  name="sharing_studio"
                  fieldLabel=""
                  placeholder="Interested in Sharing a Studio?"
                  value={sharingStudioFilter || []}
                  id="sharing_studio"
                  options={interestedSharingOptions}
                  isClearable
                />
              </MDBox>
              <MDBox sx={{ display: "inline" }}>
                <CustomSelect
                  onChange={(value: any) => {
                    setReasonClosingFilter(value);
                    setFilterChanged(true);
                  }}
                  name="reason_closing_lead"
                  fieldLabel=""
                  placeholder="Reason For Closing Lead"
                  value={reasonClosingFilter || []}
                  id="reason_closing_lead"
                  options={reasonClosingLeadOptions}
                  isClearable
                />
              </MDBox>
              <CustomSelect
                onChange={(value: any) => {
                  setMessageStatusFilter(value);
                  setFilterChanged(true);
                }}
                name="message_status"
                fieldLabel=""
                placeholder="Message Status"
                value={messageStatusFilter || null}
                id="message_status"
                options={messageStatusOptions}
                isClearable
              />
              <CustomSelect
                onChange={(value: any) => {
                  setSortByFilter(value);
                  setFilterChanged(true);
                }}
                name="sort_by"
                fieldLabel=""
                placeholder="Sort by"
                value={sortByFilter || null}
                id="sort_by"
                options={sortByOptions}
                isClearable
              />
              <CustomSelect
                onChange={(value: any) => {
                  setOptOutFilter(value);
                  setFilterChanged(true);
                }}
                name="opt_in_out"
                fieldLabel=""
                placeholder="Opt In/out"
                value={optOutFilter || null}
                id="opt_in_out"
                options={optOutOptions}
                isClearable
              />
              <MDDatePicker
                name="date_created"
                value={dateCreatedFilter}
                input={{ placeholder: "Lead Created Date" }}
                options={{
                  dateFormat: "Y-m-d",
                  maxDate: new Date(),
                }}
                onChange={(date: any) => {
                  handleDateChange(date, "date_created");
                }}
              />
              <MDDatePicker
                name="date_closed"
                value={dateClosedFilter}
                input={{ placeholder: "Lead Closed Date" }}
                options={{
                  dateFormat: "Y-m-d",
                  maxDate: new Date(),
                }}
                onChange={(date: any) => {
                  handleDateChange(date, "date_closed");
                }}
              />
              <MDBox sx={{ display: "inline" }}>
                <MDInput
                  label=""
                  className="filter-input"
                  variant="standard"
                  fullWidth
                  name="competitor_name"
                  placeholder="Competitor name"
                  onChange={(e: any) => {
                    setCompetitorNameFilter(e.target.value);
                    setFilterChanged(true);
                  }}
                  value={competitorNameFilter}
                />
              </MDBox>
              <MDBox sx={{ display: "inline" }}>
                <MDInput
                  label=""
                  className="filter-input"
                  variant="standard"
                  fullWidth
                  type="number"
                  name="deal_phone"
                  placeholder="Phone Number"
                  onChange={(e: any) => {
                    setDealPhoneFilter(e.target.value);
                    setFilterChanged(true);
                  }}
                  value={dealPhoneFilter}
                />
              </MDBox>
              <MDBox
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  marginTop: "10px",
                }}
              >
                <FormControlLabel
                  label="View My Deals"
                  control={
                    <Checkbox
                      defaultChecked={myDeals}
                      className="deal-header-checkbox"
                      onClick={(event: any) => {
                        setMyDeals(event?.target?.checked);
                        setFilterChanged(true);
                      }}
                    />
                  }
                  sx={{ margin: 0 }}
                />
                <FormControlLabel
                  label="Resubmitted Deals"
                  control={
                    <Checkbox
                      defaultChecked={resubmittedDeals}
                      className="deal-header-checkbox"
                      onClick={(event: any) => {
                        setResubmittedDeals(event?.target?.checked);
                        setFilterChanged(true);
                      }}
                    />
                  }
                  sx={{ margin: 0 }}
                />
              </MDBox>
              <MDBox sx={{ display: "flex", justifyContent: "flex-end", width: "100%" }}>
                <MDButton
                  variant="gradient"
                  sx={{ marginLeft: "10px" }}
                  color="light"
                  size="small"
                  className="xs-small"
                  onClick={() => clearFilter()}
                >
                  Clear
                </MDButton>
                <MDButton
                  variant="gradient"
                  sx={{ marginLeft: "10px" }}
                  color="info"
                  size="small"
                  className="xs-small"
                  onClick={() => onSearchChange()}
                >
                  Apply
                </MDButton>
              </MDBox>
            </MDBox>
          </>
        )}
        <MDBox sx={{ justifyContent: "flex-end", display: "flex" }}>
          <Tabs
            className="change-view-tabs"
            value={gridView}
            onChange={(e: any, value: any) => handleViewChange(e, value)}
          >
            <Tab className="tab-button" label={<AppsIcon fontSize="medium" />} />
            <Tab className="tab-button" label={<ViewListIcon fontSize="medium" />} />
          </Tabs>
          {(adminSuperAdmin() ||
            getCRMCreateAccess("add_access", "crm", "crm_deals")?.locations > 0) && (
            <MDButton
              variant="gradient"
              sx={{ marginLeft: "10px" }}
              color="info"
              size="small"
              className="xs-small"
              onClick={() => setAddUpdatePopup(true)}
            >
              <Icon sx={{ fontWeight: "bold" }}>add</Icon> Add new
            </MDButton>
          )}
        </MDBox>
      </MDBox>
      <MDBox
        sx={{
          justifyContent: "flex-start",
          display: "flex",
          marginLeft: "15px",
          marginBottom: "-25px",
        }}
      >
        <CustomBackButton
          label="SMS View"
          path="/crm/sola-pro-leads/"
          historyData={{
            leads: [...crmLeads],
            status: statusFilter,
            serviceFilter,
            studioTypeFilter,
            studioPreferenceFilter,
            leadComingFilter,
            reasonClosingFilter,
            sharingStudioFilter,
            competitorNameFilter,
            leadSearch,
            messageStatusFilter,
            dateCreatedFilter,
            dateClosedFilter,
            viewMyDeals: myDeals,
            sortByFilter,
            optOutFilter,
            dealPhoneFilter,
            resubmittedDeals,
          }}
        />
        {broadCastDealsArray?.length > 0 && (
          <Tooltip
            title={broadCastDealsArray?.length > 100 ? "Maximum 100 deals allowed" : ""}
            placement="top"
          >
            <MDBox marginLeft="10px">
              <MDButton
                onClick={() => onOpenBroadCastPopup()}
                variant="text"
                color="dark"
                size="small"
                disabled={broadCastDealsArray?.length > 100 ? true : false}
              >
                <Icon sx={{ fontWeight: "bold" }}>add</Icon> Create broadcast
              </MDButton>
            </MDBox>
          </Tooltip>
        )}
      </MDBox>
      {/* <Grid container>
        <Grid item md={4} sm={4} xl={4} lg={4} sx={{ borderRight: "1px solid rgba(0,0,0,0.2)" }}>
          <CustomBackButton label="SMS View" path="/crm/sola-pro-leads/" />
        </Grid>
      </Grid> */}
      {/* {!crmEnable && (
        <MDBox mt={7}>
          <Card>
            <MDBox padding={2} textAlign="center">
              <MDTypography variant="h5">Enabled CRM Features?</MDTypography>
              <MDTypography variant="body" fontSize={16}>
                Once enabled, you will no longer have access to HubSpot.
              </MDTypography>
            </MDBox>
            <MDBox padding={2} textAlign="center">
              <MDButton
                onClick={redirectToLocation}
                variant="outlined"
                color="secondary"
                size="small"
              >
                Enable CRM
              </MDButton>
            </MDBox>
          </Card>
        </MDBox>
      )} */}
      {gridView === 0 ? (
        <MDBox pl={1} pr={1} pb={0} pt={0} className="kanban-main-view">
          <MDBox
            position="relative"
            mt={4}
            height="100%"
            sx={({
              palette: { light, background },
              functions: { pxToRem },
              borders: { borderRadius },
            }: Theme | any) => ({
              "& .react-kanban-column": {
                backgroundColor: darkMode ? background.card : light.main,
                width: pxToRem(280),
                margin: `0 ${pxToRem(0)}`,
                padding: pxToRem(5),
                borderRadius: borderRadius.lg,
                marginBottom: 2,
              },
            })}
          >
            <Board
              // initialBoard={board}
              disableColumnDrag
              renderColumnHeader={({ id, title, cards, infoTexts, key }: any) => (
                <>
                  <MDBox
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                    mb={1}
                    className="deals-board-card-header"
                  >
                    <MDTypography variant="h6" display="flex" alignItems="center" fontSize="14px">
                      {title}
                      <Tooltip title={infoTexts} arrow>
                        <InfoIcon
                          style={{
                            width: 20,
                            height: 20,
                            cursor: "pointer",
                            color: "rgba(48, 57, 65, 0.3)",
                            marginLeft: "2px",
                          }}
                        />
                      </Tooltip>
                    </MDTypography>
                    <MDBox>
                      <MDTypography variant="p" className="board-count">
                        {leads?.deals_count?.[key] || 0}
                      </MDTypography>
                      <Checkbox
                        className="deal-header-checkbox"
                        checked={checkAllBroadCastIds(title)}
                        onClick={(event: any) => addAllCardsToBroadCast(event, cards)}
                        inputProps={{ "aria-label": "controlled" }}
                      />
                    </MDBox>
                    {/* <MDButton size="small" iconOnly onClick={(event) => openNewCardForm(event, id)}>
                        <Icon
                          sx={{
                            fontWeight: "bold",
                            color: ({ palette: { dark } }) => dark.main,
                          }}
                        >
                          add
                        </Icon>
                      </MDButton> */}
                  </MDBox>
                  {newCardForm === id ? (
                    <MDBox my={2.5} width="calc(310px - 40px)">
                      <MDInput
                        value={formValue}
                        rows="4"
                        onChange={handelSetFormValue}
                        multiline
                        fullWidth
                      />
                      <MDBox display="flex" mt={2}>
                        <MDButton
                          variant="gradient"
                          color="success"
                          size="small"
                          onClick={() => console.log("Test")}
                        >
                          add
                        </MDButton>
                        <MDBox ml={1}>
                          <MDButton
                            variant="gradient"
                            color="light"
                            size="small"
                            onClick={closeNewCardForm}
                          >
                            cancel
                          </MDButton>
                        </MDBox>
                      </MDBox>
                    </MDBox>
                  ) : null}
                </>
              )}
              renderCard={({ id, template }: any, { dragging }: any) => (
                <MDBox
                  key={id}
                  dragging={dragging.toString() || undefined}
                  display="block"
                  bgColor={darkMode ? "transparent" : "white"}
                  color="text"
                  borderRadius="xl"
                  className="kanban-card-box"
                  mt={1}
                  py={1.5}
                  px={1.5}
                  lineHeight={1.5}
                  onClick={() => {
                    getViewble(id, template);
                  }}
                  sx={{
                    border: ({ borders: { borderWidth }, palette: { white } }: any) =>
                      darkMode ? `${borderWidth[1]} solid ${white.main}` : 0,
                    fontSize: ({ typography: { size } }: any) => size.md,
                  }}
                >
                  <Checkbox
                    // className={`broadcast-chk ${
                    //   checkStatusWiseSelcted(template) ? "broadcast-chk-opacity" : ""
                    // }`}
                    className="broadcast-chk broadcast-chk-opacity"
                    checked={broadCastDealsArray?.includes(id) ? true : false}
                    onClick={(event: any) => addToBroadCast(event, id)}
                    disabled={template?.props?.isDealLock}
                    style={{ zIndex: 1000 }}
                    inputProps={{ "aria-label": "controlled" }}
                  />
                  {typeof template === "string" ? parse(template) : template}
                </MDBox>
              )}
              onCardNew={(): any => null}
              onCardDragEnd={(board: any, card: any, source: any, destination: any) => {
                if (
                  board?.template?.props.locationId &&
                  getEditable(board?.template?.props.locationId)
                ) {
                  if (["closed", "closed_lease", "waitlist"].includes(source.toColumnId)) {
                    setLeadTourPopUp(true);
                    setStatusStartLease(false);
                  }
                  onCardDrag({ board, card, source, destination });
                } else {
                  dispatch(notificationFail(MESSAGES.ERROR.LEAD_NOT_ABLE_TO_UPDATE_ERROR));
                }
              }}
            >
              {board}
            </Board>
            <MDBox sx={{ display: "flex", justifyContent: "center" }}>
              {leads?.leads?.length >= 1000 ? (
                <MDTypography variant="subtitle2" fontSize="small">
                  You can&apos;t view more than 1000 items in a column. Please try using the another
                  view instead.
                </MDTypography>
              ) : (
                pageCount > 1 && (
                  <MDButton
                    disabled={pageNo + 1 === pageCount || leads?.leads?.length >= totalCount}
                    onClick={() => getLeadData([], pageNo + 1, perPage)}
                    color="info"
                    size="small"
                  >
                    Load More...
                  </MDButton>
                )
              )}
            </MDBox>
          </MDBox>
        </MDBox>
      ) : (
        <ListView stat={state} getLeadData={getLeadData} getViewble={getViewble} />
      )}

      {draggedCard && !leadTourPopUp && (
        <MDDialog {...dialogProps}>
          <MDTypography>
            {statusStartLease
              ? "Are you sure you wish to move this deal to Closed Lease? This will start the lease application process."
              : "Are you sure to move this card?"}
          </MDTypography>
        </MDDialog>
      )}

      {addUpdatePopup && (
        <AddUpdate
          open={addUpdatePopup}
          pageNo={pageNo}
          pageCount={pageCount}
          totalCount={totalCount}
          crmLeads={crmLeads}
          perPage={perPage}
          setBoardData={setBoardData}
          onClose={() => closeModal()}
          statusWise={gridView ? false : true}
        />
      )}
      {startLeasePopUp && !leadTourPopUp && (
        <StartLease
          open={startLeasePopUp}
          fullCardDatas={fullCardDatas}
          cardData={startLeasePopUp}
          isUpdate
          onClose={() => closeModal()}
        />
      )}
      {leadTourPopUp && (
        <MDDialog {...leadTourDialogProps}>
          <MDTypography>Has The Lead Toured Sola?</MDTypography>
        </MDDialog>
      )}
      {moveToWaitlistPopUp && (
        <MDDialog {...moveToWaitlistDialogProps}>
          <Grid container>
            <Grid item xs={12} sm={12} md={12} lg={12} sx={{ marginTop: "18px" }}>
              <FormControl fullWidth>
                <CustomRequiredLabel label="Waitlist Date" />
                <MDDatePicker
                  name="waitlist_date"
                  onChange={(value: any) => setWaitlistDate(value)}
                  options={{
                    enableTime: false,
                    dateFormat: "m/d/Y",
                    time_24hr: true,
                  }}
                  value={waitlistDate}
                />
                {errors && errors.waitlistDate && (
                  <CustomErrorMessage message={errors.waitlistDate} />
                )}
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12} sx={{ marginTop: "18px" }}>
              <CustomSelect
                onChange={(value: any) => {
                  setStudioType(value);
                }}
                name="studio_type"
                fieldLabel="Studio Type"
                placeholder="Studio Type"
                value={studioType || []}
                id="studio_type"
                options={studioTypeOptions}
                multiple
              />
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12} sx={{ marginTop: "18px" }}>
              <CustomSelect
                onChange={(value: any) => {
                  setStudioPreference(value);
                }}
                name="studio_preference"
                fieldLabel="Studio Preference"
                placeholder="Studio Preference"
                value={studioPreference || []}
                id="studio_preference"
                options={studioPreferenceOptions}
                multiple
              />
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12} sx={{ marginTop: "18px" }}>
              <CustomSelect
                onChange={(value: any) => {
                  setInterestedSharing(value);
                }}
                name="interested_sharing"
                fieldLabel="Interested in Sharing a Studio?"
                placeholder=""
                value={interestedSharing || []}
                id="interested_sharing"
                options={interestedSharingOptions}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12} sx={{ marginTop: "18px" }}>
              <CustomSelect
                onChange={(value: any) => {
                  setLeadComingFrom(value);
                }}
                name="lead_coming_from"
                fieldLabel="Where is Lead Coming From"
                placeholder="Where is Lead Coming From"
                value={leadComingFrom || []}
                id="lead_coming_from"
                options={leadComingFromOptions}
                multiple
              />
            </Grid>
          </Grid>
        </MDDialog>
      )}
      {markClosedPopUp && (
        <MDDialog {...markClosedDialogProps}>
          <Grid container>
            <Grid item xs={12} sm={12} md={12} lg={12} sx={{ marginTop: "18px" }}>
              <CustomSelect
                onChange={(value: any) => {
                  setReasonClosingLead(value);
                }}
                name="reason_closing_lead"
                fieldLabel="Reason For Closing Lead"
                placeholder="Reason For Closing Lead"
                value={reasonClosingLead || []}
                id="reason_closing_lead"
                options={reasonClosingLeadOptions}
                required
                error={errors.reasonClosingLead || null}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12} sx={{ marginTop: "18px" }}>
              <MDInput
                label="If Lead Lost To Competitor, Please Enter Name of Competitor"
                variant="standard"
                fullWidth
                name="competitor_name"
                placeholder="Competitor name"
                onChange={(e: any) => setCompetitorName(e.target.value)}
                value={competitorName}
              />
            </Grid>
          </Grid>
        </MDDialog>
      )}
    </DashboardLayout>
  );
}

export default Index;
