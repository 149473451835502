import { createAsyncThunk } from "@reduxjs/toolkit";
import apiClient from "config/apiClient";
import { setLoading } from "store/slices/authSlice";
import { notificationFail, notificationSuccess } from "store/slices/notificationSlice";
import Messages from "helper/messages";
import { allFranchiseeInquiriesList, setFranchiseBlog } from "store/slices/franchiseeWebSiteSlice";
import { getErrorMessage, getFilterURL, getValue } from "helper/services";

/**
 * get franchisee inquiries
 */
export const getFranchiseeInquiries = createAsyncThunk(
  "getFranchiseeInquiries",
  async (_request: any, { dispatch }) => {
    dispatch(setLoading(true));
    const page = _request.pageIndex || 0;
    const size = _request.pageSize || 50;
    const search = _request.search || "";
    const sortBy = _request.sort || [];
    const country = _request.country || "usa";
    const filterBy = _request.filterData || [];
    try {
      let url = `franchising_inquiries?page=${
        page + 1
      }&per_page=${size}&get_inquries_by_country=${country}`;
      if (search) {
        url += `&search=${search}`;
      }
      if (sortBy?.length) {
        url += `&field=${sortBy[0]?.id}&order_by=${sortBy[0]?.desc ? "desc" : "asc"}`;
      }
      // Apply advance filter
      if (filterBy && filterBy.length) {
        url = getFilterURL(url, filterBy);
      }
      const response = await apiClient().get(url);
      if (response) {
        dispatch(setLoading(false));
        dispatch(allFranchiseeInquiriesList(response?.data || []));
      }
    } catch (error: any) {
      dispatch(allFranchiseeInquiriesList(null));
      dispatch(setLoading(false));
      dispatch(notificationFail(error.message || Messages.ERROR.SOMETHING_WENT_WRONG));
    }
  }
);

/**
 * Save Franchise Inquiries
 */
export const saveFranchiseeInquiry = createAsyncThunk(
  "saveFranchiseeInquiry",
  async (_request: any, { dispatch }) => {
    dispatch(setLoading(true));
    const { inquiryData, onClose, isUpdate, pageNo, perPage, search, sortBy, country } = _request;
    const reqData = {
      franchising_form: {
        first_name: inquiryData.first_name,
        last_name: inquiryData.last_name,
        email_address: inquiryData.email_address,
        phone_number: inquiryData.phone_number,
        multi_unit_operator: inquiryData.multi_unit_operator ? 1 : 0,
        liquid_capital: inquiryData.liquid_capital,
        city: inquiryData.city,
        state: inquiryData.state,
        agree_to_receive_email: inquiryData.agree_to_receive_email ? 1 : 0,
        utm_source: inquiryData.utm_source || "",
        utm_campaign: inquiryData.utm_campaign || "",
        utm_medium: inquiryData.utm_medium || "",
        utm_content: inquiryData.utm_content || "",
        utm_term: inquiryData.utm_term || "",
        country: inquiryData.country ? inquiryData.country.toLowerCase() : "",
      },
    };
    try {
      const response = isUpdate
        ? await apiClient().put(`franchising_inquiries/${inquiryData.id}`, reqData)
        : await apiClient().post("franchising_inquiries", reqData);
      if (response) {
        dispatch(setLoading(false));
        onClose();
        if (isUpdate) {
          dispatch(
            notificationSuccess(
              response?.data?.message || Messages.SUCCESS.FRANCHISEE_INQUIRIES_UPDATE_SUCCESS
            )
          );
          dispatch(
            getFranchiseeInquiries({
              pageIndex: pageNo,
              pageSize: perPage,
              search,
              sort: sortBy,
              country,
            })
          );
        } else {
          dispatch(
            notificationSuccess(
              response?.data?.message || Messages.SUCCESS.FRANCHISEE_INQUIRIES_CREATE_SUCCESS
            )
          );
          dispatch(getFranchiseeInquiries({ pageIndex: pageNo, pageSize: perPage, country }));
        }
      } else {
        dispatch(
          notificationFail(
            response?.data?.message || Messages.ERROR.FRANCHISEE_INQUIRIES_SAVE_ERROR
          )
        );
      }
    } catch (error: any) {
      dispatch(setLoading(false));
      if (error?.response?.data?.error) {
        const message = getErrorMessage(error?.response?.data?.error);
        dispatch(notificationFail(message));
      } else {
        dispatch(
          notificationFail(
            error?.response?.data?.message || Messages.ERROR.FRANCHISEE_INQUIRIES_SAVE_ERROR
          )
        );
      }
    }
  }
);

/**
 * Delete Franchise Inquiry
 */
export const deleteFranchiseeInquiries = createAsyncThunk(
  "deleteFranchiseeInquiries",
  async (_request: any, { dispatch }) => {
    dispatch(setLoading(true));
    const {
      id,
      setDeleteInquiryId,
      pageNo,
      perPage,
      search,
      sortBy,
      country,
      removeSelectedItem = null,
    } = _request;
    try {
      const response = await apiClient().delete(`franchising_inquiries/${id}`);
      dispatch(setLoading(false));
      if (response) {
        setDeleteInquiryId(false);
        if (removeSelectedItem) {
          removeSelectedItem(id);
        }
        dispatch(notificationSuccess(Messages.SUCCESS.FRANCHISEE_INQUIRIES_DELETE_SUCCESS));
        dispatch(
          getFranchiseeInquiries({
            pageIndex: pageNo,
            pageSize: perPage,
            search,
            sort: sortBy,
            country,
          })
        );
      } else {
        dispatch(
          notificationFail(
            response?.data?.message || Messages.ERROR.FRANCHISEE_INQUIRIES_DELETE_ERROR
          )
        );
      }
    } catch (error: any) {
      if (error?.response?.data?.error) {
        const message = getErrorMessage(error?.response?.data?.error);
        dispatch(notificationFail(message));
      } else {
        dispatch(
          notificationFail(
            error?.response?.data?.message || Messages.ERROR.FRANCHISEE_INQUIRIES_DELETE_ERROR
          )
        );
      }
    }
  }
);

/**
 * Get Franchise Blog
 */
export const getFranchiseBlogs = createAsyncThunk(
  "getFranchiseBlogs",
  async (_request: any, { dispatch }) => {
    dispatch(setLoading(true));
    const page = _request.pageIndex || 0;
    const size = _request.pageSize || 50;
    const search = _request.search || "";
    const sortBy = _request.sort || [];
    const kind = _request?.kind || "";
    const filterBy = _request?.filterData || [];
    try {
      let url = `franchise_articles?page=${page + 1}&per_page=${size}`;
      if (search) {
        url += `&search=${search}`;
      }
      if (sortBy?.length) {
        url += `&field=${sortBy[0]?.id}&order_by=${sortBy[0]?.desc ? "desc" : "asc"}`;
      }
      if (kind) {
        url += `&kind=${kind}`;
      }
      // Apply advance filter
      if (filterBy && filterBy.length) {
        url = getFilterURL(url, filterBy);
      }
      const response = await apiClient().get(url);
      if (response) {
        dispatch(setFranchiseBlog(response?.data || []));
        dispatch(setLoading(false));
      }
    } catch (error: any) {
      dispatch(notificationFail(error.message || Messages.ERROR.NO_DATA_FOUND));
      dispatch(setLoading(false));
    }
  }
);

/**
 * Create Franchise Blog
 */
export const createFranchiseBlogs = createAsyncThunk(
  "createFranchiseBlogs",
  async (_request: any, { dispatch }) => {
    try {
      const { blogData, onClose, isUpdate, pageNo, perPage, search, sortBy, kind } = _request;
      const requestData = new FormData();
      requestData.append("franchise_article[kind]", getValue(blogData?.kind));
      requestData.append("franchise_article[country_id]", getValue(blogData?.countries?.id));
      requestData.append("franchise_article[title]", getValue(blogData?.title));
      requestData.append("franchise_article[url]", getValue(blogData?.url));
      requestData.append("franchise_article[summary]", getValue(blogData?.summary));
      requestData.append("franchise_article[body]", getValue(blogData?.body));
      requestData.append("franchise_article[image]", getValue(blogData?.image));
      if (blogData.delete_image) {
        requestData.append("franchise_article[delete_image]", "1");
      }
      requestData.append("franchise_article[thumbnail]", getValue(blogData?.thumbnail));
      if (blogData.delete_thumbnail) {
        requestData.append("franchise_article[delete_thumbnail]", "1");
      }
      requestData.append("franchise_article[author]", getValue(blogData?.author));

      if (blogData?.category?.length) {
        blogData.category.forEach((val: any) => {
          requestData.append("franchise_article[category_ids][]", val.id);
        });
      } else {
        requestData.append("franchise_article[category_ids][]", "");
      }

      if (blogData?.tag?.length) {
        blogData.tag.forEach((val: any) => {
          requestData.append("franchise_article[tag_ids][]", val.id);
        });
      } else {
        requestData.append("franchise_article[tag_ids][]", "");
      }

      dispatch(setLoading(true));
      const response = isUpdate
        ? await apiClient(true).put(`franchise_articles/${blogData.id}`, requestData)
        : await apiClient(true).post("franchise_articles", requestData);
      dispatch(setLoading(false));
      if (response) {
        onClose();
        if (isUpdate) {
          dispatch(notificationSuccess(Messages.SUCCESS.RECORD_UPDATE_SUCCESS));
          dispatch(
            getFranchiseBlogs({ pageIndex: pageNo, pageSize: perPage, search, sort: sortBy, kind })
          );
        } else {
          dispatch(
            notificationSuccess(response?.data?.message || Messages.SUCCESS.RECORD_CREATE_SUCCESS)
          );
          dispatch(getFranchiseBlogs({ pageIndex: pageNo, pageSize: perPage, kind }));
        }
      } else {
        dispatch(notificationFail(response?.data?.message || Messages.ERROR.FRANCHISE_BLOG_ERROR));
      }
    } catch (error: any) {
      dispatch(setLoading(false));
      if (error?.response?.data?.error) {
        const message = getErrorMessage(error?.response?.data?.error);
        dispatch(notificationFail(message));
      } else {
        dispatch(
          notificationFail(error?.response?.data?.message || Messages.ERROR.FRANCHISE_BLOG_ERROR)
        );
      }
    }
  }
);

/**
 * Delete Franchisee Blogs
 */
export const deleteFranchiseeBlogs = createAsyncThunk(
  "deleteFranchiseeBlogs",
  async (_request: any, { dispatch }) => {
    dispatch(setLoading(true));
    const {
      id,
      setDeleteBlogId,
      pageNo,
      perPage,
      search,
      sortBy,
      kind,
      removeSelectedItem = null,
    } = _request;
    try {
      const response = await apiClient().delete(`franchise_articles/${id}`);
      dispatch(setLoading(false));
      if (response) {
        setDeleteBlogId(false);
        if (removeSelectedItem) {
          removeSelectedItem(id);
        }
        dispatch(notificationSuccess(Messages.SUCCESS.RECORD_DELETE_SUCCESS));
        dispatch(
          getFranchiseBlogs({ pageIndex: pageNo, pageSize: perPage, search, sort: sortBy, kind })
        );
      } else {
        dispatch(
          notificationFail(response?.data?.message || Messages.ERROR.FRANCHISEE_BLOG_DELETE_ERROR)
        );
      }
    } catch (error: any) {
      if (error?.response?.data?.error) {
        const message = getErrorMessage(error?.response?.data?.error);
        dispatch(notificationFail(message));
      } else {
        dispatch(
          notificationFail(
            error?.response?.data?.message || Messages.ERROR.FRANCHISEE_BLOG_DELETE_ERROR
          )
        );
      }
    }
  }
);
