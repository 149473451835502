import { createSlice, Draft, PayloadAction } from "@reduxjs/toolkit";

interface InitialState {
  answerData: any;
}

const initialState: InitialState = {
  answerData: {},
};

const rockbotSlice = createSlice({
  name: "deal",
  initialState,
  reducers: {
    setAnswerData: (state: Draft<InitialState>, action: PayloadAction<any>) => ({
      ...state,
      answerData: action.payload,
    }),
  },
});

export const { setAnswerData } = rockbotSlice.actions;

export default rockbotSlice.reducer;
