import { useState } from "react";
import { Card, FormControlLabel, Grid, Switch } from "@mui/material";
import MDBox from "components/MDBox";

function Selfservicebilling(props: any): JSX.Element {
  const { setRmDetails, rmDetails } = props;
  const [infoSwitch, setInfoSwitch] = useState(rmDetails?.is_enable_self_service_billing || false);

  const onchangeSwitch = (event: any) => {
    setInfoSwitch(event.target.checked);
    const tempRM = JSON.parse(JSON.stringify(rmDetails));
    tempRM.is_enable_self_service_billing = event.target.checked;
    setRmDetails(tempRM);
  };

  return (
    <Card id="self-service-billing" sx={{ overflow: "visible" }}>
      <MDBox component="form" px={3} pb={1}>
        <MDBox mt={2}>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={12}>
              <FormControlLabel
                control={
                  <Switch
                    checked={infoSwitch || false}
                    onChange={onchangeSwitch}
                    inputProps={{ "aria-label": "controlled" }}
                  />
                }
                label="Self-Service Billing in Sola Pro App"
                labelPlacement="end"
              />
            </Grid>
          </Grid>
        </MDBox>
      </MDBox>
    </Card>
  );
}

export default Selfservicebilling;
