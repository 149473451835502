import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import {
  Grid,
  Card,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  ListItem,
  ListItemButton,
  ListItemText,
} from "@mui/material";
import CustomAutoSearch from "components/CustomAutoSearch";
import { getPermission } from "helper/services";
import { useAppSelector, useAppDispatch } from "store/store";
import { useEffect, useState } from "react";
import { getUserCombineServices, getUserLocations } from "store/thunk/solaProsThunk";
import { setActiveServices } from "store/slices/solaProSlice";
import CustomErrorMessage from "components/CustomErrorMessage";

function Services(props: any): JSX.Element {
  const dispatch = useAppDispatch();
  const { webPageDetail, setWebPageDetails, errors } = props;
  const { allConnectServices, activeServices } = useAppSelector((state: any) => state.solaProSlice);
  const [userActiveService, setActiveUserService] = useState([]);

  const handleLocationPermission = (event: any, connectUserId?: any, locationId?: any) => {
    let finalDataOfLocationPermission: any = [...webPageDetail.location_permission_data];
    if (!event.target.checked) {
      finalDataOfLocationPermission = webPageDetail?.location_permission_data?.filter(
        (obj: any) =>
          Number(obj?.location_id) !== Number(locationId) ||
          Number(obj?.connect_user_id) !== Number(connectUserId)
      );
    } else {
      const checkedIndex: number = webPageDetail?.location_permission_data?.findIndex(
        (obj: any) =>
          Number(obj?.location_id) === Number(locationId) &&
          Number(obj?.connect_user_id) === Number(connectUserId)
      );
      if (checkedIndex !== -1) {
        finalDataOfLocationPermission[checkedIndex] = {
          location_id: locationId,
          connect_user_id: connectUserId,
          is_show: true,
        };
      } else {
        finalDataOfLocationPermission.push({
          location_id: locationId,
          connect_user_id: connectUserId,
          is_show: true,
        });
      }
    }

    setWebPageDetails({
      ...webPageDetail,
      location_permission_data: finalDataOfLocationPermission,
    });
  };

  const getLocationShowIsTrue = (connectUserId?: any, locationId?: any) =>
    webPageDetail?.location_permission_data?.some(
      (obj: any) =>
        Number(obj?.location_id) === Number(locationId) &&
        Number(obj?.connect_user_id) === Number(connectUserId) &&
        obj?.is_show
    );

  const onChangeAutoComplete = (name: string, value: any) => {
    const previousConnectUsers: any = webPageDetail?.connect_users
      ? [...webPageDetail.connect_users]
      : [];
    const latestAddedUser: any = value[value.length - 1];
    const userIds = value?.map((obj: any) => Number(obj.user_id));
    if (previousConnectUsers?.length < userIds.length) {
      dispatch(getUserLocations({ connectUserId: latestAddedUser?.user_id }))
        .unwrap()
        .then((result) => {
          const mappedUserValues: any = webPageDetail?.connect_users
            ? [...webPageDetail.connect_users, { ...latestAddedUser, locations: result }]
            : [{ ...latestAddedUser, locations: result }];
          setWebPageDetails({ ...webPageDetail, [name]: mappedUserValues });
          if (userIds?.length) {
            dispatch(getUserCombineServices({ user_ids: userIds }));
          } else {
            dispatch(setActiveServices([]));
          }
        });
    } else {
      const filteredLatestData: any = previousConnectUsers.filter((obj: any) =>
        userIds.includes(Number(obj?.user_id))
      );
      setWebPageDetails({ ...webPageDetail, [name]: filteredLatestData });
      if (userIds?.length) {
        dispatch(getUserCombineServices({ user_ids: userIds }));
      } else {
        dispatch(setActiveServices([]));
      }
    }
  };

  useEffect(() => {
    if (activeServices) {
      setActiveUserService(activeServices?.map((obj: any) => obj?.label));
    }
  }, [activeServices]);

  return (
    <Card id="users">
      <MDBox p={3}>
        <MDTypography variant="h5">Sola Pros</MDTypography>
      </MDBox>
      <MDBox pb={3} px={3}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <CustomAutoSearch
              idKey="user_id"
              nameKey="login_email"
              fieldLabel="Users"
              apiName="connect_users"
              responseKey="connect_users"
              name="connect_users"
              onChange={onChangeAutoComplete}
              value={webPageDetail?.connect_users || []}
              roleName="Sola Pro"
              error={errors?.connect_users || ""}
              required
              multiple
              slsAPI
              createNewLink={
                getPermission("users", "users_manage") ? "/users-&-roles/all-users" : ""
              }
            />
            <CustomErrorMessage
              errorColor="inherit"
              message="This webpage will appear for the follow Locations on our website."
            />
          </Grid>
          <MDBox pt={3} pl={3}>
            <MDTypography variant="h5">Users Locations</MDTypography>
          </MDBox>
          <Grid container>
            {webPageDetail?.connect_users?.length > 0 ? (
              webPageDetail?.connect_users?.map((users: any) => (
                <Grid item xs={6} sm={6} md={6} lg={6}>
                  <Accordion className="accordian-user-loc" expanded>
                    <AccordionSummary>
                      <MDTypography variant="h6">{users?.login_email}</MDTypography>
                    </AccordionSummary>
                  </Accordion>
                  <AccordionDetails>
                    {users?.locations?.length > 0 ? (
                      users?.locations?.map((value: any, index: any) => (
                        <ListItem
                          sx={{ pl: 1 }}
                          key={value?.name}
                          secondaryAction={
                            <Checkbox
                              edge="end"
                              onChange={(event: any) =>
                                handleLocationPermission(event, users?.user_id, value?.id)
                              }
                              checked={getLocationShowIsTrue(users?.user_id, value?.id) || false}
                              inputProps={{ "aria-labelledby": index }}
                            />
                          }
                        >
                          <ListItemButton>
                            <ListItemText>
                              <MDTypography variant="h6">{value?.name}</MDTypography>
                            </ListItemText>
                          </ListItemButton>
                        </ListItem>
                      ))
                    ) : (
                      <ListItemButton>
                        <ListItemText>
                          <MDTypography variant="h6">No Location Found</MDTypography>
                        </ListItemText>
                      </ListItemButton>
                    )}
                  </AccordionDetails>
                </Grid>
              ))
            ) : (
              <ListItemButton>
                <ListItemText sx={{ pl: 3, pt: 2 }}>
                  <MDTypography variant="h6">No User Selected</MDTypography>
                </ListItemText>
              </ListItemButton>
            )}
          </Grid>
          <Grid container>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <MDBox pt={3} pl={3.5}>
                <MDTypography variant="h5">Users Services</MDTypography>
              </MDBox>
            </Grid>
          </Grid>
          {allConnectServices?.map((item: any) => (
            <Grid item xs={12} sm={6} md={6} lg={4} key={item?.label}>
              <FormControl component="fieldset">
                <FormGroup aria-label="position">
                  <FormControlLabel
                    value="end"
                    disabled
                    control={
                      <Checkbox
                        checked={userActiveService?.includes(item?.label) ? true : false}
                        name={item?.label}
                        inputProps={{ "aria-label": "controlled" }}
                      />
                    }
                    label={item?.label}
                    labelPlacement="end"
                  />
                </FormGroup>
              </FormControl>
            </Grid>
          ))}
        </Grid>
      </MDBox>
    </Card>
  );
}

export default Services;
