import MDBox from "components/MDBox";
import EleaseButtons from "components/EleaseButtons";
import { useAppDispatch, useAppSelector } from "store/store";
import CustomAutoSearch from "components/CustomAutoSearch";
import { adminSuperAdmin, getCRMCreateAccess } from "helper/services";
import { useEffect, useState } from "react";
import { getStudiosByLocation } from "store/thunk/leaseThunk";
import { Box, Grid, IconButton, Tooltip, Typography } from "@mui/material";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import Paper from "@mui/material/Paper";
import { experimentalStyled as styled } from "@mui/material/styles";
import MDTypography from "components/MDTypography";
import { setleaseApplicationSteps } from "store/slices/leaseSlice";

function StudioSelection(props: any): JSX.Element {
  const {
    saveDraftData,
    setSaveDraftData,
    selectedStudios,
    setSelectedStudios,
    selectedItemId,
    setSelectedItemId,
    setSubletApplicantError,
  } = props;
  const { leaseApplicationSteps, locationStudios } = useAppSelector((state) => state.leaseSlice);
  const [location, setLocation] = useState<any>({});
  // const [selectedItemId, setSelectedItemId] = useState<string | null>(null);
  const [studios, setStudios] = useState<any>([]);
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(getStudiosByLocation(leaseApplicationSteps?.locations?.cms_location_id));
  }, []);

  useEffect(() => {
    if (leaseApplicationSteps?.studio) {
      setSelectedItemId(leaseApplicationSteps?.studio[0]?.id);
    }
  }, []);

  // console.log("leaseApplicationSteps ", leaseApplicationSteps);
  useEffect(() => {
    // Set studios by location
    if (locationStudios) {
      setStudios(locationStudios?.data?.rows?.filter((data: any) => data?.is_vacant));
    }
    if (locationStudios?.data?.rows?.length === 0) {
      setSelectedItemId("");
    }
    if (leaseApplicationSteps?.studio) {
      setSelectedItemId(leaseApplicationSteps?.studio[0]?.id);
    }
  }, [locationStudios]);

  const onChangeAutoComplete = (name: string, value: any) => {
    if (selectedStudios) {
      setSelectedStudios([]);
      setSelectedItemId("");
    }
    setLocation({ ...location, [name]: value });
    dispatch(getStudiosByLocation(value?.cms_location_id));
    setStudios(locationStudios?.data?.rows);
    if (value?.cms_location_id !== leaseApplicationSteps?.locations?.cms_location_id) {
      dispatch(
        setleaseApplicationSteps({ ...leaseApplicationSteps, locations: value, studio: {} })
      );
    }
  };

  const Item = styled(Paper)(({ theme }) => ({
    ...theme.typography.body2,
    padding: "20px",
    margin: "10px",
    textAlign: "center",
  }));

  function calculateTotalLeaseFee(mainLeaseFee: any, group: any) {
    if (group?.length) {
      const groupLeaseFeeSum = group.reduce((total: any, obj: any) => {
        const objLeaseFee = obj?.lease_fee || 0;
        return total + objLeaseFee;
      }, 0);

      return mainLeaseFee + groupLeaseFeeSum;
      // return groupLeaseFeeSum;
    }
    return mainLeaseFee;
  }

  const handleBoxSelection = (id: string, setSelectedItemId: Function, leaseFees: number) => {
    const mainLeesFees = calculateTotalLeaseFee(
      leaseFees,
      studios?.filter((obj: any) => obj.group_id === id && obj?.id !== id)
    );
    setSubletApplicantError(false);
    setSelectedItemId(id);
    const [selectStudioData] = studios.filter((obj: any) => obj.id === id);
    setSelectedStudios([selectStudioData]);
    setSaveDraftData({ ...saveDraftData, studio: selectStudioData });
    dispatch(
      setleaseApplicationSteps({
        ...leaseApplicationSteps,
        studio: [selectStudioData],
        lease_fees: mainLeesFees ? mainLeesFees : leaseFees,
      })
    );
  };

  return (
    <MDBox sx={{ maxWidth: "auto" }}>
      <CustomAutoSearch
        slsAPI
        idKey="cms_location_id"
        nameKey="name"
        apiName="get-locations"
        parentKey="location"
        responseKey="data"
        name="location"
        usersData={
          !adminSuperAdmin()
            ? getCRMCreateAccess("add_access", "crm", "crm_lease_application")
            : null
        }
        onChange={onChangeAutoComplete}
        value={leaseApplicationSteps?.locations || null}
      />
      <Box sx={{ flexGrow: 1, mt: 2 }}>
        <Grid container sx={{ height: "200px", overflow: "auto" }}>
          {studios && studios.length > 0 ? (
            studios
              ?.filter((obj: any) => !obj?.group_id || obj?.id === obj?.group_id)
              ?.map((items: any) => (
                <Grid item xs={12} sm={6} md={3} lg={3}>
                  <Item
                    sx={{
                      position: "relative",
                      backgroundColor:
                        selectedItemId === items.id
                          ? "#1A73E8"
                          : items?.is_lease_application
                          ? "#b5c6d8"
                          : "white",
                      cursor: "pointer",
                    }}
                    onClick={() =>
                      handleBoxSelection(items.id, setSelectedItemId, items?.lease_fee)
                    }
                  >
                    {items?.is_lease_application && (
                      <div
                        style={{
                          position: "absolute",
                          top: "0",
                          right: "0",
                          color: "black",
                          padding: "4px",
                          borderRadius: "4px",
                          zIndex: "1",
                        }}
                      >
                        <Tooltip title="This studio is already in another lease application.">
                          <IconButton>
                            {selectedItemId !== items.id && (
                              <InfoOutlinedIcon sx={{ color: "#000" }} />
                            )}
                          </IconButton>
                        </Tooltip>
                      </div>
                    )}
                    <Typography color={selectedItemId === items.id ? "#fff" : "#000"} variant="h3">
                      {studios.filter(
                        (obj: any) => obj.group_id === items?.id && obj?.id !== items?.id
                      )?.length ? (
                        <>
                          {studios
                            .filter(
                              (obj: any) => obj.group_id === items?.id && obj?.id !== items?.id
                            )
                            ?.map((obj: any, index: number) => (
                              <>
                                <span>{obj?.name}</span>
                                {index < studios.length - 1 && <span>,</span>}
                              </>
                            ))}
                          <span>{items?.name}</span>
                        </>
                      ) : (
                        <span>{items?.name}</span>
                      )}
                    </Typography>
                    <Typography color={selectedItemId === items.id ? "#fff" : "#000"} variant="h5">
                      {`$ ${calculateTotalLeaseFee(
                        items?.lease_fee,
                        studios?.filter(
                          (obj: any) => obj.group_id === items?.id && obj?.id !== items?.id
                        )
                      )}`}
                    </Typography>
                  </Item>{" "}
                </Grid>
              ))
          ) : (
            <MDBox
              width="100%"
              height="100%"
              display="flex"
              justifyContent="center"
              alignItems="center"
            >
              <MDTypography>No Studios available on this location...</MDTypography>
            </MDBox>
          )}
        </Grid>
      </Box>

      <EleaseButtons
        {...props}
        data={{
          // studio: leaseApplicationSteps?.studio
          //   ? leaseApplicationSteps?.studio
          //   : selectedStudios,
          studio: selectedStudios,
          locations: leaseApplicationSteps?.locations,
          disabledNext: !selectedItemId,
        }}
      />
    </MDBox>
  );
}

export default StudioSelection;
