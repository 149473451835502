// @mui material components
import { List, ListItem, ListItemText, Typography, Icon } from "@mui/material";
import MDIconButton from "components/MDIconButton";
import MDTypography from "components/MDTypography";
import { useState } from "react";
import ViewNotesDetails from "../../lead/viewNotesDetails";

// Declaring props types for Card
interface Props {
  title?: string;
  content?: string;
  locationId?: number;
  locationName?: number;
  email?: string;
  phone?: string;
  messageCount?: string | number;
  messageStatus?: string;
  conversionCount?: number;
  smsRequestStatus?: string;
  isDealLock: any;
  connectDealNotes?: any;
}

function CardItem({
  title,
  content,
  messageCount,
  locationId,
  email,
  phone,
  locationName,
  messageStatus,
  conversionCount,
  smsRequestStatus,
  isDealLock,
  connectDealNotes,
}: Props): JSX.Element {
  const [detailsPopup, setDetailsPopup] = useState<any>(false);
  const getMessagePreview = () => {
    let previewHtml = (
      <>
        <Typography
          component="span"
          variant="body2"
          color="text.primary"
          className={`multiline-ellipsis ${isDealLock}`}
          fontSize={14}
        >
          {content}
        </Typography>
        <Typography
          component="span"
          variant="subtitle1"
          color="text.secondary"
          fontSize={12}
          display="block"
        >
          {messageCount}
        </Typography>
      </>
    );

    if (!smsRequestStatus || smsRequestStatus === "requested") {
      previewHtml = (
        <Typography
          component="span"
          variant="body2"
          color="text.primary"
          className="multiline-ellipsis"
          fontSize={14}
        >
          SMS Not Enabled
        </Typography>
      );
    } else if (smsRequestStatus && smsRequestStatus !== "requested" && !conversionCount) {
      previewHtml = (
        <Typography
          component="span"
          variant="subtitle1"
          color="text.secondary"
          fontSize={12}
          display="block"
        >
          {messageCount}
        </Typography>
      );
    }
    return previewHtml;
  };

  return (
    <List sx={{ width: "100%", maxWidth: 360, bgcolor: "background.paper" }}>
      <ListItem alignItems="flex-start">
        <ListItemText
          primary={title}
          primaryTypographyProps={{
            overflow: "hidden",
            textOverflow: "ellipsis",
            fontSize: "18px",
          }}
          secondaryTypographyProps={{
            sx: {
              wordBreak: "break-all",
            },
          }}
          secondary={
            <>
              {getMessagePreview()}
              {connectDealNotes?.length > 0 && (
                <MDTypography
                  variant="h6"
                  fontWeight="medium"
                  fontSize={12}
                  paddingRight="15px !important"
                >
                  Resubmitted Contact Inquiries:
                  <MDIconButton
                    tooltipName="View"
                    aria-label="Open"
                    color="info"
                    onClick={(e: any) => {
                      e.stopPropagation();
                      setDetailsPopup(true);
                    }}
                  >
                    <Icon fontSize="small">visibility</Icon>
                  </MDIconButton>
                </MDTypography>
              )}
              <Typography
                component="span"
                variant="body2"
                color="text.primary"
                sx={{ display: "none" }}
              >
                {locationId}
                {email}
                {phone}
                {locationName}
                {messageStatus}
              </Typography>
            </>
          }
        />
      </ListItem>
      {detailsPopup && (
        <ViewNotesDetails
          detailsPopup={detailsPopup}
          onClose={(e: any) => {
            e.stopPropagation();
            setDetailsPopup(false);
          }}
          notesData={connectDealNotes}
        />
      )}
    </List>
  );
}

// Declaring default props for Card
CardItem.defaultProps = {
  title: null,
  content: null,
  messageCount: null,
  locationId: null,
  email: null,
  phone: null,
  locationName: null,
  messageStatus: null,
  conversionCount: null,
  smsRequestStatus: null,
  connectDealNotes: [],
};

export default CardItem;
