import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MESSAGES from "helper/messages";

function TrackingCode(props: any): JSX.Element {
  const { locationDetail, onChangeField } = props;
  return (
    <Card id="tracking-code">
      <MDBox p={3}>
        <MDTypography variant="h5">Tracking Code</MDTypography>
      </MDBox>
      <MDBox pb={3} px={3}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6} md={6} lg={6}>
            <MDInput
              multiline
              rows="3"
              variant="outlined"
              label="Tracking Code"
              fullWidth
              name="tracking_code"
              placeholder="Tracking Code"
              onChange={onChangeField}
              value={locationDetail?.tracking_code || ""}
              infoText={MESSAGES.INFO.OPTIONAL}
            />
          </Grid>
        </Grid>
      </MDBox>
    </Card>
  );
}

export default TrackingCode;
