import { useState } from "react";
import MDDialog from "components/MDDialog";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import { Grid, AccordionSummary, AccordionDetails, Accordion } from "@mui/material";
// import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ImagePreview from "layouts/pages/ImagePreview";
import { getLocalDateTime } from "helper/services";

function ViewDetails(props: any): JSX.Element {
  const { open, onClose, updateData } = props;

  const [isPreview, setImagePreviewPopup] = useState(false);
  const [imageToPreview, setImageToPreview] = useState("");

  const dialogProps = {
    open,
    onClose,
    title: "View My Sola Image",
    size: "md",
    saveBtn: false,
  };

  const previewImage = (image: any) => {
    setImagePreviewPopup(true);
    setImageToPreview(image);
  };

  const closeImagePreview = () => {
    setImagePreviewPopup(false);
    setImageToPreview("");
  };

  const getImageElement = (name: any, imageData: any) => {
    if (imageData) {
      return (
        <Grid item xs={12} sm={6} md={6}>
          <MDTypography fontWeight="bold" variant="button">
            {name}
          </MDTypography>
          <Grid container mt={2}>
            <Grid item xs={12} sm={6} md={6}>
              {imageData && (
                <Grid
                  container
                  onClick={() => previewImage(imageData)}
                  justifyContent="left"
                  alignItems="center"
                  display="flex"
                >
                  <Grid item className="thumbnail-img">
                    <img height={100} width={100} src={imageData} alt="location" />
                  </Grid>
                </Grid>
              )}
            </Grid>
          </Grid>
        </Grid>
      );
    }
    return null;
  };

  const getTypograpghy = (label: any, value: any) => (
    <Grid item xs={12} sm={12} md={12} lg={6}>
      <MDTypography fontWeight="bold" variant="button">
        {label}
      </MDTypography>
      <MDBox>
        <MDTypography variant="button">{value || "-"}</MDTypography>
      </MDBox>
    </Grid>
  );

  const getAccordianSummary = (label: any) => (
    <AccordionSummary
      className="location-accordion"
      // expandIcon={<ExpandMoreIcon />}
      aria-controls="publishbh-content"
    >
      <MDTypography variant="h6">{label}</MDTypography>
    </AccordionSummary>
  );

  return (
    <MDDialog {...dialogProps}>
      <MDBox className="select-container">
        <Grid container>
          <Grid item xs={12} sm={12} md={12}>
            <Accordion expanded>
              {getAccordianSummary("Basic Info")}
              <AccordionDetails>
                <Grid container spacing={2}>
                  {getTypograpghy("Name", updateData?.name || "-")}
                  {getTypograpghy("Approved", updateData?.approved ? "Yes" : "No")}
                </Grid>
                <Grid container spacing={2}>
                  {getTypograpghy("Instagram Handle", updateData?.instagram_handle || "-")}
                  {getTypograpghy("Statement", updateData?.statement || "-")}
                </Grid>
                <Grid container spacing={2}>
                  {getTypograpghy("Statement Variant", updateData?.statement_variant || "-")}
                  {getTypograpghy("Created At", getLocalDateTime(updateData?.created_at) || "-")}
                </Grid>
                <Grid container spacing={2}>
                  {updateData?.original_image_url &&
                    getImageElement("Image", updateData?.original_image_url)}
                  {updateData?.generated_image_url &&
                    getImageElement("Generated Image", updateData?.generated_image_url)}
                </Grid>
              </AccordionDetails>
            </Accordion>
          </Grid>
        </Grid>
      </MDBox>
      <ImagePreview
        imageData={imageToPreview}
        open={isPreview}
        onCloseImage={() => {
          closeImagePreview();
        }}
      />
    </MDDialog>
  );
}

export default ViewDetails;
