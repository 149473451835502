import { Grid } from "@mui/material";
import CustomAutoSearch from "components/CustomAutoSearch";
import { useState } from "react";
import { useAppDispatch, useAppSelector } from "store/store";
import { setleaseApplicationSteps } from "store/slices/leaseSlice";
import HandleButton from "./handleButton";
import NotFoundRecord from "./notFoundRecord";

function AddFromDeal(props: any): JSX.Element {
  const { errors, setShowDeals } = props;
  const { leaseApplicationSteps } = useAppSelector((state) => state.leaseSlice);
  const [showNotFound, setShowNotFound] = useState<boolean>(false);
  const dispatch = useAppDispatch();

  const getRsp = (mainResp: any) => {
    if (!mainResp?.length) {
      setShowNotFound(true);
    } else {
      setShowNotFound(false);
    }
  };

  const onChangeAutoComplete = (name: string, value: any) => {
    dispatch(setleaseApplicationSteps({ ...leaseApplicationSteps, [name]: value }));
  };

  return (
    <Grid item xs={12} sm={12} md={6} lg={6} sx={{ margin: "20px auto" }}>
      <CustomAutoSearch
        slsAPI
        idKey="id"
        nameKey="deal_name"
        fieldLabel="Search Leads"
        apiName="get-contact-inquiry"
        responseKey="leads"
        name="deal"
        required
        value={leaseApplicationSteps?.deal || ""}
        onChange={onChangeAutoComplete}
        additionaFields={{ type: "Request leasing information" }}
        error={errors && errors.deal}
        responseCallback={(result: any) => getRsp(result)}
      />
      {showNotFound ? (
        <NotFoundRecord type="Deal" setShowNotFound={setShowNotFound} setShowDeals={setShowDeals} />
      ) : (
        <HandleButton {...props} />
      )}
    </Grid>
  );
}

export default AddFromDeal;
