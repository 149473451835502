import { useCallback, useEffect, useLayoutEffect, useRef, useState } from "react";
import { Card, Icon, Checkbox } from "@mui/material";

import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDDialog from "components/MDDialog";
import MDTypography from "components/MDTypography";

import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import DataTable from "examples/Tables/DataTable";
import { getWebSiteBlogs, getFeaturedBlogs, deleteBlog } from "store/thunk/solaWebsiteThunk";
import { getCategoryList, getTagList, getCountriesList } from "store/thunk/systemAdmin";
import { useAppDispatch, useAppSelector } from "store/store";
import { capitalizeFirstLetter, getLocalDateTime } from "helper/services";
import Messages from "helper/messages";
import exportData from "helper/exportTableConst";
import MDIconButton from "components/MDIconButton";
import { getDefaultCoulmns, saveSelectedAllData } from "store/thunk/connectUserThunk";
import { setDefaultsColumns } from "store/slices/connectUsersSlice";
import AddUpdate from "./addUpdate";
import EditFeaturedBlog from "./editFeaturedBlog";
import ViewDetails from "./viewDetails";

function Index(): JSX.Element {
  const dispatch = useAppDispatch();
  const { webBlogs, featuredBlogsList } = useAppSelector((state) => state.solaWebSiteSlice);
  const { userData } = useAppSelector((state) => state.authReducer);
  const [blogs, setBlogs] = useState<any>([]);
  const [featuredBlogs, setFeaturedBlogs] = useState<any>([]);
  const [addUpdatePopup, setAddUpdatePopup] = useState<any>(false);
  const [editFeaturedPopup, setEditFeaturedPopup] = useState<any>(false);
  const [updateData, setUpdateData] = useState<any>(null);
  const [detailsPopup, setDetailsPopup] = useState<any>(false);
  const [deleteBlogPopup, setDeleteBlog] = useState<any>(false);
  const [selectedItems, setSelectedItems] = useState([]);
  const [pageCount, setPageCount] = useState(0);
  const [pageNo, setPageNo] = useState(0);
  const [perPage, setPageSize] = useState(50);
  const [totalCount, setTotalCount] = useState(0);
  const [search, setSearch] = useState<any>(null);
  const [sortBy, setSortBy] = useState<any>([]);
  const [isCheckAll, setIsCheckAll] = useState(false);
  const fetchIdRef = useRef(0);
  // SD-3199
  const [defaultExport, setDefaultExport] = useState<any>([]);
  const [callFetch, setCallFetch] = useState<any>(false);
  const [defaultSorting, setDefaultSorting] = useState<any>([]);
  const [defaultFilteing, setDefaultFilteing] = useState<any>([]);

  const getColumns = () => {
    const columns: any = [
      {
        Header: "",
        disableSortBy: true,
        accessor: "checkbox",
        export: false,
        width: "80px",
      },
      { Header: "Title", accessor: "title", width: "150px", export: true },
      { Header: "URL Name", accessor: "url_name", width: "150px", export: true },
      { Header: "Status", accessor: "status", export: true },
      { Header: "Summary", accessor: "summary", export: true },
      { Header: "Author", accessor: "author", export: true },
      { Header: "Created at", accessor: "created_at", export: true, is_date: true },
      { Header: "Action", accessor: "action", export: false, width: "200px", disableSortBy: true },
    ];
    return columns;
  };

  const closeModal = () => {
    setUpdateData("");
    setAddUpdatePopup(false);
  };

  const closeFeaturedModal = () => {
    setEditFeaturedPopup(false);
  };

  const openEditFeaturedBlogModal = () => {
    // setUpdateData(data);
    setEditFeaturedPopup(true);
  };

  const openEditModal = (data: any) => {
    setUpdateData(data);
    setAddUpdatePopup(true);
  };

  const openDeleteModal = (data: any) => {
    setUpdateData(data);
    setDeleteBlog(true);
  };

  const openDetailPopup = (data: any) => {
    setUpdateData(data);
    setDetailsPopup(true);
  };
  const closeDetailPopup = () => {
    setUpdateData("");
    setDetailsPopup(false);
  };

  useEffect(() => {
    if (webBlogs && webBlogs?.blogs) {
      setBlogs(webBlogs?.blogs);
      setPageCount(webBlogs?.meta?.total_pages);
      setTotalCount(webBlogs?.meta?.total_count);
    }
  }, [webBlogs]);

  useEffect(() => {
    if (featuredBlogsList && featuredBlogsList?.data) {
      setFeaturedBlogs(featuredBlogsList?.data);
    }
  }, [featuredBlogsList]);

  const fetchData = useCallback(({ pageSize, pageIndex, search, sortBy, filterData, isManual }) => {
    const fetchId = ++fetchIdRef.current;
    const sort: any = sortBy || [];
    // We'll even set a delay to simulate a server here
    setTimeout(() => {
      if (fetchId === fetchIdRef.current) {
        const sentReq = {
          sendData: {
            user_id: userData?.user_id,
            table_name: exportData.blogs.table_name,
            table_columns: getColumns(),
            table_sort: sortBy && sortBy?.length ? sortBy : [],
            table_filter: filterData && filterData?.length ? filterData : [],
            default_export_data: defaultExport,
          },
        };
        if (isManual) {
          dispatch(saveSelectedAllData(sentReq));
        }
        setPageNo(pageIndex);
        setSearch(search);
        setSortBy(sort);
        setPageSize(pageSize);
        // setSelectedItems([]);
        setIsCheckAll(false);
        dispatch(getWebSiteBlogs({ pageSize, pageIndex, search, sort, filterData }));
        dispatch(getFeaturedBlogs({ pageSize, pageIndex, search, sort, filterData }));
        dispatch(getCategoryList({ all: true }));
        dispatch(getTagList({ all: true }));
        dispatch(getCountriesList({ all: true }));
      }
    }, 1000);
  }, []);

  useLayoutEffect(() => {
    dispatch(setDefaultsColumns([]));
    // Set data in variable and Call defaultColums Thunk
    const sentReq = {
      user_id: userData?.user_id,
      table_name: exportData.blogs.table_name,
    };
    dispatch(getDefaultCoulmns(sentReq)).then((resp: any) => {
      // SD-3199
      if (resp?.payload) {
        const respFilter = resp?.payload?.table_filter || [];
        const respSorting = resp?.payload?.table_sort || [];
        const defaultDBexportData = resp?.payload?.default_export_data || [];
        setDefaultExport(defaultDBexportData);
        setDefaultSorting(respSorting);
        setDefaultFilteing(respFilter);
        setCallFetch(true);
        setSortBy(respSorting);
        fetchData({
          perPage,
          pageNo,
          search,
          sortBy: respSorting && respSorting?.length ? respSorting : [],
          additionalField: null,
          filterData: respFilter && respFilter?.length ? respFilter : [],
        });
      }
    });
  }, []);

  const actionButtons = (object: any) => (
    <>
      <MDIconButton
        tooltipName="View"
        aria-label="Info"
        color="info"
        onClick={() => openDetailPopup(object)}
      >
        <Icon fontSize="small">visibility</Icon>
      </MDIconButton>
      <MDIconButton
        tooltipName="Edit"
        aria-label="Edit"
        color="info"
        onClick={() => openEditModal(object)}
      >
        <Icon fontSize="small">edit</Icon>
      </MDIconButton>
      <MDIconButton
        tooltipName="Delete"
        aria-label="Delete"
        color="error"
        onClick={() => openDeleteModal(object)}
      >
        <Icon fontSize="small">delete</Icon>
      </MDIconButton>
    </>
  );

  const onChangeCheckBox = (e: any) => {
    let items = [...selectedItems];
    if (e.target.checked && !items.includes(e.target.value)) {
      items.push(e.target.value);
      if (items.length === blogs.length) {
        setIsCheckAll(true);
      }
    } else {
      items = items.filter((id: any) => id !== e.target.value);
      setIsCheckAll(false);
    }
    setSelectedItems(items);
  };

  const checkUncheckAll = (e: any) => {
    let allIds: any = [];
    allIds = blogs.map((val: any) => String(val.id));
    if (e.target.checked) {
      const selectedIds = [...new Set([...selectedItems, ...allIds])];
      setSelectedItems(selectedIds);
      setIsCheckAll(true);
    } else {
      allIds = blogs.map((val: any) => String(val.id));
      allIds = selectedItems.filter((el: any) => !allIds.includes(el));
      setSelectedItems([...allIds]);
      setIsCheckAll(false);
    }
    // setSelectedItems(allIds);
  };

  const getRows = () => {
    const data: any = blogs.map((obj: any) => ({
      checkbox: (
        <Checkbox
          checked={selectedItems.includes(obj.id) || selectedItems.includes(obj.id.toString())}
          onChange={(e: any) => onChangeCheckBox(e)}
          value={obj.id}
        />
      ),
      // For check all condition
      id: obj.id ? obj.id : "-",
      title: obj.title ? obj.title : "-",
      url_name: obj.url_name ? obj.url_name : "-",
      status: obj.status ? capitalizeFirstLetter(obj.status) : "-",
      summary: obj.summary ? obj.summary : "-",
      author: obj.author ? obj.author : "-",
      created_at: obj.created_at ? getLocalDateTime(obj.created_at) : "-",
      action: actionButtons(obj),
    }));
    return data;
  };

  const tableData = {
    columns: getColumns(),
    rows: getRows(),
  };

  // Start Remove selcted items
  const removeSelectedItem = (deletedId: number) => {
    setSelectedItems(selectedItems.filter((item: any) => Number(item) !== deletedId));
  };
  // End Remove selcted items

  const onDelete = () => {
    if (updateData?.id) {
      const delReq = {
        id: updateData?.id,
        setDeleteBlog,
        pageNo,
        perPage,
        search,
        sortBy,
        removeSelectedItem,
      };
      dispatch(deleteBlog(delReq));
    }
  };

  const deleteDialogProps = {
    open: deleteBlogPopup,
    onClose: () => setDeleteBlog(false),
    onSave: () => onDelete(),
    title: "Delete Blog",
    size: "sm",
    saveBtn: false,
    deleteBtn: true,
    saveTbtText: "Yes",
    closeBtnText: "No",
  };

  // Remove uncheck boxes
  const clearCheckItem = () => {
    setSelectedItems([]);
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={4} pb={3}>
        <Card>
          <MDBox
            bgColor="dark"
            color="white"
            coloredShadow="dark"
            borderRadius="xl"
            alignItems="center"
            justifyContent="space-between"
            pt={1}
            pb={1}
            pl={2}
            pr={2}
            ml={2}
            mr={2}
            display="flex"
            mt={-3}
            className="page-header"
          >
            <p className="page-header-label">Blogs</p>
            <MDBox>
              <MDButton
                variant="gradient"
                color="light"
                size="small"
                className="xs-small"
                onClick={() => openEditFeaturedBlogModal()}
                sx={{ mr: 2 }}
              >
                Edit Featured Blog
              </MDButton>
              <MDButton
                variant="gradient"
                color="light"
                size="small"
                className="xs-small"
                onClick={() => setAddUpdatePopup(true)}
              >
                <Icon sx={{ fontWeight: "bold" }}>add</Icon> Add New
              </MDButton>
            </MDBox>
          </MDBox>
          {(defaultSorting || defaultSorting?.length) &&
            callFetch && ( // SD-3199
              <DataTable
                defaultSortingColumns={defaultSorting} // SD-3199
                defaultFilteringColums={defaultFilteing} // SD-3199
                table={tableData}
                canSearch
                searchText="Search blogs..."
                selectedItems={selectedItems}
                showExportSelectedBtn
                showExportAllBtn
                pageCount={pageCount}
                pageNo={pageNo}
                setPageNo={setPageNo}
                totalCount={totalCount}
                fetchData={fetchData}
                exportFields={exportData.blogs.export_fields}
                exportTable={exportData.blogs.table_name}
                exportType={exportData.blogs.export_type}
                advanceFilter
                manualSearch
                manualSort
                isDisplayCheckAllCheckbox
                onChangeCheckAllCheckbox={checkUncheckAll}
                isCheckAll={isCheckAll}
                clearCheckItem={clearCheckItem}
                useRubyApiToExport
              />
            )}
          {editFeaturedPopup && (
            <EditFeaturedBlog
              open={editFeaturedPopup}
              updateData={updateData}
              featuredBlogs={featuredBlogs}
              blogs={blogs}
              pageNo={pageNo}
              perPage={perPage}
              search={search}
              sortBy={sortBy}
              onClose={() => closeFeaturedModal()}
            />
          )}
          {addUpdatePopup && (
            <AddUpdate
              open={addUpdatePopup}
              updateData={updateData}
              pageNo={pageNo}
              perPage={perPage}
              search={search}
              sortBy={sortBy}
              onClose={() => closeModal()}
            />
          )}
          {detailsPopup && (
            <ViewDetails
              open={detailsPopup}
              updateData={updateData}
              onClose={() => closeDetailPopup()}
            />
          )}
          {deleteBlogPopup && (
            <MDDialog {...deleteDialogProps}>
              <MDTypography variant="h6" fontWeight="medium">
                {Messages.GENERAL.SURE_TO_DELETE}
              </MDTypography>
            </MDDialog>
          )}
        </Card>
      </MDBox>
    </DashboardLayout>
  );
}

export default Index;
