// @mui material components
import { useEffect, useState } from "react";
import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";
import { Theme } from "@mui/material/styles";

// Material Dashboard 2 PRO React TS components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// import helper services for scroll to section
import { ScrollToSection } from "helper/services";

// Material Dashboard 2 PRO React context
import { useMaterialUIController } from "context";

function Sidenav(props: any): JSX.Element {
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;
  const { crmTabSettings } = props;
  const [sidenavItems, setSidenavItems] = useState([]);

  useEffect(() => {
    const items = [];
    items.push(
      { icon: "person", label: "Deal Owner", href: "deal-owner" },
      { icon: "person", label: "Weekend", href: "weekend" },
      { icon: "receipt", label: "Automatic Enrollment", href: "automatic-enrollment" },
      { icon: "sms", label: "communication delay", href: "communication-delay" },
      { icon: "receipt_long", label: "initial response", href: "initial-response" }
    );

    if (crmTabSettings?.followups?.length) {
      crmTabSettings.followups.map((_: any, key: number) =>
        items.push({
          icon: "receipt_long",
          label: `follow-up #${key + 1}`,
          href: `follow-up-${key + 1}`,
        })
      );
    }
    items.push({ icon: "settings", label: "Template Settings", href: "template-settings" });
    setSidenavItems(items);
  }, [crmTabSettings]);

  const renderSidenavItems = sidenavItems.map(({ icon, label, href }, key) => {
    const itemKey = `item-${key}`;

    return (
      <MDBox key={itemKey} component="li" pt={key === 0 ? 0 : 1}>
        <MDTypography
          component="a"
          onClick={() => ScrollToSection(href)}
          variant="button"
          fontWeight="regular"
          textTransform="capitalize"
          sx={({
            borders: { borderRadius },
            functions: { pxToRem },
            palette: { light },
            transitions,
          }: Theme) => ({
            display: "flex",
            alignItems: "center",
            cursor: "pointer",
            borderRadius: borderRadius.md,
            padding: `${pxToRem(10)} ${pxToRem(16)}`,
            transition: transitions.create("background-color", {
              easing: transitions.easing.easeInOut,
              duration: transitions.duration.shorter,
            }),

            "&:hover": {
              backgroundColor: light.main,
            },
          })}
        >
          <MDBox mr={1.5} lineHeight={1} color={darkMode ? "white" : "dark"}>
            <Icon fontSize="small">{icon}</Icon>
          </MDBox>
          {label}
        </MDTypography>
      </MDBox>
    );
  });

  return (
    <Card
      sx={{
        borderRadius: ({ borders: { borderRadius } }) => borderRadius.lg,
        position: "sticky",
        top: "100px",
      }}
    >
      <MDBox
        component="ul"
        display="flex"
        flexDirection="column"
        p={2}
        m={0}
        sx={{ listStyle: "none" }}
      >
        {renderSidenavItems}
      </MDBox>
    </Card>
  );
}

export default Sidenav;
