import { useEffect, useState } from "react";
import MDDialog from "components/MDDialog";
import {
  Checkbox,
  Grid,
  Icon,
  ListItem,
  ListItemButton,
  ListItemText,
  styled,
} from "@mui/material";
import MDTypography from "components/MDTypography";
import Accordion, { AccordionProps } from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary, { AccordionSummaryProps } from "@mui/material/AccordionSummary";
import MDBox from "components/MDBox";
import ImagePreview from "layouts/pages/ImagePreview";
import { useAppSelector } from "store/store";
import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import CustomErrorMessage from "components/CustomErrorMessage";
import * as React from "react";

const AccordionUser = styled((props: AccordionProps) => (
  <Accordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  "&:before": {
    display: "none",
  },
}));

const AccordionSummaryUser = styled((props: AccordionSummaryProps) => (
  <AccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: "0.9rem" }} />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === "dark" ? "rgba(255, 255, 255, .05)" : "rgba(0, 0, 0, .03)",
  flexDirection: "row-reverse",
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(90deg)",
  },
  "& .MuiAccordionSummary-content": {
    marginLeft: theme.spacing(1),
  },
}));

const AccordionDetailsUser = styled(AccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: "1px solid rgba(0, 0, 0, .125)",
}));

function ViewDetails(props: any): JSX.Element {
  const { webPageDetails } = useAppSelector((state) => state.solaProSlice);
  const { open, onClose } = props;
  const [webPageData, setWebPageData] = useState<any>({});
  const [isPreview, setImagePreviewPopup] = useState(false);
  const [imageToPreview, setImageToPreview] = useState("");
  const [expanded, setExpanded] = useState<any>("panel1");

  const handleChangeAccordian =
    (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : false);
    };

  useEffect(() => {
    if (webPageDetails) {
      const permissionData: any = [];
      webPageDetails?.connect_user_webpages?.map((item: any) => {
        item?.user_detail?.locations.map((xItem: any) => {
          permissionData.push({
            connect_user_id: item?.connect_user_id,
            location_id: xItem?.id,
            is_show: xItem?.is_show || false,
          });
          return true;
        });
        return true;
      });

      setWebPageData({
        ...webPageDetails,
        work_hours_data: webPageDetails?.connect_webpage_work_hours,
        connect_users: webPageDetails?.connect_user_webpages?.map((obj: any) => ({
          user_id: String(obj?.connect_user_id),
          login_email: obj?.user_detail?.login_email,
          locations: obj?.user_detail?.locations,
        })),
        location_permission_data: permissionData,
      });
    }
  }, [webPageDetails]);

  const dialogProps = {
    open,
    onClose,
    saveBtn: false,
    title: "View Webpage",
    size: "lg",
  };

  const previewImage = (image: any) => {
    setImagePreviewPopup(true);
    setImageToPreview(image);
  };

  const closeImagePreview = () => {
    setImagePreviewPopup(false);
    setImageToPreview("");
  };

  const getIcon = (value: any) => {
    if (value) {
      return (
        <Icon fontSize="medium" color="success">
          check_circle_rounded
        </Icon>
      );
    }
    return (
      <Icon fontSize="medium" color="error">
        cancel
      </Icon>
    );
  };

  const getImageElement = (name: any, imageData: any) => {
    if (imageData) {
      return (
        <Grid item xs={12} sm={3} md={3}>
          <MDTypography fontWeight="bold" variant="button">
            {name}
          </MDTypography>
          <Grid container mt={2}>
            <Grid item xs={12} sm={6} md={6}>
              {imageData && (
                <Grid
                  container
                  onClick={() => previewImage(imageData)}
                  // alignItems="center"
                  // display="flex"
                  className="thumbnail-img"
                >
                  <Grid item>
                    <img height={100} width={100} src={imageData} alt="blog" />
                  </Grid>
                </Grid>
              )}
            </Grid>
          </Grid>
        </Grid>
      );
    }
    return null;
  };

  const getDetailElement = (name: any, value: any) => (
    <Grid item xs={12} sm={6} md={6}>
      <MDTypography fontWeight="bold" variant="button">
        {name}
      </MDTypography>
      <MDBox sx={{ wordBreak: "break-all" }}>{value ? value : "-"}</MDBox>
    </Grid>
  );

  const getDetailElementWithDangersoulyHTML = (name: any, value: any) => (
    <Grid item xs={12} sm={12} md={12}>
      <MDTypography fontWeight="bold" variant="button">
        {name}
      </MDTypography>
      <MDBox
        sx={{ border: "1px solid #eee", overflow: "auto" }}
        p={2}
        dangerouslySetInnerHTML={{
          __html: value ? value : "-",
        }}
      />
    </Grid>
  );

  const getIconElement = (name: any, iconValue: any) => (
    <Grid item xs={12} sm={6} md={6}>
      <MDTypography fontWeight="bold" variant="button">
        {name}
      </MDTypography>
      <MDBox>{getIcon(iconValue)}</MDBox>
    </Grid>
  );

  const getAccordianSummary = (name: any) => (
    <AccordionSummary className="location-accordion">
      <MDTypography>{name}</MDTypography>
    </AccordionSummary>
  );

  const getDetailElementWithSm = (name: any, value: any) => {
    const element: any = [];
    element.push(
      <Grid item xs={2} sm={2} md={2}>
        <MDTypography fontWeight="bold" variant="button">
          {name}
        </MDTypography>
        <MDBox sx={{ wordBreak: "break-all" }}>{value ? value : "-"}</MDBox>
      </Grid>
    );

    return element;
  };

  const getIconElementWithSm = (name: any, iconValue: any) => {
    const element: any = [];
    element.push(
      <Grid item xs={2} sm={2} md={2}>
        <MDTypography fontWeight="bold" variant="button">
          {name}
        </MDTypography>
        <MDBox>{getIcon(iconValue)}</MDBox>
      </Grid>
    );
    return element;
  };

  const getLocationShowIsTrue = (connectUserId?: any, locationId?: any) =>
    webPageData?.location_permission_data?.some(
      (obj: any) =>
        Number(obj?.location_id) === Number(locationId) &&
        Number(obj?.connect_user_id) === Number(connectUserId) &&
        obj?.is_show
    );

  return (
    <MDDialog {...dialogProps}>
      <Grid container spacing={2}>
        <Grid item xs={12} md={12}>
          <MDBox mt={1}>
            <MDBox>
              <Accordion expanded>
                {getAccordianSummary("Website")}
                <AccordionDetails>
                  <Grid container spacing={2}>
                    {getDetailElement("Website Email Address", webPageData?.website_email)}
                    {getDetailElement("Website Phone Number", webPageData?.website_phone)}
                    {getDetailElement("Subtitle", webPageData?.subtitle)}
                    {getDetailElementWithDangersoulyHTML("Description", webPageData?.description)}
                    {getDetailElement("Url", webPageData?.url)}
                  </Grid>
                </AccordionDetails>
              </Accordion>
            </MDBox>
          </MDBox>
          <MDBox mt={1}>
            <MDBox>
              <Accordion expanded>
                {getAccordianSummary("Integrations")}
                <AccordionDetails>
                  <Grid container spacing={2}>
                    {getDetailElement("Website Url", webPageData?.website_url)}
                    {getDetailElement("Booking Url", webPageData?.booking_url)}
                  </Grid>
                </AccordionDetails>
              </Accordion>
            </MDBox>
          </MDBox>
          <MDBox mt={1}>
            <MDBox>
              <Accordion expanded>
                {getAccordianSummary("Business")}
                <AccordionDetails>
                  <Grid container spacing={2}>
                    {getDetailElement("Salon Name", webPageData?.business_name)}
                    {getDetailElement("Studio Number", webPageData?.studio_number)}
                    {getIconElement("Visible On Site", webPageData?.reserved)}
                    {getIconElement("Accepting New Clients", webPageData?.accepting_new_clients)}
                    {getIconElement("Walkins", webPageData?.walkins)}
                    {getIconElement("Send A Message Button", webPageData?.send_a_message_button)}
                  </Grid>
                </AccordionDetails>
              </Accordion>
            </MDBox>
          </MDBox>
          <MDBox mt={1}>
            <MDBox>
              <Accordion expanded>
                {getAccordianSummary("Social")}
                <AccordionDetails>
                  <Grid container spacing={2}>
                    {getDetailElement("Facebook Url", webPageData?.facebook_url)}
                    {getDetailElement("Google Plus Url", webPageData?.google_plus_url)}
                    {getDetailElement("Instagram Url", webPageData?.instagram_url)}
                    {getDetailElement("Linkedin Url", webPageData?.linkedin_url)}
                    {getDetailElement("Pinterest Url", webPageData?.pinterest_url)}
                    {getDetailElement("Twitter Url", webPageData?.twitter_url)}
                    {getDetailElement("Yelp Url", webPageData?.yelp_url)}
                    {getDetailElement("Tik Tok Url", webPageData?.tik_tok_url)}
                  </Grid>
                </AccordionDetails>
              </Accordion>
            </MDBox>
          </MDBox>
          <MDBox mt={1}>
            <MDBox>
              <Accordion expanded>
                {getAccordianSummary("Users")}
                <AccordionDetails>
                  <Grid container spacing={2} mt={0}>
                    {webPageData?.connect_user_webpages?.map((obj: any, inx: any) => (
                      <AccordionUser
                        expanded={expanded === `user_${inx + 1}`}
                        sx={{
                          marginTop: "20px",
                          marginLeft: "20px",
                          width: "100%",
                        }}
                        id={`user_${inx + 1}`}
                        className={`user_${inx + 1}`}
                        onChange={handleChangeAccordian(`user_${inx + 1}`)}
                      >
                        <AccordionSummaryUser aria-controls="testimonial-content">
                          <MDTypography variant="h6">{`${obj?.user_detail?.first_name || ""} ${
                            obj?.user_detail?.last_name || ""
                          }`}</MDTypography>
                        </AccordionSummaryUser>
                        <AccordionDetailsUser>
                          <Grid item xs={12} sm={12} md={12}>
                            <Grid container>
                              <Grid
                                item
                                xs={12}
                                sm={12}
                                md={12}
                                display="flex"
                                justifyContent="space-between"
                                alignItems="center"
                              >
                                <MDTypography variant="body2" fontWeight="regular" />
                              </Grid>
                            </Grid>
                            <Grid container spacing={3}>
                              {obj?.connect_user_services?.map((childObj: any) =>
                                getIconElement(childObj?.label, true)
                              )}
                            </Grid>
                          </Grid>
                        </AccordionDetailsUser>
                      </AccordionUser>
                    ))}
                  </Grid>
                </AccordionDetails>
              </Accordion>
            </MDBox>
          </MDBox>
          <MDBox mt={1}>
            <MDBox>
              <Accordion expanded>
                {getAccordianSummary("User Locations")}
                <AccordionDetails>
                  <Grid container>
                    <Grid item xs={12} sm={12} md={12} lg={12}>
                      <CustomErrorMessage
                        errorColor="inherit"
                        message="Note: This webpage will appear for the follow Locations on our website."
                      />
                    </Grid>
                    {webPageData?.connect_users?.length > 0 ? (
                      webPageData?.connect_users?.map((users: any) => (
                        <Grid item xs={6} sm={6} md={6} lg={6}>
                          <Accordion className="accordian-user-loc" expanded>
                            <AccordionSummary>
                              <MDTypography variant="h6">{users?.login_email}</MDTypography>
                            </AccordionSummary>
                          </Accordion>
                          <AccordionDetails>
                            {users?.locations?.length > 0 ? (
                              users?.locations?.map((value: any, index: any) => (
                                <ListItem
                                  sx={{ pl: 1 }}
                                  key={value?.name}
                                  secondaryAction={
                                    <Checkbox
                                      edge="end"
                                      checked={
                                        getLocationShowIsTrue(users?.user_id, value?.id) || false
                                      }
                                      inputProps={{ "aria-labelledby": index }}
                                    />
                                  }
                                >
                                  <ListItemButton>
                                    <ListItemText>
                                      <MDTypography variant="h6">{value?.name}</MDTypography>
                                    </ListItemText>
                                  </ListItemButton>
                                </ListItem>
                              ))
                            ) : (
                              <ListItemButton>
                                <ListItemText>
                                  <MDTypography variant="h6">No Location Found</MDTypography>
                                </ListItemText>
                              </ListItemButton>
                            )}
                          </AccordionDetails>
                        </Grid>
                      ))
                    ) : (
                      <ListItemText sx={{ pl: 3, pt: 2 }}>
                        <MDTypography variant="h6">No User Selected</MDTypography>
                      </ListItemText>
                    )}
                  </Grid>
                </AccordionDetails>
              </Accordion>
            </MDBox>
          </MDBox>
          <MDBox mt={1}>
            <MDBox>
              <Accordion expanded>
                {getAccordianSummary("User Services")}
                <AccordionDetails>
                  <Grid container spacing={2}>
                    {webPageData?.connect_services?.map((obj: any) =>
                      getIconElement(obj?.label, obj?.isActive)
                    )}
                  </Grid>
                </AccordionDetails>
              </Accordion>
            </MDBox>
          </MDBox>
          <MDBox mt={1}>
            <MDBox>
              <Accordion expanded>
                {getAccordianSummary("Images")}
                <AccordionDetails>
                  <Grid container spacing={2}>
                    <Grid item xs={12} style={{ display: "flex", flexWrap: "wrap" }}>
                      {[...Array(10)].map(
                        (obj: any, index: any) =>
                          webPageData?.[`image_${index + 1}_url`] &&
                          getImageElement(
                            `Image ${index + 1}`,
                            webPageData?.[`image_${index + 1}_url`]
                          )
                      )}
                    </Grid>
                  </Grid>
                </AccordionDetails>
              </Accordion>
            </MDBox>
          </MDBox>
          <MDBox mt={1}>
            <MDBox>
              <Accordion expanded>
                {getAccordianSummary("Banner Image")}
                <AccordionDetails>
                  <Grid container spacing={2}>
                    <Grid>
                      <img
                        height={100}
                        width={100}
                        src={webPageData?.banner_image_url}
                        alt="banner"
                      />
                    </Grid>
                  </Grid>
                </AccordionDetails>
              </Accordion>
            </MDBox>
          </MDBox>
          <MDBox mt={1}>
            <MDBox>
              <Accordion expanded>
                {getAccordianSummary("Testimonials")}
                <AccordionDetails>
                  <Grid container spacing={2}>
                    {[...Array(10)].map((obj: any, index: any) =>
                      getDetailElement(
                        `Testimonial ${index + 1}`,
                        webPageData?.[`testimonial_${index + 1}`]?.name
                      )
                    )}
                  </Grid>
                </AccordionDetails>
              </Accordion>
            </MDBox>
          </MDBox>
          <MDBox mt={1}>
            <MDBox>
              <Accordion expanded>
                {getAccordianSummary("Work Hours")}
                <AccordionDetails>
                  <Grid container spacing={2}>
                    {[
                      "monday",
                      "tuesday",
                      "wednesday",
                      "thursday",
                      "friday",
                      "saturday",
                      "sunday",
                    ].map((obj: any) => [
                      ...getDetailElementWithSm("Day", obj.charAt(0).toUpperCase() + obj.slice(1)),
                      ...getIconElementWithSm(
                        "Open",
                        webPageDetails?.connect_webpage_work_hours?.[obj]?.open || false
                      ),
                      ...getDetailElementWithSm(
                        "Open At 1",
                        webPageDetails?.connect_webpage_work_hours?.[obj]?.opens_at_1 || "-"
                      ),
                      ...getDetailElementWithSm(
                        "Closes At 1",
                        webPageDetails?.connect_webpage_work_hours?.[obj]?.closes_at_1 || "-"
                      ),
                      ...getDetailElementWithSm(
                        "Open At 2",
                        webPageDetails?.connect_webpage_work_hours?.[obj]?.opens_at_2 || "-"
                      ),
                      ...getDetailElementWithSm(
                        "Closes At 2",
                        webPageDetails?.connect_webpage_work_hours?.[obj]?.closes_at_2 || "-"
                      ),
                    ])}
                  </Grid>
                </AccordionDetails>
              </Accordion>
            </MDBox>
          </MDBox>
        </Grid>
      </Grid>
      <ImagePreview
        imageData={imageToPreview}
        open={isPreview}
        onCloseImage={() => {
          closeImagePreview();
        }}
      />
    </MDDialog>
  );
}

export default ViewDetails;
