import { Grid, Typography } from "@mui/material";
import MDBox from "components/MDBox";

function formatCurrency(amount: number, currencySymbol: string = "$"): string {
  // Convert the amount to a string
  let formattedAmount = amount?.toString();

  // Split the string into whole and decimal parts
  const parts = formattedAmount?.split(".");
  let wholePart = parts ? parts[0] : null;
  const decimalPart = parts ? parts[1] : null;

  // Add commas to the whole part every three digits
  wholePart = wholePart?.replace(/\B(?=(\d{3})+(?!\d))/g, ",");

  // Concatenate the whole and decimal parts with the decimal separator
  formattedAmount = wholePart + (decimalPart?.length ? `.${decimalPart}` : "");

  // Add the currency symbol to the formatted amount
  formattedAmount = currencySymbol + formattedAmount;

  return formattedAmount;
}
interface RenderCardColumnsProps {
  keys: string[];
  selectedScorecard: {
    [key: string]: number | string;
  };
}

function RenderCardColumns({ keys, selectedScorecard }: RenderCardColumnsProps) {
  return (
    <Grid item md={4}>
      <MDBox display="flex" flexDirection="column">
        {keys.map((key) => (
          <MDBox key={key} sx={{ paddingBottom: { md: 1 } }}>
            <Typography variant="body2">
              {["total_gross", "revenue_occupied_chair", "revenue_total_chair"].includes(key)
                ? formatCurrency(selectedScorecard[key] as number)
                : key === "occupancy_percentage" || key === "lease_expense_percentage"
                ? selectedScorecard[key] !== null && selectedScorecard[key] !== undefined
                  ? `${selectedScorecard[key]}%`
                  : "0%"
                : selectedScorecard[key]}
            </Typography>
          </MDBox>
        ))}
      </MDBox>
    </Grid>
  );
}

export default RenderCardColumns;
