import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "store/store";
import MDInput from "components/MDInput";
import CustomPhoneInput from "components/CustomPhoneInput";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import {
  InputLabel,
  Select,
  MenuItem,
  Checkbox,
  Grid,
  FormControl,
  Card,
  RadioGroup,
  FormControlLabel,
  Radio,
} from "@mui/material";
import Validations from "helper/validations";
import { saveContactIquiries, getContactIquiryById } from "store/thunk/systemAdmin";
import { setContactIquiryData } from "store/slices/systemAdminSlice";
import MDTypography from "components/MDTypography";
import MDDatePicker from "components/MDDatePicker";
import {
  getAllAssociationLocations,
  scrollToErrorByClass,
  getPermission,
  adminSuperAdmin,
} from "helper/services";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import CustomBackButton from "components/CustomBackButton";
import CustomAutoSearch from "components/CustomAutoSearch";
import CustomErrorMessage from "components/CustomErrorMessage";

const idealTimes = [
  "As soon as possible",
  "Within 2 months",
  "2-6 months",
  "7-11 months",
  "After 12 months",
  "Unsure",
];

function AddUpdate(): JSX.Element {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const stateData: any = useLocation();
  const { contactIquiryData } = useAppSelector((state) => state.systemAdminSlice);
  const [isUpdate, setIsUpdate] = useState<any>(false);
  const [contactInquiries, setContactInquiries] = useState<any>({});
  const [errors, setErrors] = useState<any>({});

  useEffect(() => {
    if (contactIquiryData) {
      const tempData = JSON.parse(JSON.stringify(contactIquiryData));
      tempData.location = tempData?.location_id
        ? { id: contactIquiryData?.location_id, name: contactIquiryData?.location_name }
        : null;
      tempData.services = tempData?.services?.length
        ? tempData?.services.replace(", ", ",").split(",")
        : [];
      setContactInquiries(tempData);
    } else {
      setContactInquiries({});
    }
  }, [contactIquiryData]);

  useEffect(() => {
    if (stateData?.state?.id) {
      setIsUpdate(true);
      dispatch(getContactIquiryById({ id: stateData?.state?.id }));
    } else {
      setContactInquiries({});
      dispatch(setContactIquiryData({}));
    }
  }, [stateData]);

  const onCallBack = () => {
    dispatch(setContactIquiryData({}));
    navigate(`/crm/contact-inquiries`);
  };

  const onSave = () => {
    const error = Validations.validateContactInquiriesOldCms(contactInquiries);
    setErrors(error);
    if (!Object.keys(error).length) {
      const tempData = JSON.parse(JSON.stringify(contactInquiries));
      tempData.location_id = tempData?.location?.id;
      tempData.services = tempData?.services?.length ? tempData?.services.join(", ") : "";
      const req = {
        contactInquiriesData: tempData,
        isUpdate,
        callback: onCallBack,
      };
      dispatch(saveContactIquiries(req));
    } else {
      scrollToErrorByClass(error);
    }
  };

  const onChangeField = (e: any, field: string) => {
    setContactInquiries({ ...contactInquiries, [field]: e.target.value });
  };

  const onChangeCheckBoxField = (e: any, field: string) => {
    setContactInquiries({ ...contactInquiries, [field]: e.target.checked });
  };

  const onChangeHowCanWeHelp = (e: any, field: string) => {
    setContactInquiries({ ...contactInquiries, [field]: e.target.value, services: [] });
  };

  const onChangeDate = (value: any) => {
    setContactInquiries({ ...contactInquiries, created_at: new Date(value).toISOString() });
  };

  const onChangeAutoComplete = (name: string, value: any) => {
    setContactInquiries({ ...contactInquiries, [name]: value });
  };

  const onChangeService = (e: any) => {
    let tempServices = contactInquiries?.services || [];
    if (e.target.checked) {
      tempServices.push(e.target.value);
    } else {
      tempServices = tempServices.filter((s: string) => s !== e.target.value);
    }
    setContactInquiries({ ...contactInquiries, services: tempServices });
  };

  const getServices = () => {
    let services: any = [];
    if (contactInquiries?.how_can_we_help_you === "Request leasing information") {
      services = [
        "Hair",
        "Skincare",
        "Makeup",
        "Massage",
        "Nails",
        "Lashes",
        "Waxing",
        "Permanent Makeup / Tattoo",
        "Other",
      ];
    } else if (
      contactInquiries?.how_can_we_help_you === "Book an appointment with a salon professional"
    ) {
      services = [
        "Hair",
        "Highlights (Color)",
        "Nails",
        "Eyelash Extensions",
        "Pedicure",
        "Lashes",
        "Waxing",
        "Permanent Makeup / Tattoo",
        "Other",
      ];
    }
    if (services?.length) {
      return services?.map((s: any) => (
        <Grid item xs={6} sm={4} md={4} lg={4} key={s}>
          <FormControl component="fieldset">
            <FormControlLabel
              value={s}
              control={
                <Checkbox
                  checked={contactInquiries?.services?.includes(s) || false}
                  name="services"
                  onChange={onChangeService}
                  inputProps={{ "aria-label": "controlled" }}
                />
              }
              label={s}
              labelPlacement="end"
              sx={{ display: "flex" }}
            />
          </FormControl>
        </Grid>
      ));
    }
    return null;
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox mx={2} onClick={onCallBack}>
        <CustomBackButton
          label="Back To Contact Inquiries"
          historyState={{
            prevPath: "/crm/contact-inquiries",
          }}
        />
      </MDBox>
      <MDBox pt={4} pb={3}>
        <Card>
          <MDBox p={3}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <MDInput
                  label="Name"
                  variant="standard"
                  fullWidth
                  required
                  name="name"
                  onChange={(event: any) => onChangeField(event, "name")}
                  placeholder="name"
                  value={contactInquiries?.name || ""}
                  error={errors && errors.name ? true : false}
                  helperText={errors && errors.name}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <MDInput
                  label="Email"
                  variant="standard"
                  fullWidth
                  required
                  name="email"
                  onChange={(event: any) => onChangeField(event, "email")}
                  placeholder="email"
                  value={contactInquiries?.email || ""}
                  error={errors && errors.email ? true : false}
                  helperText={errors && errors.email}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <MDInput
                  variant="standard"
                  fullWidth
                  label="Phone"
                  name="phone"
                  placeholder="(000) 000-0000"
                  id="phone-input"
                  onChange={(event: any) => onChangeField(event, "phone")}
                  InputProps={{
                    inputComponent: CustomPhoneInput as any,
                  }}
                  value={contactInquiries?.phone || ""}
                  error={(errors && errors.phone) || false}
                  helperText={errors && errors.phone}
                  required
                />
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6} className="location">
                <CustomAutoSearch
                  idKey="id"
                  nameKey="name"
                  fieldLabel="Location"
                  apiName="locations"
                  responseKey="locations"
                  name="location"
                  required
                  value={contactInquiries?.location || null}
                  onChange={onChangeAutoComplete}
                  error={errors && errors.location}
                  usersData={getAllAssociationLocations()}
                  createNewLink={
                    adminSuperAdmin() && getPermission("locations", "locations_location")
                      ? "/locations"
                      : ""
                  }
                />
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <FormControl>
                  <MDTypography variant="button" fontWeight="regular" opacity={0.8}>
                    How Can We Help You? <span className="required_star">*</span>
                  </MDTypography>
                  <RadioGroup
                    name="how_can_we_help_you"
                    value={contactInquiries?.how_can_we_help_you || ""}
                    onChange={(event: any) => onChangeHowCanWeHelp(event, "how_can_we_help_you")}
                  >
                    <FormControlLabel
                      value="Request leasing information"
                      control={<Radio />}
                      label="Request leasing information"
                    />
                    <FormControlLabel
                      value="Book an appointment with a salon professional"
                      control={<Radio />}
                      label="Book an appointment with a salon professional"
                    />
                    <FormControlLabel value="Other" control={<Radio />} label="Other" />
                  </RadioGroup>
                  {errors && errors?.how_can_we_help_you && (
                    <CustomErrorMessage message={errors?.how_can_we_help_you} />
                  )}
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <Grid container>
                  {contactInquiries?.how_can_we_help_you &&
                    contactInquiries?.how_can_we_help_you !== "Other" && (
                      <Grid item xs={12} sm={12} md={12} lg={12}>
                        <MDTypography variant="button" fontWeight="regular" opacity={0.8}>
                          Services <span className="required_star">*</span>
                        </MDTypography>
                      </Grid>
                    )}
                  {getServices()}
                  {errors && errors?.services && <CustomErrorMessage message={errors?.services} />}
                </Grid>
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <MDInput
                  multiline
                  rows={3}
                  label="Message"
                  variant="outlined"
                  fullWidth
                  name="message"
                  onChange={(e: any) => onChangeField(e, "message")}
                  placeholder="Message"
                  value={contactInquiries?.message ? contactInquiries?.message : ""}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <FormControl fullWidth>
                  <InputLabel id="contact-preference-label">Contact Preference</InputLabel>
                  <Select
                    fullWidth
                    labelId="contact-preference-label"
                    variant="outlined"
                    id="contact_preference"
                    value={
                      contactInquiries?.contact_preference
                        ? contactInquiries?.contact_preference
                        : ""
                    }
                    label="Contact Preference"
                    sx={{ padding: 0 }}
                    onChange={(event: any) => onChangeField(event, "contact_preference")}
                    className="height45"
                  >
                    <MenuItem value="phone">Phone</MenuItem>
                    <MenuItem value="email">Email</MenuItem>
                    <MenuItem value="text">Text</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <MDInput
                  multiline
                  rows={3}
                  label="Request URL"
                  variant="outlined"
                  fullWidth
                  name="request_url"
                  onChange={(e: any) => onChangeField(e, "request_url")}
                  placeholder="Request URL"
                  value={contactInquiries?.request_url ? contactInquiries.request_url : ""}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <MDInput
                  label="State"
                  variant="standard"
                  fullWidth
                  name="state"
                  onChange={(event: any) => onChangeField(event, "state")}
                  placeholder="state"
                  value={contactInquiries?.state || ""}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <MDInput
                  label="Zip Code"
                  variant="standard"
                  fullWidth
                  name="zip_code"
                  onChange={(event: any) => onChangeField(event, "zip_code")}
                  placeholder="zip_code"
                  value={contactInquiries?.zip_code || ""}
                />
              </Grid>

              <Grid item xs={12} sm={12} md={6} lg={6}>
                <MDInput
                  label="Prospect Origin"
                  variant="standard"
                  fullWidth
                  name="send_email_to_prospect"
                  onChange={(event: any) => onChangeField(event, "send_email_to_prospect")}
                  placeholder="Prospect Origin"
                  value={contactInquiries?.send_email_to_prospect || ""}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <MDInput
                  label="UTM Source"
                  variant="standard"
                  fullWidth
                  name="utm_source"
                  onChange={(event: any) => onChangeField(event, "utm_source")}
                  placeholder="UTM Source"
                  value={contactInquiries?.utm_source || ""}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <MDInput
                  label="UTM Medium"
                  variant="standard"
                  fullWidth
                  name="utm_medium"
                  onChange={(event: any) => onChangeField(event, "utm_medium")}
                  placeholder="UTM Medium"
                  value={contactInquiries?.utm_medium || ""}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <MDInput
                  label="UTM Campaign"
                  variant="standard"
                  fullWidth
                  name="utm_campaign"
                  onChange={(event: any) => onChangeField(event, "utm_campaign")}
                  placeholder="UTM Campaign"
                  value={contactInquiries?.utm_campaign || ""}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <MDInput
                  label="UTM Content"
                  variant="standard"
                  fullWidth
                  name="utm_content"
                  onChange={(event: any) => onChangeField(event, "utm_content")}
                  placeholder="UTM Content"
                  value={contactInquiries?.utm_content || ""}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6} className="select-container">
                <FormControl fullWidth>
                  <InputLabel id="newsletter-label">Newsletter Subscription</InputLabel>
                  <Select
                    fullWidth
                    labelId="newsletter-label"
                    variant="outlined"
                    id="newsletter"
                    value={contactInquiries?.newsletter ? 1 : 0}
                    label="Newsletter Subscription"
                    sx={{ padding: 0 }}
                    onChange={(event: any) => onChangeField(event, "newsletter")}
                    className="height45"
                  >
                    <MenuItem value={1}>Yes</MenuItem>
                    <MenuItem value={0}>No</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6} display="flex" alignItems="center">
                <FormControl component="fieldset">
                  <FormControlLabel
                    value={contactInquiries?.dont_see_your_location}
                    control={
                      <Checkbox
                        checked={contactInquiries?.dont_see_your_location}
                        name="dont_see_your_location"
                        onChange={(event: any) =>
                          onChangeCheckBoxField(event, "dont_see_your_location")
                        }
                        inputProps={{ "aria-label": "controlled" }}
                      />
                    }
                    label="Don't See Your Location"
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <FormControl fullWidth>
                  <MDTypography mr={2} variant="button">
                    Created At
                  </MDTypography>
                  <MDDatePicker
                    onChange={(value: any) => onChangeDate(value)}
                    options={{
                      enableTime: false,
                      dateFormat: "m/d/Y",
                      time_24hr: true,
                    }}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6} className="select-container ideal-timing">
                <FormControl fullWidth>
                  <InputLabel id="newsletter-label">When are you looking to move?</InputLabel>
                  <Select
                    fullWidth
                    labelId="newsletter-label"
                    variant="outlined"
                    id="ideal_timing"
                    value={contactInquiries?.ideal_timing ? contactInquiries?.ideal_timing : ""}
                    label="When are you looking to move?"
                    sx={{ padding: 0 }}
                    onChange={(event: any) => onChangeField(event, "ideal_timing")}
                    className="height45"
                  >
                    {idealTimes.map((times, index) => (
                      <MenuItem key={`item_${index + 1}`} value={times}>
                        {times}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} display="flex" justifyContent="right" alignItems="flex-start">
                <MDButton
                  onClick={onSave}
                  variant="gradient"
                  color="info"
                  size="small"
                  className="fixed-button"
                >
                  {isUpdate ? "Update" : "Save"}
                </MDButton>
              </Grid>
            </Grid>
          </MDBox>
        </Card>
      </MDBox>
    </DashboardLayout>
  );
}

export default AddUpdate;
