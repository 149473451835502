import { Card, Icon } from "@mui/material";
import { useAppDispatch, useAppSelector } from "store/store";

import MDBox from "components/MDBox";

import DataTable from "examples/Tables/DataTable";
import MDTypography from "components/MDTypography";
import MDIconButton from "components/MDIconButton";
import { useCallback, useEffect, useState, useRef } from "react";
// import { useNavigate } from "react-router-dom";
// import { notificationFail } from "store/slices/notificationSlice";
import { setLeads } from "store/slices/leaseSlice";
// import MESSAGES from "helper/messages";
// import { getAccessPermission } from "helper/services";
// eslint-disable-next-line no-unused-vars
// import { getLeads } from "store/thunk/leaseThunk";

function Index(props: any): JSX.Element {
  const { stat, getLeadData, getViewble } = props;
  // const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { boardData } = useAppSelector((state) => state.dealSlice);
  const { leads } = useAppSelector((state) => state.leaseSlice);
  const [boarddata, setBoardData] = useState([]);
  const [pageNo, setPageNo] = useState(0);
  const [perPage, setPageSize] = useState(100);
  const [pageCount, setPageCount] = useState(0);
  const [totalCount, setTotalCount] = useState(0);

  const fetchIdRef = useRef(0);

  useEffect(() => {
    if (boardData && boardData.columns) {
      setBoardData(boardData.columns);
    }
  }, [boardData]);
  useEffect(() => {
    if (leads) {
      setPageCount(leads?.meta?.total_pages);
      setTotalCount(leads?.meta?.total_count);
      setPageNo(leads?.meta?.current_page);
    }
  }, [leads]);

  useEffect(() => {
    setPageCount(0);
    setTotalCount(0);
    setPageNo(0);
    dispatch(setLeads(null));
    // getLeadData(stat?.data?.locations?.length ? stat?.data?.locations : []);
  }, []);

  // eslint-disable-next-line no-unused-vars
  const fetchData = useCallback(({ pageSize, pageIndex, search, sortBy, filterData }) => {
    const fetchId = ++fetchIdRef.current;
    // eslint-disable-next-line no-unused-vars
    const sort: any = sortBy || [];
    // We'll even set a delay to simulate a server here
    setTimeout(() => {
      if (fetchId === fetchIdRef.current) {
        setPageNo(pageIndex);
        setPageSize(perPage);
        getLeadData(
          stat?.data?.locations?.length ? stat?.data?.locations : [],
          pageIndex,
          pageSize
        );
        // dispatch(getLeads({ pageSize, pageIndex, search, sort, filterData }));
      }
    }, 1000);
  }, []);

  // const getAccessValue = (locationId: any, accessType: any) =>
  //   getAccessPermission(locationId, "crm", "crm_deals", "", accessType);

  // const goToLeadDetails = (obj: any) => {
  //   if (getAccessValue(obj.props?.locationId, "view_access")) {
  //     dispatch(setLeaseConversation(null));
  //     navigate(`/crm/sola-pro-leads`, {
  //       state: { data: obj.props, id: obj.id },
  //       replace: true,
  //     });
  //   } else {
  //     dispatch(notificationFail(MESSAGES.ERROR.LEAD_NOT_ABLE_TO_VIEW_ERROR));
  //   }
  // };

  const getRow = () => {
    const arr: any[] = [];
    boarddata.map((obj: any) => {
      obj.cards.map((val: any) => {
        const object = {
          title: (
            <MDTypography
              sx={{ fontSize: 14, cursor: "pointer", fontWeight: "bold" }}
              onClick={() =>
                getViewble(val.id, { props: { locationId: val?.template?.props?.locationId } })
              }
              variant="subtitle2"
            >
              {val.template.props.title}
            </MDTypography>
          ),
          content: val.template.props.content,
          status: obj.title,
          messages: val.template.props.messageCount,
          action: (
            <MDIconButton
              tooltipName="View Details"
              aria-label="Open"
              color="info"
              onClick={() =>
                getViewble(val.id, { props: { locationId: val?.template?.props?.locationId } })
              }
            >
              <Icon fontSize="small">arrow_forward_icon</Icon>
            </MDIconButton>
          ),
        };
        return arr.push(object);
      });
      return true;
    });
    return arr;
  };

  // id: obj.id,
  //   location_name: obj.location_name,

  const reviewTableData = {
    columns: [
      // { Header: "test", accessor: "id", width: "0px" },
      { Header: "Deal Name", accessor: "title" },
      { Header: "Message Preview", accessor: "content" },
      { Header: "Status", accessor: "status", width: "50px" },
      { Header: "Message Count", accessor: "messages" },
      { Header: "Action", accessor: "action", width: "50px" },
    ],
    rows: getRow(),
  };

  return (
    <>
      <MDTypography variant="subtitle2" style={{ margin: "15px" }}>
        {" "}
        &nbsp;{" "}
      </MDTypography>
      <MDBox pt={4} pb={3}>
        <Card>
          <MDBox
            bgColor="dark"
            color="white"
            coloredShadow="dark"
            borderRadius="xl"
            alignItems="center"
            justifyContent="space-between"
            pt={1}
            pb={1}
            pl={2}
            pr={2}
            ml={2}
            mr={2}
            display="flex"
            mt={-3}
          >
            CRM Deals
          </MDBox>
          <DataTable
            table={reviewTableData}
            pageCount={pageCount}
            pageNo={pageNo}
            setPageNo={setPageNo}
            totalCount={totalCount}
            fetchData={fetchData}
            entriesPerPage={{ defaultValue: perPage, entries: [50, 100, 150, 200, 250] }}
          />
          {/* <DataTable table={reviewTableData} manualSort={false} /> */}
        </Card>
      </MDBox>
    </>
  );
}

export default Index;
