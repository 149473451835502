import React, { useState, useEffect } from "react";
// @mui material components
import Card from "@mui/material/Card";
import { Link } from "react-router-dom";

// Material Dashboard 2 PRO React TS components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";

// Authentication layout components
import CoverLayout from "layouts/authentication/components/CoverLayout";

// Images
import bgImage from "assets/images/bg-reset-cover.jpeg";
import Validations from "helper/validations";
import { useAppDispatch, useAppSelector } from "store/store";
import { cognitoForgotPassword } from "store/thunk/authThunk";
import { ForgotPasswordRequest } from "../../../../types/custom-types";
import SimpleLoader from "../../../../components/MDLoader/simpleLoader";

function Cover(): JSX.Element {
  const appDispatch = useAppDispatch();
  const notificationInfo = useAppSelector((state) => state.notificationReducer);
  const [email, setEmail] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);
  const [errors, setErrors] = useState<any>({ email: "", password: "" });

  useEffect(() => {
    if (notificationInfo.message) {
      setLoading(false);
    }
  }, [notificationInfo]);

  const onSubmit = (e: any) => {
    e.preventDefault();
    const requestData: ForgotPasswordRequest = {
      email,
    };
    const error = Validations.validateForgotPassword(requestData);
    if (!Object.keys(error).length) {
      setLoading(true);
      appDispatch(cognitoForgotPassword(requestData));
    }
    setErrors(error);
  };

  return (
    <CoverLayout coverHeight="50vh" image={bgImage}>
      <Card>
        <MDBox
          variant="gradient"
          bgColor="info"
          borderRadius="lg"
          coloredShadow="success"
          mx={2}
          mt={-3}
          py={2}
          mb={1}
          textAlign="center"
        >
          <MDTypography variant="h3" fontWeight="medium" color="white" mt={1}>
            Reset Password
          </MDTypography>
          <MDTypography display="block" variant="button" color="white" mt={1} ml={2} mr={2}>
            No worries. Enter your email and we will send you a link to reset it.
          </MDTypography>
        </MDBox>
        <MDBox pt={4} pb={3} px={3}>
          <MDBox component="form" role="form" onSubmit={(e) => onSubmit(e)}>
            <MDBox mb={4}>
              <MDInput
                type="email"
                label="Email"
                variant="standard"
                fullWidth
                placeholder="john@example.com"
                InputLabelProps={{ shrink: true }}
                name="email"
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => setEmail(e.target.value)}
                value={email}
                error={(errors && errors.email) || false}
                helperText={errors && errors.email}
                required
              />
            </MDBox>
            <MDBox mt={6} mb={1}>
              <MDButton variant="gradient" color="info" fullWidth type="submit" disabled={loading}>
                {loading ? <SimpleLoader size={20} /> : "reset"}
              </MDButton>
            </MDBox>
            <MDBox mt={2} mb={1} textAlign="center">
              <MDTypography variant="button" color="text">
                Back to{" "}
                <MDTypography
                  component={Link}
                  to="/sign-in"
                  variant="button"
                  color="info"
                  fontWeight="medium"
                  textGradient
                >
                  Sign In
                </MDTypography>
              </MDTypography>
            </MDBox>
          </MDBox>
        </MDBox>
      </Card>
    </CoverLayout>
  );
}

export default Cover;
