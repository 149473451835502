import { Grid, Paper, Icon } from "@mui/material";
import MDTypography from "components/MDTypography";
import MDBox from "components/MDBox";
import MDLabelValue from "components/MDLabelValue";
import MDIconButton from "components/MDIconButton";

const locationWiseUsers = (props: any) => {
  const { association, editLocationAccess } = props;
  const htmlContent: any = [];
  if (association?.locations?.length && association?.users?.length) {
    htmlContent.push(
      <MDTypography variant="h6" mt={2} mb={1}>
        User Access
      </MDTypography>
    );
    association?.locations.map((obj: any) => {
      htmlContent.push(
        <MDBox mb={1} key={obj?.cms_location_id}>
          <Paper variant="outlined">
            <MDBox p={1}>
              <Grid container xs={12} sm={12} md={12}>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <MDLabelValue label="Location" value={obj?.name || "-"} />
                </Grid>
                {association?.users?.length > 0 &&
                  association?.users?.map((u: any) => (
                    <Grid
                      item
                      xs={6}
                      sm={6}
                      md={4}
                      lg={4}
                      key={`${obj?.cms_location_id}_${u?.login_email}`}
                    >
                      <MDIconButton
                        tooltipName="View/Edit Access"
                        aria-label="View/Edit Access"
                        color="info"
                        onClick={() => editLocationAccess(obj, u)}
                      >
                        <Icon fontSize="small">edit</Icon>
                      </MDIconButton>
                      {u.login_email}
                    </Grid>
                  ))}
              </Grid>
            </MDBox>
          </Paper>
        </MDBox>
      );
      return true;
    });
  }
  return htmlContent;
};

export default locationWiseUsers;
