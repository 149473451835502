import { useState, useEffect } from "react";
import { useAppDispatch } from "store/store";
import { Card, Icon, Grid } from "@mui/material";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDDialog from "components/MDDialog";
import MDIconButton from "components/MDIconButton";
import MDTypography from "components/MDTypography";
import DataTable from "examples/Tables/DataTable";
import CustomAutoSearch from "components/CustomAutoSearch";
import Messages from "helper/messages";
import { associationAddOwner, deleteAssociationOwner } from "store/thunk/associationThunk";
import { getPermission } from "helper/services";

function Owners(props: any): JSX.Element {
  const dispatch = useAppDispatch();

  const { association, setUserData } = props;
  const [owners, setOwnersData] = useState([]);
  const [addOwnerPopup, setAddOwnerPopup] = useState(false);
  const [selectedOwners, setSelectedOwners] = useState(null);
  const [deleteOwnerId, setDeleteOwnerId] = useState<any>(null);

  const onClose = () => {
    setSelectedOwners(null);
    setAddOwnerPopup(false);
  };

  const onAddOwner = () => {
    dispatch(
      associationAddOwner({
        ownerId: selectedOwners?.user_id,
        associationId: association?.id,
        onClose,
      })
    );
  };

  const onDelete = () => {
    if (deleteOwnerId) {
      dispatch(
        deleteAssociationOwner({
          ownerId: deleteOwnerId,
          associationId: association?.id,
        })
      );
      setDeleteOwnerId(false);
    }
  };

  useEffect(() => {
    if (association && Array.isArray(association?.owners) && association?.owners?.length) {
      setOwnersData(association?.owners?.filter((obj: any) => obj));
    }
  }, [association]);

  const actionButtons = (obj: any) => (
    <>
      <MDIconButton
        tooltipName="View Details"
        aria-label="View Details"
        color="info"
        onClick={() => setUserData({ ...obj, headerTitle: "Owner Details" })}
      >
        <Icon fontSize="small">visibility</Icon>
      </MDIconButton>
      <MDIconButton
        tooltipName={owners.length <= 1 ? "Must Have At Least 1 Owner" : "Delete"}
        aria-label="Delete"
        disabled={owners.length <= 1}
        color="error"
        onClick={() => setDeleteOwnerId(obj?.user_id)}
      >
        <Icon fontSize="small">delete</Icon>
      </MDIconButton>
    </>
  );

  const getRows = () => {
    const data: any = owners?.map((obj: any) => ({
      id: obj?.user_id,
      first_name: obj?.first_name ? obj?.first_name : "-",
      last_name: obj?.last_name ? obj?.last_name : "-",
      email: obj?.login_email ? obj?.login_email : "-",
      action: actionButtons(obj),
    }));
    return data;
  };

  const tableData = {
    columns: [
      { Header: "Id", accessor: "id", width: "50px", export: true },
      { Header: "First name", accessor: "first_name", export: true },
      { Header: "Last name", accessor: "last_name", export: true },
      { Header: "Email", accessor: "email", export: true },
      { Header: "Action", accessor: "action", export: false, disableSortBy: true, width: "200px" },
    ],
    rows: getRows(),
  };

  const ownerDialog = {
    open: addOwnerPopup,
    onClose,
    onSave: onAddOwner,
    title: "Add Owner",
    size: "sm",
    saveBtn: selectedOwners ? true : false,
    overflowVisible: "overflowVisible",
  };

  const deleteDialogProps = {
    open: deleteOwnerId,
    onClose: () => setDeleteOwnerId(false),
    onSave: () => onDelete(),
    title: "Delete Owner",
    size: "sm",
    saveBtn: false,
    deleteBtn: true,
    saveTbtText: "Yes",
    closeBtnText: "No",
  };

  return (
    <Card id="owners">
      <MDBox p={3} display="flex" justifyContent="space-between">
        <MDTypography variant="h5" className="page-header-label">
          Owners
        </MDTypography>
        <MDButton
          className="xs-small"
          variant="gradient"
          color="light"
          size="small"
          onClick={() => setAddOwnerPopup(true)}
        >
          <Icon sx={{ fontWeight: "bold" }}>add</Icon> Add New
        </MDButton>
      </MDBox>
      <DataTable table={tableData} canSearch searchText="Search..." />

      {addOwnerPopup && (
        <MDDialog {...ownerDialog}>
          <Grid container spacing={2} mt={1}>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <CustomAutoSearch
                idKey="user_id"
                nameKey="login_email"
                fieldLabel="Owner"
                apiName="connect_users"
                responseKey="connect_users"
                name="owners"
                slsAPI
                required
                value={selectedOwners || null}
                onChange={(event: any, val: any) => setSelectedOwners(val)}
                createNewLink={
                  getPermission("users", "users_manage") ? "/users-&-roles/all-users" : ""
                }
              />
            </Grid>
          </Grid>
        </MDDialog>
      )}

      {deleteOwnerId && (
        <MDDialog {...deleteDialogProps}>
          <MDTypography variant="h6" fontWeight="medium">
            {Messages.GENERAL.SURE_TO_DELETE}
          </MDTypography>
        </MDDialog>
      )}
    </Card>
  );
}

export default Owners;
