import { useState, useEffect } from "react";
import { Grid } from "@mui/material";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import CustomBackButton from "components/CustomBackButton";
import BasicInfo from "layouts/pages/associations/basicInfo";
import BusinessInfo from "layouts/pages/associations/businessInfo";
import Locations from "layouts/pages/associations/locations";
import Owners from "layouts/pages/associations/owners";
import UserAccess from "layouts/pages/associations/user-access";
import Sidenav from "layouts/pages/associations/sidenav";
import { useAppSelector, useAppDispatch } from "store/store";
import { getAssociationDetails, updateAssociationDetails } from "store/thunk/associationThunk";
import { useLocation, useNavigate } from "react-router-dom";
import { scrollToErrorByClass } from "helper/services";
import Validation from "helper/validations";
import UserDetails from "layouts/pages/system-admin/connect-users/userDetails";

function AssociationsDetails(): JSX.Element {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const locationState: any = useLocation();
  const [association, setAssociation] = useState<any>({});
  const { associationDetails } = useAppSelector((state) => state.associationSlice);
  const [errors, setErrors] = useState<any>({});
  const [userData, setUserData] = useState<any>(null);

  const onChangeField = (event: any) => {
    setAssociation({ ...association, [event.target.name]: event.target.value });
  };

  const onChangeAutoComplete = (name: string, value: any) => {
    setAssociation({ ...association, [name]: value });
  };

  useEffect(() => {
    if (locationState?.state?.associationId) {
      dispatch(getAssociationDetails({ id: locationState?.state?.associationId }));
    } else {
      navigate(`/locations/associations`);
    }
  }, [locationState]);

  useEffect(() => {
    if (associationDetails && Object.keys(associationDetails)?.length) {
      setAssociation(associationDetails);
    }
  }, [associationDetails]);

  const updateAssociation = () => {
    const associationData: any = {
      id: Number(association?.id),
      association_name: association?.association_name,
      association_description: association?.association_description,
      bussiness_name: association?.bussiness_name,
      incorporation_state: association?.incorporation_state,
      notice_address_1: association?.notice_address_1,
      notice_address_2: association?.notice_address_2,
      notice_city: association?.notice_city,
      notice_state: association?.notice_state,
      notice_country: association?.notice_country?.id,
      notice_postal_code: association?.notice_postal_code,
      userAccess: association?.userAccess?.length ? association?.userAccess : [],
    };
    const errors = Validation.validateAssociationDetails({
      association_name: association?.association_name,
    });
    setErrors(errors);
    if (!Object.keys(errors).length) {
      dispatch(updateAssociationDetails(associationData));
    } else {
      scrollToErrorByClass(errors);
    }
  };

  const attr = {
    association,
    onChangeField,
    onChangeAutoComplete,
    errors,
    updateAssociation,
    setUserData,
    setAssociation,
  };

  const userDetailsProps = {
    open: userData ? true : false,
    onClose: () => setUserData(null),
    userData,
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox mx={2}>
        <Grid container spacing={3} sx={{ display: "flex", justifyContent: "space-between" }}>
          <Grid item>
            <CustomBackButton label="Back To Associations" />
          </Grid>
        </Grid>
      </MDBox>
      <MDBox pb={2}>
        <Grid container spacing={3} mt={1}>
          <Grid item xs={12} lg={3}>
            <Sidenav />
          </Grid>
          <Grid item xs={12} lg={9}>
            <MDBox mb={3}>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <BasicInfo {...attr} />
                </Grid>
                <Grid item xs={12}>
                  <BusinessInfo {...attr} />
                </Grid>
                <Grid item xs={12}>
                  <Locations {...attr} />
                </Grid>
                <Grid item xs={12}>
                  <Owners {...attr} />
                </Grid>
                <Grid item xs={12}>
                  <UserAccess {...attr} />
                </Grid>
                <Grid item xs={12} sm={12} display="flex" justifyContent="end">
                  <MDButton
                    variant="gradient"
                    color="info"
                    size="small"
                    onClick={updateAssociation}
                    className="fixed-button"
                  >
                    Save
                  </MDButton>
                </Grid>
              </Grid>
            </MDBox>
          </Grid>
        </Grid>
      </MDBox>
      {userData && <UserDetails {...userDetailsProps} />}
    </DashboardLayout>
  );
}

export default AssociationsDetails;
