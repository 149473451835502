import { useEffect, useState } from "react";
import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";
import { Theme } from "@mui/material/styles";

import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

import { ScrollToSection, systemAdmin } from "helper/services";

import { useMaterialUIController } from "context";

function Sidenav(): JSX.Element {
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;
  const [sidenavItems, setSidenavItems] = useState([]);
  useEffect(() => {
    const items = [
      { icon: "receipt_long", label: "General", href: "general" },
      { icon: "mail_outline", label: "Contact", href: "contact" },
      { icon: "badge", label: "Address", href: "address" },
      { icon: "discount", label: "Promotions", href: "promotions" },
      { icon: "share", label: "Social", href: "social" },
      { icon: "language", label: "Tracking Code", href: "tracking-code" },
      { icon: "image", label: "Images", href: "images" },
      { icon: "rocket", label: "Rockbot", href: "rockbot" },
    ];
    if (systemAdmin()) {
      items.push({ icon: "manage_accounts", label: "Rent Manager", href: "rent-manager" });
    }
    items.push(
      { icon: "tour", label: "Tours360", href: "tours360" },
      {
        icon: "assignment",
        label: "Text and email integration",
        href: "text-email-integration",
      }
    );
    setSidenavItems(items);
  }, []);

  const renderSidenavItems = sidenavItems.map(({ icon, label, href }, key) => {
    const itemKey = `item-${key}`;

    return (
      <MDBox key={itemKey} component="li" pt={key === 0 ? 0 : 1}>
        <MDTypography
          component="a"
          onClick={() => ScrollToSection(href)}
          variant="button"
          fontWeight="regular"
          textTransform="capitalize"
          sx={({
            borders: { borderRadius },
            functions: { pxToRem },
            palette: { light },
            transitions,
          }: Theme) => ({
            display: "flex",
            alignItems: "center",
            cursor: "pointer",
            borderRadius: borderRadius.md,
            padding: `${pxToRem(10)} ${pxToRem(16)}`,
            transition: transitions.create("background-color", {
              easing: transitions.easing.easeInOut,
              duration: transitions.duration.shorter,
            }),

            "&:hover": {
              backgroundColor: light.main,
            },
          })}
        >
          <MDBox mr={1.5} lineHeight={1} color={darkMode ? "white" : "dark"}>
            <Icon fontSize="small">{icon}</Icon>
          </MDBox>
          {label}
        </MDTypography>
      </MDBox>
    );
  });

  return (
    <Card
      sx={{
        borderRadius: ({ borders: { borderRadius } }) => borderRadius.lg,
        position: "sticky",
        top: "100px",
      }}
    >
      <MDBox
        component="ul"
        display="flex"
        flexDirection="column"
        p={2}
        m={0}
        sx={{ listStyle: "none" }}
      >
        {renderSidenavItems}
      </MDBox>
    </Card>
  );
}

export default Sidenav;
