import React, { useState, useEffect } from "react";
import { v4 as uuidv4 } from "uuid";
import { Grid, AppBar, Tabs, Tab } from "@mui/material";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import MDBox from "components/MDBox";
import MDTabPanel from "components/MDTabPanel";
import MDTypography from "components/MDTypography";
import { getSolaProSurveyTabs } from "helper/services";
import SendSurvey from "./send-survey";
// import FranchiseeMessaging from "./franchisee-messaging";
import Dashboard from "./dashboard";
import SolaProMessaging from "./sola-pro-messaging";
import SurveyQuestions from "./survey-questions";

function SatisfactionSurvey(): JSX.Element {
  const [tabValue, setTabValue] = useState<number>(0);
  const [pageAccess, setPageAccess] = useState<any>([]);

  useEffect(() => {
    const tabAccess = getSolaProSurveyTabs();
    if (tabAccess?.length > 0) {
      setPageAccess(tabAccess);
    }
  }, []);

  const changeTab = (e: any, value: any) => {
    setTabValue(value);
  };

  const getComponent = (alias: string) => {
    switch (alias) {
      case "survey_solapro_dashboard":
        return <Dashboard />;
      case "survey_solapro_questions":
        return <SurveyQuestions />;
      case "survey_solapro_setup":
        return <SolaProMessaging />;
      case "survey_solapro_scheduling":
        return <SendSurvey />;
      default:
        return null;
    }
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={2} pb={2}>
        {pageAccess && pageAccess?.length > 0 ? (
          <>
            <MDBox>
              <Grid container>
                <Grid item xs={12} sm={12} md={12} lg={9}>
                  <AppBar position="static">
                    <Tabs className="survey-tabs" value={tabValue} onChange={changeTab}>
                      {pageAccess.map((obj: any) => (
                        <Tab label={obj.display_menu} key={uuidv4()} />
                      ))}
                    </Tabs>
                  </AppBar>
                </Grid>
              </Grid>
            </MDBox>
            <MDBox>
              {pageAccess.map((obj: any, key: number) => (
                <MDTabPanel value={tabValue} index={key} padding={0} key={uuidv4()}>
                  {getComponent(obj.alias)}
                </MDTabPanel>
              ))}
            </MDBox>
          </>
        ) : (
          <MDTypography ml={2}>No Access</MDTypography>
        )}
      </MDBox>
    </DashboardLayout>
  );
}

export default SatisfactionSurvey;
