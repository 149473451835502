import { useEffect, useState } from "react";
import { useAppDispatch } from "store/store";
import MDDialog from "components/MDDialog";
import MDInput from "components/MDInput";
import MDTypography from "components/MDTypography";
import CustomDropZone from "components/CustomDropZone";
import { InputLabel, MenuItem, Select, Grid, Icon, FormControl } from "@mui/material";
import Validations from "helper/validations";
import ImagePreview from "layouts/pages/ImagePreview";
import CustomAutoSearch from "components/CustomAutoSearch";
import { createTool } from "store/thunk/mobileAppThunk";
import fileimg from "assets/images/file-image.svg";
import CustomErrorMessage from "components/CustomErrorMessage";
import MDIconButton from "components/MDIconButton";
import { getMUISelectValue, scrollToErrorByClass, getPermission } from "helper/services";

function AddUpdate(props: any): JSX.Element {
  const dispatch = useAppDispatch();

  const { open, onClose, updateData, pageNo, perPage, search, sortBy } = props;
  const [isUpdate, setIsUpdate] = useState<any>(false);
  const [ToolData, setToolData] = useState<any>({});
  const [imagePreview, setImagePreview] = useState<any>("");
  const [errors, setErrors] = useState<any>({});
  const [fileImagePreview, setFileImagePreview] = useState<any>("");
  const [isPreview, setImagePreviewPopup] = useState(false);
  const [imageToPreview, setImageToPreview] = useState("");

  const previewImage = (image: any) => {
    setImagePreviewPopup(true);
    setImageToPreview(image);
  };

  const closeImagePreview = () => {
    setImagePreviewPopup(false);
    setImageToPreview("");
  };

  const onSave = () => {
    const error = Validations.validateToolForm(ToolData);
    setErrors(error);
    if (!Object.keys(error).length) {
      const req = {
        ToolData,
        onClose,
        isUpdate,
        pageNo,
        perPage,
        search,
        sortBy,
      };
      dispatch(createTool(req));
    } else {
      scrollToErrorByClass(error);
    }
  };

  useEffect(() => {
    if (updateData) {
      if (updateData.id) {
        setIsUpdate(true);
      }

      let brand: any = null;
      if (updateData.brand_id) {
        brand = {
          id: updateData.brand_id,
          name: updateData.brand_name,
        };
      }

      setToolData({ ...updateData, countries_name: updateData.country, brand });
      setImagePreview(updateData.image_url);
      setFileImagePreview(updateData.file_url);
    }
  }, [updateData]);

  const onChangeImage = async (files: any, type: any) => {
    if (files[0]) {
      const file = files[0];
      const reader = new FileReader();
      reader.onload = function (e) {
        if (type === "image_url") {
          setToolData({ ...ToolData, [type]: files[0], delete_image: 0 });
          setImagePreview(e.target.result);
        } else {
          setToolData({ ...ToolData, [type]: files[0], delete_file: 0 });
          setFileImagePreview(e.target.result);
        }
      };
      reader.readAsDataURL(file);
    }
  };

  const onImageRemove = async (e: any, type: any) => {
    e.stopPropagation();
    if (type === "image_url") {
      setToolData({ ...ToolData, [type]: null, delete_image: 1 });
      setImagePreview(null);
    } else {
      setToolData({ ...ToolData, [type]: null, delete_file: 1 });
      setFileImagePreview(null);
    }
  };

  const onChangeField = (e: any, field: string) => {
    setToolData({ ...ToolData, [field]: e.target.value });
  };

  const onChangeAutoComplete = (name: string, value: any) => {
    setToolData({ ...ToolData, [name]: value });
  };

  const dialogProps = {
    open,
    onSave,
    onClose,
    title: isUpdate ? "Update Tool And Resources" : "Add Tool And Resources",
    size: "md",
    saveTbtText: isUpdate ? "Update" : "Save",
  };

  return (
    <MDDialog {...dialogProps}>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12} md={6} lg={6}>
          <MDInput
            label="Title"
            variant="standard"
            fullWidth
            required
            name="title"
            placeholder="title"
            onChange={(event: any) => onChangeField(event, "title")}
            value={ToolData?.title ? ToolData?.title : ""}
            error={errors && errors.title ? true : false}
            helperText={errors && errors.title}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6}>
          <MDInput
            label="Description"
            variant="standard"
            fullWidth
            name="description"
            placeholder="description"
            onChange={(event: any) => onChangeField(event, "description")}
            value={ToolData?.description ? ToolData?.description : ""}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6}>
          <CustomAutoSearch
            idKey="id"
            nameKey="name"
            fieldLabel="Brand"
            apiName="brands"
            responseKey="brands"
            name="brand"
            value={ToolData?.brand || null}
            onChange={onChangeAutoComplete}
            createNewLink={
              getPermission("mobile_app", "mobile_app_brands") ? "/mobile-app/brands" : ""
            }
          />
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6}>
          <CustomAutoSearch
            idKey="id"
            nameKey="name"
            fieldLabel="Categories"
            apiName="categories"
            responseKey="categories"
            name="category"
            value={ToolData?.category || []}
            onChange={onChangeAutoComplete}
            multiple
            createNewLink={
              getPermission("sola_website", "sola_website_categories")
                ? "/sola-website/categories"
                : ""
            }
          />
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6}>
          <CustomAutoSearch
            idKey="id"
            nameKey="name"
            fieldLabel="Tags"
            apiName="tags"
            responseKey="tags"
            name="tag"
            value={ToolData?.tag || []}
            onChange={onChangeAutoComplete}
            multiple
            createNewLink={
              getPermission("sola_website", "sola_website_tags") ? "/sola-website/tags" : ""
            }
          />
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <MDTypography variant="button">Image</MDTypography>
          <Grid container>
            <Grid item xs={12} md={6}>
              <CustomDropZone
                multipleUpload={false}
                acceptedFiles={{
                  "image/*": [".gif", ".png", ".jpg", ".jpeg"],
                }}
                onDrop={(e: any) => onChangeImage(e, "image_url")}
              >
                Drop your image here, or Browse
              </CustomDropZone>
            </Grid>
            <Grid item xs={12} md={6}>
              {imagePreview && (
                <Grid
                  container
                  onClick={() => previewImage(imagePreview)}
                  justifyContent="center"
                  alignItems="center"
                  textAlign="left"
                >
                  <Grid item className="imageWithDeleteIcon">
                    <img
                      style={{ maxHeight: "100px" }}
                      width={100}
                      src={imagePreview}
                      alt="original"
                    />
                    <MDIconButton
                      tooltipName="Delete"
                      aria-label="Delete"
                      className="removeBtnClass"
                      onClick={(e: any) => onImageRemove(e, "image_url")}
                    >
                      <Icon fontSize="small">close</Icon>
                    </MDIconButton>
                  </Grid>
                </Grid>
              )}
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <MDTypography variant="button">File</MDTypography>
          <Grid container>
            <Grid item xs={12} md={6}>
              <CustomDropZone
                multipleUpload={false}
                onDrop={(e: any) => onChangeImage(e, "file_url")}
              >
                Drop your image here, or Browse
              </CustomDropZone>
            </Grid>
            <Grid item xs={12} md={6}>
              {fileImagePreview && (
                <Grid container justifyContent="center" alignItems="center" textAlign="center">
                  <Grid item className="imageWithDeleteIcon">
                    <img src={fileimg} height="70" alt="file" />
                    <MDIconButton
                      tooltipName="Delete"
                      aria-label="Delete"
                      className="removeBtnClass"
                      onClick={(e: any) => onImageRemove(e, "file_url")}
                    >
                      <Icon fontSize="small">close</Icon>
                    </MDIconButton>
                  </Grid>
                </Grid>
              )}
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6}>
          <MDInput
            label="Link Url"
            variant="standard"
            fullWidth
            name="link_url"
            placeholder="link url"
            onChange={(event: any) => onChangeField(event, "link_url")}
            value={ToolData?.link_url ? ToolData?.link_url : ""}
            error={errors && errors.link_url ? true : false}
            helperText={errors && errors.link_url}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6}>
          <MDInput
            label="Youtube Url"
            variant="standard"
            fullWidth
            name="youtube_url"
            placeholder="youtube url"
            onChange={(event: any) => onChangeField(event, "youtube_url")}
            value={ToolData?.youtube_url ? ToolData?.youtube_url : ""}
            error={errors && errors.youtube_url ? true : false}
            helperText={errors && errors.youtube_url}
          />
        </Grid>
        <Grid item sx={{ display: "flex", alignItems: "center" }} xs={12} sm={12} md={6} lg={6}>
          <FormControl fullWidth>
            <InputLabel id="featured-label">Is Featured</InputLabel>
            <Select
              fullWidth
              labelId="featured-label"
              id="is_featured"
              className="height45"
              value={getMUISelectValue(ToolData?.is_featured)}
              label="Is Featured"
              onChange={(event: any) => onChangeField(event, "is_featured")}
            >
              <MenuItem value="true">Yes</MenuItem>
              <MenuItem value="false">No</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6}>
          <CustomAutoSearch
            idKey="id"
            nameKey="name"
            fieldLabel="Countries"
            apiName="countries"
            responseKey="countries"
            name="countries_name"
            value={ToolData?.countries_name || []}
            onChange={onChangeAutoComplete}
            error={errors && errors?.countries_name ? true : false}
            multiple
            required
          />
          {errors && errors?.countries_name && (
            <CustomErrorMessage message={errors?.countries_name} />
          )}
        </Grid>
      </Grid>
      <ImagePreview
        imageData={imageToPreview}
        open={isPreview}
        onCloseImage={() => {
          closeImagePreview();
        }}
      />
    </MDDialog>
  );
}

export default AddUpdate;
