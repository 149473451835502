import { Card, Typography, Icon, IconButton, Tooltip } from "@mui/material";
import MDBox from "components/MDBox";
import DataTable from "examples/Tables/DataTable";
import { getUniqueValues, sortingValues, columnsToCast, getFBC } from "helper/reports";
import React, { useCallback, useEffect, useState } from "react";
// import InfoIcon from "@mui/icons-material/Info";
import { filterData, SearchType } from "filter-data";
import MDButton from "components/MDButton";
import CustomSelect from "components/CustomSelect";
import InfoIcon from "@mui/icons-material/Info";
import arraySort from "array-sort";
import { SelectedDate, SelectedDateRange } from "..";
import ScorecardDateInput from "../components/scorecardDateInput";
import { ScorecardDate } from "../scorecard";

function formatDate(dateString: string) {
  const parts = dateString.split("-");
  const year = parts[0];
  const month = parts[1];
  const day = parts[2];
  const formattedDate = `${month}/${day}/${year}`;
  return formattedDate;
}

function formatCurrency(amount: number, currencySymbol: string = "$"): string {
  // Convert the amount to a string
  let formattedAmount = amount?.toString();

  // Split the string into whole and decimal parts
  const parts = formattedAmount?.split(".");
  let wholePart = parts ? parts[0] : null;
  const decimalPart = parts ? parts[1] : null;

  // Add commas to the whole part every three digits
  wholePart = wholePart?.replace(/\B(?=(\d{3})+(?!\d))/g, ",");

  // Concatenate the whole and decimal parts with the decimal separator
  formattedAmount = wholePart + (decimalPart?.length ? `.${decimalPart}` : "");

  // Add the currency symbol to the formatted amount
  formattedAmount = currencySymbol + formattedAmount;

  return formattedAmount;
}

function calculateQuartile(rank: number, count: number) {
  const percentile = (rank / count) * 100;

  if (percentile <= 25) {
    return "quartile-1";
  }
  if (percentile <= 50) {
    return "quartile-2";
  }
  if (percentile <= 75) {
    return "quartile-3";
  }
  return "quartile-4";
}

function addQuartile(rank: string, count: string) {
  const quartileClass = calculateQuartile(parseInt(rank, 10), parseInt(count, 10));
  return { quartileClass };
}

export interface SortProps {
  id: string;
  desc: boolean;
}

export interface FilterProps {
  [key: string]: string;
}

interface ScorecardTableProps {
  scorecardData: {
    [key: string]: number | string;
  }[];
  showFilter: boolean;
  // eslint-disable-next-line no-unused-vars
  setShowFilter: (showFilter: boolean) => void;
  count: number;
  // eslint-disable-next-line no-unused-vars
  onChangeDateField: (event: any) => void;
  selectedDate: SelectedDate;
  dateArr: ScorecardDate[];
  selectedDateRange: SelectedDateRange;
  // eslint-disable-next-line no-unused-vars
  setSelectedDateRange: (selectedDateRange: SelectedDateRange) => void;
  // eslint-disable-next-line no-unused-vars
  setSelectedDate: (selectedDate: SelectedDate) => void;
  currentDateCheckBox: boolean;
  // eslint-disable-next-line no-unused-vars
  setCurrentDateCheckBox: (currentDateCheckBox: boolean) => void;
}

const style: Record<string, React.CSSProperties> = {
  "quartile-1": {
    color: "green !important",
    fontSize: "14px",
  },
  "quartile-2": {
    color: "blue !important",
    fontSize: "14px",
  },
  "quartile-3": {
    color: "#fca503 !important",
    fontSize: "14px",
  },
  "quartile-4": {
    color: "red !important",
    fontSize: "14px",
  },
};

function MaturedLocation({
  scorecardData,
  showFilter,
  setShowFilter,
  count,
  selectedDate,
  onChangeDateField,
  dateArr,
  selectedDateRange,
  setSelectedDateRange,
  setSelectedDate,
  currentDateCheckBox,
  setCurrentDateCheckBox,
}: ScorecardTableProps) {
  const [search, setSearch] = useState("");
  const [sort, setSort] = useState([]);
  const [filterMetaData, setFilterMetaData] = useState<{ [key: string]: string[] }>();
  const [filterByProperty, setFilterByProperty] = useState<FilterProps>({});
  const [filterByMSA, setFilterByMSA] = useState<FilterProps>({});
  const [filterByLeads, setFilterByLeads] = useState<FilterProps>({});
  const [filterByIncome, setFilterByIncome] = useState<FilterProps>({});
  const [filterByContactName, setFilterByContactName] = useState<FilterProps>({});
  const [filterByState, setFilterByState] = useState<FilterProps>({});
  const [filterByRegion, setFilterByRegion] = useState<FilterProps>({});
  const [filterByRevenueOccupiedChair, setFilterByRevenueOccupiedChair] = useState<FilterProps>({});
  const [filterByRevenueTotalChair, setFilterByRevenueTotalChair] = useState<FilterProps>({});
  const [filterByLeaseExpensePercentage, setFilterByLeaseExpensePercentage] = useState<FilterProps>(
    {}
  );
  const [filterByTotalChair, setFilterByTotalChair] = useState<FilterProps>({});
  const [filterByOccupiedChair, setFilterByOccupiedChair] = useState<FilterProps>({});
  const [filterByNpsScore, setFilterByNpsScore] = useState<FilterProps>({});
  const [applySearch, setApplySearch] = useState<boolean>(false);
  const [filterByNpsPraticipants, setFilterByNpsPraticipants] = useState<FilterProps>({});
  const [filterByMoveIns, setFilterByMoveIns] = useState<FilterProps>({});
  const [filterByMoveOuts, setFilterByMoveOuts] = useState<FilterProps>({});
  const [filterByFBC, setFilterByFBC] = useState<FilterProps>({});

  const tableColumns = [
    {
      Header: "Sola ID",
      accessor: "sola_id",
      width: "200px",
      export: true,
      disableCheck: true,
      checked: true,
    },
    {
      Header: "Property Name",
      accessor: "property",
      width: "200px",
      export: true,
      disableCheck: true,
      checked: true,
    },
    {
      Header: "MSA",
      accessor: "msa",
      width: "200px",
      export: true,
      disableCheck: true,
      checked: true,
    },
    {
      Header: "Contact Name",
      accessor: "contact_name",
      width: "200px",
      export: true,
      disableCheck: true,
      checked: true,
    },
    {
      Header: "State",
      accessor: "state",
      width: "200px",
      export: true,
      disableCheck: true,
      checked: true,
    },
    {
      Header: "Region",
      accessor: "region",
      width: "200px",
      export: true,
      disableCheck: true,
      checked: true,
    },
    {
      Header: "Opening Date",
      width: "200px",
      export: true,
      is_date: true,
      accessor: (d: any) => formatDate(d.opening_date),
    },
    {
      Header: "Franchise Business Consultant",
      accessor: "fbc",
      width: "200px",
      export: true,
      disableCheck: true,
      checked: true,
    },
    {
      Header: "Income",
      // eslint-disable-next-line react/no-unstable-nested-components
      accessor: (d: any) => {
        const { quartileClass } = addQuartile(d.income_rank, `${count}`);
        const quartileStyle = style[`${quartileClass}` as keyof typeof style];
        return <Typography sx={quartileStyle}>{formatCurrency(d.total_gross)}</Typography>;
      },
      width: "200px",
      export: true,
      disableCheck: true,
      checked: true,
    },
    {
      // eslint-disable-next-line react/no-unstable-nested-components
      Header: () => (
        <Tooltip title="This location is older than 18 months" placement="top">
          <MDBox display="flex" alignItems="center">
            <span style={{ color: "black", marginRight: "5px" }}>SSS Eligible</span>
            <InfoIcon fontSize="small" />
          </MDBox>
        </Tooltip>
      ),
      id: "SSS Eligible",
      accessor: (d: any) => d.eligible,
      width: "200px",
      export: true,
      disableCheck: true,
      checked: true,
    },
    {
      Header: "Revenue / Occupied Chair",
      // eslint-disable-next-line react/no-unstable-nested-components
      accessor: (d: any) => {
        const { quartileClass } = addQuartile(d.revenue_occupied_chair_rank, `${count}`);
        const quartileStyle = style[`${quartileClass}` as keyof typeof style];
        return (
          <Typography sx={quartileStyle}>{formatCurrency(d.revenue_occupied_chair)}</Typography>
        );
      },
      width: "200px",
      export: true,
      disableCheck: true,
      checked: true,
    },
    {
      Header: "Revenue / Total Chair",
      // eslint-disable-next-line react/no-unstable-nested-components
      accessor: (d: any) => {
        const { quartileClass } = addQuartile(d.revenue_total_chair_rank, `${count}`);
        const quartileStyle = style[`${quartileClass}` as keyof typeof style];
        return <Typography sx={quartileStyle}>{formatCurrency(d.revenue_total_chair)}</Typography>;
      },
      width: "200px",
      export: true,
      disableCheck: true,
      checked: true,
    },
    {
      Header: "Lease Expense % by Revenue",
      // eslint-disable-next-line react/no-unstable-nested-components
      accessor: (d: any) => {
        const { quartileClass } = addQuartile(d.lease_expense_percentage_rank, `${count}`);
        const quartileStyle = style[`${quartileClass}` as keyof typeof style];
        return (
          <Typography sx={quartileStyle}>
            {d.lease_expense_percentage ? `${d.lease_expense_percentage}%` : "-"}
          </Typography>
        );
      },
      width: "200px",
      export: true,
      disableCheck: true,
      checked: true,
    },
    {
      Header: "Total Occupancy",
      // eslint-disable-next-line react/no-unstable-nested-components
      accessor: (d: any) => {
        const { quartileClass } = addQuartile(d.total_chair_rank, `${count}`);
        const quartileStyle = style[`${quartileClass}` as keyof typeof style];
        return <Typography sx={quartileStyle}>{d.total_chair}</Typography>;
      },
      width: "200px",
      export: true,
      disableCheck: true,
      checked: true,
    },
    {
      Header: "Occupied",
      // eslint-disable-next-line react/no-unstable-nested-components
      accessor: (d: any) => {
        const { quartileClass } = addQuartile(d.occupied_chair_rank, `${count}`);
        const quartileStyle = style[`${quartileClass}` as keyof typeof style];
        return <Typography sx={quartileStyle}>{d.occupied_chair}</Typography>;
      },
      width: "200px",
      export: true,
      disableCheck: true,
      checked: true,
    },
    {
      Header: "Occupancy Percentage",
      // eslint-disable-next-line react/no-unstable-nested-components
      accessor: (d: any) => {
        const { quartileClass } = addQuartile(d.occupancy_percentage_rank, `${count}`);
        const quartileStyle = style[`${quartileClass}` as keyof typeof style];
        return <Typography sx={quartileStyle}>{`${d.occupancy_percentage}%`}</Typography>;
      },
      width: "200px",
      export: true,
      disableCheck: true,
      checked: true,
    },
    {
      Header: "NPS Score",
      // eslint-disable-next-line react/no-unstable-nested-components
      accessor: (d: any) => {
        const { quartileClass } = addQuartile(d.nps_score_rank, `${count}`);
        const quartileStyle = style[`${quartileClass}` as keyof typeof style];
        return <Typography sx={quartileStyle}>{d.nps_score}</Typography>;
      },
      width: "200px",
      export: true,
      disableCheck: true,
      checked: true,
    },
    {
      Header: "NPS Of Participants",
      // eslint-disable-next-line react/no-unstable-nested-components
      accessor: (d: any) => {
        const { quartileClass } = addQuartile(d.nps_participants_rank, `${count}`);
        const quartileStyle = style[`${quartileClass}` as keyof typeof style];
        return <Typography sx={quartileStyle}>{d.nps_of_participants}</Typography>;
      },
      width: "200px",
      export: true,
      disableCheck: true,
      checked: true,
    },
    {
      Header: "Move Ins",
      // eslint-disable-next-line react/no-unstable-nested-components
      accessor: (d: any) => {
        const { quartileClass } = addQuartile(d.move_ins_rank, `${count}`);
        const quartileStyle = style[`${quartileClass}` as keyof typeof style];
        return <Typography sx={quartileStyle}>{d.move_ins}</Typography>;
      },
      width: "200px",
      export: true,
      disableCheck: true,
      checked: true,
    },
    {
      Header: "Move Outs",
      // eslint-disable-next-line react/no-unstable-nested-components
      accessor: (d: any) => {
        const { quartileClass } = addQuartile(d.move_outs_rank, `${count}`);
        const quartileStyle = style[`${quartileClass}` as keyof typeof style];
        return <Typography sx={quartileStyle}>{d.move_outs}</Typography>;
      },
      width: "200px",
      export: true,
      disableCheck: true,
      checked: true,
    },
    {
      Header: "Leads",
      // eslint-disable-next-line react/no-unstable-nested-components
      accessor: (d: any) => {
        const { quartileClass } = addQuartile(d.leads_rank, `${count}`);
        const quartileStyle = style[`${quartileClass}` as keyof typeof style];
        return <Typography sx={quartileStyle}>{d.leads}</Typography>;
      },
      width: "200px",
      export: true,
      disableCheck: true,
      checked: true,
    },
  ];

  const fetchData = useCallback(() => {
    const processData = [...scorecardData];
    let result: {
      [key: string]: number | string;
    }[] = [];
    if (sort && sort[0]?.desc !== undefined) {
      const desc: boolean = sort[0].desc ? true : false;
      const columnName: any = sortingValues[sort[0].id];
      if (columnsToCast.includes(columnName)) {
        result = processData.map((obj) => {
          if (typeof obj[columnName] === "string") {
            const newValue = parseFloat(obj[columnName] as string);
            return { ...obj, [columnName]: newValue };
          }
          return obj;
        });
      }
      result = arraySort(result.length ? result : processData, sortingValues[sort[0].id], {
        reverse: desc,
      });
    }
    if (filterByNpsPraticipants.value) {
      const check = parseInt(filterByNpsPraticipants.value.split(" ").pop(), 10) as number;
      if (result.length) {
        result = result.filter((item) => parseInt(String(item.nps_of_participants), 10) > check);
      } else {
        result = processData.filter(
          (item) => parseInt(String(item.nps_of_participants), 10) > check
        );
      }
    }
    if (filterByLeads.value) {
      const leadsCondition = parseInt(filterByLeads.value.split(" ").pop(), 10) as number;
      if (result.length) {
        result = result.filter((item) => parseInt(String(item.leads), 10) > leadsCondition);
      } else {
        result = processData.filter((item) => parseInt(String(item.leads), 10) > leadsCondition);
      }
    }
    if (filterByLeaseExpensePercentage.value) {
      const check = parseInt(filterByLeaseExpensePercentage.value.split(" ").pop(), 10) as number;
      if (result.length) {
        result = result.filter((item) => item.lease_expense_percentage > check);
      } else {
        result = processData.filter((item) => item.lease_expense_percentage > check);
      }
    }
    const searchConditions = [
      {
        key: "property",
        value: filterByProperty.value as string,
        type: SearchType.EQ,
      },
      {
        key: "msa",
        value: filterByMSA.value as string,
        type: SearchType.EQ,
      },
      {
        key: "contact_name",
        value: filterByContactName.value,
        type: SearchType.EQ,
      },
      {
        key: "state",
        value: filterByState.value,
        type: SearchType.EQ,
      },
      {
        key: "region",
        value: filterByRegion.value,
        type: SearchType.EQ,
      },
      {
        key: "fbc",
        value: filterByFBC.value,
        type: SearchType.EQ,
      },
      {
        key: "revenue_occupied_chair",
        value:
          filterByRevenueOccupiedChair.value &&
          (parseInt(filterByRevenueOccupiedChair?.value.split(" ").pop(), 10) as number),
        type: SearchType.GT,
      },
      {
        key: "revenue_total_chair",
        value:
          filterByRevenueTotalChair.value &&
          (parseInt(filterByRevenueTotalChair?.value.split(" ").pop(), 10) as number),
        type: SearchType.GT,
      },
      {
        key: "total_chair",
        value:
          filterByTotalChair.value &&
          (parseInt(filterByTotalChair.value.split(" ").pop(), 10) as number),
        type: SearchType.GT,
      },
      {
        key: "total_gross",
        value:
          filterByIncome.value && (parseInt(filterByIncome.value.split(" ").pop(), 10) as number),
        type: SearchType.GT,
      },
      {
        key: "occupied_chair",
        value:
          filterByOccupiedChair.value &&
          (parseInt(filterByOccupiedChair.value.split(" ").pop(), 10) as number),
        type: SearchType.GT,
      },
      {
        key: "nps_score",
        value:
          filterByNpsScore.value &&
          (parseInt(filterByNpsScore.value.split(" ").pop(), 10) as number),
        type: SearchType.GT,
      },
      {
        key: "move_ins",
        value:
          filterByMoveIns.value && (parseInt(filterByMoveIns.value.split(" ").pop(), 10) as number),
        type: SearchType.GT,
      },
      {
        key: "move_outs",
        value: filterByMoveOuts.value
          ? (parseInt(filterByMoveOuts.value?.split(" ").pop(), 10) as number)
          : undefined,
        type: filterByMoveOuts.value?.split(" ").pop() !== "0" ? SearchType.LT : SearchType.EQ,
      },
    ];
    const searchResult = filterData(result.length ? result : processData, searchConditions);
    const searchedData = searchResult;

    return searchedData;
  }, [search, sort, applySearch, scorecardData]);

  useEffect(() => {
    setFilterMetaData(getUniqueValues(scorecardData));
  }, [scorecardData]);

  const clearFilters = () => {
    setFilterByLeads({});
    setFilterByMSA({});
    setFilterByProperty({});
    setFilterByIncome({});
    setFilterByContactName({});
    setFilterByState({});
    setFilterByRegion({});
    setFilterByRevenueOccupiedChair({});
    setFilterByRevenueTotalChair({});
    setFilterByTotalChair({});
    setFilterByOccupiedChair({});
    setFilterByNpsScore({});
    setFilterByNpsPraticipants({});
    setFilterByMoveOuts({});
    setFilterByMoveIns({});
    setFilterByFBC({});
    setFilterByLeaseExpensePercentage({});
    setApplySearch(!applySearch);
  };

  return (
    <MDBox py={3} sx={{ marginTop: { md: 1 } }}>
      <Card sx={{ padding: "20px" }}>
        <MDBox>
          {showFilter && (
            <>
              <MDBox className="crm-location-header-wrapper" onClick={() => setShowFilter(false)} />
              <MDBox className="crm-location-header" display={showFilter ? "inline-flex" : "none"}>
                <MDBox sx={{ display: "inline" }}>
                  <IconButton className="close-filter-button" onClick={() => setShowFilter(false)}>
                    <Icon>close</Icon>
                  </IconButton>
                </MDBox>
                <MDBox sx={{ display: "inline", mt: 4 }}>
                  <CustomSelect
                    onChange={(filterValue: any) => setFilterByProperty(filterValue)}
                    name="property"
                    placeholder="Select Property"
                    value={Object.keys(filterByProperty).length > 0 ? filterByProperty : null}
                    id="property"
                    options={filterMetaData?.property.map((value: any) => {
                      const tempObj = { label: value, value };
                      return tempObj;
                    })}
                  />
                  <CustomSelect
                    onChange={(filterValue: any) => setFilterByMSA(filterValue)}
                    name="msa"
                    placeholder="Select MSA"
                    value={Object.keys(filterByMSA).length > 0 ? filterByMSA : null}
                    id="msa"
                    options={filterMetaData?.msa.map((value: any) => {
                      const tempObj = { label: value, value };
                      return tempObj;
                    })}
                  />
                  <CustomSelect
                    onChange={(filterValue: any) => {
                      setFilterByContactName(filterValue);
                    }}
                    name="contact_name"
                    placeholder="Select Contact Name"
                    value={Object.keys(filterByContactName).length > 0 ? filterByContactName : null}
                    id="contact_name"
                    options={filterMetaData?.contact_name.map((value: any) => {
                      const tempObj = { label: value, value };
                      return tempObj;
                    })}
                  />
                  <CustomSelect
                    onChange={(filterValue: any) => setFilterByState(filterValue)}
                    name="state"
                    placeholder="Select State"
                    value={Object.keys(filterByState).length > 0 ? filterByState : null}
                    id="state"
                    options={filterMetaData?.state.map((value: any) => {
                      const tempObj = { label: value, value };
                      return tempObj;
                    })}
                  />
                  <CustomSelect
                    onChange={(filterValue: any) => setFilterByRegion(filterValue)}
                    name="region"
                    placeholder="Select Region"
                    value={Object.keys(filterByRegion).length > 0 ? filterByRegion : null}
                    id="region"
                    options={filterMetaData?.region.map((value: any) => {
                      const tempObj = { label: value, value };
                      return tempObj;
                    })}
                  />
                  <CustomSelect
                    onChange={(filterValue: any) => setFilterByIncome(filterValue)}
                    name="income"
                    placeholder="Select Income"
                    value={Object.keys(filterByIncome).length > 0 ? filterByIncome : null}
                    id="income"
                    options={filterMetaData?.total_gross.map((value: any) => {
                      const tempObj = { label: value, value };
                      return tempObj;
                    })}
                  />
                  <CustomSelect
                    onChange={(filterValue: any) => setFilterByRevenueOccupiedChair(filterValue)}
                    name="revenue-occupied-chair"
                    placeholder="Select Revenue / Occupied Chair"
                    value={
                      Object.keys(filterByRevenueOccupiedChair).length > 0
                        ? filterByRevenueOccupiedChair
                        : null
                    }
                    id="revenue-occupied-chair"
                    options={filterMetaData?.revenue_occupied_chair.map((value: any) => {
                      const tempObj = { label: value, value };
                      return tempObj;
                    })}
                  />
                  <CustomSelect
                    onChange={(filterValue: any) => setFilterByRevenueTotalChair(filterValue)}
                    name="revenue-total-chair"
                    placeholder="Select Revenue / Total Chair"
                    value={
                      Object.keys(filterByRevenueTotalChair).length > 0
                        ? filterByRevenueTotalChair
                        : null
                    }
                    id="revenue-total-chair"
                    options={filterMetaData?.revenue_total_chair.map((value: any) => {
                      const tempObj = { label: value, value };
                      return tempObj;
                    })}
                  />
                  <CustomSelect
                    onChange={(filterValue: any) => setFilterByTotalChair(filterValue)}
                    name="total-chair"
                    placeholder="Select Total Occupancy"
                    value={Object.keys(filterByTotalChair).length > 0 ? filterByTotalChair : null}
                    id="total-chair"
                    options={filterMetaData?.total_chair.map((value: any) => {
                      const tempObj = { label: value, value };
                      return tempObj;
                    })}
                  />
                  <CustomSelect
                    onChange={(filterValue: any) => setFilterByOccupiedChair(filterValue)}
                    name="occupancy"
                    placeholder="Select Occupancy"
                    value={
                      Object.keys(filterByOccupiedChair).length > 0 ? filterByOccupiedChair : null
                    }
                    id="occupancy"
                    options={filterMetaData?.occupied_chair.map((value: any) => {
                      const tempObj = { label: value, value };
                      return tempObj;
                    })}
                  />
                  <CustomSelect
                    onChange={(filterValue: any) => setFilterByLeaseExpensePercentage(filterValue)}
                    name="lease_expense_percentage"
                    placeholder="Select Lease Expense"
                    value={
                      Object.keys(filterByLeaseExpensePercentage).length > 0
                        ? filterByLeaseExpensePercentage
                        : null
                    }
                    id="lease-expense-percentage"
                    options={filterMetaData?.lease_expense_percentage.map((value: any) => {
                      const tempObj = { label: value, value };
                      return tempObj;
                    })}
                  />
                  <CustomSelect
                    onChange={(filterValue: any) => setFilterByNpsScore(filterValue)}
                    name="nps-score"
                    placeholder="Select NPS Score"
                    value={Object.keys(filterByNpsScore).length > 0 ? filterByNpsScore : null}
                    id="nps-score"
                    options={filterMetaData?.nps_score.map((value: any) => {
                      const tempObj = { label: value, value };
                      return tempObj;
                    })}
                  />
                  <CustomSelect
                    onChange={(filterValue: any) => setFilterByNpsPraticipants(filterValue)}
                    name="nps-of-participants"
                    placeholder="Select NPS Of Participants"
                    value={
                      Object.keys(filterByNpsPraticipants).length > 0
                        ? filterByNpsPraticipants
                        : null
                    }
                    id="nps-of-participants"
                    options={filterMetaData?.nps_of_participants.map((value: any) => {
                      const tempObj = { label: value, value };
                      return tempObj;
                    })}
                  />
                  <CustomSelect
                    onChange={(filterValue: any) => setFilterByLeads(filterValue)}
                    name="leads"
                    placeholder="Select Leads"
                    value={Object.keys(filterByLeads).length > 0 ? filterByLeads : null}
                    id="leads"
                    options={filterMetaData?.leads.map((value: any) => {
                      const tempObj = { label: value, value };
                      return tempObj;
                    })}
                  />
                  <CustomSelect
                    onChange={(filterValue: any) => setFilterByMoveIns(filterValue)}
                    name="move-ins"
                    placeholder="Select Move Ins"
                    value={Object.keys(filterByMoveIns).length > 0 ? filterByMoveIns : null}
                    id="move-ins"
                    options={filterMetaData?.move_ins.map((value: any) => {
                      const tempObj = { label: value, value };
                      return tempObj;
                    })}
                  />
                  <CustomSelect
                    onChange={(filterValue: any) => setFilterByMoveOuts(filterValue)}
                    name="move-outs"
                    placeholder="Select Move Outs"
                    value={Object.keys(filterByMoveOuts).length > 0 ? filterByMoveOuts : null}
                    id="move-outs"
                    options={filterMetaData?.move_outs.map((value: any) => {
                      const tempObj = { label: value, value };
                      return tempObj;
                    })}
                  />
                  <CustomSelect
                    onChange={(filterValue: any) => setFilterByFBC(filterValue)}
                    name="fbc"
                    placeholder="Select FBC"
                    value={Object.keys(filterByFBC).length > 0 ? filterByFBC : null}
                    id="move-outs"
                    options={getFBC(scorecardData)}
                  />
                </MDBox>
                <MDBox sx={{ display: "flex", justifyContent: "flex-end", width: "100%" }}>
                  <MDButton
                    variant="gradient"
                    color="info"
                    size="small"
                    className="xs-small"
                    sx={{ marginTop: "5px" }}
                    onClick={() => setApplySearch(!applySearch)}
                  >
                    Apply
                  </MDButton>
                  <MDButton
                    variant="gradient"
                    sx={{ marginLeft: "10px" }}
                    color="light"
                    size="small"
                    className="xs-small"
                    onClick={() => clearFilters()}
                  >
                    Clear
                  </MDButton>
                </MDBox>
              </MDBox>
            </>
          )}
          <ScorecardDateInput
            selectedDate={selectedDate}
            onChangeDateField={onChangeDateField}
            dateArr={dateArr}
            selectedDateRange={selectedDateRange}
            setSelectedDateRange={setSelectedDateRange}
            setSelectedDate={setSelectedDate}
            currentDateCheckBox={currentDateCheckBox}
            setCurrentDateCheckBox={setCurrentDateCheckBox}
          />
          {fetchData().length ? (
            <DataTable
              table={{
                columns: tableColumns,
                rows: fetchData(),
              }}
              fetchData={({
                sortBy,
                search,
              }: {
                sortBy: Array<SortProps>;
                search: string;
                filterData: any;
              }) => {
                setSearch(search);
                setSort(sortBy);
              }}
            />
          ) : (
            <MDBox mt={1} sx={{ minHeight: "200" }}>
              <Card
                variant="outlined"
                sx={{
                  height: "200px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Typography variant="h5">No record found for these values</Typography>
              </Card>
            </MDBox>
          )}
        </MDBox>
      </Card>
    </MDBox>
  );
}

export default MaturedLocation;
