import React, { useRef, useState, useEffect } from "react";
// import { useLocation } from "react-router-dom";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import { Grid, Tab, Tabs, Icon, Card, CardContent } from "@mui/material";
import MDIconButton from "components/MDIconButton";
import { useAppSelector, useAppDispatch } from "store/store";
import { getLeadConversation, sendSMS } from "store/thunk/leaseThunk";
import { getAuthUser, getLocationIdsForConversation } from "helper/services";
import { setDealActivityDetails, setLeaseConversationNotes } from "store/slices/leaseSlice";
import MDTabPanel from "components/MDTabPanel";
import moment from "moment";
import MDInput from "components/MDInput";
import fileimg from "assets/images/file-image.svg";

function NoteTab(): JSX.Element {
  const dispatch = useAppDispatch();
  const bottomRef: any = useRef(null);
  // const valueRef: any = useRef();
  // const { state }: any = useLocation();
  const [loadmoreMsg, setLoadmoreMsg] = useState<boolean>(false);
  const { leadDetails, leaseConversationNotes } = useAppSelector((state) => state.leaseSlice);
  // const [propsData] = useState<any>(state || null);
  const [leadData, setLeadData] = useState<any>({});
  const [conversation, setConversation] = useState<any>([]);
  const [textNote, setTextNote] = useState<any>("");
  const [msgtabValue, setMsgtabValue] = useState<number>(0);
  // const [messagesInterval, setMessagesInterval] = useState<any>(null);
  const [readMore, setReadMore] = useState<any>(null);
  const [lastConvId, setLastConvId] = useState<any>(null);
  const authUser = getAuthUser();

  // useEffect(() => {
  //   valueRef.current = messagesInterval;
  // }, [messagesInterval]);

  useEffect(() => {
    dispatch(setLeaseConversationNotes(null));
    //   console.log("propsData ", propsData);
    //   if (propsData && propsData.id) {
    //     dispatch(setLeadDetails(null));
    //     const request: any = {
    //       leadId: propsData.id,
    //     };
    //     dispatch(getLeadDetails(request));
    //   }
    //   window.history.replaceState(null, "");

    //   return () => {
    //     setMessagesInterval(null);
    //     clearInterval(valueRef.current);
    //   };
  }, []);

  useEffect(() => {
    if (leadDetails && leadDetails.data) {
      setLeadData(leadDetails.data);
      dispatch(setDealActivityDetails([]));
      dispatch(
        getLeadConversation({
          page: 0,
          size: 200,
          leadId: leadDetails?.data?.id,
          contact_id: leadDetails?.data?.connect_contact?.id,
          conversations_type: "notes",
          location_ids: getLocationIdsForConversation(
            leadDetails?.data?.location?.id,
            leadDetails?.data?.connect_twillio_phone_number?.location_ids
          ),
        })
      );
      setLoadmoreMsg(false);
    } else {
      setLeadData({});
    }
  }, [leadDetails]);

  useEffect(() => {
    if (leaseConversationNotes) {
      setConversation(leaseConversationNotes.connect_conversations);
      const lastID =
        leaseConversationNotes.connect_conversations[
          leaseConversationNotes.connect_conversations.length - 1
        ]?.id;
      if (!loadmoreMsg && lastID !== lastConvId) {
        setTimeout(() => {
          if (document.getElementById("msg")) {
            document.getElementById("msg").scrollTo({
              top: Number(document.getElementById("msg").scrollHeight),
              behavior: "smooth",
            });
          }
        }, 500);
      }
    } else {
      setConversation([]);
    }
  }, [leaseConversationNotes]);

  useEffect(() => {
    if (conversation) {
      if (
        document.getElementById("msg") &&
        conversation[conversation.length - 1]?.id !== lastConvId
      ) {
        document.getElementById("msg").scrollTo({
          top: Number(document.getElementById("msg").scrollHeight),
          behavior: "smooth",
        });
      }
      setLastConvId(conversation[conversation.length - 1]?.id);
    }
  }, [conversation]);

  // useEffect(() => {
  //   if (leadData?.id) {
  //     if (messagesInterval === null) {
  //       const msgInterval = setInterval(
  //         () =>
  //           dispatch(
  //             getLeadConversation({
  //               page: 0,
  //               size: 100,
  //               leadId: leadData?.id,
  //               append: true,
  //               loading: "not_visible",
  //               leaseConversation: conversation,
  //               contact_id: leadData?.connect_contact?.id,
  //               conversations_type: "notes",
  //               location_ids: getLocationIdsForConversation(
  //                 leadData?.location?.id,
  //                 leadData?.connect_twillio_phone_number?.location_ids
  //               ),
  //             })
  //           ),
  //         10000
  //       );
  //       setMessagesInterval(msgInterval);
  //     }
  //   }
  // }, [leadData]);

  const changeMsgTab = (event: any, newValue: any) => {
    setMsgtabValue(newValue);
  };

  const readMoreText = (id: any) => {
    if (id !== null) {
      setReadMore(id);
    } else {
      setReadMore(null);
    }
  };

  const displayFiles = (files?: any) => {
    const filesHtml: any = [];
    if (files?.length) {
      files?.map((file: string) =>
        filesHtml.push(
          <a href={file} target="_blank" rel="noreferrer">
            <img src={fileimg} alt={file} width={30} />
          </a>
        )
      );
    }
    return filesHtml;
  };

  const sendTextNote = () => {
    if (textNote.trim().length) {
      const tempConversation = [...conversation];
      if (tempConversation?.length) {
        tempConversation.push({
          content: textNote,
          from_no: leadData?.connect_twillio_phone_number?.twillio_phone_number,
          conversations_type: "notes",
          connect_user: {
            first_name: authUser.first_name,
            last_name: authUser.last_name,
            login_email: authUser.login_email,
          },
        });
        setConversation(tempConversation);
      }
      setLeaseConversationNotes({
        ...leaseConversationNotes,
        connect_conversations: tempConversation,
      });
      dispatch(
        sendSMS({
          leadId: leadData.id,
          content: textNote,
          location_id: leadData.location_id,
          contact_id: leadData?.connect_contact?.id || null,
          from_no: leadData?.connect_twillio_phone_number?.twillio_phone_number,
          to_no: leadData?.connect_contact?.phone || null,
          type: "notes",
          sender_user_id: authUser.user_id,
          location_ids: getLocationIdsForConversation(
            leadData.location_id,
            leadData?.connect_twillio_phone_number?.location_ids
          ),
          unenroll_lead: true,
        })
      );
    }
    setTextNote("");
  };

  const handleKeyPress = (e: any) => {
    if (e.which === 13 && e.ctrlKey) {
      sendTextNote();
    }
  };

  return (
    <>
      <Grid
        item
        xs={12}
        sm={12}
        md={12}
        lg={12}
        className="messages"
        id="msg"
        ref={bottomRef}
        onScroll={() => {
          const scrollHeight = document.getElementById("msg").scrollTop;
          if (scrollHeight === 0) {
            if (
              leaseConversationNotes.meta.current_page + 1 !==
                leaseConversationNotes.meta.total_pages &&
              conversation.length > 0
            ) {
              dispatch(
                getLeadConversation({
                  leadId: leadDetails?.data?.id,
                  page: leaseConversationNotes.meta.current_page + 1,
                  append: true,
                  leaseConversationNotes,
                  contact_id: leadDetails?.data?.connect_contact?.id,
                  conversations_type: "notes",
                  location_ids: getLocationIdsForConversation(
                    leadDetails?.data?.location?.id,
                    leadDetails?.data?.connect_twillio_phone_number?.location_ids
                  ),
                })
              );
              setLoadmoreMsg(true);
            }
          }
        }}
      >
        {conversation.length > 0 &&
          conversation.map((val: any) => (
            <div style={{ padding: "0px 12px", marginBottom: "2px" }}>
              <Card className="activity-card" key={val?.id}>
                <CardContent sx={{ padding: "16px" }}>
                  <MDTypography variant="h5" component="div">
                    {val.connect_user?.first_name} {val.connect_user?.last_name}
                    {!val.connect_user?.first_name &&
                      !val.connect_user?.last_name &&
                      val.connect_user?.login_email}
                  </MDTypography>
                  <MDTypography
                    variant="body2"
                    component="p"
                    fontSize="small"
                    className="activity-card-date"
                  >
                    {moment(val?.updated_at).format("MMMM D, YYYY, HH:mm")}
                  </MDTypography>
                  <MDTypography variant="body2">
                    {val?.content && val?.content?.length > 1000 ? (
                      <>
                        <MDTypography variant="button" fontWeight="regular">
                          {readMore === val?.id ? val?.content : val?.content.slice(0, 1000)}
                        </MDTypography>
                        {readMore === val?.id ? (
                          <MDTypography
                            variant="button"
                            className="cursor-pointer font-bold"
                            onClick={() => readMoreText(null)}
                          >
                            ...Show less
                          </MDTypography>
                        ) : (
                          <MDTypography
                            variant="button"
                            className="cursor-pointer font-bold"
                            onClick={() => readMoreText(val?.id)}
                          >
                            ...Read more
                          </MDTypography>
                        )}
                      </>
                    ) : (
                      <MDTypography variant="button" fontWeight="regular">
                        {val?.content}
                      </MDTypography>
                    )}
                    {displayFiles(val?.files || [])}
                  </MDTypography>
                </CardContent>
              </Card>
            </div>
          ))}
        {!conversation.length && (
          <Grid
            container
            spacing={0}
            direction="column"
            alignItems="center"
            justifyContent="center"
            style={{ minHeight: "42vh" }}
          >
            <MDTypography variant="body2" sx={{ mb: 1 }}>
              No Notes
            </MDTypography>
          </Grid>
        )}
      </Grid>
      <Grid item xs={12} sm={12} md={12} lg={12} sx={{ padding: "0px 12px" }}>
        <Tabs
          className="lead-tabs-msg"
          value={msgtabValue}
          onChange={changeMsgTab}
          sx={{ width: "100%" }}
        >
          <Tab
            id="internal_note"
            className="message-view-tab"
            label={<MDBox color="inherit">Internal Note</MDBox>}
            sx={{ justifyContent: "flex-start" }}
          />
        </Tabs>
        <MDTabPanel value={msgtabValue} index={0} className="notes-input">
          <Grid container>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <MDInput
                multiline
                className="notes-input-box"
                rows={3}
                sx={{ width: "100%", height: "100%" }}
                placeholder="Type Notes..."
                value={textNote || ""}
                onChange={(e: any) => setTextNote(e.target.value)}
                onKeyPress={handleKeyPress}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12} className="note-panel">
              <MDIconButton
                tooltipName="Save Note"
                type="button"
                aria-label="Save Note"
                color="info"
                onClick={sendTextNote}
              >
                <Icon fontSize="small">save_icon</Icon>
              </MDIconButton>
            </Grid>
          </Grid>
        </MDTabPanel>
      </Grid>
    </>
  );
}

export default NoteTab;
