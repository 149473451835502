// import { useState } from "react";
// @material-ui core components
import { Card, FormControlLabel, Grid, Switch } from "@mui/material";

import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";

function BillingContact(props: any): JSX.Element {
  const { errors, billingTabData, onCheckboxChange, onChangeField } = props;
  const getInputElement = (label: any, name: any, required?: boolean) => (
    <Grid item xs={12} sm={6}>
      <MDInput
        label={label}
        variant="standard"
        fullWidth
        name={name}
        placeholder={label}
        value={billingTabData?.[name] || ""}
        onChange={onChangeField("BILLING")}
        required={required}
        error={errors && errors[name] ? true : false}
        helperText={errors && errors[name]}
      />
    </Grid>
  );

  const getLabelElement = (name: any, right?: any) => (
    <Grid item xs={12} sm={6} md={6} lg={6} sx={{ textAlign: right ? "right" : "left" }}>
      <MDTypography variant="subtitle2" fontWeight={!right ? "bold" : "regular"}>
        {name}
      </MDTypography>
    </Grid>
  );

  return (
    <Card id="billing-contact" sx={{ overflow: "visible" }}>
      <MDBox p={3}>
        <MDTypography variant="h5">Billing Contact</MDTypography>
      </MDBox>
      <MDBox component="form" pb={3} px={3}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={12}>
            <FormControlLabel
              control={
                <Switch
                  checked={billingTabData?.billing_contact_info || false}
                  onChange={onCheckboxChange("BILLING")}
                  inputProps={{ "aria-label": "controlled" }}
                  name="billing_contact_info"
                />
              }
              label="Same as General Contact Info"
              labelPlacement="end"
            />
          </Grid>
          {!billingTabData?.billing_contact_info && (
            <>
              {getInputElement("First Name", "billing_first_name")}
              {getInputElement("Middle Name", "billing_middle_name")}
              {getInputElement("Last Name", "billing_last_name")}
              {getInputElement("Billing Phone", "billing_phone")}
              {getInputElement("Billing Email", "billing_email")}
            </>
          )}
          {billingTabData?.billing_contact_info && (
            <>
              {getLabelElement("First Name: ")}
              {getLabelElement(billingTabData?.f_name, true)}
              {getLabelElement("Middle Name: ")}
              {getLabelElement(billingTabData?.m_name, true)}
              {getLabelElement("Last Name: ")}
              {getLabelElement(billingTabData?.l_name, true)}
              {getLabelElement("Billing Phone: ")}
              {getLabelElement(billingTabData?.phone_number, true)}
              {getLabelElement("Billing Email: ")}
              {getLabelElement(billingTabData?.email_address, true)}
            </>
          )}
        </Grid>
      </MDBox>
    </Card>
  );
}

export default BillingContact;
