import { createSlice, Draft, PayloadAction } from "@reduxjs/toolkit";

interface InitialState {
  associationList: any;
  associationDetails: any;
}

const initialState: InitialState = {
  associationList: [],
  associationDetails: {},
};

const associationSlice = createSlice({
  name: "associationSlice",
  initialState,
  reducers: {
    setAssociationList: (state: Draft<InitialState>, action: PayloadAction<any>) => ({
      ...state,
      associationList: action.payload,
    }),
    setAssociationDetails: (state: Draft<InitialState>, action: PayloadAction<any>) => ({
      ...state,
      associationDetails: action.payload,
    }),
  },
});

export const { setAssociationList, setAssociationDetails } = associationSlice.actions;

export default associationSlice.reducer;
