import React, { useEffect, useState } from "react";
import { Switch, Grid } from "@mui/material";

import MDDialog from "components/MDDialog";
import MDInput from "components/MDInput";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

import Validations from "helper/validations";
import { saveMsa, updateMsa } from "store/thunk/systemAdmin";
import { useAppDispatch } from "store/store";
import {
  getAllAssociationLocations,
  getPermission,
  adminSuperAdmin,
  isHtmlEmpty,
} from "helper/services";
import CustomAutoSearch from "components/CustomAutoSearch";
import MDEditor from "components/MDEditor";
import constants from "helper/constants";
import CustomLabel from "components/CustomLabel";

function AddUpdate(props: any): JSX.Element {
  const dispatch = useAppDispatch();
  const { open, onClose, updateData, pageNo, perPage, search, sortBy } = props;
  const [msa, setMsa] = useState<any>();
  const [errors, setErrors] = useState<any>({});

  useEffect(() => {
    if (updateData?.id) {
      const data = JSON.parse(JSON.stringify(updateData));
      setMsa(data);
    }
  }, [updateData]);

  const onSave = () => {
    const error = Validations.validateMSAForm(msa);
    setErrors(error);
    if (!Object.keys(error).length) {
      const tempReq = JSON.parse(JSON.stringify(msa));
      if (tempReq?.locations && tempReq?.locations.length > 0) {
        tempReq.location_ids = tempReq?.locations.map((val: any) => val.id);
      }
      const req = {
        msaData: tempReq,
        onClose,
        pageNo,
        perPage,
        search,
        sortBy,
      };
      if (tempReq.id) {
        dispatch(updateMsa(req));
      } else {
        dispatch(saveMsa(req));
      }
    }
  };

  const onChangeField = (value: any, field: string) => {
    let copyValue = value;
    // react-quill uses HTML tags for markup purposes even if it's empty
    if ((field === "salons_for_lease" || field === "salons_in_msa") && isHtmlEmpty(value)) {
      copyValue = "";
    }
    setMsa({ ...msa, [field]: copyValue });
  };

  const dialogProps = {
    open,
    onClose,
    onSave,
    title: updateData?.id ? "Update MSA" : "Add MSA",
    size: "sm",
  };

  const onChangeAutoComplete = (name: string, value: any) => {
    setMsa({ ...msa, [name]: value });
  };

  return (
    <MDDialog {...dialogProps}>
      <MDBox mt={1}>
        <MDInput
          label="Name"
          variant="standard"
          fullWidth
          required
          name="name"
          placeholder="name"
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            onChangeField(e.target.value, "name")
          }
          value={msa?.name ? msa?.name : ""}
          error={errors && errors.name ? true : false}
          helperText={errors && errors.name}
        />
      </MDBox>
      <MDBox mt={1}>
        <MDInput
          label="Url Name"
          variant="standard"
          fullWidth
          required
          name="url_name"
          placeholder="Url name"
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            onChangeField(e.target.value, "url_name")
          }
          value={msa?.url_name ? msa?.url_name : ""}
          error={errors && errors.url_name ? true : false}
          helperText={errors && errors.url_name}
        />
      </MDBox>
      <MDBox mt={1}>
        <MDInput
          label="Legacy"
          variant="standard"
          fullWidth
          name="legacy_id"
          placeholder="Legacy"
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            onChangeField(e.target.value, "legacy_id")
          }
          value={msa?.legacy_id ? msa?.legacy_id : ""}
          error={errors && errors.legacy_id ? true : false}
          helperText={errors && errors.legacy_id}
        />
      </MDBox>
      <MDBox mt={1}>
        <Grid item xs={12} sm={12} className="editorHeight">
          <CustomLabel label="Salons For Lease" />
          <MDEditor
            value={msa?.salons_for_lease ?? ""}
            onChange={(value: string) => onChangeField(value, "salons_for_lease")}
            placeholder="Salons For Lease"
            modules={constants.editorModules}
            formats={constants.editorFormats}
          />
        </Grid>
      </MDBox>
      <MDBox mt={1}>
        <Grid item xs={12} sm={12} className="editorHeight">
          <CustomLabel label="Salons In MSA" />
          <MDEditor
            value={msa?.salons_in_msa ?? ""}
            onChange={(value: string) => onChangeField(value, "salons_in_msa")}
            placeholder="Salons In MSA"
            modules={constants.editorModules}
            formats={constants.editorFormats}
          />
        </Grid>
      </MDBox>
      <MDBox mt={1}>
        <MDInput
          label="Description"
          fullWidth
          multiline
          variant="standard"
          rows={2}
          name="description"
          onChange={(e: any) => onChangeField(e.target.value, "description")}
          value={msa?.description ? msa.description : ""}
        />
      </MDBox>
      <MDBox mt={1}>
        <MDInput
          label="Tracking Code"
          variant="standard"
          fullWidth
          name="tracking_code"
          placeholder="Tracking Code"
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            onChangeField(e.target.value, "tracking_code")
          }
          value={msa?.tracking_code ? msa?.tracking_code : ""}
        />
      </MDBox>
      <MDBox mt={4}>
        <CustomAutoSearch
          idKey="id"
          multiple
          nameKey="name"
          fieldLabel="Location"
          apiName="locations"
          responseKey="locations"
          name="locations"
          value={msa?.locations || []}
          onChange={onChangeAutoComplete}
          usersData={getAllAssociationLocations()}
          createNewLink={
            adminSuperAdmin() && getPermission("locations", "locations_location")
              ? "/locations"
              : ""
          }
        />
      </MDBox>
      <MDBox mt={1}>
        <MDTypography variant="span" fontSize="13px">
          Active?
        </MDTypography>
        <MDBox ml={-1.5}>
          <Switch
            checked={msa?.active}
            onChange={(_, checked) => onChangeField(checked, "active")}
          />
        </MDBox>
      </MDBox>
    </MDDialog>
  );
}

export default AddUpdate;
