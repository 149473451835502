import { createAsyncThunk } from "@reduxjs/toolkit";
import Messages from "helper/messages";
import slsApiClient from "config/slsApiClient";
import { setLoading } from "store/slices/authSlice";
import { notificationFail, notificationSuccess } from "store/slices/notificationSlice";
import { getErrorMessage } from "helper/services";
import { setSignStatusData } from "store/slices/docusignSlice";

// update Location
export const updateSignStatus = createAsyncThunk(
  "updateSignStatus",
  async (_request: any, { dispatch }) => {
    dispatch(setLoading(true));

    const email = _request?.email || "";
    const name = _request?.name || "";
    const envelopId = _request?.envelopId || "";

    try {
      const response = await slsApiClient().post(`lease/update-sign-status`, {
        email,
        name,
        envelop_id: envelopId,
      });
      if (response) {
        dispatch(setSignStatusData(response?.data));
        dispatch(setLoading(false));
        // dispatch(notificationSuccess(Messages.SUCCESS.MSA_UPDATE_SUCCESS));
      } else {
        dispatch(notificationFail(response?.data?.message || Messages.ERROR.NO_DATA_FOUND));
      }
    } catch (error: any) {
      dispatch(setLoading(false));
      if (error?.response?.data?.error) {
        const message = getErrorMessage(error?.response?.data?.error);
        dispatch(notificationFail(message));
      } else {
        dispatch(notificationFail(error?.response?.data?.message || Messages.ERROR.NO_DATA_FOUND));
      }
    }
  }
);

export const getReceipentView = createAsyncThunk(
  "getReceipentView",
  async (_request: any, { dispatch }) => {
    dispatch(setLoading(true));

    const email = _request?.email || "";
    const envelopId = _request?.envelopId || "";
    const role = _request?.role || "signer";

    try {
      const response = await slsApiClient().post(`lease/receipient-view`, {
        email,
        envelop_id: envelopId,
        role,
      });
      if (response.data.status) {
        dispatch(setLoading(false));
        window.location.href = response.data.data.receiptUrl;
        // dispatch(notificationSuccess(Messages.SUCCESS.MSA_UPDATE_SUCCESS));
      } else {
        dispatch(notificationFail(response?.data?.message || Messages.ERROR.NO_DATA_FOUND));
      }
    } catch (error: any) {
      dispatch(setLoading(false));
      if (error?.response?.data?.error) {
        const message = getErrorMessage(error?.response?.data?.error);
        dispatch(notificationFail(message));
      } else {
        dispatch(notificationFail(error?.response?.data?.message || Messages.ERROR.NO_DATA_FOUND));
      }
    }
  }
);

export const updateManagerSignStatus = createAsyncThunk(
  "updateManagerSignStatus",
  async (_request: any, { dispatch }) => {
    dispatch(setLoading(true));

    const email = _request?.email || "";
    const envelopId = _request?.envelopId || "";
    const leaseId = _request?.leaseId || "";
    const navigate = _request?.navigate;

    try {
      const response = await slsApiClient().post(`lease/update-manager-sign-status`, {
        email,
        envelop_id: envelopId,
        leaseId,
      });
      if (response) {
        dispatch(setLoading(false));
        if (response.data.data) {
          window.location.href = response.data.data;
        } else {
          dispatch(notificationSuccess(response.data.message));
          if (navigate) {
            navigate(`/crm/lease-applications`);
          }
        }
      } else {
        dispatch(notificationFail(response?.data?.message || Messages.ERROR.NO_DATA_FOUND));
      }
    } catch (error: any) {
      dispatch(setLoading(false));
      if (error?.response?.data?.error) {
        const message = getErrorMessage(error?.response?.data?.error);
        dispatch(notificationFail(message));
      } else {
        dispatch(notificationFail(error?.response?.data?.message || Messages.ERROR.NO_DATA_FOUND));
      }
    }
  }
);
