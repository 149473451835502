import { Card, Grid } from "@mui/material";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import UserWithProfilePic from "components/CustomAutoSearch/UserWithProfilePic";

function DealOwner(props: any): JSX.Element {
  const { onChangeDealOwner, dealOwnerId, connectLocation } = props;

  return (
    <Card id="deal-owner">
      <MDBox p={3} pb={0}>
        <MDTypography variant="h5">Deal Owner</MDTypography>
      </MDBox>
      <MDBox pb={3} px={3}>
        <Grid container spacing={2} mt={1}>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <UserWithProfilePic
              placeholder={!dealOwnerId ? "No Owner" : ""}
              apiName="user-list-by-location"
              responseKey="users"
              name="users"
              slsAPI
              value={dealOwnerId || null}
              onChange={onChangeDealOwner}
              additionaFields={{ location_id: connectLocation?.cms_location_id }}
            />
          </Grid>
        </Grid>
      </MDBox>
    </Card>
  );
}

export default DealOwner;
