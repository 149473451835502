import { useCallback, useEffect, useLayoutEffect, useRef, useState } from "react";
import { Card, Icon, Checkbox } from "@mui/material";

import MDBox from "components/MDBox";
import MDButton from "components/MDButton";

import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import DataTable from "examples/Tables/DataTable";
import { useAppDispatch, useAppSelector } from "store/store";
import { deleteArticle, getArticles, getArticle } from "store/thunk/solaWebsiteThunk";
import {
  adminSuperAdmin,
  getAssociationLocations,
  getLocalDateTime,
  setDefaultSelectedColumns,
} from "helper/services";
import Messages from "helper/messages";
import MDDialog from "components/MDDialog";
import MDTypography from "components/MDTypography";
import MDIconButton from "components/MDIconButton";
import exportData from "helper/exportTableConst";
import { setDefaultsColumns } from "store/slices/connectUsersSlice";
import { getDefaultCoulmns, saveSelectedAllData } from "store/thunk/connectUserThunk";
import AddUpdate from "./addUpdate";
import ViewDetails from "./viewDetails";

function News(): JSX.Element {
  const dispatch = useAppDispatch();
  const { articles } = useAppSelector((state) => state.solaWebSiteSlice);
  const { userData } = useAppSelector((state) => state.authReducer);
  const [selectedItems, setSelectedItems] = useState([]);
  const [articlesList, setArticlesList] = useState([]);
  const [articlePopup, setArticlePopup] = useState(false);
  const [editArticle, setEditArticle] = useState(false);
  const [viewArticle, setViewArticle] = useState(false);
  const [viewPopup, setViewPopup] = useState(false);
  const [deleteArticleId, setDeleteArticle] = useState<any>(false);
  const [articleDetails, setArticleDetails] = useState<any>({});
  const [pageCount, setPageCount] = useState(0);
  const [pageNo, setPageNo] = useState(0);
  const [perPage, setPageSize] = useState(50);
  const [totalCount, setTotalCount] = useState(0);
  const [search, setSearch] = useState<any>(null);
  const [sortBy, setSortBy] = useState<any>([]);
  const [isCheckAll, setIsCheckAll] = useState(false);
  // SD-3199
  const [defaultExport, setDefaultExport] = useState<any>([]);
  const [callFetch, setCallFetch] = useState<any>(false);
  const [defaultSorting, setDefaultSorting] = useState<any>([]);
  const [defaultFilteing, setDefaultFilteing] = useState<any>([]);

  const fetchIdRef = useRef(0);

  const getColumns = () => {
    const columns: any = [
      {
        Header: "",
        disableSortBy: true,
        accessor: "checkbox",
        width: "80px",
      },
      { Header: "Title", accessor: "title", export: true },
      { Header: "Article Url", accessor: "article_url", export: true },
      { Header: "Summary", accessor: "summary", export: true },
      {
        Header: "Location",
        accessor: "location",
        sorting_column: "location",
        export: true,
        associative_colums: "locations.name",
      },
      { Header: "Created At", accessor: "created_at", export: true, is_date: true },
      { Header: "Action", accessor: "action", export: false, disableSortBy: true, width: "200px" },
    ];
    return columns;
  };

  const [checkedColumns, setCheckedColumns] = useState<any>(getColumns());
  const latestRefValue = useRef(checkedColumns);

  useEffect(() => {
    latestRefValue.current = checkedColumns;
  }, [checkedColumns]);

  useEffect(() => {
    if (articles && articles?.articles) {
      setArticlesList(articles?.articles);
      setPageCount(articles?.meta?.total_pages);
      setTotalCount(articles?.meta?.total_count);
    }
  }, [articles]);

  const fetchData = useCallback(({ pageSize, pageIndex, search, sortBy, filterData, isManual }) => {
    const fetchId = ++fetchIdRef.current;
    const sort: any = sortBy || [];
    // We'll even set a delay to simulate a server here
    setTimeout(() => {
      if (fetchId === fetchIdRef.current) {
        const sentReq = {
          sendData: {
            user_id: userData?.user_id,
            table_name: exportData.articles.table_name,
            table_columns: latestRefValue.current ? latestRefValue.current : checkedColumns,
            table_sort: sortBy && sortBy?.length ? sortBy : [],
            table_filter: filterData && filterData?.length ? filterData : [],
            default_export_data: defaultExport,
          },
        };
        if (isManual) {
          dispatch(saveSelectedAllData(sentReq));
        }
        setPageNo(pageIndex);
        setSearch(search);
        setSortBy(sort);
        setPageSize(pageSize);
        // setSelectedItems([]);
        dispatch(
          getArticles({
            pageSize,
            pageIndex,
            search,
            sort,
            filterData,
          })
        );
      }
    }, 1000);
  }, []);

  useLayoutEffect(() => {
    dispatch(setDefaultsColumns([]));
    // Set data in variable and Call defaultColums Thunk
    const sentReq = {
      user_id: userData?.user_id,
      table_name: exportData.articles.table_name,
    };
    dispatch(getDefaultCoulmns(sentReq)).then((resp: any) => {
      // SD-3199

      if (resp?.payload) {
        const respFilter = resp?.payload?.table_filter || [];
        const respSorting = resp?.payload?.table_sort || [];
        const defaultDBexportData = resp?.payload?.default_export_data || [];
        const defaultDBColumn = resp?.payload?.table_columns || [];
        const tempdata = setDefaultSelectedColumns(defaultDBColumn, getColumns());
        setCheckedColumns(tempdata);
        setDefaultExport(defaultDBexportData);
        setDefaultSorting(respSorting);
        setDefaultFilteing(respFilter);
        setCallFetch(true);
        setSortBy(respSorting);
        fetchData({
          perPage,
          pageNo,
          search,
          sortBy: respSorting && respSorting?.length ? respSorting : [],
          additionalField: null,
          filterData: respFilter && respFilter?.length ? respFilter : [],
        });
      }
    });
  }, []);
  const onEditArticle = (object: any) => {
    setViewArticle(false);
    setEditArticle(true);
    const getReq = {
      id: object.id,
      setArticleDetails,
    };
    dispatch(getArticle(getReq));
  };

  const onViewDetails = (object: any) => {
    setViewArticle(true);
    const getReq = {
      id: object.id,
      setArticleDetails,
    };
    dispatch(getArticle(getReq));
  };

  useEffect(() => {
    if (Object.values(articleDetails).length > 0) {
      if (viewArticle) {
        setViewPopup(true);
      } else {
        setArticlePopup(true);
      }
    }
  }, [articleDetails]);

  const actionButtons = (object: any) => (
    <>
      <MDIconButton
        tooltipName="View"
        aria-label="Open"
        color="info"
        onClick={() => onViewDetails(object)}
      >
        <Icon fontSize="small">visibility</Icon>
      </MDIconButton>
      <MDIconButton
        tooltipName="Edit"
        aria-label="Edit"
        color="info"
        onClick={() => onEditArticle(object)}
      >
        <Icon fontSize="small">edit</Icon>
      </MDIconButton>
      <MDIconButton
        tooltipName="Delete"
        aria-label="Delete"
        color="error"
        onClick={() => setDeleteArticle(object.id)}
      >
        <Icon fontSize="small">delete</Icon>
      </MDIconButton>
    </>
  );

  const onChangeCheckBox = (e: any) => {
    let items = [...selectedItems];
    if (e.target.checked && !items.includes(e.target.value)) {
      items.push(e.target.value);
      if (items.length === articlesList.length) {
        setIsCheckAll(true);
      }
    } else {
      items = items.filter((id: any) => id !== e.target.value);
      setIsCheckAll(false);
    }
    setSelectedItems(items);
  };

  const checkUncheckAll = (e: any) => {
    let allIds: any = [];
    allIds = articlesList.map((val: any) => String(val.id));
    if (e.target.checked) {
      const selectedIds = [...new Set([...selectedItems, ...allIds])];
      setSelectedItems(selectedIds);
      setIsCheckAll(true);
    } else {
      allIds = articlesList.map((val: any) => String(val.id));
      allIds = selectedItems.filter((el: any) => !allIds.includes(el));
      setSelectedItems([...allIds]);
      setIsCheckAll(false);
    }
    // setSelectedItems(allIds);
  };

  const getRows = () => {
    const data: any = articlesList.map((obj: any) => ({
      checkbox: (
        <Checkbox
          checked={selectedItems.includes(obj.id) || selectedItems.includes(obj.id.toString())}
          onChange={(e: any) => onChangeCheckBox(e)}
          value={obj.id}
        />
      ),
      id: obj.id,
      title: obj.title ? obj.title : "-",
      article_url: obj.article_url ? obj.article_url : "-",
      summary: (
        <MDBox
          dangerouslySetInnerHTML={{
            __html: obj.summary ? obj?.summary : "-",
          }}
        />
      ),
      location: obj.location_name ? obj.location_name : "-",
      created_at: obj.created_at ? getLocalDateTime(obj.created_at) : "-",
      action: actionButtons(obj),
    }));
    return data;
  };

  const tableData = {
    columns: getColumns(),
    rows: getRows(),
  };

  // Start Remove selcted items
  const removeSelectedItem = (deletedId: number) => {
    setSelectedItems(selectedItems.filter((item: any) => Number(item) !== deletedId));
  };
  // End Remove selcted items

  const onDelete = () => {
    if (deleteArticleId) {
      const delReq = {
        id: deleteArticleId,
        setDeleteArticle,
        pageNo,
        perPage,
        search,
        sortBy,
        removeSelectedItem,
      };
      dispatch(deleteArticle(delReq));
    }
  };

  const deleteDialogProps = {
    open: deleteArticle,
    onClose: () => setDeleteArticle(false),
    onSave: () => onDelete(),
    title: "Delete News",
    size: "sm",
    saveBtn: false,
    deleteBtn: true,
    saveTbtText: "Yes",
    closeBtnText: "No",
  };

  // Remove uncheck boxes
  const clearCheckItem = () => {
    setSelectedItems([]);
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={4} pb={3}>
        <Card>
          <MDBox
            bgColor="dark"
            color="white"
            coloredShadow="dark"
            borderRadius="xl"
            alignItems="center"
            justifyContent="space-between"
            pt={1}
            pb={1}
            pl={2}
            pr={2}
            ml={2}
            mr={2}
            display="flex"
            mt={-3}
            className="page-header"
          >
            <p className="page-header-label">News</p>
            <MDButton
              variant="gradient"
              color="light"
              size="small"
              className="xs-small"
              onClick={() => {
                setEditArticle(false);
                setArticlePopup(true);
              }}
            >
              <Icon sx={{ fontWeight: "bold" }}>add</Icon> Add new
            </MDButton>
          </MDBox>
          {(defaultSorting || defaultSorting?.length) &&
            callFetch && ( // SD-3199
              <DataTable
                defaultSortingColumns={defaultSorting} // SD-3199
                defaultFilteringColums={defaultFilteing} // SD-3199
                table={tableData}
                canSearch
                searchText="Search News..."
                selectedItems={selectedItems}
                showExportSelectedBtn
                showExportAllBtn
                pageCount={pageCount}
                pageNo={pageNo}
                setPageNo={setPageNo}
                totalCount={totalCount}
                fetchData={fetchData}
                exportFields={exportData.articles.export_fields}
                exportTable={exportData.articles.table_name}
                exportType={exportData.articles.export_type}
                advanceFilter
                manualSearch
                locationsIds={!adminSuperAdmin() && getAssociationLocations()}
                manualSort
                isDisplayCheckAllCheckbox
                onChangeCheckAllCheckbox={checkUncheckAll}
                isCheckAll={isCheckAll}
                clearCheckItem={clearCheckItem}
                useRubyApiToExport
              />
            )}
          {viewArticle && (
            <ViewDetails
              open={viewPopup}
              onClose={() => setViewPopup(false)}
              articleDetails={articleDetails}
            />
          )}
          {articlePopup && (
            <AddUpdate
              open={articlePopup}
              onClose={() => setArticlePopup(false)}
              editArticle={editArticle}
              pageNo={pageNo}
              perPage={perPage}
              search={search}
              sortBy={sortBy}
              articleDetails={articleDetails}
            />
          )}
          {deleteArticleId && (
            <MDDialog {...deleteDialogProps}>
              <MDTypography variant="h6" fontWeight="medium">
                {Messages.GENERAL.SURE_TO_DELETE}
              </MDTypography>
            </MDDialog>
          )}
        </Card>
      </MDBox>
    </DashboardLayout>
  );
}

export default News;
