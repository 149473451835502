import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";
import { Theme } from "@mui/material/styles";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import { ScrollToSection, systemAdmin } from "helper/services";
import { useMaterialUIController } from "context";

function Sidenav(): JSX.Element {
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;

  const sidenavItems = [
    { icon: "person", label: "basic info", href: "basic-info" },
    { icon: "receipt_long", label: "contact info", href: "contact-info" },
    // { icon: "location_on", label: "locations", href: "locations" },
    // { icon: "person", label: "webpages", href: "webpages" },
    { icon: "content_cut';", label: "services", href: "services" },
  ];
  if (systemAdmin()) {
    sidenavItems.push({ icon: "manage_accounts", label: "Rent Manager", href: "rent-manager" });
  }

  const renderSidenavItems = sidenavItems
    ?.filter((obj: any) => obj !== false)
    ?.map(({ icon, label, href }, key) => {
      const itemKey = `item-${key}`;

      return (
        <MDBox key={itemKey} component="li" pt={key === 0 ? 0 : 1}>
          <MDTypography
            component="a"
            onClick={() => ScrollToSection(href)}
            variant="button"
            fontWeight="regular"
            textTransform="capitalize"
            sx={({
              borders: { borderRadius },
              functions: { pxToRem },
              palette: { light },
              transitions,
            }: Theme) => ({
              display: "flex",
              cursor: "pointer",
              alignItems: "center",
              borderRadius: borderRadius.md,
              padding: `${pxToRem(10)} ${pxToRem(16)}`,
              transition: transitions.create("background-color", {
                easing: transitions.easing.easeInOut,
                duration: transitions.duration.shorter,
              }),

              "&:hover": {
                backgroundColor: light.main,
              },
            })}
          >
            <MDBox mr={1.5} lineHeight={1} color={darkMode ? "white" : "dark"}>
              <Icon fontSize="small">{icon}</Icon>
            </MDBox>
            {label}
          </MDTypography>
        </MDBox>
      );
    });

  return (
    <Card
      sx={{
        borderRadius: ({ borders: { borderRadius } }) => borderRadius.lg,
        position: "sticky",
        top: "100px",
      }}
    >
      <MDBox
        component="ul"
        display="flex"
        flexDirection="column"
        p={2}
        m={0}
        sx={{ listStyle: "none" }}
      >
        {renderSidenavItems}
      </MDBox>
    </Card>
  );
}

export default Sidenav;
