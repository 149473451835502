import { useState, useEffect } from "react";
import { useAppDispatch, useAppSelector } from "store/store";
import { Card, Icon, ListItemText } from "@mui/material";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDDialog from "components/MDDialog";
import MDIconButton from "components/MDIconButton";
import MDTypography from "components/MDTypography";
import DataTable from "examples/Tables/DataTable";
import Messages from "helper/messages";
import { deleteSMSTemplate, getSMSTemplateList } from "store/thunk/locationThunk";
import AddSMSTemplate from "./add-sms-template";

function TemplateSettings(props: any): JSX.Element {
  const { connectLocation } = props;
  const dispatch = useAppDispatch();
  const { smsTemplateList } = useAppSelector((state) => state.locationSlice);
  const [templatesData, setTemplatesData] = useState([]);
  const [addTemplatePopup, setAddTemplatePopup] = useState(false);
  const [deleteTemplateId, setDeleteTemplateId] = useState<any>(null);
  const [editTemplateData, setEditTemplateData] = useState<any>(null);
  const [isUpdate, setIsUpdate] = useState<any>(false);

  useEffect(() => {
    dispatch(getSMSTemplateList({ locationIds: connectLocation?.cms_location_id }));
  }, []);

  const onClose = () => {
    setAddTemplatePopup(false);
  };

  const onDelete = () => {
    if (deleteTemplateId?.id) {
      dispatch(
        deleteSMSTemplate({
          locationId: connectLocation?.cms_location_id,
          id: deleteTemplateId?.id,
        })
      );
    }
    setDeleteTemplateId(null);
  };

  useEffect(() => {
    if (smsTemplateList) {
      setTemplatesData(smsTemplateList);
    }
  }, [smsTemplateList]);

  const setEditTemplate = (obj: any) => {
    setEditTemplateData(obj);
    setAddTemplatePopup(true);
    setIsUpdate(true);
  };

  const setAddTemplate = () => {
    setEditTemplateData([]);
    setIsUpdate(false);
    setAddTemplatePopup(true);
  };

  const actionButtons = (obj: any) => (
    <>
      <MDIconButton
        tooltipName="Edit"
        aria-label="Edit"
        color="info"
        onClick={() => setEditTemplate(obj)}
      >
        <Icon fontSize="small">edit</Icon>
      </MDIconButton>
      <MDIconButton
        tooltipName="Delete"
        aria-label="Delete"
        color="error"
        onClick={() => setDeleteTemplateId(obj)}
      >
        <Icon fontSize="small">delete</Icon>
      </MDIconButton>
    </>
  );

  const getTemplateContent = (obj: any) => (
    <MDBox className="template-message-column">
      <ListItemText primary={obj?.template_name} secondary={obj?.template_content} />
    </MDBox>
  );

  const getRows = () => {
    const data: any = templatesData?.map((obj: any) => ({
      template: getTemplateContent(obj),
      action: actionButtons(obj),
    }));
    return data;
  };

  const tableData = {
    columns: [
      { Header: "Title & Message", accessor: "template", export: true },
      { Header: "Action", accessor: "action", export: false, disableSortBy: true, width: 100 },
    ],
    rows: getRows(),
  };

  const deleteDialogProps = {
    open: deleteTemplateId ? true : false,
    onClose: () => setDeleteTemplateId(null),
    onSave: () => onDelete(),
    title: "Delete Template",
    size: "sm",
    saveBtn: false,
    deleteBtn: true,
    saveTbtText: "Yes",
    closeBtnText: "No",
  };

  return (
    <Card id="template-settings">
      <MDBox p={3} display="flex" justifyContent="space-between">
        <MDTypography variant="h5" className="page-header-label">
          SMS Template Settings
        </MDTypography>
        <MDButton
          className="xs-small"
          variant="gradient"
          color="info"
          size="small"
          onClick={() => setAddTemplate()}
        >
          <Icon sx={{ fontWeight: "bold" }}>add</Icon> Add New
        </MDButton>
      </MDBox>
      <DataTable table={tableData} />

      {addTemplatePopup && (
        <AddSMSTemplate
          open={addTemplatePopup}
          editTemplateData={editTemplateData}
          isUpdate={isUpdate}
          onClose={onClose}
          location={connectLocation}
        />
      )}

      {deleteTemplateId && (
        <MDDialog {...deleteDialogProps}>
          <MDTypography variant="h6" fontWeight="medium">
            {Messages.GENERAL.SURE_TO_DELETE}
          </MDTypography>
        </MDDialog>
      )}
    </Card>
  );
}

export default TemplateSettings;
