import { useState, useEffect, useRef, useCallback, useLayoutEffect } from "react";
import { Card, Checkbox, Icon } from "@mui/material";
import MDButton from "components/MDButton";
import MDBox from "components/MDBox";
import DataTable from "examples/Tables/DataTable";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import { useAppDispatch, useAppSelector } from "store/store";
import { getCountriesList } from "store/thunk/systemAdmin";
import { deleteBrand, getAllBrands } from "store/thunk/mobileAppThunk";
import Messages from "helper/messages";
import MDTypography from "components/MDTypography";
import MDDialog from "components/MDDialog";
import exportData from "helper/exportTableConst";
import MDIconButton from "components/MDIconButton";
import { getDefaultCoulmns, saveSelectedAllData } from "store/thunk/connectUserThunk";
import { setDefaultsColumns } from "store/slices/connectUsersSlice";
import { setDefaultSelectedColumns } from "helper/services";
import AddUpdate from "./addUpdate";
import ViewDetails from "./viewDetails";

function Brands(): JSX.Element {
  const dispatch = useAppDispatch();
  const { brands } = useAppSelector((state) => state.mobileAppSlice);
  const { userData } = useAppSelector((state) => state.authReducer);
  const [selectedItems, setSelectedItems] = useState([]);
  const [allBrands, setAllBrands] = useState([]);
  const [editBrandId, setEditBrandId] = useState(null);
  const [addUpdatePopup, setAddUpdatePopup] = useState(false);
  const [viewBrandId, setViewBrandId] = useState(null);
  const [viewPopup, setViewPopup] = useState(false);
  const [deleteBrandId, setDeleteBrandId] = useState<any>(null);
  const [pageCount, setPageCount] = useState(0);
  const [pageNo, setPageNo] = useState(0);
  const [perPage, setPageSize] = useState(50);
  const [totalCount, setTotalCount] = useState(0);
  const [search, setSearch] = useState<any>(null);
  const [sortBy, setSortBy] = useState<any>([]);
  const [isCheckAll, setIsCheckAll] = useState(false);
  const [defaultExport, setDefaultExport] = useState<any>([]);
  const [callFetch, setCallFetch] = useState<any>(false);
  const [defaultSorting, setDefaultSorting] = useState<any>([]);
  const [defaultFilteing, setDefaultFilteing] = useState<any>([]);

  const fetchIdRef = useRef(0);

  const getColumns = () => {
    const columns: any = [
      {
        Header: "",
        disableSortBy: true,
        accessor: "checkbox",
        width: "80px",
      },
      { Header: "Name", accessor: "name", export: true },
      { Header: "Website Url", accessor: "website_url", export: true },
      { Header: "Action", accessor: "action", export: false, disableSortBy: true },
    ];
    return columns;
  };

  const [checkedColumns, setCheckedColumns] = useState<any>(getColumns());
  const latestRefValue = useRef(checkedColumns);

  useEffect(() => {
    latestRefValue.current = checkedColumns;
  }, [checkedColumns]);

  useEffect(() => {
    if (brands && brands?.brands) {
      setAllBrands(brands?.brands);
      setPageCount(brands?.meta?.total_pages);
      setTotalCount(brands?.meta?.total_count);
    }
  }, [brands]);

  useEffect(() => {
    dispatch(getCountriesList({ all: true }));
  }, []);

  const fetchData = useCallback(({ pageSize, pageIndex, search, sortBy, filterData, isManual }) => {
    const fetchId = ++fetchIdRef.current;
    const sort: any = sortBy || [];
    // We'll even set a delay to simulate a server here
    setTimeout(() => {
      if (fetchId === fetchIdRef.current) {
        const sentReq = {
          sendData: {
            user_id: userData?.user_id,
            table_name: exportData.brands.table_name,
            table_columns: latestRefValue.current ? latestRefValue.current : checkedColumns,
            table_sort: sortBy && sortBy?.length ? sortBy : [],
            table_filter: filterData && filterData?.length ? filterData : [],
            default_export_data: defaultExport,
          },
        };
        if (isManual) {
          dispatch(saveSelectedAllData(sentReq));
        }
        setPageNo(pageIndex);
        setSearch(search);
        setSortBy(sort);
        setPageSize(pageSize);
        // setSelectedItems([]);
        setIsCheckAll(false);
        dispatch(getAllBrands({ pageSize, pageIndex, search, sort, filterData }));
      }
    }, 1000);
  }, []);

  useLayoutEffect(() => {
    dispatch(setDefaultsColumns([]));
    // Set data in variable and Call defaultColums Thunk
    const sentReq = {
      user_id: userData?.user_id,
      table_name: exportData.brands.table_name,
    };
    dispatch(getDefaultCoulmns(sentReq)).then((resp: any) => {
      // SD-3199
      if (resp?.payload) {
        const respFilter = resp?.payload?.table_filter || [];
        const respSorting = resp?.payload?.table_sort || [];
        const defaultDBexportData = resp?.payload?.default_export_data || [];
        const defaultDBColumn = resp?.payload?.table_columns || [];
        const tempdata = setDefaultSelectedColumns(defaultDBColumn, getColumns());
        setCheckedColumns(tempdata);
        setDefaultExport(defaultDBexportData);
        setDefaultSorting(respSorting);
        setSortBy(respSorting);
        setDefaultFilteing(respFilter);
        setCallFetch(true);
        fetchData({
          perPage,
          pageNo,
          search,
          sortBy: respSorting && respSorting?.length ? respSorting : [],
          additionalField: null,
          filterData: respFilter && respFilter?.length ? respFilter : [],
        });
      }
    });
  }, []);

  const onChangeCheckBox = (e: any) => {
    let items = JSON.parse(JSON.stringify(selectedItems));
    if (e.target.checked && !items.includes(e.target.value)) {
      items.push(e.target.value);
      if (items.length === allBrands.length) {
        setIsCheckAll(true);
      }
    } else {
      items = items.filter((id: any) => id !== e.target.value);
      setIsCheckAll(false);
    }
    setSelectedItems(items);
  };

  const checkUncheckAll = (e: any) => {
    let allIds: any = [];
    allIds = allBrands.map((val: any) => String(val.id));
    if (e.target.checked) {
      const selectedIds = [...new Set([...selectedItems, ...allIds])];
      setSelectedItems(selectedIds);
      setIsCheckAll(true);
    } else {
      allIds = allBrands.map((val: any) => String(val.id));
      allIds = selectedItems.filter((el: any) => !allIds.includes(el));
      setSelectedItems([...allIds]);
      setIsCheckAll(false);
    }
    // setSelectedItems(allIds);
  };

  const actionButtons = (obj: any) => (
    <>
      <MDIconButton
        tooltipName="View"
        aria-label="Open"
        color="info"
        onClick={() => {
          setViewPopup(true);
          setViewBrandId(obj.id);
        }}
      >
        <Icon fontSize="small">visibility</Icon>
      </MDIconButton>
      <MDIconButton
        tooltipName="Edit"
        aria-label="Edit"
        color="info"
        onClick={() => {
          setEditBrandId(obj.id);
          setAddUpdatePopup(true);
        }}
      >
        <Icon fontSize="small">edit</Icon>
      </MDIconButton>
      <MDIconButton
        tooltipName="Delete"
        aria-label="Delete"
        color="error"
        onClick={() => setDeleteBrandId(obj.id)}
      >
        <Icon fontSize="small">delete</Icon>
      </MDIconButton>
    </>
  );

  const getRows = () => {
    const data: any = allBrands.map((obj: any) => ({
      checkbox: (
        <Checkbox
          checked={selectedItems.includes(obj.id) || selectedItems.includes(obj.id.toString())}
          onChange={(e: any) => onChangeCheckBox(e)}
          value={obj.id}
        />
      ),
      id: obj.id,
      name: obj.name || "-",
      website_url: obj.website_url || "-",
      action: actionButtons(obj),
    }));
    return data;
  };

  const tableData = {
    columns: getColumns(),
    rows: getRows(),
  };

  // Start Remove selcted items
  const removeSelectedItem = (deletedId: number) => {
    setSelectedItems(selectedItems.filter((item: any) => Number(item) !== deletedId));
  };
  // End Remove selcted items

  const onDelete = () => {
    if (deleteBrandId) {
      const delReq = {
        id: deleteBrandId,
        setDeleteBrandId,
        pageNo,
        perPage,
        search,
        sortBy,
        removeSelectedItem,
      };
      dispatch(deleteBrand(delReq));
    }
  };

  const deleteDialogProps = {
    open: deleteBrandId,
    onClose: () => setDeleteBrandId(false),
    onSave: () => onDelete(),
    title: "Delete Brand",
    size: "sm",
    saveBtn: false,
    deleteBtn: true,
    saveTbtText: "Yes",
    closeBtnText: "No",
  };

  // Remove uncheck boxes
  const clearCheckItem = () => {
    setSelectedItems([]);
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={4} pb={3}>
        <Card>
          <MDBox
            bgColor="dark"
            color="white"
            coloredShadow="dark"
            borderRadius="xl"
            alignItems="center"
            justifyContent="space-between"
            pt={1}
            pb={1}
            pl={2}
            pr={2}
            ml={2}
            mr={2}
            display="flex"
            mt={-3}
            className="page-header"
          >
            <p className="page-header-label">Brands</p>
            <MDButton
              variant="gradient"
              color="light"
              size="small"
              className="xs-small"
              onClick={() => setAddUpdatePopup(true)}
            >
              <Icon sx={{ fontWeight: "bold" }}>add</Icon> Add new
            </MDButton>
          </MDBox>
          {(defaultSorting || defaultSorting?.length) && callFetch && (
            <DataTable
              defaultSortingColumns={defaultSorting}
              defaultFilteringColums={defaultFilteing}
              table={tableData}
              showExportSelectedBtn
              showExportAllBtn
              selectedItems={selectedItems}
              pageCount={pageCount}
              pageNo={pageNo}
              setPageNo={setPageNo}
              totalCount={totalCount}
              fetchData={fetchData}
              manualSearch
              exportFields={exportData.brands.export_fields}
              exportTable={exportData.brands.table_name}
              exportType={exportData.brands.export_type}
              advanceFilter
              manualSort
              isDisplayCheckAllCheckbox
              onChangeCheckAllCheckbox={checkUncheckAll}
              isCheckAll={isCheckAll}
              clearCheckItem={clearCheckItem}
              useRubyApiToExport
            />
          )}
          {addUpdatePopup && (
            <AddUpdate
              open={addUpdatePopup}
              pageNo={pageNo}
              perPage={perPage}
              search={search}
              sortBy={sortBy}
              onClose={() => {
                setAddUpdatePopup(false);
                setEditBrandId(null);
              }}
              editBrandId={editBrandId}
            />
          )}
          {viewPopup && (
            <ViewDetails
              open={viewPopup}
              onClose={() => {
                setViewPopup(false);
                setViewBrandId(null);
              }}
              viewBrandId={viewBrandId}
            />
          )}
          {deleteBrandId && (
            <MDDialog {...deleteDialogProps}>
              <MDTypography variant="h6" fontWeight="medium">
                {Messages.GENERAL.SURE_TO_DELETE}
              </MDTypography>
            </MDDialog>
          )}
        </Card>
      </MDBox>
    </DashboardLayout>
  );
}

export default Brands;
