import { useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import MDBox from "components/MDBox";
import ReportsBarChart from "examples/Charts/BarCharts/ReportsBarChart";
import ReportsLineChart from "examples/Charts/LineCharts/ReportsLineChart";
import ComplexStatisticsCard from "examples/Cards/StatisticsCards/ComplexStatisticsCard";
import SalesByCountry from "layouts/dashboards/operations-dashboard/components/SalesByCountry";
import { getLast6Months, getLast12Months, abbreviateNumber } from "helper/services";

function Operations(props: any): JSX.Element {
  const { fullDashbardData, range } = props;
  const [labels, setLabels] = useState<any>([0, 0, 0, 0, 0, 0]);
  // const labels: any = range === "last_12_month" ? getLast12Months(true) : getLast6Months(true);

  useEffect(() => {
    let labelData;
    if (range && range === "last_12_month") {
      labelData = getLast12Months(true);
    } else {
      labelData = getLast6Months(true);
    }
    setLabels(labelData);
  }, [range]);

  // useEffect(() => {
  //   if (fullDashbardData) {
  //     if (fullDashbardData?.leads_chart) {
  //       fullDashbardData?.leads_chart?.map((lead: any) => {
  //         setLeadsChart;
  //       });
  //     }
  //   }
  // }, [fullDashbardData]);

  const reportsBarChartData = {
    labels,
    datasets: {
      label: "Leads",
      data: fullDashbardData?.leads_chart ? fullDashbardData?.leads_chart : [0, 0, 0, 0, 0, 0],
    },
  };

  const occupancy = {
    labels,
    datasets: {
      label: "Occupancy %",
      data: fullDashbardData?.occupancy_chart
        ? fullDashbardData?.occupancy_chart
        : [0, 0, 0, 0, 0, 0],
    },
  };
  const revenue = {
    labels,
    datasets: {
      label: "Revenue",
      data: fullDashbardData?.revenue_chart ? fullDashbardData?.revenue_chart : [0, 0, 0, 0, 0, 0],
    },
    isCurrency: true,
  };

  const getColorByNumber = (num: any) => (num >= 0 ? "success" : "error");

  return (
    <MDBox py={3} mt={2}>
      <Grid container>
        <SalesByCountry {...props} />
      </Grid>
      <MDBox mt={4}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={6} lg={3}>
            <MDBox mb={1.5}>
              <ComplexStatisticsCard
                color="dark"
                icon="assignment_icon"
                className="custom-static-card"
                title="Gross Revenue"
                count={`$${abbreviateNumber(
                  fullDashbardData?.gross_revenue_total ? fullDashbardData?.gross_revenue_total : 0
                )}`}
                percentage={{
                  color: getColorByNumber(fullDashbardData?.gross_revenue_diff || 0),
                  amount: abbreviateNumber(fullDashbardData?.gross_revenue_diff || 0),
                  label: "Last Month",
                }}
              />
            </MDBox>
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            <MDBox mb={1.5}>
              <ComplexStatisticsCard
                icon="event_seat_icon"
                title="Unoccupied Chairs"
                count={abbreviateNumber(
                  fullDashbardData?.unoccupied_chairs_total
                    ? fullDashbardData?.unoccupied_chairs_total
                    : 0
                )}
                percentage={{
                  color: getColorByNumber(fullDashbardData?.unoccupied_chairs_diff || 0),
                  amount: abbreviateNumber(fullDashbardData?.unoccupied_chairs_diff || 0),
                  label: "Just Updated",
                }}
              />
            </MDBox>
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            <MDBox mb={1.5}>
              <ComplexStatisticsCard
                color="success"
                icon="person_add"
                title="Move Ins"
                count={abbreviateNumber(
                  fullDashbardData?.move_ins_total ? fullDashbardData?.move_ins_total : 0
                )}
                percentage={{
                  color: getColorByNumber(fullDashbardData?.move_ins_diff || 0),
                  amount: abbreviateNumber(fullDashbardData?.move_ins_diff || 0),
                  label: "Last Month",
                }}
              />
            </MDBox>
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            <MDBox mb={1.5}>
              <ComplexStatisticsCard
                color="primary"
                icon="person_remove"
                title="Move Outs"
                count={abbreviateNumber(
                  fullDashbardData?.move_outs_total ? fullDashbardData?.move_outs_total : 0
                )}
                percentage={{
                  color: getColorByNumber(fullDashbardData?.move_outs_diff || 0),
                  amount: abbreviateNumber(fullDashbardData?.move_outs_diff || 0),
                  label: "Last Month",
                }}
              />
            </MDBox>
          </Grid>
        </Grid>
      </MDBox>
      <MDBox mt={6}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={6} lg={4}>
            <MDBox mb={3}>
              <ReportsBarChart
                color="info"
                title="Leads"
                description="New Leads Over The Last 6 Months"
                date="updated 3 min ago"
                chart={reportsBarChartData}
              />
            </MDBox>
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <MDBox mb={3}>
              <ReportsLineChart
                color="success"
                title="Occupancy %"
                description="Occupancy Over The last 6 Months"
                date="updated 4 min ago"
                chart={occupancy}
              />
            </MDBox>
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <MDBox mb={3}>
              <ReportsLineChart
                color="dark"
                title="Revenue"
                description="Total Revenue Over The Last 6 Months"
                date="just updated"
                chart={revenue}
              />
            </MDBox>
          </Grid>
        </Grid>
      </MDBox>
    </MDBox>
  );
}

export default Operations;
