import React, { useMemo, useEffect, useState, MouseEvent } from "react";
import { useTable, usePagination, useGlobalFilter, useAsyncDebounce, useSortBy } from "react-table";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import Icon from "@mui/material/Icon";
import Autocomplete from "@mui/material/Autocomplete";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Grid from "@mui/material/Grid";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import moment from "moment-timezone";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDPagination from "components/MDPagination";
import MDButton from "components/MDButton";
import MDDialog from "components/MDDialog";
import { notificationFail } from "store/slices/notificationSlice";
import DataTableHeadCell from "examples/Tables/DataTable/DataTableHeadCell";
import DataTableBodyCell from "examples/Tables/DataTable/DataTableBodyCell";
import { useAppSelector, useAppDispatch } from "store/store";
import { createExportDataRequest } from "store/thunk/authThunk";
import MDDatePicker from "components/MDDatePicker";
import {
  Badge,
  Box,
  Chip,
  CircularProgress,
  FormControl,
  InputAdornment,
  InputLabel,
  Select,
  Tooltip,
} from "@mui/material";
import CustomFilterColumn from "components/CustomFilterColumn";
import FilterListIcon from "@mui/icons-material/FilterList";
import ViewColumnIcon from "@mui/icons-material/ViewColumn";
import MDIconButton from "components/MDIconButton";
import Fade from "@mui/material/Fade";
import RemoveRoundedIcon from "@mui/icons-material/RemoveRounded";
import { saveSelectedCoulms } from "store/thunk/connectUserThunk";
import {
  filteredValue,
  getFilterURL,
  checkedUncheckedValues,
  getJoinedColumnsData,
} from "helper/services";
import { SortableContainer, SortableElement } from "react-sortable-hoc";
import { arrayMoveImmutable } from "array-move";
// import { setDefaultFilterData, setDefaultSortData } from "store/slices/connectUsersSlice";

interface Props {
  entriesPerPage?:
    | false
    | {
        defaultValue: number;
        entries: number[];
      };
  canSearch?: boolean;
  showTotalEntries?: boolean;
  table: {
    columns: { [key: string]: any }[];
    rows: { [key: string]: any }[];
  };
  pagination?: {
    variant: "contained" | "gradient";
    color: "primary" | "secondary" | "info" | "success" | "warning" | "error" | "dark" | "light";
  };
  isSorted?: boolean;
  noEndBorder?: boolean;
  searchText?: string;
  selectedItems?: any;
  showExportAllBtn?: boolean;
  showExportSelectedBtn?: boolean;
  makePaymentBtn?: boolean;
  paymentOpen?: any;
  fetchData?: any;
  pageCount?: any;
  totalCount?: any;
  pageNo?: any;
  setPageNo?: any;
  manualSearch?: boolean;
  manualSort?: boolean;
  exportFields?: any;
  exportTable?: string;
  exportType?: string;
  exportSection?: string;
  additionalField?: any;
  onChangeCheckAllCheckbox?: any;
  isDisplayCheckAllCheckbox?: boolean;
  isCheckAll?: boolean;
  locationsIds?: any;
  defaultDisplayColumns?: any;
  setCheckedColumns?: any;
  checkedColumns?: any;
  tableSetting?: string;
  clearCheckItem?: any;
  advanceFilter?: boolean;
  isAction?: boolean;
  useRubyApiToExport?: boolean;
  status?: string;
  kind?: string;
  getInquriesByCountry?: string;
  joinTables?: any;
  excludeColumns?: any;
  defaultSortingColumns?: any;
  defaultFilteringColums?: any;
  // onSort?: any;
}

function DataTable({
  entriesPerPage,
  canSearch,
  showTotalEntries,
  table,
  pagination,
  isSorted,
  noEndBorder,
  searchText,
  selectedItems,
  showExportSelectedBtn,
  makePaymentBtn,
  showExportAllBtn,
  paymentOpen,
  fetchData,
  pageCount,
  totalCount,
  pageNo,
  setPageNo,
  manualSearch,
  manualSort,
  exportFields,
  exportTable,
  exportType,
  exportSection,
  additionalField,
  onChangeCheckAllCheckbox,
  isDisplayCheckAllCheckbox,
  isCheckAll,
  locationsIds,
  defaultDisplayColumns,
  setCheckedColumns,
  checkedColumns,
  tableSetting,
  clearCheckItem,
  advanceFilter,
  isAction,
  useRubyApiToExport,
  status,
  kind,
  getInquriesByCountry,
  joinTables,
  excludeColumns,
  defaultSortingColumns,
  defaultFilteringColums,
}: // onSort,
Props): JSX.Element {
  let defaultValue: any;
  let entries: any[];

  if (entriesPerPage) {
    defaultValue = entriesPerPage.defaultValue ? entriesPerPage.defaultValue : "50";
    entries = entriesPerPage.entries ? entriesPerPage.entries : ["50", "100", "150", "250"];
  }
  const dispatch = useAppDispatch();
  const { userData, tableLoader, loading } = useAppSelector((state: any) => state.authReducer);
  const { defaultExportData } = useAppSelector((state) => state.connectUsersReducer);
  const [exportPopup, setExportPopup] = useState<boolean>(false);
  const [selectedFields, setSelectedFields] = useState<any>([]);
  const [exportFromDate, setExportFromDate] = useState<any>("");
  const [exportToDate, setExportToDate] = useState<any>("");
  const [isExportSelectedRows, setIsExportSelectedRows] = useState(false);
  const [filterAnchorEl, setFilterAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [filterData, setFilterData] = useState<any>([]);
  const [badgeData, setBadgeData] = useState<any>(0);
  const [exportName, setExportName] = useState("");
  const [isCreatedAt, setIsCreatedAt] = useState<any>(false);
  const [appliedFilter, setAppliedFilter] = useState<any>([]);
  const [defaultApplyFilter, setDefaultApplyFilter] = useState<boolean>(false);
  const [saveAsDefaultBtnDisabled, setSaveAsDefaultBtnDisabled] = useState<boolean>(false);
  const [isManual, setIsManual] = useState<boolean>(false); // New change 2-10
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const dropColumns = useMemo<any>(() => table?.columns, [table]);
  const columns = useMemo<any>(
    () => (defaultDisplayColumns.length ? defaultDisplayColumns : table?.columns),
    [table]
  );

  const data = useMemo<any>(() => table.rows, [table]);
  const [colTable, setColTable] = useState(columns);
  useEffect(() => {
    if (dropColumns && dropColumns.length) {
      setColTable(dropColumns);
    }
  }, [dropColumns]);

  const updateExportData = (defaultExportData: any) => {
    if (defaultExportData && Object.entries(defaultExportData).length) {
      setSelectedFields(defaultExportData?.fields || []);
      setExportFromDate(defaultExportData?.from_date || "");
      setExportToDate(defaultExportData?.to_date || "");
      setExportName(defaultExportData?.export_type || "");
    }
  };
  useEffect(() => {
    updateExportData(defaultExportData);
  }, [defaultExportData]);

  // Set Disable columns
  // const setDisableCoulmn = (columns: any) => {
  //   const disableColumns = colTable.find((obj: any) => obj?.accessor === columns?.accessor);
  //   return disableColumns?.disableCheck ? true : false;
  // };
  const checkTableColumns = (event: any, col: any) => {
    const checkUncheckData = checkedUncheckedValues(checkedColumns, col);
    setCheckedColumns(checkUncheckData);
  };

  const SortableItem = SortableElement((column: any) => {
    const col = column?.value;
    const lableNames = colTable.find((obj: any) => obj?.accessor === col?.accessor);
    return (
      <div style={{ zIndex: "888888" }}>
        <MenuItem key={col?.accessor}>
          <FormControlLabel
            value={col?.accessor}
            control={
              <Checkbox
                inputProps={{ "aria-label": "controlled" }}
                checked={col?.checked || false}
                onChange={(event) => checkTableColumns(event, col)}
                sx={{ padding: "1px" }}
                disabled={col?.disableCheck}
              />
            }
            label={lableNames ? lableNames?.Header : col?.accessor}
            labelPlacement="end"
          />
        </MenuItem>
      </div>
    );
  });

  const SortContainer = SortableContainer(({ children }: any) => <ul>{children}</ul>);

  const tableInstance = useTable(
    {
      columns,
      data,
      initialState: {
        pageIndex: pageNo || 0,
        // SD-3199
        sortBy: defaultSortingColumns && defaultSortingColumns?.length ? defaultSortingColumns : [],
      },
      manualPagination: pageCount || false,
      pageCount,
      autoResetGlobalFilter: false,
      manualGlobalFilter: manualSearch || false,
      manualSortBy: manualSort || false,
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  // SD-3199 If we need
  // useEffect(() => {
  //   if (defaultSortingColumns && defaultSortingColumns?.length && !defaultApplySorting) {
  //     tableInstance.state.sortBy = defaultSortingColumns;
  //     setDefaultApplySorting(true);
  //   }
  // }, [defaultSortingColumns]);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    rows,
    page,
    pageOptions,
    canPreviousPage,
    canNextPage,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    setGlobalFilter,
    state: { pageIndex, pageSize, globalFilter, sortBy },
  }: any = tableInstance;

  // Set the default value for the entries per page when component mounts
  useEffect(() => setPageSize(defaultValue || 50), [defaultValue]);

  useEffect(() => {
    if (fetchData) {
      fetchData({
        pageIndex,
        pageSize,
        search: globalFilter || undefined,
        sortBy, // SD-3199
        additionalField,
        filterData,
        isManual:
          pageNo !== pageIndex || (globalFilter !== "" && globalFilter !== undefined)
            ? false
            : true, // New change 2-10
      });
    }
  }, [fetchData, pageIndex, pageSize, sortBy, filterData, isManual]);

  useEffect(() => {
    setExportName(exportType);
  }, [exportType]);

  // Set the entries per page value based on the select value
  const setEntriesPerPage = (value: any) => setPageSize(value);

  // Render the paginations
  const renderPagination = pageOptions.map((option: any) => (
    <MDPagination
      item
      key={option}
      onClick={() => gotoPage(Number(option))}
      active={pageIndex === option}
    >
      {option + 1}
    </MDPagination>
  ));

  // Handler for the input to set the pagination index
  const handleInputPagination = ({ target: { value } }: any) =>
    value > pageOptions.length || value < 0 ? gotoPage(0) : gotoPage(Number(value));

  // Customized page options starting from 1
  const customizedPageOptions = pageOptions.map((option: any) => option + 1);

  // Setting value for the pagination input
  const handleInputPaginationValue = ({ target: value }: any) => gotoPage(Number(value.value - 1));

  // Search input value state
  const [search, setSearch] = useState(globalFilter);

  const handleFilterData = (filterArr: any) => {
    if (JSON.stringify(appliedFilter) !== JSON.stringify(filterArr)) {
      setGlobalFilter("");
      setIsManual(true);
      setPageNo(0);
      tableInstance.state.pageIndex = 0;
    }
    setAppliedFilter(filterArr);
    setFilterData(filterArr);
  };

  // Search input state handle
  const onSearchChange = useAsyncDebounce((value) => {
    // if (value.length > 0) {
    //   handleFilterData([]);
    // }
    // New change 2-10
    setGlobalFilter(value || undefined);
    if (value) {
      handleFilterData([]);
    }
    if (fetchData) {
      fetchData({
        pageIndex: 0,
        pageSize,
        search: value || undefined,
        sortBy,
        additionalField,
        filterData,
        isManual: value ? false : true,
      });
    }
  }, 100);

  useEffect(() => {
    const filterLength = filteredValue(filterData);

    // const tempArr = filterData.filter((obj: any) =>
    //   Array.isArray(obj.value) ? obj.value.length > 0 : !!obj.value && obj.value !== null
    // );
    if (filterLength?.length > 0) {
      setSearch("");
      // onSearchChange("");    Hide because it unnecessary call
    }

    setBadgeData(filterLength?.length || 0);

    setIsCreatedAt(
      filterData.length <= 0 ||
        (filterData.length > 0 &&
          filterData.findIndex((item: any) => item.columnField === "created_at") === -1)
    );
  }, [filterData]);

  // A function that sets the sorted value for the table

  const setSortedValue = (column: any) => {
    let sortedValue;
    if (isSorted && column?.isSorted) {
      sortedValue = column?.isSortedDesc ? "desc" : "asc";
    } else if (isSorted) {
      sortedValue = "none";
    } else {
      sortedValue = false;
    }

    return sortedValue;
  };
  const setDrop = ({ oldIndex, newIndex }: any) => {
    const temp = checkedColumns
      .filter((column: any) => {
        if (column?.Header && column?.accessor !== "action") {
          return column;
        }
        return null;
      })
      .filter((obj: any) => !!obj);

    if (temp[newIndex] && !temp[newIndex]?.disableCheck && !temp[oldIndex]?.disableCheck) {
      const colOrder = arrayMoveImmutable(temp, oldIndex, newIndex);
      if (checkedColumns.find((obj: any) => obj?.accessor === "checkbox")) {
        colOrder.unshift(checkedColumns.find((obj: any) => obj?.accessor === "checkbox"));
      }
      if (checkedColumns.find((obj: any) => obj?.accessor === "action")) {
        colOrder.push(checkedColumns.find((obj: any) => obj?.accessor === "action"));
      }
      setColTable(colOrder);
      setCheckedColumns(colOrder);
    }
  };

  // Setting the entries starting point
  // const entriesStart = pageIndex === 0 ? pageIndex + 1 : pageIndex * pageSize + 1;
  const entriesStart = pageIndex === 0 ? pageIndex + 1 : pageIndex * pageSize + 1;

  // Setting the entries ending point
  let entriesEnd;

  if (pageIndex === 0) {
    entriesEnd = pageSize;
  } else if (pageIndex === pageOptions.length - 1) {
    entriesEnd = totalCount || rows.length;
  } else {
    entriesEnd = pageSize * (pageIndex + 1);
  }

  const onCloseExportPopup = () => {
    setExportPopup(false);
    setSelectedFields([]);
    setExportFromDate("");
    setExportToDate("");
    setIsExportSelectedRows(false);
  };

  const openExportPopup = (isExportAll: boolean) => {
    updateExportData(defaultExportData);
    setSaveAsDefaultBtnDisabled(false);
    setExportPopup(true);
    setIsExportSelectedRows(isExportAll);
  };

  const onExport = async () => {
    const UserId = userData?.user_id;
    if (!exportFromDate && exportToDate) {
      dispatch(notificationFail("Please select from date"));
      return;
    }
    if (exportFromDate && !exportToDate) {
      dispatch(notificationFail("Please select to date"));
      return;
    }
    if (!exportName) {
      dispatch(notificationFail("Please add the name of the export"));
      return;
    }
    if (!selectedFields.length || !UserId || !exportTable || !exportName) {
      dispatch(notificationFail("Export parameters Missing"));
    } else {
      let tempFilteredData = JSON.parse(JSON.stringify(filterData));
      let filterBy = filteredValue(filterData).length ? filteredValue(filterData) : [];
      if (exportFromDate && exportToDate) {
        filterBy = filterBy.filter((f: any) => f.columnName !== "created_at");
        filterBy.push({
          column: "created_at",
          value: {
            from_date: exportFromDate ? moment(exportFromDate).format("YYYY-MM-DD") : null,
            to_date: exportToDate ? moment(exportToDate).format("YYYY-MM-DD") : null,
          },
          operator_value: "between_and",
        });
        tempFilteredData = tempFilteredData.filter((f: any) => f.columnField !== "created_at");
        tempFilteredData.push({
          columnField: "created_at",
          columnName: "Created At",
          value: [exportFromDate, exportToDate],
          operatorValue: "between_and",
        });
      }

      const tempFieds = JSON.parse(JSON.stringify(selectedFields));
      let fieldsToExport = [...tempFieds].join();
      if (excludeColumns && excludeColumns?.length) {
        fieldsToExport = await JSON.parse(JSON.stringify(tempFieds))
          ?.filter((item: any) => !excludeColumns.includes(item))
          .join();
      }

      const exportDataReq = {
        export_type: exportName,
        table_name: exportTable,
        export_section: exportSection,
        fields_to_export: fieldsToExport,
        user_id: parseInt(UserId, 10),
        export_from_date: exportFromDate ? moment(exportFromDate).format("YYYY-MM-DD") : null,
        export_to_date: exportToDate ? moment(exportToDate).format("YYYY-MM-DD") : null,
        additional_data: {
          search: globalFilter ? globalFilter : "",
          filter_by: filterBy,
        },
        locations_ids:
          exportTable === "sola_classes"
            ? locationsIds &&
              locationsIds?.get_location_by_name &&
              locationsIds?.get_location_by_name.length > 0
              ? locationsIds?.get_location_by_name.map((string: any) => `'${string}'`).join()
              : null
            : locationsIds &&
              locationsIds?.get_location_by_ids &&
              locationsIds?.get_location_by_ids.length > 0
            ? locationsIds?.get_location_by_ids.join()
            : null,
        ruby_api_data: "",
      };

      if (isExportSelectedRows) {
        Object.assign(exportDataReq, {
          selected_records: selectedItems && selectedItems.length > 0 ? selectedItems.join() : null,
        });
      }

      if (useRubyApiToExport) {
        exportDataReq.ruby_api_data = `${exportTable}?per_page=${
          isExportSelectedRows && selectedItems && selectedItems.length
            ? selectedItems.length
            : totalCount
        }`;

        if (headerGroups && headerGroups?.length) {
          headerGroups?.forEach((headerGroup: any) => {
            if (headerGroup?.headers?.length) {
              headerGroup?.headers?.forEach((column: any) => {
                const orderValue = setSortedValue(column);
                if (orderValue === "asc" || orderValue === "desc") {
                  exportDataReq.ruby_api_data += `&order_by=${orderValue}&field=${
                    column?.sorting_column || column?.associative_colums || column?.id
                  }`;
                }
              });
            }
          });
        }

        if (globalFilter) {
          exportDataReq.ruby_api_data = `${exportDataReq.ruby_api_data}&search=${globalFilter}`;
        }
        if (status) {
          exportDataReq.ruby_api_data = `${exportDataReq.ruby_api_data}&status=${status}`;
        }
        if (kind || kind === "0") {
          exportDataReq.ruby_api_data = `${exportDataReq.ruby_api_data}&kind=${kind}`;
        }
        if (getInquriesByCountry) {
          exportDataReq.ruby_api_data = `${exportDataReq.ruby_api_data}&get_inquries_by_country=${getInquriesByCountry}`;
        }
        if (exportFromDate && exportToDate) {
          exportDataReq.ruby_api_data = `${exportDataReq.ruby_api_data}&check_count=true`;
        }
        if (tempFilteredData && tempFilteredData.length) {
          exportDataReq.ruby_api_data = getFilterURL(exportDataReq.ruby_api_data, tempFilteredData);
        }
      }

      if (
        ((exportTable === "request_tour_inquiries" || exportTable === "connect_lead_tracking") &&
          !useRubyApiToExport &&
          joinTables &&
          selectedFields.includes("location_name")) ||
        (filterBy.length && filterBy.some((val: any) => val?.column === "location"))
      ) {
        const tempField = JSON.parse(JSON.stringify(selectedFields));
        if (tempField.indexOf("location_name") !== -1) {
          tempField.splice(tempField.indexOf("location_name"), 1);
        }
        Object.assign(exportDataReq, {
          fields_to_export: tempField.join(),
        });
        Object.assign(exportDataReq, {
          join_tables: joinTables,
        });
      }
      if (exportTable === "request_tour_inquiries" && !useRubyApiToExport) {
        Object.assign(exportDataReq, {
          join_tables: joinTables,
        });
      }

      const isJoinTableIncluded: any = selectedFields.some((element: any) =>
        excludeColumns.includes(element)
      );
      if (!useRubyApiToExport && isJoinTableIncluded) {
        if (exportTable === "connect_locations") {
          const mappedJoinedTablesData = await getJoinedColumnsData(joinTables, selectedFields);
          Object.assign(exportDataReq, {
            join_tables: mappedJoinedTablesData,
          });
        } else {
          Object.assign(exportDataReq, {
            join_tables: joinTables,
          });
        }
      }

      const req = {
        exportDataReq,
        onCloseExportPopup,
      };
      dispatch(createExportDataRequest(req));
    }
  };

  const saveDefaultExport = () => {
    const sendData = {
      user_id: userData?.user_id,
      table_name: tableSetting
        ? tableSetting
        : exportSection
        ? `${exportTable}_${exportSection}`
        : exportTable,
      table_columns: checkedColumns,
      default_export_data: {
        fields: selectedFields || [],
        from_date: exportFromDate || "",
        to_date: exportToDate || "",
        export_type: exportName,
      },
      setSaveAsDefaultBtnDisabled,
    };
    dispatch(saveSelectedCoulms(sendData));
  };

  const exportDialogProps = {
    open: exportPopup,
    onClose: onCloseExportPopup,
    onSave: onExport,
    title: "Select fields to export",
    size: "xl",
    saveTbtText: "Export",
    saveBtn: selectedFields.length > 0 ? true : false,
    isDisabled: selectedFields.length === 0 ? true : false,
    disabledBtn: selectedFields.length === 0 ? true : false,
    saveAsDefaultBtn: true,
    saveAsDefaultBtnText: saveAsDefaultBtnDisabled ? "Saved" : "Save As Defaults",
    saveAsDefaultBtnDisabled,
    onSaveAsDefaultBtn: saveDefaultExport,
  };

  const onChangeSelectAll = (event: any) => {
    let checkedFields: any = JSON.parse(JSON.stringify(selectedFields));
    const commonlyUsed = event.target.value === "commonly_used" ? true : false;

    if (exportFields && exportFields.length > 0) {
      exportFields
        .filter((field: any) => field.common === commonlyUsed)
        .map((obj: any) => {
          if (event.target.checked) {
            if (!checkedFields.includes(obj.key)) {
              checkedFields.push(obj.key);
            }
          } else {
            checkedFields = checkedFields.filter((field: string) => field !== obj.key);
          }
          return true;
        });
    }
    setSelectedFields(checkedFields);
    setSaveAsDefaultBtnDisabled(false);
  };

  const onChangeField = (event: any) => {
    if (event.target.checked) {
      const tempSelected = JSON.parse(JSON.stringify(selectedFields));
      tempSelected.push(event.target.value);
      setSelectedFields(tempSelected);
    } else {
      setSelectedFields(selectedFields.filter((val: any) => val !== event.target.value));
    }
    setSaveAsDefaultBtnDisabled(false);
  };

  const handleClickFilter = (event: MouseEvent<HTMLButtonElement>) => {
    setFilterAnchorEl(event.currentTarget);
  };

  const handleFilterClose = () => {
    setFilterAnchorEl(null);
  };

  const showFilter = () => {
    // commented this temporary for QA push
    if (advanceFilter) {
      return (
        <>
          <MDBox className="table-box-subcontainer mobile-exports">
            <Badge
              badgeContent={badgeData}
              color="info"
              anchorOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
            >
              <MDButton
                variant="text"
                color="info"
                className="xs-small filter-button"
                size="small"
                startIcon={<FilterListIcon />}
                onClick={handleClickFilter}
              />
            </Badge>
          </MDBox>
          <MDBox className="table-box-subcontainer desktop-exports">
            <Badge
              badgeContent={badgeData}
              color="info"
              anchorOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
            >
              <MDButton
                variant="text"
                color="info"
                className="xs-small"
                size="small"
                startIcon={<FilterListIcon />}
                onClick={handleClickFilter}
              >
                Filter
              </MDButton>
            </Badge>
          </MDBox>
        </>
      );
    }
    return null;
  };

  useEffect(() => {
    if (filterData.length && appliedFilter.length && appliedFilter.length === filterData.length) {
      if (pageNo !== pageIndex) {
        setPageNo(pageIndex);
      }
    }
  }, [pageIndex, pageNo]);

  // Save data while check on box
  const onApply = () => {
    const sendData = {
      user_id: userData?.user_id,
      table_name: tableSetting
        ? tableSetting
        : exportSection
        ? `${exportTable}_${exportSection}`
        : exportTable,
      // table_columns: tempCheckedColumns,
      table_columns: checkedColumns,
      default_export_data: defaultExportData,
      table_sort: sortBy, // SD-3199
      table_filter: filterData, // SD-3199
    };
    dispatch(saveSelectedCoulms(sendData));
    handleClose();
  };

  // SD-3199
  useEffect(() => {
    if (defaultFilteringColums && defaultFilteringColums?.length && !defaultApplyFilter) {
      handleFilterData(defaultFilteringColums);
      setDefaultApplyFilter(true);
    }
  }, [defaultFilteringColums]);

  return (
    <>
      {advanceFilter && (
        <CustomFilterColumn
          anchorEl={filterAnchorEl}
          search={search}
          setPageNo={setPageNo}
          filterData={filterData}
          handleClose={handleFilterClose}
          handlefilterData={handleFilterData}
          defaultFilteringColums={defaultFilteringColums}
          sortBy={sortBy}
          setIsManual={setIsManual}
          columns={table.columns
            .filter((col: any) => {
              if (isAction) {
                if (col.Header !== "" && col.accessor !== "action") {
                  return col;
                }
              } else if (col.Header !== "") {
                return col;
              }
              return null;
            })
            .filter((obj: any) => !!obj)}
        />
      )}
      {exportPopup && (
        <MDDialog {...exportDialogProps}>
          <Grid container spacing={1} mb={1}>
            <Grid item xs={12} sm={6} md={3} lg={3}>
              <FormControl>
                <MDTypography variant="button">Export Name:</MDTypography>
                <MDInput
                  value={exportName}
                  onChange={(event: any) => {
                    setExportName(event?.target?.value);
                    setSaveAsDefaultBtnDisabled(false);
                  }}
                  required
                  name="export_type"
                />
              </FormControl>
            </Grid>
          </Grid>
          <Grid container spacing={1} mb={1}>
            {exportFields &&
              exportFields.length > 0 &&
              exportFields.filter((obj: any) => obj.common).length > 0 && (
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <MDBox>
                    <Accordion expanded>
                      <AccordionSummary className="export-accordion">
                        <MDTypography variant="h6">Commonly Used</MDTypography>
                        <FormControlLabel
                          value="commonly_used"
                          control={
                            <Checkbox
                              name="select_all"
                              inputProps={{ "aria-label": "controlled" }}
                              checked={
                                exportFields
                                  ?.filter((obj: any) => obj.common)
                                  ?.every((element: any) => selectedFields.includes(element.key))
                                  ? true
                                  : false
                              }
                              onChange={onChangeSelectAll}
                            />
                          }
                          label="Select All"
                          labelPlacement="end"
                        />
                      </AccordionSummary>
                      <AccordionDetails>
                        <Grid container spacing={1}>
                          {exportFields &&
                            exportFields.length > 0 &&
                            exportFields
                              .filter((obj: any) => obj.common)
                              .sort((a: any, b: any) => a.label.localeCompare(b.label))
                              .map((obj: any) => (
                                <Grid item xs={12} sm={6} md={3} lg={3}>
                                  <FormControlLabel
                                    value={obj.key}
                                    control={
                                      <Checkbox
                                        inputProps={{ "aria-label": "controlled" }}
                                        checked={
                                          selectedFields && selectedFields.includes(obj.key)
                                            ? true
                                            : false
                                        }
                                        onChange={onChangeField}
                                      />
                                    }
                                    label={obj.label}
                                    labelPlacement="end"
                                    sx={{ textTransform: "capitalize" }}
                                  />
                                </Grid>
                              ))}
                        </Grid>
                      </AccordionDetails>
                    </Accordion>
                  </MDBox>
                </Grid>
              )}
            {exportFields &&
              exportFields.length > 0 &&
              exportFields.filter((obj: any) => !obj.common).length > 0 && (
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <MDBox>
                    <Accordion expanded>
                      <AccordionSummary className="export-accordion">
                        <MDTypography variant="h6">All Fields</MDTypography>
                        <FormControlLabel
                          value="select_all"
                          control={
                            <Checkbox
                              name="select_all"
                              inputProps={{ "aria-label": "controlled" }}
                              checked={
                                exportFields
                                  ?.filter((obj: any) => !obj.common)
                                  ?.every((element: any) => selectedFields.includes(element.key))
                                  ? true
                                  : false
                              }
                              onChange={onChangeSelectAll}
                            />
                          }
                          label="Select All"
                          labelPlacement="end"
                        />
                      </AccordionSummary>
                      <AccordionDetails>
                        <Grid container spacing={1}>
                          {exportFields &&
                            exportFields.length > 0 &&
                            exportFields
                              .filter((obj: any) => !obj.common)
                              .sort((a: any, b: any) => a.label.localeCompare(b.label))
                              .map((obj: any) => (
                                <Grid item xs={12} sm={6} md={3} lg={3}>
                                  <FormControlLabel
                                    value={obj.key}
                                    control={
                                      <Checkbox
                                        inputProps={{ "aria-label": "controlled" }}
                                        checked={
                                          selectedFields && selectedFields.includes(obj.key)
                                            ? true
                                            : false
                                        }
                                        onChange={onChangeField}
                                      />
                                    }
                                    label={obj.label}
                                    labelPlacement="end"
                                    sx={{ textTransform: "capitalize" }}
                                  />
                                </Grid>
                              ))}
                        </Grid>
                      </AccordionDetails>
                    </Accordion>
                  </MDBox>
                </Grid>
              )}
          </Grid>
          {isCreatedAt && (
            <Grid container spacing={1} mb={1}>
              <Grid item xs={12} sm={6} md={3} lg={3}>
                <FormControl>
                  <MDTypography variant="button">From Date:</MDTypography>
                  <MDDatePicker
                    options={{
                      dateFormat: "Y-m-d",
                      maxDate: new Date(),
                    }}
                    onChange={([date]: any) => {
                      setExportFromDate(date);
                      setSaveAsDefaultBtnDisabled(false);
                    }}
                    value={exportFromDate || null}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6} md={3} lg={3}>
                <FormControl>
                  <MDTypography variant="button">To Date:</MDTypography>
                  <MDDatePicker
                    options={{
                      dateFormat: "Y-m-d",
                      maxDate: new Date(),
                    }}
                    onChange={([date]: any) => {
                      setExportToDate(date);
                      setSaveAsDefaultBtnDisabled(false);
                    }}
                    value={exportToDate || null}
                  />
                </FormControl>
              </Grid>
            </Grid>
          )}
        </MDDialog>
      )}
      <TableContainer sx={{ boxShadow: "none", pl: 2, pr: 2 }} className="listing-common-table">
        {entriesPerPage || canSearch ? (
          <MDBox
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            p={3}
            position="sticky"
            left={0}
            className="parent-common-table-box"
          >
            {entriesPerPage && (
              <MDBox
                flex="0 0 12rem"
                display="flex"
                alignItems="center"
                className="child-common-table-box-left"
              >
                <Autocomplete
                  disableClearable
                  value={pageSize.toString()}
                  options={entries}
                  onChange={(event, newValue) => {
                    setEntriesPerPage(parseInt(newValue, 10));
                  }}
                  size="small"
                  sx={{ width: "5rem" }}
                  renderInput={(params) => <MDInput {...params} />}
                />
                <MDTypography variant="caption" color="secondary">
                  &nbsp;&nbsp;entries per page
                </MDTypography>
              </MDBox>
            )}
            <MDBox
              ml="auto"
              flex="0 0 300px"
              display="flex"
              justifyContent="flex-end"
              className="child-common-table-box-right"
            >
              {/* <MDBox display={{ xs: "block", sm: "block", md: "flex" }}> */}
              {showFilter()}

              <MDBox className="mobile-exports">
                {/* <MDBox className="table-box-subcontainer" mb={{ xs: 0, sm: 0, md: 0, lg: 1 }}> */}
                {selectedItems.length > 0 && isDisplayCheckAllCheckbox && (
                  <MDButton
                    variant="text"
                    color="info"
                    className="xs-small"
                    size="small"
                    onClick={() => clearCheckItem()}
                  >
                    clear
                  </MDButton>
                )}
                {/* </MDBox> */}
                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label">Export</InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    // value="all"
                    label="Export"
                    // onChange={handleChange}
                    sx={{ height: "40px", minWidth: "80px" }}
                  >
                    {showExportSelectedBtn && selectedItems.length > 0 && (
                      <MenuItem value="export-selected" onClick={() => openExportPopup(true)}>
                        Selected
                      </MenuItem>
                    )}
                    {makePaymentBtn && (
                      <MenuItem onClick={() => paymentOpen(true)} value="make-payment">
                        Make a Payment
                      </MenuItem>
                    )}
                    <MenuItem value="all" onClick={() => openExportPopup(false)}>
                      {globalFilter || filterData?.filter((obj: any) => obj.columnName)?.length
                        ? "Filtered"
                        : "All"}
                    </MenuItem>
                  </Select>
                </FormControl>
              </MDBox>
              <MDBox className="desktop-exports">
                {selectedItems.length > 0 && isDisplayCheckAllCheckbox && (
                  <MDBox className="table-box-subcontainer" mb={{ xs: 0, sm: 0, md: 0, lg: 1 }}>
                    <MDButton
                      variant="text"
                      color="info"
                      className="xs-small"
                      size="small"
                      onClick={() => clearCheckItem()}
                    >
                      clear selected
                    </MDButton>
                  </MDBox>
                )}
                {selectedItems.length > 0 && isDisplayCheckAllCheckbox && (
                  <MDBox display="flex" width="max-content" alignItems="center" mr={2}>
                    <Chip label={`${selectedItems.length} Selected`} color="default" />
                  </MDBox>
                )}
                {showExportSelectedBtn && selectedItems.length > 0 && (
                  <MDBox className="table-box-subcontainer">
                    <MDButton
                      onClick={() => openExportPopup(true)}
                      variant="gradient"
                      color="info"
                      size="small"
                      className="xs-small"
                    >
                      Export Selected
                    </MDButton>
                  </MDBox>
                )}
                {makePaymentBtn && (
                  <MDBox className="table-box-subcontainer">
                    <MDButton
                      variant="gradient"
                      className="xs-small"
                      color="info"
                      size="small"
                      onClick={() => paymentOpen(true)}
                      sx={{ whiteSpace: "nowrap" }}
                    >
                      Make a Payment
                    </MDButton>
                  </MDBox>
                )}
                {showExportAllBtn && rows.length > 0 && (
                  <MDBox className="table-box-subcontainer">
                    <MDButton
                      variant="gradient"
                      color="info"
                      className="xs-small"
                      size="small"
                      onClick={() => openExportPopup(false)}
                    >
                      Export{" "}
                      {globalFilter || filterData?.filter((obj: any) => obj.columnName)?.length
                        ? "Filtered"
                        : "All"}
                    </MDButton>
                  </MDBox>
                )}
              </MDBox>
              {/* </MDBox>
              <MDBox display={{ xs: "block", sm: "block", md: "flex" }}> */}
              {canSearch && (
                <MDBox
                  width="12rem"
                  flex={{ xs: "1", sm: "0 0 10rem" }}
                  mt={{ xs: 0, sm: 0, md: 0, lg: 1 }}
                >
                  <MDInput
                    placeholder={searchText}
                    value={search || ""}
                    className="xs-small"
                    size="small"
                    fullWidth
                    onChange={({ currentTarget }: any) => {
                      setSearch(currentTarget.value);
                      onSearchChange(currentTarget.value);
                    }}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <Icon fontSize="medium">search</Icon>
                        </InputAdornment>
                      ),
                    }}
                  />
                </MDBox>
              )}

              {defaultDisplayColumns && defaultDisplayColumns.length > 0 && (
                <MDIconButton
                  aria-controls={open ? "fade-menu" : undefined}
                  aria-haspopup="true"
                  aria-expanded={open ? "true" : undefined}
                  aria-label="Show Columns"
                  tooltipName="Show Columns"
                  onClick={handleClick}
                >
                  <ViewColumnIcon />
                </MDIconButton>
              )}
              <Menu
                id="fade-menu"
                MenuListProps={{
                  "aria-labelledby": "fade-button",
                }}
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                TransitionComponent={Fade}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "right",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
              >
                {/* {table?.columns?.map(
                  (column: any) =>
                    column?.Header &&
                    column?.accessor !== "action" && (
                      <MenuItem key={column?.accessor}>
                        <FormControlLabel
                          value={column?.accessor}
                          control={
                            <Checkbox
                              inputProps={{ "aria-label": "controlled" }}
                              checked={
                                defaultDisplayColumns &&
                                defaultDisplayColumns.filter(
                                  (obj: any) => obj.accessor === column?.accessor
                                ).length
                              }
                              onChange={(event) => changeTableColumns(event, column)}
                              sx={{ padding: "1px" }}
                              disabled={column?.disableCheck}
                            />
                          }
                          label={column?.Header}
                          labelPlacement="end"
                        />
                      </MenuItem>
                    )
                )} */}
                <SortContainer onSortEnd={setDrop}>
                  {checkedColumns &&
                    checkedColumns
                      .filter(
                        (obj: any) => obj?.accessor !== "checkbox" && obj?.accessor !== "action"
                      )
                      .map((value: any, index: any) => (
                        <SortableItem
                          key={`item-${value?.accessor}`}
                          index={index}
                          value={value}
                          disabled={value?.disableCheck}
                        />
                      ))}
                </SortContainer>
                <MDBox textAlign="center">
                  <Tooltip title="Save column settings" placement="top">
                    <MDButton
                      onClick={onApply}
                      variant="gradient"
                      color="info"
                      size="small"
                      className="xs-small tooltip"
                      sx={{ textAlign: "center" }}
                    >
                      Save
                    </MDButton>
                  </Tooltip>
                </MDBox>
              </Menu>
              {/* </MDBox> */}

              {/* {showFilter && (
                <MDIconButton
                  aria-label="Show Columns"
                  tooltipName="Show Columns"
                  onClick={(event: any) => setOpenShowColumns(event.currentTarget)}
                  aria-controls={Boolean(openShowColumns) ? "fade-menu" : undefined}
                  aria-haspopup="true"
                  aria-expanded={Boolean(openShowColumns) ? "true" : undefined}
                >
                  <ViewColumnIcon />
                </MDIconButton>
              )}

              {columns && columns?.length > 0 && (
                <Menu
                  id="filter-menu"
                  anchorEl={Boolean(openShowColumns)}
                  open={Boolean(openShowColumns)}
                  MenuListProps={{
                    "aria-labelledby": "basic-button",
                  }}
                >
                  {columns?.map((column: any) => (
                    <MenuItem key={column?.value}>
                      <FormControlLabel
                        value={column?.value}
                        control={
                          <Checkbox
                            inputProps={{ "aria-label": "controlled" }}
                            checked={
                              fiterColumns && fiterColumns.includes(column?.value) ? true : false
                            }
                            onChange={onChangeFilter}
                            sx={{ padding: "1px" }}
                          />
                        }
                        label={column?.name}
                        labelPlacement="end"
                      />
                    </MenuItem>
                  ))}
                  <MDButton
                    onClick={onApplyFilter}
                    variant="gradient"
                    color="info"
                    size="small"
                    className="xs-small"
                  >
                    Apply
                  </MDButton>{" "}
                  <MDButton
                    onClick={clearFilter}
                    variant="gradient"
                    color="secondary"
                    size="small"
                    className="xs-small"
                  >
                    Clear
                  </MDButton>
                </Menu>
              )} */}
            </MDBox>{" "}
          </MDBox>
        ) : null}
        {!loading && tableLoader ? (
          <Box sx={{ textAlign: "center" }}>
            <CircularProgress style={{ color: "#1A73E8" }} />
          </Box>
        ) : (
          <Table {...getTableProps()}>
            <MDBox component="thead">
              {headerGroups.map((headerGroup: any) => (
                <TableRow {...headerGroup.getHeaderGroupProps()} className="listing-table-head">
                  {headerGroup.headers.map((column: any, index: any) => (
                    <DataTableHeadCell
                      {...column.getHeaderProps(isSorted && column.getSortByToggleProps())}
                      width={column.width ? column.width : "auto"}
                      align={column.align ? column.align : "left"}
                      sorted={setSortedValue(column)}
                    >
                      {index === 0 && isDisplayCheckAllCheckbox && (
                        <Checkbox
                          name="select_all"
                          inputProps={{ "aria-label": "controlled" }}
                          checked={
                            (isCheckAll ||
                              table?.rows?.every((element: any) =>
                                selectedItems.includes(element?.id?.toString())
                              )) &&
                            selectedItems.length > 0
                              ? true
                              : false
                          }
                          // indeterminate={!isCheckAll && selectedItems.length > 0 ? true : false}
                          indeterminate={
                            isCheckAll ||
                            table?.rows?.every((element: any) =>
                              selectedItems.includes(element?.id?.toString())
                            )
                              ? false
                              : !isCheckAll && selectedItems?.length === 0
                              ? false
                              : true
                          }
                          indeterminateIcon={<RemoveRoundedIcon />}
                          onChange={onChangeCheckAllCheckbox}
                        />
                      )}
                      {column.render("Header")}
                    </DataTableHeadCell>
                  ))}
                </TableRow>
              ))}
            </MDBox>
            <TableBody {...getTableBodyProps()}>
              {page.map((row: any, key: any) => {
                prepareRow(row);
                return (
                  <TableRow {...row.getRowProps()} className="listing-table-cell">
                    {row.cells.map((cell: any) => (
                      <DataTableBodyCell
                        noBorder={noEndBorder && rows.length - 1 === key}
                        align={cell.column.align ? cell.column.align : "left"}
                        width={cell.column.width ? cell.column.width : "150px"}
                        header={cell.column.Header}
                        {...cell.getCellProps()}
                      >
                        {cell.render("Cell")}
                      </DataTableBodyCell>
                    ))}
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        )}

        <MDBox
          display="flex"
          flexDirection={{ xs: "column", sm: "row" }}
          justifyContent="space-between"
          alignItems={{ xs: "flex-start", sm: "center" }}
          p={!showTotalEntries && pageOptions.length === 1 ? 0 : 3}
        >
          {showTotalEntries && (
            <MDBox mb={{ xs: 3, sm: 0 }}>
              <MDTypography variant="button" color="secondary" fontWeight="regular">
                Showing {entriesStart} to {/* Changes in show page realted */}
                {advanceFilter && rows.length < pageSize ? rows.length : entriesEnd} of{" "}
                {totalCount || rows.length} entries
              </MDTypography>
            </MDBox>
          )}
          {pageOptions.length > 1 && (
            <MDPagination
              variant={pagination.variant ? pagination.variant : "gradient"}
              color={pagination.color ? pagination.color : "info"}
            >
              {canPreviousPage && (
                <MDPagination item onClick={() => previousPage()}>
                  <Icon sx={{ fontWeight: "bold" }}>chevron_left</Icon>
                </MDPagination>
              )}
              {renderPagination.length > 6 ? (
                <MDBox width="5rem" mx={1}>
                  <MDInput
                    inputProps={{ type: "number", min: 1, max: customizedPageOptions.length }}
                    value={customizedPageOptions[pageIndex]}
                    onChange={(event: any) => {
                      handleInputPagination(event);
                      handleInputPaginationValue(event);
                    }}
                  />
                </MDBox>
              ) : (
                renderPagination
              )}
              {canNextPage && (
                <MDPagination item onClick={() => nextPage()}>
                  <Icon sx={{ fontWeight: "bold" }}>chevron_right</Icon>
                </MDPagination>
              )}
            </MDPagination>
          )}
        </MDBox>
      </TableContainer>
    </>
  );
}

// Declaring default props for DataTable
DataTable.defaultProps = {
  entriesPerPage: { defaultValue: 50, entries: ["50", "100", "150", "200", "250"] },
  canSearch: false,
  showTotalEntries: true,
  pagination: { variant: "gradient", color: "info" },
  isSorted: true,
  noEndBorder: false,
  searchText: "Search...",
  selectedItems: [],
  showExportAllBtn: false,
  makePaymentBtn: false,
  showExportSelectedBtn: false,
  paymentOpen: () => {},
  pageCount: null,
  pageNo: 0,
  totalCount: null,
  fetchData: null,
  manualSearch: false,
  manualSort: true,
  exportFields: [],
  exportTable: null,
  exportType: null,
  exportSection: null,
  additionalField: null,
  onChangeCheckAllCheckbox: () => {},
  setPageNo: () => {},
  isDisplayCheckAllCheckbox: false,
  isCheckAll: false,
  locationsIds: null,
  defaultDisplayColumns: [],
  checkedColumns: [],
  setCheckedColumns: () => {},
  tableSetting: "",
  clearCheckItem: () => {},
  advanceFilter: false,
  isAction: true,
  useRubyApiToExport: false,
  status: "",
  kind: "",
  getInquriesByCountry: "",
  joinTables: "",
  excludeColumns: [],
  defaultSortingColumns: [],
  defaultFilteringColums: [],
  // defaultFilter: [],
  // onSort: null,
};

export default DataTable;
