import { useState, useEffect } from "react";
import { useAppDispatch, useAppSelector } from "store/store";
import {
  Card,
  Icon,
  Grid,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  Link,
} from "@mui/material";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDDialog from "components/MDDialog";
import MDIconButton from "components/MDIconButton";
import MDTypography from "components/MDTypography";
import DataTable from "examples/Tables/DataTable";
import CustomDropZone from "components/CustomDropZone";
import Messages from "helper/messages";
// import MDEditor from "components/MDEditor";
import MDInput from "components/MDInput";
// import { notificationFail } from "store/slices/notificationSlice";
import {
  getESignTemplateList,
  createESignTemplate,
  editESignTemplate,
  getEditUrlESignTemplate,
  deleteESignTemplate,
} from "store/thunk/locationThunk";
// import constants from "helper/constants";
import Validations from "helper/validations";
// import CustomErrorMessage from "components/CustomErrorMessage";
// import { toBase64 } from "helper/services";
// import MDAvatar from "components/MDAvatar";

function ESignTemplates(props: any): JSX.Element {
  const dispatch = useAppDispatch();
  const { rmDetails } = props;

  const { getAccessTypePermission } = props;
  const { esignTemplateList } = useAppSelector((state) => state.locationSlice);
  const [esignTemplatesData, setEsignTemplatesData] = useState([]);
  const [addTemplatePopup, setAddTemplatePopup] = useState(false);
  const [editPopup, setEditPopup] = useState(false);
  const [templateDetails, setTemplateDetails] = useState<any>({});
  const [templateEditUrl, setTemplateEditUrl] = useState<any>(null);
  const [errors, setErrors] = useState<any>({});
  const [deleteTemplate, setDeleteTemplate] = useState<any>(null);
  const [deleteTemplateId, setDeleteTemplateId] = useState<any>(null);
  const [attachments, setAttachments] = useState<any>([]);

  useEffect(() => {
    dispatch(getESignTemplateList({ locationId: rmDetails?.cms_location_id }));
  }, []);

  useEffect(() => {
    if (esignTemplateList) {
      setEsignTemplatesData(esignTemplateList);
    }
  }, [esignTemplateList]);

  const onClose = () => {
    setAddTemplatePopup(false);
    setTemplateDetails({});
    setAttachments([]);
  };

  const onAddEditTemplate = () => {
    // add validation
    const error = Validations.validateTemplate(templateDetails);
    setErrors(error);

    if (!Object.keys(error).length) {
      if (editPopup) {
        dispatch(
          editESignTemplate({
            template_name: templateDetails.template_name,
            location_id: rmDetails?.cms_location_id,
            html_content: templateDetails?.content || "",
            is_default: templateDetails?.is_default || false,
            is_sublet: templateDetails?.is_sublet || false,
            document_id: templateDetails?.document_id,
            onClose,
          })
        );
      } else {
        dispatch(
          createESignTemplate({
            template_name: templateDetails.template_name,
            location_id: rmDetails?.cms_location_id,
            html_content: templateDetails?.content || "",
            is_default: templateDetails?.is_default || false,
            is_sublet: templateDetails?.is_sublet || false,
            attachments,
            onClose,
          })
        );
      }
    }
  };

  const onChangeField = (e: any) => {
    setTemplateDetails({ ...templateDetails, [e.target.name]: e.target.value });
  };

  // const onChangeEditor = (value: any, name: string) => {
  //   setTemplateDetails({ ...templateDetails, [name]: value });
  // };

  const onChangeCheckbox = (event: any) => {
    setTemplateDetails({ ...templateDetails, [event.target.name]: event.target.checked });
  };

  // const onDelete = () => {
  //   if (deleteOwnerId?.owner_type === "LOCATION") {
  //     dispatch(
  //       deleteLocationOwner({
  //         ownerId: deleteOwnerId?.connect_user?.user_id,
  //         locationId: locationDetail?.id,
  //       })
  //     );
  //   } else {
  //     dispatch(
  //       deleteAssociationOwner({
  //         ownerId: deleteOwnerId?.connect_user?.user_id,
  //         associationId: deleteOwnerId?.related_id,
  //         locationId: locationDetail?.cms_location_id,
  //       })
  //     );
  //   }
  //   setDeleteOwnerId(false);
  // };

  // const isOwner = (userId: any) => (getAuthUser()?.user_id === userId ? true : false);

  const openDeleteModal = (data: any) => {
    console.log("data ", data);
    setDeleteTemplateId(data.docusign_template_id);
    setDeleteTemplate(true);
  };

  const onCloseDeleteModal = () => {
    setDeleteTemplate(false);
    setDeleteTemplateId(null);
  };

  const onDelete = () => {
    if (deleteTemplateId) {
      const DelReq = {
        templateId: deleteTemplateId,
        locationId: rmDetails?.cms_location_id,
        onCloseDeleteModal,
      };
      dispatch(deleteESignTemplate(DelReq));
    }
  };

  const deleteDialogProps = {
    open: deleteTemplate,
    onClose: () => onCloseDeleteModal(),
    onSave: () => onDelete(),
    title: "Delete Template",
    size: "sm",
    saveBtn: false,
    deleteBtn: true,
    saveTbtText: "Yes",
    closeBtnText: "No",
  };

  const actionButtons = (obj: any) => (
    <>
      <MDIconButton
        tooltipName="Edit"
        aria-label="Edit"
        color="info"
        onClick={() => {
          console.log(obj);
          setEditPopup(true);
          dispatch(
            getEditUrlESignTemplate({
              locationId: obj?.location_id,
              templateId: obj?.docusign_template_id,
              setTemplateEditUrl,
            })
          );
          setTemplateDetails({
            ...templateDetails,
            template_name: obj.template_name,
            content: obj.html_content,
            is_default: obj.is_default,
            is_sublet: obj.is_sublet,
            document_id: obj.docusign_template_id,
          });
          setAddTemplatePopup(true);
        }}
        // onClick={() => setDeleteOwnerId(obj)}
      >
        <Icon fontSize="small">edit</Icon>
      </MDIconButton>
      <MDIconButton
        tooltipName="Delete"
        aria-label="Delete"
        color="error"
        onClick={() => openDeleteModal(obj)}
      >
        <Icon fontSize="small">delete</Icon>
      </MDIconButton>
    </>
  );

  const getRows = () => {
    const data: any = esignTemplatesData?.map((obj: any) => ({
      id: obj?.template_id,
      template_name: obj?.template_name || "-",
      docusign_template_id: obj?.docusign_template_id || "-",
      default: obj?.is_default ? "True" : "False",
      sublet: obj?.is_sublet ? "True" : "False",
      action: actionButtons(obj),
    }));
    return data;
  };

  const tableData = {
    columns: [
      { Header: "Id", accessor: "id", export: true },
      { Header: "Template name", accessor: "template_name", export: true },
      { Header: "Docusign Id", accessor: "docusign_template_id", export: true },
      { Header: "Default", accessor: "default", export: true },
      { Header: "Sublet", accessor: "sublet", export: true },
      { Header: "Action", accessor: "action", export: false, disableSortBy: true },
    ],
    rows: getRows(),
  };

  const ownerDialog = {
    open: addTemplatePopup,
    onClose,
    onSave: onAddEditTemplate,
    title: editPopup ? "Update Template" : "Add Template",
    size: "sm",
    saveTbtText: editPopup ? "Update" : "Save",
    overflowVisible: "overflowVisible",
  };

  // const deleteDialogProps = {
  //   open: deleteOwnerId,
  //   onClose: () => setDeleteOwnerId(false),
  //   onSave: () => onDelete(),
  //   title: "Delete Owner",
  //   size: "sm",
  //   saveBtn: false,
  //   deleteBtn: true,
  //   saveTbtText: "Yes",
  //   closeBtnText: "No",
  // };

  const onAttachFile = async (files: any) => {
    const filesArray: any = [];
    console.log("files ", files);
    if (files.length > 0) {
      for (let i = 0; i < files.length; i++) {
        filesArray.push(files[i]);
        // toBase64(files[i]).then((base64String: any) => {
        //   console.log("base64String ", base64String);
        //   if (base64String) {
        //     filesArray.push(base64String);
        //   }
        // });
      }
      console.log("filesArray ", filesArray);
      setAttachments(filesArray);
    }
  };

  return (
    <Card id="esign-templates">
      <MDBox p={3} display="flex" justifyContent="space-between" padding="24px 24px 0 24px">
        <MDTypography variant="h5" className="page-header-label">
          ESign Templates
        </MDTypography>
        {!getAccessTypePermission("location_general", "add_access") && (
          <MDButton
            className="xs-small"
            variant="gradient"
            color="light"
            size="small"
            onClick={() => setAddTemplatePopup(true)}
            disabled={getAccessTypePermission("location_general", "add_access")}
          >
            <Icon sx={{ fontWeight: "bold" }}>add</Icon> Add New
          </MDButton>
        )}
      </MDBox>
      <DataTable table={tableData} canSearch searchText="Search..." />

      {addTemplatePopup && (
        <MDDialog {...ownerDialog}>
          <Grid container spacing={2} mt={1}>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <MDInput
                label="Template Name"
                variant="standard"
                fullWidth
                name="template_name"
                placeholder="Template Name"
                onChange={onChangeField}
                value={templateDetails?.template_name || ""}
                disabled={
                  editPopup || getAccessTypePermission("location_rentmanager", "edit_access")
                }
                error={(errors && errors.template_name) || false}
                helperText={errors && errors.template_name}
                required
              />
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              {editPopup ? (
                <Link href={templateEditUrl || ""} target="_self">
                  <MDButton color="info" size="small">
                    Edit Template
                  </MDButton>
                </Link>
              ) : (
                <CustomDropZone
                  minWidth="170px"
                  minHeight="150px"
                  multipleUpload
                  acceptedFiles={{
                    "application/*": [".pdf", ".doc", ".docx"],
                  }}
                  onDrop={onAttachFile}
                >
                  Drop files here, or Browse
                </CustomDropZone>
              )}
              {attachments &&
                attachments.map((item: any) => <Grid mt={2}>{item && `${item.name}`}</Grid>)}

              {/* <MDEditor
                value={templateDetails?.content || ""}
                onChange={(e: any) => onChangeEditor(e, "content")}
                modules={constants.editorModules}
                formats={constants.editorFormats}
                // readOnly={
                //   checkDisable("lease_agreement_letter") ||
                //   getAccessTypePermission("location_rentmanager", "edit_access")
                // }
              /> */}
              {/* {errors && errors?.content && <CustomErrorMessage message={errors?.content} />} */}
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <FormControl component="fieldset">
                <FormGroup aria-label="position" row>
                  <FormControlLabel
                    value="is_default"
                    control={
                      <Checkbox
                        checked={templateDetails?.is_default || false}
                        name="is_default"
                        onChange={onChangeCheckbox}
                        inputProps={{ "aria-label": "controlled" }}
                        disabled={getAccessTypePermission("location_rentmanager", "edit_access")}
                      />
                    }
                    label="Default"
                    labelPlacement="end"
                  />
                </FormGroup>
                <FormGroup aria-label="position" row>
                  <FormControlLabel
                    value="is_sublet"
                    control={
                      <Checkbox
                        checked={templateDetails?.is_sublet || false}
                        name="is_sublet"
                        onChange={onChangeCheckbox}
                        inputProps={{ "aria-label": "controlled" }}
                        disabled={getAccessTypePermission("location_rentmanager", "edit_access")}
                      />
                    }
                    label="Sublet"
                    labelPlacement="end"
                  />
                </FormGroup>
              </FormControl>
            </Grid>
          </Grid>
        </MDDialog>
      )}

      {deleteTemplate && deleteTemplateId && (
        <MDDialog {...deleteDialogProps}>
          <MDTypography variant="h6" fontWeight="medium">
            {Messages.GENERAL.SURE_TO_DELETE}
          </MDTypography>
        </MDDialog>
      )}
    </Card>
  );
}

export default ESignTemplates;
