import { createAsyncThunk } from "@reduxjs/toolkit";
import { notificationSuccess, notificationFail } from "store/slices/notificationSlice";
import { setLoading } from "store/slices/authSlice";
import Messages from "helper/messages";
import { setAssociationDetails, setAssociationList } from "store/slices/associationSlice";
import slsApiClient from "config/slsApiClient";
import { getAuthUser, getFilterURL } from "helper/services";
import { getLocation } from "./locationThunk";

/* Get Association list */
export const getAssociationList = createAsyncThunk(
  "getAssociationList",
  async (_request: any, { dispatch }) => {
    dispatch(setLoading(true));
    const page = _request?.pageIndex || 0;
    const size = _request?.pageSize || 50;
    const sortBy = _request?.sort || [];
    const search = _request?.search || "";
    const filterBy = _request.filterData || [];
    try {
      let url = `get-associations-list?page=${page}&per_page=${size}`;
      if (search) {
        url += `&search=${search}`;
      }
      if (sortBy?.length) {
        url += `&field=${sortBy[0]?.id}&order_by=${sortBy[0]?.desc ? "desc" : "asc"}`;
      }
      const loginUser = getAuthUser();
      url += `&user_id=${loginUser?.user_id}&role_name=${loginUser?.role}`;

      if (filterBy && filterBy.length) {
        url = getFilterURL(url, filterBy);
      }
      const response = await slsApiClient().get(url);
      if (response?.data?.status) {
        dispatch(setAssociationList(response?.data));
        dispatch(setLoading(false));
      } else {
        dispatch(setLoading(false));
        dispatch(notificationFail(response?.data?.message || Messages.ERROR.NO_DATA_FOUND));
      }
    } catch (error: any) {
      dispatch(setLoading(false));
      dispatch(
        notificationFail(error?.response?.data?.message || Messages.ERROR.SOMETHING_WENT_WRONG)
      );
    }
  }
);

/* Get Association list */
export const getAssociationDetails = createAsyncThunk(
  "getAssociationList",
  async (_request: any, { dispatch }) => {
    dispatch(setAssociationDetails({}));
    dispatch(setLoading(true));
    try {
      const response = await slsApiClient().get(`get-associations-details-by-id?id=${_request.id}`);
      if (response?.data?.status) {
        dispatch(setAssociationDetails(response?.data?.data));
        dispatch(setLoading(false));
      } else {
        dispatch(setLoading(false));
        dispatch(notificationFail(response?.data?.message || Messages.ERROR.NO_DATA_FOUND));
      }
    } catch (error: any) {
      dispatch(setLoading(false));
      dispatch(
        notificationFail(error?.response?.data?.message || Messages.ERROR.SOMETHING_WENT_WRONG)
      );
    }
  }
);

/* Creat Associations */
export const createAssociation = createAsyncThunk(
  "createAssociation",
  async (_request: any, { dispatch }) => {
    const { association, onClose, pageNo, perPage } = _request;
    dispatch(setLoading(true));
    const loginUser = getAuthUser();
    try {
      if (association) {
        const request = {
          association_name: association?.association_name,
          association_description: association?.association_description,
          locations: association?.locations?.map((loc: any) => Number(loc.cms_location_id)),
          owners: association?.owners?.map((owner: any) => Number(owner.user_id)),
          users: association?.users?.map((u: any) => Number(u.user_id)) || [],
          bussiness_name: association?.bussiness_name,
          incorporation_state: association?.incorporation_state,
          notice_address_1: association?.notice_address_1,
          notice_address_2: association?.notice_address_2,
          notice_city: association?.notice_city,
          notice_state: association?.notice_state,
          notice_country: association?.notice_country?.id || null,
          notice_postal_code: association?.notice_postal_code,
          who_added_id: Number(loginUser?.user_id),
          userAccess: association?.userAccess?.length ? association?.userAccess : [],
        };
        const response = await slsApiClient().post(`create-associations`, request);
        dispatch(setLoading(false));
        onClose();
        if (response?.data?.status) {
          dispatch(getAssociationList({ pageIndex: pageNo, pageSize: perPage }));
          dispatch(
            notificationSuccess(
              response?.data?.message || Messages.SUCCESS.ASSOCIATION_CREATE_SUCCESS
            )
          );
        } else {
          dispatch(
            notificationFail(response?.data?.message || Messages.ERROR.ASSOCIATIONL_SAVE_ERROR)
          );
        }
      }
    } catch (error: any) {
      dispatch(setLoading(false));
      dispatch(
        notificationFail(error?.response?.data?.message || Messages.ERROR.SOMETHING_WENT_WRONG)
      );
    }
  }
);

/* update assoication details */
export const updateAssociationDetails = createAsyncThunk(
  "updateAssociationDetails",
  async (_request: any, { dispatch }) => {
    dispatch(setLoading(true));
    try {
      const response = await slsApiClient().post(`update-associations-details`, _request);
      if (response?.data?.status) {
        dispatch(setLoading(false));
        dispatch(notificationSuccess(Messages.SUCCESS.ASSOCIATION_UPDATE_SUCCESS));
      } else {
        dispatch(setLoading(false));
        dispatch(notificationFail(response?.data?.message || Messages.ERROR.NO_DATA_FOUND));
      }
    } catch (error: any) {
      dispatch(setLoading(false));
      dispatch(
        notificationFail(error?.response?.data?.message || Messages.ERROR.SOMETHING_WENT_WRONG)
      );
    }
  }
);

/* add location to assoication */
export const associationAddLocation = createAsyncThunk(
  "associationAddLocation",
  async (_request: any, { dispatch }) => {
    const { locationId, associationId, onClose, defaultAccess } = _request;
    dispatch(setLoading(true));
    try {
      const response = await slsApiClient().post(`association/add-location`, {
        association_id: Number(associationId),
        location_id: Number(locationId),
        defaultAccess: defaultAccess || [],
      });
      if (response?.data?.status) {
        dispatch(getAssociationDetails({ id: Number(associationId) }));
        dispatch(
          notificationSuccess(
            response?.data?.message || Messages.SUCCESS.ASSOCIATION_LOCATION_ADD_SUCCESS
          )
        );
        onClose();
      } else {
        dispatch(setLoading(false));
        dispatch(notificationFail(response?.data?.message || Messages.ERROR.NO_DATA_FOUND));
      }
    } catch (error: any) {
      dispatch(setLoading(false));
      dispatch(
        notificationFail(error?.response?.data?.message || Messages.ERROR.SOMETHING_WENT_WRONG)
      );
    }
  }
);

/* add location to assoication */
export const deleteAssociationLocation = createAsyncThunk(
  "deleteAssociationLocation",
  async (_request: any, { dispatch }) => {
    const { locationId, associationId } = _request;
    dispatch(setLoading(true));
    try {
      const response = await slsApiClient().post(`association/delete-location`, {
        association_id: Number(associationId),
        location_id: Number(locationId),
      });
      if (response?.data?.status) {
        dispatch(getAssociationDetails({ id: Number(associationId) }));
        dispatch(
          notificationSuccess(
            response?.data?.message || Messages.SUCCESS.ASSOCIATION_LOCATION_DELETE_SUCCESS
          )
        );
      } else {
        dispatch(setLoading(false));
        dispatch(notificationFail(response?.data?.message || Messages.ERROR.NO_DATA_FOUND));
      }
    } catch (error: any) {
      dispatch(setLoading(false));
      dispatch(
        notificationFail(error?.response?.data?.message || Messages.ERROR.SOMETHING_WENT_WRONG)
      );
    }
  }
);

/* add Owner to assoication */
export const associationAddOwner = createAsyncThunk(
  "associationAddOwner",
  async (_request: any, { dispatch }) => {
    const { onClose, associationId, ownerId } = _request;
    dispatch(setLoading(true));
    const loginUser = getAuthUser();
    try {
      const response = await slsApiClient().post(`association/add-owner`, {
        association_id: Number(associationId),
        owner_user_id: Number(ownerId),
        who_added_id: Number(loginUser?.user_id),
      });
      if (response?.data?.status) {
        dispatch(getAssociationDetails({ id: Number(associationId) }));
        dispatch(
          notificationSuccess(
            response?.data?.message || Messages.SUCCESS.ASSOCIATION_OWNER_ADD_SUCCESS
          )
        );
        onClose();
      } else {
        dispatch(setLoading(false));
        dispatch(notificationFail(response?.data?.message || Messages.ERROR.NO_DATA_FOUND));
      }
    } catch (error: any) {
      dispatch(setLoading(false));
      dispatch(
        notificationFail(error?.response?.data?.message || Messages.ERROR.SOMETHING_WENT_WRONG)
      );
    }
  }
);

/* delete Owner to assoication */
export const deleteAssociationOwner = createAsyncThunk(
  "deleteAssociationOwner",
  async (_request: any, { dispatch }) => {
    const { associationId, ownerId, locationId } = _request;
    dispatch(setLoading(true));
    try {
      const response = await slsApiClient().post(`association/delete-owner`, {
        association_id: Number(associationId),
        owner_user_id: Number(ownerId),
      });
      if (response?.data?.status) {
        if (locationId) {
          dispatch(getLocation({ id: locationId, cmsLocation: true }));
        } else {
          dispatch(getAssociationDetails({ id: Number(associationId) }));
        }

        dispatch(
          notificationSuccess(
            response?.data?.message || Messages.SUCCESS.ASSOCIATION_OWNER_DELETE_SUCCESS
          )
        );
      } else {
        dispatch(setLoading(false));
        dispatch(notificationFail(response?.data?.message || Messages.ERROR.NO_DATA_FOUND));
      }
    } catch (error: any) {
      dispatch(setLoading(false));
      dispatch(
        notificationFail(error?.response?.data?.message || Messages.ERROR.SOMETHING_WENT_WRONG)
      );
    }
  }
);

/* add User to assoication */
export const associationAddUser = createAsyncThunk(
  "associationAddUser",
  async (_request: any, { dispatch }) => {
    const { onClose, associationId, userId, defaultAccess } = _request;
    dispatch(setLoading(true));
    try {
      const response = await slsApiClient().post(`association/add-user`, {
        association_id: Number(associationId),
        user_id: Number(userId),
        defaultAccess: defaultAccess || [],
      });
      if (response?.data?.status) {
        dispatch(getAssociationDetails({ id: Number(associationId) }));
        dispatch(
          notificationSuccess(
            response?.data?.message || Messages.SUCCESS.ASSOCIATION_USER_ADD_SUCCESS
          )
        );
        onClose();
      } else {
        dispatch(setLoading(false));
        dispatch(notificationFail(response?.data?.message || Messages.ERROR.NO_DATA_FOUND));
      }
    } catch (error: any) {
      dispatch(setLoading(false));
      dispatch(
        notificationFail(error?.response?.data?.message || Messages.ERROR.SOMETHING_WENT_WRONG)
      );
    }
  }
);

/* delete User to assoication */
export const deleteAssociationUser = createAsyncThunk(
  "deleteAssociationUser",
  async (_request: any, { dispatch }) => {
    const { associationId, userId } = _request;
    dispatch(setLoading(true));
    try {
      const response = await slsApiClient().post(`association/delete-user`, {
        association_id: Number(associationId),
        user_id: Number(userId),
      });
      if (response?.data?.status) {
        dispatch(getAssociationDetails({ id: Number(associationId) }));
        dispatch(
          notificationSuccess(
            response?.data?.message || Messages.SUCCESS.ASSOCIATION_USER_DELETE_SUCCESS
          )
        );
      } else {
        dispatch(setLoading(false));
        dispatch(notificationFail(response?.data?.message || Messages.ERROR.NO_DATA_FOUND));
      }
    } catch (error: any) {
      dispatch(setLoading(false));
      dispatch(
        notificationFail(error?.response?.data?.message || Messages.ERROR.SOMETHING_WENT_WRONG)
      );
    }
  }
);

/* delete assoication  */
export const deleteAssociation = createAsyncThunk(
  "deleteAssociation",
  async (_request: any, { dispatch }) => {
    const { deleteData, pageNo, perPage, removeSelectedItem = null } = _request;
    dispatch(setLoading(true));
    try {
      const response = await slsApiClient().post(`delete_association`, deleteData);
      if (response?.data?.status) {
        dispatch(setLoading(false));
        if (removeSelectedItem) {
          removeSelectedItem(deleteData);
        }
        dispatch(getAssociationList({ pageIndex: pageNo, pageSize: perPage }));
        dispatch(notificationSuccess(Messages.SUCCESS.RECORD_DELETE_SUCCESS));
      } else {
        dispatch(setLoading(false));
        dispatch(notificationFail(response?.data?.message || Messages.ERROR.NO_DATA_FOUND));
      }
    } catch (error: any) {
      dispatch(setLoading(false));
      dispatch(
        notificationFail(error?.response?.data?.message || Messages.ERROR.SOMETHING_WENT_WRONG)
      );
    }
  }
);
