import { createAsyncThunk } from "@reduxjs/toolkit";
import { setLoading } from "store/slices/authSlice";
import { notificationFail, notificationSuccess } from "store/slices/notificationSlice";
import {
  menuList,
  roleList,
  roleDetails,
  roleSelected,
  additionalDetails,
} from "store/slices/rolesAndMenusSlice";
import {
  MenuRequest,
  RoleDetailsRequest,
  saveRoleRequest,
  mainMenuRequest,
} from "types/custom-types";
import Messages from "helper/messages";
import slsApiClient from "config/slsApiClient";

export const getMenusList = createAsyncThunk("getMenusList", async (_, { dispatch }) => {
  dispatch(setLoading(true));
  try {
    const response = await slsApiClient().get(`menus`);
    if (response?.data?.status) {
      dispatch(setLoading(false));
      dispatch(menuList(response?.data?.accessMenus));
    } else {
      dispatch(setLoading(false));
      dispatch(notificationFail(response?.data?.message || Messages.ERROR.SOMETHING_WENT_WRONG));
    }
  } catch (error: any) {
    dispatch(setLoading(false));
    dispatch(
      notificationFail(error?.response?.data?.message || Messages.ERROR.SOMETHING_WENT_WRONG)
    );
  }
});

export const getRolesList = createAsyncThunk("getRolesList", async (_, { dispatch }) => {
  dispatch(setLoading(true));
  try {
    const response = await slsApiClient().get(`roles`);
    dispatch(setLoading(false));
    if (response?.data?.status) {
      dispatch(roleList(response?.data?.roles));
    } else {
      dispatch(notificationFail(response?.data?.message || Messages.ERROR.SOMETHING_WENT_WRONG));
    }
  } catch (error: any) {
    dispatch(setLoading(false));
    dispatch(
      notificationFail(error?.response?.data?.message || Messages.ERROR.SOMETHING_WENT_WRONG)
    );
  }
});

export const getRoleDetails = createAsyncThunk(
  "getRolesList",
  async (_request: RoleDetailsRequest, { dispatch }) => {
    dispatch(setLoading(true));
    try {
      let params = "";
      if (_request?.user_id) {
        params = `?user_id=${_request?.user_id}`;
      } else if (_request?.name) {
        params = `?name=${_request.name}`;
      }
      const response = await slsApiClient().get(`roles-details${params}`);
      dispatch(setLoading(false));
      if (response?.data?.status) {
        dispatch(roleDetails(response?.data?.roleDetails));
        dispatch(roleSelected(response?.data?.roleName));
        dispatch(additionalDetails(response?.data?.additioanlDetials));
      } else {
        dispatch(notificationFail(response?.data?.message || Messages.ERROR.SOMETHING_WENT_WRONG));
      }
    } catch (error: any) {
      dispatch(setLoading(false));
      dispatch(
        notificationFail(error?.response?.data?.message || Messages.ERROR.SOMETHING_WENT_WRONG)
      );
    }
  }
);

export const saveRole = createAsyncThunk(
  "saveRole",
  async (_request: saveRoleRequest, { dispatch }) => {
    const { name, oldName, description, menuIds, editRole } = _request;
    dispatch(setLoading(true));
    try {
      const response = await slsApiClient().post(`add-roles`, {
        name,
        description,
        menuIds,
        oldName,
        editRole,
      });
      dispatch(setLoading(false));
      if (response?.data?.status) {
        if (editRole)
          dispatch(notificationSuccess(response?.data?.message || Messages.SUCCESS.ROLE_UPDATE));
        else dispatch(notificationSuccess(response?.data?.message || Messages.SUCCESS.ROLE_SAVE));
        dispatch(getRolesList());
      } else {
        dispatch(notificationFail(response?.data?.message || Messages.ERROR.SOMETHING_WENT_WRONG));
      }
    } catch (error: any) {
      dispatch(setLoading(false));
      dispatch(
        notificationFail(error?.response?.data?.message || Messages.ERROR.SOMETHING_WENT_WRONG)
      );
    }
  }
);

export const saveMenu = createAsyncThunk(
  "saveMenu",
  async (_request: mainMenuRequest, { dispatch }) => {
    const { alias, displayMenu, description, menuId, menuOrder, defaultMenu } = _request;
    dispatch(setLoading(true));
    try {
      const response = await slsApiClient().post(`add-update-mainmenus`, {
        alias,
        description,
        display_menu: displayMenu,
        menu_order: menuOrder,
        menu_id: menuId,
        default_menu: defaultMenu,
      });
      dispatch(setLoading(false));
      if (response?.data?.status) {
        if (menuId)
          dispatch(notificationSuccess(response?.data?.message || Messages.SUCCESS.MENU_UPDATE));
        else dispatch(notificationSuccess(response?.data?.message || Messages.SUCCESS.MENU_ADD));
        dispatch(getMenusList());
      } else {
        dispatch(notificationFail(response?.data?.message || Messages.ERROR.SOMETHING_WENT_WRONG));
      }
    } catch (error: any) {
      dispatch(setLoading(false));
      dispatch(
        notificationFail(error?.response?.data?.message || Messages.ERROR.SOMETHING_WENT_WRONG)
      );
    }
  }
);

// Insert submenu
export const addUpdateSubmenu = createAsyncThunk(
  "addUpdateSubmenu",
  async (_request: MenuRequest, { dispatch }) => {
    dispatch(setLoading(true));

    try {
      const response = await slsApiClient().post(`add-update-menus`, _request);
      dispatch(setLoading(false));
      if (response?.data?.status) {
        dispatch(
          notificationSuccess(
            _request.subMenuId
              ? Messages.SUCCESS.RECORD_UPDATE_SUCCESS
              : Messages.SUCCESS.RECORD_CREATE_SUCCESS
          )
        );
        dispatch(getMenusList());
        if (_request.callback) {
          _request.callback();
        }
      } else {
        dispatch(notificationFail(response?.data?.message || Messages.ERROR.SOMETHING_WENT_WRONG));
      }
    } catch (error: any) {
      dispatch(setLoading(false));
      dispatch(
        notificationFail(error?.response?.data?.message || Messages.ERROR.SOMETHING_WENT_WRONG)
      );
    }
  }
);
