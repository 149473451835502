import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";
import Tooltip from "@mui/material/Tooltip";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";

function LocationDescription(props: any): JSX.Element {
  const { locationFranchiseView, onChangeField, errors } = props;
  return (
    <MDBox>
      <MDBox p={2} mt={2}>
        <MDBox mb={1} textAlign="right">
          <Tooltip
            title="Provide a detailed description of your location. Make sure that a visitor will be able to visualize your location based on your description.
            -If you are located within an upscale shopping center, make sure that they know this.
            -List nearby business and landmarks.
            -Include a short description of what a salon studio is."
            placement="bottom"
            arrow
          >
            <MDButton variant="outlined" color="secondary" size="small" circular iconOnly>
              <Icon>question_mark</Icon>
            </MDButton>
          </Tooltip>
        </MDBox>
        <MDBox>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={12} md={12}>
              <MDInput
                label="Location Description (please be as detailed as possible, you want to paint a good picture for your Sola)"
                fullWidth
                multiline
                rows={15}
                InputLabelProps={{ shrink: true }}
                name="location_description"
                required
                onChange={onChangeField}
                value={locationFranchiseView?.location_description || ""}
                error={errors && errors.location_description ? true : false}
                helperText={errors && errors.location_description}
              />
            </Grid>
          </Grid>
        </MDBox>
      </MDBox>
    </MDBox>
  );
}

export default LocationDescription;
