import Grid from "@mui/material/Grid";
import MenuItem from "@mui/material/MenuItem";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import FormControl from "@mui/material/FormControl";
import Card from "@mui/material/Card";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDEditor from "components/MDEditor";
import CustomAutoSearch from "components/CustomAutoSearch";
import constants from "helper/constants";
import { getPermission, systemAdmin, adminSuperAdmin } from "helper/services";
import MESSAGES from "helper/messages";
import InputAdornment from "@mui/material/InputAdornment";
import InfoIcon from "@mui/icons-material/Info";
import Tooltip from "@mui/material/Tooltip";
// import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

function General(props: any): JSX.Element {
  const { locationDetail, onChangeDescription, onChangeField, onChangeAutoComplete, errors, from } =
    props;

  return (
    <Card id="general">
      <MDBox p={3}>
        <MDTypography variant="h5">General</MDTypography>
      </MDBox>
      <MDBox pb={3} px={3}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6} md={6} lg={6}>
            <MDInput
              label="Location Name"
              variant="standard"
              fullWidth
              name="name"
              placeholder="Location Name"
              onChange={onChangeField}
              value={locationDetail?.name || ""}
              error={errors && errors.name ? true : false}
              helperText={errors && errors.name}
              required
              disabled={!adminSuperAdmin()}
              infoText={MESSAGES.INFO.REQUIRED_LENGTH_255}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={6} lg={6}>
            <MDInput
              label="URL Name"
              variant="standard"
              fullWidth
              name="url_name"
              placeholder="URL Name"
              onChange={onChangeField}
              value={locationDetail?.url_name || ""}
              error={errors && errors.url_name ? true : false}
              helperText={errors && errors.url_name}
              required
              disabled={!systemAdmin()}
              infoText={MESSAGES.INFO.URL_NAME}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <InputLabel sx={{ marginBottom: 1 }}>Description</InputLabel>
            <MDEditor
              value={locationDetail?.description || ""}
              onChange={(val: any, delta: any, source: any) =>
                onChangeDescription(val, delta, source)
              }
              modules={constants.editorModules}
              formats={constants.editorFormats}
            />
          </Grid>
          {adminSuperAdmin() && (
            <>
              <Grid item xs={12} sm={6} md={6} lg={6}>
                <CustomAutoSearch
                  idKey="id"
                  nameKey="name"
                  fieldLabel="MSA"
                  apiName="msas"
                  responseKey="msas"
                  name="msa"
                  value={locationDetail?.msa || null}
                  onChange={onChangeAutoComplete}
                  createNewLink={
                    getPermission("locations", "locations_msa") ? "/locations/msa" : ""
                  }
                  disabled={!adminSuperAdmin()}
                  required
                  error={errors && errors.msa}
                />
              </Grid>
              {!from && (
                <Grid item xs={12} sm={6} md={6} lg={6}>
                  <CustomAutoSearch
                    idKey="id"
                    nameKey="email"
                    fieldLabel="Franchisee"
                    apiName="admins"
                    responseKey="admins"
                    name="franchisee"
                    value={locationDetail?.franchisee || null}
                    onChange={onChangeAutoComplete}
                    createNewLink={
                      getPermission("system_admin", "system_admin_legacy_admin")
                        ? "/system-admin/legacy-admin"
                        : ""
                    }
                    disabled={!adminSuperAdmin()}
                  />
                </Grid>
              )}
            </>
          )}
          <Grid item xs={12} sm={6} md={6} lg={6}>
            <MDInput
              label="Sola ID"
              variant="standard"
              fullWidth
              name="store_id"
              placeholder="Sola ID"
              onChange={onChangeField}
              value={locationDetail?.store_id || ""}
              error={errors && errors.store_id ? true : false}
              helperText={errors && errors.store_id}
              disabled={!adminSuperAdmin()}
              infoText={MESSAGES.INFO.OPTIONAL_LENGTH_255}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={6} lg={6}>
            <FormControl fullWidth>
              <InputLabel>Status</InputLabel>
              <Select
                className="height45"
                label="Status"
                value={locationDetail?.status || ""}
                name="status"
                onChange={onChangeField}
                disabled={!adminSuperAdmin()}
                endAdornment={
                  <InputAdornment position="end">
                    <Tooltip title={MESSAGES.INFO.OPTIONAL} arrow>
                      <InfoIcon
                        style={{
                          width: 20,
                          height: 20,
                          cursor: "pointer",
                          color: "rgba(48, 57, 65, 0.3)",
                          marginRight: "5px",
                        }}
                      />
                    </Tooltip>
                  </InputAdornment>
                }
              >
                <MenuItem value="open">Open</MenuItem>
                <MenuItem value="closed">Closed</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          {adminSuperAdmin() && (
            <>
              <Grid item xs={12} sm={6} md={6} lg={6}>
                <FormControl fullWidth>
                  <InputLabel>Region</InputLabel>
                  <Select
                    className="height45"
                    label="Region"
                    value={locationDetail?.region || ""}
                    name="region"
                    onChange={onChangeField}
                    disabled={!adminSuperAdmin()}
                    endAdornment={
                      <InputAdornment position="end">
                        <Tooltip title={MESSAGES.INFO.OPTIONAL} arrow>
                          <InfoIcon
                            style={{
                              width: 20,
                              height: 20,
                              cursor: "pointer",
                              color: "rgba(48, 57, 65, 0.3)",
                              marginRight: "5px",
                            }}
                          />
                        </Tooltip>
                      </InputAdornment>
                    }
                  >
                    <MenuItem value="east">East</MenuItem>
                    <MenuItem value="central">Central</MenuItem>
                    <MenuItem value="west">West</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6} md={6} lg={6}>
                <MDInput
                  label="FBC"
                  variant="standard"
                  fullWidth
                  name="fbc"
                  placeholder="FBC"
                  onChange={onChangeField}
                  value={locationDetail?.fbc || ""}
                />
              </Grid>
            </>
          )}
        </Grid>
      </MDBox>
    </Card>
  );
}

export default General;
