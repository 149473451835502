import { useState } from "react";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import General from "layouts/pages/LocationFranchiseView/location-wizard/steps/General";
import Address from "layouts/pages/LocationFranchiseView/location-wizard/steps/Address";
import LocationDescription from "layouts/pages/LocationFranchiseView/location-wizard/steps/LocationDescription";
import ExistingLocations from "layouts/pages/LocationFranchiseView/location-wizard/steps/ExistingLocations";
import LeaseManager from "layouts/pages/LocationFranchiseView/location-wizard/steps/LeaseManager";
import Review from "layouts/pages/LocationFranchiseView/location-wizard/steps/Review";
import Validation from "helper/validations";
import PageLayout from "examples/LayoutContainers/PageLayout";
import MDTypography from "components/MDTypography";

const countryList = [
  { name: "country1", id: 1 },
  { name: "country1", id: 2 },
  { name: "country3", id: 3 },
];

const steps = [
  "General",
  "Address",
  "Location Description",
  "Existing Locations",
  "Lease Manager",
  "Review",
];

function Index(props: any): JSX.Element {
  const { onClose } = props;
  const [locationFranchiseView, setLocationFranchiseView] = useState<any>({});
  const [errors, setErrors] = useState<any>({});
  const [activeStep, setActiveStep] = useState<number>(0);
  const [finished, setFinished] = useState<boolean>(false);
  const isLastStep: boolean = activeStep === steps.length - 1;

  const onChangeField = (event: any) => {
    setLocationFranchiseView({ ...locationFranchiseView, [event.target.name]: event.target.value });
  };

  const handleChangeNext = () => {
    if (isLastStep) {
      onClose();
    } else {
      setActiveStep(activeStep + 1);
    }
  };

  const handleNext = () => {
    const errorData = Validation.validateLocationFranchiseViewWizard({
      ...locationFranchiseView,
      step: activeStep,
    });

    setErrors(errorData);
    if (!Object.keys(errorData).length) {
      if (isLastStep) {
        console.log("locationFranchiseView:", locationFranchiseView);
      } else {
        handleChangeNext();
      }
    }
  };
  const handleBack = () => setActiveStep(activeStep - 1);

  const onChangeMultipleField = (event: any, index: any, field: any) => {
    const tempDetails = JSON.parse(JSON.stringify(locationFranchiseView));
    if (!tempDetails?.[field]?.[index]?.[event.target.name]) {
      tempDetails[field][index][event.target.name] = event.target.value;
    }
    tempDetails[field][index][event.target.name] = event.target.value;
    setLocationFranchiseView(tempDetails);
  };

  const setSelectedCountryId = (country: any) => {
    if (country && country.id) {
      setLocationFranchiseView({ ...locationFranchiseView, country_id: country.id });
    }
  };

  const onFinish = () => {
    setFinished(true);
  };

  function getStepContent(stepIndex: number): JSX.Element {
    const attr = {
      locationFranchiseView,
      onChangeField,
      onChangeMultipleField,
      handleNext,
      setSelectedCountryId,
      countryList,
      errors,
    };
    switch (stepIndex) {
      case 0:
        return <General {...attr} />;
      case 1:
        return <Address {...attr} />;
      case 2:
        return <LocationDescription {...attr} />;
      case 3:
        return <ExistingLocations {...attr} />;
      case 4:
        return <LeaseManager {...attr} />;
      case 5:
        return <Review {...attr} />;
      default:
        return null;
    }
  }
  return (
    <PageLayout width="100%">
      <MDBox m={2} mt={5}>
        <Grid>
          <Grid item xs={12} lg={12}>
            <Card>
              {finished ? (
                <MDBox textAlign="center" p={2}>
                  <MDTypography variant="h5" fontWeight="medium" color="info">
                    Thank you!
                  </MDTypography>
                  <MDTypography variant="button" fontWeight="medium" color="text">
                    Your request is submitted, we will contact you soon.
                  </MDTypography>
                </MDBox>
              ) : (
                <>
                  <MDBox mt={-3} mx={2}>
                    <Stepper
                      activeStep={activeStep}
                      alternativeLabel
                      className="acount-setup-stepper"
                    >
                      {steps.map((label) => (
                        <Step key={label}>
                          <StepLabel style={{ fontSize: "10px" }}>{label}</StepLabel>
                        </Step>
                      ))}
                    </Stepper>
                  </MDBox>
                  <MDBox p={2}>
                    <MDBox>
                      {getStepContent(activeStep)}
                      <MDBox mt={3} width="100%" display="flex" justifyContent="space-between">
                        {activeStep === 0 ? (
                          <MDBox />
                        ) : (
                          <MDButton variant="outlined" color="dark" onClick={handleBack}>
                            Back
                          </MDButton>
                        )}
                        <MDButton
                          variant="gradient"
                          color="dark"
                          onClick={!isLastStep ? handleNext : onFinish}
                        >
                          {isLastStep ? "Finish" : "Next"}
                        </MDButton>
                      </MDBox>
                    </MDBox>
                  </MDBox>
                </>
              )}
            </Card>
          </Grid>
        </Grid>
      </MDBox>
    </PageLayout>
  );
}

export default Index;
