import { useState, useEffect } from "react";
import { useAppDispatch } from "store/store";
import { Card, Icon, Grid } from "@mui/material";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDDialog from "components/MDDialog";
import MDIconButton from "components/MDIconButton";
import MDTypography from "components/MDTypography";
import DataTable from "examples/Tables/DataTable";
import CustomAutoSearch from "components/CustomAutoSearch";
import Messages from "helper/messages";
import { capitalizeFirstLetter, getAuthUser, getPermission } from "helper/services";
import { deleteAssociationOwner } from "store/thunk/associationThunk";
import { locationAddOwner, deleteLocationOwner } from "store/thunk/locationThunk";
import { notificationFail } from "store/slices/notificationSlice";

function Owners(props: any): JSX.Element {
  const dispatch = useAppDispatch();

  const { locationDetail, userData, getAccessTypePermission } = props;
  const [owners, setOwnersData] = useState([]);
  const [addOwnerPopup, setAddOwnerPopup] = useState(false);
  const [selectedOwners, setSelectedOwners] = useState(null);
  const [deleteOwnerId, setDeleteOwnerId] = useState<any>(null);

  const onClose = () => {
    setSelectedOwners(null);
    setAddOwnerPopup(false);
  };

  const onAddOwner = () => {
    const alreadyExistUserAsOwners: any = locationDetail?.location_users?.find(
      (obj: any) => Number(obj?.user_id) === Number(selectedOwners?.user_id)
    );

    if (alreadyExistUserAsOwners) {
      dispatch(notificationFail(Messages.ERROR.OWNER_ALREADY_EXISTS_AS_USER));
    } else {
      dispatch(
        locationAddOwner({
          ownerId: selectedOwners?.user_id,
          locationId: locationDetail?.cms_location_id,
          whoAddedId: userData?.user_id,
          onClose,
        })
      );
    }
  };

  const onDelete = () => {
    if (deleteOwnerId?.owner_type === "LOCATION") {
      dispatch(
        deleteLocationOwner({
          ownerId: deleteOwnerId?.connect_user?.user_id,
          locationId: locationDetail?.id,
        })
      );
    } else {
      dispatch(
        deleteAssociationOwner({
          ownerId: deleteOwnerId?.connect_user?.user_id,
          associationId: deleteOwnerId?.related_id,
          locationId: locationDetail?.cms_location_id,
        })
      );
    }
    setDeleteOwnerId(false);
  };

  useEffect(() => {
    if (locationDetail && Array.isArray(locationDetail?.owners) && locationDetail?.owners?.length) {
      setOwnersData(locationDetail?.owners?.filter((obj: any) => obj));
    }
  }, [locationDetail]);

  const isOwner = (userId: any) => (getAuthUser()?.user_id === userId ? true : false);

  const actionButtons = (obj: any) => (
    <MDIconButton
      tooltipName={isOwner(obj?.connect_user?.user_id) ? "You cannot delete himself." : "Delete"}
      aria-label="Delete"
      color="error"
      disabled={
        isOwner(obj?.connect_user?.user_id) ||
        getAccessTypePermission("location_general", "edit_access")
      }
      onClick={() => setDeleteOwnerId(obj)}
    >
      <Icon fontSize="small">delete</Icon>
    </MDIconButton>
  );

  const getRows = () => {
    const data: any = owners?.map((obj: any) => ({
      id: obj?.connect_user?.user_id,
      first_name: obj?.connect_user?.first_name || "-",
      last_name: obj?.connect_user?.last_name || "-",
      email: obj?.connect_user?.login_email || "-",
      type: obj?.owner_type ? capitalizeFirstLetter(obj?.owner_type) : "-",
      action: actionButtons(obj),
    }));
    return data;
  };

  const tableData = {
    columns: [
      { Header: "Id", accessor: "id", export: true },
      { Header: "First name", accessor: "first_name", export: true },
      { Header: "Last name", accessor: "last_name", export: true },
      { Header: "Email", accessor: "email", export: true },
      { Header: "Type", accessor: "type", export: true },
      { Header: "Action", accessor: "action", export: false, disableSortBy: true },
    ],
    rows: getRows(),
  };

  const ownerDialog = {
    open: addOwnerPopup,
    onClose,
    onSave: onAddOwner,
    title: "Add Owner",
    size: "sm",
    saveBtn: selectedOwners ? true : false,
    overflowVisible: "overflowVisible",
  };

  const deleteDialogProps = {
    open: deleteOwnerId,
    onClose: () => setDeleteOwnerId(false),
    onSave: () => onDelete(),
    title: "Delete Owner",
    size: "sm",
    saveBtn: false,
    deleteBtn: true,
    saveTbtText: "Yes",
    closeBtnText: "No",
  };

  return (
    <Card id="owners">
      <MDBox p={3} display="flex" justifyContent="space-between">
        <MDTypography variant="h5" className="page-header-label">
          Owners
        </MDTypography>
        {!getAccessTypePermission("location_general", "add_access") && (
          <MDButton
            className="xs-small"
            variant="gradient"
            color="light"
            size="small"
            onClick={() => setAddOwnerPopup(true)}
            disabled={getAccessTypePermission("location_general", "add_access")}
          >
            <Icon sx={{ fontWeight: "bold" }}>add</Icon> Add New
          </MDButton>
        )}
      </MDBox>
      <DataTable table={tableData} canSearch searchText="Search..." />

      {addOwnerPopup && (
        <MDDialog {...ownerDialog}>
          <Grid container spacing={2} mt={1}>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <CustomAutoSearch
                idKey="user_id"
                nameKey="login_email"
                fieldLabel="Owner"
                apiName="connect_users"
                responseKey="connect_users"
                name="owners"
                slsAPI
                required
                value={selectedOwners || null}
                onChange={(event: any, val: any) => setSelectedOwners(val)}
                createNewLink={
                  getPermission("users", "users_manage") ? "/users-&-roles/all-users" : ""
                }
              />
            </Grid>
          </Grid>
        </MDDialog>
      )}

      {deleteOwnerId && (
        <MDDialog {...deleteDialogProps}>
          <MDTypography variant="h6" fontWeight="medium">
            {Messages.GENERAL.SURE_TO_DELETE}
          </MDTypography>
        </MDDialog>
      )}
    </Card>
  );
}

export default Owners;
