import { useEffect, useState } from "react";
import MDDialog from "components/MDDialog";
import { Grid } from "@mui/material";
import MDTypography from "components/MDTypography";
import { getLocalDateTime, phoneNumberformat } from "helper/services";
import MDBox from "components/MDBox";

function ViewDetails(props: any): JSX.Element {
  const { open, onClose, viewStylistMessage } = props;
  const [loading] = useState<boolean>(false);
  const [stylistMessagesData, setStylistMessagesData] = useState<any>({});

  useEffect(() => {
    if (viewStylistMessage) {
      setStylistMessagesData((prev: any) => ({
        ...prev,
        subject: viewStylistMessage.subject,
        name: viewStylistMessage.name,
        email: viewStylistMessage.email,
        phone: viewStylistMessage.phone,
        message: viewStylistMessage.message,
        stylist: viewStylistMessage.stylist_name,
        created_at: viewStylistMessage.created_at,
        updated_at: viewStylistMessage.updated_at,
      }));
    }
  }, []);

  const dialogProps = {
    open,
    onClose,
    saveBtn: false,
    title: "Stylist Messages Info",
    size: "md",
    loading,
  };

  return (
    <MDDialog {...dialogProps}>
      <Grid container p="0 10px">
        <Grid container>
          <Grid item xs={12} sm={6} md={6}>
            <MDTypography fontWeight="bold" variant="button">
              Name
            </MDTypography>
            <MDBox>
              <MDTypography variant="button">{stylistMessagesData?.name || "-"}</MDTypography>
            </MDBox>
          </Grid>
          <Grid item xs={12} sm={6} md={6}>
            <MDTypography fontWeight="bold" variant="button">
              Email
            </MDTypography>
            <MDBox>
              <MDTypography variant="button">{stylistMessagesData?.email || "-"}</MDTypography>
            </MDBox>
          </Grid>
        </Grid>
        <Grid container>
          <Grid item xs={12} sm={6} md={6}>
            <MDTypography fontWeight="bold" variant="button">
              Phone
            </MDTypography>
            <MDBox>
              <MDTypography variant="button">
                {phoneNumberformat(stylistMessagesData?.phone)}
              </MDTypography>
            </MDBox>
          </Grid>
          <Grid item xs={12} sm={6} md={6}>
            <MDTypography fontWeight="bold" variant="button">
              Message
            </MDTypography>
            <MDBox>
              <MDTypography variant="button">{stylistMessagesData?.message || "-"}</MDTypography>
            </MDBox>
          </Grid>
        </Grid>
        <Grid container>
          <Grid item xs={12} sm={6} md={6}>
            <MDTypography fontWeight="bold" variant="button">
              Stylist
            </MDTypography>
            <MDBox>
              <MDTypography variant="button">{stylistMessagesData?.stylist || "-"}</MDTypography>
            </MDBox>
          </Grid>
          <Grid item xs={12} sm={6} md={6}>
            <MDTypography fontWeight="bold" variant="button">
              Created At
            </MDTypography>
            <MDBox>
              <MDTypography variant="button">
                {getLocalDateTime(stylistMessagesData?.created_at)}
              </MDTypography>
            </MDBox>
          </Grid>
        </Grid>
        <Grid container>
          <Grid item xs={12} sm={6} md={6}>
            <MDTypography fontWeight="bold" variant="button">
              Updated At
            </MDTypography>
            <MDBox>
              <MDTypography variant="button">
                {getLocalDateTime(stylistMessagesData?.updated_at)}
              </MDTypography>
            </MDBox>
          </Grid>
        </Grid>
      </Grid>
    </MDDialog>
  );
}

export default ViewDetails;
