import { Typography, Card, Icon, IconButton } from "@mui/material";
import MDBox from "components/MDBox";
import { useState, useEffect, useCallback } from "react";
import MDButton from "components/MDButton";
import axios from "axios";
import MDTypography from "components/MDTypography";
import { filterData, SearchType } from "filter-data";
import config from "config/config";
import Messages from "helper/messages";
import { getAuthUser, getAssociationLocations } from "helper/services";
import { useAppDispatch, useAppSelector } from "store/store";
import DataTable from "examples/Tables/DataTable";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import { getCmsLocations } from "store/thunk/locationThunk";
import {
  columnsToCastOccupancy,
  convertToFirstDayOfMonth,
  convertToLastDayOfMonth,
  fetchCurrentDate,
  getDateRange,
  getFirstDateOfCurrentMonth,
  scoercardDateRange,
  sortingValuesOccupancy,
  getUniqueValuesOccupancy,
} from "helper/reports";
import { notificationFail, notificationSuccess } from "store/slices/notificationSlice";
import CustomLoader from "components/CustomLoader";
import CustomSelect from "components/CustomSelect";
import arraySort from "array-sort";
import ScorecardDateInput from "../scorecard-dashboard/components/scorecardDateInput";
import { SelectedDate, SelectedDateRange } from "../scorecard-dashboard";
import { FilterProps, SortProps } from "../scorecard-dashboard/scorecard-table";

function OccupancyDashboard(): JSX.Element {
  const { cmsLocations } = useAppSelector((state) => state.locationSlice);
  const [solaIds, setSolaIds] = useState<String[]>([]);
  const [tableRows, setTableRows] = useState([]);
  const [selectedDate, setSelectedDate] = useState<SelectedDate>();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const userDetails = getAuthUser();
  const dispatch: any = useAppDispatch();
  const [selectedDateRange, setSelectedDateRange] = useState<SelectedDateRange>();
  const [showFilter, setShowFilter] = useState<boolean>(false);
  const [currentDateCheckBox, setCurrentDateCheckBox] = useState<boolean>(false);
  const [loader, setLoader] = useState<boolean>(true);
  const [applySearch, setApplySearch] = useState<boolean>(false);
  const [filterByTotalChair, setFilterByTotalChair] = useState<FilterProps>({});
  const [filterByOccupiedChair, setFilterByOccupiedChair] = useState<FilterProps>({});
  const [filterByMoveIns, setFilterByMoveIns] = useState<FilterProps>({});
  const [filterByMoveOuts, setFilterByMoveOuts] = useState<FilterProps>({});
  const [filterByOccupancyPercentage, setFilterByOccupancyPercentage] = useState<FilterProps>({});
  const [filterByOpenChairs, setFilterByOpenChairs] = useState<FilterProps>({});
  const [filterByLocation, setFilterByLocation] = useState<FilterProps>({});
  const [filterByProperty, setFilterByProperty] = useState<FilterProps>({});
  const [filterBySolaId, setFilterBySolaId] = useState<FilterProps>({});
  const [filterMetaData, setFilterMetaData] = useState<{ [key: string]: string[] }>();
  const [search, setSearch] = useState("");
  const [sort, setSort] = useState([]);

  const onChangeDateField = (event: any) => {
    const date = scoercardDateRange.find((date) => date.value === event.target.value);
    const [startDate, endDate] = getDateRange(date.value);
    setSelectedDate(date);
    setSelectedDateRange({
      startMonth: startDate,
      endMonth: endDate,
    });
  };

  const tableColumns: any =
    userDetails.role === "Admin" || userDetails.role === "System Admin"
      ? [
          {
            Header: "Sola ID",
            accessor: "sola_id",
            width: "200px",
            export: true,
            disableCheck: true,
            checked: true,
          },
          {
            Header: "Location",
            accessor: "location",
            width: "85px",
            export: true,
            disableCheck: false,
            checked: true,
          },
          {
            Header: "Property",
            accessor: "property",
            width: "200px",
            export: true,
            disableCheck: false,
            checked: true,
          },
          {
            Header: "Total Chairs",
            accessor: "total_chairs",
            export: true,
            disableCheck: false,
            checked: true,
          },
          {
            Header: "Open Chairs",
            accessor: "open_chairs",
            export: true,
            disableCheck: false,
            checked: false,
          },
          {
            Header: "Occupied Chairs",
            accessor: "occupied_chairs",
            export: true,
            disableCheck: false,
            checked: false,
          },
          {
            Header: "Occupancy %",
            accessor: "occupancy_percentage",
            export: true,
            disableCheck: false,
            checked: false,
          },
          {
            Header: "Move Ins",
            accessor: "move_ins",
            export: true,
            disableCheck: false,
            checked: false,
          },
          {
            Header: "Move Outs",
            accessor: "move_outs",
            export: true,
            disableCheck: false,
            checked: false,
          },
        ]
      : [
          {
            Header: "Sola ID",
            accessor: "sola_id",
            width: "200px",
            export: true,
            disableCheck: true,
            checked: true,
          },
          {
            Header: "Property",
            accessor: "property",
            width: "200px",
            export: true,
            disableCheck: false,
            checked: true,
          },
          {
            Header: "Total Chairs",
            accessor: "total_chairs",
            export: true,
            disableCheck: false,
            checked: true,
          },
          {
            Header: "Open Chairs",
            accessor: "open_chairs",
            export: true,
            disableCheck: false,
            checked: false,
          },
          {
            Header: "Occupied Chairs",
            accessor: "occupied_chairs",
            export: true,
            disableCheck: false,
            checked: false,
          },
          {
            Header: "Occupancy %",
            accessor: (d: any) => `${d.occupancy_percentage}%`,
            export: true,
            disableCheck: false,
            checked: false,
          },
          {
            Header: "Move Ins",
            accessor: "move_ins",
            export: true,
            disableCheck: false,
            checked: false,
          },
          {
            Header: "Move Outs",
            accessor: "move_outs",
            export: true,
            disableCheck: false,
            checked: false,
          },
        ];

  const fetchReport = async () => {
    setLoader(true);
    if (selectedDateRange) {
      if (selectedDateRange.startMonth > selectedDateRange.endMonth) {
        throw new Error("Start month should be lower than end month");
      } else {
        let url = "";
        const startDate = currentDateCheckBox
          ? getFirstDateOfCurrentMonth()
          : convertToFirstDayOfMonth(selectedDateRange.startMonth);
        const endDate = currentDateCheckBox
          ? fetchCurrentDate()
          : convertToLastDayOfMonth(selectedDateRange.endMonth);
        if (userDetails.role === "Admin" || userDetails.role === "System Admin") {
          url = `${config.REPORT_URL}/report/selectedOccupancy?startDate=${startDate}&endDate=${endDate}`;
        } else {
          url = `${
            config.REPORT_URL
          }/report/selectedOccupancy?startDate=${startDate}&endDate=${endDate}&locations='${solaIds.toString()}'`;
        }
        if (userDetails.role === "Admin" || userDetails.role === "System Admin" || solaIds.length) {
          axios
            .get(url, {
              responseType: "json",
            })
            .then((res) => {
              setTableRows(res.data.result);
              dispatch(
                notificationSuccess(res.data.message || Messages.SUCCESS.MASTER_DATA_CREATED)
              );
            })
            .catch((err) => {
              dispatch(
                notificationFail(
                  err?.response?.data?.message || Messages.ERROR.SOMETHING_WENT_WRONG
                )
              );
            })
            .finally(() => {
              setIsLoading(false);
              setLoader(false);
            });
        }
      }
    }
  };

  const downloadCSV = () => {
    const csvString = [
      [
        "Sola ID",
        "Location",
        "Property",
        "Total chairs",
        "Open chairs",
        "Occupied chairs",
        "Occupancy Percentage",
        "Move ins",
        "Move outs",
        "As Of Date",
      ],
      ...tableRows.map((item) => [
        item.sola_id,
        item.location,
        item.property,
        item.total_chairs,
        item.open_chairs,
        item.occupied_chairs,
        item.occupancy_percentage,
        item.move_ins,
        item.move_outs,
        item.asofdate,
      ]),
    ]
      .map((row) => row.map((value) => `"${value}"`).join(","))
      .join("\n");

    const blob = new Blob([csvString], { type: "text/csv;charset=utf-8;" });
    const url = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute(
      "download",
      `Occupancy-${selectedDateRange.startMonth}-${selectedDateRange.endMonth}.csv`
    );

    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  useEffect(() => {
    fetchReport();
  }, [selectedDateRange, currentDateCheckBox]);

  useEffect(() => {
    const pageSize = 50;
    const pageIndex = 0;
    const search: any = undefined;
    const sort: any[] = [];
    const filterData: any[] = [];
    dispatch(getCmsLocations({ pageSize, pageIndex, search, sort, filterData }));
    setSelectedDate(scoercardDateRange[0]);
    const [startDate, endDate] = getDateRange(scoercardDateRange[0].value);
    setSelectedDateRange({
      startMonth: startDate,
      endMonth: endDate,
    });
  }, []);

  useEffect(() => {
    const locationData = getAssociationLocations();
    let permittedLocations: String[] = [];
    cmsLocations?.locations?.map((locationDetail: any) => {
      if (
        locationData.get_location_by_ids?.includes(locationDetail.id) &&
        locationDetail.store_id
      ) {
        permittedLocations.push(locationDetail.store_id);
      }
      return 0;
    });
    permittedLocations = permittedLocations.filter(
      (value, index) => permittedLocations.indexOf(value) === index
    );
    setSolaIds(permittedLocations);
  }, [cmsLocations]);

  const fetchData = useCallback(() => {
    const processData = [...tableRows];
    let result: {
      [key: string]: number | string;
    }[] = [];
    if (sort && sort[0]?.desc !== undefined) {
      const desc: boolean = sort[0].desc ? true : false;
      const columnName: any = sortingValuesOccupancy[sort[0].id];
      if (columnsToCastOccupancy.includes(columnName)) {
        result = processData.map((obj) => {
          if (typeof obj[columnName] === "string") {
            const newValue = parseFloat(obj[columnName] as string);
            return { ...obj, [columnName]: newValue };
          }
          return obj;
        });
      }
      result = arraySort(result.length ? result : processData, sortingValuesOccupancy[sort[0].id], {
        reverse: desc,
      });
    }
    // if (filterByMoveOuts.value) {
    //   const check = parseInt(filterByMoveOuts.value?.split(" ").pop(), 10) as number;
    //   if (result.length) {
    //     result = result.filter((item) => parseInt(String(item.move_outs), 10) > check);
    //   } else {
    //     result = processData.filter((item) => parseInt(String(item.move_outs), 10) > check);
    //   }
    // }
    const searchConditions = [
      {
        key: "sola_id",
        value: filterBySolaId.value,
        type: SearchType.EQ,
      },
      {
        key: "location",
        value: filterByLocation.value,
        type: SearchType.EQ,
      },
      {
        key: "property",
        value: filterByProperty.value,
        type: SearchType.EQ,
      },
      {
        key: "total_chairs",
        value:
          filterByTotalChair.value &&
          (parseInt(filterByTotalChair.value.split(" ").pop(), 10) as number),
        type: SearchType.GT,
      },
      {
        key: "occupied_chairs",
        value:
          filterByOccupiedChair.value &&
          (parseInt(filterByOccupiedChair.value.split(" ").pop(), 10) as number),
        type: SearchType.GT,
      },
      {
        key: "occupancy_percentage",
        value:
          filterByOccupancyPercentage.value &&
          (parseInt(filterByOccupancyPercentage.value.split(" ").pop(), 10) as number),
        type: SearchType.GT,
      },
      {
        key: "move_ins",
        value:
          filterByMoveIns.value && (parseInt(filterByMoveIns.value.split(" ").pop(), 10) as number),
        type: SearchType.GT,
      },
      {
        key: "move_outs",
        value: filterByMoveOuts.value
          ? (parseInt(filterByMoveOuts.value?.split(" ").pop(), 10) as number)
          : undefined,
        type: filterByMoveOuts.value?.split(" ").pop() !== "0" ? SearchType.LT : SearchType.EQ,
      },
      {
        key: "open_chairs",
        value:
          filterByOpenChairs.value &&
          (parseInt(filterByOpenChairs.value.split(" ").pop(), 10) as number),
        type: SearchType.GT,
      },
    ];
    const searchResult = filterData(result.length ? result : processData, searchConditions);
    const searchedData = searchResult;

    return searchedData;
  }, [search, sort, applySearch, tableRows]);

  useEffect(() => {
    setFilterMetaData(getUniqueValuesOccupancy(tableRows));
  }, [tableRows]);

  const renderTable = () => (
    <MDBox mt={5}>
      <MDBox
        bgColor="dark"
        color="white"
        coloredShadow="dark"
        borderRadius="xl"
        alignItems="center"
        justifyContent="space-between"
        pt={1}
        pb={1}
        pl={2}
        pr={2}
        ml={2}
        mr={2}
        display="flex"
        mt={-3}
        className="page-header"
      >
        <p className="page-header-label">Occupancy</p>
      </MDBox>
      <DataTable
        table={{
          columns: tableColumns,
          rows: fetchData(),
        }}
        fetchData={({
          sortBy,
          search,
        }: {
          sortBy: Array<SortProps>;
          search: string;
          filterData: any;
        }) => {
          setSearch(search);
          setSort(sortBy);
        }}
      />
    </MDBox>
  );

  const clearFilters = () => {
    setFilterByTotalChair({});
    setFilterByOccupiedChair({});
    setFilterByMoveOuts({});
    setFilterByOpenChairs({});
    setFilterByOccupancyPercentage({});
    setFilterByMoveIns({});
    setFilterByLocation({});
    setFilterByProperty({});
    setFilterBySolaId({});
    setApplySearch(!applySearch);
  };

  return (
    <>
      {loader && (
        <MDBox
          style={{
            background: "rgba(0,0,0,0.3)",
            zIndex: 99999,
            display: "flex",
            position: "fixed",
            alignItems: "center",
            justifyContent: "center",
            width: "100%",
            height: "100vh",
          }}
        >
          <CustomLoader />
        </MDBox>
      )}
      <DashboardLayout>
        <DashboardNavbar />
        <MDBox py={3}>
          <Card sx={{ padding: "20px" }}>
            <MDBox display="flex" flexDirection="row" justifyContent="space-between">
              <MDBox display="flex" justifyContent="start" sx={{ marginTop: { xs: 2, md: 0 } }}>
                <MDBox mt={10}>
                  {showFilter && (
                    <>
                      <MDBox
                        className="crm-location-header-wrapper"
                        onClick={() => setShowFilter(false)}
                      />
                      <MDBox
                        className="crm-location-header"
                        display={showFilter ? "inline-flex" : "none"}
                      >
                        <MDBox sx={{ display: "inline" }}>
                          <IconButton
                            className="close-filter-button"
                            onClick={() => setShowFilter(false)}
                          >
                            <Icon>close</Icon>
                          </IconButton>
                        </MDBox>
                        <MDBox sx={{ display: "inline", mt: 4 }}>
                          <CustomSelect
                            onChange={(filterValue: any) => setFilterBySolaId(filterValue)}
                            name="solaid"
                            placeholder="Select Sola Id"
                            value={Object.keys(filterBySolaId).length > 0 ? filterBySolaId : null}
                            id="solaid"
                            options={filterMetaData?.sola_id.map((value: any) => {
                              const tempObj = { label: value, value };
                              return tempObj;
                            })}
                          />
                          <CustomSelect
                            onChange={(filterValue: any) => setFilterByLocation(filterValue)}
                            name="property"
                            placeholder="Select Location"
                            value={
                              Object.keys(filterByLocation).length > 0 ? filterByLocation : null
                            }
                            id="property"
                            options={filterMetaData?.location.map((value: any) => {
                              const tempObj = { label: value, value };
                              return tempObj;
                            })}
                          />
                          <CustomSelect
                            onChange={(filterValue: any) => setFilterByProperty(filterValue)}
                            name="property"
                            placeholder="Select Property"
                            value={
                              Object.keys(filterByProperty).length > 0 ? filterByProperty : null
                            }
                            id="property"
                            options={filterMetaData?.property.map((value: any) => {
                              const tempObj = { label: value, value };
                              return tempObj;
                            })}
                          />
                          <CustomSelect
                            onChange={(filterValue: any) => setFilterByTotalChair(filterValue)}
                            name="property"
                            placeholder="Select Total Occupancy"
                            value={
                              Object.keys(filterByTotalChair).length > 0 ? filterByTotalChair : null
                            }
                            id="property"
                            options={filterMetaData?.total_chair.map((value: any) => {
                              const tempObj = { label: value, value };
                              return tempObj;
                            })}
                          />
                          <CustomSelect
                            onChange={(filterValue: any) => setFilterByOccupiedChair(filterValue)}
                            name="occupancy"
                            placeholder="Select Occupancy"
                            value={
                              Object.keys(filterByOccupiedChair).length > 0
                                ? filterByOccupiedChair
                                : null
                            }
                            id="occupancy"
                            options={filterMetaData?.occupied_chair.map((value: any) => {
                              const tempObj = { label: value, value };
                              return tempObj;
                            })}
                          />
                          <CustomSelect
                            onChange={(filterValue: any) =>
                              setFilterByOccupancyPercentage(filterValue)
                            }
                            name="occupancy"
                            placeholder="Select Occupancy Percentage"
                            value={
                              Object.keys(filterByOccupancyPercentage).length > 0
                                ? filterByOccupancyPercentage
                                : null
                            }
                            id="occupancy"
                            options={filterMetaData?.occupancy_percentage.map((value: any) => {
                              const tempObj = { label: value, value };
                              return tempObj;
                            })}
                          />
                          <CustomSelect
                            onChange={(filterValue: any) => setFilterByOpenChairs(filterValue)}
                            name="occupancy"
                            placeholder="Select Open Chairs"
                            value={
                              Object.keys(filterByOpenChairs).length > 0 ? filterByOpenChairs : null
                            }
                            id="occupancy"
                            options={filterMetaData?.open_chairs.map((value: any) => {
                              const tempObj = { label: value, value };
                              return tempObj;
                            })}
                          />
                          <CustomSelect
                            onChange={(filterValue: any) => setFilterByMoveIns(filterValue)}
                            name="move-ins"
                            placeholder="Select Move Ins"
                            value={Object.keys(filterByMoveIns).length > 0 ? filterByMoveIns : null}
                            id="move-ins"
                            options={filterMetaData?.move_ins.map((value: any) => {
                              const tempObj = { label: value, value };
                              return tempObj;
                            })}
                          />
                          <CustomSelect
                            onChange={(filterValue: any) => setFilterByMoveOuts(filterValue)}
                            name="move-outs"
                            placeholder="Select Move Outs"
                            value={
                              Object.keys(filterByMoveOuts).length > 0 ? filterByMoveOuts : null
                            }
                            id="move-outs"
                            options={filterMetaData?.move_outs.map((value: any) => {
                              const tempObj = { label: value, value };
                              return tempObj;
                            })}
                          />
                        </MDBox>
                        <MDBox sx={{ display: "flex", justifyContent: "flex-end", width: "100%" }}>
                          <MDButton
                            variant="gradient"
                            color="info"
                            size="small"
                            className="xs-small"
                            sx={{ marginTop: "5px" }}
                            onClick={() => setApplySearch(!applySearch)}
                          >
                            Apply
                          </MDButton>
                          <MDButton
                            variant="gradient"
                            sx={{ marginLeft: "10px" }}
                            color="light"
                            size="small"
                            className="xs-small"
                            onClick={() => clearFilters()}
                          >
                            Clear
                          </MDButton>
                        </MDBox>
                      </MDBox>
                    </>
                  )}
                </MDBox>
                {selectedDateRange && selectedDateRange.startMonth && (
                  <ScorecardDateInput
                    selectedDate={selectedDate}
                    onChangeDateField={onChangeDateField}
                    dateArr={scoercardDateRange}
                    selectedDateRange={selectedDateRange}
                    setSelectedDateRange={setSelectedDateRange}
                    setSelectedDate={setSelectedDate}
                    currentDateCheckBox={currentDateCheckBox}
                    setCurrentDateCheckBox={setCurrentDateCheckBox}
                  />
                )}
              </MDBox>
              {tableRows ? (
                <MDBox>
                  <MDButton variant="gradient" color="info">
                    <MDTypography variant="subtitle2" color="white" onClick={downloadCSV}>
                      Export
                    </MDTypography>
                  </MDButton>
                  <IconButton
                    onClick={() => {
                      setShowFilter(!showFilter);
                    }}
                  >
                    <Icon>filter_list</Icon>
                  </IconButton>
                </MDBox>
              ) : null}
            </MDBox>
            <Typography
              variant="body2"
              color="white"
              mt={2}
              sx={{
                background: "#C1C1C1",
                padding: "10px",
                borderRadius: "5px",
                fontSize: "12px",
                lineHeight: "14px",
              }}
            >
              Disclaimer: Occupancy for the reporting month is updated daily from the 1st through
              the 10th of the following month. The occupancy data updated on the 10th will be the
              final submission for occupancy reporting. If you are seeing incorrect occupancy
              information prior to the 10th, you can update your occupancy information in Rent
              Manager. You can access video guides for updating your occupancy on FranConnect.
            </Typography>
            <MDBox mt={2}>
              {!isLoading ? (
                solaIds.length ||
                userDetails.role === "Admin" ||
                (userDetails.role === "System Admin" && tableRows.length) ? (
                  renderTable()
                ) : (
                  <MDBox sx={{ minHeight: "200" }}>
                    <Card
                      variant="outlined"
                      sx={{
                        height: "200px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <Typography variant="h5">No Data Found</Typography>
                    </Card>
                  </MDBox>
                )
              ) : null}
            </MDBox>
          </Card>
        </MDBox>
      </DashboardLayout>
    </>
  );
}

export default OccupancyDashboard;
