import { useEffect, useState } from "react";
import { SelectedDateRange } from "layouts/pages/scorecard-dashboard";
import { notificationFail } from "store/slices/notificationSlice";
import { useAppDispatch } from "store/store";
import config from "config/config";
import axios from "axios";
import {
  getFirstDateOfCurrentMonth,
  convertToFirstDayOfMonth,
  fetchCurrentDate,
  convertToLastDayOfMonth,
  getYears,
  createLeadsIntentObject,
} from "helper/reports";
import Messages from "helper/messages";
import MDTypography from "components/MDTypography";
import { Card, Divider, Grid, Icon } from "@mui/material";
import MDBox from "components/MDBox";
import { Bar } from "react-chartjs-2";
import { FormattedType, LeadsIntentDataProps } from "../..";

interface LeadsIntentInputProps {
  selectedDateRange: SelectedDateRange;
  currentDateCheckBox: boolean;
  // eslint-disable-next-line no-unused-vars
  setLeadsIntent: (leadsIntent: LeadsIntentDataProps) => void;
}
function LeadsIntent({
  selectedDateRange,
  currentDateCheckBox,
  setLeadsIntent,
}: LeadsIntentInputProps) {
  const dispatch = useAppDispatch();
  const [formattedLeadsIntent, setFormattedLeadsIntent] = useState<FormattedType>();
  const fetchLeadMaturedData = () => {
    try {
      if (selectedDateRange.startMonth > selectedDateRange.endMonth) {
        throw new Error("Start month should be lower than end month");
      } else {
        const startDate = currentDateCheckBox
          ? getFirstDateOfCurrentMonth()
          : convertToFirstDayOfMonth(selectedDateRange.startMonth);
        const endDate = currentDateCheckBox
          ? fetchCurrentDate()
          : convertToLastDayOfMonth(selectedDateRange.endMonth);
        let url = "";
        url = `${config.REPORT_URL}/report/fetchLeadsIntentData?startDate=${startDate
          .toString()
          .slice(0, 10)}&endDate=${endDate.toString().slice(0, 10)}`;
        axios
          .get(url, {
            responseType: "json",
          })
          .then((res: any) => {
            setLeadsIntent(res.data.result);
            setFormattedLeadsIntent(createLeadsIntentObject(res.data.result));
          })
          .catch((err) => {
            dispatch(
              notificationFail(err?.response?.data?.message || Messages.ERROR.SOMETHING_WENT_WRONG)
            );
          });
      }
    } catch (e) {
      dispatch(notificationFail(e.message || Messages.ERROR.SOMETHING_WENT_WRONG));
    }
  };
  useEffect(() => {
    fetchLeadMaturedData();
  }, [selectedDateRange]);

  const options = {
    plugins: {
      legend: {
        display: false,
      },
      title: {
        display: false,
        text: "Chart.js Bar Chart",
      },
    },
  };
  const data = {
    labels: getYears(selectedDateRange, currentDateCheckBox),
    datasets: [
      {
        label: "Intent B2B",
        data: formattedLeadsIntent?.intent_b2b,
        backgroundColor: "rgba(255, 99, 132, 0.5)",
      },
      {
        label: "Intent B2C",
        data: formattedLeadsIntent?.intent_b2c,
        backgroundColor: "rgba(245, 174, 39, 0.8)",
      },
      {
        label: "Intent Other",
        data: formattedLeadsIntent?.intent_other,
        backgroundColor: "rgba(178, 245, 39, 0.8)",
      },
    ],
  };

  return (
    <Card sx={{ height: "100%" }}>
      <Grid container>
        <Grid item md={12} sm={12} pt={3} pb={1} px={1}>
          <Bar options={options} data={data} />
        </Grid>
        <Grid item md={12} sm={12}>
          <MDTypography variant="h6" textTransform="capitalize" padding="0px 0px 0px 15px">
            Leads Intent
          </MDTypography>
          <Divider />
          <MDBox display="flex" alignItems="center" padding="0px 0px 15px 15px">
            <MDTypography variant="button" color="text" lineHeight={1} sx={{ mt: 0.15, mr: 0.5 }}>
              <Icon>schedule</Icon>
            </MDTypography>
            <MDTypography variant="button" color="text" fontWeight="light">
              {!currentDateCheckBox
                ? `${selectedDateRange.startMonth} - ${selectedDateRange.endMonth}`
                : `${new Date().getFullYear()}-${(new Date().getMonth() + 1)
                    .toString()
                    .padStart(2, "0")}`}
            </MDTypography>
          </MDBox>
        </Grid>
      </Grid>
    </Card>
  );
}

export default LeadsIntent;
