import { Grid } from "@mui/material";
import MDTypography from "components/MDTypography";

function SmsRequest(props: any): JSX.Element {
  const { locationData, section } = props;
  const locationNumberMessage = () => {
    let message = "";
    if (
      locationData?.connect_twillio_phone_number &&
      locationData?.connect_twillio_phone_number?.status !== "enabled"
    ) {
      message = `Location phone number status is ${locationData?.connect_twillio_phone_number?.status}. Please contact to admin`;
    } else if (
      locationData?.connect_twillio_phone_number &&
      !locationData?.connect_twillio_phone_number?.sms_status
    ) {
      message = `Location phone number SMS status is Disabled. Please contact to admin`;
    } else if (
      locationData?.connect_twillio_sms_request?.status === "active" &&
      !locationData?.connect_twillio_phone_number
    ) {
      message = `SMS status is active but number is not assigned to this location. Please contact to admin.`;
    }

    if (message) {
      return (
        <Grid
          container
          spacing={0}
          direction="column"
          alignItems="center"
          justifyContent="center"
          style={{ minHeight: "70vh" }}
        >
          <MDTypography variant="body2" sx={{ mb: 2 }}>
            {message}
          </MDTypography>
        </Grid>
      );
    }
    return null;
  };

  const deactiveStatus = ["deactive", "phone_deleted", "removed_from_phone_number", "disabled"];

  return (
    <Grid
      container
      spacing={0}
      direction="column"
      alignItems="center"
      justifyContent="center"
      textAlign="center"
      style={{ minHeight: "70vh", padding: "10px" }}
    >
      {!locationData?.connect_twillio_sms_request && (
        <MDTypography variant="body2" sx={{ mb: 2 }}>
          To active SMS for this Location, please contact our team.
        </MDTypography>
      )}
      {section &&
        locationData?.connect_twillio_sms_request &&
        locationData?.connect_twillio_sms_request?.status === "requested" && (
          <Grid
            container
            spacing={0}
            direction="column"
            alignItems="center"
            justifyContent="center"
            style={{ minHeight: "70vh" }}
          >
            <MDTypography variant="body2" sx={{ mb: 2 }}>
              SMS request for this location has been submited and admin will review soon.
            </MDTypography>
          </Grid>
        )}
      {section &&
        locationData?.connect_twillio_sms_request &&
        deactiveStatus.includes(locationData?.connect_twillio_sms_request?.status) && (
          <Grid
            container
            spacing={0}
            direction="column"
            alignItems="center"
            justifyContent="center"
            style={{ minHeight: "70vh" }}
          >
            <MDTypography variant="body2" sx={{ mb: 2 }}>
              SMS functionality is {`${locationData?.connect_twillio_sms_request?.status}`} or phone
              number is deleted by admin. Please contact to admin.
            </MDTypography>
          </Grid>
        )}
      {locationNumberMessage()}
    </Grid>
  );
}

export default SmsRequest;
