import MDBox from "components/MDBox";
import BasicInfo from "layouts/pages/sola-pros/account-setup-wizard/steps/website/basicInfo";
import ContacInfo from "layouts/pages/sola-pros/account-setup-wizard/steps/website/contacInfo";
import Images from "layouts/pages/sola-pros/account-setup-wizard/steps/website/images";
import Testimonials from "layouts/pages/sola-pros/account-setup-wizard/steps/website/testimonials";
import SocialLinks from "layouts/pages/sola-pros/account-setup-wizard/steps/website/socialLinks";
import PersonalWebsite from "layouts/pages/sola-pros/account-setup-wizard/steps/website/personalWebsite";

function Index(props: any): JSX.Element {
  return (
    <MDBox mt={2}>
      <BasicInfo {...props} />
      <ContacInfo {...props} />
      <Images {...props} />
      <Testimonials {...props} />
      <SocialLinks {...props} />
      <PersonalWebsite {...props} />
    </MDBox>
  );
}

export default Index;
