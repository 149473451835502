export default {
  editorModules: {
    toolbar: [
      [{ header: [1, 2, false] }],
      ["bold", "italic", "underline", "strike", "blockquote"],
      [{ list: "ordered" }, { list: "bullet" }, { indent: "-1" }, { indent: "+1" }],
      ["link", "image"],
      ["clean"],
    ],
  },
  editorFormats: [
    "header",
    "bold",
    "italic",
    "underline",
    "strike",
    "blockquote",
    "list",
    "bullet",
    "indent",
    "link",
    "image",
  ],
  maxWalkInsTimes: [
    { time: "15", label: "15 minutes" },
    { time: "30", label: "30 minutes" },
    { time: "45", label: "45 minutes" },
    { time: "60", label: "1 hours" },
    { time: "75", label: "1 hours 15 minutes" },
    { time: "90", label: "1 hours 30 minutes" },
    { time: "105", label: "1 hours 45 minutes" },
    { time: "120", label: "2 hours" },
    { time: "135", label: "2 hours 15 minutes" },
    { time: "150", label: "2 hours 30 minutes" },
    { time: "165", label: "2 hours 45 minutes" },
    { time: "180", label: "3 hours" },
    { time: "195", label: "3 hours 15 mintues" },
    { time: "210", label: "3 hours 30 minutes" },
    { time: "225", label: "3 hours 45 minutes" },
    { time: "240", label: "4 hours" },
    { time: "255", label: "4 hours 15 minutes" },
    { time: "270", label: "4 hours 30 minutes" },
    { time: "285", label: "4 hours 45 minutes" },
    { time: "300", label: "5 hours" },
    { time: "315", label: "5 hours 15 minutes" },
    { time: "330", label: "5 hours 30 minutes" },
    { time: "345", label: "5 hours 45 minutes" },
    { time: "360", label: "6 hours" },
    { time: "375", label: "6 hours 15 minutes" },
    { time: "390", label: "6 hours 30 minutes" },
    { time: "405", label: "6 hours 45 minutes" },
    { time: "420", label: "7 hours" },
    { time: "435", label: "7 hours 15 minutes" },
    { time: "450", label: "7 hours 30 minutes" },
    { time: "465", label: "7 hours 45 minutes" },
    { time: "480", label: "8 hours" },
  ],
  crmSmSTemplateAlias: "sola-connect-crm-follow-up-sms",
  crmEmailTemplateAlias: "sola-connect-crm-follow-up-email",
  approvalFieldsGeneral: [
    "name",
    "general_contact_first_name",
    "general_contact_last_name",
    "general_contact_email",
    "phone_number",
    "address_1",
    "address_2",
    "city",
    "state",
    "countries",
    "msas",
  ],
  approvalFieldsWebsite: [
    "url_name",
    "description",
    "public_contact_name",
    "public_phone",
    "public_email",
  ],
  dashboardTabs: [
    { alias: "operations", display_name: "Operations" },
    { alias: "marketing", display_name: "Marketing" },
  ],
  scorecardTabs: [
    { alias: "scorecard", display_name: "Scorecard" },
    { alias: "scorecard_data", display_name: "Scorecard Data" },
    { alias: "matured_location", display_name: "Matured Location" },
  ],
  openLocationTabs: [
    { alias: "matured_locations", display_name: "Matured" },
    { alias: "unmatured_locations", display_name: "Unmatured" },
  ],
  masterDataTabs: [
    { alias: "masterData", display_name: "Master Data" },
    { alias: "addroyalty", display_name: "Add Revenue Data" },
  ],
  leaseApplicationStatus: {
    NOT_SENT: "not_sent",
    APPLICATION_SENT: "application_sent",
    NEED_REVIEW: "need_review",
    SIGNED: "signed",
    SECURITY_DEPOSIT_RECEIVED: "security_deposit_received",
  },
  solaProStatusOptions: [
    { value: "open", label: "Active" },
    { value: "closed", label: "Inactive" },
  ],
  breadcrumbs: [
    {
      name: "crm",
      value: "CRM",
    },
    {
      name: "msa",
      value: "MSA",
    },
    {
      name: "sola cms",
      value: "Sola CMS",
    },
    {
      name: "sola cms",
      value: "Sola CMS",
    },
    {
      name: "rm data sync",
      value: "RM Data Sync",
    },
    {
      name: "rm events",
      value: "RM Events",
    },
    {
      name: "cms locations",
      value: "CMS Locations",
    },
    {
      name: "cms sola pros",
      value: "CMS Sola Pros",
    },
    {
      name: "faqs",
      value: "FAQs",
    },
  ],
  originalSourceType: [
    { label: "Organic Search", value: "ORGANIC_SEARCH" },
    { label: "Paid Search", value: "PAID_SEARCH" },
    { label: "Email Marketing", value: "EMAIL_MARKETING" },
    { label: "Organic Social", value: "SOCIAL_MEDIA" },
    { label: "Referrals", value: "REFERRALS" },
    { label: "Other Campaigns", value: "OTHER_CAMPAIGNS" },
    { label: "Direct Traffic", value: "DIRECT_TRAFFIC" },
    { label: "Offline Sources", value: "OFFLINE" },
    { label: "Paid Social", value: "PAID_SOCIAL" },
  ],
  smsTokens: [
    { label: "First name", value: "user_first_name", table_name: "user" },
    { label: "Last name", value: "user_last_name", table_name: "user" },
    { label: "Email", value: "user_email", table_name: "user" },

    { label: "First name", value: "first_name", table_name: "deals" },
    { label: "Last name", value: "last_name", table_name: "deals" },
    { label: "Email", value: "email", table_name: "deals" },
    { label: "Phone", value: "phone", table_name: "deals" },

    {
      label: "Website Contact First Name",
      value: "website_contact_first_name",
      table_name: "locations",
    },
    {
      label: "Website Contact Last Name",
      value: "website_contact_last_name",
      table_name: "locations",
    },
    {
      label: "Website Contact Email",
      value: "website_contact_email",
      table_name: "locations",
    },
    {
      label: "Website Contact Phone",
      value: "website_contact_phone",
      table_name: "locations",
    },
    {
      label: "General Contact First Name",
      value: "general_contact_first_name",
      table_name: "locations",
    },
    {
      label: "General Contact Last Name",
      value: "general_contact_last_name",
      table_name: "locations",
    },
    {
      label: "General Contact Email",
      value: "general_contact_email",
      table_name: "locations",
    },
    {
      label: "General Contact Phone",
      value: "general_contact_phone",
      table_name: "locations",
    },
    { label: "Location Name", value: "location_name", table_name: "locations" },
    { label: "Address 1", value: "address_1", table_name: "locations" },
    { label: "Address 2", value: "address_2", table_name: "locations" },
    { label: "City", value: "city", table_name: "locations" },
    { label: "State", value: "state", table_name: "locations" },
    { label: "Postal Code", value: "postal_code", table_name: "locations" },
  ],
  timeSlots: [
    // "12:00 AM",
    // "12:30 AM",
    // "1:00 AM",
    // "1:30 AM",
    // "2:00 AM",
    // "2:30 AM",
    // "3:00 AM",
    // "3:30 AM",
    // "4:00 AM",
    // "4:30 AM",
    // "5:00 AM",
    // "5:30 AM",
    "6:00 AM",
    "6:30 AM",
    "7:00 AM",
    "7:30 AM",
    "8:00 AM",
    "8:30 AM",
    "9:00 AM",
    "9:30 AM",
    "10:00 AM",
    "10:30 AM",
    "11:00 AM",
    "11:30 AM",
    "12:00 PM",
    "12:30 PM",
    "1:00 PM",
    "1:30 PM",
    "2:00 PM",
    "2:30 PM",
    "3:00 PM",
    "3:30 PM",
    "4:00 PM",
    "4:30 PM",
    "5:00 PM",
    "5:30 PM",
    "6:00 PM",
    "6:30 PM",
    "7:00 PM",
    "7:30 PM",
    "8:00 PM",
    "8:30 PM",
    "9:00 PM",
    "9:30 PM",
    "10:00 PM",
    "10:30 PM",
    "11:00 PM",
    "11:30 PM",
  ],
  servicesFilterOptions: [
    { value: "barber", name: "Barber" },
    { value: "botox/fillers", name: "Botox/Fillers" },
    { value: "brows", name: "Brows" },
    { value: "hair", name: "Hair" },
    { value: "hair_extensions", name: "Hair Extensions" },
    { value: "laser_hair_removal", name: "Laser Hair Removal" },
    { value: "lashes", name: "Lashes" },
    { value: "makeup", name: "Makeup" },
    { value: "massage", name: "Massage" },
    { value: "nails", name: "Nails" },
    { value: "permanent_makeup", name: "Permanent Makeup" },
    { value: "skincare", name: "Skincare" },
    { value: "tanning", name: "Tanning" },
    { value: "teeth_whitening", name: "Teeth Whitening" },
    { value: "threading", name: "Threading" },
    { value: "waxing", name: "Waxing" },
    { value: "other", name: "Other" },
  ],
  studioTypeOptions: [
    { value: "Standard (smallest)", label: "Standard" },
    { value: "Large (largest)", label: "Large" },
    { value: "Double", label: "Double" },
    { value: "Triple", label: "Triple" },
    { value: "Spa", label: "Spa" },
  ],
  studioPreferenceOptions: [
    { value: "Quiet Space (low traffic area)", label: "Quiet Space(low traffic area)" },
    { value: "Window", label: "Window" },
    { value: "Entryway", label: "Entryway" },
  ],
  interestedSharingOptions: [
    { value: "yes", label: "Yes" },
    { value: "no", label: "No" },
  ],
  leadComingFromOptions: [
    {
      value: "Commissioned based traditional salon",
      label: "Commissioned based traditional salon",
    },
    { value: "Graduated from School", label: "Graduated from School" },
    { value: "Booth Rent", label: "Booth Rent" },
    { value: "Other", label: "Other" },
    { value: "A Competitor", label: "A Competitor" },
  ],
  reasonClosingLeadOptions: [
    { value: "not_interested", label: "Not interested at this time" },
    { value: "went_dark", label: "Went Dark" },
    { value: "duplicate_contact", label: "Duplicate Contact" },
    { value: "cannot_afford", label: "Can't afford" },
    { value: "went_to_a_competitor", label: "Went to a competitor" },
    { value: "not_qualified", label: "Not qualified" },
    { value: "attempting_to_book_appointment", label: "Attempting to book appointment" },
    { value: "went_to_a_traditional_salon", label: "Went to a traditional salon" },
    { value: "went_to_a_different_sola", label: "Went to a neighboring Sola" },
    { value: "vendor", label: "Vendor" },
    { value: "other", label: "Other" },
    { value: "No immediate availability", label: "No immediate availability" },
    { value: "cold_feet_backed_out", label: "Cold Feet / Backed Out" },
    { value: "no_show", label: "No Show" },
  ],
  messageStatusOptions: [
    { value: "open", label: "Awaiting Reply" },
    { value: "done", label: "Response Sent" },
  ],
  sortByOptions: [
    { value: "created_at__desc", label: "Created at desc" },
    { value: "created_at__asc", label: "Created at asc" },
    { value: "updated_at__desc", label: "Updated at desc" },
    { value: "updated_at__asc", label: "Updated at asc" },
    { value: "last_conversation_created_at__desc", label: "Last Convesation at desc" },
    { value: "last_conversation_created_at__asc", label: "Last Convesation at asc" },
  ],
  timeSlotsArr: [
    {
      label: "6:00 AM",
      disabled: false,
    },
    {
      label: "6:30 AM",
      disabled: false,
    },
    {
      label: "7:00 AM",
      disabled: false,
    },
    {
      label: "7:30 AM",
      disabled: false,
    },
    {
      label: "8:00 AM",
      disabled: false,
    },
    {
      label: "8:30 AM",
      disabled: false,
    },
    {
      label: "9:00 AM",
      disabled: false,
    },
    {
      label: "9:30 AM",
      disabled: false,
    },
    {
      label: "10:00 AM",
      disabled: false,
    },
    {
      label: "10:30 AM",
      disabled: false,
    },
    {
      label: "11:00 AM",
      disabled: false,
    },
    {
      label: "11:30 AM",
      disabled: false,
    },
    {
      label: "12:00 PM",
      disabled: false,
    },
    {
      label: "12:30 PM",
      disabled: false,
    },
    {
      label: "1:00 PM",
      disabled: false,
    },
    {
      label: "1:30 PM",
      disabled: false,
    },
    {
      label: "2:00 PM",
      disabled: false,
    },
    {
      label: "2:30 PM",
      disabled: false,
    },
    {
      label: "3:00 PM",
      disabled: false,
    },
    {
      label: "3:30 PM",
      disabled: false,
    },
    {
      label: "4:00 PM",
      disabled: false,
    },
    {
      label: "4:30 PM",
      disabled: false,
    },
    {
      label: "5:00 PM",
      disabled: false,
    },
    {
      label: "5:30 PM",
      disabled: false,
    },
    {
      label: "6:00 PM",
      disabled: false,
    },
    {
      label: "6:30 PM",
      disabled: false,
    },
    {
      label: "7:00 PM",
      disabled: false,
    },
    {
      label: "7:30 PM",
      disabled: false,
    },
    {
      label: "8:00 PM",
      disabled: false,
    },
    {
      label: "8:30 PM",
      disabled: false,
    },
    {
      label: "9:00 PM",
      disabled: false,
    },
    {
      label: "9:30 PM",
      disabled: false,
    },
    {
      label: "10:00 PM",
      disabled: false,
    },
    {
      label: "10:30 PM",
      disabled: false,
    },
    {
      label: "11:00 PM",
      disabled: false,
    },
    {
      label: "11:30 PM",
      disabled: false,
    },
  ],
  optOutOptions: [
    { value: "no", label: "Opt In" },
    { value: "yes", label: "Opt Out" },
  ],
  callTreeAudioExts: ["mp3", "wma", "mpg", "flv", "avi"],
  inactiveReason: ["left", "accidental", "incorrect"],
  maxSize: 3759.4 * 266 * 3,
  imageExtentionArray: ["jpg", "JPG", "png", "PNG", "jpeg", "JPEG", "gif", "webp"],
  dealImportExtention: ["csv", "CSV", "xls", "xlsx", "XLS", "XLSX"],
  twillioSupportedMimeTypes: [
    ".png",
    ".jpg",
    ".jpeg",
    ".gif",
    ".bmp",
    ".tiff",
    ".webp",
    ".basic",
    ".L24",
    ".mp4",
    ".mpeg",
    ".ogg",
    ".vnd.rn-realaudio",
    ".vnd.wave",
    ".3gpp",
    ".3gpp2",
    ".ac3",
    ".webm",
    ".amr-nb",
    ".amr",
    ".aac",
    ".ogg; codecs=opus",
    ".quicktime",
    ".3gpp-tt",
    ".H261",
    ".H263",
    ".H263-1998",
    ".H263-2000",
    ".H264",
    ".vcard",
    ".x-vcard",
    ".csv",
    ".rtf",
    ".richtext",
    ".calendar",
    ".directory",
    ".pdf",
    ".vnd.apple.pkpass",
    ".msword",
    ".vnd.ms-excel",
    ".vnd.ms-powerpoint",
    ".vnd.openxmlformats-officedocument.presentationml.presentation",
    ".vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    ".vnd.openxmlformats-officedocument.wordprocessingml.document",
  ],
};
