import React, { useState } from "react";
import MDDialog from "components/MDDialog";
import MDInput from "components/MDInput";
import MDTypography from "components/MDTypography";
import {
  Autocomplete,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  Icon,
  TextField,
} from "@mui/material";
import CustomDropZone from "components/CustomDropZone";
import fileimg from "assets/images/file-image.svg";
import moment from "moment";
import MDDatePicker from "components/MDDatePicker";
import MDBox from "components/MDBox";
import MDIconButton from "components/MDIconButton";

const locationListData = [
  { id: 1, name: "Location 1" },
  { id: 2, name: "Location 2" },
  { id: 3, name: "Location 3" },
];

const unitListData = [
  { id: 1, name: "Unit 1" },
  { id: 2, name: "Unit 2" },
  { id: 3, name: "Unit 3" },
];

function addUpdate(props: any): JSX.Element {
  const { open, onClose } = props;

  const [requestData, setRequestData] = useState<any>({});
  const [filePreview, setFilePreview] = useState<any>("");
  //   const [errors, setErrors] = useState<any>({});

  const onSave = () => {
    console.log("Submit issue", requestData);
  };

  const dialogProps = {
    open,
    onSave,
    onClose,
    title: "New Facility Issue",
    size: "md",
    saveTbtText: "Submit issue",
  };

  const onChangeField = (e: any, field: string) => {
    setRequestData({ ...requestData, [field]: e.target.value });
  };

  const handleDateChange = (date: any) => {
    const utcDate = moment.utc(new Date(date)).format();
    setRequestData({ ...requestData, open_date: utcDate });
  };

  const handleSingleCheckbox = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRequestData({ ...requestData, [event.target.name]: event.target.checked });
  };

  const onChangeImage = async (files: any, type: any) => {
    if (files[0]) {
      const file = files[0];
      const reader = new FileReader();
      reader.onload = function (e) {
        if (type === "file") {
          setRequestData({ ...requestData, [type]: files[0], delete_file: 0 });
          setFilePreview(e.target.result);
        }
      };
      reader.readAsDataURL(file);
    }
  };

  const onImageRemove = async (e: any, type: any) => {
    e.stopPropagation();
    if (type === "file") {
      setRequestData({ ...requestData, [type]: null, delete_file: 1 });
      setFilePreview("");
    }
  };

  return (
    <MDDialog {...dialogProps}>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <MDBox display="flex" alignItems="center">
            <MDTypography variant="button" mr={2}>
              Open date:
            </MDTypography>
            <MDDatePicker
              value={requestData?.open_date || moment.utc(new Date()).format()}
              fullWidth
              options={{
                enableTime: true,
                dateFormat: "M d, Y H:i",
                time_24hr: true,
              }}
              onChange={([date]: any) => handleDateChange(date)}
            />
          </MDBox>
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <MDInput
            label="Subject"
            fullWidth
            variant="standard"
            name="subject"
            onChange={(e: any) => onChangeField(e, "subject")}
            value={requestData?.subject ? requestData.subject : ""}
            // error={errors && errors.description ? true : false}
            // helperText={errors && errors.description}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6}>
          <Autocomplete
            id="location"
            options={locationListData}
            value={requestData.location || null}
            getOptionLabel={(option: any) => option?.name}
            onChange={(event: any, newValue: any) => {
              setRequestData({ ...requestData, location: newValue });
            }}
            renderOption={(props: any, option: any) => (
              <li {...props} key={option.id}>
                {option.name}
              </li>
            )}
            renderInput={(params: any) => (
              <TextField {...params} variant="standard" label="Location" placeholder="Location" />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6}>
          {/* Populate all units from the selected location they are active in */}
          <Autocomplete
            id="units"
            options={unitListData}
            value={requestData.unit || null}
            getOptionLabel={(option: any) => option?.name}
            onChange={(event: any, newValue: any) => {
              setRequestData({ ...requestData, unit: newValue });
            }}
            renderOption={(props: any, option: any) => (
              <li {...props} key={option.id}>
                {option.name}
              </li>
            )}
            renderInput={(params: any) => (
              <TextField {...params} variant="standard" label="unit" placeholder="Unit" />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <FormControl component="fieldset">
            <FormGroup aria-label="position">
              <FormControlLabel
                value="end"
                control={
                  <Checkbox
                    checked={requestData?.multi_unit_operator || false}
                    name="multi_unit_operator"
                    onChange={handleSingleCheckbox}
                    inputProps={{ "aria-label": "controlled" }}
                  />
                }
                label="Is this impacting common space or multiple units?"
                labelPlacement="end"
              />
            </FormGroup>
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <MDTypography component="label" variant="button" fontWeight="regular" color="text">
            File
          </MDTypography>
          <Grid container>
            <Grid item xs={12} sm={8} md={8} lg={8}>
              <CustomDropZone
                multipleUpload={false}
                acceptedFiles={{
                  "image/*": [".gif", ".png", ".jpg", ".jpeg"],
                }}
                onDrop={(e: any) => onChangeImage(e, "file")}
              >
                Drop your image here, or Browse
              </CustomDropZone>
            </Grid>
            <Grid item xs={12} sm={4} md={4} lg={4}>
              {filePreview && (
                <Grid container justifyContent="center" alignItems="center" display="flex">
                  <Grid item className="imageWithDeleteIcon">
                    <img height={100} width={100} src={fileimg} alt="article" />
                    <MDIconButton
                      tooltipName="Delete"
                      aria-label="Delete"
                      className="removeBtnClass"
                      onClick={(e: any) => onImageRemove(e, "file")}
                    >
                      <Icon fontSize="small">close</Icon>
                    </MDIconButton>
                  </Grid>
                </Grid>
              )}
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <MDInput
            label="Description"
            fullWidth
            multiline
            variant="standard"
            rows={2}
            name="description"
            onChange={(e: any) => onChangeField(e, "description")}
            value={requestData?.description ? requestData.description : ""}
            // error={errors && errors.description ? true : false}
            // helperText={errors && errors.description}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <MDTypography variant="h6" sx={{ textDecoration: "underline" }}>
            Note: If issue is urgent, please call 123-122-1234.
          </MDTypography>
        </Grid>
      </Grid>
    </MDDialog>
  );
}

export default addUpdate;
