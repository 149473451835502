import React, { useState, useEffect } from "react";
import MDDialog from "components/MDDialog";
import MDInput from "components/MDInput";
import { FormControlLabel, Switch } from "@mui/material";
import Validations from "helper/validations";
import { saveMenu } from "store/thunk/rolesAndMenusThunk";
import { mainMenuRequest } from "types/custom-types";
import { useAppDispatch, useAppSelector } from "../../../../store/store";

function AddUpdate(props: any): JSX.Element {
  const dispatch = useAppDispatch();
  const { open, onClose, editMenu } = props;
  const [alias, setAlias] = useState("");
  const [order, setOrder] = useState("");
  const [displayMenu, setDisplayMenu] = useState("");
  const [description, setDescription] = useState("");
  const rolesMenusInfo = useAppSelector((state) => state.rolesAndMenusReducer);
  const [errors, setErrors] = useState<any>({});
  const [loading, setLoading] = useState<boolean>(false);
  const [defaultMenu, setDefaultMenu] = useState(false);

  const onSave = () => {
    const checkMenuOrder = rolesMenusInfo?.menus?.filter((val: any) => {
      if (editMenu?.menu_id) {
        return val.menu_id !== editMenu?.menu_id && val.menu_order === Number(order);
      }
      return val.menu_order === Number(order);
    });

    const requestData = {
      alias,
      display_menu: displayMenu,
      description,
      menu_order: order,
      order_exists: checkMenuOrder.length > 0,
    };
    const error = Validations.validateMenuForm(requestData);
    setErrors(error);
    if (!Object.keys(error).length) {
      setLoading(true);
    }
    if (!Object.keys(error).length) {
      setLoading(false);
      if (editMenu?.menu_id) {
        // update API call
        const reqObj: mainMenuRequest = {
          menuId: editMenu?.menu_id,
          alias,
          description,
          displayMenu,
          menuOrder: Number(order),
          defaultMenu,
        };
        dispatch(saveMenu(reqObj));
      } else {
        // Insert API call
        const reqObj: mainMenuRequest = {
          alias,
          description,
          displayMenu,
          menuOrder: Number(order),
          defaultMenu,
        };
        dispatch(saveMenu(reqObj));
      }
      onClose();
    }
  };

  useEffect(() => {
    if (editMenu) {
      setAlias(editMenu?.alias ? editMenu.alias : "");
      setDisplayMenu(editMenu?.display_menu ? editMenu.display_menu : "");
      setDescription(editMenu?.description ? editMenu.description : "");
      setOrder(editMenu?.menu_order ? editMenu.menu_order : "");
      setDefaultMenu(editMenu?.default ? editMenu.default : false);
    }
  }, [editMenu]);

  const dialogProps = {
    open,
    onClose,
    onSave,
    title: editMenu ? "Edit Menu" : "Add Menu",
    saveTbtText: editMenu && "Update",
    size: "sm",
    loading,
  };

  return (
    <MDDialog {...dialogProps}>
      <MDInput
        label="Alias"
        variant="standard"
        fullWidth
        required
        disabled={editMenu}
        name="alias"
        placeholder="Alias"
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => setAlias(e.target.value)}
        value={alias}
        error={(errors && errors.alias) || false}
        helperText={errors && errors.alias}
      />
      <MDInput
        type="Number"
        label="Menu Order"
        variant="standard"
        fullWidth
        required
        name="order"
        placeholder="Order"
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => setOrder(e.target.value)}
        value={order}
        error={(errors && errors.order) || false}
        helperText={errors && errors.order}
        sx={{ mt: 2 }}
      />
      <MDInput
        label="Display Menu"
        variant="standard"
        fullWidth
        required
        name="displayMenu"
        placeholder="Display Menu"
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => setDisplayMenu(e.target.value)}
        value={displayMenu}
        error={(errors && errors.displayMenu) || false}
        helperText={errors && errors.displayMenu}
        sx={{ mt: 2 }}
      />
      <MDInput
        label="Description"
        variant="standard"
        fullWidth
        name="description"
        placeholder="Description"
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => setDescription(e.target.value)}
        value={description}
        error={(errors && errors.description) || false}
        helperText={errors && errors.description}
        sx={{ mt: 2 }}
      />
      <FormControlLabel
        sx={{ mt: 2 }}
        control={
          <Switch
            checked={defaultMenu}
            onChange={(e: any) => setDefaultMenu(e.target.checked)}
            inputProps={{ "aria-label": "controlled" }}
          />
        }
        label="Default Menu"
        labelPlacement="end"
      />
    </MDDialog>
  );
}

export default AddUpdate;
