import { Card, Typography } from "@mui/material";
import MDBox from "components/MDBox";
import DataTable from "examples/Tables/DataTable";
import { OpenLocations } from "..";

function formatDate(dateString: string) {
  let formattedDate = "";
  if (dateString !== null && dateString.length) {
    const parts = dateString.split("-");
    const year = parts[0];
    const month = parts[1];
    const day = parts[2];
    formattedDate = `${month}/${day}/${year}`;
  }
  return dateString !== null && dateString.length ? formattedDate : "";
}

interface OpenLocationsUnmaturedProps {
  openUnmaturedLocation: OpenLocations[];
}

function OpenLocationsUnmatured({ openUnmaturedLocation }: OpenLocationsUnmaturedProps) {
  const tableColumns = [
    {
      Header: "ID",
      accessor: "id",
      width: "200px",
      export: true,
      disableCheck: true,
      checked: true,
    },
    {
      Header: "Opening Date",
      width: "200px",
      export: true,
      is_date: true,
      accessor: (d: any) => formatDate(d.open_date),
    },
    {
      Header: "Sola ID",
      accessor: "store_id",
      width: "200px",
      export: true,
      disableCheck: true,
      checked: true,
    },
    {
      Header: "Name",
      accessor: "name",
      width: "200px",
      export: true,
      disableCheck: true,
      checked: true,
    },
    {
      Header: "Status",
      accessor: "status",
      width: "200px",
      export: true,
      disableCheck: true,
      checked: true,
    },
    {
      Header: "City",
      accessor: "city",
      width: "200px",
      export: true,
      disableCheck: true,
      checked: true,
    },
    {
      Header: "State",
      accessor: "state",
      width: "200px",
      export: true,
      disableCheck: true,
      checked: true,
    },
    {
      Header: "Contact Name",
      accessor: "contact_name",
      width: "200px",
      export: true,
      disableCheck: true,
      checked: true,
    },
    {
      Header: "MSA",
      accessor: "msa",
      width: "200px",
      export: true,
      disableCheck: true,
      checked: true,
    },
    {
      Header: "Entity",
      accessor: "entity",
      width: "200px",
      export: true,
      disableCheck: true,
      checked: true,
    },
    {
      Header: "Country",
      accessor: "country",
      width: "200px",
      export: true,
      disableCheck: true,
      checked: true,
    },
  ];

  return (
    <MDBox mt={5}>
      {openUnmaturedLocation.length ? (
        <Card>
          <MDBox
            bgColor="dark"
            color="white"
            coloredShadow="dark"
            borderRadius="xl"
            alignItems="center"
            justifyContent="space-between"
            pt={1}
            pb={1}
            pl={2}
            pr={2}
            ml={2}
            mr={2}
            display="flex"
            mt={-3}
            className="page-header"
          >
            <p className="page-header-label">Unmatured Open Locations</p>
          </MDBox>

          <DataTable
            table={{
              columns: tableColumns,
              rows: openUnmaturedLocation,
            }}
            manualSort={false}
          />
        </Card>
      ) : (
        <MDBox mt={1} sx={{ minHeight: "200" }}>
          <Card
            variant="outlined"
            sx={{
              height: "200px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Typography variant="h5">No record found for these values</Typography>
          </Card>
        </MDBox>
      )}
    </MDBox>
  );
}

export default OpenLocationsUnmatured;
