import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useAppDispatch } from "store/store";
import Grid from "@mui/material/Grid";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import Validation from "helper/validations";
import General from "layouts/pages/sola-pros/connectAddUpdate/general";
import Contact from "layouts/pages/sola-pros/connectAddUpdate/contact";
import Services from "layouts/pages/sola-pros/connectAddUpdate/services";
import Location from "layouts/pages/sola-pros/connectAddUpdate/location";
import RentManager from "layouts/pages/sola-pros/connectAddUpdate/rent-manager";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Sidenav from "layouts/pages/sola-pros/connectAddUpdate/sidenav/connect";
import CustomBackButton from "components/CustomBackButton";
import { createSolaProInCognito, getAllConnectServices } from "store/thunk/solaProsThunk";
import { scrollToErrorByClass, systemAdmin } from "helper/services";
import { setSolaProDetails } from "store/slices/solaProSlice";
import Webpage from "layouts/pages/sola-pros/connectAddUpdate/webpage";

function ConnectAddUpdate(props: any): JSX.Element {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const locationState: any = useLocation();
  const { onClose, pageNo, perPage, search, sortBy, locationId } = props;
  const [errors, setErrors] = useState<any>({});
  const [solaProDetail, setSolaProDetail] = useState<any>({ create_webpage: true });
  const [isUpdate, setIsUpdate] = useState<any>(false);
  const [imagePreview, setImagePreview] = useState<any>([]);

  useEffect(() => {
    if (locationState?.state?.id) {
      setIsUpdate(true);
    } else {
      setSolaProDetail({ create_webpage: true });
      setImagePreview([]);
      dispatch(getAllConnectServices({}));
    }
  }, [locationState]);

  const onCallBack = () => {
    dispatch(setSolaProDetails({}));
    navigate(`/sola-pros`);
  };

  const onSave = () => {
    const tempSolaProDetails = JSON.parse(JSON.stringify(solaProDetail));
    const errors: any = Validation.validateNewSolaProForm(tempSolaProDetails);
    setErrors(errors);

    // Set Satus conditios
    // if (tempSolaProDetails?.status === "closed") {
    //   if (!tempSolaProDetails.inactive_reason) {
    //     tempSolaProDetails.inactive_reason = "left";
    //   }
    // } else {
    //   tempSolaProDetails.inactive_reason = "left";
    // }
    tempSolaProDetails.status = "open";
    if (!Object.keys(errors).length) {
      const req = {
        solaProDetail: tempSolaProDetails,
        onClose,
        isUpdate,
        pageNo,
        perPage,
        search,
        sortBy,
        locationId,
        callback: onCallBack,
      };
      dispatch(createSolaProInCognito(req));
    } else {
      scrollToErrorByClass(errors);
    }
  };

  const onChangeImage = async (files: any, key: any) => {
    if (files[0]) {
      const file = files[0];
      const reader = new FileReader();
      reader.onload = function (e) {
        const images: any = imagePreview;
        images[`image_${key}_url`] = e.target.result;
        setSolaProDetail({
          ...solaProDetail,
          [`image_${key}_url`]: e.target.result,
          [`image_${key}`]: file,
        });
        setImagePreview(images);
      };
      reader.readAsDataURL(file);
    }
  };

  const onImageRemove = async (e: any, key: any) => {
    e.stopPropagation();
    const deleteflag = `delete_image_${key}`;
    setSolaProDetail({
      ...solaProDetail,
      [`image_${key}_url`]: null,
      [`image_${key}`]: null,
      [deleteflag]: 1,
    });
    const images: any = imagePreview;

    images[`image_${key}_url`] = null;
    setImagePreview(images);
  };

  const onChangeAutoComplete = (name: string, value: any) => {
    setSolaProDetail({ ...solaProDetail, [name]: value });
  };

  const onChangeBiography = (value: any, delta: any, source: any) => {
    if (source === "user") {
      setSolaProDetail({ ...solaProDetail, biography: value });
    }
  };

  const onChangeField = (event: any) => {
    setSolaProDetail({ ...solaProDetail, [event.target.name]: event.target.value });
  };

  const onCheckboxChange = (event: any) => {
    setSolaProDetail({ ...solaProDetail, [event.target.name]: event.target.checked });
  };

  const onChangeMultipleField = (event: any, key: any) => {
    const data = solaProDetail[key] ? JSON.parse(JSON.stringify(solaProDetail[key])) : {};
    data[event.target.name] = event.target.value;
    setSolaProDetail({ ...solaProDetail, [key]: { ...data } });
  };

  const attr = {
    solaProDetail,
    imagePreview,
    isUpdate,
    errors,
    onChangeField,
    onChangeAutoComplete,
    onChangeMultipleField,
    onChangeBiography,
    onCheckboxChange,
    onChangeImage,
    setSolaProDetail,
    setImagePreview,
    onImageRemove,
  };
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox mx={2} onClick={onCallBack}>
        <CustomBackButton
          label="Back To Sola Pros"
          historyState={{
            prevPath: "/sola-cms/cms-sola-pros",
          }}
        />
      </MDBox>
      <Grid container spacing={3} mt={1}>
        <Grid item xs={12} lg={3}>
          <Sidenav />
        </Grid>
        <Grid item xs={12} lg={9}>
          <MDBox mb={3}>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <General {...attr} />
              </Grid>
              <Grid item xs={12}>
                <Contact {...attr} />
              </Grid>
              <Grid item xs={12}>
                <Location {...attr} />
              </Grid>
              <Grid item xs={12}>
                <Webpage {...attr} />
              </Grid>
              <Grid item xs={12}>
                <Services {...attr} />
              </Grid>
              {systemAdmin() && (
                <Grid item xs={12}>
                  <RentManager {...attr} />
                </Grid>
              )}
              <Grid item xs={12} textAlign="right">
                <MDButton
                  variant="gradient"
                  color="info"
                  size="small"
                  onClick={onSave}
                  className="fixed-button"
                >
                  {isUpdate ? "Update" : "Save"}
                </MDButton>
              </Grid>
            </Grid>
          </MDBox>
        </Grid>
      </Grid>
    </DashboardLayout>
  );
}

export default ConnectAddUpdate;
