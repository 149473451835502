import { useEffect, useState } from "react";
import MDBox from "components/MDBox";
import { Card, Grid } from "@mui/material";
import { useAppDispatch } from "store/store";
import { updateLocationRmData } from "store/thunk/locationThunk";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import { scrollToErrorByClass } from "helper/services";
import Validations from "helper/validations";
import Correspondence from "./correspondence";
import BusinessInfo from "./businessInfo";
import BankingInfo from "./bankingInfo";
import DatesFees from "./datesFees";
import Selfservicebilling from "./selfservicebilling";
import Sidenav from "./sidenav";
import ViewDetails from "./ViewDetails";
import ESignTemplates from "./ESignTemplates";

function Index(props: any): JSX.Element {
  const dispatch = useAppDispatch();
  const {
    locationDetail,
    setLocationDetail,
    onChangeField,
    onChangeEditor,
    getAccessTypePermission,
  } = props;
  console.log("---------", locationDetail);

  const [errors, setErrors] = useState<any>({});
  const [enableRm, setEnableRm] = useState<any>(true);
  const [detailsPopup, setDetailsPopup] = useState<any>(false);
  const [updateData, setUpdateData] = useState<any>(null);

  useEffect(() => {
    setEnableRm(true);
  }, []);

  const onSaveRmData = () => {
    const error = Validations.validateRmTab(locationDetail);
    setErrors(error);
    if (!Object.keys(error).length) {
      dispatch(updateLocationRmData(locationDetail));
    } else {
      scrollToErrorByClass(error);
    }
  };
  const enableRmSection = () => {
    setEnableRm(true);
  };

  const onLinkedToRom = () => {
    setDetailsPopup(true);
  };

  const attr = {
    rmDetails: locationDetail,
    onChangeField,
    onChangeEditor,
    errors,
    setErrors,
    setRmDetails: setLocationDetail,
    getAccessTypePermission,
  };
  const closeDetailPopup = () => {
    setUpdateData("");
    setDetailsPopup(false);
  };

  return (
    <>
      {!enableRm && (
        <MDBox mt={7}>
          <Card>
            <MDBox padding={2} textAlign="center">
              <MDTypography variant="h5">Enable Rent Manager Section?</MDTypography>
              <MDTypography variant="body" fontSize={16}>
                This will allow you to use the eLeasing festures of Sola Connect.
              </MDTypography>
            </MDBox>
            <MDBox padding={2} textAlign="center">
              <MDButton onClick={enableRmSection} variant="outlined" color="secondary" size="small">
                Enable eLease
              </MDButton>
            </MDBox>
          </Card>
        </MDBox>
      )}
      {(locationDetail?.is_rm_enable || enableRm) && locationDetail?.rmlinkedData?.id && (
        <Grid container spacing={3} mt={1}>
          <Grid item xs={12} lg={3}>
            <Sidenav />
          </Grid>
          <Grid item xs={12} lg={9}>
            <MDBox mb={3}>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <Correspondence {...attr} />
                </Grid>
                <Grid item xs={12}>
                  <BusinessInfo {...attr} />
                </Grid>
                <Grid item xs={12}>
                  <BankingInfo {...attr} />
                </Grid>
                <Grid item xs={12}>
                  <DatesFees {...attr} />
                </Grid>
                <Grid item xs={12}>
                  <Selfservicebilling {...attr} />
                </Grid>
                <Grid item xs={12}>
                  <ESignTemplates {...attr} />
                </Grid>
                <Grid item xs={12} display="flex" justifyContent="right" alignItems="flex-start">
                  <MDButton
                    onClick={onSaveRmData}
                    variant="gradient"
                    color="info"
                    size="small"
                    className="fixed-button"
                    disabled={getAccessTypePermission("location_rentmanager", "edit_access")}
                  >
                    Save
                  </MDButton>
                </Grid>
              </Grid>
            </MDBox>
          </Grid>
        </Grid>
      )}
      {!locationDetail?.rmlinkedData && (
        <Grid
          container
          spacing={0}
          direction="column"
          alignItems="center"
          justifyContent="center"
          textAlign="center"
          style={{ minHeight: "70vh", padding: "10px" }}
        >
          <MDTypography variant="body2" sx={{ mb: 2 }}>
            This Location is not linked to a Rent Manager database.
          </MDTypography>
          <Grid item xs={3}>
            <MDButton
              onClick={onLinkedToRom}
              variant="gradient"
              color="info"
              size="small"
              className="xs-small"
              style={{ minHeight: "70vh", padding: "10px", margin: "10px" }}
            >
              Link to Existing
            </MDButton>
            <MDButton
              // onClick={onRequestSMS}
              variant="gradient"
              color="info"
              size="small"
              className="xs-small"
            >
              Request New
            </MDButton>
          </Grid>
        </Grid>
      )}
      {detailsPopup && (
        <ViewDetails
          open={detailsPopup}
          updateData={updateData}
          onClose={() => {
            closeDetailPopup();
          }}
          locationId={locationDetail?.location_id}
          cmsLocationId={locationDetail?.cms_location_id}
        />
      )}
    </>
  );
}

export default Index;
