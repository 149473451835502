/* eslint-disable no-unsafe-optional-chaining */
/* eslint-disable no-unused-vars */
import React from "react";
import MDBox from "components/MDBox";
import ReportsLineChart from "examples/Charts/LineCharts/ReportsLineChart";
import { generateChartLabelsAndData } from "helper/reports";
import ScorecardDateInput from "layouts/pages/scorecard-dashboard/components/scorecardDateInput";
import { SelectedDateRange } from "layouts/pages/scorecard-dashboard";
import { Button, Card, Grid, Typography } from "@mui/material";

import DataTable from "examples/Tables/DataTable";
import { ScorecardDate } from "layouts/pages/scorecard-dashboard/scorecard";
import { CurrentAndLastYearRange, LeadsDensityData, SelectedDate } from "..";

interface leadsInputProps {
  selectedDate: SelectedDate;
  // eslint-disable-next-line no-unused-vars
  onChangeDateField: (event: Date) => void;
  //   dateArr: ScorecardDate[];
  selectedDateRange: SelectedDateRange;
  // eslint-disable-next-line no-unused-vars
  setSelectedDateRange: (selectedDateRange: SelectedDateRange) => void;
  // eslint-disable-next-line no-unused-vars
  setSelectedDate: (selectedDate: SelectedDate) => void;
  graphDescription: string;
  graphTitle: string;
  scoercardDateRange: ScorecardDate[];
  currentDateCheckBox: boolean;
  setCurrentDateCheckBox: (currentDateCheckBox: boolean) => void;
  leadsCurrentYear: CurrentAndLastYearRange;
  leadsLastYear: CurrentAndLastYearRange;
  // eslint-disable-next-line react/require-default-props
  setVisiblity: (visibility: boolean) => void;
  selectedlead: LeadsDensityData;
  year: string[];
}
function LeadsData({
  selectedDate,
  onChangeDateField,
  selectedDateRange,
  setSelectedDateRange,
  graphTitle,
  graphDescription,
  setSelectedDate,
  scoercardDateRange,
  setCurrentDateCheckBox,
  currentDateCheckBox,
  leadsCurrentYear,
  leadsLastYear,
  setVisiblity,
  selectedlead,
  year,
}: leadsInputProps) {
  const tableColumns = [
    {
      Header: "location_id",
      accessor: "location_id",
      width: "200px",
      export: true,
      disableCheck: true,
      checked: true,
    },
    {
      Header: "name",
      accessor: "name",
      width: "200px",
      export: true,
      disableCheck: true,
      checked: true,
    },
    {
      Header: "leads",
      accessor: "leads",
      width: "200px",
      export: true,
      disableCheck: true,
      checked: true,
    },
  ];

  return (
    <Card>
      <MDBox sx={{ padding: "15px" }}>
        <MDBox style={{ display: "flex", justifyContent: "space-between" }}>
          <ScorecardDateInput
            dateArr={scoercardDateRange}
            setCurrentDateCheckBox={setCurrentDateCheckBox}
            currentDateCheckBox={currentDateCheckBox}
            selectedDate={selectedDate}
            onChangeDateField={onChangeDateField}
            selectedDateRange={selectedDateRange}
            setSelectedDateRange={setSelectedDateRange}
            setSelectedDate={setSelectedDate}
          />
          <Button size="large" style={{ marginBottom: "50px" }} onClick={() => setVisiblity(false)}>
            Back
          </Button>
        </MDBox>

        <MDBox sx={{ marginTop: "40px", marginBottom: "40px" }}>
          {selectedlead ? (
            <ReportsLineChart
              color="success"
              title={graphTitle}
              description={graphDescription}
              date={year[0]}
              chart={generateChartLabelsAndData(
                selectedlead,
                leadsCurrentYear.start,
                leadsCurrentYear.end,
                "current_year"
              )}
            />
          ) : (
            <MDBox mt={1} sx={{ minHeight: "200" }}>
              <Card
                variant="outlined"
                sx={{
                  height: "200px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Typography variant="h5">No record found for these values</Typography>
              </Card>
            </MDBox>
          )}
        </MDBox>

        <MDBox sx={{ marginTop: "40px", marginBottom: "40px" }}>
          {selectedlead ? (
            <ReportsLineChart
              color="success"
              title={graphTitle}
              description={graphDescription}
              date={year[1]}
              chart={generateChartLabelsAndData(
                selectedlead,
                leadsLastYear.start,
                leadsLastYear.end,
                "last_year"
              )}
            />
          ) : (
            <MDBox mt={1} sx={{ minHeight: "200" }}>
              <Card
                variant="outlined"
                sx={{
                  height: "200px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Typography variant="h5">No record found for these values</Typography>
              </Card>
            </MDBox>
          )}
        </MDBox>

        <Card sx={{ marginBottom: "40px" }}>
          <MDBox
            bgColor="dark"
            color="white"
            coloredShadow="dark"
            borderRadius="xl"
            alignItems="center"
            justifyContent="space-between"
            pt={1}
            pb={1}
            pl={2}
            pr={2}
            ml={2}
            mr={2}
            display="flex"
            mt={-3}
            className="page-header"
          >
            <p className="page-header-label">
              {graphTitle} for year: {year[0]}
            </p>
          </MDBox>
          {selectedlead ? (
            <DataTable
              table={{
                columns: tableColumns,
                rows: selectedlead?.current_year.slice(
                  leadsCurrentYear.start,
                  leadsCurrentYear.end
                ),
              }}
              manualSort={false}
            />
          ) : (
            <MDBox mt={1} sx={{ minHeight: "200" }}>
              <Card
                variant="outlined"
                sx={{
                  height: "200px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Typography variant="h5">No record found for these values</Typography>
              </Card>
            </MDBox>
          )}
        </Card>
        <MDBox
          bgColor="dark"
          color="white"
          coloredShadow="dark"
          borderRadius="xl"
          alignItems="center"
          justifyContent="space-between"
          pt={1}
          pb={1}
          pl={2}
          pr={2}
          ml={2}
          mr={2}
          display="flex"
          mt={-3}
          className="page-header"
        >
          <p className="page-header-label">
            {graphTitle} for year: {year[1]}
          </p>
        </MDBox>
        {selectedlead ? (
          <DataTable
            table={{
              columns: tableColumns,
              rows: selectedlead?.last_year.slice(leadsLastYear.start, leadsLastYear.end),
            }}
            manualSort={false}
          />
        ) : (
          <MDBox mt={1} sx={{ minHeight: "200" }}>
            <Card
              variant="outlined"
              sx={{
                height: "200px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Typography variant="h5">No record found for these values</Typography>
            </Card>
          </MDBox>
        )}
      </MDBox>
    </Card>
  );
}

export default LeadsData;
