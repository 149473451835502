import { useEffect, useState, SyntheticEvent } from "react";
import {
  AccordionDetails,
  AccordionSummary,
  Card,
  Checkbox,
  FormControlLabel,
  Grid,
  Icon,
  IconButton,
  Paper,
  TextField,
  Typography,
} from "@mui/material";
import MDButton from "components/MDButton";
import Autocomplete from "@mui/material/Autocomplete";
import MDBox from "components/MDBox";
import Accordion from "@mui/material/Accordion";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import { getRolesList, getMenusList, getRoleDetails } from "store/thunk/rolesAndMenusThunk";
import { ConnectAccessRequest, RoleDetailsRequest } from "types/custom-types";
import { getConnectUsers, saveConnectuser } from "store/thunk/connectUserThunk";
import { notificationFail } from "store/slices/notificationSlice";
import { useAppDispatch, useAppSelector } from "store/store";
import CustomAutoSearch from "components/CustomAutoSearch";
import {
  getAssociationOwnersAndUsers,
  getAuthUser,
  getPermission,
  userRoles,
} from "helper/services";

function ConnectAccess(): JSX.Element {
  const dispatch = useAppDispatch();
  const usersInfo = useAppSelector((state) => state.connectUsersReducer);
  const rolesInfo = useAppSelector((state) => state.rolesAndMenusReducer);
  const [usersList, setUsersList] = useState<any>([]);
  const [rolesList, setRolesList] = useState<any>([]);
  const [selectUser, setSelectUser] = useState<any>(null);
  const [selectRole, setSelectRole] = useState<any>(null);
  const [currentRoleMenus, setCurrentRoleMenus] = useState<any>(null);
  const [additionalMenus, setAdditionalMenus] = useState<any>(null);
  const [selectedMenuIds, setSelectedMenuIds] = useState<any>([]);
  const [openAdditionalList, setOpenAdditionalList] = useState(false);
  const usersData: any = getAuthUser();

  const [expanded, setExpanded] = useState<string | false>(false);

  const handleChange = (panel: string) => (event: SyntheticEvent, isExpanded: boolean) => {
    event.preventDefault();
    setExpanded(isExpanded ? panel : false);
  };

  useEffect(() => {
    dispatch(getConnectUsers(null));
    dispatch(getRolesList());
    dispatch(getMenusList());
  }, []);

  useEffect(() => {
    const usersRows: any = [];
    if (usersInfo?.connectUsers?.length) {
      usersInfo?.connectUsers.map((user: any) => {
        const userData = {
          user_id: user.user_id,
          label: user.login_email,
          first_name: user.first_name,
          last_name: user.last_name,
          role_name: user.role_name,
          connectAccess: user.connect_accesses,
        };
        usersRows.push(userData);
        return true;
      });
      setUsersList(usersRows);

      if (selectUser) {
        setSelectUser(usersRows.find((userData: any) => userData.user_id === selectUser.user_id));
      }
    }
  }, [usersInfo]);

  useEffect(() => {
    const currentMenuIdsArr: any = [];
    const additionalMenudata: any = [];

    // set selected role
    if (rolesInfo && rolesInfo.roleSelected) {
      setSelectRole(rolesInfo.roleSelected);
    }

    // loop for all roles for displaying role's list
    if (rolesInfo && rolesInfo.roles && rolesInfo.roles?.length) {
      const rolesRows: any = [];

      rolesInfo.roles.map((role: any) => {
        const roleData = {
          label: role.name,
        };
        rolesRows.push(roleData);
        return true;
      });
      setRolesList(rolesRows);
    }

    // set current role menus from roledetails
    if (rolesInfo && rolesInfo.roleDetails && rolesInfo.roleDetails?.length) {
      rolesInfo.roleDetails.map((role: any) => {
        currentMenuIdsArr.push(role.menu_id);
        if (role.children && role.children?.length) {
          role.children.map((child: any) => {
            currentMenuIdsArr.push(child.menu_id);

            return true;
          });
        }
        return true;
      });
      setCurrentRoleMenus(rolesInfo.roleDetails);
    }

    // loop for all menus
    const selectedArr: any = [...selectedMenuIds];
    if (rolesInfo && rolesInfo.menus && rolesInfo.menus?.length) {
      const selectedUser: any = { ...selectUser };

      // If additional selcted values
      if (rolesInfo.additionalDetails && rolesInfo.additionalDetails.length) {
        setOpenAdditionalList(true);
        rolesInfo.additionalDetails.map((parentMenu: any) => {
          if (!selectedArr.includes(parentMenu.menu_id)) {
            selectedArr.push(parentMenu.menu_id);
          }
          if (parentMenu.children && parentMenu.children?.length) {
            parentMenu.children.map((childs: any) => {
              if (!selectedArr.includes(childs.menu_id)) {
                selectedArr.push(childs.menu_id);
              }
              return true;
            });
          }
          return true;
        });
      }

      rolesInfo.menus.map((parentMenu: any) => {
        const mainmenus: any = { ...parentMenu };
        const childmenus: any = [];
        if (parentMenu.child_menu && parentMenu.child_menu?.length) {
          parentMenu.child_menu.map((childs: any) => {
            if (currentMenuIdsArr.indexOf(childs.menu_id) === -1) {
              childmenus.push(childs);
              // set selected menus based on new datas -- on change of role / user etc...
              if (
                selectedUser &&
                selectedUser.connectAccess &&
                selectedUser.connectAccess?.length
              ) {
                const menuInAccess: any = selectedUser.connectAccess.find(
                  (accessMenuId: any) => accessMenuId.menu_id === childs.menu_id
                );
                if (menuInAccess && !menuInAccess.role_id) {
                  if (selectedArr.indexOf(childs.menu_id) === -1) selectedArr.push(childs.menu_id);
                  if (selectedArr.indexOf(parentMenu.menu_id) === -1)
                    selectedArr.push(parentMenu.menu_id);
                }
              }
            }

            return true;
          });
          if (childmenus?.length) {
            mainmenus.child_menu = childmenus;
            additionalMenudata.push(mainmenus);
          }
        }
        setSelectedMenuIds(selectedArr);

        if (
          currentMenuIdsArr.indexOf(parentMenu.menu_id) === -1 &&
          parentMenu.child_menu?.length <= 0
        ) {
          additionalMenudata.push(parentMenu);
          if (selectedUser && selectedUser.connectAccess && selectedUser.connectAccess?.length) {
            const parentmenuInAccess: any = selectedUser.connectAccess.find(
              (accessMenuId: any) => accessMenuId.menu_id === parentMenu.menu_id
            );

            if (parentmenuInAccess && !parentmenuInAccess.role_id) {
              if (selectedArr.indexOf(parentMenu.menu_id) === -1)
                selectedArr.push(parentMenu.menu_id);
            }
          }
        }
        return true;
      });
      setAdditionalMenus(additionalMenudata);
    }
  }, [rolesInfo]);

  const onSave = () => {
    const roleId: any = rolesInfo.roles.find((role: any) => role.name === selectRole);
    const requestParam: ConnectAccessRequest = {
      roleId: (roleId && roleId.role_id) || "",
      userId: (selectUser && selectUser.user_id) || "",
      menuIds: selectedMenuIds || [],
    };
    dispatch(saveConnectuser(requestParam));
  };

  const onChangeUser = (event: any, value: any) => {
    setSelectedMenuIds([]);
    setSelectUser(value);
    if (value && value?.user_id) {
      const request: RoleDetailsRequest = {
        user_id: value?.user_id,
      };
      dispatch(getRoleDetails(request));
    }
  };

  const onChangeRole = (event: any, value: any) => {
    console.log(usersList);
    if (selectRole !== userRoles.SOLA_PRO_USER && value?.label === userRoles.SOLA_PRO_USER) {
      dispatch(
        notificationFail(
          "You can't change the user role to Sola Pro from here. If you want to create new Sola Pro User, please to go to Sola Pro menu."
        )
      );
      return;
    }
    setSelectedMenuIds([]);
    setSelectRole(value);
    if (value && value.label) {
      const request: RoleDetailsRequest = {
        name: value?.label,
      };
      dispatch(getConnectUsers(request));
    }
  };

  const onSelectAdditionalMenu = (
    event: any,
    menuId: number,
    childMenu: any = [],
    parentMenuId: number = null
  ) => {
    const selectedMenusArr: any = JSON.parse(JSON.stringify(selectedMenuIds));
    if (childMenu?.length) {
      if (selectedMenusArr.indexOf(menuId) === -1) {
        childMenu.map((child: any) => {
          if (selectedMenusArr.indexOf(child.menu_id) === -1) {
            selectedMenusArr.push(child.menu_id);
          }
          return true;
        });
      } else {
        childMenu.map((child: any) => {
          if (selectedMenusArr.indexOf(child.menu_id) !== -1) {
            selectedMenusArr.splice(selectedMenusArr.indexOf(child.menu_id), 1);
          }
          return true;
        });
      }
    }

    if (selectedMenusArr.indexOf(menuId) === -1) {
      selectedMenusArr.push(menuId);

      if (parentMenuId && selectedMenusArr.indexOf(parentMenuId) === -1)
        selectedMenusArr.push(parentMenuId);
    } else {
      selectedMenusArr.splice(selectedMenusArr.indexOf(menuId), 1);
      if (parentMenuId && selectedMenusArr.indexOf(parentMenuId) !== -1) {
        selectedMenusArr.splice(selectedMenusArr.indexOf(parentMenuId), 1);

        const mainMenu: any = rolesInfo.menus.find(
          (parentMenu: any) => parentMenu.menu_id === parentMenuId
        );
        if (mainMenu && mainMenu.child_menu) {
          mainMenu.child_menu.map((childMenuData: any) => {
            if (
              selectedMenusArr.indexOf(childMenuData.menu_id) !== -1 &&
              selectedMenusArr.indexOf(parentMenuId) === -1
            ) {
              selectedMenusArr.push(parentMenuId);
            }
            return true;
          });
        }
      }
    }
    setSelectedMenuIds(selectedMenusArr);
  };

  const handleAdditionalList = () => {
    setOpenAdditionalList(!openAdditionalList);
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={4} pb={3}>
        <Card sx={{ minHeight: 300 }}>
          <MDBox
            bgColor="dark"
            color="white"
            coloredShadow="dark"
            borderRadius="xl"
            alignItems="center"
            justifyContent="space-between"
            pt={1}
            pb={1}
            pl={2}
            pr={2}
            ml={2}
            mr={2}
            display="flex"
            mt={-3}
            className="page-header"
          >
            <p className="page-header-label">Connect Permissions</p>
          </MDBox>
          <Grid container spacing={2} sx={{ mt: 4, ml: 2 }}>
            <Grid item xs={12} sm={12} md={6}>
              <MDBox>
                <CustomAutoSearch
                  idKey="user_id"
                  nameKey="login_email"
                  fieldLabel="Users"
                  apiName="connect_users"
                  responseKey="connect_users"
                  name="connect_users"
                  onChange={(event: any, value: any) => onChangeUser(event, value)}
                  value={selectUser || null}
                  slsAPI
                  usersData={getAssociationOwnersAndUsers(usersData?.roleDetails?.franchisee_owner)}
                  createNewLink={
                    getPermission("users", "users_manage") ? "/users-&-roles/all-users" : ""
                  }
                />
              </MDBox>
            </Grid>
            <Grid item xs={12} sm={12} md={6} sx={{ mt: 2 }}>
              {selectUser && (
                <MDBox>
                  <Autocomplete
                    disablePortal
                    id="roles"
                    className="user-access-dropdown"
                    value={selectRole}
                    disabled={selectRole === userRoles.SOLA_PRO_USER ? true : false}
                    options={rolesList}
                    onChange={(event: any, value: any) => onChangeRole(event, value)}
                    renderInput={(params: any) => <TextField {...params} label="Roles" />}
                  />
                </MDBox>
              )}
            </Grid>
          </Grid>
          <Grid container spacing={2} sx={{ p: 4 }}>
            {selectUser &&
              currentRoleMenus &&
              currentRoleMenus.map((menu: any) => (
                <Grid item md={4} lg={3} xs={12} key={menu.menu_id}>
                  <MDBox key={menu.menu_id}>
                    {menu.children && menu.children?.length > 0 ? (
                      <Accordion
                        key={menu.menu_id}
                        expanded={expanded === menu.menu_id}
                        onChange={handleChange(menu.menu_id)}
                      >
                        <AccordionSummary
                          key={menu.menu_id}
                          expandIcon={
                            menu.children && menu.children?.length > 0 && <ExpandMoreIcon />
                          }
                          aria-controls="panel3bh-content"
                          id="panel3bh-header"
                        >
                          <FormControlLabel
                            key={menu.menu_id}
                            label={menu.display_menu}
                            control={<Checkbox checked disabled />}
                          />
                        </AccordionSummary>
                        {menu.children && menu.children?.length > 0 && (
                          <AccordionDetails key={menu.menu_id}>
                            {menu.children.map((childmenu: any) => (
                              <FormControlLabel
                                key={childmenu.menu_id}
                                label={childmenu.display_menu}
                                control={<Checkbox checked disabled />}
                              />
                            ))}
                          </AccordionDetails>
                        )}
                      </Accordion>
                    ) : (
                      <Paper sx={{ padding: "12px" }}>
                        <FormControlLabel
                          key={menu.menu_id}
                          label={menu.display_menu}
                          control={<Checkbox checked disabled />}
                        />
                      </Paper>
                    )}
                  </MDBox>
                </Grid>
              ))}
          </Grid>
          <Grid container spacing={2} sx={{ p: 4 }}>
            {selectUser && (
              <>
                <Grid item md={12} xs={12}>
                  <Typography
                    className="additional_menu_title"
                    component="h6"
                    onClick={() => handleAdditionalList()}
                  >
                    <IconButton>
                      <Icon>{openAdditionalList ? "cancel_sharp" : "add_box"}</Icon>
                    </IconButton>
                    Additional Menus
                  </Typography>
                </Grid>
                {openAdditionalList &&
                  additionalMenus &&
                  additionalMenus.map((parent: any) => (
                    <Grid item md={4} lg={3} xs={12} key={parent.menu_id}>
                      <MDBox key={parent.menu_id}>
                        {parent.child_menu && parent.child_menu?.length > 0 ? (
                          <Accordion
                            key={parent.menu_id}
                            expanded={expanded === `additional+${parent.menu_id}`}
                            onChange={handleChange(`additional+${parent.menu_id}`)}
                          >
                            <AccordionSummary
                              key={parent.menu_id}
                              expandIcon={
                                parent.child_menu &&
                                parent.child_menu?.length > 0 && <ExpandMoreIcon />
                              }
                              aria-controls="panel3bh-content"
                              id="panel3bh-header"
                            >
                              <FormControlLabel
                                key={parent.menu_id}
                                label={parent.display_menu}
                                control={
                                  <Checkbox
                                    key={parent.menu_id}
                                    checked={selectedMenuIds.includes(parent.menu_id)}
                                    onClick={(event: any) =>
                                      onSelectAdditionalMenu(
                                        event,
                                        parent?.menu_id,
                                        parent.child_menu
                                      )
                                    }
                                  />
                                }
                              />
                            </AccordionSummary>
                            {parent.child_menu && parent.child_menu?.length > 0 && (
                              <AccordionDetails key={parent.menu_id}>
                                {parent.child_menu.map((childmenu: any) => (
                                  <FormControlLabel
                                    key={childmenu.menu_id}
                                    label={childmenu.display_menu}
                                    control={
                                      <Checkbox
                                        key={childmenu.menu_id}
                                        checked={selectedMenuIds.includes(childmenu.menu_id)}
                                        onChange={(event: any) =>
                                          onSelectAdditionalMenu(
                                            event,
                                            childmenu?.menu_id,
                                            [],
                                            parent.menu_id
                                          )
                                        }
                                      />
                                    }
                                  />
                                ))}
                              </AccordionDetails>
                            )}
                          </Accordion>
                        ) : (
                          <Paper sx={{ padding: "12px" }}>
                            <FormControlLabel
                              key={parent.menu_id}
                              label={parent.display_menu}
                              control={
                                <Checkbox
                                  key={parent.menu_id}
                                  checked={selectedMenuIds.includes(parent.menu_id)}
                                  onChange={(event: any) =>
                                    onSelectAdditionalMenu(event, parent?.menu_id)
                                  }
                                />
                              }
                            />
                          </Paper>
                        )}
                      </MDBox>
                    </Grid>
                  ))}
              </>
            )}
          </Grid>
          {selectUser && selectRole && (
            <Grid container className="connect-access-save-button">
              <Grid
                item
                md={12}
                sx={{ mb: 2, mr: 2, ml: 2 }}
                display="flex"
                justifyContent="flex-end"
              >
                <MDButton
                  variant="gradient"
                  color="info"
                  sx={{ maxWidth: "100px" }}
                  size="small"
                  onClick={() => onSave()}
                >
                  Update
                </MDButton>
              </Grid>
            </Grid>
          )}
        </Card>
      </MDBox>
    </DashboardLayout>
  );
}

export default ConnectAccess;
