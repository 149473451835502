import { createAsyncThunk } from "@reduxjs/toolkit";
import { notificationFail, notificationSuccess } from "store/slices/notificationSlice";
import { setLoading } from "store/slices/authSlice";
import { setImportDealDataList, setImportDealList } from "store/slices/deal-import-slice";
import Messages from "helper/messages";
import { getFilterURL, getAssociationLocations } from "helper/services";
import slsApiClient from "config/slsApiClient";

export const getAllImportedDealsList = createAsyncThunk(
  "getAllImportedDealsList",
  async (_request: any, { dispatch }) => {
    dispatch(setLoading(true));
    try {
      const page = _request?.pageIndex || 0;
      const size = _request?.pageSize || 50;
      const sortBy = _request?.sort || [];
      const search = _request?.search || "";
      const filterBy = _request?.filterData || [];

      let url = `get-import-deals?page=${page}&per_page=${size}`;
      if (search) {
        url += `&search=${search}`;
      }
      if (sortBy?.length) {
        url += `&field=${sortBy[0]?.id}&order_by=${sortBy[0]?.desc ? "desc" : "asc"}`;
      }
      // Apply advance filter
      if (filterBy?.length) {
        url = getFilterURL(url, filterBy);
      }

      const response = await slsApiClient().get(url, {
        params: getAssociationLocations(),
      });
      dispatch(setLoading(false));
      if (response?.data?.status) {
        dispatch(setImportDealList(response?.data));
      } else {
        dispatch(notificationFail(response?.data?.message || Messages.ERROR.NO_DATA_FOUND));
      }
    } catch (error: any) {
      dispatch(setLoading(false));
      dispatch(notificationFail(error?.response?.data?.message || Messages.ERROR.NO_DATA_FOUND));
    }
  }
);
export const saveImportDeal = createAsyncThunk(
  "saveImportDeal",
  async (_request: any, { dispatch }) => {
    const { pageNo, onClose, perPage, search, sortBy, filterData, formData } = _request;
    dispatch(setLoading(true));
    try {
      const response = await slsApiClient().post(`import-deals`, formData);
      if (response && response?.data?.status) {
        dispatch(notificationSuccess(response?.data?.message || "Deals Import in progress."));
        dispatch(
          getAllImportedDealsList({
            pageSize: perPage,
            pageIndex: pageNo,
            search,
            sort: sortBy,
            filterData,
          })
        );
        if (onClose) {
          onClose();
        }
      } else {
        dispatch(setLoading(false));
        dispatch(notificationFail(response?.data?.message || "Error while import deals"));
      }
    } catch (error: any) {
      dispatch(setLoading(false));
      dispatch(notificationFail(error?.response?.data?.message || "Error while import deals"));
    }
  }
);
export const getImportDataListById = createAsyncThunk(
  "getImportDataListById",
  async (_request: any, { dispatch }) => {
    dispatch(setLoading(true));
    try {
      const page = _request?.pageIndex || 0;
      const size = _request?.pageSize || 50;
      const sortBy = _request?.sort || [];
      const search = _request?.search || "";
      const filterBy = _request?.filterData || [];
      const importId = _request?.importId || [];

      let url = `get-import-deal-data?page=${page}&per_page=${size}&import_id=${importId}`;
      if (search) {
        url += `&search=${search}`;
      }
      if (sortBy?.length) {
        url += `&field=${sortBy[0]?.id}&order_by=${sortBy[0]?.desc ? "desc" : "asc"}`;
      }
      // Apply advance filter
      if (filterBy?.length) {
        url = getFilterURL(url, filterBy);
      }

      const response = await slsApiClient().get(url, {
        params: getAssociationLocations(),
      });
      dispatch(setLoading(false));
      if (response?.data?.status) {
        dispatch(setImportDealDataList(response?.data));
      } else {
        dispatch(notificationFail(response?.data?.message || Messages.ERROR.NO_DATA_FOUND));
      }
    } catch (error: any) {
      dispatch(setLoading(false));
      dispatch(notificationFail(error?.response?.data?.message || Messages.ERROR.NO_DATA_FOUND));
    }
  }
);

export const retryImportedDeal = createAsyncThunk(
  "retryImportedDeal",
  async (_request: any, { dispatch }) => {
    const { pageNo, onClose, perPage, search, sortBy, filterData, id, importId } = _request;
    dispatch(setLoading(true));
    try {
      const response = await slsApiClient().post(`retry-create-deal`, { import_deal_data_id: id });
      if (response && response?.data?.status) {
        dispatch(notificationSuccess(response?.data?.message || "Deal Retry success"));
        dispatch(
          getImportDataListById({
            pageSize: perPage,
            pageIndex: pageNo,
            search,
            sort: sortBy,
            filterData,
            importId,
          })
        );
        if (onClose) {
          onClose();
        }
      } else {
        dispatch(setLoading(false));
        dispatch(notificationFail(response?.data?.message || "Error while Retry"));
      }
    } catch (error: any) {
      dispatch(setLoading(false));
      dispatch(notificationFail(error?.response?.data?.message || "Error while import deals"));
    }
  }
);
