// uuid is a library for generating unique id
// import { v4 as uuidv4 } from "uuid";
// import moment from "moment";
// Images
// import team1 from "assets/images/team-1.jpg";
// import team3 from "assets/images/team-3.jpg";
// import team4 from "assets/images/team-4.jpg";
// import team5 from "assets/images/team-5.jpg";
// import CardItem from "../components/Card/cardItem";

const boards = {
  columns: [
    {
      id: "new",
      title: "New",
      cards: [] as string[],
      infoTexts:
        "This status represents the first contact from a person interested in lease information. Most of these come from website form submissions.",
      key: "newStatusCount",
    },
    {
      id: "attempting_to_connect",
      title: "Attempting To Connect",
      cards: [] as string[],
      infoTexts:
        "This status means you, or an automated sequence, has tried to contact this person.",
      key: "attemptingtoConnectStatusCount",
    },

    {
      id: "connected",
      title: "Connected",
      cards: [] as string[],
      infoTexts:
        "This status indicates the person has been contacted by you, or has replied to an automated sequence.",
      key: "connectedStatusCount",
    },
    {
      id: "tour_scheduled",
      title: "Tour Scheduled",
      cards: [] as string[],
      infoTexts: "This status is used to track which people have been given a tour at your salon.",
      key: "tourScheduledStatusCount",
    },
    {
      id: "tour_completed",
      title: "Tour Completed",
      cards: [] as string[],
      infoTexts: "This status is used to track which people have been given a tour at your salon.",
      key: "tourCompletedStatusCount",
    },
    {
      id: "closed_lease",
      title: "Closed Leased",
      cards: [] as string[],
      infoTexts:
        "This status is for people who have communicated intent and ready to sign a lease.",
      key: "closedLeasedStatusCount",
    },
    {
      id: "closed",
      title: "Closed",
      cards: [] as string[],
      infoTexts: "This status is for people who are not going to lease a studio for any reason.",
      key: "closedStatusCount",
    },
    {
      id: "waitlist",
      title: "Waitlist",
      cards: [] as string[],
      infoTexts:
        "This status is for anyone interested in leasing, but unable to at the present time.",
      key: "WaitlistStatusCount",
    },
  ],
};

export default boards;
