import { useState } from "react";
import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";
import Card from "@mui/material/Card";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import CustomDropZone from "components/CustomDropZone";
import ImagePreview from "layouts/pages/ImagePreview";
import MDIconButton from "components/MDIconButton";
import MESSAGES from "helper/messages";

function Images(props: any): JSX.Element {
  const { locationDetail, onChangeField, imagePreview, onChangeImage, onImageRemove } = props;

  const [isPreview, setImagePreviewPopup] = useState(false);
  const [imageToPreview, setImageToPreview] = useState("");

  const previewImage = (image: any) => {
    setImagePreviewPopup(true);
    setImageToPreview(image);
  };

  const closeImagePreview = () => {
    setImagePreviewPopup(false);
    setImageToPreview("");
  };

  const displayItems = (): JSX.Element => {
    const items: any = [];
    for (let i = 1; i <= 20; i++) {
      items.push(
        <Grid container spacing={2} key={i} mt={1}>
          <Grid item xs={12} sm={2} md={2} lg={2}>
            <MDTypography fontSize="medium">Image {i}</MDTypography>
          </Grid>
          <Grid item xs={12} sm={4} md={4} lg={4}>
            <CustomDropZone
              multipleUpload={false}
              acceptedFiles={{
                "image/*": [".gif", ".png", ".jpg", ".jpeg"],
              }}
              onDrop={(e: any) => onChangeImage(e, `image_${i}`, i)}
            >
              Drop your image here, or Browse
            </CustomDropZone>
          </Grid>
          <Grid item xs={12} sm={2} md={2} lg={2}>
            {imagePreview && imagePreview[i] && (
              <Grid
                container
                onClick={() => previewImage(imagePreview[i])}
                justifyContent="center"
                alignItems="center"
                textAlign="center"
              >
                <Grid item className="imageWithDeleteIcon">
                  <img
                    width="90%"
                    style={{ maxHeight: "100px" }}
                    src={imagePreview[i]}
                    alt="original"
                  />
                  <MDIconButton
                    tooltipName="Delete"
                    aria-label="Delete"
                    className="removeBtnClass"
                    onClick={(e: any) => onImageRemove(e, `image_${i}`, i)}
                  >
                    <Icon fontSize="small">close</Icon>
                  </MDIconButton>
                </Grid>
              </Grid>
            )}
          </Grid>
          <Grid item xs={12} sm={4} md={4} lg={4}>
            <MDInput
              multiline
              rows="3"
              variant="outlined"
              label={`Image ${i} Alt Text`}
              fullWidth
              name={`image_${i}_alt_text`}
              placeholder={`Image ${i} Alt Text`}
              onChange={onChangeField}
              value={locationDetail?.[`image_${i}_alt_text`] || ""}
              infoText={MESSAGES.INFO.IMAGE_ALT_TEXT}
            />
          </Grid>
        </Grid>
      );
    }
    return items;
  };

  return (
    <Card id="images">
      <MDBox p={3}>
        <MDTypography variant="h5">Images</MDTypography>
      </MDBox>
      <MDBox pb={3} px={3}>
        <MDBox mt={1}>{displayItems()}</MDBox>
        {isPreview && (
          <ImagePreview
            imageData={imageToPreview}
            open={isPreview}
            onCloseImage={() => {
              closeImagePreview();
            }}
          />
        )}
      </MDBox>
    </Card>
  );
}

export default Images;
