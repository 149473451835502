import { useState } from "react";
import { Card, Tabs, Tab } from "@mui/material";
import MDBox from "components/MDBox";
import MDTabPanel from "components/MDTabPanel";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import { setTabWiseSolaProList } from "store/slices/systemAdminSlice";
import { useAppDispatch } from "store/store";
import TabWiseSolaProList from "./listing";

function Index(): JSX.Element {
  const dispatch = useAppDispatch();
  const [tabValue, setTabValue] = useState<number>(0);
  const changeTab = (event: any, newValue: any) => {
    dispatch(setTabWiseSolaProList([]));
    setTabValue(newValue);
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pb={3}>
        <Card>
          <MDBox minWidth={{ xs: "100%", md: "25rem" }} mx="auto" mt={2} mb={2}>
            <Tabs className="location-tabs" value={tabValue} onChange={changeTab}>
              <Tab
                id="un_matched"
                label={
                  <MDBox py={0.5} px={2} color="inherit">
                    Unmatched Sola Pros
                  </MDBox>
                }
              />
              <Tab
                id="multiple_matched"
                label={
                  <MDBox py={0.5} px={2} color="inherit">
                    Multiple Matched Sola Pros
                  </MDBox>
                }
              />
              <Tab
                id="matched"
                label={
                  <MDBox py={0.5} px={2} color="inherit">
                    Matched Sola Pros
                  </MDBox>
                }
              />
              <Tab
                id="match_confirmed"
                label={
                  <MDBox py={0.5} px={2} color="inherit">
                    Match Confirmed Sola Pros
                  </MDBox>
                }
              />
            </Tabs>
          </MDBox>
          <MDTabPanel value={tabValue} index={0}>
            <TabWiseSolaProList tabName="unmatched" />
          </MDTabPanel>
          <MDTabPanel value={tabValue} index={1}>
            <TabWiseSolaProList tabName="multiple_matched" />
          </MDTabPanel>
          <MDTabPanel value={tabValue} index={2}>
            <TabWiseSolaProList tabName="matched" />
          </MDTabPanel>
          <MDTabPanel value={tabValue} index={3}>
            <TabWiseSolaProList tabName="confirmed" />
          </MDTabPanel>
        </Card>
      </MDBox>
    </DashboardLayout>
  );
}

export default Index;
