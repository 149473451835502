import MDButton from "components/MDButton";
import MDBox from "components/MDBox";
import { Grid } from "@mui/material";
import { systemAdmin } from "helper/services";
import BasicInfo from "./basicInfo";
import ContactInfo from "./contactInfo";
import Services from "./services";
import RentManager from "./rentManager";
import Sidenav from "./sidenav";

function Index(props: any): JSX.Element {
  const { onUpdateGeneralTabForNew } = props;

  return (
    <Grid container spacing={3} mt={1}>
      <Grid item xs={12} lg={3}>
        <Sidenav />
      </Grid>
      <Grid item xs={12} lg={9}>
        <MDBox mb={3}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <BasicInfo {...props} />
            </Grid>
            <Grid item xs={12}>
              <ContactInfo {...props} />
            </Grid>
            {/* <Grid item xs={12}>
              <Webpage {...props} />
            </Grid> */}
            <Grid item xs={12}>
              <Services {...props} />
            </Grid>
            {systemAdmin() && (
              <Grid item xs={12}>
                <RentManager {...props} />
              </Grid>
            )}
            <Grid item xs={12} display="flex" justifyContent="right" alignItems="flex-start">
              <MDButton
                onClick={onUpdateGeneralTabForNew}
                variant="gradient"
                color="info"
                size="small"
                className="fixed-button"
              >
                Update
              </MDButton>
            </Grid>
          </Grid>
        </MDBox>
      </Grid>
    </Grid>
  );
}

export default Index;
