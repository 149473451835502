import { useEffect, useState } from "react";
import { useAppDispatch } from "store/store";
import MDDialog from "components/MDDialog";
import MDInput from "components/MDInput";
import CustomDropZone from "components/CustomDropZone";
import { InputLabel, MenuItem, Select, Grid, Icon, FormControl } from "@mui/material";
import Validations from "helper/validations";
import { saveMySolaImage } from "store/thunk/systemAdmin";
import ImagePreview from "layouts/pages/ImagePreview";
import CustomErrorMessage from "components/CustomErrorMessage";
import CustomRequiredLabel from "components/CustomRequiredLabel";
import MDIconButton from "components/MDIconButton";
import { scrollToErrorByClass } from "helper/services";

function AddUpdate(props: any): JSX.Element {
  const dispatch = useAppDispatch();

  const { open, onClose, updateData, pageNo, perPage, search, sortBy } = props;
  const [isUpdate, setIsUpdate] = useState<any>(false);
  const [mySolaImage, setMySolaImage] = useState<any>({});
  const [imagePreview, setImagePreview] = useState<any>("");
  const [errors, setErrors] = useState<any>({});
  const [generatedImagePreview, setGeneratedImagePreview] = useState<any>("");
  const [isPreview, setImagePreviewPopup] = useState(false);
  const [imageToPreview, setImageToPreview] = useState("");

  const previewImage = (image: any) => {
    setImagePreviewPopup(true);
    setImageToPreview(image);
  };

  const closeImagePreview = () => {
    setImagePreviewPopup(false);
    setImageToPreview("");
  };

  const onSave = () => {
    const error = Validations.validateSola10kForm(mySolaImage);
    setErrors(error);
    if (!Object.keys(error).length) {
      const req = {
        mySolaImage,
        onClose,
        isUpdate,
        pageNo,
        perPage,
        search,
        sortBy,
      };
      dispatch(saveMySolaImage(req));
    } else {
      scrollToErrorByClass(error);
    }
  };
  useEffect(() => {
    if (updateData) {
      if (updateData.id) {
        setIsUpdate(true);
      }
      setMySolaImage(updateData);
      setImagePreview(updateData.original_image_url);
      setGeneratedImagePreview(updateData.generated_image_url);
    }
  }, [updateData]);

  const onChangeImage = async (files: any, type: any) => {
    if (files[0]) {
      const file = files[0];
      const reader = new FileReader();
      reader.onload = function (e) {
        if (type === "original_image_url") {
          setImagePreview(e.target.result);
          setMySolaImage({ ...mySolaImage, [type]: files[0], delete_image: 0 });
        } else {
          setGeneratedImagePreview(e.target.result);
          setMySolaImage({ ...mySolaImage, [type]: files[0], delete_carousel_image: 0 });
        }
      };
      reader.readAsDataURL(file);
    }
  };

  const onImageRemove = async (e: any, type: any) => {
    e.stopPropagation();
    if (type === "original_image_url") {
      setImagePreview("");
      setMySolaImage({ ...mySolaImage, [type]: null, delete_image: 1 });
    } else {
      setGeneratedImagePreview("");
      setMySolaImage({ ...mySolaImage, [type]: null, delete_carousel_image: 1 });
    }
  };

  const onChangeField = (e: any, field: string) => {
    setMySolaImage({ ...mySolaImage, [field]: e.target.value });
  };

  const dialogProps = {
    open,
    onSave,
    onClose,
    title: isUpdate ? "Update My sola Image" : "Add My sola Image",
    size: "lg",
    saveTbtText: isUpdate ? "Update" : "Save",
  };

  return (
    <MDDialog {...dialogProps}>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12} md={6} lg={6}>
          <FormControl fullWidth>
            <InputLabel id="approved-label">Approved</InputLabel>
            <Select
              fullWidth
              labelId="approved-label"
              variant="outlined"
              id="approved"
              value={mySolaImage?.approved ? 1 : 0}
              label="Approved"
              sx={{ padding: 0 }}
              onChange={(event: any) => onChangeField(event, "approved")}
              className="height45"
            >
              <MenuItem value={1}>Yes</MenuItem>
              <MenuItem value={0}>No</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6}>
          <MDInput
            label="Name"
            variant="outlined"
            fullWidth
            required
            name="name"
            placeholder="name"
            onChange={(event: any) => onChangeField(event, "name")}
            value={mySolaImage?.name ? mySolaImage?.name : ""}
            error={errors && errors.name ? true : false}
            helperText={errors && errors.name}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6}>
          <MDInput
            label="Instagram Handle"
            variant="standard"
            fullWidth
            name="instagram_handle"
            placeholder="Instagram Handle"
            onChange={(event: any) => onChangeField(event, "instagram_handle")}
            value={mySolaImage?.instagram_handle ? mySolaImage?.instagram_handle : ""}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6}>
          <MDInput
            label="Statement"
            variant="standard"
            fullWidth
            name="statement"
            placeholder="Statement"
            onChange={(event: any) => onChangeField(event, "statement")}
            value={mySolaImage?.statement ? mySolaImage?.statement : ""}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6}>
          <FormControl fullWidth>
            <InputLabel id="statement-variant">Statement Variant</InputLabel>
            <Select
              fullWidth
              labelId="statement-variant"
              variant="outlined"
              id="statementvariant"
              value={mySolaImage?.statement_variant || ""}
              label="Statement Variant"
              sx={{ padding: 0 }}
              onChange={(event: any) => onChangeField(event, "statement_variant")}
              className="height45"
            >
              <MenuItem value={0}>#MySola is my [BLANK]</MenuItem>
              <MenuItem value={1}>I feel [BLANK] in #MySola</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <CustomRequiredLabel label="Image" />
          <Grid container>
            <Grid item xs={12} md={8}>
              <CustomDropZone
                multipleUpload={false}
                acceptedFiles={{
                  "image/*": [".gif", ".png", ".jpg", ".jpeg"],
                }}
                onDrop={(e: any) => onChangeImage(e, "original_image_url")}
              >
                Drop your image here, or Browse
              </CustomDropZone>
            </Grid>
            <Grid item xs={12} md={4}>
              {imagePreview && (
                <Grid
                  container
                  onClick={() => previewImage(imagePreview)}
                  justifyContent="center"
                  alignItems="center"
                  textAlign="center"
                >
                  <Grid item className="imageWithDeleteIcon">
                    <img
                      style={{ maxHeight: "100px" }}
                      width={100}
                      src={imagePreview}
                      alt="original"
                    />
                    <MDIconButton
                      tooltipName="Delete"
                      aria-label="Delete"
                      className="removeBtnClass"
                      onClick={(e: any) => onImageRemove(e, "original_image_url")}
                    >
                      <Icon fontSize="small">close</Icon>
                    </MDIconButton>
                  </Grid>
                </Grid>
              )}
            </Grid>
            <div className="image">
              {errors && errors.image && <CustomErrorMessage message={errors.image} />}
            </div>
          </Grid>
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <CustomRequiredLabel label="Generated Image" />
          <Grid container>
            <Grid item xs={12} md={8}>
              <CustomDropZone
                multipleUpload={false}
                acceptedFiles={{
                  "image/*": [".gif", ".png", ".jpg", ".jpeg"],
                }}
                onDrop={(e: any) => onChangeImage(e, "generated_image_url")}
              >
                Drop your image here, or Browse
              </CustomDropZone>
            </Grid>
            <Grid item xs={12} md={4}>
              {generatedImagePreview && (
                <Grid
                  container
                  onClick={() => previewImage(generatedImagePreview)}
                  justifyContent="center"
                  alignItems="center"
                  textAlign="center"
                >
                  <Grid item className="imageWithDeleteIcon">
                    <img
                      style={{ maxHeight: "100px" }}
                      width={100}
                      src={generatedImagePreview}
                      alt="generated"
                    />
                    <MDIconButton
                      tooltipName="Delete"
                      aria-label="Delete"
                      className="removeBtnClass"
                      onClick={(e: any) => onImageRemove(e, "generated_image_url")}
                    >
                      <Icon fontSize="small">close</Icon>
                    </MDIconButton>
                  </Grid>
                </Grid>
              )}
            </Grid>
            <div className="generated_image">
              {errors && errors.generated_image && (
                <CustomErrorMessage message={errors.generated_image} />
              )}
            </div>
          </Grid>
        </Grid>
      </Grid>
      <ImagePreview
        imageData={imageToPreview}
        open={isPreview}
        onCloseImage={() => {
          closeImagePreview();
        }}
      />
    </MDDialog>
  );
}

export default AddUpdate;
