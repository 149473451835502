import { useState } from "react";
import { Tab, Tabs } from "@mui/material";
import MDBox from "components/MDBox";
import MDTabPanel from "components/MDTabPanel";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import InquiriesList from "layouts/pages/franchisee-website/inquiries/inquiriesList";

function Index(): JSX.Element {
  const [countryTab, setCountryTab] = useState<any>(0);

  const changeTab = (e: any, value?: number) => {
    setCountryTab(value);
  };
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox minWidth={{ xs: "100%", md: "25rem" }} maxWidth="500px" mx="auto">
        <Tabs className="location-tabs" value={countryTab} onChange={changeTab}>
          <Tab
            id="usa"
            label={
              <MDBox py={0.5} px={2} color="inherit">
                Usa
              </MDBox>
            }
          />
          <Tab
            id="ca"
            label={
              <MDBox py={0.5} px={2} color="inherit">
                Ca
              </MDBox>
            }
          />
        </Tabs>
      </MDBox>
      <MDTabPanel value={countryTab} index={0} padding={0}>
        <InquiriesList country="usa" />
      </MDTabPanel>
      <MDTabPanel value={countryTab} index={1} padding={0}>
        <InquiriesList country="ca" />
      </MDTabPanel>
    </DashboardLayout>
  );
}

export default Index;
