import MDButton from "components/MDButton";

export default function MailToLink(props: any) {
  const { mailto, label } = props;

  return (
    <MDButton
      variant="text"
      color="dark"
      size="small"
      sx={{ padding: 0, fontSize: "13px", color: "#227bea", textTransform: "none" }}
      onClick={(e) => {
        window.location.href = mailto;
        e.preventDefault();
      }}
    >
      {label}
    </MDButton>
  );
}
