import { createSlice, Draft, PayloadAction } from "@reduxjs/toolkit";

interface InitialState {
  boardData: any;
  statusArray: any;
  outBoundLogs: any;
}

const initialState: InitialState = {
  boardData: {},
  statusArray: [
    { status: "New", value: "new" },
    { status: "Attempting to Connect", value: "attempting_to_connect" },
    { status: "Connected", value: "connected" },
    { status: "Tour Scheduled", value: "tour_scheduled" },
    { status: "Tour Completed", value: "tour_completed" },
    { status: "Closed Leased", value: "closed_lease" },
    { status: "Closed", value: "closed" },
    { status: "Waitlist", value: "waitlist" },
  ],
  outBoundLogs: [],
};

const dealSlice = createSlice({
  name: "deal",
  initialState,
  reducers: {
    setBoardData: (state: Draft<InitialState>, action: PayloadAction<any>) => ({
      ...state,
      boardData: action.payload,
    }),
    setOutBoundLogs: (state: Draft<InitialState>, action: PayloadAction<any>) => ({
      ...state,
      outBoundLogs: [...state.outBoundLogs, action.payload],
    }),
    clearOutBoundLogs: (state: Draft<InitialState>, action: PayloadAction<any>) => ({
      ...state,
      outBoundLogs: action.payload,
    }),
  },
});

export const { setBoardData, setOutBoundLogs, clearOutBoundLogs } = dealSlice.actions;

export default dealSlice.reducer;
