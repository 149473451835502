import { Grid, Typography } from "@mui/material";
import MDBox from "components/MDBox";
import RenderCardColumns from "./renderCardColumns";

export interface RenderDetailsProps {
  keys: string[];
  selectedScorecard: {
    [key: string]: number | string;
  };
  column1: string[];
  column2: string[];
  column3: string[];
}

function RenderDetails({ keys, selectedScorecard, column1, column2, column3 }: RenderDetailsProps) {
  return (
    <MDBox>
      <Grid container>
        <Grid item md={6}>
          <MDBox display="flex" flexDirection="column">
            {keys.map((key: string) => (
              <MDBox sx={{ padding: "0 0 8px 20px" }}>
                <Typography variant="body2">
                  <MDBox fontWeight="fontWeightMedium" display="inline">
                    {key}
                  </MDBox>
                </Typography>
              </MDBox>
            ))}
          </MDBox>
        </Grid>
        <Grid item md={6}>
          <MDBox display="flex" flexDirection="row">
            <RenderCardColumns keys={column1} selectedScorecard={selectedScorecard} />
            <RenderCardColumns keys={column2} selectedScorecard={selectedScorecard} />
            <RenderCardColumns keys={column3} selectedScorecard={selectedScorecard} />
          </MDBox>
        </Grid>
      </Grid>
    </MDBox>
  );
}

export default RenderDetails;
