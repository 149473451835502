import MDBox from "components/MDBox";

import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";

function Index(): JSX.Element {
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox p={2}>Sample page....</MDBox>
    </DashboardLayout>
  );
}

export default Index;
