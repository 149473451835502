import React, { useEffect, useState } from "react";
import MDDialog from "components/MDDialog";
import MDInput from "components/MDInput";
import MDBox from "components/MDBox";
import Validations from "helper/validations";
import { addOrUpdateLegacyAdmin } from "store/thunk/systemAdmin";
import { useAppDispatch } from "store/store";
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  InputLabel,
  MenuItem,
  Select,
  styled,
} from "@mui/material";
import MuiAccordion, { AccordionProps } from "@mui/material/Accordion";
import MuiAccordionSummary, { AccordionSummaryProps } from "@mui/material/AccordionSummary";
import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import MDTypography from "components/MDTypography";
import CustomPasswordInput from "components/CustomPasswordInput";

const Accordion = styled((props: AccordionProps) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  "&:before": {
    display: "none",
  },
}));

const AccordionSummary = styled((props: AccordionSummaryProps) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: "0.9rem" }} />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === "dark" ? "rgba(255, 255, 255, .05)" : "rgba(0, 0, 0, .03)",
  flexDirection: "row-reverse",
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(90deg)",
  },
  "& .MuiAccordionSummary-content": {
    marginLeft: theme.spacing(1),
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: "1px solid rgba(0, 0, 0, .125)",
}));

function AddUpdate(props: any): JSX.Element {
  const dispatch = useAppDispatch();
  const { open, onClose, updateData, pageNo, perPage, search } = props;
  const [adminData, setAdminData] = useState<any>({});
  const [errors, setErrors] = useState<any>({});
  const [expanded, setExpanded] = React.useState<string | false>(false);
  const oldEmail = updateData?.id ? updateData?.email : "";

  useEffect(() => {
    if (updateData?.id) {
      setAdminData(updateData);
    }
  }, [updateData]);

  const handleChangeAccordian =
    (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : false);
    };

  const openAccordian = (accordianElementId: any, accordianExpandName: any) => {
    const element = document.getElementById(accordianElementId);
    element.addEventListener("change", () => {
      setExpanded(accordianExpandName);
    });
    const event = new Event("change", {
      bubbles: true,
      cancelable: true,
      composed: false,
    });
    element.dispatchEvent(event);
  };

  const onSave = () => {
    let error: any = {};
    error = Validations.validateLegacyAdminForm(adminData);
    if (error?.mailchimp_api_key || error?.callfire_app_login || error?.callfire_app_password) {
      openAccordian("textEmail-accordian", "textEmail");
    }
    setErrors(error);
    if (!Object.keys(error).length) {
      const req = {
        adminData,
        onClose,
        pageNo,
        perPage,
        oldEmail,
        search,
      };
      if (adminData?.id) {
        dispatch(addOrUpdateLegacyAdmin(req));
      } else {
        dispatch(addOrUpdateLegacyAdmin(req));
      }
    }
  };

  const onChangeField = (e: any, field: string) => {
    setAdminData({ ...adminData, [field]: e.target.value });
  };

  const handleSingleCheckbox = (event: React.ChangeEvent<HTMLInputElement>) => {
    setAdminData({ ...adminData, [event.target.name]: event.target.checked });
  };

  const dialogProps = {
    open,
    onClose,
    onSave,
    title: updateData?.id ? "Update Legacy Account" : "Add Legacy Account",
    size: "md",
    saveTbtText: updateData?.id ? "Update" : "Save",
  };

  return (
    <MDDialog {...dialogProps}>
      <MDBox mt={2}>
        <MDInput
          label="Username"
          variant="standard"
          fullWidth
          required
          name="email"
          placeholder="Username"
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => onChangeField(e, "email")}
          value={adminData?.email || ""}
          error={errors && errors.email ? true : false}
          helperText={errors && errors.email}
        />
      </MDBox>
      <MDBox mt={2}>
        <MDInput
          label="Email Address"
          variant="standard"
          fullWidth
          required
          name="email_address"
          placeholder="Email Address"
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => onChangeField(e, "email_address")}
          value={adminData?.email_address || ""}
          error={errors && errors.email_address ? true : false}
          helperText={errors && errors.email_address}
        />
      </MDBox>
      <MDBox mt={2}>
        <CustomPasswordInput
          label="Password"
          name="password"
          required={adminData?.id ? false : true}
          placeholder="************"
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => onChangeField(e, "password")}
          value={adminData?.password || ""}
          error={errors && errors.password ? true : false}
          helperText={errors && errors.password}
        />
      </MDBox>
      <MDBox mt={2}>
        <CustomPasswordInput
          label="Password Confirmation"
          name="password_confirmation"
          required={adminData?.id ? false : true}
          placeholder="************"
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            onChangeField(e, "password_confirmation")
          }
          error={errors && errors.password_confirmation ? true : false}
          helperText={errors && errors.password_confirmation}
          value={adminData?.password_confirmation || ""}
        />
      </MDBox>
      <MDBox mt={2}>
        <FormControl component="fieldset">
          <FormGroup aria-label="position">
            <FormControlLabel
              value="end"
              control={
                <Checkbox
                  checked={adminData?.franchisee || false}
                  name="franchisee"
                  onChange={handleSingleCheckbox}
                  inputProps={{ "aria-label": "controlled" }}
                />
              }
              label="Franchisee"
              labelPlacement="end"
            />
          </FormGroup>
        </FormControl>
      </MDBox>
      <Accordion
        expanded={expanded === "textEmail"}
        sx={{ marginTop: "20px" }}
        id="textEmail-accordian"
        onChange={handleChangeAccordian("textEmail")}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="billing-information"
        >
          <MDTypography>Text and Email Integration</MDTypography>
        </AccordionSummary>
        <AccordionDetails>
          <MDBox mt={2}>
            <MDInput
              label="Mailchimp Api Key"
              variant="standard"
              fullWidth
              name="mailchimp_api_key"
              placeholder=""
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                onChangeField(e, "mailchimp_api_key")
              }
              value={adminData?.mailchimp_api_key || ""}
              error={errors && errors.mailchimp_api_key ? true : false}
              helperText={errors && errors.mailchimp_api_key}
            />
          </MDBox>
          <MDBox mt={2}>
            <MDInput
              label="Callfire App Login"
              variant="standard"
              fullWidth
              name="callfire_app_login"
              placeholder=""
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                onChangeField(e, "callfire_app_login")
              }
              value={adminData?.callfire_app_login || ""}
              error={errors && errors.callfire_app_login ? true : false}
              helperText={errors && errors.callfire_app_login}
            />
          </MDBox>
          <MDBox mt={2}>
            <CustomPasswordInput
              label="Callfire App Password"
              name="callfire_app_password"
              placeholder=""
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                onChangeField(e, "callfire_app_password")
              }
              value={adminData?.callfire_app_password || ""}
              error={errors && errors.callfire_app_password ? true : false}
              helperText={errors && errors.callfire_app_password}
            />
          </MDBox>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="billing-information"
        >
          <MDTypography>Sola Pro</MDTypography>
        </AccordionSummary>
        <AccordionDetails>
          <MDBox mt={2}>
            <FormControl fullWidth>
              <InputLabel>Sola Pro Country Admin</InputLabel>
              <Select
                labelId="Sola Pro Country Admin"
                id="status"
                variant="standard"
                className="sola_pro_country_admin"
                value={adminData?.sola_pro_country_admin || ""}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  onChangeField(e, "sola_pro_country_admin")
                }
              >
                <MenuItem value="">Select Country</MenuItem>
                <MenuItem value="US">US</MenuItem>
                <MenuItem value="CA">CA</MenuItem>
              </Select>
            </FormControl>
          </MDBox>
        </AccordionDetails>
      </Accordion>
    </MDDialog>
  );
}

export default AddUpdate;
