import { styled } from "@mui/material/styles";
import { Dialog, DialogContent, DialogActions } from "@mui/material";
import MDButton from "components/MDButton";
import SimpleLoader from "components/MDLoader/simpleLoader";
import MDDialogTitle from "../MDDialogTitle";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

export default function CustomizedDialogs(props: any) {
  const {
    children,
    open,
    title,
    saveTbtText,
    closeBtnText,
    onSave,
    onClose,
    onCloseFunc,
    size,
    loading,
    saveBtn = true,
    closeBtn = true,
    closeBtnColor = "light",
    deleteBtn = false,
    disabledBtn = false,
    isDisabled = false,
    paynowBtn = false,
    overflowVisible,
    additionalBtnText,
    onAdditionalBtnSave,
    additionalBtnColor = "info",
    additionalBtn = false,
    titleClass = "",
    bodyClass = "",
    saveAsDefaultBtn = false,
    saveAsDefaultBtnText = "",
    saveAsDefaultBtnDisabled = false,
    onSaveAsDefaultBtn,
    fullScreen = false,
    saveBtnDisabled = false,
    additionalBtnDisabled = false,
    disableParentClick = false,
  } = props;

  const checkOpen = () => {
    let openModal = false;
    if (open) {
      openModal = true;
    }
    return openModal;
  };

  const propsObject: any = {};
  if (disableParentClick) {
    propsObject.onClick = (e: any) => e.stopPropagation();
  }

  return (
    <div>
      <BootstrapDialog
        aria-labelledby="customized-dialog-title"
        open={checkOpen()}
        maxWidth={size || "sm"}
        fullWidth
        fullScreen={fullScreen || false}
        className={overflowVisible || ""}
        {...propsObject}
      >
        <MDDialogTitle onClose={onClose} className={titleClass || ""}>
          {title}
        </MDDialogTitle>
        <DialogContent
          dividers
          className={bodyClass || ""}
          sx={overflowVisible ? { overflow: { xs: "auto", sm: "auto", md: "visible" } } : {}}
        >
          {children}
        </DialogContent>
        <DialogActions style={{ justifyContent: paynowBtn ? "center" : "flex-end" }}>
          {saveAsDefaultBtn && (
            <MDButton
              onClick={onSaveAsDefaultBtn}
              variant="text"
              color="info"
              size="small"
              disabled={saveAsDefaultBtnDisabled}
            >
              {saveAsDefaultBtnText || "Save"}
            </MDButton>
          )}
          {closeBtn && (
            <MDButton
              onClick={onCloseFunc || onClose}
              variant="gradient"
              color={closeBtnColor}
              size="small"
            >
              {closeBtnText || "Close"}
            </MDButton>
          )}
          {additionalBtn && (
            <MDButton
              onClick={onAdditionalBtnSave}
              variant="gradient"
              color={additionalBtnColor}
              size="small"
              // disabled={loading}
              disabled={loading || additionalBtnDisabled}
            >
              {loading ? <SimpleLoader size={20} /> : additionalBtnText || "Save"}
            </MDButton>
          )}
          {saveBtn && (
            <MDButton
              onClick={onSave}
              variant="gradient"
              color="info"
              size="small"
              disabled={loading || saveBtnDisabled}
            >
              {loading ? <SimpleLoader size={20} /> : saveTbtText || "Save"}
            </MDButton>
          )}
          {deleteBtn && (
            <MDButton
              onClick={onSave}
              variant="gradient"
              color="error"
              size="small"
              disabled={loading}
            >
              {loading ? <SimpleLoader size={20} /> : saveTbtText || "Save"}
            </MDButton>
          )}
          {disabledBtn && (
            <MDButton
              onClick={onSave}
              variant="gradient"
              color="info"
              size="small"
              disabled={isDisabled}
            >
              {loading ? <SimpleLoader size={20} /> : saveTbtText || "Save"}
            </MDButton>
          )}
          {paynowBtn && (
            <MDButton
              onClick={onSave}
              variant="gradient"
              color="info"
              size="small"
              disabled={loading}
            >
              {loading ? <SimpleLoader size={20} /> : "Pay Now"}
            </MDButton>
          )}
        </DialogActions>
      </BootstrapDialog>
    </div>
  );
}
