import {
  Grid,
  FormControl,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
  InputLabel,
} from "@mui/material";

import MDBox from "components/MDBox";
import MDInput from "components/MDInput";
import MDTypography from "components/MDTypography";
import CustomMMYYInput from "components/CustomMMYYInput";

function Index(props: any): JSX.Element {
  const { leaseDetails, onChangeField, errors } = props;

  return (
    <MDBox>
      <MDBox textAlign="center" mx="auto" my={2}>
        <MDBox mb={1}>
          <MDTypography variant="h5" fontWeight="regular">
            Billing Information
          </MDTypography>
        </MDBox>
      </MDBox>
      <MDBox m={2}>
        <Grid container spacing={2}>
          <FormControl error={errors && errors.billing_type ? true : false}>
            <FormLabel id="billing_type">
              Billing Type<span className="required_star">*</span>
            </FormLabel>
            <RadioGroup
              row
              aria-labelledby="billing_type"
              name="billing_type"
              value={leaseDetails?.billing_type || ""}
              onChange={onChangeField}
            >
              <FormControlLabel value="card" control={<Radio />} label="Card" />
              <FormControlLabel value="bank_transfer" control={<Radio />} label="Bank Transfer" />
            </RadioGroup>
          </FormControl>
        </Grid>
      </MDBox>
      {leaseDetails?.billing_type === "card" && (
        <MDBox>
          <Grid container spacing={3}>
            <Grid item md={6} xs={12}>
              <MDInput
                label="Name"
                variant="standard"
                name="card_name"
                placeholder="Name"
                fullWidth
                onChange={onChangeField}
                value={leaseDetails?.card_name || ""}
                error={errors && errors.card_name ? true : false}
                helperText={errors && errors.card_name}
                required
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <MDInput
                type="number"
                label="Card Number"
                variant="standard"
                name="card_number"
                fullWidth
                placeholder="Card Number"
                onChange={onChangeField}
                value={leaseDetails?.card_number || ""}
                error={errors && errors.card_number ? true : false}
                helperText={errors && errors.card_number}
                required
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <InputLabel>MM/YY</InputLabel>
              <MDInput
                variant="standard"
                fullWidth
                name="card_exp_mm_yy"
                placeholder="MM/YY"
                id="card_exp_mm_yy"
                InputProps={{
                  inputComponent: CustomMMYYInput as any,
                }}
                value={leaseDetails?.card_exp_mm_yy}
                error={errors && errors.card_exp_mm_yy ? true : false}
                helperText={errors && errors.card_exp_mm_yy}
                onChange={onChangeField}
                required
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <MDInput
                type="number"
                label="CVC"
                variant="standard"
                name="cvc"
                placeholder="CVC"
                fullWidth
                onChange={onChangeField}
              />
            </Grid>
          </Grid>
        </MDBox>
      )}
      {leaseDetails?.billing_type === "bank_transfer" && (
        <MDBox>
          <Grid container spacing={3}>
            <Grid item md={6} xs={12}>
              <MDInput
                label="Bank Name"
                variant="standard"
                name="bank_name"
                placeholder="Name"
                fullWidth
                onChange={onChangeField}
                value={leaseDetails?.bank_name || ""}
                error={errors && errors.bank_name ? true : false}
                helperText={errors && errors.bank_name}
                required
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <MDInput
                type="number"
                label="Account Number"
                variant="standard"
                name="bank_account"
                fullWidth
                placeholder="Account Number"
                onChange={onChangeField}
                value={leaseDetails?.bank_account || ""}
                error={errors && errors.bank_account ? true : false}
                helperText={errors && errors.bank_account}
                required
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <MDInput
                label="Routing"
                variant="standard"
                name="bank_routing"
                fullWidth
                placeholder="Routing"
                onChange={onChangeField}
                value={leaseDetails?.bank_routing || ""}
                error={errors && errors.bank_routing ? true : false}
                helperText={errors && errors.bank_routing}
                required
              />
            </Grid>
          </Grid>
        </MDBox>
      )}
    </MDBox>
  );
}

export default Index;
