import React, { useState } from "react";
import { Card, Grid } from "@mui/material";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import ImagePreview from "layouts/pages/ImagePreview";
import SortableList from "components/CustomGallery";
import MDButton from "components/MDButton";
import { convertImageToWebp } from "helper/services";
import { useAppDispatch } from "store/store";

function Images(props: any): JSX.Element {
  const {
    webPageDetail,
    setWebPageDetails,
    onSortEnd,
    imagesArray,
    setImagesArray,
    updateImageOrder,
    onUpdateImageOrder,
  } = props;
  const dispatch = useAppDispatch();
  const [isPreview, setImagePreviewPopup] = useState(false);
  const [imageToPreview, setImageToPreview] = useState("");

  const onChangeImage = async (files: any, key: any) => {
    if (files[0]) {
      const file = await convertImageToWebp(files[0], "stylist", dispatch);
      const reader = new FileReader();
      reader.onload = function (e) {
        setWebPageDetails({
          ...webPageDetail,
          [`image_${key}_url`]: String(e.target.result),
          [`image_${key}`]: file,
        });

        const tempArray = JSON.parse(JSON.stringify(imagesArray));
        const newImageObj = {
          index: key,
          id: key,
          url: String(e.target.result),
          name: `image_${key}`,
        };
        tempArray.push(newImageObj);
        setImagesArray(tempArray);
      };

      reader.readAsDataURL(file);
    }
  };

  const onImageRemove = async (e: any, key: any, index?: any) => {
    e.stopPropagation();
    const tempArray = JSON.parse(JSON.stringify(imagesArray));
    const findOne = tempArray.find((val: any) => val.index === index);
    if (key && findOne) {
      const deleteflag = `delete_image_${key}`;
      setWebPageDetails({
        ...webPageDetail,
        [`image_${key}_url`]: null,
        [`image_${key}`]: null,
        [deleteflag]: 1,
      });
    }

    if (index !== undefined) {
      const findIndex = tempArray.findIndex((val: any) => val.index === index);
      tempArray.splice(findIndex, 1);
      setImagesArray(tempArray);
    }
  };

  const previewImage = (image: any) => {
    setImagePreviewPopup(true);
    setImageToPreview(image);
  };

  const showAddMoreButton = () => {
    let indexValue = 0;
    for (let index = 1; index <= 10; index++) {
      if (!webPageDetail?.[`image_${index}_url`]) {
        indexValue = index;
        break;
      }
    }
    return indexValue;
  };

  const closeImagePreview = () => {
    setImagePreviewPopup(false);
    setImageToPreview("");
  };

  const onOpenEditModal = async (e: any) => {
    e.stopPropagation();
  };

  return (
    <Card id="images" sx={{ overflow: "visible" }}>
      <MDBox p={3} pb={0}>
        <MDTypography variant="h5">Images</MDTypography>
        {updateImageOrder && (
          <MDButton
            onClick={onUpdateImageOrder}
            variant="text"
            color="dark"
            size="small"
            sx={{ padding: 0, fontSize: "14px", color: "#227bea", textTransform: "capitalize" }}
          >
            Save Order
          </MDButton>
        )}
      </MDBox>
      <MDBox component="form">
        <Grid container>
          <Grid item xs={12} sm={12}>
            <MDBox>
              <SortableList
                previewImage={previewImage}
                showAddMoreButton={showAddMoreButton}
                onChangeImage={onChangeImage}
                onImageRemove={onImageRemove}
                onOpenEditModal={onOpenEditModal}
                axis="xy"
                pressDelay={90}
                items={imagesArray}
                onSortEnd={onSortEnd}
                showDeleteBtn
              />
              {isPreview && (
                <ImagePreview
                  imageData={imageToPreview}
                  open={isPreview}
                  onCloseImage={() => {
                    closeImagePreview();
                  }}
                />
              )}
            </MDBox>
          </Grid>
        </Grid>
      </MDBox>
    </Card>
  );
}

export default Images;
