import { useState, useEffect, useRef, useCallback, useLayoutEffect } from "react";
import { Card, Checkbox, Icon } from "@mui/material";
import MDButton from "components/MDButton";
import MDBox from "components/MDBox";
import DataTable from "examples/Tables/DataTable";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import {
  getLocalDateTime,
  insertinArray,
  setDefaultSelectedColumns,
  systemAdmin,
} from "helper/services";
import { useAppDispatch, useAppSelector } from "store/store";
import { deleteStylistUnit, getStylistUnits } from "store/thunk/systemAdmin";
import MDDialog from "components/MDDialog";
import MDTypography from "components/MDTypography";
import Messages from "helper/messages";
import exportData from "helper/exportTableConst";
import MDIconButton from "components/MDIconButton";
import { getDefaultCoulmns, saveSelectedAllData } from "store/thunk/connectUserThunk";
import { setDefaultsColumns } from "store/slices/connectUsersSlice";
import AddUpdate from "./addUpdate";
import ViewDetails from "./viewDetails";

function StylistUnits(): JSX.Element {
  const dispatch = useAppDispatch();
  const { stylistUnitsList } = useAppSelector((state) => state.systemAdminSlice);
  const { defaultSelectedColumns } = useAppSelector((state) => state.connectUsersReducer);
  const { userData } = useAppSelector((state) => state.authReducer);
  const [selectedItems, setSelectedItems] = useState([]);
  const [stylistUnits, setStylistUnits] = useState([]);
  const [deleteStylistUnitId, setStylistUnitId] = useState<any>(false);
  const [addStylistUnitPopup, setAddStylistUnitPopup] = useState<any>(false);
  const [stylistUnitPopup, setStylistUnitPopup] = useState<any>(false);
  const [editStylistUnit, setEditStylistUnit] = useState<any>(null);
  const [viewStylistUnit, setViewStylistUnit] = useState<any>(null);
  const [pageCount, setPageCount] = useState(0);
  const [pageNo, setPageNo] = useState(0);
  const [perPage, setPageSize] = useState(50);
  const [totalCount, setTotalCount] = useState(0);
  const [search, setSearch] = useState<any>(null);
  const [sortBy, setSortBy] = useState<any>([]);
  const [isCheckAll, setIsCheckAll] = useState(false);
  const fetchIdRef = useRef(0);
  const [displayColumns, setDisplayColumns] = useState<any>([]);
  const [defaultExport, setDefaultExport] = useState<any>([]);
  const [callFetch, setCallFetch] = useState<any>(false);
  const [defaultSorting, setDefaultSorting] = useState<any>([]);
  const [defaultFilteing, setDefaultFilteing] = useState<any>([]);

  // Get columns
  const getColumns = () => {
    let columns: any = [
      {
        Header: "",
        disableSortBy: true,
        accessor: "checkbox",
        width: "80px",
        checked: true,
      },
      {
        Header: "Stylist",
        accessor: "stylist",
        sorting_column: "stylist",
        export: true,
        disableCheck: true,
        checked: true,
        associative_colums: "stylists.name",
      },
      {
        Header: "Rent Manager Unit",
        accessor: "rent_manager_unit_id",
        width: "200px",
        export: true,
        associative_colums: "rent_manager_unit_id",
        checked: true,
      },
      { Header: "RM Lease", accessor: "rm_lease_id", export: true, checked: true },
      {
        Header: "Move In At",
        accessor: "move_in_at",
        width: "200px",
        export: true,
        is_date: true,
        checked: true,
      },
      {
        Header: "Move Out At",
        accessor: "move_out_at",
        width: "200px",
        export: true,
        is_date: true,
        checked: true,
      },
      {
        Header: "Created At",
        accessor: "created_at",
        width: "200px",
        export: true,
        is_date: true,
        checked: false,
      },
      {
        Header: "Updated At",
        accessor: "updated_at",
        width: "200px",
        export: true,
        is_date: true,
        checked: false,
      },
      {
        Header: "Action",
        accessor: "action",
        export: false,
        width: "200px",
        disableSortBy: true,
        checked: true,
      },
    ];
    if (systemAdmin()) {
      columns = insertinArray(columns, 1, {
        Header: "ID",
        accessor: "id",
        export: true,
        disableCheck: true,
        checked: true,
      });
    }
    return columns;
  };
  const [checkedColumns, setCheckedColumns] = useState<any>(getColumns());
  const latestRefValue = useRef(checkedColumns);

  useEffect(() => {
    latestRefValue.current = checkedColumns;
  }, [checkedColumns]);

  useEffect(() => {
    dispatch(setDefaultsColumns([]));
    // Set data in variable and Call defaultColums Thunk
    const sentReq = {
      user_id: userData?.user_id,
      table_name: exportData.stylist_units.table_name,
    };
    dispatch(getDefaultCoulmns(sentReq));
  }, []);

  useEffect(() => {
    if (defaultSelectedColumns && defaultSelectedColumns?.length) {
      const newColumns = setDefaultSelectedColumns(defaultSelectedColumns, getColumns());
      setCheckedColumns(newColumns);
    } else {
      setCheckedColumns(getColumns());
    }
  }, [defaultSelectedColumns]);

  useEffect(() => {
    if (checkedColumns?.length) {
      setDisplayColumns(checkedColumns.filter((col: any) => col.checked));
    }
  }, [checkedColumns]);

  // Nor used code
  // useEffect(() => {
  //   dispatch(getAllStylist({}));
  // }, []);

  useEffect(() => {
    if (stylistUnitsList && stylistUnitsList?.stylist_units) {
      setStylistUnits(stylistUnitsList?.stylist_units);
      setPageCount(stylistUnitsList?.meta?.total_pages);
      setTotalCount(stylistUnitsList?.meta?.total_count);
    }
  }, [stylistUnitsList]);

  const fetchData = useCallback(({ pageSize, pageIndex, search, sortBy, filterData, isManual }) => {
    const fetchId = ++fetchIdRef.current;
    const sort: any = sortBy || [];
    // We'll even set a delay to simulate a server here
    setTimeout(() => {
      if (fetchId === fetchIdRef.current) {
        const sentReq = {
          sendData: {
            user_id: userData?.user_id,
            table_name: exportData.stylist_units.table_name,
            table_columns: latestRefValue.current ? latestRefValue.current : checkedColumns,
            table_sort: sortBy && sortBy?.length ? sortBy : [],
            table_filter: filterData && filterData?.length ? filterData : [],
            default_export_data: defaultExport,
          },
        };
        if (isManual) {
          dispatch(saveSelectedAllData(sentReq));
        }

        setPageNo(pageIndex);
        setSearch(search);
        setSortBy(sort);
        setPageSize(pageSize);
        // setSelectedItems([]);
        setIsCheckAll(false);
        dispatch(getStylistUnits({ pageSize, pageIndex, search, sort, filterData }));
      }
    }, 1000);
  }, []);

  useLayoutEffect(() => {
    dispatch(setDefaultsColumns([]));
    // Set data in variable and Call defaultColums Thunk
    const sentReq = {
      user_id: userData?.user_id,
      table_name: exportData.stylist_units.table_name,
    };
    dispatch(getDefaultCoulmns(sentReq)).then((resp: any) => {
      // SD-3199
      if (resp?.payload) {
        const respFilter = resp?.payload?.table_filter || [];
        const respSorting = resp?.payload?.table_sort || [];
        const defaultDBexportData = resp?.payload?.default_export_data || [];
        const defaultDBColumn = resp?.payload?.table_columns || [];
        const tempdata = setDefaultSelectedColumns(defaultDBColumn, getColumns());
        setCheckedColumns(tempdata);
        setDefaultExport(defaultDBexportData);
        setDefaultSorting(respSorting);
        setSortBy(respSorting);
        setDefaultFilteing(respFilter);
        setCallFetch(true);
        fetchData({
          perPage,
          pageNo,
          search,
          sortBy: respSorting && respSorting?.length ? respSorting : [],
          additionalField: null,
          filterData: respFilter && respFilter?.length ? respFilter : [],
        });
      }
    });
  }, []);

  const onChangeCheckBox = (e: any) => {
    let items = JSON.parse(JSON.stringify(selectedItems));
    if (e.target.checked && !items.includes(e.target.value)) {
      items.push(e.target.value);
      if (items.length === stylistUnits.length) {
        setIsCheckAll(true);
      }
    } else {
      items = items.filter((id: any) => id !== e.target.value);
      setIsCheckAll(false);
    }
    setSelectedItems(items);
  };

  const checkUncheckAll = (e: any) => {
    let allIds: any = [];
    allIds = stylistUnits.map((val: any) => String(val.id));
    if (e.target.checked) {
      const selectedIds = [...new Set([...selectedItems, ...allIds])];
      setSelectedItems(selectedIds);
      setIsCheckAll(true);
    } else {
      allIds = stylistUnits.map((val: any) => String(val.id));
      allIds = selectedItems.filter((el: any) => !allIds.includes(el));
      setSelectedItems([...allIds]);
      setIsCheckAll(false);
    }
    // setSelectedItems(allIds);
  };

  const actionButtons = (obj: any) => (
    <>
      <MDIconButton
        tooltipName="View"
        aria-label="Open"
        color="info"
        onClick={() => {
          setViewStylistUnit(obj);
          setStylistUnitPopup(true);
        }}
      >
        <Icon fontSize="small">visibility</Icon>
      </MDIconButton>
      <MDIconButton
        tooltipName="Edit"
        aria-label="Edit"
        color="info"
        onClick={() => {
          setEditStylistUnit(obj);
          setAddStylistUnitPopup(true);
        }}
      >
        <Icon fontSize="small">edit</Icon>
      </MDIconButton>
      <MDIconButton
        tooltipName="Delete"
        aria-label="Delete"
        color="error"
        onClick={() => setStylistUnitId(obj.id)}
      >
        <Icon fontSize="small">delete</Icon>
      </MDIconButton>
    </>
  );

  const getRows = () => {
    const data: any = stylistUnits.map((obj: any) => ({
      checkbox: (
        <Checkbox
          checked={selectedItems.includes(obj.id) || selectedItems.includes(obj.id.toString())}
          onChange={(e: any) => onChangeCheckBox(e)}
          value={obj.id}
        />
      ),
      id: obj.id,
      stylist: obj.stylist_name || "-",
      rent_manager_unit_id: obj.rent_manager_unit_id || "-",
      rm_lease_id: obj.rm_lease_id || "-",
      move_in_at: obj.move_in_at ? getLocalDateTime(obj.move_in_at) : "-",
      move_out_at: obj.move_out_at ? getLocalDateTime(obj.move_out_at) : "-",
      created_at: obj.created_at ? getLocalDateTime(obj.created_at) : "-",
      updated_at: obj.updated_at ? getLocalDateTime(obj.updated_at) : "-",
      action: actionButtons(obj),
    }));
    return data;
  };

  const tableData = {
    columns: getColumns(),
    rows: getRows(),
  };

  // Start Remove selcted items
  const removeSelectedItem = (deletedId: number) => {
    setSelectedItems(selectedItems.filter((item: any) => Number(item) !== deletedId));
  };
  // End Remove selcted items

  const onDelete = () => {
    if (deleteStylistUnitId) {
      const delReq = {
        id: deleteStylistUnitId,
        setStylistUnitId,
        pageNo,
        perPage,
        search,
        sortBy,
        removeSelectedItem,
      };
      dispatch(deleteStylistUnit(delReq));
    }
  };

  const deleteDialogProps = {
    open: deleteStylistUnitId,
    onClose: () => setStylistUnitId(false),
    onSave: () => onDelete(),
    title: "Delete Stylist Units",
    size: "sm",
    saveBtn: false,
    deleteBtn: true,
    saveTbtText: "Yes",
    closeBtnText: "No",
  };

  // Remove uncheck boxes
  const clearCheckItem = () => {
    setSelectedItems([]);
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={4} pb={3}>
        <Card>
          <MDBox
            bgColor="dark"
            color="white"
            coloredShadow="dark"
            borderRadius="xl"
            alignItems="center"
            justifyContent="space-between"
            pt={1}
            pb={1}
            pl={2}
            pr={2}
            ml={2}
            mr={2}
            display="flex"
            mt={-3}
            className="page-header"
          >
            <p className="page-header-label">Stylist Units</p>
            <MDButton
              variant="gradient"
              color="light"
              size="small"
              className="xs-small"
              onClick={() => {
                setEditStylistUnit(null);
                setAddStylistUnitPopup(true);
              }}
            >
              <Icon sx={{ fontWeight: "bold" }}>add</Icon> Add new
            </MDButton>
          </MDBox>
          {(defaultSorting || defaultSorting?.length) && callFetch && (
            <DataTable
              defaultSortingColumns={defaultSorting}
              defaultFilteringColums={defaultFilteing}
              defaultDisplayColumns={displayColumns}
              setCheckedColumns={setCheckedColumns}
              table={tableData}
              canSearch
              showExportSelectedBtn
              showExportAllBtn
              selectedItems={selectedItems}
              pageCount={pageCount}
              pageNo={pageNo}
              setPageNo={setPageNo}
              totalCount={totalCount}
              fetchData={fetchData}
              exportFields={exportData.stylist_units.export_fields}
              exportTable={exportData.stylist_units.table_name}
              exportType={exportData.stylist_units.export_type}
              advanceFilter
              manualSearch
              manualSort
              isDisplayCheckAllCheckbox
              onChangeCheckAllCheckbox={checkUncheckAll}
              isCheckAll={isCheckAll}
              checkedColumns={checkedColumns}
              clearCheckItem={clearCheckItem}
              useRubyApiToExport
            />
          )}
          {deleteStylistUnitId && (
            <MDDialog {...deleteDialogProps}>
              <MDTypography variant="h6" fontWeight="medium">
                {Messages.GENERAL.SURE_TO_DELETE}
              </MDTypography>
            </MDDialog>
          )}
          {addStylistUnitPopup && (
            <AddUpdate
              open={addStylistUnitPopup}
              onClose={() => {
                setEditStylistUnit(null);
                setAddStylistUnitPopup(false);
              }}
              editStylistUnit={editStylistUnit}
              pageNo={pageNo}
              perPage={perPage}
              search={search}
              sortBy={sortBy}
            />
          )}
          {stylistUnitPopup && (
            <ViewDetails
              open={stylistUnitPopup}
              onClose={() => {
                setViewStylistUnit(null);
                setStylistUnitPopup(false);
              }}
              viewStylistUnit={viewStylistUnit}
            />
          )}
        </Card>
      </MDBox>
    </DashboardLayout>
  );
}

export default StylistUnits;
