import { Card } from "@mui/material";
import MDBox from "components/MDBox";

function Index(): JSX.Element {
  return (
    <MDBox pt={5} pb={3}>
      <Card>
        <MDBox padding={2}>Coming soon...</MDBox>
      </Card>
    </MDBox>
  );
}

export default Index;
