import { useCallback, useEffect, useLayoutEffect, useRef, useState } from "react";
import { Card, Checkbox, Icon } from "@mui/material";
import MDButton from "components/MDButton";
import MDBox from "components/MDBox";
import DataTable from "examples/Tables/DataTable";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import { getLocalDateTime, setDefaultSelectedColumns } from "helper/services";
import { useAppDispatch, useAppSelector } from "store/store";
import { deleteBooking, getAllBooking } from "store/thunk/systemAdmin";
import Messages from "helper/messages";
import exportData from "helper/exportTableConst";
import MDTypography from "components/MDTypography";
import MDDialog from "components/MDDialog";
import MDIconButton from "components/MDIconButton";
import { getDefaultCoulmns, saveSelectedAllData } from "store/thunk/connectUserThunk";
import { setDefaultsColumns } from "store/slices/connectUsersSlice";
import AddUpdate from "./addUpdate";
import ViewDetails from "./viewDetails";

function BookNowBooking(): JSX.Element {
  const dispatch = useAppDispatch();
  const { allBooking } = useAppSelector((state: any) => state.bookNowBookingSlice);
  const { defaultSelectedColumns } = useAppSelector((state) => state.connectUsersReducer);
  const { userData } = useAppSelector((state) => state.authReducer);
  const [selectedItems, setSelectedItems] = useState([]);
  const [bookNowBookingData, setBookNowBookingData] = useState([]);
  const [addUpdatePopup, setAddUpdatePopup] = useState(false);
  const [viewPopup, setViewPopup] = useState(false);
  const [viewBookingId, setViewBookingId] = useState(null);
  const [editBookingId, setEditBookingId] = useState(null);
  const [deleteBookingId, setDeleteBookingId] = useState<any>(null);
  const [pageCount, setPageCount] = useState(0);
  const [pageNo, setPageNo] = useState(0);
  const [totalCount, setTotalCount] = useState(0);
  const [search, setSearch] = useState<any>(null);
  const [sortBy, setSortBy] = useState<any>([]);
  const [perPage, setPageSize] = useState(50);
  const fetchIdRef = useRef(0);
  const [displayColumns, setDisplayColumns] = useState<any>([]);
  const [isCheckAll, setIsCheckAll] = useState(false);
  // SD-3199
  const [defaultExport, setDefaultExport] = useState<any>([]);
  const [callFetch, setCallFetch] = useState<any>(false);
  const [defaultSorting, setDefaultSorting] = useState<any>([]);
  const [defaultFilteing, setDefaultFilteing] = useState<any>([]);

  // Get columns
  const getColumns = () => {
    const columns: any = [
      {
        Header: "",
        disableSortBy: true,
        accessor: "checkbox",
        checked: true,
      },
      {
        Header: "Time Range",
        accessor: "time_range",
        export: true,
        width: "200px",
        disableCheck: true,
        checked: true,
      },
      {
        Header: "Location",
        accessor: "location",
        sorting_column: "location",
        export: true,
        disableCheck: true,
        associative_colums: "locations.name",
        checked: true,
      },
      { Header: "Query", accessor: "query", export: true, checked: true },
      { Header: "Services", accessor: "services", export: true, checked: true },
      {
        Header: "Stylist",
        accessor: "stylist",
        sorting_column: "stylist",
        export: true,
        associative_colums: "stylists.id",
      },
      { Header: "Booking User Name", accessor: "booking_user_name", export: true, checked: true },
      {
        Header: "Booking User Phone",
        accessor: "booking_user_phone",
        export: true,
        checked: false,
      },
      {
        Header: "Booking User Email",
        accessor: "booking_user_email",
        export: true,
        checked: false,
      },
      { Header: "Referring Url", accessor: "referring_url", export: true, checked: false },
      { Header: "Total", accessor: "total", export: true, checked: false },
      {
        Header: "Created At",
        accessor: "created_at",
        width: "200px",
        export: true,
        is_date: true,
        checked: false,
      },
      {
        Header: "Action",
        accessor: "action",
        export: false,
        width: "200px",
        disableSortBy: true,
        checked: false,
      },
    ];
    return columns;
  };
  const [checkedColumns, setCheckedColumns] = useState<any>(getColumns());
  const latestRefValue = useRef(checkedColumns);

  useEffect(() => {
    latestRefValue.current = checkedColumns;
  }, [checkedColumns]);

  const fetchData = useCallback(({ pageSize, pageIndex, search, sortBy, filterData, isManual }) => {
    const fetchId = ++fetchIdRef.current;
    const sort: any = sortBy || [];
    // We'll even set a delay to simulate a server here
    setTimeout(() => {
      if (fetchId === fetchIdRef.current) {
        const sentReq = {
          sendData: {
            user_id: userData?.user_id,
            table_name: exportData.book_now_bookings.table_name,
            table_columns: latestRefValue.current ? latestRefValue.current : checkedColumns,
            table_sort: sortBy && sortBy?.length ? sortBy : [],
            table_filter: filterData && filterData?.length ? filterData : [],
            default_export_data: defaultExport,
          },
        };
        if (isManual) {
          dispatch(saveSelectedAllData(sentReq));
        }
        setPageNo(pageIndex);
        setSearch(search);
        setSortBy(sort);
        setPageSize(pageSize);
        // setSelectedItems([]);
        dispatch(
          getAllBooking({
            pageSize,
            pageIndex,
            search,
            sort,
            filterData,
          })
        );
      }
    }, 1000);
  }, []);

  useLayoutEffect(() => {
    dispatch(setDefaultsColumns([]));
    // Set data in variable and Call defaultColums Thunk
    const sentReq = {
      user_id: userData?.user_id,
      table_name: exportData.book_now_bookings.table_name,
    };
    dispatch(getDefaultCoulmns(sentReq)).then((resp: any) => {
      // SD-3199

      if (resp?.payload) {
        const respFilter = resp?.payload?.table_filter || [];
        const respSorting = resp?.payload?.table_sort || [];
        const defaultDBexportData = resp?.payload?.default_export_data || [];
        const defaultDBColumn = resp?.payload?.table_columns || [];
        const tempdata = setDefaultSelectedColumns(defaultDBColumn, getColumns());
        setCheckedColumns(tempdata);
        setDefaultExport(defaultDBexportData);
        setDefaultSorting(respSorting);
        setDefaultFilteing(respFilter);
        setCallFetch(true);
        setSortBy(respSorting);
        fetchData({
          perPage,
          pageNo,
          search,
          sortBy: respSorting && respSorting?.length ? respSorting : [],
          additionalField: null,
          filterData: respFilter && respFilter?.length ? respFilter : [],
        });
      }
    });
  }, []);

  useEffect(() => {
    if (allBooking && allBooking?.bookings) {
      setBookNowBookingData(allBooking?.bookings);
      setPageCount(allBooking?.meta?.total_pages);
      setTotalCount(allBooking?.meta?.total_count);
    }
  }, [allBooking]);

  useEffect(() => {
    if (checkedColumns?.length) {
      setDisplayColumns(checkedColumns.filter((col: any) => col.checked));
    }
  }, [checkedColumns]);

  useEffect(() => {
    if (defaultSelectedColumns && defaultSelectedColumns.length) {
      const newColumns = setDefaultSelectedColumns(defaultSelectedColumns, getColumns());
      setCheckedColumns(newColumns);
    } else {
      setCheckedColumns(getColumns());
    }
  }, [defaultSelectedColumns]);

  const onChangeCheckBox = (e: any) => {
    let items = JSON.parse(JSON.stringify(selectedItems));
    if (e.target.checked && !items.includes(e.target.value)) {
      items.push(e.target.value);
      if (items.length === bookNowBookingData.length) {
        setIsCheckAll(true);
      }
    } else {
      items = items.filter((id: any) => id !== e.target.value);
      setIsCheckAll(false);
    }
    setSelectedItems(items);
  };

  const checkUncheckAll = (e: any) => {
    let allIds: any = [];
    allIds = bookNowBookingData.map((val: any) => String(val.id));
    if (e.target.checked) {
      const selectedIds = [...new Set([...selectedItems, ...allIds])];
      setSelectedItems(selectedIds);
      setIsCheckAll(true);
    } else {
      allIds = bookNowBookingData.map((val: any) => String(val.id));
      allIds = selectedItems.filter((el: any) => !allIds.includes(el));
      setSelectedItems([...allIds]);
      setIsCheckAll(false);
    }
    // setSelectedItems(allIds);
  };

  const actionButtons = (obj: any) => (
    <>
      <MDIconButton
        tooltipName="View"
        aria-label="Open"
        color="info"
        onClick={() => {
          setViewPopup(true);
          setViewBookingId(obj.id);
        }}
      >
        <Icon fontSize="small">visibility</Icon>
      </MDIconButton>
      <MDIconButton
        tooltipName="Edit"
        aria-label="Edit"
        color="info"
        onClick={() => {
          setEditBookingId(obj);
          setAddUpdatePopup(true);
        }}
      >
        <Icon fontSize="small">edit</Icon>
      </MDIconButton>
      <MDIconButton
        tooltipName="Delete"
        aria-label="Delete"
        color="error"
        onClick={() => setDeleteBookingId(obj.id)}
      >
        <Icon fontSize="small">delete</Icon>
      </MDIconButton>
    </>
  );

  const getRows = () => {
    const data: any = bookNowBookingData.map((obj: any) => ({
      checkbox: (
        <Checkbox
          checked={selectedItems.includes(obj.id) || selectedItems.includes(obj.id.toString())}
          onChange={(e: any) => onChangeCheckBox(e)}
          value={obj.id}
        />
      ),
      // For check all condition
      id: obj.id ? obj.id : "-",
      time_range: obj.time_range || "-",
      location: obj.location_name || "-",
      query: obj.query || "-",
      services: obj.services ? JSON.stringify(obj.services) : "-",
      stylist: obj.stylist_id || "-",
      booking_user_name: obj.booking_user_name || "-",
      booking_user_phone: obj.booking_user_phone || "-",
      booking_user_email: obj.booking_user_email || "-",
      referring_url: obj.referring_url || "-",
      total: obj.total || "-",
      created_at: obj.created_at ? getLocalDateTime(obj.created_at) : "-",
      action: actionButtons(obj),
    }));
    return data;
  };

  const tableData = {
    columns: getColumns(),
    rows: getRows(),
  };

  // Start Remove selcted items
  const removeSelectedItem = (deletedId: number) => {
    setSelectedItems(selectedItems.filter((item: any) => Number(item) !== deletedId));
  };
  // End Remove selcted items

  const onDelete = () => {
    if (deleteBookingId) {
      const delReq = {
        id: deleteBookingId,
        setDeleteBookingId,
        pageNo,
        perPage,
        search,
        sortBy,
        removeSelectedItem,
      };
      dispatch(deleteBooking(delReq));
    }
  };

  const deleteDialogProps = {
    open: deleteBookingId,
    onClose: () => setDeleteBookingId(false),
    onSave: () => onDelete(),
    title: "Delete Book Now Booking",
    size: "sm",
    saveBtn: false,
    deleteBtn: true,
    saveTbtText: "Yes",
    closeBtnText: "No",
  };

  // Remove uncheck boxes
  const clearCheckItem = () => {
    setSelectedItems([]);
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={4} pb={3}>
        <Card>
          <MDBox
            bgColor="dark"
            color="white"
            coloredShadow="dark"
            borderRadius="xl"
            alignItems="center"
            justifyContent="space-between"
            pt={1}
            pb={1}
            pl={2}
            pr={2}
            ml={2}
            mr={2}
            display="flex"
            mt={-3}
            className="page-header"
          >
            <p className="page-header-label">Book Now Booking</p>
            <MDButton
              variant="gradient"
              color="light"
              size="small"
              className="xs-small"
              onClick={() => {
                setAddUpdatePopup(true);
                setEditBookingId(null);
              }}
            >
              <Icon sx={{ fontWeight: "bold" }}>add</Icon> Add new
            </MDButton>
          </MDBox>
          {(defaultSorting || defaultSorting?.length) &&
            callFetch && ( // SD-3199
              <DataTable
                defaultSortingColumns={defaultSorting} // SD-3199
                defaultFilteringColums={defaultFilteing} // SD-3199
                defaultDisplayColumns={displayColumns}
                setCheckedColumns={setCheckedColumns}
                table={tableData}
                canSearch
                showExportAllBtn
                showExportSelectedBtn
                selectedItems={selectedItems}
                pageCount={pageCount}
                pageNo={pageNo}
                setPageNo={setPageNo}
                totalCount={totalCount}
                fetchData={fetchData}
                exportFields={exportData.book_now_bookings.export_fields}
                exportTable={exportData.book_now_bookings.table_name}
                exportType={exportData.book_now_bookings.export_type}
                advanceFilter
                manualSearch
                manualSort
                isDisplayCheckAllCheckbox
                onChangeCheckAllCheckbox={checkUncheckAll}
                isCheckAll={isCheckAll}
                checkedColumns={checkedColumns}
                clearCheckItem={clearCheckItem}
                useRubyApiToExport
              />
            )}
          {addUpdatePopup && (
            <AddUpdate
              open={addUpdatePopup}
              onClose={() => {
                setEditBookingId(null);
                setAddUpdatePopup(false);
              }}
              editBookingId={editBookingId}
              pageNo={pageNo}
              perPage={perPage}
              search={search}
              sortBy={sortBy}
            />
          )}
          {viewPopup && (
            <ViewDetails
              open={viewPopup}
              onClose={() => {
                setViewBookingId(null);
                setViewPopup(false);
              }}
              viewBookingId={viewBookingId}
            />
          )}
          {deleteBookingId && (
            <MDDialog {...deleteDialogProps}>
              <MDTypography variant="h6" fontWeight="medium">
                {Messages.GENERAL.SURE_TO_DELETE}
              </MDTypography>
            </MDDialog>
          )}
        </Card>
      </MDBox>
    </DashboardLayout>
  );
}

export default BookNowBooking;
