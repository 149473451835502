import { Card, FormControl, Grid, InputLabel, MenuItem, Select } from "@mui/material";

import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

function Index(): JSX.Element {
  return (
    <Card id="survey-questions" sx={{ mt: 3 }}>
      <MDBox component="form" pb={3} pt={3} px={3}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <MDTypography varient="subtitle2" fontSize="16px" fontWeight="500">
              How Likely Would You Be To Refer Another Person To Sola Salons?
            </MDTypography>
            <FormControl sx={{ mt: 2 }}>
              <InputLabel id="rate-label">Rate 1 to 10</InputLabel>
              <Select
                sx={{ height: "40px", width: "200px" }}
                id="rate"
                label="Rate 1 to 10"
                defaultValue={0}
              >
                <MenuItem value={10}>10</MenuItem>
                <MenuItem value={9}>9</MenuItem>
                <MenuItem value={8}>8</MenuItem>
                <MenuItem value={7}>7</MenuItem>
                <MenuItem value={6}>6</MenuItem>
                <MenuItem value={5}>5</MenuItem>
                <MenuItem value={4}>4</MenuItem>
                <MenuItem value={3}>3</MenuItem>
                <MenuItem value={2}>2</MenuItem>
                <MenuItem value={1}>1</MenuItem>
                <MenuItem value={0}>Select one</MenuItem>
              </Select>
            </FormControl>
          </Grid>
        </Grid>
      </MDBox>
    </Card>
  );
}

export default Index;
