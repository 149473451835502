import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Checkbox,
  FormControlLabel,
} from "@mui/material";
import MDBox from "components/MDBox";
import MDDatePicker from "components/MDDatePicker";
import { useAppDispatch } from "store/store";
import { notificationFail } from "store/slices/notificationSlice";
import { SelectedDate, SelectedDateRange } from "..";
import { ScorecardDate } from "../scorecard";

interface ScorecardDateInputProps {
  selectedDate: SelectedDate;
  // eslint-disable-next-line no-unused-vars
  onChangeDateField: (event: any) => void;
  dateArr: ScorecardDate[];
  selectedDateRange: SelectedDateRange;
  // eslint-disable-next-line no-unused-vars
  setSelectedDateRange: (selectedDateRange: SelectedDateRange) => void;
  // eslint-disable-next-line no-unused-vars
  setSelectedDate: (selectedDate: SelectedDate) => void;
  currentDateCheckBox: boolean;
  // eslint-disable-next-line no-unused-vars
  setCurrentDateCheckBox: (currentDateCheckBox: boolean) => void;
}

function ScorecardDateInput({
  selectedDate,
  onChangeDateField,
  dateArr,
  selectedDateRange,
  setSelectedDateRange,
  setSelectedDate,
  currentDateCheckBox,
  setCurrentDateCheckBox,
}: ScorecardDateInputProps) {
  const dispatch = useAppDispatch();
  const handleMonthYearDate = (date: any) => {
    const dateObject = new Date(date);
    const month = dateObject.getMonth() + 1;
    const year = dateObject.getFullYear();
    const formattedDate = `${year}-${month.toString().padStart(2, "0")}`;
    return formattedDate;
  };

  const handleStartMonthChange = (date: string) => {
    const currentDate = new Date();
    const currentMonth = currentDate.getMonth() + 1;
    const currentYear = currentDate.getFullYear();
    const monthyear = handleMonthYearDate(date);
    setCurrentDateCheckBox(false);
    if (monthyear < `${currentYear}-${currentMonth.toString().padStart(2, "0")}`) {
      const obj = { ...selectedDateRange };
      obj.startMonth = monthyear;
      setSelectedDateRange(obj);
      setSelectedDate({
        value: "",
      });
    } else {
      dispatch(notificationFail("Start month should be lower than current month"));
    }
  };

  return (
    <MDBox sx={{ display: "flex", flexDirection: "column", marginBottom: { xs: 2, md: 1 } }}>
      <FormControl>
        <FormControlLabel
          control={<Checkbox checked={currentDateCheckBox} />}
          label="Current month till date"
          onClick={() => {
            setCurrentDateCheckBox(!currentDateCheckBox);
          }}
        />
      </FormControl>
      {!currentDateCheckBox && (
        <MDBox mt={2}>
          <FormControl
            sx={{
              backgroundColor: "#fff",
              borderRadius: "10px",
              width: { xs: "100%", md: "150px" },
              paddingRight: "16px",
            }}
            disabled={currentDateCheckBox}
          >
            <InputLabel>Month - Year</InputLabel>
            <Select
              value={selectedDate?.value || ""}
              label="month-year"
              className="height45"
              onChange={onChangeDateField}
            >
              {dateArr?.length &&
                dateArr.map((date: any) => (
                  <MenuItem key={date.value} value={date.value}>
                    {date.value}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
          <FormControl
            sx={{
              backgroundColor: "#fff",
              borderRadius: "10px",
              width: { xs: "100%", md: "150px" },
              paddingRight: "16px",
            }}
          >
            <MDBox display="flex" alignItems="center">
              <MDDatePicker
                label="Start Month"
                input={{ label: "Start Month" }}
                options={{
                  dateFormat: "Y/m",
                }}
                value={selectedDateRange.startMonth ? selectedDateRange.startMonth : ""}
                onChange={([date]: any) => handleStartMonthChange(date)}
              />
            </MDBox>
          </FormControl>
          <FormControl
            sx={{
              backgroundColor: "#fff",
              borderRadius: "10px",
              width: { xs: "100%", md: "150px" },
              paddingRight: "16px",
            }}
          >
            <MDBox display="flex" alignItems="center">
              <MDDatePicker
                label="End Month"
                input={{ label: "End Month" }}
                options={{
                  dateFormat: "Y/m",
                }}
                value={selectedDateRange.endMonth ? selectedDateRange.endMonth : ""}
                onChange={([date]: any) => {
                  const obj = { ...selectedDateRange };
                  obj.endMonth = handleMonthYearDate(date);
                  setCurrentDateCheckBox(false);
                  setSelectedDateRange(obj);
                  setSelectedDate({
                    value: "",
                  });
                }}
              />
            </MDBox>
          </FormControl>
        </MDBox>
      )}
    </MDBox>
  );
}

export default ScorecardDateInput;
