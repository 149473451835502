import { Card, Grid, Icon } from "@mui/material";

import MDBox from "components/MDBox";
import MDIconButton from "components/MDIconButton";
import MDTypography from "components/MDTypography";

import EmailSms from "layouts/pages/locations/location-details/crm/follow-up/emailSms";

function FollowUp(props: any): JSX.Element {
  const { itemKey, removeFollowUp, getAccessTypePermission } = props;

  return (
    <Card id={`follow-up-${itemKey + 1}`} sx={{ overflow: "visible" }}>
      <MDBox p={3} display="flex" justifyContent="space-between">
        <MDTypography variant="h5">Follow-Up {itemKey + 1}</MDTypography>
        <MDIconButton
          tooltipName="Delete"
          aria-label="Delete"
          color="error"
          onClick={() => removeFollowUp(itemKey)}
          disabled={getAccessTypePermission("location_crm", "edit_access")}
        >
          <Icon fontSize="small">delete</Icon>
        </MDIconButton>
      </MDBox>
      <MDBox component="form" pb={3} px={3}>
        <Grid container spacing={3}>
          <EmailSms
            {...props}
            key={`follow-up-email_sms${itemKey}`}
            accessDisabled={getAccessTypePermission("location_crm", "edit_access")}
          />
        </Grid>
      </MDBox>
    </Card>
  );
}

export default FollowUp;
