import Checkbox from "@mui/material/Checkbox";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormGroup from "@mui/material/FormGroup";
import MDBox from "components/MDBox";
import MDInput from "components/MDInput";
import MDTypography from "components/MDTypography";
import CustomErrorMessage from "components/CustomErrorMessage";
import Autocomplete from "@mui/material/Autocomplete";

function Step2(props: any): JSX.Element {
  const { onChangeField, onChangeCheckbox, onChangeFieldValue, answers, errors } = props;
  return (
    <>
      <MDBox mt={5}>
        <MDTypography className="helper-info-texts" variant="body" fontWeight="bold">
          6. What is the name of your location?
        </MDTypography>
        <MDTypography className="helper-info-texts" variant="span" paragraph>
          How would you like the location name displayed in your Rockbot dashboard?
        </MDTypography>
        <MDInput
          placeholder="Type your answer here..."
          variant="standard"
          className="colorWhite"
          fullWidth
          InputLabelProps={{ shrink: true }}
          name="location"
          required
          onChange={onChangeField}
          value={answers?.location || ""}
          error={errors && errors.location ? true : false}
          helperText={errors && errors.location}
        />
      </MDBox>
      <MDBox mt={2}>
        <MDTypography className="helper-info-texts" variant="body" fontWeight="bold">
          7. What Rockbot services do you want to get for this location?
        </MDTypography>
        <MDTypography className="helper-info-texts" variant="span" paragraph>
          This only includes Rockbot services along with the Brightsign player.
        </MDTypography>
        <FormControl component="fieldset">
          <FormGroup aria-label="position" row>
            <FormControlLabel
              value="Directory"
              control={
                <Checkbox
                  checked={
                    answers?.location_services && answers?.location_services.includes("Directory")
                      ? true
                      : false
                  }
                  name="location_services"
                  onChange={onChangeCheckbox}
                  inputProps={{ "aria-label": "controlled" }}
                />
              }
              label="Directory"
              labelPlacement="end"
              className="checkbox-while-lable"
            />
            <FormControlLabel
              value="Music"
              control={
                <Checkbox
                  checked={
                    answers?.location_services && answers?.location_services.includes("Music")
                      ? true
                      : false
                  }
                  name="location_services"
                  onChange={onChangeCheckbox}
                  inputProps={{ "aria-label": "controlled" }}
                />
              }
              label="Music"
              labelPlacement="end"
              className="checkbox-while-lable"
            />
            <FormControlLabel
              value="TV"
              control={
                <Checkbox
                  checked={
                    answers?.location_services && answers?.location_services.includes("TV")
                      ? true
                      : false
                  }
                  name="location_services"
                  onChange={onChangeCheckbox}
                  inputProps={{ "aria-label": "controlled" }}
                />
              }
              label="TV"
              labelPlacement="end"
              className="checkbox-while-lable"
            />
          </FormGroup>
        </FormControl>
      </MDBox>
      <MDBox mt={2}>
        <MDTypography className="helper-info-texts" variant="body" fontWeight="bold">
          8. For your media players do you want wired or wireless internet players?
          <span className="required_star">*</span>
        </MDTypography>
        <MDTypography className="helper-info-texts" variant="span" paragraph>
          Wired players are $229 and require a hardwire ethernet connection Wireless players are
          $279 and can be connected to wi-fi networks **Wireless players are the most impacted
          players due to the global chip shortage. There may be a greater delay in receiving a
          wireless player.
        </MDTypography>
        <FormControl component="fieldset">
          <FormGroup aria-label="position" row>
            <FormControlLabel
              value="Wired ($229) per player"
              control={
                <Checkbox
                  checked={
                    answers?.media_player &&
                    answers?.media_player.includes("Wired ($229) per player")
                      ? true
                      : false
                  }
                  name="media_player"
                  onChange={onChangeCheckbox}
                  inputProps={{ "aria-label": "controlled" }}
                />
              }
              label="Wired ($229) per player"
              labelPlacement="end"
              className="checkbox-while-lable"
            />
            <FormControlLabel
              value="Wireless"
              control={
                <Checkbox
                  checked={
                    answers?.media_player && answers?.media_player.includes("Wireless")
                      ? true
                      : false
                  }
                  name="media_player"
                  onChange={onChangeCheckbox}
                  inputProps={{ "aria-label": "controlled" }}
                />
              }
              label="Wireless ($279) per player"
              labelPlacement="end"
              className="checkbox-while-lable"
            />
          </FormGroup>
        </FormControl>
        {errors && errors?.media_player && <CustomErrorMessage message={errors.media_player} />}
      </MDBox>
      <MDBox mt={2}>
        <MDTypography className="helper-info-texts" variant="body" fontWeight="bold">
          9. What time would you like your player to turn on?
        </MDTypography>
        <MDTypography className="helper-info-texts" variant="span" paragraph>
          We recommend 1 hour before the location opens
        </MDTypography>
        <Autocomplete
          disableClearable
          placeholder="Type or select an option"
          value={answers?.player_on_time || ""}
          options={["5 AM", "6 AM", "7 AM", "8 AM", "9 AM", "10 AM", "11 AM"]}
          onChange={(e: any, val: any) => onChangeFieldValue("player_on_time", val)}
          size="small"
          renderInput={(params: any) => <MDInput {...params} />}
          className="select-while-lable"
        />
      </MDBox>
      <MDBox mt={2}>
        <MDTypography className="helper-info-texts" variant="body" fontWeight="bold">
          10. What time would you like your player to turn off?
        </MDTypography>
        <Autocomplete
          disableClearable
          placeholder="Type or select an option"
          value={answers?.player_off_time || ""}
          onChange={(e: any, val: any) => onChangeFieldValue("player_off_time", val)}
          options={["5 PM", "6 PM", "7 PM", "8 PM", "9 PM", "10 PM", "11 PM", "12 AM", "1 AM"]}
          size="small"
          renderInput={(params: any) => <MDInput {...params} />}
          className="select-while-lable"
        />
      </MDBox>
    </>
  );
}

export default Step2;
