import { useState } from "react";
import MDDialog from "components/MDDialog";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import { Grid, AccordionSummary, Accordion, AccordionDetails } from "@mui/material";
import ImagePreview from "layouts/pages/ImagePreview";
// import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { displayMultipleValue } from "helper/services";

function ViewDetails(props: any): JSX.Element {
  const { open, onClose, updateData } = props;

  const [isPreview, setImagePreviewPopup] = useState(false);
  const [imageToPreview, setImageToPreview] = useState("");

  const previewImage = (image: any) => {
    setImagePreviewPopup(true);
    setImageToPreview(image);
  };

  const closeImagePreview = () => {
    setImagePreviewPopup(false);
    setImageToPreview("");
  };

  const dialogProps = {
    open,
    onClose,
    title: "View Tool and Resources",
    size: "md",
    saveBtn: false,
  };

  const getTypograpghy = (label: any, value: any) => (
    <Grid item xs={12} sm={12} md={12} lg={6}>
      <MDTypography fontWeight="bold" variant="button">
        {label}
      </MDTypography>
      <MDBox>
        <MDTypography variant="button">{value || "-"}</MDTypography>
      </MDBox>
    </Grid>
  );

  const getAccordianSummary = (label: any) => (
    <AccordionSummary
      className="location-accordion"
      // expandIcon={<ExpandMoreIcon />}
      aria-controls="publishbh-content"
    >
      <MDTypography variant="h6">{label}</MDTypography>
    </AccordionSummary>
  );

  const getImageElement = (name: any, imageData: any) => {
    if (imageData) {
      return (
        <Grid item xs={12} sm={6} md={6}>
          <MDTypography fontWeight="bold" variant="button">
            {name}
          </MDTypography>
          <Grid container mt={2}>
            <Grid item xs={12} sm={6} md={6}>
              {imageData && (
                <Grid
                  container
                  onClick={() => previewImage(imageData)}
                  justifyContent="left"
                  alignItems="center"
                  display="flex"
                >
                  <Grid item className="thumbnail-img">
                    <img height={100} width={100} src={imageData} alt="location" />
                  </Grid>
                </Grid>
              )}
            </Grid>
          </Grid>
        </Grid>
      );
    }
    return null;
  };

  return (
    <MDDialog {...dialogProps}>
      <Grid container>
        <Grid item xs={12} sm={12} md={12}>
          <Accordion expanded>
            {getAccordianSummary("Basic Info")}
            <AccordionDetails>
              <Grid container spacing={2}>
                {getTypograpghy("Title", updateData?.title || "-")}
                {getTypograpghy("Description", updateData?.description || "-")}
              </Grid>
              <Grid container spacing={2}>
                {getTypograpghy("Brand", updateData?.brand_name || "-")}
                {getTypograpghy("Categories", displayMultipleValue(updateData?.category))}
              </Grid>
              <Grid container spacing={2}>
                {getTypograpghy("Tags", displayMultipleValue(updateData?.tag))}
                {getImageElement("Image", updateData?.image_url)}
              </Grid>
              <Grid container spacing={2}>
                {getTypograpghy("File", updateData?.file_url || "-")}
                {getTypograpghy("Link Url", updateData?.brand_name || "-")}
              </Grid>
              <Grid container spacing={2}>
                {getTypograpghy("Youtube Url", updateData?.youtube_url || "-")}
                {getTypograpghy("Is Featured", updateData?.is_featured ? "Yes" : "No")}
                {getTypograpghy("Countries", displayMultipleValue(updateData?.country))}
              </Grid>
            </AccordionDetails>
          </Accordion>
        </Grid>
      </Grid>
      <ImagePreview
        imageData={imageToPreview}
        open={isPreview}
        onCloseImage={() => {
          closeImagePreview();
        }}
      />
    </MDDialog>
  );
}

export default ViewDetails;
