import { Card, Icon, Grid } from "@mui/material";
import { useNavigate } from "react-router-dom";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDIconButton from "components/MDIconButton";
import MDTypography from "components/MDTypography";
import MDDialog from "components/MDDialog";
import CustomAutoSearch from "components/CustomAutoSearch";
import DataTable from "examples/Tables/DataTable";
import { useEffect, useState } from "react";
import { useAppDispatch } from "store/store";
import Messages from "helper/messages";
import { associationAddLocation, deleteAssociationLocation } from "store/thunk/associationThunk";
import EditPopup from "layouts/pages/associations/locations/editPopup";
import ViewDetails from "layouts/pages/locations/location-wizard/viewDetails";
import { getPermission, getLocationCrmAccess, adminSuperAdmin, getUrlName } from "helper/services";

function Locations(props: any): JSX.Element {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { association } = props;
  const [editPopup, setEditPopup] = useState(false);
  const [location, setLocation] = useState(null);
  const [locationListData, setLocationData] = useState([]);
  const [addLocation, setAddLocation] = useState(false);
  const [deleteLocationId, setDeleteLocationId] = useState<any>(null);
  const [detailsPopup, setDetailsPopup] = useState<any>(null);

  useEffect(() => {
    if (association && Array.isArray(association?.locations) && association?.locations?.length) {
      setLocationData(association?.locations?.filter((obj: any) => obj));
    }
  }, [association]);

  const goToLocationDetails = (obj: any) => {
    navigate(`/locations/${getUrlName(obj?.name)}`, {
      state: { prevPath: "/locations", defaultTab: "0", id: obj?.id },
    });
  };

  const actionButtons = (obj: any) => (
    <>
      <MDIconButton
        tooltipName="View Location"
        aria-label="Open"
        color="info"
        onClick={() => setDetailsPopup(obj)}
      >
        <Icon fontSize="small">visibility</Icon>
      </MDIconButton>
      <MDIconButton
        tooltipName="Edit Location"
        aria-label="Open"
        color="info"
        onClick={() => goToLocationDetails(obj)}
      >
        <Icon fontSize="small">edit</Icon>
      </MDIconButton>
      <MDIconButton
        tooltipName={locationListData.length <= 1 ? "Must Have At Least 1 Location" : "Delete"}
        aria-label="Delete"
        disabled={locationListData.length <= 1}
        color="error"
        onClick={() => setDeleteLocationId(obj?.id)}
      >
        <Icon fontSize="small">delete</Icon>
      </MDIconButton>
    </>
  );

  const getRows = () => {
    const data: any = locationListData?.map((obj: any) => ({
      store_id: obj?.store_id,
      location_name: obj?.name ? obj?.name : "-",
      address: obj?.address_1 ? obj?.address_1 : "-",
      city: obj?.city ? obj?.city : "-",
      state: obj?.state ? obj?.state : "-",
      action: actionButtons(obj),
    }));
    return data;
  };

  const tableData = {
    columns: [
      { Header: "Sola ID", accessor: "store_id", width: "150px", export: true },
      { Header: "Location name", accessor: "location_name", export: true },
      { Header: "Address", accessor: "address", export: true },
      { Header: "City", accessor: "city", export: true },
      { Header: "State", accessor: "state", export: true },
      { Header: "Action", accessor: "action", export: false, disableSortBy: true, width: "200px" },
    ],
    rows: getRows(),
  };

  const onClose = () => {
    setLocation(null);
    setAddLocation(false);
  };

  const onAddLocation = () => {
    const getAccess = getLocationCrmAccess();
    dispatch(
      associationAddLocation({
        locationId: location.cms_location_id,
        associationId: association?.id,
        defaultAccess: getAccess?.defaultAccess || [],
        onClose,
      })
    );
  };

  const onDelete = () => {
    if (deleteLocationId) {
      dispatch(
        deleteAssociationLocation({
          locationId: deleteLocationId,
          associationId: association?.id,
        })
      );
      setDeleteLocationId(false);
    }
  };

  const locationDialog = {
    open: addLocation,
    onClose,
    onSave: onAddLocation,
    title: "Add Location",
    size: "sm",
    saveBtn: location ? true : false,
    overflowVisible: "overflowVisible",
  };

  const deleteDialogProps = {
    open: deleteLocationId,
    onClose: () => setDeleteLocationId(false),
    onSave: () => onDelete(),
    title: "Delete Location",
    size: "sm",
    saveBtn: false,
    deleteBtn: true,
    saveTbtText: "Yes",
    closeBtnText: "No",
  };

  return (
    <Card id="locations" sx={{ overflow: "visible" }}>
      <MDBox p={3} display="flex" justifyContent="space-between">
        <MDTypography className="page-header-label" variant="h5">
          Locations
        </MDTypography>
        <MDButton
          className="xs-small"
          variant="gradient"
          color="light"
          size="small"
          onClick={() => setAddLocation(true)}
        >
          <Icon sx={{ fontWeight: "bold" }}>add</Icon> Add New
        </MDButton>
      </MDBox>
      <DataTable table={tableData} canSearch searchText="Search..." />
      {editPopup && <EditPopup open={editPopup} onClose={() => setEditPopup(false)} />}

      {addLocation && (
        <MDDialog {...locationDialog}>
          <Grid container spacing={2} mt={1}>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <CustomAutoSearch
                idKey="cms_location_id"
                nameKey="name"
                fieldLabel="Location"
                parentKey="location"
                apiName="get-locations"
                responseKey="data"
                name="locations"
                value={location || null}
                required
                slsAPI
                onChange={(event: any, val: any) => setLocation(val)}
                createNewLink={
                  adminSuperAdmin() && getPermission("locations", "locations_location")
                    ? "/locations"
                    : ""
                }
              />
            </Grid>
          </Grid>
        </MDDialog>
      )}

      {detailsPopup && (
        <ViewDetails
          open={detailsPopup ? true : false}
          updateData={{ cms_location_id: detailsPopup?.id }}
          onClose={() => setDetailsPopup(false)}
        />
      )}

      {deleteLocationId && (
        <MDDialog {...deleteDialogProps}>
          <MDTypography variant="h6" fontWeight="medium">
            {Messages.GENERAL.SURE_TO_DELETE}
          </MDTypography>
        </MDDialog>
      )}
    </Card>
  );
}

export default Locations;
