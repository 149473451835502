import { Grid, Card, FormControl } from "@mui/material";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import CustomPhoneInput from "components/CustomPhoneInput";
import MESSAGES from "helper/messages";
import MDEditor from "components/MDEditor";

const modules = {
  toolbar: [
    [{ header: [1, 2, false] }],
    ["bold", "italic", "underline", "strike", "blockquote"],
    [{ list: "ordered" }, { list: "bullet" }, { indent: "-1" }, { indent: "+1" }],
    ["link", "image"],
    ["clean"],
  ],
};

const formats = [
  "header",
  "bold",
  "italic",
  "underline",
  "strike",
  "blockquote",
  "list",
  "bullet",
  "indent",
  "link",
  "image",
];

function Website(props: any): JSX.Element {
  const { webPageDetail, onChangeDescription, onChangeField, errors } = props;
  return (
    <Card id="website">
      <MDBox p={3}>
        <MDTypography variant="h5">Website</MDTypography>
      </MDBox>
      <MDBox pb={3} px={3}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6} md={6} lg={6}>
            <MDInput
              label="Business Name / Display Name"
              variant="standard"
              fullWidth
              name="business_name"
              placeholder="Business Name / Display Name"
              onChange={onChangeField}
              value={webPageDetail?.business_name || ""}
              error={errors && errors.business_name ? true : false}
              helperText={errors && errors.business_name}
              infoText={MESSAGES.INFO.REQUIRED}
              required
            />
          </Grid>
          <Grid item xs={12} sm={6} md={6} lg={6}>
            <MDInput
              label="URL Name"
              variant="standard"
              fullWidth
              name="url_name"
              placeholder="URL Name"
              onChange={onChangeField}
              value={webPageDetail?.url_name || ""}
              error={errors && errors.url_name ? true : false}
              helperText={errors && errors.url_name}
              infoText={MESSAGES.INFO.URL_NAME}
              required
            />
          </Grid>
          <Grid item xs={12} sm={6} md={6} lg={6}>
            <MDInput
              label="Subtitle"
              variant="standard"
              fullWidth
              name="subtitle"
              placeholder="Subtitle"
              onChange={onChangeField}
              value={webPageDetail?.subtitle || ""}
              error={errors && errors.subtitle ? true : false}
              helperText={errors && errors.subtitle}
              infoText={MESSAGES.INFO.OPTIONAL_LENGTH_255}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={6} lg={6}>
            <MDInput
              label="Studio Number"
              variant="standard"
              fullWidth
              type="text"
              name="studio_number"
              placeholder="Studio Number"
              onChange={onChangeField}
              value={webPageDetail?.studio_number || ""}
              error={errors && errors.studio_number ? true : false}
              helperText={errors && errors.studio_number}
              infoText={MESSAGES.INFO.OPTIONAL_LENGTH_255}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={6} lg={6}>
            <MDInput
              label="Website Email Address"
              variant="standard"
              fullWidth
              name="website_email"
              placeholder="Email Address"
              value={webPageDetail?.website_email || ""}
              onChange={onChangeField}
              error={errors && errors.website_email ? true : false}
              helperText={errors && errors.website_email}
              // infoText={MESSAGES.INFO.REQUIRED}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={6} lg={6}>
            <FormControl fullWidth>
              <MDInput
                variant="standard"
                fullWidth
                label="Website Phone Number"
                name="website_phone"
                placeholder="(000) 000-0000"
                id="phone-input"
                InputProps={{
                  inputComponent: CustomPhoneInput as any,
                }}
                value={webPageDetail?.website_phone || ""}
                onChange={onChangeField}
                error={errors && errors.website_phone ? true : false}
                helperText={errors && errors.website_phone}
                // infoText={MESSAGES.INFO.REQUIRED}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <MDTypography variant="button">Description</MDTypography>
            <MDEditor
              value={webPageDetail?.description ? webPageDetail.description : ""}
              onChange={(val: any, delta: any, source: any) =>
                onChangeDescription(val, delta, source)
              }
              modules={modules}
              formats={formats}
            />
          </Grid>
          {/* <Grid item xs={12} sm={6} md={6} lg={6}>
            <MDInput
              label="Url"
              variant="standard"
              fullWidth
              name="url"
              placeholder=""
              onChange={onChangeField}
              value={webPageDetail?.url || ""}
              error={errors && errors.url ? true : false}
              helperText={errors && errors.url}
              infoText={MESSAGES.INFO.WEBSITE_BOOKING_URL}
              required
            />
          </Grid> */}
        </Grid>
      </MDBox>
    </Card>
  );
}

export default Website;
