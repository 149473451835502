import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "store/store";
import MDDialog from "components/MDDialog";
import MDInput from "components/MDInput";
import MDBox from "components/MDBox";
import MDIconButton from "components/MDIconButton";
import CustomDropZone from "components/CustomDropZone";
import { Grid, Icon, Autocomplete, Checkbox, TextField } from "@mui/material";
import Validations from "helper/validations";
import { saveEducationHeroImages } from "store/thunk/mobileAppThunk";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import ImagePreview from "layouts/pages/ImagePreview";
import CustomRequiredLabel from "components/CustomRequiredLabel";
import CustomErrorMessage from "components/CustomErrorMessage";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

function addUpdate(props: any): JSX.Element {
  const dispatch = useAppDispatch();

  const { countriesList } = useAppSelector((state) => state.systemAdminSlice);
  const { open, onClose, updateData, pageNo, perPage, search, sortBy } = props;
  const [isUpdate, setIsUpdate] = useState<any>(false);
  const [educationHeroImageData, setEducationHeroImageData] = useState<any>({});
  const [imagePreview, setImagePreview] = useState<any>("");
  const [errors, setErrors] = useState<any>({});
  const [countriesListData, setCountriesListData] = useState<any>([]);
  const [selectedCountries, setSelectedCountries] = useState<any>([]);

  const [isPreview, setImagePreviewPopup] = useState(false);
  const [imageToPreview, setImageToPreview] = useState("");

  const previewImage = (image: any) => {
    setImagePreviewPopup(true);
    setImageToPreview(image);
  };

  const closeImagePreview = () => {
    setImagePreviewPopup(false);
    setImageToPreview("");
  };

  useEffect(() => {
    if (countriesList?.countries) {
      setCountriesListData(countriesList.countries);
    }
  }, [countriesList]);

  const onSave = () => {
    const error = Validations.validateEducationHeroImagesForm(educationHeroImageData);
    setErrors(error);
    if (!Object.keys(error).length) {
      const req = {
        educationHeroImageData,
        onClose,
        isUpdate,
        pageNo,
        perPage,
        search,
        sortBy,
      };
      dispatch(saveEducationHeroImages(req));
    }
  };
  useEffect(() => {
    if (updateData) {
      setIsUpdate(true);
      setEducationHeroImageData(updateData);
      setImagePreview(updateData.image_large_url);
      const temp: any = [];
      updateData.countries.forEach((val: any) => {
        const getFilter = countriesList.countries.find((v: any) => v.id === val.id);
        if (getFilter) {
          temp.push(getFilter);
        }
      });
      setSelectedCountries(temp);
    }
  }, [updateData]);

  const onChangeImage = async (files: any, type: any) => {
    if (files[0]) {
      setEducationHeroImageData({ ...educationHeroImageData, [type]: files[0] });
      const file = files[0];
      const reader = new FileReader();
      reader.onload = function (e) {
        setImagePreview(e.target.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const onChangeField = (e: any, field: string) => {
    setEducationHeroImageData({ ...educationHeroImageData, [field]: e.target.value });
  };

  const onImageRemove = async (e: any) => {
    e.stopPropagation();
    setImagePreview("");
    setEducationHeroImageData({ ...educationHeroImageData, image_original_url: null });
  };

  const dialogProps = {
    open,
    onSave,
    onClose,
    title: isUpdate ? "Update Education Hero Image" : "Add Education Hero Image",
    size: "sm",
    saveTbtText: isUpdate ? "Update" : "Save",
  };
  return (
    <MDDialog {...dialogProps}>
      <MDBox mt={2}>
        <MDInput
          label="Action Link"
          variant="standard"
          fullWidth
          name="action_link"
          placeholder="action_link"
          value={educationHeroImageData?.action_link || ""}
          onChange={(event: any) => onChangeField(event, "action_link")}
          error={errors && errors.action_link ? true : false}
          helperText={errors && errors.action_link}
        />
      </MDBox>
      <MDBox mt={2}>
        <CustomRequiredLabel label="Image" />
        <Grid container>
          <Grid item xs={12} md={8}>
            <CustomDropZone
              multipleUpload={false}
              acceptedFiles={{
                "image/*": [".gif", ".png", ".jpg", ".jpeg"],
              }}
              onDrop={(e: any) => onChangeImage(e, "image_original_url")}
            >
              Drop your image here, or Browse
            </CustomDropZone>
          </Grid>
          <Grid item xs={12} md={4} alignItems="center" display="flex">
            {imagePreview && (
              <Grid
                container
                onClick={() => previewImage(imagePreview)}
                justifyContent="center"
                alignItems="center"
                textAlign="center"
              >
                <Grid item className="imageWithDeleteIcon">
                  <img
                    width="90%"
                    style={{ maxHeight: "100px" }}
                    src={imagePreview}
                    alt="original"
                  />
                  <MDIconButton
                    tooltipName="Delete"
                    aria-label="Delete"
                    className="removeBtnClass"
                    onClick={(e: any) => onImageRemove(e)}
                  >
                    <Icon fontSize="small">close</Icon>
                  </MDIconButton>
                </Grid>
              </Grid>
            )}
          </Grid>
        </Grid>
        {errors && errors.image && <CustomErrorMessage message={errors.image} />}
      </MDBox>
      <MDBox mt={2}>
        <MDInput
          label="Position"
          variant="standard"
          fullWidth
          required
          type="number"
          name="position"
          placeholder="position"
          value={educationHeroImageData?.position}
          onChange={(event: any) => onChangeField(event, "position")}
          error={errors && errors.position ? true : false}
          helperText={errors && errors.position}
        />
      </MDBox>
      <MDBox mt={2}>
        <Autocomplete
          multiple
          id="countries"
          options={countriesListData}
          disableCloseOnSelect
          value={selectedCountries}
          getOptionLabel={(option: any) => option?.name}
          onChange={(event: any, newValue: any) => {
            setSelectedCountries(newValue);
            setEducationHeroImageData({ ...educationHeroImageData, countries: newValue });
          }}
          renderOption={(props: any, option: any, { selected }: any) => (
            <li {...props} key={option.id}>
              <Checkbox
                icon={icon}
                checkedIcon={checkedIcon}
                style={{ marginRight: 8 }}
                checked={selected}
              />
              {option.name}
            </li>
          )}
          renderInput={(params: any) => (
            <TextField
              {...params}
              required
              variant="standard"
              label="Countries"
              placeholder="Countries"
            />
          )}
        />
        {errors && errors.countries && <CustomErrorMessage message={errors.countries} />}
      </MDBox>
      <ImagePreview
        imageData={imageToPreview}
        open={isPreview}
        onCloseImage={() => {
          closeImagePreview();
        }}
      />
    </MDDialog>
  );
}

export default addUpdate;
