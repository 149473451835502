import { useEffect, useState } from "react";
import MDDialog from "components/MDDialog";
import MDBox from "components/MDBox";
import { Grid, AccordionSummary, Accordion, AccordionDetails, Icon } from "@mui/material";
// import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { getLocalDateTime } from "helper/services";
import MDTypography from "components/MDTypography";

function ViewDetails(props: any): JSX.Element {
  const { open, onClose, updateData } = props;
  const [msa, setMsa] = useState<any>({});

  useEffect(() => {
    if (updateData?.id) {
      setMsa(updateData);
    }
  }, [updateData]);

  const dialogProps = {
    open,
    onClose,
    title: "View Legacy Account",
    size: "md",
    saveBtn: false,
  };

  const getIcon = (value: any) => {
    if (value) {
      return (
        <Icon fontSize="medium" color="success">
          check_circle_rounded
        </Icon>
      );
    }
    return (
      <Icon fontSize="medium" color="error">
        cancel
      </Icon>
    );
  };

  const getIconElement = (name: any, iconValue: any) => {
    const value: any = iconValue;
    return (
      <Grid item xs={12} sm={6} md={6} mt={1}>
        <MDTypography fontWeight="bold" variant="button">
          {name}
        </MDTypography>
        <MDBox>{getIcon(value)}</MDBox>
      </Grid>
    );
  };

  const getTypograpghy = (label: any, value: any) => (
    <Grid item xs={12} sm={12} md={12} lg={6}>
      <MDTypography fontWeight="bold" variant="button">
        {label}
      </MDTypography>
      <MDBox>
        <MDTypography variant="button">{value || "-"}</MDTypography>
      </MDBox>
    </Grid>
  );

  const getAccordianSummary = (label: any) => (
    <AccordionSummary
      className="location-accordion"
      // expandIcon={<ExpandMoreIcon />}
      aria-controls="publishbh-content"
    >
      <MDTypography variant="h6">{label}</MDTypography>
    </AccordionSummary>
  );

  return (
    <MDDialog {...dialogProps}>
      <MDBox mt={1}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12} md={12}>
            <Accordion expanded>
              {getAccordianSummary("Basic Info")}
              <AccordionDetails>
                <Grid container spacing={2}>
                  {getTypograpghy("Username", msa?.email || "-")}
                  {getTypograpghy("Email Address", msa?.email_address || "-")}
                </Grid>
                <Grid container spacing={2}>
                  {getTypograpghy("Sign In Count", msa?.sign_in_count || "-")}
                  {getTypograpghy("Last Sign In At", getLocalDateTime(msa?.last_sign_in_at) || "-")}
                </Grid>
                <Grid container spacing={2}>
                  {getIconElement("Franchisee", msa?.franchisee || "-")}
                </Grid>
              </AccordionDetails>
            </Accordion>
            <Accordion expanded>
              {getAccordianSummary("Text and Email Integration")}
              <AccordionDetails>
                <Grid container spacing={2}>
                  {getTypograpghy("Mailchimp Api Key", msa?.mailchimp_api_key || "-")}
                  {getTypograpghy("Callfire App Login", msa?.callfire_app_login || "-")}
                </Grid>
                <Grid container spacing={2}>
                  {getTypograpghy("Callfire App Password", msa?.callfire_app_password || "-")}
                </Grid>
              </AccordionDetails>
            </Accordion>
            <Accordion expanded>
              {getAccordianSummary("Sola Pro")}
              <AccordionDetails>
                <Grid container spacing={2}>
                  {getTypograpghy("Sola Pro Country Admin", msa?.sola_pro_country_admin || "-")}
                </Grid>
              </AccordionDetails>
            </Accordion>
          </Grid>
        </Grid>
      </MDBox>
    </MDDialog>
  );
}

export default ViewDetails;
