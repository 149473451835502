import { SortableElement } from "react-sortable-hoc";
import { Icon, Tooltip } from "@mui/material";
import MDIconButton from "components/MDIconButton";
import ImageListItem from "@mui/material/ImageListItem";
import ImageListItemBar from "@mui/material/ImageListItemBar";

function SortableItem(props: any) {
  const {
    value,
    previewImage,
    onImageRemove,
    onOpenEditModal,
    index1,
    showEditBtn,
    showDeleteBtn,
  } = props;
  return (
    <ImageListItem className="image-item" key={value.id} onClick={() => previewImage(value?.url)}>
      <Tooltip title={value?.alt_text || ""} placement="bottom">
        <img
          src={value?.url}
          srcSet={value?.url}
          alt={value?.alt_text}
          loading="lazy"
          style={{ height: "150px", width: "170px" }}
        />
      </Tooltip>

      <ImageListItemBar
        sx={{ background: "unset" }}
        actionIcon={
          <>
            {showDeleteBtn && (
              <MDIconButton
                color="white"
                tooltipName={`Delete Image ${value?.id}`}
                aria-label={`delete Image ${value?.id}`}
                onClick={(e: any) => onImageRemove(e, value?.id, index1)}
                className="action-btn"
              >
                <Icon fontSize="small">delete</Icon>
              </MDIconButton>
            )}
            {showEditBtn && (
              <MDIconButton
                color="white"
                tooltipName="Edit Image Alt Text"
                aria-label={`Edit Image ${value?.id}`}
                onClick={(e: any) => onOpenEditModal(e, value?.id)}
                className="action-btn"
              >
                <Icon fontSize="small">edit</Icon>
              </MDIconButton>
            )}
          </>
        }
      />
    </ImageListItem>
  );
}
export default SortableElement(SortableItem);
