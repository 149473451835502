import { useEffect, useState } from "react";
import { FormControl, Grid, Icon, Tooltip, Autocomplete } from "@mui/material";
import MDDialog from "components/MDDialog";
import MDInput from "components/MDInput";
import MDBox from "components/MDBox";
import Validations from "helper/validations";
import { getAuthUser } from "helper/services";
import constants from "helper/constants";
import { createUpdateSMSTemplate } from "store/thunk/locationThunk";
import { useAppDispatch } from "store/store";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";

function TemplateSettings(props: any): JSX.Element {
  const dispatch = useAppDispatch();
  const { open, onClose, location, editTemplateData, isUpdate } = props;

  const userData = getAuthUser();
  const [smsTemplateData, setSmsTemplateData] = useState(null);
  const [errors, setErrors] = useState<any>(null);

  const [token, setToken] = useState("");
  const [tokenOpen, setTokenOpen] = useState(false);

  useEffect(() => {
    if (editTemplateData) {
      setSmsTemplateData({
        templateName: editTemplateData?.template_name,
        templateContent: editTemplateData?.template_content,
      });
    }
  }, [editTemplateData]);

  const onAddTemplate = () => {
    const error = Validations.validateSMSTemplate(smsTemplateData);
    setErrors(error);
    if (!Object.keys(error).length) {
      if (isUpdate) {
        dispatch(
          createUpdateSMSTemplate({
            id: parseFloat(editTemplateData?.id),
            templateName: smsTemplateData?.templateName,
            templateAlias: editTemplateData?.template_alias,
            templateContent: smsTemplateData?.templateContent,
            locationId: location?.cms_location_id,
            userId: userData?.user_id,
            onClose,
          })
        );
      } else {
        dispatch(
          createUpdateSMSTemplate({
            templateName: smsTemplateData?.templateName,
            templateContent: smsTemplateData?.templateContent,
            locationId: location?.cms_location_id,
            userId: userData?.user_id,
            onClose,
          })
        );
      }
    }
  };

  const onChangeField = (e: any, field: string) => {
    setSmsTemplateData({ ...smsTemplateData, [field]: e.target.value });
  };

  const onChangeFieldText = (e: any) => {
    setSmsTemplateData({ ...smsTemplateData, [e.target.name]: e.target.value });
  };

  const addToken = (event: any, value: any) => {
    if (value?.value) {
      setToken("");
      setTokenOpen(false);
      const ctrl: any = document.getElementById("templateContentArea");

      const templateData = smsTemplateData?.templateContent || "";
      const newTemplateContent = `${templateData.slice(0, ctrl.selectionStart)} {{${
        value?.value
      }}} ${templateData.slice(ctrl.selectionStart)}`;

      setSmsTemplateData({ ...smsTemplateData, templateContent: newTemplateContent });
    }
  };

  const addDialog = {
    open,
    onClose,
    onSave: onAddTemplate,
    title: isUpdate ? "Edit SMS Template" : "Add SMS Template",
    size: "sm",
    saveTbtText: isUpdate ? "Update" : "Save",
    saveBtn: true,
  };

  return (
    <MDDialog {...addDialog}>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <MDInput
            label="Template Name"
            variant="standard"
            fullWidth
            required
            name="templateName"
            onChange={(event: any) => onChangeField(event, "templateName")}
            placeholder="Template Name"
            value={smsTemplateData?.templateName}
            error={errors && errors.templateName ? true : false}
            helperText={errors && errors.templateName}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <MDBox className="template-content-wrapper">
            <MDBox display="flex">
              <Tooltip title="Add Token" arrow>
                <MDButton
                  onClick={() => setTokenOpen(!tokenOpen)}
                  size="small"
                  sx={{ maxWidth: 40, color: "#0b8cfb" }}
                  className="add-token-btn"
                >
                  <Icon fontSize="small" color="info">
                    add
                  </Icon>
                </MDButton>
              </Tooltip>
              {tokenOpen && (
                <MDBox className="placeholder-dorpdown">
                  <FormControl fullWidth>
                    <Autocomplete
                      onChange={addToken}
                      value={token || null}
                      options={constants.smsTokens}
                      groupBy={(option) => option.table_name}
                      getOptionLabel={(option: any) => option.label}
                      renderGroup={(params: any) => (
                        <li {...params}>
                          <MDTypography
                            sx={{ pl: 1 }}
                            variant="subtitle2"
                            className="font-bold text-capitalize"
                          >
                            {params.group}
                          </MDTypography>
                          <MDTypography>{params.children}</MDTypography>
                        </li>
                      )}
                      renderOption={(props, data) => (
                        <MDTypography key={data.value} {...props} variant="subtitle2">
                          {data.label}
                        </MDTypography>
                      )}
                      renderInput={(params) => <MDInput {...params} label="Select Token" />}
                    />
                  </FormControl>
                </MDBox>
              )}
            </MDBox>

            <MDInput
              label="Template Content"
              fullWidth
              required
              multiline
              rows={4}
              name="templateContent"
              id="templateContentArea"
              onClick={() => setTokenOpen(false)}
              onChange={onChangeFieldText}
              placeholder="Template Content"
              value={smsTemplateData?.templateContent || ""}
              error={errors && errors.templateContent ? true : false}
              helperText={errors && errors.templateContent}
            />
          </MDBox>
        </Grid>
      </Grid>
    </MDDialog>
  );
}

export default TemplateSettings;
