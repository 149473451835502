import { useEffect, useState } from "react";
import { useAppDispatch } from "store/store";
import MDDialog from "components/MDDialog";
import MDInput from "components/MDInput";
import MDBox from "components/MDBox";
import Validations from "helper/validations";
import { saveRegions } from "store/thunk/mobileAppThunk";
import CustomAutoSearch from "components/CustomAutoSearch";

function addUpdate(props: any): JSX.Element {
  const dispatch = useAppDispatch();
  const { open, onClose, updateData, pageNo, perPage, search, sortBy } = props;
  const [isUpdate, setIsUpdate] = useState<any>(false);
  const [regionsData, setRegionsData] = useState<any>({});
  const [errors, setErrors] = useState<any>({});

  const onSave = () => {
    const error = Validations.validateRegionsForm(regionsData);
    setErrors(error);
    if (!Object.keys(error).length) {
      const reqData = {
        ...regionsData,
        country_ids: regionsData.countries_name.map((v: any) => v.id),
      };
      const req = {
        regionsData: reqData,
        onClose,
        isUpdate,
        pageNo,
        perPage,
        search,
        sortBy,
      };
      dispatch(saveRegions(req));
    }
  };
  useEffect(() => {
    if (updateData) {
      setIsUpdate(true);
      setRegionsData(updateData);
    }
  }, [updateData]);

  const onChangeField = (e: any, field: string) => {
    setRegionsData({ ...regionsData, [field]: e.target.value });
  };

  const onChangeAutoComplete = (name: string, value: any) => {
    setRegionsData({ ...regionsData, [name]: value });
  };

  const dialogProps = {
    open,
    onSave,
    onClose,
    title: isUpdate ? "Update Regions" : "Add Regions",
    size: "sm",
    saveTbtText: isUpdate ? "Update" : "Save",
  };
  return (
    <MDDialog {...dialogProps}>
      <MDBox mt={2}>
        <MDInput
          label="Name"
          variant="standard"
          fullWidth
          required
          name="name"
          placeholder="name"
          value={regionsData?.name || ""}
          onChange={(event: any) => onChangeField(event, "name")}
          error={errors && errors.name ? true : false}
          helperText={errors && errors.name}
        />
      </MDBox>
      <MDBox mt={2}>
        <CustomAutoSearch
          idKey="id"
          nameKey="name"
          fieldLabel="Countries"
          apiName="countries"
          responseKey="countries"
          name="countries_name"
          value={regionsData?.countries_name || []}
          onChange={onChangeAutoComplete}
          error={errors && errors?.countries_name ? true : false}
          multiple
          required
        />
      </MDBox>
      <MDBox mt={2}>
        <MDInput
          label="Position"
          variant="standard"
          fullWidth
          required
          type="number"
          name="position"
          placeholder="position"
          value={regionsData?.position || ""}
          onChange={(event: any) => onChangeField(event, "position")}
          error={errors && errors.position ? true : false}
          helperText={errors && errors.position}
        />
      </MDBox>
    </MDDialog>
  );
}

export default addUpdate;
