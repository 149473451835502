import axios from "axios";
import Config from "config/config";

const baseURL = Config.API_URL;

const slsApiClient = (options?: any) => {
  const headers = {
    "x-api-key": Config.SLS_X_API_KEY,
  };
  if (options && options?.formData) {
    Object.assign(headers, { "content-type": "multipart/form-data" });
  }
  let url = baseURL;
  if (options && options?.local) {
    url = options.local;
  }
  return axios.create({
    baseURL: url,
    withCredentials: false,
    headers,
  });
};

/**
 *
 * @param options
 * @returns
 * This client is used to test against serverless locally
 * SLS_X_API_KEY_LOCAL can be gotten from this message:
 *  "Remember to use 'x-api-key' on the request headers.
    Key with token: XXXXXXXXXXXXXXXXXXXXX" on your terminal,
    when you successfully start serverless locally
 */
export const slsApiClientLocal = (options?: any) => {
  const headers = {
    "x-api-key": Config.SLS_X_API_KEY_LOCAL,
  };
  if (options && options?.formData) {
    Object.assign(headers, { "content-type": "multipart/form-data" });
  }
  let url = "http://localhost:3000/dev/";
  if (options && options?.local) {
    url = options.local;
  }
  return axios.create({
    baseURL: url,
    withCredentials: false,
    headers,
  });
};
export default slsApiClient;
