/* eslint-disable no-unused-vars */
import { Dialog, DialogTitle, Grid, Icon, Typography } from "@mui/material";
import config from "config/config";
import axios from "axios";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDDatePicker from "components/MDDatePicker";
import MDIconButton from "components/MDIconButton";
import MDInput from "components/MDInput";
import Messages from "helper/messages";
import MDTypography from "components/MDTypography";
import { notificationFail, notificationSuccess } from "store/slices/notificationSlice";
import { useAppDispatch } from "store/store";

function SaveDate(dateString: string) {
  const parts = dateString.split("-");
  const year = parts[0];
  const month = parts[1];
  const day = parts[2]?.split("T")[0];
  const formattedDate = `${year}-${month}-${day}`;
  return dateString === "-" ? dateString : formattedDate;
}

interface AddEditDialogProps {
  selectedItem: {
    [key: string]: string | number;
  };
  selectedDialog: string;
  setSelectedItem: (value: { [key: string]: string | number }) => void;
  onClose: (value: string) => void;
  title: string;
  fetchApi: boolean;
  setFetchApi: (value: boolean) => void;
}

function AddEditDialog(props: AddEditDialogProps) {
  const { onClose, selectedItem, selectedDialog, setSelectedItem, title, fetchApi, setFetchApi } =
    props;
  const dispatch = useAppDispatch();

  const handleClose = () => {
    onClose("");
  };

  const handleChange = (e: any) => {
    const updatedItem = { ...selectedItem };
    updatedItem[e.target.name] = e.target.value;
    setSelectedItem(updatedItem);
  };

  const handleDateChange = (date: any) => {
    const updatedItem = { ...selectedItem };
    const dateObject = new Date(date);
    const day = dateObject.getDate();
    const month = dateObject.getMonth() + 1;
    const year = dateObject.getFullYear();
    const formattedDate = `${year}-${month.toString().padStart(2, "0")}-${day
      .toString()
      .padStart(2, "0")}`;
    updatedItem.open_date = SaveDate(formattedDate);
    setSelectedItem(updatedItem);
  };

  const handleClosedDate = (date: any) => {
    const updatedItem = { ...selectedItem };
    const dateObject = new Date(date);
    const day = dateObject.getDate();
    const month = dateObject.getMonth() + 1;
    const year = dateObject.getFullYear();
    const formattedDate = `${year}-${month.toString().padStart(2, "0")}-${day
      .toString()
      .padStart(2, "0")}`;
    updatedItem.closed_date = SaveDate(formattedDate);
    setSelectedItem(updatedItem);
  };

  const handleMonthYearDate = (date: any) => {
    const updatedItem = { ...selectedItem };
    const dateObject = new Date(date);
    const month = dateObject.getMonth() + 1;
    const year = dateObject.getFullYear();
    const formattedDate = `${year}-${month.toString().padStart(2, "0")}`;
    updatedItem.month_year = formattedDate;
    setSelectedItem(updatedItem);
  };

  const submitItem = () => {
    const resultObject: { [key: string]: string | number } = {
      id: "",
      sola_id: "",
      rm_location_id: "",
      rm_property_id: "",
      rm_location_name: "",
      nps_score: "",
      nps_of_participants: "",
      open_date: "",
      royalty_rate: "",
      marketing_rate: "",
      site_name: "",
      city: "",
      state: "",
      address: "",
      contact_name: "",
      entity: "",
      msa: "",
      country: "",
      region: "",
      obsolete: "",
      closed_date: "",
      month_year: "",
    };
    Object.keys(selectedItem).forEach((key) => {
      const value = selectedItem[key];
      resultObject[key] = value !== "undefined" ? value : "";
    });

    if (selectedDialog === "create") {
      axios
        .post(`${config.REPORT_URL}/report/saveMasterData`, resultObject, {
          headers: {
            "x-api-key": config.REPORT_KEY,
          },
        })
        .then((response) => {
          setFetchApi(!fetchApi);
          dispatch(
            notificationSuccess(response.data.message || Messages.SUCCESS.MASTER_DATA_CREATED)
          );
        })
        .catch((err) => {
          dispatch(
            notificationFail(err?.response?.data?.message || Messages.ERROR.SOMETHING_WENT_WRONG)
          );
        });
    } else {
      axios
        .post(`${config.REPORT_URL}/report/editMasterData`, resultObject, {
          headers: {
            "x-api-key": config.REPORT_KEY,
          },
        })
        .then((response) => {
          setFetchApi(!fetchApi);
          dispatch(
            notificationSuccess(response.data.message || Messages.SUCCESS.MASTER_DATA_UPDATED)
          );
        })
        .catch((err) => {
          dispatch(
            notificationFail(err?.response?.data?.message || Messages.ERROR.SOMETHING_WENT_WRONG)
          );
        });
    }
    onClose("");
  };

  return (
    <Dialog open>
      <Grid container>
        <Grid item md={12} display="flex" justifyContent="space-between" alignItems="center">
          <DialogTitle>{title}</DialogTitle>
          <MDBox mr={2}>
            <MDIconButton
              tooltipName="Close"
              aria-label="Close"
              color="error"
              onClick={handleClose}
            >
              <Icon fontSize="small">close</Icon>
            </MDIconButton>
          </MDBox>
        </Grid>
        <Grid item md={12} sx={{ padding: "0px 30px 0px 30px" }}>
          <MDInput
            label="Sola ID"
            name="sola_id"
            sx={{ width: "100%", paddingBottom: "10px" }}
            value={selectedItem?.sola_id ? selectedItem.sola_id : ""}
            required
            onChange={handleChange}
          />
          <MDInput
            label="RM Location ID"
            name="rm_location_id"
            sx={{ width: "100%", paddingBottom: "10px" }}
            value={selectedItem?.rm_location_id ? selectedItem.rm_location_id : ""}
            required
            onChange={handleChange}
          />
          <MDInput
            label="RM Location Name"
            name="rm_location_name"
            sx={{ width: "100%", paddingBottom: "10px" }}
            value={selectedItem?.rm_location_name ? selectedItem.rm_location_name : ""}
            required
            onChange={handleChange}
          />
          <MDInput
            label="RM Property ID"
            name="rm_property_id"
            sx={{ width: "100%", paddingBottom: "10px" }}
            value={selectedItem?.rm_property_id ? selectedItem.rm_property_id : ""}
            required
            onChange={handleChange}
          />
          <MDBox display="flex" padding="5px 0px 5px 0px">
            <Grid item md={5}>
              <MDBox display="flex" alignItems="center" sx={{ padding: "5px 5px 0px 0px" }}>
                <MDTypography variant="button">Month-Year:</MDTypography>
                <MDDatePicker
                  label="Month-Year"
                  input={{ placeholder: "Select a date" }}
                  options={{
                    dateFormat: "Y-m",
                  }}
                  value={selectedItem?.month_year ? selectedItem.month_year : ""}
                  onChange={([date]: any) => handleMonthYearDate(date)}
                />
              </MDBox>
            </Grid>
            <Grid item md={3}>
              <MDBox sx={{ padding: "5px 0px 5px 0px" }}>
                <MDInput
                  label="NPS Score"
                  name="nps_score"
                  sx={{ width: "100%", paddingRight: "5px" }}
                  value={selectedItem?.nps_score ? selectedItem.nps_score : ""}
                  onChange={handleChange}
                />
              </MDBox>
            </Grid>
            <Grid item md={4}>
              <MDBox sx={{ padding: "5px 0px 5px 0px" }}>
                <MDInput
                  label="NPS Of Participants"
                  name="nps_of_participants"
                  sx={{ width: "100%", paddingRight: "5px" }}
                  value={selectedItem?.nps_of_participants ? selectedItem.nps_of_participants : ""}
                  onChange={handleChange}
                />
              </MDBox>
            </Grid>
          </MDBox>
          <MDBox display="flex" padding="5px 0px 5px 0px">
            <Grid item md={5}>
              <MDBox display="flex" alignItems="center" sx={{ padding: "5px 5px 0px 0px" }}>
                <MDTypography variant="button">Open Date:</MDTypography>
                <MDDatePicker
                  label="Open Date"
                  input={{ placeholder: "Select a date" }}
                  options={{
                    dateFormat: "Y-m-d",
                  }}
                  value={selectedItem?.open_date ? selectedItem.open_date : ""}
                  onChange={([date]: any) => handleDateChange(date)}
                />
              </MDBox>
            </Grid>
            <Grid item md={3}>
              <MDBox sx={{ padding: "5px 0px 5px 0px" }}>
                <MDInput
                  label="Royalty Rate"
                  name="royalty_rate"
                  sx={{ width: "100%", paddingRight: "5px" }}
                  value={selectedItem?.royalty_rate ? selectedItem.royalty_rate : ""}
                  required
                  onChange={handleChange}
                />
              </MDBox>
            </Grid>
            <Grid item md={4}>
              <MDBox sx={{ padding: "5px 0px 5px 0px" }}>
                <MDInput
                  label="Marketing Rate"
                  name="marketing_rate"
                  sx={{ width: "100%", paddingRight: "5px" }}
                  value={selectedItem?.marketing_rate ? selectedItem.marketing_rate : ""}
                  required
                  onChange={handleChange}
                />
              </MDBox>
            </Grid>
          </MDBox>
          <MDInput
            label="Site name"
            name="site_name"
            sx={{ width: "100%", paddingBottom: "10px" }}
            value={selectedItem?.site_name ? selectedItem.site_name : ""}
            onChange={handleChange}
          />
          <MDInput
            label="City"
            name="city"
            sx={{ width: "100%", paddingBottom: "10px" }}
            value={selectedItem?.city ? selectedItem.city : ""}
            onChange={handleChange}
          />
          <MDInput
            label="State"
            name="state"
            sx={{ width: "100%", paddingBottom: "10px" }}
            value={selectedItem?.state ? selectedItem.state : ""}
            onChange={handleChange}
          />
          <MDInput
            label="Address"
            name="address"
            sx={{ width: "100%", paddingBottom: "10px" }}
            value={selectedItem?.address ? selectedItem.address : ""}
            onChange={handleChange}
          />
          <MDInput
            label="Contact Name"
            name="contact_name"
            sx={{ width: "100%", paddingBottom: "10px" }}
            value={selectedItem?.contact_name ? selectedItem.contact_name : ""}
            onChange={handleChange}
          />
          <MDInput
            label="Entity"
            name="entity"
            sx={{ width: "100%", paddingBottom: "10px" }}
            value={selectedItem?.entity ? selectedItem.entity : ""}
            onChange={handleChange}
          />
          <MDInput
            label="MSA"
            name="msa"
            sx={{ width: "100%", paddingBottom: "10px" }}
            value={selectedItem?.msa ? selectedItem.msa : ""}
            onChange={handleChange}
          />
          <MDInput
            label="Country"
            name="country"
            sx={{ width: "100%", paddingBottom: "10px" }}
            value={selectedItem?.country ? selectedItem.country : ""}
            onChange={handleChange}
          />
          <MDInput
            label="Region"
            name="region"
            sx={{ width: "100%", paddingBottom: "10px" }}
            value={selectedItem?.region ? selectedItem.region : ""}
            onChange={handleChange}
          />
          {selectedDialog === "edit" && (
            <MDBox display="flex" alignItems="center">
              <Grid item md={4}>
                <MDBox sx={{ padding: "5px 0px 5px 0px" }}>
                  <MDInput
                    label="Obsolete"
                    name="obsolete"
                    sx={{ width: "100%", paddingRight: "5px" }}
                    value={selectedItem?.obsolete ? selectedItem.obsolete : ""}
                    onChange={handleChange}
                  />
                </MDBox>
              </Grid>
              <Grid item md={8}>
                <MDBox display="flex" alignItems="center">
                  <MDTypography variant="button" sx={{ paddingRight: "10px" }}>
                    Closed Date:
                  </MDTypography>
                  <MDDatePicker
                    label="Closed Date"
                    input={{ placeholder: "Select a date" }}
                    options={{
                      dateFormat: "Y-m-d",
                    }}
                    value={selectedItem?.closed_date ? selectedItem.closed_date : ""}
                    onChange={([date]: any) => handleClosedDate(date)}
                  />
                </MDBox>
              </Grid>
            </MDBox>
          )}
        </Grid>
        {selectedDialog === "edit" && (
          <Grid md={12} sx={{ padding: "0px 30px 0px 30px" }}>
            <Typography variant="caption">*Please send 1 if the record is obselete*</Typography>
          </Grid>
        )}
        <Grid item md={12} display="flex" justifyContent="end" sx={{ padding: "10px" }}>
          <MDButton
            variant="contained"
            color="primary"
            sx={{ marginRight: "10px" }}
            onClick={handleClose}
          >
            Cancel
          </MDButton>
          <MDButton variant="contained" color="success" onClick={submitItem}>
            Submit
          </MDButton>
        </Grid>
      </Grid>
    </Dialog>
  );
}

export default AddEditDialog;
