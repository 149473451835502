import { useEffect, useRef, useState, useCallback } from "react";
import { Card, Icon, Checkbox } from "@mui/material";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDDialog from "components/MDDialog";
import MDTypography from "components/MDTypography";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import DataTable from "examples/Tables/DataTable";
import { getCountriesList } from "store/thunk/systemAdmin";
import { deleteNotificationRecord, getNotificationsList } from "store/thunk/mobileAppThunk";
import { useAppDispatch, useAppSelector } from "store/store";
import { getDefaultCoulmns } from "store/thunk/connectUserThunk";
import { setDefaultsColumns } from "store/slices/connectUsersSlice";
import {
  getLocalDateTime,
  displayMultipleValue,
  systemAdmin,
  insertinArray,
  setDefaultSelectedColumns,
} from "helper/services";
import Messages from "helper/messages";
import exportData from "helper/exportTableConst";
import MDIconButton from "components/MDIconButton";
import AddUpdate from "./addUpdate";
import ViewDetails from "./viewDetails";

function Index(): JSX.Element {
  const dispatch = useAppDispatch();
  const [addUpdatePopup, setAddUpdatePopup] = useState<any>(false);
  const [updateData, setUpdateData] = useState<any>(null);
  const { notificationList } = useAppSelector((state) => state.mobileAppSlice);
  const { defaultSelectedColumns } = useAppSelector((state) => state.connectUsersReducer);
  const { userData } = useAppSelector((state) => state.authReducer);
  const [detailsPopup, setDetailsPopup] = useState<any>(false);
  const [deleteNotifications, setDeleteNotifications] = useState<any>(false);
  const [selectedItems, setSelectedItems] = useState([]);
  const [notifications, setNotifications] = useState([]);
  const [pageCount, setPageCount] = useState(0);
  const [pageNo, setPageNo] = useState(0);
  const [perPage, setPageSize] = useState(50);
  const [totalCount, setTotalCount] = useState(0);
  const [search, setSearch] = useState<any>(null);
  const [sortBy, setSortBy] = useState<any>([]);
  const [isCheckAll, setIsCheckAll] = useState(false);
  const [displayColumns, setDisplayColumns] = useState<any>([]);
  const fetchIdRef = useRef(0);
  // Get columns
  const getColumns = () => {
    let columns: any = [
      {
        Header: "",
        disableSortBy: true,
        accessor: "checkbox",
        export: false,
        width: "80px",
        checked: true,
      },
      {
        Header: "Brand",
        accessor: "brand",
        sorting_column: "brand",
        associative_colums: "brands.name",
        width: "200px",
        export: true,
        disableCheck: true,
        checked: true,
      },
      {
        Header: "Deal",
        accessor: "deal",
        sorting_column: "deal",
        associative_colums: "deals.title",
        width: "200px",
        export: true,
        checked: true,
      },
      {
        Header: "Tool",
        accessor: "tool",
        sorting_column: "tool",
        associative_colums: "tools.title",
        width: "200px",
        export: true,
        checked: true,
      },
      {
        Header: "Sola Class",
        accessor: "sola_class",
        sorting_column: "sola_class",
        associative_colums: "sola_classes.title",
        width: "200px",
        export: true,
        checked: true,
      },
      {
        Header: "Video",
        accessor: "video",
        sorting_column: "video",
        associative_colums: "videos.title",
        width: "200px",
        export: true,
        checked: true,
      },
      {
        Header: "Notification Text",
        accessor: "notification_text",
        width: "200px",
        export: true,
        checked: false,
      },
      {
        Header: "Send Push Notification",
        accessor: "send_push_notification",
        width: "180px",
        export: true,
        checked: false,
      },
      {
        Header: "Created At",
        accessor: "created_at",
        width: "200px",
        export: true,
        is_date: true,
        checked: false,
      },
      {
        Header: "Updated At",
        accessor: "updated_at",
        width: "200px",
        export: true,
        is_date: true,
        checked: false,
      },
      {
        Header: "Blog",
        accessor: "blog",
        sorting_column: "blog",
        associative_colums: "blogs.title",
        width: "200px",
        export: true,
        checked: false,
      },
      { Header: "Date Sent", accessor: "date_sent", width: "200px", export: false, checked: false },
      { Header: "Title", accessor: "title", width: "200px", export: false, checked: false },
      {
        Header: "Send At",
        accessor: "send_at",
        width: "200px",
        export: false,
        is_date: true,
        checked: false,
      },
      {
        Header: "Stylists",
        accessor: "stylists",
        sorting_column: "stylists",
        associative_colums: "stylists.name",
        width: "200px",
        export: false,
        checked: false,
      },
      {
        Header: "Action",
        accessor: "action",
        width: "200px",
        export: false,
        disableSortBy: true,
        checked: true,
      },
    ];
    if (systemAdmin()) {
      columns = insertinArray(columns, 1, {
        Header: "ID",
        accessor: "id",
        export: true,
        disableCheck: true,
        checked: true,
      });
    }
    return columns;
  };
  const [checkedColumns, setCheckedColumns] = useState<any>(getColumns());

  useEffect(() => {
    dispatch(setDefaultsColumns([]));

    // Set data in variable and Call defaultColums Thunk
    const sentReq = {
      user_id: userData?.user_id,
      table_name: exportData.notifications.table_name,
    };
    dispatch(getDefaultCoulmns(sentReq));
  }, []);

  useEffect(() => {
    dispatch(getCountriesList({ all: true }));
  }, []);

  useEffect(() => {
    if (notificationList && notificationList?.notifications) {
      setNotifications(notificationList.notifications);
      setPageCount(notificationList?.meta?.total_pages);
      setTotalCount(notificationList?.meta?.total_count);
    }
  }, [notificationList]);

  useEffect(() => {
    if (checkedColumns?.length) {
      setDisplayColumns(checkedColumns.filter((col: any) => col.checked));
    }
  }, [checkedColumns]);

  useEffect(() => {
    if (defaultSelectedColumns && defaultSelectedColumns?.length) {
      const newColumns = setDefaultSelectedColumns(defaultSelectedColumns, getColumns());
      setCheckedColumns(newColumns);
    } else {
      setCheckedColumns(getColumns());
    }
  }, [defaultSelectedColumns]);

  const fetchData = useCallback(({ pageSize, pageIndex, search, sortBy, filterData }) => {
    const fetchId = ++fetchIdRef.current;
    const sort: any = sortBy || [];
    // We'll even set a delay to simulate a server here
    setTimeout(() => {
      if (fetchId === fetchIdRef.current) {
        setPageNo(pageIndex);
        setSearch(search);
        setSortBy(sort);
        setPageSize(pageSize);
        // setSelectedItems([]);
        setIsCheckAll(false);
        dispatch(getNotificationsList({ pageSize, pageIndex, search, sort, filterData }));
      }
    }, 1000);
  }, []);

  const closeModal = () => {
    setUpdateData(null);
    setAddUpdatePopup(false);
  };

  const openNotificationEditModal = (data: any) => {
    setUpdateData(data);
    setAddUpdatePopup(true);
  };

  const openDeleteModal = (data: any) => {
    setUpdateData(data);
    setDeleteNotifications(true);
  };

  const openDetailPopup = (data: any) => {
    setUpdateData(data);
    setDetailsPopup(true);
  };
  const closeDetailPopup = () => {
    setUpdateData("");
    setDetailsPopup(false);
  };

  const actionButtons = (object: any) => (
    <>
      <MDIconButton
        tooltipName="View"
        aria-label="Info"
        color="info"
        onClick={() => openDetailPopup(object)}
      >
        <Icon fontSize="small">visibility</Icon>
      </MDIconButton>
      <MDIconButton
        tooltipName="Edit"
        aria-label="Edit"
        color="info"
        onClick={() => openNotificationEditModal(object)}
      >
        <Icon fontSize="small">edit</Icon>
      </MDIconButton>
      <MDIconButton
        tooltipName="Delete"
        aria-label="Delete"
        color="error"
        onClick={() => openDeleteModal(object)}
      >
        <Icon fontSize="small">delete</Icon>
      </MDIconButton>
    </>
  );

  const onChangeCheckBox = (e: any) => {
    let items = JSON.parse(JSON.stringify(selectedItems));
    if (e.target.checked && !items.includes(e.target.value)) {
      items.push(e.target.value);
      if (items.length === notifications.length) {
        setIsCheckAll(true);
      }
    } else {
      items = items.filter((id: any) => id !== e.target.value);
      setIsCheckAll(false);
    }
    setSelectedItems(items);
  };

  const checkUncheckAll = (e: any) => {
    let allIds: any = [];
    allIds = notifications.map((val: any) => String(val.id));
    if (e.target.checked) {
      const selectedIds = [...new Set([...selectedItems, ...allIds])];
      setSelectedItems(selectedIds);
      setIsCheckAll(true);
    } else {
      allIds = notifications.map((val: any) => String(val.id));
      allIds = selectedItems.filter((el: any) => !allIds.includes(el));
      setSelectedItems([...allIds]);
      setIsCheckAll(false);
    }
    // setSelectedItems(allIds);
  };

  const getRows = () => {
    const data: any = notifications.map((obj: any) => ({
      checkbox: (
        <Checkbox
          checked={selectedItems.includes(obj.id.toString())}
          onChange={(e: any) => onChangeCheckBox(e)}
          value={obj.id}
        />
      ),
      id: obj.id ? obj.id : "-",
      brand: obj.brand_name ? obj.brand_name : "-",
      deal: obj.deal_name ? obj.deal_name : "-",
      tool: obj.tool_name ? obj.tool_name : "-",
      sola_class: obj.sola_class_name ? obj.sola_class_name : "-",
      video: obj.video_name ? obj.video_name : "-",
      notification_text: obj.notification_text ? obj.notification_text : "-",
      send_push_notification: obj.send_push_notification ? obj.send_push_notification : "-",
      created_at: obj.created_at ? getLocalDateTime(obj.created_at) : "-",
      updated_at: obj.updated_at ? getLocalDateTime(obj.updated_at) : "-",
      blog: obj.blog_id ? obj.blog_name : "-",
      date_sent: obj.date_sent ? getLocalDateTime(obj.date_sent) : "-",
      title: obj.title ? obj.title : "-",
      send_at: obj.send_at ? getLocalDateTime(obj.send_at) : "-",
      stylists: obj.stylists ? displayMultipleValue(obj.stylists) : "-",
      action: actionButtons(obj),
    }));
    return data;
  };

  const tableData = {
    columns: getColumns(),
    rows: getRows(),
  };

  const onCloseDeleteModal = () => {
    setDeleteNotifications(false);
    setUpdateData("");
  };

  // Start Remove selcted items
  const removeSelectedItem = (deletedId: number) => {
    setSelectedItems(selectedItems.filter((item: any) => Number(item) !== deletedId));
  };
  // End Remove selcted items

  const onDelete = () => {
    if (updateData?.id) {
      const DelReq = {
        notificationsData: updateData,
        onCloseDeleteModal,
        pageNo,
        perPage,
        search,
        sortBy,
        removeSelectedItem,
      };
      dispatch(deleteNotificationRecord(DelReq));
    }
  };

  const deleteDialogProps = {
    open: deleteNotifications,
    onClose: () => onCloseDeleteModal(),
    onSave: () => onDelete(),
    title: "Delete Notifications",
    size: "sm",
    saveBtn: false,
    deleteBtn: true,
    saveTbtText: "Yes",
    closeBtnText: "No",
  };

  // Remove uncheck boxes
  const clearCheckItem = () => {
    setSelectedItems([]);
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={4} pb={3}>
        <Card>
          <MDBox
            bgColor="dark"
            color="white"
            coloredShadow="dark"
            borderRadius="xl"
            alignItems="center"
            justifyContent="space-between"
            pt={1}
            pb={1}
            pl={2}
            pr={2}
            ml={2}
            mr={2}
            display="flex"
            mt={-3}
            className="page-header"
          >
            <p className="page-header-label">Notifications</p>
            <MDButton
              variant="gradient"
              color="light"
              size="small"
              className="xs-small"
              onClick={() => setAddUpdatePopup(true)}
            >
              <Icon sx={{ fontWeight: "bold" }}>add</Icon> Add New
            </MDButton>
          </MDBox>
          <DataTable
            defaultDisplayColumns={displayColumns}
            setCheckedColumns={setCheckedColumns}
            table={tableData}
            canSearch
            searchText="Search notifications..."
            selectedItems={selectedItems}
            showExportSelectedBtn
            showExportAllBtn
            pageCount={pageCount}
            pageNo={pageNo}
            setPageNo={setPageNo}
            totalCount={totalCount}
            fetchData={fetchData}
            manualSearch
            exportFields={exportData.notifications.export_fields}
            exportTable={exportData.notifications.table_name}
            exportType={exportData.notifications.export_type}
            advanceFilter
            manualSort
            isDisplayCheckAllCheckbox
            onChangeCheckAllCheckbox={checkUncheckAll}
            isCheckAll={isCheckAll}
            checkedColumns={checkedColumns}
            clearCheckItem={clearCheckItem}
            useRubyApiToExport
          />
          {addUpdatePopup && (
            <AddUpdate
              open={addUpdatePopup}
              updateData={updateData}
              pageNo={pageNo}
              perPage={perPage}
              search={search}
              sortBy={sortBy}
              onClose={() => closeModal()}
            />
          )}
          {detailsPopup && (
            <ViewDetails
              open={detailsPopup}
              updateData={updateData}
              onClose={() => closeDetailPopup()}
            />
          )}
          {deleteNotifications && (
            <MDDialog {...deleteDialogProps}>
              <MDTypography variant="h6" fontWeight="medium">
                {Messages.GENERAL.SURE_TO_DELETE}
              </MDTypography>
            </MDDialog>
          )}
        </Card>
      </MDBox>
    </DashboardLayout>
  );
}

export default Index;
