import React, { useState } from "react";
import MDDialog from "components/MDDialog";
import MDBox from "components/MDBox";
import Validations from "helper/validations";
import { saveImportDeal } from "store/thunk/deal-import-thunk";
// import { useAppDispatch } from "store/store";
import { getAllAssociationLocations, getPermission, adminSuperAdmin } from "helper/services";
import CustomAutoSearch from "components/CustomAutoSearch";
import CustomDropZone from "components/CustomDropZone";
import { Grid, Icon, InputLabel } from "@mui/material";
import fileimg from "assets/images/file-image.svg";
import { notificationFail } from "store/slices/notificationSlice";
import { useAppDispatch } from "store/store";
import CustomErrorMessage from "components/CustomErrorMessage";
import MDIconButton from "components/MDIconButton";
import constants from "helper/constants";

function Import(props: any): JSX.Element {
  const dispatch = useAppDispatch();
  const { open, onClose, pageNo, perPage, search, sortBy, filterData, userData } = props;
  const [importData, setImportData] = useState<any>();
  const [errors, setErrors] = useState<any>({});

  const onSave = () => {
    const error = Validations.validateDealImportForm(importData);
    setErrors(error);
    if (!Object.keys(error).length) {
      const formData = new FormData();
      formData.append("file", importData?.file[0]);
      formData.append("location_id", importData?.location?.id);
      formData.append("uploaded_by", userData?.user_id);
      const req = {
        formData,
        onClose,
        pageNo,
        perPage,
        search,
        sortBy,
        filterData,
      };
      dispatch(saveImportDeal(req));
    }
  };

  const dialogProps = {
    open,
    onClose,
    onSave,
    title: "Import ",
    size: "sm",
    overflowVisible: "overflowVisible",
  };

  const onChangeAutoComplete = (name: string, value: any) => {
    setImportData({ ...importData, [name]: value });
    setErrors({ ...errors, location: null });
  };

  const onUpload = (files: any) => {
    if (!constants?.dealImportExtention.includes(files[0]?.path?.split(".").pop())) {
      dispatch(notificationFail("Only CSV file allowed."));
      return;
    }
    setImportData({ ...importData, file: files && files[0] ? files : null });
    setErrors({ ...errors, file: null });
  };

  return (
    <MDDialog {...dialogProps}>
      <MDBox mt={1}>
        <CustomAutoSearch
          idKey="id"
          nameKey="name"
          fieldLabel="Location"
          apiName="locations"
          responseKey="locations"
          name="location"
          value={importData?.location || []}
          error={errors && errors.location}
          required
          onChange={onChangeAutoComplete}
          usersData={getAllAssociationLocations()}
          createNewLink={
            adminSuperAdmin() && getPermission("locations", "locations_location")
              ? "/locations"
              : ""
          }
        />
      </MDBox>
      <MDBox mt={2}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <InputLabel sx={{ mb: 1, fontSize: 12 }}>Upload CSV File </InputLabel>
            <CustomDropZone
              multipleUpload={false}
              maxFiles={1}
              maxSize={1048576 * 2}
              onDrop={onUpload}
            >
              Drop your CSV file here,
            </CustomDropZone>
            {errors && errors?.file && <CustomErrorMessage message={errors?.file} />}
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            {importData?.file && (
              <MDBox
                item
                mt={2}
                className="imageWithDeleteIcon"
                display="inline-block"
                sx={{ marginRight: "15px !important" }}
              >
                <img src={fileimg} alt={importData?.file} width={25} />
                <MDIconButton
                  tooltipName="Delete"
                  aria-label="Delete"
                  className="removeBtnClass"
                  onClick={() => setImportData({ ...importData, file: null })}
                  sx={{ height: "15px", width: "15px" }}
                >
                  <Icon fontSize="small" sx={{ fontSize: "13px !important" }}>
                    close
                  </Icon>
                </MDIconButton>
              </MDBox>
            )}
          </Grid>
        </Grid>
      </MDBox>
    </MDDialog>
  );
}

export default Import;
