import { useCallback, useEffect, useLayoutEffect, useRef, useState } from "react";
import { Card, Checkbox, Icon } from "@mui/material";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import DataTable from "examples/Tables/DataTable";
import { useAppDispatch, useAppSelector } from "store/store";
import {
  getFranchiseeInquiries,
  deleteFranchiseeInquiries,
} from "store/thunk/franchiseeWebsiteThunk";
import {
  getLocalDateTime,
  insertinArray,
  systemAdmin,
  setDefaultSelectedColumns,
} from "helper/services";
import Messages from "helper/messages";
import MDDialog from "components/MDDialog";
import MDTypography from "components/MDTypography";
import exportData from "helper/exportTableConst";
import MDIconButton from "components/MDIconButton";
import { getDefaultCoulmns, saveSelectedAllData } from "store/thunk/connectUserThunk";
import { setDefaultsColumns } from "store/slices/connectUsersSlice";
import AddUpdate from "./addUpdate";
import ViewDetails from "./viewDetails";

function InquiriesList(props: any): JSX.Element {
  const { country } = props;
  const dispatch = useAppDispatch();
  const { allInquiries } = useAppSelector((state) => state.franchiseeWebsiteSlice);
  const { defaultSelectedColumns } = useAppSelector((state) => state.connectUsersReducer);
  const { userData } = useAppSelector((state) => state.authReducer);
  const [allInquiry, setAllInquiry] = useState([]);
  const [selectedItems, setSelectedItems] = useState([]);
  const [pageCount, setPageCount] = useState(0);
  const [pageNo, setPageNo] = useState(0);
  const [perPage, setPageSize] = useState(50);
  const [totalCount, setTotalCount] = useState(0);
  const [addUpdatePopup, setAddUpdatePopup] = useState(false);
  const [viewPopup, setViewPopup] = useState(false);
  const [viewData, setViewData] = useState<any>(null);
  const [editInquiries, setEditInquiries] = useState<any>(null);
  const [deleteInquiryId, setDeleteInquiryId] = useState<any>(null);
  const [search, setSearch] = useState<any>(null);
  const [sortBy, setSortBy] = useState<any>([]);
  const [isCheckAll, setIsCheckAll] = useState(false);
  const [displayColumns, setDisplayColumns] = useState<any>([]);
  const fetchIdRef = useRef(0);
  const [defaultExport, setDefaultExport] = useState<any>([]);
  const [callFetch, setCallFetch] = useState<any>(false);
  const [defaultSorting, setDefaultSorting] = useState<any>([]);
  const [defaultFilteing, setDefaultFilteing] = useState<any>([]);

  // Get columns
  const getColumns = () => {
    let columns: any = [
      {
        Header: "",
        disableSortBy: true,
        accessor: "checkbox",
        width: "80px",
        checked: true,
      },
      {
        Header: "First Name",
        accessor: "first_name",
        export: true,
        disableCheck: true,
        checked: true,
      },
      { Header: "Last Name", accessor: "last_name", export: true, checked: true },
      { Header: "Email Address", accessor: "email_address", export: true, checked: true },
      { Header: "Phone Number", accessor: "phone_number", export: true, checked: true },
      {
        Header: "Multi Unit Operator",
        accessor: "multi_unit_operator",
        export: true,
        is_boolean: true,
        checked: true,
      },
      { Header: "Liquid Capital", accessor: "liquid_capital", export: true, checked: false },
      { Header: "City", accessor: "city", export: true, checked: false },
      { Header: "State", accessor: "state", export: true, checked: false },
      {
        Header: "Agree To Receive Email",
        accessor: "agree_to_receive_email",
        export: true,
        is_boolean: true,
        checked: false,
      },
      { Header: "Created At", accessor: "created_at", export: true, is_date: true, checked: false },
      { Header: "Updated At", accessor: "updated_at", export: true, is_date: true, checked: false },
      { Header: "Utm Source", accessor: "utm_source", export: true, checked: false },
      { Header: "Utm Campaign", accessor: "utm_campaign", export: true, checked: false },
      { Header: "Utm Medium", accessor: "utm_medium", export: true, checked: false },
      { Header: "Utm Content", accessor: "utm_content", export: true, checked: false },
      { Header: "Utm Term", accessor: "utm_term", export: true, checked: false },
      { Header: "Country", accessor: "country", export: true, checked: false },
      {
        Header: "Action",
        accessor: "action",
        export: false,
        width: "180px",
        disableSortBy: true,
        checked: false,
      },
    ];
    if (systemAdmin()) {
      columns = insertinArray(columns, 1, {
        Header: "ID",
        accessor: "id",
        export: true,
        disableCheck: true,
        checked: true,
      });
    }
    return columns;
  };
  const [checkedColumns, setCheckedColumns] = useState<any>(getColumns());
  const latestRefValue = useRef(checkedColumns);

  useEffect(() => {
    latestRefValue.current = checkedColumns;
  }, [checkedColumns]);

  const fetchData = useCallback(
    ({ pageSize, pageIndex, search, sortBy, additionalField, filterData, isManual }) => {
      const fetchId = ++fetchIdRef.current;
      const sort: any = sortBy || [];
      // We'll even set a delay to simulate a server here
      setTimeout(() => {
        if (fetchId === fetchIdRef.current) {
          const sentReq = {
            sendData: {
              user_id: userData?.user_id,
              table_name: `${exportData.franchising_forms.table_name}_${country}`,
              table_columns: latestRefValue.current ? latestRefValue.current : checkedColumns, // Changes in checkedColumns
              table_sort: sortBy && sortBy?.length ? sortBy : [],
              table_filter: filterData && filterData?.length ? filterData : [],
              default_export_data: defaultExport,
            },
          };
          if (isManual) {
            dispatch(saveSelectedAllData(sentReq));
          }

          setPageNo(pageIndex);
          setSearch(search);
          setSortBy(sort);
          setPageSize(pageSize);
          // setSelectedItems([]);
          setIsCheckAll(false);
          dispatch(
            getFranchiseeInquiries({
              pageSize,
              pageIndex,
              search,
              sort,
              country: additionalField,
              filterData,
            })
          );
        }
      }, 1000);
    },
    []
  );

  useLayoutEffect(() => {
    dispatch(setDefaultsColumns([]));
    // Set data in variable and Call defaultColums Thunk
    const sentReq = {
      user_id: userData?.user_id,
      table_name: `${exportData.franchising_forms.table_name}_${country}`,
    };
    dispatch(getDefaultCoulmns(sentReq)).then((resp: any) => {
      // SD-3199
      if (resp?.payload) {
        const respFilter = resp?.payload?.table_filter || [];
        const respSorting = resp?.payload?.table_sort || [];
        const defaultDBexportData = resp?.payload?.default_export_data || [];
        const defaultDBColumn = resp?.payload?.table_columns || [];
        const tempdata = setDefaultSelectedColumns(defaultDBColumn, getColumns());
        setCheckedColumns(tempdata);
        setDefaultExport(defaultDBexportData);
        setDefaultSorting(respSorting);
        setSortBy(respSorting);
        setDefaultFilteing(respFilter);
        setCallFetch(true);
        fetchData({
          perPage,
          pageNo,
          search,
          sortBy: respSorting && respSorting?.length ? respSorting : [],
          additionalField: null,
          filterData: respFilter && respFilter?.length ? respFilter : [],
        });
      }
    });
  }, []);

  useEffect(() => {
    if (allInquiries && allInquiries?.franchising_inquiries) {
      setAllInquiry(allInquiries?.franchising_inquiries);
      setPageCount(allInquiries?.meta?.total_pages);
      setTotalCount(allInquiries?.meta?.total_count);
    }
  }, [allInquiries]);

  useEffect(() => {
    if (checkedColumns?.length) {
      setDisplayColumns(checkedColumns.filter((col: any) => col.checked));
    }
  }, [checkedColumns]);

  useEffect(() => {
    if (defaultSelectedColumns && defaultSelectedColumns?.length) {
      const newColumns = setDefaultSelectedColumns(defaultSelectedColumns, getColumns());
      setCheckedColumns(newColumns);
    } else {
      setCheckedColumns(getColumns());
    }
  }, [defaultSelectedColumns]);

  const onChangeCheckBox = (e: any) => {
    let items = JSON.parse(JSON.stringify(selectedItems));
    if (e.target.checked && !items.includes(e.target.value)) {
      items.push(e.target.value);
      if (items.length === allInquiry.length) {
        setIsCheckAll(true);
      }
    } else {
      items = items.filter((id: any) => id !== e.target.value);
      setIsCheckAll(false);
    }
    setSelectedItems(items);
  };

  const checkUncheckAll = (e: any) => {
    let allIds: any = [];
    allIds = allInquiry.map((val: any) => String(val.id));
    if (e.target.checked) {
      const selectedIds = [...new Set([...selectedItems, ...allIds])];
      setSelectedItems(selectedIds);
      setIsCheckAll(true);
    } else {
      allIds = allInquiry.map((val: any) => String(val.id));
      allIds = selectedItems.filter((el: any) => !allIds.includes(el));
      setSelectedItems([...allIds]);
      setIsCheckAll(false);
    }
    // setSelectedItems(allIds);
  };

  const actionButtons = (obj: any) => (
    <>
      <MDIconButton
        tooltipName="View"
        aria-label="Open"
        color="info"
        onClick={() => {
          setViewData(obj);
          setViewPopup(true);
        }}
      >
        <Icon fontSize="small">visibility</Icon>
      </MDIconButton>
      <MDIconButton
        tooltipName="Edit"
        aria-label="Edit"
        color="info"
        onClick={() => {
          setAddUpdatePopup(true);
          setEditInquiries(obj);
        }}
      >
        <Icon fontSize="small">edit</Icon>
      </MDIconButton>
      <MDIconButton
        tooltipName="Delete"
        aria-label="Delete"
        color="error"
        onClick={() => setDeleteInquiryId(obj.id)}
      >
        <Icon fontSize="small">delete</Icon>
      </MDIconButton>
    </>
  );

  const getRows = () => {
    const data: any = allInquiry.map((obj: any) => ({
      checkbox: (
        <Checkbox
          checked={selectedItems.includes(obj.id) || selectedItems.includes(obj.id.toString())}
          onChange={(e: any) => onChangeCheckBox(e)}
          value={obj.id}
        />
      ),
      id: obj.id,
      first_name: obj.first_name || "-",
      last_name: obj.last_name || "-",
      email_address: obj.email_address || "-",
      phone_number: obj.phone_number || "-",
      multi_unit_operator: <Checkbox checked={obj.multi_unit_operator || false} />,
      liquid_capital: obj.liquid_capital || "-",
      city: obj.city || "-",
      state: obj.state || "-",
      agree_to_receive_email: <Checkbox checked={obj.agree_to_receive_email || false} />,
      created_at: obj.created_at ? getLocalDateTime(obj.created_at) : "-",
      updated_at: obj.updated_at ? getLocalDateTime(obj.updated_at) : "-",
      utm_source: obj.utm_source || "-",
      utm_campaign: obj.utm_campaign || "-",
      utm_medium: obj.utm_medium || "-",
      utm_content: obj.utm_content || "-",
      utm_term: obj.utm_term || "-",
      country: obj.country || "-",
      action: actionButtons(obj),
    }));
    return data;
  };

  const tableData = {
    columns: getColumns(),
    rows: getRows(),
  };

  // Start Remove selcted items
  const removeSelectedItem = (deletedId: number) => {
    setSelectedItems(selectedItems.filter((item: any) => Number(item) !== deletedId));
  };
  // End Remove selcted items

  const onDelete = () => {
    if (deleteInquiryId) {
      const delReq = {
        id: deleteInquiryId,
        setDeleteInquiryId,
        pageNo,
        perPage,
        search,
        sortBy,
        country,
        removeSelectedItem,
      };
      dispatch(deleteFranchiseeInquiries(delReq));
    }
  };

  const deleteDialogProps = {
    open: deleteInquiryId,
    onClose: () => setDeleteInquiryId(false),
    onSave: () => onDelete(),
    title: "Delete Franchisee Inquiries",
    size: "sm",
    saveBtn: false,
    deleteBtn: true,
    saveTbtText: "Yes",
    closeBtnText: "No",
  };

  // Remove uncheck boxes
  const clearCheckItem = () => {
    setSelectedItems([]);
  };

  return (
    <MDBox pt={4}>
      <Card>
        <MDBox
          bgColor="dark"
          color="white"
          coloredShadow="dark"
          borderRadius="xl"
          alignItems="center"
          justifyContent="space-between"
          pt={1}
          pb={1}
          pl={2}
          pr={2}
          ml={2}
          mr={2}
          display="flex"
          mt={-3}
          className="page-header"
        >
          <p className="page-header-label">Franchising Inquiries</p>
          <MDButton
            variant="gradient"
            color="light"
            size="small"
            className="xs-small"
            onClick={() => setAddUpdatePopup(true)}
          >
            <Icon sx={{ fontWeight: "bold" }}>add</Icon> Add new
          </MDButton>
        </MDBox>
        {(defaultSorting || defaultSorting?.length) && callFetch && (
          <DataTable
            defaultSortingColumns={defaultSorting} // SD-3199
            defaultFilteringColums={defaultFilteing} // SD-3199
            defaultDisplayColumns={displayColumns}
            setCheckedColumns={setCheckedColumns}
            table={tableData}
            canSearch
            showExportSelectedBtn
            showExportAllBtn
            selectedItems={selectedItems}
            pageCount={pageCount}
            pageNo={pageNo}
            setPageNo={setPageNo}
            totalCount={totalCount}
            fetchData={fetchData}
            exportFields={exportData.franchising_forms.export_fields}
            exportTable={exportData.franchising_forms.table_name}
            exportType={exportData.franchising_forms.export_type}
            advanceFilter
            exportSection={country}
            manualSearch
            manualSort
            additionalField={country}
            isDisplayCheckAllCheckbox
            onChangeCheckAllCheckbox={checkUncheckAll}
            isCheckAll={isCheckAll}
            checkedColumns={checkedColumns}
            clearCheckItem={clearCheckItem}
            getInquriesByCountry={country}
            useRubyApiToExport
          />
        )}
        {addUpdatePopup && (
          <AddUpdate
            open={addUpdatePopup}
            pageNo={pageNo}
            perPage={perPage}
            search={search}
            sortBy={sortBy}
            onClose={() => {
              setAddUpdatePopup(false);
              setEditInquiries(null);
            }}
            editInquiries={editInquiries}
            country={country}
          />
        )}
        {viewPopup && (
          <ViewDetails
            open={viewPopup}
            onClose={() => {
              setViewPopup(false);
              setViewData(null);
            }}
            viewData={viewData}
          />
        )}
        {deleteInquiryId && (
          <MDDialog {...deleteDialogProps}>
            <MDTypography variant="h6" fontWeight="medium">
              {Messages.GENERAL.SURE_TO_DELETE}
            </MDTypography>
          </MDDialog>
        )}
      </Card>
    </MDBox>
  );
}

export default InquiriesList;
