import React, { useEffect, useState } from "react";
import MDDialog from "components/MDDialog";
import { FormControl, Grid } from "@mui/material";
import MDInput from "components/MDInput";
import { useAppDispatch } from "store/store";
import { saveStylistUnits } from "store/thunk/systemAdmin";
import CustomAutoSearch from "components/CustomAutoSearch";
import MDTypography from "components/MDTypography";
import MDDatePicker from "components/MDDatePicker";
import moment from "moment";
import Validations from "helper/validations";
import { getPermission } from "helper/services";

function AddUpdate(props: any): JSX.Element {
  const dispatch = useAppDispatch();
  const { open, onClose, editStylistUnit, pageNo, perPage, search, sortBy } = props;
  const [loading] = useState<boolean>(false);
  const [stylistUnitsData, setStylistUnitsData] = useState<any>({});
  const [errors, setErrors] = useState<any>({});

  useEffect(() => {
    if (editStylistUnit) {
      let rentManagerUnit: any = null;
      if (editStylistUnit.rent_manager_unit_id) {
        rentManagerUnit = {
          id: editStylistUnit.rent_manager_unit_id,
          name: editStylistUnit.rent_manager_unit_name,
        };
      }
      let selectedStylist: any = null;
      if (editStylistUnit.stylist_id) {
        selectedStylist = { id: editStylistUnit.stylist_id, name: editStylistUnit.stylist_name };
      }
      setStylistUnitsData((prev: any) => ({
        ...prev,
        rent_manager_unit: rentManagerUnit,
        stylist: selectedStylist,
        rm_lease: editStylistUnit.rm_lease_id,
        move_in_at: editStylistUnit.move_in_at,
        move_out_at: editStylistUnit.move_out_at,
      }));
    }
  }, [editStylistUnit]);

  const onSave = () => {
    const error = Validations.validateStylistUnits(stylistUnitsData);
    setErrors(error);
    if (!Object.keys(error).length) {
      const req = {
        stylistUnitsData,
        onClose,
        pageNo,
        perPage,
        search,
        sortBy,
      };
      if (editStylistUnit) {
        Object.assign(req, { id: editStylistUnit.id });
      }
      dispatch(saveStylistUnits(req));
    }
  };

  const onChangeField = (e: any, field: string) => {
    setStylistUnitsData((prev: any) => ({
      ...prev,
      [field]: e.target.value,
    }));
  };

  const onChangeAutoComplete = (name: string, value: any) => {
    setStylistUnitsData({ ...stylistUnitsData, [name]: value });
  };

  const handleDateChange = (type: string, date: any) => {
    const utcDate = moment(new Date(date)).toISOString();
    setStylistUnitsData({ ...stylistUnitsData, [type]: utcDate });
  };

  const dialogProps = {
    open,
    onClose,
    onSave,
    saveTbtText: editStylistUnit ? "Update" : "Save",
    title: editStylistUnit ? "Edit Stylist Units" : "Add Stylist Units",
    size: "sm",
    loading,
  };

  return (
    <MDDialog {...dialogProps}>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <CustomAutoSearch
            idKey="id"
            required
            nameKey="name"
            fieldLabel="Stylist"
            apiName="stylists"
            responseKey="stylists"
            name="stylist"
            value={stylistUnitsData.stylist}
            onChange={onChangeAutoComplete}
            error={errors.stylist}
            createNewLink={getPermission("sola_pros", "sola_pros_sola_pros") ? "/sola-pros" : ""}
            additionaFields={{ status: "active" }}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <CustomAutoSearch
            idKey="id"
            nameKey="name"
            fieldLabel="Rent Manager Unit"
            apiName="rent_manager_units"
            responseKey="rent_manager_units"
            name="rent_manager_unit"
            value={stylistUnitsData.rent_manager_unit}
            onChange={onChangeAutoComplete}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <MDInput
            type="Number"
            label="Rm Lease"
            variant="standard"
            fullWidth
            sx={{ margin: "5px 0px" }}
            name="rm_lease"
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => onChangeField(e, "rm_lease")}
            value={stylistUnitsData?.rm_lease || ""}
          />
        </Grid>
        <Grid container item xs={12} sm={12} md={12} lg={12} spacing={2} mt={1}>
          <Grid item xs={12} sm={6} md={6} lg={6}>
            <FormControl fullWidth>
              <MDTypography variant="button">Move In At</MDTypography>
              <MDDatePicker
                value={stylistUnitsData?.move_in_at || null}
                options={{
                  enableTime: true,
                  dateFormat: "M d, Y H:i",
                  time_24hr: true,
                }}
                onChange={([date]: any) => handleDateChange("move_in_at", date)}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6} md={6} lg={6}>
            <FormControl fullWidth>
              <MDTypography variant="button">Move Out At</MDTypography>
              <MDDatePicker
                value={stylistUnitsData?.move_out_at || null}
                options={{
                  enableTime: true,
                  dateFormat: "M d, Y H:i",
                  time_24hr: true,
                }}
                onChange={([date]: any) => handleDateChange("move_out_at", date)}
              />
            </FormControl>
          </Grid>
        </Grid>
      </Grid>
    </MDDialog>
  );
}

export default AddUpdate;
