import { Typography, Card, Icon, IconButton } from "@mui/material";
import MDBox from "components/MDBox";
import { useState, useEffect, useCallback } from "react";
import MDButton from "components/MDButton";
import axios from "axios";
import MDTypography from "components/MDTypography";
import config from "config/config";
import { getAuthUser, getAssociationLocations } from "helper/services";
import { notificationFail, notificationSuccess } from "store/slices/notificationSlice";
import { useAppDispatch, useAppSelector } from "store/store";
import DataTable from "examples/Tables/DataTable";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import { getCmsLocations } from "store/thunk/locationThunk";
import { filterData, SearchType } from "filter-data";
import Messages from "helper/messages";
import {
  scoercardDateRange,
  getDateRange,
  getUniqueValuesRoyalty,
  convertToFirstDayOfMonth,
  convertToLastDayOfMonth,
  fetchCurrentDate,
  getFirstDateOfCurrentMonth,
  columnsToCastRoyalty,
  sortingValuesRoyalty,
} from "helper/reports";
import CustomSelect from "components/CustomSelect";
import arraySort from "array-sort";
import CustomLoader from "components/CustomLoader";
import { FilterProps, SortProps } from "../scorecard-dashboard/scorecard-table";
import { SelectedDateRange, SelectedDate } from "../scorecard-dashboard";
import ScorecardDateInput from "../scorecard-dashboard/components/scorecardDateInput";

function formatCurrency(amount: number, currencySymbol: string = "$"): string {
  let formattedAmount = amount?.toString();
  const parts = formattedAmount?.split(".");
  let wholePart = parts ? parts[0] : null;
  const decimalPart = parts ? parts[1] : null;
  wholePart = wholePart?.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  formattedAmount = wholePart + (decimalPart?.length ? `.${decimalPart}` : "");
  formattedAmount = currencySymbol + formattedAmount;
  return formattedAmount;
}

function formatRate(rate: number) {
  const formattedRate = Math.round(rate * 1000) / 1000;
  return formattedRate;
}

function RoyaltyDashboard(): JSX.Element {
  const { cmsLocations } = useAppSelector((state) => state.locationSlice);
  const [solaIds, setSolaIds] = useState<String[]>([]);
  const [tableRows, setTableRows] = useState([]);
  const [selectedDate, setSelectedDate] = useState<SelectedDate>();
  const [isLoading, setIsLoading] = useState<Boolean>(true);
  const [showFilter, setShowFilter] = useState<boolean>(false);
  const [currentDateCheckBox, setCurrentDateCheckBox] = useState<boolean>(false);
  const [applySearch, setApplySearch] = useState<boolean>(false);
  const [filterMetaData, setFilterMetaData] = useState<{ [key: string]: string[] }>();
  const [search, setSearch] = useState("");
  const [sort, setSort] = useState([]);
  const [loader, setLoader] = useState<boolean>(true);
  const [filterByLocation, setFilterByLocation] = useState<FilterProps>({});
  const [filterByProperty, setFilterByProperty] = useState<FilterProps>({});
  const [filterByLocationId, setFilterByLocationId] = useState<FilterProps>({});
  const [selectedDateRange, setSelectedDateRange] = useState<SelectedDateRange>();
  const [filterByIncome, setFilterByIncome] = useState<FilterProps>({});
  const [filterByRoyalty, setFilterByRoyalty] = useState<FilterProps>({});
  const [filterByMarketing, setFilterByMarketing] = useState<FilterProps>({});
  const [filterByMarketingRate, setFilterByMarketingRate] = useState<FilterProps>({});
  const [filterByRoyaltyRate, setFilterByRoyaltyRate] = useState<FilterProps>({});
  const [filterBySolaId, setFilterBySolaId] = useState<FilterProps>({});
  const userDetails = getAuthUser();
  const dispatch: any = useAppDispatch();

  const onChangeDateField = (event: any) => {
    const date = scoercardDateRange.find((date) => date.value === event.target.value);
    const [startDate, endDate] = getDateRange(date.value);
    setSelectedDate(date);
    setSelectedDateRange({
      startMonth: startDate,
      endMonth: endDate,
    });
  };

  const tableColumns: any =
    userDetails.role === "Admin" || userDetails.role === "System Admin"
      ? [
          {
            Header: "Sola ID",
            accessor: "sola_id",
            width: "200px",
            export: true,
            disableCheck: true,
            checked: true,
          },
          {
            Header: "Location",
            accessor: "location",
            width: "85px",
            export: true,
            disableCheck: false,
            checked: true,
          },
          {
            Header: "Property",
            accessor: "property",
            width: "200px",
            export: true,
            disableCheck: false,
            checked: true,
          },
          {
            Header: "Income",
            accessor: (d: any) => formatCurrency(d.total_gross),
            export: true,
            disableCheck: false,
            checked: true,
          },
          {
            Header: "Location ID",
            accessor: "locationid",
            export: true,
            disableCheck: false,
            checked: false,
          },
          {
            Header: "Royalty",
            accessor: (d: any) => formatCurrency(d.royalty),
            export: true,
            disableCheck: false,
            checked: false,
          },
          {
            Header: "Marketing",
            accessor: (d: any) => formatCurrency(d.marketing),
            export: true,
            disableCheck: false,
            checked: false,
          },
          {
            Header: "Royalty Rate",
            accessor: (d: any) => formatRate(d.royalty_rate),
            export: true,
            disableCheck: false,
            checked: false,
          },
          {
            Header: "Marketing Rate",
            accessor: (d: any) => formatRate(d.marketing_rate),
            export: true,
            disableCheck: false,
            checked: false,
          },
        ]
      : [
          {
            Header: "Sola ID",
            accessor: "sola_id",
            width: "200px",
            export: true,
            disableCheck: true,
            checked: true,
          },
          {
            Header: "Property",
            accessor: "property",
            width: "200px",
            export: true,
            disableCheck: false,
            checked: true,
          },
          {
            Header: "Income",
            accessor: (d: any) => formatCurrency(d.total_gross),
            export: true,
            disableCheck: false,
            checked: true,
          },
          {
            Header: "Royalty",
            accessor: (d: any) => formatCurrency(d.royalty),
            export: true,
            disableCheck: false,
            checked: false,
          },
          {
            Header: "Marketing",
            accessor: (d: any) => formatCurrency(d.marketing),
            export: true,
            disableCheck: false,
            checked: false,
          },
          {
            Header: "Royalty Rate",
            accessor: (d: any) => formatRate(d.royalty_rate),
            export: true,
            disableCheck: false,
            checked: false,
          },
          {
            Header: "Marketing Rate",
            accessor: (d: any) => formatRate(d.marketing_rate),
            export: true,
            disableCheck: false,
            checked: false,
          },
        ];

  const fetchReport = async () => {
    if (selectedDateRange) {
      if (selectedDateRange.startMonth > selectedDateRange.endMonth) {
        throw new Error("Start month should be lower than end month");
      } else {
        setLoader(true);
        let url = "";
        const startDate = currentDateCheckBox
          ? getFirstDateOfCurrentMonth()
          : convertToFirstDayOfMonth(selectedDateRange.startMonth);
        const endDate = currentDateCheckBox
          ? fetchCurrentDate()
          : convertToLastDayOfMonth(selectedDateRange.endMonth);
        if (userDetails.role === "Admin" || userDetails.role === "System Admin") {
          url = `${config.REPORT_URL}/report/selectedRoyalty?startDate=${startDate}&endDate=${endDate}`;
        } else {
          url = `${
            config.REPORT_URL
          }/report/selectedRoyalty?startDate=${startDate}&endDate=${endDate}&locations='${solaIds.toString()}'`;
        }

        if (userDetails.role === "Admin" || userDetails.role === "System Admin" || solaIds.length) {
          axios
            .get(url, {
              responseType: "json",
            })
            .then((res) => {
              setTableRows(res.data.result);
              dispatch(
                notificationSuccess(res.data.message || Messages.SUCCESS.MASTER_DATA_CREATED)
              );
            })
            .catch((err) => {
              dispatch(
                notificationFail(
                  err?.response?.data?.message || Messages.ERROR.SOMETHING_WENT_WRONG
                )
              );
            })
            .finally(() => {
              setIsLoading(false);
              setLoader(false);
            });
        }
      }
    }
  };

  const downloadCSV = () => {
    const csvString = [
      [
        "Sola ID",
        "Property",
        "Location",
        "Location ID",
        "Income",
        "Royalty",
        "Marketing",
        "Royalty Rate",
        "Marketing Rate",
        "End Date",
      ],
      ...tableRows.map((item) => [
        item.sola_id,
        item.property,
        item.location,
        item.locationid,
        item.total_gross,
        item.royalty,
        item.marketing,
        item.royalty_rate,
        item.marketing_rate,
        item.enddate,
      ]),
    ]
      .map((row) => row.map((value) => `"${value}"`).join(","))
      .join("\n");

    const blob = new Blob([csvString], { type: "text/csv;charset=utf-8;" });
    const url = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute(
      "download",
      `Royalty-${selectedDateRange.startMonth}-${selectedDateRange.endMonth}.csv`
    );

    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  useEffect(() => {
    fetchReport();
  }, [selectedDateRange, currentDateCheckBox]);

  useEffect(() => {
    const pageSize = 50;
    const pageIndex = 0;
    const search: any = undefined;
    const sort: any[] = [];
    const filterData: any[] = [];
    dispatch(getCmsLocations({ pageSize, pageIndex, search, sort, filterData }));
    setSelectedDate(scoercardDateRange[0]);
    const [startDate, endDate] = getDateRange(scoercardDateRange[0].value);
    setSelectedDateRange({
      startMonth: startDate,
      endMonth: endDate,
    });
  }, []);

  useEffect(() => {
    const locationData = getAssociationLocations();
    let permittedLocations: String[] = [];
    cmsLocations?.locations?.map((locationDetail: any) => {
      if (
        locationData.get_location_by_ids?.includes(locationDetail.id) &&
        locationDetail.store_id
      ) {
        permittedLocations.push(locationDetail.store_id);
      }
      return 0;
    });
    permittedLocations = permittedLocations.filter(
      (value, index) => permittedLocations.indexOf(value) === index
    );
    setSolaIds(permittedLocations);
  }, [cmsLocations]);

  const fetchData = useCallback(() => {
    const processData = [...tableRows];
    let result: {
      [key: string]: number | string;
    }[] = [];
    if (sort && sort[0]?.desc !== undefined) {
      const desc: boolean = sort[0].desc ? true : false;
      const columnName: any = sortingValuesRoyalty[sort[0].id];
      if (columnsToCastRoyalty.includes(columnName)) {
        result = processData.map((obj) => {
          if (typeof obj[columnName] === "string") {
            const newValue = parseFloat(obj[columnName] as string);
            return { ...obj, [columnName]: newValue };
          }
          return obj;
        });
      }
      result = arraySort(result.length ? result : processData, sortingValuesRoyalty[sort[0].id], {
        reverse: desc,
      });
    }
    let filterRoyaltyValue = 0;
    if (filterByRoyalty.value) {
      const check = parseInt(filterByRoyalty.value?.split(" ").pop(), 10) as number;
      filterRoyaltyValue = check;
      if (result.length) {
        result = result.filter((item) => parseInt(String(item.royalty), 10) > check);
      } else {
        result = processData.filter((item) => parseInt(String(item.royalty), 10) > check);
      }
    }
    let filterMarketingValue = 0;
    if (filterByMarketing.value) {
      const check = parseInt(filterByMarketing.value?.split(" ").pop(), 10) as number;
      filterMarketingValue = check;
      if (result.length) {
        result = result.filter((item) => parseInt(String(item.marketing), 10) > check);
      } else {
        result = processData.filter((item) => parseInt(String(item.marketing), 10) > check);
      }
    }
    const searchConditions = [
      {
        key: "sola_id",
        value: filterBySolaId.value,
        type: SearchType.EQ,
      },
      {
        key: "location",
        value: filterByLocation.value,
        type: SearchType.EQ,
      },
      {
        key: "property",
        value: filterByProperty.value,
        type: SearchType.EQ,
      },
      {
        key: "locationid",
        value: filterByLocationId.value,
        type: SearchType.EQ,
      },
      {
        key: "marketing_rate",
        value: filterByMarketingRate.value,
        type: SearchType.EQ,
      },
      {
        key: "royalty_rate",
        value: filterByRoyaltyRate.value,
        type: SearchType.EQ,
      },
      {
        key: "total_gross",
        value:
          filterByIncome.value && (parseInt(filterByIncome.value.split(" ").pop(), 10) as number),
        type: SearchType.GT,
      },
    ];
    const maxRoyalty = processData.reduce((max, item) => {
      const currentRoyalty = parseFloat(item.royalty);
      const currentMax = parseFloat(max);
      return currentRoyalty > currentMax ? item.royalty : max;
    }, "0");
    const maxMarketing = processData.reduce((max, item) => {
      const currentMarketing = parseFloat(item.marketing);
      const currentMax = parseFloat(max);
      return currentMarketing > currentMax ? item.marketing : max;
    }, "0");
    let searchResult = [];
    if (maxRoyalty > filterRoyaltyValue && maxMarketing > filterMarketingValue) {
      searchResult = filterData(result.length ? result : processData, searchConditions);
    }
    const searchedData = searchResult;

    return searchedData;
  }, [search, sort, applySearch, tableRows]);

  const renderTable = () => (
    <Card>
      <MDBox
        bgColor="dark"
        color="white"
        coloredShadow="dark"
        borderRadius="xl"
        alignItems="center"
        justifyContent="space-between"
        pt={1}
        pb={1}
        pl={2}
        pr={2}
        ml={2}
        mr={2}
        display="flex"
        mt={-3}
        className="page-header"
      >
        <p className="page-header-label">Royalty</p>
      </MDBox>
      <DataTable
        table={{
          columns: tableColumns,
          rows: fetchData(),
        }}
        fetchData={({
          sortBy,
          search,
        }: {
          sortBy: Array<SortProps>;
          search: string;
          filterData: any;
        }) => {
          setSearch(search);
          setSort(sortBy);
        }}
      />
    </Card>
  );

  useEffect(() => {
    setFilterMetaData(getUniqueValuesRoyalty(tableRows));
  }, [tableRows]);

  const clearFilters = () => {
    setFilterByLocation({});
    setFilterByProperty({});
    setFilterByIncome({});
    setFilterByLocationId({});
    setFilterByRoyalty({});
    setFilterByMarketing({});
    setFilterByMarketingRate({});
    setFilterByRoyaltyRate({});
    setFilterBySolaId({});
    setApplySearch(!applySearch);
  };

  return (
    <>
      {loader && (
        <MDBox
          style={{
            background: "rgba(0,0,0,0.3)",
            zIndex: 99999,
            display: "flex",
            position: "fixed",
            alignItems: "center",
            justifyContent: "center",
            width: "100%",
            height: "100vh",
          }}
        >
          <CustomLoader />
        </MDBox>
      )}
      <DashboardLayout>
        <DashboardNavbar />
        <MDBox py={3}>
          <Card sx={{ padding: "20px" }}>
            <MDBox display="flex" flexDirection="row" justifyContent="space-between">
              <MDBox display="flex" justifyContent="start" sx={{ marginTop: { xs: 2, md: 0 } }}>
                <MDBox mt={10}>
                  {showFilter && (
                    <>
                      <MDBox
                        className="crm-location-header-wrapper"
                        onClick={() => setShowFilter(false)}
                      />
                      <MDBox
                        className="crm-location-header"
                        display={showFilter ? "inline-flex" : "none"}
                      >
                        <MDBox sx={{ display: "inline" }}>
                          <IconButton
                            className="close-filter-button"
                            onClick={() => setShowFilter(false)}
                          >
                            <Icon>close</Icon>
                          </IconButton>
                        </MDBox>
                        <MDBox sx={{ display: "inline", mt: 4 }}>
                          <CustomSelect
                            onChange={(filterValue: any) => setFilterBySolaId(filterValue)}
                            name="solaid"
                            placeholder="Select Sola Id"
                            value={Object.keys(filterBySolaId).length > 0 ? filterBySolaId : null}
                            id="solaid"
                            options={filterMetaData?.sola_id.map((value: any) => {
                              const tempObj = { label: value, value };
                              return tempObj;
                            })}
                          />
                          <CustomSelect
                            onChange={(filterValue: any) => setFilterByLocation(filterValue)}
                            name="location"
                            placeholder="Select Location"
                            value={
                              Object.keys(filterByLocation).length > 0 ? filterByLocation : null
                            }
                            id="location"
                            options={filterMetaData?.location.map((value: any) => {
                              const tempObj = { label: value, value };
                              return tempObj;
                            })}
                          />
                          <CustomSelect
                            onChange={(filterValue: any) => setFilterByProperty(filterValue)}
                            name="property"
                            placeholder="Select Property"
                            value={
                              Object.keys(filterByProperty).length > 0 ? filterByProperty : null
                            }
                            id="property"
                            options={filterMetaData?.property.map((value: any) => {
                              const tempObj = { label: value, value };
                              return tempObj;
                            })}
                          />
                          <CustomSelect
                            onChange={(filterValue: any) => setFilterByIncome(filterValue)}
                            name="income"
                            placeholder="Select Income"
                            value={Object.keys(filterByIncome).length > 0 ? filterByIncome : null}
                            id="income"
                            options={filterMetaData?.total_gross.map((value: any) => {
                              const tempObj = { label: value, value };
                              return tempObj;
                            })}
                          />
                          <CustomSelect
                            onChange={(filterValue: any) => setFilterByLocationId(filterValue)}
                            name="locationid"
                            placeholder="Select Location ID"
                            value={
                              Object.keys(filterByLocationId).length > 0 ? filterByLocationId : null
                            }
                            id="locationid"
                            options={filterMetaData?.locationid.map((value: any) => {
                              const tempObj = { label: value, value };
                              return tempObj;
                            })}
                          />
                          <CustomSelect
                            onChange={(filterValue: any) => setFilterByRoyalty(filterValue)}
                            name="royalty"
                            placeholder="Select Royalty"
                            value={Object.keys(filterByRoyalty).length > 0 ? filterByRoyalty : null}
                            id="royalty"
                            options={filterMetaData?.royalty.map((value: any) => {
                              const tempObj = { label: value, value };
                              return tempObj;
                            })}
                          />
                          <CustomSelect
                            onChange={(filterValue: any) => setFilterByMarketing(filterValue)}
                            name="marketing"
                            placeholder="Select Marketing"
                            value={
                              Object.keys(filterByMarketing).length > 0 ? filterByMarketing : null
                            }
                            id="marketing"
                            options={filterMetaData?.marketing.map((value: any) => {
                              const tempObj = { label: value, value };
                              return tempObj;
                            })}
                          />
                          <CustomSelect
                            onChange={(filterValue: any) => setFilterByRoyaltyRate(filterValue)}
                            name="marketing_rate"
                            placeholder="Select Royalty Rate"
                            value={
                              Object.keys(filterByRoyaltyRate).length > 0 ? filterByRoyaltyRate : 0
                            }
                            id="marketing_rate"
                            options={filterMetaData?.royalty_rate.map((value: any) => {
                              const tempObj = { label: value, value };
                              return tempObj;
                            })}
                          />
                          <CustomSelect
                            onChange={(filterValue: any) => setFilterByMarketingRate(filterValue)}
                            name="marketing_rate"
                            placeholder="Select Marketing Rate"
                            value={
                              Object.keys(filterByMarketingRate).length > 0
                                ? filterByMarketingRate
                                : 0
                            }
                            id="marketing_rate"
                            options={filterMetaData?.marketing_rate.map((value: any) => {
                              const tempObj = { label: value, value };
                              return tempObj;
                            })}
                          />
                        </MDBox>
                        <MDBox sx={{ display: "flex", justifyContent: "flex-end", width: "100%" }}>
                          <MDButton
                            variant="gradient"
                            color="info"
                            size="small"
                            className="xs-small"
                            sx={{ marginTop: "5px" }}
                            onClick={() => setApplySearch(!applySearch)}
                          >
                            Apply
                          </MDButton>
                          <MDButton
                            variant="gradient"
                            sx={{ marginLeft: "10px" }}
                            color="light"
                            size="small"
                            className="xs-small"
                            onClick={() => clearFilters()}
                          >
                            Clear
                          </MDButton>
                        </MDBox>
                      </MDBox>
                    </>
                  )}
                </MDBox>
                {selectedDateRange && selectedDateRange.startMonth && (
                  <ScorecardDateInput
                    selectedDate={selectedDate}
                    onChangeDateField={onChangeDateField}
                    dateArr={scoercardDateRange}
                    selectedDateRange={selectedDateRange}
                    setSelectedDateRange={setSelectedDateRange}
                    setSelectedDate={setSelectedDate}
                    currentDateCheckBox={currentDateCheckBox}
                    setCurrentDateCheckBox={setCurrentDateCheckBox}
                  />
                )}
              </MDBox>
              {tableRows ? (
                <MDBox>
                  <MDButton variant="gradient" color="info">
                    <MDTypography variant="subtitle2" color="white" onClick={downloadCSV}>
                      Export
                    </MDTypography>
                  </MDButton>
                  <IconButton
                    onClick={() => {
                      setShowFilter(!showFilter);
                    }}
                  >
                    <Icon>filter_list</Icon>
                  </IconButton>
                </MDBox>
              ) : null}
            </MDBox>
            <MDBox mt={4}>
              {!isLoading ? (
                solaIds.length ||
                userDetails.role === "Admin" ||
                (userDetails.role === "System Admin" && fetchData().length) ? (
                  renderTable()
                ) : (
                  <MDBox sx={{ minHeight: "200" }}>
                    <Card
                      variant="outlined"
                      sx={{
                        height: "200px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <Typography variant="h5">No Data Found</Typography>
                    </Card>
                  </MDBox>
                )
              ) : null}
            </MDBox>
          </Card>
        </MDBox>
      </DashboardLayout>
    </>
  );
}

export default RoyaltyDashboard;
