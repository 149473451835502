import MDDialog from "components/MDDialog";
// import MDInput from "components/MDInput";
import MDBox from "components/MDBox";
import { getLocalDateTime, formattedPhone } from "helper/services";
import { Accordion, AccordionDetails, AccordionSummary, Grid } from "@mui/material";
import MDTypography from "components/MDTypography";

function ViewDetails(props: any): JSX.Element {
  const { open, onClose, updateData } = props;

  const dialogProps = {
    open,
    onClose,
    title: "View Contact Inquiries",
    size: "md",
    saveBtn: false,
  };

  const getAccordianSummary = (name: any) => {
    const labelName = name;
    return (
      <AccordionSummary className="location-accordion">
        <MDTypography>{labelName}</MDTypography>
      </AccordionSummary>
    );
  };

  return (
    <MDDialog {...dialogProps}>
      <Accordion expanded>
        {getAccordianSummary("General")}
        <AccordionDetails>
          <MDBox>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6} md={6} mt={2}>
                <MDTypography fontWeight="bold" variant="button">
                  Name
                </MDTypography>
                <MDBox>
                  <MDTypography variant="button" sx={{ wordBreak: "break-word" }}>
                    {updateData?.name || "-"}
                  </MDTypography>
                </MDBox>
              </Grid>
              <Grid item xs={12} sm={6} md={6} mt={2}>
                <MDTypography fontWeight="bold" variant="button">
                  Email
                </MDTypography>
                <MDBox>
                  <MDTypography variant="button" sx={{ wordBreak: "break-word" }}>
                    {updateData?.email || "-"}
                  </MDTypography>
                </MDBox>
              </Grid>
            </Grid>
          </MDBox>
          <MDBox>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6} md={6} mt={2}>
                <MDTypography fontWeight="bold" variant="button">
                  Phone
                </MDTypography>
                <MDBox>
                  <MDTypography variant="button" sx={{ wordBreak: "break-word" }}>
                    {formattedPhone(updateData?.phone) || "-"}
                  </MDTypography>
                </MDBox>
              </Grid>
              <Grid item xs={12} sm={6} md={6} mt={2}>
                <MDTypography fontWeight="bold" variant="button">
                  Location
                </MDTypography>
                <MDBox>
                  <MDTypography variant="button" sx={{ wordBreak: "break-word" }}>
                    {updateData?.location?.name || "-"}
                  </MDTypography>
                </MDBox>
              </Grid>
            </Grid>
          </MDBox>
          <MDBox>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6} md={6} mt={2}>
                <MDTypography fontWeight="bold" variant="button">
                  Message
                </MDTypography>
                <MDBox>
                  <MDTypography variant="button" sx={{ wordBreak: "break-word" }}>
                    {updateData?.message || "-"}
                  </MDTypography>
                </MDBox>
              </Grid>
              <Grid item xs={12} sm={6} md={6} mt={2}>
                <MDTypography fontWeight="bold" variant="button">
                  Contact Preference
                </MDTypography>
                <MDBox>
                  <MDTypography variant="button" sx={{ wordBreak: "break-word" }}>
                    {updateData?.contact_preference || "-"}
                  </MDTypography>
                </MDBox>
              </Grid>
            </Grid>
          </MDBox>
          <MDBox>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6} md={6} mt={2}>
                <MDTypography fontWeight="bold" variant="button">
                  How Can we Help You
                </MDTypography>
                <MDBox>
                  <MDTypography variant="button" sx={{ wordBreak: "break-word" }}>
                    {updateData?.how_can_we_help_you || "-"}
                  </MDTypography>
                </MDBox>
              </Grid>
              <Grid item xs={12} sm={6} md={6} mt={2}>
                <MDTypography fontWeight="bold" variant="button">
                  Services
                </MDTypography>
                <MDBox>
                  <MDTypography sx={{ wordBreak: "break-word" }} variant="button">
                    {updateData?.services || "-"}
                  </MDTypography>
                </MDBox>
              </Grid>
            </Grid>
          </MDBox>
          <MDBox>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6} md={6} mt={2}>
                <MDTypography fontWeight="bold" variant="button">
                  Request URL
                </MDTypography>
                <MDBox>
                  <MDTypography variant="button" sx={{ wordBreak: "break-all" }}>
                    {updateData?.request_url || "-"}
                  </MDTypography>
                </MDBox>
              </Grid>
              <Grid item xs={12} sm={6} md={6} mt={2}>
                <MDTypography fontWeight="bold" variant="button">
                  State
                </MDTypography>
                <MDBox>
                  <MDTypography variant="button" sx={{ wordBreak: "break-word" }}>
                    {updateData?.state || "-"}
                  </MDTypography>
                </MDBox>
              </Grid>
              <Grid item xs={12} sm={6} md={6} mt={2}>
                <MDTypography fontWeight="bold" variant="button">
                  Zip Code
                </MDTypography>
                <MDBox>
                  <MDTypography variant="button" sx={{ wordBreak: "break-word" }}>
                    {updateData?.zip_code || "-"}
                  </MDTypography>
                </MDBox>
              </Grid>
              <Grid item xs={12} sm={6} md={6} mt={2}>
                <MDTypography fontWeight="bold" variant="button">
                  Prospect Origin
                </MDTypography>
                <MDBox>
                  <MDTypography variant="button" sx={{ wordBreak: "break-word" }}>
                    {updateData?.send_email_to_prospect || "-"}
                  </MDTypography>
                </MDBox>
              </Grid>
              <Grid item xs={12} sm={6} md={6} mt={2}>
                <MDTypography fontWeight="bold" variant="button">
                  I Would Like To Be Contacted
                </MDTypography>
                <MDBox>
                  <MDTypography variant="button">
                    {updateData?.i_would_like_to_be_contacted ? "Yes" : "No"}
                  </MDTypography>
                </MDBox>
              </Grid>
            </Grid>
          </MDBox>
          <MDBox>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6} md={6} mt={2}>
                <MDTypography fontWeight="bold" variant="button">
                  UTM Source
                </MDTypography>
                <MDBox>
                  <MDTypography variant="button" sx={{ wordBreak: "break-word" }}>
                    {updateData?.utm_source || "-"}
                  </MDTypography>
                </MDBox>
              </Grid>
              <Grid item xs={12} sm={6} md={6} mt={2}>
                <MDTypography fontWeight="bold" variant="button">
                  UTM Medium
                </MDTypography>
                <MDBox>
                  <MDTypography variant="button" sx={{ wordBreak: "break-word" }}>
                    {updateData?.utm_medium || "-"}
                  </MDTypography>
                </MDBox>
              </Grid>
            </Grid>
          </MDBox>
          <MDBox>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6} md={6} mt={2}>
                <MDTypography fontWeight="bold" variant="button">
                  UTM Campaign
                </MDTypography>
                <MDBox>
                  <MDTypography variant="button" sx={{ wordBreak: "break-word" }}>
                    {updateData?.utm_campaign || "-"}
                  </MDTypography>
                </MDBox>
              </Grid>
              <Grid item xs={12} sm={6} md={6} mt={2}>
                <MDTypography fontWeight="bold" variant="button">
                  UTM Content
                </MDTypography>
                <MDBox>
                  <MDTypography variant="button" sx={{ wordBreak: "break-word" }}>
                    {updateData?.utm_content || "-"}
                  </MDTypography>
                </MDBox>
              </Grid>
              <Grid item xs={12} sm={6} md={6} mt={2}>
                <MDTypography fontWeight="bold" variant="button">
                  Newsletter Subscription
                </MDTypography>
                <MDBox>
                  <MDTypography variant="button">
                    {updateData?.newsletter ? "Yes" : "No"}
                  </MDTypography>
                </MDBox>
              </Grid>
              <Grid item xs={12} sm={6} md={6} mt={2}>
                <MDTypography fontWeight="bold" variant="button">
                  Don&apos;t See Your Location
                </MDTypography>
                <MDBox>
                  <MDTypography variant="button">
                    {updateData?.dont_see_your_location?.toString() ? "Yes" : "No"}
                  </MDTypography>
                </MDBox>
              </Grid>
            </Grid>
          </MDBox>
          <MDBox>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6} md={6} mt={2}>
                <MDTypography fontWeight="bold" variant="button">
                  Created At
                </MDTypography>
                <MDBox>
                  <MDTypography variant="button">
                    {getLocalDateTime(updateData?.created_at)}
                  </MDTypography>
                </MDBox>
              </Grid>
              <Grid item xs={12} sm={6} md={6} mt={2}>
                <MDTypography fontWeight="bold" variant="button">
                  Updated At
                </MDTypography>
                <MDBox>
                  <MDTypography variant="button">
                    {getLocalDateTime(updateData?.updated_at)}
                  </MDTypography>
                </MDBox>
              </Grid>
              <Grid item xs={12} sm={6} md={6} mt={2}>
                <MDTypography fontWeight="bold" variant="button">
                  When are you looking to move?
                </MDTypography>
                <MDBox>
                  <MDTypography variant="button">{updateData?.ideal_timing || "-"}</MDTypography>
                </MDBox>
              </Grid>
              <Grid item xs={12} sm={6} md={6} mt={2}>
                <MDTypography fontWeight="bold" variant="button">
                  Landing Page URL
                </MDTypography>
                <MDBox>
                  <MDTypography variant="button">
                    {updateData?.requesturl_landing ?? "-"}
                  </MDTypography>
                </MDBox>
              </Grid>
              <Grid item xs={12} sm={6} md={6} mt={2}>
                <MDTypography fontWeight="bold" variant="button">
                  Referring URL
                </MDTypography>
                <MDBox>
                  <MDTypography variant="button">
                    {updateData?.requesturl_referring ?? "-"}
                  </MDTypography>
                </MDBox>
              </Grid>
              <Grid item xs={12} sm={6} md={6} mt={2}>
                <MDTypography fontWeight="bold" variant="button">
                  IP Address
                </MDTypography>
                <MDBox>
                  <MDTypography variant="button">
                    {updateData?.request_ipaddress ?? "-"}
                  </MDTypography>
                </MDBox>
              </Grid>
            </Grid>
          </MDBox>
        </AccordionDetails>
      </Accordion>
    </MDDialog>
  );
}

export default ViewDetails;
