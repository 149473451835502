import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "store/store";
import Grid from "@mui/material/Grid";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import Validation from "helper/validations";
import Settings from "layouts/pages/sola-web/addUpdate/settings";
import Website from "layouts/pages/sola-web/addUpdate/website";
import Social from "layouts/pages/sola-web/addUpdate/social";
import Images from "layouts/pages/sola-web/addUpdate/images";
import Users from "layouts/pages/sola-web/addUpdate/users";
import Testimonials from "layouts/pages/sola-web/addUpdate/testimonials";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Sidenav from "layouts/pages/sola-web/sidenav";
import CustomBackButton from "components/CustomBackButton";
import {
  getAllConnectServices,
  saveWebPages,
  getWebPageDetails,
  updateWebpageImageOrder,
} from "store/thunk/solaProsThunk";
import { scrollToErrorByClass, removeSpecialCharacters } from "helper/services";
import {
  setActiveServices,
  setAllConnectServices,
  setSolaProDetails,
} from "store/slices/solaProSlice";
import WorkHours from "layouts/pages/sola-web/addUpdate/work-hours";
import Integrations from "layouts/pages/sola-web/addUpdate/integrations";
import UsersNew from "layouts/pages/sola-web/addUpdate/users-new";
import { arrayMoveImmutable } from "array-move";
import { notificationFail } from "store/slices/notificationSlice";
import Banner from "./addUpdate/banner";
// import Locations from "layouts/pages/sola-web/addUpdate/locations";

function AddUpdate(props: any): JSX.Element {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const locationState: any = useLocation();
  const { webPageDetails } = useAppSelector((state: any) => state.solaProSlice);
  const { onClose, pageNo, perPage, search, sortBy, locationId, noDashboardLayout, webpageId } =
    props;
  const [errors, setErrors] = useState<any>({});
  const [webPageDetail, setWebPageDetails] = useState<any>({});
  const [isUpdate, setIsUpdate] = useState<any>(false);
  const [imagePreview, setImagePreview] = useState<any>([]);
  const [expanded, setExpanded] = useState<string | false>("panel1");
  const [method] = useState<string>("new");
  const [imagesArray, setImagesArray] = useState([]);
  const [bannerImagesArray, setBannerImagesArray] = useState([]);
  const [updateImageOrder, setUpdateImageOrder] = useState(false);
  const [userActiveService, setActiveUserService] = useState([]);
  const [bannerError, setBannerError] = useState("");

  useEffect(() => {
    if (locationState?.state?.id || webpageId) {
      if (!webpageId) {
        dispatch(getWebPageDetails({ id: locationState?.state?.id || webpageId, platform: "web" }));
      }
      setIsUpdate(true);
    } else if (!locationState?.state?.id && window?.location?.pathname?.includes("edit-webpage")) {
      navigate("/sola-pros/web-pages");
    } else {
      setWebPageDetails({});
      setImagePreview([]);
      dispatch(getAllConnectServices({}));
      dispatch(setSolaProDetails({}));
      setActiveUserService([]);
    }
  }, [locationState]);

  useEffect(() => {
    if (webPageDetails) {
      const permissionData: any = [];
      webPageDetails?.connect_user_webpages?.map((item: any) => {
        item?.user_detail?.locations.map((xItem: any) => {
          permissionData.push({
            connect_user_id: item?.connect_user_id,
            location_id: xItem?.id,
            is_show: xItem?.is_show || false,
          });
          return true;
        });
        return true;
      });

      const locationData: any = webPageDetails?.connect_webpage_locations?.map((obj: any) => ({
        id: obj?.location?.id,
        name: obj?.location?.name,
      }));

      const tempImagesArray: any = [];
      for (let i = 0; i < 10; i++) {
        if (webPageDetails?.[`image_${i + 1}_url`]) {
          tempImagesArray.push({
            index: i + 1,
            id: i + 1,
            url: webPageDetails?.[`image_${i + 1}_url`],
            name: `image_${i + 1}`,
          });
        }
      }

      setImagesArray(tempImagesArray);

      setWebPageDetails({
        ...webPageDetails,
        work_hours_data: webPageDetails?.connect_webpage_work_hours,
        connect_users: webPageDetails?.connect_user_webpages?.map((obj: any) => ({
          user_id: String(obj?.connect_user_id),
          login_email: obj?.user_detail?.login_email,
          locations: obj?.user_detail?.locations,
        })),
        location: [...new Map(locationData?.map((obj: any) => [obj.id, obj])).values()],
        location_permission_data: permissionData,
      });
      dispatch(setAllConnectServices(webPageDetails?.connect_services));
      dispatch(
        setActiveServices(
          webPageDetails?.active_services?.map((label: any) => ({
            label,
          }))
        )
      );
    }
  }, [webPageDetails]);

  const onCallBack = () => {
    dispatch(setSolaProDetails({}));
    navigate(`/sola-pros/web-pages`);
  };

  const onSortEnd = ({ oldIndex, newIndex }: any) => {
    setImagesArray((prevItem: any) => arrayMoveImmutable(prevItem, oldIndex, newIndex));
    setUpdateImageOrder(true);
  };

  const onUpdateImageOrder = () => {
    let allowUpdateOrder = true;
    if (allowUpdateOrder) {
      for (let i = 0; i < 10; i++) {
        if (webPageDetail?.[`delete_image_${i + 1}`]) {
          allowUpdateOrder = false;
        }
        if (typeof webPageDetail?.[`image_${i + 1}`] === "object") {
          allowUpdateOrder = false;
        }
      }
    }

    if (!allowUpdateOrder) {
      dispatch(notificationFail("Please click save button first as you delete the image"));
      return;
    }
    if (allowUpdateOrder && updateImageOrder && imagesArray.length) {
      const orderUpdateReq: any = {
        id: webPageDetail?.id,
        setUpdateImageOrder,
      };

      imagesArray.map((obj: any, inx: any) => {
        orderUpdateReq[`image_${inx + 1}_url`] = obj?.url;
        return true;
      });

      dispatch(updateWebpageImageOrder(orderUpdateReq));
    }
  };

  const onSave = () => {
    if (!bannerError) {
      setBannerError("");
    }
    const errors: any = Validation.validateWebPageForm(webPageDetail);
    setErrors(errors);
    if (!Object.keys(errors).length) {
      const req = {
        webPageDetail,
        onClose,
        isUpdate,
        pageNo,
        perPage,
        search,
        sortBy,
        locationId,
        method,
        callback: onCallBack,
      };
      dispatch(saveWebPages(req));
    } else {
      scrollToErrorByClass(errors);
    }
  };

  const onChangeImage = async (files: any, key: any) => {
    if (files[0]) {
      const file = files[0];
      const reader = new FileReader();
      reader.onload = function (e) {
        const images: any = imagePreview;
        images[`image_${key}_url`] = e.target.result;
        setWebPageDetails({
          ...webPageDetail,
          [`image_${key}_url`]: e.target.result,
          [`image_${key}`]: file,
        });
        setImagePreview(images);
      };
      reader.readAsDataURL(file);
    }
  };

  const onImageRemove = async (e: any, key: any) => {
    e.stopPropagation();
    const deleteflag = `delete_image_${key}`;
    setWebPageDetails({
      ...webPageDetail,
      [`image_${key}_url`]: null,
      [`image_${key}`]: null,
      [deleteflag]: 1,
    });
    const images: any = imagePreview;

    images[`image_${key}_url`] = null;
    setImagePreview(images);
  };

  const onChangeAutoComplete = (name: string, value: any) => {
    setWebPageDetails({ ...webPageDetail, [name]: value });
  };

  const onChangeDescription = (value: any, delta: any, source: any) => {
    if (source === "user") {
      setWebPageDetails({ ...webPageDetail, description: value });
    }
  };

  const onChangeField = (event: any) => {
    if (event.target.name === "url_name") {
      setWebPageDetails({
        ...webPageDetail,
        [event.target.name]: removeSpecialCharacters(event.target.value)
          .replace(/  +/g, " ")
          .replace(/\s+$/, "-")
          .replace("--", "-"),
      });
    } else {
      setWebPageDetails({ ...webPageDetail, [event.target.name]: event.target.value });
    }
  };

  const onCheckboxChange = (event: any) => {
    setWebPageDetails({ ...webPageDetail, [event.target.name]: event.target.checked });
  };

  const onChangeMultipleField = (event: any, key: any) => {
    const data = webPageDetail[key] ? JSON.parse(JSON.stringify(webPageDetail[key])) : {};
    data[event.target.name] = event.target.value;
    setWebPageDetails({ ...webPageDetail, [key]: { ...data } });
  };

  const onChangeWorkHours = (value: any, delta: any, source: any) => {
    if (source === "user") {
      setWebPageDetails({ ...webPageDetail, norm_work_hours: value });
    }
  };

  const attr = {
    webPageDetail,
    imagePreview,
    isUpdate,
    errors,
    onChangeField,
    onChangeAutoComplete,
    onChangeMultipleField,
    onChangeDescription,
    onCheckboxChange,
    onChangeImage,
    setWebPageDetails,
    setImagePreview,
    onImageRemove,
    expanded,
    setExpanded,
    imagesArray,
    setImagesArray,
    updateImageOrder,
    setUpdateImageOrder,
    onSortEnd,
    onUpdateImageOrder,
    userActiveService,
    setActiveUserService,
    onChangeWorkHours,
    bannerImagesArray,
    setBannerImagesArray,
    bannerError,
    setBannerError,
  };

  // console.log("webPageDetail ", webPageDetail);
  const getComponent = () => (
    <Grid container spacing={3} mt={1}>
      <Grid item xs={12} lg={3}>
        <Sidenav />
      </Grid>
      <Grid item xs={12} lg={9}>
        <MDBox mb={3}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Website {...attr} />
            </Grid>
            {method === "old" ? (
              <Grid item xs={12}>
                <Users {...attr} />
              </Grid>
            ) : (
              <Grid item xs={12}>
                <UsersNew {...attr} />
              </Grid>
            )}
            <Grid item xs={12}>
              <Integrations {...attr} />
            </Grid>
            <Grid item xs={12}>
              <Settings {...attr} />
            </Grid>
            {/* <Grid item xs={12}>
            <Locations {...attr} />
          </Grid> */}
            <Grid item xs={12}>
              <WorkHours {...attr} />
            </Grid>
            <Grid item xs={12}>
              <Social {...attr} />
            </Grid>
            <Grid item xs={12}>
              <Banner {...attr} />
            </Grid>
            <Grid item xs={12}>
              <Images {...attr} />
            </Grid>
            <Grid item xs={12}>
              <Testimonials {...attr} />
            </Grid>
            <Grid item xs={12} textAlign="right">
              <MDButton
                variant="gradient"
                color="info"
                size="small"
                onClick={onSave}
                className="fixed-button"
              >
                {isUpdate ? "Update" : "Save"}
              </MDButton>
            </Grid>
          </Grid>
        </MDBox>
      </Grid>
    </Grid>
  );

  return noDashboardLayout ? (
    getComponent()
  ) : (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox mx={2} onClick={onCallBack}>
        <CustomBackButton
          label="Back To Web Pages"
          historyState={{
            prevPath: "/sola-pros/web-pages",
          }}
        />
      </MDBox>
      {getComponent()}
    </DashboardLayout>
  );
}

export default AddUpdate;
