import { useState } from "react";
import TimePicker from "rc-time-picker";
import { v4 as uuidv4 } from "uuid";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import Icon from "@mui/material/Icon";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import CustomDropZone from "components/CustomDropZone";
import MDIconButton from "components/MDIconButton";
import ImagePreview from "layouts/pages/ImagePreview";
import { getMUISelectValue } from "helper/services";
import contants from "helper/constants";
import MDInput from "components/MDInput";

function WalkIn(props: any): JSX.Element {
  const {
    locationDetail,
    onChangeField,
    onChangeTimeField,
    onChangeFloorPlanImage,
    onImageFloorPlanRemove,
    floorPlanImage,
    getAccessTypePermission,
  } = props;

  const [isPreview, setImagePreviewPopup] = useState(false);
  const [imageToPreview, setImageToPreview] = useState("");

  const previewImage = (image: any) => {
    setImagePreviewPopup(true);
    setImageToPreview(image);
  };

  const closeImagePreview = () => {
    setImagePreviewPopup(false);
    setImageToPreview("");
  };

  return (
    <Card id="walk-in" sx={{ overflow: "visible" }}>
      <MDBox p={3}>
        <MDTypography variant="h5">Walk In</MDTypography>
      </MDBox>
      <MDBox component="form" pb={3} px={3}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6} md={6} lg={6}>
            <FormControl fullWidth>
              <InputLabel>Walk-Ins Enabled</InputLabel>
              <Select
                className="height45"
                label="Walk-Ins Enabled"
                value={getMUISelectValue(locationDetail?.walkins_enabled)}
                name="walkins_enabled"
                onChange={onChangeField}
                disabled={getAccessTypePermission("location_general", "edit_access")}
              >
                <MenuItem value="true">Yes</MenuItem>
                <MenuItem value="false">No</MenuItem>
              </Select>
              <MDTypography variant="caption" mt={1}>
                If set to yes, there will be a toggle switch inside Sola Pro to turn on/off taking
                walk-ins for each salon professional at this location.
              </MDTypography>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6} md={6} lg={6}>
            <FormControl fullWidth>
              <InputLabel>Max Walk-Ins Time</InputLabel>
              <Select
                className="height45"
                label="Max Walk-Ins Time"
                value={locationDetail?.max_walkins_time || ""}
                name="max_walkins_time"
                onChange={onChangeField}
                disabled={getAccessTypePermission("location_general", "edit_access")}
              >
                {contants.maxWalkInsTimes.map((obj) => (
                  <MenuItem key={uuidv4()} value={obj.time}>
                    {obj.label}
                  </MenuItem>
                ))}
              </Select>
              <MDTypography variant="caption" mt={1}>
                This field will set the maximum duration a salon professional at this location may
                set their taking walk-ins setting inside Sola Pro.
              </MDTypography>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6} md={6} lg={6} className="timPickerClass">
            <InputLabel>Walk-Ins End of Day</InputLabel>
            <TimePicker
              placeholder="Walk-Ins End of Day"
              className="height45"
              showSecond={false}
              format="HH:mm"
              inputReadOnly
              name="walkins_end_of_day"
              value={locationDetail?.walkins_end_of_day || null}
              onChange={onChangeTimeField}
              disabled={getAccessTypePermission("location_general", "edit_access")}
            />
            <FormControl>
              <MDTypography variant="caption">
                If set, this is the time at the end of the day where all salon professionals talking
                walk-ins at this location should be automatically turned off.
              </MDTypography>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6} md={6} lg={6}>
            <FormControl fullWidth>
              <InputLabel>Walk-Ins Time Zone</InputLabel>
              <Select
                className="height45"
                label="Walk-Ins Time Zone"
                value={locationDetail?.walkins_timezone || ""}
                name="walkins_timezone"
                onChange={onChangeField}
                disabled={getAccessTypePermission("location_general", "edit_access")}
              >
                <MenuItem value="Pacific Time">Pacific Time</MenuItem>
                <MenuItem value="Mountain Time">Mountain Time</MenuItem>
                <MenuItem value="Central Time">Central Time</MenuItem>
                <MenuItem value="Eastern Time">Eastern Time</MenuItem>
              </Select>
              <MDTypography variant="caption" mt={1}>
                In order to turn off walk-ins automatically at the correct time, please select the
                time zone of this location.
              </MDTypography>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <MDTypography component="label" variant="button" fontWeight="regular" color="text">
              Floorplan Image
            </MDTypography>
            <Grid container>
              {!floorPlanImage && (
                <Grid item xs={12} sm={8} md={8} lg={8}>
                  <CustomDropZone
                    multipleUpload={false}
                    acceptedFiles={{
                      "image/*": [".gif", ".png", ".jpg", ".jpeg"],
                    }}
                    onDrop={(e: any) => onChangeFloorPlanImage(e, "floorplan_image_url")}
                    disabled={getAccessTypePermission("location_general", "edit_access")}
                  >
                    {getAccessTypePermission("location_general", "edit_access")
                      ? "No Access"
                      : "Drop your image here, or Browse"}
                  </CustomDropZone>
                </Grid>
              )}
              <Grid item xs={12} sm={4} md={4} lg={4}>
                {floorPlanImage && (
                  <Grid
                    container
                    onClick={() => previewImage(floorPlanImage)}
                    justifyContent="center"
                    alignItems="center"
                    textAlign="center"
                  >
                    <Grid item className="imageWithDeleteIcon">
                      <img
                        width="90%"
                        style={{ maxHeight: "100px" }}
                        src={floorPlanImage}
                        alt="original"
                      />
                      {!getAccessTypePermission("location_general", "edit_access") && (
                        <MDIconButton
                          tooltipName="Delete"
                          aria-label="Delete"
                          className="removeBtnClass"
                          onClick={(e: any) => onImageFloorPlanRemove(e, `floorplan_image_url`)}
                        >
                          <Icon fontSize="small">close</Icon>
                        </MDIconButton>
                      )}
                    </Grid>
                  </Grid>
                )}
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={6} md={6} lg={6}>
            <MDInput
              label="Rockbot Manager Email"
              variant="standard"
              fullWidth
              name="rockbot_manager_email"
              placeholder="Rockbot Manager Email"
              onChange={onChangeField}
              value={locationDetail?.rockbot_manager_email || ""}
              disabled={getAccessTypePermission("location_general", "edit_access")}
            />
          </Grid>
          {isPreview && (
            <ImagePreview
              imageData={imageToPreview}
              open={isPreview}
              onCloseImage={() => {
                closeImagePreview();
              }}
            />
          )}
        </Grid>
      </MDBox>
    </Card>
  );
}

export default WalkIn;
