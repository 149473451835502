import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import SolaProsActive from "layouts/pages/sola-pros/sola-pros-list";
import SolaProsInActive from "layouts/pages/sola-pros/sola-pros-inactive";

import { useState } from "react";
import { Card, Tabs, Tab } from "@mui/material";

import MDBox from "components/MDBox";
import MDTabPanel from "components/MDTabPanel";

function Index(): JSX.Element {
  const [tabValue, setTabValue] = useState<number>(0);
  const changeTab = (event: any, newValue: any) => {
    setTabValue(newValue);
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pb={3}>
        <Card>
          <MDBox minWidth={{ xs: "100%", md: "25rem" }} mx="auto" mt={1} mb={-3}>
            <Tabs className="location-tabs" value={tabValue} onChange={changeTab}>
              <Tab
                id="Active"
                label={
                  <MDBox py={0.5} px={2} color="inherit">
                    Active
                  </MDBox>
                }
              />
              <Tab
                id="Inactive"
                label={
                  <MDBox py={0.5} px={2} color="inherit">
                    Inactive
                  </MDBox>
                }
              />
            </Tabs>
          </MDBox>
          <MDTabPanel value={tabValue} index={0}>
            <SolaProsActive />
          </MDTabPanel>
          <MDTabPanel value={tabValue} index={1}>
            <SolaProsInActive />
          </MDTabPanel>
        </Card>
      </MDBox>
    </DashboardLayout>
  );
}

export default Index;
