import { createSlice, Draft, PayloadAction } from "@reduxjs/toolkit";

interface InitialState {
  brands: any;
  brandDetails: object;
  homeButtonsList: any;
  productInformationList: any;
  classImagesList: any;
  homeHeroImages: any;
  stateRegions: any;
  sideMenuList: any;
  dealsList: any;
  solaClassRegions: any;
  states: any;
  educationHeroImages: any;
  regionsList: any;
  videosList: any;
  video: any;
  toolList: any;
  notificationList: any;
  eventsAndClassesList: any;
}

const initialState: InitialState = {
  brands: [],
  brandDetails: {},
  homeButtonsList: [],
  productInformationList: [],
  classImagesList: [],
  homeHeroImages: [],
  stateRegions: [],
  sideMenuList: [],
  dealsList: [],
  solaClassRegions: [],
  states: [],
  educationHeroImages: [],
  regionsList: [],
  videosList: [],
  video: null,
  toolList: [],
  notificationList: [],
  eventsAndClassesList: [],
};

const mobileAppSlice = createSlice({
  name: "mobileAppSlice",
  initialState,
  reducers: {
    allBrandsList: (state: Draft<InitialState>, action: PayloadAction<any>) => ({
      ...state,
      brands: action.payload,
    }),
    getBrandsDetails: (state: Draft<InitialState>, action: PayloadAction<any>) => ({
      ...state,
      brandDetails: action.payload,
    }),
    homeButtonsList: (state: Draft<InitialState>, action: PayloadAction<any>) => ({
      ...state,
      homeButtonsList: action.payload,
    }),
    productInformationList: (state: Draft<InitialState>, action: PayloadAction<any>) => ({
      ...state,
      productInformationList: action.payload,
    }),
    setClassImagesList: (state: Draft<InitialState>, action: PayloadAction<any>) => ({
      ...state,
      classImagesList: action.payload,
    }),
    setHomeHeroImages: (state: Draft<InitialState>, action: PayloadAction<any>) => ({
      ...state,
      homeHeroImages: action.payload,
    }),
    setStateRegions: (state: Draft<InitialState>, action: PayloadAction<any>) => ({
      ...state,
      stateRegions: action.payload,
    }),
    setSideMenuList: (state: Draft<InitialState>, action: PayloadAction<any>) => ({
      ...state,
      sideMenuList: action.payload,
    }),
    setDealsList: (state: Draft<InitialState>, action: PayloadAction<any>) => ({
      ...state,
      dealsList: action.payload,
    }),
    setSolaClassRegion: (state: Draft<InitialState>, action: PayloadAction<any>) => ({
      ...state,
      solaClassRegions: action.payload,
    }),
    setStates: (state: Draft<InitialState>, action: PayloadAction<any>) => ({
      ...state,
      states: action.payload,
    }),
    educationHeroImagesList: (state: Draft<InitialState>, action: PayloadAction<any>) => ({
      ...state,
      educationHeroImages: action.payload,
    }),
    setRegionsList: (state: Draft<InitialState>, action: PayloadAction<any>) => ({
      ...state,
      regionsList: action.payload,
    }),
    setVideos: (state: Draft<InitialState>, action: PayloadAction<any>) => ({
      ...state,
      videosList: action.payload,
    }),
    setVideo: (state: Draft<InitialState>, action: PayloadAction<any>) => ({
      ...state,
      video: action.payload,
    }),
    setNotificationList: (state: Draft<InitialState>, action: PayloadAction<any>) => ({
      ...state,
      notificationList: action.payload,
    }),
    setToolList: (state: Draft<InitialState>, action: PayloadAction<any>) => ({
      ...state,
      toolList: action.payload,
    }),
    setEventsAndClassesList: (state: Draft<InitialState>, action: PayloadAction<any>) => ({
      ...state,
      eventsAndClassesList: action.payload,
    }),
  },
});

export const {
  allBrandsList,
  getBrandsDetails,
  homeButtonsList,
  productInformationList,
  setClassImagesList,
  setHomeHeroImages,
  setStateRegions,
  setSideMenuList,
  setDealsList,
  setSolaClassRegion,
  setStates,
  setRegionsList,
  setNotificationList,
  educationHeroImagesList,
  setVideos,
  setVideo,
  setToolList,
  setEventsAndClassesList,
} = mobileAppSlice.actions;

export default mobileAppSlice.reducer;
