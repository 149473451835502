import { Grid } from "@mui/material";

import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";

function Index(props: any): JSX.Element {
  const { leaseDetails, onChangeField, errors } = props;

  return (
    <MDBox>
      <MDBox textAlign="center" mx="auto" my={4}>
        <MDBox mb={1}>
          <MDTypography variant="h5" fontWeight="regular">
            Mailing
          </MDTypography>
        </MDBox>
      </MDBox>
      <MDBox mt={2}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <MDInput
              label="Address 1"
              variant="standard"
              fullWidth
              name="mailing_address_1"
              placeholder="Address 1"
              onChange={onChangeField}
              value={leaseDetails?.mailing_address_1 || ""}
              error={errors && errors.mailing_address_1 ? true : false}
              helperText={errors && errors.mailing_address_1}
              required
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <MDInput
              label="Address 2"
              variant="standard"
              fullWidth
              name="mailing_address_2"
              placeholder="Address 2"
              onChange={onChangeField}
              value={leaseDetails?.mailing_address_2 || ""}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <MDInput
              label="City"
              variant="standard"
              fullWidth
              name="mailing_city"
              placeholder="City"
              onChange={onChangeField}
              value={leaseDetails?.mailing_city || ""}
              error={errors && errors.mailing_city ? true : false}
              helperText={errors && errors.mailing_city}
              required
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <MDInput
              label="State"
              variant="standard"
              fullWidth
              name="mailing_state"
              placeholder="State"
              onChange={onChangeField}
              value={leaseDetails?.mailing_state || ""}
              error={errors && errors.mailing_state ? true : false}
              helperText={errors && errors.mailing_state}
              required
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <MDInput
              label="Zip"
              variant="standard"
              fullWidth
              name="mailing_zip"
              placeholder="Zip"
              onChange={onChangeField}
              value={leaseDetails?.mailing_zip || ""}
              error={errors && errors.mailing_zip ? true : false}
              helperText={errors && errors.mailing_zip}
              required
            />
          </Grid>
        </Grid>
      </MDBox>
    </MDBox>
  );
}

export default Index;
