import Paper from "@mui/material/Paper";
import Checkbox from "@mui/material/Checkbox";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormGroup from "@mui/material/FormGroup";
import Switch from "@mui/material/Switch";
import Grid from "@mui/material/Grid";
import MDBox from "components/MDBox";
import MDLabelValue from "components/MDLabelValue";
import { formatDate } from "helper/services";
import MDTypography from "components/MDTypography";

function Review(props: any): JSX.Element {
  const { leaseData } = props;
  console.log("leaseData ", leaseData);

  const renderAddress = (leaseData: any) => {
    let address = "";
    if (leaseData.address_1) {
      address += leaseData.address_1;
    }
    if (leaseData.location && leaseData.location.address_2) {
      address += `${address !== "" ? ", " : ""}`;
      address += leaseData.location.address_2;
    }
    if (leaseData.address) {
      address += `${address !== "" ? ", " : ""}`;
      address += leaseData.address;
    }
    if (leaseData.city) {
      address += `${address !== "" ? ", " : ""}`;
      address += leaseData.city;
    }
    if (leaseData.state) {
      address += `${address !== "" ? ", " : ""}`;
      address += leaseData.state;
    }
    if (leaseData.country) {
      address += `${address !== "" ? ", " : ""}`;
      address += leaseData.country;
    }
    if (leaseData.postal_code) {
      address += `${address !== "" ? ", " : ""}`;
      address += leaseData.postal_code;
    }
    return address;
  };
  return (
    <MDBox>
      <Paper>
        <MDBox p={2}>
          <Grid container>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <MDTypography variant="h5">Applicant Information</MDTypography>
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
              <MDLabelValue
                label="Send to"
                value={`${leaseData?.first_name || leaseData?.title || "-"} ${
                  leaseData?.last_name || ""
                }`}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
              <MDLabelValue label="Email" value={leaseData?.email || "-"} />
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
              <MDLabelValue label="Phone number" value={leaseData?.phone || "-"} />
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
              <MDLabelValue
                label="Move In Date"
                value={
                  leaseData?.move_in_date ? formatDate(leaseData?.move_in_date, "MMM DD, YYYY") : ""
                }
              />
            </Grid>
          </Grid>
        </MDBox>
      </Paper>
      <Paper>
        <MDBox mt={2} p={2}>
          <Grid container>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <MDTypography variant="h5" mt={4} mb={2}>
                Lease Information
              </MDTypography>
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
              <MDLabelValue
                label="Location"
                value={leaseData?.locationName || leaseData?.location_name || "-"}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
              <MDLabelValue label="Sola ID" value={leaseData?.store_id || "-"} />
            </Grid>
            <Grid item xs={12} sm={12} md={12}>
              <MDLabelValue label="Address" value={renderAddress(leaseData)} />
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
              <MDLabelValue
                label="State for lease agreement"
                value={leaseData?.incorporation_state || "-"}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
              <MDLabelValue
                label="Unit"
                value={leaseData?.unit?.unit || leaseData?.lease_units?.unit || "-"}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
              <MDLabelValue label="Rent Period" value={leaseData?.rent_period || "-"} />
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
              <MDLabelValue label="Rent Due Date" value={leaseData?.rent_due_date || "-"} />
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
              <MDLabelValue label="Lease Term" value={leaseData?.lease_term || "-"} />
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
              <MDLabelValue
                label="Licence Fee, Year 1"
                value={`$${leaseData?.licence_fee_1 || leaseData?.license_fee_year1 || 0}`}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
              <MDLabelValue
                label="Licence Fee, Year 3"
                value={`$${leaseData?.licence_fee_2 || leaseData?.license_fee_year2 || 0}`}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
              <MDLabelValue
                label="Security Deposit Amount"
                value={`$${leaseData?.security_deposit_amount || 0}`}
              />
            </Grid>
          </Grid>
        </MDBox>
      </Paper>
      {/* <Paper>
        <MDBox p={2} mt={2}>
          <Grid container></Grid>
        </MDBox>
      </Paper> */}
      <Paper>
        <MDBox p={2} mt={2}>
          <Grid container>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <MDTypography variant="h5" mt={4} mb={2}>
                Add Move-In Incentives
              </MDTypography>
            </Grid>
            <Grid item xs={12} sm={12} md={12}>
              <FormControlLabel
                control={
                  <Switch
                    checked={leaseData?.charge_insurance || false}
                    inputProps={{ "aria-label": "controlled" }}
                  />
                }
                name="charge_insurance"
                label="Charge Insurance (after promos)"
                labelPlacement="end"
                disabled
              />
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
              <MDLabelValue
                label="Insurance Charge Frequency"
                value={leaseData?.insurance_change_frequency || "-"}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
              <MDLabelValue label="Insurance Fee" value={leaseData?.insurance_fee || "-"} />
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
              <MDLabelValue label="Weeks Free Rent" value={leaseData?.weeks_free_rent || "-"} />
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
              <MDLabelValue
                label="Months Free Insurance"
                value={leaseData?.months_free_insurance || "-"}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={12}>
              <FormControl component="fieldset">
                <FormGroup aria-label="position" row>
                  <FormControlLabel
                    value="end"
                    control={
                      <Checkbox
                        checked={leaseData?.free_paint || false}
                        name="free_paint"
                        disabled
                        inputProps={{ "aria-label": "controlled" }}
                      />
                    }
                    label="Free Paint"
                    labelPlacement="end"
                  />
                </FormGroup>
              </FormControl>
            </Grid>
          </Grid>
        </MDBox>
      </Paper>
      <Paper>
        <MDBox p={2} mt={2}>
          <Grid container>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <MDTypography variant="h5" mt={4} mb={2}>
                Lease Dates
              </MDTypography>
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
              <MDLabelValue
                label="Lease Sign"
                value={formatDate(leaseData?.lease_sign, "MMM DD, YYYY") || "-"}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
              <MDLabelValue
                label="Lease Start"
                value={formatDate(leaseData?.lease_start, "MMM DD, YYYY") || "-"}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
              <MDLabelValue
                label="Lease End"
                value={formatDate(leaseData?.lease_end, "MMM DD, YYYY") || "-"}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
              <MDLabelValue
                label="First Payment Date"
                value={formatDate(leaseData?.first_payment_date, "MMM DD, YYYY") || "-"}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
              <MDLabelValue
                label="Insurance Start"
                value={formatDate(leaseData?.insurance_start, "MMM DD, YYYY") || "-"}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
              <MDLabelValue
                label="Insurance Anniversary"
                value={formatDate(leaseData?.insurance_anniversary, "MMM DD, YYYY") || "-"}
              />
            </Grid>
          </Grid>
        </MDBox>
      </Paper>
    </MDBox>
  );
}

export default Review;
