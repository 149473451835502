import { Step, Stepper, StepLabel, StepContent, Box } from "@mui/material";
import { useAppDispatch, useAppSelector } from "store/store";
import { useState, useEffect } from "react";
import MDButton from "components/MDButton";
import MDBox from "components/MDBox";
import { setApplicantDetails, setleaseApplicationSteps } from "store/slices/leaseSlice";
import MDTypography from "components/MDTypography";
import { leaseApplicantValue } from "helper/services";
import {
  checkDealsInLease,
  // createContactIquiries,
} from "store/thunk/leaseThunk";
import Validations from "helper/validations";
import LeadOption from "./leadOption";
import AddFromDeal from "./addFromDeal";
import AddAndCheckDeal from "./addAndCheckDeal";
import AddExistingProfessional from "./addExistingProfessional";
import DetailsPopup from "./detailsPopup";
import Sublets from "./sublets";

function PageOneWizard(props: any): JSX.Element {
  const { currentPage, setSubletApplicantError } = props;
  const { leaseApplicationSteps, services, existingDeal } = useAppSelector(
    (state) => state.leaseSlice
  );
  const [leaseApplication, setLeaseApplication] = useState<any>();
  const [notFoundDeal, setNotFoundDeal] = useState<boolean>(false);
  const [detailPopup, setDetailPopup] = useState<boolean>(false);
  const [contactInquiries, setContactInquiries] = useState<any>({});
  const [selectedServices, setSelectedServices] = useState<any>([]);
  const [showDeals, setShowDeals] = useState<boolean>(false);
  const [finalStep, setFinalStep] = useState<boolean>(true);
  const [errors, setErrors] = useState<any>({});
  const [activeStep, setActiveStep] = useState(0);
  const [numFields, setNumFields] = useState<any>(1);

  const stepText: any = {
    add_existing_professional: "Add Existing Professional",
    add_from_deal: "Add From Deal",
  };
  const step2Text = leaseApplicationSteps ? stepText[leaseApplicationSteps?.selectedType] : "";
  const steps = ["Add Applicants", step2Text ? step2Text : "Select Type", "Select Applicants"];
  const dispatch = useAppDispatch();

  useEffect(() => {}, [existingDeal]);
  useEffect(() => {
    if (leaseApplicationSteps) {
      if (leaseApplicationSteps?.step) {
        setActiveStep(leaseApplicationSteps?.step);
      }
    }
  }, [leaseApplicationSteps]);

  // For if any value in existing array or not.
  // const checkExistingArrayValue = () => {
  //   console.log("leaseApplicationSteps ", leaseApplicationSteps);
  //   if (
  //     activeStep === 1 &&
  //     leaseApplicationSteps?.selectedType === leaseApplicantValue.ADDEXISTINGPROFESSIONAL &&
  //     leaseApplicationSteps?.deal?.length > 0
  //   ) {
  //     dispatch(setleaseApplicationSteps({ ...leaseApplicationSteps, deal: [] }));
  //     setContactInquiries("");
  //   }
  //   if (
  //     activeStep === 0 &&
  //     leaseApplicationSteps?.selectedType === leaseApplicantValue.ADDFROMDEAL &&
  //     leaseApplicationSteps?.professional?.length > 0
  //   ) {
  //     dispatch(
  //       setleaseApplicationSteps({
  //         ...leaseApplicationSteps,
  //         professional: [],
  //         contactInquiries: [],
  //       })
  //     );
  //     setContactInquiries("");
  //   }
  //   if (
  //     activeStep === 0 &&
  //     leaseApplicationSteps?.selectedType === leaseApplicantValue.ADDNEW &&
  //     leaseApplicationSteps?.professional?.length > 0 &&
  //     leaseApplicationSteps?.deal?.length > 0
  //   ) {
  //     dispatch(setleaseApplicationSteps({ ...leaseApplicationSteps, professional: [], deals: [] }));
  //   }
  // };

  const checkForNextButton = () => {
    let disabled = true;
    // checkExistingArrayValue();
    // for disable Next button
    if (activeStep === 0 && leaseApplicationSteps && leaseApplicationSteps?.selectedType) {
      disabled = false;
    }
    if (activeStep === 1 || activeStep === 2) {
      disabled = false;
    }
    if (
      finalStep &&
      activeStep === 1 &&
      leaseApplicationSteps?.mainType === leaseApplicantValue.ADDNEW
    ) {
      disabled = true;
    }
    return disabled;
  };

  const handleChangeNext = () => {
    if (activeStep === 1 && leaseApplicationSteps?.deal) {
      setActiveStep(activeStep + 1);
      dispatch(setApplicantDetails([]));
      dispatch(
        setleaseApplicationSteps({ ...leaseApplicationSteps, applicants: [], step: activeStep + 1 })
      );
      const sendData = {
        dealId: leaseApplicationSteps?.deal?.id,
      };
      dispatch(checkDealsInLease(sendData));
    }
    if (activeStep === 1 && leaseApplicationSteps?.professional) {
      setActiveStep(activeStep + 1);
      dispatch(setApplicantDetails([]));
      // Check existing lease from deal
      const sendData = { dealId: leaseApplicationSteps?.deal?.id };
      dispatch(checkDealsInLease(sendData));
      dispatch(
        setleaseApplicationSteps({ ...leaseApplicationSteps, applicants: [], step: activeStep + 1 })
      );
    }
    if (
      leaseApplicationSteps?.page === 1 &&
      leaseApplicationSteps?.step === 1 &&
      leaseApplicationSteps?.mainType === "add_new" &&
      leaseApplicationSteps?.deal
    ) {
      setShowDeals(true);
    }
  };
  const handleNext = (datas?: any) => {
    if (activeStep === 0 && leaseApplicationSteps && leaseApplicationSteps?.selectedType) {
      setActiveStep(activeStep + 1);
      dispatch(setleaseApplicationSteps({ ...leaseApplicationSteps, step: activeStep + 1 }));
    } else if (
      activeStep === 1 &&
      leaseApplicationSteps?.selectedType === leaseApplicantValue.ADDFROMDEAL
    ) {
      // Main Type wise validations
      if (leaseApplicationSteps?.mainType === leaseApplicantValue.ADDNEW) {
        handleChangeNext();
      } else {
        const errors = Validations.validateLeads({ ...leaseApplicationSteps });
        setErrors(errors);
        if (!Object.keys(errors).length) {
          if (leaseApplicationSteps?.deal) {
            handleChangeNext();
          }
        }
      }
    } else if (
      activeStep === 1 &&
      leaseApplicationSteps?.selectedType === leaseApplicantValue.ADDEXISTINGPROFESSIONAL
    ) {
      const errors = Validations.validateStylists({ ...leaseApplicationSteps });
      setErrors(errors);
      if (!Object.keys(errors).length) {
        if (leaseApplicationSteps?.professional) {
          handleChangeNext();
        }
      }
    } else if (activeStep === 2) {
      const error: any = Validations.applicantsData(datas?.applicants);
      setErrors(error);

      if (!Object.keys(error).length) {
        setSubletApplicantError(false);
        dispatch(
          setleaseApplicationSteps({
            ...leaseApplicationSteps,
            ...datas,
            page: currentPage + 1,
            step: activeStep,
          })
        );
      }
    } else {
      handleChangeNext();
    }
  };

  const handleBack = () => {
    setActiveStep(activeStep - 1);
    dispatch(setleaseApplicationSteps({ ...leaseApplicationSteps, step: activeStep - 1 }));
    if (activeStep === 1) {
      if (Object.keys(errors).length) {
        setErrors("");
      }
    }
    // if ((activeStep === 1 || activeStep === 2) && leaseApplicationSteps?.mainType === "add_new") {
    //   setShowDeals(false);
    //   setFinalStep(false);
    // }
    if (leaseApplicationSteps?.mainType === "add_new" && leaseApplicationSteps?.deal) {
      setShowDeals(true);
      setFinalStep(false);
    }
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  // Condition wise load component
  const loadComponent = (attr: any) => {
    let components: any = "";
    if (leaseApplicationSteps?.selectedType === leaseApplicantValue.ADDFROMDEAL) {
      if (leaseApplicationSteps?.mainType === leaseApplicantValue.ADDNEW) {
        components = <AddAndCheckDeal {...attr} />;
      } else {
        components = <AddFromDeal {...attr} />;
      }
    }
    if (leaseApplicationSteps?.selectedType === leaseApplicantValue.ADDEXISTINGPROFESSIONAL) {
      components = <AddExistingProfessional {...attr} />;
    }
    return components;
  };

  // Change selectedType while not Found professional as well as deal
  const addNewDeal = () => {
    dispatch(
      setleaseApplicationSteps({
        ...leaseApplicationSteps,
        selectedType: leaseApplicantValue.ADDNEW,
      })
    );
  };
  const getStepContent = (stepIndex: number) => {
    const attr = {
      activeStep,
      handleNext,
      handleBack,
      setLeaseApplication,
      leaseApplication,
      services,
      errors,
      selectedServices,
      contactInquiries,
      setContactInquiries,
      setSelectedServices,
      checkForNextButton,
      numFields,
      setNumFields,
      setErrors,
      addNewDeal,
      notFoundDeal,
      setNotFoundDeal,
      showDeals,
      setShowDeals,
      setFinalStep,
      finalStep,
      setSubletApplicantError,
    };
    switch (stepIndex) {
      case 0:
        return <LeadOption {...attr} />;
      case 1:
        return loadComponent({ ...attr });
      case 2:
        return <Sublets {...attr} />;
      default:
        return null;
    }
  };

  return (
    <>
      <MDBox sx={{ maxWidth: "auto" }}>
        <Stepper
          activeStep={activeStep}
          orientation="vertical"
          sx={{ background: "#fff !important", p: 0, boxShadow: "none !important" }}
        >
          {steps.map((label) => (
            <Step key={label}>
              <StepLabel
                className="applicant-step-label"
                sx={{
                  padding: " 2px 0px",
                  "& .MuiStepLabel-label": {
                    margin: "0 !important",
                  },
                }}
              >
                {label}
              </StepLabel>
              <StepContent
                sx={{
                  borderLeft: "2px solid #9fc9ff",
                  marginLeft: "6px",
                  paddingLeft: { md: "16px", xs: "8px" },
                }}
              >
                {" "}
                {getStepContent(activeStep)}{" "}
              </StepContent>
            </Step>
          ))}
        </Stepper>
        {activeStep === steps.length && (
          <Box sx={{ p: 3 }}>
            <MDTypography>All steps completed - you&apos;re finished</MDTypography>
            <MDButton onClick={handleReset} sx={{ mt: 1, mr: 1 }}>
              Reset
            </MDButton>
          </Box>
        )}
      </MDBox>
      {detailPopup && (
        <DetailsPopup
          activeStep={activeStep}
          setActiveStep={setActiveStep}
          open={detailPopup}
          onClose={() => setDetailPopup(false)}
          setDetailPopup={setDetailPopup}
        />
      )}
    </>
  );
}

export default PageOneWizard;
