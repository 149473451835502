import React, { useEffect, useState } from "react";
import MDDialog from "components/MDDialog";
import MDInput from "components/MDInput";
import MDBox from "components/MDBox";
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  Icon,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@mui/material";
import Validations from "helper/validations";
import MDTypography from "components/MDTypography";
import { useAppDispatch } from "store/store";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import MDEditor from "components/MDEditor";
import CustomDropZone from "components/CustomDropZone";
import MDIconButton from "components/MDIconButton";
import { updateWebsites } from "store/thunk/systemAdmin";
import ImagePreview from "layouts/pages/ImagePreview";
import { scrollToErrorByClass } from "helper/services";
import CustomErrorMessage from "components/CustomErrorMessage";
import Testimonials from "./testimonials";

const generalField = ["name", "biography"];
const contactField = ["email_address"];
const socialField = [
  "facebook_url",
  "google_plus_url",
  "instagram_url",
  "linkedin_url",
  "pinterest_url",
  "twitter_url",
  "yelp_url",
  "tik_tok_url",
];

function AddUpdate(props: any): JSX.Element {
  const dispatch = useAppDispatch();

  const { open, onClose, updateData, status, pageNo, perPage, search, sortBy, onReject } = props;
  const [isUpdate, setIsUpdate] = useState<any>(false);
  const [websiteData, setWebsiteData] = useState<any>({});
  const [errors, setErrors] = useState<any>({});
  const [expanded, setExpanded] = React.useState<string[]>([]);
  const [imagePreview, setImagePreview] = useState<any>([]);

  const [isPreview, setImagePreviewPopup] = useState(false);
  const [imageToPreview, setImageToPreview] = useState("");

  const previewImage = (image: any) => {
    setImagePreviewPopup(true);
    setImageToPreview(image);
  };

  const closeImagePreview = () => {
    setImagePreviewPopup(false);
    setImageToPreview("");
  };

  const onSave = () => {
    const error = Validations.validateWebsitesForm(websiteData);
    setErrors(error);
    if (!Object.keys(error).length) {
      const req = {
        websiteData,
        onClose,
        isUpdate,
        status,
        pageNo,
        perPage,
        search,
        sortBy,
      };
      dispatch(updateWebsites(req));
    } else {
      scrollToErrorByClass(error);
      const isGeneral = generalField.some((r) => Object.keys(error).includes(r));
      const isContact = contactField.some((r) => Object.keys(error).includes(r));
      const isSocial = socialField.some((r) => Object.keys(error).includes(r));

      setExpanded([
        ...expanded,
        isGeneral && "general",
        isContact && "contact",
        isSocial && "social",
      ]);
    }
  };
  useEffect(() => {
    if (updateData) {
      const data = { ...updateData };
      if (!data?.f_name && !data?.l_name) {
        if (data?.name) {
          const splitName = data?.name.split(" ");
          const [Fame, Lname] = splitName;
          data.f_name = Fame ? Fame : null;
          data.l_name = Lname ? Lname : null;
        }
      }
      setIsUpdate(true);
      setWebsiteData(data);
    }
  }, [updateData]);

  const onChangeField = (e: any, field: string) => {
    setWebsiteData({ ...websiteData, [field]: e.target.value });
  };

  const dialogProps = {
    open,
    onSave,
    onClose,
    title: "Update My Sola Website",
    size: "xl",
    saveTbtText: isUpdate ? "Approve" : "Save",
    closeBtnText: "Reject",
    closeBtnColor: "error",
    onCloseFunc: () => onReject(updateData.id),
  };

  const handleChangeAccordian = (panel: string) => {
    if (expanded.includes(panel)) {
      const arr = expanded.filter((val: string) => val !== panel);
      setExpanded([...arr]);
    } else {
      setExpanded([...expanded, panel]);
    }
  };

  const onChangeEditor = (value: any) => {
    setWebsiteData({ ...websiteData, biography: value });
  };

  const handleSingleCheckbox = (event: React.ChangeEvent<HTMLInputElement>) => {
    setWebsiteData({ ...websiteData, [event.target.name]: event.target.checked });
  };

  // const addNewElement = async () => {
  //   let testimonials: any = [];
  //   if (websiteData?.testimonials && websiteData?.testimonials.length > 0) {
  //     testimonials = JSON.parse(JSON.stringify(websiteData?.testimonials));
  //   }
  //   testimonials.push({
  //     name: "",
  //     region: "",
  //     text: "",
  //   });
  //   setWebsiteData({ ...websiteData, testimonials });
  // };

  const onChangeMultipleField = (event: any, key: any) => {
    const data = JSON.parse(JSON.stringify(websiteData?.testimonials));
    if (!data[key]) {
      data[key] = {};
    }
    data[key][event.target.name] = event.target.value;
    setWebsiteData({ ...websiteData, testimonials: data });
  };

  // const removeElement = (index: any) => {
  //   const testimonials = websiteData?.testimonials.filter((_element: any, i: any) => i !== index);
  //   setWebsiteData({ ...websiteData, testimonials });
  // };

  const onChangeImage = async (files: any, type: any, index: any) => {
    if (files[0]) {
      const deleteflag = `delete_image_${index}`;
      const file = files[0];
      const reader = new FileReader();
      reader.onload = function (e) {
        setWebsiteData({ ...websiteData, [type]: files[0], [deleteflag]: 0 });
        setImagePreview({ ...imagePreview, [type]: e.target.result });
      };
      reader.readAsDataURL(file);
    }
  };

  const onImageRemove = async (e: any, type: any, index: any) => {
    e.stopPropagation();
    const deleteflag = `delete_image_${index}`;
    setWebsiteData({ ...websiteData, [type]: null, [deleteflag]: 1 });
    setImagePreview({ ...imagePreview, [type]: null });
  };

  const renderImageComponent = (value: string, index: number) => {
    const imageName = `update_my_sola__${value}_${index}_url`;
    const displayImage = `update_my_sola__${value}_${index}_url`;
    return (
      <>
        <MDTypography variant="body2" fontWeight="regular" style={{ textTransform: "capitalize" }}>
          {value} #{index}
        </MDTypography>
        <Grid container>
          <Grid item xs={12} sm={8} md={8} lg={8}>
            <CustomDropZone
              multipleUpload={false}
              acceptedFiles={{
                "image/*": [".gif", ".png", ".jpg", ".jpeg"],
              }}
              onDrop={(e: any) => onChangeImage(e, displayImage, index)}
            >
              Drop your image here, or Browse
            </CustomDropZone>
          </Grid>
          <Grid item xs={12} sm={4} md={4} lg={4}>
            {(websiteData[displayImage] || (imagePreview && imagePreview[imageName])) && (
              <Grid
                container
                onClick={() => previewImage(imagePreview[imageName] || websiteData[displayImage])}
                justifyContent="center"
                alignItems="center"
                display="flex"
              >
                <Grid item className="imageWithDeleteIcon">
                  <img
                    height={100}
                    width={100}
                    src={imagePreview[imageName] || websiteData[displayImage]}
                    alt="website_image"
                  />
                  <MDIconButton
                    tooltipName="Delete"
                    aria-label="Delete"
                    className="removeBtnClass"
                    onClick={(e: any) => onImageRemove(e, imageName, index)}
                  >
                    <Icon fontSize="small">close</Icon>
                  </MDIconButton>
                </Grid>
              </Grid>
            )}
          </Grid>
        </Grid>
      </>
    );
  };

  return (
    <MDDialog {...dialogProps}>
      <Accordion
        expanded={expanded.includes("general")}
        sx={{ marginTop: "20px" }}
        onChange={() => handleChangeAccordian("general")}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="general-content"
          id="general-header"
          className="location-accordion"
        >
          <MDTypography variant="h6">General</MDTypography>
        </AccordionSummary>
        <AccordionDetails>
          {/* <MDBox mt={2}>
            <MDInput
              label="Name"
              variant="standard"
              fullWidth
              required
              name="name"
              placeholder=""
              onChange={(event: any) => onChangeField(event, "name")}
              value={websiteData?.name ? websiteData.name : ""}
              error={errors && errors.name ? true : false}
              helperText={errors && errors.name}
            />
          </MDBox> */}
          <MDBox mt={2}>
            <MDInput
              label="First Name"
              variant="standard"
              fullWidth
              required
              name="f_name"
              onChange={(event: any) => onChangeField(event, "f_name")}
              value={websiteData?.f_name ? websiteData.f_name : ""}
              error={errors && errors.f_name ? true : false}
              helperText={errors && errors.f_name}
            />
          </MDBox>
          <MDBox mt={2}>
            <MDInput
              label="Middle Name"
              variant="standard"
              fullWidth
              name="m_name"
              onChange={(event: any) => onChangeField(event, "m_name")}
              value={websiteData?.m_name ? websiteData.m_name : ""}
            />
          </MDBox>
          <MDBox mt={2}>
            <MDInput
              label="Last Name"
              variant="standard"
              fullWidth
              // required
              name="l_name"
              onChange={(event: any) => onChangeField(event, "l_name")}
              value={websiteData?.l_name ? websiteData.l_name : ""}
              // error={errors && errors.l_name ? true : false}
              // helperText={errors && errors.l_name}
            />
          </MDBox>
          <MDBox mt={2} className="biography">
            <MDTypography variant="button">Biography</MDTypography>
            <MDEditor
              value={websiteData?.biography ? websiteData.biography : ""}
              onChange={(value: any) => onChangeEditor(value)}
            />
            {errors && errors.biography && <CustomErrorMessage message={errors.biography} />}
          </MDBox>
        </AccordionDetails>
      </Accordion>
      <Accordion
        expanded={expanded.includes("contact")}
        sx={{ marginTop: "20px" }}
        onChange={() => handleChangeAccordian("contact")}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="contact-content"
          id="contact-header"
          className="location-accordion"
        >
          <MDTypography variant="h6">Contact</MDTypography>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <MDInput
                label="Phone Number"
                variant="standard"
                fullWidth
                name="phone_number"
                placeholder=""
                onChange={(event: any) => onChangeField(event, "phone_number")}
                value={websiteData?.phone_number ? websiteData.phone_number : ""}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <MDInput
                label="Email Address"
                variant="standard"
                fullWidth
                required
                name="email_address"
                placeholder=""
                onChange={(event: any) => onChangeField(event, "email_address")}
                value={websiteData?.email_address ? websiteData.email_address : ""}
                error={errors && errors.email_address ? true : false}
                helperText={errors && errors.email_address}
              />
            </Grid>
          </Grid>
        </AccordionDetails>
      </Accordion>
      <Accordion
        expanded={expanded.includes("business")}
        sx={{ marginTop: "20px" }}
        onChange={() => handleChangeAccordian("business")}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="business-content"
          id="business-header"
          className="location-accordion"
        >
          <MDTypography variant="h6">Business</MDTypography>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <MDInput
                label="Work Hours"
                fullWidth
                sx={{ marginTop: "8px", marginRight: "8px" }}
                multiline
                variant="standard"
                rows={2}
                InputLabelProps={{ shrink: true }}
                name="work_hours"
                onChange={(event: any) => onChangeField(event, "work_hours")}
                value={websiteData?.work_hours || ""}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6} display="flex" alignItems="flex-end">
              <MDInput
                label="Business Name"
                variant="standard"
                fullWidth
                name="business_name"
                placeholder=""
                onChange={(event: any) => onChangeField(event, "business_name")}
                value={websiteData?.business_name ? websiteData.business_name : ""}
              />
            </Grid>
          </Grid>
        </AccordionDetails>
      </Accordion>
      <Accordion
        expanded={expanded.includes("website")}
        sx={{ marginTop: "20px" }}
        onChange={() => handleChangeAccordian("website")}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="website-content"
          id="website-header"
          className="location-accordion"
        >
          <MDTypography variant="h6">Website</MDTypography>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container columnSpacing={2}>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <MDBox mt={1}>
                <MDInput
                  label="Website Url"
                  variant="standard"
                  fullWidth
                  name="website_url"
                  placeholder=""
                  onChange={(event: any) => onChangeField(event, "website_url")}
                  value={websiteData?.website_url ? websiteData.website_url : ""}
                />
              </MDBox>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <MDBox mt={1}>
                <MDInput
                  label="Booking Url"
                  variant="standard"
                  fullWidth
                  name="booking_url"
                  placeholder=""
                  onChange={(event: any) => onChangeField(event, "booking_url")}
                  value={websiteData?.booking_url ? websiteData.booking_url : ""}
                />
              </MDBox>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <MDBox mt={1}>
                <FormControl component="fieldset">
                  <FormGroup aria-label="position">
                    <FormControlLabel
                      value="end"
                      control={
                        <Checkbox
                          checked={websiteData?.reserved || false}
                          name="reserved"
                          onChange={handleSingleCheckbox}
                          inputProps={{ "aria-label": "controlled" }}
                        />
                      }
                      label="Reserved"
                      labelPlacement="end"
                    />
                  </FormGroup>
                </FormControl>
              </MDBox>
            </Grid>
          </Grid>
        </AccordionDetails>
      </Accordion>
      <Accordion
        expanded={expanded.includes("social")}
        sx={{ marginTop: "20px" }}
        onChange={() => handleChangeAccordian("social")}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="social-content"
          id="social-header"
          className="location-accordion"
        >
          <MDTypography variant="h6">Social</MDTypography>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container columnSpacing={2}>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <MDBox mt={1}>
                <MDInput
                  label="Facebook Url"
                  variant="standard"
                  fullWidth
                  name="facebook_url"
                  placeholder=""
                  onChange={(event: any) => onChangeField(event, "facebook_url")}
                  value={websiteData?.facebook_url ? websiteData.facebook_url : ""}
                  error={errors && errors.facebook_url ? true : false}
                  helperText={errors && errors.facebook_url}
                />
              </MDBox>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <MDBox mt={1}>
                <MDInput
                  label="Google Plus Url"
                  variant="standard"
                  fullWidth
                  name="google_plus_url"
                  placeholder=""
                  onChange={(event: any) => onChangeField(event, "google_plus_url")}
                  value={websiteData?.google_plus_url ? websiteData.google_plus_url : ""}
                  error={errors && errors.google_plus_url ? true : false}
                  helperText={errors && errors.google_plus_url}
                />
              </MDBox>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <MDBox mt={1}>
                <MDInput
                  label="Instagram Url"
                  variant="standard"
                  fullWidth
                  name="instagram_url"
                  placeholder=""
                  onChange={(event: any) => onChangeField(event, "instagram_url")}
                  value={websiteData?.instagram_url ? websiteData.instagram_url : ""}
                  error={errors && errors.instagram_url ? true : false}
                  helperText={errors && errors.instagram_url}
                />
              </MDBox>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <MDBox mt={1}>
                <MDInput
                  label="Linkedin Url"
                  variant="standard"
                  fullWidth
                  name="linkedin_url"
                  placeholder=""
                  onChange={(event: any) => onChangeField(event, "linkedin_url")}
                  value={websiteData?.linkedin_url ? websiteData.linkedin_url : ""}
                  error={errors && errors.linkedin_url ? true : false}
                  helperText={errors && errors.linkedin_url}
                />
              </MDBox>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <MDBox mt={1}>
                <MDInput
                  label="Pinterest Url"
                  variant="standard"
                  fullWidth
                  name="pinterest_url"
                  placeholder=""
                  onChange={(event: any) => onChangeField(event, "pinterest_url")}
                  value={websiteData?.pinterest_url ? websiteData.pinterest_url : ""}
                  error={errors && errors.pinterest_url ? true : false}
                  helperText={errors && errors.pinterest_url}
                />
              </MDBox>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <MDBox mt={1}>
                <MDInput
                  label="Twitter Url"
                  variant="standard"
                  fullWidth
                  name="twitter_url"
                  placeholder=""
                  onChange={(event: any) => onChangeField(event, "twitter_url")}
                  value={websiteData?.twitter_url ? websiteData.twitter_url : ""}
                  error={errors && errors.twitter_url ? true : false}
                  helperText={errors && errors.twitter_url}
                />
              </MDBox>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <MDBox mt={1}>
                <MDInput
                  label="Yelp Url"
                  variant="standard"
                  fullWidth
                  name="yelp_url"
                  placeholder=""
                  onChange={(event: any) => onChangeField(event, "yelp_url")}
                  value={websiteData?.yelp_url ? websiteData.yelp_url : ""}
                  error={errors && errors.yelp_url ? true : false}
                  helperText={errors && errors.yelp_url}
                />
              </MDBox>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <MDBox mt={1}>
                <MDInput
                  label="Tik Tok Url"
                  variant="standard"
                  fullWidth
                  name="tik_tok_url"
                  placeholder=""
                  onChange={(event: any) => onChangeField(event, "tik_tok_url")}
                  value={websiteData?.tik_tok_url ? websiteData.tik_tok_url : ""}
                  error={errors && errors.tik_tok_url ? true : false}
                  helperText={errors && errors.tik_tok_url}
                />
              </MDBox>
            </Grid>
          </Grid>
        </AccordionDetails>
      </Accordion>
      <Accordion
        expanded={expanded.includes("services")}
        sx={{ marginTop: "20px" }}
        onChange={() => handleChangeAccordian("services")}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="services-content"
          id="services-header"
          className="location-accordion"
        >
          <MDTypography variant="h6">Services</MDTypography>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6} md={6} lg={6}>
              <FormControl component="fieldset">
                <FormGroup aria-label="position">
                  <FormControlLabel
                    value="end"
                    control={
                      <Checkbox
                        checked={websiteData?.barber || false}
                        name="barber"
                        onChange={handleSingleCheckbox}
                        inputProps={{ "aria-label": "controlled" }}
                      />
                    }
                    label="Barber"
                    labelPlacement="end"
                  />
                </FormGroup>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6} md={6} lg={6}>
              <FormControl component="fieldset">
                <FormGroup aria-label="position">
                  <FormControlLabel
                    value="end"
                    control={
                      <Checkbox
                        checked={websiteData?.botox || false}
                        name="botox"
                        onChange={handleSingleCheckbox}
                        inputProps={{ "aria-label": "controlled" }}
                      />
                    }
                    label="Botox/Fillers"
                    labelPlacement="end"
                  />
                </FormGroup>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6} md={6} lg={6}>
              <FormControl component="fieldset">
                <FormGroup aria-label="position">
                  <FormControlLabel
                    value="end"
                    control={
                      <Checkbox
                        checked={websiteData?.brows || false}
                        name="brows"
                        onChange={handleSingleCheckbox}
                        inputProps={{ "aria-label": "controlled" }}
                      />
                    }
                    label="Brows"
                    labelPlacement="end"
                  />
                </FormGroup>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6} md={6} lg={6}>
              <FormControl component="fieldset">
                <FormGroup aria-label="position">
                  <FormControlLabel
                    value="end"
                    control={
                      <Checkbox
                        checked={websiteData?.hair || false}
                        name="hair"
                        onChange={handleSingleCheckbox}
                        inputProps={{ "aria-label": "controlled" }}
                      />
                    }
                    label="Hair"
                    labelPlacement="end"
                  />
                </FormGroup>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6} md={6} lg={6}>
              <FormControl component="fieldset">
                <FormGroup aria-label="position">
                  <FormControlLabel
                    value="end"
                    control={
                      <Checkbox
                        checked={websiteData?.hair_extensions || false}
                        name="hair_extensions"
                        onChange={handleSingleCheckbox}
                        inputProps={{ "aria-label": "controlled" }}
                      />
                    }
                    label="Hair Extensions"
                    labelPlacement="end"
                  />
                </FormGroup>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6} md={6} lg={6}>
              <FormControl component="fieldset">
                <FormGroup aria-label="position">
                  <FormControlLabel
                    value="end"
                    control={
                      <Checkbox
                        checked={websiteData?.laser_hair_removal || false}
                        name="laser_hair_removal"
                        onChange={handleSingleCheckbox}
                        inputProps={{ "aria-label": "controlled" }}
                      />
                    }
                    label="Laser Hair Removal"
                    labelPlacement="end"
                  />
                </FormGroup>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6} md={6} lg={6}>
              <FormControl component="fieldset">
                <FormGroup aria-label="position">
                  <FormControlLabel
                    value="end"
                    control={
                      <Checkbox
                        checked={websiteData?.eyelash_extensions || false}
                        name="eyelash_extensions"
                        onChange={handleSingleCheckbox}
                        inputProps={{ "aria-label": "controlled" }}
                      />
                    }
                    label="Lashes"
                    labelPlacement="end"
                  />
                </FormGroup>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6} md={6} lg={6}>
              <FormControl component="fieldset">
                <FormGroup aria-label="position">
                  <FormControlLabel
                    value="end"
                    control={
                      <Checkbox
                        checked={websiteData?.makeup || false}
                        name="makeup"
                        onChange={handleSingleCheckbox}
                        inputProps={{ "aria-label": "controlled" }}
                      />
                    }
                    label="Makeup"
                    labelPlacement="end"
                  />
                </FormGroup>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6} md={6} lg={6}>
              <FormControl component="fieldset">
                <FormGroup aria-label="position">
                  <FormControlLabel
                    value="end"
                    control={
                      <Checkbox
                        checked={websiteData?.massage || false}
                        name="massage"
                        onChange={handleSingleCheckbox}
                        inputProps={{ "aria-label": "controlled" }}
                      />
                    }
                    label="Massage"
                    labelPlacement="end"
                  />
                </FormGroup>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6} md={6} lg={6}>
              <FormControl component="fieldset">
                <FormGroup aria-label="position">
                  <FormControlLabel
                    value="end"
                    control={
                      <Checkbox
                        checked={websiteData?.nails || false}
                        name="nails"
                        onChange={handleSingleCheckbox}
                        inputProps={{ "aria-label": "controlled" }}
                      />
                    }
                    label="Nails"
                    labelPlacement="end"
                  />
                </FormGroup>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6} md={6} lg={6}>
              <FormControl component="fieldset">
                <FormGroup aria-label="position">
                  <FormControlLabel
                    value="end"
                    control={
                      <Checkbox
                        checked={websiteData?.permanent_makeup || false}
                        name="permanent_makeup"
                        onChange={handleSingleCheckbox}
                        inputProps={{ "aria-label": "controlled" }}
                      />
                    }
                    label="Permanent Makeup"
                    labelPlacement="end"
                  />
                </FormGroup>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6} md={6} lg={6}>
              <FormControl component="fieldset">
                <FormGroup aria-label="position">
                  <FormControlLabel
                    value="end"
                    control={
                      <Checkbox
                        checked={websiteData?.skin || false}
                        name="skin"
                        onChange={handleSingleCheckbox}
                        inputProps={{ "aria-label": "controlled" }}
                      />
                    }
                    label="Skincare"
                    labelPlacement="end"
                  />
                </FormGroup>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6} md={6} lg={6}>
              <FormControl component="fieldset">
                <FormGroup aria-label="position">
                  <FormControlLabel
                    value="end"
                    control={
                      <Checkbox
                        checked={websiteData?.tanning || false}
                        name="tanning"
                        onChange={handleSingleCheckbox}
                        inputProps={{ "aria-label": "controlled" }}
                      />
                    }
                    label="Tanning"
                    labelPlacement="end"
                  />
                </FormGroup>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6} md={6} lg={6}>
              <FormControl component="fieldset">
                <FormGroup aria-label="position">
                  <FormControlLabel
                    value="end"
                    control={
                      <Checkbox
                        checked={websiteData?.teeth_whitening || false}
                        name="teeth_whitening"
                        onChange={handleSingleCheckbox}
                        inputProps={{ "aria-label": "controlled" }}
                      />
                    }
                    label="Teeth Whitening"
                    labelPlacement="end"
                  />
                </FormGroup>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6} md={6} lg={6}>
              <FormControl component="fieldset">
                <FormGroup aria-label="position">
                  <FormControlLabel
                    value="end"
                    control={
                      <Checkbox
                        checked={websiteData?.threading || false}
                        name="threading"
                        onChange={handleSingleCheckbox}
                        inputProps={{ "aria-label": "controlled" }}
                      />
                    }
                    label="Threading"
                    labelPlacement="end"
                  />
                </FormGroup>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6} md={6} lg={6}>
              <FormControl component="fieldset">
                <FormGroup aria-label="position">
                  <FormControlLabel
                    value="end"
                    control={
                      <Checkbox
                        checked={websiteData?.waxing || false}
                        name="waxing"
                        onChange={handleSingleCheckbox}
                        inputProps={{ "aria-label": "controlled" }}
                      />
                    }
                    label="Waxing"
                    labelPlacement="end"
                  />
                </FormGroup>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <MDInput
                label="Other"
                variant="standard"
                fullWidth
                name="other_service"
                placeholder=""
                onChange={(event: any) => onChangeField(event, "other_service")}
                value={websiteData?.other_service ? websiteData.other_service : ""}
              />
            </Grid>
          </Grid>
        </AccordionDetails>
      </Accordion>
      <Accordion
        expanded={expanded.includes("testimonials")}
        sx={{ marginTop: "20px" }}
        onChange={() => handleChangeAccordian("testimonials")}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="testimonials-content"
          id="testimonials-header"
          className="location-accordion"
        >
          <MDTypography variant="h6">Testimonials</MDTypography>
        </AccordionSummary>
        <AccordionDetails>
          <Testimonials
            websiteData={websiteData}
            // addNewElement={addNewElement}
            onChangeMultipleField={onChangeMultipleField}
            // removeElement={removeElement}
          />
        </AccordionDetails>
      </Accordion>
      <Accordion
        expanded={expanded.includes("images")}
        sx={{ marginTop: "20px" }}
        onChange={() => handleChangeAccordian("images")}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="images-content"
          id="images-header"
          className="location-accordion"
        >
          <MDTypography variant="h6">Images</MDTypography>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              {renderImageComponent("image", 1)}
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              {renderImageComponent("image", 2)}
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              {renderImageComponent("image", 3)}
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              {renderImageComponent("image", 4)}
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              {renderImageComponent("image", 5)}
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              {renderImageComponent("image", 6)}
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              {renderImageComponent("image", 7)}
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              {renderImageComponent("image", 8)}
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              {renderImageComponent("image", 9)}
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              {renderImageComponent("image", 10)}
            </Grid>
          </Grid>
        </AccordionDetails>
      </Accordion>
      <ImagePreview
        imageData={imageToPreview}
        open={isPreview}
        onCloseImage={() => {
          closeImagePreview();
        }}
      />
    </MDDialog>
  );
}

export default AddUpdate;
