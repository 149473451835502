import { useState, useEffect } from "react";
import { Card, Grid } from "@mui/material";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import moment from "moment";
import { useAppSelector } from "store/store";
import MakePayment from "../makePayment";

function NextPayment(): JSX.Element {
  const { billingTabDetail } = useAppSelector((state) => state.solaProSlice);
  const [openMakePayment, setOpenMakePayment] = useState(false);
  const [nextPaymentData, setNextPaymentData] = useState<any>({});

  useEffect(() => {
    if (billingTabDetail && billingTabDetail?.nextPaymentDetail) {
      setNextPaymentData(billingTabDetail?.nextPaymentDetail);
    }
  }, [billingTabDetail]);

  const getLabelElement = (name: any, right?: any) => (
    <Grid item xs={12} sm={6} md={6} lg={6} sx={{ textAlign: right ? "right" : "left" }}>
      <MDTypography variant="subtitle2" fontWeight="regular">
        {name}
      </MDTypography>
    </Grid>
  );

  const recurringValue = () =>
    nextPaymentData?.RentPeriod && nextPaymentData?.RentDueDay
      ? `${nextPaymentData?.RentPeriod}, ${
          moment().day(Number(nextPaymentData?.RentDueDay)).format("dddd")
            ? moment().day(Number(nextPaymentData?.RentDueDay)).format("dddd")
            : "-"
        }`
      : "-";

  const nextDueDate = () =>
    nextPaymentData?.RentDueDay
      ? moment().day(Number(nextPaymentData?.RentDueDay)).format("DD-MM-YYYY")
      : "-";

  return (
    <Card id="next-payment" sx={{ overflow: "visible" }}>
      <MDBox p={3}>
        <MDTypography variant="h5">Next Payment</MDTypography>
      </MDBox>
      <MDBox pb={3} px={3}>
        <Grid container spacing={3}>
          {getLabelElement("Amount Due")}
          {getLabelElement(nextPaymentData?.OpenBalance || 0, true)}
          {getLabelElement("Due Date")}
          {getLabelElement(nextDueDate(), true)}
          {getLabelElement("Recurring")}
          {getLabelElement(recurringValue(), true)}
        </Grid>
        {/* <Grid container>
          <Grid item xs={12} md={12} sm={12} lg={12} pt={2}>
            <MDButton
              variant="gradient"
              color="info"
              size="small"
              onClick={() => setOpenMakePayment(true)}
            >
              View / Edit Payment Method
            </MDButton>
          </Grid>
        </Grid> */}
      </MDBox>
      <MakePayment open={openMakePayment} onClose={() => setOpenMakePayment(false)} />
    </Card>
  );
}

export default NextPayment;
