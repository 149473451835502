import MDBox from "components/MDBox";
import EleaseButtons from "components/EleaseButtons";
import {
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  Switch,
} from "@mui/material";
import MDTypography from "components/MDTypography";
import { useAppSelector } from "store/store";
import { useEffect, useState } from "react";
import MDDatePicker from "components/MDDatePicker";
import CustomRequiredLabel from "components/CustomRequiredLabel";
import CustomErrorMessage from "components/CustomErrorMessage";
import { addMonths, getNextPaymentDate } from "helper/services";
import Validations from "helper/validations";
import MDInput from "components/MDInput";
import moment from "moment";

function LeaseApplication(props: any): JSX.Element {
  const {
    saveDraftData,
    setSaveDraftData,
    childLeaseData,
    setChildLeaseData,
    setSubletApplicantError,
  } = props;
  const { leaseApplicationSteps } = useAppSelector((state) => state.leaseSlice);
  const [removeError, setRemoveError] = useState<boolean>(true);
  const [errors, setErrors] = useState<any>({});
  const currentDate = moment().utc().toISOString();

  // console.log("leaseApplicationSteps ", leaseApplicationSteps);
  useEffect(() => {
    setSubletApplicantError(false);
    const date = childLeaseData?.move_in_date
      ? new Date(childLeaseData?.move_in_date)
      : currentDate;
    if (leaseApplicationSteps?.lease_term) {
      let leaseEndDate = "";
      const leaseEnd = addMonths(
        date,
        Number(
          childLeaseData?.lease_term
            ? childLeaseData?.lease_term
            : leaseApplicationSteps?.lease_term
        )
      );
      leaseEndDate = moment(leaseEnd).utc().toISOString();
      setChildLeaseData({
        ...childLeaseData,
        lease_end: leaseEndDate,
        // lease_sign: moment(new Date()).toISOString(),
        lease_sign: currentDate,
        lease_term: leaseApplicationSteps?.lease_term,
        rent_due_date: leaseApplicationSteps?.rent_due_date,
        insurance_charge_frequency: leaseApplicationSteps?.insurance_charge_frequency,
        insurance_fee: leaseApplicationSteps?.insurance_fee,
        weeks_free_rent: leaseApplicationSteps?.weeks_free_rent,
        months_free_insurance: leaseApplicationSteps?.months_free_insurance,
      });
    }
    if (leaseApplicationSteps?.leaseData) {
      setRemoveError(false);
      setChildLeaseData({
        ...childLeaseData,
        lease_sign: leaseApplicationSteps?.leaseData?.lease_sign,
        lease_term: leaseApplicationSteps?.leaseData?.lease_term,
        rent_due_date: leaseApplicationSteps?.leaseData?.rent_due_date,
        insurance_charge_frequency: leaseApplicationSteps?.leaseData?.insurance_charge_frequency,
        insurance_fee: leaseApplicationSteps?.leaseData?.insurance_fee,
        weeks_free_rent: leaseApplicationSteps?.leaseData?.weeks_free_rent,
        months_free_insurance: leaseApplicationSteps?.leaseData?.months_free_insurance,
        move_in_date: leaseApplicationSteps?.leaseData?.move_in_date,
        lease_start: leaseApplicationSteps?.leaseData?.lease_start,
        lease_end: leaseApplicationSteps?.leaseData?.lease_end,
        first_payment_date: leaseApplicationSteps?.leaseData?.first_payment_date,
        licence_fee_1: leaseApplicationSteps?.leaseData?.licence_fee_1,
        licence_fee_2: leaseApplicationSteps?.leaseData?.licence_fee_2,
      });
    }
  }, [leaseApplicationSteps]);

  const onChangeField = (e: any) => {
    setRemoveError(true);
    // const date = childLeaseData?.move_in_date ? new Date(childLeaseData.move_in_date) : currentDate;
    if (!errors) {
      setRemoveError(false);
    }
    const date = childLeaseData?.move_in_date
      ? moment(childLeaseData.move_in_date).utc().toISOString()
      : currentDate;
    if (e.target.name === "lease_term") {
      const leaseEnd = addMonths(date, Number(e.target.value));
      setChildLeaseData({
        ...childLeaseData,
        [e.target.name]: e.target.value,
        lease_end: moment(leaseEnd).utc().toISOString(),
      });
    } else {
      setChildLeaseData({
        ...childLeaseData,
        [e.target.name]: e.target.value,
      });
    }
    // let allFieldsFilled = true;
    // // Check if all four fields are filled or not
    // if (
    //   childLeaseData?.weeks_free_rent !== "" &&
    //   childLeaseData?.months_free_insurance !== "" &&
    //   childLeaseData?.licence_fee_1 !== "" &&
    //   childLeaseData?.licence_fee_2 !== "" &&
    //   childLeaseData?.move_in_date !== ""
    // ) {
    //   allFieldsFilled = false;
    // }
    // setSubletApplicantError(allFieldsFilled);
  };

  const handleOnSave = () => {
    const errors = Validations.validateLeaseData(childLeaseData);
    setErrors(errors);
    if (!Object.keys(errors).length) {
      setRemoveError(false);
    }
    setSaveDraftData({ ...saveDraftData, childLeaseData });
  };

  const onChangeMoveinDate = (value: any, name: any) => {
    setRemoveError(true);
    if (!errors) {
      setRemoveError(false);
    }
    const date = new Date(value);
    const leaseEnd = addMonths(date, Number(childLeaseData.lease_term));
    // const firstPayment = addWeeks(date, childLeaseData?.weeks_free_rent || 0);
    const insuranceStart = addMonths(date, Number(childLeaseData?.months_free_insurance || 0));
    const firstPayment = getNextPaymentDate(
      date,
      childLeaseData?.weeks_free_rent || 0,
      childLeaseData?.rent_due_date
    );
    // const firstPaymentDate = getNextMondayDate(
    //   date,
    //   childLeaseData?.rent_due_date,
    //   childLeaseData?.weeks_free_rent
    // );
    // console.log("firstPaymentDate ", firstPaymentDate);
    setChildLeaseData({
      ...childLeaseData,
      [name]: new Date(value).toISOString(),
      lease_start: moment.utc(date).toISOString(),
      lease_end: moment.utc(leaseEnd).toISOString(),
      first_payment_date: moment.utc(firstPayment).toISOString(),
      insurance_start: moment.utc(insuranceStart).toISOString(),
    });
  };

  const onChangeDate = (value: any, name: any) => {
    setRemoveError(true);
    if (!errors) {
      setRemoveError(false);
    }
    setChildLeaseData({ ...childLeaseData, [name]: new Date(value).toISOString() });
  };

  const onChangeCheck = (e: any) => {
    setRemoveError(true);
    if (!errors) {
      setRemoveError(false);
    }
    setChildLeaseData({ ...childLeaseData, [e.target.name]: e.target.checked });
  };

  const onChangeRentDueField = (e: any) => {
    const firstPayment = getNextPaymentDate(
      childLeaseData?.move_in_date,
      childLeaseData?.weeks_free_rent || 0,
      e.target.value
    );
    setChildLeaseData({
      ...childLeaseData,
      [e.target.name]: e.target.value,
      first_payment_date: moment.utc(firstPayment).toISOString(),
    });
  };

  return (
    <MDBox sx={{ maxWidth: "auto" }}>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12} md={12} lg={12} sx={{ marginTop: "auto" }}>
          <MDTypography variant="h5" mt={4} mb={2}>
            Lease Details
          </MDTypography>
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6}>
          <InputLabel id="location_name">Location Name</InputLabel>
          {leaseApplicationSteps?.locations?.name}
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6}>
          <InputLabel id="rent_period">Rent Period</InputLabel>
          {leaseApplicationSteps?.rent_period}
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6}>
          <InputLabel id="security_deposite">Security Deposit</InputLabel>${" "}
          {leaseApplicationSteps?.security_deposite ? leaseApplicationSteps?.security_deposite : 0}
        </Grid>
      </Grid>
      <MDBox p={2}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <MDTypography variant="h5" mt={4} mb={2}>
              Add Move-In Incentives
            </MDTypography>
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <MDInput
              required
              fullWidth
              type="number"
              label="Weeks Free Rent"
              variant="outlined"
              inputProps={{ min: 0 }}
              name="weeks_free_rent"
              placeholder="Weeks Free Rent"
              onChange={onChangeField}
              value={childLeaseData?.weeks_free_rent}
              error={(errors && errors?.weeks_free_rent) || false}
              helperText={errors && errors?.weeks_free_rent}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <MDInput
              required
              fullWidth
              type="number"
              label="Months Free Insurance"
              variant="outlined"
              inputProps={{ min: 0 }}
              name="months_free_insurance"
              placeholder="Months Free Insurance"
              onChange={onChangeField}
              value={childLeaseData?.months_free_insurance}
              error={(errors && errors?.months_free_insurance) || false}
              helperText={errors && errors?.months_free_insurance}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <FormControl component="fieldset">
              <FormGroup aria-label="position" row>
                <FormControlLabel
                  value="free_paint"
                  control={
                    <Checkbox
                      checked={childLeaseData?.free_paint || false}
                      name="free_paint"
                      onChange={onChangeCheck}
                      inputProps={{ "aria-label": "controlled" }}
                    />
                  }
                  label="Free Paint"
                  labelPlacement="end"
                />
              </FormGroup>
            </FormControl>
          </Grid>
        </Grid>
      </MDBox>
      <MDBox p={2}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <MDTypography variant="h5" mt={4} mb={2}>
              License Fees
            </MDTypography>
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <FormControl fullWidth>
              <CustomRequiredLabel label="License Fee Year 1" />
              <OutlinedInput
                type="number"
                fullWidth
                // value={childLeaseData?.licence_fee_1 || childLeaseData?.license_fee_year1 || ""}
                value={childLeaseData?.licence_fee_1 || ""}
                name="licence_fee_1"
                onChange={onChangeField}
                inputProps={{ min: 0 }}
                error={(errors && errors?.licence_fee_1) || false}
                startAdornment="$"
              />
              {errors && errors?.licence_fee_1 && (
                <CustomErrorMessage message={errors?.licence_fee_1} />
              )}
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <FormControl fullWidth>
              <CustomRequiredLabel label="License Fee Year 2" />
              <OutlinedInput
                type="number"
                fullWidth
                // value={childLeaseData?.licence_fee_2 || childLeaseData?.license_fee_year2 || ""}
                value={childLeaseData?.licence_fee_2 || ""}
                name="licence_fee_2"
                onChange={onChangeField}
                inputProps={{ min: 0 }}
                error={(errors && errors?.licence_fee_2) || false}
                startAdornment="$"
              />
              {errors && errors?.licence_fee_2 && (
                <CustomErrorMessage message={errors?.licence_fee_2} />
              )}
            </FormControl>
          </Grid>
        </Grid>
      </MDBox>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <MDTypography variant="h5" mt={4} mb={2}>
            Lease Dates
          </MDTypography>
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6} sx={{ marginTop: "20px" }}>
          <FormControl fullWidth>
            <InputLabel id="lease_term">Lease Term</InputLabel>
            <Select
              label="lease_term"
              className="height45"
              name="lease_term"
              value={childLeaseData?.lease_term || ""}
              onChange={onChangeField}
            >
              <MenuItem value="MTM">MTM</MenuItem>
              <MenuItem value="6">6 Month</MenuItem>
              <MenuItem value="12">12 Month</MenuItem>
              <MenuItem value="18">18 Month</MenuItem>
              <MenuItem value="24">24 Month</MenuItem>
              <MenuItem value="36">36 Month</MenuItem>
              <MenuItem value="48">48 Month</MenuItem>
              <MenuItem value="60">60 Month</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6}>
          <FormControl fullWidth>
            <CustomRequiredLabel label="Move In Date" />
            <MDDatePicker
              placeholder="Move In Date"
              name="move_in_date"
              onChange={(value: any) => onChangeMoveinDate(value, "move_in_date")}
              options={{
                enableTime: false,
                dateFormat: "m/d/Y",
                time_24hr: true,
                minDate: currentDate,
              }}
              value={childLeaseData?.move_in_date}
              required
            />
            {errors && errors.move_in_date && <CustomErrorMessage message={errors.move_in_date} />}
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6}>
          <FormControl fullWidth>
            <CustomRequiredLabel label="Lease Sign" />
            <MDDatePicker
              name="lease_sign"
              onChange={(value: any) => onChangeDate(value, "lease_sign")}
              options={{
                enableTime: false,
                dateFormat: "m/d/Y",
                time_24hr: true,
              }}
              value={childLeaseData?.lease_sign}
            />
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6}>
          <FormControl fullWidth>
            <CustomRequiredLabel label="Lease Start" />
            <MDDatePicker
              name="lease_start"
              onChange={(value: any) => onChangeDate(value, "lease_start")}
              options={{
                enableTime: false,
                dateFormat: "m/d/Y",
                time_24hr: true,
              }}
              inputProps={{ readOnly: true }}
              value={childLeaseData?.lease_start}
              required
            />
            {errors && errors.lease_start && <CustomErrorMessage message={errors.lease_start} />}
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6}>
          <FormControl fullWidth required>
            <CustomRequiredLabel label="Lease End" />
            <MDDatePicker
              name="lease_end"
              onChange={(value: any) => onChangeDate(value, "lease_end")}
              options={{
                enableTime: false,
                dateFormat: "m/d/Y",
                time_24hr: true,
              }}
              input={{
                readOnly: true,
                disabled: true,
              }}
              inputProps={{ readOnly: true }}
              value={childLeaseData?.lease_end}
            />
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6}>
          <FormControl fullWidth>
            <CustomRequiredLabel label="First Payment Date" />
            <MDDatePicker
              name="first_payment_date"
              onChange={(value: any) => onChangeDate(value, "first_payment_date")}
              options={{
                enableTime: false,
                dateFormat: "m/d/Y",
                time_24hr: true,
              }}
              input={{
                readOnly: true,
                disabled: true,
              }}
              inputProps={{ readOnly: true }}
              value={childLeaseData?.first_payment_date}
            />
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6}>
          <FormControl fullWidth>
            <InputLabel id="rent_due_date">Rent Due Date</InputLabel>
            <Select
              required
              label="rent_due_date"
              className="height45"
              error={errors && errors?.rent_due_date ? true : false}
              name="rent_due_date"
              value={childLeaseData?.rent_due_date || ""}
              onChange={onChangeRentDueField}
            >
              <MenuItem value="Monday">Monday</MenuItem>
              <MenuItem value="Tuesday">Tuesday</MenuItem>
              <MenuItem value="Wednesday">Wednesday</MenuItem>
              <MenuItem value="Thursday">Thursday</MenuItem>
              <MenuItem value="Friday">Friday</MenuItem>
              <MenuItem value="Saturday">Saturday</MenuItem>
              <MenuItem value="Sunday">Sunday</MenuItem>
            </Select>
            {errors && errors.rent_due_date && (
              <CustomErrorMessage message={errors.rent_due_date} />
            )}
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <FormControlLabel
            control={
              <Switch
                name="charge_insurance"
                checked={childLeaseData?.charge_insurance}
                onChange={onChangeCheck}
                inputProps={{ "aria-label": "controlled" }}
              />
            }
            label={
              <MDBox display="flex">
                <MDTypography variant="subtitle2">
                  <strong>Charge Insurance</strong>
                </MDTypography>
                <MDTypography variant="subtitle2" ml={1}>
                  (after promos)
                </MDTypography>
              </MDBox>
            }
            labelPlacement="end"
          />
        </Grid>
        {childLeaseData?.charge_insurance && (
          <>
            <Grid item xs={12} sm={9} md={6} lg={6}>
              <FormControl fullWidth required sx={{ paddingTop: "4px" }}>
                <CustomRequiredLabel label="Insurance Charge Frequency" />
                <Select
                  required
                  className="height45"
                  error={errors && errors?.insurance_charge_frequency ? true : false}
                  name="insurance_charge_frequency"
                  value={childLeaseData?.insurance_charge_frequency}
                  onChange={onChangeField}
                >
                  <MenuItem value="weekly">Weekly</MenuItem>
                  <MenuItem value="annually">Annually</MenuItem>
                </Select>
                {errors && errors.insurance_charge_frequency && (
                  <CustomErrorMessage message={errors.insurance_charge_frequency} />
                )}
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <CustomRequiredLabel label="Insurance Fee" />
              </Grid>
              <OutlinedInput
                required
                type="number"
                fullWidth
                value={childLeaseData?.insurance_fee}
                name="insurance_fee"
                onChange={onChangeField}
                inputProps={{ min: 0 }}
                error={(errors && errors?.insurance_fee) || false}
                startAdornment="$"
              />
              {errors && errors?.insurance_fee && (
                <CustomErrorMessage message={errors?.insurance_fee} />
              )}
            </Grid>
          </>
        )}
        <Grid item xs={12} sm={12} md={6} lg={6}>
          <MDInput
            label="Notes"
            fullWidth
            multiline
            rows={4}
            InputLabelProps={{ shrink: true }}
            variant="outlined"
            name="note"
            onChange={onChangeField}
            value={childLeaseData?.note}
          />
        </Grid>
      </Grid>
      <Button
        variant="outlined"
        onClick={handleOnSave}
        sx={{
          mt: 1,
          mr: 1,
          color: "#1a73e8",
          "&:hover": {
            borderColor: "#1a73e8",
          },
        }}
      >
        Save
      </Button>
      <EleaseButtons
        {...props}
        data={{
          disabledNext: removeError,
          leaseMainData: childLeaseData,
          securityDeposit: true,
        }}
      />
    </MDBox>
  );
}

export default LeaseApplication;
