import Switch from "@mui/material/Switch";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableRow from "@mui/material/TableRow";
import MDBox from "components/MDBox";
import MDDialog from "components/MDDialog";
import MDTypography from "components/MDTypography";
import TableCell from "layouts/pages/account/settings/components/TableCell";

function EditPopup(props: any): JSX.Element {
  const { open, onClose } = props;

  const dialogProps = {
    open,
    onClose,
    title: "Access Update",
    size: "lg",
  };

  return (
    <MDDialog {...dialogProps}>
      <MDBox pb={3} px={3}>
        <MDBox minWidth="auto">
          <Table sx={{ minWidth: "36rem" }}>
            <MDBox component="thead">
              <TableRow>
                <TableCell width="100%" padding={[1.5, 3, 1.5, 0.5]}>
                  Location Access
                </TableCell>
                <TableCell align="center" padding={[1.5, 6, 1.5, 6]}>
                  View
                </TableCell>
                <TableCell align="center" padding={[1.5, 6, 1.5, 6]}>
                  Edit
                </TableCell>
                <TableCell align="center" padding={[1.5, 6, 1.5, 6]}>
                  Add/Remove
                </TableCell>
              </TableRow>
            </MDBox>
            <TableBody>
              <TableRow>
                <TableCell padding={[1, 1, 1, 0.5]}>
                  <MDBox lineHeight={1.4}>
                    <MDTypography display="block" variant="button" fontWeight="regular">
                      Location Users
                    </MDTypography>
                  </MDBox>
                </TableCell>
                <TableCell align="center" padding={[1, 1, 1, 0.5]}>
                  <Switch defaultChecked />
                </TableCell>
                <TableCell align="center" padding={[1, 1, 1, 0.5]}>
                  <Switch />
                </TableCell>
                <TableCell align="center" padding={[1, 1, 1, 0.5]}>
                  <Switch />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell padding={[1, 1, 1, 0.5]}>
                  <MDBox lineHeight={1.4}>
                    <MDTypography display="block" variant="button" fontWeight="regular">
                      Location Website
                    </MDTypography>
                  </MDBox>
                </TableCell>
                <TableCell align="center" padding={[1, 1, 1, 0.5]}>
                  <Switch defaultChecked />
                </TableCell>
                <TableCell align="center" padding={[1, 1, 1, 0.5]}>
                  <Switch defaultChecked />
                </TableCell>
                {/* <TableCell align="center" padding={[1, 1, 1, 0.5]}>
                  <Switch />
                </TableCell> */}
              </TableRow>
              <TableRow>
                <TableCell padding={[1, 1, 1, 0.5]}>
                  <MDBox lineHeight={1.4}>
                    <MDTypography display="block" variant="button" fontWeight="regular">
                      Location Sola Pros
                    </MDTypography>
                  </MDBox>
                </TableCell>
                <TableCell align="center" padding={[1, 1, 1, 0.5]}>
                  <Switch defaultChecked />
                </TableCell>
                <TableCell align="center" padding={[1, 1, 1, 0.5]}>
                  <Switch defaultChecked />
                </TableCell>
                <TableCell align="center" padding={[1, 1, 1, 0.5]}>
                  <Switch defaultChecked />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell padding={[1, 1, 1, 0.5]} noBorder>
                  <MDTypography display="block" variant="button" fontWeight="regular">
                    Location Rent Manager
                  </MDTypography>
                </TableCell>
                <TableCell align="center" padding={[1, 1, 1, 0.5]} noBorder>
                  <Switch defaultChecked />
                </TableCell>
                <TableCell align="center" padding={[1, 1, 1, 0.5]} noBorder>
                  <Switch />
                </TableCell>
                <TableCell align="center" padding={[1, 1, 1, 0.5]} noBorder>
                  <Switch />
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
          <Table sx={{ minWidth: "36rem", marginTop: "10px" }}>
            <MDBox component="thead">
              <TableRow>
                <TableCell width="100%" padding={[1.5, 3, 1.5, 0.5]}>
                  Association Access
                </TableCell>
                <TableCell align="center" padding={[1.5, 6, 1.5, 6]}>
                  View
                </TableCell>
                <TableCell align="center" padding={[1.5, 6, 1.5, 6]}>
                  Add/Remove
                </TableCell>
                <TableCell align="center" padding={[1.5, 6, 1.5, 6]}>
                  Edit Permissions
                </TableCell>
              </TableRow>
            </MDBox>
            <TableBody>
              <TableRow>
                <TableCell padding={[1, 1, 1, 0.5]}>
                  <MDBox lineHeight={1.4}>
                    <MDTypography display="block" variant="button" fontWeight="regular">
                      Association Users
                    </MDTypography>
                  </MDBox>
                </TableCell>
                <TableCell align="center" padding={[1, 1, 1, 0.5]}>
                  <Switch />
                </TableCell>
                <TableCell align="center" padding={[1, 1, 1, 0.5]}>
                  <Switch />
                </TableCell>
                <TableCell align="center" padding={[1, 1, 1, 0.5]}>
                  <Switch />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell padding={[1, 1, 1, 0.5]}>
                  <MDBox lineHeight={1.4}>
                    <MDTypography display="block" variant="button" fontWeight="regular">
                      Association Locations
                    </MDTypography>
                  </MDBox>
                </TableCell>
                <TableCell align="center" padding={[1, 1, 1, 0.5]}>
                  <Switch defaultChecked />
                </TableCell>
                <TableCell align="center" padding={[1, 1, 1, 0.5]}>
                  <Switch />
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </MDBox>
      </MDBox>
    </MDDialog>
  );
}

export default EditPopup;
