import { Card, FormControlLabel, Grid, Switch } from "@mui/material";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

function NumberStatus(props: any): JSX.Element {
  const { onChangeField, phoneDetail } = props;

  return (
    <Card id="number-status" sx={{ overflow: "visible" }}>
      <MDBox p={3}>
        <MDTypography variant="h5">Number Status</MDTypography>
      </MDBox>
      <MDBox component="form" pb={3} px={3}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={12}>
            <FormControlLabel
              control={
                <Switch
                  checked={
                    phoneDetail?.status === "enabled" || phoneDetail?.status === true ? true : false
                  }
                  name="status"
                  onChange={onChangeField}
                  inputProps={{ "aria-label": "controlled" }}
                />
              }
              label={
                phoneDetail?.status === "enabled" || phoneDetail?.status === true
                  ? "Enabled"
                  : "Disabled"
              }
            />
          </Grid>
        </Grid>
      </MDBox>
    </Card>
  );
}

export default NumberStatus;
