import { createSlice, Draft, PayloadAction } from "@reduxjs/toolkit";

interface InitialState {
  allInquiries: any;
}

const initialState: InitialState = {
  allInquiries: [],
};

const partnerInquiriesSlice = createSlice({
  name: "partnerInquiriesSlice",
  initialState,
  reducers: {
    allInquiriesList: (state: Draft<InitialState>, action: PayloadAction<any>) => ({
      ...state,
      allInquiries: action.payload,
    }),
  },
});

export const { allInquiriesList } = partnerInquiriesSlice.actions;

export default partnerInquiriesSlice.reducer;
