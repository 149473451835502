/* eslint-disable no-unused-vars */
import MDBox from "components/MDBox";
import ScorecardDateInput from "layouts/pages/scorecard-dashboard/components/scorecardDateInput";
import { SelectedDateRange } from "layouts/pages/scorecard-dashboard";
import { Button, Card, Typography } from "@mui/material";
import DataTable from "examples/Tables/DataTable";
import { convertSourceTypeData, getYears, SourceTypeTable } from "helper/reports";
import { useEffect, useState } from "react";
import { ScorecardDate } from "layouts/pages/scorecard-dashboard/scorecard";
import { SelectedDate, SourceTypeProps, Year } from "../..";
import SourceType from "./sourceType";

interface SourceTypeInputProps {
  selectedDate: SelectedDate;
  // eslint-disable-next-line no-unused-vars
  onChangeDateField: (event: Date) => void;
  //   dateArr: ScorecardDate[];
  selectedDateRange: SelectedDateRange;
  // eslint-disable-next-line no-unused-vars
  setSelectedDateRange: (selectedDateRange: SelectedDateRange) => void;
  // eslint-disable-next-line no-unused-vars
  setSelectedDate: (selectedDate: SelectedDate) => void;
  scoercardDateRange: ScorecardDate[];
  currentDateCheckBox: boolean;
  setCurrentDateCheckBox: (currentDateCheckBox: boolean) => void;
  // eslint-disable-next-line react/require-default-props
  setVisiblity: (visibiltiy: boolean) => void;
  SourceData: SourceTypeProps;
  setSourceType: (selectedSourceType: SourceTypeProps) => void;
  year: string[];
}
function SourceTypeDetailed({
  selectedDate,
  onChangeDateField,
  selectedDateRange,
  setSelectedDateRange,
  setSelectedDate,
  scoercardDateRange,
  setCurrentDateCheckBox,
  currentDateCheckBox,
  setVisiblity,
  SourceData,
  setSourceType,
  year,
}: SourceTypeInputProps) {
  const [tableData, setTableData] = useState<SourceTypeTable>({
    currentYearData: [],
    lastYearData: [],
  });

  useEffect(() => setTableData(convertSourceTypeData(SourceData)), [SourceData]);
  const tableColumns = [
    {
      Header: "Source Type",
      accessor: "original_source_type",
      width: "200px",
      export: true,
      disableCheck: true,
      checked: true,
    },
    {
      Header: "Count",
      accessor: "count",
      width: "200px",
      export: true,
      disableCheck: true,
      checked: true,
    },
  ];
  return (
    <Card>
      <MDBox sx={{ padding: "15px" }}>
        <MDBox style={{ display: "flex", justifyContent: "space-between" }}>
          <ScorecardDateInput
            dateArr={scoercardDateRange}
            setCurrentDateCheckBox={setCurrentDateCheckBox}
            currentDateCheckBox={currentDateCheckBox}
            selectedDate={selectedDate}
            onChangeDateField={onChangeDateField}
            selectedDateRange={selectedDateRange}
            setSelectedDateRange={setSelectedDateRange}
            setSelectedDate={setSelectedDate}
          />
          <Button size="large" style={{ marginBottom: "50px" }} onClick={() => setVisiblity(false)}>
            Back
          </Button>
        </MDBox>
        <MDBox
          sx={{ marginTop: "40px", marginBottom: "40px" }}
          style={{ display: "flex", justifyContent: "center" }}
        >
          {SourceData ? (
            <SourceType
              selectedDateRange={selectedDateRange}
              currentDateCheckBox={currentDateCheckBox}
              setSelectedSourceType={setSourceType}
            />
          ) : (
            <MDBox mt={1} sx={{ minHeight: "200" }}>
              <Card
                variant="outlined"
                sx={{
                  height: "200px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Typography variant="h5">No record found for these values</Typography>
              </Card>
            </MDBox>
          )}
        </MDBox>
        <Card sx={{ marginBottom: "40px" }}>
          <MDBox
            bgColor="dark"
            color="white"
            coloredShadow="dark"
            borderRadius="xl"
            alignItems="center"
            justifyContent="space-between"
            pt={1}
            pb={1}
            pl={2}
            pr={2}
            ml={2}
            mr={2}
            display="flex"
            mt={-3}
            className="page-header"
          >
            <p className="page-header-label">Source Type for year: {year[0]}</p>
          </MDBox>
          {SourceData ? (
            <DataTable
              table={{
                columns: tableColumns,
                rows: tableData.currentYearData,
              }}
              manualSort={false}
            />
          ) : (
            <MDBox mt={1} sx={{ minHeight: "200" }}>
              <Card
                variant="outlined"
                sx={{
                  height: "200px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Typography variant="h5">No record found for these values</Typography>
              </Card>
            </MDBox>
          )}
        </Card>
        <MDBox
          bgColor="dark"
          color="white"
          coloredShadow="dark"
          borderRadius="xl"
          alignItems="center"
          justifyContent="space-between"
          pt={1}
          pb={1}
          pl={2}
          pr={2}
          ml={2}
          mr={2}
          display="flex"
          mt={-3}
          className="page-header"
        >
          <p className="page-header-label">Source Type for year: {year[1]}</p>
        </MDBox>
        {SourceData ? (
          <DataTable
            table={{
              columns: tableColumns,
              rows: tableData.lastYearData,
            }}
            manualSort={false}
          />
        ) : (
          <MDBox mt={1} sx={{ minHeight: "200" }}>
            <Card
              variant="outlined"
              sx={{
                height: "200px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Typography variant="h5">No record found for these values</Typography>
            </Card>
          </MDBox>
        )}
      </MDBox>
    </Card>
  );
}

export default SourceTypeDetailed;
