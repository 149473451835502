import MDBox from "components/MDBox";
import { Step, StepContent, StepLabel, Stepper } from "@mui/material";
import { useEffect, useState } from "react";
import SecurityDeposit from "./securityDeposit";

function PageThreeWizard(props: any): JSX.Element {
  const [activeStep, setActiveStep] = useState(0);
  const steps = ["Sequrity Deposit"];

  useEffect(() => {
    setActiveStep(activeStep);
  }, []);

  const getStepContent = (stepIndex: number) => {
    const attr = {
      activeStep,
    };
    switch (stepIndex) {
      case 0:
        return <SecurityDeposit {...attr} {...props} />;
      default:
        return null;
    }
  };

  return (
    <MDBox className="security-deposit-box" sx={{ maxWidth: "auto", height: "100%" }}>
      <Stepper
        activeStep={activeStep}
        orientation="vertical"
        sx={{ background: "#fff !important", p: 0, height: "90%" }}
      >
        {steps.map((label) => (
          <Step key={label}>
            <StepLabel className="applicant-step-label">{label}</StepLabel>
            <StepContent sx={{ borderLeft: "2px solid #9fc9ff", marginLeft: "6px" }}>
              {getStepContent(activeStep)}{" "}
            </StepContent>
          </Step>
        ))}
      </Stepper>
    </MDBox>
  );
}

export default PageThreeWizard;
