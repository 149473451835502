import { Grid } from "@mui/material";

import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDLabelValue from "components/MDLabelValue";

function Index(props: any): JSX.Element {
  console.log("props", props);

  return (
    <MDBox>
      <MDBox textAlign="center" mx="auto" my={4}>
        <MDBox mb={1}>
          <MDTypography variant="h5" fontWeight="regular">
            Lease Summary
          </MDTypography>
        </MDBox>
      </MDBox>
      <MDBox m={2}>
        <Grid container spacing={2}>
          <Grid item md={6}>
            <MDLabelValue label="Location" value="Location 1" />
          </Grid>
          <Grid item md={6}>
            <MDLabelValue label="Location Address" value="Address" />
          </Grid>
          <Grid item md={6}>
            <MDLabelValue label="Unit Number" value="Unit Number" />
          </Grid>
          <Grid item md={6}>
            <MDLabelValue label="Payment Type" value="Weekly" />
          </Grid>
          <Grid item md={6}>
            <MDLabelValue label="First Payment Due" value="-" />
          </Grid>
          <Grid item md={6}>
            <MDLabelValue label="Payment Amount" value="$xxx" />
          </Grid>
        </Grid>
      </MDBox>
    </MDBox>
  );
}

export default Index;
