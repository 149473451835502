import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "store/store";
import Grid from "@mui/material/Grid";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import Validation from "helper/validations";
import General from "layouts/pages/sola-pros/addUpdate/general";
import Contact from "layouts/pages/sola-pros/addUpdate/contact";
import Business from "layouts/pages/sola-pros/addUpdate/business";
import Website from "layouts/pages/sola-pros/addUpdate/website";
import Social from "layouts/pages/sola-pros/addUpdate/social";
import Images from "layouts/pages/sola-pros/addUpdate/images";
import Services from "layouts/pages/sola-pros/addUpdate/services";
import Testimonials from "layouts/pages/sola-pros/addUpdate/testimonials";
import LinkedAccounts from "layouts/pages/sola-pros/addUpdate/linkedaccounts";
import RentManager from "layouts/pages/sola-pros/addUpdate/rent-manager";
import Password from "layouts/pages/sola-pros/addUpdate/password";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Sidenav from "layouts/pages/sola-pros/sidenav/cms";
import CustomBackButton from "components/CustomBackButton";
import { saveSolaProfessional, getSolaProsDetail } from "store/thunk/solaProsThunk";
import { scrollToErrorByClass, systemAdmin } from "helper/services";
import { setSolaProDetails } from "store/slices/solaProSlice";

const inActiveReasonList = ["left", "accidental", "incorrect"];

function AddUpdate(props: any): JSX.Element {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const locationState: any = useLocation();
  const { solaProDetails } = useAppSelector((state: any) => state.solaProSlice);
  const { onClose, pageNo, perPage, search, sortBy, locationId } = props;
  const [errors, setErrors] = useState<any>({});
  const [solaProDetail, setSolaProDetail] = useState<any>({});
  const [isUpdate, setIsUpdate] = useState<any>(false);
  const [imagePreview, setImagePreview] = useState<any>([]);

  useEffect(() => {
    if (locationState?.state?.id) {
      dispatch(getSolaProsDetail({ id: locationState?.state?.id }));
      setIsUpdate(true);
    } else {
      setSolaProDetail({});
      setImagePreview([]);
      dispatch(setSolaProDetails({}));
    }
  }, [locationState]);

  useEffect(() => {
    if (solaProDetails) {
      setSolaProDetail(solaProDetails);
    }
  }, [solaProDetails]);

  const onCallBack = () => {
    dispatch(setSolaProDetails({}));
    navigate(`/sola-cms/cms-sola-pros`);
  };

  const onSave = () => {
    const tempSolaProDetails = JSON.parse(JSON.stringify(solaProDetail));
    const errors: any = Validation.validateSolaProfessionalsForm(tempSolaProDetails);
    setErrors(errors);
    if (inActiveReasonList.includes(tempSolaProDetails?.inactive_reason)) {
      tempSolaProDetails.inactive_reason = inActiveReasonList.indexOf(
        tempSolaProDetails?.inactive_reason
      );
    }
    if (!tempSolaProDetails?.barber) {
      tempSolaProDetails.barber = false;
    }
    if (!Object.keys(errors).length) {
      const req = {
        solaProDetail: tempSolaProDetails,
        onClose,
        isUpdate,
        pageNo,
        perPage,
        search,
        sortBy,
        locationId,
        callback: onCallBack,
      };
      dispatch(saveSolaProfessional(req));
    } else {
      scrollToErrorByClass(errors);
    }
  };

  const onChangeImage = async (files: any, key: any) => {
    if (files[0]) {
      const file = files[0];
      const reader = new FileReader();
      reader.onload = function (e) {
        const images: any = imagePreview;
        images[`image_${key}_url`] = e.target.result;
        setSolaProDetail({
          ...solaProDetail,
          [`image_${key}_url`]: e.target.result,
          [`image_${key}`]: file,
        });
        setImagePreview(images);
      };
      reader.readAsDataURL(file);
    }
  };

  const onImageRemove = async (e: any, key: any) => {
    e.stopPropagation();
    const deleteflag = `delete_image_${key}`;
    setSolaProDetail({
      ...solaProDetail,
      [`image_${key}_url`]: null,
      [`image_${key}`]: null,
      [deleteflag]: 1,
    });
    const images: any = imagePreview;

    images[`image_${key}_url`] = null;
    setImagePreview(images);
  };

  const onChangeAutoComplete = (name: string, value: any) => {
    setSolaProDetail({ ...solaProDetail, [name]: value });
  };

  const onChangeBiography = (value: any, delta: any, source: any) => {
    if (source === "user") {
      setSolaProDetail({ ...solaProDetail, biography: value });
    }
  };

  const onChangeField = (event: any) => {
    setSolaProDetail({ ...solaProDetail, [event.target.name]: event.target.value });
  };

  const onCheckboxChange = (event: any) => {
    setSolaProDetail({ ...solaProDetail, [event.target.name]: event.target.checked });
  };

  const onChangeMultipleField = (event: any, key: any) => {
    const data = solaProDetail[key] ? JSON.parse(JSON.stringify(solaProDetail[key])) : {};
    data[event.target.name] = event.target.value;
    setSolaProDetail({ ...solaProDetail, [key]: { ...data } });
  };

  const attr = {
    solaProDetail,
    imagePreview,
    isUpdate,
    errors,
    onChangeField,
    onChangeAutoComplete,
    onChangeMultipleField,
    onChangeBiography,
    onCheckboxChange,
    onChangeImage,
    setSolaProDetail,
    setImagePreview,
    onImageRemove,
  };
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox mx={2} onClick={onCallBack}>
        <CustomBackButton
          label="Back To Sola Pros"
          historyState={{
            prevPath: "/sola-cms/cms-sola-pros",
          }}
        />
      </MDBox>
      <Grid container spacing={3} mt={1}>
        <Grid item xs={12} lg={3}>
          <Sidenav />
        </Grid>
        <Grid item xs={12} lg={9}>
          <MDBox mb={3}>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <General {...attr} />
              </Grid>
              <Grid item xs={12}>
                <Contact {...attr} />
              </Grid>
              <Grid item xs={12}>
                <Business {...attr} />
              </Grid>
              <Grid item xs={12}>
                <Website {...attr} />
              </Grid>
              <Grid item xs={12}>
                <Social {...attr} />
              </Grid>
              <Grid item xs={12}>
                <Services {...attr} />
              </Grid>
              <Grid item xs={12}>
                <Images {...attr} />
              </Grid>
              <Grid item xs={12}>
                <Testimonials {...attr} />
              </Grid>
              {systemAdmin() && (
                <>
                  <Grid item xs={12}>
                    <RentManager {...attr} />
                  </Grid>
                  <Grid item xs={12}>
                    <Password {...attr} />
                  </Grid>
                </>
              )}
              {solaProDetail?.id && (
                <Grid item xs={12}>
                  <LinkedAccounts {...attr} />
                </Grid>
              )}
              <Grid item xs={12} textAlign="right">
                <MDButton
                  variant="gradient"
                  color="info"
                  size="small"
                  onClick={onSave}
                  className="fixed-button"
                >
                  {isUpdate ? "Update" : "Save"}
                </MDButton>
              </Grid>
            </Grid>
          </MDBox>
        </Grid>
      </Grid>
    </DashboardLayout>
  );
}

export default AddUpdate;
