import { useEffect, useRef, useState, useCallback } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "store/store";
import { Card, Icon, CardContent, Grid } from "@mui/material";
import MDBox from "components/MDBox";
import Messages from "helper/messages";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import DataTable from "examples/Tables/DataTable";
import MDIconButton from "components/MDIconButton";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import * as React from "react";
import MDTypography from "components/MDTypography";
import {
  deleteLeaseApplication,
  getAllLeaseApplications,
  getAllServices,
  getLeaseApplicationDetail,
  getLeaseDataById,
  saveAsDraftApplication,
  saveFinalApplication,
  updateLeaseStatus,
} from "store/thunk/leaseThunk";
import {
  capitalizeFirstLetter,
  getAccessPermission,
  adminSuperAdmin,
  getCRMCreateAccess,
  leaseApplicantValue,
} from "helper/services";
import MDDialog from "components/MDDialog";
import MDButton from "components/MDButton";
import HelloSign from "hellosign-embedded";
import config from "config/config";
import { getLocation } from "store/thunk/locationThunk";
// import axios from "axios";
import { getReceipentView, updateManagerSignStatus } from "store/thunk/docusignThunk";
import { setLeaseDetails, setleaseApplicationSteps } from "store/slices/leaseSlice";
import Review from "../deals/Review";
import SendLeaseApplicationPop from "../deals/startLease";
import SecurityDepositPopup from "./securityDepositPopup";
import PageOneWizard from "../page-one-wizard";
import PageTwoWizard from "../page-two-wizard";
import PageThreeWizard from "../page-three-wizard";
import PageFourWizard from "../page-four-wizard";
import UploadDocument from "../page-four-wizard/uploadDocument";
import PageFiveWizard from "../page-five-wizard";
import ReviewDraftPopup from "./reviewDraftPopup";

const client = new HelloSign({
  clientId: config.HELLOSIGN_CLIENT_ID,
});

function Index(): JSX.Element {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const fetchIdRef = useRef(0);
  const { state }: any = useLocation();
  const search = new URLSearchParams(window.location.search);
  // const name = search.get("name") || "";
  const email = search.get("email") || "";
  const envelopId = search.get("envelopId") || "";
  const leaseId = search.get("lease_id") || "";
  const role = search.get("role") || "";
  const event = search.get("event") || "";

  // const { services } = useAppSelector((state) => state.leaseSlice);
  const { leaseApplications, leaseDetails, leaseApplicationSteps } = useAppSelector(
    (state) => state.leaseSlice
  );
  const [applicationsList, setApplicationsList] = useState([]);
  const [tabValue, setTabValue] = useState(6);
  const [pageCount, setPageCount] = useState(0);
  const [pageNo, setPageNo] = useState(0);
  const [activeStatus, setActiveStatus] = useState("");
  const [leaseAppplicationId, setLeaseAppplicationId] = useState("");
  // const [perPage, setPageSize] = useState(10);
  const [totalCount, setTotalCount] = useState(0);
  const [isUpdate, setIsUpdate] = useState<boolean>(false);
  const [reviewPopup, setReviewPopup] = useState<boolean>(false);
  const [reviewDraftPopup, setReviewDraftPopup] = useState<boolean>(false);
  const [depositPopup, setDepositPopup] = useState<boolean>(false);
  const [leaseApplicationpopup, setLeaseApplicationPopup] = useState<boolean>(false);
  const [leasePopupData, setLeasePopupData] = useState<any>("");
  const [leaseData, setLeaseData] = useState<any>(null);
  const [openAddDepositPopup, setAddOpenDepositPopup] = useState(false);
  const [disabledDraftBtn, setDisabledDraftBtn] = useState(true);
  const [saveDraftData, setSaveDraftData] = useState<any>({});
  const [removeDocs, setRemoveDocs] = useState<any>([]);
  // Lease realted Popup
  const [leasePopup, setLeasePopup] = useState<boolean>(false);
  const [leaseStatuses, setLeaseStatuses] = useState<any>([
    { name: "not_sent", count: 0 },
    { name: "application_sent", count: 0 },
    { name: "need_review", count: 0 },
    { name: "signed", count: 0 },
    { name: "security_deposit_received", count: 0 },
    { name: "draft", count: 0 },
  ]);
  const [deleteLeaseId, setDeleteLeaseId] = useState<any>(null);
  const [saveButtonOnReview, setSaveButtonOnReview] = useState<any>(true);
  const [airLeaseId] = useState<any>((state && state?.lease_id) || null);
  // wizard discard
  const [discardLeasePop, setDiscardLeasePop] = useState<boolean>(false);
  const [childLeaseData, setChildLeaseData] = useState<any>(null);
  const [childMembers, setChildMembers] = useState<any>([]);
  const [selectedStudios, setSelectedStudios] = useState<any>([]);
  const [selectedItemId, setSelectedItemId] = useState<string | null>(null);
  const [rmChangeDetials, setRmChangeDetials] = useState<any>({});
  const [subletApplicantError, setSubletApplicantError] = useState<boolean>(false);

  const fetchData = useCallback(({ pageSize, pageIndex, search, sortBy }) => {
    const fetchId = ++fetchIdRef.current;
    const sort: any = sortBy || [];
    // We'll even set a delay to simulate a server here
    setTimeout(() => {
      if (fetchId === fetchIdRef.current) {
        setPageNo(pageIndex);
        // setPageSize(pageSize);
        dispatch(getAllLeaseApplications({ pageSize, pageIndex, search, sort, activeStatus }));
      }
    }, 1000);
  }, []);

  useEffect(() => {
    fetchData({ pageSize: 10, pageIndex: 0 });
    if (email && leaseId && envelopId && event === "signing_complete" && role === "manager") {
      // update manager sign status
      dispatch(updateManagerSignStatus({ email, envelopId, leaseId, navigate }));
    }
    dispatch(
      setleaseApplicationSteps({
        ...leaseApplicationSteps,
        page: 1,
        selectedType: leaseApplicantValue.ADDFROMDEAL,
      })
    );
  }, []);

  useEffect(() => {
    if (leaseApplications) {
      setPageCount(leaseApplications?.meta?.total_pages);
      setTotalCount(leaseApplications?.meta?.total_count);
      setApplicationsList(leaseApplications?.connect_lease_application);
      if (activeStatus === "") {
        if (leaseApplications?.statusWiseCount && leaseApplications?.statusWiseCount.length > 0) {
          leaseStatuses?.map((status: any, key: number) => {
            const count = leaseApplications?.statusWiseCount.filter(
              (obj: any) => obj.lease_application_status === status.name
            );
            if (count.length) {
              leaseStatuses[key].count = count[0].count;
            }
            return leaseStatuses;
          });
          setLeaseStatuses(leaseStatuses);
        }
      }
    }
  }, [leaseApplications]);

  useEffect(() => {
    if (state && state?.lease_id) {
      dispatch(getLeaseApplicationDetail({ leaseId: state.lease_id }));
    }
  }, [airLeaseId, applicationsList]);

  useEffect(() => {
    if (leaseDetails) {
      setLeaseData(leaseDetails);
      if (leaseDetails.lease_application_status !== "security_deposit_received") {
        dispatch(
          updateLeaseStatus({ lease_id: state.lease_id, lease_application_status: "signed" })
        );
        setReviewPopup(false);
        setDepositPopup(true);
      }
    }
    dispatch(getAllServices(""));
  }, [leaseDetails]);

  useEffect(() => {
    if (leaseApplicationSteps?.applicants && leaseApplicationSteps?.applicants?.length) {
      setDisabledDraftBtn(false);
    }
  }, [leaseApplicationSteps?.applicants]);

  const openDraftView = (leaseId: any) => {
    setReviewDraftPopup(true);
    dispatch(getLeaseDataById({ id: leaseId }));
  };
  const actionButtons = (obj: any) => (
    <>
      {getAccessPermission(
        obj?.location_id,
        "crm",
        "crm_lease_application",
        "",
        "view_access",
        obj?.owners
      ) &&
        (obj.lease_application_status === "draft" ? (
          <>
            <MDIconButton
              tooltipName="View Lease details"
              aria-label="Open"
              color="info"
              onClick={() => openDraftView(obj?.id)}
            >
              <Icon fontSize="small">arrow_forward_icon</Icon>
            </MDIconButton>
            <MDIconButton
              tooltipName="Edit Draft"
              aria-label="Open"
              color="info"
              onClick={() => {
                if (obj.lease_application_status === "draft") {
                  setLeasePopup(true);
                  setIsUpdate(true);
                  setLeaseAppplicationId(obj?.id);
                  dispatch(getLeaseDataById({ id: obj?.id }));
                }
              }}
            >
              <Icon fontSize="small">edit</Icon>
            </MDIconButton>
          </>
        ) : (
          <MDIconButton
            tooltipName="View Lease details"
            aria-label="Open"
            color="info"
            onClick={() => {
              if (
                obj.lease_application_status === "need_review" ||
                obj.lease_application_status === "application_sent"
              ) {
                setSaveButtonOnReview(
                  getAccessPermission(
                    obj?.location_id,
                    "crm",
                    "crm_lease_application",
                    "",
                    "edit_access"
                  ) || false
                );
                setReviewPopup(true);
                setLeaseData(obj);
                setLeasePopupData(obj);
                setIsUpdate(true);
              }
              if (obj.lease_application_status === "not_sent") {
                setLeaseApplicationPopup(true);
                setLeasePopupData(obj);
                setIsUpdate(true);
              }
              if (obj.lease_application_status === "signed") {
                setAddOpenDepositPopup(true);
                setLeaseData(obj);
                dispatch(getLocation({ id: obj?.location_id, cmsLocation: true }));
              }
            }}
          >
            <Icon fontSize="small">arrow_forward_icon</Icon>
          </MDIconButton>
        ))}
      {getAccessPermission(
        obj?.location_id,
        "crm",
        "crm_lease_application",
        "",
        "edit_access",
        obj?.owners
      ) && (
        <MDIconButton
          tooltipName="Delete"
          aria-label="Delete"
          color="error"
          onClick={() => setDeleteLeaseId(obj?.id)}
        >
          <Icon fontSize="small">delete</Icon>
        </MDIconButton>
      )}
    </>
  );

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setActiveStatus(leaseStatuses[newValue]?.name);
    setTabValue(newValue);
    dispatch(getAllLeaseApplications({ activeStatus: leaseStatuses[newValue]?.name }));
  };

  const getRows = () => {
    const data: any = applicationsList?.map((obj: any) => ({
      location: obj.location_name ? obj.location_name : "-",
      lease_status: obj.lease_application_status
        ? capitalizeFirstLetter(obj?.lease_application_status?.replace(/_/g, " "))
        : "-",
      first_name: obj.first_name ? obj.first_name : "-",
      last_name: obj.last_name ? obj.last_name : "-",
      phone: obj.phone ? obj.phone : "-",
      email: obj.email ? obj.email : "-",
      action: actionButtons(obj),
    }));
    return data;
  };

  const tableData = {
    columns: [
      { Header: "Location", accessor: "location", width: "15%", export: true },
      { Header: "Lease Status", accessor: "lease_status", export: true },
      { Header: "First Name", accessor: "first_name", export: true },
      { Header: "Last Name", accessor: "last_name", export: true },
      { Header: "Phone", accessor: "phone", export: true },
      { Header: "Email", accessor: "email", export: true },
      { Header: "Action", accessor: "action", export: false, disableSortBy: true },
    ],
    rows: getRows(),
  };

  client.on("sign", async () => {
    if (leaseData) {
      dispatch(updateLeaseStatus({ lease_id: leaseData.id, lease_application_status: "signed" }));
    }
    client.close();
    setReviewPopup(false);
    setDepositPopup(true);
  });

  const onReview = async () => {
    if (leaseData?.assign_users) {
      dispatch(
        getReceipentView({
          email: leaseData?.assign_users?.login_email,
          envelopId: leaseData?.applicants_document?.[0]?.envelop_id,
          role: "manager",
        })
      );
    }
    /* axios
      .get(
        `${config.HELLOSIGN_URL}get-embedded-url-dc?signature_id=${leaseData.signature_id}&email=${leaseData.email}&lease_id=${leaseData.id}&role=manager`
      )
      .then((result) => {
        window.open(result.data.data.redirectUrl, "_self");
        // client.open(result.data.data.sign_url, {
        //   testMode: true,
        //   skipDomainVerification: true,
        // });
      })
      .catch((error) => {
        console.log("error ", error);
      }); */
  };

  const onEdit = () => {
    setReviewPopup(false);
    // setLeaseApplicationPopup(true);
    setLeasePopup(true);
    setIsUpdate(true);
    setLeaseAppplicationId(leaseData?.id);
    dispatch(getLeaseDataById({ id: leaseData?.id }));
  };
  const onDeposit = () => {
    setDepositPopup(false);
    setReviewPopup(false);
    dispatch(
      updateLeaseStatus({
        lease_id: leaseData?.id || state?.lease_id,
        lease_application_status: "signed",
      })
    );
  };

  const onAlreadyDeposit = () => {
    setDepositPopup(false);
    setReviewPopup(false);
    dispatch(
      updateLeaseStatus({
        lease_id: leaseData?.id || state?.lease_id,
        lease_application_status: "security_deposit_received",
      })
    );
  };

  const closeLeaseApplicationPopup = () => {
    setLeaseApplicationPopup(false);
    dispatch(setLeaseDetails(null));
  };

  const toggleDepositPopup = () => {
    setAddOpenDepositPopup(!openAddDepositPopup);
  };

  const onCollectDeposit = () => {
    dispatch(
      updateLeaseStatus({
        setAddOpenDepositPopup,
        lease_id: leaseData.id,
        lease_application_status: "security_deposit_received",
        activeStatus,
      })
    );
  };

  const onDeleteLease = () => {
    if (deleteLeaseId) {
      dispatch(
        deleteLeaseApplication({
          leaseId: deleteLeaseId,
          activeStatus,
          setDeleteLeaseId,
        })
      );
    }
  };
  // Main Lease popup Save Draft
  const onAdditionalLeaseBtnSave = () => {
    dispatch(
      saveAsDraftApplication({
        leaseApplicationSteps,
        setLeasePopup,
        leaseAppplicationId,
        isUpdate,
        saveDraftData,
        removeDocs,
        childLeaseData,
        childMembers,
        rmChangeDetials,
        activeStatus,
      })
    );
    dispatch(setleaseApplicationSteps({ page: 1, selectedType: leaseApplicantValue.ADDFROMDEAL }));
    setIsUpdate(false);
  };

  // save draft function
  const onSaveDraft = () => {
    setDiscardLeasePop(false);
    onAdditionalLeaseBtnSave();
  };
  const onCloseDiscard = () => {
    setRemoveDocs([]);
    setIsUpdate(false);
    setDiscardLeasePop(false);
    setLeasePopup(false);
    setDisabledDraftBtn(true);
    setChildLeaseData(null);
    setSelectedStudios([]);
    setSelectedItemId(null);
    setSubletApplicantError(false);
    setChildMembers([]);
    dispatch(setleaseApplicationSteps({ page: 1, selectedType: leaseApplicantValue.ADDFROMDEAL }));
  };

  // Final Send Lease APplication
  const onSendLeaseApplication = () => {
    // dispatch(getAllLeaseApplications({ pageSize, pageIndex, search, sort, activeStatus }));

    dispatch(
      saveFinalApplication({
        leaseApplicationSteps,
        setLeasePopup,
        leaseAppplicationId,
        isUpdate,
        saveDraftData,
        removeDocs,
        childLeaseData,
        childMembers,
        rmChangeDetials,
        activeStatus,
      })
    );
    dispatch(setleaseApplicationSteps({ page: 1, selectedType: leaseApplicantValue.ADDFROMDEAL }));
    setIsUpdate(false);
  };
  // lease application pop up --- old
  const dialogProps = {
    open: reviewPopup,
    onSave: () =>
      leaseData?.lease_application_status === "application_sent" ? onEdit() : onReview(),
    onClose: () => setReviewPopup(false),
    title: leaseData?.lease_application_status === "application_sent" ? "Review" : "Review & Sign",
    size: "md",
    saveTbtText:
      leaseData?.lease_application_status === "application_sent" ? "Edit" : "Sign & Continue",
    closeBtnText: "Cancel",
    saveBtn: saveButtonOnReview || false,
  };

  const closeDraftPopup = () => {
    setReviewDraftPopup(false);
    dispatch(setleaseApplicationSteps({ page: 1, selectedType: leaseApplicantValue.ADDFROMDEAL }));
  };

  // Dailoaf gor DraftView
  const dialogDraftViewProps = {
    open: reviewDraftPopup,
    onClose: closeDraftPopup,
    title: "Review Draft",
    size: "md",
    closeBtnText: "Cancel",
    saveBtn: false,
  };

  // deposit pop up
  const dialogPropsDeposit = {
    open: depositPopup,
    onSave: () => onDeposit(),
    onClose: () => setDepositPopup(false),
    title: "Security Deposit Confirmation",
    size: "md",
    saveTbtText: "I Understand, Charge Security Deposit",
    additionalBtn: true,
    additionalBtnText: "They Have Already Paid",
    onAdditionalBtnSave: () => onAlreadyDeposit(),
    closeBtnText: "Cancel",
  };

  // delete lease application
  const deleteDialogProps = {
    open: deleteLeaseId,
    onClose: () => setDeleteLeaseId(false),
    onSave: () => onDeleteLease(),
    title: "Delete Lease",
    size: "sm",
    saveBtn: false,
    deleteBtn: true,
    saveTbtText: "Yes",
    closeBtnText: "No",
  };

  // lease application pop up --- new(Wizard)
  const leaseDialogProps = {
    open: leasePopup,
    onClose: () => setDiscardLeasePop(true),
    title: "Create Lease Application",
    saveBtn:
      leaseApplicationSteps?.page === 6 &&
      (leaseApplicationSteps?.upload_doc_status === true ||
        leaseApplicationSteps?.security_deposit === false ||
        leaseApplicationSteps?.applicants[0]?.deposit_status === "Paid")
        ? true
        : false,
    onSave: () => onSendLeaseApplication(),
    saveTbtText: activeStatus === "application_sent" ? "Update" : "Send",
    additionalBtn: true,
    additionalBtnText: isUpdate ? "Update Draft" : "Save As Draft",
    additionalBtnColor: "info",
    onAdditionalBtnSave: () => onAdditionalLeaseBtnSave(),
    size: "xxl",
    additionalBtnDisabled:
      activeStatus === "application_sent" ||
      subletApplicantError ||
      disabledDraftBtn ||
      // leaseApplicationSteps?.page === 6 ||
      (leaseApplicationSteps?.page === 2 && !selectedItemId),
  };

  // close lease application pop up
  const closeLeaseDialogProps = {
    deleteBtn: false,
    open: discardLeasePop,
    onClose: () => {
      setDiscardLeasePop(false);
    },
    title: "Discard Lease Application ?",
    size: "sm",
    saveBtn: true,
    onSave: () => onSaveDraft(),
    saveTbtText: "Save As Draft",
    additionalBtn: true,
    additionalBtnText: "Discard",
    additionalBtnColor: "error",
    onAdditionalBtnSave: () => {
      onCloseDiscard();
    },
  };

  // console.log("subletApplicantError main file", subletApplicantError);
  return (
    <DashboardLayout>
      <SecurityDepositPopup
        leaseData={leaseData}
        open={openAddDepositPopup}
        onClose={toggleDepositPopup}
        onCollectDeposit={onCollectDeposit}
      />
      <DashboardNavbar />
      <MDBox
        bgColor="dark"
        color="white"
        coloredShadow="dark"
        borderRadius="xl"
        alignItems="center"
        justifyContent="space-between"
        pt={1}
        pb={1}
        pl={2}
        pr={2}
        ml={2}
        mr={2}
        display="flex"
        mt={3}
      >
        Overview
      </MDBox>
      <MDBox
        borderRadius="xl"
        alignItems="center"
        pt={2}
        pb={2}
        pl={2}
        pr={2}
        ml={{ xs: 0, sm: 0, md: 2, lg: 2 }}
        mr={{ xs: 0, sm: 0, md: 2, lg: 2 }}
        mt={{ xs: 0, sm: 0, md: 2, lg: 2 }}
        mb={{ xs: 2, sm: 2, md: 6, lg: 6 }}
      >
        <Grid
          container
          rowSpacing={2}
          columnSpacing={{ xs: 1, sm: 2, md: 3 }}
          justifyContent="center"
          alignItems={{ xs: "unset", sm: "unset", md: "center", lg: "center" }}
        >
          <Grid item xs={4}>
            <Card
              variant="outlined"
              sx={{ width: "100%" }}
              className={
                activeStatus === "application_sent" ? "active-lease-box lease-box" : "lease-box"
              }
            >
              <CardContent onClick={(event: any) => handleChange(event, 1)} className="inner-box">
                <MDTypography sx={{ display: "block" }} variant="subtitle" align="center">
                  Waiting On Applicant
                </MDTypography>
                <MDTypography variant="h4" align="center">
                  {leaseStatuses[1].count}
                </MDTypography>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={4}>
            <Card
              variant="outlined"
              sx={{ width: "100%" }}
              className={
                activeStatus === "need_review lease-box"
                  ? "active-lease-box lease-box"
                  : "lease-box"
              }
            >
              <CardContent onClick={(event: any) => handleChange(event, 2)} className="inner-box">
                <MDTypography sx={{ display: "block" }} variant="subtitle" align="center">
                  Needs Reviewed
                </MDTypography>
                <MDTypography variant="h4" align="center">
                  {leaseStatuses[2].count}
                </MDTypography>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={4}>
            <Card
              variant="outlined"
              sx={{ width: "100%" }}
              className={activeStatus === "signed" ? "active-lease-box lease-box" : "lease-box"}
            >
              <CardContent onClick={(event: any) => handleChange(event, 3)} className="inner-box">
                <MDTypography sx={{ display: "block" }} variant="subtitle" align="center">
                  Signed
                </MDTypography>
                <MDTypography variant="h4" align="center">
                  {leaseStatuses[3].count}
                </MDTypography>
              </CardContent>
            </Card>
          </Grid>
          {/* <Grid item xs={4}>
              <Card variant="outlined" sx={{ width: "100%" }}>
                <CardContent>
                  <MDTypography sx={{ display: "block" }} variant="subtitle" align="center">
                    Vacant Units
                  </MDTypography>
                  <MDTypography variant="h4" align="center">
                    {leaseStatuses[3].count}
                  </MDTypography>
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={4}>
              <Card variant="outlined" sx={{ width: "100%" }}>
                <CardContent>
                  <MDTypography variant="subtitle" sx={{ display: "block" }} align="center">
                    Upcoming Vacancies
                  </MDTypography>
                  <MDTypography variant="h4" align="center">
                    0
                  </MDTypography>
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={4}>
              <Card variant="outlined" sx={{ width: "100%" }}>
                <CardContent>
                  <MDTypography sx={{ display: "block" }} variant="subtitle" align="center">
                    Conneted Deals in Pipelines
                  </MDTypography>
                  <MDTypography variant="h4" align="center">
                    0
                  </MDTypography>
                </CardContent>
              </Card>
            </Grid> */}
        </Grid>
      </MDBox>
      <MDBox pt={4} pb={3}>
        <Card>
          <MDBox
            bgColor="dark"
            color="white"
            coloredShadow="dark"
            borderRadius="xl"
            alignItems="center"
            justifyContent="space-between"
            pt={1}
            pb={1}
            pl={2}
            pr={2}
            ml={2}
            mr={2}
            display="flex"
            mt={-3}
            className="page-header"
          >
            <p className="page-header-label">Application</p>
            {(adminSuperAdmin() ||
              getCRMCreateAccess("add_access", "crm", "crm_lease_application")?.locations > 0) && (
              <>
                {/* <MDButton
                    variant="gradient"
                    color="light"
                    size="small"
                    className="xs-small"
                    onClick={() => {
                      setLeaseApplicationPopup(true);
                      setIsUpdate(false);
                    }}
                  >
                    <Icon sx={{ fontWeight: "bold" }}>add</Icon> Add New
                  </MDButton> */}
                <MDButton
                  variant="gradient"
                  color="light"
                  size="small"
                  className="xs-small"
                  onClick={() => {
                    setLeasePopup(true);
                    setIsUpdate(false);
                  }}
                >
                  <Icon sx={{ fontWeight: "bold" }}>add</Icon> Add New
                </MDButton>
              </>
            )}
          </MDBox>
          <MDBox
            borderRadius="xl"
            alignItems="center"
            pt={1}
            pb={1}
            pl={2}
            pr={2}
            ml={2}
            mr={2}
            mt={2}
          >
            <Tabs
              className="location-tabs"
              indicatorColor="secondary"
              classes={{ indicator: "info" }}
              value={tabValue}
              onChange={handleChange}
            >
              <Tab
                label={
                  <MDBox py={0.5} px={2} color="inherit">
                    Not Sent
                  </MDBox>
                }
              />
              <Tab
                label={
                  <MDBox py={0.5} px={2} color="inherit">
                    Application Sent
                  </MDBox>
                }
              />
              <Tab
                label={
                  <MDBox py={0.5} px={2} color="inherit">
                    Need Review
                  </MDBox>
                }
              />
              <Tab
                label={
                  <MDBox py={0.5} px={2} color="inherit">
                    Signed
                  </MDBox>
                }
              />
              <Tab
                label={
                  <MDBox py={0.5} px={2} color="inherit">
                    Security Deposit Received
                  </MDBox>
                }
              />
              <Tab
                label={
                  <MDBox py={0.5} px={2} color="inherit">
                    Draft
                  </MDBox>
                }
              />
              <Tab
                label={
                  <MDBox py={0.5} px={2} color="inherit">
                    View All
                  </MDBox>
                }
              />
            </Tabs>
          </MDBox>
          <DataTable
            table={tableData}
            canSearch
            searchText="Search Lease..."
            pageCount={pageCount}
            pageNo={pageNo}
            totalCount={totalCount}
            fetchData={fetchData}
            manualSearch
            manualSort
          />
          {leasePopup && (
            <MDDialog {...leaseDialogProps} overflowVisible="lease-application-dailog">
              {leaseApplicationSteps?.page === 1 && (
                <PageOneWizard currentPage={1} setSubletApplicantError={setSubletApplicantError} />
              )}
              {leaseApplicationSteps?.page === 2 && (
                <PageTwoWizard
                  saveDraftData={saveDraftData}
                  setSaveDraftData={setSaveDraftData}
                  selectedStudios={selectedStudios}
                  setSelectedStudios={setSelectedStudios}
                  selectedItemId={selectedItemId}
                  setSelectedItemId={setSelectedItemId}
                  setSubletApplicantError={setSubletApplicantError}
                />
              )}
              {leaseApplicationSteps?.page === 3 && (
                <PageThreeWizard
                  saveDraftData={saveDraftData}
                  setSaveDraftData={setSaveDraftData}
                  childMembers={childMembers}
                  setChildMembers={setChildMembers}
                  rmChangeDetials={rmChangeDetials}
                  setRmChangeDetials={setRmChangeDetials}
                  isUpdateDeposite={isUpdate}
                  setSubletApplicantError={setSubletApplicantError}
                />
              )}
              {leaseApplicationSteps?.page === 4 && (
                <PageFourWizard
                  saveDraftData={saveDraftData}
                  setSaveDraftData={setSaveDraftData}
                  childLeaseData={childLeaseData}
                  setChildLeaseData={setChildLeaseData}
                  setSubletApplicantError={setSubletApplicantError}
                />
              )}
              {leaseApplicationSteps?.page === 5 && (
                <UploadDocument
                  saveDraftData={saveDraftData}
                  setSaveDraftData={setSaveDraftData}
                  removeDocs={removeDocs}
                  setRemoveDocs={setRemoveDocs}
                  childLeaseData={childLeaseData}
                  setChildLeaseData={setChildLeaseData}
                  isUpdateDoc={isUpdate}
                  setSubletApplicantError={setSubletApplicantError}
                />
              )}
              {leaseApplicationSteps?.page === 6 && <PageFiveWizard />}
            </MDDialog>
          )}
          {leaseApplicationpopup && (
            <SendLeaseApplicationPop
              open={leaseApplicationpopup}
              onClose={() => closeLeaseApplicationPopup()}
              isUpdate={isUpdate}
              activeStatus={activeStatus}
              leasePopupData={leasePopupData}
            />
          )}
        </Card>
      </MDBox>
      <MDDialog {...dialogDraftViewProps}>
        {reviewDraftPopup && <ReviewDraftPopup leaseData={leaseApplicationSteps} />}
      </MDDialog>
      <MDDialog {...dialogProps}>{reviewPopup && <Review leaseData={leaseData} />}</MDDialog>
      <MDDialog {...dialogPropsDeposit}>
        {depositPopup && (
          <MDBox>
            This Sola Pro will be charged a security deposit of $250. Once the security deposit is
            withdrawn, they will be an official Sola Pro and their web page will go live.
          </MDBox>
        )}
      </MDDialog>
      {deleteLeaseId && (
        <MDDialog {...deleteDialogProps}>
          <MDTypography variant="h6" fontWeight="medium">
            {Messages.GENERAL.SURE_TO_DELETE}
          </MDTypography>
        </MDDialog>
      )}
      {discardLeasePop && (
        <MDDialog {...closeLeaseDialogProps}>
          <MDTypography variant="h6" fontWeight="medium">
            {Messages.GENERAL.SURE_TO_DISCARD_LEASE}
          </MDTypography>
        </MDDialog>
      )}
    </DashboardLayout>
  );
}

export default Index;
