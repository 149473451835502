import MDDialog from "components/MDDialog";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
// import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { getRMContactDetailById } from "store/thunk/repairMaintenance";
import { Grid } from "@mui/material";
import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "store/store";
import { displayMultipleValue, removePlusOneInPhoneNumber } from "helper/services";
import { setRMContactDetail } from "store/slices/repairMaintenanceSlice";

function ViewDetails(props: any): JSX.Element {
  const dispatch = useAppDispatch();
  const { rmContactDetail } = useAppSelector((state: any) => state.repairMaintenanceSlice);
  const { open, onClose, viewContactId } = props;
  const [loading] = useState<boolean>(false);
  const [contactData, setContactData] = useState<any>({});

  useEffect(() => {
    if (viewContactId) {
      dispatch(getRMContactDetailById({ id: viewContactId }));
    }
  }, []);

  const onClosePopup = () => {
    onClose();
    dispatch(setRMContactDetail(null));
  };

  useEffect(() => {
    if (rmContactDetail && Object.keys(rmContactDetail).length) {
      setContactData({
        id: rmContactDetail?.id || null,
        f_name: rmContactDetail?.f_name || "",
        l_name: rmContactDetail?.l_name || "",
        m_name: rmContactDetail?.m_name || "",
        email: rmContactDetail?.email || "",
        phone: removePlusOneInPhoneNumber(rmContactDetail.phone || ""),
        title: rmContactDetail?.title || "",
        description: rmContactDetail?.description || "",
        address_1: rmContactDetail?.address_1 || "",
        address_2: rmContactDetail?.address_2 || "",
        state: rmContactDetail?.state || "",
        city: rmContactDetail?.city || "",
        postal_code: rmContactDetail?.postal_code || "",
        locations: rmContactDetail?.locations || "",
      });
    }
  }, [rmContactDetail]);

  const dialogProps = {
    open,
    onClose: onClosePopup(),
    saveBtn: false,
    title: "View Detail",
    size: "md",
    loading,
  };

  return (
    <MDDialog {...dialogProps}>
      <MDBox pb={2}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12} md={12}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6} md={6}>
                <MDTypography fontWeight="bold" variant="button">
                  First name
                </MDTypography>
                <MDBox>
                  <MDTypography variant="button">{contactData?.f_name || "-"}</MDTypography>
                </MDBox>
              </Grid>
              <Grid item xs={12} sm={6} md={6}>
                <MDTypography fontWeight="bold" variant="button">
                  Last name
                </MDTypography>
                <MDBox>
                  <MDTypography variant="button">{contactData?.l_name || "-"}</MDTypography>
                </MDBox>
              </Grid>
              <Grid item xs={12} sm={6} md={6}>
                <MDTypography fontWeight="bold" variant="button">
                  Middle name
                </MDTypography>
                <MDBox>
                  <MDTypography variant="button">{contactData?.m_name || "-"}</MDTypography>
                </MDBox>
              </Grid>
              <Grid item xs={12} sm={6} md={6}>
                <MDTypography fontWeight="bold" variant="button">
                  Title
                </MDTypography>
                <MDBox>
                  <MDTypography variant="button">{contactData?.title || "-"}</MDTypography>
                </MDBox>
              </Grid>
              <Grid item xs={12} sm={6} md={6}>
                <MDTypography fontWeight="bold" variant="button">
                  Description
                </MDTypography>
                <MDBox>
                  <MDTypography variant="button">{contactData?.description || "-"}</MDTypography>
                </MDBox>
              </Grid>
              <Grid item xs={12} sm={6} md={6}>
                <MDTypography fontWeight="bold" variant="button">
                  Address 1
                </MDTypography>
                <MDBox>
                  <MDTypography variant="button">{contactData?.address_1 || "-"}</MDTypography>
                </MDBox>
              </Grid>
              <Grid item xs={12} sm={6} md={6}>
                <MDTypography fontWeight="bold" variant="button">
                  Address 2
                </MDTypography>
                <MDBox>
                  <MDTypography variant="button">{contactData?.address_2 || "-"}</MDTypography>
                </MDBox>
              </Grid>
              <Grid item xs={12} sm={6} md={6}>
                <MDTypography fontWeight="bold" variant="button">
                  City
                </MDTypography>
                <MDBox>
                  <MDTypography variant="button">{contactData?.city || "-"}</MDTypography>
                </MDBox>
              </Grid>
              <Grid item xs={12} sm={6} md={6}>
                <MDTypography fontWeight="bold" variant="button">
                  State
                </MDTypography>
                <MDBox>
                  <MDTypography variant="button">{contactData?.state || "-"}</MDTypography>
                </MDBox>
              </Grid>
              <Grid item xs={12} sm={6} md={6}>
                <MDTypography fontWeight="bold" variant="button">
                  Postal code
                </MDTypography>
                <MDBox>
                  <MDTypography variant="button">{contactData?.postal_code || "-"}</MDTypography>
                </MDBox>
              </Grid>

              <Grid item xs={12} sm={6} md={6}>
                <MDTypography fontWeight="bold" variant="button">
                  Locations
                </MDTypography>
                <MDBox>
                  <MDTypography variant="button">
                    {displayMultipleValue(contactData.locations)}
                  </MDTypography>
                </MDBox>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </MDBox>
    </MDDialog>
  );
}

export default ViewDetails;
