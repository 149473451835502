import { Grid, CardMedia } from "@mui/material";

import MDBox from "components/MDBox";
import MDInput from "components/MDInput";
import MDTypography from "components/MDTypography";

function Index(props: any): JSX.Element {
  const { leaseDetails, onChangeField, errors } = props;

  return (
    <MDBox>
      <MDBox textAlign="center" mx="auto" my={2}>
        <MDBox mb={1}>
          <MDTypography variant="h5" fontWeight="regular">
            Lease Agreement
          </MDTypography>
        </MDBox>
      </MDBox>
      <MDBox>
        <Grid container spacing={2}>
          <CardMedia
            component="iframe"
            src="https://images.template.net/wp-content/uploads/2015/12/29130015/Sample-Contract-Agreement-Template-PDF.pdf"
            sx={{ width: "100%", height: "300px" }}
          />
        </Grid>
      </MDBox>
      <MDBox mt={3}>
        <MDInput
          label="Digital Signature"
          variant="standard"
          name="digital_signature"
          placeholder="Digital Signature"
          onChange={onChangeField}
          value={leaseDetails?.digital_signature || ""}
          error={errors && errors.digital_signature ? true : false}
          helperText={errors && errors.digital_signature}
          required
        />
      </MDBox>
    </MDBox>
  );
}

export default Index;
