/* eslint-disable no-unused-vars */
import MDBox from "components/MDBox";
import { Grid } from "@mui/material";
import { useAppDispatch } from "store/store";
import { forwardRef, useImperativeHandle, useState } from "react";
import MDButton from "components/MDButton";
import Validations from "helper/validations";
import { notificationFail } from "store/slices/notificationSlice";
import { updateLocation, updateLocationImageOrder } from "store/thunk/locationThunk";
import Sidenav from "layouts/pages/locations/location-details/website/sidenav";
import BasicInfo from "layouts/pages/locations/location-details/website/basicInfo";
import ContactInfo from "layouts/pages/locations/location-details/website/contactInfo";
import Images from "layouts/pages/locations/location-details/website/images";
import SocialLinks from "layouts/pages/locations/location-details/website/social-links";
import Integrations from "layouts/pages/locations/location-details/website/integrations";
import Settings from "layouts/pages/locations/location-details/website/settings";
import { scrollToErrorByClass, systemAdmin } from "helper/services";
import constants from "helper/constants";
import Amenities from "layouts/pages/locations/location-details/general/amenities";
import { WebsiteAttr } from "layouts/pages/locations/location-details";
import Blogs from "./blogs";

const Index = forwardRef((props: WebsiteAttr, ref: any) => {
  const { getAccessTypePermission } = props;
  const dispatch = useAppDispatch();
  const {
    locationDetail,
    oldLocationDetail,
    imagesArray,
    updateImageOrder,
    setUpdateImageOrder,
    setNewImages,
    newImages,
  } = props;

  const [errors, setErrors] = useState<any>({});
  const onSave = () => {
    const locationMoveInSpecial = locationDetail.move_in_special || "";
    const newValueTrimmed = locationMoveInSpecial
      .replace(/<p>|<\/p>/g, "")
      .replace(/^\s+|\s+$/g, "");
    const newValueWithPTags = !newValueTrimmed ? "<p><br></p>" : `<p>${newValueTrimmed}</p>`;
    const updatedLocationDetails = { ...locationDetail, move_in_special: newValueWithPTags };
    let newValue: any = {
      display_general_contact_info: oldLocationDetail?.display_general_contact_info || false,
      directory_update_required: oldLocationDetail?.directory_update_required || false,
      display_contact_form_on_website: oldLocationDetail?.display_contact_form_on_website || true, // SD-3991
    };
    Object.keys(updatedLocationDetails).forEach((key: any) => {
      if (updatedLocationDetails[key] !== oldLocationDetail[key]) {
        if (typeof updatedLocationDetails[key] === "object") {
          if (
            JSON.stringify(updatedLocationDetails[key]) !== JSON.stringify(oldLocationDetail[key])
          ) {
            newValue[key] = updatedLocationDetails[key];
          }
        } else newValue[key] = updatedLocationDetails[key];
      }
    });

    const error = Validations.validateLocationTabs(newValue, updatedLocationDetails, "website");
    setErrors(error);
    if (!Object.keys(error).length) {
      [...Array(20)].map((item: any, i: any) => {
        if (locationDetail?.[`delete_image_${i + 1}`] || updateImageOrder) {
          newValue = {
            ...newValue,
            ...newImages,
          };
        }
        return true;
      });

      Object.keys(newValue).forEach((key: any) => {
        if (constants?.approvalFieldsWebsite.indexOf(key) !== -1) {
          newValue.directory_update_required = true;
        }
      });

      const requestData = {
        approveData: JSON.stringify({
          old_value: oldLocationDetail,
          new_value: newValue,
        }),
        websiteData: updatedLocationDetails,
        tabName: "website",
        id: updatedLocationDetails?.id,
        updateImageOrder,
        imagesArray,
      };
      dispatch(updateLocation(requestData));
      if (updateImageOrder) {
        setUpdateImageOrder(false);
      }
      setUpdateImageOrder(false);
    } else {
      scrollToErrorByClass(error);
    }
  };

  useImperativeHandle(ref, () => ({
    callChildOnSave() {
      onSave();
    },
  }));

  const attr: WebsiteAttr = {
    ...props,
    errors,
    setErrors,
    onSave,
    setNewImages,
  };

  return (
    <Grid container spacing={3} mt={1}>
      <Grid item xs={12} lg={3}>
        <Sidenav />
      </Grid>
      <Grid item xs={12} lg={9}>
        <MDBox mb={3}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <BasicInfo {...attr} />
            </Grid>
            <Grid item xs={12}>
              <ContactInfo {...attr} />
            </Grid>
            <Grid item xs={12}>
              <Images {...attr} />
            </Grid>
            <Grid item xs={12}>
              <SocialLinks {...attr} />
            </Grid>
            {systemAdmin() && (
              <Grid item xs={12}>
                <Integrations {...attr} />
              </Grid>
            )}
            <Grid item xs={12}>
              <Settings {...attr} />
            </Grid>
            <Grid item xs={12}>
              <Amenities {...attr} />
            </Grid>
            <Grid item xs={12}>
              <Blogs {...attr} />
            </Grid>
            <Grid item xs={12} display="flex" justifyContent="flex-end">
              {/* <MDBox mr={1}>
                <FormControlLabel
                  control={
                    <Switch
                      checked={locationDetail?.directory_update_required || false}
                      onChange={onChangeRadio}
                      inputProps={{ "aria-label": "controlled" }}
                      name="directory_update_required"
                    />
                  }
                  label="Directory Update Required"
                  labelPlacement="end"
                />
                {errors && errors?.directory_update_required && (
                  <CustomErrorMessage message={errors?.directory_update_required} />
                )}
              </MDBox> */}
              <MDButton
                variant="gradient"
                color="info"
                size="small"
                onClick={onSave}
                className="fixed-button"
                disabled={getAccessTypePermission("location_website", "edit_access")}
              >
                Save
              </MDButton>
            </Grid>
          </Grid>
        </MDBox>
      </Grid>
    </Grid>
  );
});

export default Index;
