import React, { useEffect, useRef, useState } from "react";
import { useLocation } from "react-router-dom";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import {
  Card,
  CardContent,
  Grid,
  Tab,
  Tabs,
  Icon,
  InputAdornment,
  Stack,
  Chip,
  IconButton,
  Menu,
  MenuItem,
  Switch,
  Tooltip,
  FormControlLabel,
  Box,
  Checkbox,
} from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import WarningAmber from "@mui/icons-material/WarningAmber";
import CircularProgress from "@mui/material/CircularProgress";
import MDIconButton from "components/MDIconButton";
import CustomBackButton from "components/CustomBackButton";
import { useAppSelector, useAppDispatch } from "store/store";
import {
  getLeads,
  getLeadDetails,
  leadStatusUpdate,
  getLeadConversation,
  sendSMS,
  reSendSMS,
  editMessageStatus,
  getDealActivity,
  enrollDeal,
  leadOwnerUpdate,
} from "store/thunk/leaseThunk";
import {
  // getAllAssociationLocations,
  // getAssociationLocations,
  getAuthUser,
  toBase64,
  getMessageCountTexts,
  getLocationIdsForConversation,
  formattedPhone,
  getAccessLocationPermission,
  getEnrollNextTime,
  compressImage,
  fileSizeInBytes,
} from "helper/services";
import CustomSelect from "components/CustomSelect";
import MDTabPanel from "components/MDTabPanel";
import moment from "moment";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import CustomAutoSearch from "components/CustomAutoSearch";
import { useAsyncDebounce } from "react-table";
import SmsRequestComponent from "layouts/pages/crm/smsRequestComponent";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { StaticDatePicker } from "@mui/x-date-pickers/StaticDatePicker";
import MDDialog from "components/MDDialog";
import { cancelSchedules, updateSchedules } from "store/thunk/authThunk";
import {
  setDealActivityDetails,
  setLeadDetails,
  setLeaseConversation,
  setLeads,
} from "store/slices/leaseSlice";
import { notificationFail } from "store/slices/notificationSlice";
import CustomSMSTemplateDropDown from "components/CustomSMSTemplateDropDown";
import fileimg from "assets/images/file-image.svg";
import CustomDropZone from "components/CustomDropZone";
import Messages from "helper/messages";
import WebCalling from "layouts/pages/crm/deals/lead/webcalling";
import constants from "helper/constants";
import UserWithProfilePic from "components/CustomAutoSearch/UserWithProfilePic";
import MDDatePicker from "components/MDDatePicker";
import LeadInfo from "./leadInfo";
import NewViewLead from "./newViewLead";
import NoteTab from "./noteTab";

const {
  servicesFilterOptions,
  studioTypeOptions,
  studioPreferenceOptions,
  interestedSharingOptions,
  leadComingFromOptions,
  reasonClosingLeadOptions,
  messageStatusOptions,
  sortByOptions,
  optOutOptions,
} = constants;

function Index(): JSX.Element {
  const dispatch = useAppDispatch();
  const dealLockText =
    "All the SMS and CALLING functionality will remain locked until user reply YES to receive sms from sola connect.";
  const bottomRef: any = useRef(null);
  const valueRef: any = useRef();
  const { state }: any = useLocation();
  const { statusArray } = useAppSelector((state) => state.dealSlice);
  const { dealDetailId, loading } = useAppSelector((state) => state.authReducer);
  const { leads, leadDetails, leaseConversation, dealActivityDetails } = useAppSelector(
    (state) => state.leaseSlice
  );
  const [propsData] = useState<any>(state || null);
  const [leadData, setLeadData] = useState<any>({});
  const [valueDropdownStatus, setValueDropdownStatus] = useState<any>(null);
  const [conversation, setConversation] = useState<any>([]);
  const [textMsg, setTextMsg] = useState<any>("");
  const [statusFilter, setStatusFilter] = useState<any>([]);
  const [tabValue, setTabValue] = useState<number>(0);
  const [msgtabValue, setMsgtabValue] = useState<number>(0);
  const [cardTabValue, setCardTabValue] = useState<number>(0);
  const [toggleFilter, setToggleFilter] = useState<boolean>(false);
  const [loadmoreMsg, setLoadmoreMsg] = useState<boolean>(false);

  const [schedule, setSchedule] = useState<boolean>(false);
  const [value, setValue] = useState<any>(null);
  const [time, setTime] = useState<any>(null);
  const [timeSlot, setTimeSlot] = useState<any>([]);
  const [eventLength] = useState<any>(30);

  const [search, setSearch] = useState<any>(null);
  const [selectLocations, setSelectLocations] = useState<any>([]);
  const [dealActivity, setDealActivity] = useState<any>([]);

  const [page, setPage] = useState<any>(0);
  const [totalPages, setTotalPages] = useState<any>(0);

  const [readMore, setReadMore] = useState<any>(null);
  const [messagesInterval, setMessagesInterval] = useState<any>(null);
  const [lastConvId, setLastConvId] = useState<any>(null);

  const [showCardView, setShowCardView] = useState<any>(true);
  const [numberRemoved, setNumberRemoved] = useState<any>(false);
  const [defaultView, setDefaultView] = useState<boolean>(true);
  const [isFileUpload, setIsFileUpload] = useState("");
  const [attachments, setAttachments] = useState<any>([]);
  const authUser = getAuthUser();
  const [serviceFilterData, setServiceFilterData] = useState<any>([]);
  const [confirmUnenroll, setConfirmUnenroll] = useState<boolean>(false);
  const [studioTypeFilter, setStudioTypeFilter] = useState<any>(null);
  const [studioPreferenceFilter, setStudioPreferenceFilter] = useState<any>(null);
  const [leadComingFilter, setLeadComingFilter] = useState<any>(null);
  const [sharingStudioFilter, setSharingStudioFilter] = useState<any>(null);
  const [reasonClosingFilter, setReasonClosingFilter] = useState<any>(null);
  const [competitorNameFilter, setCompetitorNameFilter] = useState<any>(null);
  const [dealPhoneFilter, setDealPhoneFilter] = useState<any>(null);
  const [dateCreatedFilter, setDateCreatedFilter] = useState<any>(null);
  const [dateClosedFilter, setDateClosedFilter] = useState<any>(null);
  const [myDeals, setMyDeals] = useState<boolean>(false);
  // manage applySort while click on Apply
  const [applySort, setApplySort] = useState<boolean>(true);
  const [messageStatusFilter, setMessageStatusFilter] = useState(null);
  const [sortByFilter, setSortByFilter] = useState(null);
  const [pageNo, setPageNo] = useState(0);
  const [pageCount, setPageCount] = useState(0);
  const [totalCount, setTotalCount] = useState(0);
  const [perPage] = useState(100);
  const [dealOwnerId, setDealOwnerId] = useState<any>(null);
  const [filterChanged, setFilterChanged] = useState<any>(false);
  const [optOutFilter, setOptOutFilter] = useState(null);
  const [twilioSmsId, setTwilioSmsId] = useState<any>(null);
  const [sendSMSLoader, setSendSMSLoader] = useState<any>(false);
  const [resubmittedDeals, setResubmittedDeals] = useState<boolean>(false);

  useEffect(() => {
    setPageCount(leads?.meta?.total_pages);
    setTotalCount(leads?.meta?.total_count);
    setPageNo(leads?.meta?.current_page);
  }, [leads]);

  useEffect(() => {
    valueRef.current = messagesInterval;
  }, [messagesInterval]);

  const changeTab = (event: any, newValue: any) => {
    setMessagesInterval(null);
    if (valueRef?.current) {
      clearInterval(valueRef.current);
    }
    setTabValue(newValue);
  };

  const changeMsgTab = (event: any, newValue: any) => {
    setMsgtabValue(newValue);
  };

  const clearPaginationData = () => {
    setPageNo(0);
    setPageCount(0);
    setTotalCount(0);
  };

  useEffect(() => {
    dispatch(
      setLeads(
        leads?.leads?.filter((obj: any) =>
          obj.message_status === cardTabValue ? "done" : "open"
        ) || []
      )
    );
  }, [cardTabValue]);

  const readMoreText = (id: any) => {
    if (id !== null) {
      setReadMore(id);
    } else {
      setReadMore(null);
    }
  };

  const isFilterAppyied = () => {
    if (
      selectLocations?.length ||
      statusFilter?.length ||
      serviceFilterData?.length ||
      studioTypeFilter?.length ||
      studioPreferenceFilter?.length ||
      leadComingFilter?.length ||
      sharingStudioFilter ||
      reasonClosingFilter ||
      competitorNameFilter ||
      messageStatusFilter ||
      sortByFilter ||
      search ||
      dateCreatedFilter ||
      dateClosedFilter ||
      myDeals ||
      optOutFilter ||
      dealPhoneFilter ||
      resubmittedDeals
    ) {
      return true;
    }
    return false;
  };

  const sendSMSCallback = () => {
    setTextMsg("");
    setAttachments([]);
    if (!cardTabValue || leadData.status === "New" || confirmUnenroll) {
      if (!cardTabValue) {
        setCardTabValue(1);
      }
      const leadDetailrequest: any = {
        leadId: leadData.id,
        goToMessages: true,
      };
      dispatch(getLeadDetails(leadDetailrequest));
      if (confirmUnenroll) {
        setConfirmUnenroll(false);
      }
    }
    setSendSMSLoader(false);
  };

  const sendTextMessage = () => {
    if (textMsg.trim().length || attachments?.length) {
      setSendSMSLoader(true);
      if (attachments?.length > 3) {
        dispatch(notificationFail("Maximum 3 attachments allowed"));
        setSendSMSLoader(false);
        return;
      }

      // check attachments size
      let totalBites = 0;
      for (let i = 0; i < attachments.length; i++) {
        totalBites += fileSizeInBytes(attachments[i]);
      }
      const fileSizeMB = totalBites / 1024 ** 2;
      if (fileSizeMB > 5) {
        dispatch(notificationFail("Max attachments size should be upto 5 MB."));
        setSendSMSLoader(false);
        return;
      }

      dispatch(
        sendSMS({
          leadId: leadData.id,
          content: textMsg || "",
          location_id: leadData.location_id,
          contact_id: leadData?.connect_contact?.id || null,
          from_no: leadData?.connect_twillio_phone_number?.twillio_phone_number,
          to_no: leadData?.connect_contact?.phone || null,
          type: "sms",
          sender_user_id: authUser.user_id,
          unenroll_lead: confirmUnenroll,
          attachments,
          location_ids: getLocationIdsForConversation(
            leadData.location_id,
            leadData?.connect_twillio_phone_number?.location_ids
          ),
          callback: sendSMSCallback,
          setSendSMSLoader,
        })
      );
    } else {
      dispatch(notificationFail("Please enter Text message or Attach file to send."));
    }
  };

  const checkEnrollDeal = () => {
    if (leadData?.enroll_lead) {
      setConfirmUnenroll(true);
    } else {
      sendTextMessage();
    }
  };

  useEffect(() => {
    setDealActivity([]);
    dispatch(setDealActivityDetails([]));
    setPage(0);
    if (tabValue && tabValue === 1) {
      const request: any = {
        page: 0,
        leadId: leadData.id,
      };
      dispatch(getDealActivity(request));
    }
  }, [tabValue]);

  useEffect(() => {
    if (
      dealActivityDetails &&
      dealActivityDetails?.data &&
      dealActivityDetails?.meta?.total_pages
    ) {
      const ids = dealActivity.map((d: any) => d.id);

      const allActivities = dealActivityDetails.data.filter(
        (item: any) => ids.indexOf(item?.id) === -1
      );
      setDealActivity([...dealActivity, ...allActivities]);
      setTotalPages(dealActivityDetails?.meta?.total_pages);
    }
  }, [dealActivityDetails]);

  const loadMoreActivities = () => {
    const request: any = {
      page: page + 1,
      leadId: leadData.id,
    };
    dispatch(getDealActivity(request));
    setPage(page + 1);
  };

  const displayFiles = (files?: any) => {
    const filesHtml: any = [];
    if (files?.length) {
      files?.map((file: string) =>
        filesHtml.push(
          <a href={file} target="_blank" rel="noreferrer">
            <img src={fileimg} alt={file} width={30} />
          </a>
        )
      );
    }
    return filesHtml;
  };

  const renderActivity = () => {
    const activities: any = [];

    if (dealActivity?.length > 0) {
      dealActivity.map((activity: any) => {
        activities.push(
          <Card className="activity-card">
            <CardContent sx={{ padding: "16px" }}>
              <MDTypography variant="h5" component="div">
                Deal activity
              </MDTypography>
              <MDTypography
                variant="body2"
                component="p"
                fontSize="small"
                className="activity-card-date"
              >
                {moment(activity?.updated_at).format("MMMM D, YYYY, HH:mm")}
              </MDTypography>
              <MDTypography variant="body2">{activity?.content}</MDTypography>
              {displayFiles(activity?.files || [])}
            </CardContent>
          </Card>
        );
        return true;
      });
    } else {
      activities.push(
        <MDBox textAlign="center">
          <MDTypography variant="body2">No Activity</MDTypography>
        </MDBox>
      );
    }

    return activities;
  };

  const handleDateChange = (date: any, flag: any) => {
    setFilterChanged(true);
    const utcDate = date && date.length ? moment(new Date(date)).format("YYYY-MM-DD") : null;
    if (flag === "date_created") {
      setDateCreatedFilter(utcDate);
    }
    if (flag === "date_closed") {
      setDateClosedFilter(utcDate);
    }
  };

  const getAllLeads = (optionData?: any) => {
    const {
      selectLocationsList,
      statusFilterList,
      serviceData,
      studioType,
      studioPreference,
      leadComingFrom,
      reason,
      sharing,
      competitor,
      leadSearch,
      messageStatus,
      dateCreated,
      dateClosed,
      viewMyDeals,
      sortBy,
      pageIndex,
      previousDeals,
      applyMsgType,
      messageStatusCheckbox,
      optOut,
      dealPhone,
      isDealResubmitted,
    } = optionData;
    const locationsList: any = selectLocationsList
      ? selectLocationsList
      : selectLocations?.length
      ? [...selectLocations]
      : [];
    const statusList: any = statusFilterList
      ? statusFilterList
      : statusFilter?.length
      ? [...statusFilter]
      : [];
    const serviceList: any = serviceData
      ? serviceData
      : serviceFilterData?.length
      ? [...serviceFilterData]
      : [];
    const studioTypeList: any = studioType
      ? studioType
      : studioTypeFilter?.length
      ? [...studioTypeFilter]
      : [];
    const preferenceList: any = studioPreference
      ? studioPreference
      : studioPreferenceFilter?.length
      ? [...studioPreferenceFilter]
      : [];
    const leadComingFromList: any = leadComingFrom
      ? leadComingFrom
      : leadComingFilter?.length
      ? [...leadComingFilter]
      : [];
    const reasonList: any = reason
      ? reason
      : reasonClosingFilter && Object.entries(reasonClosingFilter).length > 0
      ? { ...reasonClosingFilter }
      : {};
    const sharingList: any = sharing
      ? sharing
      : sharingStudioFilter && Object.entries(sharingStudioFilter).length > 0
      ? { ...sharingStudioFilter }
      : {};
    const optOutList: any = optOut
      ? optOut
      : optOutFilter && Object.entries(optOutFilter).length > 0
      ? { ...optOutFilter }
      : {};
    const competitorName: any =
      competitor !== undefined ? competitor : competitorNameFilter ? competitorNameFilter : "";
    const dealContactNumber: any =
      competitor !== undefined ? dealPhone : dealPhoneFilter ? dealPhoneFilter : "";
    setToggleFilter(false);
    const locations =
      Object.entries(locationsList).length > 0
        ? locationsList.map((v: any) => v.cms_location_id)
        : getAccessLocationPermission("crm", "crm_deals", "", "view_access").get_location_by_ids; // SD-3070 change related permission
    // : getAssociationLocations().get_location_by_ids;
    const status =
      Object.entries(statusList).length > 0 ? statusList.map((v: any) => v.status) : null;
    const studiotype = Object.entries(studioTypeList).length > 0 ? studioTypeList : null;
    const servicefilter = Object.entries(serviceList).length > 0 ? serviceList : null;
    const preference = Object.entries(preferenceList).length > 0 ? preferenceList : null;
    const comingFrom = Object.entries(leadComingFromList).length > 0 ? leadComingFromList : null;
    const reasonClosing = Object.entries(reasonList).length > 0 ? reasonList : null;
    const sharingStudio = Object.entries(sharingList).length > 0 ? sharingList : null;
    const optOutListData = Object.entries(optOutList).length > 0 ? optOutList : null;
    let tempMessageType =
      typeof messageStatusCheckbox !== "undefined"
        ? messageStatusCheckbox
        : messageStatusFilter || messageStatus || "";
    if (!isFilterAppyied() && !messageStatus && applyMsgType) {
      if (cardTabValue) {
        tempMessageType = messageStatusOptions.find((obj: any) => obj.value === "done");
      } else {
        tempMessageType = messageStatusOptions.find((obj: any) => obj.value === "open");
      }
    }

    const request: any = {
      type: "Request leasing information",
      pageIndex: typeof pageIndex !== "undefined" ? pageIndex : pageNo,
      pageSize: perPage,
      leads: previousDeals || [],
      locations: locations?.length ? [...new Set(locations)] : [],
      status: [...new Set(status)],
      services: [...new Set(servicefilter)],
      studioTypes: [...new Set(studiotype)],
      studioPreferance: [...new Set(preference)],
      leadComingFrom: [...new Set(comingFrom)],
      reasonCloseLead: reasonClosing,
      sharingStudio,
      competitorName,
      dealContactNumber,
      search: leadSearch || search,
      messageStatus: tempMessageType,
      dateCreated: typeof dateCreated !== "undefined" ? dateCreated : dateCreatedFilter || null,
      dateClosed: typeof dateClosed !== "undefined" ? dateClosed : dateClosedFilter || null,
      optOut: optOutListData,
      sort: [
        {
          id: "last_conversation_created_at",
          desc: true,
        },
      ],
    };
    if (!applySort) {
      request.sort[0] = {
        id: "last_conversation_created_at",
        asc: true,
      };
    } else {
      request.sort[0] = {
        id: "last_conversation_created_at",
        desc: true,
      };
    }
    if (sortBy?.value || sortByFilter?.value) {
      let spitSortValue = sortBy?.value || sortByFilter?.value;
      if (spitSortValue) {
        spitSortValue = spitSortValue.split("__");
      }
      if (spitSortValue?.[0] && spitSortValue?.[1]) {
        request.sort = [{ id: spitSortValue[0], desc: spitSortValue[1] === "desc" ? true : false }];
      }
    }
    request.setPageNo = setPageNo;
    if (typeof viewMyDeals !== "undefined" ? viewMyDeals : myDeals || false) {
      request.dealOwnerId = authUser?.user_id;
    }
    if (typeof isDealResubmitted !== "undefined" ? isDealResubmitted : resubmittedDeals || false) {
      request.isDealResubmitted =
        typeof isDealResubmitted !== "undefined" ? isDealResubmitted : resubmittedDeals || false;
    }
    dispatch(getLeads(request));
  };

  const refreshPage = () => {
    if (leadData?.id) {
      const leadDetailrequest: any = {
        leadId: leadData.id,
        goToMessages: true,
      };
      dispatch(getLeadDetails(leadDetailrequest));
    }
    getAllLeads({ pageIndex: 0 });
  };

  const changeCardTab = (event: any, newValue: any) => {
    clearPaginationData();
    let tempMessageType = messageStatusOptions.find((obj: any) => obj.value === "open");
    if (newValue) {
      tempMessageType = messageStatusOptions.find((obj: any) => obj.value === "done");
    }
    getAllLeads({ pageIndex: 0, messageStatus: tempMessageType, applyMsgType: true });
    setCardTabValue(newValue);
  };

  useEffect(() => {
    if (dealDetailId) {
      dispatch(setLeadDetails(null));
      const request: any = {
        leadId: dealDetailId,
      };
      dispatch(getLeadDetails(request));
    }
  }, [dealDetailId]);

  useEffect(() => {
    if (propsData && propsData.id) {
      if (propsData?.data?.messageStatus === "done") {
        setCardTabValue(1);
      }
      dispatch(setLeadDetails(null));
      const request: any = {
        leadId: propsData.id,
      };
      dispatch(getLeadDetails(request));
    }
    window.history.replaceState(null, "");

    return () => {
      setMessagesInterval(null);
      clearInterval(valueRef.current);
    };
  }, []);

  const getLeadChatLocationDetails = (element: any) => {
    const alreadyExistsInLocation = selectLocations?.findIndex(
      (obj: any) => Number(obj.cms_location_id) === Number(element?.location?.id)
    );
    const updatedLocation: any = [
      ...selectLocations,
      {
        cms_location_id: element?.location?.id,
        name: element?.location?.name,
      },
    ];
    if (alreadyExistsInLocation === -1) {
      setSelectLocations(updatedLocation);
    }
    clearPaginationData();
    getAllLeads({ pageIndex: 0, selectLocationsList: updatedLocation, applyMsgType: false });
  };

  const checkDealInfo = (leadId: number) => {
    if (leadDetails?.data?.id === leadId) {
      setCardTabValue(!cardTabValue ? 1 : 0);
    }
  };

  // Click on Card
  const cardClickDetails = (datas: any) => {
    dispatch(setLeaseConversation(null));
    dispatch(
      getLeadDetails({
        leadId: datas?.id,
        goToMessages: true,
      })
    );
    setTextMsg("");
    clearInterval(messagesInterval);
    setMessagesInterval(null);
    setShowCardView(false);
  };

  // Click on Status of card
  const statusCard = (e: any, items: any) => {
    e.stopPropagation();
    dispatch(
      editMessageStatus({
        leadId: items.id,
        messageStatus: items.message_status === "done" ? "open" : "done",
        checkDealInfo,
        getAllLeads,
        refreshPage,
      })
    );
  };

  const getLeadChatStatusDetails = (element: any) => {
    const alreadyExistsInLocation = statusFilter?.findIndex(
      (obj: any) => obj.label === element?.status
    );
    const updatedStatus: any = [
      ...statusFilter,
      {
        label: element?.status,
        status: element?.status,
      },
    ];
    if (alreadyExistsInLocation === -1) {
      setStatusFilter(updatedStatus);
    }
    clearPaginationData();
    getAllLeads({ pageIndex: 0, statusFilterList: updatedStatus, applyMsgType: false });
  };

  const getLeadsList = () => {
    if (leadDetails && leadDetails?.data) {
      let tempFinDDeal = JSON.parse(JSON.stringify(leads?.leads || []))?.find(
        (obj: any) => obj.id === leadDetails?.data?.id
      );
      if (!tempFinDDeal) {
        tempFinDDeal = {
          ...leadDetails?.data,
          location: {
            ...leadDetails?.data?.location,
            connect_twillio_sms_request: leadDetails?.data?.connect_twillio_sms_request,
          },
        };
      }
      const tempDeals = JSON.parse(JSON.stringify(leads?.leads || []))?.filter(
        (obj: any) => obj.id !== leadDetails?.data?.id
      );
      if (tempFinDDeal) {
        tempDeals.unshift({
          ...tempFinDDeal,
          deal_name: leadDetails?.data?.deal_name,
          status: leadDetails?.data?.status,
          message_status: leadDetails?.data?.message_status,
        });
      }
      return tempDeals;
    }
    return leads?.leads?.length ? leads.leads : [];
  };

  const renderCards = (type: any, status?: any) => {
    const messageStatus = type === 0 ? "open" : "done";
    const statusWiseLeads =
      getLeadsList()?.filter(
        (obj: any) => obj.status === status && obj.message_status === messageStatus
      ) || [];
    return (
      <NewViewLead
        leads={statusWiseLeads}
        getLeadChatLocationDetails={getLeadChatLocationDetails}
        cardClickDetails={cardClickDetails}
        statusCard={statusCard}
        statusDisp={status}
        leadData={leadData}
        viewType="sort"
        getLeadChatStatusDetails={getLeadChatStatusDetails}
        applySort={applySort}
        // locations={locationsList}
      />
    );
  };

  // Default Card Render view
  const defaultRenderCards = (type?: any) => {
    const messageStatus = type === 0 ? "open" : "done";
    const statusWiseLeads =
      getLeadsList()?.filter((obj: any) => obj.message_status === messageStatus) || [];
    return (
      <NewViewLead
        leads={statusWiseLeads}
        getLeadChatLocationDetails={getLeadChatLocationDetails}
        cardClickDetails={cardClickDetails}
        statusCard={statusCard}
        leadData={leadData}
        getLeadChatStatusDetails={getLeadChatStatusDetails}
        applySort={applySort}
      />
    );
  };

  const noMessages = (type: number) => {
    let noMessageHtml: any = "";
    const messageStatus = type === 0 ? "open" : "done";
    let statusWiseLeads = leads?.leads?.length ? leads?.leads : [];
    if (!isFilterAppyied()) {
      statusWiseLeads =
        statusWiseLeads?.filter((obj: any) => obj.message_status === messageStatus) || [];
    }
    if (!statusWiseLeads?.length) {
      noMessageHtml = (
        <Grid item xs={12} sm={12} md={12} lg={12} sx={{ flex: "1 !important" }}>
          <MDBox textAlign="center" mt={15}>
            <MDTypography variant="body2">No Messages</MDTypography>
          </MDBox>
        </Grid>
      );
    }
    return noMessageHtml;
  };

  useEffect(() => {
    if (leadDetails && leadDetails.data) {
      if (leadDetails?.goToMessages) {
        setTabValue(0);
      }
      setLeadData(leadDetails.data);
      setDealActivity([]);
      dispatch(setDealActivityDetails([]));
      dispatch(
        getLeadConversation({
          leadId: leadDetails?.data?.id,
          contact_id: leadDetails?.data?.connect_contact?.id,
          conversations_type: "sms",
          location_ids: getLocationIdsForConversation(
            leadDetails?.data?.location?.id,
            leadDetails?.data?.connect_twillio_phone_number?.location_ids
          ),
        })
      );
      setLoadmoreMsg(false);
      if (!cardTabValue && leadDetails?.data?.message_status === "done") {
        setCardTabValue(1);
        getAllLeads({
          pageIndex: 0,
          messageStatus: leadDetails?.data?.message_status,
          applyMsgType: true,
        });
      }
      if (cardTabValue && leadDetails?.data?.message_status === "open") {
        setCardTabValue(0);
        getAllLeads({
          pageIndex: 0,
          messageStatus: leadDetails?.data?.message_status,
          applyMsgType: true,
        });
      }
      if (leadDetails?.data?.deal_owner_id) {
        setDealOwnerId({
          value: {
            user_id: leadDetails?.data?.deal_owner_id,
            first_name: leadDetails?.data?.connect_user?.first_name || "",
            last_name: leadDetails?.data?.connect_user?.last_name || "",
            profile_picture: leadDetails?.data?.connect_user?.profile_picture || "",
          },
          label: `${leadDetails?.data?.connect_user?.first_name || ""} ${
            leadDetails?.data?.connect_user?.last_name || ""
          } `,
        });
      } else {
        setDealOwnerId(null);
      }
    } else {
      setLeadData({});
    }

    if (leadDetails && !leadDetails?.data?.connect_twillio_phone_number) {
      // setTabValue(2);
    }
  }, [leadDetails]);

  useEffect(() => {
    if (leaseConversation) {
      setConversation(leaseConversation.connect_conversations);
      const lastID =
        leaseConversation.connect_conversations[leaseConversation.connect_conversations.length - 1]
          ?.id;
      if (!loadmoreMsg && lastID !== lastConvId) {
        setTimeout(() => {
          if (document.getElementById("msg")) {
            document.getElementById("msg").scrollTo({
              top: Number(document.getElementById("msg").scrollHeight),
              behavior: "smooth",
            });
          }
        }, 500);
      }
      if (
        leaseConversation?.deal_details &&
        (leaseConversation?.deal_details?.status !== leadDetails?.data?.status ||
          leaseConversation?.deal_details?.message_status !== leadDetails?.data?.message_status)
      ) {
        dispatch(
          setLeadDetails({
            data: {
              ...leadDetails?.data,
              status: leaseConversation?.deal_details?.status,
              message_status: leaseConversation?.deal_details?.message_status,
            },
          })
        );
      }
    } else {
      setConversation([]);
    }
  }, [leaseConversation]);

  useEffect(() => {
    if (conversation) {
      if (
        document.getElementById("msg") &&
        conversation[conversation.length - 1]?.id !== lastConvId
      ) {
        document.getElementById("msg").scrollTo({
          top: Number(document.getElementById("msg").scrollHeight),
          behavior: "smooth",
        });
      }
      setLastConvId(conversation[conversation.length - 1]?.id);
    }
  }, [conversation]);

  const getOptInOut = (data: any) => {
    let optOut = false;
    if (
      data?.connect_twillio_phone_number?.id &&
      data?.connect_contact?.opt_in_out?.length &&
      data?.connect_contact?.opt_in_out?.includes(data?.connect_twillio_phone_number?.id)
    ) {
      optOut = true;
    }
    return optOut;
  };

  useEffect(() => {
    if (leadData?.id) {
      if (messagesInterval === null) {
        const msgInterval = setInterval(
          () =>
            dispatch(
              getLeadConversation({
                page: 0,
                size: 100,
                leadId: leadData?.id,
                append: true,
                loading: "not_visible",
                leaseConversation: conversation,
                contact_id: leadData?.connect_contact?.id,
                conversations_type: "sms",
                location_ids: getLocationIdsForConversation(
                  leadData?.location?.id,
                  leadData?.connect_twillio_phone_number?.location_ids
                ),
              })
            ),
          10000
        );
        setMessagesInterval(msgInterval);
      }

      const setDealStatus = statusArray.filter((val: any) => val.status === leadData?.status)[0];
      setValueDropdownStatus({ label: setDealStatus?.status, value: setDealStatus?.value });
    }
    if (
      leadData?.connect_twillio_sms_request?.status === "removed_from_phone_number" ||
      !leadData.connect_contact?.phone ||
      getOptInOut(leadData)
    ) {
      setNumberRemoved(true);
    } else {
      setNumberRemoved(false);
    }
  }, [leadData]);

  useEffect(() => {
    let filterLocations: any = [];
    if (state && state?.data && state?.data.leads && Array.isArray(state?.data?.leads)) {
      filterLocations = state?.data?.leads.filter((obj: any) => obj?.cms_location_id !== "viewall");
      setSelectLocations(filterLocations);
    }
    if (state?.data?.status && state?.data?.status?.length) {
      setStatusFilter(state?.data?.status);
    }
    if (state?.data?.serviceFilter?.length) {
      setServiceFilterData(state?.data?.serviceFilter);
    }
    if (state?.data?.studioTypeFilter?.length) {
      setStudioTypeFilter(state?.data?.studioTypeFilter);
    }
    if (state?.data?.studioPreferenceFilter?.length) {
      setStudioPreferenceFilter(state?.data?.studioPreferenceFilter);
    }
    if (state?.data?.leadComingFilter?.length) {
      setLeadComingFilter(state?.data?.leadComingFilter);
    }
    if (
      state?.data?.reasonClosingFilter &&
      Object.entries(state?.data?.reasonClosingFilter).length
    ) {
      setReasonClosingFilter(state?.data?.reasonClosingFilter);
    }
    if (
      state?.data?.sharingStudioFilter &&
      Object.entries(state?.data?.sharingStudioFilter).length
    ) {
      setSharingStudioFilter(state?.data?.sharingStudioFilter);
    }
    if (state?.data?.competitorNameFilter) {
      setCompetitorNameFilter(state?.data?.competitorNameFilter);
    }
    if (state?.data?.leadSearch) {
      setSearch(state?.data?.leadSearch);
    }
    if (state?.data?.messageStatusFilter) {
      setMessageStatusFilter(state?.data?.messageStatusFilter);
    }
    if (state?.data?.sortByFilter) {
      setSortByFilter(state?.data?.sortByFilter);
    }
    if (state && state?.data?.dateCreatedFilter) {
      setDateCreatedFilter(state?.data?.dateCreatedFilter);
    }
    if (state && state?.data?.dateClosedFilter) {
      setDateClosedFilter(state?.data?.dateClosedFilter);
    }

    if (state && state?.data?.viewMyDeals) {
      setMyDeals(state?.data?.viewMyDeals);
    }

    if (state?.data?.optOutFilter) {
      setOptOutFilter(state?.data?.optOutFilter);
    }

    if (state?.data?.dealPhoneFilter) {
      setDealPhoneFilter(state?.data?.dealPhoneFilter);
    }

    if (state?.data?.resubmittedDeals) {
      setResubmittedDeals(true);
    }

    getAllLeads({
      selectLocationsList: filterLocations,
      statusFilterList: state?.data?.status,
      serviceData: state?.data?.serviceFilter,
      studioType: state?.data?.studioTypeFilter,
      studioPreference: state?.data?.studioPreferenceFilter,
      leadComingFrom: state?.data?.leadComingFilter,
      reason: state?.data?.reasonClosingFilter,
      sharing: state?.data?.sharingStudioFilter,
      competitor: state?.data?.competitorNameFilter,
      leadSearch: state?.data?.leadSearch,
      messageStatus: state?.data?.messageStatusFilter,
      sortBy: state?.data?.sortByFilter,
      dateCreated: state?.data?.dateCreatedFilter,
      dateClosed: state?.data?.dateClosedFilter,
      viewMyDeals: state?.data?.viewMyDeals,
      optOut: state?.data?.optOutFilter,
      dealPhone: state?.data?.dealPhoneFilter,
      previousDeals: null,
      applyMsgType:
        state?.data &&
        Object.values(state?.data).some((element: any) =>
          Array.isArray(element) ? element.length : element
        )
          ? false
          : true,
      isDealResubmitted: state?.data?.resubmittedDeals || false,
    });
  }, [state]);

  const onChangeStatusDropdown = (value: any) => {
    dispatch(
      leadStatusUpdate({
        leadId: leadData?.id,
        leadStatus: value?.status,
        userId: authUser.user_id,
      })
    );
  };

  const onChangeDealOwner = (name: any, value: any) => {
    dispatch(
      leadOwnerUpdate({
        leadId: leadData?.id,
        leadOwner: value?.value ? value?.value?.user_id : null,
        userId: authUser.user_id,
      })
    );
  };

  const onSelectChange = (value: any) => {
    setStatusFilter(value);
    setFilterChanged(true);
  };

  const onChangeAutoComplete = (name: string, value: any) => {
    setSelectLocations(value);
    setFilterChanged(true);
  };

  const onSearchChange = useAsyncDebounce(() => {
    clearPaginationData();
    getAllLeads({ pageIndex: 0, applyMsgType: true });
  }, 1000);

  const handleDeleteFilter = (index: any, type: any) => {
    setLeadData({});
    const loca: any = selectLocations?.length ? [...selectLocations] : [];
    const status: any = statusFilter?.length ? [...statusFilter] : [];
    const service: any = serviceFilterData?.length ? [...serviceFilterData] : [];
    const studioType: any = studioTypeFilter?.length ? [...studioTypeFilter] : [];
    const studioPreference: any = studioPreferenceFilter?.length ? [...studioPreferenceFilter] : [];
    const leadComingFrom: any = leadComingFilter?.length ? [...leadComingFilter] : [];
    let reason: any = reasonClosingFilter?.length ? { ...reasonClosingFilter } : {};
    let sharing: any = sharingStudioFilter?.length ? { ...sharingStudioFilter } : {};
    let competitor: any = competitorNameFilter || "";
    let dealPhone: any = dealPhoneFilter || "";
    let sortBy: any = sortByFilter || null;
    let messageStatus: any = messageStatusFilter || null;
    let dateCreated: any = dateCreatedFilter || null;
    let dateClosed: any = dateClosedFilter || null;
    let viewMyDeals: any = myDeals || false;
    let optOut: any = optOutFilter || null;
    if (type === "location") {
      loca.splice(index, 1);
      setSelectLocations(loca);
    }
    if (type === "status") {
      status.splice(index, 1);
      setStatusFilter(status);
    }
    if (type === "services") {
      service.splice(index, 1);
      setServiceFilterData(service);
    }
    if (type === "studio_type") {
      studioType.splice(index, 1);
      setStudioTypeFilter(studioType);
    }
    if (type === "studio_preference") {
      studioPreference.splice(index, 1);
      setStudioPreferenceFilter(studioPreference);
    }
    if (type === "lead_coming_from") {
      leadComingFrom.splice(index, 1);
      setLeadComingFilter(leadComingFrom);
    }
    if (type === "reason_closing") {
      reason = {};
      setReasonClosingFilter(null);
    }
    if (type === "sharing_studio") {
      sharing = {};
      setSharingStudioFilter(null);
    }
    if (type === "competitor_name") {
      competitor = "";
      setCompetitorNameFilter(null);
    }
    if (type === "deal_phone_filter") {
      dealPhone = "";
      setDealPhoneFilter(null);
    }
    if (type === "sort_by") {
      sortBy = null;
      setSortByFilter(null);
    }
    if (type === "message_status") {
      messageStatus = null;
      setMessageStatusFilter(null);
    }
    if (type === "date_created") {
      dateCreated = null;
      setDateCreatedFilter(null);
    }
    if (type === "date_closed") {
      dateClosed = null;
      setDateClosedFilter(null);
    }
    if (type === "my_deals") {
      viewMyDeals = false;
      setMyDeals(false);
    }
    if (type === "opt_in_out") {
      optOut = {};
      setOptOutFilter(null);
    }
    let isDealResubmitted = resubmittedDeals;
    if (type === "is_deal_resubmitted") {
      setResubmittedDeals(false);
      isDealResubmitted = false;
    }

    clearPaginationData();
    getAllLeads({
      selectLocationsList: loca,
      statusFilterList: status,
      serviceData: service,
      studioType,
      studioPreference,
      leadComingFrom,
      reason,
      sharing,
      competitor,
      leadSearch: search,
      messageStatus: messageStatus || null,
      dateCreated,
      dateClosed,
      viewMyDeals: viewMyDeals || false,
      sortBy,
      previousDeals: leads,
      applyMsgType: true,
      optOut,
      dealPhone,
      isDealResubmitted,
    });
  };

  const changeStatusCheckbox = (event: any, item: any) => {
    let tmpMgsStatus: any = null;
    if (!messageStatusFilter && event?.target?.checked) {
      tmpMgsStatus = item;
    } else if (!messageStatusFilter && !event?.target?.checked) {
      tmpMgsStatus = messageStatusOptions.find((obj: any) => obj.value !== item.value);
    }
    setMessageStatusFilter(tmpMgsStatus);
    getAllLeads({ pageIndex: 0, messageStatusCheckbox: tmpMgsStatus, applyMsgType: true });
  };

  useEffect(() => {
    if (value) {
      setTimeSlot([]);
      const getTimeStops = (start: any, end: any, interval: number) => {
        const startTime = moment(start, "HH:mm");
        const endTime = moment(end, "HH:mm");

        if (endTime.isBefore(startTime)) {
          endTime.add(1, "day");
        }

        const timeStops: any = [];

        while (startTime <= endTime) {
          if (!timeStops.includes(moment(startTime).format("HH:mm"))) {
            timeStops.push(moment(startTime).format("HH:mm"));
          }
          startTime.add(interval, "minutes");
        }
        return timeStops;
      };
      const todayStart = moment(value.$d || value).format("HH:mm");
      const todayEnd = moment(value.$d || value)
        .endOf("day")
        .format("HH:mm");

      const timeStops = getTimeStops(todayStart, todayEnd, 10);
      setTimeSlot(timeStops);
    }
  }, [value]);

  const scheduleDialogProps = {
    open: schedule,
    saveBtn: false,
    saveTbtText: "Update",
    additionalBtn: true,
    additionalBtnText: "Cancel",
    additionalBtnColor: "error",
    onAdditionalBtnSave: () => {
      dispatch(
        cancelSchedules({
          leadId: leadData.id,
          setSchedule,
          scheduleId: leadData.connect_schedule.id,
        })
      );
    },
    disabledBtn: true,
    onClose: () => {
      setSchedule(false);
      setValue(null);
    },
    onSave: () => {
      const date = moment(value.$d || value).format("YYYY-MM-DD");
      const startTime = moment(`${date} ${time}`).utc().toISOString();
      const endTime = moment(`${date} ${time}`).add(30, "minutes").utc().toISOString();
      dispatch(
        updateSchedules({
          leadId: leadData.id,
          setSchedule,
          scheduleId: leadData.connect_schedule.id,
          startTime,
          endTime,
        })
      );
    },
    title: "Schedule",
    size: "md",
    overflowVisible: "overflowVisible",
  };

  const renderTimeSlots = () => {
    const slots: any = [];
    if (timeSlot && timeSlot.length) {
      timeSlot.map((val: any) =>
        slots.push(
          <MDBox
            className={`time ${val === time && "active"}`}
            onClick={(e: any) => {
              setTime(e.target.innerText);
            }}
          >
            {val}
          </MDBox>
        )
      );
    }
    return slots;
  };

  const onUpdateEnroll = (type?: boolean) => {
    const enrollData = {
      location_id: leadData?.location_id,
      leadId: leadData?.id,
      enrollType: type,
    };
    dispatch(enrollDeal(enrollData));
  };

  const handleChangeTextMessage = (e: any) => {
    setTextMsg(e.target.value);
  };

  const handleKeyPress = (e: any) => {
    if (
      e.which === 13 &&
      e.ctrlKey &&
      leadData?.connect_twillio_phone_number?.status === "enabled" &&
      leadData?.connect_twillio_phone_number?.sms_status &&
      !leadData?.deal_locked
    ) {
      checkEnrollDeal();
    }
  };

  const [smsTemplatePopupAnchorEl, setSmsTemplatePopupAnchorEl] =
    useState<HTMLButtonElement | null>(null);

  const handleClickTemplatePopup = (event: any) => {
    setSmsTemplatePopupAnchorEl(event.currentTarget);
  };

  const handleTemplatePopupClose = () => {
    setSmsTemplatePopupAnchorEl(null);
  };

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const onApply = () => {
    getAllLeads({ pageIndex: 0, applyMsgType: true });
    clearPaginationData();
    setApplySort(defaultView);
    setFilterChanged(false);
  };

  const changeSortView = (value: any) => {
    setDefaultView(value);
  };

  const displayFilesWithDeleteBtn = (files?: any) => {
    const filesHtml: any = [];
    if (files?.length) {
      files?.map((file: string, index: number) =>
        filesHtml.push(
          <MDBox
            item
            className="imageWithDeleteIcon"
            display="inline-block"
            sx={{ marginRight: "15px !important" }}
          >
            <img src={fileimg} alt={file} width={25} />
            <MDIconButton
              tooltipName="Delete"
              aria-label="Delete"
              className="removeBtnClass"
              onClick={() => setAttachments(files?.filter((obj: any, i: number) => index !== i))}
              sx={{ height: "15px", width: "15px" }}
            >
              <Icon fontSize="small" sx={{ fontSize: "13px !important" }}>
                close
              </Icon>
            </MDIconButton>
          </MDBox>
        )
      );
    }
    return filesHtml;
  };

  const attchFileDialogProps = {
    open: isFileUpload ? true : false,
    onClose: () => {
      setIsFileUpload("");
    },
    onSave: () => setIsFileUpload(""),
    title: `Choose ${isFileUpload}`,
    size: "sm",
  };
  const onAttachFile = async (files: any) => {
    const filesArray: any = attachments?.length ? attachments : [];
    if (files.length > 0) {
      /* eslint-disable no-await-in-loop */
      for (let i = 0; i < files.length; i++) {
        setSendSMSLoader(true);
        toBase64(
          constants?.imageExtentionArray.includes(files[i]?.path?.split(".").pop())
            ? await compressImage(files[i])
            : files[i]
        ).then((base64String: any) => {
          if (base64String) {
            filesArray.push(base64String);
          }
          setSendSMSLoader(false);
        });
      }
      setAttachments(filesArray);
      // setSendSMSLoader(false);
    }
  };

  const onCloseConfirmUnenroll = () => {
    setConfirmUnenroll(false);
    setTextMsg("");
    setAttachments([]);
  };

  const outboundBtnAttrs = (
    toNumber?: any,
    fromNumber?: any,
    numberStatus?: string,
    dealLock?: any
  ) => {
    const attributes = { message: "Outbound Call", disabled: false };
    if (dealLock) {
      attributes.message = dealLockText;
      attributes.disabled = true;
    } else if (!toNumber && !fromNumber) {
      attributes.message = "Location number and Contact number is required for outbound call";
      attributes.disabled = true;
    } else if (!toNumber) {
      attributes.message = "Contact number is required for outbound call";
      attributes.disabled = true;
    } else if (!fromNumber) {
      attributes.message = "Location number is required for outbound call";
      attributes.disabled = true;
    } else if (numberStatus && numberStatus !== "enabled") {
      attributes.message = `Location number status is ${numberStatus}`;
      attributes.disabled = true;
    }
    return attributes;
  };

  const deleteUnenrollDialogProps = {
    open: confirmUnenroll,
    onClose: onCloseConfirmUnenroll,
    onSave: sendTextMessage,
    title: "Confirm to Unenroll",
    size: "sm",
    saveBtn: false,
    deleteBtn: true,
    saveTbtText: "Send Message",
    closeBtnText: "Cancel",
  };

  const clearFilter = () => {
    clearPaginationData();
    setSearch("");
    setSelectLocations([]);
    setStatusFilter([]);
    setServiceFilterData([]);
    setCompetitorNameFilter("");
    setStudioTypeFilter(null);
    setStudioPreferenceFilter(null);
    setLeadComingFilter(null);
    setSharingStudioFilter(null);
    setReasonClosingFilter(null);
    setMessageStatusFilter(null);
    setSortByFilter(null);
    setDateCreatedFilter(null);
    setDateClosedFilter(null);
    setMyDeals(false);
    setFilterChanged(false);
    setDealPhoneFilter("");
    setResubmittedDeals(false);
    onSearchChange();
  };

  const deactiveStatus = ["requested", "deactive", "phone_deleted", "disabled"];

  const getLoadMoreBtn = () => (
    <MDBox sx={{ display: "flex", justifyContent: "center", marginTop: "2px" }}>
      {leads?.leads?.length >= 1000 ? (
        <MDTypography variant="subtitle2" fontSize="small">
          You can&apos;t view more than 1000 items in a column. Please try using the another view
          instead.
        </MDTypography>
      ) : (
        <MDButton
          disabled={pageNo + 1 === pageCount || leads?.leads?.length >= totalCount}
          onClick={() =>
            getAllLeads({ previousDeals: leads, pageIndex: pageNo + 1, applyMsgType: true })
          }
          color="info"
          size="small"
          sx={{ padding: "5px 10px 2px", minHeight: "unset", fontSize: "10px" }}
        >
          {leads?.leads?.length >= totalCount ? "No More Records..." : "Load More..."}
        </MDButton>
      )}
    </MDBox>
  );

  const getTooltipTxt = () => {
    let toolTipTxt = "Send (Ctrl+Enter)";
    if (leadData?.deal_locked) {
      toolTipTxt = dealLockText;
    } else if (
      leadData?.connect_twillio_phone_number?.status === "disabled" ||
      !leadData?.connect_twillio_phone_number?.sms_status
    ) {
      toolTipTxt = "SMS functionality is disabled for this location phone number";
    }
    return toolTipTxt;
  };

  const reSendMessageCallBack = (data: any) => {
    setTwilioSmsId(null);
    if (data?.status && data?.twilioSmsId) {
      setConversation(
        conversation.map((obj: any) => {
          const tempObj = JSON.parse(JSON.stringify(obj));
          if (tempObj.twilio_sms_id === data.twilioSmsId && data.newTwilioSmsId) {
            tempObj.twilio_sms_id = data.newTwilioSmsId;
          }
          return tempObj;
        })
      );
    }
  };

  const reSendMessage = (twilioSmsId?: number) => {
    setTwilioSmsId(twilioSmsId);
    dispatch(reSendSMS({ twilioSmsId, reSendMessageCallBack }));
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <Grid container className="lead-details-main">
        <Grid className="lead-detail-left" item xs={12} sm={12} md={12} lg={4}>
          <MDBox
            sx={{
              display: "flex",
              justifyContent: "space-between",
              borderRight: "1px solid rgba(0,0,0,0.2)",
            }}
          >
            <CustomBackButton
              label="Sales Pipeline View"
              path="/crm/sola-pro-leads/kanban"
              historyData={
                selectLocations?.length ||
                statusFilter?.length ||
                serviceFilterData?.length ||
                studioTypeFilter?.length ||
                studioPreferenceFilter?.length ||
                leadComingFilter?.length ||
                reasonClosingFilter?.length ||
                sharingStudioFilter?.length ||
                competitorNameFilter?.length ||
                messageStatusFilter ||
                dateCreatedFilter ||
                dateClosedFilter ||
                myDeals ||
                sortByFilter ||
                search ||
                optOutFilter ||
                dealPhoneFilter?.length ||
                resubmittedDeals
                  ? {
                      locations: [...selectLocations],
                      statusData: [...statusFilter],
                      serviceData: [...serviceFilterData],
                      studioTypeFilter,
                      studioPreferenceFilter,
                      leadComingFilter,
                      reasonClosingFilter,
                      sharingStudioFilter,
                      competitorNameFilter,
                      messageStatusFilter,
                      dateCreatedFilter,
                      dateClosedFilter,
                      viewMyDeals: myDeals,
                      sortByFilter,
                      search,
                      optOutFilter,
                      dealPhoneFilter,
                      resubmittedDeals,
                    }
                  : null
              }
            />
            <MDButton
              variant="gradient"
              color="info"
              size="small"
              className="xs-small"
              sx={{ margin: "10px" }}
              onClick={refreshPage}
            >
              Refresh
            </MDButton>
          </MDBox>

          <MDBox className="cards-list-div" item md={4}>
            <Grid container className="status-select">
              <MDBox flex="1" sx={{ backgroundColor: "#ffffff", flex: "0 0 100%" }}>
                <MDInput
                  placeholder="Search"
                  value={search || ""}
                  className="search-xs-small"
                  // size="small"
                  fullWidth
                  onChange={(e: any) => {
                    if (!loading) {
                      setSearch(e.target.value);
                      onSearchChange();
                    }
                  }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <Icon fontSize="medium">search</Icon>
                      </InputAdornment>
                    ),
                  }}
                />
              </MDBox>
              {!isFilterAppyied() && (
                <Tabs className="lead-tabs" value={cardTabValue} onChange={changeCardTab}>
                  <Tab
                    id="open"
                    className="message-view-tab"
                    label={
                      <MDBox py={0.5} px={2} color="inherit">
                        Awaiting Reply
                      </MDBox>
                    }
                  />
                  <Tab
                    id="done"
                    className="message-view-tab"
                    label={
                      <MDBox py={0.5} px={2} color="inherit">
                        Response Sent
                      </MDBox>
                    }
                  />
                </Tabs>
              )}
              <MDBox
                sx={{
                  display: "flex",
                  flex: "1",
                  padding: "5px",
                  maxHeight: "100px",
                  overflow: " auto",
                }}
              >
                <Stack direction="row" spacing={1} display="block">
                  {isFilterAppyied() && (
                    <Box className="conversation-custom_filter" sx={{ display: "flex" }}>
                      {messageStatusOptions?.map((item: any) => (
                        <FormControlLabel
                          control={
                            <Tooltip
                              title={
                                messageStatusFilter && messageStatusFilter.value === item?.value
                                  ? "Atleast one should be checked!"
                                  : item?.label
                              }
                              arrow
                              placement="bottom-start"
                            >
                              <Checkbox
                                key={item?.value}
                                checked={
                                  !messageStatusFilter || messageStatusFilter?.value === item?.value
                                    ? true
                                    : false
                                }
                                disabled={
                                  messageStatusFilter && messageStatusFilter.value === item?.value
                                }
                                onChange={(event: any) => changeStatusCheckbox(event, item)}
                              />
                            </Tooltip>
                          }
                          value="end"
                          label={item?.label}
                          labelPlacement="end"
                        />
                      ))}
                    </Box>
                  )}
                  {Object.entries(selectLocations).length > 0 &&
                    selectLocations.map((v: any, index: any) => (
                      <Chip
                        onDelete={() => handleDeleteFilter(index, "location")}
                        label={v.name}
                        className="filter-chip"
                      />
                    ))}
                  {Object.entries(statusFilter).length > 0 &&
                    statusFilter.map((v: any, index: any) => (
                      <Chip
                        onDelete={() => handleDeleteFilter(index, "status")}
                        label={v.status}
                        className="filter-chip"
                      />
                    ))}
                  {serviceFilterData &&
                    Object.entries(serviceFilterData).length > 0 &&
                    serviceFilterData.map((v: any, index: any) => (
                      <Chip
                        onDelete={() => handleDeleteFilter(index, "services")}
                        label={v.label}
                        className="filter-chip"
                      />
                    ))}
                  {studioTypeFilter &&
                    Object.entries(studioTypeFilter).length > 0 &&
                    studioTypeFilter.map((v: any, index: any) => (
                      <Chip
                        onDelete={() => handleDeleteFilter(index, "studio_type")}
                        label={v.label}
                        className="filter-chip"
                      />
                    ))}
                  {studioPreferenceFilter &&
                    Object.entries(studioPreferenceFilter).length > 0 &&
                    studioPreferenceFilter.map((v: any, index: any) => (
                      <Chip
                        onDelete={() => handleDeleteFilter(index, "studio_preference")}
                        label={v.label}
                        className="filter-chip"
                      />
                    ))}
                  {leadComingFilter &&
                    Object.entries(leadComingFilter).length > 0 &&
                    leadComingFilter.map((v: any, index: any) => (
                      <Chip
                        onDelete={() => handleDeleteFilter(index, "lead_coming_from")}
                        label={v.label}
                        className="filter-chip"
                      />
                    ))}
                  {reasonClosingFilter && Object.entries(reasonClosingFilter).length > 0 && (
                    <Chip
                      onDelete={() => handleDeleteFilter(0, "reason_closing")}
                      label={reasonClosingFilter.label}
                      className="filter-chip"
                    />
                  )}
                  {sharingStudioFilter && Object.entries(sharingStudioFilter).length > 0 && (
                    <Chip
                      onDelete={() => handleDeleteFilter(0, "sharing_studio")}
                      label={sharingStudioFilter.label}
                      className="filter-chip"
                    />
                  )}
                  {competitorNameFilter && Object.entries(competitorNameFilter).length > 0 && (
                    <Chip
                      onDelete={() => handleDeleteFilter(0, "competitor_name")}
                      label={competitorNameFilter}
                      className="filter-chip"
                    />
                  )}
                  {sortByFilter && Object.entries(sortByFilter).length > 0 && (
                    <Chip
                      onDelete={() => handleDeleteFilter(0, "sort_by")}
                      label={sortByFilter?.label}
                      className="filter-chip"
                    />
                  )}
                  {optOutFilter && Object.entries(optOutFilter).length > 0 && (
                    <Chip
                      onDelete={() => handleDeleteFilter(0, "opt_in_out")}
                      label={optOutFilter?.label}
                      className="filter-chip"
                    />
                  )}
                  {dealPhoneFilter && Object.entries(dealPhoneFilter).length > 0 && (
                    <Chip
                      onDelete={() => handleDeleteFilter(0, "deal_phone_filter")}
                      label={dealPhoneFilter}
                      className="filter-chip"
                    />
                  )}
                  {/* {messageStatusFilter && Object.entries(messageStatusFilter).length > 0 && (
                    <Chip
                      onDelete={() => handleDeleteFilter(0, "message_status")}
                      label={messageStatusFilter?.label}
                    />
                  )} */}
                  {dateCreatedFilter && (
                    <Chip
                      onDelete={() => handleDeleteFilter(0, "date_created")}
                      label={dateCreatedFilter}
                      className="filter-chip"
                    />
                  )}
                  {dateClosedFilter && (
                    <Chip
                      onDelete={() => handleDeleteFilter(0, "date_closed")}
                      label={dateClosedFilter}
                      className="filter-chip"
                    />
                  )}
                  {myDeals && (
                    <Chip
                      onDelete={() => handleDeleteFilter(0, "my_deals")}
                      label="View My Deals"
                      className="filter-chip"
                    />
                  )}
                  {resubmittedDeals && (
                    <Chip
                      onDelete={() => handleDeleteFilter(0, "is_deal_resubmitted")}
                      label="Resubmitted Deals"
                      className="filter-chip"
                    />
                  )}
                </Stack>
                <MDBox sx={{ display: "flex", flex: "1", justifyContent: "flex-end" }}>
                  <Icon fontSize="medium" onClick={() => setToggleFilter(!toggleFilter)}>
                    filter_list_rounded
                  </Icon>
                </MDBox>
              </MDBox>
              {toggleFilter && (
                <MDBox position="relative" width="100%">
                  <MDBox
                    className="crm-location-header-wrapper sms-view"
                    onClick={() => {
                      if (filterChanged) {
                        onApply();
                      }
                      setToggleFilter(false);
                    }}
                  />
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={12}
                    lg={12}
                    style={{ padding: "10px" }}
                    className="crm-location-header sms-view"
                  >
                    <IconButton
                      className="close-filter-button"
                      onClick={() => {
                        if (filterChanged) {
                          onApply();
                        }
                        setToggleFilter(false);
                      }}
                    >
                      <Icon>close</Icon>
                    </IconButton>
                    <MDBox mt={4}>
                      <CustomSelect
                        multiple
                        onChange={onSelectChange}
                        name="status"
                        placeholder="Select Status"
                        value={Object.keys(statusFilter).length > 0 ? statusFilter : null}
                        id="status"
                        options={statusArray.map((obj: any) => {
                          const tempObj = { ...obj };
                          tempObj.label = tempObj.status;
                          return tempObj;
                        })}
                        isClearable
                      />
                    </MDBox>
                    <CustomAutoSearch
                      slsAPI
                      multiple
                      placeholder="Select Location"
                      idKey="cms_location_id"
                      nameKey="name"
                      parentKey="location"
                      apiName="get-locations"
                      responseKey="data"
                      name="location"
                      value={
                        selectLocations && Object.entries(selectLocations).length
                          ? selectLocations
                          : null
                      }
                      // usersData={getAllAssociationLocations()}
                      usersData={getAccessLocationPermission("crm", "crm_deals", "", "view_access")}
                      onChange={onChangeAutoComplete}
                      isClearable
                    />
                    <CustomSelect
                      onChange={(value: any) => {
                        setServiceFilterData(value);
                        setFilterChanged(true);
                      }}
                      name="services"
                      fieldLabel=""
                      placeholder="Services"
                      value={serviceFilterData || []}
                      id="services"
                      options={servicesFilterOptions.map((obj: any) => {
                        const tempObj = obj;
                        tempObj.label = tempObj.name;
                        return tempObj;
                      })}
                      multiple
                      isClearable
                    />
                    <CustomSelect
                      onChange={(value: any) => {
                        setStudioTypeFilter(value);
                        setFilterChanged(true);
                      }}
                      name="studio_type"
                      fieldLabel=""
                      placeholder="Studio Type"
                      value={studioTypeFilter || []}
                      id="studio_type"
                      options={studioTypeOptions}
                      multiple
                      isClearable
                    />
                    <CustomSelect
                      onChange={(value: any) => {
                        setStudioPreferenceFilter(value);
                        setFilterChanged(true);
                      }}
                      name="studio_preference"
                      fieldLabel=""
                      placeholder="Studio Preference"
                      value={studioPreferenceFilter || []}
                      id="studio_preference"
                      options={studioPreferenceOptions}
                      multiple
                      isClearable
                    />
                    <CustomSelect
                      onChange={(value: any) => {
                        setLeadComingFilter(value);
                        setFilterChanged(true);
                      }}
                      name="where_is_lead_coming_from"
                      fieldLabel=""
                      placeholder="Where is Lead Coming From"
                      value={leadComingFilter || []}
                      id="where_is_lead_coming_from"
                      options={leadComingFromOptions}
                      multiple
                      isClearable
                    />
                    <CustomSelect
                      onChange={(value: any) => {
                        setSharingStudioFilter(value);
                        setFilterChanged(true);
                      }}
                      name="sharing_studio"
                      fieldLabel=""
                      placeholder="Interested in Sharing a Studio?"
                      value={sharingStudioFilter || []}
                      id="sharing_studio"
                      options={interestedSharingOptions}
                      isClearable
                    />
                    <CustomSelect
                      onChange={(value: any) => {
                        setReasonClosingFilter(value);
                        setFilterChanged(true);
                      }}
                      name="reason_closing_lead"
                      fieldLabel=""
                      placeholder="Reason For Closing Lead"
                      value={reasonClosingFilter || []}
                      id="reason_closing_lead"
                      options={reasonClosingLeadOptions}
                      isClearable
                    />
                    <CustomSelect
                      onChange={(value: any) => {
                        setMessageStatusFilter(value);
                        setFilterChanged(true);
                      }}
                      name="message_status"
                      fieldLabel=""
                      placeholder="Message Status"
                      value={messageStatusFilter || null}
                      id="message_status"
                      options={messageStatusOptions}
                      isClearable
                    />
                    <CustomSelect
                      onChange={(value: any) => {
                        setSortByFilter(value);
                        setFilterChanged(true);
                      }}
                      name="sort_by"
                      fieldLabel=""
                      placeholder="Sort by"
                      value={sortByFilter || null}
                      id="sort_by"
                      options={sortByOptions}
                      isClearable
                    />
                    <CustomSelect
                      onChange={(value: any) => {
                        setOptOutFilter(value);
                        setFilterChanged(true);
                      }}
                      name="opt_in_out"
                      fieldLabel=""
                      placeholder="Opt In/out"
                      value={optOutFilter || null}
                      id="opt_in_out"
                      options={optOutOptions}
                      isClearable
                    />

                    <MDDatePicker
                      name="date_created"
                      value={dateCreatedFilter}
                      input={{ placeholder: "Lead Created Date" }}
                      options={{
                        dateFormat: "Y-m-d",
                        maxDate: new Date(),
                      }}
                      onChange={(date: any) => {
                        handleDateChange(date, "date_created");
                      }}
                    />
                    <MDDatePicker
                      name="date_closed"
                      value={dateClosedFilter}
                      input={{ placeholder: "Lead Closed Date" }}
                      options={{
                        dateFormat: "Y-m-d",
                        maxDate: new Date(),
                      }}
                      onChange={(date: any) => {
                        handleDateChange(date, "date_closed");
                      }}
                    />

                    <MDInput
                      label=""
                      className="sms-filter-input"
                      variant="standard"
                      fullWidth
                      name="competitor_name"
                      placeholder="Competitor name"
                      onChange={(e: any) => {
                        setCompetitorNameFilter(e.target.value);
                        setFilterChanged(true);
                      }}
                      value={competitorNameFilter || ""}
                    />
                    <MDInput
                      label=""
                      className="filter-input"
                      variant="standard"
                      fullWidth
                      type="number"
                      name="deal_phone"
                      placeholder="Phone Number"
                      onChange={(e: any) => {
                        setDealPhoneFilter(e.target.value);
                        setFilterChanged(true);
                      }}
                      value={dealPhoneFilter}
                    />
                    <MDBox
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        marginTop: "10px",
                      }}
                    >
                      <FormControlLabel
                        label="View My Deals"
                        control={
                          <Checkbox
                            checked={myDeals}
                            onChange={(event: any) => {
                              setMyDeals(event?.target?.checked);
                              setFilterChanged(true);
                            }}
                          />
                        }
                        sx={{ margin: 0 }}
                      />
                      <FormControlLabel
                        label="Resubmitted Deals"
                        control={
                          <Checkbox
                            checked={resubmittedDeals}
                            onChange={(event: any) => {
                              setResubmittedDeals(event?.target?.checked);
                              setFilterChanged(true);
                            }}
                          />
                        }
                        sx={{ margin: 0 }}
                      />
                      {false && (
                        <FormControlLabel
                          control={
                            <Tooltip title={defaultView ? "Default View" : "Sort: Status"} arrow>
                              <Switch
                                checked={defaultView}
                                onChange={(e: any) => changeSortView(e.target.checked)}
                                name="gilad"
                              />
                            </Tooltip>
                          }
                          value="end"
                          label={defaultView ? "Default View" : "Sort: Status"}
                          labelPlacement="end"
                        />
                      )}
                    </MDBox>
                    <MDBox
                      style={{
                        display: "flex",
                        justifyContent: "flex-end",
                        marginTop: "10px",
                      }}
                    >
                      <MDBox>
                        <MDButton
                          variant="gradient"
                          color="light"
                          size="small"
                          className="xs-small"
                          onClick={clearFilter}
                        >
                          clear
                        </MDButton>
                        <MDButton
                          variant="gradient"
                          color="info"
                          size="small"
                          className="xs-small"
                          sx={{ marginLeft: "10px" }}
                          onClick={onApply}
                        >
                          Apply
                        </MDButton>
                      </MDBox>
                    </MDBox>
                  </Grid>
                </MDBox>
              )}
            </Grid>
            {isFilterAppyied() ? (
              <Grid
                id="open-done-deals"
                className="cards-list all-cards"
                style={
                  getLeadsList()?.length <= 0 ? { display: "flex", flexDirection: "column" } : {}
                }
              >
                <NewViewLead
                  leads={getLeadsList() || []}
                  getLeadChatLocationDetails={getLeadChatLocationDetails}
                  cardClickDetails={cardClickDetails}
                  statusCard={statusCard}
                  leadData={leadData}
                  getLeadChatStatusDetails={getLeadChatStatusDetails}
                  applySort={applySort}
                />
                {noMessages(0)}
                {getLoadMoreBtn()}
              </Grid>
            ) : (
              <MDBox>
                <MDTabPanel
                  value={cardTabValue}
                  index={0}
                  className={!toggleFilter ? "cards-list-tabpanel" : "cards-list-tabpanel-filter"}
                >
                  <Grid
                    container
                    id="open-deals"
                    className="cards-list"
                    style={
                      getLeadsList()?.length <= 0
                        ? { display: "flex", flexDirection: "column" }
                        : {}
                    }
                  >
                    {applySort ? (
                      <MDBox mb={3}>{defaultRenderCards(cardTabValue)}</MDBox>
                    ) : (
                      statusArray?.length > 0 &&
                      statusArray.map((obj: any) => (
                        <MDBox mb={3}>{renderCards(cardTabValue, obj.status)}</MDBox>
                      ))
                    )}
                    {noMessages(cardTabValue)}
                    {getLoadMoreBtn()}
                  </Grid>
                </MDTabPanel>
                <MDTabPanel value={cardTabValue} index={1} className="cards-list-tabpanel">
                  <Grid
                    container
                    id="done-deals"
                    className="cards-list"
                    style={
                      getLeadsList()?.length <= 0
                        ? { display: "flex", flexDirection: "column" }
                        : {}
                    }
                  >
                    {applySort ? (
                      <MDBox mb={3}>{defaultRenderCards(cardTabValue)}</MDBox>
                    ) : (
                      statusArray?.length > 0 &&
                      statusArray.map((obj: any) => (
                        <MDBox mb={3}>{renderCards(cardTabValue, obj.status)}</MDBox>
                      ))
                    )}
                    {noMessages(cardTabValue)}
                    {getLoadMoreBtn()}
                  </Grid>
                </MDTabPanel>
              </MDBox>
            )}
          </MDBox>
        </Grid>
        <Grid
          className={showCardView ? "lead-detail-right" : " lead-detail-right active"}
          item
          xs={12}
          sm={12}
          md={12}
          lg={8}
          position="relative"
        >
          <MDBox sx={{ display: { xs: "block", sm: "block", md: "block", lg: "none" } }}>
            <MDButton
              variant="text"
              color="dark"
              size="small"
              startIcon={
                <Icon fontSize="small" sx={{ mb: "3px" }}>
                  arrow_back
                </Icon>
              }
              sx={{ padding: 0, fontSize: "14px", color: "#227bea", textTransform: "capitalize" }}
              onClick={() => {
                setShowCardView(true);
              }}
            >
              Card List
            </MDButton>
          </MDBox>
          {leadData && Object.keys(leadData)?.length > 0 && (
            <MDBox sx={{ pl: "8px", mt: "5px" }}>
              <MDBox sx={{ display: "flex", justifyContent: "space-between" }}>
                <MDTypography variant="h4" fontWeight="bold" className="deal-title-wrapper">
                  <span className="sms-view-deal-title">{leadData?.deal_name}</span>
                  {leadData?.connect_twillio_phone_number?.twillio_phone_number && (
                    <div style={{ marginLeft: "5px", paddingTop: "4px" }}>
                      <p
                        style={{
                          fontSize: "12px",
                          color: "#aaa",
                          fontWeight: "normal",
                          // marginLeft: "5px",
                        }}
                        className="sms-view-deal-title-message"
                      >
                        {getMessageCountTexts({
                          ...leadData,
                          conversation_count: leaseConversation?.connect_conversations
                            ? leaseConversation?.connect_conversations?.filter(
                                (v: any) => v.conversations_type === "sms"
                              )?.length
                            : 0,
                        })}
                      </p>
                      {leadData.enroll_lead && (
                        <p
                          style={{
                            fontSize: "12px",
                            color: "#aaa",
                            fontWeight: "normal",
                            // marginLeft: "5px",
                          }}
                          className="sms-view-deal-title-message"
                        >
                          {getEnrollNextTime({
                            ...leadData,
                          })}
                        </p>
                      )}
                    </div>
                  )}
                </MDTypography>
                <MDBox sx={{ display: "flex", alignItems: "center" }}>
                  <WebCalling
                    toNumber={leadData?.connect_contact?.phone}
                    fromNumber={leadData?.connect_twillio_phone_number?.twillio_phone_number}
                    message={
                      outboundBtnAttrs(
                        leadData?.connect_contact?.phone,
                        leadData?.connect_twillio_phone_number?.twillio_phone_number,
                        leadData?.connect_twillio_phone_number?.status,
                        leadData?.deal_locked
                      ).message
                    }
                    disabled={
                      outboundBtnAttrs(
                        leadData?.connect_contact?.phone,
                        leadData?.connect_twillio_phone_number?.twillio_phone_number,
                        leadData?.connect_twillio_phone_number?.status,
                        leadData?.deal_locked
                      ).disabled
                    }
                    dealId={leadData?.id}
                    userBy={authUser?.user_id}
                  />

                  {!leadData?.deal_locked &&
                    leadData?.connect_twillio_phone_number?.sms_status &&
                    leadData?.connect_twillio_phone_number?.status === "enabled" && (
                      <>
                        <IconButton
                          aria-label="more"
                          id="long-button"
                          aria-controls={open ? "long-menu" : undefined}
                          aria-expanded={open ? "true" : undefined}
                          aria-haspopup="true"
                          onClick={handleClick}
                        >
                          <MoreVertIcon />
                        </IconButton>
                        <Menu
                          id="long-menu"
                          MenuListProps={{
                            "aria-labelledby": "long-button",
                          }}
                          anchorEl={anchorEl}
                          open={open}
                          onClose={handleClose}
                          PaperProps={{
                            style: {
                              maxHeight: 48 * 4.5,
                              width: "30ch",
                            },
                          }}
                        >
                          <MenuItem
                            disabled={getOptInOut(leadData) || false}
                            onClick={() => {
                              onUpdateEnroll(leadData?.enroll_lead ? false : true);
                              setAnchorEl(null);
                            }}
                          >
                            {leadData?.enroll_lead
                              ? "Unenroll From SMS Campaign"
                              : "Enroll in SMS Campaign"}
                          </MenuItem>
                          {/* <MenuItem
                      onClick={() => {
                        if (leadData.connect_schedule) {
                          setValue(
                            moment.utc(leadData.connect_schedule.start_time).local().format()
                          );
                          setTime(
                            moment.utc(leadData.connect_schedule.start_time).local().format("HH:mm")
                          );
                          setSchedule(true);
                        } else {
                          const data = {
                            userId: authUser.user_id,
                            locationId: leadData.location_id,
                            leadId: leadData.id,
                          };
                          const encrptData = CryptoJS.AES.encrypt(
                            JSON.stringify(data),
                            "123456"
                          ).toString();
                          const encData = CryptoJS.enc.Base64.stringify(
                            CryptoJS.enc.Utf8.parse(encrptData)
                          );
                          setTextMsg(
                            `${window.location.protocol}//${window.location.host}/schedule?data=${encData}`
                          );
                        }
                        setAnchorEl(null);
                      }}
                    >
                      Schedule Meeting
                    </MenuItem> */}
                        </Menu>
                      </>
                    )}
                </MDBox>
              </MDBox>
            </MDBox>
          )}
          <MDBox>
            {leadData && Object.keys(leadData)?.length ? (
              <MDBox>
                <MDBox
                  className="status-container"
                  sx={{ display: "flex", justifyContent: "space-between" }}
                >
                  <MDBox
                    className="status-wrapper"
                    alignItems="center"
                    p={1}
                    maxWidth="350px"
                    display="flex"
                    style={{ flex: "0 0 50%" }}
                  >
                    <MDTypography
                      variant="subtitle2"
                      fontWeight="medium"
                      component="span"
                      sx={{ paddingRight: "10px" }}
                    >
                      Owner:
                    </MDTypography>
                    <UserWithProfilePic
                      placeholder={!dealOwnerId ? "No Owner" : ""}
                      apiName="user-list-by-location"
                      responseKey="users"
                      name="users"
                      slsAPI
                      value={dealOwnerId || null}
                      onChange={onChangeDealOwner}
                      additionaFields={{ location_id: leadData?.location_id }}
                    />
                  </MDBox>
                  <MDBox
                    className="status-wrapper"
                    sx={{ display: "flex" }}
                    alignItems="center"
                    p={1}
                    style={{ flex: "0 0 50%", justifyContent: "flex-end" }}
                  >
                    <MDTypography
                      variant="subtitle2"
                      fontWeight="medium"
                      component="span"
                      sx={{ paddingRight: "10px" }}
                    >
                      Status:
                    </MDTypography>
                    <CustomSelect
                      onChange={onChangeStatusDropdown}
                      name="status"
                      customWidth="200px"
                      placeholder="Select Status"
                      value={valueDropdownStatus}
                      id="statusLeadInfoHeader"
                      options={statusArray.map((obj: any) => {
                        const tempObj = { ...obj };
                        tempObj.label = tempObj.status;
                        return tempObj;
                      })}
                      isSearchable={false}
                    />
                  </MDBox>
                </MDBox>
                <MDBox className="location-wrapper" fontWeight="regular" p={1}>
                  <MDTypography
                    variant="subtitle2"
                    fontWeight="medium"
                    component="span"
                    sx={{ paddingRight: "10px" }}
                    className="location-name-label"
                  >
                    Location(s):
                  </MDTypography>
                  <Tooltip title={leadData?.location?.name} arrow placement="bottom-start">
                    <MDTypography
                      variant="body2"
                      component="span"
                      className="sms-view-location location-name"
                    >
                      {leadData?.location?.name}
                    </MDTypography>
                  </Tooltip>
                </MDBox>

                <Tabs className="lead-tabs" value={tabValue} onChange={changeTab}>
                  <Tab
                    id="messages"
                    className="message-view-tab"
                    label={
                      <MDBox py={0.5} px={2} color="inherit">
                        Messages
                      </MDBox>
                    }
                  />
                  <Tab
                    id="activity"
                    className="message-view-tab"
                    label={
                      <MDBox py={0.5} px={2} color="inherit">
                        Activity
                      </MDBox>
                    }
                  />
                  <Tab
                    id="info"
                    className="message-view-tab"
                    label={
                      <MDBox py={0.5} px={2} color="inherit">
                        Info
                      </MDBox>
                    }
                  />
                  <Tab
                    id="Notes"
                    className="message-view-tab"
                    label={
                      <MDBox py={0.5} px={2} color="inherit">
                        Notes
                      </MDBox>
                    }
                  />
                </Tabs>
                <Grid container className="tab-section">
                  <MDTabPanel value={tabValue} index={0} className="message-container">
                    {(!leadData?.connect_twillio_sms_request?.status ||
                      deactiveStatus.includes(leadData?.connect_twillio_sms_request?.status)) && (
                      <SmsRequestComponent locationData={leadData} section="deal" />
                    )}
                    {leadData?.connect_twillio_sms_request &&
                      (leadData?.connect_twillio_sms_request?.status === "active" ||
                        leadData?.connect_twillio_sms_request?.status ===
                          "removed_from_phone_number") && (
                        <>
                          <Grid
                            item
                            xs={12}
                            sm={12}
                            md={12}
                            lg={12}
                            className="messages"
                            id="msg"
                            ref={bottomRef}
                            onScroll={() => {
                              const scrollHeight = document.getElementById("msg").scrollTop;
                              if (scrollHeight === 0) {
                                if (
                                  leaseConversation.meta.current_page + 1 !==
                                    leaseConversation.meta.total_pages &&
                                  conversation.length > 0
                                ) {
                                  dispatch(
                                    getLeadConversation({
                                      leadId: leadDetails?.data?.id,
                                      page: leaseConversation.meta.current_page + 1,
                                      append: true,
                                      leaseConversation,
                                      contact_id: leadDetails?.data?.connect_contact?.id,
                                      conversations_type: "sms",
                                      location_ids: getLocationIdsForConversation(
                                        leadDetails?.data?.location?.id,
                                        leadDetails?.data?.connect_twillio_phone_number
                                          ?.location_ids
                                      ),
                                    })
                                  );
                                  setLoadmoreMsg(true);
                                }
                              }
                            }}
                          >
                            {conversation.length > 0 &&
                              conversation.map((val: any) => (
                                <div
                                  key={val?.id}
                                  className={
                                    !val.inbound || val.is_broadcast_sms
                                      ? val.conversations_type === "notes"
                                        ? "end-notes-chatbox message-row"
                                        : "end-message-chatbox message-row"
                                      : "start-message-chatbox message-row"
                                  }
                                >
                                  <div className="message-content">
                                    <div className="message-text">
                                      {val?.content && val?.content?.length > 1000 ? (
                                        <>
                                          <MDTypography variant="button" fontWeight="regular">
                                            {readMore === val?.id
                                              ? val?.content
                                              : val?.content.slice(0, 1000)}
                                          </MDTypography>
                                          {readMore === val?.id ? (
                                            <MDTypography
                                              variant="button"
                                              className="cursor-pointer font-bold"
                                              onClick={() => readMoreText(null)}
                                            >
                                              ...Show less
                                            </MDTypography>
                                          ) : (
                                            <MDTypography
                                              variant="button"
                                              className="cursor-pointer font-bold"
                                              onClick={() => readMoreText(val?.id)}
                                            >
                                              ...Read more
                                            </MDTypography>
                                          )}
                                        </>
                                      ) : (
                                        <MDTypography variant="button" fontWeight="regular">
                                          {val?.content}
                                        </MDTypography>
                                      )}
                                      {displayFiles(val?.files || [])}
                                      <MDBox className="message-time">
                                        {!val?.inbound &&
                                          (val?.twilio_sms_status === "failed" ||
                                            val?.twilio_sms_status === "undelivered") && (
                                            <MDBox display="flex" alignItems="center">
                                              {val?.twilio_sms_id === twilioSmsId ? (
                                                <CircularProgress
                                                  size={15}
                                                  sx={{ color: "#1a73e8" }}
                                                />
                                              ) : (
                                                <MDTypography
                                                  variant="b"
                                                  color="info"
                                                  fontSize="12px"
                                                  type="botton"
                                                  marginRight="4px"
                                                  sx={{ lineHeight: "1.25", cursor: "pointer" }}
                                                  onClick={() => reSendMessage(val?.twilio_sms_id)}
                                                >
                                                  Resend
                                                </MDTypography>
                                              )}
                                            </MDBox>
                                          )}
                                        <MDTypography
                                          variant="caption"
                                          fontWeight="regular"
                                          className="message-time"
                                        >
                                          {moment(val.created_at).format("MM/DD/YYYY h:mm a")}
                                        </MDTypography>
                                      </MDBox>
                                    </div>
                                    {!val?.inbound &&
                                      (val?.twilio_sms_status === "failed" ||
                                        val?.twilio_sms_status === "undelivered") && (
                                        <Tooltip
                                          title={
                                            val?.twilio_error_message || "Failed to send message"
                                          }
                                          placement="top"
                                        >
                                          <WarningAmber color="error" />
                                        </Tooltip>
                                      )}
                                  </div>
                                </div>
                              ))}
                            {!conversation.length && (
                              <Grid
                                container
                                spacing={0}
                                direction="column"
                                alignItems="center"
                                justifyContent="center"
                                style={{ minHeight: "42vh" }}
                              >
                                <MDTypography variant="body2" sx={{ mb: 1 }}>
                                  No Message
                                </MDTypography>
                              </Grid>
                            )}
                          </Grid>
                          <Grid item xs={12} sm={12} md={12} lg={12} sx={{ padding: "0px 12px" }}>
                            <Tabs
                              className="lead-tabs-msg"
                              value={msgtabValue}
                              onChange={changeMsgTab}
                              sx={{ width: "100%" }}
                            >
                              <Tab
                                id="reply"
                                className="message-view-tab"
                                sx={{ justifyContent: "flex-start" }}
                                label={
                                  <MDBox color={`${numberRemoved ? "gray" : "inherit"}`}>
                                    Sending To:
                                    {` ${
                                      (leadData?.connect_contact?.phone &&
                                        formattedPhone(leadData.connect_contact?.phone)) ||
                                      "inactive"
                                    }`}
                                  </MDBox>
                                }
                              />
                            </Tabs>
                            <MDTabPanel value={msgtabValue} index={0} className="msg-input">
                              <Grid container>
                                <Grid item xs={12} sm={12} md={12} lg={12} position="relative">
                                  <MDInput
                                    multiline
                                    className="msg-input-box"
                                    rows={3}
                                    sx={{ width: "100%", height: "100%" }}
                                    placeholder={
                                      numberRemoved
                                        ? "SMS is no longer active for this location."
                                        : leadData?.deal_locked
                                        ? dealLockText
                                        : "Type Message..."
                                    }
                                    value={textMsg || ""}
                                    id="txt-msg-input-box"
                                    onKeyPress={handleKeyPress}
                                    onChange={(e: any) => handleChangeTextMessage(e)}
                                    disabled={numberRemoved || leadData?.deal_locked ? true : false}
                                  />
                                  {!numberRemoved && !leadData?.deal_locked && (
                                    <MDBox
                                      className={`message-text-counts ${
                                        textMsg?.length > 1500 ? " limit" : ""
                                      }`}
                                    >
                                      {textMsg?.length}/1500
                                    </MDBox>
                                  )}
                                </Grid>
                                <Grid item xs={12} sm={12} md={12} lg={12} className="msg-panel">
                                  {getOptInOut(leadData) && (
                                    <MDTypography className="sms_stop_text">
                                      This person has opted-out of text communication. If you need
                                      to contact them please use email or phone calls
                                    </MDTypography>
                                  )}
                                  {!numberRemoved &&
                                    !leadData?.deal_locked &&
                                    leadData?.connect_twillio_phone_number?.status === "enabled" &&
                                    !getOptInOut(leadData) && (
                                      <MDTypography
                                        marginRight="auto"
                                        variant="body2"
                                        fontSize="12px"
                                        color="secondary"
                                      >
                                        This message will be sent from
                                        {` ${formattedPhone(
                                          leadData?.connect_twillio_phone_number
                                            ?.twillio_phone_number
                                        )}`}
                                      </MDTypography>
                                    )}
                                  {attachments?.length > 0 && (
                                    <MDBox mt={2} sx={{ display: "flex", alignItems: "center" }}>
                                      {displayFilesWithDeleteBtn(attachments)}
                                    </MDBox>
                                  )}
                                  <MDIconButton
                                    tooltipName="SMS Templates"
                                    type="button"
                                    aria-label="SMS Templates"
                                    sx={{ color: "#0b8cfb" }}
                                    onClick={handleClickTemplatePopup}
                                    disabled={numberRemoved || leadData?.deal_locked ? true : false}
                                  >
                                    <Icon fontSize="small">post_add</Icon>
                                  </MDIconButton>
                                  {smsTemplatePopupAnchorEl && (
                                    <CustomSMSTemplateDropDown
                                      setSMStext={setTextMsg}
                                      leadInfo={leadData}
                                      anchorEl={smsTemplatePopupAnchorEl}
                                      handleClose={handleTemplatePopupClose}
                                    />
                                  )}
                                  <MDIconButton
                                    tooltipName="Attach file"
                                    type="button"
                                    aria-label="Attach file"
                                    sx={{ color: "#0b8cfb" }}
                                    onClick={() => setIsFileUpload("file")}
                                    disabled={numberRemoved || leadData?.deal_locked ? true : false}
                                  >
                                    <Icon fontSize="small">description_icon</Icon>
                                  </MDIconButton>
                                  {/* <MDIconButton
                                    tooltipName="Attach photo"
                                    type="button"
                                    aria-label="Attach photo"
                                    sx={{ color: "#0b8cfb" }}
                                    onClick={() => setIsFileUpload("image")}
                                    disabled={numberRemoved || leadData?.deal_locked ? true : false}
                                  >
                                    <Icon fontSize="small">image_icon</Icon>
                                  </MDIconButton> */}
                                  {/* <MDIconButton
                                    tooltipName="Send Later"
                                    type="button"
                                    aria-label="Send Later"
                                    sx={{ color: "#0b8cfb" }}
                                    disabled={numberRemoved || false}
                                  >
                                    <Icon fontSize="small">access_time_icon</Icon>
                                  </MDIconButton> */}
                                  {!sendSMSLoader && (
                                    <MDIconButton
                                      tooltipName={getTooltipTxt()}
                                      type="button"
                                      disabled={
                                        leadData?.deal_locked ||
                                        numberRemoved ||
                                        leadData?.connect_twillio_phone_number?.status ===
                                          "disabled" ||
                                        !leadData?.connect_twillio_phone_number?.sms_status ||
                                        textMsg?.length > 1500
                                          ? true
                                          : false
                                      }
                                      sx={{ color: "#0b8cfb" }}
                                      aria-label="send"
                                      onClick={checkEnrollDeal}
                                    >
                                      <Icon fontSize="small">send</Icon>
                                    </MDIconButton>
                                  )}
                                  {sendSMSLoader && (
                                    <MDIconButton
                                      type="button"
                                      disabled={
                                        leadData?.deal_locked ||
                                        numberRemoved ||
                                        leadData?.connect_twillio_phone_number?.status ===
                                          "disabled" ||
                                        !leadData?.connect_twillio_phone_number?.sms_status ||
                                        textMsg?.length > 1500
                                          ? true
                                          : false
                                      }
                                      sx={{ color: "#0b8cfb" }}
                                      aria-label="send"
                                    >
                                      <CircularProgress size={15} sx={{ color: "#1a73e8" }} />
                                    </MDIconButton>
                                  )}

                                  {isFileUpload && (
                                    <MDDialog {...attchFileDialogProps}>
                                      <CustomDropZone
                                        multipleUpload
                                        maxFiles={3}
                                        maxSize={1048576 * 3}
                                        acceptedFiles={{
                                          "": constants.twillioSupportedMimeTypes,
                                        }}
                                        isFileTypeError
                                        onDrop={onAttachFile}
                                      >
                                        Drop file here, or Browse
                                      </CustomDropZone>
                                      {sendSMSLoader && (
                                        <MDIconButton
                                          type="button"
                                          disabled={
                                            leadData?.deal_locked ||
                                            numberRemoved ||
                                            leadData?.connect_twillio_phone_number?.status ===
                                              "disabled" ||
                                            !leadData?.connect_twillio_phone_number?.sms_status ||
                                            textMsg?.length > 1500
                                              ? true
                                              : false
                                          }
                                          sx={{ color: "#0b8cfb" }}
                                          aria-label="send"
                                        >
                                          <CircularProgress size={15} sx={{ color: "#1a73e8" }} />
                                        </MDIconButton>
                                      )}
                                      {attachments?.length > 0 && (
                                        <MDBox
                                          mt={2}
                                          style={{ display: "flex", alignItems: "center" }}
                                        >
                                          {displayFilesWithDeleteBtn(attachments)}
                                        </MDBox>
                                      )}
                                    </MDDialog>
                                  )}
                                </Grid>
                              </Grid>
                            </MDTabPanel>
                          </Grid>
                        </>
                      )}
                  </MDTabPanel>
                  <MDTabPanel value={tabValue} index={1} className="activity-container">
                    <Grid container>
                      <Grid item xs={12} sm={12} md={12} lg={12} sx={{ overflow: "auto" }}>
                        {renderActivity()}
                        <MDBox display="flex" justifyContent="center">
                          {dealActivity?.length > 0 && totalPages > page + 1 && (
                            <MDButton onClick={loadMoreActivities}>Load more...</MDButton>
                          )}
                        </MDBox>
                      </Grid>
                    </Grid>
                  </MDTabPanel>
                  <MDTabPanel value={tabValue} index={2} className="activity-container">
                    <LeadInfo data={leadData} getAllLeads={getAllLeads} />
                  </MDTabPanel>
                  <MDTabPanel value={tabValue} index={3} className="message-container">
                    <NoteTab />
                  </MDTabPanel>
                </Grid>
              </MDBox>
            ) : (
              <MDBox
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  position: "absolute",
                  left: "50%",
                  top: "50%",
                  transform: "translate(-50%, -50%)",
                }}
              >
                <MDTypography>No Message Selected</MDTypography>
              </MDBox>
            )}
          </MDBox>
        </Grid>
        <MDDialog {...scheduleDialogProps}>
          <Grid container className="appointment-container">
            <Grid item md={3} className="appointment-section">
              <MDTypography>{`${authUser?.first_name} ${authUser?.last_name}`}</MDTypography>
              <MDTypography variant="body2">{authUser?.login_email}</MDTypography>
              <MDTypography variant="body2">{eventLength} minutes</MDTypography>
            </Grid>
            <Grid item md={6} className="appointment-section">
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <StaticDatePicker
                  displayStaticWrapperAs="desktop"
                  orientation="landscape"
                  openTo="day"
                  value={value}
                  minDate={new Date()}
                  onChange={(newValue: any) => {
                    setValue(newValue);
                  }}
                  renderInput={(params) => <MDInput {...params} />}
                />
              </LocalizationProvider>
            </Grid>
            <Grid item md={3} className="appointment-section">
              <Grid item md={12} sx={{ textAlign: "left", marginLeft: "15px" }}>
                {value && moment(value.$d || value).format("ddd, MMM DD")}
              </Grid>
              <Grid item md={12} className="time-slots">
                {renderTimeSlots()}
              </Grid>
            </Grid>
          </Grid>
        </MDDialog>
        <MDDialog {...deleteUnenrollDialogProps}>
          <MDTypography variant="h6" fontWeight="medium">
            {Messages.INFO.SURE_TO_ENROLLED_DEAL}
          </MDTypography>
        </MDDialog>
      </Grid>
    </DashboardLayout>
  );
}

export default Index;
