import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import CustomPhoneInput from "components/CustomPhoneInput";
import { adminSuperAdmin } from "helper/services";
import MESSAGES from "helper/messages";

function Contact(props: any): JSX.Element {
  const { locationDetail, onChangeField, errors } = props;
  return (
    <Card id="contact">
      <MDBox p={3}>
        <MDTypography variant="h5">Contact</MDTypography>
      </MDBox>
      <MDBox pb={3} px={3}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6} md={6} lg={6}>
            <MDInput
              label="General Contact Name"
              variant="standard"
              fullWidth
              name="general_contact_name"
              placeholder="General Contact Name"
              onChange={onChangeField}
              value={locationDetail?.general_contact_name || ""}
              error={errors && errors.general_contact_name ? true : false}
              helperText={errors && errors.general_contact_name}
              infoText={MESSAGES.INFO.OPTIONAL_LENGTH_255}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={6} lg={6}>
            <MDInput
              label="Email Address For Inquiries"
              variant="standard"
              fullWidth
              name="email_address_for_inquiries"
              placeholder="Email Address For Inquiries"
              onChange={onChangeField}
              value={locationDetail?.email_address_for_inquiries || ""}
              error={errors && errors.email_address_for_inquiries ? true : false}
              helperText={errors && errors.email_address_for_inquiries}
              infoText={MESSAGES.INFO.OPTIONAL_LENGTH_255}
            />
          </Grid>

          <Grid item xs={12} sm={6} md={6} lg={6}>
            <MDInput
              label="Email Address For Reports"
              variant="standard"
              fullWidth
              name="email_address_for_reports"
              placeholder="Email Address For Reports"
              onChange={onChangeField}
              value={locationDetail?.email_address_for_reports || ""}
              error={errors && errors.email_address_for_reports ? true : false}
              helperText={errors && errors.email_address_for_reports}
              infoText={MESSAGES.INFO.OPTIONAL_LENGTH_255}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={6} lg={6}>
            <MDInput
              label="Email Address For Hubspot"
              variant="standard"
              fullWidth
              name="email_address_for_hubspot"
              placeholder="Email Address For Hubspot"
              onChange={onChangeField}
              value={locationDetail?.email_address_for_hubspot || ""}
              infoText={MESSAGES.INFO.HUBSPOT_EMAIL}
              error={errors && errors.email_address_for_hubspot ? true : false}
              helperText={errors && errors.email_address_for_hubspot}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={6} lg={6}>
            <MDInput
              label="Email Addresses For Stylist Website Approvals"
              variant="standard"
              fullWidth
              name="emails_for_stylist_website_approvals"
              placeholder="Email Addresses For Stylist Website Approvals"
              onChange={onChangeField}
              value={locationDetail?.emails_for_stylist_website_approvals || ""}
              infoText={MESSAGES.INFO.EMAIL_ADDRESS_FOR_STYLIST}
              error={errors && errors.emails_for_stylist_website_approvals ? true : false}
              helperText={errors && errors.emails_for_stylist_website_approvals}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={6} lg={6}>
            <MDInput
              label="Phone Number"
              variant="standard"
              fullWidth
              disabled={!adminSuperAdmin()}
              name="phone_number"
              placeholder="(000) 000-0000"
              InputProps={{
                inputComponent: CustomPhoneInput as any,
              }}
              onChange={onChangeField}
              value={locationDetail?.phone_number || ""}
              error={errors && errors.phone_number ? true : false}
              helperText={errors && errors.phone_number}
              infoText={MESSAGES.INFO.OPTIONAL_LENGTH_255}
            />
          </Grid>
        </Grid>
      </MDBox>
    </Card>
  );
}

export default Contact;
