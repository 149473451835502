import { useState } from "react";
import { Card, Tabs, Tab } from "@mui/material";

import MDBox from "components/MDBox";
import MDTabPanel from "components/MDTabPanel";

import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import RolesList from "./roles";
import MenusList from "./menus";

function Index(): JSX.Element {
  const [tabValue, setTabValue] = useState<number>(0);
  const changeTab = (event: any, newValue: any) => {
    setTabValue(newValue);
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pb={3}>
        <Card>
          <MDBox minWidth={{ xs: "100%", md: "25rem" }} mx="auto" mt={2} mb={2}>
            <Tabs className="location-tabs" value={tabValue} onChange={changeTab}>
              <Tab
                id="roles"
                label={
                  <MDBox py={0.5} px={2} color="inherit">
                    All Roles
                  </MDBox>
                }
              />
              <Tab
                id="menus"
                label={
                  <MDBox py={0.5} px={2} color="inherit">
                    All Menus
                  </MDBox>
                }
              />
            </Tabs>
          </MDBox>
          <MDTabPanel value={tabValue} index={0}>
            <RolesList />
          </MDTabPanel>
          <MDTabPanel value={tabValue} index={1}>
            <MenusList />
          </MDTabPanel>
        </Card>
      </MDBox>
    </DashboardLayout>
  );
}

export default Index;
