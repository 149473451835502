function Footer(): JSX.Element {
  return <div> </div>;
}

// Declaring default props for Footer
Footer.defaultProps = {
  light: false,
};

export default Footer;
