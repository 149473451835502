import React, { useEffect, useState, useRef } from "react";
import { useAppDispatch, useAppSelector } from "store/store";
import { arrayMoveImmutable } from "array-move";
import { Grid, AppBar, Tabs, Tab } from "@mui/material";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import MDBox from "components/MDBox";
import MDTabPanel from "components/MDTabPanel";
import General from "layouts/pages/locations/location-details/general";
import Website from "layouts/pages/locations/location-details/website";
import SolaPros from "layouts/pages/locations/location-details/sola-pros";
import LocationHistory from "layouts/pages/locations/location-details/location-history";
import LocationFacilities from "layouts/pages/locations/location-details/location-facilities";
import LocationConversation from "layouts/pages/locations/location-details/location-conversations";

import RentManager from "layouts/pages/locations/location-details/rent-manager";
import Crm from "layouts/pages/locations/location-details/crm";
import Users from "layouts/pages/locations/location-details/users";
import ManageUnits from "layouts/pages/locations/location-details/rent-manager/manageUnits";
import CmsLocationAddUpdate from "layouts/pages/locations/cms-locations/addUpdate";
import CustomBackButton from "components/CustomBackButton";
import { useLocation } from "react-router-dom";
import {
  getLocationTabs,
  userRoles,
  getShortName,
  getAccessPermission,
  // compressImage,
  convertImageToWebp,
} from "helper/services";
import { requestRMTwillioNumber } from "store/thunk/repairMaintenance";
import { getLocation } from "store/thunk/locationThunk";
import MDTypography from "components/MDTypography";
import moment from "moment";

export type ImagesArray = {
  url: string;
  alt_text: string;
  name: string;
  content_type: string;
  file_name: string;
  file_size: number;
  updated_at: string;
  index?: number;
  id?: number;
  order?: number;
};

export type WebsiteAttr = {
  editPermission: any;
  onChangeField: any;
  onChangeRadio: any;
  onChangeAutoComplete: any;
  onChangeTimeField: any;
  onChangeImage: any;
  onChangeFloorPlanImage: any;
  onImageRemove: any;
  onImageFloorPlanRemove: any;
  setLocationDetail: any;
  onChangeDescription: any;
  onChangeEditor: any;
  oldLocationDetail: any;
  locationDetail: any;
  floorPlanImage: any;
  imagePreview: any;
  userData: any;
  onSortEnd: any;
  imagesArray: ImagesArray[];
  setImagesArray: any;
  updateImageOrder: any;
  setUpdateImageOrder: any;
  locationOwners: any;
  getAccessTypePermission: any;
  rmDetails: any;
  setRmDetails: any;
  onCheckboxChange: any;
  errors?: any;
  setErrors?: React.Dispatch<React.SetStateAction<any>>;
  onSave?: () => void;
  onUpdateImageOrder?: () => void;
  setNewImages?: React.Dispatch<React.SetStateAction<any>>;
  newImages?: any;
};

function Index(): JSX.Element {
  const childRef = useRef(null);
  const dispatch = useAppDispatch();
  const locationState: any = useLocation();
  const { connectLocation } = useAppSelector((state) => state.locationSlice);
  const { userData } = useAppSelector((state) => state.authReducer);
  const [tabValue, setTabValue] = useState<number>(0);
  const [historyState, setHistoryState] = useState<{}>({});
  const [pageAccess, setPageAccess] = useState<any>([]);
  const [editPermission, setEditPermission] = useState<any>(true);
  const [locationDetail, setLocationDetail] = useState<any>({});
  const [floorPlanImage, setFloorPlanImage] = useState<any>("");
  const [oldLocationDetail, setOldLocationDetail] = useState<any>({});
  const [imagePreview, setImagePreview] = useState<any>([]);
  const [imagesArray, setImagesArray] = useState<ImagesArray[]>([]);
  const [updateImageOrder, setUpdateImageOrder] = useState(false);
  const [locationOwners, setLocationOwners] = useState([]);
  const [newImages, setNewImages] = useState([]);

  useEffect(() => {
    const urlParams = new URLSearchParams(locationState.search);
    const urlLocationId = urlParams.get("id") || null;
    const envelopeId = urlParams.get("envelopeId") || null;

    if (locationState?.state || urlLocationId) {
      dispatch(getLocation({ id: locationState?.state?.id || urlLocationId, cmsLocation: true }));
      // dispatch(
      //   getTemplates({
      //     template_alias: `crm-initial-response-email,crm-initial-response-sms,crm-follow-up-email-1,crm-follow-up-email-2,crm-follow-up-email-3,crm-follow-up-email-4,crm-follow-up-email-5,crm-follow-up-email-6,crm-follow-up-email-7,crm-follow-up-email-8,crm-follow-up-email-9,crm-follow-up-email-10,crm-follow-up-sms-1,crm-follow-up-sms-2,crm-follow-up-sms-3,crm-follow-up-sms-4,crm-follow-up-sms-5,crm-follow-up-sms-6,crm-follow-up-sms-7,crm-follow-up-sms-8,crm-follow-up-sms-9,crm-follow-up-sms-10`,
      //   })
      // );
      setHistoryState(locationState.state);
      if (envelopeId) {
        setTabValue(Number(5));
        setHistoryState({ defaultTab: "5", id: urlLocationId, prevPath: "/locations" });
      }
    } else {
      setHistoryState({});
    }
    if (!locationState?.state?.id) {
      // navigate(`/locations`);
    }
    if (locationState?.state?.defaultTab) {
      setTabValue(Number(locationState?.state?.defaultTab) || 0);
    }
  }, [locationState]);

  useEffect(() => {
    if (connectLocation) {
      const owners: any = connectLocation?.owners?.map((obj: any) => obj.connect_user);
      setLocationOwners(owners);
      const tabAccess = getLocationTabs(locationState?.state?.id, owners);
      if (tabAccess?.length > 0) {
        setPageAccess(tabAccess);
      }
      if (userData?.role !== userRoles.SYSTEM_ADMIN && userData?.role !== userRoles.ADMIN) {
        setEditPermission(false);
      }
    }
  }, [connectLocation]);

  const onchangeDropEndCallSaveOrderFunction = () => {
    childRef.current.callChildOnSave();
  };

  const onSortEnd = ({ oldIndex, newIndex }: any) => {
    const tmpArray = JSON.parse(JSON.stringify(imagesArray));
    Object.assign(tmpArray[newIndex], { order: tmpArray[oldIndex].index });
    Object.assign(tmpArray[oldIndex], { order: tmpArray[newIndex].index });
    const newImagesOrder: ImagesArray[] = arrayMoveImmutable(tmpArray, oldIndex, newIndex);
    let imagesValues: any = {};
    newImagesOrder.map((imgItem, i: number) => {
      imagesValues = {
        ...imagesValues,
        [`image_${i + 1}_url`]: imgItem.url,
        [`image_${i + 1}_alt_text`]: "",
        [`image_${i + 1}_name`]: imgItem.name,
        [`image_${i + 1}_content_type`]: imgItem.content_type,
        [`image_${i + 1}_file_name`]: imgItem.file_name,
        [`image_${i + 1}_file_size`]: imgItem.file_size,
        [`image_${i + 1}_updated_at`]: imgItem.updated_at,
      };
      return imagesValues;
    });
    const emptyArr = [...Array(20)];
    emptyArr.map((item, i) => {
      imagesValues = {
        ...imagesValues,
        [`image_${i + 1}_url`]: imagesValues[`image_${i + 1}_url`]
          ? imagesValues[`image_${i + 1}_url`]
          : null,
        [`image_${i + 1}_alt_text`]: "",
        [`image_${i + 1}_name`]: imagesValues[`image_${i + 1}_name`]
          ? imagesValues[`image_${i + 1}_name`]
          : null,
        [`image_${i + 1}_content_type`]: imagesValues[`image_${i + 1}_content_type`]
          ? imagesValues[`image_${i + 1}_content_type`]
          : null,
        [`image_${i + 1}_file_name`]: imagesValues[`image_${i + 1}_file_name`]
          ? imagesValues[`image_${i + 1}_file_name`]
          : null,
        [`image_${i + 1}_file_size`]: imagesValues[`image_${i + 1}_file_size`]
          ? imagesValues[`image_${i + 1}_file_size`]
          : null,
        [`image_${i + 1}_updated_at`]: imagesValues[`image_${i + 1}_updated_at`]
          ? imagesValues[`image_${i + 1}_updated_at`]
          : null,
      };
      return imagesValues;
    });
    setNewImages({
      ...imagesValues,
    });
    setImagesArray(newImagesOrder);
    setUpdateImageOrder(true);
    onchangeDropEndCallSaveOrderFunction();
  };

  useEffect(() => {
    if (connectLocation) {
      const tempDetails: any = JSON.parse(JSON.stringify(connectLocation));

      let amenitiesIds: any[];
      if (tempDetails?.all_amenities) {
        amenitiesIds = tempDetails?.all_amenities
          ?.filter((xObj: any) => xObj.amenity_type)
          .map((obj: any) => obj?.id);
      }

      const locationAmenities = tempDetails?.location?.connect_location_amenities || [];
      if (locationAmenities?.length) {
        amenitiesIds = locationAmenities?.map((obj: any) => Number(obj?.amenity_id));
      }
      tempDetails.amenities = amenitiesIds;

      if (connectLocation?.location && connectLocation?.location?.msas) {
        tempDetails.msas = connectLocation?.location?.msas;
      }
      if (connectLocation?.walkins_end_of_day) {
        tempDetails.walkins_end_of_day = moment(
          `${moment().format("yyyy-MM-DD")} ${connectLocation?.walkins_end_of_day}`
        );
      }
      if (connectLocation?.country_id) {
        tempDetails.countries = {
          id: connectLocation?.country_id,
          name: connectLocation?.country_name ? connectLocation?.country_name : "",
        };
      }

      if (connectLocation?.association.length > 0) {
        tempDetails.business_name = connectLocation?.association[0]?.association_name;
        tempDetails.incorporation_state = connectLocation?.association[0]?.id;
        tempDetails.notice_address = "notice_address";
      }

      if (connectLocation?.security_deposit_amount_type === null) {
        tempDetails.security_deposit_amount_type = "flat";
      }
      if (connectLocation?.insurance_charge_frequency === null) {
        tempDetails.insurance_charge_frequency = "weekly";
      }
      if (connectLocation?.annual_price_increase_type === null) {
        tempDetails.annual_price_increase_type = "flat";
      }
      if (!connectLocation?.annual_price_increase === null) {
        tempDetails.annual_price_increase = "3%";
      }
      if (!connectLocation?.lease_term === null) {
        tempDetails.lease_term = "24";
      }

      tempDetails.notice_address = `${connectLocation?.address_1} ${connectLocation?.address_2}, ${connectLocation?.state}`;
      tempDetails.full_name = `${connectLocation?.store_id} - ${connectLocation?.name}`;
      tempDetails.short_name = `${getShortName(connectLocation?.name)}`;

      const tempImagesArray: ImagesArray[] = [];
      for (let i = 0; i < 20; i++) {
        if (connectLocation?.[`image_${i + 1}_url`]) {
          tempImagesArray.push({
            index: i + 1,
            id: i + 1,
            url: connectLocation?.[`image_${i + 1}_url`],
            alt_text: connectLocation?.[`image_${i + 1}_alt_text`],
            name: `image_${i + 1}`,
            content_type: connectLocation?.[`image_${i + 1}_content_type`],
            file_name: connectLocation?.[`image_${i + 1}_file_name`],
            file_size: connectLocation?.[`image_${i + 1}_file_size`],
            updated_at: connectLocation?.[`image_${i + 1}_updated_at`],
          });
        }
      }

      setImagesArray(tempImagesArray);
      setOldLocationDetail(tempDetails);
      setLocationDetail(tempDetails);
      setFloorPlanImage(connectLocation?.floorplan_image_url);
    }
  }, [connectLocation]);

  const onChangeField = (event: any) => {
    setLocationDetail({ ...locationDetail, [event.target.name]: event.target.value });
  };

  const onChangeRadio = (event: any) => {
    setLocationDetail({ ...locationDetail, [event.target.name]: event.target.checked });
  };

  const onChangeAutoComplete = (name: string, value: any) => {
    setLocationDetail({ ...locationDetail, [name]: value });
  };

  const onChangeTimeField = (value: any) => {
    setLocationDetail({
      ...locationDetail,
      walkins_end_of_day: value ? moment(value) : "",
    });
  };

  const onChangeDescription = (val: any) => {
    setLocationDetail({ ...locationDetail, description: val });
  };

  const onChangeFloorPlanImage = async (files: any, type: any) => {
    if (files[0]) {
      const file = await convertImageToWebp(files[0], "floor_plan", dispatch);
      const reader = new FileReader();
      reader.onload = function (e) {
        setFloorPlanImage(e.target.result);
        setLocationDetail({ ...locationDetail, [type]: file, delete_floor_plan_image: false });
      };
      reader.readAsDataURL(file);
    }
  };

  const onImageFloorPlanRemove = async (e: any) => {
    e.stopPropagation();
    setFloorPlanImage("");
    setLocationDetail({
      ...locationDetail,
      floorplan_image_url: null,
      delete_floor_plan_image: true,
    });
  };

  const onChangeImage = async (files: any, name: any, key: any) => {
    if (files[0]) {
      const file = await convertImageToWebp(files[0], "location", dispatch);
      const reader = new FileReader();
      reader.onload = function (e) {
        if (key) {
          const tempImgPreview = JSON.parse(JSON.stringify(imagePreview));
          tempImgPreview[key] = e.target.result;
          setImagePreview(tempImgPreview);
        }
      };
      setLocationDetail({ ...locationDetail, [name]: file, [`delete_${name}`]: false });
      reader.readAsDataURL(file);
    }
  };

  const onImageRemove = async (e: any, name: string, key: string) => {
    e.stopPropagation();
    if (key) {
      const tempImgPreview = JSON.parse(JSON.stringify(imagePreview));
      tempImgPreview[key] = null;
      setImagePreview(tempImgPreview);
    }
    setLocationDetail({ ...locationDetail, [name]: null, [`delete_${name}`]: true });
  };

  const changeTab = (e: any, value: any) => {
    setTabValue(value);
  };

  const onChangeEditor = (value: any, name: string) => {
    setLocationDetail({ ...locationDetail, [name]: value });
  };

  const onCheckboxChange = (event: any) => {
    setLocationDetail({ ...locationDetail, [event.target.name]: event.target.checked });
  };

  const getAccessTypePermission = (sectionAlias: string, accessType: string) =>
    !getAccessPermission(
      locationDetail?.id,
      "locations",
      "locations_location",
      sectionAlias,
      accessType,
      locationOwners
    );

  const attr: WebsiteAttr = {
    editPermission,
    onChangeField,
    onChangeRadio,
    onChangeAutoComplete,
    onChangeTimeField,
    onChangeImage,
    onChangeFloorPlanImage,
    onImageRemove,
    onImageFloorPlanRemove,
    setLocationDetail,
    onChangeDescription,
    onChangeEditor,
    oldLocationDetail,
    locationDetail,
    floorPlanImage,
    imagePreview,
    userData,
    onSortEnd,
    imagesArray,
    setImagesArray,
    updateImageOrder,
    setUpdateImageOrder,
    locationOwners,
    getAccessTypePermission,
    rmDetails: locationDetail,
    setRmDetails: setLocationDetail,
    onCheckboxChange,
    setNewImages,
    newImages,
  };

  const onRMRequestSMS = () => {
    dispatch(
      requestRMTwillioNumber({
        location_id: locationDetail?.id,
        requested_user_id: userData?.user_id,
      })
    );
  };

  const conversationAttr = {
    onRMRequestSMS,
  };

  const getComponent = (alias: string) => {
    switch (alias) {
      case "location_general":
        return <General {...attr} />;
      case "location_website":
        return <Website {...attr} ref={childRef} />;
      case "location_solapros":
        return (
          <SolaPros
            locationId={connectLocation?.id}
            getAccessTypePermission={getAccessTypePermission}
          />
        );
      case "location_history":
        return (
          <LocationHistory
            locationId={connectLocation?.id}
            getAccessTypePermission={getAccessTypePermission}
          />
        );
      case "location_crm":
        return <Crm getAccessTypePermission={getAccessTypePermission} />;
      case "location_rentmanager":
        return <RentManager {...attr} />;
      case "location_studios":
        return <ManageUnits {...attr} />;
      case "location_users":
        return <Users {...attr} />;
      case "location_cms":
        return <CmsLocationAddUpdate from="new-location" />;
      case "location_facilities":
        return <LocationFacilities {...attr} />;
      case "location_conversations":
        return <LocationConversation {...attr} {...conversationAttr} />;
      default:
        return null;
    }
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox mx={2}>
        <CustomBackButton label="Back To Locations" historyState={historyState} />
      </MDBox>
      <MDBox pt={2} pb={2}>
        {pageAccess && pageAccess?.length > 0 ? (
          <>
            <MDBox>
              <Grid container>
                <Grid item xs={12} md={12} sm={12} lg={12}>
                  <AppBar position="static">
                    <Tabs className="location-tabs" value={tabValue} onChange={changeTab}>
                      {pageAccess.map((obj: any) => (
                        <Tab label={obj.display_menu} key={obj.alias} />
                      ))}
                    </Tabs>
                  </AppBar>
                </Grid>
              </Grid>
            </MDBox>
            <MDBox>
              {pageAccess.map((obj: any, key: number) => (
                <MDTabPanel value={tabValue} index={key} padding={0} key={obj.alias}>
                  {getComponent(obj.alias)}
                </MDTabPanel>
              ))}
            </MDBox>
          </>
        ) : (
          <MDTypography ml={2}>
            {Object.entries(locationDetail || {})?.length > 0 ? "No Access" : ""}
          </MDTypography>
        )}
      </MDBox>
    </DashboardLayout>
  );
}

export default Index;
