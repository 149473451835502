import { Card, Grid } from "@mui/material";
import MDBox from "components/MDBox";
import MDInput from "components/MDInput";
import MDTypography from "components/MDTypography";

function BasicInfo(props: any): JSX.Element {
  const { onChangeTextField, errors, phoneDetail } = props;

  return (
    <Card id="basic-info">
      <MDBox p={3}>
        <MDTypography variant="h5">Basic Info</MDTypography>
      </MDBox>
      <MDBox component="form" pb={3} px={3}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={12} md={12} lg={6}>
            <MDInput
              label="Number Name"
              variant="standard"
              fullWidth
              name="number_name"
              placeholder="Number Name"
              onChange={onChangeTextField}
              value={phoneDetail?.number_name || ""}
              required
              error={errors && errors.number_name ? true : false}
              helperText={errors && errors.number_name}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={6}>
            <MDInput
              label="Number Description"
              variant="standard"
              fullWidth
              name="number_description"
              placeholder="Number Description"
              onChange={onChangeTextField}
              value={phoneDetail?.number_description || ""}
            />
          </Grid>
        </Grid>
      </MDBox>
    </Card>
  );
}

export default BasicInfo;
