import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

function BeautyHive(): JSX.Element {
  return (
    <MDBox textAlign="center" mx="auto" my={4}>
      <MDBox mb={1}>
        <MDTypography variant="h5" fontWeight="regular">
          Beauty Hive
        </MDTypography>
      </MDBox>
    </MDBox>
  );
}

export default BeautyHive;
