import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Card, Grid, Paper, FormControlLabel, Switch, styled } from "@mui/material";
import MDBox from "components/MDBox";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import CustomBackButton from "components/CustomBackButton";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import { approveLocation } from "store/thunk/locationThunk";
import { useAppDispatch } from "store/store";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#d3d3d3",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
  bold: true,
}));

function LocationReview(): JSX.Element {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { state }: any = useLocation();
  const [listData, setListData] = useState<any>({});
  const [directoriesUpdated, setDirectoriesUpdated] = useState<any>(false);

  useEffect(() => {
    if (state?.listData) {
      setListData(state?.listData);
    }
  }, [state]);

  const redirectToList = () => {
    navigate("/locations/approval");
  };

  const approveRequest = () => {
    const request: any = {
      approval_id: listData?.approval_id,
      callback: redirectToList,
    };
    dispatch(approveLocation(request));
  };

  const detailLabelElement = (label: any, keyName?: any, childKey?: any) =>
    listData?.approval_data?.new_value?.[keyName] && (
      <>
        <Grid item xs={12} sm={6} md={6} lg={6}>
          <MDTypography fontWeight="bold" variant="button">
            {label}
          </MDTypography>
          <MDBox>
            <MDTypography
              variant="button"
              sx={{ lineHeight: 2.3 }}
              dangerouslySetInnerHTML={{
                __html: childKey
                  ? listData?.approval_data?.old_value?.[keyName]?.[childKey]
                  : listData?.approval_data?.old_value?.[keyName] || "-",
              }}
            />
          </MDBox>
        </Grid>
        <Grid item xs={12} sm={6} md={6} lg={6}>
          <MDTypography fontWeight="bold" variant="button">
            {label}
          </MDTypography>
          <MDBox>
            <MDTypography
              variant="button"
              sx={{ lineHeight: 2.3 }}
              dangerouslySetInnerHTML={{
                __html: childKey
                  ? listData?.approval_data?.new_value?.[keyName]?.[childKey]
                  : listData?.approval_data?.new_value?.[keyName] || "-",
              }}
            />
          </MDBox>
        </Grid>
      </>
    );

  const historyState = {
    prevPath: "/locations/approval",
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox mx={2}>
        <CustomBackButton label="Back To Approval" historyState={historyState} />
      </MDBox>
      <MDBox pt={4} pb={3}>
        <Card>
          <MDBox
            bgColor="dark"
            color="white"
            coloredShadow="dark"
            borderRadius="xl"
            alignItems="center"
            justifyContent="space-between"
            pt={1}
            pb={1}
            pl={2}
            pr={2}
            ml={2}
            mr={2}
            display="flex"
            mt={-3}
          >
            Locations Review
          </MDBox>
          <MDBox p={2}>
            <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
              <Grid item xs={6}>
                <Item>
                  <MDTypography fontWeight="bold" variant="button">
                    Old Data
                  </MDTypography>
                </Item>
              </Grid>
              <Grid item xs={6}>
                <Item>
                  <MDTypography fontWeight="bold" variant="button">
                    New Requested Data
                  </MDTypography>
                </Item>
              </Grid>
              {detailLabelElement("Location Name", "name")}
              {detailLabelElement("Location Description", "description")}
              {detailLabelElement("Address 1", "address_1")}
              {detailLabelElement("Address 2", "address_2")}
              {detailLabelElement("City", "city")}
              {detailLabelElement("State", "state")}
              {detailLabelElement("Country", "countries", "name")}
              {detailLabelElement("General contact first name", "general_contact_first_name")}
              {detailLabelElement("General contact last name", "general_contact_last_name")}
              {detailLabelElement("General contact email", "general_contact_email")}
              {detailLabelElement("Phone number", "phone_number")}
              {detailLabelElement("Public contact name", "public_contact_name")}
              {detailLabelElement("Public email", "public_email")}
              {detailLabelElement("Public phone", "public_phone")}
            </Grid>
          </MDBox>
          {listData?.request_status !== "approved" && (
            <MDBox p={2}>
              <Grid container>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={directoriesUpdated}
                        onChange={(e: any) => setDirectoriesUpdated(e.target.checked)}
                        inputProps={{ "aria-label": "controlled" }}
                      />
                    }
                    label="Directories Updated"
                    labelPlacement="end"
                  />
                  <MDButton
                    variant="gradient"
                    color="success"
                    size="small"
                    onClick={() => approveRequest()}
                    disabled={!directoriesUpdated}
                  >
                    Approve
                  </MDButton>
                </Grid>
              </Grid>
            </MDBox>
          )}
        </Card>
      </MDBox>
    </DashboardLayout>
  );
}

export default LocationReview;
