import { useState, useEffect, useRef, useCallback } from "react";
import { Card, Icon } from "@mui/material";
import MDButton from "components/MDButton";
import MDBox from "components/MDBox";
import DataTable from "examples/Tables/DataTable";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import { useAppDispatch, useAppSelector } from "store/store";
import { deleteRMContact, getRMContactList } from "store/thunk/repairMaintenance";
import Messages from "helper/messages";
import MDTypography from "components/MDTypography";
import MDDialog from "components/MDDialog";
// import exportData from "helper/exportTableConst";
import MDIconButton from "components/MDIconButton";
import AddUpdate from "./addUpdate";
import ViewDetails from "./viewDetails";

function RMContacts(): JSX.Element {
  const dispatch = useAppDispatch();
  const { rmContactList } = useAppSelector((state) => state.repairMaintenanceSlice);
  const [selectedItems, setSelectedItems] = useState([]);
  const [allContact, setAllContacts] = useState([]);
  const [editId, setEditId] = useState(null);
  const [addUpdatePopup, setAddUpdatePopup] = useState(false);
  const [viewContactId, setViewContactId] = useState(null);
  const [viewPopup, setViewPopup] = useState(false);
  const [deleteContactId, setDeleteContactId] = useState<any>(null);
  const [pageCount, setPageCount] = useState(0);
  const [pageNo, setPageNo] = useState(0);
  const [perPage, setPageSize] = useState(50);
  const [totalCount, setTotalCount] = useState(0);
  const [search, setSearch] = useState<any>(null);
  const [sortBy, setSortBy] = useState<any>([]);
  // const [isCheckAll, setIsCheckAll] = useState(false);

  const fetchIdRef = useRef(0);

  useEffect(() => {
    if (rmContactList && rmContactList?.connect_rm_contact_library) {
      setAllContacts(rmContactList?.connect_rm_contact_library);
      setPageCount(rmContactList?.meta?.total_pages);
      setTotalCount(rmContactList?.meta?.total_count);
    }
  }, [rmContactList]);

  const fetchData = useCallback(({ pageSize, pageIndex, search, sortBy, filterData }) => {
    const fetchId = ++fetchIdRef.current;
    const sort: any = sortBy || [];
    // We'll even set a delay to simulate a server here
    setTimeout(() => {
      if (fetchId === fetchIdRef.current) {
        setPageNo(pageIndex);
        setSearch(search);
        setSortBy(sort);
        setPageSize(pageSize);
        // setSelectedItems([]);
        // setIsCheckAll(false);
        dispatch(getRMContactList({ pageSize, pageIndex, search, sort, filterData }));
      }
    }, 1000);
  }, []);

  // const onChangeCheckBox = (e: any) => {
  //   let items = JSON.parse(JSON.stringify(selectedItems));
  //   if (e.target.checked && !items.includes(e.target.value)) {
  //     items.push(e.target.value);
  //     if (items.length === allContact.length) {
  //       setIsCheckAll(true);
  //     }
  //   } else {
  //     items = items.filter((id: any) => id !== e.target.value);
  //     setIsCheckAll(false);
  //   }
  //   setSelectedItems(items);
  // };

  // const checkUncheckAll = (e: any) => {
  //   let allIds: any = [];
  //   allIds = allContact.map((val: any) => String(val.id));
  //   if (e.target.checked) {
  //     const selectedIds = [...new Set([...selectedItems, ...allIds])];
  //     setSelectedItems(selectedIds);
  //     setIsCheckAll(true);
  //   } else {
  //     allIds = allContact.map((val: any) => String(val.id));
  //     allIds = selectedItems.filter((el: any) => !allIds.includes(el));
  //     setSelectedItems([...allIds]);
  //     setIsCheckAll(false);
  //   }
  //   // setSelectedItems(allIds);
  // };

  const actionButtons = (obj: any) => (
    <>
      <MDIconButton
        tooltipName="View"
        aria-label="Open"
        color="info"
        onClick={() => {
          setViewPopup(true);
          setViewContactId(obj.id);
        }}
      >
        <Icon fontSize="small">visibility</Icon>
      </MDIconButton>
      <MDIconButton
        tooltipName="Edit"
        aria-label="Edit"
        color="info"
        onClick={() => {
          setEditId(obj.id);
          setAddUpdatePopup(true);
        }}
      >
        <Icon fontSize="small">edit</Icon>
      </MDIconButton>
      <MDIconButton
        tooltipName="Delete"
        aria-label="Delete"
        color="error"
        onClick={() => setDeleteContactId(obj.id)}
      >
        <Icon fontSize="small">delete</Icon>
      </MDIconButton>
    </>
  );

  const getRows = () => {
    const data: any = allContact.map((obj: any) => ({
      // checkbox: (
      //   <Checkbox
      //     checked={selectedItems.includes(obj?.id) || selectedItems.includes(obj?.id.toString())}
      //     onChange={(e: any) => onChangeCheckBox(e)}
      //     value={obj?.id}
      //   />
      // ),
      id: obj?.id,
      email: obj?.email || "-",
      phone: obj?.phone || "-",
      f_name: obj?.f_name || "-",
      l_name: obj?.l_name || "-",
      m_name: obj?.m_name || "-",
      title: obj?.title || "-",
      action: actionButtons(obj),
    }));
    return data;
  };

  const tableData = {
    columns: [
      // {
      //   Header: "",
      //   disableSortBy: true,
      //   accessor: "checkbox",
      //   width: "80px",
      // },
      { Header: "ID", accessor: "id", export: true },
      { Header: "Email", accessor: "email", export: true },
      { Header: "Phone Number", accessor: "phone", export: true },
      { Header: "First name", accessor: "f_name", export: true },
      { Header: "Last name", accessor: "l_name", export: true },
      { Header: "Middle name", accessor: "m_name", export: true },
      { Header: "Title", accessor: "title", export: true },
      { Header: "Action", accessor: "action", export: false, disableSortBy: true },
    ],
    rows: getRows(),
  };

  // Start Remove selcted items
  const removeSelectedItem = (deletedId: number) => {
    setSelectedItems(selectedItems.filter((item: any) => Number(item) !== deletedId));
  };
  // End Remove selcted items

  const onDelete = () => {
    if (deleteContactId) {
      const delReq = {
        id: deleteContactId,
        setDeleteContactId,
        pageNo,
        perPage,
        search,
        sortBy,
        removeSelectedItem,
      };
      dispatch(deleteRMContact(delReq));
    }
  };

  const deleteDialogProps = {
    open: deleteContactId,
    onClose: () => setDeleteContactId(false),
    onSave: () => onDelete(),
    title: "Delete",
    size: "sm",
    saveBtn: false,
    deleteBtn: true,
    saveTbtText: "Yes",
    closeBtnText: "No",
  };

  // Remove uncheck boxes
  const clearCheckItem = () => {
    setSelectedItems([]);
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={4} pb={3}>
        <Card>
          <MDBox
            bgColor="dark"
            color="white"
            coloredShadow="dark"
            borderRadius="xl"
            alignItems="center"
            justifyContent="space-between"
            pt={1}
            pb={1}
            pl={2}
            pr={2}
            ml={2}
            mr={2}
            display="flex"
            mt={-3}
            className="page-header"
          >
            <p className="page-header-label">Contacts</p>
            <MDButton
              variant="gradient"
              color="light"
              size="small"
              className="xs-small"
              onClick={() => setAddUpdatePopup(true)}
            >
              <Icon sx={{ fontWeight: "bold" }}>add</Icon> Add new
            </MDButton>
          </MDBox>
          <DataTable
            table={tableData}
            // showExportSelectedBtn
            // showExportAllBtn
            selectedItems={selectedItems}
            pageCount={pageCount}
            pageNo={pageNo}
            setPageNo={setPageNo}
            totalCount={totalCount}
            fetchData={fetchData}
            canSearch
            manualSearch
            // exportFields={exportData.brands.export_fields}
            // exportTable={exportData.brands.table_name}
            // exportType={exportData.brands.export_type}
            // advanceFilter
            manualSort
            // isDisplayCheckAllCheckbox
            // onChangeCheckAllCheckbox={checkUncheckAll}
            // isCheckAll={isCheckAll}
            clearCheckItem={clearCheckItem}
            // useRubyApiToExport
          />
          {addUpdatePopup && (
            <AddUpdate
              open={addUpdatePopup}
              pageNo={pageNo}
              perPage={perPage}
              search={search}
              sortBy={sortBy}
              onClose={() => {
                setAddUpdatePopup(false);
                setEditId(null);
              }}
              editId={editId}
            />
          )}
          {viewPopup && (
            <ViewDetails
              open={viewPopup}
              onClose={() => {
                setViewPopup(false);
                setViewContactId(null);
              }}
              viewContactId={viewContactId}
            />
          )}
          {deleteContactId && (
            <MDDialog {...deleteDialogProps}>
              <MDTypography variant="h6" fontWeight="medium">
                {Messages.GENERAL.SURE_TO_DELETE}
              </MDTypography>
            </MDDialog>
          )}
        </Card>
      </MDBox>
    </DashboardLayout>
  );
}

export default RMContacts;
