import { Card, Checkbox, FormControl, FormControlLabel, FormGroup, Grid } from "@mui/material";
import CustomErrorMessage from "components/CustomErrorMessage";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

function Amenities(props: any): JSX.Element {
  const { errors, locationDetail, setLocationDetail } = props;
  const onCheckboxChange = (event: any) => {
    if (event.target.checked) {
      setLocationDetail({
        ...locationDetail,
        amenities: [
          ...JSON.parse(JSON.stringify(locationDetail.amenities || [])),
          Number(event.target.value),
        ],
      });
    } else {
      const filteredAmenties: any = locationDetail?.amenities?.filter(
        (obj: any) => Number(obj) !== Number(event.target.value)
      );
      setLocationDetail({
        ...locationDetail,
        amenities: filteredAmenties,
      });
    }
  };

  return (
    <Card id="amenities" sx={{ overflow: "visible" }}>
      <MDBox p={3}>
        <MDTypography variant="h5">Amenities</MDTypography>
      </MDBox>
      <MDBox pl={3} pr={3} pb={2}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            {locationDetail?.all_amenities?.map((amenitiesObj: any) => (
              <FormControl component="fieldset">
                <FormGroup aria-label="position">
                  <FormControlLabel
                    value={Number(amenitiesObj?.id)}
                    name="amenities"
                    control={
                      <Checkbox
                        checked={
                          locationDetail?.amenities?.includes(Number(amenitiesObj?.id))
                            ? true
                            : false
                        }
                        onChange={onCheckboxChange}
                        inputProps={{ "aria-label": "controlled" }}
                      />
                    }
                    label={amenitiesObj?.headline}
                    labelPlacement="end"
                  />
                </FormGroup>
              </FormControl>
            ))}
          </Grid>
        </Grid>
      </MDBox>
      <MDBox pl={3} pr={3} pt={0} pb={3}>
        {errors && errors.amenities && <CustomErrorMessage message={errors.amenities} />}
      </MDBox>
    </Card>
  );
}

export default Amenities;
