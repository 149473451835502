import { Accordion, AccordionDetails, AccordionSummary, Grid } from "@mui/material";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import moment from "moment";

function ReviewDraftPopup(props: any): JSX.Element {
  const { leaseData } = props;

  const getAccordianSummary = (name: any) => (
    <AccordionSummary className="location-accordion">
      <MDTypography>{name}</MDTypography>
    </AccordionSummary>
  );

  const getDetailElement = (name: any, value: any) => (
    <Grid item xs={12} sm={6} md={6}>
      <MDTypography fontWeight="bold" variant="button">
        {name}
      </MDTypography>
      <MDBox sx={{ wordBreak: "break-all" }}>{value ? value : "-"}</MDBox>
    </Grid>
  );

  const showApplicants = (applicants: any) => {
    if (!Array.isArray(applicants)) {
      return ""; // or any other default value you want to return
    }
    const fullNames = applicants?.map((item: any) => `${item?.first_name} ${item?.last_name}`);
    const commaSeparatedNames = fullNames?.join(", ");
    return commaSeparatedNames;
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md={12}>
        <MDBox mt={1}>
          <Accordion expanded>
            {getAccordianSummary("Selection of Deal")}
            <AccordionDetails>
              <Grid container spacing={2}>
                {getDetailElement(
                  "Selected Type",
                  leaseData?.selectedType?.toUpperCase().replace(/_/g, "")
                )}
                {getDetailElement("Selected Deal", leaseData?.deal?.deal_name)}
                {getDetailElement("Applicants", showApplicants(leaseData?.applicants))}
                {getDetailElement(
                  "Document Status",
                  leaseData?.upload_doc_status ? "Upload" : "Not Upload"
                )}
                {getDetailElement(
                  "Payment Status",
                  leaseData?.applicants ? leaseData?.applicants[0]?.deposit_status : ""
                )}
              </Grid>
            </AccordionDetails>
          </Accordion>
          <Accordion expanded>
            {getAccordianSummary("Studio Section")}
            <AccordionDetails>
              <Grid container spacing={2}>
                {getDetailElement(
                  "Selected Location",
                  leaseData?.locations?.name ? leaseData?.locations?.name : "-"
                )}
                {getDetailElement(
                  "Selected Studio",
                  leaseData?.studio ? leaseData?.studio[0]?.name : "-"
                )}
              </Grid>
            </AccordionDetails>
          </Accordion>
          <Accordion expanded>
            {getAccordianSummary("Security Deposit")}
            <AccordionDetails>
              <Grid container spacing={2}>
                {getDetailElement(
                  "Security Deposit Amount Type ",
                  leaseData?.security_deposit_amount_type
                    ? leaseData?.security_deposit_amount_type?.toUpperCase().replace(/_/g, "")
                    : "-"
                )}
                {getDetailElement("Security Deposit Rent", leaseData?.security_deposit_amount)}
                {leaseData?.security_deposit_amount_type === "rent_retio"
                  ? getDetailElement("Lease Fees", `$ ${leaseData?.lease_fees}`)
                  : getDetailElement(
                      "Security Deposit Amount",
                      leaseData?.security_deposit_amount
                        ? `$ ${leaseData?.security_deposit_amount}`
                        : "-"
                    )}
                {getDetailElement(
                  "Total Amount",
                  leaseData?.security_deposite ? `$ ${leaseData?.security_deposite}` : "-"
                )}
              </Grid>
            </AccordionDetails>
          </Accordion>
          <Accordion expanded>
            {getAccordianSummary("Lease Data")}
            <AccordionDetails>
              <Grid container spacing={2}>
                {getDetailElement("Weeks Free Rent", leaseData?.leaseData?.weeks_free_rent)}
                {getDetailElement(
                  "Months Free Insurance",
                  leaseData?.leaseData?.months_free_insurance
                )}
                {getDetailElement("License Fee Year 1", leaseData?.leaseData?.licence_fee_1)}
                {getDetailElement("License Fee Year 2", leaseData?.leaseData?.licence_fee_2)}
                {getDetailElement("Lease Term", leaseData?.leaseData?.lease_term)}
                {getDetailElement(
                  "Move In Date",
                  leaseData?.leaseData?.move_in_date
                    ? moment(leaseData?.leaseData?.move_in_date).format("yyyy-MM-DD")
                    : "-"
                )}
                {getDetailElement(
                  "Lease Sign",
                  leaseData?.leaseData?.lease_sign
                    ? moment(leaseData?.leaseData?.lease_sign).format("yyyy-MM-DD")
                    : "-"
                )}
                {getDetailElement(
                  "Lease Start",
                  leaseData?.leaseData?.lease_start
                    ? moment(leaseData?.leaseData?.lease_start).format("yyyy-MM-DD")
                    : "-"
                )}
                {getDetailElement(
                  "Lease End",
                  leaseData?.leaseData?.lease_end
                    ? moment(leaseData?.leaseData?.lease_end).format("yyyy-MM-DD")
                    : "-"
                )}
                {getDetailElement(
                  "First Payment Date",
                  leaseData?.leaseData?.first_payment_date
                    ? moment(leaseData?.leaseData?.first_payment_date).format("yyyy-MM-DD")
                    : "-"
                )}
                {getDetailElement("Rent Due Date", leaseData?.leaseData?.rent_due_date)}
                {getDetailElement(
                  "Insurance Charge Frequency",
                  leaseData?.leaseData?.insurance_charge_frequency
                )}
                {getDetailElement(
                  "Insurance Charge Frequency",
                  leaseData?.leaseData?.insurance_fee
                )}
                {getDetailElement("Notes", leaseData?.leaseData?.note)}
              </Grid>
            </AccordionDetails>
          </Accordion>
        </MDBox>
      </Grid>
    </Grid>
  );
}

export default ReviewDraftPopup;
