import Grid from "@mui/material/Grid";
import MDBox from "components/MDBox";
import MDInput from "components/MDInput";

function ExistingLocations(props: any): JSX.Element {
  const { locationFranchiseView, onChangeField, errors } = props;
  return (
    <MDBox>
      <MDBox p={2} mt={2}>
        <MDBox>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={12} md={12}>
              <MDInput
                label="Please list all of your existing Sola locations, if any."
                fullWidth
                multiline
                rows={15}
                InputLabelProps={{ shrink: true }}
                name="existing_location"
                required
                onChange={onChangeField}
                value={locationFranchiseView?.existing_location || ""}
                error={errors && errors.existing_location ? true : false}
                helperText={errors && errors.existing_location}
              />
            </Grid>
          </Grid>
        </MDBox>
      </MDBox>
    </MDBox>
  );
}

export default ExistingLocations;
