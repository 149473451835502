import { Card, Grid, Icon } from "@mui/material";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import CustomDropZone from "components/CustomDropZone";
import MDIconButton from "components/MDIconButton";
import CustomErrorMessage from "components/CustomErrorMessage";
import constants from "helper/constants";
import { useState } from "react";
import ImagePreview from "layouts/pages/ImagePreview";
import { useAppDispatch } from "store/store";
import { convertImageToWebp } from "helper/services";

function Banner(props: any): JSX.Element {
  const { webPageDetail, setWebPageDetails, bannerError, setBannerError } = props;
  const dispatch = useAppDispatch();
  const [imageToPreview, setImageToPreview] = useState("");
  const [isPreview, setImagePreviewPopup] = useState(false);

  const onChangeBanner = async (files: any) => {
    const selectedImage = await convertImageToWebp(files[0], "stylist_banner", dispatch);
    if (selectedImage) {
      const maxSize = constants?.maxSize; // 3MB
      if (selectedImage?.size > maxSize) {
        const msg = "Upload image till 3MB";
        setBannerError(msg);
      } else {
        setBannerError("");
        const reader = new FileReader();
        reader.onload = function (e) {
          setWebPageDetails({
            ...webPageDetail,
            banner_image_url: e.target.result,
            banner: selectedImage,
          });
        };
        reader.readAsDataURL(selectedImage);
      }
    }
  };

  const onRemoveBanner = (files: any) => {
    setWebPageDetails({
      ...webPageDetail,
      banner_image_url: files && files[0] ? files[0] : null,
      delete_banner_image: 1,
      banner: null,
    });
    setBannerError("");
  };

  const previewImage = (image: any) => {
    setImagePreviewPopup(true);
    setImageToPreview(image);
  };

  const closeImagePreview = () => {
    setImagePreviewPopup(false);
    setImageToPreview("");
  };

  return (
    <Card id="banner">
      <MDBox p={3}>
        <MDTypography variant="h5">Banner Image</MDTypography>
      </MDBox>
      <MDBox component="form" pb={3} px={3}>
        <Grid container spacing={3}>
          <Grid item xs={9} sm={6} md={6} lg={6}>
            <CustomDropZone
              maxWidth="170px"
              maxHeight="150px"
              style={{ display: "none" }}
              multipleUpload={false}
              acceptedFiles={{
                "image/*": [".png", ".jpg", ".jpeg"],
              }}
              onDrop={onChangeBanner}
            >
              <MDTypography sx={{ fontWeight: "bold" }}>
                Drop your image here, or Browse
              </MDTypography>
              <MDTypography sx={{ color: "#9fa2a6", fontSize: "0.9rem" }}>
                Maximum allowed file size is 3 MB (Allowed Type(s): .png .jpg, .jpeg)
              </MDTypography>
            </CustomDropZone>
            {bannerError && bannerError && <CustomErrorMessage message={bannerError} />}
          </Grid>
          <Grid item xs={3} sm={6} md={6} lg={6}>
            {webPageDetail.banner_image_url && (
              <Grid container>
                <Grid item className="imageWithDeleteIcon">
                  <Grid
                    container
                    onClick={() => previewImage(webPageDetail?.banner_image_url)}
                    // justifyContent="center"
                    // alignItems="center"
                    // display="flex"
                    className="thumbnail-img"
                  >
                    <Grid item>
                      <img
                        height={100}
                        width={100}
                        src={webPageDetail?.banner_image_url}
                        alt="blog"
                      />
                    </Grid>
                  </Grid>
                  {/* <img
                    height={122}
                    width={200}
                    src={webPageDetail?.banner_image_url}
                    alt="banner"
                  /> */}
                  <MDIconButton
                    tooltipName="Delete"
                    aria-label="Delete"
                    className="removeBtnClass"
                    onClick={() => onRemoveBanner(null)}
                  >
                    <Icon fontSize="small">close</Icon>
                  </MDIconButton>
                </Grid>
              </Grid>
            )}
          </Grid>
        </Grid>
      </MDBox>
      {isPreview && (
        <ImagePreview
          imageData={imageToPreview}
          open={isPreview}
          onCloseImage={() => {
            closeImagePreview();
          }}
        />
      )}
    </Card>
  );
}

export default Banner;
