import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDLabelValue from "components/MDLabelValue";
import Grid from "@mui/material/Grid";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

function Review(props: any): JSX.Element {
  const { locationFranchiseView, countryList } = props;
  let countryName = "";
  if (countryList.length > 0) {
    const found = countryList.find((c: any) => c.id === locationFranchiseView.country_id);
    if (found) {
      countryName = found.name;
    }
  }
  return (
    <MDBox>
      <MDBox textAlign="center" m={2}>
        <MDTypography variant="h5" fontWeight="regular">
          Review
        </MDTypography>
      </MDBox>
      <Grid container spacing={2}>
        <Grid item lg={12} md={12} sm={12} xs={12} xl={12}>
          <MDBox>
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="general"
              >
                <MDTypography fontWeight="bold">General</MDTypography>
              </AccordionSummary>
              <AccordionDetails>
                <MDLabelValue
                  label="Franchise Owner First Name"
                  value={locationFranchiseView?.first_name}
                />
                <MDLabelValue
                  label="Franchise Owner Last Name"
                  value={locationFranchiseView?.last_name}
                />
                <MDLabelValue label="CMS Login" value={locationFranchiseView?.cms_login || ""} />
                <MDLabelValue label="MSA" value={locationFranchiseView?.location_msa || ""} />
                <MDLabelValue
                  label="Neighborhood or Specific Location"
                  value={locationFranchiseView?.location_display_name || ""}
                />
                <MDLabelValue
                  label="Instagram Account"
                  value={locationFranchiseView?.location_instagram_name || ""}
                />
                <MDLabelValue
                  label="Facebook Account"
                  value={locationFranchiseView?.location_facebook_name || ""}
                />
                <MDLabelValue
                  label="HubSpot Login"
                  value={locationFranchiseView?.location_hubspot_login || ""}
                />
                <MDLabelValue
                  label="Email HubSpot Sales Pipeline"
                  value={locationFranchiseView?.location_hubspot_sale_email || ""}
                />
              </AccordionDetails>
            </Accordion>
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="adress"
              >
                <MDTypography fontWeight="bold">Address</MDTypography>
              </AccordionSummary>
              <AccordionDetails>
                <MDLabelValue label="Address line 1" value={locationFranchiseView?.address_1} />
                <MDLabelValue
                  label="Address line 2"
                  value={locationFranchiseView?.address_2 || ""}
                />
                <MDLabelValue label="City" value={locationFranchiseView?.city || ""} />
                <MDLabelValue
                  label="State / Province / Region"
                  value={locationFranchiseView?.state || ""}
                />
                <MDLabelValue
                  label="Postal / Zip Code"
                  value={locationFranchiseView?.zipcode || ""}
                />
                <MDLabelValue label="Country" value={countryName} />
              </AccordionDetails>
            </Accordion>
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="location_description"
              >
                <MDTypography fontWeight="bold">Location Description</MDTypography>
              </AccordionSummary>
              <AccordionDetails>
                <MDLabelValue
                  label="Location Description"
                  value={locationFranchiseView?.location_description}
                />
              </AccordionDetails>
            </Accordion>
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="existing_sola_locations"
              >
                <MDTypography fontWeight="bold">Existing Sola Locations</MDTypography>
              </AccordionSummary>
              <AccordionDetails>
                <MDLabelValue
                  label="Existing Sola Locations"
                  value={locationFranchiseView?.existing_location}
                />
              </AccordionDetails>
            </Accordion>
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="lease_manager"
              >
                <MDTypography fontWeight="bold">Lease Manager</MDTypography>
              </AccordionSummary>
              <AccordionDetails>
                <MDLabelValue
                  label="Leasing Manager First Name"
                  value={locationFranchiseView?.leasing_manager_first_name || ""}
                />
                <MDLabelValue
                  label="Leasing Manager Last Name"
                  value={locationFranchiseView?.leasing_manager_last_name || ""}
                />
                <MDLabelValue
                  label="Leasing Manager Email"
                  value={locationFranchiseView?.leasing_manager_email || ""}
                />
                <MDLabelValue
                  label="Leasing Manager Phone"
                  value={locationFranchiseView?.leasing_manager_phone || ""}
                />
              </AccordionDetails>
            </Accordion>
          </MDBox>
        </Grid>
      </Grid>
    </MDBox>
  );
}

export default Review;
