import React, { useState, useEffect } from "react";
// react-router-dom components
import { Link } from "react-router-dom";

// @mui material components
import Card from "@mui/material/Card";

// Material Dashboard 2 PRO React TS components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";

// import { Auth, Hub } from "aws-amplify";
// import { CognitoHostedUIIdentityProvider } from "@aws-amplify/auth";

// Authentication layout components
import CoverLayout from "layouts/authentication/components/CoverLayout";

// Images
import bgImage from "assets/images/bg-sign-up-cover.jpeg";
import Validations from "helper/validations";
import { RegisterRequest } from "../../../../types/custom-types";
import { useAppDispatch, useAppSelector } from "../../../../store/store";
import { register } from "../../../../store/thunk/authThunk";

function Cover(): JSX.Element {
  const appDispatch = useAppDispatch();
  // const notificationInfo = useAppSelector((state) => state.notificationReducer);
  const { thankYou } = useAppSelector((state) => state.authReducer);
  const [email, setEmail] = useState<string>("");
  const [errors, setErrors] = useState<any>({ email: "", password: "" });
  const [showThankYou, setShowThankYou] = useState<boolean>(false);

  const onRegister = (e: any) => {
    e.preventDefault();
    const requestData: RegisterRequest = {
      email,
      profileUpdate: true,
    };
    const error = Validations.validateRegisterForm(requestData);
    setErrors(error);
    if (!Object.keys(error).length) {
      appDispatch(register(requestData));
    }
  };

  useEffect(() => {
    if (thankYou) {
      setEmail("");
      setShowThankYou(true);
    }
  }, [thankYou]);

  /* useEffect(() => {
    Hub.listen("auth", ({ payload: { event } }) => {
      switch (event) {
        case "signIn":
          appDispatch(SSOLogin());
          break;
        default:
          break;
      }
    });
  }, []); */

  // login in with google
  /* const googleLogin = async () => {
    localStorage.setItem("sso_login", String(true));
    await Auth.federatedSignIn({
      provider: CognitoHostedUIIdentityProvider.Google,
    });
  }; */

  return (
    <CoverLayout image={bgImage}>
      {showThankYou ? (
        <Card>
          <MDBox
            variant="gradient"
            bgColor="info"
            borderRadius="lg"
            coloredShadow="success"
            mx={2}
            p={3}
            mt={1}
            mb={1}
            textAlign="center"
          >
            <MDTypography variant="h4" fontWeight="medium" color="white" mt={1}>
              Thank you!
            </MDTypography>
            <MDTypography display="block" variant="button" color="white" my={1}>
              Please check your email inbox for verify account link.
            </MDTypography>
          </MDBox>
        </Card>
      ) : (
        <Card>
          <MDBox
            variant="gradient"
            bgColor="info"
            borderRadius="lg"
            coloredShadow="success"
            mx={2}
            mt={-3}
            p={3}
            mb={1}
            textAlign="center"
          >
            <MDTypography variant="h4" fontWeight="medium" color="white" mt={1}>
              Join us today
            </MDTypography>
            <MDTypography display="block" variant="button" color="white" my={1}>
              Enter your name and email to register
            </MDTypography>
          </MDBox>
          <MDBox pt={4} pb={3} px={3}>
            <MDBox component="form" role="form" onSubmit={(e) => onRegister(e)}>
              <MDBox mb={2}>
                <MDInput
                  type="email"
                  label="Email"
                  variant="standard"
                  fullWidth
                  placeholder="john@example.com"
                  InputLabelProps={{ shrink: true }}
                  name="email"
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => setEmail(e.target.value)}
                  value={email}
                  error={(errors && errors.email) || false}
                  helperText={errors && errors.email}
                  required
                />
              </MDBox>
              <MDBox mt={4} mb={1}>
                <MDButton variant="gradient" color="info" fullWidth type="submit">
                  sign up
                </MDButton>
              </MDBox>
              {/* <MDBox mt={1} mb={1}>
                <MDButton
                  variant="gradient"
                  color="info"
                  fullWidth
                  type="button"
                  onClick={() => googleLogin()}
                >
                  Sign up with Google
                </MDButton>
              </MDBox> */}
              <MDBox mt={3} mb={1} textAlign="center">
                <MDTypography variant="button" color="text">
                  Already have an account?{" "}
                  <MDTypography
                    component={Link}
                    to="/sign-in"
                    variant="button"
                    color="info"
                    fontWeight="medium"
                    textGradient
                  >
                    Sign In
                  </MDTypography>
                </MDTypography>
              </MDBox>
            </MDBox>
          </MDBox>
        </Card>
      )}
    </CoverLayout>
  );
}

export default Cover;
