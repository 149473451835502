import { Card, FormControlLabel, Grid, Switch } from "@mui/material";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import CustomPhoneInput from "components/CustomPhoneInput";
import CustomErrorMessage from "components/CustomErrorMessage";

function CallTreeStatus(props: any): JSX.Element {
  const { onChangeField, onChangeTextField, phoneDetail, errors } = props;

  return (
    <Card id="call-tree-status">
      <MDBox p={3}>
        <MDTypography variant="h5">Call Tree Status</MDTypography>
      </MDBox>
      <MDBox component="form" pb={3} px={3}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={12}>
            <FormControlLabel
              control={
                <Switch
                  checked={phoneDetail?.call_tree_status || false}
                  name="call_tree_status"
                  onChange={onChangeField}
                  inputProps={{ "aria-label": "controlled" }}
                />
              }
              label={phoneDetail?.call_tree_status ? "Enabled" : "Disabled"}
            />
          </Grid>
          {!phoneDetail?.call_tree_status && (
            <Grid item xs={12} sm={12}>
              <MDInput
                variant="standard"
                fullWidth
                label="Phone Number Forwarding"
                name="forwarding_number"
                placeholder="(000) 000-0000"
                id="phone-input"
                InputProps={{
                  inputComponent: CustomPhoneInput as any,
                }}
                value={phoneDetail?.forwarding_number || ""}
                onChange={onChangeTextField}
                error={errors && errors.forwarding_number ? true : false}
                helperText={errors && errors.forwarding_number}
              />
              <CustomErrorMessage
                message="All call made to this number will be passed directly to this phone number."
                errorColor="inherit"
              />
            </Grid>
          )}
        </Grid>
      </MDBox>
    </Card>
  );
}

export default CallTreeStatus;
