import React, { useEffect, useState } from "react";
import { Box, Card, Tab, Tabs, Typography, Grid, FormControlLabel, Switch } from "@mui/material";
import MDBox from "components/MDBox";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import CustomPhoneInput from "components/CustomPhoneInput";
import Validations from "helper/validations";
import { CognitoUpdateUser } from "store/thunk/authThunk";
import { useAppDispatch } from "store/store";
import CustomAutoSearch from "components/CustomAutoSearch";
import DataTable from "examples/Tables/DataTable";
import CmsLocations from "layouts/pages/locations/cms-locations";
import {
  getAuthUser,
  displayMultipleValue,
  displayMultipleValueWithName,
  getPermission,
  userRoles,
  isFranchisie,
} from "helper/services";
import MDTypography from "components/MDTypography";
import CustomErrorMessage from "components/CustomErrorMessage";
// import TimePicker from "rc-time-picker";
// import moment from "moment";
import Preferences from "../preferences";

function TabPanel(props: any) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`profile-tabpanel-${index}`}
      aria-labelledby={`profile-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `profile-tab-${index}`,
    "aria-controls": `profile-tabpanel-${index}`,
  };
}

function GeneralInfo({ userData }: any): JSX.Element {
  const dispatch = useAppDispatch();
  const authUser = getAuthUser();
  const [errors, setErrors] = useState<any>({});
  const [notificationErrors, setNotificationErrors] = useState<any>("");
  const [userInfo, setUserData] = useState<any>(authUser);
  const [value, setValue] = React.useState(0);
  const [phone, setPhone] = useState("");
  const [associationList, setAssociationList] = useState([]);
  // const [timezone, setTimezone] = useState(moment.tz.guess() || null);

  const handleChange = (_event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };
  useEffect(() => {
    if (userData) {
      // // if (userTimezone && userTimezone?.preference_value) {
      // //   // setTimezone(userTimezone?.preference_value);
      // // }
      // const data = JSON.parse(JSON.stringify(userData));
      // if (userData?.work_start_hour) {
      //   data.work_start_hour = moment(
      //     `${moment().format("yyyy-MM-DD")} ${userData?.work_start_hour}`
      //   );
      //   /* const splitStart = userData?.work_start_hour.split(":");

      //   const workStartDate = moment().set("hours", splitStart[0]).set("minutes", splitStart[1]);
      //   if (userTimezone) {
      //     const timezoneOffset = moment.tz(userTimezone.preference_value).utcOffset();
      //     data.work_start_hour = moment(workStartDate).add(timezoneOffset, "minutes");
      //   } else {
      //     const timezoneOffset = moment.tz(moment.tz.guess()).utcOffset();
      //     data.work_start_hour = moment(workStartDate).add(timezoneOffset, "minutes");
      //   } */
      // }
      // if (userData?.work_end_hour) {
      //   data.work_end_hour = moment(`${moment().format("yyyy-MM-DD")} ${userData?.work_end_hour}`);
      //   /* const splitEnd = userData?.work_end_hour.split(":");
      //   const workEndDate = moment().set("hours", splitEnd[0]).set("minutes", splitEnd[1]);
      //   if (userTimezone) {
      //     const timezoneOffset = moment.tz(userTimezone.preference_value).utcOffset();
      //     data.work_end_hour = moment(workEndDate).add(timezoneOffset, "minutes");
      //   } else {
      //     const timezoneOffset = moment.tz(moment.tz.guess()).utcOffset();
      //     data.work_end_hour = moment(workEndDate).add(timezoneOffset, "minutes");
      //   } */
      // }
      // setUserData(data);
      setPhone(userData?.sms_phone || "");
    }
  }, [userData]);

  useEffect(() => {
    if (authUser && authUser?.connect_associations && authUser?.connect_associations.length > 0) {
      setAssociationList(authUser?.connect_associations);
    }
  }, []);

  const onChangeField = (event: any) => {
    setUserData({ ...userInfo, [event.target.name]: event.target.value });
  };
  const onSave = () => {
    const reqData = JSON.parse(JSON.stringify(userInfo));
    // const userTimezone = reqData?.connect_preferences.find(
    //   (v: any) => v.preference_type === "TIMEZONE"
    // );
    reqData.user_id = authUser.user_id;
    reqData.email = reqData?.login_email;
    reqData.firstName = reqData?.first_name;
    reqData.lastName = reqData?.last_name;
    reqData.phone = phone ? `+${phone.replace(/[- )(+]/g, "")}` : "";
    reqData.profileUpdate = true;
    reqData.userId = reqData?.user_id;
    reqData.isUpdateOther = true;
    reqData.smsNotifications = reqData?.sms_notifications;
    reqData.emailNotifications = reqData?.email_notifications;
    // reqData.userTimezone = userTimezone;
    const error = Validations.validateConnectUserForm(reqData);
    setErrors(error);

    if (!Object.keys(error).length) {
      // reqData.work_start_hour = reqData?.work_start_hour
      //   ? moment(reqData?.work_start_hour)?.format("HH:mm")
      //   : null;
      // reqData.work_end_hour = reqData?.work_end_hour
      //   ? moment(reqData?.work_end_hour)?.format("HH:mm")
      //   : null;
      dispatch(CognitoUpdateUser(reqData));
    }
  };

  const onChangeAutoComplete = (name: string, value: any) => {
    setUserData({ ...userInfo, [name]: value });
  };

  const getAssociationRows = () => {
    const data: any = associationList.map((obj: any) => ({
      id: obj?.id,
      association_name: obj?.association_name ? (
        <MDTypography sx={{ fontSize: 14, cursor: "pointer", fontWeight: "bold" }}>
          {obj.association_name}
        </MDTypography>
      ) : (
        "-"
      ),
      bussiness_name: obj?.bussiness_name || "-",
      locations: obj?.locations ? displayMultipleValue(obj?.locations) : "-",
      owners: obj?.owner_details
        ? displayMultipleValueWithName(obj?.owner_details, "first_name")
        : "-",
    }));
    return data;
  };
  const associationTableData = {
    columns: [
      { Header: "Id", accessor: "id", width: "50px", export: true },
      { Header: "Association name", accessor: "association_name", width: "80px", export: true },
      {
        Header: "Locations",
        accessor: "locations",
        export: true,
        width: "30%",
        disableSortBy: true,
      },
      { Header: "Owners", accessor: "owners", export: true, disableSortBy: true },
    ],
    rows: getAssociationRows(),
  };

  const onChangeCheck = (e: any) => {
    if (!e.target.checked) {
      setNotificationErrors("");
      setUserData({ ...userInfo, [e.target.name]: e.target.checked });
    } else if (phone && phone !== "+1 (") {
      setNotificationErrors("");
      setUserData({ ...userInfo, [e.target.name]: e.target.checked });
    } else if (e.target.name === "email_notifications") {
      setNotificationErrors("");
      setUserData({ ...userInfo, [e.target.name]: e.target.checked });
    } else {
      setNotificationErrors("Phone and Email must be added before the notification is turned on");
    }
  };

  return (
    <Card sx={{ boxShadow: "none" }}>
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <Tabs
          className="location-tabs"
          value={value}
          onChange={handleChange}
          aria-label="basic tabs example"
        >
          <Tab label="General Info" {...a11yProps(0)} />
          <Tab
            label="Additional Info"
            {...a11yProps(1)}
            sx={{ display: authUser?.role !== userRoles.SYSTEM_ADMIN ? "none" : "" }}
          />
          <Tab label="Address Info" {...a11yProps(2)} />
          <Tab
            label="Associations"
            {...a11yProps(3)}
            sx={{ display: authUser?.role !== userRoles.SYSTEM_ADMIN ? "none" : "" }}
          />
          <Tab label="Locations" {...a11yProps(4)} />
          {(userData.role === userRoles.SYSTEM_ADMIN || isFranchisie()) && (
            <Tab label="Notifications" {...a11yProps(5)} />
          )}
        </Tabs>
      </Box>
      <TabPanel value={value} index={0}>
        <MDInput
          label="First Name"
          variant="standard"
          fullWidth
          name="first_name"
          onChange={onChangeField}
          value={userInfo?.first_name || ""}
          error={(errors && errors.first_name) || false}
          helperText={errors && errors.first_name}
          required
        />
        <MDInput
          label="Last Name"
          variant="standard"
          fullWidth
          name="last_name"
          onChange={onChangeField}
          value={userInfo?.last_name || ""}
          error={(errors && errors.last_name) || false}
          helperText={errors && errors.last_name}
          sx={{ mt: 2 }}
          required
        />
        <MDInput
          label="Email"
          variant="standard"
          fullWidth
          name="login_email"
          placeholder="Email"
          onChange={onChangeField}
          value={userInfo?.login_email || ""}
          error={(errors && errors.login_email) || false}
          helperText={errors && errors.login_email}
          sx={{ mt: 2 }}
        />
        <MDInput
          variant="standard"
          fullWidth
          name="phone"
          label="Phone"
          placeholder="(000) 000-0000"
          id="phone-input"
          InputProps={{
            inputComponent: CustomPhoneInput as any,
          }}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => setPhone(e.target.value)}
          value={phone}
          error={(errors && errors.sms_phone) || false}
          helperText={errors && errors.sms_phone}
          sx={{ mt: 2 }}
        />
        <MDInput
          label="Job Title"
          variant="standard"
          sx={{ mt: 2 }}
          fullWidth
          name="job_title"
          onChange={onChangeField}
          value={userInfo?.job_title || ""}
          error={(errors && errors.job_title) || false}
          helperText={errors && errors.job_title}
        />
        <Preferences userData={userData} />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <MDBox mt={2}>
          <CustomAutoSearch
            idKey="id"
            nameKey="name"
            fieldLabel="MSA"
            apiName="msas"
            responseKey="msas"
            name="msa"
            value={userInfo?.msa || null}
            onChange={onChangeAutoComplete}
            createNewLink={getPermission("locations", "locations_msa") ? "/locations/msa" : ""}
          />
        </MDBox>
        <MDInput
          label="Managing Owner (Primary Contact)"
          variant="standard"
          fullWidth
          name="managing_owner"
          onChange={onChangeField}
          value={userInfo?.managing_owner || ""}
          error={(errors && errors.managing_owner) || false}
          helperText={errors && errors.managing_owner}
        />
        <MDInput
          label="Managing Owner First Name"
          variant="standard"
          fullWidth
          name="owner_first_name"
          sx={{ mt: 2 }}
          onChange={onChangeField}
          value={userInfo?.owner_first_name || ""}
          error={(errors && errors.owner_first_name) || false}
          helperText={errors && errors.owner_first_name}
        />
      </TabPanel>
      <TabPanel value={value} index={2}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
            <MDTypography fontSize={14} mb={2} fontWeight="bold">
              Home Address
            </MDTypography>
            <MDInput
              label="Home Street Address"
              variant="standard"
              fullWidth
              name="home_street_address"
              onChange={onChangeField}
              value={userInfo?.home_street_address || ""}
              error={(errors && errors.home_street_address) || false}
              helperText={errors && errors.home_street_address}
            />
            <MDInput
              label="Home City"
              variant="standard"
              fullWidth
              sx={{ mt: 2 }}
              name="home_city"
              onChange={onChangeField}
              value={userInfo?.home_city || ""}
              error={(errors && errors.home_city) || false}
              helperText={errors && errors.home_city}
            />
            <MDInput
              label="Home State"
              variant="standard"
              fullWidth
              sx={{ mt: 2 }}
              name="home_state"
              onChange={onChangeField}
              value={userInfo?.home_state || ""}
              error={(errors && errors.home_state) || false}
              helperText={errors && errors.home_state}
            />
            <MDInput
              label="Home Postal Code"
              variant="standard"
              sx={{ mt: 2 }}
              fullWidth
              name="home_postal_code"
              onChange={onChangeField}
              value={userInfo?.home_postal_code || ""}
              error={(errors && errors.home_postal_code) || false}
              helperText={errors && errors.home_postal_code}
            />
            <MDBox mt={4}>
              <CustomAutoSearch
                idKey="id"
                nameKey="name"
                fieldLabel="Home Country"
                apiName="countries"
                responseKey="countries"
                name="home_country"
                value={userInfo?.home_country || null}
                onChange={onChangeAutoComplete}
                error={errors && errors?.home_country ? true : false}
              />
            </MDBox>
          </Grid>
          <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
            <MDTypography fontSize={14} mb={2} fontWeight="bold">
              Other Address
            </MDTypography>
            <MDInput
              label="Other Street Address"
              variant="standard"
              fullWidth
              name="other_street_address"
              onChange={onChangeField}
              value={userInfo?.other_street_address || ""}
              error={(errors && errors.other_street_address) || false}
              helperText={errors && errors.other_street_address}
            />
            <MDInput
              label="Other City"
              variant="standard"
              sx={{ mt: 2 }}
              fullWidth
              name="other_city"
              onChange={onChangeField}
              value={userInfo?.other_city || ""}
              error={(errors && errors.other_city) || false}
              helperText={errors && errors.other_city}
            />
            <MDInput
              label="Other State"
              variant="standard"
              sx={{ mt: 2 }}
              fullWidth
              name="other_state"
              onChange={onChangeField}
              value={userInfo?.other_state || ""}
              error={(errors && errors.other_state) || false}
              helperText={errors && errors.other_state}
            />
            <MDInput
              label="Other Postal Code"
              variant="standard"
              fullWidth
              sx={{ mt: 2 }}
              name="other_postal_code"
              onChange={onChangeField}
              value={userInfo?.other_postal_code || ""}
              error={(errors && errors.other_postal_code) || false}
              helperText={errors && errors.other_postal_code}
            />
            <MDBox mt={4}>
              <CustomAutoSearch
                idKey="id"
                nameKey="name"
                fieldLabel="Other Country"
                apiName="countries"
                responseKey="countries"
                name="other_country"
                value={userInfo?.other_country || null}
                onChange={onChangeAutoComplete}
                error={errors && errors?.other_country ? true : false}
              />
            </MDBox>
          </Grid>
        </Grid>
      </TabPanel>
      <TabPanel value={value} index={3}>
        <DataTable table={associationTableData} canSearch />
      </TabPanel>
      <TabPanel value={value} index={4}>
        <CmsLocations myAccount />
      </TabPanel>
      <TabPanel value={value} index={5}>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <FormControlLabel
            control={
              <Switch
                name="sms_notifications"
                checked={userInfo?.sms_notifications ? true : false}
                onChange={onChangeCheck}
                inputProps={{ "aria-label": "controlled" }}
              />
            }
            label={
              <MDBox display="flex">
                <MDTypography variant="subtitle2">
                  <strong>SMS Notifications</strong>
                </MDTypography>
              </MDBox>
            }
            labelPlacement="end"
          />
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <FormControlLabel
            control={
              <Switch
                name="email_notifications"
                checked={userInfo?.email_notifications ? true : false}
                onChange={onChangeCheck}
                inputProps={{ "aria-label": "controlled" }}
              />
            }
            label={
              <MDBox display="flex">
                <MDTypography variant="subtitle2">
                  <strong>Email Notifications</strong>
                </MDTypography>
              </MDBox>
            }
            labelPlacement="end"
          />
          {notificationErrors && <CustomErrorMessage message={notificationErrors} />}
        </Grid>
        {/* <Grid item xs={12} sm={12} md={12} lg={12} pt={1}>
          <MDTypography fontSize={14} mb={1} fontWeight="bold">
            Work Hours
          </MDTypography>
          <MDBox sx={{ display: "flex", alignItems: "center" }}>
            <TimePicker
              placeholder="Select start time..."
              className="marginRight"
              minuteStep={5}
              showSecond={false}
              format="HH:mm"
              inputReadOnly
              name="work_start_hour"
              value={userInfo?.work_start_hour || null}
              onChange={(e: any) => {
                setUserData({ ...userInfo, work_start_hour: e ? moment(e) : null });
              }}
            />
            <MDTypography variant="subtitle2">to</MDTypography>
            <TimePicker
              placeholder="Select end time..."
              className="marginLeft"
              minuteStep={5}
              showSecond={false}
              format="HH:mm"
              inputReadOnly
              name="work_end_hour"
              value={userInfo?.work_end_hour || null}
              onChange={(e: any) => {
                setUserData({ ...userInfo, work_end_hour: e ? moment(e) : null });
              }}
            />
          </MDBox>
          {errors.workHours && <CustomErrorMessage message={errors.workHours} />}
        </Grid> */}
        {/* <Grid item xs={12} sm={12} md={12} lg={12} pt={1}>
          <MDInput
            multiline
            rows={3}
            variant="outlined"
            fullWidth
            name="work_hour_msg_content"
            id="work_hour_msg_content"
            placeholder="Please write auto response message"
            onChange={onChangeField}
            value={userInfo?.work_hour_msg_content || ""}
          />
        </Grid> */}
      </TabPanel>
      {(value < 4 || value === 5) && (
        <MDBox mt={2} textAlign="right">
          <MDButton onClick={onSave} color="info" variant="gradient" size="small">
            Update
          </MDButton>
        </MDBox>
      )}
    </Card>
  );
}

export default GeneralInfo;
