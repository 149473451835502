import { useEffect, useRef, useState, useCallback, useLayoutEffect } from "react";
import { useAppDispatch, useAppSelector } from "store/store";
import { Card, Icon } from "@mui/material";
import MDBox from "components/MDBox";

import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import DataTable from "examples/Tables/DataTable";
import MDIconButton from "components/MDIconButton";
import exportData from "helper/exportTableConst";
import { getCustomerLeads } from "store/thunk/leaseThunk";
import {
  adminSuperAdmin,
  getLocalDateTime,
  phoneNumberformat,
  getAllAssociationLocations,
  getAccessPermission,
  getCRMCreateAccess,
  setDefaultSelectedColumns,
} from "helper/services";
import Messages from "helper/messages";
import { deleteContactInquiries } from "store/thunk/systemAdmin";
import MDDialog from "components/MDDialog";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import { getDefaultCoulmns, saveSelectedAllData } from "store/thunk/connectUserThunk";
import { setDefaultsColumns } from "store/slices/connectUsersSlice";
import ViewDetails from "./viewDetails";
import AddUpdate from "./addUpdate";

function Index(): JSX.Element {
  const dispatch = useAppDispatch();
  const fetchIdRef = useRef(0);
  const [addUpdatePopup, setAddUpdatePopup] = useState<any>(false);
  const [updateData, setUpdateData] = useState<any>(null);
  const [detailsPopup, setDetailsPopup] = useState<any>(false);
  const [deleteContactPopup, setDeleteContactInquiries] = useState<any>(false);
  const { leads } = useAppSelector((state) => state.leaseSlice);
  const { userData } = useAppSelector((state) => state.authReducer);
  const [applicationsList, setApplicationsList] = useState([]);
  const [pageCount, setPageCount] = useState(0);
  const [pageNo, setPageNo] = useState(0);
  const [perPage, setPageSize] = useState(10);
  const [totalCount, setTotalCount] = useState(0);
  const [search, setSearch] = useState<any>(null);
  const [sortBy, setSortBy] = useState<any>([]);
  // SD-3199
  const [defaultExport, setDefaultExport] = useState<any>([]);
  const [callFetch, setCallFetch] = useState<any>(false);
  const [defaultSorting, setDefaultSorting] = useState<any>([]);
  const [defaultFilteing, setDefaultFilteing] = useState<any>([]);

  const getColumns = () => {
    const columns: any = [
      { Header: "Name", accessor: "name", export: true },
      { Header: "Email", accessor: "email", export: true },
      { Header: "Phone", accessor: "phone", export: true },
      {
        Header: "Location",
        accessor: "location",
        width: "15%",
        export: true,
        associative_colums: "locations.name",
      },
      {
        Header: "I Would Like To Be Contacted",
        accessor: "i_would_like_to_be_contacted",
        export: true,
        is_connected: true,
      },
      { Header: "How Can We Help You", accessor: "how_can_we_help_you", export: true },
      { Header: "Created At", accessor: "created_at", export: true, is_date: true },
      {
        Header: "Newsletter Subscription",
        accessor: "newsletter",
        export: true,
        is_connected: true,
      },
      { Header: "Action", accessor: "action", width: "200px", export: false, disableSortBy: false },
    ];
    return columns;
  };
  const [checkedColumns, setCheckedColumns] = useState<any>(getColumns());
  const latestRefValue = useRef(checkedColumns);

  useEffect(() => {
    latestRefValue.current = checkedColumns;
  }, [checkedColumns]);

  useEffect(() => {
    if (leads) {
      setPageCount(leads?.meta?.total_pages);
      setTotalCount(leads?.meta?.total_count);
      setApplicationsList(leads?.leads);
    }
  }, [leads]);

  const openDetailPopup = (data: any) => {
    setUpdateData(data);
    setDetailsPopup(true);
  };

  const openEditModal = (data: any) => {
    setUpdateData(data);
    setAddUpdatePopup(true);
  };

  const openDeleteModal = (data: any) => {
    setUpdateData(data);
    setDeleteContactInquiries(true);
  };

  const closeDetailPopup = () => {
    setUpdateData("");
    setDetailsPopup(false);
  };

  const closeModal = () => {
    setUpdateData(null);
    setAddUpdatePopup(false);
  };

  const actionButtons = (object: any) => (
    <>
      {getAccessPermission(
        object?.location_id,
        "crm",
        "crm_salon_customer_leads",
        "",
        "view_access"
      ) && (
        <MDIconButton
          tooltipName="View"
          aria-label="Info"
          color="info"
          onClick={() => openDetailPopup(object)}
        >
          <Icon fontSize="small">visibility</Icon>
        </MDIconButton>
      )}
      {adminSuperAdmin() &&
        getAccessPermission(
          object?.location_id,
          "crm",
          "crm_salon_customer_leads",
          "",
          "edit_access"
        ) && (
          <>
            <MDIconButton
              tooltipName="Edit"
              aria-label="Edit"
              color="info"
              onClick={() => openEditModal(object)}
            >
              <Icon fontSize="small">edit</Icon>
            </MDIconButton>
            <MDIconButton
              tooltipName="Delete"
              aria-label="Delete"
              color="error"
              onClick={() => openDeleteModal(object)}
            >
              <Icon fontSize="small">delete</Icon>
            </MDIconButton>
          </>
        )}
    </>
  );

  const getRows = () => {
    const data: any = applicationsList?.map((obj: any) => ({
      name: obj.name ? obj.name : "-",
      email: obj.email ? obj.email : "-",
      phone: obj.phone ? phoneNumberformat(obj.phone) : "-",
      location: obj.location ? obj.location?.name : "-",
      i_would_like_to_be_contacted: obj.i_would_like_to_be_contacted ? "Yes" : "No",
      how_can_we_help_you: obj.how_can_we_help_you ? obj.how_can_we_help_you : "-",
      created_at: obj.created_at ? getLocalDateTime(obj.created_at) : "-",
      newsletter: obj.newsletter ? "Yes" : "No",
      action: actionButtons(obj),
    }));
    return data;
  };

  const tableData = {
    columns: getColumns(),
    rows: getRows(),
  };

  const fetchData = useCallback(({ pageSize, pageIndex, search, sortBy, filterData, isManual }) => {
    const fetchId = ++fetchIdRef.current;
    const sort: any = sortBy || [];
    // We'll even set a delay to simulate a server here
    setTimeout(() => {
      if (fetchId === fetchIdRef.current) {
        const sentReq = {
          sendData: {
            user_id: userData?.user_id,
            table_name: exportData.rent_manager_units.table_name,
            table_columns: latestRefValue.current ? latestRefValue.current : checkedColumns,
            table_sort: sortBy && sortBy?.length ? sortBy : [],
            table_filter: filterData && filterData?.length ? filterData : [],
            default_export_data: defaultExport,
          },
        };
        if (isManual) {
          dispatch(saveSelectedAllData(sentReq));
        }
        setPageNo(pageIndex);
        setSearch(search);
        setSortBy(sort);
        setPageSize(pageSize);
        // setSelectedItems([]);
        dispatch(
          getCustomerLeads({
            locations: getAllAssociationLocations(),
            pageSize,
            pageIndex,
            search,
            sort,
            type: "Book an appointment with a salon professional",
            filterData,
          })
        );
      }
    }, 1000);
  }, []);

  useLayoutEffect(() => {
    dispatch(setDefaultsColumns([]));
    // Set data in variable and Call defaultColums Thunk
    const sentReq = {
      user_id: userData?.user_id,
      table_name: exportData.rent_manager_units.table_name,
    };
    dispatch(getDefaultCoulmns(sentReq)).then((resp: any) => {
      // SD-3199

      if (resp?.payload) {
        const respFilter = resp?.payload?.table_filter || [];
        const respSorting = resp?.payload?.table_sort || [];
        const defaultDBexportData = resp?.payload?.default_export_data || [];
        const defaultDBColumn = resp?.payload?.table_columns || [];
        const tempdata = setDefaultSelectedColumns(defaultDBColumn, getColumns());
        setCheckedColumns(tempdata);
        setDefaultExport(defaultDBexportData);
        setDefaultSorting(respSorting);
        setDefaultFilteing(respFilter);
        setCallFetch(true);
        setSortBy(respSorting);
        fetchData({
          perPage,
          pageNo,
          search,
          sortBy: respSorting && respSorting?.length ? respSorting : [],
          additionalField: null,
          filterData: respFilter && respFilter?.length ? respFilter : [],
        });
      }
    });
  }, []);

  const onCloseDeleteModal = () => {
    setDeleteContactInquiries(false);
    setUpdateData("");
  };

  const onDelete = () => {
    if (updateData?.id) {
      const DelReq = {
        contactInquiriesData: updateData,
        onCloseDeleteModal,
        pageNo,
        perPage,
        search,
        sortBy,
        isLead: true,
        type: "Book an appointment with a salon professional",
      };
      dispatch(deleteContactInquiries(DelReq));
    }
  };

  const deleteDialogProps = {
    open: deleteContactPopup,
    onClose: () => onCloseDeleteModal(),
    onSave: () => onDelete(),
    title: "Delete Salon Customer Lead",
    size: "sm",
    saveBtn: false,
    deleteBtn: true,
    saveTbtText: "Yes",
    closeBtnText: "No",
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={4} pb={3}>
        <Card>
          <MDBox
            bgColor="dark"
            color="white"
            coloredShadow="dark"
            borderRadius="xl"
            alignItems="center"
            justifyContent="space-between"
            pt={1}
            pb={1}
            pl={2}
            pr={2}
            ml={2}
            mr={2}
            display="flex"
            mt={-3}
          >
            <p className="page-header-label">Salon Customer Leads</p>
            {(adminSuperAdmin() ||
              getCRMCreateAccess("add_access", "crm", "crm_salon_customer_leads")?.locations >
                0) && (
              <MDButton
                variant="gradient"
                color="light"
                size="small"
                className="xs-small"
                onClick={() => {
                  openEditModal(null);
                  // setIsUpdate(false);
                }}
              >
                <Icon sx={{ fontWeight: "bold" }}>add</Icon> Add New
              </MDButton>
            )}
          </MDBox>
          {(defaultSorting || defaultSorting?.length) &&
            callFetch && ( // SD-3199
              <DataTable
                defaultSortingColumns={defaultSorting} // SD-3199
                defaultFilteringColums={defaultFilteing} // SD-3199
                table={tableData}
                canSearch
                searchText="Search Lease..."
                pageCount={pageCount}
                pageNo={pageNo}
                setPageNo={setPageNo}
                totalCount={totalCount}
                fetchData={fetchData}
                manualSearch
                manualSort
                exportTable={exportData.rent_manager_units.table_name}
                advanceFilter
              />
            )}
          {detailsPopup && (
            <ViewDetails
              open={detailsPopup}
              updateData={updateData}
              onClose={() => closeDetailPopup()}
            />
          )}
          {addUpdatePopup && (
            <AddUpdate
              open={addUpdatePopup}
              pageNo={pageNo}
              perPage={perPage}
              search={search}
              sortBy={sortBy}
              updateData={updateData}
              onClose={() => closeModal()}
            />
          )}
          {deleteContactPopup && (
            <MDDialog {...deleteDialogProps}>
              <MDTypography variant="h6" fontWeight="medium">
                {Messages.GENERAL.SURE_TO_DELETE}
              </MDTypography>
            </MDDialog>
          )}
        </Card>
      </MDBox>
    </DashboardLayout>
  );
}

export default Index;
