import { useState, useEffect, useRef, useCallback, useLayoutEffect } from "react";
import { Card, Checkbox, Icon } from "@mui/material";
import MDButton from "components/MDButton";
import MDBox from "components/MDBox";
import DataTable from "examples/Tables/DataTable";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import {
  getLocalDateTime,
  insertinArray,
  phoneNumberformat,
  systemAdmin,
  setDefaultSelectedColumns,
} from "helper/services";
import { useAppDispatch, useAppSelector } from "store/store";
import { deleteInquiry, getAllPartnerInquiries } from "store/thunk/systemAdmin";
import MDDialog from "components/MDDialog";
import MDTypography from "components/MDTypography";
import Messages from "helper/messages";
import exportData from "helper/exportTableConst";
import MDIconButton from "components/MDIconButton";
import { getDefaultCoulmns, saveSelectedAllData } from "store/thunk/connectUserThunk";
import { setDefaultsColumns } from "store/slices/connectUsersSlice";
import AddUpdate from "./addUpdate";
import ViewDetails from "./viewDetails";

function PartnerInquiries(): JSX.Element {
  const dispatch = useAppDispatch();
  const { userData } = useAppSelector((state) => state.authReducer);
  const { allInquiries } = useAppSelector((state) => state.partnerInquiriesSlice);
  const { defaultSelectedColumns } = useAppSelector((state) => state.connectUsersReducer);
  const [selectedItems, setSelectedItems] = useState([]);
  const [inquiriesList, setInquiriesList] = useState([]);
  const [deleteInquiryId, setDeleteInquiryId] = useState<any>(false);
  const [addInquiryPopup, setAddInquiryPopup] = useState<any>(false);
  const [viewInquiryPopup, setViewInquiryPopup] = useState<any>(false);
  const [editInquiry, setEditInquiry] = useState<any>(null);
  const [viewInquiry, setViewInquiry] = useState<any>(null);
  const [pageCount, setPageCount] = useState(0);
  const [pageNo, setPageNo] = useState(0);
  const [totalCount, setTotalCount] = useState(0);
  const [perPage, setPageSize] = useState(50);
  const [search, setSearch] = useState<any>(null);
  const [sortBy, setSortBy] = useState<any>([]);
  const [isCheckAll, setIsCheckAll] = useState(false);
  const [displayColumns, setDisplayColumns] = useState<any>([]);
  // SD-3199
  const [defaultExport, setDefaultExport] = useState<any>([]);
  const [callFetch, setCallFetch] = useState<any>(false);
  const [defaultSorting, setDefaultSorting] = useState<any>([]);
  const [defaultFilteing, setDefaultFilteing] = useState<any>([]);

  // Get columns
  const getColumns = () => {
    let columns: any = [
      {
        Header: "",
        disableSortBy: true,
        accessor: "checkbox",
        checked: true,
      },
      { Header: "Name", accessor: "name", export: true, disableCheck: true, checked: true },
      { Header: "Subject", accessor: "subject", export: true, checked: true },
      { Header: "Company Name", accessor: "company_name", export: true, checked: true },
      { Header: "Email", accessor: "email", export: true, checked: true },
      { Header: "Phone", accessor: "phone", export: true, checked: true },
      { Header: "Message", accessor: "message", export: true, checked: false },
      {
        Header: "Created At",
        accessor: "created_at",
        width: "200px",
        export: true,
        is_date: true,
        checked: false,
      },
      {
        Header: "Updated At",
        accessor: "updated_at",
        width: "200px",
        export: true,
        is_date: true,
        checked: false,
      },
      { Header: "Request Url", accessor: "request_url", export: true, checked: false },
      {
        Header: "Action",
        accessor: "action",
        export: false,
        width: "200px",
        disableSortBy: true,
        checked: true,
      },
    ];
    if (systemAdmin()) {
      columns = insertinArray(columns, 1, {
        Header: "ID",
        accessor: "id",
        export: true,
        disableCheck: true,
        checked: true,
      });
    }
    return columns;
  };
  const [checkedColumns, setCheckedColumns] = useState<any>(getColumns());
  const fetchIdRef = useRef(0);
  const latestRefValue = useRef(checkedColumns);

  useEffect(() => {
    latestRefValue.current = checkedColumns;
  }, [checkedColumns]);

  const fetchData = useCallback(({ pageSize, pageIndex, search, sortBy, filterData }) => {
    const fetchId = ++fetchIdRef.current;
    const sort: any = sortBy || [];
    // We'll even set a delay to simulate a server here
    setTimeout(() => {
      if (fetchId === fetchIdRef.current) {
        // SD-3199
        if (
          (sortBy && sortBy?.length) ||
          (filterData && filterData?.length) ||
          sortBy?.length === 0
        ) {
          const sentReq = {
            sendData: {
              user_id: userData?.user_id,
              table_name: exportData.partner_inquiries.table_name,
              table_columns: latestRefValue.current && latestRefValue.current,
              table_sort: sortBy,
              table_filter: filterData,
              default_export_data: defaultExport,
            },
          };
          dispatch(saveSelectedAllData(sentReq));
        }
        setPageNo(pageIndex);
        setSearch(search);
        setSortBy(sort);
        setPageSize(pageSize);
        // setSelectedItems([]);
        setIsCheckAll(false);
        dispatch(
          getAllPartnerInquiries({ pageSize, pageIndex, search, sort, userData, filterData })
        );
      }
    }, 1000);
  }, []);

  useLayoutEffect(() => {
    dispatch(setDefaultsColumns([]));
    // Set data in variable and Call defaultColums Thunk
    const sentReq = {
      user_id: userData?.user_id,
      table_name: exportData.partner_inquiries.table_name,
    };
    dispatch(getDefaultCoulmns(sentReq)).then((resp: any) => {
      // SD-3199
      const respFilter = resp?.payload?.table_filter || [];
      const respSorting = resp?.payload?.table_sort || [];
      const defaultDBexportData = resp?.payload?.default_export_data || [];
      const defaultDBColumn = resp?.payload?.table_columns || [];
      const tempdata = setDefaultSelectedColumns(defaultDBColumn, getColumns());
      setCheckedColumns(tempdata);
      setDefaultExport(defaultDBexportData);
      setDefaultSorting(respSorting);
      setSortBy(respSorting);
      setDefaultFilteing(respFilter);
      if (resp?.payload) {
        setCallFetch(true);
        fetchData({
          perPage,
          pageNo,
          search,
          sortBy: respSorting && respSorting?.length ? respSorting : [],
          additionalField: null,
          filterData: respFilter && respFilter?.length ? respFilter : [],
        });
      }
    });
  }, []);

  useEffect(() => {
    if (defaultSelectedColumns && defaultSelectedColumns?.length) {
      const newColumns = setDefaultSelectedColumns(defaultSelectedColumns, getColumns());
      setCheckedColumns(newColumns);
    } else {
      setCheckedColumns(getColumns());
    }
  }, [defaultSelectedColumns]);

  useEffect(() => {
    if (checkedColumns?.length) {
      setDisplayColumns(checkedColumns.filter((col: any) => col.checked));
    }
  }, [checkedColumns]);

  useEffect(() => {
    if (allInquiries && allInquiries?.partner_inquiries) {
      setInquiriesList(allInquiries?.partner_inquiries);
      setPageCount(allInquiries?.meta?.total_pages);
      setTotalCount(allInquiries?.meta?.total_count);
    }
  }, [allInquiries]);

  const onChangeCheckBox = (e: any) => {
    let items = JSON.parse(JSON.stringify(selectedItems));
    if (e.target.checked && !items.includes(e.target.value)) {
      items.push(e.target.value);
      if (items.length === inquiriesList.length) {
        setIsCheckAll(true);
      }
    } else {
      items = items.filter((id: any) => id !== e.target.value);
      setIsCheckAll(false);
    }
    setSelectedItems(items);
  };

  const checkUncheckAll = (e: any) => {
    let allIds: any = [];
    allIds = inquiriesList.map((val: any) => String(val.id));
    if (e.target.checked) {
      const selectedIds = [...new Set([...selectedItems, ...allIds])];
      setSelectedItems(selectedIds);
      // allIds = locations.map((val: any) => String(val.id));
      setIsCheckAll(true);
    } else {
      allIds = inquiriesList.map((val: any) => String(val.id));
      allIds = selectedItems.filter((el: any) => !allIds.includes(el));
      setSelectedItems([...allIds]);
      setIsCheckAll(false);
      // allIds = [];
      // setIsCheckAll(false);
    }
    // setSelectedItems(allIds);
  };

  const actionButtons = (obj: any) => (
    <>
      <MDIconButton
        tooltipName="View"
        aria-label="Open"
        color="info"
        onClick={() => {
          setViewInquiry(obj);
          setViewInquiryPopup(true);
        }}
      >
        <Icon fontSize="small">visibility</Icon>
      </MDIconButton>
      <MDIconButton
        tooltipName="Edit"
        aria-label="Edit"
        color="info"
        onClick={() => {
          setEditInquiry(obj);
          setAddInquiryPopup(true);
        }}
      >
        <Icon fontSize="small">edit</Icon>
      </MDIconButton>
      <MDIconButton
        tooltipName="Delete"
        aria-label="Delete"
        color="error"
        onClick={() => setDeleteInquiryId(obj.id)}
      >
        <Icon fontSize="small">delete</Icon>
      </MDIconButton>
    </>
  );

  const getRows = () => {
    const data: any = inquiriesList.map((obj: any) => ({
      checkbox: (
        <Checkbox
          checked={selectedItems.includes(obj.id) || selectedItems.includes(obj.id.toString())}
          onChange={(e: any) => onChangeCheckBox(e)}
          value={obj.id}
        />
      ),
      id: obj.id,
      subject: obj.subject || "-",
      name: obj.name || "-",
      company_name: obj.company_name || "-",
      email: obj.email || "-",
      phone: obj.phone ? phoneNumberformat(obj.phone) : "-",
      message: obj.message || "-",
      created_at: obj.created_at ? getLocalDateTime(obj.created_at) : "-",
      updated_at: obj.updated_at ? getLocalDateTime(obj.updated_at) : "-",
      request_url: obj.request_url || "-",
      action: actionButtons(obj),
    }));
    return data;
  };

  const tableData = {
    columns: getColumns(),
    rows: getRows(),
  };

  // Start Remove selcted items
  const removeSelectedItem = (deletedId: number) => {
    setSelectedItems(selectedItems.filter((item: any) => Number(item) !== deletedId));
  };
  // End Remove selcted items

  const onDelete = () => {
    if (deleteInquiryId) {
      const delReq = {
        id: deleteInquiryId,
        setDeleteInquiryId,
        pageNo,
        perPage,
        search,
        sortBy,
        removeSelectedItem,
      };
      dispatch(deleteInquiry(delReq));
    }
  };

  const deleteDialogProps = {
    open: deleteInquiryId,
    onClose: () => setDeleteInquiryId(false),
    onSave: () => onDelete(),
    title: "Delete Partner Inquiry",
    size: "sm",
    saveBtn: false,
    deleteBtn: true,
    saveTbtText: "Yes",
    closeBtnText: "No",
  };

  // Remove uncheck boxes
  const clearCheckItem = () => {
    setSelectedItems([]);
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={4} pb={3}>
        <Card>
          <MDBox
            bgColor="dark"
            color="white"
            coloredShadow="dark"
            borderRadius="xl"
            alignItems="center"
            justifyContent="space-between"
            pt={1}
            pb={1}
            pl={2}
            pr={2}
            ml={2}
            mr={2}
            display="flex"
            mt={-3}
            className="page-header"
          >
            <p className="page-header-label">Partner Inquiries</p>
            <MDButton
              variant="gradient"
              color="light"
              size="small"
              className="xs-small"
              onClick={() => {
                setEditInquiry(null);
                setAddInquiryPopup(true);
              }}
            >
              <Icon sx={{ fontWeight: "bold" }}>add</Icon> Add new
            </MDButton>
          </MDBox>
          {(defaultSorting || defaultSorting?.length) &&
            callFetch && ( // SD-3199
              <DataTable
                defaultSortingColumns={defaultSorting} // SD-3199
                defaultFilteringColums={defaultFilteing} // SD-3199
                defaultDisplayColumns={displayColumns}
                setCheckedColumns={setCheckedColumns}
                table={tableData}
                canSearch
                showExportSelectedBtn
                showExportAllBtn
                selectedItems={selectedItems}
                pageCount={pageCount}
                pageNo={pageNo}
                setPageNo={setPageNo}
                totalCount={totalCount}
                fetchData={fetchData}
                exportFields={exportData.partner_inquiries.export_fields}
                exportTable={exportData.partner_inquiries.table_name}
                exportType={exportData.partner_inquiries.export_type}
                advanceFilter
                manualSearch
                manualSort
                isDisplayCheckAllCheckbox
                onChangeCheckAllCheckbox={checkUncheckAll}
                isCheckAll={isCheckAll}
                checkedColumns={checkedColumns}
                clearCheckItem={clearCheckItem}
                useRubyApiToExport
              />
            )}
          {deleteInquiryId && (
            <MDDialog {...deleteDialogProps}>
              <MDTypography variant="h6" fontWeight="medium">
                {Messages.GENERAL.SURE_TO_DELETE}
              </MDTypography>
            </MDDialog>
          )}
          {addInquiryPopup && (
            <AddUpdate
              open={addInquiryPopup}
              onClose={() => {
                setEditInquiry(null);
                setAddInquiryPopup(false);
              }}
              pageNo={pageNo}
              perPage={perPage}
              search={search}
              sortBy={sortBy}
              editInquiry={editInquiry}
            />
          )}
          {viewInquiryPopup && (
            <ViewDetails
              open={viewInquiryPopup}
              onClose={() => {
                setViewInquiry(null);
                setViewInquiryPopup(false);
              }}
              viewInquiry={viewInquiry}
            />
          )}
        </Card>
      </MDBox>
    </DashboardLayout>
  );
}

export default PartnerInquiries;
