import { useEffect, useState } from "react";
import { useAppDispatch } from "store/store";
import { Card, Grid, FormControlLabel, Switch, Icon, InputLabel } from "@mui/material";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import Select from "react-select";
import moment from "moment";
import MDInput from "components/MDInput";
import MDDatePicker from "components/MDDatePicker";
import MDIconButton from "components/MDIconButton";
import MDDialog from "components/MDDialog";
import { RRule } from "rrule";

import { updateWeekend } from "store/thunk/locationThunk";
import Validations from "helper/validations";
import { scrollToErrorByClass } from "helper/services";
import CustomErrorMessage from "components/CustomErrorMessage";
import UserWithProfilePic from "components/CustomAutoSearch/UserWithProfilePic";

let timezones: any = moment.tz.zonesForCountry("US");
timezones = timezones.map((val: string) => ({ value: val, label: val }));

function Weekend(props: any): JSX.Element {
  const dispatch = useAppDispatch();
  const { connectLocation } = props;
  const [errors, setErrors] = useState<any>({});
  const [weekendEditPopup, setWeekendEditPopup] = useState(false);
  const [weekend, setWeekend] = useState(connectLocation?.weekend || false);
  const [outOfOffice, setoutOfOffice] = useState(connectLocation?.out_of_office || false);
  const [weekendTimezone, setWeekendTimezone] = useState(connectLocation?.weekend_timezone || null);
  const [weekendStartdate, setWeekendStartdate] = useState(
    moment().day(6).set("hour", 0).set("minute", 0).format()
  );
  const [outOfficeStartdate, setOutOfficeStartdate] = useState<any>(moment().format());
  const [outOfficeEnddate, setOutOfficeEnddate] = useState<any>(moment().format());
  const [weekendEnddate, setWeekendEnddate] = useState(
    moment().day(8).set("hour", 0).set("minute", 0).format()
  );
  const [weekendTilldate, setWeekendTilldate] = useState(moment().add(1, "month").format());
  const [weekendMessage, setWeekendMessage] = useState(connectLocation?.weekend_message || null);
  const [dealSecondOwnerId, setDealSecondOwnerId] = useState<any>(null);

  useEffect(() => {
    if (connectLocation) {
      const timezoneOffset = moment.tz(connectLocation?.weekend_timezone).utcOffset();
      setWeekendStartdate(connectLocation?.weekend_startdate);
      setWeekendEnddate(connectLocation?.weekend_enddate);
      setOutOfficeStartdate(
        moment(connectLocation?.out_office_start_date)
          .add(timezoneOffset, "minutes")
          .add(-moment().utcOffset(), "minutes")
          .toISOString()
      );
      setOutOfficeEnddate(
        moment(connectLocation?.out_office_end_date)
          .add(timezoneOffset, "minutes")
          .add(-moment().utcOffset(), "minutes")
          .toISOString()
      );
      if (connectLocation?.recurring_rule) {
        const rule = RRule.fromString(connectLocation?.recurring_rule);
        setWeekendTilldate(moment(rule.options.until).format());
      }
      if (connectLocation?.deal_secondary_owner_id) {
        setDealSecondOwnerId({
          value: {
            user_id: connectLocation?.deal_secondary_owner_id,
            first_name: connectLocation?.secondary_user?.first_name || "",
            last_name: connectLocation?.secondary_user?.last_name || "",
            profile_picture: connectLocation?.secondary_user?.profile_picture || "",
          },
          label: `${connectLocation?.secondary_user?.first_name || ""} ${
            connectLocation?.secondary_user?.last_name || ""
          } `,
        });
      } else {
        setDealSecondOwnerId(null);
      }
    }
  }, []);

  const onSaveWeekend = () => {
    const tillDate: any = moment(weekendTilldate).toISOString();
    const dtStart = new Date(
      Date.UTC(
        new Date().getUTCFullYear(),
        new Date().getUTCMonth(),
        new Date().getUTCDate(),
        new Date().getUTCHours(),
        new Date().getUTCMinutes(),
        0
      )
    );
    const RRWeekDay = [RRule.SA, RRule.SU];
    const recurringObj = {
      freq: RRule.WEEKLY,
      interval: 1,
      dtstart: dtStart,
      byweekday: RRWeekDay,
      until: new Date(typeof tillDate === "object" ? tillDate?.[0] : tillDate),
    };

    const rule = new RRule(recurringObj);
    const startDate: any = weekendStartdate;
    const endDate: any = weekendEnddate;
    const outOfcStart: any = moment(outOfficeStartdate).toISOString();
    const outOfcEnd: any = moment(outOfficeEnddate).toISOString();
    const reqObj = {
      locationId: connectLocation.location_id,
      weekend,
      weekendTimezone,
      weekendStartdate:
        typeof startDate === "object"
          ? moment(startDate?.[0]).add(moment(startDate?.[0]).utcOffset(), "minutes").toISOString()
          : startDate,
      weekendEnddate:
        typeof endDate === "object"
          ? moment(endDate?.[0]).add(moment(endDate?.[0]).utcOffset(), "minutes").toISOString()
          : endDate,
      weekendMessage,
      recurringRule: rule.toString(),
      outOfOffice,
      outOfficeStartdate: weekendTimezone
        ? moment(outOfcStart)
            .tz(weekendTimezone)
            .set("days", moment(outOfcStart).days())
            .set("hours", moment(outOfcStart).hours())
            .set("minutes", moment(outOfcStart).minutes())
            .utc()
            .toISOString()
        : moment(outOfcStart)
            .set("days", moment(outOfcStart).days())
            .set("hours", moment(outOfcStart).hours())
            .set("minutes", moment(outOfcStart).minutes())
            .utc()
            .toISOString(),
      outOfficeEnddate: weekendTimezone
        ? moment(outOfcEnd)
            .tz(weekendTimezone)
            .set("days", moment(outOfcEnd).days())
            .set("hours", moment(outOfcEnd).hours())
            .set("minutes", moment(outOfcEnd).minutes())
            .utc()
            .toISOString()
        : moment(outOfcEnd)
            .set("days", moment(outOfcEnd).days())
            .set("hours", moment(outOfcEnd).hours())
            .set("minutes", moment(outOfcEnd).minutes())
            .utc()
            .toISOString(),
      outOfficeOwnerId: dealSecondOwnerId?.value?.user_id
        ? dealSecondOwnerId?.value?.user_id
        : null,
      setWeekendEditPopup,
      cmsLocationId: connectLocation?.cms_location_id,
    };
    const error: any = Validations.validateWeekend({ ...reqObj, tillDate });
    setErrors(error);
    if (!Object.keys(error).length) {
      dispatch(updateWeekend(reqObj));
    } else {
      scrollToErrorByClass(error);
    }
  };

  const dialogProps = {
    open: weekendEditPopup,
    onClose: () => setWeekendEditPopup(false),
    onSave: () => onSaveWeekend(),
    title: `Weekend / Out Of Office`,
    size: "md",
    saveBtn: true,
    overflowVisible: "overflowVisible",
    bodyClass: "overflow-auto",
  };

  const style = {
    control: (base: any) => ({
      ...base,
      height: "auto",
      border: "none",
      boxShadow: "0 !important",
      borderBottom: "1px solid #d2d6da",
      // width: "30%",
      borderRadius: "0px !important",
      textAlign: "left",
      fontSize: 14,
      marginBottom: "10px",
    }),
    menu: (provided: any) => ({
      ...provided,
      zIndex: 99,
      fontSize: 14,
    }),
    valueContainer: (valueContainer: any) => ({
      ...valueContainer,
      padding: "0 0 0 8px",
    }),
    container: (container: any) => ({
      ...container,
      width: weekendEditPopup ? "100%" : "40%",
    }),
  };

  const onChange = (event: any) => {
    setWeekendTimezone(event?.value);
  };

  const onChangeSecondDealOwner = (name: any, value: any) => {
    setDealSecondOwnerId(value);
  };

  return (
    <Card id="weekend">
      <MDBox
        p={3}
        pb={0}
        sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}
      >
        <MDTypography variant="h5">Weekend / Out Of Office</MDTypography>
        <MDIconButton
          tooltipName="Edit"
          aria-label="Edit"
          color="info"
          onClick={() => setWeekendEditPopup(true)}
        >
          <Icon fontSize="small">edit</Icon>
        </MDIconButton>
      </MDBox>
      <MDBox pb={3} px={3}>
        <Grid container spacing={2} mt={1}>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <MDBox pb={3}>
              <FormControlLabel
                control={
                  <Switch
                    checked={weekend || false}
                    // onChange={(e: any) => setWeekend(e.target.checked)}
                    inputProps={{ "aria-label": "controlled" }}
                  />
                }
                label="Weekend Sat/Sun"
                labelPlacement="end"
              />
            </MDBox>
            <MDBox pb={3}>
              <FormControlLabel
                control={
                  <Switch
                    checked={outOfOffice || false}
                    // onChange={(e: any) => setWeekend(e.target.checked)}
                    inputProps={{ "aria-label": "controlled" }}
                  />
                }
                label="Out Of Office"
                labelPlacement="end"
              />
            </MDBox>
            <MDBox width="100%" className="weekend-time-zone-picker">
              <InputLabel id="weekendTimezone">Time Zone</InputLabel>
              <Select
                menuPlacement="auto"
                isDisabled
                placeholder="Select Timezone..."
                options={timezones.filter((val: any) => val.label)}
                value={
                  weekendTimezone
                    ? {
                        value: weekendTimezone,
                        label: weekendTimezone,
                      }
                    : ""
                }
                styles={style}
                isClearable
                // onChange={(e: any) => setWeekendTimezone(e?.target?.value)}
              />
            </MDBox>
            <Grid container pb={1} sx={{ alignItems: "center", display: "flex" }}>
              <Grid item xs={5} sm={5} md={5} lg={5}>
                <MDTypography variant="button">End Date for Weekend Off</MDTypography>
              </Grid>
              <Grid item xs={7} sm={7} md={7} lg={7} className="weekend-tilldate">
                <MDDatePicker
                  name="tilldate"
                  value={moment(weekendTilldate).format()}
                  input={{ placeholder: "Select Date" }}
                  options={{
                    clickOpens: false,
                    enableTime: false,
                    time_24hr: false,
                    dateFormat: "Y-m-d",
                    minDate: new Date(),
                  }}
                />
              </Grid>
            </Grid>
            {outOfOffice && (
              <>
                <Grid container pb={1} sx={{ alignItems: "center", display: "flex" }}>
                  <Grid item xs={5} sm={5} md={5} lg={5}>
                    <MDTypography variant="button">Out of Office Start Date:</MDTypography>
                  </Grid>
                  <Grid item xs={7} sm={7} md={7} lg={7} className="weekend-tilldate">
                    <MDDatePicker
                      name="start_date"
                      // value={
                      //   weekendStartdate && weekendTimezone
                      //     ? moment(weekendStartdate).tz(weekendTimezone).format()
                      //     : weekendStartdate && !weekendTimezone
                      //     ? moment(weekendStartdate).format()
                      //     : weekendStartdate
                      // }
                      value={
                        outOfficeStartdate ? moment(outOfficeStartdate).format() : moment().format()
                      }
                      input={{ placeholder: "Start Date" }}
                      options={{
                        clickOpens: false,
                        enableTime: true,
                        time_24hr: true,
                        dateFormat: "Y-m-d H:i",
                        defaultHour: 0,
                        defaultMinute: 0,
                      }}
                    />
                  </Grid>
                </Grid>
                <Grid container pb={1} sx={{ alignItems: "center", display: "flex" }}>
                  <Grid item xs={5} sm={5} md={5} lg={5}>
                    <MDTypography variant="button">Out of Office End Date:</MDTypography>
                  </Grid>
                  <Grid item xs={7} sm={7} md={7} lg={7} className="weekend-tilldate">
                    <MDDatePicker
                      name="end_date"
                      // value={
                      //   weekendEnddate && weekendTimezone
                      //     ? moment(weekendEnddate).tz(weekendTimezone)
                      //     : weekendEnddate
                      //     ? moment(weekendEnddate).format()
                      //     : weekendEnddate
                      // }
                      value={
                        outOfficeEnddate ? moment(outOfficeEnddate).format() : moment().format()
                      }
                      input={{ placeholder: "End Date" }}
                      options={{
                        clickOpens: false,
                        enableTime: true,
                        time_24hr: true,
                        dateFormat: "Y-m-d H:i",
                        defaultHour: 0,
                        defaultMinute: 0,
                      }}
                    />
                  </Grid>
                </Grid>
              </>
            )}
            <MDBox>
              <MDTypography variant="button">Weekend Message</MDTypography>
              <MDInput
                multiline
                rows={3}
                variant="outlined"
                fullWidth
                name="weekend_message"
                id="weekend_message"
                placeholder="Create an auto-reply message for users who contact you during your weekends."
                // onChange={onChangeField}
                disabled
                value={weekendMessage || ""}
              />
            </MDBox>
            {outOfOffice && (
              <>
                <MDBox pt={1}>
                  <MDTypography variant="button">Out of Office Lead Owner</MDTypography>
                </MDBox>
                <MDBox pb={3}>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={12} md={12} lg={12}>
                      <UserWithProfilePic
                        placeholder={!dealSecondOwnerId ? "No Owner" : ""}
                        apiName="user-list-by-location"
                        responseKey="users"
                        name="users"
                        slsAPI
                        value={dealSecondOwnerId || null}
                        disabled
                        // onChange={onChangeSecondDealOwner}
                        additionaFields={{ location_id: connectLocation?.cms_location_id }}
                      />
                      {errors && errors.secondary_owner && (
                        <CustomErrorMessage message={errors.secondary_owner} />
                      )}
                    </Grid>
                  </Grid>
                </MDBox>
              </>
            )}
          </Grid>
        </Grid>
      </MDBox>
      <MDDialog {...dialogProps}>
        <MDBox pb={2}>
          <Grid container spacing={3}>
            <Grid item xs={9} sm={9} md={8} lg={7}>
              <MDBox>
                <MDTypography variant="body2" fontWeight="medium">
                  Turn ON/OFF Weekend <span className="weekend-day">Sat</span>{" "}
                  <span className="weekend-day">Sun</span>
                </MDTypography>
                <MDTypography variant="subtitle2" fontSize="12px">
                  To enable Weekend OFF on Saturday and Sunday, toggle this button on
                </MDTypography>
              </MDBox>
            </Grid>
            <Grid
              item
              xs={3}
              sm={3}
              md={4}
              lg={5}
              sx={{ justifyContent: "flex-end", display: "flex" }}
            >
              <FormControlLabel
                control={
                  <Switch
                    checked={weekend || false}
                    onChange={(e: any) => setWeekend(e.target.checked)}
                    inputProps={{ "aria-label": "controlled" }}
                  />
                }
                label=""
                labelPlacement="end"
              />
            </Grid>
            <Grid item xs={9} sm={9} md={8} lg={7}>
              <MDBox>
                <MDTypography variant="body2" fontWeight="medium">
                  Turn ON/OFF Out Of Office
                </MDTypography>
                <MDTypography variant="subtitle2" fontSize="12px">
                  Enable to manage leads and notifications when away.
                </MDTypography>
              </MDBox>
            </Grid>
            <Grid
              item
              xs={3}
              sm={3}
              md={4}
              lg={5}
              sx={{ justifyContent: "flex-end", display: "flex" }}
            >
              <FormControlLabel
                control={
                  <Switch
                    checked={outOfOffice || false}
                    onChange={(e: any) => setoutOfOffice(e.target.checked)}
                    inputProps={{ "aria-label": "controlled" }}
                  />
                }
                label=""
                labelPlacement="end"
              />
            </Grid>
            <Grid item xs={12} sm={12} md={8} lg={7}>
              <MDBox>
                <MDTypography variant="body2" fontWeight="medium">
                  Select Time Zone
                </MDTypography>
                <MDTypography variant="subtitle2" fontSize="12px">
                  Choose the appropriate time zone for your location
                </MDTypography>
              </MDBox>
            </Grid>
            <Grid item xs={12} sm={12} md={4} lg={5}>
              <Select
                menuPlacement="auto"
                placeholder="Select Timezone..."
                name="weekend_timezone"
                options={timezones.filter((val: any) => val.label)}
                value={
                  weekendTimezone
                    ? {
                        value: weekendTimezone,
                        label: weekendTimezone,
                      }
                    : ""
                }
                styles={style}
                isClearable
                onChange={(e: any) => onChange(e)}
              />
              {errors && errors.weekend_timezone && (
                <CustomErrorMessage message={errors.weekend_timezone} />
              )}
            </Grid>
            <Grid item xs={12} sm={8} md={8} lg={7}>
              <MDBox>
                <MDTypography variant="body2" fontWeight="medium">
                  End Date for Weekend Off
                </MDTypography>
                <MDTypography variant="subtitle2" fontSize="12px">
                  Select the end date for your weekend off period.
                </MDTypography>
              </MDBox>
            </Grid>
            <Grid
              item
              xs={12}
              sm={4}
              md={4}
              lg={5}
              sx={{ justifyContent: "flex-end", display: "flex" }}
            >
              <MDDatePicker
                name="tilledate"
                // value={
                //   weekendStartdate && weekendTimezone
                //     ? moment(weekendStartdate).tz(weekendTimezone).format()
                //     : weekendStartdate
                //     ? moment(weekendStartdate).format()
                //     : weekendStartdate
                // }
                value={moment(weekendTilldate).format()}
                input={{ placeholder: "Select Date" }}
                options={{
                  dateFormat: "Y-m-d",
                  minDate: new Date(),
                }}
                onChange={(date: any) => {
                  setWeekendTilldate(date[0]);
                }}
              />
              {errors && errors.weekend_tilldate && (
                <CustomErrorMessage message={errors.weekend_tilldate} />
              )}
            </Grid>
            {outOfOffice && (
              <>
                <Grid item xs={12} sm={8} md={8} lg={7}>
                  <MDBox>
                    <MDTypography variant="body2" fontWeight="medium">
                      Start Date for Out Of Office
                    </MDTypography>
                    <MDTypography variant="subtitle2" fontSize="12px">
                      Choose the start date for auto redirection of leads and notifications
                    </MDTypography>
                  </MDBox>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={4}
                  md={4}
                  lg={5}
                  sx={{ justifyContent: "flex-end", display: "flex" }}
                >
                  <MDDatePicker
                    name="outOfficeStartDate"
                    // value={
                    //   weekendStartdate && weekendTimezone
                    //     ? moment(weekendStartdate).tz(weekendTimezone).format()
                    //     : weekendStartdate
                    //     ? moment(weekendStartdate).format()
                    //     : weekendStartdate
                    // }
                    value={
                      outOfficeStartdate ? moment(outOfficeStartdate).format() : moment().format()
                    }
                    input={{ placeholder: "Select Date" }}
                    options={{
                      enableTime: true,
                      time_24hr: true,
                      dateFormat: "Y-m-d H:i",
                      minDate: moment(),
                      defaultHour: 0,
                      defaultMinute: 0,
                    }}
                    onChange={(date: any) => {
                      setOutOfficeStartdate(date[0]);
                    }}
                  />
                  {errors && errors.start_date && (
                    <CustomErrorMessage message={errors.start_date} />
                  )}
                </Grid>
                <Grid item xs={12} sm={8} md={8} lg={7}>
                  <MDBox>
                    <MDTypography variant="body2" fontWeight="medium">
                      End Date for Out Of Office
                    </MDTypography>
                    <MDTypography variant="subtitle2" fontSize="12px">
                      Select the end date to revert leads and notifications to your ownership
                    </MDTypography>
                  </MDBox>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={4}
                  md={4}
                  lg={5}
                  sx={{ justifyContent: "flex-end", display: "flex" }}
                >
                  <MDDatePicker
                    name="outOfficeEndDate"
                    // value={
                    //   weekendStartdate && weekendTimezone
                    //     ? moment(weekendStartdate).tz(weekendTimezone).format()
                    //     : weekendStartdate
                    //     ? moment(weekendStartdate).format()
                    //     : weekendStartdate
                    // }
                    value={outOfficeEnddate ? moment(outOfficeEnddate).format() : moment().format()}
                    input={{ placeholder: "Select Date" }}
                    options={{
                      enableTime: true,
                      time_24hr: true,
                      dateFormat: "Y-m-d H:i",
                      minDate: moment(),
                      defaultHour: 0,
                      defaultMinute: 0,
                    }}
                    onChange={(date: any) => {
                      setOutOfficeEnddate(date[0]);
                    }}
                  />
                  {errors && errors.end_date && <CustomErrorMessage message={errors.end_date} />}
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <MDBox>
                    <MDTypography variant="h5">Out of Office Lead Owner</MDTypography>
                  </MDBox>
                  <MDBox pb={3}>
                    <Grid container spacing={2}>
                      <Grid item xs={12} sm={12} md={12} lg={12}>
                        <UserWithProfilePic
                          placeholder={!dealSecondOwnerId ? "No Owner" : ""}
                          apiName="user-list-by-location"
                          responseKey="users"
                          name="users"
                          slsAPI
                          value={dealSecondOwnerId || null}
                          onChange={onChangeSecondDealOwner}
                          additionaFields={{ location_id: connectLocation?.cms_location_id }}
                        />
                        {errors && errors.secondary_owner && (
                          <CustomErrorMessage message={errors.secondary_owner} />
                        )}
                      </Grid>
                    </Grid>
                  </MDBox>
                </Grid>
              </>
            )}
            <Grid item xs={12} sm={12} md={12} lg={12} sx={{ paddingTop: "0px !important" }}>
              <MDBox>
                <MDTypography variant="body2" fontWeight="medium">
                  Weekend Message
                </MDTypography>
                <MDInput
                  multiline
                  rows={3}
                  variant="outlined"
                  fullWidth
                  name="weekend_message"
                  id="weekend_message"
                  placeholder="Create an auto-reply message for users who contact you during your weekends"
                  onChange={(e: any) => setWeekendMessage(e?.target?.value)}
                  value={weekendMessage || ""}
                />
                {errors && errors.weekend_message && (
                  <CustomErrorMessage message={errors.weekend_message} />
                )}
              </MDBox>
            </Grid>
          </Grid>
        </MDBox>
      </MDDialog>
    </Card>
  );
}

export default Weekend;
