import { createSlice, Draft, PayloadAction } from "@reduxjs/toolkit";

interface InitialState {
  downloadCenter: any;
  downloadUrl: any;
}

const initialState: InitialState = {
  downloadCenter: [],
  downloadUrl: "",
};

const downloadCenterSlice = createSlice({
  name: "location",
  initialState,
  reducers: {
    setDownloadCenter: (state: Draft<InitialState>, action: PayloadAction<any>) => ({
      ...state,
      downloadCenter: action.payload,
    }),
    setDownloadFileUrl: (state: Draft<InitialState>, action: PayloadAction<any>) => ({
      ...state,
      downloadUrl: action.payload,
    }),
  },
});

export const { setDownloadCenter, setDownloadFileUrl } = downloadCenterSlice.actions;

export default downloadCenterSlice.reducer;
