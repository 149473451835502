import { createSlice, Draft, PayloadAction } from "@reduxjs/toolkit";

interface InitialState {
  solaProsList: any;
  webPagesList: any;
  updateWebPagesRequests: any;
  solaProDetails: any;
  billingTabDetail: any;
  workHoursDetail: any;
  allConnectServices: any;
  activeServices: any;
  webPageDetails: any;
  userLocations: any;
  webpageRequestIsApproved: any;
  locationWebPagesList: any;
  newSolaProDetails: any;
}

const initialState: InitialState = {
  solaProsList: [],
  webPagesList: [],
  updateWebPagesRequests: [],
  solaProDetails: {},
  billingTabDetail: {},
  workHoursDetail: {},
  allConnectServices: [],
  activeServices: [],
  webPageDetails: {},
  userLocations: [],
  webpageRequestIsApproved: {},
  locationWebPagesList: [],
  newSolaProDetails: {},
};

const solaProSlice = createSlice({
  name: "solaProSlice",
  initialState,
  reducers: {
    solaProsList: (state: Draft<InitialState>, action: PayloadAction<any>) => ({
      ...state,
      solaProsList: action.payload,
    }),
    webPagesList: (state: Draft<InitialState>, action: PayloadAction<any>) => ({
      ...state,
      webPagesList: action.payload,
    }),
    updateWebPagesRequests: (state: Draft<InitialState>, action: PayloadAction<any>) => ({
      ...state,
      updateWebPagesRequests: action.payload,
    }),
    setSolaProDetails: (state: Draft<InitialState>, action: PayloadAction<any>) => ({
      ...state,
      solaProDetails: action.payload,
    }),
    setBillingTabDetail: (state: Draft<InitialState>, action: PayloadAction<any>) => ({
      ...state,
      billingTabDetail: action.payload,
    }),
    setWorkHoursDetail: (state: Draft<InitialState>, action: PayloadAction<any>) => ({
      ...state,
      workHoursDetail: action.payload,
    }),
    setAllConnectServices: (state: Draft<InitialState>, action: PayloadAction<any>) => ({
      ...state,
      allConnectServices: action.payload,
    }),
    setActiveServices: (state: Draft<InitialState>, action: PayloadAction<any>) => ({
      ...state,
      activeServices: action.payload,
    }),
    setWebPageDetail: (state: Draft<InitialState>, action: PayloadAction<any>) => ({
      ...state,
      webPageDetails: action.payload,
    }),
    setUserLocations: (state: Draft<InitialState>, action: PayloadAction<any>) => ({
      ...state,
      userLocations: action.payload,
    }),
    setWebpageRequestIsApproved: (state: Draft<InitialState>, action: PayloadAction<any>) => ({
      ...state,
      webpageRequestIsApproved: action.payload,
    }),
    setLocationWebPagesList: (state: Draft<InitialState>, action: PayloadAction<any>) => ({
      ...state,
      locationWebPagesList: action.payload,
    }),
    setNewSolaProDetails: (state: Draft<InitialState>, action: PayloadAction<any>) => ({
      ...state,
      newSolaProDetails: action.payload,
    }),
  },
});

export const {
  solaProsList,
  webPagesList,
  updateWebPagesRequests,
  setSolaProDetails,
  setBillingTabDetail,
  setWorkHoursDetail,
  setAllConnectServices,
  setActiveServices,
  setWebPageDetail,
  setUserLocations,
  setWebpageRequestIsApproved,
  setLocationWebPagesList,
  setNewSolaProDetails,
} = solaProSlice.actions;

export default solaProSlice.reducer;
