import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "store/store";
import { useNavigate } from "react-router-dom";
import MDBox from "components/MDBox";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import OperationsDashboard from "layouts/dashboards/operations-dashboard";
import MarketingDashboard from "layouts/dashboards/marketing-dashboard";
import constants from "helper/constants";
import { getLast12Months, getAuthUser, getPermission, systemAdmin } from "helper/services";
import { AppBar, Grid, Tab, Tabs, FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import { getDashboardData } from "store/thunk/locationThunk";
import MDTabPanel from "components/MDTabPanel";
// import moment from "moment";

function Index(): JSX.Element {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { dashboardData } = useAppSelector((state) => state.locationSlice);
  const [lastMonths, setLastMonths] = useState([]);
  const [tabValue, setTabValue] = useState<number>(0);
  const [page, setPage] = useState<number>(0);
  const [perPage, setPerPage] = useState<number>(4);
  const [range, setRange] = useState<any>("last_6_month");
  const [showLoadMore, setShowLoadMore] = useState<any>(true);
  const [dashboardDetails, setDashboardDetails] = useState<any>([]);
  const [fullDashbardData, setFullDashbardData] = useState<any>([]);

  useEffect(() => {
    if (dashboardData?.connect_dashboard_data?.length) {
      setShowLoadMore(true);
      setDashboardDetails(dashboardData?.connect_dashboard_data);
      if (dashboardData?.meta?.total_pages <= page + 1) setShowLoadMore(false);
    } else {
      setShowLoadMore(false);
    }
    setFullDashbardData(dashboardData);
  }, [dashboardData]);

  const callDashboarAPI = (no?: number, count?: number, rangeVal?: any) => {
    const req = {
      location_ids: localStorage.getItem("selected_locations")
        ? JSON.parse(localStorage.getItem("selected_locations"))
        : [],
      month: rangeVal ? rangeVal : "last_6_month",
      page: no || no === 0 ? no : page,
      perPage: count ? count : perPage,
    };

    dispatch(getDashboardData(req));
  };

  useEffect(() => {
    const authUser = getAuthUser();
    if (authUser && !systemAdmin()) {
      const checkDashboardAccess = authUser?.access.filter(
        (obj: any) =>
          obj.alias === "dashboard_marketing" ||
          obj.alias === "dashboard_franchisee" ||
          obj.alias === "dashboard_solapro"
      );
      if (getPermission("sola_pros", "sola_pros_sola_pros")) {
        navigate("/sola-pros");
      } else if (!checkDashboardAccess.length && getPermission("locations", "locations_location")) {
        navigate("/locations");
      }
    }
    setLastMonths(getLast12Months());
    callDashboarAPI();
  }, []);

  const changeTab = (e: any, value: any) => {
    setTabValue(value);
  };

  const onChangePage = () => {
    setPage(page + 1);
    setPerPage(perPage);
    callDashboarAPI(page + 1, perPage, range);
  };
  const attr = {
    page,
    perPage,
    range,
    showLoadMore,
    onChangePage,
    dashboardDetails,
    fullDashbardData,
  };

  const getComponent = (alias: any) => {
    switch (alias) {
      case "operations":
        return <OperationsDashboard {...attr} />;
      case "marketing":
        return <MarketingDashboard />;
      default:
        return null;
    }
  };

  const onChangeField = (event: any) => {
    setRange(event?.target?.value || "last_6_month");
    setPage(0);
    callDashboarAPI(0, perPage, event?.target?.value || "last_6_month");
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox p={2}>
        <MDBox>
          <Grid container>
            <Grid item xs={12} sm={8} lg={6}>
              <AppBar position="static">
                <Tabs className="location-tabs" value={tabValue} onChange={changeTab}>
                  {constants?.dashboardTabs?.map((obj: any) => (
                    <Tab label={obj.display_name} key={obj.alias} />
                  ))}
                </Tabs>
              </AppBar>
            </Grid>
            <Grid item xs={12} sm={4} lg={6}>
              {tabValue !== 2 && (
                <MDBox
                  display="flex"
                  justifyContent="flex-end"
                  sx={{ marginTop: { xs: 2, md: 0 } }}
                >
                  <FormControl
                    sx={{
                      backgroundColor: "#fff",
                      borderRadius: "10px",
                      width: { xs: "100%", md: "150px" },
                    }}
                  >
                    <InputLabel id="demo-simple-select-label">Select Range</InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={range || ""}
                      name="range"
                      label="Select Range"
                      className="height45"
                      onChange={onChangeField}
                    >
                      <MenuItem key="last_6_month" value="last_6_month">
                        Last 6 Months
                      </MenuItem>
                      <MenuItem key="last_12_month" value="last_12_month">
                        Last 12 Months
                      </MenuItem>

                      {lastMonths &&
                        lastMonths?.length &&
                        lastMonths.map((month: any) => (
                          <MenuItem key={month} value={month}>
                            {month}
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                </MDBox>
              )}
            </Grid>
          </Grid>
        </MDBox>
        <MDBox>
          {constants?.dashboardTabs?.map((obj: any, key: number) => (
            <MDTabPanel value={tabValue} index={key} padding={0} key={obj.alias}>
              {getComponent(obj.alias)}
            </MDTabPanel>
          ))}
        </MDBox>
      </MDBox>
    </DashboardLayout>
  );
}

export default Index;
