import { useEffect, useState } from "react";
import {
  Card,
  Grid,
  Icon,
  IconButton,
  InputBase,
  MenuItem,
  Paper,
  Select,
  Stack,
} from "@mui/material";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDAvatar from "components/MDAvatar";
import userDefaultImg from "assets/images/user_default_image.png";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import MDButton from "components/MDButton";
import CustomBackButton from "components/CustomBackButton";
import CancelRequest from "../cancelRequest";

function Index(): JSX.Element {
  const [sendType, setSendType] = useState("send_reply");
  const [cancelRequest, setCancelRequest] = useState<any>(false);
  const [historyState, setHistoryState] = useState<{}>({});

  const params = useParams();
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (location?.state) {
      setHistoryState(location.state);
    } else {
      setHistoryState({});
    }
  }, [location]);

  const handleChange = (event: any) => {
    setSendType(event.target.value as string);
  };

  const closeCancelReqModal = () => {
    setCancelRequest(false);
  };

  const onBackBtnClick = (historyState: any) => {
    navigate(historyState.prevPath, {
      state: { defaultTab: historyState.defaultTab },
      replace: true,
    });
  };

  const messages = [
    {
      senderName: "Sola Pro",
      sender: true,
      receiverName: "Property Manager",
      message:
        "This is test text. This is test text. This is test text. This is test text. This is test text. This is test text. This is test text. This is test text. This is test text.",
    },
    {
      senderName: "Property Manager",
      sender: false,
      receiverName: "Sola Pro",
      message:
        "This is test text. This is test text. This is test text. This is test text. This is test text. This is test text. This is test text. This is test text. This is test text.",
    },
    {
      senderName: "Sola Pro",
      sender: true,
      receiverName: "Property Manager",
      message:
        "This is test text. This is test text. This is test text. This is test text. This is test text. This is test text. This is test text. This is test text. This is test text.",
    },
    {
      senderName: "Sola Pro",
      sender: true,
      receiverName: "Property Manager",
      message:
        "This is test text. This is test text. This is test text. This is test text. This is test text. This is test text. This is test text. This is test text. This is test text.",
    },
    {
      senderName: "Property Manager",
      sender: false,
      receiverName: "Sola Pro",
      message:
        "This is test text. This is test text. This is test text. This is test text. This is test text. This is test text. This is test text. This is test text. This is test text.",
    },
    {
      senderName: "Sola Pro",
      sender: true,
      receiverName: "Property Manager",
      message:
        "This is test text. This is test text. This is test text. This is test text. This is test text. This is test text. This is test text. This is test text. This is test text.",
    },
  ];

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox mx={2}>
        <CustomBackButton label="Back To Sola Pros Details" historyState={historyState} />
      </MDBox>
      <MDBox my={4} mx={2}>
        <MDBox
          display="inline-flex"
          alignItems="center"
          justifyContent="space-between"
          width="100%"
        >
          <MDTypography variant="h4" fontWeight="bold">
            Facility Requests #{params.reqId}
          </MDTypography>
        </MDBox>
        <MDBox className="chat-status-container">
          <MDBox display="flex" flexDirection="column">
            <MDTypography variant="button" fontWeight="regular">
              <MDTypography
                variant="subtitle2"
                fontWeight="medium"
                component="span"
                sx={{ paddingRight: "10px" }}
              >
                Status:
              </MDTypography>
              <MDTypography variant="body2" component="span">
                In Progress
              </MDTypography>
            </MDTypography>
            <MDTypography variant="button" fontWeight="regular">
              <MDTypography
                variant="subtitle2"
                fontWeight="medium"
                component="span"
                sx={{ paddingRight: "10px" }}
              >
                Expected Resolution Date:
              </MDTypography>
              <MDTypography variant="body2" component="span">
                05/07/2022
              </MDTypography>
            </MDTypography>
          </MDBox>
        </MDBox>
        <Card className="crm-deals-chat-container">
          {messages.map((val) => (
            <div
              className={
                val.sender ? "end-message-chatbox message-row" : "start-message-chatbox message-row"
              }
            >
              <div className="message-content">
                <MDAvatar className="avatar-image" src={userDefaultImg} />
                <div className="message-text">
                  <MDTypography variant="button" fontWeight="regular">
                    {val.message}
                  </MDTypography>
                  <MDTypography variant="caption" fontWeight="regular" className="message-time">
                    {val.senderName}
                  </MDTypography>
                </div>
              </div>
            </div>
          ))}
        </Card>
        <Grid container paddingTop={1}>
          <Paper
            component="form"
            className="reply-container"
            sx={{ p: "2px 4px", display: "flex", alignItems: "center", width: "100%" }}
          >
            <Select
              labelId="status"
              id="status"
              className="send-reply-option"
              value={sendType}
              onChange={(event: any) => handleChange(event)}
            >
              <MenuItem value="send_reply">Send reply</MenuItem>
              <MenuItem value="send_save">Save Note</MenuItem>
            </Select>
            <InputBase
              className="reply-text-box"
              placeholder="Type something...."
              inputProps={{ "aria-label": "Type something...." }}
            />
            <IconButton
              type="button"
              sx={{ p: "10px", transform: "rotate(-45deg)" }}
              aria-label="send"
            >
              <Icon fontSize="small">send</Icon>
            </IconButton>
          </Paper>
        </Grid>
        <Stack spacing={2} direction="row" mt={3}>
          <MDButton
            variant="gradient"
            color="light"
            size="small"
            className="xs-small"
            onClick={() => onBackBtnClick(historyState)}
          >
            Back
          </MDButton>
          <MDButton
            variant="text"
            color="error"
            size="small"
            className="xs-small"
            onClick={() => setCancelRequest(true)}
          >
            cancel Request
          </MDButton>
        </Stack>
        {cancelRequest && (
          <CancelRequest open={cancelRequest} onClose={() => closeCancelReqModal()} />
        )}
      </MDBox>
    </DashboardLayout>
  );
}

export default Index;
