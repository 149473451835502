import { useState, useCallback, useRef, useEffect, useLayoutEffect } from "react";
import { Card, Icon, Checkbox } from "@mui/material";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import DataTable from "examples/Tables/DataTable";
import {
  getFranchiseBlogs,
  createFranchiseBlogs,
  deleteFranchiseeBlogs,
} from "store/thunk/franchiseeWebsiteThunk";
import { useAppDispatch, useAppSelector } from "store/store";
import MDDialog from "components/MDDialog";
import MDTypography from "components/MDTypography";
import Messages from "helper/messages";
import { getLocalDateTime, setDefaultSelectedColumns } from "helper/services";
import exportData from "helper/exportTableConst";
import MDIconButton from "components/MDIconButton";
import { getDefaultCoulmns, saveSelectedAllData } from "store/thunk/connectUserThunk";
import { setDefaultsColumns } from "store/slices/connectUsersSlice";
import AddUpdate from "./addUpdate";
import ViewDetails from "./viewDetails";
// import { setLocation as setLocationToStore } from "store/slices/franchiseSlice";

function Listing({ kind }: any): JSX.Element {
  const [addUpdatePopup, setAddUpdatePopup] = useState(false);
  const [blog, setblog] = useState({});
  const [blogList, setBlogList] = useState([]);
  const [selectedItems, setSelectedItems] = useState([]);
  const { franchiseBlog } = useAppSelector((state) => state.franchiseeWebsiteSlice);
  const { userData } = useAppSelector((state) => state.authReducer);
  const [pageCount, setPageCount] = useState(0);
  const [pageNo, setPageNo] = useState(0);
  const [totalCount, setTotalCount] = useState(0);
  const [deleteBlogId, setDeleteBlogId] = useState<any>(null);
  const [viewPopup, setViewPopup] = useState(false);
  const [search, setSearch] = useState<any>(null);
  const [sortBy, setSortBy] = useState<any>([]);
  const [perPage, setPageSize] = useState(50);
  const [isCheckAll, setIsCheckAll] = useState(false);
  const [defaultExport, setDefaultExport] = useState<any>([]);
  const [callFetch, setCallFetch] = useState<any>(false);
  const [defaultSorting, setDefaultSorting] = useState<any>([]);
  const [defaultFilteing, setDefaultFilteing] = useState<any>([]);

  const getColumns = () => {
    const columns: any = [
      {
        Header: "",
        disableSortBy: true,
        width: "100px",
        accessor: "checkbox",
      },
      { Header: "Title", accessor: "title", export: true },
      { Header: "Url", accessor: "url", export: true },
      { Header: "Summary", accessor: "summary", export: true },
      { Header: "Author", accessor: "author", export: true },
      { Header: "Created At", accessor: "created_at", export: true, is_date: true },
      { Header: "Action", accessor: "action", width: "200px", export: false, disableSortBy: true },
    ];
    return columns;
  };
  const [checkedColumns, setCheckedColumns] = useState<any>(getColumns());
  const latestRefValue = useRef(checkedColumns);

  useEffect(() => {
    latestRefValue.current = checkedColumns;
  }, [checkedColumns]);

  useEffect(() => {
    if (franchiseBlog.franchise_articles && franchiseBlog.franchise_articles.length) {
      setBlogList(franchiseBlog.franchise_articles);
      setPageCount(franchiseBlog?.meta?.total_pages);
      setTotalCount(franchiseBlog?.meta?.total_count);
    }
  }, [franchiseBlog]);
  const dispatch = useAppDispatch();

  const fetchIdRef = useRef(0);

  const fetchData = useCallback(({ pageSize, pageIndex, search, sortBy, filterData, isManual }) => {
    const fetchId = ++fetchIdRef.current;
    const sort: any = sortBy || [];

    // We'll even set a delay to simulate a server here
    setTimeout(() => {
      if (fetchId === fetchIdRef.current) {
        const sentReq = {
          sendData: {
            user_id: userData?.user_id,
            table_name: `${exportData.franchise_articles.table_name}_${kind}`,
            table_columns: latestRefValue.current ? latestRefValue.current : checkedColumns, // Changes in checkedColumns
            table_sort: sortBy && sortBy?.length ? sortBy : [],
            table_filter: filterData && filterData?.length ? filterData : [],
            default_export_data: defaultExport,
          },
        };
        if (isManual) {
          dispatch(saveSelectedAllData(sentReq));
        }
        setPageNo(pageIndex);
        setSearch(search);
        setSortBy(sort);
        setPageSize(pageSize);
        // setSelectedItems([]);
        setIsCheckAll(false);
        dispatch(getFranchiseBlogs({ pageSize, pageIndex, search, sort, kind, filterData }));
      }
    }, 1000);
  }, []);

  useLayoutEffect(() => {
    dispatch(setDefaultsColumns([]));
    // Set data in variable and Call defaultColums Thunk
    const sentReq = {
      user_id: userData?.user_id,
      table_name: `${exportData.franchise_articles.table_name}_${kind}`,
    };
    dispatch(getDefaultCoulmns(sentReq)).then((resp: any) => {
      // SD-3199
      if (resp?.payload) {
        const respFilter = resp?.payload?.table_filter || [];
        const respSorting = resp?.payload?.table_sort || [];
        const defaultDBexportData = resp?.payload?.default_export_data || [];
        const defaultDBColumn = resp?.payload?.table_columns || [];
        const tempdata = setDefaultSelectedColumns(defaultDBColumn, getColumns());
        setCheckedColumns(tempdata);
        setDefaultExport(defaultDBexportData);
        setDefaultSorting(respSorting);
        setSortBy(respSorting);
        setDefaultFilteing(respFilter);
        setCallFetch(true);
        fetchData({
          perPage,
          pageNo,
          search,
          sortBy: respSorting && respSorting?.length ? respSorting : [],
          additionalField: null,
          filterData: respFilter && respFilter?.length ? respFilter : [],
        });
      }
    });
  }, []);

  const onChangeCheckBox = (e: any) => {
    let items = JSON.parse(JSON.stringify(selectedItems));
    if (e.target.checked && !items.includes(e.target.value)) {
      items.push(e.target.value);
      if (items.length === blogList.length) {
        setIsCheckAll(true);
      }
    } else {
      items = items.filter((id: any) => id !== e.target.value);
      setIsCheckAll(false);
    }
    setSelectedItems(items);
  };

  const checkUncheckAll = (e: any) => {
    let allIds: any = [];
    allIds = blogList.map((val: any) => String(val.id));
    if (e.target.checked) {
      const selectedIds = [...new Set([...selectedItems, ...allIds])];
      setSelectedItems(selectedIds);
      setIsCheckAll(true);
    } else {
      allIds = blogList.map((val: any) => String(val.id));
      allIds = selectedItems.filter((el: any) => !allIds.includes(el));
      setSelectedItems([...allIds]);
      setIsCheckAll(false);
    }
    // setSelectedItems(allIds);
  };

  const actionButtons = (obj: any) => (
    <>
      <MDIconButton
        tooltipName="View"
        aria-label="Open"
        color="info"
        onClick={() => {
          setblog(obj);
          setViewPopup(true);
        }}
      >
        <Icon fontSize="small">visibility</Icon>
      </MDIconButton>
      <MDIconButton
        tooltipName="Edit"
        aria-label="Edit"
        color="info"
        onClick={() => {
          setblog(obj);
          setAddUpdatePopup(true);
        }}
      >
        <Icon fontSize="small">edit</Icon>
      </MDIconButton>
      <MDIconButton
        tooltipName="Delete"
        aria-label="Delete"
        color="error"
        onClick={() => setDeleteBlogId(obj.id)}
      >
        <Icon fontSize="small">delete</Icon>
      </MDIconButton>
    </>
  );

  const getRows = () => {
    const data: any = blogList.map((obj: any) => ({
      checkbox: (
        <Checkbox
          checked={selectedItems.includes(obj.id.toString())}
          onChange={(e: any) => onChangeCheckBox(e)}
          value={obj.id}
        />
      ),
      // For check all condition
      id: obj.id ? obj.id : "-",
      title: obj.title || "-",
      url: obj.url || "-",
      summary: obj.summary || "-",
      author: obj.author || "-",
      created_at: obj.created_at ? getLocalDateTime(obj.created_at) : "-",
      action: actionButtons(obj),
    }));
    return data;
  };

  const tableData = {
    columns: getColumns(),
    rows: getRows(),
  };

  const closePopup = () => {
    setAddUpdatePopup(false);
    setViewPopup(false);
    setblog({});
  };

  const createNewBlog = (blogData: any, isUpdate: any) => {
    dispatch(
      createFranchiseBlogs({
        blogData,
        onClose: closePopup,
        isUpdate,
        pageNo,
        perPage,
        search,
        sortBy,
        kind,
      })
    );
  };

  // Start Remove selcted items
  const removeSelectedItem = (deletedId: number) => {
    setSelectedItems(selectedItems.filter((item: any) => Number(item) !== deletedId));
  };
  // End Remove selcted items

  const onDelete = () => {
    if (deleteBlogId) {
      const delReq = {
        id: deleteBlogId,
        setDeleteBlogId,
        pageNo,
        perPage,
        search,
        sortBy,
        kind,
        removeSelectedItem,
      };
      dispatch(deleteFranchiseeBlogs(delReq));
    }
  };

  const deleteDialogProps = {
    open: deleteBlogId,
    onClose: () => setDeleteBlogId(false),
    onSave: () => onDelete(),
    title: "Delete Franchisee Press & Blog",
    size: "sm",
    saveBtn: false,
    deleteBtn: true,
    saveTbtText: "Yes",
    closeBtnText: "No",
  };

  // Remove uncheck boxes
  const clearCheckItem = () => {
    setSelectedItems([]);
  };

  return (
    <MDBox pt={4} pb={3}>
      <Card>
        <MDBox
          bgColor="dark"
          color="white"
          coloredShadow="dark"
          borderRadius="xl"
          alignItems="center"
          justifyContent="space-between"
          pt={1}
          pb={1}
          pl={2}
          pr={2}
          ml={2}
          mr={2}
          display="flex"
          mt={-3}
          className="page-header"
        >
          <p className="page-header-label">Press & Blog</p>
          <MDButton
            variant="gradient"
            color="light"
            size="small"
            onClick={() => setAddUpdatePopup(true)}
            className="xs-small"
          >
            <Icon sx={{ fontWeight: "bold" }}>add</Icon> Add Press & Blog
          </MDButton>
        </MDBox>
        {(defaultSorting || defaultSorting?.length) && callFetch && (
          <DataTable
            defaultSortingColumns={defaultSorting}
            defaultFilteringColums={defaultFilteing}
            table={tableData}
            canSearch
            searchText="Search..."
            selectedItems={selectedItems}
            showExportSelectedBtn
            showExportAllBtn
            pageCount={pageCount}
            pageNo={pageNo}
            setPageNo={setPageNo}
            totalCount={totalCount}
            fetchData={fetchData}
            exportFields={exportData.franchise_articles.export_fields}
            exportTable={exportData.franchise_articles.table_name}
            exportType={exportData.franchise_articles.export_type}
            advanceFilter
            exportSection={kind || null}
            manualSearch
            manualSort
            isDisplayCheckAllCheckbox
            onChangeCheckAllCheckbox={checkUncheckAll}
            isCheckAll={isCheckAll}
            clearCheckItem={clearCheckItem}
            kind={kind}
            useRubyApiToExport
          />
        )}
        {addUpdatePopup && (
          <AddUpdate
            open={addUpdatePopup}
            onClose={closePopup}
            blog={blog}
            onCreateUpdate={createNewBlog}
          />
        )}
        {viewPopup && (
          <ViewDetails
            open={viewPopup}
            onClose={() => {
              setViewPopup(false);
            }}
            viewData={blog}
          />
        )}
        {deleteBlogId && (
          <MDDialog {...deleteDialogProps}>
            <MDTypography variant="h6" fontWeight="medium">
              {Messages.GENERAL.SURE_TO_DELETE}
            </MDTypography>
          </MDDialog>
        )}
      </Card>
    </MDBox>
  );
}

export default Listing;
