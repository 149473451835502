import {
  FormHelperText,
  FormControl,
  IconButton,
  Input,
  InputLabel,
  InputAdornment,
} from "@mui/material";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { useState } from "react";

export default function Index({
  label,
  value,
  name,
  placeholder,
  required,
  error,
  helperText,
  onChange,
}: any) {
  const [showPassword, setshowPassword] = useState<boolean>(false);

  const handleClickShowPassword = () => {
    setshowPassword(!showPassword);
  };

  return (
    <FormControl sx={{ width: "100%" }} variant="standard" className="login-component">
      <InputLabel error={error} htmlFor="standard-adornment-password">
        {label}
        {required ? <span className="required_star">*</span> : ""}
      </InputLabel>
      <Input
        id="standard-adornment-password"
        type={showPassword ? "text" : "password"}
        value={value}
        onChange={onChange}
        name={name}
        error={error}
        placeholder={placeholder}
        required={required}
        endAdornment={
          <InputAdornment position="end">
            <IconButton aria-label="toggle password visibility" onClick={handleClickShowPassword}>
              {showPassword ? <Visibility /> : <VisibilityOff />}
            </IconButton>
          </InputAdornment>
        }
      />
      <FormHelperText error={error}>{helperText}</FormHelperText>
    </FormControl>
  );
}
