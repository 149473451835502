import { useCallback, useEffect, useLayoutEffect, useRef, useState } from "react";
import { Card, Icon, Checkbox } from "@mui/material";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import DataTable from "examples/Tables/DataTable";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "store/store";
import {
  setDefaultSelectedColumns,
  getLocalDateTime,
  insertinArray,
  systemAdmin,
  getUserFullName,
} from "helper/services";
import { getAllImportedDealsList } from "store/thunk/deal-import-thunk";
// import exportData from "helper/exportTableConst";
import MDIconButton from "components/MDIconButton";
import { getDefaultCoulmns } from "store/thunk/connectUserThunk"; // saveSelectedAllData
import { setDefaultsColumns } from "store/slices/connectUsersSlice";
import ImportPopup from "./importPopup";

function Index(): JSX.Element {
  const dispatch = useAppDispatch();
  const [impportPopup, setImportPopup] = useState<any>(false);
  const [updateData, setUpdateData] = useState<any>("");
  const { importDealList } = useAppSelector((state) => state.dealImportSlice);
  const { defaultSelectedColumns } = useAppSelector((state) => state.connectUsersReducer);
  const { userData } = useAppSelector((state) => state.authReducer);
  const [selectedItems, setSelectedItems] = useState([]);
  const [importedDealListData, setImportedDealList] = useState([]);
  const [pageCount, setPageCount] = useState(0);
  const [pageNo, setPageNo] = useState(0);
  const [totalCount, setTotalCount] = useState(0);
  const [perPage, setPageSize] = useState(50);
  const [search, setSearch] = useState<any>(null);
  const [sortBy, setSortBy] = useState<any>([]);
  const [displayColumns, setDisplayColumns] = useState<any>([]);
  // const [defaultExport, setDefaultExport] = useState<any>([]);
  const [callFetch, setCallFetch] = useState<any>(false);
  const [defaultSorting, setDefaultSorting] = useState<any>([]);
  const [defaultFilteing, setDefaultFilteing] = useState<any>([]);

  // Get columns
  const getColumns = () => {
    let columns: any = [
      {
        Header: "",
        disableSortBy: true,
        width: "100px",
        accessor: "checkbox",
        default: true,
        checked: true,
      },
      {
        Header: "Location",
        accessor: "location",
        export: true,
        disableCheck: true,
        checked: true,
      },
      {
        Header: "Location ID",
        accessor: "location_id",
        export: false,
        disableCheck: true,
        checked: true,
      },
      {
        Header: "Number Of Leads",
        accessor: "no_of_leads",
        export: true,
        checked: true,
      },
      {
        Header: "Uploaded By",
        accessor: "uploaded_by",
        export: true,
        checked: true,
      },
      {
        Header: "Status",
        accessor: "status",
        export: true,
        checked: true,
      },
      {
        Header: "Created at",
        accessor: "created_at",
        width: "200px",
        export: true,
        is_date: true,
        checked: true,
      },
      {
        Header: "Updated at",
        accessor: "updated_at",
        width: "200px",
        export: true,
        is_date: true,
        checked: false,
      },
      {
        Header: "Action",
        accessor: "action",
        width: "200px",
        export: false,
        disableSortBy: true,
        checked: true,
      },
    ];
    if (systemAdmin()) {
      columns = insertinArray(columns, 1, {
        Header: "ID",
        accessor: "id",
        export: true,
        disableCheck: true,
        checked: true,
      });
    }
    return columns;
  };

  const [checkedColumns, setCheckedColumns] = useState<any>(getColumns());
  const [isCheckAll, setIsCheckAll] = useState(false);
  const navigate = useNavigate();
  const latestRefValue = useRef(checkedColumns);

  useEffect(() => {
    latestRefValue.current = checkedColumns;
  }, [checkedColumns]);

  const fetchIdRef = useRef(0);

  const fetchData = useCallback(({ pageSize, pageIndex, search, sortBy, filterData }) => {
    const fetchId = ++fetchIdRef.current;
    const sort: any = sortBy || [];
    // We'll even set a delay to simulate a server here
    setTimeout(() => {
      if (fetchId === fetchIdRef.current) {
        // const sentReq = {
        //   sendData: {
        //     user_id: userData?.user_id,
        //     table_name: exportData.msas.table_name,
        //     table_columns: latestRefValue.current && latestRefValue.current,
        //     table_sort: sortBy,
        //     table_filter: filterData,
        //     default_export_data: defaultExport,
        //   },
        // };
        // if (isManual) {
        //   dispatch(saveSelectedAllData(sentReq));
        // }

        setPageNo(pageIndex);
        setSearch(search);
        setSortBy(sort);
        setPageSize(pageSize);
        setSelectedItems([]);
        setIsCheckAll(false);
        dispatch(
          getAllImportedDealsList({ pageSize, pageIndex, search, sort, userData, filterData })
        );
      }
    }, 1000);
  }, []);

  useLayoutEffect(() => {
    dispatch(setDefaultsColumns([]));
    const sentReq = {
      user_id: userData?.user_id,
      table_name: "connect_imported_deals",
    };
    dispatch(getDefaultCoulmns(sentReq)).then((resp: any) => {
      const respFilter = resp?.payload?.table_filter || [];
      const respSorting = resp?.payload?.table_sort || [];
      // const defaultDBexportData = resp?.payload?.default_export_data || [];
      const defaultDBColumn = resp?.payload?.table_columns || [];
      const tempdata = setDefaultSelectedColumns(defaultDBColumn, getColumns());
      setCheckedColumns(tempdata);
      // setDefaultExport(defaultDBexportData);
      setDefaultSorting(respSorting);
      setSortBy(respSorting);
      setDefaultFilteing(respFilter);
      if (resp?.payload) {
        setCallFetch(true);
        fetchData({
          perPage,
          pageNo,
          search,
          sortBy: respSorting && respSorting?.length ? respSorting : [],
          additionalField: null,
          filterData: respFilter && respFilter?.length ? respFilter : [],
        });
      }
    });
  }, []);

  useEffect(() => {
    if (checkedColumns?.length) {
      setDisplayColumns(checkedColumns.filter((col: any) => col.checked));
    }
  }, [checkedColumns]);

  useEffect(() => {
    if (importDealList && importDealList?.connect_imported_deals) {
      setImportedDealList(importDealList?.connect_imported_deals);
      setPageCount(importDealList?.meta?.total_pages);
      setTotalCount(importDealList?.meta?.total_count);
    }
  }, [importDealList]);

  useEffect(() => {
    if (defaultSelectedColumns && defaultSelectedColumns?.length) {
      const newColumns = setDefaultSelectedColumns(defaultSelectedColumns, getColumns());
      setCheckedColumns(newColumns);
    } else {
      setCheckedColumns(getColumns());
    }
  }, [defaultSelectedColumns]);

  const closeModal = () => {
    setUpdateData("");
    setImportPopup(false);
  };

  const gotoDetail = (obj: any) => {
    navigate(`/crm/import-deals/${obj?.id}`, {
      state: { prevPath: "/crm/import-deals/", id: obj?.id },
    });
  };

  const actionButtons = (object: any) => (
    <MDIconButton
      tooltipName="View"
      aria-label="Info"
      color="info"
      onClick={() => gotoDetail(object)}
    >
      <Icon fontSize="small">visibility</Icon>
    </MDIconButton>
  );

  const onChangeCheckBox = (e: any) => {
    let items = JSON.parse(JSON.stringify(selectedItems));
    if (e.target.checked && !items.includes(e.target.value)) {
      items.push(e.target.value);
      if (items.length === importedDealListData?.length) {
        setIsCheckAll(true);
      }
    } else {
      items = items.filter((id: any) => id !== e.target.value);
      setIsCheckAll(false);
    }
    setSelectedItems(items);
  };

  const checkUncheckAll = (e: any) => {
    let allIds: any = [];
    allIds = importedDealListData.map((val: any) => String(val.id));
    if (e.target.checked) {
      const selectedIds = [...new Set([...selectedItems, ...allIds])];
      setSelectedItems(selectedIds);
      // allIds = msas.map((val: any) => String(val.id));
      setIsCheckAll(true);
    } else {
      allIds = importedDealListData.map((val: any) => String(val.id));
      allIds = selectedItems.filter((el: any) => !allIds.includes(el));
      setSelectedItems([...allIds]);
      setIsCheckAll(false);
      // allIds = [];
      // setIsCheckAll(false);
    }
    // setSelectedItems(allIds);
  };

  const getRows = () => {
    const data: any = importedDealListData.map((obj: any) => ({
      checkbox: (
        <Checkbox
          checked={selectedItems.includes(obj.id.toString())}
          onChange={(e: any) => onChangeCheckBox(e)}
          value={obj.id}
        />
      ),
      id: obj.id ? obj.id : "-",
      location: obj?.location?.name ? obj?.location?.name : "-",
      location_id: obj?.location?.id ? obj?.location?.id : "-",
      no_of_leads: obj?.no_of_leads ? obj?.no_of_leads : "0",
      uploaded_by: obj?.user?.user_id ? getUserFullName(obj?.user) : "-",
      status: obj?.status ? obj?.status : "-",
      created_at: obj?.created_at ? getLocalDateTime(obj?.created_at) : "-",
      updated_at: obj?.updated_at ? getLocalDateTime(obj?.updated_at) : "-",
      action: actionButtons(obj),
    }));
    return data;
  };

  const tableData = {
    columns: getColumns(),
    rows: getRows(),
  };

  // Remove uncheck boxes
  const clearCheckItem = () => {
    setSelectedItems([]);
  };

  const downloadSampleFile = () => {
    const link = document.createElement("a");
    link.href = "/sample.csv";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={4} pb={3}>
        <Card>
          <MDBox
            bgColor="dark"
            color="white"
            coloredShadow="dark"
            borderRadius="xl"
            alignItems="center"
            justifyContent="space-between"
            pt={1}
            pb={1}
            pl={2}
            pr={2}
            ml={2}
            mr={2}
            display="flex"
            mt={-3}
            className="page-header"
          >
            <p className="page-header-label">Imported Deals List</p>
            <MDBox>
              <MDButton
                variant="gradient"
                color="light"
                size="small"
                className="xs-small"
                onClick={() => setImportPopup(true)}
              >
                <Icon sx={{ fontWeight: "bold" }}>add</Icon> Import
              </MDButton>
              <MDButton
                variant="gradient"
                color="light"
                size="small"
                className="xs-small"
                sx={{ marginLeft: 1 }}
                onClick={downloadSampleFile}
              >
                <Icon sx={{ fontWeight: "bold" }}>download</Icon> Download sample file
              </MDButton>
            </MDBox>
          </MDBox>
          {(defaultSorting || defaultSorting?.length) &&
            callFetch && ( // SD-3199
              <DataTable
                defaultSortingColumns={defaultSorting} // SD-3199
                defaultFilteringColums={defaultFilteing} // SD-3199
                defaultDisplayColumns={displayColumns}
                setCheckedColumns={setCheckedColumns}
                table={tableData}
                tableSetting="connect_imported_deals"
                canSearch
                // searchText="Search..."
                selectedItems={selectedItems}
                // showExportSelectedBtn
                // showExportAllBtn
                pageCount={pageCount}
                pageNo={pageNo}
                setPageNo={setPageNo}
                totalCount={totalCount}
                fetchData={fetchData}
                // exportFields={exportData.msas.export_fields}
                // exportTable={exportData.msas.table_name}
                // exportType={exportData.msas.export_type}
                // advanceFilter
                manualSearch
                manualSort
                isDisplayCheckAllCheckbox
                onChangeCheckAllCheckbox={checkUncheckAll}
                isCheckAll={isCheckAll}
                checkedColumns={checkedColumns}
                clearCheckItem={clearCheckItem}
                // useRubyApiToExport
              />
            )}
          {impportPopup && (
            <ImportPopup
              open={impportPopup}
              pageNo={pageNo}
              perPage={perPage}
              search={search}
              sortBy={sortBy}
              updateData={updateData}
              filterData={defaultFilteing}
              userData={userData}
              onClose={() => closeModal()}
            />
          )}
        </Card>
      </MDBox>
    </DashboardLayout>
  );
}

export default Index;
