import { useState } from "react";
import MDDialog from "components/MDDialog";
import Autocomplete from "@mui/material/Autocomplete";
import MDInput from "components/MDInput";
import { FormControlLabel, Grid, InputLabel, Link, Switch } from "@mui/material";
import MDTypography from "components/MDTypography";
import FormField from "layouts/pages/account/components/FormField";
import MDButton from "components/MDButton";

function MakePayment(props: any): JSX.Element {
  const { open, onClose } = props;
  const [fullAmount, setFullAmount] = useState(true);
  const [paynow, setPaynow] = useState(false);
  const [amount] = useState(295);
  const [convenienceFee] = useState(13.27);
  const steps = [1, 2, 3];
  const [currentStep, setCurrentStep] = useState(steps[0]);

  const onNext = () => {
    const totalSteps = steps.length;
    const nextStep = currentStep + 1;
    if (nextStep <= totalSteps) {
      if (nextStep === 2) {
        setPaynow(true);
      } else {
        setPaynow(false);
      }
      setCurrentStep(nextStep);
    }
  };

  const onClosePopup = () => {
    onClose();
    setPaynow(false);
    setCurrentStep(1);
  };

  const renderTitle = () => {
    let title = "Make a Payment";
    switch (currentStep) {
      case 1: {
        title = "Make a Payment";
        break;
      }
      case 2: {
        title = "Review Payment";
        break;
      }
      case 3: {
        title = "Approved";
        break;
      }
      default: {
        title = "Make a Payment";
        break;
      }
    }
    return title;
  };

  const renderComponent = () => {
    let children = <>Test</>;
    switch (currentStep) {
      case 1: {
        children = (
          <Grid container columns={8}>
            <Grid item xs={8} sm={5} md={5} lg={5}>
              <InputLabel sx={{ marginBottom: "5px" }}>Bill</InputLabel>
              <Autocomplete
                defaultValue="Lease For OH103 - Dayton OH"
                options={[
                  "Lease For OH103 - Dayton OH",
                  "Lease For OH104 - Dayton OH",
                  "Lease For OH105 - Dayton OH",
                  "Lease For OH106 - Dayton OH",
                ]}
                renderInput={(params) => <MDInput {...params} variant="standard" />}
              />
            </Grid>
            <Grid item xs={8} sm={5} md={5} lg={5} mt={3}>
              <InputLabel>Payment Amount</InputLabel>
              <FormControlLabel
                control={
                  <Switch
                    checked={fullAmount}
                    onChange={(e: any) => setFullAmount(e.target.checked)}
                    inputProps={{ "aria-label": "controlled" }}
                  />
                }
                label={
                  <MDTypography fontWeight="regular" fontSize={15}>
                    Pay Full Amount ($295.00)
                  </MDTypography>
                }
                labelPlacement="end"
              />
              {!fullAmount && (
                <Grid item xs={6} md={3} sm={3} lg={3} mt={1}>
                  <FormField label="Other" placeholder="$" />
                </Grid>
              )}
            </Grid>
            <Grid item xs={8} sm={5} md={5} lg={5} mt={3}>
              <InputLabel sx={{ marginBottom: "5px" }}>Payment Source</InputLabel>
              <Autocomplete
                defaultValue="Visa Credit Card ending in 9299"
                options={[
                  "Visa Credit Card ending in 9299",
                  "MasterCard Credit Card ending in 2025",
                  "Rupay Credit Card ending in 2024",
                ]}
                renderInput={(params) => <MDInput {...params} variant="standard" />}
              />
              <MDButton variant="gradient" color="info" size="small" sx={{ marginTop: "5px" }}>
                Add New Payment Method
              </MDButton>
            </Grid>
          </Grid>
        );
        break;
      }
      case 2: {
        children = (
          <Grid container>
            <Grid item xs={12} sm={8} md={8} lg={8}>
              <MDTypography variant="subtitle2" fontWeight="medium">
                Payment Information
              </MDTypography>
              <Grid container>
                <Grid item xs={12} sm={6} md={6} lg={6}>
                  <MDTypography
                    variant="subtitle2"
                    fontWeight="medium"
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      paddingRight: "30px",
                    }}
                  >
                    Account
                    <span>edit</span>
                  </MDTypography>
                  <Grid container>
                    <Grid item xs={6} sm={6} md={6} lg={6}>
                      <MDTypography variant="subtitle2" fontWeight="regular">
                        Card Type
                      </MDTypography>
                    </Grid>
                    <Grid item xs={6} sm={6} md={6} lg={6}>
                      <MDTypography variant="subtitle2" fontWeight="regular">
                        Visa Credit Card
                      </MDTypography>
                    </Grid>
                    <Grid item xs={6} sm={6} md={6} lg={6}>
                      <MDTypography variant="subtitle2" fontWeight="regular">
                        Card Number
                      </MDTypography>
                    </Grid>
                    <Grid item xs={6} sm={6} md={6} lg={6}>
                      <MDTypography variant="subtitle2" fontWeight="regular">
                        Ending in 9299
                      </MDTypography>
                    </Grid>
                    <Grid item xs={6} sm={6} md={6} lg={6}>
                      <MDTypography variant="subtitle2" fontWeight="regular">
                        Card Expiration
                      </MDTypography>
                    </Grid>
                    <Grid item xs={6} sm={6} md={6} lg={6}>
                      <MDTypography variant="subtitle2" fontWeight="regular">
                        4 / 2023
                      </MDTypography>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12} sm={6} md={6} lg={6}>
                  <MDTypography
                    variant="subtitle2"
                    fontWeight="medium"
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      paddingRight: "30px",
                    }}
                  >
                    Billing Address
                    <span>edit</span>
                  </MDTypography>
                  <MDTypography variant="subtitle2" fontWeight="regular">
                    Peter Hackman
                    <br />
                    1210 AvidXchange LnSuite # COL - F Columbus, OH 43147
                  </MDTypography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} sm={4} md={4} lg={4}>
              <MDTypography variant="subtitle2" fontWeight="medium">
                Payment Summary
              </MDTypography>
              <Grid container mt={3}>
                <Grid item xs={12} sm={6} md={6} lg={6}>
                  <MDTypography variant="subtitle2" fontWeight="regular">
                    Amount
                  </MDTypography>
                </Grid>
                <Grid item xs={12} sm={6} md={6} lg={6} sx={{ textAlign: "right" }}>
                  <MDTypography variant="subtitle2" fontWeight="medium">
                    ${amount.toFixed(2)}
                  </MDTypography>
                </Grid>
                <Grid item xs={12} sm={6} md={6} lg={6}>
                  <MDTypography variant="subtitle2" fontWeight="regular">
                    Convenience Fee
                  </MDTypography>
                </Grid>
                <Grid item xs={12} sm={6} md={6} lg={6} sx={{ textAlign: "right" }}>
                  <MDTypography variant="subtitle2" fontWeight="medium">
                    ${convenienceFee}
                  </MDTypography>
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12} sx={{ textAlign: "right" }}>
                  <MDTypography variant="subtitle2" fontWeight="regular">
                    Total Payment
                  </MDTypography>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={12}
                  lg={12}
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "end",
                    backgroundColor: "rgba(224, 224, 224, 50%)",
                    height: "40px",
                  }}
                >
                  <MDTypography variant="subtitle1" fontWeight="medium">
                    ${amount + convenienceFee}
                  </MDTypography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} sm={8} md={8} lg={8}>
              <Link href="policy" color="#0000EE" fontSize={12}>
                Zego Privacy Policy
              </Link>
            </Grid>
          </Grid>
        );
        break;
      }
      case 3: {
        children = (
          <Grid container justifyContent="center">
            <Grid item xs={12} sm={6} md={6} lg={6}>
              <MDTypography variant="subtitle2" fontWeight="medium" style={{ color: "#0d8200" }}>
                APPROVED
              </MDTypography>
              <MDTypography variant="subtitle2" fontWeight="regular" style={{ color: "green" }}>
                Thank you for the payment!
              </MDTypography>
              <Grid container justifyContent="center">
                <Grid item xs={12} sm={6} md={6} lg={6} my={1}>
                  <MDTypography variant="subtitle2" fontWeight="regular">
                    Name On Account
                  </MDTypography>
                </Grid>
                <Grid item xs={12} sm={6} md={6} lg={6} my={1}>
                  <MDTypography variant="subtitle2" fontWeight="regular">
                    Peter Hackman
                  </MDTypography>
                </Grid>
                <Grid item xs={12} sm={6} md={6} lg={6} my={1}>
                  <MDTypography variant="subtitle2" fontWeight="regular">
                    Card Type
                  </MDTypography>
                </Grid>
                <Grid item xs={12} sm={6} md={6} lg={6} my={1}>
                  <MDTypography variant="subtitle2" fontWeight="regular">
                    Visa Credit Card
                  </MDTypography>
                </Grid>
                <Grid item xs={12} sm={6} md={6} lg={6} my={1}>
                  <MDTypography variant="subtitle2" fontWeight="regular">
                    Card Number
                  </MDTypography>
                </Grid>
                <Grid item xs={12} sm={6} md={6} lg={6} my={1}>
                  <MDTypography variant="subtitle2" fontWeight="regular">
                    xxxxxxxxxxxx9299
                  </MDTypography>
                </Grid>
                <Grid item xs={12} sm={6} md={6} lg={6} my={1}>
                  <MDTypography variant="subtitle2" fontWeight="regular">
                    Confirmation Number
                  </MDTypography>
                </Grid>
                <Grid item xs={12} sm={6} md={6} lg={6} my={1}>
                  <MDTypography variant="subtitle2" fontWeight="regular">
                    84076662116513060
                  </MDTypography>
                </Grid>
                <Grid item xs={12} sm={6} md={6} lg={6} my={1}>
                  <MDTypography variant="subtitle2" fontWeight="regular">
                    Referance Number
                  </MDTypography>
                </Grid>
                <Grid item xs={12} sm={6} md={6} lg={6} my={1}>
                  <MDTypography variant="subtitle2" fontWeight="regular">
                    194658950
                  </MDTypography>
                </Grid>
                <Grid item xs={12} sm={6} md={6} lg={6} my={1}>
                  <MDTypography variant="subtitle2" fontWeight="regular">
                    Payment Date
                  </MDTypography>
                </Grid>
                <Grid item xs={12} sm={6} md={6} lg={6} my={1}>
                  <MDTypography variant="subtitle2" fontWeight="regular">
                    3/31/2022 11:13:12 AM
                  </MDTypography>
                </Grid>
                <Grid item xs={12} sm={6} md={6} lg={6} my={1}>
                  <MDTypography variant="subtitle2" fontWeight="regular">
                    Payment Amount
                  </MDTypography>
                </Grid>
                <Grid item xs={12} sm={6} md={6} lg={6} my={1}>
                  <MDTypography variant="subtitle2" fontWeight="regular">
                    ${amount.toFixed(2)}
                  </MDTypography>
                </Grid>
                <Grid item xs={12} sm={6} md={6} lg={6} my={1}>
                  <MDTypography variant="subtitle2" fontWeight="regular">
                    Convenience Fee Amount
                  </MDTypography>
                </Grid>
                <Grid item xs={12} sm={6} md={6} lg={6} my={1}>
                  <MDTypography variant="subtitle2" fontWeight="regular">
                    ${convenienceFee.toFixed(2)}
                  </MDTypography>
                </Grid>
                <Grid item xs={12} sm={6} md={6} lg={6} my={1}>
                  <MDTypography variant="subtitle2" fontWeight="regular">
                    Total Amount
                  </MDTypography>
                </Grid>
                <Grid item xs={12} sm={6} md={6} lg={6} my={1}>
                  <MDTypography variant="subtitle2" fontWeight="regular">
                    ${Number(amount.toFixed(2)) + Number(convenienceFee.toFixed(2))}
                  </MDTypography>
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12} my={1}>
                  <MDTypography
                    variant="subtitle2"
                    fontWeight="regular"
                    style={{ color: "#117dab" }}
                  >
                    Note: Please print and keep this page for your records.
                  </MDTypography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        );
        break;
      }
      default: {
        break;
      }
    }
    return children;
  };

  const dialogProps = {
    open,
    onClose: onClosePopup,
    title: renderTitle(),
    size: "lg",
    saveBtn: currentStep !== 2,
    closeBtn: false,
    paynowBtn: paynow,
    saveTbtText: currentStep === steps.length ? "Print" : "Next",
    onSave: onNext,
  };

  return <MDDialog {...dialogProps}>{renderComponent()}</MDDialog>;
}

export default MakePayment;
