import React, { useEffect, useState } from "react";
import MDDialog from "components/MDDialog";
import { Grid, InputLabel } from "@mui/material";
import MDInput from "components/MDInput";
import CustomPhoneInput from "components/CustomPhoneInput";
import { useAppDispatch } from "store/store";
import { createPartnerInquiries, updatePartnerInquiries } from "store/thunk/systemAdmin";
import Validations from "helper/validations";

function AddUpdate(props: any): JSX.Element {
  const dispatch = useAppDispatch();
  const { open, onClose, editInquiry, pageNo, perPage, search, sortBy } = props;
  const [loading] = useState<boolean>(false);
  const [partnerInquiriesData, setPartnerInquiriesData] = useState<any>({});
  const [errors, setErrors] = useState<any>({});

  useEffect(() => {
    if (editInquiry) {
      setPartnerInquiriesData((prev: any) => ({
        ...prev,
        subject: editInquiry.subject,
        name: editInquiry.name,
        company_name: editInquiry.company_name,
        email: editInquiry.email,
        phone: editInquiry.phone,
        message: editInquiry.message,
        request_url: editInquiry.request_url,
      }));
    }
  }, []);

  const onSave = () => {
    const error = Validations.validatePartnerInquiriesForm(partnerInquiriesData);
    setErrors(error);
    if (!Object.keys(error).length) {
      const req = {
        partnerInquiriesData,
        onClose,
        pageNo,
        perPage,
        search,
        sortBy,
      };
      if (editInquiry) {
        Object.assign(req, { id: editInquiry.id });
        dispatch(updatePartnerInquiries(req));
      } else {
        dispatch(createPartnerInquiries(req));
      }
    }
  };

  const onChangeField = (e: any, field: string) => {
    setPartnerInquiriesData((prev: any) => ({
      ...prev,
      [field]: e.target.value,
    }));
  };

  const dialogProps = {
    open,
    onClose,
    onSave,
    saveTbtText: editInquiry ? "Update" : "Save",
    title: editInquiry ? "Edit Partner Inquiry" : "Add Partner Inquiry",
    size: "sm",
    loading,
  };

  return (
    <MDDialog {...dialogProps}>
      <Grid container spacing={1}>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <MDInput
            label="Subject"
            variant="standard"
            fullWidth
            required
            sx={{ margin: "5px 0px" }}
            name="subject"
            placeholder="Subject"
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => onChangeField(e, "subject")}
            value={partnerInquiriesData?.subject || ""}
            error={errors && errors.subject ? true : false}
            helperText={errors && errors.subject}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <MDInput
            label="Name"
            variant="standard"
            fullWidth
            required
            sx={{ margin: "5px 0px" }}
            name="name"
            placeholder="Name"
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => onChangeField(e, "name")}
            value={partnerInquiriesData?.name || ""}
            error={errors && errors.name ? true : false}
            helperText={errors && errors.name}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <MDInput
            label="Company Name"
            variant="standard"
            fullWidth
            name="company_name"
            placeholder="Company Name"
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => onChangeField(e, "company_name")}
            value={partnerInquiriesData?.company_name || ""}
            error={errors && errors.company_name ? true : false}
            helperText={errors && errors.company_name}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <MDInput
            label="Email"
            variant="standard"
            fullWidth
            required
            sx={{ margin: "5px 0px" }}
            name="email"
            placeholder="Email"
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => onChangeField(e, "email")}
            value={partnerInquiriesData?.email || ""}
            error={errors && errors.email ? true : false}
            helperText={errors && errors.email}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <InputLabel sx={{ marginTop: "5px" }}>Phone</InputLabel>
          <MDInput
            variant="standard"
            fullWidth
            sx={{ marginBottom: "5px" }}
            name="phone"
            placeholder="(000) 000-0000"
            id="phone-input"
            InputProps={{
              inputComponent: CustomPhoneInput as any,
            }}
            value={partnerInquiriesData?.phone || ""}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => onChangeField(e, "phone")}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <MDInput
            label="Message"
            fullWidth
            multiline
            variant="standard"
            rows={2}
            name="message"
            required
            onChange={(e: any) => onChangeField(e, "message")}
            value={partnerInquiriesData?.message ? partnerInquiriesData.message : ""}
            error={errors && errors.message ? true : false}
            helperText={errors && errors.message}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <MDInput
            label="Request Url"
            variant="standard"
            fullWidth
            sx={{ margin: "5px 0px" }}
            name="request_url"
            placeholder="Request Url"
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => onChangeField(e, "request_url")}
            value={partnerInquiriesData?.request_url || ""}
            error={errors && errors.request_url ? true : false}
            helperText={errors && errors.request_url}
          />
        </Grid>
      </Grid>
    </MDDialog>
  );
}

export default AddUpdate;
