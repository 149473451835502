import { createAsyncThunk } from "@reduxjs/toolkit";
import { notificationSuccess, notificationFail } from "store/slices/notificationSlice";
import { setLoading } from "store/slices/authSlice";
import {
  setSmsRequestList,
  setPhoneNumbersList,
  setAvailableNumnersList,
  setSMSRequestDetailData,
  setPhoneNumberDetails,
  setRMContactList,
  setRMContactDetail,
} from "store/slices/repairMaintenanceSlice";
import Messages from "helper/messages";
import slsApiClient from "config/slsApiClient";

// get SMS request detail data buy id/location_id
export const getRMSMSRequestDetails = createAsyncThunk(
  "getRMSMSRequestDetails",
  async (_request: any, { dispatch }) => {
    dispatch(setLoading(true));
    try {
      const response = await slsApiClient().post("get-rm-sms-request-detail", _request);
      if (response?.data) {
        dispatch(setSMSRequestDetailData(response?.data?.data));
      } else {
        dispatch(setSMSRequestDetailData(null));
        dispatch(notificationFail(Messages.ERROR.SOMETHING_WENT_WRONG));
      }
      dispatch(setLoading(false));
    } catch (error: any) {
      dispatch(setLoading(false));
      dispatch(setSMSRequestDetailData(null));
      if (_request?.errorMsg) {
        dispatch(
          notificationFail(error?.response?.data?.message || Messages.ERROR.SOMETHING_WENT_WRONG)
        );
      }
    }
  }
);

export const requestRMTwillioNumber = createAsyncThunk(
  "requestRMTwillioNumber",
  async (_request: any, { dispatch }) => {
    dispatch(setLoading(true));
    try {
      const response = await slsApiClient().post("request-rm-twillio-number", _request);
      if (response?.data) {
        dispatch(setLoading(false));
        dispatch(getRMSMSRequestDetails(_request));
        dispatch(
          notificationSuccess(response?.data?.message || Messages.SUCCESS.SMS_REQUEST_SUCCESS)
        );
      } else {
        dispatch(notificationFail(response?.data?.message || Messages.ERROR.SOMETHING_WENT_WRONG));
      }
    } catch (error: any) {
      dispatch(setLoading(false));
      dispatch(
        notificationFail(error?.response?.data?.message || Messages.ERROR.SOMETHING_WENT_WRONG)
      );
    }
  }
);

export const getRMNewSmsRequestList = createAsyncThunk(
  "getRMNewSmsRequestList",
  async (_request: any, { dispatch }) => {
    dispatch(setLoading(true));
    const page = _request?.pageIndex || 0;
    const size = _request?.pageSize || 50;
    const search = _request?.search || "";
    const sortBy = _request?.sort || [];

    const request: any = {
      page,
      size,
      search,
    };
    if (sortBy?.length) {
      request.sort_field = sortBy[0]?.id;
      request.sort_type = sortBy[0]?.desc ? "desc" : "asc";
    }
    try {
      const response = await slsApiClient().get("get-new-rm-sms-request-list", {
        params: request,
      });
      dispatch(setLoading(false));
      if (response?.data) {
        dispatch(setSmsRequestList(response?.data));
      } else {
        dispatch(notificationFail(Messages.ERROR.NO_DATA_FOUND));
      }
    } catch (error: any) {
      dispatch(setLoading(false));
      dispatch(notificationFail(error?.response?.data?.message || Messages.ERROR.NO_DATA_FOUND));
    }
  }
);

export const getRMPhoneNumberList = createAsyncThunk(
  "getRMPhoneNumberList",
  async (_request: any, { dispatch }) => {
    dispatch(setLoading(true));
    const page = _request?.pageIndex || 0;
    const size = _request?.pageSize || 50;
    const search = _request?.search || "";
    const sortBy = _request?.sort || [];

    const request: any = {
      page,
      size,
      search,
    };
    if (sortBy?.length) {
      request.sort_field = sortBy[0]?.id;
      request.sort_type = sortBy[0]?.desc ? "desc" : "asc";
    }
    try {
      const response = await slsApiClient().get("get-rm-twillio-numbers", {
        params: request,
      });
      dispatch(setLoading(false));
      if (response?.data) {
        dispatch(setPhoneNumbersList(response?.data));
      } else {
        dispatch(notificationFail(Messages.ERROR.NO_DATA_FOUND));
      }
    } catch (error: any) {
      dispatch(setLoading(false));
      dispatch(notificationFail(error?.response?.data?.message || Messages.ERROR.NO_DATA_FOUND));
    }
  }
);

// Assign Twillio Numner to request location
export const assignRMTwillioNumber = createAsyncThunk(
  "assignRMTwillioNumber",
  async (_request: any, { dispatch }) => {
    const { listingRequestData, assignReq, onClose } = _request;
    dispatch(setLoading(true));
    try {
      const response = await slsApiClient().post("assign-rm-twilio-number-to-location", assignReq);
      if (response?.data) {
        dispatch(getRMNewSmsRequestList(listingRequestData));
        dispatch(
          notificationSuccess(
            response?.data?.message || Messages.SUCCESS.SUCCESS_PHONE_NUMBER_ASSIGNED
          )
        );
        if (onClose) {
          onClose();
        }
      } else {
        dispatch(setLoading(false));
        dispatch(notificationFail(Messages.ERROR.SOMETHING_WENT_WRONG));
      }
    } catch (error: any) {
      dispatch(setLoading(false));
      dispatch(
        notificationFail(error?.response?.data?.message || Messages.ERROR.SOMETHING_WENT_WRONG)
      );
    }
  }
);
// Assign Twillio Numner to request location
export const getRMAvailableNumbersList = createAsyncThunk(
  "getRMAvailableNumbersList",
  async (_request: any, { dispatch }) => {
    dispatch(setLoading(true));
    try {
      const response = await slsApiClient().get("get-rm-available-phone-numbers", {
        params: { area_code: _request.area_code },
      });
      if (response?.data) {
        dispatch(setAvailableNumnersList(response?.data?.data));
      } else {
        dispatch(notificationFail(Messages.ERROR.SOMETHING_WENT_WRONG));
      }
      dispatch(setLoading(false));
    } catch (error: any) {
      dispatch(setLoading(false));
      dispatch(
        notificationFail(error?.response?.data?.message || Messages.ERROR.SOMETHING_WENT_WRONG)
      );
    }
  }
);

// buy New twillio number
export const buyRMNewNumber = createAsyncThunk(
  "buyRMNewNumber",
  async (_request: any, { dispatch }) => {
    const { buyReq, onClose, setConfirmData } = _request;
    dispatch(setLoading(true));
    try {
      const response = await slsApiClient().post("buy-rm-twillio-number", buyReq);
      if (response?.data) {
        dispatch(
          notificationSuccess(response?.data?.message || Messages.SUCCESS.SUCCESS_PHONE_NUMBER_BUY)
        );
        if (onClose) {
          onClose();
        }
        if (setConfirmData) {
          setConfirmData(false);
        }
        dispatch(getRMPhoneNumberList({ pageIndex: 0, pageSize: 10 }));
      } else {
        dispatch(setLoading(false));
        dispatch(notificationFail(Messages.ERROR.SOMETHING_WENT_WRONG));
      }
    } catch (error: any) {
      dispatch(setLoading(false));
      dispatch(
        notificationFail(error?.response?.data?.message || Messages.ERROR.SOMETHING_WENT_WRONG)
      );
    }
  }
);

// Assign Twillio Numner to request location
export const deleteRMPhoneNumber = createAsyncThunk(
  "deleteRMPhoneNumber",
  async (_request: any, { dispatch }) => {
    const { phoneListingRequestData, deleteReq, setDeleteData } = _request;
    dispatch(setLoading(true));
    try {
      const response = await slsApiClient().post("delete-rm-phone-number", deleteReq);
      if (response?.data) {
        dispatch(getRMPhoneNumberList(phoneListingRequestData));
        dispatch(
          notificationSuccess(response?.data?.message || Messages.SUCCESS.SUCCESS_PHONE_DELETE)
        );
        if (setDeleteData) {
          setDeleteData(false);
        }
      } else {
        dispatch(notificationFail(Messages.ERROR.SOMETHING_WENT_WRONG));
      }
      dispatch(setLoading(false));
    } catch (error: any) {
      dispatch(setLoading(false));
      dispatch(
        notificationFail(error?.response?.data?.message || Messages.ERROR.SOMETHING_WENT_WRONG)
      );
    }
  }
);

// get twillio numebr detail from by ID
export const getRMPhoneDetailById = createAsyncThunk(
  "getRMPhoneDetailById",
  async (_request: any, { dispatch }) => {
    dispatch(setLoading(true));
    try {
      const response = await slsApiClient().get(`get-rm-phone-number-by-id?id=${_request?.id}`);
      dispatch(setLoading(false));
      if (response?.data) {
        dispatch(setPhoneNumberDetails(response?.data?.data));
      } else {
        dispatch(notificationFail(Messages.ERROR.SOMETHING_WENT_WRONG));
      }
    } catch (error: any) {
      dispatch(setLoading(false));
      dispatch(
        notificationFail(error?.response?.data?.message || Messages.ERROR.SOMETHING_WENT_WRONG)
      );
    }
  }
);

// update RMT willio Phone Setting
export const updateRMTwillioPhoneSetting = createAsyncThunk(
  "updateRMTwillioPhoneSetting",
  async (_request: any, { dispatch }) => {
    dispatch(setLoading(true));
    try {
      const response = await slsApiClient().put("update-rm-phone-number-by-id", _request);
      if (response?.data) {
        dispatch(getRMPhoneDetailById(_request));
        dispatch(
          notificationSuccess(
            response?.data?.message || Messages.SUCCESS.SUCCESS_PHONE_SETTING_UPDATE
          )
        );
      } else {
        dispatch(notificationFail(Messages.ERROR.SOMETHING_WENT_WRONG));
      }
      dispatch(setLoading(false));
    } catch (error: any) {
      dispatch(setLoading(false));
      dispatch(
        notificationFail(error?.response?.data?.message || Messages.ERROR.SOMETHING_WENT_WRONG)
      );
    }
  }
);

// addLocations To Twillio phone number from edit page
export const updateRMTwilioNumberToLocation = createAsyncThunk(
  "updateRMTwilioNumberToLocation",
  async (_request: any, { dispatch }) => {
    const { onClose, updateData } = _request;
    dispatch(setLoading(true));
    try {
      const response = await slsApiClient().post("update-rm-twilio-number-to-location", updateData);
      if (response?.data) {
        dispatch(getRMPhoneDetailById(updateData));
        dispatch(
          notificationSuccess(
            response?.data?.message || Messages.SUCCESS.SUCCESS_PHONE_SETTING_UPDATE
          )
        );
        if (onClose) {
          onClose();
        }
      } else {
        dispatch(notificationFail(Messages.ERROR.SOMETHING_WENT_WRONG));
      }
      dispatch(setLoading(false));
    } catch (error: any) {
      dispatch(setLoading(false));
      dispatch(
        notificationFail(error?.response?.data?.message || Messages.ERROR.SOMETHING_WENT_WRONG)
      );
    }
  }
);

// remove location Twillio phone number from edit page
export const removeRMLocationFromTwillioNumber = createAsyncThunk(
  "removeRMLocationFromTwillioNumber",
  async (_request: any, { dispatch }) => {
    const { setDeleteLocationId, removeData } = _request;
    dispatch(setLoading(true));
    try {
      const response = await slsApiClient().post("remove-rm-twilio-number-to-location", removeData);
      if (response?.data) {
        dispatch(getRMPhoneDetailById(removeData));
        dispatch(notificationSuccess(Messages.SUCCESS.SUCCESS_PHONE_SETTING_UPDATE));
        if (setDeleteLocationId) {
          setDeleteLocationId(false);
        }
      } else {
        dispatch(notificationFail(Messages.ERROR.SOMETHING_WENT_WRONG));
      }
      dispatch(setLoading(false));
    } catch (error: any) {
      dispatch(setLoading(false));
      dispatch(
        notificationFail(error?.response?.data?.message || Messages.ERROR.SOMETHING_WENT_WRONG)
      );
    }
  }
);

export const getRMContactList = createAsyncThunk(
  "getRMContactList",
  async (_request: any, { dispatch }) => {
    dispatch(setLoading(true));
    const page = _request?.pageIndex || 0;
    const size = _request?.pageSize || 50;
    const search = _request?.search || "";
    const sortBy = _request?.sort || [];

    const request: any = {
      page,
      size,
      search,
    };
    if (sortBy?.length) {
      request.sort_field = sortBy[0]?.id;
      request.sort_type = sortBy[0]?.desc ? "desc" : "asc";
    }
    try {
      const response = await slsApiClient().get("get-rm-contact-list", {
        params: request,
      });
      dispatch(setLoading(false));
      if (response?.data) {
        dispatch(setRMContactList(response?.data));
      } else {
        dispatch(notificationFail(Messages.ERROR.NO_DATA_FOUND));
      }
    } catch (error: any) {
      dispatch(setLoading(false));
      dispatch(notificationFail(error?.response?.data?.message || Messages.ERROR.NO_DATA_FOUND));
    }
  }
);
export const createRMContact = createAsyncThunk(
  "createRMContact",
  async (_request: any, { dispatch }) => {
    const { tmpContactData, onClose, pageNo, perPage, search, sortBy } = _request;
    dispatch(setLoading(true));
    try {
      const response = await slsApiClient().post(
        tmpContactData?.id ? "update-rm-contact" : "create-rm-contact",
        tmpContactData
      );
      if (response?.data) {
        dispatch(
          notificationSuccess(
            tmpContactData?.id
              ? Messages.SUCCESS.RECORD_UPDATE_SUCCESS
              : Messages.SUCCESS.RECORD_CREATE_SUCCESS
          )
        );
        dispatch(getRMContactList({ pageSize: perPage, pageIndex: pageNo, search, sort: sortBy }));
        if (onClose) {
          onClose();
        }
      } else {
        dispatch(notificationFail(Messages.ERROR.SOMETHING_WENT_WRONG));
      }
      dispatch(setLoading(false));
    } catch (error: any) {
      dispatch(setLoading(false));
      dispatch(
        notificationFail(error?.response?.data?.message || Messages.ERROR.SOMETHING_WENT_WRONG)
      );
    }
  }
);
export const getRMContactDetailById = createAsyncThunk(
  "getRMContactDetailById",
  async (_request: any, { dispatch }) => {
    dispatch(setLoading(true));
    try {
      const response = await slsApiClient().post("get-rm-contact-detail-by-id", _request);
      if (response?.data) {
        dispatch(setRMContactDetail(response?.data?.data));
      } else {
        dispatch(notificationFail(Messages.ERROR.NO_DATA_FOUND));
      }
      dispatch(setLoading(false));
    } catch (error: any) {
      dispatch(setLoading(false));
      dispatch(notificationFail(error?.response?.data?.message || Messages.ERROR.NO_DATA_FOUND));
    }
  }
);
export const deleteRMContact = createAsyncThunk(
  "deleteRMContact",
  async (_request: any, { dispatch }) => {
    dispatch(setLoading(true));
    const { id, setDeleteContactId, pageNo, perPage, search, sortBy } = _request;
    try {
      const response = await slsApiClient().post("delete-rm-contact", { id });
      if (response?.data?.status) {
        setDeleteContactId(false);
        dispatch(notificationSuccess(Messages.SUCCESS.RECORD_DELETE_SUCCESS));
        dispatch(getRMContactList({ pageSize: perPage, pageIndex: pageNo, search, sort: sortBy }));
      } else {
        dispatch(notificationFail(Messages.ERROR.SOMETHING_WENT_WRONG));
      }
      dispatch(setLoading(false));
    } catch (error: any) {
      dispatch(setLoading(false));
      dispatch(notificationFail(error?.response?.data?.message || Messages.ERROR.NO_DATA_FOUND));
    }
  }
);
