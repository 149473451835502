import MDBox from "components/MDBox";
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";

function ImagePreview(props: any): JSX.Element {
  const { imageData, open, onCloseImage } = props;

  return <MDBox>{open && <Lightbox mainSrc={imageData} onCloseRequest={onCloseImage} />}</MDBox>;
}

export default ImagePreview;
