import { createSlice, Draft, PayloadAction } from "@reduxjs/toolkit";

interface InitialState {
  leaseData: any;
  accountSetUpData: any;
  leaseApplications: any;
  leads: any;
  leadDetails: any;
  leaseDetails: any;
  leaseConversation: any;
  leaseConversationNotes: any;
  allConversation: any;
  dealActivityDetails: any;
  services: any;
  existingDeal: any;
  leaseApplicationSteps: any;
  applicantDetails: any;
  locationStudios: any;
}

const initialState: InitialState = {
  leaseData: null,
  accountSetUpData: null,
  leaseApplications: null,
  leads: null,
  leadDetails: null,
  leaseDetails: null,
  leaseConversation: null,
  leaseConversationNotes: null,
  allConversation: null,
  dealActivityDetails: null,
  services: [],
  existingDeal: [],
  leaseApplicationSteps: {},
  applicantDetails: [],
  locationStudios: [],
};

const leaseSlice = createSlice({
  name: "lease",
  initialState,
  reducers: {
    setLeaseData: (state: Draft<InitialState>, action: PayloadAction<any>) => ({
      ...state,
      leaseData: action.payload,
    }),
    setLeaseDetails: (state: Draft<InitialState>, action: PayloadAction<any>) => ({
      ...state,
      leaseDetails: action.payload,
    }),
    setAccountSetUpData: (state: Draft<InitialState>, action: PayloadAction<any>) => ({
      ...state,
      accountSetUpData: action.payload,
    }),
    setLeaseApplications: (state: Draft<InitialState>, action: PayloadAction<any>) => ({
      ...state,
      leaseApplications: action.payload,
    }),
    setLeads: (state: Draft<InitialState>, action: PayloadAction<any>) => ({
      ...state,
      leads: action.payload,
    }),
    setLeadDetails: (state: Draft<InitialState>, action: PayloadAction<any>) => ({
      ...state,
      leadDetails: action.payload,
    }),
    setLeaseConversation: (state: Draft<InitialState>, action: PayloadAction<any>) => ({
      ...state,
      leaseConversation: action.payload,
    }),
    setLeaseConversationNotes: (state: Draft<InitialState>, action: PayloadAction<any>) => ({
      ...state,
      leaseConversationNotes: action.payload,
    }),
    setAllConversation: (state: Draft<InitialState>, action: PayloadAction<any>) => ({
      ...state,
      allConversation: action.payload,
    }),
    setDealActivityDetails: (state: Draft<InitialState>, action: PayloadAction<any>) => ({
      ...state,
      dealActivityDetails: action.payload,
    }),
    setAllServices: (state: Draft<InitialState>, action: PayloadAction<any>) => ({
      ...state,
      services: action.payload,
    }),
    getAllexistingDealData: (state: Draft<InitialState>, action: PayloadAction<any>) => ({
      ...state,
      existingDeal: action.payload,
    }),
    setleaseApplicationSteps: (state: Draft<InitialState>, action: PayloadAction<any>) => ({
      ...state,
      leaseApplicationSteps: action.payload,
    }),
    setApplicantDetails: (state: Draft<InitialState>, action: PayloadAction<any>) => ({
      ...state,
      applicantDetails: action.payload,
    }),
    setStudioData: (state: Draft<InitialState>, action: PayloadAction<any>) => ({
      ...state,
      locationStudios: action.payload,
    }),
  },
});

export const {
  setLeaseData,
  setAccountSetUpData,
  setLeaseApplications,
  setLeads,
  setLeadDetails,
  setLeaseDetails,
  setLeaseConversation,
  setLeaseConversationNotes,
  setAllConversation,
  setDealActivityDetails,
  setAllServices,
  getAllexistingDealData,
  setleaseApplicationSteps,
  setApplicantDetails,
  setStudioData,
} = leaseSlice.actions;

export default leaseSlice.reducer;
