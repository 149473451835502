import { useEffect, useState } from "react";
import AccountSetupWizard from "layouts/pages/sola-pros/account-setup-wizard";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import { userRoles, getPreferenceValue, getAuthUser } from "helper/services";
import { useAppSelector, useAppDispatch } from "store/store";
import { getUser } from "store/thunk/authThunk";

function Index(): JSX.Element {
  const appDispatch = useAppDispatch();
  const { userData } = useAppSelector((state) => state.authReducer);
  const [setUpWizard, setSetUpWizard] = useState<boolean>(false);
  const authUser = getAuthUser();

  useEffect(() => {
    if (authUser && authUser.user_id) {
      appDispatch(getUser({ user_id: authUser?.user_id }));
    }
  }, []);

  useEffect(() => {
    if (userData && userData.user_id) {
      if (userData.role === userRoles.SOLA_PRO_USER) {
        const accountSetUPWizard = getPreferenceValue(userData, "ACCOUNT_SETUP_WIZARD");
        if (!accountSetUPWizard) {
          setSetUpWizard(true);
        } else {
          setSetUpWizard(false);
        }
      }
    }
  }, [userData]);
  return (
    <MDBox ml={2} py={3}>
      {setUpWizard ? (
        <>
          <MDBox textAlign="center" mt={1}>
            <MDTypography variant="h5" fontWeight="bold">
              Account Setup
            </MDTypography>
          </MDBox>
          <AccountSetupWizard />
        </>
      ) : (
        <MDTypography>Sola Pro Dashboard</MDTypography>
      )}
    </MDBox>
  );
}

export default Index;
