// import { useState } from "react";
// @material-ui core components
import { Card, FormControlLabel, Grid, Switch } from "@mui/material";

import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";

function BillingAddress(props: any): JSX.Element {
  const { errors, billingTabData, onCheckboxChange, onChangeField } = props;

  const getInputElement = (label: any, name: any, required?: boolean) => (
    <Grid item xs={12} sm={6}>
      <MDInput
        label={label}
        variant="standard"
        fullWidth
        name={name}
        placeholder={label}
        value={billingTabData?.[name] || ""}
        onChange={onChangeField("BILLING")}
        required={required}
        error={errors && errors[name] ? true : false}
        helperText={errors && errors[name]}
      />
    </Grid>
  );

  const getLabelElement = (name: any, right?: any) => (
    <Grid item xs={12} sm={6} md={6} lg={6} sx={{ textAlign: right ? "right" : "left" }}>
      <MDTypography variant="subtitle2" fontWeight={!right ? "bold" : "regular"}>
        {name}
      </MDTypography>
    </Grid>
  );

  return (
    <Card id="billing-address" sx={{ overflow: "visible" }}>
      <MDBox p={3}>
        <MDTypography variant="h5">Billing Address</MDTypography>
      </MDBox>
      <MDBox pb={3} px={3}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={12}>
            <FormControlLabel
              control={
                <Switch
                  checked={billingTabData?.billing_address_info || false}
                  onChange={onCheckboxChange("BILLING")}
                  inputProps={{ "aria-label": "controlled" }}
                  name="billing_address_info"
                />
              }
              label="Same as General Contact Info"
              labelPlacement="end"
            />
          </Grid>
          {!billingTabData?.billing_address_info && (
            <>
              {getInputElement("Billing Address 1", "billing_address_1")}
              {getInputElement("Billing Address 2", "billing_address_2")}
              {getInputElement("Billing City", "billing_city")}
              {getInputElement("Billing State", "billing_state")}
              {getInputElement("Billing Postal Code", "billing_postal_code")}
              {getInputElement("Billing Country", "billing_country")}
            </>
          )}
          {billingTabData?.billing_address_info && (
            <>
              {getLabelElement("Billing Address 1: ")}
              {getLabelElement(billingTabData?.home_address_1, true)}
              {getLabelElement("Billing Address 2: ")}
              {getLabelElement(billingTabData?.home_address_2, true)}
              {getLabelElement("Billing City: ")}
              {getLabelElement(billingTabData?.home_city, true)}
              {getLabelElement("Billing State: ")}
              {getLabelElement(billingTabData?.home_state, true)}
              {getLabelElement("Billing Postal Code: ")}
              {getLabelElement(billingTabData?.home_postal_code, true)}
              {getLabelElement("Billing Country: ")}
              {getLabelElement(billingTabData?.country, true)}
            </>
          )}
        </Grid>
      </MDBox>
    </Card>
  );
}

export default BillingAddress;
