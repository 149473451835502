import { Card, Grid } from "@mui/material";
import CustomAutoSearch from "components/CustomAutoSearch";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import { getPermission, getAllAssociationLocations, adminSuperAdmin } from "helper/services";

function Locations(props: any): JSX.Element {
  const { errors, solaProDetail, onChangeAutoComplete } = props;

  return (
    <Card id="locations" sx={{ overflow: "visible" }}>
      <MDBox p={3}>
        <MDTypography variant="h5">Locations</MDTypography>
      </MDBox>
      <MDBox component="form" pb={3} px={3}>
        <Grid container spacing={3} className="location">
          <Grid item xs={12} sm={6} sx={{ mt: 0 }}>
            <CustomAutoSearch
              idKey="id"
              nameKey="name"
              fieldLabel="Locations"
              apiName="locations"
              responseKey="locations"
              name="location"
              required
              value={solaProDetail?.location || null}
              onChange={onChangeAutoComplete}
              error={errors && errors.location}
              createNewLink={
                adminSuperAdmin() && getPermission("locations", "locations_location")
                  ? "/locations"
                  : ""
              }
              usersData={getAllAssociationLocations()}
            />
          </Grid>
        </Grid>
      </MDBox>
    </Card>
  );
}

export default Locations;
