import { Card, Grid } from "@mui/material";
import MDBox from "components/MDBox";
import MDInput from "components/MDInput";
import MDTypography from "components/MDTypography";
import CustomPhoneInput from "components/CustomPhoneInput";

function Contact(props: any): JSX.Element {
  const { onChangeField, errors, solaProDetail } = props;
  return (
    <Card id="contact" sx={{ overflow: "visible" }}>
      <MDBox p={3}>
        <MDTypography variant="h5">Contact Info</MDTypography>
      </MDBox>
      <MDBox component="form" pb={3} px={3}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={12}>
            <MDTypography variant="h6">Personal</MDTypography>
          </Grid>
          <Grid item xs={12} sm={6}>
            <MDInput
              label="Email Address"
              variant="standard"
              fullWidth
              required
              name="email_address"
              onChange={onChangeField}
              value={solaProDetail?.email_address || ""}
              error={errors && errors.email_address ? true : false}
              helperText={errors && errors.email_address}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <MDInput
              label="Phone Number"
              variant="standard"
              fullWidth
              name="phone_number"
              placeholder="(000) 000-0000"
              InputProps={{
                inputComponent: CustomPhoneInput as any,
              }}
              onChange={onChangeField}
              value={solaProDetail?.phone_number || ""}
              error={errors && errors.phone_number ? true : false}
              helperText={errors && errors.phone_number}
            />
          </Grid>
        </Grid>
        <Grid container spacing={3} mt={1}>
          <Grid item xs={12} sm={12}>
            <MDTypography variant="h6">Home Address</MDTypography>
          </Grid>
          <Grid item xs={12} sm={6}>
            <MDInput
              label="Address 1"
              variant="standard"
              fullWidth
              name="home_address_1"
              onChange={onChangeField}
              value={solaProDetail?.home_address_1 || ""}
              error={errors && errors.home_address_1 ? true : false}
              helperText={errors && errors.home_address_1}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <MDInput
              label="Address 2"
              variant="standard"
              fullWidth
              name="home_address_2"
              onChange={onChangeField}
              value={solaProDetail?.home_address_2 || ""}
              error={errors && errors.home_address_2 ? true : false}
              helperText={errors && errors.home_address_2}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <MDInput
              label="City"
              variant="standard"
              fullWidth
              name="home_city"
              onChange={onChangeField}
              value={solaProDetail?.home_city || ""}
              error={errors && errors.home_city ? true : false}
              helperText={errors && errors.home_city}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <MDInput
              label="State"
              variant="standard"
              fullWidth
              name="home_state"
              onChange={onChangeField}
              value={solaProDetail?.home_state || ""}
              error={errors && errors.home_state ? true : false}
              helperText={errors && errors.home_state}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <MDInput
              label="Postal Code"
              variant="standard"
              fullWidth
              name="home_postal_code"
              onChange={onChangeField}
              value={solaProDetail?.home_postal_code || ""}
              error={errors && errors.home_postal_code ? true : false}
              helperText={errors && errors.home_postal_code}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <MDInput
              label="Country"
              variant="standard"
              fullWidth
              name="country"
              onChange={onChangeField}
              value={solaProDetail?.country || ""}
              error={errors && errors.country ? true : false}
              helperText={errors && errors.country}
            />
          </Grid>
        </Grid>
      </MDBox>
    </Card>
  );
}

export default Contact;
