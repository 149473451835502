import MDDialog from "components/MDDialog";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import { Accordion, AccordionDetails, AccordionSummary, Grid, Icon } from "@mui/material";
import { useEffect, useState } from "react";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ImagePreview from "layouts/pages/ImagePreview";

function ViewDetails(props: any): JSX.Element {
  const { open, onClose, updateData, status } = props;
  const [isPreview, setImagePreviewPopup] = useState(false);
  const [imageToPreview, setImageToPreview] = useState("");
  const [changedDataKeys, setChangedDataKeys] = useState([]);
  console.log("updateData?.changed_data ", updateData?.changed_data);

  useEffect(() => {
    if (
      updateData &&
      updateData?.changed_data &&
      Array.isArray(updateData?.changed_data) &&
      updateData?.changed_data?.length
    ) {
      setChangedDataKeys(updateData?.changed_data?.map((obj: any) => obj?.label));
    }
  }, [updateData]);

  const dialogProps = {
    open,
    onClose,
    title: "View Update Webpage Request",
    size: "md",
    saveBtn: false,
  };

  const previewImage = (image: any) => {
    setImagePreviewPopup(true);
    setImageToPreview(image);
  };

  const closeImagePreview = () => {
    setImagePreviewPopup(false);
    setImageToPreview("");
  };

  const getIcon = (value: any) => {
    if (value) {
      return (
        <Icon fontSize="medium" color="success">
          check_circle_rounded
        </Icon>
      );
    }
    return (
      <Icon fontSize="medium" color="error">
        cancel
      </Icon>
    );
  };

  const getImageElement = (name: any, imageData: any, key?: any) => {
    if (changedDataKeys.includes(key)) {
      return (
        imageData && (
          <Grid item xs={12} sm={6} md={6}>
            <MDTypography fontWeight="bold" variant="button">
              {name}
            </MDTypography>
            <Grid container mt={2}>
              <Grid item xs={12} sm={6} md={6}>
                {imageData && (
                  <Grid
                    container
                    onClick={() => previewImage(imageData)}
                    // justifyContent="center"
                    // alignItems="center"
                    // display="flex"
                    className="thumbnail-img"
                  >
                    <Grid item>
                      <img height={100} width={100} src={imageData} alt="blog" />
                    </Grid>
                  </Grid>
                )}
              </Grid>
            </Grid>
          </Grid>
        )
      );
    }
    return null;
  };

  const getDetailElement = (name: any, value: any, key?: any) => {
    if (changedDataKeys.includes(key)) {
      return (
        value && (
          <Grid item xs={12} sm={6} md={6}>
            <MDTypography fontWeight="bold" variant="button">
              {name}
            </MDTypography>
            <MDBox sx={{ wordBreak: "break-all" }}>{value ? value : "-"}</MDBox>
          </Grid>
        )
      );
    }
    return null;
  };

  const getDetailElementWithDangersoulyHTML = (name: any, value: any, key?: any) => {
    if (changedDataKeys.includes(key)) {
      return (
        <Grid item xs={12} sm={12} md={12}>
          <MDTypography fontWeight="bold" variant="button">
            {name}
          </MDTypography>
          <MDBox
            sx={{ border: "1px solid #eee", overflow: "auto" }}
            p={2}
            dangerouslySetInnerHTML={{
              __html: value ? value : "-",
            }}
          />
        </Grid>
      );
    }
    return null;
  };

  const getIconElement = (name: any, iconValue: any, key?: any) => {
    if (changedDataKeys.includes(key)) {
      return (
        <Grid item xs={12} sm={6} md={6}>
          <MDTypography fontWeight="bold" variant="button">
            {name}
          </MDTypography>
          <MDBox>{getIcon(iconValue)}</MDBox>
        </Grid>
      );
    }
    return null;
  };

  const getAccordianSummary = (name: any) => (
    <AccordionSummary className="location-accordion">
      <MDTypography>{name}</MDTypography>
    </AccordionSummary>
  );

  const getTestimonialAccordian = (index: any) => {
    if (
      changedDataKeys?.includes(`testimonial_${index}_name`) ||
      changedDataKeys?.includes(`testimonial_${index}_region`) ||
      changedDataKeys?.includes(`testimonial_${index}_title`) ||
      changedDataKeys?.includes(`testimonial_${index}_text`)
    ) {
      return (
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id={`panel1a-header-${index}`}
          >
            <MDTypography>Testimonials {index}</MDTypography>
          </AccordionSummary>
          <AccordionDetails>
            <Grid container spacing={1}>
              {changedDataKeys.includes(`testimonial_${index}_name`) && (
                <Grid item xs={12} sm={6} md={6}>
                  <MDTypography fontWeight="bold" variant="button">
                    Name
                  </MDTypography>
                  <MDBox sx={{ wordBreak: "break-all" }}>
                    {updateData?.[`testimonial_${index}`]?.name || "-"}
                  </MDBox>
                </Grid>
              )}
              {changedDataKeys.includes(`testimonial_${index}_region`) && (
                <Grid item xs={12} sm={6} md={6}>
                  <MDTypography fontWeight="bold" variant="button">
                    Region
                  </MDTypography>
                  <MDBox sx={{ wordBreak: "break-all" }}>
                    {updateData?.[`testimonial_${index}`]?.region || "-"}
                  </MDBox>
                </Grid>
              )}
              {changedDataKeys.includes(`testimonial_${index}_title`) && (
                <Grid item xs={12} sm={6} md={6}>
                  <MDTypography fontWeight="bold" variant="button">
                    Region
                  </MDTypography>
                  <MDBox sx={{ wordBreak: "break-all" }}>
                    {updateData?.[`testimonial_${index}`]?.title || "-"}
                  </MDBox>
                </Grid>
              )}
              {changedDataKeys.includes(`testimonial_${index}_text`) && (
                <Grid item xs={12} sm={6} md={6}>
                  <MDTypography fontWeight="bold" variant="button">
                    Text
                  </MDTypography>
                  <MDBox sx={{ wordBreak: "break-all" }}>
                    {updateData?.[`testimonial_${index}`]?.text || "-"}
                  </MDBox>
                </Grid>
              )}
            </Grid>
          </AccordionDetails>
        </Accordion>
      );
    }
    return null;
  };

  return (
    <MDDialog {...dialogProps}>
      <MDBox pb={2}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12} md={12}>
            <MDBox mt={1}>
              <MDBox>
                <Accordion expanded>
                  {getAccordianSummary("Website")}
                  <AccordionDetails>
                    <Grid container spacing={2}>
                      {getDetailElement(
                        "Website Email Address",
                        updateData?.website_email,
                        "website_email"
                      )}
                      {getDetailElement(
                        "Website Phone Number",
                        updateData?.website_phone,
                        "website_phone"
                      )}
                      {getDetailElement("Subtitle", updateData?.subtitle, "subtitle")}
                      {getDetailElementWithDangersoulyHTML(
                        "Description",
                        updateData?.description,
                        "description"
                      )}
                      {getDetailElement("Url Name", updateData?.url_name, "url_name")}
                    </Grid>
                  </AccordionDetails>
                </Accordion>
              </MDBox>
            </MDBox>
            <MDBox mt={1}>
              <MDBox>
                <Accordion expanded>
                  {getAccordianSummary("Integrations")}
                  <AccordionDetails>
                    <Grid container spacing={2}>
                      {getDetailElement("Website Url", updateData?.website_url, "website_url")}
                      {getDetailElement("Booking Url", updateData?.booking_url, "booking_url")}
                    </Grid>
                  </AccordionDetails>
                </Accordion>
              </MDBox>
            </MDBox>
            <MDBox mt={1}>
              <MDBox>
                <Accordion expanded>
                  {getAccordianSummary("Business")}
                  <AccordionDetails>
                    <Grid container spacing={2}>
                      {getDetailElement("Salon Name", updateData?.business_name, "business_name")}
                      {getDetailElement(
                        "Studio Number",
                        updateData?.studio_number,
                        "studio_number"
                      )}
                      {getIconElement("Visible On Site", updateData?.reserved, "reserved")}
                      {getIconElement(
                        "Accepting New Clients",
                        updateData?.accepting_new_clients,
                        "accepting_new_clients"
                      )}
                      {getIconElement("Walkins", updateData?.walkins, "walkins")}
                      {getIconElement(
                        "Send A Message Button",
                        updateData?.send_a_message_button,
                        "send_a_message_button"
                      )}
                    </Grid>
                  </AccordionDetails>
                </Accordion>
              </MDBox>
            </MDBox>
            <MDBox mt={1}>
              <MDBox>
                <Accordion expanded>
                  {getAccordianSummary("Social")}
                  <AccordionDetails>
                    <Grid container spacing={2}>
                      {getDetailElement("Facebook Url", updateData?.facebook_url, "facebook_url")}
                      {getDetailElement(
                        "Google Plus Url",
                        updateData?.google_plus_url,
                        "google_plus_url"
                      )}
                      {getDetailElement(
                        "Instagram Url",
                        updateData?.instagram_url,
                        "instagram_url"
                      )}
                      {getDetailElement("Linkedin Url", updateData?.linkedin_url, "linkedin_url")}
                      {getDetailElement(
                        "Pinterest Url",
                        updateData?.pinterest_url,
                        "pinterest_url"
                      )}
                      {getDetailElement("Twitter Url", updateData?.twitter_url, "twitter_url")}
                      {getDetailElement("Yelp Url", updateData?.yelp_url, "yelp_url")}
                      {getDetailElement("Tik Tok Url", updateData?.tik_tok_url, "tik_tok_url")}
                    </Grid>
                  </AccordionDetails>
                </Accordion>
              </MDBox>
            </MDBox>
          </Grid>
          <Grid item xs={12} sm={12} md={12}>
            <Accordion expanded>
              {getAccordianSummary("Testimonials")}
              <AccordionDetails>
                {getTestimonialAccordian(1)}
                {getTestimonialAccordian(2)}
                {getTestimonialAccordian(3)}
                {getTestimonialAccordian(4)}
                {getTestimonialAccordian(5)}
                {getTestimonialAccordian(6)}
                {getTestimonialAccordian(7)}
                {getTestimonialAccordian(8)}
                {getTestimonialAccordian(9)}
                {getTestimonialAccordian(10)}
              </AccordionDetails>
            </Accordion>
          </Grid>
          <Grid item xs={12} sm={12} md={12}>
            <Accordion expanded>
              {getAccordianSummary("Images")}
              <AccordionDetails>
                <Grid container spacing={2}>
                  <Grid item xs={12} style={{ display: "flex", flexWrap: "wrap" }}>
                    {status !== "rejected"
                      ? getImageElement("Image 1", updateData?.image_1_url, "image_1_url")
                      : getImageElement(
                          "Image 1",
                          updateData?.update_my_webpage__image_1_url,
                          "image_1_url"
                        )}
                    {status !== "rejected"
                      ? getImageElement("Image 2", updateData?.image_2_url, "image_2_url")
                      : getImageElement(
                          "Image 2",
                          updateData?.update_my_webpage__image_2_url,
                          "image_2_url"
                        )}
                    {status !== "rejected"
                      ? getImageElement("Image 3", updateData?.image_3_url, "image_3_url")
                      : getImageElement(
                          "Image 3",
                          updateData?.update_my_webpage__image_3_url,
                          "image_3_url"
                        )}
                    {status !== "rejected"
                      ? getImageElement("Image 4", updateData?.image_4_url, "image_4_url")
                      : getImageElement(
                          "Image 4",
                          updateData?.update_my_webpage__image_4_url,
                          "image_4_url"
                        )}
                    {status !== "rejected"
                      ? getImageElement("Image 5", updateData?.image_5_url, "image_5_url")
                      : getImageElement(
                          "Image 5",
                          updateData?.update_my_webpage__image_5_url,
                          "image_5_url"
                        )}
                    {status !== "rejected"
                      ? getImageElement("Image 6", updateData?.image_6_url, "image_6_url")
                      : getImageElement(
                          "Image 6",
                          updateData?.update_my_webpage__image_6_url,
                          "image_6_url"
                        )}
                    {status !== "rejected"
                      ? getImageElement("Image 7", updateData?.image_7_url, "image_7_url")
                      : getImageElement(
                          "Image 7",
                          updateData?.update_my_webpage__image_7_url,
                          "image_7_url"
                        )}
                    {status !== "rejected"
                      ? getImageElement("Image 8", updateData?.image_8_url, "image_8_url")
                      : getImageElement(
                          "Image 8",
                          updateData?.update_my_webpage__image_8_url,
                          "image_8_url"
                        )}
                    {status !== "rejected"
                      ? getImageElement("Image 9", updateData?.image_9_url, "image_9_url")
                      : getImageElement(
                          "Image 9",
                          updateData?.update_my_webpage__image_9_url,
                          "image_9_url"
                        )}
                    {status !== "rejected"
                      ? getImageElement("Image 10", updateData?.image_10_url)
                      : getImageElement(
                          "Image 10",
                          updateData?.update_my_webpage__image_10_url,
                          "image_10_url"
                        )}
                  </Grid>
                </Grid>
              </AccordionDetails>
            </Accordion>
          </Grid>
          <Grid item xs={12} sm={12} md={12}>
            <Accordion expanded>
              {getAccordianSummary("Banner Image")}
              {updateData?.banner_image_url && (
                <AccordionDetails>
                  {status !== "rejected"
                    ? getImageElement(
                        "Banner Image",
                        updateData?.banner_image_url,
                        "banner_image_url"
                      )
                    : getImageElement(
                        "Banner Image",
                        updateData?.banner_image_url,
                        "banner_image_url"
                      )}
                </AccordionDetails>
              )}
            </Accordion>
          </Grid>
        </Grid>
      </MDBox>
      {isPreview && (
        <ImagePreview
          imageData={imageToPreview}
          open={isPreview}
          onCloseImage={() => {
            closeImagePreview();
          }}
        />
      )}
    </MDDialog>
  );
}

export default ViewDetails;
