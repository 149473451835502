import { useAppSelector, useAppDispatch } from "store/store";
import { useEffect, useState } from "react";
import { Box, Card, Grid, Icon } from "@mui/material";
import MDButton from "components/MDButton";
import { sendDataToApplicants } from "helper/services";
import { setleaseApplicationSteps } from "store/slices/leaseSlice";
import CustomPhoneInput from "components/CustomPhoneInput";
import MDTypography from "components/MDTypography";
import MDIconButton from "components/MDIconButton";
import MDInput from "components/MDInput";
import MDDialog from "components/MDDialog";
import HandleButton from "./handleButton";

function Sublets(props: any): JSX.Element {
  const dispatch = useAppDispatch();

  const { errors, setErrors, activeStep, setSubletApplicantError } = props;
  const { leaseApplicationSteps, applicantDetails } = useAppSelector((state) => state.leaseSlice);
  const [applicantsData, setApplicants] = useState<any>([]);
  const [firstEnable, setFirstEnable] = useState<any>(false);
  const [lastEnable, setLastEnable] = useState<any>(false);
  const [leaseExistPopUp, setShowLeaseExistPopUp] = useState<any>(null);
  const emptyDoc: any = [];

  useEffect(() => {
    if (applicantDetails?.status && !leaseApplicationSteps?.applicants?.length) {
      const data = applicantDetails?.data;

      if (data?.lease_draft_exists) {
        setShowLeaseExistPopUp(applicantDetails?.message);
      } else {
        // Changes and store data of location in reducer 03-04-2023
        const setData = [
          {
            first_name: data?.connect_contact?.first_name,
            last_name: data?.connect_contact?.last_name,
            email: data?.connect_contact?.email,
            phone: data?.connect_contact?.phone,
            contact_id: data?.connect_contact?.id,
            isSublet: false,
            documents: emptyDoc,
            deposit_status: "Unpaid",
          },
        ];
        dispatch(
          setleaseApplicationSteps({
            ...leaseApplicationSteps,
            applicants: setData,
            locations: {
              cms_location_id: leaseApplicationSteps?.locations?.cms_location_id
                ? leaseApplicationSteps?.locations?.cms_location_id
                : data?.location?.id,
              name: leaseApplicationSteps?.locations?.name
                ? leaseApplicationSteps?.locations?.name
                : data?.location?.name,
            },
            security_deposit_amount: data?.connect_location?.security_deposit_amount,
            security_deposit_amount_type: data?.connect_location?.security_deposit_amount_type,
          })
        );
      }
    } else {
      setApplicants(leaseApplicationSteps?.applicants);
    }
  }, [applicantDetails]);

  useEffect(() => {
    if (leaseApplicationSteps?.applicants?.length) {
      setApplicants(leaseApplicationSteps?.applicants);
      setFirstEnable(false);
      setLastEnable(false);
      if (!leaseApplicationSteps?.applicants[0]?.first_name?.length) {
        setFirstEnable(true);
      }
      if (!leaseApplicationSteps?.applicants[0]?.last_name?.length) {
        setLastEnable(true);
      }
    }
  }, [leaseApplicationSteps]);

  const createAnotherLease = () => {
    const data = applicantDetails?.data;

    const setData = [
      {
        first_name: data?.connect_contact?.first_name,
        last_name: data?.connect_contact?.last_name,
        email: data?.connect_contact?.email,
        phone: data?.connect_contact?.phone,
        contact_id: data?.connect_contact?.id,
        isSublet: false,
        documents: emptyDoc,
        deposit_status: "Unpaid",
      },
    ];
    setShowLeaseExistPopUp(null);
    dispatch(
      setleaseApplicationSteps({
        ...leaseApplicationSteps,
        applicants: setData,
        locations: {
          cms_location_id: leaseApplicationSteps?.locations?.cms_location_id
            ? leaseApplicationSteps?.locations?.cms_location_id
            : data?.location?.id,
          name: leaseApplicationSteps?.locations?.name
            ? leaseApplicationSteps?.locations?.name
            : data?.location?.name,
        },
        security_deposit_amount: data?.connect_location?.security_deposit_amount,
        security_deposit_amount_type: data?.connect_location?.security_deposit_amount_type,
      })
    );
  };

  // close lease application pop up
  const ExistingLeasePopupProps = {
    deleteBtn: false,
    open: leaseExistPopUp,
    onClose: () => {
      setShowLeaseExistPopUp(null);
      dispatch(setleaseApplicationSteps({ ...leaseApplicationSteps, step: activeStep - 1 }));
    },
    title: "Lease or Draft Already Exist",
    size: "sm",
    saveBtn: true,
    onSave: () => {
      createAnotherLease();
    },
    saveTbtText: "Yes",
    closeBtnText: "No Find Another Deal",
  };

  const onChangeFieldApplicant = (e: any, itemKey: any) => {
    const newApplicants: any = [...applicantsData];
    newApplicants[itemKey] = {
      ...newApplicants[itemKey],
      [e.target.name]: e.target.value,
    };
    setApplicants(newApplicants);

    let allFieldsFilled = true;
    // Check if all four fields are filled for the current applicant
    if (
      newApplicants[itemKey].first_name &&
      newApplicants[itemKey].last_name &&
      newApplicants[itemKey].email &&
      newApplicants[itemKey].phone
    ) {
      allFieldsFilled = false;
    }
    setSubletApplicantError(allFieldsFilled);
  };

  const addMoreApplicants = (isSublet?: any) => {
    setSubletApplicantError(true);
    if (isSublet) {
      setApplicants([...applicantsData, sendDataToApplicants({}, true)]);
    } else {
      setApplicants([...applicantsData, sendDataToApplicants({})]);
    }
  };

  const removeApplicant = (removeKey?: number) => {
    setSubletApplicantError(false);
    setErrors("");
    setApplicants(applicantsData.filter((_: any, key: number) => key !== removeKey));
  };

  const getAllApplicants = () => {
    const result: any = [];
    const tempApplicants: any = [...applicantsData];
    for (let i = 0; i < tempApplicants?.length; i++) {
      result.push(
        <Card
          id={`applicant-${i}`}
          sx={{ overflow: "visible", border: "1px solid #ddd", m: "16px 0px" }}
        >
          {i !== 0 && (
            <Box
              sx={{ p: 2, display: "flex", alignItems: "center", justifyContent: "space-between" }}
            >
              {tempApplicants[i]?.isSublet ? (
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <MDTypography variant="h5">Sublet</MDTypography>
                </Box>
              ) : (
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <MDTypography variant="h5">Co-Applicant</MDTypography>
                </Box>
              )}
              <MDIconButton
                tooltipName="Delete"
                aria-label="Delete"
                color="error"
                onClick={() => removeApplicant(i)}
              >
                <Icon fontSize="small">delete</Icon>
              </MDIconButton>
            </Box>
          )}
          <Grid container>
            <Grid item xs={12} md={6} sx={{ p: 2 }}>
              <MDInput
                label="First Name"
                variant="standard"
                fullWidth
                required={i !== 0 ? "required" : ""}
                name="first_name"
                onChange={(e: any) => onChangeFieldApplicant(e, i)}
                placeholder="Firstname"
                value={tempApplicants[i]?.first_name || ""}
                error={errors && errors[`applicant_${i}_first_name`] ? true : false}
                helperText={errors && errors[`applicant_${i}_first_name`]}
                disabled={i === 0 && !firstEnable}
              />
            </Grid>
            <Grid item xs={12} md={6} sx={{ p: 2 }}>
              <MDInput
                label="Last Name"
                variant="standard"
                fullWidth
                required={i !== 0 ? "required" : ""}
                name="last_name"
                onChange={(e: any) => onChangeFieldApplicant(e, i)}
                placeholder="Lastname"
                value={tempApplicants[i]?.last_name || ""}
                error={errors && errors[`applicant_${i}_last_name`] ? true : false}
                helperText={errors && errors[`applicant_${i}_last_name`]}
                disabled={i === 0 && !lastEnable}
              />
            </Grid>
            <Grid item xs={12} md={6} sx={{ p: 2 }}>
              <MDInput
                label="Email"
                variant="standard"
                fullWidth
                required={i !== 0 ? "required" : ""}
                name="email"
                onChange={(e: any) => onChangeFieldApplicant(e, i)}
                placeholder="email"
                value={tempApplicants[i]?.email || ""}
                error={errors && errors[`applicant_${i}_email`] ? true : false}
                helperText={errors && errors[`applicant_${i}_email`]}
                disabled={i === 0}
              />
            </Grid>
            <Grid item xs={12} md={6} sx={{ p: 2 }}>
              <MDInput
                variant="standard"
                fullWidth
                label="Phone"
                name="phone"
                placeholder="(000) 000-0000"
                id="phone-input"
                onChange={(e: any) => onChangeFieldApplicant(e, i)}
                InputProps={{
                  inputComponent: CustomPhoneInput as any,
                }}
                value={tempApplicants[i]?.phone || ""}
                error={errors && errors[`applicant_${i}_phone`] ? true : false}
                helperText={errors && errors[`applicant_${i}_phone`]}
                required={i !== 0 ? "required" : ""}
                disabled={i === 0}
              />
            </Grid>
          </Grid>
        </Card>
      );
    }
    return result;
  };

  return (
    <>
      <Grid
        container
        sx={{
          maxHeight: "100%",
          display: "block",
        }}
      >
        {getAllApplicants()}
        <Grid item xs={12} sx={{ display: "flex" }}>
          <MDButton
            variant="gradient"
            color="info"
            size="small"
            className="xs-small"
            onClick={() => addMoreApplicants(true)}
          >
            <Icon sx={{ fontWeight: "bold" }}>add</Icon> Add Sublet
          </MDButton>
          <MDButton
            variant="gradient"
            color="info"
            size="small"
            className="xs-small"
            sx={{ ml: 1 }}
            onClick={() => addMoreApplicants(false)}
          >
            <Icon sx={{ fontWeight: "bold" }}>add</Icon> Add Applicant
          </MDButton>
        </Grid>
      </Grid>
      <HandleButton btnTxt="Select Studio" {...props} datas={{ applicants: applicantsData }} />
      {leaseExistPopUp && (
        <MDDialog {...ExistingLeasePopupProps}>
          <MDTypography variant="h6" fontWeight="medium">
            {leaseExistPopUp}
          </MDTypography>
        </MDDialog>
      )}
    </>
  );
}

export default Sublets;
