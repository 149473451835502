import { Card, Grid, FormControl, FormGroup, Checkbox, FormControlLabel } from "@mui/material";
import CustomErrorMessage from "components/CustomErrorMessage";
import MDBox from "components/MDBox";
import MDInput from "components/MDInput";
import MDTypography from "components/MDTypography";
import MESSAGES from "helper/messages";

function Services(props: any): JSX.Element {
  const { onCheckboxChange, errors, solaProDetail, onChangeField } = props;
  return (
    <Card id="services" sx={{ overflow: "visible" }}>
      <MDBox p={3}>
        <MDTypography variant="h5">Services</MDTypography>
      </MDBox>
      <MDBox pl={3} pr={3} pb={2}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6} md={4} lg={4}>
            <FormControl component="fieldset">
              <FormGroup aria-label="position">
                <FormControlLabel
                  value="end"
                  control={
                    <Checkbox
                      checked={solaProDetail?.barber || false}
                      name="barber"
                      onChange={onCheckboxChange}
                      inputProps={{ "aria-label": "controlled" }}
                    />
                  }
                  label="Barber"
                  labelPlacement="end"
                />
              </FormGroup>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={4}>
            <FormControl component="fieldset">
              <FormGroup aria-label="position">
                <FormControlLabel
                  value="end"
                  control={
                    <Checkbox
                      checked={solaProDetail?.botox || false}
                      name="botox"
                      onChange={onCheckboxChange}
                      inputProps={{ "aria-label": "controlled" }}
                    />
                  }
                  label="Botox/Fillers"
                  labelPlacement="end"
                />
              </FormGroup>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={4}>
            <FormControl component="fieldset">
              <FormGroup aria-label="position">
                <FormControlLabel
                  value="end"
                  control={
                    <Checkbox
                      checked={solaProDetail?.brows || false}
                      name="brows"
                      onChange={onCheckboxChange}
                      inputProps={{ "aria-label": "controlled" }}
                    />
                  }
                  label="Brows"
                  labelPlacement="end"
                />
              </FormGroup>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={4}>
            <FormControl component="fieldset">
              <FormGroup aria-label="position">
                <FormControlLabel
                  value="end"
                  control={
                    <Checkbox
                      checked={solaProDetail?.hair || false}
                      name="hair"
                      onChange={onCheckboxChange}
                      inputProps={{ "aria-label": "controlled" }}
                    />
                  }
                  label="Hair"
                  labelPlacement="end"
                />
              </FormGroup>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={4} className="services">
            <FormControl component="fieldset">
              <FormGroup aria-label="position">
                <FormControlLabel
                  value="end"
                  control={
                    <Checkbox
                      checked={solaProDetail?.hair_extensions || false}
                      name="hair_extensions"
                      onChange={onCheckboxChange}
                      inputProps={{ "aria-label": "controlled" }}
                    />
                  }
                  label="Hair Extensions"
                  labelPlacement="end"
                />
              </FormGroup>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={4}>
            <FormControl component="fieldset">
              <FormGroup aria-label="position">
                <FormControlLabel
                  value="end"
                  control={
                    <Checkbox
                      checked={solaProDetail?.laser_hair_removal || false}
                      name="laser_hair_removal"
                      onChange={onCheckboxChange}
                      inputProps={{ "aria-label": "controlled" }}
                    />
                  }
                  label="Laser Hair Removal"
                  labelPlacement="end"
                />
              </FormGroup>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={4}>
            <FormControl component="fieldset">
              <FormGroup aria-label="position">
                <FormControlLabel
                  value="end"
                  control={
                    <Checkbox
                      checked={solaProDetail?.eyelash_extensions || false}
                      name="eyelash_extensions"
                      onChange={onCheckboxChange}
                      inputProps={{ "aria-label": "controlled" }}
                    />
                  }
                  label="Lashes"
                  labelPlacement="end"
                />
              </FormGroup>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={4}>
            <FormControl component="fieldset">
              <FormGroup aria-label="position">
                <FormControlLabel
                  value="end"
                  control={
                    <Checkbox
                      checked={solaProDetail?.makeup || false}
                      name="makeup"
                      onChange={onCheckboxChange}
                      inputProps={{ "aria-label": "controlled" }}
                    />
                  }
                  label="Makeup"
                  labelPlacement="end"
                />
              </FormGroup>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={4}>
            <FormControl component="fieldset">
              <FormGroup aria-label="position">
                <FormControlLabel
                  value="end"
                  control={
                    <Checkbox
                      checked={solaProDetail?.massage || false}
                      name="massage"
                      onChange={onCheckboxChange}
                      inputProps={{ "aria-label": "controlled" }}
                    />
                  }
                  label="Massage"
                  labelPlacement="end"
                />
              </FormGroup>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={4}>
            <FormControl component="fieldset">
              <FormGroup aria-label="position">
                <FormControlLabel
                  value="end"
                  control={
                    <Checkbox
                      checked={solaProDetail?.microblading || false}
                      name="microblading"
                      onChange={onCheckboxChange}
                      inputProps={{ "aria-label": "controlled" }}
                    />
                  }
                  label="Microblading"
                  labelPlacement="end"
                />
              </FormGroup>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={4}>
            <FormControl component="fieldset">
              <FormGroup aria-label="position">
                <FormControlLabel
                  value="end"
                  control={
                    <Checkbox
                      checked={solaProDetail?.nails || false}
                      name="nails"
                      onChange={onCheckboxChange}
                      inputProps={{ "aria-label": "controlled" }}
                    />
                  }
                  label="Nails"
                  labelPlacement="end"
                />
              </FormGroup>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={4}>
            <FormControl component="fieldset">
              <FormGroup aria-label="position">
                <FormControlLabel
                  value="end"
                  control={
                    <Checkbox
                      checked={solaProDetail?.permanent_makeup || false}
                      name="permanent_makeup"
                      onChange={onCheckboxChange}
                      inputProps={{ "aria-label": "controlled" }}
                    />
                  }
                  label="Permanent Makeup"
                  labelPlacement="end"
                />
              </FormGroup>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={4}>
            <FormControl component="fieldset">
              <FormGroup aria-label="position">
                <FormControlLabel
                  value="end"
                  control={
                    <Checkbox
                      checked={solaProDetail?.skin || false}
                      name="skin"
                      onChange={onCheckboxChange}
                      inputProps={{ "aria-label": "controlled" }}
                    />
                  }
                  label="Skincare"
                  labelPlacement="end"
                />
              </FormGroup>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={4}>
            <FormControl component="fieldset">
              <FormGroup aria-label="position">
                <FormControlLabel
                  value="end"
                  control={
                    <Checkbox
                      checked={solaProDetail?.tanning || false}
                      name="tanning"
                      onChange={onCheckboxChange}
                      inputProps={{ "aria-label": "controlled" }}
                    />
                  }
                  label="Tanning"
                  labelPlacement="end"
                />
              </FormGroup>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={4}>
            <FormControl component="fieldset">
              <FormGroup aria-label="position">
                <FormControlLabel
                  value="end"
                  control={
                    <Checkbox
                      checked={solaProDetail?.teeth_whitening || false}
                      name="teeth_whitening"
                      onChange={onCheckboxChange}
                      inputProps={{ "aria-label": "controlled" }}
                    />
                  }
                  label="Teeth Whitening"
                  labelPlacement="end"
                />
              </FormGroup>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={4}>
            <FormControl component="fieldset">
              <FormGroup aria-label="position">
                <FormControlLabel
                  value="end"
                  control={
                    <Checkbox
                      checked={solaProDetail?.threading || false}
                      name="threading"
                      onChange={onCheckboxChange}
                      inputProps={{ "aria-label": "controlled" }}
                    />
                  }
                  label="Threading"
                  labelPlacement="end"
                />
              </FormGroup>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={4}>
            <FormControl component="fieldset">
              <FormGroup aria-label="position">
                <FormControlLabel
                  value="end"
                  control={
                    <Checkbox
                      checked={solaProDetail?.waxing || false}
                      name="waxing"
                      onChange={onCheckboxChange}
                      inputProps={{ "aria-label": "controlled" }}
                    />
                  }
                  label="Waxing"
                  labelPlacement="end"
                />
              </FormGroup>
            </FormControl>
          </Grid>
          {/* SD-3065 changes */}
          <Grid item xs={12} sm={6} md={4} lg={4}>
            <MDInput
              label={
                <MDTypography sx={{ fontWeight: "700", fontSize: "inherit" }}>Other</MDTypography>
              }
              variant="standard"
              fullWidth
              name="other_service"
              placeholder=""
              onChange={onChangeField}
              className="info-error"
              value={solaProDetail?.other_service ? solaProDetail?.other_service : ""}
              error={errors && errors.other_service ? true : false}
              helperText={errors && errors.other_service}
              infoText={MESSAGES.INFO.OPTIONAL_LENGTH_255}
            />
          </Grid>
        </Grid>
      </MDBox>
      <MDBox pl={3} pr={3} pt={0} pb={3}>
        {errors && errors.services && <CustomErrorMessage message={errors.services} />}
      </MDBox>
    </Card>
  );
}

export default Services;
