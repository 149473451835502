import { Grid, FormControl, Card, FormGroup, FormControlLabel, Checkbox } from "@mui/material";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import CustomPasswordInput from "components/CustomPasswordInput";

function Password(props: any): JSX.Element {
  const { solaProDetail, onCheckboxChange, isUpdate, onChangeField, errors } = props;
  return (
    <Card id="password">
      <MDBox p={3}>
        <MDTypography variant="h5">Password</MDTypography>
      </MDBox>
      <MDBox pb={3} px={3}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6} md={6} lg={6}>
            <CustomPasswordInput
              label="Password"
              name="password"
              placeholder="Password"
              onChange={onChangeField}
              value={solaProDetail?.password || ""}
              error={errors && errors.password ? true : false}
              helperText={errors && errors.password}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={6} lg={6}>
            <CustomPasswordInput
              label="Confirm Password"
              name="password_confirmation"
              placeholder="Confirm Password"
              onChange={onChangeField}
              value={solaProDetail?.password_confirmation || ""}
              error={errors && errors.password_confirmation ? true : false}
              helperText={errors && errors.password_confirmation}
            />
          </Grid>
          {isUpdate && (
            <Grid item xs={12} sm={6} md={6} lg={6}>
              <FormControl component="fieldset">
                <FormGroup aria-label="position">
                  <FormControlLabel
                    value="end"
                    control={
                      <Checkbox
                        checked={solaProDetail?.is_deleted || false}
                        name="is_deleted"
                        onChange={onCheckboxChange}
                        inputProps={{ "aria-label": "controlled" }}
                      />
                    }
                    label="Delete Account (Soft Delete)"
                    labelPlacement="end"
                  />
                </FormGroup>
              </FormControl>
            </Grid>
          )}
        </Grid>
      </MDBox>
    </Card>
  );
}

export default Password;
