import MDDialog from "components/MDDialog";
import MDBox from "components/MDBox";
import { Accordion, AccordionDetails, AccordionSummary, Grid } from "@mui/material";
import MDTypography from "components/MDTypography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { getLocalDateTime } from "helper/services";

function ViewNotesDetails(props: any): JSX.Element {
  const { detailsPopup, onClose, notesData } = props;

  const dealNotesDialogProps = {
    open: detailsPopup,
    onClose,
    title: "Resubmitted Contact Inquiries",
    size: "md",
    saveBtn: false,
    deleteBtn: false,
    saveTbtText: "Yes",
    disableParentClick: true,
  };

  const getDetailElementWithDangersoulyHTML = (createdDate: any, value: any) => {
    if (value) {
      const newValue = `${value}<b>Created At :</b>${
        createdDate !== "undefined" ? getLocalDateTime(createdDate) : "-"
      }<br/>`;
      return (
        <Grid item xs={12} sm={12} md={12}>
          <MDBox
            dangerouslySetInnerHTML={{
              __html: value ? newValue : "",
            }}
          />
        </Grid>
      );
    }
    return null;
  };

  const getAccordianSummary = (name: any) => (
    <AccordionSummary
      className="location-accordion"
      expandIcon={<ExpandMoreIcon />}
      aria-controls="publishbh-content"
    >
      <MDTypography fontSize="16px">{name}</MDTypography>
    </AccordionSummary>
  );

  return (
    <MDDialog {...dealNotesDialogProps}>
      <MDBox borderRadius="xl" alignItems="center">
        {notesData && notesData?.length
          ? notesData?.map((obj: any) => (
              <MDBox key={obj.id} mb={1}>
                <Accordion>
                  {getAccordianSummary(
                    `Submitted On: ${
                      obj?.created_at !== "undefined" ? getLocalDateTime(obj?.created_at) : "-"
                    }`
                  )}
                  <AccordionDetails>
                    <MDBox sx={{ wordBreak: "break-all" }}>
                      {getDetailElementWithDangersoulyHTML(
                        obj.created_at,
                        obj?.note_data ? obj?.note_data : ""
                      )}
                    </MDBox>
                  </AccordionDetails>
                </Accordion>
              </MDBox>
            ))
          : "No Notes Found"}
      </MDBox>
    </MDDialog>
  );
}

export default ViewNotesDetails;
