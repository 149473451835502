import { Card, Icon } from "@mui/material";
import MDBox from "components/MDBox";
import MDIconButton from "components/MDIconButton";
import MDTypography from "components/MDTypography";
import DataTable from "examples/Tables/DataTable";
import { useEffect, useState } from "react";
import { getLocalDateTime } from "helper/services";
import { useAppSelector } from "store/store";
import ViewDetails from "./viewDetails";

function LeaseAgreements(): JSX.Element {
  const { billingTabDetail } = useAppSelector((state) => state.solaProSlice);
  const [detailsPopup, setDetailsPopup] = useState<any>(false);
  const [updateData, setUpdateData] = useState<any>(null);
  const [leaseAgreementList, setLeaseAgreementList] = useState<any>([]);

  useEffect(() => {
    if (billingTabDetail && billingTabDetail?.leaseList) {
      setLeaseAgreementList(billingTabDetail?.leaseList);
    }
  }, [billingTabDetail]);

  const openDetailPopup = (object?: any) => {
    setUpdateData(object);
    setDetailsPopup(true);
  };

  const closeDetailPopup = () => {
    setUpdateData(null);
    setDetailsPopup(false);
  };

  const actionButtons = (object: any) => (
    <MDIconButton
      tooltipName={!object?.AgreementUrl ? "Lease Agreement Not Found" : "View Lease Agreement"}
      aria-label="Info"
      color="info"
      disabled={!object?.AgreementUrl ? true : false}
      onClick={() => openDetailPopup(object)}
    >
      <Icon fontSize="small">visibility</Icon>
    </MDIconButton>
  );

  const getRows = () => {
    const rows: any = leaseAgreementList.map((obj: any) => ({
      unit: obj?.UnitID ? obj?.UnitID : "-",
      start: obj?.MoveInDate ? getLocalDateTime(obj?.MoveInDate) : "-",
      end: obj?.MoveOutDate ? getLocalDateTime(obj?.MoveOutDate) : "-",
      status: obj?.Status ? obj?.Status : "-",
      action: actionButtons(obj),
    }));
    return rows;
  };

  const tableData = {
    columns: [
      { Header: "Unit", accessor: "unit", width: "15%" },
      { Header: "Start", accessor: "start", width: "20%" },
      { Header: "End", accessor: "end", width: "20%" },
      { Header: "Status", accessor: "status" },
      { Header: "Action", accessor: "action" },
    ],
    rows: getRows(),
  };

  return (
    <Card id="lease-agreements" sx={{ overflow: "visible" }}>
      <MDBox p={3}>
        <MDTypography variant="h5">Lease Agreements</MDTypography>
      </MDBox>
      <DataTable table={tableData} canSearch searchText="Search..." />
      <ViewDetails open={detailsPopup} updateData={updateData} onClose={() => closeDetailPopup()} />
    </Card>
  );
}

export default LeaseAgreements;
