import { useState } from "react";
import { v4 as uuidv4 } from "uuid";
import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";
import TimePicker from "rc-time-picker";
import MenuItem from "@mui/material/MenuItem";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import FormControl from "@mui/material/FormControl";
import Card from "@mui/material/Card";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import CustomDropZone from "components/CustomDropZone";
import ImagePreview from "layouts/pages/ImagePreview";
import { getMUISelectValue } from "helper/services";
import constants from "helper/constants";
import MDIconButton from "components/MDIconButton";
import MESSAGES from "helper/messages";
import InputAdornment from "@mui/material/InputAdornment";
import InfoIcon from "@mui/icons-material/Info";
import Tooltip from "@mui/material/Tooltip";

function Rockbot(props: any): JSX.Element {
  const {
    locationDetail,
    onChangeField,
    onChangeTimeField,
    onChangeImage,
    onImageRemove,
    floorPlanImage,
  } = props;

  const [isPreview, setImagePreviewPopup] = useState(false);
  const [imageToPreview, setImageToPreview] = useState("");

  const previewImage = (image: any) => {
    setImagePreviewPopup(true);
    setImageToPreview(image);
  };

  const closeImagePreview = () => {
    setImagePreviewPopup(false);
    setImageToPreview("");
  };

  return (
    <Card id="rockbot">
      <MDBox p={3}>
        <MDTypography variant="h5">Rockbot</MDTypography>
      </MDBox>
      <MDBox pb={3} px={3}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6} md={6} lg={6}>
            <FormControl fullWidth>
              <InputLabel>Walk-Ins Enabled</InputLabel>
              <Select
                className="height45"
                label="Walk-Ins Enabled"
                value={getMUISelectValue(locationDetail?.walkins_enabled)}
                name="walkins_enabled"
                onChange={onChangeField}
                endAdornment={
                  <InputAdornment position="end">
                    <Tooltip title={MESSAGES.INFO.WALK_IN_ENABLED} arrow>
                      <InfoIcon
                        style={{
                          width: 20,
                          height: 20,
                          cursor: "pointer",
                          color: "rgba(48, 57, 65, 0.3)",
                          marginRight: "5px",
                        }}
                      />
                    </Tooltip>
                  </InputAdornment>
                }
              >
                <MenuItem value="true">Yes</MenuItem>
                <MenuItem value="false">No</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6} md={6} lg={6}>
            <FormControl fullWidth>
              <InputLabel>Max Walk-Ins Time</InputLabel>
              <Select
                className="height45"
                label="Max Walk-Ins Time"
                value={locationDetail?.max_walkins_time || ""}
                name="max_walkins_time"
                onChange={onChangeField}
                endAdornment={
                  <InputAdornment position="end">
                    <Tooltip title={MESSAGES.INFO.MAX_WALK_IN_TIME} arrow>
                      <InfoIcon
                        style={{
                          width: 20,
                          height: 20,
                          cursor: "pointer",
                          color: "rgba(48, 57, 65, 0.3)",
                          marginRight: "5px",
                        }}
                      />
                    </Tooltip>
                  </InputAdornment>
                }
              >
                {constants.maxWalkInsTimes.map((obj) => (
                  <MenuItem key={uuidv4()} value={obj.time}>
                    {obj.label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6} md={6} lg={6} className="end-of-day-time timPickerClass">
            <InputLabel>Walk-Ins End Of Day</InputLabel>
            <TimePicker
              placeholder="Walk-Ins End Of Day"
              className="height45"
              showSecond={false}
              format="HH:mm"
              inputReadOnly
              name="walkins_end_of_day"
              value={locationDetail?.walkins_end_of_day || null}
              onChange={onChangeTimeField}
            />
            <Tooltip title={MESSAGES.INFO.WALK_IN_END_OF_DAY} arrow>
              <InfoIcon
                style={{
                  width: 20,
                  height: 20,
                  cursor: "pointer",
                  color: "rgba(48, 57, 65, 0.3)",
                  marginRight: "5px",
                  position: "absolute",
                  top: "36px",
                  right: "0px",
                }}
              />
            </Tooltip>
          </Grid>
          <Grid item xs={12} sm={6} md={6} lg={6}>
            <FormControl fullWidth>
              <InputLabel>Walk-Ins Time Zone</InputLabel>
              <Select
                className="height45"
                label="Walk-Ins Time Zone"
                value={locationDetail?.walkins_timezone || ""}
                name="walkins_timezone"
                onChange={onChangeField}
                endAdornment={
                  <InputAdornment position="end">
                    <Tooltip title={MESSAGES.INFO.WALK_IN_TIME_ZONE} arrow>
                      <InfoIcon
                        style={{
                          width: 20,
                          height: 20,
                          cursor: "pointer",
                          color: "rgba(48, 57, 65, 0.3)",
                          marginRight: "5px",
                        }}
                      />
                    </Tooltip>
                  </InputAdornment>
                }
              >
                <MenuItem value="Pacific Time">Pacific Time</MenuItem>
                <MenuItem value="Mountain Time">Mountain Time</MenuItem>
                <MenuItem value="Central Time">Central Time</MenuItem>
                <MenuItem value="Eastern Time">Eastern Time</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6} md={6} lg={6}>
            <MDTypography component="label" variant="button" fontWeight="regular" color="text">
              Floorplan Image
            </MDTypography>
            <Grid container>
              <Grid item xs={12} sm={8} md={8} lg={8}>
                <CustomDropZone
                  multipleUpload={false}
                  acceptedFiles={{
                    "image/*": [".gif", ".png", ".jpg", ".jpeg"],
                  }}
                  onDrop={(e: any) => onChangeImage(e, "floorplan_image")}
                >
                  Drop your image here, or Browse
                </CustomDropZone>
              </Grid>
              <Grid item xs={12} sm={4} md={4} lg={4}>
                {floorPlanImage && (
                  <Grid
                    container
                    onClick={() => previewImage(floorPlanImage)}
                    justifyContent="center"
                    alignItems="center"
                    textAlign="center"
                  >
                    <Grid item className="imageWithDeleteIcon">
                      <img
                        width="90%"
                        style={{ maxHeight: "100px" }}
                        src={floorPlanImage}
                        alt="original"
                      />
                      <MDIconButton
                        tooltipName="Delete"
                        aria-label="Delete"
                        className="removeBtnClass"
                        onClick={(e: any) => onImageRemove(e, `floorplan_image`)}
                      >
                        <Icon fontSize="small">close</Icon>
                      </MDIconButton>
                    </Grid>
                  </Grid>
                )}
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={6} md={6} lg={6}>
            <MDInput
              label="Rockbot Manager Email"
              variant="standard"
              fullWidth
              name="rockbot_manager_email"
              placeholder="Rockbot Manager Email"
              onChange={onChangeField}
              value={locationDetail?.rockbot_manager_email || ""}
              infoText={MESSAGES.INFO.OPTIONAL}
            />
          </Grid>
        </Grid>
        {isPreview && (
          <ImagePreview
            imageData={imageToPreview}
            open={isPreview}
            onCloseImage={() => {
              closeImagePreview();
            }}
          />
        )}
      </MDBox>
    </Card>
  );
}

export default Rockbot;
