import { createSlice, Draft, PayloadAction } from "@reduxjs/toolkit";

interface InitialState {
  webBlogs: any;
  featuredBlogsList: any;
  articles: any;
  blog: any;
  categoryList: any;
  category: any;
  tagList: any;
  tag: any;
}

const initialState: InitialState = {
  webBlogs: [],
  featuredBlogsList: [],
  articles: [],
  blog: null,
  categoryList: [],
  category: null,
  tagList: [],
  tag: null,
};

const solaWebSiteSlice = createSlice({
  name: "solaWebSite",
  initialState,
  reducers: {
    webBlogsList: (state: Draft<InitialState>, action: PayloadAction<any>) => ({
      ...state,
      webBlogs: action.payload,
    }),
    getFeaturedBlogsList: (state: Draft<InitialState>, action: PayloadAction<any>) => ({
      ...state,
      featuredBlogsList: action.payload,
    }),
    singleBlog: (state: Draft<InitialState>, action: PayloadAction<any>) => ({
      ...state,
      blog: action.payload,
    }),
    articlesList: (state: Draft<InitialState>, action: PayloadAction<any>) => ({
      ...state,
      articles: action.payload,
    }),
    categoriesList: (state: Draft<InitialState>, action: PayloadAction<any>) => ({
      ...state,
      categoryList: action.payload,
    }),
    category: (state: Draft<InitialState>, action: PayloadAction<any>) => ({
      ...state,
      category: action.payload,
    }),
    tagList: (state: Draft<InitialState>, action: PayloadAction<any>) => ({
      ...state,
      tagList: action.payload,
    }),
    setTag: (state: Draft<InitialState>, action: PayloadAction<any>) => ({
      ...state,
      tag: action.payload,
    }),
  },
});

export const {
  webBlogsList,
  getFeaturedBlogsList,
  articlesList,
  categoriesList,
  category,
  tagList,
  setTag,
} = solaWebSiteSlice.actions;

export default solaWebSiteSlice.reducer;
