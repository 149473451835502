import { useEffect } from "react";
import { Grid } from "@mui/material";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import MDBox from "components/MDBox";
import { useAppDispatch, useAppSelector } from "store/store";
import { getRMSMSRequestDetails } from "store/thunk/repairMaintenance";

function Index(props?: any): JSX.Element {
  const { onRMRequestSMS, locationDetail } = props;
  const { smsRequestDetailData } = useAppSelector((state) => state.repairMaintenanceSlice);
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(getRMSMSRequestDetails({ location_id: locationDetail?.id }));
  }, []);

  const requestButton = () => (
    <Grid
      container
      spacing={0}
      direction="column"
      alignItems="center"
      justifyContent="center"
      textAlign="center"
      style={{ minHeight: "70vh", padding: "10px" }}
    >
      <MDTypography variant="body2" sx={{ mb: 2 }}>
        To active R&M SMS for this Location, please contact our team.
      </MDTypography>
      <Grid item xs={3}>
        <MDButton
          onClick={onRMRequestSMS}
          variant="gradient"
          color="info"
          size="small"
          className="xs-small"
        >
          Request SMS
        </MDButton>
      </Grid>
    </Grid>
  );

  const messageRequestStatus = () => (
    <Grid
      container
      spacing={0}
      direction="column"
      alignItems="center"
      justifyContent="center"
      textAlign="center"
      style={{ minHeight: "70vh", padding: "10px" }}
    >
      <MDTypography variant="body2" sx={{ mb: 2 }}>
        {smsRequestDetailData?.status === "requested"
          ? "SMS request for this location has been submited and admin will review soon."
          : `SMS functionality is ${smsRequestDetailData?.status} or phone number is deleted by admin.
        Please contact to admin.`}
      </MDTypography>
    </Grid>
  );

  const updateSettings = () => (
    <Grid
      container
      spacing={0}
      direction="column"
      alignItems="center"
      justifyContent="center"
      textAlign="center"
    >
      <MDTypography variant="body2" sx={{ mb: 2 }}>
        R&M Phone number: {smsRequestDetailData?.assigned_number_data?.twillio_phone_number}
      </MDTypography>
    </Grid>
  );

  const getComponent = (status?: any) => {
    switch (status) {
      case "":
        return requestButton();
      case "active":
        return updateSettings();
      default:
        return messageRequestStatus();
    }
  };

  return <MDBox mt={3}>{getComponent(smsRequestDetailData?.status || "")}</MDBox>;
}

export default Index;
