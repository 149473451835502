import { useEffect, useState } from "react";
import MDDialog from "components/MDDialog";
import MDBox from "components/MDBox";
import { Autocomplete, TextField } from "@mui/material";
import { useAppDispatch, useAppSelector } from "store/store";
import { saveStateRegions } from "store/thunk/mobileAppThunk";
import Validations from "helper/validations";

function AddUpdate(props: any): JSX.Element {
  const dispatch = useAppDispatch();
  const { solaClassRegions, states } = useAppSelector((state) => state.mobileAppSlice);
  const { open, onClose, updateData, pageNo, perPage, search, sortBy } = props;
  const [isUpdate, setIsUpdate] = useState<any>(false);
  const [stateRegionData, setStateRegionData] = useState<any>({});
  const [classRegionList, setClassRegionList] = useState<any>([]);
  const [stateList, setStateList] = useState<any>([]);
  const [errors, setErrors] = useState<any>({});

  const onSave = () => {
    const reqData = {
      ...stateRegionData,
      sola_class_region_id: stateRegionData?.sola_class_region?.id || "",
      state: stateRegionData?.state?.state || "",
    };
    delete reqData.location;
    const error = Validations.validateStateRegionsForm(reqData);
    setErrors(error);
    if (!Object.keys(error).length) {
      const req = {
        stateRegionData: reqData,
        onClose,
        isUpdate,
        pageNo,
        perPage,
        search,
        sortBy,
      };
      dispatch(saveStateRegions(req));
    }
  };

  useEffect(() => {
    if (solaClassRegions && solaClassRegions.length > 0) {
      setClassRegionList(solaClassRegions);
    }
    if (states && states.length > 0) {
      setStateList(states);
    }
  }, [solaClassRegions, states]);

  useEffect(() => {
    if (updateData) {
      setIsUpdate(true);
      setStateRegionData((prev: any) => ({
        ...prev,
        id: updateData.id,
        sola_class_region: solaClassRegions.find(
          (v: any) => v.id === updateData.sola_class_region_id
        ),
        state: states.find((v: any) => v.state === updateData.state),
      }));
    }
  }, [updateData]);

  const dialogProps = {
    open,
    onSave,
    onClose,
    title: isUpdate ? "Update State Regions" : "Add State Regions",
    size: "sm",
    saveTbtText: isUpdate ? "Update" : "Save",
  };

  return (
    <MDDialog {...dialogProps}>
      <MDBox mt={2}>
        <Autocomplete
          id="sola_class_region"
          options={classRegionList}
          getOptionLabel={(option) => option.name}
          value={stateRegionData.sola_class_region}
          onChange={(event: any, newValue: any) => {
            setStateRegionData({ ...stateRegionData, sola_class_region: newValue });
          }}
          renderOption={(props: any, option: any) => (
            <li {...props} key={option.id}>
              {option.name}
            </li>
          )}
          renderInput={(params: any) => (
            <TextField
              {...params}
              required
              variant="standard"
              label="Sola Class Region"
              placeholder="Sola Class Region"
              error={errors && errors.sola_class_region ? true : false}
              helperText={errors && errors.sola_class_region}
            />
          )}
        />
      </MDBox>
      <MDBox mt={2}>
        <Autocomplete
          id="state"
          options={stateList}
          getOptionLabel={(option) => option.state}
          value={stateRegionData.state}
          onChange={(event: any, newValue: any) => {
            setStateRegionData({ ...stateRegionData, state: newValue });
          }}
          renderOption={(props: any, option: any) => (
            <li {...props} key={option.id}>
              {option.state}
            </li>
          )}
          renderInput={(params: any) => (
            <TextField
              {...params}
              required
              variant="standard"
              label="State"
              placeholder="State"
              error={errors && errors.state ? true : false}
              helperText={errors && errors.state}
            />
          )}
        />
      </MDBox>
    </MDDialog>
  );
}

export default AddUpdate;
