/* eslint-disable no-unused-vars */
import { Dialog, DialogTitle, Grid, Icon, Typography } from "@mui/material";
import config from "config/config";
import axios from "axios";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDDatePicker from "components/MDDatePicker";
import MDIconButton from "components/MDIconButton";
import MDInput from "components/MDInput";
import Messages from "helper/messages";
import MDTypography from "components/MDTypography";
import { notificationFail, notificationSuccess } from "store/slices/notificationSlice";
import { useAppDispatch } from "store/store";

interface AddEditDialogProps {
  selectedItem: {
    [key: string]: string | number;
  };
  selectedDialog: string;
  setSelectedItem: (value: { [key: string]: string | number }) => void;
  onClose: (value: string) => void;
  title: string;
  fetchApi: boolean;
  setFetchApi: (value: boolean) => void;
}

function AddRoyaltyDialog(props: AddEditDialogProps) {
  const { onClose, selectedItem, selectedDialog, setSelectedItem, title, fetchApi, setFetchApi } =
    props;
  const dispatch = useAppDispatch();

  const handleClose = () => {
    onClose("");
  };

  const handleChange = (e: any) => {
    const updatedItem = { ...selectedItem };
    updatedItem[e.target.name] = e.target.value;
    setSelectedItem(updatedItem);
  };

  const handleMonthYearDate = (date: any) => {
    const updatedItem = { ...selectedItem };
    const dateObject = new Date(date);
    const month = dateObject.getMonth() + 1;
    const year = dateObject.getFullYear();
    const formattedDate = `${year}-${month.toString().padStart(2, "0")}`;
    updatedItem.month_year = formattedDate;
    setSelectedItem(updatedItem);
  };

  const submitItem = () => {
    // send item to api
    if (selectedDialog === "create") {
      axios
        .post(`${config.REPORT_URL}/report/saveRoyaltyData`, selectedItem, {
          headers: {
            "x-api-key": config.REPORT_KEY,
          },
        })
        .then((response) => {
          setFetchApi(!fetchApi);
          dispatch(
            notificationSuccess(response.data.message || Messages.SUCCESS.MASTER_DATA_CREATED)
          );
        })
        .catch((err) => {
          dispatch(
            notificationFail(err?.response?.data?.message || Messages.ERROR.SOMETHING_WENT_WRONG)
          );
        });
    } else {
      axios
        .post(`${config.REPORT_URL}/report/editRoyaltyData`, selectedItem, {
          headers: {
            "x-api-key": config.REPORT_KEY,
          },
        })
        .then((response) => {
          setFetchApi(!fetchApi);
          dispatch(
            notificationSuccess(response.data.message || Messages.SUCCESS.MASTER_DATA_UPDATED)
          );
        })
        .catch((err) => {
          dispatch(
            notificationFail(err?.response?.data?.message || Messages.ERROR.SOMETHING_WENT_WRONG)
          );
        });
    }
    onClose("");
  };

  return (
    <Dialog open>
      <Grid container>
        <Grid item md={12} display="flex" justifyContent="space-between" alignItems="center">
          <DialogTitle>{title}</DialogTitle>
          <MDBox mr={2}>
            <MDIconButton
              tooltipName="Close"
              aria-label="Close"
              color="error"
              onClick={handleClose}
            >
              <Icon fontSize="small">close</Icon>
            </MDIconButton>
          </MDBox>
        </Grid>
        <Grid item md={12} sx={{ padding: "0px 30px 0px 30px" }}>
          <MDInput
            label="Sola ID"
            name="sola_id"
            sx={{ width: "100%", paddingBottom: "10px" }}
            value={selectedItem?.sola_id ? selectedItem.sola_id : ""}
            onChange={handleChange}
          />
          <MDBox display="flex" padding="5px 0px 5px 0px">
            <Grid item md={6}>
              <MDBox display="flex" alignItems="center" sx={{ padding: "5px 5px 0px 0px" }}>
                <MDTypography variant="button">Month-Year:</MDTypography>
                <MDDatePicker
                  label="Month-Year"
                  input={{ placeholder: "Select a date" }}
                  options={{
                    dateFormat: "Y/m",
                  }}
                  value={selectedItem?.month_year ? selectedItem.month_year : ""}
                  onChange={([date]: any) => handleMonthYearDate(date)}
                />
              </MDBox>
            </Grid>
            <Grid item md={6}>
              <MDBox sx={{ padding: "5px 0px 5px 0px" }}>
                <MDInput
                  label="Income"
                  name="income"
                  sx={{ width: "100%", paddingRight: "5px" }}
                  value={selectedItem?.income ? selectedItem.income : ""}
                  onChange={handleChange}
                />
              </MDBox>
            </Grid>
          </MDBox>
        </Grid>
        <Grid item md={12} display="flex" justifyContent="end" sx={{ padding: "10px" }}>
          <MDButton
            variant="contained"
            color="primary"
            sx={{ marginRight: "10px" }}
            onClick={handleClose}
          >
            Cancel
          </MDButton>
          <MDButton variant="contained" color="success" onClick={submitItem}>
            Submit
          </MDButton>
        </Grid>
      </Grid>
    </Dialog>
  );
}

export default AddRoyaltyDialog;
