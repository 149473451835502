import { Card, Grid, FormControlLabel, Switch } from "@mui/material";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import CustomPhoneInput from "components/CustomPhoneInput";

function CallTreeSetting(props: any): JSX.Element {
  const { phoneDetail, onChangeTextField, onCheckField, errors, preRecordedVoice1 } = props;

  const getMDPhoneInput = (label: any, name: any) => (
    <Grid item xs={12} sm={12} md={12} lg={12}>
      <MDInput
        label={label}
        name={name}
        required
        fullWidth
        variant="outlined"
        placeholder={label}
        InputProps={{
          inputComponent: CustomPhoneInput as any,
        }}
        value={phoneDetail?.[`${name}`] || ""}
        onChange={onChangeTextField}
        error={errors?.[`${name}`] || false}
        helperText={errors?.[`${name}`] || ""}
      />
    </Grid>
  );

  const getMDInput = (label: any, name: any) => (
    <Grid item xs={12} sm={12} md={12} lg={12}>
      <MDInput
        multiline
        rows="2"
        label={label}
        variant="outlined"
        name={name}
        fullWidth
        placeholder={label}
        value={phoneDetail?.[`${name}`] || ""}
        onChange={onChangeTextField}
        error={errors?.[`${name}`] || false}
        helperText={errors?.[`${name}`] || ""}
      />
    </Grid>
  );

  return (
    <Card id="call-tree-setting">
      <MDBox p={3} display="flex" justifyContent="space-between">
        <MDTypography variant="h5" className="page-header-label">
          Call Tree Setting
        </MDTypography>
      </MDBox>
      <MDBox component="form" pb={3} px={3}>
        <Card sx={{ mb: 1 }}>
          <MDBox pb={3} px={3}>
            <MDTypography variant="h6" className="page-header-label">
              1 - Salon Customers
            </MDTypography>
            <Grid container spacing={3} mt={1} mb={2}>
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <FormControlLabel
                  control={
                    <Switch
                      checked={phoneDetail?.use_pre_recorded_instructions || false}
                      name="use_pre_recorded_instructions"
                      onChange={onCheckField}
                      inputProps={{ "aria-label": "controlled" }}
                    />
                  }
                  label="Pre-recorded instructions"
                />
              </Grid>{" "}
              {!phoneDetail?.use_pre_recorded_instructions && (
                <>
                  {getMDPhoneInput("Forwarding Number 1", "forwarding_no_1")}
                  {getMDInput("Forwarding Notes 1", "forwarding_notes_1")}
                </>
              )}
              {preRecordedVoice1 && phoneDetail?.use_pre_recorded_instructions && (
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <audio controls>
                    <source src={preRecordedVoice1} />
                    <track src={preRecordedVoice1} kind="captions" label="english_captions" />
                    Your browser does not support the audio element.
                  </audio>
                </Grid>
              )}
              {!preRecordedVoice1 && phoneDetail?.use_pre_recorded_instructions && (
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <MDInput
                    label="Use Pre Recorded Instructions"
                    variant="outlined"
                    multiline
                    rows="3"
                    name="pre_recorded_instruction"
                    fullWidth
                    required
                    placeholder="Use Pre Recorded Instructions"
                    value={phoneDetail?.pre_recorded_instruction}
                    onChange={onChangeTextField}
                    error={errors?.pre_recorded_instruction || false}
                    helperText={errors?.pre_recorded_instruction || ""}
                  />
                </Grid>
              )}
            </Grid>
          </MDBox>
        </Card>

        <Card sx={{ mb: 1 }}>
          <MDBox pb={3} px={3}>
            <MDTypography variant="h6" className="page-header-label">
              2 - Lease Information
            </MDTypography>
            <Grid container spacing={3} mt={1} mb={2}>
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <FormControlLabel
                  control={
                    <Switch
                      checked={phoneDetail?.use_pre_recorded_instructions_2 || false}
                      name="use_pre_recorded_instructions_2"
                      onChange={onCheckField}
                      inputProps={{ "aria-label": "controlled" }}
                    />
                  }
                  label="Pre-recorded instructions"
                />
              </Grid>{" "}
              {!phoneDetail?.use_pre_recorded_instructions_2 && (
                <>
                  {getMDPhoneInput("Forwarding Number 2", "forwarding_no_2")}
                  {getMDInput("Forwarding Notes 2", "forwarding_notes_2")}
                </>
              )}
              {phoneDetail?.use_pre_recorded_instructions_2 && (
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <MDInput
                    label="Use Pre Recorded Instructions"
                    variant="outlined"
                    multiline
                    rows="3"
                    name="pre_recorded_instruction_2"
                    fullWidth
                    required
                    placeholder="Use Pre Recorded Instructions"
                    value={phoneDetail?.pre_recorded_instruction_2}
                    onChange={onChangeTextField}
                    error={errors?.pre_recorded_instruction_2 || false}
                    helperText={errors?.pre_recorded_instruction_2 || ""}
                  />
                </Grid>
              )}
            </Grid>
          </MDBox>
        </Card>

        <Card sx={{ mb: 1 }}>
          <MDBox pb={3} px={3}>
            <MDTypography variant="h6" className="page-header-label">
              3 - Existing Sola Pros
            </MDTypography>
            <Grid container spacing={3} mt={1} mb={2}>
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <FormControlLabel
                  control={
                    <Switch
                      checked={phoneDetail?.use_pre_recorded_instructions_3 || false}
                      name="use_pre_recorded_instructions_3"
                      onChange={onCheckField}
                      inputProps={{ "aria-label": "controlled" }}
                    />
                  }
                  label="Pre-recorded instructions"
                />
              </Grid>{" "}
              {!phoneDetail?.use_pre_recorded_instructions_3 && (
                <>
                  {getMDPhoneInput("Forwarding Number 3", "forwarding_no_3")}
                  {getMDInput("Forwarding Notes 3", "forwarding_notes_3")}
                </>
              )}
              {phoneDetail?.use_pre_recorded_instructions_3 && (
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <MDInput
                    label="Use Pre Recorded Instructions"
                    variant="outlined"
                    multiline
                    rows="3"
                    name="pre_recorded_instruction_3"
                    fullWidth
                    required
                    placeholder="Use Pre Recorded Instructions"
                    value={phoneDetail?.pre_recorded_instruction_3}
                    onChange={onChangeTextField}
                    error={errors?.pre_recorded_instruction_3 || false}
                    helperText={errors?.pre_recorded_instruction_3 || ""}
                  />
                </Grid>
              )}
            </Grid>
          </MDBox>
        </Card>

        <Card sx={{ mb: 1 }}>
          <MDBox pb={3} px={3}>
            <MDTypography variant="h6" className="page-header-label">
              4 - Other
            </MDTypography>
            <Grid container spacing={3} mt={1} mb={2}>
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <FormControlLabel
                  control={
                    <Switch
                      checked={phoneDetail?.use_pre_recorded_instructions_4 || false}
                      name="use_pre_recorded_instructions_4"
                      onChange={onCheckField}
                      inputProps={{ "aria-label": "controlled" }}
                    />
                  }
                  label="Pre-recorded instructions"
                />
              </Grid>{" "}
              {!phoneDetail?.use_pre_recorded_instructions_4 && (
                <>
                  {getMDPhoneInput("Forwarding Number 4", "forwarding_no_4")}
                  {getMDInput("Forwarding Notes 4", "forwarding_notes_4")}
                </>
              )}
              {phoneDetail?.use_pre_recorded_instructions_4 && (
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <MDInput
                    label="Use Pre Recorded Instructions"
                    variant="outlined"
                    multiline
                    rows="3"
                    name="pre_recorded_instruction_4"
                    fullWidth
                    required
                    placeholder="Use Pre Recorded Instructions"
                    value={phoneDetail?.pre_recorded_instruction_4}
                    onChange={onChangeTextField}
                    error={errors?.pre_recorded_instruction_4 || false}
                    helperText={errors?.pre_recorded_instruction_4 || ""}
                  />
                </Grid>
              )}
            </Grid>
          </MDBox>
        </Card>
      </MDBox>
    </Card>
  );
}

export default CallTreeSetting;
