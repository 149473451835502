// import axios from "axios";
// import config from "config/config";
import { useAppDispatch, useAppSelector } from "store/store";
// import { updateLeaseStatus } from "store/thunk/leaseThunk";

import { useEffect, useState } from "react";
// import MDDialog from "components/MDDialog";
// import MDTypography from "components/MDTypography";
import { useNavigate } from "react-router-dom";
import { Grid } from "@mui/material";
import MDBox from "components/MDBox";
import MDDialog from "components/MDDialog";
import moment from "moment";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { StaticDatePicker } from "@mui/x-date-pickers/StaticDatePicker";
import CryptoJS from "crypto-js";
import MDInput from "components/MDInput";
import { getUser, saveSchedules } from "store/thunk/authThunk";
import MDTypography from "components/MDTypography";

function Index(): JSX.Element {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { userData } = useAppSelector((state) => state.authReducer);
  const search = new URLSearchParams(window.location.search);
  const data: any = search.get("data") || "";
  const decData = CryptoJS.enc.Base64.parse(data).toString(CryptoJS.enc.Utf8);
  const bytes = CryptoJS.AES.decrypt(decData, "123456").toString(CryptoJS.enc.Utf8);
  const originalData: any = JSON.parse(bytes);
  const [schedule, setSchedule] = useState<boolean>(true);
  const [thanksPopup, setThanksPopup] = useState<boolean>(false);
  const [value, setValue] = useState<any>(null);
  const [time, setTime] = useState<any>(null);
  const [timeSlot, setTimeSlot] = useState<any>([]);
  const [eventLength] = useState<any>(30);
  const [user, setUser] = useState<any>({});

  useEffect(() => {
    if (originalData && originalData.userId) {
      dispatch(getUser({ user_id: originalData.userId, fetch_all_data: false }));
    }
  }, []);

  useEffect(() => {
    setUser(userData);
  }, [userData]);

  useEffect(() => {
    if (value) {
      setTimeSlot([]);
      const date = moment(value.$d).format("YYYY-MM-DD");
      const day = moment(date).date();
      const currentDay = moment().date();
      // console.log("offset ", moment().utcOffset() + 210);
      // console.log(moment(240).format("HH:mm"));
      /* const getTimeFromMins = (mins: any) => {
        if (mins >= 24 * 60 || mins < 0) {
          throw new RangeError(
            "Valid input should be greater than or equal to 0 and less than 1440."
          );
        }
        const h = mins / 60 || 0;
        const m = mins % 60 || 0;
        return moment.utc().hours(h).minutes(m).format("HH:mm");
      }; */
      const getTimeStops = (start: any, end: any, interval: number) => {
        const startTime = moment(start, "HH:mm");
        const endTime = moment(end, "HH:mm");

        if (endTime.isBefore(startTime)) {
          endTime.add(1, "day");
        }

        const timeStops: any = [];

        while (startTime <= endTime) {
          if (!timeStops.includes(moment(startTime).format("HH:mm"))) {
            timeStops.push(moment(startTime).format("HH:mm"));
          }
          startTime.add(interval, "minutes");
        }
        return timeStops;
      };

      // axios
      //   .get(
      //     `https://api.cal.dev/v1/availability?userId=164&dateFrom=${date}&dateTo=${date}&apiKey=cal_test_3357c6bc380bf52d7ccbf772cf1fd070`
      //   )
      //   .then((res: any) => {
      //     console.log("res ", res);
      //     res.data.workingHours.map((val: any) => {
      //       if (val.days.includes(day)) {
      //         const st = getTimeFromMins(val.startTime + moment().utcOffset());
      //         const ed = getTimeFromMins(val.endTime + moment().utcOffset());
      //         timeSlots = getTimeStops(st, ed, 10);
      //       }
      //       return true;
      //     });
      //     setTimeSlot(timeSlots);
      //   });
      /* const m = moment();
      const roundUp =
        m.second() || m.millisecond() ? m.add(5, "minute").startOf("minute") : m.startOf("minute"); */
      const min = (Math.round(moment().minutes() / 15) * 15) % 60;
      const todayStart =
        currentDay === day
          ? moment().set("minute", min).format("HH:mm")
          : moment(value.$d).format("HH:mm");
      const todayEnd = moment(value.$d).endOf("day").format("HH:mm");

      const timeStops = getTimeStops(todayStart, todayEnd, 10);
      setTimeSlot(timeStops);
    }
  }, [value]);

  const scheduleDialogProps = {
    open: schedule,
    saveBtn: false,
    disabledBtn: true,
    onClose: () => {
      setSchedule(false);
      setValue(null);
      navigate(`/crm/sola-pro-leads`);
    },
    onSave: () => {
      const date = moment(value.$d).format("YYYY-MM-DD");
      const startTime = moment(`${date} ${time}`).utc().toISOString();
      const endTime = moment(`${date} ${time}`).add(30, "minutes").utc().toISOString();
      dispatch(
        saveSchedules({
          connectDealId: originalData.leadId,
          startTime,
          endTime,
          fromEmail: userData.login_email,
          setSchedule,
          setThanksPopup,
          navigate,
        })
      );
    },
    title: "Schedule",
    size: "md",
    overflowVisible: "overflowVisible",
  };

  const thanksDialogProps = {
    open: thanksPopup,
    saveBtn: false,
    closeBtnText: "Ok",
    closeBtnColor: "info",
    onClose: () => {
      setThanksPopup(false);
    },
    title: "Thanks for Schedule Meeting",
    size: "sm",
  };

  const renderTimeSlots = () => {
    const slots: any = [];
    if (timeSlot && timeSlot.length) {
      timeSlot.map((val: any) =>
        slots.push(
          <MDBox
            className={`time ${val === time && "active"}`}
            onClick={(e: any) => {
              setTime(e.target.innerText);
            }}
          >
            {val}
          </MDBox>
        )
      );
    }
    return slots;
  };

  return (
    <MDBox>
      <MDDialog {...scheduleDialogProps}>
        <Grid container className="appointment-container">
          <Grid item md={3} className="appointment-section">
            <MDTypography>{`${user?.first_name} ${user?.last_name}`}</MDTypography>
            <MDTypography variant="body2">{user?.login_email}</MDTypography>
            <MDTypography variant="body2">{eventLength} minutes</MDTypography>
          </Grid>
          <Grid item md={6} className="appointment-section">
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <StaticDatePicker
                displayStaticWrapperAs="desktop"
                orientation="landscape"
                openTo="day"
                value={value}
                minDate={new Date()}
                onChange={(newValue: any) => {
                  setValue(newValue);
                }}
                renderInput={(params) => <MDInput {...params} />}
              />
            </LocalizationProvider>
          </Grid>
          <Grid item md={3} className="appointment-section">
            <Grid item md={12} sx={{ textAlign: "left", marginLeft: "15px" }}>
              {value && moment(value.$d).format("ddd, MMM DD")}
            </Grid>
            <Grid item md={12} className="time-slots">
              {renderTimeSlots()}
            </Grid>
          </Grid>
        </Grid>
      </MDDialog>
      <MDDialog {...thanksDialogProps}>
        <Grid container>
          <MDTypography>Thank You</MDTypography>
        </Grid>
      </MDDialog>
    </MDBox>
  );
}

export default Index;
