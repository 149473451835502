import React, { useState } from "react";
import MDDialog from "components/MDDialog";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
// import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Accordion, AccordionDetails, AccordionSummary, Grid, Link, Tooltip } from "@mui/material";
import ImagePreview from "layouts/pages/ImagePreview";
import MDButton from "components/MDButton";
import { getLocalDateTime } from "helper/services";

function ViewDetails(props: any): JSX.Element {
  const { open, onClose, updateData } = props;
  const [isPreview, setImagePreviewPopup] = useState(false);
  const [imageToPreview, setImageToPreview] = useState("");

  const dialogProps = {
    open,
    onClose,
    title: "View Product Information",
    size: "md",
    saveBtn: false,
  };

  const previewImage = (image: any) => {
    setImagePreviewPopup(true);
    setImageToPreview(image);
  };

  const closeImagePreview = () => {
    setImagePreviewPopup(false);
    setImageToPreview("");
  };

  return (
    <MDDialog {...dialogProps}>
      <MDBox pb={2}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12} md={12}>
            <Accordion expanded>
              <AccordionSummary
                className="location-accordion"
                // expandIcon={<ExpandMoreIcon />}
                aria-controls="publishbh-content"
              >
                <MDTypography variant="h6">Basic info</MDTypography>
              </AccordionSummary>
              <AccordionDetails>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6} md={6}>
                    <MDTypography fontWeight="bold" variant="button">
                      Title
                    </MDTypography>
                    <MDBox>
                      <MDTypography variant="button">{updateData.title || "-"}</MDTypography>
                    </MDBox>
                  </Grid>
                  <Grid item xs={12} sm={6} md={6}>
                    <MDTypography fontWeight="bold" variant="button">
                      Description
                    </MDTypography>
                    <MDBox>
                      <MDTypography variant="button">{updateData.description || "-"}</MDTypography>
                    </MDBox>
                  </Grid>
                  <Grid item xs={12} sm={6} md={6}>
                    <MDTypography fontWeight="bold" variant="button">
                      Link Url
                    </MDTypography>
                    <MDBox>
                      <MDTypography variant="button">{updateData.link_url || "-"}</MDTypography>
                    </MDBox>
                  </Grid>
                  <Grid item xs={12} sm={6} md={6}>
                    <MDTypography fontWeight="bold" variant="button">
                      Brand
                    </MDTypography>
                    <MDBox>
                      <MDTypography variant="button">{updateData.brand_name || "-"}</MDTypography>
                    </MDBox>
                  </Grid>
                  <Grid item xs={12} sm={6} md={6}>
                    <MDTypography fontWeight="bold" variant="button">
                      File
                    </MDTypography>
                    <MDBox>
                      <MDTypography variant="button">
                        {!updateData.file_url && "-"}
                        {updateData?.file_url && (
                          <Link
                            rel="noopener noreferrer"
                            variant="subtitle2"
                            href={updateData?.file_url}
                            target="__blank"
                          >
                            <Tooltip title="Click to View File" placement="top">
                              <MDButton>{updateData?.file_file_name}</MDButton>
                            </Tooltip>
                          </Link>
                        )}
                      </MDTypography>
                    </MDBox>
                  </Grid>
                  <Grid item xs={12} sm={12} md={12}>
                    <MDTypography fontWeight="bold" variant="button">
                      Image
                    </MDTypography>
                    <MDBox>
                      {updateData?.image_url ? (
                        <Grid
                          container
                          onClick={() => previewImage(updateData?.image_url)}
                          justifyContent="center"
                          alignItems="center"
                          textAlign="center"
                          className="thumbnail-img"
                        >
                          <img width="100%" src={updateData?.image_url} alt="original" />
                        </Grid>
                      ) : (
                        "-"
                      )}
                    </MDBox>
                  </Grid>
                  <Grid item xs={12} sm={6} md={6}>
                    <MDTypography fontWeight="bold" variant="button">
                      Created At
                    </MDTypography>
                    <MDBox>
                      <MDTypography variant="button">
                        {getLocalDateTime(updateData.created_at)}
                      </MDTypography>
                    </MDBox>
                  </Grid>
                  <Grid item xs={12} sm={6} md={6}>
                    <MDTypography fontWeight="bold" variant="button">
                      Updated At
                    </MDTypography>
                    <MDBox>
                      <MDTypography variant="button">
                        {getLocalDateTime(updateData.updated_at)}
                      </MDTypography>
                    </MDBox>
                  </Grid>
                </Grid>
              </AccordionDetails>
            </Accordion>
          </Grid>
        </Grid>
      </MDBox>
      <ImagePreview
        imageData={imageToPreview}
        open={isPreview}
        onCloseImage={() => {
          closeImagePreview();
        }}
      />
    </MDDialog>
  );
}

export default ViewDetails;
