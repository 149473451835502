import React, { useEffect, useState } from "react";
import MDDialog from "components/MDDialog";
import { Grid } from "@mui/material";
import MDInput from "components/MDInput";
import { useAppDispatch, useAppSelector } from "store/store";
import { createRMContact, getRMContactDetailById } from "store/thunk/repairMaintenance";
import Validations from "helper/validations";
import CustomAutoSearch from "components/CustomAutoSearch";
import {
  addPlusOneInPhoneNumber,
  adminSuperAdmin,
  getAllAssociationLocations,
  getPermission,
  removePlusOneInPhoneNumber,
} from "helper/services";
import CustomErrorMessage from "components/CustomErrorMessage";
import { setRMContactDetail } from "store/slices/repairMaintenanceSlice";

function AddUpdate(props: any): JSX.Element {
  const dispatch = useAppDispatch();
  const { rmContactDetail } = useAppSelector((state: any) => state.repairMaintenanceSlice);
  const { open, onClose, editId, pageNo, perPage, search, sortBy } = props;
  const [loading] = useState<boolean>(false);
  const [contactData, setContactDetail] = useState<any>({});
  const [errors, setErrors] = useState<any>({});

  useEffect(() => {
    if (editId) {
      dispatch(getRMContactDetailById({ id: editId }));
    }
    // setContactDetail({ ...contactData, selectedLocations: [] });
  }, []);

  useEffect(() => {
    if (editId && rmContactDetail && Object.keys(rmContactDetail).length) {
      setContactDetail({
        id: (editId && rmContactDetail?.id) || null,
        f_name: rmContactDetail?.f_name || "",
        l_name: rmContactDetail?.l_name || "",
        m_name: rmContactDetail?.m_name || "",
        email: rmContactDetail?.email || "",
        phone: removePlusOneInPhoneNumber(rmContactDetail.phone || ""),
        title: rmContactDetail?.title || "",
        description: rmContactDetail?.description || "",
        address_1: rmContactDetail?.address_1 || "",
        address_2: rmContactDetail?.address_2 || "",
        state: rmContactDetail?.state || "",
        city: rmContactDetail?.city || "",
        postal_code: rmContactDetail?.postal_code || "",
        selectedLocations: rmContactDetail?.locations || null,
      });
    }
  }, [rmContactDetail]);

  const onClosePopup = () => {
    onClose();
    dispatch(setRMContactDetail(null));
  };
  const onSave = () => {
    const tmpContactData = JSON.parse(JSON.stringify(contactData));
    const error = Validations.validateRMContactForm(tmpContactData);
    setErrors(error);
    if (!Object.keys(error).length) {
      tmpContactData.location_ids = tmpContactData?.selectedLocations.map((obj: any) => obj.id);
      tmpContactData.phone = addPlusOneInPhoneNumber(tmpContactData.phone);
      const req = {
        tmpContactData,
        onClose: onClosePopup,
        pageNo,
        perPage,
        search,
        sortBy,
      };
      dispatch(createRMContact(req));
    }
  };

  const onChangeField = (e: any, field: string) => {
    setContactDetail((prev: any) => ({
      ...prev,
      [field]: e.target.value,
    }));
  };
  const onChangeAutoComplete = (name: string, value: any) => {
    setContactDetail({ ...contactData, [name]: value });
    // setContactDetail({ ...contactData, location_ids: value.map((obj: any) => obj.id) });
  };

  const dialogProps = {
    open,
    onClose: onClosePopup,
    onSave,
    saveTbtText: editId ? "Update" : "Save",
    title: editId ? "Update Repair & Maintenance Contact" : "Add Repair & Maintenance Contact",
    size: "lg",
    loading,
  };

  return (
    <MDDialog {...dialogProps}>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12} md={6} lg={6}>
          <MDInput
            label="First name"
            variant="standard"
            fullWidth
            required
            sx={{ margin: "5px 0px" }}
            name="f_name"
            placeholder=""
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => onChangeField(e, "f_name")}
            value={contactData?.f_name || ""}
            error={errors && errors.f_name ? true : false}
            helperText={errors && errors.f_name}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6}>
          <MDInput
            label="Last name"
            variant="standard"
            fullWidth
            sx={{ margin: "5px 0px" }}
            name="l_name"
            placeholder=""
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => onChangeField(e, "l_name")}
            value={contactData?.l_name || ""}
            error={errors && errors.l_name ? true : false}
            helperText={errors && errors.l_name}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6}>
          <MDInput
            label="Middle name"
            variant="standard"
            fullWidth
            sx={{ margin: "5px 0px" }}
            name="m_name"
            placeholder=""
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => onChangeField(e, "m_name")}
            value={contactData?.m_name || ""}
            error={errors && errors.m_name ? true : false}
            helperText={errors && errors.m_name}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6}>
          <MDInput
            label="Phone"
            variant="standard"
            fullWidth
            sx={{ margin: "5px 0px" }}
            name="phone"
            placeholder=""
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => onChangeField(e, "phone")}
            value={contactData?.phone || ""}
            error={errors && errors.phone ? true : false}
            helperText={errors && errors.phone}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6}>
          <MDInput
            label="Email"
            variant="standard"
            fullWidth
            sx={{ margin: "5px 0px" }}
            name="email"
            placeholder=""
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => onChangeField(e, "email")}
            value={contactData?.email || ""}
            error={errors && errors.email ? true : false}
            helperText={errors && errors.email}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6}>
          <MDInput
            label="Title"
            variant="standard"
            fullWidth
            sx={{ margin: "5px 0px" }}
            name="title"
            placeholder=""
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => onChangeField(e, "title")}
            value={contactData?.title || ""}
            error={errors && errors.title ? true : false}
            helperText={errors && errors.title}
          />
        </Grid>

        <Grid item xs={12} sm={12} md={12} lg={12}>
          <MDInput
            multiline
            rows={3}
            label="Description"
            name="description"
            className="msg-input-box"
            sx={{ width: "100%", height: "100%" }}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => onChangeField(e, "description")}
            value={contactData?.description || ""}
            error={errors && errors.description ? true : false}
            helperText={errors && errors.description}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6}>
          <MDInput
            label="Address 1"
            variant="standard"
            fullWidth
            sx={{ margin: "5px 0px" }}
            name="address_1"
            placeholder=""
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => onChangeField(e, "address_1")}
            value={contactData?.address_1 || ""}
            error={errors && errors.address_1 ? true : false}
            helperText={errors && errors.address_1}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6}>
          <MDInput
            label="Address 2"
            variant="standard"
            fullWidth
            sx={{ margin: "5px 0px" }}
            name="address_2"
            placeholder=""
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => onChangeField(e, "address_2")}
            value={contactData?.address_2 || ""}
            error={errors && errors.address_2 ? true : false}
            helperText={errors && errors.address_2}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6}>
          <MDInput
            label="City"
            variant="standard"
            fullWidth
            sx={{ margin: "5px 0px" }}
            name="city"
            placeholder=""
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => onChangeField(e, "city")}
            value={contactData?.city || ""}
            error={errors && errors.city ? true : false}
            helperText={errors && errors.city}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6}>
          <MDInput
            label="State"
            variant="standard"
            fullWidth
            sx={{ margin: "5px 0px" }}
            name="state"
            placeholder=""
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => onChangeField(e, "state")}
            value={contactData?.state || ""}
            error={errors && errors.state ? true : false}
            helperText={errors && errors.state}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6}>
          <MDInput
            label="Postal code"
            variant="standard"
            fullWidth
            sx={{ margin: "5px 0px" }}
            name="postal_code"
            placeholder=""
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => onChangeField(e, "postal_code")}
            value={contactData?.postal_code || ""}
            error={errors && errors.postal_code ? true : false}
            helperText={errors && errors.postal_code}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6}>
          <CustomAutoSearch
            idKey="id"
            multiple
            nameKey="name"
            fieldLabel="Location"
            apiName="locations"
            responseKey="locations"
            name="selectedLocations"
            value={contactData?.selectedLocations || []}
            onChange={onChangeAutoComplete}
            usersData={getAllAssociationLocations()}
            createNewLink={
              adminSuperAdmin() && getPermission("sola_cms", "sola_cms_locations")
                ? "/sola-cms/cms-locations/create-location"
                : ""
            }
          />
          {errors && errors.selectedLocations && (
            <CustomErrorMessage message={errors.selectedLocations} />
          )}
        </Grid>
      </Grid>
    </MDDialog>
  );
}

export default AddUpdate;
