import { useEffect, useCallback, useState, useRef } from "react";
import { Card, Icon, Switch, Typography } from "@mui/material";
import MDButton from "components/MDButton";
import MDBox from "components/MDBox";
import MDDialog from "components/MDDialog";
import DataTable from "examples/Tables/DataTable";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import { cognitoForgotPassword, updateUserAttributes } from "store/thunk/authThunk";
import { ForgotPasswordRequest, UpdateUserAttrRequest } from "types/custom-types";
import { useAppDispatch, useAppSelector } from "store/store";
import { getConnectUsers } from "store/thunk/connectUserThunk";
import { getLocalDateTime, phoneNumberformat, adminSuperAdmin, getAuthUser } from "helper/services";
import exportData from "helper/exportTableConst";
import MDIconButton from "components/MDIconButton";
import AddUpdate from "./addUpdate";
import ResetPassword from "./resetPassword";

function ConnectUsers(): JSX.Element {
  const dispatch = useAppDispatch();
  const loginUserData = getAuthUser();
  const { connectUsers } = useAppSelector((state) => state.connectUsersReducer);
  const [userPopup, setUserPopup] = useState(false);
  const [editUser, setEditUser] = useState(null);
  const [deleteUser, setDeleteUser] = useState<any>([]);
  const [deletePopup, setDeletePopup] = useState(false);
  const [resetPassword, setresetPassword] = useState(null);
  const [resetPasswordPopup, setResetPasswordPopup] = useState(false);
  const [usersRows, setUsersRows] = useState<any>([]);
  const [deletedUserMsg, setDeletedUserMsg] = useState<any>("");
  const [pageCount, setPageCount] = useState(0);
  const [pageNo, setPageNo] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [totalCount, setTotalCount] = useState(0);
  const [search, setSearch] = useState<any>(null);
  const [sortBy, setSortBy] = useState<any>([]);
  const fetchIdRef = useRef(0);
  const [locationAccessDetails, setLocationAccessDetails] = useState<any>([]);
  const [crmAccessDetails, setCRMAccessDetails] = useState<any>([]);
  const [accessObject, setAccessObject] = useState<any>([]);

  useEffect(() => {
    if (loginUserData && loginUserData?.access && loginUserData?.access?.length) {
      const getLocationAccessData = loginUserData?.access.find(
        (obj: any) => obj.alias === "locations"
      );
      const defaultLocationAccess: any = [];
      if (getLocationAccessData) {
        const getChildMenuAccess: any = getLocationAccessData?.child_menu?.find(
          (newObj: any) => newObj.alias === "locations_location"
        );
        setLocationAccessDetails(getChildMenuAccess?.page_sections || []);
        getChildMenuAccess?.page_sections?.map((obj: any) => {
          const defaultAccess = {
            none_access: false,
            add_access: false,
            edit_access: false,
            view_access: true,
            menu_id: Number(obj?.menu_id),
          };
          defaultLocationAccess.push(defaultAccess);
          return true;
        });
      }

      const getCRMAccessData: any = loginUserData?.access?.find((obj: any) => obj.alias === "crm");
      if (getCRMAccessData) {
        setCRMAccessDetails(getCRMAccessData?.child_menu || []);
        getCRMAccessData?.child_menu?.map((obj: any) => {
          const defaultAccess = {
            none_access: false,
            add_access: false,
            edit_access: false,
            view_access: true,
            menu_id: Number(obj?.menu_id),
          };
          defaultLocationAccess.push(defaultAccess);
          return true;
        });
      }
      setAccessObject(defaultLocationAccess);
    }
  }, []);

  useEffect(() => {
    if (connectUsers) {
      setPageCount(connectUsers?.meta?.total_pages);
      setTotalCount(connectUsers?.meta?.total_count);
    }
  }, [connectUsers]);

  const fetchData = useCallback(({ pageSize, pageIndex, search, sortBy }) => {
    const fetchId = ++fetchIdRef.current;
    const sort: any = sortBy || [];
    // We'll even set a delay to simulate a server here
    setTimeout(() => {
      if (fetchId === fetchIdRef.current) {
        setPageNo(pageIndex);
        setSearch(search);
        setSortBy(sort);
        setPageSize(pageSize);
        dispatch(getConnectUsers({ pageSize, pageIndex, search, sort }));
      }
    }, 1000);
  }, []);

  // useEffect(() => {
  //   dispatch(getConnectUsers(null));
  // }, []);

  const editUserAction = (user: any) => {
    setEditUser(user);
    setUserPopup(true);
  };
  const deleteUserAction = (event: any, userData: any) => {
    setDeletePopup(true);
    setDeleteUser(userData);
    let dltmsg: string = "";
    if (userData?.deleted) {
      dltmsg = "Are you sure to restore user ";
    } else {
      dltmsg = "Are you sure to delete user ";
    }
    dltmsg += userData?.first_name || "";
    dltmsg += userData?.first_name && userData?.last_name ? " " : "";
    dltmsg += userData?.last_name || "";
    dltmsg += "?";
    setDeletedUserMsg(dltmsg);
  };

  const resetPasswordAction = (user: any) => {
    setresetPassword(user);
    setResetPasswordPopup(true);
  };

  const resetPasswordEmail = (user: any) => {
    const requestData: ForgotPasswordRequest = {
      email: user?.login_email,
    };
    dispatch(cognitoForgotPassword(requestData));
  };

  const onUserLockChange = (event: any) => {
    const requestData: any = {
      email: event.target.value,
      attributes: { locked: event.target.checked },
      pagination: {
        pageIndex: pageNo,
        pageSize,
        search,
        sort: sortBy,
      },
    };
    dispatch(updateUserAttributes(requestData));
  };

  useEffect(() => {
    const usersRows: any = [];
    if (connectUsers?.connect_users?.length) {
      connectUsers?.connect_users.map((user: any) => {
        const userData = {
          first_name: user.first_name || "-",
          last_name: user.last_name || "-",
          login_email: user.login_email || "-",
          sms_phone: phoneNumberformat(user.sms_phone),
          role_name: user.role_name ? user.role_name : "-",
          login_count: user.login_count ? user.login_count : "-",
          last_login: user.last_login ? getLocalDateTime(user.last_login) : "-",
          locked: (
            <Switch
              disabled={user.system_admin}
              checked={user.locked}
              value={user.login_email}
              onChange={(event: any) => onUserLockChange(event)}
              inputProps={{ "aria-label": "controlled" }}
            />
          ),
          deleted: (
            <Switch
              disabled={user.system_admin}
              checked={user.deleted}
              value={user.login_email}
              onChange={(event: any) => deleteUserAction(event, user)}
              inputProps={{ "aria-label": "controlled" }}
            />
          ),
          action: (
            <>
              <MDIconButton
                tooltipName="Reset password"
                aria-label="Send Email for reset password."
                onClick={() => resetPasswordAction(user)}
                color="info"
              >
                <Icon fontSize="small">key</Icon>
              </MDIconButton>
              <MDIconButton
                tooltipName="Reset email"
                aria-label="Send Email for reset password."
                onClick={() => resetPasswordEmail(user)}
                color="info"
              >
                <Icon fontSize="small">email</Icon>
              </MDIconButton>
              <MDIconButton
                tooltipName="Edit"
                aria-label="Edit"
                color="info"
                onClick={() => editUserAction(user)}
              >
                <Icon fontSize="small">edit</Icon>
              </MDIconButton>
            </>
          ),
        };
        usersRows.push(userData);

        return true;
      });
      setUsersRows(usersRows);
    }
  }, [connectUsers]);

  const tableData = {
    columns: [
      { Header: "First name", accessor: "first_name", export: true },
      { Header: "Last name", accessor: "last_name", export: true },
      { Header: "Email", accessor: "login_email", export: true },
      { Header: "Phone", accessor: "sms_phone", export: true },
      { Header: "Role name", accessor: "role_name", export: true },
      { Header: "Sign In Count", accessor: "login_count", export: true },
      { Header: "Last Sign In At", accessor: "last_login", export: true },
      { Header: "Locked", accessor: "locked", export: true },
      { Header: "Deleted", accessor: "deleted", export: true },
      { Header: "Action", accessor: "action", width: "200px", export: false, disableSortBy: true },
    ],
    rows: usersRows,
  };
  const confirmDeleteUser = () => {
    // delete user API
    const requestData: UpdateUserAttrRequest = {
      email: deleteUser.login_email,
      attributes: { deleted: !deleteUser.deleted },
      updatedByUserId: parseInt(loginUserData?.user_id, 10) || null,
      pagination: {
        pageIndex: pageNo,
        pageSize,
        search,
        sort: sortBy,
      },
    };

    dispatch(updateUserAttributes(requestData));
    setDeletePopup(false);
  };

  const dialogProps: any = {
    open: deletePopup,
    onClose: () => setDeletePopup(false),
    title: deleteUser?.deleted ? "Restore User" : "Delete User",
    saveTbtText: deleteUser?.deleted ? "Restore" : "Delete",
    deleteBtn: true,
    saveBtn: false,
    onSave: () => confirmDeleteUser(),
    size: "sm",
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={4} pb={3}>
        <Card>
          <MDBox
            bgColor="dark"
            color="white"
            coloredShadow="dark"
            borderRadius="xl"
            alignItems="center"
            justifyContent="space-between"
            pt={1}
            pb={1}
            pl={2}
            pr={2}
            ml={2}
            mr={2}
            display="flex"
            mt={-3}
            className="page-header"
          >
            <p className="page-header-label">All Users</p>
            {adminSuperAdmin() && (
              <MDButton
                variant="gradient"
                color="light"
                size="small"
                className="xs-small"
                onClick={() => setUserPopup(true)}
              >
                <Icon sx={{ fontWeight: "bold" }}>add</Icon> Add user
              </MDButton>
            )}
          </MDBox>
          <DataTable
            table={tableData}
            canSearch
            showExportAllBtn
            showExportSelectedBtn
            pageCount={pageCount}
            pageNo={pageNo}
            totalCount={totalCount}
            fetchData={fetchData}
            exportFields={exportData.connect_users.export_fields}
            exportTable={exportData.connect_users.table_name}
            exportType={exportData.connect_users.export_type}
            manualSearch
            manualSort
          />
          {resetPasswordPopup && (
            <ResetPassword
              open={resetPasswordPopup}
              onClose={() => {
                setResetPasswordPopup(false);
                setresetPassword(null);
              }}
              resetPassword={resetPassword}
            />
          )}
          {userPopup && (
            <AddUpdate
              open={userPopup}
              onClose={() => {
                setUserPopup(false);
                setEditUser(null);
              }}
              editUser={editUser}
              pageIndex={pageNo}
              pageSize={pageSize}
              search={search}
              sortBy={sortBy}
              locationAccessDetails={locationAccessDetails}
              crmAccessDetails={crmAccessDetails}
              accessObject={accessObject}
            />
          )}
        </Card>
      </MDBox>
      {deletePopup && (
        <MDDialog {...dialogProps}>
          <Typography>{deletedUserMsg}</Typography>
        </MDDialog>
      )}
    </DashboardLayout>
  );
}

export default ConnectUsers;
