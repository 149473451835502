import { Grid, Paper } from "@mui/material";
import MDBox from "components/MDBox";
import MDInput from "components/MDInput";
import MDTypography from "components/MDTypography";
// import Add from "@mui/icons-material/Add";
// import MDButton from "components/MDButton";
// import CloseIcon from "@mui/icons-material/Close";

function Testimonials(props: any): JSX.Element {
  const { websiteData, onChangeMultipleField } = props;

  return (
    <Paper>
      <MDBox p={2} mt={2}>
        {/* <MDBox mb={2}>
          <Grid container>
            <Grid item md={12} textAlign="right">
              <MDButton
                onClick={() => addNewElement("testimonials")}
                variant="outlined"
                color="dark"
                size="small"
                startIcon={<Add />}
              >
                Add
              </MDButton>
            </Grid>
          </Grid>
        </MDBox> */}
        <Grid container spacing={3} justifyContent="center">
          {[...Array(10)].map((_, index) => {
            const key = `testimonial_${index + 1}`;
            return (
              <Grid item md={6} container>
                <Paper sx={{ width: "100%", padding: "15px 10px" }} elevation={3}>
                  <Grid container>
                    <Grid item md={9}>
                      <MDTypography variant="body2" fontWeight="regular">
                        Testimonials #{index + 1}
                      </MDTypography>
                    </Grid>
                    {/* <Grid item md={3} textAlign="right">
                      <IconButton aria-label="delete" onClick={() => removeElement(index)}>
                        <CloseIcon />
                      </IconButton>
                    </Grid> */}
                  </Grid>
                  <Grid item md={12} container spacing={3}>
                    <Grid item md={6} sm={6} xs={12}>
                      <MDInput
                        label="Name"
                        variant="standard"
                        fullWidth
                        onChange={(e: any) => onChangeMultipleField(e, key)}
                        name="name"
                        placeholder="name"
                        value={
                          websiteData?.testimonials && websiteData.testimonials[key]
                            ? websiteData?.testimonials[key].name
                            : ""
                        }
                      />
                    </Grid>

                    <Grid item md={6} sm={6} xs={12}>
                      <MDInput
                        label="Region"
                        variant="standard"
                        fullWidth
                        name="region"
                        onChange={(e: any) => onChangeMultipleField(e, index)}
                        placeholder="Region"
                        value={
                          websiteData?.testimonials && websiteData.testimonials[key]
                            ? websiteData?.testimonials[key].region
                            : ""
                        }
                      />
                    </Grid>
                    <Grid item md={12} xs={12}>
                      <MDInput
                        label="Text"
                        fullWidth
                        multiline
                        rows={2}
                        InputLabelProps={{ shrink: true }}
                        variant="standard"
                        name="text"
                        onChange={(e: any) => onChangeMultipleField(e, index)}
                        value={
                          websiteData?.testimonials && websiteData.testimonials[key]
                            ? websiteData?.testimonials[key].text
                            : ""
                        }
                      />
                    </Grid>
                  </Grid>
                </Paper>
              </Grid>
            );
          })}
        </Grid>
      </MDBox>
    </Paper>
  );
}

export default Testimonials;
