import Grid from "@mui/material/Grid";
import MenuItem from "@mui/material/MenuItem";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import FormControl from "@mui/material/FormControl";
import Card from "@mui/material/Card";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import { getMUISelectValue } from "helper/services";
import MESSAGES from "helper/messages";
import InputAdornment from "@mui/material/InputAdornment";
import InfoIcon from "@mui/icons-material/Info";
import Tooltip from "@mui/material/Tooltip";

function RentManager(props: any): JSX.Element {
  const { locationDetail, onChangeField, errors } = props;
  return (
    <Card id="rent-manager">
      <MDBox p={3}>
        <MDTypography variant="h5">Rent Manager</MDTypography>
      </MDBox>
      <MDBox pb={3} px={3}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6} md={6} lg={6}>
            <MDInput
              label="Rent Manager Property ID"
              variant="standard"
              fullWidth
              name="rent_manager_property_id"
              placeholder="Rent Manager Property ID"
              onChange={onChangeField}
              value={locationDetail?.rent_manager_property_id || ""}
              error={errors && errors.rent_manager_property_id ? true : false}
              helperText={errors && errors.rent_manager_property_id}
              infoText={MESSAGES.INFO.OPTIONAL_LENGTH_255}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={6} lg={6}>
            <MDInput
              label="Rent Manager Location ID"
              variant="standard"
              fullWidth
              name="rent_manager_location_id"
              placeholder="Rent Manager Location ID"
              onChange={onChangeField}
              value={locationDetail?.rent_manager_location_id || ""}
              error={errors && errors.rent_manager_location_id ? true : false}
              helperText={errors && errors.rent_manager_location_id}
              infoText={MESSAGES.INFO.OPTIONAL_LENGTH_255}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={6} lg={6}>
            <FormControl fullWidth>
              <InputLabel>Service Request Enabled</InputLabel>
              <Select
                className="height45"
                label="Service Request Enabled"
                value={getMUISelectValue(locationDetail?.service_request_enabled)}
                name="service_request_enabled"
                onChange={onChangeField}
                endAdornment={
                  <InputAdornment position="end">
                    <Tooltip title={MESSAGES.INFO.SERVICE_REQUEST_ENABLED} arrow>
                      <InfoIcon
                        style={{
                          width: 20,
                          height: 20,
                          cursor: "pointer",
                          color: "rgba(48, 57, 65, 0.3)",
                          marginRight: "5px",
                        }}
                      />
                    </Tooltip>
                  </InputAdornment>
                }
              >
                <MenuItem value="true">Yes</MenuItem>
                <MenuItem value="false">No</MenuItem>
              </Select>
            </FormControl>
          </Grid>
        </Grid>
      </MDBox>
    </Card>
  );
}

export default RentManager;
