import { useCallback, useEffect, useRef, useState } from "react";
import { Card, Icon } from "@mui/material";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import DataTable from "examples/Tables/DataTable";
import { useAppDispatch, useAppSelector } from "store/store";
import Messages from "helper/messages";
import MDDialog from "components/MDDialog";
import MDTypography from "components/MDTypography";
import MDIconButton from "components/MDIconButton";
import { deleteAmenities, getAmenities } from "store/thunk/systemAdmin";
import AddUpdate from "./addUpdate";

function Amenities(): JSX.Element {
  const dispatch = useAppDispatch();
  const { amenitiesList } = useAppSelector((state) => state.systemAdminSlice);
  // const [selectedItems, setSelectedItems] = useState([]);
  const [amenityList, setAmenitiesList] = useState([]);
  const [addUpdatePopup, setAddUpdatePopup] = useState(false);
  const [deleteAmenityId, setDeleteAmenity] = useState<any>(false);
  const [pageCount, setPageCount] = useState(0);
  const [pageNo, setPageNo] = useState(0);
  const [perPage, setPageSize] = useState(50);
  const [totalCount, setTotalCount] = useState(0);
  const [search, setSearch] = useState<any>(null);
  const [sortBy, setSortBy] = useState<any>([]);
  const [updateData, setUpdateData] = useState<any>(null);
  const fetchIdRef = useRef(0);

  useEffect(() => {
    if (amenitiesList && amenitiesList?.amenities) {
      setAmenitiesList(amenitiesList?.amenities);
      setPageCount(amenitiesList?.meta?.total_pages);
      setTotalCount(amenitiesList?.meta?.total_count);
    }
  }, [amenitiesList]);

  const fetchData = useCallback(({ pageSize, pageIndex, search, sortBy }) => {
    const fetchId = ++fetchIdRef.current;
    const sort: any = sortBy || [];
    // We'll even set a delay to simulate a server here
    setTimeout(() => {
      if (fetchId === fetchIdRef.current) {
        setPageNo(pageIndex);
        setSearch(search);
        setSortBy(sort);
        setPageSize(pageSize);
        dispatch(getAmenities({ pageSize, pageIndex, search, sort }));
      }
    }, 1000);
  }, []);

  const onEditFaq = (object: any) => {
    setUpdateData(object);
    setAddUpdatePopup(true);
  };

  const actionButtons = (object: any) => (
    <>
      <MDIconButton
        tooltipName="Edit"
        aria-label="Edit"
        color="info"
        onClick={() => onEditFaq(object)}
      >
        <Icon fontSize="small">edit</Icon>
      </MDIconButton>
      <MDIconButton
        tooltipName="Delete"
        aria-label="Delete"
        color="error"
        onClick={() => setDeleteAmenity(object.id)}
      >
        <Icon fontSize="small">delete</Icon>
      </MDIconButton>
    </>
  );

  const getRows = () => {
    const data: any = amenityList.map((obj: any) => ({
      id: obj?.id,
      headline: obj?.headline ? obj?.headline : "-",
      description: obj?.description ? obj?.description : "-",
      icon: <Icon fontSize="medium">{obj?.icon ? obj?.icon : "-"}</Icon>,
      amenity_type: !obj?.amenity_type ? "Variable" : "Fixed",
      action: actionButtons(obj),
    }));
    return data;
  };

  const tableData = {
    columns: [
      { Header: "Id", accessor: "id", export: true },
      { Header: "Headline", accessor: "headline", export: true },
      { Header: "Description", accessor: "description", export: true },
      { Header: "Icon", accessor: "icon", export: true },
      { Header: "Amenity Type", accessor: "amenity_type", export: true },
      { Header: "Action", accessor: "action", export: false, disableSortBy: true, width: "200px" },
    ],
    rows: getRows(),
  };

  const onDelete = () => {
    if (deleteAmenityId) {
      const delReq = {
        id: deleteAmenityId,
        onClose: () => setDeleteAmenity(false),
        setDeleteAmenity,
        pageNo,
        perPage,
        search,
        sortBy,
      };
      dispatch(deleteAmenities(delReq));
    }
  };

  const deleteDialogProps = {
    open: deleteAmenities,
    onClose: () => setDeleteAmenity(false),
    onSave: () => onDelete(),
    title: "Delete Amenities",
    size: "sm",
    saveBtn: false,
    deleteBtn: true,
    saveTbtText: "Yes",
    closeBtnText: "No",
  };

  const closeModal = () => {
    setUpdateData(null);
    setAddUpdatePopup(false);
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={4} pb={3}>
        <Card>
          <MDBox
            bgColor="dark"
            color="white"
            coloredShadow="dark"
            borderRadius="xl"
            alignItems="center"
            justifyContent="space-between"
            pt={1}
            pb={1}
            pl={2}
            pr={2}
            ml={2}
            mr={2}
            display="flex"
            mt={-3}
            className="page-header"
          >
            <p className="page-header-label">Amenities</p>
            <MDButton
              variant="gradient"
              color="light"
              size="small"
              className="xs-small"
              onClick={() => {
                setAddUpdatePopup(true);
              }}
            >
              <Icon sx={{ fontWeight: "bold" }}>add</Icon> Add New
            </MDButton>
          </MDBox>
          <DataTable
            table={tableData}
            canSearch
            searchText="Search Amenities..."
            pageCount={pageCount}
            pageNo={pageNo}
            setPageNo={setPageNo}
            totalCount={totalCount}
            fetchData={fetchData}
            manualSearch
            manualSort
          />
          {addUpdatePopup && (
            <AddUpdate
              open={addUpdatePopup}
              onClose={() => closeModal()}
              pageNo={pageNo}
              perPage={perPage}
              search={search}
              sortType={sortBy}
              updateData={updateData}
            />
          )}
          {deleteAmenityId && (
            <MDDialog {...deleteDialogProps}>
              <MDTypography variant="h6" fontWeight="medium">
                {Messages.GENERAL.SURE_TO_DELETE}
              </MDTypography>
            </MDDialog>
          )}
        </Card>
      </MDBox>
    </DashboardLayout>
  );
}

export default Amenities;
