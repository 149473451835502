import { useEffect, useState } from "react";
import MDDialog from "components/MDDialog";
import { Grid } from "@mui/material";
import MDTypography from "components/MDTypography";
import { useAppDispatch, useAppSelector } from "store/store";
import { getBookingDetails } from "store/thunk/systemAdmin";
import moment from "moment";
import { getLocalDateTime } from "helper/services";
import MDBox from "components/MDBox";

function ViewDetails(props: any): JSX.Element {
  const dispatch = useAppDispatch();
  const { bookingDetails } = useAppSelector((state: any) => state.bookNowBookingSlice);
  const { open, onClose, viewBookingId } = props;
  const [loading] = useState<boolean>(false);
  const [bookNowBookingData, setBookNowBookingData] = useState<any>({});

  useEffect(() => {
    if (viewBookingId) {
      const req = {
        id: viewBookingId,
      };
      dispatch(getBookingDetails(req));
    }
  }, []);

  useEffect(() => {
    if (viewBookingId && Object.keys(bookingDetails).length) {
      let spitedTime = [];
      if (bookingDetails.time_range) {
        spitedTime = bookingDetails.time_range.split("-");
      }
      const data = {
        ...bookNowBookingData,
        id: viewBookingId && bookingDetails.id,
        time_range: bookingDetails.time_range,
        services: bookingDetails.services,
        from: spitedTime.length && moment(spitedTime[0].trim(), "hh:mm A"),
        to: spitedTime.length && moment(spitedTime[1].trim(), "hh:mm A"),
        location: bookingDetails.location_id,
        query: bookingDetails.query,
        stylist: bookingDetails.stylist_id,
        stylist_name: bookingDetails?.stylist_name ? bookingDetails?.stylist_name : "-",
        location_name: bookingDetails?.location_name ? bookingDetails?.location_name : "-",
        booking_user_name: bookingDetails.booking_user_name,
        booking_user_phone: bookingDetails.booking_user_phone,
        booking_user_email: bookingDetails.booking_user_email,
        referring_url: bookingDetails.referring_url,
        total:
          bookingDetails.total && bookingDetails.total.startsWith("$")
            ? bookingDetails.total.replace("$", "")
            : bookingDetails.total,
        gloss_redirect_url: bookingDetails.gloss_redirect_url,
        created_at: bookingDetails.created_at,
        updated_at: bookingDetails.updated_at,
      };
      setBookNowBookingData(data);
    }
  }, [bookingDetails]);

  const dialogProps = {
    open,
    onClose,
    saveBtn: false,
    title: "View Book Now Booking",
    size: "md",
    loading,
  };

  return (
    <MDDialog {...dialogProps}>
      <Grid container>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <Grid container mb={2}>
            <Grid item xs={12} sm={6} md={6}>
              <MDTypography fontWeight="bold" variant="button">
                Time Range
              </MDTypography>
              <MDBox>
                <MDTypography variant="button">
                  {bookNowBookingData?.time_range || "-"}
                </MDTypography>
              </MDBox>
            </Grid>

            <Grid item xs={12} sm={6} md={6}>
              <MDTypography fontWeight="bold" variant="button">
                Location
              </MDTypography>
              <MDBox>
                <MDTypography variant="button">
                  {bookNowBookingData?.location_name || "-"}
                </MDTypography>
              </MDBox>
            </Grid>
          </Grid>
          <Grid container mb={2}>
            <Grid item xs={12} sm={6} md={6}>
              <MDTypography fontWeight="bold" variant="button">
                Query
              </MDTypography>
              <MDBox>
                <MDTypography variant="button">{bookNowBookingData?.query || "-"}</MDTypography>
              </MDBox>
            </Grid>

            <Grid item xs={12} sm={6} md={6}>
              <MDTypography fontWeight="bold" variant="button">
                Stylists
              </MDTypography>
              <MDBox>
                <MDTypography variant="button">
                  {bookNowBookingData?.stylist_name || "-"}
                </MDTypography>
              </MDBox>
            </Grid>
          </Grid>
          <Grid container mb={2}>
            <Grid item xs={12} sm={12} md={12} mb={2}>
              <MDTypography fontWeight="bold" variant="button">
                Services
              </MDTypography>
              <MDBox>
                <MDTypography variant="button">
                  {JSON.stringify(bookNowBookingData?.services, null, 2)}
                </MDTypography>
              </MDBox>
            </Grid>
          </Grid>
          <Grid container mb={2}>
            <Grid item xs={12} sm={6} md={6}>
              <MDTypography fontWeight="bold" variant="button">
                Booking User Name
              </MDTypography>
              <MDBox>
                <MDTypography variant="button">
                  {bookNowBookingData?.booking_user_name || "-"}
                </MDTypography>
              </MDBox>
            </Grid>

            <Grid item xs={12} sm={6} md={6}>
              <MDTypography fontWeight="bold" variant="button">
                Booking User Phone
              </MDTypography>
              <MDBox>
                <MDTypography variant="button">
                  {bookNowBookingData?.booking_user_phone || "-"}
                </MDTypography>
              </MDBox>
            </Grid>
          </Grid>
          <Grid container mb={2}>
            <Grid item xs={12} sm={6} md={6}>
              <MDTypography fontWeight="bold" variant="button">
                Booking User Email
              </MDTypography>
              <MDBox>
                <MDTypography variant="button">
                  {bookNowBookingData?.booking_user_email || "-"}
                </MDTypography>
              </MDBox>
            </Grid>

            <Grid item xs={12} sm={6} md={6}>
              <MDTypography fontWeight="bold" variant="button">
                Total
              </MDTypography>
              <MDBox>
                <MDTypography variant="button">{bookNowBookingData?.total || "-"}</MDTypography>
              </MDBox>
            </Grid>

            <Grid item xs={12} sm={6} md={6}>
              <MDTypography fontWeight="bold" variant="button">
                Referring Url
              </MDTypography>
              <MDBox>
                <MDTypography variant="button">
                  {bookNowBookingData?.referring_url || "-"}
                </MDTypography>
              </MDBox>
            </Grid>

            <Grid item xs={12} sm={6} md={6}>
              <MDTypography fontWeight="bold" variant="button">
                Created At
              </MDTypography>
              <MDBox>
                <MDTypography variant="button">
                  {bookNowBookingData?.created_at
                    ? getLocalDateTime(bookNowBookingData?.created_at)
                    : "-"}
                </MDTypography>
              </MDBox>
            </Grid>

            <Grid item xs={12} sm={6} md={6}>
              <MDTypography fontWeight="bold" variant="button">
                Updated At
              </MDTypography>
              <MDBox>
                <MDTypography variant="button">
                  {bookNowBookingData?.updated_at
                    ? getLocalDateTime(bookNowBookingData?.updated_at)
                    : "-"}
                </MDTypography>
              </MDBox>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </MDDialog>
  );
}

export default ViewDetails;
