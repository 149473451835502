import { createAsyncThunk } from "@reduxjs/toolkit";
import { notificationFail } from "store/slices/notificationSlice";
import { setLoading } from "store/slices/authSlice";
import { setAnswerData } from "store/slices/rockbotSlice";
import Messages from "helper/messages";
import slsApiClient from "config/slsApiClient";

export const getRockbot = createAsyncThunk("getRockbot", async (_request: any, { dispatch }) => {
  dispatch(setLoading(true));
  try {
    const response = await slsApiClient().get(`get-rockbot?ip_address=${_request.ip_address}`);
    dispatch(setLoading(false));
    if (response && response?.data?.status) {
      dispatch(setAnswerData(response?.data?.data?.answers));
    } else {
      dispatch(notificationFail(response?.data?.message || Messages.ERROR.NO_DATA_FOUND));
    }
  } catch (error: any) {
    dispatch(setLoading(false));
    dispatch(notificationFail(error?.response?.data?.message || Messages.ERROR.NO_DATA_FOUND));
  }
});

export const addRockbot = createAsyncThunk("addRockbot", async (_request: any, { dispatch }) => {
  dispatch(setLoading(true));
  try {
    const response = await slsApiClient().post(`add-rockbot`, _request.data);
    if (response?.data?.status) {
      if (_request.callback) {
        _request.callback();
        dispatch(setLoading(false));
      }
    } else {
      dispatch(setLoading(false));
      dispatch(notificationFail(response?.data?.message || Messages.ERROR.SOMETHING_WENT_WRONG));
    }
  } catch (error: any) {
    dispatch(notificationFail(error.message || Messages.ERROR.SOMETHING_WENT_WRONG));
    dispatch(setLoading(false));
  }
});
