/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import { SelectedDateRange } from "layouts/pages/scorecard-dashboard";
import { notificationFail } from "store/slices/notificationSlice";
import { useAppDispatch } from "store/store";
import config from "config/config";
import axios from "axios";
import {
  getFirstDateOfCurrentMonth,
  convertToFirstDayOfMonth,
  fetchCurrentDate,
  convertToLastDayOfMonth,
  getYears,
  createB2BObject,
} from "helper/reports";
import Messages from "helper/messages";
import MDTypography from "components/MDTypography";
import { Card, Divider, Grid, Icon } from "@mui/material";
import MDBox from "components/MDBox";
import { Bar } from "react-chartjs-2";
import { FormattedType, LeadsAttributionData } from "../..";

interface LeadsB2BLocationProps {
  selectedDateRange: SelectedDateRange;
  currentDateCheckBox: boolean;
  setLeadsB2BData: (LeadB2BData: LeadsAttributionData) => void;
}
function LeadsB2B({
  selectedDateRange,
  currentDateCheckBox,
  setLeadsB2BData,
}: LeadsB2BLocationProps) {
  const dispatch = useAppDispatch();
  const [formattedLeadsB2B, setFormattedLeadsB2B] = useState<FormattedType>();
  const fetchLeadB2BData = () => {
    try {
      if (selectedDateRange.startMonth > selectedDateRange.endMonth) {
        throw new Error("Start month should be lower than end month");
      } else {
        const startDate = currentDateCheckBox
          ? getFirstDateOfCurrentMonth()
          : convertToFirstDayOfMonth(selectedDateRange.startMonth);
        const endDate = currentDateCheckBox
          ? fetchCurrentDate()
          : convertToLastDayOfMonth(selectedDateRange.endMonth);
        let url = "";
        url = `${config.REPORT_URL}/report/fetchLeadsB2B?startDate=${startDate
          .toString()
          .slice(0, 10)}&endDate=${endDate.toString().slice(0, 10)}`;
        axios
          .get(url, {
            responseType: "json",
          })
          .then((res: any) => {
            setLeadsB2BData(res.data.result);
            setFormattedLeadsB2B(createB2BObject(res.data.result));
          })
          .catch((err) => {
            dispatch(
              notificationFail(err?.response?.data?.message || Messages.ERROR.SOMETHING_WENT_WRONG)
            );
          });
      }
    } catch (e) {
      dispatch(notificationFail(e.message || Messages.ERROR.SOMETHING_WENT_WRONG));
    }
  };

  useEffect(() => {
    fetchLeadB2BData();
  }, [selectedDateRange]);

  const options = {
    plugins: {
      legend: {
        display: false,
      },
      title: {
        display: false,
        text: "Chart.js Bar Chart",
      },
    },
  };
  const data = {
    labels: getYears(selectedDateRange, currentDateCheckBox),
    datasets: [
      {
        label: "Request leasing information",
        data: formattedLeadsB2B?.Requestleasinginformation,
        backgroundColor: "rgba(255, 99, 132, 0.5)",
      },
      {
        label: "Request Leasing Information",
        data: formattedLeadsB2B?.RequestLeasingInformation,
        backgroundColor: "rgba(289, 174, 39, 0.8)",
      },
      {
        label: "Slick lead",
        data: formattedLeadsB2B?.Slicklead,
        backgroundColor: "rgba(178, 245, 39, 0.8)",
      },
      {
        label: "Request leasing Info",
        data: formattedLeadsB2B?.RequestleasingInfo,
        backgroundColor: "rsource_paid_othergba(245, 54, 39, 0.8)",
      },
      {
        label: "Request leasking info",
        data: formattedLeadsB2B?.Requestleaskinginfo,
        backgroundColor: "rgba(39, 245, 196, 0.8)",
      },
      {
        label: "Request leasking info",
        data: formattedLeadsB2B?.Requestleaskinginfo,
        backgroundColor: "rgba(39, 144, 245, 0.8)",
      },
      {
        label: "leasing info",
        data: formattedLeadsB2B?.leasinginfo,
        backgroundColor: "rgba(58, 39, 245, 0.8)",
      },
      {
        label: "Lead reached out via phone call ",
        data: formattedLeadsB2B?.Leadreachedoutviaphonecall,
        backgroundColor: "rgba(56, 39, 245, 0.8)",
      },
      {
        label: "tour",
        data: formattedLeadsB2B?.tour,
        backgroundColor: "rgba(56, 39, 245, 0.8)",
      },
      {
        label: "Request a tour",
        data: formattedLeadsB2B?.Requestatour,
        backgroundColor: "rgba(245, 39, 200, 0.8)",
      },
    ],
  };

  return (
    <Card sx={{ height: "100%" }}>
      <Grid container>
        <Grid item md={12} sm={12} pt={3} pb={1} px={1}>
          <Bar options={options} data={data} />
        </Grid>
        <Grid item md={12} sm={12}>
          <MDTypography variant="h6" textTransform="capitalize" padding="0px 0px 0px 15px">
            LeadsB2B
          </MDTypography>
          <MDTypography
            component="div"
            variant="button"
            color="text"
            fontWeight="light"
            padding="0px 0px 15px 15px"
          >
            A bar graph representing all LeadsB2B
          </MDTypography>
          <Divider />
          <MDBox display="flex" alignItems="center" padding="0px 0px 15px 15px">
            <MDTypography variant="button" color="text" lineHeight={1} sx={{ mt: 0.15, mr: 0.5 }}>
              <Icon>schedule</Icon>
            </MDTypography>
            <MDTypography variant="button" color="text" fontWeight="light">
              {!currentDateCheckBox
                ? `${selectedDateRange.startMonth} - ${selectedDateRange.endMonth}`
                : `${new Date().getFullYear()}-${(new Date().getMonth() + 1)
                    .toString()
                    .padStart(2, "0")}`}
            </MDTypography>
          </MDBox>
        </Grid>
      </Grid>
    </Card>
  );
}

export default LeadsB2B;
