import { Card, Checkbox, Grid, Icon } from "@mui/material";
import MDBox from "components/MDBox";
import DataTable from "examples/Tables/DataTable";
import { useCallback, useEffect, useRef, useState } from "react";
import exportData from "helper/exportTableConst";
import { useAppDispatch, useAppSelector } from "store/store";
import { getWebPageDetails, getWebpageByLocationId } from "store/thunk/solaProsThunk";
import { setDefaultSelectedColumns, removeExportFields, adminFranchisie } from "helper/services";
import MDIconButton from "components/MDIconButton";
import { getDefaultCoulmns } from "store/thunk/connectUserThunk";
import { setDefaultsColumns } from "store/slices/connectUsersSlice";
import ViewDetails from "layouts/pages/sola-web/viewDetails";
import ImagePreview from "layouts/pages/ImagePreview";
import { useNavigate } from "react-router-dom";
import WebPageAddUpdate from "layouts/pages/sola-web/web-page-details";
import { setWebPageDetail } from "store/slices/solaProSlice";

function Index(props?: any): JSX.Element {
  const { stylistId } = props;
  const dispatch = useAppDispatch();
  const fetchIdRef = useRef(0);
  const { locationWebPagesList } = useAppSelector((state) => state.solaProSlice);
  const { defaultSelectedColumns } = useAppSelector((state) => state.connectUsersReducer);
  const { userData } = useAppSelector((state) => state.authReducer);
  const [pageCount, setPageCount] = useState(0);
  const [pageNo, setPageNo] = useState(0);
  const [perPage, setPerPage] = useState(0);
  const [totalCount, setTotalCount] = useState(0);
  const [search, setSearch] = useState<any>(null);
  const [sortBy, setSortBy] = useState<any>([]);
  const [isCheckAll, setIsCheckAll] = useState(false);
  const [isPreview, setImagePreview] = useState(false);
  const [imageToPreview, setImageToPreview] = useState("");
  const [selectedItems, setSelectedItems] = useState([]);
  const [displayColumns, setDisplayColumns] = useState<any>([]);
  const [viewPopUp, setViewPopUp] = useState<boolean>(false);
  const [webPagesLocationList, setWebPagesLocationList] = useState([]);
  const [webpageId, setWebPageId] = useState(null);
  const navigate = useNavigate();
  const fetchData = useCallback(({ pageSize, pageIndex, search, sortBy }) => {
    const fetchId = ++fetchIdRef.current;
    const sort: any = sortBy || [];

    setTimeout(() => {
      if (fetchId === fetchIdRef.current) {
        setPageNo(pageIndex);
        setSearch(search);
        setSortBy(sort);
        setPerPage(pageSize);
        setIsCheckAll(false);
        dispatch(
          getWebpageByLocationId({
            pageSize,
            pageIndex,
            search,
            sort,
            stylistId,
          })
        );
      }
    }, 1000);
  }, []);

  useEffect(() => {
    if (locationWebPagesList && locationWebPagesList?.data) {
      setWebPagesLocationList(locationWebPagesList?.data);
      setPageCount(locationWebPagesList?.meta?.total_pages);
      setTotalCount(locationWebPagesList?.meta?.total_count);
      if (locationWebPagesList?.data?.length === 1) {
        dispatch(setWebPageDetail(locationWebPagesList?.data?.[0]));
        setWebPageId(locationWebPagesList?.data?.[0]?.id);
      }
    }
  }, [locationWebPagesList]);

  const getTestimonialImages = () => {
    const arr: any = [];
    [...Array(10)].map((item: any, ix: any) => {
      arr.push({
        Header: `Testimonial #${ix + 1}`,
        accessor: `testimonial_id_${ix + 1}`,
        export: true,
        checked: false,
        associative_colums: `testimonial_${ix + 1}.name`,
        disableSortBy: true,
      });
      return true;
    });
    [...Array(10)].map((item: any, ix: any) => {
      arr.push({
        Header: `Image #${ix + 1}`,
        accessor: `image_${ix + 1}_url`,
        export: true,
        checked: false,
        hide_columns: true,
        disableSortBy: true,
      });
      return true;
    });
    return arr;
  };

  // Get columns
  const getColumns = () => {
    const columns: any = [
      {
        Header: "",
        disableSortBy: true,
        accessor: "checkbox",
        export: false,
        width: "100px",
        checked: true,
      },
      {
        Header: "Business Name",
        accessor: "business_name",
        export: true,
        disableCheck: true,
        checked: true,
      },
      { Header: "Studio Number", accessor: "studio_number", export: true, checked: true },
      { Header: "Website Email Address", accessor: "website_email", export: true, checked: true },
      {
        Header: "Website Phone Number",
        accessor: "website_phone",
        export: true,
        checked: true,
      },
      { Header: "Url", accessor: "url", export: true, checked: true },
      { Header: "Website Url", accessor: "website_url", export: true, checked: true },
      { Header: "Booking Url", accessor: "booking_url", export: true, checked: true },
      { Header: "Facebook Url", accessor: "facebook_url", export: true, checked: true },
      { Header: "Google Plus Url", accessor: "google_plus_url", export: true, checked: false },
      { Header: "Instagram Url", accessor: "instagram_url", export: true, checked: false },
      { Header: "Linkedin Url", accessor: "linkedin_url", export: true, checked: false },
      { Header: "Pinterest Url", accessor: "pinterest_url", export: true, checked: false },
      { Header: "Twitter Url", accessor: "twitter_url", export: true, checked: false },
      { Header: "Yelp Url", accessor: "yelp_url", export: true, checked: false },
      { Header: "Tik Tok Url", accessor: "tik_tok_url", export: true, checked: false },
      ...getTestimonialImages(),
      {
        Header: "Action",
        accessor: "action",
        width: "200px",
        checked: true,
        export: false,
        disableSortBy: true,
      },
    ];
    return columns;
  };

  const [checkedColumns, setCheckedColumns] = useState<any>(getColumns());

  useEffect(() => {
    dispatch(setDefaultsColumns([]));

    // Set data in variable and Call defaultColums Thunk
    const sentReq = {
      user_id: userData?.user_id,
      table_name: exportData.connect_webpages.table_name,
    };
    dispatch(getDefaultCoulmns(sentReq));
  }, []);

  useEffect(() => {
    if (checkedColumns?.length) {
      setDisplayColumns(checkedColumns.filter((col: any) => col.checked));
    }
  }, [checkedColumns]);

  useEffect(() => {
    if (defaultSelectedColumns && defaultSelectedColumns?.length) {
      const newColumns = setDefaultSelectedColumns(defaultSelectedColumns, getColumns());
      setCheckedColumns(newColumns);
    } else {
      setCheckedColumns(getColumns());
    }
  }, [defaultSelectedColumns]);

  const previewImage = (image: any) => {
    setImagePreview(true);
    setImageToPreview(image);
  };

  const closeImagePreview = () => {
    setImagePreview(false);
    setImageToPreview("");
  };

  const getImage = (imageUrl: any) => (
    <Grid container>
      <Grid item>
        {imageUrl && (
          <Grid
            container
            onClick={() => previewImage(imageUrl)}
            justifyContent="center"
            alignItems="center"
            textAlign="center"
            className="thumbnail-img"
          >
            <img width="100%" height="100px" src={imageUrl} alt="original" />
          </Grid>
        )}
      </Grid>
    </Grid>
  );

  const onChangeCheckBox = (e: any) => {
    let items = JSON.parse(JSON.stringify(selectedItems));
    if (e.target.checked && !items.includes(e.target.value)) {
      items.push(e.target.value);
      if (items.length === webPagesLocationList.length) {
        setIsCheckAll(true);
      }
    } else {
      items = items.filter((id: any) => id !== e.target.value);
      setIsCheckAll(false);
    }
    setSelectedItems(items);
  };

  const checkUncheckAll = (e: any) => {
    let allIds: any = [];
    allIds = webPagesLocationList.map((val: any) => String(val.id));
    if (e.target.checked) {
      const selectedIds = [...new Set([...selectedItems, ...allIds])];
      setSelectedItems(selectedIds);
      setIsCheckAll(true);
    } else {
      allIds = webPagesLocationList.map((val: any) => String(val.id));
      allIds = selectedItems.filter((el: any) => !allIds.includes(el));
      setSelectedItems([...allIds]);
      setIsCheckAll(false);
    }
  };

  const openViewPopup = (obj: any) => {
    dispatch(getWebPageDetails({ id: obj.id, platform: "web" }));
    setViewPopUp(true);
  };

  const closeViewPopup = () => {
    setViewPopUp(false);
  };
  const goToEditWebPage = (obj: any) => {
    navigate(`/sola-pros/web-pages/edit-webpage`, {
      state: { prevPath: "/sola-pros/web-pages", id: obj?.id },
    });
  };
  const actionButtons = (obj: any) => (
    <>
      <MDIconButton
        tooltipName="View"
        aria-label="Info"
        color="info"
        onClick={() => openViewPopup(obj)}
      >
        <Icon fontSize="small">visibility</Icon>
      </MDIconButton>
      {adminFranchisie() && (
        <MDIconButton
          tooltipName="Edit"
          aria-label="Edit"
          color="info"
          onClick={() => goToEditWebPage(obj)}
        >
          <Icon fontSize="small">edit</Icon>
        </MDIconButton>
      )}
    </>
  );

  const getRows = () => {
    const data: any = webPagesLocationList.map((obj: any) => ({
      checkbox: (
        <Checkbox
          checked={selectedItems.includes(obj?.id.toString())}
          onChange={(e: any) => onChangeCheckBox(e)}
          value={obj?.id}
        />
      ),
      id: obj?.id || "-",
      business_name: obj?.business_name || "-",
      studio_number: obj?.studio_number || "-",
      website_email: obj?.website_email || "-",
      website_phone: obj?.website_phone || "-",
      url: obj?.url || "-",
      website_url: obj?.website_url || "-",
      booking_url: obj?.booking_url || "-",
      pinterest_url: obj?.pinterest_url || "-",
      facebook_url: obj?.facebook_url || "-",
      twitter_url: obj?.twitter_url || "-",
      instagram_url: obj?.instagram_url || "-",
      linkedin_url: obj?.linkedin_url || "-",
      yelp_url: obj?.yelp_url || "-",
      google_plus_url: obj?.google_plus_url || "-",
      tik_tok_url: obj?.tik_tok_url || "-",
      testimonial_id_1: obj?.testimonial_1?.name ? obj?.testimonial_1?.name || "-" : "-",
      testimonial_id_2: obj?.testimonial_2?.name ? obj?.testimonial_2?.name || "-" : "-",
      testimonial_id_3: obj?.testimonial_3?.name ? obj?.testimonial_3?.name || "-" : "-",
      testimonial_id_4: obj?.testimonial_4?.name ? obj?.testimonial_4?.name || "-" : "-",
      testimonial_id_5: obj?.testimonial_5?.name ? obj?.testimonial_5?.name || "-" : "-",
      testimonial_id_6: obj?.testimonial_6?.name ? obj?.testimonial_6?.name || "-" : "-",
      testimonial_id_7: obj?.testimonial_7?.name ? obj?.testimonial_7?.name || "-" : "-",
      testimonial_id_8: obj?.testimonial_8?.name ? obj?.testimonial_8?.name || "-" : "-",
      testimonial_id_9: obj?.testimonial_9?.name ? obj?.testimonial_9?.name || "-" : "-",
      testimonial_id_10: obj?.testimonial_10?.name ? obj?.testimonial_10?.name || "-" : "-",
      image_1_url: obj?.image_1_url ? getImage(obj?.image_1_url) : "-",
      image_2_url: obj?.image_2_url ? getImage(obj?.image_2_url) : "-",
      image_3_url: obj?.image_3_url ? getImage(obj?.image_3_url) : "-",
      image_4_url: obj?.image_4_url ? getImage(obj.image_4_url) : "-",
      image_5_url: obj?.image_5_url ? getImage(obj.image_5_url) : "-",
      image_6_url: obj?.image_6_url ? getImage(obj?.image_6_url) : "-",
      image_7_url: obj?.image_7_url ? getImage(obj?.image_7_url) : "-",
      image_8_url: obj?.image_8_url ? getImage(obj?.image_8_url) : "-",
      image_9_url: obj?.image_9_url ? getImage(obj?.image_9_url) : "-",
      image_10_url: obj?.image_10_url ? getImage(obj?.image_10_url) : "-",
      action: actionButtons(obj),
    }));
    return data;
  };

  // Remove uncheck boxes
  const clearCheckItem = () => {
    setSelectedItems([]);
  };

  const tableData = {
    columns: getColumns(),
    rows: getRows(),
  };

  return (
    <MDBox pt={4} pb={3}>
      {webPagesLocationList?.length === 1 && webpageId ? (
        <WebPageAddUpdate webpageId={webpageId} noDashboardLayout />
      ) : (
        <>
          {/* <MDBox
            bgColor="dark"
            color="white"
            coloredShadow="dark"
            borderRadius="xl"
            alignItems="center"
            justifyContent="space-between"
            pt={1}
            pb={1}
            pl={2}
            pr={2}
            ml={2}
            mr={2}
            display="flex"
            className="page-header"
            mt={-3}
          >
            <p className="page-header-label">Web Pages</p>
          </MDBox> */}
          <Card>
            <DataTable
              defaultDisplayColumns={displayColumns}
              setCheckedColumns={setCheckedColumns}
              table={tableData}
              canSearch
              searchText="Search..."
              selectedItems={selectedItems}
              pageCount={pageCount}
              pageNo={pageNo}
              setPageNo={setPageNo}
              totalCount={totalCount}
              fetchData={fetchData}
              exportFields={removeExportFields(exportData.connect_webpages.export_fields)}
              exportTable={exportData.connect_webpages.table_name}
              exportType={exportData.connect_webpages.export_type}
              manualSearch
              manualSort
              isCheckAll={isCheckAll}
              isDisplayCheckAllCheckbox
              onChangeCheckAllCheckbox={checkUncheckAll}
              checkedColumns={checkedColumns}
              clearCheckItem={clearCheckItem}
            />
          </Card>
        </>
      )}
      {viewPopUp && (
        <ViewDetails
          open={viewPopUp}
          onClose={() => closeViewPopup()}
          sortBy={sortBy}
          search={search}
          perPage={perPage}
        />
      )}
      <ImagePreview
        imageData={imageToPreview}
        open={isPreview}
        onCloseImage={() => {
          closeImagePreview();
        }}
      />
    </MDBox>
  );
}

export default Index;
