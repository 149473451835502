import MDDialog from "components/MDDialog";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
// import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { getBrand } from "store/thunk/mobileAppThunk";
import { Accordion, AccordionDetails, AccordionSummary, Grid } from "@mui/material";
import { useEffect, useState } from "react";
import ImagePreview from "layouts/pages/ImagePreview";
import { useAppDispatch, useAppSelector } from "store/store";
import { displayMultipleValue } from "helper/services";

function ViewDetails(props: any): JSX.Element {
  const dispatch = useAppDispatch();
  const { brandDetails } = useAppSelector((state: any) => state.mobileAppSlice);
  const { open, onClose, viewBrandId } = props;
  const [loading] = useState<boolean>(false);
  const [brandsData, setBrandsData] = useState<any>({});
  const [imagePreview, setImagePreview] = useState<any>("");
  const [isPreview, setImagePreviewPopup] = useState(false);
  const [imageToPreview, setImageToPreview] = useState("");

  useEffect(() => {
    if (viewBrandId) {
      dispatch(getBrand({ id: viewBrandId }));
    }
  }, []);

  useEffect(() => {
    if (Object.keys(brandDetails).length) {
      const countryData = [
        ...new Map(brandDetails?.countries_name.map((item: any) => [item.id, item])).values(),
      ];
      setBrandsData({
        id: brandDetails.id,
        name: brandDetails.name,
        website_url: brandDetails.website_url,
        links: brandDetails.links,
        introduction_video_heading_title: brandDetails.introduction_video_heading_title,
        events_and_classes_heading_title: brandDetails.events_and_classes_heading_title,
        countries: countryData,
      });
      setImagePreview(brandDetails.image_url);
    }
  }, [brandDetails]);

  const dialogProps = {
    open,
    onClose,
    saveBtn: false,
    title: "View Brands",
    size: "md",
    loading,
  };

  const previewImage = (image: any) => {
    setImagePreviewPopup(true);
    setImageToPreview(image);
  };

  const closeImagePreview = () => {
    setImagePreviewPopup(false);
    setImageToPreview("");
  };

  return (
    <MDDialog {...dialogProps}>
      <MDBox pb={2}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12} md={12}>
            <Accordion expanded>
              <AccordionSummary
                className="location-accordion"
                // expandIcon={<ExpandMoreIcon />}
                aria-controls="publishbh-content"
              >
                <MDTypography variant="h6">General</MDTypography>
              </AccordionSummary>
              <AccordionDetails>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6} md={6}>
                    <MDTypography fontWeight="bold" variant="button">
                      Name
                    </MDTypography>
                    <MDBox>
                      <MDTypography variant="button">{brandsData.name || "-"}</MDTypography>
                    </MDBox>
                  </Grid>
                  <Grid item xs={12} sm={6} md={6}>
                    <MDTypography fontWeight="bold" variant="button">
                      Color Image
                    </MDTypography>
                    <MDBox>
                      {imagePreview && (
                        <Grid
                          container
                          onClick={() => previewImage(imagePreview)}
                          alignItems="center"
                          textAlign="center"
                          className="thumbnail-img"
                        >
                          <img width="100%" src={imagePreview} alt="article" />
                        </Grid>
                      )}
                      {!imagePreview && "-"}
                    </MDBox>
                  </Grid>
                  <Grid item xs={12} sm={6} md={6}>
                    <MDTypography fontWeight="bold" variant="button">
                      Links
                    </MDTypography>
                    <MDBox>
                      <MDTypography variant="button">
                        {displayMultipleValue(brandsData.links)}
                      </MDTypography>
                    </MDBox>
                  </Grid>
                  <Grid item xs={12} sm={6} md={6}>
                    <MDTypography fontWeight="bold" variant="button">
                      Countries
                    </MDTypography>
                    <MDBox>
                      <MDTypography variant="button">
                        {displayMultipleValue(brandsData.countries)}
                      </MDTypography>
                    </MDBox>
                  </Grid>
                </Grid>
              </AccordionDetails>
            </Accordion>
          </Grid>
          <Grid item xs={12} sm={12} md={12}>
            <Accordion expanded>
              <AccordionSummary
                className="location-accordion"
                // expandIcon={<ExpandMoreIcon />}
                aria-controls="publishbh-content"
              >
                <MDTypography variant="h6">Settings</MDTypography>
              </AccordionSummary>
              <AccordionDetails>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6} md={6}>
                    <MDTypography fontWeight="bold" variant="button">
                      Introduction Video Heading Title
                    </MDTypography>
                    <MDBox>
                      <MDTypography variant="button">
                        {brandsData?.introduction_video_heading_title || "-"}
                      </MDTypography>
                    </MDBox>
                  </Grid>
                  <Grid item xs={12} sm={6} md={6}>
                    <MDTypography fontWeight="bold" variant="button">
                      Events And Classes Heading Title
                    </MDTypography>
                    <MDBox>
                      <MDTypography variant="button">
                        {brandsData?.events_and_classes_heading_title || "-"}
                      </MDTypography>
                    </MDBox>
                  </Grid>
                </Grid>
              </AccordionDetails>
            </Accordion>
          </Grid>
          <Grid item xs={12} sm={12} md={12}>
            <Accordion expanded>
              <AccordionSummary
                className="location-accordion"
                // expandIcon={<ExpandMoreIcon />}
                aria-controls="publishbh-content"
              >
                <MDTypography variant="h6">Other</MDTypography>
              </AccordionSummary>
              <AccordionDetails>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6} md={6}>
                    <MDTypography fontWeight="bold" variant="button">
                      Website Url
                    </MDTypography>
                    <MDBox>
                      <MDTypography variant="button">{brandsData?.website_url || "-"}</MDTypography>
                    </MDBox>
                  </Grid>
                </Grid>
              </AccordionDetails>
            </Accordion>
          </Grid>
        </Grid>
      </MDBox>
      {isPreview && (
        <ImagePreview
          imageData={imageToPreview}
          open={isPreview}
          onCloseImage={() => {
            closeImagePreview();
          }}
        />
      )}
    </MDDialog>
  );
}

export default ViewDetails;
