import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import { Checkbox, FormControl, FormControlLabel, FormGroup } from "@mui/material";
import CustomErrorMessage from "components/CustomErrorMessage";
import { useAppSelector } from "store/store";
import MDInput from "components/MDInput";
import MESSAGES from "helper/messages";

function Services(props: any): JSX.Element {
  const { solaProDetail, errors, setSolaProDetail, onChangeField } = props;
  const { allConnectServices } = useAppSelector((state: any) => state.solaProSlice);

  const onCheckboxChange = (event: any) => {
    setSolaProDetail({
      ...solaProDetail,
      [event.target.name]: event.target.checked,
      services: solaProDetail?.services
        ? [...new Set([...solaProDetail.services, event.target.name])]
        : [event.target.name],
    });
  };

  return (
    <Card id="services">
      <MDBox p={3}>
        <MDTypography variant="h5">Services</MDTypography>
      </MDBox>
      <MDBox pb={3} px={3}>
        <Grid container spacing={3}>
          {allConnectServices?.map((item: any) => (
            <Grid item xs={12} sm={6} md={6} lg={4} key={item?.label}>
              <FormControl component="fieldset">
                <FormGroup aria-label="position">
                  <FormControlLabel
                    value="end"
                    control={
                      <Checkbox
                        checked={solaProDetail?.[item]}
                        name={item?.label}
                        onChange={onCheckboxChange}
                        inputProps={{ "aria-label": "controlled" }}
                      />
                    }
                    label={item?.label}
                    labelPlacement="end"
                  />
                </FormGroup>
              </FormControl>
            </Grid>
          ))}
          {/* SD-3065 changes */}
          <Grid item xs={12} sm={6} md={6} lg={4}>
            <MDInput
              label={
                <MDTypography sx={{ fontWeight: "700", fontSize: "inherit" }}>Other</MDTypography>
              }
              variant="standard"
              fullWidth
              name="other_service"
              placeholder=""
              onChange={onChangeField}
              className="info-error"
              value={solaProDetail?.other_service ? solaProDetail?.other_service : ""}
              error={errors && errors.other_service ? true : false}
              helperText={errors && errors.other_service}
              infoText={MESSAGES.INFO.OPTIONAL_LENGTH_255}
            />
          </Grid>
        </Grid>
      </MDBox>
      <MDBox pl={3} pr={3} pt={0} pb={3}>
        {errors && errors.services && <CustomErrorMessage message={errors.services} />}
      </MDBox>
    </Card>
  );
}

export default Services;
