import { useEffect, useState } from "react";
import { Card, Icon, Switch } from "@mui/material";

import MDBox from "components/MDBox";
// import MDButton from "components/MDButton";

import DataTable from "examples/Tables/DataTable";
import { getLocalDateTime } from "helper/services";
import MDIconButton from "components/MDIconButton";
import { useAppDispatch, useAppSelector } from "../../../../store/store";
import AddUpdate from "./addUpdate";
import PageSection from "./pageSection";
import ViewSubMenu from "./submenu/viewSubMenu";
import { getMenusList } from "../../../../store/thunk/rolesAndMenusThunk";

function MenuLIst(): JSX.Element {
  const dispatch = useAppDispatch();
  const rolesMenusInfo = useAppSelector((state) => state.rolesAndMenusReducer);
  const [menuPopup, setMenuPopup] = useState(false);
  const [submenuPopup, setSubmenuPopup] = useState(false);
  const [menuList, setMenuList] = useState([]);
  const [subMenus, setSubMenus] = useState([]);
  const [editMenu, setEditMenu] = useState([]);
  const [viewMenuId, setViewMenuId] = useState(null);
  const [menu, setMenu] = useState(null);

  useEffect(() => {
    dispatch(getMenusList());
  }, []);

  // const editMenuAction = (val: any) => {
  //   setMenuPopup(true);
  //   setEditMenu(val);
  // };

  useEffect(() => {
    if (rolesMenusInfo?.menus.length > 0) {
      const allMenus: any = [];
      rolesMenusInfo.menus.map((val: any) => {
        const obj = {
          alias: val.alias,
          display_name: val.display_menu,
          description: val.description,
          default: (
            <Switch
              checked={val.default}
              // onChange={(e: any) => setDefaultMenu(e.target.checked)}
              inputProps={{ "aria-label": "controlled" }}
            />
          ),
          home_page: (
            <Switch
              checked={val.home_page}
              // onChange={(e: any) => setDefaultMenu(e.target.checked)}
              inputProps={{ "aria-label": "controlled" }}
            />
          ),
          updated_at: getLocalDateTime(val.updated_at, "MM/DD/YYYY"),
          action: (
            <>
              {val?.child_menu?.length > 0 && (
                <MDIconButton
                  tooltipName="Sub Menu"
                  aria-label="Sub Menu"
                  color="info"
                  onClick={() => {
                    setSubMenus(val);
                    setViewMenuId(val.menu_id);
                    setSubmenuPopup(true);
                  }}
                >
                  <Icon fontSize="small">account_tree</Icon>
                </MDIconButton>
              )}
              {val?.page_sections?.length > 0 && (
                <MDIconButton
                  tooltipName="Page Section"
                  aria-label="Page Section"
                  color="info"
                  onClick={() => {
                    setMenu(val);
                  }}
                >
                  <Icon fontSize="small">lock_reset</Icon>
                </MDIconButton>
              )}
              {/* <MDIconButton
                tooltipName="Edit"
                onClick={() => editMenuAction(val)}
                aria-label="Edit"
                color="info"
              >
                <Icon fontSize="small">edit</Icon>
              </MDIconButton> */}
            </>
          ),
        };
        allMenus.push(obj);
        return true;
      });
      setMenuList(allMenus);
    }
  }, [rolesMenusInfo]);

  const tableData = {
    columns: [
      { Header: "alias", accessor: "alias" },
      { Header: "display name", accessor: "display_name" },
      { Header: "description", accessor: "description" },
      { Header: "default menu", accessor: "default" },
      { Header: "home page", accessor: "home_page" },
      { Header: "updated at", accessor: "updated_at" },
      { Header: "Action", accessor: "action", width: "200px", disableSortBy: true },
    ],
    rows: menuList,
  };
  return (
    <Card>
      <MDBox
        bgColor="dark"
        color="white"
        coloredShadow="dark"
        borderRadius="xl"
        alignItems="center"
        justifyContent="space-between"
        pt={1}
        pb={1}
        pl={2}
        pr={2}
        ml={2}
        mr={2}
        display="flex"
        mt={-3}
        className="page-header"
      >
        <p className="page-header-label">Menus</p>
        {/* <MDButton
          variant="gradient"
          color="light"
          size="small"
          className="xs-small"
          onClick={() => setMenuPopup(true)}
        >
          <Icon sx={{ fontWeight: "bold" }}>add</Icon> Add menu
        </MDButton> */}
      </MDBox>
      <DataTable table={tableData} canSearch />
      {menuPopup && (
        <AddUpdate
          open={menuPopup}
          onClose={() => {
            setMenuPopup(false);
            setEditMenu(null);
          }}
          editMenu={editMenu}
        />
      )}
      {submenuPopup && (
        <ViewSubMenu
          open={submenuPopup}
          onClose={() => setSubmenuPopup(false)}
          subMenus={subMenus}
          viewMenuId={viewMenuId}
        />
      )}
      {menu && (
        <PageSection
          open={menu ? true : false}
          onClose={() => {
            setMenu(null);
          }}
          menu={menu}
        />
      )}
    </Card>
  );
}

export default MenuLIst;
