import {
  Card,
  FormControl,
  FormControlLabel,
  Grid,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
} from "@mui/material";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

function BankingInfo(props: any): JSX.Element {
  const { rmDetails, onChangeField, getAccessTypePermission } = props;
  return (
    <Card id="banking-info" sx={{ overflow: "visible" }}>
      <MDBox p={3}>
        <MDTypography variant="h5">Banking Information</MDTypography>
      </MDBox>
      <MDBox component="form" pb={3} px={3}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={12}>
            <MDTypography variant="h6" fontWeight="normal">
              Bank Account
            </MDTypography>
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormControl fullWidth>
              <Select
                className="height45"
                name="default_bank"
                value={rmDetails?.default_bank || ""}
                onChange={onChangeField}
                disabled={getAccessTypePermission("location_rentmanager", "edit_access")}
              >
                <MenuItem value="Bank Account 1">Bank Account 1</MenuItem>
                <MenuItem value="Bank Account 2">Bank Account 2</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={12}>
            <MDTypography variant="h6" fontWeight="normal">
              Security Deposit Payment Method
            </MDTypography>
          </Grid>
          <Grid item xs={12} sm={12}>
            <FormControl>
              <RadioGroup
                row
                name="security_deposit_payment_method"
                value={rmDetails?.security_deposit_payment_method || ""}
                onChange={onChangeField}
              >
                <FormControlLabel
                  value="ACH"
                  control={
                    <Radio
                      disabled={getAccessTypePermission("location_rentmanager", "edit_access")}
                    />
                  }
                  label="ACH"
                />
                <FormControlLabel
                  value="CREDITCARD"
                  control={
                    <Radio
                      disabled={getAccessTypePermission("location_rentmanager", "edit_access")}
                    />
                  }
                  label="Credit Card"
                />
                <FormControlLabel
                  value="OFFLINE"
                  control={
                    <Radio
                      disabled={getAccessTypePermission("location_rentmanager", "edit_access")}
                    />
                  }
                  label="Offline"
                />
              </RadioGroup>
            </FormControl>
          </Grid>
        </Grid>
      </MDBox>
    </Card>
  );
}

export default BankingInfo;
