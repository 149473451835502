import React, { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "store/store";
import MDDialog from "components/MDDialog";
import MDBox from "components/MDBox";
import Validations from "helper/validations";
import { saveNotifications } from "store/thunk/mobileAppThunk";
import { getAllStylist } from "store/thunk/systemAdmin";
import { setAllStylists } from "store/slices/systemAdminSlice";
import { FormControl, FormHelperText, Grid, styled } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import MuiAccordion, { AccordionProps } from "@mui/material/Accordion";
import MuiAccordionSummary, { AccordionSummaryProps } from "@mui/material/AccordionSummary";
import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import MDDatePicker from "components/MDDatePicker";
import moment from "moment";
import MDTypography from "components/MDTypography";
import CustomAutoSearch from "components/CustomAutoSearch";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import MDInput from "components/MDInput";
import { scrollToErrorByClass, getPermission } from "helper/services";
import MDLabelValue from "components/MDLabelValue";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Checkbox from "@mui/material/Checkbox";
import MDButton from "components/MDButton";
import Paper from "@mui/material/Paper";

const Accordion = styled((props: AccordionProps) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  "&:before": {
    display: "none",
  },
}));

function not(a: readonly number[], b: readonly number[]) {
  return a.filter((value) => b.indexOf(value) === -1);
}

function intersection(a: readonly number[], b: readonly number[]) {
  return a.filter((value) => b.indexOf(value) !== -1);
}

const AccordionSummary = styled((props: AccordionSummaryProps) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: "0.9rem" }} />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === "dark" ? "rgba(255, 255, 255, .05)" : "rgba(0, 0, 0, .03)",
  flexDirection: "row-reverse",
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(90deg)",
  },
  "& .MuiAccordionSummary-content": {
    marginLeft: theme.spacing(1),
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: "1px solid rgba(0, 0, 0, .125)",
}));

function addUpdate(props: any): JSX.Element {
  const dispatch = useAppDispatch();
  const { open, onClose, updateData, pageNo, perPage, search, sortBy } = props;
  const { allStylists } = useAppSelector((state) => state.systemAdminSlice);
  const [isUpdate, setIsUpdate] = useState<any>(false);
  const [notificationsData, setnotificationsData] = useState<any>({});
  const [stylistSearchTxt, setStylistSearchTxt] = useState<any>("");
  const [errors, setErrors] = useState<any>({});
  const [expanded, setExpanded] = React.useState<string | false>(false);

  const [checked, setChecked] = React.useState<any>([]);
  const [left, setLeft] = React.useState<any>([]);
  const [right, setRight] = React.useState<any>([]);
  const [isChooseAll, setIsChooseAll] = React.useState<any>(false);
  const leftChecked = intersection(checked, left);
  const rightChecked = intersection(checked, right);

  const handleChangeAccordian =
    (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : false);
    };

  const openAccordian = (accordianElementId: any, accordianExpandName: any) => {
    const element = document.getElementById(accordianElementId);
    element.addEventListener("change", () => {
      setExpanded(accordianExpandName);
    });
    const event = new Event("change", {
      bubbles: true,
      cancelable: true,
      composed: false,
    });
    element.dispatchEvent(event);
  };

  const onClosePopup = () => {
    dispatch(setAllStylists([]));
    onClose();
  };

  const onSave = () => {
    let tmpStylistIds = [];
    if (right && right.length > 0) {
      tmpStylistIds = right.map((v: any) => v.id);
    }
    if (!right.length && isChooseAll) {
      tmpStylistIds = ["all"];
    }
    const error = Validations.validateNotificationForm(notificationsData);
    if (error?.title || error?.notification_text) {
      openAccordian("notification-accordian", "notification");
    }
    setErrors(error);
    if (!Object.keys(error).length) {
      const reqData = {
        update_id: updateData?.id ? updateData?.id : null,
        send_at: notificationsData.send_at ? notificationsData.send_at : null,
        title: notificationsData?.title ? notificationsData.title : "",
        notification_text: notificationsData?.notification_text
          ? notificationsData.notification_text
          : "",
        blog_id: notificationsData?.blog?.id ? notificationsData.blog.id : null,
        brand_id: notificationsData?.brand?.id ? notificationsData.brand.id : null,
        deal_id: notificationsData?.deal?.id ? notificationsData.deal.id : null,
        tool_id: notificationsData?.tool?.id ? notificationsData.tool.id : null,
        sola_class_id: notificationsData?.eventClass?.id ? notificationsData.eventClass.id : null,
        video_id: notificationsData?.video?.id ? notificationsData.video.id : null,
        country_id: notificationsData?.country?.id ? notificationsData.country.id : null,
        stylist_ids: tmpStylistIds,
      };
      const req = {
        notificationsData: reqData,
        onClose: onClosePopup,
        isUpdate,
        pageNo,
        perPage,
        search,
        sortBy,
      };
      dispatch(saveNotifications(req));
    } else {
      scrollToErrorByClass(error);
    }
  };

  useEffect(() => {
    if (updateData) {
      setIsUpdate(true);
      let brand: any = null;
      if (updateData.brand_id) {
        brand = {
          id: updateData.brand_id,
          name: updateData.brand_name,
        };
      }
      let blog: any = null;
      if (updateData.blog_id) {
        blog = { id: updateData.blog_id, title: updateData?.blog_name };
      }
      let deal: any = null;
      if (updateData.deal_id) {
        deal = { id: updateData.deal_id, title: updateData.deal_name };
      }
      let tool: any = null;
      if (updateData.tool_id) {
        tool = { id: updateData.tool_id, title: updateData.tool_name };
      }
      let video: any = null;
      if (updateData.video_id) {
        video = { id: updateData.video_id, title: updateData.video_name };
      }
      let eventClass: any = null;
      if (updateData.sola_class_id) {
        eventClass = { id: updateData.sola_class_id, title: updateData.sola_class_name };
      }
      let country: any = null;
      if (updateData.country_id) {
        country = { id: updateData.country_id, name: updateData?.country_name };
      }

      setnotificationsData((prev: any) => ({
        ...prev,
        deal,
        video,
        tool,
        eventClass,
        brand,
        country,
        blog,
        title: updateData.title,
        notification_text: updateData.notification_text,
        send_at: updateData.send_at,
        stylists: updateData.stylists,
      }));
      setRight(updateData?.stylists || []);
    }
  }, [updateData]);
  useEffect(() => {
    if (allStylists && allStylists?.stylists?.length > 0) {
      const newArray = allStylists?.stylists.filter(
        ({ id }: any) => !right.some((e: any) => e.id === id)
      );
      setLeft(newArray || []);
    }
  }, [allStylists]);

  const onChangeField = (e: any, field: string) => {
    setnotificationsData({ ...notificationsData, [field]: e.target.value });
  };
  const onChangeFieldStylist = (e: any) => {
    setStylistSearchTxt(e.target.value);
  };

  const onChangeAutoComplete = (name: string, value: any) => {
    setnotificationsData({ ...notificationsData, [name]: value });
  };

  const handleDateChange = (type: string, date: any) => {
    const utcDate = moment(new Date(date)).add(10, "seconds").toISOString();
    setnotificationsData({ ...notificationsData, [type]: utcDate });
  };

  const dialogProps = {
    open,
    onSave,
    onClose: onClosePopup,
    title: isUpdate ? "Update Notification" : "Add Notification",
    size: "lg",
    saveTbtText: isUpdate ? "Update" : "Save",
  };

  const handleToggle = (value: number) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
  };

  const handleCheckedRight = () => {
    setRight(right.concat(leftChecked));
    setLeft(not(left, leftChecked));
    setChecked(not(checked, leftChecked));
  };

  const handleCheckedLeft = () => {
    const temp = left.concat(rightChecked);
    setLeft(temp);
    setRight(not(right, rightChecked));
    setChecked(not(checked, rightChecked));
  };
  const onSearchStylist = () => {
    dispatch(getAllStylist({ search: stylistSearchTxt }));
  };

  const customList = (items: any, type: string) => (
    <Paper sx={{ width: 400, height: 230, overflow: "auto" }} elevation={3}>
      {!stylistSearchTxt && isChooseAll && !right.length && type === "right" && (
        <MDTypography variant="button" padding={2}>
          Too many objects, use search box above
        </MDTypography>
      )}
      {!stylistSearchTxt && !isChooseAll && !left.length && type === "left" && (
        <MDTypography variant="button" padding={2}>
          Too many objects, use search box above
        </MDTypography>
      )}
      <List dense component="div" role="list">
        {items.map((value: any) => {
          const labelId = `list-item-${value?.id}-label`;
          return (
            <ListItem key={value?.id} role="listitem" button onClick={handleToggle(value)}>
              <ListItemIcon>
                <Checkbox
                  checked={checked.indexOf(value) !== -1}
                  tabIndex={-1}
                  disableRipple
                  inputProps={{
                    "aria-labelledby": labelId,
                  }}
                />
              </ListItemIcon>
              <ListItemText id={labelId} primary={`${value?.name}`} />
            </ListItem>
          );
        })}
      </List>
    </Paper>
  );

  const onChooseAll = () => {
    if (stylistSearchTxt && allStylists && allStylists?.stylists?.length > 0) {
      setRight(allStylists?.stylists || []);
      setLeft([]);
    }
    setIsChooseAll(true);
  };
  const clearChooseAll = () => {
    if (stylistSearchTxt && allStylists && allStylists?.stylists?.length > 0) {
      setRight([]);
      setLeft(allStylists?.stylists || []);
    }
    setIsChooseAll(false);
  };
  const onClearSearchResult = () => {
    dispatch(setAllStylists([]));
    setLeft([]);
    setStylistSearchTxt("");
  };

  return (
    <MDDialog {...dialogProps}>
      <MDBox mb={2} mt={2}>
        <Grid item className="send_at" xs={12} sm={12} md={12} lg={12}>
          <FormControl>
            <MDLabelValue label="Sent At" />
            <MDDatePicker
              value={notificationsData?.send_at || null}
              options={{
                enableTime: true,
                dateFormat: "M d, Y H:i",
                time_24hr: true,
                minDate: new Date(),
              }}
              onChange={([date]: any) => handleDateChange("send_at", date)}
            />
            {/* <CustomErrorMessage name="send_at" message={errors && errors.send_at} /> */}
            <FormHelperText>Will be send immediately if blank</FormHelperText>
          </FormControl>
        </Grid>
      </MDBox>
      <Accordion
        expanded={expanded === "content"}
        sx={{ marginTop: "20px" }}
        onChange={handleChangeAccordian("content")}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="content"
          id="content-header"
        >
          <MDTypography variant="h6">Content</MDTypography>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <CustomAutoSearch
                idKey="id"
                nameKey="title"
                fieldLabel="Blog"
                apiName="blogs"
                responseKey="blogs"
                name="blog"
                value={notificationsData?.blog || null}
                onChange={onChangeAutoComplete}
                createNewLink={
                  getPermission("sola_website", "sola_website_blog") ? "/sola-website/blog" : ""
                }
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <CustomAutoSearch
                idKey="id"
                nameKey="name"
                fieldLabel="Brand"
                apiName="brands"
                responseKey="brands"
                name="brand"
                value={notificationsData?.brand || null}
                onChange={onChangeAutoComplete}
                createNewLink={
                  getPermission("mobile_app", "mobile_app_brands") ? "/mobile-app/brands" : ""
                }
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <CustomAutoSearch
                idKey="id"
                nameKey="title"
                fieldLabel="Deal"
                apiName="deals"
                responseKey="deals"
                name="deal"
                value={notificationsData?.deal || null}
                onChange={onChangeAutoComplete}
                createNewLink={
                  getPermission("mobile_app", "mobile_app_deals") ? "/mobile-app/deals" : ""
                }
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <CustomAutoSearch
                idKey="id"
                nameKey="title"
                fieldLabel="Event Or Class"
                apiName="events_and_classes"
                responseKey="event_and_classes"
                name="eventClass"
                value={notificationsData?.eventClass || null}
                onChange={onChangeAutoComplete}
                createNewLink={
                  getPermission("mobile_app", "mobile_app_events_classes")
                    ? "/mobile-app/events-and-classes"
                    : ""
                }
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <CustomAutoSearch
                idKey="id"
                nameKey="title"
                fieldLabel="Tool Or Resource"
                apiName="tools_and_resources"
                responseKey="tools_and_resources"
                name="tool"
                value={notificationsData?.tool || null}
                onChange={onChangeAutoComplete}
                createNewLink={
                  getPermission("mobile_app", "mobile_app_tools_resources")
                    ? "/mobile-app/tools-and-resources"
                    : ""
                }
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <CustomAutoSearch
                idKey="id"
                nameKey="title"
                fieldLabel="Video"
                apiName="videos"
                responseKey="videos"
                name="video"
                value={notificationsData?.video || null}
                onChange={onChangeAutoComplete}
                createNewLink={
                  getPermission("mobile_app", "mobile_app_videos") ? "/mobile-app/videos" : ""
                }
              />
            </Grid>
          </Grid>
        </AccordionDetails>
      </Accordion>
      <Accordion
        expanded={expanded === "notification"}
        sx={{ marginTop: "20px" }}
        id="notification-accordian"
        onChange={handleChangeAccordian("notification")}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="notification-content"
          id="notification-header"
        >
          <MDTypography variant="h6">Push Notification</MDTypography>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <MDBox mt={2}>
                <MDInput
                  label="Title"
                  variant="standard"
                  fullWidth
                  required
                  name="title"
                  placeholder="Title"
                  value={notificationsData?.title || ""}
                  onChange={(event: any) => onChangeField(event, "title")}
                  error={errors && errors.title ? true : false}
                  helperText={errors && errors.title}
                />
              </MDBox>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <MDBox mt={2}>
                <MDInput
                  label="Notification Text"
                  variant="standard"
                  fullWidth
                  required
                  name="notification_text"
                  placeholder="Notification Text"
                  value={notificationsData?.notification_text || ""}
                  onChange={(event: any) => onChangeField(event, "notification_text")}
                  error={errors && errors.notification_text ? true : false}
                  helperText={errors && errors.notification_text}
                />
              </MDBox>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <CustomAutoSearch
                idKey="id"
                nameKey="name"
                fieldLabel="Country"
                apiName="countries"
                responseKey="countries"
                name="country"
                value={notificationsData?.country || null}
                onChange={onChangeAutoComplete}
              />
            </Grid>
          </Grid>
        </AccordionDetails>
      </Accordion>
      <Accordion
        expanded={expanded === "recipients"}
        sx={{ marginTop: "20px" }}
        onChange={handleChangeAccordian("recipients")}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="recipients-content"
          id="recipients-header"
        >
          <MDTypography variant="h6">Recipients</MDTypography>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <MDBox mt={2} style={{ display: "flex" }}>
                <MDInput
                  label="Stylist"
                  variant="standard"
                  fullWidth
                  name="stylistSearchTxt"
                  placeholder="Search"
                  value={stylistSearchTxt || ""}
                  onChange={(event: any) => onChangeFieldStylist(event)}
                />
                <MDButton
                  sx={{ my: 0.5 }}
                  variant="gradient"
                  color="info"
                  size="small"
                  style={{ margin: "4px 16px" }}
                  onClick={onSearchStylist}
                  aria-label="move selected right"
                >
                  Search
                </MDButton>
                <MDButton
                  sx={{ my: 0.5 }}
                  variant="gradient"
                  color="info"
                  size="small"
                  onClick={onClearSearchResult}
                >
                  Clear
                </MDButton>
              </MDBox>
            </Grid>
          </Grid>
          <Grid container spacing={2} mt={2}>
            <Grid item>
              {customList(left, "left")}
              <MDButton onClick={() => onChooseAll()} variant="text" color="dark" size="small">
                Choose all
              </MDButton>
            </Grid>
            <Grid item>
              <Grid container direction="column" alignItems="center">
                <MDButton
                  sx={{ my: 0.5 }}
                  variant="gradient"
                  color="info"
                  size="small"
                  onClick={handleCheckedRight}
                  disabled={leftChecked.length === 0}
                  aria-label="move selected right"
                >
                  &gt;
                </MDButton>
                <MDButton
                  sx={{ my: 0.5 }}
                  variant="gradient"
                  color="info"
                  size="small"
                  onClick={handleCheckedLeft}
                  disabled={rightChecked.length === 0}
                  aria-label="move selected left"
                >
                  &lt;
                </MDButton>
              </Grid>
            </Grid>
            <Grid item>
              {customList(right, "right")}
              <MDButton onClick={() => clearChooseAll()} variant="text" color="dark" size="small">
                Clear all
              </MDButton>
            </Grid>
          </Grid>
        </AccordionDetails>
      </Accordion>
    </MDDialog>
  );
}

export default addUpdate;
