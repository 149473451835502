import { Card } from "@mui/material";
import MDBox from "components/MDBox";
import DataTable from "examples/Tables/DataTable";
import { useCallback, useEffect, useRef, useState } from "react";
import { useAppDispatch, useAppSelector } from "store/store";
import { getLocationHistoryList } from "store/thunk/locationThunk";

function LocationHistoryList(props?: any): JSX.Element {
  const { locationId } = props;
  const dispatch = useAppDispatch();
  const [historyList, setHistoryList] = useState([]);
  const { locationHistoryDetails } = useAppSelector((state) => state.locationSlice);
  const [pageCount, setPageCount] = useState(0);
  const [pageNo, setPageNo] = useState(0);
  const [totalCount, setTotalCount] = useState(0);
  const fetchIdRef = useRef(0);

  useEffect(() => {
    if (locationHistoryDetails) {
      setHistoryList(locationHistoryDetails?.data);
      setPageCount(locationHistoryDetails?.meta?.total_pages);
      setTotalCount(locationHistoryDetails?.meta?.total_count);
    }
  }, [locationHistoryDetails]);

  // Get columns
  const getColumns = () => [
    { Header: "Id", accessor: "id", width: "30px", export: true },
    { Header: "User Name", accessor: "connect_user", width: "50px", export: true },
    { Header: "Changed Data", accessor: "changed_data", export: true },
  ];

  const getRows = () => {
    const data: any =
      historyList?.length > 0
        ? historyList?.map((obj: any) => ({
            id: obj?.id ? obj?.id : "-",
            connect_user: obj?.connect_user?.first_name
              ? obj?.connect_user?.first_name
              : obj?.connect_user?.login_email
              ? obj?.connect_user?.login_email
              : "-",
            changed_data: obj?.changed_data ? obj?.changed_data : "-",
          }))
        : [];
    return data;
  };

  const tableData = {
    columns: getColumns(),
    rows: getRows(),
  };

  const fetchData = useCallback(({ pageSize, pageIndex, search, sortBy }) => {
    const fetchId = ++fetchIdRef.current;
    const sort: any = sortBy || [];
    // We'll even set a delay to simulate a server here
    setTimeout(() => {
      if (fetchId === fetchIdRef.current) {
        setPageNo(pageIndex);
        dispatch(
          getLocationHistoryList({
            pageSize,
            pageIndex,
            search,
            sort,
            locationId,
          })
        );
      }
    }, 1000);
  }, []);

  return (
    <MDBox pt={4} pb={3}>
      <Card>
        <MDBox
          bgColor="dark"
          color="white"
          coloredShadow="dark"
          borderRadius="xl"
          alignItems="center"
          justifyContent="space-between"
          pt={1}
          pb={1}
          pl={2}
          pr={2}
          ml={2}
          mr={2}
          display="flex"
          mt={-3}
          className="page-header"
        >
          <p className="page-header-label">Location History</p>
        </MDBox>
        <DataTable
          table={tableData}
          canSearch
          searchText="Search Location History..."
          pageCount={pageCount}
          pageNo={pageNo}
          setPageNo={setPageNo}
          totalCount={totalCount}
          fetchData={fetchData}
          manualSearch
          manualSort
        />
      </Card>
    </MDBox>
  );
}

export default LocationHistoryList;
