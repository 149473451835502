import React, { useEffect, useState } from "react";
import MDDialog from "components/MDDialog";
import MDInput from "components/MDInput";
import MDTypography from "components/MDTypography";
import CustomAutoSearch from "components/CustomAutoSearch";
import {
  Grid,
  Checkbox,
  FormGroup,
  FormControl,
  FormControlLabel,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import Validations from "helper/validations";
import { addUpdateVideo } from "store/thunk/mobileAppThunk";
import { useAppDispatch, useAppSelector } from "store/store";
import { getPermission } from "helper/services";

function AddUpdate(props: any): JSX.Element {
  const { open, onClose, pageNo, perPage, search, sortBy } = props;
  const dispatch = useAppDispatch();
  const { video } = useAppSelector((state) => state.mobileAppSlice);
  const [videoData, setVideoData] = useState<any>({});
  const [errors, setErrors] = useState<any>({});
  const [loading] = useState<boolean>(false);

  useEffect(() => {
    if (video && Object.keys(video).length > 0) {
      const videoDetails: any = JSON.parse(JSON.stringify(video));
      if (video.brand_id) {
        Object.assign(videoDetails, { brand: { id: video.brand_id, title: video.brand_name } });
      }
      if (video.tool_id) {
        Object.assign(videoDetails, { tool: { id: video.tool_id, title: video.tool_title } });
      }
      Object.assign(videoDetails, {
        is_introduction: video.is_introduction === true ? "true" : "false",
      });
      Object.assign(videoDetails, { is_featured: video.is_featured === true ? "true" : "false" });
      setVideoData(videoDetails);
    }
  }, [video]);

  const onChangeField = (event: any) => {
    setVideoData((prev: any) => ({
      ...prev,
      [event.target.name]: event.target.value,
    }));
  };

  const onChangeCheckbox = (event: any) => {
    setVideoData((prev: any) => ({
      ...prev,
      [event.target.name]: event.target.checked,
    }));
  };

  const onSave = () => {
    const error = Validations.validateVideosForm(videoData);
    setErrors(error);
    if (!Object.keys(error).length) {
      const video = {
        id: videoData?.id,
        title: videoData?.title,
        webinar: videoData?.webinar ? true : false,
        youtube_url: videoData?.youtube_url,
        duration: videoData?.duration || "",
        brand_id: videoData?.brand?.id,
        brand_name: videoData?.brand?.title,
        tool_id: videoData?.tool?.id,
        is_featured: videoData?.is_featured === "true" ? true : false,
        is_introduction: videoData?.is_introduction === "true" ? true : false,
        country_ids: videoData?.countries?.map((obj: any) => obj.id),
        category_ids: videoData?.categories?.map((obj: any) => obj.id),
        tag_ids: videoData?.tags?.map((obj: any) => obj.id),
      };
      const request = {
        video,
        onClose,
        pageNo,
        perPage,
        search,
        sortBy,
      };
      dispatch(addUpdateVideo(request));
    }
  };

  const dialogProps = {
    open,
    onClose,
    onSave,
    saveTbtText: video?.id ? "Update" : "Save",
    title: video?.id ? "Edit Video" : "Add Video",
    size: "md",
    loading,
  };

  const onChangeAutoComplete = (name: string, value: any) => {
    setVideoData({ ...videoData, [name]: value });
  };

  return (
    <MDDialog {...dialogProps}>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <MDInput
            label="Title"
            variant="standard"
            fullWidth
            name="title"
            placeholder="Title"
            onChange={onChangeField}
            value={videoData?.title || ""}
            error={errors && errors?.title ? true : false}
            helperText={errors && errors?.title}
            required
          />
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <FormControl component="fieldset">
            <FormGroup aria-label="position">
              <FormControlLabel
                value="webinar"
                control={
                  <Checkbox
                    checked={videoData?.webinar ? true : false}
                    name="webinar"
                    onChange={onChangeCheckbox}
                    inputProps={{ "aria-label": "controlled" }}
                  />
                }
                label="Webinar"
                labelPlacement="end"
              />
            </FormGroup>
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6}>
          <MDInput
            label="Youtube Url"
            variant="standard"
            fullWidth
            name="youtube_url"
            placeholder="Youtube Url"
            onChange={onChangeField}
            value={videoData?.youtube_url || ""}
            error={errors && errors?.youtube_url ? true : false}
            helperText={errors && errors?.youtube_url}
            required
          />
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6}>
          <MDInput
            label="Duration"
            variant="standard"
            fullWidth
            name="duration"
            placeholder="Duration"
            onChange={onChangeField}
            value={videoData?.duration || ""}
          />
          <MDTypography variant="span" fontSize="12px">
            (e.g. 11:10, 1:07:41, 3:42, etc)
          </MDTypography>
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6}>
          <CustomAutoSearch
            idKey="id"
            nameKey="title"
            fieldLabel="Related Tool Or Resource"
            apiName="tools_and_resources"
            responseKey="tools_and_resources"
            name="tool"
            value={videoData?.tool || null}
            onChange={onChangeAutoComplete}
            createNewLink={
              getPermission("mobile_app", "mobile_app_tools_resources")
                ? "/mobile-app/tools-and-resources"
                : ""
            }
          />
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6}>
          <CustomAutoSearch
            idKey="id"
            nameKey="title"
            fieldLabel="Brand"
            apiName="brands"
            responseKey="brands"
            name="brand"
            value={videoData?.brand || null}
            onChange={onChangeAutoComplete}
            error={errors && errors?.brand ? true : false}
            required
            createNewLink={
              getPermission("mobile_app", "mobile_app_brands") ? "/mobile-app/brands" : ""
            }
          />
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6}>
          <CustomAutoSearch
            idKey="id"
            nameKey="name"
            fieldLabel="Categories"
            apiName="categories"
            responseKey="categories"
            name="categories"
            value={videoData?.categories || null}
            onChange={onChangeAutoComplete}
            multiple
            createNewLink={
              getPermission("sola_website", "sola_website_categories")
                ? "/sola-website/categories"
                : ""
            }
          />
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6}>
          <CustomAutoSearch
            idKey="id"
            nameKey="name"
            fieldLabel="Tags"
            apiName="tags"
            responseKey="tags"
            name="tags"
            value={videoData?.tags || null}
            onChange={onChangeAutoComplete}
            multiple
            createNewLink={
              getPermission("sola_website", "sola_website_tags") ? "/sola-website/tags" : ""
            }
          />
        </Grid>
        <Grid container item xs={12} sm={12} md={12} lg={12} spacing={2}>
          <Grid item xs={12} sm={6} md={6} lg={6}>
            <FormControl fullWidth>
              <InputLabel>Is Introduction</InputLabel>
              <Select
                className="height45"
                label="Is Introduction"
                value={videoData?.is_introduction || ""}
                name="is_introduction"
                onChange={onChangeField}
              >
                <MenuItem value="true">Yes</MenuItem>
                <MenuItem value="false">No</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6} md={6} lg={6}>
            <FormControl fullWidth>
              <InputLabel>Is Featured</InputLabel>
              <Select
                className="height45"
                label="Is Featured"
                value={videoData?.is_featured || ""}
                name="is_featured"
                onChange={onChangeField}
              >
                <MenuItem value="true">Yes</MenuItem>
                <MenuItem value="false">No</MenuItem>
              </Select>
            </FormControl>
          </Grid>
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <CustomAutoSearch
            idKey="id"
            nameKey="name"
            fieldLabel="Countries"
            apiName="countries"
            responseKey="countries"
            name="countries"
            value={videoData?.countries || null}
            onChange={onChangeAutoComplete}
            error={errors && errors?.countries ? true : false}
            multiple
            required
          />
        </Grid>
      </Grid>
    </MDDialog>
  );
}

export default AddUpdate;
