import MDTypography from "components/MDTypography";

function Index({ message, errorColor }: any) {
  return (
    <MDTypography
      color={errorColor ? errorColor : "error"}
      variant="h6"
      mt={1}
      style={{ fontSize: "0.75rem", fontWeight: 300 }}
    >
      {message}
    </MDTypography>
  );
}
export default Index;
