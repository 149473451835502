import config from "config/config";
import slsApiClient from "config/slsApiClient";
import { useState, useEffect } from "react";
import Icon from "@mui/material/Icon";
import Grid from "@mui/material/Grid";
import MDBox from "components/MDBox";
import MDDialog from "components/MDDialog";
import MDIconButton from "components/MDIconButton";
import MDTypography from "components/MDTypography";
import MDAvatar from "components/MDAvatar";
import { Device } from "@twilio/voice-sdk";
import userDefaultImg from "assets/images/user_default_image.png";
import { useAppSelector, useAppDispatch } from "store/store";
import { setOutBoundLogs, clearOutBoundLogs } from "store/slices/dealSlice";
import { useStopwatch } from "react-timer-hook";

let tempSeconds = 0;
let tempMinutes = 0;
let tempHours = 0;
function WebCalling(props: any): JSX.Element {
  const { toNumber, fromNumber, message, disabled, userBy, dealId } = props;
  const dispatch = useAppDispatch();
  const { outBoundLogs } = useAppSelector((state) => state.dealSlice);
  const [makeCallDialog, setMakeCallDialog] = useState(false);
  const [call, setCall] = useState(null);
  const [connectedDevice, setConnectedDevice] = useState(null);
  const [tryingCall, setTryingCall] = useState(false);
  const [callInprogress, setCallInprogress] = useState(false);
  const { seconds, minutes, hours, start, pause, reset } = useStopwatch({
    autoStart: false,
  });

  const formatTime = (time: any) => String(time).padStart(2, "0");

  useEffect(() => {
    tempSeconds = seconds;
  }, [seconds]);

  useEffect(() => {
    tempMinutes = minutes;
  }, [minutes]);

  useEffect(() => {
    tempHours = hours;
  }, [hours]);

  const dispatchLogMessage = (message: string) => {
    dispatch(setOutBoundLogs(message));
  };

  const makeCall = async () => {
    dispatch(clearOutBoundLogs([]));
    setTryingCall(true);
    dispatchLogMessage("Generating token for call");
    reset();
    pause();
    let token = "";
    await slsApiClient()
      .get(`${config.API_URL}outbound-call-token?phone_number=${fromNumber}`)
      .then(async (response: any) => {
        token = response?.data?.data?.token;
        if (token) {
          dispatchLogMessage("Token generated sucessfully");

          const device: any = new Device(token, {
            logLevel: 1,
          });

          const params: any = {
            To: toNumber,
            FromNumber: fromNumber,
          };

          device.register();

          if (device) {
            setConnectedDevice(device);
            dispatchLogMessage("Call connecting...");
            const tempCall = await device.connect({ params });
            if (tempCall) {
              setTryingCall(false);
              setCallInprogress(true);
              setCall(tempCall);
            } else {
              setTryingCall(false);
              dispatchLogMessage("Connection error, please try again");
            }
          } else {
            setTryingCall(false);
            dispatchLogMessage("Device is not ready to make calls");
          }

          device.on("registered", () => {
            dispatchLogMessage("Device is registered to make calls");
          });

          device.on("ready", () => {
            dispatchLogMessage("Device is ready to make calls");
          });

          device.on("error", (twilioError: any, call: any) => {
            setTryingCall(false);
            console.log("call:", call);
            console.log("An error has occurred: ", twilioError);
            dispatchLogMessage(JSON.stringify(twilioError));
          });
        } else {
          setTryingCall(false);
          dispatchLogMessage("Error During Generating token for call");
        }
      })
      .catch((error: any) => {
        setTryingCall(false);
        console.log("error:", error);
        dispatchLogMessage("Problem while make call");
        setCallInprogress(false);
      });
  };

  const onHangUp = (clearLogs: boolean) => {
    setCallInprogress(false);
    if (clearLogs) {
      dispatch(clearOutBoundLogs([]));
    }
    if (call) {
      call.disconnect();
    } else {
      reset();
      pause();
    }
    if (connectedDevice) {
      connectedDevice.destroy();
      setConnectedDevice(null);
    }
  };

  const onClose = () => {
    onHangUp(true);
    setMakeCallDialog(false);
  };

  const displayLogs = (logs: any) => {
    if (logs?.length) {
      return logs.map((log: any) => (
        <MDTypography fontSize={13} variant="subtitle2" lineHeight="17px">
          {log}
        </MDTypography>
      ));
    }
    return null;
  };

  const saveCallActivity = () => {
    if (tempHours || tempMinutes || tempSeconds) {
      try {
        const activityData = {
          user_by: userBy,
          deal_id: dealId,
          content: `Outbound call from ${fromNumber} to ${toNumber}, Duration ${
            tempHours ? formatTime(tempHours) : ""
          }${formatTime(tempMinutes)}:${formatTime(tempSeconds)}`,
        };
        slsApiClient()
          .post(`${config.API_URL}create-deal-activity`, activityData)
          .then(() => {
            tempSeconds = 0;
            tempMinutes = 0;
            tempHours = 0;
          });
      } catch (e: any) {
        tempSeconds = 0;
        tempMinutes = 0;
        tempHours = 0;
        console.log("activity error", e);
      }
    }
    reset();
    pause();
  };

  useEffect(() => {
    if (call) {
      call.on("accept", () => {
        console.log("call accept");
        start();
      });
      call.on("connect", () => console.log("connected"));
      call.on("disconnect", (e: any) => {
        console.log("disconnected", e);
        dispatchLogMessage("Call Disconnected");
        onHangUp(false);
        saveCallActivity();
        setCall(null);
      });
      call.on("cancel", () => {
        setCallInprogress(false);
        console.log("Call Canceled");
        onHangUp(false);
        saveCallActivity();
        setCall(null);
      });
    }
  }, [call]);

  const makeCallDialogProps = {
    open: makeCallDialog,
    onClose: () => onClose(),
    onSave: () => makeCall(),
    title: "Outbound Call",
    size: "xs",
    saveBtn: false,
  };

  return (
    <MDBox>
      <MDIconButton
        tooltipName={message}
        aria-label={message}
        onClick={() => setMakeCallDialog(true)}
        className="add-new-btn"
        disabled={disabled}
      >
        <Icon fontSize="small" color="info">
          phone_forwarded_icon
        </Icon>
      </MDIconButton>
      {makeCallDialog && (
        <MDDialog {...makeCallDialogProps}>
          <Grid container spacing={1} mb={1}>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <MDBox className="web-calling">
                {callInprogress && (
                  <MDTypography fontSize={12}>
                    {hours ? `${formatTime(hours)}:` : ""}
                    {formatTime(minutes)}:{formatTime(seconds)}
                  </MDTypography>
                )}
                <MDBox className="avatar-img">
                  <MDAvatar src={userDefaultImg} alt="image" size="xl" shadow="sm" />
                </MDBox>
                <MDBox className="call-btns">
                  <MDIconButton
                    tooltipName="Make call"
                    aria-label="Make call"
                    onClick={makeCall}
                    className="add-new-btn"
                    disabled={callInprogress}
                  >
                    <Icon fontSize="small" color={callInprogress ? "inherit" : "success"}>
                      call
                    </Icon>
                  </MDIconButton>
                  {tryingCall && <span className="web-calling-loader" />}
                  {!tryingCall && callInprogress && (
                    <Icon fontSize="small" color="info">
                      sync_alt_icon
                    </Icon>
                  )}
                  <MDIconButton
                    tooltipName="Hang Up"
                    aria-label="Hang Up"
                    onClick={() => onHangUp(true)}
                    className="add-new-btn"
                    disabled={!callInprogress || tryingCall}
                  >
                    <Icon fontSize="small" color={!callInprogress ? "inherit" : "error"}>
                      call_end
                    </Icon>
                  </MDIconButton>
                </MDBox>
              </MDBox>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <MDTypography fontSize={14} variant="subtitle2" fontWeight="bold">
                From : {fromNumber}
              </MDTypography>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <MDTypography fontSize={14} variant="subtitle2" fontWeight="bold">
                To: {toNumber}
              </MDTypography>
            </Grid>
            {outBoundLogs?.length > 0 && (
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <MDTypography fontSize={13} variant="subtitle2" lineHeight="17px">
                  Logs:
                </MDTypography>
                {displayLogs(outBoundLogs)}
              </Grid>
            )}
          </Grid>
        </MDDialog>
      )}
    </MDBox>
  );
}

export default WebCalling;
