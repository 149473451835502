import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

function ContacInfo(props: any): JSX.Element {
  const { accountDetail, onChangeRadio } = props;

  return (
    <Paper>
      <MDBox p={2} mt={2}>
        <MDBox mb={1}>
          <MDTypography variant="h5" fontWeight="regular">
            Contact Info
          </MDTypography>
        </MDBox>
        <MDBox>
          <Grid container spacing={3}>
            <Grid item xs={12} md={12}>
              <FormControlLabel
                control={
                  <Switch
                    checked={accountDetail?.contact_info_same_as_personal || false}
                    onChange={onChangeRadio}
                    inputProps={{ "aria-label": "controlled" }}
                    name="contact_info_same_as_personal"
                  />
                }
                label="Same as personal contact info?"
                labelPlacement="end"
              />
            </Grid>

            {!accountDetail?.contact_info_same_as_personal && (
              <>
                <Grid item xs={12} sm={12}>
                  <MDTypography variant="h6">Website Contact</MDTypography>
                </Grid>
                <Grid item xs={12} sm={12}>
                  <MDTypography variant="h6">Public Contact Name</MDTypography>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={accountDetail?.not_display_name || false}
                        onChange={onChangeRadio}
                        inputProps={{ "aria-label": "controlled" }}
                        name="not_display_name"
                      />
                    }
                    label="Do not display a name on the site"
                    labelPlacement="end"
                  />
                </Grid>
                <Grid item xs={12} sm={12}>
                  <MDTypography variant="h6">Public Phone</MDTypography>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={accountDetail?.not_deisplay_phone || false}
                        onChange={onChangeRadio}
                        inputProps={{ "aria-label": "controlled" }}
                        name="not_deisplay_phone"
                      />
                    }
                    label="Do not display a phone number on the site"
                    labelPlacement="end"
                  />
                </Grid>
                <Grid item xs={12} sm={12}>
                  <MDTypography variant="h6">Public Email</MDTypography>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={accountDetail?.not_display_email || false}
                        onChange={onChangeRadio}
                        inputProps={{ "aria-label": "controlled" }}
                        name="not_display_email"
                      />
                    }
                    label="Do not display a email on the site"
                    labelPlacement="end"
                  />
                </Grid>
              </>
            )}
          </Grid>
        </MDBox>
      </MDBox>
    </Paper>
  );
}

export default ContacInfo;
