import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import CustomAutoSearch from "components/CustomAutoSearch";
import { Checkbox, FormControl, FormControlLabel, FormGroup } from "@mui/material";
import { getPermission, getAllAssociationLocations, adminSuperAdmin } from "helper/services";
import MESSAGES from "helper/messages";

function Business(props: any): JSX.Element {
  const { solaProDetail, onChangeField, onCheckboxChange, onChangeAutoComplete, errors } = props;
  return (
    <Card id="business">
      <MDBox p={3}>
        <MDTypography variant="h5">Business</MDTypography>
      </MDBox>
      <MDBox pb={3} px={3}>
        <Grid container spacing={3}>
          <Grid item className="location" xs={12} sm={6} md={6} lg={6}>
            <CustomAutoSearch
              idKey="id"
              nameKey="name"
              fieldLabel="Locations"
              apiName="locations"
              responseKey="locations"
              name="location"
              required
              value={solaProDetail?.location || null}
              onChange={onChangeAutoComplete}
              error={errors && errors.location}
              createNewLink={
                adminSuperAdmin() && getPermission("locations", "locations_location")
                  ? "/locations"
                  : ""
              }
              usersData={getAllAssociationLocations()}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={6} lg={6}>
            <MDInput
              label="Salon Name"
              variant="standard"
              fullWidth
              name="business_name"
              placeholder="Salon Name"
              onChange={onChangeField}
              value={solaProDetail?.business_name || ""}
              error={errors && errors.business_name ? true : false}
              helperText={errors && errors.business_name}
              infoText={MESSAGES.INFO.OPTIONAL_LENGTH_255}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={6} lg={6}>
            <MDInput
              multiline
              rows="3"
              variant="standard"
              label="Work Hours"
              fullWidth
              name="work_hours"
              placeholder="Work Hours"
              onChange={onChangeField}
              value={solaProDetail?.work_hours || ""}
              infoText={MESSAGES.INFO.OPTIONAL}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={6} lg={6} display="flex" alignItems="end">
            <MDInput
              label="Studio Number"
              variant="standard"
              fullWidth
              type="text"
              name="studio_number"
              placeholder="Studio Number"
              onChange={onChangeField}
              value={solaProDetail?.studio_number || ""}
              error={errors && errors.studio_number ? true : false}
              helperText={errors && errors.studio_number}
              infoText={MESSAGES.INFO.OPTIONAL_LENGTH_255}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={6} lg={6}>
            <FormControl component="fieldset">
              <FormGroup aria-label="position">
                <FormControlLabel
                  value="end"
                  control={
                    <Checkbox
                      checked={solaProDetail?.accepting_new_clients || false}
                      name="accepting_new_clients"
                      onChange={onCheckboxChange}
                      inputProps={{ "aria-label": "controlled" }}
                    />
                  }
                  label="Accepting New Clients"
                  labelPlacement="end"
                />
              </FormGroup>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6} md={6} lg={6}>
            <FormControl component="fieldset">
              <FormGroup aria-label="position">
                <FormControlLabel
                  value="end"
                  control={
                    <Checkbox
                      checked={solaProDetail?.walkins || false}
                      name="walkins"
                      onChange={onCheckboxChange}
                      inputProps={{ "aria-label": "controlled" }}
                    />
                  }
                  label="Walkins"
                  labelPlacement="end"
                />
              </FormGroup>
            </FormControl>
          </Grid>
        </Grid>
      </MDBox>
    </Card>
  );
}

export default Business;
