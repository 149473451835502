import MDTypography from "components/MDTypography";

function Index({ label }: any) {
  return (
    <MDTypography component="label" variant="button" fontWeight="regular" color="text">
      {label}
    </MDTypography>
  );
}
export default Index;
