import { Card } from "@mui/material";

import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import FormField from "layouts/pages/account/components/FormField";
// import MDDatePicker from "components/MDDatePicker";
import TimePicker from "rc-time-picker";
import "rc-time-picker/assets/index.css";
// import moment from "moment";

function CommunicationDelay(): JSX.Element {
  return (
    <Card id="communication-delay" sx={{ overflow: "visible" }}>
      <MDBox p={3}>
        <MDTypography variant="h5">Communication Delay</MDTypography>
      </MDBox>
      <MDBox component="form" pb={3} px={3}>
        <div>
          <MDTypography variant="subtitle2">
            If Sola Pro Hasn&apos;t Completed The Survey...
          </MDTypography>
        </div>
        <div className="container-communication-delay timPickerClass">
          <div className="communication-delay-item">
            <MDTypography variant="subtitle2">Send follow-up #1</MDTypography>
            <FormField
              sx={{ width: "100px", marginLeft: 1, marginRight: 1 }}
              size="small"
              label=""
              variant="outlined"
            />
            <MDTypography variant="subtitle2">Day(s) from initial contact at,</MDTypography>
            <TimePicker
              className="time-picker"
              showSecond={false}
              format="h:mm A"
              use12Hours
              inputReadOnly
            />
          </div>
          <div className="communication-delay-item timPickerClass">
            <MDTypography variant="subtitle2">Send follow-up #2</MDTypography>
            <FormField
              sx={{ width: "100px", marginLeft: 1, marginRight: 1 }}
              size="small"
              label=""
              variant="outlined"
            />
            <MDTypography variant="subtitle2">Day(s) from initial contact at,</MDTypography>
            <TimePicker
              className="time-picker"
              showSecond={false}
              format="h:mm A"
              use12Hours
              inputReadOnly
            />
          </div>
          <div className="communication-delay-item timPickerClass">
            <MDTypography variant="subtitle2">Send follow-up #3</MDTypography>
            <FormField
              sx={{ width: "100px", marginLeft: 1, marginRight: 1 }}
              size="small"
              label=""
              variant="outlined"
            />
            <MDTypography variant="subtitle2">Day(s) from initial contact at,</MDTypography>
            <TimePicker
              className="time-picker"
              showSecond={false}
              format="h:mm A"
              use12Hours
              inputReadOnly
            />
          </div>
        </div>
      </MDBox>
    </Card>
  );
}

export default CommunicationDelay;
