import React, { useEffect, useState } from "react";
import MDDialog from "components/MDDialog";
import MDInput from "components/MDInput";
import Validations from "helper/validations";
import { createBlog } from "store/thunk/solaWebsiteThunk";
import { useAppDispatch } from "store/store";
import {
  FormControl,
  Grid,
  RadioGroup,
  Radio,
  FormControlLabel,
  Icon,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@mui/material";
import MDEditor from "components/MDEditor";
import MDTypography from "components/MDTypography";
import CustomDropZone from "components/CustomDropZone";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import MDDatePicker from "components/MDDatePicker";
import CustomRequiredLabel from "components/CustomRequiredLabel";
import CustomErrorMessage from "components/CustomErrorMessage";
import moment from "moment";
import ImagePreview from "layouts/pages/ImagePreview";
import constants from "helper/constants";
import { scrollToError, getPermission, convertImageToWebp } from "helper/services";
import CustomAutoSearch from "components/CustomAutoSearch";
import MDIconButton from "components/MDIconButton";

function AddUpdate(props: any): JSX.Element {
  const dispatch = useAppDispatch();
  const { open, onClose, updateData, pageNo, perPage, search, sortBy } = props;
  const [blogData, setblogData] = useState<any>({});
  const [isUpdate, setIsUpdate] = useState<any>(false);
  const [errors, setErrors] = useState<any>({});
  const [imagePreview, setImagePreview] = useState<any>("");
  const [imagePreviewCarousel, setImagePreviewCarousel] = useState<any>("");
  const [expanded, setExpanded] = React.useState<string | false>(false);
  const [isPreview, setImagePreviewPopup] = useState(false);
  const [imageToPreview, setImageToPreview] = useState("");

  const handleChangeAccordian =
    (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : false);
    };

  const openAccordian = (accordianElementId: any, accordianExpandName: any) => {
    const element = document.getElementById(accordianElementId);
    element.addEventListener("change", () => {
      setExpanded(accordianExpandName);
    });
    const event = new Event("change", {
      bubbles: true,
      cancelable: true,
      composed: false,
    });
    element.dispatchEvent(event);
  };

  const onSave = () => {
    const error = Validations.validateBlogForm(blogData);
    setErrors(error);
    if (!Object.keys(error).length) {
      const req = {
        isUpdate,
        blogData,
        onClose,
        pageNo,
        perPage,
        search,
        sortBy,
      };
      dispatch(createBlog(req));
    } else {
      scrollToError(error);
    }
    if (error?.status && error?.carousel_text) {
      openAccordian("publish-accordian", "error");
    } else if (error?.status) {
      openAccordian("publish-accordian", "publish");
    } else if (error?.carousel_text) {
      openAccordian("carousel-accordian", "carousel");
    }
  };

  useEffect(() => {
    if (updateData) {
      setIsUpdate(true);
      // SD-3424
      const updateLocations = updateData?.locations?.map((obj: any) => ({
        id: obj?.id,
        name: obj?.name,
      }));
      setblogData({ ...updateData, location_ids: updateLocations || [] });

      if (updateData?.image_url) setImagePreview(updateData.image_url);

      if (updateData?.carousel_image_url) setImagePreviewCarousel(updateData.carousel_image_url);
    }
  }, [updateData]);

  const onChangeImage = async (files: any, type: any) => {
    if (files[0]) {
      const file = await convertImageToWebp(files[0], "blog", dispatch);
      const reader = new FileReader();
      reader.onload = function (e) {
        if (type === "image_url") {
          setImagePreview(e.target.result);
          setblogData({ ...blogData, [type]: file, delete_image: 0 });
        } else {
          setImagePreviewCarousel(e.target.result);
          setblogData({ ...blogData, [type]: file, delete_carousel_image: 0 });
        }
      };
      reader.readAsDataURL(file);
    }
  };

  const onChangeField = (e: any, field: string) => {
    setblogData({ ...blogData, [field]: e.target.value });
  };
  const onChangeEditor = (value: any) => {
    setblogData({ ...blogData, body: value });
  };

  const handleChangeRaidoBtn = (event: React.ChangeEvent<HTMLInputElement>) => {
    setblogData({ ...blogData, [event.target.name]: event.target.value });
  };

  const handleDateChange = (date: any) => {
    const utcDate = moment.utc(new Date(date)).format();
    setblogData({ ...blogData, publish_date: utcDate });
  };

  const onImageRemove = async (e: any, type: any) => {
    e.stopPropagation();
    setblogData({ ...blogData });
    if (type === "image_url") {
      setImagePreview("");
      setblogData({ ...blogData, [type]: null, delete_image: 1 });
    } else {
      setImagePreviewCarousel("");
      setblogData({ ...blogData, [type]: null, delete_carousel_image: 1 });
    }
  };

  const previewImage = (image: any) => {
    setImagePreviewPopup(true);
    setImageToPreview(image);
  };

  const closeImagePreview = () => {
    setImagePreviewPopup(false);
    setImageToPreview("");
  };

  const onChangeAutoComplete = (name: string, value: any) => {
    setblogData({ ...blogData, [name]: value });
  };

  const dialogProps = {
    open,
    onClose,
    onSave,
    title: isUpdate ? "Update Blog" : "Add Blog",
    size: "lg",
    saveTbtText: isUpdate ? "Update" : "Save",
  };

  return (
    <MDDialog {...dialogProps}>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6} md={6}>
          <MDInput
            label="Title"
            variant="standard"
            required
            fullWidth
            name="title"
            placeholder="title"
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => onChangeField(e, "title")}
            value={blogData?.title ? blogData.title : ""}
            error={errors && errors.title ? true : false}
            helperText={errors && errors.title}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={6}>
          <MDInput
            label="URL Name"
            variant="standard"
            required
            fullWidth
            name="url_name"
            placeholder="Url name"
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => onChangeField(e, "url_name")}
            value={blogData?.url_name ? blogData.url_name : ""}
            error={errors && errors.url_name ? true : false}
            helperText={errors && errors.url_name}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={6}>
          <MDInput
            label="Canonical URL Name"
            variant="standard"
            fullWidth
            name="canonical_url"
            placeholder="Canonical URL Name"
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => onChangeField(e, "canonical_url")}
            value={blogData?.canonical_url ? blogData.canonical_url : ""}
            error={errors && errors.canonical_url ? true : false}
            helperText={errors && errors.canonical_url}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={6}>
          <MDInput
            label="Author"
            variant="standard"
            fullWidth
            name="author"
            placeholder="Author"
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => onChangeField(e, "author")}
            value={blogData?.author ? blogData.author : ""}
            error={errors && errors.author ? true : false}
            helperText={errors && errors.author}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={12}>
          <MDTypography variant="button">Image</MDTypography>
          <Grid container mt={2}>
            <Grid item xs={12} sm={8} md={6}>
              <CustomDropZone
                multipleUpload={false}
                acceptedFiles={{
                  "image/*": [".gif", ".png", ".jpg", ".jpeg"],
                }}
                onDrop={(e: any) => onChangeImage(e, "image_url")}
              >
                Drop your image here, or Browse
              </CustomDropZone>
            </Grid>
            <Grid item xs={12} sm={4} md={3}>
              {imagePreview && (
                <Grid
                  container
                  onClick={() => previewImage(imagePreview)}
                  justifyContent="center"
                  alignItems="center"
                  display="flex"
                >
                  <Grid item className="imageWithDeleteIcon">
                    <img height={100} width={100} src={imagePreview} alt="blog" />
                    <MDIconButton
                      tooltipName="Delete"
                      aria-label="Delete"
                      className="removeBtnClass"
                      onClick={(e: any) => onImageRemove(e, "image_url")}
                    >
                      <Icon fontSize="small">close</Icon>
                    </MDIconButton>
                  </Grid>
                </Grid>
              )}
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} sm={6} md={6}>
          <MDInput
            label="Meta description"
            fullWidth
            multiline
            rows={3}
            InputLabelProps={{ shrink: true }}
            name="meta_description"
            onChange={(e: any) => onChangeField(e, "meta_description")}
            value={blogData?.meta_description ? blogData.meta_description : ""}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={6}>
          <MDInput
            label="Summary"
            fullWidth
            multiline
            rows={3}
            InputLabelProps={{ shrink: true }}
            name="summary"
            onChange={(e: any) => onChangeField(e, "summary")}
            value={blogData?.summary ? blogData.summary : ""}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={12}>
          <MDTypography variant="button">Body</MDTypography>
          <MDEditor
            value={blogData?.body ? blogData.body : ""}
            onChange={(value: any) => onChangeEditor(value)}
            modules={constants.editorModules}
            formats={constants.editorFormats}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={6}>
          <CustomAutoSearch
            idKey="id"
            nameKey="name"
            fieldLabel="Categories"
            apiName="categories"
            responseKey="categories"
            name="categories"
            value={blogData?.categories || []}
            onChange={onChangeAutoComplete}
            multiple
            createNewLink={
              getPermission("sola_website", "sola_website_categories")
                ? "/sola-website/categories"
                : ""
            }
          />
        </Grid>
        <Grid item xs={12} sm={6} md={6}>
          <CustomAutoSearch
            idKey="id"
            nameKey="name"
            fieldLabel="Tags"
            apiName="tags"
            responseKey="tags"
            name="tags"
            value={blogData?.tags || []}
            onChange={onChangeAutoComplete}
            multiple
            createNewLink={
              getPermission("sola_website", "sola_website_tags") ? "/sola-website/tags" : ""
            }
          />
        </Grid>
        <Grid item xs={12} sm={12} md={6}>
          <FormControl>
            <MDTypography variant="button">Contact form visible</MDTypography>
            <RadioGroup
              row
              name="contact_form_visible"
              value={blogData?.contact_form_visible ? "yes" : "no"}
              onChange={handleChangeRaidoBtn}
            >
              <FormControlLabel value="yes" control={<Radio />} label="Yes" />
              <FormControlLabel value="no" control={<Radio />} label="No" />
            </RadioGroup>
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={12} md={6}>
          <CustomAutoSearch
            idKey="id"
            nameKey="name"
            fieldLabel="Countries"
            apiName="countries"
            responseKey="countries"
            name="countries"
            value={blogData?.countries || []}
            onChange={onChangeAutoComplete}
            error={(errors && errors.countries) || ""}
            required
            multiple
          />
        </Grid>
        {/* SD-3424 */}
        <Grid item xs={12} sm={12} md={6}>
          <CustomAutoSearch
            idKey="id"
            nameKey="name"
            fieldLabel="Locations"
            apiName="locations"
            responseKey="locations"
            name="location_ids"
            error={errors && errors.location_ids ? true : false}
            value={blogData?.location_ids || null}
            onChange={onChangeAutoComplete}
            multiple
          />
        </Grid>
        <Grid item xs={12} md={12}>
          <Accordion
            expanded={expanded === "publish" || expanded === "error"}
            sx={{ marginTop: "20px" }}
            id="publish-accordian"
            onChange={handleChangeAccordian("publish")}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="publishbh-content"
              id="publishbh-header"
              className="location-accordion"
            >
              <MDTypography variant="h6">Publish</MDTypography>
            </AccordionSummary>
            <AccordionDetails>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6} md={6}>
                  <FormControl>
                    <CustomRequiredLabel label="Status" />
                    <RadioGroup
                      row
                      name="status"
                      value={blogData?.status ? blogData?.status : ""}
                      onChange={handleChangeRaidoBtn}
                    >
                      <FormControlLabel value="published" control={<Radio />} label="Published" />
                      <FormControlLabel value="draft" control={<Radio />} label="Draft" />
                    </RadioGroup>
                  </FormControl>
                  {errors && errors.status && <CustomErrorMessage message={errors.status} />}
                </Grid>
                <Grid item xs={12} sm={6} md={6}>
                  <FormControl fullWidth>
                    <MDTypography variant="button">Publish date</MDTypography>
                    <MDDatePicker
                      value={blogData?.publish_date ? blogData?.publish_date : null}
                      fullWidth
                      options={{
                        enableTime: true,
                        dateFormat: "M d, Y H:i",
                        time_24hr: true,
                      }}
                      onChange={([date]: any) => handleDateChange(date)}
                    />
                  </FormControl>
                </Grid>
              </Grid>
            </AccordionDetails>
          </Accordion>
          <Accordion
            expanded={expanded === "carousel" || expanded === "error"}
            sx={{ marginTop: "20px" }}
            id="carousel-accordian"
            onChange={handleChangeAccordian("carousel")}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="carouselbh-content"
              id="carouselbh-header"
              className="location-accordion"
            >
              <MDTypography variant="h6">Carousel</MDTypography>
            </AccordionSummary>
            <AccordionDetails>
              <Grid item xs={12} sm={12} md={12} mb={2}>
                <MDTypography variant="button">Carousel Image</MDTypography>
                <Grid container mt={2}>
                  <Grid item xs={12} sm={8} md={4}>
                    <CustomDropZone
                      multipleUpload={false}
                      acceptedFiles={{
                        "image/*": [".gif", ".png", ".jpg", ".jpeg"],
                      }}
                      onDrop={(e: any) => onChangeImage(e, "carousel_image_url")}
                    >
                      Drop your image here, or Browse
                    </CustomDropZone>
                  </Grid>
                  <Grid item xs={12} sm={4} md={2}>
                    {imagePreviewCarousel && (
                      <Grid
                        container
                        onClick={() => previewImage(imagePreviewCarousel)}
                        justifyContent="center"
                        alignItems="center"
                        textAlign="center"
                      >
                        <Grid item className="imageWithDeleteIcon">
                          <img height={100} width={100} src={imagePreviewCarousel} alt="blog" />
                          <MDIconButton
                            tooltipName="Delete"
                            aria-label="Delete"
                            className="removeBtnClass"
                            onClick={(e: any) => onImageRemove(e, "carousel_image_url")}
                          >
                            <Icon fontSize="small">close</Icon>
                          </MDIconButton>
                        </Grid>
                      </Grid>
                    )}
                  </Grid>
                  <Grid item xs={12} sm={12} md={6}>
                    <MDInput
                      fullWidth
                      label="Carousel Text"
                      variant="standard"
                      name="carousel_text"
                      placeholder="Carousel Text"
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                        onChangeField(e, "carousel_text")
                      }
                      value={blogData?.carousel_text ? blogData.carousel_text : ""}
                      error={errors && errors.carousel_text ? true : false}
                      helperText={errors && errors.carousel_text}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </AccordionDetails>
          </Accordion>
          <Accordion
            expanded={expanded === "fb_conversion_pixel"}
            sx={{ marginTop: "20px" }}
            onChange={handleChangeAccordian("fb_conversion_pixel")}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="fb_conversion_pixelbh-content"
              id="fb_conversion_pixelbh-header"
              className="location-accordion"
            >
              <MDTypography variant="h6">Tracking</MDTypography>
            </AccordionSummary>
            <AccordionDetails>
              <Grid container spacing={2} mt={1}>
                <Grid item xs={12} md={6}>
                  <MDInput
                    label="Fb Conversion Pixel"
                    fullWidth
                    multiline
                    rows={3}
                    InputLabelProps={{ shrink: true }}
                    name="fb_conversion_pixel"
                    onChange={(e: any) => onChangeField(e, "fb_conversion_pixel")}
                    value={blogData?.fb_conversion_pixel ? blogData.fb_conversion_pixel : ""}
                  />
                </Grid>
              </Grid>
            </AccordionDetails>
          </Accordion>
        </Grid>
      </Grid>
      <ImagePreview
        imageData={imageToPreview}
        open={isPreview}
        onCloseImage={() => {
          closeImagePreview();
        }}
      />
    </MDDialog>
  );
}

export default AddUpdate;
