import { Icon } from "@mui/material";
import MDDialog from "components/MDDialog";
import MDIconButton from "components/MDIconButton";
import DataTable from "examples/Tables/DataTable";
import { getLocalDateTime } from "helper/services";
import { useEffect, useState } from "react";
import { useAppSelector } from "store/store";
// import MDButton from "components/MDButton";
import PageSection from "../pageSection";
// import AddUpdateSubmenu from "./addUpdate";

function ViewSubMenu(props: any): JSX.Element {
  const { open, onClose, subMenus, viewMenuId } = props;
  const [subMenuViewList, setSubMenuViewList] = useState(subMenus);
  const menusList = useAppSelector((state) => state.rolesAndMenusReducer);
  // const [addEditMenuPopup, setAddEditMenuPopup] = useState(false);
  // const [editSubmenuId, setEditSubmenuId] = useState(null);
  const [menu, setMenu] = useState(null);

  useEffect(() => {
    const updatedList = menusList.menus.find((val: any) => val.menu_id === viewMenuId);
    setSubMenuViewList(updatedList);
  }, [menusList]);

  const subMenuList: any = [];
  subMenuViewList.child_menu.map((val: any) => {
    subMenuList.push({
      alias: val.alias,
      display_name: val.display_menu,
      description: val.description ? val.description : "-",
      updated_at: getLocalDateTime(val.updated_at, "MM/DD/YYYY"),
      action: (
        <>
          {/* <MDIconButton
            tooltipName="Edit"
            aria-label="Edit"
            color="info"
            onClick={() => {
              setEditSubmenuId(val.menu_id);
              setAddEditMenuPopup(true);
            }}
          >
            <Icon fontSize="small">edit</Icon>
          </MDIconButton> */}
          {val?.page_sections?.length > 0 && (
            <MDIconButton
              tooltipName="Page Section"
              aria-label="Page Section"
              color="info"
              onClick={() => {
                setMenu(val);
              }}
            >
              <Icon fontSize="small">lock_reset</Icon>
            </MDIconButton>
          )}
        </>
      ),
    });
    return true;
  });

  const submenuTableData = {
    columns: [
      { Header: "alias", accessor: "alias" },
      { Header: "display name", accessor: "display_name" },
      { Header: "description", accessor: "description" },
      { Header: "updated at", accessor: "updated_at" },
      { Header: "Action", accessor: "action", disableSortBy: true },
    ],
    rows: subMenuList,
  };

  const dialogProps = {
    open,
    onClose,
    title: "Sub menus",
    size: "md",
    saveBtn: false,
  };

  return (
    <MDDialog {...dialogProps}>
      {/* <MDButton
        variant="gradient"
        color="light"
        size="small"
        onClick={() => {
          setAddEditMenuPopup(true);
          setEditSubmenuId(null);
        }}
      >
        <Icon sx={{ fontWeight: "bold" }}>add</Icon> Add Submenu
      </MDButton> */}
      <DataTable table={submenuTableData} canSearch />
      {/* {addEditMenuPopup && (
        <AddUpdateSubmenu
          open={addEditMenuPopup}
          onClose={() => setAddEditMenuPopup(false)}
          subMenus={subMenuViewList}
          editSubmenuId={editSubmenuId}
        />
      )} */}
      {menu && (
        <PageSection
          open={menu ? true : false}
          onClose={() => {
            setMenu(null);
          }}
          menu={menu}
        />
      )}
    </MDDialog>
  );
}

export default ViewSubMenu;
