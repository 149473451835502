import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";

import MDBox from "components/MDBox";
import MDInput from "components/MDInput";
import MDTypography from "components/MDTypography";

function Integrations(props: any): JSX.Element {
  const { locationDetail, onChangeField, getAccessTypePermission } = props;

  return (
    <Card id="integration" sx={{ overflow: "visible" }}>
      <MDBox p={3}>
        <MDTypography variant="h5">Integrations</MDTypography>
      </MDBox>
      <MDBox component="form" pb={3} px={3}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            <MDInput
              multiline
              rows="3"
              variant="outlined"
              label="Mailchimp List IDs"
              fullWidth
              name="mailchimp_list_ids"
              placeholder="Mailchimp List IDs"
              onChange={onChangeField}
              value={locationDetail?.mailchimp_list_ids || ""}
              disabled={getAccessTypePermission("location_general", "edit_access")}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <MDInput
              multiline
              rows="3"
              variant="outlined"
              label="Callfire List IDs"
              fullWidth
              name="callfire_list_ids"
              placeholder="Callfire List IDs"
              onChange={onChangeField}
              value={locationDetail?.callfire_list_ids || ""}
              disabled={getAccessTypePermission("location_general", "edit_access")}
            />
          </Grid>
        </Grid>
      </MDBox>
    </Card>
  );
}

export default Integrations;
