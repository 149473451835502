import { Card, Grid } from "@mui/material";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import { isFranchisie } from "helper/services";

function SocialLinks(props: any): JSX.Element {
  const { editPermission, onChangeField, locationDetail } = props;
  return (
    <Card id="social-links" sx={{ overflow: "visible" }}>
      <MDBox p={3}>
        <MDTypography variant="h5">Social Links</MDTypography>
      </MDBox>
      <MDBox component="form" pb={3} px={3}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            <MDInput
              label="Facebook URL"
              placeholder="Facebook URL"
              name="facebook_url"
              fullWidth
              variant="standard"
              value={locationDetail?.facebook_url || ""}
              onChange={onChangeField}
              disabled={!editPermission && !isFranchisie()}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <MDInput
              label="Instagram URL"
              placeholder="Instagram URL"
              name="instagram_url"
              fullWidth
              variant="standard"
              value={locationDetail?.instagram_url || ""}
              onChange={onChangeField}
              disabled={!editPermission && !isFranchisie()}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <MDInput
              label="Twitter URL"
              placeholder="Twitter URL"
              name="twitter_url"
              fullWidth
              variant="standard"
              value={locationDetail?.twitter_url || ""}
              onChange={onChangeField}
              disabled={!editPermission && !isFranchisie()}
            />
          </Grid>
        </Grid>
      </MDBox>
    </Card>
  );
}

export default SocialLinks;
