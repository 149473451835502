import React, { useEffect, useState } from "react";
import MDDialog from "components/MDDialog";
import MDInput from "components/MDInput";
import Validations from "helper/validations";
// import MDBox from "components/MDBox";
import { Checkbox, FormControl, FormControlLabel, FormGroup, Grid } from "@mui/material";
import CustomPhoneInput from "components/CustomPhoneInput";
import { useAppDispatch } from "store/store";
import { saveFranchiseeInquiry } from "store/thunk/franchiseeWebsiteThunk";

function AddUpdate(props: any): JSX.Element {
  const dispatch = useAppDispatch();
  const { open, onClose, editInquiries, pageNo, perPage, search, sortBy, country } = props;
  const [loading] = useState<boolean>(false);
  const [inquiryData, setInquiryData] = useState<any>({});
  const [errors, setErrors] = useState<any>({});
  const [isUpdate, setIsUpdate] = useState<any>(false);

  useEffect(() => {
    if (editInquiries) {
      setIsUpdate(true);
      setInquiryData({
        id: editInquiries && editInquiries.id,
        first_name: editInquiries.first_name || "",
        last_name: editInquiries.last_name || "",
        email_address: editInquiries.email_address || "",
        phone_number: editInquiries.phone_number || "",
        multi_unit_operator: editInquiries.multi_unit_operator || false,
        liquid_capital: editInquiries.liquid_capital || "",
        city: editInquiries.city || "",
        state: editInquiries.state || "",
        agree_to_receive_email: editInquiries.agree_to_receive_email || false,
        utm_source: editInquiries.utm_source || "",
        utm_campaign: editInquiries.utm_campaign || "",
        utm_medium: editInquiries.utm_medium || "",
        utm_content: editInquiries.utm_content || "",
        utm_term: editInquiries.utm_term || "",
        country: editInquiries.country || "",
      });
    }
  }, [editInquiries]);

  const onSave = () => {
    const error = Validations.validateFranchiseeInquiryForm(inquiryData);
    setErrors(error);
    if (!Object.keys(error).length) {
      const req = {
        inquiryData,
        onClose,
        isUpdate,
        pageNo,
        perPage,
        search,
        sortBy,
        country: country ? country.toLowerCase() : "",
      };
      dispatch(saveFranchiseeInquiry(req));
    }
  };

  const onChangeField = (e: any, field: string) => {
    setInquiryData((prev: any) => ({
      ...prev,
      [field]: e.target.value,
    }));
  };

  const handleSingleCheckbox = (event: React.ChangeEvent<HTMLInputElement>) => {
    setInquiryData({ ...inquiryData, [event.target.name]: event.target.checked });
  };

  const dialogProps = {
    open,
    onClose,
    onSave,
    saveTbtText: editInquiries ? "Update" : "Save",
    title: editInquiries ? "Update Franchisee Inquiries" : "Add Franchisee Inquiries",
    size: "lg",
    loading,
  };

  return (
    <MDDialog {...dialogProps}>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12} md={6} lg={6}>
          <MDInput
            label="First Name"
            variant="standard"
            fullWidth
            required
            name="first_name"
            placeholder=""
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => onChangeField(e, "first_name")}
            value={inquiryData?.first_name || ""}
            error={(errors && errors.first_name) || false}
            helperText={errors && errors.first_name}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6}>
          <MDInput
            label="Last Name"
            variant="standard"
            fullWidth
            required
            name="last_name"
            placeholder=""
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => onChangeField(e, "last_name")}
            value={inquiryData?.last_name || ""}
            error={(errors && errors.last_name) || false}
            helperText={errors && errors.last_name}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6}>
          <MDInput
            label="Email Address"
            variant="standard"
            fullWidth
            required
            name="email_address"
            placeholder=""
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => onChangeField(e, "email_address")}
            value={inquiryData?.email_address || ""}
            error={(errors && errors.email_address) || false}
            helperText={errors && errors.email_address}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6}>
          <MDInput
            label="Phone Number"
            variant="standard"
            fullWidth
            required
            name="phone_number"
            InputProps={{
              inputComponent: CustomPhoneInput as any,
            }}
            placeholder=""
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => onChangeField(e, "phone_number")}
            value={inquiryData?.phone_number || ""}
            error={(errors && errors.phone_number) || false}
            helperText={errors && errors.phone_number}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <FormControl component="fieldset">
            <FormGroup aria-label="position">
              <FormControlLabel
                value="end"
                control={
                  <Checkbox
                    checked={inquiryData?.multi_unit_operator || false}
                    name="multi_unit_operator"
                    onChange={handleSingleCheckbox}
                    inputProps={{ "aria-label": "controlled" }}
                  />
                }
                label="Multi Unit Operator"
                labelPlacement="end"
              />
            </FormGroup>
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6}>
          <MDInput
            label="Liquid Capital"
            variant="standard"
            fullWidth
            required
            name="liquid_capital"
            placeholder=""
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              onChangeField(e, "liquid_capital")
            }
            value={inquiryData?.liquid_capital || ""}
            error={(errors && errors.liquid_capital) || false}
            helperText={errors && errors.liquid_capital}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6}>
          <MDInput
            label="City"
            variant="standard"
            fullWidth
            required
            name="city"
            placeholder=""
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => onChangeField(e, "city")}
            value={inquiryData?.city || ""}
            error={(errors && errors.city) || false}
            helperText={errors && errors.city}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6}>
          <MDInput
            label="State"
            variant="standard"
            fullWidth
            required
            name="state"
            placeholder=""
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => onChangeField(e, "state")}
            value={inquiryData?.state || ""}
            error={(errors && errors.state) || false}
            helperText={errors && errors.state}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <FormControl component="fieldset">
            <FormGroup aria-label="position">
              <FormControlLabel
                value="end"
                control={
                  <Checkbox
                    checked={inquiryData?.agree_to_receive_email || false}
                    name="agree_to_receive_email"
                    onChange={handleSingleCheckbox}
                    inputProps={{ "aria-label": "controlled" }}
                  />
                }
                label="Agree To Receive Email"
                labelPlacement="end"
              />
            </FormGroup>
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6}>
          <MDInput
            label="Utm Source"
            variant="standard"
            fullWidth
            name="utm_source"
            placeholder=""
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => onChangeField(e, "utm_source")}
            value={inquiryData?.utm_source || ""}
            error={(errors && errors.utm_source) || false}
            helperText={errors && errors.utm_source}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6}>
          <MDInput
            label="Utm Campaign"
            variant="standard"
            fullWidth
            name="utm_campaign"
            placeholder=""
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => onChangeField(e, "utm_campaign")}
            value={inquiryData?.utm_campaign || ""}
            error={(errors && errors.utm_campaign) || false}
            helperText={errors && errors.utm_campaign}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6}>
          <MDInput
            label="Utm Medium"
            variant="standard"
            fullWidth
            name="utm_medium"
            placeholder=""
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => onChangeField(e, "utm_medium")}
            value={inquiryData?.utm_medium || ""}
            error={(errors && errors.utm_medium) || false}
            helperText={errors && errors.utm_medium}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6}>
          <MDInput
            label="Utm Content"
            variant="standard"
            fullWidth
            name="utm_content"
            placeholder=""
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => onChangeField(e, "utm_content")}
            value={inquiryData?.utm_content || ""}
            error={(errors && errors.utm_content) || false}
            helperText={errors && errors.utm_content}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6}>
          <MDInput
            label="Utm Term"
            variant="standard"
            fullWidth
            name="utm_term"
            placeholder=""
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => onChangeField(e, "utm_term")}
            value={inquiryData?.utm_term || ""}
            error={(errors && errors.utm_term) || false}
            helperText={errors && errors.utm_term}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6}>
          <MDInput
            label="Country"
            variant="standard"
            fullWidth
            required
            name="country"
            placeholder="usa"
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => onChangeField(e, "country")}
            value={inquiryData?.country || ""}
            error={(errors && errors.country) || false}
            helperText={errors && errors.country}
          />
        </Grid>
      </Grid>
    </MDDialog>
  );
}

export default AddUpdate;
