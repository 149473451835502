import { Card, Grid } from "@mui/material";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import { phoneNumberformat } from "helper/services";
import { useState, useEffect } from "react";
import { useAppSelector } from "store/store";

function LeaseOverview(props: any): JSX.Element {
  const { billingTabDetail } = useAppSelector((state) => state.solaProSlice);
  const [locationData, setLocationData] = useState<any>({});
  const { billingTabData } = props;

  useEffect(() => {
    if (billingTabDetail && billingTabDetail?.connectLocation) {
      setLocationData(billingTabDetail?.connectLocation);
    }
  }, [billingTabDetail]);

  const getLabelElement = (name: any, right?: any) => (
    <Grid item xs={12} sm={6} md={6} lg={6} sx={{ textAlign: right ? "right" : "left" }}>
      <MDTypography variant="subtitle2" fontWeight="regular">
        {name}
      </MDTypography>
    </Grid>
  );

  const getCommaIsExistInLastWordAndReplaceWord = (word: any) => {
    let returnWord: any = "";
    if (word && word.slice(-1) === ",") {
      returnWord = word.substring(0, billingTabData?.location?.address_1.lastIndexOf(" "));
    } else {
      returnWord = word;
    }
    return returnWord;
  };

  const getAddress = () => {
    let address: any = "";
    if (billingTabData?.location?.address_1) {
      address = getCommaIsExistInLastWordAndReplaceWord(billingTabData?.location?.address_1);
    }
    if (billingTabData?.location?.state) {
      address += billingTabData?.location?.address_1
        ? `, ${billingTabData?.location?.state}`
        : `${billingTabData?.location?.state}`;
    }
    if (
      billingTabData?.location?.address_1 &&
      billingTabData?.location?.state &&
      billingTabData?.location?.postal_code
    ) {
      address += billingTabData?.location?.state
        ? `, ${billingTabData?.location?.postal_code}`
        : billingTabData?.location?.address_1
        ? `, ${billingTabData?.location?.postal_code}`
        : `${billingTabData?.location?.postal_code}`;
    }
    return address;
  };

  return (
    <Card id="lease-overview" sx={{ overflow: "visible" }}>
      <MDBox p={3}>
        <MDTypography variant="h5">Lease Overview</MDTypography>
      </MDBox>
      <MDBox pb={3} px={3}>
        <Grid container spacing={3}>
          {getLabelElement("Location Name")}
          {getLabelElement(billingTabData?.location?.name || "-", true)}
          {getLabelElement("Unit")}
          {getLabelElement(billingTabData?.studio_number || "-", true)}
          {getLabelElement("Manager")}
          {getLabelElement(
            locationData?.general_contact_last_name || locationData?.general_contact_first_name
              ? `${locationData?.general_contact_first_name || ""} ${
                  locationData?.general_contact_last_name || ""
                }`
              : "-",
            true
          )}
          {getLabelElement("Manager Phone")}
          {getLabelElement(
            billingTabData?.location?.phone_number
              ? phoneNumberformat(billingTabData?.location?.phone_number).substring(1)
              : "-",
            true
          )}
          {getLabelElement("Location Address")}
          {getLabelElement(getAddress() || "-", true)}
        </Grid>
      </MDBox>
    </Card>
  );
}

export default LeaseOverview;
