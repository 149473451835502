import { useEffect, useState } from "react";
import MDDialog from "components/MDDialog";
import { Grid, AccordionSummary, Accordion, AccordionDetails } from "@mui/material";
// import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import MDTypography from "components/MDTypography";
import MDBox from "components/MDBox";
import { getLocalDateTime } from "helper/services";

function ViewDetails(props: any): JSX.Element {
  const { open, onClose, viewStylistUnit } = props;
  const [loading] = useState<boolean>(false);
  const [stylistMessagesData, setStylistMessagesData] = useState<any>({});

  useEffect(() => {
    if (viewStylistUnit) {
      setStylistMessagesData((prev: any) => ({
        ...prev,
        stylist: viewStylistUnit.stylist_name,
        rent_manager_unit_name: viewStylistUnit.rent_manager_unit_name,
        rm_lease_id: viewStylistUnit.rm_lease_id,
        move_in_at: viewStylistUnit.move_in_at,
        move_out_at: viewStylistUnit.move_out_at,
        created_at: viewStylistUnit.created_at,
        updated_at: viewStylistUnit.updated_at,
      }));
    }
  }, []);

  const dialogProps = {
    open,
    onClose,
    saveBtn: false,
    title: "View Stylist Units",
    size: "md",
    loading,
  };

  const getTypograpghy = (label: any, value: any) => (
    <Grid item xs={12} sm={12} md={12} lg={6}>
      <MDTypography fontWeight="bold" variant="button">
        {label}
      </MDTypography>
      <MDBox>
        <MDTypography variant="button">{value || "-"}</MDTypography>
      </MDBox>
    </Grid>
  );

  const getAccordianSummary = (label: any) => (
    <AccordionSummary
      className="location-accordion"
      // expandIcon={<ExpandMoreIcon />}
      aria-controls="publishbh-content"
    >
      <MDTypography variant="h6">{label}</MDTypography>
    </AccordionSummary>
  );

  return (
    <MDDialog {...dialogProps}>
      <Grid container p="0 10px">
        <Grid container>
          <Grid item xs={12} sm={12} md={12}>
            <Accordion expanded>
              {getAccordianSummary("Basic Info")}
              <AccordionDetails>
                <Grid container spacing={2}>
                  {getTypograpghy("Stylist", stylistMessagesData?.stylist || "-")}
                  {getTypograpghy(
                    "Rent Manager Unit",
                    stylistMessagesData?.rent_manager_unit_name || "-"
                  )}
                </Grid>
                <Grid container spacing={2}>
                  {getTypograpghy("Rm Lease", stylistMessagesData?.rm_lease_id || "-")}
                  {getTypograpghy(
                    "Move In At",
                    stylistMessagesData?.move_in_at
                      ? getLocalDateTime(stylistMessagesData?.move_in_at)
                      : "-"
                  )}
                </Grid>
                <Grid container spacing={2}>
                  {getTypograpghy(
                    "Move Out At",
                    stylistMessagesData?.move_out_at
                      ? getLocalDateTime(stylistMessagesData?.move_out_at)
                      : "-"
                  )}
                  {getTypograpghy(
                    "Created At",
                    stylistMessagesData?.created_at
                      ? getLocalDateTime(stylistMessagesData?.created_at)
                      : "-"
                  )}
                </Grid>
                <Grid container spacing={2}>
                  {getTypograpghy(
                    "Updated At",
                    stylistMessagesData?.updated_at
                      ? getLocalDateTime(stylistMessagesData?.updated_at)
                      : "-"
                  )}
                </Grid>
              </AccordionDetails>
            </Accordion>
          </Grid>
        </Grid>
      </Grid>
    </MDDialog>
  );
}

export default ViewDetails;
