import { InputLabel, MenuItem, FormControl, Select, FormHelperText } from "@mui/material";

export default function Index(props: any) {
  const { id, label, name, value, onChange, options, placeholder, disabled, error } = props;

  return (
    <FormControl variant="standard" sx={{ minWidth: 150 }} error={error ? true : false}>
      <InputLabel id={id}>{label}</InputLabel>
      <Select
        labelId={id}
        id="select-standard"
        value={value}
        name={name}
        onChange={onChange}
        placeholder={placeholder}
        disabled={disabled}
      >
        {options?.map((time: any) => (
          <MenuItem value={time?.label} disabled={time?.disabled || false} key={time?.label}>
            {time?.label}
          </MenuItem>
        ))}
      </Select>
      {error && <FormHelperText>{error}</FormHelperText>}
    </FormControl>
  );
}
