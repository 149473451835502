import React, { useEffect, useState } from "react";
import MDDialog from "components/MDDialog";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import { Accordion, AccordionDetails, AccordionSummary, Grid } from "@mui/material";
import {
  getStylistDataForApprovalReq,
  onApproveSolaProSupportRequest,
  onRejectSolaPrpMatchARequest,
} from "store/thunk/systemAdmin";
import { getLocalDateTime } from "helper/services";
import { useAppDispatch, useAppSelector } from "store/store";
import MDInput from "components/MDInput";

function ViewDetails(props: any): JSX.Element {
  const { open, onClose, viewData, pageNo, perPage, search, sortBy } = props;
  const dispatch = useAppDispatch();
  const { stylistDataForApprovalReq } = useAppSelector((state) => state.systemAdminSlice);
  const { userData } = useAppSelector((state: any) => state.authReducer);
  const [openRejectPopup, setOpenRejectPopup] = useState(null);
  const [rejectReasonTxt, setRejectReasonTxt] = useState(null);
  const [reasonError, setReasonError] = useState(null);
  const [disabledApprovedBtn, setDisabledApprovedBtn] = useState(false);
  const [tenantNotFound, setTenantNotFound] = useState(false);

  useEffect(() => {
    if (
      viewData?.stylist_id &&
      viewData?.rm_location_id &&
      viewData?.rm_property_id &&
      viewData?.rm_tenant_id
    ) {
      dispatch(getStylistDataForApprovalReq({ stylist_id: viewData?.stylist_id }));
    }
    if (!viewData?.rm_location_id && !viewData?.rm_property_id && !viewData?.rm_tenant_id) {
      setTenantNotFound(true);
    }
  }, []);
  useEffect(() => {
    let disabled = false;
    if (stylistDataForApprovalReq?.stylist?.rm_match_confirmed) {
      disabled = true;
    } else {
      if (stylistDataForApprovalReq?.match_with_other_external_ids !== null) {
        disabled = true;
      }
      if (stylistDataForApprovalReq?.already_confirm_with_other_stylist) {
        disabled = true;
      }
      // if (!stylistDataForApprovalReq?.tenant_not_in_externalids) {
      //   disabled = true
      // }
    }
    setDisabledApprovedBtn(disabled);
  }, [stylistDataForApprovalReq]);

  const onOpenRejectPopup = (data: any) => {
    setOpenRejectPopup(data);
  };
  const onCloseRejectPopup = () => {
    setOpenRejectPopup(null);
    setReasonError(null);
    setRejectReasonTxt(null);
  };
  const onApprove = () => {
    console.log("viewData:", viewData);
    dispatch(
      onApproveSolaProSupportRequest({
        pageNo,
        perPage,
        search,
        sortBy,
        stylist_id: viewData?.stylist_id,
        rm_location_id: viewData?.rm_location_id,
        tenant_id: viewData?.rm_tenant_id,
        onClose,
        approved_by: userData?.user_id,
        request_id: viewData?.id,
        match_done_by_id: userData?.user_id,
        match_done_by_type: "Admin/superadmin",
        cms_location_id: viewData?.cms_location_id,
        rm_property_id: viewData?.rm_property_id,
      })
    );
  };
  const onReject = () => {
    if (!rejectReasonTxt) {
      setReasonError("onReject");
      return;
    }
    dispatch(
      onRejectSolaPrpMatchARequest({
        pageNo,
        perPage,
        search,
        sortBy,
        stylist_id: viewData?.stylist_id,
        rm_location_id: viewData?.rm_location_id,
        tenant_id: viewData?.rm_tenant_id,
        onClose,
        approved_by: userData?.user_id,
        reject_reason: rejectReasonTxt,
        request_id: viewData?.id,
        onCloseRejectPopup,
      })
    );
  };

  const dialogProps = {
    open,
    onClose,
    title: "View",
    size: "lg",
    saveBtn: viewData?.submit_request_type_id === 1 && !tenantNotFound ? true : false,
    saveTbtText: "Approve",
    onSave: onApprove,
    additionalBtn: viewData?.submit_request_type_id ? true : false,
    additionalBtnText: "Reject",
    onAdditionalBtnSave: onOpenRejectPopup,
    additionalBtnColor: "error",
    saveBtnDisabled: disabledApprovedBtn,
  };

  const rejectDialogProps = {
    open: openRejectPopup,
    onClose: () => onCloseRejectPopup(),
    onSave: () => onReject(),
    title: "Reject request",
    size: "sm",
    saveBtn: true,
  };

  return (
    <MDDialog {...dialogProps}>
      <MDBox pb={2}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12} md={12}>
            <Accordion expanded>
              <AccordionSummary
                className="location-accordion"
                // expandIcon={<ExpandMoreIcon />}
                aria-controls="publishbh-content"
              >
                <MDTypography variant="h6">Request Detail</MDTypography>
              </AccordionSummary>
              <AccordionDetails>
                <Grid container spacing={2} mb={5}>
                  <Grid item xs={12} sm={3} md={3}>
                    <MDTypography fontWeight="bold" variant="button">
                      Request Type
                    </MDTypography>
                    <MDBox>
                      <MDTypography variant="button">
                        {viewData?.connect_submit_request_type?.request_type_name || "-"}
                      </MDTypography>
                    </MDBox>
                  </Grid>
                  <Grid item xs={12} sm={3} md={3}>
                    <MDTypography fontWeight="bold" variant="button">
                      Email
                    </MDTypography>
                    <MDBox>
                      <MDTypography variant="button">{viewData?.email || "-"}</MDTypography>
                    </MDBox>
                  </Grid>
                  <Grid item xs={12} sm={3} md={3}>
                    <MDTypography fontWeight="bold" variant="button">
                      Phone
                    </MDTypography>
                    <MDBox>
                      <MDTypography variant="button">{viewData?.phone || "-"}</MDTypography>
                    </MDBox>
                  </Grid>
                  <Grid item xs={12} sm={3} md={3}>
                    <MDTypography fontWeight="bold" variant="button">
                      First Name
                    </MDTypography>
                    <MDBox>
                      <MDTypography variant="button">{viewData?.first_name || "-"}</MDTypography>
                    </MDBox>
                  </Grid>
                  <Grid item xs={12} sm={3} md={3}>
                    <MDTypography fontWeight="bold" variant="button">
                      Last Name
                    </MDTypography>
                    <MDBox>
                      <MDTypography variant="button">{viewData?.last_name || "-"}</MDTypography>
                    </MDBox>
                  </Grid>
                  <Grid item xs={12} sm={3} md={3}>
                    <MDTypography fontWeight="bold" variant="button">
                      Maiden Name
                    </MDTypography>
                    <MDBox>
                      <MDTypography variant="button">{viewData?.maiden_name || "-"}</MDTypography>
                    </MDBox>
                  </Grid>
                  <Grid item xs={12} sm={3} md={3}>
                    <MDTypography fontWeight="bold" variant="button">
                      Message
                    </MDTypography>
                    <MDBox>
                      <MDTypography variant="button">{viewData?.message || "-"}</MDTypography>
                    </MDBox>
                  </Grid>
                  <Grid item xs={12} sm={3} md={3}>
                    <MDTypography fontWeight="bold" variant="button">
                      Studio Number
                    </MDTypography>
                    <MDBox>
                      <MDTypography variant="button">{viewData?.studio_number || "-"}</MDTypography>
                    </MDBox>
                  </Grid>
                  <Grid item xs={12} sm={3} md={3}>
                    <MDTypography fontWeight="bold" variant="button">
                      Request Status
                    </MDTypography>
                    <MDBox>
                      <MDTypography variant="button">{viewData?.status || "-"}</MDTypography>
                    </MDBox>
                  </Grid>
                  <Grid item xs={12} sm={3} md={3}>
                    <MDTypography fontWeight="bold" variant="button">
                      Reject Reason
                    </MDTypography>
                    <MDBox>
                      <MDTypography variant="button">{viewData?.reject_reason || "-"}</MDTypography>
                    </MDBox>
                  </Grid>
                  <Grid item xs={12} sm={3} md={3}>
                    <MDTypography fontWeight="bold" variant="button">
                      Stylist ID
                    </MDTypography>
                    <MDBox>
                      <MDTypography variant="button">{viewData?.stylist_id || "-"}</MDTypography>
                    </MDBox>
                  </Grid>
                  <Grid item xs={12} sm={3} md={3}>
                    <MDTypography fontWeight="bold" variant="button">
                      RM Location ID
                    </MDTypography>
                    <MDBox>
                      <MDTypography variant="button">
                        {viewData?.rm_location_id || "-"}
                      </MDTypography>
                    </MDBox>
                  </Grid>
                  <Grid item xs={12} sm={3} md={3}>
                    <MDTypography fontWeight="bold" variant="button">
                      RM Tenant ID
                    </MDTypography>
                    <MDBox>
                      <MDTypography variant="button">{viewData?.rm_tenant_id || "-"}</MDTypography>
                    </MDBox>
                  </Grid>
                  <Grid item xs={12} sm={3} md={3}>
                    <MDTypography fontWeight="bold" variant="button">
                      RM Property ID
                    </MDTypography>
                    <MDBox>
                      <MDTypography variant="button">
                        {viewData?.rm_property_id || "-"}
                      </MDTypography>
                    </MDBox>
                  </Grid>
                  <Grid item xs={12} sm={3} md={3}>
                    <MDTypography fontWeight="bold" variant="button">
                      Created At
                    </MDTypography>
                    <MDBox>
                      <MDTypography variant="button">
                        {getLocalDateTime(viewData.created_at)}
                      </MDTypography>
                    </MDBox>
                  </Grid>
                  <Grid item xs={12} sm={3} md={3}>
                    <MDTypography fontWeight="bold" variant="button">
                      Updated At
                    </MDTypography>
                    <MDBox>
                      <MDTypography variant="button">
                        {getLocalDateTime(viewData.updated_at)}
                      </MDTypography>
                    </MDBox>
                  </Grid>
                  {stylistDataForApprovalReq?.stylist?.rm_match_confirmed &&
                    stylistDataForApprovalReq?.connect_user?.user_id && (
                      <>
                        <Grid item xs={12} sm={3} md={3}>
                          <MDTypography fontWeight="bold" variant="button">
                            Approved user name
                          </MDTypography>
                          <MDBox>
                            <MDTypography variant="button">
                              {stylistDataForApprovalReq?.connect_user?.first_name}{" "}
                              {stylistDataForApprovalReq?.connect_user?.last_name}
                            </MDTypography>
                          </MDBox>
                        </Grid>
                        <Grid item xs={12} sm={3} md={3}>
                          <MDTypography fontWeight="bold" variant="button">
                            Approved user email
                          </MDTypography>
                          <MDBox>
                            <MDTypography variant="button">
                              {stylistDataForApprovalReq?.connect_user?.login_email}
                            </MDTypography>
                          </MDBox>
                        </Grid>
                      </>
                    )}
                </Grid>
                {stylistDataForApprovalReq?.stylist?.rm_match_confirmed && (
                  <>
                    <MDTypography fontWeight="bold" variant="button">
                      This stylist is already confirmed with bellow Rent manager
                    </MDTypography>
                    <Grid container>
                      <Grid item xs={12} sm={3} md={3}>
                        <MDTypography fontWeight="bold" variant="button">
                          Rent Manager ID
                        </MDTypography>
                        <MDBox>
                          <MDTypography variant="button">
                            {stylistDataForApprovalReq?.stylist?.rent_manager_id || "-"}
                          </MDTypography>
                        </MDBox>
                      </Grid>
                      <Grid item xs={12} sm={3} md={3}>
                        <MDTypography fontWeight="bold" variant="button">
                          Rent Manger Location ID
                        </MDTypography>
                        <MDBox>
                          <MDTypography variant="button">
                            {stylistDataForApprovalReq?.stylist?.rm_location_id || "-"}
                          </MDTypography>
                        </MDBox>
                      </Grid>
                    </Grid>
                  </>
                )}
                {!stylistDataForApprovalReq?.stylist?.rm_match_confirmed &&
                  stylistDataForApprovalReq?.already_confirm_with_other_stylist && (
                    <>
                      <MDTypography fontWeight="bold" variant="button">
                        This tenant request is already matched with another stylist
                      </MDTypography>
                      <Grid container>
                        <Grid item xs={12} sm={3} md={3}>
                          <MDTypography fontWeight="bold" variant="button">
                            ID
                          </MDTypography>
                          <MDBox>
                            <MDTypography variant="button">
                              {stylistDataForApprovalReq?.already_confirm_with_other_stylist?.id ||
                                "-"}
                            </MDTypography>
                          </MDBox>
                        </Grid>
                        <Grid item xs={12} sm={3} md={3}>
                          <MDTypography fontWeight="bold" variant="button">
                            Name
                          </MDTypography>
                          <MDBox>
                            <MDTypography variant="button">
                              {stylistDataForApprovalReq?.already_confirm_with_other_stylist
                                ?.name || "-"}
                            </MDTypography>
                          </MDBox>
                        </Grid>
                        <Grid item xs={12} sm={3} md={3}>
                          <MDTypography fontWeight="bold" variant="button">
                            Email
                          </MDTypography>
                          <MDBox>
                            <MDTypography variant="button">
                              {stylistDataForApprovalReq?.already_confirm_with_other_stylist
                                ?.email_address || "-"}
                            </MDTypography>
                          </MDBox>
                        </Grid>
                        <Grid item xs={12} sm={3} md={3}>
                          <MDTypography fontWeight="bold" variant="button">
                            Phone Number
                          </MDTypography>
                          <MDBox>
                            <MDTypography variant="button">
                              {stylistDataForApprovalReq?.already_confirm_with_other_stylist
                                ?.phone_number || "-"}
                            </MDTypography>
                          </MDBox>
                        </Grid>
                        <Grid item xs={12} sm={3} md={3}>
                          <MDTypography fontWeight="bold" variant="button">
                            Studio Number
                          </MDTypography>
                          <MDBox>
                            <MDTypography variant="button">
                              {stylistDataForApprovalReq?.already_confirm_with_other_stylist
                                ?.studio_number || "-"}
                            </MDTypography>
                          </MDBox>
                        </Grid>
                      </Grid>
                    </>
                  )}
                {!stylistDataForApprovalReq?.stylist?.rm_match_confirmed &&
                  stylistDataForApprovalReq?.match_with_other_external_ids && (
                    <>
                      <MDTypography fontWeight="bold" variant="button">
                        This tenant request is already other stylist in externalId
                      </MDTypography>
                      <Grid container>
                        <Grid item xs={12} sm={3} md={3}>
                          <MDTypography fontWeight="bold" variant="button">
                            Stylist Id
                          </MDTypography>
                          <MDBox>
                            <MDTypography variant="button">
                              {stylistDataForApprovalReq?.match_with_other_external_ids
                                ?.objectable_id || "-"}
                            </MDTypography>
                          </MDBox>
                        </Grid>
                        <Grid item xs={12} sm={3} md={3}>
                          <MDTypography fontWeight="bold" variant="button">
                            RM Location ID
                          </MDTypography>
                          <MDBox>
                            <MDTypography variant="button">
                              {stylistDataForApprovalReq?.match_with_other_external_ids
                                ?.rm_location_id || "-"}
                            </MDTypography>
                          </MDBox>
                        </Grid>
                        <Grid item xs={12} sm={3} md={3}>
                          <MDTypography fontWeight="bold" variant="button">
                            RM Tenant ID
                          </MDTypography>
                          <MDBox>
                            <MDTypography variant="button">
                              {stylistDataForApprovalReq?.match_with_other_external_ids
                                ?.rm_tenant_id || "-"}
                            </MDTypography>
                          </MDBox>
                        </Grid>
                      </Grid>
                    </>
                  )}
                {!stylistDataForApprovalReq?.tenant_not_in_externalids && !tenantNotFound && (
                  <MDTypography fontWeight="bold" variant="button">
                    This tenant match request does not match in any tenant in externalIds table.
                  </MDTypography>
                )}
                {console.log("tenantNotFound:", tenantNotFound)}
                {tenantNotFound && (
                  <MDTypography fontWeight="bold" variant="button">
                    This request done not found any tenant.
                  </MDTypography>
                )}
              </AccordionDetails>
            </Accordion>
          </Grid>
        </Grid>
      </MDBox>
      {openRejectPopup && (
        <MDDialog {...rejectDialogProps}>
          <MDTypography variant="h6" fontWeight="medium">
            <Grid container spacing={2} display="flex" alignItems="flex-end">
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <MDInput
                  label="Reject reason"
                  fullWidth
                  required
                  multiline
                  rows={5}
                  variant="standard"
                  placeholder="Enter Reject reason"
                  onChange={(e: any) => {
                    setRejectReasonTxt(e.target.value);
                    setReasonError(null);
                  }}
                  value={rejectReasonTxt || ""}
                  error={reasonError ? true : false}
                  helperText={reasonError || null}
                />
              </Grid>
            </Grid>
          </MDTypography>
        </MDDialog>
      )}
    </MDDialog>
  );
}

export default ViewDetails;
