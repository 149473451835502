import { useCallback, useEffect, useLayoutEffect, useRef, useState } from "react";
import { Card, Icon, Checkbox } from "@mui/material";
import MDBox from "components/MDBox";
import MDDialog from "components/MDDialog";
import MDTypography from "components/MDTypography";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import DataTable from "examples/Tables/DataTable";
import { getLegacyAdminListApi, deleteLegacyAdminRecord } from "store/thunk/systemAdmin";
import { useAppDispatch, useAppSelector } from "store/store";
import { getLocalDateTime } from "helper/services";
import Messages from "helper/messages";
import exportData from "helper/exportTableConst";
import MDIconButton from "components/MDIconButton";
import MDButton from "components/MDButton";
import { setDefaultsColumns } from "store/slices/connectUsersSlice";
import { getDefaultCoulmns, saveSelectedAllData } from "store/thunk/connectUserThunk";
import AddUpdate from "./addUpdate";
import ViewDetails from "./viewDetails";

function Index(): JSX.Element {
  const dispatch = useAppDispatch();
  const [addUpdatePopup, setAddUpdatePopup] = useState<any>(false);
  const [updateData, setUpdateData] = useState<any>("");
  const { legacyAdmins } = useAppSelector((state) => state.systemAdminSlice);
  const { userData } = useAppSelector((state) => state.authReducer);
  const [detailsPopup, setDetailsPopup] = useState<any>(false);
  const [deleteLegacyAdmin, setDeleteLegacyAdmin] = useState<any>(false);
  const [selectedItems, setSelectedItems] = useState([]);
  const [LegacyAdmin, setLegacyAdmin] = useState([]);
  const [pageCount, setPageCount] = useState(0);
  const [pageNo, setPageNo] = useState(0);
  const [perPage, setPageSize] = useState(50);
  const [search, setSearch] = useState("");
  const [totalCount, setTotalCount] = useState(0);
  const [isCheckAll, setIsCheckAll] = useState(false);
  // SD-3199
  const [defaultExport, setDefaultExport] = useState<any>([]);
  const [callFetch, setCallFetch] = useState<any>(false);
  const [defaultSorting, setDefaultSorting] = useState<any>([]);
  const [defaultFilteing, setDefaultFilteing] = useState<any>([]);

  const fetchIdRef = useRef(0);
  const getColumns = () => {
    const columns: any = [
      {
        Header: "",
        disableSortBy: true,
        accessor: "checkbox",
        export: false,
      },
      { Header: "Username", accessor: "email", export: true },
      { Header: "Email Address", accessor: "email_address", export: true },
      { Header: "Franchisee", accessor: "franchisee", export: true, is_boolean: true },
      { Header: "Sign In Count", accessor: "sign_in_count", export: true },
      {
        Header: "Last Sign In At",
        accessor: "last_sign_in_at",
        width: "200px",
        export: true,
        is_date: true,
      },
      { Header: "Action", accessor: "action", export: false, disableSortBy: true },
    ];
    return columns;
  };

  useEffect(() => {
    if (legacyAdmins && legacyAdmins?.admins) {
      setLegacyAdmin(legacyAdmins?.admins);
      setPageCount(legacyAdmins?.meta?.total_pages);
      setTotalCount(legacyAdmins?.meta?.total_count);
    }
  }, [legacyAdmins]);

  const fetchData = useCallback(({ pageSize, pageIndex, search, sortBy, filterData }) => {
    const fetchId = ++fetchIdRef.current;
    const sort: any = sortBy || [];
    // We'll even set a delay to simulate a server here
    setTimeout(() => {
      if (fetchId === fetchIdRef.current) {
        const sentReq = {
          sendData: {
            user_id: userData?.user_id,
            table_name: exportData.admins.table_name,
            table_columns: getColumns(),
            table_sort: sortBy && sortBy?.length ? sortBy : [],
            table_filter: filterData && filterData?.length ? filterData : [],
            default_export_data: defaultExport,
          },
        };
        dispatch(saveSelectedAllData(sentReq));
        setPageNo(pageIndex);
        setSearch(search);
        setPageSize(pageSize);
        // setSelectedItems([]);
        setIsCheckAll(false);
        dispatch(getLegacyAdminListApi({ pageSize, pageIndex, search, sort, filterData }));
      }
    }, 1000);
  }, []);

  useLayoutEffect(() => {
    dispatch(setDefaultsColumns([]));
    // Set data in variable and Call defaultColums Thunk
    const sentReq = {
      user_id: userData?.user_id,
      table_name: exportData.admins.table_name,
    };
    dispatch(getDefaultCoulmns(sentReq)).then((resp: any) => {
      // SD-3199

      if (resp?.payload) {
        const respFilter = resp?.payload?.table_filter || [];
        const respSorting = resp?.payload?.table_sort || [];
        const defaultDBexportData = resp?.payload?.default_export_data || [];
        setDefaultExport(defaultDBexportData);
        setDefaultSorting(respSorting);
        setDefaultFilteing(respFilter);
        setCallFetch(true);
        fetchData({
          perPage,
          pageNo,
          search,
          sortBy: respSorting && respSorting?.length ? respSorting : [],
          additionalField: null,
          filterData: respFilter && respFilter?.length ? respFilter : [],
        });
      }
    });
  }, []);

  const closeModal = () => {
    setUpdateData("");
    setAddUpdatePopup(false);
  };

  const openLegacyAdminEditModel = (data: any) => {
    setUpdateData(data);
    setAddUpdatePopup(true);
  };

  const openDeleteModal = (data: any) => {
    setUpdateData(data);
    setDeleteLegacyAdmin(true);
  };

  const openDetailPopup = (data: any) => {
    setUpdateData(data);
    setDetailsPopup(true);
  };
  const closeDetailPopup = () => {
    setUpdateData("");
    setDetailsPopup(false);
  };

  const actionButtons = (object: any) => (
    <>
      <MDIconButton aria-label="Edit" color="info" onClick={() => openLegacyAdminEditModel(object)}>
        <Icon fontSize="small">edit</Icon>
      </MDIconButton>
      <MDIconButton aria-label="Delete" color="error" onClick={() => openDeleteModal(object)}>
        <Icon fontSize="small">delete</Icon>
      </MDIconButton>
      <MDIconButton
        tooltipName="View"
        aria-label="Info"
        color="info"
        onClick={() => openDetailPopup(object)}
      >
        <Icon fontSize="small">visibility</Icon>
      </MDIconButton>
    </>
  );

  const onChangeCheckBox = (e: any) => {
    let items = JSON.parse(JSON.stringify(selectedItems));
    if (e.target.checked && !items.includes(e.target.value)) {
      items.push(e.target.value);
      if (items.length === LegacyAdmin.length) {
        setIsCheckAll(true);
      }
    } else {
      items = items.filter((id: any) => id !== e.target.value);
      setIsCheckAll(false);
    }
    setSelectedItems(items);
  };

  const checkUncheckAll = (e: any) => {
    let allIds: any = [];
    allIds = LegacyAdmin.map((val: any) => String(val.id));
    if (e.target.checked) {
      const selectedIds = [...new Set([...selectedItems, ...allIds])];
      setSelectedItems(selectedIds);
      // allIds = LegacyAdmin.map((val: any) => String(val.id));
      setIsCheckAll(true);
    } else {
      allIds = LegacyAdmin.map((val: any) => String(val.id));
      allIds = selectedItems.filter((el: any) => !allIds.includes(el));
      setSelectedItems([...allIds]);
      setIsCheckAll(false);
      // allIds = [];
      // setIsCheckAll(false);
    }
    // setSelectedItems(allIds);
  };

  const getRows = () => {
    const data: any = LegacyAdmin.map((obj: any) => ({
      checkbox: (
        <Checkbox
          checked={selectedItems.includes(obj.id.toString())}
          onChange={(e: any) => onChangeCheckBox(e)}
          value={obj.id}
        />
      ),
      id: obj.id ? obj.id : "-",
      email: obj.email ? obj.email : "-",
      email_address: obj.email_address ? obj.email_address : "-",
      franchisee: obj.franchisee ? (
        <Icon fontSize="medium" color="success">
          check_circle_rounded
        </Icon>
      ) : (
        <Icon fontSize="medium" color="error">
          cancel
        </Icon>
      ),
      sign_in_count: obj.sign_in_count ? obj.sign_in_count : "-",
      last_sign_in_at: obj.last_sign_in_at ? getLocalDateTime(obj.last_sign_in_at) : "-",
      action: actionButtons(obj),
    }));
    return data;
  };

  const tableData = {
    columns: getColumns(),
    rows: getRows(),
  };

  const onCloseDeleteModal = () => {
    setDeleteLegacyAdmin(false);
    setUpdateData("");
  };

  // Start Remove selcted items
  const removeSelectedItem = (deletedId: number) => {
    setSelectedItems(selectedItems.filter((item: any) => Number(item) !== deletedId));
  };
  // End Remove selcted items

  const onDelete = () => {
    if (updateData?.id) {
      const DelReq = {
        adminData: updateData,
        onCloseDeleteModal,
        removeSelectedItem,
      };
      dispatch(deleteLegacyAdminRecord(DelReq));
    }
  };

  const deleteDialogProps = {
    open: deleteLegacyAdmin,
    onClose: () => onCloseDeleteModal(),
    onSave: () => onDelete(),
    title: "Delete Legacy Account",
    size: "sm",
    saveBtn: false,
    deleteBtn: true,
    saveTbtText: "Yes",
    closeBtnText: "No",
  };

  // Remove uncheck boxes
  const clearCheckItem = () => {
    setSelectedItems([]);
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={4} pb={3}>
        <Card>
          <MDBox
            bgColor="dark"
            color="white"
            coloredShadow="dark"
            borderRadius="xl"
            alignItems="center"
            justifyContent="space-between"
            pt={1}
            pb={1}
            pl={2}
            pr={2}
            ml={2}
            mr={2}
            display="flex"
            mt={-3}
            className="page-header"
          >
            <p className="page-header-label">Legacy Accounts</p>
            <MDButton
              variant="gradient"
              color="light"
              size="small"
              className="xs-small"
              onClick={() => setAddUpdatePopup(true)}
            >
              <Icon sx={{ fontWeight: "bold" }}>add</Icon> Add New
            </MDButton>
          </MDBox>
          {(defaultSorting || defaultSorting?.length) &&
            callFetch && ( // SD-3199
              <DataTable
                defaultSortingColumns={defaultSorting} // SD-3199
                defaultFilteringColums={defaultFilteing} // SD-3199
                table={tableData}
                canSearch
                searchText="Search admins..."
                selectedItems={selectedItems}
                showExportSelectedBtn
                showExportAllBtn
                pageCount={pageCount}
                pageNo={pageNo}
                setPageNo={setPageNo}
                totalCount={totalCount}
                fetchData={fetchData}
                exportFields={exportData.admins.export_fields}
                exportTable={exportData.admins.table_name}
                exportType={exportData.admins.export_type}
                advanceFilter
                manualSearch
                manualSort
                isDisplayCheckAllCheckbox
                onChangeCheckAllCheckbox={checkUncheckAll}
                isCheckAll={isCheckAll}
                clearCheckItem={clearCheckItem}
                useRubyApiToExport
              />
            )}
          {addUpdatePopup && (
            <AddUpdate
              open={addUpdatePopup}
              updateData={updateData}
              pageNo={pageNo}
              perPage={perPage}
              search={search}
              onClose={() => closeModal()}
            />
          )}
          {detailsPopup && (
            <ViewDetails
              open={detailsPopup}
              updateData={updateData}
              onClose={() => closeDetailPopup()}
            />
          )}
          {deleteLegacyAdmin && (
            <MDDialog {...deleteDialogProps}>
              <MDTypography variant="h6" fontWeight="medium">
                {Messages.GENERAL.SURE_TO_DELETE}
              </MDTypography>
            </MDDialog>
          )}
        </Card>
      </MDBox>
    </DashboardLayout>
  );
}

export default Index;
