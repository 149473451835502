import { useState } from "react";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import CustomDropZone from "components/CustomDropZone";
import MDIconButton from "components/MDIconButton";
import ImagePreview from "layouts/pages/ImagePreview";
import { Icon, Grid, Card } from "@mui/material";
import MESSAGES from "helper/messages";

function Images(props: any): JSX.Element {
  const { solaProDetail, setSolaProDetail, onChangeField } = props;
  const [isPreview, setImagePreviewPopup] = useState(false);
  const [imageToPreview, setImageToPreview] = useState("");

  const onChangeImage = async (files: any, key: any) => {
    if (files[0]) {
      const file = files[0];
      const reader = new FileReader();
      reader.onload = function (e) {
        setSolaProDetail({
          ...solaProDetail,
          [`image_${key}_url`]: e.target.result,
          [`image_${key}`]: e.target.result,
        });
      };
      reader.readAsDataURL(file);
    }
  };

  const onImageRemove = async (e: any, key: any) => {
    e.stopPropagation();
    const deleteflag = `delete_image_${key}`;
    setSolaProDetail({
      ...solaProDetail,
      [`image_${key}_url`]: null,
      [`image_${key}`]: null,
      [deleteflag]: 1,
    });
  };

  const previewImage = (image: any) => {
    setImagePreviewPopup(true);
    setImageToPreview(image);
  };

  const closeImagePreview = () => {
    setImagePreviewPopup(false);
    setImageToPreview("");
  };

  const displayItems = (i: any): JSX.Element => {
    const items: any = [];
    items.push(
      <Grid container spacing={2} key={i} mt={1}>
        <Grid item xs={12} sm={2} md={2} lg={2}>
          <MDTypography fontSize="medium">Image {i}</MDTypography>
        </Grid>
        <Grid item xs={12} sm={4} md={4} lg={4}>
          <CustomDropZone
            multipleUpload={false}
            acceptedFiles={{
              "image/*": [".gif", ".png", ".jpg", ".jpeg"],
            }}
            onDrop={(e: any) => onChangeImage(e, i)}
          >
            Drop your image here, or Browse
          </CustomDropZone>
        </Grid>
        <Grid item xs={12} sm={2} md={2} lg={2}>
          {solaProDetail[`image_${i}_url`] && (
            <Grid
              container
              onClick={() => previewImage(solaProDetail[`image_${i}_url`])}
              justifyContent="center"
              alignItems="center"
              textAlign="center"
            >
              <Grid item className="imageWithDeleteIcon">
                <img
                  width="90%"
                  style={{ maxHeight: "100px" }}
                  src={solaProDetail[`image_${i}_url`]}
                  alt="original"
                />
                <MDIconButton
                  tooltipName="Delete"
                  aria-label="Delete"
                  className="removeBtnClass"
                  onClick={(e: any) => onImageRemove(e, i)}
                >
                  <Icon fontSize="small">close</Icon>
                </MDIconButton>
              </Grid>
            </Grid>
          )}
        </Grid>
        <Grid item xs={12} sm={4} md={4} lg={4}>
          <MDInput
            multiline
            rows="3"
            variant="outlined"
            label={`Image ${i} Alt Text`}
            fullWidth
            name={`image_${i}_alt_text`}
            placeholder={`Image ${i} Alt Text`}
            onChange={onChangeField}
            value={solaProDetail[`image_${i}_alt_text`] || ""}
            infoText={MESSAGES.INFO.IMAGE_ALT_TEXT}
          />
        </Grid>
      </Grid>
    );
    return items;
  };

  return (
    <Card id="images">
      <MDBox p={3}>
        <MDTypography variant="h5">Images</MDTypography>
      </MDBox>
      <MDBox pb={3} px={3}>
        {displayItems(1)}
        {displayItems(2)}
        {displayItems(3)}
        {displayItems(4)}
        {displayItems(5)}
        {displayItems(6)}
        {displayItems(7)}
        {displayItems(8)}
        {displayItems(9)}
        {displayItems(10)}
      </MDBox>
      <ImagePreview
        imageData={imageToPreview}
        open={isPreview}
        onCloseImage={() => {
          closeImagePreview();
        }}
      />
    </Card>
  );
}

export default Images;
