import React, { useEffect, useState } from "react";
import MDDialog from "components/MDDialog";
import { Grid, InputLabel } from "@mui/material";
import MDInput from "components/MDInput";
import CustomPhoneInput from "components/CustomPhoneInput";
import { useAppDispatch } from "store/store";
import { saveStylistMessages } from "store/thunk/systemAdmin";
import Validations from "helper/validations";
import CustomAutoSearch from "components/CustomAutoSearch";
import { getPermission } from "helper/services";

function AddUpdate(props: any): JSX.Element {
  const dispatch = useAppDispatch();
  const { open, onClose, editStylistMessage, pageNo, perPage, search, sortBy } = props;
  const [loading] = useState<boolean>(false);
  const [stylistMessagesData, setStylistMessagesData] = useState<any>({});
  const [errors, setErrors] = useState<any>({});

  useEffect(() => {
    if (editStylistMessage) {
      setStylistMessagesData((prev: any) => ({
        ...prev,
        name: editStylistMessage.name,
        email: editStylistMessage.email,
        phone: editStylistMessage.phone,
        message: editStylistMessage.message,
        stylist: { id: editStylistMessage.stylist_id, name: editStylistMessage.stylist_name },
      }));
    }
  }, []);

  const onSave = () => {
    const error = Validations.validateStylistMessages(stylistMessagesData);
    setErrors(error);
    if (!Object.keys(error).length) {
      const req = {
        stylistMessagesData,
        onClose,
        pageNo,
        perPage,
        search,
        sortBy,
      };
      if (editStylistMessage) {
        Object.assign(req, { id: editStylistMessage.id });
      }
      dispatch(saveStylistMessages(req));
    }
  };

  const onChangeField = (e: any, field: string) => {
    setStylistMessagesData((prev: any) => ({
      ...prev,
      [field]: e.target.value,
    }));
  };

  const dialogProps = {
    open,
    onClose,
    onSave,
    saveTbtText: editStylistMessage ? "Update" : "Save",
    title: editStylistMessage ? "Edit Stylist Messages" : "Add Stylist Messages",
    size: "sm",
    loading,
  };

  const onChangeAutoComplete = (name: string, value: any) => {
    setStylistMessagesData({ ...stylistMessagesData, [name]: value });
  };

  return (
    <MDDialog {...dialogProps}>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <MDInput
            label="Name"
            variant="standard"
            fullWidth
            required
            name="name"
            placeholder="Name"
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => onChangeField(e, "name")}
            value={stylistMessagesData?.name || ""}
            error={errors && errors.name ? true : false}
            helperText={errors && errors.name}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <MDInput
            label="Email"
            variant="standard"
            fullWidth
            name="email"
            placeholder="Email"
            required
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => onChangeField(e, "email")}
            value={stylistMessagesData?.email || ""}
            error={errors && errors.email ? true : false}
            helperText={errors && errors.email}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <InputLabel sx={{ marginTop: "5px" }}>Phone</InputLabel>
          <MDInput
            variant="standard"
            fullWidth
            name="phone"
            placeholder="(000) 000-0000"
            id="phone-input"
            InputProps={{
              inputComponent: CustomPhoneInput as any,
            }}
            value={stylistMessagesData?.phone || ""}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => onChangeField(e, "phone")}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <MDInput
            label="Message"
            fullWidth
            required
            multiline
            variant="standard"
            rows={2}
            InputLabelProps={{ shrink: true }}
            name="message"
            onChange={(event: any) => onChangeField(event, "message")}
            value={stylistMessagesData?.message || ""}
            error={errors && errors.message ? true : false}
            helperText={errors && errors.message}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <CustomAutoSearch
            idKey="id"
            nameKey="name"
            fieldLabel="Stylists"
            apiName="stylists"
            responseKey="stylists"
            name="stylist"
            value={stylistMessagesData?.stylist || null}
            onChange={onChangeAutoComplete}
            error={(errors && errors.stylist) || false}
            createNewLink={getPermission("sola_pros", "sola_pros_sola_pros") ? "/sola-pros" : ""}
            required
            additionaFields={{ status: "active" }}
          />
        </Grid>
      </Grid>
    </MDDialog>
  );
}

export default AddUpdate;
