import React, { useEffect, useState } from "react";
import MDDialog from "components/MDDialog";
import MDInput from "components/MDInput";
import MDBox from "components/MDBox";
import Validations from "helper/validations";
import { addOrUpdateExternal } from "store/thunk/systemAdmin";
import { useAppDispatch } from "store/store";
import MDTypography from "components/MDTypography";
import { Autocomplete, FormControl, Grid, TextField } from "@mui/material";
import MDDatePicker from "components/MDDatePicker";
import moment from "moment";
import CustomAutoSearch from "components/CustomAutoSearch";
import { getPermission, getAllAssociationLocations, adminSuperAdmin } from "helper/services";

function AddUpdate(props: any): JSX.Element {
  const dispatch = useAppDispatch();
  const { open, onClose, updateData, pageNo, perPage } = props;
  const [external, setExternal] = useState<any>();
  const [errors, setErrors] = useState<any>({});

  useEffect(() => {
    if (updateData?.id) {
      let objectable: any = null;
      if (updateData?.objectable_id) {
        objectable = { id: updateData?.objectable_id, name: updateData?.objectable_name };
      }
      if (updateData?.objectable_type === "Location") {
        setExternal({ ...updateData, location_objectable_id: objectable });
      } else {
        setExternal({ ...updateData, objectable_id: objectable });
      }
    }
  }, [updateData]);

  const onSave = () => {
    const error = Validations.validateExternalForm(external);
    setErrors(error);
    if (!Object.keys(error).length) {
      if (external?.location_objectable_id) {
        external.objectable_id = external?.location_objectable_id;
      }
      const req = {
        externalData: external,
        onClose,
        pageNo,
        perPage,
      };
      if (external.id) {
        dispatch(addOrUpdateExternal(req));
      } else {
        dispatch(addOrUpdateExternal(req));
      }
    }
  };

  const onChangeField = (e: any, field: string) => {
    setExternal({ ...external, [field]: e.target.value });
  };

  const handleDateChange = (type: string, date: any) => {
    const utcDate = moment(new Date(date)).toISOString();
    setExternal({ ...external, [type]: utcDate });
  };

  const onChangeAutoComplete = (name: string, value: any) => {
    setExternal({ ...external, [name]: value });
  };

  const options = [{ id: 1, name: "rent_manager" }];
  const objectable = [
    { id: 1, title: "Location" },
    { id: 2, title: "Stylist" },
  ];

  const dialogProps = {
    open,
    onClose,
    onSave,
    title: updateData?.id ? "Update External" : "Add External",
    size: "sm",
  };

  return (
    <MDDialog {...dialogProps}>
      <MDBox mt={1}>
        <Autocomplete
          id="objectable"
          options={objectable}
          getOptionLabel={(option) => option.title}
          value={
            (external?.objectable_type &&
              objectable.find((v) => v.title === external.objectable_type)) ||
            null
          }
          onChange={(event: any, newValue: any) => {
            setExternal({ ...external, objectable_type: newValue?.title });
          }}
          renderOption={(props: any, option: any) => <li {...props}>{option.title}</li>}
          renderInput={(params: any) => (
            <TextField
              {...params}
              variant="standard"
              label="Objectable"
              placeholder="objectable"
              error={(errors && errors.objectable_type) || false}
              required
            />
          )}
        />
      </MDBox>
      <MDBox mt={1}>
        {external?.objectable_type === "Stylist" && (
          <CustomAutoSearch
            idKey="id"
            nameKey="name"
            fieldLabel="Stylists"
            apiName="stylists"
            responseKey="stylists"
            name="objectable_id"
            required
            error={(errors && errors.objectable_id) || false}
            value={external?.objectable_id || null}
            onChange={onChangeAutoComplete}
            createNewLink={getPermission("sola_pros", "sola_pros_sola_pros") ? "/sola-pros" : ""}
            additionaFields={{ status: "active" }}
          />
        )}
        {external?.objectable_type === "Location" && (
          <CustomAutoSearch
            idKey="id"
            nameKey="name"
            fieldLabel="Locations"
            apiName="locations"
            responseKey="locations"
            name="location_objectable_id"
            required
            error={(errors && errors.objectable_id) || false}
            value={external?.location_objectable_id || null}
            onChange={onChangeAutoComplete}
            createNewLink={
              adminSuperAdmin() && getPermission("locations", "locations_location")
                ? "/locations"
                : ""
            }
            usersData={getAllAssociationLocations()}
          />
        )}
      </MDBox>
      <MDBox mt={2}>
        <Autocomplete
          id="kind"
          options={options}
          getOptionLabel={(option) => option.name}
          value={(external?.kind && options.find((v) => v.name === external.kind)) || null}
          onChange={(event: any, newValue: any) => {
            setExternal({ ...external, kind: newValue?.name });
          }}
          renderOption={(props: any, option: any) => <li {...props}>{option.name}</li>}
          renderInput={(params: any) => (
            <TextField
              {...params}
              variant="standard"
              label="Kind"
              placeholder="kind"
              error={(errors && errors.kind) || false}
              required
            />
          )}
        />
      </MDBox>
      <MDBox mt={1}>
        <MDInput
          label="Name"
          variant="standard"
          fullWidth
          required
          name="name"
          placeholder="name"
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => onChangeField(e, "name")}
          value={external?.name ? external?.name : ""}
          error={(errors && errors.name) || false}
          helperText={errors && errors.name}
        />
      </MDBox>
      <MDBox mt={1}>
        <MDInput
          label="Value"
          variant="standard"
          fullWidth
          required
          name="value"
          placeholder="value"
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => onChangeField(e, "value")}
          value={external?.value ? external?.value : ""}
          error={(errors && errors.value) || false}
          helperText={errors && errors.value}
        />
      </MDBox>
      <MDBox mt={1}>
        <MDInput
          type="number"
          label="Rm Location"
          variant="standard"
          fullWidth
          required
          name="rm_location_id"
          placeholder="rm location"
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => onChangeField(e, "rm_location_id")}
          value={external?.rm_location_id ? external?.rm_location_id : ""}
          error={(errors && errors.rm_location_id) || false}
          helperText={errors && errors.rm_location_id}
        />
      </MDBox>
      <MDBox mb={2} mt={2}>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <FormControl>
            <MDTypography variant="button">Active Start Date</MDTypography>
            <MDDatePicker
              value={external?.active_start_date || null}
              fullWidth
              options={{
                enableTime: true,
                dateFormat: "M d, Y H:i",
                time_24hr: true,
              }}
              onChange={([date]: any) => handleDateChange("active_start_date", date)}
            />
          </FormControl>
        </Grid>
      </MDBox>
      <MDBox mb={2} mt={2}>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <FormControl>
            <MDTypography variant="button">Active End Date</MDTypography>
            <MDDatePicker
              value={external?.active_end_date || null}
              options={{
                enableTime: true,
                dateFormat: "M d, Y H:i",
                time_24hr: true,
              }}
              onChange={([date]: any) => handleDateChange("active_end_date", date)}
            />
          </FormControl>
        </Grid>
      </MDBox>
      <MDBox mt={2}>
        <MDInput
          label="Matching Category"
          variant="standard"
          fullWidth
          name="matching_category"
          placeholder="matching category"
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            onChangeField(e, "matching_category")
          }
          value={external?.matching_category ? external?.matching_category : ""}
        />
      </MDBox>
    </MDDialog>
  );
}

export default AddUpdate;
