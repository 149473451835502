import { useEffect, useState } from "react";
import { useAppDispatch } from "store/store";
import {
  Card,
  Grid,
  Paper,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Checkbox,
  Icon,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import {
  addUpdateLocationUnits,
  deleteStudio,
  groupUngroupStudio,
  ungroupStudios,
} from "store/thunk/locationThunk";
import MDIconButton from "components/MDIconButton";
import MDDialog from "components/MDDialog";
import Messages from "helper/messages";
import EditStudio from "./editStudio";

const UNITS = 1;
// const MARKET_RATE = "$275";

function ManageUnits(props: any): JSX.Element {
  const dispatch = useAppDispatch();
  const { rmDetails, setRmDetails, getAccessTypePermission } = props;
  const [numberOfUnits, setNumberOfUnits] = useState<any>(rmDetails?.number_of_units);
  const [deleteStudioId, setDeleteStudioId] = useState<any>(false);
  const [editStudio, setEditStudio] = useState<any>(false);
  const [editedData, setEditedData] = useState<any>([]);

  // const [units, setUnits] = useState<any>(rmDetails?.rm_units || []);
  const [units, setUnits] = useState<any>(rmDetails?.rm_units || []);
  const [checkedFilterData, setCheckedFilterData] = useState<any>([]);
  const [selectedStudioCount, setSelectedStudioCount] = useState(0);
  const [ungroupStudio, setUngroupStudio] = useState<boolean>(false);
  const [ungroupId, setUngroupId] = useState<any>("");
  const [editStudioId, setEditStudioId] = useState<any>("");

  // console.log("grouping units before update ++++++", units);
  useEffect(() => {
    if (rmDetails) {
      // console.log("rmDetails ", rmDetails);
      const totalRMUnits = rmDetails?.rm_units?.length;
      setNumberOfUnits(totalRMUnits || UNITS);
      const updateUnits = rmDetails?.rm_units?.map((unit: any) => ({
        ...unit,
        is_checked: unit?.is_vacant ? false : "",
      }));
      setUnits(updateUnits || []);
    }
  }, [rmDetails]);

  // Get the initials of the Location Name
  const getIntialOfLocations = (location: any) => {
    const initials = location
      .split(" ")
      .map((word: any) => word.charAt(0))
      .join("");

    return initials;
  };

  // Get Other fileds
  const rmLocationdData = rmDetails?.rm_units.filter((obj: any) => obj.rm_location_id !== null);
  const rmPropertyData = rmDetails?.rm_units.filter((obj: any) => obj.rm_property_id !== null);
  const addNewUnit = () => {
    const unitName = getIntialOfLocations(rmDetails?.name);
    units.push({
      // type: "Single",
      // market_rate: MARKET_RATE,
      name: `${unitName} - ${units.length + 1}`,
      unit_type_name: "Standard",
      lease_fee: null,
      square_footage: null,
      is_window: false,
      isAdd: true,
      is_vacant: true,
      is_deleted: false,
      comment: "",
      rm_unit_type_id: 1,
      location_id: rmDetails?.cms_location_id,
      rm_location_id: rmLocationdData?.[0]?.rm_location_id,
      rm_property_id: rmPropertyData?.[0]?.rm_property_id,
      is_checked: "",
    });
    setNumberOfUnits(units.length + 1);
    setUnits(units);

    const tempRM = JSON.parse(JSON.stringify(rmDetails));
    tempRM.number_of_units = units.length + 1;
    tempRM.rm_units = units;
    setRmDetails(tempRM);
  };

  useEffect(() => {
    // const obj = {
    //   target: {
    //     value: rmDetails?.number_of_units || UNITS,
    //   },
    // };
    // setNumberOfUnits(rmDetails?.number_of_units || UNITS);
    // if (!rmDetails?.rm_units) {
    //   onChangeUnitNumber(obj);
    // }
  }, []);

  // const onChangeMultipleField = (event: any, index: any) => {
  //   const tempDetails = JSON.parse(JSON.stringify(units));
  //   if (!tempDetails?.[index]?.[event.target.name]) {
  //     tempDetails[index][event.target.name] = event.target.value;
  //   }
  //   tempDetails[index][event.target.name] = event.target.value;
  //   if (tempDetails[index].id) {
  //     tempDetails[index].isUpdate = true;
  //   }
  //   setUnits(tempDetails);

  //   const tempRM = JSON.parse(JSON.stringify(rmDetails));
  //   tempRM.rm_units = tempDetails;
  //   setRmDetails(tempRM);
  // };

  // const onChangeCheckBoxes = (event: any, index: number) => {
  //   const tempDetails = JSON.parse(JSON.stringify(units));
  //   if (!tempDetails?.[index]?.[event.target.name]) {
  //     tempDetails[index][event.target.name] = event.target.checked;
  //   }
  //   tempDetails[index][event.target.name] = event.target.checked;
  //   setUnits(tempDetails);

  //   const tempRM = JSON.parse(JSON.stringify(rmDetails));
  //   tempRM.rm_units = tempDetails;
  //   setRmDetails(tempRM);
  // };

  // const onChangeMultipleStudioField = (event: any, index: number) => {
  // const onChangeMultipleStudioField = (event: any, orginalName: any) => {
  //   const findOriginalIndex = units.findIndex((obj: any) => obj?.name === orginalName);
  //   console.log("findOriginalIndex ", findOriginalIndex);

  //   const tempDetails = [...units]; // Create a shallow copy of the units array

  //   if (findOriginalIndex !== -1) {
  //     const { name, value } = event.target;
  //     tempDetails[findOriginalIndex] = {
  //       ...tempDetails[findOriginalIndex], // Preserve other properties of the object
  //       [name]: value, // Update the specified property with the new value
  //     };

  //     if (tempDetails[findOriginalIndex].id) {
  //       tempDetails[findOriginalIndex].isUpdate = true;
  //     }

  //     setUnits(tempDetails);
  //     const tempRM = { ...rmDetails }; // Create a shallow copy of the rmDetails object
  //     tempRM.rm_units = tempDetails;
  //     setRmDetails(tempRM);
  //   }
  // };

  // const getUnitsBytype = (row: any) => {
  //   let result = row.name;
  //   if (row.unit_type_name === "Triple") {
  //     const elements = (
  //       <>
  //         <p>{row.name}A</p>
  //         <p>{row.name}B</p>
  //         <p>{row.name}C</p>
  //       </>
  //     );
  //     result = elements;
  //   } else if (row.unit_type_name === "Double") {
  //     const elements = (
  //       <>
  //         <p>{row.name}A</p>
  //         <p>{row.name}B</p>
  //       </>
  //     );
  //     result = elements;
  //   } else if (row.unit_type_name === "Large") {
  //     const elements = (
  //       <>
  //         <p>{row.name}A</p>
  //         <p>{row.name}B</p>
  //         <p>{row.name}C</p>
  //         <p>{row.name}D</p>
  //       </>
  //     );
  //     result = elements;
  //   } else if (row.unit_type_name === "Nail/Spa") {
  //     const elements = <p>{row.name}A</p>;
  //     result = elements;
  //   } else {
  //     result = row.name;
  //   }

  //   return result;
  // };

  const onSaveUnits = () => {
    // const data = {
    //   location_id: rmDetails?.location_id,
    //   id: rmDetails?.id,
    //   units: rmDetails?.rm_units || [],
    //   number_of_units: rmDetails?.rm_units?.length,
    // };
    const sendData = {
      updatedUnits: rmDetails?.rm_units,
      id: rmDetails?.id,
    };
    dispatch(addUpdateLocationUnits(sendData));
  };

  // Define default type count
  // const findType = [
  //   { type: "Single", count: 1 },
  //   { type: "Double", count: 2 },
  //   { type: "Triple", count: 3 },
  //   { type: "Large", count: 4 },
  //   { type: "Nail/Spa", count: 1 },
  // ];

  // Find total chair count
  const totalChairCount = () => {
    const totalCount = units?.filter((obj: any) => !obj?.group_id || obj?.id === obj?.group_id);
    return totalCount.length;
  };

  // On Delete Section
  const onDelete = () => {
    if (deleteStudioId) {
      dispatch(
        deleteStudio({
          id: deleteStudioId,
          setDeleteStudioId,
        })
      );
    }
  };

  const unGroupStudios = (e: any, unGroupId: number) => {
    const id: number = unGroupId;
    setUngroupId(id);
    setUngroupStudio(true);
  };

  const unGrouping = () => {
    const sendData = {
      id: rmDetails?.cms_location_id,
      unGroupStudio: ungroupId,
      setUngroupStudio,
    };
    dispatch(ungroupStudios(sendData));
  };

  const deleteDialogProps = {
    open: deleteStudioId ? true : false,
    onClose: () => setDeleteStudioId(false),
    onSave: () => onDelete(),
    title: "Delete Studio",
    size: "sm",
    saveBtn: false,
    deleteBtn: true,
    saveTbtText: "Yes",
    closeBtnText: "No",
  };

  const onCloseGroup = () => {
    setUngroupStudio(false);
  };

  const unGroupStudioProps = {
    open: ungroupId ? true : false,
    onClose: () => onCloseGroup(),
    onSave: () => unGrouping(),
    title: "Ungroup Studio",
    size: "sm",
    saveBtn: false,
    deleteBtn: true,
    saveTbtText: "Yes",
    closeBtnText: "No",
  };

  const editStudios = () => {
    const sendData = {
      updatedUnits: editedData,
      id: rmDetails?.id,
      setEditStudio,
    };
    dispatch(addUpdateLocationUnits(sendData));
  };

  const editStudioProps = {
    open: editStudioId ? true : false,
    onClose: () => setEditStudio(false),
    onSave: () => editStudios(),
    title: "Edit Studio",
    size: "sm",
    saveBtn: true,
    closeBtnText: "No",
    saveTbtText: "Edit",
    saveBtnDisabled: !editedData[0]?.is_vacant ? true : false,
  };

  // Remove Unit if Id not there
  const deleteFromList = (index: any) => {
    const updatedUnits = [...units];
    updatedUnits.splice(index, 1);
    setUnits(updatedUnits);
  };

  // Occupied/UnOccupied function
  const onChangeCheckBoxesGroupUnGroup = (event: any, index: number) => {
    const tempDetails = JSON.parse(JSON.stringify(units));
    tempDetails[index][event.target.name] = event.target.checked;
    setUnits(tempDetails);

    const checkedItems = tempDetails.filter((item: any) => item.is_checked);
    // console.log("checkedItems ", checkedItems);
    const unitTypeNameCounts = checkedItems.reduce((accumulator: any, item: any) => {
      if (item?.unit_type_name === "Standard" || item?.unit_type_name === "Nail/Spa") {
        return accumulator + 1;
      }
      if (item?.unit_type_name === "Double") {
        return accumulator + 2;
      }
      if (item?.unit_type_name === "Triple") {
        return accumulator + 3;
      }
      if (item?.unit_type_name === "Quad") {
        return accumulator + 4;
      }
      return accumulator;
    }, 0);
    setSelectedStudioCount(unitTypeNameCounts);
    setCheckedFilterData(checkedItems);
  };

  const groupUngroup = () => {
    const filterData = units.filter((obj: any) => obj.is_checked === true);
    setCheckedFilterData(filterData);
    const sendData = {
      mainData: filterData,
      id: rmDetails?.cms_location_id,
    };
    dispatch(groupUngroupStudio(sendData));
  };

  const getDiableButtton = () => {
    let showButton = true;
    if (selectedStudioCount && selectedStudioCount <= 4) {
      showButton = false;
    }
    if (checkedFilterData && checkedFilterData.length === 2) {
      showButton = false;
    }
    if (checkedFilterData.length < 2) {
      showButton = true;
    }
    return showButton;
  };

  function calculateTotalLeaseFee(mainLeaseFee: any, group: any) {
    if (group?.length) {
      const groupLeaseFeeSum = group.reduce((total: any, obj: any) => {
        const objLeaseFee = obj?.lease_fee || 0;
        return total + objLeaseFee;
      }, 0);

      return mainLeaseFee + groupLeaseFeeSum;
      // return groupLeaseFeeSum;
    }
    return mainLeaseFee;
  }
  const calculateUnitTypeName = (unitTypeName: string, group: any) => {
    if (unitTypeName === "Standard") {
      const countStandard = group.filter((obj: any) => obj.unit_type_name === "Standard").length;
      const countDouble = group.filter((obj: any) => obj.unit_type_name === "Double").length;
      if (countStandard >= 2 || countDouble === 1) {
        return "Triple";
      }
      if (countStandard >= 1) {
        return "Double";
      }
    }
    if (unitTypeName === "Double") {
      const countStandard = group.filter((obj: any) => obj.unit_type_name === "Standard").length;
      const countDouble = group.filter((obj: any) => obj.unit_type_name === "Double").length;
      if (countDouble === 1 || countStandard === 2 || countStandard === 4) {
        return "Quad";
      }
      if (countDouble === 1 || countStandard === 1) {
        return "Triple";
      }
    }

    return unitTypeName;
  };

  const onEditStudio = (studioId: number) => {
    setEditStudio(true);
    setEditStudioId(studioId);
  };
  return (
    <MDBox mt={4} id="manage_units">
      <Card>
        <MDBox p={3} style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
          <MDTypography variant="h5">
            Manage Studio<span className="required_star">*</span>
          </MDTypography>

          <MDButton
            variant="gradient"
            color="info"
            size="small"
            onClick={() => groupUngroup()}
            disabled={getDiableButtton()}
          >
            Group
          </MDButton>
        </MDBox>
        <MDBox component="form" pb={3} px={3}>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={12}>
              <Paper variant="outlined" sx={{ paddingBottom: 0 }}>
                <MDBox p={2} mt={2}>
                  <MDBox>
                    <MDBox mb={1} mt={1}>
                      {numberOfUnits && (
                        <TableContainer component={Paper}>
                          <Table aria-label="simple table">
                            <TableHead sx={{ display: "contents" }}>
                              <TableRow>
                                <TableCell align="left">Group/UnGroup</TableCell>
                                <TableCell align="left">Studio</TableCell>
                                <TableCell align="left">Type</TableCell>
                                <TableCell align="left">
                                  {rmDetails?.rent_period} Market Rent
                                </TableCell>
                                <TableCell align="left">Square Footage</TableCell>
                                <TableCell align="left">Window?</TableCell>
                                <TableCell align="left">Occupied</TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {units &&
                                units.length > 0 &&
                                units
                                  ?.filter(
                                    (obj: any) => !obj?.group_id || obj?.id === obj?.group_id
                                  )
                                  .map((row: any, index: number) => (
                                    <TableRow
                                      key={row.name}
                                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                                    >
                                      <TableCell align="left">
                                        {row?.is_checked === "" ? (
                                          <Checkbox name="is_checked" disabled />
                                        ) : (
                                          <Checkbox
                                            defaultChecked={
                                              row?.id === row?.group_id ? true : row?.is_checked
                                            }
                                            // defaultChecked={row?.is_checked}
                                            // defaultChecked
                                            name="is_checked"
                                            onChange={(e: any) =>
                                              e.target.checked
                                                ? onChangeCheckBoxesGroupUnGroup(e, index)
                                                : row?.id === row?.group_id
                                                ? unGroupStudios(e, row?.id)
                                                : onChangeCheckBoxesGroupUnGroup(e, index)
                                            }
                                            disabled={!row?.is_vacant}
                                          />
                                        )}
                                      </TableCell>
                                      <TableCell align="left">
                                        {units.filter(
                                          (obj: any) =>
                                            obj.group_id === row?.id && obj?.id !== row?.id
                                        )?.length ? (
                                          <>
                                            {units
                                              .filter(
                                                (obj: any) =>
                                                  obj.group_id === row?.id && obj?.id !== row?.id
                                              )
                                              ?.map((obj: any) => (
                                                <p>{obj?.name}</p>
                                              ))}
                                            <p>{row?.name}</p>
                                          </>
                                        ) : (
                                          <p>{row?.name}</p>
                                        )}
                                      </TableCell>
                                      <TableCell align="left">
                                        {calculateUnitTypeName(
                                          row?.unit_type_name,
                                          units?.filter(
                                            (obj: any) =>
                                              obj.group_id === row?.id && obj?.id !== row?.id
                                          )
                                        )}
                                      </TableCell>
                                      <TableCell align="left">
                                        <p>
                                          {calculateTotalLeaseFee(
                                            row?.lease_fee,
                                            units?.filter(
                                              (obj: any) =>
                                                obj.group_id === row?.id && obj?.id !== row?.id
                                            )
                                          )}
                                        </p>
                                      </TableCell>
                                      <TableCell align="left">
                                        <p>{row?.square_footage || "0"}</p>
                                      </TableCell>
                                      <TableCell align="left">
                                        <Checkbox
                                          checked={row?.is_window || false}
                                          name="vacant"
                                          disabled
                                          sx={{ opacity: "1 !important" }}
                                        />
                                      </TableCell>
                                      <TableCell align="left">
                                        <Checkbox
                                          checked={!row?.is_vacant}
                                          name="vacant"
                                          disabled
                                          sx={{ opacity: "1 !important" }}
                                        />
                                      </TableCell>
                                      <TableCell align="left">
                                        <MDIconButton
                                          tooltipName="Edit"
                                          aria-label="Edit"
                                          color="info"
                                          onClick={() => onEditStudio(row?.id)}
                                          // onClick={() =>
                                          //   row?.id
                                          //     ? setDeleteStudioId(row?.id)
                                          //     : deleteFromList(index)
                                          // }
                                        >
                                          <Icon fontSize="small">edit</Icon>
                                        </MDIconButton>
                                      </TableCell>
                                      <TableCell align="left">
                                        <MDIconButton
                                          tooltipName="Delete"
                                          aria-label="Delete"
                                          color="error"
                                          onClick={() =>
                                            row?.id
                                              ? setDeleteStudioId(row?.id)
                                              : deleteFromList(index)
                                          }
                                        >
                                          <Icon fontSize="small">delete</Icon>
                                        </MDIconButton>
                                      </TableCell>
                                    </TableRow>
                                  ))}
                            </TableBody>
                            <MDBox p={3}>
                              <MDTypography variant="h5">
                                Total Chair Count
                                <span> {totalChairCount()} </span>
                                {/* <span> {units?.length} </span> */}
                              </MDTypography>
                            </MDBox>
                          </Table>
                        </TableContainer>
                      )}
                    </MDBox>
                  </MDBox>
                </MDBox>
                <MDButton
                  variant="contained"
                  onClick={() => addNewUnit()}
                  startIcon={<AddIcon />}
                  disabled={getAccessTypePermission("location_rentmanager", "edit_access")}
                >
                  Add New Studio
                </MDButton>
              </Paper>
            </Grid>
            <Grid item xs={12} display="flex" justifyContent="right" alignItems="flex-start">
              <MDButton
                onClick={onSaveUnits}
                variant="gradient"
                color="info"
                size="small"
                className="fixed-button"
                disabled={getAccessTypePermission("location_studios", "edit_access")}
              >
                Save
              </MDButton>
            </Grid>
          </Grid>
        </MDBox>
        {deleteStudioId && (
          <MDDialog {...deleteDialogProps}>
            <MDTypography variant="h6" fontWeight="medium">
              {Messages.GENERAL.SURE_TO_DELETE}
            </MDTypography>
          </MDDialog>
        )}
        {ungroupStudio && (
          <MDDialog {...unGroupStudioProps}>
            <MDTypography variant="h6" fontWeight="medium">
              {Messages.GENERAL.SURE_TO_UNGROUP_STUDIO}
            </MDTypography>
          </MDDialog>
        )}
        {editStudio && (
          <MDDialog {...editStudioProps}>
            <MDTypography variant="h6" fontWeight="medium">
              <EditStudio
                editStudioId={editStudioId}
                units={units}
                setEditedData={setEditedData}
                editedData={editedData}
                getAccessTypePermission={getAccessTypePermission}
              />
            </MDTypography>
          </MDDialog>
        )}
      </Card>
    </MDBox>
  );
}

export default ManageUnits;
