import MDBox from "components/MDBox";
import { Card, Grid, Icon } from "@mui/material";
import MDDatePicker from "components/MDDatePicker";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";

function Index(): JSX.Element {
  return (
    <Card sx={{ mt: 3 }}>
      <Grid container spacing={3} p={3}>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <MDBox mb={3}>
            <MDTypography variant="h5">Set Dates</MDTypography>
            <Grid p={3} container spacing={3}>
              <Grid item sm={4} md={2} sx={{ mt: 1 }}>
                <MDTypography variant="subtitle2">Survey Send Date</MDTypography>
              </Grid>
              <Grid item sm={8} xs={12} lg={10} md={10} display="flex" alignItems="center">
                <MDDatePicker
                  options={{
                    enableTime: false,
                    dateFormat: "M/d/Y",
                    time_24hr: true,
                  }}
                />
                <Icon className="calendar-with-icon" fontSize="large">
                  calendar_month
                </Icon>
              </Grid>
              <Grid item sm={4} xs={12} md={2} sx={{ mt: 1 }}>
                <MDTypography variant="subtitle2">Survey Close Date</MDTypography>
              </Grid>
              <Grid item sm={8} xs={12} md={10} display="flex" alignItems="center">
                <MDDatePicker
                  options={{
                    enableTime: false,
                    dateFormat: "M/d/Y",
                    time_24hr: true,
                  }}
                />
                <Icon className="calendar-with-icon" fontSize="large">
                  calendar_month
                </Icon>
              </Grid>
              <Grid item xs={12} sm={4} md={2} sx={{ mt: 1 }}>
                <MDTypography variant="subtitle2">Results Send Date</MDTypography>
              </Grid>
              <Grid item xs={12} sm={8} md={10} display="flex" alignItems="center">
                <MDDatePicker
                  options={{
                    enableTime: false,
                    dateFormat: "M/d/Y",
                    time_24hr: true,
                  }}
                />
                <Icon className="calendar-with-icon" fontSize="large">
                  calendar_month
                </Icon>
              </Grid>
              <Grid item sm={12} md={2}>
                <MDButton variant="gradient" color="info" className="xs-small" size="small">
                  Schedule Send
                </MDButton>
              </Grid>
            </Grid>
          </MDBox>
        </Grid>
      </Grid>
    </Card>
  );
}

export default Index;
