import React, { useState, useEffect } from "react";
import MDDialog from "components/MDDialog";
import MDInput from "components/MDInput";
import MDBox from "components/MDBox";
import Card from "@mui/material/Card";
// import MDButton from "components/MDButton";
// import Icon from "@mui/material/Icon";
// import IconButton from "@mui/material/IconButton";
import DataTable from "examples/Tables/DataTable";
import Validations from "helper/validations";
import { addUpdateSubmenu } from "store/thunk/rolesAndMenusThunk";
import { MenuRequest } from "types/custom-types";
import Messages from "helper/messages";
import { getLocalDateTime } from "helper/services";
import { useAppDispatch } from "../../../../store/store";

function PageSection(props: any): JSX.Element {
  const dispatch = useAppDispatch();
  const { open, onClose, menu } = props;
  const [pageSections, setPageSections] = useState([]);
  const [addUpdatePopUP, setAddUpdatePopUP] = useState(false);
  const [alias, setAlias] = useState("");
  const [displayMenu, setDisplayMenu] = useState("");
  const [description, setDescription] = useState("");
  const [errors, setErrors] = useState<any>({});
  const [loading, setLoading] = useState<boolean>(false);
  const [section, setSection] = useState<any>(null);

  useEffect(() => {
    if (menu) {
      if (menu?.page_sections?.length > 0) {
        setPageSections(menu?.page_sections);
      }
    }
  }, [menu]);

  useEffect(() => {
    if (section) {
      setAlias(section?.alias ? section.alias : "");
      setDisplayMenu(section?.display_menu ? section.display_menu : "");
      setDescription(section?.description ? section.description : "");
    }
  }, [section]);

  // const actionButtons = (obj: any) => (
  //   <IconButton
  //     aria-label="Edit"
  //     color="info"
  //     onClick={() => {
  //       setAddUpdatePopUP(true);
  //       setSection(obj);
  //     }}
  //   >
  //     <Icon fontSize="small">edit</Icon>
  //   </IconButton>
  // );

  const getRows = (items: any) => {
    const data: any =
      items &&
      items.map((obj: any) => ({
        id: obj.id,
        alias: obj.alias || "-",
        display_menu: obj.display_menu || "-",
        description: obj.description || "-",
        created_at: obj.created_at ? getLocalDateTime(obj.updated_at) : "-",
        updated_at: obj.updated_at ? getLocalDateTime(obj.updated_at) : "-",
        // action: actionButtons(obj),
      }));
    return data;
  };

  const tableData = {
    columns: [
      { Header: "Alias", accessor: "alias" },
      { Header: "Display name", accessor: "display_menu" },
      { Header: "Description", accessor: "description" },
      // { Header: "Action", accessor: "action", width: "200px", disableSortBy: true },
    ],
    rows: getRows(pageSections.length > 0 ? pageSections : []),
  };

  const clearValues = () => {
    setLoading(false);
    setErrors({});
    setAlias("");
    setDisplayMenu("");
    setDescription("");
    setSection(null);
    onClose();
  };

  const onCloseAddUpdate = () => {
    setAddUpdatePopUP(false);
    clearValues();
  };

  const callback = () => {
    onCloseAddUpdate();
  };

  const onSave = () => {
    const requestData = {
      alias,
      display_menu: displayMenu,
      description,
      menu_order: 0,
    };
    const error = Validations.validatePageSectionForm(requestData);
    setErrors(error);
    if (!Object.keys(error).length) {
      const reqObj: MenuRequest = {
        subMenuId: section ? Number(section?.menu_id) : null,
        mainMenuId: Number(menu?.menu_id),
        alias,
        description,
        displayMenu,
        menuOrder: 0,
        page_section: true,
        callback,
      };
      dispatch(addUpdateSubmenu(reqObj));
    }
  };

  const dialogProps = {
    open,
    onClose,
    title: "Page Sections",
    saveBtn: false,
    size: "md",
    loading,
  };

  const addUpdateDialogProps = {
    open: addUpdatePopUP,
    onClose: onCloseAddUpdate,
    onSave,
    title: section ? "Update Page Section" : "Add Page Section",
    size: "sm",
    loading,
  };

  return (
    <>
      <MDDialog {...dialogProps}>
        <MDBox mt={3}>
          <Card>
            <MDBox
              bgColor="dark"
              color="white"
              coloredShadow="dark"
              borderRadius="xl"
              alignItems="center"
              justifyContent="space-between"
              pt={1}
              pb={1}
              pl={2}
              pr={2}
              ml={2}
              mr={2}
              display="flex"
              mt={-3}
              className="page-header"
            >
              <p className="page-header-label">Page Sections</p>
              {/* <MDButton
                variant="gradient"
                color="light"
                size="small"
                className="xs-small"
                onClick={() => setAddUpdatePopUP(true)}
              >
                <Icon sx={{ fontWeight: "bold" }}>add</Icon> Add New
              </MDButton> */}
            </MDBox>
            <DataTable table={tableData} canSearch />
          </Card>
        </MDBox>
      </MDDialog>
      {addUpdatePopUP && (
        <MDDialog {...addUpdateDialogProps}>
          <MDInput
            label="Alias"
            variant="standard"
            fullWidth
            required
            disabled={section?.menu_id ? true : false}
            name="alias"
            placeholder="Alias"
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => setAlias(e.target.value)}
            value={alias}
            error={(errors && errors.alias) || false}
            helperText={errors && errors.alias ? Messages.ERROR.FIELD_REQUIRED : ""}
          />
          <MDInput
            label="Section Name"
            variant="standard"
            fullWidth
            required
            name="displayMenu"
            placeholder="Section Name"
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => setDisplayMenu(e.target.value)}
            value={displayMenu}
            error={(errors && errors.displayMenu) || false}
            helperText={errors && errors.displayMenu ? Messages.ERROR.FIELD_REQUIRED : ""}
            sx={{ mt: 2 }}
          />
          <MDInput
            label="Description"
            variant="standard"
            fullWidth
            name="description"
            placeholder="Description"
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => setDescription(e.target.value)}
            value={description}
            sx={{ mt: 2 }}
          />
        </MDDialog>
      )}
    </>
  );
}

export default PageSection;
