import {
  InputLabel,
  Grid,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  FormControlLabel,
  FormGroup,
  Checkbox,
} from "@mui/material";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDDialog from "components/MDDialog";
import MDLabelValue from "components/MDLabelValue";

function UserAccess(props: any): JSX.Element {
  const {
    open,
    onClose,
    onSave,
    viewStatus,
    location,
    user,
    locationAccess,
    crmAccess,
    accessData,
    setAccessData,
  } = props;

  const getDefaultCheckedAccess = ({ menuId, accessName }: any) => {
    const isExists: any =
      accessData && accessData?.find((obj: any) => obj.menu_id === Number(menuId));
    if (isExists) {
      return isExists?.[accessName] || false;
    }
    return false;
  };

  const handleChange = (event: any, menuId: any) => {
    const isExists: any = accessData?.find((obj: any) => obj.menu_id === Number(menuId));
    if (isExists) {
      const newAccessData: any = accessData?.filter((obj: any) => obj.menu_id !== Number(menuId));
      const changedData: any = {
        ...isExists,
        menu_id: Number(menuId),
        [event.target.name]: event.target.checked,
        none_access: false,
      };
      if (event.target.checked) {
        if (event.target.name === "edit_access") {
          changedData.view_access = true;
        } else if (event.target.name === "add_access") {
          changedData.view_access = true;
          changedData.edit_access = true;
        }
      }
      newAccessData.push(changedData);
      setAccessData(JSON.parse(JSON.stringify(newAccessData)));
    } else {
      const changedData: any = {
        menu_id: Number(menuId),
        [event.target.name]: event.target.checked,
        none_access: false,
      };
      if (event.target.checked) {
        if (event.target.name === "edit_access") {
          changedData.view_access = true;
        } else if (event.target.name === "add_access") {
          changedData.view_access = true;
          changedData.edit_access = true;
        }
      }
      const userDetailWithNewOne: any = JSON.parse(JSON.stringify(accessData));
      userDetailWithNewOne.push(changedData);
      setAccessData(userDetailWithNewOne);
    }
  };

  const handleChangeOnNone = (event: any, menuId: any) => {
    const isExists: any = accessData?.find((obj: any) => obj.menu_id === Number(menuId));
    if (isExists) {
      const newAccessData: any = accessData?.filter((obj: any) => obj.menu_id !== Number(menuId));
      if (event.target.checked) {
        const changedData = {
          view_access: false,
          edit_access: false,
          add_access: false,
          menu_id: Number(menuId),
          [event.target.name]: event.target.checked,
          none_access: true,
        };
        newAccessData.push(changedData);
        setAccessData(JSON.parse(JSON.stringify(newAccessData)));
      }
    } else {
      const changedData = {
        view_access: false,
        edit_access: false,
        add_access: false,
        menu_id: Number(menuId),
        [event.target.name]: event.target.checked,
        none_access: true,
      };
      const userDetailWithNewOne: any = JSON.parse(JSON.stringify(accessData));
      userDetailWithNewOne.push(changedData);
      setAccessData(userDetailWithNewOne);
    }
  };

  const getCheckboxList = ({ crm }: any) => {
    const html: any = [];
    const selectedDetails: any[] = crm ? crmAccess : locationAccess;
    selectedDetails?.map((obj: any) => {
      html.push(
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <Grid container>
            <Grid item md={12}>
              <InputLabel sx={{ mb: 1, mt: 1, fontWeight: "bold" }}>{obj?.display_menu}</InputLabel>
            </Grid>
            <Grid item md={12}>
              <FormGroup row>
                <MDBox sx={{ display: "flex" }}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={
                          getDefaultCheckedAccess({
                            menuId: obj?.menu_id,
                            accessName: "none_access",
                          }) || false
                        }
                        id={obj?.alias}
                        name="none_access"
                        disabled={viewStatus}
                        onChange={(e: any) => handleChangeOnNone(e, obj?.menu_id)}
                      />
                    }
                    label="None"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={
                          getDefaultCheckedAccess({
                            menuId: obj?.menu_id,
                            accessName: "view_access",
                          }) || false
                        }
                        id={obj?.alias}
                        name="view_access"
                        disabled={
                          viewStatus ||
                          getDefaultCheckedAccess({
                            menuId: obj?.menu_id,
                            accessName: "view_access",
                          })
                        }
                        onChange={(e: any) => handleChange(e, obj?.menu_id)}
                        sx={{
                          opacity:
                            viewStatus ||
                            getDefaultCheckedAccess({
                              menuId: obj?.menu_id,
                              accessName: "view_access",
                            })
                              ? "0.5"
                              : "",
                        }}
                      />
                    }
                    label="View"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={
                          getDefaultCheckedAccess({
                            menuId: obj?.menu_id,
                            accessName: "edit_access",
                          }) || false
                        }
                        id={obj?.alias}
                        name="edit_access"
                        disabled={
                          viewStatus ||
                          getDefaultCheckedAccess({
                            menuId: obj?.menu_id,
                            accessName: "add_access",
                          })
                        }
                        onChange={(e: any) => handleChange(e, obj?.menu_id)}
                        sx={{
                          opacity:
                            viewStatus ||
                            getDefaultCheckedAccess({
                              menuId: obj?.menu_id,
                              accessName: "add_access",
                            })
                              ? "0.5"
                              : "",
                        }}
                      />
                    }
                    label="Edit"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={
                          getDefaultCheckedAccess({
                            menuId: obj?.menu_id,
                            accessName: "add_access",
                          }) || false
                        }
                        id={obj?.alias}
                        name="add_access"
                        disabled={viewStatus}
                        onChange={(e: any) => handleChange(e, obj?.menu_id)}
                      />
                    }
                    label="Add"
                  />
                </MDBox>
              </FormGroup>
            </Grid>
          </Grid>
        </Grid>
      );
      return true;
    });
    return html;
  };

  const userDialog = {
    open,
    onClose,
    onSave,
    title: "User Access",
    size: "sm",
    saveTbtText: "Ok",
  };

  return (
    <MDDialog {...userDialog}>
      <Grid container>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <MDLabelValue label="Location" value={location?.name || "-"} />
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <MDLabelValue label="User" value={user?.login_email || "-"} />
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12} md={12} lg={12} className="checkbox-list-accordian">
          <MDBox className="mui-checkbox-group">
            <Accordion expanded>
              <AccordionSummary className="location-accordion" aria-controls="publishbh-content">
                <MDTypography variant="h6">Location</MDTypography>
              </AccordionSummary>
              <AccordionDetails>
                <Grid container>{getCheckboxList({ crm: false })}</Grid>
              </AccordionDetails>
            </Accordion>
            <Accordion expanded>
              <AccordionSummary className="location-accordion" aria-controls="publishbh-content">
                <MDTypography variant="h6">CRM</MDTypography>
              </AccordionSummary>
              <AccordionDetails>
                <Grid container>{getCheckboxList({ crm: true })}</Grid>
              </AccordionDetails>
            </Accordion>
          </MDBox>
        </Grid>
      </Grid>
    </MDDialog>
  );
}

export default UserAccess;
