import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import { Grid, Card } from "@mui/material";
import MDLabelValue from "components/MDLabelValue";
import { capitalizeFirstLetter } from "helper/services";

function LinkedAccounts(props: any): JSX.Element {
  const { solaProDetail } = props;

  return (
    <Card id="linkedaccounts">
      <MDBox p={3}>
        <MDTypography variant="h5">Linked Accounts</MDTypography>
      </MDBox>
      <MDBox pb={3} px={3}>
        {solaProDetail?.id && (
          <>
            <MDTypography fontSize="14px">
              The fields below indicate if this beauty professional has a linked account for Sola
              Pro App and SolaGenius.
            </MDTypography>

            <Grid item xs={12}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6} md={6}>
                  <MDLabelValue
                    label="Sola Pro App Account"
                    value={
                      ((solaProDetail?.has_sola_pro_login === true ||
                        solaProDetail?.has_sola_pro_login === false) &&
                        capitalizeFirstLetter(solaProDetail?.has_sola_pro_login?.toString())) ||
                      "-"
                    }
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={6}>
                  <MDLabelValue
                    label="SolaGenius Account"
                    value={
                      ((solaProDetail?.has_sola_genius_account === true ||
                        solaProDetail?.has_sola_genius_account === false) &&
                        capitalizeFirstLetter(
                          solaProDetail?.has_sola_genius_account?.toString()
                        )) ||
                      "-"
                    }
                  />
                </Grid>
              </Grid>
            </Grid>
          </>
        )}
      </MDBox>
    </Card>
  );
}

export default LinkedAccounts;
