import { useEffect, useState } from "react";
import { Grid, Icon } from "@mui/material";
import moment from "moment";
import { useLocation } from "react-router-dom";
import MDBox from "components/MDBox";
import Sidenav from "layouts/pages/locations/location-details/crm/sidenav";
import InitialResponse from "layouts/pages/locations/location-details/crm/initial-response";
import TemplateSettings from "layouts/pages/locations/location-details/crm/template-settings";
import FollowUp from "layouts/pages/locations/location-details/crm/follow-up";
import CommunicationDelay from "layouts/pages/locations/location-details/crm/communication-delay";
import AutomaticEnrollment from "layouts/pages/locations/location-details/crm/automatic-enrollment";
import DealOwner from "layouts/pages/locations/location-details/crm/deal-owner";
import Weekend from "layouts/pages/locations/location-details/crm/weekend";
import { useAppDispatch, useAppSelector } from "store/store";
import { updateLocation, getTemplates } from "store/thunk/locationThunk";
import { notificationFail } from "store/slices/notificationSlice";
import MDButton from "components/MDButton";
import Messages from "helper/messages";
import SmsRequestComponent from "layouts/pages/crm/smsRequestComponent";
import { getAuthUser } from "helper/services";

function Index(props: any): JSX.Element {
  const { getAccessTypePermission } = props;
  const locationState: any = useLocation();
  const dispatch = useAppDispatch();
  const { connectLocation, templates } = useAppSelector((state) => state.locationSlice);
  const [crmTabSettings, setCrmTabSettings] = useState<any>({});
  const [errors, setErrors] = useState<any>({});
  const [dripCampaign, setDripCampaign] = useState<any>(false);
  const [dealOwnerId, setDealOwnerId] = useState<any>(null);
  const loginUserData = getAuthUser();

  useEffect(() => {
    const urlParams = new URLSearchParams(locationState.search);
    const urlLocationId = urlParams.get("id") || null;
    if (locationState?.state || urlLocationId) {
      dispatch(
        getTemplates({
          template_alias: `crm-initial-response-email,crm-initial-response-sms,crm-follow-up-email-1,crm-follow-up-email-2,crm-follow-up-email-3,crm-follow-up-email-4,crm-follow-up-email-5,crm-follow-up-email-6,crm-follow-up-email-7,crm-follow-up-email-8,crm-follow-up-email-9,crm-follow-up-email-10,crm-follow-up-sms-1,crm-follow-up-sms-2,crm-follow-up-sms-3,crm-follow-up-sms-4,crm-follow-up-sms-5,crm-follow-up-sms-6,crm-follow-up-sms-7,crm-follow-up-sms-8,crm-follow-up-sms-9,crm-follow-up-sms-10`,
        })
      );
    }
  }, [locationState]);

  useEffect(() => {
    if (connectLocation) {
      // Tempory code for send_sms = true and send_email = false 03-03-2023
      const tempCrmData = connectLocation?.crm_data;
      const updateFolloups = tempCrmData?.followups?.map((obj: any) => {
        const tempObj = { ...obj };
        if (tempObj.send_sms === false) {
          tempObj.send_sms = true;
        }
        if (tempObj.send_email === true) {
          tempObj.send_email = false;
        }
        return tempObj;
      });
      setCrmTabSettings({
        ...tempCrmData,
        followups: updateFolloups,
      });
      // Enable below code once it's changes in PROD and remove above whole till comment
      // setCrmTabSettings(connectLocation?.crm_data);
      setDripCampaign(connectLocation?.drip_campaign);
      if (connectLocation?.deal_default_owner_id) {
        setDealOwnerId({
          value: {
            user_id: connectLocation?.deal_default_owner_id,
            first_name: connectLocation?.connect_user?.first_name || "",
            last_name: connectLocation?.connect_user?.last_name || "",
            profile_picture: connectLocation?.connect_user?.profile_picture || "",
          },
          label: `${connectLocation?.connect_user?.first_name || ""} ${
            connectLocation?.connect_user?.last_name || ""
          } `,
        });
      } else {
        setDealOwnerId(null);
      }
      // setEnableCrm(connectLocation?.is_crm_enable);
    }
  }, [connectLocation]);

  const addToken = (value: any, fieldName: any, followupField?: any) => {
    if (value?.value) {
      if (followupField) {
        const ctrl: any = document.getElementById(`${followupField}_${fieldName}`);
        const templateData = crmTabSettings?.followups?.[fieldName]?.[followupField] || "";

        const newTemplateContent = `${templateData.slice(0, ctrl.selectionStart)} {{${
          value?.value
        }}} ${templateData.slice(ctrl.selectionStart)}`;

        const followups = crmTabSettings?.followups
          ? JSON.parse(JSON.stringify(crmTabSettings?.followups))
          : [];

        followups[fieldName][followupField] = newTemplateContent;

        setCrmTabSettings({
          ...crmTabSettings,
          followups,
        });
      } else {
        const ctrl: any = document.getElementById(fieldName);
        const templateData = crmTabSettings[fieldName];
        const newTemplateContent = `${templateData.slice(0, ctrl.selectionStart)} {{${
          value?.value
        }}} ${templateData.slice(ctrl.selectionStart)}`;

        setCrmTabSettings({
          ...crmTabSettings,
          [fieldName]: newTemplateContent,
        });
      }
    }
  };

  const onChangeField = (event: any) => {
    if (event.target.name === "timezone" && event.target.value) {
      setErrors({ timezoneError: "" });
    }
    setCrmTabSettings({ ...crmTabSettings, [event.target.name]: event.target.value });
  };

  const onChangeDealOwner = (name: any, value: any) => {
    console.log("value", value);
    setDealOwnerId(value);
  };

  const onChangeEditor = (value: any, name: string) => {
    setCrmTabSettings({ ...crmTabSettings, [name]: value });
  };

  const getTemplateContent = (templateAlias: string) => {
    let content = "";
    if (templates && templates?.length > 0) {
      content = templates.find(
        (obj: any) => obj.template_alias === templateAlias
      )?.template_content;
    }
    return content;
  };

  const onChangeToggle = (event: any) => {
    const currentIndex: any = Number(event.target.name.split("_")[2]);
    if (
      crmTabSettings?.[`follow_up_${currentIndex - 1}`] ||
      currentIndex - 1 === 0 ||
      !currentIndex
    ) {
      const obj: any = {
        [event.target.name]: event.target.checked,
      };

      if (event.target.name === `follow_up_${currentIndex}`) {
        obj[`${event.target.name}_sms_content`] =
          crmTabSettings?.[`${event.target.name}_sms_content`] ||
          getTemplateContent(`crm-follow-up-sms-${currentIndex}`);
        obj[`${event.target.name}_email_content`] =
          crmTabSettings?.[`${event.target.name}_email_content`] ||
          getTemplateContent(`crm-follow-up-email-${currentIndex}`);
      }

      if (event.target.name === "initial_response") {
        obj.initial_response_email_content =
          crmTabSettings?.initial_response_email_content ||
          getTemplateContent(`crm-initial-response-email`);
        obj.initial_response_sms_content =
          crmTabSettings?.initial_response_sms_content ||
          getTemplateContent(`crm-initial-response-sms`);
      }

      setCrmTabSettings({
        ...crmTabSettings,
        ...obj,
      });
    } else {
      dispatch(notificationFail(Messages.ERROR.SELECT_FOLLOWUP_IN_SEQUENCE));
    }
  };

  const onSave = () => {
    if (
      (crmTabSettings.send_initial_contact_from && !crmTabSettings.send_initial_contact_to) ||
      (!crmTabSettings.send_initial_contact_from && crmTabSettings.send_initial_contact_to)
    ) {
      setErrors({ initialTimeInvalid: "Please select both time" });
    } else if (
      crmTabSettings.send_initial_contact_from &&
      crmTabSettings.send_initial_contact_to &&
      moment(crmTabSettings.send_initial_contact_to).unix() <
        moment(crmTabSettings.send_initial_contact_from).unix()
    ) {
      setErrors({ initialTimeInvalid: "Please select valid time range" });
    } else if (
      !crmTabSettings?.timezone &&
      crmTabSettings.send_initial_contact_from &&
      crmTabSettings.send_initial_contact_to
    ) {
      setErrors({ timezoneError: "Please select Timezone" });
    } else {
      setErrors({});
      const crmTabData = { ...crmTabSettings };

      const getTimeWithTimezoneUtc = (time: any, timezone: any) => {
        const convertedInUTC = moment(time).format();
        let currentTimezoneTime = moment().tz(timezone).format();
        currentTimezoneTime = moment(currentTimezoneTime)
          .tz(timezone)
          .set("hours", moment(convertedInUTC).get("hour"))
          .set("minute", moment(convertedInUTC).get("minute"))
          .set("hours", moment(convertedInUTC).tz(timezone).get("hour"))
          .set("minute", moment(convertedInUTC).tz(timezone).get("minute"))
          .set("second", 0)
          .format();
        return moment(currentTimezoneTime).utc().toISOString();
      };

      Object.assign(crmTabData, {
        send_initial_contact_from: crmTabSettings?.send_initial_contact_from
          ? getTimeWithTimezoneUtc(crmTabSettings.send_initial_contact_from, crmTabData.timezone)
          : null,
        send_initial_contact_to: crmTabSettings?.send_initial_contact_to
          ? getTimeWithTimezoneUtc(crmTabSettings.send_initial_contact_to, crmTabData.timezone)
          : null,
      });
      const requestData = {
        tabName: "crm",
        id: connectLocation?.cms_location_id,
        crmData: JSON.stringify({ ...crmTabData }),
        dripCampaign,
        dealDefaultOwnerId: dealOwnerId?.value?.user_id ? dealOwnerId?.value?.user_id : null,
        whoAddedId: Number(loginUserData?.user_id),
      };
      dispatch(updateLocation(requestData));
    }
  };

  /* const enableCrmSection = () => {
    setEnableCrm(true);
  }; */

  const addFollowUp = () => {
    const tempCrmData = JSON.parse(JSON.stringify(crmTabSettings));
    const tempFollowups = tempCrmData?.followups || [];

    const newIndex = tempFollowups && tempFollowups.length ? tempFollowups.length + 1 : 1;
    const newFollowUp = {
      sms_content: getTemplateContent(`crm-follow-up-sms-${newIndex}`) || "",
      email_content: getTemplateContent(`crm-follow-up-email-${newIndex}`) || "",
      send_sms: true,
      send_email: true,
      send_follow_up_days: "1",
    };
    tempFollowups.push(newFollowUp);
    setCrmTabSettings({
      ...crmTabSettings,
      followups: tempFollowups,
    });
  };

  const removeFollowUp = (removeKey?: number) => {
    setCrmTabSettings({
      ...crmTabSettings,
      followups: crmTabSettings?.followups.filter((_: any, key: number) => key !== removeKey),
    });
  };

  const onChangeEmailField = (value: any, fieldName: string, key?: number) => {
    const tempCrmData = JSON.parse(JSON.stringify(crmTabSettings));
    const followups = tempCrmData?.followups || [];
    followups[key][fieldName] = value;
    setCrmTabSettings({
      ...crmTabSettings,
      followups,
    });
  };

  const onChangeFollowUpField = (e: any, fieldName: string, key?: number) => {
    const tempCrmData = JSON.parse(JSON.stringify(crmTabSettings));
    const followups = tempCrmData?.followups || [];
    followups[key][fieldName] = e.target.value;
    setCrmTabSettings({
      ...crmTabSettings,
      followups,
    });
  };

  const onChangeFollowUpTime = (e: any, fieldName: string, key?: number) => {
    const tempCrmData = JSON.parse(JSON.stringify(crmTabSettings));
    const followups = tempCrmData?.followups || [];
    followups[key][fieldName] = e ? moment(e) : null;
    setCrmTabSettings({
      ...crmTabSettings,
      followups,
    });
  };
  const onChangeInitialTime = (fieldName: string, e: any) => {
    if (e && !crmTabSettings?.timezone) {
      setErrors({ timezoneError: "Please select Timezone" });
    } else {
      if (e) {
        setErrors({ timezoneError: "" });
      }
      setCrmTabSettings({
        ...crmTabSettings,
        [fieldName]: e ? moment(e) : null,
      });
    }
  };

  const onChangeFollowUpToggle = (e: any, fieldName: string, key?: number) => {
    const tempCrmData = JSON.parse(JSON.stringify(crmTabSettings));
    const followups = tempCrmData?.followups || [];
    followups[key][fieldName] = e.target.checked;
    setCrmTabSettings({
      ...crmTabSettings,
      followups,
    });
  };

  const displayAddNewBtn = () => {
    let status = true;
    if (crmTabSettings?.followups?.length === 10) {
      status = false;
    }
    return status;
  };

  const attr = {
    ...props,
    connectLocation,
    crmTabSettings,
    errors,
    addToken,
    onChangeField,
    onChangeToggle,
    onChangeEditor,
    setErrors,
    onSave,
    onChangeEmailField,
    onChangeFollowUpField,
    onChangeFollowUpTime,
    onChangeInitialTime,
    onChangeFollowUpToggle,
    removeFollowUp,
    dripCampaign,
    setDripCampaign,
    setDealOwnerId,
    dealOwnerId,
    onChangeDealOwner,
  };

  const getAllFollowItems = () => {
    const fItems: any = [];
    if (crmTabSettings?.followups?.length) {
      for (let i = 0; i < crmTabSettings?.followups?.length; i++) {
        fItems.push(
          <Grid item xs={12} key={`follow-up-grid-${i}`}>
            <FollowUp {...attr} itemKey={i} key={`follow-up-item-${i}`} />
          </Grid>
        );
      }
    }
    return fItems;
  };

  return (
    <>
      {/* {!enableCrm && (
        <MDBox mt={7}>
          <Card>
            <MDBox padding={2} textAlign="center">
              <MDTypography variant="h5">Enabled CRM Features?</MDTypography>
              <MDTypography variant="body" fontSize={16}>
                Once enabled, you will no longer have access to HubSpot.
              </MDTypography>
            </MDBox>
            <MDBox padding={2} textAlign="center">
              <MDButton
                onClick={enableCrmSection}
                variant="outlined"
                color="secondary"
                size="small"
              >
                Enable CRM
              </MDButton>
            </MDBox>
          </Card>
        </MDBox>
      )} */}
      {(!connectLocation?.connect_twillio_phone_number ||
        connectLocation?.connect_twillio_phone_number?.status !== "enabled") && (
        <SmsRequestComponent locationData={connectLocation} section="crm" />
      )}
      {connectLocation?.connect_twillio_phone_number &&
        connectLocation?.connect_twillio_phone_number?.status === "enabled" && (
          <Grid container spacing={3} mt={1}>
            <Grid item xs={12} lg={3}>
              <Sidenav {...attr} />
            </Grid>
            <Grid item xs={12} lg={9}>
              <MDBox mb={3}>
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <DealOwner {...attr} />
                  </Grid>
                  <Grid item xs={12}>
                    <Weekend {...attr} />
                  </Grid>
                  <Grid item xs={12}>
                    <AutomaticEnrollment {...attr} />
                  </Grid>
                  <Grid item xs={12}>
                    <CommunicationDelay {...attr} />
                  </Grid>
                  <Grid item xs={12}>
                    <InitialResponse {...attr} />
                  </Grid>
                  <Grid
                    container
                    item
                    xs={12}
                    key={`follow-up-items-${crmTabSettings?.followups?.length}`}
                    spacing={3}
                  >
                    {getAllFollowItems()}
                  </Grid>
                  {displayAddNewBtn() && (
                    <Grid item xs={12}>
                      <MDButton
                        variant="gradient"
                        color="info"
                        size="small"
                        className="xs-small"
                        onClick={addFollowUp}
                        disabled={getAccessTypePermission("location_crm", "edit_access")}
                      >
                        <Icon sx={{ fontWeight: "bold" }}>add</Icon> Add Follow-Up
                      </MDButton>
                    </Grid>
                  )}
                  <Grid item xs={12}>
                    <TemplateSettings {...attr} />
                  </Grid>
                  <Grid item xs={12}>
                    <MDButton
                      sx={{ float: "right" }}
                      color="info"
                      size="small"
                      onClick={onSave}
                      className="fixed-button"
                      disabled={getAccessTypePermission("location_crm", "edit_access")}
                    >
                      Save
                    </MDButton>
                  </Grid>
                </Grid>
              </MDBox>
            </Grid>
          </Grid>
        )}
    </>
  );
}

export default Index;
