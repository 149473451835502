import { Card, Checkbox, Grid, Icon } from "@mui/material";
import MDBox from "components/MDBox";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import DataTable from "examples/Tables/DataTable";
import { useCallback, useEffect, useLayoutEffect, useRef, useState } from "react";
import exportData from "helper/exportTableConst";
import { useAppDispatch, useAppSelector } from "store/store";
import { deleteWebpageRecord, getWebPageDetails, getWebPagesList } from "store/thunk/solaProsThunk";
import {
  setDefaultSelectedColumns,
  removeExportFields,
  adminFranchisie,
  getAssociationLocations,
} from "helper/services";
import MDIconButton from "components/MDIconButton";
import { getDefaultCoulmns, saveSelectedAllData } from "store/thunk/connectUserThunk";
import { setDefaultsColumns } from "store/slices/connectUsersSlice";
import MDButton from "components/MDButton";
import { useNavigate } from "react-router-dom";
import { setWebPageDetail } from "store/slices/solaProSlice";
import ViewDetails from "layouts/pages/sola-web/viewDetails";
import ImagePreview from "layouts/pages/ImagePreview";
import MDDialog from "components/MDDialog";
import MDTypography from "components/MDTypography";
import Messages from "helper/messages";

function Index(): JSX.Element {
  const dispatch = useAppDispatch();
  const fetchIdRef = useRef(0);
  const navigate = useNavigate();
  const { webPagesList } = useAppSelector((state) => state.solaProSlice);
  const { defaultSelectedColumns } = useAppSelector((state) => state.connectUsersReducer);
  const { userData } = useAppSelector((state) => state.authReducer);
  const [pageCount, setPageCount] = useState(0);
  const [pageNo, setPageNo] = useState(0);
  const [perPage, setPerPage] = useState(0);
  const [totalCount, setTotalCount] = useState(0);
  const [search, setSearch] = useState<any>(null);
  const [sortBy, setSortBy] = useState<any>([]);
  const [isCheckAll, setIsCheckAll] = useState(false);
  const [isPreview, setImagePreview] = useState(false);
  const [imageToPreview, setImageToPreview] = useState("");
  const [selectedItems, setSelectedItems] = useState([]);
  const [updateWebsites, setUpdateWebsites] = useState([]);
  const [displayColumns, setDisplayColumns] = useState<any>([]);
  const [viewPopUp, setViewPopUp] = useState<boolean>(false);
  const [deleteWebPageRequest, setDeleteWebPageRequest] = useState<boolean>(false);
  const [deleteWebPageRequestId, setDeleteWebPageRequestId] = useState<boolean>(null);
  const [defaultExport, setDefaultExport] = useState<any>([]);
  const [callFetch, setCallFetch] = useState<any>(false);
  const [defaultSorting, setDefaultSorting] = useState<any>([]);
  const [defaultFilteing, setDefaultFilteing] = useState<any>([]);

  useEffect(() => {
    if (webPagesList && webPagesList?.data) {
      setUpdateWebsites(webPagesList?.data);
      setPageCount(webPagesList?.meta?.total_pages);
      setTotalCount(webPagesList?.meta?.total_count);
    }
  }, [webPagesList]);

  const getTestimonialImages = () => {
    const arr: any = [];
    [...Array(10)].map((item: any, ix: any) => {
      arr.push({
        Header: `Testimonial #${ix + 1}`,
        accessor: `testimonial_id_${ix + 1}`,
        export: true,
        checked: false,
        associative_colums: `testimonial_${ix + 1}.name`,
        disableSortBy: true,
      });
      return true;
    });
    [...Array(10)].map((item: any, ix: any) => {
      arr.push({
        Header: `Image #${ix + 1}`,
        accessor: `image_${ix + 1}_url`,
        export: true,
        checked: false,
        hide_columns: true,
        disableSortBy: true,
      });
      return true;
    });
    return arr;
  };

  // Get columns
  const getColumns = () => {
    const columns: any = [
      {
        Header: "",
        disableSortBy: true,
        accessor: "checkbox",
        export: false,
        width: "100px",
        checked: true,
      },
      {
        Header: "Business Name",
        accessor: "business_name",
        export: true,
        disableCheck: true,
        checked: true,
      },
      {
        Header: "Subtitle",
        accessor: "subtitle",
        export: true,
        disableCheck: true,
        checked: true,
      },
      { Header: "Studio Number", accessor: "studio_number", export: true, checked: true },
      { Header: "Website Email Address", accessor: "website_email", export: true, checked: true },
      {
        Header: "Website Phone Number",
        accessor: "website_phone",
        export: true,
        checked: true,
      },
      { Header: "Url", accessor: "url", export: true, checked: true },
      { Header: "Website Url", accessor: "website_url", export: true, checked: true },
      { Header: "Booking Url", accessor: "booking_url", export: true, checked: true },
      { Header: "Facebook Url", accessor: "facebook_url", export: true, checked: true },
      { Header: "Google Plus Url", accessor: "google_plus_url", export: true, checked: false },
      { Header: "Instagram Url", accessor: "instagram_url", export: true, checked: false },
      { Header: "Linkedin Url", accessor: "linkedin_url", export: true, checked: false },
      { Header: "Pinterest Url", accessor: "pinterest_url", export: true, checked: false },
      { Header: "Twitter Url", accessor: "twitter_url", export: true, checked: false },
      { Header: "Yelp Url", accessor: "yelp_url", export: true, checked: false },
      { Header: "Tik Tok Url", accessor: "tik_tok_url", export: true, checked: false },
      ...getTestimonialImages(),
      {
        Header: "Action",
        accessor: "action",
        width: "200px",
        checked: true,
        export: false,
        disableSortBy: true,
      },
    ];
    return columns;
  };

  const [checkedColumns, setCheckedColumns] = useState<any>(getColumns());
  const latestRefValue = useRef(checkedColumns);

  useEffect(() => {
    latestRefValue.current = checkedColumns;
  }, [checkedColumns]);

  const fetchData = useCallback(({ pageSize, pageIndex, search, sortBy, isManual }) => {
    const fetchId = ++fetchIdRef.current;
    const sort: any = sortBy || [];
    const filter: any = [];

    setTimeout(() => {
      if (fetchId === fetchIdRef.current) {
        const sentReq = {
          sendData: {
            user_id: userData?.user_id,
            table_name: exportData.connect_webpages.table_name,
            table_columns: latestRefValue.current ? latestRefValue.current : checkedColumns, // Changes in checkedColumns
            table_sort: sortBy && sortBy?.length ? sortBy : [],
            table_filter: filter,
            default_export_data: defaultExport,
          },
        };
        if (isManual) {
          dispatch(saveSelectedAllData(sentReq));
        }

        setPageNo(pageIndex);
        setSearch(search);
        setSortBy(sort);
        setPerPage(pageSize);
        setIsCheckAll(false);
        dispatch(
          getWebPagesList({
            pageSize,
            pageIndex,
            search,
            sort,
          })
        );
      }
    }, 1000);
  }, []);

  useLayoutEffect(() => {
    dispatch(setDefaultsColumns([]));

    // Set data in variable and Call defaultColums Thunk
    const sentReq = {
      user_id: userData?.user_id,
      table_name: exportData.connect_webpages.table_name,
    };
    dispatch(getDefaultCoulmns(sentReq)).then((resp: any) => {
      // SD-3199
      if (resp?.payload) {
        const respFilter = resp?.payload?.table_filter || [];
        const respSorting = resp?.payload?.table_sort || [];
        const defaultDBexportData = resp?.payload?.default_export_data || [];
        const defaultDBColumn = resp?.payload?.table_columns || [];
        const tempdata = setDefaultSelectedColumns(defaultDBColumn, getColumns());
        setCheckedColumns(tempdata);
        setDefaultExport(defaultDBexportData);
        setDefaultSorting(respSorting);
        setSortBy(respSorting);
        setDefaultFilteing(respFilter);
        setCallFetch(true);
        fetchData({
          perPage,
          pageNo,
          search,
          sortBy: respSorting && respSorting?.length ? respSorting : [],
          additionalField: null,
          filterData: respFilter && respFilter?.length ? respFilter : [],
        });
      }
    });
  }, []);

  useEffect(() => {
    if (checkedColumns?.length) {
      setDisplayColumns(checkedColumns.filter((col: any) => col.checked));
    }
  }, [checkedColumns]);

  useEffect(() => {
    if (defaultSelectedColumns && defaultSelectedColumns?.length) {
      const newColumns = setDefaultSelectedColumns(defaultSelectedColumns, getColumns());
      setCheckedColumns(newColumns);
    } else {
      setCheckedColumns(getColumns());
    }
  }, [defaultSelectedColumns]);

  const previewImage = (image: any) => {
    setImagePreview(true);
    setImageToPreview(image);
  };

  const closeImagePreview = () => {
    setImagePreview(false);
    setImageToPreview("");
  };

  const getImage = (imageUrl: any) => (
    <Grid container>
      <Grid item>
        {imageUrl && (
          <Grid
            container
            onClick={() => previewImage(imageUrl)}
            justifyContent="center"
            alignItems="center"
            textAlign="center"
            className="thumbnail-img"
          >
            <img width="100%" height="100px" src={imageUrl} alt="original" />
          </Grid>
        )}
      </Grid>
    </Grid>
  );

  const onChangeCheckBox = (e: any) => {
    let items = JSON.parse(JSON.stringify(selectedItems));
    if (e.target.checked && !items.includes(e.target.value)) {
      items.push(e.target.value);
      if (items.length === updateWebsites.length) {
        setIsCheckAll(true);
      }
    } else {
      items = items.filter((id: any) => id !== e.target.value);
      setIsCheckAll(false);
    }
    setSelectedItems(items);
  };

  const checkUncheckAll = (e: any) => {
    let allIds: any = [];
    allIds = updateWebsites.map((val: any) => String(val.id));
    if (e.target.checked) {
      const selectedIds = [...new Set([...selectedItems, ...allIds])];
      setSelectedItems(selectedIds);
      setIsCheckAll(true);
    } else {
      allIds = updateWebsites.map((val: any) => String(val.id));
      allIds = selectedItems.filter((el: any) => !allIds.includes(el));
      setSelectedItems([...allIds]);
      setIsCheckAll(false);
    }
  };

  const openViewPopup = (obj: any) => {
    dispatch(getWebPageDetails({ id: obj.id, platform: "web" }));
    setViewPopUp(true);
  };

  const closeViewPopup = () => {
    setViewPopUp(false);
  };

  const goToEditWebPage = (obj: any) => {
    navigate(`/sola-pros/web-pages/edit-webpage`, {
      state: { prevPath: "/sola-pros/web-pages", id: obj?.id },
    });
  };

  const onCloseDeleteModal = () => {
    setDeleteWebPageRequest(false);
    setDeleteWebPageRequestId(null);
  };

  const deleteWebpage = (obj: any) => {
    setDeleteWebPageRequest(true);
    setDeleteWebPageRequestId(obj?.id);
  };

  const onDelete = () => {
    dispatch(
      deleteWebpageRecord({
        pageSize: perPage,
        pageIndex: pageNo,
        search,
        sort: sortBy,
        connect_webpage_id: deleteWebPageRequestId,
        callBack: onCloseDeleteModal,
      })
    );
  };

  const actionButtons = (obj: any) => (
    <>
      <MDIconButton
        tooltipName="View"
        aria-label="Info"
        color="info"
        onClick={() => openViewPopup(obj)}
      >
        <Icon fontSize="small">visibility</Icon>
      </MDIconButton>
      {adminFranchisie() && (
        <MDIconButton
          tooltipName="Edit"
          aria-label="Edit"
          color="info"
          onClick={() => goToEditWebPage(obj)}
        >
          <Icon fontSize="small">edit</Icon>
        </MDIconButton>
      )}
      {adminFranchisie() && (
        <MDIconButton
          tooltipName="Delete"
          aria-label="Delete"
          color="error"
          onClick={() => deleteWebpage(obj)}
        >
          <Icon fontSize="small">delete</Icon>
        </MDIconButton>
      )}
    </>
  );

  const webPageAddPage = () => {
    dispatch(setWebPageDetail({}));
    navigate(`/sola-pros/web-pages/create-webpage`, {
      state: { prevPath: "/sola-pros/web-pages" },
    });
  };

  const getRows = () => {
    const data: any = updateWebsites.map((obj: any) => ({
      checkbox: (
        <Checkbox
          checked={selectedItems.includes(obj?.id.toString())}
          onChange={(e: any) => onChangeCheckBox(e)}
          value={obj?.id}
        />
      ),
      id: obj?.id || "-",
      business_name: obj?.business_name || "-",
      subtitle: obj?.subtitle || "-",
      studio_number: obj?.studio_number || "-",
      website_email: obj?.website_email || "-",
      website_phone: obj?.website_phone || "-",
      url: obj?.url || "-",
      website_url: obj?.website_url || "-",
      booking_url: obj?.booking_url || "-",
      pinterest_url: obj?.pinterest_url || "-",
      facebook_url: obj?.facebook_url || "-",
      twitter_url: obj?.twitter_url || "-",
      instagram_url: obj?.instagram_url || "-",
      linkedin_url: obj?.linkedin_url || "-",
      yelp_url: obj?.yelp_url || "-",
      google_plus_url: obj?.google_plus_url || "-",
      tik_tok_url: obj?.tik_tok_url || "-",
      testimonial_id_1: obj?.testimonial_1?.name ? obj?.testimonial_1?.name || "-" : "-",
      testimonial_id_2: obj?.testimonial_2?.name ? obj?.testimonial_2?.name || "-" : "-",
      testimonial_id_3: obj?.testimonial_3?.name ? obj?.testimonial_3?.name || "-" : "-",
      testimonial_id_4: obj?.testimonial_4?.name ? obj?.testimonial_4?.name || "-" : "-",
      testimonial_id_5: obj?.testimonial_5?.name ? obj?.testimonial_5?.name || "-" : "-",
      testimonial_id_6: obj?.testimonial_6?.name ? obj?.testimonial_6?.name || "-" : "-",
      testimonial_id_7: obj?.testimonial_7?.name ? obj?.testimonial_7?.name || "-" : "-",
      testimonial_id_8: obj?.testimonial_8?.name ? obj?.testimonial_8?.name || "-" : "-",
      testimonial_id_9: obj?.testimonial_9?.name ? obj?.testimonial_9?.name || "-" : "-",
      testimonial_id_10: obj?.testimonial_10?.name ? obj?.testimonial_10?.name || "-" : "-",
      image_1_url: obj?.image_1_url ? getImage(obj?.image_1_url) : "-",
      image_2_url: obj?.image_2_url ? getImage(obj?.image_2_url) : "-",
      image_3_url: obj?.image_3_url ? getImage(obj?.image_3_url) : "-",
      image_4_url: obj?.image_4_url ? getImage(obj.image_4_url) : "-",
      image_5_url: obj?.image_5_url ? getImage(obj.image_5_url) : "-",
      image_6_url: obj?.image_6_url ? getImage(obj?.image_6_url) : "-",
      image_7_url: obj?.image_7_url ? getImage(obj?.image_7_url) : "-",
      image_8_url: obj?.image_8_url ? getImage(obj?.image_8_url) : "-",
      image_9_url: obj?.image_9_url ? getImage(obj?.image_9_url) : "-",
      image_10_url: obj?.image_10_url ? getImage(obj?.image_10_url) : "-",
      action: actionButtons(obj),
    }));
    return data;
  };

  // Remove uncheck boxes
  const clearCheckItem = () => {
    setSelectedItems([]);
  };

  const tableData = {
    columns: getColumns(),
    rows: getRows(),
  };

  const deleteDialogProps = {
    open: deleteWebPageRequest,
    onClose: () => onCloseDeleteModal(),
    onSave: () => onDelete(),
    title: "Delete Webpage",
    size: "sm",
    saveBtn: false,
    deleteBtn: true,
    saveTbtText: "Yes",
    closeBtnText: "No",
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={4} pb={3}>
        <Card>
          <MDBox
            bgColor="dark"
            color="white"
            coloredShadow="dark"
            borderRadius="xl"
            alignItems="center"
            justifyContent="space-between"
            pt={1}
            pb={1}
            pl={2}
            pr={2}
            ml={2}
            mr={2}
            display="flex"
            className="page-header"
            mt={-3}
          >
            <p className="page-header-label">Web Pages</p>
            {adminFranchisie() && (
              <MDButton
                variant="gradient"
                color="light"
                size="small"
                className="xs-small"
                onClick={webPageAddPage}
              >
                <Icon sx={{ fontWeight: "bold" }}>add</Icon> Add New
              </MDButton>
            )}
          </MDBox>
          {(defaultSorting || defaultSorting?.length) && callFetch && (
            <DataTable
              defaultSortingColumns={defaultSorting}
              defaultFilteringColums={defaultFilteing}
              defaultDisplayColumns={displayColumns}
              setCheckedColumns={setCheckedColumns}
              table={tableData}
              canSearch
              searchText="Search..."
              selectedItems={selectedItems}
              showExportSelectedBtn
              showExportAllBtn
              pageCount={pageCount}
              pageNo={pageNo}
              setPageNo={setPageNo}
              totalCount={totalCount}
              fetchData={fetchData}
              exportFields={removeExportFields(exportData.connect_webpages.export_fields)}
              exportTable={exportData.connect_webpages.table_name}
              exportType={exportData.connect_webpages.export_type}
              manualSearch
              manualSort
              isCheckAll={isCheckAll}
              isDisplayCheckAllCheckbox
              locationsIds={getAssociationLocations()}
              onChangeCheckAllCheckbox={checkUncheckAll}
              checkedColumns={checkedColumns}
              clearCheckItem={clearCheckItem}
            />
          )}
          {viewPopUp && (
            <ViewDetails
              open={viewPopUp}
              onClose={() => closeViewPopup()}
              sortBy={sortBy}
              search={search}
              perPage={perPage}
            />
          )}
          {deleteWebPageRequest && (
            <MDDialog {...deleteDialogProps}>
              <MDTypography variant="h6" fontWeight="medium">
                {Messages.GENERAL.SURE_TO_DELETE}
              </MDTypography>
            </MDDialog>
          )}
          <ImagePreview
            imageData={imageToPreview}
            open={isPreview}
            onCloseImage={() => {
              closeImagePreview();
            }}
          />
        </Card>
      </MDBox>
    </DashboardLayout>
  );
}

export default Index;
