import MDBox from "components/MDBox";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import LocationWizardListing from "layouts/pages/locations/location-wizard/locationListing";

function Index(): JSX.Element {
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox mt={5}>
        <LocationWizardListing />
      </MDBox>
    </DashboardLayout>
  );
}

export default Index;
