import { FormControl, InputLabel } from "@mui/material";
import Select from "react-select";
import CustomErrorMessage from "components/CustomErrorMessage";

function Index(props: any): JSX.Element | null {
  const {
    options,
    name,
    value,
    onChange,
    multiple,
    fieldLabel,
    required,
    error,
    disabled,
    placeholder,
    customWidth,
    isClearable,
    isSearchable = true,
  } = props;

  const formControllCss = { marginTop: "7px" };
  if (customWidth) {
    Object.assign(formControllCss, { maxWidth: customWidth });
  }

  const style = {
    control: (base: any) => ({
      ...base,
      height: "auto",
      border: "none",
      boxShadow: "0 !important",
      borderBottom: error ? "1px solid red" : "1px solid #d2d6da",
      width: "100%",
      borderRadius: "0px !important",
      textAlign: "left",
      fontSize: 14,
    }),
    menu: (provided: any) => ({
      ...provided,
      zIndex: 99,
      fontSize: 14,
    }),
    valueContainer: (valueContainer: any) => ({
      ...valueContainer,
      padding: "0 0 0 8px",
    }),
  };

  return (
    <FormControl required={required} fullWidth sx={formControllCss}>
      <InputLabel htmlFor="name" shrink sx={{ marginLeft: "-12px", marginTop: "-10px" }}>
        {fieldLabel || ""}
      </InputLabel>
      <Select
        menuPlacement="auto"
        isClearable={isClearable || false}
        options={options || []}
        isMulti={multiple || false}
        isDisabled={disabled || false}
        onChange={(e: any) => onChange(e, name)}
        value={value}
        styles={style}
        blurInputOnSelect={false}
        placeholder={placeholder || "Select..."}
        isSearchable={isSearchable}
      />
      {error && <CustomErrorMessage message={error} />}
    </FormControl>
  );
}
export default Index;
