import { useState } from "react";
import { Box, Card, Checkbox, Icon, Tooltip } from "@mui/material";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import DataTable from "examples/Tables/DataTable";
import { useNavigate, useParams } from "react-router-dom";
import MDIconButton from "components/MDIconButton";
import AddUpdate from "./addUpdate";
import CancelRequest from "./cancelRequest";

function Index(): JSX.Element {
  const navigate = useNavigate();

  const [selectedItems, setSelectedItems] = useState([]);
  const [addUpdatePopup, setAddUpdatePopup] = useState<any>(false);
  const [cancelRequest, setCancelRequest] = useState<any>(false);

  const params = useParams();

  const closeModal = () => {
    setAddUpdatePopup(false);
  };

  const closeCancelReqModal = () => {
    setCancelRequest(false);
  };

  const goToRequestDetail = (reqId: any) => {
    navigate(`/sola-pros/${params?.id}/${reqId}`, {
      state: { prevPath: `/sola-pros/${params?.id}`, defaultTab: "4" },
    });
  };

  const actionButtons = (reqId: any) => (
    <>
      <MDIconButton
        tooltipName="View Request Details"
        aria-label="Open"
        color="info"
        onClick={() => goToRequestDetail(reqId)}
      >
        <Icon fontSize="small">open_in_new</Icon>
      </MDIconButton>
      <MDIconButton
        tooltipName="Cancel request"
        aria-label="Cancel"
        color="info"
        onClick={() => setCancelRequest(true)}
      >
        <Icon fontSize="small">close</Icon>
      </MDIconButton>
    </>
  );

  const onChangeCheckBox = (e: any) => {
    let items = JSON.parse(JSON.stringify(selectedItems));
    if (e.target.checked && !items.includes(e.target.value)) {
      items.push(e.target.value);
    } else {
      items = items.filter((id: any) => id !== e.target.value);
    }
    setSelectedItems(items);
  };

  const tableData = {
    columns: [
      {
        Header: "",
        disableSortBy: true,
        accessor: "checkbox",
        export: false,
        width: "80px",
      },
      { Header: "Id", accessor: "id", export: true },
      { Header: "Subject", accessor: "subject", width: "15%", export: true },
      { Header: "Description", accessor: "description", export: true },
      { Header: "Date Open", accessor: "date_open", export: true },
      { Header: "Status", accessor: "status", width: "20%", export: false },
      { Header: "Action", accessor: "action", export: false, disableSortBy: true },
    ],
    rows: [
      {
        checkbox: (
          <Checkbox
            checked={selectedItems.includes("1")}
            onChange={(e: any) => onChangeCheckBox(e)}
            value="1"
          />
        ),
        id: "1125",
        subject: "Example Request",
        description: "Description",
        date_open: "05/05/2022",
        status: (
          <Box display="flex" alignItems="center">
            Action Required
            <Tooltip title="This icon would indicate that a response from the Sola Pro is required. They may receive notification via Sola Pro App, SMS, and Email.">
              <Icon fontSize="small" sx={{ mx: 1, cursor: "pointer" }}>
                info
              </Icon>
            </Tooltip>
          </Box>
        ),
        action: actionButtons(1125),
      },
      {
        checkbox: (
          <Checkbox
            checked={selectedItems.includes("2")}
            onChange={(e: any) => onChangeCheckBox(e)}
            value="2"
          />
        ),
        id: "1126",
        subject: "Example Request",
        description: "Description",
        date_open: "11/08/2022",
        status: "Closed",
        action: actionButtons(1126),
      },
      {
        checkbox: (
          <Checkbox
            checked={selectedItems.includes("3")}
            onChange={(e: any) => onChangeCheckBox(e)}
            value="3"
          />
        ),
        id: "1127",
        subject: "Example Request",
        description: "Description",
        date_open: "08/11/2022",
        status: (
          <Box display="flex" alignItems="center">
            Action Required
            <Tooltip title="This icon would indicate that a response from the Sola Pro is required. They may receive notification via Sola Pro App, SMS, and Email.">
              <Icon fontSize="small" sx={{ mx: 1, cursor: "pointer" }}>
                info
              </Icon>
            </Tooltip>
          </Box>
        ),
        action: actionButtons(1127),
      },
      {
        checkbox: (
          <Checkbox
            checked={selectedItems.includes("4")}
            onChange={(e: any) => onChangeCheckBox(e)}
            value="4"
          />
        ),
        id: "1128",
        subject: "Example Request",
        description: "Description",
        date_open: "12/01/2022",
        status: "Closed",
        action: actionButtons(1128),
      },
    ],
  };

  return (
    <MDBox mt={3} pt={4} pb={3}>
      <Card>
        <MDBox
          bgColor="dark"
          color="white"
          coloredShadow="dark"
          borderRadius="xl"
          alignItems="center"
          justifyContent="space-between"
          pt={1}
          pb={1}
          pl={2}
          pr={2}
          ml={2}
          mr={2}
          display="flex"
          className="page-header"
          mt={-3}
        >
          <p className="page-header-label">Maintenance Requests</p>
          <MDButton
            variant="gradient"
            color="light"
            size="small"
            className="xs-small"
            onClick={() => setAddUpdatePopup(true)}
          >
            <Icon sx={{ fontWeight: "bold" }}>add</Icon> Add New
          </MDButton>
        </MDBox>
        <DataTable
          table={tableData}
          canSearch
          searchText="Search..."
          selectedItems={selectedItems}
          showExportSelectedBtn
          showExportAllBtn
        />
        {addUpdatePopup && <AddUpdate open={addUpdatePopup} onClose={() => closeModal()} />}
        {cancelRequest && (
          <CancelRequest open={cancelRequest} onClose={() => closeCancelReqModal()} />
        )}
      </Card>
    </MDBox>
  );
}

export default Index;
