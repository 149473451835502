import { useState, useEffect } from "react";
import TreeView from "@mui/lab/TreeView";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import TreeItem from "@mui/lab/TreeItem";
import {
  Checkbox,
  FormControlLabel,
  Paper,
  Chip,
  Stack,
  // FormGroup, InputLabel, Paper
} from "@mui/material";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import MDButton from "components/MDButton";

export default function CustomAssociationDropdown(props: any) {
  const { associations } = props;
  const [selectedItems, setSelectedItems] = useState<any>([]);
  const [selectedDetails, setSelectedDetails] = useState<any>([]);
  const [selectedAssociations, setSelectedAssociations] = useState<any>([]);
  const [isOpenDropdown, setIsOpenDropdown] = useState(false);
  const localLocations = localStorage.getItem("selected_locations");

  useEffect(() => {
    if (localLocations && associations) {
      const selectedArr: any = [];
      const selectedAssociationArr: any = [];
      associations?.map((association: any) => {
        let count = 0;
        if (association?.locations?.length > 0) {
          association?.locations?.map((loc: any) => {
            if (localLocations.indexOf(loc?.id) !== -1) {
              selectedArr.push(loc);
              count++;
            }
            return true;
          });
        }
        if (count) {
          selectedAssociationArr.push(association?.id);
        }
        return selectedArr;
      });
      setSelectedDetails(selectedArr);
      setSelectedAssociations(selectedAssociationArr);
      setSelectedItems(JSON.parse(localLocations));
    }
  }, [associations]);

  const onSelectMenu = (
    event: any,
    type: string,
    itemId?: any,
    associationId?: any,
    childrens?: any,
    detail?: any
  ) => {
    const selectedItemsArr: any = [...selectedItems];
    const selectedDetailsArr: any = [...selectedDetails];
    const selectedAssociationsArr: any = [...selectedAssociations];

    if (type === "association") {
      if (childrens?.length) {
        if (selectedAssociationsArr.indexOf(itemId) === -1) {
          selectedAssociationsArr.push(itemId);
          childrens.map((child: any) => {
            if (selectedItemsArr.indexOf(child.id) === -1) {
              selectedItemsArr.push(child.id);
              selectedDetailsArr.push(child);
            }
            return true;
          });
        } else {
          selectedAssociationsArr.splice(selectedAssociationsArr.indexOf(itemId), 1);
          childrens.map((child: any) => {
            if (selectedItemsArr.indexOf(child.id) !== -1) {
              selectedItemsArr.splice(selectedItemsArr.indexOf(child.id), 1);
              const index = selectedDetailsArr.findIndex((x: any) => x.id === child.id);
              selectedDetailsArr.splice(index, 1);
            }
            return true;
          });
        }
      }
    }
    if (type === "location") {
      if (selectedItemsArr.indexOf(itemId) === -1) {
        selectedItemsArr.push(itemId);
        selectedDetailsArr.push(detail);
        if (selectedAssociationsArr.indexOf(associationId) === -1) {
          selectedAssociationsArr.push(associationId);
        }
      } else {
        selectedItemsArr.splice(selectedItemsArr.indexOf(itemId), 1);
        const index = selectedDetailsArr.findIndex((x: any) => x.id === itemId);
        selectedDetailsArr.splice(index, 1);

        const association = associations.find((x: any) => x.id === associationId);
        let isExist: any = false;
        association?.locations?.map((location: any) => {
          if (selectedItemsArr.indexOf(location?.id) !== -1) {
            isExist = true;
          }
          return true;
        });
        if (!isExist && selectedAssociationsArr.indexOf(associationId) !== -1) {
          selectedAssociationsArr.splice(selectedAssociationsArr.indexOf(associationId), 1);
        }
      }
    }

    setSelectedItems(selectedItemsArr);
    setSelectedDetails(selectedDetailsArr);
    setSelectedAssociations(selectedAssociationsArr);

    localStorage.setItem("selected_locations", JSON.stringify(selectedItemsArr));
  };

  const handleDeleteLocation = (e: any, locationId: any) => {
    const selectedItemsArr: any = [...selectedItems];
    const selectedDetailsArr: any = [...selectedDetails];

    selectedItemsArr.splice(selectedItemsArr.indexOf(locationId), 1);
    const index = selectedDetailsArr.findIndex((x: any) => x.id === locationId);
    selectedDetailsArr.splice(selectedDetailsArr.indexOf(index), 1);

    setSelectedItems(selectedItemsArr);
    setSelectedDetails(selectedDetailsArr);
    localStorage.setItem("selected_locations", JSON.stringify(selectedItemsArr));
  };

  const applyFilter = () => {
    window.location.reload();
  };
  return (
    <MDBox className="customDropdown">
      <MDBox onClick={() => setIsOpenDropdown(!isOpenDropdown)}>
        <Paper>
          <MDTypography className="drop-h6" variant="subtitle2" p={2}>
            {selectedDetails?.length > 0
              ? selectedDetails.map((location: any) => (
                  <Stack direction="row" spacing={1}>
                    <Chip
                      label={location?.name}
                      onDelete={(e: any) => handleDeleteLocation(e, location?.id)}
                    />
                  </Stack>
                ))
              : "Choose Association"}
            {isOpenDropdown ? (
              <KeyboardArrowUpIcon className="drop-icon" />
            ) : (
              <KeyboardArrowDownIcon className="drop-icon" />
            )}
          </MDTypography>
        </Paper>
      </MDBox>
      {isOpenDropdown && (
        <MDBox className="customDropdown-content">
          <Paper>
            <TreeView
              aria-label="rich object"
              defaultCollapseIcon={<ExpandMoreIcon />}
              defaultExpanded={["root"]}
              defaultExpandIcon={<ChevronRightIcon />}
              sx={{ minHeight: 200, overflowY: "auto" }}
              multiSelect
            >
              {associations?.map(
                (association: any) =>
                  association?.locations?.length && (
                    <TreeItem
                      key={association?.id}
                      nodeId={association?.id}
                      expandIcon={association?.locations?.length ? <ChevronRightIcon /> : <> </>}
                      label={
                        <FormControlLabel
                          label={association?.association_name}
                          control={
                            <Checkbox
                              checked={selectedAssociations.includes(association?.id)}
                              onChange={(event: any) =>
                                onSelectMenu(
                                  event,
                                  "association",
                                  association?.id,
                                  null,
                                  association?.locations
                                )
                              }
                            />
                          }
                        />
                      }
                    >
                      {association?.locations?.map((location: any) => (
                        <TreeItem
                          key={location?.id}
                          nodeId={location?.id}
                          label={
                            <FormControlLabel
                              label={location?.name}
                              control={
                                <Checkbox
                                  checked={
                                    selectedItems.includes(location?.id) ||
                                    selectedItems.includes(location?.id.toString())
                                  }
                                  onChange={(event: any) =>
                                    onSelectMenu(
                                      event,
                                      "location",
                                      location?.id,
                                      association?.id,
                                      null,
                                      location
                                    )
                                  }
                                />
                              }
                            />
                          }
                        />
                      ))}
                    </TreeItem>
                  )
              )}
            </TreeView>
            <MDBox class="apply-button">
              <MDButton color="info" size="small" onClick={applyFilter}>
                Apply
              </MDButton>
            </MDBox>
          </Paper>
        </MDBox>
      )}
    </MDBox>
  );
}
