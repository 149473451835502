import { FormControlLabel, Radio, RadioGroup } from "@mui/material";
import MDBox from "components/MDBox";
import { leaseApplicant, leaseApplicantValue } from "helper/services";
import { getAllexistingDealData, setleaseApplicationSteps } from "store/slices/leaseSlice";
import { useAppDispatch, useAppSelector } from "store/store";
import HandleButton from "./handleButton";

function LeadOption(props: any): JSX.Element {
  const { setContactInquiries, contactInquiries, setSelectedServices } = props;
  const { leaseApplicationSteps } = useAppSelector((state) => state.leaseSlice);
  const dispatch = useAppDispatch();

  const onChangeField = (e: any) => {
    // Store value in Reducer
    dispatch(
      setleaseApplicationSteps({
        ...leaseApplicationSteps,
        selectedType: e.target.value,
        deal: "",
        applicants: [],
        security_deposit_amount: 0,
        security_deposit_amount_type: 0,
        studio: "",
        locations: "",
      })
    );
    if (
      leaseApplicantValue.ADDEXISTINGPROFESSIONAL === e.target.value ||
      (leaseApplicantValue.ADDFROMDEAL === e.target.value && contactInquiries)
    ) {
      setContactInquiries("");
      setSelectedServices([]);
      dispatch(getAllexistingDealData([]));
    }
  };

  return (
    <MDBox style={{ textAlign: "center" }}>
      <RadioGroup
        row
        name="selected_type"
        value={leaseApplicationSteps?.selectedType || ""}
        onChange={(event: any) => onChangeField(event)}
        style={{ display: "flex", justifyContent: "space-between" }}
      >
        <FormControlLabel
          value={leaseApplicantValue.ADDFROMDEAL}
          control={<Radio />}
          label={leaseApplicant.ADDFROMDEAL}
        />
        <FormControlLabel
          value={leaseApplicantValue.ADDEXISTINGPROFESSIONAL}
          control={<Radio />}
          label={leaseApplicant.ADDEXISTINGPROFESSIONAL}
        />
        {/* <FormControlLabel
          value={leaseApplicantValue.ADDNEW}
          control={<Radio />}
          label={leaseApplicant.ADDNEW}
        /> */}
      </RadioGroup>
      <HandleButton {...props} />
    </MDBox>
  );
}

export default LeadOption;
