import { createAsyncThunk } from "@reduxjs/toolkit";
import { notificationFail, notificationSuccess } from "store/slices/notificationSlice";
import { setLoading } from "store/slices/authSlice";
import { setDownloadCenter, setDownloadFileUrl } from "store/slices/downloadCenter";
import Messages from "helper/messages";
import slsApiClient from "config/slsApiClient";

export const getDownloadCenter = createAsyncThunk(
  "getDownloadCenter",
  async (_request: any, { dispatch }) => {
    dispatch(setLoading(true));
    try {
      const response = await slsApiClient().post(`get-download-center-data`, _request);
      dispatch(setLoading(false));
      if (response && response?.data?.status) {
        dispatch(setDownloadCenter(response?.data));
      } else {
        dispatch(notificationFail(response?.data?.message || Messages.ERROR.NO_DATA_FOUND));
      }
    } catch (error: any) {
      dispatch(setLoading(false));
      dispatch(notificationFail(error?.response?.data?.message || Messages.ERROR.NO_DATA_FOUND));
    }
  }
);

export const downloadFile = createAsyncThunk(
  "downloadFile",
  async (_request: any, { dispatch }) => {
    dispatch(setLoading(true));
    try {
      const response = await slsApiClient().post(`generate-signed-s3-url`, _request);
      dispatch(setLoading(false));
      if (response && response?.data?.status) {
        dispatch(setDownloadFileUrl(response?.data));
      } else {
        dispatch(notificationFail(response?.data?.message || Messages.ERROR.NO_DATA_FOUND));
      }
    } catch (error: any) {
      dispatch(setLoading(false));
      dispatch(notificationFail(error?.response?.data?.message || Messages.ERROR.NO_DATA_FOUND));
    }
  }
);

export const deleteDownloadCenter = createAsyncThunk(
  "deleteDownloadCenter",
  async (_request: any, { dispatch }) => {
    const { setDeleteDownloadCenterId, id, size, page, searchText, userId, sortField, sortType } =
      _request;
    dispatch(setLoading(true));
    try {
      const response = await slsApiClient().post(`delete-download-center`, { id });
      if (response && response?.data?.status) {
        setDeleteDownloadCenterId(false);
        dispatch(
          notificationSuccess(
            response?.data?.message || Messages.SUCCESS.DOWNLOAD_CENTER_DELETE_SUCCESS
          )
        );
        dispatch(
          getDownloadCenter({
            size,
            page,
            search_text: searchText,
            user_id: userId,
            sort_field: sortField,
            sort_type: sortType,
          })
        );
      } else {
        dispatch(setLoading(false));
        dispatch(notificationFail(response?.data?.message || Messages.ERROR.NO_DATA_FOUND));
      }
    } catch (error: any) {
      dispatch(setLoading(false));
      dispatch(notificationFail(error?.response?.data?.message || Messages.ERROR.NO_DATA_FOUND));
    }
  }
);

export const updateExportData = createAsyncThunk(
  "updateExportData",
  async (_request: any, { dispatch }) => {
    const {
      exportType,
      id,
      callback,
      size,
      page,
      searchText,
      sortField,
      userId,
      sortType,
      fieldsToExport,
      exportFromDate,
      exportToDate,
      joinTables,
    } = _request;
    dispatch(setLoading(true));
    try {
      const response = await slsApiClient().post(`update-export-data`, {
        export_type: exportType,
        fields_to_export: fieldsToExport,
        export_from_date: exportFromDate,
        export_to_date: exportToDate,
        ...(joinTables && { join_tables: joinTables }),
        id,
      });
      dispatch(setLoading(false));
      if (response?.data?.status) {
        if (callback) callback();
        dispatch(notificationSuccess(Messages.SUCCESS.RECORD_UPDATE_SUCCESS));
        dispatch(
          getDownloadCenter({
            size,
            page,
            search_text: searchText,
            user_id: userId,
            sort_field: sortField,
            sort_type: sortType,
          })
        );
      } else {
        dispatch(notificationFail(response?.data?.message || Messages.ERROR.SOMETHING_WENT_WRONG));
      }
    } catch (error: any) {
      dispatch(notificationFail(error.message || Messages.ERROR.SOMETHING_WENT_WRONG));
      dispatch(setLoading(false));
    }
  }
);

export const saveScheduleDownload = createAsyncThunk(
  "saveScheduleDownload",
  async (_request: any, { dispatch }) => {
    const {
      downloadId,
      emails,
      recurringRule,
      triggerDate,
      exportDateRange,
      onCloseSchedule,
      size,
      page,
      searchText,
      sortField,
      userId,
      sortType,
    } = _request;
    dispatch(setLoading(true));
    try {
      const response = await slsApiClient().post(`save-schedule-report`, {
        download_id: downloadId,
        emails,
        recurring_rule: recurringRule,
        trigger_date: triggerDate,
        export_date_range: exportDateRange,
      });
      dispatch(setLoading(false));
      if (response?.data?.status) {
        onCloseSchedule();
        dispatch(notificationSuccess(Messages.SUCCESS.SCHEDULE_REPORT_SUCCESS));
        dispatch(
          getDownloadCenter({
            size,
            page,
            search_text: searchText,
            user_id: userId,
            sort_field: sortField,
            sort_type: sortType,
          })
        );
      } else {
        dispatch(notificationFail(response?.data?.message || Messages.ERROR.SOMETHING_WENT_WRONG));
      }
    } catch (error: any) {
      dispatch(notificationFail(error.message || Messages.ERROR.SOMETHING_WENT_WRONG));
      dispatch(setLoading(false));
    }
  }
);
