import { Card, Grid } from "@mui/material";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import { systemAdmin, adminFranchisie } from "helper/services";
import MDEditor from "components/MDEditor";
import CustomErrorMessage from "components/CustomErrorMessage";

function Settings(props: any): JSX.Element {
  const { editPermission, onChangeField, locationDetail, setLocationDetail, errors } = props;
  // no need for now
  // const onChecked = (e: any) => {
  //   setLocationDetail({
  //     ...locationDetail,
  //     [`display_contact_form_on_website`]: e.target.checked,
  //   });
  // };
  const modules: any = { toolbar: false };
  const formats: any = [];

  // SD-3360
  const onChangeMoveIn = (value: any, delta: any, source: any) => {
    if (source === "user") {
      setLocationDetail({ ...locationDetail, move_in_special: value });
    }
  };
  return (
    <Card id="settings" sx={{ overflow: "visible" }}>
      <MDBox p={3}>
        <MDTypography variant="h5">Settings</MDTypography>
      </MDBox>
      <MDBox component="form" pb={3} px={3}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            {/* SD-3360 */}
            <MDTypography variant="button">Move In Special</MDTypography>
            <MDEditor
              value={locationDetail?.move_in_special ? locationDetail?.move_in_special : ""}
              onChange={(val: any, delta: any, source: any) => onChangeMoveIn(val, delta, source)}
              modules={modules}
              formats={formats}
              mt={3}
              className="move-in-container"
              style={{ height: "53px", border: "none", borderBottom: "1px solid #ccc" }}
              disabled={!editPermission && !adminFranchisie()}
            />
            <MDBox pl={3} pr={3} pt={0} pb={3}>
              {errors && errors.move_in_special && (
                <CustomErrorMessage message={errors.move_in_special} />
              )}
            </MDBox>
          </Grid>
          {systemAdmin() && (
            <Grid item xs={12} sm={6}>
              <MDInput
                label="Special Callout"
                placeholder="Special Callout"
                fullWidth
                multiline
                rows={3}
                variant="standard"
                name="open_house"
                value={locationDetail?.open_house || ""}
                onChange={onChangeField}
                disabled={!editPermission}
              />
            </Grid>
          )}
          {/* SD-3391 no need for now */}
          {/* {adminFranchisie() && (
            <Grid item xs={12} sm={6}>
              <FormControlLabel
                label="Display contact form on website"
                control={
                  <Switch
                    checked={locationDetail?.display_contact_form_on_website}
                    onChange={onChecked}
                    inputProps={{ "aria-label": "controlled" }}
                  />
                }
                labelPlacement="end"
              />
            </Grid>
          )} */}
        </Grid>
      </MDBox>
    </Card>
  );
}

export default Settings;
