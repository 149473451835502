import MDBox from "components/MDBox";
import { Grid } from "@mui/material";
import CommunicationDelay from "./communication-delay";
import InitialMessage from "./initial-message";
import FollowUp1 from "./follow-up-1";
import FollowUp2 from "./follow-up-2";
import FollowUp3 from "./follow-up-3";
import Sidenav from "./sidenav";
import ThankYouMessage from "./thank-you-message";

function Index(): JSX.Element {
  return (
    <MDBox id="survey-questions" component="form" pb={3} pt={3}>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={12} md={12} lg={3}>
          <Sidenav />
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={9}>
          <MDBox mb={3}>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={12}>
                <CommunicationDelay />
              </Grid>
              <Grid item xs={12} sm={12}>
                <InitialMessage />
              </Grid>
              <Grid item xs={12} sm={12}>
                <FollowUp1 />
              </Grid>
              <Grid item xs={12}>
                <FollowUp2 />
              </Grid>
              <Grid item xs={12}>
                <FollowUp3 />
              </Grid>
              <Grid item xs={12}>
                <ThankYouMessage />
              </Grid>
            </Grid>
          </MDBox>
        </Grid>
      </Grid>
    </MDBox>
  );
}

export default Index;
