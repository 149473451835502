import { Checkbox, Grid } from "@mui/material";
import MDBox from "components/MDBox";
import MDInput from "components/MDInput";
import { useEffect } from "react";

function EditStudio(props: any): JSX.Element {
  const { editStudioId, units, setEditedData, editedData, getAccessTypePermission } = props;

  useEffect(() => {
    if (editStudioId) {
      const tempData = units?.filter(
        (obj: any) => obj?.id === editStudioId || editStudioId === obj?.group_id
      );
      setEditedData(tempData);
    }
  }, []);

  const onChangeField = (event: any, index: number) => {
    const updatedData = [...editedData];
    updatedData[index] = {
      ...updatedData[index],
      [event.target.name]: event.target.value,
    };
    if (updatedData[index].id) {
      updatedData[index].isUpdate = true;
    }
    setEditedData(updatedData);
  };

  const onChangeCheckBoxes = (event: any, index: number) => {
    const updatedData = [...editedData];
    updatedData[index] = {
      ...updatedData[index],
      [event.target.name]: event.target.checked,
    };
    if (updatedData[index].id) {
      updatedData[index].isUpdate = true;
    }
    setEditedData(updatedData);
  };

  return (
    <MDBox p={3}>
      <MDBox component="form" pb={3} px={3}>
        {editedData &&
          editedData?.length &&
          editedData?.map((obj: any, index: any) =>
            !obj?.is_vacant ? (
              <Grid container>
                <span>
                  This studio is already occupied in active lease you can to edit this studio !!
                </span>
              </Grid>
            ) : (
              <Grid container>
                <Grid item xs={12} sm={6} sx={{ p: 1 }}>
                  <MDInput
                    label="Studio Name"
                    variant="standard"
                    fullWidth
                    name="name"
                    placeholder="Studio Name"
                    value={obj?.name || ""}
                    onChange={(e: any) => onChangeField(e, index)}
                  />
                </Grid>
                <Grid item xs={12} sm={6} sx={{ p: 1 }}>
                  <MDInput
                    label="Lease Fee"
                    variant="standard"
                    fullWidth
                    name="lease_fee"
                    placeholder="Lease Fee"
                    value={obj?.lease_fee || ""}
                    onChange={(e: any) => onChangeField(e, index)}
                  />
                </Grid>
                <Grid item xs={12} sm={6} sx={{ p: 1 }}>
                  <Checkbox
                    checked={obj?.is_window || false}
                    name="is_window"
                    onChange={(e: any) => onChangeCheckBoxes(e, index)}
                    disabled={getAccessTypePermission("location_rentmanager", "edit_access")}
                  />
                </Grid>
              </Grid>
            )
          )}
      </MDBox>
    </MDBox>
  );
}

export default EditStudio;
