import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import { Grid, Card, Paper, IconButton, Icon } from "@mui/material";
import MESSAGES from "helper/messages";

function Testimonials(props: any): JSX.Element {
  const { solaProDetail, setSolaProDetail, errors } = props;

  const onChangeMultipleField = (event: any, key: any) => {
    const data = solaProDetail[key] ? JSON.parse(JSON.stringify(solaProDetail[key])) : {};
    data[event.target.name] = event.target.value;
    setSolaProDetail({ ...solaProDetail, [key]: { ...data } });
  };

  const onTestimonialRemove = (event: any, key: any) => {
    const data = solaProDetail[key] ? JSON.parse(JSON.stringify(solaProDetail[key])) : {};
    const data1: any = { id: data.id, is_deleted: 1 };
    setSolaProDetail({ ...solaProDetail, [key]: data1 });
  };

  const displayItems = (i: any): JSX.Element => {
    const items: any = [];
    const key = `testimonial_${i}`;
    items.push(
      <Grid item xs={12} sm={12} md={12} key={i}>
        <Paper sx={{ width: "100%", padding: 2 }} elevation={3}>
          <Grid container>
            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              mb={2}
              display="flex"
              justifyContent="space-between"
              alignItems="center"
            >
              <MDTypography variant="body2" fontWeight="regular">
                Testimonial {i}
              </MDTypography>
              <IconButton
                aria-label="Delete"
                className="removeBtnClass"
                onClick={(e: any) => onTestimonialRemove(e, key)}
              >
                <Icon fontSize="small">close</Icon>
              </IconButton>
            </Grid>
          </Grid>
          <Grid container spacing={3}>
            <Grid item md={12} sm={12} xs={12}>
              <MDInput
                variant="standard"
                label="Name"
                fullWidth
                name="name"
                placeholder="Name"
                onChange={(e: any) => onChangeMultipleField(e, key)}
                value={solaProDetail[key]?.name || ""}
                error={errors && errors[`${key}_name`] ? true : false}
                helperText={errors && errors[`${key}_name`]}
                infoText={MESSAGES.INFO.OPTIONAL_LENGTH_255}
              />
            </Grid>
            <Grid item md={12} sm={12} xs={12}>
              <MDInput
                variant="standard"
                label="Region"
                fullWidth
                name="region"
                placeholder="Region"
                onChange={(e: any) => onChangeMultipleField(e, key)}
                value={solaProDetail[key]?.region || ""}
                error={errors && errors[`${key}_region`] ? true : false}
                helperText={errors && errors[`${key}_region`]}
                infoText={MESSAGES.INFO.OPTIONAL_LENGTH_255}
              />
            </Grid>
            <Grid item md={12} sm={12} xs={12}>
              <MDInput
                multiline
                rows="2"
                variant="standard"
                label="Text"
                fullWidth
                name="text"
                placeholder="Text"
                onChange={(e: any) => onChangeMultipleField(e, key)}
                value={solaProDetail[key]?.text || ""}
                error={errors && errors[`${key}_text`] ? true : false}
                helperText={errors && errors[`${key}_text`]}
                infoText={MESSAGES.INFO.REQUIRED}
              />
            </Grid>
          </Grid>
        </Paper>
      </Grid>
    );
    return items;
  };

  return (
    <Card id="testimonials">
      <MDBox p={3}>
        <MDTypography variant="h5">Testimonials</MDTypography>
      </MDBox>
      <MDBox pb={3} px={3}>
        <Grid container spacing={3}>
          {displayItems(1)}
          {displayItems(2)}
          {displayItems(3)}
          {displayItems(4)}
          {displayItems(5)}
          {displayItems(6)}
          {displayItems(7)}
          {displayItems(8)}
          {displayItems(9)}
          {displayItems(10)}
        </Grid>
      </MDBox>
    </Card>
  );
}

export default Testimonials;
