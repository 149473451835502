import { useCallback, useEffect, useLayoutEffect, useRef, useState } from "react";
import { Card, Icon, Checkbox } from "@mui/material";

import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDDialog from "components/MDDialog";
import MDTypography from "components/MDTypography";

import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import DataTable from "examples/Tables/DataTable";
import { getMsaList, deleteMsaRecord } from "store/thunk/systemAdmin";
import { useAppDispatch, useAppSelector } from "store/store";
import {
  // arrayForStoreInDB,
  // checkedUncheckedValues,
  setDefaultSelectedColumns,
  getLocalDateTime,
  insertinArray,
  systemAdmin,
} from "helper/services";
import exportData from "helper/exportTableConst";
import Messages from "helper/messages";
import MDIconButton from "components/MDIconButton";
import { getDefaultCoulmns, saveSelectedAllData } from "store/thunk/connectUserThunk";
import { setDefaultsColumns } from "store/slices/connectUsersSlice";
import AddUpdate from "./addUpdate";
import ViewDetails from "./viewDetails";

function Index(): JSX.Element {
  const dispatch = useAppDispatch();
  const [addUpdatePopup, setAddUpdatePopup] = useState<any>(false);
  const [updateData, setUpdateData] = useState<any>("");
  const { msaList } = useAppSelector((state) => state.systemAdminSlice);
  const { defaultSelectedColumns } = useAppSelector((state) => state.connectUsersReducer);
  const { userData } = useAppSelector((state) => state.authReducer);
  const [detailsPopup, setDetailsPopup] = useState<any>(false);
  const [deleteMsa, setDeleteMsa] = useState<any>(false);
  const [selectedItems, setSelectedItems] = useState([]);
  const [msas, setMsas] = useState([]);
  const [pageCount, setPageCount] = useState(0);
  const [pageNo, setPageNo] = useState(0);
  const [totalCount, setTotalCount] = useState(0);
  const [perPage, setPageSize] = useState(50);
  const [search, setSearch] = useState<any>(null);
  const [sortBy, setSortBy] = useState<any>([]);
  const [displayColumns, setDisplayColumns] = useState<any>([]);
  // SD-3199
  const [defaultExport, setDefaultExport] = useState<any>([]);
  const [callFetch, setCallFetch] = useState<any>(false);
  const [defaultSorting, setDefaultSorting] = useState<any>([]);
  const [defaultFilteing, setDefaultFilteing] = useState<any>([]);

  // Get columns
  const getColumns = () => {
    let columns: any = [
      {
        Header: "",
        disableSortBy: true,
        width: "100px",
        accessor: "checkbox",
        default: true,
        checked: true,
      },
      {
        Header: "Name",
        accessor: "name",
        export: true,
        disableCheck: true,
        checked: true,
      },
      {
        Header: "URL Name",
        accessor: "url_name",
        export: true,
        checked: true,
      },
      {
        Header: "Legacy",
        accessor: "legacy_id",
        export: true,
        checked: true,
      },
      {
        Header: "Description",
        accessor: "description",
        export: true,
        checked: true,
      },
      {
        Header: "Active",
        accessor: "active",
        export: true,
        checked: true,
      },
      {
        Header: "Created at",
        accessor: "created_at",
        width: "200px",
        export: true,
        is_date: true,
        checked: true,
      },
      {
        Header: "Updated at",
        accessor: "updated_at",
        width: "200px",
        export: true,
        is_date: true,
        checked: false,
      },
      {
        Header: "Tracking Code",
        accessor: "tracking_code",
        export: true,
        checked: false,
      },
      {
        Header: "Locations",
        accessor: "locations",
        sorting_column: "locations",
        width: "300px",
        export: true,
        associative_colums: "locations.name",
        checked: false,
      },
      {
        Header: "Action",
        accessor: "action",
        width: "200px",
        export: false,
        disableSortBy: true,
        checked: true,
      },
    ];
    if (systemAdmin()) {
      columns = insertinArray(columns, 1, {
        Header: "ID",
        accessor: "id",
        export: true,
        disableCheck: true,
        checked: true,
      });
    }
    return columns;
  };

  const [checkedColumns, setCheckedColumns] = useState<any>(getColumns());
  const [isCheckAll, setIsCheckAll] = useState(false);
  const latestRefValue = useRef(checkedColumns);

  useEffect(() => {
    latestRefValue.current = checkedColumns;
  }, [checkedColumns]);

  const fetchIdRef = useRef(0);

  const fetchData = useCallback(({ pageSize, pageIndex, search, sortBy, filterData, isManual }) => {
    const fetchId = ++fetchIdRef.current;
    const sort: any = sortBy || [];
    // We'll even set a delay to simulate a server here
    setTimeout(() => {
      if (fetchId === fetchIdRef.current) {
        // SD-3199
        const sentReq = {
          sendData: {
            user_id: userData?.user_id,
            table_name: exportData.msas.table_name,
            table_columns: latestRefValue.current && latestRefValue.current,
            table_sort: sortBy,
            table_filter: filterData,
            default_export_data: defaultExport,
          },
        };
        if (isManual) {
          dispatch(saveSelectedAllData(sentReq));
        }

        setPageNo(pageIndex);
        setSearch(search);
        setSortBy(sort);
        setPageSize(pageSize);
        // setSelectedItems([]);
        setIsCheckAll(false);
        dispatch(getMsaList({ pageSize, pageIndex, search, sort, userData, filterData }));
      }
    }, 1000);
  }, []);

  useLayoutEffect(() => {
    dispatch(setDefaultsColumns([]));
    // Set data in variable and Call defaultColums Thunk
    const sentReq = {
      user_id: userData?.user_id,
      table_name: exportData.msas.table_name,
    };
    dispatch(getDefaultCoulmns(sentReq)).then((resp: any) => {
      // SD-3199
      const respFilter = resp?.payload?.table_filter || [];
      const respSorting = resp?.payload?.table_sort || [];
      const defaultDBexportData = resp?.payload?.default_export_data || [];
      const defaultDBColumn = resp?.payload?.table_columns || [];
      const tempdata = setDefaultSelectedColumns(defaultDBColumn, getColumns());
      setCheckedColumns(tempdata);
      setDefaultExport(defaultDBexportData);
      setDefaultSorting(respSorting);
      setSortBy(respSorting);
      setDefaultFilteing(respFilter);
      if (resp?.payload) {
        setCallFetch(true);
        fetchData({
          perPage,
          pageNo,
          search,
          sortBy: respSorting && respSorting?.length ? respSorting : [],
          additionalField: null,
          filterData: respFilter && respFilter?.length ? respFilter : [],
        });
      }
    });
  }, []);

  useEffect(() => {
    if (checkedColumns?.length) {
      setDisplayColumns(checkedColumns.filter((col: any) => col.checked));
    }
  }, [checkedColumns]);

  useEffect(() => {
    if (msaList && msaList?.msas) {
      setMsas(msaList?.msas);
      setPageCount(msaList?.meta?.total_pages);
      setTotalCount(msaList?.meta?.total_count);
    }
  }, [msaList]);

  useEffect(() => {
    if (defaultSelectedColumns && defaultSelectedColumns?.length) {
      const newColumns = setDefaultSelectedColumns(defaultSelectedColumns, getColumns());
      setCheckedColumns(newColumns);
    } else {
      setCheckedColumns(getColumns());
    }
  }, [defaultSelectedColumns]);

  const closeModal = () => {
    setUpdateData("");
    setAddUpdatePopup(false);
  };

  const openMsaEditModal = (data: any) => {
    setUpdateData(data);
    setAddUpdatePopup(true);
  };

  const openDeleteModal = (data: any) => {
    setUpdateData(data);
    setDeleteMsa(true);
  };

  const openDetailPopup = (data: any) => {
    setUpdateData(data);
    setDetailsPopup(true);
  };
  const closeDetailPopup = () => {
    setUpdateData("");
    setDetailsPopup(false);
  };

  const actionButtons = (object: any) => (
    <>
      <MDIconButton
        tooltipName="View"
        aria-label="Info"
        color="info"
        onClick={() => openDetailPopup(object)}
      >
        <Icon fontSize="small">visibility</Icon>
      </MDIconButton>
      <MDIconButton
        tooltipName="Edit"
        aria-label="Edit"
        color="info"
        onClick={() => openMsaEditModal(object)}
      >
        <Icon fontSize="small">edit</Icon>
      </MDIconButton>
      <MDIconButton
        tooltipName="Delete"
        aria-label="Delete"
        color="error"
        onClick={() => openDeleteModal(object)}
      >
        <Icon fontSize="small">delete</Icon>
      </MDIconButton>
    </>
  );

  const onChangeCheckBox = (e: any) => {
    let items = JSON.parse(JSON.stringify(selectedItems));
    if (e.target.checked && !items.includes(e.target.value)) {
      items.push(e.target.value);
      if (items.length === msas.length) {
        setIsCheckAll(true);
      }
    } else {
      items = items.filter((id: any) => id !== e.target.value);
      setIsCheckAll(false);
    }
    setSelectedItems(items);
  };

  const checkUncheckAll = (e: any) => {
    let allIds: any = [];
    allIds = msas.map((val: any) => String(val.id));
    if (e.target.checked) {
      const selectedIds = [...new Set([...selectedItems, ...allIds])];
      setSelectedItems(selectedIds);
      // allIds = msas.map((val: any) => String(val.id));
      setIsCheckAll(true);
    } else {
      allIds = msas.map((val: any) => String(val.id));
      allIds = selectedItems.filter((el: any) => !allIds.includes(el));
      setSelectedItems([...allIds]);
      setIsCheckAll(false);
      // allIds = [];
      // setIsCheckAll(false);
    }
    // setSelectedItems(allIds);
  };

  const getRows = () => {
    const data: any = msas.map((obj: any) => ({
      checkbox: (
        <Checkbox
          checked={selectedItems.includes(obj.id.toString())}
          onChange={(e: any) => onChangeCheckBox(e)}
          value={obj.id}
        />
      ),
      id: obj.id ? obj.id : "-",
      name: obj.name ? obj.name : "-",
      url_name: obj.url_name ? obj.url_name : "-",
      legacy_id: obj.legacy_id ? obj.legacy_id : "-",
      description: obj.description ? obj.description : "-",
      locations:
        obj.locations && obj.locations.length
          ? obj.locations.map((u: any) => u.name).join(", ")
          : "-",

      tracking_code: obj.tracking_code ? obj.tracking_code : "-",
      active: obj.active !== null ? `${obj.active}` : "-",
      created_at: obj.created_at ? getLocalDateTime(obj.created_at) : "-",
      updated_at: obj.updated_at ? getLocalDateTime(obj.updated_at) : "-",
      action: actionButtons(obj),
    }));
    return data;
  };

  const tableData = {
    columns: getColumns(),
    rows: getRows(),
  };

  const onCloseDeleteModal = () => {
    setDeleteMsa(false);
    setUpdateData("");
  };

  // Start Remove selcted items
  const removeSelectedItem = (deletedId: number) => {
    setSelectedItems(selectedItems.filter((item: any) => Number(item) !== deletedId));
  };
  // End Remove selcted items

  const onDelete = () => {
    if (updateData?.id) {
      const DelReq = {
        msaData: updateData,
        onCloseDeleteModal,
        pageNo,
        perPage,
        search,
        sortBy,
        removeSelectedItem,
      };
      dispatch(deleteMsaRecord(DelReq));
    }
  };

  const deleteDialogProps = {
    open: deleteMsa,
    onClose: () => onCloseDeleteModal(),
    onSave: () => onDelete(),
    title: "Delete MSA",
    size: "sm",
    saveBtn: false,
    deleteBtn: true,
    saveTbtText: "Yes",
    closeBtnText: "No",
  };

  // Remove uncheck boxes
  const clearCheckItem = () => {
    setSelectedItems([]);
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={4} pb={3}>
        <Card>
          <MDBox
            bgColor="dark"
            color="white"
            coloredShadow="dark"
            borderRadius="xl"
            alignItems="center"
            justifyContent="space-between"
            pt={1}
            pb={1}
            pl={2}
            pr={2}
            ml={2}
            mr={2}
            display="flex"
            mt={-3}
            className="page-header"
          >
            <p className="page-header-label">MSA</p>
            <MDButton
              variant="gradient"
              color="light"
              size="small"
              className="xs-small"
              onClick={() => setAddUpdatePopup(true)}
            >
              <Icon sx={{ fontWeight: "bold" }}>add</Icon> Add New
            </MDButton>
          </MDBox>
          {(defaultSorting || defaultSorting?.length) &&
            callFetch && ( // SD-3199
              <DataTable
                defaultSortingColumns={defaultSorting} // SD-3199
                defaultFilteringColums={defaultFilteing} // SD-3199
                defaultDisplayColumns={displayColumns}
                setCheckedColumns={setCheckedColumns}
                table={tableData}
                canSearch
                searchText="Search MSA..."
                selectedItems={selectedItems}
                showExportSelectedBtn
                showExportAllBtn
                pageCount={pageCount}
                pageNo={pageNo}
                setPageNo={setPageNo}
                totalCount={totalCount}
                fetchData={fetchData}
                exportFields={exportData.msas.export_fields}
                exportTable={exportData.msas.table_name}
                exportType={exportData.msas.export_type}
                advanceFilter
                manualSearch
                manualSort
                isDisplayCheckAllCheckbox
                onChangeCheckAllCheckbox={checkUncheckAll}
                isCheckAll={isCheckAll}
                checkedColumns={checkedColumns}
                clearCheckItem={clearCheckItem}
                useRubyApiToExport
              />
            )}
          {addUpdatePopup && (
            <AddUpdate
              open={addUpdatePopup}
              pageNo={pageNo}
              perPage={perPage}
              search={search}
              sortBy={sortBy}
              updateData={updateData}
              onClose={() => closeModal()}
            />
          )}
          {detailsPopup && (
            <ViewDetails
              open={detailsPopup}
              updateData={updateData}
              onClose={() => closeDetailPopup()}
            />
          )}
          {deleteMsa && (
            <MDDialog {...deleteDialogProps}>
              <MDTypography variant="h6" fontWeight="medium">
                {Messages.GENERAL.SURE_TO_DELETE}
              </MDTypography>
            </MDDialog>
          )}
        </Card>
      </MDBox>
    </DashboardLayout>
  );
}

export default Index;
