import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import {
  Grid,
  Card,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
} from "@mui/material";
import CustomAutoSearch from "components/CustomAutoSearch";
import { adminSuperAdmin, getAllAssociationLocations, getPermission } from "helper/services";
import { useAppSelector, useAppDispatch } from "store/store";
import { useEffect } from "react";
import { getUserCombineServices, getUserLocations } from "store/thunk/solaProsThunk";
import { setActiveServices } from "store/slices/solaProSlice";
import CustomErrorMessage from "components/CustomErrorMessage";

function Services(props: any): JSX.Element {
  const dispatch = useAppDispatch();
  const { webPageDetail, setWebPageDetails, errors, userActiveService, setActiveUserService } =
    props;
  const { allConnectServices, activeServices } = useAppSelector((state: any) => state.solaProSlice);

  const onNormChangeAutoComplete = (name: string, value: any) => {
    setWebPageDetails({ ...webPageDetail, [name]: value });
  };

  const onChangeAutoComplete = (name: string, value: any) => {
    const previousConnectUsers: any = webPageDetail?.connect_users
      ? [...webPageDetail.connect_users]
      : [];
    const latestAddedUser: any = value[value.length - 1];
    const userIds = value?.map((obj: any) => Number(obj.user_id));
    if (previousConnectUsers?.length < userIds.length) {
      dispatch(getUserLocations({ connectUserId: latestAddedUser?.user_id }))
        .unwrap()
        .then((result) => {
          const mappedUserValues: any = webPageDetail?.connect_users
            ? [...webPageDetail.connect_users, { ...latestAddedUser, locations: result }]
            : [{ ...latestAddedUser, locations: result }];
          setWebPageDetails({ ...webPageDetail, [name]: mappedUserValues });
          if (userIds?.length) {
            dispatch(getUserCombineServices({ user_ids: userIds }));
          } else {
            dispatch(setActiveServices([]));
          }
        });
    } else {
      const filteredLatestData: any = previousConnectUsers.filter((obj: any) =>
        userIds.includes(Number(obj?.user_id))
      );
      setWebPageDetails({ ...webPageDetail, [name]: filteredLatestData });
      if (userIds?.length) {
        dispatch(getUserCombineServices({ user_ids: userIds }));
      } else {
        dispatch(setActiveServices([]));
      }
    }
  };

  useEffect(() => {
    if (activeServices) {
      setActiveUserService(activeServices?.map((obj: any) => obj?.label));
    }
  }, [activeServices]);

  return (
    <Card id="users">
      <MDBox p={3}>
        <MDTypography variant="h5">Sola Pros</MDTypography>
      </MDBox>
      <MDBox pb={3} px={3}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <CustomAutoSearch
              idKey="user_id"
              nameKey="login_email"
              fieldLabel="Sola Pros"
              apiName="connect_users"
              responseKey="connect_users"
              name="connect_users"
              onChange={onChangeAutoComplete}
              value={webPageDetail?.connect_users || []}
              roleName="Sola Pro"
              error={errors?.connect_users || ""}
              required
              multiple
              slsAPI
              usersData={{ only_role_wise: true }}
              createNewLink={
                getPermission("users", "users_manage") ? "/users-&-roles/all-users" : ""
              }
            />
          </Grid>
          <MDBox pt={3} pl={3}>
            <MDTypography variant="h5">Locations</MDTypography>
          </MDBox>
          <Grid item xs={12} sm={12} md={12} lg={12} className="location">
            <CustomAutoSearch
              idKey="id"
              nameKey="name"
              fieldLabel="Locations"
              apiName="locations"
              responseKey="locations"
              name="location"
              required
              multiple
              value={webPageDetail?.location || null}
              onChange={onNormChangeAutoComplete}
              error={errors && errors.location}
              usersData={getAllAssociationLocations()}
              createNewLink={
                adminSuperAdmin() && getPermission("locations", "locations_location")
                  ? "/locations"
                  : ""
              }
            />
            <CustomErrorMessage
              errorColor="inherit"
              message="This webpage will appear for the follow Locations on our website."
            />
          </Grid>
          <Grid container>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <MDBox pt={3} pl={3.5}>
                <MDTypography variant="h5">Sola Pro Services</MDTypography>
                <FormHelperText id="component-helper-text">
                  These serves come from each Sola Pro who has access to this webpage. The Sola Pro
                  can edit this in the Sola Pro App, or you can edit this in the General tab.
                </FormHelperText>
              </MDBox>
            </Grid>
          </Grid>
          {allConnectServices?.map((item: any) => (
            <Grid item xs={12} sm={6} md={6} lg={4} key={item?.label}>
              <FormControl component="fieldset">
                <FormGroup aria-label="position">
                  <FormControlLabel
                    value="end"
                    disabled
                    control={
                      <Checkbox
                        checked={userActiveService?.includes(item?.label) ? true : false}
                        name={item?.label}
                        inputProps={{ "aria-label": "controlled" }}
                      />
                    }
                    label={item?.label}
                    labelPlacement="end"
                  />
                </FormGroup>
              </FormControl>
            </Grid>
          ))}
          {/* SD-3065 */}
          {webPageDetail?.other_services && (
            <Grid item xs={12} sm={6} md={6} lg={4}>
              <MDTypography sx={{ fontSize: "0.875rem", fontWeight: "700" }}>Other</MDTypography>
              <MDTypography sx={{ fontSize: "0.875rem", opacity: "0.7" }}>
                {webPageDetail.other_services.join(", ").replace(/,\s*$/, "")}
              </MDTypography>
            </Grid>
          )}
        </Grid>
      </MDBox>
    </Card>
  );
}

export default Services;
