import { useNavigate } from "react-router-dom";
import { useState, useEffect, useRef, useCallback } from "react";
import { Card, Icon, Checkbox } from "@mui/material";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import DataTable from "examples/Tables/DataTable";
import MDDialog from "components/MDDialog";
import Messages from "helper/messages";
import exportData from "helper/exportTableConst";
import { useAppDispatch, useAppSelector } from "store/store";
import { getCmsLocations, deleteCmsLocation } from "store/thunk/locationThunk";
import { setCmsLocation } from "store/slices/locationSlice";
import ViewDetails from "layouts/pages/locations/cms-locations/viewDetails";
import MDIconButton from "components/MDIconButton";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import {
  adminFranchisie,
  adminSuperAdmin,
  getAssociationLocations,
  userRoles,
  insertinArray,
  removeExportFields,
  setDefaultSelectedColumns,
  getUrlName,
} from "helper/services";
import { getDefaultCoulmns, saveSelectedAllData } from "store/thunk/connectUserThunk";
import { setDefaultsColumns } from "store/slices/connectUsersSlice";

function Index(props: any): JSX.Element {
  const { myAccount } = props;
  const fetchIdRef = useRef(0);
  const dispatch: any = useAppDispatch();
  const navigate = useNavigate();
  const { cmsLocations } = useAppSelector((state) => state.locationSlice);
  const { defaultSelectedColumns } = useAppSelector((state) => state.connectUsersReducer);
  const { userData } = useAppSelector((state) => state.authReducer);
  const [selectedItems, setSelectedItems] = useState([]);
  const [locations, setLocations] = useState<any>([]);
  const [pageCount, setPageCount] = useState(0);
  const [pageNo, setPageNo] = useState(0);
  const [perPage, setPageSize] = useState(50);
  const [totalCount, setTotalCount] = useState(0);
  const [deleteLocationId, setDeleteLocationId] = useState<any>(false);
  const [detailsPopup, setDetailsPopup] = useState<any>(false);
  const [updateData, setUpdateData] = useState<any>("");
  const [search, setSearch] = useState<any>(null);
  const [sortBy, setSortBy] = useState<any>([]);
  const [displayColumns, setDisplayColumns] = useState<any>([]);
  // SD-3199
  const [defaultExport, setDefaultExport] = useState<any>([]);
  const [callFetch, setCallFetch] = useState<any>(false);
  const [defaultSorting, setDefaultSorting] = useState<any>([]);
  const [defaultFilteing, setDefaultFilteing] = useState<any>([]);

  const [isCheckAll, setIsCheckAll] = useState(false);
  const tableSetting = "cms_locations";

  const getColumns = () => {
    let columns: any = [
      {
        Header: "",
        disableSortBy: true,
        width: "100px",
        accessor: "checkbox",
        disableCheck: false,
        checked: true,
      },
      {
        Header: "Name",
        accessor: "name",
        width: "200px",
        export: true,
        disableCheck: true,
        checked: true,
      },
      {
        Header: "Sola ID",
        accessor: "store_id",
        width: "200px",
        export: true,
        disableCheck: true,
        checked: true,
      },
      {
        Header: "URL Name",
        accessor: "url_name",
        export: true,
        disableCheck: false,
        checked: true,
      },
      {
        Header: "Address 1",
        accessor: "address_1",
        export: true,
        disableCheck: false,
        checked: true,
      },
      {
        Header: "City",
        accessor: "city",
        export: true,
        disableCheck: false,
        checked: true,
      },
      {
        Header: "State",
        accessor: "state",
        export: true,
        disableCheck: false,
        checked: false,
      },
    ];

    if (adminSuperAdmin()) {
      columns.push(
        {
          Header: "MSA",
          accessor: "msa",
          associative_colums: "msas.name",
          export: true,
          disableCheck: false,
          checked: false,
        },
        {
          Header: "Franchisee",
          accessor: "franchisee",
          associative_colums: "admins.email",
          export: true,
          disableCheck: false,
          checked: false,
        }
      );
    }
    if (adminFranchisie()) {
      columns = insertinArray(columns, 1, {
        Header: "ID",
        accessor: "id",
        export: true,
        disableCheck: true,
        checked: true,
      });
    }
    if (!myAccount) {
      columns.push({
        Header: "Action",
        accessor: "action",
        width: "200px",
        export: false,
        disableSortBy: true,
        disableCheck: false,
        checked: true,
      });
    }
    return columns;
  };

  const [checkedColumns, setCheckedColumns] = useState<any>(getColumns());

  useEffect(() => {
    if (checkedColumns?.length) {
      setDisplayColumns(checkedColumns.filter((col: any) => col.checked));
    }
  }, [checkedColumns]);

  const fetchData = useCallback(({ pageSize, pageIndex, search, sortBy, filterData }) => {
    const fetchId = ++fetchIdRef.current;
    const sort: any = sortBy || [];
    // We'll even set a delay to simulate a server here
    setTimeout(() => {
      if (fetchId === fetchIdRef.current) {
        // SD-3199
        if (
          (sortBy && sortBy?.length) ||
          (filterData && filterData?.length) ||
          sortBy?.length === 0
        ) {
          const sentReq = {
            sendData: {
              user_id: userData?.user_id,
              table_name: `cms_${exportData.locations.table_name}`,
              table_columns: checkedColumns,
              table_sort: sortBy,
              table_filter: filterData,
              default_export_data: defaultExport,
            },
          };
          dispatch(saveSelectedAllData(sentReq));
        }
        setPageNo(pageIndex);
        setSearch(search);
        setSortBy(sort);
        setPageSize(pageSize);
        // setSelectedItems([]);
        setIsCheckAll(false);
        dispatch(getCmsLocations({ pageSize, pageIndex, search, sort, userData, filterData }));
      }
    }, 1000);
  }, []);

  useEffect(() => {
    dispatch(setDefaultsColumns([]));
    // Set data in variable and Call defaultColums Thunk
    const sentReq = {
      user_id: userData?.user_id,
      table_name: `cms_${exportData.locations.table_name}`,
    };
    dispatch(getDefaultCoulmns(sentReq)).then((resp: any) => {
      // SD-3199
      const respFilter = resp?.payload?.table_filter || [];
      const respSorting = resp?.payload?.table_sort || [];
      const defaultDBexportData = resp?.payload?.default_export_data || [];
      const defaultDBColumn = resp?.payload?.table_columns || [];
      const tempdata = setDefaultSelectedColumns(defaultDBColumn, getColumns());
      setCheckedColumns(tempdata);
      setDefaultExport(defaultDBexportData);
      setDefaultSorting(respSorting);
      setSortBy(respSorting);
      setDefaultFilteing(respFilter);
      if (resp?.payload) {
        setCallFetch(true);
        fetchData({
          perPage,
          pageNo,
          search,
          sortBy: respSorting && respSorting?.length ? respSorting : [],
          additionalField: null,
          filterData: respFilter && respFilter?.length ? respFilter : [],
        });
      }
    });
  }, []);

  useEffect(() => {
    if (cmsLocations && cmsLocations?.locations) {
      setLocations(cmsLocations?.locations);
      setPageCount(cmsLocations?.meta?.total_pages);
      setTotalCount(cmsLocations?.meta?.total_count);
    }
  }, [cmsLocations]);

  useEffect(() => {
    if (defaultSelectedColumns && defaultSelectedColumns?.length) {
      const newColumns = setDefaultSelectedColumns(defaultSelectedColumns, getColumns());
      setCheckedColumns(newColumns);
    } else {
      setCheckedColumns(getColumns());
    }
  }, [defaultSelectedColumns]);

  const openDetailPopup = (data: any) => {
    setUpdateData(data);
    setDetailsPopup(true);
  };

  const clearData = () => {
    dispatch(setCmsLocation(null));
  };

  const closeDetailPopup = () => {
    setDetailsPopup(false);
  };

  const editLocation = (data: any) => {
    navigate(`/sola-cms/cms-locations/${getUrlName(data?.name)}`, {
      state: { prevPath: "/sola-cms/cms-locations", id: data?.id },
    });
  };

  const addLocation = () => {
    navigate(`/sola-cms/cms-locations/create-location`, {
      state: { prevPath: "/sola-cms/cms-locations" },
    });
  };

  // Start Remove selcted items
  const removeSelectedItem = (deletedId: number) => {
    setSelectedItems(selectedItems.filter((item: any) => Number(item) !== deletedId));
  };
  // End Remove selcted items

  const onDelete = () => {
    if (deleteLocationId) {
      dispatch(
        deleteCmsLocation({
          id: deleteLocationId,
          callback: setDeleteLocationId,
          pageNo,
          perPage,
          search,
          sortBy,
          removeSelectedItem,
        })
      );
    }
  };

  const actionButtons = (object: any) => (
    <>
      <MDIconButton
        tooltipName="View"
        aria-label="Open"
        color="info"
        onClick={() => openDetailPopup(object)}
      >
        <Icon fontSize="small">visibility</Icon>
      </MDIconButton>
      {(userData?.role === userRoles?.SYSTEM_ADMIN ||
        userData?.role === userRoles?.ADMIN ||
        userData?.role === userRoles?.FRANCHISEE) && (
        <MDIconButton
          tooltipName="Edit"
          aria-label="Edit"
          color="info"
          onClick={() => editLocation(object)}
        >
          <Icon fontSize="small">edit</Icon>
        </MDIconButton>
      )}
      {(userData?.role === userRoles?.SYSTEM_ADMIN || userData?.role === userRoles?.ADMIN) && (
        <MDIconButton
          tooltipName="Delete"
          aria-label="Delete"
          color="error"
          onClick={() => setDeleteLocationId(object.id)}
        >
          <Icon fontSize="small">delete</Icon>
        </MDIconButton>
      )}
    </>
  );

  const onChangeCheckBox = (e: any) => {
    let items = JSON.parse(JSON.stringify(selectedItems));
    if (e.target.checked && !items.includes(e.target.value)) {
      items.push(e.target.value);
      if (items.length === locations.length) {
        setIsCheckAll(true);
      }
    } else {
      items = items.filter((id: any) => id !== e.target.value);
      setIsCheckAll(false);
    }
    setSelectedItems(items);
  };

  const checkUncheckAll = (e: any) => {
    let allIds: any = [];
    allIds = locations.map((val: any) => String(val.id));
    if (e.target.checked) {
      const selectedIds = [...new Set([...selectedItems, ...allIds])];
      setSelectedItems(selectedIds);
      // allIds = locations.map((val: any) => String(val.id));
      setIsCheckAll(true);
    } else {
      allIds = locations.map((val: any) => String(val.id));
      allIds = selectedItems.filter((el: any) => !allIds.includes(el));
      setSelectedItems([...allIds]);
      setIsCheckAll(false);
      // allIds = [];
      // setIsCheckAll(false);
    }
    // setSelectedItems(allIds);
  };

  const getRows = () => {
    const data = locations.map((obj: any) => ({
      checkbox: (
        <Checkbox
          checked={selectedItems.includes(obj.id) || selectedItems.includes(obj.id.toString())}
          onChange={(e: any) => onChangeCheckBox(e)}
          value={obj.id}
        />
      ),
      id: obj.id || "-",
      store_id: obj.store_id || "-",
      name: obj.name || "-",
      url_name: obj.url_name || "-",
      address_1: obj.address_1 || "-",
      city: obj.city || "-",
      state: obj.state || "-",
      msa: obj.msa_name || "-",
      franchisee: obj.franchisee || "-",
      action: actionButtons(obj),
    }));
    return data;
  };

  const tableData = {
    columns: getColumns(),
    rows: getRows(),
  };

  const deleteDialogProps = {
    open: deleteLocationId ? true : false,
    onClose: () => setDeleteLocationId(false),
    onSave: () => onDelete(),
    title: "Delete Location",
    size: "sm",
    saveBtn: false,
    deleteBtn: true,
    saveTbtText: "Yes",
    closeBtnText: "No",
  };

  // Remove uncheck boxes
  const clearCheckItem = () => {
    setSelectedItems([]);
  };

  const getTabData = () =>
    (defaultSorting || defaultSorting?.length) &&
    callFetch && ( // SD-3199
      <DataTable
        defaultSortingColumns={defaultSorting} // SD-3199
        defaultFilteringColums={defaultFilteing} // SD-3199
        defaultDisplayColumns={displayColumns}
        setCheckedColumns={setCheckedColumns}
        table={tableData}
        canSearch
        searchText="Search Locations..."
        selectedItems={selectedItems}
        showExportSelectedBtn
        showExportAllBtn
        pageCount={pageCount}
        pageNo={pageNo}
        setPageNo={setPageNo}
        totalCount={totalCount}
        fetchData={fetchData}
        exportFields={removeExportFields(exportData.locations.export_fields)}
        exportTable={exportData.locations.table_name}
        exportType={exportData.locations.export_type}
        advanceFilter
        locationsIds={!adminSuperAdmin() && getAssociationLocations()}
        manualSearch
        manualSort
        isDisplayCheckAllCheckbox
        onChangeCheckAllCheckbox={checkUncheckAll}
        isCheckAll={isCheckAll}
        checkedColumns={checkedColumns}
        tableSetting={tableSetting}
        clearCheckItem={clearCheckItem}
        useRubyApiToExport
      />
    );

  return myAccount ? (
    getTabData()
  ) : (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox mt={5}>
        <Card>
          <MDBox
            bgColor="dark"
            color="white"
            coloredShadow="dark"
            borderRadius="xl"
            alignItems="center"
            justifyContent="space-between"
            pt={1}
            pb={1}
            pl={2}
            pr={2}
            ml={2}
            mr={2}
            display="flex"
            mt={-3}
            className="page-header"
          >
            <p className="page-header-label">Locations</p>
            {adminSuperAdmin() && (
              <MDButton
                variant="gradient"
                color="light"
                size="small"
                onClick={addLocation}
                className="xs-small"
              >
                <Icon sx={{ fontWeight: "bold" }}>add</Icon> Add Location
              </MDButton>
            )}
          </MDBox>
          {getTabData()}
          {detailsPopup && (
            <ViewDetails
              open={detailsPopup}
              updateData={updateData}
              onClose={() => {
                closeDetailPopup();
                clearData();
              }}
            />
          )}
          {deleteLocationId && (
            <MDDialog {...deleteDialogProps}>
              <MDTypography variant="h6" fontWeight="medium">
                {Messages.GENERAL.SURE_TO_DELETE}
              </MDTypography>
            </MDDialog>
          )}
        </Card>
      </MDBox>
    </DashboardLayout>
  );
}

export default Index;
