import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

function Index(props: any): JSX.Element {
  const { label, value } = props;
  return (
    <MDBox key={label} display="flex" py={1} pr={2}>
      <MDTypography variant="button" fontWeight="bold" textTransform="capitalize">
        {label}: &nbsp;
      </MDTypography>
      <MDTypography variant="button" fontWeight="regular" color="text">
        &nbsp;{value}
      </MDTypography>
    </MDBox>
  );
}
export default Index;
