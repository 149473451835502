import { Card, FormControlLabel, Switch } from "@mui/material";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

function AutomaticEnrollment(props: any): JSX.Element {
  const { dripCampaign, setDripCampaign, connectLocation } = props;

  return (
    <Card id="automatic-enrollment">
      <MDBox p={3} pb={0}>
        <MDTypography variant="h5">Automatic Enrollment</MDTypography>
      </MDBox>
      <MDBox pb={3} px={3}>
        <FormControlLabel
          control={
            <Switch
              checked={dripCampaign || false}
              onChange={(e: any) => setDripCampaign(e.target.checked)}
              inputProps={{ "aria-label": "controlled" }}
            />
          }
          label="Automatically Enroll All New Deals"
          labelPlacement="end"
        />
        {connectLocation?.connect_twillio_phone_number &&
          !connectLocation?.connect_twillio_phone_number?.sms_status && (
            <MDTypography variant="body2" color="error" fontSize={12} mt={2}>
              SMS Status is disabled of assigned location number.
            </MDTypography>
          )}
      </MDBox>
    </Card>
  );
}

export default AutomaticEnrollment;
