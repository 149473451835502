import MDDialog from "components/MDDialog";
import MDBox from "components/MDBox";
import { useAppDispatch, useAppSelector } from "store/store";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import { useState } from "react";
// import { getLeadDetails } from "store/thunk/leaseThunk";
import { setleaseApplicationSteps } from "store/slices/leaseSlice";
import { sendDataToApplicants } from "helper/services";

function DetailsPopup(props: any): JSX.Element {
  const { open, onClose, activeStep, setActiveStep } = props;
  const { existingDeal, leaseApplicationSteps } = useAppSelector((state) => state.leaseSlice);
  const [clicked, setClicked] = useState<boolean>(false);
  const dispatch = useAppDispatch();

  const onAdditionalBtnSave = () => {
    const storeApplicnatData = sendDataToApplicants(leaseApplicationSteps?.contactInquiries);
    const oldApplicants = leaseApplicationSteps?.applicants
      ? [...leaseApplicationSteps.applicants]
      : [];
    oldApplicants[0] = storeApplicnatData;
    dispatch(
      setleaseApplicationSteps({
        ...leaseApplicationSteps,
        applicants: oldApplicants,
      })
    );
    setActiveStep(activeStep + 1);
    onClose();
  };

  const dialogProps = {
    open,
    onClose,
    title: "Select Deal",
    size: "xl",
    saveBtn: false,
    overflowVisible: "overflowVisible",
    additionalBtn: true,
    additionalBtnText: "Add New",
    additionalBtnColor: "info",
    onAdditionalBtnSave,
  };

  const selectedDeal = (items: any) => {
    const setRecord = {
      first_name: items?.first_name,
      last_name: items?.last_name,
      email: items?.email,
      phone: items?.phone,
      isSublet: false,
    };

    const oldApplicants = leaseApplicationSteps?.applicants
      ? [...leaseApplicationSteps.applicants]
      : [];
    oldApplicants[0] = setRecord;
    dispatch(
      setleaseApplicationSteps({
        ...leaseApplicationSteps,
        applicants: oldApplicants,
      })
    );
    // const sendData = {
    //   leadId: selectedId,
    // };
    // dispatch(getLeadDetails(sendData)).then((resp: any) => {
    //   const oldApplicants: any = leaseApplicationSteps?.applicants
    //     ? [...leaseApplicationSteps.applicants]
    //     : [];
    //   oldApplicants[0] = resp?.payload;
    //   dispatch(
    //     setleaseApplicationSteps({
    //       ...leaseApplicationSteps,
    //       applicants: oldApplicants,
    //     })
    //   );
    // });
    setClicked(!clicked);
    setActiveStep(activeStep + 1);
    onClose();
  };

  // const cardItem = (items: any) => (
  //   <List
  //     sx={{
  //       width: "100%",
  //       maxWidth: "auto",
  //       bgcolor: "background.paper",
  //       padding: "15px 15px 10px 15px",
  //       margin: "10px",
  //     }}
  //     aria-label="contacts"
  //   >
  //     {console.log("items::", items)}

  //     <ListItem disablePadding className="selectItems">
  //       <ListItemButton>
  //         <ListItemText
  //           inset
  //           primary={items?.deal_name ? items?.deal_name : items?.name}
  //           onClick={() => selectedDeal(items?.id)}
  //         />
  //       </ListItemButton>
  //     </ListItem>
  //   </List>
  // );

  const existingItem = (items: any) =>
    items &&
    items?.connect_deal && (
      <List
        sx={{
          width: "100%",
          maxWidth: "auto",
          bgcolor: "background.paper",
          padding: "15px 15px 10px 15px",
          margin: "10px",
        }}
        aria-label="contacts"
      >
        <ListItem disablePadding className="selectItems">
          <ListItemButton>
            <ListItemText
              inset
              primary={items?.connect_deal?.deal_name}
              onClick={() => selectedDeal(items)}
            />
          </ListItemButton>
        </ListItem>
      </List>
    );

  return (
    <MDDialog {...dialogProps}>
      <MDBox pt={1} style={{ height: "150px", overflow: "auto" }}>
        {/* {leaseApplicationSteps?.stylists?.length > 0 &&
          leaseApplicationSteps?.stylists?.map((item: any) => cardItem(item))} */}
        {existingDeal &&
          existingDeal?.length > 0 &&
          existingDeal?.map((item: any) => existingItem(item))}
      </MDBox>
    </MDDialog>
  );
}

export default DetailsPopup;
