import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MESSAGES from "helper/messages";
import MDEditor from "components/MDEditor";

function Promotions(props: any): JSX.Element {
  const { locationDetail, setLocationDetail, onChangeField } = props;

  // SD-3360
  const modules: any = { toolbar: false };
  const formats: any = [];
  const onChangeMoveIn = (value: any, delta: any, source: any) => {
    if (source === "user") {
      setLocationDetail({ ...locationDetail, move_in_special: value });
    }
  };
  return (
    <Card id="promotions">
      <MDBox p={3}>
        <MDTypography variant="h5">Promotions</MDTypography>
      </MDBox>
      <MDBox pb={3} px={3}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6} md={6} lg={6}>
            <MDEditor
              placeholder="Enter your move-in special here..."
              value={locationDetail?.move_in_special ? locationDetail?.move_in_special : ""}
              onChange={(val: any, delta: any, source: any) => onChangeMoveIn(val, delta, source)}
              modules={modules}
              formats={formats}
              mt={3}
              className="move-in-container"
              style={{ height: "85px", border: "none", borderBottom: "1px solid #ccc" }}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={6} lg={6}>
            <MDInput
              multiline
              rows="3"
              variant="outlined"
              label="Special Callout"
              fullWidth
              name="open_house"
              placeholder="Special Callout"
              onChange={onChangeField}
              value={locationDetail?.open_house || ""}
              infoText={MESSAGES.INFO.OPTIONAL}
            />
          </Grid>
        </Grid>
      </MDBox>
    </Card>
  );
}

export default Promotions;
