import { useState, useEffect } from "react";
import { useAppDispatch } from "store/store";
import {
  Card,
  Icon,
  Grid,
  InputLabel,
  FormGroup,
  Checkbox,
  FormControlLabel,
  Accordion,
  AccordionDetails,
  AccordionSummary,
} from "@mui/material";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDDialog from "components/MDDialog";
import MDIconButton from "components/MDIconButton";
import MDTypography from "components/MDTypography";
import DataTable from "examples/Tables/DataTable";
import CustomAutoSearch from "components/CustomAutoSearch";
import Messages from "helper/messages";
import { getAuthUser, scrollToErrorByClass, getPermission } from "helper/services";
import {
  deleteLocationUser,
  getLocationUserDetails,
  saveLocationUser,
} from "store/thunk/locationThunk";
import Validations from "helper/validations";
import CustomErrorMessage from "components/CustomErrorMessage";
import { notificationFail } from "store/slices/notificationSlice";
import EditUser from "layouts/pages/locations/location-details/users/editUser";

function Index(props: any): JSX.Element {
  const dispatch = useAppDispatch();
  const loginUserData = getAuthUser();
  const { locationDetail, getAccessTypePermission } = props;
  const [users, setUsersData] = useState([]);
  const [locationAllData, setLocationAllData] = useState<any>([]);
  const [addUserPopup, setAddUserPopup] = useState(false);
  const [selectedUsers, setSelectedUsers] = useState(null);
  const [deleteUserId, setDeleteUserId] = useState<any>(null);
  const [locationAccessDetails, setLocationAccessDetails] = useState<any>([]);
  const [crmAccessDetails, setCRMAccessDetails] = useState<any>([]);
  const [accessData, setAccessData] = useState<any>({});
  const [errros, setErrors] = useState<any>({});
  const [editPopUp, setEditPopUp] = useState<boolean>(false);
  const [viewPopUp, setViewPopup] = useState<boolean>(false);
  const [currentUser, setCurrentUser] = useState<any>({});
  const [accessObject, setAccessObject] = useState<any>({});

  useEffect(() => {
    if (loginUserData && loginUserData?.access && loginUserData?.access?.length) {
      const getLocationAccessData = loginUserData?.access.find(
        (obj: any) => obj.alias === "locations"
      );
      const defaultAccessObject: any = {};
      if (getLocationAccessData) {
        const getChildMenuAccess: any = getLocationAccessData?.child_menu?.find(
          (newObj: any) => newObj.alias === "locations_location"
        );
        setLocationAccessDetails(getChildMenuAccess?.page_sections || []);
        getChildMenuAccess?.page_sections?.map((obj: any) => {
          defaultAccessObject[obj?.alias] = {
            view_access: true,
            menu_id: Number(obj?.menu_id),
          };
          return true;
        });
      }

      const getCRMAccessData: any = loginUserData?.access?.find((obj: any) => obj.alias === "crm");
      if (getCRMAccessData) {
        setCRMAccessDetails(getCRMAccessData?.child_menu || []);
        getCRMAccessData?.child_menu?.map((obj: any) => {
          defaultAccessObject[obj?.alias] = {
            view_access: true,
            menu_id: Number(obj?.menu_id),
          };
          return true;
        });
      }
      setAccessData(defaultAccessObject);
      setAccessObject(defaultAccessObject);
    }
  }, []);

  const onClose = () => {
    setSelectedUsers(null);
    setAddUserPopup(false);
    setErrors({});
    setAccessData(accessObject);
  };

  const onAddUser = () => {
    const requestObj: any = {
      location_id: locationDetail?.id,
      user_id: selectedUsers?.user_id,
      user_access: {
        ...accessData,
      },
      onClose,
    };

    const alreadyExistUserAsOwners: any = locationDetail?.owners?.find(
      (obj: any) => Number(obj?.connect_user?.user_id) === Number(selectedUsers?.user_id)
    );
    if (alreadyExistUserAsOwners) {
      dispatch(notificationFail(Messages.ERROR.USER_ALREADY_EXISTS_AS_OWNER));
    } else {
      const error = Validations.validateLocationUser(requestObj);
      setErrors(error);
      if (!Object.keys(error).length) {
        dispatch(saveLocationUser(requestObj));
      } else {
        scrollToErrorByClass(error);
      }
    }
  };

  const onDelete = () => {
    dispatch(
      deleteLocationUser({
        user_id: deleteUserId?.user_id,
        location_id: locationAllData?.id,
      })
    );

    setDeleteUserId(false);
  };

  useEffect(() => {
    if (locationDetail) {
      setLocationAllData(locationDetail);
    }
    if (locationDetail?.location_users && Array.isArray(locationDetail?.location_users)) {
      setUsersData(locationDetail?.location_users);
    }
  }, [locationDetail]);

  const isOwner = (userId: any) => (getAuthUser()?.user_id === userId ? true : false);

  const onUserEdit = (obj: any) => {
    dispatch(getLocationUserDetails({ location_id: locationDetail?.id, user_id: obj?.user_id }));
    setEditPopUp(true);
    setCurrentUser(obj);
  };

  const onUserView = (obj: any) => {
    dispatch(getLocationUserDetails({ location_id: locationDetail?.id, user_id: obj?.user_id }));
    setViewPopup(true);
    setCurrentUser(obj);
  };

  const actionButtons = (obj: any) => (
    <>
      <MDIconButton
        tooltipName="View"
        aria-label="View"
        color="info"
        disabled={getAccessTypePermission("location_general", "view_access")}
        onClick={() => onUserView(obj)}
      >
        <Icon fontSize="small">visibility</Icon>
      </MDIconButton>
      <MDIconButton
        tooltipName="Edit"
        aria-label="Edit"
        color="info"
        disabled={
          isOwner(obj?.location_users?.user_id) ||
          getAccessTypePermission("location_general", "edit_access")
        }
        onClick={() => onUserEdit(obj)}
      >
        <Icon fontSize="small">edit</Icon>
      </MDIconButton>
      <MDIconButton
        tooltipName={
          isOwner(obj?.location_users?.user_id) ? "You cannot delete himself." : "Delete"
        }
        aria-label="Delete"
        color="error"
        disabled={
          isOwner(obj?.location_users?.user_id) ||
          getAccessTypePermission("location_general", "edit_access")
        }
        onClick={() => setDeleteUserId(obj)}
      >
        <Icon fontSize="small">delete</Icon>
      </MDIconButton>
    </>
  );

  const getRows = () => {
    const data: any = users?.map((obj: any) => ({
      id: obj?.user_id,
      first_name: obj?.first_name || "-",
      last_name: obj?.last_name || "-",
      email: obj?.login_email || "-",
      action: actionButtons(obj),
    }));
    return data;
  };

  const tableData = {
    columns: [
      { Header: "Id", accessor: "id", export: true },
      { Header: "First name", accessor: "first_name", export: true },
      { Header: "Last name", accessor: "last_name", export: true },
      { Header: "Email", accessor: "email", export: true },
      { Header: "Action", accessor: "action", export: false, disableSortBy: true },
    ],
    rows: getRows(),
  };

  const handleChange = (event: any, menuId: any) => {
    const tempObj: any = {
      menu_id: Number(menuId),
      ...accessData?.[event.target.id],
      [event.target.name]: event.target.checked,
      none_access: false,
    };
    if (event.target.checked) {
      if (event.target.name === "edit_access") {
        tempObj.view_access = true;
      } else if (event.target.name === "add_access") {
        tempObj.view_access = true;
        tempObj.edit_access = true;
      }
    }
    setAccessData({
      ...accessData,
      [event.target.id]: { ...tempObj },
    });
  };

  const handleChangeOnNone = (event: any, menuId: any) => {
    if (event.target.checked) {
      setAccessData({
        ...accessData,
        [event.target.id]: {
          menu_id: Number(menuId),
          view_access: false,
          edit_access: false,
          add_access: false,
          [event.target.name]: event.target.checked,
        },
      });
    }
  };

  const getCheckboxList = ({ crm }: any) => {
    const html: any = [];
    const selectedDetails: any[] = crm ? crmAccessDetails : locationAccessDetails;
    selectedDetails?.map((obj: any) => {
      html.push(
        <Grid item xs={12} sm={12} md={12} lg={12} key={obj?.display_menu}>
          <Grid container>
            <Grid item md={12}>
              <InputLabel sx={{ mb: 1, mt: 1, fontWeight: "bold" }}>{obj?.display_menu}</InputLabel>
            </Grid>
            <Grid item md={12}>
              <FormGroup row>
                <MDBox sx={{ display: "flex" }}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        id={obj?.alias}
                        name="none_access"
                        checked={accessData?.[obj?.alias]?.none_access || false}
                        onChange={(e: any) => handleChangeOnNone(e, obj?.menu_id)}
                      />
                    }
                    label="None"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        id={obj?.alias}
                        name="view_access"
                        checked={accessData?.[obj?.alias]?.view_access || false}
                        onChange={(e: any) => handleChange(e, obj?.menu_id)}
                        disabled={accessData?.[obj?.alias]?.view_access || false}
                        sx={{ opacity: accessData?.[obj?.alias]?.view_access ? "0.5" : "" }}
                      />
                    }
                    label="View"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        id={obj?.alias}
                        name="edit_access"
                        checked={accessData?.[obj?.alias]?.edit_access || false}
                        onChange={(e: any) => handleChange(e, obj?.menu_id)}
                        disabled={accessData?.[obj?.alias]?.add_access || false}
                        sx={{ opacity: accessData?.[obj?.alias]?.add_access ? "0.5" : "" }}
                      />
                    }
                    label="Edit"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        id={obj?.alias}
                        name="add_access"
                        checked={accessData?.[obj?.alias]?.add_access || false}
                        onChange={(e: any) => handleChange(e, obj?.menu_id)}
                      />
                    }
                    label="Add"
                  />
                </MDBox>
              </FormGroup>
            </Grid>
          </Grid>
        </Grid>
      );
      return true;
    });
    return html;
  };

  const userDialog = {
    open: addUserPopup,
    onClose,
    onSave: onAddUser,
    title: "Add User",
    size: "sm",
    saveBtn: selectedUsers ? true : false,
    overflowVisible: !selectedUsers ? "overflowVisible" : "",
  };

  const deleteDialogProps = {
    open: deleteUserId,
    onClose: () => setDeleteUserId(false),
    onSave: () => onDelete(),
    title: "Delete User",
    size: "sm",
    saveBtn: false,
    deleteBtn: true,
    saveTbtText: "Yes",
    closeBtnText: "No",
  };

  const editDialogProps = {
    userDialog: {
      open: viewPopUp ? viewPopUp : editPopUp,
      onClose: () => {
        setEditPopUp(false);
        setViewPopup(false);
      },
      title: viewPopUp ? "View User" : "Edit User",
      size: "sm",
      saveBtn: !viewPopUp,
    },
    errros,
    selectedUsers: currentUser,
    crmAccessDetails,
    locationAccessDetails,
    setErrors,
    locationId: locationDetail?.id,
    viewStatus: viewPopUp,
  };

  return (
    <MDBox mt={7}>
      <Card>
        <MDBox
          bgColor="dark"
          color="white"
          coloredShadow="dark"
          borderRadius="xl"
          alignItems="center"
          justifyContent="space-between"
          pt={1}
          pb={1}
          pl={2}
          pr={2}
          ml={2}
          mr={2}
          display="flex"
          mt={-3}
          className="page-header"
        >
          <p className="page-header-label">Users</p>
          {!getAccessTypePermission("location_general", "add_access") && (
            <MDButton
              className="xs-small"
              variant="gradient"
              color="light"
              size="small"
              onClick={() => setAddUserPopup(true)}
            >
              <Icon sx={{ fontWeight: "bold" }}>add</Icon> Add New
            </MDButton>
          )}
        </MDBox>
        <DataTable table={tableData} canSearch searchText="Search..." />
        {addUserPopup && (
          <MDDialog {...userDialog}>
            <Grid container spacing={2} mt={1}>
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <CustomAutoSearch
                  idKey="user_id"
                  nameKey="login_email"
                  fieldLabel="User"
                  apiName="connect_users"
                  responseKey="connect_users"
                  name="users"
                  slsAPI
                  required
                  value={selectedUsers || null}
                  onChange={(event: any, val: any) => setSelectedUsers(val)}
                  createNewLink={
                    getPermission("users", "users_manage") ? "/users-&-roles/all-users" : ""
                  }
                  error={errros && errros?.user_id}
                />
              </Grid>
              {selectedUsers && (
                <Grid item xs={12} sm={12} md={12} lg={12} className="checkbox-list-accordian">
                  <MDBox className="mui-checkbox-group">
                    <Accordion expanded>
                      <AccordionSummary
                        className="location-accordion"
                        aria-controls="publishbh-content"
                      >
                        <MDTypography variant="h6">Location</MDTypography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Grid container>{getCheckboxList({ crm: false })}</Grid>
                      </AccordionDetails>
                    </Accordion>
                    {crmAccessDetails?.length > 0 && (
                      <Accordion expanded>
                        <AccordionSummary
                          className="location-accordion"
                          aria-controls="publishbh-content"
                        >
                          <MDTypography variant="h6">CRM</MDTypography>
                        </AccordionSummary>
                        <AccordionDetails>
                          <Grid container>{getCheckboxList({ crm: true })}</Grid>
                        </AccordionDetails>
                      </Accordion>
                    )}
                  </MDBox>
                </Grid>
              )}
              <MDBox className="user_access" pl={2} pt={1}>
                {errros && errros?.user_access && (
                  <CustomErrorMessage message={errros?.user_access} />
                )}
              </MDBox>
            </Grid>
          </MDDialog>
        )}
        {(editPopUp || viewPopUp) && <EditUser {...editDialogProps} />}
        {deleteUserId && (
          <MDDialog {...deleteDialogProps}>
            <MDTypography variant="h6" fontWeight="medium">
              {Messages.GENERAL.SURE_TO_DELETE}
            </MDTypography>
          </MDDialog>
        )}
      </Card>
    </MDBox>
  );
}

export default Index;
