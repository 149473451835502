import { useState } from "react";
import Grid from "@mui/material/Grid";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Tooltip from "@mui/material/Tooltip";
import Icon from "@mui/material/Icon";
import MDBox from "components/MDBox";
import MDBadgeDot from "components/MDBadgeDot";
import MDButton from "components/MDButton";
import DefaultStatisticsCard from "examples/Cards/StatisticsCards/DefaultStatisticsCard";
import DefaultLineChart from "examples/Charts/LineCharts/DefaultLineChart";
import ChannelsChart from "layouts/dashboards/sales/components/ChannelsChart";
import defaultLineChartData from "layouts/dashboards/sales/data/defaultLineChartData";

function MarketingDashboard(): JSX.Element {
  // DefaultStatisticsCard state for the dropdown value
  const [salesDropdownValue, setSalesDropdownValue] = useState<string>("This Month");
  const [customersDropdownValue, setCustomersDropdownValue] = useState<string>("This Month");
  const [revenueDropdownValue, setRevenueDropdownValue] = useState<string>("This Month");

  // DefaultStatisticsCard state for the dropdown action
  const [salesDropdown, setSalesDropdown] = useState<string | null>(null);
  const [customersDropdown, setCustomersDropdown] = useState<string | null>(null);
  const [revenueDropdown, setRevenueDropdown] = useState<string | null>(null);

  // DefaultStatisticsCard handler for the dropdown action
  const openSalesDropdown = ({ currentTarget }: any) => setSalesDropdown(currentTarget);
  const closeSalesDropdown = ({ currentTarget }: any) => {
    setSalesDropdown(null);
    setSalesDropdownValue(currentTarget.innerText || salesDropdownValue);
  };
  const openCustomersDropdown = ({ currentTarget }: any) => setCustomersDropdown(currentTarget);
  const closeCustomersDropdown = ({ currentTarget }: any) => {
    setCustomersDropdown(null);
    setCustomersDropdownValue(currentTarget.innerText || salesDropdownValue);
  };
  const openRevenueDropdown = ({ currentTarget }: any) => setRevenueDropdown(currentTarget);
  const closeRevenueDropdown = ({ currentTarget }: any) => {
    setRevenueDropdown(null);
    setRevenueDropdownValue(currentTarget.innerText || salesDropdownValue);
  };

  // Dropdown menu template for the DefaultStatisticsCard
  const renderMenu = (state: any, close: any) => (
    <Menu
      anchorEl={state}
      transformOrigin={{ vertical: "top", horizontal: "center" }}
      open={Boolean(state)}
      onClose={close}
      keepMounted
      disableAutoFocusItem
    >
      <MenuItem onClick={close}>Last 7 days</MenuItem>
      <MenuItem onClick={close}>Last week</MenuItem>
      <MenuItem onClick={close}>Last 30 days</MenuItem>
    </Menu>
  );

  return (
    <MDBox py={3}>
      <MDBox mb={3}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={4}>
            <DefaultStatisticsCard
              title="Avg Cost / Lead"
              count="$254"
              percentage={{
                color: "success",
                value: "-5%",
                label: "Last Month",
              }}
              dropdown={{
                action: openSalesDropdown,
                menu: renderMenu(salesDropdown, closeSalesDropdown),
                value: salesDropdownValue,
              }}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <DefaultStatisticsCard
              title="Est. Value / Lead"
              count="$392"
              percentage={{
                color: "error",
                value: "-3.5%",
                label: "Last Month",
              }}
              dropdown={{
                action: openCustomersDropdown,
                menu: renderMenu(customersDropdown, closeCustomersDropdown),
                value: customersDropdownValue,
              }}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <DefaultStatisticsCard
              title="Est. Lead Value"
              count="$2.856"
              percentage={{
                color: "success",
                value: "+12%",
                label: "Last Month",
              }}
              dropdown={{
                action: openRevenueDropdown,
                menu: renderMenu(revenueDropdown, closeRevenueDropdown),
                value: revenueDropdownValue,
              }}
            />
          </Grid>
        </Grid>
      </MDBox>
      <MDBox mb={3}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6} lg={4}>
            <ChannelsChart />
          </Grid>
          <Grid item xs={12} sm={6} lg={8} className="revenue-line-chart">
            <DefaultLineChart
              title="Est. Revenue From Ads"
              description={
                <MDBox display="flex" justifyContent="space-between">
                  <MDBox display="flex" ml={-1}>
                    <MDBadgeDot color="info" size="sm" badgeContent="Facebook Ads" />
                    <MDBadgeDot color="dark" size="sm" badgeContent="Google Ads" />
                  </MDBox>
                  <MDBox mt={-4} mr={-1} position="absolute" right="1.5rem">
                    <Tooltip title="See which ads perform better" placement="left" arrow>
                      <MDButton variant="outlined" color="secondary" size="small" circular iconOnly>
                        <Icon>priority_high</Icon>
                      </MDButton>
                    </Tooltip>
                  </MDBox>
                </MDBox>
              }
              chart={defaultLineChartData}
            />
          </Grid>
        </Grid>
      </MDBox>
    </MDBox>
  );
}

export default MarketingDashboard;
