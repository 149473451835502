import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "store/store";
import MDDialog from "components/MDDialog";
import {
  Autocomplete,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  Switch,
  TextField,
} from "@mui/material";
import MDBox from "components/MDBox";
import MDInput from "components/MDInput";
import Validations from "helper/validations";
import MDDatePicker from "components/MDDatePicker";
import CustomRequiredLabel from "components/CustomRequiredLabel";
import {
  addMonths,
  addWeeks,
  scrollToError,
  getPermission,
  adminSuperAdmin,
  getCRMCreateAccess,
} from "helper/services";
import CustomAutoSearch from "components/CustomAutoSearch";
import { getLocation } from "store/thunk/locationThunk";
import { getLeaseApplicationDetail, SendLeaseApplication } from "store/thunk/leaseThunk";
import MDTypography from "components/MDTypography";
import MDLabelValue from "components/MDLabelValue";
import CustomErrorMessage from "components/CustomErrorMessage";
import CustomPhoneInput from "components/CustomPhoneInput";
import Review from "./Review";

function StartLease(props: any): JSX.Element {
  const dispatch: any = useAppDispatch();

  const { connectLocation } = useAppSelector((state) => state.locationSlice);
  const { leaseDetails } = useAppSelector((state) => state.leaseSlice);
  const { open, onClose, isUpdate, fullCardDatas, cardData, activeStatus, leasePopupData } = props;
  const [leaseData, setLeaseData] = useState<any>({});
  const [errors, setErrors] = useState<any>({});
  const [isReview, setIsReview] = useState<any>(false);

  useEffect(() => {
    if (isReview && document.querySelector(".MuiDialogContent-root")) {
      document.querySelector(".MuiDialogContent-root").scrollTo({
        top: 0,
        behavior: "smooth",
      });
    }
  }, [isReview]);

  const onNext = () => {
    const error = Validations.validateSendLeaseApplication(leaseData, isUpdate);
    setErrors(error);
    if (!Object.keys(error).length) {
      setIsReview(true);
    } else {
      scrollToError(error);
    }
  };

  const onSendNow = () => {
    const req = {
      leaseData,
      leadId: fullCardDatas?.board?.id,
      leadStatus: "Closed Leased",
      isNotSend: false,
      onClose,
    };
    dispatch(SendLeaseApplication(req));
  };

  const onSendLater = () => {
    const req = {
      leaseData,
      leadId: fullCardDatas?.board?.id,
      leadStatus: "Closed Leased",
      isNotSend: true,
      onClose,
      activeStatus,
    };
    dispatch(SendLeaseApplication(req));
  };

  useEffect(() => {
    if (
      isUpdate &&
      (leasePopupData?.lease_application_status === "not_sent" ||
        leasePopupData?.lease_application_status === "application_sent")
    ) {
      dispatch(getLeaseApplicationDetail({ leaseId: leasePopupData?.id }));
    }
  }, [leasePopupData]);

  useEffect(() => {
    if (leaseDetails) {
      const data = { ...leaseDetails };
      if (data?.location_id && data?.location_name) {
        data.location = { cms_location_id: data?.location_id, name: data?.location_name };
      }
      if (leaseDetails?.connect_location?.units) {
        data.units = leaseDetails?.connect_location?.units;
      }
      if (leaseDetails?.connect_location?.address_1) {
        data.address_1 = leaseDetails?.connect_location?.address_1;
      }
      if (leaseDetails?.connect_location?.address_2?.length) {
        data.address_2 = leaseDetails?.connect_location?.address_2;
      }
      setLeaseData(data);
    }
  }, [leaseDetails]);

  useEffect(() => {
    if (connectLocation) {
      const tempconnectLocation = { ...connectLocation };
      if (tempconnectLocation.id) {
        delete tempconnectLocation?.id;
        delete tempconnectLocation?.insurance_anniversary;
      }
      const date = leaseData?.move_in_date ? new Date(leaseData.move_in_date) : new Date();
      let firstPaymentDate = "";
      let leaseEndDate = "";
      if (connectLocation.weeks_free_rent) {
        const firstPayment = addWeeks(
          date,
          Number(
            leaseData.weeks_free_rent ? leaseData.weeks_free_rent : connectLocation.weeks_free_rent
          )
        );
        firstPaymentDate = new Date(firstPayment).toISOString();
      }
      if (connectLocation.lease_term) {
        const leaseEnd = addMonths(
          date,
          Number(leaseData.lease_term ? leaseData.lease_term : connectLocation.lease_term)
        );
        leaseEndDate = new Date(leaseEnd).toISOString();
      }
      setLeaseData({
        ...leaseData,
        ...tempconnectLocation,
        first_payment_date: firstPaymentDate ? firstPaymentDate : null,
        lease_end: leaseEndDate,
        lease_sign: new Date(),
      });
    }
  }, [connectLocation]);

  useEffect(() => {
    if (cardData && isUpdate) {
      const tempLeaseData = { ...cardData };
      const split = tempLeaseData.title.split(" ");
      const firstname = split.slice(0, split.length - 1).join(" ");
      const lastname = split.pop();
      Object.assign(tempLeaseData, { first_name: firstname, last_name: lastname });
      if (cardData?.locationId && cardData?.locationName) {
        tempLeaseData.location = {
          cms_location_id: cardData?.locationId,
          name: cardData?.locationName,
        };
        dispatch(getLocation({ id: cardData?.locationId, cmsLocation: true }));
      }
      setLeaseData(tempLeaseData);
    }
  }, [cardData]);

  const onChangeField = (e: any) => {
    const date = leaseData?.move_in_date ? new Date(leaseData.move_in_date) : new Date();
    if (e.target.name === "months_free_insurance") {
      const insuranceStart = addMonths(date, Number(e.target.value || 0));
      setLeaseData({
        ...leaseData,
        [e.target.name]: e.target.value,
        insurance_start: new Date(insuranceStart).toISOString(),
      });
    } else if (e.target.name === "weeks_free_rent") {
      const firstPayment = addWeeks(date, e.target.value || 0);
      setLeaseData({
        ...leaseData,
        [e.target.name]: e.target.value,
        first_payment_date: new Date(firstPayment).toISOString(),
      });
    } else if (e.target.name === "lease_term") {
      const leaseEnd = addMonths(date, Number(e.target.value));
      setLeaseData({
        ...leaseData,
        [e.target.name]: e.target.value,
        lease_end: new Date(leaseEnd).toISOString(),
      });
    } else {
      setLeaseData({ ...leaseData, [e.target.name]: e.target.value });
    }
  };

  const onChangeMoveinDate = (value: any, name: any) => {
    const date = new Date(value);
    // const leaseEnd = addYears(date, 2);
    const leaseEnd = addMonths(date, Number(leaseData.lease_term));
    const firstPayment = addWeeks(date, leaseData?.weeks_free_rent || 0);
    const insuranceStart = addMonths(date, Number(leaseData?.months_free_insurance || 0));
    setLeaseData({
      ...leaseData,
      [name]: new Date(value).toISOString(),
      lease_start: new Date(value).toISOString(),
      lease_end: new Date(leaseEnd).toISOString(),
      first_payment_date: new Date(firstPayment).toISOString(),
      insurance_start: new Date(insuranceStart).toISOString(),
      insurance_anniversary: new Date(value).toISOString(),
    });
  };

  const onChangeDate = (value: any, name: any) => {
    setLeaseData({ ...leaseData, [name]: new Date(value).toISOString() });
  };

  const onChangeCheck = (e: any) => {
    setLeaseData({ ...leaseData, [e.target.name]: e.target.checked });
  };

  const onChangeAutoComplete = (name: string, value: any) => {
    setLeaseData({ ...leaseData, [name]: value });
    dispatch(getLocation({ id: value?.cms_location_id, cmsLocation: true }));
  };

  const dialogProps = {
    open,
    onSave: isReview ? (!isUpdate ? onSendLater : onSendNow) : onNext,
    onClose: () => (isReview ? setIsReview(false) : onClose()),
    closeBtnText: isReview ? "Back" : "Close",
    title: "Send Lease Application",
    size: "md",
    saveTbtText: isReview
      ? !isUpdate
        ? "Save"
        : leasePopupData?.lease_application_status === "application_sent"
        ? "Update & Resend"
        : "Send Now"
      : "Next",
    additionalBtnText: "Send Later",
    onAdditionalBtnSave: onSendLater,
    additionalBtnColor: "error",
    additionalBtn:
      isReview && isUpdate && leasePopupData?.lease_application_status !== "application_sent",
  };

  const renderAddress = (leaseData: any) => {
    let address = "";
    if (leaseData.address_1) {
      address += leaseData.address_1;
    }
    if (leaseData.location && leaseData.location.address_2) {
      address += `${address !== "" ? ", " : ""}`;
      address += leaseData.location.address_2;
    }
    if (leaseData.address) {
      address += `${address !== "" ? ", " : ""}`;
      address += leaseData.address;
    }
    if (leaseData.city) {
      address += `${address !== "" ? ", " : ""}`;
      address += leaseData.city;
    }
    if (leaseData.state) {
      address += `${address !== "" ? ", " : ""}`;
      address += leaseData.state;
    }
    if (leaseData.country) {
      address += `${address !== "" ? ", " : ""}`;
      address += leaseData.country;
    }
    if (leaseData.postal_code) {
      address += `${address !== "" ? ", " : ""}`;
      address += leaseData.postal_code;
    }
    return address;
  };

  return (
    <MDDialog {...dialogProps}>
      {isReview ? (
        <Review leaseData={leaseData} />
      ) : (
        <MDBox>
          <MDBox p={2}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <MDTypography variant="h5">Applicant Information</MDTypography>
              </Grid>
              <Grid item xs={12} sm={12} md={6}>
                <MDInput
                  label="First Name"
                  variant="standard"
                  fullWidth
                  name="first_name"
                  placeholder="First name"
                  onChange={onChangeField}
                  value={leaseData?.first_name || ""}
                  error={(errors && errors?.first_name) || false}
                  helperText={errors && errors?.first_name}
                  required
                />
              </Grid>
              <Grid item xs={12} sm={12} md={6}>
                <MDInput
                  label="Last Name"
                  variant="standard"
                  fullWidth
                  name="last_name"
                  placeholder="Last Name"
                  onChange={onChangeField}
                  value={leaseData?.last_name || ""}
                  error={(errors && errors?.last_name) || false}
                  helperText={errors && errors?.last_name}
                  required
                />
              </Grid>
              <Grid item xs={12} sm={12} md={6}>
                <MDInput
                  label="Email Address"
                  variant="standard"
                  fullWidth
                  name="email"
                  placeholder="Email Address"
                  onChange={onChangeField}
                  value={leaseData?.email || ""}
                  error={errors && errors?.email ? true : false}
                  helperText={errors && errors?.email}
                  required
                />
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <MDInput
                  required
                  fullWidth
                  label="Phone Number"
                  id="phone"
                  key="phone"
                  variant="standard"
                  name="phone"
                  placeholder="(000) 000-0000"
                  onChange={onChangeField}
                  value={leaseData?.phone || " "}
                  error={(errors && errors?.phone) || false}
                  helperText={errors && errors?.phone}
                  InputProps={{
                    inputComponent: CustomPhoneInput as any,
                  }}
                />
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <MDTypography variant="h5" mt={4} mb={2}>
                  Lease Information
                </MDTypography>
              </Grid>
              <Grid item xs={12} sm={12} md={6}>
                <CustomAutoSearch
                  idKey="cms_location_id"
                  nameKey="name"
                  fieldLabel="Location"
                  parentKey="location"
                  apiName="get-locations"
                  responseKey="data"
                  name="location"
                  value={leaseData?.location || null}
                  required
                  fullWidth
                  slsAPI
                  onChange={onChangeAutoComplete}
                  createNewLink={
                    adminSuperAdmin() && getPermission("locations", "locations_location")
                      ? "/locations"
                      : ""
                  }
                  usersData={
                    !adminSuperAdmin()
                      ? getCRMCreateAccess("add_access", "crm", "crm_lease_application")
                      : null
                  }
                />
                {errors && errors.location && <CustomErrorMessage message={errors.location} />}
              </Grid>
              <Grid item xs={12} sm={12} md={12}>
                <MDLabelValue label="Sola ID" value={leaseData?.store_id} />
                <MDLabelValue label="Address" value={renderAddress(leaseData)} />
                <MDLabelValue label="State For Lease Agreement" value={leaseData?.state} />
              </Grid>
              <Grid container item xs={12} sm={12} md={6}>
                <Autocomplete
                  id="unit"
                  className="unit-autocomplete"
                  fullWidth
                  options={leaseData?.units || []}
                  getOptionLabel={(option) => option.unit}
                  value={
                    leaseData?.unit ||
                    (leaseData?.lease_units &&
                      Object.entries(leaseData.lease_units).length &&
                      leaseData?.lease_units) ||
                    null
                  }
                  onChange={(event: any, newValue: any) => {
                    setLeaseData({ ...leaseData, unit: newValue });
                  }}
                  renderOption={(props: any, option: any) => <li {...props}>{option.unit}</li>}
                  renderInput={(params: any) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      label="Units"
                      placeholder="Units"
                      required
                      error={errors && errors.unit ? true : false}
                      helperText={errors && errors.unit}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <FormControl fullWidth required={isUpdate}>
                  <InputLabel id="rent_period">Rent Period</InputLabel>
                  <Select
                    required={isUpdate}
                    label="rent_period"
                    className="height45"
                    error={errors && errors?.rent_period ? true : false}
                    name="rent_period"
                    value={leaseData?.rent_period || ""}
                    onChange={onChangeField}
                  >
                    <MenuItem value="Weekly">Weekly</MenuItem>
                    <MenuItem value="Monthly">Monthly</MenuItem>
                  </Select>
                  {errors && errors.rent_period && (
                    <CustomErrorMessage message={errors.rent_period} />
                  )}
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6} sx={{ marginTop: "25px" }}>
                <FormControl fullWidth required={isUpdate}>
                  <InputLabel id="rent_due_date">Rent Due Date</InputLabel>
                  <Select
                    required={isUpdate}
                    label="rent_due_date"
                    className="height45"
                    error={errors && errors?.rent_due_date ? true : false}
                    name="rent_due_date"
                    value={leaseData?.rent_due_date || ""}
                    onChange={onChangeField}
                  >
                    <MenuItem value="Monday">Monday</MenuItem>
                    <MenuItem value="Tuesday">Tuesday</MenuItem>
                    <MenuItem value="Wednesday">Wednesday</MenuItem>
                    <MenuItem value="Thursday">Thursday</MenuItem>
                    <MenuItem value="Friday">Friday</MenuItem>
                    <MenuItem value="Saturday">Saturday</MenuItem>
                    <MenuItem value="Sunday">Sunday</MenuItem>
                  </Select>
                  {errors && errors.rent_due_date && (
                    <CustomErrorMessage message={errors.rent_due_date} />
                  )}
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <CustomRequiredLabel label="Security Deposit Amount" />
                </Grid>
                <OutlinedInput
                  required={isUpdate}
                  type="number"
                  fullWidth
                  value={leaseData?.security_deposit_amount || ""}
                  name="security_deposit_amount"
                  onChange={onChangeField}
                  inputProps={{ min: 0 }}
                  error={(errors && errors?.security_deposit_amount) || false}
                  startAdornment="$"
                />
                {errors && errors?.security_deposit_amount && (
                  <CustomErrorMessage message={errors?.security_deposit_amount} />
                )}
                {/*  <MDInput
                  required={isUpdate}
                  fullWidth
                  type="number"
                  label="Security Deposit Amount"
                  variant="outlined"
                  name="security_deposit_amount"
                  placeholder="Security Deposit Amount"
                  onChange={onChangeField}
                  value={leaseData?.security_deposit_amount || ""}
                  error={(errors && errors?.security_deposit_amount) || false}
                  helperText={errors && errors?.security_deposit_amount}
                /> */}
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <CustomRequiredLabel label="License Fee Year 1" />
                </Grid>
                <OutlinedInput
                  required={isUpdate}
                  type="number"
                  fullWidth
                  value={leaseData?.licence_fee_1 || leaseData?.license_fee_year1 || ""}
                  name="licence_fee_1"
                  onChange={onChangeField}
                  inputProps={{ min: 0 }}
                  error={(errors && errors?.licence_fee_1) || false}
                  startAdornment="$"
                />
                {errors && errors?.licence_fee_1 && (
                  <CustomErrorMessage message={errors?.licence_fee_1} />
                )}
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <CustomRequiredLabel label="License Fee Year 2" />
                </Grid>
                <OutlinedInput
                  required={isUpdate}
                  type="number"
                  fullWidth
                  value={leaseData?.licence_fee_2 || leaseData?.license_fee_year2 || ""}
                  name="licence_fee_2"
                  onChange={onChangeField}
                  inputProps={{ min: 0 }}
                  error={(errors && errors?.licence_fee_2) || false}
                  startAdornment="$"
                />
                {errors && errors?.licence_fee_2 && (
                  <CustomErrorMessage message={errors?.licence_fee_2} />
                )}
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <FormControlLabel
                  control={
                    <Switch
                      name="charge_insurance"
                      checked={leaseData?.charge_insurance}
                      onChange={onChangeCheck}
                      inputProps={{ "aria-label": "controlled" }}
                    />
                  }
                  label={
                    <MDBox display="flex">
                      <MDTypography variant="subtitle2">
                        <strong>Charge Insurance</strong>
                      </MDTypography>
                      <MDTypography variant="subtitle2" ml={1}>
                        (after promos)
                      </MDTypography>
                    </MDBox>
                  }
                  labelPlacement="end"
                />
              </Grid>
              {leaseData?.charge_insurance && (
                <>
                  <Grid item xs={12} sm={9} md={6} lg={6}>
                    <FormControl fullWidth required={isUpdate} sx={{ paddingTop: "4px" }}>
                      {/* <InputLabel id="insurance_charge_frequency">
                        Insurance Charge Frequency
                      </InputLabel> */}
                      <CustomRequiredLabel label="Insurance Charge Frequency" />
                      <Select
                        required={isUpdate}
                        className="height45"
                        error={errors && errors?.insurance_charge_frequency ? true : false}
                        name="insurance_charge_frequency"
                        value={
                          leaseData?.insurance_charge_frequency ||
                          leaseData?.insurance_change_frequency ||
                          ""
                        }
                        onChange={onChangeField}
                      >
                        <MenuItem value="weekly">Weekly</MenuItem>
                        <MenuItem value="annually">Annually</MenuItem>
                      </Select>
                      {errors && errors.insurance_charge_frequency && (
                        <CustomErrorMessage message={errors.insurance_charge_frequency} />
                      )}
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={12} md={6} lg={6}>
                    <Grid item xs={12} sm={12} md={12} lg={12}>
                      <CustomRequiredLabel label="Insurance Fee" />
                    </Grid>
                    <OutlinedInput
                      required={isUpdate}
                      type="number"
                      fullWidth
                      value={leaseData?.insurance_fee || ""}
                      name="insurance_fee"
                      onChange={onChangeField}
                      inputProps={{ min: 0 }}
                      error={(errors && errors?.insurance_fee) || false}
                      startAdornment="$"
                    />
                    {errors && errors?.insurance_fee && (
                      <CustomErrorMessage message={errors?.insurance_fee} />
                    )}
                  </Grid>
                </>
              )}
            </Grid>
          </MDBox>
          <MDBox p={2}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <MDTypography variant="h5" mt={4} mb={2}>
                  Add Move-In Incentives
                </MDTypography>
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <MDInput
                  required={isUpdate}
                  fullWidth
                  type="number"
                  label="Weeks Free Rent"
                  variant="outlined"
                  inputProps={{ min: 0 }}
                  name="weeks_free_rent"
                  placeholder="Weeks Free Rent"
                  onChange={onChangeField}
                  value={leaseData?.weeks_free_rent || ""}
                  error={(errors && errors?.weeks_free_rent) || false}
                  helperText={errors && errors?.weeks_free_rent}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <MDInput
                  required={isUpdate}
                  fullWidth
                  type="number"
                  label="Months Free Insurance"
                  variant="outlined"
                  inputProps={{ min: 0 }}
                  name="months_free_insurance"
                  placeholder="Months Free Insurance"
                  onChange={onChangeField}
                  value={leaseData?.months_free_insurance || ""}
                  error={(errors && errors?.months_free_insurance) || false}
                  helperText={errors && errors?.months_free_insurance}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <FormControl component="fieldset">
                  <FormGroup aria-label="position" row>
                    <FormControlLabel
                      value="free_paint"
                      control={
                        <Checkbox
                          checked={leaseData?.free_paint || false}
                          name="free_paint"
                          onChange={onChangeCheck}
                          inputProps={{ "aria-label": "controlled" }}
                        />
                      }
                      label="Free Paint"
                      labelPlacement="end"
                    />
                  </FormGroup>
                </FormControl>
              </Grid>
            </Grid>
          </MDBox>
          <MDBox p={2}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <MDTypography variant="h5" mt={4} mb={2}>
                  Lease Dates
                </MDTypography>
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6} sx={{ marginTop: "20px" }}>
                <FormControl fullWidth required={isUpdate}>
                  <InputLabel id="lease_term">Lease Term</InputLabel>
                  <Select
                    required={isUpdate}
                    label="lease_term"
                    className="height45"
                    error={errors && errors?.lease_term ? true : false}
                    name="lease_term"
                    value={leaseData?.lease_term || ""}
                    onChange={onChangeField}
                  >
                    <MenuItem value="MTM">MTM</MenuItem>
                    <MenuItem value="6">6 Month</MenuItem>
                    <MenuItem value="12">12 Month</MenuItem>
                    <MenuItem value="18">18 Month</MenuItem>
                    <MenuItem value="24">24 Month</MenuItem>
                  </Select>
                  {errors && errors.lease_term && (
                    <CustomErrorMessage message={errors.lease_term} />
                  )}
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <FormControl fullWidth>
                  {isUpdate ? (
                    <CustomRequiredLabel label="Move In Date" />
                  ) : (
                    <MDTypography variant="caption">Move In Date</MDTypography>
                  )}
                  <MDDatePicker
                    name="move_in_date"
                    onChange={(value: any) => onChangeMoveinDate(value, "move_in_date")}
                    options={{
                      enableTime: false,
                      dateFormat: "m/d/Y",
                      time_24hr: true,
                      minDate: new Date(),
                    }}
                    value={leaseData?.move_in_date}
                  />
                  {errors && errors.move_in_date && (
                    <CustomErrorMessage message={errors.move_in_date} />
                  )}
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={12} md={6}>
                <FormControl fullWidth>
                  {isUpdate ? (
                    <CustomRequiredLabel label="Lease Sign" />
                  ) : (
                    <MDTypography variant="caption">Lease Sign</MDTypography>
                  )}
                  <MDDatePicker
                    name="lease_sign"
                    onChange={(value: any) => onChangeDate(value, "lease_sign")}
                    options={{
                      enableTime: false,
                      dateFormat: "m/d/Y",
                      time_24hr: true,
                    }}
                    value={leaseData?.lease_sign || new Date()}
                  />
                  {errors && errors.lease_sign && (
                    <CustomErrorMessage message={errors.lease_sign} />
                  )}
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={12} md={6}>
                <FormControl fullWidth>
                  {isUpdate ? (
                    <CustomRequiredLabel label="Lease Start" />
                  ) : (
                    <MDTypography variant="caption">Lease Start</MDTypography>
                  )}
                  <MDDatePicker
                    name="lease_start"
                    onChange={(value: any) => onChangeDate(value, "lease_start")}
                    options={{
                      enableTime: false,
                      dateFormat: "m/d/Y",
                      time_24hr: true,
                    }}
                    inputProps={{ readOnly: true }}
                    value={leaseData?.lease_start}
                  />
                  {errors && errors.lease_start && (
                    <CustomErrorMessage message={errors.lease_start} />
                  )}
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={12} md={6}>
                <FormControl fullWidth>
                  {isUpdate ? (
                    <CustomRequiredLabel label="Lease End" />
                  ) : (
                    <MDTypography variant="caption">Lease End</MDTypography>
                  )}
                  <MDDatePicker
                    name="lease_end"
                    onChange={(value: any) => onChangeDate(value, "lease_end")}
                    options={{
                      enableTime: false,
                      dateFormat: "m/d/Y",
                      time_24hr: true,
                    }}
                    input={{
                      readOnly: true,
                      disabled: true,
                    }}
                    value={leaseData?.lease_end}
                  />
                  {errors && errors.lease_end && <CustomErrorMessage message={errors.lease_end} />}
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={12} md={6}>
                <FormControl fullWidth>
                  {isUpdate ? (
                    <CustomRequiredLabel label="First Payment Date" />
                  ) : (
                    <MDTypography variant="caption">First Payment Date</MDTypography>
                  )}
                  <MDDatePicker
                    name="first_payment_date"
                    onChange={(value: any) => onChangeDate(value, "first_payment_date")}
                    options={{
                      enableTime: false,
                      dateFormat: "m/d/Y",
                      time_24hr: true,
                    }}
                    value={leaseData?.first_payment_date}
                    input={{
                      readOnly: true,
                      disabled: true,
                    }}
                  />
                  {errors && errors.first_payment_date && (
                    <CustomErrorMessage message={errors.first_payment_date} />
                  )}
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={12} md={6}>
                <FormControl fullWidth>
                  {isUpdate ? (
                    <CustomRequiredLabel label="Insurance Start" />
                  ) : (
                    <MDTypography variant="caption">Insurance Start</MDTypography>
                  )}
                  <MDDatePicker
                    name="insurance_start"
                    onChange={(value: any) => onChangeDate(value, "insurance_start")}
                    options={{
                      enableTime: false,
                      dateFormat: "m/d/Y",
                      time_24hr: true,
                    }}
                    input={{
                      readOnly: true,
                      disabled: true,
                    }}
                    value={leaseData?.insurance_start}
                  />
                  {errors && errors.insurance_start && (
                    <CustomErrorMessage message={errors.insurance_start} />
                  )}
                </FormControl>
              </Grid>
              {/* <Grid item xs={12} sm={12} md={6}>
                <FormControl fullWidth>
                  {isUpdate ? (
                    <CustomRequiredLabel label="Insurance Anniversary" />
                  ) : (
                    <MDTypography variant="caption">Insurance Anniversary</MDTypography>
                  )}
                  <MDDatePicker
                    name="insurance_anniversary"
                    onChange={(value: any) => onChangeDate(value, "insurance_anniversary")}
                    options={{
                      enableTime: false,
                      dateFormat: "m/d/Y",
                      time_24hr: true,
                    }}
                    value={leaseData?.insurance_anniversary}
                  />
                  {errors && errors.insurance_anniversary && (
                    <CustomErrorMessage message={errors.insurance_anniversary} />
                  )}
                </FormControl>
              </Grid> */}
            </Grid>
          </MDBox>
          <MDBox p={2}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <MDInput
                  label="Notes"
                  fullWidth
                  multiline
                  rows={4}
                  InputLabelProps={{ shrink: true }}
                  variant="outlined"
                  name="note"
                  onChange={onChangeField}
                  value={leaseData?.note ? leaseData?.note : ""}
                />
              </Grid>
            </Grid>
          </MDBox>
        </MDBox>
      )}
    </MDDialog>
  );
}

export default StartLease;
