import MDBox from "components/MDBox";
import MDInput from "components/MDInput";
import MDTypography from "components/MDTypography";

function Step1(props: any): JSX.Element {
  const { onChangeField, answers, errors } = props;
  return (
    <>
      <MDBox mt={5}>
        <MDTypography className="helper-info-texts" variant="body" fontWeight="bold">
          1. What is your full name?<span className="required_star">*</span>
        </MDTypography>
        <MDInput
          placeholder="Type your answer here..."
          variant="standard"
          className="colorWhite"
          fullWidth
          InputLabelProps={{ shrink: true }}
          name="full_name"
          required
          onChange={onChangeField}
          value={answers?.full_name || ""}
          error={errors && errors.full_name ? true : false}
          helperText={errors && errors.full_name}
        />
      </MDBox>
      <MDBox mt={2}>
        <MDTypography className="helper-info-texts" variant="body" fontWeight="bold">
          2. What is your email?<span className="required_star">*</span>
        </MDTypography>
        <MDInput
          label=""
          placeholder="Type your answer here..."
          variant="standard"
          className="colorWhite"
          fullWidth
          InputLabelProps={{ shrink: true }}
          name="email"
          required
          onChange={onChangeField}
          value={answers?.email || ""}
          error={errors && errors.email ? true : false}
          helperText={errors && errors.email}
        />
      </MDBox>
      <MDBox mt={2}>
        <MDTypography className="helper-info-texts" variant="body" fontWeight="bold">
          3. What is your best contact phone number?<span className="required_star">*</span>
        </MDTypography>
        <MDInput
          placeholder="Type your answer here..."
          variant="standard"
          className="colorWhite"
          fullWidth
          InputLabelProps={{ shrink: true }}
          name="phone_number"
          required
          onChange={onChangeField}
          value={answers?.phone_number || ""}
          error={errors && errors.phone_number ? true : false}
          helperText={errors && errors.phone_number}
        />
      </MDBox>
      <MDBox mt={2}>
        <MDTypography className="helper-info-texts" variant="body" fontWeight="bold">
          4. What is the business name of your franchise group/LLC?{" "}
          <span className="required_star">*</span>
        </MDTypography>
        <MDInput
          placeholder="Type your answer here..."
          variant="standard"
          className="colorWhite"
          fullWidth
          InputLabelProps={{ shrink: true }}
          name="business_name"
          required
          onChange={onChangeField}
          value={answers?.business_name || ""}
          error={errors && errors.business_name ? true : false}
          helperText={errors && errors.business_name}
        />
      </MDBox>
      <MDBox mt={2}>
        <MDTypography className="helper-info-texts" variant="body" fontWeight="bold">
          5. What is your Sola Location ID?<span className="required_star">*</span>
        </MDTypography>
        <MDTypography className="helper-info-texts" variant="span" paragraph>
          In order to find your location ID, please log-in to CMS, and click into your location you
          will find the location ID in the URL at the top of the screen, it will be the very last
          number. Example: https://www.solasalonstudios.com/admin/location/686
        </MDTypography>
        <MDTypography className="helper-info-texts" variant="span">
          Location ID must be a 3 or 4 Digit Number, if you do not have this please contact
          cindy.poletis@solasalonstudios.com
        </MDTypography>
        <MDInput
          placeholder="Type your answer here..."
          variant="standard"
          className="colorWhite"
          fullWidth
          InputLabelProps={{ shrink: true }}
          name="location_id"
          required
          onChange={onChangeField}
          value={answers?.location_id || ""}
          error={errors && errors.location_id ? true : false}
          helperText={errors && errors.location_id}
        />
      </MDBox>
    </>
  );
}

export default Step1;
