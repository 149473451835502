import { Card, Grid, Switch, FormControlLabel } from "@mui/material";

import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

import EmailSms from "../EmailSms";

function InitialResponse(props: any): JSX.Element {
  const { onChangeToggle, crmTabSettings, getAccessTypePermission } = props;

  return (
    <Card id="initial-response" sx={{ overflow: "visible" }}>
      <MDBox p={3}>
        <MDTypography variant="h5">Initial Response</MDTypography>
      </MDBox>
      <MDBox component="form" pb={3} px={3}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={12}>
            <FormControlLabel
              control={
                <Switch
                  checked={crmTabSettings?.initial_response ? true : false}
                  onChange={onChangeToggle}
                  inputProps={{ "aria-label": "controlled" }}
                  name="initial_response"
                  disabled={getAccessTypePermission("location_crm", "edit_access")}
                />
              }
              label="Enabled"
              labelPlacement="end"
            />
          </Grid>
          {crmTabSettings?.initial_response && (
            <EmailSms
              {...props}
              fieldName="initial_response"
              accessDisabled={getAccessTypePermission("location_crm", "edit_access")}
            />
          )}
        </Grid>
      </MDBox>
    </Card>
  );
}

export default InitialResponse;
