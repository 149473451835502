import { Box, Button } from "@mui/material";
import { setleaseApplicationSteps } from "store/slices/leaseSlice";
import { useAppDispatch, useAppSelector } from "store/store";

export default function EleaseButtons(props: any) {
  const { data, disabldBack = false, activeStep } = props;
  const { leaseApplicationSteps } = useAppSelector((state) => state.leaseSlice);
  const dispatch = useAppDispatch();
  let finalApplicants: any = [];

  // const handleSkip = () => {
  //   console.log("handleSkip ");
  // // };
  // console.log("leaseApplicationSteps back ", leaseApplicationSteps?.upload_doc_status);
  const handleBack = () => {
    dispatch(
      setleaseApplicationSteps({
        ...leaseApplicationSteps,
        page:
          leaseApplicationSteps?.upload_doc_status === false
            ? leaseApplicationSteps.page - 2
            : leaseApplicationSteps.page - 1,
        step: leaseApplicationSteps.page === 2 ? 2 : 0,
        applicants: leaseApplicationSteps?.applicants,
        locations: leaseApplicationSteps?.locations,
        studio: leaseApplicationSteps?.studio,
        security_deposit_amount: leaseApplicationSteps?.security_deposit_amount,
        security_deposit_amount_type: leaseApplicationSteps?.security_deposit_amount_type,
        rent_period: leaseApplicationSteps?.rent_period,
        lease_application_status: leaseApplicationSteps?.lease_application_status,
        rent_due_date: leaseApplicationSteps?.rent_due_date,
        security_deposite: leaseApplicationSteps?.security_deposite,
        lease_term: leaseApplicationSteps?.lease_term,
        insurance_charge_frequency: leaseApplicationSteps?.insurance_charge_frequency,
        insurance_fee: leaseApplicationSteps?.insurance_fee,
        weeks_free_rent: leaseApplicationSteps?.weeks_free_rent,
        months_free_insurance: leaseApplicationSteps?.months_free_insurance,
        free_paint: leaseApplicationSteps?.free_paint,
        leaseData: leaseApplicationSteps?.leaseData,
        applicant_documents: leaseApplicationSteps?.applicant_documents,
        upload_doc_status: leaseApplicationSteps?.upload_doc_status,
        security_deposit: leaseApplicationSteps?.security_deposit,
      })
    );
  };

  const handleNext = (e: any) => {
    if (data.applicants) {
      const temp = leaseApplicationSteps?.applicants.filter((applicant: any) => applicant.isSublet);
      const updateSubletObject = temp.map((applicant: any) => ({
        ...applicant,
      }));
      finalApplicants = [...data.applicants, ...updateSubletObject];
    }
    dispatch(
      setleaseApplicationSteps({
        ...leaseApplicationSteps,
        applicants: data?.applicants ? finalApplicants : leaseApplicationSteps?.applicants,
        locations: data?.locations ? data?.locations : leaseApplicationSteps?.locations,
        studio: leaseApplicationSteps?.studio,
        // page:
        //   leaseApplicationSteps?.page === 4
        //     ? leaseApplicationSteps?.page
        //     : leaseApplicationSteps.page + 1,
        page:
          e.target.name === "skip"
            ? leaseApplicationSteps.page + 2
            : leaseApplicationSteps.page + 1,
        // : leaseApplicationSteps?.page === 5
        // ? leaseApplicationSteps?.page + 1
        step: activeStep,
        security_deposit_amount: data?.security_deposit_amount
          ? data?.security_deposit_amount
          : leaseApplicationSteps?.security_deposit_amount,
        security_deposit_amount_type: data?.security_deposit_amount
          ? data?.security_deposit_amount_type
          : leaseApplicationSteps?.security_deposit_amount_type,
        rent_period: data?.rent_period ? data?.rent_period : leaseApplicationSteps?.rent_period,
        rent_due_date: data?.rent_due_date
          ? data?.rent_due_date
          : leaseApplicationSteps?.rent_due_date,
        security_deposite: data?.security_deposite
          ? data?.security_deposite
          : leaseApplicationSteps?.security_deposite,
        lease_term: data?.lease_term ? data?.lease_term : leaseApplicationSteps?.lease_term,
        insurance_charge_frequency: data?.insurance_charge_frequency
          ? data?.insurance_charge_frequency
          : leaseApplicationSteps?.insurance_charge_frequency,
        insurance_fee: data?.insurance_fees
          ? data?.insurance_fees
          : leaseApplicationSteps?.insurance_fee,
        months_free_insurance: data?.months_free_insurance
          ? data?.months_free_insurance
          : leaseApplicationSteps?.months_free_insurance,
        weeks_free_rent: data?.weeks_free_rent
          ? data?.weeks_free_rent
          : leaseApplicationSteps?.weeks_free_rent,
        free_paint: data?.free_paints ? data?.free_paints : leaseApplicationSteps?.free_paint,
        leaseData: data?.leaseMainData ? data?.leaseMainData : leaseApplicationSteps?.leaseData,
        applicant_documents:
          e.target.name === "skip" ? [] : data?.documents?.length ? data?.documents : [],
        lease_application_status: "application_sent",
        security_deposit: data?.securityDeposit ? data?.securityDeposit : true,
      })
    );

    // dispatch(setleaseApplicationSteps({ ...leaseApplicationSteps, applicants: data?.applicants }));
  };

  // console.log("leaseApplicationSteps after update", leaseApplicationSteps);
  return (
    <Box sx={{ mb: 2, display: "flex", alignItems: "center", justifyContent: "center" }}>
      {leaseApplicationSteps?.page === 4 ? (
        <>
          <Button
            disabled={data?.disabledNext}
            variant="contained"
            sx={{ mt: 1, mr: 1, color: "#fff" }}
            onClick={handleNext}
            name="upload"
          >
            Upload Document
          </Button>
          <Button
            disabled={data?.disabledNext}
            variant="outlined"
            onClick={handleNext}
            name="skip"
            sx={{
              mt: 1,
              mr: 1,
              color: "#1a73e8",
              "&:hover": {
                borderColor: "#1a73e8",
              },
            }}
          >
            SKip
          </Button>
        </>
      ) : leaseApplicationSteps?.page !== 6 ? (
        <Button
          disabled={data?.disabledNext}
          variant="contained"
          sx={{ mt: 1, mr: 1, color: "#fff" }}
          onClick={handleNext}
        >
          Continue
        </Button>
      ) : (
        ""
      )}
      <Button
        variant="outlined"
        onClick={handleBack}
        disabled={disabldBack}
        sx={{
          mt: 1,
          mr: 1,
          color: "#1a73e8",
          "&:hover": {
            borderColor: "#1a73e8",
          },
        }}
      >
        Back
      </Button>
      {leaseApplicationSteps?.page === 3 && (
        <Button
          disabled={
            leaseApplicationSteps?.paymentStatus && leaseApplicationSteps?.paymentStatus === "Paid"
          }
          variant="outlined"
          onClick={handleNext}
          sx={{
            mt: 1,
            mr: 1,
            color: "#1a73e8",
            "&:hover": {
              borderColor: "#1a73e8",
            },
          }}
        >
          Skip
        </Button>
      )}
    </Box>
  );
}
