import MDDialog from "components/MDDialog";
// import MDInput from "components/MDInput";
import MDBox from "components/MDBox";
import { getLocalDateTime } from "helper/services";
import { Grid } from "@mui/material";
import MDTypography from "components/MDTypography";

function ViewDetails(props: any): JSX.Element {
  const { open, onClose, updateData } = props;

  const dialogProps = {
    open,
    onClose,
    title: "View Testimonials",
    size: "md",
    saveBtn: false,
  };

  return (
    <MDDialog {...dialogProps}>
      <MDBox>
        <Grid container>
          <Grid item xs={12} sm={6} md={6} mt={2}>
            <MDTypography fontWeight="bold" variant="button">
              Name
            </MDTypography>
            <MDBox>
              <MDTypography variant="button">{updateData?.name || "-"}</MDTypography>
            </MDBox>
          </Grid>
          <Grid item xs={12} sm={6} md={6} mt={2}>
            <MDTypography fontWeight="bold" variant="button">
              Text
            </MDTypography>
            <MDBox>
              <MDTypography variant="button">{updateData?.text || "-"}</MDTypography>
            </MDBox>
          </Grid>
          <Grid item xs={12} sm={6} md={6} mt={2}>
            <MDTypography fontWeight="bold" variant="button">
              Region
            </MDTypography>
            <MDBox>
              <MDTypography variant="button">{updateData?.region || "-"}</MDTypography>
            </MDBox>
          </Grid>
          <Grid item xs={12} sm={6} md={6} mt={2}>
            <MDTypography fontWeight="bold" variant="button">
              Title
            </MDTypography>
            <MDBox>
              <MDTypography variant="button">{updateData?.title || "-"}</MDTypography>
            </MDBox>
          </Grid>
          <Grid item xs={12} sm={6} md={6} mt={2}>
            <MDTypography fontWeight="bold" variant="button">
              Created At
            </MDTypography>
            <MDBox>
              <MDTypography variant="button">
                {getLocalDateTime(updateData?.created_at)}
              </MDTypography>
            </MDBox>
          </Grid>
          <Grid item xs={12} sm={6} md={6} mt={2}>
            <MDTypography fontWeight="bold" variant="button">
              Updated At
            </MDTypography>
            <MDBox>
              <MDTypography variant="button">
                {getLocalDateTime(updateData?.updated_at)}
              </MDTypography>
            </MDBox>
          </Grid>
        </Grid>
      </MDBox>
    </MDDialog>
  );
}

export default ViewDetails;
