import { Card, Grid, Switch, FormControlLabel, Checkbox } from "@mui/material";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import CustomPhoneInput from "components/CustomPhoneInput";
import { adminSuperAdmin } from "helper/services";
import MailToLink from "components/CustomMailToLink";

function ContactInfo(props: any): JSX.Element {
  const {
    editPermission,
    onChangeField,
    setLocationDetail,
    locationDetail,
    errors,
    onCheckboxChange,
  } = props;

  const onChecked = (e: any) => {
    setLocationDetail({
      ...locationDetail,
      [`display_general_contact_info`]: e.target.checked,
    });
  };

  const getMDLabel = (label: any, value: any) => (
    <>
      <Grid item xs={12} sm={2}>
        <MDTypography variant="button" fontWeight="bold" textTransform="capitalize">
          {label}
        </MDTypography>
      </Grid>
      <Grid item xs={12} sm={10}>
        <MDTypography variant="button" fontWeight="regular" color="text">
          {value}
        </MDTypography>
      </Grid>
    </>
  );

  return (
    <Card id="contact-info" sx={{ overflow: "visible" }}>
      <MDBox p={3}>
        <MDTypography variant="h5">Contact Info</MDTypography>
      </MDBox>
      <MDBox component="form" pb={3} px={3}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={12}>
            <FormControlLabel
              control={
                <Switch
                  checked={locationDetail?.display_general_contact_info || false}
                  onChange={onChecked}
                  inputProps={{ "aria-label": "controlled" }}
                  disabled={!adminSuperAdmin()}
                />
              }
              label="Display General Contact Info?"
              labelPlacement="end"
            />
          </Grid>
          {!locationDetail?.display_general_contact_info && (
            <>
              <Grid item xs={12} sm={12}>
                <MDTypography variant="h6">Website Contact</MDTypography>
              </Grid>
              <Grid item xs={12} sm={6}>
                <MDInput
                  label="Website Contact Name"
                  placeholder="Website Contact Name"
                  fullWidth
                  variant="standard"
                  name="public_contact_name"
                  value={locationDetail?.public_contact_name || ""}
                  onChange={onChangeField}
                  disabled={!editPermission}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <MDInput
                  label="Website Phone Number"
                  variant="standard"
                  fullWidth
                  name="public_phone"
                  placeholder="Website Phone Number"
                  disabled={!adminSuperAdmin()}
                  onChange={onChangeField}
                  InputProps={{
                    inputComponent: CustomPhoneInput as any,
                  }}
                  value={locationDetail?.public_phone || ""}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <MDInput
                  label="Website Email"
                  placeholder="Website Email"
                  fullWidth
                  variant="standard"
                  name="public_email"
                  value={locationDetail?.public_email || ""}
                  onChange={onChangeField}
                  disabled={!adminSuperAdmin()}
                  error={errors && errors.public_email ? true : false}
                  helperText={errors && errors.public_email}
                />
              </Grid>
            </>
          )}
          {locationDetail?.display_general_contact_info && (
            <>
              {getMDLabel("First Name :", locationDetail?.general_contact_first_name || "-")}
              {getMDLabel("Last Name :", locationDetail?.general_contact_last_name || "-")}
              {getMDLabel("Phone :", locationDetail?.phone_number || "-")}
              {getMDLabel("Email :", locationDetail?.general_contact_email || "-")}
            </>
          )}
          <Grid item xs={12} sm={12}>
            <MDTypography fontSize={13}>
              Please contact{" "}
              <MailToLink
                label="support@solasalonstudios.com"
                mailto="mailto:support@solasalonstudios.com"
              />{" "}
              to change your phone number or email address. These are locked to ensure information
              is consistent and accurate on platforms like Google Maps and Apple Maps.
            </MDTypography>
          </Grid>
          <Grid item xs={12} sm={12}>
            <FormControlLabel
              value="commonly_used"
              control={
                <Checkbox
                  name="is_lease_detail_show"
                  inputProps={{ "aria-label": "controlled" }}
                  checked={locationDetail?.is_lease_detail_show}
                  onChange={onCheckboxChange}
                />
              }
              label="Show Contact Information Above Address"
              labelPlacement="end"
            />
            <Grid item xs={12} sm={12}>
              <MDTypography fontSize={13}>
                Your contact information will always show above by contact form. If you wish to
                display it on your location details page above your address too, please select this
                option.
              </MDTypography>
            </Grid>
          </Grid>
        </Grid>
      </MDBox>
    </Card>
  );
}

export default ContactInfo;
