import { Card, Grid } from "@mui/material";
import MDBox from "components/MDBox";
import MDInput from "components/MDInput";
import MDTypography from "components/MDTypography";
import CustomPhoneInput from "components/CustomPhoneInput";
import MailToLink from "components/CustomMailToLink";
import { isFranchisie } from "helper/services";

function ContactInfo(props: any): JSX.Element {
  const { editPermission, onChangeField, locationDetail, getAccessTypePermission, errors } = props;
  return (
    <Card id="contact-info" sx={{ overflow: "visible" }}>
      <MDBox p={3}>
        <MDTypography variant="h5">Contact Info</MDTypography>
      </MDBox>
      <MDBox component="form" pb={3} px={3}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={12}>
            <MDTypography variant="h6">General</MDTypography>
          </Grid>
          {/* <Grid item xs={12} sm={12}>
            <FormControlLabel
              control={
                <Switch
                  checked={locationDetail?.display_general_contact_info || false}
                  onChange={onChangeRadio}
                  inputProps={{ "aria-label": "controlled" }}
                  name="display_general_contact_info"
                />
              }
              label="Display General Contact Info?"
              labelPlacement="end"
              />
            </Grid> */}
          <Grid item xs={12} sm={6}>
            <MDInput
              label="First name"
              variant="standard"
              fullWidth
              name="general_contact_first_name"
              placeholder="First name"
              disabled={isFranchisie() ? editPermission : !editPermission}
              onChange={onChangeField}
              value={locationDetail?.general_contact_first_name || ""}
              // error={errors && errors.general_contact_first_name ? true : false}
              // helperText={errors && errors.general_contact_first_name}
              // required
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <MDInput
              label="Last name"
              variant="standard"
              fullWidth
              name="general_contact_last_name"
              placeholder="Last name"
              disabled={isFranchisie() ? editPermission : !editPermission}
              onChange={onChangeField}
              value={locationDetail?.general_contact_last_name || ""}
              // error={errors && errors.general_contact_last_name ? true : false}
              // helperText={errors && errors.general_contact_last_name}
              // required
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <MDInput
              label="Email"
              variant="standard"
              fullWidth
              name="general_contact_email"
              placeholder="Email"
              disabled={!editPermission}
              onChange={onChangeField}
              value={locationDetail?.general_contact_email || ""}
              error={errors && errors.general_contact_email ? true : false}
              helperText={errors && errors.general_contact_email}
              // required
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <MDInput
              label="Phone"
              variant="standard"
              fullWidth
              name="phone_number"
              placeholder="Phone"
              disabled={!editPermission}
              onChange={onChangeField}
              InputProps={{
                inputComponent: CustomPhoneInput as any,
              }}
              value={locationDetail?.phone_number || ""}
              // error={errors && errors.phone_number ? true : false}
              // helperText={errors && errors.phone_number}
              // required
            />
          </Grid>
        </Grid>
      </MDBox>
      <MDBox component="form" pb={3} px={3}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={12}>
            <MDTypography variant="h6">Other</MDTypography>
          </Grid>
          <Grid item xs={12} sm={6}>
            <MDInput
              label="Email For Website Inquiries"
              variant="standard"
              fullWidth
              name="email_address_for_inquiries"
              placeholder="Email For Website inquiries"
              onChange={onChangeField}
              value={locationDetail?.email_address_for_inquiries || ""}
              error={errors && errors.email_address_for_inquiries ? true : false}
              helperText={errors && errors.email_address_for_inquiries}
              // required
              disabled={getAccessTypePermission("location_general", "edit_access")}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <MDInput
              label="Email For Reports"
              variant="standard"
              fullWidth
              name="email_address_for_reports"
              placeholder="Email For Reports"
              onChange={onChangeField}
              value={locationDetail?.email_address_for_reports || ""}
              error={errors && errors.email_address_for_reports ? true : false}
              helperText={errors && errors.email_address_for_reports}
              // required
              disabled={getAccessTypePermission("location_general", "edit_access")}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <MDInput
              label="Email For HubSpot"
              variant="standard"
              fullWidth
              name="email_address_for_hubspot"
              placeholder="Email For HubSpot"
              onChange={onChangeField}
              value={locationDetail?.email_address_for_hubspot || ""}
              error={errors && errors.email_address_for_hubspot ? true : false}
              helperText={errors && errors.email_address_for_hubspot}
              // required
              disabled={getAccessTypePermission("location_general", "edit_access")}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <MDInput
              label="Email For Sola Professional Website Approval"
              variant="standard"
              fullWidth
              name="emails_for_stylist_website_approvals"
              placeholder="Email For Sola Professional Website Approval"
              onChange={onChangeField}
              value={locationDetail?.emails_for_stylist_website_approvals || ""}
              error={errors && errors.emails_for_stylist_website_approvals ? true : false}
              helperText={errors && errors.emails_for_stylist_website_approvals}
              // required
              disabled={getAccessTypePermission("location_general", "edit_access")}
            />
          </Grid>
          <Grid item xs={12} sm={12}>
            <MDTypography fontSize={13}>
              Please contact{" "}
              <MailToLink
                label="support@solasalonstudios.com"
                mailto="mailto:support@solasalonstudios.com"
              />{" "}
              to change your phone number or email address. These are locked to ensure information
              is consistent and accurate on platforms like Google Maps and Apple Maps.
            </MDTypography>
          </Grid>
        </Grid>
      </MDBox>
    </Card>
  );
}

export default ContactInfo;
