import { Card, Grid } from "@mui/material";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import EmailSms from "../EmailSms";

function ThankYouMessage(): JSX.Element {
  return (
    <Card id="thank-you-message" sx={{ overflow: "visible" }}>
      <MDBox p={3}>
        <MDTypography variant="h5">Thank You Message</MDTypography>
      </MDBox>
      <MDBox component="form" pb={3} px={3}>
        <Grid container spacing={3}>
          <EmailSms />
        </Grid>
      </MDBox>
    </Card>
  );
}

export default ThankYouMessage;
