import React, { useState, useEffect } from "react";
import MDDialog from "components/MDDialog";
import CloseIcon from "@mui/icons-material/Close";
import { ListItem, ListItemIcon, ListItemText } from "@mui/material";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import Validations from "helper/validations";
import { resetUserPassword } from "store/thunk/authThunk";
import { useAppDispatch, useAppSelector } from "store/store";
import Messages from "helper/messages";
import CustomPasswordInput from "components/CustomPasswordInput";

function ResetPassword(props: any): JSX.Element {
  const dispatch = useAppDispatch();
  const { open, onClose, resetPassword } = props;
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [email, setEmail] = useState("");
  const notificationInfo = useAppSelector((state) => state.notificationReducer);
  const [loading, setLoading] = useState<boolean>(false);
  const [passwordError, setPasswordError] = useState<any>({
    length: Messages.ERROR.PASSWORD_LENGTH,
    number: Messages.ERROR.PASSWORD_NUMBER,
    uppercase: Messages.ERROR.PASSWORD_UPPERCASE,
    lowercase: Messages.ERROR.PASSWORD_LOWERCASE,
    special: Messages.ERROR.PASSWORD_SPECIAL,
    confirm: Messages.ERROR.PASSWORD_CONFIRM,
  });

  const onSave = () => {
    setLoading(true);
    const requestData = {
      password,
      email,
    };
    dispatch(resetUserPassword(requestData));
  };

  const checkDisabled = () => {
    let disabled = false;
    if (Object.keys(passwordError).length) {
      disabled = true;
    }
    if (password !== confirmPassword) {
      disabled = true;
    }
    return disabled;
  };

  const onChangePassword = (password?: string) => {
    setPassword(password);
    const error = Validations.validatePassword(password);
    setPasswordError(error);
  };

  const onChangeConfirmPassword = (cnfpassword: string) => {
    setConfirmPassword(cnfpassword);
    const error = Validations.validatePassword(password, cnfpassword);
    setPasswordError(error);
  };

  useEffect(() => {
    if (notificationInfo.message) {
      setLoading(false);
      if (notificationInfo.status) {
        onClose();
      }
    }
  }, [notificationInfo]);

  useEffect(() => {
    if (resetPassword) {
      setEmail(resetPassword?.login_email ? resetPassword.login_email : "");
    }
  }, [resetPassword]);

  const dialogProps = {
    open,
    onClose,
    onSave,
    title: "Reset Password",
    saveTbtText: "Update",
    size: "sm",
    loading,
    disabledBtn: true,
    isDisabled: checkDisabled(),
    saveBtn: false,
  };

  return (
    <MDDialog {...dialogProps}>
      <CustomPasswordInput
        label="Password"
        name="password"
        required
        placeholder="Enter New Password"
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => onChangePassword(e.target.value)}
        value={password}
      />
      <MDBox mt={2} mb={2}>
        {passwordError && passwordError.length && (
          <ListItem>
            <ListItemIcon style={{ minWidth: "30px" }}>
              <CloseIcon color="error" />
            </ListItemIcon>
            <ListItemText
              primary={
                <MDTypography variant="subtitle2" style={{ fontSize: "14px" }}>
                  {Messages.ERROR.PASSWORD_LENGTH}
                </MDTypography>
              }
            />
          </ListItem>
        )}
        {passwordError && passwordError.number && (
          <ListItem>
            <ListItemIcon style={{ minWidth: "30px" }}>
              <CloseIcon color="error" />
            </ListItemIcon>
            <ListItemText
              primary={
                <MDTypography variant="subtitle2" style={{ fontSize: "14px" }}>
                  {Messages.ERROR.PASSWORD_NUMBER}
                </MDTypography>
              }
            />
          </ListItem>
        )}
        {passwordError && passwordError.uppercase && (
          <ListItem>
            <ListItemIcon style={{ minWidth: "30px" }}>
              <CloseIcon color="error" />
            </ListItemIcon>
            <ListItemText
              primary={
                <MDTypography variant="subtitle2" style={{ fontSize: "14px" }}>
                  {Messages.ERROR.PASSWORD_UPPERCASE}
                </MDTypography>
              }
            />
          </ListItem>
        )}
        {passwordError && passwordError.lowercase && (
          <ListItem>
            <ListItemIcon style={{ minWidth: "30px" }}>
              <CloseIcon color="error" />
            </ListItemIcon>
            <ListItemText
              primary={
                <MDTypography variant="subtitle2" style={{ fontSize: "14px" }}>
                  {Messages.ERROR.PASSWORD_LOWERCASE}
                </MDTypography>
              }
            />
          </ListItem>
        )}
        {passwordError && passwordError.special && (
          <ListItem>
            <ListItemIcon style={{ minWidth: "30px" }}>
              <CloseIcon color="error" />
            </ListItemIcon>
            <ListItemText
              primary={
                <MDTypography variant="subtitle2" style={{ fontSize: "14px" }}>
                  {Messages.ERROR.PASSWORD_SPECIAL}
                </MDTypography>
              }
            />
          </ListItem>
        )}
      </MDBox>
      <CustomPasswordInput
        label="Confirm Password"
        name="confirmPassword"
        required
        placeholder="Confirm Password"
        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
          onChangeConfirmPassword(e.target.value)
        }
        value={confirmPassword}
      />
      <MDBox mt={2} mb={2}>
        {passwordError && passwordError.confirm && (
          <ListItem>
            <ListItemIcon style={{ minWidth: "30px" }}>
              <CloseIcon color="error" />
            </ListItemIcon>
            <ListItemText
              primary={
                <MDTypography variant="subtitle2" style={{ fontSize: "14px" }}>
                  {Messages.ERROR.PASSWORD_CONFIRM}
                </MDTypography>
              }
            />
          </ListItem>
        )}
      </MDBox>
    </MDDialog>
  );
}

export default ResetPassword;
