import {
  Grid,
  Icon,
  ImageListItem,
  Paper,
  SvgIcon,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from "@mui/material";
import MDBox from "components/MDBox";
import CustomDropZone from "components/CustomDropZone";
import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "store/store";
import EleaseButtons from "components/EleaseButtons";
import MDIconButton from "components/MDIconButton";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import { setleaseApplicationSteps } from "store/slices/leaseSlice";

function UploadDocument(props: any): JSX.Element {
  const { saveDraftData, setSaveDraftData, setRemoveDocs, setSubletApplicantError } = props;
  const { leaseApplicationSteps } = useAppSelector((state) => state.leaseSlice);
  const [leaseApplicants, setLeaseApplicants] = useState<any>([]);
  const [applicantsDocs, setApplicantsDocs] = useState<any>({});
  const dispatch = useAppDispatch();

  useEffect(() => {
    setLeaseApplicants(leaseApplicationSteps?.applicants);
    setApplicantsDocs(leaseApplicationSteps?.applicant_documents || []);
  }, [leaseApplicationSteps]);

  const onChangeImage = async (files: any, index: any) => {
    if (files[0]) {
      setSubletApplicantError(false);
      const file = files[0];
      const reader = new FileReader();
      reader.onload = function () {
        setApplicantsDocs((prevState: any) => {
          const updatedApplicants = [...prevState];
          const propName = `applicant_${index}`;
          const updatedApplicant = {
            ...updatedApplicants[index],
            [propName]: updatedApplicants?.[index]?.[propName]
              ? [...updatedApplicants[index][propName], file]
              : [file],
          };
          updatedApplicants[index] = updatedApplicant;
          setSaveDraftData({ ...saveDraftData, updatedApplicant });
          return updatedApplicants;
        });
      };

      reader.readAsDataURL(file);
    }
  };

  const onImageRemove = (appIndex: number, docIndex: number) => {
    const newDocs = [...applicantsDocs];
    const tempApplicants: any = [];
    // Store File Object same as it is in tempApplicants array and store that in the setApplicantsDocs state
    if (newDocs?.length) {
      for (let i = 0; i < newDocs.length; i++) {
        if (i === appIndex) {
          const tempDocs: any = [];
          if (newDocs?.[appIndex]?.[`applicant_${appIndex}`]?.length) {
            newDocs[appIndex][`applicant_${appIndex}`].map((obj: any, i: any) => {
              if (i !== docIndex) {
                tempDocs.push(obj);
              }
              return true;
            });
          }
          tempApplicants.push({ [`applicant_${appIndex}`]: tempDocs });
        } else {
          tempApplicants.push(newDocs[i]);
        }
      }
    }
    setApplicantsDocs(tempApplicants);
  };

  const getDocs = (email: any) => {
    const applicant = leaseApplicationSteps?.applicants?.find(
      (applicant: any) => applicant.email === email
    );
    return applicant?.documents?.length > 0 ? applicant?.documents : [];
  };

  const onDBImageRemove = (appIndex: number, docIndex: number, removeObj: any) => {
    setRemoveDocs((prevRemoveDocs: any) => {
      const keyRemove = `applicants_${appIndex}_doc`;
      const updatedRemoveDocs = { ...prevRemoveDocs };
      if (!updatedRemoveDocs[keyRemove]) {
        updatedRemoveDocs[keyRemove] = [];
      }
      updatedRemoveDocs[keyRemove].push(removeObj);
      // return updatedRemoveDocs;
      const result = Object.keys(updatedRemoveDocs).map((key) => {
        const obj: any = {};
        obj[key] = updatedRemoveDocs[key];
        return obj;
      });

      return result;
    });
    // Update reducer applicant documents
    const tempUpdatedApplicants = [...leaseApplicationSteps.applicants];
    if (tempUpdatedApplicants[appIndex] && tempUpdatedApplicants[appIndex]?.documents) {
      const tempDocsApplicant = tempUpdatedApplicants[appIndex].documents.filter(
        (_: any, index: any) => index !== docIndex
      );

      const updatedApplicants = tempUpdatedApplicants.map((applicant, index) => {
        if (index === appIndex) {
          return {
            ...applicant,
            documents: tempDocsApplicant,
          };
        }
        return applicant;
      });
      dispatch(
        setleaseApplicationSteps({
          ...leaseApplicationSteps,
          applicants: updatedApplicants,
        })
      );
    }
  };

  return (
    <MDBox className="security-deposit-box" sx={{ maxWidth: "auto", height: "100%" }}>
      <h3>Upload Documents</h3>
      <Grid
        item
        md={9}
        pt={1}
        style={{ height: "245px", overflow: "auto", maxWidth: "100% !important" }}
      >
        <TableContainer
          component={Paper}
          sx={{
            maxWidth: "700px",
            margin: "0 auto 30px",
            border: " 1px solid #ddd",
            maxHeight: "unset",
            minHeight: "unset",
            borderRadius: "0px !important",
          }}
        >
          <Table aria-label="simple table">
            <TableHead sx={{ display: "table-header-group !important" }}>
              <TableRow>
                <TableCell>Applicant</TableCell>
                <TableCell>Upload Document</TableCell>
              </TableRow>
            </TableHead>
            {leaseApplicants &&
              leaseApplicants?.map((items: any, index: any) => (
                <TableBody>
                  <TableRow sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                    <TableCell component="td" scope="row">
                      <TextField
                        value={`${items?.first_name} ${items?.last_name}` || ""}
                        id="standard-basic"
                        variant="standard"
                        disabled
                      />
                    </TableCell>
                    <TableCell component="td" scope="row">
                      <CustomDropZone
                        multipleUpload={false}
                        acceptedFiles={{
                          "application/pdf": [".pdf"],
                        }}
                        onDrop={(e: any) => onChangeImage(e, index)}
                      >
                        Upload Documents
                      </CustomDropZone>

                      {applicantsDocs &&
                        applicantsDocs[index]?.[`applicant_${index}`]?.length > 0 &&
                        applicantsDocs[index]?.[`applicant_${index}`]?.map(
                          (obj: any, docIndex: any) => (
                            <ImageListItem key={obj.name} sx={{ marginRight: "20px" }}>
                              <SvgIcon
                                component={PictureAsPdfIcon}
                                style={{ height: "30px", width: "30px", marginTop: "30px" }}
                              />
                              {/* brose */}
                              <MDIconButton
                                // tooltipName="Delete"
                                aria-label="Delete"
                                className="removeBtnClass"
                                sx={{
                                  position: "absolute",
                                  borderRadius: "50%",
                                  top: "8px",
                                  right: "-13px",
                                  background: "#fff !important",
                                  boxShadow: "0 1px 7px rgba(0, 0, 0, 0.5)",
                                  padding: "5px !important",
                                }}
                                onClick={() => onImageRemove(index, docIndex)}
                              >
                                <Icon
                                  fontSize="small"
                                  sx={{ padding: " 0px", fontSize: "13px !important" }}
                                >
                                  close
                                </Icon>
                              </MDIconButton>
                            </ImageListItem>
                          )
                        )}
                      {getDocs(items?.email)?.map((objURL: any, docIndex: any) => (
                        <ImageListItem sx={{ marginRight: "20px" }}>
                          {/* db */}
                          <a href={objURL} target="_blank" rel="noopener noreferrer">
                            <SvgIcon
                              component={PictureAsPdfIcon}
                              style={{
                                height: "35px",
                                width: "35px",
                                marginTop: "35px",
                              }}
                            />
                          </a>
                          <MDIconButton
                            aria-label="Delete"
                            className="removeBtnClass"
                            sx={{
                              position: "absolute",
                              borderRadius: "50%",
                              top: "8px",
                              right: "-13px",
                              background: "#fff !important",
                              boxShadow: "0 1px 7px rgba(0, 0, 0, 0.5)",
                              padding: "5px !important",
                            }}
                            onClick={() => onDBImageRemove(index, docIndex, objURL)}
                          >
                            <Icon
                              fontSize="small"
                              sx={{ padding: " 0px", fontSize: "13px !important" }}
                            >
                              close
                            </Icon>
                          </MDIconButton>
                        </ImageListItem>
                      ))}
                    </TableCell>
                  </TableRow>
                </TableBody>
              ))}
          </Table>
        </TableContainer>
      </Grid>

      {/* {console.log("dbApplicantsDocs ", dbApplicantsDocs)} */}
      <EleaseButtons data={{ documents: applicantsDocs }} />
    </MDBox>
  );
}

export default UploadDocument;
