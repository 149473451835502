import { Container, Icon } from "@mui/material";
import { Link } from "react-router-dom";
// Material Dashboard 2 PRO React TS components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import { useAppDispatch } from "store/store";
import { logout } from "store/thunk/authThunk";
import MDIconButton from "components/MDIconButton";

function Index(props: any): JSX.Element {
  const { showHomeBtn } = props;

  const appDispatch = useAppDispatch();
  const onLogout = () => {
    appDispatch(logout());
  };

  return (
    <Container>
      <MDBox
        py={1}
        px={2}
        my={3}
        mx={3}
        width="calc(100% - 48px)"
        borderRadius="lg"
        color="dark"
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        position="absolute"
        left={0}
        zIndex={3}
        bgColor="white"
      >
        <MDBox display="flex" alignItems="center">
          {showHomeBtn && (
            <Link to="/home">
              <MDIconButton
                tooltipName="Home"
                size="small"
                aria-label="close"
                color="inherit"
                sx={{ marginBottom: "4px" }}
              >
                <Icon fontSize="small">home</Icon>
              </MDIconButton>
            </Link>
          )}
          <MDTypography variant="h6" fontWeight="bold" color="dark">
            Sola Connect
          </MDTypography>
        </MDBox>
        <MDBox>
          <MDButton variant="gradient" color="info" size="small" onClick={onLogout}>
            Logout
          </MDButton>
        </MDBox>
      </MDBox>
    </Container>
  );
}

export default Index;
