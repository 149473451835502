import { createAsyncThunk } from "@reduxjs/toolkit";
import { notificationFail, notificationSuccess } from "store/slices/notificationSlice";
import { setLoading } from "store/slices/authSlice";
import Messages from "helper/messages";
import {
  allBrandsList,
  getBrandsDetails,
  homeButtonsList,
  productInformationList,
  setClassImagesList,
  setDealsList,
  setHomeHeroImages,
  setStateRegions,
  setSideMenuList,
  setSolaClassRegion,
  setStates,
  educationHeroImagesList,
  setRegionsList,
  setVideos,
  setVideo,
  setToolList,
  setNotificationList,
  setEventsAndClassesList,
} from "store/slices/mobileAppSlice";
import { getErrorMessage, getFilterURL, getLocationsFilter, getValue } from "helper/services";
import apiClient from "config/apiClient";

/**
 * Get Brands list
 */
export const getAllBrands = createAsyncThunk(
  "getAllBrands",
  async (_request: any, { dispatch }) => {
    dispatch(setLoading(true));
    const page = _request.pageIndex || 0;
    const size = _request.pageSize || 50;
    const search = _request.search || "";
    const all = _request.all || "";
    const sortBy = _request.sort || [];
    const filterBy = _request.filterData || [];
    try {
      let url = `brands?page=${page + 1}&per_page=${size}`;
      if (search) {
        url += `&search=${search}`;
      }
      if (all) {
        url = `brands?all=${all}`;
      }
      if (sortBy?.length) {
        url += `&field=${sortBy[0]?.id}&order_by=${sortBy[0]?.desc ? "desc" : "asc"}`;
      }
      // Apply advance filter
      if (filterBy && filterBy.length) {
        url = getFilterURL(url, filterBy);
      }
      const response = await apiClient().get(url);
      if (response) {
        dispatch(setLoading(false));
        dispatch(allBrandsList(response?.data || []));
      }
    } catch (error: any) {
      dispatch(allBrandsList(null));
      dispatch(setLoading(false));
      dispatch(notificationFail(error.message || Messages.ERROR.SOMETHING_WENT_WRONG));
    }
  }
);

/**
 * Get Brand based on ID
 */
export const getBrand = createAsyncThunk("getBrand", async (_request: any, { dispatch }) => {
  dispatch(setLoading(true));
  try {
    const response = await apiClient().get(`brands/${_request.id}`);
    if (response) {
      dispatch(setLoading(false));
      dispatch(getBrandsDetails(response.data));
    } else {
      dispatch(notificationFail(response?.data?.message || Messages.ERROR.BRAND_DETAILS_ERROR));
    }
  } catch (error: any) {
    dispatch(setLoading(false));
    if (error?.response?.data?.error) {
      const message = getErrorMessage(error?.response?.data?.error);
      dispatch(notificationFail(message));
    } else {
      dispatch(
        notificationFail(error?.response?.data?.message || Messages.ERROR.BRAND_DETAILS_ERROR)
      );
    }
  }
});

/**
 * Create brand
 */
export const createBrand = createAsyncThunk("createBrand", async (_request: any, { dispatch }) => {
  dispatch(setLoading(true));
  const { brandsData, onClose, pageNo, perPage } = _request;
  const formData = new FormData();
  formData.append("brand[name]", brandsData.name);
  if (brandsData.website_url) {
    formData.append("brand[website_url]", brandsData.website_url);
  }
  if (brandsData.image) {
    formData.append("brand[image]", brandsData.image);
  }
  if (brandsData.links) {
    formData.append("brand[brand_link_ids]", brandsData.links);
  }
  if (brandsData.introduction_video_heading_title) {
    formData.append(
      "brand[introduction_video_heading_title]",
      brandsData.introduction_video_heading_title
    );
  }
  if (brandsData.events_and_classes_heading_title) {
    formData.append(
      "brand[events_and_classes_heading_title]",
      brandsData.events_and_classes_heading_title
    );
  }
  if (brandsData.countries) {
    brandsData.countries.forEach((val: any) => {
      formData.append("brand[country_ids][]", val);
    });
  }

  try {
    const response = await apiClient(true).post(`brands`, formData);
    if (response) {
      onClose();
      dispatch(notificationSuccess(Messages.SUCCESS.BRAND_CREATE_SUCCESS));
      dispatch(getAllBrands({ pageIndex: pageNo, pageSize: perPage }));
    } else {
      dispatch(notificationFail(response?.data?.message || Messages.ERROR.BRAND_CREATE_ERROR));
    }
  } catch (error: any) {
    dispatch(setLoading(false));
    if (error?.response?.data?.error) {
      const message = getErrorMessage(error?.response?.data?.error);
      dispatch(notificationFail(message));
    } else {
      dispatch(
        notificationFail(error?.response?.data?.message || Messages.ERROR.BRAND_CREATE_ERROR)
      );
    }
  }
});

/**
 * Update Brand data
 */
export const updateBrand = createAsyncThunk("updateBrand", async (_request: any, { dispatch }) => {
  dispatch(setLoading(true));
  const { brandsData, onClose, pageNo, perPage, search, sortBy } = _request;
  const formData = new FormData();
  formData.append("brand[name]", getValue(brandsData?.name));
  formData.append("brand[website_url]", getValue(brandsData?.website_url));
  formData.append("brand[brand_link_ids]", getValue(brandsData?.links));
  formData.append(
    "brand[introduction_video_heading_title]",
    getValue(brandsData?.introduction_video_heading_title)
  );
  formData.append(
    "brand[events_and_classes_heading_title]",
    getValue(brandsData?.events_and_classes_heading_title)
  );
  if (brandsData?.delete_image && brandsData?.delete_image === 1) {
    formData.append("brand[delete_image]", getValue(brandsData?.delete_image));
  } else {
    formData.append("brand[delete_image]", "");
  }
  formData.append("brand[image]", getValue(brandsData?.image));

  brandsData.countries.forEach((val: any) => {
    formData.append("brand[country_ids][]", val);
  });
  try {
    const response = await apiClient(true).put(`brands/${brandsData.id}`, formData);
    if (response) {
      dispatch(setLoading(false));
      onClose();
      dispatch(notificationSuccess(Messages.SUCCESS.BRAND_UPDATE_SUCCESS));
      dispatch(getAllBrands({ pageIndex: pageNo, pageSize: perPage, search, sort: sortBy }));
    } else {
      dispatch(notificationFail(response?.data?.message || Messages.ERROR.BRAND_UPDATE_ERROR));
    }
  } catch (error: any) {
    dispatch(setLoading(false));
    if (error?.response?.data?.error) {
      const message = getErrorMessage(error?.response?.data?.error);
      dispatch(notificationFail(message));
    } else {
      dispatch(
        notificationFail(error?.response?.data?.message || Messages.ERROR.BRAND_UPDATE_ERROR)
      );
    }
  }
});

/**
 * Delete Brand
 */
export const deleteBrand = createAsyncThunk("deleteBrand", async (_request: any, { dispatch }) => {
  dispatch(setLoading(true));
  const {
    id,
    setDeleteBrandId,
    pageNo,
    perPage,
    search,
    sortBy,
    removeSelectedItem = null,
  } = _request;
  try {
    const response = await apiClient().delete(`brands/${id}`);
    dispatch(setLoading(false));
    if (response) {
      setDeleteBrandId(false);
      if (removeSelectedItem) {
        removeSelectedItem(id);
      }
      dispatch(notificationSuccess(Messages.SUCCESS.BRAND_DELETE_SUCCESS));
      dispatch(getAllBrands({ pageIndex: pageNo, pageSize: perPage, search, sort: sortBy }));
    } else {
      dispatch(notificationFail(response?.data?.message || Messages.ERROR.BRAND_DELETE_ERROR));
    }
  } catch (error: any) {
    if (error?.response?.data?.error) {
      const message = getErrorMessage(error?.response?.data?.error);
      dispatch(notificationFail(message));
    } else {
      dispatch(
        notificationFail(error?.response?.data?.message || Messages.ERROR.BRAND_DELETE_ERROR)
      );
    }
  }
});

// get sola 50k images list
export const getHomeButtonsList = createAsyncThunk(
  "getHomeButtonsList",
  async (_request: any, { dispatch }) => {
    const page = _request.pageIndex || 0;
    const size = _request.pageSize || 50;
    const search = _request.search || "";
    const sortBy = _request.sort || [];
    const filterBy = _request.filterData || [];
    dispatch(setLoading(true));
    try {
      let url = `home_buttons?page=${page + 1}&per_page=${size}`;
      if (search) {
        url += `&search=${search}`;
      }
      if (sortBy?.length) {
        url += `&field=${sortBy[0]?.id}&order_by=${sortBy[0]?.desc ? "desc" : "asc"}`;
      }
      // Apply advance filter
      if (filterBy && filterBy.length) {
        url = getFilterURL(url, filterBy);
      }
      const response = await apiClient().get(url);
      if (response) {
        dispatch(homeButtonsList(response?.data || []));
        dispatch(setLoading(false));
      } else {
        dispatch(notificationFail(response?.data?.message || Messages.ERROR.GET_HOME_BUTTON_ERROR));
      }
    } catch (error: any) {
      dispatch(notificationFail(error.message || Messages.ERROR.NO_DATA_FOUND));
      dispatch(setLoading(false));
    }
  }
);

export const saveHomeButtons = createAsyncThunk(
  "saveHomeButtons",
  async (_request: any, { dispatch }) => {
    dispatch(setLoading(true));
    const { homeButtonData, onClose, isUpdate, pageNo, perPage, search, sortBy } = _request;
    const homeButtonRequest = new FormData();
    if (isUpdate && homeButtonData.id) {
      homeButtonRequest.append("home_button[id]", homeButtonData.id);
    }
    homeButtonRequest.append("home_button[action_link]", getValue(homeButtonData?.action_link));
    homeButtonRequest.append("home_button[position]", getValue(homeButtonData?.position));
    homeButtonData.country_name.forEach((val: any) => {
      homeButtonRequest.append("home_button[country_ids][]", val.id);
    });
    // homeButtonRequest.append("home_button[delete_image]", getValue(homeButtonData?.delete_image));
    homeButtonRequest.append("home_button[image]", getValue(homeButtonData?.image_original_url));
    try {
      const response = isUpdate
        ? await apiClient(true).put(`home_buttons/${homeButtonData.id}`, homeButtonRequest)
        : await apiClient(true).post("home_buttons", homeButtonRequest);
      dispatch(setLoading(false));
      if (response) {
        onClose();
        if (isUpdate) {
          dispatch(
            notificationSuccess(
              response?.data?.message || Messages.SUCCESS.HOME_BUTTON_UPDATE_SUCCESS
            )
          );
          dispatch(
            getHomeButtonsList({ pageIndex: pageNo, pageSize: perPage, search, sort: sortBy })
          );
        } else {
          dispatch(
            notificationSuccess(
              response?.data?.message || Messages.SUCCESS.HOME_BUTTON_CREATE_SUCCESS
            )
          );
          dispatch(getHomeButtonsList({ pageIndex: pageNo, pageSize: perPage }));
        }
      } else {
        dispatch(
          notificationFail(response?.data?.message || Messages.ERROR.HOME_BUTTON_SAVE_ERROR)
        );
      }
    } catch (error: any) {
      dispatch(setLoading(false));
      if (error?.response?.data?.error) {
        const message = getErrorMessage(error?.response?.data?.error);
        dispatch(notificationFail(message));
      } else {
        dispatch(
          notificationFail(error?.response?.data?.message || Messages.ERROR.HOME_BUTTON_SAVE_ERROR)
        );
      }
    }
  }
);

export const deleteHomeButtonRecord = createAsyncThunk(
  "deleteMsa",
  async (_request: any, { dispatch }) => {
    dispatch(setLoading(true));
    const {
      homeButtonData,
      onCloseDeleteModal,
      pageNo,
      perPage,
      search,
      sortBy,
      removeSelectedItem = null,
    } = _request;
    try {
      const response = await apiClient(true).delete(`home_buttons/${homeButtonData.id}`);
      dispatch(setLoading(false));
      if (response) {
        onCloseDeleteModal();
        if (removeSelectedItem) {
          removeSelectedItem(homeButtonData.id);
        }
        dispatch(notificationSuccess(Messages.SUCCESS.HOME_BUTTON_DELETE_SUCCESS));
        dispatch(
          getHomeButtonsList({ pageIndex: pageNo, pageSize: perPage, search, sort: sortBy })
        );
      } else {
        dispatch(
          notificationFail(response?.data?.message || Messages.ERROR.HOME_BUTTON_DELETE_ERROR)
        );
      }
    } catch (error: any) {
      if (error?.response?.data?.error) {
        const message = getErrorMessage(error?.response?.data?.error);
        dispatch(notificationFail(message));
      } else {
        dispatch(
          notificationFail(
            error?.response?.data?.message || Messages.ERROR.HOME_BUTTON_DELETE_ERROR
          )
        );
      }
    }
  }
);

// get product information list
export const getProductInformationList = createAsyncThunk(
  "getProductInformationList",
  async (_request: any, { dispatch }) => {
    dispatch(setLoading(true));
    const page = _request.pageIndex || 0;
    const size = _request.pageSize || 50;
    const search = _request.search || "";
    const sortBy = _request.sort || [];
    const filterBy = _request.filterData || [];
    try {
      let url = `product_informations?page=${page + 1}&per_page=${size}`;
      if (search) {
        url += `&search=${search}`;
      }
      if (sortBy?.length) {
        url += `&field=${sortBy[0]?.id}&order_by=${sortBy[0]?.desc ? "desc" : "asc"}`;
      }
      // Apply advance filter
      if (filterBy && filterBy.length) {
        url = getFilterURL(url, filterBy);
      }
      const response = await apiClient().get(url);
      if (response) {
        dispatch(productInformationList(response?.data || []));
        dispatch(setLoading(false));
      } else {
        dispatch(notificationFail(response?.data?.message || Messages.ERROR.GET_HOME_BUTTON_ERROR));
      }
    } catch (error: any) {
      dispatch(notificationFail(error.message || Messages.ERROR.NO_DATA_FOUND));
      dispatch(setLoading(false));
    }
  }
);

export const saveProductInformation = createAsyncThunk(
  "saveProductInformation",
  async (_request: any, { dispatch }) => {
    dispatch(setLoading(true));
    const { productInfo, onClose, isUpdate, pageNo, perPage, search, sortBy } = _request;
    const productInfoRequest = new FormData();

    if (isUpdate && productInfo.id) {
      productInfoRequest.append("product_information[id]", productInfo.id);
    }
    productInfoRequest.append("product_information[title]", getValue(productInfo?.title));
    productInfoRequest.append(
      "product_information[description]",
      getValue(productInfo.description)
    );
    productInfoRequest.append("product_information[link_url]", getValue(productInfo.link_url));
    productInfoRequest.append("product_information[brand_id]", getValue(productInfo?.brand?.id));
    productInfoRequest.append("product_information[file]", getValue(productInfo?.file_url));
    if (productInfo?.delete_file && productInfo?.delete_file === 1) {
      productInfoRequest.append(
        "product_information[delete_file]",
        getValue(productInfo?.delete_file)
      );
    } else {
      productInfoRequest.append("product_information[delete_file]", "");
    }
    if (productInfo?.delete_image && productInfo?.delete_image === 1) {
      productInfoRequest.append(
        "product_information[delete_image]",
        getValue(productInfo?.delete_image)
      );
    } else {
      productInfoRequest.append("product_information[delete_image]", "");
    }
    productInfoRequest.append("product_information[image]", getValue(productInfo?.image_url));
    try {
      const response = isUpdate
        ? await apiClient(true).put(`product_informations/${productInfo.id}`, productInfoRequest)
        : await apiClient(true).post("product_informations", productInfoRequest);
      dispatch(setLoading(false));
      if (response) {
        onClose();
        if (isUpdate) {
          dispatch(
            notificationSuccess(
              response?.data?.message || Messages.SUCCESS.PRODUCT_INFORMATION_UPDATE_SUCCESS
            )
          );
          dispatch(
            getProductInformationList({
              pageIndex: pageNo,
              pageSize: perPage,
              search,
              sort: sortBy,
            })
          );
        } else {
          dispatch(
            notificationSuccess(
              response?.data?.message || Messages.SUCCESS.PRODUCT_INFORMATION_CREATE_SUCCESS
            )
          );
          dispatch(getProductInformationList({ pageIndex: pageNo, pageSize: perPage }));
        }
      } else {
        dispatch(
          notificationFail(response?.data?.message || Messages.ERROR.PRODUCT_INFORMATION_SAVE_ERROR)
        );
      }
    } catch (error: any) {
      dispatch(setLoading(false));
      if (error?.response?.data?.error) {
        const message = getErrorMessage(error?.response?.data?.error);
        dispatch(notificationFail(message));
      } else {
        dispatch(
          notificationFail(
            error?.response?.data?.message || Messages.ERROR.PRODUCT_INFORMATION_SAVE_ERROR
          )
        );
      }
    }
  }
);

export const deleteProductInformation = createAsyncThunk(
  "deleteMsa",
  async (_request: any, { dispatch }) => {
    dispatch(setLoading(true));
    const {
      productInformation,
      onCloseDeleteModal,
      pageNo,
      perPage,
      search,
      sortBy,
      removeSelectedItem = null,
    } = _request;
    try {
      const response = await apiClient(true).delete(
        `product_informations/${productInformation.id}`
      );
      dispatch(setLoading(false));
      if (response) {
        onCloseDeleteModal();
        if (removeSelectedItem) {
          removeSelectedItem(productInformation.id);
        }
        dispatch(notificationSuccess(Messages.SUCCESS.PRODUCT_INFORMATION_DELETE_SUCCESS));
        dispatch(
          getProductInformationList({ pageIndex: pageNo, pageSize: perPage, search, sort: sortBy })
        );
      } else {
        dispatch(
          notificationFail(
            response?.data?.message || Messages.ERROR.PRODUCT_INFORMATION_DELETE_ERROR
          )
        );
      }
    } catch (error: any) {
      if (error?.response?.data?.error) {
        const message = getErrorMessage(error?.response?.data?.error);
        dispatch(notificationFail(message));
      } else {
        dispatch(
          notificationFail(
            error?.response?.data?.message || Messages.ERROR.PRODUCT_INFORMATION_DELETE_ERROR
          )
        );
      }
    }
  }
);

/**
 * Get all class images list
 */
export const getClassImagesList = createAsyncThunk(
  "getClassImagesList",
  async (_request: any, { dispatch }) => {
    const page = _request.pageIndex || 0;
    const size = _request.pageSize || 50;
    const search = _request.search || "";
    const sortBy = _request.sort || [];
    const filterBy = _request.filterData || [];
    dispatch(setLoading(true));
    try {
      let url = `class_images?page=${page + 1}&per_page=${size}`;
      if (search) {
        url += `&search=${search}`;
      }
      if (sortBy?.length) {
        url += `&field=${sortBy[0]?.id}&order_by=${sortBy[0]?.desc ? "desc" : "asc"}`;
      }
      // Apply advance filter
      if (filterBy && filterBy.length) {
        url = getFilterURL(url, filterBy);
      }
      const response = await apiClient().get(url);
      if (response) {
        dispatch(setLoading(false));
        dispatch(setClassImagesList(response?.data || []));
      }
    } catch (error: any) {
      dispatch(setLoading(false));
      dispatch(notificationFail(error.message || Messages.ERROR.NO_DATA_FOUND));
    }
  }
);

/**
 * Create Class Image
 */
export const saveClassImage = createAsyncThunk(
  "saveClassImage",
  async (_request: any, { dispatch }) => {
    dispatch(setLoading(true));
    const { classImagesData, onClose, isUpdate, pageNo, perPage, search, sortBy } = _request;
    const formData = new FormData();
    formData.append("class_image[name]", getValue(classImagesData?.name));
    formData.append("class_image[image]", getValue(classImagesData?.image));
    if (classImagesData?.delete_image && classImagesData?.delete_image === 1) {
      formData.append("class_image[delete_image]", getValue(classImagesData?.delete_image));
    } else {
      formData.append("class_image[delete_image]", "");
    }
    if (classImagesData?.delete_thumbnail && classImagesData?.delete_thumbnail === 1) {
      formData.append("class_image[delete_thumbnail]", getValue(classImagesData.delete_thumbnail));
    } else {
      formData.append("class_image[delete_thumbnail]", "");
    }
    formData.append("class_image[thumbnail]", getValue(classImagesData?.thumbnail));
    try {
      const response = isUpdate
        ? await apiClient(true).put(`class_images/${classImagesData.id}`, formData)
        : await apiClient(true).post("class_images", formData);
      if (response) {
        onClose();
        dispatch(notificationSuccess(Messages.SUCCESS.CLASS_IMAGE_CREATE_SUCCESS));
        if (isUpdate) {
          dispatch(
            getClassImagesList({ pageIndex: pageNo, pageSize: perPage, search, sort: sortBy })
          );
        } else {
          dispatch(getClassImagesList({ pageIndex: pageNo, pageSize: perPage }));
        }
      } else {
        dispatch(
          notificationFail(response?.data?.message || Messages.ERROR.CLASS_IMAGE_CREATE_ERROR)
        );
      }
    } catch (error: any) {
      dispatch(setLoading(false));
      if (error?.response?.data?.error) {
        const message = getErrorMessage(error?.response?.data?.error);
        dispatch(notificationFail(message));
      } else {
        dispatch(
          notificationFail(
            error?.response?.data?.message || Messages.ERROR.CLASS_IMAGE_CREATE_ERROR
          )
        );
      }
    }
  }
);

/**
 * Delete Class Image
 */
export const deleteClassImage = createAsyncThunk(
  "deleteClassImage",
  async (_request: any, { dispatch }) => {
    dispatch(setLoading(true));
    const {
      classImagesData,
      onCloseDeleteModal,
      pageNo,
      perPage,
      search,
      sortBy,
      removeSelectedItem = null,
    } = _request;
    try {
      const response = await apiClient().delete(`class_images/${classImagesData.id}`);
      dispatch(setLoading(false));
      if (response) {
        onCloseDeleteModal();
        if (removeSelectedItem) {
          removeSelectedItem(classImagesData.id);
        }
        dispatch(notificationSuccess(Messages.SUCCESS.CLASS_IMAGE_DELETE_SUCCESS));
        dispatch(
          getClassImagesList({ pageIndex: pageNo, pageSize: perPage, search, sort: sortBy })
        );
      } else {
        dispatch(
          notificationFail(response?.data?.message || Messages.ERROR.CLASS_IMAGE_DELETE_ERROR)
        );
      }
    } catch (error: any) {
      if (error?.response?.data?.error) {
        const message = getErrorMessage(error?.response?.data?.error);
        dispatch(notificationFail(message));
      } else {
        dispatch(
          notificationFail(
            error?.response?.data?.message || Messages.ERROR.CLASS_IMAGE_DELETE_ERROR
          )
        );
      }
    }
  }
);

/**
 * Get Home Hero Images list
 */
export const getHomeHeroImages = createAsyncThunk(
  "getHomeHeroImages",
  async (_request: any, { dispatch }) => {
    const page = _request.pageIndex || 0;
    const size = _request.pageSize || 50;
    const search = _request.search || "";
    const sortBy = _request.sort || [];
    const filterBy = _request.filterData || [];
    dispatch(setLoading(true));
    try {
      let url = `home_hero_images?page=${page + 1}&per_page=${size}`;
      if (search) {
        url += `&search=${search}`;
      }
      if (sortBy?.length) {
        url += `&field=${sortBy[0]?.id}&order_by=${sortBy[0]?.desc ? "desc" : "asc"}`;
      }
      // Apply advance filter
      if (filterBy && filterBy.length) {
        url = getFilterURL(url, filterBy);
      }
      const response = await apiClient().get(url);
      if (response) {
        dispatch(setLoading(false));
        dispatch(setHomeHeroImages(response?.data || []));
      }
    } catch (error: any) {
      dispatch(setLoading(false));
      dispatch(notificationFail(error.message || Messages.ERROR.NO_DATA_FOUND));
    }
  }
);

/**
 * Create Class Image
 */
export const saveHomeHeroImages = createAsyncThunk(
  "saveHomeHeroImages",
  async (_request: any, { dispatch }) => {
    dispatch(setLoading(true));
    const { homeHeroImagesData, onClose, isUpdate, pageNo, perPage, search, sortBy } = _request;
    const formData = new FormData();

    if (isUpdate && homeHeroImagesData.id) {
      formData.append("home_hero_image[id]", homeHeroImagesData.id);
    }
    formData.append("home_hero_image[action_link]", getValue(homeHeroImagesData.action_link));
    formData.append("home_hero_image[position]", getValue(homeHeroImagesData.position));
    homeHeroImagesData.countries.forEach((val: any) => {
      formData.append("home_hero_image[country_ids][]", val.id);
    });
    if (homeHeroImagesData?.delete_image && homeHeroImagesData?.delete_image === 1) {
      formData.append("home_hero_image[delete_image]", getValue(homeHeroImagesData?.delete_image));
    } else {
      formData.append("home_hero_image[delete_image]", "");
    }
    formData.append("home_hero_image[image]", getValue(homeHeroImagesData?.image_original_url));
    try {
      const response = isUpdate
        ? await apiClient(true).put(`home_hero_images/${homeHeroImagesData.id}`, formData)
        : await apiClient(true).post("home_hero_images", formData);
      if (response) {
        onClose();
        dispatch(notificationSuccess(Messages.SUCCESS.HOME_HERO_IMAGE_CREATE_SUCCESS));
        if (isUpdate) {
          dispatch(
            getHomeHeroImages({ pageIndex: pageNo, pageSize: perPage, search, sort: sortBy })
          );
        } else {
          dispatch(getHomeHeroImages({ pageIndex: pageNo, pageSize: perPage }));
        }
      } else {
        dispatch(
          notificationFail(response?.data?.message || Messages.ERROR.HOME_HERO_IMAGE_SAVE_ERROR)
        );
      }
    } catch (error: any) {
      dispatch(setLoading(false));
      if (error?.response?.data?.error) {
        const message = getErrorMessage(error?.response?.data?.error);
        dispatch(notificationFail(message));
      } else {
        dispatch(
          notificationFail(
            error?.response?.data?.message || Messages.ERROR.HOME_HERO_IMAGE_SAVE_ERROR
          )
        );
      }
    }
  }
);

/**
 * Delete Home hero Image
 */
export const deleteHomeHeroImage = createAsyncThunk(
  "deleteHomeHeroImage",
  async (_request: any, { dispatch }) => {
    dispatch(setLoading(true));
    const {
      homeHeroImagesData,
      onCloseDeleteModal,
      pageNo,
      perPage,
      search,
      sortBy,
      removeSelectedItem = null,
    } = _request;
    try {
      const response = await apiClient().delete(`home_hero_images/${homeHeroImagesData.id}`);
      dispatch(setLoading(false));
      if (response) {
        onCloseDeleteModal();
        if (removeSelectedItem) {
          removeSelectedItem(homeHeroImagesData.id);
        }
        dispatch(notificationSuccess(Messages.SUCCESS.HOME_HERO_IMAGE_DELETE_SUCCESS));
        dispatch(getHomeHeroImages({ pageIndex: pageNo, pageSize: perPage, search, sort: sortBy }));
      } else {
        dispatch(
          notificationFail(response?.data?.message || Messages.ERROR.HOME_HERO_IMAGE_DELETE_ERROR)
        );
      }
    } catch (error: any) {
      if (error?.response?.data?.error) {
        const message = getErrorMessage(error?.response?.data?.error);
        dispatch(notificationFail(message));
      } else {
        dispatch(
          notificationFail(
            error?.response?.data?.message || Messages.ERROR.HOME_HERO_IMAGE_DELETE_ERROR
          )
        );
      }
    }
  }
);

/**
 * Get list of side menu items
 */
export const getSideMenuList = createAsyncThunk(
  "getSideMenuList",
  async (_request: any, { dispatch }) => {
    const page = _request.pageIndex || 0;
    const size = _request.pageSize || 50;
    const search = _request.search || "";
    const sortBy = _request.sort || [];
    const filterBy = _request.filterData || [];
    dispatch(setLoading(true));
    try {
      let url = `side_menu_items?page=${page + 1}&per_page=${size}`;
      if (search) {
        url += `&search=${search}`;
      }
      if (sortBy?.length) {
        url += `&field=${sortBy[0]?.id}&order_by=${sortBy[0]?.desc ? "desc" : "asc"}`;
      }
      // Apply advance filter
      if (filterBy && filterBy.length) {
        url = getFilterURL(url, filterBy);
      }
      const response = await apiClient().get(url);
      if (response) {
        dispatch(setSideMenuList(response?.data || []));
        dispatch(setLoading(false));
      } else {
        dispatch(notificationFail(response?.data?.message || Messages.ERROR.GET_HOME_BUTTON_ERROR));
      }
    } catch (error: any) {
      dispatch(notificationFail(error.message || Messages.ERROR.NO_DATA_FOUND));
      dispatch(setLoading(false));
    }
  }
);

/**
 * Delete side menu record
 */
export const deleteSideMenuRecord = createAsyncThunk(
  "deleteSideMenuRecord",
  async (_request: any, { dispatch }) => {
    dispatch(setLoading(true));
    const {
      sideMenuData,
      onCloseDeleteModal,
      pageNo,
      perPage,
      search,
      sortBy,
      removeSelectedItem = null,
    } = _request;
    try {
      const response = await apiClient(true).delete(`side_menu_items/${sideMenuData.id}`);
      dispatch(setLoading(false));
      if (response) {
        onCloseDeleteModal();
        if (removeSelectedItem) {
          removeSelectedItem(sideMenuData.id);
        }
        dispatch(notificationSuccess(Messages.SUCCESS.SIDE_MENU_DELETE_SUCCESS));
        dispatch(getSideMenuList({ pageIndex: pageNo, pageSize: perPage, search, sort: sortBy }));
      } else {
        dispatch(
          notificationFail(response?.data?.message || Messages.ERROR.SIDE_MENU_DELETE_ERROR)
        );
      }
    } catch (error: any) {
      if (error?.response?.data?.error) {
        const message = getErrorMessage(error?.response?.data?.error);
        dispatch(notificationFail(message));
      } else {
        dispatch(
          notificationFail(error?.response?.data?.message || Messages.ERROR.SIDE_MENU_DELETE_ERROR)
        );
      }
    }
  }
);

/**
 * Save side menu items
 */
export const saveSideMenuItems = createAsyncThunk(
  "saveSideMenuItems",
  async (_request: any, { dispatch }) => {
    dispatch(setLoading(true));
    const { sideMenuData, onClose, isUpdate, pageNo, perPage, search, sortBy } = _request;

    try {
      const response = isUpdate
        ? await apiClient().put(`side_menu_items/${sideMenuData.id}`, {
            side_menu_item: sideMenuData,
          })
        : await apiClient().post("side_menu_items", {
            side_menu_item: sideMenuData,
          });
      dispatch(setLoading(false));
      if (response) {
        onClose();
        if (isUpdate) {
          dispatch(notificationSuccess(Messages.SUCCESS.SIDE_MENU_UPDATE_SUCCESS));
          dispatch(getSideMenuList({ pageIndex: pageNo, pageSize: perPage, search, sort: sortBy }));
        } else {
          dispatch(notificationSuccess(Messages.SUCCESS.SIDE_MENU_CREATE_SUCCESS));
          dispatch(getSideMenuList({ pageIndex: pageNo, pageSize: perPage }));
        }
      } else {
        dispatch(notificationFail(response?.data?.message || Messages.ERROR.SIDE_MENU_SAVE_ERROR));
      }
    } catch (error: any) {
      dispatch(setLoading(false));
      if (error?.response?.data?.error) {
        const message = getErrorMessage(error?.response?.data?.error);
        dispatch(notificationFail(message));
      } else {
        dispatch(
          notificationFail(error?.response?.data?.message || Messages.ERROR.SIDE_MENU_SAVE_ERROR)
        );
      }
    }
  }
);

/**
 * Get list of mobile app/deals
 */
export const getDealsList = createAsyncThunk(
  "getDealsList",
  async (_request: any, { dispatch }) => {
    const page = _request.pageIndex || 0;
    const size = _request.pageSize || 50;
    const search = _request.search || "";
    const sortBy = _request.sort || [];
    const filterBy = _request.filterData || [];
    dispatch(setLoading(true));
    try {
      let url = `deals?page=${page + 1}&per_page=${size}`;
      if (search) {
        url += `&search=${search}`;
      }
      if (sortBy?.length) {
        url += `&field=${sortBy[0]?.id}&order_by=${sortBy[0]?.desc ? "desc" : "asc"}`;
      }
      // Apply advance filter
      if (filterBy && filterBy.length) {
        url = getFilterURL(url, filterBy);
      }
      const response = await apiClient().get(url);
      if (response) {
        dispatch(setDealsList(response?.data || []));
        dispatch(setLoading(false));
      } else {
        dispatch(notificationFail(response?.data?.message || Messages.ERROR.GET_HOME_BUTTON_ERROR));
      }
    } catch (error: any) {
      dispatch(notificationFail(error.message || Messages.ERROR.NO_DATA_FOUND));
      dispatch(setLoading(false));
    }
  }
);

export const deleteDealsRecord = createAsyncThunk(
  "deleteDealsRecord",
  async (_request: any, { dispatch }) => {
    dispatch(setLoading(true));
    const {
      dealsData,
      onCloseDeleteModal,
      pageNo,
      perPage,
      search,
      sortBy,
      removeSelectedItem = null,
    } = _request;
    try {
      const response = await apiClient(true).delete(`deals/${dealsData.id}`);
      dispatch(setLoading(false));
      if (response) {
        onCloseDeleteModal();
        if (removeSelectedItem) {
          removeSelectedItem(dealsData.id);
        }
        dispatch(notificationSuccess(Messages.SUCCESS.SIDE_MENU_DELETE_SUCCESS));
        dispatch(getDealsList({ pageIndex: pageNo, pageSize: perPage, search, sort: sortBy }));
      } else {
        dispatch(
          notificationFail(response?.data?.message || Messages.ERROR.SIDE_MENU_DELETE_ERROR)
        );
      }
    } catch (error: any) {
      if (error?.response?.data?.error) {
        const message = getErrorMessage(error?.response?.data?.error);
        dispatch(notificationFail(message));
      } else {
        dispatch(
          notificationFail(error?.response?.data?.message || Messages.ERROR.SIDE_MENU_DELETE_ERROR)
        );
      }
    }
  }
);

/**
 * Save / Update deals
 */
export const saveDeals = createAsyncThunk("saveDeals", async (_request: any, { dispatch }) => {
  dispatch(setLoading(true));
  const { dealsData, onClose, pageNo, perPage, isUpdate, search, sortBy } = _request;
  const dealsFormData = new FormData();
  dealsFormData.append("deal[title]", getValue(dealsData.title));
  dealsFormData.append("deal[description]", getValue(dealsData.description));
  dealsFormData.append("deal[brand_id]", getValue(dealsData?.brand?.id));
  if (dealsData?.delete_image && dealsData?.delete_image === 1) {
    dealsFormData.append("deal[delete_image]", getValue(dealsData?.delete_image));
  } else {
    dealsFormData.append("deal[delete_image]", "");
  }
  dealsFormData.append("deal[image]", getValue(dealsData?.image));
  dealsFormData.append("deal[delete_file]", getValue(dealsData?.delete_file));
  dealsFormData.append("deal[file]", getValue(dealsData?.file));
  dealsFormData.append("deal[more_info_url]", getValue(dealsData?.more_info_url));
  dealsFormData.append("deal[is_featured]", getValue(dealsData?.is_featured?.value));
  if (dealsData.countries) {
    dealsData.countries.forEach((val: any) => {
      dealsFormData.append("deal[country_ids][]", val.id);
    });
  }
  if (dealsData.categories) {
    dealsData.categories.forEach((val: any) => {
      dealsFormData.append("deal[category_ids][]", val.id);
    });
  }
  if (dealsData.tags) {
    dealsData.tags.forEach((val: any) => {
      dealsFormData.append("deal[tag_ids][]", val.id);
    });
  }

  try {
    const response = isUpdate
      ? await apiClient(true).put(`deals/${dealsData.id}`, dealsFormData)
      : await apiClient(true).post("deals", dealsFormData);
    if (response) {
      onClose();
      if (isUpdate) {
        dispatch(notificationSuccess(Messages.SUCCESS.DEAL_UPDATE_SUCCESS));
        dispatch(getDealsList({ pageIndex: pageNo, pageSize: perPage, search, sort: sortBy }));
      } else {
        dispatch(notificationSuccess(Messages.SUCCESS.DEAL_CREATE_SUCCESS));
        dispatch(getDealsList({ pageIndex: pageNo, pageSize: perPage }));
      }
    } else {
      dispatch(notificationFail(response?.data?.message || Messages.ERROR.DEAL_SAVE_ERROR));
    }
  } catch (error: any) {
    dispatch(setLoading(false));
    if (error?.response?.data?.error) {
      const message = getErrorMessage(error?.response?.data?.error);
      dispatch(notificationFail(message));
    } else {
      dispatch(notificationFail(error?.response?.data?.message || Messages.ERROR.DEAL_SAVE_ERROR));
    }
  }
});

/**
 * get all state regions
 */
export const getStateRegions = createAsyncThunk(
  "getStateRegions",
  async (_request: any, { dispatch }) => {
    const page = _request.pageIndex || 0;
    const size = _request.pageSize || 50;
    const search = _request.search || "";
    const sortBy = _request.sort || [];
    const filterBy = _request.filterData || [];
    dispatch(setLoading(true));
    try {
      let url = `state_regions?page=${page + 1}&per_page=${size}`;
      if (search) {
        url += `&search=${search}`;
      }
      if (sortBy?.length) {
        url += `&field=${sortBy[0]?.id}&order_by=${sortBy[0]?.desc ? "desc" : "asc"}`;
      }
      // Apply advance filter
      if (filterBy && filterBy.length) {
        url = getFilterURL(url, filterBy);
      }
      const response = await apiClient().get(url);
      if (response) {
        dispatch(setLoading(false));
        dispatch(setStateRegions(response?.data || []));
      }
    } catch (error: any) {
      dispatch(setLoading(false));
      dispatch(notificationFail(error.message || Messages.ERROR.NO_DATA_FOUND));
    }
  }
);

/**
 * Delete State Regions data
 */
export const deleteStateRegionsData = createAsyncThunk(
  "deleteStateRegionsData",
  async (_request: any, { dispatch }) => {
    dispatch(setLoading(true));
    const {
      stateRegionData,
      onCloseDeleteModal,
      pageNo,
      perPage,
      search,
      sortBy,
      removeSelectedItem = null,
    } = _request;
    try {
      const response = await apiClient().delete(`state_regions/${stateRegionData.id}`);
      dispatch(setLoading(false));
      if (response) {
        onCloseDeleteModal();
        if (removeSelectedItem) {
          removeSelectedItem(stateRegionData.id);
        }
        dispatch(notificationSuccess(Messages.SUCCESS.STATE_REGIONS_DELETE_SUCCESS));
        dispatch(getStateRegions({ pageIndex: pageNo, pageSize: perPage, search, sort: sortBy }));
      } else {
        dispatch(
          notificationFail(response?.data?.message || Messages.ERROR.STATE_REGIONS_DELETE_ERROR)
        );
      }
    } catch (error: any) {
      if (error?.response?.data?.error) {
        const message = getErrorMessage(error?.response?.data?.error);
        dispatch(notificationFail(message));
      } else {
        dispatch(
          notificationFail(
            error?.response?.data?.message || Messages.ERROR.STATE_REGIONS_DELETE_ERROR
          )
        );
      }
    }
  }
);

/**
 * Get Class Region
 */
export const getSolaClassRegions = createAsyncThunk(
  "getSolaClassRegions",
  async (_request: any, { dispatch }) => {
    const type = _request.type || 0;
    dispatch(setLoading(true));
    try {
      const url = `sola_class_regions/get_region_and_state?region_or_state=${type}`;
      const response = await apiClient().get(url);
      if (response) {
        dispatch(setLoading(false));
        if (type === "region") {
          dispatch(setSolaClassRegion(response?.data || []));
        } else {
          dispatch(setStates(response?.data || []));
        }
      }
    } catch (error: any) {
      dispatch(setLoading(false));
      dispatch(notificationFail(error.message || Messages.ERROR.NO_DATA_FOUND));
    }
  }
);

/**
 * Save / Update state regions
 */
export const saveStateRegions = createAsyncThunk(
  "saveStateRegions",
  async (_request: any, { dispatch }) => {
    dispatch(setLoading(true));
    const { stateRegionData, onClose, pageNo, perPage, isUpdate, search, sortBy } = _request;
    const reqData = {
      sola_class_region_state: {
        sola_class_region_id: stateRegionData.sola_class_region_id,
        state: stateRegionData.state,
      },
    };
    try {
      const response = isUpdate
        ? await apiClient().put(`state_regions/${stateRegionData.id}`, reqData)
        : await apiClient().post("state_regions", reqData);
      if (response) {
        dispatch(setLoading(false));
        onClose();
        if (isUpdate) {
          dispatch(
            notificationSuccess(
              response?.data?.message || Messages.SUCCESS.STATE_REGION_UPDATE_SUCCESS
            )
          );
          dispatch(getStateRegions({ pageIndex: pageNo, pageSize: perPage, search, sort: sortBy }));
        } else {
          dispatch(
            notificationSuccess(
              response?.data?.message || Messages.SUCCESS.STATE_REGION_CREATE_SUCCESS
            )
          );
          dispatch(getStateRegions({ pageIndex: pageNo, pageSize: perPage }));
        }
      } else {
        dispatch(
          notificationFail(response?.data?.message || Messages.ERROR.STATE_REGION_SAVE_ERROR)
        );
      }
    } catch (error: any) {
      dispatch(setLoading(false));
      if (error?.response?.data?.error) {
        const message = getErrorMessage(error?.response?.data?.error);
        dispatch(notificationFail(message));
      } else {
        dispatch(
          notificationFail(error?.response?.data?.message || Messages.ERROR.STATE_REGION_SAVE_ERROR)
        );
      }
    }
  }
);

/**
 * Get Education Hero Images list
 */
export const getEducationHeroImagesList = createAsyncThunk(
  "getEducationHeroImagesList",
  async (_request: any, { dispatch }) => {
    const page = _request.pageIndex || 0;
    const size = _request.pageSize || 50;
    const search = _request.search || "";
    const sortBy = _request.sort || [];
    const filterBy = _request.filterData || [];
    dispatch(setLoading(true));
    try {
      let url = `education_hero_images?page=${page + 1}&per_page=${size}`;
      if (search) {
        url += `&search=${search}`;
      }
      if (sortBy?.length) {
        url += `&field=${sortBy[0]?.id}&order_by=${sortBy[0]?.desc ? "desc" : "asc"}`;
      }
      // Apply advance filter
      if (filterBy && filterBy.length) {
        url = getFilterURL(url, filterBy);
      }
      const response = await apiClient().get(url);
      if (response) {
        dispatch(educationHeroImagesList(response?.data || []));
        dispatch(setLoading(false));
      } else {
        dispatch(
          notificationFail(
            response?.data?.message || Messages.ERROR.GET_EDUCATION_HERO_IMAGES_ERROR
          )
        );
      }
    } catch (error: any) {
      dispatch(notificationFail(error.message || Messages.ERROR.NO_DATA_FOUND));
      dispatch(setLoading(false));
    }
  }
);

/**
 * Delete Education Home Images
 */
export const deleteEducationHeroImages = createAsyncThunk(
  "deleteEducationHeroImages",
  async (_request: any, { dispatch }) => {
    dispatch(setLoading(true));
    const {
      educationHeroImagesData,
      onCloseDeleteModal,
      pageNo,
      perPage,
      search,
      sortBy,
      removeSelectedItem = null,
    } = _request;
    try {
      const response = await apiClient(true).delete(
        `education_hero_images/${educationHeroImagesData.id}`
      );
      dispatch(setLoading(false));
      if (response) {
        onCloseDeleteModal();
        if (removeSelectedItem) {
          removeSelectedItem(educationHeroImagesData.id);
        }
        dispatch(notificationSuccess(Messages.SUCCESS.EDUCATION_HERO_IMAGES_DELETE_SUCCESS));
        dispatch(
          getEducationHeroImagesList({
            pageIndex: pageNo,
            pageSize: perPage,
            search,
            sort: sortBy,
          })
        );
      } else {
        dispatch(
          notificationFail(
            response?.data?.message || Messages.ERROR.EDUCATION_HERO_IMAGES_DELETE_ERROR
          )
        );
      }
    } catch (error: any) {
      if (error?.response?.data?.error) {
        const message = getErrorMessage(error?.response?.data?.error);
        dispatch(notificationFail(message));
      } else {
        dispatch(
          notificationFail(
            error?.response?.data?.message || Messages.ERROR.EDUCATION_HERO_IMAGES_DELETE_ERROR
          )
        );
      }
    }
  }
);

/**
 * Save Education Hero Images
 */
export const saveEducationHeroImages = createAsyncThunk(
  "saveEducationHeroImages",
  async (_request: any, { dispatch }) => {
    dispatch(setLoading(true));
    const { educationHeroImageData, onClose, isUpdate, pageNo, perPage, search, sortBy } = _request;
    const eduHeroImagesRequest = new FormData();
    eduHeroImagesRequest.append(
      "education_hero_image[action_link]",
      getValue(educationHeroImageData?.action_link)
    );
    eduHeroImagesRequest.append(
      "education_hero_image[position]",
      getValue(educationHeroImageData?.position)
    );
    eduHeroImagesRequest.append(
      "education_hero_image[image]",
      getValue(educationHeroImageData?.image_original_url)
    );
    educationHeroImageData.countries.forEach((val: any) => {
      eduHeroImagesRequest.append("education_hero_image[country_ids][]", val.id);
    });
    try {
      const response = isUpdate
        ? await apiClient(true).put(
            `education_hero_images/${educationHeroImageData.id}`,
            eduHeroImagesRequest
          )
        : await apiClient(true).post("education_hero_images", eduHeroImagesRequest);
      dispatch(setLoading(false));
      if (response) {
        onClose();
        if (isUpdate) {
          dispatch(
            notificationSuccess(
              response?.data?.message || Messages.SUCCESS.EDUCATION_HERO_IMAGES_UPDATE_SUCCESS
            )
          );
          dispatch(
            getEducationHeroImagesList({
              pageIndex: pageNo,
              pageSize: perPage,
              search,
              sort: sortBy,
            })
          );
        } else {
          dispatch(
            notificationSuccess(
              response?.data?.message || Messages.SUCCESS.EDUCATION_HERO_IMAGES_CREATE_SUCCESS
            )
          );
          dispatch(getEducationHeroImagesList({ pageIndex: pageNo, pageSize: perPage }));
        }
      } else {
        dispatch(
          notificationFail(
            response?.data?.message || Messages.ERROR.EDUCATION_HERO_IMAGES_SAVE_ERROR
          )
        );
      }
    } catch (error: any) {
      dispatch(setLoading(false));
      if (error?.response?.data?.error) {
        const message = getErrorMessage(error?.response?.data?.error);
        dispatch(notificationFail(message));
      } else {
        dispatch(
          notificationFail(
            error?.response?.data?.message || Messages.ERROR.EDUCATION_HERO_IMAGES_SAVE_ERROR
          )
        );
      }
    }
  }
);

/**
 * Get list of regions
 */
export const getRegionsList = createAsyncThunk(
  "getRegionsList",
  async (_request: any, { dispatch }) => {
    const page = _request.pageIndex || 0;
    const size = _request.pageSize || 50;
    const search = _request.search || "";
    const sortBy = _request.sort || [];
    const filterBy = _request.filterData || [];
    dispatch(setLoading(true));
    try {
      let url = `sola_class_regions?page=${page + 1}&per_page=${size}`;
      if (search) {
        url += `&search=${search}`;
      }
      if (sortBy?.length) {
        url += `&field=${sortBy[0]?.id}&order_by=${sortBy[0]?.desc ? "desc" : "asc"}`;
      }
      // Apply advance filter
      if (filterBy && filterBy.length) {
        url = getFilterURL(url, filterBy);
      }
      const response = await apiClient().get(url);
      if (response) {
        dispatch(setRegionsList(response?.data || []));
        dispatch(setLoading(false));
      } else {
        dispatch(notificationFail(response?.data?.message || Messages.ERROR.SOMETHING_WENT_WRONG));
      }
    } catch (error: any) {
      dispatch(notificationFail(error.message || Messages.ERROR.NO_DATA_FOUND));
      dispatch(setLoading(false));
    }
  }
);

/**
 * Save regions record
 */
export const saveRegions = createAsyncThunk("saveRegions", async (_request: any, { dispatch }) => {
  dispatch(setLoading(true));
  const { regionsData, onClose, isUpdate, pageNo, perPage, search, sortBy } = _request;

  try {
    const response = isUpdate
      ? await apiClient().put(`sola_class_regions/${regionsData.id}`, {
          region: regionsData,
        })
      : await apiClient().post("sola_class_regions", {
          region: regionsData,
        });
    if (response) {
      onClose();
      if (isUpdate) {
        dispatch(notificationSuccess(Messages.SUCCESS.REGION_UPDATE_SUCCESS));
        dispatch(getRegionsList({ pageIndex: pageNo, pageSize: perPage, search, sort: sortBy }));
      } else {
        dispatch(notificationSuccess(Messages.SUCCESS.REGION_CREATE_SUCCESS));
        dispatch(getRegionsList({ pageIndex: pageNo, pageSize: perPage }));
      }
    } else {
      dispatch(setLoading(false));
      dispatch(notificationFail(response?.data?.message || Messages.ERROR.REGION_SAVE_ERROR));
    }
  } catch (error: any) {
    dispatch(setLoading(false));
    if (error?.response?.data?.error) {
      const message = getErrorMessage(error?.response?.data?.error);
      dispatch(notificationFail(message));
    } else {
      dispatch(
        notificationFail(error?.response?.data?.message || Messages.ERROR.REGION_SAVE_ERROR)
      );
    }
  }
});

/**
 * Delete region record
 */
export const deleteRegionRecord = createAsyncThunk(
  "deleteRegionRecord",
  async (_request: any, { dispatch }) => {
    dispatch(setLoading(true));
    const {
      regionsData,
      onCloseDeleteModal,
      pageNo,
      perPage,
      search,
      sortBy,
      removeSelectedItem = null,
    } = _request;
    try {
      const response = await apiClient(true).delete(`sola_class_regions/${regionsData.id}`);
      if (response) {
        onCloseDeleteModal();
        if (removeSelectedItem) {
          removeSelectedItem(regionsData.id);
        }
        dispatch(notificationSuccess(Messages.SUCCESS.REGION_DELETE_SUCCESS));
        dispatch(getRegionsList({ pageIndex: pageNo, pageSize: perPage, search, sort: sortBy }));
      } else {
        dispatch(setLoading(false));
        dispatch(notificationFail(response?.data?.message || Messages.ERROR.REGION_DELETE_ERROR));
      }
    } catch (error: any) {
      dispatch(setLoading(false));
      if (error?.response?.data?.error) {
        const message = getErrorMessage(error?.response?.data?.error);
        dispatch(notificationFail(message));
      } else {
        dispatch(
          notificationFail(error?.response?.data?.message || Messages.ERROR.REGION_DELETE_ERROR)
        );
      }
    }
  }
);

/**
 * Get videos list
 */
export const getVideos = createAsyncThunk("getVideos", async (_request: any, { dispatch }) => {
  dispatch(setLoading(true));
  const page = _request.pageIndex || 0;
  const size = _request.pageSize || 50;
  const search = _request.search || "";
  const all = _request.all || "";
  const sortBy = _request.sort || [];
  const filterBy = _request.filterData || [];
  try {
    let url = `videos?page=${page + 1}&per_page=${size}`;
    if (search) {
      url += `&search=${search}`;
    }
    if (all) {
      url = `videos?all=${all}`;
    }
    if (sortBy?.length) {
      url += `&field=${sortBy[0]?.id}&order_by=${sortBy[0]?.desc ? "desc" : "asc"}`;
    }
    // Apply advance filter
    if (filterBy && filterBy.length) {
      url = getFilterURL(url, filterBy);
    }
    const response = await apiClient().get(url);
    if (response) {
      dispatch(setLoading(false));
      dispatch(setVideos(response?.data || []));
    }
  } catch (error: any) {
    dispatch(setVideos([]));
    dispatch(setLoading(false));
    dispatch(notificationFail(error.message || Messages.ERROR.SOMETHING_WENT_WRONG));
  }
});

// get single video
export const getVideo = createAsyncThunk("getVideo", async (_request: any, { dispatch }) => {
  dispatch(setLoading(true));
  try {
    const response = await apiClient().get(`videos/${_request.id}`);
    dispatch(setLoading(false));
    if (response) {
      dispatch(setVideo(response.data));
    } else {
      dispatch(setVideo(null));
      dispatch(notificationFail(Messages.ERROR.NO_DATA_FOUND));
    }
  } catch (error: any) {
    dispatch(notificationFail(error.message || Messages.ERROR.NO_DATA_FOUND));
    dispatch(setLoading(false));
  }
});

/**
 * add/update video
 */
export const addUpdateVideo = createAsyncThunk(
  "addUpdateVideo",
  async (_request: any, { dispatch }) => {
    dispatch(setLoading(true));
    const { video, onClose, pageNo, perPage, search, sortBy } = _request;
    try {
      const response = video?.id
        ? await apiClient().put(`videos/${video?.id}`, { video })
        : await apiClient().post(`videos`, { video });
      if (response) {
        onClose();
        if (video?.id) {
          dispatch(notificationSuccess(Messages.SUCCESS.RECORD_UPDATE_SUCCESS));
          dispatch(getVideos({ pageIndex: pageNo, pageSize: perPage, search, sort: sortBy }));
        } else {
          dispatch(notificationSuccess(Messages.SUCCESS.RECORD_CREATE_SUCCESS));
          dispatch(getVideos({ pageIndex: pageNo, pageSize: perPage }));
        }
      } else {
        dispatch(notificationFail(response?.data?.message || Messages.ERROR.SOMETHING_WENT_WRONG));
      }
    } catch (error: any) {
      dispatch(setLoading(false));
      if (error?.response?.data?.error) {
        const message = getErrorMessage(error?.response?.data?.error);
        dispatch(notificationFail(message));
      } else {
        dispatch(
          notificationFail(error?.response?.data?.message || Messages.ERROR.SOMETHING_WENT_WRONG)
        );
      }
    }
  }
);

/**
 * Delete Video
 */
export const deleteVideo = createAsyncThunk("deleteVideo", async (_request: any, { dispatch }) => {
  dispatch(setLoading(true));
  const { id, callback, pageNo, perPage, search, sortBy, removeSelectedItem = null } = _request;
  try {
    const response = await apiClient().delete(`videos/${id}`);
    dispatch(setLoading(false));
    if (response) {
      if (callback) {
        callback();
      }
      if (removeSelectedItem) {
        removeSelectedItem(id);
      }
      dispatch(notificationSuccess(Messages.SUCCESS.RECORD_DELETE_SUCCESS));
      dispatch(getVideos({ pageIndex: pageNo, pageSize: perPage, search, sort: sortBy }));
    } else {
      dispatch(notificationFail(response?.data?.message || Messages.ERROR.SOMETHING_WENT_WRONG));
    }
  } catch (error: any) {
    if (error?.response?.data?.error) {
      const message = getErrorMessage(error?.response?.data?.error);
      dispatch(notificationFail(message));
    } else {
      dispatch(
        notificationFail(error?.response?.data?.message || Messages.ERROR.SOMETHING_WENT_WRONG)
      );
    }
  }
});

/**
 * Get list of notifications
 */
export const getNotificationsList = createAsyncThunk(
  "getNotificationsList",
  async (_request: any, { dispatch }) => {
    const page = _request.pageIndex || 0;
    const size = _request.pageSize || 50;
    const search = _request.search || "";
    const sortBy = _request.sort || [];
    const filterBy = _request.filterData || [];
    dispatch(setLoading(true));
    try {
      let url = `notifications?page=${page + 1}&per_page=${size}`;
      if (search) {
        url += `&search=${search}`;
      }
      if (sortBy?.length) {
        url += `&field=${sortBy[0]?.id}&order_by=${sortBy[0]?.desc ? "desc" : "asc"}`;
      }
      // Apply advance filter
      if (filterBy && filterBy.length) {
        url = getFilterURL(url, filterBy);
      }
      const response = await apiClient().get(url);
      if (response) {
        dispatch(setNotificationList(response?.data || []));
        dispatch(setLoading(false));
      } else {
        dispatch(notificationFail(response?.data?.message || Messages.ERROR.SOMETHING_WENT_WRONG));
      }
    } catch (error: any) {
      dispatch(notificationFail(error.message || Messages.ERROR.NO_DATA_FOUND));
      dispatch(setLoading(false));
    }
  }
);

/**
 * Delete notification record
 */
export const deleteNotificationRecord = createAsyncThunk(
  "deleteNotificationRecord",
  async (_request: any, { dispatch }) => {
    dispatch(setLoading(true));
    const {
      notificationsData,
      onCloseDeleteModal,
      pageNo,
      perPage,
      search,
      sortBy,
      removeSelectedItem = null,
    } = _request;
    try {
      const response = await apiClient(true).delete(`notifications/${notificationsData.id}`);
      if (response) {
        onCloseDeleteModal();
        if (removeSelectedItem) {
          removeSelectedItem(notificationsData.id);
        }
        dispatch(notificationSuccess(Messages.SUCCESS.NOTIFICATION_DELETE_SUCCESS));
        dispatch(
          getNotificationsList({ pageIndex: pageNo, pageSize: perPage, search, sort: sortBy })
        );
      } else {
        dispatch(setLoading(false));
        dispatch(
          notificationFail(response?.data?.message || Messages.ERROR.NOTIFICATION_DELETE_ERROR)
        );
      }
    } catch (error: any) {
      dispatch(setLoading(false));
      if (error?.response?.data?.error) {
        const message = getErrorMessage(error?.response?.data?.error);
        dispatch(notificationFail(message));
      } else {
        dispatch(
          notificationFail(
            error?.response?.data?.message || Messages.ERROR.NOTIFICATION_DELETE_ERROR
          )
        );
      }
    }
  }
);

/**
 * Save notification record
 */
export const saveNotifications = createAsyncThunk(
  "saveNotifications",
  async (_request: any, { dispatch }) => {
    dispatch(setLoading(true));
    const { notificationsData, onClose, isUpdate, pageNo, perPage, search, sortBy } = _request;

    try {
      const response = isUpdate
        ? await apiClient().put(`notifications/${notificationsData.update_id}`, {
            notification: notificationsData,
          })
        : await apiClient().post("notifications", {
            notification: notificationsData,
          });
      if (response) {
        onClose();
        if (isUpdate) {
          dispatch(notificationSuccess(Messages.SUCCESS.NOTIFICATION_UPDATE_SUCCESS));
          dispatch(
            getNotificationsList({ pageIndex: pageNo, pageSize: perPage, search, sort: sortBy })
          );
        } else {
          dispatch(notificationSuccess(Messages.SUCCESS.NOTIFICATION_CREATE_SUCCESS));
          dispatch(getNotificationsList({ pageIndex: pageNo, pageSize: perPage }));
        }
      } else {
        dispatch(setLoading(false));
        dispatch(
          notificationFail(response?.data?.message || Messages.ERROR.NOTIFICATION_SAVE_ERROR)
        );
      }
    } catch (error: any) {
      dispatch(setLoading(false));
      if (error?.response?.data?.error) {
        const message = getErrorMessage(error?.response?.data?.error);
        dispatch(notificationFail(message));
      } else {
        dispatch(
          notificationFail(error?.response?.data?.message || Messages.ERROR.NOTIFICATION_SAVE_ERROR)
        );
      }
    }
  }
);

// get all tool and resources
export const getToolAndResourcesList = createAsyncThunk(
  "getToolAndResourcesList",
  async (_request: any, { dispatch }) => {
    dispatch(setLoading(true));
    const page = _request.pageIndex || 0;
    const size = _request.pageSize || 50;
    const search = _request.search || "";
    const sortBy = _request.sort || [];
    const filterBy = _request.filterData || [];
    try {
      let url = `tools_and_resources?page=${page + 1}&per_page=${size}`;
      if (search) {
        url += `&search=${search}`;
      }
      if (sortBy?.length) {
        url += `&field=${sortBy[0]?.id}&order_by=${sortBy[0]?.desc ? "desc" : "asc"}`;
      }
      // Apply advance filter
      if (filterBy && filterBy.length) {
        url = getFilterURL(url, filterBy);
      }
      const response = await apiClient().get(url);
      dispatch(setLoading(false));
      if (response) {
        dispatch(setToolList(response?.data || []));
      }
    } catch (error: any) {
      dispatch(notificationFail(error.message || Messages.ERROR.NO_DATA_FOUND));
      dispatch(setLoading(false));
    }
  }
);

export const createTool = createAsyncThunk("createTool", async (_request: any, { dispatch }) => {
  dispatch(setLoading(true));
  const { ToolData, isUpdate, onClose, pageNo, perPage, search, sortBy } = _request;
  const formData = new FormData();

  if (isUpdate && ToolData.id) {
    formData.append("tool[id]", ToolData.id);
  }
  formData.append("tool[title]", getValue(ToolData.title));
  formData.append("tool[description]", getValue(ToolData.description));
  const isFeatureValue = ToolData?.is_featured === "true" ? "1" : "0";
  formData.append("tool[is_featured]", isFeatureValue);
  formData.append("tool[link_url]", getValue(ToolData.link_url));
  formData.append("tool[youtube_url]", getValue(ToolData?.youtube_url));
  if (ToolData?.delete_image && ToolData?.delete_image === 1) {
    formData.append("tool[delete_image]", getValue(ToolData?.delete_image));
  } else {
    formData.append("tool[delete_image]", "");
  }
  formData.append("tool[image]", getValue(ToolData?.image_url));
  formData.append("tool[file]", getValue(ToolData?.file_url));
  formData.append("tool[delete_file]", getValue(ToolData?.delete_file));
  formData.append("tool[brand_id]", getValue(ToolData?.brand?.id));
  if (ToolData?.category?.length) {
    ToolData.category.forEach((val: any) => {
      formData.append("tool[category_ids][]", val.id);
    });
  } else {
    formData.append("tool[category_ids][]", "");
  }
  if (ToolData.tag) {
    ToolData.tag.forEach((val: any) => {
      formData.append("tool[tag_ids][]", val.id);
    });
  } else {
    formData.append("tool[tag_ids][]", "");
  }
  if (ToolData.countries_name) {
    ToolData.countries_name.forEach((val: any) => {
      formData.append("tool[country_ids][]", val.id);
    });
  } else {
    formData.append("tool[country_ids][]", "");
  }
  try {
    const response = isUpdate
      ? await apiClient(true).put(`tools_and_resources/${ToolData.id}`, formData)
      : await apiClient(true).post("tools_and_resources", formData);

    if (response) {
      onClose();
      if (isUpdate) {
        dispatch(notificationSuccess(Messages.SUCCESS.TOOL_UPDATE_SUCCESS));
        dispatch(
          getToolAndResourcesList({ pageIndex: pageNo, pageSize: perPage, search, sort: sortBy })
        );
      } else {
        dispatch(notificationSuccess(Messages.SUCCESS.TOOL_CREATE_SUCCESS));
        dispatch(getToolAndResourcesList({ pageIndex: pageNo, pageSize: perPage }));
      }
    }
  } catch (error: any) {
    dispatch(setLoading(false));
    if (error?.response?.data?.error) {
      const message = getErrorMessage(error?.response?.data?.error);
      dispatch(notificationFail(message));
    } else {
      dispatch(
        notificationFail(error?.response?.data?.message || Messages.ERROR.TOOLS_CREATE_ERROR)
      );
    }
  }
});

export const deleteToolAndResourcesRecord = createAsyncThunk(
  "deleteToolAndResourcesRecord",
  async (_request: any, { dispatch }) => {
    const {
      toolData,
      onCloseDeleteModal,
      pageNo,
      perPage,
      search,
      sortBy,
      removeSelectedItem = null,
    } = _request;
    dispatch(setLoading(true));
    try {
      const response = await apiClient().delete(`tools_and_resources/${toolData?.id}`);
      if (response) {
        onCloseDeleteModal(false);
        if (removeSelectedItem) {
          removeSelectedItem(toolData?.id);
        }
        dispatch(notificationSuccess(Messages.SUCCESS.TOOL_DELETE_SUCCESS));
        dispatch(
          getToolAndResourcesList({ pageIndex: pageNo, pageSize: perPage, search, sort: sortBy })
        );
      } else {
        dispatch(setLoading(false));
        dispatch(notificationFail(response?.data?.message || Messages.ERROR.TOOLS_DELETE_ERROR));
      }
    } catch (error: any) {
      dispatch(setLoading(false));
      if (error?.response?.data?.error) {
        const message = getErrorMessage(error?.response?.data?.error);
        dispatch(notificationFail(message));
      } else {
        dispatch(
          notificationFail(error?.response?.data?.message || Messages.ERROR.TOOLS_DELETE_ERROR)
        );
      }
    }
  }
);
// get events and classes list
export const getEventsAndClassesList = createAsyncThunk(
  "getEventsAndClassesList",
  async (_request: any, { dispatch }) => {
    const page = _request.pageIndex || 0;
    const size = _request.pageSize || 50;
    const search = _request.search || "";
    const sortBy = _request.sort || [];
    const filterBy = _request.filterData || [];
    dispatch(setLoading(true));
    try {
      let url = `events_and_classes?page=${page + 1}&per_page=${size}`;
      if (search) {
        url += `&search=${search}`;
      }
      if (sortBy?.length) {
        url += `&field=${sortBy[0]?.id}&order_by=${sortBy[0]?.desc ? "desc" : "asc"}`;
      }
      // Apply advance filter
      if (filterBy && filterBy.length) {
        url = getFilterURL(url, filterBy);
      }
      const response = await apiClient().get(url, {
        params: getLocationsFilter(),
      });
      if (response) {
        dispatch(setEventsAndClassesList(response?.data || []));
        dispatch(setLoading(false));
      } else {
        dispatch(notificationFail(response?.data?.message || Messages.ERROR.GET_HOME_BUTTON_ERROR));
      }
    } catch (error: any) {
      dispatch(notificationFail(error.message || Messages.ERROR.NO_DATA_FOUND));
      dispatch(setLoading(false));
    }
  }
);

export const createUpdateEventAndClasses = createAsyncThunk(
  "createUpdateEventAndClasses",
  async (_request: any, { dispatch }) => {
    dispatch(setLoading(true));
    const { eventsAndClass, isUpdate, onClose, pageNo, perPage, search, sortBy } = _request;
    const formData = new FormData();

    if (isUpdate && eventsAndClass?.id) {
      formData.append("sola_class[id]", eventsAndClass?.id);
    }
    formData.append("sola_class[title]", getValue(eventsAndClass?.title));
    formData.append("sola_class[description]", getValue(eventsAndClass?.description));
    formData.append("sola_class[category_id]", getValue(eventsAndClass?.category_id?.id));
    formData.append("sola_class[class_image_id]", getValue(eventsAndClass?.class_image_id?.id));
    formData.append("sola_class[cost]", getValue(eventsAndClass?.cost));
    formData.append("sola_class[link_text]", getValue(eventsAndClass?.link_text));
    formData.append("sola_class[link_url]", getValue(eventsAndClass?.link_url));
    formData.append("sola_class[file_text]", getValue(eventsAndClass?.file_text));
    formData.append("sola_class[video_id]", getValue(eventsAndClass?.video_id?.id));
    formData.append("sola_class[start_date]", eventsAndClass?.start_date);
    formData.append("sola_class[start_time]", getValue(eventsAndClass?.start_time));
    formData.append("sola_class[end_date]", eventsAndClass?.end_date);
    formData.append("sola_class[end_time]", getValue(eventsAndClass?.end_time));
    formData.append(
      "sola_class[sola_class_region_id]",
      getValue(eventsAndClass?.sola_class_region_id?.id)
    );
    formData.append("sola_class[address]", getValue(eventsAndClass?.address));
    formData.append("sola_class[city]", getValue(eventsAndClass?.city));
    formData.append("sola_class[state]", getValue(eventsAndClass?.state));

    if (eventsAndClass?.brand_ids?.length) {
      eventsAndClass.brand_ids.forEach((val: any) => {
        formData.append("sola_class[brand_ids][]", val.id);
      });
    } else {
      formData.append("sola_class[brand_ids][]", "");
    }

    if (eventsAndClass?.tag_ids?.length) {
      eventsAndClass.tag_ids.forEach((val: any) => {
        formData.append("sola_class[tag_ids][]", val.id);
      });
    } else {
      formData.append("sola_class[tag_ids][]", "");
    }

    formData.append("sola_class[rsvp_email_address]", getValue(eventsAndClass?.rsvp_email_address));
    formData.append("sola_class[rsvp_phone_number]", getValue(eventsAndClass?.rsvp_phone_number));
    formData.append("sola_class[location]", getValue(eventsAndClass?.location));
    formData.append("sola_class[file]", getValue(eventsAndClass?.file));
    if (eventsAndClass?.delete_file && eventsAndClass?.delete_file === 1) {
      formData.append("sola_class[delete_file]", "1");
    }

    try {
      const response = isUpdate
        ? await apiClient(true).put(`events_and_classes/${eventsAndClass.id}`, formData)
        : await apiClient(true).post("events_and_classes", formData);

      if (response) {
        onClose();
        if (isUpdate) dispatch(notificationSuccess(Messages.SUCCESS.RECORD_UPDATE_SUCCESS));
        else dispatch(notificationSuccess(Messages.SUCCESS.RECORD_CREATE_SUCCESS));

        if (isUpdate) {
          dispatch(
            getEventsAndClassesList({ pageIndex: pageNo, pageSize: perPage, search, sort: sortBy })
          );
        } else {
          dispatch(getEventsAndClassesList({ pageIndex: pageNo, pageSize: perPage }));
        }
      }
    } catch (error: any) {
      dispatch(setLoading(false));
      if (error?.response?.data?.error) {
        const message = getErrorMessage(error?.response?.data?.error);
        dispatch(notificationFail(message));
      } else {
        dispatch(
          notificationFail(error?.response?.data?.message || Messages.ERROR.BLOG_CREATE_ERROR)
        );
      }
    }
  }
);
export const deleteEventsAndClassesRecord = createAsyncThunk(
  "deleteEventsAndClassesRecord",
  async (_request: any, { dispatch }) => {
    const {
      eventsAndClass,
      onCloseDeleteModal,
      pageNo,
      perPage,
      search,
      sortBy,
      removeSelectedItem = null,
    } = _request;
    dispatch(setLoading(true));
    try {
      const response = await apiClient().delete(`events_and_classes/${eventsAndClass?.id}`);
      if (response) {
        onCloseDeleteModal(false);
        if (removeSelectedItem) {
          removeSelectedItem(eventsAndClass?.id);
        }
        dispatch(notificationSuccess(Messages.SUCCESS.TOOL_DELETE_SUCCESS));
        dispatch(
          getEventsAndClassesList({ pageIndex: pageNo, pageSize: perPage, search, sort: sortBy })
        );
      } else {
        dispatch(setLoading(false));
        dispatch(
          notificationFail(response?.data?.message || Messages.SUCCESS.RECORD_DELETE_SUCCESS)
        );
      }
    } catch (error: any) {
      dispatch(setLoading(false));
      if (error?.response?.data?.error) {
        const message = getErrorMessage(error?.response?.data?.error);
        dispatch(notificationFail(message));
      } else {
        dispatch(
          notificationFail(error?.response?.data?.message || Messages.ERROR.SOMETHING_WENT_WRONG)
        );
      }
    }
  }
);
