import { useCallback, useEffect, useLayoutEffect, useRef, useState } from "react";
import { Card, Icon, Checkbox, Grid } from "@mui/material";

import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDDialog from "components/MDDialog";
import MDTypography from "components/MDTypography";

import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import DataTable from "examples/Tables/DataTable";
import { useAppDispatch, useAppSelector } from "store/store";
import Messages from "helper/messages";
import { displayMultipleValue, setDefaultSelectedColumns } from "helper/services";
import ImagePreview from "layouts/pages/ImagePreview";
import { deleteToolAndResourcesRecord, getToolAndResourcesList } from "store/thunk/mobileAppThunk";
import exportData from "helper/exportTableConst";
import MDIconButton from "components/MDIconButton";
import { getDefaultCoulmns, saveSelectedAllData } from "store/thunk/connectUserThunk";
import { setDefaultsColumns } from "store/slices/connectUsersSlice";
import AddUpdate from "./addUpdate";
import ViewDetails from "./viewDetails";

function Index(): JSX.Element {
  const dispatch = useAppDispatch();
  const [addUpdatePopup, setAddUpdatePopup] = useState<any>(false);
  const [updateData, setUpdateData] = useState<any>({});
  const { toolList } = useAppSelector((state) => state.mobileAppSlice);
  const { defaultSelectedColumns } = useAppSelector((state) => state.connectUsersReducer);
  const { userData } = useAppSelector((state) => state.authReducer);
  const [detailsPopup, setDetailsPopup] = useState<any>(false);
  const [isDelete, setIsDelete] = useState<any>(false);
  const [selectedItems, setSelectedItems] = useState([]);
  const [imagesList, setImagesList] = useState([]);
  const [pageCount, setPageCount] = useState(0);
  const [pageNo, setPageNo] = useState(0);
  const [perPage, setPageSize] = useState(50);
  const [totalCount, setTotalCount] = useState(0);
  const [search, setSearch] = useState<any>(null);
  const [sortBy, setSortBy] = useState<any>([]);
  const [isCheckAll, setIsCheckAll] = useState(false);
  const [isPreview, setImagePreview] = useState(false);
  const [imageToPreview, setImageToPreview] = useState("");
  const fetchIdRef = useRef(0);
  const [displayColumns, setDisplayColumns] = useState<any>([]);
  const [defaultExport, setDefaultExport] = useState<any>([]);
  const [callFetch, setCallFetch] = useState<any>(false);
  const [defaultSorting, setDefaultSorting] = useState<any>([]);
  const [defaultFilteing, setDefaultFilteing] = useState<any>([]);

  // Get columns
  const getColumns = () => {
    const columns: any = [
      {
        Header: "",
        disableSortBy: true,
        accessor: "checkbox",
        export: false,
        checked: true,
      },
      {
        Header: "Title",
        accessor: "title",
        width: "200px",
        export: true,
        disableCheck: true,
        checked: true,
      },
      { Header: "Description", accessor: "description", export: true, checked: true },
      {
        Header: "Brand",
        accessor: "brand",
        sorting_column: "brand",
        export: true,
        checked: true,
        associative_colums: "brands.name",
      },
      {
        Header: "Categories",
        accessor: "categories",
        sorting_column: "categories",
        width: "200px",
        export: false,
        checked: true,
        associative_colums: "categories.name",
      },
      {
        Header: "Tags",
        accessor: "tags",
        sorting_column: "tags",
        associative_colums: "tags.name",
        width: "200px",
        export: true,
        checked: true,
      },
      {
        Header: "Image",
        accessor: "image_url",
        width: "200px",
        export: true,
        hide_columns: true,
        checked: true,
        disableSortBy: true,
      },
      { Header: "File", accessor: "file", width: "300px", export: true, checked: false },
      { Header: "Link Url", accessor: "link_url", export: true, checked: false },
      { Header: "Youtube Url", accessor: "youtube_url", export: true, checked: false },
      {
        Header: "Action",
        accessor: "action",
        width: "200px",
        export: false,
        disableSortBy: true,
        checked: false,
      },
    ];
    return columns;
  };
  const [checkedColumns, setCheckedColumns] = useState<any>(getColumns());
  const latestRefValue = useRef(checkedColumns);

  useEffect(() => {
    latestRefValue.current = checkedColumns;
  }, [checkedColumns]);

  useEffect(() => {
    dispatch(setDefaultsColumns([]));
    // Set data in variable and Call defaultColums Thunk
    const sentReq = {
      user_id: userData?.user_id,
      table_name: exportData.tools.table_name,
    };
    dispatch(getDefaultCoulmns(sentReq));
  }, []);

  useEffect(() => {
    if (checkedColumns?.length) {
      setDisplayColumns(checkedColumns.filter((col: any) => col.checked));
    }
  }, [checkedColumns]);

  useEffect(() => {
    if (defaultSelectedColumns && defaultSelectedColumns?.length) {
      const newColumns = setDefaultSelectedColumns(defaultSelectedColumns, getColumns());
      setCheckedColumns(newColumns);
    } else {
      setCheckedColumns(getColumns());
    }
  }, [defaultSelectedColumns]);

  useEffect(() => {
    if (toolList && toolList?.tools_and_resources) {
      setImagesList(toolList?.tools_and_resources);
      setPageCount(toolList?.meta?.total_pages);
      setTotalCount(toolList?.meta?.total_count);
    }
  }, [toolList]);

  const fetchData = useCallback(({ pageSize, pageIndex, search, sortBy, filterData, isManual }) => {
    const fetchId = ++fetchIdRef.current;
    const sort: any = sortBy || [];
    // We'll even set a delay to simulate a server here
    setTimeout(() => {
      if (fetchId === fetchIdRef.current) {
        const sentReq = {
          sendData: {
            user_id: userData?.user_id,
            table_name: exportData.tools.table_name,
            table_columns: latestRefValue.current ? latestRefValue.current : checkedColumns,
            table_sort: sortBy && sortBy?.length ? sortBy : [],
            table_filter: filterData && filterData?.length ? filterData : [],
            default_export_data: defaultExport,
          },
        };
        if (isManual) {
          dispatch(saveSelectedAllData(sentReq));
        }

        setPageNo(pageIndex);
        setSearch(search);
        setSortBy(sort);
        setPageSize(pageSize);
        // setSelectedItems([]);
        setIsCheckAll(false);
        dispatch(getToolAndResourcesList({ pageSize, pageIndex, search, sort, filterData }));
      }
    }, 1000);
  }, []);

  useLayoutEffect(() => {
    dispatch(setDefaultsColumns([]));
    // Set data in variable and Call defaultColums Thunk
    const sentReq = {
      user_id: userData?.user_id,
      table_name: exportData.tools.table_name,
    };
    dispatch(getDefaultCoulmns(sentReq)).then((resp: any) => {
      // SD-3199
      if (resp?.payload) {
        const respFilter = resp?.payload?.table_filter || [];
        const respSorting = resp?.payload?.table_sort || [];
        const defaultDBexportData = resp?.payload?.default_export_data || [];
        const defaultDBColumn = resp?.payload?.table_columns || [];
        const tempdata = setDefaultSelectedColumns(defaultDBColumn, getColumns());
        setCheckedColumns(tempdata);
        setDefaultExport(defaultDBexportData);
        setDefaultSorting(respSorting);
        setSortBy(respSorting);
        setDefaultFilteing(respFilter);
        setCallFetch(true);
        fetchData({
          perPage,
          pageNo,
          search,
          sortBy: respSorting && respSorting?.length ? respSorting : [],
          additionalField: null,
          filterData: respFilter && respFilter?.length ? respFilter : [],
        });
      }
    });
  }, []);

  const closeModal = () => {
    setUpdateData("");
    setAddUpdatePopup(false);
  };

  const openToolEditModal = (data: any) => {
    setUpdateData(data);
    setAddUpdatePopup(true);
  };

  const openDeleteModal = (data: any) => {
    setUpdateData(data);
    setIsDelete(true);
  };

  const openDetailPopup = (data: any) => {
    setUpdateData(data);
    setDetailsPopup(true);
  };
  const closeDetailPopup = () => {
    setUpdateData("");
    setDetailsPopup(false);
  };

  const actionButtons = (object: any) => (
    <>
      <MDIconButton
        tooltipName="View"
        aria-label="Info"
        color="info"
        onClick={() => openDetailPopup(object)}
      >
        <Icon fontSize="small">visibility</Icon>
      </MDIconButton>
      <MDIconButton
        tooltipName="Edit"
        aria-label="Edit"
        color="info"
        onClick={() => openToolEditModal(object)}
      >
        <Icon fontSize="small">edit</Icon>
      </MDIconButton>
      <MDIconButton
        tooltipName="Delete"
        aria-label="Delete"
        color="error"
        onClick={() => openDeleteModal(object)}
      >
        <Icon fontSize="small">delete</Icon>
      </MDIconButton>
    </>
  );

  const onChangeCheckBox = (e: any) => {
    let items = JSON.parse(JSON.stringify(selectedItems));
    if (e.target.checked && !items.includes(e.target.value)) {
      items.push(e.target.value);
      if (items.length === imagesList.length) {
        setIsCheckAll(true);
      }
    } else {
      items = items.filter((id: any) => id !== e.target.value);
      setIsCheckAll(false);
    }
    setSelectedItems(items);
  };

  const checkUncheckAll = (e: any) => {
    let allIds: any = [];
    allIds = imagesList.map((val: any) => String(val.id));
    if (e.target.checked) {
      const selectedIds = [...new Set([...selectedItems, ...allIds])];
      setSelectedItems(selectedIds);
      setIsCheckAll(true);
    } else {
      allIds = imagesList.map((val: any) => String(val.id));
      allIds = selectedItems.filter((el: any) => !allIds.includes(el));
      setSelectedItems([...allIds]);
      setIsCheckAll(false);
    }
    // setSelectedItems(allIds);
  };

  const previewImage = (image: any) => {
    setImagePreview(true);
    setImageToPreview(image);
  };

  const closeImagePreview = () => {
    setImagePreview(false);
    setImageToPreview("");
  };

  const getImage = (imageURL: any) => (
    <Grid container>
      <Grid item>
        {imageURL && (
          <Grid
            container
            onClick={() => previewImage(imageURL)}
            justifyContent="center"
            alignItems="center"
            textAlign="center"
            className="thumbnail-img"
          >
            <img style={{ maxHeight: "100px" }} width={100} src={imageURL} alt="original" />
          </Grid>
        )}
      </Grid>
    </Grid>
  );

  const getRows = () => {
    const data: any = imagesList.map((obj: any) => ({
      checkbox: (
        <Checkbox
          checked={selectedItems.includes(obj.id.toString())}
          onChange={(e: any) => onChangeCheckBox(e)}
          value={obj.id}
        />
      ),
      id: obj.id ? obj.id : "-",
      title: obj.title,
      description: obj.description ? obj.description : "-",
      brand: obj.brand_name ? obj.brand_name : "-",
      categories: obj.category ? displayMultipleValue(obj.category) : "-",
      tags: obj.tag ? displayMultipleValue(obj.tag) : "-",
      image_url: obj.image_url ? getImage(obj.image_url) : "-",
      file: obj.file_url ? obj.file_url : "-",
      link_url: obj.link_url ? obj.link_url : "-",
      youtube_url: obj.youtube_url ? obj.youtube_url : "-",
      action: actionButtons(obj),
    }));
    return data;
  };

  const tableData = {
    columns: getColumns(),
    rows: getRows(),
  };

  const onCloseDeleteModal = () => {
    setIsDelete(false);
    setUpdateData("");
  };

  // Start Remove selcted items
  const removeSelectedItem = (deletedId: number) => {
    setSelectedItems(selectedItems.filter((item: any) => Number(item) !== deletedId));
  };
  // End Remove selcted items

  const onDelete = () => {
    if (updateData?.id) {
      const DelReq = {
        toolData: updateData,
        onCloseDeleteModal,
        pageNo,
        perPage,
        search,
        sortBy,
        removeSelectedItem,
      };
      dispatch(deleteToolAndResourcesRecord(DelReq));
    }
  };

  const deleteDialogProps = {
    open: isDelete,
    onClose: () => onCloseDeleteModal(),
    onSave: () => onDelete(),
    title: "Delete Tool",
    size: "sm",
    saveBtn: false,
    deleteBtn: true,
    saveTbtText: "Yes",
    closeBtnText: "No",
  };

  // Remove uncheck boxes
  const clearCheckItem = () => {
    setSelectedItems([]);
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={4} pb={3}>
        <Card>
          <MDBox
            bgColor="dark"
            color="white"
            coloredShadow="dark"
            borderRadius="xl"
            alignItems="center"
            justifyContent="space-between"
            pt={1}
            pb={1}
            pl={2}
            pr={2}
            ml={2}
            mr={2}
            display="flex"
            mt={-3}
            className="page-header"
          >
            <p className="page-header-label">Tools and Resources</p>
            <MDButton
              variant="gradient"
              color="light"
              size="small"
              className="xs-small"
              onClick={() => setAddUpdatePopup(true)}
            >
              <Icon sx={{ fontWeight: "bold" }}>add</Icon> Add New
            </MDButton>
          </MDBox>
          {(defaultSorting || defaultSorting?.length) && callFetch && (
            <DataTable
              defaultSortingColumns={defaultSorting}
              defaultFilteringColums={defaultFilteing}
              defaultDisplayColumns={displayColumns}
              setCheckedColumns={setCheckedColumns}
              table={tableData}
              canSearch
              searchText="Search..."
              selectedItems={selectedItems}
              showExportSelectedBtn
              showExportAllBtn
              pageCount={pageCount}
              pageNo={pageNo}
              setPageNo={setPageNo}
              totalCount={totalCount}
              fetchData={fetchData}
              manualSearch
              exportFields={exportData.tools.export_fields}
              exportTable={exportData.tools.table_name}
              exportType={exportData.tools.export_type}
              advanceFilter
              manualSort
              isDisplayCheckAllCheckbox
              onChangeCheckAllCheckbox={checkUncheckAll}
              isCheckAll={isCheckAll}
              checkedColumns={checkedColumns}
              clearCheckItem={clearCheckItem}
              useRubyApiToExport
            />
          )}
          {addUpdatePopup && (
            <AddUpdate
              open={addUpdatePopup}
              pageNo={pageNo}
              perPage={perPage}
              search={search}
              sortBy={sortBy}
              updateData={updateData}
              onClose={() => closeModal()}
            />
          )}
          {detailsPopup && (
            <ViewDetails
              open={detailsPopup}
              updateData={updateData}
              onClose={() => closeDetailPopup()}
            />
          )}
          {isDelete && (
            <MDDialog {...deleteDialogProps}>
              <MDTypography variant="h6" fontWeight="medium">
                {Messages.GENERAL.SURE_TO_DELETE}
              </MDTypography>
            </MDDialog>
          )}
          <ImagePreview
            imageData={imageToPreview}
            open={isPreview}
            onCloseImage={() => {
              closeImagePreview();
            }}
          />
        </Card>
      </MDBox>
    </DashboardLayout>
  );
}

export default Index;
