import { useState, useEffect } from "react";
import { Card, Icon } from "@mui/material";
import MDBox from "components/MDBox";
import DataTable from "examples/Tables/DataTable";
import {
  getTabWiseSolaProList,
  getmatchConfirmSolaProList,
  removeConfirmedSolaPro,
} from "store/thunk/systemAdmin";
import {
  setTabWiseSolaProList,
  setRmSearchResultStylist,
  setFrenchiseeRmMatchData,
} from "store/slices/systemAdminSlice";
import { useAppDispatch, useAppSelector } from "store/store";
import MDDialog from "components/MDDialog";
import MDTypography from "components/MDTypography";
import MDIconButton from "components/MDIconButton";
import DetailPopup from "./detailPopup";
import SearchUnMatched from "./searchUnMatched";

function TabWiseSolaProList(props: any): JSX.Element {
  const { tabName } = props;
  const dispatch = useAppDispatch();
  const { tabWiseSolaProList } = useAppSelector((state) => state.systemAdminSlice);
  const [listingData, setListingData] = useState([]);
  const [headingName, setHeadingName] = useState("");
  const [openDetailPagePopup, setOpenDetailPagePopup] = useState(false);
  const [searchUnmatchPopup, setSearchUnmatchPopup] = useState(false);
  const [detailData, setSetDetailData] = useState("");
  const [removeConfirmed, setRemoveConfirmed] = useState<any>(false);

  useEffect(() => {
    dispatch(setTabWiseSolaProList([]));
    if (tabName) {
      if (tabName === "confirmed") {
        dispatch(getmatchConfirmSolaProList({}));
      } else {
        dispatch(getTabWiseSolaProList({ tab_name: tabName }));
      }
      if (tabName === "unmatched") {
        setHeadingName("Unmatched Sola Pros");
      } else if (tabName === "multiple_matched") {
        setHeadingName("Multiple Matched Sola Pros");
      } else if (tabName === "matched") {
        setHeadingName("Matched Sola Pros");
      } else if (tabName === "confirmed") {
        setHeadingName("Match Confirmed Sola Pros");
      } else {
        setHeadingName("List");
      }
    }
  }, []);

  const onOpenDetailpage = (object: any) => {
    setSetDetailData(object);
    setOpenDetailPagePopup(true);
  };
  const onCloseDetailPopup = () => {
    setSetDetailData("");
    setOpenDetailPagePopup(false);
    // dispatch(setTabWiseSolaProList([]));
    dispatch(setFrenchiseeRmMatchData([]));
  };
  const onOpenSearchPopup = (object: any) => {
    setSetDetailData(object);
    setSearchUnmatchPopup(true);
  };
  const onCloseSearchPopup = () => {
    setSetDetailData("");
    setSearchUnmatchPopup(false);
    dispatch(setRmSearchResultStylist([]));
  };
  const closeConifrm = () => {
    setRemoveConfirmed(false);
  };
  const onRemoveFromConfirm = () => {
    const req = {
      id: removeConfirmed?.id,
      closeConifrm,
    };
    dispatch(removeConfirmedSolaPro(req));
  };

  const confirmDialogProps = {
    open: removeConfirmed ? removeConfirmed : false,
    onClose: () => closeConifrm(),
    onSave: () => onRemoveFromConfirm(),
    title: `Remove from confirmd rent manager?`,
    size: "sm",
    saveBtn: true,
    saveTbtText: "Remove",
    closeBtnText: "Close",
  };

  const actionButtons = (object: any) => (
    <>
      {tabName === "confirmed" && (
        <MDIconButton
          tooltipName="Remove from confirmed"
          aria-label="Delete"
          color="error"
          onClick={() => setRemoveConfirmed(object)}
        >
          <Icon fontSize="small">delete</Icon>
        </MDIconButton>
      )}
      {tabName !== "confirmed" && (
        <MDIconButton
          tooltipName="View"
          aria-label="Info"
          color="info"
          onClick={() => {
            if (tabName === "unmatched") {
              onOpenSearchPopup(object);
            } else {
              onOpenDetailpage(object);
            }
          }}
        >
          <Icon fontSize="small">visibility</Icon>
        </MDIconButton>
      )}
    </>
  );

  useEffect(() => {
    if (tabWiseSolaProList && tabWiseSolaProList?.length > 0) {
      const allList: any = [];
      if (tabName !== "confirmed") {
        tabWiseSolaProList.map((val: any) => {
          allList.push({
            id: val?.id || "-",
            email: val?.email_address || "-",
            name: val?.name || "-",
            phone_number: val?.phone_number || "-",
            studio_number: val?.studio_number || "-",
            action: actionButtons(val),
          });
          return true;
        });
      } else {
        tabWiseSolaProList.map((val: any) => {
          allList.push({
            id: val?.stylist?.id || "-",
            email: val?.stylist?.email_address || "-",
            name: val?.stylist?.name || "-",
            phone_number: val?.stylist?.phone_number || "-",
            studio_number: val?.stylist?.studio_number || "-",
            rm_location_id: val?.rm_location_id || "-",
            rm_property_id: val?.rm_property_id || "-",
            rm_tenant_id: val?.rm_tenant_id || "-",
            match_done_by_type: val?.match_done_by_type || "-",
            match_done_by_id: val?.match_done_by_id || "-",
            action: actionButtons(val),
          });
          return true;
        });
      }
      setListingData(allList);
    } else if (tabWiseSolaProList && tabWiseSolaProList?.length === 0) {
      setListingData([]);
    }
  }, [tabWiseSolaProList]);

  const columns: any = [
    { Header: "Stylist ID", accessor: "id" },
    { Header: "Name", accessor: "name" },
    { Header: "email", accessor: "email" },
    { Header: "Phone Number", accessor: "phone_number" },
    { Header: "Studio Number", accessor: "studio_number" },
  ];
  if (tabName === "confirmed") {
    columns.push(
      { Header: "RM location ID", accessor: "rm_location_id" },
      { Header: "Property ID", accessor: "rm_property_id" },
      { Header: "Tenant ID", accessor: "rm_tenant_id" },
      { Header: "Match done by", accessor: "match_done_by_type" },
      { Header: "Match Done by User Id", accessor: "match_done_by_id" }
    );
  }
  if (tabName) {
    columns.push({
      Header: "Action",
      accessor: "action",
      width: "50px",
      export: false,
      disableSortBy: true,
    });
  }

  const tableData = {
    columns,
    rows: listingData,
  };
  return (
    <Card>
      <MDBox
        bgColor="dark"
        color="white"
        coloredShadow="dark"
        borderRadius="xl"
        alignItems="center"
        justifyContent="space-between"
        pt={1}
        pb={1}
        pl={2}
        pr={2}
        ml={2}
        mr={2}
        display="flex"
        mt={-3}
        className="page-header"
      >
        <p className="page-header-label">{headingName}</p>
      </MDBox>
      <DataTable table={tableData} canSearch manualSort={false} searchText="Search...." />
      {openDetailPagePopup && (
        <DetailPopup onClose={onCloseDetailPopup} detailViewData={detailData} />
      )}
      {searchUnmatchPopup && (
        <SearchUnMatched onClose={onCloseSearchPopup} detailViewData={detailData} />
      )}

      {removeConfirmed && (
        <MDDialog {...confirmDialogProps}>
          <MDTypography fontSize={16}>
            Are you sure you want to remove from confirmd rent manager ?
          </MDTypography>
        </MDDialog>
      )}
    </Card>
  );
}

export default TabWiseSolaProList;
