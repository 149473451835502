import { useCallback, useEffect, useRef, useState } from "react";
import { Card, Icon } from "@mui/material";
import MDBox from "components/MDBox";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import DataTable from "examples/Tables/DataTable";
import { useAppDispatch, useAppSelector } from "store/store";
import MDIconButton from "components/MDIconButton";
import { getHelpDeskRequestList } from "store/thunk/systemAdmin";
import ViewDetailPopup from "./viewDetails";

function SupportRequestListing(): JSX.Element {
  const dispatch = useAppDispatch();
  const { helpDeskReqList } = useAppSelector((state) => state.systemAdminSlice);
  const [helpDeskReqListData, setHelpDeskReqList] = useState([]);
  const [pageCount, setPageCount] = useState(0);
  const [pageNo, setPageNo] = useState(0);
  const [totalCount, setTotalCount] = useState(0);
  const [viewDetails, setViewDetails] = useState(null);
  const fetchIdRef = useRef(0);
  const [perPage, setPageSize] = useState(50);
  const [search, setSearch] = useState<any>(null);
  const [sortBy, setSortBy] = useState<any>([]);

  useEffect(() => {
    if (helpDeskReqList && helpDeskReqList?.connect_rm_match_request) {
      setHelpDeskReqList(helpDeskReqList?.connect_rm_match_request);
      setPageCount(helpDeskReqList?.meta?.total_pages);
      setTotalCount(helpDeskReqList?.meta?.total_count);
    }
  }, [helpDeskReqList]);

  const fetchData = useCallback(({ pageSize, pageIndex, search, sortBy }) => {
    const fetchId = ++fetchIdRef.current;
    const sort: any = sortBy || [];
    setTimeout(() => {
      if (fetchId === fetchIdRef.current) {
        setPageNo(pageIndex);
        setSearch(search);
        setSortBy(sort);
        setPageSize(pageSize);
        dispatch(getHelpDeskRequestList({ pageSize, pageIndex, search, sort }));
      }
    }, 1000);
  }, []);

  const viewRequestDetail = (data: any) => {
    setViewDetails(data);
  };
  const closeViewDetailPopup = () => {
    setViewDetails(null);
  };
  const actionButtons = (object: any) => (
    <MDIconButton
      tooltipName="Edit"
      aria-label="Edit"
      color="info"
      onClick={() => viewRequestDetail(object)}
    >
      <Icon fontSize="small">visibility</Icon>
    </MDIconButton>
  );

  const getRows = () => {
    const data: any = helpDeskReqListData.map((obj: any) => ({
      id: obj?.id,
      request_type: obj?.connect_submit_request_type?.request_type_name
        ? obj?.connect_submit_request_type?.request_type_name
        : "-",
      status: obj?.status ? obj?.status : "-",
      email: obj?.email ? obj?.email : "-",
      phone: obj?.phone ? obj?.phone : "-",
      first_name: obj?.first_name ? obj?.first_name : "-",
      last_name: obj?.last_name ? obj?.last_name : "-",
      maiden_name: obj?.maiden_name ? obj?.maiden_name : "-",
      action: actionButtons(obj),
    }));
    return data;
  };

  const tableData = {
    columns: [
      { Header: "Id", accessor: "id", export: true },
      { Header: "Request type", accessor: "request_type", export: true },
      { Header: "Status", accessor: "status", export: true },
      { Header: "Email", accessor: "email", export: true },
      { Header: "Phone", accessor: "phone", export: true },
      { Header: "First name", accessor: "first_name", export: true },
      { Header: "Last name", accessor: "last_name", export: true },
      { Header: "Maiden name", accessor: "maiden_name", export: true },
      { Header: "Action", accessor: "action", export: false, disableSortBy: true, width: "200px" },
    ],
    rows: getRows(),
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={4} pb={3}>
        <Card>
          <MDBox
            bgColor="dark"
            color="white"
            coloredShadow="dark"
            borderRadius="xl"
            alignItems="center"
            justifyContent="space-between"
            pt={1}
            pb={1}
            pl={2}
            pr={2}
            ml={2}
            mr={2}
            display="flex"
            mt={-3}
            className="page-header"
          >
            <p className="page-header-label">Self Service Helpdesk</p>
          </MDBox>
          <DataTable
            table={tableData}
            canSearch
            searchText="Search Self Service Helpdesk..."
            pageCount={pageCount}
            pageNo={pageNo}
            setPageNo={setPageNo}
            totalCount={totalCount}
            fetchData={fetchData}
            manualSearch
            manualSort
          />
        </Card>
        {viewDetails && (
          <ViewDetailPopup
            open={viewDetails}
            onClose={closeViewDetailPopup}
            viewData={viewDetails}
            pageNo={pageNo}
            perPag={perPage}
            search={search}
            sortBy={sortBy}
          />
        )}
      </MDBox>
    </DashboardLayout>
  );
}

export default SupportRequestListing;
