import React, { useEffect, useState } from "react";
import MDDialog from "components/MDDialog";
import MDInput from "components/MDInput";
import MDEditor from "components/MDEditor";
import MDTypography from "components/MDTypography";
import {
  Grid,
  Icon,
  FormControl,
  InputLabel,
  Select,
  InputAdornment,
  MenuItem,
  Tooltip,
  Box,
} from "@mui/material";
import InfoIcon from "@mui/icons-material/Info";
import MDDatePicker from "components/MDDatePicker";
import CustomDropZone from "components/CustomDropZone";
import CustomRequiredLabel from "components/CustomRequiredLabel";
import moment from "moment";
import Validations from "helper/validations";
import { createArticle, updateArticle } from "store/thunk/solaWebsiteThunk";
import { useAppDispatch } from "store/store";
import ImagePreview from "layouts/pages/ImagePreview";
import CustomErrorMessage from "components/CustomErrorMessage";
import constants from "helper/constants";
import {
  getAllAssociationLocations,
  adminSuperAdmin,
  getPermission,
  adminFranchisie,
  // compressImage,
  convertImageToWebp,
} from "helper/services";
import CustomAutoSearch from "components/CustomAutoSearch";
import MDIconButton from "components/MDIconButton";

function AddUpdate(props: any): JSX.Element {
  const { open, onClose, articleDetails, editArticle, pageNo, perPage, search, sortBy } = props;
  const dispatch = useAppDispatch();
  const [articalFormData, setArticalFormData] = useState<any>({});
  const [imagePreview, setImagePreview] = useState<any>("");
  const [errors, setErrors] = useState<any>({});
  const [loading] = useState<boolean>(false);
  const [isPreview, setImagePreviewPopup] = useState(false);
  const [imageToPreview, setImageToPreview] = useState("");

  const previewImage = (image: any) => {
    setImagePreviewPopup(true);
    setImageToPreview(image);
  };

  const closeImagePreview = () => {
    setImagePreviewPopup(false);
    setImageToPreview("");
  };

  const displaySetting = [
    { value: "sola_website", name: "Sola Website" },
    { value: "franchising_website", name: "Franchising Website" },
    { value: "both", name: "Both" },
  ];

  useEffect(() => {
    if (!editArticle) {
      setArticalFormData({});
    }
  }, []);

  useEffect(() => {
    if (editArticle && Object.keys(articleDetails).length > 0) {
      setArticalFormData((prev: any) => ({
        ...prev,
        id: articleDetails.id,
        title: articleDetails.title,
        article_url: articleDetails.article_url,
        summary: articleDetails.summary,
        location: articleDetails?.location_id
          ? { id: articleDetails?.location_id, name: articleDetails?.location_name }
          : null,
        display_setting: displaySetting.find((val) => articleDetails.display_setting === val.value)
          ?.value,
        created_at: articleDetails.created_at,
      }));
      if (articleDetails.id.toString().length === 1) {
        articleDetails.id = `00${articleDetails.id}`;
      } else if (articleDetails.id.toString().length === 2) {
        articleDetails.id = `0${articleDetails.id}`;
      }
      setImagePreview(articleDetails.image_url);
    }
  }, [articleDetails]);

  const onChangeField = (e: any, field: string) => {
    setArticalFormData((prev: any) => ({
      ...prev,
      [field]: e.target.value,
    }));
  };

  const onChangeFieldSetting = (e: any) => {
    setArticalFormData({
      ...articalFormData,
      [e.target.name]: e.target.value,
    });
  };

  const onChangeSummary = (e: any, field: string) => {
    setArticalFormData({ ...articalFormData, [field]: e });
  };

  const handleDateChange = (date: any) => {
    const utcDate = moment.utc(new Date(date)).format();
    setArticalFormData({ ...articalFormData, created_at: utcDate });
  };

  const onSave = () => {
    const error = Validations.validateArticleForm(articalFormData, imagePreview);
    setErrors(error);
    if (!Object.keys(error).length) {
      const req = {
        articalFormData,
        onClose,
        pageNo,
        perPage,
        search,
        sortBy,
      };
      if (editArticle) {
        dispatch(updateArticle(req));
      } else {
        dispatch(createArticle(req));
      }
    }
  };

  const onChangeImage = async (files: any, type: any) => {
    if (files[0]) {
      const file = await convertImageToWebp(files[0], "article", dispatch);
      setArticalFormData({ ...articalFormData, [type]: file });
      const reader = new FileReader();
      reader.onload = function (e) {
        if (type === "image") {
          setImagePreview(e.target.result);
        }
      };
      reader.readAsDataURL(file);
    }
  };

  const onImageRemove = async (e: any, type: any) => {
    e.stopPropagation();
    setArticalFormData({ ...articalFormData, [type]: null });
    if (type === "image") {
      setImagePreview("");
    }
  };

  const onChangeAutoComplete = (name: string, value: any) => {
    setArticalFormData({ ...articalFormData, [name]: value });
  };

  const dialogProps = {
    open,
    onClose,
    onSave,
    saveTbtText: editArticle ? "Update" : "Save",
    title: editArticle ? "Edit News" : "Add News",
    size: "lg",
    loading,
  };

  return (
    <MDDialog {...dialogProps}>
      <Grid container spacing={2} display="flex" alignItems="flex-end">
        <Grid item xs={12} sm={12} md={6} lg={6}>
          <MDInput
            label="Title"
            variant="standard"
            fullWidth
            required
            name="title"
            placeholder="Title"
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => onChangeField(e, "title")}
            value={articalFormData.title || ""}
            error={errors && errors.title ? true : false}
            helperText={errors && errors.title}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={6}>
          <MDInput
            label="Article Url"
            fullWidth
            multiline
            required
            variant="standard"
            rows={2}
            name="article_url"
            onChange={(e: any) => onChangeField(e, "article_url")}
            value={articalFormData?.article_url ? articalFormData.article_url : ""}
            error={errors && errors.article_url ? true : false}
            helperText={errors && errors.article_url}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <CustomRequiredLabel label="Image" />
          <Grid container mt={2}>
            <Grid item xs={12} sm={8} md={8} lg={8}>
              <CustomDropZone
                multipleUpload={false}
                acceptedFiles={{
                  "image/*": [".gif", ".png", ".jpg", ".jpeg"],
                }}
                onDrop={(e: any) => onChangeImage(e, "image")}
              >
                Drop your image here, or Browse
              </CustomDropZone>
            </Grid>
            <Grid item xs={12} sm={4} md={4} lg={4}>
              {imagePreview && (
                <Grid
                  container
                  onClick={() => previewImage(imagePreview)}
                  justifyContent="center"
                  alignItems="center"
                  display="flex"
                >
                  <Grid item className="imageWithDeleteIcon">
                    <img height={100} width={100} src={imagePreview} alt="article" />
                    <MDIconButton
                      tooltipName="Delete"
                      aria-label="Delete"
                      className="removeBtnClass"
                      onClick={(e: any) => onImageRemove(e, "image")}
                    >
                      <Icon fontSize="small">close</Icon>
                    </MDIconButton>
                  </Grid>
                </Grid>
              )}
            </Grid>
            {errors && errors.image && <CustomErrorMessage message={errors.image} />}
          </Grid>
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <MDTypography component="label" variant="button" fontWeight="regular" color="text">
            Summary
          </MDTypography>
          <MDEditor
            value={articalFormData?.summary || ""}
            onChange={(e: any) => onChangeSummary(e, "summary")}
            modules={constants.editorModules}
            formats={constants.editorFormats}
          />
        </Grid>
      </Grid>
      <Grid
        container
        sx={{ alignItems: "flex-end", m: { md: "10px 0px 0px", xs: "20px 0px 0px" } }}
      >
        <Grid item xs={12} sm={12} md={4} lg={4} sx={{ p: "0 5px" }}>
          <CustomAutoSearch
            idKey="id"
            nameKey="name"
            fieldLabel="Location"
            apiName="locations"
            responseKey="locations"
            name="location"
            // required
            value={articalFormData.location || null}
            onChange={onChangeAutoComplete}
            error={errors && errors.location}
            usersData={getAllAssociationLocations()}
            createNewLink={
              adminSuperAdmin() && getPermission("locations", "locations_location")
                ? "/locations"
                : ""
            }
          />
        </Grid>

        {adminFranchisie() && (
          <Grid
            item
            xs={12}
            sm={12}
            md={4}
            lg={4}
            sx={{ position: "relative", p: "0 5px", m: { md: "0", xs: "10px 0px" } }}
          >
            <FormControl fullWidth>
              <InputLabel>Display Setting</InputLabel>
              <Select
                className="height45"
                label="Display Setting"
                value={articalFormData?.display_setting || null}
                name="display_setting"
                onChange={onChangeFieldSetting}
                endAdornment={
                  <InputAdornment position="end">
                    <Tooltip
                      title="Select 'Website' to display on the site, 'Both' for site and Location Detail Page. If left unselected, the article won't be visible on the website"
                      arrow
                    >
                      <InfoIcon
                        style={{
                          width: 20,
                          height: 20,
                          cursor: "pointer",
                          color: "rgba(48, 57, 65, 0.3)",
                          marginRight: "5px",
                        }}
                      />
                    </Tooltip>
                  </InputAdornment>
                }
              >
                <MenuItem value="sola_website">Sola Website</MenuItem>
                <MenuItem value="franchising_website">Franchising Website</MenuItem>
                <MenuItem value="both">Both</MenuItem>
              </Select>
            </FormControl>
            <Box sx={{ position: { md: "absolute", xs: "unset" }, left: "5px", mb: "10px" }}>
              <CustomErrorMessage message="Select 'Website' to display on the site, 'Both' for site and Location Detail Page. If left unselected, the article won't be visible on the website" />
            </Box>
          </Grid>
        )}

        <Grid item xs={12} sm={12} md={4} lg={4} sx={{ p: "0 5px" }}>
          <FormControl fullWidth>
            <MDTypography
              component="label"
              variant="button"
              fontWeight="regular"
              color="text"
              display="block"
            >
              Created At
            </MDTypography>
            <MDDatePicker
              options={{
                enableTime: true,
                dateFormat: "M d, Y H:i",
                time_24hr: true,
              }}
              value={articalFormData.created_at}
              onChange={([date]: any) => handleDateChange(date)}
            />
          </FormControl>
        </Grid>
      </Grid>
      <ImagePreview
        imageData={imageToPreview}
        open={isPreview}
        onCloseImage={() => {
          closeImagePreview();
        }}
      />
    </MDDialog>
  );
}

export default AddUpdate;
