import React, { useState } from "react";
import { useAppDispatch } from "store/store";
import moment from "moment";
import MDBox from "components/MDBox";
import { Grid } from "@mui/material";
import Sidenav from "layouts/pages/locations/location-details/general/sidenav";
import BasicInfo from "layouts/pages/locations/location-details/general/basicInfo";
import ContactInfo from "layouts/pages/locations/location-details/general/contactInfo";
import Address from "layouts/pages/locations/location-details/general/address";
import WalkIn from "layouts/pages/locations/location-details/general/walk-in";
import Integrations from "layouts/pages/locations/location-details/general/integrations";
import Owners from "layouts/pages/locations/location-details/general/owners";
import RentManager from "layouts/pages/locations/location-details/general/rent-manager";
import MDButton from "components/MDButton";
import Validations from "helper/validations";
import { updateLocation } from "store/thunk/locationThunk";
import { adminFranchisie, scrollToErrorByClass, systemAdmin } from "helper/services";
import constants from "helper/constants";

function Index(props: any): JSX.Element {
  const { getAccessTypePermission } = props;
  const dispatch = useAppDispatch();
  const { oldLocationDetail, locationDetail, userData } = props;
  const [errors, setErrors] = useState<any>({});

  const onSave = () => {
    const newValue: any = {
      display_general_contact_info: oldLocationDetail?.display_general_contact_info || false,
      directory_update_required: oldLocationDetail?.directory_update_required || false,
      blog_ids: locationDetail?.blogs?.map((objData: any) => objData.id).join(",") || "",
    };
    Object.keys(locationDetail).forEach((key: any) => {
      if (locationDetail[key] !== oldLocationDetail[key]) {
        if (key === "max_walkins_time") {
          newValue[key] = Number(locationDetail[key]);
        } else if (key === "walkins_end_of_day") {
          newValue[key] = moment(locationDetail[key])?.format("HH:mm");
        } else if (typeof locationDetail[key] === "object" && key !== "floorplan_image_url") {
          if (key === "association") {
            newValue[key] = locationDetail?.[key]?.length
              ? locationDetail?.[key].map((obj: any) => obj.id)
              : [];
          } else {
            newValue[key] = locationDetail[key];
          }
        } else newValue[key] = locationDetail[key];
      }
    });

    const error = Validations.validateLocationTabs(newValue, locationDetail, "general");
    setErrors(error);
    if (!Object.keys(error).length) {
      Object.keys(newValue).forEach((key: any) => {
        if (constants?.approvalFieldsGeneral.indexOf(key) !== -1) {
          newValue.directory_update_required = true;
        }
      });

      if (newValue.msas && Array.isArray(newValue.msas)) {
        newValue.msa_ids = newValue.msas.map((item: { id: any }) => item.id);
      }

      const requestData = {
        approveData: JSON.stringify({
          old_value: {
            ...oldLocationDetail,
            msa_ids: oldLocationDetail?.location?.msas?.map((msa: any) => msa?.id) || [],
          },
          new_value: newValue,
        }),
        tabName: "general",
        floorplanImage: locationDetail?.floorplan_image_url,
        deleteFloorPlanImage: locationDetail?.delete_floor_plan_image || "",
        id: locationDetail?.id,
        whoAddedId: Number(userData?.user_id),
      };
      dispatch(updateLocation(requestData));
    } else {
      scrollToErrorByClass(error);
    }
  };

  const attr = {
    ...props,
    errors,
    setErrors,
    onSave,
    userData,
  };

  return (
    <Grid container spacing={3} mt={1}>
      <Grid item xs={12} lg={3}>
        <Sidenav />
      </Grid>
      <Grid item xs={12} lg={9}>
        <MDBox mb={3}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <BasicInfo {...attr} />
            </Grid>
            <Grid item xs={12}>
              <ContactInfo {...attr} />
            </Grid>
            <Grid item xs={12}>
              <Address {...attr} />
            </Grid>
            <Grid item xs={12}>
              <WalkIn {...attr} />
            </Grid>
            <Grid item xs={12}>
              <Integrations {...attr} />
            </Grid>
            {systemAdmin() && (
              <Grid item xs={12}>
                <Owners {...attr} />
              </Grid>
            )}
            {adminFranchisie() && (
              <Grid item xs={12}>
                <RentManager {...attr} />
              </Grid>
            )}
            <Grid item xs={12} display="flex" justifyContent="flex-end">
              {/* <MDBox mr={1}>
                <FormControlLabel
                  control={
                    <Switch
                      checked={locationDetail?.directory_update_required || false}
                      onChange={onChangeRadio}
                      inputProps={{ "aria-label": "controlled" }}
                      name="directory_update_required"
                    />
                  }
                  label="Directory Update Required"
                  labelPlacement="end"
                />
                {errors && errors?.directory_update_required && (
                  <CustomErrorMessage message={errors?.directory_update_required} />
                )}
              </MDBox> */}
              <MDButton
                onClick={onSave}
                variant="gradient"
                color="info"
                size="small"
                className="fixed-button"
                disabled={getAccessTypePermission("location_general", "edit_access")}
              >
                Save
              </MDButton>
            </Grid>
          </Grid>
        </MDBox>
      </Grid>
    </Grid>
  );
}

export default Index;
