import { Typography } from "@mui/material";
import MDBox from "components/MDBox";

interface ScorecardTitleProps {
  title: string;
}

function ScorecardTitle({ title }: ScorecardTitleProps) {
  return (
    <Typography
      variant="body2"
      display="flex"
      alignItems="center"
      sx={{
        padding: "0 0 0 20px",
        background: "#000000",
        height: "40px",
        borderRadius: "5px 5px 5px 5px",
      }}
    >
      <MDBox fontWeight="fontWeightMedium" display="inline" color="white">
        {title}
      </MDBox>
    </Typography>
  );
}

export default ScorecardTitle;
