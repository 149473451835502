import { Card } from "@mui/material";
import MDBox from "components/MDBox";
import MDInput from "components/MDInput";
import MDTypography from "components/MDTypography";
import { blockInvalidChar } from "helper/services";
import moment from "moment";
import TimePicker from "rc-time-picker";
import Select from "react-select";

let timezones: any = moment.tz.zonesForCountry("US");
timezones = timezones.map((val: string) => ({ value: val, label: val }));

function CommunicationDelay(props: any): JSX.Element {
  const {
    crmTabSettings,
    onChangeField,
    onChangeFollowUpField,
    onChangeFollowUpTime,
    onChangeInitialTime,
    getAccessTypePermission,
    errors,
  } = props;

  const followUpItem = (itemKey: number) => (
    <div className="communication-delay-item">
      <MDTypography variant="subtitle2">Send follow-up #{itemKey + 1}</MDTypography>
      <MDInput
        sx={{ width: "100px", marginLeft: 1, marginRight: 1 }}
        variant="standard"
        size="small"
        type="number"
        onChange={(e: any) => onChangeFollowUpField(e, "send_follow_up_days", itemKey)}
        name="send_follow_up_days"
        value={crmTabSettings?.followups?.[itemKey]?.send_follow_up_days || ""}
        inputProps={{
          style: { textAlign: "center" },
          min: 0,
        }}
        onKeyDown={blockInvalidChar}
        disabled={getAccessTypePermission("location_crm", "edit_access")}
      />
      <MDTypography variant="subtitle2">day(s) after initial contact at</MDTypography>
      <TimePicker
        placeholder="Select time..."
        className="marginLeft"
        use12Hours
        minuteStep={5}
        showSecond={false}
        format="hh:mm a"
        inputReadOnly
        name="time"
        value={
          crmTabSettings?.followups?.[itemKey]?.send_follow_up_time
            ? moment(crmTabSettings?.followups?.[itemKey]?.send_follow_up_time)
            : null
        }
        onChange={(e: any) => onChangeFollowUpTime(e, "send_follow_up_time", itemKey)}
      />
    </div>
  );

  const style = {
    control: (base: any) => ({
      ...base,
      height: "auto",
      border: "none",
      boxShadow: "0 !important",
      borderBottom: "1px solid #d2d6da",
      // width: "30%",
      borderRadius: "0px !important",
      textAlign: "left",
      fontSize: 14,
      marginBottom: "10px",
    }),
    menu: (provided: any) => ({
      ...provided,
      zIndex: 99,
      fontSize: 14,
    }),
    valueContainer: (valueContainer: any) => ({
      ...valueContainer,
      padding: "0 0 0 8px",
    }),
  };

  return (
    <Card id="communication-delay" sx={{ overflow: "visible" }}>
      <MDBox p={3} pb={0}>
        <MDTypography variant="h5">Communication Delay</MDTypography>
      </MDBox>
      <MDBox component="form" pb={3} px={3}>
        <MDBox className="container-communication-delay">
          <MDBox display="flex" alignItems="center">
            <MDBox mr={2}>
              <MDTypography variant="subtitle2">Timezone</MDTypography>
            </MDBox>
            <MDBox width="100%" className="time-zone-picker">
              <Select
                menuPlacement="auto"
                placeholder="Select Timezone..."
                options={timezones.filter((val: any) => val.label)}
                onChange={(value: any) => {
                  onChangeField({
                    target: { name: "timezone", value: value ? value.value : null },
                  });
                }}
                value={
                  crmTabSettings?.timezone
                    ? { value: crmTabSettings?.timezone, label: crmTabSettings?.timezone }
                    : ""
                }
                styles={style}
                isClearable
              />
            </MDBox>
            {errors && errors.timezoneError && (
              <MDTypography color="error" fontSize="12px">
                {errors.timezoneError}
              </MDTypography>
            )}
          </MDBox>
          <MDBox className="communication-delay-item">
            <MDTypography variant="subtitle2">Send initial contact</MDTypography>
            <MDInput
              sx={{ width: "100px", marginLeft: 1, marginRight: 1 }}
              size="small"
              variant="standard"
              type="number"
              onChange={onChangeField}
              name="send_initial_contact"
              value={crmTabSettings?.send_initial_contact || ""}
              inputProps={{
                style: { textAlign: "center" },
                min: 0,
              }}
              onKeyDown={blockInvalidChar}
              disabled={getAccessTypePermission("location_crm", "edit_access")}
            />
            <MDTypography variant="subtitle2">minutes after form submission</MDTypography>
          </MDBox>

          <MDBox className="communication-delay-item">
            <MDTypography variant="subtitle2">Only send this first message between </MDTypography>
            <TimePicker
              placeholder="Select time..."
              className="marginLeft top-3"
              use12Hours={false}
              minuteStep={5}
              showSecond={false}
              format="hh:mm a"
              inputReadOnly
              name="send_initial_contact_from"
              value={
                crmTabSettings?.send_initial_contact_from
                  ? moment(crmTabSettings?.send_initial_contact_from).tz(crmTabSettings?.timezone)
                  : null
              }
              onChange={(e: any) => onChangeInitialTime("send_initial_contact_from", e)}
            />
            <MDTypography variant="subtitle2"> &nbsp;and </MDTypography>
            <TimePicker
              placeholder="Select time..."
              className="marginLeft top-3"
              use12Hours={false}
              minuteStep={5}
              showSecond={false}
              format="hh:mm a"
              inputReadOnly
              name="send_initial_contact_to"
              value={
                crmTabSettings?.send_initial_contact_to
                  ? moment(crmTabSettings?.send_initial_contact_to).tz(crmTabSettings?.timezone)
                  : null
              }
              onChange={(e: any) => onChangeInitialTime("send_initial_contact_to", e)}
            />
            {errors && errors.initialTimeInvalid && (
              <MDTypography color="error" fontSize="12px">
                {errors.initialTimeInvalid}
              </MDTypography>
            )}
          </MDBox>
          <MDBox>
            <MDTypography variant="h6" mt={2} mb={2}>
              If lead hasn&apos;t replied...
            </MDTypography>
          </MDBox>
          {crmTabSettings?.followups?.length > 0 &&
            crmTabSettings.followups.map((_: any, key: number) => followUpItem(key))}
          <MDBox className="communication-delay-item" style={{ marginTop: 20 }}>
            <MDTypography variant="subtitle2">Set lead as closed</MDTypography>
            <MDInput
              sx={{ width: "100px", marginLeft: 1, marginRight: 1 }}
              size="small"
              variant="standard"
              type="number"
              onChange={onChangeField}
              name="set_lead_as_closed"
              value={crmTabSettings?.set_lead_as_closed || ""}
              inputProps={{
                style: { textAlign: "center" },
                min: 0,
              }}
              onKeyDown={blockInvalidChar}
              disabled={getAccessTypePermission("location_crm", "edit_access")}
            />
            <MDTypography variant="subtitle2">day(s) after last follow-up is sent</MDTypography>
          </MDBox>
        </MDBox>
      </MDBox>
    </Card>
  );
}

export default CommunicationDelay;
