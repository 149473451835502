import React, { useState, useEffect } from "react";
import MDDialog from "components/MDDialog";
import MDInput from "components/MDInput";
import CustomPhoneInput from "components/CustomPhoneInput";
import Validations from "helper/validations";
import { register, CognitoUpdateUser } from "store/thunk/authThunk";
import { RegisterRequest } from "types/custom-types";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Autocomplete,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Grid,
  InputLabel,
  TextField,
} from "@mui/material";
import "react-phone-input-2/lib/style.css";
import { useAppDispatch, useAppSelector } from "store/store";
import CustomAutoSearch from "components/CustomAutoSearch";
import { getAllAssociationLocations, getPermission, userRoles } from "helper/services";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import { v4 as uuidv4 } from "uuid";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { getRolesList } from "store/thunk/rolesAndMenusThunk";

function AddUpdate(props: any): JSX.Element {
  const dispatch = useAppDispatch();
  const rolesInfo = useAppSelector((state) => state.rolesAndMenusReducer);
  const {
    open,
    onClose,
    editUser,
    pageIndex,
    pageSize,
    search,
    sortBy,
    locationAccessDetails,
    crmAccessDetails,
    accessObject,
  } = props;
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [location, setLocation] = useState(null);

  const [errors, setErrors] = useState<any>({});
  const [loading, setLoading] = useState<boolean>(false);
  const [userDetails, setUserDetails] = useState<any[]>([]);
  const [userAccessDetails, setUserAccessDetails] = useState<any>([]);

  const [rolesList, setRolesList] = useState<any>([]);
  const [selectRole, setSelectRole] = useState<any>(null);

  useEffect(() => {
    if (!editUser) {
      dispatch(getRolesList());
    }
  }, []);

  const onSave = () => {
    const requestData = {
      firstName,
      lastName,
      email,
      phone,
      location,
      user_access: userDetails,
    };
    if (!editUser) {
      Object.assign(requestData, { role: selectRole?.label || "" });
    }
    const error = Validations.validateConnectUserForm(requestData);

    setErrors(error);
    if (!Object.keys(error).length) {
      setLoading(true);
    }
    if (!Object.keys(error).length) {
      setLoading(false);
      if (editUser) {
        // Update API call
        const reqObj: RegisterRequest = {
          userId: editUser?.user_id,
          firstName,
          lastName,
          email,
          phone: phone ? `+${phone.replace(/[- )(+]/g, "")}` : "",
          location,
          userAccess: userDetails,
          isUpdateOther: false,
          pagination: {
            pageIndex,
            pageSize,
            search,
            sort: sortBy,
          },
        };
        dispatch(CognitoUpdateUser(reqObj));
      } else {
        // Insert API call
        const reqObj: RegisterRequest = {
          firstName,
          lastName,
          email,
          phone: phone ? `+${phone.replace(/[- )(+]/g, "")}` : "",
          location,
          role: selectRole?.label || "",
          userAccess: userDetails,
        };
        dispatch(register(reqObj));
      }
      onClose();
    }
  };

  useEffect(() => {
    if (editUser) {
      setFirstName(editUser?.first_name ? editUser.first_name : "");
      setLastName(editUser?.last_name ? editUser.last_name : "");
      setEmail(editUser?.login_email ? editUser.login_email : "");
      setPhone(editUser?.sms_phone ? editUser.sms_phone : "");
      setLocation(editUser?.locations && editUser?.locations?.length ? editUser.locations : null);
      setUserDetails(
        editUser?.connect_location_users && editUser?.connect_location_users.length
          ? editUser?.connect_location_users
          : []
      );

      if (editUser?.locations && editUser?.locations?.length) {
        const arr: any[] = [];
        editUser?.locations?.map((obj: any) => {
          const temp = {
            location: obj,
            user_access: {
              locationAccessDetails,
              crmAccessDetails,
            },
          };
          arr.push(temp);
          return true;
        });
        setUserAccessDetails(arr);
      }
      setSelectRole({ label: editUser?.role_name || "" });
    }
  }, [editUser, locationAccessDetails, crmAccessDetails]);

  useEffect(() => {
    // loop for all roles for displaying role's list
    if (rolesInfo && rolesInfo.roles && rolesInfo.roles?.length) {
      const rolesRows: any = [];

      rolesInfo.roles.map((role: any) => {
        if (role?.name !== userRoles.SOLA_PRO_USER) {
          const roleData = {
            label: role.name,
          };
          rolesRows.push(roleData);
        }
        return true;
      });
      setRolesList(rolesRows);
    }
  }, [rolesInfo]);

  const onChangeRole = (event: any, value: any) => {
    setSelectRole(value);
  };

  const setLocationAccessPermission = (value: any) => {
    if (value.length < userAccessDetails.length) {
      const locationIds = [...new Set(value.map((obj: any) => obj.id))];
      const filteredValue = userAccessDetails.filter((obj: any) =>
        locationIds.includes(obj.location.id)
      );
      setUserAccessDetails(filteredValue);
      const filteredAccessValue = userDetails.filter((obj: any) =>
        locationIds.includes(obj.location_id)
      );
      setUserDetails(filteredAccessValue);
    } else {
      const temp = {
        location: value.at(-1),
        user_access: {
          locationAccessDetails,
          crmAccessDetails,
        },
      };
      setUserAccessDetails([...userAccessDetails, temp]);
      const defaultAccess = accessObject.map((obj: any) => ({
        ...obj,
        location_id: Number(value?.at(-1)?.id),
      }));
      setUserDetails([...userDetails, ...defaultAccess]);
    }
  };

  const onChangeAutoComplete = (name: string, value: any) => {
    setLocationAccessPermission(value);
    setLocation(value);
  };

  const getDefaultCheckedAccess = ({ locationId, menuId, accessName }: any) => {
    const isExists: any = userDetails?.find(
      (obj: any) => obj.menu_id === Number(menuId) && obj.location_id === Number(locationId)
    );
    if (isExists) {
      return isExists?.[accessName] || false;
    }
    return false;
  };

  const handleChangeOnNone = (event: any, menuId: any, locationId: any) => {
    const isExists: any = userDetails?.find(
      (obj: any) => obj.menu_id === Number(menuId) && obj.location_id === Number(locationId)
    );
    if (isExists) {
      const userDetailsIndex: any = userDetails?.findIndex(
        (obj: any) => obj.menu_id === Number(menuId) && obj.location_id === Number(locationId)
      );
      if (event.target.checked) {
        const changedData = {
          view_access: false,
          edit_access: false,
          add_access: false,
          menu_id: Number(menuId),
          location_id: Number(locationId),
          [event.target.name]: event.target.checked,
          none_access: true,
        };
        const details = JSON.parse(JSON.stringify(userDetails));
        details[userDetailsIndex] = { ...details[userDetailsIndex], ...changedData };
        setUserDetails(details);
        // newUserDetails.push(changedData);
        // setUserDetails([...userDetails, ...newUserDetails]);
      }
    } else {
      const changedData = {
        view_access: false,
        edit_access: false,
        add_access: false,
        menu_id: Number(menuId),
        location_id: Number(locationId),
        [event.target.name]: event.target.checked,
        none_access: true,
      };
      const userDetailWithNewOne: any = JSON.parse(JSON.stringify(userDetails));
      userDetailWithNewOne.push(changedData);
      setUserDetails(userDetailWithNewOne);
    }
  };

  const handleChange = (event: any, menuId: any, locationId: any) => {
    const isExists: any = userDetails?.find(
      (obj: any) => obj.menu_id === Number(menuId) && obj.location_id === Number(locationId)
    );
    if (isExists) {
      const userDetailsIndex: any = userDetails?.findIndex(
        (obj: any) => obj.menu_id === Number(menuId) && obj.location_id === Number(locationId)
      );

      const changedData: any = {
        menu_id: Number(menuId),
        location_id: Number(locationId),
        [event.target.name]: event.target.checked,
        none_access: false,
      };
      if (event.target.checked) {
        if (event.target.name === "edit_access") {
          changedData.view_access = true;
        } else if (event.target.name === "add_access") {
          changedData.view_access = true;
          changedData.edit_access = true;
        }
      }
      const details = JSON.parse(JSON.stringify(userDetails));
      details[userDetailsIndex] = { ...details[userDetailsIndex], ...changedData };
      setUserDetails(details);
    } else {
      const changedData: any = {
        menu_id: Number(menuId),
        location_id: Number(locationId),
        [event.target.name]: event.target.checked,
        none_access: false,
      };
      if (event.target.checked) {
        if (event.target.name === "edit_access") {
          changedData.view_access = true;
        } else if (event.target.name === "add_access") {
          changedData.view_access = true;
          changedData.edit_access = true;
        }
      }
      const userDetailWithNewOne: any = JSON.parse(JSON.stringify(userDetails));
      userDetailWithNewOne.push(changedData);
      setUserDetails(userDetailWithNewOne);
    }
  };

  const getCheckboxList = (selectedDetails: any, locationId: any) => {
    const html: any = [];

    selectedDetails?.map((obj: any) => {
      html.push(
        <Grid item xs={12} sm={12} md={12} lg={12} key={uuidv4()}>
          <Grid container>
            <Grid item md={12}>
              <InputLabel sx={{ mb: 1, mt: 1, fontWeight: "bold" }}>{obj?.display_menu}</InputLabel>
            </Grid>
            <Grid item md={12}>
              <FormGroup row>
                <MDBox sx={{ display: "flex" }}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={
                          getDefaultCheckedAccess({
                            locationId,
                            menuId: obj?.menu_id,
                            accessName: "none_access",
                          }) || false
                        }
                        id={obj?.alias}
                        name="none_access"
                        onChange={(e: any) => handleChangeOnNone(e, obj?.menu_id, locationId)}
                      />
                    }
                    label="None"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={
                          getDefaultCheckedAccess({
                            locationId,
                            menuId: obj?.menu_id,
                            accessName: "view_access",
                          }) || false
                        }
                        id={obj?.alias}
                        name="view_access"
                        disabled={getDefaultCheckedAccess({
                          locationId,
                          menuId: obj?.menu_id,
                          accessName: "view_access",
                        })}
                        onChange={(e: any) => handleChange(e, obj?.menu_id, locationId)}
                        sx={{
                          opacity: getDefaultCheckedAccess({
                            locationId,
                            menuId: obj?.menu_id,
                            accessName: "view_access",
                          })
                            ? "0.5"
                            : "",
                        }}
                      />
                    }
                    label="View"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={
                          getDefaultCheckedAccess({
                            locationId,
                            menuId: obj?.menu_id,
                            accessName: "edit_access",
                          }) || false
                        }
                        id={obj?.alias}
                        name="edit_access"
                        disabled={getDefaultCheckedAccess({
                          locationId,
                          menuId: obj?.menu_id,
                          accessName: "add_access",
                        })}
                        onChange={(e: any) => handleChange(e, obj?.menu_id, locationId)}
                        sx={{
                          opacity: getDefaultCheckedAccess({
                            locationId,
                            menuId: obj?.menu_id,
                            accessName: "add_access",
                          })
                            ? "0.5"
                            : "",
                        }}
                      />
                    }
                    label="Edit"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={
                          getDefaultCheckedAccess({
                            locationId,
                            menuId: obj?.menu_id,
                            accessName: "add_access",
                          }) || false
                        }
                        id={obj?.alias}
                        name="add_access"
                        onChange={(e: any) => handleChange(e, obj?.menu_id, locationId)}
                      />
                    }
                    label="Add"
                  />
                </MDBox>
              </FormGroup>
            </Grid>
          </Grid>
        </Grid>
      );
      return true;
    });
    return html;
  };

  const dialogProps = {
    open,
    onClose,
    onSave,
    title: editUser ? "Edit User" : "Add User",
    saveTbtText: editUser && "Update",
    size: "sm",
    loading,
  };

  return (
    <MDDialog {...dialogProps}>
      <MDInput
        label="First Name"
        variant="standard"
        fullWidth
        required
        name="firstName"
        placeholder="First Name"
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => setFirstName(e.target.value)}
        value={firstName}
        error={(errors && errors.firstName) || false}
        helperText={errors && errors.firstName}
      />
      <MDInput
        label="Last Name"
        variant="standard"
        fullWidth
        required
        name="lastName"
        placeholder="Last Name"
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => setLastName(e.target.value)}
        value={lastName}
        error={(errors && errors.lastName) || false}
        helperText={errors && errors.lastName}
        sx={{ mt: 2 }}
      />
      <MDInput
        label="Email"
        variant="standard"
        fullWidth
        required
        name="email"
        placeholder="Email"
        disabled={editUser ? true : false}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => setEmail(e.target.value)}
        value={email}
        error={(errors && errors.email) || false}
        helperText={errors && errors.email}
        sx={{ mt: 2 }}
      />
      <MDInput
        label="Phone"
        variant="standard"
        fullWidth
        name="phone"
        placeholder="(000) 000-0000"
        id="phone-input"
        InputProps={{
          inputComponent: CustomPhoneInput as any,
        }}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => setPhone(e.target.value)}
        value={phone}
        sx={{ mt: 2 }}
      />
      {!editUser && (
        <MDBox sx={{ mt: 2 }}>
          <Autocomplete
            id="roles"
            value={selectRole}
            options={rolesList}
            onChange={(event: any, value: any) => onChangeRole(event, value)}
            renderInput={(params: any) => (
              <TextField
                {...params}
                label="Roles"
                variant="standard"
                required
                error={(errors && errors.role) || false}
                helperText={errors && errors.role}
              />
            )}
          />
        </MDBox>
      )}
      {selectRole?.label !== userRoles.ADMIN && selectRole?.label !== userRoles.SYSTEM_ADMIN && (
        <MDBox sx={{ mt: 3 }}>
          <CustomAutoSearch
            idKey="id"
            nameKey="name"
            fieldLabel="Locations"
            apiName="locations"
            responseKey="locations"
            name="location"
            multiple
            usersData={getAllAssociationLocations()}
            value={location}
            onChange={onChangeAutoComplete}
            createNewLink={getPermission("locations", "locations_location") ? "/locations" : ""}
          />
        </MDBox>
      )}
      <MDBox className="mui-checkbox-group" sx={{ mt: 3 }}>
        {userAccessDetails && userAccessDetails.length > 0 && (
          <MDTypography variant="body2" fontWeight="medium" textTransform="capitalize">
            User Access
          </MDTypography>
        )}
        {userAccessDetails &&
          userAccessDetails.length > 0 &&
          userAccessDetails.map((val: any) => (
            <MDBox sx={{ mt: 2 }}>
              <Accordion>
                <AccordionSummary
                  className="location-accordion"
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="publishbh-content"
                >
                  <MDTypography variant="h6">{val.location.name}</MDTypography>
                </AccordionSummary>
                <AccordionDetails>
                  <Accordion expanded>
                    <AccordionSummary
                      className="location-accordion"
                      aria-controls="publishbh-content"
                    >
                      <MDTypography variant="h6">Location</MDTypography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Grid container>
                        {getCheckboxList(
                          val?.user_access?.locationAccessDetails || [],
                          val?.location?.id
                        )}
                      </Grid>
                    </AccordionDetails>
                  </Accordion>
                  <Accordion expanded>
                    <AccordionSummary
                      className="location-accordion"
                      aria-controls="publishbh-content"
                    >
                      <MDTypography variant="h6">CRM</MDTypography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Grid container>
                        {getCheckboxList(
                          val?.user_access?.crmAccessDetails || [],
                          val?.location?.id
                        )}
                      </Grid>
                    </AccordionDetails>
                  </Accordion>
                </AccordionDetails>
              </Accordion>
            </MDBox>
          ))}
      </MDBox>
    </MDDialog>
  );
}

export default AddUpdate;
