import { useState, useEffect } from "react";
import { Grid } from "@mui/material";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import CustomBackButton from "components/CustomBackButton";
import NumberStatus from "layouts/pages/repair-maintenance/phone-numbers/numberStatus";
import SmsStatus from "layouts/pages/repair-maintenance/phone-numbers/smsStatus";
import Locations from "layouts/pages/repair-maintenance/phone-numbers/locations";
import CallTreeStatus from "layouts/pages/repair-maintenance/phone-numbers/callTreeStatus";
import DasicInfo from "layouts/pages/repair-maintenance/phone-numbers/basicInfo";
import Sidenav from "layouts/pages/repair-maintenance/phone-numbers/sidenav";
import { useAppSelector, useAppDispatch } from "store/store";
import { getRMPhoneDetailById, updateRMTwillioPhoneSetting } from "store/thunk/repairMaintenance";
import { setPhoneNumberDetails } from "store/slices/repairMaintenanceSlice";
import { useLocation, useNavigate } from "react-router-dom";
import Validation from "helper/validations";
import { scrollToErrorByClass } from "helper/services";

const sideMenu = [
  { icon: "description", label: "Basic Info", href: "basic-info" },
  { icon: "phone", label: "Number Status", href: "number-status" },
  { icon: "sms", label: "SMS Status", href: "sms-status" },
  { icon: "receipt_long", label: "Locations", href: "locations" },
  { icon: "ring_volume", label: "Call Forwarding", href: "call-tree-status" },
];

function phoneNumberDetail(): JSX.Element {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const phoneState: any = useLocation();
  const [phoneDetail, setPhoneDetail] = useState<any>({});
  const { phoneNumberDetails, phoneTreeSettingDetails } = useAppSelector(
    (state) => state.repairMaintenanceSlice
  );
  const [sidenavItems] = useState<any>(sideMenu);
  const [errors, setErrors] = useState<any>({});
  const onChangeField = (event: any) => {
    setPhoneDetail({ ...phoneDetail, [event.target.name]: event.target.checked });
  };

  const onCheckField = (event: any) => {
    setPhoneDetail({ ...phoneDetail, [event.target.name]: event.target.checked });
  };

  const onChangeTextField = (event: any) => {
    setPhoneDetail({ ...phoneDetail, [event.target.name]: event.target.value });
  };

  useEffect(() => {
    const clearPhoneDetails = () => {
      dispatch(setPhoneNumberDetails(null));
    };

    return () => clearPhoneDetails();
  }, []);

  useEffect(() => {
    if (phoneDetail?.call_tree_status) {
      setPhoneDetail({
        ...phoneDetail,
        use_pre_recorded_instructions: phoneDetail?.use_pre_recorded_instructions || true,
      });
    }

    if (phoneTreeSettingDetails) {
      setPhoneDetail({
        ...phoneDetail,
        ...phoneTreeSettingDetails,
        id: phoneDetail.id,
        tree_setting_id: phoneTreeSettingDetails.id,
      });
    }
  }, [phoneTreeSettingDetails]);

  useEffect(() => {
    if (phoneState?.state?.phoneId) {
      dispatch(getRMPhoneDetailById({ id: phoneState?.state?.phoneId }));
    } else {
      navigate(`/r&m/phone-numbers`);
    }
  }, [phoneState]);

  useEffect(() => {
    if (phoneNumberDetails && Object.keys(phoneNumberDetails)?.length) {
      setPhoneDetail(phoneNumberDetails);
    }
  }, [phoneNumberDetails]);

  const updateSettings = () => {
    const error = Validation.validateUpdateRMPhoneNumber(phoneDetail);
    setErrors(error);
    if (!Object.keys(error).length) {
      let status = phoneDetail?.status;
      if (status === true || status === false) {
        if (status) {
          status = "enabled";
        } else {
          status = "disabled";
        }
      }
      const req = {
        id: phoneDetail?.id,
        sms_status: phoneDetail?.sms_status,
        number_name: phoneDetail?.number_name,
        number_description: phoneDetail?.number_description,
        status,
        forwarding_phone_number:
          phoneDetail?.forwarding_phone_number && phoneDetail?.forwarding_phone_number !== "+1 ("
            ? phoneDetail?.forwarding_phone_number
            : "",
      };
      dispatch(updateRMTwillioPhoneSetting(req));
    } else {
      scrollToErrorByClass(error);
    }
  };

  const attr = {
    phoneDetail,
    onChangeField,
    onChangeTextField,
    setPhoneDetail,
    onCheckField,
    sidenavItems,
    errors,
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox mx={2}>
        <Grid container spacing={3} sx={{ display: "flex", justifyContent: "space-between" }}>
          <Grid item>
            <CustomBackButton
              label="Back To Phone Numbers"
              historyState={{ prevPath: "/r&m/phone-numbers", defaultTab: 1 }}
            />
          </Grid>
        </Grid>
      </MDBox>
      <MDBox pb={2}>
        <Grid container spacing={3} mt={1}>
          <Grid item xs={12} lg={3}>
            <Sidenav {...attr} />
          </Grid>
          <Grid item xs={12} lg={9}>
            <MDBox mb={3}>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <DasicInfo {...attr} />
                </Grid>
                <Grid item xs={12}>
                  <NumberStatus {...attr} />
                </Grid>
                <Grid item xs={12}>
                  <SmsStatus {...attr} />
                </Grid>
                <Grid item xs={12}>
                  <Locations {...attr} />
                </Grid>
                <Grid item xs={12}>
                  <CallTreeStatus {...attr} />
                </Grid>
                <Grid item xs={12} sm={12} display="flex" justifyContent="end">
                  <MDButton
                    variant="gradient"
                    color="info"
                    size="small"
                    onClick={updateSettings}
                    className="fixed-button"
                  >
                    Update Settings
                  </MDButton>
                </Grid>
              </Grid>
            </MDBox>
          </Grid>
        </Grid>
      </MDBox>
    </DashboardLayout>
  );
}

export default phoneNumberDetail;
