import MDDialog from "components/MDDialog";
import { useEffect, useState } from "react";
import Card from "@mui/material/Card";
import MDBox from "components/MDBox";
import Grid from "@mui/material/Grid";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import CustomDropZone from "components/CustomDropZone";
import MDEditor from "components/MDEditor";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import CustomAutoSearch from "components/CustomAutoSearch";
import MDIconButton from "components/MDIconButton";
import Icon from "@mui/material/Icon";
import Validations from "helper/validations";
import constants from "helper/constants";
import { getPermission } from "helper/services";
import CustomErrorMessage from "components/CustomErrorMessage";

function AddUpdate(props: any): JSX.Element {
  const { open, onClose, blog, onCreateUpdate } = props;

  const [imagePreview, setImagePreview] = useState<any>("");
  const [thumbPreview, setThumbPreview] = useState<any>("");
  const [errors, setErrors] = useState<any>({});

  const editorValue = `<p>
      <br></br>
      <br></br>
      <br></br>
      <br></br>
    </p>`;

  const [blogData, setblogData] = useState<any>(
    blog || {
      kind: null,
      country: null,
      url: "",
      title: "",
      thumbnail: "",
      image: "",
      body: editorValue,
      summary: "",
      author: "",
      category: [],
      tag: [],
      delete_image: false,
      delete_thumbnail: false,
    }
  );

  useEffect(() => {
    let countries: any = null;
    let kind = "";
    if (blog.country_id) {
      countries = { id: blog.country_id, name: blog?.country_name };
    }
    if (blog.kind) {
      kind = blog.kind === "blog" ? "0" : "1";
    }
    setblogData({ ...blogData, countries, kind });
    setImagePreview(blog.image_url);
    setThumbPreview(blog.thumbnail_url);
  }, [blog]);

  const onChangeAutoComplete = (name: string, value: any) => {
    setblogData({ ...blogData, [name]: value });
  };

  const onChangeField = (event: any) => {
    setblogData({ ...blogData, [event.target.name]: event.target.value });
  };

  const onChangeEditor = (data: any) => {
    setblogData({ ...blogData, body: data });
  };

  const onChangeImage = async (files: any, type: any) => {
    if (files[0]) {
      setblogData({ ...blogData, [type]: files[0], delete_image: false });
      const file = files[0];
      const reader = new FileReader();
      reader.onload = function (e) {
        if (type === "image") {
          setImagePreview(e.target.result);
        }
      };
      reader.readAsDataURL(file);
    }
  };

  const onChangeThumb = async (files: any, type: any) => {
    if (files[0]) {
      setblogData({ ...blogData, [type]: files[0], delete_thumbnail: false });
      const file = files[0];
      const reader = new FileReader();
      reader.onload = function (e) {
        setThumbPreview(e.target.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const onImageRemove = async (e: any, type: any) => {
    e.stopPropagation();
    setblogData({ ...blogData, [type]: null, delete_image: true });
    setImagePreview("");
  };

  const onThumbRemove = async (e: any, type: any) => {
    e.stopPropagation();
    setblogData({ ...blogData, [type]: null, delete_thumbnail: true });
    if (type === "thumbnail") {
      setThumbPreview("");
    }
  };

  const onSave = () => {
    const error = Validations.validateFranchiseeBlogForm(blogData);
    setErrors(error);
    if (!Object.keys(error).length) {
      if (blog?.id) {
        onCreateUpdate({ ...blogData }, true);
      } else {
        onCreateUpdate({ ...blogData }, false);
      }
    }
  };

  const dialogProps = {
    open,
    onClose,
    onSave,
    saveTbtText: blog && blog?.id ? "Update" : "Save",
    title: `${blog && blog?.id ? "Update" : "Add"} Franchisee Press & Blog`,
    size: "xl",
    saveBtn: true,
  };
  return (
    <MDDialog {...dialogProps}>
      <Card>
        <MDBox p={2}>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6} md={6}>
              <FormControl fullWidth sx={{ marginTop: 2 }}>
                <InputLabel id="default-bank-label">
                  Type<span className="required_star">*</span>
                </InputLabel>
                <Select
                  id="type"
                  label="Type"
                  value={blogData?.kind || ""}
                  name="kind"
                  onChange={onChangeField}
                  variant="standard"
                >
                  <MenuItem value="0">blog</MenuItem>
                  <MenuItem value="1">press</MenuItem>
                </Select>
              </FormControl>
              {errors && <CustomErrorMessage message={errors.kind} />}
            </Grid>

            <Grid item xs={12} sm={6} md={6}>
              <CustomAutoSearch
                idKey="id"
                nameKey="name"
                fieldLabel="Country"
                apiName="countries"
                responseKey="countries"
                name="countries"
                value={blogData?.countries || null}
                onChange={onChangeAutoComplete}
                error={errors && errors.countries}
                required
              />
            </Grid>

            <Grid item xs={12} sm={6} md={6}>
              <MDInput
                label="Url"
                variant="standard"
                fullWidth
                name="url"
                placeholder="Url"
                value={blogData?.url || ""}
                onChange={onChangeField}
                error={(errors && errors.url) || false}
                helperText={errors && errors.url}
                required
              />
            </Grid>

            <Grid item xs={12} sm={6} md={6}>
              <MDInput
                label="Title"
                variant="standard"
                fullWidth
                required
                name="title"
                placeholder="Title"
                value={blogData?.title || ""}
                onChange={onChangeField}
                error={(errors && errors.title) || false}
                helperText={errors && errors.title}
              />
            </Grid>

            <Grid item xs={12} sm={12} md={6} lg={6}>
              <MDTypography variant="subtitle">Thumbnail</MDTypography>
              <CustomDropZone
                multipleUpload={false}
                acceptedFiles={{
                  "image/*": [".gif", ".png", ".jpg", ".jpeg"],
                }}
                onDrop={(e: any) => onChangeThumb(e, "thumbnail")}
              >
                Drop your image here, or Browse
              </CustomDropZone>
              {thumbPreview && (
                <Grid
                  // onClick={() => previewImage(imagePreview)}
                  container
                  justifyContent="center"
                  alignItems="center"
                  display="flex"
                  mt={2}
                >
                  <Grid item className="imageWithDeleteIcon">
                    <img height={100} width={100} src={thumbPreview} alt="article" />
                    <MDIconButton
                      tooltipName="Delete"
                      aria-label="Delete"
                      className="removeBtnClass"
                      onClick={(e: any) => onThumbRemove(e, "thumbnail")}
                    >
                      <Icon fontSize="small">close</Icon>
                    </MDIconButton>
                  </Grid>
                </Grid>
              )}
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <MDTypography variant="subtitle">Image</MDTypography>
              <CustomDropZone
                multipleUpload={false}
                acceptedFiles={{
                  "image/*": [".gif", ".png", ".jpg", ".jpeg"],
                }}
                onDrop={(e: any) => onChangeImage(e, "image")}
              >
                Drop your image here, or Browse
              </CustomDropZone>
              {imagePreview && (
                <Grid
                  // onClick={() => previewImage(imagePreview)}
                  container
                  justifyContent="center"
                  alignItems="center"
                  display="flex"
                  mt={2}
                >
                  <Grid item className="imageWithDeleteIcon">
                    <img height={100} width={100} src={imagePreview} alt="article" />
                    <MDIconButton
                      tooltipName="Delete"
                      aria-label="Delete"
                      className="removeBtnClass"
                      onClick={(e: any) => onImageRemove(e, "image")}
                    >
                      <Icon fontSize="small">close</Icon>
                    </MDIconButton>
                  </Grid>
                </Grid>
              )}
            </Grid>

            <Grid item xs={12}>
              <MDTypography variant="subtitle">
                Body<span className="required_star">*</span>
              </MDTypography>
              <MDEditor
                value={blogData?.body || ""}
                onChange={onChangeEditor}
                modules={constants.editorModules}
                formats={constants.editorFormats}
              />
              {errors && errors.body && (
                <MDTypography style={{ fontSize: "0.85rem", color: "#db3131", opacity: "0.7" }}>
                  {errors.body}
                </MDTypography>
              )}
            </Grid>

            <Grid item xs={12} sm={6} md={6}>
              <MDInput
                multiline
                rows="4"
                label="Summary"
                fullWidth
                required
                varient="standard"
                name="summary"
                placeholder="Summary"
                onChange={onChangeField}
                value={blogData?.summary || ""}
                error={(errors && errors.summary) || false}
                helperText={errors && errors.summary}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
              <MDInput
                multiline
                rows="4"
                label="Author"
                variant="outlined"
                fullWidth
                name="author"
                placeholder="Author"
                onChange={onChangeField}
                value={blogData?.author || ""}
              />
            </Grid>

            <Grid item xs={12} sm={12} md={6}>
              <CustomAutoSearch
                idKey="id"
                nameKey="name"
                fieldLabel="Categories"
                apiName="categories"
                responseKey="categories"
                name="category"
                value={blogData?.category || []}
                onChange={onChangeAutoComplete}
                multiple
                createNewLink={
                  getPermission("sola_website", "sola_website_categories")
                    ? "/sola-website/categories"
                    : ""
                }
              />
            </Grid>

            <Grid item xs={12} sm={12} md={6}>
              <CustomAutoSearch
                idKey="id"
                nameKey="name"
                fieldLabel="Tags"
                apiName="tags"
                responseKey="tags"
                name="tag"
                value={blogData?.tag || []}
                onChange={onChangeAutoComplete}
                multiple
                createNewLink={
                  getPermission("sola_website", "sola_website_tags") ? "/sola-website/tags" : ""
                }
              />
            </Grid>
          </Grid>
        </MDBox>
      </Card>
    </MDDialog>
  );
}

export default AddUpdate;
