import { Card, Grid, Icon } from "@mui/material";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import DataTable from "examples/Tables/DataTable";
import { useState } from "react";
import MDDialog from "components/MDDialog";
import moment from "moment";
import Blogsdata from "components/CustomAutoSearch/Blogsdata";
import { removeLocationBlogs, saveLocationBlogs } from "store/thunk/locationThunk";
import { useAppDispatch, useAppSelector } from "store/store";
import MDIconButton from "components/MDIconButton";
import Messages from "helper/messages";

function Blogs(props: any): JSX.Element {
  const { locationDetail } = props;
  const { connectLocation } = useAppSelector((state) => state.locationSlice);
  const [blogsPopup, setBlogsPopup] = useState(false);
  const [blogsRemovePopup, setBlogsRemovePopup] = useState(false);
  const [deleteBlogId, setDeleteBlogId] = useState(null);
  const [selectedBlogs, setSelectedBlogs] = useState<any>("");
  const dispatch = useAppDispatch();

  const removeBlogs = (id: any) => {
    setDeleteBlogId(id);
    setBlogsRemovePopup(true);
  };

  const actionButtons = (obj: any) => (
    <MDIconButton
      tooltipName="Delete"
      aria-label="Delete"
      color="error"
      onClick={() => removeBlogs(obj?.id)}
    >
      <Icon fontSize="small">delete</Icon>
    </MDIconButton>
  );

  const getRows = () => {
    const data: any = connectLocation?.location_blogs?.map((obj: any) => ({
      id: obj?.id,
      title: obj?.title || "-",
      url_name: obj?.url_name || "-",
      created_at: moment(obj?.created_at).format("YYYY-MM-DD") || "-",
      action: actionButtons(obj),
    }));
    return data;
  };

  const tableData = {
    columns: [
      { Header: "Id", accessor: "id", export: true },
      { Header: "Title", accessor: "title", export: true },
      { Header: "URL Name", accessor: "url_name", export: true },
      { Header: "Created At", accessor: "created_at", export: true },
      { Header: "Action", accessor: "action", export: false, disableSortBy: true },
    ],
    rows: getRows(),
  };

  const onClose = () => {
    setBlogsPopup(false);
    setSelectedBlogs("");
  };

  const onAddBlogs = () => {
    const requestObj: any = {
      location_id: locationDetail?.cms_location_id,
      blog_id: selectedBlogs.value.id,
      onClose,
    };
    dispatch(saveLocationBlogs(requestObj));
  };

  const blogsDialog = {
    open: blogsPopup,
    onClose,
    onSave: onAddBlogs,
    title: "Add Blog",
    size: "md",
    saveBtn: true,
    overflowVisible: "overflowVisible",
    saveBtnDisabled: !selectedBlogs,
  };

  const onchangeBlogs = (name: any, value: any) => {
    setSelectedBlogs(value);
  };

  const onDelete = () => {
    const sendData = {
      location_id: locationDetail?.cms_location_id,
      blog_id: deleteBlogId,
      setBlogsRemovePopup,
    };
    setDeleteBlogId("");
    dispatch(removeLocationBlogs(sendData));
  };

  const deleteDialogProps = {
    open: deleteBlogId ? true : false,
    onClose: () => setBlogsRemovePopup(false),
    onSave: () => onDelete(),
    title: "Delete Blog",
    size: "sm",
    saveBtn: false,
    deleteBtn: true,
    saveTbtText: "Yes",
    closeBtnText: "No",
  };

  return (
    <Card id="blogs" sx={{ overflow: "visible" }}>
      <MDBox p={3} sx={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
        <MDTypography variant="h5">Blogs</MDTypography>
        <MDButton
          className="xs-small"
          variant="gradient"
          color="light"
          size="small"
          onClick={() => setBlogsPopup(true)}
          style={{ background: "#1662c4", color: "white" }}
        >
          <Icon sx={{ fontWeight: "bold" }}>add</Icon> Add New
        </MDButton>
      </MDBox>

      <MDBox mt={7} sx={{ mt: "auto" }}>
        <DataTable table={tableData} canSearch searchText="Search..." />
        {blogsPopup && (
          <MDDialog {...blogsDialog}>
            <Grid container spacing={2} mt={1}>
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <Blogsdata
                  idKey="id"
                  nameKey="title"
                  fieldLabel="Blog"
                  apiName="blogs"
                  responseKey="blogs"
                  name="blogs"
                  required
                  value={selectedBlogs || null}
                  onChange={onchangeBlogs}
                />
              </Grid>
            </Grid>
          </MDDialog>
        )}
      </MDBox>
      {blogsRemovePopup && (
        <MDDialog {...deleteDialogProps}>
          <MDTypography variant="h6" fontWeight="medium">
            {Messages.GENERAL.SURE_TO_DELETE}
          </MDTypography>
        </MDDialog>
      )}
    </Card>
  );
}

export default Blogs;
