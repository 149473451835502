import { Grid, Paper, Typography } from "@mui/material";
import CustomAutoSearch from "components/CustomAutoSearch";
import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "store/store";
import { setleaseApplicationSteps } from "store/slices/leaseSlice";
import MDBox from "components/MDBox";
import { experimentalStyled as styled } from "@mui/material/styles";
import { getLeadByProfessionalDetails } from "store/thunk/leaseThunk";
import HandleButton from "./handleButton";
import NotFoundRecord from "./notFoundRecord";

function AddExistingProfessional(props: any): JSX.Element {
  const { errors, notFoundDeal, setNotFoundDeal, showDeals, setShowDeals } = props;
  const [showAddButton, setShowAddButton] = useState<boolean>(false);
  const { leaseApplicationSteps, existingDeal } = useAppSelector((state) => state.leaseSlice);
  const [selectedItemId, setSelectedItemId] = useState<string | null>(null);
  const dispatch = useAppDispatch();

  useEffect(() => {}, [existingDeal]);
  useEffect(() => {
    if (leaseApplicationSteps?.deal?.id) {
      setSelectedItemId(leaseApplicationSteps?.deal?.id);
    }
  }, []);
  const getRsp = (mainResp: any) => {
    if (!mainResp?.length) {
      setShowAddButton(true);
      dispatch(setleaseApplicationSteps({ ...leaseApplicationSteps, professional: "" }));
    }
  };

  // onChangeAutoComplete for deal and professional
  const onChangeAutoComplete = (name: string, value: any) => {
    setSelectedItemId("");
    if (notFoundDeal) {
      setNotFoundDeal(false);
    }
    if (showAddButton) {
      setShowAddButton(false);
    }
    const sendData = {
      user_id: value?.user_id,
      setShowDeals,
    };
    dispatch(getLeadByProfessionalDetails(sendData));
    dispatch(setleaseApplicationSteps({ ...leaseApplicationSteps, [name]: value }));
  };

  const selectedDeal = (selctedDeal: any, setSelectedItemId: Function, dealName: any) => {
    setSelectedItemId(selctedDeal);
    dispatch(
      setleaseApplicationSteps({
        ...leaseApplicationSteps,
        deal: { id: selctedDeal, deal_name: dealName },
      })
    );
  };

  const Item = styled(Paper)(({ theme }) => ({
    ...theme.typography.body2,
    padding: "20px",
    margin: "10px",
    textAlign: "center",
  }));

  const existingItem = (items: any) => (
    <Grid item xs={12} sm={12} md={4}>
      <Item
        sx={{
          position: "relative",
          backgroundColor: selectedItemId === items.id ? "#1A73E8" : "white",
          cursor: "pointer",
        }}
        onClick={() => selectedDeal(items.id, setSelectedItemId, items?.deal_name)}
      >
        <Typography
          sx={{ fontSize: "13px" }}
          color={selectedItemId === items.id ? "#fff" : "#000"}
          variant="h3"
        >
          {items?.deal_name}
        </Typography>
      </Item>{" "}
    </Grid>
  );

  return (
    <>
      <Grid item xs={12} sm={12} md={6} lg={6} sx={{ margin: "20px auto" }}>
        <CustomAutoSearch
          slsAPI
          idKey="user_id"
          nameKey="first_name"
          fieldLabel="Search Existing Professional"
          apiName="get_users_by_roles"
          responseKey="users"
          name="professional"
          required
          value={leaseApplicationSteps?.professional || ""}
          onChange={onChangeAutoComplete}
          additionaFields={{ role_name: "Sola Pro" }}
          error={errors && errors.professional}
          responseCallback={(result: any) => getRsp(result)}
        />
        {(showAddButton || notFoundDeal) && (
          <NotFoundRecord type={notFoundDeal ? "Deal" : "User"} />
        )}
      </Grid>

      {showDeals && (
        <MDBox sx={{ height: "79px", overflow: "auto" }}>
          <Grid container>
            {existingDeal?.connect_deals?.length > 0 ? (
              existingDeal?.connect_deals?.map((item: any) => existingItem(item))
            ) : (
              <NotFoundRecord type="Deal" setShowNotFound={setShowDeals} />
            )}
          </Grid>
        </MDBox>
      )}
      {selectedItemId !== "" && <HandleButton {...props} />}
    </>
  );
}

export default AddExistingProfessional;
