import { useState } from "react";
import MDDialog from "components/MDDialog";
import MDInput from "components/MDInput";
import Messages from "helper/messages";
import MDBox from "components/MDBox";
import { Grid, Icon, FormControl, Autocomplete, CircularProgress } from "@mui/material";
import { useAppSelector, useAppDispatch } from "store/store";
import MDIconButton from "components/MDIconButton";
import CustomAutoSearch from "components/CustomAutoSearch";
import { sendBulkSms } from "store/thunk/locationThunk";
import { notificationFail } from "store/slices/notificationSlice";
import MDTypography from "components/MDTypography";
import CustomDropZone from "components/CustomDropZone";
import fileimg from "assets/images/file-image.svg";
import {
  toBase64,
  getAccessLocationPermission,
  compressImage,
  fileSizeInBytes,
} from "helper/services";
import constants from "helper/constants";
import CustomChipList from "components/CustomChipList";

function createBroacastPopup(props: any): JSX.Element {
  const dispatch = useAppDispatch();
  const { userData } = useAppSelector((state: any) => state.authReducer);
  const { open, onClose, allLeads, selectedDeals, clearBroadcastArray } = props;
  const [errors, setErrors] = useState<any>({});
  const [textMsg, setTextMsg] = useState("");
  const [selectedNumber, setSelectedNumber] = useState<any>(null);
  const [filteredDeals, setFilteredDeals] = useState<any>([]);
  const [confirmSend, setConfirmSend] = useState(false);
  const [isFileUpload, setIsFileUpload] = useState(false);
  const [attachments, setAttachments] = useState<any>([]);
  const [failedDeals, setFailedDeals] = useState<any>([]);

  const [token, setToken] = useState("");
  const [tokenOpen, setTokenOpen] = useState(false);
  const [fileLoader, setFileLoader] = useState<any>(false);

  const addToken = (event: any, value: any) => {
    if (value?.value) {
      setToken("");
      setTokenOpen(false);
      const ctrl: any = document.getElementById("templateContentArea");

      const templateData = textMsg || "";
      const newTemplateContent = `${templateData.slice(0, ctrl.selectionStart)} {{${
        value?.value
      }}} ${templateData.slice(ctrl.selectionStart)}`;

      setTextMsg(newTemplateContent);
    }
  };

  const onSend = () => {
    const errors: any = {};
    if (attachments?.length > 3) {
      dispatch(notificationFail("Maximum 3 attachments allowed"));
      return;
    }
    // check attachments size
    let totalBites = 0;
    for (let i = 0; i < attachments.length; i++) {
      totalBites += fileSizeInBytes(attachments[i]);
    }
    const fileSizeMB = totalBites / 1024 ** 2;
    if (fileSizeMB > 5) {
      dispatch(notificationFail("Max attachments size should be upto 5 MB."));
      return;
    }
    if (!selectedNumber) {
      errors.phone = Messages.ERROR.FIELD_REQUIRED;
    }
    if (!textMsg) {
      errors.textMsg = Messages.ERROR.FIELD_REQUIRED;
    }
    setErrors(errors);
    if (!Object.keys(errors).length) {
      const tempDeals: any = [];
      if (allLeads?.leads?.length > 0) {
        selectedDeals.every((elem: any) =>
          tempDeals.push(allLeads?.leads.find((dl: any) => dl.id === elem))
        );
        setFilteredDeals(tempDeals);
        setConfirmSend(true);
      }
    }
  };
  const onChangeAutoComplete = (name: string, value: any) => {
    setSelectedNumber(value);
  };

  const dialogProps = {
    open,
    onSave: () => onSend(),
    onClose,
    title: "Create Broadcast",
    size: "lg",
    saveTbtText: "Send",
  };

  const onCloseConfirmDialog = () => {
    setConfirmSend(false);
  };

  const onAttachFile = async (files: any) => {
    const filesArray: any = attachments?.length ? attachments : [];
    if (files.length > 0) {
      /* eslint-disable no-await-in-loop */
      for (let i = 0; i < files.length; i++) {
        setFileLoader(true);
        toBase64(
          constants?.imageExtentionArray.includes(files[i]?.path?.split(".").pop())
            ? await compressImage(files[i])
            : files[i]
        ).then((base64String: any) => {
          if (base64String) {
            filesArray.push(base64String);
            setFileLoader(false);
          }
        });
      }
      setAttachments(filesArray);
    }
  };

  const onCallback = (data: any) => {
    if (data?.messageError?.length) {
      setFailedDeals(data?.messageError);
    } else {
      onClose();
      clearBroadcastArray([]);
    }
  };

  const onSendBroadCast = () => {
    const newDealObj = filteredDeals.map((val: any) => ({
      deal_id: val?.id,
      location_id: val?.location_id,
      contact_id: val?.connect_contact?.id,
      deal_name: val?.deal_name,
    }));

    let fromNumber = "";
    if (selectedNumber?.twillio_phone_number) {
      const tempNo = selectedNumber.twillio_phone_number.substr(
        selectedNumber.twillio_phone_number.length - 15
      );
      fromNumber = tempNo
        .trim()
        .replace(/[^a-zA-Z0-9 ]/g, "")
        .replace(/ /g, "");
    }
    const req = {
      broadCastData: {
        deals_array: newDealObj,
        from_no: `+1${fromNumber}`,
        attachments,
        sender_user_id: userData?.user_id,
        message_content: textMsg,
      },
      onCloseConfirmDialog,
      onCallback,
    };
    dispatch(sendBulkSms(req));
  };

  const deleteDialogProps = {
    open: confirmSend,
    onClose: () => onCloseConfirmDialog(),
    onSave: () => onSendBroadCast(),
    title: "Confirm broadcast send",
    size: "sm",
    saveBtn: true,
    saveTbtText: "Send",
    closeBtnText: "No",
  };

  const onChangeMessage = (e: any) => {
    if (e.target.value?.length <= 1500) {
      setTextMsg(e.target.value);
    }
  };

  return (
    <MDDialog {...dialogProps}>
      <Grid item xs={12} sm={12} md={12} lg={12} sx={{ padding: "0px 12px" }}>
        <MDBox className="msg-input">
          <Grid container>
            <Grid item xs={6} sm={6} md={6} lg={6} mb={5}>
              <CustomAutoSearch
                idKey="id"
                nameKey="twillio_phone_number"
                fieldLabel="Select Phone Numbers"
                apiName="get-all-twillio-numbers-list"
                responseKey="connect_twillio_phone_numbers"
                name="twillio_phone_number"
                required
                slsAPI
                value={selectedNumber || null}
                onChange={onChangeAutoComplete}
                error={errors && errors.phone}
                usersData={{
                  sms_status: true,
                  status: "enabled",
                  get_location_by_ids: getAccessLocationPermission(
                    "crm",
                    "crm_deals",
                    "",
                    "view_access"
                  ).get_location_by_ids, // SD-3070 change related permission
                }}
                additionalLabel="number_name"
                additionalLabelDevider="|"
              />
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <MDInput
                multiline
                rows={3}
                value={textMsg}
                className="msg-input-box"
                id="templateContentArea"
                placeholder="Type Message...(Up to 1500 characters)"
                sx={{ width: "100%", height: "100%" }}
                onChange={onChangeMessage}
                onClick={() => setTokenOpen(false)}
                error={errors && errors.textMsg}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12} className="msg-panel">
              <MDBox display="flex">
                <MDIconButton
                  tooltipName="Add Token"
                  type="button"
                  aria-label="Add Token"
                  sx={{ color: "#0b8cfb" }}
                  onClick={() => setTokenOpen(!tokenOpen)}
                >
                  <Icon fontSize="small">add</Icon>
                </MDIconButton>
              </MDBox>
              {tokenOpen && (
                <MDBox className="placeholder-broadcast-sms-dorpdown-wrapper">
                  <FormControl fullWidth className="placeholder-broadcast-sms-dorpdown">
                    <Autocomplete
                      onChange={addToken}
                      value={token || null}
                      options={constants.smsTokens}
                      groupBy={(option) => option.table_name}
                      getOptionLabel={(option: any) => option.label}
                      renderGroup={(params: any) => (
                        <li {...params}>
                          <MDTypography
                            sx={{ pl: 1 }}
                            variant="subtitle2"
                            className="font-bold text-capitalize"
                          >
                            {params.group}
                          </MDTypography>
                          <MDTypography>{params.children}</MDTypography>
                        </li>
                      )}
                      renderOption={(props, data) => (
                        <MDTypography key={data.value} {...props} variant="subtitle2">
                          {data.label}
                        </MDTypography>
                      )}
                      renderInput={(params) => <MDInput {...params} label="Select Token" />}
                    />
                  </FormControl>
                </MDBox>
              )}
              <MDIconButton
                tooltipName="Attach file"
                type="button"
                aria-label="Attach file"
                sx={{ color: "#0b8cfb" }}
                onClick={() => {
                  setIsFileUpload(true);
                }}
              >
                <Icon fontSize="small">description_icon</Icon>
              </MDIconButton>
            </Grid>
            {attachments.map((item: any, index: number) => (
              <Grid mt={5}>
                <MDBox
                  item
                  className="imageWithDeleteIcon"
                  display="inline-block"
                  sx={{ marginRight: "15px !important" }}
                >
                  <img src={fileimg} alt={item} width={25} />
                  <MDIconButton
                    tooltipName="Delete"
                    aria-label="Delete"
                    className="removeBtnClass"
                    onClick={() =>
                      setAttachments(attachments?.filter((obj: any, i: number) => index !== i))
                    }
                    sx={{ height: "15px", width: "15px" }}
                  >
                    <Icon fontSize="small" sx={{ fontSize: "13px !important" }}>
                      close
                    </Icon>
                  </MDIconButton>
                </MDBox>
                {/* <MDAvatar size="xl" shadow="sm" src={item} sx={{ margin: "0 auto" }} /> */}
              </Grid>
            ))}
            <Grid item xs={12} sm={12} md={12} lg={12} mt={1}>
              {failedDeals?.length > 0 && (
                <MDBox>
                  <MDTypography variant="body2" color="error">
                    Problem occurred while sending messages to {failedDeals?.length} out of{" "}
                    {filteredDeals?.length} deals.
                  </MDTypography>
                  <CustomChipList
                    listData={failedDeals?.map((dealName: string) => ({
                      name: dealName,
                    }))}
                  />
                </MDBox>
              )}
            </Grid>
          </Grid>
        </MDBox>
      </Grid>

      {confirmSend && (
        <MDDialog {...deleteDialogProps}>
          <MDTypography variant="h6" fontWeight="medium">
            {`This message will send to ${filteredDeals.length} Deals. Are you sure you wish to send?`}
          </MDTypography>
        </MDDialog>
      )}
      {isFileUpload && (
        <MDDialog
          open={isFileUpload}
          onClose={() => {
            setIsFileUpload(false);
            setAttachments([]);
          }}
          onSave={() => setIsFileUpload(false)}
          title="Choose files"
          size="sm"
        >
          <CustomDropZone
            multipleUpload
            maxFiles={3}
            maxSize={1048576 * 3}
            acceptedFiles={{
              "": constants.twillioSupportedMimeTypes,
            }}
            isFileTypeError
            onDrop={onAttachFile}
          >
            Drop files here, or Browse
          </CustomDropZone>
          {fileLoader && (
            <MDIconButton sx={{ color: "#0b8cfb" }}>
              <CircularProgress size={15} sx={{ color: "#1a73e8" }} />
            </MDIconButton>
          )}
        </MDDialog>
      )}
    </MDDialog>
  );
}

export default createBroacastPopup;
