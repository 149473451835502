import { createSlice, Draft, PayloadAction } from "@reduxjs/toolkit";

interface InitialState {
  allInquiries: any;
  franchiseBlog: any;
}

const initialState: InitialState = {
  allInquiries: [],
  franchiseBlog: [],
};

const franchiseeWebSiteSlice = createSlice({
  name: "franchiseeWebSiteSlice",
  initialState,
  reducers: {
    allFranchiseeInquiriesList: (state: Draft<InitialState>, action: PayloadAction<any>) => ({
      ...state,
      allInquiries: action.payload,
    }),
    setFranchiseBlog: (state: Draft<InitialState>, action: PayloadAction<any>) => ({
      ...state,
      franchiseBlog: action.payload,
    }),
  },
});

export const { allFranchiseeInquiriesList, setFranchiseBlog } = franchiseeWebSiteSlice.actions;

export default franchiseeWebSiteSlice.reducer;
