import { Card, Grid } from "@mui/material";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";

function Integrations(props: any): JSX.Element {
  const { editPermission, onChangeField, locationDetail } = props;
  return (
    <Card id="integrations" sx={{ overflow: "visible" }}>
      <MDBox p={3}>
        <MDTypography variant="h5">Integrations</MDTypography>
      </MDBox>
      <MDBox component="form" pb={3} px={3}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            <MDInput
              label="Tour #1"
              multiline
              rows={3}
              name="tour_iframe_1"
              fullWidth
              variant="standard"
              value={locationDetail?.tour_iframe_1 || ""}
              onChange={onChangeField}
              disabled={!editPermission}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <MDInput
              label="Tour #2"
              multiline
              rows={3}
              name="tour_iframe_2"
              fullWidth
              variant="standard"
              value={locationDetail?.tour_iframe_2 || ""}
              onChange={onChangeField}
              disabled={!editPermission}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <MDInput
              label="Tour #3"
              multiline
              rows={3}
              name="tour_iframe_3"
              fullWidth
              variant="standard"
              value={locationDetail?.tour_iframe_3 || ""}
              onChange={onChangeField}
              disabled={!editPermission}
            />
          </Grid>
          {/* <Grid item xs={12} sm={6}>
            <MDInput
              label="Website Chat Code"
              multiline
              rows={3}
              placeholder="Website Chat Code"
              name="chat_code"
              fullWidth
              variant="standard"
              value={locationDetail?.chat_code || ""}
              onChange={onChangeField}
              disabled={!editPermission}
            />
          </Grid> */}
          <Grid item xs={12} sm={6}>
            <MDInput
              multiline
              rows={3}
              label="Website Tracking Code"
              placeholder="Website Tracking Code"
              name="tracking_code"
              fullWidth
              variant="standard"
              value={locationDetail?.tracking_code || ""}
              onChange={onChangeField}
              disabled={!editPermission}
            />
          </Grid>
        </Grid>
      </MDBox>
    </Card>
  );
}

export default Integrations;
