import { Card, Checkbox, FormControl, FormControlLabel, FormGroup, Grid } from "@mui/material";
// import CustomAutoSearch from "components/CustomAutoSearch";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import CustomErrorMessage from "components/CustomErrorMessage";
// import { getPermission } from "helper/services";

function Webpages(props: any): JSX.Element {
  const { solaProDetail, onCheckboxChange } = props;

  return (
    <Card id="webpages" sx={{ overflow: "visible" }}>
      <MDBox p={3}>
        <MDTypography variant="h5">Webpages</MDTypography>
      </MDBox>
      <MDBox component="form" pb={3} px={3}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={12} md={12} lg={12} sx={{ mt: 0 }}>
            {/* <CustomAutoSearch
              idKey="id"
              nameKey="business_name"
              fieldLabel="Webpage"
              apiName="get-web-pages"
              responseKey="data"
              name="webpage"
              platformAPI
              value={solaProDetail?.webpage || []}
              onChange={onChangeAutoComplete}
              // error={errors && errors.webpage}
              multiple
              usersData={{
                location_id: solaProDetail?.location?.id,
              }}
              createNewLink={
                getPermission("webpages", "webpages_webpages")
                  ? "/web-pages/web-pages/create-webpage"
                  : ""
              }
            /> */}
            <FormControl component="fieldset">
              <FormGroup aria-label="position">
                <FormControlLabel
                  value="end"
                  control={
                    <Checkbox
                      checked={solaProDetail?.create_webpage}
                      name="create_webpage"
                      onChange={onCheckboxChange}
                      inputProps={{ "aria-label": "controlled" }}
                    />
                  }
                  label="Create webpage"
                  labelPlacement="end"
                />
              </FormGroup>
            </FormControl>
            <CustomErrorMessage
              message="If checked, the webpage will automatically created for this stylist. This new webpage would not display on the site by default, but this would get them started."
              errorColor="inherit"
            />
          </Grid>
        </Grid>
      </MDBox>
    </Card>
  );
}

export default Webpages;
