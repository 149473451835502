import Grid from "@mui/material/Grid";
import MDBox from "components/MDBox";
import MDInput from "components/MDInput";
import CustomPhoneInput from "components/CustomPhoneInput";

function LeaseManager(props: any): JSX.Element {
  const { locationFranchiseView, onChangeField, errors } = props;
  return (
    <MDBox>
      <MDBox p={2} mt={2}>
        <MDBox>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6} md={6}>
              <MDInput
                label="Leasing Manager First Name"
                variant="standard"
                fullWidth
                InputLabelProps={{ shrink: true }}
                name="leasing_manager_first_name"
                required
                onChange={onChangeField}
                value={locationFranchiseView?.leasing_manager_first_name || ""}
                error={errors && errors.leasing_manager_first_name ? true : false}
                helperText={errors && errors.leasing_manager_first_name}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
              <MDInput
                label="Leasing Manager Last Name"
                variant="standard"
                fullWidth
                InputLabelProps={{ shrink: true }}
                name="leasing_manager_last_name"
                required
                onChange={onChangeField}
                value={locationFranchiseView?.leasing_manager_last_name || ""}
                error={errors && errors.leasing_manager_last_name ? true : false}
                helperText={errors && errors.leasing_manager_last_name}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
              <MDInput
                label="Email address of your leasing manager (to note, this is where your Contact Forms
                  from the Sola website will go as well)"
                variant="standard"
                fullWidth
                InputLabelProps={{ shrink: true }}
                name="leasing_manager_email"
                required
                onChange={onChangeField}
                value={locationFranchiseView?.leasing_manager_email || ""}
                error={errors && errors.leasing_manager_email ? true : false}
                helperText={errors && errors.leasing_manager_email}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <MDInput
                label="Phone number of leasing manager"
                variant="standard"
                fullWidth
                name="leasing_manager_phone"
                placeholder="(000) 000-0000"
                id="leasing_manager_phone"
                InputLabelProps={{ shrink: true }}
                InputProps={{
                  inputComponent: CustomPhoneInput as any,
                }}
                value={locationFranchiseView?.leasing_manager_phone}
                error={errors && errors.leasing_manager_phone ? true : false}
                helperText={errors && errors.leasing_manager_phone}
                onChange={onChangeField}
              />
            </Grid>
          </Grid>
        </MDBox>
      </MDBox>
    </MDBox>
  );
}

export default LeaseManager;
