import { Card, Grid, Icon } from "@mui/material";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import constants from "helper/constants";
import CustomTimeSelectPicker from "components/CustomTimeSelectPicker";
import MDIconButton from "components/MDIconButton";
import { useEffect, useState } from "react";
import CustomErrorMessage from "components/CustomErrorMessage";
import MDEditor from "components/MDEditor";

const modules: any = { toolbar: false };
const formats: any = [];

function WorkHours(props: any): JSX.Element {
  const { webPageDetail, setWebPageDetails, onCheckboxChange, errors, onChangeWorkHours } = props;
  const [hoursSlots, setHoursSlots] = useState<any>({});

  useEffect(() => {
    const slotObj: any = {};
    ["sunday", "monday", "tuesday", "wednesday", "thursday", "friday", "saturday"].map(
      (item: any) => {
        slotObj[item] = constants.timeSlotsArr;
        return true;
      }
    );
    setHoursSlots(slotObj);
  }, []);

  const onChangeWorkHoursType = (event: any) => {
    setWebPageDetails({
      ...webPageDetail,
      [event.target.name]: event.target.checked,
    });
  };

  const onChangeAutoComplete = (name: string, day: any, event: any) => {
    setWebPageDetails({
      ...webPageDetail,
      work_hours_data: {
        ...webPageDetail?.work_hours_data,
        [day]: { ...webPageDetail?.work_hours_data?.[day], [name]: event.target.value },
      },
    });

    const timeSlotIndex: number = hoursSlots[day].findIndex(
      (sl: any) => sl.label === event.target.value
    );
    const mappedArray: any = hoursSlots[day].map((obj: any, index: any) => {
      if (timeSlotIndex >= index) {
        return { ...obj, disabled: true };
      }
      return obj;
    });
    setHoursSlots({ ...hoursSlots, [day]: mappedArray });
  };

  const onChangeRadioForWorkHours = (name: string, day: any, event: any) => {
    setWebPageDetails({
      ...webPageDetail,
      work_hours_data: {
        ...webPageDetail?.work_hours_data,
        [day]: { ...webPageDetail?.work_hours_data?.[day], [name]: event.target.checked },
      },
    });
  };

  const getRadioElement = (label: any, day: any, name: any, bold?: boolean) => (
    <FormControlLabel
      control={
        <Switch
          checked={webPageDetail?.work_hours_data?.[day]?.[name] || false}
          onChange={(event: any) => onChangeRadioForWorkHours(name, day, event)}
          inputProps={{ "aria-label": "controlled" }}
          name={name}
        />
      }
      label={
        !bold ? (
          <MDTypography sx={{ fontWeight: "300", fontSize: "inherit" }}>
            {webPageDetail?.work_hours_data?.[day]?.[name] ? label : "Closed"}
          </MDTypography>
        ) : webPageDetail?.work_hours_data?.[day]?.[name] ? (
          label
        ) : (
          "Closed"
        )
      }
      labelPlacement="end"
    />
  );

  const removeTimeSlot = (day: any, slotNumber: any) => {
    setWebPageDetails({
      ...webPageDetail,
      work_hours_data: {
        ...webPageDetail?.work_hours_data,
        [day]: {
          ...webPageDetail?.work_hours_data?.[day],
          [`opens_at_${slotNumber}`]: "",
          [`closes_at_${slotNumber}`]: "",
        },
      },
    });

    if (slotNumber === 1) {
      const mappedArray: any = hoursSlots[day].map((obj: any) => ({ ...obj, disabled: false }));
      setHoursSlots({ ...hoursSlots, [day]: mappedArray });
    } else {
      const findedIndex: number = hoursSlots[day].findIndex(
        (xItem: any) => xItem.label === webPageDetail?.work_hours_data?.[day]?.closes_at_1
      );
      const mappedArray: any = hoursSlots[day].map((obj: any, index: any) => {
        if (findedIndex >= index) {
          return { ...obj, disabled: true };
        }
        return { ...obj, disabled: false };
      });
      setHoursSlots({ ...hoursSlots, [day]: mappedArray });
    }
  };

  const getRemoveIcon = (day: any, slotNumber: any, disabled: boolean = false) => (
    <MDIconButton
      tooltipName="Remove"
      aria-label="remove"
      color="info"
      sx={{ marginTop: 1 }}
      disabled={!webPageDetail?.work_hours_data?.[day]?.[`opens_at_${slotNumber}`] || disabled}
      onClick={() => removeTimeSlot(day, slotNumber)}
    >
      <Icon fontSize="small">clear</Icon>
    </MDIconButton>
  );

  return (
    <Card id="work-hours" sx={{ overflow: "visible" }}>
      <MDBox p={3}>
        <MDTypography variant="h5">Work Hours</MDTypography>
      </MDBox>
      <MDBox component="form" pb={2} px={3}>
        <Grid container>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <FormControlLabel
              control={
                <Switch
                  checked={webPageDetail?.is_standard_work_hours ? true : false}
                  onChange={onChangeWorkHoursType}
                  name="is_standard_work_hours"
                  inputProps={{ "aria-label": "controlled" }}
                />
              }
              label={webPageDetail.is_standard_work_hours ? "Use Standardized Hours" : "Text Only"}
              labelPlacement="end"
            />
          </Grid>
        </Grid>
      </MDBox>
      {webPageDetail.is_standard_work_hours ? (
        <MDBox component="form" pb={2} px={3}>
          <Grid container>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <FormControlLabel
                control={
                  <Switch
                    checked={webPageDetail?.is_show_work_hours ? true : false}
                    onChange={onCheckboxChange}
                    name="is_show_work_hours"
                    inputProps={{ "aria-label": "controlled" }}
                  />
                }
                label="Visible On Webpage"
                labelPlacement="end"
              />
              <CustomErrorMessage
                errorColor="inherit"
                message="If enabled, the webpage will have work hours, appear in the directory, and in searches."
              />
            </Grid>
          </Grid>
          <Grid container mt={2}>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <MDBox display="flex" justifyContent="space-around">
                <MDTypography variant="h6">Sunday</MDTypography>
                {getRadioElement("Open", "sunday", "open", true)}
              </MDBox>
            </Grid>
            {webPageDetail?.work_hours_data?.sunday?.open && (
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <Grid container>
                  <Grid item xs={6} sm={6} md={6} lg={6}>
                    <CustomTimeSelectPicker
                      options={hoursSlots.sunday}
                      placeholder="Opens at"
                      label="Opens at"
                      name="opens_at_1"
                      id="opens_at_1"
                      value={webPageDetail?.work_hours_data?.sunday?.opens_at_1 || ""}
                      error={errors?.sunday_opens_at_1 || ""}
                      disabled={webPageDetail?.work_hours_data?.sunday?.opens_at_2 ? true : false}
                      onChange={(value: any) => onChangeAutoComplete("opens_at_1", "sunday", value)}
                    />
                  </Grid>
                  <Grid item xs={6} sm={6} md={6} lg={6}>
                    <CustomTimeSelectPicker
                      options={hoursSlots.sunday}
                      label="Closes at"
                      placeholder="Closes at"
                      name="closes_at_1"
                      id="closes_at_1"
                      value={webPageDetail?.work_hours_data?.sunday?.closes_at_1 || ""}
                      disabled={
                        !webPageDetail?.work_hours_data?.sunday?.opens_at_1 ||
                        webPageDetail?.work_hours_data?.sunday?.opens_at_2
                          ? true
                          : false
                      }
                      error={errors?.sunday_closes_at_1 || ""}
                      onChange={(value: any) =>
                        onChangeAutoComplete("closes_at_1", "sunday", value)
                      }
                    />
                    {getRemoveIcon(
                      "sunday",
                      1,
                      webPageDetail?.work_hours_data?.sunday?.opens_at_2 ? true : false
                    )}
                  </Grid>
                  <Grid item xs={6} sm={6} md={6} lg={6}>
                    <CustomTimeSelectPicker
                      options={hoursSlots.sunday}
                      placeholder="Opens at"
                      label="Opens at"
                      name="opens_at_2"
                      id="opens_at_2"
                      value={webPageDetail?.work_hours_data?.sunday?.opens_at_2 || ""}
                      disabled={!webPageDetail?.work_hours_data?.sunday?.closes_at_1 ? true : false}
                      error={errors?.sunday_opens_at_2 || ""}
                      onChange={(value: any) => onChangeAutoComplete("opens_at_2", "sunday", value)}
                    />
                  </Grid>
                  <Grid item xs={6} sm={6} md={6} lg={6}>
                    <CustomTimeSelectPicker
                      options={hoursSlots.sunday}
                      label="Closes at"
                      placeholder="Closes at"
                      name="closes_at_2"
                      id="closes_at_2"
                      value={webPageDetail?.work_hours_data?.sunday?.closes_at_2 || ""}
                      disabled={!webPageDetail?.work_hours_data?.sunday?.opens_at_2 ? true : false}
                      error={errors?.sunday_closes_at_2 || ""}
                      onChange={(value: any) =>
                        onChangeAutoComplete("closes_at_2", "sunday", value)
                      }
                    />
                    {getRemoveIcon("sunday", 2)}
                  </Grid>
                </Grid>
              </Grid>
            )}
          </Grid>
          <Grid container mt={1}>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <MDBox display="flex" justifyContent="space-around">
                <MDTypography variant="h6">Monday</MDTypography>
                {getRadioElement("Open", "monday", "open", true)}
              </MDBox>
            </Grid>
            {webPageDetail?.work_hours_data?.monday?.open && (
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <Grid container>
                  <Grid item xs={6} sm={6} md={6} lg={6}>
                    <CustomTimeSelectPicker
                      options={hoursSlots.monday}
                      placeholder="Opens at"
                      label="Opens at"
                      name="opens_at_1"
                      id="opens_at_1"
                      value={webPageDetail?.work_hours_data?.monday?.opens_at_1 || ""}
                      error={errors?.monday_opens_at_1 || ""}
                      disabled={webPageDetail?.work_hours_data?.monday?.opens_at_2 ? true : false}
                      onChange={(value: any) => onChangeAutoComplete("opens_at_1", "monday", value)}
                    />
                  </Grid>
                  <Grid item xs={6} sm={6} md={6} lg={6}>
                    <CustomTimeSelectPicker
                      options={hoursSlots.monday}
                      label="Closes at"
                      placeholder="Closes at"
                      name="closes_at_1"
                      id="closes_at_1"
                      value={webPageDetail?.work_hours_data?.monday?.closes_at_1 || ""}
                      disabled={
                        !webPageDetail?.work_hours_data?.monday?.opens_at_1 ||
                        webPageDetail?.work_hours_data?.monday?.opens_at_2
                          ? true
                          : false
                      }
                      error={errors?.monday_closes_at_1 || ""}
                      onChange={(value: any) =>
                        onChangeAutoComplete("closes_at_1", "monday", value)
                      }
                    />
                    {getRemoveIcon(
                      "monday",
                      1,
                      webPageDetail?.work_hours_data?.monday?.opens_at_2 ? true : false
                    )}
                  </Grid>
                  <Grid item xs={6} sm={6} md={6} lg={6}>
                    <CustomTimeSelectPicker
                      options={hoursSlots.monday}
                      placeholder="Opens at"
                      label="Opens at"
                      name="opens_at_2"
                      id="opens_at_2"
                      value={webPageDetail?.work_hours_data?.monday?.opens_at_2 || ""}
                      disabled={!webPageDetail?.work_hours_data?.monday?.closes_at_1 ? true : false}
                      error={errors?.monday_opens_at_2 || ""}
                      onChange={(value: any) => onChangeAutoComplete("opens_at_2", "monday", value)}
                    />
                  </Grid>
                  <Grid item xs={6} sm={6} md={6} lg={6}>
                    <CustomTimeSelectPicker
                      options={hoursSlots.monday}
                      label="Closes at"
                      placeholder="Closes at"
                      name="closes_at_2"
                      id="closes_at_2"
                      value={webPageDetail?.work_hours_data?.monday?.closes_at_2 || ""}
                      disabled={!webPageDetail?.work_hours_data?.monday?.opens_at_2 ? true : false}
                      error={errors?.monday_closes_at_2 || ""}
                      onChange={(value: any) =>
                        onChangeAutoComplete("closes_at_2", "monday", value)
                      }
                    />
                    {getRemoveIcon("monday", 2)}
                  </Grid>
                </Grid>
              </Grid>
            )}
          </Grid>
          <Grid container mt={1}>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <MDBox display="flex" justifyContent="space-around">
                <MDTypography variant="h6">Tuesday</MDTypography>
                {getRadioElement("Open", "tuesday", "open", true)}
              </MDBox>
            </Grid>
            {webPageDetail?.work_hours_data?.tuesday?.open && (
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <Grid container>
                  <Grid item xs={6} sm={6} md={6} lg={6}>
                    <CustomTimeSelectPicker
                      options={hoursSlots.tuesday}
                      placeholder="Opens at"
                      label="Opens at"
                      name="opens_at_1"
                      id="opens_at_1"
                      value={webPageDetail?.work_hours_data?.tuesday?.opens_at_1 || ""}
                      error={errors?.tuesday_opens_at_1 || ""}
                      disabled={webPageDetail?.work_hours_data?.tuesday?.opens_at_2 ? true : false}
                      onChange={(value: any) =>
                        onChangeAutoComplete("opens_at_1", "tuesday", value)
                      }
                    />
                  </Grid>
                  <Grid item xs={6} sm={6} md={6} lg={6}>
                    <CustomTimeSelectPicker
                      options={hoursSlots.tuesday}
                      label="Closes at"
                      placeholder="Closes at"
                      name="closes_at_1"
                      id="closes_at_1"
                      value={webPageDetail?.work_hours_data?.tuesday?.closes_at_1 || ""}
                      disabled={
                        !webPageDetail?.work_hours_data?.tuesday?.opens_at_1 ||
                        webPageDetail?.work_hours_data?.tuesday?.opens_at_2
                          ? true
                          : false
                      }
                      error={errors?.tuesday_closes_at_1 || ""}
                      onChange={(value: any) =>
                        onChangeAutoComplete("closes_at_1", "tuesday", value)
                      }
                    />
                    {getRemoveIcon(
                      "tuesday",
                      1,
                      webPageDetail?.work_hours_data?.tuesday?.opens_at_2 ? true : false
                    )}
                  </Grid>
                  <Grid item xs={6} sm={6} md={6} lg={6}>
                    <CustomTimeSelectPicker
                      options={hoursSlots.tuesday}
                      placeholder="Opens at"
                      label="Opens at"
                      name="opens_at_2"
                      id="opens_at_2"
                      value={webPageDetail?.work_hours_data?.tuesday?.opens_at_2 || ""}
                      disabled={
                        !webPageDetail?.work_hours_data?.tuesday?.closes_at_1 ? true : false
                      }
                      error={errors?.tuesday_opens_at_2 || ""}
                      onChange={(value: any) =>
                        onChangeAutoComplete("opens_at_2", "tuesday", value)
                      }
                    />
                  </Grid>
                  <Grid item xs={6} sm={6} md={6} lg={6}>
                    <CustomTimeSelectPicker
                      options={hoursSlots.tuesday}
                      label="Closes at"
                      placeholder="Closes at"
                      name="closes_at_2"
                      id="closes_at_2"
                      value={webPageDetail?.work_hours_data?.tuesday?.closes_at_2 || ""}
                      disabled={!webPageDetail?.work_hours_data?.tuesday?.opens_at_2 ? true : false}
                      error={errors?.tuesday_closes_at_2 || ""}
                      onChange={(value: any) =>
                        onChangeAutoComplete("closes_at_2", "tuesday", value)
                      }
                    />
                    {getRemoveIcon("tuesday", 2)}
                  </Grid>
                </Grid>
              </Grid>
            )}
          </Grid>
          <Grid container mt={1}>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <MDBox display="flex" justifyContent="space-around">
                <MDTypography variant="h6">Wednesday</MDTypography>
                {getRadioElement("Open", "wednesday", "open", true)}
              </MDBox>
            </Grid>
            {webPageDetail?.work_hours_data?.wednesday?.open && (
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <Grid container>
                  <Grid item xs={6} sm={6} md={6} lg={6}>
                    <CustomTimeSelectPicker
                      options={hoursSlots.wednesday}
                      placeholder="Opens at"
                      label="Opens at"
                      name="opens_at_1"
                      id="opens_at_1"
                      value={webPageDetail?.work_hours_data?.wednesday?.opens_at_1 || ""}
                      error={errors?.wednesday_opens_at_1 || ""}
                      disabled={
                        webPageDetail?.work_hours_data?.wednesday?.opens_at_2 ? true : false
                      }
                      onChange={(value: any) =>
                        onChangeAutoComplete("opens_at_1", "wednesday", value)
                      }
                    />
                  </Grid>
                  <Grid item xs={6} sm={6} md={6} lg={6}>
                    <CustomTimeSelectPicker
                      options={hoursSlots.wednesday}
                      label="Closes at"
                      placeholder="Closes at"
                      name="closes_at_1"
                      id="closes_at_1"
                      value={webPageDetail?.work_hours_data?.wednesday?.closes_at_1 || ""}
                      disabled={
                        !webPageDetail?.work_hours_data?.wednesday?.opens_at_1 ||
                        webPageDetail?.work_hours_data?.wednesday?.opens_at_2
                          ? true
                          : false
                      }
                      error={errors?.wednesday_closes_at_1 || ""}
                      onChange={(value: any) =>
                        onChangeAutoComplete("closes_at_1", "wednesday", value)
                      }
                    />
                    {getRemoveIcon(
                      "wednesday",
                      1,
                      webPageDetail?.work_hours_data?.wednesday?.opens_at_2 ? true : false
                    )}
                  </Grid>
                  <Grid item xs={6} sm={6} md={6} lg={6}>
                    <CustomTimeSelectPicker
                      options={hoursSlots.wednesday}
                      placeholder="Opens at"
                      label="Opens at"
                      name="opens_at_2"
                      id="opens_at_2"
                      value={webPageDetail?.work_hours_data?.wednesday?.opens_at_2 || ""}
                      disabled={
                        !webPageDetail?.work_hours_data?.wednesday?.closes_at_1 ? true : false
                      }
                      error={errors?.wednesday_opens_at_2 || ""}
                      onChange={(value: any) =>
                        onChangeAutoComplete("opens_at_2", "wednesday", value)
                      }
                    />
                  </Grid>
                  <Grid item xs={6} sm={6} md={6} lg={6}>
                    <CustomTimeSelectPicker
                      options={hoursSlots.wednesday}
                      label="Closes at"
                      placeholder="Closes at"
                      name="closes_at_2"
                      id="closes_at_2"
                      value={webPageDetail?.work_hours_data?.wednesday?.closes_at_2 || ""}
                      disabled={
                        !webPageDetail?.work_hours_data?.wednesday?.opens_at_2 ? true : false
                      }
                      error={errors?.wednesday_closes_at_2 || ""}
                      onChange={(value: any) =>
                        onChangeAutoComplete("closes_at_2", "wednesday", value)
                      }
                    />
                    {getRemoveIcon("wednesday", 2)}
                  </Grid>
                </Grid>
              </Grid>
            )}
          </Grid>
          <Grid container mt={1}>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <MDBox display="flex" justifyContent="space-around">
                <MDTypography variant="h6">Thursday</MDTypography>
                {getRadioElement("Open", "thursday", "open", true)}
              </MDBox>
            </Grid>
            {webPageDetail?.work_hours_data?.thursday?.open && (
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <Grid container>
                  <Grid item xs={6} sm={6} md={6} lg={6}>
                    <CustomTimeSelectPicker
                      options={hoursSlots.thursday}
                      placeholder="Opens at"
                      label="Opens at"
                      name="opens_at_1"
                      id="opens_at_1"
                      value={webPageDetail?.work_hours_data?.thursday?.opens_at_1 || ""}
                      disabled={webPageDetail?.work_hours_data?.thursday?.opens_at_2 ? true : false}
                      error={errors?.thursday_opens_at_1 || ""}
                      onChange={(value: any) =>
                        onChangeAutoComplete("opens_at_1", "thursday", value)
                      }
                    />
                  </Grid>
                  <Grid item xs={6} sm={6} md={6} lg={6}>
                    <CustomTimeSelectPicker
                      options={hoursSlots.thursday}
                      label="Closes at"
                      placeholder="Closes at"
                      name="closes_at_1"
                      id="closes_at_1"
                      value={webPageDetail?.work_hours_data?.thursday?.closes_at_1 || ""}
                      disabled={
                        !webPageDetail?.work_hours_data?.thursday?.opens_at_1 ||
                        webPageDetail?.work_hours_data?.thursday?.opens_at_2
                          ? true
                          : false
                      }
                      error={errors?.thursday_closes_at_1 || ""}
                      onChange={(value: any) =>
                        onChangeAutoComplete("closes_at_1", "thursday", value)
                      }
                    />
                    {getRemoveIcon(
                      "thursday",
                      1,
                      webPageDetail?.work_hours_data?.thursday?.opens_at_2 ? true : false
                    )}
                  </Grid>
                  <Grid item xs={6} sm={6} md={6} lg={6}>
                    <CustomTimeSelectPicker
                      options={hoursSlots.thursday}
                      placeholder="Opens at"
                      label="Opens at"
                      name="opens_at_2"
                      id="opens_at_2"
                      value={webPageDetail?.work_hours_data?.thursday?.opens_at_2 || ""}
                      disabled={
                        !webPageDetail?.work_hours_data?.thursday?.closes_at_1 ? true : false
                      }
                      error={errors?.thursday_opens_at_2 || ""}
                      onChange={(value: any) =>
                        onChangeAutoComplete("opens_at_2", "thursday", value)
                      }
                    />
                  </Grid>
                  <Grid item xs={6} sm={6} md={6} lg={6}>
                    <CustomTimeSelectPicker
                      options={hoursSlots.thursday}
                      label="Closes at"
                      placeholder="Closes at"
                      name="closes_at_2"
                      id="closes_at_2"
                      value={webPageDetail?.work_hours_data?.thursday?.closes_at_2 || ""}
                      disabled={
                        !webPageDetail?.work_hours_data?.thursday?.opens_at_2 ? true : false
                      }
                      error={errors?.thursday_closes_at_2 || ""}
                      onChange={(value: any) =>
                        onChangeAutoComplete("closes_at_2", "thursday", value)
                      }
                    />
                    {getRemoveIcon("thursday", 2)}
                  </Grid>
                </Grid>
              </Grid>
            )}
          </Grid>
          <Grid container mt={1}>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <MDBox display="flex" justifyContent="space-around">
                <MDTypography variant="h6">Friday</MDTypography>
                {getRadioElement("Open", "friday", "open", true)}
              </MDBox>
            </Grid>
            {webPageDetail?.work_hours_data?.friday?.open && (
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <Grid container>
                  <Grid item xs={6} sm={6} md={6} lg={6}>
                    <CustomTimeSelectPicker
                      options={hoursSlots.friday}
                      placeholder="Opens at"
                      label="Opens at"
                      name="opens_at_1"
                      id="opens_at_1"
                      value={webPageDetail?.work_hours_data?.friday?.opens_at_1 || ""}
                      disabled={webPageDetail?.work_hours_data?.friday?.opens_at_2 ? true : false}
                      error={errors?.friday_opens_at_1 || ""}
                      onChange={(value: any) => onChangeAutoComplete("opens_at_1", "friday", value)}
                    />
                  </Grid>
                  <Grid item xs={6} sm={6} md={6} lg={6}>
                    <CustomTimeSelectPicker
                      options={hoursSlots.friday}
                      label="Closes at"
                      placeholder="Closes at"
                      name="closes_at_1"
                      id="closes_at_1"
                      value={webPageDetail?.work_hours_data?.friday?.closes_at_1 || ""}
                      disabled={
                        !webPageDetail?.work_hours_data?.friday?.opens_at_1 ||
                        webPageDetail?.work_hours_data?.friday?.opens_at_2
                          ? true
                          : false
                      }
                      error={errors?.friday_closes_at_1 || ""}
                      onChange={(value: any) =>
                        onChangeAutoComplete("closes_at_1", "friday", value)
                      }
                    />
                    {getRemoveIcon(
                      "friday",
                      1,
                      webPageDetail?.work_hours_data?.friday?.opens_at_2 ? true : false
                    )}
                  </Grid>
                  <Grid item xs={6} sm={6} md={6} lg={6}>
                    <CustomTimeSelectPicker
                      options={hoursSlots.friday}
                      placeholder="Opens at"
                      label="Opens at"
                      name="opens_at_2"
                      id="opens_at_2"
                      value={webPageDetail?.work_hours_data?.friday?.opens_at_2 || ""}
                      disabled={!webPageDetail?.work_hours_data?.friday?.closes_at_1 ? true : false}
                      error={errors?.friday_opens_at_2 || ""}
                      onChange={(value: any) => onChangeAutoComplete("opens_at_2", "friday", value)}
                    />
                  </Grid>
                  <Grid item xs={6} sm={6} md={6} lg={6}>
                    <CustomTimeSelectPicker
                      options={hoursSlots.friday}
                      label="Closes at"
                      placeholder="Closes at"
                      name="closes_at_2"
                      id="closes_at_2"
                      value={webPageDetail?.work_hours_data?.friday?.closes_at_2 || ""}
                      disabled={!webPageDetail?.work_hours_data?.friday?.opens_at_2 ? true : false}
                      error={errors?.friday_closes_at_2 || ""}
                      onChange={(value: any) =>
                        onChangeAutoComplete("closes_at_2", "friday", value)
                      }
                    />
                    {getRemoveIcon("friday", 2)}
                  </Grid>
                </Grid>
              </Grid>
            )}
          </Grid>
          <Grid container mt={1}>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <MDBox display="flex" justifyContent="space-around">
                <MDTypography variant="h6">Saturday</MDTypography>
                {getRadioElement("Open", "saturday", "open", true)}
              </MDBox>
            </Grid>
            {webPageDetail?.work_hours_data?.saturday?.open && (
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <Grid container>
                  <Grid item xs={6} sm={6} md={6} lg={6}>
                    <CustomTimeSelectPicker
                      options={hoursSlots.saturday}
                      placeholder="Opens at"
                      label="Opens at"
                      name="opens_at_1"
                      id="opens_at_1"
                      value={webPageDetail?.work_hours_data?.saturday?.opens_at_1 || ""}
                      error={errors?.saturday_opens_at_1 || ""}
                      disabled={webPageDetail?.work_hours_data?.saturday?.opens_at_2 ? true : false}
                      onChange={(value: any) =>
                        onChangeAutoComplete("opens_at_1", "saturday", value)
                      }
                    />
                  </Grid>
                  <Grid item xs={6} sm={6} md={6} lg={6}>
                    <CustomTimeSelectPicker
                      options={hoursSlots.saturday}
                      label="Closes at"
                      placeholder="Closes at"
                      name="closes_at_1"
                      id="closes_at_1"
                      value={webPageDetail?.work_hours_data?.saturday?.closes_at_1 || ""}
                      disabled={
                        !webPageDetail?.work_hours_data?.saturday?.opens_at_1 ||
                        webPageDetail?.work_hours_data?.saturday?.opens_at_2
                          ? true
                          : false
                      }
                      error={errors?.saturday_closes_at_1 || ""}
                      onChange={(value: any) =>
                        onChangeAutoComplete("closes_at_1", "saturday", value)
                      }
                    />
                    {getRemoveIcon(
                      "saturday",
                      1,
                      webPageDetail?.work_hours_data?.saturday?.opens_at_2 ? true : false
                    )}
                  </Grid>
                  <Grid item xs={6} sm={6} md={6} lg={6}>
                    <CustomTimeSelectPicker
                      options={hoursSlots.saturday}
                      placeholder="Opens at"
                      label="Opens at"
                      name="opens_at_2"
                      id="opens_at_2"
                      value={webPageDetail?.work_hours_data?.saturday?.opens_at_2 || ""}
                      disabled={
                        !webPageDetail?.work_hours_data?.saturday?.closes_at_1 ? true : false
                      }
                      error={errors?.saturday_opens_at_2 || ""}
                      onChange={(value: any) =>
                        onChangeAutoComplete("opens_at_2", "saturday", value)
                      }
                    />
                  </Grid>
                  <Grid item xs={6} sm={6} md={6} lg={6}>
                    <CustomTimeSelectPicker
                      options={hoursSlots.saturday}
                      label="Closes at"
                      placeholder="Closes at"
                      name="closes_at_2"
                      id="closes_at_2"
                      value={webPageDetail?.work_hours_data?.saturday?.closes_at_2 || ""}
                      disabled={
                        !webPageDetail?.work_hours_data?.saturday?.opens_at_2 ? true : false
                      }
                      error={errors?.saturday_closes_at_2 || ""}
                      onChange={(value: any) =>
                        onChangeAutoComplete("closes_at_2", "saturday", value)
                      }
                    />
                    {getRemoveIcon("saturday", 2)}
                  </Grid>
                </Grid>
              </Grid>
            )}
          </Grid>
        </MDBox>
      ) : (
        <MDBox component="form" pb={2} px={3}>
          <Grid container>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <MDTypography variant="button">Work Hours</MDTypography>

              <MDEditor
                value={webPageDetail?.norm_work_hours ? webPageDetail?.norm_work_hours : ""}
                onChange={(val: any, delta: any, source: any) =>
                  onChangeWorkHours(val, delta, source)
                }
                modules={modules}
                formats={formats}
              />
            </Grid>
          </Grid>
        </MDBox>
      )}
    </Card>
  );
}

export default WorkHours;
