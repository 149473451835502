import { useEffect, useState, useRef, useCallback, useLayoutEffect } from "react";
import { Card, Icon, Checkbox, Link } from "@mui/material";
import MDBox from "components/MDBox";
// import MDButton from "components/MDButton";
import DataTable from "examples/Tables/DataTable";
import { getSolaProsList } from "store/thunk/solaProsThunk";
import { useAppDispatch, useAppSelector } from "store/store";
import exportData from "helper/exportTableConst";
import MDIconButton from "components/MDIconButton";
import ViewDetails from "layouts/pages/sola-pros/viewDetails";
import { useNavigate } from "react-router-dom";
import {
  adminSuperAdmin,
  getAssociationLocations,
  setDefaultSelectedColumns,
  getUrlName,
  userRoles,
} from "helper/services";
import { getDefaultCoulmns, saveSelectedAllData } from "store/thunk/connectUserThunk";
import { setDefaultsColumns } from "store/slices/connectUsersSlice";
import config from "config/config";

function SolaPros(props?: any): JSX.Element {
  const { locationId, accessDisabled } = props;
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { userData } = useAppSelector((state) => state.authReducer);
  const { solaProsList } = useAppSelector((state) => state.solaProSlice);
  const { defaultSelectedColumns } = useAppSelector((state) => state.connectUsersReducer);
  const [updateData, setUpdateData] = useState<any>(null);
  const [detailsPopup, setDetailsPopup] = useState<any>(false);
  const [selectedItems, setSelectedItems] = useState([]);
  const [prosList, setSolaProList] = useState([]);
  const [pageCount, setPageCount] = useState(0);
  const [pageNo, setPageNo] = useState(0);
  const [perPage, setPageSize] = useState(50);
  const [totalCount, setTotalCount] = useState(0);
  const [search, setSearch] = useState<any>(null);
  const [sortBy, setSortBy] = useState<any>([]);
  const [isCheckAll, setIsCheckAll] = useState(false);
  const [displayColumns, setDisplayColumns] = useState<any>([]);
  const [defaultExport, setDefaultExport] = useState<any>([]);
  // SD-3199
  const [callFetch, setCallFetch] = useState<any>(false);
  const [defaultSorting, setDefaultSorting] = useState<any>([]);
  const [defaultFilteing, setDefaultFilteing] = useState<any>([]);
  // Get columns
  const getColumns = () => {
    const columns: any = [
      {
        Header: "",
        disableSortBy: true,
        accessor: "checkbox",
        export: false,
        width: "80px",
        checked: true,
      },
      {
        Header: "First Name",
        accessor: "f_name",
        export: true,
        checked: true,
        disableCheck: true,
      },
      { Header: "Last Name", accessor: "l_name", export: true, checked: true },
      { Header: "Phone Number", accessor: "phone_number", export: true, checked: true },
      { Header: "Email", accessor: "email_address", export: true, width: "200", checked: true },
      {
        Header: "Location",
        accessor: "location_name",
        export: true,
        associative_colums: "locations.name",
        checked: true,
      },
      { Header: "Studio Number", accessor: "studio_number", export: true, checked: true },
      { Header: "Business Name", accessor: "business_name", export: true, checked: false },
      {
        Header: "Action",
        accessor: "action",
        width: "200px",
        export: false,
        disableSortBy: true,
        checked: true,
      },
    ];
    return columns;
  };
  const [checkedColumns, setCheckedColumns] = useState<any>(getColumns());
  // Changes in checkedColumns
  const latestRefValue = useRef(checkedColumns);
  const fetchIdRef = useRef(0);

  // AdduseEffect checkedColumns
  useEffect(() => {
    latestRefValue.current = checkedColumns;
  }, [checkedColumns]);

  useEffect(() => {
    if (solaProsList && solaProsList.stylists) {
      setSolaProList(solaProsList.stylists);
      setPageCount(solaProsList?.meta?.total_pages);
      setTotalCount(solaProsList?.meta?.total_count);
    }
  }, [solaProsList]);

  useEffect(() => {
    if (checkedColumns?.length) {
      setDisplayColumns(checkedColumns.filter((col: any) => col.checked));
    }
  }, [checkedColumns]);

  // Get the default colums data
  useEffect(() => {
    if (defaultSelectedColumns && defaultSelectedColumns?.length) {
      const newColumns = setDefaultSelectedColumns(defaultSelectedColumns, getColumns());
      setCheckedColumns(newColumns);
    } else {
      setCheckedColumns(getColumns());
    }
  }, [defaultSelectedColumns]);

  const fetchData = useCallback(({ pageSize, pageIndex, search, sortBy, filterData, isManual }) => {
    const fetchId = ++fetchIdRef.current;
    const sort: any = sortBy || [];
    // We'll even set a delay to simulate a server here
    setTimeout(() => {
      if (fetchId === fetchIdRef.current) {
        // SD-3199
        const sentReq = {
          sendData: {
            user_id: userData?.user_id,
            table_name: `${exportData.stylists.table_name}_closed`,
            table_columns: latestRefValue.current ? latestRefValue.current : checkedColumns, // Changes in checkedColumns
            table_sort: sort,
            table_filter: filterData,
            default_export_data: defaultExport,
          },
        };
        if (isManual) {
          dispatch(saveSelectedAllData(sentReq));
        }
        setPageNo(pageIndex);
        setSearch(search);
        setSortBy(sort);
        setPageSize(pageSize);
        // setSelectedItems([]);
        setIsCheckAll(false);
        dispatch(
          getSolaProsList({
            pageSize,
            pageIndex,
            search,
            sort,
            locationId,
            status: "inactive",
            filterData,
            ...(userData?.role_name === userRoles.SOLA_PRO_USER
              ? { userId: userData?.user_id }
              : {}),
          })
        );
      }
    }, 1000);
  }, []);

  // For get Default coulmns
  useLayoutEffect(() => {
    dispatch(setDefaultsColumns([]));
    // Set data in variable and Call defaultColums Thunk
    const sentReq = {
      user_id: userData?.user_id,
      table_name: `${exportData.stylists.table_name}_closed`,
    };
    dispatch(getDefaultCoulmns(sentReq)).then((resp: any) => {
      // SD-3199
      const respFilter = resp?.payload?.table_filter || [];
      const respSorting = resp?.payload?.table_sort || [];
      const defaultDBColumn = resp?.payload?.table_columns || [];
      const defaultDBexportData = resp?.payload?.default_export_data || [];
      setDefaultExport(defaultDBexportData);
      setCheckedColumns(defaultDBColumn);
      setDefaultSorting(respSorting);
      setDefaultFilteing(respFilter);
      if (resp?.payload) {
        setCallFetch(true);
        fetchData({
          perPage,
          pageNo,
          search,
          sortBy: respSorting && respSorting?.length ? respSorting : [],
          additionalField: null,
          filterData: respFilter && respFilter?.length ? respFilter : [],
        });
      }
    });
  }, []);

  const cmsEditPage = (data: any) => {
    navigate(`/sola-cms/cms-sola-pros/${data?.name ? getUrlName(data?.name) : ""}`, {
      state: { prevPath: "/sola-cms/cms-sola-pros", id: data?.id },
    });
  };

  const openDetailPopup = (data: any) => {
    setUpdateData(data);
    setDetailsPopup(true);
  };

  const closeDetailPopup = () => {
    setUpdateData("");
    setDetailsPopup(false);
  };

  const goToSolProsDetails = (object: any) => {
    navigate(`/sola-pros/${object.id}`, {
      state: { prevPath: `/sola-pros`, defaultTab: "0", solaProId: object.id },
    });
  };

  const actionButtons = (object: any) => {
    // const LinkURL: any = `https://www.solasalonstudios.com/salon-professional/${object?.url_name}`;
    const LinkURL: any = `${config.FRONT_END_URL}salon-professional/${object?.url_name || ""}`;
    return (
      <>
        {object?.url_name ? (
          <Link
            href={LinkURL}
            target="_blank"
            rel="noreferrer noopener"
            sx={{ textDecoration: "none" }}
          >
            <MDIconButton
              tooltipName="Sola Professional Details"
              aria-label="Open"
              color="info"
              disabled={!object?.url_name}
            >
              <Icon fontSize="small">open_in_new</Icon>
            </MDIconButton>
          </Link>
        ) : (
          <MDIconButton tooltipName="No Web URL Found" aria-label="Open" color="info" disabled>
            <Icon fontSize="small">open_in_new</Icon>
          </MDIconButton>
        )}
        <MDIconButton
          tooltipName="View"
          aria-label="Info"
          color="info"
          onClick={() => openDetailPopup(object)}
        >
          <Icon fontSize="small">visibility</Icon>
        </MDIconButton>
        <MDIconButton
          tooltipName="Edit"
          aria-label="Edit"
          color="info"
          onClick={() =>
            window?.location?.pathname?.includes("sola-cms")
              ? cmsEditPage(object)
              : goToSolProsDetails(object)
          }
        >
          <Icon fontSize="small">edit</Icon>
        </MDIconButton>
      </>
    );
  };

  const onChangeCheckBox = (e: any) => {
    let items = JSON.parse(JSON.stringify(selectedItems));
    if (e.target.checked && !items.includes(e.target.value)) {
      items.push(e.target.value);
      if (items.length === prosList.length) {
        setIsCheckAll(true);
      }
    } else {
      items = items.filter((id: any) => id !== e.target.value);
      setIsCheckAll(false);
    }
    setSelectedItems(items);
  };

  const checkUncheckAll = (e: any) => {
    let allIds: any = [];
    allIds = prosList.map((val: any) => String(val.id));
    if (e.target.checked) {
      const selectedIds = [...new Set([...selectedItems, ...allIds])];
      setSelectedItems(selectedIds);
      // allIds = prosList.map((val: any) => String(val.id));
      setIsCheckAll(true);
    } else {
      allIds = prosList.map((val: any) => String(val.id));
      allIds = selectedItems.filter((el: any) => !allIds.includes(el));
      setSelectedItems([...allIds]);
      setIsCheckAll(false);
      // allIds = [];
      // setIsCheckAll(false);
    }
    // setSelectedItems(allIds);
  };

  const getRows = () => {
    const data: any = prosList.map((obj: any) => ({
      checkbox: (
        <Checkbox
          checked={selectedItems.includes(obj.id.toString())}
          onChange={(e: any) => onChangeCheckBox(e)}
          value={obj.id}
        />
      ),
      // For check all condition
      id: obj.id ? obj.id : "-",
      f_name: obj.f_name ? obj.f_name : "-",
      l_name: obj.l_name ? obj.l_name : "-",
      phone_number: obj.phone_number ? obj.phone_number : "-",
      email_address: obj.email_address ? obj.email_address : "-",
      location_name: obj.location_name ? obj.location_name : "-",
      studio_number: obj.studio_number ? obj.studio_number : "-",
      business_name: obj.business_name ? obj.business_name : "-",
      action:
        accessDisabled ||
        window?.location?.pathname?.includes("sola-cms") ||
        window?.location?.pathname?.includes("sola-pros")
          ? actionButtons(obj)
          : null,
    }));
    return data;
  };

  const tableData = {
    columns: getColumns(),
    rows: getRows(),
  };

  // Remove uncheck boxes
  const clearCheckItem = () => {
    setSelectedItems([]);
  };

  return (
    <MDBox pt={4} pb={3}>
      <Card>
        <MDBox
          bgColor="dark"
          color="white"
          coloredShadow="dark"
          borderRadius="xl"
          alignItems="center"
          justifyContent="space-between"
          pt={1}
          pb={1}
          pl={2}
          pr={2}
          ml={2}
          mr={2}
          display="flex"
          mt={-3}
          className="page-header"
        >
          <p className="page-header-label">Sola Professional</p>
        </MDBox>
        {(defaultSorting || defaultSorting?.length) &&
          callFetch && ( // SD-3199
            <DataTable
              defaultSortingColumns={defaultSorting} // SD-3199
              defaultFilteringColums={defaultFilteing} // SD-3199
              defaultDisplayColumns={displayColumns}
              setCheckedColumns={setCheckedColumns}
              table={tableData}
              canSearch
              searchText="Search Sola Pros..."
              selectedItems={selectedItems}
              showExportSelectedBtn
              showExportAllBtn={userData?.role_name !== userRoles.SOLA_PRO_USER}
              pageCount={pageCount}
              pageNo={pageNo}
              setPageNo={setPageNo}
              totalCount={totalCount}
              fetchData={fetchData}
              exportFields={exportData.stylists.export_fields}
              exportTable={exportData.stylists.table_name}
              exportType={exportData.stylists.export_type}
              advanceFilter
              exportSection="closed"
              manualSearch
              manualSort
              locationsIds={!adminSuperAdmin() && getAssociationLocations()}
              isDisplayCheckAllCheckbox
              onChangeCheckAllCheckbox={checkUncheckAll}
              isCheckAll={isCheckAll}
              checkedColumns={checkedColumns}
              clearCheckItem={clearCheckItem}
              useRubyApiToExport
              status="inactive"
            />
          )}
        {detailsPopup && (
          <ViewDetails
            open={detailsPopup}
            updateData={updateData}
            onClose={() => closeDetailPopup()}
            sortBy={sortBy}
            search={search}
            perPage={perPage}
          />
        )}
      </Card>
    </MDBox>
  );
}

export default SolaPros;
