import { useEffect, useState } from "react";
import { FormControl, Icon, IconButton, MenuItem, Popover, Select, Tooltip } from "@mui/material";
import MDBox from "components/MDBox";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import MDDatePicker from "components/MDDatePicker";
import { fromToDate, getDateRange } from "helper/services";
import moment from "moment";

const stringOperator = [
  { name: "contains", value: "contains with" },
  { name: "Is exactly", value: "is exactly" },
  { name: "Starts with", value: "starts with" },
  { name: "Ends with", value: "ends with" },
  { name: "Is present", value: "is_present", disable: true },
  { name: "Is blank", value: "is_blank", disable: true },
];

const dateOperator = [
  { name: "Date ...", value: "date" },
  { name: "Between ... and ...", value: "between_and" },
  { name: "Today", value: "today", disable: true },
  { name: "Yesterday", value: "yesterday", disable: true },
  { name: "This week", value: "this_week", disable: true, additional_value: "between_and" },
  { name: "Last week", value: "last_week", disable: true, additional_value: "between_and" },
  { name: "Last Month", value: "last_month", disable: true, additional_value: "between_and" },
  { name: "Last 3 Months", value: "last_3_months", disable: true, additional_value: "between_and" },
  { name: "Last 6 Months", value: "last_6_months", disable: true, additional_value: "between_and" },
  {
    name: "Last 12 Months",
    value: "last_12_months",
    disable: true,
    additional_value: "between_and",
  },
  { name: "Is present", value: "is_present", disable: true },
  { name: "Is blank", value: "is_blank", disable: true },
];

// Boolean operator
const booleanOperator = [
  { name: "True", value: "true" },
  { name: "False", value: "false" },
];

// Chnaged operator
const chnagedOperator = [
  { name: "Yes", value: "true" },
  { name: "No", value: "false" },
];

// Is Connected opertor
const connectedOperator = [
  { name: "Yes", value: "yes" },
  { name: "No", value: "no" },
];

// Status operator
const statusOperator = [
  { name: "Queued", value: "0" },
  { name: "Processed", value: "1" },
  { name: "Failed", value: "2" },
];

// Contact prefrence operator
const contactPreferneceOperator = [
  { name: "Sms", value: "0" },
  { name: "Email", value: "1" },
  { name: "Phone", value: "2" },
  { name: "URL", value: "3" },
];
// Report operator
const reportOperator = [
  { name: "All Locations", value: "all_locations" },
  { name: "Request Tour Inquiries", value: "request_tour_inquiries" },
  { name: "All Contact Form Submissions", value: "all_contact_form_submission" },
  { name: "All Stylists", value: "all_stylists" },
  { name: "All Terminated Stylists Report", value: "all_terminated_stylists_report" },
  { name: "Sola Pro / SolaGenius Penetration'", value: "solapro_solagenius_penetration" },
];
const initialFilterItem = [
  {
    columnName: "",
    columnField: "",
    operatorValue: "",
    value: "",
    apply: false,
    is_date: false,
    is_boolean: false,
    is_connected: false,
    is_status: false,
    is_contact_preference: false,
    is_reporter: false,
    associative_colums: "",
    is_changed: false,
  },
];

function Index(props: any): JSX.Element | null {
  const {
    anchorEl,
    handleClose,
    filterData,
    handlefilterData,
    columns,
    search,
    defaultFilteringColums,
    setIsManual,
    // onSearchChange,
    // setSearch,
  } = props;

  const [errors, setErrors] = useState<any>({});
  const [filterItem, setFilterItem] = useState([]);
  const [filterColumns, setFilterColumns] = useState([]);
  const [error, setError] = useState(false);

  useEffect(() => {
    if (search && search?.length > 0) {
      setErrors({});
      setFilterItem(initialFilterItem);
      setFilterColumns([]);
      handlefilterData([]);
    }
  }, [search]);

  useEffect(() => {
    setErrors({});
    const mergedArray = [...filterItem, ...filterData];
    const uniqueData = [
      ...mergedArray.reduce((map, obj) => map.set(obj.columnField, obj), new Map()).values(),
    ];
    setFilterItem(uniqueData);
  }, [anchorEl]);

  useEffect(() => {
    if (
      filterItem?.length &&
      filterItem?.findIndex(
        (item: any) =>
          (item.operatorValue === "is_present" || item.operatorValue === "is_blank") &&
          (item.columnName === "" || item.columnField === "" || item.operatorValue === "")
      ) !== -1
    ) {
      setError(true);
    } else if (
      filterItem?.findIndex(
        (item: any) =>
          item.operatorValue !== "is_present" &&
          item.operatorValue !== "is_blank" &&
          (item.columnName === "" ||
            item.columnField === "" ||
            item.operatorValue === "" ||
            item.value === "")
      ) !== -1
    ) {
      setError(true);
    } else {
      setError(false);
    }
  }, [filterItem]);

  useEffect(() => {
    setFilterItem(
      defaultFilteringColums && defaultFilteringColums?.length
        ? defaultFilteringColums
        : initialFilterItem
    );
    const columFields =
      defaultFilteringColums?.length && defaultFilteringColums?.map((obj: any) => obj?.columnField);
    setFilterColumns(columFields && columFields?.length ? columFields : []);
  }, [defaultFilteringColums, initialFilterItem]);

  const open = Boolean(anchorEl);
  const id = open ? "filter-popover" : undefined;

  const handleChangeOperator = (index: any) => (event: any) => {
    const tempArr = JSON.parse(JSON.stringify(filterItem));
    tempArr[index].operatorValue = event.target.value;
    if (["is_present", "is_blank"].includes(tempArr[index].operatorValue)) {
      tempArr[index].value = "";
    }
    if (tempArr[index].is_date && tempArr[index].operatorValue === "between_and") {
      tempArr[index].value = null;
      delete tempArr[index].from_date;
      delete tempArr[index].to_date;
    }
    if (tempArr[index].is_date && tempArr[index].operatorValue !== "between_and") {
      const dateObj = new Date();
      if (tempArr[index].operatorValue === "today") {
        tempArr[index].value = moment(dateObj).format("yyyy-MM-DD");
      }
      if (tempArr[index].operatorValue === "yesterday") {
        const yesterday = new Date(dateObj.setDate(dateObj.getDate() - 1));
        tempArr[index].value = moment(yesterday).format("yyyy-MM-DD");
      }
      if (tempArr[index].operatorValue === "this_week") {
        const thisWeek = getDateRange("week", 0);
        const dispDates: any = fromToDate(thisWeek);
        tempArr[index].value = `${dispDates?.from_date} -  ${dispDates?.to_Date}`;
        tempArr[index].from_date = dispDates?.from_date;
        tempArr[index].to_date = dispDates?.to_Date;
      }
      if (tempArr[index].operatorValue === "last_week") {
        const lastWeek = getDateRange("week", 1);
        const dispDates: any = fromToDate(lastWeek);
        tempArr[index].value = `${dispDates?.from_date} -  ${dispDates?.to_Date}`;
        tempArr[index].from_date = dispDates?.from_date;
        tempArr[index].to_date = dispDates?.to_Date;
      }
      if (tempArr[index].operatorValue === "last_month") {
        // tempArr[index].value = getDateRange("month", 1);
        const lastMonth = getDateRange("month", 1);
        const dispDates: any = fromToDate(lastMonth);
        tempArr[index].value = `${dispDates?.from_date} -  ${dispDates?.to_Date}`;
        tempArr[index].from_date = dispDates?.from_date;
        tempArr[index].to_date = dispDates?.to_Date;
      }
      if (tempArr[index].operatorValue === "last_3_months") {
        // tempArr[index].value = getDateRange("month", 3);
        const lastThreeMonth = getDateRange("month", 3);
        const dispDates: any = fromToDate(lastThreeMonth);
        tempArr[index].value = `${dispDates?.from_date} -  ${dispDates?.to_Date}`;
        tempArr[index].from_date = dispDates?.from_date;
        tempArr[index].to_date = dispDates?.to_Date;
      }
      if (tempArr[index].operatorValue === "last_6_months") {
        // tempArr[index].value = getDateRange("month", 6);
        const lastSixMonth = getDateRange("month", 6);
        const dispDates: any = fromToDate(lastSixMonth);
        tempArr[index].value = `${dispDates?.from_date} -  ${dispDates?.to_Date}`;
        tempArr[index].from_date = dispDates?.from_date;
        tempArr[index].to_date = dispDates?.to_Date;
      }
      if (tempArr[index].operatorValue === "last_12_months") {
        // tempArr[index].value = getDateRange("month", 12);
        const lastTwelveMonth = getDateRange("month", 12);
        const dispDates: any = fromToDate(lastTwelveMonth);
        tempArr[index].value = `${dispDates?.from_date} -  ${dispDates?.to_Date}`;
        tempArr[index].from_date = dispDates?.from_date;
        tempArr[index].to_date = dispDates?.to_Date;
      }
    }

    setFilterItem(tempArr);
  };

  const handleChangeValue = (index: any) => (event: any) => {
    const tempArr = JSON.parse(JSON.stringify(filterItem));
    tempArr[index].value = event.target.value;

    setFilterItem(tempArr);
  };

  const getDateMode = (operatorVal: any) => {
    if (operatorVal === "between_and") {
      return "range";
    }
    return "single";
  };

  const handleChangeDateValue = (value: any, index: any, operatorValue: any) => {
    const tempArr = JSON.parse(JSON.stringify(filterItem));
    tempArr[index].value = value;
    if (operatorValue === "between_and" && tempArr[index]?.from_date) {
      delete tempArr[index].from_date;
    }
    if (operatorValue === "between_and" && tempArr[index]?.to_date) {
      delete tempArr[index].to_date;
    }

    setFilterItem(tempArr);
  };

  const setMenuItemBoolean = () => {
    const boolArr = booleanOperator;
    return boolArr.map((obj: any) => <MenuItem value={obj.value}>{obj.name}</MenuItem>);
  };

  const setChangedmBoolean = () => {
    const changedArr = chnagedOperator;
    return changedArr.map((obj: any) => <MenuItem value={obj.value}>{obj.name}</MenuItem>);
  };

  const setMenuItemStatus = () => {
    const statusArr = statusOperator;
    return statusArr.map((obj: any) => <MenuItem value={obj.value}>{obj.name}</MenuItem>);
  };

  // Set for I would Like to connected
  const setIwouldLIikeToStatus = () => {
    const statusArr = connectedOperator;
    return statusArr.map((obj: any) => <MenuItem value={obj.value}>{obj.name}</MenuItem>);
  };
  // Set reportTypeItems
  const reportTypeItems = () => {
    const reportType = reportOperator;
    return reportType.map((obj: any) => <MenuItem value={obj.value}>{obj.name}</MenuItem>);
  };

  // Set contact referenceItems
  const setCotactRefrence = () => {
    const statusArr = contactPreferneceOperator;
    return statusArr.map((obj: any) => <MenuItem value={obj.value}>{obj.name}</MenuItem>);
  };
  const setMenuItem = (obj: any) => {
    let arr = stringOperator;
    // Set Status Dropdown
    if (obj && Object.keys(obj) && obj.statusOperator) {
      arr = statusOperator;
    }
    // Set Boolean dropdown
    if (obj && Object.keys(obj) && obj.is_boolean) {
      arr = booleanOperator;
    }
    // Set Changed dropdown
    if (obj && Object.keys(obj) && obj.is_changed) {
      arr = chnagedOperator;
    }

    // Set Is Connected Dropdown
    if (obj && Object.keys(obj) && obj.is_connected) {
      arr = connectedOperator;
    }
    // Hide related report_ty
    if (obj && obj?.columnField === "report_type" && obj?.is_reporter) {
      arr = stringOperator.filter(
        (item: any) =>
          item?.name === "Is exactly" || item?.name === "Is present" || item?.name === "Is blank"
      );
    }

    // Hide contains field from stringOperator
    if (
      (obj && obj?.columnField === "id") ||
      obj?.columnField === "contact_preference" ||
      obj?.columnField === "status"
    ) {
      arr = stringOperator.filter((item: any) => item?.name === "Is exactly");
    }
    // While we have Is_boolean field from stringOperator
    if (obj && obj.is_boolean) {
      arr = stringOperator.filter((item: any) => item?.name === "Is exactly");
    }
    // While we have is chnaged field from stringOperator
    if (obj && obj.is_changed) {
      arr = stringOperator.filter((item: any) => item?.name === "Is exactly");
    }
    // While we have Is_Connected field from stringOperator
    if (obj && obj.is_connected) {
      arr = stringOperator.filter((item: any) => item?.name === "Is exactly");
    }
    // Show dateOperator while we select created_at
    if (obj && Object.keys(obj) && obj.is_date) {
      arr = dateOperator;
    }
    if (obj && obj?.columnField === "approved") {
      arr = stringOperator.filter((item: any) => item?.name === "Is exactly");
    }

    return arr.map((obj: any) => <MenuItem value={obj.value}>{obj.name}</MenuItem>);
  };

  const isInputDisable = (obj: any) => {
    if (obj && Object.keys(obj) && obj.is_date) {
      const tempArr = dateOperator.filter((item: any) => item.value === obj.operatorValue);

      return tempArr[0]?.disable || false;
    }
    const tempArr = stringOperator.filter((item: any) => item.value === obj.operatorValue);
    return tempArr[0]?.disable || false;
  };

  const onClearFilter = () => {
    setErrors({});
    setFilterItem(initialFilterItem);
    setFilterColumns([]);
    handlefilterData([]);
  };

  const onApply = () => {
    if (!error) {
      handlefilterData(filterItem);
      handleClose();
      setIsManual(true);
    }
  };

  const handleChangeColumn = (index: any) => (event: any) => {
    const tempArr = JSON.parse(JSON.stringify(filterItem));
    const tempFilterColumns: any = [];
    const column = columns.find((col: any) => col.accessor === event.target.value);
    tempArr[index].associative_colums = column.associative_colums;
    tempArr[index].columnField = event.target.value;
    tempArr[index].columnName = column.Header;
    tempArr[index].is_date = column.is_date;
    tempArr[index].is_boolean = column.is_boolean;
    tempArr[index].is_changed = column.is_changed;
    tempArr[index].is_connected = column.is_connected;
    tempArr[index].is_status = column.is_status;
    tempArr[index].is_contact_preference = column.is_contact_preference;
    tempArr[index].is_reporter = column.is_reporter;
    // tempArr[index].associative_colums = column.associative_colums;

    setFilterItem(tempArr);
    tempArr.map((val: any) => {
      tempFilterColumns.push(val.columnField);
      return true;
    });
    setFilterColumns(tempFilterColumns);
  };

  const onAddFilter = () => {
    const tempArr = JSON.parse(JSON.stringify(filterItem));
    tempArr.push({
      columnName: "",
      columnField: "",
      operatorValue: "",
      value: "",
      apply: false,
      is_date: false,
      is_boolean: false,
      is_connected: false,
      is_status: false,
      is_contact_preference: false,
      is_reporter: false,
      associative_colums: "",
      is_changed: false,
    });
    setFilterItem(tempArr);
  };

  const onRemoveFilter = (index: any) => (event: any) => {
    console.log("event ", event.target);

    const tempArr = JSON.parse(JSON.stringify(filterItem));
    const filterCol = [...filterColumns];

    filterCol.splice(filterCol.indexOf(tempArr[index].columnField), 1);
    tempArr.splice(index, 1);

    setFilterColumns(filterCol);
    setFilterItem(tempArr);
  };

  const getFilterFields = () => {
    // const items : any = [...filterItem];
    const filterFields: any = [];
    let customKey: any = 0;
    filterItem.map((obj: any, index: any) => {
      filterFields.push(
        <MDBox
          key={customKey}
          sx={{
            display: "flex",
            border: {
              lg: "0px",
              md: "0px",
              sm: "1px solid lightgrey",
              xs: "1px solid lightgrey",
            },
            borderRadius: "4px",
            justifyContent: "space-between",
            m: "2px",
          }}
        >
          <MDBox
            display="flex"
            flexDirection={{ lg: "row", md: "row", sm: "column", xs: "column" }}
            alignItems={{ lg: "flex-end", md: "flex-end" }}
          >
            <FormControl
              variant="standard"
              size="medium"
              sx={{
                m: { xs: 0, sm: 0, md: 1, lg: 1 },
                p: { xs: 1, sm: 1, md: 0, lg: 0 },
                width: { xs: "100%", sm: "100%", md: 150, lg: 150 },
              }}
            >
              <Select
                value={filterItem[index]?.columnField}
                onChange={handleChangeColumn(index)}
                placeholder="Column"
                className="custom-filter-column-fields"
                autoWidth
              >
                {columns
                  ?.filter(
                    (col: any) =>
                      (!col?.hide_columns && filterColumns?.indexOf(col.accessor) === -1) ||
                      col?.accessor === obj?.columnField
                  )
                  .map((obj: any) => (
                    <MenuItem key={obj.accessor} value={obj.accessor}>
                      {obj.Header}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
            <FormControl
              variant="standard"
              size="medium"
              sx={{
                m: { xs: 0, sm: 0, md: 1, lg: 1 },
                p: { xs: 1, sm: 1, md: 0, lg: 0 },
                width: { xs: "100%", sm: "100%", md: 130, lg: 130 },
              }}
            >
              <Select
                value={filterItem[index]?.operatorValue}
                onChange={handleChangeOperator(index)}
                placeholder="Operator"
                className="custom-filter-column-fields"
                autoWidth
              >
                {setMenuItem(obj)}
              </Select>
            </FormControl>
            <FormControl sx={{ m: 1, minWidth: 270 }} size="small">
              {obj?.is_date && !isInputDisable(obj) ? (
                <MDBox>
                  <MDDatePicker
                    input={{
                      placeholder:
                        obj?.operatorValue === "between_and" ? "Select Date Range" : "Value",
                      variant: "standard",
                      error: errors && errors[filterItem[index]?.columnField] ? true : false,
                      fullWidth: true,
                    }}
                    name={filterItem[index].columnField}
                    onChange={(value: any) =>
                      handleChangeDateValue(value, index, obj?.operatorValue)
                    }
                    options={{
                      mode: getDateMode(filterItem[index]?.operatorValue),
                      enableTime: true,
                      // dateFormat: "M d, Y H:i",
                      dateFormat: "Y-m-d",
                      time_24hr: true,
                    }}
                    error
                    value={filterItem[index].value}
                  />
                </MDBox>
              ) : (
                <MDBox display="flex">
                  {obj?.is_connected ? (
                    <Select
                      value={filterItem[index]?.value}
                      onChange={handleChangeValue(index)}
                      placeholder="Operator"
                      className="custom-filter-column-fields"
                      name="value"
                      variant="standard"
                      fullWidth
                    >
                      {setIwouldLIikeToStatus()}
                    </Select>
                  ) : obj?.is_status ? (
                    <Select
                      value={filterItem[index]?.value}
                      onChange={handleChangeValue(index)}
                      placeholder="Operator"
                      className="custom-filter-column-fields"
                      name="value"
                      variant="standard"
                      fullWidth
                    >
                      {setMenuItemStatus()}
                    </Select>
                  ) : obj?.is_changed ? (
                    <Select
                      value={filterItem[index]?.value}
                      onChange={handleChangeValue(index)}
                      placeholder="Operator"
                      className="custom-filter-column-fields"
                      name="value"
                      variant="standard"
                      fullWidth
                    >
                      {setChangedmBoolean()}
                    </Select>
                  ) : obj?.is_boolean ? (
                    <Select
                      value={filterItem[index]?.value}
                      onChange={handleChangeValue(index)}
                      placeholder="Operator"
                      className="custom-filter-column-fields"
                      name="value"
                      variant="standard"
                      fullWidth
                    >
                      {setMenuItemBoolean()}
                    </Select>
                  ) : obj?.is_reporter ? (
                    <Select
                      value={
                        obj?.operatorValue === "is_present" || obj?.operatorValue === "is_blank"
                          ? ""
                          : filterItem[index]?.value
                      }
                      onChange={handleChangeValue(index)}
                      placeholder="Operator"
                      className="custom-filter-column-fields"
                      name="value"
                      variant="standard"
                      fullWidth
                      disabled={isInputDisable(obj)}
                    >
                      {reportTypeItems()}
                    </Select>
                  ) : obj?.is_contact_preference ? (
                    <Select
                      value={filterItem[index]?.value}
                      onChange={handleChangeValue(index)}
                      placeholder="Operator"
                      className="custom-filter-column-fields"
                      name="value"
                      variant="standard"
                      fullWidth
                    >
                      {setCotactRefrence()}
                    </Select>
                  ) : (
                    <MDInput
                      fullWidth
                      value={
                        obj?.operatorValue === "is_present" || obj?.operatorValue === "is_blank"
                          ? ""
                          : filterItem[index]?.value
                      }
                      onChange={handleChangeValue(index)}
                      variant="standard"
                      placeholder="Value"
                      size="small"
                      disabled={isInputDisable(obj)}
                    />
                  )}
                </MDBox>
              )}
            </FormControl>
          </MDBox>
          {filterItem?.length > 1 && (
            <MDBox>
              <IconButton onClick={onRemoveFilter(index)}>
                <Icon color="error">close</Icon>
              </IconButton>
            </MDBox>
          )}
        </MDBox>
      );
      customKey++;

      return true;
    });

    return filterFields;
  };

  return (
    <Popover
      id={id}
      open={open}
      anchorEl={anchorEl}
      onClose={handleClose}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "center",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "center",
      }}
    >
      <MDBox display="flex" flexDirection="column">
        {getFilterFields()}
        <MDBox
          sx={{ display: "flex", justifyContent: "space-between", flexDirection: "row", mt: 1 }}
        >
          <MDBox>
            {columns?.length > filterColumns?.length && (
              <MDButton
                onClick={onAddFilter}
                variant="text"
                color="info"
                className="xs-small"
                size="small"
              >
                + Add More
              </MDButton>
            )}
          </MDBox>
          <MDBox>
            <MDButton
              onClick={onClearFilter}
              variant="text"
              color="info"
              className="xs-small"
              size="small"
              sx={{ maxWidth: 40, mr: 1 }}
            >
              Clear
            </MDButton>
            <Tooltip
              title={error ? "Please select above fields for filter or remove the filter.." : ""}
              arrow
            >
              <MDButton
                onClick={onApply}
                variant="gradient"
                color={error ? "light" : "info"}
                className="xs-small"
                size="small"
                sx={{ maxWidth: 40 }}
              >
                Apply
              </MDButton>
            </Tooltip>
          </MDBox>
        </MDBox>
      </MDBox>
    </Popover>
  );
}
export default Index;
