import React, { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "store/store";
import MDDialog from "components/MDDialog";
import { Card, CardActions, FormControl, Grid, Icon, IconButton, Snackbar } from "@mui/material";
import MDTypography from "components/MDTypography";
import {
  assignContactToDeal,
  deleteLeads,
  editContactIquiries,
  getLeadDetails,
  removeContactFromDeal,
} from "store/thunk/leaseThunk";
import Messages from "helper/messages";
import MDBox from "components/MDBox";
import MDInput from "components/MDInput";
import CustomPhoneInput from "components/CustomPhoneInput";
import MDButton from "components/MDButton";
import CustomSelect from "components/CustomSelect";
import MDDatePicker from "components/MDDatePicker";
import CustomAutoSearch from "components/CustomAutoSearch";
import UserWithProfilePic from "components/CustomAutoSearch/UserWithProfilePic";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import moment from "moment";
import { scrollToErrorByClass } from "helper/services";
import Validations from "helper/validations";
import constants from "helper/constants";
import MDIconButton from "components/MDIconButton";
import ViewNotesDetails from "./viewNotesDetails";

const {
  servicesFilterOptions,
  studioTypeOptions,
  studioPreferenceOptions,
  interestedSharingOptions,
  leadComingFromOptions,
  reasonClosingLeadOptions,
} = constants;

function LeadInfo(props: any): JSX.Element {
  const { data, getAllLeads } = props;
  const [deletePopup, setDeletePopup] = useState<any>(false);
  const { leadDetails } = useAppSelector((state) => state.leaseSlice);
  const [leadInfo, setLeadInfo] = useState<any>({});
  const split = data.deal_name.split(" ");
  const firstname = split.length > 1 ? split.slice(0, split.length - 1).join(" ") : split[0];
  const lastname = split.length > 1 ? split.pop() : "";
  const dispatch = useAppDispatch();
  const [selectedServices, setSelectedServices] = useState<any>(null);
  const [studioType, setStudioType] = useState<any>(null);
  const [studioPreference, setStudioPreference] = useState<any>(null);
  const [interestedSharing, setInterestedSharing] = useState<any>(null);
  const [leadComingFrom, setLeadComingFrom] = useState<any>(null);
  const [reasonClosingLead, setReasonClosingLead] = useState<any>(null);
  const [waitlistDate, setWaitlistDate] = useState<any>(null);
  const [competitorName, setCompetitorName] = useState<any>("");
  const [expanded, setExpanded] = useState<string | boolean>("contact");
  const [open, setOpen] = useState(false);
  const [contacts, setContactsData] = useState(null);
  const [contactDeletePopup, setContactDeletePopup] = useState<any>(false);
  const [errors, setErrors] = useState<any>({});
  const [dealOwnerId, setDealOwnerId] = useState<any>(null);
  const [detailsPopup, setDetailsPopup] = useState<any>(false);

  useEffect(() => {
    if (data) {
      const request: any = {
        leadId: data.id,
      };
      dispatch(getLeadDetails(request));
      setLeadInfo({
        ...data,
        first_name: firstname,
        last_name: lastname,
        phone: (data?.connect_contact && data?.connect_contact?.phone) || "",
        email: (data?.connect_contact && data?.connect_contact?.email) || "",
      });
      if (data?.deal_owner_id && data?.connect_user) {
        setDealOwnerId({
          value: {
            user_id: data?.deal_owner_id,
            first_name: data?.connect_user?.first_name || "",
            last_name: data?.connect_user?.last_name || "",
            profile_picture: data?.connect_user?.profile_picture || "",
          },
          label: `${data?.connect_user?.first_name || ""} ${data?.connect_user?.last_name || ""} `,
        });
      } else {
        setDealOwnerId(null);
      }
    }
  }, []);

  useEffect(() => {
    if (leadDetails) {
      if (leadDetails?.data?.deal_owner_id && leadDetails?.data?.connect_user) {
        setDealOwnerId({
          value: {
            user_id: leadDetails?.data?.deal_owner_id,
            first_name: leadDetails?.data?.connect_user?.first_name || "",
            last_name: leadDetails?.data?.connect_user?.last_name || "",
            profile_picture: leadDetails?.data?.connect_user?.profile_picture || "",
          },
          label: `${leadDetails?.data?.connect_user?.first_name || ""} ${
            leadDetails?.data?.connect_user?.last_name || ""
          }`,
        });
      } else {
        setDealOwnerId(null);
      }

      const connectContactData = leadDetails.data?.connect_contact;
      setContactsData(leadDetails.data?.connect_contact);
      setLeadInfo({
        ...leadDetails.data,
        first_name: connectContactData?.first_name || "",
        last_name: connectContactData?.last_name || "",
        phone: connectContactData?.phone || "",
        email: connectContactData?.email || "",
        selected_location: {
          id: leadDetails?.data?.location?.id,
          name: leadDetails?.data?.location?.name,
        },
      });
      const requestServices = leadDetails.data?.services?.split(",")?.map((v: string) => v.trim());
      if (requestServices) {
        setSelectedServices(
          servicesFilterOptions?.filter((v) => requestServices?.includes(v.name))
        );
      }
      setWaitlistDate(
        leadDetails.data.waitlist_date
          ? [new Date(moment(leadDetails.data.waitlist_date).format("MM/DD/YYYY"))]
          : null
      );
      setInterestedSharing(
        interestedSharingOptions.find((v: any) =>
          leadDetails.data.sharing_studio ? v.value === "yes" : v.value === "no"
        )
      );
      setStudioType(leadDetails.data.studio_type);
      setStudioPreference(leadDetails.data.studio_preference);
      setLeadComingFrom(leadDetails.data.lead_coming_from);
      setReasonClosingLead(
        reasonClosingLeadOptions.find((v: any) => v.label === leadDetails.data.reason_closing_lead)
      );
      setCompetitorName(leadDetails.data.lead_lost_competitor_name);
    }
  }, [leadDetails]);

  const onDelete = () => {
    const request = {
      leadId: data.id,
      getAllLeads,
    };
    dispatch(deleteLeads(request));
  };

  const onRemoveContact = () => {
    const request: any = {
      leadId: data.id,
      contact_id: contacts?.hs_contact_id,
      hs_deal_id: data.hs_deal_id,
      setContactDeletePopup,
    };
    dispatch(removeContactFromDeal(request));
  };

  /* const isAdmin = () => {
    if (userData.role === userRoles.SYSTEM_ADMIN || userData.role === userRoles.ADMIN) {
      return true;
    }
    return false;
  }; */

  const onContactAssign = () => {
    const request: any = {
      leadId: data.id,
      contact_id: contacts?.hs_contact_id,
      hs_deal_id: data.hs_deal_id,
    };
    dispatch(assignContactToDeal(request));
  };

  const setContacts = (event: any, value: any) => {
    setContactsData(value);
  };

  const copyToClipboard = (text: any) => {
    setOpen(true);
    navigator.clipboard.writeText(text);
  };

  const handleChangeAccordian =
    (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : false);
    };

  const onEditLead = () => {
    const error = Validations.validateLeadInfoForm(leadInfo);
    setErrors(error);
    if (!Object.keys(error).length) {
      dispatch(
        editContactIquiries({
          leadInfo,
          studioType,
          studioPreference,
          waitlistDate: waitlistDate
            ? moment(waitlistDate[0]).add(moment().utcOffset(), "minutes").toISOString()
            : null,
          leadComingFrom,
          reasonClosingLead,
          interestedSharing,
          competitorName,
          selectedServices,
          dealOwnerId: dealOwnerId?.value?.user_id ? dealOwnerId?.value?.user_id : null,
        })
      );
    } else {
      scrollToErrorByClass(error);
    }
  };

  const getOwner = () => {
    let owner = "";
    if (data && data.location) {
      owner = data.location.email_address_for_hubspot || data.location.email_address_for_inquiries;
    }
    return owner;
  };

  const deleteDialogProps = {
    open: deletePopup,
    onClose: () => setDeletePopup(false),
    onSave: () => onDelete(),
    title: "Delete Lead",
    size: "sm",
    saveBtn: false,
    deleteBtn: true,
    saveTbtText: "Yes",
    closeBtnText: "No",
  };

  const contactDeleteDialogProps = {
    open: contactDeletePopup,
    onClose: () => setContactDeletePopup(false),
    onSave: () => onRemoveContact(),
    title: "Remove Contact",
    size: "sm",
    saveBtn: false,
    deleteBtn: true,
    saveTbtText: "Yes",
    closeBtnText: "No",
  };

  const onChangeFields = (event: any) => {
    setLeadInfo({
      ...leadInfo,
      [event.target.name]: event.target.value,
    });
  };

  const onChangeDealOwner = (name: any, value: any) => {
    setDealOwnerId(value);
  };

  return (
    <MDBox>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <UserWithProfilePic
            fieldLabel="Owner"
            placeholder={!dealOwnerId ? "No Owner" : ""}
            apiName="user-list-by-location"
            responseKey="users"
            name="users"
            slsAPI
            value={dealOwnerId || null}
            onChange={onChangeDealOwner}
            additionaFields={{ location_id: leadInfo?.location_id }}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <MDInput
            label="Deal name"
            variant="standard"
            fullWidth
            name="deal_name"
            placeholder="Deal name"
            onChange={onChangeFields}
            value={leadInfo?.deal_name || ""}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <MDInput
            label="Lead Status"
            variant="standard"
            fullWidth
            name="lead_status"
            placeholder="Lead Status"
            // disabled
            readOnly
            value={data.status || ""}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <MDInput
            label="First name"
            variant="standard"
            fullWidth
            name="first_name"
            placeholder="First name"
            onChange={onChangeFields}
            value={leadInfo?.first_name || ""}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <MDInput
            label="Last name"
            variant="standard"
            fullWidth
            name="last_name"
            placeholder="Last name"
            onChange={onChangeFields}
            value={leadInfo?.last_name || ""}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <MDInput
            variant="standard"
            fullWidth
            label="Phone"
            name="phone"
            placeholder="(000) 000-0000"
            id="phone-input"
            onChange={onChangeFields}
            InputProps={{
              inputComponent: CustomPhoneInput as any,
            }}
            value={leadInfo?.phone || ""}
            disabled
          />
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <MDInput
            variant="standard"
            fullWidth
            value={leadInfo?.email || ""}
            label="Email"
            name="email"
            helperText={errors && errors.email}
            error={errors && errors?.email}
            onChange={onChangeFields}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <MDInput
            label="Lead Owner"
            variant="standard"
            fullWidth
            name="lead_owner"
            placeholder="Lead Owner"
            readOnly
            value={getOwner() || "-"}
          />
        </Grid>
      </Grid>
      <Grid container mt={2}>
        <Grid item xs={12} sm={12} md={12} lg={12} mt={2} className="selected_location">
          <CustomSelect
            onChange={(value: any) => {
              setSelectedServices(value);
            }}
            name="services"
            fieldLabel="Services"
            placeholder="Services"
            value={selectedServices || []}
            id="services"
            options={servicesFilterOptions.map((obj: any) => {
              const tempObj = obj;
              tempObj.label = tempObj.name;
              return tempObj;
            })}
            multiple
          />
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12} sx={{ marginTop: "18px" }}>
          <FormControl>
            <MDTypography variant="caption">Waitlist Date</MDTypography>
            <MDDatePicker
              name="waitlist_date"
              onChange={(value: any) => setWaitlistDate(value)}
              options={{
                enableTime: false,
                dateFormat: "m/d/Y",
                time_24hr: true,
              }}
              value={waitlistDate}
            />
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12} sx={{ marginTop: "18px" }}>
          <CustomSelect
            onChange={(value: any) => {
              setStudioType(value);
            }}
            name="studio_type"
            fieldLabel="Studio Type"
            placeholder="Studio Type"
            value={studioType || []}
            id="studio_type"
            options={studioTypeOptions}
            multiple
          />
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12} sx={{ marginTop: "18px" }}>
          <CustomSelect
            onChange={(value: any) => {
              setStudioPreference(value);
            }}
            name="studio_preference"
            fieldLabel="Studio Preference"
            placeholder="Select…"
            value={studioPreference || []}
            id="studio_preference"
            options={studioPreferenceOptions}
            multiple
          />
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12} sx={{ marginTop: "18px" }}>
          <CustomSelect
            onChange={(value: any) => {
              setInterestedSharing(value);
            }}
            name="interested_sharing"
            fieldLabel="Interested in Sharing a Studio?"
            placeholder="Select…"
            value={interestedSharing || []}
            id="interested_sharing"
            options={interestedSharingOptions}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12} sx={{ marginTop: "18px" }}>
          <CustomSelect
            onChange={(value: any) => {
              setLeadComingFrom(value);
            }}
            name="lead_coming_from"
            fieldLabel="Where is Lead Coming From"
            placeholder="Select…"
            value={leadComingFrom || []}
            id="lead_coming_from"
            options={leadComingFromOptions}
            multiple
          />
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12} sx={{ marginTop: "18px" }}>
          <CustomSelect
            onChange={(value: any) => {
              setReasonClosingLead(value);
            }}
            name="reason_closing_lead"
            fieldLabel="Reason For Closing Lead"
            placeholder="Select…"
            value={reasonClosingLead || []}
            id="reason_closing_lead"
            options={reasonClosingLeadOptions}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12} sx={{ marginTop: "18px" }}>
          <MDInput
            label="If Lead Lost To Competitor, Please Enter Name of Competitor"
            variant="standard"
            fullWidth
            name="competitor_name"
            placeholder="Competitor name"
            onChange={(e: any) => setCompetitorName(e.target.value)}
            value={competitorName}
          />
        </Grid>
      </Grid>
      <Grid container mt={2}>
        <Grid item xs={12} md={12} sm={12} lg={12}>
          <MDInput
            label="Form Message"
            variant="standard"
            fullWidth
            disabled
            value={leadInfo?.request_tour_inquiries?.[0]?.message || "No Message"}
          />
        </Grid>
        <Grid item xs={12} md={12} sm={12} lg={12} mt={2}>
          <MDInput
            label="Ideal timing to lease a studio"
            variant="standard"
            fullWidth
            disabled
            value={leadInfo?.request_tour_inquiries?.[0]?.ideal_timing || "-"}
          />
        </Grid>
      </Grid>
      <Grid container>
        <Grid item xs={12} sm={12} md={12} lg={12} mt={1}>
          {data?.connect_deal_notes.length > 0 && (
            <MDTypography variant="subtitle2" fontWeight="medium">
              Resubmitted Contact Inquiries:
              <MDIconButton
                tooltipName="View"
                aria-label="Open"
                color="info"
                onClick={() => setDetailsPopup(true)}
              >
                <Icon fontSize="small">visibility</Icon>
              </MDIconButton>
            </MDTypography>
          )}
        </Grid>
      </Grid>
      <Grid container>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <Accordion
            expanded={expanded === "contact"}
            sx={{ marginTop: "20px" }}
            id="contact-accordian"
            onChange={handleChangeAccordian("contact")}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="contact-content"
              id="contact-header"
              className="location-accordion"
            >
              <MDTypography variant="h6">Contact</MDTypography>
            </AccordionSummary>
            <AccordionDetails>
              {leadInfo?.connect_contact ? (
                // <>
                <Card sx={{ p: 2, m: 1 }}>
                  <Grid container>
                    <Grid item xs={12} sm={12} md={11} lg={11}>
                      {(leadInfo?.connect_contact?.first_name ||
                        leadInfo?.connect_contact?.last_name) && (
                        <MDTypography variant="h6">
                          {`${leadInfo?.connect_contact?.first_name} ${leadInfo?.connect_contact?.last_name}`}
                        </MDTypography>
                      )}
                      {leadInfo?.connect_contact?.email && (
                        <MDTypography variant="h6">
                          {leadInfo?.connect_contact?.email}
                          <IconButton
                            size="small"
                            aria-label="copy_outlined"
                            color="inherit"
                            onClick={() => copyToClipboard(leadInfo?.connect_contact?.email)}
                          >
                            <Icon fontSize="small">copy_outlined</Icon>
                          </IconButton>
                        </MDTypography>
                      )}
                      {leadInfo?.connect_contact?.phone && (
                        <MDTypography variant="h6">
                          {leadInfo?.connect_contact?.phone}
                          <IconButton
                            size="small"
                            aria-label="copy_outlined"
                            color="inherit"
                            onClick={() => copyToClipboard(leadInfo?.connect_contact?.phone)}
                          >
                            <Icon fontSize="small">copy_outlined</Icon>
                          </IconButton>
                        </MDTypography>
                      )}
                    </Grid>
                    <Grid item xs={12} sm={12} md={1} lg={1}>
                      <CardActions>
                        <IconButton
                          size="small"
                          color="primary"
                          onClick={() => setContactDeletePopup(true)}
                        >
                          <Icon>delete</Icon>
                        </IconButton>
                      </CardActions>
                    </Grid>
                  </Grid>
                  <Snackbar
                    open={open}
                    onClose={() => setOpen(false)}
                    autoHideDuration={2000}
                    message="Copied to clipboard"
                  />
                </Card>
              ) : (
                <Grid container spacing={2} display="flex" alignItems="center">
                  <Grid item xs={12} md={12} sm={10} lg={10}>
                    <CustomAutoSearch
                      onChange={setContacts}
                      idKey="hs_contact_id"
                      nameKey="email"
                      name="contacts"
                      responseKey="connect_contacts"
                      placeholder="Contacts"
                      value={contacts || null}
                      apiName="get-contact-list"
                      slsAPI
                    />
                  </Grid>
                  <Grid item xs={12} md={12} sm={2} lg={2}>
                    <MDButton
                      onClick={() => onContactAssign()}
                      variant="gradient"
                      color="info"
                      size="small"
                      sx={{ marginRight: "5px" }}
                      disabled={!contacts}
                    >
                      Assign
                    </MDButton>
                  </Grid>
                </Grid>
              )}
            </AccordionDetails>
          </Accordion>
        </Grid>
      </Grid>

      <MDBox sx={{ display: "flex", justifyContent: "flex-end" }}>
        <MDButton
          onClick={() => onEditLead()}
          variant="gradient"
          color="info"
          size="small"
          sx={{ marginRight: "5px" }}
        >
          Update
        </MDButton>
        <MDButton
          onClick={() => setDeletePopup(true)}
          variant="gradient"
          color="error"
          size="small"
        >
          Delete
        </MDButton>
      </MDBox>

      {deletePopup && (
        <MDDialog {...deleteDialogProps}>
          <MDTypography variant="h6" fontWeight="medium">
            {Messages.GENERAL.SURE_TO_DELETE}
          </MDTypography>
        </MDDialog>
      )}
      {contactDeletePopup && (
        <MDDialog {...contactDeleteDialogProps}>
          <MDTypography variant="h6" fontWeight="medium">
            {Messages.GENERAL.SURE_TO_REMOVE_CONTACT}
          </MDTypography>
        </MDDialog>
      )}
      {detailsPopup && (
        <ViewNotesDetails
          detailsPopup={detailsPopup}
          onClose={() => setDetailsPopup(false)}
          notesData={data?.connect_deal_notes}
        />
      )}
    </MDBox>
  );
}

export default LeadInfo;
