import Grid from "@mui/material/Grid";
import InputLabel from "@mui/material/InputLabel";
import Paper from "@mui/material/Paper";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import CustomPhoneInput from "components/CustomPhoneInput";

function ContacInfo(props: any): JSX.Element {
  const { accountDetail, onChangeField, errors } = props;
  return (
    <Paper>
      <MDBox p={2} mt={2}>
        <MDBox mb={1}>
          <MDTypography variant="h5" fontWeight="regular">
            Contact Info
          </MDTypography>
        </MDBox>
        <MDBox>
          <Grid container spacing={3}>
            <Grid item xs={12} md={12}>
              <MDTypography variant="h6" fontWeight="regular">
                Personal
              </MDTypography>
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
              <MDInput
                label="Email"
                variant="standard"
                fullWidth
                name="personal_email"
                placeholder="Email"
                onChange={onChangeField}
                value={accountDetail?.personal_email || ""}
                error={errors && errors.personal_email ? true : false}
                helperText={errors && errors.personal_email}
                required
              />
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
              <InputLabel>Phone</InputLabel>
              <MDInput
                variant="standard"
                fullWidth
                name="personal_phone"
                placeholder="(000) 000-0000"
                id="phone-input"
                InputProps={{
                  inputComponent: CustomPhoneInput as any,
                }}
                value={accountDetail?.personal_phone}
                error={errors && errors.personal_phone ? true : false}
                helperText={errors && errors.personal_phone}
                onChange={onChangeField}
                required
              />
            </Grid>
          </Grid>
          <Grid container spacing={3} mt={1}>
            <Grid item xs={12} md={12}>
              <MDTypography variant="h6" fontWeight="regular">
                Billing(Optional)
              </MDTypography>
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
              <MDInput
                label="Email"
                variant="standard"
                fullWidth
                name="billing_email"
                placeholder="Email"
                onChange={onChangeField}
                value={accountDetail?.billing_email || ""}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
              <InputLabel>Phone</InputLabel>
              <MDInput
                variant="standard"
                fullWidth
                name="billing_phone"
                placeholder="(000) 000-0000"
                id="billing-phone-input"
                InputProps={{
                  inputComponent: CustomPhoneInput as any,
                }}
                value={accountDetail?.billing_phone}
              />
            </Grid>
          </Grid>
        </MDBox>
      </MDBox>
    </Paper>
  );
}

export default ContacInfo;
